import { LocalRequestBuilder } from "../LocalRequestBuilder";
import { LocalResponseProcessor } from "../LocalResponseProcessor";

export const LocalCollectionsDS = {
  getCollection: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/getCollection", data));
  },
  setCollection: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/setCollection", data));
  },
  deleteCollection: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/deleteCollection", data));
  },
};
