import _ from "underscore";
import { startsWith } from "underscore.string";

import { AbstractSearchDataSource } from "../data-source/AbstractSearchDataSource";
import { LocalSearchService } from "../services/LocalSearchService";
import { SearchAttributesConverter } from "../converters/SearchAttributesConverter";
import { ClientSideSearch } from "../utils/ClientSideSearch";

import { SearchTargetOptionEnum } from "../../models/enums";

export function LocalSearchDataSource() {
  var allResults = [];
  var searchDataSource = AbstractSearchDataSource();

  var css = ClientSideSearch([
    function (resource) {
      var itemTypeCategories = searchDataSource.getFilters().itemTypeCategories;
      if (_.isArray(itemTypeCategories) && _.size(itemTypeCategories) > 0) {
        var matchesFound = [];
        var includeFiltersFound = false;
        var excludesFound = [];
        _.each(itemTypeCategories, function (itemTypeCategory) {
          if (startsWith(itemTypeCategory, "excl_")) {
            var itemTypeCategoryTmp = itemTypeCategory.replace("excl_", "");
            if (_.contains(resource.attributes.itemTypeCategories, itemTypeCategoryTmp)) {
              excludesFound.push(itemTypeCategoryTmp);
            }
          } else {
            includeFiltersFound = true;
            if (_.contains(resource.attributes.itemTypeCategories, itemTypeCategory)) {
              matchesFound.push(itemTypeCategory);
            }
          }
        });
        if (excludesFound.length > 0) {
          return false;
        } else {
          if (includeFiltersFound) {
            return matchesFound.length > 0;
          }
          return true;
        }
      } else {
        return true;
      }
    },
    function (resource) {
      var useCategories = searchDataSource.getFilters().useCategories;
      if (_.isArray(useCategories) && _.size(useCategories) > 0) {
        var matchesFound = [];
        var includeFiltersFound = false;
        var excludesFound = [];
        _.each(useCategories, function (useCategory) {
          if (startsWith(useCategory, "excl_")) {
            var useCategoryTmp = useCategory.replace("excl_", "");
            if (_.contains(resource.attributes.useCategories, useCategoryTmp)) {
              excludesFound.push(useCategoryTmp);
            }
          } else {
            includeFiltersFound = true;
            if (_.contains(resource.attributes.useCategories, useCategory)) {
              matchesFound.push(useCategory);
            }
          }
        });
        if (excludesFound.length > 0) {
          return false;
        } else {
          if (includeFiltersFound) {
            return matchesFound.length > 0;
          }
          return true;
        }
      } else {
        return true;
      }
    },
    function (resource) {
      var languages = searchDataSource.getFilters().languages;
      if (_.isArray(languages) && _.size(languages) > 0) {
        var matchesFound = [];
        var includeFiltersFound = false;
        var excludesFound = [];
        _.each(languages, function (language) {
          if (startsWith(language, "excl_")) {
            var languageTmp = language.replace("excl_", "");
            if (_.contains(resource.attributes.supportedLanguages, languageTmp)) {
              excludesFound.push(languageTmp);
            }
          } else {
            includeFiltersFound = true;
            if (_.contains(resource.attributes.supportedLanguages, language)) {
              matchesFound.push(language);
            }
          }
        });
        if (excludesFound.length > 0) {
          return false;
        } else {
          if (includeFiltersFound) {
            return matchesFound.length > 0;
          }
          return true;
        }
      } else {
        return true;
      }
    },
    function (resource) {
      var measurementUnits = searchDataSource.getFilters().measurementUnits;
      if (_.isArray(measurementUnits) && _.size(measurementUnits) > 0) {
        var matchesFound = [];
        var includeFiltersFound = false;
        var excludesFound = [];
        _.each(measurementUnits, function (measurementUnit) {
          if (startsWith(measurementUnit, "excl_")) {
            var measurementUnitTmp = measurementUnit.replace("excl_", "");
            if (_.contains(resource.attributes.measurementUnits, measurementUnitTmp)) {
              excludesFound.push(measurementUnitTmp);
            }
          } else {
            includeFiltersFound = true;
            if (_.contains(resource.attributes.measurementUnits, measurementUnit)) {
              matchesFound.push(measurementUnit);
            }
          }
        });
        if (excludesFound.length > 0) {
          return false;
        } else {
          if (includeFiltersFound) {
            return matchesFound.length > 0;
          }
          return true;
        }
      } else {
        return true;
      }
    },
    function (resource) {
      var locations = searchDataSource.getFilters().locations;
      if (_.isArray(locations) && _.size(locations) > 0) {
        var matchesFound = [];
        var includeFiltersFound = false;
        var excludesFound = [];
        _.each(locations, function (location) {
          if (startsWith(location, "excl_")) {
            var locationTmp = location.replace("excl_", "");
            if (_.contains(resource.attributes.locationRestrictions, locationTmp)) {
              excludesFound.push(locationTmp);
            }
          } else {
            includeFiltersFound = true;
            if (_.contains(resource.attributes.locationRestrictions, location)) {
              matchesFound.push(location);
            }
          }
        });
        if (excludesFound.length > 0) {
          return false;
        } else {
          if (includeFiltersFound) {
            return matchesFound.length > 0;
          }
          return true;
        }
      } else {
        return true;
      }
    },
  ]);

  function isLastPage(resultsCount, total) {
    return resultsCount >= total;
  }

  function searchContent(data, searchTarget) {
    if (searchTarget === SearchTargetOptionEnum.COLLECTION) {
      return LocalSearchService.searchCollections(data, true);
    } else {
      return LocalSearchService.searchPackages(data, true);
    }
  }

  function filterSearchResults(res) {
    if (!searchDataSource.getSearchTarget() || searchDataSource.getSearchTarget() === SearchTargetOptionEnum.PACKAGE) {
      allResults = css.search(res.results);
    } else {
      allResults = res;
    }
    return allResults;
  }

  searchDataSource.loadNextRows = function (rowsNumber, initial) {
    return new Promise((resolve, reject) => {
      if (initial) {
        var converterData = {
          q: searchDataSource.getQueryString(),
          sortBy: searchDataSource.getSortBy(),
        };
        var fromTo = searchDataSource.pageNumberToRowIndexes(5000);
        _.extend(converterData, fromTo);
        var data = SearchAttributesConverter.toLocal(converterData);
        searchDataSource.setLoaded(false);
        return searchContent(data, searchDataSource.getSearchTarget()).then(
          function (res) {
            allResults = filterSearchResults(res);
            var showResults = [];
            searchDataSource.setResultsTotalCount(allResults.length);
            if (allResults && allResults.length > 0) {
              showResults = allResults.slice(0, rowsNumber);
            }
            searchDataSource.setResults(showResults);
            searchDataSource.setLastPage(isLastPage(searchDataSource.getResultsCount(), allResults.length));
            searchDataSource.setLastRow(searchDataSource.getResultsCount() - 1);
            searchDataSource.setLoaded(true);
            resolve(showResults);
          },
          function (err) {
            resolve([]);
          },
        );
      } else {
        rowsNumber = rowsNumber || 12;
        var showResults = [];
        var fromTo = searchDataSource.pageNumberToRowIndexes(rowsNumber);
        if (allResults && allResults.length > 0) {
          showResults = allResults.slice(fromTo.startRow, fromTo.endRow);
        }
        searchDataSource.setResults(_.union(searchDataSource.getResults(), showResults));
        searchDataSource.setLastRow(searchDataSource.getResultsCount() - 1);
        searchDataSource.setLastPage(
          isLastPage(searchDataSource.getResultsCount(), searchDataSource.getResultsTotalCount()),
        );
        resolve(showResults);
      }
    });
  };
  searchDataSource.loadNextPage = function (rowsNumber) {
    return searchDataSource.loadNextRows(rowsNumber);
  };
  return searchDataSource;
}
