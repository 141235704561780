import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { OrganizationPageStoreContext } from "./organizationPageStore";
import { ICollection } from "../models/dataModel";
import { OnlineCollectionItem } from "../my/collections/online/OnlineCollectionItem";
import { OnlineCollectionsContext, OnlineCollectionsStore } from "../my/collections/online/onlineCollectionsStore";

/**
 * Component that displays collections of organization.
 */
export const OrganizationCollections = observer(() => {
  const orgStore = useContext(OrganizationPageStoreContext);
  const rootStore = orgStore.getRootStore();

  return (
    <div className="collections" data-testid="orgCollections">
      <Fragment>
        {orgStore.wasDataFetched() ? (
          <OnlineCollectionsContext.Provider value={new OnlineCollectionsStore(rootStore)}>
            <div className="item-grid">
              {orgStore.getCollections().map((collection: ICollection, i: number) => {
                return (
                  <div className="container" key={i}>
                    <div className="content-items">
                      <OnlineCollectionItem collection={collection} />
                    </div>
                  </div>
                );
              })}
            </div>
          </OnlineCollectionsContext.Provider>
        ) : (
          <div className="spinner">{rootStore.getTranslation("shared.spinner_loading")}</div>
        )}
      </Fragment>
    </div>
  );
});
