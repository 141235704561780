import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";
import { DialogContext, DialogStore } from "../dialogs/dialogStore";
import { EditImages } from "../dialogs/edit-images/EditImages";
import { TranslatedHtml } from "./TranslatedHtml";
import { RootStore, RootContext } from "../stores/rootStore";
import { IItem } from "../models/dataModel";
import { EditImagesStore, EditImagesStoreContext } from "../dialogs/edit-images/editImagesStore";

/**
 * A component that displays "edit" button and opens EditImages dialog
 */
export const EditImagesWrapper = observer(({ item }: { item: IItem }) => {
  const rootStore: RootStore = useContext(RootContext);
  const dialogStore: DialogStore = new DialogStore();
  const editImagesStore: EditImagesStore = new EditImagesStore(rootStore, item);

  function open() {
    dialogStore.open();
  }

  return (
    <Fragment>
      {rootStore && (
        <EditImagesStoreContext.Provider value={editImagesStore}>
          <button className="button-primary button-edit-images" onClick={open} data-testid="open-edit-images">
            <TranslatedHtml entry={"actions.edit"} />
          </button>
          <DialogContext.Provider value={dialogStore}>
            <EditImages />
          </DialogContext.Provider>
        </EditImagesStoreContext.Provider>
      )}
    </Fragment>
  );
});
