import * as React from "react";
import { observer } from "mobx-react";
import { useContext, Fragment } from "react";
import { Link } from "react-router";

import { RootContext } from "../stores/rootStore";

/**
 * A component to display alerts button.
 */
export const AlertsButton = observer(() => {
  const store = useContext(RootContext);
  const user = store.getUserStore().getCurrentUser();

  function notificationsExist() {
    const notifications = store.getNotificationsStore().getNotifications();
    return notifications && notifications.length > 0;
  }

  return (
    <Fragment>
      {user && notificationsExist() && (
        <li className="alerts">
          <Link to="/my/alerts" className="icon icon-alert-header" data-testid="alerts-button"></Link>
        </li>
      )}
    </Fragment>
  );
});
