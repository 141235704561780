import { URLBuilder } from "../utils/URLBuilder";
import axios from "axios";
import _ from "underscore";

export const LocalRequestBuilder = {
  doGet: function (path, data) {
    data = data || {};
    return axios.get(URLBuilder.buildLocalUrl(path, data));
  },
  doPost: function (path, data) {
    data = data || {};
    return axios.post(URLBuilder.buildLocalUrl(path), data);
  },
  doUpload: function (path, data, file) {
    data = data || {};
    return axios.put(URLBuilder.buildLocalUrl(path), data, {
      headers: _.extend({
        "Content-Type": undefined,
      }),
      transformRequest: function (data) {
        var fd = new FormData();
        for (var key in data) {
          var val = data[key];
          fd.append(key, val);
        }
        fd.append("file", file, file.name);
        return fd;
      },
    });
  },
};
