import { createContext } from "react";
import { action, observable, runInAction, toJS, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../../js/services/NewRepository";
import { IDialogActionsStore, IDropdownOption, IEntity, ISearchCriteriaHandler } from "../../models/dataModel";
import { RootStore, createStoreContext } from "../../stores/rootStore";
import { checkTagsValidity, isSameArrayContent } from "../../utils/functions";

/**
 * Store used to handle data for search criteria fields
 */
export class SearchCriteriaStore implements IDialogActionsStore, ISearchCriteriaHandler {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Package item object
   */
  @observable private packageItem: IEntity | undefined;

  /**
   * Allow commenting for package
   */
  @observable private allowComments = false;

  /**
   * location restrictons
   */
  @observable private locationRestrictions?: IDropdownOption[];

  /**
   * Supported languages
   */
  @observable private supportedLanguages?: IDropdownOption[];

  /**
   * Measurement units
   */
  @observable private measurementUnits?: IDropdownOption[];

  /**
   * Tags
   */
  @observable private tags?: string[];

  /**
   * Should notify subscribers about changes
   */
  @observable private doNotNotify = false;

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param packageItem Package object
   */
  public constructor(rootStore: RootStore, packageItem?: IEntity) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (packageItem) {
      runInAction(() => {
        this.packageItem = packageItem;
      });
    }
    this.init();
  }

  /**
   * Gets do not notify field value
   * @retuns do not notify value
   */
  public getDoNotNotify(): boolean {
    return this.doNotNotify;
  }

  /**
   * Sets do not notify field value
   * @params doNotNotify new value for do not notify
   */
  @action
  public setDoNotNotify(doNotNotify: boolean) {
    this.doNotNotify = doNotNotify;
  }

  /**
   * Checks if commenting is allowed
   * @returns true if commenting is allowed
   */
  public getAllowCommenting(): boolean {
    return this.allowComments;
  }

  /**
   * Gets supported languages
   * @retuns list of location restrictions
   */
  public getLocationRestrictions(): IDropdownOption[] {
    return toJS(this.locationRestrictions) || [];
  }

  /**
   * Sets location restrictions
   * @params selectedOptions selected location restrictions
   */
  @action
  public setLocationRestrictions(selectedOptions: IDropdownOption[]) {
    this.locationRestrictions = selectedOptions;
  }

  /**
   * Gets supported languages
   * @retuns list supported languages
   */
  public getSupportedLanguages(): IDropdownOption[] {
    return toJS(this.supportedLanguages) || [];
  }

  /**
   * Sets supported languages
   * @params selectedOptions selected supported languages
   */
  @action
  public setSupportedLanguages(selectedOptions: IDropdownOption[]) {
    this.supportedLanguages = selectedOptions;
  }

  /**
   * Gets measurement units
   * @retuns list of measurement units
   */
  public getMeasurementUnits(): IDropdownOption[] {
    return toJS(this.measurementUnits) || [];
  }

  /**
   * Sets supported languages
   * @params selectedOptions selected supported languages
   */
  @action
  public setMeasurementUnits(selectedOptions: IDropdownOption[]) {
    this.measurementUnits = selectedOptions;
  }

  /**
   * Gets tags
   * @return list of tags
   */
  public getTags(): string[] {
    return this.tags || [];
  }

  /**
   * Sets tags
   * @params tags list of tags
   */
  @action
  public setTags(tags: string[]) {
    this.tags = tags;
  }
  /**
   * Checks if form is valid
   * @returns true if form is valid
   */
  public isFormValid() {
    return this.areTagsValid();
  }

  /**
   * Saves allow commenting value
   */
  public async saveAllowCommenting() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user && !!this.packageItem) {
      try {
        await NewRepository.updatePackage({ id: this.packageItem.id, allowComments: !this.allowComments }, user);

        runInAction(() => {
          this.packageItem!.allowComments = !this.allowComments;
        });
        this.showSuccessMessage();
      } catch (error) {
        this.showErrorMessage(error);
      }
    }
  }

  /**
   * Checks if maintenance license exists for package
   * @returns true if maintenance license exists
   */
  public maintenanceLicenseExists(): boolean {
    return (
      !!this.packageItem &&
      !!this.packageItem.attributes &&
      _.contains(this.packageItem.attributes.licensesACL!, "teklamaintenance")
    );
  }

  /**
   * Updates maintenance license status
   * @params licenseSelected true/false if maintenance license should exist for package
   */
  @action
  public async updateLicense(licenseSelected: boolean) {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user && !!this.packageItem) {
      try {
        const packageUpdates = {
          id: this.packageItem.id,
          attributes: {
            ...this.packageItem.attributes,
            licensesACL: licenseSelected && !this.maintenanceLicenseExists() ? ["teklamaintenance"] : [],
          },
        };

        await NewRepository.updatePackage(packageUpdates, user);
        runInAction(() => {
          this.packageItem!.attributes!.licensesACL = packageUpdates.attributes!.licensesACL;
        });
        this.showSuccessMessage();
      } catch (error) {
        this.showErrorMessage(error);
      }
    }
  }

  /**
   * Checks if package is local
   * @returns true if package is local
   */
  public isLocalContent(): boolean {
    return !!this.packageItem && !!this.packageItem.isLocal;
  }

  /**
   * Saves package data
   */
  @action
  public async saveContent() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user && !!this.packageItem) {
      try {
        if (!isSameArrayContent(this.tags, this.packageItem.tags)) {
          const packageUpdates = {
            id: this.packageItem.id,
            tags: this.tags,
            doNotNotify: this.doNotNotify,
          };

          await NewRepository.updatePackage(packageUpdates, user);

          runInAction(() => {
            this.packageItem!.tags = this.tags;
          });
        }

        const locationRestrictions = (this.locationRestrictions || []).map((opt) => opt.value);
        const supportedLanguages = (this.supportedLanguages || []).map((opt) => opt.value);
        const measurementUnits = (this.measurementUnits || []).map((opt) => opt.value);

        if (
          !isSameArrayContent(locationRestrictions, this.packageItem.attributes!.locationRestrictions) ||
          !isSameArrayContent(supportedLanguages, this.packageItem.attributes!.supportedLanguages) ||
          !isSameArrayContent(measurementUnits, this.packageItem.attributes!.measurementUnits)
        ) {
          const attributes = {
            ...(!isSameArrayContent(locationRestrictions, this.packageItem.attributes!.locationRestrictions) && {
              locationRestrictions: locationRestrictions,
            }),
            ...(!isSameArrayContent(supportedLanguages, this.packageItem.attributes!.supportedLanguages) && {
              supportedLanguages: supportedLanguages,
            }),
            ...(!isSameArrayContent(measurementUnits, this.packageItem.attributes!.measurementUnits) && {
              measurementUnits: measurementUnits,
            }),
          };

          const removedAttributes = {
            locationRestrictions: _.difference(
              this.packageItem.attributes!.locationRestrictions || [],
              locationRestrictions,
            ),
            supportedLanguages: _.difference(this.packageItem.attributes!.supportedLanguages || [], supportedLanguages),
            measurementUnits: _.difference(this.packageItem.attributes!.measurementUnits || [], measurementUnits),
          };

          await NewRepository.setContentAttribute(
            this.packageItem,
            attributes,
            removedAttributes,
            this.doNotNotify,
            user.id,
          );

          runInAction(() => {
            this.packageItem!.attributes!.locationRestrictions = locationRestrictions;
            this.packageItem!.attributes!.supportedLanguages = supportedLanguages;
            this.packageItem!.attributes!.measurementUnits = measurementUnits;
          });
        }

        this.showSuccessMessage();
      } catch (error) {
        this.showErrorMessage(error);
      }
    }
  }

  /**
   * Checks the validity of tags.
   * @returns true if tags are valid
   */
  public areTagsValid(): boolean {
    return !this.tagsExist() ? true : checkTagsValidity(this.tags!.slice());
  }

  /**
   * Initializes store fields
   */
  @action
  public init() {
    if (this.packageItem) {
      this.allowComments = !!this.packageItem.allowComments;
      this.setSupportedLanguages(
        _.map(this.packageItem.attributes!.supportedLanguages!, (key) => {
          return {
            label: this.rootStore.getTranslation("supportedLanguages." + key),
            value: key,
          };
        }),
      );
      this.setLocationRestrictions(
        _.map(this.packageItem.attributes!.locationRestrictions!, (key) => {
          return {
            label: this.rootStore.getTranslation("constants.countries." + key),
            value: key,
          };
        }),
      );
      this.setMeasurementUnits(
        _.map(this.packageItem.attributes!.measurementUnits!, (key) => {
          return {
            label: this.rootStore.getTranslation("measurementUnits." + key),
            value: key,
          };
        }),
      );
      this.tags = this.packageItem.tags;
    }
  }

  private tagsExist() {
    return this.tags && this.tags.length > 0;
  }

  private showSuccessMessage() {
    this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("details.updated_successfully"));
  }

  private showErrorMessage(error) {
    let errorMsg = "";
    if (error.key === "api.errors.tcc.INVALID_TAG") {
      errorMsg = this.rootStore.getTranslation("details.update_failed_invalid_tag");
    } else {
      errorMsg = this.rootStore.getTranslation("details.update_failed");
    }

    this.rootStore.getNotificationChannelStore().error(errorMsg);
  }
}

/**
 * Context object for SearchCriteriaStore instances.
 */
export const SearchCriteriaStoreContext = createStoreContext<SearchCriteriaStore>(SearchCriteriaStore);

export const SearchCriteriaHandlerContext = createContext<ISearchCriteriaHandler>(
  new SearchCriteriaStore(new RootStore()),
);
