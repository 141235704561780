import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ModusTextInput } from "@trimble-oss/modus-react-components";
import { ModusTextInputCustomEvent } from "@trimble-oss/modus-web-components/loader";

import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const CreatorFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleCreatorChange(event: ModusTextInputCustomEvent<string>) {
    !!event.detail ? store.setCreatorDisplayName(event.detail) : store.setCreatorDisplayName(undefined);
  }

  return (
    <div className="search-filter" data-testid="creatorNameFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.creator")}:</h5>
      <ModusTextInput
        placeholder={rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.creator")}
        value={store.getCreatorDisplayName()}
        clearable={true}
        onValueChange={handleCreatorChange}
        data-testid="search-item-creator"
      ></ModusTextInput>
    </div>
  );
});
