import * as React from "react";
import { IDropdownOption, IDropdownOnChange, IDropdownOptionGroup } from "../models/dataModel";
import Select, { components } from "react-select";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="12" height="12" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="none"
          stroke="#1b1a26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m2 5 6 6 6-6"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

/**
 * A dropdown component to replace Modus select
 * @param options list of available options
 * @param selectedValue the selected option (optional)
 * @param placeholder placeholder string (optional)
 * @param onChange the onChange function
 * @param className className that determines dropdown style
 * @param inputId id of the dropdown, used to create testId (optional)
 * @param menuHeight maximum height of the menu before scrolling (optional)
 */
export const ModusDropdown = ({
  ...props
}: {
  options: IDropdownOption[] | IDropdownOptionGroup[];
  selectedValue?: IDropdownOption;
  placeholder?: string;
  onChange: IDropdownOnChange;
  className: string;
  inputId?: string;
  menuHeight?: number;
}) => {
  const testId = props.inputId ? `dropdown-${props.inputId}` : "dropdown";
  const maxMenuHeight = props.menuHeight ? props.menuHeight : 300;

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      position: "relative",
      alignItems: "center",
      flexWrap: "nowrap",
      backgroundColor: "#fff",
      backgroundPosition: "right 0.5rem center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "16px 12px",
      border: "0",
      borderBottom: state.isFocused ? "solid 0.0625rem #217cbb" : "solid 0.0625rem #6a6e79",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "#217cbb 0 0.0625rem;" : "none",
      boxSizing: "content-box",
      color: "#464b52",
      cursor: "pointer",
      fontFamily: "Open Sans",
      fontSize: "12px",
      minHeight: "2rem",
      height: "fit-content",
      minWidth: "100%",
      maxWidth: "min-content",
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      display: "flex",
      flexWrap: "wrap",
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      fontFamily: "Open Sans",
      fontSize: "12px",
      marginTop: "0.0625rem",
      borderRadius: "8px",
      overflow: "hidden",
      padding: "0",
      zIndex: "2",
    }),
    option: (baseStyles, state) => {
      let backgroundColor = "#fff";
      let color = "black";
      if (state.isSelected || state.isFocused) {
        color = "white";
        backgroundColor = "#217cbb";
      }
      return {
        ...baseStyles,
        backgroundColor,
        color,
      };
    },
  };
  return (
    <div className={props.className} data-testid={testId}>
      <Select
        components={{ DropdownIndicator, IndicatorSeparator: () => null, CrossIcon: () => null }}
        styles={customStyles}
        options={props.options}
        value={props.selectedValue}
        onChange={props.onChange}
        placeholder={props.placeholder}
        inputId={props.inputId}
        maxMenuHeight={maxMenuHeight}
      />
    </div>
  );
};
