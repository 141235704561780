import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Chart } from "react-google-charts";

import { RootContext } from "../../stores/rootStore";
import { DialogStore, DialogContext } from "../../dialogs/dialogStore";
import { AnalyticsStoreContext, AnalyticsFiltersSetterContext } from "./analyticsStore";
import { IAnalyticsData } from "../../models/dataModel";
import { AnalyticsEventTypeEnum } from "../../models/enums";

import { EventTypeFilter } from "./EventTypeFilter";
import { TimeFrameFilter } from "./TimeFrameFilter";
import { ExportAnalytics } from "../../dialogs/export-analytics/ExportAnalytics";

/**
 * A component that renders info texts
 */
export const Info = observer(() => {
  const rootStore = useContext(RootContext);
  const analyticsStore = useContext(AnalyticsStoreContext);

  return (
    <div className="recording-info">
      {analyticsStore.getEventType() === AnalyticsEventTypeEnum.DOWNLOADS && (
        <div data-testid="recording-info-download" className="recording-info-downloads">
          <span className="small">
            {rootStore.getTranslation("shared.analytics.recording_started_at")}
            <br />
            {rootStore.getTranslation("shared.analytics.counted_events_info_package")}
          </span>
        </div>
      )}
      {analyticsStore.getEventType() === AnalyticsEventTypeEnum.VIEWS && (
        <div data-testid="recording-info-view" className="recording-info-views">
          <span className="small">{rootStore.getTranslation("shared.analytics.recording_view_events_started_at")}</span>
        </div>
      )}
      {analyticsStore.getEventType() === AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK && (
        <div data-testid="recording-info-partnerLink" className="recording-info-partnerLink">
          <span className="small">{rootStore.getTranslation("shared.analytics.recording_view_events_started_at")}</span>
        </div>
      )}
    </div>
  );
});

/**
 * A component that renders various filters
 */
export const Filters = observer(() => {
  const analyticsStore = useContext(AnalyticsStoreContext);

  return (
    <div className="controls">
      <AnalyticsFiltersSetterContext.Provider value={analyticsStore}>
        <div className="row">
          <Info />
          <DialogContext.Provider value={new DialogStore()}>
            <ExportAnalytics />
          </DialogContext.Provider>
          <EventTypeFilter />
        </div>
        <div className="row">
          <TimeFrameFilter />
        </div>
      </AnalyticsFiltersSetterContext.Provider>
    </div>
  );
});

/**
 * A component that renders line chart
 */
export const LineChart = observer(() => {
  const analyticsStore = useContext(AnalyticsStoreContext);

  return (
    <div className="chart-row">
      <div className={"chart-box wide"}>
        <div className="wrapper">
          <h4>{analyticsStore.getLineChartTitle()}</h4>
          <div data-testid="line-chart" className="line-chart">
            <li>
              <Chart
                chartType="LineChart"
                width="100%"
                height="300px"
                options={analyticsStore.getLineChartOptions()}
                data={analyticsStore.getLineChartData()}
              />
            </li>
          </div>
        </div>
      </div>
    </div>
  );
});

/**
 * A component that renders geo chart
 */
export const GeoChart = observer(() => {
  const analyticsStore = useContext(AnalyticsStoreContext);

  return (
    <div className="chart-row">
      <div className={"chart-box wide"}>
        <div className="wrapper">
          <h4>{analyticsStore.getGeoChartTitle()}</h4>
          <div data-testid="geo-chart" className="geo-chart">
            <li>
              <Chart
                chartType="GeoChart"
                width="100%"
                height="300px"
                options={analyticsStore.getGeoChartOptions()}
                data={analyticsStore.getGeoChartData()}
              />
            </li>
          </div>
        </div>
      </div>
    </div>
  );
});

/**
 * A component that renders pie chart
 */
export const PieChart = observer(
  ({ title, data, testId }: { title: string; data: IAnalyticsData[]; testId: string }) => {
    const analyticsStore = useContext(AnalyticsStoreContext);

    return (
      <div className={"chart-box"}>
        <div className="wrapper">
          <h4>{title}</h4>
          <div data-testid={testId} className="pie-chart">
            <li>
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                options={analyticsStore.getPieChartOptions()}
                data={data}
              />
            </li>
          </div>
        </div>
      </div>
    );
  },
);

/**
 * A component that renders table chart
 */
export const TableChart = observer(
  ({ additionalClass, wideMode }: { additionalClass?: string; wideMode?: boolean }) => {
    const analyticsStore = useContext(AnalyticsStoreContext);

    function getClassName(type) {
      return type === "HEADER" ? "header" : "summary";
    }

    return (
      <div className="chart-row">
        <div className={classNames("chart-box", { wide: wideMode })}>
          <div className="wrapper extra-margin">
            <h4>{analyticsStore.getTableChartTitle()}</h4>
            <div data-testid="table-chart" className={classNames("table-chart", additionalClass)}>
              {analyticsStore.getTableChartData().map((tableRow, i) => {
                return (
                  <div key={i} className={getClassName(tableRow.type)}>
                    <div className="value">{tableRow.value}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

/**
 * A component that renders analytics view charts
 */
export const ChartArea = observer(() => {
  const analyticsStore = useContext(AnalyticsStoreContext);

  function showPackagesViews() {
    return analyticsStore.isPackageLevel() && analyticsStore.getEventType() === AnalyticsEventTypeEnum.VIEWS;
  }

  function showPartnerDownloadClicks(): boolean {
    return (
      analyticsStore.isPackageLevel() &&
      analyticsStore.getEventType() === AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK
    );
  }

  return (
    <Fragment>
      {analyticsStore.isLoading() ? (
        <div className="processing spinner spinner-gray" data-testid="loadingAnalytics"></div>
      ) : (
        <div className="charts" data-testid="chart">
          <LineChart />
          <GeoChart />
          <Fragment>
            {showPackagesViews() ? (
              <div className="chart-row">
                <PieChart
                  title={analyticsStore.getGeoChartTitle()}
                  data={analyticsStore.getGeoChartData()}
                  testId="pie-country-chart"
                />
                <PieChart
                  title={analyticsStore.getPieChartByOrgTitle()}
                  data={analyticsStore.getPieChartByOrgData()}
                  testId="pie-org-chart"
                />
              </div>
            ) : (
              <Fragment>
                {showPartnerDownloadClicks() ? (
                  <div className="chart-row">
                    <PieChart
                      title={analyticsStore.getGeoChartTitle()}
                      data={analyticsStore.getGeoChartData()}
                      testId="pie-country-chart"
                    />
                    <PieChart
                      title={analyticsStore.getPieChartByOrgTitle()}
                      data={analyticsStore.getPieChartByOrgData()}
                      testId="pie-org-chart"
                    />
                  </div>
                ) : (
                  <Fragment>
                    <div className="chart-row">
                      <PieChart
                        title={analyticsStore.getPieChartTitle()}
                        data={analyticsStore.getPieChartData()}
                        testId="pie-chart"
                      />
                      <PieChart
                        title={analyticsStore.getGeoChartTitle()}
                        data={analyticsStore.getGeoChartData()}
                        testId="pie-country-chart"
                      />
                    </div>
                    <div className="chart-row">
                      {analyticsStore.isOrganizationLevel() && (
                        <TableChart additionalClass="org-packages" wideMode={true} />
                      )}
                      {analyticsStore.isPackageLevel() && (
                        <Fragment>
                          <TableChart />
                          <PieChart
                            title={analyticsStore.getPieChartByOrgTitle()}
                            data={analyticsStore.getPieChartByOrgData()}
                            testId="pie-org-chart"
                          />
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that renders analytics view
 */
export const Analytics = observer(() => {
  return (
    <div className="analytics">
      <Filters />
      <ChartArea />
    </div>
  );
});
