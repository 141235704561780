import _ from "underscore";
import { TCCVersionService } from "../services/TCCVersionService";
import { ContentTypeEnum, ObjectTypeEnum } from "../../models/enums";

function searchVersions(data) {
  data = data || {};
  _.defaults(data, {
    offset: 0,
    count: 1000,
    showBinaryMetadata: true,
    sortBy: "title ASC",
    contentType: ContentTypeEnum.TEKLA_WAREHOUSE,
  });
  data.fq = "parentCollectionId==" + data.parentCollectionId;
  var contentTypeFiql;
  contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION + ")";
  data.fq = data.fq + ";" + contentTypeFiql;
  return TCCVersionService.searchEntities(data).then(function (res) {
    var promises = _.map(res, function (entry) {
      return TCCVersionService.get(entry.id);
    });
    return Promise.all(promises);
  });
}

export const TCCLongRunningActionsService = {
  setVisibilityInChildren: function (resource, overrideChildVisibility) {
    return new Promise((resolve) => {
      resource = resource || {};
      if (resource.type == ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
        return searchVersions({ parentCollectionId: resource.id }).then(function (versions) {
          var promises = _.map(versions, function (version) {
            if (overrideChildVisibility) {
              version.visibility = resource.visibility;
              version.isHidden = resource.isHidden;
              version.isBanned = resource.isBanned;
              
              return TCCVersionService.update(version);
            } else {
              if (!version.isBanned && !version.isHidden) {
                version.visibility = resource.visibility;
                version.isHidden = resource.isHidden;
                version.isBanned = resource.isBanned;
              
                return TCCVersionService.update(version);
              }
            }
          });
          Promise.all(promises).then(function (res) {
            resolve(resource);
          });
        });
      } else {
        resolve(resource);
      }
    });
  },
};
