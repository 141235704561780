import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";

export const TCCJobsService = {
  getJob: function (jobId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/jobs/" + jobId));
  },
  createJob: function (data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPostWithParams("/warehouse/v1.0/jobs", data, { performAs: performAsId }),
    );
  },
  updateJob: function (jobId, data) {
    return ResponseProcessor.process(RequestBuilder.doPut("/warehouse/v1.0/jobs/" + jobId, data));
  },
};
