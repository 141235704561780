import * as React from "react";
import { useContext, useState, Fragment } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { IDropdownOption, IOrganization, IUser, ICollection, ITreeViewCollection } from "../../../models/dataModel";

import { RootStore, RootContext } from "../../../stores/rootStore";
import { AdminStore, AdminStoreContext } from "../adminStore";
import { DialogStore, DialogContext } from "../../../dialogs/dialogStore";
import { Dropdown } from "../../../components/Dropdown";
import { ConfirmAction } from "../../../dialogs/ConfirmAction";
import { SearchForm } from "./SearchForm";
import { ItemsList } from "./ItemsList";
import { CollectionListItem } from "./CollectionListItem";

/**
 * Renders the 'Move content' section.
 */
export const MoveContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: AdminStore = useContext(AdminStoreContext);
  const confirmDialog: DialogStore = new DialogStore();

  const [isLoadingTargets, setIsLoadingTargets] = useState<boolean>(false);
  const [isLoadingSources, setIsLoadingSources] = useState<boolean>(false);
  const [transfering, setTransfering] = useState<boolean>(false);

  const [sourceCandidates, setSourceCandidates] = useState<Array<IUser | IOrganization>>([]);
  const [selectedSource, setSelectedSource] = useState<IUser | IOrganization>();
  const [sourceCollections, setSourceCollections] = useState<ITreeViewCollection[]>([]);

  const [targetCandidates, setTargetCandidates] = useState<Array<IUser | IOrganization>>([]);
  const [selectedTarget, setSelectedTarget] = useState<IUser | IOrganization>();
  const [targetCollections, setTargetCollections] = useState<ICollection[]>([]);
  const [selectedTargetCollectionOption, setSelectedTargetCollectionOption] = useState<IDropdownOption>();

  const queryingSourceCollections = store.isQueryingSourceCollections();
  const queryingTargetCollections = store.isQueryingTargetCollections();

  const showCollectionsForm = !queryingTargetCollections && targetCollections.length > 0;

  async function searchSources(searchTerm: string) {
    setIsLoadingSources(true);

    try {
      const res = await store.searchOrganizationsAndUsers(searchTerm);
      res.entries = store.filterItemFromList(res.entries, selectedTarget);

      if (res.entries && res.entries.length === 1) {
        await selectSource(res.entries[0]);
      } else {
        setSourceCandidates(res.entries);
      }
    } finally {
      setIsLoadingSources(false);
    }
  }

  async function selectSource(source) {
    setSelectedSource(source);
    setSourceCandidates([]);
    setSourceCollections(await store.fetchCollectionsAndPackages(source));
  }

  async function searchTargets(searchTerm: string) {
    setIsLoadingTargets(true);

    try {
      const res = await store.searchOrganizationsAndUsers(searchTerm);
      res.entries = store.filterItemFromList(res.entries, selectedSource);

      if (res.entries && res.entries.length === 1) {
        await selectTarget(res.entries[0]);
      } else {
        setTargetCandidates(res.entries);
      }
    } finally {
      setIsLoadingTargets(false);
    }
  }

  async function selectTarget(target) {
    setSelectedTarget(target);
    setTargetCandidates([]);
    setTargetCollections(await store.fetchCollectionsForTarget(target));
  }

  function handleSelectTargetCollection(option: IDropdownOption | null) {
    if (!option) return;
    setSelectedTargetCollectionOption(option);
  }

  const targetCollectionOptions: IDropdownOption[] = _.map(targetCollections, (collection: ICollection) => {
    return { value: collection.id!, label: collection.title! };
  });

  function isContentSelectionValid() {
    if (!selectedSource || !selectedTarget) {
      return false;
    }
    if (selectedSource.id === selectedTarget.id) {
      return false;
    }
    if (store.packagesSelectedWithoutCollection(sourceCollections)) {
      if (!selectedTargetCollectionOption) {
        return false;
      }
    } else if (!store.collectionsAreSelected(sourceCollections)) {
      return false;
    }
    return true;
  }

  async function migrateContent() {
    const targetCollection = _.find(targetCollections, (collection) => {
      return collection.id === selectedTargetCollectionOption!.value;
    });

    setTransfering(true);
    const res = await store.migrateContent(sourceCollections, targetCollection!, selectedSource!, selectedTarget!);
    setSourceCollections(res.sourceCollections);
    setTargetCollections(res.targetCollections);
    setTransfering(false);
  }

  function renderMigrateFrom() {
    return (
      <div className="migrate-from">
        <div className="title">
          <label>{rootStore.getTranslation("profile.admin.migrate.migrate_from")}</label>
        </div>
        <div className="search">
          <SearchForm
            placeholder={rootStore.getTranslation("shared.access_rights.write_id_placeholder")}
            performSearch={searchSources}
            testId={"sourceSearch"}
          />
          {isLoadingSources ? (
            <div className="loading">
              <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_loading")}</div>
            </div>
          ) : (
            <ItemsList itemCandidates={sourceCandidates} selectedItem={selectedSource} selectItem={selectSource} />
          )}
          <Fragment>
            {queryingSourceCollections ? (
              <label className="spinner spinner-gray">
                {rootStore.getTranslation("shared.transfer_package.loading")}
              </label>
            ) : (
              <Fragment>
                {sourceCollections.map((collection: ITreeViewCollection, i: number) => {
                  return (
                    <div className="collection-list" key={i}>
                      <CollectionListItem collection={collection} i={i} />
                    </div>
                  );
                })}
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    );
  }

  function renderMigrateTo() {
    return (
      <div className="migrate-to">
        <div className="title">
          <label>{rootStore.getTranslation("profile.admin.migrate.migrate_to")}</label>
        </div>
        <div className="search">
          <SearchForm
            placeholder={rootStore.getTranslation("shared.access_rights.write_id_or_name_placeholder")}
            performSearch={searchTargets}
            testId={"targetSearch"}
          />
          {isLoadingTargets ? (
            <div className="loading">
              <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_loading")}</div>
            </div>
          ) : (
            <ItemsList itemCandidates={targetCandidates} selectedItem={selectedTarget} selectItem={selectTarget} />
          )}
          {queryingTargetCollections && (
            <label className="spinner spinner-gray">
              {rootStore.getTranslation("shared.transfer_package.loading")}
            </label>
          )}
          {showCollectionsForm && (
            <form className="collections-form" name="selectCollectionForm" data-testid="collectionsDropdown" noValidate>
              {transfering ? (
                <label className="spinner spinner-gray" data-testid="spinner">
                  {rootStore.getTranslation("shared.transfer_package.moving")}
                </label>
              ) : (
                <Dropdown
                  className={"dropdown-wrapper gray"}
                  selectedValue={selectedTargetCollectionOption}
                  options={targetCollectionOptions}
                  onChange={handleSelectTargetCollection}
                  placeholder={rootStore.getTranslation("shared.transfer_package.select_collection")}
                />
              )}
            </form>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="migrate-content" data-testid="moveContentComponent">
      <div className="admin-header">{rootStore.getTranslation("profile.admin.migrate.title")}</div>
      <div className="migrate-options">
        {renderMigrateFrom()}
        <div className="migrate-action">
          <DialogContext.Provider value={confirmDialog}>
            <button
              className="button-primary button-migrate"
              onClick={() => confirmDialog.open()}
              disabled={!isContentSelectionValid()}
              data-testid="moveContentButton"
            >
              {rootStore.getTranslation("profile.admin.migrate.do_migration")}
            </button>
            <ConfirmAction
              content={rootStore.getTranslation("profile.admin.migrate.confirm")}
              callbackCloseDialog={() => {
                confirmDialog.close();
              }}
              callbackConfirm={async () => {
                await migrateContent();
              }}
            />
          </DialogContext.Provider>
        </div>
        {renderMigrateTo()}
      </div>
    </div>
  );
});
