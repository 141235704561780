import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";

/**
 * A component that displays footer.
 */
export const Footer = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <Fragment>
      <div
        id="trimble-common-footer"
        data-language={rootStore.getLocalizationStore().getLangForSelectedLocale()}
        data-testid="commonFooterAnchor"
      />
    </Fragment>
  );
});
