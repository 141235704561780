import _ from "underscore";

export function ClientSideSearch(f) {
  var filters = f || [];

  function search(resources) {
    resources = resources || [];
    return _.filter(resources, function (resource) {
      return _.all(filters, function (filter) {
        return filter(resource);
      });
    });
  }

  return {
    search: search,
  };
}
