import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext, RootStore } from "../stores/rootStore";
import { ISearchCriteriaHandler, IDropdownOption } from "../models/dataModel";
import { DropdownMultiple } from "./DropdownMultiple";
import { SearchCriteriaHandlerContext } from "../package/tabs/searchCriteriaStore";
import { metadataLabels } from "../utils/MetadataLabels";

/** Component for the Measurement units dropdown */
export const MeasurementUnits = observer(({ hideLabel }: { hideLabel?: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ISearchCriteriaHandler = useContext(SearchCriteriaHandlerContext);

  const measurementUnitOptions: IDropdownOption[] = _.map(metadataLabels.measurementUnitOptions, (option) => {
    return {
      value: option.value,
      label: rootStore.getTranslation(option.name),
    };
  });
  function handleSelectMeasurementUnits(selected: readonly IDropdownOption[]) {
    if (!selected) return;
    store.setMeasurementUnits(selected);
  }

  return (
    <li>
      {!hideLabel && <label>{rootStore.getTranslation("upload.measurementUnits")}</label>}
      <DropdownMultiple
        options={measurementUnitOptions}
        selectedValues={store.getMeasurementUnits()}
        onChange={handleSelectMeasurementUnits}
        className="dropdown-wrapper gray"
        inputId="measurementUnits"
      />
    </li>
  );
});
