import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../uploadFormStore";
import { GroupAndCategoryHandlerContext } from "../../package/tabs/groupAndCategoryStore";
import { SearchCriteriaHandlerContext } from "../../package/tabs/searchCriteriaStore";

import { ItemTypeCategories } from "../../components/ItemTypeCategories";
import { UseCategories } from "../../components/UseCategories";
import { Tags } from "../../components/Tags";
import { LocationRestrictions } from "../../components/LocationRestrictions";
import { LanguageOptions } from "../../components/LanguageOptions";
import { MeasurementUnits } from "../../components/MeasurementUnits";

/**
 * Main component for rendering the SearchCriteria column of the Upload content form.
 */
export const SearchCriteria = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity = form.getEntityStore();

  return (
    <div className="column" data-testid="searchCriteria">
      <h3>{rootStore.getTranslation("upload.package.search_criteria")}</h3>
      <ol>
        <GroupAndCategoryHandlerContext.Provider value={entity}>
          <ItemTypeCategories />
          <UseCategories />
        </GroupAndCategoryHandlerContext.Provider>
        <SearchCriteriaHandlerContext.Provider value={entity}>
          <Tags />
          <LocationRestrictions />
          <LanguageOptions />
          <MeasurementUnits />
        </SearchCriteriaHandlerContext.Provider>
      </ol>
    </div>
  );
});
