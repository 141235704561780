import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";

import { EditorModeStore, EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { RootContext, RootStore } from "../../stores/rootStore";
import { CollectionPageStore, CollectionPageStoreContext } from "../collectionPageStore";

import { ExternalResourceTypeEnum } from "../../models/enums";
import { organizationIsImmutable } from "../../utils/Immutability";
import { Dialog } from "../../dialogs/Dialog";
import { EditTitleDialog } from "../../dialogs/edit/EditTitleDialog";

/**
 * Renders the collection title section
 */
export const CollectionTitle = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const collectionPageStore: CollectionPageStore = useContext(CollectionPageStoreContext);
  const dialog: DialogStore = new DialogStore();
  const collection = collectionPageStore.getCollection();

  const [createdByImmutableOrganization, setCreatedByImmutableOrganization] = useState(false);

  useEffect(() => {
    async function checkOrganizationImmutability() {
      if (collection?.creator?.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION) {
        const isImmutable = await organizationIsImmutable(collection?.creator?.id || "");
        setCreatedByImmutableOrganization(isImmutable);
      }
    }

    checkOrganizationImmutability();
  }, [collection]);

  function canEdit() {
    return collectionPageStore.canEditCollection() && editorModeStore.getEditorMode();
  }

  function openEditDialog() {
    dialog.open();
  }

  function isContentPrivate(): boolean {
    return !!collection && collection.visibility === "private";
  }

  const editCollectionTitleButtonText = createdByImmutableOrganization
    ? rootStore.getTranslation("immutability.edit_title_or_archive")
    : rootStore.getTranslation("shared.catalog_entity_edit.edit_title_or_delete");

  return (
    <Fragment>
      {canEdit() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-title-dialog"
            title={editCollectionTitleButtonText}
            onClick={openEditDialog}
            className="icon icon-pencil"
          >
            <span>{editCollectionTitleButtonText}</span>
          </a>
          <Dialog
            content={
              <EditTitleDialog
                item={collection!}
                store={collectionPageStore}
                showArchive={createdByImmutableOrganization}
              />
            }
          />
        </DialogContext.Provider>
      )}
      <h1>
        <div
          data-testid="collection-title"
          className={classNames("icon", {
            private: isContentPrivate(),
            "icon-drawer": !isContentPrivate(),
          })}
        />
        <span>{collectionPageStore.getTitle()}</span>
      </h1>
    </Fragment>
  );
});
