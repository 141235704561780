import _ from "underscore";
import { action, runInAction, observable, makeObservable } from "mobx";

import { TCCSearchService } from "../../../js/services/TCCSearchService";

import { createStoreContext, RootStore } from "../../../stores/rootStore";
import { CollectionsStore } from "../../../stores/collectionsStore";

import { ICollection, ICollectionSearchQuery, IUser, IUserOrganization } from "../../../models/dataModel";
import { ObjectTypeEnum } from "../../../models/enums";

export class OnlineCollectionsStore extends CollectionsStore {
  /**
   * The current user.
   */
  private user?: IUser;

  /**
   * Collections created by the user.
   */
  @observable protected myCollections: ICollection[] = [];

  /**
   * Collections created by the user's organization.
   */
  @observable protected myOrganizationCollections?: Array<{ organization: string; collections: ICollection[] }>;

  /**
   * Collections shared with the user or their organization.
   */
  @observable protected mySharedCollections: ICollection[] = [];

  /**
   * Represents the loading state of different collections in the onlineCollectionsStore.
   * True if the collections have been fetched, false otherwise.
   */
  @observable protected loadingStateMap = {
    myCollections: false,
    myOrganizationCollections: false,
    mySharedCollections: false,
  };

  /**
   * Constructs a new instance of the OnlineCollectionsStore class.
   * @param {RootStore} rootStore The root store instance.
   */
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Gets collections created by the user.
   */
  public getMyCollections(): ICollection[] {
    return this.myCollections;
  }

  /**
   * Gets collections created by the user's organization.
   */
  public getMyOrganizationCollections(): Array<{ organization: string; collections: ICollection[] }> {
    return this.myOrganizationCollections || [];
  }

  /**
   * Gets collections shared with the user or their organization.
   */
  public getMySharedCollections(): ICollection[] {
    return this.mySharedCollections;
  }

  /**
   * Returns true if finished loadMyCollections.
   */
  public wereMyCollectionsFetched(): boolean {
    return this.loadingStateMap.myCollections;
  }

  /**
   * Returns true if finished loadMyOrganizationCollections.
   */
  public wereMyOrganizationCollectionsFetched(): boolean {
    return this.loadingStateMap.myOrganizationCollections;
  }

  /**
   * Returns true if finished loadMySharedCollections.
   */
  public wereSharedCollectionsFetched(): boolean {
    return this.loadingStateMap.mySharedCollections;
  }

  /**
   * Function that fetches the collection data using 'TCCSearchService'.
   * Called from OnlineCollections when the component is rendered.
   */
  @action
  public async fetchData(): Promise<void> {
    this.loadUser();

    this.loadingStateMap.myCollections = false;
    this.loadingStateMap.myOrganizationCollections = false;
    this.loadingStateMap.mySharedCollections = false;

    const hasOrganizations = !!this.user && this.user.userOrganizations && this.user.userOrganizations.length > 0;

    if (this.user) this.loadMyCollections();
    if (this.user && hasOrganizations) this.loadMyOrganizationCollections();
    if (this.user) this.loadMySharedCollections();
  }

  @action
  private async loadMyCollections(): Promise<void> {
    if (!this.user) return;

    const queryParams: ICollectionSearchQuery = {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      sortBy: "title ASC",
      fq: `creator.id==${this.user?.id}`,
    };

    try {
      const pkg = await TCCSearchService.searchCollections(
        queryParams,
        true,
        ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
        this.user?.id,
      );
      const myCollections = this.setPaths(pkg);

      runInAction(() => {
        this.myCollections = myCollections;
        this.loadingStateMap.myCollections = true;
      });
    } catch {
      console.log("Could not load my collections");
    }
  }

  @action
  private async loadMyOrganizationCollections() {
    if (!this.user) return;

    const queryParams: ICollectionSearchQuery = {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      sortBy: "title ASC",
      fq: "",
    };

    if (this.user?.userOrganizations) {
      queryParams.fq =
        "creator.id=in=(" +
        _.map(this.user?.userOrganizations, (uo: IUserOrganization) => `${uo.organization.id}`).toString() +
        ")";
    }

    try {
      const pkg = await TCCSearchService.searchCollections(
        queryParams,
        true,
        ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
        this.user?.id,
      );
      const myOrganizationCollections = this.setPaths(pkg);

      const groupedCollections: Record<string, ICollection[]> = _.groupBy(
        myOrganizationCollections.flat(),
        (collection: ICollection) => collection.creator!.displayName,
      ) as Record<string, ICollection[]>;

      const orderedCollections = Object.keys(groupedCollections)
        .sort()
        .map((key) => {
          return { organization: key, collections: groupedCollections[key] };
        });

      runInAction(() => {
        this.myOrganizationCollections = orderedCollections;
        this.loadingStateMap.myOrganizationCollections = true;
      });
    } catch {
      console.log("Could not load my organization collections");
    }
  }

  @action
  private async loadMySharedCollections() {
    if (!this.user) return;

    const queryParams: ICollectionSearchQuery = {
      attribute: JSON.stringify([
        {
          category: "references",
          name: "linkedToCollectionId",
          operator: "NOT EXISTS",
          value: "true",
        },
      ]),
      offset: 0,
      count: 200,
      show: "private",
      showBinaryMetadata: true,
      sortBy: "title ASC",
      fq: ``,
    };

    if (this.user?.userOrganizations) {
      const orgCreators = _.map(this.user?.userOrganizations, (uo: IUserOrganization) => {
        return `;creatorId!=${uo.organization.id}`;
      });

      queryParams.fq = `creatorId!=${this.user?.id}` + orgCreators.toString().replace(/,/g, "");
    }

    try {
      const pkg = await TCCSearchService.searchCollections(
        queryParams,
        true,
        ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
        this.user.id,
      );
      const mySharedCollections = this.setPaths(pkg);

      runInAction(() => {
        this.mySharedCollections = mySharedCollections;
        this.loadingStateMap.mySharedCollections = true;
      });
    } catch {
      console.log("Could not load shared collections");
    }
  }

  @action
  private loadUser() {
    this.user = this.rootStore.getUserStore().getCurrentUser();
  }
}

/**
 * Context object for CollectionsStore instances.
 */
export const OnlineCollectionsContext = createStoreContext<OnlineCollectionsStore>(OnlineCollectionsStore);
