var onLine = window.navigator.onLine;

window.addEventListener(
  "online",
  function () {
    onLine = true;
  },
  { passive: true },
);

window.addEventListener(
  "offline",
  function () {
    onLine = false;
  },
  { passive: true },
);

export const OnlineStatus = {
  isOnline: function (data) {
    return onLine;
  },
};
