import * as React from "react";
import Select, { components } from "react-select";
import { IDropdownOption, IDropdownOptionGroup, IMultiDropdownOnChange } from "../models/dataModel";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg width="12" height="12" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="none"
          stroke="#1b1a26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m2 5 6 6 6-6"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

/**
 * A dropdown component that supports selecting multiple options (styled mimicking Modus).
 * @param options list of available options
 * @param selectedValues list of selected options (optional)
 * @param placeholder placeholder string (optional)
 * @param onChange the onChange function
 * @param className className that determines dropdown style
 * @param inputId id of the dropdown, used to create testId (optional)
 * @param styles custom styles (optional)
 * @param components replacement components (optional)
 * @param isSearchable wheter user can type in the input box (optional)
 */
export const ModusDropdownMultiple = ({
  ...props
}: {
  options: Array<IDropdownOption | IDropdownOptionGroup>;
  selectedValues?: readonly IDropdownOption[];
  placeholder?: string;
  onChange: IMultiDropdownOnChange;
  className: string;
  inputId?: string;
  menuHeight?: number;
  isSearchable?: boolean;
}) => {
  const testId = props.inputId ? `dropdown-${props.inputId}` : "dropdown";
  const maxMenuHeight = props.menuHeight ? props.menuHeight : 300;

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      position: "relative",
      alignItems: "center",
      flexWrap: "nowrap",
      backgroundColor: "#fff",
      backgroundPosition: "right 0.5rem center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "16px 12px",
      border: "0",
      borderBottom: state.isFocused ? "solid 0.0625rem #217cbb" : "solid 0.0625rem #6a6e79",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "#217cbb 0 0.0625rem;" : "none",
      boxSizing: "content-box",
      color: "#464b52",
      cursor: "pointer",
      fontFamily: "Open Sans",
      fontSize: "12px",
      minHeight: "2rem",
      height: "fit-content",
      minWidth: "100%",
      maxWidth: "min-content",
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      display: "flex",
      flexWrap: "wrap",
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      fontSize: "14px",
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      fontFamily: "Open Sans",
      fontSize: "12px",
      marginTop: "0.0625rem",
      borderRadius: "8px",
      overflow: "hidden",
      padding: "0",
      zIndex: "2",
    }),
    option: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "#fff",
      "&:hover": {
        color: "white",
        backgroundColor: "#217cbb",
      },
    }),
  };

  return (
    <div className={props.className} data-testid={testId}>
      <Select
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        inputId={props.inputId}
        isMulti
        maxMenuHeight={maxMenuHeight}
        onChange={props.onChange}
        options={props.options}
        placeholder={props.placeholder}
        styles={customStyles}
        value={props.selectedValues}
        isSearchable={props.isSearchable}
      />
    </div>
  );
};
