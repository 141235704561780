import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import _ from "underscore";

import { getFileTypes, getVersionAttributes } from "../../../js/converters/PackageConverter";
import { SearchAttributesConverter } from "../../../js/converters/SearchAttributesConverter";
import { NewRepository } from "../../../js/services/NewRepository";
import { TCCPackageService } from "../../../js/services/TCCPackageService";
import { VersionConverter } from "../../../js/converters/VersionConverter";

import { createStoreContext, RootStore } from "../../../stores/rootStore";
import { ContentManagerActionsStore } from "./actions/contentManagerActionsStore";

import { FilterConverter } from "../../../utils/converters/FilterConverter";

import { ITCCEntity } from "../../../models/TCCdataModel";
import {
  FiltersToTCC,
  IBatchCollection,
  IBatchEntity,
  IDropdownOption,
  IFilter,
  IFilters,
  IQueryAttributes,
  IQuerySelectors,
  ISearchOptions
} from "../../../models/dataModel";
import {
  AvailableAttributes,
  AvailableSelectors,
  ExternalResourceTypeEnum,
  SearchPageOrderEnum,
  SearchTargetOptionEnum,
  VisibilityEnum
} from "../../../models/enums";

export interface SearchOptions extends ISearchOptions {
  sortBy: SearchPageOrderEnum;
  searchTarget: SearchTargetOptionEnum;
  filters: IFilters;
  includeBanned?: boolean;
  showHidden?: boolean;
}

export class ContentManagerStore {
  /** 
   * Root store
   * */
  private rootStore: RootStore;

  @observable private contentManagerActionsStore: ContentManagerActionsStore;

  /**
   * List of results
   */
  @observable private results: IBatchCollection[] | IBatchEntity[] = [];

  /**
   * Count of results
   */
  @observable private resultsCount: number = 0;

  /**
   * Indicator if data is being queried
   */
  @observable private querying: Record<string, boolean> = {
    results: false,
    fileTypes: false,
    views: false,
    downloads: false,
  };

  /**
   * Indicator if data is being processed
   */
  @observable private processing = false;

  /**
   * Search options
   */
  @observable private searchOptions: SearchOptions = {
    count: 20, // to be decided, lowered for development
    offset: 0,
    sortBy: SearchPageOrderEnum.LAST_UPDATED,
    searchTarget: SearchTargetOptionEnum.COLLECTION,
    showHidden: true,
    filters: {
      selectors: {
        visibility: VisibilityEnum.ALL,
        externalResourceType: ExternalResourceTypeEnum.ALL,
        title: undefined,
        creatorDisplayName: undefined,
        showBanned: undefined,
        dateCreatedStart: undefined,
        dateCreatedEnd: undefined,
        dateModifiedStart: undefined,
        dateModifiedEnd: undefined,
        minViews: undefined,
        maxViews: undefined,
        minDownloads: undefined,
        maxDownloads: undefined,
      },
      attributes: {
        compatibleSoftwareProducts: undefined,
        testedVersions: undefined,
        locations: undefined,
        languages: undefined,
        itemTypeCategories: undefined,
        useCategories: undefined,
        requiresMaintenanceLicense: undefined,
        hasPartnerLink: undefined,
        showInvalid: undefined,
        showImmutable: undefined,
      },
    },
  };

  /**
   * Search options used in previous search
   */
  @observable private previousSearchOptions: SearchOptions = { ...this.searchOptions };

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
    this.contentManagerActionsStore = new ContentManagerActionsStore(rootStore, this);
  }


  public getActionsStore(): ContentManagerActionsStore {
    return this.contentManagerActionsStore;
  }

  /**
   * @returns current search results
   */
  public getResults(): Array<IBatchCollection | IBatchEntity> {
    return this.results;
  }

  /**
   * @returns number of current search results
   */
  public getResultsCount(): number {
    return this.resultsCount;
  }

  /**
   * @returns selected results
   */
  public getSelectedResults(): Array<IBatchCollection | IBatchEntity> {
    return _.filter(this.results, (item: IBatchCollection | IBatchEntity) => !!item.selected);
  }

  /**
   * @returns selected search target
   */
  public getSearchTarget(): SearchTargetOptionEnum {
    return this.searchOptions.searchTarget;
  }

  /**
   * Switches search target between collections/packages
   * Views and downloads are reset to not interfere with the search
   */
  @action
  public setSearchTarget(target: SearchTargetOptionEnum) {
    this.updateSearchOptions({
      ...this.searchOptions,
      searchTarget: target,
      filters: {
        ...this.searchOptions.filters,
        selectors: {
          ...this.searchOptions.filters.selectors,
          minViews: undefined,
          maxViews: undefined,
          minDownloads: undefined,
          maxDownloads: undefined,
        },
      },
    });
  }

  /**
   * @returns current parameter for sorting results
   */
  public getSortBy(): string {
    return this.searchOptions.sortBy;
  }

  /**
   * Changes parameter for sorting results according to user input
   * @param option new sort parameter
   */
  @action
  public setSortBy(option: SearchPageOrderEnum) {
    this.searchOptions.sortBy = option;
  }

  /**
   * @returns user input for filter by title
   */
  public getTitle(): string | undefined {
    return this.getSelectors().title;
  }

  /**
   * Changes current title substring to new user input
   */
  @action
  public setTitle(title: string | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, title: title },
      },
    };
  }

  /**
   * @returns user input for filter by creator display name
   */
  public getCreatorDisplayName(): string | undefined {
    return this.getSelectors().creatorDisplayName;
  }

  /**
   * Changes current creator substring to new user input
   */
  @action
  public setCreatorDisplayName(creator: string | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, creatorDisplayName: creator },
      },
    };
  }

  /**
   * @returns current visibility option
   */
  public getVisibility(): VisibilityEnum {
    return this.getSelectors().visibility;
  }

  /**
   * Changes current visibility option to new user input
   */
  @action
  public setVisibility(visibility: VisibilityEnum) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, visibility: visibility },
      },
    };
  }

  /**
   * @returns current created by option
   */
  public getCreatedBy(): ExternalResourceTypeEnum {
    return this.getSelectors().externalResourceType;
  }

  /**
   * Changes current created by option to new user input
   */
  @action
  public setCreatedBy(externalResourceType: ExternalResourceTypeEnum) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, externalResourceType: externalResourceType },
      },
    };
  }

  /**
   * @returns current created start date
   */
  public getDateCreatedStart(): string | undefined {
    return this.getSelectors().dateCreatedStart;
  }

  /**
   * Changes created start date according to user input
   */
  @action
  public setDateCreatedStart(dateCreatedStart: string | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, dateCreatedStart: dateCreatedStart },
      },
    };
  }

  /**
   * @returns current created end date
   */
  public getDateCreatedEnd(): string | undefined {
    return this.getSelectors().dateCreatedEnd;
  }

  /**
   * Changes created end date according to user input
   */
  @action
  public setDateCreatedEnd(dateCreatedEnd: string | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, dateCreatedEnd: dateCreatedEnd },
      },
    };
  }

  /**
   * @returns current modified start date
   */
  public getDateModifiedStart(): string | undefined {
    return this.getSelectors().dateModifiedStart;
  }

  /**
   * Changes modified start date according to user input
   */
  @action
  public setDateModifiedStart(dateModifiedStart: string | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, dateModifiedStart: dateModifiedStart },
      },
    };
  }

  /**
   * @returns current modified end date
   */
  public getDateModifiedEnd(): string | undefined {
    return this.getSelectors().dateModifiedEnd;
  }

  /**
   * Changes modified end date according to user input
   */
  @action
  public setDateModifiedEnd(dateModifiedEnd: string | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, dateModifiedEnd: dateModifiedEnd },
      },
    };
  }

  /**
   * @returns minimum view count to query by
   */
  public getMinViews(): number | undefined {
    return this.getSelectors().minViews;
  }

  /**
   * Changes parameter for minimum view count to query by according to user input
   * @param views minimum view count to query by
   */
  @action
  public setMinViews(views: number | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, minViews: views },
      },
    };
  }

  /**
   * @returns maximum view count to query by
   */
  public getMaxViews(): number | undefined {
    return this.getSelectors().maxViews;
  }

  /**
   * Changes parameter for maximum view count to query by according to user input
   * @param views maximum view count to query by
   */
  @action
  public setMaxViews(views: number | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, maxViews: views },
      },
    };
  }

  /**
   * @returns minimum dowload count to query by
   */
  public getMinDownloads(): number | undefined {
    return this.getSelectors().minDownloads;
  }

  /**
   * Changes parameter for minimum download count to query by according to user input
   * @param downloads minimum download count to query by
   */
  @action
  public setMinDownloads(downloads: number | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, minDownloads: downloads },
      },
    };
  }

  /**
   * @returns maximum download count to query by
   */
  public getMaxDownloads(): number | undefined {
    return this.getSelectors().maxDownloads;
  }

  /**
   * Changes parameter for maximum download count to query by according to user input
   * @param downloads maximum download count to query by
   */
  @action
  public setMaxDownloads(downloads: number | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, maxDownloads: downloads },
      },
    };
  }

  /**
   * @returns current state of software product filter
   */
  public getCompatibleSoftwareProducts(): readonly IDropdownOption[] | undefined {
    return this.getAttributes()?.compatibleSoftwareProducts;
  }

  /**
   * Changes state of software product filter according to user input
   */
  @action
  public setCompatibleSoftwareProducts(compatibleSoftwareProducts: readonly IDropdownOption[] | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: {
          ...this.searchOptions.filters.attributes,
          compatibleSoftwareProducts: compatibleSoftwareProducts,
        },
      },
    };
  }

  /**
   * @returns current state of software version filter
   */
  public getTestedVersions(): readonly IDropdownOption[] | undefined {
    return this.getAttributes()?.testedVersions;
  }

  /**
   * Changes state of software product filter according to user input
   */
  @action
  public setTestedVersions(testedVersions: readonly IDropdownOption[] | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, testedVersions: testedVersions },
      },
    };
  }

  /**
   * @returns current state of location filter
   */
  public getLocations(): readonly IDropdownOption[] | undefined {
    return this.getAttributes()?.locations;
  }

  /**
   * Changes state of location filter according to user input
   */
  @action
  public setLocations(locations: readonly IDropdownOption[] | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, locations: locations },
      },
    };
  }

  /**
   * @returns current state of language filter
   */
  public getLanguages(): readonly IDropdownOption[] | undefined {
    return this.getAttributes()?.languages;
  }

  /**
   * Changes state of language filter according to user input
   */
  @action
  public setLanguages(languages: readonly IDropdownOption[] | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, languages: languages },
      },
    };
  }

  /**
   * @returns current state of item type filter
   */
  public getItemTypes(): readonly IDropdownOption[] | undefined {
    return this.getAttributes()?.itemTypeCategories;
  }

  /**
   * Changes state of item type filter according to user input
   */
  @action
  public setItemTypes(itemTypeCategories: readonly IDropdownOption[] | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, itemTypeCategories: itemTypeCategories },
      },
    };
  }

  /**
   * @returns current state of use category filter
   */
  public getUseCategories(): readonly IDropdownOption[] | undefined {
    return this.getAttributes()?.useCategories;
  }

  /**
   * Changes state of use category filter according to user input
   */
  @action
  public setUseCategories(useCategories: readonly IDropdownOption[] | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, useCategories: useCategories },
      },
    };
  }

  /**
   * @returns current state of maintenance license filter
   */
  public requiresMaintenanceLicense(): boolean | undefined {
    return this.getAttributes()?.requiresMaintenanceLicense;
  }

  /**
   * Changes state of maintenance license filter according to user input
   */
  @action
  public setRequiresMaintenanceLicense(requiresMaintenanceLicense: boolean | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: {
          ...this.searchOptions.filters.attributes,
          requiresMaintenanceLicense: requiresMaintenanceLicense,
        },
      },
    };
  }

  /**
   * @returns current state of content type filter
   */
  public hasPartnerLink(): boolean | undefined {
    return this.getAttributes()?.hasPartnerLink;
  }

  /**
   * Changes state of content type filter according to user input
   */
  @action
  public setHasPartnerLink(hasPartnerLink: boolean | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, hasPartnerLink: hasPartnerLink },
      },
    };
  }

  /**
   * @returns current state of show invalid filter
   */
  public showInvalid(): boolean | undefined {
    return this.getAttributes()?.showInvalid;
  }

  /**
   * Changes state of show invalid filter according to user input
   */
  @action
  public setShowInvalid(showInvalid: boolean | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, showInvalid: showInvalid },
      },
    };
  }

  /**
   * @returns current state of show banned filter
   */
  public showBanned(): boolean | undefined {
    return this.getSelectors()?.showBanned;
  }

  /**
   * Changes state of show banned filter according to user input
   */
  @action
  public setShowBanned(showBanned: boolean | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      showHidden: showBanned == false ? this.searchOptions.showHidden : true,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, showBanned: showBanned },
      },
    };
  }

  /**
   * @returns current state of show hidden filter
   */
  public showHidden(): boolean | undefined {
    return this.getSelectors()?.showHidden;
  }

  /**
   * Changes state of show hidden filter according to user input
   */
  @action
  public setShowHidden(showHidden: boolean | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      showHidden: showHidden == false ? undefined : true,
      filters: {
        ...this.searchOptions.filters,
        selectors: { ...this.searchOptions.filters.selectors, showHidden: showHidden },
      },
    };
  }

  /**
   * @returns current state of show immutable content filter
   */
  public showImmutable(): boolean | undefined {
    return this.getAttributes()?.showImmutable;
  }

  /**
   * Changes state of show hidden filter according to user input
   */
  @action
  public setShowImmutable(showImmutable: boolean | undefined) {
    this.searchOptions = {
      ...this.searchOptions,
      filters: {
        ...this.searchOptions.filters,
        attributes: { ...this.searchOptions.filters.attributes, showImmutable: showImmutable },
      },
    };
  }

  /**
   * Checks if any content is selected.
   * @returns {boolean} True if any content is selected, false otherwise.
   */
  public hasSelectedContent(): boolean {
    return this.getSelectedResults().length > 0;
  }

  public getSelectors(): IQuerySelectors {
    return this.searchOptions.filters.selectors;
  }

  public getAttributes(): IQueryAttributes | undefined {
    return this.searchOptions.filters.attributes;
  }

  /**
   * @returns current search options as a JavaScript object
   */
  public getSearchOptions() {
    return toJS(this.searchOptions);
  }

  /**
   * Method to extract methods upon which views and downloads need to be reloaded
   * @returns array of the relevant getter methods
   */
  public getViewsAndDownloadsDependencies() {
    return [
      this.getSearchTarget(),
      this.getVisibility(),
      this.getCreatedBy(),
      this.getDateCreatedStart(),
      this.getDateCreatedEnd(),
      this.getDateModifiedStart(),
      this.getDateModifiedEnd(),
      this.getTitle(),
      this.getCreatorDisplayName(),
      this.showBanned(),
      this.showHidden(),
      this.showImmutable(),
      this.getAttributes(),
    ];
  }

  /**
   * Updates previous search options
   * @param searchOptions previous search options
   */
  @action
  public updatePreviousSearchOptions(searchOptions: SearchOptions) {
    this.previousSearchOptions = { ...searchOptions };
  }

  /**
   * Updates current search options
   * @param searchOptions previous search options
   */
  @action
  public updateSearchOptions(searchOptions: SearchOptions) {
    this.searchOptions = { ...searchOptions };
  }

  /**
   * Resolves if data is being queried
   * @param specifier optional specifier for specific query. If not provided, checks if results are queried.
   * @returns true if is querying
   */
  public isQuerying(specifier?: string): boolean {
    return specifier ? this.querying[specifier] : this.querying.results;
  }

  /**
   * Resolves if data is being processed
   * @returns true if is processing
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Checks if the current page is the first page of the search results
   */
  @computed
  public get isFirstPage(): boolean {
    return this.searchOptions.offset === 0;
  }

  /**
   * Checks if the current page is the last page of the search results
   */
  @computed
  public get isLastPage(): boolean {
    return this.searchOptions.offset + this.searchOptions.count >= this.resultsCount;
  }

  /**
   * Checks if filters have been adjusted since last search
   */
  @computed
  public get filtersAdjusted(): boolean {
    return !_.isEqual(
      { ...this.searchOptions, count: _, offset: _ },
      { ...this.previousSearchOptions, count: _, offset: _ },
    );
  }

  @action
  public async goToNextPage() {
    this.searchOptions = { ...this.previousSearchOptions, offset: this.searchOptions.offset };
    this.searchOptions.offset += this.searchOptions.count;
  }

  @action
  public async goToPreviousPage() {
    this.searchOptions = { ...this.previousSearchOptions, offset: this.searchOptions.offset };
    this.searchOptions.offset -= this.searchOptions.count;
  }

  /**
   * Searches for collections and packages based on the provided query string.
   * @param queryStr The query string to search for.
   * @returns A promise that resolves to an array of IBatchCollection objects or undefined.
   */
  @action
  public async search() {
    this.querying.results = true;

    const admin = this.rootStore.getUserStore().getCurrentUser();
    if (!admin) return;

    try {
      const data = this.getQueryParameters();
      const params = SearchAttributesConverter.toTCC(data);

      // if filters have been adjusted since last search, offset needs to be reset
      if (this.filtersAdjusted) {
        this.searchOptions.offset = 0;
      }

      const queryParams = {
        offset: this.searchOptions.offset,
        count: this.searchOptions.count,
        sortBy: params.sortBy,
        ...(!!this.searchOptions.showHidden && {
          showHidden: this.searchOptions.showHidden,
        }),
        ...(params.fq && { fq: params.fq }),
        ...(params.anyChildFq && { anyChildFq: params.anyChildFq }),
      };

      const res: { total: number; startRow: number; endRow: number; entries: IBatchEntity[] } =
        await NewRepository.searchContent(this.searchOptions.searchTarget, queryParams, admin.id);

      runInAction(() => {
        this.results = res.entries;
        this.resultsCount = res.total;
      });
    } catch {
      console.log("SEARCH ERROR");
    } finally {
      runInAction(() => {
        this.querying.results = false;
        this.updatePreviousSearchOptions(this.searchOptions);
      });

      if (this.searchOptions.searchTarget === SearchTargetOptionEnum.PACKAGE) {
        await this.getFileTypesAndVersionAttributesForContent();
      }
    }
  }

  /**
   * Selects/unselects all packages
   * @param select true if all should be selected, false if unselected
   */
  @action
  public selectAllResults(select: boolean) {
    this.processing = true;
    _.each(this.results, (item: IBatchCollection | IBatchEntity) => {
      item.selected = select;
    });
    this.processing = false;
  }

  /**
   * Selects given item
   * @param item the item to be selected
   */
  @action
  public selectListItem(item: IBatchCollection | IBatchEntity, selected: boolean) {
    this.processing = true;
    item.selected = selected;
    this.processing = false;
  }

  /**
   * Function for querying the maximum and minimum view count any item has
   */
  @action
  public async queryViews(): Promise<void> {
    this.querying.views = true;
    try {
      const resMax: { total: number; startRow: number; endRow: number; entries: IBatchEntity[] } =
        await this.queryAttributes("views DESC");

      const resMin: { total: number; startRow: number; endRow: number; entries: IBatchEntity[] } =
        await this.queryAttributes("views ASC");

      runInAction(() => {
        this.setMinViews(resMin.entries[0].views);
        this.setMaxViews(resMax.entries[0].views);

        this.updatePreviousSearchOptions({
          ...this.previousSearchOptions,
          filters: {
            ...this.previousSearchOptions.filters,
            selectors: {
              ...this.previousSearchOptions.filters.selectors,
              minViews: this.getMinViews(),
              maxViews: this.getMaxViews(),
            },
          },
        });
      });
    } catch {
      console.log("FAILED TO FETCH VIEWS");
    } finally {
      runInAction(() => {
        this.querying.views = false;
      });
    }
  }

  /**
   * Function for querying the maximum and minimum download count any item has
   */
  @action
  public async queryDownloads(): Promise<void> {
    this.querying.downloads = true;

    try {
      const resMax: { total: number; startRow: number; endRow: number; entries: IBatchEntity[] } =
        await this.queryAttributes("downloads DESC");

      const resMin: { total: number; startRow: number; endRow: number; entries: IBatchEntity[] } =
        await this.queryAttributes("downloads ASC");

      runInAction(() => {
        this.setMaxDownloads(resMax.entries[0].downloads);
        this.setMinDownloads(resMin.entries[0].downloads);

        this.updatePreviousSearchOptions({
          ...this.previousSearchOptions,
          filters: {
            ...this.previousSearchOptions.filters,
            selectors: {
              ...this.previousSearchOptions.filters.selectors,
              minDownloads: this.getMinDownloads(),
              maxDownloads: this.getMaxDownloads(),
            },
          },
        });
      });
    } catch {
      console.log("FAILED TO FETCH DOWNLOADS");
    } finally {
      runInAction(() => {
        this.querying.downloads = false;
      });
    }
  }

  @action
  private async getFileTypesAndVersionAttributesForContent() {
    this.querying.fileTypes = true;
    const promises = _.map(this.results as IBatchEntity[], async (packageItem: IBatchEntity) => {
      return new Promise<IBatchEntity>((resolve) => {
        if (!!packageItem.versionCount && packageItem.versionCount > 0) {
          TCCPackageService.getEntities(packageItem.id).then(
            function (versions) {
              resolve({
                ...packageItem,
                versions: _.map(versions, (version: ITCCEntity) => VersionConverter.fromTCC(version)),
                fileTypes: getFileTypes(versions),
                versionAttributes: getVersionAttributes(versions),
              });
            },
            function () {
              resolve(packageItem);
            },
          );
        } else {
          resolve(packageItem);
        }
      });
    });

    const resultsWithFileTypes = await Promise.all(promises);

    runInAction(() => {
      this.results = resultsWithFileTypes;
      this.querying.fileTypes = false;
    });
  }

  /**
   * Method performing API calls to fetch views and downloads
   * @param sortBy - part of the query
   * @returns
   */
  @action
  private async queryAttributes(sortBy: string): Promise<any> {
    const admin = this.rootStore.getUserStore().getCurrentUser();
    if (!admin) return;

    let data: {
      filters: { selectors: Array<IFilter | undefined>; attributes: Array<IFilter | undefined> };
      sortBy: SearchPageOrderEnum;
    } = this.getQueryParameters();

    // remove the views and downloads to query min and max views by other filters
    const selectorsWithoutViewsAndDownloads = _.filter(data.filters.selectors, (selector) => {
      if (!!!selector) return false;
      return selector.name != AvailableSelectors.VIEWS && selector.name != AvailableSelectors.DOWNLOADS;
    });

    data = {
      ...data,
      filters: {
        ...data.filters,
        selectors: selectorsWithoutViewsAndDownloads,
      },
    };

    const params = SearchAttributesConverter.toTCC(data);

    const queryParams = {
      offset: 0,
      count: 1,
      sortBy: sortBy,
      ...(params.fq && { fq: params.fq }),
      ...(params.anyChildFq && { anyChildFq: params.anyChildFq }),
    };

    return NewRepository.searchContent(this.searchOptions.searchTarget, queryParams, admin.id);
  }

  

  private getQueryParameters() {
    const data: {
      filters: FiltersToTCC;
      sortBy: SearchPageOrderEnum;
    } = {
      filters: {
        selectors: this.getSelectorsAsFilters(),
        attributes: this.getAttributesAsFilters(),
      },
      sortBy: this.searchOptions.sortBy,
    };

    return data;
  }

  /**
   * Convert all selectors to IFilter
   * @returns array of IFilter or undefined values
   */
  private getSelectorsAsFilters(): Array<IFilter | undefined> {
    const selectors = this.getSelectors();

    const filters: Array<IFilter | undefined> = [];

    _.forEach(AvailableSelectors, (selector) => {
      const values: Array<string | number> = [];
      const comparators: string[] = [];
      let outerJoin: boolean = false;

      switch (selector) {
        case AvailableSelectors.TITLE:
          !!selectors.title && values.push(`"${selectors.title}"`) && comparators.push("=like=");
          break;

        case AvailableSelectors.CREATOR_NAME:
          !!selectors.creatorDisplayName &&
            values.push(`"${selectors.creatorDisplayName}"`) &&
            comparators.push("=like=");
          break;

        case AvailableSelectors.CREATE_TIME:
          !!selectors.dateCreatedStart && values.push(`"${selectors.dateCreatedStart}"`) && comparators.push(">=");
          !!selectors.dateCreatedEnd && values.push(`"${selectors.dateCreatedEnd}"`) && comparators.push("<=");
          break;

        case AvailableSelectors.MODIFY_TIME:
          !!selectors.dateModifiedStart && values.push(`"${selectors.dateModifiedStart}"`) && comparators.push(">=");
          !!selectors.dateModifiedEnd && values.push(`"${selectors.dateModifiedEnd}"`) && comparators.push("<=");
          break;

        case AvailableSelectors.ACCESS_LEVEL:
          if (selectors.showBanned == false) {
            filters.push(FilterConverter.toIFilter(selector, ["banned"], ["!="]));
          }

          outerJoin = true;
          selectors.showBanned && values.push("banned") && comparators.push("==");

          selectors.visibility == VisibilityEnum.PUBLIC &&
            values.push("viewer", "finder") &&
            comparators.push("==", "==");

          selectors.visibility == VisibilityEnum.PRIVATE &&
            values.push("none", "banned") &&
            comparators.push("==", "==");
          break;

        case AvailableSelectors.HIDDEN:
          selectors.showHidden && values.push("true") && comparators.push("==");
          selectors.showHidden == false && values.push("true") && comparators.push("!=");
          break;

        case AvailableSelectors.EXT_RESOURCE_TYPE:
          selectors.externalResourceType != ExternalResourceTypeEnum.ALL &&
            values.push(selectors.externalResourceType) &&
            comparators.push("==");
          break;

        case AvailableSelectors.VIEWS:
          selectors.minViews !== undefined && values.push(selectors.minViews) && comparators.push(">=");
          selectors.maxViews !== undefined && values.push(selectors.maxViews) && comparators.push("<=");
          break;

        case AvailableSelectors.DOWNLOADS:
          selectors.minDownloads !== undefined && values.push(selectors.minDownloads) && comparators.push(">=");
          selectors.maxDownloads !== undefined && values.push(selectors.maxDownloads) && comparators.push("<=");
          break;
      }

      filters.push(FilterConverter.toIFilter(selector, values, comparators, undefined, outerJoin));
    });

    return filters;
  }

  /**
   * Convert all attributes to IFilter
   * @returns array of IFilter or undefined values
   */
  private getAttributesAsFilters(): Array<IFilter | undefined> {
    // All attributes are package specific, return empty list if package is not the target
    if (this.searchOptions.searchTarget !== SearchTargetOptionEnum.PACKAGE) return [];

    const attributes = this.searchOptions.filters.attributes;
    if (attributes == undefined) return [];

    const filters: Array<IFilter | undefined> = [];

    _.forEach(AvailableAttributes, (attribute) => {
      const values: Array<string | number> = [];
      const comparators: string[] = [];
      let dataType: string | undefined;
      let evaluateToTrue: boolean | undefined;
      let outerJoin: boolean | undefined;

      switch (attribute) {
        case AvailableAttributes.SW_PRODUCTS:
          !!attributes.compatibleSoftwareProducts &&
            _.map(attributes.compatibleSoftwareProducts, (option) => {
              values.push(option.value);
              comparators.push("==");
            });
          break;

        case AvailableAttributes.SW_VERSIONS:
          !!attributes.testedVersions &&
            _.map(attributes.testedVersions, (option) => {
              values.push(option.value);
              comparators.push("==");
            });
          break;

        case AvailableAttributes.LOCATIONS:
          !!attributes.locations &&
            _.map(attributes.locations, (option) => {
              values.push(option.value);
              comparators.push("==");
            });
          break;

        case AvailableAttributes.LANGUAGES:
          !!attributes.languages &&
            _.map(attributes.languages, (option) => {
              values.push(option.value);
              comparators.push("==");
            });
          break;

        case AvailableAttributes.USE_CATEGORIES:
          !!attributes.useCategories &&
            _.map(attributes.useCategories, (option) => {
              values.push(option.value);
              comparators.push("==");
            });
          break;

        case AvailableAttributes.ITEM_TYPE_CATEGORIES:
          !!attributes.itemTypeCategories &&
            _.map(attributes.itemTypeCategories, (option) => {
              values.push(option.value);
              comparators.push("==");
            });
          break;

        case AvailableAttributes.LICENSES:
          attributes.requiresMaintenanceLicense != undefined &&
            values.push("teklamaintenance") &&
            comparators.push(attributes.requiresMaintenanceLicense ? "==" : "!=");
          break;

        case AvailableAttributes.CONTENT_TYPE:
          dataType = "string";
          evaluateToTrue = attributes.hasPartnerLink == true;
          attributes.hasPartnerLink != undefined && values.push("contentDownloadUrl") && comparators.push("=exists=");
          break;

        case AvailableAttributes.REFERENCES:
          dataType = "string";
          evaluateToTrue = attributes.showInvalid == false;
          attributes.showInvalid != undefined && values.push("parentCollectionId") && comparators.push("=exists=");
          break;

        case AvailableAttributes.IMMUTABILITY:
          attributes.showImmutable && values.push("--immutable") && comparators.push("==");
          attributes.showImmutable == false && values.push("--immutable") && comparators.push("!=");
          break;
      }

      filters.push(FilterConverter.toIFilter(attribute, values, comparators, dataType, outerJoin, evaluateToTrue));
    });

    return filters;
  }
}

/**
 * Context object for ContentManagerStore instances.
 */
export const ContentManagerStoreContext = createStoreContext<ContentManagerStore>(ContentManagerStore);