import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { Chart } from "react-google-charts";

import { RootContext, RootStore } from "../../stores/rootStore";
import { StatisticsStore, StatisticsStoreContext } from "./statisticsStore";
import {
  AnalyticsStore,
  AnalyticsStoreContext,
  AnalyticsFiltersSetterContext,
} from "../../components/analytics/analyticsStore";
import { TimeFrameFilter } from "../../components/analytics/TimeFrameFilter";

const LineChart = observer(() => {
  const analyticsStore: AnalyticsStore = useContext(AnalyticsStoreContext);

  return (
    <div className="download-charts">
      <div className="chart-row">
        <div className="chart-box wide">
          <div className="wrapper">
            <div data-testid="line-chart" className="line-chart">
              <li>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="300px"
                  options={analyticsStore.getLineChartOptions()}
                  data={analyticsStore.getStatisticsData()}
                />
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const Content = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const analyticsStore: AnalyticsStore = useContext(AnalyticsStoreContext);

  return (
    <Fragment>
      {analyticsStore.isLoading() ? (
        <div className="spinner">{rootStore.getTranslation("shared.spinner_loading")}</div>
      ) : (
        <LineChart />
      )}
    </Fragment>
  );
});

const Controls = observer(() => {
  const statisticsStore: StatisticsStore = useContext(StatisticsStoreContext);

  return (
    <div className="controls">
      <AnalyticsFiltersSetterContext.Provider value={statisticsStore}>
        <div className="row">
          <TimeFrameFilter />
        </div>
      </AnalyticsFiltersSetterContext.Provider>
    </div>
  );
});

/**
 * A component that displays statistics tab.
 */
export const Statistics = () => {
  const rootStore: RootStore = useContext(RootContext);
  const analyticsStore: AnalyticsStore = useContext(AnalyticsStoreContext);

  return (
    <StatisticsStoreContext.Provider value={new StatisticsStore(rootStore, analyticsStore)}>
      <div className="analytics">
        <Controls />
        <Content />
      </div>
    </StatisticsStoreContext.Provider>
  );
};
