import { TCCEntityDS } from "../data-source/TCCEntityDS";
import { TCCPackageDS } from "../data-source/TCCPackageDS";
import { TCCAclService } from "../services/TCCAclService";
import { VersionConverter } from "../converters/VersionConverter";
import { ModelTypeConverter } from "../converters/ModelTypeConverter";
import { contentIdFilter } from "../../utils/filters";

import { ObjectTypeEnum } from "../../models/enums";

export const TCCVersionService = {
  create: function (data) {
    var entityId = "";
    return TCCEntityDS.createEntity(VersionConverter.toTCC(data), data.creator.id)
      .then(function (res) {
        entityId = contentIdFilter(res["location"]);
        var list = [];
        list.push(entityId);
        return TCCPackageDS.updatePackageChildren(
          data.package.id,
          {
            childIds: list,
          },
          data.creator.id,
        );
      })
      .then(function (res) {
        TCCAclService.cloneAcl(
          data.package.id,
          ModelTypeConverter.toSubjectClass(data.package.type),
          entityId,
          ModelTypeConverter.toSubjectClass(ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION),
        );
        return res;
      })
      .then(function () {
        return TCCEntityDS.getEntity(entityId, !!data.creator.id ? { performAs: data.creator.id } : {});
      })
      .then(VersionConverter.fromTCC);
  },
  update: function (data) {
    return TCCEntityDS.updateEntity(VersionConverter.toTCC(data))
      .then(function () {
        return TCCEntityDS.getEntity(data.id, !!data.modifier.id ? { performAs: data.modifier.id } : {});
      })
      .then(VersionConverter.fromTCC);
  },
  searchEntities: function (data) {
    return TCCEntityDS.searchEntities(data);
  },
  /**
   * Fetches a version/entity from backend based on given id
   * @param {string} versionId
   * @param {string?} performAsId
   * @returns the version in correct format
   */
  get: function (versionId, performAsId) {
    var data = { showBinaryAttributes: true };
    return TCCEntityDS.getEntity(versionId, !!performAsId ? { ...data, performAs: performAsId } : data).then(
      VersionConverter.fromTCC,
    );
  },
  remove: function (data, editorId) {
    return TCCEntityDS.deleteEntity(data, editorId);
  },
  getAcl: function (versionId, data) {
    return TCCEntityDS.getAcl(versionId, data);
  },
  setAcl: function (versionId, data) {
    return TCCEntityDS.setAcl(versionId, data);
  },
  copyBinary: function (targetVersionId, binaryData, performAsId) {
    return TCCEntityDS.copyBinary(targetVersionId, binaryData, performAsId);
  },
  createBinaryWithMime: function (versionId, data, file, fileName, mimeType) {
    return TCCEntityDS.createBinaryWithMime(versionId, data, file, fileName, mimeType);
  },
  getBinary: function (versionId, binaryName, data) {
    return TCCEntityDS.getBinary(versionId, binaryName, data);
  },
  getBinaries: function (versionId) {
    return TCCEntityDS.getBinaries(versionId);
  },
  updateBinary: function (versionId, data, file, fileName) {
    return TCCEntityDS.updateBinary(versionId, data, file, fileName);
  },
  deleteBinary: function (versionId, binaryName) {
    return TCCEntityDS.deleteBinary(versionId, binaryName, data);
  },
  createBinary: function (versionId, data, file, fileName) {
    return TCCEntityDS.createBinary(versionId, data, file, fileName);
  },
};
