import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useState } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";

/**
 * Component for code name field, used in AddCodeNameDialog & on Upload version page.
 */
export const CodeNameField = observer(
  (props: {
    codeName: string | undefined;
    setCodeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isValid: boolean;
    alreadyInUse: boolean;
    showErrorOnUnfocus?: boolean;
    showTooltip?: boolean;
  }) => {
    const rootStore: RootStore = useContext(RootContext);

    const [fieldNotInFocus, setFieldNotInFocus] = useState(false);
    const codeNamePrefix = "twh-";

    function handleBlur() {
      setFieldNotInFocus(true);
    }

    return (
      <li data-testid="codeNameField" style={{ listStyle: "none" }}>
        <div className="tooltip-info" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <label className="required" htmlFor="codeName">
            {rootStore.getTranslation("upload.package.code_name")}
          </label>
          {!!props.showTooltip && (
            <span
              className="icon help blue"
              title={`${rootStore.getTranslation("immutability.info")} ${rootStore.getTranslation("upload.package.code_name_info")} `}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "1em" }}>
          <span style={{ height: "26px", padding: "0.5em", backgroundColor: "#9997ad", color: "white", placeContent: "center" }}>
            {codeNamePrefix}
          </span>
          <input
            type="text"
            name="codeName"
            value={props.codeName}
            required
            onChange={props.setCodeName}
            onBlur={handleBlur}
            style={{ width: "100%" }}
            data-testid="codeNameInput"
          />
        </div>
        {((props.showErrorOnUnfocus && fieldNotInFocus) || !props.showErrorOnUnfocus) && (
          <div className="error">
            {(props.codeName || "").length === 0 ? (
              <small className="error" data-testid="codeNameMissing">
                {rootStore.getTranslation("upload.package.code_name_validation.missing")}
              </small>
            ) : (
              <Fragment>
                {!props.isValid && (
                  <small className="error" data-testid="codeNameNotInCorrectFormat">
                    {rootStore.getTranslation("upload.package.code_name_validation.wrong_format")}
                  </small>
                )}
                {props.isValid && props.alreadyInUse && (
                  <small className="error" data-testid="codeNameAlreadyInUse">
                    {rootStore.getTranslation("upload.package.code_name_validation.not_unique")}
                  </small>
                )}
              </Fragment>
            )}
          </div>
        )}
      </li>
    );
  },
);
