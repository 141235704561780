import * as React from "react";
import { useContext, useEffect } from "react";
import { saveAs } from "file-saver";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { PackagePageStore, PackagePageStoreContext } from "../packagePageStore";
import { EditorModeStore, EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { ReleaseNotesStore, ReleaseNotesStoreContext } from "./releaseNotesStore";

import { IFileItem } from "../../models/dataModel";
import { EditReleaseNotes } from "package/dialogs/EditReleaseNotes";

/**
 * A component that displays release notes tab.
 */
export const ReleaseNotes = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const store: ReleaseNotesStore = useContext(ReleaseNotesStoreContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const releaseNote: IFileItem | undefined = store.getReleaseNoteFile();

  useEffect(() => {
    store.init();
  }, []);

  function canEdit() {
    return packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  async function downloadFile() {
    if (!!releaseNote) {
      const res = await store.getFileBinaryData(releaseNote);
      const blob = new Blob([res.data]);
      saveAs(blob, releaseNote.attributes!.originalName);
    }
  }

  return (
    <div className="release-notes">
      {canEdit() && (
        <DialogContext.Provider value={new DialogStore()}>
          <EditReleaseNotes />
        </DialogContext.Provider>
      )}
      {!!releaseNote && (
        <a data-testid="download-release-note" onClick={() => downloadFile()}>
          <div className="release-notes-item" data-testid="release-note-file">
            <span>{rootStore.getTranslation("details.releaseNotesUrl")}</span>
          </div>
        </a>
      )}
    </div>
  );
});
