import * as React from "react";
import { useContext } from "react";

import { RootContext, RootStore } from "../stores/rootStore";
import { SearchPageStore, SearchPageStoreContext, IGetResourcePathContext } from "./searchPageStore";
import { SearchPage } from "./SearchPage";

/**
 * Wrapper component for SearchPage
 *
 */
export const SearchPageWrapper = () => {
  const store: RootStore = useContext(RootContext);
  const searchPageStore: SearchPageStore = new SearchPageStore(store);

  return (
    <SearchPageStoreContext.Provider value={searchPageStore}>
      <IGetResourcePathContext.Provider value={searchPageStore}>
        <SearchPage />
      </IGetResourcePathContext.Provider>
    </SearchPageStoreContext.Provider>
  );
};
