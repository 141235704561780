import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Link } from "react-router";

import { RootContext, RootStore } from "../../../stores/rootStore";
import { IUser, IOrganization } from "../../../models/dataModel";
import { ExternalResourceTypeEnum } from "../../../models/enums";

/**
 * Renders the list of sources/targets.
 * @param itemCandidates list of sources/targets to pick from
 * @param selectedItem possible selected list item
 * @param selectItem function that is called when item is selected
 */
export const ItemsList = observer(
  ({
    itemCandidates,
    selectedItem,
    selectItem,
  }: {
    itemCandidates: Array<IUser | IOrganization>;
    selectedItem: IUser | IOrganization | undefined;
    selectItem;
  }) => {
    const rootStore: RootStore = useContext(RootContext);

    function isAnOrganization(item: IUser | IOrganization | undefined) {
      if (item) {
        return item.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION;
      } else {
        return false;
      }
    }

    function removeSource() {
      selectItem(undefined);
    }

    function renderItemName(item) {
      return (
        <Fragment>
          <span
            className={classNames("icon", "blue", {
              "icon-organization": isAnOrganization(item),
              "icon-user": !isAnOrganization(item),
            })}
          />
          {isAnOrganization(item) ? (
            <Link
              to={"/organization/" + item.id}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="organizationLink"
            >
              {item.displayName}
            </Link>
          ) : (
            <span className="blue">{item.displayName}</span>
          )}
        </Fragment>
      );
    }

    return (
      <div className="items">
        {!!selectedItem ? (
          <div className="items-list" data-testid="oneItemSelected">
            <li>
              {renderItemName(selectedItem)}
              <a
                className="icon icon-close small blue"
                onClick={removeSource}
                title={rootStore.getTranslation("actions.remove")}
              />
            </li>
          </div>
        ) : (
          <div className="items-list" data-testid="multipleItemsSelected">
            {itemCandidates.map((item, i: number) => {
              return (
                <li key={i}>
                  {renderItemName(item)}
                  <a
                    className="icon icon-plus small blue"
                    onClick={() => selectItem(item)}
                    title={rootStore.getTranslation("actions.add")}
                  />
                </li>
              );
            })}
          </div>
        )}
      </div>
    );
  },
);
