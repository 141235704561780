import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext, useState } from "react";
import { RootContext } from "../../stores/rootStore";
import { OrganizationPageStore } from "../organizationPageStore";
import { DialogContext } from "../../dialogs/dialogStore";
import { IOrganization } from "../../models/dataModel";

/**
 * Renders edit description dialog for organization
 */
export const EditDescriptionDialog = observer(
  ({ organization, store }: { organization: IOrganization; store: OrganizationPageStore }) => {
    const rootStore = useContext(RootContext);
    const dialog = useContext(DialogContext);
    const [description, setDescription] = useState<string>(organization.description || "");

    function cancel() {
      dialog.close();
    }

    function handleDescriptionChange(e) {
      setDescription(e.target.value);
    }

    async function updateDesription() {
      await store.updateDescription(description);
    }

    return (
      <Fragment>
        <article>
          <header>
            <h2>{rootStore.getTranslation("organization.edit.edit_description")}</h2>
          </header>
          <section>
            <ol className="basic-data">
              <li>
                <label>{rootStore.getTranslation("organization.edit.edit_description")}</label>
                <input
                  type="text"
                  value={description}
                  onChange={handleDescriptionChange}
                  data-testid="update-description-input"
                />
              </li>
            </ol>
          </section>
          <div className="actions">
            <button onClick={cancel} data-testid="cancel-edit-org-description">
              {rootStore.getTranslation("actions.cancel")}
            </button>
            <button className="button-primary" onClick={updateDesription} data-testid="update-org-description">
              {rootStore.getTranslation("actions.update")}
            </button>
          </div>
        </article>
      </Fragment>
    );
  },
);
