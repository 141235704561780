import { observer } from "mobx-react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import _ from "underscore";
import classNames from "classnames";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";
import { UploadVersionStore } from "./uploadVersionStore";

import { IDropdownOption, IDropdownOptionGroup } from "../../models/dataModel";

import { DateInput } from "../../components/DateInput";
import { DropdownMultiple } from "../../components/DropdownMultiple";
import { metadataLabels } from "../../utils/MetadataLabels";
import { Prerequisites } from "./Prerequisites";
import { VersionNumberField } from "./VersionNumberField";
/**
 * Left column of the form, with a title field
 */
const LeftColumn = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  useEffect(() => {
    if (!form.isImmutable() && _.isEmpty(version.getTitle())) {
      version.setTitle("1");
    }
  }, []);

  useEffect(() => {
    form.setVersionTitle(version.getTitle());
  }, [version.getTitle()]);

  const [fieldNotInFocus, setFieldNotInFocus] = useState(false);

  const titleMissingAndRequired = fieldNotInFocus && !form.isImmutable() && _.isEmpty(version.getTitle());

  function handleTiltleInput(event) {
    event.preventDefault();
    version.setTitle(event.target.value);
  }

  function handleBlur() {
    setFieldNotInFocus(true);
  }

  return (
    <div className="column" data-testid="leftColumn">
      <ol>
        {form.isImmutable() && <VersionNumberField />}
        <li>
          <label className={classNames({ required: !form.isImmutable() })} htmlFor="title">
            {rootStore.getTranslation("versions.version_display_name")}
          </label>
          <input
            type="text"
            name="title"
            required={!form.isImmutable()}
            value={version.getTitle()}
            onChange={handleTiltleInput}
            onBlur={handleBlur}
            data-testid="versionTitle"
          />
          {titleMissingAndRequired && (
            <div className="error">
              <small className="error">
                {rootStore.getTranslation("versions.please_enter_a_version_name_or_number")}
              </small>
            </div>
          )}
        </li>
      </ol>
    </div>
  );
});

/**
 * Middle column of the form, with fields for:
 * * compatible software products,
 * * Compatible versions and
 * * compatible operating systems
 */
const MiddleColumn = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const versionOptions: Array<IDropdownOption | IDropdownOptionGroup> = _.chain(metadataLabels.testedVersionsOptions)
    .filter((option: IDropdownOptionGroup | IDropdownOption) => {
      if (version.getVersion().attributes.compatibleSoftwareProducts.length === 0) {
        return true;
      } else {
        return (
          (option as IDropdownOption).value == "NVS" ||
          _.includes(version.getVersion().attributes.compatibleSoftwareProducts, option.label)
        );
      }
    })
    .map((option: IDropdownOptionGroup | IDropdownOption) => {
      if (_.has(option, "value")) {
        return { label: rootStore.getTranslation(option.label), value: (option as IDropdownOption).value };
      } else {
        return {
          label: rootStore.getTranslation("compatibleSoftwareProductOptions." + option.label),
          options: _.map((option as IDropdownOptionGroup).options, (version: IDropdownOption) => {
            return { label: rootStore.getTranslation(version.label), value: version.value };
          }),
        };
      }
    })
    .value();

  function handleSelectVersions(options: readonly IDropdownOption[]) {
    version.setTestedVersions(options as IDropdownOption[]);
  }

  const compatibleOperatingSystemOptions = _.map(metadataLabels.compatibleOperatingSystemsOptions, (option) => {
    return {
      label: rootStore.getTranslation(option.name),
      value: option.value,
    };
  });

  function handleSelectOperatingSystems(options: readonly IDropdownOption[]) {
    version.setCompatibleOperatingSystems(options as IDropdownOption[]);
  }

  const compatibleSoftwareProductOptions: IDropdownOption[] = _.map(
    metadataLabels.softwareProductsOptions,
    (option) => {
      return {
        label: rootStore.getTranslation(option.name),
        value: option.value,
      };
    },
  );

  function handleSelectSoftwareProducts(options: readonly IDropdownOption[]) {
    version.setCompatibleSoftwareProducts(options as IDropdownOption[]);
  }

  return (
    <div className="column" data-testid="middleColumn">
      <ol>
        <li>
          <label htmlFor="compatibleSoftwareProducts">
            {rootStore.getTranslation("upload.compatibleSoftwareProducts")}
          </label>
          <DropdownMultiple
            options={compatibleSoftwareProductOptions}
            selectedValues={version.getCompatibleSoftwareProducts()}
            onChange={handleSelectSoftwareProducts}
            className="dropdown-wrapper gray"
            inputId="products"
          />
        </li>
        <li>
          <label htmlFor="testedVersions">{rootStore.getTranslation("upload.testedVersions")}</label>
          <DropdownMultiple
            options={versionOptions}
            selectedValues={version.getTestedVersions()}
            onChange={handleSelectVersions}
            className="dropdown-wrapper gray"
            inputId="versions"
          />
        </li>
        <li>
          <label htmlFor="compatibleOperatingSystems">
            {rootStore.getTranslation("upload.compatibleOperatingSystems")}
          </label>
          <DropdownMultiple
            options={compatibleOperatingSystemOptions}
            selectedValues={version.getCompatibleOperatingSystems()}
            onChange={handleSelectOperatingSystems}
            className="dropdown-wrapper gray"
            inputId="operatingSystems"
          />
        </li>
      </ol>
    </div>
  );
});

/**
 * Right column of the form, with fields for:
 * * Product code,
 * * Product expiration date and
 * * Measurement units.
 */
const RightColumn = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const measurementUnitOptions: IDropdownOption[] = _.map(metadataLabels.measurementUnitOptions, (option) => {
    return {
      label: rootStore.getTranslation(option.name),
      value: option.value,
    };
  });

  function handleProductCodeInput(event) {
    event.preventDefault();
    version.setProductCode(event.target.value);
  }

  function handleSelectProductExpirationDate(date: Date | null) {
    if (!date) return;
    version.setProductExpirationDate(date);
  }

  function handleSelectMeasurementUnits(options: readonly IDropdownOption[]) {
    version.setMeasurementUnits(options);
  }

  return (
    <div className="column" data-testid="rightColumn">
      <ol>
        <li>
          <label htmlFor="productCode">{rootStore.getTranslation("upload.productCode")}</label>
          <input
            type="text"
            name="productCode"
            onChange={handleProductCodeInput}
            value={version.getProductCode()}
            data-testid="productCode"
          />
        </li>
        <li>
          <label>{rootStore.getTranslation("upload.productExpirationDate")}</label>
          <DateInput selectedValue={version.getProductExpirationDate()} onChange={handleSelectProductExpirationDate} />
        </li>
        <li>
          <label>{rootStore.getTranslation("upload.measurementUnits")}</label>
          <DropdownMultiple
            className="dropdown-wrapper gray"
            options={measurementUnitOptions}
            onChange={handleSelectMeasurementUnits}
            selectedValues={version.getMeasurementUnits()}
          />
        </li>
      </ol>
    </div>
  );
});

/**
 * Main component for the Version Information section.
 */
export const VersionInformation = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <section className="content-management version-content-viewer" data-testid="versionInformationSection">
      <form name="versionMetadataForm" noValidate>
        <header>
          <h3>{rootStore.getTranslation("versions.version_information")}</h3>
        </header>
        <div className="wrapper as-table-wide">
          <LeftColumn />
          <MiddleColumn />
          <RightColumn />
        </div>
        <Prerequisites />
      </form>
    </section>
  );
});
