import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext } from "../../stores/rootStore";
import { IDropdownOption, IDropdownOptionGroup } from "../../models/dataModel";
import { Dropdown } from "../Dropdown";
import { TranslatedHtml } from "../TranslatedHtml";
import { ResultOptionsStoreContext } from "./resultOptionsStore";
import { Download } from "../../dialogs/download/Download";
import { InsertIntoModelDialog } from "../../dialogs/insert-into-model/InsertIntoModelDialog";
import { DialogStore, DialogContext } from "../../dialogs/dialogStore";
import { isLicenseRequired } from "../../utils/functions";
import { metadataLabels } from "../../utils/MetadataLabels";

/**
 * A component displaying version selection.
 */
const ChooseVersion = observer(() => {
  const rootStore = useContext(RootContext);
  const resultOptionsStore = useContext(ResultOptionsStoreContext);

  const versionsOptions: IDropdownOption[] = [
    {
      label: rootStore.getTranslation("download.all_versions"),
      value: "allVersions",
    },
  ];

  _.chain(metadataLabels.testedVersionsOptions)
    .map((tvo: IDropdownOption | IDropdownOptionGroup) =>
      _.has(tvo, "options") ? (tvo as IDropdownOptionGroup).options : (tvo as IDropdownOption),
    )
    .flatten()
    .each((option: IDropdownOption) => {
      if (option.value !== "NVS")
        versionsOptions.push({
          label: rootStore.getTranslation(option.label),
          value: option.value,
        });
    });

  function changeVersion(opt: IDropdownOption | null) {
    if (!opt) return;

    resultOptionsStore.setVersionFilter(opt);
    resultOptionsStore.clearSelection();
  }

  return (
    <div className="choose-version">
      <div className="step-numbering step-one" />
      <div className="text">
        <TranslatedHtml entry="download_install.choose_version" />
      </div>
      <div className="action">
        <Dropdown
          options={versionsOptions}
          onChange={changeVersion}
          placeholder={rootStore.getTranslation("placeholders.filter-options.testedVersions")}
          className="dropdown-wrapper light"
          selectedValue={resultOptionsStore.getVersionFilter()}
          inputId="testedVersionsSelect"
        />
      </div>
    </div>
  );
});

/**
 * A component displaying select all option.
 */
const ChooseContent = observer(() => {
  const resultOptionsStore = useContext(ResultOptionsStoreContext);

  function selectAll() {
    resultOptionsStore.selectAll();
  }

  return (
    <div className="choose-content">
      <div className="step-numbering step-two" />
      <div className="text">
        <TranslatedHtml entry="download_install.choose_content" />
      </div>
      <div className="action">
        {resultOptionsStore.shouldDisplaySelectAll() && (
          <div className="select-all-option">
            <div className="select-all">
              <input
                className="white"
                data-testid="select-all"
                id="selectAll"
                type="checkbox"
                onChange={selectAll}
                checked={resultOptionsStore.areAllSelected()}
              />
              <label htmlFor="selectAll">
                <TranslatedHtml entry="helpers.select_all" />
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

/**
 * A component displaying download and install buttons.
 */
const ChooseAction = observer(() => {
  const rootStore = useContext(RootContext);
  const resultOptionsStore = useContext(ResultOptionsStoreContext);
  const userStore = rootStore.getUserStore();
  const localServiceStore = rootStore.getLocalServiceStore();

  function getErrorMessageIfExists() {
    if (!resultOptionsStore.isVersionSelected) {
      return rootStore.getTranslation("install.select_software_version");
    } else if (!localServiceStore.isLocalServiceAccessible()) {
      return rootStore.getTranslation("install.verify_service_plugin_is_running");
    } else if (localServiceStore.isLocalServiceAccessible() && !localServiceStore.getLocalService().isTsAccessible) {
      return rootStore.getTranslation("install.verify_ts_connection");
    }
  }

  function showTMinfo() {
    const tm = "teklamaintenance";
    return _.any(resultOptionsStore.getEntities(), (entity) => {
      if (isLicenseRequired(entity, tm)) {
        return !userStore.hasMaintenance();
      } else {
        return false;
      }
    });
  }

  function canDownload() {
    return resultOptionsStore.selectedCount > 0 && resultOptionsStore.isVersionSelected;
  }

  function isLocalServiceAccessible() {
    return localServiceStore.isLocalServiceAccessible() && localServiceStore.getLocalService().isTsAccessible;
  }

  function canInstall() {
    return canDownload() && isLocalServiceAccessible();
  }

  function showOptions() {
    resultOptionsStore.setOptionsVisible(true);
  }

  function hideOptions() {
    resultOptionsStore.setOptionsVisible(false);
  }

  return (
    <div className="choose-action">
      <div className="step-numbering step-three" />
      <div className="text">{rootStore.getTranslation("download_install.choose_action")}</div>
      <div className="action">
        <DialogContext.Provider value={new DialogStore()}>
          <Download
            active={canDownload()}
            entities={resultOptionsStore.selectedEntities}
            versionFilter={resultOptionsStore.getVersionFilter()}
          />
        </DialogContext.Provider>
        <DialogContext.Provider value={new DialogStore()}>
          <InsertIntoModelDialog
            active={canInstall()}
            installationStatus={resultOptionsStore.getInstallationStatus()}
          />
        </DialogContext.Provider>
      </div>
      <div className="show-result-options">
        {resultOptionsStore.getOptionsVisible() && (
          <a className="show-result-options-link" onClick={hideOptions}>
            <TranslatedHtml entry="helpers.show_less_options" />
          </a>
        )}
        {!resultOptionsStore.getOptionsVisible() && (
          <a className="show-result-options-link" onClick={showOptions} data-testid="show-options">
            <TranslatedHtml entry="helpers.show_more_options" />
          </a>
        )}
      </div>
      {getErrorMessageIfExists() && <div className="error-messages icon-help-cart">{getErrorMessageIfExists()}</div>}
      {showTMinfo() && (
        <div className="requires-maintenance">
          <div className="icon-maintenance" />
          <div className="maintenance-text">
            <TranslatedHtml entry="install.download_requires_maintenance" />
          </div>
        </div>
      )}
    </div>
  );
});

/**
 * A component displaying actions sections in shopping list.
 */
export const Actions = observer(() => {
  return (
    <Fragment>
      <section className="collection-cart-instructions" data-testid="actions">
        <div className="steps">
          <ChooseVersion />
          <ChooseContent />
          <ChooseAction />
        </div>
      </section>
    </Fragment>
  );
});
