
import { ModusButton } from "@trimble-oss/modus-react-components";
import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";
import { ContentManagerActionsStore } from "./contentManagerActionsStore";

import { SelectContentDialog, SelectContentDialogActionEnum } from "./SelectContentDialog";
import { DialogContext, DialogStore } from "../../../../dialogs/dialogStore";

import { ConfirmAction } from "../../../../dialogs/ConfirmAction";

import { IBatchCollection, IBatchEntity } from "../../../../models/dataModel";
import { SearchTargetOptionEnum } from "../../../../models/enums";

const BanCollection = observer(({ disabled }: { disabled: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const contentMangerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store: ContentManagerActionsStore = contentMangerStore.getActionsStore();
  const confirmDialog: DialogStore = new DialogStore();

  function handleOpenConfirmDialog() {
    confirmDialog.open();
  }

  function getDialogContent() {
    return (
      <div>
        <p>{rootStore.getTranslation("profile.admin.content_manager.ban_and_unban.confirm_ban")}</p>
        <ul style={{ paddingLeft: "2em" }}>
          {contentMangerStore.getSelectedResults().map((collection: IBatchCollection) => (
            <li key={collection.id}>{collection.title}</li>
          ))}
        </ul>
      </div>
    )
  }

  function handleBanSelected() {
    store.banSelected();
    confirmDialog.close();
  }

  return (
    <DialogContext.Provider value={confirmDialog}>
      <ModusButton
        disabled={disabled}
        className="me-2 mb-2"
        onClick={() => !disabled && handleOpenConfirmDialog()}
        style={{ zIndex: 0 }}
        data-testid="banSelected"
      >
        {rootStore.getTranslation("profile.admin.ban_selected")}
      </ModusButton>
      <ConfirmAction
        content={getDialogContent()}
        callbackConfirm={handleBanSelected}
        callbackCloseDialog={() => confirmDialog.close()}
      />
    </DialogContext.Provider>
  );
});

const UnBanCollection = observer(({ disabled }: { disabled: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const contentMangerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store: ContentManagerActionsStore = contentMangerStore.getActionsStore();
  const confirmDialog: DialogStore = new DialogStore();

  function handleOpenConfirmDialog() {
    confirmDialog.open();
  }

  function handleUnbanSelected() {
    store.unbanSelected();
    confirmDialog.close();
  }

  function getDialogContent() {
    return (
      <div >
        <p>{rootStore.getTranslation("profile.admin.content_manager.ban_and_unban.confirm_unban")}</p>
        <ul style={{ paddingLeft: "2em" }}>
          {contentMangerStore.getSelectedResults().map((collection: IBatchCollection) => (
            <li key={collection.id}>{collection.title}</li>
          ))}
        </ul>
      </div>
    )
  }

  return (
    <DialogContext.Provider value={confirmDialog}>
      <ModusButton
        disabled={disabled}
        className="me-2 mb-2"
        onClick={() => !disabled && handleOpenConfirmDialog()}
        style={{ zIndex: 0 }}
        data-testid="unbanSelected"
      >
        {rootStore.getTranslation("profile.admin.unban_selected")}
      </ModusButton>
      <ConfirmAction
        content={getDialogContent()}
        callbackConfirm={handleUnbanSelected}
        callbackCloseDialog={() => confirmDialog.close()}
      />
    </DialogContext.Provider>
  );
});

export const BanAndUnban = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const contentMangerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store: ContentManagerActionsStore = contentMangerStore.getActionsStore();
  const dialog: DialogStore = new DialogStore();

  const processingOrNothingSelected = contentMangerStore.isQuerying() || store.isProcessing() || !contentMangerStore.hasSelectedContent();

  const banIsDisabled =
    processingOrNothingSelected ||
    _.all(contentMangerStore.getSelectedResults(), (content: IBatchEntity | IBatchCollection) =>
      !!content.isBanned && _.all((content as IBatchEntity).versions || [], (version) => !!version.isBanned)
    );

  const unbanIsDisabled =
    processingOrNothingSelected ||
    !_.any(contentMangerStore.getSelectedResults(), (content: IBatchEntity | IBatchCollection) =>
      !!content.isBanned || _.any((content as IBatchEntity).versions || [], (version) => !!version.isBanned)
    );

  function handleOpenSelectContentDialog(action: SelectContentDialogActionEnum) {
    store.setSelectedAction(action);
    store.buildContentTree(contentMangerStore.getSelectedResults() as IBatchEntity[], action);
    dialog.open();
  }

  return contentMangerStore.getSearchTarget() === SearchTargetOptionEnum.COLLECTION ? (
    <>
      <BanCollection disabled={banIsDisabled} />
      <UnBanCollection disabled={unbanIsDisabled} />
    </>
  ) : (
    <DialogContext.Provider value={dialog}>
      <ModusButton
        disabled={banIsDisabled}
        className="me-2 mb-2"
        onClick={() => handleOpenSelectContentDialog(SelectContentDialogActionEnum.BAN)}
        style={{ zIndex: 0 }}
        data-testid="banSelected"
      >
        {rootStore.getTranslation("profile.admin.ban_selected")}
      </ModusButton>
      <ModusButton
        disabled={unbanIsDisabled}
        className="me-2 mb-2"
        onClick={() => handleOpenSelectContentDialog(SelectContentDialogActionEnum.UNBAN)}
        style={{ zIndex: 0 }}
        data-testid="unbanSelected"
      >
        {rootStore.getTranslation("profile.admin.unban_selected")}
      </ModusButton>
      <SelectContentDialog />
    </DialogContext.Provider>
  );
});