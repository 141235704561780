import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";

/**
 * A component that renders the Tekla Universal Navigation block
 */
export const TeklaNavigation = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const lang: string = rootStore.getLocalizationStore().getLangForSelectedLocale().toLowerCase();

  return (
    <div className="global-block">
      <div
        id="tekla-navigation"
        className="tekla-navigation-container"
        data-testid="tekla-navigation"
        data-language={lang}
      />
    </div>
  );
});
