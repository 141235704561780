import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext, useState } from "react";
import { RootContext } from "../../stores/rootStore";
import { BatchTranslationsDialogStoreContext } from "./batchTranslationsDialogStore";
import { BatchContentHandlerContext } from "../batch-edit/batchEditDialogStore";
import { DialogContext } from "../dialogStore";
import { TextWrapper } from "../../components/TextWrapper";
import { CollectionSelection } from "../../components/batchedits/CollectionSelection";
import { SelectAll } from "../../components/batchedits/SelectAll";
import { ContentList } from "../../components/batchedits/ContentList";

const ExportView = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchTranslationsDialogStoreContext);
  const selectedContent = dialogStore.getSelectedContent();

  function shouldExportBeDisabled(): boolean {
    return dialogStore.isProcessing() || dialogStore.isProcessingTranslations() || selectedContent.length <= 0;
  }

  return (
    <div className="translation-export-table">
      <div className="first-column">
        <div className="select-content-title">{rootStore.getTranslation("shared.translations.select_content")}</div>
        <div className="column">
          <BatchContentHandlerContext.Provider value={dialogStore}>
            <CollectionSelection />
            <div className="content-area">
              <SelectAll />
              <ContentList />
            </div>
          </BatchContentHandlerContext.Provider>
        </div>
        <div className="export-to-file">
          <button
            data-testid="export-translations-button"
            className="export-button"
            disabled={shouldExportBeDisabled()}
            onClick={() => dialogStore.exportTranslations()}
          >
            {rootStore.getTranslation("shared.translations.export_selected")}
          </button>
        </div>
      </div>
    </div>
  );
});

const ImportErrors = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchTranslationsDialogStoreContext);

  function getInfoMsg(): string {
    if (!dialogStore.isProcessing() && !dialogStore.isImportProcessed()) {
      return rootStore.getTranslation("shared.translations.import_error_info");
    } else return "";
  }

  return (
    <div className="import-errors-info">
      {getInfoMsg()}
      {dialogStore.getImportErrors().map((error, i) => {
        return (
          <div key={i} className="import-error-messages" data-testid="import-error-message">
            {error.message}
          </div>
        );
      })}
    </div>
  );
});

const ImportView = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchTranslationsDialogStoreContext);

  function handleFileSelect(event) {
    dialogStore.handleFileSelect(event.target.files[0]);
    event.target.value = null;
  }

  function getFileName(): string {
    const file = dialogStore.getTranslationsFile();
    return file ? file.name : "";
  }

  function importErrorsExist(): boolean {
    return dialogStore.getImportErrors().length > 0;
  }

  function showImportFinishedMsg(): boolean {
    return dialogStore.isImportProcessed() && dialogStore.getImportCount() > 0;
  }

  return (
    <div className="import-translations">
      <span className="select-file-title">{rootStore.getTranslation("shared.translations.select_file_title")}</span>
      <label className="button select-translations-file" htmlFor="fileupload">
        <span>{rootStore.getTranslation("shared.translations.select_file")}</span>
      </label>
      <input
        className="hidden-file-upload"
        id="fileupload"
        type="file"
        onChange={handleFileSelect}
        accept=".txt, .csv"
        data-testid="select-translations-file"
      />
      <input
        data-testid="translations-file-name"
        className="import-file"
        type="text"
        value={getFileName()}
        disabled
      ></input>
      {importErrorsExist() && <ImportErrors />}
      <button
        data-testid="import-translations-button"
        disabled={!dialogStore.canImport()}
        className="import-translations-button"
        onClick={() => dialogStore.importTranslations()}
      >
        {rootStore.getTranslation("shared.translations.import_from_file")}
      </button>
      {dialogStore.isProcessing() && (
        <div className="loading-indicator" data-testid="processing">
          <div className="spinner"></div>
        </div>
      )}
      {showImportFinishedMsg() && (
        <span data-testid="import-finished">
          {rootStore.getTranslation("shared.translations.import_translations_finished")}
        </span>
      )}
    </div>
  );
});

/**
 * Renders batch translations dialog
 */
export const BatchTranslationsDialog = observer(() => {
  const rootStore = useContext(RootContext);
  const dialog = useContext(DialogContext);
  const [showImportView, setShowImportView] = useState(false);

  function cancel() {
    dialog.close();
  }

  function showExport() {
    setShowImportView(false);
  }

  function showImport() {
    setShowImportView(true);
  }

  return (
    <Fragment>
      <article>
        <header className="main">
          <h2>{rootStore.getTranslation("shared.translations.batch_translations")}</h2>
        </header>
        <section className="select-content">
          <ol className="basic-data">
            <div className="select-export-import">
              <div className="export-import-info">
                <TextWrapper text={rootStore.getTranslation("shared.translations.export_import_info")} />
              </div>
              <button onClick={showExport} data-testid="show-export-view-button">
                {rootStore.getTranslation("shared.translations.export_translations")}
              </button>
              <button onClick={showImport} data-testid="show-import-view-button">
                {rootStore.getTranslation("shared.translations.import_translations")}
              </button>
            </div>
            <li>{showImportView ? <ImportView /> : <ExportView />}</li>
          </ol>
        </section>
        <div className="actions">
          <button onClick={cancel} data-testid="close-batch-translation-edit">
            {rootStore.getTranslation("actions.close")}
          </button>
        </div>
      </article>
    </Fragment>
  );
});
