import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../../uploadFormStore";
import { UploadEntityStore } from "../../content/uploadEntityStore";

/**
 * Component for adding the partner download link
 */
export const PartnerDownloadLink = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entityStore: UploadEntityStore = form.getEntityStore();

  function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
    entityStore.addPartnerDownloadURL(e.target.value);
  }

  return (
    <div className="addPartnerDownloadLink" data-testid="partnerDownloadLink">
      <div className="wrapper full-width-center">
        <p>{rootStore.getTranslation("shared.catalog_entity_edit.partner_download_link_disclaimer")}</p>
        <label>{rootStore.getTranslation("shared.catalog_entity_edit.partner_download_link")}</label>
        <input
          type="url"
          pattern="https?://.+"
          className="partner-download-url-input"
          data-testid="partnerDownloadLinkInput"
          onChange={handleInput}
          value={entityStore.getPartnerDownloadURL()}
          placeholder={rootStore.getTranslation("shared.catalog_entity_edit.partner_download_link_placeholder")}
          style={{ width: "100%" }}
          required
        />
      </div>
    </div>
  );
});
