import * as React from "react";
import { Fragment, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { HashRouter, useLocation, useNavigate, useSearchParams } from "react-router";

import { AppHeader } from "../header/AppHeader";
import { Footer } from "../footer/Footer";
import { AppRoutes } from "../routes/Routes";
import { Router } from "../utils/Router";
import { scrollToTop } from "../utils/functions";

import { RootStore, RootContext } from "../stores/rootStore";
import { URLInterceptor } from "../js/URLInterceptor";

import * as Mobx from "mobx";
Mobx.configure({ enforceActions: "observed", isolateGlobalState: true });

import { defineCustomElements } from "@trimble-oss/modus-web-components/loader";
defineCustomElements();

const store = new RootStore();
URLInterceptor.initialize(store.getErrorHandlerStore());

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return null;
}

const App = () => {
  const invalidURL: boolean = URLInterceptor.isUrlBroken();
  const router: Router = store.getRouter();
  const navigate = useNavigate();

  router.initStore(navigate);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!!searchParams) {
      store.getLocalizationStore().setLocaleByLanguageParam(searchParams);

      searchParams.delete("tekla_hv_language");
      setSearchParams(searchParams);
    }

    const uniNaviScript = document.createElement("script");
    uniNaviScript.src = "https://nav.prod.tekla.com/main.js";
    document.head.appendChild(uniNaviScript);

    const footerScript = document.createElement("script");
    footerScript.src = "https://cookies.prod.tekla.com/main.js";
    document.head.appendChild(footerScript);
  }, []);

  if (invalidURL) {
    URLInterceptor.fixUrlAndRedirect();
  } else {
    const location = useLocation();

    useEffect(() => {
      router.handleStateChange(location.pathname);
    }, [location]);
  }

  return (
    <RootContext.Provider value={store}>
      {!invalidURL && (
        <Fragment>
          <ScrollToTop />
          <AppHeader />
          <AppRoutes />
          <Footer />
        </Fragment>
      )}
    </RootContext.Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <HashRouter>
    <App />
  </HashRouter>,
);
