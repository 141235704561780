import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../upload/uploadFormStore";
import { NewOnlineCollection } from "./NewOnlineCollection";

/**
 * A wrapper for a component responsible for creating new online collections.
 */
export const NewOnlineCollectionWrapper = observer(() => {
  const store: RootStore = useContext(RootContext);

  return (
    <UploadFormContext.Provider value={new UploadFormStore(store)}>
      <NewOnlineCollection />
    </UploadFormContext.Provider>
  );
});
