/* eslint-disable @typescript-eslint/no-unused-vars */
import { Organization } from "../factories/Organization";
import { checkIfImmutable } from "../../utils/Immutability";

import { SourceEnum } from "../../models/enums";
import { ITCCUser } from "../../models/TCCdataModel";
import { IOrganization } from "../../models/dataModel";

function resolveDescription(data) {
  if (data.description) {
    return data.description;
  } else {
    if (
      data.attributes &&
      data.attributes.details &&
      data.attributes.details.description &&
      data.attributes.details.description.value
    ) {
      return data.attributes.details.description.value;
    } else {
      return "";
    }
  }
}

function resolveDetails(data) {
  if (!!data.attributes && !!data.attributes.details) {
    return data.attributes.details;
  } else {
    return "";
  }
}

function resolveContact(data) {
  if (data.attributes && data.attributes.contact) {
    return data.attributes.contact;
  } else {
    return "";
  }
}

export const OrganizationConverter = {
  /**
   * Convert ATC organization data to a format that can be used by the app
   * NOTE: this is not currently used in the app
   * @param {*} data
   * @returns {IOrganization} - The converted data
   */
  fromATC: function (data) {
    data = data || {};
    return Organization({
      id: data.id,
      displayName: data.name,
      source: SourceEnum.ATC,
    });
  },
  /**
   * Convert TCC user data to a format that can be used by the app
   * @param {ITCCUser} data - The data to convert
   * @returns {IOrganization} - The converted data
   */
  fromTCC: function (data) {
    data = data || {};

    return Organization({
      id: data.id,
      displayName: data.displayName,
      groupName: data.groupName,
      linkedResourcesCollection: data.linkedResourcesCollection,
      source: data.source,
      externalId: data.externalId,
      customWarehouseUrl: data.customWarehouseUrl,
      collectionCount:
        !!data.collectionCount && !!data.collectionCount["TEKLA_WAREHOUSE_COLLECTION"]
          ? data.collectionCount["TEKLA_WAREHOUSE_COLLECTION"]
          : undefined,
      packageCount:
        !!data.packageCount && !!data.packageCount["TEKLA_WAREHOUSE_PACKAGE"]
          ? data.packageCount["TEKLA_WAREHOUSE_PACKAGE"]
          : undefined,
      versionCount:
        !!data.entityCount && !!data.entityCount["TEKLA_WAREHOUSE_VERSION"]
          ? data.entityCount["TEKLA_WAREHOUSE_VERSION"]
          : undefined,
      binaries: data.binaries,
      details: resolveDetails(data),
      contact: resolveContact(data),
      description: resolveDescription(data),
      isImmutable: checkIfImmutable(data),
    });
  },
};
