import { observable, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../js/services/NewRepository";

import { ITranslationObject, IItem } from "../models/dataModel";
import { ObjectTypeEnum, SanitizationModeEnum } from "../models/enums";

import { RootStore, createStoreContext } from "../stores/rootStore";
import { sanitize, translationKeyForAPIError } from "../utils/functions";

/**
 * Content translations store.
 */
export class ContentTranslationsStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;
  /**
   * Organization object.
   */
  @observable private translations: Record<string, ITranslationObject> | undefined;
  /**
   * Flag that marks if translations have been saved for collection
   */
  @observable private translationsSaved = false;

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Gets translations for given content
   * @returns content translations
   */
  public async getContentTranslations(contentId: string): Promise<Record<string, ITranslationObject> | undefined> {
    this.translations = await NewRepository.getTranslations({ id: contentId });
    return this.translations;
  }

  /**
   * Gets translations for given field
   * @params field name of the field which translation should be fetched
   * @params translations optional parameter for checking from given
   * translations instead of default set
   * @returns (sanitized) translation for given field
   */
  public getTranslationForField(field: string, translations?: Record<string, ITranslationObject>): string {
    let resolvedTranslations = [];
    const trans: Record<string, ITranslationObject> | undefined = !!translations
      ? translations
      : this.getTranslations();
    const language = this.rootStore.getLocalizationStore().getLangForSelectedLocale();

    !!trans &&
      _.each(trans, (translationItem, key) => {
        if (key === language) {
          resolvedTranslations = _.filter(translationItem, (translation, itemKey) => {
            return itemKey === field;
          });
        }
      });

    const sanitizationMode = field === "description" ? SanitizationModeEnum.MODERATE : SanitizationModeEnum.WEAK;
    return sanitize(resolvedTranslations[0], sanitizationMode);
  }

  /**
   * Returns if item has saved translations.
   */
  public hasSavedTranslations(): boolean {
    return this.translationsSaved;
  }

  /**
   * Saves translations for given item.
   * @param attributes the translated attributes
   * @param item item to save translation for
   */
  public async saveTranslation(attributes, item: IItem) {
    const itemIsCollection = item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION;
    const successMessage = itemIsCollection
      ? this.rootStore.getTranslation("collections.notification.collection_updated")
      : this.rootStore.getTranslation("shared.catalog_entity_edit.updated_successfully");

    try {
      await NewRepository.setTranslations(item, attributes);
      this.setTranslationsSaved(true);
      this.rootStore.getNotificationChannelStore().success(successMessage);
    } catch (err) {
      if (!itemIsCollection) {
        const transKey = translationKeyForAPIError(err.key) || "shared.catalog_entity_edit.update_failed";
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation(transKey));
      }
    }
  }

  /**
   * Sets the flag for translations saved.
   * @param value true / false
   */
  public setTranslationsSaved(value: boolean) {
    this.translationsSaved = value;
  }

  /**
   * Determines if translation for a field should be updated
   * @param field field to update translation for
   */
  public shouldUpdateTranslation(field: string, item: IItem) {
    const lang = this.rootStore.getLocalizationStore().getLangForSelectedLocale();
    let filterdItems = [];
    if (item.translations) {
      _.each(item.translations, (translationItem: ITranslationObject, key) => {
        if (key === lang) {
          filterdItems = _.filter(translationItem, (translation: string, itemKey: string) => {
            return itemKey === field;
          });
        }
      });
    }
    return filterdItems && filterdItems.length > 0;
  }

  private getTranslations(): Record<string, ITranslationObject> | undefined {
    return this.translations;
  }
}

export const ContentTranslationsStoreContext = createStoreContext<ContentTranslationsStore>(ContentTranslationsStore);
