/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "underscore";
import { DateTime } from "luxon";

import { Binary } from "../factories/Binary";
import { NewCollection } from "../factories/NewCollection";
import { TCCCollectionRequestTransferObject } from "../factories/TCCCollectionRequestTransferObject";
import { LocalCollectionRequestTransferObject } from "../factories/LocalCollectionRequestTransferObject";

import { LocalBinaryService } from "../services/LocalBinaryService";
import { ImageUtils } from "../../utils/ImageUtils";
import { onlyTruthy } from "../utils/onlyTruthy";
import { URLBuilder } from "../../utils/URLBuilder";
import { AttributesConverter } from "./AttributesConverter";
import { TagsConverter } from "./TagsConverter";
import { fixDisplayName } from "./FixDisplayName";

import { ICollection } from "../../models/dataModel";
import { BinaryTypeEnum, ObjectTypeEnum, SourceEnum, VisibilityEnum } from "../../models/enums";
import { ITCCCollection, TCCBaseAccessLevelEnum } from "../../models/TCCdataModel";

/**
 * Convert TCC data to a format that can be used by the app
 * @param {ITCCCollection} data - TCC data
 * @returns {ICollection} - converted data
 */
function TCCCollectionToTWHCollection(data) {
  data = data || {}; 
  var binaries = data.binaries || {};
  var translations = data.translations;
  var attributesFromTCC = AttributesConverter.fromTCC(data.attributes || {});

  var collection = {
    _id: data.id,
    id: data.id,
    thumbnails: [],
    thumbnails3d: [],
    title: data.title,
    description: data.description,
    packagesCount: data.collectionCount + data.packageCount,
    currentUserRating: data.currentUserRating,
    reviewCount: data.reviewCount,
    source: data.source || SourceEnum.TCC,
    type: data.type,
    isHidden: data.isHidden,
    isBanned: data.baseAccessLevel === TCCBaseAccessLevelEnum.BANNED ? true : false,
    isArchived: !!attributesFromTCC.status ? attributesFromTCC.status.archived : false,
    visibility: _.includes([TCCBaseAccessLevelEnum.NONE, TCCBaseAccessLevelEnum.BANNED], data.baseAccessLevel)
      ? VisibilityEnum.PRIVATE
      : VisibilityEnum.PUBLIC,
    tags: TagsConverter.fromTCC(data.tags),
    attributes: {
      licensesACL: attributesFromTCC.licenses ? onlyTruthy(attributesFromTCC.licenses) : undefined,
      defaultThumbnail: attributesFromTCC.defaultThumbnail,
      videourls: attributesFromTCC.videourls,
      bannedDate: attributesFromTCC.status ? attributesFromTCC.status.bannedDate : undefined,
    },
    accessRole: data.accessRole,
    translations: translations,
    createdAt: DateTime.fromSQL(data.createTime, { zone: "utc" }).toJSDate(),
    creator: fixDisplayName(data.creator),
    modifiedAt: DateTime.fromSQL(data.modifyTime, { zone: "utc" }).toJSDate(),
    modifier: fixDisplayName(data.modifier),
    views: data.views,
  };

  if (attributesFromTCC.references) {
    if (attributesFromTCC.references.linkedResourcesCollectionId) {
      collection.linkedResourcesCollection = NewCollection({
        _id: attributesFromTCC.references.linkedResourcesCollectionId,
      });
    }
    if (attributesFromTCC.references.linkedToCollectionId) {
      collection.linkedToCollection = NewCollection({ _id: attributesFromTCC.references.linkedToCollectionId });
    }
  }

  _.each(binaries, function (binary, key) {
    if (key.indexOf("thumbnail_3d") > -1) {
      var thumbnail_3d = Binary.create({
        _id: binary.id,
        reference: key,
        creator: binary.creator,
        modifier: binary.modifier,
        createdAt: DateTime.fromSQL(binary.createTime, { zone: "utc" }).toJSDate(),
        modifiedAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toJSDate(),
        url: URLBuilder.buildURL(binary.url, {
          createdAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toSeconds(),
        }),
        contentUrl: URLBuilder.buildURL(binary.contentUrl),
        attributes: {
          fileName: binary.originalFileName,
          originalName: binary.originalFileName,
          fileSize: binary.fileSize,
          type: BinaryTypeEnum.THUMBNAIL,
        },
      });
      collection.thumbnails3d.push(thumbnail_3d);
    } else if (key.indexOf("thumbnail") > -1) {
      var thumbnail = Binary.create({
        _id: binary.id,
        reference: key,
        creator: binary.creator,
        modifier: binary.modifier,
        createdAt: DateTime.fromSQL(binary.createTime, { zone: "utc" }).toJSDate(),
        modifiedAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toJSDate(),
        url: URLBuilder.buildURL(binary.url, {
          createdAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toSeconds(),
        }),
        contentUrl: URLBuilder.buildURL(binary.contentUrl),
        attributes: {
          fileName: binary.originalFileName,
          originalName: binary.originalFileName,
          fileSize: binary.fileSize,
          type: BinaryTypeEnum.THUMBNAIL,
        },
      });

      if (
        collection.attributes.defaultThumbnail &&
        collection.attributes.defaultThumbnail.name &&
        collection.attributes.defaultThumbnail.name === thumbnail.reference
      ) {
        collection.thumbnails.unshift(thumbnail);
      } else {
        collection.thumbnails.push(thumbnail);
      }
    }
  });

  if (collection.thumbnails.length <= 0) {
    var thumbnail = Binary.create({
      url: ImageUtils.getDefaultThumbail(),
      contentUrl: ImageUtils.getDefaultThumbail(),
    });
    collection.thumbnails.push(thumbnail);
  }

  return NewCollection(collection);
}

function localCollectionToNewCollection(data) {
  data = data || {};
  var binaries = data.binaries || [];
  var thumbnail = _.find(binaries, function (b) {
    return b.name === "thumbnail";
  });
  var collection = {
    id: data.id,
    title: data.title,
    description: data.description,
    tags: TagsConverter.fromLocal(data.tags),
    location: data.location,
    isLocal: true,
    isPreinstalled: data.type === "preInstalled",
    packagesCount: data.packageCount,
    source: SourceEnum.LOCAL_SERVICE,
    visibility: "private",
  };
  if (data.type === "preInstalled") {
    collection.type = ObjectTypeEnum.LOCAL_COLLECTION_PREINSTALLED;
  } else if (data.type === "userDefined") {
    collection.type = ObjectTypeEnum.LOCAL_COLLECTION;
  }
  if (thumbnail) {
    var url = LocalBinaryService.getBinaryUrl("collection", data.id, "thumbnail");
    collection.thumbnail = Binary.create({
      url: URLBuilder.buildURL(url, { dontCacheMe: DateTime.utc().toSeconds() }),
      id: thumbnail.id,
    });
  } else {
    collection.thumbnail = Binary.create({ url: ImageUtils.getDefaultThumbail() });
  }
  return NewCollection(collection);
}

export const NewCollectionConverter = {
  fromLocal: function (data) {
    data = data || {};
    if (Array.isArray(data)) {
      return _.map(data, function (d) {
        return localCollectionToNewCollection(d);
      });
    } else {
      return localCollectionToNewCollection(data);
    }
  },
  toLocal: function (data) {
    data = data || {};
    return LocalCollectionRequestTransferObject({
      id: data.id || data._id,
      title: data.title,
      location: data.location,
      description: data.description,
      tags: TagsConverter.toLocal({ tag: data.tags }),
    });
  },
  /**
   * Convert TCC data to a format that can be used by the app
   * If the input is an array, it converts each element in the array.
   * @param {ITCCCollection | ITCCCollection[]} data - TCC data
   * @returns {ICollection | ICollection[]} - converted data
   */
  fromTCC: function (data) {
    data = data || {};
    if (Array.isArray(data)) {
      return _.map(data, function (d) {
        return TCCCollectionToTWHCollection(d);
      });
    } else {
      return TCCCollectionToTWHCollection(data);
    }
  },
  toTCCAttributes: function (data) {
    var attributes = data.attributes || {};
    var attributesForTCC = {};

    if (!_.isUndefined(attributes.licensesACL)) {
      var values = attributes.licensesACL;
      if (_.size(values) > 0) {
        var p = (attributesForTCC.licenses = {});
        _.each(values, function (v) {
          p[v] = { dataType: "boolean", value: "true" };
        });
      }
    }

    if (data.isArchived) {
      attributesForTCC.status = attributes.status || {};
      attributesForTCC.status.archived = { dataType: "boolean", value: true };
    }

    if (!_.isUndefined(attributes.bannedDate)) {
      attributesForTCC.status = attributesForTCC.status || {};
      attributesForTCC.status.bannedDate = { dataType: "string", value: attributes.bannedDate || "" };
    }

    if (!_.isUndefined(attributes.defaultThumbnail) && !!attributes.defaultThumbnail.name) {
      attributesForTCC.defaultThumbnail = {
        name: { dataType: "string", value: attributes.defaultThumbnail.name },
      };
    }

    if (!_.isUndefined(attributes.videourls)) {
      var values = attributes.videourls;
      if (_.size(values) > 0) {
        var p = (attributesForTCC.videourls = {});
        _.each(values, function (v, k) {
          if (v === null) {
            p[k] = null;
          } else {
            p[k] = { dataType: "string", value: v };
          }
        });
      }
    }

    if (!_.isUndefined(data.linkedResourcesCollection)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.linkedResourcesCollectionId = {
        dataType: "string",
        value: "" + data.linkedResourcesCollection.id,
      };
    }

    if (!_.isUndefined(data.linkedToCollection)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.linkedToCollectionId = { dataType: "string", value: "" + data.linkedToCollection.id };
    }
    return attributesForTCC;
  },
  toTCCAttributesPartial: function (data) {
    var attributes = data.attributes || {};
    var attributesForTCC = {};

    if (!_.isUndefined(attributes.licensesACL)) {
      var values = attributes.licensesACL;
      if (_.size(values) > 0) {
        var p = (attributesForTCC.licenses = {});
        _.each(values, function (v) {
          p[v] = { dataType: "boolean", value: "true" };
        });
      } else if (_.size(values) === 0) {
        attributesForTCC.licenses = null;
      }
    }

    if (!_.isUndefined(attributes.bannedDate)) {
      attributesForTCC.status = {};
      attributesForTCC.status.bannedDate = { dataType: "string", value: attributes.bannedDate || "" };
    }

    if (!_.isUndefined(attributes.defaultThumbnail) && !!attributes.defaultThumbnail.name) {
      attributesForTCC.defaultThumbnail = {
        name: { dataType: "string", value: attributes.defaultThumbnail.name },
      };
    }

    if (!_.isUndefined(attributes.videourls)) {
      var values = attributes.videourls;
      if (_.size(values) > 0) {
        var p = (attributesForTCC.videourls = {});
        _.each(values, function (v, k) {
          if (v === null) {
            p[k] = null;
          } else {
            p[k] = { dataType: "string", value: v };
          }
        });
      } else if (_.size(values) === 0) {
        attributesForTCC.videourls = null;
      }
    }

    if (!_.isUndefined(data.linkedResourcesCollection)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.linkedResourcesCollectionId = {
        dataType: "string",
        value: "" + data.linkedResourcesCollection.id,
      };
    }

    if (!_.isUndefined(data.linkedToCollection)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.linkedToCollectionId = { dataType: "string", value: "" + data.linkedToCollection.id };
    }
    return attributesForTCC;
  },
  /**
   * Convert app data to a format that can be read by TCC
   * @param {Object} data - app data
   * @returns {ITCCCollection} - converted data
   */
  toTCC: function (data) {
    data = data || {};

    var supportedTypes = [
      ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
      ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGES_LINKED_TO_COLLECTION_COLLECTION,
      ObjectTypeEnum.TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION,
    ];

    var transferObject = {
      id: data.id || data._id,
      title: data.title,
      type: _.contains(supportedTypes, data.type) ? data.type : ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
      description: data.description || "",
      isHidden: data.isHidden,
      baseAccessLevel: data.isBanned
        ? TCCBaseAccessLevelEnum.BANNED
        : data.visibility === VisibilityEnum.PRIVATE
          ? TCCBaseAccessLevelEnum.NONE
          : TCCBaseAccessLevelEnum.VIEWER,
      tags: TagsConverter.toTCC(data.tags),
    };

    if (!_.isUndefined(data.doNotNotify)) {
      transferObject.doNotNotify = data.doNotNotify;
    }
    if (!_.isUndefined(data.creatorId)) {
      transferObject.creatorId = data.creatorId;
    }
    transferObject.attributes = NewCollectionConverter.toTCCAttributes(data);
    return TCCCollectionRequestTransferObject(transferObject);
  },
};
