import * as React from "react";
import { observer } from "mobx-react";
import { useContext, useState, Fragment } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UserCommentsStoreContext } from "../../package/tabs/userCommentsStore";
import { OrganizationPageStore, OrganizationPageStoreContext } from "../../organizations/organizationPageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { ReplyDialogStore, ReplyDialogStoreContext } from "./ReplyDialogStore";

import { IEntity, IOrganization, IComment } from "../../models/dataModel";
import { ObjectTypeEnum, SanitizationModeEnum } from "../../models/enums";
import { removeStylesFromText, sanitize } from "../../utils/functions";

import { RichTextEditor } from "../../components/RichTextEditor";

/**
 * Renders the "Edit comment" dialog used on package & organization pages.
 */
export const EditCommentDialog = observer(
  ({ subject, commentToEdit }: { subject: IEntity | IOrganization; commentToEdit: IComment }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);
    const replyStore: ReplyDialogStore | undefined = useContext(ReplyDialogStoreContext);

    const editingAReply = commentToEdit.parentCommentId !== undefined && commentToEdit.parentCommentId !== null;
    const isOrganization: boolean = subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION;

    const store = isOrganization ? useContext(OrganizationPageStoreContext) : useContext(UserCommentsStoreContext);

    const titleText = isOrganization
      ? rootStore.getTranslation("organization.edit_comment")
      : rootStore.getTranslation("details.actions.edit_comment");

    const confirmDeleteText = isOrganization
      ? rootStore.getTranslation("organization.edit.comment_delete_confirmation")
      : rootStore.getTranslation("details.comment_delete_confirmation");

    const [commentText, setCommentText] = useState<string>(
      sanitize(commentToEdit.text, SanitizationModeEnum.JUST_LINKS),
    );
    const [showDeleteView, setShowDeleteView] = useState<boolean>(false);

    function handleInput(input: string) {
      setCommentText(input);
    }

    async function exit() {
      if (editingAReply) {
        replyStore!.updateReplyList(commentToEdit, showDeleteView, commentText);
        replyStore!.setDialogState("viewReplies");
      } else {
        dialog.close();
      }
    }

    async function deleteComment() {
      const commentHasMoreThanOneReply: boolean = !!replyStore && replyStore.getReplies().length > 1;

      const editor = isOrganization
        ? (store as OrganizationPageStore).getEditor()
        : rootStore.getUserStore().getCurrentUser();

      await store.deleteComment(commentToEdit, editor, editingAReply && commentHasMoreThanOneReply);

      if (editingAReply && commentHasMoreThanOneReply) {
        exit();
      } else {
        await store.handleCommentEdit(true);
        dialog.close();
      }
    }

    async function updateComment() {
      const cleanedTxt = removeStylesFromText(commentText);
      const commentData = { text: cleanedTxt };

      const editor = isOrganization
        ? (store as OrganizationPageStore).getEditor()
        : rootStore.getUserStore().getCurrentUser();

      await store.updateComment(commentData, commentToEdit, editor, editingAReply);

      if (editingAReply) {
        exit();
      } else {
        await store.handleCommentEdit(false);
        dialog.close();
      }
    }

    function renderDeleteView() {
      return (
        <section className="delete-comment l-center-align" data-testid="deleteCommentView">
          <div className="confirm-delete" data-testid="deleteCommentHeader">
            <span>{confirmDeleteText}</span>
          </div>
          <div className="confirm-delete-buttons">
            <button className="button-large" onClick={() => setShowDeleteView(false)} data-testid="cancelDeleteButton">
              {rootStore.getTranslation("shared.confirm.no")}
            </button>
            <button className="button-large button-primary" onClick={deleteComment} data-testid="confirmDeleteButton">
              {rootStore.getTranslation("shared.confirm.yes")}
            </button>
          </div>
        </section>
      );
    }

    function renderEditView() {
      return (
        <article data-testid="editCommentDialog">
          <header data-testid="editCommentHeader">
            <h2 className="l-center-align">{titleText}</h2>
          </header>
          <section className="not-scrollable">
            <RichTextEditor field="editComment" value={commentText} commentSubject={subject} onChange={handleInput} />
          </section>
          <div className="actions">
            <button onClick={exit} data-testid="cancelEditButton">
              {rootStore.getTranslation("actions.cancel")}
            </button>
            <button
              className="button-primary"
              onClick={() => setShowDeleteView(true)}
              data-testid="deleteCommentButton"
            >
              {rootStore.getTranslation("actions.delete")}
            </button>
            <button
              className="button-primary"
              disabled={!commentText}
              onClick={updateComment}
              data-testid="updateCommentButton"
            >
              {rootStore.getTranslation("actions.update")}
            </button>
          </div>
        </article>
      );
    }

    return <Fragment>{showDeleteView ? renderDeleteView() : renderEditView()}</Fragment>;
  },
);
