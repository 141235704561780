import { LocalRequestBuilder } from "../LocalRequestBuilder";
import { LocalResponseProcessor } from "../LocalResponseProcessor";

export const LocalUtilsDS = {
  writeFile: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/writeFile", data));
  },
  convertJsonToXml: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/convertJsonToXml", data));
  },
  convertXmlToJson: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/convertXmlToJson", data));
  },
};
