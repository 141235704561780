import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UserCommentsStore, UserCommentsStoreContext } from "./userCommentsStore";
import { PackagePageStoreContext } from "../packagePageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { ReplyDialogStore, ReplyDialogStoreContext } from "../../dialogs/comments/ReplyDialogStore";

import { IComment } from "../../models/dataModel";
import { SanitizationModeEnum } from "../../models/enums";

import { TextWrapper } from "../../components/TextWrapper";
import { Spinner } from "../../components/Spinner";
import { Dialog } from "../../dialogs/Dialog";
import { AddCommentDialog } from "../../dialogs/comments/AddCommentDialog";
import { EditCommentDialog } from "../../dialogs/comments/EditCommentDialog";
import { ViewRepliesDialog } from "../../dialogs/comments/ViewRepliesDialog";

const EditComment = observer(({ comment }: { comment: IComment }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const dialog = new DialogStore();
  const testId = "edit-comment-" + comment.id;

  async function openDialog() {
    dialog.open();
  }

  return (
    <DialogContext.Provider value={dialog}>
      <a
        className="icon icon-pencil"
        title={rootStore.getTranslation("details.actions.edit_comment")}
        onClick={openDialog}
        data-testid={testId}
      >
        <span data-testid="editCommentSpan">{rootStore.getTranslation("details.actions.edit_comment")}</span>
      </a>
      <Dialog
        content={<EditCommentDialog subject={packagePageStore.getPackage()!} commentToEdit={comment} />}
        additionalClass="comment-dialog"
      />
    </DialogContext.Provider>
  );
});

const AddComment = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const dialog = new DialogStore();

  async function openDialog() {
    dialog.open();
  }

  return (
    <DialogContext.Provider value={dialog}>
      <button className="button-primary button-add-comment" onClick={openDialog} data-testid="add-comment-button">
        {rootStore.getTranslation("details.actions.add_comment")}
      </button>
      <Dialog
        content={<AddCommentDialog subject={packagePageStore.getPackage()!} />}
        additionalClass="comment-dialog"
      />
    </DialogContext.Provider>
  );
});

const CommentReplies = observer(({ comment }: { comment: IComment }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const viewDialog = new DialogStore();
  const addDialog = new DialogStore();

  const viewReplyTestId = "view-replies-" + comment.id;
  const addReplyTestId = "add-reply-" + comment.id;

  function viewReplies() {
    viewDialog.open();
  }

  function getReplyCommentsCount(): number {
    return comment.replies ? comment.replies.length : 0;
  }

  function addReply() {
    addDialog.open();
  }

  return (
    <section className="replies">
      {getReplyCommentsCount() > 0 ? (
        <DialogContext.Provider value={viewDialog}>
          <a
            className="view-reply"
            title={rootStore.getTranslation("details.actions.view_replies")}
            onClick={viewReplies}
            data-testid={viewReplyTestId}
          >
            <span>
              {rootStore.getTranslation("details.actions.view_replies")} ({getReplyCommentsCount()})
            </span>
          </a>
          <Dialog
            content={
              <ReplyDialogStoreContext.Provider value={new ReplyDialogStore()}>
                <ViewRepliesDialog subject={packagePageStore.getPackage()!} parentComment={comment} />
              </ReplyDialogStoreContext.Provider>
            }
          />
        </DialogContext.Provider>
      ) : (
        <DialogContext.Provider value={addDialog}>
          <a
            className="view-reply"
            title={rootStore.getTranslation("details.actions.reply_comment")}
            onClick={addReply}
            data-testid={addReplyTestId}
          >
            <span>{rootStore.getTranslation("details.actions.reply_comment")}</span>
          </a>
          <Dialog
            content={<AddCommentDialog subject={packagePageStore.getPackage()!} parentComment={comment} />}
            additionalClass="comment-dialog"
          />
        </DialogContext.Provider>
      )}
    </section>
  );
});

const CommentItem = observer(({ comment }: { comment: IComment }) => {
  const rootStore = useContext(RootContext);
  const commentStore = useContext(UserCommentsStoreContext);
  const user = rootStore.getUserStore().getCurrentUser();
  const canEditComment = !!user && user.id === comment.author.id;
  const commentTestId = "comment-text-" + comment.id;

  return (
    <div data-testid="comment">
      <div className="comment-createtime" data-testid="comment-createtime">
        {commentStore.getModifiedDate(comment.createTime)}
      </div>
      <div className="comment-text" data-testid={commentTestId}>
        <p>
          <TextWrapper text={comment.text} sanitizationMode={SanitizationModeEnum.JUST_LINKS} />
        </p>
      </div>
      {canEditComment && <EditComment comment={comment} />}
      <CommentReplies comment={comment} />
      <section className="report-bad-comment">
        <a
          href={rootStore.getTranslation("details.links.report_bad_content_link")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextWrapper
            text={rootStore.getTranslation("details.actions.report_bad_comment")}
            sanitizationMode={SanitizationModeEnum.JUST_LINKS}
          />
        </a>
      </section>
    </div>
  );
});

const CommentList = observer(() => {
  const commentStore = useContext(UserCommentsStoreContext);

  return (
    <div className="user-comment-items" data-testid="commentList">
      {commentStore.getComments().map((element, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && <hr className="separator"></hr>}
            <CommentItem comment={element} key={index} />
          </Fragment>
        );
      })}
    </div>
  );
});

const CommentNavigation = observer(() => {
  const rootStore = useContext(RootContext);
  const commentStore = useContext(UserCommentsStoreContext);

  async function loadPreviousComments() {
    await commentStore.loadPreviousFeeds();
  }

  async function loadNextComments() {
    await commentStore.loadNextFeeds();
  }

  return (
    <div className="comment-navigation" data-testid="commentNavigation">
      {commentStore.shouldShowPreviousCommentsLink() && (
        <a className="previous" onClick={loadPreviousComments} data-testid="load-previous-comments-link">
          <span className="previous-link-text" data-testid="loadPreviousFeedsSpan">
            {rootStore.getTranslation("organization.show_newer_news")}
          </span>
        </a>
      )}
      {commentStore.shouldShowNextCommentsLink() && (
        <a className="next l-right" onClick={loadNextComments} data-testid="load-next-comments-link">
          <span className="previous-link-text" data-testid="loadNextFeedsSpan">
            {rootStore.getTranslation("organization.show_older_news")}
          </span>
        </a>
      )}
    </div>
  );
});

/**
 * Component that displays user comments.
 */
export const UserComments = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const commentStore: UserCommentsStore = useContext(UserCommentsStoreContext);

  return (
    <div className="user-comments" data-testid="user-comments">
      <h3 className="title">{rootStore.getTranslation("details.userComments")}</h3>
      <Fragment>
        <div className="add-and-spinner">
          <AddComment />
          {commentStore.isLoading() && <Spinner loadingWhat="comments" />}
        </div>
        <CommentList />
        <CommentNavigation />
      </Fragment>
    </div>
  );
});
