import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";

/**
 * Small component for rendering the "login or register" message.
 */
export const LoginOrRegister = observer(() => {
  const rootStore = useContext(RootContext);

  function logIn() {
    rootStore.getUserStore().login();
  }

  function register() {
    rootStore.getUserStore().register();
  }

  return (
    <Fragment>
      <span>{rootStore.getTranslation("upload.please_log_in_fragments.please")}</span>
      <a onClick={logIn}>{rootStore.getTranslation("upload.please_log_in_fragments.log_in")}</a>
      <span>{rootStore.getTranslation("upload.please_log_in_fragments.or")}</span>
      <a onClick={register}>{rootStore.getTranslation("upload.please_log_in_fragments.register")}</a>
      <span>{rootStore.getTranslation("upload.please_log_in_fragments.to_upload_online_content")}</span>
    </Fragment>
  );
});
