import _ from "underscore";
import { Settings } from "../config/Settings";

import { TCCEntityDS } from "../js/data-source/TCCEntityDS";
import { AnalyticsService } from "../js/services/AnalyticsService";
import { RootStore } from "../stores/rootStore";

import { IBinary, IUser } from "../models/dataModel";
import { AnalyticsSourceEnum } from "../models/enums";

/**
 * Store for handling download requests.
 */
export class DownloaderStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Downloads items based on given parameters
   * @params collectionId Id of collection which contains downloadable item
   * @params packageId Id of package which contains downloadable item
   * @params entityId Id of version which contains downloadable item
   * @params binaryId Id of binary that should be downloaded
   */
  public async download(collectionId: string, packageId: string, entityId: string, binaryId: string): Promise<void> {
    const userStore = this.rootStore.getUserStore();
    userStore.fetchData();
    const currentUser = userStore.getCurrentUser();

    if (currentUser) {
      try {
        const binary = await TCCEntityDS.getBinary(entityId, binaryId);
        this.storeAnalyticsData(currentUser, collectionId, packageId, entityId);
        this.downloadBinary(binary);
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("shared.resource_not_found"));
      }
    } else if (userStore.shouldDoLogin()) {
      const params = {
        collectionId: collectionId,
        packageId: packageId,
        entityId: entityId,
        binaryId: binaryId,
      };
      userStore.login(this.callbackUrl(Settings.tcc.downloadCallBackUrl, params));
    } else {
      this.rootStore.getErrorHandlerStore().handleUnauthorized();
    }
  }

  private callbackUrl(path: string, params: any): string {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const port = window.location.port;
    if (protocol && host) {
      const url = protocol + "//" + host + (port && !_.contains(["433", "8080"], port) ? ":" + port : "") + path;
      return url + "?" + new URLSearchParams(params).toString();
    } else return "";
  }

  private downloadBinary(binary: IBinary): void {
    if (binary.url) {
      window.location.assign(binary.url);
    }
  }

  private storeAnalyticsData(user: IUser, collectionId: string, packageId: string, entityId: string) {
    const packageItem = { id: packageId, collection: { id: collectionId } };
    const version = { id: entityId };
    AnalyticsService.versionDownloadComplete(user, packageItem, version, null, AnalyticsSourceEnum.ONLINE_PANEL);
  }
}
