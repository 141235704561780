import * as React from "react";
import { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useLocation } from "react-router";

import { RootStore, RootContext } from "../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "./uploadFormStore";

import { UploadCollection } from "./collection/UploadCollection";
import { UploadContent } from "./content/UploadContent";
import { UploadVersion } from "./version/UploadVersion";

import { PreviousAndNext } from "./components/PreviousAndNext";
import { UploadHeader } from "./components/UploadHeader";
import { LoginNotification } from "../components/LoginNotification";
import { Publish } from "./Publish";

import { IUserOrganization } from "../models/dataModel";
import { TemporaryBannerNotification } from "../components/TemporaryBannerNotification";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { Settings } from "../config/Settings";

/**
 * Main component for the Upload form.
 */
export const UploadForm = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const { search } = useLocation();
  const collectionId = new URLSearchParams(search).get("collectionId");
  const isLocal = new URLSearchParams(search).get("isLocal");

  async function setUploadStage() {
    if (!!collectionId) form.setUpAddContentToCollection(collectionId, !!isLocal);
    await form.fetchData();
  }

  useEffect(() => {
    setUploadStage();
  }, []);

  const view = form.getUploadStage();
  const showCollection = view === "collection";
  const showContent = view === "content";
  const showVersion = view === "version";
  const publishing = view === "publish";

  const showTopNavigation = showContent || showVersion;
  const belongsToOrgsWhereIsNotEditor = rootStore.getUserStore().getOrganizations().some((uo: IUserOrganization) => !rootStore.getUserStore().isContentEditorForOrganization(uo.organization));

  return (
    <div className="upload">
      <TemporaryBannerNotification text={<TranslatedHtml
        entry="landing.notifications.content_editor_role.create_collections_info"
        args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
        bannerFeatureToggle="contentEditorRoleInfoAfter"
        show={showCollection && rootStore.getUserStore().isUserLoggedIn() && rootStore.getUserStore().getOrganizations().length > 0 && belongsToOrgsWhereIsNotEditor}
      />
      {publishing ? (
        <Publish />
      ) : (
        <div id="content" data-testid="content">
          {!rootStore.getUserStore().isUserLoggedIn() ? (
            <LoginNotification />
          ) : (
            <div
              className={classNames({
                "collection-selection": showCollection,
                "package-information": showContent,
                "version-information": showVersion,
              })}
            >
              <section className="steps" data-testid="headerSection">
                <UploadHeader active={view} />
              </section>
              {(showCollection || showContent) && (
                <form className="step" noValidate>
                  {showTopNavigation && (
                    <section className="actions" data-testid="topNavigation">
                      <PreviousAndNext />
                    </section>
                  )}
                  {showCollection && <UploadCollection />}
                  {showContent && <UploadContent />}

                  <section className="actions actions-bottom" data-testid="bottomNavigation">
                    <PreviousAndNext />
                  </section>
                </form>
              )}
              {showVersion && (
                <div className="step">
                  <section className="actions" data-testid="topNavigation">
                    <PreviousAndNext />
                  </section>
                  <UploadVersion />
                  <section className="actions actions-bottom" data-testid="bottomNavigation">
                    <PreviousAndNext />
                  </section>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
