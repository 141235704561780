import { action, observable, makeObservable } from "mobx";
import { createStoreContext } from "../stores/rootStore";

/**
 * Store for handling common dialog functionality.
 */
export class DialogStore {
  /**
   * A flag that marks if a dialog is visible.
   */
  @observable private showDialog = false;

  constructor() {
    makeObservable(this);
  }

  /**
   * Opens the dialog.
   */
  @action
  public open() {
    this.showDialog = true;
  }

  /**
   * Closes an open dialog.
   */
  @action
  public close() {
    this.showDialog = false;
  }

  /**
   * Checks if the dialog is open.
   */
  public isDialogVisible(): boolean {
    return this.showDialog;
  }
}

export const DialogContext = createStoreContext<DialogStore>(DialogStore);
