import { ObjectTypeEnum, SourceEnum, VisibilityEnum } from "../../models/enums";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ICollection } from "../../models/dataModel";

/**
 * Creates a new collection object.
 *
 * @param {Object} data - The data object containing the collection properties from backend.
 * @returns {ICollection} - The newly created collection object.
 */
export function NewCollection(data) {
  data = data || {};
  data.attributes = data.attributes || {};

  var collection = {
    _id: data._id || data.id,
    id: data._id || data.id,
    source: data.source || SourceEnum.UNKNOWN,
    type: data.type || ObjectTypeEnum.UNKNOWN,
    title: data.title,
    description: data.description,
    tags: data.tags || [],
    isHidden: data.isHidden || false,
    isArchived: data.isArchived || false,
    isBanned: data.isBanned || false,
    visibility: data.visibility || VisibilityEnum.PRIVATE,
    packagesCount: data.packagesCount || 0,
    creator: data.creator,
    modifier: data.modifier,
    modifiedAt: data.modifiedAt,
    createdAt: data.createdAt,
    currentUserRating: data.currentUserRating,
    reviewCount: data.reviewCount,
    isLinked: data.isLinked || false,
    linkedResourcesCollection: data.linkedResourcesCollection,
    linkedToCollection: data.linkedToCollection,
    linkedToUser: data.linkedToUser,
    linkedToOrganization: data.linkedToOrganization,
    isLocal: data.isLocal || false,
    isPreinstalled: data.isPreinstalled || false,
    location: data.location,
    translations: data.translations,
    attributes: {
      // undefined: everyone can access, []: noone can access, ['teklamaintenance']: only users with teklamaintenance
      licensesACL: data.attributes.licensesACL || undefined,
      defaultThumbnail: data.attributes.defaultThumbnail,
      videourls: data.attributes.videourls,
      bannedDate: data.attributes.bannedDate || undefined,
    },
    accessRole: data.accessRole,
    views: data.views || 0,
  };

  if (collection.isLocal) {
    collection.thumbnail = data.thumbnail;
  } else {
    collection.thumbnails = data.thumbnails;
    collection.thumbnails3d = data.thumbnails3d;
  }

  return collection;
}
