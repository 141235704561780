import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import { BatchContentHandlerContext } from "../../dialogs/batch-edit/batchEditDialogStore";

import { IBatchContentHandler, IDropdownOption } from "../../models/dataModel";
import { Dropdown } from "../Dropdown";

/**
 * Collection selection dropdown for batch dialogs
 */
export const CollectionSelection = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialogStore: IBatchContentHandler = useContext(BatchContentHandlerContext);
  const selectedCollectionOption: IDropdownOption = dialogStore.getSelectedCollectionOption();

  function getCollectionOptionsWithLabel(): IDropdownOption[] {
    return _.map(dialogStore.getCollectionOptions(), (option: IDropdownOption) => {
      if (
        (_.isString(option.value) && (option.value as string).includes("show_organization")) ||
        option.value == "show_all"
      ) {
        return option;
      } else {
        return {
          label: (
            <div className="flex-space-between">
              {option.value.title}
              <span className="small text-align-end">{` (${option.value.creator?.displayName})`}</span>
            </div>
          ),
          value: option.value.id,
        };
      }
    });
  }

  function handleCollectionChange(option: IDropdownOption | null) {
    if (!option) return;
    dialogStore.handleCollectionChange(option);
  }

  return (
    <div className="show-dropdown">
      <span className="select-collection-title">{rootStore.getTranslation("batch_edit.content.show_collection")}</span>
      <div className="dropdown-wrapper">
        <Dropdown
          options={getCollectionOptionsWithLabel()}
          onChange={handleCollectionChange}
          placeholder={rootStore.getTranslation("batch_edit.content.select_collection")}
          className="dropdown-wrapper gray"
          selectedValue={selectedCollectionOption}
          inputId="select-collection"
          menuHeight={175}
        />
      </div>
    </div>
  );
});
