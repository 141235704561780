import * as React from "react";
import { observer } from "mobx-react";

import { SearchTargetOptionEnum } from "../../models/enums";

import { RootContext } from "../../stores/rootStore";
import { useContext } from "react";

/**
 * A component that renders the content type selector.
 * @param rootStore rootstore for accessing translations and searchStore
 * @param packagesCount length of the packages list
 * @param getCollections length of the collections list
 * @param contentFinishedLoading boolean that reflects if the content finished loading
 */
export const ContentTypeSelector = observer(
  ({
    packagesCount,
    collectionsCount,
    contentFinishedLoading,
  }: {
    packagesCount?: number;
    collectionsCount?: number;
    contentFinishedLoading: boolean;
  }) => {
    const rootStore = useContext(RootContext);
    const store = rootStore.getSearchStore();

    const selected = store.getSearchTarget();
    const notSelected =
      selected === SearchTargetOptionEnum.PACKAGE ? SearchTargetOptionEnum.COLLECTION : SearchTargetOptionEnum.PACKAGE;

    function changeSearchTarget() {
      store.setSearchTarget(notSelected);
    }

    const renderContentItemsLabel = () => {
      return (
        <label className="icon icon-warehouse-content l-inline">
          {rootStore.getTranslation("helpers.show_content_items")}
          {contentFinishedLoading && (
            <span className="count" data-testid="count">
              {" "}
              ({packagesCount})
            </span>
          )}
        </label>
      );
    };

    const renderCollectionsLabel = () => {
      return (
        <label className="icon icon-drawer l-inline">
          {rootStore.getTranslation("helpers.show_collections")}
          {contentFinishedLoading && (
            <span className="count" data-testid="count">
              {" "}
              ({collectionsCount})
            </span>
          )}
        </label>
      );
    };

    const renderSelected = () => {
      return (
        <div className="radioactive" data-testid={`${selected}`}>
          <div className="radioactivedot" />
          {selected === SearchTargetOptionEnum.PACKAGE ? renderContentItemsLabel() : renderCollectionsLabel()}
        </div>
      );
    };

    const renderUnselected = () => {
      return (
        <div className="radio" data-testid="switchTarget" onClick={changeSearchTarget}>
          {selected === SearchTargetOptionEnum.PACKAGE ? renderCollectionsLabel() : renderContentItemsLabel()}
        </div>
      );
    };

    return (
      <div className="content-type-selection">
        <div className="radiowrapper">
          {selected === SearchTargetOptionEnum.PACKAGE && (
            <div className="firstgroup">
              {renderSelected()}
              {renderUnselected()}
            </div>
          )}
          {selected === SearchTargetOptionEnum.COLLECTION && (
            <div className="secondgroup">
              {renderUnselected()}
              {renderSelected()}
            </div>
          )}
        </div>
      </div>
    );
  },
);
