import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";
import _ from "underscore";

import { LocalServiceStore } from "../stores/localServiceStore";
import { RootContext, RootStore } from "../stores/rootStore";
import { UserStore } from "../stores/userStore";

import { IUser, IUserOrganization } from "../models/dataModel";
import { ModusIcon } from "@trimble-oss/modus-react-components";

const RolesTable = ({ userOrganizations }: { userOrganizations: IUserOrganization[] }) => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <div className="roles-table">
      <h2>
        {rootStore.getTranslation("myDetails.my_roles")}
        <a href={rootStore.getTranslation("myDetails.my_roles_link_url")} target="_blank" rel="noreferrer" style={{ marginLeft: "1.5em", fontSize: "small" }}>
          {rootStore.getTranslation("myDetails.my_roles_link")} <ModusIcon name="launch" size="14" style={{ paddingLeft: "0.5em", verticalAlign: "middle" }} />
        </a>
      </h2>
      <table data-testid="rolesTable">
        <thead>
          <tr>
            <th>{rootStore.getTranslation("collections.labels.asOrganization")}</th>
            <th>{rootStore.getTranslation("myDetails.organization_roles")}</th>
          </tr>
        </thead>
        <tbody>
          {_.map(userOrganizations, (uo: IUserOrganization, index: number) => (
            <tr key={index} data-testid={`organizationRow-${uo.organization.id}`}>
              <td>{uo.organization.displayName}</td>
              <td>{uo.roles.map((role) => rootStore.getTranslation(`myDetails.roles.${role}`)).join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const MyDetails = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: UserStore = rootStore.getUserStore();
  const localServiceStore: LocalServiceStore = rootStore.getLocalServiceStore();
  const user: IUser | undefined = store.getCurrentUser();

  const adminLabel = store.isUserAdmin() ? (
    <span className="role-label">{rootStore.getTranslation("profile.admin_role")}</span>
  ) : (
    ""
  );

  const salesAdminLabel =
    store.isUserAdmin() && store.isUserSalesAdmin() ? (
      <span className="role-label">{rootStore.getTranslation("profile.sales_role")}</span>
    ) : (
      ""
    );

  const maintenanceAccess = store.hasMaintenance()
    ? rootStore.getTranslation("shared.maintenance.can_access_maintenance.yes")
    : rootStore.getTranslation("shared.maintenance.can_access_maintenance.no");

  const maintenanceIconClass = store.hasMaintenance() ? "icon icon-maintenance blue" : "icon icon-maintenance";

  return (
    <div className="profile" data-testid="myDetails">
      <section className="profile-preferences">
        <h1>
          {user!.displayName}
          {adminLabel}
          {salesAdminLabel}
        </h1>
        <form>
          <article className="user-info">
            <ul>
              <li>
                <span className="label">{rootStore.getTranslation("profile.email")}</span>
                <span>{user!.email}</span>
              </li>
              <li>
                <span className="label l-block">{rootStore.getTranslation("profile.user_id")}</span>
                <span>{user!.externalId}</span>
              </li>
              <li>
                <a className="button button-icon edit" href={store.getATCUrl()}>
                  {rootStore.getTranslation("profile.edit_in_atc")}
                </a>
              </li>
            </ul>
            <ul>
              {user!.organization && (
                <Fragment>
                  {user!.organization.displayName && (
                    <li>
                      <span className="label">{rootStore.getTranslation("profile.organization")}</span>
                      <span>{user!.organization.displayName}</span>
                    </li>
                  )}
                  {user!.organization.externalId && (
                    <li>
                      <span className="label l-block">{rootStore.getTranslation("profile.organization_id")}</span>
                      <span>{user!.organization.externalId}</span>
                    </li>
                  )}
                </Fragment>
              )}
              <li>
                <span className="label l-block">{rootStore.getTranslation("profile.maintenanceAccess")}</span>
                <span>{maintenanceAccess}</span>
                <span className={maintenanceIconClass} />
              </li>
              {localServiceStore.isLocalServiceAccessible() && (
                <li data-testid="localServiceVersion">
                  <span className="label l-block">
                    {rootStore.getTranslation("shared.footer.tekla_warehouse_service") + ":"}
                  </span>
                  <span>{localServiceStore.getLocalServiceVersion()}</span>
                </li>
              )}
            </ul>
          </article>
        </form>
        {!!user?.userOrganizations && !_.isEmpty(user?.userOrganizations) && (
          <RolesTable userOrganizations={user.userOrganizations} />
        )}
      </section>
    </div>
  );
});
