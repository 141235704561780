import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import classNames from "classnames";

import { RootContext } from "../../stores/rootStore";
import { BatchEditDialogStoreContext } from "./batchEditDialogStore";
import { GroupAndCategoryHandlerContext } from "../../package/tabs/groupAndCategoryStore";
import { SearchCriteriaHandlerContext } from "../../package/tabs/searchCriteriaStore";

import { ItemTypeCategories } from "../../components/ItemTypeCategories";
import { UseCategories } from "../../components/UseCategories";
import { Tags } from "../../components/Tags";
import { LocationRestrictions } from "../../components/LocationRestrictions";
import { LanguageOptions } from "../../components/LanguageOptions";
import { MeasurementUnits } from "../../components/MeasurementUnits";
import { SelectAction } from "./SelectAction";

const ItemTypeCategory = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowSearchTypeCategory(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-item-type-category"
        id="selectItemTypeCategory"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowSearchTypeCategory()}
      />
      <label htmlFor="selectItemTypeCategory">{rootStore.getTranslation("upload.itemTypeCategories")}</label>
      {dialogStore.getShowSearchTypeCategory() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="searchTypeCategory"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getSearchTypeCategoryAction}
            setAction={dialogStore.setSearchTypeCategoryAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("searchTypeCategory"),
            })}
          >
            <ItemTypeCategories hideLabel={true} />
          </div>
        </div>
      )}
    </div>
  );
});

const UseCategory = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleChange(event) {
    dialogStore.setShowUseCategory(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-use-category"
        id="selectUseCategory"
        type="checkbox"
        onChange={handleChange}
        checked={dialogStore.getShowUseCategory()}
      />
      <label htmlFor="selectUseCategory">{rootStore.getTranslation("upload.useCategories")}</label>
      {dialogStore.getShowUseCategory() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="useCategory"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getUseCategoryAction}
            setAction={dialogStore.setUseCategoryAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("useCategory"),
            })}
          >
            <UseCategories hideLabel={true} />
          </div>
        </div>
      )}
    </div>
  );
});

const Tag = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleChange(event) {
    dialogStore.setShowTag(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-tags"
        id="selectTags"
        type="checkbox"
        onChange={handleChange}
        checked={dialogStore.getShowTag()}
      />
      <label htmlFor="selectTags">{rootStore.getTranslation("upload.tags")}</label>
      {dialogStore.getShowTag() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="tag"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getTagAction}
            setAction={dialogStore.setTagAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("tag"),
            })}
          >
            <Tags hideLabel={true} />
          </div>
        </div>
      )}
    </div>
  );
});

const LocationRestriction = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleChange(event) {
    dialogStore.setShowLocationRestriction(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-location-restriction"
        id="selectLocationRestriction"
        type="checkbox"
        onChange={handleChange}
        checked={dialogStore.getShowLocationRestriction()}
      />
      <label htmlFor="selectLocationRestriction">{rootStore.getTranslation("upload.locationRestrictions")}</label>
      {dialogStore.getShowLocationRestriction() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="locationRestriction"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getLocationRestrictionAction}
            setAction={dialogStore.setLocationRestrictionAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("locationRestriction"),
            })}
          >
            <LocationRestrictions hideLabel={true} />
          </div>
        </div>
      )}
    </div>
  );
});

const SupportedLanguage = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleChange(event) {
    dialogStore.setShowSupportedLanguage(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-language"
        id="selectLanguage"
        type="checkbox"
        onChange={handleChange}
        checked={dialogStore.getShowSupportedLanguage()}
      />
      <label htmlFor="selectLanguage">{rootStore.getTranslation("upload.supportedLanguages")}</label>
      {dialogStore.getShowSupportedLanguage() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="supportedLanguage"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getSupportedLanguageAction}
            setAction={dialogStore.setSupportedLanguageAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("supportedLanguage"),
            })}
          >
            <LanguageOptions hideLabel={true} />
          </div>
        </div>
      )}
    </div>
  );
});

const MeasurementUnit = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleChange(event) {
    dialogStore.setShowMeasurementUnit(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-measurement-unit"
        id="selectMeasurementUnit"
        type="checkbox"
        onChange={handleChange}
        checked={dialogStore.getShowMeasurementUnit()}
      />
      <label htmlFor="selectMeasurementUnit">{rootStore.getTranslation("upload.measurementUnits")}</label>
      {dialogStore.getShowMeasurementUnit() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="measurementUnit"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getMeasurementUnitAction}
            setAction={dialogStore.setMeasurementUnitAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("measurementUnit"),
            })}
          >
            <MeasurementUnits hideLabel={true} />
          </div>
        </div>
      )}
    </div>
  );
});

const Commenting = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowCommenting(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-commenting"
        id="selectCommenting"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowCommenting()}
      />
      <label htmlFor="selectCommenting">{rootStore.getTranslation("shared.commenting.content_commenting")}</label>
      {dialogStore.getShowCommenting() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="commenting"
            options={dialogStore.getCommentAndLicenseActionOptions()}
            getAction={dialogStore.getCommentAction}
            setAction={dialogStore.setCommentAction}
          />
        </div>
      )}
    </div>
  );
});

const UrlInput = observer(
  ({
    fieldName,
    getFieldValue,
    setFieldValue,
    translationKey,
  }: {
    fieldName: string;
    getFieldValue: () => string;
    setFieldValue: (value: string) => void;
    translationKey?: string;
  }) => {
    const rootStore = useContext(RootContext);
    const dialogStore = useContext(BatchEditDialogStoreContext);

    function getFieldTitle(): string {
      const key = translationKey ? translationKey : fieldName + "Subtitle";
      return rootStore.getTranslation("upload." + key);
    }

    function getTestId() {
      return "input-" + fieldName;
    }

    function handleUrlChange(event) {
      setFieldValue(event.target.value);
    }

    return (
      <div className="input-with-tooltip">
        <div>
          <label className="tooltip-info" title={getFieldTitle()}>
            <span className="icon help blue" />
          </label>
          <div
            className={classNames({
              "invalid-action": !dialogStore.isValidUrl(getFieldValue()) || dialogStore.hasInvalidValue(fieldName),
            })}
          >
            <input data-testid={getTestId()} type="url" value={getFieldValue()} onChange={handleUrlChange}></input>
          </div>
        </div>
      </div>
    );
  },
);

const HelpUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowHelpUrl(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-help-url"
        id="selectHelpUrl"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowHelpUrl()}
      />
      <label htmlFor="selectHelpUrl">{rootStore.getTranslation("upload.helpUrl")}</label>
      {dialogStore.getShowHelpUrl() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="helpUrl"
            options={[dialogStore.getReplaceActionOption()]}
            getAction={dialogStore.getHelpUrlAction}
            setAction={dialogStore.setHelpUrlAction}
          />
          <UrlInput fieldName="helpUrl" getFieldValue={dialogStore.getHelpUrl} setFieldValue={dialogStore.setHelpUrl} />
        </div>
      )}
    </div>
  );
});

const SupportUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowSupportUrl(event.target.checked);
  }

  function handleSupportUrlChange(event) {
    dialogStore.setSupportUrl(event.target.value);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-support-url"
        id="selectSupportUrl"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowSupportUrl()}
      />
      <label htmlFor="selectSupportUrl">{rootStore.getTranslation("upload.supportUrl")}</label>
      {dialogStore.getShowSupportUrl() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="supportUrl"
            options={[dialogStore.getReplaceActionOption()]}
            getAction={dialogStore.getSupportUrlAction}
            setAction={dialogStore.setSupportUrlAction}
          />
          <div
            className={classNames("inputs", {
              "invalid-action": dialogStore.hasInvalidValue("supportUrl"),
            })}
          >
            <div>
              <input
                data-testid="input-supportUrl"
                type="text"
                value={dialogStore.getSupportUrl()}
                onChange={handleSupportUrlChange}
              ></input>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const DiscussionForumUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowDiscussionForumUrl(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-discussion-forum-url"
        id="selectDiscussionForumUrl"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowDiscussionForumUrl()}
      />
      <label htmlFor="selectDiscussionForumUrl">{rootStore.getTranslation("upload.supportForumUrl")}</label>
      {dialogStore.getShowDiscussionForumUrl() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="discussionForumUrl"
            options={[dialogStore.getReplaceActionOption()]}
            getAction={dialogStore.getDiscussionForumUrlAction}
            setAction={dialogStore.setDiscussionForumUrlAction}
          />
          <UrlInput
            fieldName="discussionForumUrl"
            getFieldValue={dialogStore.getDiscussionForumUrl}
            setFieldValue={dialogStore.setDiscussionForumUrl}
            translationKey="supportForumUrlSubtitle"
          />
        </div>
      )}
    </div>
  );
});

const Copyright = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowCopyright(event.target.checked);
  }

  function handleCopyrightChange(event) {
    dialogStore.setCopyright(event.target.value);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-copyright"
        id="selectCopyright"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowCopyright()}
      />
      <label htmlFor="selectCopyright">{rootStore.getTranslation("upload.copyrightInfo")}</label>
      {dialogStore.getShowCopyright() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="copyright"
            options={[dialogStore.getReplaceActionOption()]}
            getAction={dialogStore.getCopyrightAction}
            setAction={dialogStore.setCopyrightAction}
          />
          <div
            className={classNames("inputs", {
              "invalid-action": dialogStore.hasInvalidValue("copyright"),
            })}
          >
            <div>
              <input
                data-testid="input-copyright"
                type="text"
                value={dialogStore.getCopyright()}
                onChange={handleCopyrightChange}
              ></input>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const SpecialTermsUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowSpecialTermsUrl(event.target.checked);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-special-terms-url"
        id="selectSpecialTermsUrl"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowSpecialTermsUrl()}
      />
      <label htmlFor="selectSpecialTermsUrl">{rootStore.getTranslation("upload.specialTermsUrl")}</label>
      {dialogStore.getShowSpecialTermsUrl() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="specialTermsUrl"
            options={[dialogStore.getReplaceActionOption()]}
            getAction={dialogStore.getSpecialTermsUrlAction}
            setAction={dialogStore.setSpecialTermsUrlAction}
          />
          <UrlInput
            fieldName="specialTermsUrl"
            getFieldValue={dialogStore.getSpecialTermsUrl}
            setFieldValue={dialogStore.setSpecialTermsUrl}
          />
        </div>
      )}
    </div>
  );
});

const Licenses = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowLicense(event.target.checked);
  }

  return (
    <Fragment>
      <h4 className="sub-group-title">{rootStore.getTranslation("batch_edit.support.licenses")}</h4>
      <div className="edit-group">
        <input
          className="white"
          data-testid="select-license"
          id="selectLicense"
          type="checkbox"
          onChange={handleSelectChange}
          checked={dialogStore.getShowLicense()}
        />
        <label htmlFor="selectLicense">
          {rootStore.getTranslation("shared.maintenance.download_requires_tekla_maintenance")}
        </label>
        {dialogStore.getShowLicense() && (
          <div className="content-groups edit-fields">
            <SelectAction
              fieldName="license"
              options={dialogStore.getCommentAndLicenseActionOptions()}
              getAction={dialogStore.getLicenseAction}
              setAction={dialogStore.setLicenseAction}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
});

/**
 * Renders package fields for editing
 */
export const EditPackage = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  return (
    <div className="batch-edit-content">
      <h4>{rootStore.getTranslation("batch_edit.content_title")}</h4>
      <GroupAndCategoryHandlerContext.Provider value={dialogStore}>
        <ItemTypeCategory />
        <UseCategory />
      </GroupAndCategoryHandlerContext.Provider>
      <SearchCriteriaHandlerContext.Provider value={dialogStore}>
        <Tag />
        <LocationRestriction />
        <SupportedLanguage />
        <MeasurementUnit />
      </SearchCriteriaHandlerContext.Provider>
      <Commenting />
      <div className="batch-edit-subgroups">
        <h4 className="sub-group-title">{rootStore.getTranslation("batch_edit.support.support")}</h4>
        <HelpUrl />
        <SupportUrl />
        <DiscussionForumUrl />
        <Copyright />
        <SpecialTermsUrl />
        <Licenses />
      </div>
    </div>
  );
});
