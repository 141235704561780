import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import classNames from "classnames";

import { RootContext, RootStore } from "../stores/rootStore";
import { SearchPageSettingsStore } from "../stores/searchPageSettingsStore";
import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";
import { ResultOptionsStore, ResultOptionsStoreContext } from "../components/result-options/resultOptionsStore";
import { CollectionPageStore, CollectionPageStoreContext } from "./collectionPageStore";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { AddContentButton } from "../components/AddContentButton";
import { SelectableEntity } from "../components/SelectableEntity";

import { IEntity } from "../models/dataModel";
import { SearchPageListStyleEnum } from "../models/enums";

/**
 * A component that renders result items
 */
const ResultsList = observer(({ results }: { results: IEntity[] }) => {
  const resultOptionsStore: ResultOptionsStore = useContext(ResultOptionsStoreContext);
  const searchPageSettingsStore: SearchPageSettingsStore = resultOptionsStore.getSearchPageSettingsStore();
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);

  function showUnlink(entity: IEntity): boolean {
    return editorModeStore.getEditorMode() && !!entity.isLinked;
  }

  return (
    <Fragment>
      {!resultOptionsStore.isLoading() && (
        <div className="results">
          {results.map((entity: IEntity, i: number) => {
            return (
              <div key={i} className="result" scroll-if="entity.id === data.entityId">
                <SelectableEntity
                  entity={entity}
                  listStyle={searchPageSettingsStore.resultListStyle}
                  isSelectable={resultOptionsStore.canSelectEntity(entity)}
                  showUnlink={showUnlink(entity)}
                />
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that renders linked packages
 */
export const LinkedPackages = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const resultOptionsStore: ResultOptionsStore = useContext(ResultOptionsStoreContext);

  function getLinkedPackages(): IEntity[] {
    return _.filter(resultOptionsStore.getResults(), (entity) => !!entity.isLinked);
  }

  function linkedPackagesCount(): number {
    const linkedPackages = getLinkedPackages();
    return linkedPackages ? linkedPackages.length : 0;
  }

  function inThumbnailsMode(): boolean {
    return resultOptionsStore.getSearchPageSettingsStore().resultListStyle == SearchPageListStyleEnum.THUMBNAILS;
  }

  return (
    <div>
      {!resultOptionsStore.isLoading() && linkedPackagesCount() > 0 && (
        <section
          className={classNames("results-container", {
            "as-thumbnails": inThumbnailsMode(),
          })}
          data-testid="results-linked-packages"
        >
          <header>
            <h2>{rootStore.getTranslation("collections.entity_list.linked_content")}</h2>
            <span className="small l-margin-left-default icon con-link"></span>
          </header>
          <div className="listing-wrapper" data-testid="resultsContainer">
            {!resultOptionsStore.isLoading() && (
              <div className="results-summary">
                <span className="label results-count">
                  <TranslatedHtml entry="collections.entity_list.results_summary" args={[linkedPackagesCount()]} />
                </span>
              </div>
            )}
            <ResultsList results={getLinkedPackages()} />
          </div>
        </section>
      )}
    </div>
  );
});

/**
 * A component that renders packages
 */
export const Packages = observer(() => {
  const resultOptionsStore: ResultOptionsStore = useContext(ResultOptionsStoreContext);
  const collectionPageStore: CollectionPageStore = useContext(CollectionPageStoreContext);

  function getPackages(): IEntity[] {
    return _.filter(resultOptionsStore.getResults(), (entity: IEntity) => !entity.isLinked);
  }

  function packagesCount(): number {
    const packages = getPackages();
    return packages ? packages.length : 0;
  }

  function inThumbnailsMode(): boolean {
    return resultOptionsStore.getSearchPageSettingsStore().resultListStyle == SearchPageListStyleEnum.THUMBNAILS;
  }

  return (
    <section
      className={classNames("results-container", {
        "as-thumbnails": inThumbnailsMode(),
      })}
      data-testid="results-packages"
    >
      <div className="listing-wrapper">
        {resultOptionsStore.isLoading() && (
          <div className="l-center-align">
            <div className="spinner" data-testid="loadingPackages">
              <TranslatedHtml entry="shared.spinner_loading" />
            </div>
          </div>
        )}
        {resultOptionsStore.wasDataFetched() && !resultOptionsStore.isLoading() && (
          <div className="results-summary">
            {packagesCount() == 0 && (
              <span className="label results-count">
                <TranslatedHtml entry="collections.entity_list.no_content" />
              </span>
            )}
            {packagesCount() > 0 && (
              <span className="label results-count">
                <TranslatedHtml entry="collections.entity_list.results_summary" args={[packagesCount()]} />
              </span>
            )}
          </div>
        )}
        <AddContentButton collection={collectionPageStore.getCollection()} />
        <ResultsList results={getPackages()} />
      </div>
    </section>
  );
});

/**
 * A component that renders entities in collection page or information about lack of results.
 */
export const Results = observer(() => {
  const resultOptionsStore: ResultOptionsStore = useContext(ResultOptionsStoreContext);

  React.useEffect(() => {
    resultOptionsStore.fetchData();
  }, []);

  return (
    <div className="catalog" data-testid="collectionContent">
      <Packages />
      <LinkedPackages />
    </div>
  );
});
