export function IntervalChecker(callback, timeInterval) {
  timeInterval = timeInterval || 1000;
  var interval = null;
  if (callback !== null) {
    interval = setInterval(callback, timeInterval);
  }
  return {
    stop: function () {
      clearInterval(interval);
    },
  };
}
