import { observer } from "mobx-react";
import * as React from "react";
import { useContext, Fragment } from "react";
import _ from "underscore";
import classNames from "classnames";

import { RootContext, RootStore } from "../../stores/rootStore";
import { DialogContext, DialogStore } from "../dialogStore";
import { EditImagesStore, EditImagesStoreContext } from "./editImagesStore";
import { ThumbnailStore, ThumbnailStoreContext } from "../../components/thumbnail/thumbnailStore";

import { IDropdownOption } from "../../models/dataModel";

import { TranslatedHtml } from "../../components/TranslatedHtml";
import { Dropdown } from "../../components/Dropdown";
import { Dialog } from "../Dialog";
import { ImageUtils } from "../../utils/ImageUtils";

/**
 * Enumeration for dialog view state
 */
export const enum EditImagesViewState {
  MAIN,
  THREE_D,
  ADD,
}

/**
 * Enumeration for adding file (sub) view
 */
export enum EditImagesFileType {
  IMAGE = "image",
  THREE_D = "three_d",
  VIDEO = "video",
}

/**
 * Binary
 */
const Binary = observer(({ binary }) => {
  const store: EditImagesStore = useContext(EditImagesStoreContext);
  const rootStore: RootStore = useContext(RootContext);

  function getCollectionThumbnailStyle() {
    const thumbnailUrl = store.getItem().isLocal ? store.getItem().thumbnail!.url : binary.contentUrl;
    return {
      backgroundImage: `url(${thumbnailUrl})`,
    };
  }

  function setAsDefaultBinary(binary) {
    store.setDefaultBinary(binary);
  }

  function show3DView() {
    store.setViewState(EditImagesViewState.THREE_D);
  }

  function canDeleteBinary(binary): boolean {
    return store.isBinaryDeletable(binary);
  }

  async function deleteBinary(binary) {
    try {
      await store.deleteBinary(binary);
      displayNotificationAfterDeletion(binary);
    } catch (err) {
      if (err) {
        displayNotificationAfterFailedDeletion(binary);
      }
    }
  }

  function getDeletedBinaryNotificationText(binary) {
    if (binary) {
      if (binary.isThumbnail) {
        return rootStore.getTranslation("collections.notification.thumbnail_deleted");
      } else if (binary.is3DThumbnail) {
        return rootStore.getTranslation("shared.3dPic.3d_pic_removed");
      } else if (binary.isVideo) {
        return rootStore.getTranslation("collections.notification.video_deleted");
      }
    }
  }

  function getDeletedBinaryFailureText(binary) {
    if (binary) {
      if (binary.isThumbnail) {
        return rootStore.getTranslation("shared.catalog_entity_edit.update_failed");
      } else if (binary.is3DThumbnail) {
        return rootStore.getTranslation("shared.3dPic.3d_pic_remove_failed");
      } else if (binary.isVideo) {
        return rootStore.getTranslation("collections.notification.video_deleting_failed");
      }
    }
  }

  function displayNotificationAfterDeletion(binary) {
    const text = getDeletedBinaryNotificationText(binary);
    if (text) {
      rootStore.getNotificationChannelStore().success(text);
    }
  }

  function displayNotificationAfterFailedDeletion(binary) {
    const text = getDeletedBinaryFailureText(binary);
    if (text) {
      rootStore.getNotificationChannelStore().error(text);
    }
  }

  function testIdPrefix(binary) {
    return `thumbnail-${binary.reference}`;
  }

  return (
    <div className="row" data-testid={testIdPrefix(binary)}>
      <div className="default-image-selection">
        {binary.isThumbnail && store.thumbnailsCount > 1 && (
          <Fragment>
            <label className="l-line">
              <TranslatedHtml entry="images.set_as_default" />
            </label>
            <div className="radiowrapper edit-image">
              {binary.isDefaultBinary && (
                <div className="activegroup">
                  <div className="radioactive">
                    <div className="radioactivedot" />
                  </div>
                </div>
              )}
              {!binary.isDefaultBinary && (
                <div className="inactivegroup">
                  <div
                    className="radio"
                    onClick={() => setAsDefaultBinary(binary)}
                    data-testid={`${testIdPrefix(binary)}-set-as-default`}
                  />
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
      {binary.isThumbnail && (
        <div className={"thumb-box"} data-testid={`${testIdPrefix(binary)}-image`}>
          <figure style={getCollectionThumbnailStyle()} />
        </div>
      )}
      {binary.is3DThumbnail && (
        <div className={"thumb-box"} onClick={show3DView} data-testid={`${testIdPrefix(binary)}-skp`}>
          <a>
            <figure style={{ backgroundImage: "url(/images/3d-thumb.png)" }} />
          </a>
        </div>
      )}
      {binary.isVideo && (
        <div className="video" data-testid={`${testIdPrefix(binary)}-video`}>
          <iframe width="350" height="263" src={binary.value} allowFullScreen={true} frameBorder={0} />
        </div>
      )}
      <div className="action">
        <button
          className="delete-thumbnail"
          disabled={!canDeleteBinary(binary)}
          onClick={() => deleteBinary(binary)}
          data-testid={`${testIdPrefix(binary)}-delete`}
        >
          <TranslatedHtml entry={"images.delete"} />
        </button>
      </div>
    </div>
  );
});

/**
 * A component being a wrapper for thumbnails loop
 */
const Binaries = observer(() => {
  const store: EditImagesStore = useContext(EditImagesStoreContext);

  return (
    <div className="image-content">
      {store.binariesSorted.map((binary, i) => {
        return <Binary key={i} binary={binary} />;
      })}
    </div>
  );
});

/**
 * A component that lists thumbnails
 */
const MainView = observer(() => {
  const store: EditImagesStore = useContext(EditImagesStoreContext);

  function showAddNew() {
    store.setViewState(EditImagesViewState.ADD);
  }

  return (
    <Fragment>
      {store.getViewState() === EditImagesViewState.MAIN && (
        <div className="main-view">
          <button className="add-new" disabled={store.isProcessing()} onClick={showAddNew} data-testid="add-new">
            <TranslatedHtml entry={"images.add_new"} />
          </button>
          <Binaries />
        </div>
      )}
    </Fragment>
  );
});

const ThreeDView = observer(() => {
  const store: EditImagesStore = useContext(EditImagesStoreContext);

  function showMainView() {
    store.setViewState(EditImagesViewState.MAIN);
  }

  return (
    <Fragment>
      {store.getViewState() === EditImagesViewState.THREE_D && (
        <div className="show3D-view">
          <div className="thumb-box-3d">
            <iframe className="threed-pic-frame" src={store.get3DThumbnailUrl()} frameBorder={0} />
          </div>
          <div className="buttons">
            <button className="cancel" disabled={store.isProcessing()} onClick={showMainView}>
              <TranslatedHtml entry="actions.cancel" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that renders for user to add new image
 */
const AddImage = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: EditImagesStore = useContext(EditImagesStoreContext);
  const labelClassNames = classNames("button", "button-icon", "upload", {
    processing: store.isProcessing(),
  });

  function fileSelected(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target || !event.target.files) return;
    const selectedFile: File | undefined = event.target.files[0];

    if (!ImageUtils.isImage(selectedFile)) {
      rootStore
        .getNotificationChannelStore()
        .error(rootStore.getTranslation("collections.notification.invalid_file_type"));
    } else if (ImageUtils.isTooBigImage(selectedFile)) {
      rootStore
        .getNotificationChannelStore()
        .error(rootStore.getTranslation("upload.package.selected_image_is_too_big"));
    } else {
      store.setSelectedFile(selectedFile);
    }
  }

  return (
    <Fragment>
      {store.getAddImageFileType() === EditImagesFileType.IMAGE && (
        <div className="choose-image">
          <label className={labelClassNames} htmlFor="chooseImageFile" data-testid="choose-image">
            <TranslatedHtml entry={"images.select_file"} />
            <input
              data-testid="image"
              id="chooseImageFile"
              className="hidden-file-upload"
              type="file"
              accept="image/*"
              onChange={fileSelected}
              onClick={(e: any) => (e.target.value = null)}
              required
            />
          </label>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that renders for user to add new skp file
 */
const AddThreeDModel = observer(() => {
  const store: EditImagesStore = useContext(EditImagesStoreContext);
  const labelClassNames = classNames("button", "button-icon", "upload", {
    processing: store.isProcessing(),
  });

  function fileSelected(data) {
    if (data && data.target && data.target.files[0]) {
      store.setSelectedFile(data.target.files[0]);
    }
  }

  return (
    <Fragment>
      {store.getAddImageFileType() === EditImagesFileType.THREE_D && (
        <div className="choose-3D-image">
          {store.threeDThumbnailsCount > 0 && (
            <div className="info-3D-exists">
              <TranslatedHtml entry={"images.info_3D_exists"} />
            </div>
          )}
          {store.threeDThumbnailsCount === 0 && (
            <label className={labelClassNames} htmlFor="choose3DModel" data-testid="choose-3d-model">
              <TranslatedHtml entry={"images.select_file"} />
              <input
                data-testid="skp-file"
                id="choose3DModel"
                className="hidden-file-upload"
                type="file"
                accept=".skp"
                onChange={fileSelected}
                required
              />
            </label>
          )}
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that renders for user to add new video
 */
const AddVideo = observer(() => {
  const rootStore = useContext(RootContext);
  const store: EditImagesStore = useContext(EditImagesStoreContext);

  const newVideoUrl = store.getVideoUrl() || "";

  function onBlur() {
    store.setVideoUrlErrorDisplayable(true);
  }

  function setVideoUrl(evt) {
    store.setVideoUrl(evt.target.value);
  }

  return (
    <Fragment>
      {store.getAddImageFileType() === EditImagesFileType.VIDEO && (
        <div className="add-video">
          <div className="video-title">
            <span className="label">{rootStore.getTranslation("images.video.url")}</span>
          </div>
          <div className="video-link">
            <input
              data-testid="video-link"
              id="videoUrl"
              className="url"
              type="url"
              onBlur={onBlur}
              value={newVideoUrl}
              onChange={setVideoUrl}
            />
            {store.getVideoUrlErrorDisplayable() && !store.isVideoUrlValid && (
              <div className="invalid-url" data-testid="invalid-url">
                <TranslatedHtml entry={"images.video.invalid_url"} />
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
});

/**
 *  A component that displays file name
 */
const FileName = observer(() => {
  const store: EditImagesStore = useContext(EditImagesStoreContext);

  return (
    <Fragment>
      {store.getAddImageFileType() != EditImagesFileType.VIDEO && store.getSelectedFile() && (
        <div className="selected-file" data-testid="filename">
          <label className="filename">{store.getSelectedFile()!.name}</label>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that displays thumbnail
 */
const ThumbBox = observer(() => {
  const store: EditImagesStore = useContext(EditImagesStoreContext);

  function getThumbnailStyle() {
    return {
      backgroundImage: `url(${store.getSelectedFileThumbnail()})`,
    };
  }

  return (
    <Fragment>
      {store.getSelectedFile() && (
        <div className="thumb-box">
          <figure style={getThumbnailStyle()} />
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that let user to select new file type
 */
const AddView = observer(() => {
  const rootStore = useContext(RootContext);
  const store: EditImagesStore = useContext(EditImagesStoreContext);
  const dropdownPlaceholder = rootStore.getTranslation("images.choose_option");

  const fileTypes = [EditImagesFileType.IMAGE, EditImagesFileType.THREE_D, EditImagesFileType.VIDEO];
  const dropdownOptions = _.map(fileTypes, (ft) => {
    return {
      label: rootStore.getTranslation(`images.action_options.${ft}`),
      value: ft,
    };
  });

  function chooseAction(action: IDropdownOption | null) {
    if (!action) return;
    store.setAddImageFileType(EditImagesFileType[action.value.toUpperCase()]);
  }

  function showMainView() {
    store.setViewState(EditImagesViewState.MAIN);
  }

  function displayNotificationSuccess(message) {
    rootStore.getNotificationChannelStore().success(message);
  }

  function displayNotificationFailure(message) {
    rootStore.getNotificationChannelStore().error(message);
  }

  function add() {
    if (store.getAddImageFileType() === EditImagesFileType.VIDEO) {
      addVideo();
    } else {
      addFile();
    }
  }

  async function addFile() {
    try {
      const message = await store.addFile();
      displayNotificationSuccess(message);
    } catch (err) {
      displayNotificationFailure(err);
    }
  }

  async function addVideo() {
    try {
      const message = await store.addVideo();
      displayNotificationSuccess(message);
    } catch (err) {
      displayNotificationFailure(err);
    }
  }

  function isAddButtonDisabled(): boolean {
    const fileNotSelected: boolean =
      store.getAddImageFileType() === EditImagesFileType.VIDEO ? !store.isVideoUrlValid : !store.getSelectedFile();
    return store.isProcessing() || fileNotSelected;
  }

  return (
    <Fragment>
      {store.getViewState() === EditImagesViewState.ADD && (
        <div className="add-view">
          <div className="action-options">
            <span className="label">
              <TranslatedHtml entry="images.add_new" />
            </span>
            <div className="choose-action">
              <Dropdown
                options={dropdownOptions}
                onChange={chooseAction}
                className="dropdown-wrapper gray"
                placeholder={dropdownPlaceholder}
              />
            </div>
            <AddImage />
            <AddThreeDModel />
            <FileName />
            <AddVideo />
            <ThumbBox />
          </div>
          <div className="buttons">
            <button className="cancel" onClick={showMainView}>
              <TranslatedHtml entry="actions.cancel" />
            </button>
            <button className="add" onClick={add} disabled={isAddButtonDisabled()} data-testid="add-file">
              <TranslatedHtml entry="images.add" />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
});

/**
 * Dialog content for EditImages
 */
const DialogContent = observer(() => {
  const dialog: DialogStore = useContext(DialogContext);
  const store: EditImagesStore = useContext(EditImagesStoreContext);
  const thumbnailStore: ThumbnailStore | undefined = useContext(ThumbnailStoreContext);

  function handleCloseDialog() {
    if (store.isUpdated()) {
      if (thumbnailStore) {
        thumbnailStore.reloadContent();
      }
    }
    dialog.close();
  }

  return (
    <article data-testid="edit-images-dialog">
      <ol className="basic-data">
        <div className="image-views">
          <MainView />
          <ThreeDView />
          <AddView />
        </div>
      </ol>
      {store.getViewState() === EditImagesViewState.MAIN && (
        <div className="actions">
          <button onClick={handleCloseDialog} data-testid="download-close" disabled={store.isProcessing()}>
            <TranslatedHtml entry={"actions.close"} />
          </button>
        </div>
      )}
    </article>
  );
});

/**
 * A component that renders edit images dialog
 */
export const EditImages = observer(() => {
  return <Dialog content={<DialogContent />} additionalClass="edit-images" />;
});
