import * as React from "react";
import { useContext } from "react";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext } from "../stores/rootStore";

export const GettingStarted = () => {
  const store = useContext(RootContext);
  const seeTuaHtml = "about.getting_started.tekla_account_and_system_requirements.see_tua_for_prerequisites_html";

  return (
    <article className="about-getting_started">
      <header>
        <h2>{store.getTranslation("about.getting_started.title")}</h2>
      </header>
      <section id="teklaAccountAndSystemRequirements">
        <h3>{store.getTranslation("about.getting_started.tekla_account_and_system_requirements.title")}</h3>
        <p>
          <TranslatedHtml entry="about.getting_started.tekla_account_and_system_requirements.register_atc_html" />
        </p>
        <p>
          <TranslatedHtml entry={seeTuaHtml} />
        </p>
      </section>
      <section id="teklaWarehouseServicePlugin">
        <h3>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.title")}</h3>
        <p>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.description_1")}</p>
        <ul className="feature-list">
          <li>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.feature_1")}</li>
          <li>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.feature_2")}</li>
          <li>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.feature_3")}</li>
          <li>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.feature_4")}</li>
        </ul>
        <p>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.description_2")}</p>
        <p>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.description_3")}</p>
        <p>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.description_4")}</p>
        <p>{store.getTranslation("about.getting_started.tekla_warehouse_service_plugin.description_5")}</p>
      </section>
      <section id="howDoIGetTeklaWarehouseService">
        <h3>{store.getTranslation("about.getting_started.how_do_i_get_tekla_warehouse_service.title")}</h3>
        <p>{store.getTranslation("about.getting_started.how_do_i_get_tekla_warehouse_service.description_1")}</p>
        <p>
          <TranslatedHtml entry="about.getting_started.how_do_i_get_tekla_warehouse_service.download_html" />
        </p>
      </section>
      <section id="teklaStructuresAndTeklaWarehouse">
        <h3>{store.getTranslation("about.getting_started.tekla_structures_and_tekla_warehouse.title")}</h3>
        <p>{store.getTranslation("about.getting_started.tekla_structures_and_tekla_warehouse.description_1")}</p>
      </section>
    </article>
  );
};

export const gettingStartedNavigationInfo = {
  name: "getting_started",
  component: <GettingStarted />,
  translationId: "about.navigation.getting_started",
  subnavigation: [
    {
      name: "teklaAccountAndSystemRequirements",
      translationId: "about.navigation.getting_started__tekla-account-and-system-requirements",
    },
    {
      name: "teklaWarehouseServicePlugin",
      translationId: "about.navigation.getting_started__tekla-warehouse-service-plugin",
    },
    {
      name: "howDoIGetTeklaWarehouseService",
      translationId: "about.navigation.getting_started__how-do-i-get-tekla-warehouse-service",
    },
    {
      name: "teklaStructuresAndTeklaWarehouse",
      translationId: "about.navigation.getting_started__tekla-structures-and-tekla-warehouse",
    },
  ],
};
