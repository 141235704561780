import * as React from "react";
import { ChangeEvent, Fragment, useContext, useState } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext, RootStore } from "../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "./contentManagerStore";

import { IBatchCollection, IBatchEntity } from "../../../models/dataModel";
import { CollectionListItem } from "./CollectionListItem";
import { TranslatedHtml } from "../../../components/TranslatedHtml";

const Actions = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);
  const isDisabled = store.isQuerying() || store.isProcessing() || !store.hasSelectedContent();

  function collectionsExist(): boolean {
    return store.getCollections().length > 0;
  }

  function packageSelectedWithoutParentWithConditionSelected(condition: string): boolean {
    const packages = store.getSelectedPackages();

    if (condition === "isHidden") {
      return _.any(packages, (p: IBatchEntity) => {
        const parent = _.find(store.getCollections(), (c: IBatchCollection) => c.id === p.collection?.id);
        return !!parent && !parent.selected && !!parent.isHidden;
      });
    } else if (condition === "isBanned") {
      return _.any(packages, (p: IBatchEntity) => {
        const parent = _.find(store.getCollections(), (c: IBatchCollection) => c.id === p.collection?.id);
        return !!parent && !parent.selected && !!parent.isBanned;
      });
    } else {
      return false;
    }
  }

  return (
    <div className="manage-content-actions">
      <button
        disabled={store.isProcessing() || !collectionsExist()}
        className={"button-primary select-all"}
        onClick={() => store.selectAllCollections(true)}
        data-testid="select-all"
      >
        {rootStore.getTranslation("helpers.select_all")}
      </button>
      <button
        disabled={isDisabled}
        className={"button-primary unselect-all"}
        onClick={() => store.selectAllCollections(false)}
        data-testid="unselect-all"
      >
        {rootStore.getTranslation("helpers.unselect_all")}
      </button>
      <button
        disabled={isDisabled}
        className={"button-primary hide-selected"}
        onClick={() => store.hideSelectedPackagesAndVersions()}
        data-testid="hide-selected"
      >
        {rootStore.getTranslation("actions.hide_selected")}
      </button>
      <button
        disabled={isDisabled || packageSelectedWithoutParentWithConditionSelected("isHidden")}
        className={"button-primary unhide-selected"}
        onClick={() => store.unhideSelectedPackagesAndVersions()}
        data-testid="unhide-selected"
      >
        {rootStore.getTranslation("actions.unhide_selected")}
      </button>

      <button
        disabled={isDisabled}
        className={"button-primary ban-selected"}
        onClick={() => store.banSelectedPackages()}
        data-testid="ban-selected"
      >
        {rootStore.getTranslation("profile.admin.ban_selected")}
      </button>
      <button
        disabled={isDisabled || packageSelectedWithoutParentWithConditionSelected("isBanned")}
        className={"button-primary unban-selected"}
        onClick={() => store.unbanSelectedPackages()}
        data-testid="unban-selected"
      >
        {rootStore.getTranslation("profile.admin.unban_selected")}
      </button>
      <button
        disabled={isDisabled}
        className={"button-primary remove-selected"}
        onClick={() => store.removeSelectedContent()}
        data-testid="remove-selected"
      >
        {rootStore.getTranslation("actions.delete_selected")}
      </button>
    </div>
  );
});

const SearchOptions = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const [searchValue, setSearchValue] = useState("");

  function handleInput(event: ChangeEvent<HTMLInputElement>): void {
    setSearchValue(event.target.value);
  }

  function handleSearch(searchInvalid: boolean): void {
    if (searchInvalid) {
      store.searchInvalidPackages();
    } else {
      store.searchCollectionsAndPackages(searchValue);
    }
  }

  function searchBannedContent(): void {
    store.searchBannedContent();
  }

  return (
    <Fragment>
      <div className="search-by-keyword">
        <input
          className="search"
          data-testid="searchById"
          id="searchById"
          type="text"
          placeholder={rootStore.getTranslation("profile.admin.enter_package_or_collection_id")}
          onChange={handleInput}
        />
        <button
          disabled={store.isProcessing() || store.isQuerying()}
          className={"button-primary button-search icon-search"}
          onClick={() => handleSearch(false)}
          data-testid="search-content"
        >
          {rootStore.getTranslation("profile.admin.search_content")}
        </button>
        <div className="show-hidden">
          <input
            data-testid="include-hidden-content"
            id="searchHiddenPackages"
            type="checkbox"
            onChange={() => store.setIncludeHiddenContent()}
            checked={store.getIncludeHiddenContent()}
          />
          <label htmlFor="searchHiddenPackages">
            <TranslatedHtml entry="profile.admin.show_hidden_content" />
          </label>
        </div>
      </div>
      <div className="search-by-criteria">
        <button
          disabled={store.isProcessing() || store.isQuerying()}
          className={"button-secondary button-search"}
          onClick={() => handleSearch(true)}
          data-testid="search-invalid-content"
        >
          {rootStore.getTranslation("profile.admin.show_invalid_content")}
        </button>
        <button
          disabled={store.isProcessing() || store.isQuerying()}
          className={"button-secondary button-search"}
          onClick={searchBannedContent}
          data-testid="search-banned-content"
        >
          {rootStore.getTranslation("profile.admin.show_banned_content")}
        </button>
      </div>
    </Fragment>
  );
});

const CollectionsList = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const contentManagerStore: ContentManagerStore = useContext(ContentManagerStoreContext);

  function openByDefault(collection: IBatchCollection): boolean {
    if (collection.id === contentManagerStore.getSearchedId()) {
      return true;
    } else if (_.any(collection.packages || [], (p: IBatchEntity) => p.id === contentManagerStore.getSearchedId())) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Fragment>
      {contentManagerStore.isQuerying() || contentManagerStore.isProcessing() ? (
        <div className="spinner" data-testid="loadingContent">
          {contentManagerStore.isQuerying()
            ? rootStore.getTranslation("shared.spinner_loading")
            : rootStore.getTranslation("shared.spinner_processing")}
        </div>
      ) : (
        <div className="collection-list" data-testid="collectionList">
          {_.map(contentManagerStore.getCollections(), (collection: IBatchCollection, i: number) => {
            return (
              <div className="collection-list-item" data-testid={`collection-${collection.id}`} key={i}>
                <CollectionListItem collection={collection} open={openByDefault(collection)} />
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
});

export const ManageContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <ContentManagerStoreContext.Provider value={new ContentManagerStore(rootStore)}>
      <div className="content-manager">
        <SearchOptions />
        <hr />
        <Actions />
        <CollectionsList />
      </div>
    </ContentManagerStoreContext.Provider>
  );
});
