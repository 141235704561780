import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";
import { CollectionPage } from "./CollectionPage";
import { CollectionPageStoreContext, CollectionPageStore } from "./collectionPageStore";

/**
 * A component that displays collection page
 */
export const CollectionPageWrapper = observer(() => {
  const store: RootStore = useContext(RootContext);
  const collectionPageStore = new CollectionPageStore(store);

  return (
    <CollectionPageStoreContext.Provider value={collectionPageStore}>
      <CollectionPage />
    </CollectionPageStoreContext.Provider>
  );
});
