import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";
import _ from "underscore";
import { Link } from "react-router";

import { RootContext, RootStore } from "../../../stores/rootStore";

import { IOrganization } from "../../../models/dataModel";
import { ManageOrganizationsStore, ManageOrganizationsStoreContext } from "./manageOrganizationsStore";

const OrganizationRow = observer(({ organization }: { organization: IOrganization }) => {
  const store: ManageOrganizationsStore = useContext(ManageOrganizationsStoreContext);

  function handleSelectOrganization(e: React.ChangeEvent<HTMLInputElement>) {
    store.toggleSelection(organization, e.target.checked);
  }

  return (
    <tr className="organization-item" data-testid={`organizationRow-${organization.id}`}>
      <td style={{ display: "flex", flexDirection: "column" }}>
        <Link to={`/organization/${organization?.id}`}>{organization?.displayName}</Link>
        <span className="small">{organization?.id}</span>
      </td>
      <td data-testid={`collectionCount-${organization.id}`}>{organization?.collectionCount}</td>
      <td data-testid={`packageCount-${organization.id}`}>{organization?.packageCount}</td>
      <td data-testid={`isImmutable-${organization.id}`}>{organization?.isImmutable ? "TRUE" : "FALSE"}</td>
      <td>
        <div className="checkbox">
          <input
            key={organization.id}
            id={`selectOrganizationCheckbox-${organization.id}`}
            type="checkbox"
            checked={store.isSelected(organization)}
            onChange={handleSelectOrganization}
            data-testid={`selectOrganizationCheckbox-${organization.id}`}
          />
          <label htmlFor={`selectOrganizationCheckbox-${organization.id}`}></label>
        </div>
      </td>
    </tr>
  );
});

export const OrganizationList = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageOrganizationsStore = useContext(ManageOrganizationsStoreContext);
  const hasFoundOrganizations = !!store.getOrganizations() && store.getOrganizations().length > 0;

  return (
    <Fragment>
      {store.isLoading() || store.isProcessing() ? (
        <div className="loading" data-testid="isLoadingOrganizations" style={{ padding: "3em" }}>
          <div className="spinner spinner-gray">
            {store.isLoading()
              ? rootStore.getTranslation("shared.spinner_loading")
              : rootStore.getTranslation("shared.spinner_processing")}
          </div>
        </div>
      ) : (
        hasFoundOrganizations && (
          <table className="organizations-list" data-testid={`organizationList`} style={{ marginTop: "2em" }}>
            <thead>
              <tr>
                <th />
                <th>{rootStore.getTranslation("profile.admin.manage_organizations.collection_count")}</th>
                <th>{rootStore.getTranslation("profile.admin.manage_organizations.package_count")}</th>
                <th>{rootStore.getTranslation("profile.admin.manage_organizations.marked_as_immutable")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {_.map(store.getOrganizations(), (organization: IOrganization, i: number) => (
                <OrganizationRow organization={organization} key={i} />
              ))}
            </tbody>
          </table>
        )
      )}
    </Fragment>
  );
});
