import { Prerequisite } from "../factories/Prerequisite";
import _ from "underscore";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IVersionPrerequisite } from "../../models/dataModel";

export const PrerequisiteConverter = {
  /**
   * Converts TCC data to a format that can be used by the app
   * @param {Object} attributesFromTCC - The data to convert
   * @returns {IVersionPrerequisite[]} - The converted data
   */
  fromTCC: function (attributesFromTCC) {
    attributesFromTCC = attributesFromTCC || {};
    if (!attributesFromTCC.prerequisites) return attributesFromTCC;

    function p(data) {
      return Prerequisite({ url: data.url, title: data.title });
    }

    if (attributesFromTCC.prerequisites["URLs"]) {
      var urls = attributesFromTCC.prerequisites["URLs"].split(",");
      var urlTitles = attributesFromTCC.prerequisites["URLTitles"].split(",");
      return _.map(urls, function (urlValue, index) {
        var urlTitleValue = urlTitles[index];
        return p({ url: urlValue, title: urlTitleValue });
      });
    } else {
      //Handle old data structure
      return _.map(attributesFromTCC.prerequisites, function (v, key) {
        var titleValue = "";
        if (attributesFromTCC.prerequisitesTitles) {
          titleValue = _.find(attributesFromTCC.prerequisitesTitles, function (title, titleKey) {
            return key === titleKey;
          });
          return p({ url: v, title: titleValue });
        }
      });
    }
  },
  toTCC: function (data) {
    return !!data ? data.url : undefined;
  },
  fromLocal: function (data) {
    data = data || {};

    function p(data) {
      return Prerequisite({ url: data.url, title: data.title });
    }

    if (Array.isArray(data)) {
      return _.map(data, function (d) {
        return p(d);
      });
    } else {
      return p(data);
    }
  },
  toLocal: function (data) {
    data = data || {};
    return data.url;
  },
  fromLocalAttributes: function (data) {
    function attributesToPrerequisite(data) {
      return Prerequisite({ url: data.url, title: data.title });
    }

    if (Array.isArray(data.attributes)) {
      return _.map(data.attributes, function (d) {
        return attributesToPrerequisite(d);
      });
    } else {
      return attributesToPrerequisite(data.attributes);
    }
  },
  toLocalAttributes: function (datatmp) {
    var data;
    data = { url: datatmp.url, title: datatmp.title };
    return data;
  },
};
