import * as React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { RootContext } from "../stores/rootStore";
import { TCCFeatureToggleDS } from "../js/data-source/TCCFeatureToggleDS";
import { IFeatureToggle } from "../models/dataModel";
/**
 * A component that displays "upload" and "search" buttons.
 */
export const BrowseOrUpload = observer(() => {
  const store = useContext(RootContext);
  const user = store.getUserStore().getCurrentUser();
  const navigate = useNavigate();
  setRecommendedToggle();
  const [showRecommended, setShowRecommended] = useState(false);

  async function setRecommendedToggle(): Promise<void> {
    try {
      const toggle: IFeatureToggle = await TCCFeatureToggleDS.getFeatureToggle("recommendedContent");
      setShowRecommended(toggle.isEnabled && !!user);
    } catch {
      console.log("Error while fetching feature toggle");
    }
  }

  return (
    <section
      className={classNames("action-links", {
        "no-recommended-list": !showRecommended,
      })}
      data-testid="browseOrUpload"
    >
      <div className="left-column" />
      <div className="search-link">
        <div
          className={classNames("action-items", {
            "no-recommended-list": !showRecommended,
          })}
        >
          <div className="search-title">{store.getTranslation("landing.action_links.search_title")}</div>
          <button data-testid="search-button" onClick={() => navigate("/search")}>
            {store.getTranslation("actions.search")}
            <span className="search-icon" />
          </button>
        </div>
      </div>
      <div className="middle-column" />
      <div className="upload-link">
        <div
          className={classNames("action-items", {
            "no-recommended-list": !showRecommended,
          })}
        >
          <div className="upload-title">{store.getTranslation("landing.action_links.upload_title")}</div>
          <button data-testid="upload-button" onClick={() => navigate("/upload")}>
            {store.getTranslation("actions.upload")}
            <span className="icon-upload" />
          </button>
        </div>
      </div>
      <div className="right-column" />
    </section>
  );
});
