import { RootStore } from "../stores/rootStore";
import { IClient, IEntity, IVersion, IVersionTool } from "../models/dataModel";
import { AnalyticsService } from "../js/services/AnalyticsService";
import { LocalTsService } from "../js/services/LocalTsService";
import { URLBuilder } from "./URLBuilder";

const useOldUrlFormat = true;

/**
 * Class for download/install content related functionalities
 */
export const Installer = {
  /**
   * Downloads item from version that is type of tool
   * @params store Rootstore
   * @params packageItem Package that version belongs to
   * @params version Version that downloadable item is included
   * @params tool The actual downloadable item
   */
  downloadTool: (store: RootStore, packageItem: IEntity, version: IVersion, tool: IVersionTool) => {
    const currentUser = store.getUserStore().getCurrentUser();
    if (!packageItem.isLocal) {
      AnalyticsService.versionDownloadComplete(currentUser!, packageItem, version);
    }
    window.location.assign(tool.url);
  },
  /**
   * Downloads items from version
   * @params store Rootstore
   * @params packageItem Package that version belongs to
   * @params version Version whose items are to be downloaded
   */
  downloadVersion: (store: RootStore, packageItem: IEntity, version: IVersion) => {
    const currentUser = store.getUserStore().getCurrentUser();
    const data = {
      subjectId: version.id,
      subjectClass: "entity",
      fn: version.id + ".zip",
    };
    if (!packageItem.isLocal) {
      const params = { download: "true", fileName: data.fn };
      const downloadUrl = URLBuilder.buildWarehouseURL(
        "/warehouse/v1.0/entities/" + data.subjectId + "/binaries/",
        params,
        true,
      );
      AnalyticsService.versionDownloadComplete(currentUser!, packageItem, version);
      window.location.assign(downloadUrl);
    } else {
      data.subjectClass = "version";
      window.location.assign(URLBuilder.buildLocalUrl("/TeklaWarehouse/GetBinaries", data));
    }
  },
  /**
   * Gets download url for version items
   * @param version Version whose items download url is requested
   */
  getDownloadVersionUrl: (version: IVersion): string => {
    const data = {
      subjectId: version.id,
      subjectClass: "entity",
      fn: version.id + ".zip",
    };
    if (!version.isLocal) {
      if (useOldUrlFormat) {
        return URLBuilder.buildWarehouseURL("/warehouse/GetBinaries", data, true);
      } else {
        const params = { download: "true", fileName: data.fn };
        return URLBuilder.buildWarehouseURL("/warehouse/v1.0/entities/" + data.subjectId + "/binaries/", params, true);
      }
    } else {
      data.subjectClass = "version";
      return URLBuilder.buildWarehouseURL("/TeklaWarehouse/GetBinaries", data);
    }
  },
  /**
   * Installs version items
   * @param store Rootstore
   * @param packageItem Package that version belongs to
   * @param version Version whose items download url is requested
   * @param client The running TS version that items should be installed to
   */
  installVersion: async (store: RootStore, packageItem: IEntity, version: IVersion, client: IClient): Promise<void> => {
    let reference;
    const userStore = store.getUserStore();
    const currentUser = userStore.getCurrentUser();
    if (useOldUrlFormat) {
      reference = version.isLocal
        ? "twhlocal://Version?id=" + version.id
        : URLBuilder.buildWarehouseURL("/warehouse/GetEntity", { id: version.id }, true);
    } else {
      reference = version.isLocal
        ? "twhlocal://Version?id=" + version.id
        : URLBuilder.buildWarehouseURL("/warehouse/v1.0/entities/" + version.id, null, true);
    }
    const ticket = userStore.get3dwTicket();
    if (!version.isLocal) {
      try {
        await LocalTsService.installPackage({
          clientId: client.id,
          reference: reference,
          ticket: ticket,
        });
        AnalyticsService.versionDownloadComplete(currentUser!, packageItem, version, client);
      } catch {
        AnalyticsService.versionDownloadFailure(currentUser!, packageItem, version, client);
      }
    } else {
      LocalTsService.installPackage({
        clientId: client.id,
        reference: reference,
        ticket: ticket,
      });
    }
  },
};
