import _ from "underscore";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IResourceAttributes } from "../../models/dataModel";

function convertProperty(dataType, value) {
  if (dataType === "int") {
    return parseInt(value);
  } else if (dataType === "boolean") {
    return value === true;
  } else {
    return "" + value;
  }
}

export const AttributesConverter = {
  /**
   * Convert TCC attributes to a format that can be used by the app
   * TCC attributes are in the format: { "category": { "property": { "dataType": "string", "value": "value" } } }
   * App attributes are in the format: { "category": { "property": "value" } }
   * @param {Object} data - TCC data
   * @returns {IResourceAttributes} - converted attributes
   */
  fromTCC: function (data) {
    data = data || {};
    var convertedData = {};
    if (_.isObject(data)) {
      _.each(_.keys(data), function (key) {
        var category = data[key];
        if (category && _.isObject(category)) {
          var categoryData = {};
          _.each(_.keys(category), function (propertyKey) {
            var property = category[propertyKey];
            categoryData[propertyKey] = convertProperty(property.dataType, property.value);
          });
          convertedData[key] = categoryData;
        }
      });
    }
    return convertedData;
  },
};
