
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Zh = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["zh-TW"]) {
      window.locale["zh-TW"] = {};
    }

    _.extend(window.locale["zh-TW"], {
      myDetails: {
        my_roles: "角色",
        organization_roles: "角色",
        roles: {
          member: "Tekla Warehouse 內容編輯器 (員工)",
          employee: "員工",
          contentEditor: "Tekla Warehouse 內容編輯器 (外部)",
        },
      },
      immutability: {
        add_code_name_for_package: "為內容新增程式代碼名稱",
        code_name: "代碼名稱",
        immutable: "不可變的",
        archive: "封存",
        archive_collection: "封存收集及其所有內容",
        archive_version: "封存所選版本",
        archive_package: "封存此套件及其所有內容",
        archive_dialog_text: "封存的內容將不可見或不可搜尋 Tekla Warehouse。但是，如果該內容之前已透過 Tekla Structures 雲端服務使用過，則雲端服務可以繼續存取該內容。",
        archive_success: function (name: string) {
          return `${name} 封存成功`;
        },
        archive_failure: function (name: string) {
          return `${name} 封存失敗`;
        },
        archived_content_redirect_message: "您嘗試存取的內容已封存",
        edit_title_move_or_archive: "編輯標題、移動或封存",
        edit_title_or_archive: "編輯標題或封存",
        major_update: "主要更新",
        major_update_description: "無法向後相容的變更，例如新增或刪除功能，或其他重大變更。",
        minor_update: "小更新",
        minor_update_description: "向後相容的更改，例如新功能或增強功能，而不破壞現有功能。",
        patch_update: "補丁更新",
        patch_update_description: "向後相容的修正，例如安全性修補程式、錯誤修復或效能改進，但沒有新功能。",
        info: "您的組織為 Tekla Structures 雲端服務提供內容。Tekla Structures 雲端服務中的內容需要有代碼名稱和版本號碼，以確保相容性和一致性。",
        immutable_files_info: "為了保持雲端服務中內容的一致性，您不能刪除或修改檔案。如果需要修改，請封存目前版本並上傳新版本。",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `有關詳細信息，請參閱<a href="${readMoreUrl}" target="_blank">本頁</a>。`;
        },
        resolve_conflict: {
          title: "解決衝突",
          description: "找到下列項目。 選擇 [保留現有的] 以保留安裝期間找到的現有項目。",
          table_header_name: "名稱",
          table_header_type: "類型",
          table_header_action: "作用",
          action_keep_existing: "保留現有的",
          action_replace: "置換",
          action_done: "完成",
        },
        auth: {
          authentication_process_failed: "驗證程序失敗",
          access_denied: "存取遭拒",
          handle_unauthorized: {
            unauthorized: "未授權",
            please_log_in: "請登入或註冊",
          },
        },
        translations: {
          edit_translations: "編輯翻譯",
          edit_translations_info: "請從清單中選擇語言，然後加以編輯並儲存",
          edit_translations_default_language_info: "當使用者選擇的語言無法使用時，會顯示<b>預設</b>語言",
          edit_content: "內容資訊",
          edit_support: "支援",
          updated_successfully: "已成功更新翻譯",
          error_on_update: "更新翻譯時發生錯誤",
          default: "預設",
          batch_translations: "批次翻譯",
          export_import_info:
            "<b>匯出</b>您選取內容的批次翻譯樣板，編輯此樣板以加入翻譯，然後再<b>匯入</b>該樣板以同時儲存多重翻譯。",
          import_translations: "匯入",
          export_translations: "匯出",
          translations_missing: " (空白)",
          select_content: "選擇要匯出的翻譯:",
          import_finished: "匯入完成!",
          select_file: "選擇檔案",
          select_file_title: "選擇要匯入的檔案:",
          import_from_file: "匯入",
          import_error_info: "檔案不符合樣板格式:",
          export_selected: "匯出",
          import_translations_finished: "翻譯已匯入",
          details_too_long: "細部內容超出允許的最大長度",
          import_failed: "無法匯入翻譯",
          translate_to_other_languages: "翻譯成其他語言",
          import_count: function (count) {
            return "更新翻譯對 " + count + " 內容";
          },
          invalid_language: function (contentId, language) {
            return "內容'" + contentId + "'包含無效的語言'" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "內容'" + contentId + "'包含不正確的翻譯欄位'" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return "內容'" + contentId + "'翻譯欄位'" + translationField + "' 值超過允許的最大長度";
          },
        },
        "3dPic": {
          "3d_pic_description": "3D 模型",
          "3d_pic_added": "成功加入 3D 模型",
          "3d_pic_updated": "成功更新 3D 模型",
          "3d_pic_removed": "成功移除 3D 模型",
          "3d_pic_update_failed": "無法更新 3D 模型",
          "3d_pic_remove_failed": "無法移除 3D 模型",
          "3d_pic_missing": "沒有內容的 3D 影像。 點選底下的 [選擇 3D 模型]，選擇影像。",
          "3d_pic_uploading": "已載入您的模型。 請稍候...",
        },
        catalog_entity_edit: {
          froala_placeholder: "點選這裡以編輯內容",
          updated_successfully: "已成功更新",
          update_failed: "更新失敗",
          edit_search_criteria: "編輯搜尋條件",
          edit_group_and_category: "編輯群組和目錄",
          edit_content: "編輯內容",
          edit_thumbnail: "編輯縮圖",
          edit_thumbnail_descriptive: "建議的尺寸: 400 x 300 像素",
          edit_description: "編輯描述",
          edit_download_link: "新增或編輯合作夥伴下載連結",
          versions_not_shown_to_users:
            "因為這個內容項目內含合作夥伴下載連結，所以 Tekla Warehouse 使用者看不到您先前已加入到該內容項目的任何版本。Tekla Warehouse 管理員與使用者如果具備編輯權限，則仍可以查看該內容項目的所有版本。",
          partner_download_link: "輸入下載 URL",
          partner_download_link_disclaimer:
            "如將合作夥伴下載連結新增至現有的內容項目，Tekla Warehouse 使用者將再也看不到您先前已加入到該內容項目的任何版本。這個動作無法復原。",
          edit_title_or_delete: "編輯標題或刪除",
          edit_title_or_move_delete: "編輯標題、移動或刪除",
          edit_support: "編輯支援資訊",
          edit_release_notes: "編輯發行註記",
          edit_content_wysiwyg: "編輯內容細部",
          edit_content_embed_link: "如需更多資訊，請參閱 Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "您只能嵌入來自 YouTube、Vimeo 或 Alugha 的影片。",
          details_unallowed_embed_info: "您只能嵌入來自以下網域的內容：YouTube、Vimeo 或 Alugha。允許的 URL 格式為：",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "影片連結無效。您可以透過 YouTube 和 Vimeo 的 URL 新增影片",
          edit_content_vide_code_error: "嵌入格式無效。僅接受 iframe。允許的影片提供者包括 YouTube、Vimeo 和 Alugha。",
          details_max_length_exceeded: "細部內容太長",
          editor_mode_label: "編輯器模式:",
          editor_mode_on: "開",
          editor_mode_off: "關",
          do_not_notify: "次要更新，不必通知訂閱者",
          invalid_tag: "無效的標籤",
        },
        transfer_package: {
          header: "移動內容",
          actions: {
            move: "移動",
            cancel: "取消",
          },
          loading: "正在載入收集...",
          moving: "正在移動內容",
          select_collection: "選擇收集",
          content_moved: "內容已移動。 變更在幾分鐘後就會顯示出來。",
          operation_failed: "操作失敗",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "內容已成功連結至 " + name;
          },
          selected_resources_linked: function (name) {
            return "選取的內容已成功連結至 " + name;
          },
          resources_linked_with_errors: "內容已連結，部分內容出現錯誤",
          resources_linking_failed: "內容連結失敗",
          resource_unlinked: "連結已成功移除。 變更在幾分鐘後就會顯示出來。",
          resources_unlinked: "已從選取的內容中成功移除連結。 變更在幾分鐘後就會顯示出來。",
          resources_unlinked_with_errors: "連結已移除，部分內容出現錯誤",
          resources_unlinking_failed: "連結移除失敗",
          linking: "正在建立連結",
          linking_failed: "連結失敗",
          linking_failed_title: "無法連結下列內容:",
          unlinking_failed_title: "無法取消連結下列內容:",
          linking_failed_already_contains: "內容已連結到所選收集。 更新可能需要一點時間才會顯示。",
          linking_failed_already_contains_short: "內容已連結到所選收集",
          unlinking_failed: "連結移除失敗",
          unlinking_failed_does_not_contain: "已從收集中移除內容連結。 更新可能需要一點時間才會顯示。",
          unlinking_failed_does_not_contain_short: "已從收集中移除內容連結",
        },
        notification_channel: {
          title_success: "成功",
          title_notice: "聲明",
          title_info: "資訊",
          title_error: "錯誤",
        },
        maintenance: {
          download_requires_tekla_maintenance: "下載時需要 Tekla 維護合約",
          can_access_maintenance: {
            yes: "是",
            no: "否",
          },
        },
        confirm: {
          title: "確認",
          yes: "是",
          no: "否",
          ok: "確定",
          close: "關閉",
          dont_show: "不要再顯示此訊息",
        },
        show_more: " 顯示更多",
        show_less: " 顯示更少",
        spinner_loading: "正在載入",
        spinner_processing: "處理中-",
        spinner_loading_long_time: "載入內容可能需要幾分鐘時間",
        local_service: {
          info: "需要 Tekla Warehouse Service 插件程式以建立本機及網路內容。 有了 Tekla Structures 20.1 SR1 或更新的版本，您可以直接從模型中上傳內容。",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件程式</a> " +
            " 以建立本機及網路內容。",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件程式</a> " +
            " 無法使用。 需要此服務以啟用本機及網路收集。",
          download_to_unlock_all_features_html:
            "下載 " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件程式</a> " +
            " 以解除鎖定所有功能。",
          service_out_of_date_html:
            "您的 Tekla Warehouse 插件程式不是最新。 更新 " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件程式</a> " +
            " 以解除鎖定所有功能。",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "選擇語言",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "邀請其他用戶",
          send_invitation_to_email: "傳送邀請至電子郵件",
          add_e_mail_addresses: "加入電子郵件地址",
          and_assign_role: "並指派角色",
          send_invitation: "傳送邀請",
          invitation_already_sent: "邀請已傳送!",
          invitation_sent: "邀請已傳送!",
          update_selected_invitations: "更新選定的邀請",
          new_role: "新角色",
          invitations_list_updated: "邀請清單已更新!",
        },
        item_browser_title: "選擇內容項目",
        prerequisites: {
          description_placeholder: "在此加入描述",
          link_name_placeholder: "在此加入連結名稱",
          add_prerequisites_and_recommendations: "先決條件與建議 (選擇性)",
          list_the_profiles_components_files: "列出讓內容正常運作必備的所有斷面規格、元件、檔案或模型。",
          link_to_existing_content: "連結現有內容",
          please_enter_prerequisite_url: "請輸入先決條件 URL",
          please_enter_valid_prerequisite_url: "請輸入有效的先決條件 URL",
          missing_http: "遺失 http://",
          add_link: "加入連結",
        },
        treeview: {
          cannot_export_this_container: "無法輸出此容器。 請改為選擇個別項目。",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "檔案必須小於 " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "無效的檔案類型。  檔案必須是下列任一類型 " + allowed_types;
          },
        },
        analytics: {
          timeframe: "時間範圍:",
          timeframe_start: "開始:",
          timeframe_end: "結束:",
          eventtype: "事件類型:",

          downloads_daily_by_version: "依版本下載",
          downloads_by_country: "依國家/地區下載",
          downloads_by_version: "依版本下載",
          downloads_by_version_organization: "依版本與組織下載",
          downloads_by_organization: "依組織下載",
          downloads_daily_by_package: "依內容項目下載",
          views_daily_by_package: "依內容項目檢視",
          views_by_country: "依國家/地區檢視",
          views_by_organization: "依組織檢視",

          downloads_by_package: "依內容項目下載",
          views_by_package: "依內容項目檢視",
          downloads_daily_by_collection: "依收集下載",
          downloads_by_collection: "依收集下載",
          views_daily_by_collection: "依收集檢視",
          views_by_collection: "依收集檢視",

          events_by_organization_func: function (version) {
            return '版本 "' + version + '" 依組織的事件'; // Version "2.1" events by organizations
          },
          pie_slice_others: "其他",
          sum_all_versions: "所有版本",
          sum_all_packages: "所有內容項目",
          sum_all_collections: "所有收集",
          sum_all_organizations: "所有組織",
          recording_started_at: "資料從 2015 年 6 月開始記錄",
          recording_view_events_started_at: "資料從 2017 年 3 月開始記錄",
          version_title_func: function (title) {
            return '版本 "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return title + " (" + pluralize(count, [count + " 下載", count + " 下載", count + " 下載"]) + ")"; // Tekla Oy (21 downloads)
          },
          counted_events_info: "數字包含所有完成的下載。",
          counted_events_info_package: "數字包含所有完成的下載。",
          export: {
            caption: "輸出分析",
            select_options: "選擇選項",
            select_timeframe_description: "請選擇下列時間範圍以輸出分析:",
            select_eventtype_description: "請選擇下列事件類型以輸出分析︰",
          },
        },
        access_rights: {
          editors: "編輯器",
          download_access: "檢視與下載存取",
          write_user_id_placeholder: "寫入用戶 ID",
          write_organization_id: "輸入有效的組織 ID。在單獨的列中輸入每個 ID。",
          write_id_placeholder:
            "輸入使用者 ID、使用者電子郵件地址，或組織 ID。在另外一列中輸入個別 ID 或電子郵件地址。",
          id_help_html_short:
            "為安全考量，您無法搜尋我們的用戶。<br/><br/>" +
            "直接從您希望提供存取權限的人，要求其提供用戶 ID。 <br/>" +
            '用戶 ID 顯示於 [<a href="#/my/">我的詳細</a>]。',
          id_help_html:
            "基於安全考量，您無法搜尋我們的用戶。<br/><br/>" +
            "直接從您希望提供存取權限的人，要求其提供用戶 ID。 若要提供存取權限給組織裡所有用戶，請使用組織 ID。<br/><br/>" +
            '用戶與組織 ID 已經顯示於 [<a href="#/my/">我的詳細</a>]。',
          max_input_limit: function (max_rows) {
            return "在另外一列中輸入個別 ID 或電子郵件地址。您可以一次輸入 " + max_rows + " 列。";
          },
          input_too_long: "您所輸入的文字過長。",
          viewer_already_added: function (input) {
            return `${input} 已經加入做為檢視者`;
          },
          wrong_format: function (input) {
            return `${input} 不是有效的 ID 或電子郵件地址`;
          },
          candidates_for_keyword: function (keyword) {
            return "用電子郵件地址或 ID " + keyword + " 所找到的使用者";
          },
        },
      },
      images: {
        add_new: "加入新",
        add: "加入",
        delete: "刪除",
        set_as_default: "預設縮圖",
        select_file: "選擇檔案",
        choose_option: "選擇選項",
        info_3D_exists: "您已加入了一個 3D 模型，您無法加入超過一個模型至內容。",
        action_options: {
          image: "影像",
          "3DModel": "3D 模型",
          video: "影片",
        },
        video: {
          url: "影片 URL",
          invalid_url: "無效的 URL",
        },
      },
      units: {
        bytes: "位元組",
        kB: "KB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "下載",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " 按讚數", number + " 按讚", number + " 按讚數"]);
      },
      main_info: {
        header_title: "Tekla Warehouse 網站全新外型登場！",
        header_text: "您覺得全新串流的 Tekla Warehouse 網站如何？",
        header_text2: "主要變更項目包括：",
        bullet_text_1: "登陸頁面的清楚搜尋功能。",
        bullet_text_2: "所有的搜尋篩選條件都集中到搜尋頁面的左邊，而且預設的縮圖檢視在相同視圖中包含了更多的搜尋結果。",
        bullet_text_3:
          "內容頁面的下載選項安排在正中位置，方便您開始使用內容。 現在「下載」與「安裝」選項都能透過 [下載] 按鈕存取",
        bullet_text_4: "「集合」頁面和「我的購物車」都可透過 3 步驟來操作下載和安裝的所有功能。",
      },
      organizations: {
        error_loading_collection_contents: "載入收集內容時發生錯誤",
        error_loading_organization: "載入組織時發生錯誤",
      },
      downloads_graph: {
        date: "日期",
        total_views: "總檢視數",
        total_downloads: "總下載數",
        views: "檢視",
        downloads: "下載",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service 插件程式" +
          "</a>" +
          " 有需要。",
        processingFailed: "處理失敗",
        forbidden: "禁止",
        resource_not_found: "找不到資源",
        no_matches_found: "找不到相符",
        no_matches_found_for: function (input) {
          return "用電子郵件地址或 ID " + input + " 找不到任何符合項";
        },
        not_identified: "尚未辨識",
        error_while_searching: "搜尋時發生錯誤",
        error_user_not_found: "找不到用戶或組織",
        error_while_adding_member: "加入存取權限成員時發生錯誤",
        error_while_removing_member: "移除存取權限成員時發生錯誤",
      },
      offline_to_online: {
        fail_on_initialize: "處理 zip 壓縮檔時發生錯誤，中止轉換作業",
        fail_on_validate: "內容中繼資料驗證失敗，中止轉換",
        fail_on_save: "儲存內容時發生錯誤，回復變更",
        success: "成功地將離線內容轉換為線上內容",
        validate_errors_title: "內容轉換失敗",
        processing: "正在儲存套件 ",
        processed: "已處理項目",
        collection_thumbnail_missing_name: "收集縮圖二進位檔缺少「檔名」",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "套件'" + packageTitle + "'縮圖二進位檔缺少'檔名'";
        },
        package_value_missing_title_func: function () {
          return "套件缺少'標題'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "套件'" + packageTitle + "'缺少'" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return "套件'" + packageTitle + "'欄位'" + field + "'帶有下列無效值: " + invalidValues;
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "套件'" + packageTitle + "'帶有無效的標記: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "套件'" + packageTitle + "'版本缺少'標題'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "套件'" + packageTitle + "'版本'" + versionTitle + "'缺少'" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "套件'" + packageTitle + "'版本'" + versionTitle + "'欄位'" + field + "'帶有下列無效值: " + invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "套件'" + packageTitle + "'版本'" + versionTitle + "'二進位檔缺少'檔名'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "重要提示：Tekla Warehouse 中本地集合的終止",
            part1: "從2025年6月2日起，Tekla Warehouse 中將不再提供本地集合。用戶將無法再創建、查看或搜索本地集合，也無法使用本地集合中的內容。",
            part2: "如需有關從本地集合到線上集合的過渡幫助，請聯繫您的本地支持組織",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `重要事項！ 這是安全性增強功能，您必須擁有 Tekla Warehouse 內容編輯器角色，才能在 2024 年 11 月 ${date} 日之後管理您組織的內容。請要求您的組織管理員指派此角色給您。有關更多信息，請參閱<a href="${productBulletinUrl}" target="_blank">產品公告</a>。`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `這是安全性增強功能，您必須擁有 Tekla Warehouse 內容編輯器角色，才能在 2024 年 11 月 ${date} 日之後管理您組織的內容。請要求您的組織管理員指派此角色給您。有關更多信息，請參閱<a href="${productBulletinUrl}" target="_blank">產品公告</a>。`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `沒有看到適合您組織的編輯選項？11 月 ${date} 日之後，您必須具有 Tekla Warehouse 內容編輯者角色才能管理組織的內容。您可以在「我的Warehouse」>「我的詳細」下檢查您的角色。有關更多信息，請參閱<a href="${productBulletinUrl}" target="_blank">產品公告</a>。`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `沒有看到為您的組織創建內容的選項？11 月 ${date} 日之後，您必須具有 Tekla Warehouse 內容編輯者角色才能管理組織的內容。您可以在「我的 Warehouse」>「我的詳細」下檢查您的角色。有關更多信息，請參閱<a href="${productBulletinUrl}" target="_blank">產品公告</a>。`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `11 月 ${date} 日之後，您必須具有 Tekla Warehouse 內容編輯者角色才能管理組織的內容。請要求您組織的管理員為您指派該角色。您可以到 Tekla Online 個人資料中查看誰是您組織的系統管理員。有關更多信息，請參閱<a href="${productBulletinUrl}" target="_blank">產品公告</a>。`
            },
          },
          service_plugin_update_info:
            "連接 Tekla Structures 時遇到問題？請確保您正在使用最新版本的 <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service 插件</a>。",
        },
        maintenance_info:
          "Tekla Warehouse 將於 2017-10-27 22:00:00 - 23:00:00 UTC 這段期間進行維護。 不便之處，敬請見諒。",
        release_notes_info: "Tekla Warehouse 的最新功能",
        release_notes_link:
          'Tekla Warehouse 已經更新，請閱讀<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">新增功能</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "需要為專案安排 Tekla 開發者？",
        heading2Dev:
          '歡迎使用稱為 Tekla 開發專家的新目錄。<br><br>您可以在這裡找到技術高超的程式設計人員，並且認識其他的 Tekla 使用者。<br><br>我們歡迎每位 Tekla Partners Program 會員在這個「專家」目錄中加入自己的資訊。<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">尋找開發者 &rsaquo;</a>',
        static_intro:
          "您可以在這個免費提供的 Tekla Structures BIM 儲存空間裡，同時對內與對外尋找、匯入、安裝與分享內容。 Tekla Warehouse 旨在讓用戶輕鬆有效率地產製高品質模型。",
        new_intro:
          'Tekla Corporation 現在稱為 Trimble。 我們持續開發 Tekla 系列軟體解決方案，並由同一批人員為您服務。 進一步了解: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "請驗證您的帳戶",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "最新上傳內容&rsaquo;",
          popular: "熱門內容 &rsaquo;",
          recommended: "建議 &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">新聞 &rsaquo;</a>',
          item_title: "最新消息!",
          item_description:
            '部分新聞相關內容, 部分新聞相關內容, 部分新聞相關內容, 部分新聞相關內容, 部分新聞相關內容, 部分新聞相關內容。<br><a href="https://developer.tekla.com" target="_blank">深入閱讀 &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse 編號",
          partners: "合作夥伴",
          partners_count: "352",
          collections: "收集",
          collections_count: "793",
          items: "內容項目",
          items_count: "2893",
        },
        partners: "我們的合作夥伴",
        action_links: {
          search_title: "瀏覽內容?",
          upload_title: "上傳內容?",
        },
        videos: {
          title: '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">影片 &rsaquo;</a>',
          link: '請造訪我們的 <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Youtube 頻道</a>，以觀賞更多影片。',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return '檢查您的電子郵件裡是否有確認連結，或是<u><a href="' + settingsUrl + '">訂購新的連結</a></u>。';
        },
        verifyAccountAcceptTerms: "請驗證您的電子郵件地址，並接受條款與條件以及隱私權政策",
        acceptTerms: "請接受條款與條件以及隱私權政策",
        acceptUpdatedTerms: "請核准更新的 tekla online services 條款與條件以及隱私權政策",
        termsNotAccepted_html_func: function (settingsUrl) {
          return '返回您的 <u><a href="' + settingsUrl + '">個人資料</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return '請檢視並接受個人資料內的條款與條件及隱私權政策<u><a href="' + settingsUrl + '"></a></u>。';
        },
        suggestions: {
          browseCollections: "瀏覽收集 &rsaquo;",
          new: "新增",
          popular: "熱門",
          recommended: "建議",
          organizations: "組織",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "選擇資料夾",
        selectFolderInstructions: "本機及網路資料夾可做為收集來使用。",
        selectedFolder: "選取資料夾 (複製貼上位置):",
        selectedFolderPlaceholder: "例如，C:\\資料夾、Z:\\網路資料夾、\\\\machine-name\\資料夾",
        collectionCreation: {
          name: "名稱",
          createNewCollection: "建立本機收集",
          pleaseInputTitle: "請輸入標題",
          invalidPath: "不正確的路徑",
          access_denied: "無法加入收集: 存取遭拒",
          unsafe_location: "無法加入收集: 不安全的位置",
          add_collection_failed: "無法加入收集",
          minLengthError: "標題長度至少需為 3 個字元",
          maxLengthError: "標題不得超過 20 個字元",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "本機及網路資料夾可做為收集來使用。",
        collectionCreation: {
          name: "名稱",
          createNewCollection: "建立線上收集",
          pleaseInputTitle: "請輸入標題",
          minLengthError: "標題長度至少需為 3 個字元",
          maxLengthError: "標題不得超過 20 個字元",
        },
      },
      popups: {
        generic: {
          resourceSaved: "資源已儲存",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">您看到潛力了嗎?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">提供我們意見回饋</span>',
          help_us_improve_html:
            '幫助我們改善 Tekla Warehouse！ <br/><a id="showSurvey">點選這裡</a>或是<a id="dontShow">不要再顯示此訊息</a>',
          dialog: {
            do_you_see_potential: "您看到潛力了嗎?",
            help_us_improve_html: "幫助我們改善 Tekla Warehouse！ 我們很重視您的意見。",
            continue_to_survey: "開始調查",
            exit_to_warehouse: "不用了，謝謝",
          },
        },
      },
      search: {
        bar: {
          search: "搜尋",
          filter: " ",
          more_filters: "更多篩選條件",
          hide_filters: "隱藏篩選條件",
          clear: "清除",
          exclude: "排除",
          include: "包含",
          clear_filters: "重置過濾器",
          load_defaults: "使用我的喜好設定 &rsaquo;",
          save_defaults: "儲存為我的喜好設定 &rsaquo;",
          defaults_saved_successfully: "預設篩選條件已儲存",
          edit_defaults: "編輯預設值",
        },
        info: {
          searching_organizations: "在組織內搜尋...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["沒有結果", amountTotal + " 結果"]);
            } else {
              return (
                pluralize(amountTotal, ["沒有結果", amountTotal + " 結果"]) + ' 針對 "' + escapeHtml(searchTerm) + '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " 結果";
            } else if (!searchTerm) {
              return "輸入搜尋字串以檢視組織結果";
            } else if (searchTerm.length < 2) {
              return "至少提供兩個字元以利組織搜尋";
            } else {
              return (
                pluralize(amountTotal, ["沒有結果", amountTotal + " 結果"]) + ' 針對 "' + escapeHtml(searchTerm) + '"'
              );
            }
          },
          online: " 線上內容結果",
          local: " 本機內容結果",
          organization: "組織內結果",
          plugin_missing_info:
            '本機內容只在您已安裝 <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service 插件程式" +
            "</a>" +
            " 時才會顯示出來。",
          version_filter_info: "在本機內容結果中進行軟體版本篩選是無效的動作",
        },
        exclude_env_files: "排除環境檔案",
        sidebar: {
          filters: {
            measurementUnits: "測量單位",
            locations: "專案位置",
            languages: "語言",
          },
          placeholders: {
            use_categories: "目錄",
            measurement_units: "測量單位",
            languages: "語言",
            locations: "位置",
          },
        },
      },
      placeholders: {
        name: "名稱...",
        contentOwner: "內容擁有者名稱...",
        useCategory: "選擇使用",
        selectType: "選擇類型",
        filter: "篩選...",
        search: "搜尋...",
        choose: "選擇...",
        searchFilters: "搜尋篩選條件...",
        selectContentItem: "選擇內容項目...",
        search_content: "搜尋內容...",
        includeAs: "納入為...",
        selectCollection: "選擇收集",
        selectUserGroup: "選擇用戶群組",
        online_collections_tooltip: "點擊以搜尋線上內容",
        local_collections_tooltip: "點選以搜尋本機與網路內容，以及與 Tekla Structures 一併安裝的內容",
        organizations_tooltip: "點選以搜尋組織",
        "filter-options": {
          products: "所有軟體產品",
          locations: "所有位置",
          languages: "所有語言",
          measurementUnits: "所有測量單位",
          testedVersions: "所有軟體版本",
          useCategories: "所有目錄",
          useCategoriesCombined: "目錄",
          allGroups: "所有群組",
          itemTypeCategories: "所有項目類型",
        },
        "exclude-filter-options": {
          products: "排除軟體產品",
          locations: "排除位置",
          languages: "排除語言",
          measurementUnits: "排除測量單位",
          testedVersions: "排除軟體版本",
          useCategories: "排除目錄",
          useCategoriesCombined: "排除目錄",
          allGroups: "排除群組",
          itemTypeCategories: "排除項目類型",
        },
      },
      link: {
        link_selected: "連結已選取",
        unlink_selected: "取消連結選取項目",
      },
      download_install: {
        choose_version: "選擇您的軟體版本:",
        choose_content: "從下方列表中選擇內容項目:",
        choose_action: "選擇選取項目的動作:",
      },
      download: {
        copy_to_clipboard: "複製到剪貼簿",
        download_selected: "下載",
        add_selected_items_to_basket: "將選取項目加入我的購物車",
        add_item_to_basket: "將選取項目加入我的購物車",
        add_all_items_to_basket: "全部加入到購物車",
        add_all_items_to_basket_info: "共有超過 100 項目。 載入可能要花一些時間",
        add_all_items_to_basket_too_many_results: "您無法加入超過 1000 個項目",
        cart_loading: "共有超過 100 項目。 載入可能要花一些時間",
        remove_item_from_basket: "從購物車移除",
        shopping_cart_no_content: "購物車中無內容",
        shopping_cart_no_content_for_version: "購物車中沒有選取版本的內容",
        shopping_cart_items_func: function (itemsCount) {
          return "我的購物車 (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "下載指令",
          step_one: "使用此按鈕，將下載資訊複製到您的剪貼簿",
          step_two_func: function (downloadAppUrl) {
            return (
              "執行 Tekla Warehouse 下載程式應用程式。 要下載該應用程式，請前往 " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">此處。</a> '
            );
          },
          step_three: "Tekla Warehouse 下載程式將引導您完成剩下的下載作業。",
          show_data_title: "無法執行嗎?",
          show_data_to_be_copied: "手動複製",
          hide_data_to_be_copied: "隱藏下載資料",
        },
      },
      install: {
        install_title: " 項目將透過 Tekla Warehouse 插件程式安裝。",
        failed_install_information:
          "您已選擇僅可供下載的內容。 現在將不會安裝此內容。 請從不同於 Tekla Warehouse 的位置下載此內容。",
        verify_service_plugin_is_running: "安裝: 請確認您的 Tekla Warehouse Service 插件程式已正確執行",
        verify_ts_connection: "安裝: 請確認 Tekla Structures 正在執行",
        select_software_version: "請依軟體版本篩選，以便加上連結、下載或安裝",
        download_requires_maintenance: "存取下列某些項目時需要 Tekla 維護合約",
        install_selected: "安裝",
        start_install: "開始安裝",
        install_started: "安裝作業已開始",
        select_ts_version: "選擇 TS 版本",
        install_retry: "需要重試的話，請從 Tekla Warehouse 重新開始",
        failed_installs_info_func: function (failedCount) {
          return "不會安裝的內容 (" + failedCount + " 版本):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "安裝選取 " + totalInstallsCount + " 項目";
        },
      },
      helpers: {
        behalf: "依",
        sortBy: "排序依據:",
        filter: "篩選結果",
        showAsThumbs: "顯示為縮圖",
        showAsList: "顯示為列表",
        resultsPerPage: "按頁面顯示:",
        filter_by_version: "依版本篩選:",
        show_more_options: "更多選項",
        show_less_options: "更少選項",
        filter_by_type: "依類型篩選:",
        select_all: "選擇全部",
        show_content_items: "顯示內容項目",
        choose_action: "選擇選取內容的動作: ",
        show_collections: "顯示收集",
        search: {
          show: "顯示:",
          show_content_items: "內容項目",
          show_collections: "收集",
          show_organizations: "組織",
          show_online_content: "線上內容",
          show_offline_content: "離線內容",
          local_content_info:
            '本機內容只在您已安裝 <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " 插件程式時才顯示。",
        },
        tekla_maintenance_required: "存取下列某些項目時需要 Tekla 維護合約",
        show_content_alerts: "顯示內容通知 ",
        show_collection_alerts: "顯示收集通知 ",
      },
      actions: {
        load_more: "載入更多",
        use_infitinite_scroll: "使用無限捲動",
        back: "返回",
        show_download_dialog: "下載",
        download: "單純下載",
        download_on_partner_site: "從合作夥伴網站下載",
        insert: "安裝",
        install: "安裝",
        downloadAndInstall: "安裝",
        createNew: "建立新的",
        viewItems: "檢視",
        viewCollection: "檢視收集",
        viewGroup: "顯示群組",
        joinGroup: "加入群組",
        loginToDownload: "登入以下載",
        show3DPic: "顯示 3D 模型",
        remove: "移除",
        browse: "瀏覽",
        browseFolder: "瀏覽",
        browseFileSystem: "瀏覽",
        add: "加入",
        save: "儲存",
        batch_edit: "批次編輯",
        translations: "翻譯",
        cancel: "取消",
        continue: "繼續",
        delete: "刪除",
        addNetworkPath: "加入網路位置",
        select: "選擇",
        create: "建立",
        update: "更新",
        choose_thumbnail: "選擇縮圖",
        choose_picture: "選擇圖片",
        remove_picture: "刪除圖片",
        choose_3D_picture: "選擇 3D 模型",
        remove_3D_picture: "移除 3D 模型",
        like: "按讚",
        unlike: "取消按讚",
        close: "關閉",
        export: "匯出",
        add_or_remove: "加入/移除",
        select_content_to_convert: "選擇離線內容",
        search: "搜尋",
        upload: "上傳",
        edit: "編輯",
        view_3d: "點選以檢視模型...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "Beta",
        search: "搜尋",
        profile: "我的詳細",
        myWarehouse: "我的 Warehouse",
        myStuff: "我的物品",
        logout: "登出",
        login: "登入",
        register: "註冊",
        upload: "上傳",
        uploading: "上傳",
        dashboard: "儀表板",
        learnMore: "進一步了解",
        about: "關於",
        shoppingCart: "我的購物車",
        addToCartInfo: "您可以將項目收集到 [我的購物車]，然後一次下載並安裝所有項目。 下載作業完全免費",
      },
      cubeNavigation: {
        headings: {
          get_started: "開始使用",
          learn: "學習",
          get_help: "獲取說明",
          extend_your_tekla: "展延您的 TEKLA",
          collaborate: "協同合作",
          students_and_educators: "Students and educators",
          about: "關於",
        },
        "tekla-online": "線上服務",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Tekla 網站",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla 外部網路",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "我的個人資料",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla 線上學習",
        tekla_services: "TEKLA 服務首頁",
        register: "註冊",
        login: "登入",
        evaluate: "評估",
        buy: "購買",
        download: "下載",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "產品",
        teklaServices: "服務",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "我的收集",
        myLikes: "我的按讚數",
        profile: "我的詳細",
        preferences: "我的喜好設定",
        myUserGroups: "我的用戶群組",
        localCollections: "本機與網路收集",
        tsCollections: "Tekla Structures 收集",
        downloads: "下載",
        selectContent: "選擇內容檔案",
        addMetadata: "加入中繼資料",
        onlineCollections: "線上收集",
        userGroups: "用戶群組",
        createNew: "加入內容",
        notifications: "通知",
        contentUsage: "內容用途",
        browseCollections: "瀏覽收集 ",
        admin: "管理",
        sales: "銷售",
        shoppingCart: "我的購物車",
      },
      notifications: {
        title: "通知",
        invitedBy: "邀請者",
        invitationType: "邀請類型",
        invitedTo: "邀請地點",
        createdAt: "建立時間",
        action: "作用",
        acceptInvitation: "接受",
      },
      batch_edit: {
        selectActionType: "選擇動作類型",
        edit_content: "編輯內容中繼資料",
        content_title: "內容",
        metadataToChange: "要改變的中繼資料",
        selectContent: "適用內容",
        select_action: "選擇動作",
        support: {
          support: "支援",
          licenses: "授權",
        },
        versions: {
          version: "版本",
          prerequisities: "先決條件與要求",
        },
        content: {
          select_collection: "選擇收集",
          show_collection: "顯示:",
          show_all_content: "所有收集",
          show_organization_content: "我的組織收集",
          select_all: "選擇全部",
          select_all_info: "(此作業可能需要一點時間)",
          actionOptions: {
            add: "加入",
            remove: "移除",
            replace: "取代為",
            yes: "是",
            no: "否",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "未選擇任何屬性",
            invalid_attributes: "無效的屬性值",
            invalid_actions: "無效的動作",
            content_not_selected: "未選擇任何內容",
            entities_not_selected: "已提供套件相關資料，但未選擇任何套件",
            versions_not_selected: "已提供版本相關資料，但未選擇任何版本",
          },
        },
        update: {
          info_processing: "在所有項目處理完畢之前，請勿關閉此視窗。",
          info_processed: "所有項目現已處理完畢。",
          processing: "正在處理項目",
          error_info: "更新下列項目時出現問題:",
          package_failure_func: function (package_title, errorMsg) {
            return "內容: " + package_title + ", 錯誤: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "內容: " + package_title + ", 版本: " + version_title + ", 錯誤: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " 項目即將更新，您要繼續嗎?";
          },
        },
      },
      myUserGroups: {
        title: "用戶群組",
        createdByPrefix: "作者:",
      },
      downloads: {
        title: "下載",
      },
      myCollections: {
        new_collection: "新收集",
        my_collections: "我的收集",
        created_by_me: "由本人建立",
        local_collections: "本機與網路收集",
        online_collections: "線上收集",
        created_by_my_organization: "由我的組織建立",
        created_by_my_organizations: "由我的組織創建",
        shared_with_me: "與我共用",
        shared_with_me_or_organization: "與我或我的組織共用",
        notification: {
          error: "錯誤",
          error_loading_online_collections: "載入線上收集時發生錯誤",
          error_loading_packages_liked_by_me: "載入由我按讚的內容時發生錯誤",
          error_loading_my_organization_s_collections: "載入我的組織收集時發生錯誤",
          error_loading_my_shared_collections: "載入我的共用收集時發生錯誤",
        },
        local: {
          helpText1: "若要存取本機收集，請安裝 ",
          helpText2: "Tekla Warehouse Service 插件程式",
          helpText3: ".",
          title: "本機與網路收集",
          notFound: "還找不到本機收集。",
          myCollectionsTitle: "我的收集",
          actions: {
            createNew: "建立本機或網路收集",
          },
        },
      },
      organization: {
        news_feed: "新聞摘要",
        add_comment: "加入摘要文字",
        edit_comment: "編輯新聞項目",
        subTabs: {
          collections: "收集",
          analytics: "分析",
          contact_and_support: "連絡人",
          news_feed: "新聞摘要",
        },
        edit: {
          edit_title: "編輯標題",
          edit_description: "編輯描述",
          edit_contact: "編輯連絡資訊",
          edit_logo: "編輯標誌",
          edit_url: "編輯 URL",
          edit_email: "編輯電子郵件",
          edit_phonenumber: "編輯電話號碼",
          edit_warehouse_url: "編輯 Warehouse URL",
          edit_warehouse_url_subtitle: "字母、非北歐字元、數字或特殊字元",
          edit_url_subtitle: "插入完整的 URL",
          edit_logo_descriptive: "最佳的影像尺寸約為 350 * 200 像素",
          edit_facebook_url: "編輯 Facebook URL",
          edit_twitter_url: "編輯 Twitter URL",
          edit_linkedin_url: "編輯 LinkedIn URL",
          edit_youtube_url: "編輯 Youtube URL",
          edit_googleplus_url: "編輯 Google+ URL",
          logo_updated: "商標已更新",
          logo_update_failed: "商標更新失敗",
          logo_remove_failed: "商標移除失敗",
          logo_removed: "商標已成功移除",
          updated_successfully: "已成功更新",
          updated_failed: "更新失敗",
          add_comment: "新增",
          edit_comment: "編輯新聞項目",
          comment_added: "新聞摘要項目已成功加入",
          comment_updated: "新聞摘要項目已成功更新",
          comment_deleted: "新聞摘要項目已成功刪除",
          comment_add_failed: "無法加入新聞摘要",
          comment_update_failed: "無法更新新聞摘要項目",
          comment_delete_failed: "無法刪除新聞摘要項目",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "線上內容搜尋依據 " + name + " &rsaquo;";
        },
        contact_information: "連絡我們",
        website: "網站 &rsaquo;",
        show_newer_news: "較新",
        show_older_news: "較舊",
      },
      online_collections: {
        new_collection: "新收集",
        online_collections: "線上收集",
        created_by_organizations: "由組織建立",
        created_by_users: "由用戶建立",
      },
      local_collections: {
        tab_title: "內容",
        local_collections: "本機收集",
      },
      versions: {
        add_tool: "加入應用程式",
        table_header: {
          name: "名稱",
          type: "類型",
          platform: "平台",
          modified_at: "修改時間",
          size: "大小",
          virus_scan: "病毒掃描",
        },
        // placeholders
        select_file_type: "選擇檔案類型",
        select_platform: "選擇平台",
        select_unit: "選擇單位",
        select_quality: "選擇品質",
        what_will_be_added: "您要加入什麼?",
        select_files_from_your_disk: "從您的磁碟選擇檔案",
        add: "加入",
        //content management
        add_application: "加入應用程式",
        select_content_from_tekla_structures: "從 Tekla Structures 選擇內容",
        or: "或",
        files: "檔案",
        //version metadata
        version_information: "版本資訊",
        version_display_name: "版本顯示名稱",
        version_number: "版本號",
        version_number_info: "輸入版本號。儲存後，版本號碼無法變更。",
        existing_versions: "此內容的現有版本號",
        version_number_validation: {
          missing: "您必須輸入版本號",
          invalid: "版本號碼必須遵循語意版本控制規則 (MAJOR.MINOR.PATCH)",
          already_in_use: "此版本號已用於此內容。",
        },
        select_update_type: "若要產生版本號，請先選擇更新類型",
        please_enter_a_version_name_or_number: "請輸入版本名稱或編號。",
        virus_scan: {
          soft_failed: "失敗",
          hard_failed: "尚未掃描",
          infected: "已感染",
        },
      },
      collections: {
        by: "作者:",
        edit: {
          edit_collection: "編輯收集",
          edit_description: "編輯描述",
        },
        notification: {
          collection_updated: "收集已更新",
          error: "錯誤",
          error_loading_other_users_collections: "載入其他用戶收集時發生錯誤",
          error_loading_other_organizations_collections: "載入其他組織的收集時發生錯誤",
          error_loading_collection_contents: "載入收集內容時發生錯誤",
          members_list_updated: "成員清單已更新!",
          invitations_removed: "邀請已移除!",
          collection_removed: "收集已移除",
          thumbnail_deleted: "縮圖已刪除",
          wrong_file_type: "錯誤的檔案類型。",
          invalid_file_type: "無效的檔案類型。",
          selected_image_is_too_big: "所選影像太大。",
          thumbnail_updated: "縮圖已更新",
          default_thumbnail_updated: "預設縮圖已更新",
          default_thumbnail_update_failed: "預設縮圖更新失敗",
          thumbnail_added: "縮圖已加入",
          thumbnail_update_failed: "縮圖更新失敗",
          video_added: "影片已加入",
          video_adding_failed: "無法加入影片",
          video_deleted: "影片已刪除",
          video_deleting_failed: "無法刪除影片",
        },
        confirm: {
          delete_all: function (title) {
            return '刪除收集與收集裡的所有內容"' + title + '"?';
          },
          remove_members: "確定嗎?",
          remove_invitations: "確定嗎?",
        },
        useNetworkDrive: "使用網路磁碟機或資料夾",
        createCollection: "建立線上收集",
        createCollectionInstructions: "建立收集以便為您的內容分組。",

        actions: {
          delete_thumbnail: "刪除縮圖",
          edit_collection: "編輯收集",
          delete_collection: "刪除收集",
          add_content: "加入內容",
        },
        subTabs: {
          content: "內容",
          members: "顯示成員",
          analytics: "分析",
          manage_access: "管理存取",
        },

        members: {
          add_user_groups: "加入用戶群組",
          select_user_group: "選擇用戶群組",
          with_role: "使用角色",
          user_groups_added: "用戶群組已加入!",
          members_list_updated: "成員清單已更新!",
          update_selected_members: "更新選定的成員",
          table_header: {
            member_type: "成員類型",
            member_name: "成員名稱",
            email: "電子郵件",
            role: "角色",
            joined_at: "加入時間",
            invited_at: "邀請時間",
          },
          new_role: "新角色",
        },
        member_list: {
          users_who_can_access_this_collection: "可存取此收集的用戶",
          add_user_group: "加入用戶群組",
          update_members: "更新成員",
          remove_members: "移除成員",
          pending_invitations: "等候傳送的邀請",
          user: "用戶",
          invite_member: "邀請成員",
          update_invitations: "更新邀請",
          remove_invitations: "移除邀請",
        },
        labels: {
          select_organization: "選擇組織",
          select_visibility: "選擇可見性",
          title: "名稱",
          name: "名稱",
          createAs: "建立為: ",
          asMyself: "我自己",
          asUserGroup: "用戶群組",
          asOrganization: "組織",
          selectOwner: "為內容擁有者選擇組織",
          visibility: "可見性",
          visibilityOptions: {
            public: "公共",
            private: "私有",
          },
          description: "描述",
        },
        new_collection: {
          please_input_a_username: "請輸入用戶名稱",
          title_is_required_to_be_at_least_3_characters: "標題長度至少需為 3 個字元",
          title_cannot_be_longer_than_20_characters: "標題不得超過 20 個字元",
        },
        collection_list: {
          no_content: "沒有收集。",
          results_summary: function (number) {
            return "" + number + "  收集 " + pluralize(number, ["項目", "項目", "項目"]);
          },
        },
        entity_list: {
          no_content: "收集內沒有內容。",
          results_summary: function (number) {
            return "" + number + " 內容 " + pluralize(number, ["項目", "項目", "項目"]);
          },
          linked_content: "連結的內容",
          unlink: "取消連結內容",
        },
      },
      alerts: {
        no_updates: "最近未更新",
        notifications_marked_as_read: "通知標示為已讀取",
        cleared_alerts: "所有通知已清除",
        alert_by_email: "當下列任何內容已更新為新版本，或是收集已更新為新內容時，將電子郵件寄給我",
        action_types: {
          create: "已建立",
          update: "已編輯",
          delete: "已刪除",
          added_entity: "版本已加入",
          removed_entity: "版本已移除",
          added_collection: "內容已加入",
          removed_collection: "內容已移除",
        },
        property_names: {
          description: "細部",
          searchCriteria: "搜尋條件資訊",
          groupCategory: "群組和目錄資訊",
          support: "支援資訊",
          licenses: "授權資訊",
          relatedContent: "相關內容資訊",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "更新日期 " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "通知喜好設定已成功更新",
          preferences_update_failed: "更新通知喜好設定時發生錯誤",
        },
      },
      browseDialog: {
        useNetworkDrive: "使用網路磁碟機或資料夾",
        ownerGroups: {
          title: "擁有者群組",
        },
      },
      itemTypeCategories: {
        all: "所有群組",
        application: "應用程式",
        "3dProduct": "3D 產品",
        customComponent: "用戶元件",
        tool: "應用程式",
        profile: "斷面規格",
        material: "材料",
        bolt: "螺栓",
        rebar: "鋼筋",
        reportTemplate: "報表樣板",
        drawingSetup: "圖面設定檔案",
        modelSetup: "模型設定檔案",
        environmentFile: "環境檔案",
        shape: "形狀",
        //'shapeCatalog': '形狀目錄',
        //'foreignGeometryDefinition': '外來幾何體定義'
      },
      platforms: {
        WIN_X86: "32 位元",
        WIN_X64: "64 位元",
        ANY: "不分平台",
      },
      platformsDownload: {
        WIN_X86: "32 位元",
        WIN_X64: "64 位元",
        ANY: " ",
      },
      contentItemSource: {
        other: "一般 - 至模型資料夾",
        run: "執行",
        bolt: "螺栓組合",
        component: "元件",
        drawing: "圖面設定 (BETA)",
        material: "材料",
        profile: "斷面規格",
        mesh: "鋼筋網格",
        rebar: "鋼筋",
        shape: "形狀",
        geometry: "幾何體",
        modeltemplate: "模型樣板",
        cloningtemplate: "複製樣板",
        noaction: "沒有作用",
        releasenotes: "發行註記",
      },
      contentItemHeader: {
        bolt: "螺栓組合",
        component: "元件",
        drawing: "圖面設定 (BETA)",
        material: "材料",
        profile: "斷面規格",
        mesh: "鋼筋網格",
        rebar: "鋼筋",
        shape: "形狀",
      },
      useCategoryGroups: {
        workflow: "工作流程",
        structures: "結構",
        drawings: "圖面",
        modeling: "模型建立",
      },
      useCategories: {
        title: "使用於/對象",
        conceptualDesign: "概念設計",
        designEngineeringAnalysis: "設計/工程/分析",
        detailing: "細部操作",
        fabrication: "製造",
        erectionInstallationPour: "吊裝/安裝/澆鑄",
        contracting: "合約承包",
        scheduling: "正在排程",
        takeOffEstimation: "數量計算/預估",
        concreteStructures: "現場澆鑄混凝土",
        precastConcrete: "預鑄混凝土",
        offshore: "離岸",
        steelStructures: "鋼骨結構",
        timberStructures: "木材結構",
        reinforcement: "鋼筋",
        drawingCreation: "圖面建立",
        drawingManagement: "圖面管理",
        drawingEditing: "圖面編輯",
        drawingAnnotation: "圖面註記",
        viewSimulateNavigate: "檢視、模擬、瀏覽",
        compareReviewManageData: "比較、審核、管理資料",
        softwareCollaboration: "軟體協同合作",
        productivity: "生產力",
        interoperability: "互通性",
        systemSetup: "系統設定",
      },
      measurementUnits: {
        "0": "公制",
        "1": "英制",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "開啟 BIM (IFC)",
        standalone: "獨立",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "無指定版本",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight1',
        //'TBS1': 'Tekla BIMsight1.5',
        //'TBS2': 'Tekla BIMsight2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "實驗性",
        candidate: "候選人",
        approved: "已核准",
        standard: "標準",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "英語",
        "zh-Hans": "簡體中文",
        "zh-Hant": "繁體中文",
        cs: "捷克語",
        da: "丹麥語",
        nl: "荷蘭語",
        fi: "芬蘭語",
        fr: "法語",
        de: "德語",
        hu: "匈牙利語",
        it: "義大利語",
        ja: "日語",
        ko: "韓語",
        pl: "波蘭語",
        pt: "葡萄牙語",
        "pt-br": "巴西葡萄牙語",
        ru: "俄語",
        es: "西班牙語",
        sv: "瑞典語",
        no: "挪威語",
        other: "其他",
      },
      isoLangs: {
        ab: {
          name: "阿布哈茲語",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "阿法爾語",
          nativeName: "Afaraf",
        },
        af: {
          name: "南非語",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "阿坎語",
          nativeName: "Akan",
        },
        sq: {
          name: "阿爾巴尼亞語",
          nativeName: "Shqip",
        },
        am: {
          name: "阿姆哈拉語",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "阿拉伯語",
          nativeName: "العربية",
        },
        an: {
          name: "阿拉貢語",
          nativeName: "Aragonés",
        },
        hy: {
          name: "亞美尼亞語",
          nativeName: "Հայերեն",
        },
        as: {
          name: "阿薩姆語",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "阿瓦爾語",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "阿維斯陀語",
          nativeName: "avesta",
        },
        ay: {
          name: "艾馬拉語",
          nativeName: "aymar aru",
        },
        az: {
          name: "亞塞拜然語",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "班巴拉語",
          nativeName: "bamanankan",
        },
        ba: {
          name: "巴什基爾語",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "巴斯克語",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "白俄羅斯語",
          nativeName: "Беларуская",
        },
        bn: {
          name: "孟加拉語",
          nativeName: "বাংলা",
        },
        bh: {
          name: "比哈爾語",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "比斯拉馬語",
          nativeName: "Bislama",
        },
        bs: {
          name: "波士尼亞語",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "布裏多尼語",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "保加利亞語",
          nativeName: "български език",
        },
        my: {
          name: "緬甸語",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "加泰隆尼亞語; 巴倫西亞語",
          nativeName: "Català",
        },
        ch: {
          name: "查莫羅語",
          nativeName: "Chamoru",
        },
        ce: {
          name: "車臣語",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "奇卡瓦語; 卡瓦語; 尼揚賈語",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "簡體中文",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "簡體中文",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "繁體中文",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "繁體中文 (台灣)",
          nativeName: "國語",
        },
        cv: {
          name: "楚瓦什語",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "凱爾特語",
          nativeName: "Kernewek",
        },
        co: {
          name: "科西嘉語",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "克里語",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "克羅埃西亞語",
          nativeName: "hrvatski",
        },
        cs: {
          name: "捷克語",
          nativeName: "česky, čeština",
        },
        da: {
          name: "丹麥語",
          nativeName: "dansk",
        },
        dv: {
          name: "迪培希語; 馬爾地夫語;",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "荷蘭語",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "英語",
          nativeName: "English",
        },
        eo: {
          name: "世界語",
          nativeName: "Esperanto",
        },
        et: {
          name: "愛沙尼亞語",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "佑歐語",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "法羅語",
          nativeName: "føroyskt",
        },
        fj: {
          name: "斐濟語",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "芬蘭語",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "法語",
          nativeName: "Français",
        },
        ff: {
          name: "富拉語",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "加利西亞語",
          nativeName: "Galego",
        },
        ka: {
          name: "喬治亞語",
          nativeName: "ქართული",
        },
        de: {
          name: "德語",
          nativeName: "Deutsch",
        },
        el: {
          name: "希臘語 (現代)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "瓜拉尼語",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "古吉拉特語",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "海地語",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "豪撒語",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "希伯來語 (現代)",
          nativeName: "עברית",
        },
        hz: {
          name: "赫勒婁語",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "北印度語",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "希裏木托語",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "匈牙利語",
          nativeName: "Magyar",
        },
        ia: {
          name: "國際語",
          nativeName: "Interlingua",
        },
        id: {
          name: "印尼語",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "國際語",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "愛爾蘭語",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "伊博語",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "因紐特語",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "伊多語",
          nativeName: "Ido",
        },
        is: {
          name: "冰島語",
          nativeName: "Íslenska",
        },
        it: {
          name: "義大利語",
          nativeName: "Italiano",
        },
        iu: {
          name: "依奴提圖特語",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "日語",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "爪哇語",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "格陵蘭語",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "埃納德語",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "卡努裡語",
          nativeName: "Kanuri",
        },
        ks: {
          name: "喀什米爾語",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "哈薩克語",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "穀美爾語",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "基庫尤語",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "基尼亞盧旺達語",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "吉爾吉斯語",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "科米語",
          nativeName: "коми кыв",
        },
        kg: {
          name: "剛果語",
          nativeName: "KiKongo",
        },
        ko: {
          name: "韓語",
          nativeName: "한국어",
        },
        ku: {
          name: "庫德語",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "貢耶瑪語",
          nativeName: "Kuanyama",
        },
        la: {
          name: "拉丁語",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "盧森堡語",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "烏干達語",
          nativeName: "Luganda",
        },
        li: {
          name: "林堡語",
          nativeName: "Limburgs",
        },
        ln: {
          name: "林加拉語",
          nativeName: "Lingála",
        },
        lo: {
          name: "寮國語",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "立陶宛語",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "盧巴卡丹加語 (西非)",
          nativeName: "",
        },
        lv: {
          name: "拉脫維亞語",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "曼島語",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "馬其頓語",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "馬達加斯加語",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "馬來語",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "馬拉雅拉姆語 (印度)",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "馬爾他語",
          nativeName: "Malti",
        },
        mi: {
          name: "毛利語",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "馬拉地語",
          nativeName: "मराठी",
        },
        mh: {
          name: "馬紹爾語",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "蒙古語",
          nativeName: "монгол",
        },
        na: {
          name: "諾魯",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "納瓦霍語",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "挪威伯克麥語",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "北恩德貝萊語 (南非方言的一種)",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "尼泊爾語",
          nativeName: "नेपाली",
        },
        ng: {
          name: "恩東加語",
          nativeName: "Owambo",
        },
        nn: {
          name: "挪威尼諾斯克語 (挪威兩種官方語言之一)",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "挪威語",
          nativeName: "Norsk",
        },
        ii: {
          name: "彝語",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "南恩德貝萊語 (南非方言的一種)",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "奧克語",
          nativeName: "Occitan",
        },
        oj: {
          name: "奧傑布瓦語",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "古代教會斯拉夫語",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "奧羅莫語",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "歐利亞語",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "奧塞梯語",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "邦加比語",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pāli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "波斯語",
          nativeName: "فارسی",
        },
        pl: {
          name: "波蘭語",
          nativeName: "polski",
        },
        ps: {
          name: "普什圖語",
          nativeName: "پښتو",
        },
        pt: {
          name: "葡萄牙語",
          nativeName: "Português",
        },
        "pt-br": {
          name: "巴西葡萄牙語",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "奇楚瓦語",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "羅曼什語",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "基隆迪語",
          nativeName: "kiRundi",
        },
        ro: {
          name: "羅馬尼亞語",
          nativeName: "română",
        },
        ru: {
          name: "俄語",
          nativeName: "русский",
        },
        sa: {
          name: "梵語",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "撒丁語",
          nativeName: "sardu",
        },
        sd: {
          name: "信德語",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "北拉普蘭語",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "薩摩亞語",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "桑戈語",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "塞爾維亞語",
          nativeName: "српски језик",
        },
        gd: {
          name: "蘇格蘭高地的蓋爾語; 蓋爾語",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "肖納語",
          nativeName: "chiShona",
        },
        si: {
          name: "僧伽羅語",
          nativeName: "සිංහල",
        },
        sk: {
          name: "斯洛伐克語",
          nativeName: "slovenčina",
        },
        sl: {
          name: "斯洛維尼亞語",
          nativeName: "slovenščina",
        },
        so: {
          name: "索馬利亞語",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "南梭蘇語",
          nativeName: "Sesotho",
        },
        es: {
          name: "西班牙語", //      'name':'傳統/西班牙語',
          nativeName: "español",
        },
        su: {
          name: "巽他語",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "斯瓦希裡語",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "斯瓦特語",
          nativeName: "SiSwati",
        },
        sv: {
          name: "瑞典語",
          nativeName: "svenska",
        },
        ta: {
          name: "坦米爾語",
          nativeName: "தமிழ்",
        },
        te: {
          name: "泰盧固語 (印度東部)",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "塔吉克語",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "泰語",
          nativeName: "ไทย",
        },
        ti: {
          name: "提格裡尼亞語",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "藏語",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "土庫曼語",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "他加祿語",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "茨瓦納語",
          nativeName: "Setswana",
        },
        to: {
          name: "東加語 (東加群島)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "土耳其語",
          nativeName: "Türkçe",
        },
        ts: {
          name: "聰加語",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "韃靼語",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "契維語",
          nativeName: "Twi",
        },
        ty: {
          name: "大溪地語",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "維吾爾語",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "烏克蘭語",
          nativeName: "українська",
        },
        ur: {
          name: "烏爾都語",
          nativeName: "اردو",
        },
        uz: {
          name: "烏茲別克語",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "溫達語",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "越南語",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "沃拉普克語",
          nativeName: "Volapük",
        },
        wa: {
          name: "華隆語",
          nativeName: "Walon",
        },
        cy: {
          name: "威爾士語",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "沃洛夫語",
          nativeName: "Wollof",
        },
        fy: {
          name: "西弗里西語",
          nativeName: "Frysk",
        },
        xh: {
          name: "科薩語 (南非方言的一種)",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "意第緒語",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "約魯巴語",
          nativeName: "Yorùbá",
        },
        za: {
          name: "壯族語",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "其他",
          nativeName: "其他",
        },
      },

      details: {
        navigation: {
          content_information: "內容資訊",
          version_and_file_management: "版本與檔案管理",
          details: "詳細資料",
          versions: "版本",
          analytics: "分析",
          statistics: "統計資料",
          manage_access: "管理存取",
          related_content: "相關",
          properties: "性質",
          support: "公司及支援",
          release_notes: "發行註記",
          comments: "用戶註解",
        },
        related_content: {
          other_content_title: "您可能喜歡的其他內容",
          related_content_title: "內容建議者 ",
          related_content_url: "內容 URL",
          add_related_content: "加入相關內容",
          add_related_content_info: "您只能將相關線上內容加入到線上內容，並將本機內容加入到本機內容",
          remove_related_content: "移除",
          relatedContentUrlSubtitle: "為內容加入內容 ID 或完整的 URL",
          updated_successfully: "已成功更新相關內容",
          update_failed: "更新相關內容時發生錯誤",
          update_failed_invalid_id: "提供的內容 ID 無效",
        },
        actions: {
          move: "移動",
          delete: "刪除",
          add_comment: "加入註解",
          add_reply: "加入回覆",
          edit_comment: "編輯註解",
          reply_comment: "加入回覆",
          view_replies: "檢視回覆",
          report_bad_comment: "報表錯誤註解 &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "編輯標題或刪除",
            title_or_move_delete: "編輯標題、移動或刪除",
            thumbnail: "編輯縮圖",
            description: "編輯描述",
            groupandcategory: "編輯群組和目錄",
            details: "編輯細部",
            versions: "編輯版本",
            support: "編輯支援資訊",
            release_notes: "編輯發行註記",
            search_criteria: "編輯搜尋條件",
            batchedit_and_translations:
              "藉由翻譯功能，您可以多國語言出版內容。 不同版本的內容翻譯將會依據用戶所選的 Warehouse UI 語言進行顯示。 透過批次編輯功能，您可以一次編輯多個項目。 首先選擇欄位，接著選擇您要編輯的項目",
          },
        },
        links: {
          link: "連結",
          select_collection_placeholder: "選擇要連結的收集",
          linking_collections_label: "與此連結的收集",
          private_content_note: "註記: 個人內容將維持只對可存取的用戶顯示。",
          share_on_social_media: "分享於:",
          report_bad_content: "報表內容錯誤 &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "報告不合法內容 &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "當您訂閱通知，即表示您同意與內容擁有者分享您的 Tekla Account 顯示名稱與組織名稱 (若有的話)。",
          alert_me: "通知我",
          unsubscribe: "取消訂閱",
          subscribed_successfully: "已成功訂閱",
          unsubscribed_successfully: "已成功取消訂閱",
          subscribers_label: "訂閱者",
        },
        reviews: {
          reviewers_label: "檢閱者",
        },
        comments: {
          replies: "回覆",
        },
        scan: {
          soft_failed_binaries_info: "病毒掃描失敗  ",
          hard_failed_binaries_info: "無法完成病毒掃描 ",
          infected_binaries_info: "病毒掃描找到一項感染 ",
          what_now: "現在該怎麼辦?",
          popup: {
            soft_failed_binaries_title: "無法執行的二進位檔",
            hard_failed_binaries_title: "無法執行的二進位檔",
            infected_binaries_title: "遭受感染的二進位檔",
            soft_failed_binaries_info:
              "您所上傳的檔案無法完成病毒掃描。 這是暫時的情況。 請移除檔案並重新上傳。 所有檔案必須先通過病毒掃描，其他用戶才會看到這些檔案。",
            hard_failed_binaries_info:
              "無法完成病毒掃描。 請移除檔案。 所有檔案必須先通過病毒掃描，其他用戶才會看到這些檔案。",
            infected_binaries_info:
              "病毒掃描在上傳的檔案中找到一項感染。 請移除檔案。 所有檔案必須先通過病毒掃描，其他用戶才會看到這些檔案。",
            version: "版本: ",
            fileName: "檔案名稱: ",
          },
        },
        status: {
          content_banned_info:
            "該內容已被管理員封鎖。目前其他用戶無法使用，除了內容擁有者以外。<a href='https://support.tekla.com/contact-us' target='_blank'>聯絡支援人員</a>了解更多。",
          content_archived_info:
            "此內容已封存。沒有編輯權限的使用者如果嘗試透過直接連結存取內容，將被重定向到首頁。",
          archived: "已封存",
        },
        modified_by: function (name) {
          return " 以 " + name;
        },
        created_by: function (name) {
          return "建立者 " + name;
        },
        deleteEntity: function (title) {
          return "刪除 " + title + "?";
        },
        userComments: "用戶註解",
        comment_added: "註解已成功加入",
        comment_updated: "註解已成功更新",
        comment_deleted: "註解已成功刪除",
        comment_add_failed: "無法加入註解",
        comment_update_failed: "無法更新註解",
        comment_delete_failed: "無法刪除註解",
        comment_delete_confirmation: "要刪除註解項目?",
        name: "名稱",
        details: "細部",
        contentOwner: "建立者",
        contentOwnerGroup: "內容擁有者群組",
        contentGroup: "內容群組",
        thumbnail: "縮圖",
        tooltip: "工具提示",
        description: "描述",
        additional_information: "附加資訊",
        images: "產品影像",
        installationInfo: "安裝資訊",
        productCode: "產品代碼",
        copyrightInfo: "著作權",
        productExpirationDate: "產品過期日期",
        helpUrl: "網站 &rsaquo;",
        supportUrl: "支援 &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "發行註記",
        supportForumUrl: "Discussion Forum &rsaquo;",
        itemTypeCategories: "群組",
        useCategories: "目錄",
        category: "目錄:",
        group: "群組: ",
        compatibleSoftwareProducts: "相容的軟體產品",
        compatibleOperatingSystems: "相容的作業系統",
        locationRestrictions: "產品可用性:",
        supportedLanguages: "語言:",
        measurementUnits: "測量單位:",
        tags: "標籤與關鍵字:",
        prerequisites: "先決條件:",
        releaseNotes: "發行註記:",
        versions: "版本",
        testedVersions: "相容版本",
        qualityTag: "品質 - 由擁有者評估",
        otherAttributes: "其他屬性",
        labels: "搜尋條件",
        supportInformation: "支援",
        updateInformation: "更新",
        latestUpdate: "上次更新",
        totalDownloads: "下載總數",
        licenses: "版權",
        maintenanceRequired: "下載時需要 Tekla 維護合約",
        tekla_maintenance_content: "Tekla 維護內容",
        download_requires_tekla_maintenance: "下載時需要 Tekla 維護合約",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "已成功更新",
        update_failed: "更新失敗",
        update_failed_invalid_tag: "更新失敗: 無效的標籤",
        update_failed_details_max_length_exceeded: "更新失敗: 細部太長",
        show_version_details: "更多資訊",
        content_removed: "內容已移除",
        no_versions_available: "無可用的版本。 更新可能需要一點時間才會顯示。 更新瀏覽器以檢視變更。",
        thumbnail_deleted: "縮圖已刪除",
        thumbnail_updated: "縮圖已更新",
        verify_service_plugin_is_running: "請確認您的 Tekla Warehouse Service 插件程式已正確執行",
        verify_ts_connection: "請確認 Tekla Structures 正在執行",
        download_tos: {
          title: "品質、支援與隱私權",
          content_1: "請確保下載的內容符合您的品質標準。 對於協力廠商內容，Trimble 不支援亦不負責。 請直接洽詢內容提供者尋求支援。",
          content_2: "您的國家/地區與組織名稱應該已在服務合約中發佈並向內容擁有者揭露您的合法用途，以便用於知會您最新內容更新訊息。 您可以從這些通知中取消訂閱內容。",
          content_3: '對於 Trimble 內容擴展，適用 <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimble 條款</a>。第三方內容可能適用第三方許可條款。請參閱 <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimble 隱私聲明</a>。',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "此為免費提供的 Tekla Structures BIM 儲存空間。 您可以在公司內部與全球尋找、輸入、安裝及共用， 更有效率地建立高品質模型。",
        },
        landing: {
          title: "Tekla Warehouse",
          description: "Tekla Warehouse 是針對 Tekla Structures 建模工具、智慧型零件與樣板所打造的免費線上與本機資料庫",
        },
        search: {
          title: "搜尋 Tekla Warehouse",
          description:
            "搜尋 Tekla 延伸功能、3D 零件、形狀、用戶元件、斷面規格、鋼板之類的材料與混凝土等級、鋼筋、網格與樣板材料",
        },
        about: {
          title: "Tekla Warehouse 相關資訊",
          description: "Tekla Warehouse 為您的產品提供免費行銷管道，讓您充分掌控內容",
          developers: {
            title: "預設開發人員標題",
            description: "預設開發人員描述",
          },
          usage: {
            title: "預設用途標題",
            description: "預設用途描述",
          },
          getting_started: {
            title: "預設開始使用標題",
            description: "預設開始使用描述",
          },
          content: {
            title: "預設內容標題",
            description: "預設內容描述",
          },
          using_content: {
            title: "預設使用內容標題",
            description: "預設使用內容描述",
          },
          creating_content: {
            title: "預設建立內容標題",
            description: "預設建立內容描述",
          },
        },
        collection: {
          title: "預設收集標題",
          description: "預設收集描述",
        },
        my_warehouse: {
          title: "我的 Tekla Warehouse",
          description: "管理您的 Tekla Warehouse 內容",
        },
      },
      upload: {
        actions: {
          cancel: "取消",
          previous: "&lsaquo; 上一項",
          next: "下一項 &rsaquo;",
        },
        content: {
          add_content: "新增檔案",
        },
        collection: {
          heading: "選擇或建立收集",
          subHeading:
            "收集群組內容。 您可以使用線上收集來出版內容，或是透過本機收集和同時共用內容。 您也可以將本機收集當作個人用途。",
          loading: "正在載入收集...",
          selectFromMyCollections: "從我的收集選擇",
          description_of_the_collection: "描述",
          title_validation: {
            missing: "請輸入標題。",
            too_short: function (length) {
              return "標題至少應為 " + length + " 個字元。";
            },
            too_long: function (length) {
              return "標題不能超過 " + length + " 個字元。";
            },
          },
        },
        package: {
          basic_information: "基本資訊",
          code_name: "代碼名稱",
          code_name_info: "輸入代碼名稱作為該內容的唯一識別碼。代碼名稱可以包含小寫字母、數字和以下特殊字元：_ - @。儲存後，您將無法變更代碼名稱。",
          code_name_validation: {
            missing: "您必須輸入代碼名稱",
            wrong_format: "代碼名稱可以包含小寫字母、數字和以下特殊字元：_ - @",
            not_unique: "該代碼名稱已被使用。您必須輸入唯一的代碼名稱。",
          },
          search_criteria: "搜尋條件",
          support: "支援",
          title: "標題",
          thumbnail: "縮圖",
          "3D_pic": "3D 模型",
          title_validation: {
            missing: "請輸入標題。",
            too_short: function (length) {
              return "標題至少應為 " + length + " 個字元。";
            },
          },
          description_validation: {
            missing: "請輸入描述。",
            too_short: function (length) {
              return "描述至少應為 " + length + " 個字元。";
            },
          },
          wrong_file_type: "錯誤的檔案類型。",
          selected_image_is_too_big: "所選影像太大。",
        },
        version: {
          select_version: "選擇版本",
          select_quality: "選擇品質",
          versions: "版本",
          displayNameNew: "版本顯示名稱",
          copy_from_version: "從已存在版本複製中繼資料 (選擇性)",
          displayName: "顯示名稱",
          content_information: "內容資訊",
          back_to_content_information: "&lsaquo; 回到內容資訊",
          version_and_file_management: "版本與檔案管理",
          there_s_no_versions_yet: "尚無版本。",
          create_a_version_to_add_files: "建立版本以加入檔案。",
          add_version: "加入版本",
          version_information: "版本資訊",
          delete_version: "刪除版本",
          no_versions_available: "無可用的版本。 更新可能需要一點時間才會顯示。",
          update_start_title_html: '<div class="spinner spinner-white">更新</div>',
          update_start_message: "此作業可能需要一點時間。",
          version_was_created: "版本已建立。",
          version_was_created_with_delay: "版本已建立。 變更在幾分鐘後就會顯示出來。",
          version_was_updated: "版本已更新。",
          version_was_deleted: "版本已刪除",
          version_was_deleted_with_delay: "版本已刪除。 變更在幾分鐘後就會顯示出來。",
          confirm_delete: function (title) {
            return '刪除版本 "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "已上傳套件。",
          couldn_t_create_a_package: "無法建立套件。 變更將復原。",
          uploading_title_html: '<div class="spinner spinner-white">上傳中</div>',
          uploading_text: "此作業可能需要一點時間。",
          select_organization: "選擇組織",
          collection_visibility: "收集可見性:",
          actions: {
            create: "建立",
            publish: "出版",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "此內容將對可存取本機或網路磁碟機的用戶顯示。",
          this_content_will_be_visible_only_to_you: "此內容只會對您顯示。",
          this_content_will_be_visible_only_to_you_and_your_organization: "此內容只對您及組織內的所有人顯示。",
          this_content_will_be_visible_to_everyone_after_publishing: "此內容將在出版後對所有人顯示。",
          public_content_disclaimer:
            "您需對所上傳之內容負責。 您有權上傳內容，但請確保您的內容無害，且不違反任何智慧財產權規定。 您同意在服務合約中公布任何適用於您所上傳之內容用途的相關條款與條件。",
        },
        metadata: {
          title: "中繼資料",
          subtitle: "基本資料",
          deleteThumbnail: "刪除縮圖",
          buttonCreate: "建立",
        },
        basicdata: {
          title: "加入內容",
          clickButtonToSelectFile: "點擊按鈕以選擇檔案",
          thumbnail: "縮圖",
          dropImageHere: "將影像放置在此",
        },
        "3dGeometry": {
          title: "建立 3D 產品目錄",
          buttonAddEntity: "加入 3D 產品",
          buttonAdd: "加入自訂屬性",
          errorAttributeAlreadyExists: "已存在相同名稱的屬性。",
          buttonCreatePackage: "建立套件",
          buttonCreatePackages: "建立套件",
          addCustomAttributePlaceholder: "屬性的名稱",
        },
        please_log_in_fragments: {
          please: "請 ",
          log_in: "登入",
          or: " 或 ",
          register: "註冊",
          to_upload_online_content: " 上傳線上內容。",
        },
        header: {
          upload_header: "上傳",
          collection: "收集",
          content: "內容",
          version: "版本",
          finish: "完成",
        },
        contentOwner: "建立者",
        contentOwnerGroup: "出版內容",
        select_action: "選擇動作",
        tooltip: "工具提示",
        description: "描述",
        copyright: "著作權",
        tags: "標籤與關鍵字",
        tagsSubtitle:
          "請使用逗號來分隔，例如: 關鍵字 1、關鍵字 2。 如果關鍵字包含多個文字，請在文字間使用底線 (_)，但請勿使用空格。 除了底線與逗號之外，不得使用其他特殊字元。",
        images: "影像",
        installationInfo: "安裝資訊",
        productCode: "產品代碼",
        copyrightInfo: "著作權資訊",
        Details: "詳細資料",
        specialTermsUrl: "EULA",
        productExpirationDate: "產品過期日期",
        helpUrl: "網站",
        helpUrlSubtitle: "插入完整的 URL",
        detailsSubtitle:
          "在此階段，[細部] 欄位只能加入純文字。 內容建立完畢後，您可以編輯 [細部] 以包含 HTML 格式的資料。",
        specialTermsUrlSubtitle:
          "內容擁有者有機會為自己的內容新增額外的條款。 擁有者需要提供其指定條款的位置 URL。 該條款將不會取代 Trimble 的服務條款。",
        releaseNotes: "發行註記檔案",
        releaseNoteFileSubTitle: "將發行註記加入為檔案",
        supportUrl: "說明與支援",
        supportUrlSubtitle: "插入完整的 URL 或是「mailto:」前置碼以發送電子郵件",
        supportForumUrl: "Discussion Forum (網站)",
        supportForumUrlSubtitle: "插入完整的 URL",
        measurementUnits: "測量單位",
        itemTypeCategories: "群組",
        useCategories: "目錄",
        compatibleSoftwareProducts: "相容的軟體產品",
        testedVersions: "相容版本",
        compatibleOperatingSystems: "相容的作業系統",
        locationRestrictions: "產品可用性",
        supportedLanguages: "語言",
        select_releasenotes_file: "選擇檔案",
        qualityTag: "品質 - 由擁有者評估",
        requireMaintenance: "下載時需要 Tekla 客戶維護合約",
        contentOwnerGroupAsMyself: "如我自己",
        contentOwnerGroupAsUserGroup: "如用戶群組",
        selectUserGroupForContentOwner: "選擇內容擁有者的用戶群組",
        defaultUploadTemplate: "預設套件",
        templateFor3DGeometryFiles: "3D 幾何體套件",
        title: "標題",
        details: "細部",
        selectContentOwner: "選擇內容擁有者",
        selectContentType: "類型",
        errorWrongfiletype: "錯誤的檔案類型。",
        errorImageTooBig: "所選影像太大。",
        errorBlankTitle: "標題不能為空白。",
        commonLabelsTitle: "所有套件的通用標籤",
      },
      profile: {
        content_usage: "內容用途",
        profile_updated: "斷面規格已更新",
        invitation_accepted: function (target) {
          return "已接受邀請! 您現在可以存取 " + target + ".";
        },
        user_groups: {
          edit_user_group: "編輯用戶群組",
          user_group_updated: "用戶群組已更新",
          create_a_new_user_group: "建立新用戶群組",
          name_of_the_user_group: "用戶群組名稱",
          description: "描述",
          group_visibility: "群組可見性",
          remove: '"您確定嗎?"',
          remove_invitations: '"您確定嗎?"',
          remove_members: '"您確定嗎?"',
          invitations_removed: "邀請已移除!",
          members_list_updated: "成員清單已更新!",
          user_group_created: "用戶群組已建立",
        },
        members: {
          update_selected_members: "更新選定的成員",
          new_role: "新角色",
          members_list_updated: "成員清單已更新!",
          edit_user_group: "編輯用戶群組",
          delete_user_group: "刪除用戶群組",
          filter: "篩選條件",
          user_group_members: "用戶群組成員",
          update_members: "更新成員",
          remove_members: "移除成員",
          pending_invitations: "等候傳送的邀請",
          invite_member: "邀請成員",
          update_invitations: "更新邀請",
          remove_invitations: "移除邀請",
        },
        subTabs: {
          alerts: "我的通知",
          collections: "收集",
          likes: "按讚數",
        },
        admin: {
          grant_sales_admin: "授予用戶銷售管理員角色",
          grant_analyst_role: "授予用戶分析師角色",
          role_granted: "授予角色",
          role_removed: "角色已移除",
          offline_to_online: "將離線內容轉換成線上內容",
          use_elastic_search: "使用彈性搜尋",
        },
        sales: {
          title: "銷售",
        },
        subtitlePreferences: "搜尋喜好設定",
        preferenceInfo: '這些篩選條件主要用於為您推薦 Tekla Warehouse <a href="#/landing">首頁</a>上的內容。',
        clearFilters: "清除篩選條件 &rsaquo;",
        admin_role: "管理",
        sales_role: "銷售",
        admin_mode: "管理模式:",
        firstName: "名字",
        lastName: "姓氏",
        displayName: "顯示名稱:",
        organization: "組織名稱:",
        email: "電子郵件:",
        teklaId: "Tekla ID",
        maintenanceAccess: "存取 Tekla 維護內容:",
        myLocations: "專案位置",
        measurementUnits: "單位",
        useCategories: "使用",
        itemTypeCategories: "我喜歡下列類型",
        languages: "語言",
        operatingSystems: "作業系統",
        software: "軟體產品",
        user_id: "用戶 ID:",
        organization_id: "組織 ID:",
        edit_in_atc: "編輯 Tekla Account 裡我的個人資料",
        no_likes: "無按讚數",
        no_alerts: "無訂閱",
        clear_alerts: "清除通知",
        alerts_info_html:
          "當發生任何下列任一變更，您將收到通知<br>" +
          "<b>內容:</b> 有人修改內容項目的標題、描述、搜尋條件或中繼資料、加入新版內容，或是刪除某個版本時。<br>" +
          "<b>收集:</b> 有人編輯標題或描述，或是加入或移除內容時。",
      },
      uploader: {
        placeholders: {
          productCode: "產品代碼",
          title: "名稱",
          description: "描述",
          productWeight: "產品重量",
          weightUnit: "重量單位",
          material: "材料",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D 產品目錄",
          addToolButton: "應用程式",
          select_files: "來自磁碟的檔案",
          select_files_from_ts: "來自 Tekla Structures 的檔案",
          partnerDownloadLink: "合作夥伴下載連結",
        },
        headings: {
          geometryFile: "幾何體檔案",
          thumbnailFile: "縮圖點陣圖 (.bmp)",
          title: "名稱",
          description: "描述",
          productCode: "產品代碼",
          productWeight: "產品重量",
          weightUnit: "重量單位",
          material: "材料",
        },
      },
      constants: {
        countries: {
          GLOBAL: "全球",
          AF: "阿富汗",
          AX: "奧蘭群島",
          AL: "阿爾巴尼亞",
          DZ: "阿爾及利亞",
          AS: "美屬薩摩亞",
          AD: "安道爾",
          AO: "安哥拉",
          AI: "安圭拉",
          AQ: "南極大陸",
          AG: "安地卡及巴布達",
          AR: "阿根廷",
          AM: "亞美尼亞",
          AW: "阿路巴",
          AU: "澳洲",
          AT: "奧地利",
          AZ: "亞塞拜然",
          BS: "巴哈馬",
          BH: "巴林",
          BD: "孟加拉",
          BB: "巴貝多",
          BY: "白俄羅斯",
          BE: "比利時",
          BZ: "貝里斯",
          BJ: "貝南",
          BM: "百慕達",
          BT: "不丹",
          BO: "玻利維亞",
          BQ: "波奈",
          BA: "波士尼亞赫塞哥維納",
          BW: "波札那",
          BV: "布威島",
          BR: "巴西",
          IO: "英屬印度洋領土",
          BN: "汶萊",
          BG: "保加利亞",
          BF: "布吉納法索",
          BI: "蒲隆地",
          KH: "柬埔寨",
          CM: "喀麥隆",
          CA: "加拿大",
          CV: "維德角",
          KY: "開曼群島",
          CF: "中非共和國",
          TD: "查德",
          CL: "智利",
          CN: "中國",
          CX: "聖誕島",
          CC: "可可斯群島",
          CO: "哥倫比亞",
          KM: "葛摩",
          CG: "剛果共和國",
          CD: "剛果民主共和國",
          CK: "柯克群島",
          CR: "哥斯大黎加",
          CI: "象牙海岸",
          HR: "克羅埃西亞",
          CU: "古巴",
          CW: "古拉索",
          CY: "賽普勒斯",
          CZ: "捷克共和國",
          DK: "丹麥",
          DJ: "吉布地",
          DM: "多米尼克",
          DO: "多明尼加共和國",
          EC: "厄瓜多",
          EG: "埃及",
          SV: "薩爾瓦多",
          GQ: "赤道幾內亞",
          ER: "厄利垂亞",
          EE: "愛沙尼亞",
          ET: "衣索比亞",
          FK: "福克蘭群島",
          FO: "法羅群島",
          FJ: "斐濟",
          FI: "芬蘭",
          FR: "法國",
          GF: "法屬圭亞那",
          PF: "法屬玻里尼西亞",
          TF: "法國南方領土",
          GA: "加彭",
          GM: "甘比亞",
          GE: "喬治亞",
          DE: "德國",
          GH: "迦納",
          GI: "直布羅陀",
          GR: "希臘",
          GL: "格陵蘭",
          GD: "格瑞那達",
          GP: "哥德普洛",
          GU: "關島",
          GT: "瓜地馬拉",
          GG: "根息",
          GN: "幾內亞",
          GW: "幾內亞比索",
          GY: "蓋亞納",
          HT: "海地",
          HM: "赫德島和麥克唐納群島",
          VA: "梵蒂岡",
          HN: "宏都拉斯",
          HK: "香港特別行政區",
          HU: "匈牙利",
          IS: "冰島",
          IN: "印度",
          ID: "印尼",
          IR: "伊朗",
          IQ: "伊拉克",
          IE: "愛爾蘭",
          IM: "曼島",
          IL: "以色列",
          IT: "義大利",
          JM: "牙買加",
          JP: "日本",
          JE: "澤西島",
          JO: "約旦",
          KZ: "哈薩克",
          KE: "肯亞",
          KI: "吉里巴斯",
          KW: "科威特",
          KG: "吉爾吉斯",
          LA: "寮國",
          LV: "拉脫維亞",
          LB: "黎巴嫩",
          LS: "賴索托",
          LR: "賴比瑞亞",
          LY: "利比亞",
          LI: "列支敦斯登",
          LT: "立陶宛",
          LU: "盧森堡",
          MO: "澳門特別行政區",
          MK: "馬其頓共和國",
          MG: "馬達加斯加",
          MW: "馬拉威",
          MY: "馬來西亞",
          MV: "馬爾地夫",
          ML: "馬利",
          MT: "馬爾他",
          MH: "馬紹爾群島",
          MQ: "馬丁尼克",
          MR: "茅利塔尼亞",
          MU: "模里西斯",
          YT: "馬約特島",
          MX: "墨西哥",
          FM: "密克羅尼西亞",
          MD: "摩爾多瓦",
          MC: "摩納哥",
          MN: "蒙古",
          ME: "蒙特內哥羅",
          MS: "蒙特色拉特島",
          MA: "摩洛哥",
          MZ: "莫三比克",
          MM: "緬甸",
          NA: "納米比亞",
          NR: "諾魯",
          NP: "尼泊爾",
          NL: "紐西蘭",
          NC: "新喀里多尼亞群島",
          NZ: "紐西蘭",
          NI: "尼加拉瓜",
          NE: "尼日",
          NG: "奈及利亞",
          NU: "紐威島",
          NF: "諾福克島",
          KP: "北韓",
          MP: "北馬里安納群島",
          NO: "挪威",
          OM: "阿曼",
          PK: "巴基斯坦",
          PW: "帛琉",
          PS: "巴勒斯坦領土",
          PA: "巴拿馬",
          PG: "巴布亞紐幾內亞",
          PY: "巴拉圭",
          PE: "秘魯",
          PH: "菲律賓",
          PN: "皮特康",
          PL: "波蘭",
          PT: "葡萄牙",
          PR: "波多黎各",
          QA: "卡達",
          RE: "留尼旺",
          RO: "羅馬尼亞",
          RU: "俄羅斯聯邦",
          RW: "盧安達",
          BL: "聖巴泰勒米",
          SH: "聖赫勒拿島",
          KN: "聖克里斯多福及尼維斯",
          LC: "聖露西亞",
          MF: "聖馬丁島",
          PM: "聖匹島",
          VC: "聖文森及格瑞那丁",
          WS: "薩摩亞",
          SM: "聖馬利諾",
          ST: "聖多美普林西比",
          SA: "沙烏地阿拉伯",
          SN: "塞內加爾",
          RS: "塞爾維亞",
          SC: "塞席爾",
          SL: "獅子山",
          SG: "新加坡",
          SX: "荷屬聖馬丁",
          SK: "斯洛伐克",
          SI: "斯洛維尼亞",
          SB: "索羅門群島",
          SO: "索馬利亞",
          ZA: "南非",
          GS: "南喬治亞",
          KR: "南韓",
          SS: "南蘇丹",
          ES: "西班牙",
          LK: "斯里蘭卡",
          SD: "蘇丹",
          SR: "蘇利南",
          SJ: "司瓦巴與揚馬延",
          SZ: "史瓦濟蘭",
          SE: "瑞典",
          CH: "瑞士",
          SY: "敘利亞阿拉伯共和國",
          TW: "台灣",
          TJ: "塔吉克",
          TZ: "坦尚尼亞",
          TH: "泰國",
          TL: "東帝汶",
          TG: "多哥",
          TK: "托克勞群島",
          TO: "東加",
          TT: "千里達及托巴哥",
          TN: "突尼西亞",
          TR: "土耳其",
          TM: "土庫曼",
          TC: "土克斯及開科斯群島",
          TV: "吐瓦魯",
          UG: "烏干達",
          UA: "烏克蘭",
          AE: "阿拉伯聯合大公國",
          GB: "英國",
          US: "美國",
          UM: "美國外島",
          UY: "烏拉圭",
          UZ: "烏茲別克",
          VU: "萬那杜",
          VE: "委內瑞拉",
          VN: "越南",
          VG: "維爾京群島",
          VI: "美屬維爾京群島",
          WF: "瓦利斯及福杜納群島",
          EH: "西撒哈拉",
          YE: "葉門",
          ZM: "尚比亞",
          ZW: "辛巴威",
        },
        sortOptions: {
          "-modifyTime": "上次更新",
          "-modifiedAt": "上次更新",
          title: "依 A-Z 字母順序",
          "-title": "依 Z-A 字母順序",
          "-reviewCount": "最多人按讚",
          name: "名稱",
          role: "角色",
          email: "電子郵件",
          "-relevance": "關聯性",
          "-createdAt": "上次建立日期",
          displayName: "依 A-Z 字母順序",
          "-displayName": "依 Z-A 字母順序",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "今天",
          yesterday: "昨天",
          this_week: "本週",
          previous_week: "上週",
          this_month: "本月",
          previous_month: "上月",
          this_year: "今年",
          previous_year: "去年",
          beginning_of_time: "開始時間",
          custom: "自訂時間範圍",
        },
        eventTypeOptions: {
          downloads: "下載",
          views: "檢視",
          all_events: "所有事件",
        },
      },
    });
  },
};
