import { TCCCollectionDS } from "../data-source/TCCCollectionDS";
import { NewCollectionConverter } from "../converters/NewCollectionConverter";

function get(data) {
  return TCCCollectionDS.getCollection(data.id, { performAs: data.modifier.id }).then(NewCollectionConverter.fromTCC);
}

function updateCollection(data) {
  var convertedData = NewCollectionConverter.toTCC(data);
  return TCCCollectionDS.setCollection(convertedData)
    .then(TCCCollectionDS.getCollection(data.id, { performAs: data.modifier.id }))
    .then(NewCollectionConverter.fromTCC);
}

export const LinkedResourcesCollectionFinder = {
  /**
   * Finds the linked resources collection and updates its owner and visibility
   * * If the linked resources collection is not found, it resolves the promise
   * * If the linked resources collection is found, it updates the owner and visibility
   * @param {*} data
   * @returns
   */
  findAndUpdateOwnerAndVisibility: function (data) {
    return new Promise((resolve, reject) => {
      if (!data.linkedResourcesCollection) {
        resolve({ id: data.id });
      } else {
        get({ id: data.linkedResourcesCollection.id, modifier: data.modifier }).then(
          function (res) {
            var linkedResourcesCollection = res;
            if (linkedResourcesCollection) {
              linkedResourcesCollection.creator = data.creator;
              linkedResourcesCollection.visibility = data.visibility;
              updateCollection(linkedResourcesCollection).then(
                function () {
                  resolve({ id: data.id });
                },
                function (err) {
                  reject(err);
                },
              );
            } else {
              resolve({ id: data.id });
            }
          },
          function () {
            resolve({ id: data.id });
          },
        );
      }
    });
  },
};
