import { action, observable, runInAction, makeObservable } from "mobx";
import _ from "underscore";
import { DateTime } from "luxon";

import { TCCPackageService } from "../../js/services/TCCPackageService";
import { createStoreContext, RootStore } from "../../stores/rootStore";
import { IComment, ICommentFetchResult, IEntity, IOrganization, IUser } from "../../models/dataModel";

export interface ICommentData {
  text: string;
  parentCommentId?: string;
}

/**
 * A store that contains logic for package comments.
 */
export class UserCommentsStore {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Package item
   */
  private packageItem?: IEntity;

  /**
   * loading indicator
   */
  @observable protected loading = false;

  /**
   * Flag that marks if comment has been deleted.
   */
  @observable protected commentDeleted = false;

  /**
   * Number of comments that are loaded and shown on the page at once.
   */
  public static readonly COMMENTS_PER_PAGE: number = 2;

  /**
   * Number of comments loaded.
   */
  @observable private commentsLoadedCount = 0;

  /**
   * All the comments for package.
   */
  @observable private allComments: IComment[] = [];

  /**
   * Comments that user can see in the the page.
   */
  @observable private comments: IComment[] = [];

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   * @param packageItem Package object
   */
  public constructor(rootStore: RootStore, packageItem?: IEntity) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (packageItem) {
      runInAction(() => {
        this.packageItem = packageItem;
      });
      this.init();
    }
  }

  /**
   * Gets comments that user can see in the page.
   * @returns List of comments.
   */
  public getComments(): IComment[] {
    return this.comments;
  }

  /**
   * Resolves if comments are loading
   * @returns true if is comments are being loaded
   */
  public isLoading(): boolean {
    return this.loading;
  }

  /**
   * Updates a comment.
   * @param commentData object that contains the new comment data
   */
  @action
  public updateComment = async (
    commentData,
    commentToEdit: IComment,
    editor: IOrganization | IUser | undefined,
    stopLoading?: boolean,
  ) => {
    this.loading = true;

    try {
      await TCCPackageService.updateComment(this.packageItem!.id, commentToEdit.id, commentData, editor?.id);
      this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("details.comment_updated"));
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("details.comment_updated_failed"));
    }

    if (stopLoading) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  /**
   * Deletes a comment from package.
   * @param commentToDelete comment to delete
   */
  @action
  public deleteComment = async (
    commentToDelete: IComment,
    editor: IOrganization | IUser | undefined,
    stopLoading?: boolean,
  ) => {
    this.loading = true;

    try {
      await TCCPackageService.deleteComment(this.packageItem!.id, commentToDelete.id, editor?.id);
      this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("details.comment_deleted"));
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("details.comment_delete_failed"));
    }

    if (stopLoading) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  /**
   * Handles side effects of deleting / editing one of the comments.
   * @param isDeleted True if comment was deleted, false if only edited.
   */
  @action
  public async handleCommentEdit(isDeleted: boolean) {
    if (isDeleted) {
      setTimeout(async () => await this.resetCommentsListAfterDelete(), 1200);
    } else {
      setTimeout(async () => await this.resetCommentsListAfterEdit(), 1200);
    }
  }

  /**
   * Adds comment to package.
   * @param commentData object that contains the new comment data
   */
  @action
  public addComment = async (commentData: ICommentData, editor: IOrganization | IUser | undefined) => {
    this.loading = true;

    try {
      await TCCPackageService.addComment(this.packageItem!.id, commentData, editor?.id);
      this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("details.comment_added"));
      setTimeout(async () => await this.init(), 1200);
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("details.comment_add_failed"));
    }
  };

  /**
   * Checks if link to load previous comments should be shown.
   * @return True if link should be shown
   */
  public shouldShowPreviousCommentsLink = (): boolean => {
    return !!this.commentsLoadedCount && this.commentsLoadedCount > UserCommentsStore.COMMENTS_PER_PAGE;
  };

  /**
   * Checks if link to load next comments should be shown.
   * @return True if link should be shown
   */
  public shouldShowNextCommentsLink = (): boolean => {
    return this.isMoreCommentsToLoad();
  };

  /**
   * Loads next portion of older comments. Requests number of comments that is supposed
   * to be displayed in one page.
   */
  @action
  public loadNextFeeds = async () => {
    if (this.allComments) {
      this.comments = this.allComments.slice(
        this.commentsLoadedCount,
        this.commentsLoadedCount + UserCommentsStore.COMMENTS_PER_PAGE,
      );
      this.commentsLoadedCount = this.commentsLoadedCount + UserCommentsStore.COMMENTS_PER_PAGE;
    }
  };

  /**
   * Loads previous portion of newer comments. Requests number of comments that is supposed
   * to be displayed in one page.
   */
  @action
  public loadPreviousFeeds = async () => {
    if (this.allComments) {
      this.commentsLoadedCount = this.commentsLoadedCount - UserCommentsStore.COMMENTS_PER_PAGE;
      this.comments = this.allComments.slice(
        this.commentsLoadedCount - UserCommentsStore.COMMENTS_PER_PAGE,
        UserCommentsStore.COMMENTS_PER_PAGE,
      );
    }
  };

  /**
   * Formats date to short format.
   * @param dateTime Date to modify.
   * @return Date in short format.
   */
  public getModifiedDate = function (dateTime: string) {
    return DateTime.fromSQL(dateTime).toFormat("dd.MM.yyyy");
  };

  /**
   * Update comments information.
   * @param comments Comments that user can currently see.
   */
  @action
  private updateCommentsInfo = (comments: IComment[]) => {
    this.allComments = comments;
    this.commentsLoadedCount = UserCommentsStore.COMMENTS_PER_PAGE;
    this.comments = this.allComments.slice(0, this.commentsLoadedCount);
  };

  /**
   * Fetches all comments
   * @return Lis of comment fetch results.
   */
  private async fetchAllComments(): Promise<ICommentFetchResult | undefined> {
    let comments: IComment[] = [];
    const promises: Array<Promise<ICommentFetchResult[] | void>> = [];
    const dontUnwrap = true;
    const queryCount = 100;

    let searchOptions = {
      offset: 0,
      count: queryCount,
      sortBy: "createTime DESC",
    };

    try {
      if (!!this.packageItem) {
        const result: ICommentFetchResult = await TCCPackageService.getComments(
          this.packageItem.id,
          searchOptions,
          dontUnwrap,
        );

        if (result.total > queryCount) {
          comments = comments.concat(result.entries);
          for (let offset = queryCount; offset < result.total; offset = offset + queryCount) {
            searchOptions = {
              offset: offset,
              count: queryCount,
              sortBy: "createTime DESC",
            };
            promises.push(
              TCCPackageService.getComments(this.packageItem.id, searchOptions, dontUnwrap).then(function (res) {
                comments = comments.concat(res.entries);
              }),
            );
          }
          await Promise.all(promises);
          return { total: result.total, entries: comments };
        } else {
          return result;
        }
      } else {
        return undefined;
      }
    } catch {
      console.log("Error while fetching user comments");
      return undefined;
    }
  }

  private isMoreCommentsToLoad(): boolean {
    return this.allComments && this.allComments.length > this.commentsLoadedCount && this.commentsLoadedCount > 0;
  }

  private filterMainLevelComments(fetchResult: ICommentFetchResult): ICommentFetchResult {
    const entries = _.reject(fetchResult.entries, function (entry) {
      return entry.parentCommentId != null || entry.isDeleted;
    });
    fetchResult.entries = entries;
    return fetchResult;
  }

  private setCommentReplies(fetchResult: ICommentFetchResult) {
    _.each(fetchResult.entries, function (entry) {
      entry.replies = _.filter(fetchResult.entries, function (entry2) {
        return entry2.parentCommentId === entry.id && !entry2.isDeleted;
      });
    });
  }

  @action
  private async init() {
    this.loading = true;
    let commentsRes: ICommentFetchResult | undefined = await this.fetchAllComments();

    if (!!commentsRes) {
      this.setCommentReplies(commentsRes);
      commentsRes = this.filterMainLevelComments(commentsRes);
      this.updateCommentsInfo(commentsRes.entries);
    }
    runInAction(() => {
      this.loading = false;
    });
  }

  @action
  private async resetCommentsListAfterEdit() {
    this.loading = true;
    let commentsRes: ICommentFetchResult | undefined = await this.fetchAllComments();

    if (!!commentsRes) {
      this.setCommentReplies(commentsRes);
      commentsRes = this.filterMainLevelComments(commentsRes);
      runInAction(() => {
        this.allComments = commentsRes!.entries;
        this.comments = this.allComments.slice(
          this.commentsLoadedCount - UserCommentsStore.COMMENTS_PER_PAGE,
          this.commentsLoadedCount,
        );
      });
    }

    runInAction(() => {
      this.loading = false;
    });
  }

  @action
  private async resetCommentsListAfterDelete() {
    this.loading = true;
    let commentsRes: ICommentFetchResult | undefined = await this.fetchAllComments();

    if (commentsRes) {
      this.setCommentReplies(commentsRes);
      commentsRes = this.filterMainLevelComments(commentsRes);
      runInAction(() => {
        this.allComments = commentsRes!.entries;
        if (this.allComments.length < this.commentsLoadedCount) {
          this.commentsLoadedCount = this.allComments.length;
        }

        const visibleCommentsCount =
          this.commentsLoadedCount % UserCommentsStore.COMMENTS_PER_PAGE || UserCommentsStore.COMMENTS_PER_PAGE;

        const startRow = Math.max(0, this.commentsLoadedCount - visibleCommentsCount);
        this.comments = this.allComments.slice(startRow, startRow + UserCommentsStore.COMMENTS_PER_PAGE);
      });
    }

    runInAction(() => {
      this.loading = false;
    });
  }
}

/**
 * Context object for UserCommentsStore instances.
 */
export const UserCommentsStoreContext = createStoreContext<UserCommentsStore>(UserCommentsStore);
