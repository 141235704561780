import * as sanitizeHtml from "sanitize-html";
import _ from "underscore";
import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import { DateTime } from "luxon";

import { IEntity, ICollection } from "../models/dataModel";
import { SanitizationModeEnum } from "../models/enums";

/**
 * Parses the given date (string, date or DateTime) and turns it into a luxon DateTime object
 * @param date date to be parsed
 * @returns DateTime object
 */
export function parseDate(date: string | Date | DateTime | undefined | null): DateTime | null {
  if (date) {
    if (typeof date === "string") {
      return DateTime.fromISO(date);
    } else if (DateTime.isDateTime(date)) {
      return date;
    } else if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    } else {
      return null;
    }
  } else {
    return null;
  }
}

/**
 * Converts a date object to ISO format without letters for backend use
 * @param date - date object to be converted
 * @returns string format yyyy-MM-dd HH:mm:ss.SSS
 */
export function dateToISOString(date: Date): string {
  return date.toISOString().replace("T", " ").replace("Z", "");
}

/**
 * Scrolls to a element with the given element id.
 * @param id id of the html element
 */
export function scrollToId(id: string) {
  scrollToElement(document.getElementById(id));
}

/**
 * Scrolls to a element with the given element id.
 * @param element HTMLElement or blank object
 * @param headerOffset top offset
 */
export function scrollToElement(element: HTMLElement | null | undefined, headerOffset = 40) {
  if (element) {
    const elementPosition = element.offsetTop;
    document.documentElement.scrollTop = elementPosition - headerOffset;
  }
}

/**
 * Scrolls to the top of the page.
 */
export function scrollToTop() {
  const element = document.getElementById("masthead");
  if (element) {
    const elementPosition = element.offsetTop;
    document.documentElement.scrollTop = elementPosition;
  }
}

/**
 * Checks if two arrays have the same content.
 *
 * @param arr1 - The first array to compare.
 * @param arr2 - The second array to compare.
 * @returns `true` if the arrays have the same content, `false` otherwise.
 */
export function isSameArrayContent(arr1: string[] | undefined, arr2: string[] | undefined) {
  if (!arr1 || !arr2) return false;
  return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
}

/**
 * Sanitizes text. It supports several levels of sanitization.
 * In SanitizationModeEnum.STRICT mode it removes all the tags and all the unsafe code.
 * In SanitizationModeEnum.JUST_LINKS mode it allows only <a> tag.
 * In SanitizationModeEnum.MODERATE it allows only <B>, <I>, <U>.
 * In SanitizationModeEnum.WEAK it allows basic HTML tags, including IFRAME.
 * In SanitizationModeEnum.NONE it does nothing.
 * @param text input text
 * @param mode sanitization mode
 * @returns sanitized text
 */
export function sanitize(text = "", mode: SanitizationModeEnum = SanitizationModeEnum.STRICT): string {
  if (mode === SanitizationModeEnum.NONE) {
    return text;
  } else if (mode == SanitizationModeEnum.WEAK) {
    return sanitizeHtml(text, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([
        "h1",
        "h2",
        "img",
        "iframe",
        "pre",
        "s",
        "sub",
        "sup",
        "span",
        "u",
      ]),
      allowedAttributes: {
        a: ["href", "rel", "target"],
        iframe: ["allowfullscreen", "title", "width", "height", "frameborder", "alt", "src", "style"],
        img: ["src", "style", "class"],
        li: ["style"],
        ol: ["style"],
        p: ["class", "style"],
        span: ["style"],
        ul: ["style"],
      },
    });
  } else if (mode === SanitizationModeEnum.JUST_LINKS) {
    return sanitizeHtml(text, {
      allowedTags: ["a"],
      allowedAttributes: { a: ["href", "rel", "target"] },
    });
  } else if (mode === SanitizationModeEnum.MODERATE) {
    return sanitizeHtml(text, {
      allowedTags: ["b", "i", "u", "em", "strong"],
      allowedAttributes: {},
    });
  } else {
    return sanitizeHtml(text, { allowedTags: [], allowedAttributes: {} });
  }
}

/**
 * Sets css class for element when element is at top of window when scrolling.
 * Used for setting sticky element behaviour.
 * @param elementId id of the html element
 * @param className css class to be bound to element
 */
export function setClassWhenAtTop(elementId, className) {
  let offSet;
  function handleScroll() {
    const element = document.getElementById(elementId);
    if (element) {
      if (!offSet) {
        offSet = element.offsetTop;
      }
      if (document.documentElement.scrollTop >= offSet) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }
  window.addEventListener("scroll", handleScroll);
}

/**
 * Checks if entity matches search terms. It's a function copied from the old application.
 * @param entity Entity
 * @param searchTerm query string
 */
export function isEntityMatchingSearchTerm(entity: IEntity, searchTerm: string) {
  function escapeRegexp(input) {
    return input.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
  }

  if (!searchTerm) {
    return true;
  } else {
    const regexp = new RegExp(escapeRegexp(searchTerm), "i");
    return (
      entity.title!.match(regexp) ||
      (!!entity.description ? entity.description.match(regexp) : false) ||
      _.any(entity.attributes!.useCategories!, (val: string) => !!String(val).match(regexp)) ||
      (!!entity.tags && _.any(entity.tags, (val: string) => !!String(val).match(regexp))) ||
      _.any(entity.attributes!.itemTypeCategories!, (val: string) => !!String(val).match(regexp))
    );
  }
}

export function isValidUserId(userId: string): boolean {
  const createRegExp = (str) => new RegExp(str.raw[0].replace(/\s/gm, ""), "");

  const uidValidator = createRegExp`
    ^([a-zA-Z0-9_-]){8}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-])
    {4}-([a-zA-Z0-9_-]){12}$`;

  return uidValidator.test(userId);
}

/**
 * Checks if a given URL is a valid download URL.
 *
 * @param url - The URL to be checked.
 * @returns A boolean indicating whether the URL is a valid download URL.
 */
export function isValidDownloadURL(url: string): boolean {
  let givenURL;
  try {
    givenURL = new URL(url);
  } catch {
    return false;
  }
  return givenURL.protocol === "http:" || givenURL.protocol === "https:";
}

export function checkTagsValidity(tagsEntries: string[]): boolean {
  const tagRegexp = /^[^!"#€%&\/()=?\]`^*;:-@£$\[≈±´~–™.¡”¥¢‰¶\\{}≠¿`^’’·„§½¤¨|+-]+$/;

  function hasValidTagSpacing(tagItem: string): boolean {
    return tagItem.trim().indexOf(" ") < 0;
  }

  function isValidTag(tagEntry: string): boolean {
    return hasValidTagSpacing(tagEntry) && tagRegexp.test(tagEntry);
  }

  let validTags = true;
  _.each(tagsEntries, function (tagEntry) {
    if (!isValidTag(tagEntry)) {
      validTags = false;
      return;
    }
  });
  return validTags;
}

/**
 * Returns the text content of given html string.
 * @param text string to strip from html
 */
export function stripHtml(text: string) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = text;
  return tmp.textContent || tmp.innerText;
}

/**
 * Removes styles from given string
 * @param text text to strip from styling
 */
export function removeStylesFromText(text: string) {
  const startInd = text.indexOf("<span style");
  if (startInd > -1) {
    const endInd = text.indexOf(">", startInd);
    text = text.replace(text.substring(startInd, endInd + 1), "");
    text = text.replace("</span>", "");
  }
  return text;
}

/**
 * Resolves if given collection/package has been liked by user
 * @param content the collection/package to be checked
 * @returns true if given content is liked by user
 */
export function isLiked(content: ICollection | IEntity): boolean {
  return content.currentUserRating === 5;
}

/**
 * Resolves if license is required for downloading package items
 * @param packageItem the package to be checked
 * @param license name of the license to be checked
 * @returns true if license is required
 */
export function isLicenseRequired(packageItem: IEntity, license: string): boolean {
  return (
    !packageItem.isLocal &&
    !!packageItem.attributes &&
    !!packageItem.attributes.licensesACL &&
    _.contains(packageItem.attributes.licensesACL, license)
  );
}

/**
 * Resolves if performance cookies are enabled
 * @returns true if cookies are enabled
 */
export function performanceCookiesAreEnabled(): boolean {
  const cookie = Cookies.get("cookies.settings");
  if (cookie) {
    try {
      const cookieValue = JSON.parse(Base64.decode(cookie));
      return cookieValue["enablePerformanceCookies"];
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

/**
 * Resolves text from array based on given index
 * @param indexParam index to be used to search string from array
 * @param plurals array of strings
 * @returns string
 */
export function pluralize(indexParam: number, plurals: string[]) {
  const index: number = indexParam || 0;
  return plurals[index] || plurals[plurals.length - 1] || "Missing: plural form";
}

/**
 * Escapes given sring
 * @param srcString string to be escaped
 * @returns escaped string
 */
export function escapeHtml(srcString: string): string {
  const entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;",
  };
  return String(srcString).replace(/[&<>"'\/]/g, function (s) {
    return entityMap[s];
  });
}

/** Sorts array of objects by given field
 * @param arrayToSort the array to be sorted
 * @param sortByField field used for sorting
 */
export function sortArray(arrayToSort: any[], sortByField: string): any[] {
  if (arrayToSort && sortByField) {
    if (sortByField.charAt(0) === "-") {
      return arrayToSort.slice().sort((a, b) => (a[sortByField.substring(1)] > b[sortByField.substring(1)] ? -1 : 1));
    } else {
      return arrayToSort.slice().sort((a, b) => (a[sortByField] < b[sortByField] ? -1 : 1));
    }
  } else return arrayToSort;
}

/**
 * Resolves translation key with API error
 * @params apiError that is used for resolving translation key
 * @returns resolved translation key or empty string
 */
export function translationKeyForAPIError(apiError: string): string {
  if (apiError === "api.errors.tcc.INVALID_TAG") {
    return "details.update_failed_invalid_tag";
  } else if (apiError === "api.errors.tcc.TOO_LONG_VALUE") {
    return "details.update_failed_details_max_length_exceeded";
  } else if (apiError === "FILE_SIZE_LIMIT_EXCEEDED") {
    return "upload.version.upload_failed_file_too_big";
  } else {
    return "";
  }
}

/**
 * Checks if the given details contain any unallowed content.
 * @param details - The details to check.
 * @returns True if the details contain unallowed content, false otherwise.
 */
export function includesUnallowedContent(details: string): boolean {
  const allowedEndpoints = [
    "https://alugha.com/embed/web-player",
    "https://player.vimeo.com/video",
    "https://www.youtube.com/embed",
    "https://www.youtube-nocookie.com/embed",
  ];

  const urls = details.match(/<iframe[^>]*src\s*=\s*["'](.*?)["'][^>]*>/g)?.map((iframe) => {
    const srcMatch = iframe.match(/src\s*=\s*["'](.*?)["']/);
    const url = !!srcMatch && new URL(srcMatch[1]);
    return !!url ? url.toString() : "";
  });

  return !!urls ? _.any(urls, (url: string) => !_.any(allowedEndpoints, (ep: string) => url.startsWith(ep))) : false;
}
