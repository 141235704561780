import { supportedLocales } from "../../constants/LocalizationConstants";
import { ILocale } from "../../models/dataModel";

function getLocale(isoCode: string): ILocale | null {
  if (isoCode && isoCode.length > 0) {
    return supportedLocales[isoCode];
  } else return null;
}

/**
 * Language converter helper class
 */
export const LanguageCodeConverter = {
  /**
   * Converts given isoCode to language
   * @params isoCode the code to be converted
   */
  convertIsoCodeToLang: (isoCode: string): string => {
    const locale: ILocale | null = getLocale(isoCode);
    return locale?.lang || "";
  },
  /**
   * Converts given isoCode to language key
   * @params isoCode the code to be converted
   */
  convertIsoCodeToLangKey: (isoCode: string): string => {
    const locale: ILocale | null = getLocale(isoCode);
    return locale?.langKey || "";
  },
};
