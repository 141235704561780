import { observer } from "mobx-react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";

import { ModusNumberInput } from "@trimble-oss/modus-react-components";

import classNames from "classnames";
import { ModusDualSlider } from "components/ModusDualSlider";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const DownloadsFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const [totalMinDownloads, setTotalMinDownloads] = useState(-1);
  const [totalMaxDownloads, setTotalMaxDownloads] = useState(-1);

  useEffect(() => {
    fetchData();
  }, store.getViewsAndDownloadsDependencies());

  useEffect(() => {
    return () => {
      store.setMinDownloads(undefined);
      store.setMaxDownloads(undefined);
    };
  }, []);

  async function fetchData() {
    await store.queryDownloads();

    if (store.getMinDownloads() !== undefined) {
      setTotalMinDownloads(store.getMinDownloads()!);
    }

    if (store.getMaxDownloads() !== undefined) {
      setTotalMaxDownloads(store.getMaxDownloads()!);
    }
  }

  function handleMinValueChange(event) {
    const nextMin = parseInt(event.target.value);
    if (isNaN(nextMin)) {
      return;
    } else if (nextMin <= (store.getMaxDownloads() || totalMaxDownloads)) {
      store.setMinDownloads(nextMin);
    } else {
      store.setMinDownloads(store.getMaxDownloads());
    }
  }

  function handleMaxValueChange(event) {
    const nextMax = parseInt(event.target.value);
    if (isNaN(nextMax)) {
      return;
    } else if (nextMax >= (store.getMinDownloads() || totalMinDownloads)) {
      store.setMaxDownloads(nextMax);
    } else {
      store.setMaxDownloads(store.getMinDownloads());
    }
  }

  return (
    <>
      <div className={classNames({ "search-filter": true, sectioned: true })} data-testid="downloadsFilter">
        <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.downloads")}:</h5>
        <div className="input-container">
          <div className="number-input">
            <ModusNumberInput
              label={rootStore.getTranslation("profile.admin.content_manager.helpers.from")}
              minValue={totalMinDownloads}
              maxValue={store.getMaxDownloads() || totalMaxDownloads}
              onInput={handleMinValueChange}
              value={
                store.getMinDownloads() !== undefined
                  ? store.getMinDownloads()?.toString()
                  : totalMinDownloads.toString()
              }
              disabled={store.isQuerying("downloads")}
              data-testid="downloads-min-field"
            ></ModusNumberInput>
          </div>
          <div className="number-input">
            <ModusNumberInput
              label={rootStore.getTranslation("profile.admin.content_manager.helpers.to")}
              minValue={store.getMinDownloads() || totalMinDownloads}
              maxValue={totalMaxDownloads}
              onInput={handleMaxValueChange}
              value={
                store.getMaxDownloads() !== undefined
                  ? store.getMaxDownloads()?.toString()
                  : totalMaxDownloads.toString()
              }
              disabled={store.isQuerying("downloads")}
              data-testid="downloads-max-field"
            ></ModusNumberInput>
          </div>
        </div>
      </div>
      <div className="search-filter">
        <ModusDualSlider
          lowerLimit={totalMinDownloads}
          upperLimit={totalMaxDownloads}
          minValue={store.getMinDownloads() !== undefined ? store.getMinDownloads() : totalMinDownloads}
          maxValue={store.getMaxDownloads() !== undefined ? store.getMaxDownloads() : totalMaxDownloads}
          onChangeMin={handleMinValueChange}
          onChangeMax={handleMaxValueChange}
          disabled={store.isQuerying("downloads")}
          testIdMin="downloads-min-slider"
          testIdMax="downloads-max-slider"
          testId="downloads-dual-slider"
        />
      </div>
    </>
  );
});
