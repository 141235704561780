import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ModusRadioGroup } from "@trimble-oss/modus-react-components";
import { ModusRadioGroupCustomEvent } from "@trimble-oss/modus-web-components/loader";
import { SearchTargetOptionEnum } from "../../../models/enums";

import { RootContext, RootStore } from "../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "./contentManagerStore";

export const SearchTarget = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleSearchTargetChange(event: ModusRadioGroupCustomEvent<string>) {
    store.setSearchTarget(event.detail as SearchTargetOptionEnum);
  }

  return (
    <div className="search-target">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.search_target")}:</h5>
      <ModusRadioGroup
        name="searchTarget"
        data-testid={"searchTargetSelected-" + store.getSearchTarget()}
        checkedId={store.getSearchTarget()}
        onButtonClick={handleSearchTargetChange}
        radioButtons={[
          {
            id: SearchTargetOptionEnum.COLLECTION,
            label: rootStore.getTranslation("helpers.search.show_collections"),
            checked: store.getSearchTarget() === SearchTargetOptionEnum.COLLECTION,
          },
          {
            id: SearchTargetOptionEnum.PACKAGE,
            label: rootStore.getTranslation("helpers.search.show_content_items"),
            checked: store.getSearchTarget() === SearchTargetOptionEnum.PACKAGE,
          },
        ]}
      />
    </div>
  );
});
