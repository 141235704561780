import { action, observable, runInAction, makeObservable } from "mobx";
import { createStoreContext, RootStore } from "../../stores/rootStore";
import _ from "underscore";
import { IOrganization, IWarehouseAPIResourcesWrapped } from "../../models/dataModel";
import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import { OrganizationFilterAttributesConverter } from "../../utils/converters/OrganizationFilterAttributesConverter";
import { TCCOrganizationService } from "../../js/services/TCCOrganizationService";

/**
 * A store that can be used to fetch partner organizations
 */
export class PartnersStore extends AbstractAsyncStore {
  /**
   * Partners
   */
  @observable private partners: IOrganization[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.fetchData();
  }

  /**
   * Get search link for partners
   */
  public getPartnersLink() {
    const lang = this.rootStore.getLangKeyBySelectedLocale();
    return `/search?languages=${lang}&sortBy=title&showOrganizationList=true`;
  }

  /**
   * Fetches partner organizations
   */
  @action
  public async fetchData() {
    this.dataFetched = false;
    this.partners = [];

    // Warehouse API doesn't allow us to return random objects so that we are trying to stretch it to its limits by:
    // - using random offset
    // - fetching only first 5 objects
    // - using pseudo-random order ( we can't use ORDER BY RAND() equivalent in Warehouse API)
    // There's not many organizations matching the criteria (~25).

    // get the count first, so that we can use proper offset

    try {
      const partnersCount: number = await this.countPartners();

      const c = 5;
      const offset = Math.floor(Math.random() * (partnersCount - c));
      const queryParams = {
        offset: offset > 0 ? offset : 0,
        count: c,
        fq: {},
      };

      const res = await this.searchPartners(queryParams);

      runInAction(() => {
        this.dataFetched = true;
        this.partners = res.entries;
      });
    } catch {
      console.log("Could not load partners.");
    }
  }

  /**
   * Gets partner organizations
   * @returns partner organizations
   */
  public getPartners(): IOrganization[] {
    return this.partners;
  }

  private async countPartners(): Promise<number> {
    const queryParams = { fq: {} };
    return (await this.searchPartners(queryParams)).total;
  }

  private async searchPartners(queryParams): Promise<IWarehouseAPIResourcesWrapped<IOrganization>> {
    const filterAttributes = OrganizationFilterAttributesConverter.toTCC({
      binaryNamesFilter: "thumbnail",
    });
    queryParams.fq = `${filterAttributes};(displayName=unlike='Trimble')`;
    queryParams.sortBy = _.sample(["createTime DESC", "createTime ASC"]);
    const org = await TCCOrganizationService.search(queryParams);
    return org as IWarehouseAPIResourcesWrapped<IOrganization>;
  }
}

/**
 * Context object for PartnersStore instances.
 */
export const PartnersContext = createStoreContext<PartnersStore>(PartnersStore);
