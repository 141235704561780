
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Hu = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["hu-HU"]) {
      window.locale["hu-HU"] = {};
    }

    _.extend(window.locale["hu-HU"], {
      myDetails: {
        my_roles: "Saját szerepkör",
        organization_roles: "Szerepkörök",
        roles: {
          member: "Tekla Warehouse tartalomszerkesztő (Alkalmazott)",
          employee: "Alkalmazott",
          contentEditor: "Tekla Warehouse tartalomszerkesztő (Külső)",
        },
      },
      immutability: {
        add_code_name_for_package: "Adjon meg egy kódnevet a tartalomhoz",
        code_name: "Kódnév",
        immutable: "Megváltoztathatatlan",
        archive: "Archívum",
        archive_collection: "A gyűjtemény és teljes tartalmának archiválása",
        archive_version: "A kiválasztott verzió archiválása",
        archive_package: "A csomag és teljes tartalmának archiválása",
        archive_dialog_text: "Az archivált tartalom nem lesz látható vagy kereshető a Tekla Warehouse-ben. Ha azonban a tartalmat korábban már használták a Tekla Structures felhőszolgáltatással, a felhőszolgáltatás továbbra is hozzáférhet a tartalomhoz.",
        archive_success: function (name: string) {
          return `${name} sikeresen archiválva`;
        },
        archive_failure: function (name: string) {
          return `${name} archiválása nem sikerült`;
        },
        archived_content_redirect_message: "Az Ön által elérni kívánt tartalom archiválásra került",
        edit_title_move_or_archive: "Cím szerkesztése, áthelyezése vagy archiválása",
        edit_title_or_archive: "Cím szerkesztése vagy archiválása",
        major_update: "Jelentős frissítés",
        major_update_description: "Nem visszamenőlegesen kompatibilis változások, például funkciók hozzáadása vagy eltávolítása, vagy egyéb jelentős változtatások.",
        minor_update: "Kisebb frissítés",
        minor_update_description: "Visszafelé kompatibilis változtatások, például új szolgáltatások vagy fejlesztések a meglévő funkciók megszakítása nélkül.",
        patch_update: "Javítócsomag frissítés",
        patch_update_description: "Visszafelé kompatibilis javítások, például biztonsági javítások, hibajavítások vagy teljesítményjavítások, új funkciók nélkül.",
        info: "Az Ön szervezete tartalmat biztosít a Tekla Structures felhőszolgáltatáshoz. A Tekla Structures felhőszolgáltatásban található tartalomhoz kódnévre és verziószámra van szükség a kompatibilitás és a konzisztencia biztosítása érdekében.",
        immutable_files_info: "A tartalom konzisztenciájának fenntartása érdekében a felhőszolgáltatásban nem lehet fájlokat eltávolítani vagy módosítani. Ha módosításokra van szükség, archiválja a jelenlegi verziót, és töltsön fel egy új verziót.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `További információért tekintse meg <a href="${readMoreUrl}" target="_blank">ezt az oldalt</a>.`;
        },
        resolve_conflict: {
          title: "Ütközés feloldása",
          description:
            "A következő elemeket találta a rendszer. Válassza a „Meglévő megtartása” lehetőséget, hogy megtartsa a meglévő elemeket, amelyeket a telepítésében megtalált a rendszer.",
          table_header_name: "Név",
          table_header_type: "Típus",
          table_header_action: "Művelet",
          action_keep_existing: "Meglévő megtartása",
          action_replace: "Csere",
          action_done: "Kész",
        },
        auth: {
          authentication_process_failed: "A hitelesítés nem sikerült",
          access_denied: "Hozzáférés megtagadva",
          handle_unauthorized: {
            unauthorized: "Jogosulatlan",
            please_log_in: "Kérjük, jelentkezzen be, vagy regisztráljon",
          },
        },
        translations: {
          edit_translations: "Fordítások szerkesztése",
          edit_translations_info: "Válasszon nyelvet a listából, szerkessze, majd mentse",
          edit_translations_default_language_info:
            "Az <b>Alapértelmezett</b> nyelv jelenik meg olyankor, amikor a felhasználó által kiválasztott nyelv nem érhető el",
          edit_content: "Tartalominformációk",
          edit_support: "Támogatás",
          updated_successfully: "A fordítások sikeresen frissültek",
          error_on_update: "Hiba történt a fordítások frissítése során",
          default: "Alapértelmezett",
          batch_translations: "Kötegelt fordítások",
          export_import_info:
            "<b>Exportálja</b> a kötegelt fordítások Ön által kiválasztott tartalomhoz tartozó sablonját, módosítsa fordítások hozzáadásával a sablont, majd a sablon <b>visszaimportálásával</b> mentsen több fordítást is egyszerre.",
          import_translations: "Importálás",
          export_translations: "Exportálás",
          translations_missing: " (üres)",
          select_content: "Válassza ki az exportálandó fordításokat:",
          import_finished: "Az importálás befejeződött",
          select_file: "Fájl kiválasztása",
          select_file_title: "Válassza ki az importálandó fájlt:",
          import_from_file: "Importálás",
          import_error_info: "A fájl nem a sablon formátumát használja:",
          export_selected: "Exportálás",
          invalid_file: "Érvénytelen fájltartalom-formátum",
          import_translations_finished: "A fordítások importálva",
          details_too_long: "A részletek tartalma hosszabb a maximálisan engedélyezettnél",
          import_failed: "A fordítások importálása nem sikerült",
          translate_to_other_languages: "Fordítás más nyelvekre",
          import_count: function (count) {
            return "Frissített fordítások a(z) " + count + " tartalomhoz";
          },
          invalid_language: function (contentId, language) {
            return "A tartalomban '" + contentId + "' érvénytelen nyelv van '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "A tartalomban '" + contentId + "' érvénytelen fordítási mező van '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "A tartalom '" +
              contentId +
              "' fordítási mezőjének '" +
              translationField +
              "' értéke meghaladja a maximális megengedett hosszt"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "3D-s modell",
          "3d_pic_added": "A 3D-s modell hozzáadása sikerült",
          "3d_pic_updated": "A 3D-s modell frissítése sikerült",
          "3d_pic_removed": "A 3D-s modell eltávolítása sikerült",
          "3d_pic_update_failed": "A 3D-s modell frissítése nem sikerült",
          "3d_pic_remove_failed": "A 3D-s modell eltávolítása nem sikerült",
          "3d_pic_missing":
            "A tartalomhoz nincs 3D-s kép. Az alábbi '3D-modell kiválasztása ' elemre kattintva választhat ki képet.",
          "3d_pic_uploading": "A modell betöltése folyamatban van. Kis türelmet...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Kattintson ide a tartalom szerkesztéséhez",
          updated_successfully: "Sikeresen frissítve",
          update_failed: "A frissítés nem sikerült",
          edit_search_criteria: "Keresési feltételek szerkesztése",
          edit_group_and_category: "Csoport és kategória szerkesztése",
          edit_content: "Tartalom szerkesztése",
          edit_thumbnail: "Miniatűr szerkesztése",
          edit_thumbnail_descriptive: "Ajánlott méret: 400 x 300 px",
          edit_description: "Leírás szerkesztése",
          edit_download_link: "Partner letöltési link hozzáadása vagy szerkesztése",
          versions_not_shown_to_users:
            "Mivel ez a tartalomelem partner letöltési linket tartalmaz, a Tekla Warehouse felhasználók nem láthatják azokat a verziókat, amelyeket korábban adott hozzá a tartalomelemhez. A Tekla Warehouse rendszergazdák és a szerkesztési joggal rendelkező felhasználók továbbra is láthatják a tartalomelem összes verzióját.",
          partner_download_link: "Adja meg a letöltési URL-t",
          partner_download_link_disclaimer:
            "Ha partner letöltési linket ad hozzá egy meglévő tartalomelemhez, a Tekla Warehouse felhasználók többé nem láthatják azokat a verziókat, amelyeket korábban adott hozzá a tartalomelemhez. Ez a művelet nem vonható vissza.",
          edit_title_or_delete: "Cím szerkesztése vagy törlése",
          edit_title_or_move_delete: "Cím szerkesztése, áthelyezése vagy törlése",
          edit_support: "Support információk szerkesztése",
          edit_release_notes: "Újdonságok szerkesztése",
          edit_content_wysiwyg: "Tartalom részleteinek szerkesztése",
          edit_content_embed_link: "További információ: Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Csak YouTube, Vimeo vagy Alugha videókat ágyazhat be.",
          details_unallowed_embed_info: "Csak a következő domainek tartalmát ágyazhatja be: YouTube, Vimeo vagy Alugha. A megengedett URL-formátumok a következők:",
          your_video_id: "AZ_ÖN_VIDEÓ_AZONOSÍTÓJA",
          edit_content_video_link_error: "Érvénytelen videóhivatkozás. Hozzáadhat videókat URL alapján a YouTube-ról és a Vimeóról",
          edit_content_vide_code_error: "Érvénytelen beágyazási formátum. Csak iframe-eket fogadunk el. Az engedélyezett videószolgáltatók a YouTube, a Vimeo és az Alugha.",
          details_max_length_exceeded: "A Részletek tartalma túl hosszú",
          editor_mode_label: "Szerkesztő mód:",
          editor_mode_on: "Be",
          editor_mode_off: "Ki",
          do_not_notify: "Kisebb frissítés, feliratkozott felhasználók értesítésének mellőzése",
          invalid_tag: "Érvénytelen címke",
        },
        transfer_package: {
          header: "Tartalom áthelyezése",
          actions: {
            move: "Áthelyezés",
            cancel: "Mégse",
          },
          loading: "Gyűjtemények betöltése...",
          moving: "Tartalom áthelyezése",
          select_collection: "Gyűjtemény kiválasztása",
          content_moved: "Tartalom áthelyezve. A változtatások pár perc múlva jelennek meg.",
          operation_failed: "A művelet sikertelen",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "A tartalmat sikerült összekapcsolni a következővel: " + name;
          },
          selected_resources_linked: function (name) {
            return "A kiválasztott tartalmat sikerült összekapcsolni a következővel: " + name;
          },
          resources_linked_with_errors: "A tartalom összekapcsolva; egyes tartalmakkal kapcsolatban hiba merült fel",
          resources_linking_failed: "A tartalom összekapcsolása nem sikerült",
          resource_unlinked: "Az összekapcsolás eltávolítása sikerült. A változtatások pár perc múlva jelennek meg.",
          resources_unlinked:
            "Eltávolította az összekapcsolásokat a kiválasztott tartalomból. A változtatások pár perc múlva jelennek meg.",
          resources_unlinked_with_errors:
            "Az összekapcsolások eltávolítva; egyes tartalmakkal kapcsolatban hiba merült fel",
          resources_unlinking_failed: "Az összekapcsolást nem sikerült eltávolítani",
          linking: "Összekapcsolás létrehozása",
          linking_failed: "Az összekapcsolás nem sikerült",
          linking_failed_title: "A következő tartalom összekapcsolása nem sikerült:",
          unlinking_failed_title: "A következő tartalom összekapcsolásának megszüntetése nem sikerült:",
          linking_failed_already_contains:
            "A tartalom már kapcsolódik a kijelölt gyűjteményhez. Kis időt igénybe vehet, amíg a frissítések megjelennek.",
          linking_failed_already_contains_short: "A tartalom már kapcsolódik a kijelölt gyűjteményhez",
          unlinking_failed: "Az összekapcsolás eltávolítása nem sikerült",
          unlinking_failed_does_not_contain:
            "A tartalom összekapcsolása már el lett távolítva a gyűjteményből. Kis időt igénybe vehet, amíg a frissítések megjelennek.",
          unlinking_failed_does_not_contain_short: "A tartalom összekapcsolása már el lett távolítva a gyűjteményből",
        },
        notification_channel: {
          title_success: "sikeres",
          title_notice: "értesítés",
          title_info: "információ",
          title_error: "hiba",
        },
        maintenance: {
          download_requires_tekla_maintenance: "A letöltéshez Tekla Maintenance szükséges",
          can_access_maintenance: {
            yes: "Igen",
            no: "Nem",
          },
        },
        confirm: {
          title: "Megerősítés",
          yes: "Igen",
          no: "Nem",
          ok: "OK",
          close: "Bezárás",
          dont_show: "Ne jelenjen meg többé",
        },
        show_more: " Több részlet",
        show_less: " Kevesebb részlet",
        spinner_loading: "Betöltés",
        spinner_processing: "Feldolgozás",
        spinner_loading_long_time: "A tartalom betöltése néhány percet is igénybe vehet",
        local_service: {
          info: "A helyi vagy hálózati tartalom létrehozásához Tekla Warehouse Service beépülő modul szükséges. A Tekla Structures 20.1 SR1 vagy újabb verziójában közvetlenül a modellből tölthetők fel a tartalmak.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "A Tekla Warehouse Service beépülő modul</a> " +
            "szükséges a helyi vagy hálózati tartalom létrehozásához.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "A Tekla Warehouse Service beépülő modul</a> " +
            "nem érhető el. A szolgáltatás szükséges a helyi vagy hálózati gyűjtemények engedélyezéséhez.",
          download_to_unlock_all_features_html:
            "Töltse le a(z) " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "A Tekla Warehouse Service beépülő modul</a> " +
            " összetevőt az összes funkció eléréséhez.",
          service_out_of_date_html:
            "A Tekla Warehouse beépülő modul elavult. Frissítse a(z) " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "A Tekla Warehouse Service beépülő modul</a> " +
            " összetevőt az összes funkció eléréséhez.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Nyelv kiválasztása",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Más felhasználók meghívása",
          send_invitation_to_email: "Meghívó küldése e-mailben",
          add_e_mail_addresses: "E-mail-címek hozzáadása",
          and_assign_role: "és szerepkörök kiosztása",
          send_invitation: "Meghívó elküldése",
          invitation_already_sent: "Meghívó már elküldve!",
          invitation_sent: "Meghívó elküldve!",
          update_selected_invitations: "Választott meghívók frissítése",
          new_role: "Új szerepkör",
          invitations_list_updated: "Meghívólista frissítve!",
        },
        item_browser_title: "Tartalomelemek kiválasztása",
        prerequisites: {
          description_placeholder: "Itt adja meg a leírást",
          link_name_placeholder: "Itt adja meg a hivatkozás nevét",
          add_prerequisites_and_recommendations: "Előfeltételek és ajánlások (opcionális)",
          list_the_profiles_components_files:
            "Azon profilok, komponensek, fájlok vagy modellek listázása, amelyek szükségesek a tartalom megfelelő működéséhez.",
          link_to_existing_content: "Link a meglévő tartalomhoz",
          please_enter_prerequisite_url: "Kérjük, adjon meg egy előfeltétel URL-címet",
          please_enter_valid_prerequisite_url: "Kérjük, adjon meg egy érvényes előfeltétel URL-címet",
          missing_http: "Hiányzó http://",
          add_link: "Link hozzáadása",
        },
        treeview: {
          cannot_export_this_container: "Konténer exportálása sikertelen. Kérjük, egyedi elemeket válasszon ki.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "A fájlnak kisebbnek kell lennie, mint " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Érvénytelen fájltípus.  Az alábbi fájltípusok egyikét kell használni " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Időkeret:",
          timeframe_start: "Kezdés:",
          timeframe_end: "Befejezés:",
          eventtype: "Esemény típusa:",

          downloads_daily_by_version: "Letöltések verzió szerint",
          downloads_by_country: "Letöltések ország szerint",
          downloads_by_version: "Letöltések verzió szerint",
          downloads_by_version_organization: "Letöltések verzió és szervezet szerint",
          downloads_by_organization: "Letöltések szervezet szerint",
          downloads_daily_by_package: "Letöltések tartalomelem szerint",
          views_daily_by_package: "Megtekintések tartalomelem szerint",
          views_by_country: "Megtekintések országonként",
          views_by_organization: "Megtekintések szervezet szerint",

          downloads_by_package: "Letöltések tartalomelem szerint",
          views_by_package: "Megtekintések tartalomelem szerint",
          downloads_daily_by_collection: "Letöltések gyűjtemény szerint",
          downloads_by_collection: "Letöltések gyűjtemény szerint",
          views_daily_by_collection: "Megtekintések gyűjtemény szerint",
          views_by_collection: "Megtekintések gyűjtemény szerint",

          events_by_organization_func: function (version) {
            return 'Verzió: "' + version + '" események szervezet szerint'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Továbbiak",
          sum_all_versions: "Minden verzió",
          sum_all_packages: "Minden tartalomelem",
          sum_all_collections: "Minden gyűjtemény",
          sum_all_organizations: "Minden szervezet",
          recording_started_at: "Adatrögzítés kezdete: 2015. június",
          recording_view_events_started_at: "Adatrögzítés kezdete: 2017. március",
          version_title_func: function (title) {
            return 'Verzió: "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " letöltés", count + " letöltés", count + " letöltés"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "A szám az összes befejezett letöltést tartalmazza.",
          counted_events_info_package: "A szám az összes befejezett letöltést tartalmazza.",
          export: {
            caption: "Analitika exportálása",
            select_options: "Válasszon a beállítások közül",
            select_timeframe_description: "Válasszon a lentiek közül időkeretet az analitika exportálásához:",
            select_eventtype_description:
              "Válassza ki az eseménytípust, amelyből analitikai adatokat szeretne exportálni:",
          },
        },
        access_rights: {
          editors: "Szerkesztők",
          download_access: "Megtekintési és letöltési hozzáférés",
          write_user_id_placeholder: "Felhasználói azonosító írása",
          write_organization_id: "Adja meg az érvényes szervezetazonosítókat. Minden azonosítót külön sorban írjon be.",
          write_id_placeholder:
            "Adja meg a felhasználói azonosítókat, felhasználói e-mail-címeket vagy szervezeti azonosítókat. Minden azonosítót vagy e-mail-címet külön sorban írjon be.",
          id_help_html_short:
            "Biztonsági okokból a rendszerünkben szereplő felhasználók nem kereshetők.<br/><br/>" +
            "A felhasználói azonosítót közvetlenül attól kérje el, akinek hozzáférést szeretne biztosítani. <br/>" +
            'A felhasználói azonosító a <a href="#/my/">Saját adatok </a> részen található.',
          id_help_html:
            "A rendszerünkben szereplő felhasználókra biztonsági okokból nem lehet rákeresni.<br/><br/>" +
            "A felhasználói azonosítót közvetlenül attól kérje el, akinek hozzáférést szeretne biztosítani. Ha egy adott szervezet valamennyi felhasználójának szeretne hozzáférést biztosítani, használja a szervezeti azonosítót.<br/><br/>" +
            'A felhasználói és a szervezeti azonosító a <a href="#/my/">Saját adatok</a> területen található.',
          max_input_limit: function (max_rows) {
            return (
              "Minden azonosítót vagy e-mail-címet külön sorban írjon be. Egyszerre " + max_rows + " sort vihet be."
            );
          },
          input_too_long: "A beírt szöveg túl hosszú.",
          viewer_already_added: function (input) {
            return `${input} már hozzá lett adva nézegetőként`;
          },
          wrong_format: function (input) {
            return `${input} érvénytelen azonosító vagy e-mail-cím`;
          },
          candidates_for_keyword: function (keyword) {
            return "E-mail-címmel vagy azonosítóval rendelkező felhasználók " + keyword;
          },
        },
      },
      images: {
        add_new: "Új hozzáadása",
        add: "Hozzáadás",
        delete: "Törlés",
        set_as_default: "Alapértelmezett miniatűr",
        select_file: "Fájl kiválasztása",
        choose_option: "Beállítás kiválasztása",
        info_3D_exists: "Egy 3D-s modellt már hozzáadott; a tartalomhoz csak egy modell adható hozzá.",
        action_options: {
          image: "Kép",
          "3DModel": "3D-s modell",
          video: "Videó",
        },
        video: {
          url: "Videó URL-címe",
          invalid_url: "Érvénytelen URL-cím",
        },
      },
      units: {
        bytes: "bájt",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Letöltés",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " kedvelés", number + " kedvelés", number + " kedvelés"]);
      },
      main_info: {
        header_title: "A Tekla Warehouse webhelye új külsőt kapott!",
        header_text: "Hogy tetszik a Tekla Warehouse új, tökéletesített webhelye?",
        header_text2: "A legfontosabb módosítások:",
        bullet_text_1: "A keresés egyértelmű a céloldalon.",
        bullet_text_2:
          "A Keresés oldal összes keresési szűrője a bal oldalon van, és az alapértelmezett miniatűrnézet több keresési eredménnyel rendelkezik az adott nézetben.",
        bullet_text_3:
          "A Tartalom oldal Letöltés beállítása középen van, így a tartalmak könnyen használhatók. Mind a letöltési, mind a telepítési beállítás használható a Letöltés gombbal.",
        bullet_text_4:
          "Mind a Gyűjtemény, mind a Saját kosár oldalon 3 lépésre bontott útmutató könnyíti meg a tartalmak letöltését és telepítését.",
      },
      organizations: {
        error_loading_collection_contents: "Hiba történt a gyűjtemény tartalmainak betöltése során",
        error_loading_organization: "Hiba történt a szervezet betöltése során",
      },
      downloads_graph: {
        date: "Dátum",
        total_views: "Összes megtekintés",
        total_downloads: "Összes letöltés",
        views: "Megtekintések",
        downloads: "Letöltések",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service beépülő modul" +
          "</a>" +
          " szükséges.",
        processingFailed: "A feldolgozás sikertelen",
        forbidden: "Tiltott",
        resource_not_found: "Az erőforrás nem található",
        no_matches_found: "Nincs találat",
        no_matches_found_for: function (input) {
          return "Nem található egyezés az e-mail-címmel vagy azonosítóval " + input;
        },
        not_identified: "Nincs azonosítva",
        error_while_searching: "Hiba a keresés közben",
        error_user_not_found: "Nem található felhasználó vagy szervezet.",
        error_while_adding_member: "Hiba történt a hozzáférési jogosultsághoz tartozó tag felvétele során",
        error_while_removing_member: "Hiba történt a hozzáférési jogosultsághoz tartozó tag eltávolítása során",
      },
      offline_to_online: {
        fail_on_initialize: "Hiba történt a tömörített tartalom kezelése során; a konvertálás megszakadt",
        fail_on_validate: "A tartalom metaadatainak hitelesítése sikertelen; a konvertálás megszakadt",
        fail_on_save: "Hiba történt a tartalom mentése során; a rendszer visszaállítja a módosítások előtti állapotot",
        success: "Az offline tartalom online tartalommá konvertálása sikerült",
        validate_errors_title: "A tartalom konvertálása nem sikerült",
        processing: "Csomag mentése ",
        processed: "Feldolgozott elem",
        collection_thumbnail_missing_name:
          "A gyűjtemény miniatűrjének bináris értékéből hiányzik a következő: 'filename'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "A csomag ('" + packageTitle + "') miniatűrjének bináris értékéből hiányzik a következő: 'fájlnév'";
        },
        package_value_missing_title_func: function () {
          return "A csomagból hiányzik a következő: 'cím'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "A csomagból ('" + packageTitle + "') hiányzik a következő: '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "A csomag ('" +
            packageTitle +
            "') mezőjében ('" +
            field +
            "') a következő érvénytelen értékek vannak: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "A csomagban ('" + packageTitle + "') érvénytelen címke van: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "A csomag ('" + packageTitle + "') verziójából hiányzik a következő: 'cím'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return (
            "A csomag ('" +
            packageTitle +
            "') verziójából ('" +
            versionTitle +
            "') hiányzik a következő: '" +
            field +
            "'"
          );
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "A csomag ('" +
            packageTitle +
            "') verziójának ('" +
            versionTitle +
            "') mezőjében ('" +
            field +
            "') a következő érvénytelen értékek vannak: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return (
            "A csomag ('" +
            packageTitle +
            "') verziójának ('" +
            versionTitle +
            "') bináris értékéből hiányzik a következő: 'fájlnév'"
          );
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Fontos: A kolekcję lokalną megszűnnek a Tekla Warehouse-ban.",
            part1: "2025. június 2-tól a kolekcję lokalną már nem lesznek elérhetők a Tekla Warehouse-ban. A felhasználók többé nem tudnak kolekcję lokalną létrehozni, megtekinteni, keresni, vagy azok tartalmát használni.",
            part2: "A kolekcję lokalną-ról a kolekcje online-ra való átálláshoz kérjük, vegye fel a kapcsolatot a helyi támogatási szervezetével.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Fontos! Biztonsági fejlesztésként 2024. november ${date}. után a szervezet tartalmának kezeléséhez Tekla Warehouse Tartalomszerkesztő szerepkörrel kell rendelkeznie. <br />Kérjük, kérje meg szervezete rendszergazdáját, hogy ossza ki Önnek ezt a szerepet. További információért tekintse meg a <a href="${productBulletinUrl}" target="_blank">termékközleményt</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Biztonsági fejlesztésként 2024. november ${date}. után a szervezet tartalmának kezeléséhez Tekla Warehouse Tartalomszerkesztő szerepkörrel kell rendelkeznie. <br />Kérjük, kérje meg szervezete rendszergazdáját, hogy ossza ki Önnek ezt a szerepet. További információért tekintse meg a <a href="${productBulletinUrl}" target="_blank">termékközleményt</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Nem látja a szervezetére vonatkozó szerkesztési lehetőséget?  November ${date}. után a szervezet tartalmának kezeléséhez Tekla Warehouse tartalomszerkesztő szerepkörrel kell rendelkeznie. <br />Szerepkörét a Saját Warehouse > Saját adatok menüpont alatt ellenőrizheti. További információért tekintse meg a <a href="${productBulletinUrl}" target="_blank">termékközleményt</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Nem látja a szervezetének megfelelő tartalom létrehozásának lehetőségét? November ${date}. után a szervezet tartalmának kezeléséhez Tekla Warehouse tartalomszerkesztő szerepkörrel kell rendelkeznie. <br />Szerepkörét a Saját Warehouse > Saját adatok menüpont alatt ellenőrizheti. További információért tekintse meg a <a href="${productBulletinUrl}" target="_blank">termékközleményt</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `November ${date}. után a szervezet tartalmának kezeléséhez Tekla Warehouse tartalomszerkesztő szerepkörrel kell rendelkeznie. <br />Kérjük, kérje meg szervezetének rendszergazdáját, hogy ossza ki Önnek ezt a szerepet. A Tekla Online profiljában ellenőrizheti, hogy ki az Ön szervezetének rendszergazdája. További információért tekintse meg a <a href="${productBulletinUrl}" target="_blank">termékközleményt</a>.`
            },
          },
          service_plugin_update_info:
            "Problémái vannak a Tekla Structures csatlakozásával? Győződjön meg róla, hogy a legújabb <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service plugin-t használja</a>.",
        },
        maintenance_info:
          "A Tekla Warehouse-t 2017. 10. 27-én 22:00:00 és 23:00:00 UTC között karbantartjuk. Elnézést kérünk a kényelmetlenségekért.",
        release_notes_info: "A Tekla Warehouse új funkciói",
        release_notes_link:
          'A Tekla Warehouse frissült; tekintse át az <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">újdonságokat</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Tekla-fejlesztőre van szüksége a projektjeihez?",
        heading2Dev:
          'Tekintse meg az új könyvtárat, a Szakértői Tekla-fejlesztés könyvtárat.<br><br>Itt léphetnek kapcsolatba a tehetséges programozók és az érdeklődő Tekla-felhasználók.<br><br>A Tekla Partners Program valamennyi tagja esetében szívesen vesszük, ha feltölti az adatait a szakértői tárba.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Fejlesztő keresése &rsaquo;</a>',
        static_intro:
          "Ez az ingyenes Tekla Structures BIM-tárhely, ahol és ahonnan mind belső, mind globális tartalmak kereshetők, importálhatók, telepíthetők és megoszthatók. A Tekla Warehouse a jó minőségű modellek hatékony és könnyű elkészítését segíti.",
        new_intro:
          'A Tekla Corporation új neve Trimble. Folytatjuk a Tekla szoftveres megoldásainak a fejlesztését, és ugyanazokkal a munkatársakkal igyekszünk segíteni Önnek. További információ: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Igazolja a fiókját",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "A legújabb feltöltések &rsaquo;",
          popular: "Népszerű tartalom &rsaquo;",
          recommended: "Ajánlott &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Hírek &rsaquo;</a>',
          item_title: "Megjöttek a hírek!",
          item_description:
            'Hírekkel kapcsolatos szöveg, hírekkel kapcsolatos szöveg, hírekkel kapcsolatos szöveg, hírekkel kapcsolatos szöveg, hírekkel kapcsolatos szöveg, hírekkel kapcsolatos szöveg.<br><a href="https://developer.tekla.com" target="_blank">További információ &rsaquo;</a>',
        },
        statistics: {
          title: "A Warehouse a számok tükrében",
          partners: "Partnerek",
          partners_count: "352",
          collections: "Gyűjtemények",
          collections_count: "793",
          items: "Tartalomelemek",
          items_count: "2893",
        },
        partners: "A partnereink",
        action_links: {
          search_title: "Tartalmakat keres?",
          upload_title: "Tartalmat töltene fel?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Videók &rsaquo;</a>',
          link: 'További videókat a <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Youtube-csatornánkban</a> talál.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Keresse meg a visszaigazolásra szolgáló linket az e-mail-fiókjában, vagy <u><a href="' +
            settingsUrl +
            '">kérjen új linket</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Erősítse meg az e-mail-címét, és fogadja el a felhasználási feltételeket és az adatvédelmi irányelveket",
        acceptTerms: "Fogadja el a felhasználási feltételeket és az adatvédelmi irányelveket",
        acceptUpdatedTerms:
          "Fogadja el a tekla online services frissített felhasználási feltételeit és adatvédelmi irányelveit",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Visszatérés a <u><a href="' + settingsUrl + '">profiljához</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Tanulmányozza át, és fogadja el a felhasználási feltételeket és az adatvédelmi szabályzatot a <u><a href="' +
            settingsUrl +
            '">profiljában</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Gyűjtemények tallózása &rsaquo;",
          new: "Új",
          popular: "Népszerű",
          recommended: "Ajánlott",
          organizations: "Szervezetek",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Mappa kiválasztása",
        selectFolderInstructions: "Gyűjteményként helyi és hálózati mappák használhatók.",
        selectedFolder: "Kiválasztott mappa (hely másolás és beillesztése):",
        selectedFolderPlaceholder: "Pl. C:\\mappa, Z:\\hálózati mappa, \\\\gépnév\\mappa",
        collectionCreation: {
          name: "Név",
          createNewCollection: "Helyi gyűjtemény létrehozása",
          pleaseInputTitle: "Kérjük, adjon meg egy címet",
          invalidPath: "Érvénytelen elérési útvonal",
          access_denied: "A gyűjtemény hozzáadása nem sikerült: a hozzáférés megtagadva",
          unsafe_location: "A gyűjtemény hozzáadása nem sikerült: nem biztonságos hely",
          add_collection_failed: "A gyűjtemény hozzáadása nem sikerült",
          minLengthError: "A címnek legalább 3 karakterből kell állnia",
          maxLengthError: "A cím nem lehet 20 karakternél hosszabb",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Gyűjteményként helyi és hálózati mappák használhatók.",
        collectionCreation: {
          name: "Név",
          createNewCollection: "Online gyűjtemény létrehozása",
          pleaseInputTitle: "Kérjük, adjon meg egy címet",
          minLengthError: "A címnek legalább 3 karakterből kell állnia",
          maxLengthError: "A cím nem lehet 20 karakternél hosszabb",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Erőforrás elmentve",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Látja a lehetőséget?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Visszajelzés küldése</span>',
          help_us_improve_html:
            'Segítsen a Tekla Warehouse továbbfejlesztésében! <br/><a id="showSurvey">Kattintson ide</a>, vagy <a id="dontShow">ne jelenjen meg többé</a>',
          dialog: {
            do_you_see_potential: "Látja a lehetőséget?",
            help_us_improve_html: "Segítsen a Tekla Warehouse továbbfejlesztésében! A véleménye fontos számunkra.",
            continue_to_survey: "A felmérés elindítása",
            exit_to_warehouse: "Nem, köszönöm",
          },
        },
      },
      search: {
        bar: {
          search: "Keresés",
          filter: " ",
          more_filters: "További szűrők",
          hide_filters: "Szűrők elrejtése",
          clear: "Törlés",
          exclude: "Kizárás",
          include: "Befoglalás",
          clear_filters: "Szűrők visszaállítása",
          load_defaults: "Saját preferenciák felhasználása &rsaquo;",
          save_defaults: "Mentés saját preferenciaként &rsaquo;",
          defaults_saved_successfully: "Mentette az alapértelmezett szűrőket",
          edit_defaults: "Alapértelmezett értékek szerkesztése",
        },
        info: {
          searching_organizations: "Keresés a szervezetek között...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Nincs találat", amountTotal + " találat"]);
            } else {
              return (
                pluralize(amountTotal, ["Nincs találat", amountTotal + " találat"]) +
                ' a következőhöz: "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " találat";
            } else if (!searchTerm) {
              return "A szervezeti találatok megjelenítéséhez adjon meg keresési kifejezést";
            } else if (searchTerm.length < 2) {
              return "Adjon meg legalább két karaktert a szervezet kereséséhez";
            } else {
              return (
                pluralize(amountTotal, ["Nincs találat", amountTotal + " találat"]) +
                ' a következőhöz: "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " találat az online tartalmak között",
          local: " találat a helyi tartalmak között",
          organization: "találat a szervezetek között",
          plugin_missing_info:
            'A helyi tartalom csak akkor jelenik meg, ha a <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service beépülő modul" +
            "</a>" +
            " telepítve van.",
          version_filter_info: "A szoftververzió-szűrés nem érvényes a helyi tartalomban előforduló találatokra",
        },
        exclude_env_files: "Környezeti fájlok kihagyása",
        sidebar: {
          filters: {
            measurementUnits: "Mértékegységek",
            locations: "Projekthelyek",
            languages: "Nyelvek",
          },
          placeholders: {
            use_categories: "Kategória",
            measurement_units: "Mértékegységek",
            languages: "Nyelvek",
            locations: "Helyek",
          },
        },
      },
      placeholders: {
        name: "Név...",
        contentOwner: "Tartalom tulajdonosának neve...",
        useCategory: "Felhasználási mód kiválasztása",
        selectType: "Típus kiválasztása",
        filter: "Szűrő...",
        search: "Keresés...",
        choose: "Válasszon...",
        searchFilters: "Keresési szűrők...",
        selectContentItem: "Tartalomelem kiválasztása...",
        search_content: "Tartalom keresése...",
        includeAs: "Beemelés másként...",
        selectCollection: "Gyűjtemény kiválasztása",
        selectUserGroup: "Felhasználói csoport kiválasztása",
        online_collections_tooltip: "Kattintson ide az online tartalomban történő kereséshez",
        local_collections_tooltip:
          "Kattintson ide a helyi és a hálózati tartalmak, illetve a telepített Tekla Structures-példányban található tartalmak közötti kereséshez",
        organizations_tooltip: "Kattintson a szervezetek kereséséhez",
        "filter-options": {
          products: "Összes szoftver",
          locations: "Minden hely",
          languages: "Minden nyelv",
          measurementUnits: "Minden mértékegység",
          testedVersions: "Minden szoftververzió",
          useCategories: "Minden kategória",
          useCategoriesCombined: "Kategória",
          allGroups: "Minden csoport",
          itemTypeCategories: "Minden elemtípus",
        },
        "exclude-filter-options": {
          products: "Szoftverek kizárása",
          locations: "Helyek kizárása",
          languages: "Nyelvek kizárása",
          measurementUnits: "Mértékegységek kizárása",
          testedVersions: "Szoftververziók kizárása",
          useCategories: "Kategóriák kizárása",
          useCategoriesCombined: "Kategória kizárása",
          allGroups: "Csoportok kizárása",
          itemTypeCategories: "Elemtípusok kizárása",
        },
      },
      link: {
        link_selected: "A kiválasztott összekapcsolása",
        unlink_selected: "A kiválasztott leválasztása",
      },
      download_install: {
        choose_version: "Válassza ki a szoftververziót:",
        choose_content: "Az alábbi listában válassza ki a tartalomelemeket:",
        choose_action: "Válasszon műveletet a kijelölt elemekhez:",
      },
      download: {
        copy_to_clipboard: "Másolás a vágólapra",
        download_selected: "Letöltés",
        add_selected_items_to_basket: "A kiválasztott hozzáadása a Saját kosárhoz",
        add_item_to_basket: "A kiválasztott hozzáadása a Saját kosárhoz",
        add_all_items_to_basket: "Az összes hozzáadása a kosárhoz",
        add_all_items_to_basket_info: "Több, mint 100 elem van. A betöltés eltarthat egy ideig",
        add_all_items_to_basket_too_many_results: "1000-nél több elem nem adható hozzá",
        cart_loading: "Több mint 100 elem van. A betöltés eltarthat egy ideig",
        remove_item_from_basket: "Eltávolítás a kosárból",
        shopping_cart_no_content: "Nincs tartalom a kosárban",
        shopping_cart_no_content_for_version: "A kijelölt verzióhoz nincs tartalom a kosárban",
        shopping_cart_items_func: function (itemsCount) {
          return "Saját kosár (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Letöltési útmutatás",
          step_one: "A leöltési információkat ezzel a gombbal a vágólapra másolhatja",
          step_two_func: function (downloadAppUrl) {
            return (
              "Futtassa a Tekla Warehouse Downloader alkalmazást. Az alkalmazás " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">itt tölthető le.</a> '
            );
          },
          step_three: "A Tekla Warehouse Downloader végigvezeti a letöltési folyamat hátralevő részén.",
          show_data_title: "Nem működött?",
          show_data_to_be_copied: "Manuális másolás",
          hide_data_to_be_copied: "Letöltési adatok elrejtése",
        },
      },
      install: {
        install_title: " elem lesz telepítve a Tekla Warehouse beépülő modul használatával.",
        failed_install_information:
          "Ön csak letölthető formában elérhető tartalmat választott ki. Ez a tartalom nem lesz telepítve. A tartalmat töltse le külön a Tekla Warehouse rendszerből.",
        verify_service_plugin_is_running:
          "Telepítés: ellenőrizze, hogy a Tekla Warehouse Service beépülő modul megfelelően működik-e",
        verify_ts_connection: "Telepítés: ellenőrizze, hogy a Tekla Structures fut-e",
        select_software_version: "Az összekapcsoláshoz, letöltéshez vagy telepítéshez szűrjön szoftververzió szerint",
        download_requires_maintenance:
          "Az alábbi elemek némelyikének eléréséhez a Tekla Maintenance használata szükséges",
        install_selected: "Telepítés",
        start_install: "Telepítés indítása",
        install_started: "A telepítés elindult",
        select_ts_version: "TS-verzió kiválasztása",
        install_retry: "Ha újra kell próbálkozni, a Tekla Warehouse alkalmazásból kezdje újra",
        failed_installs_info_func: function (failedCount) {
          return "Tartalom, amely nem lesz telepítve (" + failedCount + " verzió):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "A kiválasztott " + totalInstallsCount + " elem telepítése";
        },
      },
      helpers: {
        behalf: "Szerint",
        sortBy: "Rendezési szempont:",
        filter: "Eredmények szűrése",
        showAsThumbs: "Megjelenítés miniatűrként",
        showAsList: "Megjelenítés listaként",
        resultsPerPage: "Oldalanként ennyi elem:",
        filter_by_version: "Szűrés verzió szerint:",
        show_more_options: "További lehetőségek",
        show_less_options: "Kevesebb lehetőség",
        filter_by_type: "Szűrés típus szerint:",
        select_all: "Az összes kiválasztása",
        show_content_items: "Tartalomelemek megjelenítése",
        choose_action: "Művelet kiválasztása a kiválasztott tartalomhoz: ",
        show_collections: "Gyűjtemények megjelenítése",
        search: {
          show: "Megjelenítés:",
          show_content_items: "Tartalomelemek",
          show_collections: "Gyűjtemények",
          show_organizations: "Szervezetek",
          show_online_content: "Online tartalom",
          show_offline_content: "Offline tartalom",
          local_content_info:
            'A helyi tartalom csak akkor jelenik meg, ha a(z) <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " beépülő modul telepítve van.",
        },
        tekla_maintenance_required: "Az alábbi elemek némelyikének eléréséhez a Tekla Maintenance használata szükséges",
        show_content_alerts: "Tartalmi figyelmeztetések megjelenítése ",
        show_collection_alerts: "Gyűjteménnyel kapcsolatos figyelmeztetések megjelenítése ",
      },
      actions: {
        load_more: "Továbbiak betöltése",
        use_infitinite_scroll: "Végtelenített görgetés használata",
        back: "Vissza",
        show_download_dialog: "Letöltés",
        download: "Csak letöltés",
        download_on_partner_site: "Letöltés a partneroldalon",
        insert: "Telepítés",
        install: "Telepítés",
        downloadAndInstall: "Telepítés",
        createNew: "Új létrehozása",
        viewItems: "Megtekintés",
        viewCollection: "Gyűjtemény megtekintése",
        viewGroup: "Csoport megjelenítése",
        joinGroup: "Csatlakozás csoporthoz",
        loginToDownload: "Bejelentkezés a letöltéshez",
        show3DPic: "3D-s modell megjelenítése",
        remove: "Eltávolítás",
        browse: "Tallózás",
        browseFolder: "Tallózás",
        browseFileSystem: "Tallózás",
        add: "Hozzáadás",
        save: "Mentés",
        batch_edit: "Kötegelt szerkesztés",
        translations: "Fordítások",
        cancel: "Mégse",
        continue: "Folytatás",
        delete: "Törlés",
        addNetworkPath: "Hálózati hely hozzáadása",
        select: "Kiválasztás",
        create: "Létrehozás",
        update: "Frissítés",
        choose_thumbnail: "Miniatűr kiválasztása",
        choose_picture: "Kép kiválasztása",
        remove_picture: "Kép törlése",
        choose_3D_picture: "3D-s modell kiválasztása",
        remove_3D_picture: "3D-s model eltávolítása",
        like: "Tetszik",
        unlike: "Nem tetszik",
        close: "Bezárás",
        export: "Exportálás",
        add_or_remove: "Hozzáadás/eltávolítás",
        select_content_to_convert: "Offline tartalom kiválasztása",
        search: "Keresés",
        upload: "Feltöltés",
        edit: "Szerkesztés",
        view_3d: "Kattintson a modell megtekintéséhez...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "béta",
        search: "Keresés",
        profile: "Saját adatok",
        myWarehouse: "Saját Warehouse",
        myStuff: "Saját menü",
        logout: "Kijelentkezés",
        login: "Bejelentkezés",
        register: "Regisztrálás",
        upload: "Feltöltés",
        uploading: "Feltöltés",
        dashboard: "Irányítópult",
        learnMore: "További információ",
        about: "Névjegy",
        shoppingCart: "Saját kosár",
        addToCartInfo:
          "A Saját kosárba összegyűjtheti az elemeket, majd egyszerre töltheti le, illetve telepítheti őket. A letöltés teljesen ingyenes",
      },
      cubeNavigation: {
        headings: {
          get_started: "ELSŐ LÉPÉSEK",
          learn: "TANULÁS",
          get_help: "SEGÍTSÉG",
          extend_your_tekla: "BŐVÍTSE KI A TEKLA-JÁT",
          collaborate: "EGYÜTTMŰKÖDÉS",
          students_and_educators: "Students and educators",
          about: "BEMUTATKOZÁS",
        },
        "tekla-online": "Online szolgáltatások",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "A Tekla weboldala",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Saját profil",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla e-tanfolyam",
        tekla_services: "TEKLA-SZOLGÁLTATÁSOK KEZDŐLAPJA",
        register: "Regisztrálás",
        login: "Bejelentkezés",
        evaluate: "Kiértékelés",
        buy: "Vásárlás",
        download: "Letöltés",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Termékek",
        teklaServices: "Szolgáltatások",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Gyűjteményeim",
        myLikes: "Saját kedvelések",
        profile: "Saját adatok",
        preferences: "Saját preferenciák",
        myUserGroups: "Saját felhasználói csoportok",
        localCollections: "Helyi és hálózati gyűjtemények",
        tsCollections: "Tekla Structures-gyűjtemények",
        downloads: "Letöltések",
        selectContent: "Tartalomfájlok kiválasztása",
        addMetadata: "Metaadatok hozzáadása",
        onlineCollections: "Online gyűjtemények",
        userGroups: "Felhasználói csoportok",
        createNew: "Tartalom hozzáadása",
        notifications: "Értesítések",
        contentUsage: "Tartalomhasználat",
        browseCollections: "Gyűjtemények böngészése",
        admin: "Rendszergazda",
        sales: "Értékesítés",
        shoppingCart: "Saját kosár",
      },
      notifications: {
        title: "értesítés",
        invitedBy: "Meghívta:",
        invitationType: "Meghívó típusa",
        invitedTo: "Meghívás a következőbe:",
        createdAt: "Létrehozás dátuma:",
        action: "Művelet",
        acceptInvitation: "Elfogad",
      },
      batch_edit: {
        selectActionType: "Művelet típusának kiválasztása",
        edit_content: "Tartalom metaadatok szerkesztése",
        content_title: "Tartalom",
        metadataToChange: "Módosítandó metaadatok",
        selectContent: "A következő tartalomhoz",
        select_action: "Művelet kiválasztása",
        support: {
          support: "Support",
          licenses: "Licencek",
        },
        versions: {
          version: "Verzió",
          prerequisities: "Előfeltételek és követelmények",
        },
        content: {
          select_collection: "Gyűjtemény kiválasztása",
          show_collection: "Mutasd:",
          show_all_content: "Minden gyűjtemény",
          show_organization_content: "Szervezetem gyűjteményei",
          select_all: "Mindent kiválaszt",
          select_all_info: "(Ez eltarthat egy darabig)",
          actionOptions: {
            add: "Hozzáadás",
            remove: "Eltávolítás",
            replace: "Mire cseréli:",
            yes: "Igen",
            no: "nem",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Nincs kijelölt attribútum",
            invalid_attributes: "Érvénytelen attribútumértékek",
            invalid_actions: "Érvénytelen műveletek",
            content_not_selected: "Nincs kijelölt tartalom",
            entities_not_selected: "A csomagadatok megadva, de nincsenek csomagok kijelölve",
            versions_not_selected: "A verzióadatok megadva, de nincsenek verziók kijelölve",
          },
        },
        update: {
          info_processing: "Kérjük, ne zárja be ezt az ablakot, amíg az összes elem feldolgozásra nem kerül.",
          info_processed: "Az összes elem feldolgozásra került.",
          processing: "Elem feldolgozása",
          error_info: "Hiba lépett fel a következő elemek frissítése során:",
          package_failure_func: function (package_title, errorMsg) {
            return "Tartalom: " + package_title + ", hiba: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Tartalom: " + package_title + ", verzió: " + version_title + ", hiba: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " elem frissítésére kerül sor, folytatja?";
          },
        },
      },
      myUserGroups: {
        title: "Felhasználói csoportok",
        createdByPrefix: "Létrehozta:",
      },
      downloads: {
        title: "Letöltések",
      },
      myCollections: {
        new_collection: "Új gyűjtemény",
        my_collections: "Saját gyűjtemények",
        created_by_me: "Általam létrehozott",
        local_collections: "Helyi és hálózati gyűjtemények",
        online_collections: "Online gyűjtemények",
        created_by_my_organization: "A szervezetem által létrehozott",
        created_by_my_organizations: "A szervezeteim által létrehozva",
        shared_with_me: "Velem megosztott",
        shared_with_me_or_organization: "Velem vagy a szervezetemmel megosztott",
        notification: {
          error: "Hiba",
          error_loading_online_collections: "Hiba történt az online gyűjtemények betöltése során",
          error_loading_packages_liked_by_me: "Hiba történt az általam kedvelt tartalom betöltése során",
          error_loading_my_organization_s_collections: "Hiba történt a szervezetem gyűjteményeinek betöltése során",
          error_loading_my_shared_collections: "Hiba történt a megosztott gyűjteményeim betöltése során",
        },
        local: {
          helpText1: "A helyi gyűjteményekhez való hozzáféréshez telepíteni kell ",
          helpText2: "a Tekla Warehouse Service beépülő modult",
          helpText3: ".",
          title: "Helyi és hálózati gyűjtemények",
          notFound: "Még nem találhatók helyi gyűjtemények.",
          myCollectionsTitle: "Gyűjteményeim",
          actions: {
            createNew: "Helyi vagy hálózati gyűjtemény létrehozása",
          },
        },
      },
      organization: {
        news_feed: "Hírcsatorna",
        add_comment: "Hírcsatornaszöveg hozzáadása",
        edit_comment: "Hírelem szerkesztése",
        subTabs: {
          collections: "Gyűjtemények",
          analytics: "Analitika",
          contact_and_support: "Kapcsolat",
          news_feed: "Hírcsatorna",
        },
        edit: {
          edit_title: "Cím szerkesztése",
          edit_description: "Leírás szerkesztése",
          edit_contact: "Kapcsolatfelvételi adatok szerkesztése",
          edit_logo: "Logó szerkesztése",
          edit_url: "URL-cím szerkesztése",
          edit_email: "E-mail szerkesztése",
          edit_phonenumber: "Telefonszám szerkesztése",
          edit_warehouse_url: "Warehouse URL szerkesztése",
          edit_warehouse_url_subtitle: "Betűk (skandináv karakterek, számok és speciális karakterek nélkül)",
          edit_url_subtitle: "Teljes URL-cím beillesztése",
          edit_logo_descriptive: "Az optimális képméret hozzávetőleg 350 * 200 képpont",
          edit_facebook_url: "Facebook URL szerkesztése",
          edit_twitter_url: "Twitter URL szerkesztése",
          edit_linkedin_url: "LinkedIn URL szerkesztése",
          edit_youtube_url: "Youtube URL szerkesztése",
          edit_googleplus_url: "Google+ URL szerkesztése",
          logo_updated: "Frissített logó",
          logo_update_failed: "A logó frissítése nem sikerült",
          logo_remove_failed: "A logó eltávolítása nem sikerült",
          logo_removed: "A logó eltávolítása sikerült",
          updated_successfully: "Sikeresen frissítve",
          updated_failed: "A frissítés nem sikerült",
          add_comment: "Új hozzáadása",
          edit_comment: "Hírelem szerkesztése",
          comment_added: "Hírcsatornaelem sikeresen hozzáadva",
          comment_updated: "Hírcsatornaelem sikeresen frissítve",
          comment_deleted: "Hírcsatornaelem sikeresen törölve",
          comment_add_failed: "Nem sikerült a hírcsatorna hozzáadása",
          comment_update_failed: "Nem sikerült frissíteni a hírcsatornaelemet",
          comment_delete_failed: "Nem sikerült törölni a hírcsatornaelemet",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Online tartalom keresése a következő szerint: " + name + " &rsaquo;";
        },
        contact_information: "Lépjen kapcsolatba velünk",
        website: "Weboldal &rsaquo;",
        show_newer_news: "Újabb",
        show_older_news: "Régebbi",
      },
      online_collections: {
        new_collection: "Új gyűjtemény",
        online_collections: "Online gyűjtemények",
        created_by_organizations: "Szervezetek által létrehozva",
        created_by_users: "Felhasználók által létrehozva",
      },
      local_collections: {
        tab_title: "Tartalom",
        local_collections: "Helyi gyűjtemények",
      },
      versions: {
        add_tool: "Alkalmazás hozzáadása",
        table_header: {
          name: "Név",
          type: "Típus",
          platform: "Platform",
          modified_at: "Módosítva:",
          size: "Méret",
          virus_scan: "Vírusellenőrzés",
        },
        // placeholders
        select_file_type: "Fájltípus kiválasztása",
        select_platform: "Platform kiválasztása",
        select_unit: "Egység kiválasztása",
        select_quality: "Minőség kiválasztása",
        what_will_be_added: "Mit szeretne hozzáadni?",
        select_files_from_your_disk: "Fájlok kiválasztása a lemezről",
        add: "Hozzáadás",
        //content management
        add_application: "Alkalmazás hozzáadása",
        select_content_from_tekla_structures: "Tartalom kiválasztása a Tekla Structures rendszerből",
        or: "vagy",
        files: "Fájlok",
        //version metadata
        version_information: "Verzióinformáció",
        version_display_name: "Verzió megjelenített neve",
        version_number: "Verziószám",
        version_number_info: "Adja meg a verziószámot. Mentés után nem módosíthatja a verziószámot. ",
        existing_versions: "A tartalom meglévő verziószámai",
        version_number_validation: {
          missing: "Meg kell adnia egy verziószámot",
          invalid: "A verziószámnak követnie kell a szemantikai verziókészítés szabályait (MAJOR.MINOR.PATCH)",
          already_in_use: "Ez a verziószám már használatban van ehhez a tartalomhoz.",
        },
        select_update_type: "Verziószám generálásához először válassza ki a frissítés típusát",
        please_enter_a_version_name_or_number: "Kérjük, adjon meg egy verziónevet vagy -számot.",
        virus_scan: {
          soft_failed: "Sikertelen",
          hard_failed: "Nem ellenőrzött",
          infected: "Fertőzött",
        },
      },
      collections: {
        by: "Módosította:",
        edit: {
          edit_collection: "Gyűjtemény szerkesztése",
          edit_description: "Leírás szerkesztése",
        },
        notification: {
          collection_updated: "Gyűjtemény frissítve",
          error: "Hiba",
          error_loading_other_users_collections: "Hiba történt a többi felhasználó gyűjteményének betöltése során",
          error_loading_other_organizations_collections:
            "Hiba történt a többi szervezet gyűjteményének betöltése során",
          error_loading_collection_contents: "Hiba történt a gyűjtemény tartalmának betöltése során",
          members_list_updated: "Taglista frissítve!",
          invitations_removed: "Meghívások eltávolítva!",
          collection_removed: "Gyűjtemény eltávolítva",
          thumbnail_deleted: "Miniatűr törölve",
          wrong_file_type: "Hibás fájltípus.",
          invalid_file_type: "Érvénytelen fájltípus.",
          selected_image_is_too_big: "A kiválasztott kép túl nagy.",
          thumbnail_updated: "Miniatűr frissítve",
          default_thumbnail_updated: "Az alapértelmezett miniatűr frissítve",
          default_thumbnail_update_failed: "Az alapértelmezett miniatűr frissítése nem sikerült",
          thumbnail_added: "Miniatűr hozzáadva",
          thumbnail_update_failed: "A miniatűr frissítése nem sikerült",
          video_added: "Videó hozzáadva",
          video_adding_failed: "A videó hozzáadása nem sikerült",
          video_deleted: "Videó törölve",
          video_deleting_failed: "A videó törlése nem sikerült",
        },
        confirm: {
          delete_all: function (title) {
            return 'A gyűjtemény és a gyűjtemény teljes tartalmának törlése  "' + title + '"?';
          },
          remove_members: "Biztos?",
          remove_invitations: "Biztos?",
        },
        useNetworkDrive: "Hálózati meghajtó vagy mappa használata",
        createCollection: "Online gyűjtemény létrehozása",
        createCollectionInstructions: "Gyűjtemény létrehozása a tartalom csoportosításához.",

        actions: {
          delete_thumbnail: "Miniatűr törlése",
          edit_collection: "Gyűjtemény szerkesztése",
          delete_collection: "Gyűjtemény törlése",
          add_content: "Tartalom hozzáadása",
        },
        subTabs: {
          content: "Tartalom",
          members: "Tagok hozzáadása",
          analytics: "Analitika",
          manage_access: "Hozzáférés kezelése",
        },

        members: {
          add_user_groups: "Felhasználói csoportok hozzáadása",
          select_user_group: "Felhasználói csoport kiválasztása",
          with_role: "szerepkörrel",
          user_groups_added: "Felhasználói csoportok hozzáadva!",
          members_list_updated: "Taglista frissítve!",
          update_selected_members: "Kiválasztott tagok frissítése",
          table_header: {
            member_type: "Tag típusa",
            member_name: "Tag neve",
            email: "E-mail",
            role: "Szerepkör",
            joined_at: "Csatlakozás dátuma:",
            invited_at: "Meghívás dátuma:",
          },
          new_role: "Új szerepkör",
        },
        member_list: {
          users_who_can_access_this_collection: "Ehhez a gyűjteményhez hozzáférő felhasználók",
          add_user_group: "Felhasználói csoport hozzáadása",
          update_members: "Tagok frissítése",
          remove_members: "Tagok eltávolítása",
          pending_invitations: "Függő meghívók",
          user: "felhasználó",
          invite_member: "Tag meghívása",
          update_invitations: "Meghívók frissítése",
          remove_invitations: "Meghívók eltávolítása",
        },
        labels: {
          select_organization: "Szervezet kiválasztása",
          select_visibility: "Láthatóság kiválasztása",
          title: "Név",
          name: "Név",
          createAs: "Létrehozás mint: ",
          asMyself: "Saját magam",
          asUserGroup: "Felhasználói csoport",
          asOrganization: "Szervezet",
          selectOwner: "Szervezet kiválasztása a tartalomtulajdonos számára",
          visibility: "Láthatóság",
          visibilityOptions: {
            public: "Nyilvános",
            private: "Privát",
          },
          description: "Leírás",
        },
        new_collection: {
          please_input_a_username: "Kérjük, adjon meg egy felhasználónevet",
          title_is_required_to_be_at_least_3_characters: "A címnek legalább 3 karakterből kell állnia",
          title_cannot_be_longer_than_20_characters: "A cím nem lehet 20 karakternél hosszabb",
        },
        collection_list: {
          no_content: "Nincs gyűjtemény.",
          results_summary: function (number) {
            return "" + number + "  gyűjtemény " + pluralize(number, ["elem", "elem", "elem"]);
          },
        },
        entity_list: {
          no_content: "Nincs tartalom a gyűjteményben.",
          results_summary: function (number) {
            return "" + number + " tartalom " + pluralize(number, ["elem", "elem", "elem"]);
          },
          linked_content: "Összekapcsolt tartalom",
          unlink: "Tartalom leválasztása",
        },
      },
      alerts: {
        no_updates: "A közelmúltban nem frissített",
        notifications_marked_as_read: "Értesítés megjelölése olvasottként",
        cleared_alerts: "Minden értesítés törölve",
        alert_by_email:
          "Küldjenek e-mailt, ha az alább felsorolt tartalmak bármelyike frissül az új verzióra, illetve egy gyűjtemény új tartalommal frissül",
        action_types: {
          create: "elkészült",
          update: "szerkesztésre került",
          delete: "törölve lett",
          added_entity: "verzió hozzáadására került sor",
          removed_entity: "verzió eltávolítására került sor",
          added_collection: "tartalom hozzáadva",
          removed_collection: "tartalom eltávolítva",
        },
        property_names: {
          description: "Részletek",
          searchCriteria: "Keresési feltételekkel kapcsolatos információk",
          groupCategory: "Csoportokkal és kategóriákkal kapcsolatos információk",
          support: "Támogatási információk",
          licenses: "Licencekkel kapcsolatos információk",
          relatedContent: "Kapcsolódó tartalmak információi",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Frissítve " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Az értesítési beállítások frissítése sikerült",
          preferences_update_failed: "Hiba történt az értesítési beállítása frissítése során",
        },
      },
      browseDialog: {
        useNetworkDrive: "Hálózati meghajtó vagy mappa használata",
        ownerGroups: {
          title: "Tulajdonosi csoportok",
        },
      },
      itemTypeCategories: {
        all: "Összes csoport",
        application: "Alkalmazások",
        "3dProduct": "3D-s termékek",
        customComponent: "Felhasználói komponensek",
        tool: "Alkalmazások",
        profile: "Szelvények",
        material: "Anyagok",
        bolt: "Csavarok",
        rebar: "Vasbetétek",
        reportTemplate: "Jelentéssablonok",
        drawingSetup: "Rajz beállítási fájljai",
        modelSetup: "Modell beállítási fájljai",
        environmentFile: "Környezeti fájlok",
        shape: "Kontúrok",
        //'shapeCatalog': 'Alakzatkatalógus',
        //'foreignGeometryDefinition': 'Idegen geometriadefiníció'
      },
      platforms: {
        WIN_X86: "32 bites",
        WIN_X64: "64 bites",
        ANY: "Platformfüggetlen",
      },
      platformsDownload: {
        WIN_X86: "32 bites",
        WIN_X64: "64 bites",
        ANY: " ",
      },
      contentItemSource: {
        other: "Általános - modell mappába",
        run: "Futtat",
        bolt: "Csavargarnitúra",
        component: "Komponens",
        drawing: "Rajzbeállítás (BÉTA)",
        material: "Anyag",
        profile: "Szelvény",
        mesh: "Betonacél háló",
        rebar: "Vasbetét",
        shape: "Alak",
        geometry: "Geometria",
        modeltemplate: "Modellsablon",
        cloningtemplate: "Klónozási sablon",
        noaction: "Semmi",
        releasenotes: "Újdonságok",
      },
      contentItemHeader: {
        bolt: "Csavargyártmány",
        component: "Komponensek",
        drawing: "Rajzbeállítások (BÉTA)",
        material: "Anyagok",
        profile: "Szelvények",
        mesh: "Betonacél hálók",
        rebar: "Vasalás",
        shape: "Kontúrok",
      },
      useCategoryGroups: {
        workflow: "Munkafolyamat",
        structures: "Szerkezetek",
        drawings: "Rajzok",
        modeling: "Modellezés",
      },
      useCategories: {
        title: "Felhasználva a következőkben/höz",
        conceptualDesign: "Fogalmi szintű terv",
        designEngineeringAnalysis: "Terv/gépészet/elemzés",
        detailing: "Részlettervezés",
        fabrication: "Előállítás",
        erectionInstallationPour: "Felépítés/telepítés/öntés",
        contracting: "Szerződés",
        scheduling: "Ütemezés",
        takeOffEstimation: "Levezetés/becslés",
        concreteStructures: "Helyszíni vasbeton",
        precastConcrete: "Előre gyártott vasbeton",
        offshore: "Kiékelt bekötés",
        steelStructures: "Acélszerkezetek",
        timberStructures: "Faszerkezetek",
        reinforcement: "Vasbetét",
        drawingCreation: "Rajzlétrehozás",
        drawingManagement: "Rajz kezelése",
        drawingEditing: "Rajz szerkesztése",
        drawingAnnotation: "Rajz szövegmagyarázat",
        viewSimulateNavigate: "Megtekintés, szimuláció, navigáció",
        compareReviewManageData: "Adatok összehasonlítása, áttekintése, kezelése",
        softwareCollaboration: "Szoftveres együttműködés",
        productivity: "Hatékonyság",
        interoperability: "Együttműködés",
        systemSetup: "Rendszerbeállítás",
      },
      measurementUnits: {
        "0": "Metrikus",
        "1": "Angolszász",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "SketchUp",
        openBIM: "Nyitott BIM (IFC)",
        standalone: "Önálló",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Nem verzióspecifikus",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'SketchUp 7',
        //'SK1': 'SketchUp 8'
      },
      qualityTags: {
        experimental: "Kísérleti",
        candidate: "Jelölt",
        approved: "Jóváhagyott",
        standard: "Standard",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Angol",
        "zh-Hans": "Kínai (egyszerűsített)",
        "zh-Hant": "Kínai (hagyományos)",
        cs: "Cseh",
        da: "Dán",
        nl: "Holland",
        fi: "Finn",
        fr: "Francia",
        de: "Német",
        hu: "Magyar",
        it: "Olasz",
        ja: "Japán",
        ko: "Koreai",
        pl: "Lengyel",
        pt: "Portugál",
        "pt-br": "Portugál (brazíliai)",
        ru: "Orosz",
        es: "Spanyol",
        sv: "Svéd",
        no: "Norvég",
        other: "Egyéb",
      },
      isoLangs: {
        ab: {
          name: "Abház",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikansz",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Albán",
          nativeName: "Shqip",
        },
        am: {
          name: "Amhara",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Arab",
          nativeName: "العربية",
        },
        an: {
          name: "Aragóniai",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Örmény",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Asszámi",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avar",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avesztai",
          nativeName: "avesta",
        },
        ay: {
          name: "Ajmara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azerbajdzsáni",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Baskir",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Baszk",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Belorusz",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengáli",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihári",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bosnyák",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Breton",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Bolgár",
          nativeName: "български език",
        },
        my: {
          name: "Burmai",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Katalán, valenciai",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Csecsen",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa, chewa, nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Kínai, egyszerűsített és kínai",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Kínai (egyszerűsített)",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Kínai (hagyományos)",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Kínai (tajvani)",
          nativeName: "國語",
        },
        cv: {
          name: "Csuvas",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Cornwalli",
          nativeName: "Kernewek",
        },
        co: {
          name: "Korzikai",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Horvát",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Cseh",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Dán",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi, maldív",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Holland",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Angol",
          nativeName: "English",
        },
        eo: {
          name: "Eszperantó",
          nativeName: "Esperanto",
        },
        et: {
          name: "Észt",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Feröeri",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fidzsi-szigeteki",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finn",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Francia",
          nativeName: "Français",
        },
        ff: {
          name: "Fula",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Galiciai",
          nativeName: "Galego",
        },
        ka: {
          name: "Grúz",
          nativeName: "ქართული",
        },
        de: {
          name: "Német",
          nativeName: "Deutsch",
        },
        el: {
          name: "Görög, modern",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gudzsaráti",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haiti, haiti kreol",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausza",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Héber (modern)",
          nativeName: "עברית",
        },
        hz: {
          name: "Herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Magyar",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonéz",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingva",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Ír",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiat",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Izlandi",
          nativeName: "Íslenska",
        },
        it: {
          name: "Olasz",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japán",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Jávai",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, grönlandi",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Kasmíri",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Kazah",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Kinyarwanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Kirgiz",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kongói",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Koreai",
          nativeName: "한국어",
        },
        ku: {
          name: "Kurd",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kwanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latin",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxemburgi",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburgi",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Lao",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Litván",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-katanga",
          nativeName: "",
        },
        lv: {
          name: "Lett",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macedón",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Madagaszkári",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Maláj",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malayalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Máltai",
          nativeName: "Malti",
        },
        mi: {
          name: "Maori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Maráthi",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshall-szigeteki",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongol",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Norvég bokmål",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Észak-ndebele",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepáli",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Norvég nynorsk",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Norvég",
          nativeName: "Norsk",
        },
        ii: {
          name: "Noszu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Dél-ndebele",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Okcitán",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwe",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Óegyházi szláv, ószláv, óbolgár, régi szláv",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Oszét",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Páli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Perzsa",
          nativeName: "فارسی",
        },
        pl: {
          name: "Lengyel",
          nativeName: "polski",
        },
        ps: {
          name: "Pastu",
          nativeName: "پښتو",
        },
        pt: {
          name: "Portugál",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Portugál (brazíliai)",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Kecsua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Romans",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Román, moldáv",
          nativeName: "română",
        },
        ru: {
          name: "Orosz",
          nativeName: "русский",
        },
        sa: {
          name: "Szanszkrit",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Szárd",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Észak-sámi",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Szamoai",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Szerb",
          nativeName: "српски језик",
        },
        gd: {
          name: "Skót gael; gael",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Szingaléz",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Szlovák",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Szlovén",
          nativeName: "slovenščina",
        },
        so: {
          name: "Szomáli",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Dél-szoto",
          nativeName: "Sesotho",
        },
        es: {
          name: "Spanyol", //      'name':'Hagyományos/spanyol',
          nativeName: "español",
        },
        su: {
          name: "Szundanéz",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Szuahéli",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Szvati",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Svéd",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tadzsik",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Thai",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrinya",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Standard tibeti, tibeti, központi",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Türkmén",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalog",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Csvana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tonga (Tonga-szigeteki)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Török",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Conga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tatár",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Tahiti",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Ujgur",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ukrán",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Üzbég",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnami",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Vallon",
          nativeName: "Walon",
        },
        cy: {
          name: "Walesi",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Volof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Nyugat-fríz",
          nativeName: "Frysk",
        },
        xh: {
          name: "Khosza",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Jiddis",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Yoruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Chuang, csuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Másik",
          nativeName: "Másik",
        },
      },

      details: {
        navigation: {
          content_information: "Tartalominformációk",
          version_and_file_management: "Verzió- és fájlkezelés",
          details: "Részletek",
          versions: "Verziók",
          analytics: "Analitika",
          statistics: "Statisztika",
          manage_access: "Hozzáférés kezelése",
          related_content: "Kapcsolódó",
          properties: "Tulajdonságok",
          support: "Cég és támogatás",
          release_notes: "Újdonságok",
          comments: "Felhasználói megjegyzések",
        },
        related_content: {
          other_content_title: "Egyéb tartalom, amelyet kedvelhet",
          related_content_title: "Általa javasolt tartalom: ",
          related_content_url: "Tartalom URL-címe",
          add_related_content: "Kapcsolódó tartalom hozzáadása",
          add_related_content_info:
            "Kapcsolódó online tartalmat csak online tartalomhoz, helyi tartalmat pedig csak helyi tartalomhoz adhat",
          remove_related_content: "Eltávolítás",
          relatedContentUrlSubtitle: "Tartalomazonosító vagy teljes URL-cím a tartalomhoz",
          updated_successfully: "A kapcsolódó tartalom sikeresen frissítve",
          update_failed: "Hiba a kapcsolódó tartalom frissítésekor",
          update_failed_invalid_id: "A megadott tartalomazonosító érvénytelen",
        },
        actions: {
          move: "Áthelyezés",
          delete: "Törlés",
          add_comment: "Megjegyzés hozzáadása",
          add_reply: "Válasz hozzáadása",
          edit_comment: "Megjegyzés szerkesztése",
          reply_comment: "Válasz hozzáadása",
          view_replies: "Válaszok megtekintése",
          report_bad_comment: "Helytelen megjegyzés jelentése &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Cím szerkesztése vagy törlése",
            title_or_move_delete: "Cím szerkesztése, áthelyezése vagy törlése",
            thumbnail: "Miniatűr szerkesztése",
            description: "Leírás szerkesztése",
            groupandcategory: "Csoport és kategória szerkesztése",
            details: "Részletek szerkesztése",
            versions: "Verziók szerkesztése",
            support: "Support információk szerkesztése",
            release_notes: "Újdonságok szerkesztése",
            search_criteria: "Keresési feltételek szerkesztése",
            batchedit_and_translations:
              "A Fordítás funkcióval több nyelven is közzéteheti a tartalmakat. Tartalmának lefordított verziója a Warehouse kezelőfelületi nyelvének beállítása alapján jelenik meg. A Tömeges szerkesztéssel egyszerre több elemet is módosíthat. Először válassza ki a mezőt, majd a módosítandó elemeket",
          },
        },
        links: {
          link: "Csatolás",
          select_collection_placeholder: "Válassza ki a csatolni kívánt gyűjteményt",
          linking_collections_label: "A következőhöz kapcsolódó gyűjtemények:",
          private_content_note: "Megjegyzés: A privát tartalmat csak a hozzáféréssel rendelkező felhasználók látják.",
          share_on_social_media: "Megosztás a következőn:",
          report_bad_content: "Helytelen tartalom jelentése &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Illegális tartalom bejelentése &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "A figyelmeztetésekre való feliratkozással Ön elfogadja, hogy rendszerünk megosztja a Tekla Account megjelenített nevét és szervezetnevét (ha van) a tartalom tulajdonosával.",
          alert_me: "Értesítést kérek",
          unsubscribe: "Leiratkozás",
          subscribed_successfully: "Sikeresen feliratkozott",
          unsubscribed_successfully: "Sikeresen leiratkozott",
          subscribers_label: "Feliratkozott felhasználók",
        },
        reviews: {
          reviewers_label: "Ellenőrök",
        },
        comments: {
          replies: "Válaszok",
        },
        scan: {
          soft_failed_binaries_info: "A vírusellenőrzés sikertelen volt  ",
          hard_failed_binaries_info: "A vírusellenőrzés nem hajtható végre ",
          infected_binaries_info: "A vírusellenőrzés fertőzött fájlt talált ",
          what_now: "Mi a teendő?",
          popup: {
            soft_failed_binaries_title: "Hibás bináris elemek",
            hard_failed_binaries_title: "Hibás bináris elemek",
            infected_binaries_title: "Fertőzött bináris elemek",
            soft_failed_binaries_info:
              "A feltöltött fájlok vírusellenőrzése nem sikerült. A hiba ideiglenes. Távolítsa el a fájlokat, és töltse fel őket újra. A felhasználók számára csak a vírusellenőrzésen megfelelt fájlok jelennek meg.",
            hard_failed_binaries_info:
              "A vírusellenőrzés nem hajtható végre. Távolítsa el a fájlokat. A felhasználók számára csak a vírusellenőrzésen megfelelt fájlok jelennek meg.",
            infected_binaries_info:
              "A vírusellenőrzés fertőzött feltöltött fájlt talált. Távolítsa el a fájlokat. A felhasználók számára csak a vírusellenőrzésen megfelelt fájlok jelennek meg.",
            version: "Verzió: ",
            fileName: "fájlnév: ",
          },
        },
        status: {
          content_banned_info:
            "A tartalmat a rendszergazda letiltotta. Jelenleg a tartalom tulajdonosán kívül más felhasználók számára nem elérhető. <a href='https://support.tekla.com/contact-us' target='_blank'>Kapcsolatfelvétel az ügyfélszolgálattal</a> további információkért.",
          content_archived_info:
            "Ez a tartalom archiválva lett. A szerkesztési jogokkal nem rendelkező felhasználók a kezdőlapra lesznek átirányítva, ha közvetlen hivatkozáson keresztül próbálják meglátogatni a tartalmat.",
          archived: "Archivált",
        },
        modified_by: function (name) {
          return " által " + name;
        },
        created_by: function (name) {
          return "Készítette: " + name;
        },
        deleteEntity: function (title) {
          return "Törlés " + title + "?";
        },
        userComments: "Felhasználói megjegyzések",
        comment_added: "A megjegyzés hozzáadva",
        comment_updated: "A megjegyzés frissítése sikerült",
        comment_deleted: "Megjegyzés sikeresen törölve",
        comment_add_failed: "A megjegyzés hozzáadása nem sikerült",
        comment_update_failed: "A megjegyzés frissítése sikertelen",
        comment_delete_failed: "A megjegyzés törlése sikertelen",
        comment_delete_confirmation: "Törli a megjegyzéselemet?",
        name: "Név",
        details: "Részletek",
        contentOwner: "Készítő",
        contentOwnerGroup: "Tartalomtulajdonos-csoport",
        contentGroup: "Tartalomcsoport",
        thumbnail: "Miniatűrök",
        tooltip: "Elemleírás",
        description: "Leírás",
        additional_information: "További információ",
        images: "Termékképek",
        installationInfo: "Telepítési információk",
        productCode: "Termékkód",
        copyrightInfo: "Szerzői jog",
        productExpirationDate: "Termék lejárati ideje",
        helpUrl: "Webhely &rsaquo;",
        supportUrl: "Támogatás &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "Újdonságok",
        supportForumUrl: "Vitafórum &rsaquo;",
        itemTypeCategories: "Csoport",
        useCategories: "Kategória",
        category: "Kategória:",
        group: "Csoport:",
        compatibleSoftwareProducts: "Kompatibilis szoftverek",
        compatibleOperatingSystems: "Kompatibilis operációs rendszerek",
        locationRestrictions: "Termék elérhetősége:",
        supportedLanguages: "Nyelvek:",
        measurementUnits: "Mértékegységek:",
        tags: "Kiegészítések és kulcsszavak:",
        prerequisites: "Előfeltételek:",
        releaseNotes: "Újdonságok:",
        versions: "Verziók",
        testedVersions: "Kompatibilis verziók",
        qualityTag: "Minőség - a tulajdonos értékeli",
        otherAttributes: "Egyéb attribútumok",
        labels: "Keresési feltételek",
        supportInformation: "Támogatás",
        updateInformation: "Frissítések",
        latestUpdate: "Utolsó frissítés",
        totalDownloads: "Összes letöltés",
        licenses: "Licencek",
        maintenanceRequired: "A letöltéshez Tekla Maintenance szükséges",
        tekla_maintenance_content: "Tekla Maintenance-tartalom",
        download_requires_tekla_maintenance: "A letöltéshez Tekla Maintenance szükséges",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Sikeresen frissítve",
        update_failed: "A frissítés nem sikerült",
        update_failed_invalid_tag: "A frissítés nem sikerült: érvénytelen címke",
        update_failed_details_max_length_exceeded: "A frissítés nem sikerült: túl sok részlet",
        show_version_details: "További információ",
        content_removed: "Tartalom eltávolítva",
        no_versions_available:
          "Nem áll rendelkezésre verzió. A frissítések megjelenítése eltarthat egy darabig. A módosítások megtekintéséhez frissítse a böngészőt.",
        thumbnail_deleted: "Miniatűr törölve",
        thumbnail_updated: "Miniatűr frissítve",
        verify_service_plugin_is_running:
          "Ellenőrizze, hogy a Tekla Warehouse Service beépülő modul megfelelően működik-e",
        verify_ts_connection: "Ellenőrizze, hogy a Tekla Structures fut-e",
        download_tos: {
          title: "Minőség, támogatás és adatvédelem",
          content_1: "Győződjön meg arról, hogy a letöltött tartalom megfelel a minőségi követelményeinek. A Trimble nem támogatja a külső felek tartalmait, és nem vállal értük felelősséget. Támogatásért forduljon közvetlenül a tartalomszolgáltatóhoz.",
          content_2: "Az országának és a szervezetének a neve megjelenhet a szolgáltatásban, és a szabályozássokkal összhangban lévő célokból megosztható a tartalom tulajdonosaival (például azért, hogy Ön értesülhessen a tartalom módosításairól). Az értesítésekről leiratkozhat.",
          content_3: 'A Trimble tartalombővítményekre a <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimble feltételei</a> vonatkoznak. A harmadik fél tartalmára a harmadik fél licencfeltételei vonatkozhatnak. Lásd a <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimble Adatvédelmi Nyilatkozatot</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Ez az ingyenes Tekla Structures BIM-tároló. Keresés, importálás, telepítés és megosztás cégen belül és globálisan. Kiváló minőségű modelleket készíthet, hatékonyan.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "A Tekla Warehouse ingyenes online és helyi tár, ahol Tekla Structures-modellezési eszközök, -intelligens elemek és -sablonok találhatók",
        },
        search: {
          title: "Keresés a Tekla Warehouse rendszerben",
          description:
            "Tekla-bővítmények, 3D-s elemek, alakzatok, felhasználói komponensek, profilok, anyagok (pl. acél- és betonminőségek), vasalások, hálók és sablonok is kereshetők",
        },
        about: {
          title: "Információk a Tekla Warehouse rendszerről",
          description:
            "A Tekla Warehouse ingyenes marketingcsatornát biztosít a termékekhez, és a tartalmak teljes ellenőrzését teszi lehetővé",
          developers: {
            title: "Alapértelmezett fejlesztői cím",
            description: "Alapértelmezett fejlesztői leírás",
          },
          usage: {
            title: "Alapértelmezett használati cím",
            description: "Alapértelmezett használati leírás",
          },
          getting_started: {
            title: "Alapértelmezett kezdő lépések címe",
            description: "Alapértelmezett kezdő lépések leírása",
          },
          content: {
            title: "Alapértelmezett tartalom címe",
            description: "Alapértelmezett tartalom leírása",
          },
          using_content: {
            title: "Alapértelmezett, amely a tartalom címét használja",
            description: "Alapértelmezett, amely a tartalom leírását használja",
          },
          creating_content: {
            title: "Alapértelmezett tartalom-létrehozási cím",
            description: "Alapértelmezett tartalom-létrehozási leírás",
          },
        },
        collection: {
          title: "Alapértelmezett gyűjteménycím",
          description: "Alapértelmezett gyűjteményleírás",
        },
        my_warehouse: {
          title: "Saját Tekla Warehouse",
          description: "A Tekla Warehouse-tartalom kezelése",
        },
      },
      upload: {
        actions: {
          cancel: "Mégse",
          previous: "&lsaquo; Előző",
          next: "Következő &rsaquo;",
        },
        content: {
          add_content: "Fájlok hozzáadása",
        },
        collection: {
          heading: "Gyűjtemény kiválasztása vagy létrehozása",
          subHeading:
            "Gyűjtemények csoportjainak tartalma. Az online gyűjtemények segítségével közzéteheti a tartalmakat vagy a helyi gyűjteményeket, hogy megossza őket a kollégáival. A helyi gyűjtemények személyes célra is használhatók.",
          loading: "Gyűjtemények betöltése...",
          selectFromMyCollections: "Kiválasztás a Saját gyűjtemények közül",
          description_of_the_collection: "Leírás",
          title_validation: {
            missing: "Kérjük, adjon meg egy címet.",
            too_short: function (length) {
              return "A cím legalább " + length + " karakter legyen.";
            },
            too_long: function (length) {
              return "A cím nem lehet hosszabb, mint " + length + " karakter.";
            },
          },
        },
        package: {
          basic_information: "Alapvető információk",
          code_name: "Kódnév",
          code_name_info:
            "Adjon meg egy kódnevet a tartalom egyedi azonosítójaként. A kódnév tartalmazhat kisbetűket, számokat és a következő speciális karaktereket: _ - @. Mentés után nem módosíthatja a kódnevet.",
          code_name_validation: {
            missing: "Meg kell adnia egy kódnevet",
            wrong_format: "A kódnév tartalmazhat kisbetűket, számokat és a következő speciális karaktereket: _ - @",
            not_unique: "Ez a kódnév már használatban van. Egyedi kódnevet kell megadnia.",
          },
          search_criteria: "Keresési feltételek",
          support: "Támogatás",
          title: "Cím",
          thumbnail: "Miniatűr",
          "3D_pic": "3D-s modell",
          title_validation: {
            missing: "Kérjük, adjon meg egy címet.",
            too_short: function (length) {
              return "A cím legalább " + length + " karakter legyen.";
            },
          },
          description_validation: {
            missing: "Kérjük, adjon meg leírást.",
            too_short: function (length) {
              return "A leírás legalább " + length + " karakter legyen.";
            },
          },
          wrong_file_type: "Hibás fájltípus.",
          selected_image_is_too_big: "A kiválasztott kép túl nagy.",
        },
        version: {
          select_version: "Verzió kiválasztása",
          select_quality: "Minőség kiválasztása",
          versions: "Verziók",
          displayNameNew: "Verzió megjelenített neve",
          copy_from_version: "Metaadatok másolása meglévő verzióból (opcionális)",
          displayName: "Megjelenített név",
          content_information: "Tartalominformációk",
          back_to_content_information: "&lsaquo; Vissza a tartalominformációkhoz",
          version_and_file_management: "Verzió- és fájlkezelés",
          there_s_no_versions_yet: "Még nincsenek verziók.",
          create_a_version_to_add_files: "Hozzon létre egy verziót a fájlok hozzáadásához.",
          add_version: "Verzió hozzáadása",
          version_information: "Verzióinformáció",
          delete_version: "Verzió törlése",
          no_versions_available: "Nem áll rendelkezésre verzió. A frissítések megjelenítése eltarthat egy darabig.",
          update_start_title_html: '<div class="spinner spinner-white">Frissítés</div>',
          update_start_message: "Ez eltarthat egy darabig.",
          version_was_created: "Verzió létrehozva.",
          version_was_created_with_delay: "Verzió létrehozva. A változtatások pár perc múlva jelennek meg.",
          version_was_updated: "Verzió frissítve.",
          version_was_deleted: "Verzió törölve",
          version_was_deleted_with_delay: "Verzió törölve. A változtatások pár perc múlva jelennek meg.",
          confirm_delete: function (title) {
            return 'Törli a verziót: "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "A csomag feltöltve.",
          couldn_t_create_a_package: "Nem sikerült létrehozni a csomagot. A változtatásokat visszavonja a rendszer.",
          uploading_title_html: '<div class="spinner spinner-white">Feltöltés</div>',
          uploading_text: "Ez eltarthat egy darabig.",
          select_organization: "Szervezet kiválasztása",
          collection_visibility: "Gyűjtemény láthatósága:",
          actions: {
            create: "Létrehozás",
            publish: "Kiadni",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Ez a tartalom minden felhasználó számára látható lesz, aki hozzáfér a helyi vagy a hálózati meghajtóhoz.",
          this_content_will_be_visible_only_to_you: "Ez a tartalom csak az Ön számára lesz látható.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Ezt a tartalmat csak Ön és a szervezet munkatársai láthatják.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Ez a tartalom mindenki számára látható lesz a közzététel után.",
          public_content_disclaimer:
            "Ön felelős minden tartalomért, amelyet feltölt. Győződjön meg arról, hogy a feltöltött tartalom nem káros, hogy Ön jogosult a tartalom feltöltésére, és a tartalom nem sért szellemi tulajdonjogot. Ön elfogadja, hogy köteles közzétenni a szolgáltatásban a feltöltött tartalom használatára vonatkozó esetleges feltételeket.",
        },
        metadata: {
          title: "Metaadatok",
          subtitle: "Alapadatok",
          deleteThumbnail: "Miniatűr törlése",
          buttonCreate: "Létrehozás",
        },
        basicdata: {
          title: "Tartalom hozzáadása",
          clickButtonToSelectFile: "Kattintson a gombra a fájl kiválasztásához",
          thumbnail: "Miniatűrök",
          dropImageHere: "Húzza ide a képet",
        },
        "3dGeometry": {
          title: "3D-termékkatalógus létrehozása",
          buttonAddEntity: "3D-s termék hozzáadása",
          buttonAdd: "Egyéni attribútum hozzáadása",
          errorAttributeAlreadyExists: "Már létezik attribútum a megadott névvel.",
          buttonCreatePackage: "Csomag létrehozása",
          buttonCreatePackages: "Csomagok létrehozása",
          addCustomAttributePlaceholder: "Tulajdonság neve",
        },
        please_log_in_fragments: {
          please: "Kérjük, ",
          log_in: "Bejelentkezés",
          or: " vagy ",
          register: "regisztráljon",
          to_upload_online_content: " az online tartalom feltöltéséhez.",
        },
        header: {
          upload_header: "Feltöltés",
          collection: "Gyűjtemény",
          content: "Tartalom",
          version: "Verzió",
          finish: "Befejezés",
        },
        contentOwner: "Készítő",
        contentOwnerGroup: "Tartalom kiadása",
        select_action: "Művelet kiválasztása",
        tooltip: "Elemleírás",
        description: "Leírás",
        copyright: "Copyright",
        tags: "Kiegészítések és kulcsszavak",
        tagsSubtitle:
          "Az elválasztáshoz használjon vesszőt, például: 1. kulcsszó, 2. kulcsszó. Ha a kulcsszó több szóból áll, a szavak között használjon aláhúzásjelet (–); a szavak között nem használható szóköz. Az aláhúzásjelen és a vesszőn kívül nem használható más speciális karakter.",
        images: "Képek",
        installationInfo: "Telepítési információk",
        productCode: "Termékkód",
        copyrightInfo: "Szerzői jogi információk",
        Details: "Részletek",
        specialTermsUrl: "EULA",
        productExpirationDate: "Termék lejárati ideje",
        helpUrl: "Weboldal",
        helpUrlSubtitle: "teljes URL-cím beszúrása",
        detailsSubtitle:
          "Ebben a fázisban csak egyszerű szöveget adjon hozzá a részleteket tartalmazó mezőhöz. A tartalom létrehozása után HTML-formázás hozzáadásával módosíthatja a részleteket.",
        specialTermsUrlSubtitle:
          "A tartalom tulajdonosának lehetősége nyílt, hogy kiegészítő feltételeket adjon saját tartalmaihoz. A tulajdonosnak meg kell adnia az URL-címet, ahol a specifikus feltételek megtalálhatóak. Ezek a feltételek nem írják felül a Trimble Szolgáltatási feltételeit.",
        releaseNotes: "Kiadási megjegyzések fájlja",
        releaseNoteFileSubTitle: "Kiadási megjegyzések hozzáadása fájlként",
        supportUrl: "Súgó és támogatás",
        supportUrlSubtitle: "szúrja be a teljes URL-címet vagy a „mailto:” előtagot az e-mailezéshez",
        supportForumUrl: "Vitafórum (webhely)",
        supportForumUrlSubtitle: "teljes URL-cím beillesztése",
        measurementUnits: "Mértékegységek",
        itemTypeCategories: "Csoport",
        useCategories: "Kategória",
        compatibleSoftwareProducts: "Kompatibilis szoftverek",
        testedVersions: "Kompatibilis verziók",
        compatibleOperatingSystems: "Kompatibilis operációs rendszer",
        locationRestrictions: "Termék elérhetősége",
        supportedLanguages: "Nyelvek",
        select_releasenotes_file: "Fájlválasztás",
        qualityTag: "Minőség - a tulajdonos értékeli",
        requireMaintenance: "A letöltéshez Tekla-ügyfélkarbantartás szükséges",
        contentOwnerGroupAsMyself: "Saját magamként",
        contentOwnerGroupAsUserGroup: "Felhasználói csoportként",
        selectUserGroupForContentOwner: "Felhasználói csoport kiválasztása tartalomtulajdonosként",
        defaultUploadTemplate: "Alapértelmezett csomag",
        templateFor3DGeometryFiles: "3D-s geometriacsomag(ok)",
        title: "Cím",
        details: "Részletek",
        selectContentOwner: "Tartalomtulajdonos kiválasztása",
        selectContentType: "Típus",
        errorWrongfiletype: "Hibás fájltípus.",
        errorImageTooBig: "A kiválasztott kép túl nagy.",
        errorBlankTitle: "A cím nem lehet üres.",
        commonLabelsTitle: "Általános címkék minden csomaghoz",
      },
      profile: {
        content_usage: "Tartalomhasználat",
        profile_updated: "A profil frissítve",
        invitation_accepted: function (target) {
          return "A meghívó elfogadva! Most már hozzáfér a következőkhöz: " + target + ".";
        },
        user_groups: {
          edit_user_group: "Felhasználói csoport szerkesztése",
          user_group_updated: "Felhasználói csoport frissítve",
          create_a_new_user_group: "Új felhasználói csoport létrehozása",
          name_of_the_user_group: "Felhasználói csoport neve",
          description: "Leírás",
          group_visibility: "Csoport láthatósága:",
          remove: "„Biztos benne?”",
          remove_invitations: "„Biztos benne?”",
          remove_members: "„Biztos benne?”",
          invitations_removed: "Meghívások eltávolítva!",
          members_list_updated: "Taglista frissítve!",
          user_group_created: "Felhasználói csoport létrehozva",
        },
        members: {
          update_selected_members: "Kiválasztott tagok frissítése",
          new_role: "Új szerepkör",
          members_list_updated: "Taglista frissítve!",
          edit_user_group: "Felhasználói csoport szerkesztése",
          delete_user_group: "Felhasználói csoport törlése",
          filter: "Szűrő",
          user_group_members: "Felhasználói csoport tagjai",
          update_members: "Tagok frissítése",
          remove_members: "Tagok eltávolítása",
          pending_invitations: "Függő meghívók",
          invite_member: "Tag meghívása",
          update_invitations: "Meghívók frissítése",
          remove_invitations: "Meghívók eltávolítása",
        },
        subTabs: {
          alerts: "Saját riasztások",
          collections: "Gyűjtemények",
          likes: "Kedvelések",
        },
        admin: {
          grant_sales_admin: "Értékesítési rendszergazdai szerep felhasználóhoz való rendelése",
          grant_analyst_role: "Elemzési szerep felhasználóhoz való rendelése",
          role_granted: "Szerep hozzárendelve",
          role_removed: "Szerep eltávolítva",
          offline_to_online: "Online tartalom online tartalommá alakítása",
          use_elastic_search: "Rugalmas keresés használata",
        },
        sales: {
          title: "Értékesítés",
        },
        subtitlePreferences: "Keresési beállítások",
        preferenceInfo:
          'Ezek a szűrők tartalmat ajánlanak Önnek a Tekla Warehouse <a href="#/landing">kezdőlapján.</a>',
        clearFilters: "Szűrők törlése &rsaquo;",
        admin_role: "Rendszergazda",
        sales_role: "Értékesítés",
        admin_mode: "Rendszergazda üzemmód:",
        firstName: "Keresztnév",
        lastName: "Vezetéknév",
        displayName: "Megjelenített név:",
        organization: "Szervezet neve:",
        email: "E-mail:",
        teklaId: "Tekla-azonosító",
        maintenanceAccess: "Hozzáférés a Tekla Maintenance-tartalmakhoz:",
        myLocations: "Projekthelyek",
        measurementUnits: "Egységek",
        useCategories: "Használat",
        itemTypeCategories: "A következő típusok iránt érdeklődöm",
        languages: "Nyelvek",
        operatingSystems: "Operációs rendszerek",
        software: "Szoftverek",
        user_id: "Felhasználói azonosító:",
        organization_id: "Szervezetazonosító:",
        edit_in_atc: "A profil szerkesztése a Tekla Account-ban",
        no_likes: "Nincs kedvelés",
        no_alerts: "Nincs előfizetés",
        clear_alerts: "Riasztások törlése",
        alerts_info_html:
          "Riasztást kap, ha a következő módosítások valamelyikére sor kerül<br>" +
          "<b>Tartalom:</b> A tartalomelem címe, leírása, keresési feltételei, illetve metaadatai módosultak, a tartalom új verziója lettek hozzáadva, vagy egy verziót töröltek.<br>" +
          "<b>Gyűjtemény:</b> Cím vagy leírás szerkesztve, tartalom hozzáadva vagy eltávolítva.",
      },
      uploader: {
        placeholders: {
          productCode: "Termékkód",
          title: "Név",
          description: "Leírás",
          productWeight: "Termék súlya",
          weightUnit: "Súly mértékegysége",
          material: "Anyag",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D-termékkatalógus",
          addToolButton: "Alkalmazás",
          select_files: "Fájlok lemezről",
          select_files_from_ts: "Fájlok a Tekla Structures rendszerből",
          partnerDownloadLink: "Partner letöltési link",
        },
        headings: {
          geometryFile: "Geometriai fájl",
          thumbnailFile: "Miniatűr bittérképe (.bmp)",
          title: "Név",
          description: "Leírás",
          productCode: "Termékkód",
          productWeight: "Termék súlya",
          weightUnit: "Súly mértékegysége",
          material: "Anyag",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Globális",
          AF: "Afganisztán",
          AX: "Åland-szigetek",
          AL: "Albánia",
          DZ: "Algéria",
          AS: "Amerikai Szamoa",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarktisz",
          AG: "Antigua és Barbuda",
          AR: "Argentína",
          AM: "Örményország",
          AW: "Aruba",
          AU: "Ausztrália",
          AT: "Ausztria",
          AZ: "Azerbajdzsán",
          BS: "Bahama-szigetek",
          BH: "Bahrein",
          BD: "Banglades",
          BB: "Barbados",
          BY: "Fehéroroszország",
          BE: "Belgium",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermuda",
          BT: "Bhután",
          BO: "Bolívia",
          BQ: "Bonaire",
          BA: "Bosznia és Hercegovina",
          BW: "Botswana",
          BV: "Bouvet-sziget",
          BR: "Brazília",
          IO: "Brit Indiai-óceáni Terület",
          BN: "Brunei",
          BG: "Bulgária",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Kambodzsa",
          CM: "Kamerun",
          CA: "Kanada",
          CV: "Zöld-foki-szigetek",
          KY: "Kajmán-szigetek",
          CF: "Közép-afrikai Köztársaság",
          TD: "Csád",
          CL: "Chile",
          CN: "Kínai",
          CX: "Karácsony-sziget",
          CC: "Kókusz (Keeling)-szigetek",
          CO: "Kolumbia",
          KM: "Comore-szigetek",
          CG: "Kongói Köztársaság",
          CD: "Kongói Demokratikus Köztársaság",
          CK: "Cook-szigetek",
          CR: "Costa Rica",
          CI: "Elefántcsontpart",
          HR: "Horvátország",
          CU: "Kuba",
          CW: "Curaçao",
          CY: "Ciprus",
          CZ: "Cseh Köztársaság",
          DK: "Dánia",
          DJ: "Dzsibuti",
          DM: "Dominika",
          DO: "Dominikai Köztársaság",
          EC: "Ecuador",
          EG: "Egyiptom",
          SV: "El Salvador",
          GQ: "Egyenlítői-Guinea",
          ER: "Eritrea",
          EE: "Észtország",
          ET: "Etiópia",
          FK: "Falkland-szigetek (Malvin-szigetek)",
          FO: "Feröer-szigetek",
          FJ: "Fidzsi-szigetek",
          FI: "Finnország",
          FR: "Francia",
          GF: "Francia Guyana",
          PF: "Francia Polinézia",
          TF: "Francia Déli Területek",
          GA: "Gabon",
          GM: "Gambia",
          GE: "Grúzia",
          DE: "Németország",
          GH: "Ghána",
          GI: "Gibraltár",
          GR: "Görögország",
          GL: "Grönland",
          GD: "Grenada",
          GP: "Guadeloupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guinea",
          GW: "Bissau-Guinea",
          GY: "Guyana",
          HT: "Haiti",
          HM: "Heard-sziget és McDonald-szigetek",
          VA: "Vatikánváros",
          HN: "Honduras",
          HK: "Hongkong",
          HU: "Magyarország",
          IS: "Izland",
          IN: "Indiai",
          ID: "Indonézia",
          IR: "Irán",
          IQ: "Irak",
          IE: "Írország",
          IM: "Man-sziget",
          IL: "Izrael",
          IT: "Olaszország",
          JM: "Jamaica",
          JP: "Japán",
          JE: "Jersey",
          JO: "Jordánia",
          KZ: "Kazahsztán",
          KE: "Kenya",
          KI: "Kiribati",
          KW: "Kuvait",
          KG: "Kirgizisztán",
          LA: "Laosz",
          LV: "Lettország",
          LB: "Libanon",
          LS: "Lesotho",
          LR: "Libéria",
          LY: "Líbia",
          LI: "Liechtenstein",
          LT: "Litvánia",
          LU: "Luxemburg",
          MO: "Makaó",
          MK: "Macedónia",
          MG: "Madagaszkár",
          MW: "Malawi",
          MY: "Malajzia",
          MV: "Maldív-szigetek",
          ML: "Mali",
          MT: "Málta",
          MH: "Marshall-szigetek",
          MQ: "Martinique",
          MR: "Mauritánia",
          MU: "Mauritius",
          YT: "Mayotte",
          MX: "Mexikó",
          FM: "Mikronézia",
          MD: "Moldova",
          MC: "Monaco",
          MN: "Mongólia",
          ME: "Montenegró",
          MS: "Montserrat",
          MA: "Marokkó",
          MZ: "Mozambik",
          MM: "Mianmar (Burma)",
          NA: "Namíbia",
          NR: "Nauru",
          NP: "Nepál",
          NL: "Hollandia",
          NC: "Új-Kaledónia",
          NZ: "Új-Zéland",
          NI: "Nicaragua",
          NE: "Niger",
          NG: "Nigéria",
          NU: "Niue",
          NF: "Norfolk-sziget",
          KP: "Észak-Korea",
          MP: "Északi-Mariana-szigetek",
          NO: "Norvégia",
          OM: "Omán",
          PK: "Pakisztán",
          PW: "Palau",
          PS: "Palesztin területek",
          PA: "Panama",
          PG: "Pápua Új-Guinea",
          PY: "Paraguay",
          PE: "Peru",
          PH: "Fülöp-szigetek",
          PN: "Pitcairn",
          PL: "Lengyelország",
          PT: "Portugália",
          PR: "Puerto Rico",
          QA: "Katar",
          RE: "Réunion",
          RO: "Románia",
          RU: "Orosz Föderáció",
          RW: "Ruanda",
          BL: "Saint-Barthélemy",
          SH: "Szent Ilona",
          KN: "Saint Kitts és Nevis",
          LC: "Saint Lucia",
          MF: "Szent Márton-sziget",
          PM: "Saint Pierre és Miquelon",
          VC: "Saint Vincent és a Grenadine-szigetek",
          WS: "Szamoa",
          SM: "San Marino",
          ST: "São Tomé és Príncipe",
          SA: "Szaúd-Arábia",
          SN: "Szenegál",
          RS: "Szerbia",
          SC: "Seychelle-szigetek",
          SL: "Sierra Leone",
          SG: "Szingapúr",
          SX: "Sint Maarten",
          SK: "Szlovákia",
          SI: "Szlovénia",
          SB: "Salamon-szigetek",
          SO: "Szomália",
          ZA: "Dél Afrikai",
          GS: "Dél-Grúzia",
          KR: "Dél-Korea",
          SS: "Dél-Szudán",
          ES: "Spanyolország",
          LK: "Srí Lanka",
          SD: "Szudán",
          SR: "Suriname",
          SJ: "Svalbard- és Jan Mayen-szigetek",
          SZ: "Szváziföld",
          SE: "Svédország",
          CH: "Svájc",
          SY: "Szíriai Arab Köztársaság",
          TW: "Tajvan",
          TJ: "Tádzsikisztán",
          TZ: "Tanzánia",
          TH: "Thaiföld",
          TL: "Kelet-Timor",
          TG: "Togo",
          TK: "Tokelau-szigetek",
          TO: "Tonga",
          TT: "Trinidad és Tobago",
          TN: "Tunézia",
          TR: "Törökország",
          TM: "Türkmenisztán",
          TC: "Turks- és Caicos-szigetek",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ukrajna",
          AE: "Egyesült Arab Emírségek",
          GB: "Egyesült Királyság",
          US: "Amerikai Egyesült Államok",
          UM: "Amerikai Egyesült Államok lakatlan külbirtokai",
          UY: "Uruguay",
          UZ: "Üzbegisztán",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietnám",
          VG: "Brit Virgin-szigetek",
          VI: "Amerikai Virgin-szigetek",
          WF: "Wallis és Futuna",
          EH: "Nyugat-Szahara",
          YE: "Jemen",
          ZM: "Zambia",
          ZW: "Zimbabwe",
        },
        sortOptions: {
          "-modifyTime": "Utoljára frissített",
          "-modifiedAt": "Utoljára frissített",
          title: "Ábécé szerinti A-Z",
          "-title": "Ábécé szerinti Z-A",
          "-reviewCount": "Legkedveltebb",
          name: "Név",
          role: "Szerepkör",
          email: "E-mail",
          "-relevance": "Jelentőség",
          "-createdAt": "Utoljára létrehozva",
          displayName: "Ábécé szerinti A-Z",
          "-displayName": "Ábécé szerinti Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Ma",
          yesterday: "Tegnap",
          this_week: "Ez a hét",
          previous_week: "Múlt hét",
          this_month: "Ez a hónap",
          previous_month: "Múlt hónap",
          this_year: "Ez az év",
          previous_year: "Tavalyi év",
          beginning_of_time: "Kezdetek",
          custom: "Egyéni időkeret",
        },
        eventTypeOptions: {
          downloads: "Letölthetők",
          views: "Nézetek",
          all_events: "Minden esemény",
        },
      },
    });
  },
};
