import { observable, action, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import { RootStore, createStoreContext } from "../../stores/rootStore";
import { Installer } from "../../utils/Installer";
import { NewRepository } from "../../js/services/NewRepository";
import { IClient, IEntity, IFileItem, IInstallationStatus, IVersion } from "../../models/dataModel";
import { ItemTypeEnum } from "models/enums";

export class InstallDialogStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Installation status.
   */
  @observable private installationStatus!: IInstallationStatus;

  /**
   * The selected Tekla Structures client.
   */
  @observable private selectedTsVersion?: IClient;

  /**
   * Count of selected entities.
   */
  @observable private selectedCount = 0;

  /**
   * Count of installable entities.
   */
  @observable private installableCount = 0;

  /**
   * Title of the failed installation, generated if failed installs exist.
   */
  @observable private failedListTitle = "";

  /**
   * Flag for observing if the Installer has been called.
   */
  @observable private installStarted = false;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Sets the installation status passed from the page that calls the dialog, calls the initializer for store.
   * @param status installation status
   */
  @action
  public async setInstallationStatus(status: IInstallationStatus) {
    this.installationStatus = status;
    await this.setVersions();
    this.initialize();
  }

  /**
   * Initializes the store based on installationStatus.
   */
  @action
  private initialize() {
    if (this.installationStatus.failedInstallationAttempts) {
      this.selectedCount =
        this.selectedCount + this.versionCountFor(this.installationStatus.failedInstallationAttempts);
      this.failedListTitle = this.rootStore.getTranslation(
        "install.failed_installs_info_func",
        _.escape(this.installationStatus.failedInstallationAttempts.length.toString()),
      );
    }

    if (this.installationStatus.installableEntities) {
      this.selectedCount = this.selectedCount + this.versionCountFor(this.installationStatus.installableEntities);
      this.installableCount = this.versionCountFor(this.installationStatus.installableEntities);
    }
  }

  /**
   * Sets the selected Tekla Structures version.
   * @param to the selected TS client
   */
  @action
  public setSelectedTSVersion(to: IClient) {
    this.selectedTsVersion = to;
  }

  /**
   * Returns the selected TS version, or 'undefined' if no version is selected.
   */
  public getSelectedTSVersion(): IClient | undefined {
    return this.selectedTsVersion;
  }

  /**
   * Gets the selection information based on selectedCount
   * @returns a string with number of selected items
   */
  public getSelectionInfo(): string {
    return this.rootStore.getTranslation("install.total_selection_info_func", _.escape(this.selectedCount.toString()));
  }

  /**
   * Returns the count of installable items
   */
  public getInstallableCount() {
    return this.installableCount;
  }

  /**
   * Gets a list of failed installations
   */
  public getFailedInstallations(): Array<{ title: string }> {
    if (this.installationStatus) {
      return this.installationStatus.failedInstallationAttempts;
    } else return [];
  }

  /**
   * Gets the title of the failed list
   */
  public getFailedListTitle(): string {
    return this.failedListTitle;
  }

  /**
   * Returns a boolean indicating if there are failed installations
   */
  public hasFailedInstallations(): boolean {
    return (
      this.installationStatus.failedInstallationAttempts &&
      this.installationStatus.failedInstallationAttempts.length > 0
    );
  }

  public hasInstallStarted(): boolean {
    return this.installStarted;
  }

  /**
   * Installs the selected entity.
   */
  @action
  public async installSelected(): Promise<boolean> {
    let tsVersion;
    if (this.selectedTsVersion) {
      tsVersion = this.selectedTsVersion.id;
    } else {
      tsVersion = this.rootStore.getLocalServiceStore().getLocalService().clients[0].id;
    }

    this.installStarted = true;

    try {
      const promises: Array<Promise<unknown>> = [];
      _.each(this.installationStatus.installableEntities, (entity) => {
        return _.each(entity.versions, async (version) => {
          promises.push(Installer.installVersion(this.rootStore, entity.package, version, tsVersion));
        });
      });
      await Promise.all(promises);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Resolves if installable data has versions with tsep binaries
   * @returns true if tsep versions found
   */
  public tsepVersionsExist(): boolean {
    const tsepVersions = _.filter(
      this.installationStatus.installableEntities,
      (installableEntity: { package: IEntity; versions: IVersion[] }) => {
        return _.some(installableEntity.versions, (version: IVersion) => this.versionHasTsepBinaries(version));
      },
    );
    return tsepVersions.length > 0;
  }

  private versionHasTsepBinaries(version: IVersion): boolean {
    const tsepBinaries = _.filter(version.binaries, (binary: IFileItem) => {
      return !!binary.attributes && !!binary.attributes.itemType && binary.attributes.itemType === ItemTypeEnum.TSEP;
    });
    return tsepBinaries.length > 0;
  }

  /**
   * Helper method for getting the count of versions for a list of items.
   */
  private versionCountFor(list): number {
    let versionCount = 0;
    _.each(list, function (item) {
      if (item.versions) {
        versionCount = versionCount + item.versions.length;
      }
    });
    return versionCount;
  }

  /**
   * Fetches versions to given entity
   * @params entity to search versions for
   */
  private async fetchVersions(installableEntity: { package: IEntity; versions: IVersion[] }): Promise<IVersion[]> {
    const versions = await NewRepository.getVersions(installableEntity.package, false);
    installableEntity.versions = _.reject(versions, (version) => {
      return version.attributes.isTool;
    });
    const failedVersions: IVersion[] = _.filter(versions, (version) => {
      return version.attributes.isTool;
    });
    _.each(failedVersions, (version) => {
      runInAction(() => {
        this.installationStatus.failedInstallationAttempts.push({
          title: `${installableEntity.package.title}, version: ${version.title}`,
        });
      });
    });
    return installableEntity.versions;
  }

  /**
   * Sets versions to given entities if not already existing
   * @params entities list of entities to handle
   */
  private async setVersions() {
    const promises: Array<Promise<IVersion[]>> = [];
    _.each(this.installationStatus.installableEntities, async (installableEntity) => {
      if (!installableEntity.versions || installableEntity.versions.length == 0) {
        promises.push(this.fetchVersions(installableEntity));
      }
    });

    return Promise.all(promises);
  }
}

export const InstallDialogContext = createStoreContext<InstallDialogStore>(InstallDialogStore);
