import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext } from "../../stores/rootStore";
import { PackagePageStoreContext } from "../packagePageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { SearchCriteriaStoreContext } from "./searchCriteriaStore";

import { EditSearchCriteria } from "package/dialogs/EditSearchCriteria";

const Licenses = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(SearchCriteriaStoreContext);

  function handleChange(event) {
    store.updateLicense(event.target.checked);
  }

  return (
    <div className="search-criteria-item">
      <span className="label">{rootStore.getTranslation("details.licenses")}</span>
      <div className="license l-relative">
        <input
          id="maintenance-license"
          type="checkbox"
          checked={store.maintenanceLicenseExists()}
          onChange={handleChange}
          data-testid="maintenance-license"
        />
        <label htmlFor="maintenance-license">{rootStore.getTranslation("upload.requireMaintenance")}</label>
      </div>
    </div>
  );
});

const Commenting = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(SearchCriteriaStoreContext);

  function setAllowCommenting() {
    store.saveAllowCommenting();
  }

  return (
    <div className="search-criteria-item">
      <span className="label">{rootStore.getTranslation("details.content_commenting")}</span>
      <div className="commenting l-relative">
        <input
          id="edit-commenting"
          type="checkbox"
          checked={store.getAllowCommenting()}
          onChange={setAllowCommenting}
          data-testid="allow-commenting"
        />
        <label htmlFor="edit-commenting">{rootStore.getTranslation("shared.commenting.content_commenting")}</label>
      </div>
    </div>
  );
});

/**
 * A component for editing search criteria information
 */
export const SearchCriteria = observer(() => {
  const rootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function canEdit(): boolean {
    return packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function tagsExist(): boolean {
    return !!packageItem && !!packageItem.tags && packageItem.tags.length > 0;
  }

  function getTagsList(): string {
    return !!packageItem && packageItem!.tags ? packageItem.tags.join(", ") : "";
  }

  function attributeExists(key): boolean {
    return (
      !!packageItem && packageItem.attributes && packageItem.attributes[key] && packageItem.attributes[key].length > 0
    );
  }

  function getAttributeTranslations(attributeName): string {
    const translations: string[] = [];
    const key = attributeName === "locationRestrictions" ? "constants.countries" : attributeName;
    if (packageItem) {
      _.map(packageItem.attributes![attributeName], (attribute) => {
        translations.push(rootStore.getTranslation(key + "." + attribute));
      });
    }
    return translations.join(", ");
  }

  function canEditLicensesAndCommenting(): boolean {
    return !!packageItem && !packageItem.isLocal && canEdit();
  }

  return (
    <Fragment>
      {canEdit() && (
        <DialogContext.Provider value={new DialogStore()}>
          <EditSearchCriteria />
        </DialogContext.Provider>
      )}
      {tagsExist() && (
        <div className="search-criteria-item">
          <span className="label">{rootStore.getTranslation("details.tags")}</span>
          <span data-testid="tags-list">{getTagsList()}</span>
        </div>
      )}
      {attributeExists("locationRestrictions") && (
        <div className="search-criteria-item">
          <span className="label">{rootStore.getTranslation("details.locationRestrictions")}</span>
          <span data-testid="location-restrictions">{getAttributeTranslations("locationRestrictions")}</span>
        </div>
      )}
      {attributeExists("supportedLanguages") && (
        <div className="search-criteria-item">
          <span className="label">{rootStore.getTranslation("details.supportedLanguages")}</span>
          <span data-testid="supported-languages">{getAttributeTranslations("supportedLanguages")}</span>
        </div>
      )}
      {attributeExists("measurementUnits") && (
        <div className="search-criteria-item">
          <span className="label">{rootStore.getTranslation("details.measurementUnits")}</span>
          <span data-testid="measurement-units">{getAttributeTranslations("measurementUnits")}</span>
        </div>
      )}
      {canEditLicensesAndCommenting() && (
        <Fragment>
          <Licenses />
          <Commenting />
        </Fragment>
      )}
    </Fragment>
  );
});
