import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { RootStore, RootContext } from "../stores/rootStore";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { DialogContext, DialogStore } from "./dialogStore";
import { ConfirmAction } from "./ConfirmAction";

/**
 * Component that renders a TOS dialog.
 */
export const TOSDialog = observer(({ confirmAction }: { confirmAction: () => void }) => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);
  const userStore = rootStore.getUserStore();

  return (
    <ConfirmAction
      title={rootStore.getTranslation("details.download_tos.title")}
      content={
        <div className="tos-content">
          <p><TranslatedHtml entry={"details.download_tos.content_1"} /></p>
          <p><TranslatedHtml entry={"details.download_tos.content_2"} /></p>
          <p><TranslatedHtml entry={"details.download_tos.content_3"} /></p>
        </div>
      }
      callbackCloseDialog={() => dialog.close()}
      callbackConfirm={() => {
        confirmAction();
      }}
      callbackDontShow={(value) => {
        if (value) {
          userStore.setHasSeenTOSFlag(!!value);
        }
      }}
      labelConfirm={rootStore.getTranslation("actions.continue")}
      labelCancel={rootStore.getTranslation("actions.cancel")}
    />
  );
});
