import { Cs } from "./localization-cs-cz";
import { En } from "../localizations/localization-en-us";
import { De } from "../localizations/localization-de-de";
import { Es } from "../localizations/localization-es-es";
import { Fr } from "../localizations/localization-fr-fr";
import { Hu } from "../localizations/localization-hu-hu";
import { It } from "../localizations/localization-it-it";
import { Ja } from "../localizations/localization-ja-jp";
import { Ko } from "../localizations/localization-ko-kr";
import { Nl } from "../localizations/localization-nl-nl";
import { Pl } from "../localizations/localization-pl-pl";
import { PtBr } from "../localizations/localization-pt-br";
import { Pt } from "../localizations/localization-pt-pt";
import { Ru } from "../localizations/localization-ru-ru";
import { ZhCn } from "../localizations/localization-zh-cn";
import { Zh } from "../localizations/localization-zh-tw";
//About page localization files
import { CsAbt } from "../localizations/localization-about-cs-cz";
import { DeAbt } from "../localizations/localization-about-de-de";
import { EnAbt } from "../localizations/localization-about-en-us";
import { EsAbt } from "../localizations/localization-about-es-es";
import { FrAbt } from "../localizations/localization-about-fr-fr";
import { HuAbt } from "../localizations/localization-about-hu-hu";
import { ItAbt } from "../localizations/localization-about-it-it";
import { JaAbt } from "../localizations/localization-about-ja-jp";
import { KoAbt } from "../localizations/localization-about-ko-kr";
import { NlAbt } from "../localizations/localization-about-nl-nl";
import { PlAbt } from "../localizations/localization-about-pl-pl";
import { PtBrAbt } from "../localizations/localization-about-pt-br";
import { PtAbt } from "../localizations/localization-about-pt-pt";
import { RuAbt } from "../localizations/localization-about-ru-ru";
import { ZhCnAbt } from "../localizations/localization-about-zh-cn";
import { ZhAbt } from "../localizations/localization-about-zh-tw";

function setLocalizations() {
  Cs.set();
  En.set();
  De.set();
  Es.set();
  Fr.set();
  Hu.set();
  It.set();
  Ja.set();
  Ko.set();
  Nl.set();
  Pl.set();
  PtBr.set();
  Pt.set();
  Ru.set();
  ZhCn.set();
  Zh.set();
}

function setAboutPageLocalizations() {
  CsAbt.set();
  DeAbt.set();
  EnAbt.set();
  EsAbt.set();
  FrAbt.set();
  HuAbt.set();
  ItAbt.set();
  JaAbt.set();
  KoAbt.set();
  NlAbt.set();
  PlAbt.set();
  PtBrAbt.set();
  PtAbt.set();
  RuAbt.set();
  ZhCnAbt.set();
  ZhAbt.set();
}

/**
 * Component that sets localization strings
 */
export const Localizations = {
  init: (): void => {
    setLocalizations();
    setAboutPageLocalizations();
  },
};
