
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Pt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["pt-PT"]) {
      window.locale["pt-PT"] = {};
    }

    _.extend(window.locale["pt-PT"], {
      myDetails: {
        my_roles: "As minhas funções",
        organization_roles: "Funções",
        roles: {
          member: "Editor de conteúdos do Tekla Warehouse (Empregado)",
          employee: "Empregado",
          contentEditor: "Editor de conteúdos do Tekla Warehouse (Externo)",
        },
      },
      immutability: {
        add_code_name_for_package: "Adicionar um nome de código para o conteúdo",
        code_name: "Nome de código",
        immutable: "Imutável",
        archive: "Arquivo",
        archive_collection: "Arquivar a colecção e todo o seu conteúdo",
        archive_version: "Arquivar a versão selecionada",
        archive_package: "Arquivar o pacote e todo o seu conteúdo",
        archive_dialog_text: "Os conteúdos arquivados não serão visíveis ou pesquisáveis em Tekla Warehouse. No entanto, se o conteúdo tiver sido utilizado anteriormente com o serviço de nuvem Tekla Structures , o serviço de nuvem pode continuar a aceder ao conteúdo.",
        archive_success: function (name: string) {
          return `${name} arquivado com sucesso`;
        },
        archive_failure: function (name: string) {
          return `O arquivamento de ${name} falhou`;
        },
        archived_content_redirect_message: "O conteúdo a que está a tentar aceder foi arquivado",
        edit_title_move_or_archive: "Editar título, mover ou arquivar",
        edit_title_or_archive: "Editar título ou arquivar",
        major_update: "Actualização importante",
        major_update_description: "As alterações que não sejam compatíveis com as versões anteriores, tais como a adição ou remoção de funcionalidades, ou outras alterações radicais.",
        minor_update: "Pequena actualização",
        minor_update_description: "Alterações compatíveis com as versões anteriores, tais como novas características ou melhorias sem quebrar a funcionalidade existente.",
        patch_update: "Atualização de patch",
        patch_update_description: "Correcções compatíveis com versões anteriores, tais como patches de segurança, correcções de erros ou melhorias de desempenho, sem funcionalidades novas.",
        info: "A sua organização fornece conteúdos para o serviço cloud da Tekla Structures. É necessário um nome de código e um número de versão para os conteúdos no serviço da Cloud Tekla Structures para garantir a compatibilidade e a consistência.",
        immutable_files_info: "Para manter a consistência do conteúdo no serviço de nuvem, não é possível remover ou modificar ficheiros. Se forem necessárias alterações, arquive a versão atual e carregue uma nova versão.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Para mais informações, consulte <a href="${readMoreUrl}" target="_blank">esta página</a>.`;
        },
        resolve_conflict: {
          title: "Resolver conflito",
          description:
            'Foram encontrados os seguintes itens. Seleccione "Manter existente" para manter o item existente encontrado na instalação.',
          table_header_name: "Nome",
          table_header_type: "Tipo",
          table_header_action: "Acção",
          action_keep_existing: "Manter existentes",
          action_replace: "Substituir",
          action_done: "Concluído",
        },
        auth: {
          authentication_process_failed: "Falha no processo de autenticação",
          access_denied: "Acesso negado",
          handle_unauthorized: {
            unauthorized: "Não autorizado",
            please_log_in: "Iniciar sessão ou registar-se",
          },
        },
        translations: {
          edit_translations: "Editar traduções",
          edit_translations_info: "Seleccione um idioma da lista, edite e grave",
          edit_translations_default_language_info:
            "<b>Predefinição</b> é mostrado quando o idioma seleccionado pelo utilizador não está disponível",
          edit_content: "Informações do conteúdo",
          edit_support: "Suporte",
          updated_successfully: "Traduções actualizadas com sucesso",
          error_on_update: "Erro ao actualizar traduções",
          default: "Predefinição",
          batch_translations: "Traduções em lote",
          export_import_info:
            "<b>Exportar</b> o template de traduções em lote para o conteúdo da sua escolha, editar o template acrescentando as traduções e voltar a <b>importar</b> o template para guardar várias traduções em simultâneo.",
          import_translations: "Importar",
          export_translations: "Exportar",
          translations_missing: " (vazio)",
          select_content: "Seleccionar traduções a exportar:",
          import_finished: "Importação concluída!",
          select_file: "Seleccionar ficheiro",
          select_file_title: "Seleccionar ficheiro a importar:",
          import_from_file: "Importar",
          import_error_info: "O ficheiro não segue o formato do template:",
          export_selected: "Exportar",
          invalid_file: "Formato do conteúdo do ficheiro não válido",
          import_translations_finished: "Traduções importadas",
          details_too_long: "O conteúdo dos detalhes excede o comprimento máximo permitido",
          import_failed: "Falha ao importar traduções",
          translate_to_other_languages: "Traduzir para outros idiomas",
          import_count: function (count) {
            return "Traduções actualizadas para " + count + " conteúdo";
          },
          invalid_language: function (contentId, language) {
            return "O conteúdo '" + contentId + "' contém o idioma não válido '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "O conteúdo '" + contentId + "' contém o campo de tradução não válido '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "O valor do campo de tradução '" +
              translationField +
              "' do conteúdo '" +
              contentId +
              "' excede o comprimento máximo permitido"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "Modelo 3D",
          "3d_pic_added": "Modelo 3D adicionado com sucesso",
          "3d_pic_updated": "Modelo 3D actualizado com sucesso",
          "3d_pic_removed": "Modelo 3D removido com sucesso",
          "3d_pic_update_failed": "Falha ao actualizar o modelo 3D",
          "3d_pic_remove_failed": "Falha ao remover o modelo 3D",
          "3d_pic_missing":
            "Sem imagem 3D para o conteúdo. Escolha a imagem ao clicar em 'Escolher modelo 3D ' abaixo.",
          "3d_pic_uploading": "O modelo está a carregar. Aguarde...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Clicar aqui para editar o conteúdo",
          updated_successfully: "Actualizado com sucesso",
          update_failed: "Erro na actualização",
          edit_search_criteria: "Editar critérios de pesquisa",
          edit_group_and_category: "Editar grupo e categoria",
          edit_content: "Editar conteúdo",
          edit_thumbnail: "Editar miniatura",
          edit_thumbnail_descriptive: "Dimensões recomendadas: 400 x 300 px",
          edit_description: "Editar descrição",
          edit_download_link: "Adicionar ou editar ligação de transferência do parceiro",
          versions_not_shown_to_users:
            "Como este item de conteúdo possui uma ligação de transferência do parceiro, os utilizadores do Tekla Warehouse não podem ver quaisquer versões adicionadas anteriormente ao item de conteúdo. Os administradores do Tekla Warehouse e os utilizadores com direitos de edição continuam a poder ver todas as versões do item de conteúdo.",
          partner_download_link: "Introduzir o URL de transferência",
          partner_download_link_disclaimer:
            "Se adicionar uma ligação de transferência do parceiro a um item de conteúdo existente, os utilizadores do Tekla Warehouse deixarão de poder ver as versões que adicionou anteriormente ao item de conteúdo. Esta acção não pode ser desfeita.",
          edit_title_or_delete: "Editar título ou apagar",
          edit_title_or_move_delete: "Editar título, mover ou apagar",
          edit_support: "Editar informações de suporte",
          edit_release_notes: "Editar novidades",
          edit_content_wysiwyg: "Editar detalhes do conteúdo",
          edit_content_embed_link: "Mais informações em Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Só é possível inserir vídeos do YouTube, Vimeo ou Alugha.",
          details_unallowed_embed_info: "Só é possível inserir conteúdo dos seguintes domínios: YouTube, Vimeo ou Alugha. Os formatos de URL permitidos são:",
          your_video_id: "ID_DO_VÍDEO",
          edit_content_video_link_error: "Link de vídeo inválido. Pode adicionar vídeos por URL do YouTube e do Vimeo",
          edit_content_vide_code_error: "Formato inserido inválido. Só são aceites iframes. Os fornecedores de vídeo permitidos são o YouTube, o Vimeo e o Alugha.",
          details_max_length_exceeded: "O conteúdo dos Detalhes é demasiado extenso",
          editor_mode_label: "Modo de editor:",
          editor_mode_on: "Ligado",
          editor_mode_off: "Desligado",
          do_not_notify: "Pequena actualização, não notificar subscritores",
          invalid_tag: "Etiqueta não válida",
        },
        transfer_package: {
          header: "Mover conteúdo",
          actions: {
            move: "Mover",
            cancel: "Cancelar",
          },
          loading: "A carregar as colecções...",
          moving: "A mover o conteúdo",
          select_collection: "Seleccionar colecção",
          content_moved: "Conteúdo movido. As alterações poderão ser visíveis após alguns minutos.",
          operation_failed: "A operação falhou",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Conteúdo associado com sucesso a " + name;
          },
          selected_resources_linked: function (name) {
            return "Conteúdo seleccionado associado com sucesso a " + name;
          },
          resources_linked_with_errors: "Conteúdo associado, ocorreram erros em algumas partes do conteúdo",
          resources_linking_failed: "Falha na associação do conteúdo",
          resource_unlinked: "Associação removida com sucesso. As alterações poderão ser visíveis após alguns minutos.",
          resources_unlinked:
            "Associações removidas com sucesso do conteúdo seleccionado. As alterações poderão ser visíveis após alguns minutos.",
          resources_unlinked_with_errors: "Associações removidas, ocorreram erros em algumas partes do conteúdo",
          resources_unlinking_failed: "Falha na remoção das associações",
          linking: "A criar associação",
          linking_failed: "Falha na associação",
          linking_failed_title: "Não foi possível associar o seguinte conteúdo:",
          unlinking_failed_title: "Não foi possível desassociar o seguinte conteúdo:",
          linking_failed_already_contains:
            "O conteúdo já foi associado a uma colecção seleccionada. As actualizações poderão demorar algum tempo a aparecer.",
          linking_failed_already_contains_short: "O conteúdo já foi associado à colecção seleccionada",
          unlinking_failed: "Falha na remoção da associação",
          unlinking_failed_does_not_contain:
            "A associação do conteúdo já foi removida da colecção. As actualizações poderão demorar algum tempo a aparecer.",
          unlinking_failed_does_not_contain_short: "A associação do conteúdo já foi removida da colecção",
        },
        notification_channel: {
          title_success: "sucesso",
          title_notice: "aviso",
          title_info: "informações",
          title_error: "erro",
        },
        maintenance: {
          download_requires_tekla_maintenance: "A transferência necessita do Tekla Maintenance",
          can_access_maintenance: {
            yes: "Sim",
            no: "Não",
          },
        },
        confirm: {
          title: "Confirmar",
          yes: "Sim",
          no: "Não",
          ok: "Ok",
          close: "Fechar",
          dont_show: "Não mostrar isto novamente",
        },
        show_more: " Mostrar mais",
        show_less: " Mostrar menos",
        spinner_loading: "A carregar",
        spinner_processing: "A processar",
        spinner_loading_long_time: "O carregamento do conteúdo pode demorar alguns minutos",
        local_service: {
          info: "O plug-in Tekla Warehouse Service é necessário para criar conteúdo local e de rede. Com o Tekla Structures 20.1 SR1 ou versões mais recentes é possível carregar o conteúdo directamente a partir do modelo.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service</a> " +
            "é necessário para criar conteúdo local e de rede.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service</a> " +
            "não está disponível. O serviço é necessário para activar as colecções locais e de rede.",
          download_to_unlock_all_features_html:
            "Transferir " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service</a> " +
            "para desbloquear todas as funcionalidades.",
          service_out_of_date_html:
            "O plug-in Tekla Warehouse está desactualizado. Actualizar " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service</a> " +
            "para desbloquear todas as funcionalidades.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Seleccionar o idioma",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Convidar outros utilizadores",
          send_invitation_to_email: "Enviar convite para email",
          add_e_mail_addresses: "Adicionar endereços de email",
          and_assign_role: "e atribuir função",
          send_invitation: "Enviar convite",
          invitation_already_sent: "O convite já foi enviado!",
          invitation_sent: "Convite enviado!",
          update_selected_invitations: "Actualizar convites seleccionados",
          new_role: "Nova função",
          invitations_list_updated: "Lista de convites actualizada!",
        },
        item_browser_title: "Seleccionar itens de conteúdo",
        prerequisites: {
          description_placeholder: "Adicionar descrição aqui",
          link_name_placeholder: "Adicionar nome de ligação aqui",
          add_prerequisites_and_recommendations: "Pré-requisitos e recomendações (opcional)",
          list_the_profiles_components_files:
            "Liste os perfis, componentes, ficheiros ou os modelos necessários para que o conteúdo funcione correctamente.",
          link_to_existing_content: "Ligar ao conteúdo existente",
          please_enter_prerequisite_url: "Introduzir o URL de pré-requisito",
          please_enter_valid_prerequisite_url: "Introduzir um URL de pré-requisito válido",
          missing_http: "http:// em falta",
          add_link: "Adicionar ligação",
        },
        treeview: {
          cannot_export_this_container:
            "Não é possível exportar este conteúdo. Em alternativa, seleccione itens individuais.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "O ficheiro tem de ser inferior a " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Tipo de ficheiro não válido.  O ficheiro tem de ter um dos seguintes tipos " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Período de tempo:",
          timeframe_start: "Início:",
          timeframe_end: "Fim:",
          eventtype: "Tipo de evento:",

          downloads_daily_by_version: "Transferências por versão",
          downloads_by_country: "Transferências por país",
          downloads_by_version: "Transferências por versão",
          downloads_by_version_organization: "Transferências por versão e por organização",
          downloads_by_organization: "Transferências por organização",
          downloads_daily_by_package: "Transferências por item de conteúdo",
          views_daily_by_package: "Visualizações por item de conteúdo",
          views_by_country: "Visualizações por país",
          views_by_organization: "Visualizações por organização",

          downloads_by_package: "Transferências por item de conteúdo",
          views_by_package: "Visualizações por item de conteúdo",
          downloads_daily_by_collection: "Transferências por colecção",
          downloads_by_collection: "Transferências por colecção",
          views_daily_by_collection: "Visualizações por colecção",
          views_by_collection: "Visualizações por colecção",

          events_by_organization_func: function (version) {
            return 'Versão "' + version + '" eventos por organização'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Outros",
          sum_all_versions: "Todas as versões",
          sum_all_packages: "Todos os itens de conteúdo",
          sum_all_collections: "Todas as colecções",
          sum_all_organizations: "Todas as organizações",
          recording_started_at: "Gravação de dados iniciada em Junho de 2015",
          recording_view_events_started_at: "Gravação de dados iniciada em Março de 2017",
          version_title_func: function (title) {
            return 'Versão "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title +
              " (" +
              pluralize(count, [count + " transferências", count + " transferência", count + " transferências"]) +
              ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Os números incluem todas as transferências concluídas.",
          counted_events_info_package: "Os números incluem todas as transferências concluídas.",
          export: {
            caption: "Exportar análise",
            select_options: "Seleccionar opções",
            select_timeframe_description: "Seleccione abaixo um período de tempo de onde exportar a análise:",
            select_eventtype_description: "Seleccione o tipo de evento de onde exportar a análise:",
          },
        },
        access_rights: {
          editors: "Editores",
          download_access: "Ver e transferir acesso",
          write_user_id_placeholder: "Escrever ID de utilizador",
          write_organization_id: "Introduza IDs de organização válidos. Introduza cada ID numa linha separada.",
          write_id_placeholder:
            "Introduza IDs de utilizador, endereços de email de utilizadores ou IDs da organização. Introduza cada ID ou endereço de email numa linha separada.",
          id_help_html_short:
            "Por motivos de segurança, os nossos utilizadores não são passíveis de pesquisa.<br/><br/>" +
            "Solicite o ID de utilizador directamente à pessoa a quem pretende dar acesso. <br/>" +
            'O ID de utilizador é apresentado em <a href="#/my/">Os meus detalhes</a>.',
          id_help_html:
            "Por motivos de segurança, os nossos utilizadores não são passíveis de pesquisa.<br/><br/>" +
            "Solicite o ID de utilizador directamente à pessoa a quem pretende dar acesso. Para conceder acesso a todos os utilizadores de uma organização, use o ID da organização.<br/><br/>" +
            'Os IDs de utilizador e da organização são apresentados em <a href="#/my/">Os meus detalhes</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Introduza cada ID ou endereço de email numa linha separada. Pode introduzir " +
              max_rows +
              " linhas de cada vez."
            );
          },
          input_too_long: "O texto que introduziu é demasiado longo.",
          viewer_already_added: function (input) {
            return `${input} já foi adicionado como visualizador`;
          },
          wrong_format: function (input) {
            return `${input} não é um ID ou um endereço de email válido`;
          },
          candidates_for_keyword: function (keyword) {
            return "Foram encontrados utilizadores com o endereço de email ou o ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Adicionar nova",
        add: "Adicionar",
        delete: "Apagar",
        set_as_default: "Miniatura predefinida",
        select_file: "Seleccionar ficheiro",
        choose_option: "Escolher opção",
        info_3D_exists: "Já adicionou um modelo 3D, não pode adicionar mais do que um modelo ao conteúdo.",
        action_options: {
          image: "Imagem",
          "3DModel": "Modelo 3D",
          video: "Vídeo",
        },
        video: {
          url: "URL de vídeo",
          invalid_url: "URL não válido",
        },
      },
      units: {
        bytes: "bytes",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Transferir",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " gostos", number + " gosto", number + " gostos"]);
      },
      main_info: {
        header_title: "O Website do Tekla Warehouse tem um novo aspecto!",
        header_text: "Qual é a sua opinião acerca do novo Website optimizado do Tekla Warehouse?",
        header_text2: "As principais alterações são:",
        bullet_text_1: "Apagar Pesquisa na página de destino.",
        bullet_text_2:
          "Todos os filtros existentes na página Pesquisa situam-se à esquerda e a vista de miniatura predefinida possui mais resultados de pesquisa na mesma vista.",
        bullet_text_3:
          "A opção Transferir na página Conteúdo está localizada num ponto central, para facilitar a utilização do conteúdo. As opções Transferir e Instalar estão agora acessíveis através do botão Transferir.",
        bullet_text_4:
          "A página Colecção e O meu carrinho incluem instruções em 3 passos para utilizar as transferências e instalar tudo.",
      },
      organizations: {
        error_loading_collection_contents: "Erro ao carregar os conteúdos das colecções",
        error_loading_organization: "Erro ao carregar organização",
      },
      downloads_graph: {
        date: "Data",
        total_views: "Total de visualizações",
        total_downloads: "Total de transferências",
        views: "Visualizações",
        downloads: "Transferências",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "O plug-in Tekla Warehouse Service" +
          "</a>" +
          " é necessário.",
        processingFailed: "Falha no processamento",
        forbidden: "Proibido",
        resource_not_found: "Recurso não encontrado",
        no_matches_found: "Não foram encontradas correspondências",
        no_matches_found_for: function (input) {
          return "Não foram encontradas correspondências com o endereço de email ou o ID " + input;
        },
        not_identified: "Não identificado",
        error_while_searching: "Erro ao pesquisar",
        error_user_not_found: "Utilizador ou organização não encontrada",
        error_while_adding_member: "Erro ao adicionar direito de acesso para membro",
        error_while_removing_member: "Erro ao remover direito de acesso para membro",
      },
      offline_to_online: {
        fail_on_initialize: "Erro ao tratar o conteúdo do zip, a anular a conversão",
        fail_on_validate: "Falha na validação dos metadados do conteúdo, a anular conversão",
        fail_on_save: "Ocorreu um erro ao guardar o conteúdo, a reverter as alterações",
        success: "Conteúdo offline convertido com sucesso para conteúdo online",
        validate_errors_title: "Falha ao converter conteúdo",
        processing: "A guardar pacote ",
        processed: "Item processado",
        collection_thumbnail_missing_name: "Falta o 'nome do ficheiro' ao binário da miniatura da colecção",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Falta o 'nome do ficheiro'do binário da miniatura do pacote '" + packageTitle + "'";
        },
        package_value_missing_title_func: function () {
          return "Falta o 'título' no pacote";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Falta o '" + field + "' do pacote '" + packageTitle + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "O campo '" +
            field +
            "' do pacote '" +
            packageTitle +
            "' possui os seguintes valores não válidos: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "O pacote '" + packageTitle + "' possui uma etiqueta não válida: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "Falta o 'título'da versão do pacote '" + packageTitle + "'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Falta o '" + field + "' da versão '" + versionTitle + "' do pacote '" + packageTitle + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "O campo '" +
            field +
            "' da versão '" +
            versionTitle +
            "' do pacote '" +
            packageTitle +
            "' possui os seguintes valores não válidos: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "Falta o 'nome do ficheiro' da versão '" + versionTitle + "' do pacote '" + packageTitle + "'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Importante: Fim do Suporte para Colecções Locais no Tekla Warehouse",
            part1: "A partir de 2 de junho de 2025, as colecções locais deixarão de estar disponíveis no Tekla Warehouse. Os utilizadores não poderão mais criar, visualizar ou pesquisar colecções locais, nem utilizar conteúdo de colecções locais.",
            part2: "Para obter assistência na transição de colecções locais para colecções online, por favor contacte a sua organização de suporte local.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Importante! Como melhoria de segurança, deverá possuir a função de Editor de Conteúdo do Tekla Warehouse para gerir o conteúdo da sua organização após ${date} de novembro de 2024. <br />Solicite ao administrador da sua organização para lhe atribuir a função. Para mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Como melhoria de segurança, deverá possuir a função de Editor de Conteúdo do Tekla Warehouse para gerir o conteúdo da sua organização após ${date} de novembro de 2024. <br />Solicite ao administrador da sua organização para lhe atribuir a função. Para mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Não vê uma opção de edição para a sua organização? Após o dia ${date} de novembro, deverá possuir a função de Editor de Conteúdo do Tekla Warehouse para poder gerir os conteúdos da sua organização. <br />Poderá verificar a sua função em O meu Warehouse > Os meus Detalhes. Para mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Não vê uma opção para criar conteúdos para a sua organização? Após o dia ${date} de novembro, deverá possuir a função de Editor de Conteúdo do Tekla Warehouse para poder gerir os conteúdos da sua organização. <br />Poderá verificar a sua função em O meu Warehouse > Os meus Detalhes. Para mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Após o dia ${date} de novembro, deverá possuir a função de Editor de Conteúdo do Tekla Warehouse para poder gerir os conteúdos da sua organização. Peça ao administrador da sua organização para lhe atribuir a função. <br />Pode verificar quem é o administrador da sua organização no seu perfil Tekla Online . Para mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
          },
          service_plugin_update_info:
            "Está tendo problemas para se conectar ao Tekla Structures? Certifique-se de que está usando o plugin mais recente do <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service</a>.",
        },
        maintenance_info:
          "O Tekla Warehouse está em manutenção entre as 22:00:00 e as 23:00:00 UTC de 27-10-2017. Lamentamos o incómodo.",
        release_notes_info: "Novas funcionalidades do Tekla Warehouse",
        release_notes_link:
          'O Tekla Warehouse foi actualizado, leia <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">o que há de novo</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Necessita de um programador Tekla para o seu projecto?",
        heading2Dev:
          'Consulte o novo directório de Especialistas de programação da Tekla.<br><br>É um local onde programadores talentosos e utilizadores interessados da Tekla podem partilhar experiências.<br><br>Todos os membros do Tekla Partners Program são convidados a adicionar as respectivas informações ao directório de Especialistas.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Encontrar um programador &rsaquo;</a>',
        static_intro:
          "Este é o armazenamento BIM gratuito do Tekla Structures onde pode encontrar, importar, instalar e partilhar conteúdo interna e globalmente. O objectivo do Tekla Warehouse é produzir modelos de alta qualidade de maneira eficaz.",
        new_intro:
          'A Tekla Corporation chama-se agora Trimble. Continuamos a desenvolver soluções de software Tekla e as mesmas pessoas encontram-se disponíveis para si. Saber mais: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Confirme a sua conta",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Carregamentos mais recentes &rsaquo;",
          popular: "Conteúdo popular &rsaquo;",
          recommended: "Recomendado &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Informações do produto &rsaquo;</a>',
          item_title: "Novas informações do produto!",
          item_description:
            'Texto relacionado com informações do produto.<br><a href="https://developer.tekla.com" target="_blank">Ler mais &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse em números",
          partners: "Parceiros",
          partners_count: "352",
          collections: "Colecções",
          collections_count: "793",
          items: "Itens de conteúdo",
          items_count: "2893",
        },
        partners: "Os nossos parceiros",
        action_links: {
          search_title: "Procurar conteúdo?",
          upload_title: "Carregar conteúdo?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Vídeos &rsaquo;</a>',
          link: 'Visite o nosso <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">canal do YouTube</a> para ver mais vídeos.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Consulte o seu email para verificar se recebeu a ligação de confirmação, ou <u><a href="' +
            settingsUrl +
            '">solicite uma nova ligação</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Confirme o seu endereço de email e aceite os termos e condições e a política de privacidade",
        acceptTerms: "Aceite os termos e condições e a política de privacidade",
        acceptUpdatedTerms:
          "Aprove os termos e condições e a política de privacidade actualizados dos tekla online services",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Voltar ao seu <u><a href="' + settingsUrl + '">perfil</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Reveja e aceite os Termos e condições e a Política de privacidade no seu <u><a href="' +
            settingsUrl +
            '">perfil</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Procurar nas colecções &rsaquo;",
          new: "Novo",
          popular: "Popular",
          recommended: "Recomendado",
          organizations: "Organizações",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Seleccionar pasta",
        selectFolderInstructions: "As pastas locais e de rede podem ser utilizadas como colecções.",
        selectedFolder: "Pasta seleccionada (copiar e colar a localização):",
        selectedFolderPlaceholder: "Por exemplo: C:\\pasta, Z:\\pasta de rede, \\\\nome-da-máquina\\pasta",
        collectionCreation: {
          name: "Nome",
          createNewCollection: "Criar colecção local",
          pleaseInputTitle: "Introduza um título",
          invalidPath: "O caminho não é válido",
          access_denied: "Falha ao adicionar colecção: acesso negado",
          unsafe_location: "Falha ao adicionar colecção: local inseguro",
          add_collection_failed: "Falha ao adicionar colecção",
          minLengthError: "O título tem de ter pelo menos 3 caracteres",
          maxLengthError: "O título não pode exceder os 20 caracteres",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "As pastas locais e de rede podem ser utilizadas como colecções.",
        collectionCreation: {
          name: "Nome",
          createNewCollection: "Criar colecção online",
          pleaseInputTitle: "Introduzir um título",
          minLengthError: "O título tem de ter pelo menos 3 caracteres",
          maxLengthError: "O título não pode exceder os 20 caracteres",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Recurso guardado",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Consegue ver o potencial?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Dê-nos feedback</span>',
          help_us_improve_html:
            'Ajude-nos a melhorar o Tekla Warehouse! <br/><a id="showSurvey">Clicar aqui</a> ou <a id="dontShow">não mostrar isto novamente</a>',
          dialog: {
            do_you_see_potential: "Consegue ver o potencial?",
            help_us_improve_html: "Ajude-nos a melhorar o Tekla Warehouse! A sua opinião é importante para nós.",
            continue_to_survey: "Iniciar o inquérito",
            exit_to_warehouse: "Não, obrigado",
          },
        },
      },
      search: {
        bar: {
          search: "Procurar",
          filter: " ",
          more_filters: "Mais filtros",
          hide_filters: "Ocultar filtros",
          clear: "Apagar",
          exclude: "Excluir",
          include: "Incluir",
          clear_filters: "Restabelecer filtros",
          load_defaults: "Usar as minhas preferências &rsaquo;",
          save_defaults: "Guardar como as minhas preferências &rsaquo;",
          defaults_saved_successfully: "Filtros predefinidos guardados",
          edit_defaults: "Editar predefinições",
        },
        info: {
          searching_organizations: "A pesquisar em organizações...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Não existem resultados", amountTotal + " resultados"]);
            } else {
              return (
                pluralize(amountTotal, ["Não existem resultados", amountTotal + " resultados"]) +
                ' para "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " resultados";
            } else if (!searchTerm) {
              return "Introduzir um termo de pesquisa para ver resultados da organização";
            } else if (searchTerm.length < 2) {
              return "Fornecer pelo menos dois caracteres para pesquisa da organização";
            } else {
              return (
                pluralize(amountTotal, ["Não existem resultados", amountTotal + " resultados"]) +
                ' para "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " resultados no conteúdo online",
          local: " resultados no conteúdo local",
          organization: "resultados em organizações",
          plugin_missing_info:
            'O conteúdo local só é mostrado se tiver <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "O plug-in Tekla Warehouse Service" +
            "</a>" +
            " instalado.",
          version_filter_info: "O filtro da versão de software não é válido em resultados do conteúdo local",
        },
        exclude_env_files: "Excluir ficheiros de ambiente",
        sidebar: {
          filters: {
            measurementUnits: "Unidades de medida",
            locations: "Localizações de projecto",
            languages: "Idiomas",
          },
          placeholders: {
            use_categories: "Categoria",
            measurement_units: "Unidades de medida",
            languages: "Idiomas",
            locations: "Localizações",
          },
        },
      },
      placeholders: {
        name: "Nome...",
        contentOwner: "Nome do proprietário do conteúdo...",
        useCategory: "Seleccionar utilização",
        selectType: "Seleccionar tipo",
        filter: "Filtro...",
        search: "Pesquisar...",
        choose: "Escolher...",
        searchFilters: "Filtros de pesquisa...",
        selectContentItem: "Seleccionar item de conteúdo...",
        search_content: "Conteúdo de pesquisa...",
        includeAs: "Incluir como...",
        selectCollection: "Seleccionar colecção",
        selectUserGroup: "Seleccionar grupo de utilizadores",
        online_collections_tooltip: "Clique para pesquisar conteúdo online",
        local_collections_tooltip:
          "Clique para pesquisar conteúdo local e de rede e conteúdo incluído com a instalação do Tekla Structures",
        organizations_tooltip: "Clique para procurar organizações",
        "filter-options": {
          products: "Todos os produtos de software",
          locations: "Todas as localizações",
          languages: "Todos os idiomas",
          measurementUnits: "Todas as unidades de medida",
          testedVersions: "Todas as versões de software",
          useCategories: "Todas as estruturas de categorias",
          useCategoriesCombined: "Categoria",
          allGroups: "Todos os grupos",
          itemTypeCategories: "Todos os tipos de item",
        },
        "exclude-filter-options": {
          products: "Excluir produtos de software",
          locations: "Excluir localizações",
          languages: "Excluir idiomas",
          measurementUnits: "Excluir unidades de medida",
          testedVersions: "Excluir versões de software",
          useCategories: "Excluir categorias",
          useCategoriesCombined: "Excluir Categoria",
          allGroups: "Excluir grupos",
          itemTypeCategories: "Excluir tipos de item",
        },
      },
      link: {
        link_selected: "Associar a selecção",
        unlink_selected: "Desassociar a selecção",
      },
      download_install: {
        choose_version: "Escolha a sua versão de software:",
        choose_content: "Escolha os itens de conteúdo da lista indicada abaixo:",
        choose_action: "Escolha a acção para os itens seleccionados:",
      },
      download: {
        copy_to_clipboard: "Copiar para a área de transferência",
        download_selected: "Transferir",
        add_selected_items_to_basket: "Adicionar selecção a O meu carrinho",
        add_item_to_basket: "Adicionar selecção a O meu carrinho",
        add_all_items_to_basket: "Adicionar tudo ao carrinho",
        add_all_items_to_basket_info: "Existem mais de 100 itens. O carregamento pode demorar algum tempo",
        add_all_items_to_basket_too_many_results: "Não é possível adicionar mais de 1000 itens",
        cart_loading: "Existem mais de 100 itens. O carregamento pode demorar algum tempo",
        remove_item_from_basket: "Remover do carrinho",
        shopping_cart_no_content: "Não existe conteúdo no carrinho",
        shopping_cart_no_content_for_version: "Não existe conteúdo no carrinho para a versão seleccionada",
        shopping_cart_items_func: function (itemsCount) {
          return "O meu carrinho (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Transferir instruções",
          step_one: "Copie as informações de transferência para a sua área de transferência com este botão",
          step_two_func: function (downloadAppUrl) {
            return (
              "Execute a aplicação Tekla Warehouse Downloader. Pode transferir a aplicação " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">aqui.</a> '
            );
          },
          step_three: "A aplicação Tekla Warehouse Downloader orientará o resto da transferência.",
          show_data_title: "Não funcionou?",
          show_data_to_be_copied: "Copiar manualmente",
          hide_data_to_be_copied: "Ocultar dados de transferência",
        },
      },
      install: {
        install_title: " itens serão instalados utilizando o plug-in Tekla Warehouse.",
        failed_install_information:
          "Seleccionou conteúdo que só está disponível para transferência. Este conteúdo não será instalado agora. Transfira este conteúdo separadamente do Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Instalar: verifique se o plug-in Tekla Warehouse Service está a funcionar correctamente",
        verify_ts_connection: "Instalar: verifique se o Tekla Structures está a funcionar",
        select_software_version: "Filtrar por versão de software para associar, transferir ou instalar",
        download_requires_maintenance: "O acesso a alguns itens abaixo necessita do Tekla Maintenance",
        install_selected: "Instalar",
        start_install: "Iniciar a instalação",
        install_started: "A instalação teve início",
        select_ts_version: "Seleccionar versão do TS",
        install_retry: "Inicie novamente a partir do Tekla Warehouse se for necessário voltar a tentar",
        failed_installs_info_func: function (failedCount) {
          return "Conteúdo que não será instalado (" + failedCount + " versões):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Instalação para " + totalInstallsCount + " itens seleccionados";
        },
      },
      helpers: {
        behalf: "Por",
        sortBy: "Ordenar por:",
        filter: "Resultados do filtro",
        showAsThumbs: "Mostrar como miniaturas",
        showAsList: "Mostrar como lista",
        resultsPerPage: "Mostrar por página:",
        filter_by_version: "Filtrar por versão:",
        show_more_options: "Mais opções",
        show_less_options: "Menos opções",
        filter_by_type: "Filtrar por tipo:",
        select_all: "Seleccionar tudo",
        show_content_items: "Mostrar itens de conteúdo",
        choose_action: "Escolha a acção para o conteúdo seleccionado: ",
        show_collections: "Mostrar colecções",
        search: {
          show: "Mostrar:",
          show_content_items: "Itens de conteúdo",
          show_collections: "Colecções",
          show_organizations: "Organizações",
          show_online_content: "Conteúdo online",
          show_offline_content: "Conteúdo offline",
          local_content_info:
            'O conteúdo local só é mostrado se tiver <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "o plug-in Tekla Warehouse Service" +
            "</a></b>" +
            " instalado.",
        },
        tekla_maintenance_required: "O acesso a alguns itens abaixo necessita do Tekla Maintenance",
        show_content_alerts: "Mostrar alertas de conteúdo ",
        show_collection_alerts: "Mostrar alertas de colecção ",
      },
      actions: {
        load_more: "Carregar mais",
        use_infitinite_scroll: "Usar deslocamento infinito",
        back: "Atrás",
        show_download_dialog: "Transferir",
        download: "Apenas transferir",
        download_on_partner_site: "Transferir no site do parceiro",
        insert: "Instalar",
        install: "Instalar",
        downloadAndInstall: "Instalar",
        createNew: "Criar novo",
        viewItems: "Vista",
        viewCollection: "Ver colecção",
        viewGroup: "Mostrar grupo",
        joinGroup: "Aderir ao grupo",
        loginToDownload: "Iniciar sessão para transferir",
        show3DPic: "Mostrar o modelo 3D",
        remove: "Remover",
        browse: "Procurar",
        browseFolder: "Procurar",
        browseFileSystem: "Procurar",
        add: "Adicionar",
        save: "Guardar",
        batch_edit: "Editar em lote",
        translations: "Traduções",
        cancel: "Cancelar",
        continue: "Continuar",
        delete: "Apagar",
        addNetworkPath: "Adicionar localização de rede",
        select: "Seleccionar",
        create: "Criar",
        update: "Actualizar",
        choose_thumbnail: "Escolher miniatura",
        choose_picture: "Escolher imagem",
        remove_picture: "Apagar imagem",
        choose_3D_picture: "Escolher modelo 3D",
        remove_3D_picture: "Remover modelo 3D",
        like: "Gosto",
        unlike: "Não gosto",
        close: "Fechar",
        export: "Exportar",
        add_or_remove: "Adicionar/remover",
        select_content_to_convert: "Seleccionar conteúdo offline",
        search: "Procurar",
        upload: "Carregar",
        edit: "Editar",
        view_3d: "Clique para ver o modelo...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "beta",
        search: "Procurar",
        profile: "Os meus detalhes",
        myWarehouse: "O meu Warehouse",
        myStuff: "Os meus itens",
        logout: "Terminar sessão",
        login: "Iniciar sessão",
        register: "Registar",
        upload: "Carregar",
        uploading: "Carregar",
        dashboard: "Painel de controlo",
        learnMore: "Saber mais",
        about: "Acerca",
        shoppingCart: "O meu carrinho",
        addToCartInfo:
          "Pode adicionar itens em O meu carrinho e transferi-los ou instalá-los na totalidade e em simultâneo. A transferência é totalmente gratuita",
      },
      cubeNavigation: {
        headings: {
          get_started: "COMEÇAR A UTILIZAR",
          learn: "APRENDER",
          get_help: "OBTER AJUDA",
          extend_your_tekla: "PROLONGAR TEKLA",
          collaborate: "COLABORAR",
          students_and_educators: "Students and educators",
          about: "ACERCA",
        },
        "tekla-online": "Serviços online",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Website da Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Extranet da Tekla",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "O meu perfil",
        discussionForum: "Fórum de Discussão",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "PÁGINA INICIAL DOS SERVIÇOS TEKLA",
        register: "Registar",
        login: "Iniciar sessão",
        evaluate: "Avaliar",
        buy: "Comprar",
        download: "Transferir",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Produtos",
        teklaServices: "Serviços",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "As minhas colecções",
        myLikes: "Os meus gostos",
        profile: "Os meus detalhes",
        preferences: "As minhas preferências",
        myUserGroups: "Os meus grupos de utilizadores",
        localCollections: "Colecções locais e de rede",
        tsCollections: "Colecções do Tekla Structures",
        downloads: "Transferências",
        selectContent: "Seleccionar ficheiros de conteúdo",
        addMetadata: "Adicionar metadados",
        onlineCollections: "Colecções online",
        userGroups: "Grupos de utilizadores",
        createNew: "Adicionar conteúdo",
        notifications: "Notificações",
        contentUsage: "Utilização do conteúdo",
        browseCollections: "Procurar nas colecções",
        admin: "Administrador",
        sales: "Vendas",
        shoppingCart: "O meu carrinho",
      },
      notifications: {
        title: "notificação",
        invitedBy: "Convidado por",
        invitationType: "Tipo de convite",
        invitedTo: "Convidado para",
        createdAt: "Criado em",
        action: "Acção",
        acceptInvitation: "Aceitar",
      },
      batch_edit: {
        selectActionType: "Seleccionar tipo de acção",
        edit_content: "Editar metadados do conteúdo",
        content_title: "Conteúdo",
        metadataToChange: "Metadados a alterar",
        selectContent: "Para o conteúdo",
        select_action: "Seleccionar acção",
        support: {
          support: "Suporte",
          licenses: "Licenças",
        },
        versions: {
          version: "Versão",
          prerequisities: "Pré-requisitos e requisitos",
        },
        content: {
          select_collection: "Seleccionar colecção",
          show_collection: "Mostrar:",
          show_all_content: "Todas as colecções",
          show_organization_content: "As colecções da minha organização",
          select_all: "Seleccionar tudo",
          select_all_info: "(Esta operação pode demorar algum tempo)",
          actionOptions: {
            add: "Adicionar",
            remove: "Remover",
            replace: "Substituir por",
            yes: "Sim",
            no: "não",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Nenhum atributo seleccionado",
            invalid_attributes: "Valores de atributo inválidos",
            invalid_actions: "Acções inválidas",
            content_not_selected: "Nenhum conteúdo seleccionado",
            entities_not_selected:
              "Os dados relacionados com o pacote foram fornecidos, mas não existem pacotes seleccionados",
            versions_not_selected:
              "Os dados relacionados com a versão foram fornecidos, mas não existem versões seleccionadas",
          },
        },
        update: {
          info_processing: "Mantenha esta janela aberta até todos os itens terem sido processados.",
          info_processed: "Todos os itens já foram processados.",
          processing: "A processar item",
          error_info: "Ocorreu um problema ao actualizar os seguintes itens:",
          package_failure_func: function (package_title, errorMsg) {
            return "Conteúdo: " + package_title + ", erro: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Conteúdo: " + package_title + ", versão: " + version_title + ", erro: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " itens serão actualizados. Pretende continuar?";
          },
        },
      },
      myUserGroups: {
        title: "Grupos de utilizadores",
        createdByPrefix: "Por:",
      },
      downloads: {
        title: "Transferências",
      },
      myCollections: {
        new_collection: "Nova colecção",
        my_collections: "As minhas colecções",
        created_by_me: "Criadas por mim",
        local_collections: "Colecções locais e de rede",
        online_collections: "Colecções online",
        created_by_my_organization: "Criadas pela minha organização",
        created_by_my_organizations: "Criado pelas minhas organizações",
        shared_with_me: "Partilhadas comigo",
        shared_with_me_or_organization: "Partilhadas comigo ou com a minha organização",
        notification: {
          error: "Erro",
          error_loading_online_collections: "Erro ao carregar colecções online",
          error_loading_packages_liked_by_me: "Erro ao carregar conteúdo no qual efectuei gostos",
          error_loading_my_organization_s_collections: "Erro ao carregar as colecções da minha organização",
          error_loading_my_shared_collections: "Erro ao carregar as minhas colecções partilhadas",
        },
        local: {
          helpText1: "Para aceder às colecções locais, instale ",
          helpText2: "o plug-in Tekla Warehouse Service",
          helpText3: ".",
          title: "Colecções locais e de rede",
          notFound: "Ainda não foram encontradas colecções locais.",
          myCollectionsTitle: "As minhas colecções",
          actions: {
            createNew: "Criar colecção local ou de rede",
          },
        },
      },
      organization: {
        news_feed: "Feed de notícias",
        add_comment: "Adicionar texto do feed",
        edit_comment: "Editar item de notícias",
        subTabs: {
          collections: "Colecções",
          analytics: "Análise",
          contact_and_support: "Contacto",
          news_feed: "Feed de notícias",
        },
        edit: {
          edit_title: "Editar título",
          edit_description: "Editar descrição",
          edit_contact: "Editar informações de contacto",
          edit_logo: "Editar logótipo",
          edit_url: "Editar url",
          edit_email: "Editar email",
          edit_phonenumber: "Editar número de telefone",
          edit_warehouse_url: "Editar url do Warehouse",
          edit_warehouse_url_subtitle:
            "Caracteres alfabéticos, sem caracteres escandinavos, números ou caracteres especiais",
          edit_url_subtitle: "Inserir url completo",
          edit_logo_descriptive: "Tamanho de imagem optimizado de aproximadamente 350 * 200 píxeis",
          edit_facebook_url: "Editar url do Facebook",
          edit_twitter_url: "Editar url do Twitter",
          edit_linkedin_url: "Editar url do LinkedIn",
          edit_youtube_url: "Editar url do YouTube",
          edit_googleplus_url: "Editar url do Google+",
          logo_updated: "Logótipo actualizado",
          logo_update_failed: "Falha na actualização do logótipo",
          logo_remove_failed: "Falha na remoção do logótipo",
          logo_removed: "Logótipo removido com sucesso",
          updated_successfully: "Actualizado com sucesso",
          updated_failed: "Falha na actualização",
          add_comment: "Adicionar novo",
          edit_comment: "Editar item de notícias",
          comment_added: "Item do feed de notícias adicionado com sucesso",
          comment_updated: "Item do feed de notícias actualizado com sucesso",
          comment_deleted: "Item do feed de notícias apagado com sucesso",
          comment_add_failed: "Falha ao adicionar feed de notícias",
          comment_update_failed: "Falha ao actualizar item do feed de notícias",
          comment_delete_failed: "Falha ao apagar item do feed de notícias",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Procurar conteúdo online por " + name + " &rsaquo;";
        },
        contact_information: "Contacte-nos",
        website: "Website &rsaquo;",
        show_newer_news: "Mais recente",
        show_older_news: "Mais antigo",
      },
      online_collections: {
        new_collection: "Nova colecção",
        online_collections: "Colecções online",
        created_by_organizations: "Criada(s) por organizações",
        created_by_users: "Criada(s) por utilizadores",
      },
      local_collections: {
        tab_title: "Conteúdo",
        local_collections: "Colecções locais",
      },
      versions: {
        add_tool: "Adicionar aplicação",
        table_header: {
          name: "Nome",
          type: "Tipo",
          platform: "Plataforma",
          modified_at: "Modificado em",
          size: "Dimensão",
          virus_scan: "Detecção de vírus",
        },
        // placeholders
        select_file_type: "Seleccionar tipo de ficheiro",
        select_platform: "Seleccionar plataforma",
        select_unit: "Seleccionar unidade",
        select_quality: "Seleccionar qualidade",
        what_will_be_added: "O que pretende adicionar?",
        select_files_from_your_disk: "Seleccionar ficheiros do disco",
        add: "Adicionar",
        //content management
        add_application: "Adicionar aplicação",
        select_content_from_tekla_structures: "Seleccionar conteúdo do Tekla Structures",
        or: "ou",
        files: "Ficheiros",
        //version metadata
        version_information: "Informações da versão",
        version_display_name: "Nome da versão no ecrã",
        version_number: "Número da versão",
        version_number_info: "Introduza um número de versão. Não é possível alterar o número da versão depois de este ter sido guardado.",
        existing_versions: "Números de versão existentes para este conteúdo",
        version_number_validation: {
          missing: "É necessário introduzir um número de versão",
          invalid: "O número da versão deve seguir as regras semânticas de versão (MAJOR.MINOR.PATCH)",
          already_in_use: "Este número de versão já está a ser utilizado para este conteúdo.",
        },
        select_update_type: "Para gerar um número de versão, seleccione primeiro o tipo de actualização",
        please_enter_a_version_name_or_number: "Introduza um nome ou um número da versão.",
        virus_scan: {
          soft_failed: "Falha",
          hard_failed: "Detecção não efetuada",
          infected: "Infectado",
        },
      },
      collections: {
        by: "Por:",
        edit: {
          edit_collection: "Editar colecção",
          edit_description: "Editar descrição",
        },
        notification: {
          collection_updated: "Colecção actualizada",
          error: "Erro",
          error_loading_other_users_collections: "Erro ao carregar colecções de outros utilizadores",
          error_loading_other_organizations_collections: "Erro ao carregar colecções de outras organizações",
          error_loading_collection_contents: "Erro ao carregar o conteúdo das colecções",
          members_list_updated: "Lista de membros actualizada!",
          invitations_removed: "Convites removidos!",
          collection_removed: "Colecção removida",
          thumbnail_deleted: "Miniatura apagada",
          wrong_file_type: "Tipo de ficheiro errado.",
          invalid_file_type: "Tipo de ficheiro não válido.",
          selected_image_is_too_big: "A imagem seleccionada é demasiado grande.",
          thumbnail_updated: "Miniatura actualizada",
          default_thumbnail_updated: "Miniatura predefinida actualizada",
          default_thumbnail_update_failed: "Erro na actualização da miniatura predefinida",
          thumbnail_added: "Miniatura adicionada",
          thumbnail_update_failed: "Erro na actualização de miniaturas",
          video_added: "Vídeo adicionado",
          video_adding_failed: "Falha ao adicionar vídeo",
          video_deleted: "Vídeo apagado",
          video_deleting_failed: "Falha ao apagar vídeo",
        },
        confirm: {
          delete_all: function (title) {
            return 'Apagar a colecção e todo o conteúdo na colecção "' + title + '"?';
          },
          remove_members: "Tem a certeza?",
          remove_invitations: "Tem a certeza?",
        },
        useNetworkDrive: "Utilizar unidade ou pasta de rede",
        createCollection: "Criar colecção online",
        createCollectionInstructions: "Criar uma colecção para agrupar o conteúdo.",

        actions: {
          delete_thumbnail: "Apagar miniatura",
          edit_collection: "Editar colecção",
          delete_collection: "Apagar colecção",
          add_content: "Adicionar conteúdo",
        },
        subTabs: {
          content: "Conteúdo",
          members: "Mostrar membros",
          analytics: "Análise",
          manage_access: "Gerir acesso",
        },

        members: {
          add_user_groups: "Adicionar grupos de utilizadores",
          select_user_group: "Seleccionar grupo de utilizadores",
          with_role: "com a função",
          user_groups_added: "Grupos de utilizadores adicionados!",
          members_list_updated: "Lista de membros actualizada!",
          update_selected_members: "Actualizar membros seleccionados",
          table_header: {
            member_type: "Tipo de membro",
            member_name: "Nome do membro",
            email: "Email",
            role: "Função",
            joined_at: "Aderiu em",
            invited_at: "Convidado em",
          },
          new_role: "Nova função",
        },
        member_list: {
          users_who_can_access_this_collection: "Utilizadores que podem aceder a esta colecção",
          add_user_group: "Adicionar grupo de utilizadores",
          update_members: "Actualizar membros",
          remove_members: "Remover membros",
          pending_invitations: "Convites pendentes",
          user: "utilizador",
          invite_member: "Convidar membro",
          update_invitations: "Actualizar convites",
          remove_invitations: "Remover convites",
        },
        labels: {
          select_organization: "Seleccionar organização",
          select_visibility: "Seleccionar visibilidade",
          title: "Nome",
          name: "Nome",
          createAs: "Criar como: ",
          asMyself: "O próprio",
          asUserGroup: "Grupo de utilizadores",
          asOrganization: "Organização",
          selectOwner: "Seleccionar organização para proprietário do conteúdo",
          visibility: "Visibilidade",
          visibilityOptions: {
            public: "Público",
            private: "Privado",
          },
          description: "Descrição",
        },
        new_collection: {
          please_input_a_username: "Introduzir um nome de utilizador",
          title_is_required_to_be_at_least_3_characters: "O título tem de ter pelo menos 3 caracteres",
          title_cannot_be_longer_than_20_characters: "O título não pode exceder os 20 caracteres",
        },
        collection_list: {
          no_content: "Não existem colecções.",
          results_summary: function (number) {
            return "" + number + "  colecção " + pluralize(number, ["itens", "item", "itens"]);
          },
        },
        entity_list: {
          no_content: "Não existe conteúdo na colecção.",
          results_summary: function (number) {
            return "" + number + " conteúdo " + pluralize(number, ["itens", "item", "itens"]);
          },
          linked_content: "Conteúdo associado",
          unlink: "Desassociar conteúdo",
        },
      },
      alerts: {
        no_updates: "Não actualizado recentemente",
        notifications_marked_as_read: "Notificação marcada como lida",
        cleared_alerts: "Todas as notificações limpas",
        alert_by_email:
          "Enviar-me um email quando qualquer conteúdo listado abaixo for actualizado com uma nova versão ou uma colecção for actualizada com novo conteúdo",
        action_types: {
          create: "foi criado",
          update: "foi editado",
          delete: "foi apagado",
          added_entity: "versão foi adicionada",
          removed_entity: "versão foi removida",
          added_collection: "conteúdo foi adicionado",
          removed_collection: "conteúdo foi removido",
        },
        property_names: {
          description: "Detalhes",
          searchCriteria: "Informações dos critérios de pesquisa",
          groupCategory: "Informações do grupo e da categoria",
          support: "Informações de apoio",
          licenses: "Informações de licenças",
          relatedContent: "Informações de conteúdo relacionadas",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Actualizado " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Preferências de notificação actualizadas com êxito",
          preferences_update_failed: "Ocorreu um erro ao actualizar as preferências de notificação",
        },
      },
      browseDialog: {
        useNetworkDrive: "Utilizar unidade ou pasta de rede",
        ownerGroups: {
          title: "Grupos de proprietários",
        },
      },
      itemTypeCategories: {
        all: "Todos os grupos",
        application: "Aplicações",
        "3dProduct": "Produtos 3D",
        customComponent: "Componentes personalizados",
        tool: "Aplicações",
        profile: "Perfis",
        material: "Materiais",
        bolt: "Parafusos",
        rebar: "Armaduras",
        reportTemplate: "Templates de listas",
        drawingSetup: "Ficheiros de configuração de desenhos",
        modelSetup: "Ficheiros de configuração de modelos",
        environmentFile: "Ficheiros de ambientes",
        shape: "Formas",
        //'shapeCatalog': 'Catálogo de formas',
        //'foreignGeometryDefinition': 'Definição de geometria externa'
      },
      platforms: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: "Independente da plataforma",
      },
      platformsDownload: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: " ",
      },
      contentItemSource: {
        other: "Geral - para pasta do modelo",
        run: "Executar ferramenta",
        bolt: "Conjunto de parafusos",
        component: "Componente",
        drawing: "Definição do desenho (BETA)",
        material: "Material",
        profile: "Perfil",
        mesh: "Malha de armaduras",
        rebar: "Armadura",
        shape: "Forma",
        geometry: "Geometria",
        modeltemplate: "Template do modelo",
        cloningtemplate: "Template de clonagem",
        noaction: "Nenhuma acção",
        releasenotes: "Novidades",
      },
      contentItemHeader: {
        bolt: "Conjuntos de parafusos",
        component: "Componentes",
        drawing: "Definições do desenho (BETA)",
        material: "Materiais",
        profile: "Perfis",
        mesh: "Malhas de reforço",
        rebar: "Barras de reforço",
        shape: "Formas",
      },
      useCategoryGroups: {
        workflow: "Fluxo de trabalho",
        structures: "Estruturas",
        drawings: "Desenhos",
        modeling: "Modelação",
      },
      useCategories: {
        title: "Utilizado em/para",
        conceptualDesign: "Design conceptual",
        designEngineeringAnalysis: "Design / Engenharia / Análise",
        detailing: "Detalhes",
        fabrication: "Fabrico",
        erectionInstallationPour: "Montagem / Instalação / Betonagem",
        contracting: "Empreitadas",
        scheduling: "Agendamento",
        takeOffEstimation: "Início / Estimativa",
        concreteStructures: "Betão moldado in situ",
        precastConcrete: "Betão pré-fabricado",
        offshore: "Balanço",
        steelStructures: "Estruturas de aço",
        timberStructures: "Estruturas de madeira",
        reinforcement: "Reforço",
        drawingCreation: "Criação de desenhos",
        drawingManagement: "Gestão de desenhos",
        drawingEditing: "Edição de desenhos",
        drawingAnnotation: "Anotação de desenhos",
        viewSimulateNavigate: "Visualizar, simular, navegar",
        compareReviewManageData: "Comparar, rever, gerir dados",
        softwareCollaboration: "Colaboração de software",
        productivity: "Produtividade",
        interoperability: "Interoperabilidade",
        systemSetup: "Configuração do sistema",
      },
      measurementUnits: {
        "0": "Métrico",
        "1": "Imperial",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Abrir BIM (IFC)",
        standalone: "Autónomo",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Sem versão específica",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "Experimental",
        candidate: "Candidate",
        approved: "Aprovado",
        standard: "Norma",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Inglês",
        "zh-Hans": "Chinês simplificado",
        "zh-Hant": "Chinês tradicional",
        cs: "Checo",
        da: "Dinamarquês",
        nl: "Holandês",
        fi: "Finlandês",
        fr: "Francês",
        de: "Alemão",
        hu: "Húngaro",
        it: "Italiano",
        ja: "Japonês",
        ko: "Coreano",
        pl: "Polaco",
        pt: "Português",
        "pt-br": "Português do Brasil",
        ru: "Russo",
        es: "Espanhol",
        sv: "Sueco",
        no: "Norueguês",
        other: "Outro",
      },
      isoLangs: {
        ab: {
          name: "Abecásio",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikaans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Albanês",
          nativeName: "Shqip",
        },
        am: {
          name: "Amárico",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Árabe",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonês",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Arménio",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Assamês",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avárico",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avéstico",
          nativeName: "avesta",
        },
        ay: {
          name: "Aimará",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azerbaijano",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Basquir",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Basco",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Bielorrusso",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengali",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Biari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislamá",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bósnio",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Bretão",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Búlgaro",
          nativeName: "български език",
        },
        my: {
          name: "Birmanês",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Catalão; Valenciano",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Checheno",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa; Chewa; Nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chinês, simplificado e chinês",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Chinês simplificado",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Chinês tradicional",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Chinês de Taiwan",
          nativeName: "國語",
        },
        cv: {
          name: "Chuvache",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Córnico",
          nativeName: "Kernewek",
        },
        co: {
          name: "Corso",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Croata",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Checo",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Dinamarquês",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi; maldivense;",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Holandês",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Inglês",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Estoniano",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Feroês",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fijiano",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finlandês",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Francês",
          nativeName: "Français",
        },
        ff: {
          name: "Fula; fulani",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Galego",
          nativeName: "Galego",
        },
        ka: {
          name: "Georgiano",
          nativeName: "ქართული",
        },
        de: {
          name: "Alemão",
          nativeName: "Deutsch",
        },
        el: {
          name: "Grego, Moderno",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guarani",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Guzerate",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haitiano; crioulo do Haiti",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Hebreu (moderno)",
          nativeName: "עברית",
        },
        hz: {
          name: "Hereró",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Húngaro",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlíngua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonésio",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingue",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Irlandês",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Ibo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaque",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Islandês",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italiano",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japonês",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Javanês",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, Gronelandês",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Canarês",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Caxemira",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Cazaque",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Cambojano",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyo",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Ruanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Quirguiz",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kikongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Coreano",
          nativeName: "한국어",
        },
        ku: {
          name: "Curdo",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Cuanhama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latim",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxemburguês",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburguês",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Laociano",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Lituano",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-Katanga",
          nativeName: "",
        },
        lv: {
          name: "Letão",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macedónio",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Malgaxe",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Malaio",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malaiala",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltês",
          nativeName: "Malti",
        },
        mi: {
          name: "Maori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Marati",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshalês",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongol",
          nativeName: "монгол",
        },
        na: {
          name: "Nauruano",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo, Navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Dano-Norueguês",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Ndebele Setentrional",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepalês",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Novo Norueguês",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Norueguês",
          nativeName: "Norsk",
        },
        ii: {
          name: "Nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Ndebele do Sul",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Occitano",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibua",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Antigo Eslavo Eclesiástico, Eslavo Eclesiástico, Antigo Búlgaro, Antigo Eslavónico",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriá",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Osseta",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pali",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Persa",
          nativeName: "فارسی",
        },
        pl: {
          name: "Polaco",
          nativeName: "polski",
        },
        ps: {
          name: "Pashtu",
          nativeName: "پښتو",
        },
        pt: {
          name: "Português",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Português do Brasil",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quéchua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Romanche",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Rundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Romeno, Moldavo",
          nativeName: "română",
        },
        ru: {
          name: "Russo",
          nativeName: "русский",
        },
        sa: {
          name: "Sânscrito",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sardo",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Sami Setentrional",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoano",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Sérvio",
          nativeName: "српски језик",
        },
        gd: {
          name: "Gaélico Escocês; Gaélico",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Chona",
          nativeName: "chiShona",
        },
        si: {
          name: "Cingalês",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Eslovaco",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Esloveno",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somali",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Soto do Sul",
          nativeName: "Sesotho",
        },
        es: {
          name: "Espanhol", //      'name':'Tradicional/Espanhol',
          nativeName: "español",
        },
        su: {
          name: "Sundanês",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Suaíli",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Suazi",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Sueco",
          nativeName: "svenska",
        },
        ta: {
          name: "Tâmil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tadjique",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Tailandês",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrina",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibetano Padrão, Tibetano, Central",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turcomeno",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalo",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tonganês (Ilhas de Tonga)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Turco",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tártaro",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Taitiano",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Uigure, uigur",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ucraniano",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Uzbeque",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamita",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapuque",
          nativeName: "Volapük",
        },
        wa: {
          name: "Valão",
          nativeName: "Walon",
        },
        cy: {
          name: "Galês",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Frísio Ocidental",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Iídiche",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Iorubá",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Zhuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Outro",
          nativeName: "Outro",
        },
      },

      details: {
        navigation: {
          content_information: "Informações do conteúdo",
          version_and_file_management: "Gestão de versões e de ficheiros",
          details: "Detalhes",
          versions: "Versões",
          analytics: "Análise",
          statistics: "Estatísticas",
          manage_access: "Gerir acesso",
          related_content: "Relacionado",
          properties: "Propriedades",
          support: "Empresa e suporte",
          release_notes: "Novidades",
          comments: "Comentários do utilizador",
        },
        related_content: {
          other_content_title: "Outro conteúdo que lhe pode interessar",
          related_content_title: "Conteúdo recomendado por ",
          related_content_url: "URL do conteúdo",
          add_related_content: "Adicionar conteúdo relacionado",
          add_related_content_info:
            "Só pode adicionar conteúdo online relacionado a conteúdo online e conteúdo local a conteúdo local",
          remove_related_content: "Remover",
          relatedContentUrlSubtitle: "Id do conteúdo ou url completo do conteúdo",
          updated_successfully: "Conteúdo relacionado actualizado com sucesso",
          update_failed: "Erro ao actualizar conteúdo relacionado",
          update_failed_invalid_id: "ID do conteúdo fornecido é inválido",
        },
        actions: {
          move: "Mover",
          delete: "Apagar",
          add_comment: "Adicionar comentário",
          add_reply: "Adicionar resposta",
          edit_comment: "Editar comentário",
          reply_comment: "Adicionar resposta",
          view_replies: "Ver respostas",
          report_bad_comment: "Comunicar comentário incorrecto &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Editar título ou apagar",
            title_or_move_delete: "Editar título, mover ou apagar",
            thumbnail: "Editar miniatura",
            description: "Editar descrição",
            groupandcategory: "Editar grupo e categoria",
            details: "Editar detalhes",
            versions: "Editar versões",
            support: "Editar informações de suporte",
            release_notes: "Editar novidades",
            search_criteria: "Editar critérios de pesquisa",
            batchedit_and_translations:
              "Com a funcionalidade Tradução pode publicar o conteúdo em vários idiomas. A versão traduzida do conteúdo será apresentada aos utilizadores com base na respectiva selecção do idioma da IU do Warehouse. Com a funcionalidade Editar em Lote pode editar vários itens em simultâneo. Seleccione em primeiro lugar um campo e, em seguida, os itens que pretende editar",
          },
        },
        links: {
          link: "Associar",
          select_collection_placeholder: "Seleccionar colecção para associar",
          linking_collections_label: "Colecções associadas a este item",
          private_content_note:
            "Nota: O conteúdo privado permanecerá visível apenas para os utilizadores com acesso ao mesmo.",
          share_on_social_media: "Partilhar no:",
          report_bad_content: "Comunicar conteúdo incorrecto &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Comunicar conteúdos ilegais &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Ao subscrever alertas, concorda em partilhar no ecrã o seu nome e o nome da organização (se aplicável) da Tekla Account com o proprietário do conteúdo.",
          alert_me: "Alertar-me",
          unsubscribe: "Anular a subscrição",
          subscribed_successfully: "Subscrição efectuada com sucesso",
          unsubscribed_successfully: "Subscrição anulada com sucesso",
          subscribers_label: "Subscritores",
        },
        reviews: {
          reviewers_label: "Revisores",
        },
        comments: {
          replies: "Respostas",
        },
        scan: {
          soft_failed_binaries_info: "Falha na detecção de vírus  ",
          hard_failed_binaries_info: "Não foi possível efectuar a detecção de vírus ",
          infected_binaries_info: "A detecção de vírus encontrou uma infecção ",
          what_now: "E agora?",
          popup: {
            soft_failed_binaries_title: "Binários com falhas",
            hard_failed_binaries_title: "Binários com falhas",
            infected_binaries_title: "Binários infectados",
            soft_failed_binaries_info:
              "Falha na detecção de vírus para os ficheiros carregados. Apenas temporário. Remova os ficheiros e carregue-os novamente. Todos os ficheiros devem passar a detecção de vírus antes de ficarem visíveis para outros utilizadores.",
            hard_failed_binaries_info:
              "Não foi possível efectuar a detecção de vírus. Remova os ficheiros. Todos os ficheiros devem passar a detecção de vírus antes de ficarem visíveis para outros utilizadores.",
            infected_binaries_info:
              "A detecção de vírus encontrou uma infecção nos ficheiros carregados. Remova os ficheiros. Todos os ficheiros devem passar a detecção de vírus antes de ficarem visíveis para outros utilizadores.",
            version: "Versão: ",
            fileName: "nome do ficheiro: ",
          },
        },
        status: {
          content_banned_info:
            "O conteúdo está bloqueado pelo administrador. Actualmente, não está disponível para outros utilizadores, excepto para o proprietário do conteúdo. <a href='https://support.tekla.com/contact-us' target='_blank'>Contacte o suporte</a> para obter mais informações.",
          content_archived_info:
            "Este conteúdo foi arquivado. Os utilizadores sem direitos de edição serão redireccionados para a página inicial se tentarem consultar o conteúdo através de uma ligação directa.",
          archived: "Arquivado",
        },
        modified_by: function (name) {
          return " por " + name;
        },
        created_by: function (name) {
          return "Criado por " + name;
        },
        deleteEntity: function (title) {
          return "Apagar " + title + "?";
        },
        userComments: "Comentários do utilizador",
        comment_added: "Comentário adicionado com sucesso",
        comment_updated: "Comentário actualizado com sucesso",
        comment_deleted: "Comentário apagado com sucesso",
        comment_add_failed: "Falha ao adicionar comentário",
        comment_update_failed: "Falha ao actualizar comentário",
        comment_delete_failed: "Falha ao apagar comentário",
        comment_delete_confirmation: "Apagar item de comentário?",
        name: "Nome",
        details: "Detalhes",
        contentOwner: "Criador",
        contentOwnerGroup: "Grupo do proprietário do conteúdo",
        contentGroup: "Grupo do conteúdo",
        thumbnail: "Miniatura",
        tooltip: "Descrição de ferramenta",
        description: "Descrição",
        additional_information: "Informações adicionais",
        images: "Imagens do produto",
        installationInfo: "Informações da instalação",
        productCode: "Código do produto",
        copyrightInfo: "Copyright",
        productExpirationDate: "Data de validade do produto",
        helpUrl: "Website &rsaquo;",
        supportUrl: "Suporte &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "Novidades",
        supportForumUrl: "Discussion Forum &rsaquo;",
        itemTypeCategories: "Grupo",
        useCategories: "Categoria",
        category: "Categoria:",
        group: "Grupo:",
        compatibleSoftwareProducts: "Produtos de software compatíveis",
        compatibleOperatingSystems: "Sistemas operativos compatíveis",
        locationRestrictions: "Disponibilidade do produto:",
        supportedLanguages: "Idiomas:",
        measurementUnits: "Unidades de medida:",
        tags: "Etiquetas e palavras-chave:",
        prerequisites: "Pré-requisitos:",
        releaseNotes: "Novidades:",
        versions: "Versões",
        testedVersions: "Versões compatíveis",
        qualityTag: "Qualidade - avaliada pelo proprietário",
        otherAttributes: "Outros atributos",
        labels: "Critérios de pesquisa",
        supportInformation: "Suporte",
        updateInformation: "Actualizações",
        latestUpdate: "Actualização mais recente",
        totalDownloads: "Total de transferências",
        licenses: "Licenças",
        maintenanceRequired: "A transferência necessita do Tekla Maintenance",
        tekla_maintenance_content: "Conteúdo do Tekla Maintenance",
        download_requires_tekla_maintenance: "A transferência necessita do Tekla Maintenance",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Actualizado com sucesso",
        update_failed: "Erro na actualização",
        update_failed_invalid_tag: "Erro na actualização: etiqueta não válida",
        update_failed_details_max_length_exceeded: "Erro na actualização: os detalhes são demasiado extensos",
        show_version_details: "Mais informações",
        content_removed: "Conteúdo removido",
        no_versions_available:
          "Não existem versões disponíveis. As actualizações podem demorar algum tempo a aparecer. Actualize o seu navegador para ver as alterações.",
        thumbnail_deleted: "Miniatura apagada",
        thumbnail_updated: "Miniatura actualizada",
        verify_service_plugin_is_running:
          "Verifique se o plug-in Tekla Warehouse Service está a funcionar correctamente",
        verify_ts_connection: "Verifique se o Tekla Structures está a funcionar",
        download_tos: {
          title: "Qualidade, suporte e privacidade",
          content_1: "Certifique-se de que o conteúdo transferido cumpre os seus padrões de qualidade. A Trimble não fornece suporte e não é responsável por qualquer conteúdo de terceiros. Contacte directamente o fornecedor de conteúdo para obter suporte.",
          content_2: "O seu país e o nome da sua organização podem ser publicados no serviço e divulgados aos proprietários do conteúdo com objectivos legítimos, tais como mantê-lo actualizado acerca de modificações introduzidas no conteúdo. Pode anular a subscrição destas notificações.",
          content_3: 'Para extensões de conteúdo da Trimble, aplicam-se os <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">termos da Trimble</a>. Termos de licença de terceiros podem aplicar-se ao conteúdo de terceiros. Veja o <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Aviso de Privacidade da Trimble</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Este é o armazenamento BIM gratuito do Tekla Structures. Encontre, importe, instale e partilhe interna e globalmente. Produza modelos de alta qualidade de maneira eficaz.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "O Tekla Warehouse é uma biblioteca online e local gratuita para ferramentas de modelação, peças inteligentes e templates do Tekla Structures",
        },
        search: {
          title: "Procurar no Tekla Warehouse",
          description:
            "Procurar extensões Tekla, peças 3D, formas, componentes personalizados, perfis, materiais como qualidades de aço e de betão, armaduras, malhas e templates",
        },
        about: {
          title: "Informações acerca do Tekla Warehouse",
          description:
            "O Tekla Warehouse disponibiliza um canal de marketing gratuito para os seus produtos e proporciona-lhe controlo total do seu conteúdo",
          developers: {
            title: "Título de programadores predefinido",
            description: "Descrição de programadores predefinida",
          },
          usage: {
            title: "Título de utilização predefinido",
            description: "Descrição de utilização predefinida",
          },
          getting_started: {
            title: "Título de iniciação predefinido",
            description: "Descrição de iniciação predefinida",
          },
          content: {
            title: "Título de conteúdo predefinido",
            description: "Descrição de conteúdo predefinida",
          },
          using_content: {
            title: "Título de utilização de conteúdo predefinido",
            description: "Descrição de utilização de conteúdo predefinida",
          },
          creating_content: {
            title: "Título de criação de conteúdo predefinido",
            description: "Descrição de criação de conteúdo predefinida",
          },
        },
        collection: {
          title: "Título da colecção predefinido",
          description: "Descrição da colecção predefinida",
        },
        my_warehouse: {
          title: "O meu Tekla Warehouse",
          description: "Gerir o conteúdo do Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Cancelar",
          previous: "&lsaquo; Anterior",
          next: "Seguinte &rsaquo;",
        },
        content: {
          add_content: "Adicionar ficheiros",
        },
        collection: {
          heading: "Seleccionar ou criar colecção",
          subHeading:
            "Conteúdo do grupo de colecções. Pode usar colecções online para publicar o seu conteúdo ou colecções locais para partilhar conteúdo com os colegas. Também pode usar colecções locais para utilização privada.",
          loading: "A carregar as colecções...",
          selectFromMyCollections: "Seleccionar de As minhas colecções",
          description_of_the_collection: "Descrição",
          title_validation: {
            missing: "Introduza um título.",
            too_short: function (length) {
              return "O título deve ter pelo menos " + length + " caracteres.";
            },
            too_long: function (length) {
              return "O título não pode exceder os " + length + " caracteres de comprimento.";
            },
          },
        },
        package: {
          basic_information: "Informações básicas",
          code_name: "Nome de código",
          code_name_info: "Introduza um nome de código como identificador único para este conteúdo. O nome de código pode incluir letras minúsculas, números e estes caracteres especiais: _ - @. Não é possível alterar o nome do código depois de este ter sido guardado.",
          code_name_validation: {
            missing: "É necessário introduzir um nome de código",
            wrong_format: "O nome de código pode incluir letras minúsculas, números e estes caracteres especiais: _ - @",
            not_unique: "Este nome de código já está a ser utilizado. É necessário introduzir um nome de código único.",
          },
          search_criteria: "Critérios de pesquisa",
          support: "Suporte",
          title: "Título",
          thumbnail: "Miniatura",
          "3D_pic": "Modelo 3D",
          title_validation: {
            missing: "Introduza um título.",
            too_short: function (length) {
              return "O título deve ter pelo menos " + length + " caracteres.";
            },
          },
          description_validation: {
            missing: "Introduza uma descrição.",
            too_short: function (length) {
              return "A descrição deve ter pelo menos " + length + " caracteres.";
            },
          },
          wrong_file_type: "Tipo de ficheiro errado.",
          selected_image_is_too_big: "A imagem seleccionada é demasiado grande.",
        },
        version: {
          select_version: "Seleccionar versão",
          select_quality: "Seleccionar qualidade",
          versions: "Versões",
          displayNameNew: "Nome da versão no ecrã",
          copy_from_version: "Copiar metadados da versão existente (opcional)",
          displayName: "Nome no ecrã",
          content_information: "Informações do conteúdo",
          back_to_content_information: "&lsaquo; Voltar às informações do conteúdo",
          version_and_file_management: "Gestão de versões e de ficheiros",
          there_s_no_versions_yet: "Ainda não existem versões.",
          create_a_version_to_add_files: "Crie uma versão para adicionar ficheiros.",
          add_version: "Adicionar versão",
          version_information: "Informações da versão",
          delete_version: "Apagar versão",
          no_versions_available:
            "Não existem versões disponíveis. As actualizações podem demorar algum tempo a aparecer.",
          update_start_title_html: '<div class="spinner spinner-white">A actualizar</div>',
          update_start_message: "Esta operação pode demorar algum tempo.",
          version_was_created: "A versão foi criada.",
          version_was_created_with_delay:
            "A versão foi criada. As alterações poderão ser visíveis após alguns minutos.",
          version_was_updated: "A versão foi actualizada.",
          version_was_deleted: "A versão foi apagada",
          version_was_deleted_with_delay:
            "A versão foi apagada. As alterações poderão ser visíveis após alguns minutos.",
          confirm_delete: function (title) {
            return 'Apagar versão "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "O pacote foi carregado.",
          couldn_t_create_a_package: "Não foi possível criar um pacote. As alterações serão revertidas.",
          uploading_title_html: '<div class="spinner spinner-white">A carregar</div>',
          uploading_text: "Esta operação pode demorar algum tempo.",
          select_organization: "Seleccionar organização",
          collection_visibility: "Visibilidade da colecção:",
          actions: {
            create: "Criar",
            publish: "Publicar",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Este conteúdo será visível para todos os utilizadores com acesso à unidade local ou de rede.",
          this_content_will_be_visible_only_to_you: "Este conteúdo apenas será visível para si.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Este conteúdo apenas será visível para si e para todos os utilizadores da sua organização.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Este conteúdo será visível para todos os utilizadores após a publicação.",
          public_content_disclaimer:
            "O utilizador é responsável por qualquer conteúdo carregado pelo próprio. Certifique-se de que o conteúdo não é nocivo, que tem o direito de carregar o conteúdo e que este não viola quaisquer direitos de propriedade intelectual. O utilizador concorda em publicar no serviço quaisquer termos e condições que possam ser aplicáveis à utilização do conteúdo carregado.",
        },
        metadata: {
          title: "Metadados",
          subtitle: "Dados básicos",
          deleteThumbnail: "Apagar miniatura",
          buttonCreate: "Criar",
        },
        basicdata: {
          title: "Adicionar conteúdo",
          clickButtonToSelectFile: "Clicar no botão para seleccionar o ficheiro",
          thumbnail: "Miniatura",
          dropImageHere: "Arrastar e largar a imagem aqui",
        },
        "3dGeometry": {
          title: "Criar catálogo de produtos 3D",
          buttonAddEntity: "Adicionar produto 3D",
          buttonAdd: "Adicionar atributo personalizado",
          errorAttributeAlreadyExists: "Já existe um atributo com o mesmo nome.",
          buttonCreatePackage: "Criar pacote",
          buttonCreatePackages: "Criar pacotes",
          addCustomAttributePlaceholder: "Nome do atributo",
        },
        please_log_in_fragments: {
          please: "Inicie ",
          log_in: "sessão",
          or: " ou ",
          register: "registe-se",
          to_upload_online_content: " para carregar o conteúdo online.",
        },
        header: {
          upload_header: "Carregar",
          collection: "Colecção",
          content: "Conteúdo",
          version: "Versão",
          finish: "Acabamento",
        },
        contentOwner: "Criador",
        contentOwnerGroup: "Publicar conteúdo",
        select_action: "Seleccionar acção",
        tooltip: "Descrição de ferramenta",
        description: "Descrição",
        copyright: "Copyright",
        tags: "Etiquetas e palavras-chave",
        tagsSubtitle:
          "Use a vírgula para separar, por exemplo: palavras-chave1, palavras-chave2. Se a palavra-chave for composta por várias palavras, use o sublinhado (_) entre as palavras; não são permitidos espaços entre palavras. Além do sublinhado e da vírgula, não são permitidos outros caracteres especiais.",
        images: "Imagens",
        installationInfo: "Informações da instalação",
        productCode: "Código Produto",
        copyrightInfo: "Informações de Copyright",
        Details: "Detalhes",
        specialTermsUrl: "EULA",
        productExpirationDate: "Data de validade do produto",
        helpUrl: "Website",
        helpUrlSubtitle: "inserir url completo",
        detailsSubtitle:
          "Nesta fase, só pode adicionar texto simples ao campo Detalhes. Depois de criar o conteúdo, pode editar os Detalhes para que estes contenham formatação HTML.",
        specialTermsUrlSubtitle:
          "O proprietário do conteúdo tem a possibilidade de acrescentar termos adicionais ao respectivo conteúdo. O proprietário tem de fornecer um URL onde estejam localizados os respectivos termos específicos. Tal não anula os Termos de Serviço da Trimble.",
        releaseNotes: "Ficheiro de Novidades",
        releaseNoteFileSubTitle: "Adicionar novidades como ficheiro",
        supportUrl: "Ajuda e Suporte",
        supportUrlSubtitle: 'inserir url completo ou prefixo "mailto:" para enviar por email',
        supportForumUrl: "Discussion Forum (Website)",
        supportForumUrlSubtitle: "inserir url completo",
        measurementUnits: "Unidades de medida",
        itemTypeCategories: "Grupo",
        useCategories: "Categoria",
        compatibleSoftwareProducts: "Produtos de software compatíveis",
        testedVersions: "Versões compatíveis",
        compatibleOperatingSystems: "Sistema operativo compatível",
        locationRestrictions: "Disponibilidade do produto",
        supportedLanguages: "Idiomas",
        select_releasenotes_file: "Seleccionar ficheiro",
        qualityTag: "Qualidade - avaliada pelo proprietário",
        requireMaintenance: "Para transferir necessita de um contrato de manutenção da Tekla válido",
        contentOwnerGroupAsMyself: "Como o próprio",
        contentOwnerGroupAsUserGroup: "Como grupo de utilizadores",
        selectUserGroupForContentOwner: "Seleccionar grupo de utilizadores para proprietário do conteúdo",
        defaultUploadTemplate: "Pacote predefinido",
        templateFor3DGeometryFiles: "Pacote(s) de geometria 3D",
        title: "Título",
        details: "Detalhes",
        selectContentOwner: "Seleccionar proprietário do conteúdo",
        selectContentType: "Tipo",
        errorWrongfiletype: "Tipo de ficheiro errado.",
        errorImageTooBig: "A imagem seleccionada é demasiado grande.",
        errorBlankTitle: "O título não pode estar em branco.",
        commonLabelsTitle: "Etiquetas comuns para todos os pacotes",
      },
      profile: {
        content_usage: "Utilização do conteúdo",
        profile_updated: "Perfil actualizado",
        invitation_accepted: function (target) {
          return "Convite aceite! Tem agora acesso a " + target + ".";
        },
        user_groups: {
          edit_user_group: "Editar grupo de utilizadores",
          user_group_updated: "Grupo de utilizadores actualizado",
          create_a_new_user_group: "Criar um novo grupo de utilizadores",
          name_of_the_user_group: "Nome do grupo de utilizadores",
          description: "Descrição",
          group_visibility: "Visibilidade do grupo",
          remove: '"Tem a certeza?"',
          remove_invitations: '"Tem a certeza?"',
          remove_members: '"Tem a certeza?"',
          invitations_removed: "Convites removidos!",
          members_list_updated: "Lista de membros actualizada!",
          user_group_created: "Grupo de utilizadores criado",
        },
        members: {
          update_selected_members: "Actualizar membros seleccionados",
          new_role: "Nova função",
          members_list_updated: "Lista de membros actualizada!",
          edit_user_group: "Editar grupo de utilizadores",
          delete_user_group: "Apagar grupo de utilizadores",
          filter: "Filtro",
          user_group_members: "Membros do grupo de utilizadores",
          update_members: "Actualizar membros",
          remove_members: "Remover membros",
          pending_invitations: "Convites pendentes",
          invite_member: "Convidar membro",
          update_invitations: "Actualizar convites",
          remove_invitations: "Remover convites",
        },
        subTabs: {
          alerts: "Os meus alertas",
          collections: "Colecções",
          likes: "Gostos",
        },
        admin: {
          grant_sales_admin: "Conceder função de administrador de vendas ao utilizador",
          grant_analyst_role: "Conceder função de analista ao utilizador",
          role_granted: "Função concedida",
          role_removed: "Função removida",
          offline_to_online: "Converter conteúdo offline para online",
          use_elastic_search: "Usar pesquisa elástica",
        },
        sales: {
          title: "Vendas",
        },
        subtitlePreferences: "Preferências de pesquisa",
        preferenceInfo:
          'Estes filtros destinam-se a recomendar conteúdo para o utilizador na <a href="#/landing">página inicial</a> do Tekla Warehouse.',
        clearFilters: "Apagar filtros &rsaquo;",
        admin_role: "Administrador",
        sales_role: "Vendas",
        admin_mode: "Modo de Administrador:",
        firstName: "Nome",
        lastName: "Apelido",
        displayName: "Nome no ecrã:",
        organization: "Nome da organização:",
        email: "Email:",
        teklaId: "ID Tekla",
        maintenanceAccess: "Aceder a conteúdo do Tekla Maintenance:",
        myLocations: "Localizações de projecto",
        measurementUnits: "Unidades",
        useCategories: "Usar",
        itemTypeCategories: "Estou interessado nos seguintes tipos",
        languages: "Idiomas",
        operatingSystems: "Sistemas Operativos",
        software: "Produtos de software",
        user_id: "ID de Utilizador:",
        organization_id: "ID da Organização:",
        edit_in_atc: "Editar o meu perfil na Tekla Account",
        no_likes: "Nenhum gosto",
        no_alerts: "Nenhuma subscrição",
        clear_alerts: "Apagar alertas",
        alerts_info_html:
          "Receberá um alerta quando ocorrer qualquer uma das seguintes alterações<br>" +
          "<b>Conteúdo:</b> O título, a descrição, os critérios de pesquisa ou os metadados do item de conteúdo foram modificados, uma nova versão do conteúdo foi adicionada ou uma versão foi apagada.<br>" +
          "<b>Colecção:</b> O título ou descrição foi editado, o conteúdo foi adicionado ou removido.",
      },
      uploader: {
        placeholders: {
          productCode: "Código Produto",
          title: "Nome",
          description: "Descrição",
          productWeight: "Peso do produto",
          weightUnit: "Unidade de peso",
          material: "Material",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Catálogo de produtos 3D",
          addToolButton: "Aplicação",
          select_files: "Ficheiros do disco",
          select_files_from_ts: "Ficheiros do Tekla Structures",
          partnerDownloadLink: "Ligação de transferência do parceiro",
        },
        headings: {
          geometryFile: "Ficheiro de geometria",
          thumbnailFile: "Bitmap (.bmp) da miniatura",
          title: "Nome",
          description: "Descrição",
          productCode: "Código Produto",
          productWeight: "Peso do Produto",
          weightUnit: "Unidade de Peso",
          material: "Material",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Global",
          AF: "Afeganistão",
          AX: "Ilhas Alanda",
          AL: "Albânia",
          DZ: "Argélia",
          AS: "Samoa Americana",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguila",
          AQ: "Antártida",
          AG: "Antígua e Barbuda",
          AR: "Argentina",
          AM: "Arménia",
          AW: "Aruba",
          AU: "Austrália",
          AT: "Áustria",
          AZ: "Azerbaijão",
          BS: "Baamas",
          BH: "Barém",
          BD: "Bangladeche",
          BB: "Barbados",
          BY: "Bielorrússia",
          BE: "Bélgica",
          BZ: "Belize",
          BJ: "Benim",
          BM: "Bermudas",
          BT: "Butão",
          BO: "Bolívia",
          BQ: "Bonaire",
          BA: "Bósnia-Herzegovina",
          BW: "Botsuana",
          BV: "Ilha Bouvet",
          BR: "Brasil",
          IO: "Território Britânico do Oceano Índico",
          BN: "Brunei",
          BG: "Bulgária",
          BF: "Burquina Faso",
          BI: "Burundi",
          KH: "Camboja",
          CM: "Camarões",
          CA: "Canadá",
          CV: "Cabo Verde",
          KY: "Ilhas Caimão",
          CF: "República Centro-Africana",
          TD: "Chade",
          CL: "Chile",
          CN: "China",
          CX: "Ilha do Natal",
          CC: "Ilhas dos Cocos",
          CO: "Colômbia",
          KM: "Comores",
          CG: "República do Congo",
          CD: "República Democrática do Congo",
          CK: "Ilhas Cook",
          CR: "Costa Rica",
          CI: "Costa do Marfim",
          HR: "Croácia",
          CU: "Cuba",
          CW: "Curaçau",
          CY: "Chipre",
          CZ: "República Checa",
          DK: "Dinamarca",
          DJ: "Jibuti",
          DM: "Domínica",
          DO: "República Dominicana",
          EC: "Equador",
          EG: "Egito",
          SV: "Salvador",
          GQ: "Guiné Equatorial",
          ER: "Eritreia",
          EE: "Estónia",
          ET: "Etiópia",
          FK: "Ilhas Falkland (Malvinas)",
          FO: "Ilhas Faroé",
          FJ: "Fiji",
          FI: "Finlândia",
          FR: "França",
          GF: "Guiana Francesa",
          PF: "Polinésia Francesa",
          TF: "Terras Austrais e Antárticas Francesas",
          GA: "Gabão",
          GM: "Gâmbia",
          GE: "Geórgia",
          DE: "Alemanha",
          GH: "Gana",
          GI: "Gibraltar",
          GR: "Grécia",
          GL: "Gronelândia",
          GD: "Granada",
          GP: "Guadalupe",
          GU: "Guame",
          GT: "Guatemala",
          GG: "Guernesey",
          GN: "Guiné",
          GW: "Guiné-Bissau",
          GY: "Guiana",
          HT: "Haiti",
          HM: "Ilha Heard e Ilhas McDonald",
          VA: "Cidade do Vaticano",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Hungria",
          IS: "Islândia",
          IN: "Índia",
          ID: "Indonésia",
          IR: "Irão",
          IQ: "Iraque",
          IE: "Irlanda",
          IM: "Ilha de Man",
          IL: "Israel",
          IT: "Itália",
          JM: "Jamaica",
          JP: "Japão",
          JE: "Jersey",
          JO: "Jordânia",
          KZ: "Cazaquistão",
          KE: "Quénia",
          KI: "Quiribáti",
          KW: "Koweit",
          KG: "Quirguistão",
          LA: "Laos",
          LV: "Letónia",
          LB: "Líbano",
          LS: "Lesoto",
          LR: "Libéria",
          LY: "Líbia",
          LI: "Liechtenstein",
          LT: "Lituânia",
          LU: "Luxemburgo",
          MO: "Macau",
          MK: "Macedónia",
          MG: "Madagáscar",
          MW: "Maláui",
          MY: "Malásia",
          MV: "Maldivas",
          ML: "Mali",
          MT: "Malta",
          MH: "Ilhas Marshall",
          MQ: "Martinica",
          MR: "Mauritânia",
          MU: "Maurícia",
          YT: "Maiote",
          MX: "México",
          FM: "Micronésia",
          MD: "Moldávia",
          MC: "Mónaco",
          MN: "Mongólia",
          ME: "Montenegro",
          MS: "Monserrate",
          MA: "Marrocos",
          MZ: "Moçambique",
          MM: "Mianmar (Birmânia)",
          NA: "Namíbia",
          NR: "Nauruano",
          NP: "Nepal",
          NL: "Países Baixos",
          NC: "Nova Caledónia",
          NZ: "Nova Zelândia",
          NI: "Nicarágua",
          NE: "Níger",
          NG: "Nigéria",
          NU: "Niuê",
          NF: "Ilha Norfolk",
          KP: "Coreia do Norte",
          MP: "Ilhas Marianas do Norte",
          NO: "Noruega",
          OM: "Omã",
          PK: "Paquistão",
          PW: "Palau",
          PS: "Território Palestiniano",
          PA: "Panamá",
          PG: "Papua-Nova Guiné",
          PY: "Paraguai",
          PE: "Peru",
          PH: "Filipinas",
          PN: "Ilhas Pitcairn",
          PL: "Polónia",
          PT: "Portugal",
          PR: "Porto Rico",
          QA: "Catar",
          RE: "Reunião",
          RO: "Roménia",
          RU: "Federação Russa",
          RW: "Ruanda",
          BL: "São Bartolomeu",
          SH: "Santa Helena",
          KN: "São Cristóvão e Nevis",
          LC: "Santa Lúcia",
          MF: "São Martinho (Saint-Martin)",
          PM: "São Pedro e Miquelon",
          VC: "São Vicente e Granadinas",
          WS: "Samoa",
          SM: "São Marinho",
          ST: "São Tomé e Príncipe",
          SA: "Arábia Saudita",
          SN: "Senegal",
          RS: "Sérvia",
          SC: "Seicheles",
          SL: "Serra Leoa",
          SG: "Singapura",
          SX: "São Martinho (Sint Maarten)",
          SK: "Eslováquia",
          SI: "Eslovénia",
          SB: "Ilhas Salomão",
          SO: "Somália",
          ZA: "África do Sul",
          GS: "Geórgia do Sul",
          KR: "Coreia do Sul",
          SS: "Sudão do Sul",
          ES: "Espanha",
          LK: "Sri Lanca",
          SD: "Sudão",
          SR: "Suriname",
          SJ: "Svalbard e Jan Mayen",
          SZ: "Suazilândia",
          SE: "Suécia",
          CH: "Suíça",
          SY: "República Árabe Síria",
          TW: "Taiwan",
          TJ: "Tajiquistão",
          TZ: "Tanzânia",
          TH: "Tailândia",
          TL: "Timor-Leste",
          TG: "Togo",
          TK: "Toquelau",
          TO: "Tonga",
          TT: "Trindade e Tobago",
          TN: "Tunísia",
          TR: "Turquia",
          TM: "Turquemenistão",
          TC: "Ilhas Turcas e Caicos",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ucrânia",
          AE: "Emirados Árabes Unidos",
          GB: "Reino Unido",
          US: "Estados Unidos",
          UM: "Ilhas Menores distantes dos Estados Unidos",
          UY: "Uruguai",
          UZ: "Usbequistão",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietname",
          VG: "Ilhas Virgens (Britânicas)",
          VI: "Ilhas Virgens (Americanas)",
          WF: "Wallis e Futuna",
          EH: "Sara Ocidental",
          YE: "Iémen",
          ZM: "Zâmbia",
          ZW: "Zimbabué",
        },
        sortOptions: {
          "-modifyTime": "Última actualização",
          "-modifiedAt": "Última actualização",
          title: "Ordem alfabética de A-Z",
          "-title": "Ordem alfabética de Z-A",
          "-reviewCount": "Maior número de gostos",
          name: "Nome",
          role: "Função",
          email: "Email",
          "-relevance": "Relevância",
          "-createdAt": "Última criação",
          displayName: "Ordem alfabética de A-Z",
          "-displayName": "Ordem alfabética de Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Hoje",
          yesterday: "Ontem",
          this_week: "Esta semana",
          previous_week: "Semana anterior",
          this_month: "Este mês",
          previous_month: "Mês anterior",
          this_year: "Este ano",
          previous_year: "Ano anterior",
          beginning_of_time: "Desde sempre",
          custom: "Personalizar período de tempo",
        },
        eventTypeOptions: {
          downloads: "Transferências",
          views: "Visualizações",
          all_events: "Todos os eventos",
        },
      },
    });
  },
};
