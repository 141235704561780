import _ from "underscore";
import { RootStore } from "./rootStore";

/**
 * Store for handling errors.
 */
export class ErrorHandlerStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Handles unauthorized request
   * @params message Notification to be showed to user
   * @params timeout Delay before doing redirect
   */
  public handleUnauthorized(message?: string, timeout?: number) {
    const notificationMessage = _.isUndefined(message)
      ? this.rootStore.getTranslation("shared.auth.access_denied")
      : message;
    const delay = timeout || 100;

    if (notificationMessage) {
      this.rootStore.getNotificationChannelStore().error(notificationMessage);
    }

    setTimeout(() => {
      if (this.rootStore.getUserStore().getCurrentUser()) {
        this.rootStore.getRouter().goToPreviousState();
      } else {
        this.rootStore.getRouter().changeRoutingState("/auth/handle_unauthorized");
      }
    }, delay);
  }
}
