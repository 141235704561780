/* eslint-disable no-async-promise-executor */
import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { createContext } from "react";
import _ from "underscore";
import * as validator from "validator";

import { NewRepository } from "../../js/services/NewRepository";
import { TCCOrganizationService } from "../../js/services/TCCOrganizationService";
import { createStoreContext, RootStore } from "../../stores/rootStore";

import {
  IBatchCollection,
  IBatchContentHandler,
  IBatchEntity,
  IBatchVersion,
  IDropdownOption,
  IDropdownOptionGroup,
  IEntity,
  IGroupAndCategoryHandler,
  IModifier,
  IOrganization,
  IResource,
  ISearchCriteriaHandler,
  IUserOrganizationProperties,
} from "../../models/dataModel";
import { ObjectTypeEnum } from "../../models/enums";

import { OrganizationFilterAttributesConverter } from "../../utils/converters/OrganizationFilterAttributesConverter";
import { checkTagsValidity } from "../../utils/functions";
import { metadataLabels } from "../../utils/MetadataLabels";

export interface IError {
  content?: IBatchEntity | IBatchVersion;
  id: string;
  message: string;
}

export interface IFieldError {
  fieldName: string;
  invalidAction: boolean;
  invalidValue: boolean;
}

export enum ActionEnum {
  ADD = "Add",
  REMOVE = "Remove",
  REPLACE = "Replace",
  ALLOW = "Allow",
  DENY = "Deny",
}

/**
 * Enumeration for dialog view state
 */
export const enum BatchEditViewState {
  EDIT,
  CONFIRM,
  UPDATE,
}

/**
 * A store that contains logic for translations dialog.
 */
export class BatchEditDialogStore implements IBatchContentHandler, IGroupAndCategoryHandler, ISearchCriteriaHandler {
  /**
   * Available collections list
   */
  @observable private availableCollections: IBatchCollection[] = [];
  /**
   * Collections dropdown options
   */
  @observable private collectionOptions: IDropdownOption[] = [];
  /**
   * Viewable collections list
   */
  @observable private collections: IBatchCollection[] = [];
  /**
   * Selected comment action option
   */
  @observable private commentAction?: IDropdownOption;
  /**
   * Copyright info
   */
  @observable private copyright = "";
  /**
   * Selected copyright action option
   */
  @observable private copyrightAction?: IDropdownOption;
  /**
   * Discussion forum url
   */
  @observable private discussionForumUrl = "";
  /**
   * Selected discussion form url action option
   */
  @observable private discussionForumUrlAction?: IDropdownOption;
  /**
   * list of general errors found on while performing validation/update
   */
  @observable private errors: IError[] = [];
  /**
   * list of field related errors found on validation check
   */
  @observable private fieldErrors: IFieldError[] = [];
  /**
   * Help url
   */
  @observable private helpUrl = "";
  /**
   * Selected help url action option
   */
  @observable private helpUrlAction?: IDropdownOption;
  /**
   * Indicator if is processing data
   */
  @observable private itemsProcessedCount = 0;
  /**
   * Selected license action option
   */
  @observable private licenseAction?: IDropdownOption;
  /**
   * Selected tag action option
   */
  @observable private locationRestrictionAction?: IDropdownOption;
  /**
   * location restrictions
   */
  @observable private locationRestrictions?: IDropdownOption[];
  /**
   * Selected measurement unit action option
   */
  @observable private measurementUnitAction?: IDropdownOption;
  /**
   * Measurement units
   */
  @observable private measurementUnits?: IDropdownOption[];
  /**
   * Selected operating system action option
   */
  @observable private operatingSystemAction?: IDropdownOption;
  /**
   * Operatingsystems
   */
  @observable private operatingSystems?: IDropdownOption[];
  /**
   * Organization search results
   */
  @observable private organizationSearchResults: IOrganization[] = [];
  /**
   * Indicator if is processing data
   */
  @observable private processing = false;
  /**
   * Product code
   */
  @observable private productCode = "";
  /**
   * Selected product code action option
   */
  @observable private productCodeAction?: IDropdownOption;
  /**
   * Product expiration date
   */
  @observable private productExpirationDate?: Date;
  /**
   * Selected product expiration date action option
   */
  @observable private productExpirationDateAction?: IDropdownOption;
  /**
   * Resource (organization/collection/package)
   */
  @observable private resource: IResource | undefined = undefined;
  /**
   * Root store
   */
  private rootStore: RootStore;
  /**
   * SearchTypeCategories for package
   */
  @observable private searchTypeCategories?: IDropdownOption[];
  /**
   * Selected item type category action option
   */
  @observable private searchTypeCategoryAction?: IDropdownOption;
  /**
   * Flag for if all content should be selected
   */
  @observable private selectAll = false;
  /**
   * Selected collection option
   */
  @observable private selectedCollectionOption?: IDropdownOption;
  /**
   * Selected organization
   */
  private selectedOrganization?: IOrganization;
  /**
   * Selected tested version option
   */
  private selectedVersionOption?: IDropdownOption;
  /**
   * Flag for if commenting fields should be visible
   */
  @observable private showCommenting = false;
  /**
   * Flag for if copyright fields should be visible
   */
  @observable private showCopyright = false;
  /**
   * Flag for if discussion forum url fields should be visible
   */
  @observable private showDiscussionForumUrl = false;
  /**
   * Flag for if help url fields should be visible
   */
  @observable private showHelpUrl = false;
  /**
   * Flag for if license fields should be visible
   */
  @observable private showLicense = false;
  /**
   * Flag for if availibility fields should be visible
   */
  @observable private showLocationRestriction = false;
  /**
   * Flag for if measurement unit fields should be visible
   */
  @observable private showMeasurementUnit = false;
  /**
   * Flag for if operatingsystem fields should be visible
   */
  @observable private showOperatingSystem = false;
  /**
   * Flag for if product code fields should be visible
   */
  @observable private showProductCode = false;
  /**
   * Flag for if product expiration date fields should be visible
   */
  @observable private showProductExpirationDate = false;
  /**
   * Flag for if item type category fields should be visible
   */
  @observable private showSearchTypeCategory = false;
  /**
   * Indicator if select organization sub-view should be shown
   */
  @observable private showSelectOrganization = false;
  /**
   * Flag for if operatingsystem fields should be visible
   */
  @observable private showSoftwareProduct = false;
  /**
   * Flag for if special terms url fields should be visible
   */
  @observable private showSpecialTermsUrl = false;
  /**
   * Flag for if support url fields should be visible
   */
  @observable private showSupportUrl = false;
  /**
   * Flag for if language fields should be visible
   */
  @observable private showSupportedLanguage = false;
  /**
   * Flag for if tag fields should be visible
   */
  @observable private showTag = false;
  /**
   * Flag for if tested version fields should be visible
   */
  @observable private showTestedVersion = false;
  /**
   * Flag for if item use category fields should be visible
   */
  @observable private showUseCategory = false;
  /**
   * Flag for if vesion measurement unit fields should be visible
   */
  @observable private showVersionMeasurementUnit = false;
  /**
   * Selected software product action option
   */
  @observable private softWareProductAction?: IDropdownOption;
  /**
   * Software products
   */
  @observable private softWareProducts?: IDropdownOption[];
  /**
   * Special terms url
   */
  @observable private specialTermsUrl = "";
  /**
   * Selected special terms url action option
   */
  @observable private specialTermsUrlAction?: IDropdownOption;
  /**
   * Support url
   */
  @observable private supportUrl = "";
  /**
   * Selected support url action option
   */
  @observable private supportUrlAction?: IDropdownOption;
  /**
   * Selected supported language action option
   */
  @observable private supportedLanguageAction?: IDropdownOption;
  /**
   * Supported languages
   */
  @observable private supportedLanguages?: IDropdownOption[];
  /**
   * Selected tag action option
   */
  @observable private tagAction?: IDropdownOption;
  /**
   * Tags
   */
  @observable private tags?: string[];
  /**
   * Selected tested version action option
   */
  @observable private testedVersionAction?: IDropdownOption;
  /**
   * Tested versions
   */
  @observable private testedVersions?: IDropdownOption[];
  /**
   * Indicator if is update has been processed
   */
  @observable private updateProcessed = false;
  /**
   * Usecategories for package
   */
  @observable private useCategories?: IDropdownOption[];
  /**
   * Selected use category action option
   */
  @observable private useCategoryAction?: IDropdownOption;
  /**
   * Indicator if is date is being validated
   */
  @observable private validating = false;
  /**
   * Selected version measurement unit action option
   */
  @observable private versionMeasurementUnitAction?: IDropdownOption;
  /**
   * Version measurement units
   */
  @observable private versionMeasurementUnits?: IDropdownOption[];
  /**
   * Tested versions dropdown options
   */
  @observable private versionOptions: IDropdownOption[] = [];
  /**
   * A flag marking which view is currently rendered.
   */
  @observable
  private viewState: BatchEditViewState = BatchEditViewState.EDIT;

  /**
   * Gets selected commenting dropdown option
   * @returns selected option
   */
  public getCommentAction = (): IDropdownOption => {
    return this.commentAction!;
  };
  /**
   * Gets selected copyright action option
   * @returns selected option
   */
  public getCopyrightAction = (): IDropdownOption => {
    return this.copyrightAction!;
  };
  /**
   * Gets discussion forum url
   * @retuns url
   */
  public getDiscussionForumUrl = (): string => {
    return this.discussionForumUrl;
  };
  /**
   * Gets selected discussion form url action option
   * @returns selected option
   */
  public getDiscussionForumUrlAction = (): IDropdownOption => {
    return this.discussionForumUrlAction!;
  };
  /**
   * Gets helpurl
   * @retuns url
   */
  public getHelpUrl = (): string => {
    return this.helpUrl;
  };
  /**
   * Gets selected helpurl action option
   * @returns selected option
   */
  public getHelpUrlAction = (): IDropdownOption => {
    return this.helpUrlAction!;
  };
  /**
   * Gets selected license dropdown option
   * @returns selected option
   */
  public getLicenseAction = (): IDropdownOption => {
    return this.licenseAction!;
  };
  /**
   * Gets selected location restriction action
   * @returns selected option
   */
  public getLocationRestrictionAction = (): IDropdownOption => {
    return this.locationRestrictionAction!;
  };
  /**
   * Gets selected measurement unit dropdown option
   * @returns selected option
   */
  public getMeasurementUnitAction = (): IDropdownOption => {
    return this.measurementUnitAction!;
  };
  /**
   * Gets selected operatingsystem action dropdown option
   * @returns selected option
   */
  public getOperatingSystemAction = (): IDropdownOption => {
    return this.operatingSystemAction!;
  };
  /**
   * Gets selected product code action option
   * @returns selected option
   */
  public getProductCodeAction = (): IDropdownOption => {
    return this.productCodeAction!;
  };
  /**
   * Gets selected product expiration date action option
   * @returns selected option
   */
  public getProductExpirationDateAction = (): IDropdownOption => {
    return this.productExpirationDateAction!;
  };
  /**
   * Gets selected item type category dropdown option
   * @returns selected option
   */
  public getSearchTypeCategoryAction = (): IDropdownOption => {
    return this.searchTypeCategoryAction!;
  };
  /**
   * Gets selected software product action dropdown option
   * @returns selected option
   */
  public getSoftwareProductAction = (): IDropdownOption => {
    return this.softWareProductAction!;
  };
  /**
   * Gets special terms url
   * @retuns url
   */
  public getSpecialTermsUrl = (): string => {
    return this.specialTermsUrl;
  };
  /**
   * Gets selected special terms url action option
   * @returns selected option
   */
  public getSpecialTermsUrlAction = (): IDropdownOption => {
    return this.specialTermsUrlAction!;
  };
  /**
   * Gets support url
   * @retuns url
   */
  public getSupportUrl = (): string => {
    return this.supportUrl;
  };
  /**
   * Gets selected support url action option
   * @returns selected option
   */
  public getSupportUrlAction = (): IDropdownOption => {
    return this.supportUrlAction!;
  };
  /**
   * Gets selected language dropdown option
   * @returns selected option
   */
  public getSupportedLanguageAction = (): IDropdownOption => {
    return this.supportedLanguageAction!;
  };
  /**
   * Gets selected tag dropdown option
   * @returns selected option
   */
  public getTagAction = (): IDropdownOption => {
    return this.tagAction!;
  };
  /**
   * Gets selected tested version action dropdown option
   * @returns selected option
   */
  public getTestedVersionAction = (): IDropdownOption => {
    return this.testedVersionAction!;
  };
  /**
   * Gets selected use category dropdown option
   * @returns selected option
   */
  public getUseCategoryAction = (): IDropdownOption => {
    return this.useCategoryAction!;
  };
  /**
   * Gets selected version measurement unit action dropdown option
   * @returns selected option
   */
  public getVersionMeasurementUnitAction = (): IDropdownOption => {
    return this.versionMeasurementUnitAction!;
  };
  /**
   * Searches organizations
   * @params searchTerm the keyword used for searching
   */
  @action
  public searchOrganizations = async (searchTerm: string) => {
    this.processing = true;
    this.organizationSearchResults = [];
    const queryParams = {
      fq: OrganizationFilterAttributesConverter.toTCC({
        organizationNameFilter: searchTerm,
      }),
      sortBy: "displayName",
    };
    const result = await TCCOrganizationService.search(queryParams);
    runInAction(() => {
      this.organizationSearchResults = result.entries;
      this.processing = false;
    });
  };
  /**
   * Handles actions when comment action is changed
   * @params option the selected dropdown option
   */
  @action
  public setCommentAction = (option: IDropdownOption) => {
    this.commentAction = option;
    this.validateCommentAction();
  };
  /**
   * Handles actions when copyright action is changed
   * @params option the selected dropdown option
   */
  @action
  public setCopyrightAction = (option: IDropdownOption) => {
    this.copyrightAction = option;
    this.validateCopyright();
  };
  /**
   * Sets discussion forum url
   * @params url the url to be set
   */
  @action
  public setDiscussionForumUrl = (url: string) => {
    this.discussionForumUrl = url;
    this.validateDiscussionForumUrl();
  };
  /**
   * Handles actions when discussion form url action is changed
   * @params option the selected dropdown option
   */
  @action
  public setDiscussionForumUrlAction = (option: IDropdownOption) => {
    this.discussionForumUrlAction = option;
    this.validateDiscussionForumUrl();
  };
  /**
   * Sets help url
   * @params url the url to be set
   */
  @action
  public setHelpUrl = (url: string) => {
    this.helpUrl = url;
    this.validateHelpUrl();
  };
  /**
   * Handles actions when helpurl action is changed
   * @params option the selected dropdown option
   */
  @action
  public setHelpUrlAction = (option: IDropdownOption) => {
    this.helpUrlAction = option;
    this.validateHelpUrl();
  };
  /**
   * Handles actions when license action is changed
   * @params option the selected dropdown option
   */
  @action
  public setLicenseAction = (option: IDropdownOption) => {
    this.licenseAction = option;
    this.validateLicenseAction();
  };
  /**
   * Handles actions when location restriction action is changed
   * @params option the selected dropdown option
   */
  @action
  public setLocationRestrictionAction = (option: IDropdownOption) => {
    this.locationRestrictionAction = option;
    this.validateLocationRestriction();
  };
  /**
   * Handles actions when measurement unit action is changed
   * @params option the selected dropdown option
   */
  @action
  public setMeasurementUnitAction = (option: IDropdownOption) => {
    this.measurementUnitAction = option;
    this.validateMeasurementUnit();
  };
  /**
   * Handles actions when operatingsystem action is changed
   * @params option the selected dropdown option
   */
  @action
  public setOperatingSystemAction = (option: IDropdownOption) => {
    this.operatingSystemAction = option;
    this.validateOperatingSystem();
  };
  /**
   * Handles actions when product code action is changed
   * @params option the selected dropdown option
   */
  @action
  public setProductCodeAction = (option: IDropdownOption) => {
    this.productCodeAction = option;
    this.validateProductCode();
  };
  /**
   * Handles actions when product expiration date action is changed
   * @params option the selected dropdown option
   */
  @action
  public setProductExpirationDateAction = (option: IDropdownOption) => {
    this.productExpirationDateAction = option;
    this.validateProductExpirationDate();
  };
  /**
   * Handles actions when item type category action is changed
   * @params option the selected dropdown option
   */
  @action
  public setSearchTypeCategoryAction = (option: IDropdownOption) => {
    this.searchTypeCategoryAction = option;
    this.validateSearchTypeCategory();
  };
  /**
   * Handles actions when software product action is changed
   * @params option the selected dropdown option
   */
  @action
  public setSoftwareProductAction = (option: IDropdownOption) => {
    this.softWareProductAction = option;
    this.validateSoftwareProduct();
  };
  /**
   * Sets special terms url
   * @params url the url to be set
   */
  @action
  public setSpecialTermsUrl = (url: string) => {
    this.specialTermsUrl = url;
    this.validateSpecialTermsUrl();
  };
  /**
   * Handles actions when special terms url action is changed
   * @params option the selected dropdown option
   */
  @action
  public setSpecialTermsUrlAction = (option: IDropdownOption) => {
    this.specialTermsUrlAction = option;
    this.validateSpecialTermsUrl();
  };
  /**
   * Sets support url
   * @params url the url to be set
   */
  @action
  public setSupportUrl = (url: string) => {
    this.supportUrl = url;
    this.validateSupportUrl();
  };
  /**
   * Handles actions when support url action is changed
   * @params option the selected dropdown option
   */
  @action
  public setSupportUrlAction = (option: IDropdownOption) => {
    this.supportUrlAction = option;
    this.validateSupportUrl();
  };
  /**
   * Handles actions when supported language action is changed
   * @params option the selected dropdown option
   */
  @action
  public setSupportedLanguageAction = (option: IDropdownOption) => {
    this.supportedLanguageAction = option;
    this.validateSupportedLanguage();
  };
  /**
   * Handles actions when tag action is changed
   * @params option the selected dropdown option
   */
  @action
  public setTagAction = (option: IDropdownOption) => {
    this.tagAction = option;
    this.validateTag();
  };
  /**
   * Handles actions when tested version action is changed
   * @params option the selected dropdown option
   */
  @action
  public setTestedVersionAction = (option: IDropdownOption) => {
    this.testedVersionAction = option;
    this.validateTestedVersion();
  };
  /**
   * Handles actions when use category action is changed
   * @params option the selected dropdown option
   */
  @action
  public setUseCategoryAction = (option: IDropdownOption) => {
    this.useCategoryAction = option;
    this.validateUseCategory();
  };
  /**
   * Handles actions when version measurement unit action is changed
   * @params option the selected dropdown option
   */
  @action
  public setVersionMeasurementUnitAction = (option: IDropdownOption) => {
    this.versionMeasurementUnitAction = option;
    this.validateVersionMeasurementUnit();
  };

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   * @param availableCollections list of available collections to select from.
   * @param resource default resource (organization/collection/package) from which content should be viewed.
   */
  public constructor(rootStore: RootStore, availableCollections?: IBatchCollection[], resource?: IResource) {
    makeObservable(this);
    this.rootStore = rootStore;
    runInAction(() => {
      if (availableCollections) {
        this.availableCollections = _.clone(toJS(availableCollections));
      }
      if (resource) {
        this.resource = resource;
        this.init();
      }
    });
  }

  /**
   * Checks the validity of tags.
   * @returns true if tags are valid
   */
  public areTagsValid(): boolean {
    return !this.tagsExist() ? true : checkTagsValidity(this.tags!.slice());
  }

  /**
   * Resolves if errors exist
   * @returns true if errors found
   */
  public errorsExist(): boolean {
    return this.errors.length > 0;
  }

  /**
   * Gets action dropdown options
   * @returns List of options
   */
  public getActionOptions(): IDropdownOption[] {
    return [
      {
        value: ActionEnum.ADD,
        label: this.rootStore.getTranslation("batch_edit.content.actionOptions.add"),
      },
      {
        value: ActionEnum.REMOVE,
        label: this.rootStore.getTranslation("batch_edit.content.actionOptions.remove"),
      },
      this.getReplaceActionOption(),
    ];
  }

  /**
   * Get collection dropdown options
   * @returns List of options
   */
  public getCollectionOptions(): IDropdownOption[] {
    return this.collectionOptions;
  }

  /**
   * Gets viewable collections list
   */
  public getCollections(): IBatchCollection[] {
    return this.collections;
  }

  /**
   * Gets comment action dropdown options
   * @returns List of options
   */
  public getCommentAndLicenseActionOptions(): IDropdownOption[] {
    return [
      {
        value: ActionEnum.ALLOW,
        label: this.rootStore.getTranslation("batch_edit.content.actionOptions.yes"),
      },
      {
        value: ActionEnum.DENY,
        label: this.rootStore.getTranslation("batch_edit.content.actionOptions.no"),
      },
    ];
  }

  /**
   * Gets copyright
   * @retuns copyright text
   */
  public getCopyright(): string {
    return this.copyright;
  }

  /**
   * Gets validation errors
   * @returns list of errors
   */
  public getErrors(): IError[] {
    return this.errors;
  }

  /**
   * Resolves count of processed items
   * @returns count
   */
  public getItemsProcessedCount(): number {
    return this.itemsProcessedCount;
  }

  /**
   * Gets location restrictions
   * @retuns list of location restrictions
   */
  public getLocationRestrictions(): IDropdownOption[] {
    return toJS(this.locationRestrictions) || [];
  }

  /**
   * Gets measurement units
   * @retuns list of measurement units
   */
  public getMeasurementUnits(): IDropdownOption[] {
    return toJS(this.measurementUnits) || [];
  }

  /**
   * Gets operatingsystems
   * @retuns list of operatingsystem
   */
  public getOperatingSystems(): IDropdownOption[] {
    return toJS(this.operatingSystems) || [];
  }

  /**
   * Gets organizations found on search
   */
  public getOrganizationSearchResults(): IOrganization[] {
    return this.organizationSearchResults;
  }

  public getProcessingStatusText(): string {
    return (
      (this.updateProcessed
        ? this.rootStore.getTranslation("batch_edit.update.processed")
        : this.rootStore.getTranslation("batch_edit.update.processing")) +
      " " +
      this.getItemsProcessedCount() +
      " / " +
      this.getToBeProcessedCount()
    );
  }

  /**
   * Gets product code
   * @retuns product code
   */
  public getProductCode(): string {
    return this.productCode;
  }

  /**
   * Gets product expiration date
   * @retuns product expiration date
   */
  public getProductExpirationDate(): Date {
    if (this.productExpirationDate) {
      return this.productExpirationDate;
    } else {
      return null as unknown as Date;
    }
  }

  /**
   * Gets replace action dropdown option
   * @returns Dropdown option
   */
  public getReplaceActionOption(): IDropdownOption {
    return {
      value: ActionEnum.REPLACE,
      label: this.rootStore.getTranslation("batch_edit.content.actionOptions.replace"),
    };
  }

  /**
   * Gets item type categories
   * @retuns list of item type categories
   */
  public getSearchTypeCategories(): IDropdownOption[] {
    return toJS(this.searchTypeCategories) || [];
  }

  /**
   * Resolves if select all flag is on
   */
  public getSelectAll(): boolean {
    return this.selectAll;
  }

  /**
   * Gets selected collection dropdown option
   * @returns selected option
   */
  public getSelectedCollectionOption(): IDropdownOption {
    return this.selectedCollectionOption!;
  }

  /**
   * Gets selected organization
   */
  public getSelectedOrganization(): IOrganization | undefined {
    return this.selectedOrganization;
  }

  /**
   * Gets selected version dropdown option
   * @returns selected option
   */
  public getSelectedVersionOption(): IDropdownOption {
    return this.selectedVersionOption!;
  }

  /**
   * Resolves if commenting fields should be shown
   */
  public getShowCommenting(): boolean {
    return this.showCommenting;
  }

  /**
   * Resolves if copyright fields should be shown
   */
  public getShowCopyright(): boolean {
    return this.showCopyright;
  }

  /**
   * Resolves if support url fields should be shown
   */
  public getShowDiscussionForumUrl(): boolean {
    return this.showDiscussionForumUrl;
  }

  /**
   * Resolves if help url fields should be shown
   */
  public getShowHelpUrl(): boolean {
    return this.showHelpUrl;
  }

  /**
   * Resolves if license fields should be shown
   */
  public getShowLicense(): boolean {
    return this.showLicense;
  }

  /**
   * Resolves if tag fields should be shown
   */
  public getShowLocationRestriction(): boolean {
    return this.showLocationRestriction;
  }

  /**
   * Resolves if measurement unit fields should be shown
   */
  public getShowMeasurementUnit(): boolean {
    return this.showMeasurementUnit;
  }

  /**
   * Resolves if operatingsystem fields should be shown
   */
  public getShowOperatingSystem(): boolean {
    return this.showOperatingSystem;
  }

  /**
   * Resolves if product code fields should be shown
   */
  public getShowProductCode(): boolean {
    return this.showProductCode;
  }

  /**
   * Resolves if product expiration date fields should be shown
   */
  public getShowProductExpirationDate(): boolean {
    return this.showProductExpirationDate;
  }

  /**
   * Resolves if search type category fields should be shown
   */
  public getShowSearchTypeCategory(): boolean {
    return this.showSearchTypeCategory;
  }

  /**
   * Resolves if select organization sub-view should be shown
   * @returns true if view should be shown
   */
  public getShowSelectOrganization(): boolean {
    return this.showSelectOrganization;
  }

  /**
   * Resolves if software product fields should be shown
   */
  public getShowSoftwareProduct(): boolean {
    return this.showSoftwareProduct;
  }

  /**
   * Resolves if special terms url fields should be shown
   */
  public getShowSpecialTermsUrl(): boolean {
    return this.showSpecialTermsUrl;
  }

  /**
   * Resolves if support url fields should be shown
   */
  public getShowSupportUrl(): boolean {
    return this.showSupportUrl;
  }

  /**
   * Resolves if language fields should be shown
   */
  public getShowSupportedLanguage(): boolean {
    return this.showSupportedLanguage;
  }

  /**
   * Resolves if tag fields should be shown
   */
  public getShowTag(): boolean {
    return this.showTag;
  }

  /**
   * Resolves if tested version fields should be shown
   */
  public getShowTestedVersion(): boolean {
    return this.showTestedVersion;
  }

  /**
   * Resolves if use category fields should be shown
   */
  public getShowUseCategory(): boolean {
    return this.showUseCategory;
  }

  /**
   * Resolves if version measurement unit fields should be shown
   */
  public getShowVersionMeasurementUnit(): boolean {
    return this.showVersionMeasurementUnit;
  }

  /**
   * Gets software products
   * @retuns list of software products
   */
  public getSoftwareProducts(): IDropdownOption[] {
    return toJS(this.softWareProducts) || [];
  }

  /**
   * Gets supported languages
   * @retuns list supported languages
   */
  public getSupportedLanguages(): IDropdownOption[] {
    return toJS(this.supportedLanguages) || [];
  }

  /**
   * Gets tags
   * @return list of tags
   */
  public getTags(): string[] {
    return this.tags || [];
  }

  /**
   * Gets tested versions
   * @retuns list of tested versions
   */
  public getTestedVersions(): IDropdownOption[] {
    return toJS(this.testedVersions) || [];
  }

  /**
   * Gets use categories
   * @retuns list of use categories
   */
  public getUseCategories(): IDropdownOption[] {
    return toJS(this.useCategories) || [];
  }

  /**
   * Gets version measurement units
   * @retuns list of version measurement units
   */
  public getVersionMeasurementUnits(): IDropdownOption[] {
    return toJS(this.versionMeasurementUnits) || [];
  }

  /**
   * Get version dropdown options
   * @returns List of options
   */
  public getVersionOptions(): IDropdownOption[] {
    return this.versionOptions;
  }

  /**
   * Returns view state
   * @returns view state
   */
  public getViewState(): BatchEditViewState {
    return this.viewState;
  }

  /**
   * Handles actions when new collection is selected
   * @params option the selected dropdown option
   */
  @action
  public async handleCollectionChange(option: IDropdownOption) {
    this.selectedCollectionOption = option;
    let selectContent = false;
    const user = this.rootStore.getUserStore().getCurrentUser();
    this.processing = true;
    this.selectAll = false;
    this.selectAllContent(false);
    if (option.value === "show_all") {
      this.collections = this.availableCollections;
    } else if (_.isString(option.value) && (option.value as string).includes("show_organization")) {
      this.collections = _.filter(this.availableCollections, (collection: IBatchCollection) => {
        return !!user && !!collection.creator && collection.creator.id === option.value.split("_")[2];
      });
    } else {
      selectContent = true;
      this.collections = _.filter(this.availableCollections, (collection: IBatchCollection) => {
        return collection.id === option.value;
      });
    }

    const promises: Array<Promise<void>> = [];
    _.each(this.collections, (collection: IBatchCollection) => {
      if (selectContent) {
        collection.open = true;
      }
      collection.packages = undefined;
      promises.push(this.fetchPackagesForCollection(collection));
    });
    await Promise.all(promises);

    runInAction(() => {
      if (selectContent && (!this.selectedVersionOption || this.selectedVersionOption?.value == "show_all")) {
        this.selectAll = true;
        this.selectAllContent(true);
      }
      this.processing = false;
    });
  }

  /**
   * Handles actions when version is selected
   * @params option the selected dropdown option
   */
  @action
  public async handleVersionChange(option: IDropdownOption) {
    this.processing = true;
    this.selectedVersionOption = option;
    const promises: Array<Promise<void>> = [];
    _.each(this.collections, (collection: IBatchCollection) => {
      collection.packages = undefined;
      promises.push(this.fetchPackagesForCollection(collection));
    });
    await Promise.all(promises);
    runInAction(() => {
      this.processing = false;
    });
  }

  /**
   * Resolves if field has invalid action error
   * @params fieldName the field that be used to search errors for
   * @returns true if field has invalid action error
   */
  public hasInvalidAction(fieldName: string): boolean {
    if (this.validating) {
      const fieldErrors: IFieldError[] = _.filter(this.fieldErrors, (error) => {
        return error.fieldName === fieldName && error.invalidAction === true;
      });
      return fieldErrors && fieldErrors.length > 0;
    } else {
      return false;
    }
  }

  /**
   * Resolves if field has invalid value error
   * @params fieldName the field that be used to search errors for
   * @returns true if field has invalid value error
   */
  public hasInvalidValue(fieldName: string) {
    if (this.validating) {
      const fieldErrors: IFieldError[] = _.filter(this.fieldErrors, (error) => {
        return error.fieldName === fieldName && error.invalidValue === true;
      });
      return fieldErrors && fieldErrors.length > 0;
    } else {
      return false;
    }
  }

  /**
   * Fetches viewable content
   */
  @action
  public async initViewableContent() {
    this.processing = true;
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!this.resource && !!this.availableCollections && this.availableCollections.length > 0) {
      if (!!this.selectedCollectionOption && this.resourceIsOrBelongsToSelectedCollection()) {
        if (this.isPackage(this.resource)) {
          this.collections = _.filter(this.availableCollections, (collection) => {
            return collection.id === this.resource!.collection!.id;
          });
          if (this.collections.length > 0) {
            this.collections[0].open = true;
          }
        } else if (this.isCollection(this.resource)) {
          this.collections = _.filter(this.availableCollections, (collection) => {
            return collection.id === this.resource!.id;
          });
          if (this.collections.length > 0) {
            this.collections[0].open = true;
            this.collections[0].selected = true;
          }
        }

        if ((this.isCollection(this.resource) || this.isPackage(this.resource)) && this.collections[0]) {
          this.collections[0].packages = [];
          await this.fetchPackagesForCollection(this.collections[0]);
          this.setPackagesSelection(this.collections[0].packages!);
        }
      } else if (this.isOrganization(this.resource)) {
        this.collections = _.filter(this.availableCollections, (collection: IBatchCollection) => {
          return !!user && !!collection.creator && collection.creator.id === this.resource?.id;
        });

        if (this.collections.length > 0) {
          return this.handleCollectionChange({
            value: "show_organization_" + this.resource.id,
            label:
              this.rootStore.getTranslation("batch_edit.content.show_organization_content") +
              `: ${this.resource.displayName}`,
          });
        }
      } else {
        if (!!this.selectedCollectionOption) {
          return this.handleCollectionChange(this.selectedCollectionOption);
        }
      }
    }

    runInAction(() => {
      this.processing = false;
    });
  }

  /**
   * Resolves if content checkbox should be shown as indeterminated.
   * This is when some (not all) of the content child items are selected
   * @params content The content item that should be inspected
   */
  public isContentIndeterminated(content: IBatchCollection | IBatchEntity | IBatchVersion): boolean {
    if (this.isCollection(content as IResource)) {
      return this.isCollectionIndeterminated(content as IBatchCollection);
    } else if (this.isPackage(content as IResource)) {
      return this.isPackageIndeterminated(content as IBatchEntity);
    } else {
      return false;
    }
  }

  /**
   * Resolves if data is being processed
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Resolves if update has finished
   */
  public isUpdateProcessed(): boolean {
    return this.updateProcessed;
  }

  /**
   * Checks if given url is valid
   * @returns true if url is valid
   */
  public isValidUrl(url: string): boolean {
    if (!url) {
      return true;
    }
    return !!(
      url &&
      validator.isURL(url, {
        protocols: ["http", "https", "ftp"],
        require_protocol: true,
      })
    );
  }

  /**
   * Resolves if fields are being validate
   * @returns true if validated
   */
  public isValidating(): boolean {
    return this.validating;
  }

  /**
   * Performs update on selected items
   */
  @action
  public async performUpdate(): Promise<void[]> {
    this.updateProcessed = false;
    this.itemsProcessedCount = 0;
    this.processing = true;
    const promises: Array<Promise<void>> = [];
    promises.push(this.updatePackages());
    promises.push(this.updateVersions());
    const promise = await Promise.all(promises);
    runInAction(() => {
      this.processing = false;
      this.updateProcessed = true;
    });
    return promise;
  }

  /**
   * Resets all field values
   */
  @action
  public resetValues() {
    this.setViewState(BatchEditViewState.EDIT);
    this.errors = [];
    this.fieldErrors = [];
    this.validating = false;
    this.updateProcessed = false;
    this.resetPackageFields();
    this.resetVersionFields();
  }

  /**
   * Resolves confirm message that should be shown
   * @returns message
   */
  public resolveConfirmMessage(): string {
    return this.rootStore.getTranslation(
      "batch_edit.update.confirm_update_func",
      _.escape(this.getToBeProcessedCount().toString()),
    );
  }

  /**
   * Resolves failure text that should be shown
   * @returns text
   */
  public resolveFailureText(error: IError) {
    if (error.content) {
      if (error.content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
        return this.rootStore.getTranslation(
          "batch_edit.update.package_failure_func",
          _.escape(error.content.title!),
          _.escape(error.message),
        );
      } else if (error.content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
        return this.rootStore.getTranslation(
          "batch_edit.update.version_failure_func",
          _.escape(error.content.title!),
          _.escape(error.message),
        );
      }
    }
  }

  /**
   * Selects content and child items
   * @params content the content to be selected
   */
  public selectContent(content: IBatchCollection | IBatchEntity | IBatchVersion) {
    if (this.isCollection(content as IResource)) {
      this.selectCollection(content as IBatchCollection);
    } else if (this.isPackage(content as IResource)) {
      this.selectPackage(content as IBatchEntity);
    } else if (this.isVersion(content as IResource)) {
      this.selectVersion(content as IBatchVersion);
    }
    if (this.validating) {
      this.validateContentSelection();
    }
  }

  /**
   * Sets copyright
   * @params copyright the text to be set
   */
  @action
  public setCopyright(copyright: string) {
    this.copyright = copyright;
    this.validateCopyright();
  }

  /**
   * Sets location restrictions
   * @params selectedOptions selected location restrictions
   */
  @action
  public setLocationRestrictions(selectedOptions: IDropdownOption[]) {
    this.locationRestrictions = selectedOptions;
    this.validateLocationRestriction();
  }

  /**
   * Sets supported languages
   * @params selectedOptions selected supported languages
   */
  @action
  public setMeasurementUnits(selectedOptions: readonly IDropdownOption[]) {
    this.measurementUnits = selectedOptions as IDropdownOption[];
    this.validateMeasurementUnit();
  }

  /**
   * Sets operatingsystems
   * @params selectedOptions selected operating systems
   */
  @action
  public setOperatingSystems(selectedOptions: readonly IDropdownOption[]) {
    this.operatingSystems = selectedOptions as IDropdownOption[];
    this.validateOperatingSystem();
  }

  /**
   * Sets product code
   * @params productCode the product code to be set
   */
  @action
  public setProductCode(productCode: string) {
    this.productCode = productCode;
    this.validateProductCode();
  }

  /**
   * Sets product expiration date
   * @params productExpirationDate the value to be set
   */
  @action
  public setProductExpirationDate(productExpirationDate: Date) {
    this.productExpirationDate = productExpirationDate;
    this.validateProductExpirationDate();
  }

  /**
   * Sets item type categories
   * @params selectedOptions selected item type categories
   */
  @action
  public setSearchTypeCategories(selectedOptions: IDropdownOption[]) {
    this.searchTypeCategories = selectedOptions;
    this.validateSearchTypeCategory();
  }

  /**
   * Selects/unselects all content
   */
  @action
  public setSelectAll(selectAll: boolean) {
    this.selectAll = selectAll;
    this.processing = true;
    this.selectAllContent(this.selectAll);
    this.processing = false;
  }

  /**
   * Sets selected organization
   * @params organization the new organization to be set
   */
  @action
  public async setSelectedOrganization(organization: IOrganization) {
    this.processing = true;
    this.selectedOrganization = organization;
    this.organizationSearchResults = [];

    try {
      const results = await NewRepository.getCollectionsByCreator(
        organization,
        false,
        this.rootStore.getUserStore().getCurrentUser()?.id,
      );

      runInAction(() => {
        this.availableCollections = results;
        this.initContentFields(this.availableCollections);
        this.initCollectionOptions();
      });

      await this.handleCollectionChange({
        value: "show_all",
        label: this.rootStore.getTranslation("batch_edit.content.show_all_content"),
      });

      runInAction(() => {
        this.showSelectOrganization = false;
      });
    } catch {
      console.log("Failed to set selected organization");
    } finally {
      runInAction(() => {
        this.processing = false;
      });
    }
  }

  /**
   * Method for setting commenting fields visibility
   * @params showCommenting should fields be shown
   */
  @action
  public setShowCommenting(showCommenting: boolean) {
    this.showCommenting = showCommenting;
    this.validateCommentAction();
  }

  /**
   * Method for setting copyright fields visibility
   * @params showCopyright should fields be shown
   */
  @action
  public setShowCopyright(showCopyright: boolean) {
    this.showCopyright = showCopyright;
    this.validateCopyright();
  }

  /**
   * Method for setting discussion forum url fields visibility
   * @params showDiscussionForumUrl should fields be shown
   */
  @action
  public setShowDiscussionForumUrl(showDiscussionForumUrl: boolean) {
    this.showDiscussionForumUrl = showDiscussionForumUrl;
    this.validateDiscussionForumUrl();
  }

  /**
   * Method for setting use help url fields visibility
   * @params showHelpUrl should fields be shown
   */
  @action
  public setShowHelpUrl(showHelpUrl: boolean) {
    this.showHelpUrl = showHelpUrl;
    this.validateHelpUrl();
  }

  /**
   * Method for setting commenting fields visibility
   * @params showLicense should fields be shown
   */
  @action
  public setShowLicense(showLicense: boolean) {
    this.showLicense = showLicense;
    this.validateLicenseAction();
  }

  /**
   * Method for setting location restriction fields visibility
   * @params showLocationRestriction should fields be shown
   */
  @action
  public setShowLocationRestriction(showLocationRestriction: boolean) {
    this.showLocationRestriction = showLocationRestriction;
    this.validateLocationRestriction();
  }

  /**
   * Method for setting measurement unit fields visibility
   * @params showMeasurementUnit should fields be shown
   */
  @action
  public setShowMeasurementUnit(showMeasurementUnit: boolean) {
    this.showMeasurementUnit = showMeasurementUnit;
    this.validateMeasurementUnit();
  }

  /**
   * Method for setting operatingsystem fields visibility
   * @params showOperatingSystem should fields be shown
   */
  @action
  public setShowOperatingSystem(showOperatingSystem: boolean) {
    this.showOperatingSystem = showOperatingSystem;
    this.validateOperatingSystem();
  }

  /**
   * Method for setting product code fields visibility
   * @params showProductCode should fields be shown
   */
  @action
  public setShowProductCode(showProductCode: boolean) {
    this.showProductCode = showProductCode;
    this.validateProductCode();
  }

  /**
   * Method for setting product expiration date fields visibility
   * @params showProductExpirationDate should fields be shown
   */
  @action
  public setShowProductExpirationDate(showProductExpirationDate: boolean) {
    this.showProductExpirationDate = showProductExpirationDate;
    this.validateProductExpirationDate();
  }

  /**
   * Method for setting item type category fields visibility
   * @params showSearchTypeCategory should fields be shown
   */
  @action
  public setShowSearchTypeCategory(showSearchTypeCategory: boolean) {
    this.showSearchTypeCategory = showSearchTypeCategory;
    this.validateSearchTypeCategory();
  }

  /**
   * Sets if select organization sub-view should be shown
   * @params showSelectOrganization the value to be se
   */
  @action
  public setShowSelectOrganization(showSelectOrganization: boolean) {
    this.showSelectOrganization = showSelectOrganization;
  }

  /**
   * Method for setting software product fields visibility
   * @params showSoftWareProduct should fields be shown
   */
  @action
  public setShowSoftwareProduct(showSoftwareProduct: boolean) {
    this.showSoftwareProduct = showSoftwareProduct;
    this.validateSoftwareProduct();
  }

  /**
   * Method for setting speical terms fields visibility
   * @params showSpecialTermsUrl should fields be shown
   */
  @action
  public setShowSpecialTermsUrl(showSpecialTermsUrl: boolean) {
    this.showSpecialTermsUrl = showSpecialTermsUrl;
    this.validateSpecialTermsUrl();
  }

  /**
   * Method for setting support url fields visibility
   * @params showSupportUrl should fields be shown
   */
  @action
  public setShowSupportUrl(showSupportUrl: boolean) {
    this.showSupportUrl = showSupportUrl;
    this.validateSupportUrl();
  }

  /**
   * Method for setting supported language fields visibility
   * @params showSupportedLanguage should fields be shown
   */
  @action
  public setShowSupportedLanguage(showSupportedLanguage: boolean) {
    this.showSupportedLanguage = showSupportedLanguage;
    this.validateSupportedLanguage();
  }

  /**
   * Method for setting tag fields visibility
   * @params showTag should fields be shown
   */
  @action
  public setShowTag(showTag: boolean) {
    this.showTag = showTag;
    this.validateTag();
  }

  /**
   * Method for setting tested version fields visibility
   * @params showTestedVersion should fields be shown
   */
  @action
  public setShowTestedVersion(showTestedVersion: boolean) {
    this.showTestedVersion = showTestedVersion;
    this.validateTestedVersion();
  }

  /**
   * Method for setting use category fields visibility
   * @params showUseCategory should fields be shown
   */
  @action
  public setShowUseCategory(showUseCategory: boolean) {
    this.showUseCategory = showUseCategory;
    this.validateUseCategory();
  }

  /**
   * Method for setting version measurement unit fields visibility
   * @params showVersionMeasurementUnit should fields be shown
   */
  @action
  public setShowVersionMeasurementUnit(showVersionMeasurementUnit: boolean) {
    this.showVersionMeasurementUnit = showVersionMeasurementUnit;
    this.validateVersionMeasurementUnit();
  }

  /**
   * Sets software products
   * @params selectedOptions selected software products
   */
  @action
  public setSoftWareProducts(selectedOptions: IDropdownOption[]) {
    this.softWareProducts = selectedOptions;
    this.validateSoftwareProduct();
  }

  /**
   * Sets supported languages
   * @params selectedOptions selected supported languages
   */
  @action
  public setSupportedLanguages(selectedOptions: IDropdownOption[]) {
    this.supportedLanguages = selectedOptions;
    this.validateSupportedLanguage();
  }

  /**
   * Sets tags
   * @params tags list of tags
   */
  @action
  public setTags(tags: string[]) {
    this.tags = tags;
    this.validateTag();
  }

  /**
   * Sets tested versions
   * @params selectedOptions selected tested versions
   */
  @action
  public setTestedVersions(selectedOptions: IDropdownOption[]) {
    this.testedVersions = selectedOptions;
    this.validateTestedVersion();
  }

  /**
   * Sets use categories
   * @params selectedOptions selected use categories
   */
  @action
  public setUseCategories(selectedOptions: IDropdownOption[]) {
    this.useCategories = selectedOptions;
    this.validateUseCategory();
  }

  /**
   * Sets measurement units
   * @params selectedOptions selected version measurement units
   */
  @action
  public setVersionMeasurementUnits(selectedOptions: readonly IDropdownOption[]) {
    this.versionMeasurementUnits = selectedOptions as IDropdownOption[];
    this.validateVersionMeasurementUnit();
  }

  /**
   * Sets view state
   * @param viewState EditImageViewState object
   */
  @action
  public setViewState(viewState: BatchEditViewState) {
    this.processing = true;
    this.viewState = viewState;
    this.processing = false;
  }

  /**
   * Resolves if page should be reloaded
   */
  public shouldReloadPage(): boolean {
    return this.updateProcessed && this.isPackage(this.resource!);
  }

  /**
   * Resolves if version listing should be shown
   */
  public showVersions(): boolean {
    return true;
  }

  /**
   * Opens content on tree
   * @params content the content item to be viewed
   */
  public async toggleContent(content: IBatchCollection | IBatchEntity) {
    if (this.isCollection(content as IResource)) {
      await this.toggleCollection(content as IBatchCollection);
    } else if (this.isPackage(content as IResource)) {
      await this.togglePackage(content as IBatchEntity);
    }
  }

  /**
   * Resolves if data is valid and update can be made
   * @returns true if data valid
   */
  @action
  public validateForm() {
    this.validating = true;
    this.validateAttributes();
    this.validateContentSelection();

    if (this.errors.length == 0 && this.fieldErrors.length == 0) {
      this.setViewState(BatchEditViewState.CONFIRM);
    }
  }

  @action
  private addContentAttribute(content: IBatchEntity | IBatchVersion, key: string, values: string[]) {
    if (_.has(content.attributes, key) && _.isArray(content.attributes![key])) {
      content.attributes![key] = content.attributes![key].concat(values);
    } else if (_.has(content, key) && _.isArray(content[key])) {
      content[key] = content[key].concat(values);
    }
  }

  @action
  private addErrorMessage(translationId: string) {
    if (!this.containsErrorMessage(translationId)) {
      this.errors.push({
        id: translationId,
        message: this.rootStore.getTranslation("batch_edit.content.validation." + translationId),
      });
    }
  }

  private containsErrorMessage(translationId): boolean {
    return _.any(this.errors, function (error) {
      return error.id === translationId;
    });
  }

  @action
  private async fetchPackagesForCollection(collection: IBatchCollection) {
    if (!collection.packages || collection.packages.length == 0) {
      try {
        collection.loading = true;
        const queryParams = { id: collection.id };
        if (this.selectedVersionOption && this.selectedVersionOption.value !== "allVersions") {
          collection.selected = false;
          this.selectAll = false;

          _.extend(queryParams, {
            testedVersion: this.selectedVersionOption.value,
          });
        }
        const packages: IBatchEntity[] = await NewRepository.getPackages(
          queryParams,
          false,
          this.rootStore.getUserStore().getCurrentUser()?.id,
        );

        if (packages.length > 0) {
          runInAction(() => {
            collection.packages = _.sortBy(packages, "title");
          });
          const promises: Array<Promise<void>> = [];
          _.each(collection.packages!, (packageItem: IBatchEntity) => {
            promises.push(this.fetchVersionsForPackage(packageItem, collection));
          });
          await Promise.all(promises);
        } else {
          runInAction(() => {
            collection.packages = [];
          });
        }
      } catch {
        console.log("Error occurred while fetching packages for collection", collection.id);
      } finally {
        runInAction(() => {
          collection.loading = false;
        });
      }
    }
  }

  @action
  private async fetchVersionsForPackage(packageItem: IBatchEntity, collection?: IBatchCollection) {
    if (!packageItem.versions || packageItem.versions.length == 0) {
      packageItem.open = true;
      packageItem.loading = true;

      try {
        const versions: IBatchVersion[] = await NewRepository.getVersions(packageItem, false);

        runInAction(() => {
          packageItem.versions = _.chain(versions)
            .map((version: IBatchVersion) => {
              if (
                !!this.selectedVersionOption &&
                (version.attributes.testedVersions?.includes(this.selectedVersionOption?.value) ||
                  this.selectedVersionOption.value === "allVersions")
              ) {
                if (!!collection) collection.open = true;
                return { ...version, selected: true };
              } else {
                return version;
              }
            })
            .sortBy("title")
            .value();
        });
      } catch {
        console.log("Error occurred while fetching versions for package", packageItem.id);
      } finally {
        runInAction(() => {
          packageItem.loading = false;
        });
      }
    }
  }

  private getSelectedPackages(): IBatchEntity[] {
    let selectedPackages: IBatchEntity[] = [];
    _.each(this.collections, function (collection) {
      const packages: IBatchEntity[] = _.filter(collection.packages!, (packageItem: IEntity) => {
        return !!packageItem.selected;
      });
      selectedPackages = selectedPackages.concat(packages);
    });
    return selectedPackages;
  }

  private getSelectedVersions(): IBatchVersion[] {
    let selectedVersions: IBatchVersion[] = [];
    _.each(this.collections, function (collection) {
      _.each(collection.packages!, function (packageItem) {
        const versions: IBatchVersion[] = _.filter(packageItem.versions || [], (version: IBatchVersion) => {
          return !!version.selected;
        });
        selectedVersions = selectedVersions.concat(versions);
      });
    });
    return selectedVersions;
  }

  private getToBeProcessedCount(): number {
    let toBeProcessedCount = 0;
    const selectedPackages: IBatchEntity[] = this.getSelectedPackages();
    const selectedVersions: IBatchVersion[] = this.getSelectedVersions();
    if (this.packageAttributesSelected()) {
      toBeProcessedCount = toBeProcessedCount + selectedPackages.length;
    }
    if (this.versionAttributesSelected()) {
      toBeProcessedCount = toBeProcessedCount + selectedVersions.length;
    }
    return toBeProcessedCount;
  }

  @action
  private async init() {
    this.processing = true;
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (user) {
      this.selectedOrganization = user.organization;
    }
    this.initContentFields(this.availableCollections);
    this.initCollectionOptions();
    this.initVersionOptions();
    this.initSelectedCollectionOption();

    runInAction(() => {
      this.processing = false;
    });
  }

  @action
  private initCollectionOptions() {
    this.collectionOptions = _.map(this.availableCollections, (collection: IBatchCollection) => {
      return { label: collection.title!, value: collection };
    });

    _.map(
      this.rootStore.getUserStore().getOrganizationsWithEditRights() || [],
      (organization: IUserOrganizationProperties) => {
        const showOrganizationOption: IDropdownOption = {
          value: `show_organization_${organization.id}`,
          label:
            this.rootStore.getTranslation("batch_edit.content.show_organization_content") +
            `: ${organization.displayName}`,
        };
        this.collectionOptions.unshift(showOrganizationOption);
      },
    );

    const showAllOption = {
      value: "show_all",
      label: this.rootStore.getTranslation("batch_edit.content.show_all_content"),
    };
    this.collectionOptions.unshift(showAllOption);
  }

  @action
  private initContentFields(contentList: IBatchCollection[] | IBatchEntity[] | IBatchVersion[]) {
    _.each(contentList, (content) => {
      content.open = false;
      content.selected = false;
      content.loading = false;
    });
  }

  @action
  private initSelectedCollectionOption() {
    let option: IDropdownOption[] = [];
    if (this.availableCollections && this.resource) {
      if (this.isOrganization(this.resource)) {
        option = _.filter(this.collectionOptions, (collectionOption: IDropdownOption) => {
          return collectionOption.value === "show_organization";
        });
      } else if (this.isCollection(this.resource)) {
        option = _.filter(this.collectionOptions, (collectionOption: IDropdownOption) => {
          return collectionOption.value.id === this.resource!.id;
        });
      } else if (this.isPackage(this.resource)) {
        option = _.filter(this.collectionOptions, (collectionOption: IDropdownOption) => {
          return collectionOption.value.id === this.resource!.collection!.id;
        });
      }
      this.selectedCollectionOption = option[0];
    }
  }

  private initVersionOptions() {
    this.versionOptions = [
      {
        label: this.rootStore.getTranslation("download.all_versions"),
        value: "allVersions",
      },
    ];

    _.chain(metadataLabels.testedVersionsOptions)
      .map((tvo: IDropdownOption | IDropdownOptionGroup) =>
        _.has(tvo, "options") ? (tvo as IDropdownOptionGroup).options : (tvo as IDropdownOption),
      )
      .flatten()
      .each((option) => {
        if (option.value !== "NVS")
          this.versionOptions.push({
            label: this.rootStore.getTranslation(option.label),
            value: option.value,
          });
      });
  }

  private invalidActionsExist(): boolean {
    const invalidActions = _.filter(this.fieldErrors, (error) => {
      return error.invalidAction === true;
    });
    return invalidActions && invalidActions.length > 0;
  }

  private invalidValuesExist(): boolean {
    const invalidValues = _.filter(this.fieldErrors, (error) => {
      return error.invalidValue === true;
    });
    return invalidValues && invalidValues.length > 0;
  }

  private isCollection(resource: IResource): boolean {
    return resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION;
  }

  private isCollectionIndeterminated(collection: IBatchCollection): boolean {
    if (!collection.selected || !collection.packages) {
      return false;
    } else {
      const selectedPackages: IBatchEntity[] = _.filter(collection.packages, (packageItem: IEntity) => {
        return !!packageItem.selected;
      }) as IBatchEntity[];
      return selectedPackages.length > 0 && selectedPackages.length != collection.packages.length;
    }
  }

  private isOrganization(resource: IResource): boolean {
    return resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION;
  }

  private isPackage(resource: IResource): boolean {
    return resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE;
  }

  private isPackageIndeterminated(packageItem: IBatchEntity): boolean {
    if (!packageItem.selected || !packageItem.versions) {
      return false;
    } else {
      const selectedVersions: IBatchVersion[] = _.filter(packageItem.versions, (version: IBatchVersion) => {
        return !!version.selected;
      });
      return selectedVersions.length > 0 && selectedVersions.length != packageItem.versions.length;
    }
  }

  private isVersion(resource: IResource): boolean {
    return resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION;
  }

  private resourceIsOrBelongsToSelectedCollection(): boolean {
    return (
      !!this.resource &&
      !!this.selectedCollectionOption &&
      (this.resource.id === (this.selectedCollectionOption.value as IBatchCollection).id ||
        (!!this.resource.collection &&
          this.resource.collection?.id === (this.selectedCollectionOption.value as IBatchCollection).id))
    );
  }

  private packageAttributesSelected(): boolean {
    return (
      this.getShowSearchTypeCategory() ||
      this.getShowUseCategory() ||
      this.getShowTag() ||
      this.getShowLocationRestriction() ||
      this.getShowSupportedLanguage() ||
      this.getShowMeasurementUnit() ||
      this.getShowCommenting() ||
      this.getShowHelpUrl() ||
      this.getShowSupportUrl() ||
      this.getShowDiscussionForumUrl() ||
      this.getShowCopyright() ||
      this.getShowSpecialTermsUrl() ||
      this.getShowLicense()
    );
  }

  @action
  private removeContentAttribute(content: IBatchEntity | IBatchVersion, key: string, values: string[]) {
    if (_.has(content.attributes, key) && _.isArray(content.attributes![key])) {
      content.attributes![key] = _.reject(content.attributes![key], function (attributeValue) {
        return _.contains(values, attributeValue);
      });
    } else if (_.has(content, key) && _.isArray(content[key])) {
      content[key] = _.reject(content[key], function (attributeValue) {
        return _.contains(values, attributeValue);
      });
    }
  }

  private removeErrorFromList(fieldName: string): IFieldError[] {
    return _.reject(this.fieldErrors, function (error) {
      return error.fieldName == fieldName;
    });
  }

  @action
  private removeErrorMessage(translationId: string) {
    this.errors = _.reject(this.errors, function (error) {
      return error.id === translationId;
    });
  }

  @action
  private replaceContentAttribute(content: IBatchEntity | IBatchVersion, key: string, values: string[] | string) {
    if (_.has(content, key)) {
      content[key] = values;
    } else if (_.has(content.attributes, key)) {
      content.attributes![key] = values;
    }
  }

  private resetPackageFields() {
    this.resetPropertyFields();
    this.resetSupportFields();
  }

  @action
  private resetPropertyFields() {
    this.showUseCategory = false;
    this.useCategoryAction = undefined;
    this.useCategories = [];
    this.showSearchTypeCategory = false;
    this.searchTypeCategoryAction = undefined;
    this.searchTypeCategories = [];
    this.showTag = false;
    this.tagAction = undefined;
    this.tags = [];
    this.showLocationRestriction = false;
    this.locationRestrictionAction = undefined;
    this.locationRestrictions = [];
    this.showSupportedLanguage = false;
    this.supportedLanguageAction = undefined;
    this.supportedLanguages = [];
    this.showCommenting = false;
    this.commentAction = undefined;
    this.showLicense = false;
    this.licenseAction = undefined;
  }

  @action
  private resetSupportFields() {
    this.showHelpUrl = false;
    this.helpUrlAction = undefined;
    this.helpUrl = "";
    this.showSupportUrl = false;
    this.supportUrlAction = undefined;
    this.supportUrl = "";
    this.showDiscussionForumUrl = false;
    this.discussionForumUrlAction = undefined;
    this.discussionForumUrl = "";
    this.showCopyright = false;
    this.copyrightAction = undefined;
    this.copyright = "";
    this.showSpecialTermsUrl = false;
    this.specialTermsUrlAction = undefined;
    this.specialTermsUrl = "";
  }

  @action
  private resetVersionFields() {
    this.showOperatingSystem = false;
    this.operatingSystemAction = undefined;
    this.operatingSystems = [];
    this.showSoftwareProduct = false;
    this.softWareProductAction = undefined;
    this.softWareProducts = [];
    this.showTestedVersion = false;
    this.testedVersionAction = undefined;
    this.testedVersions = [];
    this.showProductCode = false;
    this.productCodeAction = undefined;
    this.productCode = "";
    this.showProductExpirationDate = false;
    this.productExpirationDateAction = undefined;
    this.productExpirationDate = undefined;
    this.showVersionMeasurementUnit = false;
    this.versionMeasurementUnitAction = undefined;
    this.versionMeasurementUnits = [];
  }

  @action
  private selectAllContent(selectAll: boolean) {
    _.each(this.collections, (collection: IBatchCollection) => {
      collection.selected = selectAll;
      _.each(collection.packages!, (packageItem: IBatchEntity) => {
        packageItem.selected = selectAll;
        _.each(packageItem.versions || [], (version: IBatchVersion) => {
          version.selected = selectAll;
        });
      });
    });

    if (this.validating) {
      this.validateContentSelection();
    }
  }

  @action
  private selectCollection(collection: IBatchCollection) {
    collection.selected = !collection.selected;
    this.processing = true;
    _.each(collection.packages!, (packageItem) => {
      this.selectPackage(packageItem, collection);
    });
    this.processing = false;
  }

  @action
  private selectPackage(packageItem: IBatchEntity, collection?: IBatchCollection) {
    packageItem.selected = !!collection ? collection.selected : !packageItem.selected;
    this.processing = true;
    _.each(packageItem.versions || [], (version) => {
      version.selected = packageItem.selected;
    });
    this.processing = false;
  }

  @action
  private selectVersion(version: IBatchVersion) {
    this.processing = true;
    version.selected = !version.selected;
    this.processing = false;
  }

  @action
  private setContentAttribute(
    content: IBatchEntity | IBatchVersion,
    key: string,
    action: IDropdownOption,
    values: string[],
  ) {
    if (action.value === ActionEnum.ADD) {
      this.addContentAttribute(content, key, values);
    } else if (action.value === ActionEnum.REMOVE) {
      this.removeContentAttribute(content, key, values);
    } else if (action.value === ActionEnum.REPLACE) {
      this.replaceContentAttribute(content, key, values);
    }
  }

  @action
  private setPackageAttributes(packageItem: IBatchEntity) {
    this.setPropertyAttributes(packageItem);
    this.setSupportAttributes(packageItem);
  }

  @action
  private setPackageCommenting(packageItem: IBatchEntity, action: IDropdownOption) {
    if (action.value === ActionEnum.ALLOW) {
      packageItem.allowComments = true;
    } else if (action.value === ActionEnum.DENY) {
      packageItem.allowComments = false;
    }
  }

  @action
  private setPackageLicenses(packageItem: IBatchEntity, action: IDropdownOption) {
    if (action.value === ActionEnum.ALLOW) {
      packageItem.attributes!.licensesACL = ["teklamaintenance"];
    } else if (action.value === ActionEnum.DENY) {
      this.removeContentAttribute(packageItem, "licensesACL", ["teklamaintenance"]);
    }
  }

  @action
  private setPackagesSelection(packages: IBatchEntity[]) {
    const isCollection = this.isCollection(this.resource!);
    if (isCollection) {
      this.selectAll = true;
    }
    _.each(packages, (packageItem) => {
      if (packageItem.id === this.resource!.id || isCollection) {
        packageItem.selected = true;
        this.setVersionsSelection(packageItem);
      }
      packageItem.open = true;
    });
  }

  @action
  private setPropertyAttributes(packageItem: IBatchEntity) {
    if (this.getShowSearchTypeCategory()) {
      this.setContentAttribute(
        packageItem,
        "itemTypeCategories",
        this.getSearchTypeCategoryAction(),
        this.toStringArray(this.getSearchTypeCategories()),
      );
    }
    if (this.getShowUseCategory()) {
      this.setContentAttribute(
        packageItem,
        "useCategories",
        this.getUseCategoryAction(),
        this.toStringArray(this.getUseCategories()),
      );
    }
    if (this.getShowTag()) {
      this.setContentAttribute(packageItem, "tags", this.getTagAction(), toJS(this.getTags()));
    }
    if (this.getShowLocationRestriction()) {
      this.setContentAttribute(
        packageItem,
        "locationRestrictions",
        this.getLocationRestrictionAction(),
        this.toStringArray(this.getLocationRestrictions()),
      );
    }
    if (this.getShowSupportedLanguage()) {
      this.setContentAttribute(
        packageItem,
        "supportedLanguages",
        this.getSupportedLanguageAction(),
        this.toStringArray(this.getSupportedLanguages()),
      );
    }
    if (this.getShowMeasurementUnit()) {
      this.setContentAttribute(
        packageItem,
        "measurementUnits",
        this.getMeasurementUnitAction(),
        this.toStringArray(this.getMeasurementUnits()),
      );
    }
    if (this.getShowCommenting()) {
      this.setPackageCommenting(packageItem, this.getCommentAction());
    }
    if (this.getShowLicense()) {
      this.setPackageLicenses(packageItem, this.getLicenseAction());
    }
  }

  @action
  private setSupportAttributes(packageItem: IBatchEntity) {
    if (this.getShowHelpUrl()) {
      this.replaceContentAttribute(packageItem, "helpUrl", this.getHelpUrl());
    }
    if (this.getShowSupportUrl()) {
      this.replaceContentAttribute(packageItem, "supportUrl", this.getSupportUrl());
    }
    if (this.getShowDiscussionForumUrl()) {
      this.replaceContentAttribute(packageItem, "discussionForumUrl", this.getDiscussionForumUrl());
    }
    if (this.getShowCopyright()) {
      this.replaceContentAttribute(packageItem, "copyrightInfo", this.getCopyright());
    }
    if (this.getShowSpecialTermsUrl()) {
      this.replaceContentAttribute(packageItem, "specialTermsUrl", this.getSpecialTermsUrl());
    }
  }

  private setVersionAttributes(version: IBatchVersion) {
    if (this.getShowOperatingSystem()) {
      this.setContentAttribute(
        version,
        "compatibleOperatingSystems",
        this.getOperatingSystemAction(),
        this.toStringArray(this.getOperatingSystems()),
      );
    }
    if (this.getShowSoftwareProduct()) {
      this.setContentAttribute(
        version,
        "compatibleSoftwareProducts",
        this.getSoftwareProductAction(),
        this.toStringArray(this.getSoftwareProducts()),
      );
    }
    if (this.getShowTestedVersion()) {
      this.setContentAttribute(
        version,
        "testedVersions",
        this.getTestedVersionAction(),
        this.toStringArray(this.getTestedVersions()),
      );
    }
    if (this.getShowProductCode()) {
      this.replaceContentAttribute(version, "productCode", this.getProductCode());
    }
    if (this.getShowProductExpirationDate()) {
      this.replaceContentAttribute(version, "productExpirationDate", this.getProductExpirationDate().toString());
    }
    if (this.getShowVersionMeasurementUnit()) {
      this.setContentAttribute(
        version,
        "measurementUnits",
        this.getVersionMeasurementUnitAction(),
        this.toStringArray(this.getVersionMeasurementUnits()),
      );
    }
  }

  @action
  private setVersionsSelection(packageItem: IBatchEntity) {
    _.each(packageItem.versions || [], (version: IBatchVersion) => {
      if (packageItem.selected) {
        version.selected = true;
      }
    });
  }

  private tagsExist() {
    return this.tags && this.tags.length > 0;
  }

  private toStringArray(selectedOptions: IDropdownOption[]): string[] {
    return _.map(selectedOptions, (option) => {
      return option.value;
    });
  }

  @action
  private async toggleCollection(collection: IBatchCollection) {
    collection.open = !collection.open;
    if (collection.open) {
      await this.fetchPackagesForCollection(collection);
    }
  }

  @action
  private async togglePackage(packageItem: IBatchEntity) {
    packageItem.open = !packageItem.open;
    if (packageItem.open) {
      await this.fetchVersionsForPackage(packageItem);
    }
  }

  @action
  private updatePackages(): Promise<void> {
    return new Promise(async (resolve) => {
      if (!this.packageAttributesSelected()) {
        resolve();
      } else {
        const user = this.rootStore.getUserStore().getCurrentUser();
        const promises: Array<Promise<void>> = [];
        const packages: IBatchEntity[] = this.getSelectedPackages();

        !!user &&
          !_.isEmpty(packages) &&
          _.each(packages, (packageItem: IBatchEntity) => {
            this.setPackageAttributes(packageItem);

            promises.push(
              NewRepository.setPackage(packageItem, user).then(() => {
                runInAction(() => {
                  this.itemsProcessedCount = this.itemsProcessedCount + 1;
                });
              }),
            );
          });
        await Promise.all(promises);
        resolve();
      }
    });
  }

  private updateVersions(): Promise<void> {
    return new Promise(async (resolve) => {
      if (!this.versionAttributesSelected()) {
        resolve();
      } else {
        const user = this.rootStore.getUserStore().getCurrentUser();

        if (!!user) {
          const promises: Array<Promise<void>> = [];
          const versions: IBatchVersion[] = this.getSelectedVersions();
          _.each(versions, (version) => {
            this.setVersionAttributes(version);
            promises.push(
              NewRepository.updateVersion(version, user as IModifier).then(() => {
                runInAction(() => {
                  this.itemsProcessedCount = this.itemsProcessedCount + 1;
                });
              }),
            );
          });
          await Promise.all(promises);
          resolve();
        }
      }
    });
  }

  private validateAttributes() {
    if (!this.packageAttributesSelected() && !this.versionAttributesSelected()) {
      this.addErrorMessage("no_attributes_selected");
    } else {
      this.removeErrorMessage("no_attributes_selected");
    }

    if (this.invalidActionsExist()) {
      this.addErrorMessage("invalid_actions");
    } else {
      this.removeErrorMessage("invalid_actions");
    }

    if (this.invalidValuesExist()) {
      this.addErrorMessage("invalid_attributes");
    } else {
      this.removeErrorMessage("invalid_attributes");
    }
  }

  @action
  private validateCommentAction() {
    this.fieldErrors = this.removeErrorFromList("commenting");
    if (this.getShowCommenting()) {
      if (!this.getCommentAction()) {
        this.fieldErrors.push({
          fieldName: "commenting",
          invalidAction: true,
          invalidValue: false,
        });
      }
      this.validateAttributes();
    }
  }

  private validateContentSelection() {
    const packages: IBatchEntity[] = this.getSelectedPackages();
    const packagesSelected = !!packages && packages.length > 0;
    const versions: IBatchVersion[] = this.getSelectedVersions();
    const versionsSelected = !!versions && versions.length > 0;

    if (!packagesSelected && !versionsSelected) {
      this.addErrorMessage("content_not_selected");
    } else {
      this.removeErrorMessage("content_not_selected");
      if (!packagesSelected && this.packageAttributesSelected()) {
        this.addErrorMessage("entities_not_selected");
      } else {
        this.removeErrorMessage("entities_not_selected");
        if (!versionsSelected && this.versionAttributesSelected()) {
          this.addErrorMessage("versions_not_selected");
        } else {
          this.removeErrorMessage("versions_not_selected");
        }
      }
    }
  }

  private validateCopyright() {
    this.validateField("copyright", this.getShowCopyright(), this.getCopyrightAction(), this.getCopyright());
  }

  private validateDiscussionForumUrl() {
    this.validateUrlField(
      "discussionForumUrl",
      this.getShowDiscussionForumUrl(),
      this.getDiscussionForumUrlAction(),
      this.getDiscussionForumUrl(),
    );
  }

  @action
  private validateField(
    fieldName: string,
    showFields: boolean,
    action: IDropdownOption,
    values: IDropdownOption[] | string,
  ) {
    let invalidAction = false;
    let invalidValue = false;
    this.fieldErrors = this.removeErrorFromList(fieldName);

    if (showFields) {
      invalidAction = !action;
      if (_.isArray(values)) {
        invalidValue = values.length == 0;
      } else {
        invalidValue = !values;
      }
      if (invalidAction || invalidValue) {
        this.fieldErrors.push({
          fieldName: fieldName,
          invalidAction: invalidAction,
          invalidValue: invalidValue,
        });
      }
    }
    this.validateAttributes();
  }

  private validateHelpUrl() {
    this.validateUrlField("helpUrl", this.getShowHelpUrl(), this.getHelpUrlAction(), this.getHelpUrl());
  }

  @action
  private validateLicenseAction() {
    this.fieldErrors = this.removeErrorFromList("license");
    if (this.getShowLicense()) {
      if (!this.getLicenseAction()) {
        this.fieldErrors.push({
          fieldName: "license",
          invalidAction: true,
          invalidValue: false,
        });
      }
      this.validateAttributes();
    }
  }

  private validateLocationRestriction() {
    this.validateField(
      "locationRestriction",
      this.getShowLocationRestriction(),
      this.getLocationRestrictionAction(),
      this.getLocationRestrictions(),
    );
  }

  private validateMeasurementUnit() {
    this.validateField(
      "measurementUnit",
      this.getShowMeasurementUnit(),
      this.getMeasurementUnitAction(),
      this.getMeasurementUnits(),
    );
  }

  private validateOperatingSystem() {
    this.validateField(
      "operatingSystem",
      this.getShowOperatingSystem(),
      this.getOperatingSystemAction(),
      this.getOperatingSystems(),
    );
  }

  private validateProductCode() {
    this.validateField("productCode", this.getShowProductCode(), this.getProductCodeAction(), this.getProductCode());
  }

  private validateProductExpirationDate() {
    const dateStr = this.getProductExpirationDate() ? this.getProductExpirationDate().toString() : "";
    this.validateField(
      "productExpirationDate",
      this.getShowProductExpirationDate(),
      this.getProductExpirationDateAction(),
      dateStr,
    );
  }

  private validateSearchTypeCategory() {
    this.validateField(
      "searchTypeCategory",
      this.getShowSearchTypeCategory(),
      this.getSearchTypeCategoryAction(),
      this.getSearchTypeCategories(),
    );
  }

  private validateSoftwareProduct() {
    this.validateField(
      "softwareProduct",
      this.getShowSoftwareProduct(),
      this.getSoftwareProductAction(),
      this.getSoftwareProducts(),
    );
  }

  private validateSpecialTermsUrl() {
    this.validateUrlField(
      "specialTermsUrl",
      this.getShowSpecialTermsUrl(),
      this.getSpecialTermsUrlAction(),
      this.getSpecialTermsUrl(),
    );
  }

  private validateSupportUrl() {
    this.validateField("supportUrl", this.getShowSupportUrl(), this.getSupportUrlAction(), this.getSupportUrl());
  }

  private validateSupportedLanguage() {
    this.validateField(
      "supportedLanguage",
      this.getShowSupportedLanguage(),
      this.getSupportedLanguageAction(),
      this.getSupportedLanguages(),
    );
  }

  @action
  private validateTag() {
    let invalidAction = false;
    let invalidValue = false;
    this.fieldErrors = this.removeErrorFromList("tag");

    if (this.getShowTag()) {
      invalidAction = !this.getTagAction();
      invalidValue = !this.tags || this.tags.length == 0 || !checkTagsValidity(this.tags!.slice());

      if (invalidAction || invalidValue) {
        this.fieldErrors.push({
          fieldName: "tag",
          invalidAction: invalidAction,
          invalidValue: invalidValue,
        });
      }
    }
    this.validateAttributes();
  }

  private validateTestedVersion() {
    this.validateField(
      "testedVersion",
      this.getShowTestedVersion(),
      this.getTestedVersionAction(),
      this.getTestedVersions(),
    );
  }

  @action
  private validateUrlField(fieldName: string, showFields: boolean, action: IDropdownOption, value: string) {
    let invalidAction = false;
    let invalidValue = false;
    this.fieldErrors = this.removeErrorFromList(fieldName);

    if (showFields) {
      invalidAction = !action;
      invalidValue = !value || !this.isValidUrl(value);

      if (invalidAction || invalidValue) {
        this.fieldErrors.push({
          fieldName: fieldName,
          invalidAction: invalidAction,
          invalidValue: invalidValue,
        });
      }
    }
    this.validateAttributes();
  }

  private validateUseCategory() {
    this.validateField("useCategory", this.getShowUseCategory(), this.getUseCategoryAction(), this.getUseCategories());
  }

  private validateVersionMeasurementUnit() {
    this.validateField(
      "versionMeasurementUnit",
      this.getShowVersionMeasurementUnit(),
      this.getVersionMeasurementUnitAction(),
      this.getVersionMeasurementUnits(),
    );
  }

  private versionAttributesSelected(): boolean {
    return (
      this.getShowOperatingSystem() ||
      this.getShowSoftwareProduct() ||
      this.getShowTestedVersion() ||
      this.getShowProductCode() ||
      this.getShowProductExpirationDate() ||
      this.getShowVersionMeasurementUnit()
    );
  }
}

/**
 * Context objects for BatchEditDialogStore instances.
 */
export const BatchEditDialogStoreContext = createStoreContext<BatchEditDialogStore>(BatchEditDialogStore);

export const BatchContentHandlerContext = createContext<IBatchContentHandler>(
  new BatchEditDialogStore(new RootStore()),
);
