import _ from "underscore";
import { v4 as uuid } from "uuid";

import { NewRepository } from "../js/services/NewRepository";

import { RootStore } from "../stores/rootStore";
import { IVersionFormData } from "./version/uploadVersionStore";

import { I3DGeometryFile, IBinary, IEntity, IFile, IVersion } from "../models/dataModel";
import { ItemTypeEnum } from "../models/enums";

import { ThreeDGeometryXML } from "./ThreeDGeometryXML";

/**
 * SKP files importer.
 */
export class ThreeDGeometryFileImporter {
  /**
   * RootStore instance
   */
  private rootStore: RootStore;

  /**
   * A constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Uploades SKP file(s), generates and uploads XML metadata for each SKP file.
   * Creates or uses version provided as a parameter.
   * @param entity package
   * @param version version to be used or version data for new instance
   * @param threeDGeometryFiles SKP files to import
   */
  public async import(
    entity: IEntity,
    version: IVersion | IVersionFormData,
    threeDGeometryFiles: I3DGeometryFile[] = [],
  ): Promise<IVersion | undefined> {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!user) {
      const newVersion = await this.createVersionIfNeeded(entity, version);
      await this.upload3DFiles(entity, newVersion, threeDGeometryFiles);
      return NewRepository.getVersion(newVersion, user.id) as unknown as IVersion;
    }
  }

  private versionTypeGuard(versionOrForm: IVersion | IVersionFormData): versionOrForm is IVersion {
    return !!(versionOrForm as IVersion).id;
  }

  private async createBinary(version: IVersion, file: IFile, isLocal = false): Promise<IBinary> {
    const binary: IBinary = {
      file: file,
      reference: uuid(),
      attributes: {
        itemType: ItemTypeEnum.NO_ACTION,
        fileName: file.name,
      },
    };

    if (isLocal) {
      binary.location = `file://${file.id}`;
      binary.attributes!.originalName = file.name;
    }
    return NewRepository.addBinary(version, binary, this.rootStore.getUserStore().getCurrentUser()!.id);
  }

  private async upload3DFile(
    entity: IEntity,
    version: IVersion,
    threeDGeometryFile: I3DGeometryFile,
  ): Promise<IVersion> {
    const reference = uuid();
    const xmlFileName = `${reference}.xml`;
    const blob: IBinary = {
      blob: ThreeDGeometryXML.generateXML(entity, version, threeDGeometryFile),
      reference: reference,
      attributes: {
        fileName: xmlFileName,
        originalName: xmlFileName,
        itemType: ItemTypeEnum.GEOMETRY,
      },
    };
    await Promise.all([
      this.createBinary(version, threeDGeometryFile.geometryFile as IFile, entity.isLocal),
      this.createBinary(version, threeDGeometryFile.thumbnailFile as IFile, entity.isLocal),
      NewRepository.addBlob(version, blob, this.rootStore.getUserStore().getCurrentUser()!.id),
    ]);
    return version;
  }

  private upload3DFiles(
    entity: IEntity,
    version: IVersion,
    threeDGeometryFiles: I3DGeometryFile[] = [],
  ): Promise<IVersion[]> {
    const promises: Array<Promise<IVersion>> = _.map(threeDGeometryFiles, (threeDGeometryFile) => {
      return this.upload3DFile(entity, version, threeDGeometryFile);
    });

    return Promise.all(promises);
  }

  private async createVersionIfNeeded(entity: IEntity, version: IVersion | IVersionFormData): Promise<IVersion> {
    if (this.versionTypeGuard(version)) {
      return version;
    } else {
      const newVersion: IVersion = version as IVersion;
      newVersion.package = entity;
      newVersion.isLocal = entity.isLocal!;
      newVersion.visibility = entity.visibility!;
      if (!newVersion.attributes) {
        newVersion.attributes = {};
      }
      newVersion.attributes.licensesACL = entity.attributes!.licensesACL;
      return NewRepository.createVersion(
        newVersion,
        this.rootStore.getUserStore().getCurrentUser()!,
      ) as unknown as IVersion;
    }
  }
}
