 
export const CsAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["cs-CZ"]) {
      window.locale["cs-CZ"] = {};
    }

    window.locale["cs-CZ"].about = {
      navigation: {
        why: "Proč Tekla Warehouse?",
        usage_basics: "Základy Tekla Warehouse",
        "usage_basics__using-content": "Používání obsahu",
        "usage_basics__adding-content": "Přidávání obsahu",
        usage_basics__collection: "Kolekce",
        "usage_basics__content-item": "Položka obsahu",
        usage_basics__version: "Verze",
        getting_started: "Úvod",
        "getting_started__tekla-account-and-system-requirements": "Tekla account a systémové požadavky",
        "getting_started__tekla-warehouse-service-plugin": "Zásuvný modul Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Jak získám Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures a Tekla Warehouse",
        content: "Obsah v Tekla Warehouse",
        "content__3d-products": "Produkty 3D modelů",
        "content__custom-components": "Uživatelské komponenty",
        content__applications: "Aplikace",
        "content__profiles-shapes-etc": "Profily, materiály, šrouby, pruty atd.",
        "content__model-templates": "Předlohy modelů",
        "content__drawing-setup": "Nastavení výkresu",
        "content__report-templates-etc": "Předlohy výpisů a soubory vlastností",
        using_content: "Používání obsahu",
        creating_content: "Vytváření obsahu",
        "creating_content__getting-started-with-content-creation": "Úvod do vytváření obsahu",
        "creating_content__expert-help-for-creating-content": "Potřebujete odbornou pomoc s vytvářením obsahu?",
        application_developers: "Vývoj aplikací pro Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Proč Tekla Warehouse?",
        description_1:
          "Tekla Warehouse zahrnuje aplikace, uživatelské komponenty, prvky, profily, materiály jako různé jakosti oceli a betonu, šrouby, pruty, sítě, tvary a šablony pro Tekla Structures. Skutečná data o produktech dodavatele v modelu poskytují maximální přesnost rozměrů použitých prvků a materiálů při modelování. To vede k minimalizaci chyb a kvalitnějšímu plánování projektu.",
        description_2:
          "Služba Tekla Warehouse poskytuje centralizovaný přístup k tomuto obsahu, který lze nyní použít efektivnějším způsobem. Obsah ve službě Tekla Warehouse neustále přibývá.",
        description_3: "Pomocí Tekla Warehouse",
        feature_1:
          "Výrobci mohou své produkty a aplikace zpřístupnit uživatelům. Přesné 3D modely produktů lze jednoduše přenést do modelu Tekla a později při konstrukci snadno použít odpovídající produkt výrobce.",
        feature_2:
          "Pomocí své firemní sítě můžete sdílet obsah s kolegy a ukládat obsah na lokálním úložišti pro osobní použití.",
        description_4:
          "Díky vyhledávání ve službě Tekla Warehouse snadno najdete, co potřebujete, a to bez ohledu na typ obsahu.",
        description_5: "K povolení funkce síťových a lokálních kolekcí je nutný zásuvný modul Tekla Warehouse Service.",
        view_licences_html:
          'Zobrazit <a href="/api/third_party_licenses.txt" target="_blank">licence třetích stran</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Základy Tekla Warehouse",
        using_content: {
          title: "Používání obsahu",
          description_1:
            "Obsah můžete používat v online kolekcích, sdílet jej se svými kolegy pomocí síťových kolekcí a využívat lokální kolekce k soukromým účelům.",
          description_2:
            "Lokální a síťové kolekce vyžadují Tekla Warehouse Service, která je nainstalována s Tekla Structures 20.1 Service Release 1 a novějšími verzemi programu Tekla Structures.",
          description_3:
            "Spolu s programem Tekla Structures se instalují konkrétní kolekce Tekla s lokalizovaným obsahem prostředí.",
          description_4: "Další informace naleznete v části Používání obsahu.",
        },
        adding_content: {
          title: "Přidávání obsahu",
          description_1:
            'Pomocí online kolekcí můžete svůj obsah zpřístupnit všem uživatelům služby Tekla Warehouse. Abyste mohli obsah publikovat jako organizace, musí být vaše organizace přidána na adrese account.tekla.com. Další informace získáte u místní <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">kanceláře nebo prodejce Tekla</a>.',
          description_2: "Další informace naleznete v části Vytváření obsahu.",
        },
        collection: {
          title: "Kolekce",
          feature_1:
            "Kolekce seskupují položky obsahu, jako například složky, a definují přístupová práva k obsahu (veřejný/soukromý).",
          feature_1_1:
            "Tvůrci modelů mohou obsah používat v online kolekcích, sdílet jej se svými kolegy pomocí síťových kolekcí a využívat lokální kolekce k soukromým účelům.",
          feature_2:
            "Poskytovatelé obsahu mohou pomocí online kolekcí svůj obsah zpřístupnit všem uživatelům služby Tekla Warehouse.",
          feature_3:
            "Lokální a síťové sbírky vyžadují zásuvný modul Tekla Warehouse Service, který se instaluje spolu s Tekla Structures 20.1 Service Release 1 a novějšími verzemi a lze jej také nainstalovat samostatně.",
        },
        "content-item": {
          title: "Položka obsahu",
          feature_1:
            "Položka obsahu je logickou skupinou souborů. Mezi položky obsahu patří například katalog 3D produktů, předloha modelu, nástroj, skupina uživatelských komponentů nebo katalog profilů.",
          feature_2: "Položky obsahu lze označit podle typu a použití, což uživatelům pomůže váš obsah najít.",
        },
        version: {
          title: "Verze",
          feature_1:
            "Položka obsahu může mít zároveň mnoho verzí, které se používají spolu s různými verzemi programu Tekla Structures. Uživatel může vybrat správnou verzi ze stránky obsahu.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Úvod",
        tekla_account_and_system_requirements: {
          title: "Tekla account a systémové požadavky",
          register_atc_html:
            'K přihlášení do služby Tekla Warehouse je nutný účet Tekla account. Pokud jste se ještě nezaregistrovali, učiňte tak na adrese <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>. (Nejedná se o starý účet Extranet.)',
          see_tua_for_prerequisites_html:
            'Podívejte se do Tekla User Assistance, kde jsou uvedeny informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">požadavcích na používání služby Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Zásuvný modul Tekla Warehouse Service",
          description_1: "Kromě online úložiště obsahu vám Tekla Warehouse také poskytuje:",
          feature_1: "lokální kolekce pro osobní použití,",
          feature_2: "síťové kolekce pro sdílení obsahu s kolegy pomocí firemní sítě,",
          feature_3: "snadnou instalaci při používání nebo vytváření obsahu,",
          feature_4: "prohledávání lokálního a síťového obsahu.",
          description_2:
            "Toto je možné se zásuvným modulem Tekla Warehouse Service, který funguje jako lokální stanice komunikující s webovými stránkami Tekla Structures a Tekla Warehouse.",
          description_3:
            "Pokud modul Tekla Warehouse Service není nainstalován nebo neběží, služba Tekla Warehouse na to upozorní v horní části stránky, aby bylo možné problém vyřešit.",
          description_4:
            "Pokud se vyskytnou problémy se službou, lze ji restartovat pomocí programu Služby v systému Windows. Název služby je Tekla Warehouse. Zkontrolujte také bezpečnostní nastavení svého prohlížeče.",
          description_5:
            "Webové stránky Tekla Warehouse lze používat bez modulu Tekla Warehouse Service i programu Tekla Structures. Zkušenost tak bude bližší klasickému webu pro sdílení obsahu, kde se soubory manuálně nahrávají a stahují.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Jak získám Tekla Warehouse Service?",
          description_1:
            "Zásuvný modul Tekla Warehouse Service je nainstalován s programem Tekla Structures 20.1 Service Release 1 a novějším. Modul Tekla Warehouse Service lze také instalovat samostatně za účelem zpřístupnění lokálních a síťových sbírek. Funkce pro snadnou instalaci a nahrávání jsou ale k dispozici pouze s programem Tekla Structures 20.1 Service Release 1 a novějšími verzemi.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">STÁHNOUT TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures a Tekla Warehouse",
          description_1:
            "Služba Tekla Warehouse se připojuje přímo k programu Tekla Structures, díky čemuž je používání nebo nahrávání obsahu jednodušší. Můžete vybrat obsah přímo ze svého modelu nebo vložit obsah přímo do modelu bez nutnosti složitého importování/exportování dat (vyžaduje Tekla Structures 20.1 Service Release 1 a novější). Všechny potřebné soubory lze zahrnout do stejné položky obsahu, jako je uživatelský komponent nebo profil, abyste měli jistotu, že vše bude fungovat tak, jak jste zamýšleli.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Obsah v Tekla Warehouse",
        description_1:
          "Služba Tekla Warehouse nabízí několik typů obsahu. Zde naleznete přehled různých typů obsahu a další informace o tom, jak začít.",
        description_2:
          'Ze služby Tekla Warehouse můžete také stahovat <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">rozšíření Tekla</a>.',
        "3d_products": {
          title: "Produkty 3D modelů",
          image_subtitle: "Jedná se o objekty s pevnou geometrií, například zabudované prvky, bednění a příslušenství.",
          tua_link_html:
            'Další informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">vytváření katalogu 3D produktů v kolekci Tekla Warehouse</a> získáte ve službě Tekla User Assistance.',
        },
        custom_components: {
          title: "Uživatelské komponenty",
          image_subtitle:
            "Uživatelské komponenty jsou nativní objekty programu Tekla Structures s pevnou nebo parametrickou geometrií.",
          tua_link_html:
            'Další informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">ukládání obsahu z modelu Tekla Structures do kolekce Tekla Warehouse</a> získáte ve službě Tekla User Assistance.',
        },
        applications: {
          title: "Aplikace",
          image_subtitle:
            "K práci s programem Tekla Structures používají aplikace rozhraní Tekla Open API. Například integrace dat je propojena s řešením návrhu.",
          tua_link_html:
            'Další informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">nahrávání aplikace do kolekce Tekla Warehouse</a> získáte ve službě Tekla User Assistance.',
        },
        profiles_shapes_etc: {
          title: "Profily, tvary, materiály, šroubové sestavy, pruty a sítě",
          image_subtitle:
            "Katalogy obsahují seznamy vlastností souvisejících s prvky, například různé jakosti materiálů a jejich hustotu.",
          tua_link_html:
            'Další informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">ukládání obsahu z modelu Tekla Structures do kolekce Tekla Warehouse</a> získáte ve službě Tekla User Assistance.',
        },
        model_templates: {
          title: "Předlohy modelů",
          image_subtitle:
            "Předlohu modelu lze použít pro podobné, opakující se projekty. Předloha může obsahovat všechny potřebné katalogy, předlohy výpisů, nastavení výkresů a nastavení pokročilých možností.",
          tua_link_html:
            'Další informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">ručním k přidávání souborů do kolekce Tekla Warehouse</a> získáte ve službě Tekla User Assistance.',
        },
        drawing_setup: {
          title: "Nastavení výkresu",
          image_subtitle:
            "Soubory nastavení definují, jak se vytvářejí výkresy, např. rozvržení, filtry a šablony pro klonování.",
          tua_link_html:
            'Další informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">ručním k přidávání souborů do kolekce Tekla Warehouse</a> získáte ve službě Tekla User Assistance.',
        },
        report_templates_etc: {
          title: "Předlohy výpisů a soubory vlastností modelů",
          description_1:
            "Předlohy výpisů definují obsah a rozvržení dat modelu ve výpisu. Soubory vlastností modelů, např. filtry, fungují, když je přidáte do složky modelu.",
          tua_link_html:
            'Další informace o <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">ručním k přidávání souborů do kolekce Tekla Warehouse</a> získáte ve službě Tekla User Assistance.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Používání obsahu",
        description_1:
          "V závislosti na typu obsahu můžete vložit svůj zvolený obsah do svého modelu Tekla Structures nebo jej stáhnout a nainstalovat. Podívejte se do služby Tekla User Assistance, kde získáte pokyny k:",
        feature_1:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Hledání obsahu ve službě Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Používání obsahu ze služby Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Vkládání obsahu ze služby Tekla Warehouse do modelu Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Řešení konfliktů ve službě Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Vytváření obsahu",
        getting_started_with_content_creation: {
          title: "Úvod do vytváření obsahu",
          description_1: "Podívejte se do služby Tekla User Assistance, kde získáte další informace o:",
          feature_1:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_getting_started" target="_blank">Úvodu do služby Tekla Warehouse</a>',
          feature_2:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_adding_content" target="_blank">Přidávání obsahu do služby Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Úpravě obsahu ve službě Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Stahování obsahu ze služby Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Potřebujete odbornou pomoc s vytvářením obsahu?",
          description_1:
            'Pokud máte zájem o publikování obsahu ve službě Tekla Warehouse, ale ocenili byste odbornou pomoc při jeho vytváření a údržbě, kontaktujte místní <a href="http://www.tekla.com/contact" target="_blank">kancelář nebo prodejce Tekla</a>, kde se dozvíte další informace ohledně možností spolupráce.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Vývoj aplikací pro Tekla Structures",
        description_1:
          "Protože společnost Tekla má otevřený přístup k informačním modelům budov (BIM), můžete používat řešení a výrobní stroje jiných poskytovatelů a stále využívat rozhraní společnosti Tekla.",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> je specializované aplikační programovací rozhraní (API) vytvořené společností Tekla, které vám umožňuje vyvíjet aplikace a další funkce na modelovací platformě Tekla Structures a integrovat ji do vašeho vlastního prostředí. Rozhraní Tekla Open API je implementováno pomocí technologie Microsoft .NET.',
        description_3:
          'Centrem všech informací o rozhraní Tekla Open API je <a href="https://developer.tekla.com" target="_blank">Tekla Developer Center</a>.',
        description_4:
          'Pokud máte zájem o vývoj aplikací pro Tekla Structures, zapojte se do <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a>.',
      },
    };
  },
};
