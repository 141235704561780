
import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";

import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

import { ModusButton, ModusCheckbox } from "@trimble-oss/modus-react-components";
import { HideAndUnhide } from "./HideAndUnhide";
import { BanAndUnban } from "./BanAndUnban";

const SelectAll = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const [selectAll, setSelectAll] = React.useState(false);

  function handleSelectAll() {
    store.selectAllResults(!selectAll);
    setSelectAll(!selectAll);
  }

  return (
    <ModusCheckbox
      ariaLabel={rootStore.getTranslation("helpers.select_all")}
      disabled={store.isQuerying() || store.isProcessing()}
      onClick={handleSelectAll}
      data-testid="select-all"
      label="Select all on this page"
      checked={selectAll}
    />
  );
});

const Pagination = observer(() => {
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const cantGoToNextPage = store.isLastPage;
  const cantGoToPreviousPage = store.isQuerying() || store.isProcessing() || store.isFirstPage;

  function handleGoToNextPage() {
    if (!cantGoToNextPage) {
      store.goToNextPage();
      store.search();
    }
  }

  function handleGoToPreviousPage() {
    if (!cantGoToPreviousPage) {
      store.goToPreviousPage();
      store.search();
    }
  }

  function getCurrentResultsCount() {
    return `${store.getSearchOptions().offset}-${store.getSearchOptions().offset + store.getSearchOptions().count}`;
  }

  return (
    <Fragment>
      <div className="pagination-info">{`${getCurrentResultsCount()} of ${store.getResultsCount()}`}</div>
      <ModusButton
        id="previous-page"
        buttonStyle="borderless"
        color="secondary"
        iconOnly="chevron_left"
        disabled={cantGoToPreviousPage}
        aria-label="Previous page"
        onClick={handleGoToPreviousPage}
      ></ModusButton>
      <ModusButton
        id="next-page"
        buttonStyle="borderless"
        color="secondary"
        iconOnly="chevron_right"
        disabled={cantGoToNextPage}
        aria-label="Next page"
        onClick={handleGoToNextPage}
      ></ModusButton>
    </Fragment>
  );
});

const ActionsForSelectedContent = () => {
  return (
    <div className="actions-for-selected">
      <HideAndUnhide />
      <BanAndUnban />
    </div>
  );
};

const ContentSelectedInfo = observer(() => {
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  return (
    <div className="content-selected-info" style={{ paddingRight: "1em" }}>
      <span className="results-count" data-testid="resultsSelectedCount">
        {`${store.getSelectedResults().length} of ${store.getSearchOptions().count} ${store.getSearchTarget().toLowerCase()}s selected`}
      </span>
    </div>
  );
});

export const Actions = observer(() => {
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  return (
    <div className="content-manager-actions" data-testid="contentManagerActions">
      <div className="actions-left">
        <div>
          <ContentSelectedInfo />
          <SelectAll />
        </div>
        <div>
          <ActionsForSelectedContent />
        </div>
      </div>
      <div className="actions-right">{!store.isQuerying() && store.getResultsCount() > 0 && <Pagination />}</div>
    </div>
  );
});
