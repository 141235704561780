import { createContext } from "react";
import { action, observable, runInAction, toJS, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../../js/services/NewRepository";
import { IDialogActionsStore, IDropdownOption, IEntity, IGroupAndCategoryHandler } from "../../models/dataModel";
import { RootStore, createStoreContext } from "../../stores/rootStore";
import { isSameArrayContent } from "../../utils/functions";

/**
 * Store for handling group and category data
 */
export class GroupAndCategoryStore implements IDialogActionsStore, IGroupAndCategoryHandler {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Package item object
   */
  @observable private packageItem: IEntity | undefined;

  /**
   * Should notify subscribers about changes
   */
  @observable private doNotNotify = false;

  /**
   * ItemTypeCategories for package
   */
  @observable private itemTypeCategories?: IDropdownOption[];

  /**
   * Usecategories for package
   */
  @observable private useCategories?: IDropdownOption[];

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param packageItem Package object
   */

  public constructor(rootStore: RootStore, packageItem?: IEntity) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (packageItem) {
      runInAction(() => {
        this.packageItem = packageItem;
      });
    }
    this.init();
  }

  /**
   * Gets do not notify field value
   * @retuns do not notify value
   */
  public getDoNotNotify(): boolean {
    return this.doNotNotify;
  }

  /**
   * Sets do not notify field value
   * @params doNotNotify the value to be set
   */
  @action
  public setDoNotNotify(doNotNotify: boolean) {
    this.doNotNotify = doNotNotify;
  }

  /**
   * Checks if form is valid
   * @returns true if form is valid
   */
  public isFormValid() {
    return true;
  }

  /**
   * Gets item type categories
   * @retuns list of item type categories
   */
  public getSearchTypeCategories(): IDropdownOption[] {
    return toJS(this.itemTypeCategories) || [];
  }

  /**
   * Checks if package is local
   * @returns true if package is local
   */
  public isLocalContent(): boolean {
    return !!this.packageItem && this.packageItem.isLocal!;
  }

  /**
   * Sets item type categories
   * @params selectedOptions selected item type categories
   */
  @action
  public setSearchTypeCategories(selectedOptions: IDropdownOption[]) {
    this.itemTypeCategories = selectedOptions;
  }

  /**
   * Gets use categories
   * @retuns list of use categories
   */
  public getUseCategories(): IDropdownOption[] {
    return toJS(this.useCategories) || [];
  }

  /**
   * Sets use categories
   * @params selectedOptions selected use categories
   */
  @action
  public setUseCategories(selectedOptions: IDropdownOption[]) {
    this.useCategories = selectedOptions;
  }

  /**
   * Saves package information
   */
  @action
  public async saveContent() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!this.packageItem && (!!user || this.packageItem?.isLocal)) {
      const useCategories = (this.useCategories || []).map((opt) => opt.value);
      const itemTypeCategories = (this.itemTypeCategories || []).map((opt) => opt.value);

      if (
        !isSameArrayContent(useCategories, this.packageItem.attributes!.useCategories) ||
        !isSameArrayContent(itemTypeCategories, this.packageItem.attributes!.itemTypeCategories)
      ) {
        const attributes = {
          ...(!isSameArrayContent(useCategories, this.packageItem.attributes!.useCategories) && {
            useCategories: useCategories,
          }),
          ...(!isSameArrayContent(itemTypeCategories, this.packageItem.attributes!.itemTypeCategories) && {
            itemTypeCategories: itemTypeCategories,
          }),
        };

        const removedAttributes = {
          useCategories: _.difference(this.packageItem.attributes!.useCategories || [], useCategories),
          itemTypeCategories: _.difference(this.packageItem.attributes!.itemTypeCategories || [], itemTypeCategories),
        };

        try {
          await NewRepository.setContentAttribute(
            this.packageItem,
            attributes,
            removedAttributes,
            this.doNotNotify,
            user?.id,
          );

          runInAction(() => {
            this.packageItem!.attributes!.useCategories = useCategories;
            this.packageItem!.attributes!.itemTypeCategories = itemTypeCategories;
          });

          this.showSuccessMessage();
        } catch {
          this.showErrorMessage();
        }
      }
    }
  }

  /**
   * Initializes store fields
   */

  public init() {
    if (this.packageItem) {
      this.setSearchTypeCategories(
        _.map(this.packageItem.attributes!.itemTypeCategories!, (key) => {
          return {
            label: this.rootStore.getTranslation("itemTypeCategories." + key),
            value: key,
          };
        }),
      );
      this.setUseCategories(
        _.map(this.packageItem.attributes!.useCategories!, (key) => {
          return {
            label: this.rootStore.getTranslation("useCategories." + key),
            value: key,
          };
        }),
      );
    }
  }

  private showSuccessMessage() {
    this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("details.updated_successfully"));
  }

  private showErrorMessage() {
    this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("details.update_failed"));
  }
}

/**
 * Context object for GroupAndCategoryStore instances.
 */
export const GroupAndCategoryStoreContext = createStoreContext<GroupAndCategoryStore>(GroupAndCategoryStore);

export const GroupAndCategoryHandlerContext = createContext<IGroupAndCategoryHandler>(
  new GroupAndCategoryStore(new RootStore()),
);
