import * as React from "react";
import { useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { PopupStatusStore, PopupStatusStoreContext } from "./popupStatusStore";
import { IDropdownOption } from "../../models/dataModel";

const PopupToggler = () => {
  const popupStatusStore: PopupStatusStore = useContext(PopupStatusStoreContext);
  const rootStore: RootStore = useContext(RootContext);
  const selectedLocaleOption: IDropdownOption | undefined = rootStore.getLocalizationStore().getSelectedLocaleOption();

  function getSelectedLocaleName() {
    return selectedLocaleOption ? selectedLocaleOption.label : "";
  }

  function openPopup() {
    popupStatusStore.togglePopup("language");
  }

  return (
    <a id="language-menu" data-testid="toggle-popup" className="global-item popup-wrapper-item" onClick={openPopup}>
      <span className="icon icon-globe" />
      <span>{getSelectedLocaleName()}</span>
      <span className="icon icon-arrow-down" />
    </a>
  );
};

const Popup = () => {
  const rootStore = useContext(RootContext);
  const localizationStore = rootStore.getLocalizationStore();
  const selectedLocaleOption = localizationStore.getSelectedLocaleOption();

  function getSelectedLocaleValue() {
    return selectedLocaleOption ? selectedLocaleOption.value : "";
  }

  return (
    <ul className="popup language-selection">
      {localizationStore.getLocaleOptions().map((localeOption: IDropdownOption, i: number) => {
        return (
          <div className="language-options" key={i}>
            <li
              className={classNames("lang", {
                selected: localeOption.value === getSelectedLocaleValue(),
              })}
              onClick={() => localizationStore.changeLocale(localeOption.value)}
            >
              <a data-testid={"lang-option-" + localeOption.value}>{localeOption.label}</a>
            </li>
          </div>
        );
      })}
    </ul>
  );
};

/**
 * A component that displays language menu
 * @param store PopupStatusStore object.
 */
export const LanguageMenu = observer(() => {
  const popupStatusStore = useContext(PopupStatusStoreContext);

  return (
    <div className="global-block">
      <div
        className={classNames("popup-wrapper", {
          open: popupStatusStore.isPopupOpen("language"),
        })}
      >
        <PopupToggler />
        <Popup />
      </div>
    </div>
  );
});
