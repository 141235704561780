import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import { TCCOrganizationService } from "../js/services/TCCOrganizationService";
import { OrganizationFilterAttributesConverter } from "../utils/converters/OrganizationFilterAttributesConverter";

/**
 * A component that queries organizations by custom name and redirects to found organization page.
 */
export const OrganizationByNameWrapper = () => {
  const { orgname } = useParams();
  const navigate = useNavigate();

  async function fetchOrganizationByCustomName(): Promise<any> {
    const filterQuery = OrganizationFilterAttributesConverter.toTCC({
      customWarehouseUrlFilter: orgname,
    });
    const queryData = {
      fq: filterQuery,
    };

    let to: string;
    try {
      const res = await TCCOrganizationService.search(queryData);
      if (res.entries && res.entries.length === 1) {
        to = "/organization/" + res.entries[0].id;
      } else {
        to = "/landing";
      }
    } catch {
      to = "/landing";
    }

    return navigate(to);
  }

  useEffect(() => {
    fetchOrganizationByCustomName();
  }, []);

  return <div></div>;
};
