import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";
import { PackagePageStore, PackagePageStoreContext } from "./packagePageStore";
import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";
import { DialogContext, DialogStore } from "../dialogs/dialogStore";

import { IEntity } from "../models/dataModel";
import { Dialog } from "../dialogs/Dialog";
import { AnalyticsService } from "js/services/AnalyticsService";

export const AddPartnerDownloadLinkDialog = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);

  const [url, setUrl] = useState<string>(packagePageStore.getPackage()?.attributes?.contentDownloadUrl || "https://");
  const [dontNotify, setDontNotify] = useState<boolean>(false);

  function handleInput(e) {
    setUrl(e.target.value);
  }

  function handleChangeDontNotify() {
    setDontNotify(!dontNotify);
  }

  function cancel() {
    dialog.close();
  }

  async function updatePartnerDownloadUrl(e) {
    e.preventDefault();

    await packagePageStore.editPartnerDownloadURL(url, dontNotify);
    dialog.close();
  }

  return (
    <article className="edit-dialog" data-testid="editPartnerDownloadLinkDialog">
      <header>
        <h2>{rootStore.getTranslation("shared.catalog_entity_edit.edit_download_link")}</h2>
      </header>
      <form onSubmit={updatePartnerDownloadUrl}>
        <section className="not-scrollable">
          <p>{rootStore.getTranslation("shared.catalog_entity_edit.partner_download_link_disclaimer")}</p>
          <label>{rootStore.getTranslation("shared.catalog_entity_edit.partner_download_link")}</label>
          <input
            type="url"
            pattern="https?://.+"
            required
            className="partner-download-url-input"
            onChange={handleInput}
            value={url}
            placeholder={rootStore.getTranslation("shared.catalog_entity_edit.partner_download_link_placeholder")}
            style={{ width: "100%" }}
          />
        </section>
        <div className="actions">
          <div className="dont-notify">
            <input type="checkbox" id="dontNotify" onChange={handleChangeDontNotify} data-testid="dontNotify" />
            <label htmlFor="dontNotify">{rootStore.getTranslation("shared.catalog_entity_edit.do_not_notify")}</label>
          </div>
          <button onClick={cancel} data-testid="cancelEdit">
            {rootStore.getTranslation("actions.cancel")}
          </button>
          <button type="submit" className="button-primary" data-testid="updatePartnerDownloadUrl">
            {rootStore.getTranslation("actions.save")}
          </button>
        </div>
      </form>
    </article>
  );
});

const AddPartnerDownloadLink = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const dialog: DialogStore = new DialogStore();
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function canEdit() {
    return !!packageItem && packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function addPartnerDownloadLink() {
    dialog.open();
  }

  return (
    <Fragment>
      {canEdit() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-description-dialog"
            title={rootStore.getTranslation("shared.catalog_entity_edit.edit_download_link")}
            onClick={addPartnerDownloadLink}
            className="icon icon-pencil"
          >
            <span>{rootStore.getTranslation("shared.catalog_entity_edit.edit_download_link")}</span>
          </a>
          <Dialog content={<AddPartnerDownloadLinkDialog />} additionalClass={"basic-edit"} />
        </DialogContext.Provider>
      )}
    </Fragment>
  );
});

export const DownloadOnPartnerSiteButton = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem: IEntity | undefined = packagePageStore.getPackage();

  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const isEditing: boolean = editorModeStore.getEditorMode();

  function shouldShowDownloadOnPartnerSiteButton(): boolean {
    return packagePageStore.hasPartnerDownloadURL();
  }

  function canDownload(): boolean {
    return !!packageItem && rootStore.getUserStore().canDownload(packageItem);
  }

  async function moveToPartnerSite() {
    await AnalyticsService.movedToPartnerSite(rootStore.getUserStore().getCurrentUser(), packageItem);
    window.open(packageItem?.attributes?.contentDownloadUrl, "_blank", "noreferrer");
  }

  return (
    <Fragment>
      {isEditing && <AddPartnerDownloadLink />}
      {shouldShowDownloadOnPartnerSiteButton() && canDownload() && (
        <button
          onClick={moveToPartnerSite}
          className="download-on-partner-site"
          data-testid="downloadOnPartnerSiteButton"
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          {rootStore.getTranslation("actions.download_on_partner_site")}
          <img src="/images/external-link.svg" alt="External link" style={{ paddingLeft: "6px", color: "white" }} />
        </button>
      )}
    </Fragment>
  );
});
