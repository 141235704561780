import { metadataConstants } from "../constants/MetadataConstants";
import { countries } from "../constants/CountriesConstants";
import _ from "underscore";

/**
 * Helper class for metadata options
 */
export const metadataLabels = {
  measurementUnitOptions: metadataConstants.measurementUnitKeys.map((key) => {
    return {
      name: "measurementUnits." + key,
      value: key,
      category: "measurementUnits",
    };
  }),
  itemTypeCategoriesOptions: metadataConstants.itemTypeCategoriesKeys.map((key) => {
    return {
      name: "itemTypeCategories." + key,
      value: key,
      category: "itemTypeCategories",
    };
  }),
  compatibleOperatingSystemsOptions: metadataConstants.compatibleOperatingSystemsKeys.map((key) => {
    return { name: "compatibleOperatingSystems." + key, value: key };
  }),
  softwareProductsOptions: metadataConstants.softwareProductsOptionsKeys.map((key) => {
    return { name: "compatibleSoftwareProductOptions." + key, value: key };
  }),
  testedVersionsOptions: metadataConstants.testedVersionsKeys.map(
    (value: string | { software: string; versions: string[] }) => {
      if (typeof value === "string") {
        return { label: `testedVersions.${value}`, value };
      } else {
        return {
          label: value.software,
          options: value.versions.map((version) => {
            return { label: `testedVersions.${version}`, value: version };
          }),
        };
      }
    },
  ),
  qualityOptions: metadataConstants.qualityKeys.map(function (key) {
    return { name: "qualityTags." + key, value: key };
  }),
  supportedLanguagesOptions: metadataConstants.supportedLanguagesKeys.map((key) => {
    return {
      name: "isoLangs." + key + ".name",
      value: key,
      category: "languages",
    };
  }),
  countryOptions: _.map(countries, (country) => {
    return {
      name: "constants.countries." + country.ccode,
      value: country.ccode,
      group: country.region,
      category: "locations",
    };
  }),
  fileTypeOptions: metadataConstants.contentItemKeys.map((key) => {
    return { name: "contentItemSource." + key, value: key };
  }),
  platformOptions: metadataConstants.platformKeys.map((key) => {
    return { name: "platforms." + key, value: key };
  }),
};
