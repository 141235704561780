import * as React from "react";
import { IDropdownOption, IMultiDropdownOnChange, IDropdownOptionGroup } from "../models/dataModel";
import Select from "react-select";

/**
 * A dropdown component that supports selecting multiple options.
 * @param options list of available options
 * @param selectedValues list of selected options (optional)
 * @param placeholder placeholder string (optional)
 * @param onChange the onChange function
 * @param className className that determines dropdown style
 * @param inputId id of the dropdown, used to create testId (optional)
 */
export const DropdownMultiple = ({
  ...props
}: {
  options: Array<IDropdownOption | IDropdownOptionGroup>;
  selectedValues?: IDropdownOption[];
  placeholder?: string;
  onChange: IMultiDropdownOnChange;
  className: string;
  inputId?: string;
  menuHeight?: number;
}) => {
  const testId = props.inputId ? `dropdown-${props.inputId}` : "dropdown";
  const maxMenuHeight = props.menuHeight ? props.menuHeight : 300;
  return (
    <div className={props.className} data-testid={testId}>
      <Select
        options={props.options}
        value={props.selectedValues}
        onChange={props.onChange}
        placeholder={props.placeholder}
        inputId={props.inputId}
        isMulti
        maxMenuHeight={maxMenuHeight}
      />
    </div>
  );
};
