import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";

import { SanitizationModeEnum } from "../../models/enums";
import { RootContext, RootStore } from "../../stores/rootStore";
import { PackagePageStore, PackagePageStoreContext } from "../packagePageStore";
import { SupportStore, SupportStoreContext } from "./supportStore";
import { EditorModeStore, EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { TextWrapper } from "../../components/TextWrapper";
import { EditSupportInfo } from "../dialogs/EditSupportInfo";

const CopyrightInfo = observer(() => {
  const store: SupportStore = useContext(SupportStoreContext);
  const copyrightPrefix = "&copy;&nbsp;";

  function getCopyrightText(): string {
    const text = store.getSupportFieldUrl("copyright");
    if (text != "") {
      return copyrightPrefix + text;
    } else return text;
  }

  return (
    <Fragment>
      {getCopyrightText() && (
        <div className="support-item">
          <div className="copyright" data-testid="copyright-info">
            <span>
              <TextWrapper text={getCopyrightText()} sanitizationMode={SanitizationModeEnum.STRICT} />
            </span>
          </div>
        </div>
      )}
    </Fragment>
  );
});

const SupportItem = observer(({ url, text }: { url: string; text: string }) => {
  const testId = "support-item-" + text;
  function attributesExist(): boolean {
    return url != "" && text != "";
  }

  return (
    <Fragment>
      {attributesExist() && (
        <div className="support-item">
          <a data-testid={testId} href={url} target="blank" rel="noopener noreferrer">
            <span>
              <TextWrapper text={text} sanitizationMode={SanitizationModeEnum.STRICT} />
            </span>
          </a>
        </div>
      )}
    </Fragment>
  );
});

const SpecialTerms = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: SupportStore = useContext(SupportStoreContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function getSpecialTermsUrl() {
    return !!packageItem && packageItem.attributes!["specialTermsUrl"]
      ? packageItem.attributes!["specialTermsUrl"]
      : "";
  }

  return (
    <Fragment>
      {store.getSupportFieldUrl("specialTermsUrl") && (
        <div className="support-item">
          <a
            data-testid="special-terms"
            id="specialTermsUrl"
            href={getSpecialTermsUrl()}
            target="blank"
            rel="noopener noreferrer"
          >
            <span>
              <TextWrapper
                text={rootStore.getTranslation("details.specialTermsUrl")}
                sanitizationMode={SanitizationModeEnum.STRICT}
              />
            </span>
            <label className="tooltip-info" title={rootStore.getTranslation("upload.specialTermsUrlSubtitle")}>
              <span className="icon help blue" />
            </label>
          </a>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that displays support information on properties tab.
 */
export const Support = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const packageItem = packagePageStore.getPackage();

  function showEditButton(): boolean {
    return packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function getHelpUrl() {
    return !!packageItem && packageItem.attributes!["helpUrl"] ? packageItem.attributes!["helpUrl"] : "";
  }

  function getDiscussionForumUrl() {
    return !!packageItem && packageItem.attributes!["discussionForumUrl"]
      ? packageItem.attributes!["discussionForumUrl"]
      : "";
  }

  function getSupportUrl() {
    return !!packageItem && packageItem.attributes!["supportUrl"] ? packageItem.attributes!["supportUrl"] : "";
  }

  return (
    <div className="support">
      <CopyrightInfo />
      {showEditButton() && (
        <DialogContext.Provider value={new DialogStore()}>
          <EditSupportInfo />
        </DialogContext.Provider>
      )}
      <SupportItem url={getHelpUrl()} text={rootStore.getTranslation("details.helpUrl")} />
      <SupportItem url={getDiscussionForumUrl()} text={rootStore.getTranslation("details.supportForumUrl")} />
      <SupportItem url={getSupportUrl()} text={rootStore.getTranslation("details.supportUrl")} />
      <SpecialTerms />
    </div>
  );
});
