import { LocalVersionsDS } from "../data-source/LocalVersionsDS";
import { VersionConverter } from "../converters/VersionConverter";

export const LocalVersionService = {
  create: function (data) {
    // var test = VersionConverter.toLocal(data);
    return LocalVersionsDS.setVersion(VersionConverter.toLocal(data))
      .then(function (res) {
        return LocalVersionsDS.getVersion({ id: res.id });
      })
      .then(VersionConverter.fromLocal);
  },
  update: function (data) {
    // var testupdate = VersionConverter.toLocal(data);
    return LocalVersionsDS.setVersion(VersionConverter.toLocal(data))
      .then(function (res) {
        return LocalVersionsDS.getVersion({ id: res.id });
      })
      .then(VersionConverter.fromLocal);
  },
  get: function (data) {
    return LocalVersionsDS.getVersion(data).then(VersionConverter.fromLocal);
  },
  remove: function (data) {
    return LocalVersionsDS.deleteVersion(data);
  },
};
