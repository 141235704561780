import * as React from "react";
import { useState, Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../stores/rootStore";
import { VersionEditorStore, VersionEditorContext } from "./versionEditorStore";

import { AddFiles } from "../upload/version/AddFiles";
import { VersionInformation } from "../upload/version/VersionInformation";
import { versionNumberIsUniqueForThePackage } from "../utils/Immutability";

/**
 * Main component for the Add version page.
 */
export const AddVersion = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const versionEditor: VersionEditorStore = useContext(VersionEditorContext);
  const versionForm = versionEditor.getVersionForm();

  const [saved, setSaved] = useState(false);
  const querying = versionEditor.isLoading();

  function updateVersion() {
    setSaved(true);
    versionEditor.updateVersion(true);
  }

  function canUpdateVersion() {
    return (
      versionForm.getVersion() &&
      versionForm.isFormValid() &&
      ((versionEditor.getFormStore().isImmutable() &&
        versionNumberIsUniqueForThePackage(
          versionForm.getVersion().attributes.versionNumber,
          versionEditor.getExistingVersionsWithoutVersionInEdit(),
        )) ||
        !versionEditor.getFormStore().isImmutable())
    );
  }

  const saveDisabled = saved || !canUpdateVersion();

  return (
    <Fragment>
      {!querying && (
        <div data-testid="addVersionContent">
          <hr />
          <section className="files">
            <AddFiles />
          </section>
          <hr />
          <section className="version-metadata">
            <VersionInformation />
          </section>
          <section className="actions actions-bottom">
            <div className="previous-and-next">
              <button className="button-large l-right button-primary" onClick={updateVersion} disabled={saveDisabled}>
                {rootStore.getTranslation("actions.save")}
              </button>
            </div>
          </section>
        </div>
      )}
    </Fragment>
  );
});
