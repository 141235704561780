import { observable, action, computed, runInAction, makeObservable, toJS } from "mobx";
import _ from "underscore";
import * as React from "react";

import { Creator } from "../../utils/converters/Creator";
import { NewRepository } from "../../js/services/NewRepository";

import { RootStore } from "../../stores/rootStore";
import { UploadFormStore } from "../uploadFormStore";
import {
  ICollection,
  IModifier,
  IDropdownOption,
  IDropdownOptionGroup,
  ICollectionFormData,
} from "../../models/dataModel";
import { ExternalResourceTypeEnum } from "../../models/enums";
import { organizationIsImmutable } from "../../utils/Immutability";

export enum CollectionFormUploadMethod {
  CREATE_NEW_ONLINE_COLLECTION = "createNewOnlineCollection",
  CREATE_NEW_LOCAL_COLLECTION = "createNewLocalCollection",
  SELECT_EXISTING_COLLECTION = "selectCollection",
}

export class UploadCollectionStore {
  public static readonly LOCATION_REGEXP = /^(([a-zA-Z]:\\)|(\\\\+[A-Za-zäÄöÖåÅ0-9]))($|(?!.*?[._]{2})[^\/:?<>*|]+$)/;

  /** RootStore */
  private rootStore: RootStore;

  /** Store that holds upload form data */
  private form: UploadFormStore;

  /** List of organizations the user belongs to, */
  @observable private organizations: IModifier[] = [];

  /** Folder location for local collection */
  @observable private collectionLocation = "";

  /** Dropdown option for selected collection */
  @observable private selectedCollectionOption?: IDropdownOption;

  /** Dropdown option for selected creator organization */
  @observable private selectedCreatorOrganizationOption?: IDropdownOption;

  /** List of collections the user has upload rights to */
  @observable private collections: ICollection[] = [];

  /** The upload method. */
  @observable private uploadMethod?: CollectionFormUploadMethod;

  /** Collection object that stores either
   * * The selected collection
   * * An empty collection form data object
   */
  @observable private collection?: ICollection | ICollectionFormData;

  /** Empty collection object */
  private newCollection: ICollectionFormData = {
    id: "",
    creator: {
      displayName: "",
      externalResourceType: ExternalResourceTypeEnum.USER,
      id: "",
      pictureUrl: "",
      trimbleId: "",
      isVerified: false,
    },
    description: "",
    isLocal: false,
    thumbnail: undefined,
    title: "",
    type: "",
    visibility: "",
  };

  /**
   * Constructor
   * @param rootStore RootStore
   * @param form UploadFormStore, stores all upload form information
   */
  public constructor(rootStore: RootStore, form: UploadFormStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.form = form;
  }

  /** Returns the selected upload method. */
  public getUploadMethod(): CollectionFormUploadMethod | undefined {
    return this.uploadMethod;
  }

  /** Sets collections available to the user. */
  @action
  public setAvailableCollections(collections: ICollection[]) {
    this.collections = collections;
  }

  /**
   * Finds a collection from available collections based on id.
   * @param id id of the collection
   */
  @action
  public async setSelectedCollectionFromId(id: string, isLocal?: boolean) {
    if (!!isLocal) this.uploadMethod = CollectionFormUploadMethod.CREATE_NEW_LOCAL_COLLECTION;

    this.collection = _.find(this.collections, (c) => {
      return c.id === id;
    });
    if (!this.collection) {
      const params = !!isLocal ? { id: id, isLocal: true } : { id: id };

      try {
        const collection = (await NewRepository.getCollection(
          params,
          this.rootStore.getUserStore().getCurrentUser()?.id || "",
        )) as unknown as ICollection;

        const isImmutable =
          collection.creator?.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION &&
          (await organizationIsImmutable(collection.creator.id));

        runInAction(async () => {
          this.collection = collection;
          this.form.setIsImmutable(isImmutable);
          this.form.setCollectionTitle(this.collection!.title!);
        });
      } catch {
        console.log("Error fetching collection");
      }
    }
  }

  /** Returns the selected collection object. */
  public getCollection() {
    return this.collection!;
  }

  /** Returns if the collection is local. */
  public isLocal() {
    return this.collection && this.collection.isLocal;
  }

  /** Returns if the collection is new. */
  public isNewCollection() {
    return !!this.uploadMethod && this.uploadMethod.includes("New");
  }

  /** Returns the name of the selected collection. */
  public getTitle(): string | undefined {
    if (this.collection) return this.collection.title;
  }

  /**
   * Sets a title for the collection.
   * @param title title of the collection
   */
  @action
  public setTitle(title: string) {
    this.collection!.title = title;
    this.form.setCollectionTitle(title);
  }

  /** Returns the collection description. */
  public getDescription(): string | undefined {
    if (this.collection) return this.collection.description;
  }

  /**
   * Sets a description for the collection.
   * @param description description of the collection
   */
  @action
  public setDescription(description: string) {
    this.collection!.description = description;
  }

  /** Returns the selected owner type. */
  public getCreatorType(): ExternalResourceTypeEnum {
    return this.collection!.creator!.externalResourceType;
  }

  /**
   * Sets a owner type for the collection.
   * @param to selected owner type
   */
  @action
  public setCreatorType(to: ExternalResourceTypeEnum) {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user && this.collection && to === ExternalResourceTypeEnum.USER) {
      this.collection.creator = Creator.convert(user);
      this.form.setIsImmutable(false);
    } else if (!!user && this.collection && to === ExternalResourceTypeEnum.ORGANIZATION) {
      if (!!this.selectedCreatorOrganizationOption) {
        this.setCreatorOrganizationOption(this.selectedCreatorOrganizationOption);
      } else {
        this.collection.creator = Creator.convert({}, ExternalResourceTypeEnum.ORGANIZATION);
      }
    }
  }

  /** Returns the selected visibility type. */
  public getVisibilityType() {
    return this.collection!.visibility;
  }

  /**
   * Returns the selected visibility type.
   * @param to selected visibility type
   */
  @action
  public setVisibilityType(to: string) {
    this.collection!.visibility = to;
  }

  /** Returns if user belongs to organizations. */
  public hasOrganizations(): boolean {
    return !!this.organizations && this.organizations.length > 0;
  }

  /**
   * Sets user's organizations.
   * @param organizations list of organizations
   */
  @action
  public setOrganizations(organizations: IModifier[]) {
    this.organizations = organizations;

    if (organizations.length === 1) {
      this.selectedCreatorOrganizationOption = {
        value: organizations[0].id,
        label: organizations[0].displayName,
      };
    }
  }

  /** Returns the name of user's primary organization. */
  public getOrganizationName(): string | undefined {
    if (this.organizations && this.organizations[0]) return this.organizations[0].displayName;
  }

  /** Returns a list of available organizations. */
  public getOrganizationOptions(): IDropdownOption[] {
    return _.map(this.organizations, (organization: IModifier) => ({
      value: organization.id,
      label: organization.displayName,
    }));
  }

  /**
   * Sets the selected creator organization option
   */
  @action
  public async setCreatorOrganizationOption(option: IDropdownOption) {
    this.selectedCreatorOrganizationOption = option;

    this.collection!.creator = Creator.convert(
      _.find(this.organizations, (o: IModifier) => o.id === option.value),
      ExternalResourceTypeEnum.ORGANIZATION,
    );

    if (await organizationIsImmutable(option.value)) {
      this.form.setIsImmutable(true);
    } else {
      this.form.setIsImmutable(false);
    }
  }

  /**
   * Returns the selected creator organization option
   * */
  public getCreatorOrganizationOption(): IDropdownOption | undefined {
    return this.selectedCreatorOrganizationOption;
  }

  /**
   * Sets the collection thumbnail.
   * @param thumbnail given thumbnail
   */
  @action
  public setThumbnail(thumbnail) {
    this.collection = _.extend(this.collection, { thumbnail: thumbnail });
  }

  /** Returns the collection thumbnail. */
  public getThumbnail() {
    return this.collection!.thumbnail;
  }

  /**
   * Sets the folder location for a local collection.
   * @param to the folder location string
   */
  @action
  public setLocation(to: string) {
    this.collectionLocation = to;
  }

  /** Returns the folder location for a local collection. */
  public getLocation(): string | undefined {
    return this.collectionLocation;
  }

  /**
   * Sets the form state, called when an option is chosen from the dropdown.
   * @param option the selected dropdown option
   */
  @action
  public async setFormStateFromOption(option: IDropdownOption) {
    if (option) {
      this.selectedCollectionOption = option;
      if (option.value === "online") {
        this.uploadMethod = CollectionFormUploadMethod.CREATE_NEW_ONLINE_COLLECTION;
        this.collection = this.newCollection;
        this.collection.isLocal = false;
        this.setCreatorType(ExternalResourceTypeEnum.USER);
        this.collection.visibility = "private";
      } else if (option.value === "local") {
        this.uploadMethod = CollectionFormUploadMethod.CREATE_NEW_LOCAL_COLLECTION;
        this.collection = this.newCollection;
        this.collection.isLocal = true;
      } else {
        this.uploadMethod = CollectionFormUploadMethod.SELECT_EXISTING_COLLECTION;
        const collection = _.find(this.collections, (c: ICollection) => {
          return c.id === option.value;
        });
        this.collection = collection;
        if (
          collection?.creator?.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION &&
          (await organizationIsImmutable(collection?.creator?.id))
        ) {
          this.form.setIsImmutable(true);
        } else {
          this.form.setIsImmutable(false);
        }
      }
    }
  }

  /** Returns the selected collection option, which can be either
   * * 'createNewOnlineCollection'
   * * 'createNewLocalCollection'
   * * or 'selectCollection'
   */
  public getSelectedCollectionOption(): IDropdownOption | undefined {
    return this.selectedCollectionOption;
  }

  /**
   * Gets the available collections from NewRepository.
   */
  @computed
  public get collectionOptions(): IDropdownOptionGroup[] {
    const user = this.rootStore.getUserStore().getCurrentUser();
    let collectionOptions;

    collectionOptions = _.chain(toJS(this.collections))
      .sortBy((collection: ICollection) => {
        return collection.title!.toLowerCase();
      })
      .sortBy((collection: ICollection) => {
        return collection.isLocal;
      })
      .value();

    collectionOptions = _.map(collectionOptions, (c: { isLocal: any }) => {
      return _.extend(c, {
        groupBy: c.isLocal
          ? this.rootStore.getTranslation("local_collections.local_collections")
          : this.rootStore.getTranslation("online_collections.online_collections"),
      });
    });

    if (this.rootStore.getLocalServiceStore().isLocalServiceAccessible()) {
      collectionOptions.unshift({
        _id: "local",
        groupBy: this.rootStore.getTranslation("online_collections.new_collection"),
        title: this.rootStore.getTranslation("localCollections.collectionCreation.createNewCollection"),
      });
    }

    if (user) {
      collectionOptions.unshift({
        _id: "online",
        groupBy: this.rootStore.getTranslation("online_collections.new_collection"),
        title: this.rootStore.getTranslation("onlineCollections.collectionCreation.createNewCollection"),
      });
    }

    const groupedOptions: IDropdownOptionGroup[] = this.groupCollectionOptions(collectionOptions);

    return groupedOptions;
  }

  /**
   * Helper function that groups collection options according to their 'groupBy' values.
   */
  private groupCollectionOptions(options): IDropdownOptionGroup[] {
    const getLabel = (option): JSX.Element => {
      return (
        <div className="flex-space-between">
          {option.title}
          <span className="small text-align-end">{` (${option.creator.displayName})`}</span>
        </div>
      );
    };

    const grouped: IDropdownOptionGroup[] = [];
    _.each(options, (option) => {
      if (
        !_.find(grouped, (group) => {
          return group.label === option.groupBy;
        })
      ) {
        grouped.push({
          label: option.groupBy,
          options: [{ value: option._id, label: !!option.creator ? getLabel(option) : option.title }],
        });
      } else {
        _.each(grouped, (group) => {
          if (group.label === option.groupBy) {
            group.options.push({
              value: option._id,
              label: !!option.creator ? getLabel(option) : option.title,
            });
          }
        });
      }
    });

    return grouped;
  }

  public isLocationValid() {
    if (this.collection) {
      if (this.isLocal()) {
        return UploadCollectionStore.LOCATION_REGEXP.test(this.collectionLocation);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  /** Checks if the collection form is valid. */
  public isFormValid(): boolean {
    if (this.collection) {
      //check if pre-existing collection
      if (this.collection.id) {
        return true;
      } else {
        const hasCreator = !!this.collection.creator?.id;
        const titlePresent = !!this.collection.title;
        const properLength =
          this.collection.title!.length >= this.form.getValidations().collection.title.minLength &&
          this.collection.title!.length <= this.form.getValidations().collection.title.maxLength;
        if (this.isLocal()) {
          return titlePresent && properLength && this.isLocationValid();
        } else {
          return hasCreator && titlePresent && properLength;
        }
      }
    } else {
      return false;
    }
  }
}
