import * as React from "react";
import { useContext, useState } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { AnalyticsStoreContext } from "../../components/analytics/analyticsStore";

export const ExportAnalytics = observer(() => {
  const rootStore = useContext(RootContext);
  const analyticsStore = useContext(AnalyticsStoreContext);
  const [isExporting, setIsExporting] = useState(false);

  async function exportData() {
    setIsExporting(true);
    await analyticsStore.exportAllData();
    setIsExporting(false);
  }

  return (
    <div className="export-analytics">
      <div className="admin-header">{rootStore.getTranslation("profile.admin.export_analytics")}</div>
      <button
        className="button button-primary button-export"
        disabled={isExporting}
        onClick={exportData}
        data-testid="admin-export-analytics"
      >
        {rootStore.getTranslation("actions.export")}
      </button>
      {isExporting && (
        <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_processing")}</div>
      )}
    </div>
  );
});
