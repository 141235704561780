/* eslint-disable no-async-promise-executor */
import { action, observable, runInAction, toJS, makeObservable } from "mobx";
import _ from "underscore";

import {
  ICollection,
  IEntity,
  IEditorModeStrategy,
  IInstallationStatus,
  IOrganization,
  IResource,
  ITranslationObject,
  IVersion,
  IVersionTool,
  IItem,
  IBinary,
  IFileItem,
  IUser,
} from "../models/dataModel";
import {
  DialogTypeEnum,
  ExternalResourceTypeEnum,
  ItemTypeEnum,
  PlatformEnum,
  SanitizationModeEnum,
  SearchPageOrderEnum,
} from "../models/enums";
import { ITCCBinary } from "../models/TCCdataModel";

import { RootStore, createStoreContext } from "../stores/rootStore";
import { AbstractAsyncStore } from "../stores/abstractAsyncStore";
import { ContentTranslationsStore } from "../stores/contentTranslationsStore";

import { ScanResultsChecker } from "../utils/ScanResultsChecker";
import { isValidDownloadURL, sanitize, sortArray, translationKeyForAPIError } from "../utils/functions";

import { NewRepository } from "../js/services/NewRepository";
import { AnalyticsService } from "../js/services/AnalyticsService";
import { TCCVersionService } from "../js/services/TCCVersionService";
import { TCCPackageService } from "../js/services/TCCPackageService";

/**
 * Package page store.
 */
export class PackagePageStore extends AbstractAsyncStore implements IEditorModeStrategy {
  /**
   * Package object.
   */
  @observable private package: IEntity | undefined;

  /**
   * Package versions
   */
  @observable private versions: IVersion[] = [];

  /**
   * Organization object.
   */
  @observable private organization: IOrganization | undefined;

  /**
   * Content translations store.
   */
  private contentTranslationsStore: ContentTranslationsStore;

  /**
   * Package title.
   */
  @observable private title = "";

  /**
   * Package description
   */
  @observable private description = "";

  /**
   * Package copyright
   */
  @observable private copyright = "";

  /**
   * Package details
   */
  @observable private details = "";

  /**
   * Stores release note file for the package
   */
  @observable private releaseNoteFile?: File;

  /**
   * Flag that tells if release note file has been added for package
   */
  @observable private releaseNoteFileAdded = false;

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
    this.contentTranslationsStore = new ContentTranslationsStore(rootStore);
  }

  /**
   * Fetches package with given id
   */
  @action
  public async fetchData({ packageId, isLocal }: { packageId: string; isLocal: boolean }) {
    const promises: Array<Promise<void>> = [];
    this.dataFetched = false;
    this.loading = true;
    const currentUser = this.rootStore.getUserStore().getCurrentUser();
    try {
      const packageItem = (await NewRepository.getPackage(
        {
          id: packageId,
          isLocal: isLocal,
        },
        currentUser?.id || "",
      )) as unknown as IEntity;

      if (!!packageItem) {
        runInAction(() => {
          this.package = this.sanitizeFields(packageItem);
        });

        this.formatRelatedList(this.package!.related);
        promises.push(this.fetchCollectionForPackage());
        promises.push(this.fetchVersions());

        if (this.package!.isLocal) {
          this.setFieldsForLocalPackage();
        } else {
          promises.push(this.fetchTranslations());
          promises.push(this.fetchOrganization());

          try {
            await AnalyticsService.contentViewed(currentUser, this.package);
          } catch {
            console.log("Could not update analytics.");
          }
        }
        await Promise.all(promises);
      }
    } catch {
      const msg = this.rootStore.getTranslation("errors.forbidden");
      this.rootStore.getErrorHandlerStore().handleUnauthorized(msg);
    } finally {
      runInAction(() => {
        this.loading = false;
        this.dataFetched = true;
      });
    }
  }

  /**
   * Returns the content translations store.
   */
  public getTranslationsStore(): ContentTranslationsStore {
    return this.contentTranslationsStore;
  }

  /**
   * Gets package object
   * @returns package object
   */
  public getPackage(): IEntity | undefined {
    return this.package;
  }

  /**
   * Gets package object
   * @returns package object
   */
  public getResource(): IResource | undefined {
    return this.getPackage() as IResource;
  }

  /**
   * Gets versions for package
   * @returns version list
   */
  public getVersions(): IVersion[] {
    return _.filter(this.versions, (version: IVersion) => !version.isArchived);
  }

  /**
   * Gets package title
   * @returns title
   */
  public getTitle(): string {
    return this.title;
  }

  /**
   * Sets package title
   * @params title the value to be set
   */
  @action
  public setTitle = (title: string) => {
    this.title = title;
  };

  /**
   * Updates package title
   * @param dontNotify boolean that tells if subscribers should be notified of the update
   */
  @action
  public updateTitle = async (dontNotify: boolean) => {
    const selectedLang: string = this.rootStore.getLocalizationStore().getLangForSelectedLocale();
    const attributes = {};
    const tempTitle = this.title;

    if (this.package) {
      if (this.contentTranslationsStore.shouldUpdateTranslation("title", this.package as IItem)) {
        attributes[selectedLang] = {};
        attributes[selectedLang].title = tempTitle;
        this.package.translations![selectedLang].title = tempTitle;
        await this.contentTranslationsStore.saveTranslation(attributes, this.package as IItem);
      } else {
        this.package.title = tempTitle;
        await this.updateEntity({ title: tempTitle }, dontNotify);
      }

      runInAction(() => {
        if (this.contentTranslationsStore.hasSavedTranslations()) {
          this.package!.translatedTitle = this.package!.translations![selectedLang].title;
        } else {
          this.package!.translatedTitle = this.package!.title;
        }
      });
    }
  };

  /**
   * Gets package description
   * @returns description
   */
  public getDescription(): string {
    return this.description;
  }

  /**
   * Sets package description
   * @params description the value to be set
   */
  @action
  public setDescription = (description: string) => {
    this.description = sanitize(description, SanitizationModeEnum.MODERATE);
  };

  /**
   * Updates package description to NewRepository
   * @param dontNotify boolean that tells if subscribers should be notified of the update
   */
  @action
  public async updateDescription(dontNotify: boolean) {
    const selectedLang: string = this.rootStore.getLocalizationStore().getLangForSelectedLocale();
    const attributes = {};
    const tempDescription = this.description;

    if (this.package) {
      if (this.contentTranslationsStore.shouldUpdateTranslation("description", this.package as IItem)) {
        attributes[selectedLang] = {};
        attributes[selectedLang].description = this.description;
        this.package.translations![selectedLang].description = tempDescription;
        await this.contentTranslationsStore.saveTranslation(attributes, this.package as IItem);
      } else {
        this.package.description = tempDescription;
        await this.updateEntity({ description: tempDescription }, dontNotify);
      }

      runInAction(() => {
        if (this.contentTranslationsStore.hasSavedTranslations()) {
          this.package!.translatedDescription = this.package!.translations![selectedLang].description;
        } else {
          this.package!.translatedDescription = this.package!.description;
        }
      });
    }
  }

  /**
   * Gets package copyright
   * @returns copyright
   */
  public getCopyright(): string {
    return this.copyright;
  }

  /**
   * Gets package details
   * @returns details
   */
  public getDetails(): string {
    return this.details;
  }

  /**
   * Sets package details
   * @params details The value to be set
   */

  @action
  public setDetails = (details: string) => {
    this.details = sanitize(details, SanitizationModeEnum.WEAK);
  };

  /**
   * Updates package details to NewRepository
   * @param dontNotify boolean that tells if subscribers should be notified of the update
   */
  @action
  public async updateDetails(dontNotify: boolean) {
    const selectedLang: string = this.rootStore.getLocalizationStore().getLangForSelectedLocale();
    const attributes = {};

    if (this.package) {
      if (this.package.isLocal) {
        this.package.richContent.description = this.details;
        await this.updateEntity({ richContent: { description: this.details } }, dontNotify);
      } else {
        const tempDetails = this.details;
        if (this.contentTranslationsStore.shouldUpdateTranslation("details", this.package)) {
          attributes[selectedLang] = {};
          attributes[selectedLang].details = tempDetails;
          this.package.translations![selectedLang].details = tempDetails;
          await this.contentTranslationsStore.saveTranslation(attributes, this.package);
        } else {
          this.package.richContent = tempDetails;
          await this.updateEntity({ richContent: tempDetails }, dontNotify);
        }
      }

      runInAction(() => {
        if (this.contentTranslationsStore.hasSavedTranslations()) {
          this.package!.translatedDetails = this.package!.translations![selectedLang].details;
        } else {
          this.package!.translatedDetails = this.package!.richContent;
        }
      });
    }
  }

  /**
   * Reloads data the way depending on which dialog was used
   * @params dialogType type of dialog thas triggered reload request
   */
  public async reloadData(dialogType: DialogTypeEnum) {
    const packageItem = this.getPackage();
    if (packageItem) {
      if (dialogType === DialogTypeEnum.TRANSLATIONS) {
        this.fetchTranslations();
      } else {
        this.fetchData({
          packageId: packageItem.id!,
          isLocal: packageItem.isLocal!,
        });
      }
    }
  }

  /**
   * Gets organization object
   * @returns organization object
   */
  public getOrganization(): IOrganization | undefined {
    return this.organization;
  }

  /**
   * Method for resolving if package is owned by organization
   * @returns true if package is owned by organization
   */
  public isOwnedByOrganization(packageItem: IEntity): boolean {
    return (
      !!packageItem &&
      !!packageItem.creator &&
      packageItem.creator.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION
    );
  }

  /**
   * @return Returns true if current user can edit package.
   */
  public canEditPackage(): boolean {
    return this.package ? this.rootStore.getUserStore().canEditResource(this.package as IResource) : false;
  }

  /**
   * Sets collection for package
   */
  @action
  public setCollection = (collection: ICollection) => {
    const packageItem = this.getPackage();
    if (packageItem) {
      packageItem.collection = collection;
    }
  };

  /**
   * Resolves if translations dialogs should be shown
   * @returns true if dialogs should be shown
   */
  public shouldShowTranslationDialogs(): boolean {
    return !this.isLocalPackage();
  }

  /**
   * Resolves if batch edit dialog should be shown
   * @returns true if dialogs should be shown
   */
  public shouldShowBatchEditDialog(): boolean {
    return !this.isLocalPackage();
  }

  /**
   * Sorts version tools
   * @returns list of sorted version tools
   */
  public sortVersionTools(tools: IVersionTool[]): IVersionTool[] {
    return _.sortBy(tools, function (tool) {
      if (tool.platform == PlatformEnum.ANY) {
        return 0;
      } else if (tool.platform == PlatformEnum.WIN_X64) {
        return 1;
      } else if (tool.platform == PlatformEnum.WIN_X86) {
        return 2;
      } else {
        return 3;
      }
    });
  }

  /**
   * Generates istallation status object
   * @params version selected version
   * @returns Installation status object
   */
  public getInstallationStatus(version: IVersion): IInstallationStatus {
    const packageItem = this.getPackage();
    const installationStatus: IInstallationStatus = {
      failedInstallationAttempts: [],
      installableEntities: [],
    };
    if (packageItem) {
      const installableEntity: { package: IEntity; versions: IVersion[] } = {
        package: packageItem,
        versions: [],
      };
      installableEntity.versions.push(version);
      installationStatus.installableEntities.push(installableEntity);
    }
    return installationStatus;
  }

  /**
   * Gets title to be shown for tool version
   * @params tool The tool version that should be shown title
   * @returns text to be shown
   */
  public getTitleForTool(tool: IVersionTool): string {
    return (
      this.rootStore.getTranslation("tools.download") +
      " " +
      this.rootStore.getTranslation("platformsDownload." + tool.platform)
    );
  }

  /**
   * Resolves if version should be displayed
   * @param version The version that should be inspected
   * @returns true if version should be displayed
   */
  public shouldDisplayVersion(version: IVersion): boolean {
    return version && (version.isLocal || ScanResultsChecker.versionBinariesHavePassedScan(version));
  }

  /**
   * Resolves if version is type of tool
   * @param version The version that should be inspected
   * @returns true if version is tool
   */
  public isTool(version: IVersion): boolean {
    return !!version && !!version.attributes && !!version.attributes.isTool;
  }

  /**
   * Checks if version has attributes set
   * @returns true if version has attributes
   */
  public versionAttributeDataExits(version: IVersion): boolean {
    const attributes = version.attributes;
    return (
      !!attributes &&
      (this.versionAttributeExists(version, "compatibleOperatingSystems") ||
        this.versionAttributeExists(version, "compatibleSoftwareProducts") ||
        this.versionAttributeExists(version, "measurementUnits") ||
        this.versionAttributeExists(version, "testedVersions") ||
        !!attributes.productExpirationDate ||
        !!attributes.productCode)
    );
  }

  /**
   * Checks if version has given attribute
   * @params version the version to check
   * @params attributeName the name of attribute to check
   * @returns true if version has given attribute
   */
  public versionAttributeExists(version: IVersion, attributeName: string): boolean {
    const attributes = version.attributes;
    return !!attributes && !!attributes[attributeName] && attributes[attributeName].length > 0;
  }

  /**
   * Checks if version has prerequisite data
   * @params version the version to check
   * @returns true if version has prequisite data
   */
  public versionPrerequisiteDataExists(version: IVersion): boolean {
    return (
      !!version &&
      !!version.attributes &&
      (!!version.attributes.prerequisiteDescription || (!!version.prerequisites && version.prerequisites.length > 0))
    );
  }

  /**
   * Sorts versions according to the sorting rule.
   * @returns list of sorted versions
   */
  public getSortedVersions(sortingOrder?: SearchPageOrderEnum): IVersion[] {
    return sortArray(
      this.getVersions(),
      sortingOrder || this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder,
    );
  }

  /**
   * Shares current location on facebook.
   */
  public shareOnFacebook() {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(window.location.href),
      "facebook-share-dialog",
      "width=626,height=436",
    );
  }

  /**
   * Shares current location on twitter.
   */
  public shareOnTwitter = () => {
    window.open(
      "http://twitter.com/share?text=" + this.getTitle() + "&url=" + encodeURIComponent(window.location.href),
      "facebook-share-dialog",
      "width=626,height=436",
    );
  };

  /**
   * Resolves if package is local
   * @returns true if local package
   */
  public isLocalPackage(): boolean {
    return !!this.package && !!this.package.isLocal;
  }

  /**
   * Resolves if version has tsep binaries
   * @param version The version to be inspected
   * @returns true if tsep binaries found
   */
  public versionHasTsepBinaries(version: IVersion): boolean {
    const tsepBinaries = _.filter(version.binaries, (binary: IFileItem) => {
      return !!binary.attributes && binary.attributes.itemType === ItemTypeEnum.TSEP;
    });
    return tsepBinaries.length > 0;
  }

  /**
   * Resolves if version has been tagged with "noaction"
   * @param version The version to be inspected
   * @returns true if version has been tagged with "noaction"
   */
  public versionHasNoActionBinaries(version: IVersion): boolean {
    return _.any(version.binaries, (binary: IBinary) => {
      return !!binary.attributes && binary.attributes.itemType === ItemTypeEnum.NO_ACTION;
    });
  }

  /**
   * Checks if a given version has a specific file type.
   *
   * @param version - The version to check.
   * @param fileType - The file type to search for.
   * @returns A boolean indicating whether the version has the specified file type.
   */
  public versionHasFileType(version: IVersion, fileType: string): boolean {
    return _.map(version.binaries, (binary: IFileItem) => binary.ext).includes(fileType);
  }

  /**
   * Is there a defined partner download URL?
   * @returns true if external download URL has been defined for the content
   */
  public hasPartnerDownloadURL(): boolean {
    return !!this.package && !_.isUndefined(this.package!.attributes!.contentDownloadUrl);
  }

  /**
   * Should show versions on package page?
   */
  public showVersions(): boolean {
    if (this.hasPartnerDownloadURL()) {
      return this.rootStore.getUserStore().canEditResource(this.package as IResource);
    } else {
      return true;
    }
  }

  /**
   * Adds or updates a partner download URL for package.
   * @param newURL new url
   */
  @action
  public async editPartnerDownloadURL(newUrl: string, doNotNotify: boolean): Promise<void> {
    if (!this.package || !isValidDownloadURL(newUrl)) return;

    try {
      _.extend(this.package.attributes, { ...this.package.attributes, contentDownloadUrl: newUrl });

      await NewRepository.setContentAttribute(
        this.package,
        {
          contentDownloadUrl: newUrl,
        },
        null,
        doNotNotify,
        this.getRootStore().getUserStore().getCurrentUser()?.id,
      );

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("shared.catalog_entity_edit.updated_successfully"));
    } catch (err) {
      const transKey = translationKeyForAPIError((err as any).key) || "details.update_failed";
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation(transKey));
      console.log("Could not add download URL");
    }
  }

  @action
  private setFieldsForLocalPackage() {
    if (this.package) {
      if (this.package.title) {
        this.title = this.package.title;
      }
      if (this.package.description) {
        this.description = this.package.description;
      }
      if (this.package.copyrightInfo) {
        this.copyright = this.package.copyrightInfo;
      }
      if (this.package.richContent) {
        this.details = this.package.richContent.description;
      }
    }
  }

  /**
   * Deletes the selected package.
   */
  public performDelete = async () => {
    const packageItem = this.getPackage();
    const editor = this.getEditor();

    if (!!packageItem && !!editor) {
      try {
        await NewRepository.deletePackage(packageItem, editor.id);

        this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("details.content_removed"));
        if (packageItem.isLocal) {
          this.rootStore.getRouter().changeRoutingState("/collections/local/" + packageItem.collection.id);
        } else {
          this.rootStore.getRouter().changeRoutingState("/collections/online/" + packageItem.collection.id);
        }
      } catch {
        console.log("Failed to delete package " + packageItem.id);
      }
    }
  };

  @action
  private async fetchTranslations(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.package) {
        try {
          const translations = await this.contentTranslationsStore.getContentTranslations(this.package.id!);
          if (translations) {
            this.package.translations = this.sanitizeTranslations(translations);
            this.setTranslationFields();
          }
        } catch {
          console.log("Error while fetching translations for package ", this.package.id);
        }
        resolve();
      } else {
        resolve();
      }
    });
  }

  private async fetchOrganization(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.package && this.isOwnedByOrganization(this.package)) {
        const organization = await NewRepository.getOrganization({
          id: this.package.creator!.id,
          showBinaryAttributes: true,
        });
        runInAction(() => {
          if (organization) {
            this.organization = organization;
          }
        });
        resolve();
      } else {
        resolve();
      }
    });
  }

  private setTranslationFields() {
    const packageItem = this.getPackage();
    if (packageItem) {
      this.setTranslatedTitle();
      this.setTranslatedDescription();
      this.setTranslatedCopyright();
      this.setTranslatedDetails();
    }
  }

  @action
  private setTranslatedTitle() {
    const packageItem = this.getPackage();
    if (packageItem) {
      const translation = this.contentTranslationsStore.getTranslationForField("title");
      if (translation && translation != "") {
        packageItem.translatedTitle = translation;
        this.title = translation;
      } else {
        const title = packageItem.title ? packageItem.title : "";
        packageItem.title = title;
        this.title = title;
      }
    }
  }

  @action
  private setTranslatedDescription() {
    const packageItem = this.getPackage();
    if (packageItem) {
      const translation = this.contentTranslationsStore.getTranslationForField("description");
      if (translation && translation != "") {
        packageItem.translatedDescription = translation;
        this.description = translation;
      } else {
        const desc = packageItem.description ? sanitize(packageItem.description, SanitizationModeEnum.MODERATE) : "";
        packageItem.translatedDescription = desc;
        this.description = desc;
      }
    }
  }

  @action
  private setTranslatedCopyright() {
    const packageItem = this.getPackage();
    if (packageItem) {
      const translation = this.contentTranslationsStore.getTranslationForField("copyright");
      if (translation && translation != "") {
        packageItem.translatedCopyright = translation;
        this.copyright = translation;
      } else {
        const copyright = packageItem.copyrightInfo ? packageItem.copyrightInfo : "";
        packageItem.translatedCopyright = copyright;
        this.copyright = copyright;
      }
    }
  }

  @action
  private setTranslatedDetails() {
    const packageItem = this.getPackage();
    if (packageItem) {
      const translation = this.contentTranslationsStore.getTranslationForField("details");
      if (translation && translation != "") {
        packageItem.translatedDetails = translation;
        this.details = translation;
      } else {
        const details = packageItem.richContent ? sanitize(packageItem.richContent, SanitizationModeEnum.WEAK) : "";
        packageItem.translatedDetails = details;
        this.details = details;
      }
    }
  }

  private async fetchCollectionForPackage(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!!this.package && !!this.package.collection) {
        let collection: ICollection | undefined;
        try {
          collection = (await NewRepository.getCollection(
            {
              id: this.package.collection.id,
              isLocal: this.package.isLocal,
            },
            this.getEditor()?.id || "",
          )) as unknown as ICollection;
        } catch {
          console.log("Error while fetching collection for package ", this.package.id);
        }

        if (!!collection) {
          if (!collection.isLocal) {
            try {
              const translations = await NewRepository.getTranslations({
                id: collection.id,
              });
              if (translations) {
                const translation = this.contentTranslationsStore.getTranslationForField("title", translations);
                if (translation && translation != "") {
                  collection.translatedTitle = translation;
                }
              }
            } catch {
              console.log("Error while fetching translations for collection ", collection.id);
            }
          }
          runInAction(() => {
            this.package!.collection = collection as ICollection;
          });
        }
        resolve();
      } else {
        console.log("No collection found for package");
        resolve();
      }
    });
  }

  @action
  private async fetchVersions(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (this.package) {
        const versions = await NewRepository.getVersions(
          this.package,
          true,
          false,
          this.rootStore.getUserStore().canSeeVersionsStillScanning(this.package as IResource),
        );

        runInAction(() => {
          if (versions) {
            this.versions = versions;
          }
        });
        resolve();
      } else {
        resolve();
      }
    });
  }

  /**
   * Updates the entity/package
   * @param dontNotify boolean that tells if subscribers should be notified of the update
   */
  public async updateEntity(data, dontNotify: boolean) {
    const editor = this.getEditor();

    if (!!editor || this.isLocalPackage()) {
      try {
        let res;

        if (this.isLocalPackage()) {
          const updatedEntity = { ...this.package, ...data, doNotNotify: dontNotify };
          res = await NewRepository.setPackage(updatedEntity, editor);
        } else {
          const entityUpdates = { ...data, id: this.package!.id, doNotNotify: dontNotify };
          res = await NewRepository.updatePackage(entityUpdates, editor);
        }

        runInAction(() => {
          this.package = _.extend(
            this.package,
            _.pick(res, "modifier", "creator", "createdAt", "modifiedAt", "releasenote", "attributes", "copyrightInfo"),
          );

          if (!this.isLocalPackage()) this.setTranslationFields();
        });
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("shared.catalog_entity_edit.updated_successfully"));
      } catch (err) {
        const transKey = translationKeyForAPIError((err as any).key) || "details.update_failed";
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation(transKey));
      }
    }
  }

  /**
   * Returns a list of collections that the package can be transferred to.
   */
  public async getCollectionsWithTransferRights(): Promise<ICollection[]> {
    let collectionsWithTransferRights: ICollection[] = [];
    const userCanEditOwnerOrganization = !!this.organization && this.rootStore.getUserStore().canEditOrganization(this.organization);

    try {
      const collections: ICollection[] = await NewRepository.getCollectionsWithTransferRights(
        this.rootStore.getUserStore().getCurrentUser(),
        this.package!.collection,
        userCanEditOwnerOrganization
      );

      if (!!collections) {
        const sortedCollections = _.chain(collections)
          .reject((c: ICollection) => {
            return c.id === this.package!.collection.id;
          })
          .sortBy((c: ICollection) => {
            return c.title!.toLowerCase();
          })
          .sortBy((c: ICollection) => {
            return c.isLocal;
          })
          .value();

        collectionsWithTransferRights = sortedCollections;
      }
    } catch {
      console.log("Error occurred while fetching collections with link rights");
    }

    return collectionsWithTransferRights;
  }

  /**
   * Moves the selected package to new collection.
   */
  @action
  public transferPackage = async (selectedCollection: ICollection) => {
    const oldCollection = this.package!.collection;
    const newCollection = selectedCollection;
    const editor = this.getEditor();

    if (!!newCollection && !!oldCollection) {
      try {
        await NewRepository.transferPackage(this.package, oldCollection, newCollection, editor);
        this.setCollection(newCollection);
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("shared.transfer_package.content_moved"));
      } catch {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("shared.transfer_package.operation_failed"));
      }
    }
  };

  /**
   * Copies the selected package to new collection.
   * @params selectedCollection The collection that package should be copied to
   */
  @action
  public copyPackage = async (selectedCollection: ICollection) => {
    const editor = this.getEditor();
    let createdPackage;

    if (selectedCollection && this.package) {
      try {
        const clonedPackage: IEntity = this.clonePackage(this.package);
        clonedPackage.collection = toJS(selectedCollection);
        clonedPackage.visibility = selectedCollection.visibility;

        createdPackage = await NewRepository.createPackage(clonedPackage, editor);

        if (!!createdPackage) {
          try {
            await this.updateCopiedDetails(createdPackage);
          } catch (e) {
            console.log("Failed to update copied details");
            throw e;
          }

          try {
            await this.clonePackageBinaries(this.package, createdPackage);
          } catch (e) {
            console.log("Failed to clone package binaries");
            throw e;
          }

          try {
            await this.copyVersions(createdPackage);
          } catch (e) {
            console.log("Failed to copy versions");
            throw e;
          }

          this.rootStore
            .getNotificationChannelStore()
            .success(this.rootStore.getTranslation("shared.transfer_package.content_copied"));
        }
      } catch {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("shared.transfer_package.operation_failed"));

        if (!!editor && !!createdPackage) {
          try {
            await NewRepository.deletePackage(createdPackage, editor?.id);
          } catch {
            console.log("Failed to delete package");
          }
        }
      }
    }
  };

  private removeEmptyFieldsFromTranslations(translations: Record<string, ITranslationObject>): Record<string, ITranslationObject> {
    return _.mapObject(translations, (value: ITranslationObject, key: string) => {
      return _.mapObject(value, (value: string | undefined, key: string) => {
        return _.isEmpty(value) ? undefined : value;
      });
    });
  }

  /**
   * Replace any instances of the old package id with the new package id in the copied package.
   * @param createdPackage - The package that was created by copying the original package.
   */
  @action
  private async updateCopiedDetails(createdPackage: IEntity): Promise<void> {
    const editor = this.getEditor();

    if (!!editor) {
      if (createdPackage.richContent) {
        createdPackage.richContent = createdPackage.richContent.replace(this.package!.id, createdPackage.id);
      }

      if (this.package!.translations) {
        createdPackage.translations = _.mapObject(this.removeEmptyFieldsFromTranslations(this.package!.translations), (translation: ITranslationObject) => ({
          ...translation,
          details: !!translation.details ? (translation.details).replace(this.package!.id!, createdPackage.id!) : undefined,
        }));
      }

      await NewRepository.setPackage(createdPackage, editor);
    }
  }

  private clonePackage(sourcePackage: IEntity): IEntity {
    const newPackage: IEntity = { ...toJS(_.omit(sourcePackage, "id", "_id", "codeName")), id: "" };
    newPackage.attributes!.itemTypeCategories = toJS(sourcePackage.attributes!.itemTypeCategories);
    newPackage.attributes!.useCategories = toJS(sourcePackage.attributes!.useCategories);
    newPackage.attributes!.licensesACL = toJS(sourcePackage.attributes!.licensesACL);
    newPackage.attributes!.locationRestrictions = toJS(sourcePackage.attributes!.locationRestrictions);
    newPackage.attributes!.measurementUnits = toJS(sourcePackage.attributes!.measurementUnits);
    newPackage.attributes!.supportedLanguages = toJS(sourcePackage.attributes!.supportedLanguages);
    newPackage.attributes!.videourls = sourcePackage.attributes!.videourls;
    newPackage.related = toJS(sourcePackage.related);
    newPackage.releasenote = toJS(sourcePackage.releasenote);
    newPackage.tags = toJS(sourcePackage.tags);
    newPackage.thumbnails = toJS(sourcePackage.thumbnails);
    newPackage.thumbnails3d = toJS(sourcePackage.thumbnails3d);
    newPackage.thumbnails_details = toJS(sourcePackage.thumbnails_details);
    newPackage.translations = this.removeEmptyFieldsFromTranslations(toJS(sourcePackage.translations) || {});

    return newPackage;
  }

  private async clonePackageBinaries(sourcePackage: IEntity, targetPackage: IEntity): Promise<void> {
    const promises: Array<Promise<any>> = [];
    !!sourcePackage.binaries &&
      _.each(sourcePackage.binaries, (binary: ITCCBinary, key: string) => {
        promises.push(
          this.clonePackageBinary(targetPackage.id!, {
            url: this.stripUrl(binary.url),
            reference: key.toString(),
          }),
        );
      });
    await Promise.all(promises);
    return;
  }

  private stripUrl(url: string | undefined): string {
    if (!!url) {
      if (url.indexOf("?") > 0) {
        return url.substring(0, url.indexOf("?"));
      } else return url;
    } else {
      return "";
    }
  }

  private clonePackageBinary(targetPackageId: string, binaryData: { url: string; reference: string }): Promise<void> {
    return TCCPackageService.copyBinary(targetPackageId, binaryData);
  }

  @action
  private async copyVersions(createdPackage: IEntity): Promise<Array<IVersion | undefined>> {
    const promises: Array<Promise<IVersion | undefined>> = [];
    _.each(this.versions, async (version: IVersion) => {
      const clonedVersion = this.cloneVersion(version);
      clonedVersion.package = createdPackage;

      promises.push(this.createVersionAndCopyBinaries(version, clonedVersion));
    });
    return Promise.all(promises);
  }

  @action
  private cloneVersion(version: IVersion): IVersion {
    const newVersion: IVersion = { ...toJS(_.omit(version, "id", "_id")), id: "" };
    newVersion.tags = [];
    newVersion.tools = toJS(version.tools);
    newVersion.prerequisites = toJS(version.prerequisites);
    newVersion.binaries = toJS(version.binaries);
    newVersion.attributes.compatibleOperatingSystems = toJS(version.attributes.compatibleOperatingSystems);
    newVersion.attributes.compatibleSoftwareProducts = toJS(version.attributes.compatibleSoftwareProducts);
    newVersion.attributes.measurementUnits = toJS(version.attributes.measurementUnits);
    newVersion.attributes.testedVersions = toJS(version.attributes.testedVersions);
    newVersion.attributes.licensesACL = toJS(version.attributes.licensesACL);
    return newVersion;
  }

  @action
  private async createVersionAndCopyBinaries(
    version: IVersion,
    clonedVersion: IVersion,
  ): Promise<IVersion | undefined> {
    const editor = this.getEditor();
    if (!editor) return;

    try {
      const createdVersion = (await NewRepository.createVersion(clonedVersion, editor)) as unknown as IVersion;
      await this.cloneVersionBinaries(version, createdVersion);

      return createdVersion;
    } catch (err) {
      console.log("Failed to create version from", version.id);
      throw err;
    }
  }

  private async cloneVersionBinaries(sourceVersion: IVersion, targetVersion: IVersion): Promise<any[]> {
    const promises: Array<Promise<any>> = [];

    _.each(sourceVersion.binaries, (thumbnail: IFileItem) => {
      promises.push(
        this.cloneVersionBinary(
          targetVersion.id!,
          {
            url: this.stripUrl(thumbnail.url),
            reference: thumbnail.reference,
          },
          sourceVersion.creator!.id,
        ),
      );
    });

    return Promise.all(promises);
  }

  private cloneVersionBinary(
    targetVersionId: string,
    binaryData: { url: string; reference: string | undefined },
    creatorId: string,
  ): Promise<any> {
    return TCCVersionService.copyBinary(targetVersionId, binaryData, creatorId);
  }

  @action
  private formatRelatedList(relatedList: any): void {
    const formattedList: string[] = [];
    if (relatedList) {
      relatedList = toJS(relatedList);
      if (!_.isArray(relatedList)) {
        relatedList = relatedList.split(",");
      }
      _.each(relatedList, function (relatedItem) {
        if (relatedItem.id) {
          formattedList.push(relatedItem.id);
        } else {
          formattedList.push(relatedItem);
        }
      });
      this.package!.related = formattedList;
    }
  }

  /**
   * Returns the editor
   * * the user, is user is the creator or belongs to the owner organization,
   * * the owner organization, if user is contentEditor for the owner organization.
   */
  private getEditor(): IUser | IOrganization | undefined {
    const isCreator = this.package?.creator?.id === this.rootStore.getUserStore().getCurrentUser()?.id;

    if (
      isCreator ||
      this.rootStore.getUserStore().isUserAdmin() ||
      (!!this.organization && this.rootStore.getUserStore().belongsToOrganization(this.organization))
    ) {
      return this.rootStore.getUserStore().getCurrentUser();
    } else if (!!this.organization && this.rootStore.getUserStore().isContentEditorForOrganization(this.organization)) {
      return this.organization;
    }
  }

  private sanitizeFields(packageItem: IEntity): IEntity {
    packageItem.description = sanitize(packageItem.description, SanitizationModeEnum.MODERATE);
    if (packageItem.isLocal) {
      packageItem.richContent.description = sanitize(packageItem.richContent.description, SanitizationModeEnum.WEAK);
    } else {
      packageItem.richContent = sanitize(packageItem.richContent, SanitizationModeEnum.WEAK);
    }
    return packageItem;
  }

  private sanitizeTranslations(translations: Record<string, ITranslationObject>): Record<string, ITranslationObject> {
    _.each(translations, (translationItem: ITranslationObject, language: string) => {
      _.each(translationItem, (translation: string, field: string) => {
        if (field === "description") {
          translations[language][field] = sanitize(translations[language][field], SanitizationModeEnum.MODERATE);
        } else if (field === "details") {
          translations[language][field] = sanitize(translations[language][field], SanitizationModeEnum.WEAK);
        }
      });
    });
    return translations;
  }
}

export const PackagePageStoreContext = createStoreContext<PackagePageStore>(PackagePageStore);
