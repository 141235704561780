import { action, runInAction, observable, makeObservable } from "mobx";

import { TCCSearchService } from "../../js/services/TCCSearchService";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { EntitiesStore } from "../../stores/entitiesStore";

import { IEntity, IPackageSearchQuery, ICollection, ICollectionSearchQuery } from "../../models/dataModel";
import { ObjectTypeEnum } from "../../models/enums";

export class MyLikesStore extends EntitiesStore {
  /**
   * The current user.
   */
  private user;

  /**
   * Packages liked by the user.
   */
  @observable protected myLikedPackages: IEntity[] = [];

  /**
   * Collections liked by the user.
   */
  @observable protected myLikedCollections: ICollection[] = [];

  /**
   * Flags marking if async processing has finished.
   */
  @observable protected fetchedLikedPackages = false;

  @observable protected fetchedLikedCollections = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Fetches the current user from UserStore.
   */
  private loadUser() {
    this.user = this.rootStore.getUserStore().getCurrentUser();
  }

  /**
   * Gets packages liked by the user.
   */
  public getPackagesLikedByMe() {
    return this.myLikedPackages;
  }

  /**
   * Gets collections liked by the user.
   */
  public getCollectionsLikedByMe() {
    return this.myLikedCollections;
  }

  /**
   * Returns if the user has liked content.
   * @returns boolean
   */
  public hasLikedContent() {
    return this.myLikedCollections.length > 0 || this.myLikedPackages.length > 0;
  }

  /**
   * Returns if the async prosessing has finished.
   * @returns boolean
   */
  @observable public contentFinishedLoading() {
    return this.fetchedLikedPackages && this.fetchedLikedCollections;
  }

  /**
   * Function that fetches the collection data using 'TCCSearchService'.
   * Called from OnlineCollections when the component is rendered.
   */
  @action
  public async fetchData() {
    this.loadUser();

    if (this.user) {
      this.loadPackagesLikedByMe();
      this.loadCollectionsLikedByMe();
    }
  }

  /**
   * Loads collections created by user from 'TCCSearchService'.
   */
  @action
  private async loadPackagesLikedByMe() {
    const queryParams: IPackageSearchQuery = {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      sortBy: "title ASC",
      fq: "reviewedByUser==" + this.user.id,
    };

    const pkg = await TCCSearchService.searchPackages(
      queryParams,
      true,
      this.rootStore.getUserStore().getCurrentUser()?.id,
    );
    const result = this.setPaths(pkg);

    runInAction(() => {
      this.myLikedPackages = result;
      this.fetchedLikedPackages = true;
    });
  }

  /**
   * Loads collections created by user's organization from 'TCCSearchService'.
   */
  @action
  private async loadCollectionsLikedByMe() {
    const queryParams: ICollectionSearchQuery = {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      sortBy: "title ASC",
      fq: "reviewedByUser==" + this.user.id,
    };
    if (!this.user) return;

    const pkg = await TCCSearchService.searchCollections(
      queryParams,
      true,
      ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
      this.user.id,
    );
    const result = this.setCollectionPaths(pkg);

    runInAction(() => {
      this.myLikedCollections = result;
      this.fetchedLikedCollections = true;
    });
  }

  /**
   * Processes list passed as parameter and defines methods that return paths to entity and creators.
   * @param collections to be updated
   * @returns processed list
   */
  protected setCollectionPaths(collections: ICollection[]) {
    return (collections || []).map((item) => {
      item.getCollectionPath = function () {
        return "/collections/online/" + this.id;
      };
      item.getOrganizationPath = function () {
        return "/organization/" + this.creator!.id;
      };
      return item;
    });
  }
}

/**
 * Context object for MyLikesStore instances.
 */
export const MyLikesContext = createStoreContext<MyLikesStore>(MyLikesStore);
