import { observer } from "mobx-react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";

import { ModusNumberInput } from "@trimble-oss/modus-react-components";

import classNames from "classnames";
import { ModusDualSlider } from "components/ModusDualSlider";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const ViewsFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const [totalMinViews, setTotalMinViews] = useState(-1);
  const [totalMaxViews, setTotalMaxViews] = useState(-1);

  useEffect(() => {
    fetchData();
  }, store.getViewsAndDownloadsDependencies());

  async function fetchData() {
    await store.queryViews();

    if (store.getMinViews() !== undefined) {
      setTotalMinViews(store.getMinViews()!);
    }

    if (store.getMaxViews() !== undefined) {
      setTotalMaxViews(store.getMaxViews()!);
    }
  }

  function handleMinValueChange(event) {
    const nextMin = parseInt(event.target.value);
    if (isNaN(nextMin)) {
      return;
    } else if (nextMin <= (store.getMaxViews() || totalMaxViews)) {
      store.setMinViews(nextMin);
    } else {
      store.setMinViews(store.getMaxViews());
    }
  }

  function handleMaxValueChange(event) {
    const nextMax = parseInt(event.target.value);
    if (isNaN(nextMax)) {
      return;
    } else if (nextMax >= (store.getMinViews() || totalMinViews)) {
      store.setMaxViews(nextMax);
    } else {
      store.setMaxViews(store.getMinViews());
    }
  }

  return (
    <>
      <div className={classNames({ "search-filter": true, sectioned: true })} data-testid="viewsFilter">
        <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.views")}:</h5>
        <div className="input-container">
          <div className="number-input">
            <ModusNumberInput
              label={rootStore.getTranslation("profile.admin.content_manager.helpers.from")}
              minValue={totalMinViews}
              maxValue={store.getMaxViews() || totalMaxViews}
              onInput={handleMinValueChange}
              value={store.getMinViews() !== undefined ? store.getMinViews()?.toString() : totalMinViews.toString()}
              disabled={store.isQuerying("views")}
              data-testid="views-min-field"
            ></ModusNumberInput>
          </div>
          <div className="number-input">
            <ModusNumberInput
              label={rootStore.getTranslation("profile.admin.content_manager.helpers.to")}
              minValue={store.getMinViews() || totalMinViews}
              maxValue={totalMaxViews}
              onInput={handleMaxValueChange}
              value={store.getMaxViews() !== undefined ? store.getMaxViews()?.toString() : totalMaxViews.toString()}
              disabled={store.isQuerying("views")}
              data-testid="views-max-field"
            ></ModusNumberInput>
          </div>
        </div>
      </div>
      <div className="search-filter">
        <ModusDualSlider
          lowerLimit={totalMinViews}
          upperLimit={totalMaxViews}
          minValue={store.getMinViews() !== undefined ? store.getMinViews() : totalMinViews}
          maxValue={store.getMaxViews() !== undefined ? store.getMaxViews() : totalMaxViews}
          onChangeMin={handleMinValueChange}
          onChangeMax={handleMaxValueChange}
          disabled={store.isQuerying("views")}
          testIdMin="views-min-slider"
          testIdMax="views-max-slider"
          testId="views-dual-slider"
        />
      </div>
    </>
  );
});
