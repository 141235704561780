import { ModusButton, ModusCheckbox, ModusIcon, ModusModal } from "@trimble-oss/modus-react-components";
import classNames from "classnames";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";
import { ContentManagerActionsStore } from "./contentManagerActionsStore";

import { DialogContext, DialogStore } from "../../../../dialogs/dialogStore";

import { IBatchVersion, ITreeViewContent, ITreeViewVersion } from "../../../../models/dataModel";
import { Dialog } from "dialogs/Dialog";

export enum SelectContentDialogActionEnum {
  BAN,
  UNBAN,
}

const VersionToSelect = observer(({ packageItem, version }: { packageItem: ITreeViewContent; version: ITreeViewVersion; }) => {
  const contentMangerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store: ContentManagerActionsStore = contentMangerStore.getActionsStore();

  const versionTitle = `${version.title} ${version.isBanned
    ? `(banned ${DateTime.fromISO(version.attributes?.bannedDate || "").toFormat("yyyy/MM/dd HH:mm")})`
    : ""}`;

  return (
    <li
      style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0 0 0.25em 0" }}
      data-testid={`version-${version.id}-for-package-${packageItem.id}`}
    >
      <span>{versionTitle}</span>
      <ModusCheckbox
        checked={version.selected}
        onCheckboxClick={(e) => store.selectVersion(packageItem, version, e.detail)}
        data-testid={`version-${version.id}-checkbox`}
      />
    </li>
  );
});

const PackageToSelect = observer(({ packageItem }: { packageItem: ITreeViewContent }) => {
  const contentMangerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store: ContentManagerActionsStore = contentMangerStore.getActionsStore();
  const packageTitle = `${packageItem.title} ${packageItem.isBanned
    ? `(banned ${DateTime.fromISO(packageItem.attributes?.bannedDate || "").toFormat("yyyy/MM/dd HH:mm")})`
    : ""}`;

  const onlySomeVersionsSelected = !!packageItem.versions &&
    _.some(packageItem.versions, (version: IBatchVersion) => !!version.selected) &&
    !_.all(packageItem.versions, (version: IBatchVersion) => !!version.selected);

  return (
    <li data-testid={"packageListItem-" + packageItem.id} style={{ paddingBottom: "1em", borderBottom: "1px solid #e0e0e0", marginBottom: "1em" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", paddingBottom: "0.25em" }}>
        <span style={{ display: "flex", alignItems: "center" }}>
          <ModusIcon name="package" size="20" style={{ paddingRight: "0.75em", height: "20px" }} />
          {packageTitle}
        </span>
        <ModusCheckbox
          checked={packageItem.selected}
          indeterminate={onlySomeVersionsSelected}
          onCheckboxClick={(e) => store.selectContent(packageItem, e.detail)}
          data-testid={"package-" + packageItem.id + "-checkbox"}
        />
      </div>
      {!!packageItem.versions && !_.isEmpty(packageItem.versions) && (
        <ul data-testid={"versionsListFor-" + packageItem.id} style={{ listStyle: "none", paddingLeft: "3em" }}>
          {((packageItem.versions || []) as ITreeViewVersion[]).map((version: ITreeViewVersion, j: number) => (
            <VersionToSelect key={j} packageItem={packageItem} version={version} />
          ))}
        </ul>
      )}
    </li>
  );
});


const SelectContentDialogContent = observer(() => {
  const contentMangerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store: ContentManagerActionsStore = contentMangerStore.getActionsStore();

  return (
    <div
      data-testid="selectContentDialogBody"
      style={{ paddingTop: "1.5em", display: "flex", justifyContent: "between", alignItems: "start", width: "100%" }}
    >
      <ul style={{ listStyle: "none", width: "100%" }} data-testid="selectedContentNestedList">
        {(store.getPackages() as ITreeViewContent[]).map((packageItem: ITreeViewContent, i: number) => (
          <PackageToSelect key={i} packageItem={packageItem} />
        ))}
      </ul>
    </div>
  );
});

export const SelectContentDialog = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const contentMangerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store: ContentManagerActionsStore = contentMangerStore.getActionsStore();
  const dialog: DialogStore = useContext(DialogContext);

  async function handleBanOrUnbanContent() {
    if (store.getSelectedAction() === SelectContentDialogActionEnum.BAN) {
      await store.banSelected();
    } else {
      await store.unbanSelected();
    }
    dialog.close();
  }

  function handleCancel() {
    store.resetSelectContentDialog();
    dialog.close();
  }

  return (
    <Dialog
      content={
        <div data-testid="selectContentDialog" style={{ padding: "1.5em" }}>
          <h3 style={{ marginTop: "0.5em" }}>{rootStore.getTranslation(store.getSelectedAction() === SelectContentDialogActionEnum.BAN ? "profile.admin.ban_selected" : "profile.admin.unban_selected")}</h3>
          <SelectContentDialogContent />
          <div style={{
            paddingTop: "1.5em",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1em"
          }}>
            <ModusButton
              onClick={handleCancel}
              data-testid="cancelSelectContentDialog"
            >
              {rootStore.getTranslation("actions.cancel")}
            </ModusButton>
            <ModusButton
              onClick={handleBanOrUnbanContent}
              data-testid="confirmSelectContentDialog"
            >
              {rootStore.getTranslation(store.getSelectedAction() === SelectContentDialogActionEnum.BAN ? "profile.admin.ban_selected" : "profile.admin.unban_selected")}
            </ModusButton>
          </div>
        </div >
      }
    />
  );
});