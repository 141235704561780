import * as React from "react";
import { IDropdownOption, IDropdownOnChange, IDropdownOptionGroup } from "../models/dataModel";
import Select from "react-select";

/**
 * A dropdown component
 * @param options list of available options
 * @param selectedValue the selected option (optional)
 * @param placeholder placeholder string (optional)
 * @param onChange the onChange function
 * @param className className that determines dropdown style
 * @param inputId id of the dropdown, used to create testId (optional)
 * @param isDisabled should the dropdown be disabled (optional)
 */
export const Dropdown = ({
  ...props
}: {
  options: IDropdownOption[] | IDropdownOptionGroup[];
  selectedValue?: IDropdownOption;
  placeholder?: string;
  onChange: IDropdownOnChange;
  className: string;
  inputId?: string;
  menuHeight?: number;
  isDisabled?: boolean;
}) => {
  const testId = props.inputId ? `dropdown-${props.inputId}` : "dropdown";
  const maxMenuHeight = props.menuHeight ? props.menuHeight : 300;
  return (
    <div className={props.className} data-testid={testId} data-disabled={props.isDisabled}>
      <Select
        options={props.options}
        value={props.selectedValue}
        onChange={props.onChange}
        placeholder={props.placeholder}
        inputId={props.inputId}
        maxMenuHeight={maxMenuHeight}
        isDisabled={props.isDisabled || false}
      />
    </div>
  );
};
