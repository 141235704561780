import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStoreContext, EditorModeStore } from "../../components/editor-mode/editorModeStore";
import { PackagePageStoreContext, PackagePageStore } from "../packagePageStore";
import { DialogStore, DialogContext } from "../../dialogs/dialogStore";

import { SanitizationModeEnum } from "../../models/enums";

import { TextWrapper } from "../../components/TextWrapper";
import { Dialog } from "../../dialogs/Dialog";
import { EditDetailsDialog } from "../dialogs/EditDetailsDialog";

/**
 * A component that provides package details functionalities
 */
export const PackageDetails = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const dialog: DialogStore = new DialogStore();
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function canEdit() {
    return !!packageItem && packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function editDetails() {
    dialog.open();
  }

  function getDetails(): string {
    return packagePageStore.getDetails();
  }

  return (
    <Fragment>
      {canEdit() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-package-details-dialog"
            title={rootStore.getTranslation("details.edit.tooltips.details")}
            onClick={editDetails}
            className="icon icon-pencil"
          >
            <span>{rootStore.getTranslation("details.edit.tooltips.details")}</span>
          </a>
          <Dialog
            content={<EditDetailsDialog entity={packageItem!} store={packagePageStore} />}
            additionalClass="scrollable-fill"
          />
        </DialogContext.Provider>
      )}
      <p className="rich-content" data-testid="package-details">
        <TextWrapper
          text={getDetails()}
          cssClasses={["translated-html"]}
          sanitizationMode={SanitizationModeEnum.NONE}
        />
      </p>
      <p className="report-bad-content" data-testid="report-bad-content">
        <a
          href={rootStore.getTranslation("details.links.report_bad_content_link")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextWrapper
            text={rootStore.getTranslation("details.links.report_bad_content")}
            sanitizationMode={SanitizationModeEnum.JUST_LINKS}
          />
        </a>
      </p>
      <p className="report-illegal-content" data-testid="report-illegal-content">
        <a
          href={rootStore.getTranslation("details.links.report_illegal_content_link")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextWrapper
            text={rootStore.getTranslation("details.links.report_illegal_content")}
            sanitizationMode={SanitizationModeEnum.JUST_LINKS}
          />
        </a>
      </p>
    </Fragment>
  );
});
