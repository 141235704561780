/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */

import _ from "underscore";
import URI from "urijs";

import { Settings } from "../../config/Settings";
import { NewUserConverter } from "../converters/NewUserConverter";
import { TCCUserDS } from "../data-source/TCCUserDS";
import { TCCOrganizationService } from "./TCCOrganizationService";
import { UserSession } from "./UserSession";

import { IUserOrganization } from "../../models/dataModel";
import { SourceEnum, UserATCProfileStatusEnum } from "../../models/enums";
import { ITCCUser } from "../../models/TCCdataModel";

function getLandingURL() {
  return "/landing";
}

function logoutSilently(callBack) {
  UserSession.removeCurrentUser(callBack);
  TCCUserDS.logoff().then(
    function (res) {
      window.location.reload();
    },
    function (err) {
      window.location.reload();
    },
  );
}

function isVerifiedUser(atcProfile) {
  return atcProfile[0].Profile_status === UserATCProfileStatusEnum.VERIFIED;
}

function getMainOrganization(userOrganizations) {
  return userOrganizations.find((uo) => uo.roles.includes("employee") || uo.roles.includes("member"))?.organization;
}

/**
 * Stores user data in local storage and sets the user session.
 * @param {{ rawUser, atcProfile, rawOrganization: ITCCUser, userOrganizations: IUserOrganization, userCookies }} data - The user data to be stored.
 * @param {Function} callBack - The callback function to be executed after storing the user data.
 */
function storeUser(data, callBack) {
  var rawUser = data.rawUser;
  var atcProfile = data.atcProfile;
  var rawOrganization = data.rawOrganization;

  var userData = NewUserConverter.fromTCC(rawUser);
  var organization = rawOrganization;
  if (atcProfile) {
    userData.ATCId = atcProfile[0].Profile_id;
  }
  if (organization) {
    organization.source = SourceEnum.TCC;
    var filteredOrg = _.omit(organization, "description", "details", "contact");
    userData.organization = filteredOrg;
  }
  if (data.userOrganizations) {
    userData.userOrganizations = data.userOrganizations;
  }
  if (data.userCookies) {
    userData.cookies = data.userCookies;
  }
  var strippedUserData = JSON.stringify(_.omit(userData, "personalInfo"));
  localStorage.setItem(Settings.auth.currentUserLocalStorageName, strippedUserData);
  UserSession.setOrLoadUser(userData, callBack);
}

/**
 * Stores user data and redirects to the appropriate URL.
 * @param {Object} data - The user data to be stored.
 * @param {Function} cb - The callback function to be executed after storing the user data.
 * @returns {string} - The URL to redirect to.
 */
function storeUserAndRedirect(data, cb) {
  storeUser(data, cb);
  if (data.redirectToUrl) {
    var redirectToUri = new URI(data.redirectToUrl);
    if (redirectToUri.href() && redirectToUri.fragment() !== new URI(Settings.tcc.unauthorizedUrl).fragment()) {
      return redirectToUri.fragment();
    } else {
      return getLandingURL();
    }
  } else {
    return getLandingURL();
  }
}

/**
 * Resolves an error string based on the profile status.
 * @param {Object} profileStatus - The profile status object.
 * @returns {string} - The error string.
 */
function resolveErrorStringFromProfileStatus(profileStatus) {
  function userHasNotVerifiedEmail(profileStatus) {
    return profileStatus.indexOf(UserATCProfileStatusEnum.EMAIL_NOT_VERIFIED) >= 0;
  }

  function userHasNotAcceptedTerms(profileStatus) {
    return profileStatus.indexOf(UserATCProfileStatusEnum.TERMS_NOT_ACCEPTED) >= 0;
  }

  function userHasNotAcceptedUpdatedTerms(profileStatus) {
    return profileStatus.indexOf(UserATCProfileStatusEnum.UPDATED_TERMS_NOT_ACCEPTED) >= 0;
  }

  if (profileStatus) {
    if (userHasNotVerifiedEmail(profileStatus) && userHasNotAcceptedTerms(profileStatus)) {
      return "email_unverified_terms_not_accepted";
    }
    if (userHasNotVerifiedEmail(profileStatus)) {
      return "email_unverified";
    }
    if (userHasNotAcceptedUpdatedTerms(profileStatus)) {
      return "updated_terms_not_accepted";
    }
    if (userHasNotAcceptedTerms(profileStatus)) {
      return "terms_not_accepted";
    }
  }
  return "";
}

/**
 * Validates the ATC user.
 * If current user found, it will check if the user is the same as the current user.
 *
 * @param {any} callBack - The callback function to be executed after validation.
 * @returns {Promise} A promise that resolves when the validation is complete.
 */
function validateATCUser(callBack) {
  return new Promise((resolve, reject) => {
    TCCUserDS.me().then(
      function (rawUser) {
        if (rawUser) {
          var currentUser = UserSession.getCurrentUser();
          if (!currentUser) {
            TCCUserDS.atcGraphMe(rawUser.id).then(
              function (atcProfile) {
                if (isVerifiedUser(atcProfile)) {
                  TCCUserDS.getCookies().then(
                    function (userCookies) {
                      TCCUserDS.getOrganizations().then(
                        function (userOrganizations) {
                          let mainOrganization = getMainOrganization(userOrganizations);
                          if (!!rawUser && !!mainOrganization) {
                            TCCOrganizationService.get({ id: mainOrganization.id }).then(
                              function (rawOrganization) {
                                storeUser(
                                  {
                                    rawUser: rawUser,
                                    atcProfile: atcProfile,
                                    rawOrganization: rawOrganization,
                                    userOrganizations: userOrganizations,
                                    userCookies: userCookies,
                                  },
                                  callBack,
                                );
                                resolve();
                              },
                              function () {
                                console.log("Failed to load user's organization");
                                storeUser(
                                  {
                                    rawUser: rawUser,
                                    atcProfile: atcProfile,
                                    redirectToUrl: "/",
                                    userOrganizations: userOrganizations,
                                    userCookies: userCookies,
                                  },
                                  callBack,
                                );
                                resolve();
                              },
                            );
                          } else {
                            storeUser(
                              {
                                rawUser: rawUser,
                                atcProfile: atcProfile,
                                userOrganizations: userOrganizations,
                                userCookies: userCookies,
                              },
                              callBack,
                            );
                            resolve();
                          }
                        },
                        function () {
                          console.log("Failed to load user's organizations");
                          resolve();
                        },
                      );
                    },
                    function () {
                      console.log("Failed to load user's cookies");
                      resolve();
                    },
                  );
                } else {
                  console.log("Failed to verify user");
                  resolve();
                }
              },
              function () {
                console.log("Error validating ATC user");
                resolve();
              },
            );
          } else {
            var tccUser = NewUserConverter.fromTCC(rawUser);
            if (tccUser && tccUser.id != currentUser.id) {
              logoutSilently(callBack);
            }
            resolve();
          }
        } else {
          if (UserSession.getCurrentUser()) {
            UserSession.removeCurrentUser(callBack);
            resolve();
            window.location.assign(Settings.tcc.logOutCallbackUrl);
          }
          resolve();
        }
      },
      function () {
        if (UserSession.getCurrentUser()) {
          UserSession.removeCurrentUser(callBack);
          resolve();
          window.location.assign(Settings.tcc.logOutCallbackUrl);
        }
        resolve();
      },
    );
  });
}

/**
 * Validates the user session and performs necessary actions based on the session status.
 * @param {string | null} redirectToUrl - The URL to redirect the user after session validation.
 * @param {function} reloadUserCb - Callback function to reload user data.
 * @param {function} setAccountErrorCb - Callback function to set account error.
 * @param {function} navigateCb - Callback function to navigate to a specific URL.
 * @returns {Promise} - A promise that resolves when the session validation is complete.
 * @throws {Error} - If there is an error during the session validation process.
 */
function validateSession(redirectToUrl, reloadUserCb, setAccountErrorCb, navigateCb) {
  /**
   * Handles an invalid session.
   * @param {string} [errorCode] - The error code associated with the invalid session.
   */
  function handleInvalidSession(errorCode) {
    UserSession.removeCurrentUser(reloadUserCb);
    if (_.isString(errorCode)) {
      setAccountErrorCb(errorCode, navigateCb);
    } else {
      if (redirectToUrl) {
        var redirectToUri = new URI(redirectToUrl);
        navigateCb(redirectToUri.fragment());
      } else {
        navigateCb(getLandingURL());
      }
    }
  }

  TCCUserDS.me().then(
    function (rawUser) {
      if (rawUser) {
        TCCUserDS.atcGraphMe(rawUser.id).then(
          function (atcProfile) {
            if (isVerifiedUser(atcProfile)) {
              TCCUserDS.getCookies().then(
                function (userCookies) {
                  TCCUserDS.getOrganizations().then(
                    function (userOrganizations) {
                      let mainOrganization = getMainOrganization(userOrganizations);
                      if (!!rawUser && !!mainOrganization) {
                        TCCOrganizationService.get({ id: mainOrganization.id }).then(
                          function (rawOrganization) {
                            var url = storeUserAndRedirect({
                              rawUser: rawUser,
                              atcProfile: atcProfile,
                              rawOrganization: rawOrganization,
                              redirectToUrl: redirectToUrl,
                              userOrganizations: userOrganizations,
                              userCookies: userCookies,
                            });
                            navigateCb(url);
                          },
                          function () {
                            console.log("Failed to load user's organization");
                            var url = storeUserAndRedirect({
                              rawUser: rawUser,
                              atcProfile: atcProfile,
                              redirectToUrl: redirectToUrl,
                              userOrganizations: userOrganizations,
                              userCookies: userCookies,
                            });
                            navigateCb(url);
                          },
                        );
                      } else {
                        var url = storeUserAndRedirect({
                          rawUser: rawUser,
                          atcProfile: atcProfile,
                          redirectToUrl: redirectToUrl,
                          userOrganizations: userOrganizations,
                          userCookies: userCookies,
                        });
                        navigateCb(url);
                      }
                    },
                    function () {
                      console.log("Failed to load user's organizations");
                    },
                  );
                },
                function () {
                  console.log("Failed to load user's cookies");
                },
              );
            } else {
              handleInvalidSession(resolveErrorStringFromProfileStatus(atcProfile[0].Profile_status));
            }
          },
          function () {
            console.log("Failed to verify user profile");
            handleInvalidSession("cantverify");
          },
        );
      } else {
        handleInvalidSession();
      }
    },
    function () {
      console.log("Failed to load current user");
      handleInvalidSession();
    },
  );
}

export const Auth = {
  validateSession: validateSession,
  validateATCUser: validateATCUser,
  handleSSOCallback: function () {
    UserSession.removeCurrentUser();
    window.location.assign(Settings.tcc.logOutCallbackUrl);
  },
  handleSSOTCCCallback: function (params) {
    TCCUserDS.teklaContinueLogoff(params);
  },
};
