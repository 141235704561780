import _ from "underscore";

const host = window.location.host,
  TCCProductionHost = "warehouse.tekla.com",
  TCCPreproductionHost = "preprod.warehouse.tekla.com",
  TCCDevHost = "dev.warehouse.tekla.com",
  ATCProductionHost = "account.tekla.com/account",
  ATCPreproductionHost = "account.stg.tekla.com/account",
  AdminProdHost = "admin.account.tekla.com",
  AdminPreprodHost = "admin.account.stg.tekla.com",
  AdminDevHost = "admin.account.dev.tekla.com";

function apiEndpoint(): string {
  if (host == TCCProductionHost) {
    return "https://" + TCCProductionHost;
  } else if (host == TCCPreproductionHost) {
    return "https://" + TCCPreproductionHost;
  } else {
    return "https://" + TCCDevHost;
  }
}

function atcEndpoint(): string {
  if (host == TCCProductionHost) {
    return "https://" + ATCProductionHost;
  } else if (host == TCCPreproductionHost) {
    return "https://" + ATCPreproductionHost;
  } else {
    return "https://" + ATCPreproductionHost;
  }
}

function adminEndpoint(): string {
  if (host == TCCProductionHost) {
    return "https://" + AdminProdHost;
  } else if (host == TCCPreproductionHost) {
    return "https://" + AdminPreprodHost;
  } else {
    return "https://" + AdminDevHost;
  }
}

function cookieEndpoint(): string {
  if (host == TCCProductionHost) {
    return "https://cookies.prod.tekla.com/main.js";
  } else if (host == TCCPreproductionHost) {
    return "https://cookies.stg.tekla.com/main.js";
  } else {
    return "https://cookies.dev.tekla.com/main.js";
  }
}

function downloaderApplicationUrl(): string {
  if (host == TCCProductionHost) {
    return "/#/catalog/details/9025933f-4b12-4842-a636-94379cfe7468";
  } else if (host == TCCPreproductionHost) {
    return "/#/catalog/details/9025933f-4b12-4842-a636-94379cfe7468";
  } else if (host == TCCDevHost) {
    return "/#/catalog/details/9025933f-4b12-4842-a636-94379cfe7468";
  } else {
    return "https://dev.warehouse.tekla.com/#/catalog/details/9025933f-4b12-4842-a636-94379cfe7468";
  }
}

function isOffline(): boolean {
  return (
    _.contains(["localhost", "127.0.0.1"], host) && window.location.port == "8092" && window.location.protocol == "http"
  );
}

function localApiEndpoint(): string {
  if (isOffline()) {
    return "http://127.0.0.1:8090";
  } else {
    return window.location.protocol == "http"
      ? "http://local.warehouse.tekla.com:8091"
      : "https://local.warehouse.tekla.com:8091";
  }
}

function froalaKeys() {
  return {
    key: "mPD4tD2A1B1C1E1B2C1lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2E2C5F1F2F1H4A11==",
  };
}

function sketchUpEndpoint(): string {
  if (host == TCCProductionHost) {
    return "https://app.sketchup.com/viewer/tekla?WarehouseCollectionId=";
  } else if (host == TCCPreproductionHost) {
    return "https://stg-app.sketchup.com/viewer/tekla?use_stacks=stg&WarehouseCollectionId=";
  } else {
    return "https://dev-app.sketchup.com/viewer/tekla?use_stacks=dev&WarehouseCollectionId=";
  }
}

function isProduction() {
  return _.contains([TCCProductionHost], host);
}

function authSuccessCallback() {
  return apiEndpoint() + "/%23/auth/validate";
}

function authFailureCallback() {
  return apiEndpoint() + "/%23/auth/failure";
}

/**
 * Common settings
 */
export const Settings = {
  adaptiveSearchStorage: "adaptiveSearch",
  atc: {
    apiEndpoint: atcEndpoint(),
    adminEndpoint: adminEndpoint(),
  },
  ATCTicketCookieName: "idpsvu",
  auth: {
    currentUserLocalStorageName: "currentUser",
    sso: _.contains([TCCProductionHost, TCCPreproductionHost, TCCDevHost], host),
  },
  cookieEndpoint: cookieEndpoint(),
  defaultSearchTypeCookieName: "defaultSearchType",
  downloaderAppUrl: downloaderApplicationUrl(),
  froala: froalaKeys(),
  isLocalDev: _.contains(["localhost:8080", "127.0.0.1"], host),
  isOffline: isOffline(),
  isProduction: isProduction(),
  local: {
    apiEndpoint: localApiEndpoint(),
    validServiceVersions: "1.1.118.0",
  },
  localStorageName: "cart",
  sketchup: {
    sketchUpEndpoint: sketchUpEndpoint(),
  },
  searchRelevancyStorage: "searchrelevancy",
  surveys: {
    show082016: false,
    show012024: true,
  },
  notifications: {
    contentEditorRoleLaunchDate: "11",
    contentEditorRoleProductBulletinLink: "https://support.tekla.com/bulletin/product-bulletin-changes-to-editing-rights-in-tekla-warehouse",
  },
  tcc: {
    basicAuth: {
      username: "devLogin9",
      password: "RedWhiteBlue",
    },
    authUrl: "/warehouse/v1.0/loginflow/loginTekla",
    authSuccessCallbackPrefix: "&successUrl=",
    authSuccessCallback: authSuccessCallback(),
    authFailureCallbackPrefix: "&failureUrl=",
    authFailureCallback: authFailureCallback(),
    logOutUrl: "/warehouse/v1.0/loginflow/logoutTekla",
    continueLogOutUrl: "/warehouse/v1.0/loginflow/logoutTeklaContinue",
    downloadCallBackUrl: "/%23/download",
    logOutSuccessCallbackPrefix: "?successUrl=",
    logOutSuccessCallback: authSuccessCallback(),
    logOutFailureCallbackPrefix: "&failureUrl=",
    logOutFailureCallback: authFailureCallback(),
    logOutCallbackUrl: "/",
    unauthorizedUrl: "/%23/auth/handle_unauthorized",
    APITicketCookieName: "3dw_ticket",
    errorIds: {
      collectionAlreadyContainsElement: "api.errors.tcc.PARENT_ALREADY_CONTAINS_ELEMENT",
      collectionDoesNotContainElement: "api.errors.tcc.PARENT_DOES_NOT_CONTAIN_ELEMENT",
    },
    apiEndpoint: apiEndpoint(),
    localRequests: _.contains([TCCProductionHost, TCCDevHost, TCCProductionHost], host),
    defaultTimeout: 1200,
  },
};
