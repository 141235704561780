import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { EditTranslationsButton } from "./EditTranslationsDialog";
import { IItem } from "../../models/dataModel";

/**
 * A component that displays edit translations dialog.
 */
export const Translations = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);

  return (
    <Fragment>
      {editorModeStore.getEditorMode() && (
        <div>
          <div className="translations">
            <EditTranslationsButton item={editorModeStore.getResource() as IItem} />
            <a
              title={rootStore.getTranslation("details.edit.tooltips.batchedit_and_translations")}
              className="icon-help cart-info"
            ></a>
          </div>
        </div>
      )}
    </Fragment>
  );
});
