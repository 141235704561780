import { LocalUploadDS } from "../data-source/LocalUploadDS";
import { UploadJobConverter } from "../converters/UploadJobConverter";

export const LocalUploadService = {
  upload: function (data) {
    return LocalUploadDS.uploadBinaries(data).then(UploadJobConverter.fromLocal);
  },
  getUploadStatus: function (data) {
    return LocalUploadDS.getUploadStatus(UploadJobConverter.toLocal(data));
  },
};
