/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useContext, Fragment } from "react";
import classNames from "classnames";
import _ from "underscore";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { IModifierFilter, ISearchFilter, SearchPageStore, SearchPageStoreContext } from "./searchPageStore";
import { IDropdownOption, IDropdownOptionGroup } from "../models/dataModel";
import {
  ResultSetEnum,
  SearchFilterTypeEnum,
  SearchQueryParamEnum,
  SearchTargetOptionEnum,
  AvailableAttributes,
} from "../models/enums";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { Dropdown } from "../components/Dropdown";
import { SortBy } from "../components/search/SortBy";
import { ListStyleToggle } from "../components/search/ListStyleToggle";
import { metadataLabels } from "../utils/MetadataLabels";
import { Settings } from "../config/Settings";

/**
 * Selected radio button
 */
const ActiveRadioOption = observer(({ translationKey }: { translationKey: string }) => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <div className="radioactive-search" data-testid={`search-target-active-${translationKey}`}>
      <div className="radioactivedot-search" />
      <label className="l-inline">{rootStore.getTranslation(translationKey)}</label>
    </div>
  );
});

/**
 * Selectable radio button
 */
const RadioOption = observer(({ action, translationKey }: { action: () => void; translationKey: string }) => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <div className="radio-search" onClick={() => action()} data-testid={`search-target-${translationKey}`}>
      <label className="l-inline">{rootStore.getTranslation(translationKey)}</label>
    </div>
  );
});

/**
 * Adaptive search selection
 */
const AdaptiveSearchSelection = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const userStore = rootStore.getUserStore();
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const selectedGeoLocation = searchPageStore.getGeoLocation();
  const geoLocationOptions: IDropdownOption[] = _.map(metadataLabels.countryOptions, (option) => {
    return {
      value: option.value,
      label: rootStore.getTranslation(option.name),
    };
  });

  function handleAdaptiveSearchChange(event) {
    searchPageStore.handleUseAdaptiveSearchChange(event.target.checked);
  }

  function getSelectedGeoLocationOption() {
    if (selectedGeoLocation) {
      return _.find(geoLocationOptions, (option) => option.value == selectedGeoLocation);
    }
  }

  function handleGeoLocationChange(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;
    searchPageStore.handleGeoLocationChange(selectedOption.value);
  }

  const selectedGeoLocationOption = getSelectedGeoLocationOption();

  return (
    <Fragment>
      {userStore.isUserAdmin() && (
        <div className="personalized-search">
          <input
            className="white-blue-border"
            id="useAdaptiveSearch"
            type="checkbox"
            checked={searchPageStore.getUseAdaptiveSearch()}
            onChange={handleAdaptiveSearchChange}
            data-testid="use-adaptive-search"
          />
          <label htmlFor="useAdaptiveSearch">
            {rootStore.getTranslation("helpers.search.use_personalized_search")}
          </label>
          {searchPageStore.getUseAdaptiveSearch() && (
            <div className="geolocation">
              <div className="title">{rootStore.getTranslation("helpers.geolocation")}</div>
              <div className="sort-dropdown-search">
                <div className="dropdown-wrapper-search">
                  <Dropdown
                    options={geoLocationOptions}
                    onChange={handleGeoLocationChange}
                    className="dropdown-wrapper light"
                    inputId={"geolocation"}
                    selectedValue={selectedGeoLocationOption}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
});

/**
 * Search target selection (radio buttons)
 */
const SearchTargetSelection = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const [searchParams, setSearchParams] = useSearchParams();

  function setTarget(target: SearchTargetOptionEnum): void {
    searchPageStore.setSearchTarget(target);
    searchParams.set(SearchQueryParamEnum.searchTarget, target);

    searchParams.delete(SearchQueryParamEnum.showContentView);
    searchParams.delete(SearchQueryParamEnum.showOrganizationList);

    if ([SearchTargetOptionEnum.COLLECTION, SearchTargetOptionEnum.ORGANIZATION].includes(target)) {
      searchParams.delete(SearchQueryParamEnum.products);
      searchParams.delete(SearchQueryParamEnum.testedVersions);
      searchParams.delete(SearchQueryParamEnum.itemTypeCategories);
      searchParams.delete(SearchQueryParamEnum.locations);
      searchParams.delete(SearchQueryParamEnum.languages);
      searchParams.delete(SearchQueryParamEnum.useCategories);
      searchParams.delete(SearchQueryParamEnum.measurementUnits);
    }

    setSearchParams(searchParams);
  }

  function notOffline(): boolean {
    return !Settings.isOffline;
  }

  return (
    <div className="search-type-selection" data-testid="search-target-selection">
      <div className="radio-title">
        <TranslatedHtml entry={"helpers.search.show"} />
      </div>
      <div className="radiowrapper-search">
        {searchPageStore.getSearchTarget() == SearchTargetOptionEnum.PACKAGE && (
          <div className="firstgroup">
            <ActiveRadioOption translationKey={"helpers.search.show_content_items"} />
            <RadioOption
              action={() => setTarget(SearchTargetOptionEnum.COLLECTION)}
              translationKey={"helpers.search.show_collections"}
            />
            {notOffline() && (
              <RadioOption
                action={() => setTarget(SearchTargetOptionEnum.ORGANIZATION)}
                translationKey={"helpers.search.show_organizations"}
              />
            )}
          </div>
        )}
        {searchPageStore.getSearchTarget() == SearchTargetOptionEnum.COLLECTION && (
          <div className="secondgroup">
            <RadioOption
              action={() => setTarget(SearchTargetOptionEnum.PACKAGE)}
              translationKey={"helpers.search.show_content_items"}
            />
            <ActiveRadioOption translationKey={"helpers.search.show_collections"} />
            {notOffline() && (
              <RadioOption
                action={() => setTarget(SearchTargetOptionEnum.ORGANIZATION)}
                translationKey={"helpers.search.show_organizations"}
              />
            )}
          </div>
        )}
        {notOffline() && searchPageStore.getSearchTarget() == SearchTargetOptionEnum.ORGANIZATION && (
          <div className="thirdgroup">
            <RadioOption
              action={() => setTarget(SearchTargetOptionEnum.PACKAGE)}
              translationKey={"helpers.search.show_content_items"}
            />
            <RadioOption
              action={() => setTarget(SearchTargetOptionEnum.COLLECTION)}
              translationKey={"helpers.search.show_collections"}
            />
            <ActiveRadioOption translationKey={"helpers.search.show_organizations"} />
          </div>
        )}
      </div>
    </div>
  );
});

/**
 * Result set selection (local / TCC)
 */
const ResultSetSelection = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  function setResultSet(set: ResultSetEnum): void {
    searchPageStore.setResultSet(set);
  }

  function notOffline(): boolean {
    return !Settings.isOffline;
  }

  return (
    <Fragment>
      {notOffline() && searchPageStore.getSearchTarget() !== SearchTargetOptionEnum.ORGANIZATION && (
        <div className="online-local-selection" data-testid="result-set-selection">
          <div className="radiowrapper-search">
            {searchPageStore.getResultSet() == ResultSetEnum.TCC && (
              <div className="firstgroup">
                <ActiveRadioOption translationKey={"helpers.search.show_online_content"} />
                <RadioOption
                  action={() => setResultSet(ResultSetEnum.LOCAL)}
                  translationKey="helpers.search.show_offline_content"
                />
              </div>
            )}
            {searchPageStore.getResultSet() == ResultSetEnum.LOCAL && (
              <div className="secondgroup">
                <RadioOption
                  action={() => setResultSet(ResultSetEnum.TCC)}
                  translationKey="helpers.search.show_online_content"
                />
                <ActiveRadioOption translationKey={"helpers.search.show_offline_content"} />
              </div>
            )}
          </div>
          <div className={"local-content-info"}>
            <TranslatedHtml entry={"helpers.search.local_content_info"} />
          </div>
        </div>
      )}
    </Fragment>
  );
});

/**
 * Renders selected filter
 */
const SelectedFilterOption = observer(
  ({ namespace, filter, title }: { namespace: AvailableAttributes; filter: ISearchFilter; title: string }) => {
    const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
    const [searchParams, setSearchParams] = useSearchParams();

    function remove(namespace: AvailableAttributes, selectedOption: IDropdownOption): void {
      searchPageStore.removeFilter(namespace, selectedOption);
      setSearchParams(searchPageStore.stateToQueryParams());
    }

    return (
      <li
        className={classNames({
          "select2-search-choice": true,
          activated: !!filter.activated,
          excludechoice: filter.filterType === SearchFilterTypeEnum.EXCLUDE,
        })}
        data-testid={`selected-filter-${filter.filterType}-${filter.option.value}`}
      >
        <div title={title}>
          {filter.option.label}
          <a
            className={classNames({
              "select2-search-choice-close": true,
              excludechoice: filter.filterType === SearchFilterTypeEnum.EXCLUDE,
            })}
            onClick={() => remove(namespace, filter.option)}
            tabIndex={-1}
            data-testid={`remove-selected-filter-${filter.filterType}-${filter.option.value}`}
          />
        </div>
      </li>
    );
  },
);

/**
 * Renders list of selected filters
 */
const SelectedFilterOptions = observer(({ namespace, title }: { namespace: AvailableAttributes; title: string }) => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  return (
    <Fragment>
      {_.map(searchPageStore.getFilters(namespace), (filter, i) => {
        return <SelectedFilterOption filter={filter} namespace={namespace} title={title} key={i} />;
      })}
    </Fragment>
  );
});

/**
 * Renders filter dropdown
 */
const Filter = observer(
  ({
    options,
    namespace,
    translationKey,
  }: {
    options: IDropdownOption[] | IDropdownOptionGroup[];
    namespace: AvailableAttributes;
    translationKey: string;
  }) => {
    const rootStore: RootStore = useContext(RootContext);
    const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
    const [searchParams, setSearchParams] = useSearchParams();

    function add(namespace: AvailableAttributes, selectedOption: IDropdownOption | null): void {
      if (!selectedOption) return;
      searchPageStore.addFilter(namespace, selectedOption);
      setSearchParams(searchPageStore.stateToQueryParams());
    }

    const placeholder: string = rootStore.getTranslation(translationKey);

    return (
      <div className="pull">
        <div className="pull-wrapper" title={placeholder}>
          <Dropdown
            options={options}
            onChange={(selectedOption: IDropdownOption | null) => add(namespace, selectedOption)}
            placeholder={placeholder}
            className="dropdown-wrapper light"
            inputId={`${namespace}-filter`}
            selectedValue={{ value: placeholder, label: placeholder }}
          />
          <div className="select2-container-multi select2-container">
            <ul className="select2-choices">
              <SelectedFilterOptions namespace={namespace} title={placeholder} />
            </ul>
          </div>
        </div>
      </div>
    );
  },
);

/**
 * Renders exclusion filter dropdown
 */
const ExcludeFilter = observer(
  ({
    options,
    namespace,
    translationKey,
  }: {
    options: IDropdownOption[] | IDropdownOptionGroup[];
    namespace: AvailableAttributes;
    translationKey: string;
  }) => {
    const rootStore = useContext(RootContext);
    const searchPageStore = useContext(SearchPageStoreContext);
    const [searchParams, setSearchParams] = useSearchParams();

    function exclude(namespace: AvailableAttributes, selectedOption: IDropdownOption | null): void {
      if (!selectedOption) return;

      searchPageStore.excludeFilter(namespace, selectedOption);
      setSearchParams(searchPageStore.stateToQueryParams());
    }

    const placeholder: string = rootStore.getTranslation(translationKey);

    return (
      <div className="pull pull-exclude">
        <div className="pull-wrapper" title={rootStore.getTranslation(translationKey)}>
          <Dropdown
            options={options}
            onChange={(selectedOption: IDropdownOption | null) => {
              exclude(namespace, selectedOption);
            }}
            placeholder={placeholder}
            className="dropdown-wrapper light"
            inputId={`${namespace}-exclude-filter`}
            selectedValue={{ value: placeholder, label: placeholder }}
          />
        </div>
      </div>
    );
  },
);

/**
 * Renders a component with filters management (clear filters, toggle exclusion filters)
 */
const FilterLinks = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const [searchParams, setSearchParams] = useSearchParams();

  function toggleExcludedFilters(): void {
    searchPageStore.setExcludeFiltersOpen(!searchPageStore.getExcludeFiltersOpen());
  }

  function resetFilters(): void {
    searchPageStore.resetFilters();
    setSearchParams(searchPageStore.stateToQueryParams());
  }

  return (
    <div className="filter-links">
      {searchPageStore.filtersSize > 0 && (
        <a className="clear-filters" onClick={resetFilters} data-testid="clear-filters">
          {rootStore.getTranslation("search.bar.clear_filters")}
        </a>
      )}
      <a
        className={classNames({
          exclude: true,
          active: searchPageStore.getExcludeFiltersOpen(),
        })}
        onClick={toggleExcludedFilters}
        data-testid="show-exclude-filters"
      >
        {rootStore.getTranslation("search.bar.exclude")}
      </a>
    </div>
  );
});

/**
 * Renders filters
 */
const IncludeFilters = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  const productOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.SW_PRODUCTS,
  ) as IDropdownOption[];
  const useCategoriesOptions: IDropdownOptionGroup[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.USE_CATEGORIES,
  ) as IDropdownOptionGroup[];
  const languageOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.LANGUAGES,
  ) as IDropdownOption[];
  const measurementUnitOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.MEASUREMENT_UNITS,
  ) as IDropdownOption[];
  const locationRestrictionOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.LOCATIONS,
  ) as IDropdownOption[];
  const versionOptions = searchPageStore.getAvailableFilters(AvailableAttributes.SW_VERSIONS) as IDropdownOptionGroup[];
  const itemTypeCategoriesOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.ITEM_TYPE_CATEGORIES,
  ) as IDropdownOption[];

  return (
    <div className="filter-pulldowns" data-testid="include-filters">
      {searchPageStore.getResultSet() !== ResultSetEnum.LOCAL && (
        <Filter
          namespace={AvailableAttributes.SW_PRODUCTS}
          options={productOptions}
          translationKey={"placeholders.filter-options.products"}
        />
      )}
      {searchPageStore.getResultSet() !== ResultSetEnum.LOCAL && (
        <Filter
          namespace={AvailableAttributes.SW_VERSIONS}
          options={versionOptions}
          translationKey={"placeholders.filter-options.testedVersions"}
        />
      )}
      <Filter
        namespace={AvailableAttributes.ITEM_TYPE_CATEGORIES}
        options={itemTypeCategoriesOptions}
        translationKey={"placeholders.filter-options.itemTypeCategories"}
      />
      <Filter
        namespace={AvailableAttributes.LOCATIONS}
        options={locationRestrictionOptions}
        translationKey={"placeholders.filter-options.locations"}
      />
      <Filter
        namespace={AvailableAttributes.LANGUAGES}
        options={languageOptions}
        translationKey={"placeholders.filter-options.languages"}
      />
      <Filter
        namespace={AvailableAttributes.USE_CATEGORIES}
        options={useCategoriesOptions}
        translationKey={"placeholders.filter-options.useCategories"}
      />
      <Filter
        namespace={AvailableAttributes.MEASUREMENT_UNITS}
        options={measurementUnitOptions}
        translationKey={"placeholders.filter-options.measurementUnits"}
      />
    </div>
  );
});

/**
 * Renders exclusion filters
 */
const ExcludeFilters = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  const productOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.SW_PRODUCTS,
  ) as IDropdownOption[];
  const useCategoriesOptions: IDropdownOptionGroup[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.USE_CATEGORIES,
  ) as IDropdownOptionGroup[];
  const languageOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.LANGUAGES,
  ) as IDropdownOption[];
  const measurementUnitOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.MEASUREMENT_UNITS,
  ) as IDropdownOption[];
  const locationRestrictionOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.LOCATIONS,
  ) as IDropdownOption[];
  const versionOptions = searchPageStore.getAvailableFilters(AvailableAttributes.SW_VERSIONS) as IDropdownOptionGroup[];
  const itemTypeCategoriesOptions: IDropdownOption[] = searchPageStore.getAvailableFilters(
    AvailableAttributes.ITEM_TYPE_CATEGORIES,
  ) as IDropdownOption[];

  return (
    <div className="filter-actions">
      <FilterLinks />
      {searchPageStore.getExcludeFiltersOpen() && (
        <div className="exclude-filters" data-testid="exclude-filters">
          <div className="filter-pulldowns">
            {searchPageStore.getResultSet() !== ResultSetEnum.LOCAL && (
              <ExcludeFilter
                namespace={AvailableAttributes.SW_PRODUCTS}
                options={productOptions}
                translationKey={"placeholders.exclude-filter-options.products"}
              />
            )}
            {searchPageStore.getResultSet() !== ResultSetEnum.LOCAL && (
              <ExcludeFilter
                namespace={AvailableAttributes.SW_VERSIONS}
                options={versionOptions}
                translationKey={"placeholders.exclude-filter-options.testedVersions"}
              />
            )}
            <ExcludeFilter
              namespace={AvailableAttributes.ITEM_TYPE_CATEGORIES}
              options={itemTypeCategoriesOptions}
              translationKey={"placeholders.exclude-filter-options.itemTypeCategories"}
            />
            <ExcludeFilter
              namespace={AvailableAttributes.LOCATIONS}
              options={locationRestrictionOptions}
              translationKey={"placeholders.exclude-filter-options.locations"}
            />
            <ExcludeFilter
              namespace={AvailableAttributes.LANGUAGES}
              options={languageOptions}
              translationKey={"placeholders.exclude-filter-options.languages"}
            />
            <ExcludeFilter
              namespace={AvailableAttributes.USE_CATEGORIES}
              options={useCategoriesOptions}
              translationKey={"placeholders.exclude-filter-options.useCategories"}
            />
            <ExcludeFilter
              namespace={AvailableAttributes.MEASUREMENT_UNITS}
              options={measurementUnitOptions}
              translationKey={"placeholders.exclude-filter-options.measurementUnits"}
            />
          </div>
        </div>
      )}
    </div>
  );
});

/**
 * Filters section
 */
const Filters = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const creatorFilter: IModifierFilter | undefined = searchPageStore.getCreatorFilter();

  function removeCreatorFilter(): void {
    searchPageStore.removeCreatorFilter();
    setSearchParams(searchPageStore.stateToQueryParams());
  }

  return (
    <Fragment>
      {searchPageStore.getSearchTarget() === SearchTargetOptionEnum.PACKAGE && (
        <div className="filters">
          <span className="title">{rootStore.getTranslation("helpers.filter")}</span>
          {searchPageStore.shouldDisplayCreatorFilter() && (
            <section className="creator-filter" data-testid="creator-filter">
              <div className="select2-container select2-container-multi">
                <ul className="select2-choices">
                  <li className="select2-search-choice">
                    <div>
                      {creatorFilter!.organizationName!}
                      <a
                        className="select2-search-choice-close"
                        tabIndex={-1}
                        onClick={removeCreatorFilter}
                        data-testid="remove-creator-filter"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          )}
          <IncludeFilters />
          <ExcludeFilters />
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that renders side bar (search criteria).
 */
export const SideBar = observer(() => {
  const searchPageStore = useContext(SearchPageStoreContext);
  const pageWhereUsed = searchPageStore.getResultSet() === ResultSetEnum.LOCAL ? "local" : "online";

  return (
    <div className="left-panel" data-testid="side-bar">
      <div className="left-panel-content">
        <AdaptiveSearchSelection />
        <SearchTargetSelection />
        <div className="sorting-options-search">
          <SortBy pageWhereUsed={pageWhereUsed} sortSettingsStore={searchPageStore} />
          <ListStyleToggle pageWhereUsed="search" />
        </div>
        <Filters />
        <ResultSetSelection />
      </div>
    </div>
  );
});
