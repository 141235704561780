import _ from "underscore";
import { Base64 } from "js-base64";
import { observable, action, runInAction, makeObservable } from "mobx";

import { RootStore, createStoreContext } from "../../stores/rootStore";
import { Installer } from "../../utils/Installer";
import { IClient, IEntity, IDropdownOption, IVersion, IBatchVersion } from "../../models/dataModel";
import { LocalTsService } from "../../js/services/LocalTsService";
import { NewRepository } from "../../js/services/NewRepository";

export class DownloadDialogStore {
  /** Root store. */
  private rootStore: RootStore;

  /** List of selected entities */
  @observable private selectedEntities: IEntity[] = [];

  /** Version filter */
  @observable private versionFilter?: IDropdownOption;

  /**
   * Flag for observing if the download has been triggered via local service
   */
  @observable private downloadStarted = false;

  /**
   * Flag for observing if the download has been triggered via local service
   */
  @observable private selectedClient?: IClient;

  /**
   * Count of downloadable versions.
   */
  @observable private downloadableCount = 0;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Returns currently selected client
   * @returns selected client
   */
  public getSelectedClient(): IClient | undefined {
    return this.selectedClient;
  }

  /**
   * Sets currently selected client
   * @param client Selected client
   */
  @action
  public setSelectedClient(client: IClient) {
    this.selectedClient = client;
  }

  /**
   * Checks whether download should be disabled
   * @returns true if download should be disabled
   */
  public disableDownload(): boolean {
    return !this.selectedClient || this.downloadStarted || this.downloadableCount === 0;
  }

  /**
   * Get downloadable entities count
   * @returns count of entities
   */
  public getDownladableCount(): number {
    return this.downloadableCount;
  }

  /**
   * Sets the download information based on parameters.
   * @param entities list of selected entities
   * @param versionFilter version filter
   */
  @action
  public async setDownloadInformation(entities: IEntity[], versionFilter?: IDropdownOption): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      this.selectedEntities = entities;
      if (versionFilter) {
        this.versionFilter = versionFilter;
        await this.setVersions(this.selectedEntities);
        let versionsCount = 0;
        _.each(this.selectedEntities, (entity) => {
          const versions = this.getVersionsForSelectedVersion(entity, this.versionFilter!);
          versionsCount = versionsCount + versions.length;
        });
        runInAction(() => {
          this.downloadableCount = versionsCount;
        });
        resolve();
      } else {
        resolve();
      }
    });
  }

  /**
   * Donwloads content by using local service
   * @param client the client to be used for downloading content
   */
  @action
  public async downloadContent(client?: IClient) {
    try {
      if (client) {
        this.downloadStarted = true;
        const downloadUrls = await this.getDownloadUrlsForLocalService();
        const data = {
          clientId: client.id,
          ticket: this.get3dwTicket(),
          urls: downloadUrls,
        };
        await LocalTsService.downloadPackages(data);
      }
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("download.download_error"));
    }
  }

  /**
   * Gets the selection information based on selectedCount
   * @returns a string with number of selected items
   */
  public getSelectionInfo(): string {
    return this.rootStore.getTranslation(
      "download.total_selection_info_func",
      _.escape(this.selectedEntities.length.toString()),
    );
  }

  private async getUrlsForSelectedContent(): Promise<string[]> {
    const urls: string[] = await (async () => {
      const promises: Array<Promise<any>> = [];
      _.each(this.selectedEntities, (entity) => {
        const versions = this.getVersionsForSelectedVersion(entity, this.versionFilter!);
        _.each(versions, (version) => {
          const urlExists = version.attributes.isTool && version.tools.length > 0 && version.tools[0].url;
          if (urlExists) {
            promises.push(new Promise((resolve) => resolve(version.tools[0].url)));
          } else {
            promises.push(new Promise((resolve) => resolve(Installer.getDownloadVersionUrl(version))));
          }
        });
      });
      return await Promise.all(promises);
    })();
    return urls;
  }

  /**
   * Returns the download URL for download app.
   */
  public async getDownloadUrlsForApp(): Promise<string> {
    if (this.selectedEntities && this.versionFilter) {
      const urls: string[] = await this.getUrlsForSelectedContent();
      let urlParts: string[] = [];
      urlParts = ["TWH-DOWNLOADINFO-1.0"];
      _.each(urls, (url) => urlParts.push(this.resolveDownloadUrl(url)));
      urlParts.push(`3dw_ticket=${this.get3dwTicket()}`);
      return Base64.encode(urlParts.join("|"));
    } else {
      console.error("no selected entities or version filter");
      return "";
    }
  }

  /**
   * Returns the download URL for download app.
   */
  public async getDownloadUrlsForLocalService(): Promise<string[]> {
    if (this.selectedEntities && this.versionFilter) {
      const urls: string[] = await this.getUrlsForSelectedContent();
      const urlParts: string[] = [];
      _.each(urls, (url) => urlParts.push(this.resolveDownloadUrl(url)));
      return urlParts;
    } else {
      console.error("no selected entities or version filter");
      return [];
    }
  }

  /**
   * Method for initializing values
   */
  @action
  public init() {
    this.selectedClient = undefined;
    this.downloadStarted = false;
  }

  /**
   * Checks if download has been started
   * @returns true if download has started
   */
  public hasDownloadStarted(): boolean {
    return this.downloadStarted;
  }

  /**
   * Returns 3dw ticket read from the cookie
   * @returns 3dw ticket
   */
  private get3dwTicket(): string {
    return this.rootStore.getUserStore().get3dwTicket();
  }

  /**
   * Helper function that resolves the download URL based on host.
   */
  private resolveDownloadUrl(downloadUrl: string) {
    if (downloadUrl.toString().indexOf("https://") < 0) {
      return `https://${window.location.hostname}${downloadUrl}`;
    }
    return downloadUrl;
  }

  /**
   * Helper function to get versions that match the selected TS version.
   */
  private getVersionsForSelectedVersion(entity: IEntity, selectedVersion: IDropdownOption) {
    if (selectedVersion && selectedVersion!.value) {
      return !!entity.versions
        ? _.filter(entity.versions, (version: IBatchVersion) => {
            const supportedVersion = _.contains(version.attributes.testedVersions!, selectedVersion!.value);
            return supportedVersion || _.contains(version.attributes.testedVersions!, "NVS");
          })
        : [];
    } else {
      return [];
    }
  }

  /**
   * Fetches versions to given entity
   * @params entity to search versions for
   */
  private async fetchVersions(entity: IEntity): Promise<IVersion[]> {
    const versions = await NewRepository.getVersions(entity, false);
    runInAction(() => {
      entity.versions = versions;
    });
    return versions;
  }

  /**
   * Sets versions to given entities if not already existing
   * @params entities list of entities to handle
   */
  private async setVersions(entities: IEntity[]) {
    const promises: Array<Promise<IVersion[]>> = [];
    _.each(entities, async (entity) => {
      if (!entity.versions) {
        promises.push(this.fetchVersions(entity));
      }
    });
    return Promise.all(promises);
  }
}

export const DownloadDialogContext = createStoreContext<DownloadDialogStore>(DownloadDialogStore);
