import * as React from "react";
import { observer } from "mobx-react";
import { useEffect, useContext, Fragment } from "react";
import { useParams } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { TabNavigationStore, TabNavigationStoreContext } from "../components/tab-navigation/tabNavigationStore";
import { ResultOptionsStore, ResultOptionsStoreContext } from "../components/result-options/resultOptionsStore";
import {
  LinkToCollectionStore,
  LinkToCollectionStoreContext,
} from "../components/result-options/linkToCollectionStore";
import { CollectionPageStore, CollectionPageStoreContext } from "./collectionPageStore";
import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";
import { CollectionPageStrategy } from "./CollectionPageStrategy";

import { IResource } from "../models/dataModel";

import { Breadcrumbs } from "../components/breadcrumbs/Breadcrumbs";
import { BannedBanner } from "../components/BannedBanner";
import { ArchivedBanner } from "../components/ArchivedBanner";
import { Actions } from "../components/result-options/Actions";
import { Options } from "../components/result-options/Options";
import { Details } from "./Details";
import { Tabs } from "./Tabs";

import { TemporaryBannerNotification } from "../components/TemporaryBannerNotification";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { Settings } from "../config/Settings";
/**
 * A component that displays collection page.
 */
export const CollectionPage = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const collectionPageStore: CollectionPageStore = useContext(CollectionPageStoreContext);
  const collectionPageStrategy = new CollectionPageStrategy(rootStore);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    collectionPageStore.fetchData({ collectionId: id! });
  }, [id]);

  !!collectionPageStore.getCollection() && collectionPageStrategy.setCollection(collectionPageStore.getCollection());

  return (
    <Fragment>
      {collectionPageStore.wasDataFetched() ? (
        collectionPageStore.getCollection() && (
          <ResultOptionsStoreContext.Provider value={new ResultOptionsStore(rootStore, collectionPageStrategy)}>
            <LinkToCollectionStoreContext.Provider value={new LinkToCollectionStore(rootStore)}>
              <Breadcrumbs item={collectionPageStore.getCollection()! as IResource} />
              <BannedBanner item={collectionPageStore.getCollection()!} />
              <ArchivedBanner item={collectionPageStore.getCollection()!} />
              <TemporaryBannerNotification text={<TranslatedHtml
                entry="landing.notifications.content_editor_role.content_page_info"
                args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
                bannerFeatureToggle="contentEditorRoleInfoAfter"
                show={
                  rootStore.getUserStore().isUserLoggedIn() &&
                  (!!collectionPageStore.getCollection() &&
                    collectionPageStore.isCollectionOwnedByOrganization(collectionPageStore.getCollection()!) &&
                    rootStore.getUserStore().belongsToOrganization(collectionPageStore.getCollection()!) &&
                    !rootStore.getUserStore().canEditOrganization(collectionPageStore.getOrganization()!)
                  )
                }
              />
              <div id="content" className="collection">
                <EditorModeStoreContext.Provider value={new EditorModeStore(rootStore, collectionPageStore)}>
                  <Details />
                  <Actions />
                  <Options />
                  <TabNavigationStoreContext.Provider value={new TabNavigationStore()}>
                    <Tabs />
                  </TabNavigationStoreContext.Provider>
                </EditorModeStoreContext.Provider>
              </div>
            </LinkToCollectionStoreContext.Provider>
          </ResultOptionsStoreContext.Provider>
        )
      ) : (
        <div className="spinner l-margin-default l-center-align" data-testid="loadingCollection">
          {rootStore.getTranslation("shared.spinner_loading")}
        </div>
      )}
    </Fragment>
  );
});
