import _ from "underscore";

import { ExternalResourceTypeEnum, SourceEnum } from "../../models/enums";

/**
 * Creates a new creator object.
 * @param {Object} data - The data for the creator.
 * @param {ExternalResourceTypeEnum} creatorType - The type of the creator.
 * @param {SourceEnum} [source] - The source of the creator.
 * @returns {Object} - The created creator object.
 */
export function Creator(data, creatorType, source) {
  data = data || {};
  creatorType = creatorType || ExternalResourceTypeEnum.USER;
  source = source || data.source || SourceEnum.UNKNOWN;
  var creator = _.extend(_.clone(data), { externalResourceType: creatorType, source: source });
  return creator;
}
