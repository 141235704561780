import * as React from "react";
import { useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { NavLink, useLocation } from "react-router";

import { RootContext } from "../../stores/rootStore";

const MenuItem = observer(
  ({
    navi,
    path,
    title,
    showMobileMenuItems,
  }: {
    navi: string;
    path: string;
    title: string;
    showMobileMenuItems: boolean;
  }) => {
    const store = useContext(RootContext);
    const user = store.getUserStore().getCurrentUser();
    const { pathname } = useLocation();
    const isActive = pathname.includes(path.split("/")[1]);

    return (
      <li>
        <NavLink
          to={path}
          data-testid={"change-view-" + navi}
          className={() =>
            classNames("menuitem", {
              active: isActive,
              showMobileMenu: showMobileMenuItems,
              user: user,
              nouser: !user,
            })
          }
        >
          {() => (
            <div
              className={classNames("link", {
                active: isActive,
                showMobileMenu: showMobileMenuItems,
                user: user,
                nouser: !user,
              })}
            >
              {store.getTranslation(title)}
            </div>
          )}
        </NavLink>
      </li>
    );
  },
);

/**
 * A component that displays main navigation menu items
 */
export const Menu = ({ showMobileMenuItems }: { showMobileMenuItems: boolean }) => {
  const store = useContext(RootContext);

  return (
    <div className="menu">
      <MenuItem
        navi={"landing"}
        path={"/landing"}
        title={"mainNavigation.teklaWarehouse"}
        showMobileMenuItems={showMobileMenuItems}
      />
      <MenuItem
        navi={"search"}
        path={"/search"}
        title={"mainNavigation.search"}
        showMobileMenuItems={showMobileMenuItems}
      />
      <MenuItem
        navi={"about"}
        path={"/about/why"}
        title={"mainNavigation.about"}
        showMobileMenuItems={showMobileMenuItems}
      />
      {store.getUserStore().getCurrentUser() ? (
        <MenuItem
          navi={"my"}
          path={"/my/collections"}
          title={"mainNavigation.myWarehouse"}
          showMobileMenuItems={showMobileMenuItems}
        />
      ) : (
        <MenuItem
          navi={"my"}
          path={"/my/local"}
          title={"mainNavigation.myWarehouse"}
          showMobileMenuItems={showMobileMenuItems}
        />
      )}
    </div>
  );
};
