 
export const EsAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["es-ES"]) {
      window.locale["es-ES"] = {};
    }

    window.locale["es-ES"].about = {
      navigation: {
        why: "¿Por qué Tekla Warehouse?",
        usage_basics: "Conceptos básicos de Tekla Warehouse",
        "usage_basics__using-content": "Usar contenido",
        "usage_basics__adding-content": "Añadir contenido",
        usage_basics__collection: "Colección",
        "usage_basics__content-item": "Elemento de contenido",
        usage_basics__version: "Versión",
        getting_started: "Iniciación",
        "getting_started__tekla-account-and-system-requirements": "Tekla account y requisitos del sistema",
        "getting_started__tekla-warehouse-service-plugin": "Complemento Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "¿Cómo obtener Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures y Tekla Warehouse",
        content: "Contenido en Tekla Warehouse",
        "content__3d-products": "Productos de modelo 3D",
        "content__custom-components": "Componentes personalizados",
        content__applications: "Aplicaciones",
        "content__profiles-shapes-etc": "Perfiles, materiales, tornillos, armaduras, etc.",
        "content__model-templates": "Plantillas de modelo",
        "content__drawing-setup": "Configuración de dibujo",
        "content__report-templates-etc": "Cuadros de informes y archivos de atributos",
        using_content: "Usar contenido",
        creating_content: "Crear contenido",
        "creating_content__getting-started-with-content-creation": "Iniciación a la creación de contenido",
        "creating_content__expert-help-for-creating-content": "¿Ayuda experta para crear contenido?",
        application_developers: "Desarrollo de aplicaciones para Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "¿Por qué Tekla Warehouse?",
        description_1:
          "Tekla Warehouse incluye aplicaciones, componentes personalizados, partes, perfiles, materiales como calidades de acero y hormigón, tornillos, armaduras, mallas, formas y plantillas para Tekla Structures. Con las partes reales de los proveedores en los modelos se obtiene el máximo de precisión y la información exacta de los materiales para la planificación y la adquisición, y el uso de contenido predefinido acelera el modelado.",
        description_2:
          "Tekla Warehouse ofrece un acceso centralizado a este contenido que ahora se puede utilizar de un modo agilizado. El contenido en Tekla Warehouse aumenta constantemente.",
        description_3: "Con Tekla Warehouse",
        feature_1:
          "Los fabricantes pueden poner sus productos y aplicaciones a disposición de los usuarios, que pueden llevar los modelos de productos 3D exactos directamente a sus modelos de Tekla y utilizar posteriormente el producto físico en la construcción.",
        feature_2:
          "Puede utilizar la red de su empresa para compartir el contenido con compañeros y guardar el contenido localmente para uso privado.",
        description_4:
          "Con la búsqueda de Tekla Warehouse puede encontrar exactamente lo que necesite con facilidad, independientemente del tipo de contenido.",
        description_5:
          "Se necesita el complemento Tekla Warehouse Service para habilitar las colecciones en red y locales.",
        view_licences_html:
          'Consulte <a href="/api/third_party_licenses.txt" target="_blank">licencias de terceros</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Conceptos básicos de Tekla Warehouse",
        using_content: {
          title: "Usar contenido",
          description_1:
            "Puede usar el contenido de colecciones en línea, compartir el contenido con compañeros usando colecciones en red y utilizar colecciones locales para su uso privado.",
          description_2:
            "Las colecciones locales y en red necesitan Tekla Warehouse Service, que se instala con Tekla Structures 20.1 Service Release 1 y versiones posteriores de Tekla Structures.",
          description_3:
            "Las colecciones específicas de Tekla con contenido de entorno localizado se instalan con Tekla Structures.",
          description_4: "Consulte la sección Usar contenido para obtener más información.",
        },
        adding_content: {
          title: "Añadir contenido",
          description_1:
            'Puede utilizar colecciones en línea para publicar su contenido para todos los usuarios de Tekla Warehouse. Para publicar contenido como organización, su organización debe estar añadida a account.tekla.com. Póngase en contacto con su <a href="https://www.tekla.com/la/contacto/oficinas-y-representantes" target="_blank">oficina o distribuidor local de Tekla</a> para obtener más información.',
          description_2: "Consulte la sección Crear contenido para obtener más información.",
        },
        collection: {
          title: "Colección",
          feature_1:
            "Las colecciones agrupan elementos de contenido, como carpetas, y definen los derechos de acceso al contenido (público/privado).",
          feature_1_1:
            "Los modeladores pueden utilizar el contenido de colecciones en línea, compartir el contenido con compañeros con las colecciones en red y utilizar las colecciones locales para su uso privado.",
          feature_2:
            "Los proveedores de contenidos pueden utilizar las colecciones en línea para publicar su contenido a todos los usuarios de Tekla Warehouse.",
          feature_3:
            "Las colecciones locales y en red necesitan el complemento Tekla Warehouse Service, que se instala con Tekla Structures 20.1 Service Release 1 y versiones posteriores y también se puede instalar por separado.",
        },
        "content-item": {
          title: "Elemento de contenido",
          feature_1:
            "Un elemento de contenido es un grupo lógico de archivos. Por ejemplo, un catálogo de productos 3D, una plantilla de modelo, una herramienta, un grupo de componentes personalizados, o una base de datos de perfiles son elementos de contenido.",
          feature_2:
            "Los elementos de contenido se pueden etiquetar por tipo y caso de uso, lo que ayuda a los usuarios a encontrar su contenido.",
        },
        version: {
          title: "Versión",
          feature_1:
            "Al mismo tiempo, un elemento de contenido puede tener distintas versiones utilizadas con diferentes versiones de Tekla Structures. El usuario puede seleccionar la versión correcta en la página de contenido.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Iniciación",
        tekla_account_and_system_requirements: {
          title: "Tekla account y requisitos del sistema",
          register_atc_html:
            'Debe iniciar sesión en Tekla Warehouse con su Tekla account. Si no se ha registrado aún, hágalo en <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>. (Tenga en cuenta que esta no es la antigua cuenta de la Extranet.)',
          see_tua_for_prerequisites_html:
            'Consulte Tekla User Assistance para obtener información sobre los <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">requisitos previos para utilizar Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Complemento Tekla Warehouse Service",
          description_1: "Además de ser un almacenamiento en línea para el contenido, Tekla Warehouse también permite:",
          feature_1: "Colecciones locales para uso personal",
          feature_2: "Colecciones en red para compartir el contenido con compañeros, usando la red de la empresa",
          feature_3: "Una instalación sencilla al utilizar o crear contenido",
          feature_4: "Buscar contenido local y en red",
          description_2:
            "Esto es posible con Tekla Warehouse Service, un complemento que funciona como un sistema back-end local, comunicándose con el sitio web de Tekla Structures y Tekla Warehouse.",
          description_3:
            "Si Tekla Warehouse Service no está instalado o no se está ejecutando, Tekla Warehouse lo resalta en la parte superior de la página para poder solucionar el problema.",
          description_4:
            "Si se producen problemas con el servicio, puede reiniciarlo utilizando el programa de Servicios de Windows. El nombre del servicio es Tekla Warehouse. Compruebe también las configuraciones de seguridad de su navegador.",
          description_5:
            "El sitio web de Tekla Warehouse puede utilizarse sin Tekla Warehouse Service o Tekla Structures. La experiencia será entonces más parecida a un sitio web típico de uso compartido de contenido, en el que los archivos se cargan y descargan manualmente.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "¿Cómo obtener Tekla Warehouse Service?",
          description_1:
            "El complemento Tekla Warehouse Service se instala con Tekla Structures 20.1 Service Release 1 y versiones posteriores. También puede instalar por separado Tekla Warehouse Service para activar colecciones locales y en red, pero las características de carga e instalación sencilla son solo posibles con Tekla Structures 20.1 Service Release 1 y versiones posteriores.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">DESCARGAR TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures y Tekla Warehouse",
          description_1:
            "Tekla Warehouse se conecta directamente con Tekla Structures, haciendo que sea más fácil utilizar el contenido, o cargarlo. Puede seleccionar contenido directamente en su modelo y también insertar contenido directamente en el modelo sin tener que molestarse con la importación/exportación de archivos (para ello se necesita Tekla Structures 20.1 Service Release 1 o versiones posteriores). Todos los archivos necesarios se pueden incluir en el mismo elemento de contenido, como un componente personalizado o un perfil, para garantizar que los elementos funcionan como está previsto.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Contenido en Tekla Warehouse",
        description_1:
          "Tekla Warehouse ofrece distintos tipos de contenido. Aquí puede encontrar una descripción de los distintos tipos de contenido, así como más información para comenzar.",
        description_2:
          'En Tekla Warehouse también puede descargar <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Extensiones de Tekla</a>.',
        "3d_products": {
          title: "Productos de modelo 3D",
          image_subtitle:
            "Se trata de objetos con geometría definida, por ejemplo, objetos embebidos, encofrados y accesorios.",
          tua_link_html:
            'Consulte Tekla User Assistance para obtener más información sobre cómo <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">Crear un catálogo de productos 3D en una colección de Tekla Warehouse</a>.',
        },
        custom_components: {
          title: "Componentes personalizados",
          image_subtitle:
            "Los componentes personalizados son objetos nativos de Tekla Structures con geometría fija o paramétrica.",
          tua_link_html:
            'Consulte Tekla User Assistance para obtener más información sobre cómo <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Cargar contenido de un modelo de Tekla Structures en una colección de Tekla Warehouse</a>.',
        },
        applications: {
          title: "Aplicaciones",
          image_subtitle:
            "Las aplicaciones usan Tekla Open API para interactuar con Tekla Structures. Por ejemplo, enlaces de integración de datos en una solución de diseño.",
          tua_link_html:
            'Consulte Tekla User Assistance para obtener más información sobre cómo <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">Cargar una aplicación a una colección de Tekla Warehouse</a>.',
        },
        profiles_shapes_etc: {
          title: "Perfiles, formas, materiales, conjuntos de tornillos, armaduras y mallas",
          image_subtitle:
            "Las bases de datos contienen listas de propiedades relacionadas con partes. Por ejemplo, diferentes calidades de materiales y sus densidades.",
          tua_link_html:
            'Consulte Tekla User Assistance para obtener más información sobre cómo <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Cargar contenido de un modelo de Tekla Structures en una colección de Tekla Warehouse</a>.',
        },
        model_templates: {
          title: "Plantillas de modelo",
          image_subtitle:
            "Una plantilla de modelo se puede utilizar para proyectos similares, recurrentes. La plantilla puede contener todas las bases de datos necesarias, cuadros de informe, configuraciones de dibujos y configuraciones de opciones avanzadas.",
          tua_link_html:
            'Consulte Tekla User Assistance para obtener más información sobre cómo <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Añadir archivos manualmente a una colección de Tekla Warehouse</a>.',
        },
        drawing_setup: {
          title: "Configuración de dibujo",
          image_subtitle:
            "Los archivos de configuración definen cómo se crean los dibujos, por ejemplo disposiciones, filtros y plantillas de clonación.",
          tua_link_html:
            'Consulte Tekla User Assistance para obtener más información sobre cómo <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Añadir archivos manualmente a una colección de Tekla Warehouse</a>.',
        },
        report_templates_etc: {
          title: "Cuadros de informes y archivos de atributos de modelo",
          description_1:
            "Los cuadros de informe definen el contenido y la disposición de los datos del modelo en un informe. Los archivos de atributos de modelo, por ejemplo, los filtros, funcionan cuando se añaden a la carpeta del modelo.",
          tua_link_html:
            'Consulte Tekla User Assistance para obtener más información sobre cómo <a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Añadir archivos manualmente a una colección de Tekla Warehouse</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Usar contenido",
        description_1:
          "Dependiendo del tipo de contenido, puede insertar el contenido elegido en su modelo de Tekla Structures o bien descargarlo e instalarlo. Consulte Tekla User Assistance para obtener instrucciones sobre",
        feature_1:
          '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Buscar contenido en Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Usar contenido de Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Insertar contenido de Tekla Warehouse en un modelo de Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Gestión de conflictos en Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Crear contenido",
        getting_started_with_content_creation: {
          title: "Iniciación a la creación de contenido",
          description_1: "Consulte Tekla User Assistance para obtener más información sobre:",
          feature_1:
            '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Iniciación a Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">Añadir contenido a Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Modificar contenido en Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Descargar contenido desde Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "¿Ayuda experta para crear contenido?",
          description_1:
            'Si está interesado en publicar contenido en Tekla Warehouse, pero desea obtener ayuda experta para crearlo y mantenerlo, póngase en contacto con su <a href="https://www.tekla.com/la/contacto/oficinas-y-representantes" target="_blank">oficina o distribuidor local de Tekla</a> para obtener más información sobre posibilidades de colaboración.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Desarrollo de aplicaciones para Tekla Structures",
        description_1:
          "Puesto que Tekla aplica un enfoque abierto al modelado de información de construcción (BIM), puede ejecutar las soluciones y la maquinaria de fabricación de otros proveedores y seguir interactuando con Tekla.",
        description_2:
          '<a href="https://www.tekla.com/la/soluciones/dise%C3%B1o-interoperable/open-api" target="_blank">Tekla Open API</a> es una interfaz de programación de aplicaciones (API) especializada desarrollada por Tekla, que le permite desarrollar aplicaciones y funcionalidades adicionales en la plataforma de modelado de Tekla Structures e integrarlas en su propio entorno. Tekla Open API se implementa utilizando la tecnología Microsoft .NET.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center</a> es el centro de toda la información de Tekla Open API.',
        description_4:
          'Únase a <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> si está interesado en desarrollar aplicaciones para Tekla Structures.',
      },
    };
  },
};
