import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { OrganizationPageStoreContext } from "./organizationPageStore";
import { EditorModeStoreContext } from "../components/editor-mode/editorModeStore";

import { IStringValue } from "../models/dataModel";
import { SanitizationModeEnum } from "../models/enums";

import { EditContact } from "./dialogs/EditContact";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { TextWrapper } from "../components/TextWrapper";

interface ILinkInfo {
  // #region Properties (2)

  iconClassName: string;
  link: IStringValue;

  // #endregion Properties (2)
}

const ContactInfo = observer(() => {
  const orgStore = useContext(OrganizationPageStoreContext);
  const organization = orgStore.getOrganization();

  if (!organization || !organization.contact) return <Fragment />;

  return (
    <Fragment>
      {organization.contact.url && organization.contact.url.value && (
        <li key="organization.url" data-testid="organizationUrl">
          <a href={organization.contact.url.value} target="_blank" rel="noopener noreferrer">
            <span data-testid="organizationUrlValue">
              <TranslatedHtml entry="organization.website" />
            </span>
          </a>
        </li>
      )}
      {organization.contact.email && organization.contact.email.value && (
        <li key="organization.email" data-testid="organizationEmail">
          <span data-testid="organizationEmailValue">
            <TextWrapper text={organization.contact.email.value} sanitizationMode={SanitizationModeEnum.STRICT} />
          </span>
        </li>
      )}

      {organization.contact.phonenumber && organization.contact.phonenumber.value && (
        <li key="organization.phonenumber" data-testid="organizationPhone">
          <span data-testid="organizationPhoneValue">
            <TextWrapper text={organization.contact.phonenumber.value} sanitizationMode={SanitizationModeEnum.STRICT} />
          </span>
        </li>
      )}
    </Fragment>
  );
});

const ContactLink = observer(({ linkInfo }: { linkInfo: ILinkInfo }) => {
  const linkClass = `icon ${linkInfo.iconClassName}`;

  if (linkInfo.link && linkInfo.link.value) {
    return (
      <a
        className={linkClass}
        key={linkInfo.iconClassName}
        data-testid="socialMediaLink"
        href={linkInfo.link.value}
        target="_blank"
        rel="noopener noreferrer"
      />
    );
  } else return <Fragment />;
});

const ContactLinkList = observer(() => {
  const orgStore = useContext(OrganizationPageStoreContext);
  const organization = orgStore.getOrganization();

  if (!organization || !organization.contact) return <Fragment />;
  const links: ILinkInfo[] = [
    { link: organization.contact.facebook, iconClassName: "icon-facebook" },
    { link: organization.contact.twitter, iconClassName: "icon-twitter" },
    { link: organization.contact.linkedin, iconClassName: "icon-linkedin" },
    { link: organization.contact.youtube, iconClassName: "icon-youtube" },
  ];

  return (
    <section className="social-media-links" data-testid="socialMediaLinks">
      {links.map((linkInfo: ILinkInfo, i: number) => {
        return <ContactLink key={i} linkInfo={linkInfo} />;
      })}
    </section>
  );
});

/**
 * Component that displays organization contact information.
 */
export const ContactAndSupport = observer(() => {
  const orgStore = useContext(OrganizationPageStoreContext);
  const rootStore = orgStore.getRootStore();
  const editorModeStore = useContext(EditorModeStoreContext);

  return (
    <Fragment>
      <div className="contact-and-support" data-testid="contactAndSupport">
        <h3 className="title" data-testid="contactAndSupportTitle">
          {rootStore.getTranslation("organization.contact_information")}
        </h3>
        <Fragment>
          <ol className="more">
            {editorModeStore.getEditorMode() && orgStore.canEditOrganization() && (
              <li key="organization.edit_contact" data-testid="organizationEditContact">
                <EditContact />
              </li>
            )}
            <ContactInfo />
          </ol>
          <ContactLinkList />
        </Fragment>
      </div>
    </Fragment>
  );
});
