import { LocalRequestBuilder } from "../LocalRequestBuilder";
import { LocalResponseProcessor } from "../LocalResponseProcessor";

export const LocalPackagesDS = {
  getPackage: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/getPackage", data));
  },
  setPackage: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/setPackage", data));
  },
  deletePackage: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/deletePackage", data));
  },
};
