import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { LocalCollectionPageStoreContext } from "../localCollectionPageStore";
import { DialogStore, DialogContext } from "../../dialogs/dialogStore";
import { Dialog } from "../../dialogs/Dialog";
import { EditTitleDialog } from "../../dialogs/edit/EditTitleDialog";

/**
 * A component that displays local collection title
 */
export const LocalCollectionTitle = observer(() => {
  const rootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const localCollectionPageStore = useContext(LocalCollectionPageStoreContext);
  const dialog = new DialogStore();
  const collection = localCollectionPageStore.getCollection();

  function canEdit() {
    return editorModeStore.getEditorMode();
  }

  function openEditDialog() {
    dialog.open();
  }

  function getLocation() {
    return collection ? collection.location : "";
  }

  return (
    <Fragment>
      <header>
        {canEdit() && (
          <DialogContext.Provider value={dialog}>
            <a
              data-testid="open-edit-local-title-dialog"
              title={rootStore.getTranslation("shared.catalog_entity_edit.edit_title_or_delete")}
              onClick={openEditDialog}
              className="icon icon-pencil"
            >
              <span>{rootStore.getTranslation("shared.catalog_entity_edit.edit_title_or_delete")}</span>
            </a>
            <Dialog
              content={<EditTitleDialog item={collection!} store={localCollectionPageStore} showArchive={false} />}
            />
          </DialogContext.Provider>
        )}
        <h1>
          <div data-testid="local-collection-title" className="icon icon-drawer"></div>
          <span>{localCollectionPageStore.getTitle()}</span>
        </h1>
        <div className="path">{getLocation()}</div>
      </header>
    </Fragment>
  );
});
