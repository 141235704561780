import * as React from "react";
import { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootStore, RootContext } from "../../stores/rootStore";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import { Dropdown } from "../../components/Dropdown";
import { InstallDialogContext, InstallDialogStore } from "./installDialogStore";
import { DialogContext, DialogStore } from "../dialogStore";
import { IClient, IInstallationStatus, IDropdownOption } from "../../models/dataModel";
import { Dialog } from "../Dialog";

/**
 * Component that renders the box with information about items that can't be installed.
 */
const FailedToInstall = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: InstallDialogStore = useContext(InstallDialogContext);

  return (
    <div className="failed-installs" data-testid="install-failed">
      <div className="failed-content-area">
        <label className="failed-description">{rootStore.getTranslation("install.failed_install_information")}</label>
        <label className="failed-title">{store.getFailedListTitle()}</label>
        {store.getFailedInstallations().map((installation, i: number) => {
          return (
            <label className="failed-content" data-testid="install-failed-item" key={i}>
              {installation.title}
            </label>
          );
        })}
      </div>
    </div>
  );
});

/**
 * Component that renders the main content of the dialog.
 */
const DialogContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);
  const store: InstallDialogStore = useContext(InstallDialogContext);
  const localServiceStore = rootStore.getLocalServiceStore();
  const clients = getClients();

  useEffect(() => {
    if (clients.length === 1) {
      store.setSelectedTSVersion(clients[0]);
    }
  }, []);

  async function install() {
    await store.installSelected();
  }

  function getClients(): IClient[] {
    const clients = localServiceStore.getLocalService().clients;
    const massDownloadClients: IClient[] = localServiceStore.getDownloadCapableClients();
    return store.tsepVersionsExist() ? massDownloadClients : clients;
  }

  function handleCloseDialog() {
    dialog.close();
  }

  function changeVersion(option: IDropdownOption | null) {
    if (!option) return;

    const version = _.find(clients, (client) => {
      return client.name === option.value;
    });
    if (!!version) {
      store.setSelectedTSVersion(version);
    }
  }

  function getClientOptions(): IDropdownOption[] {
    const clientOptions: IDropdownOption[] = [];
    _.map(clients, (client) => {
      clientOptions.push({ label: client.name, value: client.name });
    });
    return clientOptions;
  }

  function installDisabled(): boolean {
    return !store.getSelectedTSVersion() || store.getInstallableCount() <= 0 || store.hasInstallStarted();
  }

  return (
    <article>
      <header>
        <h2 className="l-center-align" data-testid="install-title">
          {store.getSelectionInfo()}
        </h2>
      </header>
      <section className="labels">
        <ol className="basic-data">
          <div className="download-install">
            {store.hasFailedInstallations() && <FailedToInstall />}
            <div className="install-information" data-testid="install-information">
              {clients.length > 1 && (
                <div className="select-ts-version" data-testid="install-version-dropdown">
                  <Dropdown
                    options={getClientOptions()}
                    onChange={changeVersion}
                    placeholder={rootStore.getTranslation("install.select_ts_version")}
                    className="dropdown-wrapper gray"
                    inputId="selectCollection"
                  />
                </div>
              )}
              <label className="install-title" data-testid="install-subtitle">
                {store.getInstallableCount()} {rootStore.getTranslation("install.install_title")}
              </label>
              <button onClick={install} disabled={installDisabled()} data-testid="install-start">
                {rootStore.getTranslation("install.start_install")}
              </button>
              {store.hasInstallStarted() && (
                <div className="install-progress" data-testid="install-in-progress">
                  <label className="install-started">{rootStore.getTranslation("install.install_started")}</label>
                  <label className="install-title">{rootStore.getTranslation("install.install_retry")}</label>
                </div>
              )}
            </div>
          </div>
        </ol>
      </section>
      <div className="actions">
        <button onClick={handleCloseDialog} data-testid="install-close">
          {rootStore.getTranslation("actions.close")}
        </button>
      </div>
    </article>
  );
});

/**
 * Component that renders an install button and the dialog that opens when the button is clicked.
 */
export const InsertIntoModelDialog = observer(
  ({ active, installationStatus }: { active: boolean; installationStatus: IInstallationStatus }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);
    const store = new InstallDialogStore(rootStore);

    async function openDialog() {
      await store.setInstallationStatus(installationStatus);
      dialog.open();
    }

    return (
      <InstallDialogContext.Provider value={store}>
        <button className="install" disabled={!active} onClick={openDialog} data-testid="install-button">
          <span className="icon icon-install" />
          <TranslatedHtml entry="actions.insert" />
        </button>

        <Dialog content={<DialogContent />} />
      </InstallDialogContext.Provider>
    );
  },
);
