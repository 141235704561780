
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Cs = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["cs-CZ"]) {
      window.locale["cs-CZ"] = {};
    }

    _.extend(window.locale["cs-CZ"], {
      myDetails: {
        my_roles: "Moje funkce",
        organization_roles: "Funkce",
        roles: {
          member: "Editor obsahu Tekla Warehouse (Správce)",
          employee: "Správce",
          contentEditor: "Editor obsahu Tekla Warehouse (Externí)",
        },
      },
      immutability: {
        add_code_name_for_package: "Přidat kódový název obsahu",
        code_name: "Kódový název",
        immutable: "Neměnný",
        archive: "Archiv",
        archive_collection: "Archivovat kolekci a veškerý její obsah",
        archive_version: "Archivovat vybranou verzi",
        archive_package: "Archivovat balíček a veškerý jeho obsah",
        archive_dialog_text: "Archivovaný obsah nebude viditelný v Tekla Warehouse, ani jej nebude možné prohledávat. Pokud však cloudová služba Tekla Structures obsah již dříve použila, má k němu i nadále přístup.",
        archive_success: function (name: string) {
          return `${name} úspěšně archivováno`;
        },
        archive_failure: function (name: string) {
          return `Archivace ${name} se nezdařila`;
        },
        archived_content_redirect_message: "Obsah, ke kterému se pokoušíte získat přístup, byl archivován",
        edit_title_move_or_archive: "Upravit název, přesunout nebo archivovat",
        edit_title_or_archive: "Upravit název nebo archivovat",
        major_update: "Hlavní aktualizace",
        major_update_description: "Změny, které nejsou zpětně kompatibilní, jako je přidávání nebo odebírání funkcí nebo jiné změny mající vliv na funkčnost.",
        minor_update: "Menší aktualizace",
        minor_update_description: "Zpětně kompatibilní změny, jako jsou nové funkce nebo vylepšení bez narušení stávající funkčnosti.",
        patch_update: "Opravná aktualizace",
        patch_update_description: "Zpětně kompatibilní opravy, jako jsou bezpečnostní záplaty, opravy chyb nebo vylepšení výkonu, bez nových funkcí.",
        info: "Vaše organizace poskytuje obsah pro cloudovou službu Tekla Structures. Pro obsah v Tekla Structures cloudové službě je vyžadován kódový název a číslo verze, aby byla zajištěna kompatibilita a konzistence.",
        immutable_files_info: "Chcete-li zachovat konzistenci obsahu v cloudové službě, nemůžete odstraňovat ani upravovat soubory. Pokud jsou nutné úpravy, archivujte aktuální verzi a nahrajte novou verzi.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Další informace naleznete na <a href="${readMoreUrl}" target="_blank">této stránce</a>.`;
        },
        resolve_conflict: {
          title: "Vyřešit konflikt",
          description:
            "Byly nalezeny následující položky. Chcete-li zachovat stávající položky nalezené v instalaci, zvolte možnost „Zachovat stávající“.",
          table_header_name: "Název",
          table_header_type: "Typ",
          table_header_action: "Akce",
          action_keep_existing: "Zachovat stávající",
          action_replace: "Nahradit",
          action_done: "Hotovo",
        },
        auth: {
          authentication_process_failed: "Proces ověření se nezdařil.",
          access_denied: "Přístup odepřen",
          handle_unauthorized: {
            unauthorized: "Neoprávněný přístup",
            please_log_in: "Přihlaste se nebo zaregistrujte.",
          },
        },
        translations: {
          edit_translations: "Upravit překlady",
          edit_translations_info: "Vyberte ze seznamu jazyk, proveďte úpravu a uložte.",
          edit_translations_default_language_info:
            "Není-li jazyk vybraný uživatelem k dispozici, zobrazí se <b>Výchozí</b>.",
          edit_content: "Informace o obsahu",
          edit_support: "Podpora",
          updated_successfully: "Překlady byly úspěšně aktualizovány.",
          error_on_update: "Při aktualizaci překladů došlo k chybě.",
          default: "Výchozí",
          batch_translations: "Dávkové překlady",
          export_import_info:
            "Chcete-li uložit více překladů současně, <b>exportujte</b> předlohu dávkových překladů pro vámi vybraný obsah, upravte ji přidáním překladů a <b>importujte</b> předlohy zpět.",
          import_translations: "Import",
          export_translations: "Export",
          translations_missing: " (prázdné)",
          select_content: "Vyberte překlady pro export:",
          import_finished: "Import byl dokončen.",
          select_file: "Vybrat soubor",
          select_file_title: "Vyberte soubor pro import:",
          import_from_file: "Import",
          import_error_info: "Soubor nedodržuje formát předlohy:",
          export_selected: "Export",
          invalid_file: "Neplatný formát obsahu souboru",
          import_translations_finished: "Překlady byly importovány",
          details_too_long: "Obsah detailů překračuje maximální povolenou délku",
          import_failed: "Import překladů se nezdařil",
          translate_to_other_languages: "Přeložit do jiných jazyků",
          import_count: function (count) {
            return "Aktualizovány překlady pro " + count + " obsah";
          },
          invalid_language: function (contentId, language) {
            return "Obsah '" + contentId + "' obsahuje neplatný jazyk '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "Obsah '" + contentId + "' obsahuje neplatné pole překladu '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "Hodnota pole překladu '" +
              translationField +
              "'  obsahu '" +
              contentId +
              "' překračuje maximální povolenou délku"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "3D model",
          "3d_pic_added": "3D model byl úspěšně přidán",
          "3d_pic_updated": "3D model byl úspěšně aktualizován",
          "3d_pic_removed": "3D model byl úspěšně odebrán",
          "3d_pic_update_failed": "Aktualizace 3D modelu selhala",
          "3d_pic_remove_failed": "Odebrání 3D modelu bylo neúspěšné",
          "3d_pic_missing": "Žádný 3D obrázek pro obsah. Zvolte obrázek kliknutím na položku 'Zvolit 3D model' níže.",
          "3d_pic_uploading": "Daný model se načítá. Prosím čekejte...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Kliknutím sem upravíte obsah.",
          updated_successfully: "Úspěšně aktualizováno",
          update_failed: "Aktualizace selhala",
          edit_search_criteria: "Upravit kritéria hledání",
          edit_group_and_category: "Upravit skupinu a kategorii",
          edit_content: "Upravit obsah",
          edit_thumbnail: "Upravit miniaturu",
          edit_thumbnail_descriptive: "Doporučené rozměry: 400 × 300 px",
          edit_description: "Upravit popis",
          edit_download_link: "Přidejte nebo upravte partnerský odkaz ke stažení",
          versions_not_shown_to_users:
            "Protože tato položka obsahu má partnerský odkaz ke stažení, uživatelé Tekla Warehouse nevidí žádné další verze, které jste dříve přidali do položky obsahu. Správci a uživatelé Tekla Warehouse s oprávněním k úpravám nadále uvidí všechny verze položky obsahu.",
          partner_download_link: "Zadejte adresu URL ke stažení",
          partner_download_link_disclaimer:
            "Pokud do existující položky obsahu přidáte partnerský odkaz ke stažení, uživatelé Tekla Warehouse již neuvidí žádné další verze, které jste dříve přidali do položky obsahu. Tuto akci nelze vrátit zpět.",
          edit_title_or_delete: "Upravit nadpis nebo odstranit",
          edit_title_or_move_delete: "Upravit nadpis, přesunout nebo odstranit",
          edit_support: "Upravit informace o podpoře",
          edit_release_notes: "Upravit poznámky k nové verzi",
          edit_content_wysiwyg: "Upravit podrobnosti o obsahu",
          edit_content_embed_link: "Další informace získáte v Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Můžete vkládat pouze videa ze služeb YouTube, Vimeo nebo Alugha.",
          details_unallowed_embed_info:
            "Můžete vkládat pouze obsah z následujících domén: YouTube, Vimeo nebo Alugha. Povolené formáty URL adresy jsou:",
          your_video_id: "ID_VAŠEHO_VIDEA",
          edit_content_video_link_error: "Neplatný odkaz na video. Videa můžete přidávat pomocí adresy URL z YouTube a Vimeo",
          edit_content_vide_code_error:
            "Neplatný formát vložení. Akceptovány jsou pouze iframe. Povolenými poskytovateli videí jsou YouTube, Vimeo a Alugha.",
          details_max_length_exceeded: "Obsah Detaily je příliš dlouhý",
          editor_mode_label: "Režim editoru:",
          editor_mode_on: "Zap.",
          editor_mode_off: "Vyp.",
          do_not_notify: "Menší aktualizace, neupozorňovat účastníky",
          invalid_tag: "Neplatná značka",

        },
        transfer_package: {
          header: "Přesunout obsah",
          actions: {
            move: "Posunout",
            cancel: "Zrušit",
          },
          loading: "Načítání kolekcí...",
          moving: "Přesunování obsahu",
          select_collection: "Vybrat kolekci",
          content_moved: "Obsah byl přesunut. Změny se mohou projevit během několika minut.",
          operation_failed: "Operace se nezdařila",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Obsah úspěšně propojen do " + name;
          },
          selected_resources_linked: function (name) {
            return "Vybraný obsah úspěšně propojen do " + name;
          },
          resources_linked_with_errors: "Obsah propojen, u části obsahu došlo k chybám",
          resources_linking_failed: "Propojení obsahu se nezdařilo",
          resource_unlinked: "Odkaz úspěšně vymazán. Změny se mohou projevit během několika minut.",
          resources_unlinked:
            "Propojení byla z vybraného obsahu úspěšně odebrána. Změny se mohou projevit během několika minut.",
          resources_unlinked_with_errors: "Propojení odebrána, u části obsahu došlo k chybám",
          resources_unlinking_failed: "Odebrání propojení se nezdařilo",
          linking: "Vytváření odkazu",
          linking_failed: "Propojení se nezdařilo.",
          linking_failed_title: "Následující obsah nebylo možno propojit:",
          unlinking_failed_title: "U následujícího obsahu nebylo možno zrušit propojení:",
          linking_failed_already_contains:
            "Obsah již byl propojen s vybranou kolekcí. Zobrazení aktualizací může chvíli trvat.",
          linking_failed_already_contains_short: "Obsah již byl propojen s vybranou kolekcí",
          unlinking_failed: "Nepodařilo se odstranit odkaz.",
          unlinking_failed_does_not_contain:
            "Odkaz na obsah byl již odebrán z kolekce. Zobrazení aktualizací může chvíli trvat.",
          unlinking_failed_does_not_contain_short: "Odkaz na obsah byl již odebrán z kolekce",
        },
        notification_channel: {
          title_success: "úspěch",
          title_notice: "upozornění",
          title_info: "info",
          title_error: "chyba",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Stažení vyžaduje službu Tekla Maintenance",
          can_access_maintenance: {
            yes: "Ano",
            no: "Ne",
          },
        },
        confirm: {
          title: "Potvrdit",
          yes: "Ano",
          no: "Ne",
          ok: "OK",
          close: "Zavřít",
          dont_show: "Příště již nezobrazovat",
        },
        show_more: " Zobrazit více",
        show_less: " Zobrazit méně",
        spinner_loading: "Načítání",
        spinner_processing: "Zpracování",
        spinner_loading_long_time: "Načítání obsahu může trvat několik minut.",
        local_service: {
          info: "Pro tvorbu lokálního a síťového obsahu je nutný zásuvný modul Tekla Warehouse Service. Tekla Structures verze 20.1 SR1 a novější umožňuje nahrávat obsah přímo z modelu.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Zásuvný modul Tekla Warehouse Service</a>" +
            "je třeba pro tvorbu lokálního a síťového obsahu.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Zásuvný modul Tekla Warehouse Service</a>" +
            "není k dispozici. Tato služba je nutná k povolení funkce lokálních a síťových kolekcí.",
          download_to_unlock_all_features_html:
            "Stáhnout " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Zásuvný modul Tekla Warehouse Service</a>" +
            "aby byly dostupné všechny funkce.",
          service_out_of_date_html:
            "Váš zásuvný modul Tekla Warehouse není aktuální. Aktualizovat " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Zásuvný modul Tekla Warehouse Service</a>" +
            "aby byly dostupné všechny funkce.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Vyberte jazyk",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Pozvat další uživatele",
          send_invitation_to_email: "Zaslat pozvánku na e-mail",
          add_e_mail_addresses: "Přidat e-mailové adresy",
          and_assign_role: "a přiřadit funkci",
          send_invitation: "Zaslat pozvánku",
          invitation_already_sent: "Pozvánka již byla zaslána!",
          invitation_sent: "Pozvánka zaslána!",
          update_selected_invitations: "Aktualizovat vybrané pozvánky",
          new_role: "Nová funkce",
          invitations_list_updated: "Seznam pozvánek byl aktualizován!",
        },
        item_browser_title: "Vybrat položky obsahu",
        prerequisites: {
          description_placeholder: "Sem přidejte popis.",
          link_name_placeholder: "Sem přidejte název odkazu.",
          add_prerequisites_and_recommendations: "Vyžadované a doporučené součásti (volitelné)",
          list_the_profiles_components_files:
            "Seznam profilů, komponent, souborů a modelů, které jsou nutné k zajištění správné funkce obsahu.",
          link_to_existing_content: "Odkaz na stávající obsah",
          please_enter_prerequisite_url: "Zadejte požadovanou adresu URL",
          please_enter_valid_prerequisite_url: "Zadejte platnou adresu URL",
          missing_http: "Chybí http://",
          add_link: "Přidat odkaz",
        },
        treeview: {
          cannot_export_this_container: "Export této skupiny dat nelze provést. Vyberte jednotlivé položky.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "Soubor musí být menší než " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Neplatný typ souboru.  Soubor musí být jednoho z uvedených typů. " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Časový rámec:",
          timeframe_start: "Počátek:",
          timeframe_end: "Konec:",
          eventtype: "Typ události:",

          downloads_daily_by_version: "Stažení podle verze",
          downloads_by_country: "Stažení podle země",
          downloads_by_version: "Stažení podle verze",
          downloads_by_version_organization: "Stažení podle verze a organizace",
          downloads_by_organization: "Stažení podle organizace",
          downloads_daily_by_package: "Stažení podle položky obsahu",
          views_daily_by_package: "Zobrazení podle položky obsahu",
          views_by_country: "Zobrazení podle země",
          views_by_organization: "Zobrazení podle organizace",

          downloads_by_package: "Stažení podle položky obsahu",
          views_by_package: "Zobrazení podle položky obsahu",
          downloads_daily_by_collection: "Stažení podle kolekce",
          downloads_by_collection: "Stažení podle kolekce",
          views_daily_by_collection: "Zobrazení podle kolekce",
          views_by_collection: "Zobrazení podle kolekce",

          events_by_organization_func: function (version) {
            return 'Verze "' + version + '" události podle organizace'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Ostatní",
          sum_all_versions: "Všechny verze",
          sum_all_packages: "Všechny položky obsahu",
          sum_all_collections: "Všechny kolekce",
          sum_all_organizations: "Všechny organizace",
          recording_started_at: "Záznam dat začal v červnu 2015",
          recording_view_events_started_at: "Záznam dat začal v březnu 2017",
          version_title_func: function (title) {
            return 'Verze "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return title + " (" + pluralize(count, [count + " stažení", count + " stažení", count + " stažení"]) + ")"; // Tekla Oy (21 downloads)
          },
          counted_events_info: "Čísla zahrnují všechna dokončená stahování.",
          counted_events_info_package: "Čísla zahrnují všechna dokončená stahování.",
          export: {
            caption: "Export analýzy",
            select_options: "Výběr možností",
            select_timeframe_description: "Zvolte časový rámec pro export analýz z:",
            select_eventtype_description: "Vyberte typ události pro export analýz z:",
          },
        },
        access_rights: {
          editors: "Editory",
          download_access: "Přístup k zobrazení a stažení",
          write_user_id_placeholder: "Zapsat ID uživatele",
          write_organization_id: "Zadejte platné ID organizace. Zadejte každé ID na samostatný řádek.",
          write_id_placeholder:
            "Zadejte ID uživatelů, e-mailové adresy uživatelů nebo ID organizací. Každé ID nebo e-mailovou adresu zadejte na samostatný řádek.",
          id_help_html_short:
            "Z bezpečnostních důvodů nelze v našich uživatelích vyhledávat.<br/><br/>" +
            "Požádejte o ID uživatele přímo osobu, které chcete udělit přístup. <br/>" +
            'ID uživatele je zobrazeno v části <a href="#/my/">Moje detaily</a>.',
          id_help_html:
            "Z bezpečnostních důvodů nelze v našich uživatelích vyhledávat.<br/><br/>" +
            "Požádejte o ID uživatele přímo osobu, které chcete udělit přístup. Chcete-li poskytnout přístup všem uživatelům v organizaci, použijte ID organizace.<br/><br/>" +
            'ID uživatele a organizace jsou zobrazeny v části <a href="#/my/">Moje detaily</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Každé ID nebo e-mailovou adresu zadejte na samostatný řádek. Najednou můžete zadat maximálně " +
              max_rows +
              " řádků."
            );
          },
          candidates_for_keyword: function (keyword) {
            return "Byli nalezeni uživatelé s e-mailovou adresou nebo ID " + keyword;
          },
          input_too_long: "Zadaný text je příliš dlouhý.",
          viewer_already_added: function (input) {
            return `${input} byl/a již přidán/a v režimu prohlížení`;
          },
          wrong_format: function (input) {
            return `${input} není platné ID nebo e-mailová adresa.`;
          },
        },
      },
      images: {
        add_new: "Přidat nový",
        add: "Přidat",
        delete: "Vymazat",
        set_as_default: "Výchozí miniatura",
        select_file: "Vybrat soubor",
        choose_option: "Vyberte volbu",
        info_3D_exists: "3D model jste již přidali, k obsahu lze přidat pouze jeden 3D model.",
        action_options: {
          image: "Obrázek",
          "3DModel": "3D model",
          video: "Video",
        },
        video: {
          url: "Adresa URL videa",
          invalid_url: "Chybná adresa URL",
        },
      },
      units: {
        bytes: "B",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Stáhnout",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " lajků", number + " lajk", number + " lajků"]);
      },
      main_info: {
        header_title: "Web Tekla Warehouse má nový vzhled!",
        header_text: "Jak se vám líbí nový vyladěný web Tekla Warehouse?",
        header_text2: "Hlavní změny:",
        bullet_text_1: "Přehledné hledání na úvodní stránce.",
        bullet_text_2:
          "Všechny filtry hledání na stránce Hledat jsou umístěny vlevo a výchozí zobrazení s miniaturami obsahuje více výsledků hledání v jednom pohledu.",
        bullet_text_3:
          "Možnost Stáhnout na stránce Obsah je umístěna uprostřed, začít používat obsah je proto snadné. Možnosti Stáhnout a Instalovat jsou nyní obě přístupné pomocí tlačítka Stáhnout",
        bullet_text_4: "Stránky Kolekce a Můj košík obsahují pokyny ve 3 krocích k použití stažení a instalaci všeho.",
      },
      organizations: {
        error_loading_collection_contents: "Chyba při načítání obsahu kolekce",
        error_loading_organization: "Chyba při načítání organizace",
      },
      downloads_graph: {
        date: "Datum",
        total_views: "Počet zhlédnutí",
        total_downloads: "Počet stažení",
        views: "Pohledy",
        downloads: "Stažení",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Zásuvný modul Tekla Warehouse Service" +
          "</a>" +
          " je vyžadován.",
        processingFailed: "Zpracování se nezdařilo",
        forbidden: "Zakázáno",
        resource_not_found: "Zdroj nebyl nalezen.",
        no_matches_found: "Nebyly nalezeny žádné shody",
        no_matches_found_for: function (input) {
          return "Nebyly nalezeny žádné shody s e-mailovou adresou nebo ID " + input;
        },
        not_identified: "Neidentifikováno",
        error_while_searching: "Chyba při vyhledávání",
        error_user_not_found: "Uživatele nebo organizaci se nepodařilo nalézt.",
        error_while_adding_member: "Chyba při přidávání přístupových práv členovi",
        error_while_removing_member: "Chyba při odebírání přístupových práv členovi",
      },
      offline_to_online: {
        fail_on_initialize: "Chyba při zpracování obsahu archivu zip, převod přerušen",
        fail_on_validate: "Ověření obsahu metadat selhalo, převod přerušen",
        fail_on_save: "Při ukládání obsahu došlo k chybě, vrácení změn zpět.",
        success: "Obsah offline úspěšně převeden na obsah online",
        validate_errors_title: "Převod obsahu se nezdařil",
        processing: "Uložení balíčku ",
        collection_thumbnail_missing_name: "Binárnímu souboru miniatury chybí 'název souboru'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Binární miniatuře balíčku '" + packageTitle + "' chybí 'název souboru'";
        },
        package_value_missing_title_func: function () {
          return "Balíčku chybí 'název'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Balíčku '" + packageTitle + "' chybí '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "Pole '" +
            field +
            "' balíčku '" +
            packageTitle +
            "' obsahuje následující neplatné hodnoty: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Balíček '" + packageTitle + "' má neplatnou značku: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "Verzi balíčku '" + packageTitle + "' chybí 'nadpis'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Package '" + packageTitle + "' version '" + versionTitle + "' is missing '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Pole '" +
            field +
            "' verze '" +
            versionTitle +
            "' balíčku '" +
            packageTitle +
            "' obsahuje následující neplatné hodnoty: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "Binárnímu souboru verze '" + versionTitle + "' balíčku '" + packageTitle + "' chybí 'název souboru'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Důležité: Konec podpory lokálních kolekcí v Tekla Warehouse",
            part1: "Od 2 czerwca 2025 již nebudou lokální kolekce dostupné v Tekla Warehouse. Uživatelé již nebudou moci vytvářet, prohlížet ani vyhledávat lokální kolekce nebo používat obsah z lokálních kolekcí.",
            part2: "Pro pomoc s přechodem z lokálních kolekcí na online kolekce kontaktujte prosím vaši místní podpůrnou organizaci.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Důležité! V rámci zvýšení zabezpečení můžete po ${date}. listopadu 2024 spravovat obsah své organizace v Tekla Warehouse pouze s rolí Editor obsahu. <br />Požádejte správce své organizace, aby vám tuto roli přidělil. Další informace naleznete v <a href="${productBulletinUrl}" target="_blank">produktovém bulletinu</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `V rámci zvýšení zabezpečení můžete po ${date}. listopadu 2024 spravovat obsah své organizace v Tekla Warehouse pouze s rolí Editor obsahu. <br />Požádejte správce své organizace, aby vám tuto roli přidělil. Další informace naleznete v <a href="${productBulletinUrl}" target="_blank">produktovém bulletinu</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Nevidíte možnost úprav pro svou organizaci? Po ${date}. listopadu musíte mít roli Tekla Warehouse Editor obsahu, abyste mohli spravovat obsah své organizace. <br />Svou roli můžete zkontrolovat v části Můj Warehouse > Moje podrobnosti. Další informace naleznete v <a href="${productBulletinUrl}" target="_blank">produktovém bulletinu</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Nevidíte možnost vytvořit obsah pro svou organizaci? Po ${date}. listopadu musíte mít roli Tekla Warehouse Editor obsahu, abyste mohli spravovat obsah své organizace. Svou roli můžete zkontrolovat v části Můj Warehouse > Moje podrobnosti. Další informace naleznete v <a href="${productBulletinUrl}" target="_blank">produktovém bulletinu</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Po ${date}. listopadu musíte mít roli Tekla Warehouse Editor obsahu, abyste mohli spravovat obsah své organizace. Požádejte správce své organizace, aby vám tuto roli přidělil. Ve svém profilu Tekla Online zjistíte, kdo je správcem vaší organizace. Další informace naleznete v <a href="${productBulletinUrl}" target="_blank">produktovém bulletinu</a>.`
            },
          },
          service_plugin_update_info:
            'Máš problémy s připojením k Tekla Structures? Ujisti se, že máš nainstalovaný nejnovější <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="blank" rel="noopener noreferrer">plugin Tekla Warehouse Service</a>.',
        },
        maintenance_info:
          "27. 10. 2017 probíhá údržba Tekla Warehouse v čase 22:00:00–23:00:00 UTC. Omlouváme se za nepříjemnosti.",
        release_notes_info: "Nové funkce Tekla Warehouse",
        release_notes_link:
          'Služba Tekla Warehouse byla aktualizována, podívejte se, <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">co je nového</a>.',
        heading: "Tekla Warehouse",
        heading1Dev: "Sháníte pro svůj projekt vývojáře Tekla?",
        heading2Dev:
          'Vyzkoušejte náš nový adresář s názvem Odborníci na vývoj společnosti Tekla.<br><br>Jedná se o místo slučující talentované programátory Tekla a uživatele, kteří by o ně měli zájem.<br><br>Své kontaktní informace mohou do adresáře odborníků přidávat všichni účastníci programu Tekla Partners Program.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Hledat vývojáře &rsaquo;</a>',
        static_intro:
          "Toto je bezplatné úložiště BIM Tekla Structures, kde můžete najít, importovat, instalovat a sdílet obsah interně i globálně. Tekla Warehouse usnadňuje efektivní tvorbu modelů vysoké jakosti.",
        new_intro:
          'Tekla Corporation se nyní jmenuje Trimble. I nadále budeme pokračovat ve vývoji softwarových řešení Tekla a pořád jsou tu pro vás ti stejní lidé. Další informace: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Ověřte svůj účet.",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Nejnovější nahrané soubory &rsaquo;",
          popular: "Oblíbený obsah &rsaquo;",
          recommended: "Doporučené &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Zprávy &rsaquo;</a>',
          item_title: "Tohle je zpráva!",
          item_description:
            'Nějaký text týkající se zprávy, nějaký text týkající se zprávy, nějaký text týkající se zprávy, nějaký text týkající se zprávy, nějaký text týkající se zprávy, nějaký text týkající se zprávy.<br><a href="https://developer.tekla.com" target="_blank">Další informace &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse v číslech",
          partners: "Partneři",
          partners_count: "352",
          collections: "Kolekce",
          collections_count: "793",
          items: "Položky obsahu",
          items_count: "2893",
        },
        partners: "Naši partneři",
        action_links: {
          search_title: "Procházet obsah?",
          upload_title: "Nahrát obsah?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Videa &rsaquo;</a>',
          link: 'Další videa najdete na našem <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">kanálu YouTube</a>.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Najděte odkaz pro ověření v doručené poště, případně si <u><a href="' +
            settingsUrl +
            '">vyžádejte nový odkaz</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Ověřte prosím e-mailovou adresu a přijměte podmínky a zásady ochrany osobních údajů.",
        acceptTerms: "Přijměte podmínky a zásady ochrany osobních údajů.",
        acceptUpdatedTerms:
          "Potvrďte prosím aktualizované podmínky a zásady ochrany osobních údajů tekla online services.",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Návrat do <u><a href="' + settingsUrl + '">profilu</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Přečtěte si a přijměte podmínky a zásady ochrany osobních údajů ve vašem <u><a href="' +
            settingsUrl +
            '">profilu</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Procházet kolekce &rsaquo;",
          new: "Nový",
          popular: "Oblíbené",
          recommended: "Doporučené",
          organizations: "Organizace",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Vyberte složku",
        selectFolderInstructions: "Lokální a síťové složky mohou být použity jako kolekce.",
        selectedFolder: "Vyberte složku (zkopírujte a vložte umístění):",
        selectedFolderPlaceholder: "Např. C:\\složka, Z:\\síťová složka, \\\\název-počítače\\složka",
        collectionCreation: {
          name: "Název",
          createNewCollection: "Vytvořit lokální kolekci",
          pleaseInputTitle: "Zadejte prosím název.",
          invalidPath: "Chybná cesta",
          access_denied: "Nepodařilo se přidat kolekci: přístup odepřen",
          unsafe_location: "Nepodařilo se přidat kolekci: nebezpečné umístění",
          add_collection_failed: "Nepodařilo se přidat kolekci",
          minLengthError: "Název musí obsahovat minimálně 3 znaky.",
          maxLengthError: "Název nesmí být delší než 20 znaků.",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Lokální a síťové složky mohou být použity jako kolekce.",
        collectionCreation: {
          name: "Název",
          createNewCollection: "Vytvořit online kolekci",
          pleaseInputTitle: "Zadejte prosím název.",
          minLengthError: "Název musí obsahovat minimálně 3 znaky.",
          maxLengthError: "Název nesmí být delší než 20 znaků.",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Prostředek uložen",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Vidíte ty možnosti?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Poskytněte nám zpětnou vazbu</span>',
          help_us_improve_html:
            'Pomozte nám zdokonalovat Tekla Warehouse! <br/><a id="showSurvey">Klikněte zde</a> nebo <a id="dontShow">znovu nezobrazovat</a>',
          dialog: {
            do_you_see_potential: "Vidíte ty možnosti?",
            help_us_improve_html: "Pomozte nám zdokonalovat Tekla Warehouse! Váš názor je pro nás důležitý.",
            continue_to_survey: "Spustit průzkum",
            exit_to_warehouse: "Ne, děkuji",
          },
        },
      },
      search: {
        bar: {
          search: "Hledat",
          filter: " ",
          more_filters: "Další filtry",
          hide_filters: "Skrýt filtry",
          clear: "Vymazat",
          exclude: "Kromě",
          include: "Včetně",
          clear_filters: "Resetovat filtry",
          load_defaults: "Použít moje předvolby &rsaquo;",
          save_defaults: "Uložit jako moje předvolby &rsaquo;",
          defaults_saved_successfully: "Výchozí filtry uloženy",
          edit_defaults: "Upravit výchozí hodnoty",
        },
        info: {
          searching_organizations: "Hledání v organizacích...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Žádné výsledky", amountTotal + " výsledky"]);
            } else {
              return (
                pluralize(amountTotal, ["Žádné výsledky", amountTotal + " výsledky"]) +
                ' pro "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " výsledky";
            } else if (!searchTerm) {
              return "Zadejte hledaný výraz, zobrazí se výsledky hledání organizace.";
            } else if (searchTerm.length < 2) {
              return "Pro hledání organizace zadejte minimálně dva znaky.";
            } else {
              return (
                pluralize(amountTotal, ["Žádné výsledky", amountTotal + " výsledky"]) +
                ' pro "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " výsledky v online obsahu",
          local: " výsledky v místním obsahu",
          organization: "výsledky v organizacích",
          plugin_missing_info:
            'Místní obsah se zobrazí pouze pokud máte nainstalováno <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Zásuvný modul Tekla Warehouse Service" +
            "</a>" +
            ".",
          version_filter_info: "Filtrování verze softwaru není ve výsledcích místního obsahu platné.",
        },
        exclude_env_files: "Vyloučit soubory prostředí",
        sidebar: {
          filters: {
            measurementUnits: "Měrné jednotky",
            locations: "Umístění projektu",
            languages: "Jazyky",
          },
          placeholders: {
            use_categories: "Kategorie",
            measurement_units: "Měrné jednotky",
            languages: "Jazyky",
            locations: "Umístění",
          },
        },
      },
      placeholders: {
        name: "Název...",
        contentOwner: "Jméno vlastníka obsahu...",
        useCategory: "Vyberte použití",
        selectType: "Vyberte typ",
        filter: "Filtr...",
        search: "Hledání...",
        choose: "Vybrat...",
        searchFilters: "Hledat filtry...",
        selectContentItem: "Vybrat položku obsahu...",
        search_content: "Hledat v obsahu...",
        includeAs: "Zahrnout jako...",
        selectCollection: "Vybrat kolekci",
        selectUserGroup: "Vybrat skupinu uživatelů",
        online_collections_tooltip: "Kliknutím prohledáte online obsah.",
        local_collections_tooltip:
          "Klikněte pro prohledávání místního a síťového obsahu a obsahu v instalacích Tekla Structures",
        organizations_tooltip: "Klikněte pro prohledávání organizací",
        "filter-options": {
          products: "Všechny softwarové produkty",
          locations: "Všechna umístění",
          languages: "Všechny jazyky",
          measurementUnits: "Všechny měrné jednotky",
          testedVersions: "Všechny verze softwaru",
          useCategories: "Všechny kategorie",
          useCategoriesCombined: "Kategorie",
          allGroups: "Všechny skupiny",
          itemTypeCategories: "Všechny typy položek",
        },
        "exclude-filter-options": {
          products: "Vyloučit softwarové produkty",
          locations: "Vyloučit umístění",
          languages: "Vyloučit jazyky",
          measurementUnits: "Vyloučit měrné jednotky",
          testedVersions: "Vyloučit verze softwaru",
          useCategories: "Vyloučit kategorie",
          useCategoriesCombined: "Vyloučit kategorii",
          allGroups: "Vyloučit skupiny",
          itemTypeCategories: "Vyloučit typy položek",
        },
      },
      link: {
        link_selected: "Propojit vybrané",
        unlink_selected: "Odpojit vybrané",
      },
      download_install: {
        choose_version: "Vyberte verzi softwaru:",
        choose_content: "Vyberte položky obsahu ze seznamu níže:",
        choose_action: "Vyberte akci pro vybrané položky:",
      },
      download: {
        copy_to_clipboard: "Kopírovat do schránky",
        download_selected: "Stáhnout",
        add_selected_items_to_basket: "Přidat vybrané do košíku",
        add_item_to_basket: "Přidat vybrané do košíku",
        add_all_items_to_basket: "Přidat vše do košíku",
        add_all_items_to_basket_info: "Počet položek překračuje 100. Načítání může nějakou dobu trvat.",
        add_all_items_to_basket_too_many_results: "Nelze přidat více než 1000 položek.",
        cart_loading: "Počet položek překračuje 100. Načítání může nějakou dobu trvat.",
        remove_item_from_basket: "Odstranit z košíku",
        shopping_cart_no_content: "Košík je prázdný.",
        shopping_cart_no_content_for_version: "Pro vybranou verzi není v košíku žádný obsah",
        shopping_cart_items_func: function (itemsCount) {
          return "Můj košík (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Stažení instrukcí",
          step_one: "Pomocí tohoto tlačítka nakopírujete informace o stažení do schránky.",
          step_two_func: function (downloadAppUrl) {
            return (
              "Spusťte aplikaci Tekla Warehouse Downloader. Aplikaci si můžete stáhnout " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">zde.</a> '
            );
          },
          step_three: "Aplikace Tekla Warehouse Downloader vás provede zbývajícím postupem stahování.",
          show_data_title: "Nefungovalo to?",
          show_data_to_be_copied: "Nakopírovat informace ručně",
          hide_data_to_be_copied: "Skrýt údaje o stahování",
        },
      },
      install: {
        install_title: " položky budou nainstalovány pomocí zásuvného modulu Tekla Warehouse.",
        failed_install_information:
          "Vybrali jste obsah, který je k dispozici pouze ke stažení. Tento obsah nebude nyní instalován. Tento obsah stáhněte samostatně z Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Instalace: zkontrolujte, zda je zásuvný modul Tekla Warehouse Service správně spuštěn",
        verify_ts_connection: "Instalace: zkontrolujte, zda je spuštěn program Tekla Structures",
        select_software_version: "Filtrovat podle verze softwaru za účelem propojení, stažení nebo instalace",
        download_requires_maintenance: "Přístup k některým položkám níže vyžaduje službu Tekla Maintenance.",
        install_selected: "Nainstalovat",
        start_install: "Spustit instalaci",
        install_started: "Instalace byla zahájena",
        select_ts_version: "Vyberte verzi TS",
        install_retry: "Je-li třeba akci zopakovat, začněte znovu ze služby Tekla Warehouse.",
        failed_installs_info_func: function (failedCount) {
          return "Obsah, který nebude nainstalován (" + failedCount + " verze):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Instalace pro vybrané " + totalInstallsCount + " položky";
        },
      },
      helpers: {
        behalf: "Vytvořil",
        sortBy: "Seřadit podle:",
        filter: "Filtrovat výsledky",
        showAsThumbs: "Zobrazit jako miniatury",
        showAsList: "Zobrazit jako seznam",
        resultsPerPage: "Zobrazit na stránce:",
        filter_by_version: "Filtrovat podle verze:",
        show_more_options: "Více možností",
        show_less_options: "Méně možností",
        filter_by_type: "Filtrovat podle typu:",
        select_all: "Vybrat vše",
        show_content_items: "Zobrazit položky obsahu",
        choose_action: "Vyberte akci pro vybraný obsah: ",
        show_collections: "Zobrazit kolekce",
        search: {
          show: "Zobrazit:",
          show_content_items: "Položky obsahu",
          show_collections: "Kolekce",
          show_organizations: "Organizace",
          show_online_content: "Online obsah",
          show_offline_content: "Offline obsah",
          local_content_info:
            'Místní obsah se zobrazí pouze pokud máte nainstalováno <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " zásuvný modul nainstalován.",
        },
        tekla_maintenance_required: "Přístup k některým položkám níže vyžaduje službu Tekla Maintenance.",
        show_content_alerts: "Zobrazovat upozornění na obsah ",
        show_collection_alerts: "Zobrazovat upozornění na kolekce ",
      },
      actions: {
        load_more: "Načíst více",
        use_infitinite_scroll: "Použít nekonečný posuv",
        back: "Zpět",
        show_download_dialog: "Stáhnout",
        download: "Pouze stáhnout",
        download_on_partner_site: "Stažení na partnerském webu",
        insert: "Nainstalovat",
        install: "Nainstalovat",
        downloadAndInstall: "Nainstalovat",
        createNew: "Vytvořit nový",
        viewItems: "Pohled",
        viewCollection: "Zobrazit kolekci",
        viewGroup: "Zobrazit skupinu",
        joinGroup: "Připojit ke skupině",
        loginToDownload: "Přihlásit ke stažení",
        show3DPic: "Zobrazit 3D model",
        remove: "Odstranit",
        browse: "Procházet",
        browseFolder: "Procházet",
        browseFileSystem: "Procházet",
        add: "Přidat",
        save: "Uložit",
        batch_edit: "Upravit dávku",
        translations: "Překlady",
        cancel: "Zrušit",
        continue: "Pokračovat",
        delete: "Vymazat",
        addNetworkPath: "Přidat umístění v síti",
        select: "Vybrat",
        create: "Vytvořit",
        update: "Aktualizovat",
        choose_thumbnail: "Vyberte miniaturu",
        choose_picture: "Vybrat obrázek",
        remove_picture: "Odstranit obrázek",
        choose_3D_picture: "Vyberte 3D model",
        remove_3D_picture: "Odstraňte 3D model",
        like: "Líbí",
        unlike: "Nelíbí",
        close: "Zavřít",
        export: "Export",
        add_or_remove: "Přidat / Odebrat",
        select_content_to_convert: "Vybrat obsah offline",
        search: "Hledat",
        upload: "Nahrát",
        edit: "Editovat",
        view_3d: "Kliknutím zobrazte model...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "beta",
        search: "Hledat",
        profile: "Moje podrobnosti",
        myWarehouse: "Můj Warehouse",
        myStuff: "Moje věci",
        logout: "Odhlásit",
        login: "Přihlásit se",
        register: "Zaregistrovat",
        upload: "Nahrát",
        uploading: "Nahrát",
        dashboard: "Panel",
        learnMore: "Další informace",
        about: "O aplikaci",
        shoppingCart: "Můj košík",
        addToCartInfo:
          "Můžete si shromáždit položky do košíku a stáhnout nebo nainstalovat je všechny najednou. Stahování je zcela zdarma.",
      },
      cubeNavigation: {
        headings: {
          get_started: "ZAČNĚTE",
          learn: "VÝUKA",
          get_help: "ZÍSKAT NÁPOVĚDU",
          extend_your_tekla: "ROZŠIŘTE SI PROGRAM TEKLA",
          collaborate: "SPOLUPRÁCE",
          students_and_educators: "Students and educators",
          about: "O APLIKACI",
        },
        "tekla-online": "Služby online",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Web Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Extranet Tekla",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Můj profil",
        discussionForum: "Diskusní fórum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "DOMOVSKÁ STRÁNKA SLUŽEB TEKLA",
        register: "Zaregistrovat",
        login: "Přihlásit",
        teklaAbout: "Tekla",
        evaluate: "Vyhodnotit",
        buy: "Koupit",
        download: "Stáhnout",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Produkty",
        teklaServices: "Služby",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Moje kolekce",
        myLikes: "Moje „to se mi líbí“",
        profile: "Moje podrobnosti",
        preferences: "Moje předvolby",
        myUserGroups: "Moje skupiny uživatelů",
        localCollections: "Lokální a síťové kolekce",
        tsCollections: "Kolekce Tekla Structures",
        downloads: "Stáhnout",
        selectContent: "Vybrat soubory obsahu",
        addMetadata: "Přidat metadata",
        onlineCollections: "Online kolekce",
        userGroups: "Skupiny uživatelů",
        createNew: "Přidat obsah",
        notifications: "Oznámení",
        contentUsage: "Využití obsahu",
        browseCollections: "Procházet kolekce",
        admin: "Správce",
        sales: "Prodeje",
        shoppingCart: "Můj košík",
      },
      notifications: {
        title: "oznámení",
        invitedBy: "Na pozvání",
        invitationType: "Typ pozvání",
        invitedTo: "Pozvání k",
        createdAt: "Vytvořeno v",
        action: "Akce",
        acceptInvitation: "Přijmout",
      },
      batch_edit: {
        selectActionType: "Vybrat typ akce",
        edit_content: "Upravit metadata obsahu",
        content_title: "Obsah",
        metadataToChange: "Metadata ke změnění",
        selectContent: "Pro obsah",
        select_action: "Vybrat akci",
        support: {
          support: "Podpora",
          licenses: "Licence",
        },
        versions: {
          version: "Verze",
          prerequisities: "Předpoklady a požadavky",
        },
        content: {
          select_collection: "Vybrat kolekci",
          show_collection: "Zobrazit:",
          show_all_content: "Všechny kolekce",
          show_organization_content: "Kolekce mé organizace",
          select_all: "Vybrat vše",
          select_all_info: "(Může to chvíli trvat)",
          actionOptions: {
            add: "Přidat",
            remove: "Odebrat",
            replace: "Nahradit s",
            yes: "Ano",
            no: "ne",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Žádné vlastnosti nebyly vybrány",
            invalid_attributes: "Neplatné hodnoty vlastností",
            invalid_actions: "Neplatné akce",
            content_not_selected: "Nebyl vybrán žádný obsah",
            entities_not_selected: "Data balíčku jsou k dispozici, avšak žádné balíčky nebyly vybrány",
            versions_not_selected: "Data verze jsou k dispozici, avšak žádné verze nebyly vybrány",
          },
        },
        update: {
          info_processing: "Ponechte toto okno otevřené, dokud nebudou všechny položky zpracovány.",
          info_processed: "Všechny položky byly zpracovány.",
          processing: "Zpracování položky",
          processed: "Zpracovaná položka",
          error_info: "Došlo k potížím při aktualizaci následujících položek:",
          package_failure_func: function (package_title, errorMsg) {
            return "Obsah: " + package_title + ", chyba: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Obsah: " + package_title + ", verze: " + version_title + ", chyba: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " položky budou aktualizovány, chcete pokračovat?";
          },
        },
      },
      myUserGroups: {
        title: "Skupiny uživatelů",
        createdByPrefix: "Podle:",
      },
      downloads: {
        title: "Stáhnout",
      },
      myCollections: {
        new_collection: "Nová kolekce",
        my_collections: "Moje kolekce",
        created_by_me: "Vytvořeno mnou",
        local_collections: "Lokální a síťové kolekce",
        online_collections: "Online kolekce",
        created_by_my_organization: "Vytvořeno mou organizací",
        created_by_my_organizations: "Vytvořeno mými organizacemi",
        shared_with_me: "Sdíleno se mnou",
        shared_with_me_or_organization: "Sdíleno se mnou nebo s mou organizací",
        notification: {
          error: "Chyba",
          error_loading_online_collections: "Chyba při načítání online kolekcí",
          error_loading_packages_liked_by_me: "Chyba při načítání obsahu, který se mi líbí",
          error_loading_my_organization_s_collections: "Chyba při načítání kolekcí vlastní organizace",
          error_loading_my_shared_collections: "Chyba při načítání mých sdílených kolekcí",
        },
        local: {
          helpText1: "Pokud potřebujete přistupovat k lokálním kolekcím, nainstalujte si prosím ",
          helpText2: "Zásuvný modul Tekla Warehouse Service",
          helpText3: ".",
          title: "Lokální a síťové kolekce",
          notFound: "Dosud nebyly nalezeny žádné lokální kolekce.",
          myCollectionsTitle: "Moje kolekce",
          actions: {
            createNew: "Vytvořit lokální nebo síťovou kolekci",
          },
        },
      },
      organization: {
        news_feed: "Webový zdroj",
        add_comment: "Přidat text zdroje",
        edit_comment: "Upravit Webový zdroj",
        subTabs: {
          collections: "Kolekce",
          analytics: "Analýzy",
          contact_and_support: "Kontakt",
          news_feed: "Webový zdroj",
        },
        edit: {
          edit_title: "Upravit název",
          edit_description: "Upravit popis",
          edit_contact: "Upravit kontaktní údaje",
          edit_logo: "Upravit logo",
          edit_url: "Upravit adresu URL",
          edit_email: "Upravit e-mail",
          edit_phonenumber: "Upravit telefonní číslo",
          edit_warehouse_url: "Upravit adresu URL systému Warehouse",
          edit_warehouse_url_subtitle: "Písmena, žádná čísla ani speciální znaky",
          edit_url_subtitle: "Zadejte úplnou URL adresu",
          edit_logo_descriptive: "Optimální velikost obrázku je přibližně 350 × 200 bodů",
          edit_facebook_url: "Upravit URL adresu Facebooku",
          edit_twitter_url: "Upravit URL adresu Twitteru",
          edit_linkedin_url: "Upravit URL adresu LinkedIn",
          edit_youtube_url: "Upravit URL adresu Youtube",
          edit_googleplus_url: "Upravit URL adresu Google+",
          logo_updated: "Logo bylo aktualizováno",
          logo_update_failed: "Aktualizace loga se nezdařila",
          logo_remove_failed: "Odstranění loga se nezdařilo",
          logo_removed: "Logo bylo úspěšně odstraněno",
          updated_successfully: "Úspěšně aktualizováno",
          updated_failed: "Aktualizace se nezdařila",
          add_comment: "Přidat nový",
          edit_comment: "Upravit Webový zdroj",
          comment_added: "Webový zdroj byl úspěšně přidán",
          comment_updated: "Webový zdroj byl úspěšně aktualizován",
          comment_deleted: "Webový zdroj byl úspěšně odstraněn",
          comment_add_failed: "Nepodařilo se přidat webový zdroj",
          comment_update_failed: "Nepodařilo se aktualizovat webový zdroj",
          comment_delete_failed: "Nepodařilo se odstranit webový zdroj",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Prohledat online obsah podle " + name + " &rsaquo;";
        },
        contact_information: "Kontaktujte nás",
        website: "Webové stránky &rsaquo;",
        show_newer_news: "Novější",
        show_older_news: "Starší",
      },
      online_collections: {
        new_collection: "Nová kolekce",
        online_collections: "Online kolekce",
        created_by_organizations: "Vytvořeno organizacemi",
        created_by_users: "Vytvořeno uživateli",
      },
      local_collections: {
        tab_title: "Obsah",
        local_collections: "Lokální kolekce",
      },
      versions: {
        add_tool: "Přidat aplikaci",
        table_header: {
          name: "Název",
          type: "Typ",
          platform: "Platforma",
          modified_at: "Změněno",
          size: "Velikost",
          virus_scan: "Antivirová kontrola",
        },
        // placeholders
        select_file_type: "Vyberte typ souboru",
        select_platform: "Vyberte platformu",
        select_unit: "Vyberte jednotku",
        select_quality: "Vyberte kvalitu",
        what_will_be_added: "Co chcete přidat?",
        select_files_from_your_disk: "Vyberte soubory z vašeho disku",
        add: "Přidat",
        //content management
        add_application: "Přidat aplikaci",
        select_content_from_tekla_structures: "Vybrat obsah z programu Tekla Structures",
        or: "nebo",
        files: "Soubory",
        //version metadata
        version_information: "Informace o verzi",
        version_display_name: "Zobrazovaný název verze",
        version_number: "Číslo verze",
        version_number_info: "Zadejte číslo verze. Po uložení nelze změnit číslo verze. ",
        existing_versions: "Existující čísla verzí tohoto obsahu",
        version_number_validation: {
          missing: "Musíte zadat číslo verze",
          invalid:
            "Číslo verze musí odpovídat pravidlům sémantického verzování (MAJOR.MINOR.PATCH)",
          already_in_use: "Toto číslo verze se již pro tento obsah používá.",
        },
        select_update_type: "Chcete-li vygenerovat číslo verze, nejprve vyberte typ aktualizace",
        please_enter_a_version_name_or_number: "Zadejte název nebo číslo verze.",
        virus_scan: {
          soft_failed: "Neúspěšný",
          hard_failed: "Nekontrolován",
          infected: "Nakažen",
        },
      },
      collections: {
        by: "Podle:",
        edit: {
          edit_collection: "Upravit kolekci",
          edit_description: "Upravit popis",
        },
        notification: {
          collection_updated: "Kolekce byla aktualizována.",
          error: "Chyba",
          error_loading_other_users_collections: "Chyba při načítání kolekcí ostatních uživatelů",
          error_loading_other_organizations_collections: "Chyba při načítání kolekcí jiných organizací",
          error_loading_collection_contents: "Chyba při načítání obsahu kolekce",
          members_list_updated: "Seznam členů aktualizován!",
          invitations_removed: "Pozvánky byly odebrány!",
          collection_removed: "Kolekce byla odebrána.",
          thumbnail_deleted: "Miniatura byla smazána.",
          wrong_file_type: "Chybný typ souboru.",
          invalid_file_type: "Neplatný typ souboru.",
          selected_image_is_too_big: "Vybraný obrázek je příliš velký.",
          thumbnail_updated: "Miniatura byla aktualizována.",
          default_thumbnail_updated: "Výchozí miniatura byla aktualizována.",
          default_thumbnail_update_failed: "Aktualizace výchozí miniatury se nezdařila.",
          thumbnail_added: "Miniatura byla přidána.",
          thumbnail_update_failed: "Aktualizace miniatury se nezdařila.",
          video_added: "Video bylo přidáno.",
          video_adding_failed: "Přidání videa se nezdařilo.",
          video_deleted: "Video bylo odstraněno.",
          video_deleting_failed: "Odstranění videa se nezdařilo.",
        },
        confirm: {
          delete_all: function (title) {
            return 'Odstranit kolekci a celý její obsah  "' + title + '"?';
          },
          remove_members: "Jste si jistí?",
          remove_invitations: "Jste si jistí?",
        },
        useNetworkDrive: "Použít síťovou jednotku nebo složku",
        createCollection: "Vytvořit online kolekci",
        createCollectionInstructions: "Vytvořte kolekci, která vám umožní obsah seskupit.",

        actions: {
          delete_thumbnail: "Smazat miniaturu",
          edit_collection: "Upravit kolekci",
          delete_collection: "Smazat kolekci",
          add_content: "Přidat obsah",
        },
        subTabs: {
          content: "Obsah",
          members: "Zobrazit členy",
          analytics: "Analýzy",
          manage_access: "Spravovat přístup",
        },

        members: {
          add_user_groups: "Přidat skupiny uživatelů",
          select_user_group: "Vybrat skupinu uživatelů",
          with_role: "s funkcí",
          user_groups_added: "Skupiny uživatelů přidány!",
          members_list_updated: "Seznam členů aktualizován!",
          update_selected_members: "Aktualizovat vybrané členy",
          table_header: {
            member_type: "Typ člena",
            member_name: "Jméno člena",
            email: "E-mail",
            role: "Funkce",
            joined_at: "Čas připojení",
            invited_at: "Čas pozvání",
          },
          new_role: "Nová funkce",
        },
        member_list: {
          users_who_can_access_this_collection: "Uživatelé, kteří mají přístup k této kolekci",
          add_user_group: "Přidat skupinu uživatelů",
          update_members: "Aktualizovat členy",
          remove_members: "Odebrat členy",
          pending_invitations: "Čekající pozvánky",
          user: "uživatel",
          invite_member: "Pozvat člena",
          update_invitations: "Aktualizovat pozvánky",
          remove_invitations: "Odebrat pozvánky",
        },
        labels: {
          select_organization: "Vybrat organizaci",
          select_visibility: "Vybrat viditelnost",
          title: "Název",
          name: "Název",
          createAs: "Vytvořit jako: ",
          asMyself: "Moje osoba",
          asUserGroup: "Skupina uživatelů",
          asOrganization: "Organizace",
          selectOwner: "Vybrat organizaci pro vlastníka obsahu",
          visibility: "Viditelnost",
          visibilityOptions: {
            public: "Veřejný",
            private: "Soukromý",
          },
          description: "Popis",
        },
        new_collection: {
          please_input_a_username: "Zadejte uživatelské jméno.",
          title_is_required_to_be_at_least_3_characters: "Název musí obsahovat minimálně 3 znaky.",
          title_cannot_be_longer_than_20_characters: "Název nesmí být delší než 20 znaků.",
        },
        collection_list: {
          no_content: "Žádné kolekce.",
          results_summary: function (number) {
            return "" + number + "  kolekce " + pluralize(number, ["položky", "položka", "položky"]);
          },
        },
        entity_list: {
          no_content: "V kolekci není žádný obsah.",
          results_summary: function (number) {
            return "" + number + " obsah " + pluralize(number, ["položky", "položka", "položky"]);
          },
          linked_content: "Propojený obsah",
          unlink: "Zrušit propojení obsahu",
        },
      },
      alerts: {
        no_updates: "V poslední době neaktualizováno",
        notifications_marked_as_read: "Oznámení označeno jako přečtené",
        cleared_alerts: "Všechna oznámení smazána",
        alert_by_email:
          "Pošlete mi e-mail, když bude jakýkoli níže uvedený obsah aktualizován na novou verzi, případně když bude aktualizován obsah kolekce",
        action_types: {
          create: "byl vytvořen",
          update: "byl upraven",
          delete: "byl odebrán",
          added_entity: "verze byla přidána",
          removed_entity: "verze byla odebrána",
          added_collection: "obsah byl přidán",
          removed_collection: "obsah byl odebrán",
        },
        property_names: {
          description: "Detaily",
          searchCriteria: "Informace o kritériích vyhledávání",
          groupCategory: "Informace o skupině a kategorii",
          support: "Informace o podpoře",
          licenses: "Informace o licencích",
          relatedContent: "Informace o souvisejícím obsahu",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Aktualizované " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Předvolby oznámení úspěšně aktualizovány",
          preferences_update_failed: "Došlo k chybě při aktualizaci předvoleb oznámení",
        },
      },
      browseDialog: {
        useNetworkDrive: "Použít síťovou jednotku nebo složku",
        ownerGroups: {
          title: "Skupiny vlastníků",
        },
      },
      itemTypeCategories: {
        all: "Všechny skupiny",
        application: "Aplikace",
        "3dProduct": "3D produkty",
        customComponent: "Uživatelské komponenty",
        tool: "Aplikace",
        profile: "Profily",
        material: "Materiály",
        bolt: "Šrouby",
        rebar: "Pruty",
        reportTemplate: "Předloha výpisu",
        drawingSetup: "Soubory nastavení výkresu",
        modelSetup: "Soubory nastavení modelu",
        environmentFile: "Soubory prostředí",
        shape: "Tvary",
        //'shapeCatalog': 'Katalog tvarů',
        //'foreignGeometryDefinition': 'Cizí definice geometrie'
      },
      platforms: {
        WIN_X86: "32-bit",
        WIN_X64: "64-bit",
        ANY: "Nezávislá na platformě",
      },
      platformsDownload: {
        WIN_X86: "32-bit",
        WIN_X64: "64-bit",
        ANY: " ",
      },
      contentItemSource: {
        other: "Obecné - do složky modelu",
        run: "Spustitelné",
        bolt: "Šroubová sestava",
        component: "Komponent",
        drawing: "Nastavení výkresu (BETA)",
        material: "Materiál",
        profile: "Profil",
        mesh: "Armovací síť",
        rebar: "Armovací prut",
        shape: "Tvar",
        geometry: "Geometrie",
        modeltemplate: "Předloha modelu",
        cloningtemplate: "Šablona pro klonování",
        noaction: "Žádná akce",
        releasenotes: "Poznámky k verzi",
      },
      contentItemHeader: {
        bolt: "Šroubové sestavy",
        component: "Komponenty",
        drawing: "Nastavení výkresu (BETA)",
        material: "Materiály",
        profile: "Profily",
        mesh: "Armovací sítě",
        rebar: "Armovací pruty",
        shape: "Tvary",
      },
      useCategoryGroups: {
        workflow: "Průběh práce",
        structures: "Konstrukce",
        drawings: "Výkresy",
        modeling: "Modelování",
      },
      useCategories: {
        title: "Použití pro/v",
        conceptualDesign: "Koncepční návrh",
        designEngineeringAnalysis: "Návrh / projektování / analýza",
        detailing: "Detailování",
        fabrication: "Výroba",
        erectionInstallationPour: "Stavba / instalace / lití",
        contracting: "Smlouvy",
        scheduling: "Plánování",
        takeOffEstimation: "Zahájení / odhad",
        concreteStructures: "Monolitický beton",
        precastConcrete: "Prefabrikovaný beton",
        offshore: "Těžební plošiny",
        steelStructures: "Ocelové konstrukce",
        timberStructures: "Dřevěné konstrukce",
        reinforcement: "Armatura",
        drawingCreation: "Generování výkresu",
        drawingManagement: "Správa výkresu",
        drawingEditing: "Úprava výkresu",
        drawingAnnotation: "Popis výkresu",
        viewSimulateNavigate: "Zobrazení, simulace, navigace",
        compareReviewManageData: "Srovnání, kontrola, správa dat",
        softwareCollaboration: "Softwarová spolupráce",
        productivity: "Produktivita",
        interoperability: "Interoperabilita",
        systemSetup: "Nastavení systému",
      },
      measurementUnits: {
        "0": "Metrické jednotky",
        "1": "Imperiální jednotky",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Otevřený BIM (IFC)",
        standalone: "Samostatné",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures15',
        //'TS1': 'Tekla Structures16',
        //'TS2': 'Tekla Structures17',
        NVS: "Nezávislé na verzi",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight1',
        //'TBS1': 'Tekla BIMsight1.5',
        //'TBS2': 'Tekla BIMsight2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "Experimentální",
        candidate: "Kandidát",
        approved: "Schváleno",
        standard: "Standardní",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "angličtina",
        "zh-Hans": "čínština (zjednodušená)",
        "zh-Hant": "čínština (tradiční)",
        cs: "čeština",
        da: "dánština",
        nl: "nizozemština",
        fi: "finština",
        fr: "francouzština",
        de: "němčina",
        hu: "maďarština",
        it: "italština",
        ja: "japonština",
        ko: "korejština",
        pl: "polština",
        pt: "portugalština",
        "pt-br": "portugalština (brazilská)",
        ru: "ruština",
        es: "španělština",
        sv: "švédština",
        no: "norština",
        other: "jiný",
      },
      isoLangs: {
        ab: {
          name: "abcházština",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "afarština",
          nativeName: "Afaraf",
        },
        af: {
          name: "afrikánština",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "akanština",
          nativeName: "Akan",
        },
        sq: {
          name: "albánština",
          nativeName: "Shqip",
        },
        am: {
          name: "amharština",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "arabština",
          nativeName: "العربية",
        },
        an: {
          name: "aragonština",
          nativeName: "Aragonés",
        },
        hy: {
          name: "arménština",
          nativeName: "Հայերեն",
        },
        as: {
          name: "ásámština",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "avarština",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "avestánština",
          nativeName: "avesta",
        },
        ay: {
          name: "ajmarština",
          nativeName: "aymar aru",
        },
        az: {
          name: "ázerbájdžánština",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "bambarština",
          nativeName: "bamanankan",
        },
        ba: {
          name: "baškirština",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "baskičtina",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "běloruština",
          nativeName: "Беларуская",
        },
        bn: {
          name: "bengálština",
          nativeName: "বাংলা",
        },
        bh: {
          name: "bihárština",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "bislamština",
          nativeName: "Bislama",
        },
        bs: {
          name: "bosenština",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "bretonština",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "bulharština",
          nativeName: "български език",
        },
        my: {
          name: "barmština",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "katalánština (valencijština)",
          nativeName: "Català",
        },
        ch: {
          name: "chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "čečenština",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "chichewa (chewa, nyanja)",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "čínština (zjednodušená a tradiční)",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "čínština (zjednodušená)",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "čínština (tradiční)",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "čínština (tchajwanská)",
          nativeName: "國語",
        },
        cv: {
          name: "čuvaština",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "kornština",
          nativeName: "Kernewek",
        },
        co: {
          name: "korsičtina",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "kríjština",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "chorvatština",
          nativeName: "hrvatski",
        },
        cs: {
          name: "čeština",
          nativeName: "česky, čeština",
        },
        da: {
          name: "dánština",
          nativeName: "dansk",
        },
        dv: {
          name: "maledivština (dhivehi)",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "nizozemština",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "angličtina",
          nativeName: "English",
        },
        eo: {
          name: "esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "estonština",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "eveština (ewe)",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "faerština",
          nativeName: "føroyskt",
        },
        fj: {
          name: "fidžijština",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "finština",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "francouzština",
          nativeName: "Français",
        },
        ff: {
          name: "fula (fulah, pulaar, pular)",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "galicijština",
          nativeName: "Galego",
        },
        ka: {
          name: "gruzínština",
          nativeName: "ქართული",
        },
        de: {
          name: "němčina",
          nativeName: "Deutsch",
        },
        el: {
          name: "řečtina (moderní)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "gudžarátština",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "haitština (haitská kreolština)",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "hebrejština (moderní)",
          nativeName: "עברית",
        },
        hz: {
          name: "herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "hindština",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "hiri motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "maďarština",
          nativeName: "Magyar",
        },
        ia: {
          name: "interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "indonéština",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "interlingue",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "irština",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "ido",
          nativeName: "Ido",
        },
        is: {
          name: "islandština",
          nativeName: "Íslenska",
        },
        it: {
          name: "italština",
          nativeName: "Italiano",
        },
        iu: {
          name: "inuktitutština",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "japonština",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "javánština",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "grónština (kalaallisut)",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "kannadština",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "kašmírština",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "kazaština",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "khmerština",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "kikujština (gikuyu)",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "rwandština (kinyarwanda)",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "kyrgyzština",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "konžština",
          nativeName: "KiKongo",
        },
        ko: {
          name: "korejština",
          nativeName: "한국어",
        },
        ku: {
          name: "kurdština",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "latina",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "lucemburština",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "lugandština",
          nativeName: "Luganda",
        },
        li: {
          name: "limburština",
          nativeName: "Limburgs",
        },
        ln: {
          name: "ngalština (lingala)",
          nativeName: "Lingála",
        },
        lo: {
          name: "laoština",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "litevština",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "luba-katanga",
          nativeName: "",
        },
        lv: {
          name: "lotyština",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "manština",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "makedonština",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "malagaština",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "malajština",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "malajálamština",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "maltština",
          nativeName: "Malti",
        },
        mi: {
          name: "maorština",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "maráthština",
          nativeName: "मराठी",
        },
        mh: {
          name: "maršalština",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "mongolština",
          nativeName: "монгол",
        },
        na: {
          name: "nauruština",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "navajo (navaho)",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "norština (bokmål)",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "severní ndebelština",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "nepálština",
          nativeName: "नेपाली",
        },
        ng: {
          name: "ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "norština (nynorsk)",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "norština",
          nativeName: "Norsk",
        },
        ii: {
          name: "nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "jižní ndebelština",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "okcitánština",
          nativeName: "Occitan",
        },
        oj: {
          name: "ojibwe (ojibwa)",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "staroslověnština (stará církevní slovanština)",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "urijština",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "osetština",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "paňdžábština",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "páli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "perština",
          nativeName: "فارسی",
        },
        pl: {
          name: "polština",
          nativeName: "polski",
        },
        ps: {
          name: "paštština (paštó)",
          nativeName: "پښتو",
        },
        pt: {
          name: "portugalština",
          nativeName: "Português",
        },
        "pt-br": {
          name: "portugalština (Brazílie)",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "kečuánština",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "rétorománština",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "rumunština, moldavština",
          nativeName: "română",
        },
        ru: {
          name: "ruština",
          nativeName: "русский",
        },
        sa: {
          name: "sanskrt",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "sardinština",
          nativeName: "sardu",
        },
        sd: {
          name: "sindhština",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "severní sámština",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "samojština",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "srbština",
          nativeName: "српски језик",
        },
        gd: {
          name: "skotská gaelština",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "šonština",
          nativeName: "chiShona",
        },
        si: {
          name: "sinhalština",
          nativeName: "සිංහල",
        },
        sk: {
          name: "slovenština",
          nativeName: "slovenčina",
        },
        sl: {
          name: "slovinština",
          nativeName: "slovenščina",
        },
        so: {
          name: "somálština",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "sesotho (jižní sotho)",
          nativeName: "Sesotho",
        },
        es: {
          name: "španělština", //      'name':'španělština (tradiční)',
          nativeName: "español",
        },
        su: {
          name: "sundština",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "svahilština",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "svazijština",
          nativeName: "SiSwati",
        },
        sv: {
          name: "švédština",
          nativeName: "svenska",
        },
        ta: {
          name: "tamilština",
          nativeName: "தமிழ்",
        },
        te: {
          name: "telugština",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "tádžičtina",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "thajština",
          nativeName: "ไทย",
        },
        ti: {
          name: "tigriňština",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "tibetština (standardní, centrální)",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "turkmenština",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "tagalog",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "tonga (souostroví Tonga)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "turečtina",
          nativeName: "Türkçe",
        },
        ts: {
          name: "tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "tatarština",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "twi",
          nativeName: "Twi",
        },
        ty: {
          name: "tahitština",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "ujgurština",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "ukrajinština",
          nativeName: "українська",
        },
        ur: {
          name: "urdština",
          nativeName: "اردو",
        },
        uz: {
          name: "uzbečtina",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "vietnamština",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "valonština",
          nativeName: "Walon",
        },
        cy: {
          name: "velština",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "wolofština",
          nativeName: "Wollof",
        },
        fy: {
          name: "západofríština",
          nativeName: "Frysk",
        },
        xh: {
          name: "xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "jidiš",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "joruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "čuangština",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "jiný",
          nativeName: "Jiný",
        },
      },

      details: {
        navigation: {
          content_information: "Informace o obsahu",
          version_and_file_management: "Správa verzí a souborů",
          details: "Detaily",
          versions: "Verze",
          analytics: "Analýzy",
          statistics: "Statistika",
          manage_access: "Spravovat přístup",
          related_content: "Související",
          properties: "Vlastnosti",
          support: "Společnost a podpora",
          release_notes: "Poznámky k nové verzi",
          comments: "Komentáře uživatele",
        },
        related_content: {
          other_content_title: "Další obsah, který by se vám mohl líbit",
          related_content_title: "Obsah, který doporučuje ",
          related_content_url: "Adresa URL obsahu",
          add_related_content: "Přidat související obsah",
          add_related_content_info:
            "Můžete pouze přidávat související online obsah k online obsahu a lokální obsah k lokálnímu obsahu",
          remove_related_content: "Odebrat",
          relatedContentUrlSubtitle: "ID obsahu nebo celá URL adresa obsahu",
          updated_successfully: "Související obsah byl úspěšně aktualizován",
          update_failed: "Chyba při aktualizaci souvisejícího obsahu",
          update_failed_invalid_id: "Zadané ID obsahu je neplatné",
        },
        actions: {
          move: "Posunout",
          delete: "Vymazat",
          add_comment: "Přidat poznámku",
          add_reply: "Přidat odpověď",
          edit_comment: "Editovat poznámku",
          reply_comment: "Přidat odpověď",
          view_replies: "Zobrazit odpovědi",
          report_bad_comment: "Nahlásit nevhodný komentář&rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Upravit nadpis nebo odstranit",
            title_or_move_delete: "Upravit nadpis, přesunout nebo odstranit",
            thumbnail: "Upravit miniaturu",
            description: "Upravit popis",
            groupandcategory: "Upravit skupinu a kategorii",
            details: "Upravit detaily",
            versions: "Upravit verze",
            support: "Upravit informace o podpoře",
            release_notes: "Upravit poznámky k nové verzi",
            search_criteria: "Upravit kritéria hledání",
            batchedit_and_translations:
              "Pomocí funkce Překlad můžete publikovat obsah ve více jazycích. Přeložená verze obsahu se bude zobrazovat uživatelům podle jejich výběru jazyka uživatelského rozhraní systému Warehouse. Díky úpravám dávky můžete upravovat více položek najednou. Nejprve vyberte pole a potom položky, které chcete upravovat.",
          },
        },
        links: {
          link: "Propojit",
          select_collection_placeholder: "Vyberte kolekci k propojení",
          linking_collections_label: "Kolekce propojení s tímto",
          private_content_note: "Poznámka: Soukromý obsah bude viditelný pouze uživatelům, kteří k němu mají přístup.",
          share_on_social_media: "Sdílet na:",
          report_bad_content: "Nahlásit nevhodný obsah &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Nahlásit nezákonný obsah &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Objednáním odběru upozornění vyjadřujete souhlas se sdílením zobrazovaného názvu vašeho účtu Tekla Account a názvu organizace (je-li k dispozici) s vlastníkem obsahu.",
          alert_me: "Zasílat upozornění",
          unsubscribe: "Odhlásit odebírání",
          subscribed_successfully: "Přihlášení proběhlo úspěšně",
          unsubscribed_successfully: "Odhlášení proběhlo úspěšně",
          subscribers_label: "Odběratelé",
        },
        reviews: {
          reviewers_label: "Recenzenti",
        },
        comments: {
          replies: "Odpovědi",
        },
        scan: {
          soft_failed_binaries_info: "Antivirová kontrola se nezdařila  ",
          hard_failed_binaries_info: "Antivirovou kontrolu nebylo možno provést ",
          infected_binaries_info: "Antivirová kontrola nalezla virus ",
          what_now: "Co nyní?",
          popup: {
            soft_failed_binaries_title: "Neúspěšné binární soubory",
            hard_failed_binaries_title: "Neúspěšné binární soubory",
            infected_binaries_title: "Zavirované binární soubory",
            soft_failed_binaries_info:
              "Antivirová kontrola nahraných souborů se nezdařila. Jedná se o přechodnou situaci. Odeberte prosím soubory a odešlete je znovu. Všechny soubory musí projít antivirovou kontrolou, než budou viditelné ostatním uživatelům.",
            hard_failed_binaries_info:
              "Antivirovou kontrolu nebylo možno provést. Odeberte prosím tyto soubory. Všechny soubory musí projít antivirovou kontrolou, než budou viditelné ostatním uživatelům.",
            infected_binaries_info:
              "Podle antivirové kontroly jsou vámi nahrané soubory zavirované. Odeberte prosím tyto soubory. Všechny soubory musí projít antivirovou kontrolou, než budou viditelné ostatním uživatelům.",
            version: "Verze: ",
            fileName: "název souboru: ",
          },
        },
        status: {
          content_banned_info:
            "Obsah je blokován administrátorem. V současné době není k dispozici ostatním uživatelům kromě vlastníka obsahu. <a href='https://support.tekla.com/contact-us' target='_blank'>Kontaktujte podporu</a> pro více informací.",
          content_archived_info:
            "Tento obsah byl archivován. Pokud se uživatelé bez práv k úpravám pokusí navštívit obsah prostřednictvím přímého odkazu, budou přesměrováni na titulní stránku.",
          archived: "Archivováno",
        },
        modified_by: function (name) {
          return " podle " + name;
        },
        created_by: function (name) {
          return "Vytvořeno uživatelem " + name;
        },
        deleteEntity: function (title) {
          return "Vymazat " + title + "?";
        },
        userComments: "Komentáře uživatele",
        comment_added: "Komentář byl úspěšně přidán",
        comment_updated: "Komentář byl úspěšně aktualizován",
        comment_deleted: "Komentář byl úspěšně odstraněn",
        comment_add_failed: "Nepodařilo se přidat komentář",
        comment_update_failed: "Nepodařilo se aktualizovat komentář",
        comment_delete_failed: "Nepodařilo se odstranit komentář",
        comment_delete_confirmation: "Odstranit položku komentáře?",
        name: "Název",
        details: "Detaily",
        contentOwner: "Tvůrce",
        contentOwnerGroup: "Skupina vlastníka obsahu",
        contentGroup: "Skupina obsahu",
        thumbnail: "Miniatura",
        tooltip: "Popisek",
        description: "Popis",
        additional_information: "Dodatečná informace",
        images: "Obrázky produktu",
        installationInfo: "Informace o instalaci",
        productCode: "Kód produktu",
        copyrightInfo: "Copyright",
        productExpirationDate: "Datum vypršení platnosti produktu",
        helpUrl: "Webové stránky &rsaquo;",
        supportUrl: "Podpora &rsaquo;",
        specialTermsUrl: "SMLOUVA EULA &rsaquo;",
        releaseNotesUrl: "Poznámky k verzi",
        supportForumUrl: "Diskusní fórum &rsaquo;",
        itemTypeCategories: "Skupina",
        useCategories: "Kategorie",
        category: "Kategorie:",
        group: "Skupina:",
        compatibleSoftwareProducts: "Kompatibilní softwarové produkty",
        compatibleOperatingSystems: "Kompatibilní operační systémy",
        locationRestrictions: "Dostupnost produktu:",
        supportedLanguages: "Jazyky:",
        measurementUnits: "Měrné jednotky:",
        tags: "Značky a klíčová slova:",
        prerequisites: "Vyžadované součásti:",
        releaseNotes: "Poznámky k verzi:",
        versions: "Verze",
        testedVersions: "Kompatibilní verze",
        qualityTag: "Kvalita - hodnoceno vlastníkem",
        otherAttributes: "Další atributy",
        labels: "Parametry vyhledávání",
        supportInformation: "Podpora",
        updateInformation: "Aktualizace",
        latestUpdate: "Poslední aktualizace",
        totalDownloads: "Počet stažení",
        licenses: "Licence",
        maintenanceRequired: "Stažení vyžaduje službu Tekla Maintenance",
        tekla_maintenance_content: "Obsah služby Tekla Maintenance",
        download_requires_tekla_maintenance: "Stažení vyžaduje službu Tekla Maintenance",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Úspěšně aktualizováno",
        update_failed: "Aktualizace selhala",
        update_failed_invalid_tag: "Aktualizace se nezdařila: neplatná značka",
        update_failed_details_max_length_exceeded: "Aktualizace se nezdařila: detaily jsou příliš dlouhé",
        show_version_details: "Více informací",
        content_removed: "Obsah byl odstraněn.",
        no_versions_available:
          "K dispozici není žádná verze. Zobrazení aktualizace může chvíli trvat. Pro zobrazení změn obnovte stránku v prohlížeči.",
        thumbnail_deleted: "Miniatura byla smazána.",
        thumbnail_updated: "Miniatura byla aktualizována.",
        verify_service_plugin_is_running: "Zkontrolujte, zda je zásuvný modul Tekla Warehouse Service správně spuštěn",
        verify_ts_connection: "Zkontrolujte, zda je spuštěn program Tekla Structures",
        download_tos: {
          title: "Kvalita, podpora a soukromí",
          content_1: "Ujistěte se, že stažený obsah vyhovuje vašim kvalitativním požadavkům. Společnost Trimble neposkytuje podporu pro žádný obsah třetích stran a nenese za něj zodpovědnost. O podporu žádejte přímo poskytovatele obsahu.",
          content_2: "Název vaší země a organizace mohou být publikovány ve službě a zveřejněny pro vlastníky obsahu pro legitimní účely, jako je možnost upozornit vás na změny obsahu. Z odběru těchto oznámení se můžete odhlásit.",
          content_3: 'Pro rozšíření obsahu Trimble platí <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">podmínky Trimble</a>. Na obsah třetích stran se mohou vztahovat licenční podmínky třetích stran. Viz <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Oznámení o ochraně osobních údajů Trimble</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Toto je bezplatné úložiště služby Tekla Structures. Vyhledávejte, importujte, instalujte a sdílejte interně i globálně. Vytvářejte vysoce kvalitní modely efektivně.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse je bezplatná online a lokální knihovna pro modelovací nástroje, inteligentní prvky a šablony Tekla Structures",
        },
        search: {
          title: "Prohledat Tekla Warehouse",
          description:
            "Vyhledávejte rozšíření Tekla, 3D díly, tvary, uživatelské komponenty, profily, materiály, například ocel a beton různé jakosti, pruty, sítě a šablony",
        },
        about: {
          title: "Informace o službě Tekla Warehouse",
          description:
            "Tekla Warehouse nabízí bezplatný marketingový kanál pro vaše produkty a nabízí vám možnost úplné kontroly nad obsahem.",
          developers: {
            title: "Název výchozího vývojáře",
            description: "Popis výchozího vývojáře",
          },
          usage: {
            title: "Název výchozího použití",
            description: "Popis výchozího použití",
          },
          getting_started: {
            title: "Název výchozího úvodu",
            description: "Popis výchozího úvodu",
          },
          content: {
            title: "Název výchozího obsahu",
            description: "Popis výchozího obsahu",
          },
          using_content: {
            title: "Název výchozího použití obsahu",
            description: "Popis výchozího použití obsahu",
          },
          creating_content: {
            title: "Název výchozího vytváření obsahu",
            description: "Popis výchozího vytváření obsahu",
          },
        },
        collection: {
          title: "Název výchozí kolekce",
          description: "Popis výchozí kolekce",
        },
        my_warehouse: {
          title: "Moje Tekla Warehouse",
          description: "Spravujte svůj obsah služby Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Zrušit",
          previous: "&lsaquo; Předchozí",
          next: "Další &rsaquo;",
        },
        content: {
          add_content: "Přidat soubory",
        },
        collection: {
          heading: "Vybrat nebo vytvořit kolekci",
          subHeading:
            "Kolekce slouží k seskupení obsahu. Online kolekce můžete použít k publikaci obsahu a lokální kolekce ke sdílení obsahu s kolegy. Lokální kolekce můžete také použít k soukromým účelům.",
          loading: "Načítání kolekcí...",
          selectFromMyCollections: "Vybrat ze složky Moje kolekce",
          description_of_the_collection: "Popis",
          title_validation: {
            missing: "Prosím zadejte název.",
            too_short: function (length) {
              return "Nadpis by měl obsahovat minimálně " + length + " znaků.";
            },
            too_long: function (length) {
              return "Nadpis nesmí být delší než " + length + " znaků.";
            },
          },
        },
        package: {
          basic_information: "Základní informace",
          code_name: "Kódový název",
          code_name_info:
            "Zadejte kódový název jako jedinečný identifikátor tohoto obsahu. Kódový název může obsahovat malá písmena, čísla a tyto speciální znaky: _ - @. Po uložení nelze kódový název změnit.",
          code_name_validation: {
            missing: "Musíte zadat kódový název",
            wrong_format: "Kódový název může obsahovat malá písmena, čísla a tyto speciální znaky: _ - @",
            not_unique: "Tento kódový název se již používá. Musíte zadat jedinečný kódový název.",
          },
          search_criteria: "Parametry vyhledávání",
          support: "Podpora",
          title: "Nadpis",
          thumbnail: "Miniatura",
          "3D_pic": "3D model",
          title_validation: {
            missing: "Prosím zadejte název.",
            too_short: function (length) {
              return "Nadpis by měl obsahovat minimálně " + length + " znaků.";
            },
          },
          description_validation: {
            missing: "Zadejte prosím popis.",
            too_short: function (length) {
              return "Popis by měl obsahovat minimálně " + length + " znaků.";
            },
          },
          wrong_file_type: "Chybný typ souboru.",
          selected_image_is_too_big: "Vybraný obrázek je příliš velký.",
        },
        version: {
          select_version: "Vyberte verzi",
          select_quality: "Vyberte kvalitu",
          versions: "Verze",
          displayNameNew: "Zobrazovaný název verze",
          copy_from_version: "Kopírovat metadata ze stávající verze (volitelné)",
          displayName: "Zobrazovaný název",
          content_information: "Informace o obsahu",
          back_to_content_information: "&lsaquo; Zpět na informace o obsahu",
          version_and_file_management: "Správa verzí a souborů",
          there_s_no_versions_yet: "Zatím nejsou k dispozici žádné verze.",
          create_a_version_to_add_files: "Chcete-li přidat soubory, vytvořte verzi.",
          add_version: "Přidat verzi",
          version_information: "Informace o verzi",
          delete_version: "Smazat verzi",
          no_versions_available: "K dispozici není žádná verze. Zobrazení aktualizace může chvíli trvat.",
          update_start_title_html: '<div class="spinner spinner-white">Aktualizace</div>',
          update_start_message: "Operace může chvíli trvat.",
          version_was_created: "Verze byla vytvořena.",
          version_was_created_with_delay: "Verze byla vytvořena. Změny se mohou projevit během několika minut.",
          version_was_updated: "Verze byla aktualizována.",
          version_was_deleted: "Verze byla smazána.",
          version_was_deleted_with_delay: "Verze byla smazána. Změny se mohou projevit během několika minut.",
          confirm_delete: function (title) {
            return 'Odstranit verzi "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Balíček byl nahrán.",
          couldn_t_create_a_package: "Nelze vytvořit balíček. Změny budou vráceny zpět.",
          uploading_title_html: '<div class="spinner spinner-white">Probíhá nahrávání</div>',
          uploading_text: "Operace může chvíli trvat.",
          select_organization: "Vybrat organizaci",
          collection_visibility: "Viditelnost kolekce:",
          actions: {
            create: "Vytvořit",
            publish: "Publikovat",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Tento obsah bude viditelný pro všechny uživatele, kteří mají přístup na lokální nebo síťový disk.",
          this_content_will_be_visible_only_to_you: "Tento obsah uvidíte pouze vy.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Tento obsah bude viditelný pouze pro vás a všechny ve vaší organizaci.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Po publikaci bude tento obsah viditelný pro všechny.",
          public_content_disclaimer:
            "Zodpovídáte za veškerý obsah, který nahrajete. Ujistěte se, že váš obsah není škodlivý, že máte právo k jeho nahrání a že neporušuje žádná práva duševního vlastnictví. Souhlasíte s tím, že ve službě uvedete jakékoli podmínky, které se mohou případně vztahovat k použití nahraného obsahu.",
        },
        metadata: {
          title: "Metadata",
          subtitle: "Základní údaje",
          deleteThumbnail: "Smazat miniaturu",
          buttonCreate: "Vytvořit",
        },
        basicdata: {
          title: "Přidat obsah",
          clickButtonToSelectFile: "Soubor vyberete kliknutím na tlačítko.",
          thumbnail: "Miniatura",
          dropImageHere: "Sem přetáhněte obrázek.",
        },
        "3dGeometry": {
          title: "Vytvořit katalog 3D produktů",
          buttonAddEntity: "Přidat 3D produkt",
          buttonAdd: "Přidat vlastní atribut",
          errorAttributeAlreadyExists: "Atribut s tímto názvem již existuje.",
          buttonCreatePackage: "Vytvořit balíček",
          buttonCreatePackages: "Vytvořit balíčky",
          addCustomAttributePlaceholder: "Název vlastnosti",
        },
        please_log_in_fragments: {
          please: "Prosím ",
          log_in: "Přihlásit se",
          or: " nebo ",
          register: "se zaregistrujte,",
          to_upload_online_content: " abyste mohli nahrát obsah.",
        },
        header: {
          upload_header: "Nahrát",
          collection: "Kolekce",
          content: "Obsah",
          version: "Verze",
          finish: "Konec",
        },
        contentOwner: "Tvůrce",
        contentOwnerGroup: "Publikovat obsah",
        select_action: "Vybrat akci",
        tooltip: "Popisek",
        description: "Popis",
        copyright: "Autorská práva",
        tags: "Značky a klíčová slova",
        tagsSubtitle:
          "K oddělování používejte čárku, například: klíčová slova 1, klíčová slova 2. Pokud klíčové slovo obsahuje více slov, použijte mezi slovy podtržítka (_), nejsou povoleny mezery mezi slovy. Nejsou povoleny žádné speciální znaky kromě podtržítka a čárky.",
        images: "Obrázky",
        installationInfo: "Informace o instalaci",
        productCode: "Kód výrobku",
        copyrightInfo: "Informace o autorských právech",
        Details: "Detaily",
        specialTermsUrl: "Smlouva EULA",
        productExpirationDate: "Datum vypršení platnosti produktu",
        helpUrl: "Webové stránky",
        helpUrlSubtitle: "vložit úplnou URL adresu",
        detailsSubtitle:
          "V této fázi můžete do pole Detaily přidat pouze neformátovaný text. Po vytvoření obsahu můžete pole Detaily upravit tak, aby obsahovalo formátování html.",
        specialTermsUrlSubtitle:
          "Vlastník má možnost přidávat další podmínky pro svůj vlastní obsah. Vlastník musí poskytnout adresu URL, kde jsou speciální podmínky umístěny. Tyto podmínky nemají přednost před podmínkami poskytování služeb společnosti Trimble a neruší je.",
        releaseNotes: "Soubor poznámek k verzi",
        releaseNoteFileSubTitle: "Přidat poznámky k verzi jako soubor",
        supportUrl: "Nápověda a podpora",
        supportUrlSubtitle: "zadejte úplnou URL adresu nebo předponu „mailto:“ pro zaslání e-mailu",
        supportForumUrl: "Diskuzní fórum (webové stránky)",
        supportForumUrlSubtitle: "vložit úplnou URL adresu",
        measurementUnits: "Měrné jednotky",
        itemTypeCategories: "Skupina",
        useCategories: "Kategorie",
        compatibleSoftwareProducts: "Kompatibilní softwarové produkty",
        testedVersions: "Kompatibilní verze",
        compatibleOperatingSystems: "Kompatibilní operační systém",
        locationRestrictions: "Dostupnost produktu",
        supportedLanguages: "Jazyky",
        select_releasenotes_file: "Vybrat soubor",
        qualityTag: "Kvalita - hodnoceno vlastníkem",
        requireMaintenance: "Stažení tohoto souboru vyžaduje aktivní servisní smlouvu Tekla.",
        contentOwnerGroupAsMyself: "Jako já",
        contentOwnerGroupAsUserGroup: "Jako skupina uživatelů",
        selectUserGroupForContentOwner: "Vyberte skupinu uživatelů pro vlastníka obsahu",
        defaultUploadTemplate: "Výchozí balíček",
        templateFor3DGeometryFiles: "3D geom. balíček/balíčky",
        title: "Nadpis",
        details: "Detaily",
        selectContentOwner: "Vyberte vlastníka obsahu",
        selectContentType: "Typ",
        errorWrongfiletype: "Chybný typ souboru.",
        errorImageTooBig: "Vybraný obrázek je příliš velký.",
        errorBlankTitle: "Nadpis nemůže být prázdný.",
        commonLabelsTitle: "Společné popisky pro všechny balíčky",
      },
      profile: {
        content_usage: "Využití obsahu",
        profile_updated: "Profil aktualizován",
        invitation_accepted: function (target) {
          return "Pozvání přijato! Nyní máte přístup k " + target + ".";
        },
        user_groups: {
          edit_user_group: "Upravit skupinu uživatelů",
          user_group_updated: "Skupina uživatelů byla aktualizována.",
          create_a_new_user_group: "Vytvořit novou skupinu uživatelů",
          name_of_the_user_group: "Název skupiny uživatelů",
          description: "Popis",
          group_visibility: "Viditelnost skupiny",
          remove: "„Jste si jisti?“",
          remove_invitations: "„Jste si jisti?“",
          remove_members: "„Jste si jisti?“",
          invitations_removed: "Pozvánky byly odebrány!",
          members_list_updated: "Seznam členů aktualizován!",
          user_group_created: "Skupina uživatelů vytvořena",
        },
        members: {
          update_selected_members: "Aktualizovat vybrané členy",
          new_role: "Nová funkce",
          members_list_updated: "Seznam členů aktualizován!",
          edit_user_group: "Upravit skupinu uživatelů",
          delete_user_group: "Odebrat skupinu uživatelů",
          filter: "Filtr",
          user_group_members: "Členové skupiny uživatelů",
          update_members: "Aktualizovat členy",
          remove_members: "Odebrat členy",
          pending_invitations: "Čekající pozvánky",
          invite_member: "Pozvat člena",
          update_invitations: "Aktualizovat pozvánky",
          remove_invitations: "Odebrat pozvánky",
        },
        subTabs: {
          alerts: "Moje výstrahy",
          collections: "Kolekce",
          likes: "Líbí",
        },
        admin: {
          grant_sales_admin: "Přidělit uživateli funkci správce pro prodej",
          grant_analyst_role: "Přidělit uživateli funkci analytika",
          role_granted: "Funkce přidělena",
          role_removed: "Funkce odebrána",
          offline_to_online: "Převést offline obsah na online",
          use_elastic_search: "Použít elastické vyhledávání",
        },
        sales: {
          title: "Prodeje",
        },
        subtitlePreferences: "Předvolby vyhledávání",
        preferenceInfo:
          'Tyto filtry slouží pro doporučení obsahu na <a href="#/landing">domovské stránce</a> Tekla Warehouse.',
        clearFilters: "Vymazat filtry &rsaquo;",
        admin_role: "Správce",
        sales_role: "Prodeje",
        admin_mode: "Režim správce:",
        firstName: "Křestní jméno",
        lastName: "Příjmení",
        displayName: "Zobrazené jméno:",
        organization: "Název organizace:",
        email: "E-mail:",
        teklaId: "ID Tekla",
        maintenanceAccess: "Přístup k obsahu služby Tekla Maintenance:",
        myLocations: "Umístění projektu",
        measurementUnits: "Jednotky",
        useCategories: "Použít",
        itemTypeCategories: "Zajímám se o následující typy",
        languages: "Jazyky",
        operatingSystems: "Operační systémy",
        software: "Softwarové produkty",
        user_id: "ID uživatele:",
        organization_id: "ID organizace:",
        edit_in_atc: "Upravit můj profil v účtu Tekla Account",
        no_likes: "Žádná líbí",
        no_alerts: "Žádné přihlášené odběry",
        clear_alerts: "Vymazat oznámení",
        alerts_info_html:
          "Obdržíte výstrahu, jakmile dojde k jakékoli následující změně<br>" +
          "<b>Obsah:</b> Došlo ke změně nadpisu, popisu, parametrů vyhledávání nebo metadat položky obsahu, byla přidána nová verze obsahu nebo byla verze vymazána.<br>" +
          "<b>Kolekce:</b> Název nebo popis je upraven, obsah je přidán nebo odebrán.",
      },
      uploader: {
        placeholders: {
          productCode: "Kód výrobku",
          title: "Název",
          description: "Popis",
          productWeight: "Hmotnost produktu",
          weightUnit: "Jednotka hmotnosti",
          material: "Materiál",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Katalog 3D produktů",
          addToolButton: "Aplikace",
          select_files: "Soubory z disku",
          select_files_from_ts: "Soubory z Tekla Structures",
          partnerDownloadLink: "Partnerský odkaz ke stažení",
        },
        headings: {
          geometryFile: "Soubor geometrie",
          thumbnailFile: "Bitmapa miniatury (.bmp)",
          title: "Název",
          description: "Popis",
          productCode: "Kód výrobku",
          productWeight: "Hmotnost produktu",
          weightUnit: "Jednotka hmotnosti",
          material: "Materiál",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Globální",
          AF: "Afghánistán",
          AX: "Ålandy",
          AL: "Albánie",
          DZ: "Alžírsko",
          AS: "Americká Samoa",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarktida",
          AG: "Antigua a Barbuda",
          AR: "Argentina",
          AM: "Arménie",
          AW: "Aruba",
          AU: "Austrálie",
          AT: "Rakousko",
          AZ: "Ázerbájdžán",
          BS: "Bahamy",
          BH: "Bahrajn",
          BD: "Bangladéš",
          BB: "Barbados",
          BY: "Bělorusko",
          BE: "Belgie",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermudy",
          BT: "Bhútán",
          BO: "Bolívie",
          BQ: "Bonaire",
          BA: "Bosna a Hercegovina",
          BW: "Botswana",
          BV: "Bouvetův ostrov",
          BR: "Brazílie",
          IO: "Britské indickooceánské území",
          BN: "Brunej (Sultanát Brunej)",
          BG: "Bulharsko",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Kambodža",
          CM: "Kamerun",
          CA: "Kanada",
          CV: "Kapverdy",
          KY: "Kajmanské ostrovy",
          CF: "Středoafrická republika",
          TD: "Čad",
          CL: "Chile",
          CN: "Čína",
          CX: "Vánoční ostrov",
          CC: "Kokosové ostrovy (Keelingovy)",
          CO: "Kolumbie",
          KM: "Komory",
          CG: "Kongo (Konžská republika)",
          CD: "Kongo (Demokratická republika Kongo)",
          CK: "Cookovy ostrovy",
          CR: "Kostarika",
          CI: "Pobřeží slonoviny",
          HR: "Chorvatsko",
          CU: "Kuba",
          CW: "Curaçao",
          CY: "Kypr",
          CZ: "Česká republika",
          DK: "Dánsko",
          DJ: "Džibutsko",
          DM: "Dominika",
          DO: "Dominikánská republika",
          EC: "Ekvádor",
          EG: "Egypt",
          SV: "Salvador",
          GQ: "Rovníková Guinea",
          ER: "Eritrea",
          EE: "Estonsko",
          ET: "Etiopie",
          FK: "Falklandy (Malvíny)",
          FO: "Faerské ostrovy",
          FJ: "Fidži",
          FI: "Finsko",
          FR: "Francie",
          GF: "Francouzská Guyana",
          PF: "Francouzská Polynésie",
          TF: "Francouzská jižní území",
          GA: "Gabon",
          GM: "Gambie",
          GE: "Gruzie",
          DE: "Německo",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Řecko",
          GL: "Grónsko",
          GD: "Grenada",
          GP: "Guadeloupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guinea",
          GW: "Guinea-Bissau",
          GY: "Guyana",
          HT: "Haiti",
          HM: "Heardův ostrov a McDonaldovy ostrovy",
          VA: "Vatikán",
          HN: "Honduras",
          HK: "Hongkong",
          HU: "Maďarsko",
          IS: "Island",
          IN: "Indie",
          ID: "Indonésie",
          IR: "Írán",
          IQ: "Irák",
          IE: "Irsko",
          IM: "Ostrov Man",
          IL: "Izrael",
          IT: "Itálie",
          JM: "Jamajka",
          JP: "Japonsko",
          JE: "Jersey",
          JO: "Jordánsko",
          KZ: "Kazachstán",
          KE: "Keňa",
          KI: "Kiribati",
          KW: "Kuvajt",
          KG: "Kyrgyzstán",
          LA: "Laos",
          LV: "Lotyšsko",
          LB: "Libanon",
          LS: "Lesotho",
          LR: "Libérie",
          LY: "Libye",
          LI: "Lichtenštejnsko",
          LT: "Litva",
          LU: "Lucembursko",
          MO: "Macao",
          MK: "Makedonie",
          MG: "Madagaskar",
          MW: "Malawi",
          MY: "Malajsie",
          MV: "Maledivy",
          ML: "Mali",
          MT: "Malta",
          MH: "Marshallovy ostrovy",
          MQ: "Martinik",
          MR: "Mauritánie",
          MU: "Mauricius",
          YT: "Mayotte",
          MX: "Mexiko",
          FM: "Mikronésie",
          MD: "Moldavsko",
          MC: "Monako",
          MN: "Mongolsko",
          ME: "Černá Hora",
          MS: "Montserrat",
          MA: "Maroko",
          MZ: "Mosambik",
          MM: "Myanmar (Barma)",
          NA: "Namibie",
          NR: "Nauru",
          NP: "Nepál",
          NL: "Nizozemsko",
          NC: "Nová Kaledonie",
          NZ: "Nový Zéland",
          NI: "Nikaragua",
          NE: "Niger",
          NG: "Nigérie",
          NU: "Niue",
          NF: "Norfolk (ostrov)",
          KP: "Korejská lidově demokratická republika",
          MP: "Severní Mariany",
          NO: "Norsko",
          OM: "Omán",
          PK: "Pákistán",
          PW: "Palau",
          PS: "Palestinské území",
          PA: "Panama",
          PG: "Papua-Nová Guinea",
          PY: "Paraguay",
          PE: "Peru",
          PH: "Filipíny",
          PN: "Pitcairn",
          PL: "Polsko",
          PT: "Portugalsko",
          PR: "Portoriko",
          QA: "Katar",
          RE: "Réunion",
          RO: "Rumunsko",
          RU: "Ruská federace",
          RW: "Rwanda",
          BL: "Svatý Bartoloměj",
          SH: "Svatá Helena",
          KN: "Svatý Kryštof a Nevis",
          LC: "Svatá Lucie",
          MF: "Svatý Martin",
          PM: "Saint-Pierre a Miquelon",
          VC: "Svatý Vincenc a Grenadiny",
          WS: "Samoa",
          SM: "San Marino",
          ST: "Svatý Tomáš a Princův ostrov",
          SA: "Saúdská Arábie",
          SN: "Senegal",
          RS: "Srbsko",
          SC: "Seychely",
          SL: "Sierra Leone",
          SG: "Singapur",
          SX: "Svatý Martin (nizozemská část)",
          SK: "Slovensko",
          SI: "Slovinsko",
          SB: "Šalamounovy ostrovy",
          SO: "Somálsko",
          ZA: "Jižní Afrika",
          GS: "Jižní Georgie",
          KR: "Jižní Korea",
          SS: "Jižní Súdán",
          ES: "Španělsko",
          LK: "Šrí Lanka",
          SD: "Súdán",
          SR: "Surinam",
          SJ: "Špicberky a Jan Mayen",
          SZ: "Svazijsko",
          SE: "Švédsko",
          CH: "Švýcarsko",
          SY: "Sýrie (Syrská arabská republika)",
          TW: "Tchaj-wan",
          TJ: "Tádžikistán",
          TZ: "Tanzanie",
          TH: "Thajsko",
          TL: "Východní Timor",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad a Tobago",
          TN: "Tunisko",
          TR: "Turecko",
          TM: "Turkmenistán",
          TC: "Turks a Caicos",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ukrajina",
          AE: "Spojené arabské emiráty",
          GB: "Spojené království",
          US: "Spojené státy americké",
          UM: "Menší odlehlé ostrovy Spojených států amerických",
          UY: "Uruguay",
          UZ: "Uzbekistán",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietnam",
          VG: "Panenské ostrovy (britské)",
          VI: "Panenské ostrovy (americké)",
          WF: "Wallis a Futuna",
          EH: "Západní Sahara",
          YE: "Jemen",
          ZM: "Zambie",
          ZW: "Zimbabwe",
        },
        sortOptions: {
          "-modifyTime": "Naposledy aktualizováno",
          "-modifiedAt": "Naposledy aktualizováno",
          title: "Abecedně A-Z",
          "-title": "Abecedně Z-A",
          "-reviewCount": "Nejoblíbenější",
          name: "Název",
          role: "Funkce",
          email: "E-mail",
          "-relevance": "Relevance",
          "-createdAt": "Poslední vytvořené",
          displayName: "Abecedně A-Z",
          "-displayName": "Abecedně Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Dnes",
          yesterday: "Včera",
          this_week: "Tento týden",
          previous_week: "Předchozí týden",
          this_month: "Tento měsíc",
          previous_month: "Předchozí měsíc",
          this_year: "Letos",
          previous_year: "Loni",
          beginning_of_time: "Začátek času",
          custom: "Uživatelský časový rámec",
        },
        eventTypeOptions: {
          downloads: "Stažení",
          views: "Zhlédnutí",
          all_events: "Všechny události",
        },
      },
    });
  },
};
