import * as React from "react";
import { observer } from "mobx-react";
import { RootContext, RootStore } from "../stores/rootStore";
import { useContext } from "react";
import { ITabListItem } from "../models/dataModel";
import { TabNavigationStore, TabNavigationStoreContext } from "../components/tab-navigation/tabNavigationStore";
import { TabNavigation } from "../components/tab-navigation/TabNavigation";
import { Analytics } from "../components/analytics/Analytics";
import { AnalyticsStore, AnalyticsStoreContext } from "../components/analytics/analyticsStore";
import { NewsFeed } from "./NewsFeed";
import { ContactAndSupport } from "./ContactAndSupport";
import { OrganizationCollections } from "./OrganizationCollections";
import { OrganizationPageStore, OrganizationPageStoreContext } from "./organizationPageStore";

/**
 * A component that displays tabs.
 */
export const Tabs = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const tabNavigationStore: TabNavigationStore = useContext(TabNavigationStoreContext);
  const organizationPageStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const organization = organizationPageStore.getOrganization();

  function canSeeAnalytics() {
    return !!organization && rootStore.getUserStore().canSeeAnalytics(organization);
  }

  function getTabList(): ITabListItem[] {
    return [
      {
        name: "collections",
        translationKey: "organization.subTabs.collections",
        isDefault: true,
        canAccess: true,
      },
      {
        name: "analytics",
        translationKey: "organization.subTabs.analytics",
        canAccess: canSeeAnalytics(),
      },
      {
        name: "contact",
        translationKey: "organization.subTabs.contact_and_support",
        canAccess: true,
      },
      {
        name: "newsFeed",
        translationKey: "organization.subTabs.news_feed",
        canAccess: true,
      },
    ];
  }

  function shouldDisplayTabContents(tabName: string) {
    return tabNavigationStore.getActiveTabName() === tabName;
  }

  function getNaviStyl(): string {
    let naviStyle = "tabs organization ";
    if (organizationPageStore.canEditOrganization()) {
      naviStyle = naviStyle + "caneditorg";
    }
    return naviStyle;
  }

  return (
    <div className="details-tabs-container">
      <TabNavigation tabList={getTabList()} navStyle={getNaviStyl()} />
      <div className="tab-view wide">
        {shouldDisplayTabContents("collections") && <OrganizationCollections />}
        {shouldDisplayTabContents("analytics") && (
          <AnalyticsStoreContext.Provider value={new AnalyticsStore(rootStore, organization)}>
            <Analytics />
          </AnalyticsStoreContext.Provider>
        )}
        {shouldDisplayTabContents("contact") && <ContactAndSupport />}
        {shouldDisplayTabContents("newsFeed") && <NewsFeed />}
      </div>
    </div>
  );
});
