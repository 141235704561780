import * as React from "react";
import { Fragment, useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import { IAclUser } from "../../models/dataModel";
import { AclEditorStore, AclEditorStoreContext, IInputError } from "./aclEditorStore";

/**
 * Displays list of candidates
 */
export const CandidatesList = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const aclEditorStore: AclEditorStore = useContext(AclEditorStoreContext);

  async function addViewer(entry: IAclUser, keyword: string) {
    await aclEditorStore.addViewer(keyword, entry, true);
  }

  return (
    <ul className="users-list" data-testid="candidatesList">
      {_.map(aclEditorStore.getCandidates(), (candidate: [string, IAclUser[]], i: number) => {
        const keyword = candidate[0];
        const entries = candidate[1];
        return (
          <div data-testid={`candidates-for-${keyword}`} key={i}>
            <p>{rootStore.getTranslation("shared.access_rights.candidates_for_keyword", keyword)}</p>
            {entries.map((entry: IAclUser, j: number) => (
              <li data-testid={`candidate-item-${entry.id}`} key={j} className="candidates">
                <span
                  className={classNames("icon blue", {
                    "icon-organization": aclEditorStore.isOrganization(entry),
                    "icon-user": !aclEditorStore.isOrganization(entry),
                  })}
                />
                {!aclEditorStore.isOrganization(entry) ? (
                  <span data-testid="user-entry" className="blue">
                    {entry.displayName}
                  </span>
                ) : (
                  <a data-testid="organization-entry">{entry.displayName}</a>
                )}
                <a
                  data-testid={`add-entry-${entry.id}`}
                  className="icon icon-plus small blue"
                  onClick={() => addViewer(entry, keyword)}
                  title={rootStore.getTranslation("actions.add")}
                >
                  {}
                </a>
              </li>
            ))}
          </div>
        );
      })}
    </ul>
  );
});

/**
 * Displays list of viewers
 */
export const ViewersList = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const aclEditorStore: AclEditorStore = useContext(AclEditorStoreContext);

  return (
    <ul className="users-list" data-testid="viewersList">
      {aclEditorStore.getViewers().map((entry: IAclUser, index: number) => {
        return (
          <li data-testid={`list-item-${entry.id}`} key={index}>
            <span
              className={classNames("icon blue", {
                "icon-organization": aclEditorStore.isOrganization(entry),
                "icon-user": !aclEditorStore.isOrganization(entry),
              })}
            />
            {!aclEditorStore.isOrganization(entry) ? (
              <span data-testid="user-entry" className="blue">
                {entry.displayName}
              </span>
            ) : (
              <a data-testid="organization-entry">{entry.displayName}</a>
            )}
            <a
              data-testid="remove-entry"
              className="icon icon-close small blue"
              onClick={() => aclEditorStore.removeViewer(entry)}
              title={rootStore.getTranslation("actions.remove")}
            ></a>
          </li>
        );
      })}
    </ul>
  );
});

/**
 * Displays spinner
 */
const Spinner = observer(() => {
  return (
    <div className="loading-indicator" data-testid="loading-acl-data">
      <div className="spinner"></div>
    </div>
  );
});

const InputInvalidMessage = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const aclEditorStore: AclEditorStore = useContext(AclEditorStoreContext);

  return (
    <div className="error input-invalid" data-testid="input-invalid-message">
      <ul>
        {!!aclEditorStore.getInputErrors() &&
          _.map(aclEditorStore.getInputErrors()!, (value: IInputError, i: number) => {
            return (
              <li className="error" key={i}>
                {value.message}
              </li>
            );
          })}
        {aclEditorStore.inputTooLong() && (
          <li className="error">{rootStore.getTranslation("shared.access_rights.input_too_long")}</li>
        )}
      </ul>
    </div>
  );
});

/**
 * Displays acl editor features.
 */
export const AclEditor = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const aclEditorStore: AclEditorStore = useContext(AclEditorStoreContext);
  const placeholder = aclEditorStore.isAnalystUsersContainerCollection()
    ? rootStore.getTranslation("profile.admin.grant_analyst_role")
    : rootStore.getTranslation("shared.access_rights.write_id_placeholder");

  const [textareaHeight, setTextareaHeight] = React.useState<number>();
  const [checkingValidity, setCheckingValidity] = React.useState<boolean>(false);
  const inputIsValid = !checkingValidity && aclEditorStore.isInputValid();

  function storeInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCheckingValidity(true);
    aclEditorStore.storeInput(e.target.value);
    setTimeout(async () => {
      await aclEditorStore.checkInputValidity();
      setCheckingValidity(false);
    }, 1200);
  }

  function submit(evt) {
    evt.preventDefault();

    aclEditorStore.addToViewers();
  }

  function calcHeight(value): number {
    const numberOfLineBreaks = (value.match(/\n/g) || []).length;
    // min-height + lines x line-height + padding + border
    const newHeight = 22 + numberOfLineBreaks * 22 + 16 + 4;
    return newHeight;
  }

  const textarea: HTMLTextAreaElement | null = document.querySelector(".acl-editor-textarea");

  if (!!textarea && !_.isNull(textarea)) {
    textarea.addEventListener("keyup", () => {
      setTextareaHeight(calcHeight(textarea!.value));
    });
  }

  return (
    <div className="acl-editor">
      <form style={{ display: "flex" }}>
        <div className="input-and-message">
          <textarea
            style={{ height: textareaHeight + "px", overflow: "hidden" }}
            data-testid="viewer-input"
            className="acl-editor-textarea"
            placeholder={placeholder}
            value={aclEditorStore.getInput()}
            onChange={storeInput}
          ></textarea>
          {!aclEditorStore.isInputValid() && <InputInvalidMessage />}
        </div>
        {aclEditorStore.isLoading() ? (
          <Spinner />
        ) : (
          <button
            style={{ placeSelf: "center", margin: "0.25em", marginLeft: "0.5em" }}
            data-testid="add-viewer"
            className="button-primary"
            disabled={!inputIsValid}
            onClick={submit}
          >
            {rootStore.getTranslation("actions.add")}
          </button>
        )}
      </form>

      {aclEditorStore.wasDataFetched() && !aclEditorStore.isLoading() && (
        <Fragment>
          <CandidatesList />
          <ViewersList />
        </Fragment>
      )}
    </div>
  );
});
