import * as React from "react";
import { observer } from "mobx-react";
import { useContext, useState } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { DialogContext, DialogStore } from "../dialogStore";
import { ICommentData, UserCommentsStoreContext } from "../../package/tabs/userCommentsStore";
import { OrganizationPageStore, OrganizationPageStoreContext } from "../../organizations/organizationPageStore";
import { ReplyDialogStore, ReplyDialogStoreContext } from "./ReplyDialogStore";

import { IEntity, IOrganization, IComment } from "../../models/dataModel";
import { ObjectTypeEnum } from "../../models/enums";
import { removeStylesFromText } from "../../utils/functions";

import { RichTextEditor } from "../../components/RichTextEditor";

/**
 * Renders the "Add comment" dialog used on package & organization pages.
 */
export const AddCommentDialog = observer(
  ({ subject, parentComment }: { subject: IEntity | IOrganization; parentComment?: IComment }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);
    const replyStore: ReplyDialogStore = useContext(ReplyDialogStoreContext);

    const isReply = !!parentComment;
    const isOrganization = subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION;
    const store = isOrganization ? useContext(OrganizationPageStoreContext) : useContext(UserCommentsStoreContext);

    const [commentText, setCommentText] = useState<string>("");

    function handleInput(input: string) {
      setCommentText(input);
    }

    function cancel() {
      if (isReply && replyStore && parentComment!.replies!.length > 0) {
        replyStore.setDialogState("viewReplies");
      } else {
        dialog.close();
      }
    }

    async function addComment() {
      const cleaned = removeStylesFromText(commentText);
      let commentData: ICommentData;

      if (isReply) {
        commentData = { text: cleaned, parentCommentId: parentComment!.id };
      } else {
        commentData = { text: cleaned };
      }

      const creator = isOrganization
        ? (store as OrganizationPageStore).getEditor()
        : rootStore.getUserStore().getCurrentUser();

      await store.addComment(commentData, creator);
      dialog.close();
    }

    return (
      <article data-testid="addCommentDialog">
        <header>
          <h2 className="l-center-align" data-testid="addCommentHeader">
            {isOrganization
              ? rootStore.getTranslation("organization.add_comment")
              : rootStore.getTranslation("details.actions.add_comment")}
          </h2>
        </header>
        <section className="not-scrollable">
          <RichTextEditor field="addComment" commentSubject={subject} value={commentText} onChange={handleInput} />
        </section>
        <div className="actions">
          <button onClick={cancel} data-testid="cancelAddComment">
            {rootStore.getTranslation("actions.cancel")}
          </button>
          <button
            className="button-primary"
            disabled={!commentText}
            onClick={addComment}
            data-testid="addCommentButton"
          >
            {rootStore.getTranslation("actions.save")}
          </button>
        </div>
      </article>
    );
  },
);
