import { action, computed, makeObservable } from "mobx";
import { SearchPageListStyleEnum, SearchPageOrderEnum } from "../models/enums";
import { AbstractJSONCookieStore } from "./abstractJSONCookieStore";

/**
 * A store that can be used to store search page settings.
 */
export class SearchPageSettingsStore extends AbstractJSONCookieStore {
  public static readonly COOKIE_NAME = "SEARCH_PAGE_VIEW_STATE";

  constructor() {
    super();
    makeObservable(this);
  }

  /**
   * Returns result list order
   * @returns result list order
   */
  @computed
  public get resultListOrder(): SearchPageOrderEnum {
    return this.parseCookie().sortBy;
  }

  /**
   * Returns list style
   * @returns list style
   */
  @computed
  public get resultListStyle(): SearchPageListStyleEnum {
    return this.parseCookie().resultListStyle || SearchPageListStyleEnum.THUMBNAILS;
  }

  /**
   * Returns cookie name where data is stored.
   * @returns cookie name.
   */
  public getCookieName(): string {
    return SearchPageSettingsStore.COOKIE_NAME;
  }

  /**
   * Sets result list order
   * @param order being SearchPageOrderEnum object
   */
  @action
  public setResultListOrder(order: SearchPageOrderEnum) {
    const jsonData = this.parseCookie();
    jsonData.sortBy = order;
    this.storeInCookie(jsonData);
  }

  /**
   * Sets list style
   * @param listStyle being SearchPageListStyleEnum object
   */
  @action
  public setResultListStyle(listStyle: SearchPageListStyleEnum) {
    const jsonData = this.parseCookie();
    jsonData.resultListStyle = listStyle;
    this.storeInCookie(jsonData);
  }
}
