import _ from "underscore";

import { LocalVersionService } from "../services/LocalVersionService";
import { LocalRequestBuilder } from "../LocalRequestBuilder";
import { LocalResponseProcessor } from "../LocalResponseProcessor";
import { NewCollectionConverter } from "../converters/NewCollectionConverter";
import { PackageConverter } from "../converters/PackageConverter";

function search(data, dontUnwrap) {
  return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/search", data), false, dontUnwrap);
}

export const LocalSearchService = {
  searchCollections: function (data) {
    data = data || {};
    _.defaults(data, {
      startRow: 1,
      endRow: 5000,
      showBinaryMetadata: true,
      sortBy: "title",
    });
    data.class = "collection";
    return search(data).then(function (res) {
      return _.map(res.entries, function (entry) {
        return NewCollectionConverter.fromLocal(entry);
      });
    });
  },
  searchPackages: function (data, returnRawData) {
    data = data || {};
    _.defaults(data, {
      startRow: 1,
      endRow: 5000,
      showBinaryMetadata: true,
      sortBy: "title",
    });
    data.class = "package";
    return search(data, returnRawData).then(function (res) {
      var promises = _.map(returnRawData ? res.data.entries : res.entries, function (entry) {
        return PackageConverter.fromLocal(entry);
      });
      return Promise.all(promises).then(function (resources, x) {
        if (returnRawData) {
          return {
            raw: res,
            results: resources,
          };
        } else {
          return resources;
        }
      });
    });
  },
  searchVersions: function (data) {
    data = data || {};
    _.defaults(data, {
      startRow: 1,
      endRow: 5000,
      showBinaryMetadata: true,
      sortBy: "title",
    });
    data.class = "version";
    return search(data).then(function (res) {
      var promises = _.map(res.entries, function (entry) {
        return LocalVersionService.get({ id: entry.id });
      });
      return Promise.all(promises);
    });
  },
};
