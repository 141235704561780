import * as React from "react";
import { useContext, useState } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { IEntity, ILinkingResult } from "../../models/dataModel";

import { RootContext, RootStore } from "../../stores/rootStore";
import { ResultOptionsStoreContext, ResultOptionsStore } from "./resultOptionsStore";
import { LinkToCollectionStoreContext, LinkToCollectionStore } from "./linkToCollectionStore";
import { CollectionPageStoreContext, CollectionPageStore } from "../../collection/collectionPageStore";
import { ConfirmAction } from "../../dialogs/ConfirmAction";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { TranslatedHtml } from "../TranslatedHtml";

/**
 * A components that provides link to collection feature.
 */
export const UnlinkFromCollection = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const failureDialog: DialogStore = new DialogStore();
  const resultOptionsStore: ResultOptionsStore = useContext(ResultOptionsStoreContext);
  const linkToCollectionStore: LinkToCollectionStore = useContext(LinkToCollectionStoreContext);
  const collectionPageStore: CollectionPageStore = useContext(CollectionPageStoreContext);

  const collection = collectionPageStore.getCollection();
  const [failures, setFailures] = useState<Array<{ error: string; entity: IEntity }>>([]);

  async function unlink() {
    try {
      if (collection) {
        renderLinkingResult(await linkToCollectionStore.unlink(resultOptionsStore.selectedEntities, collection));
      }
    } catch (err) {
      console.log(err);
    }
  }

  function renderLinkingResult(linkingResult: ILinkingResult) {
    if (linkingResult.failures.length == 0) {
      rootStore
        .getNotificationChannelStore()
        .success(
          rootStore.getTranslation("shared.linked_resources.resources_unlinked", [linkingResult.collection.title]),
        );
    } else {
      if (linkingResult.linkedCount > 0) {
        rootStore
          .getNotificationChannelStore()
          .error(rootStore.getTranslation("shared.linked_resources.resources_unlinked_with_errors"));
      } else {
        rootStore
          .getNotificationChannelStore()
          .error(rootStore.getTranslation("shared.linked_resources.resources_unlinking_failed"));
      }

      setFailures(linkingResult.failures);
      failureDialog.open();
    }
  }

  function getDialogContent(failures: Array<{ error: string; entity: IEntity }>) {
    return (
      <ul className="link-errors">
        {_.map(failures, (failure, i) => {
          return (
            <li key={i}>
              <a target="_blank" rel="noopener noreferrer" href={getEntityLink(failure.entity)}>
                {failure.entity.title}
              </a>
              <span>{failure.error}</span>
            </li>
          );
        })}
      </ul>
    );
  }

  function getEntityLink(entity) {
    if (entity.isLocal) {
      return "/catalog/localdetails/" + entity.id;
    } else {
      return "/catalog/details/" + entity.id;
    }
  }

  return (
    <DialogContext.Provider value={failureDialog}>
      <button
        className="unlink-selected"
        disabled={!resultOptionsStore.isLinkedEntitiesSelected}
        onClick={unlink}
        data-testid="unlink-selected"
      >
        <TranslatedHtml entry="link.unlink_selected" />
      </button>

      <ConfirmAction
        title={rootStore.getTranslation("shared.linked_resources.unlinking_failed_title")}
        content={getDialogContent(failures)}
         
        callbackConfirm={() => {}}
        callbackCloseDialog={() => failureDialog.close()}
        labelCancel={""}
        labelConfirm={rootStore.getTranslation("shared.confirm.close")}
      />
    </DialogContext.Provider>
  );
});
