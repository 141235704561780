import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { ISearchCriteriaHandler } from "../models/dataModel";
import { RootContext, RootStore } from "../stores/rootStore";
import { SearchCriteriaHandlerContext } from "../package/tabs/searchCriteriaStore";

/** Component for the Tags field */
export const Tags = observer(({ hideLabel }: { hideLabel?: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ISearchCriteriaHandler = useContext(SearchCriteriaHandlerContext);

  function handleInputTags(event) {
    event.preventDefault();
    let tags = [] as string[];
    if (event.target.value) {
      tags = event.target.value.split(",");
      tags = tags.map((tag) => tag.trim());
    }
    store.setTags(tags);
  }

  function getTagString(): string {
    return store.getTags().toString();
  }

  return (
    <li>
      {!hideLabel && (
        <label className="tooltip-info" title={rootStore.getTranslation("upload.tagsSubtitle")} htmlFor="tags">
          {rootStore.getTranslation("upload.tags")}
          <span className="icon help blue" />
        </label>
      )}
      <input name="tags" type="text" defaultValue={getTagString()} onChange={handleInputTags} data-testid="tags" />
      {!store.areTagsValid() && (
        <div className="error" data-testid="tagError">
          <small className="error">{rootStore.getTranslation("shared.catalog_entity_edit.invalid_tag")}</small>
        </div>
      )}
    </li>
  );
});
