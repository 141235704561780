import * as React from "react";
import { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { CreateNewLocalCollection } from "../upload/collection/CreateNewLocalCollection";
import { UploadFormContext, UploadFormStore } from "../upload/uploadFormStore";
import { TranslatedHtml } from "../components/TranslatedHtml";

const Actions = observer(() => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const rootStore: RootStore = useContext(RootContext);
  const navigate = useNavigate();

  const [isCreatingCollection, setIsCreatingCollection] = useState(false);

  async function createCollection() {
    setIsCreatingCollection(true);
    try {
      const res = await form.getUploaderStore().createCollection();
      if (!!res) navigate("/collections/local/" + res?.collection.id);
    } catch (err) {
      rootStore
        .getNotificationChannelStore()
        .error(rootStore.getTranslation("localCollections.collectionCreation.addCollectionFailed"));
    } finally {
      setIsCreatingCollection(false);
    }
  }

  return (
    <section className="actions actions-bottom" data-testid="bottomNavigation">
      <div className="previous-and-next">
        <button
          className="button-large l-right button-primary next"
          type="button"
          disabled={!form.getCollectionStore().isFormValid() || isCreatingCollection}
          onClick={createCollection}
          data-testid="create-collection-button"
        >
          <TranslatedHtml entry="actions.add" />
        </button>
      </div>
    </section>
  );
});

/**
 * Component for creating new local collections.
 */
export const NewLocalCollection = () => {
  return (
    <div className="upload" data-testid="newLocalCollection">
      <div id="content">
        <form className="step new-local-collection">
          <header className="step-main" />
          <CreateNewLocalCollection />
          <Actions />
        </form>
      </div>
    </div>
  );
};
