import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext, useState } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { OrganizationPageStore, OrganizationPageStoreContext } from "../organizationPageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { TranslatedHtml } from "../../components/TranslatedHtml";

/**
 * Renders edit logo dialog for organization
 */
export const EditLogoDialog = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const dialog: DialogStore = useContext(DialogContext);
  const [loading, setLoading] = useState<boolean>(false);

  function cancel() {
    dialog.close();
  }

  async function addLogo(data) {
    setLoading(true);
    if (data && data.target && data.target.files[0]) {
      await store.addLogo(data.target.files[0]);
    }
    setLoading(false);
  }

  async function deleteLogo() {
    setLoading(true);
    await store.deleteLogo();
    setLoading(false);
  }

  return (
    <article data-testid="editLogoDialog">
      <header>
        <h2>{rootStore.getTranslation("organization.edit.edit_logo")}</h2>
      </header>
      <section className="logo">
        {loading ? (
          <div className="spinner" data-testid="updating-logo" />
        ) : (
          <Fragment>
            <ol className="basic-data">
              <li>
                <label>{rootStore.getTranslation("organization.edit.edit_logo_descriptive")}</label>
              </li>
            </ol>
            {store.logoExists && (
              <div className="l-center-align">
                <img className="logo" src={store.getOrganizationLogoUrl()}></img>
              </div>
            )}
          </Fragment>
        )}
      </section>
      <div className="actions">
        <button onClick={cancel} disabled={loading} data-testid="cancel-edit-org-logo">
          {rootStore.getTranslation("actions.cancel")}
        </button>
        <button
          className="button-icon"
          onClick={deleteLogo}
          disabled={loading || !store.logoExists}
          data-testid="delete-org-logo"
        >
          <span className="icon icon-trash"></span>
          <span>{rootStore.getTranslation("actions.remove_picture")}</span>
        </button>
        <label className="button upload" htmlFor="chooseImageFile">
          <TranslatedHtml entry={"actions.choose_picture"} />
          <input
            disabled={loading}
            data-testid="choose-org-logo"
            id="chooseImageFile"
            className="hidden-file-upload"
            type="file"
            accept="image/*"
            onChange={addLogo}
            required
          />
        </label>
      </div>
    </article>
  );
});
