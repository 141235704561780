import * as React from "react";
import { useContext, useState, Fragment, useEffect } from "react";
import _ from "underscore";

import { ITreeViewCollection, ITreeViewContent } from "../../../models/dataModel";

import { RootStore, RootContext } from "../../../stores/rootStore";

/**
 * Renders an individual content list item.
 * @param content content
 * @param isCollectionSelected is the collection content belongs to selected
 * @param i index of the content list item
 */
const ContentListItem = ({
  content,
  isCollectionSelected,
  i,
}: {
  content: ITreeViewContent;
  isCollectionSelected: boolean;
  i: number;
}) => {
  const [selected, setSelected] = useState<boolean>(content.selected);
  const listItemId = content.id! + i;

  useEffect(() => {
    setSelected(content.selected);
  }, [isCollectionSelected]);

  function contentSelectionChange(content: ITreeViewContent) {
    if (!isCollectionSelected) {
      setSelected(!content.selected);
      content.selected = !content.selected;
    }
  }

  return (
    <Fragment>
      <div className="checkbox">
        <input
          id={listItemId}
          type="checkbox"
          onChange={() => contentSelectionChange(content)}
          checked={!!selected}
          disabled={isCollectionSelected}
          data-testid="selectContent"
        />
        <label htmlFor={listItemId} />
      </div>
      <span className="checkbox-label">{content.title}</span>
    </Fragment>
  );
};

/**
 * Renders a collection list item for the list of available source collections.
 * @param collection collection
 * @param i index of the list item
 */
export const CollectionListItem = ({ collection, i }: { collection: ITreeViewCollection; i: number }) => {
  const rootStore: RootStore = useContext(RootContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(collection.selected);
  const listItemId = collection.id! + i;

  function toggleCollection() {
    setIsOpen(!isOpen);
  }

  function collectionSelectionChange(event) {
    if (!isOpen) {
      toggleCollection();
      setSelected(event.target.checked);
      collection.selected = event.target.checked;
      selectContent(collection);
    } else {
      setSelected(event.target.checked);
      collection.selected = event.target.checked;
      selectContent(collection);
    }
  }

  function selectContent(collection: ITreeViewCollection) {
    _.each(collection.packages, (content: ITreeViewContent) => {
      content.selected = collection.selected;
    });
  }

  return (
    <Fragment>
      <div className="treecontrols">
        {isOpen ? (
          <div className="close" onClick={toggleCollection}>
            {rootStore.getTranslation("batch_edit.content.treecontrol.close")}
          </div>
        ) : (
          <div className="open" onClick={toggleCollection}>
            {rootStore.getTranslation("batch_edit.content.treecontrol.open")}
          </div>
        )}
      </div>
      <div className="checkbox">
        <input id={listItemId} type="checkbox" onChange={collectionSelectionChange} data-testid="selectCollection" />
        <label htmlFor={listItemId} />
      </div>
      <span className="checkbox-label" onClick={toggleCollection}>
        {collection.title}
      </span>
      {isOpen && (
        <Fragment>
          {(collection.packages || []).map((content: ITreeViewContent, i: number) => {
            return (
              <div className="content-list" key={i}>
                <ContentListItem content={content} isCollectionSelected={selected} i={i} />
              </div>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
};
