import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { IOrganization, IResource } from "../../models/dataModel";

export const ImmutableFlag = observer(({ resource }: { resource: IResource | IOrganization }) => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <Fragment>
      {rootStore.getUserStore().isUserAdmin() && !!resource?.isImmutable && (
        <span
          data-testid="isImmutableFlag"
          className="role-label"
          style={{ marginLeft: "1.5em", verticalAlign: "super", maxHeight: "24px", lineHeight: "normal" }}
        >
          {rootStore.getTranslation("immutability.immutable")}
        </span>
      )}
    </Fragment>
  );
});
