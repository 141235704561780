/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime } from "luxon";
import _ from "underscore";

import { startsWith } from "underscore.string";
import { ImageUtils } from "../../utils/ImageUtils";
import { Binary } from "../factories/Binary";
import { LocalPackageRequestTransferObject } from "../factories/LocalPackageRequestTransferObject";
import { NewCollection } from "../factories/NewCollection";
import { Package } from "../factories/Package";
import { TCCCollectionRequestTransferObject } from "../factories/TCCCollectionRequestTransferObject";
import { LocalBinaryService } from "../services/LocalBinaryService";
import { onlyTruthy } from "../utils/onlyTruthy";
import { AttributesConverter } from "./AttributesConverter";
import { fixDisplayName } from "./FixDisplayName";
import { TagsConverter } from "./TagsConverter";
import { URLBuilder } from "../../utils/URLBuilder";

import { IEntity } from "../../models/dataModel";
import { BinaryTypeEnum, ObjectTypeEnum, SourceEnum, VisibilityEnum } from "../../models/enums";
import { ITCCPackage, TCCBaseAccessLevelEnum, ITCCEntity } from "../../models/TCCdataModel";

/**
 * Extracts the extensions from the binaries.
 * Does not include the thumbnail extension.
 * @param {ITCCEntity[]} versions - The binaries to extract the extensions from.
 * @returns {string[]} - The extracted extensions.
 */
export function getFileTypes(versions) {
  var extensions = [];

  _.each(versions, function (version) {
    var fileTypes = _.chain(version.binaries)
      .filter(function (binary) {
        return !binary.name.includes("thumbnail");
      })
      .map(function (binary) {
        return binary.ext;
      })
      .value();

    if (fileTypes.length > 0) {
      extensions = extensions.concat(fileTypes);
    }
  });

  return _.uniq(extensions);
}

/**
 * Extracts the version attributes for tested versions and compatible software products

 * @param {ITCCEntity[]} versions - The binaries to extract the attributes from.
 * @returns {{compatibleSoftwareProducts: string[], testedVersions: string[]}} - Object with the properties
 */
export function getVersionAttributes(versions) {
  var compatibleSoftwareProducts = [];
  var testedVersions = [];

  _.forEach(versions, (version) => {
    if (!!version.attributes?.compatibleSoftwareProducts) {
      compatibleSoftwareProducts = compatibleSoftwareProducts.concat(
        Object.keys(version.attributes.compatibleSoftwareProducts),
      );
    }

    if (!!version.attributes?.testedVersions) {
      testedVersions = testedVersions.concat(Object.keys(version.attributes.testedVersions));
    }
  });

  return { compatibleSoftwareProducts: _.uniq(compatibleSoftwareProducts), testedVersions: _.uniq(testedVersions) };
}

/**
 * Converts a TCC package to a TWH package object.
 *
 * @param {ITCCPackage} data - The TCC package data.
 * @returns {IEntity} - The converted package object.
 */
function TCCPackageToTWHPackage(data) {
  data = data || {};
  var binaries = data.binaries || {};
  var attributesFromTCC = AttributesConverter.fromTCC(data.attributes || {});
  var details = resolveDetails(data.details, attributesFromTCC);
  attributesFromTCC.support = attributesFromTCC.support || {};
  var copyrightInfo = data.copyright || attributesFromTCC.support.copyrightInfo || "";
  var releasenote = binaries.releasenote;
  var translations = data.translations;

  var pack = {
    _id: data.id,
    id: data.id,
    binaries: data.binaries,
    thumbnails: [],
    thumbnails3d: [],
    thumbnails_details: [],
    title: data.title,
    description: data.description,
    source: data.source,
    codeName: data.externalId,
    currentUserRating: data.currentUserRating,
    reviewCount: data.reviewCount,
    type: data.type,
    isHidden: data.isHidden,
    isBanned: data.baseAccessLevel === TCCBaseAccessLevelEnum.BANNED ? true : false,
    isArchived: !!attributesFromTCC.status ? attributesFromTCC.status.archived : false,
    isImmutable: !!attributesFromTCC.immutability ? attributesFromTCC.immutability["--immutable"] : false,
    visibility: _.includes([TCCBaseAccessLevelEnum.NONE, TCCBaseAccessLevelEnum.BANNED], data.baseAccessLevel)
      ? VisibilityEnum.PRIVATE
      : VisibilityEnum.PUBLIC,
    tags: TagsConverter.fromTCC(data.tags),
    copyrightInfo: copyrightInfo,
    translations: translations,
    richContent: details,
    accessRole: data.accessRole,
    versionCount: data.entityCount,
    views: data.views,
    downloads: data.downloads,
  };

  if (data.allowComments) {
    pack.allowComments = data.allowComments;
  }

  if (attributesFromTCC.references) {
    if (attributesFromTCC.references.parentCollectionId) {
      pack.collection = NewCollection({ _id: attributesFromTCC.references.parentCollectionId });
    }
    if (attributesFromTCC.references.relatedContent) {
      if (startsWith(attributesFromTCC.references.relatedContent, "[")) {
        pack.related = JSON.parse(attributesFromTCC.references.relatedContent);
      } else {
        pack.related = attributesFromTCC.references.relatedContent;
      }
      if (!_.isArray(pack.related)) {
        pack.related = pack.related.split(",");
      }
    }
  }

  if (data.parentCollectionId) {
    pack.collection = NewCollection({ _id: data.parentCollectionId });
  }

  pack.createdAt = DateTime.fromSQL(data.createTime, { zone: "utc" }).toJSDate();
  pack.modifiedAt = DateTime.fromSQL(data.modifyTime, { zone: "utc" }).toJSDate();
  pack.creator = fixDisplayName(data.creator);
  pack.modifier = fixDisplayName(data.modifier);

  if (releasenote) {
    pack.releasenote = Binary.create({
      _id: releasenote.id,
      reference: "releasenote",
      creator: releasenote.creator,
      modifier: releasenote.modifier,
      createdAt: DateTime.fromSQL(releasenote.createTime, { zone: "utc" }).toJSDate(),
      modifiedAt: DateTime.fromSQL(releasenote.modifyTime, { zone: "utc" }).toJSDate(),
      url: URLBuilder.buildURL(releasenote.url, {
        createdAt: DateTime.fromSQL(releasenote.modifyTime, { zone: "utc" }).toSeconds(),
      }),
      contentUrl: URLBuilder.buildURL(releasenote.contentUrl),
      attributes: {
        fileName: releasenote.originalFileName,
        originalName: releasenote.originalFileName,
        fileSize: releasenote.fileSize,
        type: BinaryTypeEnum.OTHER,
      },
    });
  }

  _.each(binaries, function (binary, key) {
    if (key.indexOf("thumbnail_3d") > -1) {
      var thumbnail_3d = Binary.create({
        _id: binary.id,
        reference: key,
        creator: binary.creator,
        modifier: binary.modifier,
        createdAt: DateTime.fromSQL(binary.createTime, { zone: "utc" }).toJSDate(),
        modifiedAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toJSDate(),
        url: URLBuilder.buildURL(binary.url, {
          createdAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toSeconds(),
        }),
        contentUrl: URLBuilder.buildURL(binary.contentUrl),
        attributes: {
          fileName: binary.originalFileName,
          originalName: binary.originalFileName,
          fileSize: binary.fileSize,
          type: BinaryTypeEnum.THUMBNAIL,
        },
      });
      pack.thumbnails3d.push(thumbnail_3d);
    } else if (key.indexOf("thumbnail_details") > -1) {
      var thumbnail = Binary.create({
        _id: binary.id,
        reference: key,
        creator: binary.creator,
        modifier: binary.modifier,
        createdAt: DateTime.fromSQL(binary.createTime).toJSDate(),
        modifiedAt: DateTime.fromSQL(binary.modifyTime).toJSDate(),
        url: URLBuilder.buildURL(binary.url, { createdAt: DateTime.fromSQL(binary.modifyTime).toSeconds() }),
        contentUrl: URLBuilder.buildURL(binary.contentUrl),
        attributes: {
          fileName: binary.originalFileName,
          originalName: binary.originalFileName,
          fileSize: binary.fileSize,
          type: BinaryTypeEnum.THUMBNAIL,
        },
      });
      pack.thumbnails_details.push(thumbnail);
    } else if (key.indexOf("thumbnail") > -1) {
      var thumbnail = Binary.create({
        _id: binary.id,
        reference: key,
        creator: binary.creator,
        modifier: binary.modifier,
        createdAt: DateTime.fromSQL(binary.createTime, { zone: "utc" }).toJSDate(),
        modifiedAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toJSDate(),
        url: URLBuilder.buildURL(binary.url, {
          createdAt: DateTime.fromSQL(binary.modifyTime, { zone: "utc" }).toSeconds(),
        }),
        contentUrl: URLBuilder.buildURL(binary.contentUrl),
        attributes: {
          fileName: binary.originalFileName,
          originalName: binary.originalFileName,
          fileSize: binary.fileSize,
          type: BinaryTypeEnum.THUMBNAIL,
        },
      });

      if (
        data.attributes &&
        data.attributes.defaultThumbnail &&
        data.attributes.defaultThumbnail.name &&
        data.attributes.defaultThumbnail.name.value === thumbnail.reference
      ) {
        pack.thumbnails.unshift(thumbnail);
      } else {
        pack.thumbnails.push(thumbnail);
      }
    }
  });

  if (pack.thumbnails.length <= 0) {
    var thumbnail = Binary.create({
      url: ImageUtils.getDefaultThumbail(),
      contentUrl: ImageUtils.getDefaultThumbail(),
    });
    pack.thumbnails.push(thumbnail);
  }

  pack.attributes = {
    licensesACL: attributesFromTCC.licenses ? onlyTruthy(attributesFromTCC.licenses) : undefined,
    helpUrl: attributesFromTCC.support.helpUrl,
    supportUrl: attributesFromTCC.support.supportUrl,
    discussionForumUrl: attributesFromTCC.support.discussionForumUrl,
    specialTermsUrl: attributesFromTCC.support.specialTermsUrl,
    contentDownloadUrl: attributesFromTCC.content ? attributesFromTCC.content.contentDownloadUrl : undefined,
    measurementUnits: onlyTruthy(attributesFromTCC.measurementUnits),
    testedVersions: onlyTruthy(attributesFromTCC.testedVersions),
    itemTypeCategories: onlyTruthy(attributesFromTCC.itemTypeCategories),
    useCategories: onlyTruthy(attributesFromTCC.useCategories),
    locationRestrictions: onlyTruthy(attributesFromTCC.locationRestrictions),
    supportedLanguages: onlyTruthy(attributesFromTCC.supportedLanguages),
    defaultThumbnail: attributesFromTCC.defaultThumbnail,
    videourls: attributesFromTCC.videourls,
    bannedDate: attributesFromTCC.status ? attributesFromTCC.status.bannedDate : undefined,
  };

  return Package(pack);
}

function resolveDetails(details, attributes) {
  if (details) {
    return details;
  } else if (attributes.richContent && attributes.richContent.description) {
    return attributes.richContent.description;
  } else {
    return "";
  }
}

function localPackageToPackage(data) {
  data = data || {};

  var binaries = data.binaries || [];
  var thumbnail = _.find(binaries, function (b) {
    return b.name === "thumbnail";
  });

  data.attributes = data.attributes || {};
  var richContent = data.attributes.richContent || {};

  delete data.attributes.richContent;

  var pack = {
    id: data.id,
    title: data.title,
    description: data.description,
    tags: TagsConverter.fromLocal(data.tags),
    source: SourceEnum.LOCAL_SERVICE,
    type: ObjectTypeEnum.LOCAL_PACKAGE,
    isLocal: true,
    versionCount: data.versionCount,
    visibility: VisibilityEnum.PRIVATE,
    richContent: richContent,
    attributes: data.attributes || {},
    related: data.related,
  };

  if (data.collectionId) {
    pack.collection = NewCollection({ _id: data.collectionId });
  }

  if (thumbnail) {
    var url = LocalBinaryService.getBinaryUrl("package", data.id, "thumbnail");
    pack.thumbnail = Binary.create({
      url: URLBuilder.buildURL(url, { dontCacheMe: DateTime.utc().toSeconds() }),
      id: thumbnail.id,
    });
  } else {
    pack.thumbnail = Binary.create({ url: ImageUtils.getDefaultThumbail() });
  }

  return Package(pack);
}

export const PackageConverter = {
  fromLocal: function (data) {
    data = data || {};
    if (data.attributes.relatedContent) {
      data.related = data.attributes.relatedContent;
    }
    if (Array.isArray(data)) {
      return _.map(data, function (d) {
        return localPackageToPackage(d);
      });
    } else {
      return localPackageToPackage(data);
    }
  },
  toLocal: function (data) {
    data = data || {};
    if (data.related) {
      data.attributes.relatedContent = data.related;
    }
    data.attributes = data.attributes || { richContent: {} };
    if (!data.attributes.richContent) {
      data.attributes.richContent = {};
    }
    data.attributes.richContent.description = data.richContent.description || "";

    if (data.copyrightInfo) {
      data.attributes.copyrightInfo = data.copyrightInfo;
    }

    var p = LocalPackageRequestTransferObject({
      id: data.id || data._id,
      collectionId: data.collection ? data.collection.id : undefined,
      title: data.title,
      description: data.description,
      tags: TagsConverter.toLocal(data.tags),
      attributes: data.attributes,
    });
    return p;
  },
  fromTCC: function (data) {
    data = data || {};
    if (Array.isArray(data)) {
      return _.map(data, function (d) {
        return TCCPackageToTWHPackage(d);
      });
    } else {
      return TCCPackageToTWHPackage(data);
    }
  },
  toTCCPartial: function (data) {
    data = data || {};

    var converted = {
      ...(!_.isUndefined(data.id) && { id: data.id }),
      ...(!_.isUndefined(data.title) && { title: data.title }),
      ...(!_.isUndefined(data.codeName) && { externalId: data.codeName }),
      ...(!_.isUndefined(data.description) && { description: data.description }),
      ...(!_.isUndefined(data.isHidden) && { isHidden: data.isHidden }),
      ...((data.isBanned || data.visibility) && {
        baseAccessLevel: data.isBanned
          ? TCCBaseAccessLevelEnum.BANNED
          : data.visibility === VisibilityEnum.PRIVATE
          ? TCCBaseAccessLevelEnum.NONE
          : TCCBaseAccessLevelEnum.VIEWER,
      }),
      ...(!_.isUndefined(data.tags) && { tags: TagsConverter.toTCC(data.tags) }),
      ...(!_.isUndefined(data.collection) && { parentCollectionId: data.collection ? data.collection.id : null }),
      ...(!_.isUndefined(data.copyrightInfo) && { copyright: data.copyrightInfo }),
      ...(!_.isUndefined(data.richContent) && { details: data.richContent }),
      ...(!_.isUndefined(data.doNotNotify) && { doNotNotify: data.doNotNotify }),
      ...(!_.isUndefined(data.allowComments) && { allowComments: data.allowComments }),
      ...(!_.isUndefined(data.creatorId) && { creatorId: data.creatorId }),
      ...(!_.isUndefined(data.translations) && { translations: data.translations }),
      ...((!_.isUndefined(data.attributes) || !_.isUndefined(data.related) || !_.isUndefined(data.isImmutable)) && {
        attributes: PackageConverter.toTCCAttributesPartial(data),
      }),
    };

    return converted;
  },
  toTCC: function (data) {
    data = data || {};

    var transferObject = {
      id: data.id || data._id,
      title: data.title,
      externalId: data.codeName,
      type: ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE,
      description: data.description,
      isHidden: data.isHidden,
      baseAccessLevel: data.isBanned
        ? TCCBaseAccessLevelEnum.BANNED
        : data.visibility === VisibilityEnum.PRIVATE
        ? TCCBaseAccessLevelEnum.NONE
        : TCCBaseAccessLevelEnum.VIEWER,
      tags: TagsConverter.toTCC(data.tags),
      parentCollectionId: data.collection ? data.collection.id : null,
      copyright: data.copyrightInfo || "",
      details: data.richContent || "",
    };
    if (!_.isUndefined(data.doNotNotify)) {
      transferObject.doNotNotify = data.doNotNotify;
    }
    if (!_.isUndefined(data.allowComments)) {
      transferObject.allowComments = data.allowComments;
    }
    if (!_.isUndefined(data.creatorId)) {
      transferObject.creatorId = data.creatorId;
    }
    if (!_.isUndefined(data.translations)) {
      transferObject.translations = data.translations;
    }

    transferObject.attributes = PackageConverter.toTCCAttributes(data);
    var t = TCCCollectionRequestTransferObject(transferObject);
    return t;
  },
  toTCCAttributes: function (data) {
    var attributes = data.attributes || {};
    var attributesForTCC = {};

    if (
      !_.isUndefined(attributes.helpUrl) ||
      !_.isUndefined(attributes.supportUrl) ||
      !_.isUndefined(attributes.discussionForumUrl) ||
      !_.isUndefined(attributes.specialTermsUrl)
    ) {
      attributesForTCC.support = {
        ...(!_.isUndefined(attributes.helpUrl) && { helpUrl: { dataType: "string", value: attributes.helpUrl } }),
        ...(!_.isUndefined(attributes.supportUrl) && {
          supportUrl: { dataType: "string", value: attributes.supportUrl },
        }),
        ...(!_.isUndefined(attributes.discussionForumUrl) && {
          discussionForumUrl: { dataType: "string", value: attributes.discussionForumUrl },
        }),
        ...(!_.isUndefined(attributes.specialTermsUrl) && {
          specialTermsUrl: { dataType: "string", value: attributes.specialTermsUrl },
        }),
      };
    }

    if (!_.isUndefined(attributes.contentDownloadUrl)) {
      attributesForTCC.content = {};
      attributesForTCC.content.contentDownloadUrl = { dataType: "string", value: attributes.contentDownloadUrl || "" };
    }

    if (!_.isUndefined(attributes.bannedDate)) {
      attributesForTCC.status = {};
      attributesForTCC.status.bannedDate = { dataType: "string", value: attributes.bannedDate || "" };
    }

    if (!_.isUndefined(attributes.defaultThumbnail) && !!attributes.defaultThumbnail.name) {
      attributesForTCC.defaultThumbnail = {
        name: { dataType: "string", value: attributes.defaultThumbnail.name },
      };
    }

    if (!_.isUndefined(data.collection)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.parentCollectionId = { dataType: "string", value: "" + data.collection.id };
    }

    if (!_.isUndefined(data.related)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.relatedContent = { dataType: "stringArray", value: data.related };
    }

    if (data.isImmutable) {
      attributesForTCC.immutability = attributes.immutability || {};
      attributesForTCC.immutability["--immutable"] = { dataType: "boolean", value: true };
    }

    if (data.isArchived) {
      attributesForTCC.status = attributes.status || {};
      attributesForTCC.status.archived = { dataType: "boolean", value: true };
    }

    if (
      attributes.measurementUnits ||
      attributes.itemTypeCategories ||
      attributes.useCategories ||
      attributes.locationRestrictions ||
      attributes.supportedLanguages
    ) {
      var searchCriteria = _.filter(
        ["measurementUnits", "itemTypeCategories", "useCategories", "locationRestrictions", "supportedLanguages"],
        (criteria) => !_.isUndefined(attributes[criteria]),
      );

      _.each(searchCriteria, function (criteria) {
        var values = attributes[criteria] || [];
        if (_.size(values) > 0) {
          var p = (attributesForTCC[criteria] = {});
          _.each(values, function (v) {
            p[v] = { dataType: "boolean", value: true };
          });
        }
      });
    }

    if (!_.isUndefined(attributes.licensesACL)) {
      var values = attributes.licensesACL;
      if (_.size(values) > 0) {
        var p = (attributesForTCC.licenses = {});
        _.each(values, function (v) {
          p[v] = { dataType: "boolean", value: true };
        });
      }
    }

    if (!_.isUndefined(attributes.videourls)) {
      var values = attributes.videourls;
      if (_.size(values) > 0) {
        var p = (attributesForTCC.videourls = {});
        _.each(values, function (v, k) {
          if (v === null) {
            p[k] = null;
          } else {
            p[k] = { dataType: "string", value: v };
          }
        });
      }
    }

    return attributesForTCC;
  },
  toTCCAttributesPartial: function (data) {
    var attributes = data.attributes || {};
    var removedAttributes = data.removedAttributes || {};
    var attributesForTCC = {};

    if (
      !_.isUndefined(attributes.helpUrl) ||
      !_.isUndefined(attributes.supportUrl) ||
      !_.isUndefined(attributes.discussionForumUrl) ||
      !_.isUndefined(attributes.specialTermsUrl)
    ) {
      attributesForTCC.support = {
        ...(!_.isUndefined(attributes.helpUrl) && { helpUrl: { dataType: "string", value: attributes.helpUrl } }),
        ...(!_.isUndefined(attributes.supportUrl) && {
          supportUrl: { dataType: "string", value: attributes.supportUrl },
        }),
        ...(!_.isUndefined(attributes.discussionForumUrl) && {
          discussionForumUrl: { dataType: "string", value: attributes.discussionForumUrl },
        }),
        ...(!_.isUndefined(attributes.specialTermsUrl) && {
          specialTermsUrl: { dataType: "string", value: attributes.specialTermsUrl },
        }),
      };
    }

    if (!_.isUndefined(attributes.contentDownloadUrl)) {
      attributesForTCC.content = {};
      attributesForTCC.content.contentDownloadUrl = { dataType: "string", value: attributes.contentDownloadUrl || "" };
    }

    if (!_.isUndefined(attributes.bannedDate)) {
      attributesForTCC.status = {};
      attributesForTCC.status.bannedDate = { dataType: "string", value: attributes.bannedDate || "" };
    }

    if (!_.isUndefined(attributes.defaultThumbnail) && !!attributes.defaultThumbnail.name) {
      attributesForTCC.defaultThumbnail = {
        name: { dataType: "string", value: attributes.defaultThumbnail.name },
      };
    }

    if (!_.isUndefined(data.collection)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.parentCollectionId = { dataType: "string", value: "" + data.collection.id };
    }

    if (!_.isUndefined(data.related)) {
      attributesForTCC.references = attributesForTCC.references || {};
      attributesForTCC.references.relatedContent = { dataType: "stringArray", value: data.related };
    }

    if (data.isImmutable) {
      attributesForTCC.immutability = attributes.immutability || {};
      attributesForTCC.immutability["--immutable"] = { dataType: "boolean", value: true };
    }

    if (data.isArchived) {
      attributesForTCC.status = attributes.status || {};
      attributesForTCC.status.archived = { dataType: "boolean", value: true };
    }

    if (
      attributes.measurementUnits ||
      attributes.itemTypeCategories ||
      attributes.useCategories ||
      attributes.locationRestrictions ||
      attributes.supportedLanguages
    ) {
      var searchCriteria = _.filter(
        ["measurementUnits", "itemTypeCategories", "useCategories", "locationRestrictions", "supportedLanguages"],
        (criteria) => !_.isUndefined(attributes[criteria]),
      );

      _.each(searchCriteria, function (criteria) {
        var values = attributes[criteria] || [];
        if (_.size(values) > 0) {
          var p = (attributesForTCC[criteria] = {});
          _.each(values, function (v) {
            p[v] = { dataType: "boolean", value: true };
          });
        } else if (_.size(values) === 0) {
          attributesForTCC[criteria] = null;
        }
      });
    }

    if (
      removedAttributes.measurementUnits ||
      removedAttributes.itemTypeCategories ||
      removedAttributes.useCategories ||
      removedAttributes.locationRestrictions ||
      removedAttributes.supportedLanguages
    ) {
      var removedSearchCriteria = _.filter(
        ["measurementUnits", "itemTypeCategories", "useCategories", "locationRestrictions", "supportedLanguages"],
        (criteria) => !_.isUndefined(removedAttributes[criteria]),
      );

      _.each(removedSearchCriteria, function (criteria) {
        var values = removedAttributes[criteria] || [];
        if (_.size(values) > 0) {
          var p = attributesForTCC[criteria] || {};
          _.each(values, function (v) {
            p[v] = null;
          });
        }
      });
    }

    if (!_.isUndefined(attributes.licensesACL)) {
      var licenses = attributes.licensesACL;
      if (_.size(licenses) > 0) {
        var p = (attributesForTCC.licenses = {});
        _.each(licenses, function (v) {
          p[v] = { dataType: "boolean", value: true };
        });
      } else if (_.size(licenses) === 0) {
        attributesForTCC.licenses = null;
      }
    }

    if (!_.isUndefined(attributes.videourls)) {
      var urls = attributes.videourls;
      if (_.size(urls) > 0) {
        var q = (attributesForTCC.videourls = {});
        _.each(urls, function (v, k) {
          if (v === null) {
            q[k] = null;
          } else {
            q[k] = { dataType: "string", value: v };
          }
        });
      } else if (_.size(urls) === 0) {
        attributesForTCC.videourls = null;
      }
    }

    return attributesForTCC;
  },
};
