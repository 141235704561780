/**
 * Creates a UserPreferences object.
 * @param {Object} data - The data object containing user preferences.
 * @returns {Object} - The UserPreferences object.
 */
export function UserPreferences(data) {
  data = data || {};

  return {
    locations: data.locations || [],
    measurementUnit: data.measurementUnit,
    useCategories: data.useCategories || [],
    languages: data.languages || [],
    operatingSystems: data.operatingSystems || [],
    softwareProducts: data.softwareProducts || [],
  };
}
