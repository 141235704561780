import * as React from "react";
import classNames from "classnames";
import * as _string from "underscore.string";
import { SanitizationModeEnum } from "../models/enums";
import { sanitize } from "../utils/functions";

/**
 * A component that can be used to display text. The text can also be pre-processed before it gets rendered.
 * @param text input text
 * @param nonBreaking boolean marking if white spaces should be replaced with non breaking entity
 * @param sanitizationMode sanitization mode, it defaults to SanitizationModeEnum.NONE
 * @param truncate output text limit. Minimal value is 3.
 * @param cssClasses an array of strings representing css classes that will be added to the SPAN element (wrapper)
 */
export const TextWrapper = ({
  text,
  nonBreaking,
  sanitizationMode = SanitizationModeEnum.NONE,
  truncate,
  cssClasses,
}: {
  text: string;
  nonBreaking?: boolean;
  sanitizationMode?: SanitizationModeEnum;
  truncate?: number;
  cssClasses?: string[];
}) => {
  const defaultCssClass = "text-wrapper";
  cssClasses = cssClasses || [];
  cssClasses.push(defaultCssClass);

  if (sanitizationMode !== SanitizationModeEnum.NONE) {
    text = sanitize(text, sanitizationMode);
    cssClasses.push("sanitized");
  }
  if (nonBreaking) {
    text = text.replace(/ /g, "&nbsp;");
    cssClasses.push("non-breaking");
  }
  if (truncate && truncate > 3) {
    text = _string.truncate(text, truncate);
    cssClasses.push("truncated");
  }
  return <span className={classNames(cssClasses)} dangerouslySetInnerHTML={{ __html: text }} />;
};
