import _ from "underscore";

function fixName(input) {
  if (_.isString(input)) {
    return input == "null" ? "" : input;
  } else {
    return input;
  }
}

/**
 * Fixes the display name by ensuring it is a valid name format.
 * If the input is an object with a "displayName" property, it fixes the value of the property.
 * If the input is a string, it fixes the string itself.
 * If the input is neither an object nor a string, it returns the input as is.
 *
 * @param {Object | string} input - The input to fix the display name for.
 * @returns {Object | string} - The input with the display name fixed.
 */
export function fixDisplayName(input) {
  if (_.isObject(input)) {
    if (_.has(input, "displayName")) {
      input.displayName = fixName(input.displayName);
      return input;
    } else {
      input.displayName = "";
      return input;
    }
  } else if (_.isString(input)) {
    return fixName(input);
  } else {
    return input;
  }
}
