import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import { RootContext } from "../../stores/rootStore";
import { BatchContentHandlerContext } from "../../dialogs/batch-edit/batchEditDialogStore";

/**
 * Select all checkbox for batch dialogs
 */
export const SelectAll = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchContentHandlerContext);

  function handleSelectionChange(event) {
    dialogStore.setSelectAll(event.target.checked);
  }

  return (
    <div className="select-all">
      <input
        id="select-all"
        type="checkbox"
        checked={dialogStore.getSelectAll()}
        onChange={handleSelectionChange}
        data-testid="select-all-content"
      />
      <label htmlFor="select-all">{rootStore.getTranslation("batch_edit.content.select_all")}</label>
      <div className="info">{rootStore.getTranslation("batch_edit.content.select_all_info")}</div>
      {dialogStore.isProcessing() && (
        <div className="spinner select-all">{rootStore.getTranslation("shared.spinner_loading")}</div>
      )}
    </div>
  );
});
