import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { ThumbnailStoreContext } from "../thumbnail/thumbnailStore";
import { ImageNaviStoreContext } from "./imageNaviStore";
import classNames from "classnames";

/**
 * A component that displays thumbnail navigation controls
 */
export const Controls = observer(() => {
  const thumbnailStore = useContext(ThumbnailStoreContext);
  const binaries = thumbnailStore.getBinaries();
  const imageNaviStore = useContext(ImageNaviStoreContext);

  function shouldShowControls() {
    return binaries.length > 2;
  }

  return (
    <Fragment>
      {shouldShowControls() && (
        <div className="controls">
          <a
            data-testid="imagenavi-controls-backward"
            className={classNames("navigate-backward", {
              disabled: !imageNaviStore.canNavigateBackward(),
            })}
            onClick={imageNaviStore.navigateBackward}
          ></a>
          <a
            data-testid="imagenavi-controls-forward"
            className={classNames("navigate-forward", {
              disabled: !imageNaviStore.canNavigateForward(binaries),
            })}
            onClick={imageNaviStore.navigateForward}
          ></a>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that displays thumbnail
 */
export const Thumbnail = observer(
  ({ naviIndex, bigMargin, type }: { naviIndex: number; bigMargin?: boolean; type?: string }) => {
    const thumbnailStore = useContext(ThumbnailStoreContext);
    const binaries = thumbnailStore.getBinaries();
    const imageNaviStore = useContext(ImageNaviStoreContext);

    function getStyle(url) {
      return { backgroundImage: `url(${url})` };
    }

    function getUrl() {
      if (type === "3D") {
        return "images/3d-thumb.png";
      } else {
        return binaries[naviIndex].contentUrl;
      }
    }

    return (
      <Fragment>
        <div
          className={classNames("thumb-box thumb-box-small", {
            "big-margin": bigMargin,
          })}
        >
          <a
            data-testid="view-thumbnail"
            className="thumb-box thumb-box-small"
            onClick={() => imageNaviStore.setViewIndex(naviIndex)}
          >
            <figure thumb-box="" thumbnail-url={getUrl()} style={getStyle(getUrl())} />
          </a>
        </div>
      </Fragment>
    );
  },
);

/**
 * A component that displays video
 */
export const Video = observer(({ naviIndex, bigMargin }: { naviIndex: number; bigMargin?: boolean }) => {
  const thumbnailStore = useContext(ThumbnailStoreContext);
  const binaries = thumbnailStore.getBinaries();
  const imageNaviStore = useContext(ImageNaviStoreContext);

  return (
    <Fragment>
      <div
        className={classNames("thumb-box thumb-box-small", {
          "big-video-margin": bigMargin,
          "video-margin": !bigMargin,
        })}
      >
        <div data-testid="view-video" className="layer" onClick={() => imageNaviStore.setViewIndex(naviIndex)}></div>
        <iframe width="75" height="55" src={binaries[naviIndex].value}></iframe>
      </div>
    </Fragment>
  );
});

/**
 * A component that displays image navigation
 */
export const ImageNavi = observer(() => {
  const thumbnailStore = useContext(ThumbnailStoreContext);
  const binaries = thumbnailStore.getBinaries();
  const imageNaviStore = useContext(ImageNaviStoreContext);

  function shouldDisplayNavi() {
    return binaries.length > 1;
  }

  return (
    <Fragment>
      {shouldDisplayNavi() && (
        <div className="image-navi">
          <Controls />
          {binaries[imageNaviStore.getNaviIndex()].isThumbnail && (
            <Thumbnail naviIndex={imageNaviStore.getNaviIndex()} bigMargin={true} />
          )}
          {binaries[imageNaviStore.getNaviIndex()].is3DThumbnail && (
            <Thumbnail naviIndex={imageNaviStore.getNaviIndex()} bigMargin={true} type="3D" />
          )}
          {binaries[imageNaviStore.getNaviIndex()].isVideo && (
            <Video naviIndex={imageNaviStore.getNaviIndex()} bigMargin={true} />
          )}
          {binaries[imageNaviStore.getNaviIndex() + 1].isThumbnail && (
            <Thumbnail naviIndex={imageNaviStore.getNaviIndex() + 1} />
          )}
          {binaries[imageNaviStore.getNaviIndex() + 1].is3DThumbnail && (
            <Thumbnail naviIndex={imageNaviStore.getNaviIndex() + 1} type="3D" />
          )}
          {binaries[imageNaviStore.getNaviIndex() + 1].isVideo && (
            <Video naviIndex={imageNaviStore.getNaviIndex() + 1} />
          )}
        </div>
      )}
    </Fragment>
  );
});
