/**
 * Field validity settings
 */
export const fieldValidity = {
  package: {
    title: { minLength: 3 },
    description: { minLength: 5 },
    details: { maxLength: 5000 },
  },
  collection: {
    title: { minLength: 3, maxLength: 100 },
  },
};

export interface IFieldValidity {
  package: {
    title: { minLength: number };
    description: { minLength: number };
    details: { maxLength: number };
  };
  collection: {
    title: { minLength: number; maxLength: number };
  };
}
