import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { MyAlertsContext } from "./myAlertsStore";
import { INotification, IItem } from "../../models/dataModel";
import { SearchPageListStyleEnum } from "../../models/enums";

export const NotificationsForItem = observer(({ item }: { item: IItem }) => {
  const store = useContext(MyAlertsContext);
  const rootStore = store.getRootStore();
  const notifications = rootStore.getNotificationsStore();

  const resultListStyle = rootStore.getSearchStore().getSearchPageSettingsStore().resultListStyle;
  const displayAs = resultListStyle !== SearchPageListStyleEnum.LIST ? " as-thumbnails" : "";

  return (
    <div className={classNames("notifications-wrapper", displayAs)} data-testid="itemNotifications">
      {notifications.getContentNotificationsFor(item).map((notification: INotification, i: number) => {
        return (
          <div className="notification" key={i}>
            {store.resolveNotificationText(notification)}
          </div>
        );
      })}
    </div>
  );
});
