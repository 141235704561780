import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import * as ReactModal from "react-modal";
import classNames from "classnames";

import { DialogContext, DialogStore } from "./dialogStore";

/**
 * Wrapper component that uses ReactModal to render a dialog.
 * @param content the component that renders dialog content
 * @param additionalClass optional additional className, "dialog-content" by default
 */
export const Dialog = observer(({ content, additionalClass }: { content: JSX.Element; additionalClass?: string }) => {
  const dialog: DialogStore = useContext(DialogContext);

  return (
    <ReactModal
      isOpen={dialog.isDialogVisible()}
      className={classNames("dialog-content", additionalClass)}
      overlayClassName="dialog-mask"
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      {content}
    </ReactModal>
  );
});
