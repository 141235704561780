import * as React from "react";
import { useState } from "react";

/**
 * Renders the search form used for admin page components.
 * @param placeholder the placeholder text for the search field
 * @param performSearch the executed search function
 */
export const SearchForm = ({
  placeholder,
  performSearch,
  testId,
}: {
  placeholder: string;
  performSearch: (term: string, clear?: () => void) => void;
  testId: string;
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  function handleInput(event) {
    setSearchTerm(event.target.value);
  }

  function clearSearchTerm() {
    setSearchTerm("");
  }

  function handleSubmit(event) {
    event.preventDefault();
    performSearch(searchTerm, clearSearchTerm);
  }

  return (
    <form className="search-form" onSubmit={handleSubmit} data-testid={testId}>
      <input
        className="search"
        type="search"
        value={searchTerm}
        onChange={handleInput}
        placeholder={placeholder}
        data-testid={testId + "Input"}
      />
      <button className="input-search" type="submit" data-testid={testId + "Button"} />
    </form>
  );
};
