import _ from "underscore";
import { APIError } from "./APIError";

function detectArray(data, forceArray) {
  data = data || {};
  return forceArray || Array.isArray(data.entries);
}

export const ResponseProcessor = {
  process: function (httpRequest, forceArray, dontUnwrap, rejectQuietly) {
    forceArray = forceArray || false;
    dontUnwrap = dontUnwrap || false;
    rejectQuietly = rejectQuietly || false;

    return new Promise((resolve, reject) => {
      if (!httpRequest) {
        reject("no request");
      } else {
        httpRequest.then(
          function (res) {
            if (res) {
              if (res.data) {
                if (res.status == "200") {
                  if (dontUnwrap) {
                    resolve(res.data);
                  } else {
                    var data = null;
                    if (detectArray(res.data, forceArray)) {
                      data = res.data.entries || [];
                    } else {
                      data = _.omit(res.data || {}, "success", "error", "message", "startRow", "endRow", "entries");
                    }
                    resolve(data);
                  }
                } else {
                  var e = APIError({
                    status: res.status,
                    key: "api.errors.tcc." + res.data.errorId,
                    message: res.data.message,
                    source: "TCC",
                    originalError: res,
                  });
                  reject(e);
                }
              } else {
                if (res.status == "201") {
                  //success with no return data
                  resolve(res.headers);
                } else if (res.status == "202" || res.status == "204") {
                  resolve();
                } else {
                  var e = APIError({
                    status: res.status,
                    message: "No data",
                    source: "TCC",
                    originalError: res,
                  });
                  reject(e);
                }
              }
            }
          },
          function (err) {
            if (rejectQuietly) {
              reject(err);
            } else {
              console.log("err:", err);
              var errorData;
              if (err.data) {
                errorData = APIError({
                  status: err.status,
                  key: "api.errors.tcc." + err.data.errorId,
                  message: err.data.message,
                  source: "TCC",
                  originalError: err,
                });
              } else {
                errorData = APIError({
                  status: err.status,
                  source: "TCC",
                  originalError: err,
                });
              }
              reject(errorData);
            }
          },
        );
      }
    });
  },
};
