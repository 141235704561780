import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import _ from "underscore";

import { IDropdownOption, ICategoryFilterStore } from "../../models/dataModel";
import { metadataConstants } from "../../constants/MetadataConstants";
import { RootContext, RootStore } from "../../stores/rootStore";
import { SearchStore } from "../../stores/searchStore";
import { TranslatedHtml } from "../TranslatedHtml";
import { Dropdown } from "../Dropdown";

/**
 * A component that renders categories filter.
 * @param itemTypeCategoryStore the store used to handle change of itemTypeCategory selection
 */
export const CategoryFilter = observer(
  ({ pageWhereUsed, categoryFilterStore }: { pageWhereUsed?: string; categoryFilterStore?: ICategoryFilterStore }) => {
    const rootStore: RootStore = useContext(RootContext);
    const store: ICategoryFilterStore | SearchStore = categoryFilterStore
      ? categoryFilterStore
      : rootStore.getSearchStore();

    const pageSpecificOptionsClass = pageWhereUsed ? `${pageWhereUsed}` + "-sorting-options" : "";
    const pageSpecificSortClass = pageWhereUsed ? `${pageWhereUsed}` + "-sort-dropdown" : "";

    const categoriesOptions: IDropdownOption[] = [
      {
        value: "all",
        label: rootStore.getTranslation("itemTypeCategories.all"),
      },
    ];
    _.each(metadataConstants.itemTypeCategoriesKeys, (key) => {
      categoriesOptions.push({
        value: key,
        label: rootStore.getTranslation(`itemTypeCategories.${key}`),
      });
    });

    function changeCategory(opt: IDropdownOption | null) {
      if (!opt) return;
      store.setItemTypeCategory(opt);
    }

    return (
      <Fragment>
        <div
          className={classNames("sorting-options category-type-options", pageSpecificOptionsClass)}
          data-testid="sorting-options"
        >
          <div className={classNames("sort-dropdown", pageSpecificSortClass)} data-testid="sort-dropdown">
            <span className="label">
              <TranslatedHtml entry="helpers.filter_by_type" />
            </span>
            <Dropdown
              options={categoriesOptions}
              onChange={changeCategory}
              placeholder={rootStore.getTranslation("placeholders.filter-options.allGroups")}
              className="dropdown-wrapper sort-dropdown-wrapper light"
              selectedValue={store.getItemTypeCategory()}
              inputId="category-select"
            />
          </div>
        </div>
      </Fragment>
    );
  },
);
