import { action, observable, makeObservable } from "mobx";
import _ from "underscore";

import { LocalTsService } from "../../js/services/LocalTsService";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { TreeNode, TreeNodeTypeEnum, TreeViewStore } from "./tree-view/treeViewStore";

export interface ISessionItemGroup {
  items?: TreeNode[];
  loading?: boolean;
  name: string;
  open: boolean;
  tree?: TreeViewStore;
  type: string;
}

export interface IGroupItem {
  id?: string;
  name: string;
  path: string[] | null;
  type: string;
}

/**
 * The item browser store.
 */
export class ItemBrowserStore {
  /** Stores the item groups */
  @observable private itemGroups: Record<string, IGroupItem[]> = {};
  /** Map of allowed item types & their tscatalog info */
  private itemTypeMap = [
    ["tscatalog://boltassembly?item=", "bolt"],
    ["tscatalog://component?item=", "component"],
    ["tscatalog://drawing?item=", "drawing"],
    ["tscatalog://material?item=", "material"],
    ["tscatalog://mesh?item=", "mesh"],
    ["tscatalog://profile?item=", "profile"],
    ["tscatalog://rebar?item=", "rebar"],
    ["tscatalog://shape?item=", "shape"],
  ];
  /** RootStore */
  private rootStore: RootStore;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Loads the items from LocalTsService
   */
  @action
  public async fetchItems(): Promise<ISessionItemGroup[]> {
    try {
      const res = await LocalTsService.getItems({});

      this.itemGroups = _.chain(res.items || [])
        .map((item) => {
          let itemObj: any = null;

          item = (item || "").trim();
          if (item.length > 0) {
            _.map(this.itemTypeMap, (pair: string[]) => {
              const len = pair[0].length;
              if (item.toLowerCase().substring(0, len) === pair[0]) {
                const parts = this.extractItemURIParts(item.substring(len)) || [];
                itemObj = {
                  id: item,
                  type: pair[1],
                  name: _.last(parts),
                  path: _.initial(parts, 1),
                };
              }
            });
          }

          return itemObj;
        })
        .compact()
        .groupBy((item) => {
          return item.type;
        })
        .value();
    } catch {
      console.log("Error occurred while fetching items");
    }

    const sessionItemsGrouped: ISessionItemGroup[] = [];

    _.map(this.itemGroups, (val, key) => {
      const groupItem: ISessionItemGroup = {
        type: key,
        name: "type" + key,
        open: false,
      };

      sessionItemsGrouped.push(groupItem);
    });

    return sessionItemsGrouped;
  }

  /**
   * Function that gathers all export items and sorts them into destination folders.
   * @param items list of items to export
   * @param type type of items
   * @param destinationCollection collection to export items to
   */
  public gatherExports(items: TreeNode[], type: string, destinationCollection: any[]) {
    _.map(items, (child: TreeNode) => {
      if (child.getType() === TreeNodeTypeEnum.FILE && child.isExported()) {
        destinationCollection.push({
          nodeType: TreeNodeTypeEnum.FILE,
          itemType: type,
          reference: child.getNodeId(),
          name: child.getName(),
        });
      } else if (child.getType() === TreeNodeTypeEnum.FOLDER) {
        if (child.isExported()) {
          destinationCollection.push({
            nodeType: TreeNodeTypeEnum.FOLDER,
            itemType: type,
            reference: child.getNodeId(),
            name: child.getName(),
          });
        } else {
          this.gatherExports(child.getChildren(), type, destinationCollection);
        }
      }
    });
  }

  /**
   * Opens a tree view for the given group
   * @param group group to open treeview for
   */
  public openGroupTreeView(group: ISessionItemGroup) {
    const tree = new TreeViewStore(this.rootStore);
    _.each(this.itemGroups[group.type], (itemGroup: IGroupItem) => {
      tree.feed(itemGroup);
    });

    const root = tree.getRoot();
    group.tree = tree;
    group.items = root.getChildren();
  }

  /**
   * Helper function that extracts item uri parts from the full uri
   * @param fullURI full uri string
   * @returns uri parts as a list
   */
  private extractItemURIParts(fullURI: string): string[] {
    const parts = _.map(fullURI.split("/"), (part) => {
      return part.replace("\n", "/");
    });
    return parts;
  }
}

export const ItemBrowserStoreContext = createStoreContext<ItemBrowserStore>(ItemBrowserStore);
