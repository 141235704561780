import * as React from "react";
import { Link } from "react-router";

/**
 * A component that displays Warehouse logo
 */
export const WarehouseLogo = () => {
  return (
    <li>
      <Link to="/landing" className="logo">
        <img src="/images/tekla-warehouse-logo-2020.jpg" alt="Tekla Warehouse" className="show-only-for-IE" />
        <img src="/images/tekla-warehouse-logo-2020.png" alt="Tekla Warehouse" className="hide-from-IE" />
      </Link>
    </li>
  );
};
