import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import _ from "underscore";

import { CollectionPageStore } from "../../collection/collectionPageStore";
import { LocalCollectionPageStore } from "../../local-collection/localCollectionPageStore";
import { PackagePageStore } from "../../package/packagePageStore";
import { RootContext, RootStore } from "../../stores/rootStore";
import { DialogContext, DialogStore } from "../dialogStore";

import { ICollection, IDropdownOption, IItem } from "../../models/dataModel";
import { ObjectTypeEnum } from "../../models/enums";

import { Dropdown } from "../../components/Dropdown";
import { ArchiveDialogContent } from "../../components/immutability/ArchiveButton";
import { getById } from "../../utils/filters";

enum VisibleViewEnum {
  EDIT = "edit",
  MOVE = "move",
  COPY = "copy",
  DELETE = "delete",
  ARCHIVE = "archive",
}

const MoveOrCopyView = observer(
  ({
    item,
    store,
    visibleView,
  }: {
    item: IItem;
    store: LocalCollectionPageStore | CollectionPageStore | PackagePageStore;
    visibleView: VisibleViewEnum;
  }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);

    const itemIsCollection =
      item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION || item.type === ObjectTypeEnum.LOCAL_COLLECTION;

    const [availableCollections, setAvailableCollections] = useState<ICollection[]>([]);
    const [collectionToTransferTo, setCollectionToTransferTo] = useState<ICollection>();
    const [transfering, setTransfering] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    async function init() {
      const collectionsWithTransferRights = await (store as PackagePageStore).getCollectionsWithTransferRights();
      !!collectionsWithTransferRights && setAvailableCollections(collectionsWithTransferRights);

      setLoading(false);
    }

    useEffect(() => {
      if (!itemIsCollection) {
        init();
      }
    }, []);

    function cancel() {
      dialog.close();
    }

    const showDropdown = !loading && !transfering;
    const allowTransfer = !transfering && !!collectionToTransferTo;

    const availableCollectionOptions: IDropdownOption[] = _.map(availableCollections, (collection: ICollection) => {
      return { label: collection.title!, value: collection.id! };
    });

    function handleSelectCollection(option: IDropdownOption | null) {
      if (!option) return;

      const selected = getById(availableCollections, option.value);
      setCollectionToTransferTo(selected);
    }

    function getTitleText(): string {
      return visibleView === VisibleViewEnum.MOVE
        ? rootStore.getTranslation("shared.transfer_package.move_header")
        : rootStore.getTranslation("shared.transfer_package.copy_header");
    }

    function getProcessingText(): string {
      return visibleView === VisibleViewEnum.MOVE
        ? rootStore.getTranslation("shared.transfer_package.moving")
        : rootStore.getTranslation("shared.transfer_package.copying");
    }

    function getButtonText(): string {
      return visibleView === VisibleViewEnum.MOVE
        ? rootStore.getTranslation("shared.transfer_package.actions.move")
        : rootStore.getTranslation("shared.transfer_package.actions.copy");
    }

    async function transferOrCopyEntity() {
      if (!!collectionToTransferTo) {
        setTransfering(true);
        if (visibleView === VisibleViewEnum.MOVE) {
          await (store as PackagePageStore).transferPackage(collectionToTransferTo);
        } else {
          await (store as PackagePageStore).copyPackage(collectionToTransferTo);
        }
        setTransfering(false);
        dialog.close();
      }
    }

    return (
      <article>
        <header>
          <h2>{getTitleText()}</h2>
        </header>
        <section>
          <form name="selectCollectionForm">
            {loading && (
              <label className="spinner spinner-gray" data-testid="spinner">
                {rootStore.getTranslation("shared.transfer_package.loading")}
              </label>
            )}
            {transfering && <label className="spinner spinner-gray">{getProcessingText()}</label>}
            {showDropdown && (
              <Dropdown
                options={availableCollectionOptions}
                onChange={handleSelectCollection}
                placeholder={rootStore.getTranslation("shared.transfer_package.select_collection")}
                className={classNames("dropdown-wrapper gray", {
                  "local-collection": item.isLocal,
                  "online-collection": !item.isLocal,
                })}
              />
            )}
          </form>
        </section>
        <div className="actions">
          <button onClick={cancel} data-testid="cancelTransfer">
            {rootStore.getTranslation("shared.transfer_package.actions.cancel")}
          </button>
          <button
            className="button-primary"
            onClick={transferOrCopyEntity}
            disabled={!allowTransfer}
            data-testid="confirmTransfer"
          >
            {getButtonText()}
          </button>
        </div>
      </article>
    );
  },
);

const DeleteView = observer(
  ({ item, store }: { item: IItem; store: LocalCollectionPageStore | CollectionPageStore | PackagePageStore }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);

    const itemIsCollection =
      item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION || item.type === ObjectTypeEnum.LOCAL_COLLECTION;

    async function confirmDelete() {
      await store.performDelete();
      dialog.close();
    }

    function getConfirmationText() {
      if (itemIsCollection) {
        return rootStore.getTranslation("collections.confirm.delete_all", _.escape(item!.title!));
      } else {
        return rootStore.getTranslation("details.deleteEntity", _.escape(item!.title!));
      }
    }

    function cancel() {
      dialog.close();
    }

    return (
      <Fragment>
        <header>
          <h2>{rootStore.getTranslation("shared.confirm.title")}</h2>
        </header>
        <section>
          <ol className="basic-data">
            <li>
              <span>{getConfirmationText()}</span>
            </li>
          </ol>
        </section>
        <div className="actions">
          <button onClick={cancel} data-testid="cancelDelete">
            {rootStore.getTranslation("shared.confirm.no")}
          </button>
          <button className="button-primary" onClick={confirmDelete} data-testid="confirmDelete">
            {rootStore.getTranslation("shared.confirm.yes")}
          </button>
        </div>
      </Fragment>
    );
  },
);

/**
 * Renders the "Edit title, move or delete" dialog used on collection & package page
 * On collection page, the dialog is "Edit title or delete"
 */
export const EditTitleDialog = observer(
  ({
    item,
    store,
    showArchive,
  }: {
    item: IItem;
    store: LocalCollectionPageStore | CollectionPageStore | PackagePageStore;
    showArchive: boolean;
  }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);
    const translationsStore = item.isLocal
      ? undefined
      : (store as CollectionPageStore | PackagePageStore).getTranslationsStore();

    const showDontNotify = !item!.isLocal;
    const itemIsCollection =
      item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION || item.type === ObjectTypeEnum.LOCAL_COLLECTION;
    const showMoveorCopyEntity = !itemIsCollection && !item.isLocal && !item.isBanned;

    const [visibleView, setVisibleView] = useState<VisibleViewEnum>(VisibleViewEnum.EDIT);
    const [title, setTitle] = useState<string>("");
    const [dontNotify, setDontNotify] = useState<boolean>(false);

    useEffect(() => {
      if (!!item) {
        if (!!translationsStore && !!translationsStore.getTranslationForField("title")) {
          setTitle(translationsStore.getTranslationForField("title"));
        } else {
          setTitle(item.title!);
        }
      }
    }, []);

    function handleChangeTitle(e) {
      setTitle(e.target.value);
    }

    function handleChangeDontNotify() {
      setDontNotify(!dontNotify);
    }

    function renderEditView() {
      async function updateTitle() {
        store.setTitle(title);
        if (item.isLocal) {
          await (store as LocalCollectionPageStore).updateTitle();
        } else {
          await store.updateTitle(dontNotify);
        }
        dialog.close();
      }

      function getDialogTitle(): string {
        if (itemIsCollection) {
          return showArchive
            ? rootStore.getTranslation("immutability.edit_title_or_archive")
            : rootStore.getTranslation("shared.catalog_entity_edit.edit_title_or_delete");
        } else {
          return showArchive
            ? rootStore.getTranslation("immutability.edit_title_move_or_archive")
            : rootStore.getTranslation("shared.catalog_entity_edit.edit_title_or_move_delete");
        }
      }

      return (
        <Fragment>
          <header className="with-top-actions">
            <h2>{getDialogTitle()}</h2>
          </header>
          <section>
            <ol className="basic-data edit-title">
              <li>
                <label>
                  {itemIsCollection
                    ? rootStore.getTranslation("collections.labels.name")
                    : rootStore.getTranslation("upload.title")}
                </label>
                <input type="text" value={title} onChange={handleChangeTitle} data-testid="updateTitleField" />
              </li>
            </ol>
          </section>
          <div className="top-actions">
            {showArchive ? (
              <button
                className="button"
                data-testid={"archiveButtonFor-" + item.id}
                onClick={() => setVisibleView(VisibleViewEnum.ARCHIVE)}
              >
                {rootStore.getTranslation("immutability.archive")}
              </button>
            ) : (
              <button
                className="button-icon"
                onClick={() => setVisibleView(VisibleViewEnum.DELETE)}
                data-testid="deleteItem"
              >
                <span className="icon icon-trash" />
                <span>{rootStore.getTranslation("details.actions.delete")}</span>
              </button>
            )}
            {showMoveorCopyEntity && (
              <Fragment>
                <button
                  className="button-icon"
                  onClick={() => setVisibleView(VisibleViewEnum.MOVE)}
                  data-testid="moveItem"
                >
                  <span className="icon icon-move" />
                  <span>{rootStore.getTranslation("details.actions.move")}</span>
                </button>
                <button
                  className="button-icon"
                  onClick={() => setVisibleView(VisibleViewEnum.COPY)}
                  data-testid="copyItem"
                >
                  <span className="icon icon-copy" />
                  <span>{rootStore.getTranslation("details.actions.copy")}</span>
                </button>
              </Fragment>
            )}
          </div>
          <div className="actions">
            {showDontNotify && (
              <div className="dont-notify">
                <input type="checkbox" id="dontNotify" onChange={handleChangeDontNotify} data-testid="dontNotify" />
                <label htmlFor="dontNotify">
                  {rootStore.getTranslation("shared.catalog_entity_edit.do_not_notify")}
                </label>
              </div>
            )}
            <button onClick={() => dialog.close()} data-testid="cancelEdit">
              {rootStore.getTranslation("actions.cancel")}
            </button>
            <button className="button-primary" onClick={updateTitle} data-testid="updateTitle">
              {itemIsCollection ? rootStore.getTranslation("actions.update") : rootStore.getTranslation("actions.save")}
            </button>
          </div>
        </Fragment>
      );
    }

    return (
      <article data-testid="editTitleDialog">
        {visibleView === VisibleViewEnum.EDIT && renderEditView()}
        {visibleView === VisibleViewEnum.DELETE && <DeleteView item={item} store={store} />}
        {visibleView === VisibleViewEnum.ARCHIVE && <ArchiveDialogContent resource={item} />}
        {(visibleView === VisibleViewEnum.MOVE || visibleView === VisibleViewEnum.COPY) && (
          <MoveOrCopyView item={item} store={store} visibleView={visibleView} />
        )}
      </article>
    );
  },
);
