import { action, observable, runInAction, makeObservable } from "mobx";
import _ from "underscore";
import { DateTime } from "luxon";

import { createStoreContext, RootStore } from "../stores/rootStore";
import { CollectionsStore } from "../stores/collectionsStore";
import { NewRepository } from "../js/services/NewRepository";
import {
  ICollection,
  IComment,
  ICommentFetchResult,
  IEditorModeStrategy,
  IOrganization,
  IOrganizationContact,
  IStringValue,
  IUser,
} from "../models/dataModel";
import { ImageUtils } from "../utils/ImageUtils";
import { FileHandleConverter } from "../utils/converters/FileHandleConverter";
import { TCCOrganizationService } from "../js/services/TCCOrganizationService";

/**
 * A store that contains logic for organization page.
 */
export class OrganizationPageStore extends CollectionsStore implements IEditorModeStrategy {
  /**
   * Current organization.
   */
  @observable private organization: IOrganization | undefined;

  /**
   * Total number of comments (news feeds) for current organization.
   */
  @observable private commentsCount = 0;

  /**
   * Number of comments loaded on all pages of news feed section.
   */
  @observable private commentsLoadedCount = 0;

  /**
   * Comments that user can see in the current news feed page.
   */
  @observable private comments: IComment[] = [];

  /**
   * Number of comments that are loaded and shown on the page at once.
   */
  public static readonly COMMENTS_PER_PAGE: number = 2;

  /**
   * Constructor.
   * @param rootStore Root store.
   */
  public constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Fetches organization data
   */
  @action
  public async fetchData({ organizationId }: { organizationId: string }) {
    this.loading = true;
    this.dataFetched = false;

    try {
      const organization = await NewRepository.getOrganization({
        id: organizationId,
        showBinaryAttributes: true,
      });
      runInAction(() => {
        this.organization = organization;
      });

      await this.setupCommentsAndCollections();

      runInAction(() => {
        this.dataFetched = true;
      });
    } catch {
      console.log("Failed to load organization data");
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  /**
   * Updates organization title
   * @params title new title to be set
   */
  @action
  public async updateTitle(title: string) {
    try {
      if (this.organization && this.getEditor()) {
        await TCCOrganizationService.update(
          this.organization.id,
          {
            displayName: title,
          },
          this.getEditor()?.id,
        );
        runInAction(() => {
          this.organization!.displayName = title;
        });
        this.showUpdateSuccesfullNotification();
      }
    } catch {
      this.showUpdateErrorNotification();
    }
  }

  /**
   * Updates organization description
   * @params description new description to be set
   */
  @action
  public async updateDescription(description: string) {
    try {
      if (!!this.organization && !!this.getEditor()) {
        await TCCOrganizationService.update(
          this.organization.id,
          {
            description: description,
          },
          this.getEditor()?.id,
        );
        runInAction(() => {
          this.organization!.description = description;
        });
        this.showUpdateSuccesfullNotification();
      }
    } catch {
      this.showUpdateErrorNotification();
    }
  }

  /**
   * Updates organization warehouse ulr
   * @params warehouseUrl new url to be set
   */
  @action
  public async updateWarehouseUrl(warehouseUrl: string) {
    try {
      if (!!this.organization && !!this.getEditor()) {
        await TCCOrganizationService.update(
          this.organization.id,
          {
            customWarehouseUrl: warehouseUrl,
          },
          this.getEditor()?.id,
        );
        runInAction(() => {
          this.organization!.customWarehouseUrl = warehouseUrl;
        });
        this.showUpdateSuccesfullNotification();
      }
    } catch (error) {
      this.showUpdateErrorNotification();
    }
  }

  /**
   * Resolves if organization logo exists
   * @returns true if exists
   */
  public get logoExists(): boolean {
    return (
      !!this.organization &&
      !!this.organization.binaries &&
      !!this.organization.binaries.thumbnail &&
      !!this.organization.binaries.thumbnail.contentUrl
    );
  }

  /**
   * Returns the organization logo url
   */
  public getOrganizationLogoUrl(): string | undefined {
    return this.logoExists ? this.organization?.binaries?.thumbnail.contentUrl : undefined;
  }

  /**
   * Adds organization logo
   * @params file the new logo file to be added
   */
  @action
  public async addLogo(file: File) {
    try {
      if (!ImageUtils.isTooBigImage(file)) {
        if (!!this.organization) {
          const binary = FileHandleConverter.fromFilePickerToBinary(file);

          const res: IOrganization = await NewRepository.addThumbnail(
            { ...this.organization, modifier: this.getEditor() } as IOrganization,
            binary,
          );

          runInAction(() => {
            this.organization!.binaries = res.binaries;
          });
          this.showUpdateSuccesfullNotification("organization.edit.logo_updated");
        }
      } else {
        this.showUpdateErrorNotification("collections.notification.selected_image_is_too_big");
      }
    } catch {
      this.showUpdateErrorNotification("organization.edit.logo_update_failed");
    }
  }

  /**
   * Removes organization logo
   */
  @action
  public async deleteLogo() {
    try {
      if (this.organization) {
        const res = await NewRepository.deleteThumbnail(this.organization);
        runInAction(() => {
          if (res && res.binaries) {
            this.organization!.binaries = res.binaries;
          } else {
            this.organization!.binaries = { thumbnail: {} };
          }
        });
        this.showUpdateSuccesfullNotification("organization.edit.logo_removed");
      }
    } catch {
      this.showUpdateErrorNotification("organization.edit.logo_remove_failed");
    }
  }

  /**
   * Checks if link to load previous comments should be shown.
   * @return True if link to load previous comments should be shown, otherwise - false.
   */
  public shouldShowPreviousNewsFeedsLink = (): boolean => {
    return (
      !this.isLoading() &&
      !!this.commentsLoadedCount &&
      this.commentsLoadedCount > OrganizationPageStore.COMMENTS_PER_PAGE
    );
  };

  /**
   * Checks if link to load next comments should be shown.
   * @return True if link to load next comments should be shown, otherwise - false.
   */
  public shouldShowNextNewsFeedsLink = (): boolean => {
    return !this.isLoading() && this.isMoreNewsFeedToLoad();
  };

  /**
   * Formats date to short format.
   * @param dateTime Date to modify.
   * @return Date in short format, if input date was invalid returns string "Invalid date".
   */
  public getModifiedDate = function (dateTime: string) {
    return DateTime.fromJSDate(new Date(dateTime)).toFormat("dd.MM.yyyy");
  };

  /**
   * Adds a comment for the organization.
   * @param commentData comment data
   */
  @action
  public addComment = async (commentData) => {
    this.loading = true;

    try {
      await TCCOrganizationService.addComment(this.organization!.id, commentData);
      setTimeout(async () => await this.handleCommentAdd(), 1200);
      this.showUpdateSuccesfullNotification("organization.edit.comment_added");
    } catch (err) {
      this.showUpdateErrorNotification("organization.edit.comment_add_failed");
    }
  };

  /**
   * Deletes a comment from package.
   * @param commentToDelete comment that is being deleted
   */
  @action
  public deleteComment = async (
    commentToDelete: IComment,
    editor: IOrganization | IUser | undefined,
    stopLoading?: boolean,
  ) => {
    this.loading = true;

    try {
      await TCCOrganizationService.deleteComment(this.organization!.id, commentToDelete.id, commentToDelete.author.id);

      this.showUpdateSuccesfullNotification("organization.edit.comment_deleted");
    } catch (err) {
      this.showUpdateErrorNotification("organization.edit.comment_delete_failed");
    }

    if (stopLoading) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  /**
   * Updates a comment.
   * @param commentData object that contains the new comment data
   * @param commentToEdit comment that is being edited
   */
  @action
  public updateComment = async (commentData, commentToEdit: IComment, editor, stopLoading?: boolean) => {
    this.loading = true;

    try {
      await TCCOrganizationService.updateComment(
        this.organization!.id,
        commentToEdit.id,
        commentData,
        commentToEdit.author.id,
      );
      this.showUpdateSuccesfullNotification("organization.edit.comment_updated");
    } catch {
      this.showUpdateErrorNotification("organization.edit.comment_update_failed");
    }

    if (stopLoading) {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  /**
   * Handles side effects of deleting / editing one of the comments.
   * @param isDeleted True if comment was deleted, false if only edited.
   */
  public handleCommentEdit = async (isDeleted: boolean) => {
    if (isDeleted) {
      setTimeout(async () => await this.resetCommentsListAfterDelete(), 1200);
    } else {
      setTimeout(async () => await this.resetCommentsListAfterEdit(), 1200);
    }
  };

  /**
   * Checks if link to load next comments should be shown.
   * @return True if link to load next comments should be shown, otherwise - false.
   */
  public isMoreNewsFeedToLoad = () => {
    return this.comments && this.commentsCount > this.commentsLoadedCount && this.commentsLoadedCount > 0;
  };

  /**
   * Loads next portion of older comments (news feeds). Requests number of comments that is supposed
   * to be displayed in one page.
   * If response contains less comments than that, it will modify comment counters accordingly
   * and load to the page comments that are available.
   */
  @action
  public loadNextFeeds = async () => {
    this.loading = true;
    const start = this.commentsLoadedCount;
    const searchOptions = {
      offset: start,
      count: OrganizationPageStore.COMMENTS_PER_PAGE,
      sortBy: "createTime DESC",
    };

    try {
      const res = await TCCOrganizationService.getComments(this.organization && this.organization.id, searchOptions);

      runInAction(() => {
        this.loading = false;
        if (res) {
          this.updateCommentsInfo(
            res.entries,
            this.commentsLoadedCount + (res.entries ? res.entries.length : 0),
            res.total,
          );
        }
      });
    } catch {
      runInAction(() => {
        this.loading = false;
      });
      console.log("Error occurred while fetching next comments");
    }
  };

  /**
   * Loads previous portion of newer comments (news feeds). Requests number of comments that is supposed
   * to be displayed in one page.
   * If response contains less comments than that, it will modify comment counters accordingly
   * and load to the page comments that are available.
   */
  @action
  public loadPreviousFeeds = async () => {
    this.loading = true;
    const start = Math.max(
      this.commentsLoadedCount - this.comments.length - OrganizationPageStore.COMMENTS_PER_PAGE,
      0,
    );
    const searchOptions = {
      offset: start,
      count: OrganizationPageStore.COMMENTS_PER_PAGE,
      sortBy: "createTime DESC",
    };

    try {
      const res = await TCCOrganizationService.getComments(this.organization && this.organization.id, searchOptions);

      runInAction(() => {
        this.loading = false;
        if (res) {
          this.updateCommentsInfo(res.entries, this.commentsLoadedCount - this.comments.length, res.total);
        }
      });
    } catch {
      runInAction(() => {
        this.loading = false;
      });
      console.log("Error occurred while fetching previous comments");
    }
  };

  /**
   * Updates contact information to TCCOrganizationService.
   */
  public updateContact = async (contactInfo: IOrganizationContact) => {
    try {
      const contactAttributes = {};
      _.each(contactInfo, (field: IStringValue, key: string) => {
        if (field && field.value) {
          contactAttributes[key] = { dataType: "string", value: field.value };
        }
      });

      await TCCOrganizationService.setAttributes(
        this.organization!.id,
        {
          contact: contactAttributes,
        },
        this.getEditor()?.id,
      );

      this.handleContactEdit(contactInfo);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("organization.edit.updated_successfully"));
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("organization.edit.updated_failed"));
    }
  };

  /**
   * Gets organization
   * @returns organization if set
   */
  public getResource(): IOrganization | undefined {
    return this.getOrganization();
  }

  /**
   * Resolves if translation dialogs should be shown
   */
  public shouldShowTranslationDialogs(): boolean {
    return false;
  }

  /**
   * Resolves if batch edit dialog should be shown
   */
  public shouldShowBatchEditDialog(): boolean {
    return true;
  }

  /**
   * Returns custom warehouse url if set
   */
  public getCustomWarehouseUrl(): string {
    if (!!this.organization && this.organization.customWarehouseUrl) {
      return "/by/" + this.organization.customWarehouseUrl;
    } else {
      return "";
    }
  }

  /**
   * Updates store comments with specified number of comments (news feeds) starting from specified start index.
   * Modifies comment counters and variables accordingly.
   * @param startRow Starting index.
   * @param count Number of comments to load.
   * @param dontUnwrap True if response should not be unwrapped, otherwise - false.
   */
  @action
  public updateNewsFeedByStartAndCount = async (startRow: number, count: number, dontUnwrap = false) => {
    this.loading = true;

    try {
      const res = await this.fetchNewsFeedByStartAndCount(startRow, count, dontUnwrap);

      runInAction(() => {
        if (res) {
          this.updateCommentsInfo(res.entries, startRow + (res.entries ? res.entries.length : 0), res.total);
        }
      });
    } catch {
      console.log("Error occurred while fetching comments");
    }

    runInAction(() => {
      this.loading = false;
    });
  };

  /**
   * Gets current organization.
   * @returns localization store
   */
  public getOrganization(): IOrganization | undefined {
    return this.organization;
  }

  /**
   * Gets total number of comments (news feeds) for current organization.
   */
  public getCommentsCount(): number {
    return this.commentsCount;
  }

  /**
   * Gets number of comments loaded on all pages of news feed section.
   */
  public getCommentsLoadedCount(): number {
    return this.commentsLoadedCount;
  }

  /**
   * Sets number of comments loaded on all pages of news feed section.
   */
  @action
  public setCommentsLoadedCount(value: number) {
    this.commentsLoadedCount = value;
  }

  /**
   * Gets comments that user can see in the current news feed page.
   */
  public getComments(): IComment[] {
    return this.comments;
  }

  /**
   * @return Returns true if current user can edit organization.
   */
  public canEditOrganization = (): boolean => {
    return !!this.organization && this.rootStore.getUserStore().canEditOrganization(this.organization);
  };

  /**
   * Returns the editor
   * * the user, is user belongs to the organization,
   * * the organization, if user is contentEditor for the organization.
   */
  public getEditor(): IUser | IOrganization | undefined {
    if (
      this.rootStore.getUserStore().isUserAdmin() ||
      (!!this.organization && this.rootStore.getUserStore().belongsToOrganization(this.organization))
    ) {
      return this.rootStore.getUserStore().getCurrentUser();
    } else if (!!this.organization && this.rootStore.getUserStore().isContentEditorForOrganization(this.organization)) {
      return this.organization;
    }
  }

  /**
   *
   * Handles side effects of adding the comment.
   */
  private handleCommentAdd = async () => {
    const startRow = 0;
    const count = OrganizationPageStore.COMMENTS_PER_PAGE;
    const dontUnwrap = false;
    await this.updateNewsFeedByStartAndCount(startRow, count, dontUnwrap);
  };

  /**
   * Handles side effects of editing of organization contact.
   */
  @action
  private handleContactEdit = (contact: IOrganizationContact) => {
    if (this.organization && contact) {
      _.extend(this.organization.contact, {
        url: contact.url,
        email: contact.email,
        phonenumber: contact.phonenumber,
        facebook: contact.facebook,
        twitter: contact.twitter,
        linkedin: contact.linkedin,
        youtube: contact.youtube,
      });
    }
  };

  /**
   * Update comments information.
   * @param visibleComments Comments that user can currently see.
   * @param loadedCommentsCount Number of comments loaded.
   * @param totalCount Total available comments count.
   */
  @action
  private updateCommentsInfo = (visibleComments: IComment[], loadedCommentsCount: number, totalCount: number) => {
    this.commentsCount = totalCount;
    this.comments = visibleComments;
    this.commentsLoadedCount = loadedCommentsCount;
  };

  /**
   * Sets up current organization comments and collections, fetches them if needed.
   */
  @action
  private setupCommentsAndCollections = async () => {
    let commentsRes: ICommentFetchResult | undefined;
    const dontUnwrap = true;

    try {
      const collections = await this.fetchCollections();
      commentsRes = await this.fetchNewsFeedByStartAndCount(0, OrganizationPageStore.COMMENTS_PER_PAGE, dontUnwrap);
      runInAction(() => {
        this.collections = collections;
        if (commentsRes) {
          this.updateCommentsInfo(
            commentsRes.entries,
            commentsRes.entries ? commentsRes.entries.length : 0,
            commentsRes.total,
          );
        }
      });
    } catch {
      console.log("Error while fetching organization collections and comments");
    }
  };

  /**
   * Updates comments list after one of the comments has been edited.
   * Modifies comment counters and variables accordingly.
   */
  @action
  private resetCommentsListAfterEdit = async () => {
    const loadedCurrentlyOnTheScreen =
      this.commentsLoadedCount % OrganizationPageStore.COMMENTS_PER_PAGE || OrganizationPageStore.COMMENTS_PER_PAGE;
    await this.updateNewsFeedByStartAndCount(
      this.commentsLoadedCount - loadedCurrentlyOnTheScreen,
      loadedCurrentlyOnTheScreen,
    );
  };

  /**
   * Updates comments list after one of the comments has been deleted.
   * Modifies comment counters and variables accordingly.
   */
  @action
  private resetCommentsListAfterDelete = async () => {
    const newTotalCount = this.commentsCount - 1;

    if (newTotalCount < this.commentsLoadedCount) {
      this.commentsLoadedCount = newTotalCount;
    }

    const visibleCommentsCount =
      this.commentsLoadedCount % OrganizationPageStore.COMMENTS_PER_PAGE || OrganizationPageStore.COMMENTS_PER_PAGE;

    const startRow = Math.max(0, this.commentsLoadedCount - visibleCommentsCount);
    await this.updateNewsFeedByStartAndCount(startRow, visibleCommentsCount);
  };

  /**
   * Fetches collections created by the current organization.
   * @return Collections created by the current organization.
   */
  private fetchCollections = async (): Promise<ICollection[]> => {
    try {
      const result =
        this.organization &&
        (await NewRepository.getCollectionsByCreator(this.organization, true, this.getEditor()?.id));
      if (result) {
        return this.setPaths(result);
      } else return [];
    } catch {
      console.log("Error occurred while fethcing collections");
      return [];
    }
  };

  /**
   * Fetches specified number of comments (news feeds) starting from specified start index.
   * @param startRow Starting index.
   * @param count Number of comments to load.
   * @param dontUnwrap True if response should not be unwrapped, otherwise - false.
   * @return Object recieved from comment fetching request.
   */
  private fetchNewsFeedByStartAndCount = async (
    startRow: number,
    count: number,
    dontUnwrap = false,
  ): Promise<ICommentFetchResult | undefined> => {
    const searchOptions = {
      offset: startRow,
      count: count,
      sortBy: "createTime DESC",
    };

    try {
      if (dontUnwrap) {
        return TCCOrganizationService.getComments(this.organization && this.organization.id, searchOptions, dontUnwrap);
      } else {
        return TCCOrganizationService.getComments(this.organization && this.organization.id, searchOptions);
      }
    } catch {
      return undefined;
    }
  };

  private showUpdateSuccesfullNotification(translationKey?: string): void {
    const msg = translationKey
      ? this.rootStore.getTranslation(translationKey)
      : this.rootStore.getTranslation("organization.edit.updated_successfully");
    this.rootStore.getNotificationChannelStore().success(msg);
  }

  private showUpdateErrorNotification(translationKey?: string): void {
    const msg = translationKey
      ? this.rootStore.getTranslation(translationKey)
      : this.rootStore.getTranslation("organization.edit.update_failed");
    this.rootStore.getNotificationChannelStore().error(msg);
  }
}

/**
 * Context object for OrganizationPageStore instances.
 */
export const OrganizationPageStoreContext = createStoreContext<OrganizationPageStore>(OrganizationPageStore);
