import { RootStore } from "./rootStore";
import { IClient, ILocalService } from "../models/dataModel";
import { LocalServicePoller } from "../js/utils/LocalServicePoller";
import { action, observable, makeObservable } from "mobx";
import _ from "underscore";

/**
 * Store for handling local service related tasks.
 */
export class LocalServiceStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Local service instance
   */
  @observable private localService: ILocalService = {} as any;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    LocalServicePoller.init(this.setLocalService);
  }

  /**
   * Method for getting instance of local service
   * @returns returns instance of local service if available
   */
  public getLocalService(): ILocalService {
    return this.localService;
  }

  /**
   * Method for setting instance of local service
   * @params localService instance of local service
   */
  @action
  public setLocalService = (localService: ILocalService) => {
    this.localService = localService;
  };

  /**
   * Method for checking if local service is accessible
   * @returns true if local service is accessible
   */
  public isLocalServiceAccessible(): boolean {
    const localService = this.getLocalService();
    return localService && localService.isAccessible;
  }

  /**
   * Method for checking if Tekla Structures is accessible
   * @returns true if Tekla Structures is accessible
   */
  public isTSAccessible(): boolean {
    const localService = this.getLocalService();
    return localService && localService.isTsAccessible;
  }

  /**
   * Method for getting local service version
   * @returns returns local service version
   */
  public getLocalServiceVersion(): string {
    const localService = this.getLocalService();
    return localService ? localService.serviceVersion : "";
  }

  /**
   * Returns the list of ts clients that can handle download requests
   * @returns list of clients
   */
  public getDownloadCapableClients(): IClient[] {
    const clients: IClient[] = this.getLocalService().clients;
    return _.filter(clients, (client: IClient) => {
      return !!_.find(client.features, (feature) => {
        return feature === "MassDownloaderAvailable";
      });
    });
  }
}
