import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import _ from "underscore";

import { IDropdownOption } from "../../models/dataModel";
import { ExternalResourceTypeEnum } from "../../models/enums";
import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";
import { UploadCollectionStore } from "./uploadCollectionStore";

import { Dropdown } from "../../components/Dropdown";
import { SetThumbnail } from "../components/SetThumbnail";
import { SetCollectionTitle } from "./SetCollectionTitle";
import { SetVisibility } from "./SetVisibility";

const PleaseSelectOrganizationMessage = () => {
  const rootStore = useContext(RootContext);

  return (
    <div className="error" style={{ width: "199px", marginLeft: "32px" }}>
      <small className="error">{rootStore.getTranslation("upload.collection.organization_missing")}</small>
    </div>
  );
};

/**
 * Component for rendering the "Create as" section of the form.
 */
const CreateAs = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const collection: UploadCollectionStore = form.getCollectionStore();
  const [didSelectOrganization, setDidSelectOrganization] = React.useState(true);

  const creatorIsUser = collection.getCreatorType() === ExternalResourceTypeEnum.USER;
  const creatorIsOrganization = collection.getCreatorType() === ExternalResourceTypeEnum.ORGANIZATION;

  function reactToMouseLeave() {
    if (
      creatorIsOrganization &&
      collection.hasOrganizations() &&
      _.isUndefined(collection.getCreatorOrganizationOption())
    ) {
      setDidSelectOrganization(false);
    }
  }

  useEffect(() => {
    creatorIsUser
      ? collection.setCreatorType(ExternalResourceTypeEnum.USER)
      : collection.setCreatorType(ExternalResourceTypeEnum.ORGANIZATION);
  }, []);

  function setCreatorAsOrganization() {
    collection.setCreatorType(ExternalResourceTypeEnum.ORGANIZATION);
  }

  function setCreatorAsUser() {
    collection.setCreatorType(ExternalResourceTypeEnum.USER);
  }

  function selectOrganization(option: IDropdownOption | null) {
    if (!option) return;
    setDidSelectOrganization(true);
    collection.setCreatorOrganizationOption(option);
  }

  return (
    <div onMouseLeave={reactToMouseLeave}>
      <h3 className="owner-type">{rootStore.getTranslation("collections.labels.createAs")}</h3>
      <ul>
        <div className="radiowrapper-publish">
          {creatorIsUser && (
            <div className="firstgroup">
              <div className="radioactive-publish">
                <div className="radioactivedot-publish" data-testid="asUserActive" />
                <label className="icon icon-user l-inline">
                  {rootStore.getTranslation("collections.labels.asMyself")}
                </label>
              </div>
              {form.isLoading() ? (
                <div data-testid="loadingOrganizations">
                  <div className="spinner"></div>
                </div>
              ) : (
                collection.hasOrganizations() && (
                  <div className="radio-publish" onClick={setCreatorAsOrganization} data-testid="switchToOrganization">
                    <div className="radioactivedot-publish" data-testid="asOrganizationActive" />
                    {collection.getOrganizationOptions().length > 1 ? (
                      <div className="organization-option" data-testid="selectFromUserOrganizations">
                        <Dropdown
                          options={collection.getOrganizationOptions()}
                          placeholder={rootStore.getTranslation("collections.labels.select_organization")}
                          selectedValue={collection.getCreatorOrganizationOption()}
                          onChange={selectOrganization}
                          className={"dropdown-wrapper gray"}
                          isDisabled={true}
                        />
                      </div>
                    ) : (
                      <label className="icon icon-organization l-inline" data-testid="showUserOrganization">
                        {rootStore.getTranslation("collections.labels.asOrganization")}
                        <span className="l-margin-left-default">{collection.getOrganizationName()}</span>
                      </label>
                    )}
                  </div>
                )
              )}
            </div>
          )}
          {creatorIsOrganization && (
            <div className="secondgroup">
              <div className="radio-publish" onClick={setCreatorAsUser} data-testid="switchToUser">
                <label className="icon icon-user l-inline">
                  {rootStore.getTranslation("collections.labels.asMyself")}
                </label>
              </div>
              {collection.hasOrganizations() && (
                <div className="radioactive-publish">
                  <div className="radioactivedot-publish" data-testid="asOrganizationActive" />
                  {collection.getOrganizationOptions().length > 1 ? (
                    <div className="organization-option" data-testid="selectFromUserOrganizations">
                      <Dropdown
                        options={collection.getOrganizationOptions()}
                        placeholder={rootStore.getTranslation("collections.labels.select_organization")}
                        selectedValue={collection.getCreatorOrganizationOption()}
                        onChange={selectOrganization}
                        className={"dropdown-wrapper gray"}
                      />
                    </div>
                  ) : (
                    <label className="icon icon-organization l-inline" data-testid="showUserOrganization">
                      {rootStore.getTranslation("collections.labels.asOrganization")}
                      <span className="l-margin-left-default">{collection.getOrganizationName()}</span>
                    </label>
                  )}
                  {!didSelectOrganization && <PleaseSelectOrganizationMessage />}
                </div>
              )}
            </div>
          )}
        </div>
      </ul>
    </div>
  );
});

/**
 * Component for rendering the "Set description" section of the form.
 */
const SetDescription = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();

  function handleInput(event) {
    event.preventDefault();
    collection.setDescription(event.target.value || "");
  }

  return (
    <Fragment>
      <label htmlFor="description">{rootStore.getTranslation("upload.collection.description_of_the_collection")}</label>
      <textarea
        name="description"
        data-testid="setDescription"
        onChange={handleInput}
        value={collection.getDescription()}
      />
    </Fragment>
  );
});

/**
 * Component for creating new online collections.
 */
export const CreateNewOnlineCollection = observer(() => {
  const rootStore: RootStore = useContext(RootContext);


  return (
    <div data-testid="onlineCollectionForm">
      <header>
        <h2 className="create-collection">
          {rootStore.getTranslation("onlineCollections.collectionCreation.createNewCollection")}
        </h2>
      </header>
      <div className="wrapper as-table">
        <CreateAs />
        <SetVisibility />
        <SetCollectionTitle />
        <SetDescription />
        <SetThumbnail />
      </div>
      <hr />
    </div>
  );
});
