import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "./uploadFormStore";

import { UploadForm } from "./UploadForm";

/**
 * Main component for the Upload page.
 */
export const Upload = observer(() => {
  const store: RootStore = useContext(RootContext);

  return (
    <UploadFormContext.Provider value={new UploadFormStore(store)}>
      <UploadForm />
    </UploadFormContext.Provider>
  );
});
