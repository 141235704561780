import { action, observable, makeObservable } from "mobx";
import { IShoppingCartItem } from "../models/dataModel";
import _ from "underscore";
import { RootStore } from "./rootStore";
import { IRootStoreProvider } from "../models/dataModel";

/**
 * Shopping cart cache, which stores IShoppingCartItem objects.
 */
export class ShoppingCartStoreCache implements IRootStoreProvider {
  /**
   * The name of local storage key where shopping cart items are stored.
   */
  public static readonly LOCAL_STORAGE_KEY = "cart";
  /**
   * An array of shopping cart items, which is synced with local storage,
   * so that i can be observable with mobx.
   */
  @observable private shoppingCartCache: IShoppingCartItem[] = [];

  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.syncWithLocalStorage();
  }

  /**
   * Updates shopping cart items from local storage.
   */
  public syncWithLocalStorage() {
    this.shoppingCartCache = this.getCartItemsFromLocalStorage();
  }

  /**
   * Checks if cartItem is present in shopping cart.
   * @param cartItem Cart item.
   * @returns true if cartItem is present in the cart. Otherwise returns false.
   */
  public isInTheCart(cartItem: IShoppingCartItem) {
    return !!_.find(this.shoppingCartCache, (ci) => ci.id == cartItem.id);
  }

  /**
   * Checks shopping cart size.
   * @returns shopping cart size.
   */
  public getShoppingCartSize() {
    return this.shoppingCartCache.length;
  }

  /**
   * Adds cartItem to shopping cart, unless cartItem is already present in shopping cart.
   * @param cartItem Cart item.
   */
  @action
  public addToCart(cartItem: IShoppingCartItem) {
    this.addAllToCart([cartItem]);
  }

  /**
   * Adds items to shopping cart.
   * @param items Cart items.
   */
  @action
  public addAllToCart(items: IShoppingCartItem[]) {
    const shoppingCartLocalStorage = this.getCartItemsFromLocalStorage();
    _.each(items, (item) => {
      if (!this.isInTheCartInLocalStorage(shoppingCartLocalStorage, item)) {
        shoppingCartLocalStorage.push(item);
      }
    });
    localStorage.setItem(ShoppingCartStoreCache.LOCAL_STORAGE_KEY, JSON.stringify(shoppingCartLocalStorage));
    this.syncWithLocalStorage();
  }

  /**
   * Removes cartItem from shopping cart.
   * @param cartItem Cart item.
   */
  @action
  public removeFromCart(cartItem: IShoppingCartItem) {
    this.removeAllFromCart([cartItem]);
  }

  /**
   * Removes items from shopping cart.
   * @param items Cart items.
   */
  @action
  public removeAllFromCart(items: IShoppingCartItem[]) {
    const idsToRemove = _.uniq(_.map(items, (item) => item.id));
    const cart = JSON.stringify(_.reject(this.getCartItemsFromLocalStorage(), (ci) => _.includes(idsToRemove, ci.id)));
    localStorage.setItem(ShoppingCartStoreCache.LOCAL_STORAGE_KEY, cart);
    this.syncWithLocalStorage();
  }

  /**
   * Returns root store.
   */
  public getRootStore(): RootStore {
    return this.rootStore;
  }

  private getCartItemsFromLocalStorage() {
    const cart = localStorage.getItem(ShoppingCartStoreCache.LOCAL_STORAGE_KEY);
    return cart ? JSON.parse(cart) : [];
  }

  private isInTheCartInLocalStorage(items: IShoppingCartItem[], cartItem: IShoppingCartItem) {
    return !!_.find(items, (ci) => ci.id == cartItem.id);
  }

  public getShoppingCartCache(): IShoppingCartItem[] {
    return this.shoppingCartCache;
  }
}
