import {
  IResultOptionsStrategy,
  ISearchCriteria,
  IEntity,
  IDropdownOption,
  IShoppingCartItem,
  IBatchVersion,
} from "../models/dataModel";
import _ from "underscore";
import { isEntityMatchingSearchTerm, sortArray } from "../utils/functions";
import { RootStore } from "../stores/rootStore";
import { NewRepository } from "../js/services/NewRepository";

/**
 * class that presents strategy to be used for shopping cart page
 */
export class ShoppingCartStrategy implements IResultOptionsStrategy {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /**
   * checks if add button should be displayed
   * @returns true if should display button
   */
  public shouldDisplayAddToBasketButton(): boolean {
    return false;
  }
  /**
   * checks if remove button should be displayed
   * @returns true if should display button
   */
  public shouldDisplayRemoveFromBasketButton(): boolean {
    return true;
  }

  /**
   * checks if mass unlink button should be displayed
   * @returns true if should display button
   */
  public shouldDisplayMassUnlinkButton(): boolean {
    return false;
  }

  /**
   * checks if mass entities should be updated after filtering
   * @returns true if entities should be updated
   */
  public shouldUpdateEntities(): boolean {
    return false;
  }

  /**
   * checks if select all option should be available
   * @param entities list of entities
   * @param versionFilter value of version filter
   * @returns true if can select
   */
  public shouldDisplaySelectAll(entities: IEntity[], versionFilter: IDropdownOption): boolean {
    return versionFilter && entities.length > 0;
  }

  /**
   * checks if entity selection can be made
   * @param entities list of entities
   * @param versionFilter value of version filter
   * @returns true if can select
   */
  public canSelectEntity(entity: IEntity, versionFilter: IDropdownOption): boolean {
    return versionFilter && entity ? true : false;
  }

  /**
   * filters entities based on selection criteria
   * @param searchCriteria wrapper object for search values
   * @param entities list of entities
   * @returns promise of entities
   */
  public async filterEntities(searchCriteria: ISearchCriteria, entities: IEntity[]): Promise<IEntity[]> {
    const filteredEntities = _.chain(entities)
      .filter((entity) => {
        return isEntityMatchingSearchTerm(entity, searchCriteria.searchTerm || "");
      })
      .filter((entity) => {
        if (!!searchCriteria.itemCategoryFilter && searchCriteria.itemCategoryFilter.value !== "all") {
          return _.contains(entity.attributes!.itemTypeCategories!, searchCriteria.itemCategoryFilter.value);
        } else {
          return true;
        }
      })
      .filter((entity) => {
        const filter = searchCriteria.versionFilter ? searchCriteria.versionFilter : null;
        if (filter && filter.value !== "allVersions") {
          return _.any(entity.versions || [], (version: IBatchVersion) => {
            const supportedVersion = _.contains(version.attributes.testedVersions!, filter.value);
            return supportedVersion || _.contains(version.attributes.testedVersions!, "NVS");
          });
        } else {
          return true;
        }
      })
      .value();
    if (this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder) {
      return sortArray(filteredEntities, this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder);
    } else {
      return filteredEntities;
    }
  }

  /**
   * fetched entities added to shopping cart
   * @returns Promise of entity list
   */
  public fetchData(): Promise<IEntity[]> {
    const promises = this.rootStore
      .getShoppingCartStoreCache()
      .getShoppingCartCache()
      .map(async (cartItem: IShoppingCartItem): Promise<IEntity> => {
        const entity: IEntity = (await NewRepository.getPackage(
          {
            id: cartItem.id,
            isLocal: false,
          },
          this.rootStore.getUserStore().getCurrentUser()?.id || "",
        )) as unknown as IEntity;
        entity.versions = await NewRepository.getVersions(entity, false);
        entity.selected = false;
        entity.getEntityPath = function () {
          return "/catalog/details/" + this.id;
        };
        entity.getOrganizationPath = function () {
          return "/organization/" + this.creator!.id;
        };
        return entity;
      });
    return Promise.all(promises);
  }
}
