import _ from "underscore";
import { APIError } from "./APIError";

export const LocalResponseProcessor = {
  process: function (httpRequest, forceArray, dontUnwrap) {
    forceArray = forceArray || false;
    dontUnwrap = dontUnwrap || false;

    function unknownResponse(error) {
      return APIError({
        status: 500,
        message: "No data",
        key: "api.errors.local.unknown",
        source: "local", // TODO
        originalError: error,
      });
    }

    return new Promise((resolve, reject) => {
      if (!httpRequest) {
        reject("no request");
      } else {
        httpRequest.then(
          function (res) {
            if (res) {
              if (res.data) {
                if (res.data.status) {
                  if (res.data.status === "ok") {
                    if (dontUnwrap) {
                      resolve(res.data);
                    } else {
                      resolve(res.data.data);
                    }
                  } else if (res.data.status === "error") {
                    var error = res.data.errors != null && res.data.errors.length > 0 ? _.first(res.data.errors) : null;
                    var apiError;

                    if (
                      _.contains(["CODE:CollectionNotFound", "CODE:PackageNotFound", "CODE:VersionNotFound"], error)
                    ) {
                      apiError = APIError({
                        status: 404,
                        message: "Not found",
                        key: "api.errors.local." + error, // TODO
                        source: "local", // TODO
                        originalError: res,
                      });
                    } else {
                      apiError = APIError({
                        status: 422,
                        message: "Request failed",
                        key: "api.errors.local." + error, // TODO
                        source: "local", // TODO
                        originalError: res,
                      });
                    }
                    reject(apiError);
                  } else {
                    reject();
                  }
                } else {
                  reject();
                }
              } else {
                reject();
              }
            }
          },
          function (err) {
            if (err.status == 404) {
              var apiError = APIError({
                status: 404,
                message: "Tekla Warehouse Service plugin is not available",
                key: "api.errors.local.not_available",
                source: "local", // TODO
                originalError: err,
              });
              reject(apiError);
            } else {
              reject(unknownResponse(err));
            }
          },
        );
      }
    });
  },
};
