import { action, runInAction, observable, computed, makeObservable } from "mobx";
import _ from "underscore";
import { DateTime } from "luxon";

import { NewRepository } from "../../js/services/NewRepository";
import { TCCUserDS } from "../../js/data-source/TCCUserDS";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";

import { IItem, INotification, INotificationPreference, ICollection, IEntity, INotificationAttribute } from "../../models/dataModel";
import { NotifyChannelEnum, NotificationActionEnum, ObjectTypeEnum } from "../../models/enums";
import { metadataConstants } from "../../constants/MetadataConstants";

export class MyAlertsStore extends AbstractAsyncStore {
  /**
   * The current user.
   */
  @observable private user;

  /**
   * Alerts from subscribed items.
   */
  @observable protected notifications: INotification[] = [];

  /**
   * Alerts from subscribed items.
   */
  @observable protected notificationPreferences: INotificationPreference[] = [];

  /**
   * Collections and entities the user has subscribed to.
   */
  @observable protected subscriptions: IItem[] = [];

  /**
   * A flag for sending notifications about subscribed content
   */
  @observable public sendNotificationsToUser = false;

  /**
   * Flag for loading status.
   */
  @observable protected didLoadNotifications = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Fetches the current user from UserStore and loads user notifications from 'TTCUsersDS'.
   */
  @action
  private async loadUser() {
    this.user = this.rootStore.getUserStore().getCurrentUser();

    try {
      const userInformation = await TCCUserDS.me();

      runInAction(() => {
        this.user.notificationPreferences = userInformation.notificationPreferences.twh;
        this.setNotificationPreferences(userInformation.notificationPreferences.twh);
      });
    } catch {
      console.log("Failed to load user.");
    }
  }

  /**
   * Returns true if the notification data finished loading.
   */
  public loadingFinished() {
    return this.didLoadNotifications;
  }

  /**
   * Gets the count of active alerts.
   * @param type type of item (package/collection)
   * @returns a string with active alert count, i.e. "(2 active)"
   */
  public getCountOfActiveAlerts(type: ObjectTypeEnum): string {
    let list;
    if (type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) list = this.contentItemSubscriptions;
    else if (type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) list = this.collectionSubscriptions;

    const activeAlerts = list.filter((item) => {
      return this.rootStore.getNotificationsStore().hasUnreadAlerts(item);
    });
    return this.rootStore.getTranslation("alerts.count_active", activeAlerts.length);
  }

  /**
   * Gets a list of collections subscribed to by the user.
   */
  @computed
  public get collectionSubscriptions(): ICollection[] {
    return this.subscriptions.filter(
      (item) => item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
    ) as ICollection[];
  }

  /**
   * Gets a list of content items subscribed to by the user.
   */
  @computed
  public get contentItemSubscriptions(): IEntity[] {
    return this.subscriptions.filter((item) => item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) as IEntity[];
  }

  /**
   * Returns true if the user has subscriptions.
   * @returns optional amount of subscribers to check
   */
  public hasSubscriptions(amount?: number): boolean {
    if (amount) return this.subscriptions.length > amount;
    else return this.subscriptions.length > 0;
  }

  /**
   * Function that fetches the collection data using 'NewRepository'.
   */
  @action
  public async fetchData() {
    this.loadUser();
    this.didLoadNotifications = false;
    this.loading = true;
    let subs;

    if (this.user) {
      try {
        subs = await NewRepository.getSubscriptions(false, this.user?.id);
        runInAction(() => {
          if (subs) {
            this.subscriptions = subs;
            this.contentItemSubscriptions;
            this.collectionSubscriptions;
          }
        });
      } catch {
        console.log("Failed to fetch subscriptions.");
      }
    }

    runInAction(() => {
      this.didLoadNotifications = true;
      this.loading = false;
    });
  }

  /**
   * Sets preferences for receiving notifications from subscribed items.
   * @param notifications list of notifications to change preferences for
   */
  @action
  public setNotificationPreferences(notificationPreferences: INotificationPreference[]) {
    if (notificationPreferences) {
      const subscribedItemContentAdded = notificationPreferences.filter((notificationPref) => {
        return (
          notificationPref.trigger === "subscribedItemContentAdded" &&
          notificationPref.notify.includes(NotifyChannelEnum.EMAIL)
        );
      });

      if (!_.isEmpty(subscribedItemContentAdded)) {
        this.sendNotificationsToUser = true;
      } else {
        this.sendNotificationsToUser = false;
      }
    }
  }

  /**
   * Finds notifications by what triggers them.
   * @param notificationPreferences list of notification preferences
   * @param triggerName the trigger name
   */
  public findNotificationByTriggerName(notificationPreferences: INotificationPreference[], triggerName: string) {
    const filteredNotifications = notificationPreferences.filter((notification) => {
      return notification.trigger === triggerName;
    });

    if (filteredNotifications && filteredNotifications.length === 1) {
      return filteredNotifications[0];
    }
    return null;
  }

  /**
   * Saves notification preferences from subscribed items.
   * @param notifyByEmail boolean that indicates weather user should be notified by email
   */
  public async saveNotificationPreferences(notifyByEmail: boolean) {
    const notification = this.findNotificationByTriggerName(
      this.user.notificationPreferences,
      "subscribedItemContentAdded",
    );

    if (notification) {
      if (notifyByEmail) {
        notification.notify.push(NotifyChannelEnum.EMAIL);
      } else {
        notification.notify = _.reject(notification.notify, function (notify) {
          return notify === NotifyChannelEnum.EMAIL;
        });
      }

      try {
        await TCCUserDS.setUser(this.user.id, {
          notificationPreferences: { twh: this.user.notificationPreferences },
        });

        this.loadUser();

        this.rootStore
          .getNotificationChannelStore()
          .success(
            this.rootStore.getTranslation("alerts.notification_channel_update.preferences_updated_successfully"),
          );
      } catch {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("alerts.notification_channel_update.preferences_update_failed"));
      }
    }
  }

  /**
   * Resolves the text for a given notification.
   * @param notification notification for which the text is resolved
   */
  public resolveNotificationText(notification: INotification) {
    const localTime = DateTime.fromISO(notification.createTime).toLocal().toRelative() as string;

    if (this.isAddOrDeleteAction(notification.actionType)) {
      const action = this.rootStore.getTranslation("alerts.action_types." + notification.actionType);
      return this.rootStore.getTranslation("alerts.notification_text", action, localTime);
    } else if (this.notificationAttributesExist(notification)) {
      if (notification.delta![0].attributes[0].propertyName === "contentDownloadUrl") {
        console.log("contentDownloadUrl");
        return this.resolveNotificationTextFromAttributes(
          notification.delta![0].attributes,
          this.rootStore.getTranslation("alerts.action_types.added"),
          localTime
        );
      } else {
        console.log("other");
        return this.resolveNotificationTextFromAttributes(
          notification.delta![0].attributes,
          this.rootStore.getTranslation("alerts.action_types." + notification.actionType),
          localTime
        );
      }
    } else if (this.notificationsExist(notification)) {
      const action = this.rootStore.getTranslation("alerts.action_types." + notification.actionType);

      return this.rootStore.getTranslation(
        "alerts.property_change_notification_text",
        notification.delta![0].propertyName,
        action,
        localTime,
      );
    } else {
      return "";
    }
  }

  /**
   * Helper for checking if given action is an add or delete action.
   */
  private isAddOrDeleteAction(actionType: NotificationActionEnum) {
    const addAndDeleteActions = [
      NotificationActionEnum.ADDED_ENTITY,
      NotificationActionEnum.REMOVED_ENTITY,
      NotificationActionEnum.ADDED_COLLECTION,
      NotificationActionEnum.REMOVED_COLLECTION,
      NotificationActionEnum.ADDED_PACKAGE,
      NotificationActionEnum.REMOVED_PACKAGE,
    ];
    return addAndDeleteActions.includes(actionType);
  }

  /**
   * Helper for checking if notification has attributes.
   */
  private notificationAttributesExist(notification: INotification): boolean {
    return !!notification.delta && !!notification.delta[0].attributes && _.any(notification.delta[0].attributes);
  }

  /**
   * Helper for checking if notifications exist.
   */
  private notificationsExist(notification: INotification) {
    return notification.delta && notification.delta[0];
  }

  /**
   * Helper for resolving notification text from attributes.
   */
  private resolveNotificationTextFromAttributes(attributes: INotificationAttribute[], action: string, localTime: string): string {
    const shownCategories: string[] = [];

    const propChanges: string[] = _.map(attributes, (attribute) => {
      let fieldName = "";
      if (metadataConstants.alerts.acceptedAttributePropertyNotifications.includes(attribute.propertyName)) {
        fieldName = this.rootStore.getTranslation("alerts.property_names." + attribute.propertyName);
      } else {
        let categoryName = "";
        _.find(metadataConstants.alerts.acceptedAttributeCategoryNotifications, function (categories, key) {
          if (categories.includes(attribute.categoryName)) {
            categoryName = key;
            return true;
          }
          return false;
        });

        if (categoryName && !shownCategories.includes(categoryName)) {
          fieldName = this.rootStore.getTranslation("alerts.property_names." + categoryName);
          shownCategories.push(categoryName);
        }
      }

      if (!!fieldName) {
        return this.rootStore.getTranslation("alerts.property_change_notification_text", fieldName, action, localTime);
      } else {
        return "";
      }
    });

    return _.chain(propChanges).compact().join("<br/>").value();
  }
}

/**
 * Context object for MyAlertsStore instances.
 */
export const MyAlertsContext = createStoreContext<MyAlertsStore>(MyAlertsStore);
