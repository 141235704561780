/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Fragment, useState, useEffect, useContext } from "react";
import _ from "underscore";

import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";

import { RootContext, RootStore } from "../stores/rootStore";
import { IItem, IBinary, IEntity, IOrganization } from "../models/dataModel";
import { ObjectTypeEnum, BinaryTypeEnum } from "../models/enums";

import { Settings } from "../config/Settings";
import { FileHandleConverter } from "../utils/converters/FileHandleConverter";
import { URLBuilder } from "../utils/URLBuilder";

/**
 * Rich text editor used in
 * * EditTranslations
 * * EditDescriptionDialog
 * * EditDetailsDialog
 * * AddCommentDialog
 * * EditCommentDialog
 * Using https://froala.com/wysiwyg-editor/docs/framework-plugins/react/
 */
export const RichTextEditor = ({
  ...props
}: {
  field: string;
  onChange: (input: string) => void;
  targetItem?: IItem;
  commentSubject?: IEntity | IOrganization;
  value: string;
  disabled?: boolean;
  allowQuickInsert?: boolean;
}) => {
  const rootStore: RootStore = useContext(RootContext);
  const froalaKey = Settings.froala.key;

  const isCommentField = !!props.commentSubject;
  const targetItemIsAPackage = props.targetItem
    ? props.targetItem.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE ||
    props.targetItem.type === ObjectTypeEnum.LOCAL_PACKAGE
    : false;
  const canUploadImages = !!props.targetItem && targetItemIsAPackage && !props.targetItem.isLocal;

  const [editor, setEditor] = useState<any>();

  useEffect(() => {
    if (editor) {
      if (props.disabled) {
        editor.edit.off();
      } else {
        editor.edit.on();
      }
    }
  }, [props.disabled]);

  function resolveFroalaButtonsToShow() {
    let buttons;

    if (isCommentField) {
      buttons = { moreRich: { buttons: ["insertLink"] } };
    } else {
      if (props.field === "description") {
        buttons = { moreText: { buttons: ["bold", "italic", "underline"] } };
      } else if (props.field === "details") {
        buttons = {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
            ],
            buttonsVisible: 3,
          },
          moreParagraph: {
            buttons: ["paragraphFormat", "paragraphStyle", "align", "outdent", "indent", "formatOL", "formatUL"],
            buttonsVisible: 5,
          },
          moreRich: {
            buttons: ["insertLink", "insertImage", "insertVideo", "insertTable", "insertHR"],
          },
          moreMisc: {
            buttons: ["undo", "redo", "selectAll"],
            align: "right",
            buttonsVisible: 3,
          },
        };
      }
    }

    if (props.targetItem && props.targetItem.isLocal) {
      if (props.field === "details") {
        buttons.moreRich.buttons = _.without(buttons.moreRich.buttons, "insertImage");
      }
    }
    return buttons;
  }

  /**
   * Resolves the froala configuration.
   * Allowed options can be found in https://froala.com/wysiwyg-editor/docs/options/
   * Events documentation: https://froala.com/wysiwyg-editor/docs/events/
   */
  function resolveFroalaOptions() {
    const froalaOptions = {
      key: froalaKey,
      attribution: false,
      charCounterMax: isCommentField ? 300 : -1,
      toolbarButtons: resolveFroalaButtonsToShow(),
      toolbarInline: false,
      placeholderText: "",
      fontSizeDefaultSelection: "14",
      fontSize: ["8", "9", "10", "11", "12", "14", "16", "18", "24", "30", "36", "48", "60", "72", "96"],
      requestWithCredentials: true,
      quickInsertEnabled: props.allowQuickInsert ? true : false,
      quickInsertButtons: ["image", "video", "table", "ul", "ol", "hr"],
      htmlAllowedAttrs: [
        "allowfullscreen",
        "title",
        "href",
        "width",
        "height",
        "frameborder",
        "alt",
        "src",
        "style",
        "rel",
        "target",
      ],
      events: {
        initialized: function () {
          setEditor(this);
          if (props.disabled) {
            (this as any).edit.off();
          }
        },
      },
    };

    if (!!props.targetItem) {
      if (props.field === "details") {
        const uploadParams = {
          subjectClass: "collection",
          subjectId: props.targetItem.id,
        };

        _.extend(froalaOptions, {
          imageUploadParam: "binary",
          imageManagerDeleteURL: canUploadImages ? URLBuilder.buildWarehouseURL("/warehouse/v1.0/packages/") : "",
          imageManagerDeleteParams: canUploadImages ? uploadParams : null,
          videoInsertButtons: ["videoByURL", "videoEmbed"],
        });

        _.extend(froalaOptions.events, {
          "image.beforeUpload": function (images: File[]) {
            beforeImageUpload(this, images);
          },
          "image.uploaded": function () {
            setTimeout(() => parseImageResponse(this), Settings.tcc.defaultTimeout);
            return false;
          },
          "video.linkError": function () {
            rootStore
              .getNotificationChannelStore()
              .error(rootStore.getTranslation("shared.catalog_entity_edit.edit_content_video_link_error"));
          },
          "video.codeError": function () {
            rootStore
              .getNotificationChannelStore()
              .error(rootStore.getTranslation("shared.catalog_entity_edit.edit_content_vide_code_error"));
          },
        });
      }
    }

    return froalaOptions;
  }

  /** Converts imageFile to binary, returns an object containing binary attributes */
  function binaryAttributesConverter(imageFile, isImage) {
    const binary: IBinary = FileHandleConverter.fromFilePickerToBinary(imageFile);
    binary.attributes!.type = isImage ? BinaryTypeEnum.IMAGE : BinaryTypeEnum.VIDEO;

    const attributesForTCC = {
      metadata: {
        platform: {
          dataType: "string",
          value: "" + binary.attributes!.platform,
        },
        type: { dataType: "string", value: "" + binary.attributes!.type },
        itemType: {
          dataType: "string",
          value: "" + binary.attributes!.itemType,
        },
        itemSource: {
          dataType: "string",
          value: "" + binary.attributes!.itemSource,
        },
        location: { dataType: "string", value: "" + binary.location },
      },
    };

    return {
      dto: {
        name: binary.reference,
        originalFileName: binary.attributes!.originalName,
        fileSize: binary.attributes!.fileSize,
        isMetadata: true,
        attributes: attributesForTCC,
      },
    };
  }

  /**
   * Triggered before image upload.
   * @param editor the froala editor instance
   * @param images uploaded image files
   */
  async function beforeImageUpload(editor, images: File[]) {
    if (images !== undefined && images.length > 0) {
      const binaryAttributes = binaryAttributesConverter(images[0], true);
      const uploadParams = {
        dto: JSON.stringify(binaryAttributes.dto),
        file: images[0],
      };

      _.extend(editor.opts, {
        imageUploadParams: uploadParams,
        imageUploadURL: URLBuilder.buildWarehouseURL("/warehouse/v1.0/packages/") + props.targetItem!.id + "/binaries",
      });
    }
  }

  /**
   * Triggered before video upload.
   * @param editor the froala editor instance
   * @param files uploaded video files
   */
  async function beforeVideoUpload(editor, files: File[]) {
    if (files !== undefined && files.length > 0) {
      const binaryAttributes = binaryAttributesConverter(files[0], false);

      const uploadParams = {
        dto: JSON.stringify(binaryAttributes.dto),
        file: files[0],
      };

      _.extend(editor.opts, {
        videoUploadMethod: "POST",
        videoUploadParams: uploadParams,
        videoUploadURL: URLBuilder.buildWarehouseURL("/warehouse/v1.0/packages/") + props.targetItem!.id + "/binaries",
      });
    }
  }

  /**
   * Triggered after the image was uploaded.
   * @param editor the froala editor instance
   */
  function parseImageResponse(editor) {
    const params = JSON.parse(editor.opts.imageUploadParams.dto);
    const binaryUrl = editor.opts.imageUploadURL + "/" + params.name;
    const thumbnailInfo = { reference: params.name };

    if ((props.targetItem as IEntity).thumbnails_details) {
      (props.targetItem as IEntity).thumbnails_details!.push(thumbnailInfo);
    } else {
      _.extend(props.targetItem as IEntity, {
        thumbnails_details: [thumbnailInfo],
      });
    }

    fetch(binaryUrl, {
      method: "GET",
      headers: editor.opts.requestHeaders,
      credentials: editor.opts.requestWithCredentials ? "include" : "omit",
    })
      .then((response) => response.json())
      .then(function (result) {
        editor.image.insert(
          result.url,
          true,
          {
            name: params.name,
            id: params.name,
          },
          editor.image.get(),
        );
      });
  }

  /**
   * Triggered after the image was uploaded.
   * @param editor the froala editor instance
   */
  function parseVideoResponse(editor) {
    const params = JSON.parse(editor.opts.videoUploadParams.dto);
    const binaryUrl = editor.opts.videoUploadURL + "/" + params.name;
    const thumbnailInfo = { reference: params.name };

    if ((props.targetItem as IEntity).thumbnails_details) {
      (props.targetItem as IEntity).thumbnails_details!.push(thumbnailInfo);
    } else {
      _.extend(props.targetItem as IEntity, {
        thumbnails_details: [thumbnailInfo],
      });
    }

    fetch(binaryUrl, {
      method: "GET",
      headers: editor.opts.requestHeaders,
      credentials: editor.opts.requestWithCredentials ? "include" : "omit",
    })
      .then((response) => response.json())
      .then(function (result) {
        console.log(result);
        editor.video.insert(
           
          `<video data-testid="${result.id}" controls><source src={${result.url}} type={video/${result.ext}} /></video>`,
        );
      });
  }

  return (
    <Fragment>
      <FroalaEditor config={resolveFroalaOptions()} model={props.value} onModelChange={props.onChange} />
    </Fragment>
  );
};
