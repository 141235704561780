import * as React from "react";
import { useContext } from "react";

import { RootContext, RootStore } from "../stores/rootStore";
import { SearchWithAutocompleteStore, SearchWithAutocompleteStoreContext } from "../stores/searchWithAutocompleteStore";
import { SearchWithAutocomplete } from "../components/SearchWithAutocomplete";

/**
 * Search component for header
 */
export const HeaderSearch = () => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <SearchWithAutocompleteStoreContext.Provider value={new SearchWithAutocompleteStore(rootStore)}>
      <li className="header-search-bar">
        <section className="search" data-testid="header-search-area">
          <SearchWithAutocomplete cssClassName="input-search-header icon-search" />
        </section>
      </li>
    </SearchWithAutocompleteStoreContext.Provider>
  );
};
