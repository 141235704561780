import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import _ from "underscore";
import { v4 as uuid } from "uuid";

import {
  I3DGeometryFile,
  IDropdownOption,
  IEntity,
  IFile,
  IFileItem,
  IVersion,
  IVersionPrerequisite,
} from "../../models/dataModel";
import { ItemSourceEnum, ItemTypeEnum, PlatformEnum } from "../../models/enums";
import { RootStore } from "../../stores/rootStore";
import { UploadFormStore } from "../uploadFormStore";

import { metadataConstants } from "../../constants/MetadataConstants";
import { FileHandleConverter } from "../../utils/converters/FileHandleConverter";
import { isValidDownloadURL } from "../../utils/functions";
import { isValidVersionNumber } from "../../utils/Immutability";
import { ScanResultsChecker } from "../../utils/ScanResultsChecker";

export enum VersionUploaderViewState {
  FILES = "files",
  APPLICATION = "application",
  FILES_FROM_TS = "filesFromTS",
  GEOMETRY_FILE = "3DGeometryFile",
  PARTNER_DOWNLOAD_LINK = "partnerDownloadLink",
  EXISTING_FILES = "existingFiles",
}

export interface IVersionFormData {
  attributes: {
    compatibleOperatingSystems: string[];
    compatibleSoftwareProducts: string[];
    licensesACL?: string[];
    measurementUnits: string[];
    prerequisiteDescription: string;
    productCode: string;
    productExpirationDate: Date;
    testedVersions: string[];
    isTool: boolean;
    versionNumber: string;
  };
  isLocal?: boolean;
  package?: IEntity;
  prerequisites: IVersionPrerequisite[];
  tags?: string[];
  title: string;
  visibility?: string;
}

export class UploadVersionStore {
  /** RootStore */
  private rootStore: RootStore;

  /** Store that holds upload form data */
  private form: UploadFormStore;

  /** Current version view state */
  @observable private viewState = VersionUploaderViewState.FILES;

  /** Maximum file size for user files */
  private MAX_FILE_SIZE = 524288000;

  /**
   * Object that stores the version form data.
   */
  @observable private version: IVersionFormData = {
    attributes: {
      compatibleOperatingSystems: [] as string[],
      compatibleSoftwareProducts: [] as string[],
      isTool: false,
      measurementUnits: [] as string[],
      prerequisiteDescription: "",
      productExpirationDate: null as unknown as Date,
      productCode: "",
      testedVersions: [] as string[],
      versionNumber: "",
    },
    title: "",
    prerequisites: [] as IVersionPrerequisite[],
  };

  @observable private versionInEdit?: IVersion;

  /** List of files */
  @observable private files = {
    selectedFiles: [] as IFile[],
    selectedTools: [] as IFile[],
    selectedFilesForRemoval: [] as Array<IFile | I3DGeometryFile | IFileItem>,
    ThreeDGeometryFiles: [] as I3DGeometryFile[],
    existingFiles: [] as IFileItem[],
  };

  /** Should display list of current binaries */
  @computed
  public get displayCurrentBinaries(): boolean {
    return this.files.existingFiles.length > 0;
  }

  /** Flag for marking when file selection should trigger */
  @observable private fileSelectionActive = false;

  /** Selected tested version options */
  @observable private selectedTestedVersions: IDropdownOption[] = [];

  /** Selected compatible operating system options */
  @observable private selectedCompatibleOperatingSystems: IDropdownOption[] = [];

  /** Selected compatible software product options */
  @observable private selectedCompatibleSoftwareProducts: IDropdownOption[] = [];

  /** Selected measurement unit options */
  @observable private selectedMeasurementUnits: IDropdownOption[] = [];

  /** List of user created custom attributes for 3D geometry files */
  @observable private customAttributesFor3DGeometryFile: string[] = [];

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore, form: UploadFormStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.form = form;

    runInAction(() => {
      _.extend(this.version, { isLocal: form.isLocal() });
    });
  }

  /** Returns the version object */
  public getVersion(): IVersionFormData {
    return this.version;
  }

  /** Returns the version in edit. */
  public getVersionInEdit(): IVersion | undefined {
    return this.versionInEdit;
  }

  /** Sets the selected version for editing. */
  @action
  public setVersionForEditing(version?: IVersion) {
    this.versionInEdit = version;
    this.version = version as IVersionFormData;
    this.files.existingFiles = version ? (version.binaries as IFileItem[]) : [];
  }

  /** Returns the current version view state */
  public getViewState(): VersionUploaderViewState {
    return this.viewState;
  }

  /** Sets the current version view state */
  @action
  public setViewState(selected: VersionUploaderViewState) {
    this.viewState = selected;
  }

  /** Returns weather the file selection dialog should open when triggered. */
  public canSelectFiles(): boolean {
    return this.fileSelectionActive;
  }

  /** Defines weather the file selection dialog should open when triggered. */
  @action
  public setCanSelectFiles(active: boolean) {
    this.fileSelectionActive = active;
  }

  /**
   * Returns dropdown options for types of content user can add to the version.
   */
  @computed
  public get contentTypeOptions(): IDropdownOption[] {
    const options = [
      {
        value: VersionUploaderViewState.FILES,
        label: this.rootStore.getTranslation("uploader.addContent.select_files"),
      },
      {
        value: VersionUploaderViewState.APPLICATION,
        label: this.rootStore.getTranslation("uploader.addContent.addToolButton"),
      },
      {
        value: VersionUploaderViewState.GEOMETRY_FILE,
        label: this.rootStore.getTranslation("uploader.addContent.3dGeometryButton"),
      },
      {
        value: VersionUploaderViewState.PARTNER_DOWNLOAD_LINK,
        label: this.rootStore.getTranslation("uploader.addContent.partnerDownloadLink"),
      },
    ];

    if (this.rootStore.getLocalServiceStore().isLocalServiceAccessible()) {
      options.push({
        value: VersionUploaderViewState.FILES_FROM_TS,
        label: this.rootStore.getTranslation("uploader.addContent.select_files_from_ts"),
      });
    }
    return options;
  }

  /**
   * Returns item type options for given file, taking into account the file type.
   * */
  public getItemTypeOptionsForFile(file: IFile): IDropdownOption[] {
    const options = metadataConstants.contentItemKeys.map((key) => {
      return {
        value: key,
        label: this.rootStore.getTranslation("contentItemSource." + key),
      };
    });

    const type = this.getFileExtensionFromFileName(file.name);
    if (file.itemSource === ItemSourceEnum.EXPORTED_FROM_TS) {
      return _.compact([_.first(this.pickOptions(options, [file.type]))]);
    } else {
      const fileTypeOptions = this.getFileTypeOptions();
      return this.findOptionsRecursively(type, fileTypeOptions, this.getDefaultFileTypeOptions());
    }
  }

  /**
   * Parses through available file type options and assigns them to file types.
   */
  private getFileTypeOptions(): Record<string, IDropdownOption[]> {
    const options = metadataConstants.contentItemKeys.map((key) => {
      return {
        value: key,
        label: this.rootStore.getTranslation("contentItemSource." + key),
      };
    });

    let optionsForFileTypes: Record<string, IDropdownOption[]> = {
      msi: this.pickOptions(options, ["run", "other"]),
      tsep: this.pickOptions(options, ["tsep"]),
      zip: this.pickOptions(options, ["other", "modeltemplate", "cloningtemplate"]),
      bass: this.pickOptions(options, ["bolt"]),
      uel: this.pickOptions(options, ["profile", "component", "template"]),
      lis: this.pickOptions(options, ["profile", "material"]),
      clb: this.pickOptions(options, ["profile"]),
      "rules.lis": this.pickOptions(options, ["profile"]),
      "profiles.lis": this.pickOptions(options, ["profile"]),
      "profiles.clb": this.pickOptions(options, ["profile"]),
      "profiles.uel": this.pickOptions(options, ["profile"]),
      tsds: this.pickOptions(options, ["drawing"]),
      mexp: this.pickOptions(options, ["mesh"]),
      rexp: this.pickOptions(options, ["rebar"]),
      tsc: this.pickOptions(options, ["shape"]),
      xml: this.pickOptions(options, ["geometry", "other"]),
      pdf: this.pickOptions(options, ["other"]),
      txt: this.pickOptions(options, ["other"]),
      doc: this.pickOptions(options, ["other"]),
      docx: this.pickOptions(options, ["other"]),
      rtf: this.pickOptions(options, ["other"]),
      csv: this.pickOptions(options, ["other"]),
    };
    if (this.form.isLocal()) {
      optionsForFileTypes = _.omit(optionsForFileTypes, "rtf");
    }
    return optionsForFileTypes;
  }

  /**
   * Sets default file type options for available file types.
   */
  private getFileTypeOptionDefaults() {
    return {
      msi: this.pickOption(this.getFileTypeOptions().msi, "run"),
      tsep: this.pickOption(this.getFileTypeOptions().tsep, "tsep"),
      zip: this.pickOption(this.getFileTypeOptions().zip, "other"),
      bass: this.pickOption(this.getFileTypeOptions().bass, "bolt"),
      uel: this.pickOption(this.getFileTypeOptions().uel, "component"),
      lis: this.pickOption(this.getFileTypeOptions().lis, "profile"),
      clb: this.pickOption(this.getFileTypeOptions().clb, "profile"),
      "rules.lis": this.pickOption(this.getFileTypeOptions()["rules.lis"], "profile"),
      "profiles.lis": this.pickOption(this.getFileTypeOptions()["profiles.lis"], "profile"),
      "profiles.clb": this.pickOption(this.getFileTypeOptions()["profiles.clb"], "profile"),
      "profiles.uel": this.pickOption(this.getFileTypeOptions()["profiles.uel"], "profile"),
      tsds: this.pickOption(this.getFileTypeOptions().tsds, "drawing"),
      mexp: this.pickOption(this.getFileTypeOptions().mexp, "mesh"),
      rexp: this.pickOption(this.getFileTypeOptions().rexp, "rebar"),
      tsc: this.pickOption(this.getFileTypeOptions().tsc, "shape"),
      xml: this.pickOption(this.getFileTypeOptions().xml, "geometry"),
      pdf: this.pickOption(this.getFileTypeOptions().pdf, "other"),
      doc: this.pickOption(this.getFileTypeOptions().doc, "other"),
      docx: this.pickOption(this.getFileTypeOptions().docx, "other"),
      rtf: this.pickOption(this.getFileTypeOptions().rtf, "other"),
      txt: this.pickOption(this.getFileTypeOptions().txt, "other"),
      csv: this.pickOption(this.getFileTypeOptions().csv, "other"),
    };
  }

  /**
   * Returns a list of default file type options.
   */
  public getDefaultFileTypeOptions() {
    const options = metadataConstants.contentItemKeys.map((key) => {
      return {
        value: key,
        label: this.rootStore.getTranslation("contentItemSource." + key),
      };
    });
    return this.pickOptions(options, ["other", "run", "noaction"]);
  }

  /** Helper method for picking options for file types */
  private pickOptions(options, names) {
    options = options || [];
    names = names || [];
    return _.select(options, function (option) {
      return _.find(names, function (name) {
        return name === option.value;
      });
    });
  }

  /** Helper method for picking a single option from list of options */
  private pickOption(options, name) {
    const names = name ? [name] : [];
    return _.first(this.pickOptions(options, names));
  }

  /**
   * Handles the file validation and assigns them to correct lists.
   * @param files list of files selected by user
   * @param fileType the file type
   */
  @action
  public selectFiles(selectedFiles: any[], fileType: string) {
    let parser;
    let saveTo;
    const fileTypeBlackList = ["text/html", "image/svg+xml"];

    if (fileType === "selectedTools") {
      this.version.attributes.isTool = true;
    } else {
      this.version.attributes.isTool = false;
    }

    if (fileType === "filesFromTS") {
      parser = FileHandleConverter.fromTS;
      saveTo = "selectedFiles";
    } else {
      parser = FileHandleConverter.fromFilePicker;
      saveTo = fileType;
    }

    const filteredFiles = _.reject(selectedFiles || [], (file) => {
      if (!!file.size && file.size > this.MAX_FILE_SIZE) {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("upload.version.file_is_too_big", _.escape(file.name)));
        return true;
      }
      if (_.contains(fileTypeBlackList, file.type)) {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("upload.version.unallowed_file_type", _.escape(file.name)));
        return true;
      }
      return false;
    });

    let newFiles: IFile[] = [];
    if (parser === FileHandleConverter.fromTS) {
      newFiles = _.map(filteredFiles || [], (file) => {
        return parser(file, file.itemType);
      });
    } else {
      newFiles = _.map(filteredFiles || [], (file) => {
        const defaultSelectedOption = this.pickOptions(this.getDefaultFileTypeOptions(), ["other"])[0];
        const key = this.getFileExtensionFromFileName(file.name);
        const itemType = this.findOptionsRecursively(key, this.getFileTypeOptionDefaults(), defaultSelectedOption);
        return parser(file, itemType.value);
      });
    }

    if (fileType === "selectedTools") {
      const filesWithPlatforms: IFile[] = [];

      newFiles = _.map(newFiles, (tool: IFile) => {
        const allFilesToCheck = [...this.getSelectedTools(), ...this.getExistingFiles(), ...filesWithPlatforms];

        const thereIsAlreadyAnotherToolWithThisPlatform = _.some(allFilesToCheck, (existingTool: IFile | IFileItem) => {
          const toolName = (existingTool as IFile).name || (existingTool as IFileItem).attributes?.fileName;
          const toolPlatform = (existingTool as IFile).platform || (existingTool as IFileItem).attributes?.platform;

          return (this.toolWithPlatformExists(toolPlatform) || _.some(filesWithPlatforms, (newFile: IFile) => newFile.platform === toolPlatform)) && (toolName !== tool.name);
        });

        if (thereIsAlreadyAnotherToolWithThisPlatform) {
          const filteredPlatformOptions = metadataConstants.platformKeys.map((key: string) => {
            return { value: key, label: this.rootStore.getTranslation("platforms." + key) };
          }).filter((option: IDropdownOption) => {
            return !this.toolWithPlatformExists(option.value) && !_.some(filesWithPlatforms, (newFile: IFile) => newFile.platform === option.value)
          });

          const unSelectedPlatform = _.find(filteredPlatformOptions, (option: IDropdownOption) => {
            const selectedPlatforms: string[] = _.map(allFilesToCheck, (existingTool: IFile | IFileItem) => (existingTool as IFile).platform || (existingTool as IFileItem).attributes?.platform || "");
            return !selectedPlatforms.includes(option.value)
          });

          const toolWithPlatformSet = { ...tool, platform: filteredPlatformOptions.length > 0 ? unSelectedPlatform?.value as PlatformEnum : undefined }
          filesWithPlatforms.push(toolWithPlatformSet);
          return toolWithPlatformSet;
        } else {
          filesWithPlatforms.push(tool);
          return tool;
        }
      });
    }

    this.files[saveTo] = this.files[saveTo].concat(newFiles);

    this.files[saveTo] = _.chain(this.files[saveTo])
      .uniq(false, (file) => file._id)
      .value();

    this.setCanSelectFiles(false);
  }

  /**
   * Parses the file extension from file name, for example 'file.zip' -> 'zip'
   * @param name Name of the file
   */
  public getFileExtensionFromFileName(name: string): string {
    name = name || "";
    const fragments = name.split(".");
    fragments.shift();
    return fragments.join(".");
  }

  /** Private helper for finding options recursively from the options map */
  private findOptionsRecursively(key, optionsMap, optionsDefaults) {
    if (!key || !optionsMap || !optionsDefaults) {
      return [];
    } else {
      if (optionsMap[key]) {
        return optionsMap[key];
      } else {
        const newKey = this.getFileExtensionFromFileName(key);
        if (newKey === "") {
          return optionsDefaults;
        } else {
          return this.findOptionsRecursively(newKey, optionsMap, optionsDefaults);
        }
      }
    }
  }

  /** Returns a list of selected applications/tools */
  public getSelectedTools(): IFile[] {
    return this.files.selectedTools;
  }

  /** Returns the platform for a specific tool */
  public getPlatformForTool(toolName: string): string {
    const tool: IFile | undefined = _.find(this.files.selectedTools, (tool: IFile) => tool.name === toolName);
    return !!tool && !!tool.platform ? tool.platform : "";
  }

  /**
   * Sets a platform for a tool.
   * @param toolName name of the tool/application
   * @param selectedPlatform selected platform
   */
  @action
  public setPlatformForTool(toolName: string, selectedPlatform: PlatformEnum) {
    _.each(this.files.selectedTools, (tool: IFile) => {
      if (tool.name === toolName) {
        tool.platform = selectedPlatform;
      }
    });
  }

  /**
   * Checks if a tool with a specific platform exists.
   * @param platform platform to check
   */
  public toolWithPlatformExists(platform: string | PlatformEnum | undefined): boolean {
    return _.some(this.getSelectedTools(), (tool: IFile) => {
      return this.getPlatformForTool(tool.name) === platform;
    }) || _.some(this.getExistingFiles(), (file: IFileItem) => {
      return file.attributes!.platform === platform;
    });
  }

  /**
   * Returns the file type for a specific file.
   * @param fileId id of the file
   */
  public getFileType(fileId: string): string {
    const file: IFile | undefined = _.find(this.files.selectedFiles, (file: IFile) => {
      return file.id === fileId;
    });
    return !!file && !!file.type ? file.type : "";
  }

  /**
   * Sets a type for a specific file and saves it to 'selectedFiles'.
   * @param fileId id of the file
   * @param type Selected filetype
   */
  @action
  public setTypeForFile(fileId: string, type: ItemTypeEnum) {
    _.each(this.files.selectedFiles, (file: IFile) => {
      if (file.id === fileId) {
        file.type = type;
      }
    });
  }

  /** Returns a list of selected files. */
  public getSelectedFiles(): IFile[] {
    return this.files.selectedFiles;
  }

  /** Sets selected files. */
  @action
  public setSelectedFiles(files: IFile[]) {
    this.files.selectedFiles = files;
  }

  /** Returns the version number */
  public getVersionNumber(): string {
    return this.version.attributes.versionNumber;
  }

  /** Sets the version number. */
  @action
  public setVersionNumber(versionNumber: string) {
    this.version.attributes.versionNumber = versionNumber;
  }

  /** Returns 'true' if the version is a tool */
  public isTool(): boolean {
    return this.version.attributes.isTool;
  }

  /**
   * Gets the file size from bytes.
   * @param bytes The size of the file.
   * @param precision Optional percision value.
   * @returns The size as a string (e.g. 1 MB).
   */
  public getFileSize(bytes, precision?): string {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "n/a";
    if (parseInt(bytes) == 0) return "0 bytes";
    if (typeof precision === "undefined") {
      precision = bytes < 1024 ? 0 : 1;
    }
    const units = [
      this.rootStore.getTranslation("units.bytes"),
      this.rootStore.getTranslation("units.kB"),
      this.rootStore.getTranslation("units.MB"),
      this.rootStore.getTranslation("units.GB"),
      this.rootStore.getTranslation("units.TB"),
      this.rootStore.getTranslation("units.PB"),
    ],
      number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + " " + units[number];
  }

  /**
   * Removes the selected file from 'files'
   * @param file File to be removed
   * @param fileType The type of file removed
   */
  @action
  public removeSelectedFile(file: IFile | I3DGeometryFile | IFileItem, fileType: string) {
    this.files[fileType] = _.without(this.files[fileType], file);
    this.files.selectedFilesForRemoval.push(file);
  }

  /**
   * Creates a 3D geometry file from given object.
   * @param file I3DGeometryFile
   */
  @action
  public create3DGeometryFile(file: I3DGeometryFile) {
    this.files.ThreeDGeometryFiles = this.files.ThreeDGeometryFiles.concat([file]);
  }

  /**
   * Adds a blank 3D geometry file.
   */
  @action
  public createBlank3DGeometryFile() {
    // we need to provide at least these properties here (geometryFile, thumbnailFile) so that mobx could track changes
    // and trigger form re-evaluation
    this.create3DGeometryFile({
      geometryFile: null,
      id: uuid(),
      material: "",
      description: "",
      productCode: "",
      productWeight: 0,
      thumbnailFile: null,
      title: "",
      weightUnit: "kg",
    });
  }

  /**
   * Returns a list of existing 3D geometry files.
   */
  public get3DGeometryFiles() {
    return this.files.ThreeDGeometryFiles;
  }

  /** Returns a list of user created custom attributes for 3D geometry files. */
  public getCustomAttributesFor3DGeometryFile() {
    return this.customAttributesFor3DGeometryFile;
  }

  /**
   * Adds a new custom attribute to the 3D geometry file form.
   * @param name string with a custom attribute name
   */
  @action
  public add3DGeometryFileFields(name: string) {
    this.customAttributesFor3DGeometryFile.push(name);
    if (this.files.ThreeDGeometryFiles) {
      this.files.ThreeDGeometryFiles.map((file: I3DGeometryFile) => (file = _.extend(file, { [name]: undefined })));
    }
  }

  /**
   * Removes the named custom attribute from the 3D geometry file form.
   * @param name string with a custom attribute name
   */
  @action
  public remove3DGeometryFileFields(name: string) {
    this.customAttributesFor3DGeometryFile = _.without(this.customAttributesFor3DGeometryFile, name);
    if (this.files.ThreeDGeometryFiles) {
      this.files.ThreeDGeometryFiles.map((file: I3DGeometryFile) => _.omit(file, name));
    }
  }

  /**
   * Edit a 3D geometry file
   * @param id id of the file to edit
   * @param field field to edit
   * @param value new value for the field
   */
  @action
  public edit3DGeometryFile(id: string, field: string, value: string | number | IFile) {
    this.files.ThreeDGeometryFiles = _.map(this.files.ThreeDGeometryFiles, (file: I3DGeometryFile) => {
      if (file.id === id) {
        return _.extend(file, { [field]: value });
      } else {
        return file;
      }
    });
  }

  /** Returns a list of existing files  */
  public getExistingFiles(): IFileItem[] {
    return this.files.existingFiles;
  }

  /** Updates the list of existing files by fetching them from the version in edit. */
  @action
  public updateExistingFiles() {
    this.files.existingFiles = this.versionInEdit!.binaries;
  }

  /** Returns a list of files selected for removal */
  public getSelectedFilesForRemoval(): Array<IFile | I3DGeometryFile | IFileItem> {
    return this.files.selectedFilesForRemoval;
  }

  /** Resets selected files for removal list */
  @action
  public resetSelectedFilesForRemoval() {
    this.files.selectedFilesForRemoval = [];
  }

  /** Returns if the TS scan is currently in progress for binary.  */
  public scanInProgress(item: IFileItem): boolean {
    return ScanResultsChecker.binaryScanInProgress(item);
  }

  /** Returns if the TS scan is currently in progress for any or version binaries.  */
  public scanInProgressForVersion(): boolean {
    return !!this.versionInEdit ? ScanResultsChecker.versionBinariesScanInProgress(this.versionInEdit!) : false;
  }

  /** Checks if the version binaries have passed a scan. */
  public hasPassedScan(): boolean {
    return !!this.versionInEdit ? ScanResultsChecker.versionBinariesHavePassedScan(this.versionInEdit!) : true;
  }

  /** Returns if form should show virus scan header. */
  public shouldShowVirusScanHeader(): boolean {
    return this.rootStore.getUserStore().isUserAdmin() && (!this.hasPassedScan() || this.scanInProgressForVersion());
  }

  /** Gets the scan result text for an item. */
  public getScanResultText(binary) {
    if (ScanResultsChecker.binaryHasSoftFailed(binary.item)) {
      return this.rootStore.getTranslation("versions.virus_scan.soft_failed");
    } else if (ScanResultsChecker.binaryHasHardFailed(binary.item)) {
      return this.rootStore.getTranslation("versions.virus_scan.hard_failed");
    } else if (ScanResultsChecker.binaryIsInfected(binary.item)) {
      return this.rootStore.getTranslation("versions.virus_scan.infected");
    } else {
      return "";
    }
  }

  /**
   * Copies the metadata from given version to the form data.
   * @param version version to copy metadata from
   */
  @action
  public copyVersionMetadata(version: IVersion) {
    if (version.attributes.compatibleOperatingSystems) {
      this.setCompatibleOperatingSystems(
        _.map(version.attributes.compatibleOperatingSystems, (key) => {
          return {
            label: this.rootStore.getTranslation("compatibleOperatingSystems." + key),
            value: key,
          };
        }),
      );
    }
    if (version.attributes.compatibleSoftwareProducts) {
      this.setCompatibleSoftwareProducts(
        _.map(version.attributes.compatibleSoftwareProducts, (key) => {
          return {
            label: this.rootStore.getTranslation("compatibleSoftwareProductOptions." + key),
            value: key,
          };
        }),
      );
    }
    if (version.attributes.testedVersions) {
      this.setTestedVersions(
        _.map(version.attributes.testedVersions, (key) => {
          return {
            label: this.rootStore.getTranslation("testedVersions." + key),
            value: key,
          };
        }),
      );
    }
    if (version.attributes.measurementUnits) {
      this.setMeasurementUnits(
        _.map(version.attributes.measurementUnits, (key) => {
          return {
            label: this.rootStore.getTranslation("measurementUnits." + key),
            value: key,
          };
        }),
      );
    }

    this.version.attributes.productCode = version.attributes.productCode || "";
    this.version.attributes.prerequisiteDescription = version.attributes.prerequisiteDescription || "";
    this.version.attributes.productExpirationDate = version.attributes.productExpirationDate as Date;
    this.version.prerequisites = version.prerequisites || [];
  }

  /** Returns the version title */
  public getTitle() {
    return this.version.title;
  }

  /** Sets the version title and sends the information to form header */
  @action
  public setTitle(title: string) {
    this.version.title = title;

    if (this.form) {
      this.form.setVersionTitle(title);
    }
  }

  /** Returns the dropdown options for tested versions */
  public getTestedVersions(): IDropdownOption[] {
    return toJS(this.selectedTestedVersions) || [];
  }

  /** Sets tested versions */
  @action
  public setTestedVersions(options: IDropdownOption[]) {
    this.selectedTestedVersions = options;
    const versions = _.map(options, (o) => o.value);
    this.version.attributes.testedVersions = versions;
  }

  /** Returns a list of compatible operating system options */
  public getCompatibleOperatingSystems(): IDropdownOption[] {
    return toJS(this.selectedCompatibleOperatingSystems) || [];
  }

  /**
   * Sets compatible operating systems for the version.
   * @param options selected dropdown options
   */
  @action
  public setCompatibleOperatingSystems(options: IDropdownOption[]) {
    this.selectedCompatibleOperatingSystems = options;
    const operatingSystems = _.map(options, (o) => o.value);
    this.version.attributes.compatibleOperatingSystems = operatingSystems;
  }

  /** Returns a list of compatible software product options */
  public getCompatibleSoftwareProducts(): IDropdownOption[] {
    return toJS(this.selectedCompatibleSoftwareProducts) || [];
  }

  /**
   * Sets compatible software products for the version.
   * @param options selected dropdown options
   */
  @action
  public setCompatibleSoftwareProducts(options: IDropdownOption[]) {
    this.selectedCompatibleSoftwareProducts = options;
    const products = _.map(options, (o) => o.value);
    this.version.attributes.compatibleSoftwareProducts = products;
  }

  /** Returns the product code */
  public getProductCode(): string {
    return this.version.attributes.productCode;
  }

  /**
   * Sets a product code for the version.
   * @param code given product code
   */
  @action
  public setProductCode(code: string) {
    this.version.attributes.productCode = code;
  }

  /** Returns the product expiration date */
  public getProductExpirationDate(): Date {
    if (this.version.attributes.productExpirationDate) {
      return new Date(this.version.attributes.productExpirationDate);
    } else {
      return null as unknown as Date;
    }
  }

  /**
   * Sets a product expiration date for the version.
   * @param date given product expiration date
   */
  @action
  public setProductExpirationDate(date: Date) {
    this.version.attributes = _.extend(this.version.attributes, {
      productExpirationDate: date,
    });
  }

  /** Returns the selected measurement units. */
  public getMeasurementUnits(): IDropdownOption[] {
    return toJS(this.selectedMeasurementUnits) || [];
  }

  /**
   * Sets measurement units for the version.
   * @param options selected dropdown options
   */
  @action
  public setMeasurementUnits(options: readonly IDropdownOption[]) {
    this.selectedMeasurementUnits = options as IDropdownOption[];
    const units = _.map(options, (o) => o.value);
    this.version.attributes.measurementUnits = units;
  }

  /** Returns a list of prerequisites for the version. */
  public getPrerequisites(): IVersionPrerequisite[] {
    return this.version.prerequisites;
  }

  /** Returns a prerequisite description for the version. */
  public getPrerequisiteDescription(): string {
    return this.version.attributes.prerequisiteDescription;
  }

  /** Sets a prerequisite description based on user input. */
  @action
  public setPrerequisiteDescription(description: string) {
    this.version.attributes.prerequisiteDescription = description;
  }

  /** Adds a prerequisite link for the version. */
  @action
  public addPrerequisite(prerequisite: IVersionPrerequisite) {
    this.version.prerequisites.push(prerequisite);
  }

  /** Adds a prerequisite link title. */
  @action
  public setPrequisiteTitle(prerequisite: IVersionPrerequisite, title = "") {
    prerequisite.title = title;
  }

  @action
  /** Adds a prerequisite url. */
  public setPrequisiteUrl(prerequisite: IVersionPrerequisite, url = "") {
    prerequisite.url = url;
  }

  /**
   * Removes a prerequisite link from the list of version prerequisites.
   * @param prerequisite prerequisite to be removed
   */
  @action
  public removePrerequisite(prerequisite: IVersionPrerequisite) {
    this.version.prerequisites = _.without(this.version.prerequisites, prerequisite);
  }

  /**
   * Checks if the version in edit is immutable.
   * @returns {boolean} True if the version in edit is immutable, false otherwise.
   */
  public versionInEditIsImmutable(): boolean {
    return !!this.versionInEdit && !!this.versionInEdit.isImmutable;
  }

  public hasAddedContent(): boolean {
    if (
      this.viewState === VersionUploaderViewState.FILES ||
      this.viewState === VersionUploaderViewState.FILES_FROM_TS
    ) {
      return this.isTool()
        ? this.checkFiles(this.files.selectedTools.length > 0)
        : this.checkFiles(this.files.selectedFiles.length > 0);
    } else if (this.viewState === VersionUploaderViewState.APPLICATION) {
      return this.checkFiles(this.files.selectedTools.length > 0);
    } else if (this.viewState === VersionUploaderViewState.GEOMETRY_FILE) {
      return this.checkFiles(this.files.ThreeDGeometryFiles.length > 0);
    } else if (this.viewState === VersionUploaderViewState.PARTNER_DOWNLOAD_LINK) {
      const downloadURL = this.form.getEntityStore().getPartnerDownloadURL();
      return !!downloadURL && !_.isEmpty(downloadURL);
    } else {
      return false;
    }
  }

  /** Determines if the version form is valid. */
  public isFormValid(): boolean {
    const hasTitleIfRequired = this.form.isImmutable() ? true : !!this.version.title && !_.isEmpty(this.version.title);
    return hasTitleIfRequired && this.versionNumberNotRequiredOrRequiredAndValid() && this.hasAddedContent() && this.addedContentIsValid();
  }

  public applicationHasDuplicatePlatforms(): boolean {
    const tools = [...this.files.existingFiles, ...this.files.selectedTools];
    return _.unique(tools, (f: IFile | IFileItem) => (f as IFile).platform || (f as IFileItem).attributes?.platform).length != tools.length;
  }

  private versionNumberNotRequiredOrRequiredAndValid(): boolean {
    if (this.form.isImmutable()) {
      return (
        !!this.version.attributes.versionNumber &&
        this.version.attributes.versionNumber.length > 0 &&
        isValidVersionNumber(this.version.attributes.versionNumber)
      );
    } else {
      return true;
    }
  }

  private addedContentIsValid(): boolean {
    if (this.viewState === VersionUploaderViewState.GEOMETRY_FILE) {
      return _.all(this.files.ThreeDGeometryFiles, (f: I3DGeometryFile) => !!f && !!f.thumbnailFile && !!f.geometryFile);
    } else if (this.viewState === VersionUploaderViewState.APPLICATION) {
      const hasDuplicatedPlatforms = _.unique(this.files.selectedTools, (f: IFile) => f.platform).length != this.files.selectedTools.length;
      return [...this.files.existingFiles, ...this.files.selectedTools].length <= 3 && !hasDuplicatedPlatforms;
    } else if (this.viewState === VersionUploaderViewState.PARTNER_DOWNLOAD_LINK) {
      const downloadURL = this.form.getEntityStore().getPartnerDownloadURL();
      return !!downloadURL && isValidDownloadURL(downloadURL);
    } else {
      return true;
    }
  }

  private checkFiles(additionalCondition: boolean): boolean {
    if (this.displayCurrentBinaries) {
      return additionalCondition || _.size(this.files.existingFiles) > 0;
    } else {
      return additionalCondition;
    }
  }
}
