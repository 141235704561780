import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { SearchTargetOptionEnum, ObjectTypeEnum } from "../../models/enums";
import { MyAlertsContext } from "./myAlertsStore";

export const AlertTypeSelector = observer(() => {
  const store = useContext(MyAlertsContext);
  const rootStore = store.getRootStore();
  const searchStore = rootStore.getSearchStore();

  const selected = searchStore.getSearchTarget();
  const notSelected =
    selected === SearchTargetOptionEnum.PACKAGE ? SearchTargetOptionEnum.COLLECTION : SearchTargetOptionEnum.PACKAGE;

  function changeSearchTarget() {
    searchStore.setSearchTarget(notSelected);
  }

  function contentAlertsCount() {
    return store.getCountOfActiveAlerts(ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE);
  }

  function collectionAlertsCount() {
    return store.getCountOfActiveAlerts(ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION);
  }

  const renderContentItemAlertsLabel = () => {
    return (
      <label className="icon icon-warehouse-content l-inline">
        {rootStore.getTranslation("helpers.show_content_alerts")}
        {store.loadingFinished() && (
          <span className="count" data-testid="itemCount">
            {" "}
            {contentAlertsCount()}
          </span>
        )}
      </label>
    );
  };

  const renderCollectionAlertsLabel = () => {
    return (
      <label className="icon icon-drawer l-inline">
        {rootStore.getTranslation("helpers.show_collection_alerts")}
        {store.loadingFinished() && (
          <span className="count" data-testid="collectionCount">
            {" "}
            {collectionAlertsCount()}
          </span>
        )}
      </label>
    );
  };

  const renderSelected = () => {
    return (
      <div className="radioactive" data-testid={`${selected}`}>
        <div className="radioactivedot" />
        {selected === SearchTargetOptionEnum.PACKAGE ? renderContentItemAlertsLabel() : renderCollectionAlertsLabel()}
      </div>
    );
  };

  const renderUnselected = () => {
    return (
      <div className="radio" data-testid="switchTarget" onClick={changeSearchTarget}>
        {selected === SearchTargetOptionEnum.PACKAGE ? renderCollectionAlertsLabel() : renderContentItemAlertsLabel()}
      </div>
    );
  };

  return (
    <div className="content-type-selection">
      <div className="radiowrapper">
        {selected === SearchTargetOptionEnum.PACKAGE && (
          <div className="firstgroup">
            {renderSelected()}
            {renderUnselected()}
          </div>
        )}
        {selected === SearchTargetOptionEnum.COLLECTION && (
          <div className="secondgroup">
            {renderUnselected()}
            {renderSelected()}
          </div>
        )}
      </div>
    </div>
  );
});
