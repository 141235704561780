import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { CollectionPageStoreContext, CollectionPageStore } from "../collectionPageStore";
import {
  LocalCollectionPageStoreContext,
  LocalCollectionPageStore,
} from "../../local-collection/localCollectionPageStore";
import { DialogStore, DialogContext } from "../../dialogs/dialogStore";
import { EditorModeStoreContext, EditorModeStore } from "../../components/editor-mode/editorModeStore";

import { IItem } from "../../models/dataModel";
import { SanitizationModeEnum } from "../../models/enums";

import { TextWrapper } from "../../components/TextWrapper";
import { Dialog } from "../../dialogs/Dialog";
import { EditDescriptionDialog } from "../../dialogs/edit/EditDescriptionDialog";
import { stripHtml } from "../../utils/functions";

/**
 * A component that provides edit description functionalities
 */
export const EditDescription = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const dialog: DialogStore = useContext(DialogContext);
  const collectionPageStore: CollectionPageStore = useContext(CollectionPageStoreContext);

  function canEdit() {
    return collectionPageStore.canEditCollection() && editorModeStore.getEditorMode();
  }

  function editDescription() {
    dialog.open();
  }

  return (
    <Fragment>
      {canEdit() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-description-dialog"
            title={rootStore.getTranslation("shared.catalog_entity_edit.edit_description")}
            onClick={editDescription}
            className="icon icon-pencil"
          >
            <span>{rootStore.getTranslation("shared.catalog_entity_edit.edit_description")}</span>
          </a>
          <Dialog
            content={
              <EditDescriptionDialog item={collectionPageStore.getCollection() as IItem} store={collectionPageStore} />
            }
            additionalClass="basic-edit"
          />
        </DialogContext.Provider>
      )}
    </Fragment>
  );
});

/**
 * A component that provides editing functionalities for local collection.
 */
export const EditLocalDescription = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const dialog: DialogStore = useContext(DialogContext);
  const localCollectionPageStore: LocalCollectionPageStore = useContext(LocalCollectionPageStoreContext);

  function editDescription() {
    dialog.open();
  }

  return (
    <Fragment>
      {editorModeStore.getEditorMode() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-local-description-dialog"
            title={rootStore.getTranslation("shared.catalog_entity_edit.edit_description")}
            onClick={editDescription}
            className="icon icon-pencil"
          >
            <span>{rootStore.getTranslation("shared.catalog_entity_edit.edit_description")}</span>
          </a>
          <Dialog
            content={
              <EditDescriptionDialog
                item={localCollectionPageStore.getCollection() as IItem}
                store={localCollectionPageStore}
              />
            }
            additionalClass="basic-edit-local"
          />
        </DialogContext.Provider>
      )}
    </Fragment>
  );
});

/**
 * A component that displays collection description.
 */
export const CollectionDescription = observer(
  ({ description, isLocal }: { description: string; isLocal?: boolean }) => {
    const rootStore = useContext(RootContext);
    const [showShortDescription, setShowShortDescription] = useState<boolean>(descriptionLimitExceeded());

    function descriptionLimitExceeded(): boolean {
      return !!description && stripHtml(description).length > 300;
    }

    function showMore() {
      setShowShortDescription(false);
    }

    function showLess() {
      setShowShortDescription(true);
    }

    return (
      <Fragment>
        <div className="column description-box">
          {isLocal ? <EditLocalDescription /> : <EditDescription />}
          {showShortDescription ? (
            <div className="shortDescription">
              <p data-testid="short-description" className="sanitized-description">
                <TextWrapper
                  text={description}
                  cssClasses={["translated-html"]}
                  sanitizationMode={SanitizationModeEnum.MODERATE}
                  truncate={300}
                />
              </p>
              <a data-testid="show-more" onClick={showMore}>
                <span>{rootStore.getTranslation("shared.show_more")}</span>
              </a>
            </div>
          ) : (
            <div className="longDescription">
              <p data-testid="long-description" className="sanitized-description">
                <TextWrapper
                  text={description}
                  cssClasses={["translated-html"]}
                  sanitizationMode={SanitizationModeEnum.MODERATE}
                />
              </p>
              {descriptionLimitExceeded() && (
                <a data-testid="show-less" onClick={showLess}>
                  <span>{rootStore.getTranslation("shared.show_less")}</span>
                </a>
              )}
            </div>
          )}
        </div>
      </Fragment>
    );
  },
);
