import * as React from "react";
import { useContext, Fragment, useEffect } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootStore, RootContext } from "../../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../../uploadFormStore";
import { UploadVersionStore } from "../uploadVersionStore";
import { DialogStore, DialogContext } from "../../../dialogs/dialogStore";
import { ItemBrowserStoreContext, ItemBrowserStore } from "../../../dialogs/item-browser/itemBrowserStore";

import { IFile, IDropdownOption } from "../../../models/dataModel";
import { Dropdown } from "../../../components/Dropdown";
import { SelectPlatform } from "./Applications";
import { Dialog } from "../../../dialogs/Dialog";
import { ItemBrowser } from "../../../dialogs/item-browser/ItemBrowser";

/**
 * Component for the 'Select file type' dropdown
 */
const SelectFileType = observer(({ file }: { file: IFile }) => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const fileTypeOptions = version.getItemTypeOptionsForFile(file);

  const selectedFileType: IDropdownOption | undefined = _.find(fileTypeOptions, (option: IDropdownOption) => {
    return version.getFileType(file.id!) === option.value;
  });

  function handleSelectFileType(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;

    version.setTypeForFile(file.id!, selectedOption.value);
  }

  return (
    <td>
      <Dropdown
        onChange={handleSelectFileType}
        options={fileTypeOptions}
        placeholder={rootStore.getTranslation("versions.select_file_type")}
        selectedValue={selectedFileType}
        className="dropdown-wrapper light"
      />
    </td>
  );
});

/**
 * Component for rendering individual file rows.
 */
const FileRow = observer(({ file }: { file: IFile }) => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  function removeSelectedFile() {
    version.removeSelectedFile(file, version.isTool() ? "selectedTools" : "selectedFiles");
  }

  return (
    <Fragment>
      <td>{file.name}</td>
      {version.isTool() ? <SelectPlatform file={file} /> : <SelectFileType file={file} />}
      {!form.isLocal() && <td>{version.getFileSize(file.size)}</td>}
      <td className="auto-width">
        <a className="icon icon-trash" data-testid="removeFile" onClick={removeSelectedFile} />
      </td>
    </Fragment>
  );
});

/**
 * Wrapper components for rendering the file list.
 */
const FileList = observer(({ files }: { files: IFile[] }) => {
  return (
    <tbody>
      {files.map((file: IFile, i: number) => {
        return (
          <tr key={i}>
            <FileRow file={file} />
          </tr>
        );
      })}
    </tbody>
  );
});

/**
 * Main component for displaying list of files selected for upload.
 * Shows files selected from the file browser and from Tekla Structures.
 */
export const Files = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();
  const dialog: DialogStore = useContext(DialogContext);
  const tsFileBrowserDialog: DialogStore = new DialogStore();

  const uploadFiles = !dialog.isDialogVisible() && version.getViewState() === "files";
  const uploadFilesFromTS = version.getViewState() === "filesFromTS";

  const files: IFile[] = version.isTool() ? version.getSelectedTools() : version.getSelectedFiles();
  const showSelectedFiles = (uploadFiles || uploadFilesFromTS) && files.length > 0;

  useEffect(() => {
    if (uploadFilesFromTS && version.canSelectFiles()) tsFileBrowserDialog.open();
  }, []);

  function triggerFileUpload(element) {
    if (element && version.canSelectFiles()) element.click();
  }

  function handleSelectFiles(event) {
    version.selectFiles(event.target.files, "selectedFiles");
  }

  return (
    <div className="uploadfiles" data-testid="files">
      <div className="wrapper full-width-center">
        {uploadFiles && (
          <input
            className="hidden-file-upload"
            id="toolupload"
            type="file"
            ref={triggerFileUpload}
            onChange={handleSelectFiles}
            data-testid="addFiles"
            multiple
          />
        )}
        {uploadFilesFromTS && (
          <DialogContext.Provider value={tsFileBrowserDialog}>
            <Dialog
              content={
                <ItemBrowserStoreContext.Provider value={new ItemBrowserStore(rootStore)}>
                  <ItemBrowser />
                </ItemBrowserStoreContext.Provider>
              }
            />
          </DialogContext.Provider>
        )}
        {showSelectedFiles && (
          <section className="selected-files" data-testid="selectedFiles">
            <form name="contentManagementForm" noValidate>
              <table>
                <thead>
                  <tr>
                    <th>{rootStore.getTranslation("versions.table_header.name")}</th>
                    {!version.isTool() && <th>{rootStore.getTranslation("versions.table_header.type")}</th>}
                    {version.isTool() && <th>{rootStore.getTranslation("versions.table_header.platform")}</th>}
                    {!form.isLocal() && <th>{rootStore.getTranslation("versions.table_header.size")}</th>}
                    <th className="auto-width" />
                  </tr>
                </thead>
                <FileList files={files} />
              </table>
            </form>
          </section>
        )}
      </div>
    </div>
  );
});
