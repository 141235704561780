import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import { mkConfig } from "export-to-csv";

import { ExportAnalyticsDialogStore, ExportAnalyticsDialogStoreContext } from "./exportAnalyticsDialogStore";
import { RootContext } from "../../stores/rootStore";
import { DialogContext } from "../dialogStore";
import { AnalyticsStoreContext, AnalyticsFiltersSetterContext } from "../../components/analytics/analyticsStore";

import { EventTypeFilter } from "../../components/analytics/EventTypeFilter";
import { TimeFrameFilter } from "../../components/analytics/TimeFrameFilter";
import { Dialog } from "../Dialog";

import { IAnalyticsExportOptions } from "../../models/dataModel";

/**
 * A component that renders export analytics functionality
 */
export const DialogContent = observer(() => {
  const rootStore = useContext(RootContext);
  const analyticsStore = useContext(AnalyticsStoreContext);
  const exportAnalyticsDialogStore = useContext(ExportAnalyticsDialogStoreContext);
  const dialog = useContext(DialogContext);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    exportAnalyticsDialogStore.setEventType(analyticsStore.getEventType());
    exportAnalyticsDialogStore.setTimeFrame(analyticsStore.getTimeFrame());
    exportAnalyticsDialogStore.setStartDate(analyticsStore.getStartDate());
    exportAnalyticsDialogStore.setEndDate(analyticsStore.getEndDate());
  }, []);

  function handleCloseDialog() {
    dialog.close();
  }

  async function exportData() {
    setIsExporting(true);
    const data = await analyticsStore.fetchExportData(
      exportAnalyticsDialogStore.getEventType(),
      exportAnalyticsDialogStore.getTimeFrame(),
      exportAnalyticsDialogStore.getStartDate(),
      exportAnalyticsDialogStore.getEndDate(),
    );
    const exportOptions: IAnalyticsExportOptions = mkConfig({
      filename: analyticsStore.formatDateToString(DateTime.now().toJSDate()),
      fieldSeparator: ";",
      useKeysAsHeaders: true,
    });

    analyticsStore.downloadFile(data, exportOptions);
    dialog.close();
  }

  return (
    <article className="analytics export" data-testid="exportAnalyticsDialog">
      <header className="l-center-align">
        <h2>{rootStore.getTranslation("shared.analytics.export.select_options")}</h2>
      </header>
      <section className="l-center-align">
        <ol className="basic-data">
          <AnalyticsFiltersSetterContext.Provider value={exportAnalyticsDialogStore}>
            <EventTypeFilter />
            <TimeFrameFilter />
          </AnalyticsFiltersSetterContext.Provider>
        </ol>
      </section>
      <div className="actions">
        <button onClick={handleCloseDialog} data-testid="export-analytics-close">
          {rootStore.getTranslation("actions.cancel")}
        </button>
        <button disabled={isExporting} onClick={exportData} data-testid="export-analytics">
          {rootStore.getTranslation("actions.export")}
        </button>
      </div>
    </article>
  );
});

/**
 * A component that renders export analytics functionalities
 */
export const ExportAnalytics = observer(() => {
  const rootStore = useContext(RootContext);
  const analyticsStore = useContext(AnalyticsStoreContext);
  const dialog = useContext(DialogContext);
  const exportAnalyticsDialogStore = new ExportAnalyticsDialogStore(rootStore);

  async function exportToCsv() {
    dialog.open();
  }

  return (
    <Fragment>
      <div className={analyticsStore.resolveExportClassName()}>
        <button data-testid="open-export-dialog" onClick={() => exportToCsv()}>
          {rootStore.getTranslation("shared.analytics.export.caption")}
        </button>
      </div>
      <ExportAnalyticsDialogStoreContext.Provider value={exportAnalyticsDialogStore}>
        <Dialog content={<DialogContent />} additionalClass="export-analytics" />
      </ExportAnalyticsDialogStoreContext.Provider>
    </Fragment>
  );
});
