import * as React from "react";
import { useContext } from "react";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext } from "../stores/rootStore";

export const UsingContent = () => {
  const store = useContext(RootContext);

  return (
    <article className="about-using_content">
      <header>
        <h2>{store.getTranslation("about.using_content.title")}</h2>
      </header>
      <section>
        <p>{store.getTranslation("about.using_content.description_1")}</p>
        <ul className="feature-list">
          <li>
            <TranslatedHtml entry="about.using_content.feature_1" />
          </li>
          <li>
            <TranslatedHtml entry="about.using_content.feature_2" />
          </li>
          <li>
            <TranslatedHtml entry="about.using_content.feature_3" />
          </li>
          <li>
            <TranslatedHtml entry="about.using_content.feature_4" />
          </li>
        </ul>
      </section>
    </article>
  );
};

export const usingContentNavigationInfo = {
  name: "using_content",
  component: <UsingContent />,
  translationId: "about.navigation.using_content",
};
