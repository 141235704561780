import { LocalRequestBuilder } from "../LocalRequestBuilder";
import { LocalResponseProcessor } from "../LocalResponseProcessor";

export const LocalTsService = {
  getItems: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/getItems", data));
  },
  installPackage: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/installPackage", data));
  },
  downloadPackages: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/downloadPackages", data));
  },
};
