
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Ko = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["ko-KR"]) {
      window.locale["ko-KR"] = {};
    }

    _.extend(window.locale["ko-KR"], {
      myDetails: {
        my_roles: "역할",
        my_roles_link: "콘텐츠에 대한 Tekla Warehouse 역할 및 접근 권한",
        my_roles_link_url: "https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "역할",
        roles: {
          member: "Tekla Warehouse 내용 편집자 (직원)",
          employee: "직원",
          contentEditor: "Tekla Warehouse 내용 편집자 (외부)",
        },
      },
      immutability: {
        add_code_name_for_package: "내용의 코드 이름 추가",
        code_name: "코드 이름",
        immutable: "변경 불가",
        archive: "보관",
        archive_collection: "컬렉션 및 컬렉션의 모든 내용 보관",
        archive_version: "선택한 버전 보관",
        archive_package: "패키지와 모든 내용을 보관합니다",
        archive_dialog_text: "보관된 내용은 Tekla Warehouse에서 표시되거나 검색되지 않습니다. 그러나 콘텐츠가 이전에 클라우드 서비스와 함께 Tekla Structures 사용된 경우 클라우드 서비스는 콘텐츠에 계속 액세스할 수 있습니다.",
        archive_success: function (name: string) {
          return `${name}이(가) 성공적으로 보관되었습니다.`;
        },
        archive_failure: function (name: string) {
          return `${name}을(를) 보관하지 못했습니다.`;
        },
        archived_content_redirect_message: "액세스하려는 내용이 보관됨",
        edit_title_move_or_archive: "제목 편집, 이동 또는 보관",
        edit_title_or_archive: "제목 편집 또는 보관",
        major_update: "주요 업데이트",
        major_update_description: "하위 호환되지 않는 변경 사항(기능 추가 또는 제거 등) 또는 기타 주요 변경 사항.",
        minor_update: "소규모 업데이트",
        minor_update_description: "하위 호환되는 변경 사항(기존 기능을 손상하지 않는 새로운 기능 또는 개선 사항 등).",
        patch_update: "패치 업데이트",
        patch_update_description: "새로운 기능 없이 하위 호환되는 수정 사항(보안 패치, 버그 수정 또는 성능 개선 사항 등).",
        info: "조직에서 Tekla Structures 클라우드 서비스의 내용을 제공합니다. 코드 이름과 버전 번호는 호환성과 일관성을 Tekla Structures 보장하기 위해 클라우드 서비스의 콘텐츠에 필요합니다.",
        immutable_files_info: "클라우드 서비스에서 콘텐츠 일관성을 유지하려면 파일을 제거하거나 수정할 수 없습니다. 수정이 필요한 경우에는 현재 버전을 보관하고 새 버전을 업로드하십시오.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `자세한 내용은 <a href="${readMoreUrl}" target="_blank">이 페이지</a>를 참조하십시오.`;
        },
        resolve_conflict: {
          title: "충돌 해결",
          description: '다음 아이템을 찾았습니다. 설치 시 발견된 기존 항목을 유지하려면 "기존 항목 유지"를 선택합니다.',
          table_header_name: "이름",
          table_header_type: "유형",
          table_header_action: "작업",
          action_keep_existing: "기존 항목 유지",
          action_replace: "바꾸기",
          action_done: "완료",
        },
        auth: {
          authentication_process_failed: "인증 처리에 실패하였습니다.",
          access_denied: "접근이 거부되었습니다.",
          handle_unauthorized: {
            unauthorized: "인증되지 않음.",
            please_log_in: "로그인하거나 등록하세요.",
          },
        },
        translations: {
          edit_translations: "변환 편집",
          edit_translations_info: "목록에서 언어를 선택해 편집하고 저장하세요.",
          edit_translations_default_language_info:
            "선택한 언어 사용자를 사용할 수 없는 경우 <b>기본값</b>이 표시됩니다.",
          edit_content: "내용 정보",
          edit_support: "지원",
          updated_successfully: "변환이 성공적으로 업데이트됨",
          error_on_update: "변환 업데이트 중 오류 발생",
          default: "기본값",
          batch_translations: "일괄 변환",
          export_import_info:
            "선택한 내용에 대한 일괄 변환 템플릿 <b>내보내기</b>하고, 템플릿을 편집하여 변역을 추가하고, 한 번에 여러 개의 변역을 저장하기 위해 다시 템플릿을 <b>가져오기</b>합니다.",
          import_translations: "가져오기",
          export_translations: "내보내기",
          translations_missing: " (비어 있음)",
          select_content: "내보낼 변역을 선택하세요.",
          import_finished: "가져오기 완료됨!",
          select_file: "파일 선택",
          select_file_title: "가져올 파일을 선택하세요.",
          import_from_file: "가져오기",
          import_error_info: "파일이 템플릿 형식을 따르지 않습니다.",
          export_selected: "내보내기",
          invalid_file: "잘못된 파일 내용 형식",
          import_translations_finished: "가져온 변환",
          details_too_long: "세부 정보 콘텐츠가 최대 허용 길이를 초과합니다.",
          import_failed: "변환 가져오기 실패",
          translate_to_other_languages: "다른 언어로 변환",
          import_count: function (count) {
            return "에 대한 최근 변환 " + count + " 내용";
          },
          invalid_language: function (contentId, language) {
            return "내용 '" + contentId + "'에 잘못된 언어가 포함되어 있습니다. '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "내용 '" + contentId + "'에 유효하지 않은 변환 필드가 있습니다. '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return "내용 '" + contentId + "' 변환 필드 '" + translationField + "' 값이 최대 허용 길이를 초과합니다.";
          },
        },
        "3dPic": {
          "3d_pic_description": "3D 모델",
          "3d_pic_added": "3D 모델이 성공적으로 추가됨",
          "3d_pic_updated": "3D 모델이 성공적으로 업데이트됨",
          "3d_pic_removed": "3D 모델이 성공적으로 제거됨",
          "3d_pic_update_failed": "3D 모델 업데이트 실패",
          "3d_pic_remove_failed": "3D 모델 제거 실패",
          "3d_pic_missing": "콘텐츠용 3D 이미지가 없습니다. 아래의 '3D 모델 선택'을 클릭하여 이미지를 선택하십시오.",
          "3d_pic_uploading": "모델이 로드되고 있습니다. 기다려 주세요...",
        },
        catalog_entity_edit: {
          froala_placeholder: "여기를 클릭해 내용을 편집하세요.",
          updated_successfully: "성공적으로 업데이트됨",
          update_failed: "업데이트 실패",
          edit_search_criteria: "검색 기준 편집",
          edit_group_and_category: "그룹 및 범주 편집",
          edit_content: "내용 편집",
          edit_thumbnail: "미리 보기 편집",
          edit_thumbnail_descriptive: "권장되는 치수: 400 x 300 픽셀",
          edit_description: "설명 편집",
          edit_download_link: "파트너 다운로드 링크 추가 또는 편집",
          versions_not_shown_to_users:
            "이 내용 항목에 파트너 다운로드 링크가 있으므로 이전에 내용 항목에 추가된 버전을 Tekla Warehouse 사용자가 볼 수 없습니다. 편집 권한이 있는 Tekla Warehouse 관리자 및 사용자는 여전히 모든 버전의 내용 항목을 볼 수 있습니다.",
          partner_download_link: "다운로드 URL 입력",
          partner_download_link_disclaimer:
            "기존 내용 항목에 파트너 다운로드 링크를 추가하면 이전에 내용 항목에 추가된 버전을 Tekla Warehouse 사용자가 더 이상 볼 수 없습니다. 이 작업은 실행 취소할 수 없습니다.",
          edit_title_or_delete: "제목 편집 또는 삭제",
          edit_title_or_move_delete: "제목 편집, 이동 또는 삭제",
          edit_support: "지원 정보 편집",
          edit_release_notes: "릴리스 정보 편집",
          edit_content_wysiwyg: "내용 세부 사항 편집",
          edit_content_embed_link: "Tekla User Assistance의 자세한 정보 &rsaquo;",
          edit_content_embed_info: "YouTube, Vimeo 또는 Alugha의 동영상만 임베드할 수 있습니다.",
          details_unallowed_embed_info: "YouTube, Vimeo 또는 Alugha 도메인의 콘텐츠만 임베드할 수 있습니다. 허용되는 URL 형식은 다음과 같습니다.",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "잘못된 동영상 링크입니다. YouTube 및 Vimeo에서 URL로 동영상을 추가할 수 있습니다.",
          edit_content_vide_code_error: "잘못된 임베드 형식입니다. iframe만 허용됩니다. 허용되는 비디오 공급자는 YouTube, Vimeo 및 Alugha입니다.",
          details_max_length_exceeded: "세부 정보 내용이 너무 깁니다.",
          editor_mode_label: "편집기 모드:",
          editor_mode_on: "켜기",
          editor_mode_off: "끄기",
          do_not_notify: "부분 업데이트, 구독자에게 알리지 말 것",
          invalid_tag: "잘못된 태그",
        },
        transfer_package: {
          header: "내용 이동",
          actions: {
            move: "이동",
            cancel: "취소",
          },
          loading: "모음 로딩 중...",
          moving: "내용 이동",
          select_collection: "모음 선택",
          content_moved: "내용이 이동됨. 몇 분 후 변경 내용이 보일 것입니다.",
          operation_failed: "작업 실패",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "내용이 다음에 성공적으로 연결되었습니다. " + name;
          },
          selected_resources_linked: function (name) {
            return "선택한 내용이 다음에 성공적으로 연결되었습니다. " + name;
          },
          resources_linked_with_errors: "연결된 콘텐츠, 일부 콘텐츠에 오류가 있었습니다.",
          resources_linking_failed: "콘텐츠 연결 실패함",
          resource_unlinked: "연결이 성공적으로 해제되었습니다. 몇 분 후 변경 내용이 보일 것입니다.",
          resources_unlinked: "선택한 내용에서 링크가 제거되었습니다. 몇 분 후 변경 내용이 보일 것입니다.",
          resources_unlinked_with_errors: "링크가 제거되었으므로 일부 내용에 오류가 있었습니다.",
          resources_unlinking_failed: "연결 해제 실패",
          linking: "연결 생성",
          linking_failed: "연결 실패",
          linking_failed_title: "다음 콘텐츠를 연결할 수 없습니다.",
          unlinking_failed_title: "다음 콘텐츠를 연결 해제할 수 없습니다.",
          linking_failed_already_contains:
            "내용이 선택된 모음에 이미 연결되었습니다. 업데이트가 표시되는 데 약간 시간이 걸릴 수 있습니다.",
          linking_failed_already_contains_short: "내용이 선택된 모음에 이미 연결되었습니다.",
          unlinking_failed: "연결 해제 실패",
          unlinking_failed_does_not_contain:
            "내용 연결이 모음에서 이미 해제되었습니다. 업데이트가 표시되는 데 약간 시간이 걸릴 수 있습니다.",
          unlinking_failed_does_not_contain_short: "내용 연결이 모음에서 이미 해제되었습니다.",
        },
        notification_channel: {
          title_success: "성공",
          title_notice: "알림",
          title_info: "정보",
          title_error: "오류",
        },
        maintenance: {
          download_requires_tekla_maintenance: "다운로드하려면 Tekla 유지보수가 필요합니다.",
          can_access_maintenance: {
            yes: "예",
            no: "아니요",
          },
        },
        confirm: {
          title: "확인",
          yes: "예",
          no: "아니요",
          ok: "확인",
          close: "닫기",
          dont_show: "이것을 다시 표시하지 않음",
        },
        show_more: " 자세히 보기",
        show_less: " 간략히 보기",
        spinner_loading: "로딩 중",
        spinner_processing: "처리 중",
        spinner_loading_long_time: "내용을 로딩하는 데 몇 분 걸릴 수 있습니다.",
        local_service: {
          info: "Tekla Warehouse Service 플러그인은 로컬 및 네트워크 내용을 생성하는 데 필요합니다. Tekla Structures 20.1 SR1 이상 버전을 사용해 모델에서 내용을 직접 업로드할 수 있습니다.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service 플러그인</a> " +
            "은 로컬 및 네트워크 내용을 생성하는 데 필요합니다.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service 플러그인</a> " +
            "은(는) 사용할 수 없습니다. 그 서비스는 로컬 및 네트워크 모음을 활성화하는 데 필요합니다.",
          download_to_unlock_all_features_html:
            "" +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service 플러그인</a> " +
            "을(를) 다운로드하여 모든 기능 잠금을 해제합니다.",
          service_out_of_date_html:
            "Tekla Warehouse 플러그인이 오래되었습니다. 업데이트 " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service 플러그인</a> " +
            "을(를) 다운로드하여 모든 기능 잠금을 해제합니다.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "언어 선택",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "다른 사용자 초대",
          send_invitation_to_email: "초대장을 전자 메일로 전송",
          add_e_mail_addresses: "전자 메일 주소 추가",
          and_assign_role: "및 역할 지정",
          send_invitation: "초대장 전송",
          invitation_already_sent: "초대장이 이미 전송되었습니다!",
          invitation_sent: "초대장이 전송되었습니다!",
          update_selected_invitations: "선택한 초대장 업데이트",
          new_role: "새 역할",
          invitations_list_updated: "초대장 목록이 업데이트되었습니다!",
        },
        item_browser_title: "내용 아이템 선택",
        prerequisites: {
          description_placeholder: "여기에 설명 추가",
          link_name_placeholder: "여기에 링크 이름 추가",
          add_prerequisites_and_recommendations: "기본 요건 및 권장 사항(선택 사항)",
          list_the_profiles_components_files:
            "내용이 올바르게 작동하는 데 필요한 프로파일, 컴포넌트, 파일 또는 모델을 나열합니다.",
          link_to_existing_content: "기존 내용에 연결",
          please_enter_prerequisite_url: "기본 요건 URL을 입력하세요.",
          please_enter_valid_prerequisite_url: "유효한 기본 요건 URL을 입력하세요.",
          missing_http: "누락된 http://",
          add_link: "링크 추가",
        },
        treeview: {
          cannot_export_this_container: "이 컨테이너를 내보내기 할 수 없습니다. 대신에 개별 항목을 선택하세요.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "파일은 반드시 " + max_size + " MB보다 작아야 합니다.";
          },
          "invalid_type.": function (allowed_types) {
            return "파일 유형이 잘못되었습니다.  파일 유형은 다음 중 하나여야 합니다. " + allowed_types;
          },
        },
        analytics: {
          timeframe: "타임프레임:",
          timeframe_start: "시작:",
          timeframe_end: "끝:",
          eventtype: "이벤트 유형:",

          downloads_daily_by_version: "버전별 다운로드",
          downloads_by_country: "국가별 다운로드",
          downloads_by_version: "버전별 다운로드",
          downloads_by_version_organization: "버전 및 조직별 다운로드",
          downloads_by_organization: "조직별 다운로드",
          downloads_daily_by_package: "내용 항목별 다운로드",
          views_daily_by_package: "내용 항목별 뷰",
          views_by_country: "국가별 뷰",
          views_by_organization: "조직별 뷰",

          downloads_by_package: "내용 항목별 다운로드",
          views_by_package: "내용 항목별 뷰",
          downloads_daily_by_collection: "모음별 다운로드",
          downloads_by_collection: "모음별 다운로드",
          views_daily_by_collection: "모음별 보기",
          views_by_collection: "모음별 보기",

          events_by_organization_func: function (version) {
            return '버전 "' + version + '" 조직별 이벤트'; // Version "2.1" events by organizations
          },
          pie_slice_others: "기타",
          sum_all_versions: "모든 버전",
          sum_all_packages: "모든 내용 아이템",
          sum_all_collections: "모든 모음",
          sum_all_organizations: "모든 조직",
          recording_started_at: "2015년 6월 데이터 기록 시작",
          recording_view_events_started_at: "2017년 3월 데이터 기록 시작",
          version_title_func: function (title) {
            return '버전 "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " 다운로드", count + " 다운로드", count + " 다운로드"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "숫자에는 완료된 다운로드가 모두 포함됩니다.",
          counted_events_info_package: "숫자에는 완료된 다운로드가 모두 포함됩니다.",
          export: {
            caption: "내보내기 분석",
            select_options: "옵션 선택",
            select_timeframe_description: "아래에서 분석을 내보내기할 타임프레임을 선택하세요.",
            select_eventtype_description: "아래에서 분석을 내보내기할 이벤트 유형을 선택하세요.",
          },
        },
        access_rights: {
          editors: "편집기",
          download_access: "보기 및 다운로드 접근",
          write_user_id_placeholder: "사용자 ID 쓰기",
          write_organization_id: "유효한 조직 ID를 입력합니다. 각 ID를 별도의 행에 입력합니다.",
          write_id_placeholder:
            "사용자 ID, 사용자 전자 메일 주소 또는 조직 ID를 입력합니다. 각 ID 또는 전자 메일 주소를 별도의 행에 입력합니다.",
          id_help_html_short:
            "보안상의 이유로 사용자는 검색할 수 없습니다.<br/><br/>" +
            "접근 권한을 부여하고 싶은 사람에게 직접 사용자 ID를 요청하세요. <br/>" +
            '사용자 ID가 <a href="#/my/">내 세부 정보</a>에 표시됩니다.',
          id_help_html:
            "보안상의 이유로 사용자는 검색할 수 없습니다.<br/><br/>" +
            "접근 권한을 부여하고 싶은 사람에게 직접 사용자 ID를 요청하세요. 조직의 모든 사용자에게 접근 권한을 부여하려면 조직 ID를 사용하세요.<br/><br/>" +
            '사용자 및 조직 ID는 <a href="#/my/">내 세부 정보</a>에 표시됩니다.',
          max_input_limit: function (max_rows) {
            return (
              "각 ID 또는 전자 메일 주소를 별도의 행에 입력합니다. 한 번에 " +
              max_rows +
              "개 행까지 입력할 수 있습니다."
            );
          },
          input_too_long: "입력한 텍스트가 너무 깁니다.",
          viewer_already_added: function (input) {
            return `${input}이(가) 이미 뷰어로 추가됨`;
          },
          wrong_format: function (input) {
            return `${input}은(는) 유효한 ID 또는 전자 메일 주소가 아님`;
          },
          candidates_for_keyword: function (keyword) {
            return "해당 전자 메일 주소 또는 ID로 검색된 사용자 " + keyword;
          },
        },
      },
      images: {
        add_new: "새로 추가",
        add: "추가",
        delete: "삭제",
        set_as_default: "기본 미리 보기 이미지",
        select_file: "파일 선택",
        choose_option: "옵션 선택",
        info_3D_exists: "이미 3D 모델을 추가했으므로 하나 이상의 모델을 내용에 추가할 수 없습니다.",
        action_options: {
          image: "이미지",
          "3DModel": "3D 모델",
          video: "비디오",
        },
        video: {
          url: "비디오 URL",
          invalid_url: "잘못된 URL",
        },
      },
      units: {
        bytes: "bytes",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "다운로드",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " 좋아요", number + " 좋아요", number + " 좋아요"]);
      },
      main_info: {
        header_title: "Tekla Warehouse 웹 사이트가 새롭게 단장했습니다!",
        header_text: "새롭고 심플하게 바뀐 Tekla Warehouse 웹 사이트에 대해 어떻게 생각하세요?",
        header_text2: "주요 변경 사항:",
        bullet_text_1: "시작 페이지의 깔끔한 검색 기능.",
        bullet_text_2:
          "검색 페이지의 모든 검색 필터를 좌측에서 찾을 수 있으며, 기본 미리 보기 뷰에 더 많은 검색 결과가 표시됩니다.",
        bullet_text_3:
          "콘텐츠 페이지의 다운로드 옵션이 중앙에 위치하여 콘텐츠 사용을 쉽게 시작할 수 있습니다. 다운로드 버튼으로 다운로드와 설치 둘 다를 할 수 있습니다.",
        bullet_text_4:
          "모든 항목을 다운로드하고 설치하는 데 사용되는 3단계 지침이 컬렉션 페이지와 내 카트 모두에서 제공됩니다.",
      },
      organizations: {
        error_loading_collection_contents: "모음 내용 로딩 중 오류 발생",
        error_loading_organization: "조직 로딩 중 오류 발생",
      },
      downloads_graph: {
        date: "날짜",
        total_views: "총 보기 수",
        total_downloads: "총 다운로드 수",
        views: "보기",
        downloads: "다운로드",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service 플러그인" +
          "</a>" +
          "이(가) 필요합니다.",
        processingFailed: "처리 실패",
        forbidden: "금지됨",
        resource_not_found: "리소스를 찾을 수 없음",
        no_matches_found: "일치되는 것을 찾을 수 없음",
        no_matches_found_for: function (input) {
          return "해당 전자 메일 주소 또는 ID와 일치하는 항목 없음 " + input;
        },
        not_identified: "식별되지 않음",
        error_while_searching: "검색 중 오류 발생",
        error_user_not_found: "사용자 또는 조직을 찾을 수 없음",
        error_while_adding_member: "접근 권한 회원 추가 중 오류 발생",
        error_while_removing_member: "접근 권한 회원 제거 중 오류 발생",
      },
      offline_to_online: {
        fail_on_initialize: "Zip 콘텐츠를 처리하는 중 오류가 발생했습니다. 변환을 중단합니다.",
        fail_on_validate: "콘텐츠 메타 데이터 유효성 검사에 실패했습니다. 변환을 중단합니다.",
        fail_on_save: "콘텐츠를 저장하고 변경 사항을 롤백하는 중에 오류가 발생했습니다.",
        success: "오프라인 콘텐츠를 온라인 콘텐츠로 성공적으로 변환했습니다.",
        validate_errors_title: "콘텐츠 변환 실패",
        processing: "패키지 저장 중 ",
        processed: "처리된 아이템",
        collection_thumbnail_missing_name: "모음 미리 보기 바이너리에 'filename'이(가) 누락되었습니다",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "패키지 '" + packageTitle + "' 미리 보기 바이너리에 '파일 이름'이 누락되었습니다.";
        },
        package_value_missing_title_func: function () {
          return "패키지에 '제목'이 누락되었습니다.";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "패키지 '" + packageTitle + "'에 '" + field + "'이(가) 누락되었습니다.";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "패키지 '" +
            packageTitle +
            "' 필드'" +
            field +
            "'에 다음과 같은 유효하지 않은 값이 있습니다. " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "패키지 '" + packageTitle + "'에 유효하지 않은 태그가 있습니다. " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "패키지 '" + packageTitle + "' 버전에 '제목'이 누락되었습니다.";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "패키지 '" + packageTitle + "' 버전'" + versionTitle + "'에 '" + field + "'이(가) 누락되었습니다.";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "패키지 '" +
            packageTitle +
            "' 버전 '" +
            versionTitle +
            "' 필드'" +
            field +
            "'에 다음과 같은 유효하지 않은 값이 있습니다. " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "패키지 '" + packageTitle + "' 버전 '" + versionTitle + "' 바이너리에 '파일 이름'이 누락되었습니다.";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "중요: Tekla Warehouse에서 로컬 컬렉션의 서비스 종료",
            part1: "2025년 6월 2일부터 Tekla Warehouse에서 로컬 컬렉션을 더 이상 사용할 수 없습니다. 사용자는 로컬 컬렉션을 생성, 보기 또는 검색하거나 로컬 컬렉션의 콘텐츠를 사용할 수 없게 됩니다.",
            part2: "로컬 컬렉션에서 온라인 컬렉션으로 전환하는 데 도움이 필요하시면, 지역 지원 조직에 문의하십시오.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `중요! 보안 기능 향상으로 2024년 11월 ${date}일 이후로는 Tekla Warehouse 내용 편집자 역할이 있어야 조직의 내용을 관리할 수 있습니다. 조직의 관리자에게 해당 역할을 할당하도록 요청하시기 바랍니다. 자세한 내용은 <a href="${productBulletinUrl}" target="_blank">제품 공지</a>를 참조하십시오.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `보안 기능 향상으로 2024년 11월 ${date}일 이후로는 Tekla Warehouse 내용 편집자 역할이 있어야 조직의 내용을 관리할 수 있습니다. 조직의 관리자에게 해당 역할을 할당하도록 요청하시기 바랍니다. 자세한 내용은 <a href="${productBulletinUrl}" target="_blank">제품 공지</a>를 참조하십시오.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `조직에 대한 편집 옵션이 보이지 않나요? 11월 ${date}일 이후로는 Tekla Warehouse 내용 편집자 역할이 있어야 조직의 내용을 관리할 수 있습니다. 내 Warehouse > 내 세부 정보에서 역할을 확인할 수 있습니다. 자세한 내용은 <a href="${productBulletinUrl}" target="_blank">제품 공지</a>를 참조하십시오.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `조직의 내용을 생성할 수 있는 옵션이 보이지 않습니까? 11월 ${date}일 이후로는 Tekla Warehouse 내용 편집자 역할이 있어야 조직의 내용을 관리할 수 있습니다. 내 Warehouse > 내 세부 정보에서 역할을 확인할 수 있습니다. 자세한 내용은 <a href="${productBulletinUrl}" target="_blank">제품 공지</a>를 참조하십시오.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `11월 ${date}일 이후로는 Tekla Warehouse 내용 편집자 역할이 있어야 조직의 내용을 관리할 수 있습니다. 조직의 관리자에게 역할을 할당해 줄 것을 요청하세요. Tekla Online 프로파일에서 조직의 관리자가 누구인지 확인할 수 있습니다. 자세한 내용은 <a href="${productBulletinUrl}" target="_blank">제품 공지</a>를 참조하십시오.`
            },
          },
          service_plugin_update_info:
            "Tekla Structures에 연결하는 데 문제가 있습니까? 최신 <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service</a> 플러그인을 사용하고 있는지 확인하십시오.",
        },
        maintenance_info:
          "Tekla Warehouse가 2017-10-27 22:00:00 - 23:00:00 UTC 동안 유지 관리 중입니다. 불편을 끼쳐 드려 죄송합니다.",
        release_notes_info: "Tekla Warehouse의 새로운 기능",
        release_notes_link:
          'Tekla Warehouse가 업데이트되었습니다. <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">새로운 기능</a>을 참조하십시오.',
        heading: "Tekla Warehouse",
        heading1Dev: "프로젝트용 Tekla 개발자가 필요하십니까?",
        heading2Dev:
          'Tekla Development Experts라는 이름의 새 디렉터리를 확인하십시오.<br><br>이곳에서 재능있는 프로그래머와 흥미를 느낀 Tekla 사용자가 함께 만날 수 있습니다.<br><br>모든 Tekla Partners Program 구성원은 Experts 디렉터리에 자신의 정보를 언제든 추가할 수 있습니다.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">개발자를 찾아보십시오 &rsaquo;</a>',
        static_intro:
          "무료 Tekla Structures BIM 저장소로서, 내부적으로나 전 세계적으로 콘텐츠를 찾고 가져오고 설치하고 공유할 수 있습니다. Tekla Warehouse의 목적은 고품질의 모델을 효율적으로 쉽게 생성할 수 있도록 하는 것입니다.",
        new_intro:
          'Tekla Corporation의 이름이 이제 Trimble로 바뀌었습니다. 당사는 계속 Tekla 소프트웨어 솔루션을 개발하며, 직원들도 그대로 있습니다. 자세한 정보: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "계정을 확인하세요",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "최신 업로드 &rsaquo;",
          popular: "인기 있는 콘텐츠 &rsaquo;",
          recommended: "추천 &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">뉴스 &rsaquo;</a>',
          item_title: "이것은 뉴스입니다!",
          item_description:
            '일부 뉴스 관련 텍스트, 일부 뉴스 관련 텍스트, 일부 뉴스 관련 텍스트, 일부 뉴스 관련 텍스트, 일부 뉴스 관련 텍스트, 일부 뉴스 관련 텍스트.<br><a href="https://developer.tekla.com" target="_blank">자세히 보기&rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse의 숫자",
          partners: "파트너",
          partners_count: "352",
          collections: "모음",
          collections_count: "793",
          items: "내용 아이템",
          items_count: "2893",
        },
        partners: "우리의 파트너들",
        action_links: {
          search_title: "콘텐츠를 찾으십니까?",
          upload_title: "콘텐츠를 업로드하시겠습니까?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">비디오 &rsaquo;</a>',
          link: '더 많은 비디오를 보려면 <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Youtube 채널</a>을 방문하십시오.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            '전자 메일에 확인 링크가 있는지 확인하거나 <u><a href="' + settingsUrl + '">새 링크를 요청하십시오</a></u>.'
          );
        },
        verifyAccountAcceptTerms: "전자 메일 주소를 확인하고 이용 약관 및 개인정보 보호정책에 동의하시기 바랍니다.",
        acceptTerms: "이용 약관 및 개인정보 보호정책에 동의하세요.",
        acceptUpdatedTerms: "업데이트된 tekla online services 이용 약관 및 개인정보 보호정책에 동의하세요.",
        termsNotAccepted_html_func: function (settingsUrl) {
          return '귀하의 <u><a href="' + settingsUrl + '">프로파일</a></u>로 돌아갑니다.';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            '귀하의 <u><a href="' +
            settingsUrl +
            '">프로파일</a></u>에서 이용 약관 및 개인정보 보호정책을 읽어보시고 이에 동의하시기 바랍니다.'
          );
        },
        suggestions: {
          browseCollections: "모음 검색 &rsaquo;",
          new: "새로운 아이템",
          popular: "인기 모음",
          recommended: "추천",
          organizations: "조직",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "폴더 선택",
        selectFolderInstructions: "로컬 및 네트워크 폴더를 모음으로 사용할 수 있습니다.",
        selectedFolder: "선택한 폴더(복사-붙여넣기 위치):",
        selectedFolderPlaceholder: "예) C:\\folder, Z:\\network folder, \\\\machine-name\\folder",
        collectionCreation: {
          name: "이름",
          createNewCollection: "로컬 모음 생성",
          pleaseInputTitle: "제목을 입력하세요.",
          invalidPath: "잘못된 경로",
          access_denied: "모음을 추가하지 못했습니다. 접근이 거부됨",
          unsafe_location: "모음을 추가하지 못했습니다. 안전하지 않은 위치",
          add_collection_failed: "모음을 추가하지 못했습니다.",
          minLengthError: "제목은 3자 이상이어야 합니다",
          maxLengthError: "제목은 20자보다 길어서는 안 됩니다",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "로컬 및 네트워크 폴더를 모음으로 사용할 수 있습니다.",
        collectionCreation: {
          name: "이름",
          createNewCollection: "온라인 모음 생성",
          pleaseInputTitle: "제목을 입력하세요.",
          minLengthError: "제목은 최소 3자이어야 합니다.",
          maxLengthError: "제목은 20자보다 길어서는 안 됩니다",
        },
      },
      popups: {
        generic: {
          resourceSaved: "리소스가 저장됨",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">가능성이 보이나요?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">피드백 보내기</span>',
          help_us_improve_html:
            'Tekla Warehouse 개선에 도움을 주세요! <br/><a id="showSurvey">여기를 클릭</a>하거나 <a id="dontShow">이것을 다시 표시하지 않습니다</a>',
          dialog: {
            do_you_see_potential: "가능성이 보이나요?",
            help_us_improve_html: "Tekla Warehouse 개선에 도움을 주세요! 귀하의 의견은 우리에게 중요합니다.",
            continue_to_survey: "설문 조사 시작",
            exit_to_warehouse: "괜찮습니다",
          },
        },
      },
      search: {
        bar: {
          search: "검색",
          filter: " ",
          more_filters: "더 많은 필터",
          hide_filters: "필터 감추기",
          clear: "지우기",
          exclude: "제외",
          include: "포함",
          clear_filters: "필터 재설정",
          load_defaults: "내 기본 설정 사용 &rsaquo;",
          save_defaults: "내 기본 설정으로 저장 &rsaquo;",
          defaults_saved_successfully: "기본 필터 저장됨",
          edit_defaults: "기본값 편집",
        },
        info: {
          searching_organizations: "조직에서 검색 중...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["결과 없음", amountTotal + " 결과"]);
            } else {
              return pluralize(amountTotal, ["결과 없음", amountTotal + " 결과"]) + ' "' + escapeHtml(searchTerm) + '"';
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " 결과";
            } else if (!searchTerm) {
              return "조직 결과를 보려면 검색어를 입력하십시오";
            } else if (searchTerm.length < 2) {
              return "조직 검색에 2자 이상 입력하십시오";
            } else {
              return pluralize(amountTotal, ["결과 없음", amountTotal + " 결과"]) + ' "' + escapeHtml(searchTerm) + '"';
            }
          },
          online: " 온라인 콘텐츠 결과",
          local: " 로컬 내용 결과",
          organization: "조직의 결과",
          plugin_missing_info:
            '로컬 내용은 <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service 플러그인" +
            "</a>" +
            " 설치 환경에만 표시됩니다.",
          version_filter_info: "소프트웨어 버전 필터링은 로컬 내용 결과에서 유효하지 않습니다",
        },
        exclude_env_files: "환경 파일 제외",
        sidebar: {
          filters: {
            measurementUnits: "측정 단위",
            locations: "프로젝트 위치",
            languages: "언어",
          },
          placeholders: {
            use_categories: "범주",
            measurement_units: "측정 단위",
            languages: "언어",
            locations: "위치",
          },
        },
      },
      placeholders: {
        name: "이름...",
        contentOwner: "내용 소유자 이름...",
        useCategory: "용도 선택",
        selectType: "유형 선택",
        filter: "필터...",
        search: "검색...",
        choose: "선택...",
        searchFilters: "필터 검색...",
        selectContentItem: "내용 항목 선택...",
        search_content: "내용 검색...",
        includeAs: "...로 포함",
        selectCollection: "모음 선택",
        selectUserGroup: "사용자 그룹 선택",
        online_collections_tooltip: "클릭하여 온라인 내용을 검색하세요.",
        local_collections_tooltip:
          "클릭하여 로컬 및 네트워크 내용 및 Tekla Structures 설치와 함께 포함된 내용을 검색하세요.",
        organizations_tooltip: "클릭해서 조직을 검색하세요.",
        "filter-options": {
          products: "소프트웨어 제품 전체",
          locations: "모든 위치",
          languages: "모든 언어",
          measurementUnits: "모든 측정 단위",
          testedVersions: "모든 소프트웨어 버전",
          useCategories: "모든 범주",
          useCategoriesCombined: "범주",
          allGroups: "모든 그룹",
          itemTypeCategories: "모든 아이템 유형",
        },
        "exclude-filter-options": {
          products: "소프트웨어 제품 제외",
          locations: "위치 제외",
          languages: "언어 제외",
          measurementUnits: "측정 단위 제외",
          testedVersions: "소프트웨어 버전 제외",
          useCategories: "범주 제외",
          useCategoriesCombined: "범주 제외",
          allGroups: "그룹 제외",
          itemTypeCategories: "아이템 유형 제외",
        },
      },
      link: {
        link_selected: "선택한 객체 링크",
        unlink_selected: "선택한 객체 링크 해제",
      },
      download_install: {
        choose_version: "소프트웨어 버전 선택:",
        choose_content: "아래 목록에서 콘텐츠 아이템 선택:",
        choose_action: "선택한 아이템에 대한 작업 선택:",
      },
      download: {
        copy_to_clipboard: "클립보드로 복사",
        download_selected: "다운로드",
        add_selected_items_to_basket: "내 카트에 선택한 아이템 추가",
        add_item_to_basket: "내 카트에 선택한 아이템 추가",
        add_all_items_to_basket: "모두 카트에 추가",
        add_all_items_to_basket_info: "100개 이상의 아이템이 있습니다. 로드하는 데 약간의 시간이 걸릴 수 있습니다.",
        add_all_items_to_basket_too_many_results: "1000개 이상의 항목을 추가할 수 없습니다.",
        cart_loading: "100개 이상의 항목이 있습니다. 로드하는 데 약간의 시간이 걸릴 수 있습니다.",
        remove_item_from_basket: "카트에서 제거",
        shopping_cart_no_content: "카트에 내용 없음",
        shopping_cart_no_content_for_version: "선택한 버전의 카트에 콘텐츠가 없습니다.",
        shopping_cart_items_func: function (itemsCount) {
          return "내 카트(" + itemsCount + ") ";
        },
        download_instructions: {
          title: "다운로드 지침",
          step_one: "이 버튼을 사용하여 다운로드 정보를 클립보드로 복사합니다.",
          step_two_func: function (downloadAppUrl) {
            return (
              "Tekla Warehouse다운로더 응용 프로그램을 실행합니다. 응용 프로그램을 다운로드할 수 있습니다. " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">여기.</a> '
            );
          },
          step_three: "Tekla Warehouse 다운로더 응용 프로그램은 나머지 다운로드 과정을 안내합니다.",
          show_data_title: "작동하지 않았습니까?",
          show_data_to_be_copied: "수동으로 복사",
          hide_data_to_be_copied: "다운로드 데이터 숨기기",
        },
      },
      install: {
        install_title: " 아이템은 Tekla Warehouse 플러그인을 사용하여 설치됩니다.",
        failed_install_information:
          "다운로드에만 사용할 수 있는 내용을 선택했습니다. 이 내용은 현재 설치되지 않습니다. Tekla Warehouse와 별도로 이 내용을 다운로드하십시오.",
        verify_service_plugin_is_running: "설치: Tekla Warehouse Service 플러그인이 올바르게 실행 중인지 확인하십시오.",
        verify_ts_connection: "설치: Tekla Structures가 실행 중인지 확인하십시오.",
        select_software_version: "링크, 다운로드 또는 설치를 위해 소프트웨어 버전별로 필터링",
        download_requires_maintenance: "아래의 일부 아이템에 접근하려면 Tekla 유지보수가 필요합니다.",
        install_selected: "설치",
        start_install: "설치 시작",
        install_started: "설치가 시작되었습니다.",
        select_ts_version: "TS 버전 선택",
        install_retry: "재시도가 필요한 경우 Tekla Warehouse에서 다시 시작하세요.",
        failed_installs_info_func: function (failedCount) {
          return "설치되지 않은 내용(" + failedCount + " 버전):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "선택한 아이템에 대한 설치 " + totalInstallsCount + " 아이템";
        },
      },
      helpers: {
        behalf: "기준",
        sortBy: "정렬 기준:",
        filter: "결과 필터링",
        showAsThumbs: "미리 보기로 표시",
        showAsList: "목록으로 표시",
        resultsPerPage: "페이지별 표시:",
        filter_by_version: "소프트웨어 버전별 필터링:",
        show_more_options: "추가 옵션",
        show_less_options: "옵션 감소",
        filter_by_type: "유형별 필터링:",
        select_all: "모두 선택",
        show_content_items: "내용 아이템 표시",
        choose_action: "선택한 콘텐츠에 대한 작업 선택: ",
        show_collections: "모음 표시",
        search: {
          show: "표시:",
          show_content_items: "내용 아이템",
          show_collections: "모음",
          show_organizations: "조직",
          show_online_content: "온라인 콘텐츠",
          show_offline_content: "오프라인 내용",
          local_content_info:
            '로컬 내용은 <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " 플러그인을 설치한 경우에만 표시됩니다.",
        },
        tekla_maintenance_required: "아래의 일부 아이템에 접근하려면 Tekla 유지보수가 필요합니다.",
        show_content_alerts: "내용 알림 표시 ",
        show_collection_alerts: "모음 알림 표시 ",
      },
      actions: {
        load_more: "자세한 정보",
        use_infitinite_scroll: "무한 스크롤 사용",
        back: "뒤로",
        show_download_dialog: "다운로드",
        download: "다운로드만 하기",
        download_on_partner_site: "파트너 사이트에서 다운로드",
        insert: "설치",
        install: "설치",
        downloadAndInstall: "설치",
        createNew: "새로 생성",
        viewItems: "보기",
        viewCollection: "모음 보기",
        viewGroup: "그룹 표시",
        joinGroup: "그룹에 가입",
        loginToDownload: "로그인하여 다운로드",
        show3DPic: "3D 모델 표시",
        remove: "제거",
        browse: "찾아보기",
        browseFolder: "찾아보기",
        browseFileSystem: "찾아보기",
        add: "추가",
        save: "저장",
        batch_edit: "일괄 편집",
        translations: "변환",
        cancel: "취소",
        continue: "계속",
        delete: "삭제",
        addNetworkPath: "네트워크 위치 추가",
        select: "선택",
        create: "생성",
        update: "업데이트",
        choose_thumbnail: "미리 보기 선택",
        choose_picture: "그림 선택",
        remove_picture: "그림 삭제",
        choose_3D_picture: "3D 모델 선택",
        remove_3D_picture: "3D 모델 제거",
        like: "유사한",
        unlike: "유사하지 않은",
        close: "닫기",
        export: "내보내기",
        add_or_remove: "추가 / 제거",
        select_content_to_convert: "오프라인 콘텐츠 선택",
        search: "검색",
        upload: "업로드",
        edit: "편집",
        view_3d: "모델을 보려면 클릭하십시오...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "베타",
        search: "검색",
        profile: "내 세부 정보",
        myWarehouse: "내 Warehouse",
        myStuff: "내 것",
        logout: "로그아웃",
        login: "로그인",
        register: "등록",
        upload: "업로드",
        uploading: "업로드",
        dashboard: "대시보드",
        learnMore: "자세한 정보",
        about: "정보",
        shoppingCart: "내 카트",
        addToCartInfo:
          "내 카트에 아이템을 수집하여 한 번에 다운로드 또는 설치할 수 있습니다. 다운로드는 완전히 무료입니다.",
      },
      cubeNavigation: {
        headings: {
          get_started: "시작하기",
          learn: "학습하기",
          get_help: "도움말 보기",
          extend_your_tekla: "나의 TEKLA 확장하기",
          collaborate: "공동 작업",
          students_and_educators: "Students and educators",
          about: "정보",
        },
        "tekla-online": "온라인 서비스",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Tekla 웹 사이트",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "내 프로파일",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla전자 학습",
        tekla_services: "TEKLA 서비스 홈",
        register: "등록",
        login: "로그인",
        evaluate: "평가하기",
        buy: "구입하기",
        download: "다운로드",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "제품",
        teklaServices: "서비스",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "내 모음",
        myLikes: "좋아요",
        profile: "내 세부 정보",
        preferences: "내 기본 설정",
        myUserGroups: "내 사용자 그룹",
        localCollections: "로컬 및 네트워크 모음",
        tsCollections: "Tekla Structures 모음",
        downloads: "다운로드",
        selectContent: "내용 파일 선택",
        addMetadata: "메타데이터 추가",
        onlineCollections: "온라인 모음",
        userGroups: "사용자 그룹",
        createNew: "내용 추가",
        notifications: "공지 사항",
        contentUsage: "내용 용도",
        browseCollections: "모음 검색",
        admin: "관리자",
        sales: "영업",
        shoppingCart: "내 카트",
      },
      notifications: {
        title: "공지 사항",
        invitedBy: "초대자",
        invitationType: "초대 유형",
        invitedTo: "초대 목적",
        createdAt: "생성 위치",
        action: "작업",
        acceptInvitation: "허용",
      },
      batch_edit: {
        selectActionType: "작업 유형 선택",
        edit_content: "내용 메타데이터 편집",
        content_title: "내용",
        metadataToChange: "변경할 메타데이터",
        selectContent: "내용 대상",
        select_action: "작업 선택",
        support: {
          support: "지원",
          licenses: "라이선스",
        },
        versions: {
          version: "버전",
          prerequisities: "기본 요건 및 요구 사항",
        },
        content: {
          select_collection: "모음 선택",
          show_collection: "표시:",
          show_all_content: "모든 모음",
          show_organization_content: "내 조직 모음",
          select_all: "모두 선택",
          select_all_info: "(이 작업은 약간의 시간이 걸릴 수 있음)",
          actionOptions: {
            add: "추가",
            remove: "제거",
            replace: "바꿀 내용",
            yes: "예",
            no: "아니요",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "선택한 속성 없음",
            invalid_attributes: "잘못된 속성 값",
            invalid_actions: "잘못된 작업",
            content_not_selected: "선택한 내용 없음",
            entities_not_selected: "패키지 관련 데이터가 제공되었으나 선택한 패키지가 없음",
            versions_not_selected: "버전 관련 데이터가 제공되었으나 선택한 버전이 없음",
          },
        },
        update: {
          info_processing: "모든 아이템이 처리될 때까지 이 창을 열어 두십시오.",
          info_processed: "이제 모든 아이템이 처리되었습니다.",
          processing: "아이템 처리 중",
          error_info: "다음 아이템을 업데이트하는 동안 문제가 발생했습니다.",
          package_failure_func: function (package_title, errorMsg) {
            return "내용: " + package_title + ", 오류: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "내용: " + package_title + ", 버전: " + version_title + ", 오류: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " 아이템이 업데이트됩니다. 계속하시겠습니까?";
          },
        },
      },
      myUserGroups: {
        title: "사용자 그룹",
        createdByPrefix: "기준:",
      },
      downloads: {
        title: "다운로드",
      },
      myCollections: {
        new_collection: "새 모음",
        my_collections: "내 모음",
        created_by_me: "내가 생성함",
        local_collections: "로컬 및 네트워크 모음",
        online_collections: "온라인 모음",
        created_by_my_organization: "내 조직이 생성함",
        created_by_my_organizations: "내 조직이 생성함",
        shared_with_me: "내가 공유함",
        shared_with_me_or_organization: "나 자신 또는 내 조직과 공유함",
        notification: {
          error: "오류",
          error_loading_online_collections: "온라인 모음 로딩 중 오류 발생",
          error_loading_packages_liked_by_me: "내가 좋아한 내용 로딩 중 오류 발생",
          error_loading_my_organization_s_collections: "내 조직의 모음 로딩 중 오류 발생",
          error_loading_my_shared_collections: "내가 공유한 모음 로딩 중 오류 발생",
        },
        local: {
          helpText1: "로컬 모음에 액세스하려면 설치하세요. ",
          helpText2: "Tekla Warehouse Service 플러그인",
          helpText3: ".",
          title: "로컬 및 네트워크 모음",
          notFound: "로컬 모음이 아직 발견되지 않았습니다.",
          myCollectionsTitle: "내 모음",
          actions: {
            createNew: "로컬 또는 네트워크 모음 생성",
          },
        },
      },
      organization: {
        news_feed: "뉴스 피드",
        add_comment: "피드 텍스트 추가",
        edit_comment: "새 아이템 편집",
        subTabs: {
          collections: "모음",
          analytics: "분석",
          contact_and_support: "연락처",
          news_feed: "뉴스 피드",
        },
        edit: {
          edit_title: "제목 편집",
          edit_description: "설명 편집",
          edit_contact: "연락처 정보 편집",
          edit_logo: "로고 편집",
          edit_url: "URL 편집",
          edit_email: "전자 메일 편집",
          edit_phonenumber: "전화번호 편집",
          edit_warehouse_url: "Warehouse URL 편집",
          edit_warehouse_url_subtitle: "알파벳만 가능함. 스칸디나비아어 문자, 숫자 또는 특수 문자는 사용 불가",
          edit_url_subtitle: "완전한 URL 삽입",
          edit_logo_descriptive: "최적의 이미지 크기는 약 350 * 200 픽셀임",
          edit_facebook_url: "Facebook URL 편집",
          edit_twitter_url: "Twitter URL 편집",
          edit_linkedin_url: "LinkedIn URL 편집",
          edit_youtube_url: "Youtube URL 편집",
          edit_googleplus_url: "Google+ URL 편집",
          logo_updated: "로고가 업데이트됨",
          logo_update_failed: "로고 업데이트 실패",
          logo_remove_failed: "로고 제거 실패",
          logo_removed: "로고가 성공적으로 제거됨",
          updated_successfully: "성공적으로 업데이트됨",
          updated_failed: "업데이트 실패",
          add_comment: "새로 추가",
          edit_comment: "새 항목 편집",
          comment_added: "새 피드 항목이 성공적으로 추가되었습니다.",
          comment_updated: "새 피드 항목이 성공적으로 업데이트되었습니다.",
          comment_deleted: "새 피드 항목이 성공적으로 삭제되었습니다.",
          comment_add_failed: "뉴스 피드를 추가하지 못했습니다.",
          comment_update_failed: "뉴스 피드 아이템을 추가하지 못했습니다.",
          comment_delete_failed: "뉴스 피드 아이템을 삭제하지 못했습니다.",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "다음 기준에 따라 온라인 콘텐츠를 검색하세요. " + name + " &rsaquo;";
        },
        contact_information: "문의하기",
        website: "웹 사이트 &rsaquo;",
        show_newer_news: "최신",
        show_older_news: "이전",
      },
      online_collections: {
        new_collection: "새 모음",
        online_collections: "온라인 모음",
        created_by_organizations: "조직이 생성함.",
        created_by_users: "사용자가 생성함",
      },
      local_collections: {
        tab_title: "내용",
        local_collections: "로컬 모음",
      },
      versions: {
        add_tool: "응용 프로그램 추가",
        table_header: {
          name: "이름",
          type: "유형",
          platform: "플랫폼",
          modified_at: "수정 위치",
          size: "크기",
          virus_scan: "바이러스 검사",
        },
        // placeholders
        select_file_type: "파일 유형 선택",
        select_platform: "플랫폼 선택",
        select_unit: "단위 선택",
        select_quality: "품질 선택",
        what_will_be_added: "무엇을 추가하시겠습니까?",
        select_files_from_your_disk: "디스크에서 파일 선택",
        add: "추가",
        //content management
        add_application: "응용 프로그램 추가",
        select_content_from_tekla_structures: "Tekla Structures에서 내용 선택",
        or: "또는",
        files: "파일",
        //version metadata
        version_information: "버전 정보",
        version_display_name: "버전 표시 이름",
        version_number: "버전 번호",
        version_number_info: "버전 번호를 입력하십시오. 버전 번호를 저장한 후에는 변경할 수 없습니다. ",
        existing_versions: "이 콘텐츠의 기존 버전 번호",
        version_number_validation: {
          missing: "버전 번호를 입력해야 합니다",
          invalid: "버전 번호는 시맨틱 버저닝의 규칙을 따라야 함(MAJOR.MINOR.PATCH)",
          already_in_use: "이 버전 번호는 이미 이 콘텐츠에 사용 중입니다.",
        },
        select_update_type: "버전 번호를 생성하려면 먼저 업데이트 유형을 선택합니다",
        please_enter_a_version_name_or_number: "버전 이름 또는 숫자를 입력하세요.",
        virus_scan: {
          soft_failed: "실패",
          hard_failed: "스캔되지 않음",
          infected: "감염됨",
        },
      },
      collections: {
        by: "기준:",
        edit: {
          edit_collection: "모음 편집",
          edit_description: "설명 편집",
        },
        notification: {
          collection_updated: "모음이 업데이트됨",
          error: "오류",
          error_loading_other_users_collections: "다른 사용자의 모음 로딩 중 오류 발생",
          error_loading_other_organizations_collections: "다른 조직의 모음 로딩 중 오류 발생",
          error_loading_collection_contents: "모음 내용 로딩 중 오류 발생",
          members_list_updated: "회원 목록이 업데이트되었습니다!",
          invitations_removed: "초대장이 제거되었습니다!",
          collection_removed: "모음이 제거됨",
          thumbnail_deleted: "미리 보기가 삭제됨",
          wrong_file_type: "잘못된 파일 유형입니다.",
          invalid_file_type: "파일 유형이 잘못되었습니다.",
          selected_image_is_too_big: "선택한 이미지가 너무 큽니다.",
          thumbnail_updated: "미리 보기가 업데이트됨",
          default_thumbnail_updated: "기본 미리 보기 이미지가 업데이트됨",
          default_thumbnail_update_failed: "기본 축소판 업데이트에 실패했습니다.",
          thumbnail_added: "미리 보기 추가됨",
          thumbnail_update_failed: "미리 보기 이미지 업데이트에 실패했습니다.",
          video_added: "비디오 추가됨",
          video_adding_failed: "비디오를 추가하지 못했습니다.",
          video_deleted: "비디오가 삭제됨",
          video_deleting_failed: "비디오를 삭제하지 못했습니다.",
        },
        confirm: {
          delete_all: function (title) {
            return '모음과 모음 내 모든 내용을 삭제합니다.  "' + title + '"?';
          },
          remove_members: "확실합니까?",
          remove_invitations: "확실합니까?",
        },
        useNetworkDrive: "네트워크 드라이브나 폴더 사용",
        createCollection: "온라인 모음 생성",
        createCollectionInstructions: "모음을 생성해 내용을 그룹화합니다.",

        actions: {
          delete_thumbnail: "미리 보기 삭제",
          edit_collection: "모음 편집",
          delete_collection: "모음 삭제",
          add_content: "내용 추가",
        },
        subTabs: {
          content: "내용",
          members: "회원 표시",
          analytics: "분석",
          manage_access: "접근 관리",
        },

        members: {
          add_user_groups: "사용자 그룹 추가",
          select_user_group: "사용자 그룹 선택",
          with_role: "역할로",
          user_groups_added: "사용자 그룹이 추가되었습니다!",
          members_list_updated: "회원 목록이 업데이트되었습니다!",
          update_selected_members: "선택한 회원 업데이트",
          table_header: {
            member_type: "회원 유형:",
            member_name: "회원 이름",
            email: "전자 메일",
            role: "역할",
            joined_at: "가입 위치",
            invited_at: "초대 위치",
          },
          new_role: "새 역할",
        },
        member_list: {
          users_who_can_access_this_collection: "이 모음에 액세스할 수 있는 사용자",
          add_user_group: "사용자 그룹 추가",
          update_members: "회원 업데이트",
          remove_members: "회원 제거",
          pending_invitations: "보류 중인 초대장",
          user: "사용자",
          invite_member: "회원 초대",
          update_invitations: "초대장 업데이트",
          remove_invitations: "초대장 제거",
        },
        labels: {
          select_organization: "조직 선택",
          select_visibility: "가시성 선택",
          title: "이름",
          name: "이름",
          createAs: "다음 항목으로 생성: ",
          asMyself: "나 자신",
          asUserGroup: "사용자 그룹",
          asOrganization: "조직",
          selectOwner: "내용 소유자의 조직 선택",
          visibility: "가시성",
          visibilityOptions: {
            public: "공개",
            private: "비공개",
          },
          description: "설명",
        },
        new_collection: {
          please_input_a_username: "사용자 이름을 입력하세요.",
          title_is_required_to_be_at_least_3_characters: "제목은 3자 이상이어야 합니다",
          title_cannot_be_longer_than_20_characters: "제목은 20자보다 길어서는 안 됩니다",
        },
        collection_list: {
          no_content: "모음이 없습니다.",
          results_summary: function (number) {
            return "" + number + "  모음 " + pluralize(number, ["아이템", "아이템", "아이템"]);
          },
        },
        entity_list: {
          no_content: "모음에 내용이 없습니다.",
          results_summary: function (number) {
            return "" + number + " 내용 " + pluralize(number, ["아이템", "아이템", "아이템"]);
          },
          linked_content: "링크된 내용",
          unlink: "내용 연결 해제",
        },
      },
      alerts: {
        no_updates: "최근에 업데이트되지 않음",
        notifications_marked_as_read: "읽은 것으로 표시된 공지 사항",
        cleared_alerts: "모든 공지 사항이 지워짐",
        alert_by_email:
          "아래 나열된 내용이 새 버전으로 업데이트되거나 모음이 새 내용으로 업데이트될 경우 전자 메일 받기",
        action_types: {
          create: "생성됨",
          update: "편집됨",
          delete: "삭제됨",
          added_entity: "버전이 추가됨",
          removed_entity: "버전이 제거됨",
          added_collection: "내용이 추가됨",
          removed_collection: "내용이 제거됨",
        },
        property_names: {
          description: "상세",
          searchCriteria: "검색 기준 정보",
          groupCategory: "그룹 및 범주 정보",
          support: "지원 정보",
          licenses: "라이선스 정보",
          relatedContent: "관련 내용 정보",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "업데이트됨 " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "알림 기본 설정이 성공적으로 업데이트됨",
          preferences_update_failed: "알림 기본 설정 업데이트 중 오류 발생",
        },
      },
      browseDialog: {
        useNetworkDrive: "네트워크 드라이브나 폴더 사용",
        ownerGroups: {
          title: "소유자 그룹",
        },
      },
      itemTypeCategories: {
        all: "모든 그룹",
        application: "응용 프로그램",
        "3dProduct": "3D 제품",
        customComponent: "사용자 컴포넌트",
        tool: "응용 프로그램",
        profile: "프로파일",
        material: "재질",
        bolt: "볼트",
        rebar: "철근",
        reportTemplate: "레포트 템플릿",
        drawingSetup: "도면 설정 파일",
        modelSetup: "모델 설정 파일",
        environmentFile: "환경 파일",
        shape: "형상",
        //'shapeCatalog': '형상 카탈로그',
        //'foreignGeometryDefinition': '외부 지오메트리 정의'
      },
      platforms: {
        WIN_X86: "32비트",
        WIN_X64: "64비트",
        ANY: "독립형 플랫폼",
      },
      platformsDownload: {
        WIN_X86: "32비트",
        WIN_X64: "64비트",
        ANY: " ",
      },
      contentItemSource: {
        other: "일반 - 모델 폴더로",
        run: "도구 실행",
        bolt: "볼트 어셈블리",
        component: "컴포넌트",
        drawing: "도면 설정(베타)",
        material: "재질",
        profile: "프로파일",
        mesh: "철근 메쉬",
        rebar: "철근",
        shape: "형상",
        geometry: "지오메트리",
        modeltemplate: "모델 템플릿",
        cloningtemplate: "복제 템플릿",
        noaction: "작업 없음",
        releasenotes: "릴리스 정보",
      },
      contentItemHeader: {
        bolt: "볼트 어셈블리",
        component: "컴포넌트",
        drawing: "도면 설정(베타)",
        material: "재질",
        profile: "프로파일",
        mesh: "철근 메쉬",
        rebar: "철근",
        shape: "형상",
      },
      useCategoryGroups: {
        workflow: "작업 공정",
        structures: "구조",
        drawings: "도면",
        modeling: "모델링",
      },
      useCategories: {
        title: "용도",
        conceptualDesign: "개념적 설계",
        designEngineeringAnalysis: "설계 / 엔지니어링 / 해석",
        detailing: "디테일링",
        fabrication: "제작",
        erectionInstallationPour: "설립 / 설치 / 타설",
        contracting: "도급",
        scheduling: "일정 계획",
        takeOffEstimation: "견적 조사 / 적산",
        concreteStructures: "현장 타설 콘크리트",
        precastConcrete: "프리캐스트 콘크리트",
        offshore: "Offshore",
        steelStructures: "철골 구조",
        timberStructures: "목재 구조",
        reinforcement: "철근",
        drawingCreation: "도면 생성",
        drawingManagement: "도면 관리",
        drawingEditing: "도면 편집",
        drawingAnnotation: "도면 주석",
        viewSimulateNavigate: "보기, 시뮬레이션하기, 탐색하기",
        compareReviewManageData: "데이터를 비교, 검토, 관리하기",
        softwareCollaboration: "소프트웨어 공동 작업",
        productivity: "생산성",
        interoperability: "상호운용성",
        systemSetup: "시스템 설정",
      },
      measurementUnits: {
        "0": "미터법",
        "1": "피트-인치",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "SketchUp",
        openBIM: "Open BIM(IFC)",
        standalone: "독립형",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "버전에 특정되지 않음",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight1',
        //'TBS1': 'Tekla BIMsight1.5',
        //'TBS2': 'Tekla BIMsight2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "실험용",
        candidate: "후보",
        approved: "승인됨",
        standard: "표준",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "영어",
        "zh-Hans": "중국어(간체)",
        "zh-Hant": "중국어(번체)",
        cs: "체코어",
        da: "덴마크어",
        nl: "네덜란드어",
        fi: "핀란드어",
        fr: "프랑스어",
        de: "독일어",
        hu: "헝가리어",
        it: "이탈리아어",
        ja: "일본어",
        ko: "한국어",
        pl: "폴란드어",
        pt: "포르투갈어",
        "pt-br": "포르투갈어(브라질)",
        ru: "러시아어",
        es: "스페인어",
        sv: "스웨덴어",
        no: "노르웨이어",
        other: "기타",
      },
      isoLangs: {
        ab: {
          name: "아브하지아어",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "아파르어",
          nativeName: "Afaraf",
        },
        af: {
          name: "아프리칸스어",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "아칸어",
          nativeName: "Akan",
        },
        sq: {
          name: "알바니아어",
          nativeName: "Shqip",
        },
        am: {
          name: "암하라어",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "아랍어",
          nativeName: "العربية",
        },
        an: {
          name: "아라곤어",
          nativeName: "Aragonés",
        },
        hy: {
          name: "아르메니아어",
          nativeName: "Հայերեն",
        },
        as: {
          name: "아삼어",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "아바르어",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "아베스타어",
          nativeName: "avesta",
        },
        ay: {
          name: "아이마라어",
          nativeName: "aymar aru",
        },
        az: {
          name: "아제르바이잔어",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "밤바라어",
          nativeName: "bamanankan",
        },
        ba: {
          name: "바슈키르어",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "바스크어",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "벨라루스어",
          nativeName: "Беларуская",
        },
        bn: {
          name: "벵골어",
          nativeName: "বাংলা",
        },
        bh: {
          name: "비하르어",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "비슬라마어",
          nativeName: "Bislama",
        },
        bs: {
          name: "보스니아어",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "브르타뉴어",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "불가리아어",
          nativeName: "български език",
        },
        my: {
          name: "버마어",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "카탈로니아어, 발렌시아어",
          nativeName: "Català",
        },
        ch: {
          name: "차모로어",
          nativeName: "Chamoru",
        },
        ce: {
          name: "체첸어",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "치체와어, 체와어, 니안자어",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "중국어(간체) 및 중국어",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "중국어(간체)",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "중국어(번체)",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "중국어(대만)",
          nativeName: "國語",
        },
        cv: {
          name: "추바시어",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "콘월어",
          nativeName: "Kernewek",
        },
        co: {
          name: "코르시카어",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "크리어",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "크로아티아어",
          nativeName: "hrvatski",
        },
        cs: {
          name: "체코어",
          nativeName: "česky, čeština",
        },
        da: {
          name: "덴마크어",
          nativeName: "dansk",
        },
        dv: {
          name: "디베히어, 드베히어, 몰디브어",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "네덜란드어",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "영어",
          nativeName: "English",
        },
        eo: {
          name: "에스페란토어",
          nativeName: "Esperanto",
        },
        et: {
          name: "에스토니아어",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "에웨어",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "페로어",
          nativeName: "føroyskt",
        },
        fj: {
          name: "피지어",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "핀란드어",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "프랑스어",
          nativeName: "Français",
        },
        ff: {
          name: "풀라어",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "갈리시아어",
          nativeName: "Galego",
        },
        ka: {
          name: "그루지야어",
          nativeName: "ქართული",
        },
        de: {
          name: "독일어",
          nativeName: "Deutsch",
        },
        el: {
          name: "그리스어(현대)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "과라니어",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "구자라트어",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "아이티어, 아이티 크리올어",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "하우사어",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "히브리어(현대)",
          nativeName: "עברית",
        },
        hz: {
          name: "헤레로어",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "힌두어",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "히리 모투어",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "헝가리어",
          nativeName: "Magyar",
        },
        ia: {
          name: "인터링구아",
          nativeName: "Interlingua",
        },
        id: {
          name: "인도네시아어",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "인테르링구에",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "아일랜드어",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "이그보어",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "이누피아크어",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "이도어",
          nativeName: "Ido",
        },
        is: {
          name: "아이슬란드어",
          nativeName: "Íslenska",
        },
        it: {
          name: "이탈리아어",
          nativeName: "Italiano",
        },
        iu: {
          name: "이누크티투트어",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "일본어",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "자바어",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "칼라히수트어, 그린란드어",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "칸나다어",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "카누리어",
          nativeName: "Kanuri",
        },
        ks: {
          name: "카슈미르어",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "카자흐어",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "크메르어",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "키쿠유어, 기쿠유어",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "키냐르완다어",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "키르기스어",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "코미어",
          nativeName: "коми кыв",
        },
        kg: {
          name: "콩고어",
          nativeName: "KiKongo",
        },
        ko: {
          name: "한국어",
          nativeName: "한국어",
        },
        ku: {
          name: "쿠르드어",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "콰냐마어",
          nativeName: "Kuanyama",
        },
        la: {
          name: "라틴어",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "룩셈부르크어, 레체뷔르게시어",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "루간다어",
          nativeName: "Luganda",
        },
        li: {
          name: "륌뷔르흐어",
          nativeName: "Limburgs",
        },
        ln: {
          name: "링갈라어",
          nativeName: "Lingála",
        },
        lo: {
          name: "라오어",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "리투아니아어",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "루바-카탕가어",
          nativeName: "",
        },
        lv: {
          name: "라트비아어",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "맹크스어",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "마케도니아어",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "말라가시어",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "말레이어",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "말라얄람어",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "몰타어",
          nativeName: "Malti",
        },
        mi: {
          name: "마오리어",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "마라티어",
          nativeName: "मराठी",
        },
        mh: {
          name: "마셜어",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "몽골어",
          nativeName: "монгол",
        },
        na: {
          name: "나우루어",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "나바호어",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "노르웨이 보크몰",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "북부 은데벨레어",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "네팔어",
          nativeName: "नेपाली",
        },
        ng: {
          name: "은동가어",
          nativeName: "Owambo",
        },
        nn: {
          name: "노르웨이어(니노르스크)",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "노르웨이어",
          nativeName: "Norsk",
        },
        ii: {
          name: "누소어",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "남부 은데벨레어",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "오크어",
          nativeName: "Occitan",
        },
        oj: {
          name: "오지브웨어, 오지브와어",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "고대 교회 슬라브어, 교회 슬라브어, 고대 불가리아어, 고대 슬라브어",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "오로모어",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "오리야어",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "오세트어",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "판자브어, 펀자브어",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "팔리어",
          nativeName: "पाऴि",
        },
        fa: {
          name: "페르시아어",
          nativeName: "فارسی",
        },
        pl: {
          name: "폴란드어",
          nativeName: "polski",
        },
        ps: {
          name: "파슈토어, 푸슈토어",
          nativeName: "پښتو",
        },
        pt: {
          name: "포르투갈어",
          nativeName: "Português",
        },
        "pt-br": {
          name: "포르투갈어(브라질)",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "케추아어",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "로망슈어",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "키룬디어",
          nativeName: "kiRundi",
        },
        ro: {
          name: "루마니아어, 몰다비아어, 몰도바어",
          nativeName: "română",
        },
        ru: {
          name: "러시아어",
          nativeName: "русский",
        },
        sa: {
          name: "산스크리트어",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "사르데냐어",
          nativeName: "sardu",
        },
        sd: {
          name: "신드어",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "북부 사미어",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "사모아어",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "상고어",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "세르비아어",
          nativeName: "српски језик",
        },
        gd: {
          name: "스코틀랜드 게일어, 게일어",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "쇼나어",
          nativeName: "chiShona",
        },
        si: {
          name: "싱할라어",
          nativeName: "සිංහල",
        },
        sk: {
          name: "슬로바키아어",
          nativeName: "slovenčina",
        },
        sl: {
          name: "슬로베니아어",
          nativeName: "slovenščina",
        },
        so: {
          name: "소말리아어",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "남부 소토어",
          nativeName: "Sesotho",
        },
        es: {
          name: "스페인어", //      'name':'전통 / 스페인어',
          nativeName: "스페인어",
        },
        su: {
          name: "수단어",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "스와힐리어",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "스와트어",
          nativeName: "SiSwati",
        },
        sv: {
          name: "스웨덴어",
          nativeName: "svenska",
        },
        ta: {
          name: "타밀어",
          nativeName: "தமிழ்",
        },
        te: {
          name: "텔루구어",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "타지크어",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "태국어",
          nativeName: "ไทย",
        },
        ti: {
          name: "티그리냐어",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "티벳 표준어, 중부 티벳어",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "투르크멘어",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "타갈로그어",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "츠와나어",
          nativeName: "Setswana",
        },
        to: {
          name: "통가어(통가섬)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "터키어",
          nativeName: "Türkçe",
        },
        ts: {
          name: "총가어",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "타타르어",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "트위어",
          nativeName: "Twi",
        },
        ty: {
          name: "타히티어",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "위구르어",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "우크라이나어",
          nativeName: "українська",
        },
        ur: {
          name: "우르두어",
          nativeName: "اردو",
        },
        uz: {
          name: "우즈베크어",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "벤다어",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "베트남어",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "볼라퓌크어",
          nativeName: "Volapük",
        },
        wa: {
          name: "왈론어",
          nativeName: "Walon",
        },
        cy: {
          name: "웨일스어",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "월로프어",
          nativeName: "Wollof",
        },
        fy: {
          name: "서부 프리지아어",
          nativeName: "Frysk",
        },
        xh: {
          name: "코사어",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "이디시어",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "요루바어",
          nativeName: "Yorùbá",
        },
        za: {
          name: "좡어, 촹어",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "기타",
          nativeName: "기타",
        },
      },

      details: {
        navigation: {
          content_information: "내용 정보",
          version_and_file_management: "버전 및 파일 관리",
          details: "상세",
          versions: "버전",
          analytics: "분석",
          statistics: "통계",
          manage_access: "접근 관리",
          related_content: "관련 사항",
          properties: "속성",
          support: "회사 및 지원",
          release_notes: "릴리스 정보",
          comments: "사용자 설명",
        },
        related_content: {
          other_content_title: "귀하가 좋아할만한 기타 내용",
          related_content_title: "추천하는 내용 ",
          related_content_url: "내용 URL",
          add_related_content: "관련 내용 추가",
          add_related_content_info:
            "관련 온라인 내용은 온라인 내용만 추가하고 로컬 내용은 로컬 내용에만 추가할 수 있습니다.",
          remove_related_content: "제거",
          relatedContentUrlSubtitle: "내용 ID 또는 내용에 연결된 완전한 URL",
          updated_successfully: "관련 내용이 성공적으로 업데이트됨",
          update_failed: "관련 내용 업데이트 중 오류 발생",
          update_failed_invalid_id: "제공한 콘텐츠 ID가 잘못되었습니다.",
        },
        actions: {
          move: "이동",
          delete: "삭제",
          add_comment: "설명 추가",
          add_reply: "답글 추가",
          edit_comment: "설명 편집",
          reply_comment: "답글 추가",
          view_replies: "답글 보기",
          report_bad_comment: "악성 설명 레포트 &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "제목 편집 또는 삭제",
            title_or_move_delete: "제목 편집, 이동 또는 삭제",
            thumbnail: "미리 보기 편집",
            description: "설명 편집",
            groupandcategory: "그룹 및 범주 편집",
            details: "세부 사항 편집",
            versions: "버전 편집",
            support: "지원 정보 편집",
            release_notes: "릴리스 정보 편집",
            search_criteria: "검색 기준 편집",
            batchedit_and_translations:
              "변환 기능을 사용하면 다국어로 내용을 게시할 수 있습니다. 사용자의 Warehouse UI 언어 선택에 따라 변환된 버전의 내용이 사용자에게 표시됩니다. 일괄 편집을 사용해서 다수의 항목을 한 번에 편집할 수 있습니다. 먼저 필드를 선택한 다음 편집할 항목을 선택하십시오.",
          },
        },
        links: {
          link: "링크",
          select_collection_placeholder: "연결을 위해 모음 선택",
          linking_collections_label: "여기에 모음 연결",
          private_content_note: "주의: 비공개 내용은 접근 권한이 있는 사용자에게만 보입니다.",
          share_on_social_media: "공유 위치:",
          report_bad_content: "악성 설명 레포트 &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "불법 콘텐츠 신고 &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "알림을 구독하는 것은 자신의 Tekla Account 표시 이름 및 조직 이름(해당하는 경우)을 내용 소유자와 공유하는 데 동의하는 것입니다.",
          alert_me: "제게 알려주세요.",
          unsubscribe: "구독 해지",
          subscribed_successfully: "구독에 성공했습니다.",
          unsubscribed_successfully: "구독 해지에 성공했습니다.",
          subscribers_label: "구독자",
        },
        reviews: {
          reviewers_label: "리뷰어",
        },
        comments: {
          replies: "답글",
        },
        scan: {
          soft_failed_binaries_info: "바이러스 검사가 실패했습니다.  ",
          hard_failed_binaries_info: "바이러스 검사를 완료할 수 없습니다. ",
          infected_binaries_info: "바이러스 스캔이 감염을 발견했습니다. ",
          what_now: "이제 어떻게 하면 되나요?",
          popup: {
            soft_failed_binaries_title: "실패한 바이너리",
            hard_failed_binaries_title: "실패한 바이너리",
            infected_binaries_title: "감염된 바이너리",
            soft_failed_binaries_info:
              "업로드한 파일에 대한 바이러스 검사가 실패했습니다. 이것은 일시적인 것입니다. 파일을 제거하고 다시 업로드하십시오. 모든 파일은 다른 사용자가 볼 수 있게 되기 전에 바이러스 검사를 통과해야 합니다.",
            hard_failed_binaries_info:
              "바이러스 검사를 완료할 수 없습니다. 파일을 제거하십시오. 모든 파일은 다른 사용자가 볼 수 있게 되기 전에 바이러스 검사를 통과해야 합니다.",
            infected_binaries_info:
              "바이러스 검사에서 업로드한 파일에 감염이 발견되었습니다. 파일을 제거하십시오. 모든 파일은 다른 사용자가 볼 수 있게 되기 전에 바이러스 검사를 통과해야 합니다.",
            version: "버전: ",
            fileName: "파일 이름: ",
          },
        },
        status: {
          content_banned_info:
            "관리자가 콘텐츠를 차단했습니다. 현재 콘텐츠 소유자를 제외한 다른 사용자는 사용할 수 없습니다. <a href='https://support.tekla.com/contact-us' target='_blank'>자세한 내용은 지원팀</a>에 문의하십시오.",
          content_archived_info:
            "이 콘텐츠는 보관되었습니다. 편집 권한이 없는 사용자가 다이렉트 링크를 통해 내용을 방문하려고 하면 앞 페이지로 리디렉션됩니다.",
          archived: "보관됨",
        },
        modified_by: function (name) {
          return " 기준 " + name;
        },
        created_by: function (name) {
          return "작성자: " + name;
        },
        deleteEntity: function (title) {
          return "삭제 " + title + "?";
        },
        userComments: "사용자 설명",
        comment_added: "설명이 성공적으로 추가됨",
        comment_updated: "설명이 성공적으로 업데이트됨",
        comment_deleted: "설명이 성공적으로 삭제됨",
        comment_add_failed: "설명을 추가하지 못했습니다.",
        comment_update_failed: "설명을 업데이트하지 못했습니다.",
        comment_delete_failed: "설명을 삭제하지 못했습니다.",
        comment_delete_confirmation: "설명 항목을 삭제하시겠습니까?",
        name: "이름",
        details: "상세",
        contentOwner: "만든 이:",
        contentOwnerGroup: "내용 소유자 그룹",
        contentGroup: "내용 그룹",
        thumbnail: "미리 보기",
        tooltip: "도구 설명",
        description: "설명",
        additional_information: "추가 정보",
        images: "제품 이미지",
        installationInfo: "설치 정보",
        productCode: "제품 코드",
        copyrightInfo: "저작권",
        productExpirationDate: "제품 만료 날짜",
        helpUrl: "웹 사이트 &rsaquo;",
        supportUrl: "지원 &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "릴리스 정보",
        supportForumUrl: "Discussion forum &rsaquo;",
        itemTypeCategories: "그룹",
        useCategories: "범주",
        category: "범주:",
        group: "그룹:",
        compatibleSoftwareProducts: "호환 가능 소프트웨어 제품",
        compatibleOperatingSystems: "호환 가능 운영 체제",
        locationRestrictions: "제품 가용성:",
        supportedLanguages: "언어:",
        measurementUnits: "측정 단위:",
        tags: "태그 및 키워드:",
        prerequisites: "기본 요건:",
        releaseNotes: "릴리스 정보:",
        versions: "버전",
        testedVersions: "호환 가능 버전",
        qualityTag: "소유자 평가 품질",
        otherAttributes: "기타 속성",
        labels: "검색 기준",
        supportInformation: "지원",
        updateInformation: "업데이트",
        latestUpdate: "최종 업데이트",
        totalDownloads: "총 다운로드 수",
        licenses: "라이선스",
        maintenanceRequired: "다운로드하려면 Tekla 유지보수가 필요합니다.",
        tekla_maintenance_content: "Tekla Maintenance Content",
        download_requires_tekla_maintenance: "다운로드하려면 Tekla 유지보수가 필요합니다.",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "성공적으로 업데이트됨",
        update_failed: "업데이트 실패",
        update_failed_invalid_tag: "업데이트 실패: 잘못된 태그",
        update_failed_details_max_length_exceeded: "업데이트 실패: 세부 정보가 너무 깁니다.",
        show_version_details: "자세한 정보",
        content_removed: "내용이 제거됨",
        no_versions_available:
          "사용 가능한 버전이 없음. 업데이트가 표시되는 데 약간 시간이 걸릴 수 있습니다. 브라우저를 새로 고침하여 변경 내용을 확인하십시오.",
        thumbnail_deleted: "미리 보기가 삭제됨",
        thumbnail_updated: "미리 보기가 업데이트됨",
        verify_service_plugin_is_running: "Tekla Warehouse Service 플러그인이 올바르게 실행 중인지 확인하십시오.",
        verify_ts_connection: "Tekla Structures가 실행 중인지 확인하십시오.",
        download_tos: {
          title: "품질, 지원 및 개인 정보 보호",
          content_1: "다운로드한 내용이 품질 기준을 준수하도록 해야 합니다. Trimble는 서드 파티 내용은 지원하지 않으며 그에 대한 책임도 지지 않습니다. 지원을 받으려면 내용 공급자에게 직접 연락하시기 바랍니다.",
          content_2: "자신의 국가 및 조직이 서비스에 게시되어 내용 수정에 대한 업데이트 유지와 같은 합법적 목적을 위한 내용의 소유자에게 노출될 수 있습니다. 이러한 알림에 대한 구독을 해지할 수 있습니다.",
          content_3: 'Trimble 콘텐츠 확장에는 <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimble 약관</a>이 적용됩니다. 타사 콘텐츠에는 타사의 라이선스 조건이 적용될 수 있습니다. <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimble 개인정보 보호 공지</a>를 참조하십시오.'
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "이것은 무료 Tekla Structures BIM 저장소입니다. 찾고, 가져오고, 설치하고 내부적으로 그리고 전 세계적으로 공유하세요. 고품질 모델을 효율적으로 생산합니다.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse는 Tekla Structures 모델링 도구, 지능형 부재 및 템플릿을 위한 무료 온라인 로컬 라이브러리입니다.",
        },
        search: {
          title: "Tekla Warehouse 검색",
          description:
            "Tekla 확장 프로그램, 3D 부재, 형상, 사용자 컴포넌트, 프로파일, 철골 및 콘크리트 등급과 같은 재질, 철근, 메쉬, 그리고 템플릿 검색",
        },
        about: {
          title: "Tekla Warehouse에 대한 정보",
          description:
            "Tekla Warehouse는 귀하의 제품에 대한 무료 마케팅 채널을 제공하며 귀하의 내용을 완벽하게 통제합니다.",
          developers: {
            title: "기본 개발자 제목",
            description: "기본 개발자 설명",
          },
          usage: {
            title: "기본 사용 제목",
            description: "기본 사용 설명",
          },
          getting_started: {
            title: "기본 시작 제목",
            description: "기본 시작 설명",
          },
          content: {
            title: "기본 내용 제목",
            description: "기본 내용 설명",
          },
          using_content: {
            title: "내용 제목을 사용하는 기본값",
            description: "내용 설명을 사용하는 기본값",
          },
          creating_content: {
            title: "내용 제목을 생성하는 기본값",
            description: "내용 설명을 생성하는 기본값",
          },
        },
        collection: {
          title: "기본 모음 제목",
          description: "기본 모음 설명",
        },
        my_warehouse: {
          title: "내 Tekla Warehouse",
          description: "Tekla Warehouse 내용 관리",
        },
      },
      upload: {
        actions: {
          cancel: "취소",
          previous: "&lsaquo; 이전",
          next: "다음 &rsaquo;",
        },
        content: {
          add_content: "파일 추가",
        },
        collection: {
          heading: "모음 선택 또는 생성",
          subHeading:
            "모음 그룹 내용. 온라인 모음을 사용하여 내용을 게시하거나, 로컬 모음을 통해 동료들과 내용을 공유할 수 있습니다. 개인적인 용도로 로컬 모음을 사용할 수도 있습니다.",
          loading: "모음 로딩 중...",
          selectFromMyCollections: "내 모음에서 선택",
          description_of_the_collection: "설명",
          title_validation: {
            missing: "제목을 입력하세요.",
            too_short: function (length) {
              return "제목은 최소한 " + length + " 자이어야 합니다.";
            },
            too_long: function (length) {
              return "제목은 " + length + " 자 이상이어서는 안 됩니다.";
            },
          },
        },
        package: {
          basic_information: "기본 정보",
          code_name: "코드 이름",
          code_name_info: "이 내용에 대해 고유 식별자로 코드 이름을 입력하십시오. 코드 이름에는 소문자, 숫자 및 특수 문자(_ - @)가 포함될 수 있습니다. 코드 이름을 저장한 후에는 변경할 수 없습니다. ",
          code_name_validation: {
            missing: "코드 이름을 입력해야 합니다",
            wrong_format: "코드 이름에는 소문자, 숫자 및 _ - @ 특수 문자가 포함될 수 있습니다.",
            not_unique: "이 코드 이름은 이미 사용 중입니다. 고유한 코드 이름을 입력해야 합니다.",
          },
          search_criteria: "검색 기준",
          support: "지원",
          title: "제목",
          thumbnail: "미리 보기",
          "3D_pic": "3D 모델",
          title_validation: {
            missing: "제목을 입력하세요.",
            too_short: function (length) {
              return "제목은 최소한 " + length + " 자이어야 합니다.";
            },
          },
          description_validation: {
            missing: "설명을 입력하세요.",
            too_short: function (length) {
              return "설명은 최소한 " + length + " 자이어야 합니다.";
            },
          },
          wrong_file_type: "잘못된 파일 유형.",
          selected_image_is_too_big: "선택한 이미지가 너무 큽니다.",
        },
        version: {
          select_version: "버전 선택",
          select_quality: "품질 선택",
          versions: "버전",
          displayNameNew: "버전 표시 이름",
          copy_from_version: "기존 버전의 메타 데이터 복사(선택 사항)",
          displayName: "표시 이름",
          content_information: "내용 정보",
          back_to_content_information: "&lsaquo; 내용 정보로 돌아가기",
          version_and_file_management: "버전 및 파일 관리",
          there_s_no_versions_yet: "아직 버전이 없습니다.",
          create_a_version_to_add_files: "버전을 생성해 파일을 추가합니다.",
          add_version: "버전 추가",
          version_information: "버전 정보",
          delete_version: "버전 삭제",
          no_versions_available: "사용 가능한 버전이 없음. 업데이트가 표시되는 데 약간 시간이 걸릴 수 있습니다.",
          update_start_title_html: '<div class="spinner spinner-white">업데이트 중</div>',
          update_start_message: "이 작업은 약간의 시간이 걸릴 수 있습니다.",
          version_was_created: "버전이 생성되었습니다.",
          version_was_created_with_delay: "버전이 생성되었습니다. 몇 분 후 변경 내용이 보일 것입니다.",
          version_was_updated: "버전이 업데이트되었습니다.",
          version_was_deleted: "버전이 삭제되었습니다.",
          version_was_deleted_with_delay: "버전이 삭제되었습니다. 몇 분 후 변경 내용이 보일 것입니다.",
          confirm_delete: function (title) {
            return '버전 삭제 "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "패키지가 업로드되었습니다.",
          couldn_t_create_a_package: "패키지를 생성할 수 없습니다. 변경 내용이 롤백될 것입니다.",
          uploading_title_html: '<div class="spinner spinner-white">업로드 중</div>',
          uploading_text: "이 작업은 약간의 시간이 걸릴 수 있습니다.",
          select_organization: "조직 선택",
          collection_visibility: "모음 가시성:",
          actions: {
            create: "생성",
            publish: "게시",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "이 내용은 로컬 또는 네트워크 드라이브에 대한 접근 권한이 있는 모든 사용자에게 보입니다.",
          this_content_will_be_visible_only_to_you: "이 내용은 자기 자신에게만 보입니다.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "이 내용은 귀하와 귀하의 조직 내 모든 사람에게만 보입니다.",
          this_content_will_be_visible_to_everyone_after_publishing: "게시 후 이 내용은 모든 사람이 볼 수 있습니다.",
          public_content_disclaimer:
            "업로드하는 사람은 자신이 업로드한 모든 내용에 대해 책임이 있습니다. 업로드 내용이 해로운 것이어서는 안 됩니다. 회원은 내용을 업로드할 권리가 있지만 지적재산권을 침해하는 것이어서는 안 됩니다. 업로드하는 내용의 사용에 적용될 수 있는 약관을 서비스에 게시하는 것에 동의합니다.",
        },
        metadata: {
          title: "메타데이터",
          subtitle: "기본 데이터",
          deleteThumbnail: "미리 보기 삭제",
          buttonCreate: "생성",
        },
        basicdata: {
          title: "내용 추가",
          clickButtonToSelectFile: "버튼을 클릭하여 파일을 선택합니다.",
          thumbnail: "미리 보기",
          dropImageHere: "이미지를 여기에 둡니다.",
        },
        "3dGeometry": {
          title: "3D 제품 카탈로그 생성",
          buttonAddEntity: "3D 제품 추가",
          buttonAdd: "사용자 지정 속성 추가",
          errorAttributeAlreadyExists: "이름이 동일한 속성이 이미 존재합니다.",
          buttonCreatePackage: "패키지 생성",
          buttonCreatePackages: "패키지 생성",
          addCustomAttributePlaceholder: "속성 이름",
        },
        please_log_in_fragments: {
          please: "로그인 ",
          log_in: "또는",
          or: " 등록 ",
          register: "하여",
          to_upload_online_content: " 온라인 내용을 업로드하세요.",
        },
        header: {
          upload_header: "업로드",
          collection: "모음",
          content: "내용",
          version: "버전",
          finish: "마침",
        },
        contentOwner: "만든 이:",
        contentOwnerGroup: "내용 게시",
        select_action: "작업 선택",
        tooltip: "도구 설명",
        description: "설명",
        copyright: "저작권",
        tags: "태그 및 키워드",
        tagsSubtitle:
          "쉼표로 구분하세요. 예: 키워드1, 키워드2. 키워드가 여러 단어로 구성된 경우 단어 사이에 밑줄(_)을 사용하십시오. 단어 사이에는 공백을 사용할 수 없습니다. 문자와 쉼표는 제외하고 다른 특수 문자는 사용할 수 없습니다.",
        images: "이미지",
        installationInfo: "설치 정보",
        productCode: "제품 코드",
        copyrightInfo: "저작권 정보",
        Details: "상세",
        specialTermsUrl: "EULA",
        productExpirationDate: "제품 만료 날짜",
        helpUrl: "웹 사이트",
        helpUrlSubtitle: "완전한 URL 삽입",
        detailsSubtitle:
          "이 단계에서는 일반 텍스트만 세부 정보 필드에 추가할 수 있습니다. 내용을 만든 후에 HTML 형식을 포함하도록 세부 정보를 편집할 수 있습니다.",
        specialTermsUrlSubtitle:
          "내용 소유자에게는 자신의 내용에 대해 약관을 추가할 수 있는 기회가 있습니다. 소유자는 특정 약관이 있는 웹페이지의 URL을 제공해야 합니다. 이것은 Trimble의 서비스 조건에 우선하지 않습니다.",
        releaseNotes: "릴리스 정보 파일",
        releaseNoteFileSubTitle: "릴리스 정보를 파일로 추가",
        supportUrl: "도움말 및 지원",
        supportUrlSubtitle: '완전한 URL을 삽입하거나 전자 메일 전송을 위해 "mailto:" 접두사를 삽입합니다.',
        supportForumUrl: "Discussion forum(웹 사이트)",
        supportForumUrlSubtitle: "완전한 URL 삽입",
        measurementUnits: "측정 단위",
        itemTypeCategories: "그룹",
        useCategories: "범주",
        compatibleSoftwareProducts: "호환 가능 소프트웨어 제품",
        testedVersions: "호환 가능 버전",
        compatibleOperatingSystems: "호환 가능 운영 체제",
        locationRestrictions: "제품 가용성",
        supportedLanguages: "언어",
        select_releasenotes_file: "파일 선택",
        qualityTag: "소유자 평가 품질",
        requireMaintenance: "다운로드하려면 Tekla 사용자 지정 유지관리가 필요합니다.",
        contentOwnerGroupAsMyself: "나 자신으로",
        contentOwnerGroupAsUserGroup: "사용자 그룹으로",
        selectUserGroupForContentOwner: "내용 소유자를 위한 사용자 그룹 선택",
        defaultUploadTemplate: "기본 패키지",
        templateFor3DGeometryFiles: "3D 지오메트리 패키지(복수도 가능)",
        title: "제목",
        details: "상세",
        selectContentOwner: "내용 소유자 선택",
        selectContentType: "유형",
        errorWrongfiletype: "잘못된 파일 유형.",
        errorImageTooBig: "선택한 이미지가 너무 큽니다.",
        errorBlankTitle: "제목은 비워둘 수 없습니다.",
        commonLabelsTitle: "모든 패키지에 대한 공통 레이블",
      },
      profile: {
        content_usage: "내용 용도",
        profile_updated: "프로파일이 업데이트됨",
        invitation_accepted: function (target) {
          return "초대장이 수락되었습니다! 이제 다음에 대한 액세스 권한이 있습니다. " + target + ".";
        },
        user_groups: {
          edit_user_group: "사용자 그룹 편집",
          user_group_updated: "업데이트된 사용자 그룹",
          create_a_new_user_group: "새 사용자 그룹 생성",
          name_of_the_user_group: "사용자 그룹 이름",
          description: "설명",
          group_visibility: "그룹 가시성",
          remove: '"확실합니까?"',
          remove_invitations: '"확실합니까?"',
          remove_members: '"확실합니까?"',
          invitations_removed: "초대장이 제거되었습니다!",
          members_list_updated: "회원 목록이 업데이트되었습니다!",
          user_group_created: "사용자 그룹이 생성됨",
        },
        members: {
          update_selected_members: "선택한 회원 업데이트",
          new_role: "새 역할",
          members_list_updated: "회원 목록이 업데이트되었습니다!",
          edit_user_group: "사용자 그룹 편집",
          delete_user_group: "사용자 그룹 삭제",
          filter: "필터",
          user_group_members: "사용자 그룹 회원",
          update_members: "회원 업데이트",
          remove_members: "회원 제거",
          pending_invitations: "보류 중인 초대장",
          invite_member: "회원 초대",
          update_invitations: "초대장 업데이트",
          remove_invitations: "초대장 제거",
        },
        subTabs: {
          alerts: "내 알림",
          collections: "모음",
          likes: "좋아요",
        },
        admin: {
          grant_sales_admin: "사용자에게 영업 관리자 역할 부여",
          grant_analyst_role: "사용자에게 분석가 역할 부여",
          role_granted: "역할 부여됨",
          role_removed: "역할 제거됨",
          offline_to_online: "오프라인 콘텐츠를 온라인으로 변환",
          use_elastic_search: "엘라스틱서치 사용",
        },
        sales: {
          title: "영업",
        },
        subtitlePreferences: "검색 설정",
        preferenceInfo:
          '이 필터들은 Tekla Warehouse <a href="#/landing">홈페이지</a>에서 사용할 내용을 추천하기 위한 것입니다.',
        clearFilters: "필터 지우기 &rsaquo;",
        admin_role: "관리자",
        sales_role: "영업",
        admin_mode: "관리자 모드:",
        firstName: "이름",
        lastName: "성",
        displayName: "표시 이름:",
        organization: "조직 이름:",
        email: "전자 메일:",
        teklaId: "Tekla ID",
        maintenanceAccess: "Tekla 유지보수 내용에 대한 접근 권한:",
        myLocations: "프로젝트 위치",
        measurementUnits: "단위",
        useCategories: "사용",
        itemTypeCategories: "다음 유형에 관심이 있습니다.",
        languages: "언어",
        operatingSystems: "운영 체제",
        software: "소프트웨어 제품",
        user_id: "사용자 ID:",
        organization_id: "조직 ID:",
        edit_in_atc: "Tekla Account에서 내 프로파일 편집하기",
        no_likes: "싫어요",
        no_alerts: "구독 내용 없음",
        clear_alerts: "알림 지우기",
        alerts_info_html:
          "다음 중 어느 하나라도 변경되면 알림을 수신하게 됩니다.<br>" +
          "<b>내용:</b> 내용 항목의 제목, 설명, 검색 기준 또는 메타데이터가 변경된 경우 새 버전의 내용이 추가되거나 버전이 삭제됩니다.<br>" +
          "<b>모음:</b> 제목 또는 설명은 편집되고 내용은 추가되거나 삭제됩니다.",
      },
      uploader: {
        placeholders: {
          productCode: "제품 코드",
          title: "이름",
          description: "설명",
          productWeight: "제품 중량",
          weightUnit: "무게 단위",
          material: "재질",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D 제품 카탈로그",
          addToolButton: "응용 프로그램",
          select_files: "디스크의 파일",
          select_files_from_ts: "출처: Tekla Structures",
          partnerDownloadLink: "파트너 다운로드 링크",
        },
        headings: {
          geometryFile: "지오메트리 파일",
          thumbnailFile: "미리 보기 비트맵 파일(.bmp)",
          title: "이름",
          description: "설명",
          productCode: "제품 코드",
          productWeight: "제품 중량",
          weightUnit: "무게 단위",
          material: "재질",
        },
      },
      constants: {
        countries: {
          GLOBAL: "글로벌",
          AF: "아프가니스탄",
          AX: "올란드제도",
          AL: "알바니아",
          DZ: "알제리",
          AS: "아메리칸사모아",
          AD: "안도라",
          AO: "앙골라",
          AI: "앵귈라",
          AQ: "남극 대륙",
          AG: "앤티가 바부다",
          AR: "아르헨티나",
          AM: "아르메니아",
          AW: "아루바",
          AU: "오스트레일리아",
          AT: "오스트리아",
          AZ: "아제르바이잔",
          BS: "바하마",
          BH: "바레인",
          BD: "방글라데시",
          BB: "바베이도스",
          BY: "벨라루스",
          BE: "벨기에",
          BZ: "벨리즈",
          BJ: "베냉",
          BM: "버뮤다",
          BT: "부탄",
          BO: "볼리비아",
          BQ: "보네르",
          BA: "보스니아-헤르체고비나",
          BW: "보츠와나",
          BV: "부베이 섬",
          BR: "브라질",
          IO: "영인도 제도",
          BN: "브루나이",
          BG: "불가리아",
          BF: "부르키나파소",
          BI: "부룬디",
          KH: "캄보디아",
          CM: "카메룬",
          CA: "캐나다",
          CV: "카보베르데",
          KY: "케이맨제도",
          CF: "중앙아프리카공화국",
          TD: "차드",
          CL: "칠레",
          CN: "중국",
          CX: "크리스마스 섬",
          CC: "코코스(킬링)제도",
          CO: "콜롬비아",
          KM: "코모로",
          CG: "콩고공화국",
          CD: "콩고민주공화국",
          CK: "쿡제도",
          CR: "코스타리카",
          CI: "코트디부아르",
          HR: "크로아티아",
          CU: "쿠바",
          CW: "퀴라소",
          CY: "키프로스",
          CZ: "체코",
          DK: "덴마크",
          DJ: "지부티",
          DM: "도미니카",
          DO: "도미니카공화국",
          EC: "에콰도르",
          EG: "이집트",
          SV: "엘살바도르",
          GQ: "적도 기니",
          ER: "에리트레아",
          EE: "에스토니아",
          ET: "에티오피아",
          FK: "포클랜드 제도(말비나스)",
          FO: "페로 제도",
          FJ: "피지",
          FI: "핀란드",
          FR: "프랑스",
          GF: "프랑스령 기아나",
          PF: "프랑스령 폴리네시아",
          TF: "프랑스령 남부 지역",
          GA: "가봉",
          GM: "감비아",
          GE: "그루지야",
          DE: "독일",
          GH: "가나",
          GI: "지브롤터",
          GR: "그리스",
          GL: "그린란드",
          GD: "그레나다",
          GP: "과달로프",
          GU: "괌",
          GT: "과테말라",
          GG: "건지",
          GN: "기니",
          GW: "기니비사우",
          GY: "가이아나",
          HT: "아이티",
          HM: "허드 맥도널드 제도",
          VA: "바티칸",
          HN: "온두라스",
          HK: "홍콩",
          HU: "헝가리",
          IS: "아이슬란드",
          IN: "인도",
          ID: "인도네시아",
          IR: "이란",
          IQ: "이라크",
          IE: "아일랜드",
          IM: "맨 섬",
          IL: "이스라엘",
          IT: "이탈리아",
          JM: "자메이카",
          JP: "일본",
          JE: "저지",
          JO: "요르단",
          KZ: "카자흐스탄",
          KE: "케냐",
          KI: "키리바시",
          KW: "쿠웨이트",
          KG: "키르기스스탄",
          LA: "라오스",
          LV: "라트비아",
          LB: "레바논",
          LS: "레소토",
          LR: "라이베리아",
          LY: "리비아",
          LI: "리히텐슈타인",
          LT: "리투아니아",
          LU: "룩셈부르크",
          MO: "마카오",
          MK: "마케도니아",
          MG: "마다가스카르",
          MW: "말라위",
          MY: "말레이시아",
          MV: "몰디브",
          ML: "말리",
          MT: "몰타",
          MH: "마셜제도",
          MQ: "마르티니크",
          MR: "모리타니아",
          MU: "모리셔스",
          YT: "마요트",
          MX: "멕시코",
          FM: "미크로네시아",
          MD: "몰도바",
          MC: "모나코",
          MN: "몽골",
          ME: "몬테네그로",
          MS: "몬트세라트",
          MA: "모로코",
          MZ: "모잠비크",
          MM: "미얀마(버마)",
          NA: "나미비아",
          NR: "나우루",
          NP: "네팔",
          NL: "네덜란드",
          NC: "뉴칼레도니아",
          NZ: "뉴질랜드",
          NI: "니카라과",
          NE: "니제르",
          NG: "나이지리아",
          NU: "니우에",
          NF: "노퍽 섬",
          KP: "북한",
          MP: "북마리아나 제도",
          NO: "노르웨이",
          OM: "오만",
          PK: "파키스탄",
          PW: "팔라우",
          PS: "팔레스타인 영토",
          PA: "파나마",
          PG: "파푸아뉴기니",
          PY: "파라과이",
          PE: "페루",
          PH: "필리핀",
          PN: "핏케언",
          PL: "폴란드",
          PT: "포르투갈",
          PR: "푸에르토리코",
          QA: "카타르",
          RE: "레위니옹",
          RO: "루마니아",
          RU: "러시아 연방",
          RW: "르완다",
          BL: "생바르텔르미",
          SH: "세인트 헬레나",
          KN: "세인트키츠 네비스",
          LC: "세인트 루시아",
          MF: "세인트 마틴",
          PM: "생피에르 미클롱",
          VC: "세인트 빈센트 그레나딘",
          WS: "사모아",
          SM: "산마리노",
          ST: "상투메 프린시페",
          SA: "사우디아라비아",
          SN: "세네갈",
          RS: "세르비아",
          SC: "세이셸",
          SL: "시에라리온",
          SG: "싱가포르",
          SX: "신트마르턴",
          SK: "슬로바키아",
          SI: "슬로베니아",
          SB: "솔로몬 제도",
          SO: "소말리아",
          ZA: "남아프리카",
          GS: "사우스 조지아",
          KR: "대한민국",
          SS: "남수단",
          ES: "스페인",
          LK: "스리랑카",
          SD: "수단",
          SR: "수리남",
          SJ: "스발바르 얀마옌",
          SZ: "스와질란드",
          SE: "스웨덴",
          CH: "스위스",
          SY: "시리아",
          TW: "대만",
          TJ: "타지키스탄",
          TZ: "탄자니아",
          TH: "태국",
          TL: "동티모르",
          TG: "토고",
          TK: "토켈라우",
          TO: "통가",
          TT: "트리니다드 토바고",
          TN: "튀니지",
          TR: "터키",
          TM: "투르크메니스탄",
          TC: "터크스 케이커스 제도",
          TV: "투발루",
          UG: "우간다",
          UA: "우크라이나",
          AE: "아랍에미리트",
          GB: "영국",
          US: "미국",
          UM: "미국령 소군도",
          UY: "우루과이",
          UZ: "우즈베키스탄",
          VU: "바누아투",
          VE: "베네수엘라",
          VN: "베트남",
          VG: "버진아일랜드(영국령)",
          VI: "버진아일랜드(미국령)",
          WF: "월리스 푸투나",
          EH: "서사하라",
          YE: "예멘",
          ZM: "잠비아",
          ZW: "짐바브웨",
        },
        sortOptions: {
          "-modifyTime": "최종 업데이트",
          "-modifiedAt": "최종 업데이트",
          title: "알파벳순(A-Z)",
          "-title": "알파벳 역순(Z-A)",
          "-reviewCount": "가장 좋아함",
          name: "이름",
          role: "역할",
          email: "전자 메일",
          "-relevance": "관련성",
          "-createdAt": "마지막으로 생성됨",
          displayName: "알파벳순(A-Z)",
          "-displayName": "알파벳 역순(Z-A)",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "오늘",
          yesterday: "어제",
          this_week: "이번 주",
          previous_week: "지난 주",
          this_month: "이번 달",
          previous_month: "지난 달",
          this_year: "올해",
          previous_year: "작년",
          beginning_of_time: "시간의 시작",
          custom: "사용자 지정 기간",
        },
        eventTypeOptions: {
          downloads: "다운로드",
          views: "뷰",
          all_events: "모든 이벤트",
        },
      },
    });
  },
};
