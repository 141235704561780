import * as React from "react";
import { Fragment } from "react";
import { observer } from "mobx-react";

import { ICollection, IEntity } from "../models/dataModel";
import { TranslatedHtml } from "./TranslatedHtml";

/**
 * Renders a banner indicating that the provided item is banned.
 *
 * @param {IEntity | ICollection} item - The item that is banned.
 * @returns {JSX.Element} The rendered banned banner.
 */
export const BannedBanner = observer(({ item }: { item: IEntity | ICollection }) => {
  return (
    <Fragment>
      {item.isBanned && (
        <div className="content-banned-banner" data-testid={`contentBanned`}>
          <div className="content-banned">
            <div className="banned-info-sign" />
            <div className="banned-info-text">
              <TranslatedHtml entry="details.status.content_banned_info" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
});
