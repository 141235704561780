import * as React from "react";
import { useContext } from "react";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext } from "../stores/rootStore";

export const Content = () => {
  const store = useContext(RootContext);

  return (
    <article className="about-content">
      <header>
        <h2>{store.getTranslation("about.content.title")}</h2>
        <p>{store.getTranslation("about.content.description_1")}</p>
      </header>
      <section id="3dProducts">
        <h3>{store.getTranslation("about.content.3d_products.title")}</h3>
        <figure>
          <img src="../images/learn_more/3d_products.png" />
        </figure>
        <p style={{ clear: "both" }}>{store.getTranslation("about.content.3d_products.image_subtitle")}</p>
        <p>
          <TranslatedHtml entry="about.content.3d_products.tua_link_html" />
        </p>
      </section>
      <section id="customComponents">
        <h3>{store.getTranslation("about.content.custom_components.title")}</h3>
        <figure>
          <img src="../images/learn_more/custom_components.png" />
        </figure>
        <p style={{ clear: "both" }}>{store.getTranslation("about.content.custom_components.image_subtitle")}</p>
        <p>
          <TranslatedHtml entry="about.content.custom_components.tua_link_html" />
        </p>
      </section>
      <section id="applications">
        <h3>{store.getTranslation("about.content.applications.title")}</h3>
        <figure>
          <img src="../images/learn_more/tools2.png" />
        </figure>
        <p style={{ clear: "both" }}>{store.getTranslation("about.content.applications.image_subtitle")}</p>
        <p>
          <TranslatedHtml entry="about.content.applications.tua_link_html" />
        </p>
      </section>
      <section id="profilesShapesEtc">
        <h3>{store.getTranslation("about.content.profiles_shapes_etc.title")}</h3>
        <figure>
          <img src="../images/learn_more/profiles_materials_bolts_et_al.png" />
        </figure>
        <p style={{ clear: "both" }}>{store.getTranslation("about.content.profiles_shapes_etc.image_subtitle")}</p>
        <p>
          <TranslatedHtml entry="about.content.profiles_shapes_etc.tua_link_html" />
        </p>
      </section>
      <section id="modelTemplates">
        <h3>{store.getTranslation("about.content.model_templates.title")}</h3>
        <figure>
          <img src="../images/learn_more/model_setup.png" />
        </figure>
        <p style={{ clear: "both" }}>{store.getTranslation("about.content.model_templates.image_subtitle")}</p>
        <p>
          <TranslatedHtml entry="about.content.model_templates.tua_link_html" />
        </p>
      </section>
      <section id="drawingSetup">
        <h3>{store.getTranslation("about.content.drawing_setup.title")}</h3>
        <figure>
          <img src="../images/learn_more/drawing_setup.png" />
        </figure>
        <p style={{ clear: "both" }}>{store.getTranslation("about.content.drawing_setup.image_subtitle")}</p>
        <p>
          <TranslatedHtml entry="about.content.drawing_setup.tua_link_html" />
        </p>
      </section>
      <section id="reportTemplatesEtc">
        <h3>{store.getTranslation("about.content.report_templates_etc.title")}</h3>
        <p>{store.getTranslation("about.content.report_templates_etc.description_1")}</p>
        <p>
          <TranslatedHtml entry="about.content.report_templates_etc.tua_link_html" />
        </p>
      </section>
    </article>
  );
};

export const contentNavigationInfo = {
  name: "content",
  component: <Content />,
  translationId: "about.navigation.content",
  subnavigation: [
    {
      name: "3dProducts",
      translationId: "about.navigation.content__3d-products",
    },
    {
      name: "customComponents",
      translationId: "about.navigation.content__custom-components",
    },
    {
      name: "applications",
      translationId: "about.navigation.content__applications",
    },
    {
      name: "profilesShapesEtc",
      translationId: "about.navigation.content__profiles-shapes-etc",
    },
    {
      name: "modelTemplates",
      translationId: "about.navigation.content__model-templates",
    },
    {
      name: "drawingSetup",
      translationId: "about.navigation.content__drawing-setup",
    },
    {
      name: "reportTemplatesEtc",
      translationId: "about.navigation.content__report-templates-etc",
    },
  ],
};
