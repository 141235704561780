import { PackageConverter } from "../converters/PackageConverter";
import { LocalPackagesDS } from "../data-source/LocalPackagesDS";

export const LocalPackageService = {
  create: function (data) {
    return LocalPackagesDS.setPackage(PackageConverter.toLocal(data))
      .then(function (res) {
        return LocalPackagesDS.getPackage({ id: res.id });
      })
      .then(PackageConverter.fromLocal);
  },
  update: function (data) {
    return LocalPackagesDS.setPackage(PackageConverter.toLocal(data))
      .then(function (res) {
        return LocalPackagesDS.getPackage({ id: res.id });
      })
      .then(PackageConverter.fromLocal);
  },
  get: function (data) {
    return LocalPackagesDS.getPackage(data).then(PackageConverter.fromLocal);
  },
  remove: function (data) {
    return LocalPackagesDS.deletePackage(data);
  },
};
