import * as React from "react";
import { RootContext } from "../stores/rootStore";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import { PackagePageStoreContext } from "./packagePageStore";
import { isLicenseRequired } from "../utils/functions";

/**
 * A component that renders license related information
 */
export const LicenseInfo = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function isMaintenanceLicenseRequired(): boolean {
    return !!packageItem && isLicenseRequired(packageItem, "teklamaintenance");
  }

  function getMaintenanceUrl() {
    return rootStore.getTranslation("details.download_requires_tekla_maintenance_link");
  }

  return (
    <Fragment>
      {isMaintenanceLicenseRequired() && (
        <a
          data-testid="maintenance-info"
          className="tekla-maintenance-required"
          href={getMaintenanceUrl()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="icon icon-maintenance"></span>
          <span>{rootStore.getTranslation("details.download_requires_tekla_maintenance")}</span>
        </a>
      )}
    </Fragment>
  );
});
