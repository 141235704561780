import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { TabNavigationStore, TabNavigationStoreContext } from "../components/tab-navigation/tabNavigationStore";
import { OrganizationPageStore, OrganizationPageStoreContext } from "./organizationPageStore";
import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";

import { Breadcrumbs } from "../components/breadcrumbs/Breadcrumbs";
import { Details } from "./Details";
import { Tabs } from "./Tabs";

import { TemporaryBannerNotification } from "../components/TemporaryBannerNotification";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { Settings } from "../config/Settings";

/**
 * A component that displays organization page.
 */
export const OrganizationPage = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);

  const { id } = useParams();

  async function fetchData() {
    await orgStore.fetchData({ organizationId: id! });
  }

  useEffect(() => {
    if (!id) return;
    fetchData();
  }, [id]);

  return (
    <Fragment>
      {orgStore.wasDataFetched() ? (
        !!orgStore.getOrganization() && (
          <div>
            <TemporaryBannerNotification text={<TranslatedHtml
              entry="landing.notifications.content_editor_role.content_page_info"
              args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
              bannerFeatureToggle="contentEditorRoleInfoAfter"
              show={rootStore.getUserStore().isUserLoggedIn() && (rootStore.getUserStore().belongsToOrganization(orgStore.getOrganization()!) && !rootStore.getUserStore().canEditOrganization(orgStore.getOrganization()!))}
            />
            <Breadcrumbs item={orgStore.getOrganization()!} />
            <div className="organizations organization-content">
              <section className="main">
                <EditorModeStoreContext.Provider value={new EditorModeStore(rootStore, orgStore)}>
                  <Details />
                  <TabNavigationStoreContext.Provider value={new TabNavigationStore()}>
                    <Tabs />
                  </TabNavigationStoreContext.Provider>
                </EditorModeStoreContext.Provider>
              </section>
            </div>
          </div>
        )
      ) : (
        <div className="spinner l-margin-default l-center-align" data-testid="loadingOrganization">
          {rootStore.getTranslation("shared.spinner_loading")}
        </div>
      )}
    </Fragment>
  );
});
