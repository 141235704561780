import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ModusRadioGroup } from "@trimble-oss/modus-react-components";
import { ModusRadioGroupCustomEvent } from "@trimble-oss/modus-web-components/loader";
import { ExternalResourceTypeEnum } from "../../../../models/enums";

import classNames from "classnames";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const ExternalResourceTypeFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleCreatedByChange(event: ModusRadioGroupCustomEvent<string>) {
    store.setCreatedBy(event.detail as ExternalResourceTypeEnum);
  }

  return (
    <div className={classNames({ "search-filter": true, sectioned: true })} data-testid="createdByFilter">
      <h5>
        {rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.external_resource_type")}:
      </h5>
      <ModusRadioGroup
        name="CreatedByFilter"
        data-testid="created-by-filter"
        checkedId={store.getCreatedBy()}
        onButtonClick={handleCreatedByChange}
        radioButtons={[
          {
            id: ExternalResourceTypeEnum.ALL,
            label: rootStore.getTranslation("profile.admin.content_manager.creator.all"),
            checked: store.getCreatedBy() === ExternalResourceTypeEnum.ALL,
          },
          {
            id: ExternalResourceTypeEnum.ORGANIZATION,
            label: rootStore.getTranslation("profile.admin.content_manager.creator.organization"),
            checked: store.getCreatedBy() === ExternalResourceTypeEnum.ORGANIZATION,
          },
          {
            id: ExternalResourceTypeEnum.USER,
            label: rootStore.getTranslation("profile.admin.content_manager.creator.user"),
            checked: store.getCreatedBy() === ExternalResourceTypeEnum.USER,
          },
        ]}
      />
    </div>
  );
});
