/* eslint-disable no-async-promise-executor */
import { action, computed, IObservableArray, observable, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import { Settings } from "../../config/Settings";
import { NewRepository } from "../../js/services/NewRepository";

import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import { createStoreContext, RootStore } from "../../stores/rootStore";
import { ICollection, IDropdownOption, IEntity } from "../../models/dataModel";

export class LinkToCollectionStore extends AbstractAsyncStore {
  /**
   * A collection that entity should be linked to.
   */
  @observable private linkToCollection?: IDropdownOption;

  /**
   * A flag marking if linking process is in progress.
   */
  @observable private linking = false;

  /**
   * A flag marking if collections with linking rights are being fetched.
   */
  @observable private queryingLinks = false;

  /**
   * A flag that marks if linking popup is visible.
   */
  @observable private linkingPopupVisible = false;

  /**
   * Collections that entity can be linked to.
   */
  private collectionsWithLinkingRights: IObservableArray<ICollection> = observable.array();

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.fetchData();
  }

  @action
  public async fetchData() {
    this.queryingLinks = true;
    this.dataFetched = false;
    this.loading = true;
    try {
      const collectionsWithLinkingRight = await this.fetchCollectionsWithLinkingRights();

      runInAction(() => {
        this.collectionsWithLinkingRights.replace(collectionsWithLinkingRight);
        this.loading = false;
        this.dataFetched = true;
        this.queryingLinks = false;
      });
    } catch {
      console.log("Failed to load collections with linking rights.");

      runInAction(() => {
        this.loading = false;
        this.dataFetched = false;
        this.queryingLinks = false;
      });
    }
  }

  /**
   * * Method for setting if linking popup should be visible
   * @param popupVisible
   */
  @action
  public setLinkingPopupVisible(popupVisible: boolean) {
    this.linkingPopupVisible = popupVisible;
  }

  /**
   * Returns linking popup visibility
   * @returns true if linking popup is visible
   */
  public getLinkingPopupVisible() {
    return this.linkingPopupVisible;
  }

  /**
   * Returns collections with linking rights.
   * @returns collections with linking rights
   */
  public getCollectionsWithLinkingRights(): ICollection[] {
    return this.collectionsWithLinkingRights;
  }

  /**
   * Returns querying collections with collections rights process status
   * @returns true if collections are being queried
   */
  public getQueryingLinks(): boolean {
    return this.queryingLinks;
  }

  /**
   * Sets collection to which entity should be linked to
   * @param collection entity should be linked to
   */
  @action
  public setLinkToCollection(collection: IDropdownOption) {
    this.linkToCollection = collection;
  }

  /**
   * Checks if linking process is in progress
   * @returns true if linking process is in progress
   */
  public isLinking(): boolean {
    return this.linking;
  }

  /**
   * Links entity to selected collection
   * @param entity
   */
  @action
  public async link(entity: IEntity) {
    const collection = this.collectionSelectedForLinking;
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!collection && !!user) {
      this.linking = true;
      collection.linkedResourcesCollection = await this.createLinkedCollection(collection);
      try {
        await NewRepository.linkPackage(entity, collection, user);
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("shared.linked_resources.resource_linked", [collection.title]));

        runInAction(() => {
          this.linking = false;
        });
      } catch (err) {
        if (err.key === Settings.tcc.errorIds.collectionAlreadyContainsElement) {
          this.renderErrorNotification("shared.linked_resources.linking_failed_already_contains");
        } else {
          this.renderErrorNotification("shared.linked_resources.linking_failed");
        }
        runInAction(() => {
          this.linking = false;
        });
      }
    }
  }

  /**
   * Returns a collection to which selected versions will be linked to
   * @returns collection or undefined if not set
   */
  public getLinkToCollection(): IDropdownOption | undefined {
    return this.linkToCollection;
  }

  /**
   * Returns collection selected for linking.
   * @returns collection selected for linking or null if no collection was selected
   */
  @computed
  public get collectionSelectedForLinking(): ICollection | undefined {
    if (this.linkToCollection) {
      return _.find(this.getCollectionsWithLinkingRights(), (collection) => {
        return collection.id == this.linkToCollection!.value;
      });
    }
  }

  private fetchCollectionsWithLinkingRights(): Promise<ICollection[]> {
    const user = this.rootStore.getUserStore().getCurrentUser();
    return NewRepository.getCollectionsWithLinkRights({ user: user });
  }

  private async createLinkedCollection(collection: ICollection): Promise<ICollection> {
    return new Promise(async (resolve) => {
      if (collection.linkedResourcesCollection) {
        resolve(collection.linkedResourcesCollection);
      } else {
        const user = this.rootStore.getUserStore().getCurrentUser();
        resolve(await NewRepository.createLinkedResourcesCollection(collection, user));
      }
    });
  }

  private renderErrorNotification(translation: string) {
    this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation(translation));
  }
}

export const LinkToCollectionStoreContext = createStoreContext<LinkToCollectionStore>(LinkToCollectionStore);
