import { TCCUserService } from "./TCCUserService";

const category = "flags";

function set(user, name, type, value) {
  var attributes = {};
  attributes[name] = { dataType: type, value: value };
  return TCCUserService.setAttributes(user.id, { flags: attributes });
}

export const TCCUserFlagsService = {
  get: function (user, name) {
    return TCCUserService.getAttribute(user.id, category, name).then(function (res) {
      return res.value;
    });
  },
  setBoolean: function (user, name, value) {
    return set(user, name, "boolean", value);
  },
};
