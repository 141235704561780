import { action, observable, makeObservable } from "mobx";
import { IBinary } from "../../models/dataModel";
import { createStoreContext } from "../../stores/rootStore";

/**
 * Subscribe store.
 */
export class ImageNaviStore {
  /**
   * index of binary that is currently viewed
   */
  @observable private viewIndex = 0;

  /**
   * index of navi item
   */
  @observable private naviIndex = 0;

  constructor() {
    makeObservable(this);
  }

  /**
   * Method for getting current view index
   * @returns current view index
   */
  public getViewIndex(): number {
    return this.viewIndex;
  }

  /**
   * Method for setting current view index
   * @params index to be set
   */
  @action
  public setViewIndex(viewIndex: number) {
    this.viewIndex = viewIndex;
  }

  /**
   * Method for getting current navi index
   * @returns current navi index
   */
  public getNaviIndex(): number {
    return this.naviIndex;
  }

  /**
   * Resolves if navigating backward is enabled
   * @returns true if navigating backward is enabled
   */
  public canNavigateBackward(): boolean {
    return this.naviIndex > 0;
  }

  /**
   * Resolves if navigating forward is enabled
   * @params binaries List of existing thumbnails
   * @returns true if navigating forward is enabled
   */
  public canNavigateForward(binaries: IBinary[]) {
    return binaries && binaries.length - this.naviIndex > 2;
  }

  /**
   * Navigates backward showing previous thumbnail
   */
  @action
  public navigateBackward = () => {
    this.naviIndex = this.naviIndex - 1;
  };

  /**
   * Navigates forward showing next thumbnail
   */
  @action
  public navigateForward = () => {
    this.naviIndex = this.naviIndex + 1;
  };
}

export const ImageNaviStoreContext = createStoreContext<ImageNaviStore>(ImageNaviStore);
