import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import { Link } from "react-router";

import { RootContext } from "../../stores/rootStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { OrganizationPageStoreContext } from "../organizationPageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { Dialog } from "../../dialogs/Dialog";
import { EditWarehouseUrlDialog } from "./EditWarehouseUrlDialog";

/**
 * A component that displays Warehouse url
 */
export const WarehouseUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const orgStore = useContext(OrganizationPageStoreContext);
  const organization = orgStore.getOrganization();
  const dialog = new DialogStore();

  function editWarehouseUrl() {
    dialog.open();
  }

  function customUrlExists(): boolean {
    return !!organization && !!organization.customWarehouseUrl;
  }

  return (
    <section>
      {editorModeStore.getEditorMode() && (
        <DialogContext.Provider value={dialog}>
          <section className="editwarehouseurl">
            <a
              data-testid="open-edit-warehouse-url-dialog"
              title={rootStore.getTranslation("organization.edit.edit_warehouse_url")}
              onClick={editWarehouseUrl}
              className="icon icon-pencil"
            >
              <span>{rootStore.getTranslation("organization.edit.edit_warehouse_url")}</span>
            </a>
            <Dialog
              content={<EditWarehouseUrlDialog organization={organization!} store={orgStore} />}
              additionalClass="edit-org-description"
            />
          </section>
        </DialogContext.Provider>
      )}
      {customUrlExists() && (
        <section className="warehouseurl">
          <Link data-testid="warehouse-url" className="italic small" to={orgStore.getCustomWarehouseUrl()}>
            {"https://" + window.location.host + "/#" + orgStore.getCustomWarehouseUrl()}&nbsp;&rsaquo;
          </Link>
        </section>
      )}
    </section>
  );
});
