import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";

/**
 * Component for rendering the "Set thumbnail" sections of the form.
 */
export const SetThumbnail = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);

  function getThumbnail() {
    return {
      backgroundImage: `url(${form.thumbnailOrDefault(form.getUploadStage())})`,
    };
  }

  function removeThumbnail() {
    form.removeThumbnail(form.getUploadStage());
  }

  function handleSelectFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target || !event.target.files) return;
    form.setThumbnailFromFile(_.first(event.target.files), form.getUploadStage());
  }

  return (
    <Fragment>
      <label htmlFor="preview">{rootStore.getTranslation("upload.package.thumbnail")}</label>
      <div className="thumb-box">
        <figure style={getThumbnail()} data-testid="thumbnailFigure" />
        <div className="controls">
          <label className="button button-icon upload" htmlFor="fileupload">
            <span className="icon icon-pencil" />
            <span>{rootStore.getTranslation("actions.choose_thumbnail")}</span>
          </label>
          <a className="button button-icon upload" onClick={removeThumbnail} data-testid="removeThumbnail">
            <span className="icon icon-close" />
            <span>{rootStore.getTranslation("collections.actions.delete_thumbnail")}</span>
          </a>
          <input
            className="hidden-file-upload"
            id="fileupload"
            type="file"
            onChange={handleSelectFile}
            onClick={(e: any) => (e.target.value = null)}
            accept="image/*"
            data-testid="uploadThumbnail"
          />
        </div>
      </div>
    </Fragment>
  );
});
