import * as React from "react";
import { RootContext } from "../stores/rootStore";
import { observer } from "mobx-react";
import { CollectionTitle } from "./dialogs/CollectionTitle";
import { EditorMode } from "../components/editor-mode/EditorMode";
import { useContext } from "react";
import { Like } from "../components/like/Like";
import { LikeStore, LikeStoreContext } from "../components/like/likeStore";
import { Subscribe } from "../components/subscribe/Subscribe";
import { SubscribeStore, SubscribeStoreContext } from "../components/subscribe/subscribeStore";
import { Thumbnail } from "../components/thumbnail/Thumbnail";
import { ImageNavi } from "../components/image-navi/ImageNavi";
import { CollectionDescription } from "./dialogs/CollectionDescription";
import { ThumbnailStore, ThumbnailStoreContext } from "../components/thumbnail/thumbnailStore";
import { ImageNaviStore, ImageNaviStoreContext } from "../components/image-navi/imageNaviStore";
import { CollectionPageStoreContext } from "./collectionPageStore";

/**
 * A component that displays organization logo.
 */
export const OrganizationLogo = observer(() => {
  const collectionPageStore = useContext(CollectionPageStoreContext);
  const organization = collectionPageStore.getOrganization();

  function thumbnailDataExists() {
    return organization && organization.binaries && organization.binaries.thumbnail;
  }

  function getThumbnailUrl() {
    return !!organization && !!organization.binaries ? organization.binaries.thumbnail.contentUrl : "";
  }

  return <div className="company-logo">{thumbnailDataExists() && <img src={getThumbnailUrl()} />}</div>;
});

/**
 * A component that displays collection page header actions section.
 */
export const Actions = observer(() => {
  const rootStore = useContext(RootContext);
  const collectionPageStore = useContext(CollectionPageStoreContext);

  return (
    <ul className="header-actions">
      <LikeStoreContext.Provider value={new LikeStore(collectionPageStore.getCollection())}>
        <Like />
      </LikeStoreContext.Provider>
      <SubscribeStoreContext.Provider value={new SubscribeStore(rootStore, collectionPageStore.getCollection())}>
        <Subscribe />
      </SubscribeStoreContext.Provider>
    </ul>
  );
});

/**
 * A component that displays thumbnails, description and image navi
 */
export const Info = observer(() => {
  const rootStore = useContext(RootContext);
  const collectionPageStore = useContext(CollectionPageStoreContext);

  return (
    <article id="collection_info">
      <div className="description">
        <ImageNaviStoreContext.Provider value={new ImageNaviStore()}>
          <ThumbnailStoreContext.Provider value={new ThumbnailStore(rootStore, collectionPageStore.getCollection())}>
            <Thumbnail />
            <ImageNavi />
          </ThumbnailStoreContext.Provider>
        </ImageNaviStoreContext.Provider>
        <CollectionDescription description={collectionPageStore.getDescription()} />
      </div>
    </article>
  );
});

/**
 * A component that displays collection page details section.
 */
export const Details = observer(() => {
  const collectionPageStore = useContext(CollectionPageStoreContext);

  return (
    <section className="content-details">
      <OrganizationLogo />
      {collectionPageStore.canEditCollection() && <EditorMode />}
      <header>
        <CollectionTitle />
        <Actions />
      </header>
      <Info />
    </section>
  );
});
