import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ModusTextInput } from "@trimble-oss/modus-react-components";
import { ModusTextInputCustomEvent } from "@trimble-oss/modus-web-components/loader";

import classNames from "classnames";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const TitleFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleTitleChange(event: ModusTextInputCustomEvent<string>) {
    !!event.detail ? store.setTitle(event.detail) : store.setTitle(undefined);
  }

  return (
    <div className={classNames({ "search-filter": true, sectioned: true })} data-testid="titleFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.title")}:</h5>
      <ModusTextInput
        placeholder={rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.title")}
        value={store.getTitle() || ""}
        clearable={true}
        onValueChange={handleTitleChange}
        data-testid="search-item-title"
      ></ModusTextInput>
    </div>
  );
});
