import { useContext } from "react";

import { ModusRadioGroup } from "@trimble-oss/modus-react-components";
import { ModusRadioGroupCustomEvent } from "@trimble-oss/modus-web-components/loader";
import { observer } from "mobx-react";
import * as React from "react";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";
import { ToggleEnum } from "models/enums";

export const PartnerLinkFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleContentTypeChange(event: ModusRadioGroupCustomEvent<string>) {
    const checked = event.detail;
    checked == ToggleEnum.ALL
      ? store.setHasPartnerLink(undefined)
      : store.setHasPartnerLink(checked == ToggleEnum.SHOW);
  }

  function getCheckedId(): ToggleEnum {
    let checkedId = ToggleEnum.ALL;
    if (store.hasPartnerLink()) {
      checkedId = ToggleEnum.SHOW;
    } else if (store.hasPartnerLink() == false) {
      checkedId = ToggleEnum.HIDE;
    }
    return checkedId;
  }

  return (
    <div className="search-filter" data-testid="partnerLinkFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.special_filters.has_partner_link")}:</h5>
      <ModusRadioGroup
        name="PartnerLinkFilter"
        data-testid="partner-link-filter"
        checkedId={getCheckedId()}
        onButtonClick={handleContentTypeChange}
        radioButtons={[
          {
            id: ToggleEnum.SHOW,
            label: rootStore.getTranslation("profile.admin.content_manager.helpers.yes"),
            checked: store.hasPartnerLink(),
          },
          {
            id: ToggleEnum.HIDE,
            label: rootStore.getTranslation("profile.admin.content_manager.helpers.no"),
            checked: store.hasPartnerLink() == false,
          },
          {
            id: ToggleEnum.ALL,
            label: rootStore.getTranslation("profile.admin.content_manager.helpers.show_all"),
            checked: store.hasPartnerLink() == undefined,
          },
        ]}
      />
    </div>
  );
});
