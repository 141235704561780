import { useContext } from "react";

import { ModusRadioGroup } from "@trimble-oss/modus-react-components";
import { ModusRadioGroupCustomEvent } from "@trimble-oss/modus-web-components/loader";
import { observer } from "mobx-react";
import * as React from "react";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";
import { ToggleEnum } from "models/enums";

export const BannedContentFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleShowBannedChange(event: ModusRadioGroupCustomEvent<string>) {
    const checked = event.detail;
    checked == ToggleEnum.ALL ? store.setShowBanned(undefined) : store.setShowBanned(checked == ToggleEnum.SHOW);
  }

  function getCheckedId(): ToggleEnum {
    let checkedId = ToggleEnum.ALL;
    if (store.showBanned()) {
      checkedId = ToggleEnum.SHOW;
    } else if (store.showBanned() == false) {
      checkedId = ToggleEnum.HIDE;
    }
    return checkedId;
  }

  return (
    <div className="search-filter" data-testid="bannedContentFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.admin_filters.show_banned_content")}:</h5>
      <ModusRadioGroup
        name="bannedContentFilter"
        data-testid="banned-content-filter"
        checkedId={getCheckedId()}
        onButtonClick={handleShowBannedChange}
        radioButtons={[
          {
            id: ToggleEnum.SHOW,
            label: rootStore.getTranslation("profile.admin.content_manager.helpers.yes"),
            checked: store.showBanned(),
          },
          {
            id: ToggleEnum.HIDE,
            label: rootStore.getTranslation("profile.admin.content_manager.helpers.no"),
            checked: store.showBanned() == false,
          },
          {
            id: ToggleEnum.ALL,
            label: rootStore.getTranslation("profile.admin.content_manager.helpers.show_all"),
            checked: store.showBanned() == undefined,
          },
        ]}
      />
    </div>
  );
});
