import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

import { ModusBadge, ModusCard, ModusIcon, ModusSpinner } from "@trimble-oss/modus-react-components";
import { ExternalResourceTypeEnum, ObjectTypeEnum, VisibilityEnum } from "models/enums";
import { Link } from "react-router";
import { RootContext, RootStore } from "stores/rootStore";
import { IBatchCollection, IBatchEntity, IEntity, IItem } from "../../../../models/dataModel";
import { ImageUtils } from "../../../../utils/ImageUtils";
import { Tags } from "./Tags";

const FileTypes = observer(({ item }: { item: IBatchEntity }) => {
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  return (
    <div className="inline-icon">
      <ModusIcon className="icon" name="file_missing" title={"File types"}></ModusIcon>
      {store.isQuerying("fileTypes") ? (
        <ModusSpinner size="0.5rem" color="tertiary" title={"Loading file types"} />
      ) : (
        <span data-testid="file-types">{item.fileTypes?.join(", ")}</span>
      )}
    </div>
  );
});

export const ResultCard = observer(({ item }: { item: IBatchCollection | IBatchEntity }) => {
  const rootStore: RootStore = useContext(RootContext);
  const contentManagerStore: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleClick() {
    contentManagerStore.selectListItem(item, !item.selected);
  }

  function isPackage(item: IItem) {
    return item.type == ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE;
  }

  function isCreatedByOrganization(item: IItem) {
    return item.creator?.externalResourceType == ExternalResourceTypeEnum.ORGANIZATION;
  }

  function hasPartnerDownloadLink(): boolean {
    return !!(item as IEntity).attributes?.contentDownloadUrl;
  }

  const hasBannedContent = !item.isBanned && (item.type == ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE
    ? (item as IBatchEntity).versions?.some(v => v.isBanned) || false
    : (item as IBatchCollection).packages?.some(p => p.isBanned) || false);

  function isPrivate(item: IItem) {
    return item.visibility == VisibilityEnum.PRIVATE;
  }

  function formatDate(date: Date | undefined) {
    if (!date) {
      return "";
    }
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  function getItemThumbnail(item: IItem) {
    let thumbnailUrl = ImageUtils.getDefaultThumbail();

    if (item.isLocal && !!item.thumbnail?.url) {
      thumbnailUrl = item.thumbnail.url;
    } else if (!item.isLocal && !!item.thumbnails && !!item.thumbnails[0].url) {
      thumbnailUrl = item.thumbnails[0].url;
    }

    return {
      backgroundImage: `url(${thumbnailUrl})`,
    };
  }

  function getResourcePath(item: IItem): string {
    return item.type == ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE
      ? `/catalog/details/${item.id}`
      : `/collections/online/${item.id}`;
  }

  function hasContent(): boolean {
    return isPackage(item)
      ? !!(item as IBatchEntity).versionCount && (item as IBatchEntity).versionCount! > 0
      : !!(item as IBatchCollection).packagesCount && (item as IBatchCollection).packagesCount! > 0;
  }

  return (
    <ModusCard
      className={classNames("result-card", { selected: item.selected })}
      data-testid={`result-card-${item.id}`}
      width="100%"
      height="100%"
      borderRadius="1em"
      onClick={handleClick}
    >
      <div className={classNames("item-card", { selected: item.selected })}>
        <div className="thumb-box">
          <figure style={getItemThumbnail(item)} />
          {!!item.attributes?.licensesACL?.includes("teklamaintenance") && (
            <div className="requires-maintenance">
              <div className="inline-icon">
                <ModusIcon className="icon" name="key" data-testid="key-icon"></ModusIcon>
                <span>{rootStore.getTranslation("shared.maintenance.requires_tekla_maintenance")}</span>
              </div>
            </div>
          )}
          <div className="item-status-tags" style={{ display: "flex", flexWrap: "wrap" }}>
            {item.isHidden && (
              <div className="item-tag" key="isHidden">
                <ModusBadge
                  key={rootStore.getTranslation("profile.admin.hidden_package")}
                  color="tertiary"
                  data-testid="isHidden"
                >
                  {rootStore.getTranslation("profile.admin.content_manager.admin_filters.hidden_content")}
                </ModusBadge>
              </div>
            )}
            {item.isBanned && (
              <div className="item-tag" key="isBanned">
                <ModusBadge
                  key={rootStore.getTranslation("profile.admin.banned_content")}
                  color="danger"
                  data-testid="isBanned"
                >
                  {rootStore.getTranslation("profile.admin.content_manager.admin_filters.banned_content")}
                  {" "}
                  {!!item.attributes?.bannedDate && formatDate(new Date(item.attributes?.bannedDate))}
                </ModusBadge>
              </div>
            )}
            {hasBannedContent && (
              <div className="item-tag" key="hasBannedContent">
                <ModusBadge
                  key={rootStore.getTranslation("profile.admin.banned_content")}
                  color="danger"
                  data-testid="hasBannedContent"
                >
                  {rootStore.getTranslation("profile.admin.content_manager.admin_filters.includes_banned_content")}
                </ModusBadge>
              </div>
            )}
            {isPackage(item) && item.isImmutable && (
              <div className="item-tag" key="isImmutable">
                <ModusBadge
                  key={rootStore.getTranslation("immutability.immutable")}
                  color="tertiary"
                  data-testid="isImmutable"
                >
                  {rootStore.getTranslation("immutability.immutable")}
                </ModusBadge>
              </div>
            )}
          </div>
        </div>
        <div className="item-content">
          <div className="item-header">
            <div className="inline-icon">
              {isPrivate(item) && (
                <ModusIcon
                  className="icon"
                  name="lock"
                  data-testid="lock-icon"
                  title={rootStore.getTranslation("collections.labels.visibilityOptions.private")}
                />
              )}
              <Link data-testid="title" to={getResourcePath(item)}>
                {item.title}
              </Link>
            </div>
          </div>
          <div className="item-description">
            <div className="item-attributes">
              {isPackage(item) ? (
                <div className="inline-icon">
                  <ModusIcon className="icon" name="master_data" title={"Version count"} />
                  <span data-testid="version-count">{(item as IBatchEntity).versionCount}</span>
                </div>
              ) : (
                <div className="inline-icon">
                  <ModusIcon className="icon" name="master_data" title={"Content count"} />
                  <span data-testid="content-count">{(item as IBatchCollection).packagesCount}</span>
                </div>
              )}
              <div className="inline-icon">
                <ModusIcon
                  className="icon"
                  name="visibility_on"
                  title={rootStore.getTranslation("downloads_graph.views")}
                />
                <span data-testid="view-count">{item.views}</span>
              </div>
              {isPackage(item) && (
                <>
                  {hasPartnerDownloadLink() ? (
                    <div className="inline-icon">
                      <ModusIcon
                        className="icon"
                        name="link"
                        title="Has a partner download link"
                        data-testid="hasPartnerDownloadLink"
                      />
                      <span>Has a partner download link</span>
                    </div>
                  ) : (
                    <div className="inline-icon">
                      <ModusIcon
                        className="icon"
                        name="download"
                        title={rootStore.getTranslation("downloads_graph.downloads")}
                      />
                      <span data-testid="download-count">{(item as IBatchEntity).downloads}</span>
                    </div>)}
                </>
              )}
              {isPackage(item) && hasContent() && <FileTypes item={item as IBatchEntity} />}
            </div>
            {isPackage(item) && <Tags item={item} />}
          </div>
          <div className="item-footer">
            <div className="inline-icon">
              {isCreatedByOrganization(item) ? (
                <ModusIcon
                  className="icon"
                  name="buildings"
                  title={rootStore.getTranslation("upload.contentOwner")}
                ></ModusIcon>
              ) : (
                <ModusIcon
                  className="icon"
                  name="person"
                  title={rootStore.getTranslation("upload.contentOwner")}
                ></ModusIcon>
              )}
              <span data-testid="creator">{item.creator?.displayName}</span>
            </div>
            <div className="inline-icon">
              <ModusIcon
                className="icon"
                name="add"
                title={rootStore.getTranslation("notifications.createdAt")}
              ></ModusIcon>
              <span data-testid="created-date">{formatDate(item.createdAt)}</span>
            </div>
            <div className="inline-icon">
              <ModusIcon
                className="icon"
                name="file_edit"
                title={rootStore.getTranslation("constants.sortOptions.-modifiedAt")}
              ></ModusIcon>
              <span data-testid="last-modified">
                {`${formatDate(item.modifiedAt)} 
                ${rootStore.getTranslation("helpers.behalf").toLowerCase()} 
                ${item.modifier?.displayName}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModusCard>
  );
});
