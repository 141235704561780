import _ from "underscore";

import { UserStore } from "../stores/userStore";
import { RootStore } from "../stores/rootStore";
import { Auth } from "../js/services/Auth";
import { NavigateFunction } from "react-router";

/**
 * Class for handling routing related tasks
 */
export class Router {
  /**
   * Rootstore instance
   */
  private rootStore: RootStore;

  /**
   * The navigate function from react-router
   */
  private navigate?: NavigateFunction;

  /**
   * Constructor
   * @param rootStore rootStore
   */
  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Handles router state changes
   */
  public async handleStateChange(pathname: string): Promise<void> {
    if (this.shouldForceReload(pathname)) {
      location.reload();
    } else {
      const userStore: UserStore = this.rootStore.getUserStore();
      const whiteList = ["/auth/handleUnauthorized", "/auth/validate", "/auth/sso", "/auth/tcc_sso", "/auth/failure"];

      await Auth.validateATCUser(userStore.fetchData());

      if (!_.contains(whiteList, pathname)) {
        if (userStore.shouldDoLogin()) {
          userStore.login();
        }
      }
    }
    localStorage.setItem("routingPath", pathname);
  }

  /**
   * Initializes the router with react-router navigate function
   * @param navigate navigate function
   */
  public initStore(navigate: NavigateFunction) {
    this.navigate = navigate;
  }

  /**
   * Changes to new route
   * @params newPath The route to change to
   * @params params Search params for the url
   */
  public changeRoutingState(newPath: string, params?: Record<string, any>) {
    const url = !!params ? newPath + "?" + new URLSearchParams(params).toString() : newPath;
    !!this.navigate && this.navigate(url);
  }

  /**
   * Gets router query param
   * @params paramName The param to be fetched
   */
  public getQueryParam(paramName: string): string {
    const params = new URLSearchParams(window.location.search);
    return params.get(paramName) || "";
  }

  /**
   * Goes to previous state.
   */
  public goToPreviousState(): void {
    if (this.navigate) {
      this.navigate(-1);
    }
  }

  private shouldForceReload(pathname: string): boolean {
    const previousPath = localStorage.getItem("routingPath");

    if (!!previousPath) {
      if (pathname === previousPath) {
        return false;
      } else if (pathname.indexOf("/catalog/details") > -1) {
        return previousPath.indexOf("/catalog/details") > -1;
      } else if (pathname.indexOf("/catalog/localdetails") > -1) {
        return previousPath.indexOf("/catalog/localdetails") > -1;
      }
    }
    return false;
  }
}
