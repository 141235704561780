import { ISupportedLocales } from "../models/dataModel";

/**
 * Supported locales list
 */
export const supportedLocales: ISupportedLocales = {
  "en-US": { isoCode: "en-US", lang: "en", langKey: "en" },
  "cs-CZ": { isoCode: "cs-CZ", lang: "cs", langKey: "cs" },
  "de-DE": { isoCode: "de-DE", lang: "de", langKey: "de" },
  "es-ES": { isoCode: "es-ES", lang: "es", langKey: "es" },
  "fr-FR": { isoCode: "fr-FR", lang: "fr", langKey: "fr" },
  "hu-HU": { isoCode: "hu-HU", lang: "hu", langKey: "hu" },
  "it-IT": { isoCode: "it-IT", lang: "it", langKey: "it" },
  "ja-JP": { isoCode: "ja-JP", lang: "ja", langKey: "ja" },
  "nl-NL": { isoCode: "nl-NL", lang: "nl", langKey: "nl" },
  "pl-PL": { isoCode: "pl-PL", lang: "pl", langKey: "pl" },
  "pt-PT": { isoCode: "pt-PT", lang: "pt-PT", langKey: "pt" },
  "pt-BR": { isoCode: "pt-BR", lang: "pt-BR", langKey: "pt-br" },
  "ru-RU": { isoCode: "ru-RU", lang: "ru", langKey: "ru" },
  "zh-CN": { isoCode: "zh-CN", lang: "zh-CN", langKey: "zh-Hans" },
  "zh-TW": { isoCode: "zh-TW", lang: "zh-TW", langKey: "zh-Hant" },
  "ko-KR": { isoCode: "ko-KR", lang: "ko", langKey: "ko" },
};
