import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import { Fragment } from "react";

import { RootContext } from "../../stores/rootStore";
import { LinkToCollectionStore, LinkToCollectionStoreContext } from "./linkToCollectionStore";
import { PackagePageStoreContext } from "../../package/packagePageStore";

import { TranslatedHtml } from "../TranslatedHtml";
import { Dropdown } from "../Dropdown";

import { ICollection, IDropdownOption } from "../../models/dataModel";

/**
 * A component displaying a popup that let user select collection to link an entity to.
 */
const Popup = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const linkToCollectionStore = useContext(LinkToCollectionStoreContext);

  const collectionOptions: IDropdownOption[] = _.map(
    linkToCollectionStore.getCollectionsWithLinkingRights(),
    (collection: ICollection) => {
      return {
        label: (
          <div className="flex-space-between">
            {collection.title}
            <span className="small text-align-end">{` (${collection.creator?.displayName})`}</span>
          </div>
        ),
        value: collection.id!,
      };
    },
  );

  function hidePopup() {
    linkToCollectionStore.setLinkingPopupVisible(false);
  }

  function changeCollection(opt: IDropdownOption | null) {
    if (!opt) return;
    linkToCollectionStore.setLinkToCollection(opt);
  }

  function link() {
    try {
      if (packageItem) {
        linkToCollectionStore.link(packageItem);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Fragment>
      {linkToCollectionStore.getLinkingPopupVisible() && (
        <div className="link-popup" onMouseLeave={hidePopup} data-testid="linking-popup">
          {linkToCollectionStore.getQueryingLinks() && (
            <div className="querying">
              <div className="spinner spinner-gray">
                <TranslatedHtml entry="shared.spinner_loading" />
              </div>
            </div>
          )}
          {!linkToCollectionStore.getQueryingLinks() && (
            <div className="popup-content" data-testid="linking-popup-content">
              <Dropdown
                data-testid="select-collection-dropdown"
                options={collectionOptions}
                onChange={changeCollection}
                placeholder={rootStore.getTranslation("details.links.select_collection_placeholder")}
                className="dropdown-wrapper light"
                selectedValue={linkToCollectionStore.getLinkToCollection()}
                inputId="link-to-collection-select"
              />
              <div className="l-center-align">
                {!linkToCollectionStore.isLinking() && (
                  <button
                    className="link"
                    disabled={!linkToCollectionStore.getLinkToCollection()}
                    onClick={link}
                    data-testid="link-button"
                  >
                    <TranslatedHtml entry="details.links.link" />
                  </button>
                )}
                {linkToCollectionStore.isLinking() && (
                  <div className="l-margin-default spinner spinner-gray">
                    <TranslatedHtml entry="shared.linked_resources.linking" />
                  </div>
                )}
              </div>
              <TranslatedHtml entry="details.links.private_content_note" />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
});

/**
 * A components that provides link to collection feature.
 */
export const LinkToCollection = observer(() => {
  const linkToCollectionStore: LinkToCollectionStore = useContext(LinkToCollectionStoreContext);

  function showPopup() {
    linkToCollectionStore.setLinkingPopupVisible(true);
  }

  return (
    <Fragment>
      {linkToCollectionStore.wasDataFetched() && (
        <li className="action-item links">
          <a data-testid="link-to-collection" className="icon icon-link" onClick={showPopup}></a>
          <Popup />
        </li>
      )}
    </Fragment>
  );
});
