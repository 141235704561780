import { useContext } from "react";

import { IDropdownOption } from "models/dataModel";
import _ from "underscore";
import { metadataLabels } from "utils/MetadataLabels";

import { ModusDropdownMultiple } from "components/ModusDropdownMultiple";
import { observer } from "mobx-react";
import * as React from "react";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const LocationFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const locationRestrictionOptions: IDropdownOption[] = _.map(metadataLabels.countryOptions, (option) => {
    return {
      value: option.value,
      label: rootStore.getTranslation(option.name),
    };
  });

  function handleLocationChange(selectedOptions: readonly IDropdownOption[]) {
    !_.isEmpty(selectedOptions) ? store.setLocations(selectedOptions) : store.setLocations(undefined);
  }

  return (
    <div className="search-filter" data-testid="locationFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.locations")}:</h5>
      <ModusDropdownMultiple
        placeholder={rootStore.getTranslation("placeholders.filter-options.locations")}
        className="dropdown-locations"
        inputId="locations"
        options={locationRestrictionOptions}
        onChange={handleLocationChange}
        selectedValues={store.getLocations()}
        isSearchable={true}
      />
    </div>
  );
});
