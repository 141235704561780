/* eslint-disable no-async-promise-executor */
import { action, computed, observable, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../../js/services/NewRepository";

import { ICollection, IDropdownOption, IEntity, ILinkingResult } from "../../models/dataModel";
import { Settings } from "../../config/Settings";

import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import { createStoreContext, RootStore } from "../../stores/rootStore";

export class LinkToCollectionStore extends AbstractAsyncStore {
  /**
   * A collection selected entities should be linked to.
   */
  @observable private linkToCollection?: IDropdownOption;

  /**
   * A flag marking if linking process is in progress.
   */
  @observable private linking = false;

  /**
   * A flag marking if collections with linking rights are being fetched.
   */
  @observable private queryingLinks = false;

  /**
   * Collections that entities can be linked to.
   */
  @observable private collectionsWithLinkingRights?: ICollection[];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.fetchData();
  }

  @action
  public async fetchData() {
    this.queryingLinks = true;
    this.dataFetched = false;
    this.loading = true;

    try {
      const collectionsWithLinkingRight = await this.fetchCollectionsWithLinkingRights();

      runInAction(() => {
        this.collectionsWithLinkingRights = collectionsWithLinkingRight;
        this.loading = false;
        this.dataFetched = true;
        this.queryingLinks = false;
      });
    } catch (err) {
      runInAction(() => {
        this.loading = false;
        this.dataFetched = true;
        this.queryingLinks = false;
      });
    }
  }

  /**
   * Returns collections with linking rights.
   * @returns collections with linking rights
   */
  public getCollectionsWithLinkingRights(): ICollection[] {
    return this.collectionsWithLinkingRights || [];
  }

  /**
   * Returns querying collections with collections rights process status
   * @returns true if collections are being queried
   */
  public getQueryingLinks(): boolean {
    return this.queryingLinks;
  }

  /**
   * Sets collection to which selected entities should be linked to
   * @param collection selected entities should be linked to
   */
  @action
  public setLinkToCollection(collection: IDropdownOption) {
    this.linkToCollection = collection;
  }

  /**
   * Checks if linking process is in progress
   * @returns true if linking process is in progress
   */
  public isLinking(): boolean {
    return this.linking;
  }

  /**
   * Links entities to selected collection
   * @param entities
   */
  @action
  public async link(entities: IEntity[]): Promise<ILinkingResult> {
    const collection = this.collectionSelectedForLinking;
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!collection && !!user) {
      const linkingResult: ILinkingResult = {
        linkedCount: 0,
        collection: collection,
        failures: [],
      };
      this.linking = true;
      collection.linkedResourcesCollection = await this.createLinkedCollection(collection);

      const promises: Array<Promise<unknown>> = _.map(entities, (entity) => {
        return new Promise<void>(async (resolve) => {
          try {
            await NewRepository.linkPackage(entity, collection, user);
            linkingResult.linkedCount += 1;
            resolve();
          } catch (err) {
            if (err.key === Settings.tcc.errorIds.collectionAlreadyContainsElement) {
              linkingResult.failures.push({
                entity: entity,
                error: this.rootStore.getTranslation("shared.linked_resources.linking_failed_already_contains_short"),
              });
            } else {
              linkingResult.failures.push({ error: "", entity: entity });
            }
            resolve();
          }
        });
      });
      await Promise.all(promises);
      runInAction(() => {
        this.linking = false;
      });
      return linkingResult;
    } else {
      throw new Error("no selected collection");
    }
  }

  /**
   * Unlinks entities from given collection
   * @param entities
   */
  @action
  public async unlink(entities: IEntity[], collection: ICollection): Promise<ILinkingResult> {
    if (collection) {
      const linkingResult: ILinkingResult = {
        linkedCount: 0,
        collection: collection,
        failures: [],
      };
      this.linking = true;

      const promises: Array<Promise<unknown>> = _.map(entities, (entity) => {
        return new Promise<void>(async (resolve) => {
          try {
            if (!!collection.linkedResourcesCollection) {
              await NewRepository.unlinkPackage(entity, collection.linkedResourcesCollection);
              linkingResult.linkedCount += 1;
              resolve();
            }
          } catch (err) {
            if (err.key === Settings.tcc.errorIds.collectionDoesNotContainElement) {
              linkingResult.failures.push({
                entity: entity,
                error: this.rootStore.getTranslation("shared.linked_resources.unlinking_failed_does_not_contain_short"),
              });
            } else {
              linkingResult.failures.push({ error: "", entity: entity });
            }
            resolve();
          }
        });
      });
      await Promise.all(promises);
      this.linking = false;
      return linkingResult;
    } else {
      throw new Error("no selected collection");
    }
  }

  /**
   * Returns a collection to which selected versions will be linked to
   * @returns collection or undefined if not set
   */
  public getLinkToCollection(): IDropdownOption | undefined {
    return this.linkToCollection;
  }

  /**
   * Returns collection selected for linking.
   * @returns collection selected for linking or null if no collection was selected
   */
  @computed
  public get collectionSelectedForLinking(): ICollection | undefined {
    if (this.linkToCollection) {
      return _.find(this.getCollectionsWithLinkingRights(), (collection) => {
        return collection.id == this.linkToCollection!.value;
      });
    }
  }

  private async fetchCollectionsWithLinkingRights(): Promise<ICollection[]> {
    const user = this.rootStore.getUserStore().getCurrentUser();
    return NewRepository.getCollectionsWithLinkRights({ user: user });
  }

  private async createLinkedCollection(collection: ICollection): Promise<ICollection> {
    if (collection.linkedResourcesCollection) {
      return collection.linkedResourcesCollection;
    } else {
      const user = this.rootStore.getUserStore().getCurrentUser();
      return NewRepository.createLinkedResourcesCollection(collection, user);
    }
  }
}

export const LinkToCollectionStoreContext = createStoreContext<LinkToCollectionStore>(LinkToCollectionStore);
