import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";
import { DateTimeFormatOptions } from "luxon";

import { RootStore, RootContext } from "../../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../../uploadFormStore";
import { UploadVersionStore } from "../uploadVersionStore";
import { DialogContext, DialogStore } from "../../../dialogs/dialogStore";

import { IFileItem } from "../../../models/dataModel";
import { ConfirmAction } from "../../../dialogs/ConfirmAction";
import { ScanResultsChecker } from "../../../utils/ScanResultsChecker";
import { NewRepository } from "../../../js/services/NewRepository";

/**
 * Renders the virus scan information dialog.
 */
const VirusScanResults = (binary) => {
  const rootStore: RootStore = useContext(RootContext);
  const scanResultDialog: DialogStore = useContext(DialogContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  let infoMsg = "";
  let titleTxt = "";

  if (ScanResultsChecker.binaryHasSoftFailed(binary.item)) {
    titleTxt = rootStore.getTranslation("details.scan.popup.soft_failed_binaries_title");
    infoMsg = rootStore.getTranslation("details.scan.popup.soft_failed_binaries_info");
  } else if (ScanResultsChecker.binaryHasHardFailed(binary.item)) {
    titleTxt = rootStore.getTranslation("details.scan.popup.hard_failed_binaries_title");
    infoMsg = rootStore.getTranslation("details.scan.popup.hard_failed_binaries_info");
  } else if (ScanResultsChecker.binaryIsInfected(binary.item)) {
    titleTxt = rootStore.getTranslation("details.scan.popup.infected_binaries_title");
    infoMsg = rootStore.getTranslation("details.scan.popup.infected_binaries_info");
  }

  function showScanResults() {
    scanResultDialog.open();
  }

  return (
    <Fragment>
      <a className="scan-failed-binary" onClick={showScanResults}>
        {version.getScanResultText(binary)}
      </a>
      <ConfirmAction
        title={titleTxt}
        content={
          <ul className="scan-errors">
            <ul className="scan-errors-info">
              {infoMsg} <br />
            </ul>
          </ul>
        }
        labelConfirm={rootStore.getTranslation("shared.confirm.close")}
        labelCancel={""}
        callbackCloseDialog={() => scanResultDialog.close()}
        callbackConfirm={() => { }}
      />
    </Fragment>
  );
};

/**
 * Component for a file row in the 'Existing files' list.
 */
const FileRow = observer(({ item }: { item: IFileItem }) => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();
  const scanResultDialog: DialogStore = new DialogStore();

  async function rescanBinary() {
    const currentUser = rootStore.getUserStore().getCurrentUser();
    const creator = form.getCollectionStore().getCollection().creator;

    if (!!currentUser) {
      try {
        await NewRepository.rescanBinary(item, creator?.id || currentUser.id);
        rootStore.getNotificationChannelStore().success(rootStore.getTranslation("versions.virus_scan.scan_started"));
      } catch {
        console.log("Error while sending rescan request for binary ", item.id);
      }
    }
  }

  function removeFile() {
    version.removeSelectedFile(item, "existingFiles");
  }

  const showFailedScan = !form.isLocal() || !version.hasPassedScan();
  const showScanInProgress = !form.isLocal() && version.scanInProgress(item) && rootStore.getUserStore().isUserAdmin();
  const showRescan = !form.isLocal() && !version.hasPassedScan() && rootStore.getUserStore().isUserAdmin();

  function getModifiedAt() {
    if (!item.modifiedAt || isNaN(item.modifiedAt.getDate())) {
      return "";
    } else {
      const modifiedDate = item.modifiedAt;
      const locale = rootStore.getLocalizationStore().getLocale();

      const options: DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

      return new Intl.DateTimeFormat(locale, options).format(modifiedDate);
    }
  }

  return (
    <Fragment>
      <td>{item.attributes!.fileName}</td>
      {version.isTool() ? (
        <td>{rootStore.getTranslation("platforms." + item.attributes!.platform)}</td>
      ) : (
        <td>{rootStore.getTranslation("contentItemSource." + item.attributes!.itemType)}</td>
      )}
      {!form.isLocal() && (
        <Fragment>
          <td data-testid="modifiedAt">{getModifiedAt()}</td>
          <td>{version.getFileSize(item.attributes!.fileSize)}</td>
        </Fragment>
      )}
      {showFailedScan && (
        <td>
          <DialogContext.Provider value={scanResultDialog}>
            <VirusScanResults item={item} />
          </DialogContext.Provider>
        </td>
      )}
      {showScanInProgress && (
        <td>
          <div className="scan-in-progress">{rootStore.getTranslation("versions.virus_scan.scan_in_progress")}</div>
        </td>
      )}
      {showRescan && (
        <td>
          <a className="rescan-binary" onClick={rescanBinary}>
            {rootStore.getTranslation("versions.virus_scan.rescan")}
          </a>
        </td>
      )}
      {!form.editingImmutableVersion() && (
        <td className="auto-width">
          <a className="icon icon-trash" onClick={removeFile} data-testid="removeFileRow" />
        </td>
      )}
    </Fragment>
  );
});

/**
 * Wrapper components for rendering the list of existing files.
 */
const FileList = observer(() => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  return (
    <tbody>
      {version.getExistingFiles().map((item: IFileItem, i: number) => {
        return (
          <tr key={i}>
            <FileRow item={item} />
          </tr>
        );
      })}
    </tbody>
  );
});

/**
 * Renders the 'Existing files' view.
 */
export const ExistingFiles = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  return (
    <section className="existing-files" data-testid="existingFiles">
      <Fragment>
        <hr />
        <header>
          <h3
            className="tooltip-info"
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}
          >
            {rootStore.getTranslation("versions.files")}
            {form.editingImmutableVersion() && (
              <span className="icon help blue" title={rootStore.getTranslation("immutability.immutable_files_info")} />
            )}
          </h3>
        </header>
        <div className="wrapper full-width-center">
          <table data-testid="existingFilesTable">
            <thead>
              <tr>
                <th>{rootStore.getTranslation("versions.table_header.name")}</th>
                {version.isTool() ? (
                  <th>{rootStore.getTranslation("versions.table_header.platform")}</th>
                ) : (
                  <th>{rootStore.getTranslation("versions.table_header.type")}</th>
                )}
                {!form.isLocal() && (
                  <Fragment>
                    <th>{rootStore.getTranslation("versions.table_header.modified_at")}</th>
                    <th>{rootStore.getTranslation("versions.table_header.size")}</th>
                  </Fragment>
                )}
                {(form.isLocal() || version.shouldShowVirusScanHeader()) && (
                  <th>{rootStore.getTranslation("versions.table_header.virus_scan")}</th>
                )}
                <th className="auto-width" />
              </tr>
            </thead>
            <FileList />
          </table>
        </div>
      </Fragment>
    </section>
  );
});
