import { action, observable, makeObservable } from "mobx";
import { DateTime } from "luxon";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { IAnalyticsFiltersSetter } from "../../components/analytics/analyticsStore";
import { AnalyticsStore } from "../../components/analytics/analyticsStore";
import { AnalyticsTimeFrameEnum } from "../../models/enums";

/**
 * Statisics store.
 */

export class StatisticsStore implements IAnalyticsFiltersSetter {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Analytics store.
   */
  private analyticsStore?: AnalyticsStore;

  /**
   * Timeframe selection to filter analytics data
   */
  @observable private timeFrame: string = AnalyticsTimeFrameEnum.THIS_MONTH;

  /**
   * Start date used for filtering analytics data, calculated based on timeframe selection
   */
  @observable private startDate!: Date;

  /**
   * End date used for filtering analytics data, calculated based on timeframe selection
   */
  @observable private endDate!: Date;

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param resource Resource instance
   */
  public constructor(rootStore: RootStore, analyticsStore?: AnalyticsStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.startDate = DateTime.fromObject({ hour: 0 }).minus({ days: 30 }).toJSDate();
    this.endDate = new Date();
    if (analyticsStore) {
      this.analyticsStore = analyticsStore;
      this.fetchData();
    }
  }

  /**
   * Gets timeframe
   * @returns timeframe value
   */
  public getTimeFrame(): string {
    return this.timeFrame;
  }

  /**
   * Sets timeframe
   * @params timeFrame the value to be set
   */
  @action
  public setTimeFrame = (timeFrame: string) => {
    this.timeFrame = timeFrame;
    this.fetchData();
  };

  /**
   * Gets start date
   * @returns start date value
   */
  public getStartDate(): Date {
    return this.startDate;
  }

  /**
   * Sets start date
   * @params startDate the value to be set
   */
  @action
  public setStartDate = (startDate: Date) => {
    this.startDate = startDate;
    this.fetchData();
  };

  /**
   * Gets end data
   * @returns end date value
   */
  public getEndDate(): Date {
    return this.endDate;
  }

  /**
   * Sets end date
   * @params endDate the value to be set
   */
  @action
  public setEndDate = (endDate: Date) => {
    this.endDate = endDate;
    this.fetchData();
  };

  private fetchData() {
    if (this.analyticsStore) {
      this.analyticsStore.fetchStatisticsData(this.getTimeFrame(), this.getStartDate(), this.getEndDate());
    }
  }
}

export const StatisticsStoreContext = createStoreContext<StatisticsStore>(StatisticsStore);
