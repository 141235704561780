import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { RootStore, RootContext } from "../stores/rootStore";
import { DownloaderStore } from "./downloaderStore";

/**
 * A component that handles download requests
 */
export const Downloader = () => {
  const store: RootStore = useContext(RootContext);
  const downloaderStore = new DownloaderStore(store);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const collectionId = queryParams.get("collectionId") || "";
  const packageId = queryParams.get("packageId") || "";
  const entityId = queryParams.get("entityId") || "";
  const binaryId = queryParams.get("binaryId") || "";

  useEffect(() => {
    downloaderStore.download(collectionId, packageId, entityId, binaryId);
  }, [collectionId, packageId, entityId, binaryId]);

  return <Fragment></Fragment>;
};
