import axios from "axios";

function getPath() {
  return decodeURIComponent(window.location.href);
}

function setUpInterceptors(errorHandlerStore) {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (!!err.response && !!err.response.status && err.response.status === 401) {
        errorHandlerStore.handleUnauthorized();
      }
      return Promise.reject(err);
    },
  );
}

export const URLInterceptor = {
  initialize: function (errorHandlerStore) {
    setUpInterceptors(errorHandlerStore);
  },
  isUrlBroken: function () {
    return this.isUrlInOldFormat() || this.searchParamsBeforeHash() || getPath().indexOf("#_") > -1;
  },
  isUrlInOldFormat: function () {
    return getPath().indexOf("#/!/") > -1 || getPath().indexOf("#!/") > -1;
  },
  searchParamsBeforeHash: function () {
    return getPath().indexOf("?") > -1 && getPath().indexOf("?") < getPath().indexOf("#");
  },
  fixUrlAndRedirect: function () {
    let newUrl = getPath();
    let reload = true;

    if (this.searchParamsBeforeHash()) {
      newUrl = window.location.origin + "/" + window.location.hash + window.location.search;
      reload = false;
    }

    if (this.isUrlInOldFormat()) {
      if (getPath().indexOf("#/!/") > -1) {
        newUrl = newUrl.replace("#/!/", "#/");
      } else if (getPath().indexOf("#!/") > -1) {
        newUrl = newUrl.replace("#!/", "#/");
      }
    } else if (getPath().indexOf("#_") > -1) {
      newUrl = newUrl.replace("#_", "#/_");
    }

    window.location.href = newUrl;
    !!reload && location.reload();
  },
};
