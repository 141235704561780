import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import classNames from "classnames";

import { RootStore, RootContext } from "../../stores/rootStore";
import { ISessionItemGroup, ItemBrowserStore, ItemBrowserStoreContext } from "./itemBrowserStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { UploadFormContext, UploadFormStore } from "../../upload/uploadFormStore";
import { UploadVersionStore } from "../../upload/version/uploadVersionStore";
import { TreeView } from "./tree-view/TreeView";
import { TreeViewStoreContext } from "./tree-view/treeViewStore";

/**
 * Renders item groups for ItemBrowser
 * @param group rendered item group
 */
const ItemGroup = observer(({ group }: { group: ISessionItemGroup }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ItemBrowserStore = useContext(ItemBrowserStoreContext);

  const [isOpen, setIsOpen] = useState(group.open);
  const [isLoading, setIsLoading] = useState(group.loading);

  function openGroupItem() {
    if (!isOpen && !group.items) {
      setIsLoading(true);
      store.openGroupTreeView(group);
      setIsLoading(false);
    }

    setIsOpen(!isOpen);
  }

  return (
    <li data-testid="item-group">
      <a className={classNames("header", { selected: isOpen })} onClick={openGroupItem}>
        {rootStore.getTranslation("contentItemHeader." + group.type)}
      </a>
      {isLoading && (
        <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_loading_long_time")}</div>
      )}
      {isOpen && (
        <div className="content treeview" data-testid="group-open">
          <TreeViewStoreContext.Provider value={group.tree!}>
            <TreeView treeId={"tsItemTree" + group.type} items={group.items!} />
          </TreeViewStoreContext.Provider>
        </div>
      )}
    </li>
  );
});

/**
 * Renders the ItemBrowser component for Add version page.
 */
export const ItemBrowser = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();
  const store: ItemBrowserStore = useContext(ItemBrowserStoreContext);
  const dialog: DialogStore = useContext(DialogContext);

  const [sessionItems, setSessionItems] = useState<ISessionItemGroup[]>([]);
  const loadingSessionItems = !sessionItems || sessionItems.length <= 0;

  useEffect(() => {
    async function init() {
      const items = await store.fetchItems();
      setSessionItems(items);
    }

    init();
  }, []);

  function cancel() {
    dialog.close();
  }

  function selectItems() {
    const exportItems = [];
    _.map(sessionItems, (itemGroup: ISessionItemGroup) => {
      store.gatherExports(itemGroup.items!, itemGroup.type, exportItems);
    });

    dialog.close();
    version.selectFiles(exportItems, "filesFromTS");
  }

  return (
    <article data-testid="itemBrowser">
      <header>
        <h2>{rootStore.getTranslation("shared.item_browser_title")}</h2>
        <span className="info">{rootStore.getTranslation("shared.spinner_loading_long_time")}</span>
      </header>
      <section>
        {loadingSessionItems ? (
          <div className="loader">
            <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_loading")}</div>
          </div>
        ) : (
          <ul className="tree-item-browser" data-testid="treeItems">
            {_.map(sessionItems, (group: ISessionItemGroup, i: number) => {
              return <ItemGroup group={group} key={i} />;
            })}
          </ul>
        )}
      </section>
      <div className="actions">
        <button onClick={cancel}>{rootStore.getTranslation("actions.cancel")}</button>
        <button className="button-primary" onClick={selectItems}>
          {rootStore.getTranslation("actions.select")}
        </button>
      </div>
    </article>
  );
});
