import _ from "underscore";

import { IFileItem, IVersion } from "../models/dataModel";
import { JobResultCodeEnum } from "../models/enums";

function jobResultsExist(binary: IFileItem): boolean {
  return !!binary.jobResults && binary.jobResults.length > 0 && !!binary.jobResults[0].resultCode;
}

function binaryHasStatus(binary: IFileItem, status: string): boolean {
  return jobResultsExist(binary) && binary.jobResults![0].resultCode === status ? true : false;
}

function filterBinariesByStatus(versions: IVersion[], failedStatus: string) {
  const failedBinaries: IFileItem[] = [];
  _.each(versions, function (version) {
    _.each(version.binaries, function (binary) {
      if (binaryHasStatus(binary, failedStatus)) {
        binary.versionName = version.title;
        binary.versionId = version.id;
        failedBinaries.push(binary);
      }
    });
  });
  return failedBinaries;
}

/**
 * Helper class for checking virus scan results
 */
export const ScanResultsChecker = {
  /**
   * Filters given versions binaries with soft failed scan status
   * @param versions the versions which binaries should be filtered
   */
  filterSoftFailedBinaries: (versions: IVersion[]): IFileItem[] => {
    return filterBinariesByStatus(versions, JobResultCodeEnum.SOFT_FAILED);
  },
  /**
   * Filters given versions binaries with hard failed scan status
   * @param versions the versions which binaries should be filtered
   */
  filterHardFailedBinaries: (versions: IVersion[]): IFileItem[] => {
    return filterBinariesByStatus(versions, JobResultCodeEnum.HARD_FAILED);
  },
  /**
   * Filters given versions binaries with infected scan status
   * @param versions the versions which binaries should be filtered
   */
  filterInfectedBinaries: (versions: IVersion[]): IFileItem[] => {
    return filterBinariesByStatus(versions, JobResultCodeEnum.INFECTED);
  },
  /**
   * Checks if version binaries have passed the scan
   * @param version the version which binaries should be checked
   */
  versionBinariesHavePassedScan: (version: IVersion): boolean => {
    const failedBinaries: IFileItem[] = _.filter(version.binaries, function (binary) {
      return binaryHasStatus(binary, JobResultCodeEnum.INFECTED);
    });
    return failedBinaries.length === 0;
  },
  /**
   * Checks if binary has soft failed on scan
   * @param binary the binary to be checked
   */
  binaryHasSoftFailed: (binary: IFileItem): boolean => {
    return binaryHasStatus(binary, JobResultCodeEnum.SOFT_FAILED);
  },
  /**
   * Checks if binary has hard failed on scan
   * @param binary the binary to be checked
   */
  binaryHasHardFailed: (binary: IFileItem): boolean => {
    return binaryHasStatus(binary, JobResultCodeEnum.HARD_FAILED);
  },
  /**
   * Checks if binary is infected
   * @param binary the binary to be checked
   */
  binaryIsInfected: (binary: IFileItem): boolean => {
    return binaryHasStatus(binary, JobResultCodeEnum.INFECTED);
  },
  /**
   * Checks if binary scan is in progress
   * @param binary the binary to be checked
   */
  binaryScanInProgress: (binary: IFileItem): boolean => {
    return binaryHasStatus(binary, JobResultCodeEnum.IN_PROGRESS);
  },
  /**
   * Checks if any of version binaries are being scanned
   * @param version the version which binaries should be checked
   */
  versionBinariesScanInProgress: (version: IVersion): boolean => {
    const binariesInProgress: IFileItem[] = filterBinariesByStatus([version], JobResultCodeEnum.IN_PROGRESS);
    return binariesInProgress.length > 0;
  },
  /**
   * Checks if any of version binaries have binaries that scan is not started for
   * @param version the version which binaries should be checked
   */
  versionBinariesScanNotStarted: (version: IVersion): boolean => {
    const binariesInScanNotStarted = _.filter(version.binaries, function (binary) {
      return !jobResultsExist(binary);
    });
    return binariesInScanNotStarted.length > 0;
  },
};
