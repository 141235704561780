import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { StatsContext } from "./statsStore";

/**
 * A component that displays some of Tekla Warehouse usage statistics (page views number, session numbers, users count).
 */
export const Stats = observer(() => {
  const store = useContext(StatsContext);
  const rootStore = store.getRootStore();

  const renderStats = () => {
    return (
      <section className="statistics" data-testid="stats">
        <div className="title">{rootStore.getTranslation("landing.statistics.title")}</div>
        <div className="stat-content">
          <div className="middle-column">
            <div className="stat-items">
              <div id="store.users" className="stat-count" data-testid="users">
                {store.getStats().users}
              </div>
              <div className="stat-title">{rootStore.getTranslation("landing.statistics.users")}</div>
            </div>
            <div className="stat-items">
              <div id="store.users" className="stat-count" data-testid="organizations">
                {store.getStats().organizations}
              </div>
              <div className="stat-title">{rootStore.getTranslation("landing.statistics.organizations")}</div>
            </div>
            <div className="stat-items">
              <div id="store.users" className="stat-count" data-testid="collections">
                {store.getStats().collections}
              </div>
              <div className="stat-title">{rootStore.getTranslation("landing.statistics.collections")}</div>
            </div>
            <div className="stat-items">
              <div id="store.users" className="stat-count" data-testid="packages">
                {store.getStats().packages}
              </div>
              <div className="stat-title">{rootStore.getTranslation("landing.statistics.packages")}</div>
            </div>
            <div className="stat-items">
              <div id="store.pageViews" className="stat-count" data-testid="pageViews">
                {store.getStats().pageViews}
              </div>
              <div className="stat-title">{rootStore.getTranslation("landing.statistics.page_views")}</div>
            </div>
            <div className="stat-items">
              <div id="store.sessions" className="stat-count" data-testid="sessions">
                {store.getStats().sessions}
              </div>
              <div className="stat-title">{rootStore.getTranslation("landing.statistics.sessions")}</div>
            </div>
          </div>
          <div className="right-column" />
        </div>
      </section>
    );
  };

  return store.wasDataFetched() ? renderStats() : <></>;
});
