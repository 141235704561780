import * as React from "react";
import { RootContext } from "../stores/rootStore";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import { PackagePageStoreContext } from "./packagePageStore";

/**
 * A component that displays login button
 */
export const LoginButton = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const userStore = rootStore.getUserStore();
  const user = userStore.getCurrentUser();

  function shouldShowLoginButton(): boolean {
    return !!packageItem && !packageItem.isLocal && !user;
  }

  function login() {
    userStore.login();
  }

  return (
    <Fragment>
      {shouldShowLoginButton() && (
        <button data-testid="login-button" onClick={login} className="login-to-download">
          {rootStore.getTranslation("actions.loginToDownload")}
        </button>
      )}
    </Fragment>
  );
});
