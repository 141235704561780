import { action, observable, runInAction, makeObservable } from "mobx";

import { NewRepository } from "../../js/services/NewRepository";

import { ICollection, IResource, IEditorModeStrategy } from "../../models/dataModel";
import { DialogTypeEnum } from "../../models/enums";

import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import { RootStore, createStoreContext } from "../../stores/rootStore";

/**
 * Result options store.
 */
export class EditorModeStore extends AbstractAsyncStore {
  /**
   * List of available collections.
   */
  @observable private availableCollections: ICollection[] = [];
  /**
   * Editor mode status indicator
   */
  @observable private editorMode = false;
  /**
   * Provided strategy for resources handling
   */
  @observable private editorModeStrategy?: IEditorModeStrategy;

  /**
   * Gets content item
   * @returns list of available collections
   */
  public reloadData = (dialogType: DialogTypeEnum) => {
    if (this.editorModeStrategy && this.editorModeStrategy.reloadData) {
      this.editorModeStrategy.reloadData(dialogType);
    }
  };

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore, editorModeStrategy?: IEditorModeStrategy) {
    super(rootStore);
    makeObservable(this);
    if (editorModeStrategy) {
      this.editorModeStrategy = editorModeStrategy;
      this.fetchData();
    }
  }

  /**
   * Fetches list of available collections
   */
  @action
  public async fetchData() {
    this.dataFetched = false;
    this.loading = true;
    this.availableCollections = [];

    try {
      const availableCollections = await NewRepository.getCollectionsWithLinkRights({
        user: this.rootStore.getUserStore().getCurrentUser(),
      });

      runInAction(() => {
        this.availableCollections = availableCollections;
        this.loading = false;
        this.dataFetched = true;
      });
    } catch {
      console.log("Failed to load collections with linking rights.");

      runInAction(() => {
        this.loading = false;
        this.dataFetched = false;
      });
    }
  }

  /**
   * Gets available collections
   * @returns list of available collections
   */
  public getAvailableCollections(): ICollection[] {
    return this.availableCollections;
  }

  /**
   * Gets status of editor mode
   * @returns true if editor mode is on
   */
  public getEditorMode(): boolean {
    return this.editorMode;
  }

  /**
   * Gets content item
   * @returns content item object
   */
  public getResource(): IResource | undefined {
    return this.editorModeStrategy ? this.editorModeStrategy.getResource() : undefined;
  }

  /**
   * Sets status of editor mode
   * @params editorMode new value for for editorMode
   */
  @action
  public setEditorMode(editorMode: boolean) {
    this.editorMode = editorMode;
  }

  /**
   * Resolves if batch dialog should be shown
   * @returns true if dialog should be shown
   */
  public shouldShowBatchEditDialog(): boolean {
    return this.editorModeStrategy ? this.editorModeStrategy.shouldShowBatchEditDialog() : false;
  }

  /**
   * Resolves if translations dialogs should be shown
   * @returns true if dialogs should be shown
   */
  public shouldShowTranslationDialogs(): boolean {
    return this.editorModeStrategy ? this.editorModeStrategy.shouldShowTranslationDialogs() : false;
  }
}

export const EditorModeStoreContext = createStoreContext<EditorModeStore>(EditorModeStore);
