import { LocalRequestBuilder } from "../LocalRequestBuilder";
import { LocalResponseProcessor } from "../LocalResponseProcessor";

export const LocalBinaryDS = {
  setBinary: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/setBinary", data));
  },
  setBinary2: function (data, file) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doUpload("/TeklaWarehouse/setBinary", data, file));
  },
  getBinary: function (params) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doGet("/TeklaWarehouse/getBinary", params));
  },
  deleteBinary: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/deleteBinary", data));
  },
};
