import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { IFileItem } from "../../models/dataModel";
import { ScanReportDialogStoreContext } from "./scanReportDialogStore";
import { DialogContext } from "../../dialogs/dialogStore";
import { Dialog } from "../../dialogs/Dialog";

const DialogContent = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(ScanReportDialogStoreContext);
  const dialog = useContext(DialogContext);
  const binaries: IFileItem[] = store.getInfectedBinaries();

  function handleCloseDialog() {
    dialog.close();
  }

  function getFileName(binary: IFileItem) {
    return binary && binary.attributes ? binary.attributes.fileName : "";
  }

  return (
    <article>
      <header>
        <h2>{rootStore.getTranslation("details.scan.popup.infected_binaries_title")}</h2>
      </header>
      <section>
        <ol className="basic-data">
          <li>
            <ul className="scan-errors">
              <ul className="scan-errors-info">
                {rootStore.getTranslation("details.scan.popup.infected_binaries_info")}
                <br />
              </ul>
              {binaries.map((binary: IFileItem, i: number) => {
                return (
                  <li data-testid="binary-name" key={i}>
                    <b>{rootStore.getTranslation("details.scan.popup.version")}:</b>
                    {binary.versionName},<b>{rootStore.getTranslation("details.scan.popup.fileName")}:</b>
                    {getFileName(binary)}
                  </li>
                );
              })}
            </ul>
          </li>
        </ol>
      </section>
      <div className="actions">
        <button onClick={handleCloseDialog} data-testid="show-failures-close">
          {rootStore.getTranslation("actions.close")}
        </button>
      </div>
    </article>
  );
});

const InfectedBinaries = observer(() => {
  const rootStore = useContext(RootContext);
  const dialog = useContext(DialogContext);

  async function openDialog() {
    dialog.open();
  }

  return (
    <Fragment>
      <div className="scan-info-text">
        {rootStore.getTranslation("details.scan.infected_binaries_info")}
        <a className="scan-failed-binaries" data-testid="show-failed-binaries" onClick={openDialog}>
          {rootStore.getTranslation("details.scan.what_now")}
        </a>
        <div className="contact-trimble">{rootStore.getTranslation("details.scan.contact_trimble")}</div>
      </div>
      <Dialog content={<DialogContent />} additionalClass="scan-report" />
    </Fragment>
  );
});

/**
 * A component that displays scan report
 */
export const ScanReport = observer(() => {
  const store = useContext(ScanReportDialogStoreContext);

  return (
    <Fragment>
      {store.infectedBinariesExist() && (
        <section className="content-info">
          <div className="content-scan">
            <div className="scan-info-sign"></div>
            <InfectedBinaries />
          </div>
        </section>
      )}
    </Fragment>
  );
});
