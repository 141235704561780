interface IFilterAttributes {
  organizationNameFilter?: string;
  binaryNamesFilter?: string;
  customWarehouseUrlFilter?: string;
  organizationIdFilter?: string;
}

/**
 * Helper class for converting organization filter attributes
 */
export const OrganizationFilterAttributesConverter = {
  /**
   * Converts organization filter attributes
   * @params data given filter data used for converting
   */
  toTCC: (data: IFilterAttributes): string => {
    let convertedData = "";
    const organizationFilterFiqls: string[] = [];
    const organizationFilters: string[] = [];

    if (data.organizationNameFilter) {
      organizationFilters.push("(displayName=like=" + "'" + data.organizationNameFilter + "')");
    }

    if (data.binaryNamesFilter) {
      organizationFilters.push("(binaryNames==" + "'" + data.binaryNamesFilter + "')");
    }

    if (data.customWarehouseUrlFilter) {
      organizationFilters.push("(customWarehouseUrl==" + "'" + data.customWarehouseUrlFilter + "')");
    }

    if (data.organizationIdFilter) {
      organizationFilters.push("(id==" + "'" + data.organizationIdFilter + "')");
    }

    organizationFilters.push("(type==tekla)");
    organizationFilterFiqls.push(organizationFilters.join(";"));
    convertedData = organizationFilterFiqls.join(";");

    return convertedData;
  },
};
