import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import _ from "underscore";

import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";
import { UploadVersionStore, VersionUploaderViewState } from "./uploadVersionStore";

import { metadataConstants } from "../../constants/MetadataConstants";
import { IDropdownOption, IFile, IFileItem } from "../../models/dataModel";

import { Dropdown } from "../../components/Dropdown";
import { Dialog } from "../../dialogs/Dialog";
import { Applications } from "./add/Applications";
import { ExistingFiles } from "./add/ExistingFiles";
import { Files } from "./add/Files";
import { Import3DGeometryFile } from "./add/Import3DGeometryFile";
import { PartnerDownloadLink } from "./add/PartnerDownloadLink";

/**
 * Renders content type selection dialog content.
 */
const SelectContentType = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const [selectedContentType, setSelectedContentType] = React.useState<IDropdownOption | undefined>(() =>
    _.find(version.contentTypeOptions, (option) => option.value === version.getViewState())
  );

  function handleSelectType(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;
    setSelectedContentType(selectedOption);
  }

  function cancel(e) {
    e.preventDefault();
    dialog.close();
  }

  function continueToFileSelection(e) {
    e.preventDefault();

    if (!!selectedContentType) {
      version.setViewState(selectedContentType.value as VersionUploaderViewState);

      if (selectedContentType.value === VersionUploaderViewState.GEOMETRY_FILE) {
        version.createBlank3DGeometryFile();
      }

      version.setCanSelectFiles(true);
    }
    dialog.close();
  }

  return (
    <article>
      <header>
        <h2 className="l-center-align">{rootStore.getTranslation("versions.what_will_be_added")}</h2>
      </header>
      <section className="selection">
        <Dropdown
          options={version.contentTypeOptions}
          isDisabled={version.hasAddedContent()}
          inputId="contentType"
          selectedValue={selectedContentType}
          className="dropdown-wrapper gray"
          onChange={handleSelectType}
        />
      </section>
      <div className="actions">
        <button onClick={cancel}>{rootStore.getTranslation("actions.cancel")}</button>
        <button className="button-primary" onClick={continueToFileSelection}>
          {rootStore.getTranslation("actions.continue")}
        </button>
      </div>
    </article>
  );
});

/**
 * Main component for the Add files -button, the content type selector dialog, and file management area.
 */
export const AddFiles = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const showAddApplication = version.getViewState() === VersionUploaderViewState.APPLICATION;
  const showUploadFiles = (version.getViewState() === VersionUploaderViewState.FILES ||
    version.getViewState() === VersionUploaderViewState.FILES_FROM_TS);
  const showImport3DGeometryFile = version.getViewState() === VersionUploaderViewState.GEOMETRY_FILE && version.get3DGeometryFiles().length > 0;
  const showAddPartnerLink = version.getViewState() === VersionUploaderViewState.PARTNER_DOWNLOAD_LINK;

  const hasAddedEveryPlatformType = _.all(metadataConstants.platformKeys, (platformType) =>
    _.some(form.getVersionStore().getSelectedTools(), (tool: IFile) => version.getPlatformForTool(tool.name) === platformType) ||
    _.some(form.getVersionStore().getExistingFiles(), (file: IFileItem) => file.attributes?.platform === platformType)
  );

  const disableAddFilesButton = version.isTool() && hasAddedEveryPlatformType;

  function showContentTypeDialog() {
    dialog.open();
  }

  return (
    <section className="content-management version-content-viewer" data-testid="addFilesSection">
      {!form.editingImmutableVersion() && (
        <React.Fragment>
          <Dialog content={<SelectContentType />} additionalClass="file-type" />
          {showUploadFiles && <Files />}
          {showAddApplication && <Applications />}
          {showImport3DGeometryFile && <Import3DGeometryFile />}
          {showAddPartnerLink && <PartnerDownloadLink />}
        </React.Fragment>
      )}
      {version.displayCurrentBinaries && <ExistingFiles />}
      {!form.editingImmutableVersion() && (
        <div className="wrapper">
          <div className="content-dialog l-center-align">
            <button className="button-large" data-testid="addButton" disabled={disableAddFilesButton} onClick={showContentTypeDialog}>
              {rootStore.getTranslation("versions.add")}
            </button>
          </div>
        </div>
      )}
    </section>
  );
});
