import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";
import { Link, Route, Routes, useLocation } from "react-router";

import { NewLocalCollectionWrapper } from "../collection/NewLocalCollectionWrapper";
import { TemporaryBannerNotification } from "../components/TemporaryBannerNotification";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { Settings } from "../config/Settings";
import { RootContext, RootStore } from "../stores/rootStore";
import { MyDetails } from "./MyDetails";
import { AdminPage } from "./admin/AdminPage";
import { MyAlerts } from "./alerts/MyAlerts";
import { MyCollections } from "./collections/MyCollections";
import { MyLikes } from "./likes/MyLikes";

const LocalCollectionsEndOfLifeMessage = () => {
  const { pathname } = useLocation();

  return (
    <TemporaryBannerNotification
      text={
        <div>
          <b><TranslatedHtml entry="landing.notifications.local_collections_end_of_life.title" /></b>
          <p style={{ color: "white" }}><TranslatedHtml entry="landing.notifications.local_collections_end_of_life.part1" /></p>
          <p style={{ color: "white" }}><TranslatedHtml entry="landing.notifications.local_collections_end_of_life.part2" /></p>
        </div>
      }
      bannerFeatureToggle="localCollectionsEndOfLifeMessage"
      show={pathname === "/my/map" || pathname === "/my/local"}
    />
  );
}

const Navigation = observer(() => {
  const store: RootStore = useContext(RootContext);
  const { pathname } = useLocation();

  const view = pathname;
  const showOnlineFunctionality = !Settings.isOffline;
  const hasUser = store.getUserStore().isUserLoggedIn();
  const isUserAdmin = store.getUserStore().isUserAdmin();

  function isActiveView(viewName): boolean {
    return viewName === view;
  }

  function showAdminView(): boolean {
    return showOnlineFunctionality && isUserAdmin;
  }

  function showOnlineItems(): boolean {
    return showOnlineFunctionality && hasUser;
  }

  return (
    <nav className="tabs">
      <ul>
        {showOnlineItems() ? (
          <Fragment>
            <li
              className={classNames("tab-item", {
                active: isActiveView("/my/settings") || isActiveView("/my/"),
              })}
            >
              <Link to="/my/settings">{<TranslatedHtml entry="subNavigation.profile" />}</Link>
            </li>
            <li
              className={classNames("tab-item", {
                active: isActiveView("/my/collections") || isActiveView("/my/local") || isActiveView("/my/map"),
              })}
            >
              <Link to="/my/collections">{<TranslatedHtml entry="subNavigation.myCollections" />}</Link>
            </li>
          </Fragment>
        ) : (
          <li
            className={classNames("tab-item", {
              active: isActiveView("/my/local") || isActiveView("/my/map"),
            })}
          >
            <Link to="/my/local">{<TranslatedHtml entry="subNavigation.myCollections" />}</Link>
          </li>
        )}
        {showOnlineItems() && (
          <Fragment>
            <li
              className={classNames("tab-item", {
                active: isActiveView("/my/alerts"),
              })}
            >
              <Link to="/my/alerts">{<TranslatedHtml entry="profile.subTabs.alerts" />}</Link>
            </li>
            <li
              className={classNames("tab-item", {
                active: isActiveView("/my/likes"),
              })}
            >
              <Link to="/my/likes">{<TranslatedHtml entry="subNavigation.myLikes" />}</Link>
            </li>
          </Fragment>
        )}
        {showAdminView() && (
          <li
            className={classNames("tab-item", {
              active: isActiveView("/my/admin"),
            })}
          >
            <Link to="/my/admin">{<TranslatedHtml entry="subNavigation.admin" />}</Link>
          </li>
        )}
      </ul>
    </nav>
  );
});

const TabView = observer(() => {
  const store = useContext(RootContext);
  const hasUser = store.getUserStore().isUserLoggedIn();
  const isUserAdmin = store.getUserStore().isUserAdmin();

  return (
    <div id="content" className="tab-view">
      <Routes>
        {!!hasUser && <Route path="/" element={<MyDetails />} />}
        {!!hasUser && <Route path="/settings" element={<MyDetails />} />}
        {!!hasUser && <Route path="/collections" element={<MyCollections mode="online" />} />}
        <Route path="/local" element={<MyCollections mode="local" />} />
        {!!hasUser && <Route path="/alerts" element={<MyAlerts />} />}
        {!!hasUser && <Route path="/likes" element={<MyLikes />} />}
        {!!isUserAdmin && <Route path="/admin" element={<AdminPage />} />}
        <Route path="/map" element={<NewLocalCollectionWrapper />} />
      </Routes>
    </div>
  );
});

export const MyPage = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const { pathname } = useLocation();

  const creatingLocalCollectionVersionsBugNotification = "The latest Tekla Warehouse service plugin version has an issue with creating version folders if you are not signed-in. Please sign-in to continue.";

  return (
    <div className="main-tabs-container">
      <LocalCollectionsEndOfLifeMessage />
      <TemporaryBannerNotification text={creatingLocalCollectionVersionsBugNotification} show={pathname === "/my/map" && !rootStore.getUserStore().isUserLoggedIn()} />
      <TemporaryBannerNotification text={<TranslatedHtml
        entry="landing.notifications.content_editor_role.my_details_info"
        args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
        bannerFeatureToggle="contentEditorRoleInfoAfter"
        show={pathname === "/my/settings" && rootStore.getUserStore().isUserLoggedIn()}
      />
      <Navigation />
      <TabView />
    </div>
  );
});
