import _ from "underscore";
import { ObjectTypeEnum } from "../../models/enums";

export const ModelTypeConverter = {
  toSubjectClass: function (type) {
    if (_.contains([ObjectTypeEnum.LOCAL_COLLECTION, ObjectTypeEnum.LOCAL_COLLECTION_PREINSTALLED], type)) {
      return "collection";
    } else if (_.contains([ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION], type)) {
      return "collection";
    } else if (_.contains([ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE], type)) {
      return "package";
    } else if (_.contains([ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION], type)) {
      return "entity";
    } else if (_.contains([ObjectTypeEnum.LOCAL_PACKAGE], type)) {
      return "package";
    } else if (_.contains([ObjectTypeEnum.LOCAL_VERSION], type)) {
      return "version";
    } else if (
      _.contains([ObjectTypeEnum.TEKLA_WAREHOUSE_USER], type) ||
      _.contains([ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION], type)
    ) {
      return "user";
    } else {
      return null;
    }
  },
};
