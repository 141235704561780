import { IRootStoreProvider } from "../models/dataModel";
import { RootStore } from "./rootStore";
import { observable, makeObservable } from "mobx";

/**
 * Abstract class that should be used as a parent class for stores that perform async data process,
 * for example Ajax queries (especially API queries).
 */
export abstract class AbstractAsyncStore implements IRootStoreProvider {
  /**
   * Root store
   */
  protected rootStore: RootStore;
  /**
   * A flag marking if async processing was finished.
   */
  @observable protected dataFetched = false;
  /**
   * A flag marking if async processing is in progress.
   */
  @observable protected loading = false;

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Returns root store.
   */
  public getRootStore(): RootStore {
    return this.rootStore;
  }

  /**
   * Abstract method to be implemented in subclasses.
   * This is the place where async processing (ie. fetching data) should happen.
   * @param queryParams Not mandatory hash of parameters.
   */
  public abstract fetchData(queryParams?: object): Promise<any>;

  /**
   * Returns true if async processing was finished.
   */
  public wasDataFetched(): boolean {
    return this.dataFetched;
  }

  /**
   * Returns true if async processing is in progress.
   */
  public isLoading(): boolean {
    return this.loading;
  }
}
