import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext } from "../../../stores/rootStore";
import { OnlineCollectionsContext, OnlineCollectionsStore } from "./onlineCollectionsStore";
import { UserStore } from "../../../stores/userStore";
import { ICollection } from "../../../models/dataModel";

import { OnlineCollectionItem } from "./OnlineCollectionItem";
import { Spinner } from "../../../components/Spinner";

const MyCollections = observer(() => {
  const rootStore = useContext(RootContext);
  const store: OnlineCollectionsStore = useContext(OnlineCollectionsContext);

  return (
    <section className="online-lists" data-testid="myOnlineCollections">
      <span className="icon icon-user collection-list-icons" />
      <h2 className="l-inline created-by-me">{rootStore.getTranslation("myCollections.created_by_me")}</h2>
      {store.wereMyCollectionsFetched() ? (
        <div className="item-grid" data-testid="myCollections">
          {store.getMyCollections().map((collection: ICollection, i: number) => {
            return (
              <div className="container" key={i}>
                <div className="content-items">
                  <OnlineCollectionItem collection={collection} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Spinner loadingWhat="myCollections" />
      )}
    </section>
  );
});

const MyOrganizationsCollections = observer(() => {
  const rootStore = useContext(RootContext);
  const store: OnlineCollectionsStore = useContext(OnlineCollectionsContext);
  const userStore: UserStore = rootStore.getUserStore();

  const belongsToMoreThanOneOrganization = userStore.getOrganizations().length > 1;

  if (userStore.hasOrganizations())
    return (
      <section className="online-lists">
        <span className="icon icon-organization collection-list-icons" />
        <h2 className="l-inline created-by-organization">
          {belongsToMoreThanOneOrganization
            ? rootStore.getTranslation("myCollections.created_by_my_organizations")
            : rootStore.getTranslation("myCollections.created_by_my_organization")}
        </h2>
        {store.wereMyOrganizationCollectionsFetched() ? (
          <div className="item-grid" data-testid="myOrganizationsCollections">
            {!_.isUndefined(store.getMyOrganizationCollections()) &&
              _.map(
                store.getMyOrganizationCollections()!,
                (oc: { organization: string; collections: ICollection[] }, index: number) => {
                  const organization = userStore
                    .getOrganizations()
                    .find((org) => org.organization.displayName === oc.organization);
                  return (
                    <div className="organization-collections" key={index}>
                      {belongsToMoreThanOneOrganization && (
                        <h3 data-testid={`org-name-${organization?.organization.id}`}>
                          {organization?.organization.displayName}
                        </h3>
                      )}
                      <div className="item-grid" data-testid={`${organization?.organization.displayName}-collections`}>
                        {_.map(oc.collections, (collection: ICollection, i: number) => {
                          return (
                            <div className="container" key={i}>
                              <div className="content-items">
                                <OnlineCollectionItem collection={collection} />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                },
              )}
          </div>
        ) : (
          <Spinner loadingWhat="myOrganizationCollections" />
        )}
      </section>
    );
});

const MySharedCollections = observer(() => {
  const rootStore = useContext(RootContext);
  const store: OnlineCollectionsStore = useContext(OnlineCollectionsContext);
  const userStore: UserStore = rootStore.getUserStore();
  const titleKey = userStore.hasOrganizations()
    ? "myCollections.shared_with_me_or_organization"
    : "myCollections.shared_with_me";

  return (
    <section className="online-lists">
      <h2 className="l-inline created-by-organization">{rootStore.getTranslation(titleKey)}</h2>
      {store.wereSharedCollectionsFetched() ? (
        <div className="item-grid" data-testid="collectionsSharedWithMe">
          {store.getMySharedCollections().map((collection: ICollection, i: number) => {
            return (
              <div className="container" key={i}>
                <div className="content-items">
                  <OnlineCollectionItem collection={collection} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Spinner loadingWhat="mySharedCollections" />
      )}
    </section>
  );
});

export const OnlineCollections = observer(() => {
  return (
    <Fragment>
      <MyCollections />
      <MyOrganizationsCollections />
      <MySharedCollections />
    </Fragment>
  );
});
