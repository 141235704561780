import { createContext, Context } from "react";
import _ from "underscore";

import { Router } from "../utils/Router";
import { ShoppingCartStoreCache } from "./shoppingCartStoreCache";
import { LocalizationStore } from "./localizationStore";
import { LocalServiceStore } from "./localServiceStore";
import { OnlineStatusStore } from "./onlineStatusStore";
import { NotificationsStore } from "./notificationsStore";
import { NotificationChannelStore } from "./notificationChannelStore";
import { ErrorHandlerStore } from "./errorHandlerStore";
import { UserStore } from "./userStore";
import { SearchStore } from "./searchStore";
import { LanguageCodeConverter } from "../utils/converters/LanguageCodeConverter";

/**
 * When instantiated - RootStore object serves mainly as a proxy to Tekla Warehouse dependencies
 * and encapsulates shopping cart. It can also be used to use Tekla Warehouse's translations.
 */
export class RootStore {
  /**
   * Router instance.
   */
  private router: Router;

  /**
   * Shopping cart cache instance.
   */
  private shoppingCartStoreCache: ShoppingCartStoreCache;

  /**
   * Localization store instance.
   */
  private localizationStore: LocalizationStore;

  /**
   * User store instance for accessing user information.
   */
  private userStore: UserStore;

  /**
   * Local service store instance
   */
  private localServiceStore: LocalServiceStore;

  /**
   * Online status store instance
   */
  private onlineStatusStore: OnlineStatusStore;

  /**
   * Notifications store instance
   */
  private notificationsStore: NotificationsStore;

  /**
   * Search store instance
   */
  private searchStore: SearchStore;

  /**
   * Notification channel store instance
   */
  private notificationChannelStore: NotificationChannelStore;

  /**
   * Error handler store instance
   */
  private errorHandlerStore: ErrorHandlerStore;

  /**
   * Constructor
   */
  public constructor() {
    this.router = new Router(this);
    this.shoppingCartStoreCache = new ShoppingCartStoreCache(this);
    this.localizationStore = new LocalizationStore(this);
    this.userStore = new UserStore(this);
    this.localServiceStore = new LocalServiceStore(this);
    this.onlineStatusStore = new OnlineStatusStore();
    this.notificationsStore = new NotificationsStore(this);
    this.searchStore = new SearchStore(this);
    this.notificationChannelStore = new NotificationChannelStore(this);
    this.errorHandlerStore = new ErrorHandlerStore(this);
  }

  /**
   * Gets the translation for the given entry.
   * @param entry The entry key of the translation.
   * @param args A number of variable arguments that will be applied to the translation.
   * @returns translated string
   */
  public getTranslation(entry: string, ...args: any[]): string {
    const target: string | Function = this.localizationStore.getTranslation(entry);
    if (typeof target == "function") {
      return target.apply(
        this,
        (args || []).map((item) => _.escape(item)),
      );
    } else {
      return target || `[${entry}]`;
    }
  }

  /**
   * Gets language key by selected locale.
   * @returns Language key if found
   */
  public getLangKeyBySelectedLocale() {
    return LanguageCodeConverter.convertIsoCodeToLangKey(this.getLocalizationStore().getLocale());
  }

  /**
   * Gets router
   * @returns instance
   */
  public getRouter(): Router {
    return this.router;
  }

  /**
   * Gets shopping cart cache.
   * @returns shopping cart cache.
   */
  public getShoppingCartStoreCache(): ShoppingCartStoreCache {
    return this.shoppingCartStoreCache;
  }

  /**
   * Gets localization store.
   * @returns localization store
   */
  public getLocalizationStore(): LocalizationStore {
    return this.localizationStore;
  }

  /* Gets user store.
   * @returns user store
   */
  public getUserStore(): UserStore {
    return this.userStore;
  }

  /**
   * Gets local service store.
   * @returns local service store
   */
  public getLocalServiceStore(): LocalServiceStore {
    return this.localServiceStore;
  }

  /**
   * Gets online status store.
   * @returns online status store
   */
  public getOnlineStatusStore(): OnlineStatusStore {
    return this.onlineStatusStore;
  }

  /**
   * Gets notifications store.
   * @returns notifications store
   */
  public getNotificationsStore(): NotificationsStore {
    return this.notificationsStore;
  }

  /**
   * Gets search store.
   * @returns search store
   */
  public getSearchStore(): SearchStore {
    return this.searchStore;
  }

  /**
   * Returns notification channel store
   */
  public getNotificationChannelStore() {
    return this.notificationChannelStore;
  }

  /**
   * Returns error channel store
   */
  public getErrorHandlerStore() {
    return this.errorHandlerStore;
  }
}

/**
 * Context object for RootStore instances.
 */
export const RootContext = createContext<RootStore>(new RootStore());

/**
 * Helper method that creates Context object for a given store type.
 * @param storeType Store type
 * @return Context object for the specified store type.
 */
export function createStoreContext<T>(storeType: { new (rootStore: RootStore): T }): Context<T> {
  return createContext<T>(new storeType(new RootStore()));
}
