 
export const ZhAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["zh-TW"]) {
      window.locale["zh-TW"] = {};
    }

    window.locale["zh-TW"].about = {
      navigation: {
        why: "為何是 Tekla Warehouse?",
        usage_basics: "Tekla Warehouse 基礎",
        "usage_basics__using-content": "使用內容",
        "usage_basics__adding-content": "加入內容",
        usage_basics__collection: "收集",
        "usage_basics__content-item": "內容項目",
        usage_basics__version: "版本",
        getting_started: "開始使用",
        "getting_started__tekla-account-and-system-requirements": "Tekla account 與系統需求",
        "getting_started__tekla-warehouse-service-plugin": "Tekla Warehouse Service 插件程式",
        "getting_started__how-do-i-get-tekla-warehouse-service": "如何取得 Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures 與 Tekla Warehouse",
        content: "Tekla Warehouse 內容",
        "content__3d-products": "3D 模型產品",
        "content__custom-components": "用戶元件",
        content__applications: "應用程式",
        "content__profiles-shapes-etc": "斷面規格、材料、螺栓、鋼筋等",
        "content__model-templates": "模型樣板",
        "content__drawing-setup": "圖面設定",
        "content__report-templates-etc": "報表樣板與屬性檔案",
        using_content: "使用內容",
        creating_content: "建立內容",
        "creating_content__getting-started-with-content-creation": "開始建立內容",
        "creating_content__expert-help-for-creating-content": "需要專家協助您建立內容嗎?",
        application_developers: "Tekla Structures 應用程式開發",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "為何是 Tekla Warehouse?",
        description_1:
          "Tekla Warehouse 包含各種應用程式、用戶元件、零件、斷面規格、以及包含鋼與混凝土等級的材料庫、螺栓、鋼筋、網格、形狀與 Tekla Structures 樣板。 模型裡實際供應商的零件皆有最高要求的準確度與精確的材料資訊，方便您後續規劃與採購之用，而預先建立完成的模型物件內容更可加速您的模型建立作業。",
        description_2:
          "Tekla Warehouse 針對此內容所提供的集中存取機制，現在使用起來更為簡便。 Tekla Warehouse 內容仍持續增加。",
        description_3: "使用 Tekla Warehouse",
        feature_1:
          "製造商可以製作他們的產品模型物件與相關應用程式以提供用戶使用，以便他們將精確的 3D 產品模型直接匯入 Tekla 模型中，並於後續應用在建築的實體產品上。",
        feature_2: "您可以透過貴公司網路將內容與同事共用，並將內容儲存在本機以供個人使用",
        description_4: "Tekla Warehouse 搜尋功能讓您不管任何內容類型，都能輕鬆地找到想要的內容。",
        description_5: "需要 Tekla Warehouse Service 插件程式以啟用網路及本機收集。",
        view_licences_html: '檢視<a href="/api/third_party_licenses.txt" target="_blank">協力廠商授權</a>。',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Tekla Warehouse 基礎",
        using_content: {
          title: "使用內容",
          description_1: "您可以使用線上收集裡的內容、透過網路收集與同事共用內容，並運用本機收集以供個人使用。",
          description_2:
            "本機與網路收集需要 Tekla Warehouse Service，後者會隨著 Tekla Structures20.1 Service Release 1 以及較新版的 Tekla Structures 一起安裝。",
          description_3: "包含本地化環境內容的特定 Tekla 收集會與 Tekla Structures 一併安裝進去。",
          description_4: "請參閱「使用內容」一節，了解詳細資訊。",
        },
        adding_content: {
          title: "加入內容",
          description_1:
            '您可以使用線上收集，將內容發佈給所有 Tekla Warehouse 用戶。 為了以組織名義發佈內容，您的組織需要先加入 account.tekla.com。 如需詳細資訊，請洽詢您當地的 <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">Tekla 營運據點或經銷商</a>。',
          description_2: "請參閱「建立內容」一節，了解詳細資訊。",
        },
        collection: {
          title: "收集",
          feature_1: "收集可將內容項目分組歸類，像是資料夾，並定義內容 (公用/私人) 的存取權限。",
          feature_1_1: "模型建立者可以使用線上收集裡的內容、透過網路收集與同事共用內容，並運用本機收集以供個人使用。",
          feature_2: "內容提供者可以使用線上收集，將所屬內容發佈給所有 Tekla Warehouse 用戶。",
          feature_3:
            "本機與網路收集需要藉助 Tekla Warehouse Service 插件程式，後者會隨著 Tekla Structures 20.1 Service Release 1 與較新版本一起安裝，也可以單獨安裝。",
        },
        "content-item": {
          title: "內容項目",
          feature_1:
            "內容項目是一群檔案的邏輯群組。 舉例來說，3D 產品目錄、模型樣板、工具、用戶元件群組，或是斷面規格目錄都算是內容項目。",
          feature_2: "內容項目可以加上類型與使用案例標籤加以分類，以協助用戶找到您的內容。",
        },
        version: {
          title: "版本",
          feature_1:
            "同時，內容項目具有多種版本，可以搭配不同的 Tekla Structures 版本一起使用。 用戶可以從內容頁面選擇正確的版本。",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "開始使用",
        tekla_account_and_system_requirements: {
          title: "Tekla account 與系統需求",
          register_atc_html:
            '您需要 Tekla account 以登入 Tekla Warehouse。 如果您尚未註冊，請前往 <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a> 辦理。 (請注意，這不是舊有的 Extranet 帳戶。)',
          see_tua_for_prerequisites_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">使用 Tekla Warehouse</a> 的先決條件相關資訊。',
        },
        tekla_warehouse_service_plugin: {
          title: "Tekla Warehouse Service 插件程式",
          description_1: "除了做為內容的線上儲存位置之外，Tekla Warehouse 還可用於:",
          feature_1: "本機收集，可供個人使用",
          feature_2: "網路收集，可透過公司網路與同事共用內容",
          feature_3: "當使用或建立內容時安裝簡易",
          feature_4: "搜尋本機與網路內容",
          description_2:
            "您可以透過 Tekla Warehouse Service 插件程式做為本機後端，以便和 Tekla Structures 及 Tekla Warehouse 網站通訊，進而達到此目的。",
          description_3:
            "當 Tekla Warehouse Service 尚未安裝或是無法執行，Tekla Warehouse 會在頁面上方提醒您進行故障排除。",
          description_4:
            "如果問題出在服務本身，則您可以使用 Windows 系統裡的「服務」程式來重新啟動服務。 該服務名稱為 Tekla Warehouse。 請同時檢查您的瀏覽器安全性設定。",
          description_5:
            "Tekla Warehouse 網站可在不開啟 Tekla Warehouse Service 或 Tekla Structures 的情況下使用。 使用起來就像是一般的內容分享網站一樣，可讓您手動上傳與下載檔案。",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "如何取得 Tekla Warehouse Service?",
          description_1:
            "Tekla Warehouse Service 插件程式會隨著 Tekla Structures 20.1 Service Release 1 與更新的版本一起安裝。 您也可以單獨安裝 Tekla Warehouse Service 以啟用本機與網路收集，不過唯有使用 Tekla Structures 20.1 Service Release 1 與較新的版本才能簡化安裝程序，並且啟用上傳功能。",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">下載 TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures 與 Tekla Warehouse",
          description_1:
            "Tekla Warehouse 能夠直接與 Tekla Structures 連線，方便您使用或是上傳內容。 您可以直接從模型中選擇內容，也可以將內容直接插入模型中，無須先匯入/匯出檔案 (要執行匯入/匯出作業，必須藉助 Tekla Structures 20.1 Service Release 1 與更新的版本)。 所需的一切檔案可以同時包含到同一個內容項目中，像是用戶元件或是斷面規格，以確保所有作業如預期執行。",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Tekla Warehouse 內容",
        description_1:
          "Tekla Warehouse 提供多種不同的內容類型。 您可在此找到不同的內容類型概述，以及如何開始使用內容的詳細資訊。",
        description_2:
          '您也可以從 Tekla Warehouse 下載 <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla 延伸功能</a>。',
        "3d_products": {
          title: "3D 模型產品",
          image_subtitle: "這些是具有固定幾何體的物件，例如嵌入物、模板與配件。",
          tua_link_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">在 Tekla Warehouse 收集中建立 3D 產品目錄</a>的詳細資訊。',
        },
        custom_components: {
          title: "用戶元件",
          image_subtitle: "用戶元件指的是具有固定或參數幾何體的 Tekla Structures 原生物件。",
          tua_link_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">將內容從 Tekla Structures 模型上傳至 Tekla Warehouse 收集</a>的詳細資訊。',
        },
        applications: {
          title: "應用程式",
          image_subtitle:
            "應用程式使用 Tekla Open API 與 Tekla Structures 互動。 舉例來說，資料整合會與設計解決方案進行連結。",
          tua_link_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">將應用程式上傳至 Tekla Warehouse 收集</a>的詳細資訊。',
        },
        profiles_shapes_etc: {
          title: "斷面規格、形狀、材料、螺栓組合、鋼筋與網格",
          image_subtitle: "目錄包含與零件相關的性質列表。 舉例來說，不同的材料等級與其密度。",
          tua_link_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">將內容從 Tekla Structures 模型上傳至 Tekla Warehouse 收集</a>的詳細資訊。',
        },
        model_templates: {
          title: "模型樣板",
          image_subtitle:
            "模型樣板可運用在重複進行的類似專案上。 該樣板可包含所有必要目錄、報表樣板、圖面設定與進階選項設定。",
          tua_link_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">手動將檔案加入 Tekla Warehouse 收集</a>的詳細資訊。',
        },
        drawing_setup: {
          title: "圖面設定",
          image_subtitle: "設定檔案定義圖面的建立方式，例如版面佈置、濾鏡與複製樣板。",
          tua_link_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">手動將檔案加入 Tekla Warehouse 收集</a>的詳細資訊。',
        },
        report_templates_etc: {
          title: "報表樣板與模型屬性檔案",
          description_1:
            "報表樣板定義了在報表中模型資料的內容與版面佈置。 模型屬性檔案，例如過濾器等，可在加入模型資料夾內後正常使用。",
          tua_link_html:
            '請參閱 Tekla User Assistance，以取得<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">手動將檔案加入 Tekla Warehouse 收集</a>的詳細資訊。',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "使用內容",
        description_1:
          "依據內容類型而定，您可以將選定內容插入 Tekla Structures 模型中，或是下載後安裝它。 請參閱 Tekla User Assistance，以操作",
        feature_1:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">在 Tekla Warehouse 中搜尋內容</a>的說明',
        feature_2:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">使用來自 Tekla Warehouse 的內容</a>',
        feature_3:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">將 Tekla Warehouse 內容插入 Tekla Structures 模型</a>',
        feature_4:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Tekla Warehouse 中的衝突管理</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "建立內容",
        getting_started_with_content_creation: {
          title: "開始建立內容",
          description_1: "請參閱 Tekla User Assistance，以取得下列各項的詳細資訊：",
          feature_1:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_getting_started" target="_blank">開始使用 Tekla Warehouse</a>',
          feature_2:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_adding_content" target="_blank">將內容加入 Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">修改 Tekla Warehouse 內容</a>',
          feature_4:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">從 Tekla Warehouse 下載內容</a>',
        },
        expert_help_for_creating_content: {
          title: "需要專家協助您建立內容嗎?",
          description_1:
            '如果您有意願在 Tekla Warehouse 裡發佈內容，但是需要專家協助您建立與維護內容的話，請洽詢您當地的 <a href="http://www.tekla.com/contact" target="_blank">Tekla 營運據點或經銷商</a>，以取得協同合作可能性的詳細資訊。',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Tekla Structures 應用程式開發",
        description_1:
          "由於 Tekla 對於建築資訊模型 (BIM) 秉持著開放接納的態度，因此您可以執行其他供應商的解決方案與加工機械，但仍舊可以和 Tekla 互動。",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> 是由 Tekla 所開發的特定應用程式介面 (API)，能夠協助您在 Tekla Structures 建模平台上開發各種應用程式與其他功能，並將之整合到您自己的環境當中。 Tekla Open API 需藉助 Microsoft .NET 技術來實作。',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center</a> 匯集了 Tekla Open API 相關資訊。',
        description_4:
          '如果您對於開發 Tekla Structures 應用程式很感興趣的話，歡迎您加入 <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a>。',
      },
    };
  },
};
