import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import { RootContext, RootStore } from "../stores/rootStore";
import { IGroupAndCategoryHandler, IDropdownOption, IDropdownOptionGroup } from "../models/dataModel";
import { DropdownMultiple } from "./DropdownMultiple";
import { GroupAndCategoryHandlerContext } from "../package/tabs/groupAndCategoryStore";
import { metadataConstants } from "../constants/MetadataConstants";

/** Component for the Use category dropdown */
export const UseCategories = observer(({ hideLabel }: { hideLabel?: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: IGroupAndCategoryHandler = useContext(GroupAndCategoryHandlerContext);

  function useCategoriesOptions(): IDropdownOptionGroup[] {
    const options: IDropdownOptionGroup[] = [];
    _.each(metadataConstants.groupedUseCategoriesKeys, (categories, group) => {
      options.push({
        label: rootStore.getTranslation("useCategoryGroups." + group),
        options: _.map(categories, (category) => {
          return {
            value: category,
            label: rootStore.getTranslation("useCategories." + category),
          };
        }),
      });
    });
    return options;
  }

  function handleSelectUseCategory(selectedOptions: readonly IDropdownOption[]) {
    store.setUseCategories(selectedOptions);
  }

  return (
    <li>
      {!hideLabel && <label>{rootStore.getTranslation("upload.useCategories")}</label>}
      <DropdownMultiple
        options={useCategoriesOptions()}
        selectedValues={store.getUseCategories()}
        onChange={handleSelectUseCategory}
        className="dropdown-wrapper gray"
        inputId="useCategories"
        menuHeight={175}
      />
    </li>
  );
});
