import { observable, action, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../js/services/NewRepository";

import { RootStore } from "../stores/rootStore";
import { UploadFormStore } from "./uploadFormStore";
import { IEntityFormData } from "./content/uploadEntityStore";
import { IVersionFormData, VersionUploaderViewState } from "./version/uploadVersionStore";

import {
  IBinary,
  IModifier,
  IVersion,
  IEntity,
  ICollection,
  IFile,
  IFileItem,
  IUser,
  ICollectionFormData,
  I3DGeometryFile,
} from "../models/dataModel";
import { ObjectTypeEnum, UploadStrategyEnum } from "../models/enums";

import { ThreeDGeometryFileImporter } from "./ThreeDGeometryFileImporter";
import { FileHandleConverter } from "../utils/converters/FileHandleConverter";
import { CollectionFormUploadMethod } from "./collection/uploadCollectionStore";

interface IUploadDataObject {
  collection?: ICollection;
  entity?: IEntity;
  version?: IVersion;
  binaries?: IFileItem[];
  fileHandlesNotAdded?: IFile[];
}

/**
 * Store that handles the uploading of collection, entity, and version objects based on data stored in uploadFormStore.
 */
export class UploaderStore {
  /** Root store. */
  private rootStore: RootStore;

  /** Upload form store. */
  private form: UploadFormStore;

  /** Stores the result data */
  @observable private uploadData: IUploadDataObject = {};

  /** Status of the publishing process */
  @observable private publishInProgress = false;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore, form: UploadFormStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.form = form;
  }

  /** Extends the uploadData object with new data */
  @action
  public setUploadData(newData) {
    this.uploadData = _.extend(this.uploadData, newData);
  }

  /** Returns the uploadData object */
  public getUploadData() {
    return this.uploadData;
  }

  /** Returns the publishing status. */
  public publishing(): boolean {
    return this.publishInProgress;
  }

  /**
   * Calls the upload actions for collection, entity and version.
   */
  private async handlePublish() {
    const strategy = this.form.getUploadStrategy();
    if (strategy !== UploadStrategyEnum.ADD_VERSION && strategy !== UploadStrategyEnum.EDIT_VERSION) {
      if (this.form.getCollectionStore().isNewCollection()) {
        await this.createCollection();
      } else {
        this.returnCollection();
      }
      await this.createEntity();
    }
    await this.createVersion();

    //this is a temporary solution, should be refactored
    if (this.uploadData.collection?.isBanned) await this.updateBannedState();
  }

  /**
   * Publishes the collection that contains the entity and version.
   * When uploading has finished, calls router to take the user to the newly created entity.
   * If publish fails, displays an error message.
   */
  @action
  public async publish() {
    const router = this.rootStore.getRouter();
    this.form.startPublish();
    this.publishInProgress = true;

    try {
      await this.handlePublish();
      this.showSuccessNotification();
      setTimeout(() => {
        if (this.uploadData.entity!.isLocal) {
          router.changeRoutingState("/catalog/localdetails/" + this.uploadData.entity!.id);
        } else {
          router.changeRoutingState("/catalog/details/" + this.uploadData.entity!.id);
        }
      }, 500);
    } catch {
      this.showErrorNotification();
      try {
        await this.rollback();

        setTimeout(() => {
          router.changeRoutingState("/upload");
        }, 500);
      } catch {
        setTimeout(() => {
          router.changeRoutingState("/upload");
        }, 500);
      }
    }
    runInAction(() => {
      this.publishInProgress = false;
    });
  }

  private showSuccessNotification() {
    const strategy = this.form.getUploadStrategy();
    if (strategy === UploadStrategyEnum.ADD_VERSION) {
      const newVersion: IVersion = this.getUploadData().version!;
      const msg = this.rootStore.getTranslation(
        newVersion!.isLocal ? "upload.version.version_was_created" : "upload.version.version_was_created_with_delay",
      );
      this.rootStore.getNotificationChannelStore().success(msg);
    } else {
      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("upload.publish.package_was_uploaded"));
    }
  }

  private showErrorNotification() {
    const strategy = this.form.getUploadStrategy();
    if (strategy === UploadStrategyEnum.ADD_VERSION) {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("upload.version.version_add_failed"));
    } else {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("upload.publish.couldn_t_create_a_package"));
    }
  }

  /** Returns the existing collection object. */
  private returnCollection() {
    this.setUploadData({
      collection: this.form.getCollectionStore().getCollection(),
    });
  }

  /** Creates a collection object based on form data. */
  @action
  public async createCollection(): Promise<{ collection: ICollection } | undefined> {
    const collection: ICollection | ICollectionFormData = this.form.getCollectionStore().getCollection();
    const currentUser = this.rootStore.getUserStore().getCurrentUser();

    if (this.form.getCollectionStore().getUploadMethod() !== CollectionFormUploadMethod.SELECT_EXISTING_COLLECTION) {
      if (currentUser) {
        _.extend(collection, {
          versions: [],
          modifier: currentUser.id,
        });
      }
    }

    if (this.form.getCollectionStore().getUploadMethod() === CollectionFormUploadMethod.CREATE_NEW_LOCAL_COLLECTION) {
      _.extend(collection, {
        location: this.form.getCollectionStore().getLocation(),
      });
    }

    try {
      const res = await NewRepository.createCollection(collection, currentUser);

      let resultData;
      if (!!collection.thumbnail && !!collection.thumbnail.file) {
        try {
          const collectionWithThumbnail: ICollection = await NewRepository.addThumbnail(
            res,
            FileHandleConverter.fromCustomFilePickerToBinary(collection!.thumbnail.file!),
          );

          resultData = { collection: collectionWithThumbnail };
        } catch {
          console.log("Failed to upload collection thumbnail");
        }
      } else {
        resultData = { collection: res };
      }

      this.setUploadData(resultData);
      return resultData;
    } catch {
      console.log("Failed to create new collection.");
    }
  }

  /**
   * Creates an entity from the form data.
   */
  private async createEntity(): Promise<{ entity: IEntity } | undefined> {
    const entityStore = this.form.getEntityStore();
    const user = this.rootStore.getUserStore().getCurrentUser();
    const collection: ICollection = this.uploadData.collection!;
    let entity: IEntityFormData = entityStore.getEntity() as IEntityFormData;

    _.extend(entity, {
      collection: collection,
      creator: collection.creator,
      isLocal: collection.isLocal,
      type: collection.isLocal ? ObjectTypeEnum.LOCAL_COLLECTION : ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
      visibility: collection.visibility,
      richContent: collection.isLocal ? { description: entityStore.getDetails() } : entityStore.getDetails(),
      isImmutable: this.form.isImmutable(),
    });

    if (this.form.isImmutable() && !_.isEmpty(entity.codeName)) {
      entity.codeName = "twh-" + entity.codeName;
    } else {
      entity = _.omit(entity, "codeName");
    }

    try {
      const res: IEntity = await NewRepository.createPackage(entity, user);
      let resultData;

      if (entity.thumbnail) {
        try {
          const packageWithThumbnail: IEntity = await NewRepository.addThumbnail(
            res,
            FileHandleConverter.fromCustomFilePickerToBinary(entity.thumbnail!.file!),
          );

          resultData = { entity: packageWithThumbnail };
        } catch {
          console.log("Failed to upload entity thumbnail");
          resultData = { entity: res };
        }
      } else {
        resultData = { entity: res };
      }

      runInAction(() => this.setUploadData(resultData));
      return resultData;
    } catch {
      console.log("Failed to create package");
    }
  }

  /**
   * Creates a version from the form data, and calls the funcition to add files to the version.
   */
  @action
  private async createVersion() {
    const version: IVersionFormData = this.form.getVersionStore().getVersion();
    const entity: IEntity = this.uploadData.entity!;
    const viewState = this.form.getVersionStore().getViewState();

    _.extend(version, {
      package: entity,
      creator: entity.creator,
      isLocal: entity.isLocal,
      visibility: entity.visibility,
      tags: entity.tags,
      isImmutable: this.form.isImmutable(),
    });

    if (this.form.isImmutable() && _.isEmpty(version.title)) {
      version.title = version.attributes.versionNumber;
    }

    if (!!entity.attributes && entity.attributes!.licensesACL) {
      _.extend(version.attributes, {
        licensesACL: entity.attributes!.licensesACL,
      });
    }

    if (viewState === VersionUploaderViewState.PARTNER_DOWNLOAD_LINK) {
      return;
    } else if (
      _.includes(
        [VersionUploaderViewState.FILES, VersionUploaderViewState.FILES_FROM_TS, VersionUploaderViewState.APPLICATION],
        viewState,
      )
    ) {
      return this.addContent(version);
    } else if (viewState === VersionUploaderViewState.GEOMETRY_FILE) {
      return this.importGeometryFile(version);
    }
  }

  /**
   * Updates an existing version with values from version data form, and calls functions to add files to the version.
   */
  @action
  public async editVersion(editor: IUser | IModifier | undefined) {
    if (!editor) return;

    const versionData: IVersionFormData = this.form.getVersionStore().getVersion();
    const versionInEdit: IVersion = this.form.getVersionStore().getVersionInEdit()!;
    const viewState = this.form.getVersionStore().getViewState();
    let updatedVersion;

    versionInEdit.attributes = versionData.attributes;
    versionInEdit.title = versionData.title || versionData.attributes.versionNumber;
    versionInEdit.prerequisites = versionData.prerequisites;
    versionInEdit.isImmutable = this.form.isImmutable();

    try {
      updatedVersion = (await NewRepository.updateVersion(versionInEdit, editor as IModifier)) as unknown as IVersion;
      const removedFiles: Array<IFile | I3DGeometryFile | IFileItem> = this.form
        .getVersionStore()
        .getSelectedFilesForRemoval();

      if (!!removedFiles && removedFiles.length > 0) {
        try {
          await NewRepository.deleteBinaries(updatedVersion, removedFiles, editor.id);

          runInAction(() => {
            updatedVersion.binaries = this.filterDeletedBinaries(updatedVersion.binaries, removedFiles);
            this.form.getVersionStore().resetSelectedFilesForRemoval();
          });
        } catch {
          console.log("Failed to delete binaries");
        }
      }
    } catch {
      console.log("Failed to update version");
    }

    if (viewState === "files" || viewState === "filesFromTS" || viewState === "application") {
      return this.editVersionContent(updatedVersion);
    } else if (viewState === "3DGeometryFile") {
      return this.importGeometryFile(updatedVersion);
    }
  }

  private filterDeletedBinaries(existingBinaries: IFileItem[], removedBinaries: IFileItem[]) {
    const filteredBinaries = _.reject(existingBinaries, (existingBinary: IFileItem) => {
      return !!_.find(removedBinaries, (removedBinary: IFileItem) => {
        return !!removedBinary.attributes && removedBinary.attributes.fileName === existingBinary.attributes!.fileName;
      });
    });
    return filteredBinaries;
  }

  /**
   * Adds content to existing version.
   */
  private async editVersionContent(version: IVersion): Promise<
    | {
        version: IVersion;
        binaries: IBinary[];
        fileHandlesNotAdded: IFile[];
      }
    | undefined
  > {
    const currentBinaries = version.binaries;

    const newFiles: IFile[] = version.attributes.isTool
      ? this.form.getVersionStore().getSelectedTools()
      : this.form.getVersionStore().getSelectedFiles();

    const fileHandlesNotAdded: IFile[] = [];
    const binaries: IBinary[] = [];

    _.each(newFiles, (fileHandle: IFile) => {
      const newBinary = FileHandleConverter.fileHandleToBinary(fileHandle);
      const duplicate = _.find(currentBinaries, (existingBinary) => {
        return (
          !!newBinary &&
          !!newBinary.attributes &&
          newBinary.attributes.originalName === existingBinary.attributes!.originalName &&
          !!newBinary &&
          existingBinary.attributes!.itemType === newBinary.attributes.itemType
        );
      });

      if (!!duplicate) {
        fileHandlesNotAdded.push(fileHandle);
      } else {
        binaries.push(newBinary);
      }
    });

    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        const versionWithBinaries: IVersion = await NewRepository.addBinaries(
          version,
          binaries,
          this.form.isImmutable(),
          user.id,
        );

        const resultData = {
          version: versionWithBinaries,
          binaries: binaries,
          fileHandlesNotAdded: fileHandlesNotAdded,
        };

        this.setUploadData(resultData);
        return resultData;
      } catch {
        console.log("Failed to add binaries");
      }
    }
  }

  /**
   * Adds content for new version.
   */
  @action
  private async addContent(
    versionData: IVersionFormData,
  ): Promise<{ version: IVersion; binaries: IBinary[] } | undefined> {
    const files: IFile[] = versionData.attributes.isTool
      ? this.form.getVersionStore().getSelectedTools()
      : this.form.getVersionStore().getSelectedFiles();

    const user = this.rootStore.getUserStore().getCurrentUser();
    const binaries: IBinary[] = FileHandleConverter.fileHandlesToBinary(files);

    let res: IVersion;

    if (!!user) {
      try {
        res = (await NewRepository.createVersion(versionData, user)) as unknown as IVersion;
        runInAction(() => this.setUploadData({ version: res }));

        try {
          const updatedVersion: IVersion = await NewRepository.addBinaries(
            res,
            binaries,
            this.form.isImmutable(),
            user.id,
          );

          const resultData = {
            version: updatedVersion,
            binaries: binaries,
          };

          runInAction(() => this.setUploadData(resultData));
          return resultData;
        } catch {
          console.log("Could not add binaries");
        }
      } catch {
        console.log("Could not add version");
      }
    }
  }

  /**
   * Imports a geometry file.
   */
  private async importGeometryFile(version: IVersionFormData | IVersion): Promise<{ version: IVersion | undefined }> {
    const versionForm = this.form.getVersionStore();
    const entity: IEntity = version.package!;

    const versionWith3DFiles = await new ThreeDGeometryFileImporter(this.rootStore).import(
      entity,
      version,
      versionForm.get3DGeometryFiles() || [],
    );

    const resultData = { version: versionWith3DFiles };
    this.setUploadData(resultData);
    return resultData;
  }

  private shouldDeleteCollection(collection?: ICollection) {
    const uploadMethod = this.form.getCollectionStore().getUploadMethod();
    return (
      !!collection &&
      !!uploadMethod &&
      _.includes(
        [
          CollectionFormUploadMethod.CREATE_NEW_ONLINE_COLLECTION,
          CollectionFormUploadMethod.CREATE_NEW_LOCAL_COLLECTION,
        ],
        uploadMethod,
      )
    );
  }

  private async updateBannedState() {
    return NewRepository.updatePackage(
      {
        id: this.uploadData.entity!.id,
        isBanned: true,
        attributes: { ...this.uploadData.entity!.attributes, bannedDate: new Date().toISOString() },
      },
      this.rootStore.getUserStore().getCurrentUser(),
      true,
    );
  }

  private shouldDeletePackage(entity?: IEntity) {
    const strategy = this.form.getUploadStrategy();
    return entity && strategy !== UploadStrategyEnum.ADD_VERSION && strategy !== UploadStrategyEnum.EDIT_VERSION;
  }

  /** Deletes created data for collection, entity and version objects. */
  public async rollback() {
    if (
      !!this.uploadData!.collection &&
      this.shouldDeleteCollection(this.uploadData.collection) &&
      !!this.uploadData?.collection?.creator
    ) {
      await NewRepository.deleteCollection(this.uploadData!.collection, this.uploadData?.collection?.creator?.id);
    }

    if (
      !!this.uploadData!.entity &&
      this.shouldDeletePackage(this.uploadData!.entity) &&
      !!this.uploadData?.entity?.creator
    ) {
      await NewRepository.deletePackage(this.uploadData!.entity, this.uploadData!.entity?.creator?.id);
    }

    if (this.uploadData.version && this.uploadData!.entity?.creator) {
      await NewRepository.deleteVersion(this.uploadData!.version, this.uploadData!.entity?.creator?.id);
    }
  }
}
