import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ModusDateInput, ModusDatePicker } from "@trimble-oss/modus-react-components";

import { ModusDateInputEventDetails } from "@trimble-oss/modus-web-components/loader";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";
import { dateToISOString } from "utils/functions";

export const DateModifiedFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleStartDateChange(event: CustomEvent<ModusDateInputEventDetails>) {
    !!event.detail.value
      ? store.setDateModifiedStart(dateToISOString(new Date(event.detail.value)))
      : store.setDateModifiedStart(undefined);
  }

  function handleEndDateChange(event: CustomEvent<ModusDateInputEventDetails>) {
    !!event.detail.value
      ? store.setDateModifiedEnd(dateToISOString(new Date(event.detail.value)))
      : store.setDateModifiedEnd(undefined);
  }

  return (
    <div className="search-filter" data-testid="dateModifiedFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.date_modified")}:</h5>
      <ModusDatePicker data-testid="search-date-modified">
        <ModusDateInput
          showCalendarIcon={true}
          label={rootStore.getTranslation("profile.admin.content_manager.helpers.from")}
          format="mm/dd/yyyy"
          placeholder={rootStore.getTranslation("profile.admin.content_manager.helpers.date_format")}
          type="start"
          onValueChange={handleStartDateChange}
          data-testid="date-modified-start"
        ></ModusDateInput>
        <ModusDateInput
          showCalendarIcon={true}
          label={rootStore.getTranslation("profile.admin.content_manager.helpers.to")}
          format="mm/dd/yyyy"
          placeholder={rootStore.getTranslation("profile.admin.content_manager.helpers.date_format")}
          type="end"
          onValueChange={handleEndDateChange}
          data-testid="date-modified-end"
        ></ModusDateInput>
      </ModusDatePicker>
    </div>
  );
});
