import * as React from "react";
import { Fragment, useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";
import { LikeStore, LikeStoreContext } from "../components/like/likeStore";
import { SubscribeStore, SubscribeStoreContext } from "../components/subscribe/subscribeStore";
import { ThumbnailStore, ThumbnailStoreContext } from "../components/thumbnail/thumbnailStore";
import { ImageNaviStore, ImageNaviStoreContext } from "../components/image-navi/imageNaviStore";
import { LocalThumbnailStore, LocalThumbnailStoreContext } from "../components/thumbnail/localThumbnailStore";
import {
  LinkToCollectionStore,
  LinkToCollectionStoreContext,
} from "../components/link-to-collection/linkToCollectionStore";
import { PackagePageStore, PackagePageStoreContext } from "./packagePageStore";

import { Like } from "../components/like/Like";
import { EditorMode } from "../components/editor-mode/EditorMode";
import { Thumbnail } from "../components/thumbnail/Thumbnail";
import { ImageNavi } from "../components/image-navi/ImageNavi";
import { Subscribe } from "../components/subscribe/Subscribe";
import { LinkToCollection } from "../components/link-to-collection/LinkToCollection";
import { LocalThumbnail } from "../components/thumbnail/LocalThumbnail";
import { LoginButton } from "./LoginButton";
import { LicenseInfo } from "./LicenseInfo";
import { DownloadTools } from "./DownloadTools";
import { DownloadVersion } from "./DownloadVersion";
import { PackageDescription } from "./dialogs/PackageDescription";
import { PackageTitle } from "./dialogs/PackageTitle";
import { DownloadOnPartnerSiteButton } from "./DownloadOnPartnerSiteButton";

import { IVersion } from "../models/dataModel";
import { SearchPageOrderEnum } from "../models/enums";

const ToolVersionInfo = observer(({ version }: { version: IVersion }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function canDownload(): boolean {
    return !!packageItem && rootStore.getUserStore().canDownload(packageItem);
  }

  return (
    <Fragment>
      {canDownload() && (
        <div className="dialog-column">
          <div className="action-item">
            <ul>
              <DownloadTools version={version} />
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
});

const VersionsList = observer(() => {
  const packagePageStore = useContext(PackagePageStoreContext);
  const versions = packagePageStore.getSortedVersions(SearchPageOrderEnum.LAST_UPDATED);

  return (
    <Fragment>
      {versions.map((version: IVersion, i) => {
        return (
          <div key={i} className="versions">
            {packagePageStore.shouldDisplayVersion(version) && (
              <div className="version">
                <h3 className="title" style={{ overflowWrap: "anywhere" }}>
                  {version.title}
                </h3>
                <div className="actions">
                  {packagePageStore.isTool(version) ? (
                    <ToolVersionInfo version={version} />
                  ) : (
                    <div className="dialog-column">
                      <DownloadVersion version={version} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </Fragment>
  );
});

const DownloadVersionInfo = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const versions = packagePageStore.getVersions();
  const user = rootStore.getUserStore().getCurrentUser();
  const [showDownloadInfo, showOrHideDownloadInfo] = useState(false);

  const showLoginButton = !rootStore.getUserStore().isUserLoggedIn() && !packageItem?.isLocal;

  function shouldShowDownloadButton(): boolean {
    return (
      !!packageItem &&
      !packagePageStore.hasPartnerDownloadURL() &&
      (packageItem.isLocal || !!user) &&
      versions.length > 0
    );
  }

  return (
    <Fragment>
      {showLoginButton ? (
        <LoginButton />
      ) : (
        <Fragment>
          <DownloadOnPartnerSiteButton />
          {shouldShowDownloadButton() && (
            <button
              data-testid="show-download-info"
              onClick={() => showOrHideDownloadInfo(!showDownloadInfo)}
              className="show-download-dialog"
            >
              {rootStore.getTranslation("actions.show_download_dialog")}
            </button>
          )}
          {showDownloadInfo && (
            <div
              data-testid="download-info"
              className="download-dialog"
              style={{ maxHeight: "680px", overflowY: "auto", overflowX: "hidden" }}
            >
              <div className="maintenance-info">
                <LicenseInfo />
              </div>
              <VersionsList />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

const OrganizationLogo = observer(() => {
  const packagePageStore = useContext(PackagePageStoreContext);
  const organization = packagePageStore.getOrganization();

  function thumbnailDataExists(): boolean {
    return !!organization && !!organization.binaries && !!organization.binaries.thumbnail;
  }

  function getThumbnailUrl() {
    return !!organization && !!organization.binaries ? organization.binaries.thumbnail.contentUrl : "";
  }

  return <div className="company-logo">{thumbnailDataExists() && <img src={getThumbnailUrl()} />}</div>;
});

const Actions = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const linkToCollectionStore = new LinkToCollectionStore(rootStore);

  useEffect(() => {
    linkToCollectionStore.fetchData();
  }, []);

  return (
    <ul className="header-actions" data-testid="packageActions">
      <LikeStoreContext.Provider value={new LikeStore(packagePageStore.getPackage())}>
        <Like />
      </LikeStoreContext.Provider>
      <LinkToCollectionStoreContext.Provider value={linkToCollectionStore}>
        <LinkToCollection />
      </LinkToCollectionStoreContext.Provider>
      <SubscribeStoreContext.Provider value={new SubscribeStore(rootStore, packagePageStore.getPackage())}>
        <Subscribe />
      </SubscribeStoreContext.Provider>
    </ul>
  );
});

const SocialMediaLinks = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);

  return (
    <section className="social-media-links">
      <h4 className="social-media-title">{rootStore.getTranslation("details.links.share_on_social_media")}</h4>
      <a data-testid="facebook-link" className="icon icon-facebook" onClick={packagePageStore.shareOnFacebook}></a>
      <a data-testid="twitter-link" className="icon icon-twitter" onClick={packagePageStore.shareOnTwitter}></a>
    </section>
  );
});

const ThumbAndDescription = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);

  return (
    <article>
      <div className="description">
        {packagePageStore.isLocalPackage() ? (
          <LocalThumbnailStoreContext.Provider
            value={new LocalThumbnailStore(rootStore, packagePageStore.getPackage())}
          >
            <LocalThumbnail />
          </LocalThumbnailStoreContext.Provider>
        ) : (
          <ImageNaviStoreContext.Provider value={new ImageNaviStore()}>
            <ThumbnailStoreContext.Provider value={new ThumbnailStore(rootStore, packagePageStore.getPackage())}>
              <Thumbnail />
              <ImageNavi />
            </ThumbnailStoreContext.Provider>
          </ImageNaviStoreContext.Provider>
        )}
        <div className="column description-box">
          <DownloadVersionInfo />
          <PackageDescription description={packagePageStore.getDescription()} />
        </div>
      </div>
      {!packagePageStore.isLocalPackage() && <SocialMediaLinks />}
    </article>
  );
});

/**
 * A component that displays misc info for package page.
 */
export const Info = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const showActions = rootStore.getUserStore().isUserLoggedIn() && !packagePageStore.isLocalPackage();

  return (
    <section className="content-details">
      <header>
        <OrganizationLogo />
        {packagePageStore.canEditPackage() && <EditorMode />}
        <PackageTitle />
        {showActions && <Actions />}
      </header>
      <ThumbAndDescription />
    </section>
  );
});
