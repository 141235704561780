import { action, observable, computed, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../../js/services/NewRepository";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { CollectionPageStore } from "../../collection/collectionPageStore";
import { PackagePageStore } from "../../package/packagePageStore";

import { ICollection, IEntity, IDropdownOption, IItem, ITranslationObject } from "../../models/dataModel";
import { DialogTypeEnum, ObjectTypeEnum, SanitizationModeEnum } from "../../models/enums";

import { sanitize, stripHtml } from "../../utils/functions";
import { LanguageCodeConverter } from "../../utils/converters/LanguageCodeConverter";

export class EditTranslationsStore {
  /** Flag that marks if the target item is an entity. */
  @observable private isEntity = false;

  /** Root store. */
  private rootStore: RootStore;

  /** Stores the saved translations. */
  @observable private savedTranslations: Record<string, ITranslationObject> = {};

  /** Stores the selected language option. */
  @observable private selectedLanguageOption?: IDropdownOption;

  /** Stores the target item */
  @observable private target?: IItem;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Checks if input for details exceeds the set max length (5000).
   * @returns true if max length exceeded
   */
  public detailsMaxLengthExceeded(details: string): boolean {
    if (details) {
      const stripped = stripHtml(details);
      return !!stripped && stripped.length > 5000;
    } else {
      return false;
    }
  }

  /**
   * Returns the selected language.
   */
  public getSelectedLanguage(): string | undefined {
    if (this.selectedLanguageOption) {
      return this.selectedLanguageOption.value;
    }
  }

  /**
   * Returns the selected language option.
   */
  public getSelectedLanguageOption(): IDropdownOption | undefined {
    return !!this.selectedLanguageOption
      ? _.find(this.supportedLanguageOptions, (option: IDropdownOption) => {
          return option.value === this.selectedLanguageOption!.value;
        })
      : undefined;
  }

  /**
   * Returns translations for selected language.
   */
  public getSelectedLanguageTranslations(): ITranslationObject | undefined {
    const lang = this.getSelectedLanguage()!;
    if (lang) {
      return this.savedTranslations[lang];
    }
  }

  /**
   * Returns the target item.
   */
  public getTargetItem(): IItem {
    return this.target!;
  }

  /**
   * Initializes the store.
   * @param item the selected item to which translations are saved.
   */
  @action
  public initialize(item: IItem) {
    if (item && item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      this.target = item as ICollection;
    } else if (item && item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      this.target = item as IEntity;
      this.isEntity = true;
    }

    this.initializeTranslations(item);
  }

  /**
   * Saves item translations for selected language.
   * @param translations translations for the selected language
   */
  public async saveTranslationsForSelectedLanguage(translations: ITranslationObject, parentStore: CollectionPageStore | PackagePageStore) {
    const selectedLanguage = this.selectedLanguageOption!.value;

    try {
      if (selectedLanguage === "default") {
        await this.saveDefaultTranslation(translations);
      } else {
        const attributes = {};
        attributes[selectedLanguage] = {};
        _.each(translations, async (translation: string, key: string) => {
          if (translation && translation.length > 0) {
            if (key === "details") {
              attributes[selectedLanguage][key] = sanitize(translation, SanitizationModeEnum.WEAK);
            } else if (key === "description") {
              attributes[selectedLanguage][key] = sanitize(translation, SanitizationModeEnum.MODERATE);
            } else {
              attributes[selectedLanguage][key] = sanitize(translation);
            }
          } else {
            attributes[selectedLanguage][key] = null;
          }
        });
        await this.saveTranslationItem(this.target!, attributes);
      }

      await this.updateSavedTranslations();

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("shared.translations.updated_successfully"));

      await parentStore.reloadData(DialogTypeEnum.TRANSLATIONS);

    } catch  {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("shared.translations.error_on_update"));
    }
  }

  /**
   * Sets the selected language option.
   * @param option selected dropdown option
   */
  @action
  public setSelectedLanguageOption(option: IDropdownOption | undefined) {
    this.selectedLanguageOption = option;
  }

  /**
   * Set the supported language options and default language.
   */
  @computed
  public get supportedLanguageOptions(): IDropdownOption[] {
    const langOptions: IDropdownOption[] = JSON.parse(
      JSON.stringify(this.rootStore.getLocalizationStore().getLocaleOptions()),
    );

    _.each(langOptions, (option: { value: string; label: string }) => {
      option.value = LanguageCodeConverter.convertIsoCodeToLang(option.value);

      if (!this.translationsExistForLanguage(option.value)) {
        option.label = option.label + this.rootStore.getTranslation("shared.translations.translations_missing");
      }
    });

    langOptions.unshift({
      label: this.rootStore.getTranslation("shared.translations.default"),
      value: "default",
    });

    return langOptions;
  }

  /**
   * Returns if the target item is an entity.
   */
  public targetIsEntity(): boolean {
    return this.isEntity;
  }

  /**
   * Initializes translations for supported languages.
   * If no translation exists, creates the field and sets it as an empty string.
   */
  @action
  private initializeTranslations(item: IItem) {
    const translations = {};

    if (item) {
      translations["default"] = {};
      translations["default"].title = item.title;
      translations["default"].description = sanitize(item.description, SanitizationModeEnum.MODERATE) || "";

      if (this.isEntity) {
        translations["default"].copyright = (item as IEntity).copyrightInfo || "";
        translations["default"].details = sanitize((item as IEntity).richContent, SanitizationModeEnum.WEAK) || "";
      }

      if (item && item.translations) {
        _.each(item.translations, (translationItem: ITranslationObject, language: string) => {
          _.each(translationItem, (translation: string, field: string) => {
            _.extend(translations[language], { [field]: translation });
          });
        });

        _.extend(translations, this.verifyTranslations(item.translations));

        _.each(this.supportedLanguageOptions, (option) => {
          if (option.value !== "default") {
            if (!translations[option.value]) {
              translations[option.value] = {};
            }
            _.each(["title", "copyright", "description", "details"], (field) => {
              _.extend(translations[option.value], {
                [field]: translations[option.value][field] || "",
              });
            });
          }
        });
      }
    }
    this.savedTranslations = translations;
  }

  /**
   * Helper method that saves the default translation for the item.
   */
  private async saveDefaultTranslation(translations) {
    this.target!.description = translations.description;
    this.target!.title = translations.title;
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      if (!!this.isEntity) {
        (this.target as IEntity).copyrightInfo = translations.copyright;
        (this.target as IEntity).richContent = sanitize(translations.details, SanitizationModeEnum.WEAK);
        await NewRepository.setPackage(this.target as IEntity, user);
      } else {
        await NewRepository.updateCollection(this.target as ICollection, user);
      }
    }
  }

  /**
   * Helper method that saves translations for the target item.
   * @param target the target item to which translations are set
   * @param attributes collection of translated attributes
   */
  private async saveTranslationItem(target, attributes) {
    await NewRepository.setTranslations(target, attributes);
  }

  /**
   * Helper method that checks if translations exist for given language
   * @param lang the given language
   */
  private translationsExistForLanguage(lang: string): boolean {
    let foundTranslations = false;
    const translations: ITranslationObject | undefined = _.find(this.savedTranslations, (translation, langKey) => {
      return langKey === lang;
    });

    !!translations &&
      _.each(translations, (translation: string) => {
        if (translation && translation !== "") {
          foundTranslations = true;
        }
      });

    return foundTranslations;
  }

  /**
   * Updates the saved translations from the target item.
   */
  @action
  private async updateSavedTranslations() {
    let item: IItem;
    const currentUserId = this.rootStore.getUserStore().getCurrentUser()?.id || "";

    if (this.isEntity) {
      item = (await NewRepository.getPackage(
        {
          id: this.target!.id,
          isLocal: this.target!.isLocal,
        },
        currentUserId,
      )) as unknown as IItem;
    } else {
      item = (await NewRepository.getCollection(
        {
          id: this.target!.id,
          isLocal: this.target!.isLocal,
        },
        currentUserId,
      )) as unknown as IItem;
    }

    if (!!this.target) {
      const translations = await NewRepository.getTranslations(this.target);
      _.extend(item, { translations: translations });
    }

    runInAction(() => {
      this.target = item;
      this.initializeTranslations(item);
    });
  }

  /**
   * Verifies the translations
   */
  private verifyTranslations(translations: Record<string, ITranslationObject>) {
    _.each(translations, (translationItem) => {
      if (translationItem.details) {
        translationItem.details = sanitize(translationItem.details, SanitizationModeEnum.WEAK);
      }
      if (translationItem.description) {
        translationItem.description = sanitize(translationItem.description, SanitizationModeEnum.MODERATE);
      }
    });
    return translations;
  }
}

export const EditTranslationsContext = createStoreContext<EditTranslationsStore>(EditTranslationsStore);
