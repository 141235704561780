import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ModusDateInput, ModusDatePicker } from "@trimble-oss/modus-react-components";

import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";
import classNames from "classnames";
import { ModusDateInputEventDetails } from "@trimble-oss/modus-web-components";
import { dateToISOString } from "utils/functions";

export const DateCreatedFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleStartDateChange(event: CustomEvent<ModusDateInputEventDetails>) {
    !!event.detail.value
      ? store.setDateCreatedStart(dateToISOString(new Date(event.detail.value)))
      : store.setDateCreatedStart(undefined);
  }

  function handleEndDateChange(event: CustomEvent<ModusDateInputEventDetails>) {
    !!event.detail.value
      ? store.setDateCreatedEnd(dateToISOString(new Date(event.detail.value)))
      : store.setDateCreatedEnd(undefined);
  }

  return (
    <div className={classNames({ "search-filter": true, sectioned: true })} data-testid="dateCreatedFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.date_created")}:</h5>
      <ModusDatePicker data-testid="search-date-created">
        <ModusDateInput
          showCalendarIcon={true}
          label={rootStore.getTranslation("profile.admin.content_manager.helpers.from")}
          format="mm/dd/yyyy"
          placeholder={rootStore.getTranslation("profile.admin.content_manager.helpers.date_format")}
          type="start"
          onValueChange={handleStartDateChange}
          data-testid="date-created-start"
        ></ModusDateInput>
        <ModusDateInput
          showCalendarIcon={true}
          label={rootStore.getTranslation("profile.admin.content_manager.helpers.to")}
          format="mm/dd/yyyy"
          placeholder={rootStore.getTranslation("profile.admin.content_manager.helpers.date_format")}
          type="end"
          onValueChange={handleEndDateChange}
          data-testid="date-created-end"
        ></ModusDateInput>
      </ModusDatePicker>
    </div>
  );
});
