import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { UploadFormContext } from "../uploadFormStore";
import { ExternalResourceTypeEnum } from "../../models/enums";

const Selected = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();
  const isPrivate = collection.getVisibilityType() === "private";

  return (
    <div className="radioactive-publish">
      <div className="radioactivedot-publish" />
      {isPrivate ? (
        <label className="icon private l-inline" data-testid="privateActive">
          {rootStore.getTranslation("collections.labels.visibilityOptions.private")}
        </label>
      ) : (
        <label className="icon public l-inline" data-testid="publicActive">
          {rootStore.getTranslation("collections.labels.visibilityOptions.public")}
        </label>
      )}
    </div>
  );
});

const Unselected = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();
  const isPrivate = collection.getVisibilityType() === "private";

  function setVisibility() {
    isPrivate ? collection.setVisibilityType("public") : collection.setVisibilityType("private");
  }

  return (
    <div className="radio-publish" onClick={setVisibility} data-testid="switchVisibilityType">
      {isPrivate ? (
        <label className="icon public l-inline">
          {rootStore.getTranslation("collections.labels.visibilityOptions.public")}
        </label>
      ) : (
        <label className="icon private l-inline">
          {rootStore.getTranslation("collections.labels.visibilityOptions.private")}
        </label>
      )}
    </div>
  );
});

/**
 * Component that renders the "Set visibility" section of the form.
 */
export const SetVisibility = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();

  const creatorIsUser = collection.getCreatorType() === ExternalResourceTypeEnum.USER;
  const creatorIsOrganization = collection.getCreatorType() === ExternalResourceTypeEnum.ORGANIZATION;
  const isPrivate = collection.getVisibilityType() === "private";
  const isPublic = collection.getVisibilityType() === "public";

  return (
    <Fragment>
      <h3 className="visibility">{rootStore.getTranslation("upload.publish.collection_visibility")}</h3>
      <ul>
        <div className="radiowrapper-publish">
          {isPrivate && (
            <div className="firstgroup">
              <Selected />
              <Unselected />
            </div>
          )}
          {isPublic && (
            <div className="secondgroup">
              <Unselected />
              <Selected />
            </div>
          )}
          {isPrivate && (
            <div className="disclaimers">
              {creatorIsUser && (
                <div className="disclaimer italic">
                  {rootStore.getTranslation("upload.publish.this_content_will_be_visible_only_to_you")}
                </div>
              )}
              {creatorIsOrganization && (
                <div className="disclaimer italic">
                  {rootStore.getTranslation(
                    "upload.publish.this_content_will_be_visible_only_to_you_and_your_organization",
                  )}
                </div>
              )}
            </div>
          )}
          {isPublic && (
            <div className="disclaimers">
              <div className="disclaimer italic">
                {rootStore.getTranslation("upload.publish.this_content_will_be_visible_to_everyone_after_publishing")}
              </div>
              {!form.isLocal() && (
                <div className="disclaimer">{rootStore.getTranslation("upload.publish.public_content_disclaimer")}</div>
              )}
            </div>
          )}
        </div>
      </ul>
    </Fragment>
  );
});
