 
export const PtAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["pt-PT"]) {
      window.locale["pt-PT"] = {};
    }

    window.locale["pt-PT"].about = {
      navigation: {
        why: "Porquê o Tekla Warehouse?",
        usage_basics: "Princípios básicos do Tekla Warehouse",
        "usage_basics__using-content": "Usar conteúdo",
        "usage_basics__adding-content": "Adicionar conteúdo",
        usage_basics__collection: "Colecção",
        "usage_basics__content-item": "Item de conteúdo",
        usage_basics__version: "Versão",
        getting_started: "Iniciação",
        "getting_started__tekla-account-and-system-requirements": "Tekla accounte requisitos do sistema",
        "getting_started__tekla-warehouse-service-plugin": "Plug-in Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Como posso obter o Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures e Tekla Warehouse",
        content: "Conteúdo no Tekla Warehouse",
        "content__3d-products": "Produtos de modelos em 3D",
        "content__custom-components": "Componentes personalizados",
        content__applications: "Aplicações",
        "content__profiles-shapes-etc": "Perfis, materiais, parafusos, armaduras, etc.",
        "content__model-templates": "Templates dos modelos",
        "content__drawing-setup": "Configuração de desenhos",
        "content__report-templates-etc": "Relatórios de templates e ficheiros de atributos",
        using_content: "Utilizar conteúdo",
        creating_content: "Criar conteúdo",
        "creating_content__getting-started-with-content-creation": "Iniciação à criação de conteúdo",
        "creating_content__expert-help-for-creating-content": "Necessita de ajuda especializada para criar conteúdo?",
        application_developers: "Desenvolvimento de aplicações para o Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Porquê o Tekla Warehouse?",
        description_1:
          "O Tekla Warehouse inclui aplicações, componentes personalizados, peças, perfis, materiais como qualidades de aço e de betão, parafusos, armaduras, malha, formas e templates para o Tekla Structures. A utilização de peças reais dos fornecedores nos modelos permite a mais elevada precisão e disponibiliza informações exactas acerca do material para efeitos de planeamento e de aquisição; a utilização de conteúdo já pronto permite uma modelação mais rápida.",
        description_2:
          "O Tekla Warehouse proporciona um acesso centralizado a este conteúdo, o qual pode agora ser utilizado de uma forma optimizada. O conteúdo do Tekla Warehouse está sempre a aumentar.",
        description_3: "Com o Tekla Warehouse",
        feature_1:
          "Os fabricantes podem disponibilizar produtos e aplicações para os utilizadores, os quais podem usar os modelos exactos dos produtos em 3D directamente nos respectivos modelos Tekla; mais tarde, podem usar o produto físico na construção.",
        feature_2:
          "É possível usar a rede da empresa para partilhar conteúdo com colegas e guardar conteúdo localmente para utilização privada",
        description_4:
          "O Tekla Warehouse facilita a pesquisa do material exacto necessário, independentemente do tipo de conteúdo.",
        description_5: "É necessário o plug-in Tekla Warehouse Service para activar as colecções locais e de rede.",
        view_licences_html: 'Ver as <a href="/api/third_party_licenses.txt" target="_blank">licenças de terceiros</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Princípios básicos do Tekla Warehouse",
        using_content: {
          title: "Usar conteúdo",
          description_1:
            "É possível usar o conteúdo em colecções online, partilhar conteúdo com os colegas através das colecções de rede e usar colecções locais para utilização privada.",
          description_2:
            "As colecções locais e de rede necessitam do Tekla Warehouse Service, o qual é instalado com o Tekla Structures 20.1 Service Release 1 e versões mais recentes do Tekla Structures.",
          description_3:
            "As colecções Tekla específicas que contêm conteúdo em ambiente localizado são instaladas com o Tekla Structures.",
          description_4: "Consulte a secção Usar conteúdo para obter mais informações.",
        },
        adding_content: {
          title: "Adicionar conteúdo",
          description_1:
            'É possível usar colecções online para publicar conteúdo para todos os utilizadores do Tekla Warehouse. Para poder publicar conteúdo na qualidade de organização, é necessário adicionar a organização a account.tekla.com. Contacte o <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">escritório ou o revendedor Tekla</a> local para obter mais informações.',
          description_2: "Consulte a secção Criar conteúdo para obter mais informações.",
        },
        collection: {
          title: "Colecção",
          feature_1:
            "As colecções agrupam itens de conteúdo em conjuntos, como pastas, e definem os direitos de acesso ao conteúdo (público/privado).",
          feature_1_1:
            "Os modeladores podem utilizar conteúdo em colecções online, partilhar conteúdo com os colegas através das colecções de rede e usar colecções locais para utilização privada.",
          feature_2:
            "Os fornecedores de conteúdo podem usar colecções online para publicar o respectivo conteúdo para todos os utilizadores do Tekla Warehouse.",
          feature_3:
            "As colecções locais e de rede necessitam do plug-in Tekla Warehouse Service, o qual é instalado com o Tekla Structures 20.1 Service Release 1 e versões mais recentes e também pode ser instalado separadamente.",
        },
        "content-item": {
          title: "Item de conteúdo",
          feature_1:
            "Um item de conteúdo é um grupo lógico de ficheiros. Por exemplo, um catálogo de produtos em 3D, um template do modelo, uma ferramenta, um grupo de componentes personalizados ou um catálogo de perfis são itens de conteúdo.",
          feature_2:
            "Os itens de conteúdo podem ser etiquetados por tipo e por utilização, o que ajuda os utilizadores a encontrarem o conteúdo.",
        },
        version: {
          title: "Versão",
          feature_1:
            "Em simultâneo, um item de conteúdo pode ter muitas versões que são utilizadas com diferentes versões do Tekla Structures. O utilizador pode seleccionar a versão correcta na página de conteúdo.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Iniciação",
        tekla_account_and_system_requirements: {
          title: "Tekla accounte requisitos do sistema",
          register_atc_html:
            'É necessária uma Tekla account para iniciar sessão no Tekla Warehouse. Se ainda não se registou, faça-o em <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>. (Tenha em atenção que esta não é a antiga conta da Extranet.)',
          see_tua_for_prerequisites_html:
            'Consulte o Tekla User Assistance para obter informações sobre os <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">pré-requisitos para usar o Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Plug-in Tekla Warehouse Service",
          description_1:
            "Para além de ser um armazenamento online para conteúdo, o Tekla Warehouse permite igualmente:",
          feature_1: "Colecções locais para utilização pessoal",
          feature_2: "Colecções de rede para partilhar conteúdo com colegas através da rede da empresa",
          feature_3: "Fácil instalação durante a utilização ou a criação de conteúdo",
          feature_4: "Pesquisa de conteúdo local e em rede",
          description_2:
            "Tal é possível com o Tekla Warehouse Service, um plug-in que funciona como um back-end local e que comunica com o Tekla Structures e com o Website do Tekla Warehouse.",
          description_3:
            "Se o Tekla Warehouse Service não estiver instalado ou não estiver a funcionar, o Tekla Warehouse realça este facto no topo da página para permitir a resolução de problemas.",
          description_4:
            "Se ocorrerem problemas com o serviço, é possível reiniciá-lo através do programa Serviços no Windows. O nome do serviço é Tekla Warehouse. Verifique igualmente as definições de segurança do navegador.",
          description_5:
            "É possível usar o Website do Tekla Warehouse sem o Tekla Warehouse Service ou sem o Tekla Structures. Nesse caso, a experiência será mais semelhante à de um Website de partilha de conteúdo típico, em que os ficheiros são carregados e transferidos manualmente.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Como posso obter o Tekla Warehouse Service?",
          description_1:
            "O plug-in Tekla Warehouse Service é instalado com o Tekla Structures 20.1 Service Release 1 e versões mais recentes. É igualmente possível instalar o Tekla Warehouse Service em separado para activar as colecções locais e de rede, mas a instalação fácil e as funcionalidades de carregamento só são possíveis com o Tekla Structures 20.1 Service Release 1 e versões mais recentes.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">TRANSFERIR O TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures e Tekla Warehouse",
          description_1:
            "O Tekla Warehouse estabelece uma ligação directa com o Tekla Structures, facilitando a utilização de conteúdo existente ou o carregamento deste. É possível seleccionar conteúdo directamente a partir do modelo e também inserir conteúdo directamente no modelo, sem necessidade de importar/exportar ficheiros (para obter esta funcionalidade, é necessário o Tekla Structures 20.1 Service Release 1 e versões mais recentes). Todos os ficheiros necessários podem ser incluídos no mesmo item de conteúdo, como um componente personalizado ou um perfil, para assegurar que tudo funciona de acordo com o pretendido.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Conteúdo no Tekla Warehouse",
        description_1:
          "O Tekla Warehouse disponibiliza diferentes tipos de conteúdo. Aqui pode encontrar informações acerca dos diferentes tipos de conteúdo e mais informações de iniciação.",
        description_2:
          'A partir do Tekla Warehouse, também pode transferir <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Extensões Tekla</a>.',
        "3d_products": {
          title: "Produtos de modelos em 3D",
          image_subtitle:
            "Estes são objectos com geometria fixa, por exemplo, objectos embutidos, cofragem e acessórios.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre como <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">Criar um catálogo de produtos 3D numa colecção do Tekla Warehouse</a>.',
        },
        custom_components: {
          title: "Componentes personalizados",
          image_subtitle:
            "Os componentes personalizados são objectos nativos do Tekla Structures com geometria fixa ou paramétrica.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre como <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Carregar conteúdo de um modelo do Tekla Structures para uma colecção do Tekla Warehouse</a>.',
        },
        applications: {
          title: "Aplicações",
          image_subtitle:
            "As aplicações utilizam a Tekla Open API para interagir com o Tekla Structures. Por exemplo, ligações de integração de dados numa solução de design.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre como <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">Carregar uma aplicação para uma colecção do Tekla Warehouse</a>.',
        },
        profiles_shapes_etc: {
          title: "Perfis, formas, materiais, conjuntos de parafusos, armaduras e malhas",
          image_subtitle:
            "Os catálogos contêm listas de propriedades relacionadas com peças. Por exemplo, diferentes qualidades do material e respectivas densidades.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre como <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Carregar conteúdo de um modelo do Tekla Structures para uma colecção do Tekla Warehouse</a>.',
        },
        model_templates: {
          title: "Templates dos modelos",
          image_subtitle:
            "É possível usar um template do modelo para projectos semelhantes e recorrentes. O template pode conter todos os catálogos, templates de listas, configurações de desenhos e definições de opções avançadas necessários.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre como <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adicionar ficheiros manualmente a uma colecção do Tekla Warehouse</a>.',
        },
        drawing_setup: {
          title: "Configuração de desenhos",
          image_subtitle:
            "Os ficheiros de configuração definem o modo de criação dos desenhos, por exemplo, disposições, filtros e templates de clonagem.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre como <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adicionar ficheiros manualmente a uma colecção do Tekla Warehouse</a>.',
        },
        report_templates_etc: {
          title: "Templates de listas e ficheiros de atributos dos modelos",
          description_1:
            "Os templates de listas definem o conteúdo e a disposição dos dados do modelo numa lista. Os ficheiros de atributos dos modelos, por exemplo, filtros, funcionam quando adicionados à pasta do modelo.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre como <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adicionar ficheiros manualmente a uma colecção do Tekla Warehouse</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Usar conteúdo",
        description_1:
          "Dependendo do tipo de conteúdo, pode inserir o conteúdo escolhido no modelo do Tekla Structures ou transferi-lo e instalá-lo. Consulte o Tekla User Assistance para obter instruções acerca dos seguintes tópicos",
        feature_1:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Pesquisar conteúdo no Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Usar conteúdo do Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Inserir conteúdo do Tekla Warehouse num modelo do Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Gestão de conflitos no Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Criar conteúdo",
        getting_started_with_content_creation: {
          title: "Iniciação à criação de conteúdo",
          description_1: "Consulte o Tekla User Assistance para obter mais informações acerca dos seguintes tópicos:",
          feature_1:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_getting_started" target="_blank">Iniciação ao Tekla Warehouse</a>',
          feature_2:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_adding_content" target="_blank">Adicionar conteúdo ao Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Modificar conteúdo no Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Transferir conteúdo do Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Necessita de ajuda especializada para criar conteúdo?",
          description_1:
            'Se está interessado em publicar conteúdo no Tekla Warehouse, mas gostaria de dispor de ajuda especializada na criação e na manutenção desse conteúdo, contacte o <a href="http://www.tekla.com/contact" target="_blank">escritório ou o revendedor Tekla</a> local para obter mais informações acerca de possibilidades de colaboração.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Desenvolvimento de aplicações para o Tekla Structures",
        description_1:
          "Devido ao facto de a Tekla advogar uma abordagem aberta em relação à modelação de informações de construção (BIM - Building Information Modeling), é possível executar soluções de outros fornecedores e usar outra maquinaria de fabrico e continuar a efectuar a interface com a Tekla.",
        description_2:
          'O <a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> é um interface de programação de aplicações (API - Application Programming Interface) desenvolvido pela Tekla que permite o desenvolvimento de aplicações e de funcionalidade adicional na plataforma de modelação do Tekla Structures e a respectiva integração em ambiente próprio. O Tekla Open API é implementado através da utilização da tecnologia Microsoft .NET.',
        description_3:
          'O <a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> é o ponto central para todas as informações relacionadas com o Tekla Open API.',
        description_4:
          'Adira ao <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> se estiver interessado em desenvolver aplicações para o Tekla Structures.',
      },
    };
  },
};
