 
export const FrAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["fr-FR"]) {
      window.locale["fr-FR"] = {};
    }

    window.locale["fr-FR"].about = {
      navigation: {
        why: "Pourquoi Tekla Warehouse ?",
        usage_basics: "Bases de Tekla Warehouse",
        "usage_basics__using-content": "Utilisation du contenu",
        "usage_basics__adding-content": "Ajout de contenu",
        usage_basics__collection: "Collection",
        "usage_basics__content-item": "Élément de contenu",
        usage_basics__version: "Version",
        getting_started: "Démarrage",
        "getting_started__tekla-account-and-system-requirements": "Tekla account et configuration requise",
        "getting_started__tekla-warehouse-service-plugin": "Programme additionnel Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Comment obtenir Tekla Warehouse Service ?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures et Tekla Warehouse",
        content: "Contenu de Tekla Warehouse",
        "content__3d-products": "Produits de modèle 3D",
        "content__custom-components": "Composants personnalisés",
        content__applications: "Applications",
        "content__profiles-shapes-etc": "Profils, matériaux, boulons, armatures, etc.",
        "content__model-templates": "Prototypes",
        "content__drawing-setup": "Paramètres de dessin",
        "content__report-templates-etc": "Gabarits de listes et fichiers d'attributs",
        using_content: "Utilisation du contenu",
        creating_content: "Création de contenu",
        "creating_content__getting-started-with-content-creation": "Premiers pas avec la création de contenu",
        "creating_content__expert-help-for-creating-content": "Aide experte pour la création de contenu ?",
        application_developers: "Développement d'applications pour Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Pourquoi Tekla Warehouse ?",
        description_1:
          "Tekla Warehouse comprend des applications, des composants personnalisés, des pièces, des profils, des matériaux tels que l'acier et le béton, des boulons, des armatures, des treillis, des formes et des gabarits pour Tekla Structures. Les pièces réelles de fournisseurs utilisées dans les modèles permettent d'obtenir le meilleur degré de précision qui soit et des informations sur les matériaux aussi exactes que possible pour la planification et l'approvisionnement. L'utilisation d'un contenu prêt à être utilisé permet une modélisation plus rapide.",
        description_2:
          "Tekla Warehouse fournit un accès centralisé à ce contenu, dont l'application peut désormais être rationalisée. Le contenu de Tekla Warehouse est en constante augmentation.",
        description_3: "Avec Tekla Warehouse",
        feature_1:
          "Les fabricants peuvent mettre leurs produits et applications à la disposition des utilisateurs, qui peuvent intégrer leurs produits 3D exacts directement dans leurs modèles Tekla et utiliser par la suite le produit physique dans la construction.",
        feature_2:
          "Vous pouvez utiliser le réseau de votre entreprise pour partager un contenu avec vos collègues et enregistrer du contenu localement pour un usage privé",
        description_4:
          "La recherche dans le Tekla Warehouse vous permet de trouver, en toute simplicité, exactement ce dont vous avez besoin, peu importe le type de contenu.",
        description_5:
          "Le programme additionnel Tekla Warehouse Service est nécessaire pour activer les collections locales et réseau.",
        view_licences_html: 'Voir les <a href="/api/third_party_licenses.txt" target="_blank">licences tierces</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Bases de Tekla Warehouse",
        using_content: {
          title: "Utilisation du contenu",
          description_1:
            "Vous pouvez utiliser le contenu dans les collections en ligne, partager du contenu avec vos collègues via les collections réseau, et utiliser les collections locales pour un usage privé.",
          description_2:
            "Les collections locales et réseau doivent disposer de Tekla Warehouse Service, qui est installé avec le patch correctif 1 de Tekla Structures20.1 ainsi que les versions plus récentes de Tekla Structures.",
          description_3:
            "Les collections spécifiques de Tekla comportant le contenu d'environnement localisé sont installées avec Tekla Structures.",
          description_4: "Voir la section Utilisation de contenu pour plus d'informations.",
        },
        adding_content: {
          title: "Ajout de contenu",
          description_1:
            'Vous pouvez utiliser les collections en ligne pour publier votre contenu et le rendre accessible à tous les utilisateurs de Tekla Warehouse. Pour pouvoir publier le contenu en tant qu\'organisation, votre société doit être ajoutée à account.tekla.com. Contactez votre <a href="https://www.tekla.com/fr/contact/offices-and-resellers" target="_blank">bureau ou revendeur Tekla </a> local pour plus d\'informations.',
          description_2: "Voir la section Création de contenu pour plus d'informations.",
        },
        collection: {
          title: "Collection",
          feature_1:
            "Les collections regroupent des éléments de contenu, comme des dossiers, et définissent les droits d'accès à ce contenu (public/privé).",
          feature_1_1:
            "Les modélisateurs peuvent utiliser le contenu dans les collections en ligne, partager le contenu avec des collègues via les collections réseau, et utiliser les collections locales pour un usage privé.",
          feature_2:
            "Les fournisseurs de contenu peuvent utiliser les collections en ligne pour publier leur contenu et le rendre accessible à tous les utilisateurs du Tekla Warehouse.",
          feature_3:
            "Les collections locales et réseau nécessitent le programme additionnel Tekla Warehouse Service, qui est installé avec le patch correctif 1 de Tekla Structures 20.1 ou des versions plus récentes et qui peut être également installé séparément.",
        },
        "content-item": {
          title: "Élément de contenu",
          feature_1:
            "Un élément de contenu est un groupe logique de fichiers. Il peut s'agir, par exemple, d'un catalogue de produits 3D, d'un prototype, d'un outil, d'un groupe de composants personnalisés ou d'un catalogue de profils.",
          feature_2:
            "Les éléments de contenu peuvent être étiquetés par type et cas d'utilisation, ce qui aide les utilisateurs à faire des recherches dans votre contenu.",
        },
        version: {
          title: "Version",
          feature_1:
            "Un élément de contenu peut avoir plusieurs versions qui sont utilisées avec différentes versions de Tekla Structures. L'utilisateur peut sélectionner la version souhaitée depuis la page de contenu.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Démarrage",
        tekla_account_and_system_requirements: {
          title: "Tekla account et configuration requise",
          register_atc_html:
            "Vous avez besoin d’un Tekla account pour vous connecter à Tekla Warehouse. Si vous ne l'avez pas encore fait, veuillez vous enregistrer sur le site <a href=\"https://account.tekla.com/\" target=\"_blank\">account.tekla.com</a>. (Veuillez noter qu'il ne s'agit pas de l'ancien compte Extranet.)",
          see_tua_for_prerequisites_html:
            'Voir Tekla User Assistance pour plus d’informations sur <a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">les prérequis pour l\'utilisation de Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Programme additionnel Tekla Warehouse Service",
          description_1:
            "En plus de constituer un stockage en ligne pour votre contenu, le Tekla Warehouse vous apporte également :",
          feature_1: "des collections locales pour un usage personnel,",
          feature_2:
            "des collections réseau pour partager du contenu avec des collègues en utilisant le réseau de l'entreprise,",
          feature_3: "une installation simple en utilisant ou en créant le contenu,",
          feature_4: "une fonction de recherche dans le contenu local et réseau.",
          description_2:
            "Ceci est possible avec Tekla Warehouse Service, un programme additionnel qui fonctionne comme un serveur local, communiquant avec Tekla Structures et le site Web Tekla Warehouse.",
          description_3:
            "Si Tekla Warehouse Service n'est pas installé ou n'est pas en cours d'exécution, ce problème est mis en évidence par le Tekla Warehouse en haut de la page pour permettre d'y remédier.",
          description_4:
            "En cas de problèmes en rapport avec le service, vous pouvez le redémarrer en utilisant le programme Services dans Windows. Le nom du service est Tekla Warehouse. Vérifiez également les paramètres de sécurité de votre navigateur.",
          description_5:
            "Le site Web Tekla Warehouse peut être utilisé sans Tekla Warehouse Serviceou Tekla Structures. L'expérience sera alors plus proche de celle d'un site Web de partage de contenu classique sur lequel les fichiers sont chargés et téléchargés manuellement.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Comment obtenir Tekla Warehouse Service ?",
          description_1:
            "Le programme additionnel Tekla Warehouse Service est installé avec le patch correctif 1 de Tekla Structures 20.1 et les versions ultérieures. Vous pouvez également installer Tekla Warehouse Service séparément pour activer les collections locales et réseau, mais les fonctions facilitant l'installation et le chargement sont possibles uniquement avec Tekla Structures 20.1, patch correctif 1, et ses versions plus récentes.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">TÉLÉCHARGER TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures et Tekla Warehouse",
          description_1:
            "Tekla Warehouse se connecte directement à Tekla Structures, facilitant ainsi l'utilisation ou le chargement du contenu. Vous pouvez sélectionner du contenu directement depuis votre modèle et aussi insérer du contenu directement dans le modèle sans avoir à vous soucier des fichiers d'import/export (pour cela, vous devez disposer de Tekla Structures 20.1, patch correctif 1, ou d'une version plus récente). Tous les fichiers nécessaires peuvent être inclus dans le même élément de contenu, tel qu'un composant personnalisé ou un profil, pour vérifier si tout fonctionne comme prévu.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Contenu de Tekla Warehouse",
        description_1:
          "Tekla Warehouse offre plusieurs types de contenu différents. Vous trouverez ici une vue d'ensemble des différents types de contenu et plus d'informations de mise en route.",
        description_2:
          'À partir de Tekla Warehouse, vous pouvez également télécharger les <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Extensions Tekla</a>.',
        "3d_products": {
          title: "Produits de modèle 3D",
          image_subtitle:
            "Il s'agit d'objets dont la géométrie est fixe, par exemple des inserts, coffrages et accessoires.",
          tua_link_html:
            'Voir Tekla User Assistance pour plus d’informations sur la <a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">création d’un catalogue de produits 3D dans une collection Tekla Warehouse</a>.',
        },
        custom_components: {
          title: "Composants personnalisés",
          image_subtitle:
            "Les composants personnalisés sont les objets natifs de Tekla Structures possédant une géométrie fixe ou paramétrique.",
          tua_link_html:
            'Voir Tekla User Assistance pour plus d’informations sur le <a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">chargement de contenu à partir d\'un modèle Tekla Structures dans une collection Tekla Warehouse</a>.',
        },
        applications: {
          title: "Applications",
          image_subtitle:
            "Des applications utilisent Tekla Open API pour interagir avec Tekla Structures. Par exemple, l'intégration de données est reliée à une solution de conception.",
          tua_link_html:
            'Voir Tekla User Assistance pour plus d’informations sur le <a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">chargement d\'une application dans une collection Tekla Warehouse</a>.',
        },
        profiles_shapes_etc: {
          title: "Profils, formes, matériaux, assemblages de boulons, armatures et treillis",
          image_subtitle:
            "Les catalogues contiennent des listes de propriétés relatives aux pièces. Par exemple, les différents matériaux et leurs densités.",
          tua_link_html:
            'Voir Tekla User Assistance pour plus d’informations sur le <a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">chargement de contenu à partir d\'un modèle Tekla Structures dans une collection Tekla Warehouse</a>.',
        },
        model_templates: {
          title: "Prototypes",
          image_subtitle:
            "Un prototype peut être utilisé pour des projets similaires récurrents. Le prototype peut contenir tous les catalogues, gabarits de listes, paramètres de dessin et paramètres d'options avancées nécessaires.",
          tua_link_html:
            'Voir Tekla User Assistance pour plus d’informations sur l\'<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Ajout manuel de fichiers à une collection Tekla Warehouse</a>.',
        },
        drawing_setup: {
          title: "Paramètres de dessin",
          image_subtitle:
            "Les fichiers de configuration définissent la manière dont les dessins sont créés, par exemple les mises en page, filtres et gabarits de clonage.",
          tua_link_html:
            'Voir Tekla User Assistance pour plus d’informations sur l\'<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Ajout manuel de fichiers à une collection Tekla Warehouse</a>.',
        },
        report_templates_etc: {
          title: "Gabarits de listes et fichiers d'attributs de modèle",
          description_1:
            "Les gabarits de listes définissent le contenu et la mise en page des données de modèle dans une liste. Les fichiers d'attributs de modèle, par exemple les filtres, fonctionnent lorsqu'ils sont ajoutés au répertoire modèle.",
          tua_link_html:
            'Voir Tekla User Assistance pour plus d’informations sur l\'<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Ajout manuel de fichiers à une collection Tekla Warehouse</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Utilisation du contenu",
        description_1:
          "Selon le type de contenu, vous pouvez soit insérer le contenu choisi dans votre modèle Tekla Structures, soit le télécharger et l'installer. Voir Tekla User Assistance pour obtenir des instructions sur",
        feature_1:
          '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">La recherche de contenu dans Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">L\'utilisation du contenu de Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">L\'insertion de contenu Tekla Warehouse dans un modèle Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">La gestion des conflits dans Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Création de contenu",
        getting_started_with_content_creation: {
          title: "Premiers pas avec la création de contenu",
          description_1: "Voir Tekla User Assistance pour plus d’informations sur :",
          feature_1:
            '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Le démarrage avec Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">L\'ajout de contenu dans Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">La modification de contenu dans Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Le téléchargement de contenu depuis Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Aide experte pour la création de contenu ?",
          description_1:
            'Si vous êtes intéressé par la publication de contenu dans Tekla Warehouse, et que vous souhaitez bénéficier d\'une aide experte pour créer et administrer ce contenu, veuillez contacter votre <a href="https://www.tekla.com/fr/contact/offices-and-resellers" target="_blank">bureau ou revendeur Tekla</a> local pour plus d\'informations sur les options de collaboration.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Développement d'applications pour Tekla Structures",
        description_1:
          "Comme Tekla a une approche ouverte de la modélisation de l'information du bâtiment (BIM), vous pouvez utiliser les machines et les solutions d'autres fournisseurs tout en restant connecté à Tekla.",
        description_2:
          '<a href="https://www.tekla.com/fr/solutions/interop%C3%A9rabilit%C3%A9-de-la-conception/open-api" target="_blank">Tekla Open API</a> est une interface de programmation d\'applications (API) spécialisée développée par Tekla qui vous permet de créer des applications et des fonctionnalités supplémentaires sur la plateforme de modélisation Tekla Structures et de les intégrer à votre environnement. Tekla Open API est mis en œuvre à l\'aide de la technologie Microsoft .NET.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center</a> est la plate-forme pour toutes les informations relatives à Tekla Open API.',
        description_4:
          'Rejoignez <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> si vous souhaitez développer des applications pour Tekla Structures.',
      },
    };
  },
};
