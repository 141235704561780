import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import { RootContext } from "../../stores/rootStore";
import { BatchEditDialog } from "./BatchEditDialog";
import { BatchEditDialogStore, BatchEditDialogStoreContext } from "./batchEditDialogStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import classNames from "classnames";
import { DialogContext, DialogStore } from "../dialogStore";
import { Dialog } from "../Dialog";
/**
 * A component that displays edit translations dialog.
 */
export const BatchEdit = observer(() => {
  const rootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const dialog = new DialogStore();
  const batchEditDialogStore = new BatchEditDialogStore(
    rootStore,
    editorModeStore.getAvailableCollections(),
    editorModeStore.getResource(),
  );

  function shouldButtonBeDisabled() {
    return editorModeStore.getAvailableCollections().length <= 0;
  }

  function openDialog() {
    batchEditDialogStore.initViewableContent();
    dialog.open();
  }

  return (
    <Fragment>
      {editorModeStore.getEditorMode() && (
        <DialogContext.Provider value={dialog}>
          <div>
            <div className="batch-edit">
              <button
                data-testid="open-batch-edit-dialog"
                disabled={shouldButtonBeDisabled()}
                onClick={openDialog}
                className={classNames("button-primary button-bulk-edit")}
              >
                {rootStore.getTranslation("actions.batch_edit")}
              </button>
            </div>
          </div>
          <BatchEditDialogStoreContext.Provider value={batchEditDialogStore}>
            <Dialog content={<BatchEditDialog />} additionalClass="batch-edit" />
          </BatchEditDialogStoreContext.Provider>
        </DialogContext.Provider>
      )}
    </Fragment>
  );
});
