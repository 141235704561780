import * as React from "react";
import { useContext, Fragment, useState } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../uploadFormStore";

import { Support } from "./Support";
import { ContentInformation } from "./ContentInformation";
import { SearchCriteria } from "./SearchCriteria";
import { UploadEntityStore } from "./uploadEntityStore";

/**
 * Component for the 'details' field of the Content page.
 */
const DetailsField = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity: UploadEntityStore = form.getEntityStore();

  const [visited, setVisited] = useState(false);

  function handleInput(event) {
    event.preventDefault();
    entity.setDetails(event.target.value || "");
  }

  function checkValidity() {
    setVisited(true);
  }

  const detailsTooLong = visited && entity.getDetails().length > form.getValidations().package.details.maxLength;

  return (
    <div className="details">
      <label className="tooltip-info" title={rootStore.getTranslation("upload.detailsSubtitle")}>
        {rootStore.getTranslation("upload.details")}
        <span className="icon help blue" />
      </label>
      <textarea
        name="details"
        maxLength={form.getValidations().package.details.maxLength}
        onChange={handleInput}
        onBlur={checkValidity}
        value={entity.getDetails()}
        data-testid="setDetails"
      />
      {detailsTooLong && (
        <div className="error">
          <small className="error">
            {rootStore.getTranslation("shared.catalog_entity_edit.details_max_length_exceeded")}
          </small>
        </div>
      )}
    </div>
  );
});

/**
 * Component for the 'Upload content' page.
 */
export const UploadContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <Fragment>
      <header className="step-main" data-testid="contentHeader">
        <h2>{rootStore.getTranslation("upload.version.content_information")}</h2>
      </header>
      <div className="wrapper as-table-wide">
        <ContentInformation />
        <SearchCriteria />
        <Support />
      </div>
      <DetailsField />
      <hr />
    </Fragment>
  );
});
