import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { SearchPageListStyleEnum } from "../models/enums";

import { ResultOptionsStoreContext } from "../components/result-options/resultOptionsStore";
import { SelectableEntity } from "../components/SelectableEntity";
import { TranslatedHtml } from "../components/TranslatedHtml";

const ResultsList = observer(() => {
  const resultOptionsStore = useContext(ResultOptionsStoreContext);
  const searchPageSettingsStore = resultOptionsStore.getSearchPageSettingsStore();

  return (
    <div className="results" data-testid="shopping-cart-results">
      {resultOptionsStore.getResults().map((entity, i) => {
        return (
          <div key={i} className="result" scroll-if="entity.id === data.entityId">
            <SelectableEntity
              entity={entity}
              listStyle={searchPageSettingsStore.resultListStyle}
              isSelectable={resultOptionsStore.canSelectEntity(entity)}
            />
          </div>
        );
      })}
    </div>
  );
});

/**
 * A component that renders entities in shopping cart page or information about lack of results.
 */
export const Results = observer(() => {
  const resultOptionsStore = useContext(ResultOptionsStoreContext);

  function filteredResultsPresent() {
    return resultOptionsStore.getResults().length > 0;
  }

  function inThumbnailsMode() {
    return resultOptionsStore.getSearchPageSettingsStore().resultListStyle == SearchPageListStyleEnum.THUMBNAILS;
  }

  function noContentTranslationKey(): string {
    if (resultOptionsStore.isVersionSelected) {
      return "download.shopping_cart_no_content_for_version";
    } else {
      return "download.shopping_cart_no_content";
    }
  }

  function isLoadingTranslationKey(): string {
    return resultOptionsStore.itemsCount <= 100 ? "shared.spinner_loading" : "download.cart_loading";
  }

  return (
    <section
      className={classNames("shopping-cart-results-container", {
        "as-thumbnails": inThumbnailsMode(),
      })}
    >
      <div className="listing-wrapper">
        {resultOptionsStore.isLoading() && (
          <div className="l-center-align">
            <div className="spinner">
              <TranslatedHtml entry={isLoadingTranslationKey()} />
            </div>
          </div>
        )}
        {!resultOptionsStore.isLoading() && (
          <div className="results-summary">
            {!filteredResultsPresent() && (
              <span className="label results-count" data-testid="no-results">
                <TranslatedHtml entry={noContentTranslationKey()} />
              </span>
            )}
            {filteredResultsPresent() && (
              <span className="label results-count" data-testid="results-count">
                <TranslatedHtml
                  entry="collections.entity_list.results_summary"
                  args={[resultOptionsStore.getResults().length]}
                />
              </span>
            )}
            <ResultsList />
          </div>
        )}
      </div>
    </section>
  );
});
