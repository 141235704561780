import * as React from "react";
import { Fragment } from "react";

import { LocalServiceInfo } from "./LocalServiceInfo";
import { SuperNavi } from "./supernavi/SuperNavi";
import { MainNavi } from "./mainnavi/MainNavi";

/**
 * A component that displays header elements.
 */
export const AppHeader = () => {
  return (
    <Fragment>
      <header id="masthead">
        <LocalServiceInfo />
        <SuperNavi />
        <MainNavi />
      </header>
    </Fragment>
  );
};
