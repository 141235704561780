import { DateTime } from "luxon";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import _ from "underscore";

import { NewRepository } from "../js/services/NewRepository";
import { RootStore } from "./rootStore";

import { IItem, INotification } from "../models/dataModel";
import { NotificationActionEnum, NotificationStatusEnum } from "../models/enums";

/**
 * Store for handling notifications.
 */
export class NotificationsStore {
  @observable private intervalId = 0;
  /**
   * Notifications
   */
  @observable private notifications: INotification[] = [];

  /**
   * Flag that indicates that notifications are being fetched.
   */
  @observable private isLoading = false;

  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);

    this.rootStore = rootStore;
  }

  /**
   * Sets all notifications to 'read'.
   */
  @action
  public async clearAll() {
    this.isLoading = true;
    try {
      await NewRepository.setNotificationsStatus(null, NotificationStatusEnum.READ);
    } catch {
      console.log("Failed to mark notifications as read");
    } finally {
      setTimeout(async () => {
        await this.fetchNotifications();

        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("alerts.notifications_marked_as_read"));

        runInAction(() => {
          this.isLoading = false;
        });
      }, 4500);
    }
  }

  /**
   * Triggers notification fetch
   */
  public async fetchData() {
    await this.fetchNotifications();
    this.triggerPoll();
  }

  /**
   * Gets five latests notifications for a given item.
   * @param item item the user has subscribed to
   * @returns five latest active notifications
   */
  public getContentNotificationsFor(item: IItem): INotification[] {
    const contentNotifications = this.notifications.filter((n) => n.subjectId === item.id);

    if (contentNotifications) {
      return contentNotifications.slice(-5);
    } else {
      return contentNotifications;
    }
  }

  /**
   * Gets the loading state.
   * @returns Loading state
   */
  public isLoadingNotifications(): boolean {
    return this.isLoading;
  }

  /**
   * Gets notifications.
   * @returns Notifications
   */
  public getNotifications(): INotification[] {
    return this.notifications;
  }

  /**
   * Returns true if the user or speficied item has unread alerts.
   * @param item an item the user has subscribed to
   */
  public hasUnreadAlerts(item?: IItem): boolean {
    if (item) {
      return !!this.getUnreadNotificationForItem(item.id!);
    } else {
      return this.isAnyNotificationUnread;
    }
  }

  /**
   * Generates a message that informs the user about the updates for an item.
   * Displayed when user holds cursor over the alert icon.
   * @param item item the user has subscribed to
   */
  public lastUpdatedText(item: IItem) {
    const notification = this.getUnreadNotificationForItem(item.id!);
    if (notification) {
      return this.rootStore.getTranslation(
        "alerts.updated_func",
        DateTime.fromISO(notification.createTime).toRelative(),
      );
    } else {
      return this.rootStore.getTranslation("alerts.no_updates");
    }
  }

  /**
   * Marks notifications of the given item as 'read'.
   * @param item the item whose notifications are to be marked as read
   */
  @action
  public async markAsRead(item: IItem) {
    this.isLoading = true;
    try {
      await NewRepository.setNotificationsStatus(item.id, NotificationStatusEnum.READ);
    } catch {
      console.log("Failed to mark notifications as read");
    } finally {
      setTimeout(async () => {
        await this.fetchNotifications();

        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("alerts.notifications_marked_as_read"));

        runInAction(() => {
          this.isLoading = false;
        });
      }, 4500);
    }
  }

  @action
  private async fetchNotifications() {
    const currentUser = !!this.rootStore && this.rootStore.getUserStore().getCurrentUser();
    if (!currentUser) return;

    try {
      const subscriptions: IItem[] = await NewRepository.getSubscriptions(true, currentUser.id);
      const notifications: INotification[] = await NewRepository.getNotifications();

      runInAction(() => {
        this.notifications = this.filterNotifications(notifications, subscriptions);
      });

    } catch {
      runInAction(() => {
        this.notifications = [];
      });
      if (this.intervalId > 0) {
        window.clearInterval(this.intervalId);
      }
    }
  }

  private filterNotifications(notifications: INotification[], subscriptions: IItem[]): INotification[] {
    return _.filter(notifications, (notification) => {
      return _.some(subscriptions, (subscription) => {
        return notification.subjectId === subscription.id && this.isValidNotification(notification);
      });
    });
  }

  /** Helper method for checking if item has unread notifications */
  private getUnreadNotificationForItem(id: string) {
    return _.find(this.unreadNotifications, (n) => n.subjectId === id);
  }

  /** Helper method for checking if unread notifications exist */
  @computed
  private get isAnyNotificationUnread(): boolean {
    return _.any(this.unreadNotifications);
  }

  /** Helper method for getting the list of unread notifications */
  @computed
  private get unreadNotifications(): INotification[] {
    return _.filter(this.notifications, (n: INotification) => n.status !== NotificationStatusEnum.READ);
  }

  private isValidNotification(notification: INotification): boolean {
    if (
      notification.status === NotificationStatusEnum.READ ||
      notification.actionType === NotificationActionEnum.DELETE
    ) {
      return false;
    }
    if (notification.actionType === NotificationActionEnum.UPDATE) {
      if (!this.notificationDetailsExist(notification)) {
        return false;
      }
    }
    return true;
  }

  private notificationDetailsExist(notification: INotification): boolean {
    return _.isArray(notification.delta) && _.any(notification.delta);
  }

  @action
  private triggerPoll() {
    this.intervalId = window.setInterval(this.fetchNotifications, 5000);
  }
}
