/* eslint-disable @typescript-eslint/no-unused-vars */
import { AttributesConverter } from "./AttributesConverter";
import { NewUser } from "../factories/NewUser";
import { onlyTruthy } from "../utils/onlyTruthy";

import { ITCCUser } from "../../models/TCCdataModel";
import { IUser } from "../../models/dataModel";

function newUser(data) {
  var attributesFromTCC = AttributesConverter.fromTCC(data.attributes || {});

  var user = NewUser({
    email: data.emailAddress,
    displayName: data.displayName,
    id: data.id,
    organization: data.organization,
    externalId: data.externalId,
    personalInfo: data.personalInfo,
    roles: data.roles,
    suspended: data.isLocked,
    attributes: {
      licenses: onlyTruthy(attributesFromTCC.licenses),
      flags: attributesFromTCC.flags,
    },
  });

  return user;
}

export const NewUserConverter = {
  /**
   * Convert TCC user data to a format that can be used by the app
   * @param {ITCCUser} data - The data to convert
   * @returns {IUser} - The converted data
   */
  fromTCC: function (data) {
    data = data || {};
    return newUser(data);
  },
};
