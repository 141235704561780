 
import * as React from "react";
import { observer } from "mobx-react";
import { useContext, useState, useEffect } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { PackagePageStore } from "../packagePageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { IEntity } from "../../models/dataModel";
import { SanitizationModeEnum } from "../../models/enums";

import { RichTextEditor } from "../../components/RichTextEditor";
import { includesUnallowedContent, sanitize, stripHtml } from "../../utils/functions";
import { TranslatedHtml } from "components/TranslatedHtml";

/**
 * Renders the "Edit details" dialog used on package page.
 */
export const EditDetailsDialog = observer(({ entity, store }: { entity: IEntity; store: PackagePageStore }) => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);
  const translationsStore = entity.isLocal ? undefined : (store as PackagePageStore).getTranslationsStore();

  const [details, setDetails] = useState<string>("");
  const [dontNotify, setDontNotify] = useState<boolean>(false);

  const maxLengthExceeded: boolean = isDetailsMaxLengthExceeded();
  const isFormValid = !maxLengthExceeded && !includesUnallowedContent(details);
  const showEmbedInfoIcon = true;
  const showDontNotify = !entity.isLocal;

  function isDetailsMaxLengthExceeded(): boolean {
    const strippedDetails = stripHtml(details);
    return !!strippedDetails && strippedDetails.length > 5000;
  }

  useEffect(() => {
    if (!!entity) {
      if (!!translationsStore && !!translationsStore.getTranslationForField("details")) {
        setDetails(sanitize(translationsStore.getTranslationForField("details"), SanitizationModeEnum.WEAK));
      } else if (entity.isLocal) {
        setDetails(sanitize(entity.richContent.description || "", SanitizationModeEnum.WEAK));
      } else {
        setDetails(sanitize(entity.richContent || "", SanitizationModeEnum.WEAK));
      }
    }
  }, []);

  function handleInput(input: string) {
    setDetails(input);
  }

  function handleChangeDontNotify() {
    setDontNotify(!dontNotify);
  }

  function cancel() {
    dialog.close();
  }

  async function updateDetails() {
    store.setDetails(details);
    await store.updateDetails(dontNotify);
    dialog.close();
  }

  return (
    <article className="edit-dialog" data-testid="editDetailsDialog">
      <header>
        <h2>{rootStore.getTranslation("shared.catalog_entity_edit.edit_content_wysiwyg")}</h2>
        {showEmbedInfoIcon && (
          <div className="icon-help-details embed-info">
            {rootStore.getTranslation("shared.catalog_entity_edit.edit_content_embed_info")}
            <a
              href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_modify_content_items#GUID-C2A34569-43C2-4512-B9F4-8900FC6B0AFB"
              target="_blank"
              rel="noreferrer"
              style={{ marginLeft: "5px" }}
            >
              <TranslatedHtml entry="shared.catalog_entity_edit.edit_content_embed_link" />
            </a>
          </div>
        )}
      </header>
      <section className="not-scrollable details">
        <RichTextEditor field="details" onChange={handleInput} value={details} targetItem={entity} />
      </section>
      {!isFormValid && (
        <div className="error details-errors">
          {includesUnallowedContent(details) && (
            <small className="unallowed-embeds" data-testid="includesUnallowedEmbeds">
              <TranslatedHtml entry={"shared.catalog_entity_edit.details_unallowed_embed_info"} />
              <ul>
                <li>
                  {"https://alugha.com/embed/web-player/" +
                    rootStore.getTranslation("shared.catalog_entity_edit.your_video_id")}
                </li>
                <li>
                  {"https://player.vimeo.com/video/" +
                    rootStore.getTranslation("shared.catalog_entity_edit.your_video_id")}
                </li>
                <li>
                  {"https://www.youtube.com/embed/" +
                    rootStore.getTranslation("shared.catalog_entity_edit.your_video_id")}
                </li>
                <li>
                  {"https://www.youtube-nocookie.com/embed/" +
                    rootStore.getTranslation("shared.catalog_entity_edit.your_video_id")}
                </li>
              </ul>
            </small>
          )}
          {maxLengthExceeded && (
            <small className="details-too-long" data-testid="detailsTooLong">
              {rootStore.getTranslation("shared.catalog_entity_edit.details_max_length_exceeded")}
            </small>
          )}
        </div>
      )}
      <div className="actions">
        {showDontNotify && (
          <div className="dont-notify">
            <input type="checkbox" id="dontNotify" onChange={handleChangeDontNotify} data-testid="dontNotify" />
            <label htmlFor="dontNotify">{rootStore.getTranslation("shared.catalog_entity_edit.do_not_notify")}</label>
          </div>
        )}
        <button onClick={cancel} data-testid="cancelEdit">
          {rootStore.getTranslation("actions.cancel")}
        </button>
        <button className="button-primary" onClick={updateDetails} disabled={!isFormValid} data-testid="updateDetails">
          {rootStore.getTranslation("actions.save")}
        </button>
      </div>
    </article>
  );
});
