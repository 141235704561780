import { TCCCollectionDS } from "./TCCCollectionDS";
import { TCCPackageDS } from "./TCCPackageDS";
import { TCCEntityDS } from "./TCCEntityDS";

export const TCCAclDS = {
  getCollectionAcl: function (collectionId, data) {
    return TCCCollectionDS.getAcl(collectionId, data);
  },
  setCollectionAcl: function (collectionId, data, performAsId) {
    return TCCCollectionDS.setAcl(collectionId, data, performAsId);
  },
  getPackageAcl: function (packageId, data) {
    return TCCPackageDS.getAcl(packageId, data);
  },
  setPackageAcl: function (packageId, data, performAsId) {
    return TCCPackageDS.setAcl(packageId, data, performAsId);
  },
  getVersionAcl: function (versionId, data) {
    return TCCEntityDS.getAcl(versionId, data);
  },
  setVersionAcl: function (versionId, data, performAsId) {
    return TCCEntityDS.setAcl(versionId, data, performAsId);
  },
};
