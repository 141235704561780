import { action, observable, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../js/services/NewRepository";

import { ICollection, IEditorModeStrategy, IOrganization, IResource, ITranslationObject } from "../models/dataModel";
import { ExternalResourceTypeEnum, DialogTypeEnum, SanitizationModeEnum } from "../models/enums";

import { AbstractAsyncStore } from "../stores/abstractAsyncStore";
import { ContentTranslationsStore } from "../stores/contentTranslationsStore";
import { RootStore, createStoreContext } from "../stores/rootStore";
import { sanitize } from "../utils/functions";

/**
 * Collection page store.
 */
export class CollectionPageStore extends AbstractAsyncStore implements IEditorModeStrategy {
  /**
   * Collection object.
   */
  @observable private collection: ICollection | undefined;
  /**
   * Content translations store.
   */
  private contentTranslationsStore: ContentTranslationsStore;
  /**
   * Collection description
   */
  @observable private description = "";
  /**
   * Organization object.
   */
  @observable private organization: IOrganization | undefined;
  /**
   * collection title.
   */
  @observable private title = "";

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
    this.contentTranslationsStore = new ContentTranslationsStore(rootStore);
  }

  /**
   * @return Returns true if current user can edit collection.
   */
  public canEditCollection(): boolean {
    return this.collection ? this.rootStore.getUserStore().canEditResource(this.collection as IResource) : false;
  }

  /**
   * Fetches collection with given id
   */
  @action
  public async fetchData({ collectionId }: { collectionId: string }) {
    this.dataFetched = false;
    this.loading = true;
    try {
      const collection = (await NewRepository.getCollection(
        {
          id: collectionId,
        },
        this.rootStore.getUserStore().getCurrentUser()?.id || "",
      )) as unknown as ICollection;

      if (collection) {
        runInAction(() => {
          this.collection = this.sanitizeFields(collection);
        });
        try {
          this.collection!.translations = await this.fetchTranslations();
        } catch {
          console.log("Error while fetching translations for collection ", collectionId);
        }
        const organization = await this.fetchOrganization();
        runInAction(() => {
          this.organization = organization;
          if (this.collection!.translations) {
            this.setTranslationFields();
          }
        });
      }
    } catch (err) {
      const msg = this.rootStore.getTranslation("collections.notification.error_loading_collection_contents");
      this.rootStore.getErrorHandlerStore().handleUnauthorized(msg);
    } finally {
      runInAction(() => {
        this.loading = false;
        this.dataFetched = true;
      });
    }
  }

  /**
   * Gets collection object
   * @returns collection object
   */
  public getCollection(): ICollection | undefined {
    return this.collection;
  }

  /**
   * Gets collection description
   * @returns description
   */
  public getDescription(): string {
    return this.description;
  }

  /**
   * Gets organization object
   * @returns organization object
   */
  public getOrganization(): IOrganization | undefined {
    return this.organization;
  }

  /**
   * Gets collection object
   * @returns collection object
   */
  public getResource(): IResource | undefined {
    return this.getCollection() as IResource;
  }

  /**
   * Gets collection title
   * @returns title
   */
  public getTitle(): string {
    return this.title;
  }

  /**
   * Returns the content translations store.
   */
  public getTranslationsStore(): ContentTranslationsStore {
    return this.contentTranslationsStore;
  }

  /**
   * Method for resolving if collection is owned by organization
   * @returns true if collection is owned by organization
   */
  public isCollectionOwnedByOrganization(collection: ICollection): boolean {
    return (
      !!collection &&
      !!collection.creator &&
      collection.creator.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION
    );
  }

  /**
   * Performs the deletion of the collection.
   * If the deletion is successful, a success notification is displayed.
   * The routing state is then changed to "/my/collections".
   * If the deletion fails, an error message is logged to the console.
   */
  public performDelete = async () => {
    try {
      const editor = this.rootStore.getUserStore().getCurrentUser();
      if (!!editor) await NewRepository.deleteCollection(this.getCollection(), editor.id);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("collections.notification.collection_removed"));
      this.rootStore.getRouter().changeRoutingState("/my/collections");
    } catch {
      console.log("Failed to delete collection");
    }
  };

  /**
   * Reloads data the way depending on which dialog was used
   * @params dialogType type of dialog thas triggered reload request
   */
  @action
  public async reloadData(dialogType: DialogTypeEnum) {
    const collection = this.getCollection();
    if (collection) {
      if (dialogType === DialogTypeEnum.TRANSLATIONS) {
        const translations = await this.fetchTranslations();
        if (translations) {
          runInAction(() => {
            collection.translations = translations;
            this.setTranslationFields();
          });
        }
      } else {
        this.fetchData({ collectionId: collection.id! });
      }
    }
  }

  /**
   * Sets collection description
   * @param description The value to be set
   */
  @action
  public setDescription(description: string) {
    this.description = sanitize(description, SanitizationModeEnum.MODERATE);
  }

  /**
   * Sets collection title
   * @param newTitle The value to be set
   */
  @action
  public setTitle = (newTitle: string) => {
    this.title = newTitle;
  };

  /**
   * Resolves if batch edit dialog should be shown
   * @returns true if dialogs should be shown
   */
  public shouldShowBatchEditDialog(): boolean {
    return true;
  }

  /**
   * Resolves if translations dialogs should be shown
   * @returns true if dialogs should be shown
   */
  public shouldShowTranslationDialogs(): boolean {
    return true;
  }

  /**
   * Updates collection description
   * @param dontNotify boolean that tells if subscribers should be notified of the update
   */
  @action
  public async updateDescription(dontNotify: boolean) {
    const lang = this.rootStore.getLocalizationStore().getLangForSelectedLocale();
    const attributes = {};
    const tempDescription = this.description;

    if (this.collection) {
      if (this.contentTranslationsStore.shouldUpdateTranslation("description", this.collection)) {
        attributes[lang] = {};
        attributes[lang].description = tempDescription;
        this.collection.translations![lang].description = tempDescription;
        this.collection.translatedDescription = tempDescription;
        await this.contentTranslationsStore.saveTranslation(attributes, this.collection);
      } else {
        this.collection.description = tempDescription;
        await this.updateCollection(dontNotify);
      }

      runInAction(() => {
        if (this.contentTranslationsStore.hasSavedTranslations()) {
          this.collection!.translatedDescription = this.collection!.translations![lang].description;
        } else {
          this.collection!.translatedDescription = this.collection!.description;
        }
      });
    }
  }

  /**
   * Updates the collection title
   * @param dontNotify boolean that tells if subscribers should be notified of the update
   */
  @action
  public updateTitle = async (dontNotify: boolean) => {
    const lang = this.rootStore.getLocalizationStore().getLangForSelectedLocale();
    const attributes = {};
    const tempTitle = this.title;

    if (this.collection) {
      if (this.contentTranslationsStore.shouldUpdateTranslation("title", this.collection)) {
        attributes[lang] = {};
        attributes[lang].title = tempTitle;
        this.collection.translations![lang].title = tempTitle;
        await this.contentTranslationsStore.saveTranslation(attributes, this.collection);
      } else {
        this.collection.title = tempTitle;
        await this.updateCollection(dontNotify);
      }

      runInAction(() => {
        if (this.contentTranslationsStore.hasSavedTranslations()) {
          this.collection!.translatedTitle = this.collection!.translations![lang].title;
        } else {
          this.collection!.translatedTitle = this.collection!.title;
        }
      });
    }
  };

  private async fetchOrganization(): Promise<IOrganization | undefined> {
    const collection = this.getCollection();
    if (collection && this.isCollectionOwnedByOrganization(collection)) {
      return NewRepository.getOrganization({
        id: collection.creator!.id,
        showBinaryAttributes: true,
      });
    }
  }

  private async fetchTranslations(): Promise<Record<string, ITranslationObject> | undefined> {
    const collection = this.getCollection();
    if (collection) {
      const translations = await this.contentTranslationsStore.getContentTranslations(collection.id!);
      if (translations) {
        return this.sanitizeTranslations(translations);
      }
    }
  }

  private sanitizeFields(collection: ICollection): ICollection {
    collection.description = sanitize(collection.description, SanitizationModeEnum.MODERATE);
    return collection;
  }

  private sanitizeTranslations(translations: Record<string, ITranslationObject>): Record<string, ITranslationObject> {
    _.each(translations, (translationItem: ITranslationObject, language: string) => {
      _.each(translationItem, (translation: string, field: string) => {
        if (field === "description") {
          translations[language][field] = sanitize(translations[language][field], SanitizationModeEnum.MODERATE);
        }
      });
    });

    return translations;
  }

  @action
  private setTranslatedDescription() {
    const collection = this.getCollection();
    if (collection) {
      const translation = this.contentTranslationsStore.getTranslationForField("description");
      if (translation && translation != "") {
        collection.translatedDescription = translation;
        this.description = translation;
      } else {
        const desc = collection.description ? sanitize(collection.description, SanitizationModeEnum.MODERATE) : "";
        collection.translatedDescription = desc;
        this.description = desc;
      }
    }
  }

  @action
  private setTranslatedTitle() {
    const collection = this.getCollection();
    if (collection) {
      const translation = this.contentTranslationsStore.getTranslationForField("title");
      if (translation && translation != "") {
        collection.translatedTitle = translation;
        this.title = translation;
      } else {
        const title = collection.title ? collection.title : "";
        collection.title = title;
        this.title = title;
      }
    }
  }

  private setTranslationFields() {
    const collection = this.getCollection();
    if (collection) {
      this.setTranslatedTitle();
      this.setTranslatedDescription();
    }
  }

  /**
   * Updates collection to NewRepository.
   * @param dontNotify boolean that tells if subscribers should be notified of the update
   */
  private async updateCollection(dontNotify: boolean) {
    try {
      const user = this.rootStore.getUserStore().getCurrentUser();
      const collection = _.extend(this.collection, { doNotNotify: dontNotify });
      if (!!user) await NewRepository.updateCollection(collection, user);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("collections.notification.collection_updated"));
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("collections.notification.error"));
    }
  }
}

export const CollectionPageStoreContext = createStoreContext<CollectionPageStore>(CollectionPageStore);
