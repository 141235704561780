
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const PtBr = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["pt-BR"]) {
      window.locale["pt-BR"] = {};
    }

    _.extend(window.locale["pt-BR"], {
      myDetails: {
        my_roles: "Minhas funções",
        organization_roles: "Funções",
        roles: {
          member: "Editor de conteúdo do Tekla Warehouse (Funcionário)",
          employee: "Funcionário",
          contentEditor: "Editor de conteúdo do Tekla Warehouse (Externa)",
        },
      },
      immutability: {
        add_code_name_for_package: "Adicione um nome de código para o conteúdo",
        code_name: "Nome do código",
        immutable: "Imutável",
        archive: "Arquivar",
        archive_collection: "Arquivar a coleção e todo o seu conteúdo",
        archive_version: "Arquivar a versão selecionada",
        archive_package: "Arquivar o pacote e todo o seu conteúdo",
        archive_dialog_text: "O conteúdo arquivado não ficará visível nem pesquisável em Tekla Warehouse. No entanto, se o conteúdo tiver sido usado anteriormente com o serviço de nuvem Tekla Structures, o serviço de nuvem poderá continuar a acessar o conteúdo.",
        archive_success: function (name: string) {
          return `${name} arquivado com sucesso`;
        },
        archive_failure: function (name: string) {
          return `Falha ao arquivar ${name}`;
        },
        archived_content_redirect_message: "O conteúdo que você está tentando acessar foi arquivado",
        edit_title_move_or_archive: "Editar título, mover ou arquivar",
        edit_title_or_archive: "Editar título ou arquivar",
        major_update: "Atualização principal",
        major_update_description: "Alterações que não são compatíveis com versões anteriores, como adicionar ou remover recursos ou outras alterações significativas.",
        minor_update: "Atualização secundária",
        minor_update_description: "Alterações compatíveis com versões anteriores, como novos recursos ou melhorias sem interromper a funcionalidade existente.",
        patch_update: "Atualização de patch",
        patch_update_description: "Correções de compatibilidade com versões anteriores, como patches de segurança, correções de bugs ou melhorias de desempenho, sem novos recursos.",
        info: "Sua organização fornece conteúdo para o serviço de nuvem Tekla Structures. Um nome de código e um número de versão são necessários para o conteúdo no serviço de nuvem Tekla Structures para garantir compatibilidade e consistência.",
        immutable_files_info: "Para manter a consistência do conteúdo no serviço de nuvem, você não pode remover ou modificar arquivos. Se forem necessárias modificações, arquive a versão atual e carregue uma nova versão.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Para mais informações, consulte <a href="${readMoreUrl}" target="_blank">esta página</a>.`;
        },
        resolve_conflict: {
          title: "Resolver conflito",
          description:
            "Os itens a seguir foram encontrados. Selecione 'Manter existentes' para manter o item existente encontrado em sua instalação.",
          table_header_name: "Nome",
          table_header_type: "Tipo",
          table_header_action: "Ação",
          action_keep_existing: "Manter existente",
          action_replace: "Substituir",
          action_done: "Pronto",
        },
        auth: {
          authentication_process_failed: "Falha no processo de autenticação",
          access_denied: "Acesso negado",
          handle_unauthorized: {
            unauthorized: "Não autorizado",
            please_log_in: "Faça login ou registre-se",
          },
        },
        translations: {
          edit_translations: "Editar traduções",
          edit_translations_info: "Selecione um idioma na lista, edite e salve",
          edit_translations_default_language_info:
            "<b>Padrão</b> é mostrado quando o idioma selecionado pelo usuário não está disponível",
          edit_content: "Informações do conteúdo",
          edit_support: "Suporte",
          updated_successfully: "Traduções atualizadas com êxito",
          error_on_update: "Erro ao atualizar traduções",
          default: "Padrão",
          batch_translations: "Traduções em lote",
          export_import_info:
            "<b>Exportar</b> o modelo de traduções em lote para o conteúdo de sua escolha, editar o modelo adicionando as traduções e <b>importar</b> o modelo de volta para salvar várias traduções de uma só vez.",
          import_translations: "Importar",
          export_translations: "Exportar",
          translations_missing: " (vazio)",
          select_content: "Selecione traduções para exportação:",
          import_finished: "Importação concluída!",
          select_file: "Selecionar arquivo",
          select_file_title: "Selecionar o arquivo a ser importado:",
          import_from_file: "Importar",
          import_error_info: "O arquivo não segue o formato do template:",
          export_selected: "Exportar",
          invalid_file: "Formato do conteúdo do arquivo inválido",
          import_translations_finished: "Traduções importadas",
          details_too_long: "O conteúdo dos detalhes excede o tamanho máximo permitido",
          import_failed: "A importação das traduções falhou",
          translate_to_other_languages: "Traduzir para outros idiomas",
          import_count: function (count) {
            return "Traduções atualizadas para " + count + " conteúdo";
          },
          invalid_language: function (contentId, language) {
            return "Conteúdo '" + contentId + "' contém idioma inválido '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "Conteúdo '" + contentId + "' contém campo de tradução inválido '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "Conteúdo '" +
              contentId +
              "' campo de tradução '" +
              translationField +
              "' o valor excede o comprimento máximo permitido"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "Modelo 3D",
          "3d_pic_added": "Modelo 3D adicionado com êxito",
          "3d_pic_updated": "Modelo 3D atualizado com êxito",
          "3d_pic_removed": "Modelo 3D removido com êxito",
          "3d_pic_update_failed": "Falha ao atualizar modelo 3d",
          "3d_pic_remove_failed": "Falha ao remover modelo 3d",
          "3d_pic_missing":
            "Nenhuma imagem 3D para o conteúdo. Escolha a imagem clicando em 'Escolha o modelo 3D ' abaixo.",
          "3d_pic_uploading": "O seu modelo está sendo carregado. Aguarde...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Clique aqui para editar o conteúdo",
          updated_successfully: "Atualização com êxito",
          update_failed: "Erro na atualização",
          edit_search_criteria: "Editar critérios de pesquisa",
          edit_group_and_category: "Editar grupo e categoria",
          edit_content: "Editar conteúdo",
          edit_thumbnail: "Editar miniatura",
          edit_thumbnail_descriptive: "Dimensões recomendadas: 400 x 300 px",
          edit_description: "Editar descrição",
          edit_download_link: "Adicionar ou editar link de download do parceiro",
          versions_not_shown_to_users:
            "Como esse item de conteúdo tem um link de download do parceiro, os usuários do Tekla Warehouse não podem ver nenhuma versão adicionada anteriormente ao item de conteúdo. Os administradores e usuários do Tekla Warehouse com direitos de edição ainda poderão ver todas as versões do item de conteúdo.",
          partner_download_link: "Insira o URL de download",
          partner_download_link_disclaimer:
            "Se você adicionar um link de download de parceiro a um item de conteúdo existente, os usuários do Tekla Warehouse não poderão mais ver as versões adicionadas anteriormente ao item de conteúdo. Essa ação não poderá ser desfeita.",
          edit_title_or_delete: "Editar título ou excluir",
          edit_title_or_move_delete: "Editar título, mover ou excluir",
          edit_support: "Editar informações de suporte",
          edit_release_notes: "Editar novidades",
          edit_content_wysiwyg: "Editar detalhes do conteúdo",
          edit_content_embed_link: "Mais informações em Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Você só pode inserir vídeos do YouTube, Vimeo ou Alugha.",
          details_unallowed_embed_info: "Você só pode inserir conteúdo dos seguintes domínios: YouTube, Vimeo ou Alugha. Os formatos de URL permitidos são:",
          your_video_id: "SEU_ID_DE_VÍDEO",
          edit_content_video_link_error: "Link de vídeo inválido. Você pode adicionar vídeos por URL do YouTube e Vimeo",
          edit_content_vide_code_error: "Formato de inserção inválido. Somente iframes são aceitos. Os provedores de vídeo permitidos são YouTube, Vimeo e Alugha.",
          details_max_length_exceeded: "O conteúdo de detalhes é muito longo",
          editor_mode_label: "Modo do editor:",
          editor_mode_on: "Ligado",
          editor_mode_off: "Desligado",
          do_not_notify: "Atualização pequena, não notificar assinantes",
          invalid_tag: "Etiqueta inválida",
        },
        transfer_package: {
          header: "Mover conteúdo",
          actions: {
            move: "Mover",
            cancel: "Cancelar",
          },
          loading: "Carregando coleções...",
          moving: "Movendo conteúdo",
          select_collection: "Selecionar coleção",
          content_moved: "Conteúdo movido. As alterações serão visíveis dentro de alguns minutos.",
          operation_failed: "Falha na operação",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Conteúdo vinculado com êxito a " + name;
          },
          selected_resources_linked: function (name) {
            return "Conteúdo selecionado vinculado com êxito a " + name;
          },
          resources_linked_with_errors: "Conteúdo vinculado, ocorreu erro em partes do conteúdo",
          resources_linking_failed: "Falha na vinculação do conteúdo",
          resource_unlinked: "Link removido com êxito. As alterações serão visíveis dentro de alguns minutos.",
          resources_unlinked:
            "Links removidos com êxito do conteúdo selecionado. As alterações serão visíveis dentro de alguns minutos.",
          resources_unlinked_with_errors: "Links removidos, ocorreu erro em partes do conteúdo",
          resources_unlinking_failed: "Falha ao remover link",
          linking: "Criando link",
          linking_failed: "Falha ao criar link",
          linking_failed_title: "O conteúdo a seguir não pôde ser vinculado:",
          unlinking_failed_title: "O conteúdo a seguir não pôde ser desvinculado:",
          linking_failed_already_contains:
            "O conteúdo já foi vinculado à coleção selecionada. As atualizações podem demorar um pouco para serem exibidas.",
          linking_failed_already_contains_short: "O conteúdo já foi vinculado à coleção selecionada",
          unlinking_failed: "Falha ao remover link",
          unlinking_failed_does_not_contain:
            "O link de conteúdo já foi removido da coleção. As atualizações podem demorar um pouco para serem exibidas.",
          unlinking_failed_does_not_contain_short: "O link de conteúdo já foi removido da coleção",
        },
        notification_channel: {
          title_success: "sucesso",
          title_notice: "aviso",
          title_info: "informação",
          title_error: "erro",
        },
        maintenance: {
          download_requires_tekla_maintenance: "A descarga requer a Manutenção da Tekla",
          can_access_maintenance: {
            yes: "Sim",
            no: "Não",
          },
        },
        confirm: {
          title: "Confirmar",
          yes: "Sim",
          no: "Não",
          ok: "OK",
          close: "Fechar",
          dont_show: "Não mostrar novamente",
        },
        show_more: " Mostrar mais",
        show_less: " Mostrar menos",
        spinner_loading: "Carregando",
        spinner_processing: "Processando",
        spinner_loading_long_time: "O carregamento de conteúdo pode demorar vários minutos",
        local_service: {
          info: "O plug-in Tekla Warehouse Service é necessário para a criação de conteúdo local e de rede. Com o Tekla Structures 20.1 SR1 ou posterior, é possível carregar conteúdo diretamente do seu modelo.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service </a> " +
            "é necessário para a criação de conteúdo local e de rede.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service </a> " +
            "não está disponível. O serviço é necessário para permitir coleções locais e de rede.",
          download_to_unlock_all_features_html:
            "Baixe " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service </a> " +
            "para desbloquear todos os recursos.",
          service_out_of_date_html:
            "O seu plug-in Tekla Warehouse está desatualizado. Atualize " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "O plug-in Tekla Warehouse Service </a> " +
            "para desbloquear todos os recursos.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Selecionar idioma",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Convidar outros usuários",
          send_invitation_to_email: "Enviar convite para email",
          add_e_mail_addresses: "Adicionar endereços de email",
          and_assign_role: "e atribuir função",
          send_invitation: "Enviar convite",
          invitation_already_sent: "Convite já enviado!",
          invitation_sent: "Convite enviado!",
          update_selected_invitations: "Atualizar convites selecionados",
          new_role: "Nova função",
          invitations_list_updated: "Lista de convites atualizada!",
        },
        item_browser_title: "Selecionar itens de conteúdo",
        prerequisites: {
          description_placeholder: "Adicionar descrição aqui",
          link_name_placeholder: "Adicionar nome do link aqui",
          add_prerequisites_and_recommendations: "Adicionar pré-requisitos e recomendações (opcional)",
          list_the_profiles_components_files:
            "Liste os perfis, componentes, arquivos ou modelos necessários para que o conteúdo funcione corretamente.",
          link_to_existing_content: "Vincular a conteúdo existente",
          please_enter_prerequisite_url: "Insira a URL do pré-requisito",
          please_enter_valid_prerequisite_url: "Insira uma URL válida de pré-requisito",
          missing_http: "Http:// ausente",
          add_link: "Adicionar link",
        },
        treeview: {
          cannot_export_this_container: "Não é possível exportar este container. Selecione itens individuais.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "O arquivo precisa ser menor que " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Tipo de arquivo inválido.  O arquivo precisa ser de um dos tipos a seguir " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Período:",
          timeframe_start: "Inicio:",
          timeframe_end: "Fim:",
          eventtype: "Tipo de evento:",

          downloads_daily_by_version: "Downloads por versão",
          downloads_by_country: "Downloads por país",
          downloads_by_version: "Downloads por versão",
          downloads_by_version_organization: "Downloads por versão e organização",
          downloads_by_organization: "Downloads por organização",
          downloads_daily_by_package: "Downloads por item de conteúdo",
          views_daily_by_package: "Visualizações por item de conteúdo",
          views_by_country: "Visualizações por país",
          views_by_organization: "Visualizações por organização",

          downloads_by_package: "Downloads por item de conteúdo",
          views_by_package: "Visualizações por item de conteúdo",
          downloads_daily_by_collection: "Downloads por coleção",
          downloads_by_collection: "Descargas por coleção",
          views_daily_by_collection: "Vistas por coleção",
          views_by_collection: "Vistas por coleção",

          events_by_organization_func: function (version) {
            return 'Versão "' + version + '" eventos por organização'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Outros",
          sum_all_versions: "Todas as versões",
          sum_all_packages: "Todos os itens de conteúdo",
          sum_all_collections: "Todas as coleções",
          sum_all_organizations: "Todas as organizações",
          recording_started_at: "Registro de dados iniciado em junho de 2015",
          recording_view_events_started_at: "Registro de dados iniciado em março de 2017",
          version_title_func: function (title) {
            return 'Versão "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " descargas", count + " descarga", count + " descargas"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Os números incluem todas as descargas concluídas",
          counted_events_info_package: "Os números incluem todas as descargas concluídas",
          export: {
            caption: "Exportar relatório",
            select_options: "Selecionar opções",
            select_timeframe_description: "Selecione abaixo um período do qual exportar relatório:",
            select_eventtype_description: "Selecione o tipo de evento do qual exportar relatório:",
          },
        },
        access_rights: {
          editors: "Editores",
          download_access: "Acesso de exibição e download",
          write_user_id_placeholder: "Gravar ID de usuário",
          write_organization_id: "Insira IDs de organização válidos. Insira cada ID em uma linha separada.",
          write_id_placeholder:
            "Insira os IDs de usuário, endereços de e-mail de usuário ou IDs de organização. Insira cada ID ou endereço de e-mail em uma linha separada.",
          id_help_html_short:
            "Por motivos de segurança, nossos usuários não são pesquisáveis.<br/><br/>" +
            "Solicite o ID de usuário diretamente da pessoa à qual você deseja conceder acesso. <br/>" +
            'ID de usuário é mostrado em <a href="#/my/">Meu detalhes</a>.',
          id_help_html:
            "Por motivos de segurança, nossos usuários não são pesquisáveis.<br/><br/>" +
            "Solicite o ID de usuário diretamente da pessoa à qual você deseja conceder acesso. Para conceder acesso a todos os usuários de uma organização, use o ID da organização.<br/><br/>" +
            'Os IDs de usuário e organização são mostrados em <a href="#/my/">Meus detalhes</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Insira cada ID ou endereço de e-mail em uma linha separada. Você pode inserir " +
              max_rows +
              " linhas por vez."
            );
          },
          input_too_long: "O texto inserido é muito longo.",
          viewer_already_added: function (input) {
            return `${input} já foi adicionado como visualizador`;
          },
          wrong_format: function (input) {
            return `${input} não é um ID ou endereço de e-mail válido`;
          },
          candidates_for_keyword: function (keyword) {
            return "Usuários encontrados com o endereço de e-mail ou ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Adicionar novo",
        add: "Adicionar",
        delete: "Excluir",
        set_as_default: "Miniatura padrão",
        select_file: "Selecionar arquivo",
        choose_option: "Escolher opção",
        info_3D_exists: "Você já adicionou um modelo 3D, você não pode adicionar mais de um modelo ao conteúdo.",
        action_options: {
          image: "Imagem",
          "3DModel": "Modelo 3D",
          video: "Vídeo",
        },
        video: {
          url: "URL do vídeo",
          invalid_url: "URL inválida",
        },
      },
      units: {
        bytes: "bytes",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Descarga",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " curtidas", number + " curtida", number + " curtidas"]);
      },
      main_info: {
        header_title: "O site do Tekla Warehouse tem uma nova aparência!",
        header_text: "Qual é a sua opinião em relação ao novo site otimizado do Tekla Warehouse?",
        header_text2: "As principais mudanças são:",
        bullet_text_1: "Pesquisa fácil na página inicial.",
        bullet_text_2:
          "Todos os filtros de pesquisa na página de pesquisa estão à esquerda e a visualização de miniatura padrão tem mais resultados de pesquisa na mesma exibição.",
        bullet_text_3:
          "A opção para descarregar na página de conteúdo está localizada centralmente para facilitar o uso do conteúdo. As opções para descarregar e instalar podem agora ser acessadas pelo botão Descarregar.",
        bullet_text_4:
          "A página Coleções e Meu carrinho têm instruções em três etapas para usar o botão de descarregar e para instalar.",
      },
      organizations: {
        error_loading_collection_contents: "Erro ao carregar conteúdo de coleção",
        error_loading_organization: "Erro ao carregar organização",
      },
      downloads_graph: {
        date: "Data",
        total_views: "Total de vistas",
        total_downloads: "Total de descargas",
        views: "Vistas",
        downloads: "Descargas",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "O plug-in Tekla Warehouse Service" +
          "</a>" +
          " é necessário.",
        processingFailed: "Falha de processamento",
        forbidden: "Proibido",
        resource_not_found: "Recurso não encontrado",
        no_matches_found: "Nenhuma correspondência encontrada",
        no_matches_found_for: function (input) {
          return "Nenhuma correspondência encontrada com o endereço de e-mail ou ID " + input;
        },
        not_identified: "Não identificado",
        error_while_searching: "Erro ao pesquisar",
        error_user_not_found: "Usuário ou organização não encontrado(a)",
        error_while_adding_member: "Erro ao adicionar membro do direito de acesso",
        error_while_removing_member: "Erro ao remover membro do direito de acesso",
      },
      offline_to_online: {
        fail_on_initialize: "Erro ao manipular o conteúdo do zip, anulando conversão",
        fail_on_validate: "A validação de metadados de conteúdo falhou, anulando conversões",
        fail_on_save: "Ocorreu um erro ao salvar o conteúdo, revertendo as alterações",
        success: "Conteúdo off-line convertido com êxito em conteúdo on-line",
        validate_errors_title: "Falha ao converter conteúdo",
        processing: "Salvando pacote ",
        processed: "Item processado",
        collection_thumbnail_missing_name: "Binário de miniatura de coleção está faltando 'filename '",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Pacote '" + packageTitle + "' binário de miniatura está faltando 'nome do arquivo'";
        },
        package_value_missing_title_func: function () {
          return "Pacote está faltando 'título'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Pacote '" + packageTitle + "' está faltando '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "Pacote '" +
            packageTitle +
            "' campo '" +
            field +
            "' possui os seguintes valores inválidos: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Pacote '" + packageTitle + "' possui etiqueta inválida: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "Pacote '" + packageTitle + "' versão está faltando 'título'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Pacote '" + packageTitle + "' versão '" + versionTitle + "' está faltando '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Pacote '" +
            packageTitle +
            "' versão '" +
            versionTitle +
            "' campo '" +
            field +
            "' possui os seguintes valores inválidos: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "Pacote '" + packageTitle + "' versão '" + versionTitle + "' binário está faltando 'nomedearquivo'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Importante: Fim do Suporte para Coleções Locais no Tekla Warehouse",
            part1: "A partir de 2 de junho de 2025, as coleções locais não estarão mais disponíveis no Tekla Warehouse. Os usuários não poderão mais criar, visualizar, pesquisar ou utilizar conteúdo de coleções locais.",
            part2: "Para obter assistência na transição de coleções locais para coleções online, por favor entre em contato com a sua organização de suporte local.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Importante! Como reforço de segurança, você deve ter a função Editor de Conteúdo do Tekla Warehouse para gerenciar o conteúdo da sua organização após ${date} de novembro de 2024. <br />Peça ao administrador da sua organização para atribuir a função a você. Para obter mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Como reforço de segurança, você deve ter a função Editor de Conteúdo do Tekla Warehouse para gerenciar o conteúdo da sua organização após ${date} de novembro de 2024. <br />Peça ao administrador da sua organização para atribuir a função a você. Para obter mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Não vê uma opção de edição para sua organização? Após ${date} de novembro, você deverá ter a função Editor de Conteúdo do Tekla Warehouse para gerenciar o conteúdo da sua organização. <br />Você pode verificar sua função em Meu Warehouse> Meus Detalhes. Para obter mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Não vê uma opção para criar conteúdo para sua organização? Após ${date} de novembro, você deverá ter a função Editor de Conteúdo do Tekla Warehouse para gerenciar o conteúdo da sua organização. <br />Você pode verificar sua função em Meu Warehouse> Meus Detalhes. Para obter mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Após ${date} de novembro, você deverá ter a função Editor de Conteúdo do Tekla Warehouse para gerenciar o conteúdo da sua organização. Peça ao administrador da sua organização para atribuir a função a você. <br />Você pode verificar quem é o administrador da sua organização no seu Tekla Online Perfil. Para obter mais informações, consulte o <a href="${productBulletinUrl}" target="_blank">boletim do produto</a>.`
            },
          },
          service_plugin_update_info:
            "Está tendo problemas para se conectar ao Tekla Structures? Certifique-se de que está usando o plugin mais recente do <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service</a>.",
        },
        maintenance_info:
          "O Tekla Warehouse estará em manutenção durante 2017-10-27 22:00:00 - 23:00:00 UTC. Nos desculpamos pelo inconveniente causado.",
        release_notes_info: "Novos recursos no Tekla Warehouse",
        release_notes_link:
          'O Tekla Warehouse foi atualizado, veja as <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">novidades</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Precisa de um desenvolvedor Tekla para o seu projeto?",
        heading2Dev:
          'Verifique o novo diretório chamado Tekla Development Experts.<br><br>É um lugar onde programadores talentosos e usuários interessados do Tekla podem se reunir.<br><br>Todos os membros do Tekla Partners Program podem adicionar suas informações ao diretório Experts.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Encontrar um desenvolvedor &rsaquo;</a>',
        static_intro:
          "Este é o armazenamento BIM grátis do Tekla Structures, onde você pode localizar, importar, instalar e compartilhar conteúdo internamente e globalmente. O Tekla Warehouse visa facilitar a produção eficiente de modelos de alta qualidade.",
        new_intro:
          'Tekla Corporation agora se chama Trimble. Continuamos desenvolvendo soluções de software da Tekla e o suporte é fornecido pelas mesmas pessoas. Saiba mais: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Verifique sua conta",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Carregamentos mais recentes &rsaquo;",
          popular: "Conteúdo popular &rsaquo;",
          recommended: "Recomendadas &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Novidades &rsaquo;</a>',
          item_title: "Isso é novidade!",
          item_description:
            'Algumas notícias relacionadas ao texto, algumas notícias relacionadas ao texto, algumas notícias relacionadas ao texto, algumas notícias relacionadas ao texto, algumas notícias relacionadas ao texto, algumas notícias relacionadas ao texto.<br><a href="https://developer.tekla.com" target="_blank">Leia mais &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse em números",
          partners: "Parceiros",
          partners_count: "352",
          collections: "Coleções",
          collections_count: "793",
          items: "Itens de conteúdo",
          items_count: "2893",
        },
        partners: "Nossos parceiros",
        action_links: {
          search_title: "Procurar conteúdo?",
          upload_title: "Carregar conteúdo?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Vídeos &rsaquo;</a>',
          link: 'Visite o nosso <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">canal do Youtube</a> para mais vídeos.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Verifique seu email para o link de verificação ou <u><a href="' +
            settingsUrl +
            '">solicite um novo link</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Verifique seu endereço de e-mail e aceite os termos e condições e a política de privacidade",
        acceptTerms: "Aceite os termos e condições e a política de privacidade",
        acceptUpdatedTerms:
          "Aprove os termos e condições e política de privacidade dos tekla online services atualizados",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Volte ao seu <u><a href="' + settingsUrl + '">perfil</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Revise e aceite os Termos e condições e a Política de privacidade no seu <u><a href="' +
            settingsUrl +
            '">perfil</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Procurar coleções &rsaquo;",
          new: "Novo",
          popular: "Popular",
          recommended: "Recomendadas",
          organizations: "Organizações",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Selecionar pasta",
        selectFolderInstructions: "Pastas locais e de rede podem ser usadas como Coleções.",
        selectedFolder: "Pasta selecionada (local de recorte-colagem):",
        selectedFolderPlaceholder: "Por exemplo: C:\\pasta, Z:\\pasta de rede, \\\\nome-do-computador\\pasta",
        collectionCreation: {
          name: "Nome",
          createNewCollection: "Criar coleção local",
          pleaseInputTitle: "Insira um título",
          invalidPath: "Caminho inválido",
          access_denied: "Falha ao adicionar coleção: acesso negado",
          unsafe_location: "Falha ao adicionar coleção: local inseguro",
          add_collection_failed: "Falha ao adicionar coleção",
          minLengthError: "O título precisa conter pelo menos 3 caracteres",
          maxLengthError: "O título não pode ter mais de 20 caracteres",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Pastas locais e de rede podem ser usadas como Coleções.",
        collectionCreation: {
          name: "Nome",
          createNewCollection: "Criar coleção online",
          pleaseInputTitle: "Insira um título",
          minLengthError: "O título precisa conter pelo menos 3 caracteres",
          maxLengthError: "O título não pode ter mais de 20 caracteres",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Recurso salvo",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Você consegue ver o potencial?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Envie-nos seu feedback</span>',
          help_us_improve_html:
            'Nos ajude a aprimorar o Tekla Warehouse! <br/><a id="showSurvey">Clique aqui</a> ou <a id="dontShow">não mostre isso novamente</a>',
          dialog: {
            do_you_see_potential: "Você consegue ver o potencial?",
            help_us_improve_html: "Nos ajude a aprimorar o Tekla Warehouse! Sua opinião é importante para nós.",
            continue_to_survey: "Iniciar pesquisa",
            exit_to_warehouse: "Não, obrigado",
          },
        },
      },
      search: {
        bar: {
          search: "Procurar",
          filter: " ",
          more_filters: "Mais filtros",
          hide_filters: "Ocultar filtros",
          clear: "Apagar",
          exclude: "Excluir",
          include: "Incluir",
          clear_filters: "Redefinir filtros",
          load_defaults: "Usar minhas preferências &rsaquo;",
          save_defaults: "Salvar como minhas preferências &rsaquo;",
          defaults_saved_successfully: "Filtros padrão salvos",
          edit_defaults: "Editar padrões",
        },
        info: {
          searching_organizations: "Procurando em organizações...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Não há resultados", amountTotal + " resultados"]);
            } else {
              return (
                pluralize(amountTotal, ["Não há resultados", amountTotal + " resultados"]) +
                ' para "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " resultados";
            } else if (!searchTerm) {
              return "Insira um termo de pesquisa para ver os resultados de organização";
            } else if (searchTerm.length < 2) {
              return "Forneça pelo menos dois caracteres para a pesquisa de organização";
            } else {
              return (
                pluralize(amountTotal, ["Não há resultados", amountTotal + " resultados"]) +
                ' para "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " resultados no conteúdo online",
          local: " resultados no conteúdo local",
          organization: "resultados em organizações",
          plugin_missing_info:
            'O conteúdo local somente será exibido se você tiver o <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "O plug-in Tekla Warehouse Service" +
            "</a>" +
            " instalado.",
          version_filter_info: "A filtragem de versão de software não é válida nos resultados de conteúdo local",
        },
        exclude_env_files: "Excluir arquivos de ambiente",
        sidebar: {
          filters: {
            measurementUnits: "Unidades de medida",
            locations: "Locais de projeto",
            languages: "Idiomas",
          },
          placeholders: {
            use_categories: "Categoria",
            measurement_units: "Unidades de medida",
            languages: "Idiomas",
            locations: "Locais",
          },
        },
      },
      placeholders: {
        name: "Nome...",
        contentOwner: "Nome do proprietário do conteúdo...",
        useCategory: "Selecionar uso",
        selectType: "Selecionar tipo",
        filter: "Filtrar...",
        search: "Pesquisar...",
        choose: "Escolher...",
        searchFilters: "Filtros de pesquisa...",
        selectContentItem: "Selecionar item de conteúdo...",
        search_content: "Pesquisar conteúdo...",
        includeAs: "Incluir como...",
        selectCollection: "Selecionar coleção",
        selectUserGroup: "Selecionar grupo de usuários",
        online_collections_tooltip: "Clique para pesquisar conteúdo online",
        local_collections_tooltip:
          "Clique para pesquisar conteúdo local e de rede e conteúdo incluído com a instalação do Tekla Structures",
        organizations_tooltip: "Clique para pesquisar organizações",
        "filter-options": {
          products: "Todos os produtos de software",
          locations: "Todos os locais",
          languages: "Todos os idiomas",
          measurementUnits: "Todas as unidades de medida",
          testedVersions: "Todas as versões do software",
          useCategories: "Todas as categorias",
          useCategoriesCombined: "Categoria",
          allGroups: "Todos os grupos",
          itemTypeCategories: "Todos os tipos de item",
        },
        "exclude-filter-options": {
          products: "Excluir produtos de software",
          locations: "Excluir locais",
          languages: "Excluir idiomas",
          measurementUnits: "Excluir unidades de medida",
          testedVersions: "Excluir versões de software",
          useCategories: "Excluir categorias",
          useCategoriesCombined: "Excluir categoria",
          allGroups: "Excluir grupos",
          itemTypeCategories: "Excluir tipos de item",
        },
      },
      link: {
        link_selected: "Link selecionado",
        unlink_selected: "Desvincular selecionado",
      },
      download_install: {
        choose_version: "Escolha a sua versão do software:",
        choose_content: "Escolha itens de conteúdo na lista abaixo:",
        choose_action: "Escolha a ação para os itens selecionados:",
      },
      download: {
        copy_to_clipboard: "Copiar para a área de transferência",
        download_selected: "Descarga",
        add_selected_items_to_basket: "Adicionar selecionado ao Meu carrinho",
        add_item_to_basket: "Adicionar selecionado ao Meu carrinho",
        add_all_items_to_basket: "Adicionar tudo ao carrinho",
        add_all_items_to_basket_info: "Há mais de 100 itens. O carregamento poderá demorar",
        add_all_items_to_basket_too_many_results: "Não é possível adicionar mais de 1.000 itens",
        cart_loading: "Há mais de 100 itens. O carregamento poderá demorar",
        remove_item_from_basket: "Remover do carrinho",
        shopping_cart_no_content: "Nenhum conteúdo no carrinho",
        shopping_cart_no_content_for_version: "Nenhum conteúdo no carrinho para a versão selecionada",
        shopping_cart_items_func: function (itemsCount) {
          return "Meu carrinho (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Instruções de descarga",
          step_one: "Copie as informações de descarga para a área de transferência com este botão",
          step_two_func: function (downloadAppUrl) {
            return (
              "Executar o aplicativo de downloads do Tekla Warehouse. Você pode baixar o aplicativo " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">aqui.</a> '
            );
          },
          step_three: "O aplicativo de descarga do Tekla Warehouse guiará você ao longo da descarga.",
          show_data_title: "Não funcionou?",
          show_data_to_be_copied: "Copiar manualmente",
          hide_data_to_be_copied: "Ocultar dados de descarga",
        },
      },
      install: {
        install_title: " itens serão instalados usando o plug-in do Tekla Warehouse.",
        failed_install_information:
          "Você selecionou conteúdo que está disponível somente para descarga. Este conteúdo não será instalado agora. Descarregue este conteúdo separadamente do Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Instalar: verifique se o plug-in do Tekla Warehouse Service está sendo executado corretamente",
        verify_ts_connection: "Instalar: verifique se o Tekla Structures está sendo executado",
        select_software_version: "Filtrar por versão do software para vincular, baixar ou instalar",
        download_requires_maintenance: "O acesso a alguns itens abaixo requer a Manutenção Tekla",
        install_selected: "Instalar",
        start_install: "Iniciar instalação",
        install_started: "Instalação iniciada",
        select_ts_version: "Selecionar versão do TS",
        install_retry: "Reinicie pelo Tekla Warehouse se for preciso tentar novamente",
        failed_installs_info_func: function (failedCount) {
          return "Conteúdo que não será instalado (" + failedCount + " versões):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Instalação para itens " + totalInstallsCount + " selecionados";
        },
      },
      helpers: {
        behalf: "Por",
        sortBy: "Classificar por:",
        filter: "Filtrar resultados",
        showAsThumbs: "Mostrar como miniaturas",
        showAsList: "Mostrar como lista",
        resultsPerPage: "Mostrar por página:",
        filter_by_version: "Filtrar por versão:",
        show_more_options: "Mais opções",
        show_less_options: "Menos opções",
        filter_by_type: "Filtrar por tipo:",
        select_all: "Selecionar todas",
        show_content_items: "Mostrar itens de conteúdo",
        choose_action: "Escolha a ação para o conteúdo selecionado: ",
        show_collections: "Mostrar coleções",
        search: {
          show: "Mostrar:",
          show_content_items: "Itens de conteúdo",
          show_collections: "Coleções",
          show_organizations: "Organizações",
          show_online_content: "Conteúdo online",
          show_offline_content: "Conteúdo offline",
          local_content_info:
            'O conteúdo local somente será exibido se você tiver o <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "plug-in do Tekla Warehouse Service" +
            "</a></b>" +
            " plugin instalado.",
        },
        tekla_maintenance_required: "O acesso a alguns itens abaixo requer a Manutenção Tekla",
        show_content_alerts: "Mostrar alertas de conteúdo ",
        show_collection_alerts: "Mostrar alertas de coleção ",
      },
      actions: {
        load_more: "Carregar mais",
        use_infitinite_scroll: "Usar rolagem infinita",
        back: "Voltar",
        show_download_dialog: "Descarga",
        download: "Apenas baixar",
        download_on_partner_site: "Faça download no site parceiro",
        insert: "Instalar",
        install: "Instalar",
        downloadAndInstall: "Instalar",
        createNew: "Criar novo",
        viewItems: "Vista",
        viewCollection: "Ver coleção",
        viewGroup: "Mostrar grupo",
        joinGroup: "Ingressar no grupo",
        loginToDownload: "Fazer login para baixar",
        show3DPic: "Mostrar modelo 3D",
        remove: "Remover",
        browse: "Procurar",
        browseFolder: "Procurar",
        browseFileSystem: "Procurar",
        add: "Adicionar",
        save: "Salvar",
        batch_edit: "Edição em lote",
        translations: "Traduções",
        cancel: "Cancelar",
        continue: "Continuar",
        delete: "Excluir",
        addNetworkPath: "Adicionar local de rede",
        select: "Selecionar",
        create: "Criar",
        update: "Atualizar",
        choose_thumbnail: "Escolher miniatura",
        choose_picture: "Escolher imagem",
        remove_picture: "Excluir imagem",
        choose_3D_picture: "Escolher modelo 3D",
        remove_3D_picture: "Remover modelo 3D",
        like: "Curtir",
        unlike: "Descurtir",
        close: "Fechar",
        export: "Exportar",
        add_or_remove: "Adicionar/Remover",
        select_content_to_convert: "Selecionar conteúdo off-line",
        search: "Procurar",
        upload: "Carregar",
        edit: "Editar",
        view_3d: "Clique para exibir o modelo...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "beta",
        search: "Procurar",
        profile: "Meus detalhes",
        myWarehouse: "Meu Warehouse",
        myStuff: "Minhas coisas",
        logout: "Fazer logoff",
        login: "Entrar",
        register: "Registrar",
        upload: "Carregar",
        uploading: "Carregar",
        dashboard: "Painel",
        learnMore: "Saiba mais",
        about: "Sobre",
        shoppingCart: "Meu carrinho",
        addToCartInfo:
          "Você pode coletar itens no Meu Carrinho e baixá-los ou instalá-los imediatamente. O download é totalmente grátis",
      },
      cubeNavigation: {
        headings: {
          get_started: "INTRODUÇÃO",
          learn: "APRENDER",
          get_help: "OBTER AJUDA",
          extend_your_tekla: "AMPLIE SEU TEKLA",
          collaborate: "COLABORAR",
          students_and_educators: "Students and educators",
          about: "SOBRE",
        },
        "tekla-online": "Serviços online",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Site Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Extranet Tekla",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Meu perfil",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "PÁGINA INICIAL DE SERVIÇOS DA TEKLA",
        register: "Registrar",
        login: "Fazer login",
        evaluate: "Avaliar",
        buy: "Comprar",
        download: "Baixar",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Produtos",
        teklaServices: "Serviços",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Minhas coleções",
        myLikes: "Minhas curtidas",
        profile: "Meus detalhes",
        preferences: "Minhas preferências",
        myUserGroups: "Meus grupos de usuários",
        localCollections: "Coleções locais e de rede",
        tsCollections: "Coleções do Tekla Structures",
        downloads: "Descargas",
        selectContent: "Selecionar arquivos de conteúdo",
        addMetadata: "Adicionar metadados",
        onlineCollections: "Coleções online",
        userGroups: "Grupos de usuários",
        createNew: "Adicionar conteúdo",
        notifications: "Notificações",
        contentUsage: "Uso do conteúdo",
        browseCollections: "Procurar coleções",
        admin: "Admin",
        sales: "Vendas",
        shoppingCart: "Meu carrinho",
      },
      notifications: {
        title: "notificação",
        invitedBy: "Convidado por",
        invitationType: "Tipo de convite",
        invitedTo: "Convidado para",
        createdAt: "Criado em",
        action: "Ação",
        acceptInvitation: "Aceitar",
      },
      batch_edit: {
        selectActionType: "Selecionar tipo de ação",
        edit_content: "Editar metadados de conteúdo",
        content_title: "Conteúdo",
        metadataToChange: "Metadados a serem alterados",
        selectContent: "Para conteúdo",
        select_action: "Selecionar ação",
        support: {
          support: "Suporte",
          licenses: "Licenças",
        },
        versions: {
          version: "Versão",
          prerequisities: "Pré-requisitos e recomendações",
        },
        content: {
          select_collection: "Selecionar coleção",
          show_collection: "Mostrar:",
          show_all_content: "Todas as coleções",
          show_organization_content: "Coleções da minha organização",
          select_all: "Selecionar tudo",
          select_all_info: "(Isso pode demorar um pouco)",
          actionOptions: {
            add: "Adicionar",
            remove: "Remover",
            replace: "Substituir por",
            yes: "Sim",
            no: "não",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Nenhum atributo selecionado",
            invalid_attributes: "Valores de atributo inválidos",
            invalid_actions: "Ações inválidas",
            content_not_selected: "Nenhum conteúdo selecionado",
            entities_not_selected: "Foram fornecidos dados relacionados ao pacote, mas nenhum pacote está selecionado",
            versions_not_selected: "Foram fornecidos dados relacionados à versão, mas nenhuma versão está selecionada",
          },
        },
        update: {
          info_processing: "Mantenha esta janela aberta até todos os itens serem processados.",
          info_processed: "Todos os itens foram processados.",
          processing: "Processando item",
          error_info: "Ocorreu um erro ao atualizar os seguintes itens:",
          package_failure_func: function (package_title, errorMsg) {
            return "Conteúdo: " + package_title + ", erro: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Conteúdo: " + package_title + ", versão: " + version_title + ", erro: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " os itens serão atualizados, deseja continuar?";
          },
        },
      },
      myUserGroups: {
        title: "Grupos de usuários",
        createdByPrefix: "Por:",
      },
      downloads: {
        title: "Descargas",
      },
      myCollections: {
        new_collection: "Nova coleção",
        my_collections: "Minhas coleções",
        created_by_me: "Criadas por mim",
        local_collections: "Coleções locais e de rede",
        online_collections: "Coleções online",
        created_by_my_organization: "Criadas pela minha organização",
        created_by_my_organizations: "Criado por minhas organizações",
        shared_with_me: "Compartilhadas comigo",
        shared_with_me_or_organization: "Compartilhadas comigo ou com minha organização",
        notification: {
          error: "Erro",
          error_loading_online_collections: "Erro ao carregar coleções online",
          error_loading_packages_liked_by_me: "Erro ao carregar conteúdo curtido por mim",
          error_loading_my_organization_s_collections: "Erro ao carregar as coleções da minha organização",
          error_loading_my_shared_collections: "Erro ao carregar minhas coleções compartilhadas",
        },
        local: {
          helpText1: "Para acessar as coleções locais, instale o ",
          helpText2: "plug-in Tekla Warehouse Service",
          helpText3: ".",
          title: "Coleções locais e de rede",
          notFound: "Nenhuma coleção local encontrada ainda.",
          myCollectionsTitle: "Minhas coleções",
          actions: {
            createNew: "Criar coleção local ou de rede",
          },
        },
      },
      organization: {
        news_feed: "Feed de notícias",
        add_comment: "Adicionar texto de feed",
        edit_comment: "Editar item de notícias",
        subTabs: {
          collections: "Coleções",
          analytics: "Relatórios",
          contact_and_support: "Contato",
          news_feed: "Feed de notícias",
        },
        edit: {
          edit_title: "Editar título",
          edit_description: "Editar descrição",
          edit_contact: "Editar informações de contato",
          edit_logo: "Editar logotipo",
          edit_url: "Editar URL",
          edit_email: "Editar email",
          edit_phonenumber: "Editar número de telefone",
          edit_warehouse_url: "Editar URL do Warehouse",
          edit_warehouse_url_subtitle:
            "Caracteres alfabéticos, sem caracteres escandinavos, números ou caracteres especiais",
          edit_url_subtitle: "Inserir URL completa",
          edit_logo_descriptive: "O tamanho ideal da imagem é de aproximadamente 350 x 200 pixels",
          edit_facebook_url: "Editar URL do Facebook",
          edit_twitter_url: "Editar URL do Twitter",
          edit_linkedin_url: "Editar URL do LinkedIn",
          edit_youtube_url: "Editar URL do YouTube",
          edit_googleplus_url: "Editar URL do Google+",
          logo_updated: "Logotipo atualizado",
          logo_update_failed: "Falha na atualização do logotipo",
          logo_remove_failed: "Falha na remoção do logotipo",
          logo_removed: "Logotipo removido com sucesso",
          updated_successfully: "Atualização bem-sucedida",
          updated_failed: "Falha na atualização",
          add_comment: "Adicionar novo",
          edit_comment: "Editar item de notícias",
          comment_added: "Item de feed de notícias adicionado com sucesso",
          comment_updated: "Item de feed de notícias atualizado com sucesso",
          comment_deleted: "Item de feed de notícias excluído com sucesso",
          comment_add_failed: "Falha ao adicionar feed de notícias",
          comment_update_failed: "Falha ao atualizar item de feed de notícias",
          comment_delete_failed: "Falha ao excluir item de feed de notícias",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Procurar conteúdo online por " + name + " &rsaquo;";
        },
        contact_information: "Contate-nos",
        website: "Site &rsaquo;",
        show_newer_news: "Mais recente",
        show_older_news: "Mais antigo",
      },
      online_collections: {
        new_collection: "Nova coleção",
        online_collections: "Coleções online",
        created_by_organizations: "Criadas por organizações",
        created_by_users: "Criadas por usuários",
      },
      local_collections: {
        tab_title: "Conteúdo",
        local_collections: "Coleções locais",
      },
      versions: {
        add_tool: "Adicionar aplicativo",
        table_header: {
          name: "Nome",
          type: "Tipo",
          platform: "Plataforma",
          modified_at: "Modificado em",
          size: "Tamanho",
          virus_scan: "Varredura antivírus",
        },
        // placeholders
        select_file_type: "Selecionar tipo de arquivo",
        select_platform: "Selecionar plataforma",
        select_unit: "Selecionar unidade",
        select_quality: "Selecionar qualidade",
        what_will_be_added: "O que você deseja adicionar?",
        select_files_from_your_disk: "Selecionar arquivos do seu disco",
        add: "Adicionar",
        //content management
        add_application: "Adicionar aplicativo",
        select_content_from_tekla_structures: "Selecionar conteúdo do Tekla Structures",
        or: "ou",
        files: "Arquivos",
        //version metadata
        version_information: "Informações da versão",
        version_display_name: "Nome de exibição da versão",
        version_number: "Número da versão",
        version_number_info: "Insira um número de versão. Não é possível alterar o número da versão depois que ela for salva.",
        existing_versions: "Números de versão existentes para este conteúdo",
        version_number_validation: {
          missing: "Você deve inserir um número de versão",
          invalid: "O número da versão deve seguir as regras de versionamento semântico (MAJOR.MINOR.PATCH)",
          already_in_use: "Este número de versão já está em uso para este conteúdo.",
        },
        select_update_type: "Para gerar um número de versão, primeiro selecione o tipo de atualização",
        please_enter_a_version_name_or_number: "Insira um nome ou um número da versão.",
        virus_scan: {
          soft_failed: "Falha",
          hard_failed: "Não verificado",
          infected: "Infectado",
        },
      },
      collections: {
        by: "Por:",
        edit: {
          edit_collection: "Editar coleção",
          edit_description: "Editar descrição",
        },
        notification: {
          collection_updated: "Coleção atualizada",
          error: "Erro",
          error_loading_other_users_collections: "Erro ao carregar coleções de outros usuários",
          error_loading_other_organizations_collections: "Erro ao carregar coleções de outras organizações",
          error_loading_collection_contents: "Erro ao carregar conteúdo de coleção",
          members_list_updated: "Lista de membros atualizada!",
          invitations_removed: "Convites removidos!",
          collection_removed: "Coleção removida",
          thumbnail_deleted: "Miniatura excluída",
          wrong_file_type: "Tipo errado de arquivo.",
          invalid_file_type: "Tipo de arquivo inválido.",
          selected_image_is_too_big: "A imagem selecionada é muito grande.",
          thumbnail_updated: "Miniatura atualizada",
          default_thumbnail_updated: "Miniatura padrão atualizada",
          default_thumbnail_update_failed: "Falha na atualização de miniatura padrão",
          thumbnail_added: "Miniatura adicionada",
          thumbnail_update_failed: "Falha na atualização da miniatura",
          video_added: "Vídeo adicionado",
          video_adding_failed: "Falha ao adicionar vídeo",
          video_deleted: "Vídeo excluído",
          video_deleting_failed: "Falha ao excluir vídeo",
        },
        confirm: {
          delete_all: function (title) {
            return 'Excluir coleção e todo o conteúdo da coleção "' + title + '"?';
          },
          remove_members: "Tem certeza?",
          remove_invitations: "Tem certeza?",
        },
        useNetworkDrive: "Usar unidade ou pasta de rede",
        createCollection: "Criar coleção online",
        createCollectionInstructions: "Crie uma coleção para agrupar seu conteúdo.",

        actions: {
          delete_thumbnail: "Excluir miniatura",
          edit_collection: "Editar coleção",
          delete_collection: "Excluir coleção",
          add_content: "Adicionar conteúdo",
        },
        subTabs: {
          content: "Conteúdo",
          members: "Mostrar membros",
          analytics: "Relatórios",
          manage_access: "Gerenciar acesso",
        },

        members: {
          add_user_groups: "Adicionar grupos de usuários",
          select_user_group: "Selecionar grupo de usuários",
          with_role: "com função",
          user_groups_added: "Grupos de usuários adicionados!",
          members_list_updated: "Lista de membros atualizada!",
          update_selected_members: "Atualizar membros selecionados",
          table_header: {
            member_type: "Tipo de membro",
            member_name: "Nome do membro",
            email: "Email",
            role: "Função",
            joined_at: "Ingressou em",
            invited_at: "Convidado em",
          },
          new_role: "Nova função",
        },
        member_list: {
          users_who_can_access_this_collection: "Usuários que podem acessar esta coleção",
          add_user_group: "Adicionar grupo de usuários",
          update_members: "Atualizar membros",
          remove_members: "Remover membros",
          pending_invitations: "Convites pendentes",
          user: "usuário",
          invite_member: "Convidar membro",
          update_invitations: "Atualizar convites",
          remove_invitations: "Remover convites",
        },
        labels: {
          select_organization: "Selecionar organização",
          select_visibility: "Selecionar visibilidade",
          title: "Nome",
          name: "Nome",
          createAs: "Criar como: ",
          asMyself: "Eu mesmo",
          asUserGroup: "Grupo de usuários",
          asOrganization: "Organização",
          selectOwner: "Selecionar organização do proprietário do conteúdo",
          visibility: "Visibilidade",
          visibilityOptions: {
            public: "Pública",
            private: "Privada",
          },
          description: "Descrição",
        },
        new_collection: {
          please_input_a_username: "Insira um nome de usuário",
          title_is_required_to_be_at_least_3_characters: "O título precisa conter pelo menos 3 caracteres",
          title_cannot_be_longer_than_20_characters: "O título não pode ter mais de 20 caracteres",
        },
        collection_list: {
          no_content: "Nenhuma coleção.",
          results_summary: function (number) {
            return "" + number + "  coleção " + pluralize(number, ["itens", "item", "itens"]);
          },
        },
        entity_list: {
          no_content: "Nenhum conteúdo na coleção.",
          results_summary: function (number) {
            return "" + number + " conteúdo " + pluralize(number, ["itens", "item", "itens"]);
          },
          linked_content: "Conteúdo vinculado",
          unlink: "Desvincular conteúdo",
        },
      },
      alerts: {
        no_updates: "Não atualizado recentemente",
        notifications_marked_as_read: "Notificação marcada como lida",
        cleared_alerts: "Todas as notificações limpas",
        alert_by_email:
          "Enviar-me email quando qualquer conteúdo abaixo for atualizado com versão nova ou quando uma coleção for atualizada com conteúdo novo",
        action_types: {
          create: "foi criado",
          update: "foi editado",
          delete: "foi excluído",
          added_entity: "versão foi adicionada",
          removed_entity: "versão foi removida",
          added_collection: "conteúdo foi adicionado",
          removed_collection: "conteúdo foi removido",
        },
        property_names: {
          description: "Detalhes",
          searchCriteria: "Informações de critérios de pesquisa",
          groupCategory: "Informações de grupo e categoria",
          support: "Informações de suporte",
          licenses: "Informações de licenças",
          relatedContent: "Informações de conteúdo relacionado",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Atualizado " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Preferências de notificação atualizadas com sucesso",
          preferences_update_failed: "Ocorreu um erro ao atualizar preferências de notificação",
        },
      },
      browseDialog: {
        useNetworkDrive: "Usar unidade ou pasta de rede",
        ownerGroups: {
          title: "Grupos de proprietários",
        },
      },
      itemTypeCategories: {
        all: "Todos os grupos",
        application: "Aplicativos",
        "3dProduct": "Produtos 3D",
        customComponent: "Componentes personalizados",
        tool: "Aplicativos",
        profile: "Perfis",
        material: "Materiais",
        bolt: "Parafusos",
        rebar: "Armaduras",
        reportTemplate: "Templates de lista",
        drawingSetup: "Arquivos de configuração de desenho",
        modelSetup: "Arquivos de configuração de modelo",
        environmentFile: "Arquivos de ambiente",
        shape: "Formatos",
        //'shapeCatalog': 'Catálogo de blocos externos',
        //'foreignGeometryDefinition': 'Definição de geometria externa'
      },
      platforms: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: "Independente da plataforma",
      },
      platformsDownload: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: " ",
      },
      contentItemSource: {
        other: "Geral - para pasta de modelos",
        run: "Executar ferramenta",
        bolt: "Conjunto de parafuso",
        component: "Componente",
        drawing: "Configuração de desenho (BETA)",
        material: "Material",
        profile: "Perfil",
        mesh: "Tela soldada",
        rebar: "Vergalhão",
        shape: "Forma",
        geometry: "Geometria",
        modeltemplate: "Modelo template",
        cloningtemplate: "Modelo de clonagem",
        noaction: "Nenhuma operação",
        releasenotes: "Novidades",
      },
      contentItemHeader: {
        bolt: "Conjuntos de parafusos",
        component: "Componentes",
        drawing: "Configurações do desenho (BETA)",
        material: "Materiais",
        profile: "Perfis",
        mesh: "Malhas de armadura",
        rebar: "Barras de armadura",
        shape: "Formatos",
      },
      useCategoryGroups: {
        workflow: "Fluxo de trabalho",
        structures: "Estruturas",
        drawings: "Desenhos",
        modeling: "Modelagem",
      },
      useCategories: {
        title: "Usado em/para",
        conceptualDesign: "Projeto conceitual",
        designEngineeringAnalysis: "Projeto / Engenharia / Análise",
        detailing: "Detalhamento",
        fabrication: "Fabricação",
        erectionInstallationPour: "Construção / Instalação / Concretagem",
        contracting: "Contratação",
        scheduling: "Programação",
        takeOffEstimation: "Quantitativo / Estimativa",
        concreteStructures: "Concreto moldado in loco",
        precastConcrete: "Concreto pré-moldado",
        offshore: "Balanço",
        steelStructures: "Estruturas de aço",
        timberStructures: "Estruturas de madeira",
        reinforcement: "Armadura",
        drawingCreation: "Criação de desenho",
        drawingManagement: "Gerenciamento de desenho",
        drawingEditing: "Edição de desenho",
        drawingAnnotation: "Anotação de desenho",
        viewSimulateNavigate: "Ver, simular, navegar",
        compareReviewManageData: "Comparar, revisar, gerenciar dados",
        softwareCollaboration: "Colaboração de software",
        productivity: "Produtividade",
        interoperability: "Interoperabilidade",
        systemSetup: "Configuração do sistema",
      },
      measurementUnits: {
        "0": "Métrico",
        "1": "Imperial",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Open BIM (IFC)",
        standalone: "Autônomo",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Sem especificação de versão",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "Experimental",
        candidate: "Candidato",
        approved: "Aprovado",
        standard: "Padrão",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Inglês",
        "zh-Hans": "Chinês simplificado",
        "zh-Hant": "Chinês tradicional",
        cs: "Tcheco",
        da: "Dinamarquês",
        nl: "Holandês",
        fi: "Finlandês",
        fr: "Francês",
        de: "Alemão",
        hu: "Húngaro",
        it: "Italiano",
        ja: "Japonês",
        ko: "Coreano",
        pl: "Polonês",
        pt: "Português",
        "pt-br": "Português (Brasil)",
        ru: "Russo",
        es: "Espanhol",
        sv: "Sueco",
        no: "Norueguês",
        other: "Outro",
      },
      isoLangs: {
        ab: {
          name: "Abkhaz",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Africâner",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Albanês",
          nativeName: "Shqip",
        },
        am: {
          name: "Aramaico",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Árabe",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonês",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Armênio",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Assamês",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avárico",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avéstico",
          nativeName: "avesta",
        },
        ay: {
          name: "Aymara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azeri",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Bashkir",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Basco",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Bielorrusso",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengali",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bósnio",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Bretão",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Búlgaro",
          nativeName: "български език",
        },
        my: {
          name: "Birmanês",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Catalão; Valenciano",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Checheno",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa; Chewa; Nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chinês, simplificado e chinês",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Chinês simplificado",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Chinês tradicional",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Chinês (Taiwan)",
          nativeName: "國語",
        },
        cv: {
          name: "Chuvache",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Córnico",
          nativeName: "Kernewek",
        },
        co: {
          name: "Corso",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Croata",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Tcheco",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Dinamarquês",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi; Dhivehi; Maldiviano;",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Holandês",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Inglês",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Estoniano",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Faroês",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fijiano",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finlandês",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Francês",
          nativeName: "Français",
        },
        ff: {
          name: "Fula; Fulah; Pulaar; Pular",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Galego",
          nativeName: "Galego",
        },
        ka: {
          name: "Georgiano",
          nativeName: "ქართული",
        },
        de: {
          name: "Alemão",
          nativeName: "Deutsch",
        },
        el: {
          name: "Grego, moderno",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guarani",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gujarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haitiano; crioulo haitiano",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Hebraico (moderno)",
          nativeName: "עברית",
        },
        hz: {
          name: "Herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Húngaro",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlíngua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonésio",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlíngue",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Irlandês",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Islandês",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italiano",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japonês",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Javanês",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, groenlandês",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Canarês",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Kashmiri",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Cazaque",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu, Gikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Quiniaruanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Quirguiz",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Coreano",
          nativeName: "한국어",
        },
        ku: {
          name: "Curdo",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kwanyama, Kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latim",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxemburguês, Letzeburgesch",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburguês, limburgo",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Lao",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Lituano",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-Katanga",
          nativeName: "",
        },
        lv: {
          name: "Letão",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manês",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macedônio",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Malgaxe",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Malaio",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malaialo",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltês",
          nativeName: "Malti",
        },
        mi: {
          name: "Māori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Marati (Marāṭhī)",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshalês",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongol",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo, Navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Bokmål norueguês",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Ndebele do norte",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepali",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Nynorsk norueguês",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Norueguês",
          nativeName: "Norsk",
        },
        ii: {
          name: "Nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Ndebele do sul",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Occitano",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwe, Ojibwa",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Antigo eslavo eclesiástico, eslavo eclesiástico, búlgaro antigo, eslavo antigo",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Ossético",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Panjabi, Punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pāli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Persa",
          nativeName: "فارسی",
        },
        pl: {
          name: "Polonês",
          nativeName: "polski",
        },
        ps: {
          name: "Pachto, Pashtu",
          nativeName: "پښتو",
        },
        pt: {
          name: "Português",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Português (Brasil)",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quíchua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Romanche",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Romeno, moldavo",
          nativeName: "română",
        },
        ru: {
          name: "Russo",
          nativeName: "русский",
        },
        sa: {
          name: "Sânscrito (Saṁskṛta)",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sardo",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Sami do norte",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoano",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Sérvio",
          nativeName: "српски језик",
        },
        gd: {
          name: "Gaélico escocês; gaélico",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Sinhala, cingalês",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Eslovaco",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Esloveno",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somali",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Soto do sul",
          nativeName: "Sesotho",
        },
        es: {
          name: "Espanhol", //      'name':'Tradicional / Espanhol',
          nativeName: "Español",
        },
        su: {
          name: "Sundanês",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Suaíli",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Suázi",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Sueco",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tadjique",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Tailandês",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrinya",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibetano padrão, tibetano, central",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turcomeno",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalo",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tonga (Ilhas Tonga)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Turco",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tatar",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Taitiano",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Uighur, Uyghur",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ucraniano",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Uzbeque",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamita",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Walloon",
          nativeName: "Walon",
        },
        cy: {
          name: "Galês",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Frísio ocidental",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Iídiche",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Iorubá",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Zhuang, Chuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Outro",
          nativeName: "Outro",
        },
      },

      details: {
        navigation: {
          content_information: "Informações do conteúdo",
          version_and_file_management: "Gerenciamento de versão e arquivo",
          details: "Detalhes",
          versions: "Versões",
          analytics: "Relatórios",
          statistics: "Estatísticas",
          manage_access: "Gerenciar acesso",
          related_content: "Relacionado",
          properties: "Propriedades",
          support: "Empresa e suporte",
          release_notes: "Novidades",
          comments: "Comentários do usuário",
        },
        related_content: {
          other_content_title: "Outro conteúdo que você pode gostar",
          related_content_title: "Conteúdo recomendado por ",
          related_content_url: "URL do conteúdo",
          add_related_content: "Adicionar conteúdo relacionado",
          add_related_content_info:
            "Você só pode adicionar conteúdo online relacionado a conteúdo online, e conteúdo local a conteúdo local",
          remove_related_content: "Remover",
          relatedContentUrlSubtitle: "ID do conteúdo ou URL completa do conteúdo",
          updated_successfully: "Conteúdo relacionado atualizado com êxito",
          update_failed: "Erro ao atualizar conteúdo relacionado",
          update_failed_invalid_id: "O id de conteúdo fornecido é inválido",
        },
        actions: {
          move: "Mover",
          delete: "Excluir",
          add_comment: "Adicionar comentário",
          add_reply: "Adicionar resposta",
          edit_comment: "Editar comentário",
          reply_comment: "Adicionar resposta",
          view_replies: "Ver respostas",
          report_bad_comment: "Reportar comentário impróprio &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Editar título ou excluir",
            title_or_move_delete: "Editar título, mover ou excluir",
            thumbnail: "Editar miniatura",
            description: "Editar descrição",
            groupandcategory: "Editar grupo e categoria",
            details: "Editar detalhes",
            versions: "Editar versões",
            support: "Editar informações de suporte",
            release_notes: "Editar novidades",
            search_criteria: "Editar critérios de pesquisa",
            batchedit_and_translations:
              "Com o recurso de tradução, você pode publicar o conteúdo em vários idiomas. A versão traduzida do conteúdo será mostrada a usuários com base nas seleções de idioma da interface do usuário do Warehouse. Com a edição em lote, você pode editar vários itens de uma vez. Primeiro selecione um campo e, em seguida, os itens que deseja editar",
          },
        },
        links: {
          link: "Vincular",
          select_collection_placeholder: "Selecionar coleção para vincular",
          linking_collections_label: "Coleções vinculadas a esta",
          private_content_note: "Nota: o conteúdo privado continuará visível apenas para os usuários com acesso a ele.",
          share_on_social_media: "Compartilhar em:",
          report_bad_content: "Reportar conteúdo impróprio &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Denunciar conteúdo ilegal &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Ao ativar os alertas, você concorda em compartilhar seu nome de exibição e nome de organização da Tekla Account (se aplicável) com o proprietário do conteúdo.",
          alert_me: "Alertar-me",
          unsubscribe: "Cancelar assinatura",
          subscribed_successfully: "Assinatura concluída com sucesso",
          unsubscribed_successfully: "Cancelamento da assinatura concluído com sucesso",
          subscribers_label: "Assinantes",
        },
        reviews: {
          reviewers_label: "Revisores",
        },
        comments: {
          replies: "Respostas",
        },
        scan: {
          soft_failed_binaries_info: "A varredura antivírus falhou  ",
          hard_failed_binaries_info: "A varredura antivírus não pôde ser executada ",
          infected_binaries_info: "A varredura antivírus encontrou um vírus ",
          what_now: "E agora?",
          popup: {
            soft_failed_binaries_title: "Falha nos binários",
            hard_failed_binaries_title: "Falha nos binários",
            infected_binaries_title: "Binários infectados",
            soft_failed_binaries_info:
              "A varredura antivírus dos arquivos enviados por você falhou. Isso é apenas temporário. Remova os arquivos e carregue-os novamente. Todos os arquivos devem passar pela varredura antivírus para ficarem visíveis para outros usuários.",
            hard_failed_binaries_info:
              "A varredura antivírus não pôde ser executada. Remova os arquivos. Todos os arquivos devem passar pela varredura antivírus para ficarem visíveis para outros usuários.",
            infected_binaries_info:
              "A varredura antivírus encontrou um vírus nos arquivos carregados. Remova os arquivos. Todos os arquivos devem passar pela varredura antivírus para ficarem visíveis para outros usuários.",
            version: "Versão: ",
            fileName: "Nome do arquivo: ",
          },
        },
        status: {
          content_banned_info:
            "O conteúdo foi bloqueado pelo administrador. No momento, não está disponível para outros usuários, exceto o proprietário do conteúdo. <a href='https://support.tekla.com/contact-us' target='_blank'>Fale com o suporte</a> para obter mais informações.",
          content_archived_info:
            "Este conteúdo foi arquivado. Usuários sem direitos de edição serão redirecionados para a página inicial se tentarem visitar o conteúdo por meio de um link direto.",
          archived: "Arquivado",
        },
        modified_by: function (name) {
          return " por " + name;
        },
        created_by: function (name) {
          return "Criado por " + name;
        },
        deleteEntity: function (title) {
          return "Excluir " + title + "?";
        },
        userComments: "Comentários do usuário",
        comment_added: "Comentário adicionado com sucesso",
        comment_updated: "Comentário atualizado com sucesso",
        comment_deleted: "Comentário excluído com sucesso",
        comment_add_failed: "Falha ao adicionar comentário",
        comment_update_failed: "Falha ao atualizar comentário",
        comment_delete_failed: "Falha ao excluir comentário",
        comment_delete_confirmation: "Excluir item de comentário?",
        name: "Nome",
        details: "Detalhes",
        contentOwner: "Criador",
        contentOwnerGroup: "Grupo do proprietário do conteúdo",
        contentGroup: "Grupo do conteúdo",
        thumbnail: "Miniatura",
        tooltip: "Dica",
        description: "Descrição",
        additional_information: "Informações adicionais",
        images: "Imagens de produto",
        installationInfo: "Informações da instalação",
        productCode: "Código do produto",
        copyrightInfo: "Copyright",
        productExpirationDate: "Data de validade do produto",
        helpUrl: "Site &rsaquo;",
        supportUrl: "Suporte &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "Novidades",
        supportForumUrl: "Discussion Forum &rsaquo;",
        itemTypeCategories: "Grupo",
        useCategories: "Categoria",
        category: "Categoria:",
        group: "Grupo:",
        compatibleSoftwareProducts: "Produtos de software compatíveis",
        compatibleOperatingSystems: "Sistemas operacionais compatíveis",
        locationRestrictions: "Disponibilidade do produto:",
        supportedLanguages: "Idiomas:",
        measurementUnits: "Unidades de medida:",
        tags: "Tags e palavras-chave:",
        prerequisites: "Pré-requisitos:",
        releaseNotes: "Novidades:",
        versions: "Versões",
        testedVersions: "Versões compatíveis",
        qualityTag: "Qualidade - avaliada pelo proprietário",
        otherAttributes: "Outros atributos",
        labels: "Critérios de pesquisa",
        supportInformation: "Suporte",
        updateInformation: "Atualizações",
        latestUpdate: "Última atualização",
        totalDownloads: "Total de descargas",
        licenses: "Licenças",
        maintenanceRequired: "A descarga requer a Manutenção da Tekla",
        tekla_maintenance_content: "Conteúdo da Manutenção Tekla",
        download_requires_tekla_maintenance: "A descarga requer a Manutenção da Tekla",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Atualização com êxito",
        update_failed: "Erro na atualização",
        update_failed_invalid_tag: "Erro na atualização: etiqueta inválida",
        update_failed_details_max_length_exceeded: "Erro na atualização: detalhes muito longos",
        show_version_details: "Mais informações",
        content_removed: "Conteúdo removido",
        no_versions_available:
          "Nenhuma versão disponível. As atualizações podem demorar para serem exibidas. Atualize seu navegador para ver as alterações.",
        thumbnail_deleted: "Miniatura excluída",
        thumbnail_updated: "Miniatura atualizada",
        verify_service_plugin_is_running:
          "Verifique se o plug-in do Tekla Warehouse Service está sendo executado corretamente",
        verify_ts_connection: "Verifique se o Tekla Structures está sendo executado",
        download_tos: {
          title: "Qualidade, suporte e privacidade",
          content_1: "Certifique-se de que o conteúdo baixado esteja em conformidade com os seus padrões de qualidade. A Trimble não oferece suporte e não é responsável por conteúdo de terceiros. Entre em contato diretamente com o provedor de conteúdo para obter suporte.",
          content_2: "O país e o nome da organização podem ser publicados no serviço e divulgados aos proprietários de conteúdo para fins legítimos, tais como mantê-lo atualizado sobre as modificações de conteúdo. Você poderá cancelar a assinatura dessas notificações.",
          content_3: 'Para extensões de conteúdo da Trimble, aplicam-se os <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">termos da Trimble</a>. Termos de licença de terceiros podem se aplicar ao conteúdo de terceiros. Veja o <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Aviso de Privacidade da Trimble</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Este é o armazenamento BIM grátis do Tekla Structures. Encontre, importe, instale e compartilhe de forma interna e global. Produza modelos de alta qualidade de forma eficiente.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "O Tekla Warehouse é uma biblioteca online e local gratuita de ferramentas de modelagem, peças inteligentes e modelos do Tekla Structures",
        },
        search: {
          title: "Procurar no Tekla Warehouse",
          description:
            "Procurar extensões, peças 3D, formas, componentes personalizados, perfis, materiais como graus de aço e concreto, armaduras, malhas e modelos da Tekla",
        },
        about: {
          title: "Informações sobre o Tekla Warehouse",
          description:
            "O Tekla Warehouse oferece um canal de marketing gratuito para seus produtos e dá a você o controle total do seu conteúdo",
          developers: {
            title: "Título de desenvolvedores padrão",
            description: "Descrição de desenvolvedores padrão",
          },
          usage: {
            title: "Título de uso padrão",
            description: "Descrição de uso padrão",
          },
          getting_started: {
            title: "Título de introdução padrão",
            description: "Descrição de introdução padrão",
          },
          content: {
            title: "Título de conteúdo padrão",
            description: "Descrição de conteúdo padrão",
          },
          using_content: {
            title: "Título de conteúdo de uso padrão",
            description: "Descrição de conteúdo de uso padrão",
          },
          creating_content: {
            title: "Título de conteúdo de criação padrão",
            description: "Descrição de conteúdo de criação padrão",
          },
        },
        collection: {
          title: "Título de coleção padrão",
          description: "Descrição de coleção padrão",
        },
        my_warehouse: {
          title: "Meu Tekla Warehouse",
          description: "Gerenciar seu conteúdo do Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Cancelar",
          previous: "&lsaquo; Anterior",
          next: "Próximo &rsaquo;",
        },
        content: {
          add_content: "Adicionar arquivos",
        },
        collection: {
          heading: "Selecionar ou criar coleção",
          subHeading:
            "Conteúdo do grupo de coleções. Você pode usar coleções online para publicar seu conteúdo ou coleções locais para compartilhar conteúdo com seus colegas. Você também pode usar coleções locais para uso privado.",
          loading: "Carregando coleções...",
          selectFromMyCollections: "Selecionar em Minhas coleções",
          description_of_the_collection: "Descrição",
          title_validation: {
            missing: "Insira um título.",
            too_short: function (length) {
              return "O título deve ter no mínimo " + length + " caracteres.";
            },
            too_long: function (length) {
              return "O título não pode ter mais de " + length + " caracteres.";
            },
          },
        },
        package: {
          basic_information: "Informações básicas",
          code_name: "Nome do código",
          code_name_info: "Insira um nome de código como um identificador exclusivo para este conteúdo. O nome de código pode incluir letras minúsculas, números e estes caracteres especiais: _ - @. Não é possível alterar o nome do código depois que ele for salvo.",
          code_name_validation: {
            missing: "Você deve inserir um nome de código",
            wrong_format: "O nome de código pode incluir letras minúsculas, números e estes caracteres especiais: _ - @",
            not_unique: "Este nome de código já está em uso. Você deve inserir um nome de código exclusivo.",
          },
          search_criteria: "Critérios de pesquisa",
          support: "Suporte",
          title: "Título",
          thumbnail: "Miniatura",
          "3D_pic": "Modelo 3D",
          title_validation: {
            missing: "Insira um título.",
            too_short: function (length) {
              return "O título deve ter no mínimo " + length + " caracteres.";
            },
          },
          description_validation: {
            missing: "Insira uma descrição.",
            too_short: function (length) {
              return "A descrição deve ter no mínimo " + length + " caracteres.";
            },
          },
          wrong_file_type: "Tipo errado de arquivo.",
          selected_image_is_too_big: "A imagem selecionada é muito grande.",
        },
        version: {
          select_version: "Selecionar versão",
          select_quality: "Selecionar qualidade",
          versions: "Versões",
          displayNameNew: "Nome de exibição da versão",
          copy_from_version: "Copiar metadados da versão existente (opcional)",
          displayName: "Nome para exibição",
          content_information: "Informações do conteúdo",
          back_to_content_information: "&lsaquo; Voltar para informações do conteúdo",
          version_and_file_management: "Gerenciamento de versão e arquivo",
          there_s_no_versions_yet: "Ainda não há nenhuma versão.",
          create_a_version_to_add_files: "Crie uma versão para adicionar arquivos.",
          add_version: "Adicionar versão",
          version_information: "Informações da versão",
          delete_version: "Excluir versão",
          no_versions_available: "Nenhuma versão disponível. As atualizações podem demorar para serem exibidas.",
          update_start_title_html: '<div class="spinner spinner-white">Atualizando</div>',
          update_start_message: "Isso pode demorar um pouco.",
          version_was_created: "A versão foi criada.",
          version_was_created_with_delay: "A versão foi criada. As alterações serão visíveis dentro de alguns minutos.",
          version_was_updated: "A versão foi atualizada.",
          version_was_deleted: "A versão foi excluída",
          version_was_deleted_with_delay:
            "A versão foi excluída. As alterações serão visíveis dentro de alguns minutos.",
          confirm_delete: function (title) {
            return 'Excluir versão "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "O pacote foi carregado.",
          couldn_t_create_a_package: "Não foi possível criar um pacote. As alterações serão revertidas.",
          uploading_title_html: '<div class="spinner spinner-white">Carregando</div>',
          uploading_text: "Isso pode demorar um pouco.",
          select_organization: "Selecionar organização",
          collection_visibility: "Visibilidade da coleção:",
          actions: {
            create: "Criar",
            publish: "Publicar",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Este conteúdo estará visível a todos os usuários com acesso à unidade local ou de rede.",
          this_content_will_be_visible_only_to_you: "Este conteúdo ficará visível somente para você.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Este conteúdo ficará visível somente para você e para todos na sua organização.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Este conteúdo ficará visível para todos após a publicação.",
          public_content_disclaimer:
            "Você é responsável por todo o conteúdo que carregar. Certifique-se de que o conteúdo não seja nocivo, que você tenha permissão para carregá-lo e que ele não viole nenhum direito de propriedade intelectual. Você concorda em publicar no serviço quaisquer termos e condições aplicáveis ao uso do conteúdo carregado por você.",
        },
        metadata: {
          title: "Metadados",
          subtitle: "Dados básicos",
          deleteThumbnail: "Excluir miniatura",
          buttonCreate: "Criar",
        },
        basicdata: {
          title: "Adicionar conteúdo",
          clickButtonToSelectFile: "Clique no botão para selecionar o arquivo",
          thumbnail: "Miniatura",
          dropImageHere: "Soltar imagem aqui",
        },
        "3dGeometry": {
          title: "Criar catálogo de produtos 3D",
          buttonAddEntity: "Adicionar produto 3D",
          buttonAdd: "Adicionar atributo personalizado",
          errorAttributeAlreadyExists: "Já existe um atributo de mesmo nome.",
          buttonCreatePackage: "Criar pacote",
          buttonCreatePackages: "Criar pacotes",
          addCustomAttributePlaceholder: "Nome do atributo",
        },
        please_log_in_fragments: {
          please: "Por favor ",
          log_in: "Entrar",
          or: " ou ",
          register: "registre-se",
          to_upload_online_content: " para carregar conteúdo online.",
        },
        header: {
          upload_header: "Carregar",
          collection: "Coleção",
          content: "Conteúdo",
          version: "Versão",
          finish: "Concluir",
        },
        contentOwner: "Criador",
        contentOwnerGroup: "Publicar conteúdo",
        select_action: "Selecionar ação",
        tooltip: "Dica",
        description: "Descrição",
        copyright: "Copyright",
        tags: "Etiquetas e palavras-chave",
        tagsSubtitle:
          "Use vírgula para separar, por exemplo: palavras-chave 1, palavras-chave 2. Se a palavra-chave consistir em várias palavras, use o sublinhado (_) entre as palavras, não é permitido espaço entre as palavras. Além de sublinhado e vírgula, não há outros caracteres especiais permitidos.",
        images: "Imagens",
        installationInfo: "Informações de instalação",
        productCode: "Código Produto",
        copyrightInfo: "Informações de copyright",
        Details: "Detalhes",
        specialTermsUrl: "EULA",
        productExpirationDate: "Data de validade do produto",
        helpUrl: "Site",
        helpUrlSubtitle: "inserir URL completa",
        detailsSubtitle:
          "Nesta fase, você só pode adicionar texto simples ao campo Detalhes. Após a criação do conteúdo, você pode editar os Detalhes para que contenha a formatação em HTML.",
        specialTermsUrlSubtitle:
          "O proprietário de conteúdo tem a oportunidade de adicionar termos adicionais ao próprio conteúdo. O proprietário precisa fornecer uma URL onde seus termos específicos estão localizados. Isso não anula os Termos de serviço da Trimble.",
        releaseNotes: "Arquivo de notas",
        releaseNoteFileSubTitle: "Adicionar notas como arquivo",
        supportUrl: "Ajuda e suporte",
        supportUrlSubtitle: "inserir URL completa ou o prefixo 'mailto:' para emails",
        supportForumUrl: "Discussion Forum (site)",
        supportForumUrlSubtitle: "inserir URL completa",
        measurementUnits: "Unidades de medida",
        itemTypeCategories: "Grupo",
        useCategories: "Categoria",
        compatibleSoftwareProducts: "Produtos de software compatíveis",
        testedVersions: "Versões compatíveis",
        compatibleOperatingSystems: "Sistema operacional compatível",
        locationRestrictions: "Disponibilidade do produto",
        supportedLanguages: "Idiomas",
        select_releasenotes_file: "Selecionar arquivo",
        qualityTag: "Qualidade - avaliada pelo proprietário",
        requireMaintenance: "O download requer a manutenção do cliente Tekla",
        contentOwnerGroupAsMyself: "Como eu mesmo",
        contentOwnerGroupAsUserGroup: "Como grupo de usuários",
        selectUserGroupForContentOwner: "Selecionar grupo de usuário do proprietário de conteúdo",
        defaultUploadTemplate: "Pacote padrão",
        templateFor3DGeometryFiles: "Pacote(s) de geometria 3D",
        title: "Título",
        details: "Detalhes",
        selectContentOwner: "Selecionar proprietário de conteúdo",
        selectContentType: "Tipo",
        errorWrongfiletype: "Tipo errado de arquivo.",
        errorImageTooBig: "A imagem selecionada é muito grande.",
        errorBlankTitle: "O título não pode ficar em branco.",
        commonLabelsTitle: "Etiquetas comuns para todos os pacotes",
      },
      profile: {
        content_usage: "Uso do conteúdo",
        profile_updated: "Perfil atualizado",
        invitation_accepted: function (target) {
          return "Convite aceito! Agora você tem acesso a " + target + ".";
        },
        user_groups: {
          edit_user_group: "Editar grupo de usuários",
          user_group_updated: "Grupo de usuários atualizado",
          create_a_new_user_group: "Criar novo grupo de usuários",
          name_of_the_user_group: "Nome do grupo de usuários",
          description: "Descrição",
          group_visibility: "Visibilidade do grupo",
          remove: "Tem certeza?",
          remove_invitations: "Tem certeza?",
          remove_members: "Tem certeza?",
          invitations_removed: "Convites removidos!",
          members_list_updated: "Lista de membros atualizada!",
          user_group_created: "Grupo de usuários criado",
        },
        members: {
          update_selected_members: "Atualizar membros selecionados",
          new_role: "Nova função",
          members_list_updated: "Lista de membros atualizada!",
          edit_user_group: "Editar grupo de usuários",
          delete_user_group: "Excluir grupo de usuários",
          filter: "Filtrar",
          user_group_members: "Membros do grupo de usuários",
          update_members: "Atualizar membros",
          remove_members: "Remover membros",
          pending_invitations: "Convites pendentes",
          invite_member: "Convidar membro",
          update_invitations: "Atualizar convites",
          remove_invitations: "Remover convites",
        },
        subTabs: {
          alerts: "Meus alertas",
          collections: "Coleções",
          likes: "Curtidas",
        },
        admin: {
          grant_sales_admin: "Conceder função de administrador de vendas ao usuário",
          grant_analyst_role: "Conceder função de analista ao usuário",
          role_granted: "Função concedida",
          role_removed: "Função removida",
          offline_to_online: "Converter conteúdo off-line em on-line",
          use_elastic_search: "Usar pesquisa elástica",
        },
        sales: {
          title: "Vendas",
        },
        subtitlePreferences: "Preferências de pesquisa",
        preferenceInfo:
          'Esses filtros são para recomendar conteúdo para você na <a href="#/landing">página inicial</a> do Tekla Warehouse.',
        clearFilters: "Limpar filtros &rsaquo;",
        admin_role: "Admin",
        sales_role: "Vendas",
        admin_mode: "Modo admin:",
        firstName: "Nome",
        lastName: "Sobrenome",
        displayName: "Nome para exibição:",
        organization: "Nome da organização:",
        email: "Email:",
        teklaId: "ID Tekla",
        maintenanceAccess: "Acesso ao conteúdo da Manutenção da Tekla:",
        myLocations: "Locais de projeto",
        measurementUnits: "Unidades",
        useCategories: "Usar",
        itemTypeCategories: "Estou interessado nos tipos a seguir",
        languages: "Idiomas",
        operatingSystems: "Sistemas operacionais",
        software: "Produtos de software",
        user_id: "ID de usuário:",
        organization_id: "ID da organização:",
        edit_in_atc: "Editar o meu perfil em Tekla Account",
        no_likes: "Nenhuma curtida",
        no_alerts: "Nenhuma assinatura",
        clear_alerts: "Limpar alertas",
        alerts_info_html:
          "Você receberá um alerta quando qualquer uma das seguintes alterações ocorrer<br>" +
          "<b>Conteúdo:</b> modificação de título, descrição, critérios de pesquisa ou metadados de item de conteúdo, inclusão de nova versão do conteúdo ou exclusão de uma versão.<br>" +
          "<b>Coleção:</b> edição de título ou descrição, inclusão ou remoção de conteúdo.",
      },
      uploader: {
        placeholders: {
          productCode: "Código Produto",
          title: "Nome",
          description: "Descrição",
          productWeight: "Peso do produto",
          weightUnit: "Unidade de peso",
          material: "Material",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Catálogo de produtos 3D",
          addToolButton: "Aplicação",
          select_files: "Arquivos do disco",
          select_files_from_ts: "Arquivos do Tekla Structures",
          partnerDownloadLink: "Link de download do parceiro",
        },
        headings: {
          geometryFile: "Arquivo de geometria",
          thumbnailFile: "Bitmap de miniatura (.bmp)",
          title: "Nome",
          description: "Descrição",
          productCode: "Código Produto",
          productWeight: "Peso do produto",
          weightUnit: "Unidade de peso",
          material: "Material",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Global",
          AF: "Afeganistão",
          AX: "Ilhas Åland",
          AL: "Albânia",
          DZ: "Argélia",
          AS: "Samoa Americana",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antártida",
          AG: "Antígua e Barbuda",
          AR: "Argentina",
          AM: "Armênia",
          AW: "Aruba",
          AU: "Austrália",
          AT: "Áustria",
          AZ: "Azerbaijão",
          BS: "Bahamas",
          BH: "Bahrein",
          BD: "Bangladesh",
          BB: "Barbados",
          BY: "Belarus",
          BE: "Bélgica",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermuda",
          BT: "Butão",
          BO: "Bolívia",
          BQ: "Bonaire",
          BA: "Bósnia e Herzegovina",
          BW: "Botsuana",
          BV: "Ilha Bouvet",
          BR: "Brasil",
          IO: "Território Britânico do Oceano Índico",
          BN: "Brunei Darussalam",
          BG: "Bulgária",
          BF: "Burquina Faso",
          BI: "Burundi",
          KH: "Camboja",
          CM: "Camarões",
          CA: "Canadá",
          CV: "Cabo Verde",
          KY: "Ilhas Cayman",
          CF: "República Centro-Africana",
          TD: "Chade",
          CL: "Chile",
          CN: "China",
          CX: "Ilha Christmas",
          CC: "Ilhas Cocos (Keeling)",
          CO: "Colômbia",
          KM: "Comores",
          CG: "República do Congo",
          CD: "República Democrática do Congo",
          CK: "Ilhas Cook",
          CR: "Costa Rica",
          CI: "Costa do Marfim",
          HR: "Croácia",
          CU: "Cuba",
          CW: "Curaçao",
          CY: "Chipre",
          CZ: "República Tcheca",
          DK: "Dinamarca",
          DJ: "Djibuti",
          DM: "Dominica",
          DO: "República Dominicana",
          EC: "Equador",
          EG: "Egito",
          SV: "El Salvador",
          GQ: "Guiné Equatorial",
          ER: "Eritreia",
          EE: "Estônia",
          ET: "Etiópia",
          FK: "Ilhas Malvinas (Falkland Islands)",
          FO: "Ilhas Faroé",
          FJ: "Fiji",
          FI: "Finlândia",
          FR: "França",
          GF: "Guiana Francesa",
          PF: "Polinésia Francesa",
          TF: "Territórios Franceses do Sul",
          GA: "Gabão",
          GM: "Gâmbia",
          GE: "Geórgia",
          DE: "Alemanha",
          GH: "Gana",
          GI: "Gibraltar",
          GR: "Grécia",
          GL: "Groenlândia",
          GD: "Granada",
          GP: "Guadalupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guiné",
          GW: "Guiné-Bissau",
          GY: "Guiana",
          HT: "Haiti",
          HM: "Ilhas Heard e McDonald",
          VA: "Cidade do Vaticano",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Hungria",
          IS: "Islândia",
          IN: "Índia",
          ID: "Indonésia",
          IR: "Irã",
          IQ: "Iraque",
          IE: "Irlanda",
          IM: "Ilha de Man",
          IL: "Israel",
          IT: "Itália",
          JM: "Jamaica",
          JP: "Japão",
          JE: "Jérsei",
          JO: "Jordânia",
          KZ: "Cazaquistão",
          KE: "Quênia",
          KI: "Kiribati",
          KW: "Kuwait",
          KG: "Quirguistão",
          LA: "Laos",
          LV: "Letônia",
          LB: "Líbano",
          LS: "Lesoto",
          LR: "Libéria",
          LY: "Líbia",
          LI: "Liechtenstein",
          LT: "Lituânia",
          LU: "Luxemburgo",
          MO: "Macau",
          MK: "Macedônia",
          MG: "Madagascar",
          MW: "Malawi",
          MY: "Malásia",
          MV: "Maldivas",
          ML: "Mali",
          MT: "Malta",
          MH: "Ilhas Marshall",
          MQ: "Martinica",
          MR: "Mauritânia",
          MU: "Maurício",
          YT: "Mayotte",
          MX: "México",
          FM: "Micronésia",
          MD: "Moldova",
          MC: "Mônaco",
          MN: "Mongólia",
          ME: "Montenegro",
          MS: "Montserrat",
          MA: "Marrocos",
          MZ: "Moçambique",
          MM: "Mianmar (Birmânia)",
          NA: "Namíbia",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Holanda",
          NC: "Nova Caledônia",
          NZ: "Nova Zelândia",
          NI: "Nicarágua",
          NE: "Níger",
          NG: "Nigéria",
          NU: "Niue",
          NF: "Ilha Norfolk",
          KP: "Coreia do Norte",
          MP: "Ilhas Marianas do Norte",
          NO: "Noruega",
          OM: "Omã",
          PK: "Paquistão",
          PW: "Palau",
          PS: "Território Palestino",
          PA: "Panamá",
          PG: "Papua-Nova Guiné",
          PY: "Paraguai",
          PE: "Peru",
          PH: "Filipinas",
          PN: "Pitcairn",
          PL: "Polônia",
          PT: "Portugal",
          PR: "Porto Rico",
          QA: "Catar",
          RE: "Reunião",
          RO: "Romênia",
          RU: "Russia",
          RW: "Ruanda",
          BL: "São Bartolomeu",
          SH: "Santa Helena",
          KN: "São Cristóvão e Névis",
          LC: "Santa Lúcia",
          MF: "São Martinho",
          PM: "São Pedro e Miquelon",
          VC: "São Vicente e Granadinas",
          WS: "Samoa",
          SM: "São Marinho",
          ST: "São Tomé e Príncipe",
          SA: "Arábia Saudita",
          SN: "Senegal",
          RS: "Sérvia",
          SC: "Seicheles",
          SL: "Serra Leoa",
          SG: "Cingapura",
          SX: "São Martinho",
          SK: "Eslováquia",
          SI: "Eslovênia",
          SB: "Ilhas Salomão",
          SO: "Somália",
          ZA: "África do Sul",
          GS: "Geórgia do Sul",
          KR: "Coreia do Sul",
          SS: "Sudão do Sul",
          ES: "Espanha",
          LK: "Sri Lanka",
          SD: "Sudão",
          SR: "Suriname",
          SJ: "Svalbard e Ilha de Jan Mayen",
          SZ: "Suazilândia",
          SE: "Suécia",
          CH: "Suíça",
          SY: "Síria",
          TW: "Taiwan",
          TJ: "Tadjiquistão",
          TZ: "Tanzânia",
          TH: "Tailândia",
          TL: "Timor-Leste",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad e Tobago",
          TN: "Tunísia",
          TR: "Turquia",
          TM: "Turcomenistão",
          TC: "Ilhas Turcos e Caicos",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ucrânia",
          AE: "Emirados Árabes Unidos",
          GB: "Reino Unido",
          US: "Estados Unidos",
          UM: "Territórios Insulares dos Estados Unidos",
          UY: "Uruguai",
          UZ: "Uzbequistão",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietnã",
          VG: "Ilhas Virgens Britânicas",
          VI: "Ilhas Virgens dos Estados Unidos",
          WF: "Ilhas Wallis e Futuna",
          EH: "Saara Ocidental",
          YE: "Iêmen",
          ZM: "Zâmbia",
          ZW: "Zimbábue",
        },
        sortOptions: {
          "-modifyTime": "Última atualização",
          "-modifiedAt": "Última atualização",
          title: "Alfabética de A-Z",
          "-title": "Alfabética de Z-A",
          "-reviewCount": "Mais curtidas",
          name: "Nome",
          role: "Função",
          email: "Email",
          "-relevance": "Importância",
          "-createdAt": "Última criação",
          displayName: "Alfabética de A-Z",
          "-displayName": "Alfabética de Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Hoje",
          yesterday: "Ontem",
          this_week: "Esta semana",
          previous_week: "Semana anterior",
          this_month: "Este mês",
          previous_month: "Mês anterior",
          this_year: "Este ano",
          previous_year: "Ano anterior",
          beginning_of_time: "Início de tempo",
          custom: "Período personalizado",
        },
        eventTypeOptions: {
          downloads: "Downloads",
          views: "Visualizações",
          all_events: "Todos os eventos",
        },
      },
    });
  },
};
