import * as React from "react";
import { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router";

import { SearchPageStore, SearchPageStoreContext } from "./searchPageStore";
import { SearchBar } from "./SearchBar";
import { SideBar } from "./SideBar";
import { SearchResults } from "./SearchResults";

/**
 * Search page component.
 */
export const SearchPage = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const location = useLocation();

  useEffect(() => {
    searchPageStore.setSearchCriteria(location.search);
    searchPageStore.initialDataLoad();
  }, [location]);

  return (
    <div className="catalog">
      <SearchBar />
      <div id="results">
        <SideBar />
        <SearchResults />
      </div>
    </div>
  );
});
