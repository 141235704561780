import { TCCCollectionDS } from "../data-source/TCCCollectionDS";
import { TCCPackageDS } from "../data-source/TCCPackageDS";
import { TCCEntityDS } from "../data-source/TCCEntityDS";
import { TCCOrganizationService } from "../services/TCCOrganizationService";
import { ObjectTypeEnum } from "../../models/enums";

function prepareBinaryAttributes(binary) {
  binary = binary || { attributes: {} };
  var attributesForTCC = {
    metadata: {
      originalName: { dataType: "string", value: "" + binary.attributes.originalName },
      platform: { dataType: "string", value: "" + binary.attributes.platform },
      type: { dataType: "string", value: "" + binary.attributes.type },
      itemType: { dataType: "string", value: "" + binary.attributes.itemType },
      itemSource: { dataType: "string", value: "" + binary.attributes.itemSource },
      location: { dataType: "string", value: "" + binary.location }, // readonly
    },
  };

  if (binary.attributes.immutability) {
    attributesForTCC.immutability = binary.attributes.immutability;
  }

  return {
    dto: {
      name: binary.reference,
      originalFileName: binary.attributes.originalName,
      fileSize: binary.attributes.fileSize,
      isMetadata: binary.isMetadata || false,
      attributes: attributesForTCC,
    },
  };
}

export const TCCBinaryService = {
  upload: function (subject, binary) {
    binary = binary || {};
    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      return TCCPackageDS.createBinary(subject.id, prepareBinaryAttributes(binary), binary.file, subject.modifier.id);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionDS.createBinary(
        subject.id,
        prepareBinaryAttributes(binary),
        binary.file,
        subject.modifier.id,
      );
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
      return TCCEntityDS.createBinary(subject.id, prepareBinaryAttributes(binary), binary.file, subject.modifier.id);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
      return TCCOrganizationService.createBinary(
        subject.id,
        prepareBinaryAttributes(binary),
        binary.file,
        subject.modifier.id,
      );
    }
  },
  addBlob: function (subject, binary) {
    binary = binary || {};
    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      return TCCPackageDS.createBinaryWithMime(subject.id, prepareBinaryAttributes(binary), binary.blob, "text/xml");
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionDS.createBinaryWithMime(subject.id, prepareBinaryAttributes(binary), binary.blob, "text/xml");
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
      return TCCEntityDS.createBinaryWithMime(subject.id, prepareBinaryAttributes(binary), binary.blob, "text/xml");
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
      return TCCOrganizationService.createBinaryWithMime(
        subject.id,
        prepareBinaryAttributes(binary),
        binary.blob,
        "text/xml",
      );
    }
  },
  remove: function (subject, binary) {
    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      return TCCPackageDS.deleteBinary(subject.id, binary.reference);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionDS.deleteBinary(subject.id, binary.reference);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
      return TCCEntityDS.deleteBinary(subject.id, binary.reference);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
      return TCCOrganizationService.deleteBinary(subject.id, binary.reference);
    }
  },
  getBinary: function (subject, filename, performAsId) {
    var params = performAsId ? { performAs: performAsId } : {};

    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      return TCCPackageDS.getBinary(subject.id, filename, params);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionDS.getBinary(subject.id, filename);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
      return TCCEntityDS.getBinary(subject.id, filename);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
      return TCCOrganizationService.getBinary(subject.id, filename);
    }
  },
  getBinaries: function (subject, performAsId) {
    var params = performAsId ? { performAs: performAsId } : {};

    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      return TCCPackageDS.getBinaries(subject.id, params);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionDS.getBinaries(subject.id);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
      return TCCEntityDS.getBinaries(subject.id);
    } else if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
      return TCCOrganizationService.getBinaries(subject.id);
    }
  },
};
