export function LocalPackageRequestTransferObject(data) {
  data = data || {};
  var convertedData = {
    title: data.title,
    description: data.description,
    tags: data.tags,
    attributes: data.attributes,
  };
  if (data.id) {
    convertedData.id = data.id;
  } else {
    convertedData.collectionId = data.collectionId;
  }
  return convertedData;
}
