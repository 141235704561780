import * as React from "react";
import { useEffect } from "react";
import classNames from "classnames";

import { TCCFeatureToggleDS } from "../js/data-source/TCCFeatureToggleDS";

import { IFeatureToggle } from "../models/dataModel";
import { TranslatedHtml } from "./TranslatedHtml";


/**
 * Renders a component for displaying a temporary upload error message.
 * Parent should have the class 'temporary-upload-error-message-parent'.
 */
export const TemporaryUploadErrorMessage = () => {
  return (
    <div className="temporary-upload-error-message">
      <TranslatedHtml entry={"landing.notifications.version_creation_stuck_info"} />
    </div>
  );
};

/**
 * Renders a component for displaying a temporary banner notification.
 */
export const TemporaryBannerNotification = ({ text, color, show, bannerFeatureToggle }: { text: string | JSX.Element; color?: string; show: boolean; bannerFeatureToggle?: string }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  async function loadFeatureToggle() {
    if (!bannerFeatureToggle) {
      setIsVisible(show);
      return;
    }

    try {
      const toggle: IFeatureToggle = await TCCFeatureToggleDS.getFeatureToggle(bannerFeatureToggle);
      setIsVisible(toggle.isEnabled && show);
    } catch {
      console.log("Error while fetching feature toggle");
    }
  }

  useEffect(() => {
    loadFeatureToggle()
  }, [show]);

  return (
    isVisible && <div className={classNames("temporary-info-box", color)} data-testid="temporary-info">
      <div className="temporary-info-textarea">
        <div className="info-text-with-link">{text}</div>
      </div>
    </div>
  );
};

