export function LocalVersionRequestTransferObject(data) {
  data = data || {};
  var convertedData = {
    title: data.title,
    location: data.location,
    description: data.description,
    tags: data.tags,
    prerequisites: data.prerequisites,
    attributes: data.attributes,
  };

  if (data.id) {
    convertedData.id = data.id;
  } else {
    convertedData.packageId = data.packageId;
  }
  return convertedData;
}
