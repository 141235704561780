import _ from "underscore";

/**
 * Filters an array and returns only the truthy values.
 * @param {Array} arr - The array to filter.
 * @returns {Array} - The filtered array containing only truthy values.
 */
export function onlyTruthy(arr) {
  return _.chain(arr || [])
    .map(function (v, k) {
      return v === true ? k : null;
    })
    .compact()
    .value();
}
