import { ObjectTypeEnum } from "../../models/enums";

export function Prerequisite(data) {
  data = data || {};
  data.attributes = data.attributes || {};
  return {
    type: data.type || ObjectTypeEnum.UNKNOWN,
    title: data.title,
    uri: data.uri,
    url: data.url,
    downloadUrl: data.downloadUrl,
  };
}
