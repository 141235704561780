import { action, observable, runInAction, toJS, makeObservable, ObservableMap } from "mobx";
import _ from "underscore";
import * as validator from "validator";

import { TCCUserDS } from "../../js/data-source/TCCUserDS";
import { TCCAclService } from "../../js/services/TCCAclService";
import { TCCOrganizationService } from "../../js/services/TCCOrganizationService";

export interface IInputError {
  isValid: boolean;
  input: string;
  message?: string;
}

import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import { createStoreContext, RootStore } from "../../stores/rootStore";

import { IAclQueryObject, IAclUser, IAclUserList, ICollection, ICollectionAcl } from "../../models/dataModel";
import { ExternalResourceTypeEnum, ObjectTypeEnum } from "../../models/enums";

import { isValidUserId } from "../../utils/functions";

/**
 * Acl editor store.
 */
export class AclEditorStore extends AbstractAsyncStore {
  /**
   * Candidates list
   */
  @observable private candidates: ObservableMap<string, IAclUser[]> = observable.map();
  /**
   * Collection object
   */
  private collection?: ICollection;
  /**
   * input text
   */
  @observable private input = "";

  /**
   * List of possible input error messages
   */
  @observable private inputValidityMap?: ObservableMap<number, IInputError> = observable.map();

  /**
   * Max limit for users/organizations you can add as viewers.
   */
  private static readonly MAX_VIEWER_INPUT_LIMIT: number = 100;

  /**
   * Results list
   */
  @observable private viewers: IAclUser[] = [];

  public constructor(rootStore: RootStore, collection?: ICollection) {
    super(rootStore);
    makeObservable(this);

    if (collection) {
      this.collection = collection;
      this.fetchData(collection);
    }
  }

  /**
   * Adds user to viewers based on given input
   * 1. Creates a list of viewers to add by input rows
   * 2. Removes duplicate entries
   * 3. Searches for viewers*
   * 4. Adds analyst role to found users
   *
   * * If multiple candidates for given input row are found, user is shown a list to choose from
   */
  @action
  public async addToViewers(): Promise<any> {
    this.inputValidityMap?.clear();

    if (this.collection) {
      this.loading = true;
      let inputRows: string[] = _.uniq(this.input.trimEnd().split("\n"));
      inputRows = _.filter(inputRows, (row: string) => !_.isEmpty(row.trim()));

      const promises: Array<Promise<IAclUser | undefined>> = _.map(inputRows, async (input: string) => {
        input = input.trim();

        let foundUsers: IAclUser[];
        if (input.indexOf("@") > 0) {
          const fqs = "emailAddress==" + input;
          const query = { fq: fqs, sortBy: "displayName ASC" };
          foundUsers = await this.fetchUsers(query, input);
        } else {
          const fqs = "externalId==" + input;
          const query = { fq: fqs };
          foundUsers = await this.fetchUsers(query, input);
        }

        if (this.isAnalystUsersContainerCollection()) {
          foundUsers = this.filterOutOrganizations(foundUsers);
        }

        if (foundUsers && foundUsers.length > 1) {
          runInAction(() => {
            this.candidates.set(input, foundUsers);
          });
        } else if (foundUsers && foundUsers.length === 1) {
          runInAction(() => {
            inputRows = _.without(inputRows, input);
          });
          return foundUsers[0];
        }
      });

      const res = await Promise.all(promises);
      const entries: IAclUser[] = _.filter(res, (entry: IAclUser | undefined) => {
        return !_.isUndefined(entry);
      }) as IAclUser[];

      if (entries.length > 0) {
        let viewers: IAclUser[] = _.clone(toJS(this.viewers));
        viewers = viewers.concat(entries);

        const viewersList = {};
        _.each(viewers, (viewer: IAclUser) => {
          viewersList[viewer.id] = this.resolveResourceType(viewer);
        });

        const data = {
          subjectClass: "collection",
          subjectId: this.collection?.id,
          viewers: viewersList,
          editors: {},
          finders: {},
        };

        try {
          await TCCAclService.setRecursively(data, this.collection.linkedResourcesCollection);

          if (this.isAnalystUsersContainerCollection()) {
            const promises = _.map(entries, (entry: IAclUser) => {
              return this.addAnalystRole(entry);
            });

            await Promise.all(promises);
          }

          runInAction(() => {
            this.viewers = this.sortByName(viewers);
          });
        } catch {
          this.showErrorNotification("error_while_adding_member");
        }
      }

      runInAction(() => {
        this.input = inputRows.join("\n");
        this.loading = false;
      });
    }
  }

  /**
   * Adds given user as viewer to collection.
   * @params entry User that should be added as viewer
   */
  @action
  public async addViewer(keyword: string, entry: IAclUser, showLoading?: boolean) {
    if (showLoading) this.loading = true;

    this.candidates.delete(keyword);
    const viewers: IAclUser[] = _.clone(toJS(this.viewers));
    viewers.push(entry);
    const viewersList = {};
    _.each(viewers, (viewer) => {
      viewersList[viewer.id] = this.resolveResourceType(viewer);
    });
    const data = {
      subjectClass: "collection",
      subjectId: this.collection?.id,
      viewers: viewersList,
      editors: {},
      finders: {},
    };

    if (this.collection) {
      try {
        await TCCAclService.setRecursively(data, this.collection.linkedResourcesCollection);
        if (this.isAnalystUsersContainerCollection()) {
          this.addAnalystRole(entry);
        }
        runInAction(() => {
          this.viewers = this.sortByName(viewers);
        });
      } catch {
        this.showErrorNotification("error_while_adding_member");
      } finally {
        if (showLoading) {
          runInAction(() => {
            this.loading = false;
          });
        }
      }
    }
  }

  /**
   * Resolves if given input is valid
   * @return true given input is valid
   */
  @action
  public async checkInputValidity() {
    const inputsToCompare = this.inputValidityMap?.toJSON();
    this.inputValidityMap?.clear();
    const inputRows: string[] = this.input.trimEnd().split("\n");

    !!inputsToCompare &&
      _.each(inputsToCompare, (validity: [number, IInputError]) => {
        if (_.includes(inputRows, validity[1].input)) {
          this.inputValidityMap?.set(validity[0], validity[1]);
        }
      });

    if (inputRows.length === 1) {
      await this.checkInputRowValidity(this.input.trim(), 0);
    } else if (inputRows.length > 1 && inputRows.length) {
      _.map(inputRows, async (row: string) => await this.checkInputRowValidity(row, inputRows.indexOf(row)));
    }
  }

  /**
   * Fetches acl data for collection
   * @params collection object
   */
  @action
  public async fetchData(collection: ICollection) {
    this.dataFetched = false;
    this.loading = true;
    const aclData = await this.fetchAclData(collection);
    const viewers = this.sortByName(await this.fetchUserDetails(aclData.viewers));

    runInAction(() => {
      this.viewers = viewers;
      this.loading = false;
      this.dataFetched = true;
    });
  }

  /**
   * Gets user/organization search result list
   * @returns map of users/organizations and their search words
   */
  public getCandidates(): Array<[string, IAclUser[]]> {
    return this.candidates.toJSON();
  }

  /**
   * Fetches users/organizations with viewers rights
   * @returns list of users/organizations
   */
  public getViewers(): IAclUser[] {
    return this.viewers;
  }

  /**
   * Returns the input user has given for textarea
   * @returns input from textarea
   */
  public getInput(): string {
    return this.input;
  }

  /**
   * Returns the max input limit
   * For testing on local environment & automated tests on preprod, set max limit to 3
   * On production, use MAX_VIEWER_INPUT_LIMIT
   * @returns {number} max input row limit
   */
  public getMaxInputLimit(): number {
    if (_.any(["localhost", "preprod.warehouse.tekla.com"], (str: string) => str === window.location.hostname)) {
      return 3;
    } else {
      return AclEditorStore.MAX_VIEWER_INPUT_LIMIT;
    }
  }

  /**
   * Returns inputErrors
   */
  public getInputErrors(): IInputError[] {
    return _.filter(Array.from(this.inputValidityMap!.values()), (obj: IInputError) => !obj.isValid);
  }

  /**
   * Resolves if given input is too long
   */
  public inputTooLong(): boolean {
    const inputRows: string[] = _.uniq(this.input.trimEnd().split("\n"));
    return inputRows.length > this.getMaxInputLimit();
  }

  /**
   * Resolves if set collection is analyst collection
   * @returns true if analyst collection
   */
  public isAnalystUsersContainerCollection(): boolean {
    return (
      !!this.collection && this.collection.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION
    );
  }

  /**
   * Resolves if given input is valid
   * @return true given input is valid
   */
  public isInputValid(): boolean {
    return (
      !this.inputTooLong() && _.every(Array.from(this.inputValidityMap!.values()), (obj: IInputError) => obj.isValid)
    );
  }

  /**
   * Resolves if given entry is organization
   * @params entry to resolve if it is organization
   * @return true is given entry is organization
   */
  public isOrganization(entry: IAclUser): boolean {
    return entry && entry.type && entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION;
  }

  /**
   * Removes given user from collection viewers.
   * @params entry User that should be removed from viewers
   */
  @action
  public async removeViewer(entry: IAclUser) {
    if (this.collection) {
      try {
        this.loading = true;
        const viewers = _.reject(this.viewers, (result) => result.id == entry.id);
        const data = {
          subjectClass: "collection",
          subjectId: this.collection.id,
          viewers: viewers.map(function (viewer) {
            return viewer.id;
          }),
          editors: [],
          finders: [],
        };
        await TCCAclService.setRecursively(data, this.collection.linkedResourcesCollection);
        if (this.isAnalystUsersContainerCollection()) {
          this.removeAnalystRole(entry);
        }
        runInAction(() => {
          this.viewers = viewers;
          this.loading = false;
        });

        this.inputValidityMap?.clear();
        await this.checkInputValidity();
      } catch {
        runInAction(() => {
          this.loading = false;
        });
        this.showErrorNotification("error_while_removing_member");
      }
    }
  }

  /**
   * Stores user given input
   * @params input string given by user
   */
  @action
  public storeInput(input: string) {
    this.input = input;
  }

  @action
  private async addAnalystRole(entry: IAclUser) {
    try {
      entry.roles.push("analyst");
      await TCCUserDS.setUser(entry.id, { roles: entry.roles });
      this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("profile.admin.role_granted"));
    } catch (e) {
      this.showErrorNotification("error_while_granting_analyst_role");
    }
  }

  @action
  private async checkInputRowValidity(input: string, row: number) {
    if (_.isEmpty(input)) return;

    if (
      !!this.inputValidityMap &&
      _.any(this.inputValidityMap.toJSON(), (validity: [number, IInputError]) => validity[1].input == input)
    )
      return;

    if (validator.isEmail(input) || isValidUserId(input)) {
      const isViewer = await this.isViewerAlready(input);

      if (isViewer) {
        runInAction(() =>
          this.inputValidityMap?.set(row, {
            isValid: false,
            input: input,
            message: this.rootStore.getTranslation("shared.access_rights.viewer_already_added", input),
          }),
        );
      } else {
        runInAction(() =>
          this.inputValidityMap?.set(row, {
            isValid: true,
            input: input,
          }),
        );
      }
    } else {
      runInAction(() =>
        this.inputValidityMap?.set(row, {
          isValid: false,
          input: input,
          message: this.rootStore.getTranslation("shared.access_rights.wrong_format", input),
        }),
      );
    }
  }

  /**
   * Combines user and organization lists to one list
   * @params results List of results
   * @returns list of users/organizations
   */
  private combineResults(userEntries: IAclUser[], orgEntries: IAclUser[]): IAclUser[] {
    const combinedResults: IAclUser[] = [];
    _.each(userEntries.entries, (entry) => {
      combinedResults.push(entry);
    });
    _.each(orgEntries.entries, (entry) => {
      combinedResults.push(entry);
    });
    return combinedResults;
  }

  /**
   * Fetches acl data for collection
   * @params collection object
   * @returns acl data for collection
   */
  private async fetchAclData(collection: ICollection): Promise<ICollectionAcl> {
    return TCCAclService.getAcl(collection.id, "collection");
  }

  /**
   * Fetches user details
   * @params list of user ids which details should be fetched
   * @returns list of users with details
   */
  private async fetchUserDetails(users: IAclUserList[]): Promise<IAclUser[]> {
    const promises: Array<Promise<IAclUser>> = _.map(users, async (user) => {
      if (user.externalResourceType === ExternalResourceTypeEnum.USER) {
        return TCCUserDS.getUser(user.id);
      } else if (user.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION) {
        return TCCOrganizationService.get({ id: user.id });
      }
    });
    return Promise.all(promises);
  }

  /**
   * Fetches users and organizations
   * @params query data used for searching users and organizations
   * @params inputStr specific input string that provided no matches
   * @returns list of users/organizations
   */
  @action
  private async fetchUsers(query: IAclQueryObject, inputStr: string): Promise<IAclUser[]> {
    this.dataFetched = false;
    try {
      const promises: Array<Promise<IAclUser[]>> = [];
      promises.push(TCCUserDS.getUsers(query));
      promises.push(TCCOrganizationService.search(query));
      const results = await Promise.all(promises);
      const users = this.combineResults(results[0], results[1]);
      runInAction(() => {
        this.dataFetched = true;
      });
      if (users.length === 0) {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("errors.no_matches_found_for", inputStr));
      }
      return users;
    } catch (err) {
      this.showErrorNotification("error_while_adding_member");
      runInAction(() => {
        this.dataFetched = false;
      });
      return [];
    }
  }

  private filterOutOrganizations(results: IAclUser[]): IAclUser[] {
    results = _.reject(results, function (result) {
      return result.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION;
    });
    return results;
  }

  private async isViewerAlready(input: string): Promise<boolean> {
    if (isValidUserId(input)) {
      return _.any(this.viewers, (viewer: IAclUser) => viewer.id === input);
    } else if (validator.isEmail(input)) {
      try {
        const query = { fq: "emailAddress==" + input, sortBy: "displayName ASC" };
        const res = await TCCUserDS.getUsers(query);

        return _.any(res.entries, (entry: IAclUser) =>
          _.any(this.viewers, (viewer: IAclUser) => viewer.id === entry.id),
        );
      } catch {
        return false;
      }
    } else {
      return false;
    }
  }

  @action
  private async removeAnalystRole(entry: IAclUser) {
    try {
      entry.roles = _.reject(entry.roles, function (role) {
        return role === "analyst";
      });
      await TCCUserDS.setUser(entry.id, { roles: entry.roles });
      this.rootStore.getNotificationChannelStore().success(this.rootStore.getTranslation("profile.admin.role_removed"));
    } catch (e) {
      this.showErrorNotification("error_while_removing_analyst_role");
    }
  }

  private resolveResourceType(viewer): string {
    const type = viewer.type ? viewer.type : viewer.externalResourceType;
    if (type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
      return ExternalResourceTypeEnum.ORGANIZATION;
    } else if (type === ObjectTypeEnum.TEKLA_WAREHOUSE_USER) {
      return ExternalResourceTypeEnum.USER;
    } else return type;
  }

  /**
   * Shows error notification popup
   * @params errorKey used for fetching translation
   */
  private showErrorNotification(errorKey: string) {
    this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("errors." + errorKey));
  }

  private sortByName(aclUsers: IAclUser[]): IAclUser[] {
    return aclUsers.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
  }
}

export const AclEditorStoreContext = createStoreContext<AclEditorStore>(AclEditorStore);
