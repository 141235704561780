import * as X2JS from "@m22/x2js";
import { I3DGeometryFile, IEntity, IVersion } from "../models/dataModel";
import _ from "underscore";

/**
 * SKP metadata generator
 */
export class ThreeDGeometryXML {
  private static buildUserValueElements(threeDGeometryFile: I3DGeometryFile): object {
    const attributesToOmit = [
      "$$hashKey",
      "geometryFile",
      "thumbnailFile",
      "id",
      "title",
      "description",
      "productCode",
      "productWeight",
      "weightUnit",
      "material",
      "helpUrl",
    ];
    return _.chain(threeDGeometryFile)
      .omit(attributesToOmit)
      .map((v, k) => {
        return { UserValue: { _key: k, __text: v } };
      })
      .value();
  }

  /**
   * Generates XML (as a string) metadata for SKP file.
   * @param entity package
   * @param version version
   * @param threeDGeometryFile SKP file
   */
  public static generateXML(entity: IEntity, version: IVersion, threeDGeometryFile: I3DGeometryFile): string {
    const json = {
      ImportShape: {
        _xmlns: "http://tempuri.org/MetadataSchema.xsd",
        _version: "1.0",
        GeometryFileReference: threeDGeometryFile.geometryFile!.name,
        ImageFileReference: threeDGeometryFile.thumbnailFile!.name,
        ProductName: threeDGeometryFile.title || entity.title,
        ProductCode: threeDGeometryFile.productCode || version.productCode,
        ProductDescription: threeDGeometryFile.description || entity.description,
        ProductWeight: {
          _unit: threeDGeometryFile.weightUnit || "kg",
          __text: threeDGeometryFile.productWeight,
        },
        Material: threeDGeometryFile.material,
        Website: threeDGeometryFile.helpUrl || entity.attributes!.helpUrl,
        UserValues: this.buildUserValueElements(threeDGeometryFile),
      },
    };

    return `<?xml version="1.0" encoding="utf-8"?>${new X2JS().json2xml_str(json)}`;
  }
}
