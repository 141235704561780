import { action, observable, runInAction, toJS, makeObservable } from "mobx";
import { createContext } from "react";
import { DateTime } from "luxon";
import _ from "underscore";
import { download, generateCsv } from "export-to-csv";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import {
  IAnalyticsChartData,
  IAnalyticsData,
  IAnalyticsGeoChartOptions,
  IAnalyticsExtractionData,
  IAnalyticsExportOptions,
  IAnalyticsLineChartData,
  IAnalyticsLineChartDataRow,
  IAnalyticsLineChartOptions,
  IAnalyticsPieChartOptions,
  IAnalyticsTableChartData,
  IAnalyticsTableChartOptions,
  IAnalyticsQueryObject,
  IAnalyticsTimeFrameObject,
  IDropdownOption,
  IResource,
  IAnalyticsChartDataRow,
} from "../../models/dataModel";
import {
  AnalyticsEventTypeEnum,
  AnalyticsGroupByEnum,
  AnalyticsTimeFrameEnum,
  AnalyticsSubjectTypeEnum,
  AnalyticsTimeIntervalEnum,
  ObjectTypeEnum,
  ExternalResourceTypeEnum,
} from "../../models/enums";

import { CountryCodeConverter } from "../../utils/converters/CountryCodeConverter";
import { AnalyticsService } from "../../js/services/AnalyticsService";

export interface IAnalyticsFiltersSetter {
  getEndDate(): Date;
  getEventType?(): string;
  getStartDate(): Date;
  getTimeFrame(): string;
  setEndDate(endDate: Date): void;
  setEventType?(eventType: string): void;
  setStartDate(startDate: Date): void;
  setTimeFrame(timeFrame: string): void;
}

export enum IAnalyticsQueryType {
  ANALYTICS = "analytics",
  STATISTICS = "statistics",
}

/**
 * Analytics store.
 */
export class AnalyticsStore extends AbstractAsyncStore implements IAnalyticsFiltersSetter {
  /**
   * Time that analytics recording was started
   */
  private analyticsBeginOfTime = "2015-06-01 00:00:00";
  /**
   * End date used for filtering analytics data
   */
  @observable private endDate!: Date;
  /**
   * Event type for filtering analytics data
   */
  @observable private eventType: string = AnalyticsEventTypeEnum.DOWNLOADS;
  /**
   * Analytics data to be shown on geo chart
   */
  @observable private geoChartData: IAnalyticsData[] = [];
  /**
   * Analytics data to be shown on line chart
   */
  @observable private lineChartData: IAnalyticsData[] = [];
  /**
   * Analytics data to be shown on basic pie chart
   */
  @observable private pieChartData: IAnalyticsData[] = [];
  /**
   * Analytics data to be shown on pie chart grouped by organization
   */
  @observable private pieChartOrgData: IAnalyticsData[] = [];
  /**
   * Resource object
   */
  @observable private resource: IResource | undefined;
  /**
   * Start date used for filtering analytics data
   */
  @observable private startDate!: Date;
  /**
   * Analytics data to be shown on statistics tab
   */
  @observable private statisticsData: IAnalyticsData[] = [];
  /**
   * Analytics data to be shown on table chart
   */
  @observable private tableChartData: IAnalyticsTableChartData[] = [];
  /**
   * Interval to be used on searches
   */
  private timeInterval: string = AnalyticsTimeIntervalEnum.DAY;

  /**
   * Timeframe selection to filter analytics data
   */
  @observable protected timeFrame: string = AnalyticsTimeFrameEnum.THIS_MONTH;

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param resource Resource instance
   */
  public constructor(rootStore: RootStore, resource?: IResource, hasPartnerDownloadUrl?: boolean) {
    super(rootStore);
    makeObservable(this);
    runInAction(() => {
      this.startDate = DateTime.fromObject({ hour: 0 }).minus({ days: 30 }).toJSDate();
      this.endDate = new Date();

      if (hasPartnerDownloadUrl) {
        this.eventType = AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK;
      }

      if (resource) {
        this.resource = resource;
        if (rootStore.getUserStore().canSeeAnalytics(resource)) {
          this.fetchData();
        }
      }
    });
  }

  /**
   * Creates and downloads csv file base on input data
   * @param data The input data to be used to create file
   * @params exportOptions The options to be used when initializing ExportToCsv component
   */
  public downloadFile = (data: any[], exportOptions: IAnalyticsExportOptions) => {
    if (!_.isEmpty(data)) {
      const csv = generateCsv(exportOptions)(data);
      download(exportOptions)(csv);
    } else {
      this.rootStore.getNotificationChannelStore().info("No data to export.");
    }
  };

  /**
   * Fetches all analytics extraction data for all content
   */
  public async exportAllData(): Promise<IAnalyticsData[]> {
    let offset = 0;
    const max = 10000;
    let extractionData: IAnalyticsExtractionData[] = [];
    const queryData = {
      startDate: "",
      endDate: "",
      contentType: "",
      offset: 0,
      count: 0,
    };

    try {
      queryData.startDate = this.analyticsBeginOfTime;
      queryData.endDate = this.formatDateToString(DateTime.now().endOf("day").toJSDate());
      queryData.contentType = "twh";
      queryData.offset = offset;
      queryData.count = max;

      let exportData = await AnalyticsService.getAnalyticsExtractionData(queryData);

      extractionData.push(exportData.entries);
      offset = offset + max;

      while (offset < exportData.total) {
        queryData.offset = offset;
        queryData.count = max;
        exportData = await AnalyticsService.getAnalyticsExtractionData(queryData);
        offset = offset + max;
        if (extractionData.length === 100) {
          extractionData.push(exportData.entries);
          this.downloadFile(this.formatExportAllData(extractionData), this.getExportOptions());
          extractionData = [];
        } else {
          extractionData.push(exportData.entries);
        }
      }
      const formattedExtractionData: IAnalyticsData[] = this.formatExportAllData(extractionData);
      this.downloadFile(formattedExtractionData, this.getExportOptions());
      return formattedExtractionData;
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.export_analytics_failed"));
      return [];
    }
  }

  /**
   * Fetches analytics data based on selected filters
   */
  @action
  public async fetchData() {
    this.dataFetched = false;
    this.loading = true;
    const promises: Array<Promise<any>> = [];
    promises.push(this.fetchLineChartData());
    promises.push(this.fetchGeoChartData());
    promises.push(this.fetchPieChartData());
    if (this.isPackageLevel()) {
      promises.push(this.fetchPieChartOrgData());
    }
    if (this.isPackageLevel() || this.isOrganizationLevel()) {
      promises.push(this.fetchTableChartData());
    }
    await Promise.all(promises);
    runInAction(() => {
      this.loading = false;
      this.dataFetched = true;
    });
  }

  /**
   * Fetches analytics extraction data based on selected filters
   * @params eventType Type of event to filter data
   * @params timeFrame Selected timeframe to filter data
   * @params startDate The first day that analytics data should be included - used only if custom timeframe is selected
   * @params endDate The last day that analytics data should be included  - used only if custom timeframe is selected
   * @returns extraction data
   */
  public async fetchExportData(eventType: string, timeFrame: string, startDate: Date, endDate: Date): Promise<any> {
    let offset = 0;
    const max = 10000;
    const extractionData: IAnalyticsExtractionData[] = [];
    const timeFrameObject = this.resolveTimeFrameObject(timeFrame, startDate, endDate);
    const queryData = this.formQueryData(timeFrameObject, eventType);
    queryData.offset = offset;
    queryData.count = max;
    queryData.contentType = "twh";

    try {
      let exportData = await AnalyticsService.getAnalyticsExtractionData(queryData);
      extractionData.push(exportData.entries);
      offset = offset + max;

      while (offset < exportData.total) {
        queryData.offset = offset;
        queryData.count = max;
        exportData = await AnalyticsService.getAnalyticsExtractionData(queryData);
        offset = offset + max;
        extractionData.push(exportData.entries);
      }
    } catch {
      console.log("Failed to fetch export data");
    }

    return this.formatExtractionData(extractionData);
  }

  /**
   * Fetches statistics data data based on selected filters
   * @params timeFrame Selected timeframe to filter data
   * @params startDate The first day that analytics data should be included - used only if custom timeframe is selected
   * @params endDate The last day that analytics data should be included  - used only if custom timeframe is selected
   * @returns statistics data
   */
  @action
  public async fetchStatisticsData(timeFrame: string, startDate: Date, endDate: Date): Promise<IAnalyticsData> {
    this.loading = true;
    const timeFrameObject = this.resolveTimeFrameObject(timeFrame, startDate, endDate);
    let queryData = this.formQueryData(timeFrameObject, undefined, IAnalyticsQueryType.STATISTICS);
    queryData = _.extend(
      {
        timeInterval: this.resolveTimeInterval(queryData),
        includeEmptyIntervals: true,
      },
      queryData,
    );
    try {
      const statisticsData = this.formatStatisticsData(
        await AnalyticsService.getAnalyticsPublicData(queryData),
        queryData,
      );
      runInAction(() => {
        this.statisticsData = statisticsData;
      });
    } catch {
      console.log("Could not load statistics data");
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }

    return this.statisticsData;
  }

  /**
   * Format given date object to string
   * @params date The date to be formatted
   * @returns formatted date value as string
   */
  public formatDateToString(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd hh:mm:ss");
  }

  /**
   * Gets end data
   * @returns end date value
   */
  public getEndDate(): Date {
    return this.endDate;
  }

  /**
   * Gets event type
   * @returns event type value
   */
  public getEventType(): string {
    return this.eventType;
  }

  /**
   * Gets event type options for event type dropdown
   * @returns Array of event type values
   */
  public getEventTypeOptions(includePartnerDownload?: boolean): IDropdownOption[] {
    const options = [
      {
        label: this.rootStore.getTranslation("constants.eventTypeOptions.views"),
        value: AnalyticsEventTypeEnum.VIEWS,
      },
    ];

    return includePartnerDownload
      ? options.concat([
          {
            label: this.rootStore.getTranslation("constants.eventTypeOptions.partnerDownload"),
            value: AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK,
          },
        ])
      : options.concat([
          {
            label: this.rootStore.getTranslation("constants.eventTypeOptions.downloads"),
            value: AnalyticsEventTypeEnum.DOWNLOADS,
          },
        ]);
  }

  /**
   * Gets geo chart data
   * @returns geo chart data
   */
  public getGeoChartData(): IAnalyticsData[] {
    return toJS(this.geoChartData);
  }

  /**
   * Gets settings to be used with geo chart
   * @returns options list
   */
  public getGeoChartOptions(): IAnalyticsGeoChartOptions {
    return {
      colorAxis: { colors: ["#e7e6ed", "#005f9e"] },
    };
  }

  /**
   * Resolves title for geo chart
   * @returns title text
   */
  public getGeoChartTitle(): string {
    return this.rootStore.getTranslation("shared.analytics." + this.getEventType().toLowerCase() + "_by_country");
  }

  /**
   * Gets line chart data
   * @returns line chart data
   */
  public getLineChartData(): IAnalyticsData[] {
    return toJS(this.lineChartData);
  }

  /**
   * Gets settings to be used with line chart
   * @returns options list
   */
  public getLineChartOptions(): IAnalyticsLineChartOptions {
    const dateFormat = this.shouldUseHourFormat() ? "HH:mm a" : "MMM dd, yyyy";
    const options = {
      displayAnnotations: false,
      displayExactValues: false,
      annotationWidth: 20,
      thickness: 4,
      displayDateBarSeparator: false,
      curvetype: "function",
      legend: { position: "right" },
      hAxis: {
        gridlines: {
          color: "transparent",
        },
        format: dateFormat,
      },
      vAxis: {
        minValue: 0,
        format: "#,##0",
      },
    };
    return options;
  }

  /**
   * Resolves title for line chart
   * @returns title text
   */
  public getLineChartTitle(): string {
    if (this.resource) {
      if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
        return this.rootStore.getTranslation("shared.analytics." + this.getEventType() + "_daily_by_collection");
      } else if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
        return this.rootStore.getTranslation("shared.analytics." + this.getEventType() + "_daily_by_package");
      } else if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
        if (this.getEventType() === AnalyticsEventTypeEnum.DOWNLOADS) {
          return this.rootStore.getTranslation("shared.analytics.downloads_daily_by_version");
        } else if (this.getEventType() === AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK) {
          return this.rootStore.getTranslation("shared.analytics.twh_clicked_partner_link_daily_by_package");
        } else {
          return this.rootStore.getTranslation("shared.analytics.views_daily_by_package");
        }
      }
    }
    return "";
  }

  /**
   * Gets pie chart by org data
   * @returns pie chart by org data
   */
  public getPieChartByOrgData(): IAnalyticsData[] {
    return toJS(this.pieChartOrgData);
  }

  /**
   * Resolves title for pie chart for organization data
   * @returns title text
   */
  public getPieChartByOrgTitle(): string {
    return this.rootStore.getTranslation("shared.analytics." + this.getEventType().toLowerCase() + "_by_organization");
  }

  /**
   * Gets pie chart data
   * @returns pie chart data
   */
  public getPieChartData(): IAnalyticsData[] {
    return toJS(this.pieChartData);
  }

  /**
   * Gets settings to be used with pie chart
   * @returns options list
   */
  public getPieChartOptions(): IAnalyticsPieChartOptions {
    return {
      chartArea: { width: 400, height: 250, top: 25, left: 150 },
      sliceVisibilityThreshold: 0.005,
    };
  }

  /**
   * Resolves title for pie chart
   * @returns title text
   */
  public getPieChartTitle(): string {
    if (this.resource) {
      if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
        return this.rootStore.getTranslation("shared.analytics." + this.getEventType() + "_by_collection");
      } else if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
        return this.rootStore.getTranslation("shared.analytics." + this.getEventType() + "_by_package");
      } else if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
        return this.rootStore.getTranslation("shared.analytics." + this.getEventType() + "_by_version");
      }
    }
    return "";
  }

  /**
   * Gets start date
   * @returns start date value
   */
  public getStartDate(): Date {
    return this.startDate;
  }

  /**
   * Gets statistics data
   * @returns statistics data
   */
  public getStatisticsData(): IAnalyticsData[] {
    return toJS(this.statisticsData);
  }

  /**
   * Gets table chart by org data
   * @returns table chart by org data
   */
  public getTableChartData(): IAnalyticsTableChartData[] {
    return toJS(this.tableChartData);
  }

  /**
   * Gets settings to be used with table chart
   * @returns options list
   */
  public getTableChartOptions(): IAnalyticsTableChartOptions {
    return {
      chartArea: {
        showRowNumber: false,
        allowHtml: true,
        sort: "disable",
      },
    };
  }

  /**
   * Resolves title for table chart
   * @returns title text
   */
  public getTableChartTitle(): string {
    if (this.resource) {
      if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
        return this.rootStore.getTranslation("shared.analytics." + this.getEventType() + "_by_organization");
      } else if (this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
        return this.rootStore.getTranslation("shared.analytics.downloads_by_version_organization");
      }
    }
    return "";
  }

  /**
   * Gets timeframe
   * @returns timeframe value
   */
  public getTimeFrame(): string {
    return this.timeFrame;
  }

  /**
   * Gets timeframe options for timeframe dropdown
   * @returns Array of timeframe values
   */
  public getTimeFrameOptions(): IDropdownOption[] {
    return [
      {
        label: this.rootStore.getTranslation("constants.analyticsTimeframeOptions.today"),
        value: AnalyticsTimeFrameEnum.TODAY,
      },
      {
        label: this.rootStore.getTranslation("constants.analyticsTimeframeOptions.this_week"),
        value: AnalyticsTimeFrameEnum.THIS_WEEK,
      },
      {
        label: this.rootStore.getTranslation("constants.analyticsTimeframeOptions.this_month"),
        value: AnalyticsTimeFrameEnum.THIS_MONTH,
      },
      {
        label: this.rootStore.getTranslation("constants.analyticsTimeframeOptions.this_year"),
        value: AnalyticsTimeFrameEnum.THIS_YEAR,
      },
      {
        label: this.rootStore.getTranslation("constants.analyticsTimeframeOptions.beginning_of_time"),
        value: AnalyticsTimeFrameEnum.BEGINNING_OF_TIME,
      },
      {
        label: this.rootStore.getTranslation("constants.analyticsTimeframeOptions.custom"),
        value: AnalyticsTimeFrameEnum.CUSTOM,
      },
    ];
  }

  /**
   * Resolves if organization level in case
   * @returns true if on organization level
   */
  public isOrganizationLevel(): boolean {
    return !!this.resource && this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION;
  }

  /**
   * Resolves if package level in case
   * @returns true if on package level
   */
  public isPackageLevel(): boolean {
    return !!this.resource && this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE;
  }

  /**
   * Resolves classname to be used for export component
   * @returns classname value
   */
  public resolveExportClassName(): string {
    if (this.resource && this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return "export-collection";
    } else if (this.resource && this.resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      return "export-details";
    } else return "export";
  }

  /**
   * Sets end date
   * @params endDate the value to be set
   */
  @action
  public setEndDate = (endDate: Date) => {
    this.endDate = endDate;
    this.fetchData();
  };

  /**
   * Sets event type
   * @params eventType the value to be set
   */
  @action
  public setEventType = async (eventType: string) => {
    this.eventType = eventType;
    await this.fetchData();
  };

  /**
   * Sets start date
   * @params startDate the value to be set
   */
  @action
  public setStartDate = (startDate: Date) => {
    this.startDate = startDate;
    this.fetchData();
  };

  /**
   * Sets timeframe
   * @params timeFrame the value to be set
   */
  @action
  public setTimeFrame = (timeFrame: string) => {
    this.timeFrame = timeFrame;
    if (timeFrame !== AnalyticsTimeFrameEnum.CUSTOM) {
      this.fetchData();
    }
  };

  @action
  private async fetchGeoChartData(): Promise<IAnalyticsData> {
    const timeFrameObject = this.resolveTimeFrameObject(this.timeFrame, this.startDate, this.endDate);
    const queryData = this.formQueryData(timeFrameObject, this.eventType);
    queryData.groupBy = AnalyticsGroupByEnum.COUNTRY;
    const data = await AnalyticsService.getAnalyticsData(queryData);
    this.geoChartData = this.formatGeoChartData(data, queryData);
    return this.geoChartData;
  }

  private async fetchLineChartData(): Promise<IAnalyticsData> {
    const timeFrameObject = this.resolveTimeFrameObject(this.timeFrame, this.startDate, this.endDate);
    let queryData = this.formQueryData(timeFrameObject, this.eventType);
    queryData = _.extend(
      {
        timeInterval: this.resolveTimeInterval(queryData),
        includeEmptyIntervals: true,
      },
      queryData,
    );
    this.lineChartData = this.formatLineChartData(await AnalyticsService.getAnalyticsData(queryData), queryData);
    return this.lineChartData;
  }

  @action
  private async fetchPieChartData(): Promise<IAnalyticsData> {
    const timeFrameObject = this.resolveTimeFrameObject(this.timeFrame, this.startDate, this.endDate);
    const queryData = this.formQueryData(timeFrameObject, this.eventType);
    const analyticsData = await AnalyticsService.getAnalyticsData(queryData);
    this.pieChartData = this.formatAnalyticsData(this.sortDataDesc(analyticsData), queryData.groupBy);
    return this.pieChartData;
  }

  @action
  private async fetchPieChartOrgData(): Promise<IAnalyticsData> {
    const timeFrameObject = this.resolveTimeFrameObject(this.timeFrame, this.startDate, this.endDate);
    const queryData = this.formQueryData(timeFrameObject, this.eventType);
    queryData.groupBy = AnalyticsGroupByEnum.ORGANIZATION;
    const analyticsData = await AnalyticsService.getAnalyticsData(queryData);
    this.pieChartOrgData = this.formatAnalyticsData(
      this.sortDataDesc(analyticsData),
      AnalyticsGroupByEnum.ORGANIZATION,
    );
    return this.pieChartData;
  }

  @action
  private async fetchTableChartData(): Promise<IAnalyticsTableChartData[]> {
    const timeFrameObject = this.resolveTimeFrameObject(this.timeFrame, this.startDate, this.endDate);
    const queryData = this.formQueryData(timeFrameObject, this.eventType);
    if (this.isOrganizationLevel()) {
      queryData.groupBy = AnalyticsGroupByEnum.ORGANIZATION_AND_PACKAGE;
      queryData.subjectType = AnalyticsSubjectTypeEnum.CREATOR;
    } else {
      queryData.groupBy = AnalyticsGroupByEnum.ORGANIZATION_AND_ITEM;
    }
    const data: IAnalyticsChartData = await AnalyticsService.getAnalyticsData(queryData);
    if (this.isOrganizationLevel()) {
      this.tableChartData = this.formatOrgTableChartData(data);
    } else {
      this.tableChartData = this.formatTableChartData(data);
    }
    return this.tableChartData;
  }

  /**
   * Forms query data object based on filter values
   * @params eventType Type of event used for filtering
   * @params eventType Query object for timeframe fields
   * @returns object containing query data
   */
  private formQueryData(
    timeFrame: IAnalyticsTimeFrameObject,
    eventType?: string,
    queryType?: IAnalyticsQueryType,
  ): IAnalyticsQueryObject {
    const queryTarget = queryType ? queryType : IAnalyticsQueryType.ANALYTICS;
    const data: IAnalyticsQueryObject = {
      startDate: this.formatDateToString(timeFrame.startDate),
      endDate: this.formatDateToString(timeFrame.endDate),
      subjectType: this.resolveSubjectType(),
      subjectId: this.resolveSubjectId(),
      groupBy: this.resolveGroupBy(),
    };
    if (eventType) {
      data.eventType = this.resolveEventType(eventType);
    }
    if (
      queryTarget === IAnalyticsQueryType.ANALYTICS &&
      !this.rootStore.getUserStore().isUserAnalyst() &&
      this.resource
    ) {
      if (data.subjectType === AnalyticsSubjectTypeEnum.CREATOR) {
        data.performAs = this.resource.id;
      } else if (
        this.resource.creator &&
        this.resource.creator.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION
      ) {
        data.performAs = this.resource.creator.id;
      }
    }
    return data;
  }

  private formatAnalyticsData(data: IAnalyticsChartDataRow[], valueField: AnalyticsGroupByEnum): IAnalyticsData[] {
    const analyticsData: IAnalyticsData[] = [];
    const header = ["Header1", "Header2"];
    analyticsData.push(header);
    _.each(data, (analyticsRow: IAnalyticsChartDataRow) => {
      const row = [analyticsRow[valueField + "Value"], analyticsRow.count];
      analyticsData.push(row);
    });
    return analyticsData;
  }

  private formatExportAllData(extractionData: IAnalyticsExtractionData[]): IAnalyticsData[] {
    let csvData: any[] = [];
    _.each(extractionData, function (extractionDataTable) {
      _.each(extractionDataTable, function (row: IAnalyticsExtractionData) {
        const dataRow = {
          Created_at: row.eventTime || "",
          Event_name: row.eventType || "",
          Organization_id: row.organizationId || "",
          Organization_name: row.organizationName || "",
          Organization_atc_id: row.organizationExternalId || "",
          Country: row.countryCode || "",
          City: row.city || "",
          Subject_class: row.subjectType || "",
          Subject_id: row.subjectId || "",
          Subject_name: row.subjectName || "",
          Subject_package_id: row.packageId || "",
          Subject_package_name: row.packageName || "",
          Subject_collection_id: row.collectionId || "",
          Subject_collection_name: row.collectionName || "",
          User_agent: row.userAgent || "",
        };
        csvData.push(dataRow);
      });
    });
    csvData = _.sortBy(csvData, function (row) {
      return row["Created_at"];
    });
    return csvData;
  }

  private formatExtractionData(extractionData: IAnalyticsExtractionData[]): IAnalyticsData[] {
    let csvData: any[] = [];

    _.each(extractionData, function (extractionDataTable) {
      _.each(extractionDataTable, function (row: IAnalyticsExtractionData) {
        const dataRow = {
          Created_at: row.eventTime || "",
          Event_name: row.eventType || "",
          Organization_id: row.organizationId || "",
          Organization_name: row.organizationName || "",
          Organization_atc_id: row.organizationExternalId || "",
          Country: row.countryCode || "",
          City: row.city || "",
          Subject_class: row.subjectType || "",
          Subject_id: row.subjectId || "",
          Subject_name: row.subjectName || "",
          Subject_package_id: row.packageId || "",
          Subject_package_name: row.packageName || "",
          Subject_collection_id: row.collectionId || "",
          Subject_collection_name: row.collectionName || "",
          User_agent: row.userAgent || "",
        };
        csvData.push(dataRow);
      });
    });
    csvData = _.sortBy(csvData, function (row) {
      return row["Created_at"];
    });

    return csvData;
  }

  private formatGeoChartData(data: IAnalyticsChartData, queryData: IAnalyticsQueryObject): IAnalyticsData[] {
    const analyticsData: IAnalyticsData[] = [];

    let eventName;

    switch (queryData.eventType) {
      case AnalyticsEventTypeEnum.CONTENT_VIEWED:
        eventName = "Views";
        break;
      case AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK:
        eventName = "Partner download link clicks";
        break;
      default:
        eventName = "Downloads";
    }

    const header = ["Version", eventName];
    analyticsData.push(header);
    const sortedData: IAnalyticsChartDataRow[] = this.sortDataDesc(data);

    _.each(sortedData, (analyticsRow: IAnalyticsChartDataRow) => {
      const countryName = CountryCodeConverter.getCountryNameByCode(analyticsRow.countryValue as string);
      if (countryName) {
        const country = { v: analyticsRow.countryValue, f: countryName };
        const row = [country, analyticsRow.count];
        analyticsData.push(row);
      }
    });
    return analyticsData;
  }

  private formatLineChartData(data: IAnalyticsLineChartData, queryData: IAnalyticsQueryObject): IAnalyticsData[] {
    const itemKey = queryData.groupBy + "Id";
    const itemValue = queryData.groupBy + "Value";
    const keyValues = this.getLineChartKeyValues(data, itemKey);
    const chartData: IAnalyticsData[] = [];
    const columns: any[] = [];

    if (queryData.timeInterval === AnalyticsTimeIntervalEnum.HOUR) columns.push({ type: "datetime", label: "Day" });
    else columns.push({ type: "date", label: "Day" });

    _.each(keyValues, function (keyValue) {
      columns.push(keyValue[itemValue]);
    });
    columns.push(this.resolveSummaryTitle());
    chartData.push(columns);

    _.each(data, (analyticsRow, analyticsKey) => {
      let allVersionsSum = 0;
      if (analyticsKey !== "id" && analyticsKey !== "title" && analyticsKey !== "name") {
        const keysToInsert: any[] = [];
        keysToInsert.push({ v: this.formatStringToDate(analyticsKey) });
        _.each(keyValues, (keyValue) => {
          const rowObj = this.getObjectFromArray(analyticsRow as IAnalyticsLineChartDataRow[], keyValue, itemKey);
          if (!rowObj || rowObj.length === 0) {
            keysToInsert.push(0);
          } else {
            keysToInsert.push(rowObj[0].count);
            allVersionsSum = allVersionsSum + rowObj[0].count;
          }
        });
        keysToInsert.push(allVersionsSum);
        chartData.push(keysToInsert);
      }
    });
    return chartData;
  }

  private formatOrgTableChartData(data: IAnalyticsChartData): IAnalyticsTableChartData[] {
    const tableChartData: IAnalyticsTableChartData[] = [];
    const sortedAnalytics = _.sortBy(data.aggregates, function (analyticsObj) {
      return analyticsObj.organizationValue;
    });
    _.each(sortedAnalytics, (analyticsRow) => {
      tableChartData.push({
        type: "SUMMARY",
        value: analyticsRow.organizationValue + " (" + analyticsRow.packageValue + ")",
      });
    });
    return tableChartData;
  }

  private formatStatisticsData(data: IAnalyticsLineChartData, queryData: IAnalyticsQueryObject): IAnalyticsData[] {
    let allViewsSum = 0;
    let allDownloadsSum = 0;
    const keyValues = ["TWH_CONTENT_VIEWED", "TWH_VERSION_DOWNLOAD_COMPLETE"];
    const chartData: IAnalyticsData[] = [];
    const columns: any[] = [];

    if (queryData.timeInterval === AnalyticsTimeIntervalEnum.HOUR) columns.push({ type: "datetime", label: "Day" });
    else {
      columns.push({ type: "date", label: "Day" });
    }

    columns.push(this.rootStore.getTranslation("downloads_graph.total_views"));
    columns.push(this.rootStore.getTranslation("downloads_graph.total_downloads"));
    chartData.push(columns);

    _.each(this.sortReverse(data), (analyticsRow) => {
      if (analyticsRow[0] !== "id" && analyticsRow[0] !== "name") {
        const keysToInsert: any[] = [];
        keysToInsert.push({ v: this.formatStringToDate(analyticsRow[0]) });
        _.each(keyValues, function (keyValue) {
          const rowObj = _.filter(analyticsRow[1], function (row) {
            return row["eventTypeValue"] === keyValue;
          });
          if (rowObj && rowObj.length > 0) {
            if (keyValue === "TWH_CONTENT_VIEWED") {
              allViewsSum = allViewsSum + rowObj[0].count;
            } else if (keyValue === "TWH_VERSION_DOWNLOAD_COMPLETE") {
              allDownloadsSum = allDownloadsSum + rowObj[0].count;
            }
          }
        });
        keysToInsert.push(allViewsSum);
        keysToInsert.push(allDownloadsSum);
        chartData.push(keysToInsert);
      }
    });
    return chartData;
  }

  /**
   * Converts string to date and formats it
   * @params date Date in string format
   * @returns Formatted date object
   */
  private formatStringToDate(date: string): Date {
    return DateTime.fromSQL(date).toLocal().toJSDate();
  }

  private formatTableChartData(data: IAnalyticsChartData): IAnalyticsTableChartData[] {
    const tableChartData: IAnalyticsTableChartData[] = [];
    let prevItemValue = "";
    const sortedAnalytics = _.sortBy(data.aggregates, (analyticsObj: IAnalyticsChartDataRow) => {
      return analyticsObj.organizationValue;
    });
    _.each(sortedAnalytics, (analyticsRow: IAnalyticsChartDataRow) => {
      if (analyticsRow.itemValue !== prevItemValue) {
        prevItemValue = analyticsRow.itemValue;
        tableChartData.push({
          type: "HEADER",
          value: this.rootStore.getTranslation("shared.analytics.version_title_func", _.escape(analyticsRow.itemValue)),
        });
      }
      tableChartData.push({
        type: "SUMMARY",
        value: this.rootStore.getTranslation(
          "shared.analytics.downloads_count_func",
          _.escape(analyticsRow.organizationValue),
          _.escape(analyticsRow.count.toString()),
        ),
      });
    });
    return tableChartData;
  }

  private getExportOptions(): IAnalyticsExportOptions {
    return {
      filename: this.formatDateToString(DateTime.now().toJSDate()),
      fieldSeparator: ",",
      useKeysAsHeaders: true,
    };
  }

  /**
   * Gets key values to be used while formatting line chart data
   * @params data Aanalytics data
   * @params itemKey The key to be used to resolve if item has been already added to array
   * @returns Array of line chart data rows
   */
  private getLineChartKeyValues(data: IAnalyticsLineChartData, itemKey: string): IAnalyticsLineChartDataRow[] {
    const allKeys: IAnalyticsLineChartDataRow[] = [];
    _.each(data, (analyticsData, key) => {
      if (key !== "id" && key !== "title" && key !== "name" && _.isArray(analyticsData)) {
        _.each(analyticsData, (analyticsRow) => {
          const rowObj = this.getObjectFromArray(allKeys, analyticsRow, itemKey);
          if (!rowObj || rowObj.length === 0) {
            allKeys.push(analyticsRow);
          }
        });
      }
    });
    return allKeys;
  }

  /**
   * Gets key values to be used while formatting line chart data
   * @params array To be searched for object
   * @params object The object to be searched from array
   * @params key The object key to be used while comparing data
   * @returns Found object
   */
  private getObjectFromArray(
    array: IAnalyticsLineChartDataRow[],
    object: IAnalyticsLineChartDataRow,
    itemKey: string,
  ): IAnalyticsLineChartDataRow[] {
    const foundObjects: IAnalyticsLineChartDataRow[] = _.filter(array, function (row) {
      return row[itemKey] === object[itemKey];
    });

    return foundObjects;
  }

  /**
   * Resolves event type string to be used in query
   * @params eventType The event type to be used for filtering
   * @returns event type
   */
  private resolveEventType(eventType: string): AnalyticsEventTypeEnum {
    if (eventType === AnalyticsEventTypeEnum.DOWNLOADS) {
      return AnalyticsEventTypeEnum.VERSION_DOWNLOAD_COMPLETE;
    } else if (eventType === AnalyticsEventTypeEnum.VIEWS) {
      return AnalyticsEventTypeEnum.CONTENT_VIEWED;
    } else if (eventType === AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK) {
      return AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK;
    }
    return AnalyticsEventTypeEnum.DOWNLOADS;
  }

  /**
   * Resolves group by value to be used on query
   * @returns resolved group by value
   */
  private resolveGroupBy(): AnalyticsGroupByEnum {
    if (this.resource) {
      switch (this.resource.type) {
        case ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE:
          return AnalyticsGroupByEnum.ITEM;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION:
          return AnalyticsGroupByEnum.PACKAGE;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION:
          return AnalyticsGroupByEnum.COLLECTION;
      }
    }
    return AnalyticsGroupByEnum.ITEM;
  }

  private resolveMaxValueFromChartData(data: IAnalyticsData[]): number {
    let maxValue = 0;
    _.each(data, function (childArray) {
      _.each(childArray, function (columnVal) {
        if (typeof columnVal == "number") {
          if (columnVal > maxValue) {
            maxValue = columnVal;
          }
        }
      });
    });
    return maxValue;
  }

  /**
   * Resolves subject id used for filtering based on resource
   * @returns subject id
   */
  private resolveSubjectId(): string {
    return this.resource ? this.resource.id : "";
  }

  /**
   * Resolves subject type used for filtering based on resource type
   * @returns subject type
   */
  private resolveSubjectType(): AnalyticsSubjectTypeEnum {
    if (this.resource) {
      switch (this.resource.type) {
        case ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE:
          return AnalyticsSubjectTypeEnum.PACKAGE;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION:
          return AnalyticsSubjectTypeEnum.COLLECTION;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION:
          return AnalyticsSubjectTypeEnum.CREATOR;
      }
    }
    return AnalyticsSubjectTypeEnum.PACKAGE;
  }

  /**
   * Resolves summary title basend on resource type
   * @returns Resolved title
   */
  private resolveSummaryTitle(): string {
    if (this.resource) {
      switch (this.resource.type) {
        case ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE:
          return this.rootStore.getTranslation("shared.analytics.sum_all_versions");
        case ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION:
          return this.rootStore.getTranslation("shared.analytics.sum_all_packages");
        case ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION:
          return this.rootStore.getTranslation("shared.analytics.sum_all_collections");
      }
    }
    return "";
  }

  /**
   * Resolves ticks to be used with line chart
   * @params data Line chart data
   * @returns array of ticks to be used on line chart
   */
  private resolveTicksFromData(data: IAnalyticsData[]): number[] {
    let ticks: number[] = [];
    const maxValue = this.resolveMaxValueFromChartData(data);
    if (maxValue > 5000) {
      this.setTicks(ticks, maxValue, 1000);
    } else if (maxValue > 1000) {
      this.setTicks(ticks, maxValue, 500);
    } else if (maxValue > 100) {
      this.setTicks(ticks, maxValue, 50);
    } else if (maxValue > 10) {
      this.setTicks(ticks, maxValue, 5);
    } else if (maxValue > 5) {
      ticks = [0, 2, 4, 6, 8, 10];
    } else {
      ticks = [0, 1, 2, 3, 4, 5];
    }
    return ticks;
  }

  /**
   * Resolves start date
   * @returns start date value
   */
  private resolveTimeFrameObject(timeFrame: string, startDate: Date, endDate: Date): IAnalyticsTimeFrameObject {
    let start, end;
    if (timeFrame === AnalyticsTimeFrameEnum.CUSTOM) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59);
      return {
        startDate: startDate,
        endDate: endDate,
      };
    } else if (timeFrame === AnalyticsTimeFrameEnum.TODAY) {
      start = DateTime.now().startOf("day");
      end = start.plus({ days: 1 });
    } else if (timeFrame === AnalyticsTimeFrameEnum.THIS_WEEK) {
      start = DateTime.now().startOf("week");
      end = DateTime.now().endOf("week");
    } else if (timeFrame === AnalyticsTimeFrameEnum.THIS_MONTH) {
      start = DateTime.now().startOf("month");
      end = DateTime.now().endOf("month");
    } else if (timeFrame === AnalyticsTimeFrameEnum.THIS_YEAR) {
      start = DateTime.now().startOf("year");
      end = DateTime.now().endOf("year");
    } else if (timeFrame === AnalyticsTimeFrameEnum.BEGINNING_OF_TIME) {
      start = DateTime.fromFormat(this.analyticsBeginOfTime, "yyyy-MM-dd hh:mm:ss");
      end = DateTime.now().endOf("day");
    }
    return {
      startDate: start.toJSDate(),
      endDate: end.toJSDate(),
    };
  }

  /**
   * Resolves time interval to be used on query
   * @params queryData The data used on query
   * @returns Timeinterval value
   */
  @action
  private resolveTimeInterval(queryData: IAnalyticsQueryObject): AnalyticsTimeIntervalEnum {
    const start = DateTime.fromJSDate(new Date(queryData.startDate));
    const end = DateTime.fromJSDate(new Date(queryData.endDate));
    const diffInDays = end.diff(start, "days");
    if (diffInDays.days === 0) {
      this.timeInterval = AnalyticsTimeIntervalEnum.HOUR;
      return AnalyticsTimeIntervalEnum.HOUR;
    } else {
      this.timeInterval = AnalyticsTimeIntervalEnum.DAY;
      return AnalyticsTimeIntervalEnum.DAY;
    }
  }

  /**
   * Sets ticks to be used with line chart
   * @params ticks Array to set ticks to
   * @params maxValue The biggest value on line chart data
   * @params ticksInterval The interval that ticks should be sperated from each other
   */
  private setTicks(ticks: number[], maxValue: number, ticksInterval: number) {
    ticks.push(0);
    const result = maxValue / ticksInterval;
    const maxTickValue = Math.floor(result) + 1;
    for (let count = 1; count <= maxTickValue; count = count + 1) {
      ticks.push(count * ticksInterval);
    }
  }

  private shouldUseHourFormat() {
    return this.timeInterval === AnalyticsTimeIntervalEnum.HOUR;
  }

  private sortDataDesc(data: IAnalyticsChartData): IAnalyticsChartDataRow[] {
    const sortedData = _.sortBy(data.aggregates, (analyticsObj: IAnalyticsChartDataRow) => {
      return -analyticsObj.count;
    });
    return sortedData;
  }

  private sortReverse(data: IAnalyticsLineChartData): any[] {
    const sortedArray: any[] = [];
    for (const i in data) {
      sortedArray.push([i, data[i]]);
    }
    return sortedArray.reverse();
  }
}

export const AnalyticsStoreContext = createStoreContext<AnalyticsStore>(AnalyticsStore);

export const AnalyticsFiltersSetterContext = createContext<IAnalyticsFiltersSetter>(
  new AnalyticsStore(new RootStore()),
);
