import * as React from "react";
import { observer } from "mobx-react";
import { useEffect, useContext } from "react";

import { ResultOptionsStoreContext } from "../components/result-options/resultOptionsStore";
import { RootContext } from "../stores/rootStore";

import { Actions } from "../components/result-options/Actions";
import { Options } from "../components/result-options/Options";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { Results } from "./Results";

/**
 * A component that displays shopping cart.
 */
export const ShoppingCart = observer(() => {
  const rootStore = useContext(RootContext);
  const resultOptionsStore = useContext(ResultOptionsStoreContext);

  useEffect(() => {
    resultOptionsStore.fetchData();
  }, [rootStore.getUserStore().getCurrentUser()]);

  return (
    <div className="catalog">
      <div className="cart-header" data-testid="shopping-cart-title">
        <TranslatedHtml entry="mainNavigation.shoppingCart" />
      </div>
      <Actions />
      <Options />
      <Results />
    </div>
  );
});
