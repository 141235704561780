import * as React from "react";
import { useContext } from "react";

import { observer } from "mobx-react";
import classNames from "classnames";

import { SearchPageListStyleEnum } from "../../models/enums";
import { RootContext } from "../../stores/rootStore";

/**
 * A component that renders the two buttons used for switching list style.
 * @param pageWhereUsed the page where the buttons are used
 */
export const ListStyleToggle = observer(({ pageWhereUsed }: { pageWhereUsed?: string }) => {
  const rootStore = useContext(RootContext);
  const store = rootStore.getSearchStore().getSearchPageSettingsStore();

  function inThumbnailsMode() {
    return store.resultListStyle == SearchPageListStyleEnum.THUMBNAILS;
  }

  function inListMode() {
    return store.resultListStyle == SearchPageListStyleEnum.LIST;
  }

  const pageSpecificClass = pageWhereUsed ? `${pageWhereUsed}` + "-show-as" : "";

  function switchToListMode() {
    store.setResultListStyle(SearchPageListStyleEnum.LIST);
  }

  function switchToThumbnailsMode() {
    store.setResultListStyle(SearchPageListStyleEnum.THUMBNAILS);
  }

  return (
    <div className={"toggle-button show-as " + `${pageSpecificClass}`}>
      <button
        data-testid="listButton"
        className={classNames("icon", "list", { active: inListMode() })}
        title={rootStore.getTranslation("helpers.showAsList")}
        onClick={switchToListMode}
      />
      <button
        data-testid="thumbnailButton"
        className={classNames("icon", "icon-thumbnails", {
          active: inThumbnailsMode(),
        })}
        title={rootStore.getTranslation("helpers.showAsThumbs")}
        onClick={switchToThumbnailsMode}
      />
    </div>
  );
});
