import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";

import { RootContext } from "../stores/rootStore";
import { OrganizationPageStoreContext } from "./organizationPageStore";
import { EditorModeStoreContext } from "../components/editor-mode/editorModeStore";

import { EditorMode } from "../components/editor-mode/EditorMode";
import { OrganizationDescription } from "./dialogs/OrganizationDescription";
import { WarehouseUrl } from "./dialogs/WarehouseUrl";
import { OrganizationLogo } from "./dialogs/OrganizationLogo";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { ImmutableFlag } from "../components/immutability/ImmutableFlag";

import { Settings } from "../config/Settings";

const OrganizationNames = observer(() => {
  const rootStore = useContext(RootContext);
  const orgStore = useContext(OrganizationPageStoreContext);
  const organization = orgStore.getOrganization();
  const editorModeStore = useContext(EditorModeStoreContext);

  function optionalNameExists(): boolean {
    return organization!.displayName !== organization!.groupName;
  }

  return (
    <Fragment>
      <h1 data-testid="org-title">{organization!.displayName}</h1>
      <ImmutableFlag resource={organization!} />
      {optionalNameExists() && (
        <Fragment>
          {editorModeStore.getEditorMode() && (
            <section className="edit-group-name" data-testid="edit-name">
              <label
                className="tooltip-info"
                title={rootStore.getTranslation("organization.edit.edit_displayname_no_link")}
              >
                <span className="icon help blue" />
              </label>
              <TranslatedHtml entry="organization.edit.edit_displayname" args={[Settings.atc.adminEndpoint]} />
            </section>
          )}
          <section data-testid="org-title-2" className="group-name">
            {organization!.groupName}
          </section>
        </Fragment>
      )}
    </Fragment>
  );
});

/**
 * A component that displays organization details.
 */
export const Details = observer(() => {
  const orgStore = useContext(OrganizationPageStoreContext);

  return (
    <section className="content-details">
      {orgStore.canEditOrganization() && <EditorMode />}
      <header>
        <OrganizationNames />
        <WarehouseUrl />
        <OrganizationLogo />
        <OrganizationDescription />
      </header>
    </section>
  );
});
