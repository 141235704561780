import * as React from "react";
import { useContext, useEffect, Fragment } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";

import { RootContext } from "../../stores/rootStore";
import { LocalCollectionsContext, LocalCollectionsStore } from "./local/localCollectionsStore";
import { OnlineCollectionsContext, OnlineCollectionsStore } from "./online/onlineCollectionsStore";
import { OnlineCollections } from "./online/OnlineCollections";
import { LocalCollections } from "./local/LocalCollections";

export const MyCollections = observer(({ mode }: { mode: string }) => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.getUserStore();
  const localStore = new LocalCollectionsStore(rootStore);
  const onlineStore = new OnlineCollectionsStore(rootStore);

  const inLocalMode = mode === "local";
  const user = userStore.getCurrentUser();

  useEffect(() => {
    if (mode === "online") {
      onlineStore.fetchData();
    } else {
      localStore.fetchData();
    }
  });

  const renderLocalCollections = () => {
    return (
      <LocalCollectionsContext.Provider value={localStore}>
        <Fragment>
          {user && (
            <Link to="../collections" className="button show-online-collections">
              {rootStore.getTranslation("myCollections.online_collections")}
            </Link>
          )}
          {rootStore.getLocalServiceStore().isLocalServiceAccessible() && (
            <Link to="../map" className="button create">
              {rootStore.getTranslation("myCollections.local.actions.createNew")}
            </Link>
          )}
          <LocalCollections />
        </Fragment>
      </LocalCollectionsContext.Provider>
    );
  };

  const renderOnlineCollections = () => {
    return (
      <OnlineCollectionsContext.Provider value={onlineStore}>
        <Fragment>
          <Link to="../local" className="button show-local-collections">
            {rootStore.getTranslation("myCollections.local_collections")}
          </Link>
          <Link to="/collections/new" className="button create">
            {rootStore.getTranslation("myCollections.new_collection")}
          </Link>
          <OnlineCollections />
        </Fragment>
      </OnlineCollectionsContext.Provider>
    );
  };

  return (
    <article className="collections">{inLocalMode ? renderLocalCollections() : renderOnlineCollections()}</article>
  );
});
