import { action, makeObservable, observable, toJS } from "mobx";
import _ from "underscore";

import { RootStore } from "../../stores/rootStore";
import { UploadFormStore } from "../uploadFormStore";

import {
  ICollection,
  IDropdownOption,
  IFile,
  IGroupAndCategoryHandler,
  ISearchCriteriaHandler,
} from "../../models/dataModel";
import { ObjectTypeEnum, SanitizationModeEnum } from "../../models/enums";

import { checkTagsValidity, sanitize } from "../../utils/functions";
import { isUniqueCodeName, isValidCodeName } from "../../utils/Immutability";

export interface IEntityFormData {
  attributes: {
    itemTypeCategories: string[];
    useCategories: string[];
    licensesACL: string[];
    locationRestrictions: string[];
    supportedLanguages: string[];
    measurementUnits: string[];
    helpUrl: string;
    supportUrl: string;
    discussionForumUrl: string;
    specialTermsUrl: string;
    contentDownloadUrl: string;
  };
  codeName?: string;
  collection?: ICollection;
  copyrightInfo: string;
  description: string;
  isLocal?: boolean;
  richContent?: { description: string } | string;
  tags: string[];
  thumbnail?: { url: string; file?: IFile };
  title: string;
  type?: ObjectTypeEnum;
  visibility?: string;
}

export class UploadEntityStore implements IGroupAndCategoryHandler, ISearchCriteriaHandler {
  /** RootStore */
  private rootStore: RootStore;

  /** Store that holds upload form data */
  private form: UploadFormStore;

  /**
   * The entity object, stores given entity data.
   */
  @observable private entity: IEntityFormData = {
    attributes: {
      itemTypeCategories: [] as string[],
      useCategories: [] as string[],
      licensesACL: [] as string[],
      locationRestrictions: [] as string[],
      supportedLanguages: [] as string[],
      measurementUnits: [] as string[],
      helpUrl: "",
      supportUrl: "",
      discussionForumUrl: "",
      specialTermsUrl: "",
      contentDownloadUrl: "",
    },
    codeName: "",
    copyrightInfo: "",
    description: "",
    tags: [] as string[],
    title: "",
  };

  /** Selected search type category options */
  @observable private searchTypeCategories?: IDropdownOption[];

  /** Selected use category options */
  @observable private useCategories?: IDropdownOption[];

  /** Selected location restriction options */
  @observable private locationRestrictions?: IDropdownOption[];

  /** Selected supported language options */
  @observable private supportedLanguages?: IDropdownOption[];

  /** Selected measurement unit options */
  @observable private measurementUnits?: IDropdownOption[];

  /** Stores the contents of the details field */
  @observable private details = "";

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore, form: UploadFormStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.form = form;
  }

  /** Returns the entity object */
  public getEntity() {
    return this.entity;
  }

  /** Returns the entity title */
  public getTitle() {
    return this.entity.title;
  }

  /** Sets the title for entity and saves it to the form store. */
  @action
  public setTitle(title: string) {
    this.entity.title = title;
    this.form.setEntityTitle(title);
  }

  /** Gets the entity description */
  public getDescription(): string {
    return this.entity.description!;
  }

  /** Sets a description for the entity */
  @action
  public setDescription(description: string) {
    this.entity.description = description;
  }

  /** Gets the entity code name */
  public getCodeName(): string | undefined {
    return this.entity.codeName;
  }

  /** Sets a code name for the entity */
  @action
  public setCodeName(codeName: string) {
    this.entity.codeName = codeName;
  }

  /** Sets the partner download url */
  @action
  public addPartnerDownloadURL(url: string) {
    _.extend(this.entity.attributes, { ...this.entity.attributes, contentDownloadUrl: url });
  }

  /** Gets the partner download url */
  public getPartnerDownloadURL(): string | undefined {
    return this.entity.attributes.contentDownloadUrl;
  }

  /** Sets a thumbnail for the entity */
  @action
  public setThumbnail(thumbnail) {
    this.entity = _.extend(this.entity, { thumbnail: thumbnail });
  }

  /** Returns the entity thumbnail. */
  public getThumbnail() {
    return this.entity.thumbnail;
  }

  /** Returns the selected dropdown options for search type categories */
  public getSearchTypeCategories(): IDropdownOption[] {
    return toJS(this.searchTypeCategories) || [];
  }

  /** Sets search type categories for entity and saves the selected dropdown options. */
  @action
  public setSearchTypeCategories(selectedOptions: IDropdownOption[]) {
    this.searchTypeCategories = selectedOptions;
    const categories = (selectedOptions || []).map((opt) => opt.value);
    this.entity.attributes!.itemTypeCategories = categories;
  }

  /** Returns the selected dropdown options for use categories */
  public getUseCategories(): IDropdownOption[] {
    return toJS(this.useCategories) || [];
  }

  /** Sets use categories for entity and saves the selected dropdown options. */
  @action
  public setUseCategories(selectedOptions: IDropdownOption[]) {
    this.useCategories = selectedOptions;
    const categories = (selectedOptions || []).map((opt) => opt.value);
    this.entity.attributes!.useCategories = categories;
  }

  /**
   * Checks the validity of tags.
   */
  public areTagsValid(): boolean {
    if (this.entity.tags!.length === 0) {
      return true;
    } else {
      return checkTagsValidity(this.entity.tags!.slice());
    }
  }

  /** Returns a list of tags for the entity */
  public getTags(): string[] {
    return this.entity.tags!;
  }

  /** Sets tags for the entity */
  @action
  public setTags(tags: string[]) {
    this.entity.tags = tags;
  }

  /** Returns the selected dropdown options for location restrictions */
  public getLocationRestrictions(): IDropdownOption[] {
    return toJS(this.locationRestrictions) || [];
  }

  /** Sets location restrictions for entity and saves the selected dropdown options. */
  @action
  public setLocationRestrictions(selectedOptions: IDropdownOption[]) {
    this.locationRestrictions = selectedOptions;
    const values = (selectedOptions || []).map((opt) => opt.value);
    this.entity.attributes!.locationRestrictions = values;
  }

  /** Returns the selected dropdown options for supported languages */
  public getSupportedLanguages(): IDropdownOption[] {
    return toJS(this.supportedLanguages) || [];
  }

  /** Sets supported langugages for entity and saves the selected dropdown options. */
  @action
  public setSupportedLanguages(selectedOptions: IDropdownOption[]) {
    this.supportedLanguages = selectedOptions;
    const values = (selectedOptions || []).map((opt) => opt.value);
    this.entity.attributes!.supportedLanguages = values;
  }

  /** Returns the selected dropdown options for measurement unitss */
  public getMeasurementUnits(): IDropdownOption[] {
    return toJS(this.measurementUnits) || [];
  }

  /** Sets measurement units for entity and saves the selected dropdown options. */
  @action
  public setMeasurementUnits(selectedOptions: IDropdownOption[]) {
    this.measurementUnits = selectedOptions;
    const values = (selectedOptions || []).map((opt) => opt.value);
    this.entity.attributes!.measurementUnits = values;
  }

  /** Returns the help url */
  public getHelpUrl(): string {
    return this.entity.attributes!.helpUrl!;
  }

  /** Sets a help url for the entity. */
  @action
  public setHelpUrl(url: string) {
    this.entity.attributes!.helpUrl = url;
  }

  /** Returns the support url */
  public getSupportUrl(): string {
    return this.entity.attributes!.supportUrl!;
  }

  /** Sets a support url for the entity */
  @action
  public setSupportUrl(url: string) {
    this.entity.attributes!.supportUrl = url;
  }

  /** Returns the discussion forum url */
  public getDiscussionForumUrl(): string {
    return this.entity.attributes!.discussionForumUrl!;
  }

  /** Sets a discussion forum url for the entity */
  @action
  public setDiscussionForumUrl(url: string) {
    this.entity.attributes!.discussionForumUrl = url;
  }

  /** Returns the copyright information */
  public getCopyrightInfo(): string {
    return this.entity.copyrightInfo!;
  }

  /** Sets copyright information for the entity */
  @action
  public setCopyrightInfo(info: string) {
    this.entity.copyrightInfo = info;
  }

  /** Returns the special terms url */
  public getSpecialTermsUrl(): string {
    return this.entity.attributes!.specialTermsUrl!;
  }

  /** Sets a special terms url for the entity */
  @action
  public setSpecialTermsUrl(url: string) {
    this.entity.attributes!.specialTermsUrl = url;
  }

  /** Returns a list of available licences for the entity */
  public getAvailableLicenses() {
    return [
      {
        id: "teklamaintenance",
        text: this.rootStore.getTranslation("shared.maintenance.download_requires_tekla_maintenance"),
      },
    ];
  }

  /**
   * Adds a licence for entity
   * @param license id of the license
   */
  @action
  public addLicense(license: string) {
    this.entity.attributes!.licensesACL!.push(license);
  }

  /**
   * Removes a licence from entity
   * @param license id of the license
   */
  @action
  public removeLicense(license: string) {
    this.entity.attributes!.licensesACL = _.without(this.entity.attributes!.licensesACL, license);
  }

  /**
   * Checks if given license exists for the entity
   * @param license id of the license
   */
  public hasLicense(license: string) {
    return this.entity.attributes!.licensesACL!.includes(license);
  }

  /** Returns content of the details field. */
  public getDetails(): string {
    return this.details;
  }

  /** Sets the content of the details field. */
  @action
  public setDetails(input: string) {
    this.details = input;
  }

  /**
   * Checks if all necessary information has been given about the entity.
   */
  public async isFormValid() {
    this.runSecurityFilter();

    return (
      this.entity.title.length >= this.form.getValidations().package.title.minLength &&
      this.entity.description.length >= this.form.getValidations().package.description.minLength &&
      (await this.codeNameNotRequiredOrRequiredAndValid()) &&
      this.areTagsValid()
    );
  }

  /**
   * Checks if the code name of the entity is unique.
   * @returns A promise that resolves to a boolean indicating whether the code name is unique or not.
   */
  public async isCodeNameUnique(): Promise<boolean> {
    return !!this.entity.codeName ? isUniqueCodeName(this.entity.codeName) : false;
  }

  /**
   * Checks if the code name of the entity is valid.
   * @returns A boolean indicating whether the code name is valid or not.
   */
  public isCodeNameInCorrectFormat(): boolean {
    return !!this.entity.codeName ? isValidCodeName(this.entity.codeName) : false;
  }

  private async codeNameNotRequiredOrRequiredAndValid(): Promise<boolean> {
    if (this.form.isImmutable()) {
      return (
        !!this.entity.codeName &&
        this.entity.codeName.length > 0 &&
        (await this.isCodeNameUnique()) &&
        this.isCodeNameInCorrectFormat()
      );
    } else {
      return true;
    }
  }

  /**
   * Runs the security filter for entity description and details field.
   */
  @action
  private runSecurityFilter() {
    if (this.entity.description) {
      this.entity.description = sanitize(this.entity.description, SanitizationModeEnum.MODERATE);
    }
    if (this.details) {
      this.details = sanitize(this.details, SanitizationModeEnum.WEAK);
    }
  }
}
