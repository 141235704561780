import * as React from "react";
import { useContext, useState, Fragment, CSSProperties } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootStore, RootContext } from "../../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../../uploadFormStore";
import { UploadVersionStore } from "../uploadVersionStore";

import { I3DGeometryFile, IDropdownOption } from "../../../models/dataModel";
import { Dropdown } from "../../../components/Dropdown";

/** Handles custom attribute fields and their input */
const CustomAttribute = ({ file, column }: { file: I3DGeometryFile; column: string }) => {
  function handleInput(event) {
    file[column] = event.target.value;
  }

  return (
    <input
      type="text"
      onChange={handleInput}
      data-testid={column + "Field"}
      placeholder={column}
      value={file[column]}
    />
  );
};

/** Renders the custom attributes for selected file row. */
const CustomAttributes = observer(({ file }: { file: I3DGeometryFile }) => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  return (
    <Fragment>
      {version.getCustomAttributesFor3DGeometryFile().map((column: string, i: number) => {
        return (
          <td key={i}>
            <CustomAttribute file={file} column={column} />
          </td>
        );
      })}
    </Fragment>
  );
});

/** Component for a single 3D geometry file row */
const FileRow = observer(({ file }: { file: I3DGeometryFile }) => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  function handleChangeToField(event, field: string) {
    version.edit3DGeometryFile(file.id, field, event.target.value);
  }

  function handleAddFile(event, field: string) {
    version.edit3DGeometryFile(file.id, field, _.first(event.target.files));
  }

  const weightUnitOptions: IDropdownOption[] = [
    { value: "kg", label: "kg" },
    { value: "lb", label: "lb" },
  ];

  const selectedWeightUnit = !!file && _.find(weightUnitOptions, (option: IDropdownOption) => {
    return option.value === file.weightUnit;
  });

  function handleSelectUnit(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;

    version.edit3DGeometryFile(file.id, "weightUnit", selectedOption.value);

    const kg = 0.45359237;
    if (selectedOption.value === "lb") {
      version.edit3DGeometryFile(file.id, "productWeight", file.productWeight / kg);
    } else {
      version.edit3DGeometryFile(file.id, "productWeight", file.productWeight * kg);
    }
  }

  function removeFile() {
    version.removeSelectedFile(file, "ThreeDGeometryFiles");
  }

  const noMargin: CSSProperties = {
    margin: 0,
  };

  const thumbnailName = !!file?.thumbnailFile ? file.thumbnailFile.name : "";
  const geometryfileName = !!file?.geometryFile ? file.geometryFile.name : "";

  return (
    <Fragment>
      <td className="geometryFile">
        <div>
          <p style={noMargin}>{geometryfileName}</p>
          <input
            type="file"
            accept=".skp, .tsc, .dxf, .dwg, .ifc, .ircZIP, .ifcXML, .igs, .iges, .stp, .step, .dgn"
            onChange={(e) => handleAddFile(e, "geometryFile")}
            data-testid="uploadGeometry"
          />
        </div>
      </td>
      <td className="thumbnailFile">
        <div>
          <p style={noMargin}>{thumbnailName}</p>
          <input
            type="file"
            accept="image/bmp"
            data-testid="uploadThumbnail"
            onChange={(e) => handleAddFile(e, "thumbnailFile")}
            required
          />
        </div>
      </td>
      <td className="title">
        <input
          type="text"
          onChange={(e) => handleChangeToField(e, "title")}
          placeholder={rootStore.getTranslation("uploader.placeholders.title")}
          value={file.title}
          data-testid="title"
        />
      </td>
      <td className="productCode">
        <input
          type="text"
          onChange={(e) => handleChangeToField(e, "productCode")}
          placeholder={rootStore.getTranslation("uploader.placeholders.productCode")}
          value={file.productCode}
          data-testid="productCode"
        />
      </td>
      <td className="productWeight">
        <input
          type="number"
          onChange={(e) => handleChangeToField(e, "productWeight")}
          placeholder={rootStore.getTranslation("uploader.placeholders.productWeight")}
          value={file.productWeight}
          data-testid="productWeight"
        />
      </td>
      <td className="weightUnit">
        <Dropdown
          onChange={handleSelectUnit}
          options={weightUnitOptions}
          placeholder={rootStore.getTranslation("versions.select_unit")}
          selectedValue={selectedWeightUnit}
          className="dropdown-wrapper gray"
        />
      </td>
      <td className="material">
        <input
          type="text"
          onChange={(e) => handleChangeToField(e, "material")}
          placeholder={rootStore.getTranslation("uploader.placeholders.material")}
          value={file.material}
          data-testid="material"
        />
      </td>
      <CustomAttributes file={file} />
      <td className="auto-width">
        <a className="icon icon-trash" onClick={removeFile} data-testid="removeFile">
          {rootStore.getTranslation("actions.remove")}
        </a>
      </td>
    </Fragment>
  );
});

/**
 * Wrapper components for rendering the file list.
 */
const FileList = observer(() => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  return (
    <tbody>
      {version.get3DGeometryFiles().map((file: I3DGeometryFile, i: number) => {
        return (
          <tr key={i}>
            <FileRow file={file} />
          </tr>
        );
      })}
    </tbody>
  );
});

/** Component for the custom attribute input field and button. */
const AddCustomAttribute = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const [columnName, setColumnName] = useState("");

  function canAddColumn(): boolean {
    return (
      columnName !== "" &&
      !_.any(version.getCustomAttributesFor3DGeometryFile(), (column) => {
        return column === columnName;
      })
    );
  }

  function addColumn(event) {
    event.preventDefault();
    if (canAddColumn()) {
      version.add3DGeometryFileFields(columnName);
      setColumnName("");
    }
  }

  function handleNameInput(event) {
    setColumnName(event.target.value);
  }

  return (
    <Fragment>
      <button className="l-right l-no-margin" disabled={!canAddColumn()} onClick={addColumn}>
        {rootStore.getTranslation("upload.3dGeometry.buttonAdd")}
      </button>
      <div className="l-margin-default l-right" />
      <input
        className="less-wide small l-right l-no-margin"
        type="text"
        onChange={handleNameInput}
        placeholder={rootStore.getTranslation("upload.3dGeometry.addCustomAttributePlaceholder")}
        value={columnName}
        data-testid="customColumnInput"
      />
    </Fragment>
  );
});

/**
 * Main component for importing 3D geometry files.
 */
export const Import3DGeometryFile = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const columns = ["geometryFile", "thumbnailFile", "title", "productCode", "productWeight", "weightUnit", "material"];

  function removeColumn(column: string) {
    version.remove3DGeometryFileFields(column);
  }

  function add3DGeometryFile(event) {
    event.preventDefault();
    version.createBlank3DGeometryFile();
  }

  return (
    <div data-testid="3DGeometryFiles">
      <header>
        <h3>{rootStore.getTranslation("upload.3dGeometry.title")}</h3>
      </header>
      <div className="wrapper full-width-center">
        <form name="import3dGeometryFileForm" noValidate>
          <AddCustomAttribute />
          <div className="scrollable-table l-bmargin-0 l-bpadding-0">
            <table className="mass-uploader">
              <thead>
                <tr>
                  {columns.map((column: string, i: number) => {
                    return (
                      <th key={i}>
                        <span data-testid={column + "Header"}>
                          {rootStore.getTranslation("uploader.headings." + column)} *
                        </span>
                      </th>
                    );
                  })}
                  {version.getCustomAttributesFor3DGeometryFile().map((column: string, i: number) => {
                    return (
                      <th key={i}>
                        <span data-testid={column + "Header"}>{column}</span>
                        <a
                          className="icon icon-trash"
                          onClick={() => removeColumn(column)}
                          data-testid={column + "Remove"}
                        />
                      </th>
                    );
                  })}
                  <th className="auto-width" />
                </tr>
              </thead>
              <FileList />
            </table>
          </div>
          <button className="l-right l-no-margin" onClick={add3DGeometryFile}>
            {rootStore.getTranslation("upload.3dGeometry.buttonAddEntity")}
          </button>
        </form>
      </div>
    </div>
  );
});
