 
export const HuAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["hu-HU"]) {
      window.locale["hu-HU"] = {};
    }

    window.locale["hu-HU"].about = {
      navigation: {
        why: "Miért a Tekla Warehouse?",
        usage_basics: "Tekla Warehouse – alapok",
        "usage_basics__using-content": "A tartalmak felhasználása",
        "usage_basics__adding-content": "Tartalom hozzáadása",
        usage_basics__collection: "Gyűjtemény",
        "usage_basics__content-item": "Tartalomelem",
        usage_basics__version: "Verzió",
        getting_started: "Kezdetek",
        "getting_started__tekla-account-and-system-requirements": "Tekla account és rendszerkövetelmények",
        "getting_started__tekla-warehouse-service-plugin": "A Tekla Warehouse Service beépülő modul",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Hogy szerezhető be a Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures és Tekla Warehouse",
        content: "A Tekla Warehouse tartalmai",
        "content__3d-products": "3D-modelltermékek",
        "content__custom-components": "Felhasználói komponensek",
        content__applications: "Alkalmazások",
        "content__profiles-shapes-etc": "Szelvények, anyagok, csavarok, vasalások stb.",
        "content__model-templates": "Modellsablonok",
        "content__drawing-setup": "Rajzbeállítás",
        "content__report-templates-etc": "Listasablonok és attribútumfájlok",
        using_content: "A tartalmak felhasználása",
        creating_content: "Tartalom létrehozása",
        "creating_content__getting-started-with-content-creation": "A tartalom létrehozásának első lépései",
        "creating_content__expert-help-for-creating-content":
          "Szakértői segítségre van szüksége a tartalom létrehozásához?",
        application_developers: "Alkalmazásfejlesztés Tekla Structures rendszerhez",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Miért a Tekla Warehouse?",
        description_1:
          "A Tekla Warehouse alkalmazásokat, felhasználói komponenseket, elemeket, szelvényeket, anyagokat (pl. acél- és betonminőségeket), csavarokat, vasalásokat, hálókat, kontúrokat és sablonokat tartalmaz a Tekla Structures rendszerhez. A modellekben valódi szállítóktól származó elemek találhatók, ami maximális precizitást és pontos anyaginformációkat biztosít a tervezéshez és a beszerzéshez, az előkészített tartalom használata pedig felgyorsítja a modellezést.",
        description_2:
          "A Tekla Warehouse egy helyről biztosít hozzáférést ezekhez a tartalmakhoz, amelyeket most még gördülékenyebben lehet használatba venni. A Tekla Warehouse kínálata folyamatos növekszik.",
        description_3: "A Tekla Warehouse-zal",
        feature_1:
          "A gyártók elérhetővé tehetik termékeiket és alkalmazásaikat a felhasználók számára, akik a pontos háromdimenziós termékmodelleket közvetlenül a saját Tekla-modelljeikbe illeszthetik be, a fizikai terméket pedig később, a tényleges építkezési munkálatok során is felhasználhatják.",
        feature_2:
          "A vállalati hálózaton megoszthatja a tartalmakat a munkatársaival, és az adatokat helyi számítógépre, személyes használatra is mentheti",
        description_4:
          "A Tekla Warehouse keresőjével tartalomtípustól függetlenül könnyen megtalálhatja, amire szüksége van.",
        description_5:
          "A helyi vagy a hálózati gyűjtemények engedélyezéséhez a Tekla Warehouse Service beépülő modulra van szükség.",
        view_licences_html:
          '<a href="/api/third_party_licenses.txt" target="_blank">A harmadik féltől származó licencek itt tekinthetők meg</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Tekla Warehouse – alapok",
        using_content: {
          title: "A tartalmak felhasználása",
          description_1:
            "Felhasználhatja az online gyűjteményekben szereplő tartalmakat, a hálózati gyűjtemények segítségével tartalmakat oszthat meg kollégáival, és helyi gyűjteményeket tarthat fenn személyes használatra.",
          description_2:
            "A helyi és a hálózati gyűjtemények használatához a Tekla Warehouse Service szükséges, amely a Tekla Structures 20.1 Service Release 1 és az újabb Tekla Structures-verziók használata esetén települ.",
          description_3:
            "A Tekla Structures telepítésekor honosított környezeti tartalmakat tartalmazó Tekla-gyűjtemények is kerülnek a számítógépre.",
          description_4: "Erről A tartalmak felhasználása című részben talál további információt.",
        },
        adding_content: {
          title: "Tartalom hozzáadása",
          description_1:
            'Az online gyűjtemények segítségével a tartalmak a Tekla Warehouse összes felhasználója számára közzétehetők. Ha egy szervezet nevében szeretne tartalmat közzétenni, a szervezetet regisztrálni kell az account.tekla.com webhelyen. Erről a <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">Tekla helyi kirendeltségétől vagy a viszonteladóktól</a> kaphat további információt.',
          description_2: "További tudnivalók a Tartalom létrehozása című részben találhatók.",
        },
        collection: {
          title: "Gyűjtemény",
          feature_1:
            "A gyűjtemények a tartalomelemek (például mappák) csoportosítására szolgálnak; a használatukkal meghatározható a tartalomhoz szükséges hozzáférési jogosultság (nyilvános/privát).",
          feature_1_1:
            "A modellezők igénybe vehetik az online gyűjteményekben szereplő tartalmakat, a hálózati gyűjtemények segítségével tartalmakat oszthatnak meg kollégáikkal, és helyi gyűjteményeket tarthatnak fenn személyes használatra.",
          feature_2:
            "A tartalomszolgáltatók az online gyűjtemények segítségével a Tekla Warehouse összes felhasználója számára közzétehetik a tartalmaikat.",
          feature_3:
            "A helyi és a hálózati gyűjtemények használatához a Tekla Warehouse Service beépülő modul szükséges, amely a Tekla Structures 20.1 Service Release 1 és az újabb verziók használata esetén települ, illetve külön telepíthető.",
        },
        "content-item": {
          title: "Tartalomelem",
          feature_1:
            "A tartalomelem fájlok logikai csoportja. Lehet például háromdimenziós termékek katalógusa, modellsablon, eszköz, felhasználói komponensek csoportja vagy szelvénykatalógus.",
          feature_2:
            "A tartalomelemek típus és használati eset szerint címkézhetők, így megkönnyítik a felhasználók számára a tartalom megtalálását.",
        },
        version: {
          title: "Verzió",
          feature_1:
            "A tartalomelemek több verzióval is rendelkezhetnek, amelyek a Tekla Structures különböző verzióiban is használhatók. A felhasználó a megfelelő verziót a tartalomelem oldaláról választhatja ki.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Kezdetek",
        tekla_account_and_system_requirements: {
          title: "Tekla account és rendszerkövetelmények",
          register_atc_html:
            'A Tekla Warehouse rendszerbe való bejelentkezéshez Tekla account szükséges. Ha még nem tette meg, regisztráljon az <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a> webhelyen. (Megjegyzés: ez nem a régi extranetes fiók.)',
          see_tua_for_prerequisites_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">a Tekla Warehouse használatának előfeltételeiről</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "A Tekla Warehouse Service beépülő modul",
          description_1: "A Tekla Warehouse nemcsak online tartalomtár, hanem további lehetőségeket is kínál:",
          feature_1: "Helyi gyűjtemények személyes használatra",
          feature_2: "Hálózati gyűjtemények, amelyekkel tartalmakat oszthat meg munkatársaival a vállalati hálózaton",
          feature_3: "Egyszerű telepítés tartalom használata vagy létrehozása esetén",
          feature_4: "Helyi és hálózati tartalmak keresése",
          description_2:
            "Ezek a funkciók a Tekla Warehouse Service használatával érhetők el – ez olyan beépülő modul, amely a Tekla Structures és a Tekla Warehouse webhelyével kapcsolatot tartó helyi háttéralkalmazásként működik.",
          description_3:
            "Ha a Tekla Warehouse Service nincs telepítve vagy nem fut, a Tekla Warehouse az oldal felső részén jelzi a problémát, így lehetővé válik a hiba elhárítása.",
          description_4:
            "Ha a szolgáltatással probléma adódik, a Services programmal indíthatja újra a Windowsban. A szolgáltatás neve Tekla Warehouse. A böngésző biztonsági beállításait ugyancsak célszerű ellenőriznie.",
          description_5:
            "A Tekla Warehouse webhelye a Tekla Warehouse Service és a Tekla Structures nélkül is használható. A szolgáltatás ebben az esetben azonban inkább az általános tartalommegosztó webhelyekéhez lesz hasonló, ahol a fájlok fel- és letöltése manuálisan történik.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Hogy szerezhető be a Tekla Warehouse Service?",
          description_1:
            "A Tekla Warehouse Service beépülő modul a Tekla Structures 20.1 Service Release 1 és újabb verzióival települ. A Tekla Warehouse Service külön is telepíthető a helyi és a hálózati gyűjtemények engedélyezéséhez, a könnyített telepítési és feltöltési funkciók azonban csak a Tekla Structures 20.1 Service Release 1 és újabb verziókban működnek.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">A TEKLA WAREHOUSE SERVICE LETÖLTÉSE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures és Tekla Warehouse",
          description_1:
            "A Tekla Warehouse közvetlenül kapcsolódik a Tekla Structures rendszerhez, így megkönnyíti a tartalmak használatát és feltöltését. A tartalom közvetlenül a modellből is kiválasztható, és közvetlenül a modellbe is beilleszthető, a fájlok körülményesebb importálása, illetve exportálása nélkül (ehhez a funkcióhoz Tekla Structures 20.1 Service Release 1 vagy újabb verzió szükséges). Egy tartalomelemben, például felhasználói komponensben vagy szelvényben az összes szükséges fájl elhelyezhető úgy, hogy minden a kívánt módon működjön.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "A Tekla Warehouse tartalmai",
        description_1:
          "A Tekla Warehouse több tartalomtípust kínál. Az alábbiakban áttekintést adunk a különböző tartalomtípusokról, és olyan további információkat biztosítunk, amelyek segítenek megtenni az első lépéseket.",
        description_2:
          'A Tekla Warehouse rendszerből a <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla Extensions</a> is letölthető.',
        "3d_products": {
          title: "3D-modelltermékek",
          image_subtitle:
            "Ezek fix geometriai jellemzőkkel rendelkező objektumok, például szerelvények, zsaluzatok vagy kellékek.",
          tua_link_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">3D-termékkatalógusok Tekla Warehouse-gyűjteményekben történő létrehozásáról</a>.',
        },
        custom_components: {
          title: "Felhasználói komponensek",
          image_subtitle:
            "A felhasználói komponensek a Tekla Structures olyan natív objektumai, amelyek fix vagy parametrikus geometriai jellemzőkkel rendelkeznek.",
          tua_link_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">a Tekla Structures-modell tartalmainak Tekla Warehouse-gyűjteménybe történő feltöltéséről</a>.',
        },
        applications: {
          title: "Alkalmazások",
          image_subtitle:
            "Az alkalmazások a Tekla Open API használatával tartják a kapcsolatot a Tekla Structures rendszerrel. Az adatintegrációs összetevő például a tervezői megoldáshoz kapcsolódik.",
          tua_link_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">az alkalmazások Tekla Warehouse-gyűjteménybe való feltöltéséről</a>.',
        },
        profiles_shapes_etc: {
          title: "Szelvények, kontúrok, anyagok, csavargyártmányok, vasalások és hálók",
          image_subtitle:
            "A katalógusok elemekhez kapcsolódó tulajdonságlistákat tartalmaznak, például különböző anyagminőségeket és -sűrűségeket.",
          tua_link_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">a Tekla Structures-modell tartalmainak Tekla Warehouse-gyűjteménybe történő feltöltéséről</a>.',
        },
        model_templates: {
          title: "Modellsablonok",
          image_subtitle:
            "A modellsablonok hasonló, ismétlődő projektekhez használhatók. A sablon az összes szükséges katalógust, jelentéssablont, rajzbeállítást és speciális beállítást tartalmazhatja.",
          tua_link_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">a fájlok Tekla Warehouse-gyűjteményhez való manuális hozzáadásáról</a>.',
        },
        drawing_setup: {
          title: "Rajzbeállítás",
          image_subtitle:
            "A beállítási fájlok a rajzok létrehozási módját határozzák meg (például az elrendezéseket, a szűrőket és a klónozási sablonokat).",
          tua_link_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">a fájlok Tekla Warehouse-gyűjteményhez való manuális hozzáadásáról</a>.',
        },
        report_templates_etc: {
          title: "Listasablonok és modellattribútum-fájlok",
          description_1:
            "A listasablonok a modelladatok tartalmát és elrendezését határozzák meg a jelentésben. A modellattribútum-fájlok (például a szűrők) csak a modell mappájába helyezve működnek.",
          tua_link_html:
            'A Tekla User Assistance részen további információt talál <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">a fájlok Tekla Warehouse-gyűjteményhez való manuális hozzáadásáról</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "A tartalmak felhasználása",
        description_1:
          "Tartalomtípustól függően a kiválasztott tartalmat beillesztheti a Tekla Structures-modellbe, vagy letöltheti és telepítheti. További útmutató a Tekla User Assistance részen található",
        feature_1:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Tartalom keresése a Tekla Warehouse rendszerben</a>',
        feature_2:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Tekla Warehouse-tartalmak használata</a>',
        feature_3:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Tekla Warehouse-tartalom beszúrása Tekla Structures-modellbe</a>',
        feature_4:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Ütközések kezelése a Tekla Warehouse rendszerben</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Tartalom létrehozása",
        getting_started_with_content_creation: {
          title: "A tartalom létrehozásának első lépései",
          description_1: "A Tekla User Assistance részen további információkat talál a következőkről:",
          feature_1:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_getting_started" target="_blank">A Tekla Warehouse használatának megkezdése</a>',
          feature_2:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_adding_content" target="_blank">Tartalom hozzáadása a Tekla Warehouse rendszerhez</a>',
          feature_3:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Tekla Warehouse-tartalmak módosítása</a>',
          feature_4:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Tartalmak letöltése a Tekla Warehouse rendszerből</a>',
        },
        expert_help_for_creating_content: {
          title: "Szakértői segítségre van szüksége a tartalom létrehozásához?",
          description_1:
            'Ha tartalmakat szeretne közzétenni a Tekla Warehouse rendszerben, de szakértő segítségére van szüksége a tartalom létrehozásához és karbantartásához, az együttműködési lehetőségekkel kapcsolatban a <a href="http://www.tekla.com/contact" target="_blank">Tekla helyi kirendeltségétől vagy viszonteladójától</a> kaphat további információt.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Alkalmazásfejlesztés Tekla Structures rendszerhez",
        description_1:
          "A Tekla az épületinformációs modellezés (BIM) terén nyílt megközelítést alkalmaz, így a Tekla rendszereihez más szolgáltatók megoldásainak és gyártógépeinek használata esetén is kapcsolódhat.",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">A Tekla Open API</a> a Tekla specializált alkalmazásprogramozási felülete (API), amellyel alkalmazásokat és további funkciókat fejleszthet a Tekla Structures platformhoz, és a saját környezetébe integrálhatja őket. A Tekla Open API a Microsoft .NET technológiájára épül.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">A Tekla Developer Center</a> minden információt tartalmaz a Tekla Open API-ról.',
        description_4:
          'A <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a>hoz is csatlakozhat, ha részt szeretne venni a Tekla Structures-alkalmazások fejlesztésében.',
      },
    };
  },
};
