import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import { RootContext } from "../../stores/rootStore";
import { BatchTranslationsDialog } from "./BatchTranslationsDialog";
import { BatchTranslationsDialogStore, BatchTranslationsDialogStoreContext } from "./batchTranslationsDialogStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { DialogContext, DialogStore } from "../dialogStore";
import { Dialog } from "../Dialog";
/**
 * A component that displays edit translations button.
 */
export const BatchTranslations = observer(() => {
  const rootStore = useContext(RootContext);
  const dialog = new DialogStore();
  const editorModeStore = useContext(EditorModeStoreContext);
  const batchTranslationsDialogStore = new BatchTranslationsDialogStore(
    rootStore,
    editorModeStore.getAvailableCollections(),
    editorModeStore.getResource(),
  );

  function shouldButtonBeDisabled() {
    return editorModeStore.getAvailableCollections().length <= 0;
  }

  function openDialog() {
    dialog.open();
  }

  return (
    <Fragment>
      {editorModeStore.getEditorMode() && (
        <DialogContext.Provider value={dialog}>
          <div>
            <div className="batch-translations">
              <button
                data-testid="open-batch-translations-dialog"
                disabled={shouldButtonBeDisabled()}
                onClick={openDialog}
                className="button-primary l-left"
              >
                {rootStore.getTranslation("shared.translations.batch_translations")}
              </button>
            </div>
          </div>
          <BatchTranslationsDialogStoreContext.Provider value={batchTranslationsDialogStore}>
            <Dialog content={<BatchTranslationsDialog />} additionalClass="translations-export" />
          </BatchTranslationsDialogStoreContext.Provider>
        </DialogContext.Provider>
      )}
    </Fragment>
  );
});
