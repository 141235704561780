 
export const DeAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["de-DE"]) {
      window.locale["de-DE"] = {};
    }

    window.locale["de-DE"].about = {
      navigation: {
        why: "Warum Tekla Warehouse?",
        usage_basics: "Grundlagen zu Tekla Warehouse",
        "usage_basics__using-content": "Verwenden von Inhalten",
        "usage_basics__adding-content": "Hinzufügen von Inhalten",
        usage_basics__collection: "Sammlung",
        "usage_basics__content-item": "Inhaltselement",
        usage_basics__version: "Version",
        getting_started: "Erste Schritte",
        "getting_started__tekla-account-and-system-requirements": "Tekla account und Systemanforderungen",
        "getting_started__tekla-warehouse-service-plugin": "Tekla Warehouse Service-Plug-in",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Wo erhalte ich Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures und Tekla Warehouse",
        content: "Inhalte in Tekla Warehouse",
        "content__3d-products": "3D-Modellprodukte",
        "content__custom-components": "Benutzerdefinierte Komponenten",
        content__applications: "Anwendungen",
        "content__profiles-shapes-etc": "Profile, Materialien, Schrauben, Bewehrungen usw.",
        "content__model-templates": "Modellvorlagen",
        "content__drawing-setup": "Zeichnungssetup",
        "content__report-templates-etc": "Listenvorlagen und Attributdateien",
        using_content: "Verwenden von Inhalten",
        creating_content: "Erstellen von Inhalten",
        "creating_content__getting-started-with-content-creation": "Grundlagen zum Erstellen von Inhalten",
        "creating_content__expert-help-for-creating-content": "Expertenhilfe für das Erstellen von Inhalten",
        application_developers: "Anwendungsentwicklung für Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Warum Tekla Warehouse?",
        description_1:
          "Tekla Warehouse enthält Anwendungen, benutzerdefinierte Komponenten, Teile, Profile, Materialien wie Stahl- und Betongüten, Schrauben, Bewehrungen, Matten, Formen und Vorlagen für Tekla Structures. Die Verwendung echter Anbieterteile in Modellen führt zur höchsten Genauigkeit und exakten Materialinformationen für die Planung und Beschaffung. Außerdem beschleunigen gebrauchsfertige Inhalte die Modellierung.",
        description_2:
          "Tekla Warehouse bietet einen zentralen Zugang zu diesen Inhalten, die nun auf optimierte Weise genutzt werden können. Die Inhalte in Tekla Warehouse werden ständig erweitert.",
        description_3: "Mit Tekla Warehouse",
        feature_1:
          "können Hersteller eigene Anwendungen und Produkte bereitstellen, sodass Benutzer die genauen 3D-Produktmodelle direkt in Tekla-Modelle einbinden und später das richtige Produkt bei der Bauausführung verwenden können",
        feature_2:
          "können Sie im internen Firmennetz Inhalte mit Kollegen teilen und auch lokal speichern, um diese für den eigenen Bedarf zu nutzen",
        description_4: "Die Suche in Tekla Warehouse erleichtert das Auffinden der benötigten Inhaltstypen.",
        description_5:
          "Das Tekla Warehouse Service-Plug-in ist erforderlich, um lokale und Netzwerksammlungen zu aktivieren.",
        view_licences_html:
          '<a href="/api/third_party_licenses.txt" target="_blank">Drittanbieterlizenzen</a> anzeigen.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Grundlagen zu Tekla Warehouse",
        using_content: {
          title: "Verwenden von Inhalten",
          description_1:
            "Sie können die Inhalte aus Online-Sammlungen verwenden, Inhalte mit Kollegen über Netzwerksammlungen teilen oder lokale Sammlungen für den eigenen Bedarf nutzen.",
          description_2:
            "Für lokale und Netzwerksammlungen ist der Tekla Warehouse Service erforderlich, der zum Installationsumfang von Tekla Structures 20.1 Service Release 1 und neueren Versionen von Tekla Structures gehört.",
          description_3:
            "Bestimmte Tekla-Sammlungen mit lokalisierten Umgebungsinhalten werden mit Tekla Structures installiert.",
          description_4: "Einzelheiten finden Sie im Abschnitt Verwenden von Inhalten.",
        },
        adding_content: {
          title: "Hinzufügen von Inhalten",
          description_1:
            'Sie können Online-Sammlungen verwenden, um Inhalte für alle Tekla Warehouse-Benutzer zu veröffentlichen. Um Inhalte als Organisation zu veröffentlichen, muss Ihre Organisation zu account.tekla.com hinzugefügt werden. Wenden Sie sich für weitere Informationen an die <a href="https://www.tekla.com/de/kontakt/niederlassungen-und-partner" target="_blank">Tekla-Niederlassung oder den Händler</a> vor Ort.',
          description_2: "Einzelheiten finden Sie im Abschnitt Erstellen von Inhalten.",
        },
        collection: {
          title: "Sammlung",
          feature_1:
            "Mit Sammlungen fassen Sie Inhaltselemente zusammen (ähnlich wie Ordner) und regeln die Zugriffsrechte auf Inhalte (öffentlich/privat).",
          feature_1_1:
            "Modellierer können Inhalte aus Online-Sammlungen verwenden, Inhalte mit Kollegen über Netzwerksammlungen teilen oder lokale Sammlungen für den eigenen Bedarf nutzen.",
          feature_2:
            "Inhaltsanbieter können eigene Inhalte mithilfe von Online-Sammlungen für alle Tekla Warehouse-Benutzer veröffentlichen.",
          feature_3:
            "Für lokale und Netzwerksammlungen wird das Tekla Warehouse Service-Plug-in benötigt, das zum Installationsumfang von Tekla Structures 20.1 Service Release 1 und höher gehört. Es kann auch separat installiert werden.",
        },
        "content-item": {
          title: "Inhaltselement",
          feature_1:
            "Ein Inhaltselement ist eine logische Gruppe von Dateien. So sind beispielsweise ein 3D-Produktkatalog, eine Modellvorlage, ein Werkzeug, eine Gruppe benutzerdefinierter Komponenten und ein Profilkatalog Inhaltselemente.",
          feature_2:
            "Inhaltselemente können nach Typ und Anwendungsfall beschriftet werden, um die Suche danach zu erleichtern.",
        },
        version: {
          title: "Version",
          feature_1:
            "Außerdem können Inhaltselemente in mehreren Versionen vorliegen, die für verschiedene Versionen von Tekla Structures geeignet sind. Benutzer wählen die passende Version auf der Inhaltsseite aus.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Erste Schritte",
        tekla_account_and_system_requirements: {
          title: "Tekla account und Systemanforderungen",
          register_atc_html:
            'Für die Anmeldung bei Tekla Warehouse benötigen Sie einen Tekla account. Wenn Sie noch nicht registriert sind, können Sie dies unter <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a> erledigen. (Beachten Sie bitte, dass es sich nicht um das alte Extranet-Konto handelt.)',
          see_tua_for_prerequisites_html:
            'Einzelheiten zu den <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">Voraussetzungen zur Nutzung von Tekla Warehouse</a> finden Sie in der Tekla User Assistance.',
        },
        tekla_warehouse_service_plugin: {
          title: "Tekla Warehouse Service-Plug-in",
          description_1:
            "Tekla Warehouse ist nicht nur ein Onlinespeicher für Inhalte, sondern bietet noch weitere Funktionen:",
          feature_1: "lokale Sammlungen für die persönliche Verwendung",
          feature_2: "Netzwerksammlungen, über die Inhalte mit Kollegen im Firmennetz geteilt werden können",
          feature_3: "einfache Installation beim Verwenden und Erstellen von Inhalten",
          feature_4: "Suchen nach lokalen und Netzwerkinhalten",
          description_2:
            "Dies ist mit dem Tekla Warehouse Service möglich, einem Plug-in, das als lokales Back-End fungiert und die Verbindung zwischen Tekla Structures und der Tekla Warehouse-Website herstellt.",
          description_3:
            "Wenn der Tekla Warehouse Service nicht installiert ist oder nicht läuft, wird in Tekla Warehouse oben auf der Seite eine entsprechende Warnung angezeigt.",
          description_4:
            "Bei Problemen mit dem Dienst können Sie ihn über das Diensteprogramm in Windows neu starten. Der Name des Dienstes lautet Tekla Warehouse. Überprüfen Sie auch die Sicherheitseinstellungen Ihres Browsers.",
          description_5:
            "Die Tekla Warehouse-Website kann auch ohne den Tekla Warehouse Service oder Tekla Structures verwendet werden. Sie ähnelt dann einem typischen Dienst für die Freigabe von Inhalten, bei dem Dateien manuell hochgeladen und heruntergeladen werden.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Wo erhalte ich Tekla Warehouse Service?",
          description_1:
            "Das Tekla Warehouse Service-Plug-in wird mit Tekla Structures 20.1 Service Release 1 und höher installiert. Sie können den Tekla Warehouse Service auch separat installieren, um lokale und Netzwerksammlungen zu verwenden. Das einfache Installieren und Hochladen steht jedoch ausschließlich in Tekla Structures 20.1 Service Release 1 und höher zur Verfügung.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">TEKLA WAREHOUSE SERVICE HERUNTERLADEN</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures und Tekla Warehouse",
          description_1:
            "Tekla Warehouse ist direkt in Tekla Structures eingebunden, um das Verwenden und Hochladen von Inhalten zu erleichtern. Sie können direkt im Modell Inhalte auswählen und einfügen, ohne den Umweg über die Import- bzw. Exportfunktion zu gehen (hierfür wird Tekla Structures 20.1 Service Release 1 und höher benötigt). Alle erforderlichen Dateien können im selben Inhaltselement enthalten sein, zum Beispiel eine benutzerdefinierte Komponente oder ein Profil. So ist sichergestellt, dass die beabsichtigte Funktion erhalten bleibt.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Inhalte in Tekla Warehouse",
        description_1:
          "Tekla Warehouse unterstützt verschiedene Inhaltstypen. Hier finden Sie eine Übersicht der verschiedenen Inhaltstypen und weitere Informationen für den Einstieg.",
        description_2:
          'Sie können im Tekla Warehouse auch <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla-Erweiterungen</a> herunterladen.',
        "3d_products": {
          title: "3D-Modellprodukte",
          image_subtitle: "Dies sind Objekte mit fester Geometrie, beispielsweise Einbauteile, Schalungen und Zubehör.",
          tua_link_html:
            'Weitere Informationen zum <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">Erstellen von 3D-Produktkatalogen für Tekla Warehouse-Sammlungen</a> finden Sie in der Tekla User Assistance.',
        },
        custom_components: {
          title: "Benutzerdefinierte Komponenten",
          image_subtitle:
            "Benutzerdefinierte Komponenten sind programmeigene Objekte in Tekla Structures mit fester oder parametrischer Geometrie.",
          tua_link_html:
            'Weitere Informationen zum <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Hochladen von Inhalten aus Tekla Structures-Modellen in Tekla Warehouse-Sammlungen</a> finden Sie in der Tekla User Assistance.',
        },
        applications: {
          title: "Anwendungen",
          image_subtitle:
            "Anwendungen nutzen das Tekla Open API, um mit Tekla Structures zu interagieren. Damit können zum Beispiel Daten in einer Entwurfslösung integriert werden.",
          tua_link_html:
            'Weitere Informationen zum <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">Hochladen von Anwendungen zu Tekla Warehouse-Sammlungen</a> finden Sie in der Tekla User Assistance.',
        },
        profiles_shapes_etc: {
          title: "Profile, Formen, Materialien, Schraubengarnituren, Bewehrungen und Matten",
          image_subtitle:
            "Kataloge enthalten Listen mit Eigenschaften der Teile. Es kann sich zum Beispiel um unterschiedlichen Materialgüten und ihre Dichten handeln.",
          tua_link_html:
            'Weitere Informationen zum <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Hochladen von Inhalten aus Tekla Structures-Modellen in Tekla Warehouse-Sammlungen</a> finden Sie in der Tekla User Assistance.',
        },
        model_templates: {
          title: "Modellvorlagen",
          image_subtitle:
            "Eine Modellvorlage ist bei einander ähnlichen, sich wiederholenden Projekten nützlich. Die Vorlage kann alle erforderlichen Kataloge, Listenvorlagen, Zeichnungssetups und Einstellungen der erweiterten Option enthalten.",
          tua_link_html:
            'Weitere Informationen zum <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">manuellen Hinzufügen von Dateien zu einer Tekla Warehouse-Sammlung</a> finden Sie in der Tekla User Assistance.',
        },
        drawing_setup: {
          title: "Zeichnungssetup",
          image_subtitle:
            "Mit Setup-Dateien lässt sich definieren, wie Zeichnungen erstellt werden, beispielsweise Layouts, Filter und Klonvorlagen.",
          tua_link_html:
            'Weitere Informationen zum <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">manuellen Hinzufügen von Dateien zu einer Tekla Warehouse-Sammlung</a> finden Sie in der Tekla User Assistance.',
        },
        report_templates_etc: {
          title: "Listenvorlagen und Modellattributdateien",
          description_1:
            "Mit Listenvorlagen definieren Sie die Inhalte und das Layout der Modelldaten in Listen. Modellattributdateien wie Filter funktionieren, wenn sie dem Modellordner hinzugefügt werden.",
          tua_link_html:
            'Weitere Informationen zum <a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">manuellen Hinzufügen von Dateien zu einer Tekla Warehouse-Sammlung</a> finden Sie in der Tekla User Assistance.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Verwenden von Inhalten",
        description_1:
          "Je nach Inhaltstyp können Sie entweder den ausgewählten Inhalt in Ihr Tekla Structures-Modell einfügen oder den Inhalt herunterladen und installieren. Die Tekla User Assistance enthält Anleitungen zu folgenden Themen:",
        feature_1:
          '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Suchen nach Inhalten in Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Verwenden von Inhalten aus Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Einfügen von Tekla Warehouse-Inhalten in Tekla Structures-Modelle</a>',
        feature_4:
          '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Konfliktmanagement in Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Erstellen von Inhalten",
        getting_started_with_content_creation: {
          title: "Grundlagen zum Erstellen von Inhalten",
          description_1: "Die Tekla User Assistance enthält weitere Informationen zu folgenden Themen:",
          feature_1:
            '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Erste Schritte mit Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">Hinzufügen von Inhalten zu Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Ändern von Inhalten in Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Herunterladen von Inhalten aus Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Expertenhilfe für das Erstellen von Inhalten",
          description_1:
            'Wenn Sie Inhalte in Tekla Warehouse veröffentlichen möchten, aber Expertenrat zum Erstellen und Pflegen der Inhalte wünschen, wenden Sie sich bitte an die <a href="https://www.tekla.com/de/kontakt/niederlassungen-und-partner" target="_blank">Tekla-Niederlassung oder einen Händler</a> vor Ort, um mehr über die Möglichkeiten zur Zusammenarbeit zu erfahren.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Anwendungsentwicklung für Tekla Structures",
        description_1:
          "Da Tekla einen offenen Ansatz für das Building Information Modeling (BIM) nutzt, können Sie Lösungen und Fertigungsmaschinen anderer Anbieter nutzen und mit Tekla verbinden.",
        description_2:
          '<a href="https://www.tekla.com/de/loesungen/entwurf-interoperabilitaet/tekla-apis-fuer-entwickler" target="_blank">Tekla Open API</a> ist eine spezielle, von Tekla entwickelte Programmierschnittstelle (Application Programming Interface, API), die Ihnen die Erstellung von Anwendungen und zusätzlichen Funktionen für die Tekla Structures-Modellierungsplattform und deren Integration in Ihre Umgebung ermöglicht. Tekla Open API wird mithilfe der Microsoft-.NET-Technologie implementiert.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> ist die zentrale Anlaufstelle für alle Tekla Open API-Informationen.',
        description_4:
          'Treten Sie dem <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> bei, wenn Sie Anwendungen für Tekla Structures entwickeln möchten.',
      },
    };
  },
};
