import * as React from "react";
import { useContext, useState, Fragment, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { UploadFormContext } from "../uploadFormStore";
import { TranslatedHtml } from "../../components/TranslatedHtml";

/**
 * Main component for rendering the Next and Previous buttons for the Upload form.
 */
export const PreviousAndNext = observer(() => {
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();
  const entity = form.getEntityStore();
  const version = form.getVersionStore();
  const navigate = useNavigate();

  const [clickedPublish, setClickedPublish] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const canPublish = !clickedPublish && !isDisabled;

  useEffect(() => {
    async function checkFormValidity() {
      if (form.getUploadStage() === "collection") {
        setIsDisabled(!collection.isFormValid());
      } else if (form.getUploadStage() === "content") {
        setIsDisabled(!(await entity.isFormValid()));
      } else {
        setIsDisabled(!(await form.isFormValid()));
      }
    }

    checkFormValidity();
  }, [collection.isFormValid(), entity.isFormValid(), version.isFormValid()]);

  function cancel() {
    navigate("/search");
  }

  function publish() {
    setClickedPublish(true);
    form.getUploaderStore().publish();
  }

  function publishButtonText(): string {
    return form.isLocal() || collection.getVisibilityType() === "private"
      ? "upload.publish.actions.create"
      : "upload.publish.actions.publish";
  }

  return (
    <div className="previous-and-next" data-testid="navigationButtons">
      {form.hasPrevious() ? (
        <button
          className="button-large l-left previous"
          type="button"
          onClick={() => form.goBack()}
          data-testid="previous"
        >
          <TranslatedHtml entry="upload.actions.previous" />
        </button>
      ) : (
        <button className="button-large l-left previous" type="button" onClick={cancel} data-testid="cancel">
          <TranslatedHtml entry="upload.actions.cancel" />
        </button>
      )}
      {form.hasNext() ? (
        <button
          className="button-large l-right button-primary next"
          type="button"
          disabled={isDisabled}
          onClick={() => form.goToNext()}
          data-testid="next"
        >
          <TranslatedHtml entry="upload.actions.next" />
        </button>
      ) : (
        <Fragment>
          <button
            className="button-large l-right button-primary next"
            type="button"
            disabled={!canPublish}
            onClick={publish}
            data-testid="publish"
          >
            <TranslatedHtml entry={publishButtonText()} />
          </button>
        </Fragment>
      )}
    </div>
  );
});
