 
export const KoAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["ko-KR"]) {
      window.locale["ko-KR"] = {};
    }

    window.locale["ko-KR"].about = {
      navigation: {
        why: "왜 Tekla Warehouse일까요?",
        usage_basics: "Tekla Warehouse 기본 사항",
        "usage_basics__using-content": "내용 사용",
        "usage_basics__adding-content": "내용 추가",
        usage_basics__collection: "모음",
        "usage_basics__content-item": "내용 아이템",
        usage_basics__version: "버전",
        getting_started: "시작하기",
        "getting_started__tekla-account-and-system-requirements": "Tekla account 및 시스템 요구 사항",
        "getting_started__tekla-warehouse-service-plugin": "Tekla Warehouse Service 플러그인",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Tekla Warehouse Service는 어떻게 얻을 수 있나요?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures 및 Tekla Warehouse",
        content: "Tekla Warehouse의 내용",
        "content__3d-products": "3D 모델 제품",
        "content__custom-components": "사용자 컴포넌트",
        content__applications: "응용 프로그램",
        "content__profiles-shapes-etc": "프로파일, 재질, 볼트, 철근 등",
        "content__model-templates": "모델 템플릿",
        "content__drawing-setup": "도면 설정",
        "content__report-templates-etc": "레포트 템플릿 및 속성 파일",
        using_content: "내용 사용하기",
        creating_content: "내용 생성",
        "creating_content__getting-started-with-content-creation": "내용 생성 시작하기",
        "creating_content__expert-help-for-creating-content": "내용 생성에 대한 전문적 도움이란?",
        application_developers: "Tekla Structures용 응용 프로그램 개발",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "왜 Tekla Warehouse일까요?",
        description_1:
          "Tekla Warehouse에는 응용 프로그램, 사용자 컴포넌트, 부재, 프로파일, 철골 및 콘크리트와 같은 재질, 볼트, 철근, 메쉬, 형상, 그리고 Tekla Structures용 템플릿이 포함됩니다. 모델에는 실제 공급자의 부재가 들어 있어 설계 및 조달을 위한 최상의 정확도와 정확한 자료 정보를 얻을 수 있고, 바로 사용 가능한 내용을 활용하여 모델링 시간을 단축할 수 있습니다.",
        description_2:
          "Tekla Warehouse는 이제 간소화된 방식으로 사용할 수 있는 이 내용에 대해 중앙집중형 접근을 제공합니다. Tekla Warehouse의 내용이 끊임없이 증가하고 있습니다.",
        description_3: "Tekla Warehouse로",
        feature_1:
          "제조업체는 사용자가 자사 제품과 응용 프로그램을 사용할 수 있도록 할 수 있습니다. 사용자는 정확한 3D 제품 모델을 Tekla 모델로 바로 가져옴으로써 나중에 건설 과정에서 물리적 제품을 사용할 수 있습니다.",
        feature_2: "회사 네트워크를 사용해 동료들과 내용을 공유하고 개인 용도로 로컬 저장할 수 있습니다.",
        description_4:
          "편리한 Tekla Warehouse 검색 기능으로 내용 유형에 상관없이 필요한 것을 정확하게 찾을 수 있습니다.",
        description_5: "Tekla Warehouse Service 플러그인은 네트워크 및 로컬 모음을 활성화하는 데 필요합니다.",
        view_licences_html: '<a href="/api/third_party_licenses.txt" target="_blank">서드 파티 라이선스</a> 보기.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Tekla Warehouse 기본 사항",
        using_content: {
          title: "내용 사용",
          description_1:
            "온라인 모음에 있는 내용을 사용하고, 네트워크 모음을 통해 동료들과 내용을 공유하며, 개인 용도로 로컬 모음을 이용할 수 있습니다.",
          description_2:
            "로컬 및 네트워크 모음에는 Tekla Structures 20.1 서비스 릴리스 1 및 최신 버전의 Tekla Structures와 함께 설치되는 Tekla Warehouse Service가 필요합니다.",
          description_3: "현지화된 환경 내용이 담긴 특정 Tekla 모음은 Tekla Structures와 함께 설치됩니다.",
          description_4: "자세한 정보는 내용 사용 섹션을 참조하세요.",
        },
        adding_content: {
          title: "내용 추가",
          description_1:
            '온라인 모음을 이용하여 자신의 내용을 모든 Tekla Warehouse 사용자에게 게시할 수 있습니다. 조직으로서 내용을 게시하려면 account.tekla.com에 자신의 조직을 추가해야 합니다. 자세한 내용은 현지 <a href="https://www.tekla.com/kr/contact/offices-and-resellers" target="_blank">Tekla 사무소 또는 리셀러</a>에게 문의하시기 바랍니다.',
          description_2: "자세한 내용은 내용 생성 섹션을 참조하세요.",
        },
        collection: {
          title: "모음",
          feature_1: "모음은 내용 항목을 폴더처럼 한데 모으고 그 내용(공개/비공개)에 대한 접근 권한을 정의합니다.",
          feature_1_1:
            "모형 제작자는 온라인 모음에 있는 내용을 사용하고, 네트워크 모음을 통해 동료들과 내용을 공유하며, 로컬 모음을 개인 용도로 이용할 수 있습니다.",
          feature_2:
            "내용 제공자들은 온라인 모음을 사용하여 자신의 내용을 모든 Tekla Warehouse 사용자에게 게시할 수 있습니다.",
          feature_3:
            "로컬 및 네트워크 모음에는 Tekla Warehouse Service 플러그인이 필요한데, 이 플러그인은 Tekla Structures 20.1 서비스 릴리스 1 이상 버전과 함께 설치되기도 하고 그와는 별도로 설치할 수도 있습니다.",
        },
        "content-item": {
          title: "내용 항목",
          feature_1:
            "내용 항목은 파일을 논리적으로 모아놓은 것입니다. 예를 들면, 3D 제품 카탈로그, 모델 템플릿, 도구, 사용자 컴포넌트 모음 또는 프로파일 카탈로그 등이 내용 항목입니다.",
          feature_2:
            "내용 항목에는 유형 및 사용 사례에 대한 정보를 첨부할 수 있어 사용자들이 내용을 검색하는 데 도움이 됩니다.",
        },
        version: {
          title: "버전",
          feature_1:
            "내용 아이템은 서로 다른 Tekla Structures 버전과 함께 사용되는 여러 가지 버전을 동시에 보유할 수 있습니다. 사용자는 내용 페이지에서 올바른 버전을 선택할 수 있습니다.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "시작하기",
        tekla_account_and_system_requirements: {
          title: "Tekla account 및 시스템 요구 사항",
          register_atc_html:
            'Tekla Warehouse에 로그인하려면 Tekla account가 필요합니다. 아직 등록하지 않았다면 <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>에서 등록하시기 바랍니다. (이것은 이전의 엑스트라넷 계정이 아님에 유의하세요).',
          see_tua_for_prerequisites_html:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">Tekla Warehouse 사용을 위한 기본 요건</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
        tekla_warehouse_service_plugin: {
          title: "Tekla Warehouse Service 플러그인",
          description_1: "Tekla Warehouse는 온라인 내용 보관함이자 다음과 같은 용도로도 사용할 수 있습니다.",
          feature_1: "개인 용도의 로컬 모음",
          feature_2: "회사 네트워크를 사용해 동료들과 내용을 공유할 수 있는 네트워크 모음",
          feature_3: "내용 사용 또는 생성 시 쉬운 설치",
          feature_4: "로컬 및 네트워크 내용 검색",
          description_2:
            "로컬 백엔드 역할을 하는 플러그인인 Tekla Warehouse Service가 Tekla Structures 및 Tekla Warehouse 웹 사이트와 교신하기 때문에 Tekla Warehouse를 이처럼 다양한 용도로 사용할 수 있습니다.",
          description_3:
            "Tekla Warehouse Service가 설치되지 않았거나 실행되지 않을 경우, 문제를 해결할 수 있도록 Tekla Warehouse 페이지 상단에 이 점을 강조 표시합니다.",
          description_4:
            "서비스에 문제가 발생하면 Windows에서 Services 프로그램을 사용해 재시작할 수 있습니다. 서비스 이름은 Tekla Warehouse 입니다. 브라우저의 보안 설정도 점검해보십시오.",
          description_5:
            "Tekla Warehouse 웹 사이트는 Tekla Warehouse Service 또는 Tekla Structures 없이 사용할 수 있습니다. 그러한 체험은 파일이 수동으로 업로드 및 다운로드되는 전형적인 내용 공유 웹 사이트에 더 근접한 것입니다.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Tekla Warehouse Service는 어떻게 얻을 수 있나요?",
          description_1:
            "Tekla Warehouse Service 플러그인은 Tekla Structures 20.1 서비스 릴리스 1 이상의 버전과 함께 설치됩니다. Tekla Warehouse Service를 별도로 설치해 로컬 및 네트워크 모음을 활성화할 수도 있지만, 간편 설치 및 업로드 기능은 Tekla Structures 20.1 서비스 릴리스 1 이상 버전에서만 사용할 수 있습니다.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">TEKLA WAREHOUSE SERVICE 다운로드</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures 및 Tekla Warehouse",
          description_1:
            "Tekla Warehouse는 Tekla Structures에 직접 연결되므로 내용을 사용하거나 업로드하는 것이 더 간편합니다. 모델에서 직접 내용을 선택할 수 있고, 또한 가져오기/내보내기 파일로 인한 번거로움을 겪을 필요 없이 내용을 모델에 직접 삽입할 수 있습니다(이를 위해서는 Tekla Structures 20.1 서비스 릴리스 1 이상 버전이 필요함). 필요한 모든 파일은 작업이 의도대로 진행되도록 하기 위해 사용자 컴포넌트 또는 프로파일과 같은 동일한 내용 아이템에 포함시킬 수 있습니다.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Tekla Warehouse의 내용",
        description_1:
          "Tekla Warehouse는 몇 가지 서로 다른 유형의 내용을 제공합니다. 여기에서는 내용 유형 개요 및 시작하기에 대한 자세한 정보를 찾아볼 수 있습니다.",
        description_2:
          'Tekla Warehouse에서 <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla 확장 프로그램</a>을 다운로드할 수도 있습니다.',
        "3d_products": {
          title: "3D 모델 제품",
          image_subtitle: "이것은 임베디드, 거푸집, 액세서리와 같은 고정된 지오메트리를 지닌 객체들입니다.",
          tua_link_html:
            'Tekla Warehouse 모음에서 <a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">3D 제품 카탈로그 생성</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
        custom_components: {
          title: "사용자 컴포넌트",
          image_subtitle: "사용자 컴포넌트는 고정 또는 매개변수 지오메트리를 지닌 Tekla Structures 기본 객체입니다.",
          tua_link_html:
            'Tekla Structures 모델에서 Tekla Warehouse 모음으로 <a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">내용 업로드</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
        applications: {
          title: "응용 프로그램",
          image_subtitle:
            "응용 프로그램은 Tekla Open API를 사용해 Tekla Structures와 상호작용합니다. 예를 들어 데이터 통합은 설계 솔루션으로 연결됩니다.",
          tua_link_html:
            'Tekla Warehouse 모음에 <a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">응용 프로그램 업로드</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
        profiles_shapes_etc: {
          title: "프로파일, 형상, 재질, 볼트 어셈블리, 철근, 메쉬",
          image_subtitle:
            "카탈로그에는 부재 관련 속성을 나열한 목록이 들어 있습니다. 그 예로 서로 다른 재질 등급 및 밀도를 들 수 있습니다.",
          tua_link_html:
            'Tekla Structures 모델에서 Tekla Warehouse 모음으로 <a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">내용 업로드</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
        model_templates: {
          title: "모델 템플릿",
          image_subtitle:
            "모델 템플릿은 서로 유사하고 반복되는 프로젝트에 사용될 수 있습니다. 템플릿에는 필요한 모든 카탈로그, 레포트 템플릿, 도면 설정 및 고급 옵션 설정을 담을 수 있습니다.",
          tua_link_html:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Tekla Warehouse 모음에 수동으로 파일 추가</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
        drawing_setup: {
          title: "도면 설정",
          image_subtitle: "설정 파일은 도면이 생성되는 방식, 예를 들면 레이아웃, 필터, 복제 템플릿 등을 정의합니다.",
          tua_link_html:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Tekla Warehouse 모음에 수동으로 파일 추가</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
        report_templates_etc: {
          title: "레포트 템플릿 및 모델 속성 파일",
          description_1:
            "레포트 템플릿은 레포트에서 모델 데이터의 내용 및 레이아웃을 정의합니다. 필터와 같은 모델 속성 파일은 모델 폴더에 추가될 때 작동합니다.",
          tua_link_html:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Tekla Warehouse 모음에 수동으로 파일 추가</a>에 대한 자세한 내용은 Tekla User Assistance를 참조하세요.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "내용 사용",
        description_1:
          "내용 유형에 따라, 선택한 내용을 Tekla Structures 모델에 삽입하거나 다운로드하여 설치할 수 있습니다. 다음 사항에 대한 지침은 Tekla User Assistance를 참고하세요.",
        feature_1:
          '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Tekla Warehouse에서 내용 검색하기</a>',
        feature_2:
          '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Tekla Warehouse 내용 사용</a>',
        feature_3:
          '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Tekla Warehouse 내용을 Tekla Structures 모델에 삽입하기</a>',
        feature_4:
          '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Tekla Warehouse에서 충돌 관리</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "내용 생성",
        getting_started_with_content_creation: {
          title: "내용 생성 시작하기",
          description_1: "다음 사항에 대한 자세한 내용은 Tekla User Assistance를 참고하세요.",
          feature_1:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Tekla Warehouse 시작하기</a>',
          feature_2:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">Tekla Warehouse에 내용 추가하기</a>',
          feature_3:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Tekla Warehouse에서 내용 수정하기</a>',
          feature_4:
            '<a href="https://support.tekla.com/ko/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Tekla Warehouse에서 내용 다운로드하기</a>',
        },
        expert_help_for_creating_content: {
          title: "내용 생성에 대한 전문적 도움이란?",
          description_1:
            'Tekla Warehouse의 내용을 게시하고 싶지만 내용을 생성하고 관리하기 위해 전문적 도움을 받고 싶다면, 현지 <a href="https://www.tekla.com/kr/contact/offices-and-resellers" target="_blank">Tekla 사무소 또는 리셀러</a>에게 연락해 협력 가능성에 대한 자세한 정보를 얻으시기 바랍니다.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Tekla Structures용 응용 프로그램 개발",
        description_1:
          "Tekla는 건축 정보 모델링(BIM)에 대한 개방적인 접근 방식을 보유하고 있으므로 다른 공급자의 솔루션과 가공 기계를 사용하면서도 Tekla와 연계할 수 있습니다.",
        description_2:
          '<a href="https://www.tekla.com/kr/%EC%86%94%EB%A3%A8%EC%85%98/%EC%84%A4%EA%B3%84-%EC%83%81%ED%98%B8%EC%9A%B4%EC%9A%A9%EC%84%B1/open-api" target="_blank">Tekla Open API</a>는 Tekla가 개발한 특화된 응용 프로그램 인터페이스(API)로서 Tekla Structures 모델링 플랫폼에서 응용 프로그램 및 부가 기능을 개발하고 그것을 자신의 환경에 통합할 수 있게 해줍니다. Tekla Open API는 Microsoft .NET 기술로 구현되었습니다.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a>는 모든 Tekla Open API 정보를 위한 허브입니다.',
        description_4:
          'Tekla Structures에 대한 응용 프로그램 개발에 관심이 있다면 <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a>에 참가하십시오.',
      },
    };
  },
};
