import { action, observable, runInAction, makeObservable } from "mobx";
import { IEntity, ILocalCollection, IEditorModeStrategy, IResource } from "../models/dataModel";
import { AbstractAsyncStore } from "../stores/abstractAsyncStore";
import { RootStore, createStoreContext } from "../stores/rootStore";
import { NewRepository } from "../js/services/NewRepository";

/**
 * Collection page store.
 */
export class LocalCollectionPageStore extends AbstractAsyncStore implements IEditorModeStrategy {
  /**
   * Collection object.
   */
  @observable private collection: ILocalCollection | undefined;

  /**
   * collection title.
   */
  @observable private title = "";

  /**
   * Collection description
   */
  @observable private description = "";

  /**
   * Collection object.
   */
  @observable private packages: IEntity[] = [];

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Fetches collection with given id
   */
  @action
  public async fetchData({ collectionId }: { collectionId: string }) {
    runInAction(() => {
      this.dataFetched = false;
      this.loading = true;
    });
    try {
      const collection = await NewRepository.getCollection(
        {
          id: collectionId,
          isLocal: true,
        },
        this.rootStore.getUserStore().getCurrentUser()?.id || "",
      );
      runInAction(() => {
        this.collection = collection as unknown as ILocalCollection;
      });

      if (this.collection) {
        const packages = await NewRepository.getPackages(
          this.collection,
          false,
          this.rootStore.getUserStore().getCurrentUser()?.id,
        );
        runInAction(() => {
          this.setFields();
          this.packages = packages;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.loading = false;
        this.dataFetched = true;
      });
      const msg = this.rootStore.getTranslation("collections.notification.error_loading_collection_contents");
      this.rootStore.getErrorHandlerStore().handleUnauthorized(msg);
    }
    runInAction(() => {
      this.loading = false;
      this.dataFetched = true;
    });
  }

  /**
   * Gets collection object
   * @returns collection object
   */
  public getCollection(): ILocalCollection | undefined {
    return this.collection;
  }

  /**
   * Gets collection object
   * @returns collection object
   */
  public getResource(): IResource | undefined {
    return this.getCollection() as IResource;
  }

  /**
   * Gets collection packages
   * @returns collection object
   */
  public getPackages(): IEntity[] {
    return this.packages;
  }

  /**
   * Gets collection title
   * @returns title
   */
  public getTitle(): string {
    return this.title;
  }

  /**
   * Sets collection title
   * @param newTitle The value to be set
   */
  @action
  public setTitle = (newTitle: string) => {
    this.title = newTitle;
  };

  /**
   * Updates collection title
   * @param newTitle The value to be set
   */
  @action
  public updateTitle = async () => {
    if (this.collection) {
      this.collection.title = this.title;
      const user = this.rootStore.getUserStore().getCurrentUser();
      if (!!user) await NewRepository.updateCollection(this.collection, user);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("collections.notification.collection_updated"));
    }
  };

  /**
   * Gets collection description
   * @returns description
   */
  public getDescription(): string {
    return this.description;
  }

  /**
   * Sets collection description
   * @params description The value to be set
   */
  @action
  public setDescription = (description: string) => {
    this.description = description;
  };

  /**
   * Updates collection description
   */
  @action
  public async updateDescription() {
    if (this.collection) {
      const user = this.rootStore.getUserStore().getCurrentUser();
      this.collection.description = this.description;
      if (!!user) await NewRepository.updateCollection(this.collection, user);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("collections.notification.collection_updated"));
    }
  }

  /**
   * Resolves if translations dialogs should be shown
   * @returns true if dialogs should be shown
   */
  public shouldShowTranslationDialogs(): boolean {
    return false;
  }

  /**
   * Resolves if batch edit dialog should be shown
   * @returns true if dialogs should be shown
   */
  public shouldShowBatchEditDialog(): boolean {
    return false;
  }

  /**
   * Gets the thumbnail for the collection
   */
  public getPackageThumbnail(packageItem: IEntity) {
    const thumbnailUrl = packageItem.thumbnail!.url;
    return {
      backgroundImage: `url(${thumbnailUrl})`,
    };
  }

  /**
   * Sets the title and description fields for collection.
   */
  @action
  private setFields() {
    if (this.collection) {
      this.description = this.collection.description ? this.collection.description : "";
      this.title = this.collection.title ? this.collection.title : "";
    }
  }

  /**
   * Performs the delete collection action, and displays a 'success' message.
   */
  public performDelete = async () => {
    try {
      const editor = this.rootStore.getUserStore().getCurrentUser();

      if (!!editor) {
        await NewRepository.deleteCollection(this.getCollection(), editor.id);
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("collections.notification.collection_removed"));

        this.rootStore.getRouter().changeRoutingState("/my/local");
      }
    } catch {
      console.log("Failed to delete collection");
    }
  };
}

export const LocalCollectionPageStoreContext = createStoreContext<LocalCollectionPageStore>(LocalCollectionPageStore);
