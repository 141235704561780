import * as URI from "urijs";
import { Settings } from "../config/Settings";

function build(path: string, params: Record<string, string>): string {
  return new URI(path).addSearch(params).toString();
}

/**
 * UrlBuilder helper class
 */
export const URLBuilder = {
  /**
   * Builds a Warehouse URL string based on given data
   * @param path The path to be used
   * @param params Queryparams to be added to url
   * @param forceUrl If want to force to use api endpoint url as basis
   */
  buildWarehouseURL: function (path: string, params?: Record<string, string> | null, forceUrl?: boolean): string {
    const queryParams = params || {};
    if (forceUrl || !Settings.tcc.localRequests) {
      return build(Settings.tcc.apiEndpoint + path, queryParams);
    } else {
      return build(path, queryParams);
    }
  },
  /**
   * Builds local content url string based on given data
   * @param path The path to be used
   * @param params Queryparams to be added to url
   */
  buildLocalUrl: function (path: string, params?: any): string {
    const queryParams = params || {};
    return build(Settings.local.apiEndpoint + path, queryParams);
  },
  /**
   * Builds an URL based on given path and parameters
   * @param path - the path
   * @param params - the parameters
   * @returns the URL
   */
  buildURL: function (path: string, params?: Record<string, string>): string {
    return build(path, params || {});
  },
};
