import * as React from "react";
import { useContext } from "react";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext } from "../stores/rootStore";

export const Why = () => {
  const store = useContext(RootContext);

  return (
    <article className="about-why">
      <header>
        <h2>{store.getTranslation("about.why.title")}</h2>
      </header>
      <section>
        <p>{store.getTranslation("about.why.description_1")}</p>
        <p>{store.getTranslation("about.why.description_2")}</p>
        <p>{store.getTranslation("about.why.description_3")}</p>
        <ul className="feature-list">
          <li>{store.getTranslation("about.why.feature_1")}</li>
          <li>{store.getTranslation("about.why.feature_2")}</li>
        </ul>
        <p>{store.getTranslation("about.why.description_4")}</p>
        <p>{store.getTranslation("about.why.description_5")}</p>
        <figure>
          <img src="../images/learn_more/2013-12-bim-image2.jpg" />
        </figure>
        <p>
          <TranslatedHtml entry="about.why.view_licences_html" />
        </p>
      </section>
    </article>
  );
};

export const whyNavigationInfo = {
  name: "why",
  component: <Why />,
  translationId: "about.navigation.why",
};
