
import * as React from "react";
import { Navigate, Route, Routes } from "react-router";

import { LandingPage } from "../landing/LandingPage";
import { AboutPage } from "../about/AboutPage";
import { Unauthorized } from "../auth/Unauthorized";
import { AuthHandler } from "../auth/AuthHandler";
import { MyPage } from "../my/MyPage";
import { ContentManager } from "../my/admin/content-manager/ContentManager";
import { OrganizationPageWrapper } from "../organizations/OrganizationPageWrapper";
import { OrganizationByNameWrapper } from "../organizations/OrganizationByNameWrapper";
import { CollectionPageWrapper } from "../collection/CollectionPageWrapper";
import { LocalCollectionPageWrapper } from "../local-collection/LocalCollectionPageWrapper";
import { NewOnlineCollectionWrapper } from "../collection/NewOnlineCollectionWrapper";
import { PackagePageWrapper } from "../package/PackagePageWrapper";
import { SearchPageWrapper } from "../search/SearchPageWrapper";
import { ShoppingCartWrapper } from "../cart/ShoppingCartWrapper";
import { Upload } from "../upload/Upload";
import { VersionEditor } from "../versions/VersionEditor";
import { Downloader } from "../download/Downloader";

import { AuthModeEnum, UploadStrategyEnum } from "../models/enums";

export const AppRoutes = () => {
  return (
    <Routes>
      {/* Auth */}
      <Route path="/auth/handle_unauthorized" element={<Unauthorized />} />
      <Route path="/auth/validate" element={<AuthHandler mode={AuthModeEnum.VALIDATE} />} />
      <Route path="/auth/sso" element={<AuthHandler mode={AuthModeEnum.SSO} />} />
      <Route path="/auth/sso_tcc" element={<AuthHandler mode={AuthModeEnum.SSO_TCC} />} />
      <Route path="/auth/failure" element={<AuthHandler mode={AuthModeEnum.FAILURE} />} />
      {/* Landing page */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/landing" element={<LandingPage />} />
      {/* About page */}
      <Route path="/about/why" element={<AboutPage view="why" />} />
      <Route path="/about/usage_basics" element={<AboutPage view="usageBasics" />} />
      <Route path="/about/getting_started" element={<AboutPage view="gettingStarted" />} />
      <Route path="/about/content" element={<AboutPage view="content" />} />
      <Route path="/about/using_content" element={<AboutPage view="usingContent" />} />
      <Route path="/about/creating_content" element={<AboutPage view="creatingContent" />} />
      <Route path="/about/application_developers" element={<AboutPage view="applicationDevelopers" />} />
      {/* Search */}
      <Route path="/search" element={<SearchPageWrapper />} />
      {/* Shopping cart */}
      <Route path="/shoppingcart" element={<ShoppingCartWrapper />} />
      {/* Upload */}
      <Route path="/upload" element={<Upload />} />
      <Route path="/addPackage" element={<Upload />} />
      {/* My page */}
      <Route path="/my">
        <Route path="*" element={<MyPage />} />
      </Route>
      <Route path="/my/admin/content-manager" element={<ContentManager />} />
      {/* Organizations */}
      <Route path="/organization/:id" element={<OrganizationPageWrapper />} />
      <Route path="/by/:orgname" element={<OrganizationByNameWrapper />} />
      {/* Collections */}
      <Route path="/collections/online/:id" element={<CollectionPageWrapper />} />
      <Route path="/collections/local/:id" element={<LocalCollectionPageWrapper />} />
      <Route path="/collections/new" element={<NewOnlineCollectionWrapper />} />
      {/* Packages */}
      <Route path="/catalog/details/:id" element={<PackagePageWrapper islocal={false} />} />
      <Route path="/catalog/localdetails/:id" element={<PackagePageWrapper islocal={true} />} />
      {/* Version */}
      <Route path="/catalog/addVersion/:id" element={<VersionEditor view={UploadStrategyEnum.ADD_VERSION} />} />
      <Route path="/catalog/editVersion/:id" element={<VersionEditor view={UploadStrategyEnum.EDIT_VERSION} />} />
      <Route
        path="/catalog/editVersion/:id/:version"
        element={<VersionEditor view={UploadStrategyEnum.EDIT_VERSION} />}
      />
      <Route path="/catalog/addLocalVersion/:id" element={<VersionEditor view={UploadStrategyEnum.ADD_VERSION} />} />
      <Route path="/catalog/editLocalVersion/:id" element={<VersionEditor view={UploadStrategyEnum.EDIT_VERSION} />} />
      <Route
        path="/catalog/editLocalVersion/:id/:version"
        element={<VersionEditor view={UploadStrategyEnum.EDIT_VERSION} />}
      />
      {/* Download endpoint */}
      <Route path="/download" element={<Downloader />} />
      {/* Default route */}
      <Route path="*" element={<Navigate to="/landing" replace />} />
    </Routes>
  );
};
