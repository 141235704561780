import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext, useState } from "react";
import _ from "underscore";

import { RootContext } from "../../stores/rootStore";
import { OrganizationPageStore } from "../organizationPageStore";
import { DialogContext } from "../../dialogs/dialogStore";
import { IOrganization } from "../../models/dataModel";

/**
 * Renders edit description dialog for organization
 */
export const EditWarehouseUrlDialog = observer(
  ({ organization, store }: { organization: IOrganization; store: OrganizationPageStore }) => {
    const rootStore = useContext(RootContext);
    const dialog = useContext(DialogContext);
    const orgWarehouseUrl = organization!.customWarehouseUrl || "";
    const [warehouseUrl, setWarehouseUrl] = useState<string>(orgWarehouseUrl);
    const customUrlValidator = /^([a-zA-Z])+$/;

    function cancel() {
      dialog.close();
    }

    function handleWarehouseUrlChange(e) {
      setWarehouseUrl(e.target.value);
    }

    async function updateWarehouseUrl() {
      await store.updateWarehouseUrl(warehouseUrl);
    }

    function getCustomWarehouseUrlTitle() {
      return "https://" + window.location.host + "/#/by/";
    }

    function isUrlValid(): boolean {
      return _.isEmpty(warehouseUrl) || customUrlValidator.test(warehouseUrl);
    }

    return (
      <Fragment>
        <article>
          <header>
            <h2>{rootStore.getTranslation("organization.edit.edit_warehouse_url")}</h2>
          </header>
          <section className="warehouseurl">
            <ol className="basic-data">
              <li>
                <label
                  className="tooltip-info"
                  title={rootStore.getTranslation("organization.edit.edit_warehouse_url_subtitle")}
                >
                  {rootStore.getTranslation("organization.edit.edit_warehouse_url")}
                  <span className="icon help blue" />
                </label>
                <label className="l-inline">{getCustomWarehouseUrlTitle()}</label>
                <input
                  type="text"
                  value={warehouseUrl}
                  onChange={handleWarehouseUrlChange}
                  data-testid="update-warehouse-url-input"
                />
              </li>
            </ol>
          </section>
          <div className="actions">
            <button onClick={cancel} data-testid="cancel-edit-warehouse-url">
              {rootStore.getTranslation("actions.cancel")}
            </button>
            <button
              disabled={!isUrlValid()}
              className="button-primary"
              onClick={updateWarehouseUrl}
              data-testid="update-warehouse-url"
            >
              {rootStore.getTranslation("actions.update")}
            </button>
          </div>
        </article>
      </Fragment>
    );
  },
);
