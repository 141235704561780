import { action, observable, runInAction, makeObservable } from "mobx";
import * as validator from "validator";
import _ from "underscore";

import { NewRepository } from "../../js/services/NewRepository";
import { IDialogActionsStore, IEntity, ITranslationObject } from "../../models/dataModel";
import { RootStore, createStoreContext } from "../../stores/rootStore";
import { PackagePageStore } from "package/packagePageStore";

/**
 * Store used to handle data for support component
 */
export class SupportStore implements IDialogActionsStore {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Package item object
   */
  @observable private packagePageStore?: PackagePageStore;

  /**
   * Represents a collection of support URLs.
   */
  @observable private supportUrls: Map<string, string> = observable.map({
    copyright: "",
    helpUrl: "",
    supportUrl: "",
    discussionForumUrl: "",
    specialTermsUrl: "",
  });

  /**
   * Should notify subscribers about package updates
   */
  @observable private doNotNotify = false;

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param packageItem Package object
   */
  public constructor(rootStore: RootStore, packagePageStore?: PackagePageStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (packagePageStore) {
      runInAction(() => {
        this.packagePageStore = packagePageStore;
      });
      this.init();
    }
  }

  /**
   * Gets the url for the support field.
   * @retuns url for the support field
   */
  public getSupportFieldUrl(name: string): string {
    return this.supportUrls.get(name) || "";
  }

  /**
   * Sets the support field URL.
   *
   * @param name - The name of the support field.
   * @param value - The value to set for the support field.
   */
  @action
  public setSupportFieldUrl(name: string, value) {
    this.supportUrls.set(name, value);
  }

  /**
   * Gets do not notify field value
   * @retuns do not notify value
   */
  public getDoNotNotify(): boolean {
    return this.doNotNotify;
  }

  /**
   * Sets do not notify field value
   * @param doNotNotify - new value for do not notify
   */
  @action
  public setDoNotNotify(doNotNotify: boolean): boolean {
    return (this.doNotNotify = doNotNotify);
  }

  /**
   * Initializes fields
   */
  @action
  public init() {
    const packageItem = this.getPackage();

    if (!!packageItem && !!packageItem.attributes) {
      this.supportUrls.set("copyright", this.resolveCopyright());
      this.supportUrls.set("helpUrl", packageItem.attributes["helpUrl"] || "");
      this.supportUrls.set("supportUrl", packageItem.attributes["supportUrl"] || "");
      this.supportUrls.set("discussionForumUrl", packageItem.attributes["discussionForumUrl"] || "");
      this.supportUrls.set("specialTermsUrl", packageItem.attributes["specialTermsUrl"] || "");
      this.doNotNotify = false;
    }
  }

  /**
   * Saves package data
   */
  public async saveContent(): Promise<void> {
    const user = this.rootStore.getUserStore().getCurrentUser();
    const packageItem = this.getPackage();

    if (!!this.packagePageStore && !!packageItem && (!!user || packageItem?.isLocal)) {
      try {
        const data = await this.getUpdatedData();
        this.packagePageStore.updateEntity(data, this.doNotNotify);
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("details.update_failed"));
      }
    }
  }

  /**
   * Checks if given url is valid
   * @returns true if url is valid
   */
  public isValidUrl(url: string | undefined): boolean {
    if (!url) {
      return true;
    }
    return !!(
      url &&
      validator.isURL(url, {
        protocols: ["http", "https", "ftp"],
        require_protocol: true,
      })
    );
  }

  /**
   * Checks if form is valid
   * @returns true if form is valid
   */
  public isFormValid(): boolean {
    return (
      this.isValidUrl(this.supportUrls.get("helpUrl")) &&
      this.isValidUrl(this.supportUrls.get("specialTermsUrl")) &&
      this.isValidUrl(this.supportUrls.get("discussionForumUrl"))
    );
  }

  /**
   * Checks if package is local
   * @returns true if package is local
   */
  public isLocalContent(): boolean {
    return !!this.getPackage() && !!this.getPackage()?.isLocal;
  }

  @action
  private async getUpdatedData(): Promise<Record<string, any> | undefined> {
    const packageItem = this.getPackage();
    if (packageItem) {
      let packageUpdates: any = { id: packageItem.id };

      const language: string = this.rootStore.getLocalizationStore().getLangForSelectedLocale();
      if (packageItem.isLocal) {
        packageUpdates = {
          ...packageUpdates,
          attributes: { ...packageItem.attributes, copyrightInfo: this.supportUrls.get("copyright") },
        };
      } else {
        if (this.shouldUpdateTranslation("copyright", language)) {
          if (packageItem.translations) {
            packageItem.translations[language].copyright = this.supportUrls.get("copyright");
            await NewRepository.setTranslations(packageItem, packageItem.translations);
          }
        } else {
          packageUpdates = {
            ...packageUpdates,
            copyrightInfo: this.supportUrls.get("copyright"),
          };
        }
        packageUpdates = {
          ...packageUpdates,
          attributes: {
            ...this.getPackage()?.attributes,
            helpUrl: this.supportUrls.get("helpUrl"),
            supportUrl: this.supportUrls.get("supportUrl"),
            discussionForumUrl: this.supportUrls.get("discussionForumUrl"),
            specialTermsUrl: this.supportUrls.get("specialTermsUrl"),
          },
        };
      }

      return packageUpdates;
    }
  }

  private getPackage(): IEntity | undefined {
    return !!this.packagePageStore ? this.packagePageStore.getPackage() : undefined;
  }

  private shouldUpdateTranslation(field: string, language: string): boolean {
    const packageItem = this.getPackage();
    let filteredItems = [];
    if (!!packageItem && !!packageItem.translations) {
      _.each(packageItem.translations, (translationItem: ITranslationObject, key: string) => {
        if (key === language) {
          filteredItems = _.filter(translationItem, function (translation, itemKey) {
            return itemKey === field;
          });
        }
      });
    }
    return filteredItems && filteredItems.length > 0;
  }

  private resolveCopyright() {
    const packageItem = this.getPackage();
    if (packageItem) {
      if (packageItem.isLocal) {
        return packageItem.attributes!["copyrightInfo"] || "";
      } else {
        return packageItem.translatedCopyright || "";
      }
    } else {
      return "";
    }
  }
}

/**
 * Context object for SupportStoreContext instances.
 */
export const SupportStoreContext = createStoreContext<SupportStore>(SupportStore);
