import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";

import { RootContext, RootStore } from "../stores/rootStore";
import { CollectionPageStore, CollectionPageStoreContext } from "./collectionPageStore";
import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";
import { TabNavigationStore, TabNavigationStoreContext } from "../components/tab-navigation/tabNavigationStore";
import { AccessManagementStore, AccessManagementStoreContext } from "./access-management/accessManagementStore";
import { AnalyticsStore, AnalyticsStoreContext } from "../components/analytics/analyticsStore";

import { IResource, ITabListItem } from "../models/dataModel";

import { AccessManagement } from "./access-management/AccessManagement";
import { Analytics } from "../components/analytics/Analytics";
import { TabNavigation } from "../components/tab-navigation/TabNavigation";
import { Results } from "./Results";

/**
 * A component that displays tabs.
 */
export const Tabs = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const tabNavigationStore: TabNavigationStore = useContext(TabNavigationStoreContext);
  const collectionPageStore: CollectionPageStore = useContext(CollectionPageStoreContext);
  const collection = collectionPageStore.getCollection();

  function canEditAccessRights() {
    return (
      !!collection &&
      !collection.isBanned &&
      rootStore.getUserStore().canEditAccessRights(collection) &&
      editorModeStore.getEditorMode()
    );
  }

  function canSeeAnalytics() {
    return !!collection && rootStore.getUserStore().canSeeAnalytics(collection as IResource);
  }

  function getTabList(): ITabListItem[] {
    return [
      {
        name: "content",
        translationKey: "collections.subTabs.content",
        isDefault: true,
        canAccess: true,
      },
      {
        name: "analytics",
        translationKey: "collections.subTabs.analytics",
        canAccess: canSeeAnalytics(),
      },
      {
        name: "access",
        translationKey: "collections.subTabs.manage_access",
        canAccess: canEditAccessRights(),
      },
    ];
  }

  function shouldDisplayTabContents(tabName: string) {
    return (
      tabNavigationStore.getActiveTabName() === tabName &&
      ((tabName === "access" && canEditAccessRights()) ||
        (tabName === "analytics" && canSeeAnalytics()) ||
        tabName === "content")
    );
  }

  return (
    <div className="details-tabs-container">
      <TabNavigation tabList={getTabList()} listStyle="details-tabs" />
      <div className="tab-view wide">
        {shouldDisplayTabContents("content") && <Results />}
        {shouldDisplayTabContents("access") && (
          <AccessManagementStoreContext.Provider value={new AccessManagementStore(rootStore)}>
            <AccessManagement />
          </AccessManagementStoreContext.Provider>
        )}
        {shouldDisplayTabContents("analytics") && (
          <AnalyticsStoreContext.Provider value={new AnalyticsStore(rootStore, collection as IResource)}>
            <Analytics />
          </AnalyticsStoreContext.Provider>
        )}
      </div>
    </div>
  );
});
