import * as React from "react";
import { useContext, Fragment, useState } from "react";
import _ from "underscore";

import { RootStore, RootContext } from "../stores/rootStore";
import { DialogContext, DialogStore } from "./dialogStore";
import { Dialog } from "./Dialog";

/**
 * Component that renders a confirmation dialog.
 * @param title title of the confirm dialog (optional)
 * @param content dialog content
 * @param labelConfirm label for the confirm button (optional)
 * @param labelCancel label for the cancel button (optional)
 * @param callbackCloseDialog function that handles the closing of the dialog
 * @param callbackConfirm function that is called when the confirm button is clicked
 * @param callbackDontShow function that is called when user clicks the don't show again checkbox (optional)
 * @param dontShowChecked value of the don't show checkbox (optional)
 */
export const ConfirmAction = ({
  title,
  content,
  labelConfirm,
  labelCancel,
  callbackCloseDialog,
  callbackConfirm,
  callbackDontShow,
  dontShowChecked,
}: {
  title?: string;
  content: JSX.Element | string;
  dontShowChecked?: boolean;
  labelConfirm?: string;
  labelCancel?: string;
  callbackCloseDialog;
  callbackConfirm;
  callbackDontShow?;
}) => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);

  const dontShowAvailable = !!callbackDontShow;
  const showCancel = labelCancel !== "";
  const showConfirm = labelConfirm !== "";

  const [dontShowAgain, setDontShowAgain] = useState<boolean>(!!dontShowChecked);

  if (!title) {
    title = rootStore.getTranslation("shared.confirm.title");
  }

  if (labelConfirm === undefined) {
    labelConfirm = rootStore.getTranslation("shared.confirm.yes");
  }

  if (labelCancel === undefined) {
    labelCancel = rootStore.getTranslation("shared.confirm.no");
  }

  function handleChange() {
    setDontShowAgain(!dontShowAgain);
  }

  function cancelAction() {
    callbackCloseDialog();
  }

  function confirmAction() {
    if (_.isFunction(callbackDontShow)) {
      callbackDontShow!(dontShowAgain);
    }

    if (_.isFunction(callbackConfirm)) {
      callbackConfirm();
    }

    dialog.close();
  }

  function renderDialogContent(): JSX.Element {
    return (
      <article data-testid="confirmActionDialog">
        <header>
          <h2>{title}</h2>
        </header>
        <section>
          <ol className="basic-data">
            <li>
              <span>{content}</span>
            </li>
          </ol>
        </section>
        <div className="actions">
          {dontShowAvailable && (
            <div className="dont-show">
              <input
                id="dontShow"
                type="checkbox"
                data-testid="dontShowAgain"
                onChange={handleChange}
                checked={dontShowAgain}
              />
              <label htmlFor="dontShow">{rootStore.getTranslation("shared.confirm.dont_show")}</label>
            </div>
          )}
          {showCancel && (
            <button data-testid="cancelActionButton" onClick={cancelAction}>
              {labelCancel}
            </button>
          )}
          {showConfirm && (
            <button data-testid="confirmActionButton" className="button-primary" onClick={confirmAction}>
              {labelConfirm!}
            </button>
          )}
        </div>
      </article>
    );
  }

  return (
    <Fragment>
      <Dialog content={renderDialogContent()} />
    </Fragment>
  );
};
