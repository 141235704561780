import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { SubscribeStoreContext } from "./subscribeStore";
import classNames from "classnames";

/**
 * A component that shows popup displaying alert/unsubscribe buttons
 */
export const Popup = observer(() => {
  const rootStore = useContext(RootContext);
  const subscribeStore = useContext(SubscribeStoreContext);
  const [showPopup, shouldShowPopup] = useState(true);

  function subscribe() {
    subscribeStore.subscribe();
    shouldShowPopup(false);
  }

  function unSubscribe() {
    subscribeStore.unSubscribe();
    shouldShowPopup(false);
  }

  return (
    <Fragment>
      {showPopup && (
        <div className="subscribers-popup">
          <div className="popup-content">
            {!subscribeStore.hasSubscribed() && (
              <span>{rootStore.getTranslation("details.alerts.subscriber_note")}</span>
            )}
            <div className="l-center-align">
              {!subscribeStore.isLoading() && !subscribeStore.hasSubscribed() && (
                <button data-testid="subscribe" onClick={subscribe}>
                  {rootStore.getTranslation("details.alerts.alert_me")}
                </button>
              )}
              {!subscribeStore.isLoading() && subscribeStore.hasSubscribed() && (
                <button data-testid="unSubscribe" onClick={unSubscribe}>
                  {rootStore.getTranslation("details.alerts.unsubscribe")}
                </button>
              )}
              {subscribeStore.isLoading() && <div className="l-margin-default spinner"></div>}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that displays subscribe functionality
 */
export const Subscribe = observer(() => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.getUserStore();
  const subscribeStore = useContext(SubscribeStoreContext);
  const [showPopup, showOrHidePopup] = useState(false);

  return (
    <Fragment>
      {userStore.getCurrentUser() && (
        <li className="action-item subscribers" onMouseLeave={() => showOrHidePopup(false)}>
          <a
            data-testid="handle-subscription"
            className={classNames("icon icon-alert", {
              yellow: subscribeStore.hasSubscribed(),
            })}
            onClick={() => showOrHidePopup(!showPopup)}
          ></a>
          <span className="count">{subscribeStore.getSubscriberCount()}</span>
          {showPopup && <Popup />}
        </li>
      )}
    </Fragment>
  );
});
