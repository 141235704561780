import { createContext } from "react";
import { action, runInAction, makeObservable } from "mobx";

import { AnalyticsService } from "../../js/services/AnalyticsService";
import { TCCSearchService } from "../../js/services/TCCSearchService";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { EntitiesStore } from "../../stores/entitiesStore";

import { IEntity, IGetResourcePath, ICollection, IPackageSearchQuery } from "../../models/dataModel";
import { AnalyticsEventTypeEnum } from "../../models/enums";

/**
 * A store that can be used to fetch the most popular packages (based on user reviews) added to Tekla Warehouse.
 * It takes current locale into account.
 */
export class MostPopularEntitiesStore extends EntitiesStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.fetchData();
  }

  /**
   * Gets 5 the most popular packages supporting current locale.
   */
  @action
  public async fetchData() {
    this.dataFetched = false;
    this.entities = [];

    const queryParams: IPackageSearchQuery = {
      offset: 0,
      count: 5,
      showBinaryMetadata: true,
      sortBy: "reviewCount DESC",
    };

    const lang = this.rootStore.getLangKeyBySelectedLocale();
    if (lang) {
      queryParams.fq = `attribute:supportedLanguages:${lang}:boolean==true`;
    }
    const res = this.setPaths(
      await TCCSearchService.searchPackages(queryParams, true, this.rootStore.getUserStore().getCurrentUser()?.id),
    );

    runInAction(() => {
      this.dataFetched = true;
      this.entities = res;
    });
  }

  /**
   * Method that handles routing state when clicked in resource
   */
  public getResourcePath(resource: IEntity | ICollection): string {
    return "/catalog/details/" + resource.id;
  }

  /**
   * Sends analytics data for content viewed
   * @param resource
   */
  public async sendAnalyticsData(resource: IEntity | ICollection): Promise<any> {
    try {
      await await AnalyticsService.contentViewed(
        this.rootStore.getUserStore().getCurrentUser(),
        resource,
        AnalyticsEventTypeEnum.POPULAR_CONTENT_VIEWED,
      );
    } catch {
      console.log("Could not send analytics data");
    }
  }
}

/**
 * Context object for MostPopularEntitiesStore instances.
 */
export const MostPopularEntitiesContext = createStoreContext<MostPopularEntitiesStore>(MostPopularEntitiesStore);

/**
 * Context object for IGetResourcePath instances (of classes that implement IGetResourcePath interface).
 */
export const IGetResourcePathContext = createContext<IGetResourcePath | undefined>(undefined);
