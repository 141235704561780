import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";
import { UploadVersionStore } from "./uploadVersionStore";

import { AddFiles } from "./AddFiles";
import { VersionInformation } from "./VersionInformation";

/**
 * Component for the UploadVersion stage, includes the 'AddFiles' dialog and forms and version information.
 */
export const UploadVersion = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const canAddVersionInformation = version.getViewState() !== "partnerDownloadLink";

  return (
    <Fragment>
      <header className="step-main" data-testid="versionHeader">
        <h2>{rootStore.getTranslation("upload.content.add_content")}</h2>
      </header>
      <hr />
      <section className="files">
        <AddFiles />
      </section>
      <hr />
      {canAddVersionInformation && (
        <Fragment>
          <section className="version-metadata">
            <VersionInformation />
          </section>
          <hr />
        </Fragment>
      )}
    </Fragment>
  );
});
