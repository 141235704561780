import { IFilter } from "models/dataModel";
import { AvailableAttributes, AvailableSelectors } from "models/enums";
import _ from "underscore";

/**
 * Utility for converting data to IFilter
 * By default, items are joined by inner join
 */
export const FilterConverter = {
  /**
   *
   * @param name - name of attribute or selector
   * @param values - values for filter as array of string or number
   * @param comparators - array the same length as values
   * @param dataType - type of the attribute (optional, default value "boolean")
   * @param outerJoin - if only one of the given condition must be true (optional, default value false)
   * @param evaluateToTrue - boolean value the condition must evaluate to (optional, default value true)
   * @returns IFilter object
   */
  toIFilter(
    name: AvailableAttributes | AvailableSelectors,
    values: Array<string | number>,
    comparators: string[],
    dataType: string = "boolean",
    outerJoin: boolean = false,
    evaluateToTrue: boolean = true,
  ): IFilter | undefined {
    values = _.filter(values, (val) => val !== undefined);

    if (values.length == 0 || values.length !== comparators.length) {
      return undefined;
    }

    const valuesWithComparators: Array<{ value: string | number; comparator: string }> = [];

    values.map((value, index) => {
      valuesWithComparators.push({ value: value, comparator: comparators[index] });
    });

    return { name, valuesWithComparators, dataType, outerJoin, evaluateToTrue };
  },
};
