import { useContext } from "react";

import { IDropdownOption, IDropdownOptionGroup } from "models/dataModel";
import _ from "underscore";

import { ModusDropdownMultiple } from "components/ModusDropdownMultiple";
import { metadataConstants } from "constants/MetadataConstants";
import { observer } from "mobx-react";
import * as React from "react";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const UseCategoryFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const categoryOptions: IDropdownOptionGroup[] = _.map(
    metadataConstants.groupedUseCategoriesKeys,
    (categories, group) => {
      return {
        label: rootStore.getTranslation("useCategoryGroups." + group),
        options: _.map(categories, (category) => {
          return {
            value: category,
            label: rootStore.getTranslation("useCategories." + category),
          };
        }),
      };
    },
  );

  function handleCategoryChange(selectedOptions: readonly IDropdownOption[]) {
    !_.isEmpty(selectedOptions) ? store.setUseCategories(selectedOptions) : store.setUseCategories(undefined);
  }
  return (
    <div className="search-filter" data-testid="useCategoryFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.use_categories")}:</h5>
      <ModusDropdownMultiple
        placeholder={rootStore.getTranslation("placeholders.filter-options.useCategories")}
        className="dropdown-use-categories"
        inputId="use-categories"
        options={categoryOptions}
        onChange={handleCategoryChange}
        selectedValues={store.getUseCategories()}
        isSearchable={true}
      />
    </div>
  );
});
