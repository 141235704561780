import _ from "underscore";

import { TCCAclDS } from "../data-source/TCCAclDS";
import { TCCEntityDS } from "../data-source/TCCEntityDS";
import { TCCItemService } from "../services/TCCItemService";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ICollection, IEntity, ICollectionAcl } from "../../models/dataModel";
import { ContentTypeEnum, ObjectTypeEnum } from "../../models/enums";
import { TCCBaseAccessLevelEnum } from "../../models/TCCdataModel";

/**
 * Gets ACL for the given source
 * @param {string} sourceId
 * @param {string} sourceType
 * @param {string} [performAsId]
 * @returns an ACL object, or null if source type invalid
 */
function getAcl(sourceId, sourceType, performAsId) {
  var data = { withExternalResourceType: true, useNewFormat: true };
  if (!!performAsId) data = { ...data, performAs: performAsId };

  if (sourceType === "collection") {
    return TCCAclDS.getCollectionAcl(sourceId, data);
  } else if (sourceType === "package") {
    return TCCAclDS.getPackageAcl(sourceId, data);
  } else if (sourceType === "entity") {
    return TCCAclDS.getVersionAcl(sourceId, data);
  }
  return null;
}

/**
 * Clones ACL from source to target
 * @param {string} sourceId
 * @param {string | null} sourceType
 * @param {string} targetId
 * @param {string | null} targetType
 * @param {string} performAsId
 * @returns nothing
 */
function cloneAcl(sourceId, sourceType, targetId, targetType, performAsId) {
  return getAcl(sourceId, sourceType, performAsId).then(function (acl) {
    var aclData = {};
    aclData.baseAccessLevel = acl.baseAccessLevel;
    aclData.viewers = acl.viewers;
    aclData.editors = acl.editors;
    aclData.finders = acl.finders;
    aclData.isPrivate = acl.isPrivate;
    setAcl(targetId, targetType, aclData, performAsId);
  });
}

/**
 * Clones the ACL from a collection to a package item and its versions.
 * @param {Object} collection - The collection object.
 * @param {Object} packageItem - The package item object.
 */
function cloneAclFromCollection(collection, packageItem) {
  cloneAcl(collection.id, "collection", packageItem.id, "package");
  searchVersions(packageItem.id).then(function (versions) {
    versions.forEach(function (version) {
      cloneAcl(collection.id, "collection", version.id, "entity");
    });
  });
}

/**
 * Sets ACL for the given target
 * @param {string} targetId
 * @param {string} targetType
 * @param {{ viewers: Array; editors: Array; finders: Array; isPrivate: boolean}} acl
 * @param {string} performAsId
 * @returns nothing
 */
function setAcl(targetId, targetType, acl, performAsId) {
  if (targetType === "collection") {
    return TCCAclDS.setCollectionAcl(targetId, acl, performAsId);
  } else if (targetType === "package") {
    return TCCAclDS.setPackageAcl(targetId, acl, performAsId);
  } else if (targetType === "entity") {
    return TCCAclDS.setVersionAcl(targetId, acl, performAsId);
  }
  return null;
}

function searchPackages(parentCollectionId) {
  var searchQuery = { fq: "parentCollectionId==" + parentCollectionId };
  var contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE + ")";
  searchQuery.fq = searchQuery.fq + ";" + contentTypeFiql;
  searchQuery.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
  return TCCItemService.searchItems(searchQuery);
}

/**
 * Searches for versions based on the parent collection ID.
 * @param {string} parentCollectionId - The ID of the parent collection.
 * @returns {Promise<Array>} - A promise that resolves to an array of versions.
 */
function searchVersions(parentCollectionId) {
  var searchQuery = { fq: "parentCollectionId==" + parentCollectionId };
  var contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION + ")";
  searchQuery.fq = searchQuery.fq + ";" + contentTypeFiql;
  searchQuery.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
  return TCCEntityDS.searchEntities(searchQuery);
}

/**
 * Sets ACL to packages and versions.
 * If package is banned, it will not set ACL.
 * @param {Object} data - The data object.
 * @param {Object} aclData - The ACL data object.
 */
function setAclToPackagesAndVersions(data, aclData) {
  searchPackages(data.subjectId).then(function (packages) {
    _.each(packages, function (packageItem) {
      if (packageItem.baseAccessLevel !== TCCBaseAccessLevelEnum.BANNED) {
        setAcl(packageItem.id, packageItem.subjectClass, aclData).then(function () {
          searchVersions(packageItem.id).then(function (versions) {
            versions.forEach(function (version) {
              if (version.baseAccessLevel !== TCCBaseAccessLevelEnum.BANNED) {
                setAcl(version.id, "entity", aclData);
              }
            });
          });
        });
      }
    });
  });
}

/**
 * Sets the ACL (Access Control List) recursively for the given data and linked resources collection.
 * @param {Object} data - The data object containing viewers, editors, finders,
 *                        subjectId, subjectClass, and isPrivate properties.
 * @param {Object} linkedResourcesCollection - The linked resources collection object.
 * @returns {Promise} A promise that resolves when the ACL is set.
 */
function setRecursively(data, linkedResourcesCollection) {
  var aclData = {};
  aclData.baseAccessLevel = data.baseAccessLevel;
  aclData.viewers = data.viewers;
  aclData.editors = data.editors;
  aclData.finders = data.finders;
  aclData.isPrivate = true;
  return setAcl(data.subjectId, data.subjectClass, aclData).then(function () {
    if (linkedResourcesCollection) {
      setAcl(linkedResourcesCollection.id, "collection", aclData).then(function () {
        return setAclToPackagesAndVersions(data, aclData);
      });
    } else {
      return setAclToPackagesAndVersions(data, aclData);
    }
  });
}

export const TCCAclService = {
  getAcl: getAcl,
  setRecursively: setRecursively,
  cloneAcl: cloneAcl,
  cloneAclFromCollection: cloneAclFromCollection,
};
