import * as React from "react";
import { Fragment, useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";

import { ITabListItem } from "../../models/dataModel";
import { TabNavigationStore, TabNavigationStoreContext } from "./tabNavigationStore";

/**
 * Renders a tab navigation component.
 *
 * @param {Object} props - The component props.
 * @param {ITabListItem[]} props.tabList - The list of tab items.
 * @param {string} [props.navStyle] - The style for the navigation.
 * @param {string} [props.listStyle] - The style for the tab list.
 * @returns {JSX.Element} The rendered tab navigation component.
 */
export const TabNavigation = observer(
  ({ ...props }: { tabList: ITabListItem[]; navStyle?: string; listStyle?: string }) => {
    const rootStore: RootStore = useContext(RootContext);
    const store: TabNavigationStore = useContext(TabNavigationStoreContext);

    const navStyle = props.navStyle ? props.navStyle : "tabs";
    const listStyle = props.listStyle ? props.listStyle : "";

    React.useEffect(() => {
      function setDefaultTabIfNotSelectedOrCantAccess() {
        const activeTab = _.find(props.tabList, (tab: ITabListItem) => tab.name === store.getActiveTabName());

        if (props.tabList && (!store.getActiveTabName() || !activeTab?.canAccess)) {
          props.tabList.map((item: ITabListItem) => {
            if (item.isDefault) {
              store.setActiveTabName(item.name);
            }
          });
        }
      }

      setDefaultTabIfNotSelectedOrCantAccess();
    }, [store.getActiveTabName()]);

    function isActiveTab(tabListItem: ITabListItem) {
      return (
        (!!store.getActiveTabName() && store.getActiveTabName() === tabListItem.name) ||
        (!store.getActiveTabName() && tabListItem.isDefault)
      );
    }

    function getLabelName(translationKey: string, additionalString?: string) {
      return additionalString
        ? rootStore.getTranslation(translationKey) + additionalString
        : rootStore.getTranslation(translationKey);
    }

    return (
      <nav className={navStyle}>
        <ul className={listStyle}>
          {props.tabList.map((item: ITabListItem, index: number) => (
            <Fragment key={index}>
              {item.canAccess && (
                <li key={index} className={classNames("tab-item", { active: isActiveTab(item) })}>
                  <a onClick={() => store.setActiveTabName(item.name)} data-testid={"tab-" + item.name}>
                    {getLabelName(item.translationKey, item.additionalString)}
                  </a>
                </li>
              )}
            </Fragment>
          ))}
        </ul>
      </nav>
    );
  },
);
