import { alert, Notice, Options } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/mobile/dist/PNotifyMobile.css";
import { RootStore } from "./rootStore";

/**
 * A store responsible for displaying notifications.
 */
export class NotificationChannelStore {
  /**
   * RootStore
   */
  private rootStore: RootStore;

  /**
   * Public constructor
   * @param rootStore root store
   */
  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  /**
   * Display notification of type "notice"
   * @param text text to be displayed
   */
  public notice(text: string) {
    this.showNotification("notice", text);
  }

  /**
   * Display notification of type "info"
   * @param text text to be displayed
   */
  public info(text: string) {
    this.showNotification("info", text);
  }

  /**
   * Display notification of type "error"
   * @param text text to be displayed
   */
  public error(text: string) {
    this.showNotification("error", text);
  }

  /**
   * Display notification of type "success"
   * @param text text to be displayed
   */
  public success(text: string) {
    this.showNotification("success", text);
  }

  /**
   * Displays notification
   * @param options Pnotify's Options object
   * @param callback Function to be used after showing notification
   */
  public show(options: Options, callback?: (notice: Notice) => void) {
    const notice = alert(options);
    if (callback) {
      callback(notice);
    }
  }

  private getTitle(key: string) {
    return this.rootStore.getTranslation(`shared.notification_channel.title_${key}`);
  }

  private showNotification(type: Options["type"], text: string) {
    if (document && document.body) {
      alert({
        text: text,
        textTrusted: true,
        title: this.getTitle((type || "info").toString()).toUpperCase(),
        titleTrusted: true,
        icon: false,
        hide: true,
        remove: true,
        type: type || "info",
        delay: 3000,
        sticker: false,
        width: "320px",
        icons: {
          closer: "icon-remove white",
        },
      });
    }
  }
}
