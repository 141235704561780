import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useEffect } from "react";

import { RootContext, RootStore } from "../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "./contentManagerStore";

import { SearchResults } from "./SearchResults";
import { SidePane } from "./SidePane";

import { Actions } from "./actions/Actions";

const MainView = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const contentManagerStore: ContentManagerStore = useContext(ContentManagerStoreContext);

  useEffect(() => {
    // Make initial search
    contentManagerStore.search();
  }, [contentManagerStore.getSearchTarget()]);

  return (
    <div className="content-manager-main">
      {!contentManagerStore.isQuerying() && contentManagerStore.getResults().length == 0 ? (
        <span className="label results-count" data-testid="noResultsMessage">
          {rootStore.getTranslation("profile.admin.content_manager.helpers.no_content_found")}
        </span>
      ) : (
        <>
          <Actions />
          <SearchResults />
        </>
      )}
    </div>
  );
});

export const ContentManager = () => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <Fragment>
      {rootStore.getUserStore().isUserAdmin() && (
        <ContentManagerStoreContext.Provider value={new ContentManagerStore(rootStore)}>
          <div className="content-manager" data-testid="contentManager">
            <div className="content-manager-header">
              <h2>{rootStore.getTranslation("profile.admin.content_manager.title")}</h2>
            </div>
            <div className="content-manager-body">
              <SidePane />
              <MainView />
            </div>
          </div>
        </ContentManagerStoreContext.Provider>
      )}
    </Fragment>
  );
};
