import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";

import { NewRepository } from "../../js/services/NewRepository";

import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { PackagePageStore, PackagePageStoreContext } from "../../package/packagePageStore";
import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStore, EditorModeStoreContext } from "./editorModeStore";

import { CodeNameField } from "../../components/immutability/CodeNameField";
import { Dialog } from "../../dialogs/Dialog";
import { Spinner } from "../../components/Spinner";

import { DialogTypeEnum } from "../../models/enums";
import { IEntity } from "../../models/dataModel";
import { isUniqueCodeName, isValidCodeName, suggestCodeNameWithoutPrefix } from "../../utils/Immutability";

/**
 * A component that displays toggle buttons
 */
export const AddCodeNameForPackageDialog = observer(({ contentItem }: { contentItem: IEntity }) => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const dialog: DialogStore = useContext(DialogContext);

  const [codeNameWithoutPrefix, setCodeNameWithoutPrefix] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isValidCodeNameFormat, setIsValidCodeNameFormat] = useState(false);
  const [codeNameIsUnique, setCodeNameIsUnique] = useState(true);

  async function generateCodeName() {
    setIsLoading(true);
    setCodeNameWithoutPrefix(await suggestCodeNameWithoutPrefix(contentItem.title || ""));
    setIsLoading(false);
  }

  async function checkCodeNameIsUnique() {
    setIsLoading(true);
    if (isValidCodeNameFormat) {
      setCodeNameIsUnique(await isUniqueCodeName(codeNameWithoutPrefix));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    generateCodeName();
  }, []);

  useEffect(() => {
    setIsValidCodeNameFormat(isValidCodeName(codeNameWithoutPrefix));
    checkCodeNameIsUnique();
  }, [codeNameWithoutPrefix]);

  async function handleAddCodeName(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    try {
      await NewRepository.addCodeNameForPackage(contentItem.id, codeNameWithoutPrefix);
    } catch {
      console.error("Failed to add code name for package");
    } finally {
      editorModeStore.setEditorMode(true);
      setIsLoading(false);
      packagePageStore.reloadData(DialogTypeEnum.OTHER);
      dialog.close();
    }
  }

  return (
    <Dialog
      content={
        <article data-testid="addCodeNameDialog">
          <header className="dialog-header">
            <h2 style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}>
              {rootStore.getTranslation("immutability.add_code_name_for_package")}
            </h2>
          </header>
          <form onSubmit={handleAddCodeName} data-testid="addCodeNameForm">
            <section className="dialog-body" style={{ paddingTop: "70px" }}>
              <p style={{ paddingLeft: "1em", paddingRight: "1em" }}>{rootStore.getTranslation("immutability.info")}</p>
              <p style={{ paddingLeft: "1em", paddingRight: "1em", paddingBottom: "0.5em" }}>
                {rootStore.getTranslation("upload.package.code_name_info")}
              </p>
              <div className="add-code-name">
                <ul style={{ margin: "1em" }}>
                  <CodeNameField
                    codeName={codeNameWithoutPrefix}
                    setCodeName={(e) => setCodeNameWithoutPrefix(e.target.value)}
                    isValid={isValidCodeNameFormat}
                    alreadyInUse={!codeNameIsUnique}
                    showErrorOnUnfocus={false}
                  />
                </ul>
              </div>
            </section>
            <div className="actions">
              {isLoading ?
                <div style={{ padding: "1em" }}>
                  <Spinner loadingWhat="addingCodeName" />
                </div>
                : (
                  <Fragment>
                    <button type="reset"
                      disabled={isLoading || !(codeNameIsUnique && isValidCodeNameFormat)}
                      onClick={() => dialog.close()} data-testid="cancelButton"
                    >
                      {rootStore.getTranslation("actions.cancel")}
                    </button>
                    <button
                      className="button-primary"
                      type="submit"
                      disabled={isLoading || !(codeNameIsUnique && isValidCodeNameFormat)}
                      data-testid="saveCodeNameButton"
                    >
                      {rootStore.getTranslation("actions.save")}
                    </button>
                  </Fragment>
                )}
            </div>
          </form>
        </article>
      }
    />
  );
});
