import { createStoreContext } from "../../stores/rootStore";
import { action, observable, makeObservable } from "mobx";
import _ from "underscore";

import { IComment } from "../../models/dataModel";

/**
 * A store that holds the state of the 'View replies' dialog.
 */
export class ReplyDialogStore {
  /**
   * State of the dialog
   */
  @observable private dialogState = "viewReplies";

  /**
   * Selected comment to reply to.
   */
  @observable private selectedReply: IComment | undefined = undefined;

  /**
   * List of replies to parent comment.
   */
  @observable private replies: IComment[] = [];

  constructor() {
    makeObservable(this);
  }

  /**
   * Gets the dialog state
   * @returns state of the dialog (viewReplies/addReply/editReply)
   */
  public getDialogState(): string {
    return this.dialogState;
  }

  /**
   * Sets the dialog state when in dialog
   * @param state of the dialog (viewReplies/addReply/editReply)
   */
  @action
  public setDialogState(state: string) {
    this.dialogState = state;
  }

  /**
   * Returns a list of replies to the parent comment
   */
  public getReplies(): IComment[] {
    return this.replies;
  }

  /**
   * Sets the replies
   * @param replies list of replies
   */
  @action
  public setReplies(replies: IComment[]) {
    this.replies = replies;
  }

  /**
   * Returns the selected reply comment.
   */
  public getSelectedReply(): IComment {
    return this.selectedReply!;
  }

  /**
   * Sets the selected comment to reply to
   * @param reply selected comment to reply to
   */
  @action
  public setSelectedReply(reply: IComment) {
    this.selectedReply = reply;
  }

  /**
   * Updates the list of replies.
   */
  @action
  public async updateReplyList(updatedReply: IComment, deleted: boolean, commentText: string) {
    if (deleted) {
      this.replies = _.without(this.replies, updatedReply);
    } else {
      _.map(this.replies, (reply: IComment) => {
        if (reply.id === updatedReply.id) {
          reply.text = commentText;
        }
      });
    }
  }
}

/**
 * Context object for ReplyDialogStore instances.
 */
export const ReplyDialogStoreContext = createStoreContext<ReplyDialogStore>(ReplyDialogStore);
