import _ from "underscore";

import { TCCCollectionDS } from "../data-source/TCCCollectionDS";
import { contentIdFilter } from "../../utils/filters";
import { NewCollectionConverter } from "../converters/NewCollectionConverter";
import { LinkedResourcesCollectionFinder } from "../services/LinkedResourcesCollectionFinder";
import { TCCPackageService } from "./TCCPackageService";
import { NewRepository } from "./NewRepository";
import { TCCBaseAccessLevelEnum } from "../../models/TCCdataModel";

export const TCCCollectionService = {
  create: function (data) {
    return TCCCollectionDS.createCollection(NewCollectionConverter.toTCC(data), data.creator.id).then(function (res) {
      return contentIdFilter(res["location"]);
    });
  },
  updateVisibilityRecursively: function (data) {
    var convertedData = NewCollectionConverter.toTCC(data);
    var updateData = _.pick(convertedData, "id", "baseAccessLevel", "isHidden");
    var queryParam = { recursive: JSON.stringify({ propagateTo: "all" }) };
    return TCCCollectionDS.updateCollection(updateData, queryParam)
      .then(function () {
        return LinkedResourcesCollectionFinder.findAndUpdateOwnerAndVisibility(data);
      })
      .then(function () {
        return TCCCollectionDS.getCollection(data.id, { performAs: data.modifier.id });
      })
      .then(NewCollectionConverter.fromTCC);
  },
  updateVisibilityRecursivelyExcludingBanned: function (data) {
    var convertedData = NewCollectionConverter.toTCC(data);
    var updateData = _.pick(convertedData, "id", "baseAccessLevel", "isHidden");

    return TCCCollectionDS.updateCollection(updateData)
      .then(function () {
        return LinkedResourcesCollectionFinder.findAndUpdateOwnerAndVisibility(data);
      })
      .then(function () {
        return NewRepository.getPackagesForCollection(data.id);
      })
      .then(function (packages) {
        let promises = [];

        packages.map((pkg) => {
          if (pkg.baseAccessLevel !== TCCBaseAccessLevelEnum.BANNED) {
            promises.push(
              TCCPackageService.update(
                {
                  id: pkg.id,
                  visibility: data.visibility,
                  isHidden: data.isHidden,
                  modifier: data.modifier,
                },
                true,
                false
              ),
            );
          }
        });

        return Promise.all(promises);
      })
      .then(function () {
        return TCCCollectionDS.getCollection(data.id, { performAs: data.modifier.id });
      })
      .then(NewCollectionConverter.fromTCC);
  },
  update: function (data) {
    var convertedData = NewCollectionConverter.toTCC(data);
    var queryParams = {};
    if (data.doNotNotify) {
      queryParams = { doNotNotify: true };
    }
    return TCCCollectionDS.setCollection(convertedData, queryParams)
      .then(function () {
        return TCCCollectionDS.getCollection(convertedData.id, { performAs: data.modifier.id });
      })
      .then(NewCollectionConverter.fromTCC);
  },
  /**
   * Searches for collections according to the given parameters
   * @param {Object} data - the search parameters
   * @param {boolean} dontUnwrap - whether to unwrap the response
   * @param {string} performAsId - the id of the user performing the action
   * @returns {Promise<*>} - the result of the search operation
   */
  searchCollections: function (data, dontUnwrap, performAsId) {
    return TCCCollectionDS.searchCollections(data, dontUnwrap, performAsId);
  },
  /**
   * Fetches a collection from the backend
   * @param {string} collectionId
   * @param {string} performAsId
   * @param {*?} params
   * @returns a collection
   */
  get: function (collectionId, performAsId, params) {
    if (performAsId) params = { ...(params || {}), performAs: performAsId };
    return TCCCollectionDS.getCollection(collectionId, params).then(NewCollectionConverter.fromTCC);
  },
  /**
   * Deletes the given collection
   * @param {*} data - the collection to delete
   * @param {boolean} updateRecursively - whether to update the collection recursively
   * @param {string} performAsId - the id of the user performing the action
   * @returns {Promise<*>} - the result of the delete operation
   */
  remove: function (data, updateRecursively, performAsId) {
    var params = {};
    if (updateRecursively) {
      params = { recursive: JSON.stringify({ propagateTo: "entities" }) };
    }
    if (performAsId) {
      params = { ...params, performAs: performAsId };
    }
    return TCCCollectionDS.deleteCollection(data, params);
  },
  addToCollection: function (data) {
    var list = [];
    list.push(data.childId);
    return TCCCollectionDS.updateCollectionChildren(data.parentCollectionId, {
      childIds: list,
    });
  },
  addReview: function (collectionId, review) {
    return TCCCollectionDS.addReview(collectionId, review);
  },
  deleteReview: function (collectionId) {
    return TCCCollectionDS.deleteReview(collectionId);
  },
  getReviews: function (collectionId) {
    return TCCCollectionDS.getReviews(collectionId);
  },
  getSubscribers: function (collectionId) {
    return TCCCollectionDS.getSubscribers(collectionId);
  },
  getAcl: function (collectionId, data) {
    return TCCCollectionDS.getAcl(collectionId, data);
  },
  setAcl: function (collectionId, data) {
    return TCCCollectionDS.setAcl(collectionId, data);
  },
  getAttributes: function (collectionId) {
    return TCCCollectionDS.getAttributes(collectionId);
  },
  setAttributes: function (collectionId, data) {
    return TCCCollectionDS.setAttributes(collectionId, data);
  },
  updateAttributes: function (collectionId, data, performAsId) {
    return TCCCollectionDS.updateAttributes(collectionId, data, performAsId);
  },
  createBinary: function (collectionId, data, file) {
    return TCCCollectionDS.createBinary(collectionId, data, file);
  },
  createBinaryWithMime: function (collectionId, data, file, fileName, mimeType) {
    return TCCCollectionDS.createBinaryWithMime(collectionId, data, file, fileName, mimeType);
  },
  getBinaries: function (collectionId) {
    return TCCCollectionDS.getBinaries(collectionId);
  },
  getBinary: function (collectionId, binaryName, data) {
    return TCCCollectionDS.getBinary(collectionId, binaryName, data);
  },
  updateBinary: function (collectionId, data, file, fileName) {
    return TCCCollectionDS.updateBinary(collectionId, data, file, fileName);
  },
  deleteBinary: function (collectionId, binaryName) {
    return TCCCollectionDS.deleteBinary(collectionId, binaryName);
  },
};
