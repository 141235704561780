import { UploadJob } from "../factories/UploadJob";

export const UploadJobConverter = {
  fromLocal: function (data) {
    data = data || {};
    return UploadJob(data);
  },
  toLocal: function (data) {
    data = data || {};
    return {
      id: data.id,
    };
  },
};
