import * as React from "react";
import { Fragment } from "react";

import { WarehouseVersion } from "../js/utils/WarehouseVersion";
import { RootContext, RootStore } from "../stores/rootStore";

import { whyNavigationInfo } from "./Why";
import { usageBasicsNavigationInfo } from "./UsageBasics";
import { gettingStartedNavigationInfo } from "./GettingStarted";
import { contentNavigationInfo } from "./Content";
import { usingContentNavigationInfo } from "./UsingContent";
import { creatingContentNavigationInfo } from "./CreatingContent";
import { applicationDevelopersNavigationInfo } from "./ApplicationDevelopers";
import { SideNavigationLink } from "./SideNavigationLink";

export const AboutPage = ({ view }: { view: string }) => {
  const rootStore: RootStore = React.useContext(RootContext);
  const viewList = {
    why: whyNavigationInfo,
    usageBasics: usageBasicsNavigationInfo,
    gettingStarted: gettingStartedNavigationInfo,
    content: contentNavigationInfo,
    usingContent: usingContentNavigationInfo,
    creatingContent: creatingContentNavigationInfo,
    applicationDevelopers: applicationDevelopersNavigationInfo,
  };

  return (
    <Fragment>
      <div id="content" className="catalog learn" data-testid="aboutPage">
        <div id="sidebar">
          <nav id="sub-navigation">
            <ul>
              {Object.keys(viewList).map((key, index) => (
                <div key={index}>
                  <SideNavigationLink linkData={viewList[key]} />
                </div>
              ))}
            </ul>
            <div className="warehouse-service-info" data-testid="WarehouseServiceVersionInfo">
              <span className="small">
                {`${rootStore.getTranslation("shared.footer.tekla_warehouse")} ${WarehouseVersion.getText()}`}
              </span>
            </div>
          </nav>
        </div>
        <div id="content" className="content-sidebar">
          {viewList[view].component}
        </div>
      </div>
    </Fragment>
  );
};
