import { LocalRequestBuilder } from "../LocalRequestBuilder";
import { LocalResponseProcessor } from "../LocalResponseProcessor";

export const LocalVersionsDS = {
  getVersion: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/getVersion", data));
  },
  setVersion: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/setVersion", data));
  },
  deleteVersion: function (data) {
    return LocalResponseProcessor.process(LocalRequestBuilder.doPost("/TeklaWarehouse/deleteVersion", data));
  },
};
