import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../stores/rootStore";
import { TranslatedHtml } from "../components/TranslatedHtml";

/**
 * A component that shows info about local service
 */
export const LocalServiceInfo = observer(() => {
  const rootStore = useContext(RootContext);
  const store = rootStore.getLocalServiceStore();

  return (
    <div>
      {!store.isLocalServiceAccessible() && (
        <div className="local-service-warning background-blue">
          <span className="small">
            <TranslatedHtml entry="shared.local_service.download_to_unlock_all_features_html" />
          </span>
        </div>
      )}
      {store.isLocalServiceAccessible() && !store.getLocalService().validServiceVersion && (
        <div className="local-service-warning background-blue">
          <span className="small">
            <TranslatedHtml entry="shared.local_service.service_out_of_date_html" />
          </span>
        </div>
      )}
    </div>
  );
});
