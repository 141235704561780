import _ from "underscore";
import { startsWith } from "underscore.string";
import { SortOptions } from "../../utils/SortOptions";
import { AvailableAttributes, SearchPageOrderEnum } from "models/enums";

/**
 * Converts a sort string to the corresponding database query sort attribute.
 * @param {string} sortStr - The sort string to be converted.
 * @param {boolean} local - Indicates whether the sort is for local or online options.
 * @returns {string} - The converted sort attribute for the database query.
 */
function convertSortStr(sortStr, local) {
  const options = SortOptions.get();
  if (local) {
    if (
      !_.find(options.local, function (s) {
        return s.rule === sortStr;
      })
    ) {
      sortStr = options.default.local;
    }
  } else {
    if (
      !_.find(options.online, function (s) {
        return s.rule === sortStr;
      })
    ) {
      sortStr = options.default.online;
    }
    if (sortStr === SearchPageOrderEnum.RELEVANCE) {
      return "relevance DESC";
    }
    if (sortStr === SearchPageOrderEnum.POPULARITY) {
      return "popularity DESC";
    }
  }
  var ret = "";
  var sortAtr = sortStr.indexOf("-") === 0 ? sortStr.substring(1) : sortStr;
  if (sortStr.indexOf("-") === 0) {
    ret = sortAtr + " DESC";
  } else {
    ret = sortAtr + " ASC";
  }
  ret = ret.replace("modifiedAt", "modifyTime");
  ret = ret.replace("createdAt", "createTime");
  return ret;
}

/**
 * Checks if the given filter is an exclude filter.
 * @param {string} filter - The filter to check.
 * @returns {boolean} - True if the filter is an exclude filter, false otherwise.
 */
function isExcludeFilter(filter) {
  return startsWith(filter, "excl_");
}

/**
 * Resolves the exclude filter name by removing the "excl_" prefix.
 *
 * @param {string} filter - The filter to resolve.
 * @returns {string} - The resolved filter name.
 */
function resolveExcludeFilterName(filter) {
  return filter.replace("excl_", "");
}

/**
 * Abstracts getting the query parameters for filters
 * @param {*} filter - filter containing the values
 * @param {*} attribute - string containing the name of attribute being filtered
 * @returns array of strings containing the formatted query parameters
 */
function getAttributeFilterFiqls(filter) {
  let filterFiqls = [];
  let includeFiqls = [];
  let excludeFiqls = [];

  filter.valuesWithComparators.forEach((item) => {
    if (!_.isString(item.value)) {
      return [];
    }
    if (isExcludeFilter(item.value)) {
      const valueWihtoutPrefix = resolveExcludeFilterName(item.value);
      excludeFiqls.push(
        `attribute:${filter.name}:${valueWihtoutPrefix}:${filter.dataType}${item.comparator}${filter.evaluateToTrue}`,
      );
    } else {
      // If the filter is a string and the comparator is =exists=, we need to add an additional check to ensure the value is not empty
      if (filter.dataType === "string" && item.comparator === "=exists=" && !!filter.evaluateToTrue) {
        includeFiqls.push(
          `attribute:${filter.name}:${item.value}:${filter.dataType}${item.comparator}${filter.evaluateToTrue};attribute:${filter.name}:${item.value}:string!=""`
        );
      } else {
        includeFiqls.push(
          `attribute:${filter.name}:${item.value}:${filter.dataType}${item.comparator}${filter.evaluateToTrue}`,
        );
      }
    }
  });

  if (excludeFiqls.length > 0) {
    if (includeFiqls.length > 0) {
      filterFiqls.push(`(${includeFiqls.join()});(${excludeFiqls.join(";")})`);
    } else {
      filterFiqls.push(`(${excludeFiqls.join(";")})`);
    }
  } else {
    filterFiqls.push(`(${includeFiqls.join()})`);
  }

  return filterFiqls;
}

/**
 * Function to convert a selector and values with comparators to a query string
 * @param {IFilter} filter
 * @returns query string for given selector
 */
function getFilterFiqls(filter) {
  let fiqls = [];

  filter.valuesWithComparators.forEach((item) => {
    fiqls.push(`${filter.name}${item.comparator}${item.value}`);
  });

  return filter.outerJoin ? `(${fiqls.join(",")})` : `(${fiqls.join(";")})`;
}

/**
 * Converts input from filters into strings that can be passed as parameters in a query
 *
 * @param {*} filters - nested object with properties "attributes" and "selectors", which contain IFilter[]
 * @returns an object containing query parameters
 */
function toTCCFilters(filters) {
  let filterFiqls = [];
  let childFilterFiqls = [];

  _.map(filters.selectors, (selector) => {
    if (!!selector) {
      filterFiqls.push(getFilterFiqls(selector));
    }
  });

  if (!!filters.attributes) {
    _.map(filters.attributes, (attribute) => {
      if (!!attribute) {
        if (attribute.name == AvailableAttributes.SW_PRODUCTS || attribute.name == AvailableAttributes.SW_VERSIONS) {
          childFilterFiqls.push(getAttributeFilterFiqls(attribute));
        } else {
          filterFiqls.push(getAttributeFilterFiqls(attribute));
        }
      }
    });
  }

  const filterQuery = { filterQuery: filterFiqls.join(";"), childFilterQuery: childFilterFiqls.join(";") };

  return filterQuery;
}

/**
 * SearchAttributesConverter is a utility object that provides methods for converting search attributes.
 */
export const SearchAttributesConverter = {
  /**
   * Converts the given data to TCC format.
   * @param {Object} data - The data to convert.
   * @returns {Object} - The converted data in TCC format.
   */
  toTCC: function (data) {
    var returnData = _.omit(data, ["sortBy", "filters", "categories"]);

    let filterQuery = "";
    let childFilterQuery = "";

    returnData = _.extend(returnData, { sortBy: convertSortStr(data.sortBy) });

    if (data.filters) {
      ({ filterQuery, childFilterQuery } = toTCCFilters(data.filters));
    }

    if (filterQuery) {
      if (returnData.fq) {
        returnData.fq = "(" + returnData.fq + ");(" + filterQuery + ")";
      } else {
        _.extend(returnData, { fq: filterQuery });
      }
    }

    if (childFilterQuery) {
      _.extend(returnData, { anyChildFq: childFilterQuery });
    }

    if (returnData.attribute) {
      returnData.attribute = JSON.stringify(returnData.attribute);
    }

    return returnData;
  },
  toLocal: function (data) {
    var returnData = _.omit(data, ["sortBy", "filters", "categories"]);
    returnData = _.extend(returnData, { sortBy: convertSortStr(data.sortBy, true) });
    return returnData;
  },
};
