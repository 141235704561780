import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import { Link } from "react-router";

import { ICollection } from "../models/dataModel";
import { RootContext, RootStore } from "../stores/rootStore";
import { EditorModeStore, EditorModeStoreContext } from "./editor-mode/editorModeStore";

/**
 * A component that renders add content button
 */
export const AddContentButton = observer(({ collection }: { collection?: ICollection }) => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);

  const uploadPath = !!collection
    ? "/addPackage?" +
    new URLSearchParams({ collectionId: collection.id! }).toString() +
    (collection.isLocal ? "&" + new URLSearchParams({ isLocal: "true" }).toString() : "")
    : "";

  return (
    <Fragment>
      {editorModeStore.getEditorMode() && !!collection && rootStore.getUserStore().canUpload(collection) && (
        <Link to={uploadPath} className="button create" data-testid="create-package-button">
          {rootStore.getTranslation("collections.actions.add_content")}
        </Link>
      )}
    </Fragment>
  );
});
