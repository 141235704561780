/* eslint-disable @typescript-eslint/no-unused-vars */

import { IBinary } from "../../models/dataModel";
import { BinaryTypeEnum, ItemTypeEnum, ItemSourceEnum, PlatformEnum } from "../../models/enums";
import { IdGenerator } from "../../utils/IdGenerator";

export const Binary = {
  /**
   * Create a new binary object
   * @param {*} data
   * @returns {IBinary} binary object
   */
  create: function (data) {
    data = data || {};
    data.attributes = data.attributes || {};

    return {
      id: data._id || data.id,
      _id: data._id || data.id,
      blob: data.blob,
      ext: data.ext,
      file: data.file,
      url: data.url,
      contentUrl: data.contentUrl,
      location: data.location,
      reference: data.reference || IdGenerator.generateUUID(),
      creator: data.creator,
      modifier: data.modifier,
      createdAt: data.createdAt,
      modifiedAt: data.modifiedAt,
      jobResults: data.jobResults,
      versionId: data.versionId,
      isImmutable: data.isImmutable || false,
      attributes: {
        fileName: data.attributes.fileName,
        originalName: data.attributes.originalName,
        fileSize: data.attributes.fileSize,
        type: data.attributes.type || BinaryTypeEnum.UNKNOWN,
        platform: data.attributes.platform || PlatformEnum.ANY,
        itemType: data.attributes.itemType || ItemTypeEnum.NO_ACTION,
        itemSource: data.attributes.itemSource || ItemSourceEnum.UNKNOWN,
      },
    };
  },
};
