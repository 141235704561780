import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";

import { MyLikesContext } from "./myLikesStore";

import { ContentTypeSelector } from "../../components/search/ContentTypeSelector";
import { ResultList } from "../../components/search/ResultList";
import { ListStyleToggle } from "../../components/search/ListStyleToggle";
import { SortBy } from "../../components/search/SortBy";
import { SearchField } from "../../components/search/SearchField";
import { NoLikesMessage } from "./NoLikesMessage";

export const ResultsWrapper = observer(() => {
  const store = useContext(MyLikesContext);

  return (
    <Fragment>
      <section className="result-options likes-result-options">
        <div className="summary l-center">
          <NoLikesMessage />
          {store.hasLikedContent() && (
            <div className="sorting-options likes-sorting-options">
              <ListStyleToggle pageWhereUsed="likes" />
              <SearchField pageWhereUsed="likes" />
              <SortBy pageWhereUsed="likes" />
            </div>
          )}
          <ContentTypeSelector
            packagesCount={store.getPackagesLikedByMe().length}
            collectionsCount={store.getCollectionsLikedByMe().length}
            contentFinishedLoading={store.contentFinishedLoading()}
          />
        </div>
      </section>
      <ResultList
        pageWhereUsed="likes"
        packages={store.getPackagesLikedByMe()}
        collections={store.getCollectionsLikedByMe()}
        contentFinishedLoading={store.contentFinishedLoading()}
      />
    </Fragment>
  );
});
