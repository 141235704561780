 
export const NlAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["nl-NL"]) {
      window.locale["nl-NL"] = {};
    }

    window.locale["nl-NL"].about = {
      navigation: {
        why: "Waarom Tekla Warehouse?",
        usage_basics: "Basisprincipes van Tekla Warehouse",
        "usage_basics__using-content": "Inhoud gebruiken",
        "usage_basics__adding-content": "Inhoud toevoegen",
        usage_basics__collection: "Verzameling",
        "usage_basics__content-item": "Inhoudsitem",
        usage_basics__version: "Versie",
        getting_started: "Aan de slag",
        "getting_started__tekla-account-and-system-requirements": "Tekla account en systeemvereisten",
        "getting_started__tekla-warehouse-service-plugin": "Tekla Warehouse Service-plugin",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Hoe krijgt ik Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures en Tekla Warehouse",
        content: "Inhoud in Tekla Warehouse",
        "content__3d-products": "3D-modelproducten",
        "content__custom-components": "Gebruikerscomponenten",
        content__applications: "Applicaties",
        "content__profiles-shapes-etc": "Profielen, materialen, bouten, staven, enzovoort.",
        "content__model-templates": "Modeltemplates",
        "content__drawing-setup": "Tekeninginstelling",
        "content__report-templates-etc": "Lijsttemplates en attribuutbestanden",
        using_content: "Inhoud gebruiken",
        creating_content: "Inhoud maken",
        "creating_content__getting-started-with-content-creation": "Aan de slag met het maken van inhoud",
        "creating_content__expert-help-for-creating-content": "Hulp nodig van een expert bij het maken van inhoud?",
        application_developers: "Applicatieontwikkelingen voor Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Waarom Tekla Warehouse?",
        description_1:
          "Tekla Warehouse bevat applicaties, gebruikerscomponenten, onderdelen, profielen, materialen zoals staal- en betonkwaliteiten, bouten, staven, netten, vormen en templates voor Tekla Structures. Werkelijke onderdelen van leveranciers in de modellen leiden tot de grootste nauwkeurigheid en exacte materiaalgegevens voor planning en inkoop, en het gebruik van kant-en-klare inhoud versnelt het modelleren.",
        description_2:
          "Tekla Warehouse biedt een gecentraliseerde toegang tot deze inhoud die nu op een gestroomlijnde manier in gebruik kan worden genomen. De inhoud in Tekla Warehouse neemt voortdurend toe.",
        description_3: "Met Tekla Warehouse",
        feature_1:
          "Fabrikanten kunnen hun producten en applicaties ter beschikking stellen aan gebruikers die de exacte 3D-productmodellen in hun Tekla-modellen kunnen opnemen en later het fysieke product in de constructie kunnen gebruiken.",
        feature_2:
          "U kunt uw bedrijfsnetwerk gebruiken om de inhoud met collega's te delen en inhoud voor persoonlijk gebruik lokaal op te slaan",
        description_4:
          "De zoekfunctie in Tekla Warehouse maakt het precies zoeken wat u nodig hebt eenvoudig, ongeacht het inhoudstype.",
        description_5:
          "De plugin Tekla Warehouse Service is nodig om netwerk- en lokale verzamelingen in te schakelen.",
        view_licences_html: 'Bekijk <a href="/api/third_party_licenses.txt" target="_blank">licenties van derden</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Basisprincipes van Tekla Warehouse",
        using_content: {
          title: "Inhoud gebruiken",
          description_1:
            "U kunt de inhoud in online verzamelingen gebruiken, inhoud met uw collega's delen via netwerkverzamelingen en lokale verzamelingen voor persoonlijk gebruik gebruiken.",
          description_2:
            "Lokale en netwerkverzamelingen hebben de Tekla Warehouse Service nodig, die met Tekla Structures 20.1 Service Release 1 en latere Tekla Structures-versies wordt geïnstalleerd.",
          description_3:
            "Bepaalde Tekla-verzamelingen die gelokaliseerde omgevingsinhoud bevatten, worden met Tekla Structures geïnstalleerd.",
          description_4: "Raadpleeg voor meer informatie de paragraaf Inhoud gebruiken.",
        },
        adding_content: {
          title: "Inhoud toevoegen",
          description_1:
            'U kunt online verzamelingen gebruiken om uw inhoud naar alle Tekla Warehouse-gebruikers te publiceren. Als u inhoud als een organisatie wilt publiceren, moet uw organisatie aan account.tekla.com worden toegevoegd. Neem voor meer informatie contact op met uw lokale <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">Tekla-kantoor of -leverancier</a>.',
          description_2: "Raadpleeg voor meer informatie de paragraaf Inhoud maken.",
        },
        collection: {
          title: "Verzameling",
          feature_1:
            "Verzamelingen groeperen inhoudsitems zoals mappen en definiëren de toegangsrechten tot de inhoud (openbaar/privé).",
          feature_1_1:
            "Modelleurs kunnen de inhoud in online verzamelingen gebruiken, inhoud met collega's met netwerkverzamelingen delen en lokale verzamelingen voor persoonlijk gebruik gebruiken.",
          feature_2:
            "Inhoudsproviders kunnen online verzamelingen gebruiken om hun inhoud naar alle Tekla Warehouse-gebruikers te publiceren.",
          feature_3:
            "Lokale en netwerkverzamelingen hebben de plugin Tekla Warehouse Service nodig die met Tekla Structures 20.1 Service Release 1 en later wordt geïnstalleerd en ook apart kan worden geïnstalleerd.",
        },
        "content-item": {
          title: "Inhoudsitem",
          feature_1:
            "Een inhoudsitem is een logische groep bestanden. Een 3D-productendatabase, een modeltemplate, een tool, een groep gebruikerscomponenten of een profielendatabase zijn bijvoorbeeld inhoudsitems.",
          feature_2:
            "Inhoudsitems kunnen op type en gebruikssituaties worden gelabeld, waarmee gebruikers uw inhoud kunnen vinden.",
        },
        version: {
          title: "Versie",
          feature_1:
            "Een inhoudsitem kan vele versies tegelijk hebben die met verschillende Tekla Structures-versies worden gebruikt. De gebruiker kan de juiste versie op de inhoudspagina selecteren.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Aan de slag",
        tekla_account_and_system_requirements: {
          title: "Tekla account en systeemvereisten",
          register_atc_html:
            'U moet een Tekla account hebben om op Tekla Warehouse in te kunnen loggen. Als u zich nog niet hebt geregistreerd, doet u dat op <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>. (Dit is niet het oude Extranet-account.)',
          see_tua_for_prerequisites_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">vereisten voor het gebruik van Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Tekla Warehouse Service-plugin",
          description_1:
            "Tekla Warehouse maakt, naast het feit dat het een online opslag voor inhoud is, ook het volgende mogelijk:",
          feature_1: "Lokale verzamelingen voor persoonlijk gebruik",
          feature_2: "Netwerkverzamelingen om inhoud te delen met collega's die het bedrijfsnetwerk gebruiken",
          feature_3: "Eenvoudige installatie bij het gebruik of het maken van inhoud",
          feature_4: "Lokale en netwerkinhoud zoeken",
          description_2:
            "Dit is mogelijk met Tekla Warehouse Service, een plugin die als een lokale back-end werkt en met de website van Tekla Structures en Tekla Warehouse communiceert.",
          description_3:
            "Als Tekla Warehouse Service niet is geïnstalleerd of niet actief is, markeert Tekla Warehouse dit boven aan de pagina om het oplossen van problemen mogelijk te maken.",
          description_4:
            "Als er zich problemen met de service voordoen, kunt u opnieuw opstarten met het programma Services in Windows. Naam van de service is Tekla Warehouse. Controleer ook de beveiligingsinstellingen van uw browser.",
          description_5:
            "De website Tekla Warehouse kan zonder Tekla Warehouse Service of Tekla Structures worden gebruikt. De ervaring lijkt dan meer op een typische website voor inhoudsdeling waar bestanden handmatig worden geüpload en gedownload.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Hoe krijgt ik Tekla Warehouse Service?",
          description_1:
            "De plugin Tekla Warehouse Service wordt geïnstalleerd met Tekla Structures 20.1 Service Release 1 en later. U kunt Tekla Warehouse Service ook apart installeren om lokale en netwerkverzamelingen mogelijk te maken maar eenvoudig installatie- en uploadfuncties zijn alleen mogelijk met Tekla Structures 20.1 Service Release 1 en later.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">DOWNLOAD TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures en Tekla Warehouse",
          description_1:
            "Tekla Warehouse maakt rechtstreeks verbinding met Tekla Structures waardoor het eenvoudiger wordt om inhoud in gebruik te nemen of te uploaden. U kunt inhoud rechtstreeks uit uw model selecteren en ook inhoud rechtstreeks in het model invoegen zonder gedoe met import-/exportbestanden (Tekla Structures 20.1 Service Release 1 en later is hiervoor nodig). Alle benodigde bestanden zoals een gebruikerscomponent of een profiel kunnen in hetzelfde inhoudsitem worden opgenomen om te zorgen dat de zaken werken zoals bedoeld.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Inhoud in Tekla Warehouse",
        description_1:
          "Tekla Warehouse biedt verschillende typen inhoud. Hier kunt u een overzicht van de verschillende inhoudstypen vinden en meer informatie om aan de slag te kunnen.",
        description_2:
          'U kunt van Tekla Warehouse ook <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla-extensies</a> downloaden.',
        "3d_products": {
          title: "3D-modelproducten",
          image_subtitle:
            "Dat zijn objecten met een vaste geometrie, bijvoorbeeld instortvoorzieningen, bekisting en accessoires.",
          tua_link_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">het maken van een 3D-productdatabase in een Tekla Warehouse-verzameling</a>.',
        },
        custom_components: {
          title: "Gebruikerscomponenten",
          image_subtitle:
            "Gebruikerscomponenten zijn Tekla Structures-objecten met een vaste of een parametrische geometrie.",
          tua_link_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">het uploaden van inhoud van een Tekla Structures-model naar een Tekla Warehouse-verzameling</a>.',
        },
        applications: {
          title: "Applicaties",
          image_subtitle:
            "Applicaties gebruiken Tekla Open API om met Tekla Structures te werken. Bijvoorbeeld gegevensintegratiekoppelingen naar een ontwerpoplossing.",
          tua_link_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">het uploaden van een applicatie naar een Tekla Warehouse-verzameling</a>.',
        },
        profiles_shapes_etc: {
          title: "Profielen, vormen, materialen, boutsamenstellingen, staven en netten",
          image_subtitle:
            "Databases bevatten lijsten met eigenschappen van onderdelen. Bijvoorbeeld verschillende materiaalkwaliteiten en hun soortelijk gewicht.",
          tua_link_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">het uploaden van inhoud van een Tekla Structures-model naar een Tekla Warehouse-verzameling</a>.',
        },
        model_templates: {
          title: "Modeltemplates",
          image_subtitle:
            "Een modeltemplate kan voor vergelijkbare, terugkerende projecten worden gebruikt. De template kan alle benodigde databases, lijsttemplates, tekeninginstellingen en aangepaste variabelen bevatten.",
          tua_link_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">het handmatig toevoegen van bestanden aan een Tekla Warehouse-verzameling</a>.',
        },
        drawing_setup: {
          title: "Tekeninginstelling",
          image_subtitle:
            "Instellingsbestanden definiëren hoe tekeningen worden gemaakt, bijvoorbeeld de opmaak, filters en kloontemplates.",
          tua_link_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">het handmatig toevoegen van bestanden aan een Tekla Warehouse-verzameling</a>.',
        },
        report_templates_etc: {
          title: "Lijsttemplates en modelattribuutbestanden",
          description_1:
            "Lijsttemplates definiëren de inhoud en de opmaak van modelgegevens in een lijst. Modelattribuutbestanden, bijvoorbeeld filters, werken wanneer ze aan de modelmap zijn toegevoegd.",
          tua_link_html:
            'Raadpleeg Tekla User Assistance voor meer informatie over <a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">het handmatig toevoegen van bestanden aan een Tekla Warehouse-verzameling</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Inhoud gebruiken",
        description_1:
          "Afhankelijk van het inhoudstype kunt u uw gekozen inhoud in uw Tekla Structures-model invoegen of deze downloaden en installeren. Raadpleeg Tekla User Assistance voor instructies over",
        feature_1:
          '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Zoeken naar inhoud in Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Inhoud vanuit Tekla Warehouse gebruiken</a>',
        feature_3:
          '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Tekla Warehouse-inhoud in een Tekla Structures-model toevoegen</a>',
        feature_4:
          '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Conflictbeheer in Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Inhoud maken",
        getting_started_with_content_creation: {
          title: "Aan de slag met het maken van inhoud",
          description_1: "Raadpleeg Tekla User Assistance voor meer informatie over:",
          feature_1:
            '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Aan de slag met Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">Inhoud toevoegen aan Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Inhoud wijzigen in Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Inhoud downloaden van Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Hulp nodig van een expert bij het maken van inhoud?",
          description_1:
            'Als u geïnteresseerd bent in het publiceren van inhoud in Tekla Warehouse maar deskundige hulp wilt hebben bij het maken en onderhouden ervan, neemt u contact op met uw lokale <a href="http://www.tekla.com/contact" target="_blank">Tekla-kantoor of -leverancier</a> voor meer informatie over samenwerkingsmogelijkheden.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Applicatieontwikkelingen voor Tekla Structures",
        description_1:
          "Omdat Tekla bij het modelleren van bouwinformatie (BIM) over een open aanpak beschikt, kunt u de oplossingen van andere leveranciers toepassen en machines voor de productie aansturen én met Tekla blijven communiceren.",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> is een speciale Application Programming Interface (API) die is ontwikkeld door Tekla. U kunt er applicaties en aanvullende functionaliteit mee ontwikkelen op het modelleerplatform van Tekla Structures en deze integreren in uw eigen omgeving. Tekla Open API wordt geïmplementeerd met de Microsoft .NET-technologie.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> is de hub voor alle Tekla Open API-informatie.',
        description_4:
          'Neem deel aan <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> als u geïnteresseerd bent in het ontwikkelen van applicaties voor Tekla Structures.',
      },
    };
  },
};
