import { NewCollectionConverter } from "../converters/NewCollectionConverter";
import { LocalCollectionsDS } from "../data-source/LocalCollectionsDS";

export const LocalCollectionService = {
  create: function (data) {
    return LocalCollectionsDS.setCollection(NewCollectionConverter.toLocal(data))
      .then(function (res) {
        return LocalCollectionsDS.getCollection({ id: res.id });
      })
      .then(NewCollectionConverter.fromLocal);
  },
  update: function (data) {
    return LocalCollectionsDS.setCollection(NewCollectionConverter.toLocal(data))
      .then(function (res) {
        return LocalCollectionsDS.getCollection({ id: res.id });
      })
      .then(NewCollectionConverter.fromLocal);
  },
  get: function (data) {
    return LocalCollectionsDS.getCollection(data).then(NewCollectionConverter.fromLocal);
  },
  remove: function (data) {
    return LocalCollectionsDS.deleteCollection(data);
  },
};
