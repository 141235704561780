export function APIError(data) {
  return {
    message: data.message,
    key: data.key,
    status: data.status,
    originalError: data.originalError,
    source: data.source,
    displayable: data.key || data.message,
  };
}
