import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { RootContext, RootStore } from "../../stores/rootStore";
import { TranslatedHtml } from "../TranslatedHtml";
import { ShoppingCartStoreCache } from "stores/shoppingCartStoreCache";

/**
 * A component to display shopping cart status in the header.
 */
export const ShoppingCartWidget = observer(() => {
  const store: RootStore = useContext(RootContext);
  const shoppingCart: ShoppingCartStoreCache = store.getShoppingCartStoreCache();
  const navigate = useNavigate();
  const title = store.getTranslation("mainNavigation.addToCartInfo");

  function goToShoppingCart() {
    return navigate("/shoppingcart");
  }

  return (
    <button className="show-basket" onClick={goToShoppingCart} title={title}>
      <span data-testid="cart-label">
        <TranslatedHtml entry="download.shopping_cart_items_func" args={[shoppingCart.getShoppingCartSize()]} />
      </span>
      <span className="icon-warehouse" />
    </button>
  );
});
