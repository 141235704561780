import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import _ from "underscore";

import { SearchPageOrderEnum, SearchTargetOptionEnum } from "../../../models/enums";
import { IDropdownOption } from "../../../models/dataModel";

import { RootContext, RootStore } from "../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "./contentManagerStore";

import { ModusAccordion, ModusAccordionItem } from "@trimble-oss/modus-react-components";

import { ModusDropdown } from "../../../components/ModusDropdown";
import { BannedContentFilter } from "./filters/BannedContentFilter";
import { CreatorFilter } from "./filters/CreatorFilter";
import { DateCreatedFilter } from "./filters/DateCreatedFilter";
import { DateModifiedFilter } from "./filters/DateModifiedFilter";
import { DownloadsFilter } from "./filters/DownloadsFilter";
import { ExternalResourceTypeFilter } from "./filters/ExternalResourceTypeFilter";
import { HiddenContentFilter } from "./filters/HiddenContentFilter";
import { ImmutableContentFilter } from "./filters/ImmutableContentFilter";
import { InvalidContentFilter } from "./filters/InvalidContentFilter";
import { ItemTypeFilter } from "./filters/ItemTypeFilter";
import { LanguageFilter } from "./filters/LanguageFilter";
import { LocationFilter } from "./filters/LocationFilter";
import { MaintenanceLicenseFilter } from "./filters/MaintenanceLicenseFilter";
import { PartnerLinkFilter } from "./filters/PartnerLinkFilter";
import { SoftwareProductFilter } from "./filters/SoftwareProductFilter";
import { SoftwareVersionFilter } from "./filters/SoftwareVersionFilter";
import { TitleFilter } from "./filters/TitleFilter";
import { UseCategoryFilter } from "./filters/UseCategoryFilter";
import { ViewsFilter } from "./filters/ViewsFilter";
import { VisibilityFilter } from "./filters/VisibilityFilter";
import { SearchTarget } from "./SearchTarget";

const Filters = observer(() => {
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  return (
    <div className="search-filters">
      <VisibilityFilter />
      <ExternalResourceTypeFilter />
      <DateCreatedFilter />
      <DateModifiedFilter />
      <TitleFilter />
      <CreatorFilter />
      {store.getSearchTarget() == SearchTargetOptionEnum.PACKAGE && (
        <>
          <SoftwareProductFilter />
          <SoftwareVersionFilter />
          <LocationFilter />
          <LanguageFilter />
          <UseCategoryFilter />
          <ItemTypeFilter />
        </>
      )}
      <ViewsFilter />
      {store.getSearchTarget() == SearchTargetOptionEnum.PACKAGE && <DownloadsFilter />}
    </div>
  );
});

const SpecialFilters = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  return (
    <>
      {store.getSearchTarget() == SearchTargetOptionEnum.PACKAGE && (
        <ModusAccordion style={{ marginTop: "0.75em" }}>
          <ModusAccordionItem
            header-text={rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.special")}
            size="condensed"
            icon="filter"
          >
            <MaintenanceLicenseFilter />
            <PartnerLinkFilter />
          </ModusAccordionItem>
        </ModusAccordion>
      )}
      <ModusAccordion style={{ marginTop: "0.75em" }}>
        <ModusAccordionItem
          header-text={rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.admin")}
          size="condensed"
          icon="filter"
        >
          {store.getSearchTarget() == SearchTargetOptionEnum.PACKAGE && <InvalidContentFilter />}
          <BannedContentFilter />
          <HiddenContentFilter />
          {store.getSearchTarget() == SearchTargetOptionEnum.PACKAGE && <ImmutableContentFilter />}
        </ModusAccordionItem>
      </ModusAccordion>
    </>
  );
});

const SortBy = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleSortByChange(option: IDropdownOption | null) {
    if (!option) return;
    store.setSortBy(option.value);
  }

  const options: IDropdownOption[] = [
    {
      value: SearchPageOrderEnum.ALPHABETICAL_AZ,
      label: rootStore.getTranslation("constants.sortOptions.title"),
    },
    {
      value: SearchPageOrderEnum.LAST_CREATED,
      label: rootStore.getTranslation("constants.sortOptions.-createdAt"),
    },
    {
      value: SearchPageOrderEnum.LAST_UPDATED,
      label: rootStore.getTranslation("constants.sortOptions.-modifyTime"),
    },
    {
      value: SearchPageOrderEnum.CREATOR,
      label: rootStore.getTranslation("constants.sortOptions.creatorName"),
    },
    {
      value: SearchPageOrderEnum.MOST_LIKED,
      label: rootStore.getTranslation("constants.sortOptions.-reviewCount"),
    },
    {
      value: SearchPageOrderEnum.POPULARITY,
      label: rootStore.getTranslation("constants.sortOptions.popularity"),
    },
    {
      value: SearchPageOrderEnum.RELEVANCE,
      label: rootStore.getTranslation("constants.sortOptions.-relevance"),
    },
  ];

  function getSelectedOption() {
    return _.find(options, (option) => option.value == store.getSortBy());
  }

  return (
    <div className="sort-by">
      <h5>{rootStore.getTranslation("helpers.sortBy")}</h5>
      <ModusDropdown
        options={options}
        onChange={handleSortByChange}
        className="dropdown-sort-by"
        inputId="sort-by"
        selectedValue={getSelectedOption()}
      />
    </div>
  );
});

export const SidePane = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  return (
    <div className="content-manager-sidepane rounded">
      <SortBy />
      <SearchTarget />
      <Filters />
      <SpecialFilters />
      <button
        data-testid="searchButton"
        disabled={!store.filtersAdjusted || store.isQuerying("views") || store.isQuerying("downloads")}
        onClick={() => store.search()}
      >
        {rootStore.getTranslation("profile.admin.search_content")}
      </button>
    </div>
  );
});
