import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

import { ModusBadge } from "@trimble-oss/modus-react-components";
import { IBatchCollection, IBatchEntity, IDropdownOption, IEntity } from "../../../../models/dataModel";

interface IContentTag {
  label: string;
  active: boolean;
}

export const Tags = observer(({ item }: { item: IBatchCollection | IBatchEntity }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function getTags(item: IEntity): IContentTag[] {
    let tags: IContentTag[] = [];

    if (!!item.tags) {
      tags = _.union(
        tags,
        _.map(item.tags, (tag: string) => ({ label: tag, active: false })),
      );
    }

    if (!!item.attributes) {
      const supportedLanguages = _.map(item.attributes.supportedLanguages || [], (option) => {
        return {
          label: rootStore.getTranslation(`supportedLanguages.${option}`),
          active: _.includes(
            _.map(store.getLanguages() || [], (opt: IDropdownOption) => opt.value),
            option,
          ),
        };
      }) as IContentTag[];

      const locationRestrictions = _.map(item.attributes.locationRestrictions || [], (option) => {
        return {
          label: rootStore.getTranslation(`constants.countries.${option}`),
          active: _.includes(
            _.map(store.getLocations() || [], (opt: IDropdownOption) => opt.value),
            option,
          ),
        };
      }) as IContentTag[];

      const itemTypeCategories = _.map(item.attributes.itemTypeCategories || [], (option) => {
        return {
          label: rootStore.getTranslation(`itemTypeCategories.${option}`),
          active: _.includes(
            _.map(store.getItemTypes() || [], (opt: IDropdownOption) => opt.value),
            option,
          ),
        };
      }) as IContentTag[];

      const useCategories = _.map(item.attributes.useCategories || [], (option) => {
        return {
          label: rootStore.getTranslation(`useCategories.${option}`),
          active: _.includes(
            _.map(store.getUseCategories() || [], (opt: IDropdownOption) => opt.value),
            option,
          ),
        };
      }) as IContentTag[];

      const measurementUnits = _.map(item.attributes.measurementUnits || [], (option) => {
        return {
          label: rootStore.getTranslation(`measurementUnits.${option}`),
          active: _.includes(
            _.map(store.getAttributes()?.measurementUnits || [], (opt: IDropdownOption) => opt.value),
            option,
          ),
        };
      }) as IContentTag[];

      tags = _.union(
        tags,
        supportedLanguages,
        locationRestrictions,
        itemTypeCategories,
        useCategories,
        measurementUnits,
      );
    }

    if (!!item.versionAttributes) {
      const compatibleSoftwareproducts = _.map(item.versionAttributes.compatibleSoftwareProducts || [], (option) => {
        return {
          label: rootStore.getTranslation(`compatibleSoftwareProductOptions.${option}`),
          active: _.map(store.getCompatibleSoftwareProducts() || [], (opt: IDropdownOption) => opt.value).includes(
            option,
          ),
        };
      }) as IContentTag[];

      const testedVersions = _.map(item.versionAttributes.testedVersions || [], (option) => {
        return {
          label: rootStore.getTranslation(`testedVersions.${option}`),
          active: _.includes(
            _.map(store.getTestedVersions() || [], (opt: IDropdownOption) => opt.value),
            option,
          ),
        };
      }) as IContentTag[];

      tags = _.union(tags, compatibleSoftwareproducts, testedVersions);
    }

    return _.sortBy(tags, (tag: IContentTag) => tag.active).reverse();
  }

  return (
    !!getTags(item as IBatchEntity) && (
      <div className="item-tags" data-testid="item-tags">
        {_.map(getTags(item as IBatchEntity), (tag: IContentTag, i: number) => (
          <div className="item-tag" key={i}>
            <ModusBadge key={tag.label} color={tag.active ? "primary" : "tertiary"}>
              {tag.label}
            </ModusBadge>
          </div>
        ))}
      </div>
    )
  );
});
