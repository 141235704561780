import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { LocalThumbnailStoreContext } from "./localThumbnailStore";
import { EditorModeStoreContext } from "../editor-mode/editorModeStore";
import { ImageUtils } from "../../utils/ImageUtils";

/**
 * A component that displays add and remove buttons for thumbnail
 */
export const Controls = observer(() => {
  const rootStore = useContext(RootContext);
  const thumbnailStore = useContext(LocalThumbnailStoreContext);
  const thumbnail = thumbnailStore.getThumbnail();

  function addThumbnail(data) {
    if (data && data.target && data.target.files[0]) {
      thumbnailStore.addThumbnail(data.target.files[0]);
    }
  }

  function isDefaultThumbnail() {
    return thumbnail.url === ImageUtils.getDefaultThumbail();
  }

  return (
    <div className="controls">
      <label className="button button-icon upload" htmlFor="chooseImageFile">
        <span className="icon icon-pencil" />
        <span>{rootStore.getTranslation("actions.choose_thumbnail")}</span>
      </label>
      {!isDefaultThumbnail() && (
        <a
          className="button button-icon upload"
          onClick={thumbnailStore.deleteThumbnail}
          data-testid="delete-local-thumbnail"
        >
          <span className="icon icon-close" />
          <span>{rootStore.getTranslation("collections.actions.delete_thumbnail")}</span>
        </a>
      )}
      <input
        data-testid="add-local-thumbnail"
        id="chooseImageFile"
        className="hidden-file-upload"
        type="file"
        accept="image/*"
        onChange={addThumbnail}
        required
      />
    </div>
  );
});

/**
 * A component that displays local collection page thumbnail
 */
export const LocalThumbnail = observer(() => {
  const thumbnailStore = useContext(LocalThumbnailStoreContext);
  const thumbnail = thumbnailStore.getThumbnail();
  const editorModeStore = useContext(EditorModeStoreContext);

  function getStyle(url) {
    return { backgroundImage: `url(${url})` };
  }

  return (
    <Fragment>
      {thumbnail && (
        <div className="column">
          <div data-testid="local-thumbnail" className="thumb-box">
            <figure thumb-box="" thumbnail-url={thumbnail.url} style={getStyle(thumbnail.url)} />
            {editorModeStore.getEditorMode() && <Controls />}
          </div>
        </div>
      )}
    </Fragment>
  );
});
