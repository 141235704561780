import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../../stores/rootStore";
import { ManageOrganizationsStore, ManageOrganizationsStoreContext } from "./manageOrganizationsStore";

import { OrganizationList } from "./OrganizationList";
import { IOrganization } from "../../../models/dataModel";

const MakeOrganizationImmutableButton = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageOrganizationsStore = useContext(ManageOrganizationsStoreContext);
  const hasSelectedOrganizations = !!store.getSelectedOrganizations() && store.getSelectedOrganizations().length > 0;

  async function makeOrganizationImmutable() {
    await store.makeOrganizationsImmutable();
  }

  const thereAreNonImmutableSelectedOrganizations = store
    .getSelectedOrganizations()
    .some((organization: IOrganization) => !organization.isImmutable);

  return (
    <button
      className="button"
      onClick={makeOrganizationImmutable}
      disabled={!hasSelectedOrganizations || store.isProcessing() || !thereAreNonImmutableSelectedOrganizations}
      data-testid="makeOrganizationImmutableButton"
    >
      {rootStore.getTranslation("profile.admin.manage_organizations.make_immutable")}
    </button>
  );
});

const RemoveImmutableStatusButton = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageOrganizationsStore = useContext(ManageOrganizationsStoreContext);
  const hasSelectedOrganizations = !!store.getSelectedOrganizations() && store.getSelectedOrganizations().length > 0;

  async function removeImmutableStatus() {
    await store.removeImmutableStatus();
  }

  const thereAreImmutableSelectedOrganizations = store
    .getSelectedOrganizations()
    .some((organization: IOrganization) => organization.isImmutable);

  return (
    <button
      className="button"
      onClick={removeImmutableStatus}
      disabled={!hasSelectedOrganizations || store.isProcessing() || !thereAreImmutableSelectedOrganizations}
      data-testid="removeImmutableStatusButton"
    >
      {rootStore.getTranslation("profile.admin.manage_organizations.make_unimmutable")}
    </button>
  );
});

const ShowImmutableOrganizationsButton = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageOrganizationsStore = useContext(ManageOrganizationsStoreContext);

  return (
    <button
      className="button"
      style={{ marginLeft: "1em" }}
      onClick={() => store.fetchImmutableOrganizations()}
      disabled={store.isLoading() || store.isProcessing()}
      data-testid="showImmutableOrganizationsButton"
    >
      {rootStore.getTranslation("profile.admin.manage_organizations.show_immutable")}
    </button>
  );
});

const SearchOrganizations = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageOrganizationsStore = useContext(ManageOrganizationsStoreContext);

  const [textareaHeight, setTextareaHeight] = React.useState<number>();

  function handleSearchInput(event) {
    store.setInput(event.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await searchOrganization();
  }

  async function searchOrganization() {
    await store.searchOrganizations();
  }

  function calcHeight(value): number {
    const numberOfLineBreaks = (value.match(/\n/g) || []).length;
    // min-height + lines x line-height + padding + border
    const newHeight = 22 + numberOfLineBreaks * 22 + 16 + 4;
    return newHeight;
  }

  const textarea: HTMLTextAreaElement | null = document.querySelector(".acl-editor-textarea");

  if (!!textarea && !_.isNull(textarea)) {
    textarea.addEventListener("keyup", () => {
      setTextareaHeight(calcHeight(textarea!.value));
    });
  }

  return (
    <div
      className="search-options"
      style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: "1em" }}
    >
      <form className="search-form" onSubmit={handleSubmit} data-testid="searchOrganizationsForm">
        <textarea
          style={{ width: "400px", height: textareaHeight + "px", overflow: "hidden" }}
          value={store.getInput()}
          onChange={handleSearchInput}
          placeholder={rootStore.getTranslation("shared.access_rights.write_organization_id")}
          disabled={store.isLoading() || store.isProcessing()}
          data-testid="searchOrganizationsInput"
        />
        <button
          type="submit"
          className="input-search"
          disabled={store.isLoading() || store.isProcessing()}
          data-testid="searchButton"
        />
      </form>
      {"or"}
      <ShowImmutableOrganizationsButton />
    </div>
  );
});

const Actions = observer(() => {
  const store: ManageOrganizationsStore = useContext(ManageOrganizationsStoreContext);
  const hasFoundOrganizations = !!store.getOrganizations() && store.getOrganizations().length > 0;

  return (
    <Fragment>
      {hasFoundOrganizations && (
        <Fragment>
          <hr />
          <div className="actions" style={{ paddingTop: "1em", paddingBottom: "1em" }}>
            <MakeOrganizationImmutableButton />
            <RemoveImmutableStatusButton />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
});

export const ManageOrganizations = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <ManageOrganizationsStoreContext.Provider value={new ManageOrganizationsStore(rootStore)}>
      <div className="manage-organizations" data-testid="manageOrganizations">
        <div className="admin-header">{rootStore.getTranslation("profile.admin.manage_organizations.title")}</div>
        <div className="select-organizations">
          <SearchOrganizations />
          <Actions />
          <OrganizationList />
        </div>
      </div>
    </ManageOrganizationsStoreContext.Provider>
  );
});
