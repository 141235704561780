import { action, observable, makeObservable } from "mobx";
import { createStoreContext } from "../../stores/rootStore";

/**
 * Conte page tab store
 */
export class TabNavigationStore {
  /**
   * Name of the current active tab
   */
  @observable private activeTabName = "";

  constructor() {
    makeObservable(this);
  }

  /**
   * Sets active tab name
   * @params tabName name of the active tab
   */
  @action
  public setActiveTabName(tabName: string) {
    this.activeTabName = tabName;
  }

  /**
   * gets active tab name
   * @returns name of the active tab
   */
  public getActiveTabName(): string {
    return this.activeTabName;
  }
}

export const TabNavigationStoreContext = createStoreContext<TabNavigationStore>(TabNavigationStore);
