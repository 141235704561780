import { ObjectTypeEnum, SourceEnum } from "../../models/enums";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IOrganization } from "../../models/dataModel";

/**
 * Creates an Organization object.
 *
 * @param {Object} data - The data used to initialize the Organization.
 * @returns {IOrganization} The Organization object.
 */
export function Organization(data) {
  data = data || {};

  return {
    id: data.id,
    displayName: data.displayName,
    groupName: data.groupName,
    linkedResourcesCollection: data.linkedResourcesCollection,
    source: data.source || SourceEnum.UNKNOWN,
    type: ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION,
    externalId: data.externalId,
    customWarehouseUrl: data.customWarehouseUrl,
    binaries: data.binaries,
    details: data.details,
    contact: data.contact || {},
    description: data.description,
    collectionCount: data.collectionCount,
    packageCount: data.packageCount,
    versionCount: data.versionCount,
    isImmutable: data.isImmutable,
  };
}
