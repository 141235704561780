 
export const ZhCnAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["zh-CN"]) {
      window.locale["zh-CN"] = {};
    }

    window.locale["zh-CN"].about = {
      navigation: {
        why: "为什么选择 Tekla Warehouse?",
        usage_basics: "Tekla Warehouse 基础",
        "usage_basics__using-content": "使用内容",
        "usage_basics__adding-content": "添加内容",
        usage_basics__collection: "集合",
        "usage_basics__content-item": "内容项",
        usage_basics__version: "版本",
        getting_started: "开始",
        "getting_started__tekla-account-and-system-requirements": "Tekla account 和系统要求",
        "getting_started__tekla-warehouse-service-plugin": "Tekla Warehouse Service 插件",
        "getting_started__how-do-i-get-tekla-warehouse-service": "如何获得 Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures 和 Tekla Warehouse",
        content: "Tekla Warehouse 中的内容",
        "content__3d-products": "3D 模型产品",
        "content__custom-components": "自定义组件",
        content__applications: "应用程序",
        "content__profiles-shapes-etc": "型材、材料、螺栓、钢筋等。",
        "content__model-templates": "模型模板",
        "content__drawing-setup": "图纸设置",
        "content__report-templates-etc": "报告模板和属性文件",
        using_content: "使用内容",
        creating_content: "创建内容",
        "creating_content__getting-started-with-content-creation": "内容创建入门",
        "creating_content__expert-help-for-creating-content": "需要获得创建内容方面的专家帮助?",
        application_developers: "有关 Tekla Structures 的应用程序开发",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "为什么选择 Tekla Warehouse?",
        description_1:
          "Tekla Warehouse 包括应用程序、自定义组件、零件、型材、钢和混凝土等级等材料、螺栓、钢筋、钢筋网、形状和 Tekla Structures 模板。 模型中现有的实际供应商零件可以带来最高的精确性以及准确的材料信息，以便进行规划和采购 – 并且使用现成的内容加速建模过程。",
        description_2:
          "Tekla Warehouse 提供对此内容的集中访问权限，从而能够以简化的方式使用此内容。 Tekla Warehouse 中的内容持续增加。",
        description_3: "使用 Tekla Warehouse",
        feature_1:
          "制造商可以为用户提供产品和应用程序，用户可以将精确的 3D 产品模型直接用于他们的 Tekla 模型中，并且日后在施工中可使用实体产品。",
        feature_2: "您可以使用贵公司网络与同事共享内容，并且可以将内容保存到本地以供个人使用",
        description_4: "不论您要搜索何种内容类型，Tekla Warehouse 搜索功能都会精确地找到您需要的内容。",
        description_5: "需要 Tekla Warehouse Service 插件才能启用网络和本地集合。",
        view_licences_html: '查看<a href="/api/third_party_licenses.txt" target="_blank">第三方许可证</a>。',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Tekla Warehouse 基础",
        using_content: {
          title: "使用内容",
          description_1: "您可以使用在线集合中的内容，使用网络集合与您的同事共享内容，并利用本地集合以供个人使用。",
          description_2:
            "本地和网络集合需要 Tekla Warehouse Service，后者随 Tekla Structures 20.1 Service Release 1 和更新版本的 Tekla Structures 一起安装。",
          description_3: "包含本地化环境内容的特定 Tekla 集合随 Tekla Structures 一起安装。",
          description_4: "有关更多信息，请参阅“使用内容”一节。",
        },
        adding_content: {
          title: "添加内容",
          description_1:
            '您可以利用在线集合将您的内容发布给所有 Tekla Warehouse 用户。 为了以组织身份发布内容，需要将您的组织添加到 account.tekla.com 中。 有关更多信息，请联系您当地的 <a href="https://www.tekla.com/cn/%E8%81%94%E7%B3%BB/%E5%8A%9E%E5%85%AC%E5%AE%A4%E5%92%8C%E7%BB%8F%E9%94%80%E5%95%86" target="_blank">Tekla 办事处或经销商</a>。',
          description_2: "有关更多信息，请参阅“创建内容”一节。",
        },
        collection: {
          title: "集合",
          feature_1: "集合对内容项组合（例如文件夹）进行分组，并定义对内容的访问权限（公共/专用）。",
          feature_1_1: "建模者可以使用在线集合中的内容，使用网络集合与同事共享内容，并利用本地集合以供个人使用。",
          feature_2: "内容提供商可以使用在线集合将他们的内容发布给所有 Tekla Warehouse 用户。",
          feature_3:
            "本地和网络集合需要 Tekla Warehouse Service 插件，后者随 Tekla Structures 20.1 Service Release 1 及其更新版本一起安装，也可以单独进行安装。",
        },
        "content-item": {
          title: "内容项",
          feature_1: "内容项是文件的逻辑组。 例如，3D 产品目录、模型模板、工具、自定义组件组或型材目录均为内容项。",
          feature_2: "可以按类型和使用情况标记内容项，这有助于用户查找您的内容。",
        },
        version: {
          title: "版本",
          feature_1:
            "同时，内容项可以有许多版本，这些版本可以与不同的 Tekla Structures 版本配合使用。 用户可以从内容页选择正确的版本。",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "开始",
        tekla_account_and_system_requirements: {
          title: "Tekla account 和系统要求",
          register_atc_html:
            '您需要使用 Tekla account 登录到 Tekla Warehouse。 如果您尚未注册帐户，请在 <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a> 上进行注册。 （请注意这不是旧 Extranet 帐户。）',
          see_tua_for_prerequisites_html:
            '请参阅 Tekla User Assistance 获取有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">使用 Tekla Warehouse 的先决条件</a>的信息。',
        },
        tekla_warehouse_service_plugin: {
          title: "Tekla Warehouse Service 插件",
          description_1: "除了作为内容在线存储工具，Tekla Warehouse 还具有以下功能:",
          feature_1: "供个人使用的本地集合",
          feature_2: "使用公司网络与同事共享内容的网络集合",
          feature_3: "当使用或创建内容时便于安装",
          feature_4: "搜索本地和网络内容",
          description_2:
            "借助作为本地后端运行的 Tekla Warehouse Service 插件，与 Tekla Structures 和 Tekla Warehouse 网站进行通信。",
          description_3:
            "如果未安装或运行 Tekla Warehouse Service，Tekla Warehouse 会在页面顶部突出显示此信息，以允许进行故障排除。",
          description_4:
            "如果服务出现问题，您可以在 Windows 中使用“服务”程序重新启动它。 服务名称为 Tekla Warehouse。 另外请检查您的浏览器的安全设置。",
          description_5:
            "可在不使用 Tekla Warehouse Service 或 Tekla Structures 的情况下使用 Tekla Warehouse 网站。 该体验类似于一个典型的内容共享网站，在这里可以手动上载和下载文件。",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "如何获得 Tekla Warehouse Service?",
          description_1:
            "Tekla Warehouse Service 插件随 Tekla Structures 20.1 Service Release 1 和较新版本一起安装。 您也可以单独安装 Tekla Warehouse Service 以支持本地和网络集合，但是简单易行的安装和上载功能仅在 Tekla Structures 20.1 Service Release 1 和更新版本中可用。",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">下载 TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures 和 Tekla Warehouse",
          description_1:
            "Tekla Warehouse 直接连接到 Tekla Structures，使您能够更方便地利用或上载内容。 您可以从您的模型中直接选择内容，也可以直接将内容插入模型中，而无需输入/输出文件（要实现此功能，需要 Tekla Structures 20.1 Service Release 1 和更新版本）。 所有需要的文件可以包括在同一内容项（例如自定义组件或型材）中，以确保按预期处理内容。",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Tekla Warehouse 中的内容",
        description_1:
          "Tekla Warehouse 提供几种不同的内容类型。 您可以在此处找到不同内容类型的概述以及有关使用入门的更多信息。",
        description_2:
          '您还可以从 Tekla Warehouse 下载 <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla 扩展</a>。',
        "3d_products": {
          title: "3D 模型产品",
          image_subtitle: "这些对象具有固定的几何形状，例如，埋件、框架和配件。",
          tua_link_html:
            '有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">在 Tekla Warehouse 集合中创建 3D 产品目录</a>的更多信息，请参阅 Tekla User Assistance。',
        },
        custom_components: {
          title: "自定义组件",
          image_subtitle: "自定义组件是具有固定或参数化几何形状的 Tekla Structures 本机对象。",
          tua_link_html:
            '有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">将内容从 Tekla Structures 模型上传到 Tekla Warehouse 集合</a>的更多信息，请参阅 Tekla User Assistance。',
        },
        applications: {
          title: "应用程序",
          image_subtitle:
            "应用程序使用 Tekla Open API 与 Tekla Structures 进行交互。 例如，数据集成链接至设计解决方案。",
          tua_link_html:
            '有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">将应用程序上传到 Tekla Warehouse 集合</a>的更多信息，请参阅 Tekla User Assistance。',
        },
        profiles_shapes_etc: {
          title: "型材、形状、材料、螺栓构件、钢筋和钢筋网",
          image_subtitle: "目录包含与零件相关的属性的列表。 例如，不同材料等级及其密度。",
          tua_link_html:
            '有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">将内容从 Tekla Structures 模型上传到 Tekla Warehouse 集合</a>的更多信息，请参阅 Tekla User Assistance。',
        },
        model_templates: {
          title: "模型模板",
          image_subtitle:
            "模型模板可以用于类似的重复项目。 该模板包含所有必要的目录、报告模板、图纸设置和高级选项设置。",
          tua_link_html:
            '有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">将文件手动添加到 Tekla Warehouse 集合</a>的更多信息，请参阅 Tekla User Assistance。',
        },
        drawing_setup: {
          title: "图纸设置",
          image_subtitle: "设置文件定义图纸的创建方式，例如布置、过滤和复制模板。",
          tua_link_html:
            '有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">将文件手动添加到 Tekla Warehouse 集合</a>的更多信息，请参阅 Tekla User Assistance。',
        },
        report_templates_etc: {
          title: "报告模板和模型属性文件",
          description_1:
            "报告模板定义报告中的内容和模型数据布置。 在将模型属性文件(如过滤)添加到模型文件夹中后，这些文件才有效。",
          tua_link_html:
            '有关<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">将文件手动添加到 Tekla Warehouse 集合</a>的更多信息，请参阅 Tekla User Assistance。',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "使用内容",
        description_1:
          "根据内容类型，您可以将所选的内容插入 Tekla Structures 模型或下载并安装此内容。 请参阅 Tekla User Assistance 中有关下列操作的说明",
        feature_1:
          '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">在 Tekla Warehouse 中搜索内容</a>',
        feature_2:
          '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">使用 Tekla Warehouse 中的内容</a>',
        feature_3:
          '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">将 Tekla Warehouse 内容插入 Tekla Structures 模型</a>',
        feature_4:
          '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">在 Tekla Warehouse 中执行冲突管理</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "创建内容",
        getting_started_with_content_creation: {
          title: "内容创建入门",
          description_1: "请参阅 Tekla User Assistance 中有关下列操作的更多信息:",
          feature_1:
            '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">开始使用 Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">将内容添加到 Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">修改 Tekla Warehouse 中的内容</a>',
          feature_4:
            '<a href="https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">从 Tekla Warehouse 下载内容</a>',
        },
        expert_help_for_creating_content: {
          title: "需要获得创建内容方面的专家帮助?",
          description_1:
            '如果您对在 Tekla Warehouse 中发布内容感兴趣，但是希望在创建并维护内容方面获得专业级帮助，请与您当地的 <a href="https://www.tekla.com/cn/%E8%81%94%E7%B3%BB/%E5%8A%9E%E5%85%AC%E5%AE%A4%E5%92%8C%E7%BB%8F%E9%94%80%E5%95%86" target="_blank">Tekla 办事处或经销商</a>联系以获得有关协作可能性的更多信息。',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "有关 Tekla Structures 的应用程序开发",
        description_1:
          "由于 Tekla 提供建筑信息建模 (BIM) 的开放式方法，因此您可以运行其他提供商的解决方案和制造机器，但是仍可以与 Tekla 衔接。",
        description_2:
          '<a href="https://www.tekla.com/cn/%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/%E8%AE%BE%E8%AE%A1%E4%BA%A4%E4%BA%92/%E9%9D%A2%E5%90%91%E5%BC%80%E5%8F%91%E8%80%85%E7%9A%84tekla-api" target="_blank">Tekla Open API</a> 是一种由 Tekla 开发的专业应用程序编程界面 (API)，使用它可以在 Tekla Structures 建模平台上开发应用程序和其他功能，并将其集成到您自己的环境中。 Tekla Open API 是通过使用 Microsoft .NET 技术而实现的。',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center</a> 是所有 Tekla Open API 信息的中心。',
        description_4:
          '如果您想开发 Tekla Structures 应用程序，请加入 <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a>。',
      },
    };
  },
};
