import { action, observable, runInAction, makeObservable } from "mobx";
import { DateTime } from "luxon";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { IAnalyticsFiltersSetter } from "../../components/analytics/analyticsStore";

import { AnalyticsEventTypeEnum, AnalyticsTimeFrameEnum } from "../../models/enums";

/**
 * Collection page store.
 */

export class ExportAnalyticsDialogStore implements IAnalyticsFiltersSetter {
  /**
   * End date used for filtering analytics data, calculated based on timeframe selection
   */
  @observable private endDate!: Date;
  /**
   * Event type for filtering analytics data
   */
  @observable private eventType: string = AnalyticsEventTypeEnum.DOWNLOADS;
  /**
   * Root store.
   */
  private rootStore: RootStore;
  /**
   * Start date used for filtering analytics data, calculated based on timeframe selection
   */
  @observable private startDate!: Date;
  /**
   * Timeframe selection to filter analytics data
   */
  @observable private timeFrame: string = AnalyticsTimeFrameEnum.THIS_MONTH;

  /**
   * Sets end date
   * @params endDate the value to be set
   */
  @action
  public setEndDate = (endDate: Date) => {
    this.endDate = endDate;
  };
  /**
   * Sets event type
   * @params eventType the value to be set
   */
  @action
  public setEventType = (eventType: string) => {
    this.eventType = eventType;
  };
  /**
   * Sets start date
   * @params startDate the value to be set
   */
  @action
  public setStartDate = (startDate: Date) => {
    this.startDate = startDate;
  };
  /**
   * Sets timeframe
   * @params timeFrame the value to be set
   */
  @action
  public setTimeFrame = (timeFrame: string) => {
    this.timeFrame = timeFrame;
  };

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param resource Resource instance
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    runInAction(() => {
      this.startDate = DateTime.fromObject({ hour: 0 }).minus({ days: 30 }).toJSDate();
      this.endDate = new Date();
    });
  }

  /**
   * Gets end data
   * @returns end date value
   */
  public getEndDate(): Date {
    return this.endDate;
  }

  /**
   * Gets event type
   * @returns event type value
   */
  public getEventType(): string {
    return this.eventType;
  }

  /**
   * Gets start date
   * @returns start date value
   */
  public getStartDate(): Date {
    return this.startDate;
  }

  /**
   * Gets timeframe
   * @returns timeframe value
   */
  public getTimeFrame(): string {
    return this.timeFrame;
  }
}

export const ExportAnalyticsDialogStoreContext =
  createStoreContext<ExportAnalyticsDialogStore>(ExportAnalyticsDialogStore);
