import * as React from "react";
import { Fragment, useContext } from "react";
import { RootContext } from "../stores/rootStore";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { Settings } from "../config/Settings";

interface IVerificationError {
  message: string;
  messageWithLink: string;
}

/**
 * A component that displays information about unverified account of currently signed in user.
 */
export const AccountVerificationErrors = () => {
  const store = useContext(RootContext);
  const errorKeyFromQuery = store.getUserStore().getAccountValidationError();
  let error: IVerificationError | undefined;

  if (errorKeyFromQuery) {
    if (errorKeyFromQuery == "email_unverified") {
      error = {
        message: "landing.verifyAccount",
        messageWithLink: "landing.unverifiedInstructions_html_func",
      };
    } else if (errorKeyFromQuery == "email_unverified_terms_not_accepted") {
      error = {
        message: "landing.verifyAccountAcceptTerms",
        messageWithLink: "landing.termsNotAccepted_html_func",
      };
    } else if (errorKeyFromQuery == "terms_not_accepted") {
      error = {
        message: "landing.acceptTerms",
        messageWithLink: "landing.termsNotAccepted_html_func",
      };
    } else if (errorKeyFromQuery === "updated_terms_not_accepted") {
      error = {
        message: "landing.acceptUpdatedTerms",
        messageWithLink: "landing.updatedTermsNotAccepted_html_func",
      };
    }
  }

  return (
    <Fragment>
      {error && (
        <div className="verify-account-box" data-testid="account-verification-errors">
          <div className="verify-account-textarea">
            <div className="warning-sign" />
            <div className="warning-text">
              <div className="info-text" data-testid="verification-error">
                <TranslatedHtml entry={error.message} />
              </div>
              <div className="info-text-with-link" data-testid="verification-error-html">
                <TranslatedHtml entry={error.messageWithLink} args={[Settings.atc.apiEndpoint]} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
