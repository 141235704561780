import _ from "underscore";

import { TCCSearchDS } from "../data-source/TCCSearchDS";

import { TCCCollectionService } from "./TCCCollectionService";
import { TCCOrganizationService } from "./TCCOrganizationService";
import { TCCPackageService } from "./TCCPackageService";
import { TCCVersionService } from "./TCCVersionService";
import { TCCEntityDS } from "../data-source/TCCEntityDS";

import { NewCollectionConverter } from "../converters/NewCollectionConverter";
import { OrganizationConverter } from "../converters/OrganizationConverter";
import { PackageConverter } from "../converters/PackageConverter";
import { VersionConverter } from "../converters/VersionConverter";

import { ContentTypeEnum, ObjectTypeEnum } from "../../models/enums";

/**
 * Returns a resource, either from backend or converted to correct format
 * @param {*} entry version/package/collection to get
 * @param {boolean} fetch
 * @param {string} performAsId
 * @returns the returned item
 */
function getResource(entry, fetch, performAsId) {
  return new Promise((resolve, reject) => {
    if (entry && entry.type) {
      if (fetch) {
        if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
          resolve(TCCVersionService.get(entry.id, performAsId));
        } else if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
          resolve(TCCPackageService.get(entry.id, performAsId));
        } else if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
          resolve(TCCCollectionService.get(entry.id, performAsId));
        } else if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION) {
          resolve(TCCCollectionService.get(entry.id, performAsId));
        } else return reject();
      } else {
        if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION) {
          return resolve(VersionConverter.fromTCC(entry));
        } else if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
          return resolve(PackageConverter.fromTCC(entry));
        } else if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
          resolve(NewCollectionConverter.fromTCC(entry));
        } else if (entry.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION) {
          resolve(NewCollectionConverter.fromTCC(entry));
        } else return reject();
      }
    } else {
      return reject();
    }
  });
}

function sortToOrignalOrder(originalResources, newResources) {
  var origResources = [];
  if (_.isArray(originalResources)) {
    origResources = originalResources;
  } else {
    origResources = originalResources.entries;
  }
  var sortedResults = [];
  _.each(origResources, function (origResource) {
    _.each(newResources, function (newResource) {
      if (origResource.id === newResource.id) {
        sortedResults.push(newResource);
      }
    });
  });
  return sortedResults;
}

export const TCCSearchService = {
  /**
   * Search collections from TCC according to the given query parameters
   * @param {Object} data - query parameters
   * @param {boolean} dontFetchAgain - if true, the search will not fetch the resources again
   * @param {ObjectTypeEnum} type - type of the collection
   * @param {string} performAsId - id of the user to perform the search as
   * @returns {Promise} - a promise that resolves to the search results
   */
  searchCollections: async function (data, dontFetchAgain, type, performAsId) {
    data = data || {};
    _.defaults(data, {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: "title ASC",
    });

    var contentTypeFiql;
    var contentType = ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION;
    if (type) {
      contentType = type;
    }
    contentTypeFiql = "(type==" + contentType + ")";
    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }
    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    return TCCCollectionService.searchCollections(data, false, performAsId).then(function (res) {
      var origResources = res;
      var resources = [];
      var promises = _.map(res, function (entry) {
        return new Promise((resolve, reject) => {
          getResource(entry, !!!dontFetchAgain, performAsId).then(
            function (resource) {
              resources.push(resource);
              resolve();
            },
            function (err) {
              resolve();
            },
          );
        });
      });
      return Promise.all(promises).then(function () {
        var res = sortToOrignalOrder(origResources, resources);
        return res;
      });
    });
  },
  searchCollectionsRaw: function (data, dontFetchAgain, type, performAsId) {
    data = data || {};
    _.defaults(data, {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: "title ASC",
    });

    var contentTypeFiql;
    var contentType = ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION;
    if (type) {
      contentType = type;
    }
    contentTypeFiql = "(type==" + contentType + ")";
    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }
    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    return TCCCollectionService.searchCollections(data, true, performAsId).then(function (res) {
      var origResources = res;
      var resources = [];
      var promises = _.map(res.entries, function (entry) {
        return new Promise((resolve, reject) => {
          resources.push({ id: entry.id });
          getResource(entry, !!!dontFetchAgain, performAsId).then(
            function (resource) {
              var entity = _.find(resources, function (e) {
                return e.id === resource.id;
              });
              _.extend(entity, resource);
              resolve();
            },
            function (err) {
              resolve();
            },
          );
        });
      });
      return Promise.all(promises).then(function () {
        var sortedRes = sortToOrignalOrder(origResources, resources);
        return {
          raw: res,
          results: sortedRes,
        };
      });
    });
  },
  searchPackages: function (data, dontFetchAgain, performAsId) {
    data = data || {};
    _.defaults(data, {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: "title ASC",
    });
    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    var contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE + ")";
    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }

    return TCCPackageService.searchPackages(data, false, performAsId).then(function (res) {
      var origResources = res;
      var resources = [];
      var promises = _.map(res, function (entry) {
        return new Promise((resolve, reject) => {
          getResource(entry, !!!dontFetchAgain, performAsId).then(
            function (resource) {
              resources.push(resource);
              resolve();
            },
            function (err) {
              console.log("PACKAGEERROR:", entry.id);
              resolve();
            },
          );
        });
      });
      return Promise.all(promises).then(function () {
        var res = sortToOrignalOrder(origResources, resources);
        return res;
      });
    });
  },
  /**
   * Search packages, don't do additional query to fetch the entities
   * Does a separate query for binaries
   * @param {*} data
   * @param {string} performAsId
   * @returns {Promise<{ entries: ITCCPackage[], startRow: number, total: number, endRow: number }>}
   */
  packageSearch: function (data, performAsId) {
    data = data || {};

    _.defaults(data, {
      offset: data.offset || 0,
      count: data.count || 200,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: data.sortBy || "title ASC",
    });

    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    var contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE + ")";

    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }

    return TCCPackageService.searchPackages(data, true, performAsId).then(function (res) {
      return { ...res, entries: PackageConverter.fromTCC(res.entries) };
    });
  },
  /**
   * Perform a search for collections, don't do additional query to fetch the resources
   * @param {*} data
   * @param {string} performAsId
   * @returns {Promise<{total: number; startRow: number; endRow: number; entries: ICollection[]}>}
   */
  collectionSearch: function (data, performAsId) {
    data = data || {};

    _.defaults(data, {
      offset: data.offset || 0,
      count: data.count || 200,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: data.sortBy || "title ASC",
    });

    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    var contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION + ")";

    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }

    return TCCCollectionService.searchCollections(data, true, performAsId).then(function (res) {
      return { ...res, entries: NewCollectionConverter.fromTCC(res.entries) };
    });
  },
  searchOrganizations: function (data) {
    data = data || {};
    return TCCOrganizationService.search(data).then(function (res) {
      return { total: res.total, entries: _.map(res.entries, (e) => OrganizationConverter.fromTCC(e)) };
    });
  },
  /**
   *
   * @param {IPackageSearchQuery} data
   * @param {boolean | undefined} dontFetchAgain
   * @param {string | undefined} performAsId
   * @returns
   */
  searchRecommendedPackages: function (data, dontFetchAgain, performAsId) {
    data = data || {};
    _.defaults(data, {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: "title ASC",
    });
    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    var contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE + ")";
    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }
    return TCCPackageService.searchRecommendedPackages(data, true, performAsId).then(function (res) {
      var origResources = res;
      var resources = [];
      var promises = _.map(res.entries, function (entry) {
        return new Promise((resolve, reject) => {
          getResource(entry, !!!dontFetchAgain, performAsId).then(
            function (resource) {
              resources.push({ id: entry.id });
              var entity = _.find(resources, function (e) {
                return e.id === resource.id;
              });
              _.extend(entity, resource);
              resolve();
            },
            function (err) {
              resolve();
            },
          );
        });
      });
      return Promise.all(promises).then(function () {
        var sortedRes = sortToOrignalOrder(origResources, resources);
        return {
          raw: res,
          results: sortedRes,
        };
      });
    });
  },
  /**
   * Searches packages from TCC according to the given query parameters
   * @param {Object} data - query parameters
   * @param {boolean} dontFetchAgain - if true, the search will not fetch the resources again
   * @returns {Promise} - a promise that resolves to the search results
   */
  searchPackagesRaw: function (data, dontFetchAgain) {
    data = data || {};
    _.defaults(data, {
      offset: 0,
      count: 200,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: "title ASC",
    });
    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    var contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE + ")";
    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }
    return TCCPackageService.searchPackages(data, true).then(function (res) {
      var origResources = res;
      var resources = [];
      var promises = _.map(res.entries, function (entry) {
        return new Promise((resolve, reject) => {
          getResource(entry, !!!dontFetchAgain).then(
            function (resource) {
              resources.push({ id: entry.id });
              var entity = _.find(resources, function (e) {
                return e.id === resource.id;
              });
              _.extend(entity, resource);
              resolve();
            },
            function (err) {
              resolve();
            },
          );
        });
      });
      return Promise.all(promises).then(function () {
        var sortedRes = sortToOrignalOrder(origResources, resources);
        return {
          raw: res,
          results: sortedRes,
        };
      });
    });
  },
  searchVersions: function (data, dontFetchAgain) {
    data = data || {};
    _.defaults(data, {
      offset: 0,
      count: 500,
      showBinaryMetadata: true,
      showAttributes: true,
      sortBy: "title ASC",
    });

    var contentTypeFiql;
    contentTypeFiql = "(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION + ")";
    if (!data.fq) {
      data.fq = contentTypeFiql;
    } else {
      data.fq = data.fq + ";" + contentTypeFiql;
    }
    data.contentType = ContentTypeEnum.TEKLA_WAREHOUSE;
    return TCCVersionService.searchEntities(data).then(function (res) {
      var origResources = res;
      var versionsWithBinaries = [];
      
      var versionPromises = _.map(res, function (version) {
        if (!!version.binaries) {
          return new Promise((resolve) => {
             TCCEntityDS.getBinaries(version.id, true).then(
              function (binaries) {   
                versionsWithBinaries.push({ ...version, binaries: binaries });
                resolve();
              }, function () {
                versionsWithBinaries.push(version);
                resolve();
              },
            );
          });
        } else {
          versionsWithBinaries.push(version);
        }
      });

      return Promise.all(versionPromises).then(function () {
        var resources = [];
        var promises = _.map(versionsWithBinaries, function (entry) {
          return new Promise((resolve) => {
            getResource(entry, !!!dontFetchAgain).then(
              function (resource) {
                resources.push(resource);
                resolve();
              },
              function () {
                resolve();
              },
            );
          });
        });

        return Promise.all(promises).then(function () {
          var res = sortToOrignalOrder(origResources, resources);
          return res;
        });
      });
    });
  },
  searchAutocompleteSuggestions: function (searchStr) {
    var data = {
      q: searchStr,
      contentType: "twh",
      count: 10,
    };
    return TCCSearchDS.searchAutocompleteSuggestions(data).then(function (res) {
      return res.suggestions;
    });
  },
  searchAutocompleteSuggestionsWithSelectedLanguage: function (searchStr) {
    var data = {
      q: searchStr,
      contentType: "twh",
      count: 10,
    };
    return TCCSearchDS.searchAutocompleteSuggestionsWithSelectedLanguage(data).then(function (res) {
      return res.suggestions;
    });
  },
};
