import * as React from "react";
import { observer } from "mobx-react";
import { RootContext } from "../stores/rootStore";
import { useContext } from "react";
import { DialogContext } from "../dialogs/dialogStore";
import { IDialogActionsStore } from "../models/dataModel";

/**
 * A component providing dialog action functionalities
 */
export const DialogActions = observer(({ store }: { store: IDialogActionsStore }) => {
  const rootStore = useContext(RootContext);
  const dialog = useContext(DialogContext);

  function saveData() {
    store.saveContent();
    dialog.close();
  }

  function closeDialog() {
    store.init();
    dialog.close();
  }

  function handleDoNotNotifySelection() {
    store.setDoNotNotify(!store.getDoNotNotify());
  }

  return (
    <div className="actions">
      {!store.isLocalContent() && (
        <div className="dont-notify">
          <input
            className="white-blue-border"
            id="do-not-notify"
            type="checkbox"
            checked={store.getDoNotNotify()}
            onChange={handleDoNotNotifySelection}
            data-testid="do-not-notify"
          />
          <label htmlFor="do-not-notify">{rootStore.getTranslation("shared.catalog_entity_edit.do_not_notify")}</label>
        </div>
      )}
      <button onClick={closeDialog} data-testid="close-dialog">
        {rootStore.getTranslation("actions.cancel")}
      </button>
      <button disabled={!store.isFormValid()} onClick={saveData} data-testid="save-data">
        {rootStore.getTranslation("actions.save")}
      </button>
    </div>
  );
});
