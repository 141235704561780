import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Fragment } from "react";

import { RootContext } from "../../stores/rootStore";
import { ResultOptionsStoreContext } from "./resultOptionsStore";
import { EditorModeStoreContext } from "../editor-mode/editorModeStore";

import { CategoryFilter } from "../search/CategoryFilter";
import { SortBy } from "../search/SortBy";
import { ListStyleToggle } from "../search/ListStyleToggle";
import { SearchField } from "../search/SearchField";
import { TranslatedHtml } from "../TranslatedHtml";
import { LinkToCollection } from "./LinkToCollection";
import { UnlinkFromCollection } from "./UnlinkFromCollection";

import { SearchPageListStyleEnum } from "../../models/enums";

/**
 * A component that renders order selection.
 */
const Sort = observer(() => {
  const resultOptionsStore = useContext(ResultOptionsStoreContext);

  return (
    <Fragment>
      <div className="sorting-options">
        <SortBy pageWhereUsed="result-options" sortSettingsStore={resultOptionsStore} />
      </div>
    </Fragment>
  );
});

/**
 * A component that renders options section. Hidden by default and when there's no results.
 */
export const Options = observer(() => {
  const rootStore = useContext(RootContext);
  const resultOptionsStore = useContext(ResultOptionsStoreContext);
  const editorModeStore = useContext(EditorModeStoreContext);

  function inListMode() {
    return resultOptionsStore.getSearchPageSettingsStore().resultListStyle == SearchPageListStyleEnum.LIST;
  }

  function removeSelectedItemFromBasket() {
    resultOptionsStore.removeSelectedEntities();
  }

  function addSelectedItemToBasket() {
    resultOptionsStore.addSelectedEntities();
  }

  function shouldDisplayMassUnlink() {
    return editorModeStore.getEditorMode() && resultOptionsStore.shouldDisplayMassUnlinkButton();
  }

  return (
    <Fragment>
      {resultOptionsStore.getOptionsVisible() && (
        <section
          className={classNames("collection-cart-result-options", {
            "as-thumbnails": inListMode(),
          })}
          data-testid="result-options"
        >
          <div className="summary l-center">
            <div className="version-sort">
              {resultOptionsStore.wasDataFetched() && (
                <div className="version-downloads">
                  <div className="version-buttons">
                    <div className="choose-action-info">{rootStore.getTranslation("helpers.choose_action")}</div>
                    <LinkToCollection />
                    {shouldDisplayMassUnlink() && <UnlinkFromCollection />}
                    {resultOptionsStore.shouldDisplayRemoveFromBasketButton() && (
                      <button
                        className="remove-from-basket"
                        disabled={!(resultOptionsStore.selectedCount > 0)}
                        onClick={removeSelectedItemFromBasket}
                        data-testid="remove-from-basket"
                      >
                        <span className="cart-button-text">
                          <TranslatedHtml entry="download.remove_item_from_basket" />
                        </span>
                        <span className="icon-warehouse" />
                      </button>
                    )}
                    {resultOptionsStore.shouldDisplayAddToBasketButton() && (
                      <button
                        className="add-selected-to-cart"
                        disabled={!(resultOptionsStore.selectedCount > 0)}
                        onClick={addSelectedItemToBasket}
                        data-testid="add-to-basket"
                      >
                        <span className="cart-button-text">
                          <TranslatedHtml entry="download.add_selected_items_to_basket" />
                        </span>
                        <span className="icon-warehouse" />
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className="sorts">
                <CategoryFilter categoryFilterStore={resultOptionsStore} />
                <Sort />
                <SearchField searchStore={resultOptionsStore} />
                <ListStyleToggle pageWhereUsed="result-options" />
              </div>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
});
