import * as React from "react";
import { useEffect, useContext } from "react";
import { RootContext, RootStore } from "../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../upload/uploadFormStore";
import { NewLocalCollection } from "./NewLocalCollection";

/**
 * A wrapper for a component responsible for creating new local collections.
 */
export const NewLocalCollectionWrapper = () => {
  const store: RootStore = useContext(RootContext);
  const form = new UploadFormStore(store);
  form.getCollectionStore().setFormStateFromOption({ value: "local", label: "local" });

  useEffect(() => {
    form.fetchData();
  }, []);

  return (
    <UploadFormContext.Provider value={form}>
      <NewLocalCollection />
    </UploadFormContext.Provider>
  );
};
