import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import classNames from "classnames";

import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStore, EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { OrganizationPageStore, OrganizationPageStoreContext } from "../organizationPageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { Dialog } from "../../dialogs/Dialog";
import { EditLogoDialog } from "./EditLogoDialog";

/**
 * A component that displays organization logo
 */
export const OrganizationLogo = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const dialog = new DialogStore();

  function editLogo() {
    dialog.open();
  }

  return (
    <section
      className={classNames("organization-logo", {
        cannotedit: !orgStore.canEditOrganization(),
      })}
    >
      <div className="l-right">
        {editorModeStore.getEditorMode() && (
          <DialogContext.Provider value={dialog}>
            <a
              data-testid="open-edit-warehouse-logo-dialog"
              title={rootStore.getTranslation("organization.edit.edit_logo")}
              onClick={editLogo}
              className="edit-logo icon icon-pencil"
            >
              <span>{rootStore.getTranslation("organization.edit.edit_logo")}</span>
            </a>
            <Dialog content={<EditLogoDialog />} additionalClass="edit-org-logo" />
          </DialogContext.Provider>
        )}
        {orgStore.logoExists && (
          <div
            className={classNames("logo", {
              editmode: editorModeStore.getEditorMode(),
            })}
            data-testid="org-logo"
          >
            <img src={orgStore.getOrganizationLogoUrl()}></img>
          </div>
        )}
      </div>
    </section>
  );
});
