import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { AdminStoreContext } from "./adminStore";
import { AclEditorStoreContext, AclEditorStore } from "../../collection/acl-editor/aclEditorStore";
import { AclEditor } from "../../collection/acl-editor/AclEditor";

export const GrantAnalyst = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);
  const [collectionExists, setCollectionExists] = useState(false);
  const analystCollection = store.getAnalystUsersContainerCollection();
  if (!analystCollection) {
    initAnalystUsersContainerCollection();
  }

  async function initAnalystUsersContainerCollection() {
    await store.initAnalystUsersContainerCollection();
    setCollectionExists(true);
  }

  return (
    <Fragment>
      {collectionExists && (
        <div className="add-analyst-user" data-testid="add-analyst-user">
          <div className="admin-header">{rootStore.getTranslation("profile.admin.grant_analyst_role")}</div>
          <AclEditorStoreContext.Provider
            value={new AclEditorStore(rootStore, store.getAnalystUsersContainerCollection())}
          >
            <AclEditor />
          </AclEditorStoreContext.Provider>
        </div>
      )}
    </Fragment>
  );
});
