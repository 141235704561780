import * as React from "react";
import { useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { ShoppingCartWidget } from "../../components/cart/ShoppingCartWidget";
import { UploadButton } from "../UploadButton";
import { AlertsButton } from "../AlertsButton";
import { HeaderSearch } from "../HeaderSearch";

/**
 * A component that displays right side of main navigation
 */
export const NaviRight = observer(() => {
  const store = useContext(RootContext);
  const user = store.getUserStore().getCurrentUser();

  return (
    <div className={classNames("nav-right", { user: user, nouser: !user })}>
      <AlertsButton />
      <UploadButton />
      {user && (
        <li data-testid="shopping-cart-right" className="shopping-cart">
          <ShoppingCartWidget />
        </li>
      )}
      <HeaderSearch />
    </div>
  );
});
