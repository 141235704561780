import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../uploadFormStore";
import { UploadEntityStore } from "./uploadEntityStore";

/**
 * Renders a license checkbox.
 */
const License = observer(({ license }) => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity: UploadEntityStore = form.getEntityStore();

  const hasLicense = entity.hasLicense(license.id);

  function handleSetLicense() {
    if (!hasLicense) entity.addLicense(license.id);
    else entity.removeLicense(license.id);
  }

  return (
    <Fragment>
      <input
        id={license.id}
        type="checkbox"
        data-testid={license.id}
        onChange={handleSetLicense}
        defaultChecked={hasLicense}
      />
      <label className="l-inline" htmlFor={license.id}>
        {license.text}
      </label>
    </Fragment>
  );
});

/**
 * Renders fields for website, help & support, & discussion forum URLs.
 */
const WebsiteSupportAndForumURLs = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity: UploadEntityStore = form.getEntityStore();

  function handleInputHelpUrl(event) {
    event.preventDefault();
    entity.setHelpUrl(event.target.value);
  }

  function handleInputSupportUrl(event) {
    event.preventDefault();
    entity.setSupportUrl(event.target.value);
  }

  function handleInputDiscussionForumUrl(event) {
    event.preventDefault();
    entity.setDiscussionForumUrl(event.target.value);
  }

  return (
    <Fragment>
      <li>
        <label className="tooltip-info" title={rootStore.getTranslation("upload.helpUrlSubtitle")}>
          {rootStore.getTranslation("upload.helpUrl")}
          <span className="icon help blue" />
        </label>
        <input type="url" value={entity.getHelpUrl()} onChange={handleInputHelpUrl} data-testid="helpUrl" />
      </li>
      <li>
        <label className="tooltip-info" title={rootStore.getTranslation("upload.supportUrlSubtitle")}>
          {rootStore.getTranslation("upload.supportUrl")}
          <span className="icon help blue" />
        </label>
        <input type="text" value={entity.getSupportUrl()} onChange={handleInputSupportUrl} data-testid="supportUrl" />
      </li>
      <li>
        <label className="tooltip-info" title={rootStore.getTranslation("upload.supportForumUrlSubtitle")}>
          {rootStore.getTranslation("upload.supportForumUrl")}
          <span className="icon help blue" />
        </label>
        <input
          type="url"
          value={entity.getDiscussionForumUrl()}
          onChange={handleInputDiscussionForumUrl}
          data-testid="discussionForumUrl"
        />
      </li>
    </Fragment>
  );
});

/**
 * Renders the fields for copyright information and special terms URL.
 */
const CopyrightInformationAndEULA = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity: UploadEntityStore = form.getEntityStore();

  function handleInputCopyrightInfo(event) {
    event.preventDefault();
    entity.setCopyrightInfo(event.target.value);
  }

  function handleInputSpecialTermsUrl(event) {
    event.preventDefault();
    entity.setSpecialTermsUrl(event.target.value);
  }

  return (
    <Fragment>
      <li>
        <label>{rootStore.getTranslation("upload.copyrightInfo")}</label>
        <input
          type="text"
          value={entity.getCopyrightInfo()}
          onChange={handleInputCopyrightInfo}
          data-testid="copyrightInfo"
          maxLength={350}
        />
      </li>
      <li>
        <label className="tooltip-info" title={rootStore.getTranslation("upload.specialTermsUrlSubtitle")}>
          {rootStore.getTranslation("upload.specialTermsUrl")}
          <span className="icon help blue" />
        </label>
        <input
          type="url"
          value={entity.getSpecialTermsUrl()}
          onChange={handleInputSpecialTermsUrl}
          data-testid="specialTermsUrl"
        />
      </li>
    </Fragment>
  );
});

/**
 * Main component for rendering the 'Support' column of the 'Content information' page.
 */
export const Support = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity: UploadEntityStore = form.getEntityStore();

  const entityIsNotLocal = !form.isLocal();

  return (
    <div className="column last" data-testid="support">
      <h3>{rootStore.getTranslation("upload.package.support")}</h3>
      <ol>
        <WebsiteSupportAndForumURLs />
        <CopyrightInformationAndEULA />
      </ol>
      {entityIsNotLocal && (
        <Fragment>
          <h3>{rootStore.getTranslation("details.licenses")}</h3>
          <ol>
            {entity.getAvailableLicenses().map((license, i: number) => {
              return (
                <li className="input-wrapper" key={i}>
                  <License license={license} />
                </li>
              );
            })}
          </ol>
        </Fragment>
      )}
    </div>
  );
});
