import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import { RootContext, RootStore } from "../stores/rootStore";
import { ISearchCriteriaHandler, IDropdownOption } from "../models/dataModel";
import { DropdownMultiple } from "./DropdownMultiple";
import { SearchCriteriaHandlerContext } from "../package/tabs/searchCriteriaStore";
import { metadataLabels } from "../utils/MetadataLabels";

/** Component for the Language options dropdown */
export const LanguageOptions = observer(({ hideLabel }: { hideLabel?: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ISearchCriteriaHandler = useContext(SearchCriteriaHandlerContext);

  const languageOptions: IDropdownOption[] = _.map(metadataLabels.supportedLanguagesOptions, (option) => {
    return {
      value: option.value,
      label: rootStore.getTranslation(option.name),
    };
  });

  function handleSelectSupportedLanguages(selectedOptions: readonly IDropdownOption[]) {
    store.setSupportedLanguages(selectedOptions);
  }

  return (
    <li>
      {!hideLabel && <label>{rootStore.getTranslation("upload.supportedLanguages")}</label>}
      <DropdownMultiple
        options={languageOptions}
        selectedValues={store.getSupportedLanguages()}
        onChange={handleSelectSupportedLanguages}
        className="dropdown-wrapper gray"
        inputId="languageOptions"
        menuHeight={175}
      />
    </li>
  );
});
