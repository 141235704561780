import * as React from "react";
import { useContext } from "react";

import {
  SearchWithAutocompleteStore,
  SearchWithAutocompleteStoreContext,
} from "../../stores/searchWithAutocompleteStore";
import { SearchWithAutocomplete } from "../../components/SearchWithAutocomplete";
import { RootContext } from "../../stores/rootStore";

/**
 * A component that renders entities search form. The form offers entity name autocompletion.
 */
export const SearchArea = () => {
  const rootStore = useContext(RootContext);

  return (
    <section className="heroSlider" data-testid="search-area">
      <div className="hero hero-intro">
        <div className="title">
          <h1>{rootStore.getTranslation("landing.heading")}</h1>
          <div className="icon-area">
            <div className="icon-warehouse" />
          </div>
        </div>
        <div className="search-area">
          <SearchWithAutocompleteStoreContext.Provider value={new SearchWithAutocompleteStore(rootStore)}>
            <SearchWithAutocomplete />
          </SearchWithAutocompleteStoreContext.Provider>
        </div>
        <p className="intro-text light">{rootStore.getTranslation("landing.static_intro")}</p>
      </div>
    </section>
  );
};
