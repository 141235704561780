import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { RootContext } from "../stores/rootStore";
import { IEntityProps } from "../models/dataModel";
import { SearchPageListStyleEnum } from "../models/enums";
import { EntityListItem } from "./EntityListItem";

/**
 * A component to display Entity. It provides also button to add entity to the shopping cart, for signed in users.
 */

export const EntityWithCart = observer(
  ({ entity, listStyle = SearchPageListStyleEnum.THUMBNAILS, showAlerts }: IEntityProps) => {
    const rootStore = useContext(RootContext);
    const userStore = rootStore.getUserStore();
    const shoppingCart = rootStore.getShoppingCartStoreCache();

    function canSelectEntity() {
      return !!rootStore.getUserStore().getCurrentUser() && userStore.canDownload(entity);
    }

    function shouldRenderShoppingCartIntegration() {
      return entity.type === "TEKLA_WAREHOUSE_PACKAGE" && canSelectEntity();
    }

    function renderShoppingCartIntegration() {
      const title = rootStore.getTranslation("download.add_item_to_basket");
      return (
        <div
          className={classNames("shopping-cart-actions", {
            thumbmode: listStyle == SearchPageListStyleEnum.THUMBNAILS,
          })}
          data-testid="shoppingCartIntegration"
        >
          {!shoppingCart.isInTheCart(entity) && (
            <button
              className="add-to-basket"
              title={title}
              onClick={() => shoppingCart.addToCart(entity)}
              data-testid="addToBasket"
            >
              <span className="cart-button-text icon plus" />
            </button>
          )}
          {shoppingCart.isInTheCart(entity) && (
            <button
              className="remove-from-basket"
              title={rootStore.getTranslation("download.remove_item_from_basket")}
              onClick={() => shoppingCart.removeFromCart(entity)}
              data-testid="removeFromBasket"
            >
              <span className="cart-button-text icon minus" />
            </button>
          )}
        </div>
      );
    }

    return (
      <Fragment>
        <EntityListItem entity={entity} listStyle={listStyle} showAlerts={showAlerts} />
        {shouldRenderShoppingCartIntegration() && renderShoppingCartIntegration()}
      </Fragment>
    );
  },
);
