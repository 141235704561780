import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";
import { Settings } from "../../config/Settings";
import { URLBuilder } from "../../utils/URLBuilder";
import { IdGenerator } from "../../utils/IdGenerator";

function currentUrlDoubleEncoded(absUrl) {
  let url = absUrl || window.location.href;
  return encodeURIComponent(encodeURIComponent(url));
}

export const TCCUserDS = {
  authorizeWithTekla: function (absUrl) {
    var s = Settings.tcc.authSuccessCallback + "?redirectToUrl=" + currentUrlDoubleEncoded(absUrl);
    var f = Settings.tcc.authFailureCallback + "?redirectToUrl=" + currentUrlDoubleEncoded(absUrl);
    var redirectUrl =
      URLBuilder.buildWarehouseURL(Settings.tcc.authUrl, { cache: IdGenerator.generateUUID() }, true) +
      Settings.tcc.authSuccessCallbackPrefix +
      s +
      Settings.tcc.authFailureCallbackPrefix +
      f;
    window.location.href = redirectUrl;
  },
  me: function () {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/users/me?showPersonalInfo=true"));
  },
  atcGraphMe: function (userId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/users/" + userId + "/atc/graph/me"));
  },
  getUser: function (userId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/users/" + userId), false, true);
  },
  getSubscriptions: function (data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/users/me/following", data), false, true);
  },
  subscribe: function (data) {
    return ResponseProcessor.process(RequestBuilder.doPost("/warehouse/v1.0/users/me/following", data));
  },
  deleteUser: function (userId) {
    return ResponseProcessor.process(RequestBuilder.doDelete("/warehouse/v1.0/users/" + userId));
  },
  unSubscribe: function (params) {
    return ResponseProcessor.process(RequestBuilder.doDelete("/warehouse/v1.0/users/me/following", params));
  },
  getUsers: function (data) {
    data = data || {};
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/users", data), false, true);
  },
  setUser: function (userId, data) {
    data = data || {};
    return ResponseProcessor.process(RequestBuilder.doPatch("/warehouse/v1.0/users/" + userId, data));
  },
  setAttributes: function (userId, data) {
    data = data || {};
    return ResponseProcessor.process(RequestBuilder.doPatch("/warehouse/v1.0/users/" + userId + "/attributes", data));
  },
  getAttributes: function (userId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/users/" + userId + "/attributes"));
  },
  logoff: function () {
    return ResponseProcessor.process(RequestBuilder.doPost("/warehouse/v1.0/loginflow/logout"));
  },
  getCookies: function () {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/loginflow/cookies"));
  },
  getOrganizations: function () {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/users/me/organizations"));
  },
  teklaLogoff: function () {
    var redirectUrl =
      URLBuilder.buildWarehouseURL(Settings.tcc.logOutUrl, {}, true) +
      Settings.tcc.logOutSuccessCallbackPrefix +
      Settings.tcc.logOutSuccessCallback +
      Settings.tcc.logOutFailureCallbackPrefix +
      Settings.tcc.logOutFailureCallback;
    window.location.assign(redirectUrl);
  },
  teklaContinueLogoff: function (params) {
    window.location.assign(URLBuilder.buildWarehouseURL(Settings.tcc.continueLogOutUrl, params, true));
  },
};
