import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import { ModusSpinner } from "@trimble-oss/modus-react-components";
import { useNavigate } from "react-router";

import { Dialog } from "../../dialogs/Dialog";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { RootContext, RootStore } from "../../stores/rootStore";

import { NewRepository } from "../../js/services/NewRepository";

import { ICollection, IEntity, IResource, IVersion } from "../../models/dataModel";
import { ExternalResourceTypeEnum, ObjectTypeEnum } from "../../models/enums";

export const ArchiveDialogContent = observer(
  ({ resource, setIsArchiving }: { resource: IResource; setIsArchiving?: any }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);
    const currentUser = rootStore.getUserStore().getCurrentUser();

    const navigate = useNavigate();

    function getPathOfParentResource(): string {
      switch (resource.type) {
        case ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION:
          return (resource as ICollection).creator?.externalResourceType == ExternalResourceTypeEnum.ORGANIZATION
            ? `/organizations/${(resource as ICollection).creator?.id}`
            : `/my/collections`;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE:
          return `/collections/online/${(resource as IEntity).collection?.id}`;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION:
          return `/catalog/details/${(resource as IVersion).package?.id}?showVersions=true`;
      }

      return "/";
    }

    const itemIsCollection =
      resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION || resource.type === ObjectTypeEnum.LOCAL_COLLECTION;

    const itemIsVersion =
      resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION || resource.type === ObjectTypeEnum.LOCAL_VERSION;

    async function archive() {
      if (!currentUser || !resource) return;

      !!setIsArchiving && setIsArchiving(true);
      dialog.close();

      try {
        switch (resource.type) {
          case ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION:
            await NewRepository.archiveCollection(resource as ICollection, currentUser);
            break;
          case ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE:
            await NewRepository.archivePackage(resource as IEntity, currentUser);
            break;
          case ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION:
            await NewRepository.archiveVersion(resource as IVersion, (resource as IVersion).package, currentUser);
        }

        setTimeout(() => {
          rootStore
            .getNotificationChannelStore()
            .success(rootStore.getTranslation("immutability.archive_success", resource.title));
          !!setIsArchiving && setIsArchiving(false);
          navigate(getPathOfParentResource());
        }, 3500);
      } catch {
        console.error("Failed to archive resource");
        rootStore
          .getNotificationChannelStore()
          .error(rootStore.getTranslation("immutability.archive_failure", resource.title));
        !!setIsArchiving && setIsArchiving(false);
      }
    }

    function cancel() {
      dialog.close();
    }

    return (
      <div
        data-testid="confirmArchiveDialog"
        style={{ display: "flex", flexDirection: "column", position: "relative", margin: "2em" }}
      >
        <header style={{ position: "relative", padding: 0 }}>
          <h3 style={{ marginTop: 0 }}>
            {itemIsCollection
              ? rootStore.getTranslation("immutability.archive_collection")
              : itemIsVersion
                ? rootStore.getTranslation("immutability.archive_version")
                : rootStore.getTranslation("immutability.archive_package")}
          </h3>
        </header>
        <section style={{ position: "relative", padding: 0 }}>
          <p style={{ marginTop: "0" }}>{rootStore.getTranslation("immutability.archive_dialog_text")}</p>
        </section>
        <hr />
        <div className="actions" style={{ display: "flex", justifyContent: "end" }}>
          <button className="cancel" onClick={cancel} data-testid="cancelButton">
            {rootStore.getTranslation("shared.confirm.no")}
          </button>
          <button className="button-primary" onClick={archive} data-testid="confirmButton">
            {rootStore.getTranslation("shared.confirm.yes")}
          </button>
        </div>
      </div>
    );
  },
);

export const ArchiveButton = observer(({ resource }: { resource: IResource }) => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);

  const [isArchiving, setIsArchiving] = React.useState(false);

  return (
    <React.Fragment>
      <button
        className="button-large l-left"
        data-testid={"archiveButtonFor-" + resource.id}
        onClick={() => dialog.open()}
      >
        {isArchiving ? <ModusSpinner /> : rootStore.getTranslation("immutability.archive")}
      </button>
      <Dialog
        content={<ArchiveDialogContent resource={resource} setIsArchiving={setIsArchiving} />}
        additionalClass="archive-dialog"
      />
    </React.Fragment>
  );
});
