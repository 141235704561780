import * as React from "react";
import { useContext } from "react";
import _ from "underscore";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { type Placement } from "@floating-ui/react";

import { RootStore, RootContext } from "../stores/rootStore";

import { cs } from "date-fns/locale/cs";
import { es } from "date-fns/locale/es";
import { enUS } from "date-fns/locale/en-US";
import { de } from "date-fns/locale/de";
import { fr } from "date-fns/locale/fr";
import { it } from "date-fns/locale/it";
import { ja } from "date-fns/locale/ja";
import { nl } from "date-fns/locale/nl";
import { pl } from "date-fns/locale/pl";
import { pt } from "date-fns/locale/pt";
import { ru } from "date-fns/locale/ru";
import { ko } from "date-fns/locale/ko";

registerLocale("cs", cs);
registerLocale("es", es);
registerLocale("en", enUS);
registerLocale("de", de);
registerLocale("fr", fr);
registerLocale("it", it);
registerLocale("ja", ja);
registerLocale("nl", nl);
registerLocale("pl", pl);
registerLocale("pt", pt);
registerLocale("ru", ru);
registerLocale("ko", ko);

interface IDatePickerOnChange {
  (selectedOption: Date | null);
}

/**
 * A date picker component, using:
 * https://www.npmjs.com/package/react-datepicker
 */
export const DateInput = ({
  ...props
}: {
  selectedValue: Date;
  onChange: IDatePickerOnChange;
  testId?: string;
  placement?: string;
}) => {
  const rootStore: RootStore = useContext(RootContext);
  const testId = props.testId ? props.testId : "dateInput";
  const placement = props.placement ? props.placement : "bottom-start";

  const locale = _.first(rootStore.getLocalizationStore().getLocale().split("-"));

  return (
    <div className="datepicker datepicker-field">
      <DatePicker
        selected={props.selectedValue}
        onChange={props.onChange}
        dateFormat={"dd / MM / yyyy"}
        locale={locale}
        customInput={<input type="text" data-testid={testId} />}
        popperPlacement={placement as Placement}
      />
    </div>
  );
};
