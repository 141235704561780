import { ISortOptions } from "../models/dataModel";

const options = {
  default: {
    online: "-relevance",
    local: "title",
    versions: "-modifiedAt",
    users: "name",
    organization: "title",
  },
  online: [
    "-relevance",
    "-createdAt",
    "creatorName",
    "-modifiedAt",
    "popularity",
    "title",
    "-title",
    "-reviewCount",
    "recommended",
  ],
  local: ["title", "-title"],
  versions: ["-modifiedAt", "-title", "title"],
  users: ["role", "name", "email"],
  organization: ["-createdAt", "title", "-title"],
};

/**
 * Sort options helper class
 */
export const SortOptions = {
  /**
   * Gets sort options
   */
  get: (): ISortOptions => {
    const sortOptions: ISortOptions = {
      online: [],
      local: [],
      versions: [],
      organization: [],
      default: options.default,
    };
    sortOptions.online = options.online.map((key) => {
      return { rule: key, label: "constants.sortOptions." + key };
    });
    sortOptions.local = options.local.map((key) => {
      return { rule: key, label: "constants.sortOptions." + key };
    });
    sortOptions.versions = options.versions.map((key) => {
      return { rule: key, label: "constants.sortOptions." + key };
    });
    sortOptions.organization = options.organization.map((key) => {
      return { rule: key, label: "constants.sortOptions." + key };
    });
    return sortOptions;
  },
};
