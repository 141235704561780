import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";

export const TCCFeatureToggleDS = {
  getFeatureToggles: function () {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/featuretoggles/twh"));
  },
  getFeatureToggle: function (toggleName) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/featuretoggles/twh/" + toggleName));
  },
  createFeatureToggle: function (toggle) {
    return ResponseProcessor.process(RequestBuilder.doPost("/warehouse/v1.0/featuretoggles", toggle));
  },
  updateFeatureToggle: function (toggle) {
    return ResponseProcessor.process(RequestBuilder.doPut("/warehouse/v1.0/featuretoggles/twh/" + toggle.name, toggle));
  },
};
