import Cookie from "mobx-cookie";
import { observable, makeObservable } from "mobx";

/**
 * Base class for stores that track changes in cookies
 */
export abstract class AbstractCookieStore {
  /**
   * Cookie handle
   */
  @observable protected cookieHandle;

  /**
   * Default constructor
   */
  public constructor() {
    makeObservable(this);
    this.loadCookie();
  }

  /**
   * Returns cookie name where data is stored.
   * @returns cookie name
   */
  public abstract getCookieName(): string;

  /**
   * Reloads data from the cookie.
   */
  public reload() {
    this.loadCookie();
  }

  /**
   * Reads the cookie and parses the value
   */
  protected abstract parseCookie(): any;

  /**
   * Stores data in the cookie
   * @param data data to store in the cookie
   */
  protected abstract storeInCookie(data: any);

  protected loadCookie() {
    this.cookieHandle = new Cookie(this.getCookieName());
  }
}
