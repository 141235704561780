export enum AccessRoleEnum {
  VIEWER = "viewer",
  EDITOR = "editor",
  FINDER = "finder",
  OWNER = "owner",
}

export enum AuthModeEnum {
  VALIDATE = "validate",
  SSO = "sso",
  SSO_TCC = "sso_tcc",
  FAILURE = "failure",
}

export enum AnalyticsEventTypeEnum {
  EVENTS = "TWH_EVENTS",
  VERSION_DOWNLOAD_FAILURE = "TWH_VERSION_DOWNLOAD_FAILURE",
  VERSION_DOWNLOAD_COMPLETE = "TWH_VERSION_DOWNLOAD_COMPLETE",
  CONTENT_VIEWED = "TWH_CONTENT_VIEWED",
  CLICKED_PARTNER_DOWNLOAD_LINK = "TWH_CLICKED_PARTNER_LINK",
  RECOMMENDED_CONTENT_VIEWED = "TWH_RECOMMENDED_CONTENT_VIEWED",
  NEWEST_CONTENT_VIEWED = "TWH_NEWEST_CONTENT_VIEWED",
  POPULAR_CONTENT_VIEWED = "TWH_POPULAR_CONTENT_VIEWED",
  DOWNLOADS = "downloads",
  VIEWS = "views",
}

export enum AnalyticsGroupByEnum {
  ITEM = "item",
  COLLECTION = "collection",
  ORGANIZATION = "organization",
  PACKAGE = "package",
  COUNTRY = "country",
  ORGANIZATION_AND_PACKAGE = "organization,package",
  ORGANIZATION_AND_ITEM = "organization,item",
}

export enum AnalyticsSourceEnum {
  TEKLA_WAREHOUSE = "TWH",
  ONLINE_PANEL = "OnlinePanel",
}

export enum AnalyticsSubjectTypeEnum {
  PACKAGE = "package",
  COLLECTION = "collection",
  CREATOR = "creator",
}

export enum AnalyticsTimeFrameEnum {
  TODAY = "downloads",
  THIS_WEEK = "this_week",
  THIS_MONTH = "this_month",
  THIS_YEAR = "this_year",
  BEGINNING_OF_TIME = "beginning_of_time",
  CUSTOM = "custom",
}

export enum AnalyticsTimeIntervalEnum {
  DAY = "day",
  HOUR = "hour",
}

export enum BinaryTypeEnum {
  UNKNOWN = -1, // unknown
  THUMBNAIL = 0,
  IMAGE = 1,
  VIDEO = 2,
  PACKAGE_CONTENT = 100,
  OTHER = 1000, // "an attachment" that is not a package content nor image
}

export enum CompatibleSoftwareEnum {
  TEKLA_STRUCTURES = "TS",
  TRIMBLE_CONNECT = "TC",
  SKETCHUP = "SK",
  OPEN_BIM = "openBIM",
  STANDALONE = "standalone",
  TEKLA_POWERFAB = "PF",
  TEKLA_TEDDS = "tedds",
  TEKLA_STRUCTURAL_DESIGNER = "TSD",
}

export enum ContentTypeEnum {
  TEKLA_WAREHOUSE = "TWH",
}

export enum DialogTypeEnum {
  TRANSLATIONS,
  BATCH_EDIT,
  OTHER,
}

export enum ExternalResourceTypeEnum {
  USER = "user",
  ORGANIZATION = "organization",
  ALL = "all",
}

export enum ItemSourceEnum {
  UNKNOWN = -1,
  BROWSER_FILE_PICKER = 0,
  CUSTOM_FILE_PICKER = 1,
  EXPORTED_FROM_TS = 2,
  TS = 3,
  DB = 4,
  TCC = 5,
}

export enum ItemTypeEnum {
  NO_ACTION = "noaction",
  OTHER = "other",
  RUN = "run",
  BOLT = "bolt",
  COMPONENT = "component",
  DRAWING = "drawing",
  MATERIAL = "material",
  PROFILE = "profile",
  MESH = "mesh",
  REBAR = "rebar",
  SHAPE = "shape",
  GEOMETRY = "geometry",
  MODEL_TEMPLATE = "modeltemplate",
  CLONING_TEMPLATE = "cloningtemplate",
  TSEP = "tsep",
}

export enum JobResultCodeEnum {
  SOFT_FAILED = "SCAN_SOFT_FAILED",
  HARD_FAILED = "SCAN_HARD_FAILED",
  INFECTED = "SCAN_INFECTED",
  SCAN_CLEAN = "SCAN_CLEAN",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum JobResultStatusEnum {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
}

export enum NotificationActionEnum {
  UPDATE = "update",
  DELETE = "delete",
  ADDED_ENTITY = "added_entity",
  REMOVED_ENTITY = "removed_entity",
  ADDED_COLLECTION = "added_collection",
  REMOVED_COLLECTION = "removed_collection",
  ADDED_PACKAGE = "added_package",
  REMOVED_PACKAGE = "removed_package",
}

export enum LocalUploadStateEnum {
  UNKNOWN = "unknown",
  PENDING = "pending",
  IN_PROGRESS = "inProgress",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  FAILED = "failed",
}

export enum NotificationStatusEnum {
  READ = "read",
  UNREAD = "unread",
}

export enum OrganizationRoleEnum {
  MEMBER = "member", // internal user with content editor role
  EMPLOYEE = "employee", // internal user without content editor role
  CONTENT_EDITOR = "contentEditor", // external user with content editor role
  NOTIFICATION_RECEIVER = "notificationReceiver",
}

export enum PlatformEnum {
  WIN_X86 = "WIN_X86",
  WIN_X64 = "WIN_X64",
  ANY = "ANY",
}

export enum UploadTypeEnum {
  LOCAL = "LOCAL",
}

export enum UserATCProfileStatusEnum {
  VERIFIED = "CanUseTeklaServices",
  TERMS_NOT_ACCEPTED = "TermsAndCondNotAccepted",
  UPDATED_TERMS_NOT_ACCEPTED = "UpdatedTermsAndCondNotAccepted",
  EMAIL_NOT_VERIFIED = "EmailNotVerified",
}

export enum NotifyChannelEnum {
  BROWSER = "BROWSER",
  DESKTOP = "DESKTOP",
  EMAIL = "EMAIL",
}

export enum ObjectTypeEnum {
  TEKLA_WAREHOUSE_USER = "TEKLA_WAREHOUSE_USER",
  TEKLA_WAREHOUSE_ORGANIZATION = "ATC_ORGANIZATION",

  TEKLA_WAREHOUSE_CATALOG = "TEKLA_WAREHOUSE_CATALOG",
  TEKLA_WAREHOUSE_COLLECTION = "TEKLA_WAREHOUSE_COLLECTION",
  TEKLA_WAREHOUSE_PACKAGE = "TEKLA_WAREHOUSE_PACKAGE",
  TEKLA_WAREHOUSE_VERSION = "TEKLA_WAREHOUSE_VERSION",

  TEKLA_WAREHOUSE_COLLECTIONS_LINKED_TO_USER_COLLECTION = "TEKLA_WAREHOUSE_COLLECTIONS_LINKED_TO_USER_COLLECTION",
   
  TEKLA_WAREHOUSE_COLLECTIONS_LINKED_TO_ORGANIZATION_COLLECTION = "TEKLA_WAREHOUSE_COLLECTIONS_LINKED_TO_ORGANIZATION_COLLECTION",

  TEKLA_WAREHOUSE_PACKAGES_LINKED_TO_COLLECTION_COLLECTION = "TEKLA_WAREHOUSE_PACKAGES_LINKED_TO_COLLECTION_COLLECTION",
  TEKLA_WAREHOUSE_ADMIN_SALES_USERS_CONTAINER_COLLECTION = "TEKLA_WAREHOUSE_ADMIN_SALES_USERS_CONTAINER_COLLECTION",
  TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION = "TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION",

  LOCAL_COLLECTION = "LOCAL_COLLECTION",
  LOCAL_COLLECTION_PREINSTALLED = "LOCAL_COLLECTION_PREINSTALLED",
  LOCAL_PACKAGE = "LOCAL_PACKAGE",
  LOCAL_VERSION = "LOCAL_VERSION",

  UNKNOWN = "UNKNOWN",
}

export enum SanitizationModeEnum {
  STRICT,
  JUST_LINKS,
  MODERATE,
  WEAK,
  NONE,
}

export enum SearchFilterTypeEnum {
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
}

export enum SearchPageListStyleEnum {
  LIST = "list",
  THUMBNAILS = "thumbnails",
}

export enum SearchPageOrderEnum {
  RELEVANCE = "-relevance",
  LAST_CREATED = "-createdAt",
  CREATOR = "creatorName",
  LAST_UPDATED = "-modifiedAt",
  POPULARITY = "popularity",
  ALPHABETICAL_AZ = "title",
  ALPHABETICAL_ZA = "-title",
  MOST_LIKED = "-reviewCount",
  RECOMMENDED = "recommended",
}

export enum SearchQueryParamEnum {
  itemTypeCategories = "itemTypeCategories",
  locations = "locations",
  measurementUnits = "measurementUnits",
  testedVersions = "testedVersions",
  products = "compatibleSoftwareProducts",
  languages = "languages",
  useCategories = "useCategories",
  sortBy = "sortBy",
  listSize = "listSize",
  searchTarget = "searchTarget",
  creator = "creator",
  showOrganizationList = "showOrganizationList",
  showContentView = "showContentView",
  showLocalContentView = "showLocalContentView",
  resultSet = "resultSet",
  entityId = "entityId",
  organizationId = "organizationId",
  searchTerm = "searchTerm",
  title = "title",
}

export enum SearchTargetOptionEnum {
  COLLECTION = "COLLECTION",
  PACKAGE = "PACKAGE",
  ORGANIZATION = "ORGANIZATION",
}

export enum SourceEnum {
  TEKLA_WAREHOUSE = "TEKLA_WAREHOUSE",
  LOCAL_SERVICE = "LOCAL_SERVICE",
  TCC = "TCC",
  ATC = "ATC",
  UNKNOWN = "UNKNOWN",
}

export enum UpdateTypeEnum {
  MAJOR = "major",
  MINOR = "minor",
  PATCH = "patch",
}

export enum UploadStrategyEnum {
  EDIT_VERSION = "editVersion",
  ADD_VERSION = "addVersion",
  NEW_COLLECTION = "newCollection",
}

export enum ResultSetEnum {
  TCC = "TCC",
  LOCAL = "LOCAL",
}

export enum VisibilityEnum {
  PUBLIC = "public",
  PRIVATE = "private",
  ALL = "all",
}

export enum ToggleEnum {
  ALL = "all",
  HIDE = "hide",
  SHOW = "show",
}

export enum AvailableSelectors {
  TITLE = "title",
  CREATOR_NAME = "creator.displayName",
  CREATOR_ID = "creatorId",
  CREATE_TIME = "createTime",
  MODIFY_TIME = "modifyTime",
  ACCESS_LEVEL = "baseAccessLevel",
  EXT_RESOURCE_TYPE = "creator.externalResourceType",
  HIDDEN = "isHidden",
  VIEWS = "views",
  DOWNLOADS = "downloads",
}

export enum AvailableAttributes {
  CONTENT_TYPE = "content",
  ITEM_TYPE_CATEGORIES = "itemTypeCategories",
  LANGUAGES = "supportedLanguages",
  LICENSES = "licenses",
  LOCATIONS = "locationRestrictions",
  MEASUREMENT_UNITS = "measurementUnits",
  REFERENCES = "references",
  SW_PRODUCTS = "compatibleSoftwareProducts",
  SW_VERSIONS = "testedVersions",
  USE_CATEGORIES = "useCategories",
  IMMUTABILITY = "immutability",
}
