import { action, computed, makeObservable, observable, runInAction } from "mobx";
import _ from "underscore";

import { Binary } from "../js/factories/Binary";
import { NewRepository } from "../js/services/NewRepository";

import { createStoreContext, RootStore } from "../stores/rootStore";
import { UploadCollectionStore } from "./collection/uploadCollectionStore";
import { UploadEntityStore } from "./content/uploadEntityStore";
import { UploaderStore } from "./uploaderStore";
import { UploadVersionStore } from "./version/uploadVersionStore";

import { organizationIsImmutable } from "../utils/Immutability";
import { ImageUtils } from "../utils/ImageUtils";

import { fieldValidity, IFieldValidity } from "../constants/FieldValidity";
import { ExternalResourceTypeEnum, UploadStrategyEnum } from "../models/enums";

export class UploadFormStore {
  /** Collection store, for handling the collection object. */
  private collection: UploadCollectionStore;

  /** Temporary storage for collection thumbnail */
  @observable private collectionThumbnail = null;

  /** Collection title */
  @observable private collectionTitle = "";

  /** Flag to mark if available collections and organizations were fetched */
  @observable private dataFetched = false;

  /** Entity store, for handling the entity object. */
  private entity: UploadEntityStore;

  /** Temporary storage for entity thumbnail */
  @observable private entityThumbnail = null;

  /** Entity title */
  @observable private entityTitle = "";

  /** Flag to mark if the page is loading available collections and organizations. */
  @observable private querying = false;

  /** Root store. */
  private rootStore: RootStore;

  /** Current stage of the upload process. */
  @observable private uploadStage = "collection";

  /** The three stages of upload process */
  private uploadStages = ["collection", "content", "version"];

  /** Selected upload strategy. */
  @observable private uploadStrategy: UploadStrategyEnum = UploadStrategyEnum.NEW_COLLECTION;

  /** Uploader store, for handling upload functionality. */
  private uploader: UploaderStore;

  /** Version store, for handling the version object. */
  @observable private version: UploadVersionStore;

  /** Version title */
  @observable private versionTitle = "";

  /** Indicates whether the content should be immutable */
  @observable private immutable: boolean = false;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.collection = new UploadCollectionStore(rootStore, this);
    this.entity = new UploadEntityStore(rootStore, this);
    this.version = new UploadVersionStore(rootStore, this);
    this.uploader = new UploaderStore(rootStore, this);
  }

  /** Returns all three navigation titles */
  @computed
  public get navigationTitles() {
    return {
      collectionTitle: this.collectionTitle,
      packageTitle: this.entityTitle,
      versionTitle: this.versionTitle,
    };
  }

  /**
   * Loads user's organizations and available collections from NewRepository.
   */
  @action
  public async fetchData(): Promise<boolean | undefined> {
    this.querying = true;
    let user = this.rootStore.getUserStore().getCurrentUser();

    if (!user) {
      this.rootStore.getUserStore().fetchData();
      user = this.rootStore.getUserStore().getCurrentUser();
    }

    if (!!user) {
      try {
        const collectionResults = await NewRepository.getCollectionsWithUploadRights({ user: user });
        const organizationResults = this.rootStore.getUserStore().getOrganizationsWithEditRightsAsCreators();

        runInAction(() => {
          this.collection.setAvailableCollections(collectionResults);
          this.collection.setOrganizations(organizationResults);
        });
      } catch {
        console.log("Error while fetching data for upload");
      } finally {
        runInAction(() => {
          this.querying = false;
          this.dataFetched = true;
        });
      }
      
      return true;
    }
  }

  /** Returns the collection form store */
  public getCollectionStore() {
    return this.collection;
  }

  /** Returns the entity form store */
  public getEntityStore() {
    return this.entity;
  }

  /** Returns the current upload stage */
  public getUploadStage() {
    return this.uploadStage;
  }

  /** Returns the active upload strategy */
  public getUploadStrategy(): UploadStrategyEnum {
    return this.uploadStrategy;
  }

  /** Returns the uploaderStore */
  public getUploaderStore() {
    return this.uploader;
  }

  /**
   * Returns field validity information.
   */
  public getValidations(): IFieldValidity {
    return fieldValidity;
  }

  /** Returns the version form store */
  public getVersionStore() {
    return this.version;
  }

  /** Updates the current upload stage by returning to previous one. */
  @action
  public goBack() {
    const indexOfCurrent = this.uploadStages.indexOf(this.uploadStage);
    this.uploadStage = this.uploadStages[indexOfCurrent - 1];
  }

  /** Updates the current upload stage by moving on the next one. */
  @action
  public goToNext() {
    const indexOfCurrent = this.uploadStages.indexOf(this.uploadStage);
    this.uploadStage = this.uploadStages[indexOfCurrent + 1];
  }

  /** Returns if there are remaining upload stages. */
  public hasNext() {
    return this.uploadStages.indexOf(this.uploadStage) < this.uploadStages.length - 1;
  }

  /** Returns if there are previous upload stages. */
  public hasPrevious() {
    return this.uploadStages.indexOf(this.uploadStage) > 0;
  }

  /**
   * Checks if all pages of the upload form are filled appropriately.
   */
  public async isFormValid(): Promise<boolean> {
    return this.collection.isFormValid() && (await this.entity.isFormValid()) && this.version.isFormValid();
  }

  /**
   * Checks the state of the query for available collections and organizations.
   */
  public isLoading(): boolean {
    return this.querying;
  }

  /**
   * Returns wheather the collection and its contents are stored locally.
   */
  public isLocal() {
    return this.collection.isLocal();
  }

  /**
   * Removes the thumbnail.
   * @param from either collection or entity
   */
  @action
  public removeThumbnail(from: string) {
    if (from === "collection") {
      this.collection.setThumbnail(undefined);
      this.collectionThumbnail = null;
    } else if (from === "content") {
      this.entity.setThumbnail(undefined);
      this.entityThumbnail = null;
    }
  }

  /** Sets the collection title used in the header */
  @action
  public setCollectionTitle(title: string) {
    this.collectionTitle = title;
  }

  /** Sets the entity title used in the header */
  @action
  public setEntityTitle(title: string) {
    this.entityTitle = title;
  }

  /** Sets the entity title used in the header */
  @action
  public setIsImmutable(value: boolean) {
    this.immutable = value;
  }

  /**
   * Returns the isImmutable flag.
   */
  public isImmutable(): boolean {
    return this.immutable;
  }

  /**
   * Returns if the user is editing an immutable version.
   */
  public editingImmutableVersion(): boolean {
    return this.uploadStrategy === UploadStrategyEnum.EDIT_VERSION && !!this.version.getVersionInEdit()?.isImmutable;
  }

  /**
   * Sets the selected file (or first if many selected) as the collection thumbnail.
   * @param files list of selected files
   * @param to string indicating if adding thumbnail for "collection" or "content"
   */
  @action
  public setThumbnailFromFile(selectedFile: File | undefined, to: string) {
    if (!selectedFile) return;

    if (!ImageUtils.isImage(selectedFile)) {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("collections.notification.invalid_file_type"));
    } else if (ImageUtils.isTooBigImage(selectedFile)) {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("upload.package.selected_image_is_too_big"));
    } else {
      if (to === "collection") {
        this.collection.setThumbnail(Binary.create({ file: selectedFile }));
        ImageUtils.readDataLocally(selectedFile, (res: any) => {
          runInAction(() => {
            this.collectionThumbnail = res;
          });
        });
      } else if (to === "content") {
        this.entity.setThumbnail(Binary.create({ file: selectedFile }));
        ImageUtils.readDataLocally(selectedFile, (res: any) => {
          runInAction(() => {
            this.entityThumbnail = res;
          });
        });
      }
    }
  }

  /**
   * Sets up the form for adding content to a given collection.
   * @param id id of the collection
   */
  @action
  public setUpAddContentToCollection(id: string, isLocal?: boolean) {
    this.uploadStage = "content";
    this.uploadStages = _.without(this.uploadStages, "collection");

    this.collection.setSelectedCollectionFromId(id, isLocal);
  }

  /**
   * Sets the upload strategy.
   * @param to selected strategy (e.g. "addVersion" / "editVersion")
   */
  @action
  public setUploadStrategy(to: UploadStrategyEnum) {
    this.uploadStrategy = to;
  }

  /** Sets the version title used in the header */
  @action
  public setVersionTitle(title: string) {
    this.versionTitle = title;
  }

  /** Changes upload stage to 'publish'. */
  @action
  public startPublish() {
    this.uploadStage = "publish";
  }

  /**
   * Returns url for the thumbnail, or if not available, the default thumbnail image.
   */
  public thumbnailOrDefault(type: string): string {
    let thumbnail;
    if (type === "collection") {
      thumbnail = this.collectionThumbnail;
    } else if (type === "content") {
      thumbnail = this.entityThumbnail;
    }
    return thumbnail ? thumbnail : ImageUtils.getDefaultThumbail();
  }

  /**
   * Checks if the creator of the collection is an immutable organization.
   * @returns A promise that resolves to a boolean indicating whether the creator is an immutable organization.
   */
  public async creatorIsAnImmutableOrganization(): Promise<boolean> {
    return (
      !!this.getCollectionStore().getCollection()?.creator &&
      this.getCollectionStore().getCreatorType() === ExternalResourceTypeEnum.ORGANIZATION &&
      (await organizationIsImmutable(this.getCollectionStore().getCollection()?.creator!.id))
    );
  }

  /**
   * Checks if available collections and organizations were fetched.
   */
  public wasDataFetched(): boolean {
    return this.dataFetched;
  }
}

export const UploadFormContext = createStoreContext<UploadFormStore>(UploadFormStore);
