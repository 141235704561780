import { action, observable, runInAction, makeObservable } from "mobx";
import { createStoreContext, RootStore } from "../../stores/rootStore";
import { ISiteStats } from "../../models/dataModel";
import { NewRepository } from "../../js/services/NewRepository";
import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";

/**
 *
 */
export class StatsStore extends AbstractAsyncStore {
  @observable private stats: ISiteStats = {
    pageViews: 0,
    sessions: 0,
    users: 0,
    organizations: 0,
    collections: 0,
    packages: 0,
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.fetchData();
  }

  /**
   *
   *
   */
  @action
  public fetchData = async () => {
    try {
      this.dataFetched = false;
      const sites = await NewRepository.getSiteStats();

      const res = sites as {
        pageviewCount: number;
        sessionCount: number;
        userCount: number;
        organizationsWithPublicPackages: number;
        publicCollectionCount: number;
        publicPackageCount: number;
      };

      runInAction(() => {
        this.stats = {
          pageViews: res.pageviewCount,
          sessions: res.sessionCount,
          users: res.userCount,
          organizations: res.organizationsWithPublicPackages,
          collections: res.publicCollectionCount,
          packages: res.publicPackageCount,
        };
      });
    } catch (err) {
      console.log(`Failed to fetch statistics, server responded with "${err.originalError.message}"`);
      throw err;
    } finally {
      runInAction(() => {
        this.dataFetched = true;
      });
    }
  };

  public getStats(): ISiteStats {
    return this.stats;
  }
}

/**
 * Context object for StatsStore instances.
 */
export const StatsContext = createStoreContext<StatsStore>(StatsStore);
