import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext, useState } from "react";
import { RootContext } from "../../stores/rootStore";
import { IBatchCollection, IBatchEntity } from "../../models/dataModel";
import { BatchContentHandlerContext } from "../../dialogs/batch-edit/batchEditDialogStore";
import { ContentCheckbox } from "./ContentCheckbox";

const PackageItem = observer(({ packageItem }: { packageItem: IBatchEntity }) => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchContentHandlerContext);
  const [toggleChanged, setToggleChanged] = useState(false);

  function resolveViewPackageId() {
    return "view-package-" + packageItem.id;
  }

  function resolveHidePackageId() {
    return "hide-package-" + packageItem.id;
  }

  function shouldListVersions(): boolean {
    return (
      !!packageItem.open && !!packageItem.versions && packageItem.versions!.length > 0 && dialogStore.showVersions()
    );
  }

  function togglePackage() {
    dialogStore.toggleContent(packageItem);
    setToggleChanged(!toggleChanged);
  }

  return (
    <Fragment>
      {!dialogStore.isProcessing() && (
        <Fragment>
          {dialogStore.showVersions() && (
            <div className="treecontrols">
              {packageItem.open ? (
                <div data-testid={resolveHidePackageId()} className="close" onClick={() => togglePackage()}>
                  {rootStore.getTranslation("batch_edit.content.treecontrol.close")}
                </div>
              ) : (
                <div data-testid={resolveViewPackageId()} className="open" onClick={() => togglePackage()}>
                  {rootStore.getTranslation("batch_edit.content.treecontrol.open")}
                </div>
              )}
            </div>
          )}
          <ContentCheckbox content={packageItem} />
          <span className="checkbox-label" onClick={() => togglePackage()}>
            {packageItem.title}
          </span>
          {shouldListVersions() && (
            <Fragment>
              {packageItem.loading ? (
                <div className="spinner content-list" data-testid="loadingVersions">
                  {rootStore.getTranslation("shared.spinner_loading")}
                </div>
              ) : (
                <Fragment>
                  {packageItem.versions!.map((version, i) => {
                    return (
                      <div className="version-list" key={i} data-testid={`versionsListFor-${packageItem.id}`}>
                        <ContentCheckbox content={version} />
                        <span className="checkbox-label">{version.title}</span>
                      </div>
                    );
                  })}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

const PackagesList = observer(({ packages }: { packages: IBatchEntity[] }) => {
  return (
    <Fragment>
      {packages!.map((packageItem, i) => {
        return (
          <div className="content-list" key={i} data-testid="packagesList">
            <PackageItem packageItem={packageItem} />
          </div>
        );
      })}
    </Fragment>
  );
});

const Collection = observer(({ collection }: { collection: IBatchCollection }) => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchContentHandlerContext);
  const [toggleChanged, setToggleChanged] = useState(false);

  function resolveViewCollectionId() {
    return "view-collection-" + collection.id;
  }

  function resolveHideCollectionId() {
    return "hide-collection-" + collection.id;
  }

  function toggleCollection() {
    dialogStore.toggleContent(collection);
    setToggleChanged(!toggleChanged);
  }

  function shouldListPackages(): boolean {
    return !!collection.open && !!collection.packages && collection.packages!.length > 0;
  }

  return (
    <Fragment>
      {!dialogStore.isProcessing() && (
        <Fragment>
          <div className="treecontrols">
            {collection.open ? (
              <div data-testid={resolveHideCollectionId()} className="close" onClick={() => toggleCollection()}>
                {rootStore.getTranslation("batch_edit.content.treecontrol.close")}
              </div>
            ) : (
              <div data-testid={resolveViewCollectionId()} className="open" onClick={() => toggleCollection()}>
                {rootStore.getTranslation("batch_edit.content.treecontrol.open")}
              </div>
            )}
          </div>
          <ContentCheckbox content={collection} />
          <span className="checkbox-label" onClick={() => toggleCollection()}>
            {collection.title}
          </span>
          {shouldListPackages() && (
            <Fragment>
              {collection.loading ? (
                <div className="spinner content-list" data-testid="loadingPackages">
                  {rootStore.getTranslation("shared.spinner_loading")}
                </div>
              ) : (
                <PackagesList packages={collection.packages!} />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
});

/**
 * Component that renders content listing to batch dialogs
 */
export const ContentList = observer(() => {
  const dialogStore = useContext(BatchContentHandlerContext);

  return (
    <Fragment>
      {!dialogStore.isProcessing() && (
        <Fragment>
          {dialogStore.getCollections().map((collection: IBatchCollection, i: number) => {
            return (
              <div className="collection-list" key={i}>
                <Collection collection={collection} />
              </div>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
});
