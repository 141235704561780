import * as React from "react";
import { observer } from "mobx-react";
import { useContext, useState, useEffect } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { CollectionPageStore } from "../../collection/collectionPageStore";
import { PackagePageStore } from "../../package/packagePageStore";
import { LocalCollectionPageStore } from "local-collection/localCollectionPageStore";
import { DialogContext, DialogStore } from "../dialogStore";

import { IItem } from "../../models/dataModel";
import { ObjectTypeEnum, SanitizationModeEnum } from "../../models/enums";

import { RichTextEditor } from "../../components/RichTextEditor";
import { sanitize } from "../../utils/functions";

/**
 * Renders the "Edit description" dialog used on collection & package pages.
 */
export const EditDescriptionDialog = observer(
  ({ item, store }: { item: IItem; store: LocalCollectionPageStore | CollectionPageStore | PackagePageStore }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);
    const translationsStore = item.isLocal
      ? undefined
      : (store as CollectionPageStore | PackagePageStore).getTranslationsStore();

    const showDontNotify = !item.isLocal;
    const actionButtonText =
      item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION || item.type === ObjectTypeEnum.LOCAL_COLLECTION
        ? rootStore.getTranslation("actions.update")
        : rootStore.getTranslation("actions.save");

    const [description, setDescription] = useState<string>("");
    const [dontNotify, setDontNotify] = useState<boolean>(false);

    useEffect(() => {
      if (item) {
        if (translationsStore && translationsStore.getTranslationForField("description")) {
          setDescription(
            sanitize(translationsStore.getTranslationForField("description"), SanitizationModeEnum.MODERATE),
          );
        } else {
          setDescription(sanitize(item.description, SanitizationModeEnum.MODERATE));
        }
      }
    }, []);

    function handleInput(input: string) {
      setDescription(input);
    }

    function handleChangeDontNotify() {
      setDontNotify(!dontNotify);
    }

    function cancel() {
      dialog.close();
    }

    async function updateDescription() {
      store.setDescription(description);
      if (item.isLocal) {
        await (store as LocalCollectionPageStore).updateDescription();
      } else {
        await store.updateDescription(dontNotify);
      }
      dialog.close();
    }

    return (
      <article className="edit-dialog" data-testid="editDescriptionDialog">
        <header>
          <h2>{rootStore.getTranslation("shared.catalog_entity_edit.edit_description")}</h2>
        </header>
        <section className="not-scrollable">
          <RichTextEditor field="description" onChange={handleInput} value={description} targetItem={item} />
        </section>
        <div className="actions">
          {showDontNotify && (
            <div className="dont-notify">
              <input type="checkbox" id="dontNotify" onChange={handleChangeDontNotify} data-testid="dontNotify" />
              <label htmlFor="dontNotify">{rootStore.getTranslation("shared.catalog_entity_edit.do_not_notify")}</label>
            </div>
          )}
          <button onClick={cancel} data-testid="cancelEdit">
            {rootStore.getTranslation("actions.cancel")}
          </button>
          <button className="button-primary" onClick={updateDescription} data-testid="updateDescription">
            {actionButtonText}
          </button>
        </div>
      </article>
    );
  },
);
