import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { TranslatedHtml } from "./TranslatedHtml";
import { RootContext, RootStore } from "../stores/rootStore";

import { ICollection, IEntity } from "../models/dataModel";

/**
 * Renders a banner indicating that the provided item is archived.
 *
 * @param {IEntity | ICollection} item - The item that is archived.
 * @returns {JSX.Element} The rendered archived banner.
 */
export const ArchivedBanner = observer(({ item }: { item: IEntity | ICollection }) => {
  const rootStore: RootStore = useContext(RootContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (item.isArchived) {
      rootStore
        .getNotificationChannelStore()
        .error(rootStore.getTranslation("immutability.archived_content_redirect_message"));

      navigate("/");
    }
  }, [item]);

  return (
    <Fragment>
      {item.isArchived && (
        <div className="content-archived-banner" data-testid={`contentArchived`}>
          <div className="content-archived">
            <div className="archived-info-sign" />
            <div className="archived-info-text">
              <TranslatedHtml entry="details.status.content_archived_info" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
});
