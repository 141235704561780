import { ICollection, IResultOptionsStrategy, ISearchCriteria, IEntity, IDropdownOption } from "../models/dataModel";
import _ from "underscore";
import { isEntityMatchingSearchTerm, sortArray } from "../utils/functions";
import { RootStore } from "../stores/rootStore";
import { NewRepository } from "../js/services/NewRepository";
import { action } from "mobx";
import { SearchPageOrderEnum } from "models/enums";

/**
 * class that presents strategy to be used for shopping cart page
 */
export class CollectionPageStrategy implements IResultOptionsStrategy {
  /**
   * Root store.
   */
  private rootStore: RootStore;
  /**
   * Collection object.
   */
  private collection?: ICollection;

  /**
   * Version filter value used on previous search
   */
  private versionFilter = "";

  /**
   * Sort value used on previous search
   */
  private resultListOrder = "";

  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public setCollection(collection?: ICollection) {
    if (collection) {
      this.collection = collection;
    }
  }

  /**
   * checks if add button should be displayed
   * @returns true if should display button
   */
  public shouldDisplayAddToBasketButton(): boolean {
    return true;
  }
  /**
   * checks if remove button should be displayed
   * @returns true if should display button
   */
  public shouldDisplayRemoveFromBasketButton(): boolean {
    return false;
  }

  /**
   * checks if mass unlink button should be displayed
   * @returns true if should display button
   */
  public shouldDisplayMassUnlinkButton(): boolean {
    return true;
  }

  /**
   * checks if select all option should be available
   * @param entities list of entities
   * @param versionFilter value of version filter
   * @returns true if can select
   */
  public shouldDisplaySelectAll(entities: IEntity[], versionFilter: IDropdownOption): boolean {
    return !!versionFilter && versionFilter.value !== "allVersions" && entities.length > 0;
  }

  /**
   * checks if entity selection can be made
   * @param entity entity to check
   * @param versionFilter value of version filter
   * @returns true if can select
   */
  public canSelectEntity(entity: IEntity, versionFilter: IDropdownOption): boolean {
    return (
      !!versionFilter && versionFilter.value !== "allVersions" && this.rootStore.getUserStore().canDownload(entity)
    );
  }

  private getVersionFilter(searchCriteria?: ISearchCriteria): string {
    if (!!searchCriteria) {
      if (!!searchCriteria.versionFilter && !!searchCriteria.versionFilter.value) {
        let filterValue = searchCriteria.versionFilter.value;
        if (filterValue == "allVersions") {
          filterValue = "";
        }
        return filterValue;
      }
    }
    return "";
  }

  private shouldDoClientSort(sort: string) {
    return sort !== "-relevance" && sort !== "popularity" && sort !== "creatorName";
  }

  /**
   * checks if entities should be updated before filtering
   * @returns true if entities should be updated
   */
  public shouldUpdateEntities(searchCriteria: ISearchCriteria): boolean {
    const versionFilter = this.getVersionFilter(searchCriteria);
    if (this.versionFilter != versionFilter) {
      return true;
    }
    const resultListOrder = this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder;
    return resultListOrder !== this.resultListOrder && !this.shouldDoClientSort(resultListOrder);
  }

  /**
   * filters entities based on selection criteria
   * @param searchCriteria wrapper object for search values
   * @param entities list of entities
   * @returns promise of entities
   */
  @action
  public async filterEntities(searchCriteria: ISearchCriteria, entities: IEntity[]): Promise<IEntity[]> {
    if (this.shouldUpdateEntities(searchCriteria)) {
      entities = await this.fetchData(searchCriteria);
    }
    this.versionFilter = this.getVersionFilter(searchCriteria);
    this.resultListOrder = this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder;

    const filteredEntities = _.chain(entities)
      .filter((entity) => {
        return isEntityMatchingSearchTerm(entity, searchCriteria.searchTerm || "");
      })
      .filter((entity) => {
        if (searchCriteria.itemCategoryFilter && searchCriteria.itemCategoryFilter.value !== "all") {
          return _.contains(entity.attributes!.itemTypeCategories!, searchCriteria.itemCategoryFilter.value);
        } else {
          return true;
        }
      })
      .value();

    if (this.shouldDoClientSort(this.resultListOrder)) {
      return sortArray(filteredEntities, this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder);
    } else {
      return entities;
    }
  }

  /**
   * fetch collection entities
   * @returns Promise of entity list
   */
  public async fetchData(searchCriteria?: ISearchCriteria): Promise<IEntity[]> {
    let packages: IEntity[] = [];
    let linkedPackages: IEntity[] = [];
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (this.collection) {
      try {
        packages = await NewRepository.getPackages(
          {
            id: this.collection.id,
            testedVersion: this.getVersionFilter(searchCriteria),
            sortBy:
              this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder ==
              SearchPageOrderEnum.RECOMMENDED
                ? SearchPageOrderEnum.RELEVANCE
                : this.rootStore.getSearchStore().getSearchPageSettingsStore().resultListOrder,
          },
          true,
          user?.id,
        );

        packages = _.map(packages, (entity: IEntity) => {
          return {
            ...entity,
            selected: false,
            getEntityPath: function () {
              return "/catalog/details/" + this.id;
            },
            getOrganizationPath: function () {
              return "/organization/" + this.creator!.id;
            },
            isLinked: false,
          };
        }) as unknown as IEntity[];
      } catch {
        console.log("Failed to fetch packages");
      }

      try {
        linkedPackages = await NewRepository.getLinkedResources(
          {
            id: this.collection.id,
            linkedResourcesCollection: this.collection.linkedResourcesCollection,
            type: this.collection.type,
          },
          true,
          user?.id,
        );

        linkedPackages = _.map(linkedPackages, (entity: IEntity) => {
          return {
            ...entity,
            selected: false,
            getEntityPath: function () {
              return "/catalog/details/" + this.id;
            },
            getOrganizationPath: function () {
              return "/organization/" + this.creator!.id;
            },
            isLinked: true,
          };
        }) as unknown as IEntity[];
      } catch {
        console.log("Failed to fetch linked packages");
      }

      return packages.concat(linkedPackages);
    } else {
      throw new Error("no collection");
    }
  }
}
