import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import _ from "underscore";

import { ContentManagerStore, ContentManagerStoreContext } from "./contentManagerStore";

import { ModusSpinner } from "@trimble-oss/modus-react-components";
import { IBatchCollection, IBatchEntity } from "../../../models/dataModel";
import { ResultCard } from "./result-card/ResultCard";

export const SearchResults = observer(() => {
  const contentManagerStore: ContentManagerStore = useContext(ContentManagerStoreContext);

  return (
    <div className="content-manager-results">
      {contentManagerStore.isProcessing() ? (
        <ModusSpinner color="primary" />
      ) : (
        <div className="result-list" data-testid={`${contentManagerStore.getSearchTarget()}-result-list`}>
          {_.map(contentManagerStore.getResults(), (item: IBatchCollection | IBatchEntity, i: number) => {
            return (
              <div className="result-list-item" data-testid={`item-${item.id}`} key={i}>
                <ResultCard item={item} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});
