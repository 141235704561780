import * as React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { SupportStore, SupportStoreContext } from "../tabs/supportStore";
import { DialogContext } from "../../dialogs/dialogStore";

import { DialogActions } from "../../components/DialogActions";
import { Dialog } from "../../dialogs/Dialog";

const HelpUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const store: SupportStore = useContext(SupportStoreContext);

  function handleHelpUrlChange(event) {
    store.setSupportFieldUrl("helpUrl", event.target.value);
  }

  return (
    <li>
      <label className="tooltip-info" title={rootStore.getTranslation("upload.helpUrlSubtitle")}>
        {rootStore.getTranslation("upload.helpUrl")}
        <span className="icon help blue" />
      </label>
      <input
        data-testid="dialog-help-url"
        className={classNames({
          invalid: !store.isValidUrl(store.getSupportFieldUrl("helpUrl")),
        })}
        type="url"
        value={store.getSupportFieldUrl("helpUrl")}
        onChange={handleHelpUrlChange}
      ></input>
    </li>
  );
});

const SupportUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(SupportStoreContext);

  function handleSupportUrlChange(event) {
    store.setSupportFieldUrl("supportUrl", event.target.value);
  }

  return (
    <li>
      <label className="tooltip-info" title={rootStore.getTranslation("upload.supportUrlSubtitle")}>
        {rootStore.getTranslation("upload.supportUrl")}
        <span className="icon help blue" />
      </label>
      <input
        data-testid="dialog-support-url"
        type="text"
        value={store.getSupportFieldUrl("supportUrl")}
        onChange={handleSupportUrlChange}
      ></input>
    </li>
  );
});

const DiscussionForumUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(SupportStoreContext);

  function handleDiscussionForumUrlChange(event) {
    store.setSupportFieldUrl("discussionForumUrl", event.target.value);
  }

  return (
    <li>
      <label className="tooltip-info" title={rootStore.getTranslation("upload.supportForumUrlSubtitle")}>
        {rootStore.getTranslation("upload.supportForumUrl")}
        <span className="icon help blue" />
      </label>
      <input
        data-testid="dialog-discussion-forum-url"
        className={classNames({
          invalid: !store.isValidUrl(store.getSupportFieldUrl("discussionForumUrl")),
        })}
        type="url"
        value={store.getSupportFieldUrl("discussionForumUrl")}
        onChange={handleDiscussionForumUrlChange}
      ></input>
    </li>
  );
});

const Copyright = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(SupportStoreContext);

  function handleCopyrightChange(event) {
    store.setSupportFieldUrl("copyright", event.target.value);
  }

  return (
    <li>
      <label className="tooltip-info" title={rootStore.getTranslation("upload.copyrightInfo")}>
        {rootStore.getTranslation("upload.copyrightInfo")}
      </label>
      <input
        data-testid="dialog-copyright"
        type="text"
        value={store.getSupportFieldUrl("copyright")}
        onChange={handleCopyrightChange}
        maxLength={350}
      ></input>
    </li>
  );
});

const SpecialTermsUrl = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(SupportStoreContext);

  function handleSpecialTermsUrlChange(event) {
    store.setSupportFieldUrl("specialTermsUrl", event.target.value);
  }

  return (
    <li>
      <label className="tooltip-info" title={rootStore.getTranslation("upload.specialTermsUrlSubtitle")}>
        {rootStore.getTranslation("upload.specialTermsUrl")}
        <span className="icon help blue" />
      </label>
      <input
        data-testid="dialog-special-terms-url"
        className={classNames({
          invalid: !store.isValidUrl(store.getSupportFieldUrl("specialTermsUrl")),
        })}
        type="url"
        value={store.getSupportFieldUrl("specialTermsUrl")}
        onChange={handleSpecialTermsUrlChange}
      ></input>
    </li>
  );
});

const SupportForm = observer(() => {
  const store: SupportStore = useContext(SupportStoreContext);

  return (
    <form name="supportForm">
      <ol
        className={classNames("support-data", {
          local: store.isLocalContent(),
        })}
      >
        <HelpUrl />
        <SupportUrl />
        <DiscussionForumUrl />
        <Copyright />
        <SpecialTermsUrl />
      </ol>
    </form>
  );
});

const DialogContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: SupportStore = useContext(SupportStoreContext);

  return (
    <article>
      <header>
        <h2>{rootStore.getTranslation("shared.catalog_entity_edit.edit_support")}</h2>
      </header>
      <section>
        <SupportForm />
      </section>
      <DialogActions store={store} />
    </article>
  );
});

export const EditSupportInfo = observer(() => {
  const rootStore = useContext(RootContext);
  const dialog = useContext(DialogContext);

  function editSupport() {
    dialog.open();
  }

  return (
    <Fragment>
      <div className="edit-support">
        <a
          data-testid="edit-support"
          className="icon icon-pencil"
          title={rootStore.getTranslation("details.edit.tooltips.support")}
          onClick={editSupport}
        >
          <span>{rootStore.getTranslation("details.edit.tooltips.support")}</span>
        </a>
      </div>
      <Dialog content={<DialogContent />} additionalClass="support" />
    </Fragment>
  );
});
