import * as React from "react";
import { useContext } from "react";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext } from "../stores/rootStore";

export const ApplicationDevelopers = () => {
  const store = useContext(RootContext);

  return (
    <article className="about-application_developers">
      <header>
        <h2>{store.getTranslation("about.application_developers.title")}</h2>
      </header>
      <section>
        <p>{store.getTranslation("about.application_developers.description_1")}</p>
        <p>
          <TranslatedHtml entry="about.application_developers.description_2" />
        </p>
        <p>
          <TranslatedHtml entry="about.application_developers.description_3" />
        </p>
        <p>
          <TranslatedHtml entry="about.application_developers.description_4" />
        </p>
      </section>
    </article>
  );
};

export const applicationDevelopersNavigationInfo = {
  name: "application_developers",
  component: <ApplicationDevelopers />,
  translationId: "about.navigation.application_developers",
};
