import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { CreateNewOnlineCollection } from "../upload/collection/CreateNewOnlineCollection";
import { UploadFormContext, UploadFormStore } from "../upload/uploadFormStore";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { LoginNotification } from "../components/LoginNotification";

import { IUserOrganization } from "../models/dataModel";
import { TemporaryBannerNotification } from "../components/TemporaryBannerNotification";
import { Settings } from "../config/Settings";

const Actions = observer(() => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const rootStore: RootStore = useContext(RootContext);
  const navigate = useNavigate();

  const [isCreatingCollection, setIsCreatingCollection] = useState(false);

  async function createCollection() {
    setIsCreatingCollection(true);

    try {
      const res = await form.getUploaderStore().createCollection();
      if (!!res) navigate("/collections/online/" + res?.collection.id);
    } catch {
      rootStore
        .getNotificationChannelStore()
        .error(rootStore.getTranslation("onlineCollections.collectionCreation.addCollectionFailed"));
    }
  }

  return (
    <div className="wrapper as-table">
      <section className="actions actions-bottom" data-testid="bottomNavigation">
        <div className="previous-and-next">
          <button
            className="button-large l-right button-primary next"
            type="button"
            disabled={!form.getCollectionStore().isFormValid() || isCreatingCollection}
            onClick={createCollection}
            data-testid="create-collection-button"
          >
            <TranslatedHtml entry="actions.create" />
          </button>
        </div>
      </section>
    </div>
  );
});

/**
 * Component for creating new online collections.
 */
export const NewOnlineCollection = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const belongsToOrgsWhereIsNotEditor = rootStore.getUserStore().getOrganizations().some((uo: IUserOrganization) => !rootStore.getUserStore().isContentEditorForOrganization(uo.organization));

  async function fetchData() {
    await form.fetchData();
  }

  useEffect(() => {
    form.getCollectionStore().setFormStateFromOption({ value: "online", label: "online" });

    fetchData();
  }, []);

  return (
    <div className="upload" data-testid="newOnlineCollection">
      <TemporaryBannerNotification text={<TranslatedHtml
        entry="landing.notifications.content_editor_role.create_collections_info"
        args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
        bannerFeatureToggle="contentEditorRoleInfoAfter"
        show={rootStore.getUserStore().isUserLoggedIn() && rootStore.getUserStore().getOrganizations().length > 0 && belongsToOrgsWhereIsNotEditor}
      />
      {!rootStore.getUserStore().isUserLoggedIn() ? (
        <LoginNotification />
      ) : (
        <div id="content">
          <form className="step new-collection">
            <header className="step-main" />
            {!!form.getCollectionStore().getSelectedCollectionOption() && <CreateNewOnlineCollection />}
            <Actions />
          </form>
        </div>
      )}
    </div>
  );
});
