import { action, computed, makeObservable, observable, runInAction, toJS } from "mobx";
import _ from "underscore";

import { NewRepository } from "../../../../js/services/NewRepository";
import { TCCAclService } from "../../../../js/services/TCCAclService";

import { RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore } from "../contentManagerStore";

import {
  IBatchCollection,
  IBatchEntity,
  IBatchVersion,
  ICollection,
  IEntity,
  IFilters,
  IModifier,
  ISearchOptions,
  ITreeViewContent,
  ITreeViewVersion,
  IVersion
} from "../../../../models/dataModel";
import {
  ObjectTypeEnum,
  SearchPageOrderEnum,
  SearchTargetOptionEnum
} from "../../../../models/enums";


import { SelectContentDialogActionEnum } from "./SelectContentDialog";

export interface SearchOptions extends ISearchOptions {
  sortBy: SearchPageOrderEnum;
  searchTarget: SearchTargetOptionEnum;
  filters: IFilters;
  includeBanned?: boolean;
  showHidden?: boolean;
}

export class ContentManagerActionsStore {
  /** 
   * Root store
   * */
  private rootStore: RootStore;

  /** 
   * Root store
   * */
  private contentManagerStore: ContentManagerStore;

  /**
   * Packages
   */
  @observable private packages: ITreeViewContent[] = [];

  /**
  * Collection of flags indicating if a query is in progress.
  */
  @observable private querying: { [key: string]: boolean } = {
    sources: false,
    targets: false,
    targetCollections: false,
  };

  /**
   * Is processing
   */
  @observable private processing: boolean = false;

  @observable private action?: SelectContentDialogActionEnum;

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore, contentManagerStore: ContentManagerStore) {
    makeObservable(this);

    this.contentManagerStore = contentManagerStore;
    this.rootStore = rootStore;
  }

  /**
   * Resolves if data is being processed
   * @returns true if is processing
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Resolves if querying sources for the move dialog
   * @returns true if querying sources
   */
  public isQueryingSources(): boolean {
    return this.querying.sources;
  }

  /**
   * Resolves if querying target collections for the move dialog
   * @returns true if querying target collections
   */
  public isQueryingTargetCollections(): boolean {
    return this.querying.targetCollections;
  }

  /**
   * Resolves if querying targets for the move dialog
   * @returns true if querying targets
   */
  public isLoadingTargets(): boolean {
    return this.querying.targets;
  }

  /**
   * Returns the selected select content dialog action
   */
  public getSelectedAction(): SelectContentDialogActionEnum | undefined {
    return this.action;
  }

  /**
   * Sets the selected select content dialog action
   */
  @action
  public setSelectedAction(action: SelectContentDialogActionEnum) {
    this.action = action;
  }

  /**
   * Returns a list of selected packages
   */
  @computed public get selectedPackages(): ITreeViewContent[] {
    return this.packages.filter((contentItem: ITreeViewContent) => contentItem.selected);
  }

  /**
   * Returns all available packages
   */
  public getPackages(): ITreeViewContent[] {
    return toJS(this.packages);
  }

  /**
    * Resets the select content dialog selections.
    */
  @action
  public resetSelectContentDialog(): void {
    this.packages = [];
  }

  /**
   * Sets the 'selected' flag for the given package and its versions.
   * @param packageItem - package
   * @param selected - true if selected, false if unselected
   */
  @action
  public selectContent(packageItem: ITreeViewContent, selected: boolean) {
    this.packages = _.map(this.packages, (item: ITreeViewContent) => {
      if (item.id === packageItem.id) {
        item.selected = selected;

        item.versions = _.map(item.versions as ITreeViewVersion[], (version: ITreeViewVersion) => ({
          ...version,
          selected: selected,
        }));
      }

      return item;
    });
  }

  /**
   * Sets the 'selected' flag for the given version.
   * @param packageItem - package to which the version belongs to
   * @param version - version
   * @param selected - true if selected, false if unselected
   */
  @action
  public selectVersion(packageItem: ITreeViewContent, version: ITreeViewVersion, selected: boolean) {
    this.packages = _.map(this.packages, (item: ITreeViewContent) => {
      if (item.id === packageItem.id) {
        item.versions = _.map(item.versions as ITreeViewVersion[], (versionItem: ITreeViewVersion) => ({
          ...versionItem,
          selected: versionItem.id === version.id ? selected : versionItem.selected,
        }));
        item.selected = _.all(item.versions as ITreeViewVersion[], (versionItem: ITreeViewVersion) => !!versionItem.selected);
      }

      return item;
    });
  }

  /**
  * Initializes the tree content for ban/unban content dialog.
  * @param sources 
  * @param action
  */
  @action
  public buildContentTree(sources: IBatchEntity[], action: SelectContentDialogActionEnum) {
    if (sources.length > 0) {
      this.packages = _.map(sources, (packageItem: IBatchEntity) => ({
        ...packageItem,
        selected: action === SelectContentDialogActionEnum.BAN || (action === SelectContentDialogActionEnum.UNBAN && !!packageItem.isBanned),
        versions: _.map(packageItem.versions as IBatchVersion[], (version: IBatchVersion) => ({
          ...version,
          selected: (action === SelectContentDialogActionEnum.BAN && !version.isBanned) || (action === SelectContentDialogActionEnum.UNBAN && !!version.isBanned),
        })),
      })) as ITreeViewContent[];
    }
  }


  /**
   * Bans selected packages
   */
  @action
  public async banSelected() {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!user) return;

    this.processing = true;

    try {
      if (this.contentManagerStore.getSearchTarget() === SearchTargetOptionEnum.COLLECTION) {
        const resultsToBan = _.filter(this.contentManagerStore.getSelectedResults(), (item: IBatchCollection | IBatchEntity) => !item.isBanned);

        if (_.isEmpty(resultsToBan)) return;

        const promises: Array<Promise<any>> = [];

        _.each(resultsToBan, async (item: IBatchCollection | IBatchEntity) => {
          promises.push(NewRepository.updateCollection({ ...item, isBanned: true, attributes: { ...item.attributes, bannedDate: new Date().toISOString() } }, user));
          promises.push(this.updatePackagesAndVersionsForCollection(item as IBatchCollection, { isBanned: true }));
        });

        await Promise.all(promises);

      } else if (this.contentManagerStore.getSearchTarget() === SearchTargetOptionEnum.PACKAGE) {
        _.each(this.packages, async (item: IBatchEntity) => {
          await this.updatePackageAndVersionsIfSelected(item as IBatchEntity, { isBanned: true });
        });
      }

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.packages_banned"));
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        setTimeout(async () => await this.contentManagerStore.search(), 4500);

        this.processing = false;
      });
    }
  }

  /**
   * Unbans selected
   */
  @action
  public async unbanSelected() {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!user) return;

    this.processing = true;

    try {
      if (this.contentManagerStore.getSearchTarget() === SearchTargetOptionEnum.COLLECTION) {
        const resultsToUnBan = _.filter(this.contentManagerStore.getSelectedResults(),
          (item: IBatchCollection | IBatchEntity) => !!item.isBanned
        );

        if (_.isEmpty(resultsToUnBan)) return;

        const promises: Array<Promise<any>> = [];

        _.each(resultsToUnBan, async (item: IBatchCollection | IBatchEntity) => {
          promises.push(NewRepository.updateCollection(
            { ...item, isBanned: false, attributes: { ...item.attributes, bannedDate: undefined } },
            user,
          ));
          promises.push(this.updatePackagesAndVersionsForCollection(item as IBatchCollection, { isBanned: false }));
        });

        await Promise.all(promises);

      } else if (this.contentManagerStore.getSearchTarget() === SearchTargetOptionEnum.PACKAGE) {
        _.each(this.packages, async (item: IBatchEntity) => {
          await this.updatePackageAndVersionsIfSelected(item as IBatchEntity, { isBanned: false });
        });
      }

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.packages_unbanned"));
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        setTimeout(async () => await this.contentManagerStore.search(), 4500);

        this.processing = false;
      });
    }
  }

  /**
   * Hides selected packages
   */
  @action
  public async hideSelected() {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!user) return;

    this.processing = true;

    try {
      const resultsToHide = _.filter(
        this.contentManagerStore.getSelectedResults(),
        (item: IBatchCollection | IBatchEntity) => !item.isHidden,
      );

      if (_.isEmpty(resultsToHide)) return;

      const promises: Array<Promise<any>> = [];

      _.each(resultsToHide, async (item: IBatchCollection | IBatchEntity) => {
        if (item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
          promises.push(NewRepository.updateCollection({ ...item, isHidden: true }, user));
          promises.push(this.updatePackagesAndVersionsForCollection(item as IBatchCollection, { isHidden: true }));
        } else if (item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
          promises.push(this.updatePackageAndVersions(item as IBatchEntity, { isHidden: true }));
        }
      });

      await Promise.all(promises);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.packages_hidden"));
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        setTimeout(async () => await this.contentManagerStore.search(), 4500);

        this.processing = false;
      });
    }
  }

  /**
   * Unhides selected
   */
  @action
  public async unhideSelected() {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!user) return;

    this.processing = true;

    try {
      const resultsToUnHide = _.filter(
        this.contentManagerStore.getSelectedResults(),
        (item: IBatchCollection | IBatchEntity) => !!item.isHidden,
      );

      if (_.isEmpty(resultsToUnHide)) return;

      const promises: Array<Promise<any>> = [];

      _.each(resultsToUnHide, async (item: IBatchCollection | IBatchEntity) => {
        if (item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
          promises.push(NewRepository.updateCollection({ ...item, isHidden: false }, user));
          promises.push(this.updatePackagesAndVersionsForCollection(item as IBatchCollection, { isHidden: false }));
        } else if (item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
          promises.push(this.updatePackageAndVersions(item as IBatchEntity, { isHidden: false }));
        }
      });

      await Promise.all(promises);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.packages_unhidden"));
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        setTimeout(async () => await this.contentManagerStore.search(), 4500);

        this.processing = false;
      });
    }
  }

  /**
   * Deletes selected content
   */
  @action
  public async removeSelectedContent() {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!user) return;

    this.processing = true;

    try {
      const promises: Array<Promise<void>> = [];

      _.each(this.contentManagerStore.getSelectedResults(), (item: IBatchEntity | IBatchCollection) => {
        if (item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
          promises.push(NewRepository.deleteCollection(item as ICollection, user.id));
        } else if (item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
          promises.push(NewRepository.deletePackage(item as IEntity, user.id));
        }
      });

      await Promise.all(promises);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.content_removed"));

    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        setTimeout(async () => await this.contentManagerStore.search(), 4500);

        this.processing = false;
      });
    }
  }

  private async updatePackagesAndVersionsForCollection(collection: IBatchCollection, data: { isHidden?: boolean; isBanned?: boolean }) {
    const packages = await NewRepository.getPackagesForCollection(collection.id);

    const promises: Array<Promise<any>> = [];
    _.each(packages, async (packageItem: IBatchEntity) => {
      promises.push(this.updatePackageAndVersions(packageItem, data));
    });

    return Promise.all(promises);
  }

  private async updatePackageAndVersions(packageItem: IBatchEntity, data: { isHidden?: boolean; isBanned?: boolean }) {
    const user = this.rootStore.getUserStore().getCurrentUser();
    const promises: Array<Promise<any>> = [];

    if (!_.isUndefined(data.isBanned)) {
      packageItem = {
        ...packageItem,
        isBanned: data.isBanned,
        attributes: { ...packageItem.attributes, bannedDate: !!data.isBanned ? new Date().toISOString() : undefined },
      };
      promises.push(NewRepository.updatePackage(packageItem, user, true));

      const versions = await NewRepository.getVersions({ id: packageItem.id }, true, true);

      _.each(versions, (version: IVersion) => {
        version = {
          ...version,
          isBanned: data.isBanned,
          attributes: { ...version.attributes, bannedDate: !!data.isBanned ? new Date().toISOString() : undefined },
        };
        promises.push(NewRepository.updateVersion(version, user as IModifier));
      });
    } else {
      packageItem = { ...packageItem, ...data };
      promises.push(NewRepository.updatePackage(packageItem, user, true));

      const versions = await NewRepository.getVersions({ id: packageItem.id }, true, true);

      _.each(versions, (version: IVersion) => {
        version = { ...version, ...data };
        promises.push(NewRepository.updateVersion(version, user as IModifier));
      });
    }

    return Promise.all(promises);
  }

  private async updatePackageAndVersionsIfSelected(packageItem: IBatchEntity, data: { isHidden?: boolean; isBanned?: boolean }) {
    const user = this.rootStore.getUserStore().getCurrentUser();
    const promises: Array<Promise<any>> = [];

    if (!_.isUndefined(data.isBanned)) {
      // if package is selected, update it and all versions
      if (packageItem.selected) {
        packageItem = {
          ...packageItem,
          isBanned: data.isBanned,
          attributes: { ...packageItem.attributes, bannedDate: !!data.isBanned ? new Date().toISOString() : undefined },
        };
        promises.push(NewRepository.updatePackage(packageItem, user, true));

        const versions = await NewRepository.getVersions({ id: packageItem.id }, true, true);

        _.each(versions, (version: IVersion) => {
          version = {
            ...version,
            isBanned: data.isBanned,
            attributes: { ...version.attributes, bannedDate: !!data.isBanned ? new Date().toISOString() : undefined },
          };
          promises.push(NewRepository.updateVersion(version, user as IModifier));
        });

        if (!data.isBanned && !!packageItem.collection) {
          promises.push(TCCAclService.cloneAcl(packageItem.collection.id, "collection", packageItem.id, "package", user!.id));
        }

      } else {
        const selectedVersions = _.filter(packageItem.versions as ITreeViewVersion[], (version: ITreeViewVersion) => version.selected);

        _.each(selectedVersions, (version: ITreeViewVersion) => {
          version = {
            ...version,
            isBanned: data.isBanned,
            attributes: { ...version.attributes, bannedDate: !!data.isBanned ? new Date().toISOString() : undefined },
          };

          promises.push(NewRepository.updateVersion(version, user as IModifier));

          if (!data.isBanned) {
            promises.push(TCCAclService.cloneAcl(packageItem.id, "package", version.id, "entity", user!.id));
          }
        });
      }
    }

    return Promise.all(promises);
  }
}