import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import { useSearchParams } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { AnalyticsStore, AnalyticsStoreContext } from "../components/analytics/analyticsStore";
import { PackagePageStore, PackagePageStoreContext } from "./packagePageStore";
import { RelatedContentStore, RelatedContentStoreContext } from "../package/tabs/relatedContentStore";
import { UserCommentsStore, UserCommentsStoreContext } from "../package/tabs/userCommentsStore";
import { GroupAndCategoryStore, GroupAndCategoryStoreContext } from "../package/tabs/groupAndCategoryStore";
import { SearchCriteriaStore, SearchCriteriaStoreContext } from "../package/tabs/searchCriteriaStore";
import { ReleaseNotesStore, ReleaseNotesStoreContext } from "../package/tabs/releaseNotesStore";
import { SupportStore, SupportStoreContext } from "../package/tabs/supportStore";
import { TabNavigationStore, TabNavigationStoreContext } from "../components/tab-navigation/tabNavigationStore";

import { TabNavigation } from "../components/tab-navigation/TabNavigation";
import { Analytics } from "../components/analytics/Analytics";
import { PackageDetails } from "./tabs/PackageDetails";
import { Versions } from "./tabs/Versions";
import { RelatedContent } from "./tabs/RelatedContent";
import { Support } from "./tabs/Support";
import { ReleaseNotes } from "./tabs/ReleaseNotes";
import { Statistics } from "./statistics/Statistics";
import { UserComments } from "./tabs/UserComments";
import { GroupAndCategory } from "./tabs/GroupAndCategory";
import { SearchCriteria } from "./tabs/SearchCriteria";

import { IEntity, IResource, ITabListItem } from "../models/dataModel";

/**
 * A component that displays package page tabs.
 */
export const Tabs = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const tabNavigationStore: TabNavigationStore = useContext(TabNavigationStoreContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem: IEntity | undefined = packagePageStore.getPackage();
  const analyticsStore = new AnalyticsStore(
    rootStore,
    packageItem as IResource,
    packagePageStore.hasPartnerDownloadURL(),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  function isActiveTab(tabName: string): boolean {
    return tabNavigationStore.getActiveTabName() === tabName;
  }

  function showVersionTabAsDefault(): boolean {
    return !!searchParams.get("showVersions") && packagePageStore.showVersions();
  }

  function showAnalytics(): boolean {
    return (
      !packagePageStore.isLocalPackage() &&
      !!packageItem &&
      rootStore.getUserStore().canSeeAnalytics(packageItem as IResource)
    );
  }

  function showComments() {
    return !packagePageStore.isLocalPackage() && !!packageItem && !!packageItem!.allowComments;
  }

  function getTabList(): ITabListItem[] {
    return [
      {
        name: "details",
        translationKey: "details.navigation.details",
        isDefault: !showVersionTabAsDefault(),
        canAccess: true,
      },
      {
        name: "versions",
        translationKey: "details.navigation.versions",
        additionalString: " (" + packagePageStore.getVersions().length + ")",
        isDefault: showVersionTabAsDefault(),
        canAccess: packagePageStore.showVersions(),
      },
      {
        name: "related",
        translationKey: "details.navigation.related_content",
        canAccess: true,
      },
      {
        name: "analytics",
        translationKey: "details.navigation.analytics",
        canAccess: showAnalytics(),
      },
      {
        name: "properties",
        translationKey: "details.navigation.properties",
        canAccess: true,
      },
      {
        name: "support",
        translationKey: "details.navigation.support",
        canAccess: true,
      },
      {
        name: "releasenotes",
        translationKey: "details.navigation.release_notes",
        canAccess: !packagePageStore.isLocalPackage(),
      },
      {
        name: "statistics",
        translationKey: "details.navigation.statistics",
        canAccess: !packagePageStore.isLocalPackage(),
      },
      {
        name: "comments",
        translationKey: "details.navigation.comments",
        canAccess: showComments(),
      },
    ];
  }

  return (
    <div className="details-tabs-container">
      <TabNavigation tabList={getTabList()} listStyle="details-tabs" />
      <AnalyticsStoreContext.Provider value={analyticsStore}>
        <div className="tab-view wide">
          {isActiveTab("details") && <PackageDetails />}
          {isActiveTab("versions") && <Versions />}
          {isActiveTab("related") && (
            <RelatedContentStoreContext.Provider value={new RelatedContentStore(rootStore, packageItem)}>
              <RelatedContent />
            </RelatedContentStoreContext.Provider>
          )}
          {isActiveTab("analytics") && <Analytics />}
          {isActiveTab("properties") && (
            <div className="properties">
              <GroupAndCategoryStoreContext.Provider value={new GroupAndCategoryStore(rootStore, packageItem)}>
                <GroupAndCategory />
              </GroupAndCategoryStoreContext.Provider>
              <SearchCriteriaStoreContext.Provider value={new SearchCriteriaStore(rootStore, packageItem)}>
                <SearchCriteria />
              </SearchCriteriaStoreContext.Provider>
            </div>
          )}
          {isActiveTab("support") && (
            <SupportStoreContext.Provider value={new SupportStore(rootStore, packagePageStore)}>
              <Support />
            </SupportStoreContext.Provider>
          )}
          {isActiveTab("releasenotes") && (
            <ReleaseNotesStoreContext.Provider value={new ReleaseNotesStore(rootStore, packageItem)}>
              <ReleaseNotes />
            </ReleaseNotesStoreContext.Provider>
          )}
          {isActiveTab("statistics") && <Statistics />}
          {isActiveTab("comments") && (
            <UserCommentsStoreContext.Provider value={new UserCommentsStore(rootStore, packageItem)}>
              <UserComments />
            </UserCommentsStoreContext.Provider>
          )}
        </div>
      </AnalyticsStoreContext.Provider>
    </div>
  );
});
