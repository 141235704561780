import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { ThumbnailStoreContext } from "./thumbnailStore";
import { ImageNaviStoreContext } from "../image-navi/imageNaviStore";
import { EditorModeStoreContext } from "../editor-mode/editorModeStore";
import { EditImagesWrapper } from "../EditImagesWrapper";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { Dialog } from "../../dialogs/Dialog";
import { ThreeDPicViewerDialog } from "../../dialogs/ThreeDPicViewerDialog";

/**
 * A component that displays collection page thumbnails
 */
export const Thumbnail = observer(() => {
  const rootStore = useContext(RootContext);
  const thumbnailStore = useContext(ThumbnailStoreContext);
  const content = thumbnailStore.getContent();
  const binaries = thumbnailStore.getBinaries();
  const imageNaviStore = useContext(ImageNaviStoreContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const dialog = new DialogStore();

  function getStyle(url) {
    return { backgroundImage: `url(${url})` };
  }

  function show3DPic() {
    dialog.open();
  }

  return (
    <Fragment>
      {content && (
        <div className="column">
          {binaries[imageNaviStore.getViewIndex()].isThumbnail && (
            <div data-testid="thumbnail" className="thumb-box">
              <figure
                thumb-box=""
                thumbnail-url={binaries[imageNaviStore.getViewIndex()].contentUrl}
                style={getStyle(binaries[imageNaviStore.getViewIndex()].contentUrl)}
              />
            </div>
          )}
          {binaries[imageNaviStore.getViewIndex()].is3DThumbnail && (
            <DialogContext.Provider value={dialog}>
              <div data-testid="thumbnail-3d" className="thumb-box" onClick={() => show3DPic()}>
                <div className="view-3d-info">{rootStore.getTranslation("actions.view_3d")}</div>
                <a className="thumb-box">
                  <figure thumb-box="" thumbnail-url="/images/3d-thumb.png" style={getStyle("/images/3d-thumb.png")} />
                </a>
              </div>
              <Dialog content={<ThreeDPicViewerDialog />} additionalClass="threed-pic-viewer" />
            </DialogContext.Provider>
          )}
          {binaries[imageNaviStore.getViewIndex()].isVideo && (
            <div data-testid="thumbnail-video" className="thumb-box">
              <iframe width="320" height="240" src={binaries[imageNaviStore.getViewIndex()].value} />
            </div>
          )}
          {thumbnailStore.canEditContent() && editorModeStore.getEditorMode() && (
            <EditImagesWrapper data-testid="edit-images" item={content} />
          )}
        </div>
      )}
    </Fragment>
  );
});
