import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { UploadFormContext } from "../uploadFormStore";
import { SetCollectionTitle } from "./SetCollectionTitle";
import { useState } from "react";

const LocationValidation = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);

  return (
    <Fragment>
      {!form.getCollectionStore().isLocationValid() && (
        <div className="error" data-testid="errorMessage">
          <small className="error" data-testid="invalid-location">
            {rootStore.getTranslation("upload.collection.location_validation.invalid")}
          </small>
        </div>
      )}
    </Fragment>
  );
});

/**
 * Component for rendering the CreatNewLocalCollection form.
 */
export const CreateNewLocalCollection = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();
  const [fieldNotInFocus, setFieldNotInFocus] = useState(false);

  function handleLocationInput(event) {
    event.preventDefault();
    collection.setLocation(event.target.value || "");
  }

  function handleBlur() {
    setFieldNotInFocus(true);
  }

  const displayValidation = fieldNotInFocus && !form.getCollectionStore().isLocationValid();

  return (
    <div data-testid="localCollectionForm">
      <header>
        <h2>{rootStore.getTranslation("localCollections.collectionCreation.createNewCollection")}</h2>
        <p>{rootStore.getTranslation("localCollections.selectFolderInstructions")}</p>
      </header>
      <div className="wrapper">
        <SetCollectionTitle />
        <label className="required" htmlFor="location">
          {rootStore.getTranslation("localCollections.selectedFolder")}
        </label>
        <input
          type="text"
          name="location"
          onChange={handleLocationInput}
          onBlur={handleBlur}
          required
          placeholder={rootStore.getTranslation("localCollections.selectedFolderPlaceholder")}
          value={collection.getLocation()}
          data-testid="folderInput"
        />
        {displayValidation && <LocationValidation />}
      </div>
      <hr />
    </div>
  );
});
