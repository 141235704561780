/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useContext } from "react";
import { DateTime, Interval } from "luxon";

import { RootContext } from "../stores/rootStore";
import { TranslatedHtml } from "../components/TranslatedHtml";

/**
 * A component that displays information about Tekla Development Experts directory.
 */
export const TeklaDevelopmentExperts = ({ campaignStart, campaignEnd }: { campaignStart: Date; campaignEnd: Date }) => {
  const store = useContext(RootContext);

  function useCampaignLayout() {
    return Interval.fromDateTimes(DateTime.fromJSDate(campaignStart), DateTime.fromJSDate(campaignEnd)).contains(
      DateTime.now(),
    );
  }

  function getCampaignMedia() {
    return (
      <a
        href="https://www.trimble.com/en/our-company/events/dimensions/experiences/0-60-challenge"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../images/tekla-structures-startups-1120x630.gif" />
      </a>
    );
  }

  function getOpenAPIMedia() {
    return (
      <a
        href="https://learn.trimble.com/learn/learning_plan/view/453/tekla-open-api-fundamentals"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="../images/2022-TeklaOpenAPI-promotion-banner.jpg" />
      </a>
    );
  }

  function getDevelopmentMedia() {
    return (
      <a href="https://developer.tekla.com/tekla-development-experts" target="_blank" rel="noopener noreferrer">
        <img src="../images/dev_center_find_developer_2024.jpg" alt="Tekla Development Experts" />
      </a>
    );
  }

  function renderMedia() {
    if (useCampaignLayout()) {
      return getCampaignMedia();
    } else {
      return getDevelopmentMedia();
    }
  }

  function getCampaignTitle() {
    return store.getTranslation("landing.trimble_0_60.title");
  }

  function getOpenAPITitle() {
    return store.getTranslation("landing.open_api_fundamentals.title");
  }

  function getDevelopmentTitle() {
    return store.getTranslation("landing.seeking_experts_for_your_project.title");
  }

  function getTitle() {
    if (useCampaignLayout()) {
      return getCampaignTitle();
    } else {
      return getDevelopmentTitle();
    }
  }

  function getCampaignDescription() {
    return "landing.trimble_0_60.description";
  }

  function getOpenAPIDescription() {
    return "landing.open_api_fundamentals.description";
  }

  function getDevelomentDescription() {
    return "landing.seeking_experts_for_your_project.description";
  }

  function getDesciption() {
    if (useCampaignLayout()) {
      return getCampaignDescription();
    } else {
      return getDevelomentDescription();
    }
  }

  return (
    <section className="dev-site-info" data-testid="campaignArea">
      <div className="content-area">
        <div className="dev-site-media">{renderMedia()}</div>
        <div className="dev-site-text" data-testid="dev-info">
          <div className="title" data-testid="dev-title">
            {getTitle()}
          </div>
          <div className="description" data-testid="dev-description">
            <TranslatedHtml entry={getDesciption()} />
          </div>
        </div>
      </div>
    </section>
  );
};
