 
export const PlAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["pl-PL"]) {
      window.locale["pl-PL"] = {};
    }

    window.locale["pl-PL"].about = {
      navigation: {
        why: "Dlaczego Tekla Warehouse?",
        usage_basics: "Podstawy Tekla Warehouse",
        "usage_basics__using-content": "Korzystanie z zawartości",
        "usage_basics__adding-content": "Dodawanie zawartości",
        usage_basics__collection: "Kolekcja",
        "usage_basics__content-item": "Pozycja zawartości",
        usage_basics__version: "Wersja",
        getting_started: "Pierwsze kroki",
        "getting_started__tekla-account-and-system-requirements": "Tekla account i wymagania systemowe",
        "getting_started__tekla-warehouse-service-plugin": "Dodatek Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Jak uzyskać Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures i Tekla Warehouse",
        content: "Zawartość w Tekla Warehouse",
        "content__3d-products": "Modele produktów 3D",
        "content__custom-components": "Komponenty użytkownika",
        content__applications: "Aplikacje",
        "content__profiles-shapes-etc": "Profile, materiały, śruby, zbrojenia, itp.",
        "content__model-templates": "Szablony modeli",
        "content__drawing-setup": "Ustawienia rysunków",
        "content__report-templates-etc": "Szablony raportów i plików atrybutów",
        using_content: "Używanie zawartości",
        creating_content: "Tworzenie zawartości",
        "creating_content__getting-started-with-content-creation": "Tworzenie zawartości - wstęp",
        "creating_content__expert-help-for-creating-content": "Potrzebujesz pomocy eksperta w tworzeniu zawartości?",
        application_developers: "Tworzenie aplikacji do Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Dlaczego Tekla Warehouse?",
        description_1:
          "Tekla Warehouse zawiera aplikacje, komponenty użytkownika, części, profile, materiały (jak stal lub klasy betonu), śruby, zbrojenia, kraty, kształty i szablony dla Tekla Structures. Rzeczywiste elementy dostawców w modelach zapewniają najwyższą precyzję i dokładne informacje o materiałach w celu ich zamówienia i projektowania, a używanie gotowej zawartości przyspiesza modelowanie.",
        description_2:
          "Tekla Warehouse gwarantuje centralny dostęp do tej zawartości, z której można skorzystać w prosty sposób. Zawartość w Tekla Warehouse cały czas jest poszerzana.",
        description_3: "Za pomocą Tekla Warehouse",
        feature_1:
          "Producenci mogą udostępniać swoje produkty i aplikacje użytkownikom, którzy mogą przenosić dokładne modele produktów 3D do swoich modeli Tekla, a następnie używać fizycznego produktu przy budowie.",
        feature_2:
          "Możesz korzystać ze swojej sieci firmowej w celu udostępniania zawartości kolegom z pracy i zapisywania jej lokalnie do użytku prywatnego",
        description_4:
          "Wyszukiwarka Tekla Warehouse pozwala znaleźć dokładnie to, czego potrzebujesz, niezależnie od typu zawartości.",
        description_5: "Dodatek Tekla Warehouse Service jest wymagany, aby włączyć kolekcje lokalne i sieciowe.",
        view_licences_html:
          'Wyświetl <a href="/api/third_party_licenses.txt" target="_blank">licencje firm zewnętrznych</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Podstawy Tekla Warehouse",
        using_content: {
          title: "Korzystanie z zawartości",
          description_1:
            "Można korzystać z zawartości w kolekcjach online, udostępniać zawartość kolegom z pracy za pomocą kolekcji sieciowych oraz korzystać z kolekcji lokalnych w celach prywatnych.",
          description_2:
            "Lokalne i sieciowe kolekcje Tekla Warehouse Service, zainstalowane z Tekla Structures 20.1 Service Release 1 nowszymi wersjami Tekla Structures.",
          description_3:
            "Specyficzne kolekcje Tekla zawierające zlokalizowaną zawartość środowiska są instalowane razem z Tekla Structures.",
          description_4: "Aby uzyskać więcej informacji, przeczytaj rozdział Korzystanie z zawartości.",
        },
        adding_content: {
          title: "Dodawanie zawartości",
          description_1:
            'Kolekcje online można wykorzystać w celu udostępnienia zawartości wszystkim użytkownikom Tekla Warehouse. Aby opublikować zawartość jako organizacja, należy dodać organizację na stronie account.tekla.com. Skontaktuj się z lokalnym <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">przedstawicielem lub sprzedawcą Tekla</a>, aby uzyskać więcej informacji.',
          description_2: "Aby dowiedzieć się więcej, patrz rozdział Tworzenie zawartości.",
        },
        collection: {
          title: "Kolekcja",
          feature_1:
            "Kolekcje grupują pozycje zawartości razem, np. foldery, i określają uprawnienia dostępu do zawartości (publiczne/prywatne).",
          feature_1_1:
            "Projektanci mogą korzystać z zawartości w kolekcjach online, udostępniać zawartość kolegom z pracy za pomocą kolekcji sieciowych oraz prywatnie wykorzystywać kolekcje lokalne.",
          feature_2:
            "Dostawcy zawartości mogą wykorzystać kolekcje online w celu udostępnienia swojej zawartości wszystkim użytkownikom Tekla Warehouse.",
          feature_3:
            "Lokalne i sieciowe kolekcje wymagają dodatku Tekla Warehouse Service, który jest instalowany razem z Tekla Structures 20.1 Service Release 1 i nowszymi wersjami, a także może być instalowany oddzielnie.",
        },
        "content-item": {
          title: "Pozycja zawartości",
          feature_1:
            "Pozycja zawartości to logiczna grupa plików. Na przykład pozycjami zawartości są katalog produktów 3D, szablon modelu, narzędzie, grupa komponentów użytkownika lub katalog profili.",
          feature_2:
            "Pozycje zawartości można opatrzyć etykietą wg ich typu i zastosowania, co umożliwi użytkownikom łatwiejsze znalezienie Twojej zawartości.",
        },
        version: {
          title: "Wersja",
          feature_1:
            "Jednocześnie pozycja zawartości może mieć wiele wersji, które są używane w różnych wersjach programu Tekla Structures. Użytkownik może wybrać odpowiednią wersję z odpowiedniej strony.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Pierwsze kroki",
        tekla_account_and_system_requirements: {
          title: "Tekla account i wymagania systemowe",
          register_atc_html:
            'Musisz mieć konto Tekla account, aby się zalogować do Tekla Warehouse. Zarejestruj się pod adresem <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>, jeśli nie masz jeszcze za sobą tego kroku. (Zwróć uwagę, że to nie jest stare konto Extranet).',
          see_tua_for_prerequisites_html:
            'Zobacz Tekla User Assistance, aby uzyskać informacje na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">wymagań wstępnych korzystania z Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Dodatek Tekla Warehouse Service",
          description_1: "Oprócz tego, że jest to magazyn online dla zawartości, Tekla Warehouse umożliwia:",
          feature_1: "korzystanie z kolekcji lokalnych do celów osobistych,",
          feature_2:
            "korzystanie z kolekcji sieciowych, dzięki którym można udostępniać zawartości kolegom z pracy za pomocą sieci firmowej,",
          feature_3: "łatwą instalację podczas używania lub tworzenia zawartości,",
          feature_4: "przeszukiwanie zawartości lokalnej i sieciowej.",
          description_2:
            "Jest to możliwe dzięki dodatkowi Tekla Warehouse Service, który działa jako lokalne zaplecze, komunikując się z Tekla Structures i witryną Tekla Warehouse.",
          description_3:
            "Jeśli dodatek Tekla Warehouse Service nie jest zainstalowany lub uruchomiony, Tekla Warehouse sygnalizuje to u góry strony, aby umożliwić rozwiązanie problemu.",
          description_4:
            "Jeżeli problemy będą nadal występować, należy zrestartować program za pomocą programu Services (Usługi) w systemie Windows. Nazwa usługi to Tekla Warehouse. Należy również sprawdzić ustawienia zabezpieczeń w wyszukiwarce.",
          description_5:
            "Witryny internetowej Tekla Warehouse można używać bez Tekla Warehouse Service czy Tekla Structures. W takim wypadku używanie strony będzie przypominać korzystanie z typowej strony internetowej udostępniającej zawartość, do której pliki można ręcznie wysyłać i je stamtąd pobierać.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Jak uzyskać Tekla Warehouse Service?",
          description_1:
            "Dodatek Tekla Warehouse Service jest instalowany z Tekla Structures 20.1 Service Release 1 i nowszymi wersjami. Można również oddzielnie zainstalować dodatek Tekla Warehouse Service, aby włączyć kolekcje lokalne i sieciowe, ale prosta instalacja i funkcje wysyłania są dostępne jedynie w Tekla Structures 20.1 Service Release 1 i w nowszych wersjach.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">POBIERZ TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures i Tekla Warehouse",
          description_1:
            "Tekla Warehouse łączy się bezpośrednio z Tekla Structures, co pozwala na swobodniejsze użytkowanie lub wysyłanie zawartości. Zawartość można zarówno wybrać bezpośrednio z modelu, jak i wstawić ją bezpośrednio do modelu, eliminując trud związany z eksportowaniem/importowaniem plików (do tego wymagana jest wersja Tekla Structures 20.1 Service Release 1 lub nowsza wersja). Wszystkie potrzebne pliki można zawrzeć w tej samej pozycji zawartości, np. komponencie użytkownika lub profilu, aby sprawdzić, czy wszystko działa jak należy.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Zawartość w Tekla Warehouse",
        description_1:
          "Tekla Warehouse oferuje kilka różnych typów zawartości. W niniejszym dokumencie można znaleźć ogólny zarys różnych typów zawartości i więcej informacji przy rozpoczęciu pracy z usługą.",
        description_2:
          'Z Tekla Warehouse możesz też pobrać <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla rozszerzenia</a>.',
        "3d_products": {
          title: "Modele produktów 3D",
          image_subtitle: "Są to obiekty ze stałą geometrią, np. elementy osadzone, szalunki i akcesoria.",
          tua_link_html:
            'Zobacz Tekla User Assistance, aby uzyskać więcej informacji na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">tworzenia katalogu produktów 3D w kolekcji Tekla Warehouse</a>.',
        },
        custom_components: {
          title: "Komponenty użytkownika",
          image_subtitle:
            "Komponenty użytkownika są natywnymi obiektami Tekla Structures ze stałą lub parametryczną geometrią.",
          tua_link_html:
            'Zobacz Tekla User Assistance, aby uzyskać więcej informacji na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">wysyłania zawartości z modelu Tekla Structures do kolekcji Tekla Warehouse</a>.',
        },
        applications: {
          title: "Aplikacje",
          image_subtitle:
            "Aplikacje korzystają z Tekla Open API do prowadzenia interakcji z Tekla Structures. Na przykład aplikacje integracji danych z rozwiązaniami projektowymi.",
          tua_link_html:
            'Zobacz Tekla User Assistance, aby uzyskać więcej informacji na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">wysyłania aplikacji do kolekcji Tekla Warehouse</a>.',
        },
        profiles_shapes_etc: {
          title: "Profile, kształty, materiały, zespoły śrub, zbrojenia oraz siatki",
          image_subtitle:
            "Katalogi zawierają listę atrybutów związanych z elementami. Na przykład różne klasy materiałów i ich gęstości.",
          tua_link_html:
            'Zobacz Tekla User Assistance, aby uzyskać więcej informacji na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">wysyłania zawartości z modelu Tekla Structures do kolekcji Tekla Warehouse</a>.',
        },
        model_templates: {
          title: "Szablony modeli",
          image_subtitle:
            "Szablon modelu można użyć dla podobnych, powtarzających się projektów. Szablon może zawierać wszystkie niezbędne katalogi, szablony raportów, ustawienia rysowania i ustawienia opcji zaawansowanych.",
          tua_link_html:
            'Zobacz Tekla User Assistance, aby uzyskać więcej informacji na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">ręcznego dodawania plików do kolekcji Tekla Warehouse</a>.',
        },
        drawing_setup: {
          title: "Ustawienia rysunków",
          image_subtitle:
            "Pliki ustawień określają, jak rysunki są tworzone, np. układy, filtry i szablony klonowania.",
          tua_link_html:
            'Zobacz Tekla User Assistance, aby uzyskać więcej informacji na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">ręcznego dodawania plików do kolekcji Tekla Warehouse</a>.',
        },
        report_templates_etc: {
          title: "Szablony raportów i plików atrybutów modeli",
          description_1:
            "Szablony raportów określają zawartość i układ danych modelu w raporcie. Pliki atrybutów modeli (np. filtry) działają, gdy zostaną dodane do folderu modelu.",
          tua_link_html:
            'Zobacz Tekla User Assistance, aby uzyskać więcej informacji na temat <a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">ręcznego dodawania plików do kolekcji Tekla Warehouse</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Korzystanie z zawartości",
        description_1:
          "W zależności od typu zawartości można dodać wybraną zawartość do modelu programu Tekla Structures lub ją pobrać i zainstalować. Zobacz Tekla User Assistance, aby uzyskać instrukcje dotyczące",
        feature_1:
          '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">szukania zawartości w Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">używania zawartości z Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">wstawiania zawartości Tekla Warehouse do modelu Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">zarządzania konfliktami w Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Tworzenie zawartości",
        getting_started_with_content_creation: {
          title: "Tworzenie zawartości - pierwsze kroki",
          description_1: "Patrz Tekla User Assistance, aby uzyskać więcej informacji na temat:",
          feature_1:
            '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">stawiania pierwszych kroków w Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">dodawania zawartości do Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">modyfikowania zawartości w Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">pobierania zawartości z Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Potrzebujesz pomocy eksperta w tworzeniu zawartości?",
          description_1:
            'Jeśli interesuje Cię publikowanie zawartości w Tekla Warehouse, ale chcesz otrzymać pomoc eksperta w zakresie tworzenia zawartości i jej przechowywania, skontaktuj się z <a href="http://www.tekla.com/contact" target="_blank">lokalnym przedstawicielem lub sprzedawcą Tekla</a> w celu uzyskania dodatkowych informacji na temat możliwości współpracy.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Tworzenie aplikacji do Tekla Structures",
        description_1:
          "Ponieważ Tekla stosuje otwarte podejście do modelowania informacji o budynkach (BIM), można korzystać z rozwiązań oraz urządzeń produkcyjnych innych dostawców i wciąż współpracować z Tekla.",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> jest specjalnym interfejsem programistycznym aplikacji (API) opracowanym przez Tekla, który umożliwia tworzenie aplikacji i dodatkowych funkcji na platformie modelowania Tekla Structures i integrowania ich z własnym środowiskiem. Interfejs Tekla Open API jest implementowany przy użyciu technologii Microsoft .NET.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> to centralne źródło wszystkich informacji na temat Tekla Open API.',
        description_4:
          'Dołącz do <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a>, jeśli interesuje Cię tworzenie aplikacji dla Tekla Structures.',
      },
    };
  },
};
