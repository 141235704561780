import * as React from "react";
import { useContext, useState } from "react";
import { RootContext } from "../../stores/rootStore";
import { ShoppingCartWidget } from "../../components/cart/ShoppingCartWidget";
import { UploadButton } from "../UploadButton";
import { AlertsButton } from "../AlertsButton";
import { HeaderSearch } from "../HeaderSearch";
import { WarehouseLogo } from "./WarehouseLogo";
import { Menu } from "./Menu";
import classNames from "classnames";
import { observer } from "mobx-react";

/**
 * A component that displays left side of main navigation
 */
export const NaviLeft = observer(() => {
  const store = useContext(RootContext);
  const user = store.getUserStore().getCurrentUser();
  const [showMobileMenuItems, showOrHideMobileMenuItems] = useState(false);

  return (
    <div className={classNames("nav-left", { user: user, nouser: !user })}>
      <WarehouseLogo />
      <li className={classNames("mobile-menu", { user: user, nouser: !user })}>
        <button
          data-testid="show-mobile-menu"
          onClick={() => showOrHideMobileMenuItems(!showMobileMenuItems)}
          className="hamburger-menu"
        >
          <span className="icon-hamburger"></span>
        </button>
      </li>
      <div
        className={classNames("gadgets", {
          showMobileMenu: showMobileMenuItems,
          user: user,
          nouser: !user,
        })}
      >
        <AlertsButton />
        <HeaderSearch />
        {user && (
          <li data-testid="shopping-cart-left" className="shopping-cart">
            <ShoppingCartWidget />
          </li>
        )}
        <UploadButton />
      </div>
      <Menu showMobileMenuItems={showMobileMenuItems} />
    </div>
  );
});
