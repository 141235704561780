import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";

export const TCCUserLockoutDS = {
  getUserLockouts: function (params) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/internal/user/lockouts", params));
  },
  createUserLockout: function (data) {
    return ResponseProcessor.process(RequestBuilder.doPost("/warehouse/v1.0/internal/user/lockouts", data));
  },
  updateUserLockout: function (lockoutId, data) {
    return ResponseProcessor.process(RequestBuilder.doPut("/warehouse/v1.0/internal/user/lockouts/" + lockoutId, data));
  },
  deleteUserLockout: function (lockoutId) {
    return ResponseProcessor.process(RequestBuilder.doDelete("/warehouse/v1.0/internal/user/lockouts/" + lockoutId));
  },
};
