import _ from "underscore";
import { ContentTypeEnum, ObjectTypeEnum, SourceEnum } from "../../models/enums";

export function TCCEntityRequestTransferObject(data) {
  data = data || {};
  var convertedData = {
    title: data.title,
    description: data.description || "Version",
    source: SourceEnum.TEKLA_WAREHOUSE,
    type: ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION,
    contentType: ContentTypeEnum.TEKLA_WAREHOUSE,
    tags: data.tags || "",
    baseAccessLevel: data.baseAccessLevel,
    isHidden: data.isHidden,
  };
  if (data.parentCollectionId) {
    convertedData.parentCollectionId = data.parentCollectionId;
  }
  if (data.attributes) {
    convertedData.attributes = data.attributes;
  }
  if (!_.isUndefined(data.doNotNotify)) {
    convertedData.doNotNotify = data.doNotNotify;
  }
  if (!_.isUndefined(data.creatorId)) {
    convertedData.creator = {};
    convertedData.creator.id = data.creatorId;
  }
  if (data._id || data.id) {
    _.extend(convertedData, { id: data._id || data.id });
  } else {
    _.extend(convertedData, {
      uploadAsOrganization: data.uploadAsOrganization || false,
    });
  }
  return convertedData;
}
