import { action, observable, runInAction, makeObservable } from "mobx";
import { createStoreContext, RootStore } from "./rootStore";
import _ from "underscore";
import { AbstractAsyncStore } from "./abstractAsyncStore";
import { TCCSearchService } from "../js/services/TCCSearchService";

export interface IAutocompleteSuggestion {
  id: string;
  label: string;
}

/**
 * A store that can be used for a search component (with name autocompletion)
 */
export class SearchWithAutocompleteStore extends AbstractAsyncStore {
  @observable private suggestions: IAutocompleteSuggestion[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Fetches names for the given queryString and sets suggested resources list,
   * using the name for both id and label (required by Autosuggest component).
   * @param queryString part or complete name of resource name
   */
  @action
  public async fetchData({ queryString }: { queryString: string }) {
    if (queryString.length > 30) {
      console.log("Query string too long for fetching suggestions");
      return;
    }

    this.dataFetched = false;
    this.loading = true;
    this.suggestions = [];

    const s = await TCCSearchService.searchAutocompleteSuggestions(queryString.replace(/\:/g, ""));
    const res = s as Array<{ q: string }>;

    runInAction(() => {
      this.loading = false;
      this.dataFetched = true;
      this.suggestions = _.sortBy(res, "q").map((el) => {
        return { id: el.q, label: el.q };
      }) as IAutocompleteSuggestion[];
    });
  }

  /**
   * Gets entities suggested for a given query string (passed to fetchData method).
   * @returns an array of suggested entities
   */
  public getSuggestions(): IAutocompleteSuggestion[] {
    return this.suggestions;
  }
}

/**
 * Context object for SearchWithAutocompleteStore instances.
 */
export const SearchWithAutocompleteStoreContext =
  createStoreContext<SearchWithAutocompleteStore>(SearchWithAutocompleteStore);
