import * as React from "react";

/**
 * A dual slider component styled according to Modus rules
 * Used for filtering in content manager
 *
 * @param upperLimit upper limit for slider, defaults to 0
 * @param lowerLimit lower limit for slider, defaults to 100
 * @param minValue current value for minimum, defaults to midpoint
 * @param maxValue current value for maximum, defaults to midpoint
 * @param step step size, defaults to 1
 * @param onChangeMin function for handling minimum value change
 * @param onChangeMax function for handling maximum value change
 * @param onMouseUpMin function for handling on mouse up event for minimum slider
 * @param onMouseUpMax function for handling on mouse up event for maximum slider
 * @param inputIdMin id for minimum slider
 * @param inputIdMax id for maximum slider
 * @param testIdMin test id for minimum slider
 * @param testIdMax test id for maximum slider
 * @param testId test id for dual slider
 * @returns ModusDualSlider component
 */
export const ModusDualSlider = ({
  ...props
}: {
  upperLimit?: number;
  lowerLimit?: number;
  minValue?: number;
  maxValue?: number;
  step?: number;
  onChangeMin?: (event: any) => void;
  onChangeMax?: (event: any) => void;
  onMouseUpMin?: (event: any) => void;
  onMouseUpMax?: (event: any) => void;
  disabled?: boolean;
  inputIdMin?: string;
  inputIdMax?: string;
  testIdMin?: string;
  testIdMax?: string;
  testId?: string;
}) => {
  return (
    <div className="multi-range" data-testid={props.testId || "dual-slider"}>
      <input
        type="range"
        id={props.inputIdMin || "min-slider"}
        data-testid={props.testIdMin}
        min={props.lowerLimit}
        max={props.upperLimit}
        step={props.step}
        value={props.minValue}
        onChange={props.onChangeMin}
        disabled={props.disabled}
        onMouseUp={props.onMouseUpMin}
      />
      <input
        type="range"
        id={props.inputIdMax || "max-slider"}
        data-testid={props.testIdMax}
        min={props.lowerLimit}
        max={props.upperLimit}
        step={props.step}
        value={props.maxValue}
        onChange={props.onChangeMax}
        disabled={props.disabled}
        onMouseUp={props.onMouseUpMax}
      />
    </div>
  );
};
