import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router";
import _ from "underscore";

import { Auth } from "../js/services/Auth";
import { RootStore, RootContext } from "../stores/rootStore";
import { AuthModeEnum } from "../models/enums";

/**
 * A component for auth requests
 * @param mode Authorization action mode
 */
export const AuthHandler = ({ mode }: { mode: AuthModeEnum }) => {
  const rootStore: RootStore = useContext(RootContext);
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  function isValidRedirectUrl(url: string): boolean {
    try {
      const redirectURL = new URL(url);
      return redirectURL.hostname.includes("warehouse.tekla.com");
    } catch {
      return false;
    }
  }

  useEffect(() => {
    if (mode === AuthModeEnum.VALIDATE) {
      const redirectToUrl = searchParams.get("redirectToUrl");
      if (_.isUndefined(redirectToUrl) || _.isNull(redirectToUrl) || redirectToUrl === "") {
        Auth.validateSession(
          null,
          rootStore.getUserStore().fetchData,
          rootStore.getUserStore().setAccountValidationError,
          navigate,
        );
      } else {
        let url = "/landing";
        if (isValidRedirectUrl(redirectToUrl)) {
          url = decodeURIComponent(decodeURIComponent(redirectToUrl));
        }
        Auth.validateSession(
          url,
          rootStore.getUserStore().fetchData,
          rootStore.getUserStore().setAccountValidationError,
          navigate,
        );
      }
    } else if (mode === AuthModeEnum.SSO) {
      Auth.handleSSOCallback();
    } else if (mode === AuthModeEnum.SSO_TCC) {
      const successValue = searchParams.get("success");
      const relayStateValue = searchParams.get("RelayState");
      const params = {};
      if (successValue) {
        _.extend(params, { success: successValue });
      }
      if (relayStateValue) {
        _.extend(params, { RelayState: relayStateValue });
      }
      Auth.handleSSOTCCCallback(params);
    } else if (mode === AuthModeEnum.FAILURE) {
      const msg = rootStore.getTranslation("shared.auth.authentication_process_failed");
      rootStore.getErrorHandlerStore().handleUnauthorized(msg);
    }
  }, []);

  return <Fragment></Fragment>;
};
