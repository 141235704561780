import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../../stores/rootStore";
import { GroupAndCategoryStoreContext, GroupAndCategoryHandlerContext } from "../tabs/groupAndCategoryStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { Dialog } from "../../dialogs/Dialog";
import { DialogActions } from "../../components/DialogActions";
import { ItemTypeCategories } from "../../components/ItemTypeCategories";
import { UseCategories } from "../../components/UseCategories";

const DialogContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store = useContext(GroupAndCategoryStoreContext);

  return (
    <article data-testid="editGroupAndCategoryDialog">
      <header>
        <h2 className="l-center-align">
          {rootStore.getTranslation("shared.catalog_entity_edit.edit_group_and_category")}
        </h2>
      </header>
      <section className="labels">
        <ol className="basic-data">
          <GroupAndCategoryHandlerContext.Provider value={store}>
            <ItemTypeCategories />
            <UseCategories />
          </GroupAndCategoryHandlerContext.Provider>
        </ol>
      </section>
      <DialogActions store={store} />
    </article>
  );
});

/**
 * Renders the editor dialog & button for GroupAndCategory in package page
 */
export const EditGroupAndCategory = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);

  function openDialog() {
    dialog.open();
  }

  return (
    <Fragment>
      <div className="edit-group-and-category">
        <a
          data-testid="edit-group-and-category"
          className="icon icon-pencil"
          title={rootStore.getTranslation("details.edit.tooltips.groupandcategory")}
          onClick={openDialog}
        >
          <span>{rootStore.getTranslation("details.edit.tooltips.groupandcategory")}</span>
        </a>
      </div>
      <Dialog content={<DialogContent />} additionalClass="group-and-category" />
    </Fragment>
  );
});
