import _ from "underscore";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ITCCCollection } from "../../models/TCCdataModel";
import { ContentTypeEnum, SourceEnum } from "../../models/enums";

/**
 * Converts the data to a TCCCollection object.
 * @param {*} data
 * @returns {ITCCCollection} - The converted data.
 */
export function TCCCollectionRequestTransferObject(data) {
  data = data || {};

  var convertedData = {
    title: data.title,
    externalId: data.externalId,
    description: data.description,
    isCatalog: data.isCatalog || false,
    source: SourceEnum.TEKLA_WAREHOUSE,
    type: data.type,
    contentType: ContentTypeEnum.TEKLA_WAREHOUSE,
    tags: data.tags || [],
    baseAccessLevel: data.baseAccessLevel,
    isHidden: data.isHidden || false,
    copyright: data.copyright,
    details: data.details,
    translations: data.translations,
  };

  if (data.attributes) {
    convertedData.attributes = data.attributes;
  }

  if (!_.isUndefined(data.doNotNotify)) {
    convertedData.doNotNotify = data.doNotNotify;
  }

  if (!_.isUndefined(data.allowComments)) {
    convertedData.allowComments = data.allowComments;
  }

  if (!_.isUndefined(data.creatorId)) {
    convertedData.creator = {};
    convertedData.creator.id = data.creatorId;
  }

  if (data._id || data.id) {
    _.extend(convertedData, {
      id: data._id || data.id,
    });
  } else {
    _.extend(convertedData, {
      uploadAsOrganization: data.uploadAsOrganization || false,
    });
  }

  return convertedData;
}
