import * as React from "react";
import { Fragment, useContext } from "react";
import { RootContext, RootStore } from "../stores/rootStore";
import { observer } from "mobx-react";
import { ResultOptionsStoreContext, ResultOptionsStore } from "../components/result-options/resultOptionsStore";
import { ShoppingCart } from "./ShoppingCart";
import { ShoppingCartStrategy } from "./shoppingCartStrategy";
import {
  LinkToCollectionStore,
  LinkToCollectionStoreContext,
} from "../components/result-options/linkToCollectionStore";

/**
 * A component that displays shopping cart. In fact it's a wrapper for ShoppingCart component, serving a role of
 * ShoppingCartStoreContext provider.
 *
 * @param store RootStore object that provides translations and dependencies.
 */
export const ShoppingCartWrapper = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const resultOptionsStore = new ResultOptionsStore(rootStore, new ShoppingCartStrategy(rootStore));
  const linkToCollectionStore = new LinkToCollectionStore(rootStore);

  return (
    <Fragment>
      {rootStore && (
        <ResultOptionsStoreContext.Provider value={resultOptionsStore}>
          <LinkToCollectionStoreContext.Provider value={linkToCollectionStore}>
            <ShoppingCart />
          </LinkToCollectionStoreContext.Provider>
        </ResultOptionsStoreContext.Provider>
      )}
    </Fragment>
  );
});
