import { runInAction } from "mobx";
import _ from "underscore";

import { ModelTypeConverter } from "../converters/ModelTypeConverter";
import { NewCollectionConverter } from "../converters/NewCollectionConverter";
import { PackageConverter } from "../converters/PackageConverter";
import { SearchAttributesConverter } from "../converters/SearchAttributesConverter";
import { LocalUtilsDS } from "../data-source/LocalUtilsDS";
import { TCCCollectionDS } from "../data-source/TCCCollectionDS";
import { TCCSiteStatsDS } from "../data-source/TCCSiteStatsDS";
import { TCCTranslationDS } from "../data-source/TCCTranslationDS";
import { TCCUserDS } from "../data-source/TCCUserDS";
import { TCCUserLockoutDS } from "../data-source/TCCUserLockoutDS";
import { Binary } from "../factories/Binary";
import { LocalBinaryService } from "../services/LocalBinaryService";
import { LocalCollectionService } from "../services/LocalCollectionService";
import { LocalPackageService } from "../services/LocalPackageService";
import { LocalSearchService } from "../services/LocalSearchService";
import { LocalVersionService } from "../services/LocalVersionService";
import { TCCAclService } from "../services/TCCAclService";
import { TCCBinaryService } from "../services/TCCBinaryService";
import { TCCCollectionService } from "../services/TCCCollectionService";
import { TCCJobsService } from "../services/TCCJobsService";
import { TCCNotificationService } from "../services/TCCNotificationService";
import { TCCOrganizationService } from "../services/TCCOrganizationService";
import { TCCPackageService } from "../services/TCCPackageService";
import { TCCSearchService } from "../services/TCCSearchService";
import { TCCUserService } from "../services/TCCUserService";
import { TCCVersionService } from "../services/TCCVersionService";
import { Uploader } from "../utils/Uploader";

import { Settings } from "../../config/Settings";
// eslint-disable-next-line prettier/prettier
import {
  IBatchEntity,
  IBinary,
  ICollection,
  IEntity,
  IEntityPayload,
  IFileItem,
  IItem,
  IModifier,
  INotification,
  IOrganization,
  ITranslationObject,
  IUser,
} from "../../models/dataModel";
import {
  BinaryTypeEnum,
  ContentTypeEnum,
  ExternalResourceTypeEnum,
  ObjectTypeEnum,
  OrganizationRoleEnum,
  SearchTargetOptionEnum,
} from "../../models/enums";
import { ScanResultsChecker } from "../../utils/ScanResultsChecker";
import { organizationIsImmutable } from "../../utils/Immutability";

/**
 * Adds binaries to given subject.
 * @param {IVersion} subject - The subject.
 * @param {Array} binaries - The binaries to be added.
 * @param {string} performAsId - The ID of the user performing the action.
 * @returns {Promise} A promise that resolves with the result of the operation.
 */
function addBinaries(subject, binaries, flagAsImmutable, performAsId) {
  binaries = binaries || [];

  if (flagAsImmutable) {
    binaries = _.map(binaries, function (binary) {
      binary.attributes = binary.attributes || {};
      binary.attributes.immutability = {
        "--immutable": { dataType: "boolean", value: true },
      };
      return binary;
    });
  }

  return Uploader.upload(subject, binaries, BinaryTypeEnum.PACKAGE_CONTENT).then(function () {
    return detectTypeAndGet(subject, performAsId);
  });
}

/**
 * Adds a binary for given subject.
 * @param {IVersion} subject - The subject.
 * @param {object} binary - The binary object to be added.
 * @param {string} performAsId - The ID of the user performing the action.
 * @returns {Promise} A promise that resolves with the result of the operation.
 */
function addBinary(subject, binary, performAsId) {
  binary.attributes.type = BinaryTypeEnum.PACKAGE_CONTENT;
  return uploadBinary(subject, binary).then(function () {
    return detectTypeAndGet(subject, performAsId);
  });
}

/**
 * Adds a blob for the given version.
 * @param {IVersion} subject - The subject of the blob.
 * @param {Object} binary - The binary object to be added.
 * @param {string} performAsId - The ID of the user performing the action.
 * @returns {Promise} A promise that resolves to the result of adding the blob.
 */
function addBlob(subject, binary, performAsId) {
  if (subject.isLocal) {
    var extension = binary.attributes.originalName.split(".").pop();
    return LocalUtilsDS.writeFile({ text: binary.blob, extension: extension }).then(function (tempFile) {
      binary.location = tempFile.filename;
      return uploadBinary(subject, binary, BinaryTypeEnum.PACKAGE_CONTENT, true).then(function () {
        return detectTypeAndGet(subject, performAsId);
      });
    });
  } else {
    return TCCBinaryService.addBlob(subject, binary).then(function () {
      return detectTypeAndGet(subject, performAsId);
    });
  }
}

/**
 * Adds a code name for a package.
 *
 * @param {string} packageId - The ID of the package.
 * @param {string} codeNameWithoutPrefix - The code name to be added.
 * @returns {Promise} A promise that resolves when the attributes are updated.
 */
function addCodeNameForPackage(packageId, codeNameWithoutPrefix) {
  const data = {
    id: packageId,
    codeName: "twh-" + codeNameWithoutPrefix,
    isImmutable: true,
    doNotNotify: true,
  };

  return TCCPackageService.update(data, false);
}

/**
 * Adds a release note for the given package.
 * @param {IEntity} subject - The subject of the release note.
 * @param {Object} binary - The binary data of the release note.
 * @param {string} performAsId - The ID of the user performing the action.
 * @returns {Promise} A promise that resolves with the result of the upload and detection.
 */
function addReleaseNote(subject, binary, performAsId) {
  subject = subject || {};
  binary = binary || {};
  binary.reference = "releasenote";
  binary.attributes.type = BinaryTypeEnum.THUMBNAIL;
  return uploadBinary(subject, binary).then(function () {
    return detectTypeAndGet(subject, performAsId);
  });
}

/**
 * Adds a review for a subject.
 * @param {IItem} subject - The subject to add the review to.
 * @param {number} rating - The rating for the review.
 * @returns {Promise} A promise that resolves to the result of adding the review.
 */
function addReview(subject, rating) {
  if (!subject.isLocal) {
    var review = {
      overall: rating,
    };
    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionService.addReview(subject.id, review)
        .then((res) => new Promise((resolve) => setTimeout(() => resolve(res), Settings.defaultTimeout)))
        .then(function () {
          return detectTypeAndGet(subject);
        });
    } else {
      return TCCPackageService.addReview(subject.id, review)
        .then((res) => new Promise((resolve) => setTimeout(() => resolve(res), Settings.defaultTimeout)))
        .then(function () {
          return detectTypeAndGet(subject);
        });
    }
  }
}

/**
 * Adds a thumbnail to the subject using the provided binary data.
 * If a reference is not provided, the default reference "thumbnail" will be used.
 * The binary data is marked as metadata.
 * The binary type is set to BinaryTypeEnum.THUMBNAIL.
 * Returns a promise that resolves after the binary is uploaded and a delay of 1500 milliseconds.
 * After the delay, the type of the subject is detected and retrieved.
 * @param {IItem | IEntity | ICollection | IOrganization} subject - The subject to which the thumbnail will be added.
 * @param {IBinary} binary - The binary data of the thumbnail.
 * @param {string} [reference] - The reference for the thumbnail. If not provided, "thumbnail" is used.
 * @param {IUser | IModifier} [editor] - The user performing the action.
 * @returns {Promise} A promise that resolves with the detected type of the subject.
 */
function addThumbnail(subject, binary, reference, editor) {
  subject = subject || {};

  if (!!editor) {
    subject.modifiedAt = new Date();
    setModifier(subject, editor);
  }

  binary = binary || {};
  binary.isMetadata = true;
  if (!reference) {
    binary.reference = "thumbnail";
  } else {
    binary.reference = reference;
  }
  binary.attributes.type = BinaryTypeEnum.THUMBNAIL;
  return uploadBinary(subject, binary)
    .then(() => new Promise((resolve) => setTimeout(() => resolve(), 1500)))
    .then(function () {
      return detectTypeAndGet(subject, null);
    });
}

/**
 * Adds a 3D thumbnail to the subject using the provided binary data.
 * @param {IItem | IEntity | ICollection | IOrganization} subject - The subject to which the thumbnail will be added.
 * @param {IBinary} binary - The binary data of the thumbnail.
 * @param {string} [reference] - The reference name for the thumbnail. If not provided, "thumbnail_3d" will be used. 
 * @param {IUser | IModifier} [editor] - The user performing the action.
 * @returns {Promise} A promise that resolves with the result of uploading the binary and detecting the type.
 */
function add3DThumbnail(subject, binary, reference, editor) {
  subject = subject || {};

  if (!!editor) {
    subject.modifiedAt = new Date();
    setModifier(subject, editor);
  }

  binary = binary || {};
  binary.isMetadata = true;
  if (!reference) {
    binary.reference = "thumbnail_3d";
  } else {
    binary.reference = reference;
  }
  binary.attributes.type = BinaryTypeEnum.THUMBNAIL;
  return uploadBinary(subject, binary).then(function () {
    return detectTypeAndGet(subject, null);
  });
}

/**
 * Archives a collection and its packages.
 *
 * @param {ICollection} collection - The collection to be archived.
 * @param {IUser} user - The user performing the archive action.
 * @returns {Promise} - A promise that resolves when all the updates are completed.
 */
function archiveCollection(collection, user) {
  let promises = [];

  collection.isHidden = true;
  collection.isArchived = true;
  promises.push(updateCollection(collection, user));

  var query = {
    offset: 0,
    count: 1000,
    fq: "parentCollectionId==" + collection.id,
  };

  TCCSearchService.searchPackages(query, true, user.id).then(function (packages) {
    _.each(packages || [], function (packageItem) {
      promises.push(archivePackage(packageItem, user));
    });
  });

  return Promise.all(promises);
}

/**
 * Archives a package and its versions.
 * If package is not immutable, but is created by an immutable organization, flag package as immutable.
 *
 * @param {IEntity} packageItem - The package to be archived.
 * @param {IUser} user - The user performing the archive action.
 * @returns {Promise} A promise that resolves when the package and its versions are successfully archived.
 */
async function archivePackage(packageItem, user) {
  let promises = [];

  packageItem.isHidden = true;
  packageItem.isArchived = true;

  if (
    !packageItem.isImmutable &&
    packageItem.creator.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION &&
    (await organizationIsImmutable(packageItem.creator.id))
  ) {
    packageItem.isImmutable = true;
  }

  promises.push(updatePackage(packageItem, user, false));

  var query = {
    offset: 0,
    count: 1000,
    fq: "parentCollectionId==" + packageItem.id,
  };

  TCCSearchService.searchVersions(query).then(function (versions) {
    _.each(versions || [], function (version) {
      promises.push(archiveVersion(version, packageItem, user));
    });
  });

  return Promise.all(promises);
}

/**
 * Archives a version.
 * If version is not immutable, but it belongs to an immutable package, flag version as immutable.
 *
 * @param {IVersion} version - The version to be archived.
 * @param {IEntity} package - The package to which the version belongs.
 * @param {Object} user - The user performing the archive.
 * @returns {Promise} - A promise that resolves when the version is successfully archived.
 */
async function archiveVersion(version, packageItem, user) {
  version.isHidden = true;
  version.isArchived = true;

  if (!version.isImmutable && packageItem?.isImmutable) {
    version.isImmutable = true;
  }

  return updateVersion(version, user);
}

/**
 * Creates a collection for analyst users.
 * @param {IUser} user - The user object.
 * @returns {Promise<Object>} A promise that resolves to the created collection.
 */
function createAnalystUsersContainerCollection(user) {
  var data = {};
  data.visibility = "private";
  data.title = "Analyst users container collection";
  data.type = ObjectTypeEnum.TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION;
  setCreatorAndModifier(data, data.creator || user, user);
  return TCCCollectionService.create(data).then(function (collectionId) {
    return TCCCollectionDS.getCollection(collectionId, { performAs: data.modifier.id }).then(
      NewCollectionConverter.fromTCC,
    );
  });
}

/**
 * Creates a collection based on given data, sets user as the creator
 * @param {ICollection | ICollectionFormData} data
 * @param {IUser | IOrganization | IModifier | undefined} user
 * @returns {Promise<ICollection>} The created collection id
 */
function createCollection(data, user) {
  if (data.isLocal) {
    return LocalCollectionService.create(data);
  } else {
    runInAction(() => {
      data.modifiedAt = data.createdAt = new Date();
      var creator = !!data.creator && !!data.creator.id ? data.creator : user;
      setCreatorAndModifier(data, creator, user);
    });
    return TCCCollectionService.create(data)
      .then(
        (collectionId) => new Promise((resolve) => setTimeout(() => resolve(collectionId), 1500)),
      )
      .then(function (collectionId) {
        return TCCCollectionService.get(collectionId, user.id).then(
          function (collection) {
            return collection;
          },
          async function () {
            console.log("Failed to get collection, will retry after delay");
            return new Promise((resolve) => setTimeout(() => resolve(collectionId), 1500)).then(
              function (collectionId) {
                console.log("Retry to get collection");
                return TCCCollectionService.get(collectionId, user.id);
              },
            );
          },
        );
      });
  }
}

/**
 * Creates a linked resources collection.
 * @param {Object} data - The data object containing information about the collection.
 * @param {Object} user - The user object.
 * @returns {Promise} A promise that resolves with the created linked resources collection or rejects with an error.
 */
function createLinkedResourcesCollection(data, user) {
  return new Promise((resolve, reject) => {
    if (data.isLocal) {
      reject({ err: "local resources not supported" });
    } else {
      if (data.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
        createCollection(
          {
            isHidden: true,
            visibility: data.visibility,
            type: ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
            owner: data.owner,
            creator: data.creator,
            title: "linkedResourcesCollection",
            description: "linkedResourcesCollection",
            linkedToCollection: data,
          },
          user,
        ).then(
          function (linkedResourcesCollection) {
            data.linkedResourcesCollection = linkedResourcesCollection;
            TCCCollectionService.update(data).then(
              function () {
                resolve(linkedResourcesCollection);
              },
              function (err) {
                reject(err);
              },
            );
          },
          function (err) {
            reject(err);
          },
        );
      } else {
        reject({ err: "resource type not supported" });
      }
    }
  });
}

/**
 * Creates a new package based on given data
 * @param {*} data
 * @param {IUser | IOrganization | undefined} user
 * @returns the created package
 */
function createPackage(data, user) {
  if (data.isLocal) {
    return LocalPackageService.create(data);
  } else {
    data.modifiedAt = data.createdAt = new Date();
    setCreatorAndModifier(data, data.creator || user, user);
    return TCCPackageService.create(data);
  }
}

/**
 * Creates a new version.
 * @param {Object} data - The data for the new version.
 * @param {IUser | IOrganization} user - The user creating the version.
 * @returns {Promise} A promise that resolves with the created version.
 */
function createVersion(data, user) {
  if (data.isLocal) {
    return LocalVersionService.create(data);
  } else {
    data.modifiedAt = data.createdAt = new Date();
    setCreatorAndModifier(data, data.creator || user, user);
    return TCCVersionService.create(data);
  }
}

/**
 * Deletes a binary from a subject.
 * @param {Object} subject - The subject from which the binary will be deleted.
 * @param {Object} binary - The binary to be deleted.
 * @returns {Promise} A promise that resolves when the binary is successfully deleted.
 */
function deleteBinary(subject, binary) {
  if (subject.isLocal) {
    return LocalBinaryService.remove(subject, binary);
  } else {
    return TCCBinaryService.remove(subject, binary);
  }
}

/**
 * Deletes the specified binaries for a given subject.
 * @param {Object} subject - The subject for which the binaries are being deleted.
 * @param {Array} binaries - The binaries to be deleted.
 * @param {string} performAsId - The ID of the user performing the deletion.
 * @returns {Promise} A promise that resolves with the result of the deletion or rejects with an error.
 */
function deleteBinaries(subject, binaries, performAsId) {
  binaries = binaries || [];
  return new Promise((resolve, reject) => {
    if (subject.isLocal) {
      var deleteBinaryDataPromises = _.map(binaries, function (binary) {
        return function () {
          deleteBinary(subject, binary);
        };
      });
      _.reduce(
        deleteBinaryDataPromises,
        function (temp, a) {
          return temp.then(a);
        },
        Promise.resolve({}),
      ).then(
        function () {
          detectTypeAndGet(subject, performAsId).then(
            function (res) {
              resolve(res);
            },
            function (err) {
              reject(err);
            },
          );
        },
        function (err) {
          reject(err);
        },
      );
    } else {
      var deleteBinaryDataPromises = _.map(binaries, function (binary) {
        deleteBinary(subject, binary);
      });
      Promise.all(deleteBinaryDataPromises).then(
        function () {
          detectTypeAndGet(subject, performAsId).then(
            function (res) {
              resolve(res);
            },
            function (err) {
              reject(err);
            },
          );
        },
        function (err) {
          reject(err);
        },
      );
    }
  });
}

/**
 * Deletes a collection.
 * @param {Object} data - The collection data.
 * @param {string} performAsId - The ID of the user performing the deletion.
 * @returns {Promise} A promise that resolves when the collection is deleted.
 */
function deleteCollection(data, performAsId) {
  if (data.isLocal) {
    return LocalCollectionService.remove(data);
  } else {
    if (data.linkedResourcesCollection) {
      return TCCCollectionService.remove(data.linkedResourcesCollection, false, performAsId).then(
        function () {
          return TCCCollectionService.remove(data, true, performAsId);
        },
        function () {
          return TCCCollectionService.remove(data, true, performAsId);
        },
      );
    } else {
      return TCCCollectionService.remove(data, true, performAsId);
    }
  }
}

/**
 * Deletes a package.
 * @param {IEntity} data - The package data.
 * @param {string} authorization - The authorization token.
 * @returns {Promise} A promise that resolves when the package is deleted.
 */
async function deletePackage(data, authorization) {
  if (data.isLocal) {
    return LocalPackageService.remove(data);
  } else {
    var query = {
      offset: 0,
      count: 1000,
      fq: "parentCollectionId==" + data.id,
    };
    const res = await TCCSearchService.searchVersions(query);
    var promises = _.map(res, function (entry) {
      return new Promise((resolve) => {
        TCCVersionService.remove({ id: entry.id }, authorization).then(
          function () {
            resolve();
          },
          function () {
            resolve();
          },
        );
      });
    });
    await Promise.all(promises);
    return await TCCPackageService.remove(data, authorization);
  }
}

/**
 * Deletes a content attribute from a given content object.
 * @param {object} content - The content object.
 * @param {object} attribute - The attribute to be deleted.
 * @returns {Promise<object>} - A promise that resolves to the updated content object.
 */
function deleteContentAttribute(content, attribute) {
  var attributes = {
    category: attribute.category,
    name: attribute.name,
    value: attribute.value,
  };
  if (content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
    return TCCCollectionService.setAttributes(content.id, attributes).then(function () {
      return detectTypeAndGet(content, null);
    });
  } else {
    return TCCPackageService.setAttributes(content.id, attributes).then(function () {
      return detectTypeAndGet(content, null);
    });
  }
}

/**
 * Deletes a release note binary and returns the entity without the deleted release note.
 * @param {IEntity} subject - The subject of the release note.
 * @param {string} performAsId - The ID of the user performing the deletion.
 * @returns {Promise} A promise that resolves with the entity without the deleted release note.
 */
function deleteReleaseNote(subject, performAsId) {
  var binary = Binary.create({
    reference: "releasenote",
  });
  return deleteBinary(subject, binary)
    .then(() => new Promise((resolve) => setTimeout(() => resolve(), Settings.defaultTimeout)))
    .then(function () {
      return detectTypeAndGet(subject, performAsId);
    });
}

/**
 * Deletes a review.
 * @param {IItem} subject - The subject of the review.
 * @returns {Promise} A promise that resolves with the result of the deletion.
 */
function deleteReview(subject) {
  if (!subject.isLocal) {
    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionService.deleteReview(subject.id)
        .then((res) => new Promise((resolve) => setTimeout(() => resolve(res), Settings.defaultTimeout)))
        .then(function () {
          return detectTypeAndGet(subject);
        });
    } else {
      return TCCPackageService.deleteReview(subject.id)
        .then((res) => new Promise((resolve) => setTimeout(() => resolve(res), Settings.defaultTimeout)))
        .then(function () {
          return detectTypeAndGet(subject);
        });
    }
  }
}

/**
 * Deletes the 3D thumbnail for a subject.
 * If a custom reference is provided, it will be used instead of the default "thumbnail_3d".
 * @param {IItem} subject - The subject for which the 3D thumbnail should be deleted.
 * @param {string} [reference] - Optional. The custom reference for the 3D thumbnail.
 * @returns {Promise} A promise that resolves with the result of deleting the 3D thumbnail and detecting the type.
 */
function delete3dThumbnail(subject, reference) {
  var referenceTmp = "thumbnail_3d";
  if (reference) {
    referenceTmp = reference;
  }
  var binary = Binary.create({
    reference: referenceTmp,
  });
  return deleteBinary(subject, binary)
    .then(() => new Promise((resolve) => setTimeout(() => resolve(), Settings.defaultTimeout)))
    .then(function () {
      return detectTypeAndGet(subject, null);
    });
}

/**
 * Deletes a user.
 * @param {string} userId - The ID of the user to delete.
 * @returns {Promise} A promise that resolves when the user is deleted.
 */
function deleteUser(userId) {
  return TCCUserService.deleteUser(userId);
}

/**
 * Deletes the thumbnail binary associated with the given subject and reference.
 * If no reference is provided, the default reference "thumbnail" is used.
 * @param {IItem | IOrganization} subject - The subject from which binary is deleted.
 * @param {string} [reference] - The reference of the binary (optional).
 * @returns {Promise} A promise that resolves after the thumbnail binary is deleted and the type is detected.
 */
function deleteThumbnail(subject, reference) {
  var referenceTmp = "thumbnail";
  if (reference) {
    referenceTmp = reference;
  }
  var binary = Binary.create({
    reference: referenceTmp,
  });
  return deleteBinary(subject, binary)
    .then(() => new Promise((resolve) => setTimeout(() => resolve(), 1500)))
    .then(function () {
      return detectTypeAndGet(subject, null);
    });
}

/**
 * Deletes a version.
 * @param {Object} data - The version data.
 * @param {string} performAsId - The authorization token.
 * @returns {Promise} A promise that resolves when the version is deleted.
 */
function deleteVersion(data, performAsId) {
  if (data.isLocal) {
    return LocalVersionService.remove(data);
  } else {
    return TCCVersionService.remove(data, performAsId);
  }
}

/**
 * Detects the type of data and retrieves the corresponding information.
 *
 * @param {Object} data - The data object.
 * @param {string} authorization - The authorization token.
 * @returns {Promise} - A promise that resolves with the retrieved information.
 */
function detectTypeAndGet(data, authorization) {
  data = data || {};
  if (_.contains([ObjectTypeEnum.LOCAL_COLLECTION, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION], data.type)) {
    return getCollection(data, authorization);
  } else if (_.contains([ObjectTypeEnum.LOCAL_PACKAGE, ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE], data.type)) {
    return getPackage(data, authorization);
  } else if (_.contains([ObjectTypeEnum.LOCAL_VERSION, ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION], data.type)) {
    return getVersion(data, authorization);
  } else if (data.type === ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION) {
    return getOrganization({ id: data.id, showBinaryAttributes: true });
  } else {
    console.log("unknown data type", data);
    return null;
  }
}

/**
 * Retrieves all collections based on the provided parameters.
 * @param {number} offSet - The offset value for pagination.
 * @param {number} count - The number of collections to retrieve.
 * @param {string} sortBy - The field to sort the collections by.
 * @param {string | undefined} performAsId - The ID of the user performing the action.
 * @returns {Array} - An array of collections.
 */
function getAllCollections(offSet, count, sortBy, performAsId) {
  var data = {};
  data.offset = offSet || 0;
  data.count = count || 1000;
  if (sortBy) {
    data.sortBy = sortBy;
  }
  return TCCSearchService.searchCollections(data, true, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, performAsId);
}

function getAllCollectionsRaw(offSet, count, sortBy, performAsId) {
  var data = {};
  data.offset = offSet || 0;
  data.count = count || 1000;
  if (sortBy) {
    data.sortBy = sortBy;
  }
  return TCCSearchService.searchCollectionsRaw(data, true, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, performAsId);
}

/**
 * Retrieves all packages from the repository.
 * @param {number} offSet - The offset value for pagination.
 * @param {number} count - The number of packages to retrieve.
 * @param {boolean} showHidden - Indicates whether to include hidden packages.
 * @param {string} sortBy - The field to sort the packages by.
 * @param {string | undefined} performAsId - The ID of the user performing the action.
 * @returns {Promise} A promise that resolves with the search result.
 */
function getAllPackages(offSet, count, showHidden, sortBy, performAsId) {
  var data = {};
  data.offset = offSet || 0;
  data.count = count || 1000;
  if (showHidden) {
    data.showHidden = true;
  }
  if (sortBy) {
    data.sortBy = sortBy;
  }
  return TCCSearchService.searchPackages(data, true, performAsId);
}

// /**
//  * Retrieves raw data for all packages.
//  * (Used in admin > manage packages).
//  * @param {number} count - The number of packages to retrieve.
//  * @param {boolean} showHidden - Indicates whether to include hidden packages.
//  * @param {string} sortBy - The field to sort the packages by.
//  * @returns {Promise} A promise that resolves with the raw data of the packages.
//  */
// function getAllPackagesRaw(queryData, performAsId) {
//   var data = queryData || {};
//   return TCCSearchService.searchPackagesRaw(data, true, performAsId);
// }

/**
 * Retrieves a list of banned packages.
 * @param {string} performAsId - The ID of the user performing the search.
 * @returns {Promise} A promise that resolves with the search result.
 */
function getBannedPackages(performAsId) {
  var data = {
    fq: "baseAccessLevel==banned",
    count: 1000,
    sortBy: "modifyTime ASC",
    showHidden: true,
  };

  return TCCSearchService.searchPackages(data, true, performAsId);
}

/**
 * Retrieves all subscriptions.
 * @returns {Promise<Array>} A promise that resolves with an array of subscriptions.
 */
function getAllSubscriptions() {
  return new Promise((resolve) => {
    var allSubcriptions = [];
    var total;
    var promises = [];
    TCCUserService.getSubscriptions({ count: 100 }).then(function (res) {
      allSubcriptions = res.entries;
      total = res.total;
      if (total > res.endRow) {
        for (offset = res.endRow; offset < total; offset = offset + 100) {
          promises.push(
            TCCUserService.getSubscriptions({ count: 100, offset: offset }).then(
              function (res) {
                allSubcriptions = allSubcriptions.concat(res.entries);
              },
              function () {
                total = 0;
                offset = 0;
                resolve();
              },
            ),
          );
        }
        Promise.all(promises).finally(function () {
          resolve(allSubcriptions);
        });
      } else {
        resolve(allSubcriptions);
      }
    });
  });
}

/**
 * Retrieves the binaries for a given subject.
 * @param {string} subject - The subject for which to retrieve the binaries.
 * @param {string | undefined} performAsId - The ID of the user performing the action.
 * @returns {Promise} - A promise that resolves with the binaries.
 */
function getBinaries(subject, performAsId) {
  return TCCBinaryService.getBinaries(subject, performAsId);
}

/**
 * Fetches a collection based on given data object, either from LocalCollectionService or TCCCollectionService.
 * @param {{ id: string; isLocal?: boolean }} data
 * @param {string} performAsId
 * @returns collection
 */
function getCollection(data, performAsId) {
  data = data || {};
  if (data.isLocal) {
    return LocalCollectionService.get({ id: data.id });
  } else {
    var reqData = {};
    if (data.recordEvent) {
      reqData = { recordEvent: true };
    }
    return TCCCollectionService.get(data.id, performAsId, reqData);
  }
}

/**
 * Retrieves collections based on the provided data.
 * If the data is marked as local, it performs a local search using LocalSearchService.
 * Otherwise, it performs a search using TCCSearchService.
 * @param {Object} data - The data used for searching collections.
 * @param {string | undefined} performAsId - The ID used for performing the search.
 * @returns {Array} - An array of collections.
 */
function getCollections(data, performAsId) {
  data = data || {};
  if (data.isLocal) {
    return LocalSearchService.searchCollections(data);
  } else {
    return TCCSearchService.searchCollections(data, false, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, performAsId);
  }
}

/**
 * Retrieves collections with the total count based on the provided data.
 * @param {Object} data - The data used for searching collections.
 * @param {string | undefined} performAsId - The ID used for performing the search.
 * @returns {Object} - An array of collections.
 */
function getCollectionsWithTotalCount(data, performAsId) {
  data = data || {};

  return TCCSearchService.searchCollectionsRaw(data, false, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, performAsId);
}

/**
 * Retrieves collections created by a specific creator.
 * @param {Object} creator - The creator object.
 * @param {boolean} dontFetchAgain - Indicates whether to fetch the collections again.
 * @param {string | undefined} performAsId - The ID of the user performing the action.
 * @returns {Array} - An array of collections.
 */
async function getCollectionsByCreator(creator, dontFetchAgain, performAsId) {
  creator = creator || {};
  if (creator && creator.id) {
    var fqs = ["creatorId==" + creator.id];
    var query = {
      fq: fqs.join(";"),
    };
    return TCCSearchService.searchCollections(
      query,
      dontFetchAgain,
      ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
      performAsId,
    );
  } else {
    return [];
  }
}

/**
 * Fetches a list of collections the user defined in the data object has the right to link content to.
 * @param {{ user: IUser | undefined }} data
 * @returns list of collections
 */
function getCollectionsWithLinkRights(data) {
  return new Promise((resolve, reject) => {
    data = data || {};
    var promises = [];
    if (data.user) {
      promises.push(getMyCollections(data));
      promises.push(getCollectionsForMyOrganizationsWithEditRights(data));
    } else {
      promises.push(Promise.resolve([]));
    }
    Promise.all(promises).then(
      function (res) {
        resolve(_.union.apply(null, res));
      },
      function (err) {
        reject(err);
      },
    );
  });
}

/**
 * Fetches a list of collections the user defined in the data object has the right to transfer content to.
 * @param {IUser | undefined} user - The user object.
 * @param {ICollection} sourceCollection - The source collection.
 * @param {boolean} userHasEditRightsToSourceOrganization - Indicates whether the user has edit rights to the source organization.
 * @returns list of collections
 */
function getCollectionsWithTransferRights(user, sourceCollection, userHasEditRightsToSourceOrganization) {
  return new Promise((resolve, reject) => {
    var promises = [];

    if (!!user && !!sourceCollection && !!sourceCollection.creator) {
      if (sourceCollection.creator.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION && userHasEditRightsToSourceOrganization) {
        promises.push(getCollectionsByCreator(sourceCollection.creator, true, user.id));
      } else {
        promises.push(getCollectionsByCreator(user, true, user.id));
      }
    } else {
      promises.push(Promise.resolve([]));
    }

    Promise.all(promises).then(
      function (res) {
        resolve(_.union.apply(null, res));
      },
      function (err) {
        reject(err);
      },
    );
  });
}

/**
 * Retrieves collections with upload rights.
 * @param {{ user: IUser }} data - Additional data for the function.
 * @returns {Promise<Array>} - A promise that resolves to an array of collections with upload rights.
 */
function getCollectionsWithUploadRights(data) {
  return new Promise((resolve, reject) => {
    data = data || {};
    var promises = [];

    function fetchNotPreinstalled() {
      return new Promise((resolve) => {
        LocalSearchService.searchCollections().then(
          function (res) {
            var notPreinstalledCollections = _.select(res || [], function (c) {
              return !c.isPreinstalled;
            });
            resolve(notPreinstalledCollections);
          },
          function () {
            resolve([]);
          },
        );
      });
    }
    promises.push(fetchNotPreinstalled());
    if (data.user) {
      promises.push(getMyCollections(data));
      promises.push(getCollectionsForMyOrganizationsWithEditRights(data));
    } else {
      promises.push(Promise.resolve([]));
    }

    Promise.all(promises).then(
      function (res) {
        resolve(_.union.apply(null, res));
      },
      function (err) {
        reject(err);
      },
    );
  });
}

/**
 * Gets subscribers for given subject
 * @param {IEntity | ICollection} subject
 * @returns subscribers
 */
function getContentSubscribers(subject) {
  if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
    return TCCCollectionService.getSubscribers(subject.id);
  } else {
    return TCCPackageService.getSubscribers(subject.id);
  }
}

/**
 * Retrieves a list of immutable organizations.
 *
 * @returns {Promise<Array>} A promise that resolves to an array of immutable organizations.
 */
function getImmutableOrganizations() {
  return TCCSearchService.searchOrganizations({
    fq: `(attribute:immutability:--immutable:boolean==true;type==twh)`,
  }).then(function (res) {
    return res.entries;
  });
}

/**
 * Retrieves a list of infected entities from the TCCSearchService.
 * @returns {Array} An array of infected entities.
 */
function getInfectedEntities() {
  var query = {
    offset: 0,
    count: 1000,
    showHidden: true,
    fq: "binaries.jobs.virusScan.status==SUCCESS;binaries.jobs.virusScan.resultCode==SCAN_INFECTED",
  };
  return TCCSearchService.searchVersions(query);
}

/**
 * Retrieves invalid content (packages without parentCollectionId).
 * @returns {Promise<Array>} A promise that resolves to an array of packages.
 */
function getInvalidPackages() {
  var query = {
    offset: 0,
    count: 1000,
    showHidden: true,
    fq: "attribute:references:parentCollectionId:string=exists=false",
  };
  return TCCSearchService.searchPackages(query);
}

/**
 * Fetches linked resources for given data object
 * @param {*} data The data object
 * @param {boolean} dontFetchAgain Should fetch again
 * @param {string | undefined} performAsId the performAsId
 * @returns list of linked resources
 */
function getLinkedResources(data, dontFetchAgain, performAsId) {
  if (data) {
    if (data.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return getLinkedResourcesCollection(data, performAsId).then(function (res) {
        if (res) {
          var searchQuery = { id: res.id, sortBy: data.sortBy };
          if (data.testedVersion) {
            _.extend(searchQuery, { testedVersion: data.testedVersion });
          }
          return getPackages(searchQuery, dontFetchAgain);
        } else {
          return [];
        }
      });
    }
  } else {
    return [];
  }
}

/**
 * Retrieves the linked resources collection based on the provided data.
 * @param {Object} data - The data object.
 * @param {string} performAsId - The performAsId.
 * @returns {Promise<Object|null>} - A promise that resolves to the linked resources collection or null.
 */
function getLinkedResourcesCollection(data, performAsId) {
  if (data && !data.isLocal) {
    if (data.linkedResourcesCollection) {
      return TCCCollectionService.get(data.linkedResourcesCollection.id, performAsId);
    } else {
      var searchQuery = { fq: "attribute:references:linkedToCollectionId:string==" + data.id };
      return TCCSearchService.searchCollections(
        searchQuery,
        false,
        ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
        performAsId,
      ).then(
        function (res) {
          return _.first(res);
        },
        function () {
          return null;
        },
      );
    }
  } else {
    return null;
  }
}

/**
 * Fetches collections based on user id using the TCCSearchService.searchCollections function
 * @param {{ user: IUser | undefined }} data object containing user
 * @returns list of collections for the given user
 */
function getMyCollections(data) {
  data = data || {};
  if (data.user) {
    var fqs = ["creatorId==" + data.user.id];
    var query = {
      fq: fqs.join(";"),
    };
    return TCCSearchService.searchCollections(query, true, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, data.user.id);
  } else {
    return [];
  }
}

/**
 * Fetches the collections available to user from their member organization
 * @param {*} data
 * @returns list of collections
 */
function getMyOrganizationCollections(data) {
  data = data || {};
  if (data.user && data.user.organization && data.user.organization.id) {
    var fqs = ["creatorId==" + data.user.organization.id];
    var query = {
      fq: fqs.join(";"),
    };
    return TCCSearchService.searchCollections(query, true, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, data.user.id);
  } else {
    return [];
  }
}

/**
 * Returns the collections available to user from all their organizations
 * @param {{ user: IUser }} data
 * @returns a list of collections
 */
function getMyOrganizationsCollections(data) {
  data = data || {};

  if (!!data.user && !!data.user.userOrganizations && !_.isEmpty(data.user.userOrganizations)) {
    var fq = "creator.id=in=(" + _.map(data.user?.userOrganizations, (uo) => `${uo.organization.id}`).toString() + ")";
    var query = { fq: fq };

    return TCCSearchService.searchCollections(query, true, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, data.user.id);
  } else {
    return [];
  }
}

/**
 * Returns the collections available to user from the organizations they have edit rights to (member/content editor roles)
 * @param {{ user: IUser }} data
 * @returns a list of collections
 */
function getCollectionsForMyOrganizationsWithEditRights(data) {
  data = data || {};

  if (!!data.user && !!data.user.userOrganizations && !_.isEmpty(data.user.userOrganizations)) {
    var userOrganizationsWithEditorRights = _.filter(
      data.user.userOrganizations,
      (uo) =>
        _.contains(uo.roles, OrganizationRoleEnum.CONTENT_EDITOR) || _.contains(uo.roles, OrganizationRoleEnum.MEMBER),
    );

    if (!_.isEmpty(userOrganizationsWithEditorRights)) {
      var fq =
        "creator.id=in=(" + _.map(userOrganizationsWithEditorRights, (uo) => `${uo.organization.id}`).toString() + ")";
      var query = { fq: fq };

      return TCCSearchService.searchCollections(query, true, ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION, data.user.id);
    } else {
      return [];
    }
  } else {
    return [];
  }
}

/**
 * Retrieves notifications from the TCCNotificationService.
 * @returns {Promise<INotification[]>} A promise that resolves to an array of notifications.
 */
function getNotifications() {
  return TCCNotificationService.getNotifications().then(function (res) {
    return res.notifications;
  });
}

/**
 * Fetches an organization based on the data object
 * @param {{ id: string; showBinaryAttributes?: boolean; }} data
 * @returns organization
 */
function getOrganization(data) {
  return TCCOrganizationService.get(data);
}

/**
 * Fetches a package based on given data object, either from LocalPackageService or TCCPackageService.
 * @param {{ id: string; isLocal?: boolean }} data
 * @param {string} [performAsId]
 * @returns {Promise<IEntity>} - the package
 */
function getPackage(data, performAsId) {
  data = data || {};
  if (data.isLocal) {
    return LocalPackageService.get({ id: data.id });
  } else {
    var reqData;
    if (data.recordEvent) {
      reqData = { recordEvent: true };
    }
    return TCCPackageService.get(data.id, performAsId, reqData);
  }
}

/**
 * Retrieves packages based on the provided data.
 * @param {Object} data - The data object containing parameters for package retrieval.
 * @param {boolean} dontFetchAgain - Indicates whether to fetch packages again.
 * @param {string} [performAsId] - The ID of the user performing the action.
 * @param {boolean} [showHidden] - Indicates whether to include hidden packages.
 * @returns {Promise} - A promise that resolves with the retrieved packages.
 */
function getPackages(data, dontFetchAgain, performAsId, showHidden) {
  data = data || {};
  if (data.isLocal) {
    return LocalSearchService.searchPackages({ parentId: data.id });
  } else {
    if (data.testedVersion) {
      data.filters = {};
      data.filters.testedVersions = [];
      data.filters.testedVersions.push(data.testedVersion);
    }
    var convertedAttributes = SearchAttributesConverter.toTCC(data);
    var searchQuery = {
      fq: "parentCollectionId==" + data.id,
      sortBy: convertedAttributes.sortBy,
      anyChildFq: convertedAttributes.anyChildFq,
    };

    if (showHidden) {
      searchQuery.showHidden = true;
    }

    return TCCSearchService.searchPackages(searchQuery, dontFetchAgain, performAsId);
  }
}

/**
 * Retrieves a package by its code name.
 *
 * @param {string} codeName - The code name (without prefix) of the package.
 * @returns {Promise<IEntity|null>} - A promise that resolves to the package object if found, or null if not found.
 */
async function getPackageByCodeName(codeName) {
  return TCCPackageService.getByCodeName("twh-" + codeName);
}

/**
 * Retrieves packages for the collection.
 * @param {string} collectionId - The data object containing parameters for package retrieval.
 * @param {boolean} dontFetchAgain - Indicates whether to fetch packages again.
 * @param {string} [performAsId] - The ID of the user performing the action.
 * @param {boolean} [showHidden] - Indicates whether to include hidden packages.
 * @returns {Promise} - A promise that resolves with the retrieved packages.
 */
function getPackagesForCollection(collectionId) {
  var searchQuery = {
    fq: "parentCollectionId==" + collectionId + ";(type==" + ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE + ")",
    contentType: ContentTypeEnum.TEKLA_WAREHOUSE,
    showHidden: true,
  };

  return TCCPackageService.searchPackages(searchQuery, false);
}

/**
 * Retrieves a user by their email address.
 * @param {string} email - The email address of the user.
 * @returns {IUser} - The user object.
 */
async function getUserByEmail(email) {
  return TCCUserService.getUsers({ fq: `emailAddress==${email}` }).then(function (res) {
    return _.first(res);
  });
}

/**
 * Retrieves the suspended date for a given user.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Date>} A promise that resolves to the suspended date of the user, or undefined if the user is not suspended.
 */
function getUserSuspendedData(userId) {
  return getUserLockoutEvent(userId).then(function (res) {
    if (res) {
      return res;
    } else {
      return undefined;
    }
  });
}

/**
 * Retrieves reviews for a given subject.
 * @param {IItem} subject - The subject to retrieve reviews for.
 * @returns {Array} - An array of reviews.
 */
function getReviews(subject) {
  if (!subject.isLocal) {
    if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
      return TCCCollectionService.getReviews(subject.id);
    } else {
      return TCCPackageService.getReviews(subject.id);
    }
  }
}

/**
 * Retrieves the site statistics.
 * @returns {Object} The site statistics.
 */
function getSiteStats() {
  return TCCSiteStatsDS.getStatistics();
}

/**
 * Retrieves the subscriber count for a given subject and user.
 * @param {IItem} subject - The subject to retrieve the subscriber count for.
 * @param {IUser} user - The user object.
 * @returns {Promise<number|null>} - A promise that resolves to the subscriber count or null.
 */
function getSubscriberCount(subject, user) {
  return new Promise((resolve) => {
    if (!subject.isLocal && user) {
      if (subject.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
        TCCCollectionService.getSubscribers(subject.id).then(function (res) {
          resolve(res.length);
        });
      } else {
        TCCPackageService.getSubscribers(subject.id).then(function (res) {
          resolve(res.length);
        });
      }
    } else {
      resolve(null);
    }
  });
}

/**
 * Retrieves the subscribers for a given subject.
 * @param {Object} subject - The subject for which to retrieve subscribers.
 * @returns {Promise<Array>} - A promise that resolves to an array of subscribers.
 */
function getSubscribers(subject) {
  if (!subject.isLocal) {
    return getContentSubscribers(subject).then(function (res) {
      var resources = [];
      var promises = _.map(res, function (entry) {
        if (entry.id) {
          return new Promise((resolve) => {
            TCCUserDS.getUser(entry.id).then(
              function (rawUser) {
                if (rawUser && rawUser.entries && rawUser.entries[0].masterId) {
                  TCCOrganizationService.get({ id: rawUser.entries[0].masterId }).then(function (rawOrganization) {
                    _.extend(entry, {
                      organization: {
                        id: rawOrganization.id,
                        displayName: rawOrganization.displayName,
                      },
                    });
                    resources.push(entry);
                    resolve();
                  });
                } else {
                  resources.push(entry);
                  resolve();
                }
              },
              function () {
                resolve();
              },
            );
          });
        }
      });
      return Promise.all(promises).then(function () {
        return Promise.resolve(resources);
      });
    });
  }
}

/**
 * Retrieves subscriptions and their associated resources.
 * @param {boolean} dontFetchObjects - Flag indicating whether to fetch the associated resources or not.
 * @param {string} performAsId - The ID of the user performing the action.
 * @returns {Promise<Array>} A promise that resolves to an array of resources.
 */
function getSubscriptions(dontFetchObjects, performAsId) {
  return getAllSubscriptions().then(function (res) {
    var resources = [];
    var promises = _.map(res, function (entry) {
      if (dontFetchObjects) {
        resources.push(entry);
        return Promise.resolve({});
      } else {
        return new Promise((resolve) => {
          if (entry.subjectClass === "collection") {
            TCCCollectionService.get(entry.id, performAsId).then(
              function (resource) {
                resources.push(resource);
                resolve();
              },
              function () {
                resolve();
              },
            );
          } else {
            TCCPackageService.get(entry.id, performAsId).then(
              function (resource) {
                resources.push(resource);
                resolve();
              },
              function () {
                resolve();
              },
            );
          }
        });
      }
    });
    return Promise.all(promises).then(function () {
      return Promise.resolve(resources);
    });
  });
}

/**
 * Retrieves translations for a given target.
 * @param {Object} target - The target object.
 * @returns {Promise<Record<string, ITranslationObject>>} - Translations.
 */
function getTranslations(target) {
  if (target.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
    return TCCTranslationDS.getPackageTranslations(target.id);
  } else {
    return TCCTranslationDS.getCollectionTranslations(target.id);
  }
}

/**
 * Retrieves the lockout event for a specific user.
 * @param {string} userId - The ID of the user.
 * @param {number} [offset=0] - The offset value for pagination.
 * @returns {Promise<Object>} - A promise that resolves to the lockout event object.
 */
function getUserLockoutEvent(userId, offset) {
  offset = offset || 0;

  return TCCUserLockoutDS.getUserLockouts({ offset: offset, count: 100 }).then(function (res) {
    let lockout = _.find(res, function (entry) {
      return entry.userId === userId;
    });

    if (lockout) {
      return lockout;
    } else {
      return getUserLockoutEvent(userId, offset + 100);
    }
  });
}

/**
 * Fetches a version based on given data object, either from LocalVersionService or TCCVersionService.
 * @param {{ id: string; isLocal?: boolean }} data
 * @param {string} performAsId
 * @returns the version
 */
function getVersion(data, performAsId) {
  data = data || {};
  if (data.isLocal) {
    return LocalVersionService.get({ id: data.id });
  } else {
    return TCCVersionService.get(data.id, performAsId);
  }
}

/**
 * Retrieves versions based on the provided data.
 * @param {Object} data - The data object.
 * @param {boolean} dontFetchAgain - Indicates whether to fetch versions again.
 * @param {boolean | undefined} [canSeeHidden] - Indicates whether hidden versions can be seen.
 * @param {boolean | undefined} [canSeeVersionsStillBeingScanned] - Indicates if versions that are still being scanned can be seen.
 * @returns {Promise<Array>} - A promise that resolves to an array of filtered versions.
 */
function getVersions(data, dontFetchAgain, canSeeHidden = false, canSeeVersionsStillBeingScanned = false) {
  data = data || {};
  if (data.isLocal) {
    return LocalSearchService.searchVersions({ parentId: data.id });
  } else {
    return new Promise((resolve) => {
      if (data.testedVersion) {
        data.filters = {};
        data.filters.testedVersions = [];
        data.filters.testedVersions.push(data.testedVersion);
      }
      var convertedAttributes = SearchAttributesConverter.toTCC(data);
      var searchQuery = {
        fq: "parentCollectionId==" + data.id,
        sortBy: convertedAttributes.sortBy,
        anyChildFq: convertedAttributes.anyChildFq,
      };
      if (canSeeHidden) {
        _.extend(searchQuery, {
          showHidden: true,
        });
      }
      TCCSearchService.searchVersions(searchQuery, dontFetchAgain).then(
        function (versionitems) {
          if (canSeeVersionsStillBeingScanned) {
            return resolve(versionitems);
          } else {
            const filteredVersions = _.reject(versionitems || [], (version) => {
              if (
                ScanResultsChecker.versionBinariesScanNotStarted(version) ||
                ScanResultsChecker.versionBinariesScanInProgress(version)
              ) {
                return true;
              }
              return false;
            });
            resolve(filteredVersions);
          }
        },
        function () {
          resolve();
        },
      );
    });
  }
}

/**
 * Checks if a user has subscribed to a subject.
 * @param {IItem} subject - The subject to check for subscription.
 * @param {IUser} user - The user object.
 * @returns {Promise} A promise that resolves to an object with a 'subscribed' property indicating if the user has subscribed.
 */
function hasUserSubscribed(subject, user) {
  return new Promise((resolve) => {
    if (!subject.isLocal && user) {
      getAllSubscriptions().then(function (res) {
        resolve({
          subscribed:
            res &&
            _.any(res, function (s) {
              return s.id === subject.id;
            }),
        });
      });
    } else {
      resolve([]);
    }
  });
}

/**
 * Links a package to a destination.
 * @param {Object} pack - The package to be linked.
 * @param {Object} destination - The destination to link the package to.
 * @param {IUser} user - The user performing the linking.
 * @returns {Promise} A promise that resolves with the linked collection or rejects with an error.
 */
function linkPackage(pack, destination, user) {
  return new Promise((resolve, reject) => {
    var packageList = [];
    packageList.push(pack.id);
    if (destination.isLocal) {
      reject({ err: "local resources not supported" });
    } else {
      if (destination.linkedResourcesCollection) {
        TCCCollectionDS.updateCollectionChildren(destination.linkedResourcesCollection.id, {
          childIds: packageList,
        }).then(
          function (collectionAdded) {
            resolve(collectionAdded);
          },
          function (err) {
            reject(err);
          },
        );
      } else {
        createLinkedResourcesCollection(destination, user).then(function (linkedResourcesCollection) {
          TCCCollectionDS.updateCollectionChildren(linkedResourcesCollection.id, { childIds: packageList }).then(
            function (collectionAdded) {
              return TCCAclService.cloneAcl(
                destination.id,
                "collection",
                linkedResourcesCollection.id,
                "collection",
              ).then(
                function () {
                  resolve(collectionAdded);
                },
                function (aclError) {
                  reject(aclError);
                },
              );
            },
            function (err) {
              reject(err);
            },
          );
        });
      }
    }
  });
}

/**
 * Makes an organization immutable.
 *
 * @param {string} organizationId - The ID of the organization.
 * @param {boolean} isImmutable - Indicates whether the organization should be made immutable.
 * @returns {Promise} A promise that resolves when the organization attributes are set.
 */
function makeOrganizationImmutable(organizationId, isImmutable) {
  let attributes = {
    immutability: { "--immutable": isImmutable ? { dataType: "boolean", value: true } : null },
  };

  return TCCOrganizationService.setAttributes(organizationId, attributes);
}

/**
 * Rescans a binary for viruses.
 * @param {Object} binary - The binary to be scanned.
 * @param {string} performAsId - The ID of the user performing the scan.
 * @returns {Promise} A promise that resolves to the created job.
 */
function rescanBinary(binary, performAsId) {
  return TCCJobsService.createJob(
    {
      binary: binary.id,
      jobType: "VIRUS_SCAN",
      status: "NOT_STARTED",
      progress: 0,
      state: "Recan started from Warehouse Admin ui",
    },
    performAsId,
  );
}

/**
 * Searches for packages based on the provided data.
 * @param {Object} data - The data used for searching packages.
 * @param {string} performAsId - The ID used for performing the search.
 * @returns {Promise<Array>} - A promise that resolves to an array of search results.
 */
function searchPackages(data, performAsId) {
  return new Promise((resolve, reject) => {
    data = data || {};
    var promises = [];

    function fetchLocal() {
      return new Promise((resolve) => {
        LocalSearchService.searchPackages({ q: data.q }).then(
          function (res) {
            resolve(res);
          },
          function () {
            resolve([]);
          },
        );
      });
    }
    promises.push(fetchLocal());
    if (!Settings.hideOnlineFunctionality) {
      promises.push(TCCSearchService.packageSearch(data, false, performAsId));
    }
    Promise.all(promises).then(
      function (res) {
        if (!Settings.hideOnlineFunctionality) {
          resolve(_.union(res[0], res[1]));
        } else {
          resolve(res[0]);
        }
      },
      function (err) {
        reject(err);
      },
    );
  });
}

/**
 * Searches for content based on the provided data.
 * @param {SearchTargetOptionEnum} searchTarget - The target object for the search.
 * @param {Object} data - The data used for searching packages.
 * @param {string} performAsId - The ID used for performing the search.
 * @returns {Object} - A promise that resolves to an array of search results.
 */
function searchContent(searchTarget, data, performAsId) {
  if (searchTarget === SearchTargetOptionEnum.COLLECTION) {
    return new Promise((resolve, reject) => {
      TCCSearchService.collectionSearch(data, performAsId).then(
        function (res) {
          resolve(res);
        },
        function (err) {
          reject(err);
        },
      );
    });
  } else if (searchTarget === SearchTargetOptionEnum.PACKAGE) {
    return new Promise((resolve, reject) => {
      TCCSearchService.packageSearch(data, performAsId).then(
        function (res) {
          resolve(res);
        },
        function (err) {
          reject(err);
        },
      );
    });
  }
}

/**
 * Sets an author (creator or modifier) for data object
 * @param {object} data data object
 * @param {object} author the user object
 * @param {string} fieldName creator/modifier
 * @returns data object with the creator or modifier set
 */
function setAuthor(data, author, fieldName) {
  data = data || {};
  if (_.isString(fieldName)) {
    if (author && author.id && _.isString(author.displayName)) {
      data[fieldName] = _.pick(author, "id", "displayName");
    }
  }
  return data;
}

/**
 * Sets the attributes of a content object and returns the updated content.
 * @param {Object} content - The content object to update.
 * @param {Object} attributes - The attributes to update on the content object.
 * @param {Object | null} removedAttributes - The attributes to remove from the content object.
 * @param {boolean} doNotNotify - Flag indicating whether to notify listeners of the update.
 * @param {string | undefined} performAsId - The ID of the user performing the update.
 * @returns {Promise<object>} A promise that resolves with the updated content object.
 */
function setContentAttribute(content, attributes, removedAttributes, doNotNotify, performAsId) {
  if (content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
    if (content.isLocal) {
      return LocalCollectionService.update({ ...content, attributes: { ...content.attributes, ...attributes } });
    } else {
      return TCCCollectionService.updateAttributes(
        content.id,
        NewCollectionConverter.toTCCAttributesPartial({ attributes: attributes }),
        performAsId,
      ).then(function () {
        return detectTypeAndGet(content, null);
      });
    }
  } else {
    if (content.isLocal) {
      return LocalPackageService.update({ ...content, attributes: { ...content.attributes, ...attributes } });
    } else {
      return TCCPackageService.updateAttributes(
        content.id,
        PackageConverter.toTCCAttributesPartial({ attributes: attributes, removedAttributes: removedAttributes }),
        doNotNotify,
        performAsId,
      ).then(function () {
        return detectTypeAndGet(content, null);
      });
    }
  }
}

/**
 * Sets the creator of the data object.
 * @param {Object} data - The data object to set the creator for.
 * @param {object} author - The author to set as the creator.
 * @returns {Object} - The updated data object with the creator set.
 */
function setCreator(data, author) {
  return setAuthor(data, author, "creator");
}

/**
 * Sets the modifier of the data object.
 * @param {object} data - The data object to modify.
 * @param {object} author - The author of the modification.
 * @returns {object} - The modified data object.
 */
function setModifier(data, author) {
  return setAuthor(data, author, "modifier");
}

/**
 * Sets the notification status for a given ID.
 * @param {string} id - The ID of the notification.
 * @param {string} status - The status to set for the notification.
 * @returns {Promise} A promise that resolves when the notification status is set.
 */
function setNotificationStatus(id, status) {
  return TCCNotificationService.setNotificationStatus(id, { status: status });
}

/**
 * Sets the status of notifications based on the provided item id and status.
 * @param {string | null} id - The ID of the item for which notifications should be marked with the status. If null, all notifications are marked.
 * @param {string} status - The status to set for the notifications.
 * @returns {Promise<Array>} - A promise that resolves to an array of resources.
 */
function setNotificationsStatus(id, status) {
  return TCCNotificationService.getNotifications().then(function (res) {
    var responses = [];
    var promises = _.map(
      _.filter(res.notifications, function (n) {
        if (id) {
          return n.subjectId === id && n.status !== status;
        } else {
          return n.status !== status;
        }
      }),
      function (entry) {
        return new Promise((resolve) => {
          TCCNotificationService.setNotificationStatus(entry.id, { status: status }).then(
            function (res) {
              responses.push(res);
              resolve();
            },
            function () {
              resolve();
            },
          );
        });
      },
    );
    return Promise.all(promises).then(function () {
      return Promise.resolve(responses);
    });
  });
}

/**
 * Sets the creator and modifier of the data object.
 * @param {object} data - The data object to be modified.
 * @param {object} creator - The creator value to be set.
 * @param {object} modifier - The modifier value to be set.
 * @returns {object} - The modified data object.
 */
function setCreatorAndModifier(data, creator, modifier) {
  setCreator(data, creator);
  return setModifier(data, modifier);
}

/**
 * Sets translations for a target object.
 * @param {Object} target - The target object.
 * @param {Record<string, ITranslationObject>} translations - The translations to set.
 * @returns {Promise} - A promise that resolves when the translations are set.
 */
function setTranslations(target, translations) {
  if (target.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
    return TCCTranslationDS.setPackageTranslations(target.id, translations);
  } else {
    return TCCTranslationDS.setCollectionTranslations(target.id, translations);
  }
}

/**
 * Subscribes to a subject.
 * @param {IItem} subject - The subject to subscribe to.
 * @returns {Promise} A promise that resolves when the subscription is successful.
 */
function subscribe(subject) {
  if (!subject.isLocal) {
    var subject = {
      id: subject.id,
      subjectClass: ModelTypeConverter.toSubjectClass(subject.type),
    };
    return TCCUserService.subscribe(subject);
  }
}

/**
 * Suspends a user.
 * @param {IUser} user - The user to suspend.
 * @returns {Promise} - A promise that resolves when the user is suspended.
 */
function suspendUser(user) {
  var userLockoutEntry = {
    emailAddress: user.email,
    externalId: user.externalId,
    trimbleId: user.trimbleId,
    userId: user.id,
  };

  return TCCUserService.suspend(userLockoutEntry);
}

/**
 * Transfers a package from source collection to another collection.
 * @param {Object} pack - The package to be transferred.
 * @param {Object} source - The collection from which the package is being transferred.
 * @param {Object} destination - The collection to which the package is being transferred.
 * @param {Object} user - The user performing the transfer.
 * @returns {Promise} A promise that resolves when the transfer is successful, or rejects with an error.
 */
function transferPackage(pack, source, destination, user) {
  return new Promise((resolve, reject) => {
    if (!pack || !source || !destination || pack.isLocal || !source.creator || !destination.creator) {
      reject();
    } else {
      var targetClass = "package";
      var oldCollectionData = { childId: pack.id, childClass: "collection", parentCollectionId: pack.collection.id };
      TCCPackageService.removeFromCollection(oldCollectionData).then(
        function () {
          var newCollectionData = { childId: pack.id, childClass: targetClass, parentCollectionId: destination.id };
          TCCPackageService.addToCollection(newCollectionData).then(
            function () {
              runInAction(() => {
                pack.collection = destination;
                pack.visibility = destination.visibility;
                pack.isHidden = destination.isHidden;
                pack.isBanned = destination.isBanned;
                if (pack.creator.id !== destination.creator.id) {
                  pack.creatorId = destination.creator.id;
                }
              });
              setPackage(pack, user, true).then(
                function () {
                  if (pack.visibility === "private") {
                    TCCAclService.cloneAclFromCollection(destination, pack);
                  }
                  resolve();
                },
                function (err) {
                  reject(err);
                },
              );
            },
            function () {
              TCCPackageService.addToCollection(oldCollectionData).then(
                function () {
                  getPackage({ id: pack.id }, user.id).then(
                    function (res) {
                      resolve(res);
                    },
                    function (err) {
                      reject(err);
                    },
                  );
                },
                function (err) {
                  reject(err);
                },
              );
            },
          );
        },
        function (err) {
          reject(err);
        },
      );
    }
  });
}

function translate(data) {
  return TCCTranslationDS.translate(data);
}

/**
 * Removes a package from a collection.
 * @param {IEntity} pack - The package to be unlinked.
 * @param {ICollection} linkedToResource - The resource to which the package is linked.
 * @returns {Promise} A promise that resolves when the package is successfully unlinked.
 */
function unlinkPackage(pack, linkedToResource) {
  var data = { childId: pack.id, parentCollectionId: linkedToResource.id };
  return TCCPackageService.removeFromCollection(data);
}

/**
 * Unsubscribes from a subject.
 * @param {IItem} subject - The subject to unsubscribe from.
 * @returns {Promise} - A promise that resolves when the unsubscription is complete.
 */
function unsubscribe(subject) {
  if (!subject.isLocal) {
    var subject = {
      subjectId: subject.id,
      subjectClass: ModelTypeConverter.toSubjectClass(subject.type),
    };
    return TCCUserService.unSubscribe(subject);
  }
}

/**
 * Unsuspends a user by their lockout ID.
 * @param {string} lockoutId - The lockout ID of the user to unsuspend.
 * @returns {Promise} A promise that resolves when the user is unsuspended.
 */
function unsuspendUser(lockoutId) {
  return TCCUserService.unSuspend(lockoutId);
}

function uploadBinary(subject, binary, type, forceOldUploadStrategyForLocal) {
  return Uploader.upload(subject, binary, type, forceOldUploadStrategyForLocal);
}

/**
 * Updates the state of a binary scan job.
 * @param {{ jobId: string; status: string; resultCode: string; binary: IFileItem | null }} data - The data object containing the job information.
 * @returns {Promise} A promise that resolves with the updated job object.
 */
function updateBinaryScanJobState(data) {
  return TCCJobsService.updateJob(data.jobId, {
    binary: data.binary.id,
    jobType: "VIRUS_SCAN",
    status: data.status,
    resultCode: data.resultCode,
    progress: 100,
    state: "Status overridden from Warehouse Admin ui",
  });
}

/**
 * Updates a collection based on the provided data and user.
 * If the data is local, it updates the collection using the LocalCollectionService.
 * If the data is not local, it updates the collection using the TCCCollectionService.
 * @param {ICollection} data - The data used to update the collection.
 * @param {IUser} user - The user performing the update.
 * @returns {Promise} - A promise that resolves with the updated collection.
 */
function updateCollection(data, user) {
  if (data.isLocal) {
    return LocalCollectionService.update(data);
  } else {
    data.modifiedAt = new Date();
    setModifier(data, user);
    return TCCCollectionService.update(data);
  }
}

/**
 * Updates the visibility of a collection recursively.
 * @param {Object} data - The data object representing the collection.
 * @param {IUser} user - The user performing the update.
 * @returns {Promise} - A promise that resolves when the visibility update is complete.
 */
function updateCollectionVisibilityRecursively(data, user) {
  data.modifiedAt = new Date();
  setModifier(data, user);
  return TCCCollectionService.updateVisibilityRecursivelyExcludingBanned(data);
}

/**
 * Updates the given package with new content, sets user as the modifier
 * @param {Object} data new data for package
 * @param {IOrganization | IUser | undefined} user modifier
 * @param {boolean} useRecursive should set visibility in children
 * @returns {Promise<IEntity>} updated package
 */
function updatePackage(data, user, useRecursive = undefined) {
  data.modifiedAt = new Date();
  setModifier(data, user);
  return TCCPackageService.update(data, useRecursive);
}

/**
 * Updates the related content of a subject.
 *
 * @param {IEntity} subject - The subject to which the related content will be updated.
 * @param {any} relatedContent - The related content to be set.
 * @param {string} [performAsId] - The ID of the user performing the action.
 * @returns {Promise} A promise that resolves when content has been added.
 */
function updateRelatedContent(subject, relatedContent, performAsId) {
  if (subject.isLocal) {
    return LocalPackageService.update({ ...subject, related: relatedContent });
  } else {
    var attributes = { references: { relatedContent: { dataType: "stringArray", value: relatedContent } } };
    return TCCPackageService.updateAttributes(subject.id, attributes, true, performAsId);
  }
}

/**
 * Updates the given package (with PUT), sets user as the modifier
 * @param {IEntity | IBatchEntity | IEntityPayload} data new data for package
 * @param {IOrganization | IUser | undefined} user modifier
 * @param {boolean} useRecursive should set visibility in children
 * @returns
 */
function setPackage(data, user, useRecursive = undefined) {
  if (data.isLocal) {
    return LocalPackageService.update(data);
  } else {
    data.modifiedAt = new Date();
    setModifier(data, user);
    return TCCPackageService.replace(data, useRecursive);
  }
}

/**
 * Updates the version of a repository.
 * @param {Object} data - The data of the repository.
 * @param {IModifier} user - The user performing the update.
 * @returns {Promise} - A promise that resolves with the updated version.
 */
function updateVersion(data, user) {
  if (data.isLocal) {
    return LocalVersionService.update(data);
  } else {
    data.modifiedAt = new Date();
    setModifier(data, user);
    return TCCVersionService.update(data);
  }
}

/**
 * Represents a repository for managing packages and collections.
 */
export const NewRepository = {
  addBinaries: addBinaries,
  addBinary: addBinary,
  addBlob: addBlob,
  addCodeNameForPackage: addCodeNameForPackage,
  addReleaseNote: addReleaseNote,
  addReview: addReview,
  addThumbnail: addThumbnail,
  add3DThumbnail: add3DThumbnail,
  archiveCollection: archiveCollection,
  archivePackage: archivePackage,
  archiveVersion: archiveVersion,
  createAnalystUsersContainerCollection: createAnalystUsersContainerCollection,
  createCollection: createCollection,
  createLinkedResourcesCollection: createLinkedResourcesCollection,
  createPackage: createPackage,
  createVersion: createVersion,
  deleteBinaries: deleteBinaries,
  deleteCollection: deleteCollection,
  // deleteContentAttribute: deleteContentAttribute,
  deletePackage: deletePackage,
  deleteReleaseNote: deleteReleaseNote,
  deleteReview: deleteReview,
  deleteThumbnail: deleteThumbnail,
  delete3dThumbnail: delete3dThumbnail,
  deleteUser: deleteUser,
  deleteVersion: deleteVersion,
  getAllCollections: getAllCollections,
  // getAllCollectionsRaw: getAllCollectionsRaw,
  getAllPackages: getAllPackages,
  // getAllPackagesRaw: getAllPackagesRaw,
  getAllSubscriptions: getAllSubscriptions,
  getBannedPackages: getBannedPackages,
  getBinaries: getBinaries,
  getCollection: getCollection,
  getCollections: getCollections,
  getCollectionsWithTotalCount: getCollectionsWithTotalCount,
  getCollectionsByCreator: getCollectionsByCreator,
  getCollectionsWithLinkRights: getCollectionsWithLinkRights,
  getCollectionsWithTransferRights: getCollectionsWithTransferRights,
  getCollectionsWithUploadRights: getCollectionsWithUploadRights,
  getContentSubscribers: getContentSubscribers,
  getImmutableOrganizations: getImmutableOrganizations,
  getInfectedEntities: getInfectedEntities,
  getInvalidPackages: getInvalidPackages,
  getLinkedResources: getLinkedResources,
  getMyCollections: getMyCollections,
  getMyOrganizationCollections: getMyOrganizationCollections,
  getNotifications: getNotifications,
  getOrganization: getOrganization,
  getPackage: getPackage,
  getPackageByCodeName: getPackageByCodeName,
  getPackages: getPackages,
  getPackagesForCollection: getPackagesForCollection,
  getUserByEmail: getUserByEmail,
  getUserSuspendedData: getUserSuspendedData,
  // getReviews: getReviews,
  getSiteStats: getSiteStats,
  getSubscriberCount: getSubscriberCount,
  // getSubscribers: getSubscribers,
  getSubscriptions: getSubscriptions,
  getTranslations: getTranslations,
  getVersion: getVersion,
  getVersions: getVersions,
  hasUserSubscribed: hasUserSubscribed,
  linkPackage: linkPackage,
  makeOrganizationImmutable: makeOrganizationImmutable,
  rescanBinary: rescanBinary,
  searchPackages: searchPackages,
  searchContent: searchContent,
  setContentAttribute: setContentAttribute,
  // setNotificationStatus: setNotificationStatus,
  setNotificationsStatus: setNotificationsStatus,
  setPackage: setPackage,
  setTranslations: setTranslations,
  subscribe: subscribe,
  suspendUser: suspendUser,
  transferPackage: transferPackage,
  // translate: translate,
  unlinkPackage: unlinkPackage,
  unsubscribe: unsubscribe,
  unsuspendUser: unsuspendUser,
  updateBinaryScanJobState: updateBinaryScanJobState,
  updateCollection: updateCollection,
  updateCollectionVisibilityRecursively: updateCollectionVisibilityRecursively,
  updatePackage: updatePackage,
  updateRelatedContent: updateRelatedContent,
  updateVersion: updateVersion,
};
