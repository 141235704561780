import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { RootContext, RootStore } from "../../stores/rootStore";
import { Settings } from "../../config/Settings";

/**
 * Renders individual boost items.
 * Fetches their values from local storage, updates them if slider is moved.
 */
const BoostItem = observer(({ ...props }: { header: string; itemName: string; step: number }) => {
  const rootStore = useContext(RootContext);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const relevancy = localStorage.getItem(Settings.searchRelevancyStorage + "." + props.itemName);
    if (relevancy) {
      setValue(Number(relevancy));
    }
  });

  let bubblePosition = 0;
  if (value > 0) {
    bubblePosition = ((value - 1) / 4) * 100;
  }
  const bubbleStyle = {
    left: `${bubblePosition}%`,
  };

  function handleChange(event) {
    const newValue = event.target.value;
    setValue(newValue);
    localStorage.setItem(Settings.searchRelevancyStorage + "." + props.itemName, newValue.toString());
  }

  return (
    <div className="boost-item">
      <div className="boost-item-header">
        {rootStore.getTranslation("profile.admin.search_relevancy." + props.header)}
      </div>
      <div className="rzslider">
        <input
          type="range"
          id="slider"
          className="slider-line"
          data-testid={props.header + "_input"}
          min="1"
          max="5"
          value={value}
          onChange={handleChange}
          step={props.step}
        />
        <output htmlFor="slider" style={bubbleStyle} className="slider-handle">
          <span>{value}</span>
        </output>
      </div>
    </div>
  );
});

/**
 * Main component for the "Adjust relevancy settings" section of Admin page.
 */
export const AdjustRelevancySettings = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <div className="search-relevancy-settings" data-testid="relevancySettingsComponent">
      <div className="admin-header">{rootStore.getTranslation("profile.admin.search_relevancy.header")}</div>
      <div className="search-relevancy-fields">
        <BoostItem header="title" itemName="titleBoost" step={0.1} />
        <BoostItem header="title_translations" itemName="titleTranslationsBoost" step={0.1} />
        <BoostItem header="description" itemName="descriptionBoost" step={0.1} />
        <BoostItem header="description_translations" itemName="descriptionTranslationsBoost" step={0.1} />
        <BoostItem header="details" itemName="detailsBoost" step={0.1} />
        <BoostItem header="details_translations" itemName="detailsTranslationsBoost" step={0.1} />
        <BoostItem header="views_popularity" itemName="viewsPopularityFactorBoost" step={1} />
        <BoostItem header="downloads_popularity" itemName="downloadsPopularityFactorBoost" step={1} />
      </div>
    </div>
  );
});
