import * as React from "react";
import { RootContext } from "../../stores/rootStore";
import { useContext, useState } from "react";
import { TranslatedHtml } from "../../components/TranslatedHtml";

/**
 * A component that displays videos section on landing page.
 */
export const Videos = () => {
  const store = useContext(RootContext);
  const [playVideo, setPlayVideo] = useState(false);

  function renderVideo() {
    setPlayVideo(true);
  }

  function getTitle() {
    return store.getTranslation("landing.videos.title_text");
  }

  function getDesciptionKey() {
    return "landing.videos.description";
  }

  function getInlineStyles() {
    return {
      backgroundImage: `url(${store.getTranslation("landing.videos.img")})`,
    };
  }

  return (
    <section className="videos" data-testid="videos">
      <div className="content-area">
        <div className="videos-link">
          <div>
            {playVideo ? (
              <div>
                <iframe allow="autoplay" src={store.getTranslation("landing.videos.url")} />
              </div>
            ) : (
              <a onClick={renderVideo}>
                <link rel="preload" as="image" href="https://i.ytimg.com/vi/0IbAwIU_WgU/hqdefault.jpg" />
                <div
                  className="video-item false"
                  style={getInlineStyles()}
                  data-title="Streamline your work with Tekla Structures"
                >
                  <div className="play-btn"></div>
                </div>
              </a>
            )}
          </div>
        </div>
        <div className="videos-text">
          <div className="title">{getTitle()}</div>
          <div className="description">
            <TranslatedHtml entry={getDesciptionKey()} />
          </div>
        </div>
      </div>
    </section>
  );
};
