import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootStore } from "../../stores/rootStore";
import { OrganizationPageStoreContext, OrganizationPageStore } from "../organizationPageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { Dialog } from "../../dialogs/Dialog";
import { AddCommentDialog } from "../../dialogs/comments/AddCommentDialog";

/**
 * Component that displays add comment button.
 */
export const AddComment = observer(() => {
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const dialog: DialogStore = new DialogStore();
  const rootStore: RootStore = orgStore.getRootStore();

  async function addComment() {
    dialog.open();
  }

  return (
    <DialogContext.Provider value={dialog}>
      <button className="button-primary button-add-comment" onClick={addComment} data-testid="addCommentButton">
        {rootStore.getTranslation("organization.edit.add_comment")}
      </button>
      <Dialog content={<AddCommentDialog subject={orgStore.getOrganization()!} />} additionalClass="comment-dialog" />
    </DialogContext.Provider>
  );
});
