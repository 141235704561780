import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";
import { SearchDataConverter } from "../converters/SearchDataConverter";

export const TCCEntityDS = {
  searchEntities: function (data, dontUnwrap) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/entities", SearchDataConverter.addParameters(data)),
      false,
      dontUnwrap,
    );
  },
  createEntity: function (data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPostWithParams("/warehouse/v1.0/entities", data, { performAs: performAsId }),
    );
  },
  updateEntity: function (data) {
    return ResponseProcessor.process(RequestBuilder.doPut("/warehouse/v1.0/entities/" + data.id, data));
  },
  getEntityAttributes: function (entityId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/entities/" + entityId + "/attributes", data),
    );
  },
  setEntityAttributes: function (entityId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/entities/" + entityId + "/attributes", data),
    );
  },
  getEntity: function (entityId, data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/entities/" + entityId, data));
  },
  deleteEntity: function (data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/entities/" + data.id, { performAs: performAsId }),
    );
  },
  getAcl: function (versionId, data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/entities/" + versionId + "/acl", data));
  },
  setAcl: function (versionId, data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/entities/" + versionId + "/acl", data, { performAs: performAsId }),
    );
  },
  getBinaries: function (versionId, showAttributes) {
    var params = { showAttributes: showAttributes || true };
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/entities/" + versionId + "/binaries", params));
  },
  getBinary: function (versionId, binaryName, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/entities/" + versionId + "/binaries/" + binaryName, data),
    );
  },
  updateBinary: function (versionId, data, file, fileName) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/entities/" + versionId + "/binaries/" + fileName, data, file),
    );
  },
  copyBinary: function (targetVersionId, binaryData, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPostWithSourceHeader(
        "/warehouse/v1.0/entities/" + targetVersionId + "/binaries/" + binaryData.reference,
        binaryData.url,
        { performAs: performAsId },
      ),
    );
  },
  deleteBinary: function (versionId, binaryName) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/entities/" + versionId + "/binaries/" + binaryName),
    );
  },
  createBinary: function (versionId, data, file, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.upload("/warehouse/v1.0/entities/" + versionId + "/binaries", data, file, {
        performAs: performAsId,
      }),
    );
  },
  createBinaryWithMime: function (versionId, data, file, mimeType) {
    return ResponseProcessor.process(
      RequestBuilder.upload2("/warehouse/v1.0/entities/" + versionId + "/binaries", data, file, mimeType),
    );
  },
};
