import { action, computed, observable, ObservableMap, observe, runInAction, makeObservable } from "mobx";
import { createContext } from "react";
import Cookies from "js-cookie";
import _ from "underscore";
import { startsWith } from "underscore.string";

import { NewRepository } from "../js/services/NewRepository";
import { TCCUserDS } from "../js/data-source/TCCUserDS";
import { TCCOrganizationService } from "../js/services/TCCOrganizationService";
import { SearchResultSetsView } from "../js/factories/SearchResultSetsView";
import { TCCFeatureToggleDS } from "../js/data-source/TCCFeatureToggleDS";
import { LocalSearchDataSource } from "../js/data-source/LocalSearchDataSource";
import { TCCSearchDataSource } from "../js/data-source/TCCSearchDataSource";

import { createStoreContext, RootStore } from "../stores/rootStore";
import { AbstractAsyncStore } from "../stores/abstractAsyncStore";
import { SearchPageSettingsStore } from "../stores/searchPageSettingsStore";
import { LocalServiceStore } from "../stores/localServiceStore";

import {
  FiltersToTCC,
  ICollection,
  IDropdownOption,
  IDropdownOptionGroup,
  IEntity,
  IFeatureToggle,
  IFilter,
  IGetResourcePath,
  IOrganization,
  ISortSettingsStore,
} from "../models/dataModel";
import {
  ResultSetEnum,
  AvailableAttributes,
  SearchFilterTypeEnum,
  SearchPageOrderEnum,
  SearchTargetOptionEnum,
  AvailableSelectors,
} from "../models/enums";

import { performanceCookiesAreEnabled, sortArray } from "../utils/functions";
import { metadataConstants } from "../constants/MetadataConstants";
import { metadataLabels } from "../utils/MetadataLabels";
import { Settings } from "../config/Settings";
import { OrganizationFilterAttributesConverter } from "../utils/converters/OrganizationFilterAttributesConverter";
import { TCCSearchService } from "js/services/TCCSearchService";
import { FilterConverter } from "utils/converters/FilterConverter";

/**
 * Search filter
 */
export interface ISearchFilter {
  activated: boolean;
  filterType: SearchFilterTypeEnum;
  option: IDropdownOption;
}

/**
 * Creator filter
 */
export interface IModifierFilter {
  organizationId: string;
  organizationName?: string;
}

/**
 * Available filters map's record type
 */
interface AvailableFiltersMap {
  [name: string]: IDropdownOption[] | Array<IDropdownOptionGroup | IDropdownOption>;
}

/**
 * Search page mobx store
 */
export class SearchPageStore extends AbstractAsyncStore implements ISortSettingsStore, IGetResourcePath {
  /**
   * Map of available filters
   */
  private availableFilters: AvailableFiltersMap = {};

  /**
   * Creator filter
   */
  @observable private creatorFilter: IModifierFilter | undefined;

  /**
   * Property that stores eclusion filters section state. True if open.
   */
  @observable private excludeFiltersOpen = false;

  /**
   * List of selected filters (also excluded filters).
   */
  @observable private filters: ObservableMap = observable.map();

  /**
   * Geolocation to be used on adaptive searches
   */
  @observable private geoLocation = "";

  /**
   * Item id
   */
  private itemId: string | undefined;

  /**
   * Results list size to load on initial load
   */
  private listSizeToLoad: number = SearchPageStore.DEFAULT_PAGE_SIZE;

  /**
   * True if local resources are being loaded
   */
  @observable private localDataBeingLoaded = false;

  /**
   * True if in narrow mode.
   */
  @observable private narrowSearchMode = false;

  /**
   * Organization id
   */
  private organizationId: string | undefined;

  /**
   * Organizations
   */
  @observable private organizations: IOrganization[] = [];

  /**
   * True if organizations are being loaded
   */
  @observable private organizationsBeingLoaded = false;

  /**
   * Total count of organizations
   */
  @observable private organizationsTotal = 0;

  /**
   * Result set
   */
  @observable private resultSet: ResultSetEnum = ResultSetEnum.TCC;

  /**
   * Results map
   */
  @observable private results: ObservableMap = observable.map();

  /**
   * Page size
   */
  @observable private resultsPerPage: number = SearchPageStore.DEFAULT_PAGE_SIZE;

  /**
   * Search target
   */
  @observable private searchTarget: SearchTargetOptionEnum = SearchTargetOptionEnum.PACKAGE;

  /**
   * Query string
   */
  @observable private searchTerm = "";

  /**
   * True if TCC resources are being loaded
   */
  @observable private tccDataBeingLoaded = false;

  /**
   * Should use personalized search
   */
  @observable private useAdaptiveSearch = false;

  /**
   * Property that marks if infinite scrolling should be on.
   */
  @observable private useInfiniteScroll = true;

  /**
   * Default result list size
   */
  public static readonly DEFAULT_PAGE_SIZE = 36;

  /**
   * Excluded filter prefix
   */
  public static readonly EXCLUDED_FILTER_PREFIX = "excl_";

  /**
   * List of filter categories
   */
  public static readonly FILTER_TYPES = [
    AvailableAttributes.SW_PRODUCTS,
    AvailableAttributes.SW_VERSIONS,
    AvailableAttributes.ITEM_TYPE_CATEGORIES,
    AvailableAttributes.LOCATIONS,
    AvailableAttributes.LANGUAGES,
    AvailableAttributes.USE_CATEGORIES,
    AvailableAttributes.MEASUREMENT_UNITS,
  ];

  /**
   * A constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.setAvailableFilters();
    this.resetFilters(false);

    if (!this.isInOfflineMode()) {
      const localService: LocalServiceStore = this.rootStore.getLocalServiceStore();
      observe(localService, () => {
        runInAction(() => {
          if (this.resultSet == ResultSetEnum.LOCAL && !localService.isLocalServiceAccessible()) {
            this.resultSet = ResultSetEnum.TCC;
            if (this.searchTarget == SearchTargetOptionEnum.ORGANIZATION) {
              this.searchTarget = SearchTargetOptionEnum.PACKAGE;
            }
            this.sync();
          }
        });
      });
    }
  }

  /**
   * Returns number of selected filters.
   */
  @computed
  public get filtersSize(): number {
    return SearchPageStore.FILTER_TYPES.map((k) => (this.filters.get(k) || []).length).reduce((a, b) => a + b, 0);
  }

  /**
   *  Method for getting selected sort
   *  @returns sort that is currently used
   */
  @computed
  public get resultListOrder(): SearchPageOrderEnum {
    return this.getSearchPageSettingsStore().resultListOrder;
  }

  @computed
  private get listSize(): number {
    let length = 0;
    if (this.resultSet == ResultSetEnum.TCC) {
      if (this.searchTarget == SearchTargetOptionEnum.ORGANIZATION) {
        length = this.getOrganizationsCount();
      } else {
        length = this.getResultsCount(ResultSetEnum.TCC);
      }
    } else {
      length = this.getResultsCount(ResultSetEnum.LOCAL);
    }
    return length || SearchPageStore.DEFAULT_PAGE_SIZE;
  }

  @computed
  private get selectableItems() {
    return _.select(this.getResults(ResultSetEnum.TCC), (item) => this.canSelect(item));
  }

  /**
   * Adds all items to shopping cart
   */
  public async addAllItemsToShoppingCart(): Promise<void> {
    const shoppingCart = this.rootStore.getShoppingCartStoreCache();
    await this.fetchRemainingTCCResults();
    shoppingCart.addAllToCart(this.selectableItems);
  }

  /**
   * Add filter
   * @param namespace namespace
   * @param option filter to add
   */
  @action
  public addFilter(namespace: AvailableAttributes, option: IDropdownOption) {
    if (!this.findFilterInTheNamespace(namespace, option.value)) {
      this.filters.get(namespace).push({
        filterType: SearchFilterTypeEnum.INCLUDE,
        option: option,
        activated: false,
      });
      this.sync();
      this.activateFilter(this.findFilterInTheNamespace(namespace, option.value));
    }
  }

  /**
   * Returns true if there's more organization to load
   */
  public areMoreOrganizationsToLoad(): boolean {
    return this.getOrganizationsCount() < this.organizationsTotal;
  }

  /**
   * Return true if organizations are currently being loaded
   */
  public areOrganizationsBeingLoaded(): boolean {
    return this.organizationsBeingLoaded;
  }

  /**
   * Add filter to excluded ones
   * @param namespace namespace
   * @param option filter to be added
   */
  @action
  public excludeFilter(namespace: AvailableAttributes, option: IDropdownOption) {
    if (!this.findFilterInTheNamespace(namespace, option.value)) {
      this.filters.get(namespace).push({
        filterType: SearchFilterTypeEnum.EXCLUDE,
        option: option,
        activated: false,
      });
      this.sync();
      this.activateFilter(this.findFilterInTheNamespace(namespace, option.value));
    }
  }

  /**
   * Fetches data
   */
  @action
  public async fetchData(): Promise<ObservableMap> {
    return this.fetchResults();
  }

  /**
   * Fetches more organizations
   */
  @action
  public async fetchMoreOrganizations(): Promise<IOrganization[]> {
    this.organizationsBeingLoaded = true;

    const organizationQuery = {
      offset: this.getOrganizationsCount(),
      count: SearchPageStore.DEFAULT_PAGE_SIZE,
      sortBy: this.getOrganizationsSortBy(),
      calculateTotal: true,
      fq: OrganizationFilterAttributesConverter.toTCC({
        organizationNameFilter: this.searchTerm,
      }),
    };
    const res = await TCCSearchService.searchOrganizations(organizationQuery);

    runInAction(() => {
      this.organizations = this.organizations.concat(res.entries);
      this.organizationsTotal = res.total;
      this.organizationsBeingLoaded = false;
    });

    return this.organizations;
  }

  /**
   * Fetches next page
   * @param resultSet result set
   * @param pageSize page size
   */
  @action
  public async fetchMoreResults(
    resultSet: ResultSetEnum = ResultSetEnum.TCC,
    pageSize: number = SearchPageStore.DEFAULT_PAGE_SIZE,
  ): Promise<ObservableMap> {
    this.loading = true;
    const nextPageResults = await SearchResultSetsView.getDataSource(resultSet).loadNextPage(pageSize);
    runInAction(() => {
      this.results.set(resultSet, this.results.get(resultSet).concat(nextPageResults));
      this.loading = false;
    });
    return this.results;
  }

  /**
   * Fetches organizations
   * @param endRow rows count
   */
  @action
  public async fetchOrganizations(endRow: number = SearchPageStore.DEFAULT_PAGE_SIZE): Promise<IOrganization[]> {
    if (endRow > 99) {
      return this.fetchOrganizationsByPaging(endRow);
    } else {
      this.organizationsBeingLoaded = true;
      const organizationQuery = {
        offset: 0,
        count: endRow,
        sortBy: this.getOrganizationsSortBy(),
        calculateTotal: true,
        fq: OrganizationFilterAttributesConverter.toTCC({
          organizationNameFilter: this.searchTerm,
        }),
      };

      try {
        const res = await TCCSearchService.searchOrganizations(organizationQuery);

        runInAction(() => {
          this.organizations = res.entries;
          this.organizationsTotal = res.total;
          this.organizationsBeingLoaded = false;
          this.dataFetched = true;
        });
      } catch {
        runInAction(() => {
          this.organizationsBeingLoaded = false;
        });
      }

      return this.organizations;
    }
  }

  /**
   * Fetches organizations (by paging)
   * @param listSize list size
   * @param startRow first row number
   */
  @action
  public async fetchOrganizationsByPaging(listSize: number, startRow = 0): Promise<IOrganization[]> {
    const count = Math.min(listSize - startRow, 99);

    this.organizationsBeingLoaded = true;
    const organizationQuery = {
      offset: startRow,
      count: count,
      sortBy: this.getOrganizationsSortBy(),
      calculateTotal: true,
      fq: OrganizationFilterAttributesConverter.toTCC({
        organizationNameFilter: this.searchTerm,
      }),
    };
    const res = await TCCOrganizationService.search(organizationQuery);
    runInAction(() => {
      this.organizations = this.organizations.concat(res.entries);
      this.organizationsTotal = res.total;
      if (this.getOrganizationsCount() < listSize) {
        this.fetchOrganizationsByPaging(listSize, this.getOrganizationsCount());
      } else {
        this.organizationsBeingLoaded = false;
        this.dataFetched = true;
      }
    });
    return this.organizations;
  }

  /**
   * Fetches data
   * @param pageSize Page size
   * @param recordEvent
   */
  @action
  public async fetchResults(
    pageSize: number = SearchPageStore.DEFAULT_PAGE_SIZE,
    recordEvent = false,
  ): Promise<ObservableMap> {
    this.loading = true;
    this.localDataBeingLoaded = true;
    this.tccDataBeingLoaded = true;

    await SearchResultSetsView.loadInitialPage(
      this.searchTerm.replace(/\:/g, ""),
      pageSize,
      this.getSearchPageSettingsStore().resultListOrder,
      this.stateToSearchParams(),
      this.creatorFilter ? this.creatorFilter.organizationId : null,
      this.searchTarget,
      null,
      recordEvent,
    );

    runInAction(() => {
      this.results.set(ResultSetEnum.TCC, SearchResultSetsView.getDataSource(ResultSetEnum.TCC).getResults());
      this.results.set(ResultSetEnum.LOCAL, SearchResultSetsView.getDataSource(ResultSetEnum.LOCAL).getResults());
      this.loading = false;
      this.dataFetched = true;
      this.localDataBeingLoaded = false;
      this.tccDataBeingLoaded = false;
    });
    return this.results;
  }

  /**
   * Retrieves the available filters for a given namespace.
   * If the namespace is VERSION, the available filters are filtered based on the selected product filters.
   * If no product filters are selected, all available filters are returned.
   * If product filters are selected, only the filters compatible with the selected product filters are returned.
   * If exclusion product filters are selected, the product versions that are excluded are not returned.
   * @param namespace - The namespace of the filter.
   * @returns An array of IDropdownOption or IDropdownOptionGroup representing the available filters.
   */
  public getAvailableFilters(namespace: AvailableAttributes): Array<IDropdownOption | IDropdownOptionGroup> {
    return namespace === AvailableAttributes.SW_VERSIONS
      ? _.filter(
          this.availableFilters[AvailableAttributes.SW_VERSIONS],
          (option: IDropdownOption | IDropdownOptionGroup) => {
            const includeProductFilters = this.filters
              .get(AvailableAttributes.SW_PRODUCTS)
              .filter((filter: ISearchFilter) => filter.filterType == SearchFilterTypeEnum.INCLUDE);

            const excludeProductFilters = this.filters
              .get(AvailableAttributes.SW_PRODUCTS)
              .filter((filter: ISearchFilter) => filter.filterType == SearchFilterTypeEnum.EXCLUDE);

            const versionShouldBeExcluded =
              !!excludeProductFilters &&
              _.includes(
                _.map(excludeProductFilters, (p: ISearchFilter) => p.option.label),
                option.label,
              );

            const versionShouldBeIncluded =
              (option as IDropdownOption).value == "NVS" ||
              includeProductFilters.length === 0 ||
              _.includes(
                _.map(includeProductFilters, (p: ISearchFilter) => p.option.label),
                option.label,
              );

            return versionShouldBeExcluded ? false : versionShouldBeIncluded;
          },
        )
      : this.availableFilters[namespace];
  }

  /**
   * Returns creator filter
   */
  public getCreatorFilter(): IModifierFilter | undefined {
    return this.creatorFilter;
  }

  /**
   * Returns true if exclusion filters section is visible
   */
  public getExcludeFiltersOpen(): boolean {
    return this.excludeFiltersOpen;
  }

  /**
   * Returns selected filters
   * @param namespace namespace
   */
  public getFilters(namespace: AvailableAttributes): ISearchFilter[] {
    return this.filters.get(namespace);
  }

  /**
   * Returns selected geo location
   */
  public getGeoLocation(): string {
    return this.geoLocation;
  }

  /**
   * Returns item id (entity or collection id).
   */
  public getItemId(): string | undefined {
    return this.itemId;
  }

  /**
   * Returns organizations id
   */
  public getOrganizationId(): string | undefined {
    return this.organizationId;
  }

  /**
   * Returns number of current organizations list
   */
  public getOrganizationsCount(): number {
    return _.size(this.organizations);
  }

  /**
   * Returns sorted organizations list
   */
  public getOrganizationsSorted(): IOrganization[] {
    if (this.resultListOrder) {
      return sortArray(this.organizations, this.getOrganizationsOrderBy());
    } else {
      return this.organizations;
    }
  }

  public getOrganizationsTotalCount(): number {
    return this.organizationsTotal;
  }

  /**
   * Method that handles routing state when clicked in resource
   */
  @action
  public getResourcePath(resource: IEntity | ICollection): string {
    let state;
    if (this.entityTypeGuard(resource)) {
      state = resource.isLocal ? "/catalog/localdetails/" : "/catalog/details/";
    } else {
      state = resource.isLocal ? "/collections/local/" : "/collections/online/";
    }
    return state + resource.id;
  }

  /**
   * Returns result set
   */
  public getResultSet(): ResultSetEnum {
    return this.resultSet;
  }

  /**
   * Returns results
   * @param resultSet result set
   */
  public getResults(resultSet: ResultSetEnum = ResultSetEnum.TCC): any[] {
    return this.results.get(resultSet);
  }

  /**
   * Returns current result set size for selected result set.
   * @param resultSet result set
   */
  public getResultsCount(resultSet: ResultSetEnum = ResultSetEnum.TCC): number {
    return _.size(this.results.get(resultSet));
  }

  /**
   * Returns selected page size
   */
  public getResultsPerPage(): number {
    return this.resultsPerPage;
  }

  /**
   * Returns sorted result set.
   * @param resultSet result set
   */
  public getResultsSorted(resultSet: ResultSetEnum = ResultSetEnum.TCC): any[] {
    if (this.resultListOrder) {
      return sortArray(this.getResults(resultSet), this.resultListOrder);
    } else {
      return this.getResults(resultSet);
    }
  }

  /**
   * Returns SearchPageSettingsStore instance
   */
  public getSearchPageSettingsStore(): SearchPageSettingsStore {
    return this.rootStore.getSearchStore().getSearchPageSettingsStore();
  }

  /**
   * Returns search target
   */
  public getSearchTarget(): SearchTargetOptionEnum {
    return this.searchTarget;
  }

  /**
   * Returns query string
   */
  public getSearchTerm(): string {
    return this.searchTerm;
  }

  /**
   * Returns total result set count for selected result set.
   * @param resultSet result set
   */
  public getTotalResultsCount(resultSet: ResultSetEnum = ResultSetEnum.TCC): number {
    return SearchResultSetsView.getDataSource(resultSet).getResultsTotalCount();
  }

  /**
   * Returns value of adaptive search property
   */
  public getUseAdaptiveSearch(): boolean {
    return this.useAdaptiveSearch;
  }

  /**
   * Returns true if infinite scroll should be enabled.
   */
  public getUseInfiniteScroll(): boolean {
    return this.useInfiniteScroll;
  }

  /**
   * Handles users geo location selection
   * @params geoLocation user selected geo location value
   */
  public handleGeoLocationChange(geoLocation: string) {
    this.setGeoLocation(geoLocation);
    if (this.resultListOrder === "-relevance") {
      this.doInitialQuery();
    }
  }

  /**
   * Handles use adaptive search selection
   * @params useAdaptiveSearch whether adaptive search should be used or not
   */
  public handleUseAdaptiveSearchChange(useAdaptiveSearch: boolean) {
    this.setUseAdaptiveSearch(useAdaptiveSearch);
    if (this.resultListOrder === "-relevance") {
      this.doInitialQuery();
    }
  }

  public async initialDataLoad() {
    if (!this.geoLocation) {
      this.setGeoLocation("FI");
      const featureToggle: IFeatureToggle = await TCCFeatureToggleDS.getFeatureToggle("adaptivesearch");
      this.setUseAdaptiveSearch(featureToggle.isEnabled);
    }
    if (this.organizationId) {
      await this.doInitialQuery();
      await this.fetchOrganizations(this.listSizeToLoad);
    } else if (this.itemId) {
      await this.doInitialQuery(this.listSizeToLoad);
      await this.fetchOrganizations(SearchPageStore.DEFAULT_PAGE_SIZE);
    } else {
      await this.fetchOrganizations(SearchPageStore.DEFAULT_PAGE_SIZE);
      await this.doInitialQuery();

      if (this.listSizeToLoad > SearchPageStore.DEFAULT_PAGE_SIZE && this.listSizeToLoad > this.listSize) {
        await this.fetchMoreResults(this.resultSet, Math.max(this.listSizeToLoad - this.listSize, 0));
      }
    }
  }

  /**
   * Return true if local resources are currently being loaded
   */
  public isLocalDataBeingLoaded(): boolean {
    return this.localDataBeingLoaded;
  }

  /**
   * Returns true if there's more resources to load for the given result set
   * @param resultSet
   */
  public isMoreDataToLoad(resultSet: ResultSetEnum = ResultSetEnum.TCC): boolean {
    return !SearchResultSetsView.getDataSource(resultSet).isLastPage() && _.size(this.results.get(resultSet)) > 0;
  }

  /**
   * Returns true if narrow mode is on
   */
  public isNarrowSearchMode(): boolean {
    return this.narrowSearchMode;
  }

  /**
   * Return true if TCC resources are currently being loaded
   */
  public isTCCDataBeingLoaded(): boolean {
    return this.tccDataBeingLoaded;
  }

  /**
   * Removes creator filter
   */
  @action
  public removeCreatorFilter(): void {
    this.creatorFilter = undefined;
    this.sync();
  }

  /**
   * Removes filter
   * @param namespace namespace
   * @param option filter to be removed
   */
  @action
  public removeFilter(namespace: AvailableAttributes, option: IDropdownOption) {
    this.filters.set(
      namespace,
      _.reject(this.filters.get(namespace), (v) => {
        return v.option.value == option.value;
      }),
    );
    this.sync();
  }

  /**
   * Resets search filters
   * @param sync should sync?
   */
  @action
  public resetFilters(sync = true): void {
    this.searchTarget = SearchTargetOptionEnum.PACKAGE;
    this.resultSet = ResultSetEnum.TCC;
    this.excludeFiltersOpen = false;

    _.each(SearchPageStore.FILTER_TYPES, (filterType) => {
      this.filters.set(filterType, []);
    });

    if (sync) {
      this.sync();
    }
  }

  /**
   * Triggers search
   * @param query query string
   */
  @action
  public search(query: string): void {
    this.searchTerm = query;
    this.setNarrowMode(true);
    this.initialDataLoad();
  }

  /**
   * Sets if exclusion filters should be open
   * @param isOpen should it be open?
   */
  @action
  public setExcludeFiltersOpen(isOpen = true): void {
    this.excludeFiltersOpen = isOpen;
  }

  /**
   * Sets geo location
   * @params geoLocation the value to be set
   */
  @action
  public setGeoLocation(geoLocation: string) {
    this.geoLocation = geoLocation;
    localStorage.setItem(Settings.adaptiveSearchStorage + ".geoLocation", geoLocation);
  }

  /**
   * Sets narrow mode
   * @param narrowMode boolean
   */
  @action
  public setNarrowMode(narrowMode = false): void {
    this.narrowSearchMode = narrowMode;
  }

  /**
   * Set organizations id
   * @params organizationId the Id to be set
   */
  @action
  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  /**
   * Method for setting sort
   * @param sort to be used
   */
  @action
  public setResultListOrder(sort: string) {
    this.getSearchPageSettingsStore().setResultListOrder(sort as SearchPageOrderEnum);

    if (this.searchTarget === SearchTargetOptionEnum.ORGANIZATION) {
      if (this.areMoreOrganizationsToLoad() || this.isBackendSortNeeded(sort)) {
        return this.fetchOrganizations();
      }
    } else {
      if (this.isMoreDataToLoad(this.resultSet) || this.isBackendSortNeeded(sort)) {
        return this.doInitialQuery();
      }
    }

    this.sync();
  }

  /**
   * Sets result set
   * @param set result set
   */
  @action
  public setResultSet(set: ResultSetEnum) {
    this.resultSet = set;
    this.sync();
  }

  /**
   * Sets page size
   * @param perPage page size
   */
  @action
  public setResultsPerPage(perPage: number = SearchPageStore.DEFAULT_PAGE_SIZE) {
    this.resultsPerPage = perPage;
    this.doInitialQuery();
  }

  /**
   * Sets the search criteria for the search based on search string
   * @param search possible search string ("?searchTerm=testing&showContentView=true")
   */
  @action
  public setSearchCriteria(search?: string) {
    const searchParams = new URLSearchParams(search);

    this.setCreatorFilter(searchParams.get("creator"));

    if (searchParams.get("sortBy")) {
      this.getSearchPageSettingsStore().setResultListOrder(searchParams.get("sortBy") as SearchPageOrderEnum);
    }

    if (searchParams.get("showOrganizationList")) {
      searchParams.get("showOrganizationList") === "true" && this.setSearchTarget(SearchTargetOptionEnum.ORGANIZATION);
    }

    this.listSizeToLoad = Math.min(
      !!searchParams.get("listSize") ? parseInt(searchParams.get("listSize")!) : SearchPageStore.DEFAULT_PAGE_SIZE,
      SearchPageStore.DEFAULT_PAGE_SIZE * 30,
    );

    if (searchParams.get("entityId")) {
      this.itemId = searchParams.get("entityId") || undefined;
    }

    if (searchParams.get("organizationId")) {
      this.organizationId = searchParams.get("organizationId") || undefined;
    }

    this.searchTerm = searchParams.get("searchTerm") || "";

    this.setDefaultSearchTarget(searchParams);

    if (this.showLocalContent(searchParams)) {
      this.resultSet = ResultSetEnum.LOCAL;
    }

    if (this.isInOfflineMode() && this.searchTarget == SearchTargetOptionEnum.ORGANIZATION) {
      this.searchTarget = SearchTargetOptionEnum.PACKAGE;
    }

    this.setFiltersFromParams(
      AvailableAttributes.SW_PRODUCTS,
      searchParams.get("compatibleSoftwareProducts") || undefined,
    );
    this.setFiltersFromParams(AvailableAttributes.SW_VERSIONS, searchParams.get("testedVersions") || undefined);
    this.setFiltersFromParams(
      AvailableAttributes.ITEM_TYPE_CATEGORIES,
      searchParams.get("itemTypeCategories") || undefined,
    );
    this.setFiltersFromParams(AvailableAttributes.LOCATIONS, searchParams.get("locations") || undefined);
    this.setFiltersFromParams(AvailableAttributes.LANGUAGES, searchParams.get("languages") || undefined);

    // support both new parameter (useCategories) and old ones, for backwards compatibility
    const useCategoriesCombined = _.chain(["workflow", "structures", "drawings", "modeling", "useCategories"])
      .map((paramName) => {
        return searchParams.get(paramName);
      })
      .map((paramString) => {
        return (paramString || "").split(",");
      })
      .flatten()
      .uniq()
      .join()
      .value();

    this.setFiltersFromParams(AvailableAttributes.USE_CATEGORIES, useCategoriesCombined);
    this.setFiltersFromParams(AvailableAttributes.MEASUREMENT_UNITS, searchParams.get("measurementUnits") || undefined);
  }

  /**
   * Sets search target
   * @param target search target
   */
  @action
  public setSearchTarget(target: SearchTargetOptionEnum) {
    this.rootStore.getSearchStore().setSearchTarget(target);
    this.searchTarget = target;
    this.sync();
    this.storeDefaultSearchTargetInCookie(target);
    this.storeDefaultSearchTargetInTCC(target);
  }

  /**
   * Sets search term
   * @param st query string
   */
  @action
  public setSearchTerm(st: string): void {
    this.searchTerm = st;
    this.sync();
  }

  /**
   * Sets use adaptive search
   * @params useAdaptiveSearch the value to be set
   */
  @action
  public setUseAdaptiveSearch(useAdaptiveSearch: boolean) {
    this.useAdaptiveSearch = useAdaptiveSearch;
    if (useAdaptiveSearch) {
      localStorage.setItem(Settings.adaptiveSearchStorage + ".useAdaptiveSearch", "true");
    } else {
      localStorage.removeItem(Settings.adaptiveSearchStorage + ".useAdaptiveSearch");
    }
  }

  /**
   * Sets if infinite scroll should be used.
   * @param use should infinite scroll be enabled?
   */
  @action
  public setUseInfiniteScroll(use = true): void {
    this.useInfiniteScroll = use;
  }

  /**
   * Returns true if creator filter is set.
   */
  public shouldDisplayCreatorFilter(): boolean {
    return !!this.creatorFilter && !!this.creatorFilter.organizationId && !!this.creatorFilter.organizationName;
  }

  public stateToQueryParams(): URLSearchParams {
    let params: Record<string, string> = {
      itemTypeCategories: this.toQueryParameter(AvailableAttributes.ITEM_TYPE_CATEGORIES),
      locations: this.toQueryParameter(AvailableAttributes.LOCATIONS),
      measurementUnits: this.toQueryParameter(AvailableAttributes.MEASUREMENT_UNITS),
      compatibleSoftwareProducts: this.toQueryParameter(AvailableAttributes.SW_PRODUCTS),
      testedVersions: this.toQueryParameter(AvailableAttributes.SW_VERSIONS),
      languages: this.toQueryParameter(AvailableAttributes.LANGUAGES),
      useCategories: this.toQueryParameter(AvailableAttributes.USE_CATEGORIES),
      sortBy: this.getSearchPageSettingsStore().resultListOrder,
      listSize: this.listSize.toString(),
      searchTarget: this.searchTarget,
      searchTerm: this.searchTerm,
      creator: this.creatorFilter?.organizationId || "",
      resultSet: this.resultSet,
    };

    params = _.omit(params, (value: string) => {
      return value === "" || _.isUndefined(value);
    }) as Record<string, string>;

    if (!!this.itemId) {
      // for backwards compatibility. it rather should be itemId, as it is either entity id or collection id...
      params.entityId = this.itemId;
    }

    if (!!this.organizationId) {
      params.organizationId = this.organizationId;
    }

    return new URLSearchParams(params);
  }

  @action
  private activateFilter(filter) {
    if (filter) {
      setTimeout(() => {
        runInAction(() => {
          filter.activated = true;
        });
      }, 200);
      setTimeout(() => {
        runInAction(() => {
          filter.activated = false;
        });
      }, 400);
    }
  }

  private canSelect(resource: IEntity | ICollection): boolean {
    if (this.searchTarget == SearchTargetOptionEnum.PACKAGE && this.entityTypeGuard(resource)) {
      const userStore = this.rootStore.getUserStore();
      const user = userStore.getCurrentUser();
      return !!user && userStore.canDownload(resource);
    } else {
      return false;
    }
  }

  @action
  private async doInitialQuery(pageSize: number = SearchPageStore.DEFAULT_PAGE_SIZE): Promise<ObservableMap<any, any>> {
    SearchResultSetsView.registerDataSource(ResultSetEnum.TCC, TCCSearchDataSource());
    SearchResultSetsView.registerDataSource(ResultSetEnum.LOCAL, LocalSearchDataSource());

    this.results.set(ResultSetEnum.TCC, []);
    this.results.set(ResultSetEnum.LOCAL, []);

    return this.fetchResults(pageSize);
  }

  private entityTypeGuard(entityOrCollection: IEntity | ICollection): entityOrCollection is IEntity {
    return "collection" in entityOrCollection;
  }

  @action
  private async fetchRemainingTCCResults(): Promise<ObservableMap> {
    this.loading = true;
    const remainingItemsSize = Math.max(
      this.getTotalResultsCount(ResultSetEnum.TCC) - this.getResultsCount(ResultSetEnum.TCC),
      0,
    );
    const results = await SearchResultSetsView.getDataSource(ResultSetEnum.TCC).loadNextPage(remainingItemsSize);
    runInAction(() => {
      this.results.set(ResultSetEnum.TCC, this.results.get(ResultSetEnum.TCC).concat(results));
      this.loading = false;
      this.dataFetched = true;
    });
    return this.results;
  }

  private filterToArray(key): string[] {
    return _.map(this.filters.get(key), (filter) => {
      return filter.filterType == SearchFilterTypeEnum.INCLUDE
        ? filter.option.value
        : `${SearchPageStore.EXCLUDED_FILTER_PREFIX}${filter.option.value}`;
    });
  }

  private findFilterInTheNamespace(namespace: AvailableAttributes, value: string) {
    return _.find(this.filters.get(namespace), (f) => f.option.value === value);
  }

  private flattenedAvailableFilters(namespace: AvailableAttributes) {
    return _.flatten(
      _.map(this.availableFilters[namespace], (af: IDropdownOption | IDropdownOptionGroup) => {
        return "options" in af && !!af.options ? af.options : af;
      }),
    );
  }

  private getOrganizationsOrderBy() {
    const orderBy = this.resultListOrder;
    switch (orderBy) {
      case SearchPageOrderEnum.ALPHABETICAL_ZA:
        return "-displayName";
      case SearchPageOrderEnum.LAST_CREATED:
        return "-createdAt";
    }
    return "displayName";
  }

  private getOrganizationsSortBy() {
    const orderBy = this.resultListOrder;
    switch (orderBy) {
      case SearchPageOrderEnum.ALPHABETICAL_ZA:
        return "displayName DESC";
      case SearchPageOrderEnum.LAST_CREATED:
        return "createTime DESC";
    }
    return "displayName ASC";
  }

  private isBackendSortNeeded(sort: string) {
    return _.contains(
      [SearchPageOrderEnum.RELEVANCE, SearchPageOrderEnum.POPULARITY, SearchPageOrderEnum.CREATOR],
      sort,
    );
  }

  private isInOfflineMode(): boolean {
    return !!Settings.isOffline;
  }

  @action
  private setAvailableFilters(): void {
    this.availableFilters[AvailableAttributes.ITEM_TYPE_CATEGORIES] = _.map(
      metadataLabels.itemTypeCategoriesOptions,
      (option) => {
        return {
          label: this.rootStore.getTranslation(option.name),
          value: option.value,
        };
      },
    );

    this.availableFilters[AvailableAttributes.LANGUAGES] = _.map(metadataLabels.supportedLanguagesOptions, (option) => {
      return {
        value: option.value,
        label: this.rootStore.getTranslation(option.name),
      };
    });

    this.availableFilters[AvailableAttributes.MEASUREMENT_UNITS] = _.map(
      metadataLabels.measurementUnitOptions,
      (option) => {
        return {
          value: option.value,
          label: this.rootStore.getTranslation(option.name),
        };
      },
    );

    const locationGroups: Record<string, IDropdownOptionGroup> = {};
    _.each(metadataLabels.countryOptions, (option) => {
      if (!locationGroups[option.group]) {
        locationGroups[option.group] = { label: option.group, options: [] };
      }
      locationGroups[option.group].options.push({
        value: option.value,
        label: this.rootStore.getTranslation(option.name),
      });
    });
    this.availableFilters[AvailableAttributes.LOCATIONS] = _.values(locationGroups);

    this.availableFilters[AvailableAttributes.SW_PRODUCTS] = _.chain(metadataLabels.softwareProductsOptions)
      .map((option) => {
        return {
          label: this.rootStore.getTranslation(option.name),
          value: option.value,
        };
      })
      .value();

    this.availableFilters[AvailableAttributes.SW_VERSIONS] = _.chain(metadataLabels.testedVersionsOptions)
      .map((option: IDropdownOption | IDropdownOptionGroup) => {
        if (_.has(option, "value")) {
          return {
            label: this.rootStore.getTranslation(option.label),
            value: (option as IDropdownOption).value,
          };
        } else {
          return {
            label: this.rootStore.getTranslation("compatibleSoftwareProductOptions." + option.label),
            options: _.map((option as IDropdownOptionGroup).options, (version: IDropdownOption) => {
              return { label: this.rootStore.getTranslation(version.label), value: version.value };
            }),
          };
        }
      })
      .value();

    this.availableFilters[AvailableAttributes.USE_CATEGORIES] = _.map(
      metadataConstants.groupedUseCategoriesKeys,
      (categories, group) => {
        return {
          label: this.rootStore.getTranslation("useCategoryGroups." + group),
          options: _.map(categories, (category) => {
            return {
              value: category,
              label: this.rootStore.getTranslation("useCategories." + category),
            };
          }),
        };
      },
    );
  }

  @action
  private async setCreatorFilter(creator?: string | null) {
    const router = this.rootStore.getRouter();
    const creatorParam = !_.isNull(creator) && !_.isUndefined(creator) ? creator : router.getQueryParam("creator");
    if (creatorParam) {
      this.creatorFilter = {
        organizationId: "" + creatorParam,
      };
      const organization = await NewRepository.getOrganization({
        id: creatorParam,
      });
      this.creatorFilter = {
        organizationName: organization.displayName,
        organizationId: organization.id,
      };
      return this.creatorFilter;
    }
  }

  @action
  private setDefaultSearchTarget(searchParams: URLSearchParams) {
    if (searchParams.get("showContentView")) {
      this.searchTarget = SearchTargetOptionEnum.PACKAGE;
    } else if (searchParams.get("searchTarget")) {
      this.searchTarget = SearchTargetOptionEnum[searchParams.get("searchTarget")!] || SearchTargetOptionEnum.PACKAGE;
    } else {
      const searchTargetFromCookie = Cookies.get(Settings.defaultSearchTypeCookieName);
      const user = this.rootStore.getUserStore().getCurrentUser();

      if (!!searchTargetFromCookie && performanceCookiesAreEnabled()) {
        this.searchTarget = SearchTargetOptionEnum[searchTargetFromCookie] || SearchTargetOptionEnum.PACKAGE;
      } else if (!!user && !!user.personalInfo && !!user.personalInfo.searchTarget) {
        this.searchTarget = user.personalInfo.searchTarget.value;
        this.storeDefaultSearchTargetInCookie(user.personalInfo.searchTarget.value);
      } else {
        this.searchTarget = SearchTargetOptionEnum.PACKAGE;
      }
    }
  }

  @action
  private setFiltersFromParams(namespace: AvailableAttributes, paramsString = "") {
    const filtersFromParams = (paramsString || "").split(",");
    _.each(filtersFromParams, (filterName) => {
      if (filterName) {
        const filter = _.find(this.flattenedAvailableFilters(namespace), (af) => {
          return "value" in af && af.value === filterName.replace(SearchPageStore.EXCLUDED_FILTER_PREFIX, "");
        });

        if (filter) {
          const filterType = startsWith(filterName, SearchPageStore.EXCLUDED_FILTER_PREFIX)
            ? SearchFilterTypeEnum.EXCLUDE
            : SearchFilterTypeEnum.INCLUDE;
          this.filters.get(namespace).push({ filterType: filterType, option: filter, activated: false });
        }
      }
    });
    _.each(this.filters.get(namespace), this.activateFilter);
  }

  private showLocalContent(searchParams: URLSearchParams): boolean {
    // for backwards compatibility
    return (
      this.isInOfflineMode() ||
      searchParams.get("showLocalContentView") == "true" ||
      searchParams.get("resultSet") == ResultSetEnum.LOCAL
    );
  }

  private stateToSearchParams(): FiltersToTCC {
    const attributes = [
      this.toIFilter(AvailableAttributes.ITEM_TYPE_CATEGORIES),
      this.toIFilter(AvailableAttributes.LOCATIONS),
      this.toIFilter(AvailableAttributes.MEASUREMENT_UNITS),
      this.toIFilter(AvailableAttributes.LANGUAGES),
      this.toIFilter(AvailableAttributes.USE_CATEGORIES),
    ];
    if (this.resultSet == ResultSetEnum.TCC) {
      attributes.push(this.toIFilter(AvailableAttributes.SW_PRODUCTS));
      attributes.push(this.toIFilter(AvailableAttributes.SW_VERSIONS));
    }
    const selectors: Array<IFilter | undefined> = [];
    if (this.creatorFilter) {
      selectors.push(
        FilterConverter.toIFilter(AvailableSelectors.CREATOR_ID, [this.creatorFilter.organizationId], ["=="]),
      );
    }
    return { attributes: attributes, selectors: selectors };
  }

  @action
  private storeDefaultSearchTargetInCookie(searchTarget: SearchTargetOptionEnum) {
    if (performanceCookiesAreEnabled()) {
      Cookies.set(Settings.defaultSearchTypeCookieName, searchTarget);
    }
  }

  private async storeDefaultSearchTargetInTCC(searchTarget: SearchTargetOptionEnum) {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (user) {
      const payload = {
        personalInfo: {
          searchTarget: { value: searchTarget, isPrivate: true },
        },
      };
      TCCUserDS.setUser(user.id, payload);
    }
  }

  private sync(recordEvent = false): void {
    if (this.searchTarget != SearchTargetOptionEnum.ORGANIZATION) {
      this.fetchResults(this.listSize, recordEvent);
    }
  }

  /**
   * Method to convert current filters to IFilter objects
   * @param namespace AvailableAttributes enum
   * @returns IFilter or undefiend
   */
  private toIFilter(namespace: AvailableAttributes): IFilter | undefined {
    const values: string[] = [];
    const comparators: string[] = [];

    _.forEach(this.filters.get(namespace), (filter) => {
      if (filter.filterType == SearchFilterTypeEnum.EXCLUDE) {
        values.push(SearchPageStore.EXCLUDED_FILTER_PREFIX + filter.option.value);
        comparators.push("!=");
      } else {
        values.push(filter.option.value);
        comparators.push("==");
      }
    });

    return FilterConverter.toIFilter(namespace, values, comparators);
  }

  private toQueryParameter(key): string {
    return this.filterToArray(key).join();
  }
}

/**
 * Context object for SearchPage instances.
 */
export const SearchPageStoreContext = createStoreContext<SearchPageStore>(SearchPageStore);

/**
 * Context object for IGetResourcePathContext instances (of classes that implement IGetResourcePath interface).
 */
export const IGetResourcePathContext = createContext<IGetResourcePath | undefined>(undefined);
