import { action, makeObservable, observable, runInAction } from "mobx";
import _ from "underscore";

import { NewRepository } from "../../../js/services/NewRepository";

import { createStoreContext, RootStore } from "../../../stores/rootStore";

import { IOrganization } from "../../../models/dataModel";

export class ManageOrganizationsStore {
  /**
   * The input used to filter users.
   */
  @observable private input: string = "";
  /**
   * Indicates whether the organizations are currently being loaded.
   */
  @observable private loading: boolean = false;
  /**
   * The list of organizations.
   */
  @observable private organizations: IOrganization[] = [];
  /**
   * Indicates whether the processing is currently in progress.
   */
  @observable private processing: boolean = false;
  /** Root store */
  private rootStore: RootStore;
  /**
   * The selected organizations.
   */
  @observable private selectedOrganizations: IOrganization[] = [];

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Fetches immutable organizations.
   *
   * @returns A promise that resolves to void.
   */
  @action
  public async fetchImmutableOrganizations(): Promise<void> {
    this.loading = true;
    this.input = "";
    this.organizations = [];

    try {
      const res = await NewRepository.getImmutableOrganizations();

      if (!!res) {
        runInAction(() => {
          this.organizations = res;
        });
      } else {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("errors.no_matches_found"));
      }
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  /**
   * Returns the user input.
   */
  public getInput(): string {
    return this.input || "";
  }

  /**
   * Retrieves the found organizations.
   *
   * @returns An array of `IOrganization` representing the organizations.
   */
  public getOrganizations(): IOrganization[] {
    return this.organizations;
  }

  /**
   * Retrieves the selected organizations.
   *
   * @returns An array of `IOrganization` representing the selected organizations.
   */
  public getSelectedOrganizations(): IOrganization[] {
    return this.selectedOrganizations;
  }

  /**
   * Checks if organizations are currently being loaded.
   * @returns {boolean} True if organizations are being loaded, false otherwise.
   */
  public isLoading(): boolean {
    return this.loading;
  }

  /**
   * Resolves if data is being processed
   * @returns true if is processing
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Checks if an organization is selected.
   * @param organization organization to check
   * @returns true if selected
   */
  public isSelected(organization: IOrganization): boolean {
    return _.contains(
      _.map(this.selectedOrganizations, (org) => org.id),
      organization.id,
    );
  }

  /**
   * Makes the selected organizations immutable.
   *
   * @remarks
   * This method sets the `processing` flag to `true` and attempts to make each selected organization
   * immutable by calling the `makeOrganizationImmutable` method of the `NewRepository` class.
   * If an error occurs during the process, an error message is displayed.
   * After all organizations have been processed, the `processing` flag is set back to `false`.
   *
   * @returns A promise that resolves when all organizations have been made immutable.
   */
  @action
  public async makeOrganizationsImmutable() {
    if (!this.selectedOrganizations || _.isEmpty(this.selectedOrganizations)) return;

    this.processing = true;

    try {
      const promises = _.map(this.selectedOrganizations, async (organization: IOrganization) => {
        try {
          await NewRepository.makeOrganizationImmutable(organization.id, true);
        } catch {
          console.log("Error making organization " + organization.id + " immutable");
          throw new Error("Error making organization " + organization.id + " immutable");
        }
      });

      await Promise.all(promises);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.manage_organizations.making_immutable_succeeded"));

      setTimeout(async () => await this.reloadOrganizations(), 1000);
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.manage_organizations.making_immutable_failed"));
    }
  }

  /**
   * Removes the immutable status from selected organizations.
   *
   * @remarks
   * This method makes a call to the backend API to make the selected organizations unimmutable.
   * If there are no selected organizations or if they are empty, the method returns early.
   *
   * @returns A Promise that resolves when the immutable status is successfully removed from all selected organizations.
   *
   *
   */
  @action
  public async removeImmutableStatus() {
    if (!this.selectedOrganizations || _.isEmpty(this.selectedOrganizations)) return;

    this.processing = true;

    try {
      const promises = _.map(this.selectedOrganizations, async (organization: IOrganization) => {
        try {
          await NewRepository.makeOrganizationImmutable(organization.id, false);
        } catch {
          console.log("Error making organization " + organization.id + " unimmutable");
          throw new Error("Error making organization " + organization.id + " unimmutable");
        }
      });

      await Promise.all(promises);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.manage_organizations.making_unimmutable_succeeded"));

      setTimeout(async () => await this.reloadOrganizations(), 1500);
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.manage_organizations.making_unimmutable_failed"));
    }
  }

  /**
   * Searches for organizations based on the input provided.
   *
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  @action
  public async searchOrganizations(): Promise<void> {
    this.loading = true;

    let inputRows: string[] = _.uniq(this.input.trimEnd().split("\n"));
    inputRows = _.filter(inputRows, (row: string) => !_.isEmpty(row.trim()));

    const promises: Array<Promise<IOrganization | undefined>> = _.map(inputRows, async (searchStr: string) => {
      searchStr = searchStr.trim();

      try {
        return NewRepository.getOrganization({ id: searchStr });
      } catch {
        console.log("Error fetching organization " + searchStr);
      }
    });

    try {
      const organizations = await Promise.all(promises);

      if (!!organizations) {
        runInAction(() => {
          this.organizations = _.filter(organizations, (org: IOrganization | undefined) => !!org) as IOrganization[];
          this.input = "";
        });
      }
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  /**
   * Sets the input
   * @param input input to set
   */
  @action
  public setInput(input: string) {
    this.input = input;
  }

  /**
   * Adds an organization to the selected organizations list.
   *
   * @param organization - The organization to be added.
   */
  @action
  public toggleSelection(organization: IOrganization, selected: boolean) {
    if (selected) {
      this.selectedOrganizations.push(organization);
    } else {
      this.selectedOrganizations = _.without(this.selectedOrganizations, organization);
    }
  }

  @action
  private async reloadOrganizations(): Promise<void> {
    this.loading = true;
    this.processing = false;
    this.selectedOrganizations = [];

    const promises: Array<Promise<IOrganization | undefined>> = _.map(
      this.organizations,
      async (organization: IOrganization) => {
        try {
          return NewRepository.getOrganization({ id: organization.id });
        } catch {
          console.log("Error fetching organization " + organization.id);
        }
      },
    );

    try {
      const organizations = await Promise.all(promises);

      if (!!organizations) {
        runInAction(() => {
          this.organizations = _.filter(organizations, (org: IOrganization | undefined) => !!org) as IOrganization[];
        });
      }
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

/**
 * Context object for ManageOrganizationsStore instances.
 */
export const ManageOrganizationsStoreContext = createStoreContext<ManageOrganizationsStore>(ManageOrganizationsStore);
