import { ICountry } from "../models/dataModel";

/**
 * Countries list
 */
export const countries: ICountry[] = [
  { ccode: "GLOBAL", cname: "Global", region: "" },
  { ccode: "AF", cname: "Afghanistan", region: "Asia" },
  { ccode: "AX", cname: "Aland Islands", region: "Europe" },
  { ccode: "AL", cname: "Albania", region: "Europe" },
  { ccode: "DZ", cname: "Algeria", region: "Africa" },
  { ccode: "AS", cname: "American Samoa", region: "Oceania" },
  { ccode: "AD", cname: "Andorra", region: "Europe" },
  { ccode: "AO", cname: "Angola", region: "Africa" },
  { ccode: "AI", cname: "Anguilla", region: "Americas" },
  { ccode: "AQ", cname: "Antarctica", region: "" },
  { ccode: "AG", cname: "Antigua And Barbuda", region: "Americas" },
  { ccode: "AR", cname: "Argentina", region: "Americas" },
  { ccode: "AM", cname: "Armenia", region: "Asia" },
  { ccode: "AW", cname: "Aruba", region: "Americas" },
  { ccode: "AU", cname: "Australia", region: "Oceania" },
  { ccode: "AT", cname: "Austria", region: "Europe" },
  { ccode: "AZ", cname: "Azerbaijan", region: "Asia" },
  { ccode: "BS", cname: "Bahamas", region: "Americas" },
  { ccode: "BH", cname: "Bahrain", region: "Asia" },
  { ccode: "BD", cname: "Bangladesh", region: "Asia" },
  { ccode: "BB", cname: "Barbados", region: "Americas" },
  { ccode: "BY", cname: "Belarus", region: "Europe" },
  { ccode: "BE", cname: "Belgium", region: "Europe" },
  { ccode: "BZ", cname: "Belize", region: "Americas" },
  { ccode: "BJ", cname: "Benin", region: "Africa" },
  { ccode: "BM", cname: "Bermuda", region: "Americas" },
  { ccode: "BT", cname: "Bhutan", region: "Asia" },
  { ccode: "BO", cname: "Bolivia", region: "Americas" },
  { ccode: "BA", cname: "Bosnia And Herzegovina", region: "Europe" },
  { ccode: "BW", cname: "Botswana", region: "Africa" },
  { ccode: "BV", cname: "Bouvet Island", region: "" },
  { ccode: "BR", cname: "Brazil", region: "Americas" },
  { ccode: "IO", cname: "British Indian Ocean Territory", region: "Africa" },
  { ccode: "BN", cname: "Brunei Darussalam", region: "Asia" },
  { ccode: "BG", cname: "Bulgaria", region: "Europe" },
  { ccode: "BF", cname: "Burkina Faso", region: "Africa" },
  { ccode: "BI", cname: "Burundi", region: "Africa" },
  { ccode: "KH", cname: "Cambodia", region: "Asia" },
  { ccode: "CM", cname: "Cameroon", region: "Africa" },
  { ccode: "CA", cname: "Canada", region: "Americas" },
  { ccode: "CV", cname: "Cape Verde", region: "Africa" },
  { ccode: "KY", cname: "Cayman Islands", region: "Americas" },
  { ccode: "CF", cname: "Central African Republic", region: "Africa" },
  { ccode: "TD", cname: "Chad", region: "Africa" },
  { ccode: "CL", cname: "Chile", region: "Americas" },
  { ccode: "CN", cname: "China", region: "Asia" },
  { ccode: "CX", cname: "Christmas Island", region: "Oceania" },
  { ccode: "CC", cname: "Cocos (Keeling) Islands", region: "Oceania" },
  { ccode: "CO", cname: "Colombia", region: "Americas" },
  { ccode: "KM", cname: "Comoros", region: "Africa" },
  { ccode: "CG", cname: "Congo", region: "Africa" },
  { ccode: "CD", cname: "Congo, Democratic Republic", region: "Africa" },
  { ccode: "CK", cname: "Cook Islands", region: "Oceania" },
  { ccode: "CR", cname: "Costa Rica", region: "Americas" },
  { ccode: "CI", cname: "Cote D'Ivoire", region: "Africa" },
  { ccode: "HR", cname: "Croatia", region: "Europe" },
  { ccode: "CU", cname: "Cuba", region: "Americas" },
  { ccode: "CY", cname: "Cyprus", region: "Europe" },
  { ccode: "CZ", cname: "Czech Republic", region: "Europe" },
  { ccode: "DK", cname: "Denmark", region: "Europe" },
  { ccode: "DJ", cname: "Djibouti", region: "Africa" },
  { ccode: "DM", cname: "Dominica", region: "Americas" },
  { ccode: "DO", cname: "Dominican Republic", region: "Americas" },
  { ccode: "EC", cname: "Ecuador", region: "Americas" },
  { ccode: "EG", cname: "Egypt", region: "Africa" },
  { ccode: "SV", cname: "El Salvador", region: "Americas" },
  { ccode: "GQ", cname: "Equatorial Guinea", region: "Africa" },
  { ccode: "ER", cname: "Eritrea", region: "Africa" },
  { ccode: "EE", cname: "Estonia", region: "Europe" },
  { ccode: "ET", cname: "Ethiopia", region: "Africa" },
  { ccode: "FK", cname: "Falkland Islands (Malvinas)", region: "Americas" },
  { ccode: "FO", cname: "Faroe Islands", region: "Europe" },
  { ccode: "FJ", cname: "Fiji", region: "Oceania" },
  { ccode: "FI", cname: "Finland", region: "Europe" },
  { ccode: "FR", cname: "France", region: "Europe" },
  { ccode: "GF", cname: "French Guiana", region: "Americas" },
  { ccode: "PF", cname: "French Polynesia", region: "Oceania" },
  { ccode: "TF", cname: "French Southern Territories", region: "" },
  { ccode: "GA", cname: "Gabon", region: "Africa" },
  { ccode: "GM", cname: "Gambia", region: "Africa" },
  { ccode: "GE", cname: "Georgia", region: "Asia" },
  { ccode: "DE", cname: "Germany", region: "Europe" },
  { ccode: "GH", cname: "Ghana", region: "Africa" },
  { ccode: "GI", cname: "Gibraltar", region: "Europe" },
  { ccode: "GR", cname: "Greece", region: "Europe" },
  { ccode: "GL", cname: "Greenland", region: "Americas" },
  { ccode: "GD", cname: "Grenada", region: "Americas" },
  { ccode: "GP", cname: "Guadeloupe", region: "Americas" },
  { ccode: "GU", cname: "Guam", region: "Oceania" },
  { ccode: "GT", cname: "Guatemala", region: "Americas" },
  { ccode: "GG", cname: "Guernsey", region: "Europe" },
  { ccode: "GN", cname: "Guinea", region: "Africa" },
  { ccode: "GW", cname: "Guinea-Bissau", region: "Africa" },
  { ccode: "GY", cname: "Guyana", region: "Americas" },
  { ccode: "HT", cname: "Haiti", region: "Americas" },
  { ccode: "HM", cname: "Heard Island & Mcdonald Islands", region: "" },
  { ccode: "VA", cname: "Holy See (Vatican City State)", region: "Europe" },
  { ccode: "HN", cname: "Honduras", region: "Americas" },
  { ccode: "HK", cname: "Hong Kong", region: "Asia" },
  { ccode: "HU", cname: "Hungary", region: "Europe" },
  { ccode: "IS", cname: "Iceland", region: "Europe" },
  { ccode: "IN", cname: "India", region: "Asia" },
  { ccode: "ID", cname: "Indonesia", region: "Asia" },
  { ccode: "IR", cname: "Iran, Islamic Republic Of", region: "Asia" },
  { ccode: "IQ", cname: "Iraq", region: "Asia" },
  { ccode: "IE", cname: "Ireland", region: "Europe" },
  { ccode: "IM", cname: "Isle Of Man", region: "Europe" },
  { ccode: "IL", cname: "Israel", region: "Asia" },
  { ccode: "IT", cname: "Italy", region: "Europe" },
  { ccode: "JM", cname: "Jamaica", region: "Americas" },
  { ccode: "JP", cname: "Japan", region: "Asia" },
  { ccode: "JE", cname: "Jersey", region: "Europe" },
  { ccode: "JO", cname: "Jordan", region: "Asia" },
  { ccode: "KZ", cname: "Kazakhstan", region: "Asia" },
  { ccode: "KE", cname: "Kenya", region: "Africa" },
  { ccode: "KI", cname: "Kiribati", region: "Oceania" },
  { ccode: "KR", cname: "Korea", region: "Asia" },
  { ccode: "KW", cname: "Kuwait", region: "Asia" },
  { ccode: "KG", cname: "Kyrgyzstan", region: "Asia" },
  { ccode: "LA", cname: "Lao People's Democratic Republic", region: "Asia" },
  { ccode: "LV", cname: "Latvia", region: "Europe" },
  { ccode: "LB", cname: "Lebanon", region: "Asia" },
  { ccode: "LS", cname: "Lesotho", region: "Africa" },
  { ccode: "LR", cname: "Liberia", region: "Africa" },
  { ccode: "LY", cname: "Libyan Arab Jamahiriya", region: "Africa" },
  { ccode: "LI", cname: "Liechtenstein", region: "Europe" },
  { ccode: "LT", cname: "Lithuania", region: "Europe" },
  { ccode: "LU", cname: "Luxembourg", region: "Europe" },
  { ccode: "MO", cname: "Macao", region: "Asia" },
  { ccode: "MK", cname: "Macedonia", region: "Europe" },
  { ccode: "MG", cname: "Madagascar", region: "Africa" },
  { ccode: "MW", cname: "Malawi", region: "Africa" },
  { ccode: "MY", cname: "Malaysia", region: "Asia" },
  { ccode: "MV", cname: "Maldives", region: "Asia" },
  { ccode: "ML", cname: "Mali", region: "Africa" },
  { ccode: "MT", cname: "Malta", region: "Europe" },
  { ccode: "MH", cname: "Marshall Islands", region: "Oceania" },
  { ccode: "MQ", cname: "Martinique", region: "Americas" },
  { ccode: "MR", cname: "Mauritania", region: "Africa" },
  { ccode: "MU", cname: "Mauritius", region: "Africa" },
  { ccode: "YT", cname: "Mayotte", region: "Africa" },
  { ccode: "MX", cname: "Mexico", region: "Americas" },
  { ccode: "FM", cname: "Micronesia, Federated States Of", region: "Oceania" },
  { ccode: "MD", cname: "Moldova", region: "Europe" },
  { ccode: "MC", cname: "Monaco", region: "Europe" },
  { ccode: "MN", cname: "Mongolia", region: "Asia" },
  { ccode: "ME", cname: "Montenegro", region: "Europe" },
  { ccode: "MS", cname: "Montserrat", region: "Americas" },
  { ccode: "MA", cname: "Morocco", region: "Africa" },
  { ccode: "MZ", cname: "Mozambique", region: "Africa" },
  { ccode: "MM", cname: "Myanmar", region: "Asia" },
  { ccode: "NA", cname: "Namibia", region: "Africa" },
  { ccode: "NR", cname: "Nauru", region: "Oceania" },
  { ccode: "NP", cname: "Nepal", region: "Asia" },
  { ccode: "NL", cname: "Netherlands", region: "Europe" },
  { ccode: "AN", cname: "Netherlands Antilles", region: "Americas" },
  { ccode: "NC", cname: "New Caledonia", region: "Oceania" },
  { ccode: "NZ", cname: "New Zealand", region: "Oceania" },
  { ccode: "NI", cname: "Nicaragua", region: "Americas" },
  { ccode: "NE", cname: "Niger", region: "Africa" },
  { ccode: "NG", cname: "Nigeria", region: "Africa" },
  { ccode: "NU", cname: "Niue", region: "Oceania" },
  { ccode: "NF", cname: "Norfolk Island", region: "Oceania" },
  { ccode: "MP", cname: "Northern Mariana Islands", region: "Oceania" },
  { ccode: "NO", cname: "Norway", region: "Europe" },
  { ccode: "OM", cname: "Oman", region: "Asia" },
  { ccode: "PK", cname: "Pakistan", region: "Asia" },
  { ccode: "PW", cname: "Palau", region: "Oceania" },
  { ccode: "PS", cname: "Palestinian Territory, Occupied", region: "Asia" },
  { ccode: "PA", cname: "Panama", region: "Americas" },
  { ccode: "PG", cname: "Papua New Guinea", region: "Oceania" },
  { ccode: "PY", cname: "Paraguay", region: "Americas" },
  { ccode: "PE", cname: "Peru", region: "Americas" },
  { ccode: "PH", cname: "Philippines", region: "Asia" },
  { ccode: "PN", cname: "Pitcairn", region: "Oceania" },
  { ccode: "PL", cname: "Poland", region: "Europe" },
  { ccode: "PT", cname: "Portugal", region: "Europe" },
  { ccode: "PR", cname: "Puerto Rico", region: "Americas" },
  { ccode: "QA", cname: "Qatar", region: "Asia" },
  { ccode: "RE", cname: "Reunion", region: "Africa" },
  { ccode: "RO", cname: "Romania", region: "Europe" },
  { ccode: "RU", cname: "Russian Federation", region: "Europe" },
  { ccode: "RW", cname: "Rwanda", region: "Africa" },
  { ccode: "BL", cname: "Saint Barthelemy", region: "Americas" },
  { ccode: "SH", cname: "Saint Helena", region: "Africa" },
  { ccode: "KN", cname: "Saint Kitts And Nevis", region: "Americas" },
  { ccode: "LC", cname: "Saint Lucia", region: "Americas" },
  { ccode: "MF", cname: "Saint Martin", region: "Americas" },
  { ccode: "PM", cname: "Saint Pierre And Miquelon", region: "Americas" },
  { ccode: "VC", cname: "Saint Vincent And Grenadines", region: "Americas" },
  { ccode: "WS", cname: "Samoa", region: "Oceania" },
  { ccode: "SM", cname: "San Marino", region: "Europe" },
  { ccode: "ST", cname: "Sao Tome And Principe", region: "Africa" },
  { ccode: "SA", cname: "Saudi Arabia", region: "Asia" },
  { ccode: "SN", cname: "Senegal", region: "Africa" },
  { ccode: "RS", cname: "Serbia", region: "Europe" },
  { ccode: "SC", cname: "Seychelles", region: "Africa" },
  { ccode: "SL", cname: "Sierra Leone", region: "Africa" },
  { ccode: "SG", cname: "Singapore", region: "Asia" },
  { ccode: "SK", cname: "Slovakia", region: "Europe" },
  { ccode: "SI", cname: "Slovenia", region: "Europe" },
  { ccode: "SB", cname: "Solomon Islands", region: "Oceania" },
  { ccode: "SO", cname: "Somalia", region: "Africa" },
  { ccode: "ZA", cname: "South Africa", region: "Africa" },
  { ccode: "GS", cname: "South Georgia And Sandwich Isl.", region: "Americas" },
  { ccode: "ES", cname: "Spain", region: "Europe" },
  { ccode: "LK", cname: "Sri Lanka", region: "Asia" },
  { ccode: "SD", cname: "Sudan", region: "Africa" },
  { ccode: "SR", cname: "Suriname", region: "Americas" },
  { ccode: "SJ", cname: "Svalbard And Jan Mayen", region: "Europe" },
  { ccode: "SZ", cname: "Swaziland", region: "Africa" },
  { ccode: "SE", cname: "Sweden", region: "Europe" },
  { ccode: "CH", cname: "Switzerland", region: "Europe" },
  { ccode: "SY", cname: "Syrian Arab Republic", region: "Asia" },
  { ccode: "TW", cname: "Taiwan", region: "Asia" },
  { ccode: "TJ", cname: "Tajikistan", region: "Asia" },
  { ccode: "TZ", cname: "Tanzania", region: "Africa" },
  { ccode: "TH", cname: "Thailand", region: "Asia" },
  { ccode: "TL", cname: "Timor-Leste", region: "Asia" },
  { ccode: "TG", cname: "Togo", region: "Africa" },
  { ccode: "TK", cname: "Tokelau", region: "Oceania" },
  { ccode: "TO", cname: "Tonga", region: "Oceania" },
  { ccode: "TT", cname: "Trinidad And Tobago", region: "Americas" },
  { ccode: "TN", cname: "Tunisia", region: "Africa" },
  { ccode: "TR", cname: "Turkey", region: "Asia" },
  { ccode: "TM", cname: "Turkmenistan", region: "Asia" },
  { ccode: "TC", cname: "Turks And Caicos Islands", region: "Americas" },
  { ccode: "TV", cname: "Tuvalu", region: "Oceania" },
  { ccode: "UG", cname: "Uganda", region: "Africa" },
  { ccode: "UA", cname: "Ukraine", region: "Europe" },
  { ccode: "AE", cname: "United Arab Emirates", region: "Asia" },
  { ccode: "GB", cname: "United Kingdom", region: "Europe" },
  { ccode: "US", cname: "United States", region: "Americas" },
  { ccode: "UM", cname: "United States Outlying Islands", region: "Americas" },
  { ccode: "UY", cname: "Uruguay", region: "Americas" },
  { ccode: "UZ", cname: "Uzbekistan", region: "Asia" },
  { ccode: "VU", cname: "Vanuatu", region: "Asia" },
  { ccode: "VE", cname: "Venezuela", region: "Americas" },
  { ccode: "VN", cname: "Vietnam", region: "Asia" },
  { ccode: "VG", cname: "Virgin Islands, British", region: "Americas" },
  { ccode: "VI", cname: "Virgin Islands, U.S.", region: "Americas" },
  { ccode: "WF", cname: "Wallis And Futuna", region: "Oceania" },
  { ccode: "EH", cname: "Western Sahara", region: "Africa" },
  { ccode: "YE", cname: "Yemen", region: "Asia" },
  { ccode: "ZM", cname: "Zambia", region: "Africa" },
  { ccode: "ZW", cname: "Zimbabwe", region: "Africa" },
];
