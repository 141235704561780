import { TCCAnalyticsDS } from "../data-source/TCCAnalyticsDS";

import { AnalyticsEventTypeEnum, AnalyticsSourceEnum } from "../../models/enums";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IUser, IEntity, IVersion, IClient } from "../../models/dataModel";

export const AnalyticsService = {
  getAnalyticsData: function (data) {
    return TCCAnalyticsDS.getAnalyticsData(data);
  },
  getAnalyticsPublicData: function (data) {
    return TCCAnalyticsDS.getAnalyticsPublicData(data);
  },
  getAnalyticsExtractionData: function (data) {
    return TCCAnalyticsDS.getAnalyticsExtractionData(data);
  },
  /**
   * Save analytics data for completed version download event.
   * @param {IUser} currentUser - user who initiated the download
   * @param {{ id: string, collection: { id: string } } | IEntity} pack - pagacke from which the version was downloaded
   * @param {{ id: string } | IVersion} version - version that was downloaded
   * @param {IClient?} [client] -  client from which the download was initiated
   * @param {AnalyticsSourceEnum} [source] - source of the event
   * @returns {Promise} - promise that resolves to the result of the save operation
   */
  versionDownloadComplete: function (currentUser, pack, version, client, source) {
    var data = {
      clientApplication: source || AnalyticsSourceEnum.TEKLA_WAREHOUSE,
      contextId: (client && client.modelId) || "",
      eventType: AnalyticsEventTypeEnum.VERSION_DOWNLOAD_COMPLETE,
      subject: {
        collection: {
          id: pack.collection.id,
        },
        package: {
          id: pack.id,
        },
        id: version.id,
      },
      creator: {
        organization: {
          id: (!!currentUser && !!currentUser.organization && currentUser.organization.id) || "",
        },
      },
    };
    return TCCAnalyticsDS.saveAnalyticsData(data);
  },
  /**
   * Save analytics data for failed version download event.
   * @param {IUser} currentUser - user who initiated the download
   * @param {{ id: string, collection: { id: string } } | IEntity} pack - pagacke from which the version was downloaded
   * @param {{ id: string } | IVersion} version - version that was downloaded
   * @param {IClient?} [client] -  client from which the download was initiated
   * @param {AnalyticsSourceEnum} [source] - source of the event
   * @returns {Promise} - promise that resolves to the result of the save operation
   */
  versionDownloadFailure: function (currentUser, pack, version, client, source) {
    var data = {
      clientApplication: source || AnalyticsSourceEnum.TEKLA_WAREHOUSE,
      contextId: (client && client.modelId) || "",
      eventType: AnalyticsEventTypeEnum.VERSION_DOWNLOAD_FAILURE,
      subject: {
        collection: {
          id: pack.collection.id,
        },
        package: {
          id: pack.id,
        },
        id: version.id,
      },
      creator: {
        organization: {
          id: (currentUser && currentUser.organization.id) || "",
        },
      },
    };
    return TCCAnalyticsDS.saveAnalyticsData(data);
  },
  /**
   * Save analytics data for content view event.
   * @param {IUser} currentUser - user viewing the content
   * @param {IEntity | ICollection} pack - viewed package
   * @param {AnalyticsEventTypeEnum} [eventName] - event type to be saved
   * @returns {Promise} - promise that resolves to the result of the save operation
   */
  contentViewed: function (user, pack, eventName) {
    const organizationId = user ? user.organization.id : "";
    const eventType = eventName ? eventName : AnalyticsEventTypeEnum.CONTENT_VIEWED;
    const data = {
      eventType: eventType,
      subject: {
        collection: {
          id: pack.collection.id,
        },
        package: {
          id: pack.id,
        },
        id: pack.id,
      },
      creator: {
        organization: {
          id: organizationId,
        },
      },
    };
    return TCCAnalyticsDS.saveAnalyticsData(data);
  },
  /**
   * Save analytics data for moved to partner site event.
   * @param {IUser | undefined} user - user clicking the partner download link
   * @param {IEntity | ICollection} pack - package for which the partner download link was clicked
   * @returns {Promise} - promise that resolves to the result of the save operation
   */
  movedToPartnerSite: function (user, pack) {
    const organizationId = user ? user.organization.id : "";
    const data = {
      eventType: AnalyticsEventTypeEnum.CLICKED_PARTNER_DOWNLOAD_LINK,
      subject: {
        collection: {
          id: pack.collection.id,
        },
        package: {
          id: pack.id,
        },
        id: pack.id,
      },
      creator: {
        organization: {
          id: organizationId,
        },
      },
    };
    return TCCAnalyticsDS.saveAnalyticsData(data);
  },
};
