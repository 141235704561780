 
export const ItAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["it-IT"]) {
      window.locale["it-IT"] = {};
    }

    window.locale["it-IT"].about = {
      navigation: {
        why: "Perché Tekla Warehouse?",
        usage_basics: "Nozioni di base su Tekla Warehouse",
        "usage_basics__using-content": "Utilizzo del contenuto",
        "usage_basics__adding-content": "Aggiunta di contenuto",
        usage_basics__collection: "Raccolta",
        "usage_basics__content-item": "Oggetto contenuto",
        usage_basics__version: "Versione",
        getting_started: "Per iniziare",
        "getting_started__tekla-account-and-system-requirements": "Tekla account e requisiti di sistema",
        "getting_started__tekla-warehouse-service-plugin": "Plug-in Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Come ottenere Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures e Tekla Warehouse",
        content: "Contenuto in Tekla Warehouse",
        "content__3d-products": "Prodotti modelli 3D",
        "content__custom-components": "Componenti personalizzati",
        content__applications: "Applicazioni",
        "content__profiles-shapes-etc": "Profili, materiali, bulloni, barre d'armatura ecc.",
        "content__model-templates": "Modelli prototipo",
        "content__drawing-setup": "Impostazioni disegno",
        "content__report-templates-etc": "Modelli di report e file di attributi",
        using_content: "Utilizzo del contenuto",
        creating_content: "Creazione di contenuto",
        "creating_content__getting-started-with-content-creation": "Per iniziare con la creazione di contenuto",
        "creating_content__expert-help-for-creating-content":
          "Richiesta assistenza di esperti per la creazione di contenuti",
        application_developers: "Sviluppo di applicazioni per Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Perché Tekla Warehouse?",
        description_1:
          "Tekla Warehouse include applicazioni, componenti personalizzati, parti, profili, materiali come acciaio e calcestruzzo, bulloni, barre d'armatura, reti, sagome e template per Tekla Structures. Le parti effettive dei fornitori nei modelli garantiscono la più elevata precisione e informazioni esatte sui materiali per la progettazione e l'approvvigionamento. Inoltre, l'utilizzo di contenuto pronto accelera la modellazione.",
        description_2:
          "Tekla Warehouse fornisce accesso centralizzato a questo contenuto che ora è possibile utilizzare in modo efficace e semplificato. Il contenuto di Tekla Warehouse è in continuo aumento.",
        description_3: "Con Tekla Warehouse",
        feature_1:
          "I produttori possono rendere i propri prodotti e applicazioni disponibili agli utenti, che possono trasferire i modelli dei prodotti 3D direttamente nei propri modelli Tekla e utilizzare successivamente il prodotto fisico nella costruzione.",
        feature_2:
          "È possibile utilizzare la rete aziendale per condividere il contenuto con i colleghi e per salvare il contenuto a livello locale per uso privato",
        description_4:
          "Con la ricerca di Tekla Warehouse è possibile trovare facilmente gli elementi cercati, indipendentemente dal tipo di contenuto.",
        description_5: "Il plug-in Tekla Warehouse Service è necessario per abilitare le raccolte di rete e locali.",
        view_licences_html:
          'Visualizza le <a href="/api/third_party_licenses.txt" target="_blank">licenze di terzi</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Nozioni di base su Tekla Warehouse",
        using_content: {
          title: "Utilizzo del contenuto",
          description_1:
            "È possibile utilizzare il contenuto nelle raccolte online, condividere il contenuto con i propri colleghi utilizzando le raccolte di rete e utilizzare le raccolte locali per uso privato.",
          description_2:
            "Per le raccolte locali e di rete è necessario Tekla Warehouse Service, installato con Tekla Structures 20.1 Service Release 1 e versioni più recenti di Tekla Structures.",
          description_3:
            "Le raccolte Tekla specifiche che comprendono i contenuti dell'ambiente localizzato vengono installate con Tekla Structures.",
          description_4: "Per ulteriori informazioni, vedere la sezione Utilizzo di contenuti.",
        },
        adding_content: {
          title: "Aggiunta di contenuto",
          description_1:
            'È possibile utilizzare le raccolte online per pubblicare il contenuto per tutti gli utenti di Tekla Warehouse. Per pubblicare il contenuto come organizzazione, è necessario che l\'organizzazione dell\'utente venga aggiunta a account.tekla.com. Per ulteriori informazioni, contattare <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">l\'ufficio o il rivenditore Tekla</a> di zona.',
          description_2: "Per ulteriori informazioni, vedere la sezione Creazione di contenuti.",
        },
        collection: {
          title: "Raccolta",
          feature_1:
            "Nelle raccolte vengono raggruppati gli elementi di contenuto, come le cartelle, e vengono definiti i diritti di accesso al contenuto (pubblico/privato).",
          feature_1_1:
            "I modellatori possono utilizzare il contenuto nelle raccolte online, condividere il contenuto con i propri colleghi utilizzando le raccolte di rete e utilizzare le raccolte locali per uso privato.",
          feature_2:
            "I fornitori di contenuti possono utilizzare le raccolte online per pubblicare il proprio contenuto per tutti gli utenti di Tekla Warehouse.",
          feature_3:
            "Per le raccolte locali e di rete è necessario il plug-in Tekla Warehouse Service, installato con Tekla Structures 20.1 Service Release 1 e versioni più recenti e installabile anche separatamente.",
        },
        "content-item": {
          title: "Oggetto contenuto",
          feature_1:
            "Un elemento di contenuto è un gruppo logico di file. Ad esempio, un catalogo di prodotti 3D, un modello prototipo, uno strumento, un gruppo di componenti personalizzati o un catalogo profili sono elementi di contenuto.",
          feature_2:
            "Gli elementi di contenuto possono essere etichettati per tipo e a seconda dell'uso per facilitare agli utenti la ricerca di contenuto.",
        },
        version: {
          title: "Versione",
          feature_1:
            "Allo stesso tempo, un elemento di contenuto può avere molte versioni utilizzate con le diverse versioni di Tekla Structures. L'utente può selezionare la versione corretta dalla pagina del contenuto.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Per iniziare",
        tekla_account_and_system_requirements: {
          title: "Tekla account e requisiti di sistema",
          register_atc_html:
            'È necessario un Tekla account per eseguire l\'accesso a Tekla Warehouse. Se non lo si è ancora fatto, registrarsi all\'indirizzo <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a> (si noti che non si tratta del vecchio account Extranet).',
          see_tua_for_prerequisites_html:
            'Vedere Tekla User Assistance per informazioni sui <a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">prerequisiti per l\'utilizzo di Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Plug-in Tekla Warehouse Service",
          description_1: "Oltre a essere uno storage online per il contenuto, Tekla Warehouse può contenere anche:",
          feature_1: "Raccolte locali per uso personale",
          feature_2: "Raccolte di rete per condividere il contenuto con i colleghi utilizzando la rete aziendale",
          feature_3: "Installazione semplice quando si utilizzano o si creano contenuti",
          feature_4: "Ricerca di contenuto locale e di rete",
          description_2:
            "Ciò è possibile grazie a Tekla Warehouse Service, un plug-in che funziona come back-end locale, comunicando con Tekla Structures e il sito Web Tekla Warehouse.",
          description_3:
            "Se Tekla Warehouse Service non è installato o non è in esecuzione, in Tekla Warehouse viene indicato nella parte superiore della pagina per consentire la risoluzione dei problemi.",
          description_4:
            "Se si verificano problemi con il servizio, è possibile riavviarlo utilizzando il programma Servizi in Windows. Il nome del servizio è Tekla Warehouse. Controllare inoltre le impostazioni di sicurezza del browser in uso.",
          description_5:
            "Il sito Web Tekla Warehouse può essere utilizzato senza Tekla Warehouse Service o Tekla Structures. L'esperienza sarà quindi più simile a quella con un sito Web di condivisione di contenuto in cui i file vengono caricati e scaricati manualmente.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Come ottenere Tekla Warehouse Service?",
          description_1:
            "Il plug-in Tekla Warehouse Service viene installato con Tekla Structures 20.1 Service Release 1 e versioni successive. È inoltre possibile installare separatamente Tekla Warehouse Service per consentire l'uso delle raccolte locali e di rete, tuttavia l'installazione semplice e le funzioni di caricamento sono garantite solo con Tekla Structures 20.1 Service Release 1 e versioni successive.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">DOWNLOAD TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures e Tekla Warehouse",
          description_1:
            "Tekla Warehouse è collegato direttamente con Tekla Structures facilitando l'utilizzo del contenuto e il relativo caricamento. È possibile selezionare il contenuto direttamente dal modello e inserirlo inoltre direttamente nel modello senza doversi preoccupare dei file di importazione/esportazione (a tale scopo, è necessario disporre di Tekla Structures 20.1 Service Release 1 e versioni successive). Tutti i file necessari possono essere inclusi nello stesso elemento di contenuto, come un componente personalizzato o un profilo, per assicurarsi che tutto funzioni come previsto.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Contenuto in Tekla Warehouse",
        description_1:
          "Tekla Warehouse offre diversi tipi di contenuti. Di seguito è riportata una panoramica dei diversi tipi di contenuto e ulteriori informazioni su come iniziare.",
        description_2:
          'Da Tekla Warehouse è inoltre possibile scaricare le <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">estensioni Tekla</a>.',
        "3d_products": {
          title: "Prodotti modelli 3D",
          image_subtitle: "Trattasi di oggetti con geometria fissa, ad esempio inserti, casseforma e accessori.",
          tua_link_html:
            'Vedere Tekla User Assistance per ulteriori informazioni sulla <a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">creazione di un catalogo di prodotti 3D in una raccolta Tekla Warehouse</a>.',
        },
        custom_components: {
          title: "Componenti personalizzati",
          image_subtitle:
            "I componenti personalizzati sono oggetti nativi di Tekla Structures con geometria fissa o parametrica.",
          tua_link_html:
            'Vedere Tekla User Assistance per ulteriori informazioni sul <a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">caricamento di contenuti da un modello Tekla Structures da una raccolta Tekla Warehouse</a>.',
        },
        applications: {
          title: "Applicazioni",
          image_subtitle:
            "Le applicazioni utilizzano Tekla Open API per interagire con Tekla Structures. Ad esempio, link di integrazione dati alle soluzioni di progettazione.",
          tua_link_html:
            'Vedere Tekla User Assistance per ulteriori informazioni sul <a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">caricamento di un\'applicazione in una raccolta Tekla Warehouse</a>.',
        },
        profiles_shapes_etc: {
          title: "Profili, sagome, materiali, assemblaggi bulloni, barre d'armatura e reti",
          image_subtitle:
            "I cataloghi contengono liste di proprietà relative alle parti. Ad esempio, i diversi tipi di materiali e le relative densità.",
          tua_link_html:
            'Vedere Tekla User Assistance per ulteriori informazioni sul <a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">caricamento di contenuti da un modello Tekla Structures da una raccolta Tekla Warehouse</a>.',
        },
        model_templates: {
          title: "Modelli prototipo",
          image_subtitle:
            "Un modello prototipo può essere utilizzato per progetti simili ricorrenti. Il template può contenere tutti i cataloghi necessari, modelli di report, impostazioni di disegno e impostazioni delle opzioni avanzate.",
          tua_link_html:
            'Vedere Tekla User Assistance per ulteriori informazioni sull\'<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">aggiunta manuale dei file a una raccolta Tekla Warehouse</a>.',
        },
        drawing_setup: {
          title: "Impostazioni disegno",
          image_subtitle:
            "I file di impostazione definiscono la modalità di creazione dei disegni, ad esempio layout, filtri e template di clonazione.",
          tua_link_html:
            'Vedere Tekla User Assistance per ulteriori informazioni sull\'<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">aggiunta manuale dei file a una raccolta Tekla Warehouse</a>.',
        },
        report_templates_etc: {
          title: "Modelli di report e file degli attributi del modello",
          description_1:
            "I modelli di report definiscono il contenuto e il layout dei dati del modello in un report. I file degli attributi del modello, ad esempio i filtri, funzionano quando vengono aggiunti alla cartella modello.",
          tua_link_html:
            'Vedere Tekla User Assistance per ulteriori informazioni sull\'<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">aggiunta manuale dei file a una raccolta Tekla Warehouse</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Utilizzo del contenuto",
        description_1:
          "In base al tipo di contenuto, è possibile inserire il contenuto scelto nel modello Tekla Structures oppure scaricarlo e installarlo. Vedere Tekla User Assistance per istruzioni su",
        feature_1:
          '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Ricerca del contenuto in Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Utilizzo di contenuto da Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Inserimento di contenuto Tekla Warehouse in un modello Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Gestione dei conflitti in Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Creazione di contenuto",
        getting_started_with_content_creation: {
          title: "Per iniziare con la creazione di contenuto",
          description_1: "Vedere Tekla User Assistance per ulteriori informazioni su:",
          feature_1:
            '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Guida introduttiva a Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">Aggiunta di contenuto a Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Modifica del contenuto in Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Download di contenuto da Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Richiesta assistenza di esperti per la creazione di contenuti",
          description_1:
            'Se si è interessati a pubblicare contenuto in Tekla Warehouse, ma si desidera avvalersi dell\'assistenza di esperti per la creazione e la manutenzione del contenuto, contattare <a href="http://www.tekla.com/contact" target="_blank">l\'ufficio o il rivenditore Tekla</a> di zona per ulteriori informazioni sulle possibilità di collaborazione.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Sviluppo di applicazioni per Tekla Structures",
        description_1:
          "Poiché Tekla ha un approccio aperto verso il Building Information Modeling (BIM), è possibile utilizzare le soluzioni e le macchine per la produzione di altri fornitori e interfacciarsi comunque con Tekla.",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> è un\'interfaccia API sviluppata da Tekla che consente di sviluppare applicazioni e funzionalità aggiuntive sulla piattaforma di modellazione di Tekla Structures e di integrarla nel proprio ambiente. Tekla Open API viene implementata utilizzando la tecnologia Microsoft .NET.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center</a> è l\'hub per tutte le informazioni Tekla Open API.',
        description_4:
          'Partecipare al <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> se si è interessati a sviluppare applicazioni per Tekla Structures.',
      },
    };
  },
};
