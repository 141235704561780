
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Nl = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["nl-NL"]) {
      window.locale["nl-NL"] = {};
    }

    _.extend(window.locale["nl-NL"], {
      myDetails: {
        my_roles: "Mijn rollen",
        my_roles_link: "Rollen en toegangsrechten voor Tekla Warehouse inhoud",
        my_roles_link_url: "https://support.tekla.com/nl/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Rollen",
        roles: {
          member: "Tekla Warehouse Inhoudseditor (Medewerker)",
          employee: "Medewerker",
          contentEditor: "Tekla Warehouse Inhoudseditor (Extern)",
        },
      },
      immutability: {
        add_code_name_for_package: "Een codenaam voor de inhoud toevoegen",
        code_name: "Codenaam",
        immutable: "Onveranderlijk",
        archive: "Archiveren",
        archive_collection: "De verzameling en de gehele inhoud archiveren",
        archive_version: "De geselecteerde versie archiveren",
        archive_package: "Het pakket en de gehele inhoud archiveren",
        archive_dialog_text: "Gearchiveerde inhoud is niet zichtbaar of doorzoekbaar in de Tekla Warehouse-cloudservice. Als de inhoud echter eerder met de Tekla Structures cloudservice is gebruikt, kan de cloudservice toegang tot de inhoud blijven krijgen.",
        archive_success: function (name: string) {
          return `${name} succesvol gearchiveerd`;
        },
        archive_failure: function (name: string) {
          return `Archiveren van ${name} is mislukt`;
        },
        archived_content_redirect_message: "De inhoud waar u toegang toe probeert te krijgen, is gearchiveerd.",
        edit_title_move_or_archive: "Titel bewerken, verplaatsen of archiveren",
        edit_title_or_archive: "Titel bewerken of archiveren",
        major_update: "Belangrijke update",
        major_update_description: "Wijzigingen die niet compatibel zijn met oudere versies, zoals het toevoegen of verwijderen van functies of andere wijzigingen die fouten veroorzaken.",
        minor_update: "Kleine update",
        minor_update_description: "Wijzigingen die compatibel zijn met oudere versies, zoals nieuwe functies of verbeteringen zonder fouten te veroorzaken in bestaande functionaliteit.",
        patch_update: "Patch-update",
        patch_update_description: "Achterwaartse compatibele oplossingen zoals beveiligingspatches, foutoplossingen of prestatieverbeteringen zonder nieuwe functies.",
        info: "Uw organisatie biedt inhoud voor de Tekla Structures-cloudservice. Om compatibiliteit en consistentie te garanderen, zijn voor de inhoud in de Tekla Structures-cloudservice een codenaam en versienummer vereist.",
        immutable_files_info: "Om de consistentie van de inhoud in de cloudservice te bewaren kunt u geen bestanden verwijderen of wijzigen. Als wijzigingen nodig zijn, archiveer dan de huidige versie en upload een nieuwe versie.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Zie <a href="${readMoreUrl}" target="_blank">deze pagina</a> voor meer informatie.`;
        },
        resolve_conflict: {
          title: "Conflict oplossen",
          description:
            "De volgende items zijn gevonden. Selecteer Bestaande bewaren om het in uw installatie gevonden bestaande item te bewaren.",
          table_header_name: "Naam",
          table_header_type: "Type",
          table_header_action: "Actie",
          action_keep_existing: "Bestaande bewaren",
          action_replace: "Vervangen",
          action_done: "Gereed",
        },
        auth: {
          authentication_process_failed: "Verificatieproces is mislukt",
          access_denied: "Toegang geweigerd",
          handle_unauthorized: {
            unauthorized: "Niet geautoriseerd",
            please_log_in: "Log in of registreer",
          },
        },
        translations: {
          edit_translations: "Vertalingen bewerken",
          edit_translations_info: "Selecteer een taal in de lijst, bewerk en sla op",
          edit_translations_default_language_info:
            "<b>Standaard</b> wordt weergegeven als de door de gebruiker geselecteerde taal niet beschikbaar is",
          edit_content: "Inhoudsgegevens",
          edit_support: "Ondersteuning",
          updated_successfully: "Vertalingen bijgewerkt",
          error_on_update: "Fout tijdens het bijwerken van vertalingen",
          default: "Standaard",
          batch_translations: "Batchvertalingen",
          export_import_info:
            "<b>Exporteer</b> de batchvertalingentemplate voor inhoud van uw keuze, bewerk de template zodat deze de vertalingen toevoegt en <b>importeer</b> de template terug om meerdere vertalingen in één keer op te slaan.",
          import_translations: "Importeren",
          export_translations: "Exporteren",
          translations_missing: " (leeg)",
          select_content: "Selecteer te exporteren vertalingen:",
          import_finished: "Importeren is voltooid.",
          select_file: "Bestand selecteren",
          select_file_title: "Selecteer te importeren bestand:",
          import_from_file: "Importeren",
          import_error_info: "Bestand volgt de template-indeling niet:",
          export_selected: "Exporteren",
          invalid_file: "Ongeldig indeling van de bestandsinhoud",
          import_translations_finished: "Vertalingen geïmporteerd",
          details_too_long: "Details van de inhoud overschrijden de maximale toegestane lengte",
          import_failed: "Importeren vertalingen is mislukt",
          translate_to_other_languages: "Naar andere taal vertalen",
          import_count: function (count) {
            return "Bijgewerkte vertalingen voor " + count + " inhoud";
          },
          invalid_language: function (contentId, language) {
            return "De inhoud '" + contentId + "' bevat een ongeldige taal '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "De inhoud '" + contentId + "' bevat een ongeldig vertalingsveld '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "De waarde van de inhoud '" +
              contentId +
              "' van het vertalingsveld '" +
              translationField +
              "' overschrijdt de maximaal toegestane lengte"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "3D-model",
          "3d_pic_added": "3D-model toegevoegd",
          "3d_pic_updated": "3D-model bijgewerkt",
          "3d_pic_removed": "3D-model verwijderd",
          "3d_pic_update_failed": "3D-model bijwerken is mislukt",
          "3d_pic_remove_failed": "3D-model verwijderen is mislukt",
          "3d_pic_missing":
            "Geen 3D-afbeelding voor inhoud. Kies afbeelding door hieronder op '3D-model kiezen' te klikken.",
          "3d_pic_uploading": "Uw model wordt geladen. Even geduld...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Hier klikken om de inhoud te bewerken",
          updated_successfully: "Bijwerken is geslaagd",
          update_failed: "Bijwerken is mislukt",
          edit_search_criteria: "Zoekcriteria bewerken",
          edit_group_and_category: "Groep en categorie bewerken",
          edit_content: "Inhoud bewerken",
          edit_thumbnail: "Miniatuur bewerken",
          edit_thumbnail_descriptive: "Aanbevolen afmetingen: 400 x 300 px",
          edit_description: "Beschrijving bewerken",
          edit_download_link: "Downloadkoppeling voor partners toevoegen of bewerken",
          versions_not_shown_to_users:
            "Omdat dit inhoudsitem een downloadkoppeling voor partners heeft, kunnen Tekla Warehouse-gebruikers geen versies zien die u eerder aan het inhoudsitem hebt toegevoegd. Tekla Warehouse-beheerders en -gebruikers met bewerkingsrechten kunnen nog steeds alle versies van het inhoudsitem zien.",
          partner_download_link: "De download-URL invoeren",
          partner_download_link_disclaimer:
            "Als u een downloadkoppeling voor partners aan een bestaand inhoudsitem toevoegt, kunnen Tekla Warehouse-gebruikers niet langer versies zien die u eerder aan het inhoudsitem hebt toegevoegd. Deze actie kan niet ongedaan worden gemaakt.",
          edit_title_or_delete: "Titel bewerken of verwijderen",
          edit_title_or_move_delete: "Titel bewerken, verplaatsen of verwijderen",
          edit_support: "Ondersteuningsgegevens bewerken",
          edit_release_notes: "Release Notes bewerken",
          edit_content_wysiwyg: "Inhoudsgegevens bewerken",
          edit_content_embed_link: "Meer informatie in Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "U kunt alleen video's van YouTube, Vimeo of Alugha insluiten.",
          details_unallowed_embed_info: "U kunt alleen inhoud van de volgende domeinen insluiten: YouTube, Vimeo of Alugha. De toegestane URL-indelingen zijn:",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "Ongeldige videokoppeling. U kunt video's van YouTube en Vimeo toevoegen door middel van een URL",
          edit_content_vide_code_error: "Ongeldige insluitingsindeling. Alleen iframes worden geaccepteerd. Toegestane video-providers zijn YouTube, Vimeo en Alugha.",
          details_max_length_exceeded: "De inhoud Details is te lang",
          editor_mode_label: "Editormodus:",
          editor_mode_on: "Aan",
          editor_mode_off: "Uit",
          do_not_notify: "Kleine update, informeer de abonnees niet",
          invalid_tag: "Ongeldige tag",
        },
        transfer_package: {
          header: "Inhoud verplaatsen",
          actions: {
            move: "Verplaatsen",
            cancel: "Annuleren",
          },
          loading: "Verzamelingen laden...",
          moving: "Inhoud verplaatsen",
          select_collection: "Verzameling selecteren",
          content_moved: "Inhoud verplaatst. De wijzigingen zijn mogelijk na een paar minuten zichtbaar.",
          operation_failed: "Bewerking is mislukt",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Inhoud gekoppeld in " + name;
          },
          selected_resources_linked: function (name) {
            return "Geselecteerde inhoud gekoppeld in " + name;
          },
          resources_linked_with_errors: "Inhoud gekoppeld, er zijn fouten opgetreden bij een deel van de inhoud",
          resources_linking_failed: "Inhoud koppelen is mislukt",
          resource_unlinked: "Koppeling is verwijderd. De wijzigingen zijn mogelijk na een paar minuten zichtbaar.",
          resources_unlinked:
            "Koppelingen met de geselecteerde inhoud verwijderd. De wijzigingen zijn mogelijk na een paar minuten zichtbaar.",
          resources_unlinked_with_errors:
            "Koppelingen verwijderd, er zijn fouten opgetreden met een deel van de inhoud",
          resources_unlinking_failed: "Verwijderen van koppeling is mislukt",
          linking: "Koppeling maken",
          linking_failed: "Koppelen is mislukt",
          linking_failed_title: "De volgende inhoud kan niet worden gekoppeld:",
          unlinking_failed_title: "De volgende inhoud kan niet worden ontkoppeld:",
          linking_failed_already_contains:
            "De inhoud is al aan geselecteerde verzameling gekoppeld. Het kan even duren voordat de updates verschijnen.",
          linking_failed_already_contains_short: "De inhoud is al aan de geselecteerde verzameling gekoppeld",
          unlinking_failed: "Verwijderen koppeling is mislukt",
          unlinking_failed_does_not_contain:
            "De inhoudskoppeling is al uit de verzameling verwijderd. Het kan even duren voordat de updates verschijnen.",
          unlinking_failed_does_not_contain_short: "De inhoudskoppeling is al uit de verzameling verwijderd",
        },
        notification_channel: {
          title_success: "succes",
          title_notice: "melding",
          title_info: "informatie",
          title_error: "fout",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Downloaden vereist Tekla Maintenance",
          can_access_maintenance: {
            yes: "Ja",
            no: "Nee",
          },
        },
        confirm: {
          title: "Bevestigen",
          yes: "Ja",
          no: "Nee",
          ok: "OK",
          close: "Sluiten",
          dont_show: "Dit niet meer weergeven",
        },
        show_more: " Meer weergeven",
        show_less: " Minder weergeven",
        spinner_loading: "Laden",
        spinner_processing: "Verwerken",
        spinner_loading_long_time: "Het laden van inhoud kan enkele minuten duren",
        local_service: {
          info: "De plugin Tekla Warehouse Service is nodig om lokale en netwerkinhoud te maken. Met Tekla Structures 20.1 SR1 of later kunt u inhoud rechtstreeks vanuit uw model uploaden.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-plugin</a> " +
            "is nodig om lokale en netwerkinhoud te maken.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-plugin</a> " +
            "is niet beschikbaar. De service is nodig om lokale en netwerkverzamelingen in te schakelen.",
          download_to_unlock_all_features_html:
            "Downloaden " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-plugin</a> " +
            "om alle functies te ontgrendelen.",
          service_out_of_date_html:
            "Uw plugin Tekla Warehouse is verouderd. Bijwerken " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-plugin</a> " +
            "om alle functies te ontgrendelen.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Taal selecteren",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Andere gebruikers uitnodigen",
          send_invitation_to_email: "Uitnodiging naar e-mailadres verzenden",
          add_e_mail_addresses: "E-mailadressen toevoegen",
          and_assign_role: "en rol toewijzen",
          send_invitation: "Uitnodiging verzenden",
          invitation_already_sent: "De uitnodiging is al verzonden.",
          invitation_sent: "De uitnodiging is verzonden.",
          update_selected_invitations: "Geselecteerde uitnodigingen bijwerken",
          new_role: "Nieuwe rol",
          invitations_list_updated: "De lijst met uitnodigingen is bijgewerkt.",
        },
        item_browser_title: "Inhoudsitems selecteren",
        prerequisites: {
          description_placeholder: "Beschrijving hier toevoegen",
          link_name_placeholder: "Koppelingsnaam hier toevoegen",
          add_prerequisites_and_recommendations: "Vereisten en aanbevelingen (optioneel)",
          list_the_profiles_components_files:
            "Geef de profielen, componenten, bestanden of modellen weer die voor de inhoud nodig zijn om correct te kunnen werken.",
          link_to_existing_content: "Koppeling naar bestaande inhoud",
          please_enter_prerequisite_url: "Voer een vereiste URL in",
          please_enter_valid_prerequisite_url: "Voer een geldige vereiste URL in",
          missing_http: "Ontbrekende http://",
          add_link: "Koppeling toevoegen",
        },
        treeview: {
          cannot_export_this_container:
            "Kan deze container niet exporteren. Selecteer in plaats daarvan individuele items.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "Bestand moet kleiner zijn dan " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Ongeldig bestandstype.  Het bestand moet een van de volgende typen zijn " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Periode:",
          timeframe_start: "Start:",
          timeframe_end: "Eind:",
          eventtype: "Gebeurtenistype:",

          downloads_daily_by_version: "Downloads per versie",
          downloads_by_country: "Downloads per land",
          downloads_by_version: "Downloads per versie",
          downloads_by_version_organization: "Downloads per versie en organisatie",
          downloads_by_organization: "Downloads per organisatie",
          downloads_daily_by_package: "Downloads per inhoudsitem",
          views_daily_by_package: "Weergaven per inhoudsitem",
          views_by_country: "Weergaven per land",
          views_by_organization: "Weergaven per organisatie",

          downloads_by_package: "Downloads per inhoudsitem",
          views_by_package: "Weergaven per inhoudsitem",
          downloads_daily_by_collection: "Downloads per verzameling",
          downloads_by_collection: "Downloads per verzameling",
          views_daily_by_collection: "Weergaven per verzameling",
          views_by_collection: "Weergaven per verzameling",

          events_by_organization_func: function (version) {
            return 'Versie "' + version + '" gebeurtenissen op organisatie'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Andere",
          sum_all_versions: "Alle versies",
          sum_all_packages: "Alle inhoudsitems",
          sum_all_collections: "Alle verzamelingen",
          sum_all_organizations: "Alle organisaties",
          recording_started_at: "Opnemen gegevens gestart in juni 2015",
          recording_view_events_started_at: "Opnemen gegevens gestart in maart 2017",
          version_title_func: function (title) {
            return 'Versie "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " downloads", count + " downloaden", count + " downloads"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "De getallen bevatten alle voltooide downloads.",
          counted_events_info_package: "De getallen bevatten alle voltooide downloads.",
          export: {
            caption: "Analyses exporteren",
            select_options: "Opties selecteren",
            select_timeframe_description: "Selecteer hieronder een periode om analyses te exporteren van:",
            select_eventtype_description: "Selecteer het gebeurtenistype om analyses van te exporteren:",
          },
        },
        access_rights: {
          editors: "Editors",
          download_access: "Toegang weergeven en downloaden",
          write_user_id_placeholder: "Gebruikers-ID schrijven",
          write_organization_id: "Voer geldige organisatie-id's in. Voer elke ID op een aparte rij in.",
          write_id_placeholder:
            "Voer gebruikers-id's, e-mailadressen van gebruikers of organisatie-id's in. Voer elke id of e-mailadres in een aparte rij in.",
          id_help_html_short:
            "Onze gebruikers zijn om veiligheidsredenen niet te zoeken.<br/><br/>" +
            "Vraag de gebruikers-ID rechtstreeks aan de persoon aan wie u toegang wilt verlenen. <br/>" +
            'Gebruikers-ID wordt weergegeven in <a href="#/my/">Mijn profiel</a>.',
          id_help_html:
            "Onze gebruikers zijn om veiligheidsredenen niet te zoeken.<br/><br/>" +
            "Vraag de gebruikers-ID rechtstreeks aan de persoon aan wie u toegang wilt verlenen. Als u aan alle gebruikers in een organisatie toegang wilt verlenen, gebruikt u de organisatie-ID.<br/><br/>" +
            'De gebruikers- en organisatie-ID\'s worden in <a href="#/my/">Mijn profiel</a> weergegeven.',
          max_input_limit: function (max_rows) {
            return "Voer elke id of e-mailadres in een aparte rij in. U kunt " + max_rows + " rijen tegelijk invoeren.";
          },
          input_too_long: "De tekst die u hebt ingevoerd is te lang.",
          viewer_already_added: function (input) {
            return `${input} is al toegevoegd als kijker`;
          },
          wrong_format: function (input) {
            return `${input} is geen geldige id of geen geldig e-mailadres`;
          },
          candidates_for_keyword: function (keyword) {
            return "Gebruikers gevonden met het e-mailadres of de id " + keyword;
          },
        },
      },
      images: {
        add_new: "Nieuw toevoegen",
        add: "Toevoegen",
        delete: "Verwijderen",
        set_as_default: "Standaard miniatuur",
        select_file: "Bestand selecteren",
        choose_option: "Optie kiezen",
        info_3D_exists: "U hebt al een 3D-model toegevoegd. U kunt niet meer dan één model aan een inhoud toevoegen.",
        action_options: {
          image: "Afbeelding",
          "3DModel": "3D-model",
          video: "Video",
        },
        video: {
          url: "Video-URL",
          invalid_url: "Ongeldige URL",
        },
      },
      units: {
        bytes: "bytes",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Downloaden",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " likes", number + " like", number + " likes"]);
      },
      main_info: {
        header_title: "De website van Tekla Warehouse heeft een nieuw uiterlijk!",
        header_text: "Hoe vind u de nieuwe gestroomlijnde website van Tekla Warehouse?",
        header_text2: "De belangrijkste wijzigingen zijn:",
        bullet_text_1: "Duidelijk Zoeken op de openingspagina.",
        bullet_text_2:
          "Alle zoekfilters op de pagina Zoeken staan links en de standaard miniatuurweergave heeft meer zoekresultaten in hetzelfde venster.",
        bullet_text_3:
          "De optie Downloaden op de pagina Inhoud is centraal geplaatst zodat het eenvoudig is om inhoud te gaan gebruiken. De opties Downloaden en Installeren zijn nu beide toegankelijk met de knop Downloaden.",
        bullet_text_4:
          "Zowel de pagina Verzameling als Mijn winkelwagentje heeft een drie-stappeninstructie voor het gebruik van het downloaden en het installeren van alles.",
      },
      organizations: {
        error_loading_collection_contents: "Fout bij het laden van verzamelingsinhoud",
        error_loading_organization: "Fout bij het laden van de organisatie",
      },
      downloads_graph: {
        date: "Datum",
        total_views: "Totaal aantal aanzichten",
        total_downloads: "Totaal aantal downloads",
        views: "Aanzichten",
        downloads: "Downloads",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service-plugin" +
          "</a>" +
          " is nodig.",
        processingFailed: "Verwerking is mislukt",
        forbidden: "Verboden",
        resource_not_found: "Bron niet gevonden",
        no_matches_found: "Geen resultaten gevonden",
        no_matches_found_for: function (input) {
          return "Er zijn geen overeenkomsten gevonden met het e-mailadres of de id " + input;
        },
        not_identified: "Niet geïdentificeerd",
        error_while_searching: "Fout bij het zoeken",
        error_user_not_found: "Gebruiker of organisatie niet gevonden",
        error_while_adding_member: "Fout tijdens het toevoegen van een toegangsrecht aan een lid",
        error_while_removing_member: "Fout tijdens het verwijderen van een toegangsrecht van een lid",
      },
      offline_to_online: {
        fail_on_initialize: "Fout bij het verwerken van zip-inhoud, converteren wordt afgebroken",
        fail_on_validate: "Validatie inhoud metagegevens is mislukt, converteren wordt afgebroken",
        fail_on_save: "Fout opgetreden tijdens het opslaan van inhoud, wijzigingen worden teruggedraaid",
        success: "Offline inhoud geconverteerd naar online inhoud",
        validate_errors_title: "Converteren inhoud is mislukt",
        processing: "Pakket opslaan ",
        processed: "Verwerkt item",
        collection_thumbnail_missing_name: "'filename' ontbreekt aan verzameling binaire miniatuurbestanden",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return (
            "De 'bestandsnaam' van het binair bestand van de miniatuur van pakket '" + packageTitle + "' ontbreekt"
          );
        },
        package_value_missing_title_func: function () {
          return "De 'titel' van het pakket ontbreekt";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Het '" + field + "' van pakket '" + packageTitle + "' ontbreekt";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "Het veld '" +
            field +
            "' van pakket '" +
            packageTitle +
            "' heeft de volgende ongeldige waarden: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Het pakket '" + packageTitle + "' heeft een ongeldige tag: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "De 'titel' van de versie van pakket '" + packageTitle + "' ontbreekt";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return (
            "Het veld '" + field + "' van de versie '" + versionTitle + "' van pakket '" + packageTitle + "' ontbreekt"
          );
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Het veld '" +
            field +
            "' van de versie '" +
            versionTitle +
            "' van pakket '" +
            packageTitle +
            "' heeft de volgende ongeldige waarden: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return (
            "De 'bestandsnaam' van de binaire versie '" + versionTitle + "' van pakket '" + packageTitle + "' ontbreekt"
          );
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Belangrijk: Einde van de ondersteuning voor lokale verzamelingen in Tekla Warehouse",
            part1: "Vanaf 2 juni 2025 zullen lokale verzamelingen niet langer beschikbaar zijn in Tekla Warehouse. Gebruikers zullen geen lokale verzamelingen meer kunnen aanmaken, bekijken of doorzoeken, of inhoud uit lokale verzamelingen kunnen gebruiken.",
            part2: "Voor hulp bij de overgang van lokale verzamelingen naar online verzamelingen, neem contact op met uw lokale ondersteuningsorganisatie.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Belangrijk! Ter verbetering van de beveiliging moet u na ${date} november 2024 de rol Tekla Warehouse Inhoudseditor hebben om de inhoud van uw organisatie te beheren. <br />Vraag de beheerder van uw organisatie om de rol aan u toe te wijzen. Raadpleeg voor meer informatie het <a href="${productBulletinUrl}" target="_blank">productbulletin</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Ter verbetering van de beveiliging moet u na ${date} november 2024 de rol Tekla Warehouse Inhoudseditor hebben om de inhoud van uw organisatie te beheren. <br />Vraag de beheerder van uw organisatie om de rol aan u toe te wijzen. Raadpleeg voor meer informatie het <a href="${productBulletinUrl}" target="_blank">productbulletin</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Ziet u geen bewerkingsoptie voor uw organisatie? Na ${date} november moet u de rol Tekla Warehouse Inhoudseditor hebben om de inhoud van uw organisatie te beheren. <br />U kunt uw rol onder Mijn Warehouse > Mijn details controleren. Raadpleeg voor meer informatie het <a href="${productBulletinUrl}" target="_blank">productbulletin</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Ziet u geen optie om inhoud voor uw organisatie te maken? Na ${date} november moet u de rol Tekla Warehouse Inhoudseditor hebben om de inhoud van uw organisatie te beheren. <br />U kunt uw rol onder Mijn Warehouse > Mijn details controleren. Raadpleeg voor meer informatie het <a href="${productBulletinUrl}" target="_blank">productbulletin</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Na ${date} november moet u de rol Tekla Warehouse Inhoudseditor hebben om de inhoud van uw organisatie te beheren. Vraag de beheerder van uw organisatie om de rol aan u toe te wijzen. <br />U kunt in uw Tekla Online-profiel zien wie de beheerder van uw organisatie is. Raadpleeg voor meer informatie het <a href="${productBulletinUrl}" target="_blank">productbulletin</a>.`
            },
          },
          service_plugin_update_info:
            "Heb je problemen met het verbinden met Tekla Structures? Zorg ervoor dat je de nieuwste <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service plugin</a> gebruikt.",
        },
        maintenance_info:
          "Tekla Warehouse is in onderhoud op 2017-10-27 van 22.00 uur tot 23.00 uur UTC. Onze excuses voor het ongemak.",
        release_notes_info: "Nieuwe functies in Tekla Warehouse",
        release_notes_link:
          'Tekla Warehouse is bijgewerkt. Lees <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">wat er nieuw is</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Hebt u een Tekla-ontwikkelaar voor uw project nodig?",
        heading2Dev:
          'Bekijk de nieuwe map met de naam Tekla Development Experts.<br><br>Het is een plaats waar getalenteerde programmeurs en geïnteresseerde Tekla-gebruikers kunnen samenkomen.<br><br>Alle leden van het Tekla Partners Program zijn van harte welkom om hun gegevens aan de Experts-map toe te voegen.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Zoek een ontwikkelaar &rsaquo;</a>',
        static_intro:
          "Dit is de vrije Tekla Structures BIM-opslag waar u zowel intern als wereldwijd inhoud kunt zoeken, importeren, installeren en delen. Het is de bedoeling van Tekla Warehouse om het eenvoudig te maken om modellen van een hoge kwaliteit efficiënter te produceren.",
        new_intro:
          'Tekla Corporation heet nu Trimble. We blijven Tekla-softwareoplossingen ontwikkelen en dezelfde mensen zijn er voor u. Meer informatie: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Controleer uw account",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Nieuwste uploads &rsaquo;",
          popular: "Populaire inhoud &rsaquo;",
          recommended: "Aanbevolen &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Nieuws &rsaquo;</a>',
          item_title: "Dit is nieuws.",
          item_description:
            'Wat aan nieuws gerelateerde tekst, wat aan nieuws gerelateerde tekst, wat aan nieuws gerelateerde tekst, wat aan nieuws gerelateerde tekst, wat aan nieuws gerelateerde tekst, wat aan nieuws gerelateerde tekst.<br><a href="https://developer.tekla.com" target="_blank">Lees meer &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse in aantallen",
          partners: "Partners",
          partners_count: "352",
          collections: "Verzamelingen",
          collections_count: "793",
          items: "Inhoudsitems",
          items_count: "2893",
        },
        partners: "Onze partners",
        action_links: {
          search_title: "Naar inhoud bladeren?",
          upload_title: "Inhoud uploaden?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Video\'s &rsaquo;</a>',
          link: 'Ga voor meer video\'s naar ons <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Youtube-kanaal</a>.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Controleer uw e-mail op een controlekoppeling of <u><a href="' +
            settingsUrl +
            '">vraag een nieuwe koppeling aan</a></u>.'
          );
        },
        verifyAccountAcceptTerms: "Controleer uw e-mailadres en accepteer de voorwaarden en het privacybeleid",
        acceptTerms: "Accepteer de voorwaarden en het privacybeleid",
        acceptUpdatedTerms: "Bevestig de bijgewerkte voorwaarden en het privacybeleid van tekla online services",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Terug naar uw <u><a href="' + settingsUrl + '">profiel</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Controleer en accepteer de voorwaarden en het privacybeleid in uw <u><a href="' +
            settingsUrl +
            '">profiel</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Door verzamelingen bladeren &rsaquo;",
          new: "Nieuw",
          popular: "Populair",
          recommended: "Aanbevolen",
          organizations: "Organisaties",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Map selecteren",
        selectFolderInstructions: "Lokale en netwerkmappen kunnen als verzamelingen worden gebruikt.",
        selectedFolder: "Geselecteerde map (kopieer-en-plaklocatie):",
        selectedFolderPlaceholder: "Bijvoorbeeld C:\\map, Z:\\netwerkmap, \\\\machinenaam\\map",
        collectionCreation: {
          name: "Naam",
          createNewCollection: "Een lokale verzameling maken",
          pleaseInputTitle: "Voer een titel in",
          invalidPath: "Ongeldig pad",
          access_denied: "Toevoegen verzameling is mislukt: toegang geweigerd",
          unsafe_location: "Toevoegen verzameling is mislukt: onveilig locatie",
          add_collection_failed: "Toevoegen verzameling is mislukt",
          minLengthError: "De titel moet minimaal drie tekens bevatten",
          maxLengthError: "De titel mag niet langer dan 20 tekens zijn",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Lokale en netwerkmappen kunnen als verzamelingen worden gebruikt.",
        collectionCreation: {
          name: "Naam",
          createNewCollection: "Een online verzameling maken",
          pleaseInputTitle: "Voer een titel in",
          minLengthError: "De titel moet minimaal 3 tekens bevatten",
          maxLengthError: "De titel mag niet langer dan 20 tekens zijn",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Bron opgeslagen",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Ziet u het potentieel?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Geef ons feedback</span>',
          help_us_improve_html:
            'Help ons Tekla Warehouse te verbeteren. <br/><a id="showSurvey">Klik hier</a> of <a id="dontShow">geef dit niet meer weer</a>',
          dialog: {
            do_you_see_potential: "Ziet u het potentieel?",
            help_us_improve_html: "Help ons Tekla Warehouse te verbeteren. Uw mening is belangrijk voor ons.",
            continue_to_survey: "Enquête starten",
            exit_to_warehouse: "Nee bedankt",
          },
        },
      },
      search: {
        bar: {
          search: "Zoeken",
          filter: " ",
          more_filters: "Meer filters",
          hide_filters: "Filters verbergen",
          clear: "Wissen",
          exclude: "Uitsluiten",
          include: "Opnemen",
          clear_filters: "Filters opnieuw instellen",
          load_defaults: "Mijn voorkeuren gebruiken &rsaquo;",
          save_defaults: "Als mijn voorkeuren opslaan &rsaquo;",
          defaults_saved_successfully: "Standaardfilters opgeslagen",
          edit_defaults: "Standaards bewerken",
        },
        info: {
          searching_organizations: "Zoeken in organisaties...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Geen resultaten", amountTotal + " resultaten"]);
            } else {
              return (
                pluralize(amountTotal, ["Geen resultaten", amountTotal + " resultaten"]) +
                ' voor "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " resultaten";
            } else if (!searchTerm) {
              return "Een zoekterm invoeren om organisatieresultaten te zien";
            } else if (searchTerm.length < 2) {
              return "Ten minste twee tekens geven voor zoeken in organisatie";
            } else {
              return (
                pluralize(amountTotal, ["Geen resultaten", amountTotal + " resultaten"]) +
                ' voor "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " resultaten in online inhoud",
          local: " resultaten in lokale inhoud",
          organization: "resultaten in organisaties",
          plugin_missing_info:
            'Lokale inhoud wordt alleen weergegeven als u <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service-plugin" +
            "</a>" +
            " hebt geïnstalleerd.",
          version_filter_info: "Het filteren van de softwareversie is niet geldig in resultaten van de lokale inhoud",
        },
        exclude_env_files: "Omgevingsbestanden uitsluiten",
        sidebar: {
          filters: {
            measurementUnits: "Maateenheden",
            locations: "Projectlocaties",
            languages: "Talen",
          },
          placeholders: {
            use_categories: "Categorie",
            measurement_units: "Maateenheden",
            languages: "Talen",
            locations: "Locaties",
          },
        },
      },
      placeholders: {
        name: "Naam...",
        contentOwner: "Naam van de inhoudseigenaar...",
        useCategory: "Gebruik selecteren",
        selectType: "Type selecteren",
        filter: "Filteren...",
        search: "Zoeken...",
        choose: "Kiezen...",
        searchFilters: "Filters zoeken...",
        selectContentItem: "Inhoudsitem selecteren...",
        search_content: "Inhoud zoeken...",
        includeAs: "Opnemen als...",
        selectCollection: "Verzameling selecteren",
        selectUserGroup: "Gebruikersgroep selecteren",
        online_collections_tooltip: "Klikken om online inhoud te zoeken",
        local_collections_tooltip:
          "Klikken om lokale en netwerkinhoud, en inhoud die bij de installatie van Tekla Structures is opgenomen te zoeken",
        organizations_tooltip: "Klikken om organisaties te zoeken",
        "filter-options": {
          products: "Alle softwareproducten",
          locations: "Alle locaties",
          languages: "Alle talen",
          measurementUnits: "Alle maateenheden",
          testedVersions: "Alle softwareversies",
          useCategories: "Alle categorieën",
          useCategoriesCombined: "Categorie",
          allGroups: "Alle groepen",
          itemTypeCategories: "Alle itemtypen",
        },
        "exclude-filter-options": {
          products: "Softwareproducten uitsluiten",
          locations: "Locaties uitsluiten",
          languages: "Talen uitsluiten",
          measurementUnits: "Maateenheden uitsluiten",
          testedVersions: "Softwareversies uitsluiten",
          useCategories: "Categorieën uitsluiten",
          useCategoriesCombined: "Categorie uitsluiten",
          allGroups: "Groepen uitsluiten",
          itemTypeCategories: "Itemtypen uitsluiten",
        },
      },
      link: {
        link_selected: "Geselecteerde koppelen",
        unlink_selected: "Geselecteerde ontkoppelen",
      },
      download_install: {
        choose_version: "Kies uw softwareversie:",
        choose_content: "Kies inhoudsitems uit de lijst hieronder:",
        choose_action: "Kies een actie voor geselecteerde items:",
      },
      download: {
        copy_to_clipboard: "Naar klembord kopiëren",
        download_selected: "Downloaden",
        add_selected_items_to_basket: "Geselecteerde aan Mijn winkelwagentje toevoegen",
        add_item_to_basket: "Geselecteerde aan Mijn winkelwagentje toevoegen",
        add_all_items_to_basket: "Alle aan winkelwagentje toevoegen",
        add_all_items_to_basket_info: "Er zijn meer dan 100 items. Laden kan enige tijd duren",
        add_all_items_to_basket_too_many_results: "U kunt niet meer dan 1000 items toevoegen",
        cart_loading: "Er zijn meer dan 100 items. Laden kan enige tijd duren",
        remove_item_from_basket: "Uit winkelwagentje verwijderen",
        shopping_cart_no_content: "Geen inhoud in winkelwagentje",
        shopping_cart_no_content_for_version: "Geen inhoud in winkelwagentje voor de geselecteerde versie",
        shopping_cart_items_func: function (itemsCount) {
          return "Mijn winkelwagentje (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Instructies downloaden",
          step_one: "De downloadgegevens met deze knop naar uw klembord kopiëren",
          step_two_func: function (downloadAppUrl) {
            return (
              "Voer de applicatie Tekla Warehouse Downloader uit. U kunt de applicatie downloaden " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">hier.</a> '
            );
          },
          step_three: "De applicatie Tekla Warehouse Downloader leidt u door de rest van de download.",
          show_data_title: "Werkte het niet?",
          show_data_to_be_copied: "Handmatig kopiëren",
          hide_data_to_be_copied: "Downloadgegevens verbergen",
        },
      },
      install: {
        install_title: " items worden via de plugin van Tekla Warehouse geïnstalleerd.",
        failed_install_information:
          "U hebt geselecteerde inhoud die alleen voor downloaden beschikbaar is. Deze inhoud wordt nu niet geïnstalleerd. Download deze inhoud afzonderlijk van Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Installeren: controleer of uw plugin van Tekla Warehouse Service correct wordt uitgevoerd",
        verify_ts_connection: "Installeren: controleer of Tekla Structures wordt uitgevoerd",
        select_software_version: "Filteren op softwareversie om te koppelen, downloaden of te installeren",
        download_requires_maintenance: "Toegang tot enkele onderstaande items vereist Tekla Maintenance",
        install_selected: "Installeren",
        start_install: "Installatie starten",
        install_started: "De installatie is gestart",
        select_ts_version: "TS-versie selecteren",
        install_retry: "Start opnieuw vanuit Tekla Warehouse als een nieuwe poging nodig is",
        failed_installs_info_func: function (failedCount) {
          return "Inhoud die niet wordt geïnstalleerd (" + failedCount + " versies):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Installatie voor geselecteerde " + totalInstallsCount + " items";
        },
      },
      helpers: {
        behalf: "Door",
        sortBy: "Sorteren op:",
        filter: "Filterresultaten",
        showAsThumbs: "Als miniaturen weergeven",
        showAsList: "Als lijst weergeven",
        resultsPerPage: "Per pagina weergeven:",
        filter_by_version: "Op versie filteren:",
        show_more_options: "Meer opties",
        show_less_options: "Minder opties",
        filter_by_type: "Op type filteren:",
        select_all: "Alles selecteren",
        show_content_items: "Inhoudsitems weergeven",
        choose_action: "Kies actie voor geselecteerde inhoud: ",
        show_collections: "Verzamelingen weergeven",
        search: {
          show: "Weergeven:",
          show_content_items: "Inhoudsitems",
          show_collections: "Verzamelingen",
          show_organizations: "Organisaties",
          show_online_content: "Online inhoud",
          show_offline_content: "Offline inhoud",
          local_content_info:
            'Lokale inhoud wordt alleen weergegeven als u <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " plugin heb geïnstalleerd.",
        },
        tekla_maintenance_required: "Toegang tot enkele onderstaande items vereisen Tekla Maintenance",
        show_content_alerts: "Inhoudswaarschuwingen weergeven ",
        show_collection_alerts: "Collectiewaarschuwingen weergeven ",
      },
      actions: {
        load_more: "Meer laden",
        use_infitinite_scroll: "Oneindig scrollen gebruiken",
        back: "Terug",
        show_download_dialog: "Downloaden",
        download: "Gewoon downloaden",
        download_on_partner_site: "Downloaden op partnersite",
        insert: "Installeren",
        install: "Installeren",
        downloadAndInstall: "Installeren",
        createNew: "Nieuw maken",
        viewItems: "Venster",
        viewCollection: "Verzameling weergeven",
        viewGroup: "Groep weergeven",
        joinGroup: "Aan groep deelnemen",
        loginToDownload: "Bij downloaden inloggen",
        show3DPic: "3D-model weergeven",
        remove: "Verwijderen",
        browse: "Bladeren",
        browseFolder: "Bladeren",
        browseFileSystem: "Bladeren",
        add: "Toevoegen",
        save: "Opslaan",
        batch_edit: "Batchbewerking",
        translations: "Vertalingen",
        cancel: "Annuleren",
        continue: "Doorgaan",
        delete: "Verwijderen",
        addNetworkPath: "Netwerklocatie toevoegen",
        select: "Selecteren",
        create: "Maken",
        update: "Bijwerken/update",
        choose_thumbnail: "Miniatuur kiezen",
        choose_picture: "Afbeelding kiezen",
        remove_picture: "Afbeelding verwijderen",
        choose_3D_picture: "3D-model kiezen",
        remove_3D_picture: "3D-model verwijderen",
        like: "Like",
        unlike: "Unlike",
        close: "Sluiten",
        export: "Exporteren",
        add_or_remove: "Toevoegen/verwijderen",
        select_content_to_convert: "Offline inhoud selecteren",
        search: "Zoeken",
        upload: "Uploaden",
        edit: "Bewerken",
        view_3d: "Klikken om model weer te geven...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "bèta",
        search: "Zoeken",
        profile: "Mijn profiel",
        myWarehouse: "Mijn Warehouse",
        myStuff: "Mijn artikelen",
        logout: "Uitloggen",
        login: "Aanmelden",
        register: "Registreren",
        upload: "Uploaden",
        uploading: "Uploaden",
        dashboard: "Dashboard",
        learnMore: "Meer informatie",
        about: "Info",
        shoppingCart: "Mijn winkelwagentje",
        addToCartInfo:
          "U kunt items in Mijn winkelwagentje verzamelen en deze in één keer downloaden of installeren. Downloaden is volledig gratis",
      },
      cubeNavigation: {
        headings: {
          get_started: "AAN DE SLAG",
          learn: "LEREN",
          get_help: "HULP KRIJGEN",
          extend_your_tekla: "UW TEKLA UITBREIDEN",
          collaborate: "SAMENWERKEN",
          students_and_educators: "Students and educators",
          about: "INFO",
        },
        "tekla-online": "Online services",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Tekla Website",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Mijn profiel",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "TEKLA SERVICES HOME",
        register: "Registreren",
        login: "Inloggen",
        evaluate: "Evalueren",
        buy: "Kopen",
        download: "Downloaden",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Producten",
        teklaServices: "Services",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Mijn verzamelingen",
        myLikes: "Mijn likes",
        profile: "Mijn profiel",
        preferences: "Mijn voorkeuren",
        myUserGroups: "Mijn gebruikersgroepen",
        localCollections: "Lokale en netwerkverzamelingen",
        tsCollections: "Tekla Structures-verzamelingen",
        downloads: "Downloads",
        selectContent: "Inhoudsbestanden selecteren",
        addMetadata: "Metagegevens toevoegen",
        onlineCollections: "Online verzamelingen",
        userGroups: "Gebruikersgroepen",
        createNew: "Inhoud toevoegen",
        notifications: "Meldingen",
        contentUsage: "Inhoudsgebruik",
        browseCollections: "Door verzamelingen bladeren",
        admin: "Admin",
        sales: "Verkoop",
        shoppingCart: "Mijn winkelwagentje",
      },
      notifications: {
        title: "melding",
        invitedBy: "Uitgenodigd door",
        invitationType: "Uitnodigingstype",
        invitedTo: "Uitgenodigd bij",
        createdAt: "Gemaakt op",
        action: "Actie",
        acceptInvitation: "Accepteren",
      },
      batch_edit: {
        selectActionType: "Actietype selecteren",
        edit_content: "Metagegevens van de inhoud bewerken",
        content_title: "Inhoud",
        metadataToChange: "Te wijzigen metagegevens",
        selectContent: "Voor inhoud",
        select_action: "Actie selecteren",
        support: {
          support: "Ondersteuning",
          licenses: "Licenties",
        },
        versions: {
          version: "Versie",
          prerequisities: "Voorwaarden en vereisten",
        },
        content: {
          select_collection: "Verzameling selecteren",
          show_collection: "Weergeven:",
          show_all_content: "Alle verzamelingen",
          show_organization_content: "Mijn organisatieverzamelingen",
          select_all: "Alles selecteren",
          select_all_info: "(Dit kan even duren)",
          actionOptions: {
            add: "Toevoegen",
            remove: "Verwijderen",
            replace: "Vervangen door",
            yes: "Ja",
            no: "nee",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Geen attributen geselecteerd",
            invalid_attributes: "Ongeldige attribuutwaarden",
            invalid_actions: "Ongeldige acties",
            content_not_selected: "Geen inhoud geselecteerd",
            entities_not_selected: "Pakketgerelateerde gegevens geleverd maar geen pakketten geselecteerd",
            versions_not_selected: "Versiegerelateerde gegevens geleverd maar geen versies geselecteerd",
          },
        },
        update: {
          info_processing: "Houd dit venster geopend totdat alle items zijn verwerkt.",
          info_processed: "Alle items zijn nu verwerkt.",
          processing: "Item verwerken",
          error_info: "Er is een probleem met het bijwerken van de volgende items:",
          package_failure_func: function (package_title, errorMsg) {
            return "Inhoud: " + package_title + ", fout: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Inhoud: " + package_title + ", versie: " + version_title + ", fout: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " items worden bijgewerkt, wilt u doorgaan?";
          },
        },
      },
      myUserGroups: {
        title: "Gebruikersgroepen",
        createdByPrefix: "Door:",
      },
      downloads: {
        title: "Downloads",
      },
      myCollections: {
        new_collection: "Nieuwe verzameling",
        my_collections: "Mijn verzamelingen",
        created_by_me: "Gemaakt door mij",
        local_collections: "Lokale en netwerkverzamelingen",
        online_collections: "Online verzamelingen",
        created_by_my_organization: "Gemaakt door mijn organisatie",
        created_by_my_organizations: "Gemaakt door mijn organisaties",
        shared_with_me: "Gedeeld met mij",
        shared_with_me_or_organization: "Gedeeld met mij of mijn organisatie",
        notification: {
          error: "Fout",
          error_loading_online_collections: "Fout bij het laden van online verzamelingen",
          error_loading_packages_liked_by_me: "Fout bij het laden van inhoud die ik leuk vind",
          error_loading_my_organization_s_collections: "Fout bij het laden van de verzamelingen van mijn organisatie",
          error_loading_my_shared_collections: "Fout bij het laden van mijn gedeelde verzamelingen",
        },
        local: {
          helpText1: "Als u toegang tot lokale verzamelingen wilt, installeert u ",
          helpText2: "Tekla Warehouse Service-plugin",
          helpText3: ".",
          title: "Lokale en netwerkverzamelingen",
          notFound: "Nog geen lokale verzamelingen gevonden.",
          myCollectionsTitle: "Mijn verzamelingen",
          actions: {
            createNew: "Lokale of netwerkverzameling maken",
          },
        },
      },
      organization: {
        news_feed: "Nieuwsfeed",
        add_comment: "Feedtekst toevoegen",
        edit_comment: "Nieuwsitem bewerken",
        subTabs: {
          collections: "Verzamelingen",
          analytics: "Analyses",
          contact_and_support: "Contact",
          news_feed: "Nieuwsfeed",
        },
        edit: {
          edit_title: "Titel bewerken",
          edit_description: "Beschrijving bewerken",
          edit_contact: "Contactgegevens bewerken",
          edit_logo: "Logo bewerken",
          edit_url: "URL bewerken",
          edit_email: "E-mail bewerken",
          edit_phonenumber: "Telefoonnummer bewerken",
          edit_warehouse_url: "Warehouse-URL bewerken",
          edit_warehouse_url_subtitle: "Alfabetten, geen Scandinavische tekens, getallen of speciale tekens",
          edit_url_subtitle: "Volledige URL invoegen",
          edit_logo_descriptive: "Optimale afbeeldingsgrootte circa 350 * 200 pixels",
          edit_facebook_url: "Facebook-URL bewerken",
          edit_twitter_url: "Twitter-URL bewerken",
          edit_linkedin_url: "LinkedIn-URL bewerken",
          edit_youtube_url: "Youtube-URL bewerken",
          edit_googleplus_url: "Google+-URL bewerken",
          logo_updated: "Logo bijgewerkt",
          logo_update_failed: "Bijwerken logo is mislukt",
          logo_remove_failed: "Verwijderen logo is mislukt",
          logo_removed: "Logo is verwijderd",
          updated_successfully: "Bijwerken is geslaagd",
          updated_failed: "Bijwerken is mislukt",
          add_comment: "Nieuw toevoegen",
          edit_comment: "Nieuwsitem bewerken",
          comment_added: "Nieuwsfeeditem is toegevoegd",
          comment_updated: "Nieuwsfeeditem is bijgewerkt",
          comment_deleted: "Nieuwsfeeditem is verwijderd",
          comment_add_failed: "Nieuwsfeed toevoegen is mislukt",
          comment_update_failed: "Bijwerken nieuwsfeeditem is mislukt",
          comment_delete_failed: "Verwijderen nieuwsfeeditem is mislukt",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Online inhoud zoeken op " + name + " &rsaquo;";
        },
        contact_information: "Contact met ons opnemen",
        website: "Website &rsaquo;",
        show_newer_news: "Nieuwere",
        show_older_news: "Oudere",
      },
      online_collections: {
        new_collection: "Nieuwe verzameling",
        online_collections: "Online verzamelingen",
        created_by_organizations: "Gemaakt door organisaties",
        created_by_users: "Gemaakt door gebruikers",
      },
      local_collections: {
        tab_title: "Inhoud",
        local_collections: "Lokale verzamelingen",
      },
      versions: {
        add_tool: "Applicatie toevoegen",
        table_header: {
          name: "Naam",
          type: "Type",
          platform: "Platform",
          modified_at: "Gewijzigd op",
          size: "Afmeting",
          virus_scan: "Virusscan",
        },
        // placeholders
        select_file_type: "Het bestandstype selecteren",
        select_platform: "Platform selecteren",
        select_unit: "Eenheid selecteren",
        select_quality: "Kwaliteit selecteren",
        what_will_be_added: "Wat wilt u toevoegen?",
        select_files_from_your_disk: "Bestanden van uw schijf selecteren",
        add: "Toevoegen",
        //content management
        add_application: "Applicatie toevoegen",
        select_content_from_tekla_structures: "Inhoudsbestanden selecteren van Tekla Structures",
        or: "of",
        files: "Bestanden",
        //version metadata
        version_information: "Versiegegevens",
        version_display_name: "Weergavenaam versie",
        version_number: "Versienummer",
        version_number_info: "Voer een versienummer in. U kunt het versienummer niet wijzigen nadat het is opgeslagen.",
        existing_versions: "Bestaande versienummers voor deze inhoud",
        version_number_validation: {
          missing: "U moet een versienummer invoeren.",
          invalid: "Het versienummer moet de regels voor semantisch versiebeheer volgen (PRIMAIR.SECUNDAIR.PATCH)",
          already_in_use: "Dit versienummer is al in gebruik voor deze inhoud.",
        },
        select_update_type: "Als u een versienummer wilt genereren, selecteert u eerst het updatetype",
        please_enter_a_version_name_or_number: "Voer een versienaam of -nummer in.",
        virus_scan: {
          soft_failed: "Mislukt",
          hard_failed: "Niet gescand",
          infected: "Geïnfecteerd",
        },
      },
      collections: {
        by: "Door:",
        edit: {
          edit_collection: "Verzameling bewerken",
          edit_description: "Beschrijving bewerken",
        },
        notification: {
          collection_updated: "Verzameling bijgewerkt",
          error: "Fout",
          error_loading_other_users_collections: "Fout bij het laden van de verzamelingen van andere gebruikers",
          error_loading_other_organizations_collections:
            "Fout bij het laden van de verzamelingen van een andere organisatie",
          error_loading_collection_contents: "Fout bij het laden van verzamelingsinhoud",
          members_list_updated: "Ledenlijst bijgewerkt.",
          invitations_removed: "Uitnodigingen verwijderd!",
          collection_removed: "Verzameling verwijderd",
          thumbnail_deleted: "Miniatuur verwijderd",
          wrong_file_type: "Onjuist bestandstype.",
          invalid_file_type: "Ongeldig bestandstype.",
          selected_image_is_too_big: "De geselecteerde afbeelding is te groot.",
          thumbnail_updated: "Miniatuur bijgewerkt",
          default_thumbnail_updated: "Standaard miniatuur bijgewerkt",
          default_thumbnail_update_failed: "Bijwerken standaard miniatuur is mislukt",
          thumbnail_added: "Miniatuur toegevoegd",
          thumbnail_update_failed: "Bijwerken miniatuur is mislukt",
          video_added: "Video toegevoegd",
          video_adding_failed: "Toevoegen video is mislukt",
          video_deleted: "Video verwijderd",
          video_deleting_failed: "Verwijderen video is mislukt",
        },
        confirm: {
          delete_all: function (title) {
            return 'De verzameling en alle inhoud in verzameling verwijderen "' + title + '"?';
          },
          remove_members: "Weet u het zeker?",
          remove_invitations: "Weet u het zeker?",
        },
        useNetworkDrive: "Netwerkschijf of -map gebruiken",
        createCollection: "Een online verzameling maken",
        createCollectionInstructions: "Maak een verzameling om uw inhoud te groeperen.",

        actions: {
          delete_thumbnail: "Miniatuur verwijderen",
          edit_collection: "Verzameling bewerken",
          delete_collection: "Verzameling verwijderen",
          add_content: "Inhoud toevoegen",
        },
        subTabs: {
          content: "Inhoud",
          members: "Leden weergeven",
          analytics: "Analyses",
          manage_access: "Toegang beheren",
        },

        members: {
          add_user_groups: "Gebruikersgroepen toevoegen",
          select_user_group: "Gebruikersgroep selecteren",
          with_role: "met rol",
          user_groups_added: "Gebruikersgroepen toegevoegd!",
          members_list_updated: "Ledenlijst bijgewerkt.",
          update_selected_members: "Geselecteerde leden bijwerken",
          table_header: {
            member_type: "Lidtype",
            member_name: "Lidnaam",
            email: "E-mail",
            role: "Rol",
            joined_at: "Deelgenomen op",
            invited_at: "Uitgenodigd op",
          },
          new_role: "Nieuwe rol",
        },
        member_list: {
          users_who_can_access_this_collection: "Gebruikers die toegang tot deze verzameling hebben",
          add_user_group: "Gebruikersgroep toevoegen",
          update_members: "Leden bijwerken",
          remove_members: "Leden verwijderen",
          pending_invitations: "Uitnodigingen in behandeling",
          user: "gebruiker",
          invite_member: "Lid uitnodigen",
          update_invitations: "Uitnodigingen bijwerken",
          remove_invitations: "Uitnodigingen verwijderen",
        },
        labels: {
          select_organization: "Organisatie selecteren",
          select_visibility: "Zichtbaarheid selecteren",
          title: "Naam",
          name: "Naam",
          createAs: "Maken als: ",
          asMyself: "Mijzelf",
          asUserGroup: "Gebruikersgroep",
          asOrganization: "Organisatie",
          selectOwner: "Een organisatie voor de inhoudseigenaar selecteren",
          visibility: "Zichtbaarheid",
          visibilityOptions: {
            public: "Openbaar",
            private: "Persoonlijk",
          },
          description: "Beschrijving",
        },
        new_collection: {
          please_input_a_username: "Voer een gebruikersnaam in",
          title_is_required_to_be_at_least_3_characters: "De titel moet minimaal drie tekens bevatten",
          title_cannot_be_longer_than_20_characters: "De titel mag niet langer dan 20 tekens zijn",
        },
        collection_list: {
          no_content: "Geen verzamelingen.",
          results_summary: function (number) {
            return "" + number + "  verzameling " + pluralize(number, ["items", "item", "items"]);
          },
        },
        entity_list: {
          no_content: "Geen inhoud in de verzameling.",
          results_summary: function (number) {
            return "" + number + " inhoud " + pluralize(number, ["items", "item", "items"]);
          },
          linked_content: "Gekoppelde inhoud",
          unlink: "Inhoud ontkoppelen",
        },
      },
      alerts: {
        no_updates: "Niet onlangs bijgewerkt",
        notifications_marked_as_read: "Melding gemarkeerd als gelezen",
        cleared_alerts: "Alle meldingen gewist",
        alert_by_email:
          "Stuur me een e-mail wanneer de hieronder weergegeven inhoud met een nieuwe versie wordt bijgewerkt of een verzameling met nieuwe inhoud wordt bijgewerkt",
        action_types: {
          create: "is gemaakt",
          update: "is bewerkt",
          delete: "is verwijderd",
          added_entity: "versie is toegevoegd",
          removed_entity: "versie is verwijderd",
          added_collection: "inhoud is toegevoegd",
          removed_collection: "inhoud is verwijderd",
        },
        property_names: {
          description: "Details",
          searchCriteria: "Informatie over zoekcriteria",
          groupCategory: "Groeps- en categoriegegevens",
          support: "Ondersteuningsgegevens",
          licenses: "Licentiegegevens",
          relatedContent: "Gerelateerde inhoudsgegevens",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Bijgewerkt " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Meldingsvoorkeuren zijn bijgewerkt",
          preferences_update_failed: "Er is een fout opgetreden tijdens het bijwerken van meldingsvoorkeuren",
        },
      },
      browseDialog: {
        useNetworkDrive: "Netwerkschijf of -map gebruiken",
        ownerGroups: {
          title: "Eigenarengroepen",
        },
      },
      itemTypeCategories: {
        all: "Alle groepen",
        application: "Applicaties",
        "3dProduct": "3D-producten",
        customComponent: "Gebruikerscomponenten",
        tool: "Applicaties",
        profile: "Profielen",
        material: "Materialen",
        bolt: "Bouten",
        rebar: "Staven",
        reportTemplate: "Lijsttemplates",
        drawingSetup: "Tekeninginstellingenbestanden",
        modelSetup: "Modelinstellingenbestanden",
        environmentFile: "Omgevingsbestanden",
        shape: "Vormen",
        //'shapeCatalog': 'Vormendatabase',
        //'foreignGeometryDefinition': 'Afwijkende geometriedefinitie'
      },
      platforms: {
        WIN_X86: "32-bits",
        WIN_X64: "64-bits",
        ANY: "Platformonafhankelijk",
      },
      platformsDownload: {
        WIN_X86: "32-bits",
        WIN_X64: "64-bits",
        ANY: " ",
      },
      contentItemSource: {
        other: "Algemeen - naar modelmap",
        run: "Uitvoeren",
        bolt: "Boutsamenstelling",
        component: "Component",
        drawing: "Tekeninginstelling (BÈTA)",
        material: "Materiaal",
        profile: "Profiel",
        mesh: "Wapeningsnet",
        rebar: "Wapeningsstaaf",
        shape: "Vorm",
        geometry: "Geometrie",
        modeltemplate: "Modeltemplate",
        cloningtemplate: "Kloontemplate",
        noaction: "Geen actie",
        releasenotes: "Release Notes",
      },
      contentItemHeader: {
        bolt: "Boutsamenstellingen",
        component: "Componenten",
        drawing: "Tekeninginstellingen (BÈTA)",
        material: "Materialen",
        profile: "Profielen",
        mesh: "Wapeningsnetten",
        rebar: "Wapeningsstaven",
        shape: "Vormen",
      },
      useCategoryGroups: {
        workflow: "Voortgang werk",
        structures: "Structuren",
        drawings: "Tekeningen",
        modeling: "Modelleren",
      },
      useCategories: {
        title: "Gebruikt in/voor",
        conceptualDesign: "Conceptueel ontwerp",
        designEngineeringAnalysis: "Ontwerp/Engineering/Berekening",
        detailing: "Detailleren",
        fabrication: "Fabricage",
        erectionInstallationPour: "Montage/Installatie/Stort",
        contracting: "Aannemen",
        scheduling: "Plannen",
        takeOffEstimation: "Afname/Raming",
        concreteStructures: "Insitu-beton",
        precastConcrete: "Prefab-beton",
        offshore: "Offshore",
        steelStructures: "Staalstructuren",
        timberStructures: "Houtstructuren",
        reinforcement: "Wapening",
        drawingCreation: "Tekening maken",
        drawingManagement: "Tekeningenbeheer",
        drawingEditing: "Tekening bewerken",
        drawingAnnotation: "Tekeningannotatie",
        viewSimulateNavigate: "Weergeven, simuleren, navigeren",
        compareReviewManageData: "Vergelijken, controleren, gegevens beheren",
        softwareCollaboration: "Softwaresamenwerking",
        productivity: "Productiviteit",
        interoperability: "Uitwisselbaarheid",
        systemSetup: "Systeeminstellingen",
      },
      measurementUnits: {
        "0": "Metrisch",
        "1": "Engels",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "SketchUp",
        openBIM: "BIM (IFC) openen",
        standalone: "Zelfstandig",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Niet versiespecifiek",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'SketchUp 7',
        //'SK1': 'SketchUp 8'
      },
      qualityTags: {
        experimental: "Experimenteel",
        candidate: "Kandidaat",
        approved: "Goedgekeurd",
        standard: "Standaard",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Engels",
        "zh-Hans": "Chinees - Vereenvoudigd",
        "zh-Hant": "Chinees - Traditioneel",
        cs: "Tsjechisch",
        da: "Deens",
        nl: "Nederlands",
        fi: "Fins",
        fr: "Frans",
        de: "Duits",
        hu: "Hongaars",
        it: "Italiaans",
        ja: "Japans",
        ko: "Koreaans",
        pl: "Pools",
        pt: "Portugees",
        "pt-br": "Portugees - Braziliaans",
        ru: "Russisch",
        es: "Spaans",
        sv: "Zweeds",
        no: "Noors",
        other: "Andere",
      },
      isoLangs: {
        ab: {
          name: "Abkhaz",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikaans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Albaans",
          nativeName: "Shqip",
        },
        am: {
          name: "Amhaars",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Arabisch",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonees",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Armeens",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Assamees",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avarisch",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avestisch",
          nativeName: "avesta",
        },
        ay: {
          name: "Aymaraans",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azerbeidzjaans",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambaraans",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Bashkir",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Baskisch",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Wit-Russisch",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengaals",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bosnisch",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Bretons",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Bulgaars",
          nativeName: "български език",
        },
        my: {
          name: "Birmaans",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Catalaans; Valenciaans",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Tsjetsjeens",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa; Chewa; Nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chinees, Vereenvoudigd en Chinees",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Chinees - Vereenvoudigd",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Chinees - Traditioneel",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Chinees - Taiwanees",
          nativeName: "國語",
        },
        cv: {
          name: "Tsjoevasjisch",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Cornisch",
          nativeName: "Kernewek",
        },
        co: {
          name: "Corsicaans",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Kroatisch",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Tsjechisch",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Deens",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi; Dhivehi; Maldiviaans;",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Nederlands",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Engels",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Ests",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Faroees",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fijisch",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Fins",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Frans",
          nativeName: "Français",
        },
        ff: {
          name: "Fula; Fulah; Pulaar; Pular",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Galicisch",
          nativeName: "Galego",
        },
        ka: {
          name: "Georgisch",
          nativeName: "ქართული",
        },
        de: {
          name: "Duits",
          nativeName: "Deutsch",
        },
        el: {
          name: "Grieks, modern",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gujarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haïtiaans; Haïtiaans Creools",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Hebreeuws (modern)",
          nativeName: "עברית",
        },
        hz: {
          name: "Herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Hongaars",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonesisch",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingue",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Iers",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "IJslands",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italiaans",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japans",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Javaans",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, Groenlands",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Kasjmiri",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Kazachstaans",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu, Gikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Kinyarwanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Kirgizisch",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Koreaans",
          nativeName: "한국어",
        },
        ku: {
          name: "Koerdisch",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kwanyama, Kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latijns",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxemburgs",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburgs",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Lao",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Litouws",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-Katanga",
          nativeName: "",
        },
        lv: {
          name: "Lets",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macedonisch",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Malagassisch",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Maleis",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malayalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltees",
          nativeName: "Malti",
        },
        mi: {
          name: "Māori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Marathi (Marāṭhī)",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshalleilands",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongolisch",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo, Navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Noorse Bokmål",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Noord-Ndebele",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepalees",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Noors Nynorsk",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Noors",
          nativeName: "Norsk",
        },
        ii: {
          name: "Nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Zuid-Ndebele",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Occitaans",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwe, Ojibwa",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Oude Kerk Slavisch, Kerk Slavisch, Oud Bulgaars, Oud Slavisch",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Ossetisch",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Panjabi, Punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pāli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Perzisch",
          nativeName: "فارسی",
        },
        pl: {
          name: "Pools",
          nativeName: "polski",
        },
        ps: {
          name: "Pashto, Pushto",
          nativeName: "پښتو",
        },
        pt: {
          name: "Portugees",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Portugees - Braziliaans",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quechua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Romaans",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Roemeens, Moldavisch",
          nativeName: "română",
        },
        ru: {
          name: "Russisch",
          nativeName: "русский",
        },
        sa: {
          name: "Sanskrit (Saṁskṛta)",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sardijns",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Noordelijke Sami",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoaans",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Servisch",
          nativeName: "српски језик",
        },
        gd: {
          name: "Schots-Gaelisch; Gaelisch",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Singalees",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Slowaaks",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Sloveens",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somalisch",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Zuid-Sotho",
          nativeName: "Sesotho",
        },
        es: {
          name: "Spaans", //      'name':'Traditioneel/Spaans',
          nativeName: "Español",
        },
        su: {
          name: "Soendanees",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Swahili",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Swati",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Zweeds",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tadzjieks",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Thais",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrinya",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibetaans standaard, Tibetaans, centraal",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turkmeens",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalog",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tongaans (Tonga Eilanden)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Turks",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tataars",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Tahitiaans",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Oeigoers",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Oekraïens",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Oezbeeks",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamees",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Wallonisch",
          nativeName: "Walon",
        },
        cy: {
          name: "Welsh",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "West-Fries",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Jiddisch",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Yoruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Zhuang, Chuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Andere",
          nativeName: "Andere",
        },
      },

      details: {
        navigation: {
          content_information: "Inhoudsgegevens",
          version_and_file_management: "Versie- en bestandsbeheer",
          details: "Details",
          versions: "Versies",
          analytics: "Analyses",
          statistics: "Statistieken",
          manage_access: "Toegang beheren",
          related_content: "Gerelateerd",
          properties: "Eigenschappen",
          support: "Bedrijf en ondersteuning",
          release_notes: "Release Notes",
          comments: "Gebruikersopmerkingen",
        },
        related_content: {
          other_content_title: "Andere inhoud die u mogelijk interesseert",
          related_content_title: "Inhoud aanbevolen door ",
          related_content_url: "Inhouds-URL",
          add_related_content: "Gerelateerde inhoud toevoegen",
          add_related_content_info:
            "U kunt alleen gerelateerde online inhoud aan online inhoud en lokale inhoud aan lokale inhoud toevoegen",
          remove_related_content: "Verwijderen",
          relatedContentUrlSubtitle: "Inhouds-ID of complete URL naar inhoud",
          updated_successfully: "Bijwerken gerelateerde inhoud is geslaagd",
          update_failed: "Fout tijdens het bijwerken van gerelateerde inhoud",
          update_failed_invalid_id: "Geleverde inhoud-id is ongeldig",
        },
        actions: {
          move: "Verplaatsen",
          delete: "Verwijderen",
          add_comment: "Opmerking toevoegen",
          add_reply: "Antwoord toevoegen",
          edit_comment: "Opmerking bewerken",
          reply_comment: "Antwoord toevoegen",
          view_replies: "Antwoorden weergeven",
          report_bad_comment: "Slechte opmerking melden &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Titel bewerken of verwijderen",
            title_or_move_delete: "Titel bewerken, verplaatsen of verwijderen",
            thumbnail: "Miniatuur bewerken",
            description: "Beschrijving bewerken",
            groupandcategory: "Groep en categorie bewerken",
            details: "Details bewerken",
            versions: "Versies bewerken",
            support: "Ondersteuningsgegevens bewerken",
            release_notes: "Release Notes bewerken",
            search_criteria: "Zoekcriteria bewerken",
            batchedit_and_translations:
              "Met de vertaalfunctie kunt u uw inhoud in meerdere talen publiceren. Op basis van hun selectie van de UI-taal van Warehouse wordt aan gebruikers een vertaalde versie van uw inhoud getoond. Met Batchbewerking kunt u meerdere objecten tegelijk bewerken. Selecteer eerst een veld en vervolgens de items die u wilt bewerken",
          },
        },
        links: {
          link: "Koppelen",
          select_collection_placeholder: "Te koppelen verzameling selecteren",
          linking_collections_label: "Verzamelingen die hieraan koppelen",
          private_content_note:
            "Opmerking: Persoonlijke inhoud blijft alleen zichtbaar voor gebruikers die er toegang toe hebben.",
          share_on_social_media: "Delen op:",
          report_bad_content: "Slechte inhoud melden &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Illegale inhoud melden &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Door u op waarschuwingen te abonneren, gaat u akkoord met het delen van de weergave- en organisatienaam (indien van toepassing) van uw Tekla Account met de eigenaar van de inhoud.",
          alert_me: "Waarschuw mij",
          unsubscribe: "Abonnement opzeggen",
          subscribed_successfully: "Abonneren is geslaagd",
          unsubscribed_successfully: "Abonnement opzeggen is geslaagd",
          subscribers_label: "Abonnees",
        },
        reviews: {
          reviewers_label: "Revisoren",
        },
        comments: {
          replies: "Antwoorden",
        },
        scan: {
          soft_failed_binaries_info: "Virusscan is mislukt  ",
          hard_failed_binaries_info: "Virusscan kan niet worden uitgevoerd ",
          infected_binaries_info: "Virusscan heeft een infectie gevonden ",
          what_now: "Wat nu?",
          popup: {
            soft_failed_binaries_title: "Mislukte binaire bestanden",
            hard_failed_binaries_title: "Mislukte binaire bestanden",
            infected_binaries_title: "Geïnfecteerde binaire bestanden",
            soft_failed_binaries_info:
              "Virusscan voor de bestanden die u hebt geüpload, is mislukt. Dit is alleen tijdelijk. Verwijder de bestanden en uploadt ze opnieuw. Alle bestanden moeten de virusscan doorlopen voordat ze voor andere gebruikers zichtbaar worden.",
            hard_failed_binaries_info:
              "Virusscan kan niet worden uitgevoerd. Verwijder de bestanden. Alle bestanden moeten de virusscan doorlopen voordat ze voor andere gebruikers zichtbaar worden.",
            infected_binaries_info:
              "De virusscan heeft een infectie gevonden in de bestanden die u hebt geüpload. Verwijder de bestanden. Alle bestanden moeten de virusscan doorlopen voordat ze voor andere gebruikers zichtbaar worden.",
            version: "Versie: ",
            fileName: "bestandsnaam: ",
          },
        },
        status: {
          content_banned_info:
            "De inhoud wordt door de beheerder geblokkeerd. Het is momenteel niet beschikbaar voor andere gebruikers, met uitzondering van de eigenaar van de inhoud. <a href='https://support.tekla.com/contact-us' target='_blank'>Neem contact op met de helpdesk</a> voor meer informatie.",
          content_archived_info:
            "Deze inhoud is gearchiveerd. Gebruikers zonder bewerkingsrechten worden naar de voorpagina omgeleid als ze de inhoud via een rechtstreekse koppeling proberen te bezoeken.",
          archived: "Gearchiveerd",
        },
        modified_by: function (name) {
          return " door " + name;
        },
        created_by: function (name) {
          return "Gemaakt door " + name;
        },
        deleteEntity: function (title) {
          return "Verwijderen " + title + "?";
        },
        userComments: "Gebruikersopmerkingen",
        comment_added: "Opmerking is toegevoegd",
        comment_updated: "Opmerking is bijgewerkt",
        comment_deleted: "Opmerking is verwijderd",
        comment_add_failed: "Toevoegen opmerking is mislukt",
        comment_update_failed: "Bijwerken opmerking is mislukt",
        comment_delete_failed: "Verwijderen opmerking is mislukt",
        comment_delete_confirmation: "Opmerkingsitem verwijderen?",
        name: "Naam",
        details: "Details",
        contentOwner: "Maker",
        contentOwnerGroup: "Groep van inhoudseigenaren",
        contentGroup: "Inhoudsgroep",
        thumbnail: "Miniatuur",
        tooltip: "Tooltip",
        description: "Beschrijving",
        additional_information: "Extra gegevens",
        images: "Productafbeeldingen",
        installationInfo: "Installatiegegevens",
        productCode: "Productcode",
        copyrightInfo: "Copyright",
        productExpirationDate: "Vervaldatum product",
        helpUrl: "Website &rsaquo;",
        supportUrl: "Ondersteuning &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "Release Notes",
        supportForumUrl: "Discussion Forum &rsaquo;",
        itemTypeCategories: "Groep",
        useCategories: "Categorie",
        category: "Categorie:",
        group: "Groep:",
        compatibleSoftwareProducts: "Compatibele softwareproducten",
        compatibleOperatingSystems: "Compatibele besturingssystemen",
        locationRestrictions: "Beschikbaarheid product:",
        supportedLanguages: "Talen:",
        measurementUnits: "Maateenheden:",
        tags: "Tags en trefwoorden:",
        prerequisites: "Vereisten:",
        releaseNotes: "Release Notes:",
        versions: "Versies",
        testedVersions: "Compatibele versies",
        qualityTag: "Kwaliteit - geëvalueerd door de eigenaar",
        otherAttributes: "Andere attributen",
        labels: "Zoekcriteria",
        supportInformation: "Ondersteuning",
        updateInformation: "Updates",
        latestUpdate: "Nieuwste update",
        totalDownloads: "Totaal aantal downloads",
        licenses: "Licenties",
        maintenanceRequired: "Downloaden vereist Tekla Maintenance",
        tekla_maintenance_content: "Tekla Maintenance Content",
        download_requires_tekla_maintenance: "Downloaden vereist Tekla Maintenance",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Bijwerken is geslaagd",
        update_failed: "Update is mislukt",
        update_failed_invalid_tag: "Update is mislukt: ongeldige tag",
        update_failed_details_max_length_exceeded: "Update is mislukt: details te lang",
        show_version_details: "Meer informatie",
        content_removed: "Inhoud verwijderd",
        no_versions_available:
          "Geen versies beschikbaar. Het kan even duren voordat de updates verschijnen. Vernieuw uw browser om de wijzigingen te zien.",
        thumbnail_deleted: "Miniatuur verwijderd",
        thumbnail_updated: "Miniatuur bijgewerkt",
        verify_service_plugin_is_running:
          "Controleer of uw plugin van Tekla Warehouse Service correct wordt uitgevoerd",
        verify_ts_connection: "Controleer of Tekla Structures wordt uitgevoerd",
        download_tos: {
          title: "Kwaliteit, ondersteuning en privacy",
          content_1: "Zorg ervoor dat de gedownloade inhoud aan uw kwaliteitstandaards voldoet. Trimble biedt geen ondersteuning en is niet verantwoordelijk voor inhoud van derden. Neem voor ondersteuning rechtstreeks contact op met de inhoudsprovider.",
          content_2: "Uw land- en organisatienaam kan in de service worden gepubliceerd en voor betrouwbare doeleinden aan de eigenaars van de inhoud worden verstrekt, zoals het u op de hoogte houden van inhoudswijzigingen. U kunt het abonnement voor deze meldingen opzeggen.",
          content_3: 'Voor Trimble-inhoudsuitbreidingen zijn de <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimble-voorwaarden</a> van toepassing. Voor inhoud van derden kunnen de licentievoorwaarden van derden van toepassing zijn. Zie de <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimble Privacyverklaring</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Dit is de vrije Tekla Structures BIM-opslag. Zoek, importeer, installeer en deel intern en wereldwijd. Produceer op een efficiënte wijze modellen met een hoge kwaliteit.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse is een gratis online en lokale bibliotheek voor modelleertools van Tekla Structures, intelligente onderdelen en templates",
        },
        search: {
          title: "Tekla Warehouse zoeken",
          description:
            "Zoeken naar Tekla-extensies, 3D-onderdelen, vormen, gebruikerscomponenten, profielen, materialen zoals stalen en betonnen kwaliteiten, staven, netten en templates",
        },
        about: {
          title: "Informatie over Tekla Warehouse",
          description:
            "Tekla Warehouse biedt een gratis marketingkanaal voor uw producten en geeft u de volledige controle over uw inhoud",
          developers: {
            title: "Standaard ontwikkelaarstitel",
            description: "Standaard ontwikkelaarsbeschrijving",
          },
          usage: {
            title: "Standaard gebruikstitel",
            description: "Standaard gebruiksbeschrijving",
          },
          getting_started: {
            title: "Standaard aan-de-slag-titel",
            description: "Standaard aan-de-slag-beschrijving",
          },
          content: {
            title: "Standaard inhoudstitel",
            description: "Standaard inhoudsbeschrijving",
          },
          using_content: {
            title: "Standaard inhoudstitel gebruiken",
            description: "Standaard inhoudsbeschrijving gebruiken",
          },
          creating_content: {
            title: "Standaard inhoudstitel maken",
            description: "Standaard inhoudsbeschrijving maken",
          },
        },
        collection: {
          title: "Standaard verzamelingentitel",
          description: "Standaard verzamelingenbeschrijving",
        },
        my_warehouse: {
          title: "Mijn Tekla Warehouse",
          description: "Uw Tekla Warehouse-inhoud beheren",
        },
      },
      upload: {
        actions: {
          cancel: "Annuleren",
          previous: "&lsaquo; Vorige",
          next: "Volgende &rsaquo;",
        },
        content: {
          add_content: "Alle bestanden",
        },
        collection: {
          heading: "Verzameling selecteren of maken",
          subHeading:
            "Inhoud verzamelingengroep. Met online verzamelingen kunt u uw inhoud, lokale verzamelingen om inhoud met uw collega's te delen publiceren. U kunt ook lokale verzamelingen voor persoonlijk gebruik gebruiken.",
          loading: "Verzamelingen laden...",
          selectFromMyCollections: "Uit Mijn verzamelingen selecteren",
          description_of_the_collection: "Beschrijving",
          title_validation: {
            missing: "Voer een titel in.",
            too_short: function (length) {
              return "Een titel moet minimaal " + length + " tekens bevatten.";
            },
            too_long: function (length) {
              return "Een titel mag niet langer dan " + length + " tekens zijn.";
            },
          },
        },
        package: {
          basic_information: "Basisgegevens",
          code_name: "Codenaam",
          code_name_info: "Voer een codenaam in als unieke identificatie voor deze inhoud. De codenaam kan kleine letters, cijfers en deze speciale tekens bevatten: _ - @. U kunt de codenaam niet wijzigen nadat deze is opgeslagen.",
          code_name_validation: {
            missing: "U moet een codenaam invoeren",
            wrong_format: "De codenaam kan kleine letters, nummers en deze speciale tekens bevatten: _ - @",
            not_unique: "Deze codenaam is al in gebruik. U moet een unieke codenaam invoeren.",
          },
          search_criteria: "Criteria zoeken",
          support: "Ondersteuning",
          title: "Titel",
          thumbnail: "Miniatuur",
          "3D_pic": "3D-model",
          title_validation: {
            missing: "Voer een titel in.",
            too_short: function (length) {
              return "Een titel moet minimaal " + length + " tekens bevatten.";
            },
          },
          description_validation: {
            missing: "Voer een beschrijving in.",
            too_short: function (length) {
              return "Een beschrijving moet minimaal " + length + " tekens bevatten.";
            },
          },
          wrong_file_type: "Onjuist bestandstype.",
          selected_image_is_too_big: "De geselecteerde afbeelding is te groot.",
        },
        version: {
          select_version: "Versie selecteren",
          select_quality: "Kwaliteit selecteren",
          versions: "Versies",
          displayNameNew: "Weergavenaam versie",
          copy_from_version: "Metagegevens van bestaande versie kopiëren (optioneel)",
          displayName: "Weergavenaam",
          content_information: "Inhoudsgegevens",
          back_to_content_information: "&lsaquo; Terug naar de inhoudsgegevens",
          version_and_file_management: "Versie- en bestandsbeheer",
          there_s_no_versions_yet: "Er zijn nog geen versies.",
          create_a_version_to_add_files: "Maak een versie om bestanden toe te voegen.",
          add_version: "Versie toevoegen",
          version_information: "Versiegegevens",
          delete_version: "Versie verwijderen",
          no_versions_available: "Geen versies beschikbaar. Het kan even duren voordat de updates verschijnen.",
          update_start_title_html: '<div class="spinner spinner-white">Updating</div>',
          update_start_message: "Dit kan even duren.",
          version_was_created: "Er is een versie gemaakt.",
          version_was_created_with_delay:
            "Er is een versie gemaakt. De wijzigingen zijn mogelijk na een paar minuten zichtbaar.",
          version_was_updated: "De versie is bijgewerkt.",
          version_was_deleted: "De versie is verwijderd",
          version_was_deleted_with_delay:
            "De versie is verwijderd. De wijzigingen zijn mogelijk na een paar minuten zichtbaar.",
          confirm_delete: function (title) {
            return 'Versie verwijderen "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Het pakket is geüpload.",
          couldn_t_create_a_package: "Kan geen pakket maken. Wijzigingen worden teruggedraaid.",
          uploading_title_html: '<div class="spinner spinner-white">Uploaden</div>',
          uploading_text: "Dit kan even duren.",
          select_organization: "Organisatie selecteren",
          collection_visibility: "Zichtbaarheid verzameling:",
          actions: {
            create: "Maken",
            publish: "Publiceren",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Deze inhoud is zichtbaar voor alle gebruikers met toegang tot de lokale schijf of de netwerkschijf.",
          this_content_will_be_visible_only_to_you: "Deze inhoud is alleen voor u zichtbaar.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Deze inhoud is alleen zichtbaar voor u en iedereen in uw organisatie.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Deze inhoud is na het publiceren voor iedereen zichtbaar.",
          public_content_disclaimer:
            "U bent verantwoordelijk voor inhoud die u uploadt. Zorg ervoor dat uw inhoud niet schadelijk is, u het recht hebt de inhoud te uploaden en het niet strijdig is met intellectuele eigendomsrechten. U gaat ermee akkoord in de service de voorwaarden te posten die mogelijk op het gebruik van de inhoud die u upload van toepassing zijn.",
        },
        metadata: {
          title: "Metagegevens",
          subtitle: "Basisgegevens",
          deleteThumbnail: "Miniatuur verwijderen",
          buttonCreate: "Maken",
        },
        basicdata: {
          title: "Inhoud toevoegen",
          clickButtonToSelectFile: "Op de knop klikken om het bestand te selecteren",
          thumbnail: "Miniatuur",
          dropImageHere: "Afbeelding hier neerzetten",
        },
        "3dGeometry": {
          title: "3D-productdatabase maken",
          buttonAddEntity: "3D-product toevoegen",
          buttonAdd: "Aangepast attribuut toevoegen",
          errorAttributeAlreadyExists: "Er bestaat al een attribuut met dezelfde naam.",
          buttonCreatePackage: "Pakket maken",
          buttonCreatePackages: "Pakketten maken",
          addCustomAttributePlaceholder: "Attribuutnaam",
        },
        please_log_in_fragments: {
          please: "Log ",
          log_in: "in",
          or: " of ",
          register: "registreer",
          to_upload_online_content: " om online inhoud te uploaden.",
        },
        header: {
          upload_header: "Uploaden",
          collection: "Verzameling",
          content: "Inhoud",
          version: "Versie",
          finish: "Beëindigen",
        },
        contentOwner: "Maker",
        contentOwnerGroup: "Inhoud publiceren",
        select_action: "Actie selecteren",
        tooltip: "Tooltip",
        description: "Beschrijving",
        copyright: "Copyright",
        tags: "Tags en trefwoorden",
        tagsSubtitle:
          "Gebruik een komma om te scheiden, bijvoorbeeld: trefwoorden1, trefwoorden2. Als het sleutelwoord uit meerdere woorden bestaat, gebruikt u (_) tussen woorden. Er zijn geen spaties tussen woorden toegestaan. Er zijn behalve een onderstreping en een komma geen speciale tekens toegestaan.",
        images: "Afbeeldingen",
        installationInfo: "Installatiegegevens",
        productCode: "Productcode",
        copyrightInfo: "Copyright-gegevens",
        Details: "Details",
        specialTermsUrl: "EULA",
        productExpirationDate: "Vervaldatum product",
        helpUrl: "Website",
        helpUrlSubtitle: "volledige URL invoegen",
        detailsSubtitle:
          "In deze fase kunt u alleen tekst zonder opmaak aan het veld Details toevoegen. Nadat de inhoud is gemaakt, kunt u kunt de Details bewerken zodat deze HTML-opmaak bevat.",
        specialTermsUrlSubtitle:
          "De eigenaar van de inhoud heeft de mogelijkheid om aanvullende voorwaarden voor zijn/haar eigen inhoud toe te voegen. De eigenaar moet een URL verstrekken waarin zich zijn/haar specifieke voorwaarden bevinden. Dit overschrijft geen servicevoorwaarden van Trimble.",
        releaseNotes: "Bestand met Release Notes",
        releaseNoteFileSubTitle: "Release Notes als bestand toevoegen",
        supportUrl: "Help en ondersteuning",
        supportUrlSubtitle: "voeg de volledige URL of de prefix 'mailto:' voor het e-mailen in",
        supportForumUrl: "Discussion Forum (website)",
        supportForumUrlSubtitle: "volledige URL invoegen",
        measurementUnits: "Maateenheden",
        itemTypeCategories: "Groep",
        useCategories: "Categorie",
        compatibleSoftwareProducts: "Compatibele softwareproducten",
        testedVersions: "Compatibele versies",
        compatibleOperatingSystems: "Compatibel besturingssysteem",
        locationRestrictions: "Beschikbaarheid product",
        supportedLanguages: "Talen",
        select_releasenotes_file: "Bestand selecteren",
        qualityTag: "Kwaliteit - geëvalueerd door de eigenaar",
        requireMaintenance: "Downloaden vereist Tekla-klantenonderhoud",
        contentOwnerGroupAsMyself: "Als mijzelf",
        contentOwnerGroupAsUserGroup: "Als gebruikersgroep",
        selectUserGroupForContentOwner: "Gebruikersgroep voor eigenaar van de inhoud selecteren",
        defaultUploadTemplate: "Standaardpakket",
        templateFor3DGeometryFiles: "3D-geometriepakket(ten)",
        title: "Titel",
        details: "Details",
        selectContentOwner: "Eigenaar van de inhoud selecteren",
        selectContentType: "Type",
        errorWrongfiletype: "Onjuist bestandstype.",
        errorImageTooBig: "De geselecteerde afbeelding is te groot.",
        errorBlankTitle: "De titel mag niet leeg zijn.",
        commonLabelsTitle: "Algemene labels voor alle pakketten",
      },
      profile: {
        content_usage: "Inhoudsgebruik",
        profile_updated: "Profiel bijgewerkt",
        invitation_accepted: function (target) {
          return "Uitnodiging geaccepteerd. U hebt nu toegang tot " + target + ".";
        },
        user_groups: {
          edit_user_group: "Gebruikersgroep bijwerken",
          user_group_updated: "Gebruikersgroep bijgewerkt",
          create_a_new_user_group: "Een nieuwe gebruikersgroep maken",
          name_of_the_user_group: "Naam van de gebruikersgroep",
          description: "Beschrijving",
          group_visibility: "Zichtbaarheid groep",
          remove: "Weet u het zeker?",
          remove_invitations: "Weet u het zeker?",
          remove_members: "Weet u het zeker?",
          invitations_removed: "Uitnodigingen verwijderd.",
          members_list_updated: "Ledenlijst bijgewerkt.",
          user_group_created: "Gebruikersgroep gemaakt",
        },
        members: {
          update_selected_members: "Geselecteerde leden bijwerken",
          new_role: "Nieuwe rol",
          members_list_updated: "Ledenlijst bijgewerkt.",
          edit_user_group: "Gebruikersgroep bijwerken",
          delete_user_group: "Gebruikersgroep verwijderen",
          filter: "Filteren",
          user_group_members: "Leden gebruikersgroep",
          update_members: "Leden bijwerken",
          remove_members: "Leden verwijderen",
          pending_invitations: "Uitnodigingen in behandeling",
          invite_member: "Lid uitnodigen",
          update_invitations: "Uitnodigingen bijwerken",
          remove_invitations: "Uitnodigingen verwijderen",
        },
        subTabs: {
          alerts: "Mijn waarschuwingen",
          collections: "Verzamelingen",
          likes: "Likes",
        },
        admin: {
          grant_sales_admin: "Admin-rol voor gebruiker aan verkoop verlenen",
          grant_analyst_role: "Analistrol voor gebruiker verlenen",
          role_granted: "Rol verleend",
          role_removed: "Rol verwijderd",
          offline_to_online: "Offline inhoud converteren naar online",
          use_elastic_search: "Elastisch zoeken gebruiken",
        },
        sales: {
          title: "Verkoop",
        },
        subtitlePreferences: "Zoekvoorkeuren",
        preferenceInfo:
          'Deze filters zijn bedoeld om inhoud op de <a href="#/landing">startpagina van Tekla Warehouse</a> aan u aan te bevelen.',
        clearFilters: "Filters wissen &rsaquo;",
        admin_role: "Admin",
        sales_role: "Verkoop",
        admin_mode: "Admin-modus:",
        firstName: "Voornaam",
        lastName: "Achternaam",
        displayName: "Weergavenaam:",
        organization: "Organisatienaam:",
        email: "E-mail:",
        teklaId: "Tekla-ID",
        maintenanceAccess: "Toegang tot inhoud van Tekla Maintenance:",
        myLocations: "Projectlocaties",
        measurementUnits: "Eenheden",
        useCategories: "Gebruiken",
        itemTypeCategories: "Ik ben geïnteresseerd in de volgende typen",
        languages: "Talen",
        operatingSystems: "Besturingssystemen",
        software: "Softwareproducten",
        user_id: "Gebruikers-ID:",
        organization_id: "Organisatie-ID:",
        edit_in_atc: "Mijn profiel bewerken in Tekla Account",
        no_likes: "Geen likes",
        no_alerts: "Geen abonnementen",
        clear_alerts: "Waarschuwingen wissen",
        alerts_info_html:
          "U ontvangt een waarschuwing wanneer één van de volgende wijzigingen plaatsvindt<br>" +
          "<b>Inhoud:</b> Titel, beschrijving, zoekcriteria of metagegevens van inhoudsitem zijn gewijzigd, een nieuwe versie van de inhoud is toegevoegd of een versie is verwijderd.<br>" +
          "<b>Verzameling:</b> Titel of beschrijving is bewerkt, inhoud is toegevoegd of verwijderd.",
      },
      uploader: {
        placeholders: {
          productCode: "Productcode",
          title: "Naam",
          description: "Beschrijving",
          productWeight: "Productgewicht",
          weightUnit: "Gewichtseenheid",
          material: "Materiaal",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D-productdatabase",
          addToolButton: "Applicatie",
          select_files: "Bestanden van schijf",
          select_files_from_ts: "Bestanden van Tekla Structures",
          partnerDownloadLink: "Downloadkoppeling voor partners",
        },
        headings: {
          geometryFile: "Geometriebestand",
          thumbnailFile: "Bitmap (.bmp) miniatuur",
          title: "Naam",
          description: "Beschrijving",
          productCode: "Productcode",
          productWeight: "Productgewicht",
          weightUnit: "Gewichtseenheid",
          material: "Materiaal",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Globaal",
          AF: "Afghanistan",
          AX: "Ãland Eilanden",
          AL: "Albanië",
          DZ: "Algerije",
          AS: "Amerikaans Samoa",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarctica",
          AG: "Antigua en Barbuda",
          AR: "Argentinië",
          AM: "Armenië",
          AW: "Aruba",
          AU: "Australië",
          AT: "Oostenrijk",
          AZ: "Azerbeidzjan",
          BS: "Bahama's",
          BH: "Bahrein",
          BD: "Bangladesh",
          BB: "Barbados",
          BY: "Wit-Rusland",
          BE: "België",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermuda",
          BT: "Bhutan",
          BO: "Bolivia",
          BQ: "Bonaire",
          BA: "Bosnië en Herzegovina",
          BW: "Botswana",
          BV: "Bouveteiland",
          BR: "Brazilië",
          IO: "Brits Territorium in de Indische Oceaan",
          BN: "Brunei Darussalam",
          BG: "Bulgarije",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Cambodja",
          CM: "Kameroen",
          CA: "Canada",
          CV: "Kaapverdië",
          KY: "Kaaimaneilanden",
          CF: "Centraal-Afrikaanse Republiek",
          TD: "Tsjaad",
          CL: "Chili",
          CN: "China",
          CX: "Christmaseiland",
          CC: "Cocoseilanden",
          CO: "Colombia",
          KM: "Comoren",
          CG: "Republiek Congo",
          CD: "Democratische Republiek Congo",
          CK: "Cookeilanden",
          CR: "Costa Rica",
          CI: "Ivoorkust",
          HR: "Kroatië",
          CU: "Cuba",
          CW: "Curaçao",
          CY: "Cyprus",
          CZ: "Tsjechië",
          DK: "Denemarken",
          DJ: "Djibouti",
          DM: "Dominica",
          DO: "Dominicaanse Republiek",
          EC: "Ecuador",
          EG: "Egypte",
          SV: "El Salvador",
          GQ: "Equatoriaal-Guinea",
          ER: "Eritrea",
          EE: "Estland",
          ET: "Ethiopië",
          FK: "Falklandeilanden",
          FO: "Faeröer",
          FJ: "Fiji",
          FI: "Finland",
          FR: "Frankrijk",
          GF: "Frans-Guyana",
          PF: "Frans-Polynesië",
          TF: "Franse Gebieden in de zuidelijke Indische Oceaan",
          GA: "Gabon",
          GM: "Gambia",
          GE: "Georgië",
          DE: "Duitsland",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Griekenland",
          GL: "Groenland",
          GD: "Grenada",
          GP: "Guadeloupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guinee",
          GW: "Guinee-Bissau",
          GY: "Guyana",
          HT: "Haïti",
          HM: "Heardeiland en McDonaldeilanden",
          VA: "Vaticaanstad",
          HN: "Honduras",
          HK: "Hongkong",
          HU: "Hongarije",
          IS: "IJsland",
          IN: "India",
          ID: "Indonesië",
          IR: "Iran",
          IQ: "Irak",
          IE: "Ierland",
          IM: "Isle of Man",
          IL: "Israël",
          IT: "Italië",
          JM: "Jamaica",
          JP: "Japan",
          JE: "Jersey",
          JO: "Jordanië",
          KZ: "Kazachstan",
          KE: "Kenia",
          KI: "Kiribati",
          KW: "Koeweit",
          KG: "Kirgizië",
          LA: "Laos",
          LV: "Letland",
          LB: "Libanon",
          LS: "Lesotho",
          LR: "Liberia",
          LY: "Libië",
          LI: "Liechtenstein",
          LT: "Litouwen",
          LU: "Luxemburg",
          MO: "Macao",
          MK: "Macedonië",
          MG: "Madagascar",
          MW: "Malawi",
          MY: "Maleisië",
          MV: "Malediven",
          ML: "Mali",
          MT: "Malta",
          MH: "Marshalleilanden",
          MQ: "Martinique",
          MR: "Mauritanië",
          MU: "Mauritius",
          YT: "Mayotte",
          MX: "Mexico",
          FM: "Micronesië",
          MD: "Moldavië",
          MC: "Monaco",
          MN: "Mongolië",
          ME: "Montenegro",
          MS: "Montserrat",
          MA: "Marokko",
          MZ: "Mozambique",
          MM: "Myanmar (Birma)",
          NA: "Namibië",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Nederland",
          NC: "Nieuw-Caledonië",
          NZ: "Nieuw-Zeeland",
          NI: "Nicaragua",
          NE: "Niger",
          NG: "Nigeria",
          NU: "Niue",
          NF: "Norfolkeiland",
          KP: "Noord-Korea",
          MP: "Noordelijke Marianen",
          NO: "Noorwegen",
          OM: "Oman",
          PK: "Pakistan",
          PW: "Palau",
          PS: "Palestijnse gebieden",
          PA: "Panama",
          PG: "Papoea-Nieuw-Guinea",
          PY: "Paraguay",
          PE: "Peru",
          PH: "Filippijnen",
          PN: "Pitcairn",
          PL: "Polen",
          PT: "Portugal",
          PR: "Puerto Rico",
          QA: "Qatar",
          RE: "Réunion",
          RO: "Roemenië",
          RU: "Russische federatie",
          RW: "Rwanda",
          BL: "Saint-Barthélemy",
          SH: "Sint-Helena",
          KN: "Saint Kitts en Nevis",
          LC: "Saint Lucia",
          MF: "Saint Martin",
          PM: "Saint-Pierre en Miquelon",
          VC: "Saint Vincent en de Grenadines",
          WS: "Samoa",
          SM: "San Marino",
          ST: "Sao Tomé en Principe",
          SA: "Saoedi-Arabië",
          SN: "Senegal",
          RS: "Servië",
          SC: "Seychellen",
          SL: "Sierra Leone",
          SG: "Singapore",
          SX: "Sint-Maarten",
          SK: "Slowakije",
          SI: "Slovenië",
          SB: "Salomonseilanden",
          SO: "Somalië",
          ZA: "Zuid-Afrika",
          GS: "Zuid-Georgië",
          KR: "Zuid-Korea",
          SS: "Zuid-Soedan",
          ES: "Spanje",
          LK: "Sri Lanka",
          SD: "Soedan",
          SR: "Suriname",
          SJ: "Svalbard en Jan Mayen",
          SZ: "Swaziland",
          SE: "Zweden",
          CH: "Zwitserland",
          SY: "Syrië",
          TW: "Taiwan",
          TJ: "Tadzjikistan",
          TZ: "Tanzania",
          TH: "Thailand",
          TL: "Timor-Leste",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad en Tobago",
          TN: "Tunesië",
          TR: "Turkije",
          TM: "Turkmenistan",
          TC: "Turks- en Caicoseilanden",
          TV: "Tuvalu",
          UG: "Oeganda",
          UA: "Oekraïne",
          AE: "Verenigde Arabische Emiraten",
          GB: "Verenigd Koninkrijk",
          US: "Verenigde Staten",
          UM: "Amerikaanse ondergeschikte afgelegen eilanden",
          UY: "Uruguay",
          UZ: "Oezbekistan",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietnam",
          VG: "Britse Maagdeneilanden",
          VI: "Amerikaanse Maagdeneilanden",
          WF: "Wallis en Futuna",
          EH: "Westelijke Sahara",
          YE: "Jemen",
          ZM: "Zambia",
          ZW: "Zimbabwe",
        },
        sortOptions: {
          "-modifyTime": "Voor het laatst bijgewerkt",
          "-modifiedAt": "Voor het laatst bijgewerkt",
          title: "Alfabetisch A-Z",
          "-title": "Alfabetisch Z-A",
          "-reviewCount": "Most liked",
          name: "Naam",
          role: "Rol",
          email: "E-mail",
          "-relevance": "Relevantie",
          "-createdAt": "Voor het laatst gemaakt",
          displayName: "Alfabetisch A-Z",
          "-displayName": "Alfabetisch Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Vandaag",
          yesterday: "Gisteren",
          this_week: "Deze week",
          previous_week: "Vorige week",
          this_month: "Deze maand",
          previous_month: "Vorige maand",
          this_year: "Dit jaar",
          previous_year: "Vorig jaar",
          beginning_of_time: "Begin van tijd",
          custom: "Aangepaste periode",
        },
        eventTypeOptions: {
          downloads: "Downloads",
          views: "Weergaven",
          all_events: "Alle gebeurtenissen",
        },
      },
    });
  },
};
