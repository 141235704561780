import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import { Link } from "react-router";

import { RootContext, RootStore } from "../../stores/rootStore";
import { BatchEditDialogStoreContext, BatchContentHandlerContext, BatchEditDialogStore } from "./batchEditDialogStore";
import { IDropdownOption, IOrganization } from "../../models/dataModel";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import {
  SearchWithAutocompleteStore,
  SearchWithAutocompleteStoreContext,
} from "../../stores/searchWithAutocompleteStore";
import { CollectionSelection } from "../../components/batchedits/CollectionSelection";
import { SelectAll } from "../../components/batchedits/SelectAll";
import { ContentList } from "../../components/batchedits/ContentList";
import { SearchWithAutocomplete } from "../../components/SearchWithAutocomplete";
import { Dropdown } from "../../components/Dropdown";
import { UserStore } from "stores/userStore";

const Organization = observer(({ organization }: { organization: IOrganization }) => {
  const rootStore: RootStore = useContext(RootContext);
  const dialogStore: BatchEditDialogStore = useContext(BatchEditDialogStoreContext);

  function setSelectedOrganization(evt) {
    evt.preventDefault();
    dialogStore.setSelectedOrganization(organization);
  }

  return (
    <Fragment>
      <span className="icon blue icon-organization" />
      <Link
        data-testid={`show-organization-${organization.id}`}
        to={"/organization/" + organization.id}
        target="_blank"
        rel="noopener noreferrer"
      >
        {organization.displayName}
      </Link>
      <a
        data-testid={`select-organization-${organization.id}`}
        className="icon icon-plus small blue"
        onClick={setSelectedOrganization}
        title={rootStore.getTranslation("actions.add")}
      ></a>
    </Fragment>
  );
});

const OrganizationList = observer(() => {
  const dialogStore: BatchEditDialogStore = useContext(BatchEditDialogStoreContext);

  return (
    <Fragment>
      {dialogStore.isProcessing() ? (
        <div className="spinner" data-testid="searchingOrganizations">
          <TranslatedHtml entry="shared.spinner_loading" />
        </div>
      ) : (
        <div className="organizations-list" data-testid="organizationList">
          {dialogStore.getOrganizationSearchResults().map((organization: IOrganization, index) => {
            return (
              <div data-testid={`organization-list-${organization.id}`} key={index}>
                <Organization organization={organization} />
              </div>
            );
          })}
        </div>
      )}
    </Fragment>
  );
});

const SearchOrganization = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialogStore: BatchEditDialogStore = useContext(BatchEditDialogStoreContext);

  return (
    <Fragment>
      {dialogStore.getShowSelectOrganization() && (
        <div className="select-organization-search">
          <div className="search-form">
            <SearchWithAutocompleteStoreContext.Provider value={new SearchWithAutocompleteStore(rootStore)}>
              <SearchWithAutocomplete
                cssClassName="input-search icon-search"
                onSubmit={dialogStore.searchOrganizations}
              />
            </SearchWithAutocompleteStoreContext.Provider>
            <OrganizationList />
          </div>
        </div>
      )}
    </Fragment>
  );
});

const SelectOrganization = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const userStore: UserStore = rootStore.getUserStore();
  const dialogStore: BatchEditDialogStore = useContext(BatchEditDialogStoreContext);
  const organization = dialogStore.getSelectedOrganization();

  function getOrganizatioName(): string {
    return !!organization && !!organization.displayName ? organization.displayName : "";
  }

  return (
    <Fragment>
      {userStore.isUserAdmin() && (
        <div className="organization">
          <div className="selected-organization">
            <div className="organization-title">
              {rootStore.getTranslation("batch_edit.content.current_organization")}
            </div>
            <div className="organization-name">{getOrganizatioName()}</div>
            {dialogStore.getShowSelectOrganization() ? (
              <a
                data-testid="cancel-select-organization"
                className="cancel-select-organization"
                onClick={() => dialogStore.setShowSelectOrganization(false)}
              >
                {rootStore.getTranslation("batch_edit.content.cancel_select_organization")}
              </a>
            ) : (
              <a
                data-testid="select-organization"
                className="select-organization"
                onClick={() => dialogStore.setShowSelectOrganization(true)}
              >
                {rootStore.getTranslation("batch_edit.content.select_organization")}
              </a>
            )}
          </div>
          <SearchOrganization />
        </div>
      )}
    </Fragment>
  );
});

const VersionSelection = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialogStore: BatchEditDialogStore = useContext(BatchEditDialogStoreContext);
  const versionOptions = dialogStore.getVersionOptions();

  function handleVersionChange(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;

    dialogStore.handleVersionChange(selectedOption);
  }

  return (
    <Fragment>
      <span className="select-version-title">{rootStore.getTranslation("helpers.filter_by_version")}</span>
      <div className="dropdown-wrapper">
        <Dropdown
          options={versionOptions}
          onChange={handleVersionChange}
          placeholder={rootStore.getTranslation("download.all_versions")}
          className="dropdown-wrapper gray"
          selectedValue={dialogStore.getSelectedVersionOption()}
          inputId="select-version"
          menuHeight={150}
        />
      </div>
    </Fragment>
  );
});

const Content = observer(() => {
  const store: BatchEditDialogStore = useContext(BatchEditDialogStoreContext);

  return (
    <Fragment>
      {!store.getShowSelectOrganization() && (
        <Fragment>
          <BatchContentHandlerContext.Provider value={store}>
            <div className="select-content">
              <CollectionSelection />
              <VersionSelection />
            </div>
            <div className="content-area">
              <SelectAll />
              <ContentList />
            </div>
          </BatchContentHandlerContext.Provider>
        </Fragment>
      )}
    </Fragment>
  );
});

/**
 * Renders select content fields
 */
export const SelectContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <div className="first-column">
      <h3>{rootStore.getTranslation("batch_edit.selectContent")}</h3>
      <div className="column">
        <div className="batch-select-content">
          <SelectOrganization />
          <Content />
        </div>
      </div>
    </div>
  );
});
