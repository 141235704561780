import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";

import { RootStore, RootContext } from "../../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";

/**
 * Renders the upload header.
 */
export const UploadHeader = observer(({ active }: { active: string }) => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);

  const activeCollection = active === "collection" ? "active" : "";
  const activeContent = active === "content" ? "active" : "";
  const activeVersion = active === "version" ? "active" : "";

  return (
    <div className="navigation" data-testid="uploadHeader">
      <h1>{rootStore.getTranslation("upload.header.upload_header")}</h1>
      <div className="wizard-wrapper">
        <div className="wizard-line" />
        <ul className="wizard-navigation">
          <li className={classnames("step", activeCollection)} data-testid="collectionCircle">
            <div className="step-bg-wrapper">
              <div className="step-circle bg-circle">&nbsp;</div>
            </div>
            <div className="step-circle">1</div>
            <div className="step-title main">{rootStore.getTranslation("upload.header.collection")}</div>
            {form.navigationTitles.collectionTitle && (
              <div className="step-title" data-testid="collectionTitle">
                {form.navigationTitles.collectionTitle}
              </div>
            )}
          </li>
          <li className={classnames("step", activeContent)} data-testid="contentCircle">
            <div className="step-bg-wrapper">
              <div className="step-circle bg-circle">&nbsp;</div>
            </div>
            <div className="step-circle">2</div>
            <div className="step-title main">{rootStore.getTranslation("upload.header.content")}</div>
            {form.navigationTitles.packageTitle && (
              <div className="step-title" data-testid="contentTitle">
                {form.navigationTitles.packageTitle}
              </div>
            )}
          </li>
          <li className={classnames("step", activeVersion)} data-testid="versionCircle">
            <div className="step-bg-wrapper">
              <div className="step-circle bg-circle">&nbsp;</div>
            </div>
            <div className="step-circle">3</div>
            <div className="step-title main">{rootStore.getTranslation("upload.header.version")}</div>
            {form.navigationTitles.versionTitle && (
              <div className="step-title" data-testid="versionTitle">
                {form.navigationTitles.versionTitle}
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
});
