 
export const JaAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["ja-JP"]) {
      window.locale["ja-JP"] = {};
    }

    window.locale["ja-JP"].about = {
      navigation: {
        why: "Tekla Warehouse とは？",
        usage_basics: "Tekla Warehouse の基本概念",
        "usage_basics__using-content": "コンテンツの使用",
        "usage_basics__adding-content": "コンテンツの追加",
        usage_basics__collection: "コレクション",
        "usage_basics__content-item": "コンテンツ アイテム",
        usage_basics__version: "バージョン",
        getting_started: "利用の開始",
        "getting_started__tekla-account-and-system-requirements": "Tekla account とシステム要件",
        "getting_started__tekla-warehouse-service-plugin": "Tekla Warehouse Service プラグイン",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Tekla Warehouse Service の入手方法",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures ～ Tekla Warehouse",
        content: "Tekla Warehouse のコンテンツ",
        "content__3d-products": "3D モデル製品",
        "content__custom-components": "カスタム コンポーネント",
        content__applications: "アプリケーション",
        "content__profiles-shapes-etc": "プロファイル、材質、ボルト、鉄筋など",
        "content__model-templates": "モデル テンプレート",
        "content__drawing-setup": "図面設定",
        "content__report-templates-etc": "レポート テンプレートおよび属性ファイル",
        using_content: "コンテンツの使用",
        creating_content: "コンテンツの作成",
        "creating_content__getting-started-with-content-creation": "コンテンツの作成の開始",
        "creating_content__expert-help-for-creating-content": "コンテンツ作成の専門的なサポートが必要ですか?",
        application_developers: "Tekla Structures のアプリケーション開発",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Tekla Warehouse とは？",
        description_1:
          "Tekla Warehouse には、アプリケーション、カスタム コンポーネント、部材、プロファイル、鋼やコンクリートなどの材質、ボルト、鉄筋、金網、形状、Tekla Structures のテンプレートが含まれています。 供給元が実際に作成したモデルの部材から、計画と調達に役立つ、最高の精度と正確な材質情報が得られます。既存のコンテンツを使用することにより、モデリングが迅速化されます。",
        description_2:
          "Tekla Warehouse では、このコンテンツに 1 か所でアクセスできるため、コンテンツを合理的に使用できます。 Tekla Warehouse のコンテンツは継続的に増え続けています。",
        description_3: "Tekla Warehouse では、次のことができます。",
        feature_1:
          "製造元は、製品やアプリケーションをユーザーに使ってもらえるよう公開することができます。ユーザーは、それらの正確な 3D 製品モデルを Tekla モデルにそのまま取り込み、後から物理製品を建設に使用することができます。",
        feature_2:
          "会社のネットワークを使用してコンテンツを同僚と共有したり、コンテンツをローカルに保存して個別の目的に使用したりできます。",
        description_4:
          "Tekla Warehouse の検索を使用すると、コンテンツのタイプによらず、必要なものを正確に見つけることが容易になります。",
        description_5:
          "ネットワーク/ローカル コレクションを有効にするには、Tekla Warehouse Service プラグインが必要です。",
        view_licences_html:
          '<a href="/api/third_party_licenses.txt" target="_blank">サード パーティ ライセンス</a>をご確認ください。',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Tekla Warehouse の基本概念",
        using_content: {
          title: "コンテンツの使用",
          description_1:
            "オンライン コレクションのコンテンツの使用、ネットワーク コレクションを使用した同僚とのコンテンツの共有、さらにローカル コレクションの個別目的での利用が可能です。",
          description_2:
            "ローカル/ネットワーク コレクションを使用するには、Tekla Structures 20.1 サービス リリース 1 かそれよりも新しいバージョンの Tekla Structures でインストールされる Tekla Warehouse Service が必要です。",
          description_3:
            "ローカライズされた環境コンテンツを含む特定の Tekla コレクションは、Tekla Structures とともにインストールされます。",
          description_4: "詳細については、コンテンツの使用に関するセクションを参照してください。",
        },
        adding_content: {
          title: "コンテンツの追加",
          description_1:
            'オンライン コレクションを利用すると、すべての Tekla Warehouse ユーザーにコンテンツを公開できます。 コンテンツを組織として公開するには、ご自分の組織を account.tekla.com に追加する必要があります。 詳細については、最寄りの <a href="https://www.tekla.com/jp/contact/offices-and-resellers" target="_blank">Tekla オフィスまたは販売店</a>までお問い合わせください。',
          description_2: "詳細については、コンテンツの作成に関するセクションを参照してください。",
        },
        collection: {
          title: "コレクション",
          feature_1:
            "コレクションは、コンテンツ アイテムをフォルダのようにグループ化したものであり、コンテンツへのアクセス権限 (公開/非公開) を定義できます。",
          feature_1_1:
            "モデル作成者は、オンライン コレクションにあるコンテンツの使用、ネットワーク コレクションを使用した同僚とのコンテンツの共有、ローカル コレクションの個別目的での利用が可能です。",
          feature_2:
            "コンテンツ提供者は、オンライン コレクションを使用して、すべての Tekla Warehouse ユーザーにコンテンツを公開できます。",
          feature_3:
            "ローカル/ネットワーク コレクションを使用するには、Tekla Structures 20.1 サービス リリース 1 以降でインストールされる Tekla Warehouse Service プラグインが必要です。このプラグインは別途インストールすることもできます。",
        },
        "content-item": {
          title: "コンテンツ アイテム",
          feature_1:
            "コンテンツ アイテムはファイルの論理グループです。 たとえば、3D 製品カタログ、モデル テンプレート、ツール、カスタム コンポーネントのグループ、プロファイル カタログがコンテンツ アイテムです。",
          feature_2:
            "コンテンツ アイテムには、タイプとユース ケース別にラベルを付けることができ、ユーザーがコンテンツを見つけるのに役立ちます。",
        },
        version: {
          title: "バージョン",
          feature_1:
            "1 つのコンテンツ アイテムに、異なる Tekla Structures バージョンで使用するための複数のバージョンを定義することができます。 ユーザーは、コンテンツ ページから適切なバージョンを選択できます。",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "利用の開始",
        tekla_account_and_system_requirements: {
          title: "Tekla account とシステム要件",
          register_atc_html:
            'Tekla Warehouse にログインするには Tekla account が必要です。 未登録の場合は、<a href="https://account.tekla.com/" target="_blank">account.tekla.com</a> で登録してください。 (これは、これまでのエクストラネット アカウントではないことに注意してください。)',
          see_tua_for_prerequisites_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">Tekla Warehouse の使用の前提条件</a>については、Tekla User Assistance を参照してください。',
        },
        tekla_warehouse_service_plugin: {
          title: "Tekla Warehouse Service プラグイン",
          description_1: "Tekla Warehouse は、コンテンツのオンライン ストレージである上に、次のことができます。",
          feature_1: "個別の目的で使用できるローカル コレクション",
          feature_2: "会社のネットワークを使用して同僚とコンテンツを共有できるネットワーク コレクション",
          feature_3: "コンテンツの使用時や作成時の簡易インストール",
          feature_4: "ローカルおよびネットワークのコンテンツの検索",
          description_2:
            "これらの機能を使用するには、Tekla Warehouse Service が必要です。このサービスは、ローカル バックエンドとして動作し、Tekla Structures と Tekla Warehouse ウェブサイトとの通信を行うプラグインです。",
          description_3:
            "Tekla Warehouse Service がインストールされていない場合または実行されていない場合は、Tekla Warehouse のページ上部にそれがハイライト表示されるため、トラブルシューティングすることができます。",
          description_4:
            "このサービスに問題が発生した場合、Windows のサービス プログラムを使用して再起動することができます。 サービス名は Tekla Warehouse です。 また、ブラウザーのセキュリティ設定も確認してください。",
          description_5:
            "Tekla Warehouse ウェブサイトは、Tekla Warehouse Service や Tekla Structures がなくても使用できます。 その使い方は、一般的なコンテンツ共有ウェブサイトに似ており、ファイルを手動でアップロードおよびダウンロードすることができます。",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Tekla Warehouse Service の入手方法",
          description_1:
            "Tekla Warehouse Service プラグインは、Tekla Structures 20.1 サービス リリース 1 以降でインストールされます。 Tekla Warehouse Service を個別にインストールして、ローカル/ネットワーク コレクションを有効にすることもできますが、簡易インストールとアップロード機能は、Tekla Structures 20.1 サービス リリース 1 以降でのみ使用できます。",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">TEKLA WAREHOUSE SERVICE のダウンロード</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures ～ Tekla Warehouse",
          description_1:
            "Tekla Warehouse は Tekla Structures に直接接続するため、コンテンツの使用とアップロードが容易になります。 ファイルのインポートとエクスポートにわずらわされることなく、コンテンツをモデルから直接選択したり、コンテンツをモデルに直接挿入したりできます (これには Tekla Structures 20.1 サービス リリース 1 以降が必要です)。 必要なすべてのファイルを、カスタム コンポーネントまたはプロファイルなどの同じコンテンツ アイテムに含めることができるため、意図したとおりに機能することが保証されます。",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Tekla Warehouse のコンテンツ",
        description_1:
          "Tekla Warehouse は、複数の異なるタイプのコンテンツを提供します。 以下では、異なるコンテンツ タイプの概要と利用を開始するための詳細を確認できます。",
        description_2:
          'Tekla Warehouse から <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla 拡張機能</a>をダウンロードすることもできます。',
        "3d_products": {
          title: "3D モデル製品",
          image_subtitle: "これらは、埋込み材、型枠、アクセサリなどの固定形状を持つオブジェクトです。",
          tua_link_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">Tekla Warehouse コレクションでの 3D 製品カタログの作成</a>の詳細については、Tekla User Assistance を参照してください。',
        },
        custom_components: {
          title: "カスタム コンポーネント",
          image_subtitle:
            "カスタム コンポーネントは、固定形状またはパラメトリック形状を持つ Tekla Structures のネイティブ オブジェクトです。",
          tua_link_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Tekla Structures モデルから Tekla Warehouse コレクションへのコンテンツのアップロード</a>の詳細については、Tekla User Assistance を参照してください。',
        },
        applications: {
          title: "アプリケーション",
          image_subtitle:
            "アプリケーションは、Tekla Open API を使用して Tekla Structures とやりとりします。 たとえば、データ統合を設計ソリューションにリンクできます。",
          tua_link_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">Tekla Warehouse コレクションへのアプリケーションのアップロード</a>の詳細については、Tekla User Assistance を参照してください。',
        },
        profiles_shapes_etc: {
          title: "プロファイル、形状、材質、ボルト セット、鉄筋、および金網",
          image_subtitle:
            "カタログには、部材に関連するプロパティのリストが含まれています。 たとえば、異なる材質の等級やそれらの比重が含まれています。",
          tua_link_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Tekla Structures モデルから Tekla Warehouse コレクションへのコンテンツのアップロード</a>の詳細については、Tekla User Assistance を参照してください。',
        },
        model_templates: {
          title: "モデル テンプレート",
          image_subtitle:
            "モデル テンプレートは、類似性や繰り返しのあるプロジェクトで使用できます。 テンプレートには、すべての必要なカタログ、レポート テンプレート、図面設定、および詳細オプション設定を含めることができます。",
          tua_link_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Tekla Warehouse コレクションへのファイルの手動追加</a>の詳細については、Tekla User Assistance を参照してください。',
        },
        drawing_setup: {
          title: "図面設定",
          image_subtitle:
            "設定ファイルでは、レイアウト、フィルター、図面複製テンプレートなどの図面の作成方法を定義します。",
          tua_link_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Tekla Warehouse コレクションへのファイルの手動追加</a>の詳細については、Tekla User Assistance を参照してください。',
        },
        report_templates_etc: {
          title: "レポート テンプレートおよびモデル属性ファイル",
          description_1:
            "レポート テンプレートでは、モデル データのコンテンツとレイアウトをレポートに定義します。 フィルターなどのモデル属性ファイルは、モデル フォルダに追加した場合に機能します。",
          tua_link_html:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Tekla Warehouse コレクションへのファイルの手動追加</a>の詳細については、Tekla User Assistance を参照してください。',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "コンテンツの使用",
        description_1:
          "コンテンツ タイプに応じて、選択したコンテンツを Tekla Structures モデルに挿入するか、コンテンツをダウンロードしてインストールすることができます。 以下の項目の説明については、Tekla User Assistance を参照してください。",
        feature_1:
          '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Tekla Warehouse でのコンテンツの検索</a>',
        feature_2:
          '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Tekla Warehouse からのコンテンツの使用</a>',
        feature_3:
          '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Tekla Structures モデルへの Tekla Warehouse コンテンツの挿入</a>',
        feature_4:
          '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Tekla Warehouse における競合管理</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "コンテンツの作成",
        getting_started_with_content_creation: {
          title: "コンテンツの作成の開始",
          description_1: "以下の項目の詳細については、Tekla User Assistance を参照してください。",
          feature_1:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Tekla Warehouse のはじめ方</a>',
          feature_2:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">Tekla Warehouse へのコンテンツの追加</a>',
          feature_3:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Tekla Warehouse でのコンテンツの変更</a>',
          feature_4:
            '<a href="https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Tekla Warehouse からのコンテンツのダウンロード</a>',
        },
        expert_help_for_creating_content: {
          title: "コンテンツ作成の専門的なサポートが必要ですか?",
          description_1:
            'Tekla Warehouse でコンテンツを公開することに興味があり、コンテンツの作成とメンテナンスに専門的なサポートが必要な場合は、コラボレーションの可能性の詳細について、最寄りの <a href="https://www.tekla.com/jp/contact/offices-and-resellers" target="_blank">Tekla オフィスまたは販売店</a>にお問い合わせください。',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Tekla Structures のアプリケーション開発",
        description_1:
          "Tekla では、Building Information Modeling (BIM) にオープンなアプローチを提供しており、他社のソリューションや工作機械を使用しながら、それらを Tekla 製品と連結させることができます。",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> は、Tekla により開発された特別なアプリケーション プログラミング インターフェイス (API) で、これを使用すると Tekla Structures モデリング プラットホームでアプリケーションや追加機能を開発したり、ご使用の環境に統合させることができます。 Tekla Open API は Microsoft .NET テクノロジーを使用して実装されています。',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> は、すべての Tekla Open API 情報のハブです。',
        description_4:
          'Tekla Structures のアプリケーションの開発に興味がある場合は、<a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> にご参加ください。',
      },
    };
  },
};
