import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../stores/rootStore";
import { OrganizationPageStoreContext, OrganizationPageStore } from "./organizationPageStore";
import { OrganizationPage } from "./OrganizationPage";

/**
 * A component that displays organization page.
 */
export const OrganizationPageWrapper = observer(() => {
  const store: RootStore = useContext(RootContext);

  return (
    <OrganizationPageStoreContext.Provider value={new OrganizationPageStore(store)}>
      <OrganizationPage />
    </OrganizationPageStoreContext.Provider>
  );
});
