import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import {
  AnalyticsStoreContext,
  IAnalyticsFiltersSetter,
  AnalyticsFiltersSetterContext,
  AnalyticsStore,
} from "./analyticsStore";
import { IDropdownOption } from "../../models/dataModel";
import { Dropdown } from "../Dropdown";
import { PackagePageStore, PackagePageStoreContext } from "package/packagePageStore";

/**
 * A component that renders event type filter
 */
export const EventTypeFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const analyticsStore: AnalyticsStore = useContext(AnalyticsStoreContext);
  const eventTypeOptions: IDropdownOption[] = analyticsStore.getEventTypeOptions(
    packagePageStore.hasPartnerDownloadURL() ? true : false,
  );
  let selectedEventType = "";

  const analyticsFilterSetter: IAnalyticsFiltersSetter = useContext(AnalyticsFiltersSetterContext);
  if (analyticsFilterSetter.getEventType) {
    selectedEventType = analyticsFilterSetter.getEventType();
  }

  function changeEventType(opt: IDropdownOption | null) {
    if (!opt) return;

    if (analyticsFilterSetter.setEventType) {
      analyticsFilterSetter.setEventType(opt.value);
    }
  }

  function getSelectedOption() {
    if (selectedEventType) {
      return _.find(eventTypeOptions, (option) => option.value == selectedEventType);
    }
  }

  const selectedOption = getSelectedOption();

  return (
    <div className="eventtype-options">
      <span className="label">{rootStore.getTranslation("shared.analytics.eventtype")}</span>
      <div className="dropdown-container">
        <Dropdown
          options={eventTypeOptions}
          onChange={changeEventType}
          placeholder={rootStore.getTranslation("shared.analytics.eventtype")}
          className="dropdown-wrapper gray"
          selectedValue={selectedOption}
        />
      </div>
    </div>
  );
});
