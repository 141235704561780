
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const ZhCn = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["zh-CN"]) {
      window.locale["zh-CN"] = {};
    }

    _.extend(window.locale["zh-CN"], {
      myDetails: {
        my_roles: "角色",
        my_roles_link: "Tekla Warehouse 内容的角色和访问权限",
        my_roles_link_url: "https://support.tekla.com/zh-hans/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "角色",
        roles: {
          member: "Tekla Warehouse 内容编辑者 (员工)",
          employee: "员工",
          contentEditor: "Tekla Warehouse 内容编辑者 (外部)",
        },
      },
      immutability: {
        add_code_name_for_package: "为内容添加代号",
        code_name: "代号",
        immutable: "不可变",
        archive: "存档",
        archive_collection: "存档集合及其所有内容",
        archive_version: "存档选定版本",
        archive_package: "存档包及其所有内容",
        archive_dialog_text: "已存档内容在 Tekla Warehouse 中将不可见或无法搜索。然而，如果该内容之前已在 Tekla Structures 云服务中使用过，则云服务可以继续访问该内容。",
        archive_success: function (name: string) {
          return `${name} 成功存档`;
        },
        archive_failure: function (name: string) {
          return `${name} 存档失败`;
        },
        archived_content_redirect_message: "您尝试访问的内容已存档",
        edit_title_move_or_archive: "编辑标题、移动或存档",
        edit_title_or_archive: "编辑标题或存档",
        major_update: "重大更新",
        major_update_description: "不向后兼容的更改，例如添加或删除功能，或其他破坏性更改。",
        minor_update: "小更新",
        minor_update_description: "向后兼容的更改，例如新功能或增强功能，而不会破坏现有功能。",
        patch_update: "补丁更新",
        patch_update_description: "向后兼容的修复，例如安全补丁、错误修复或性能改进，没有新功能。",
        info: "您所在组织为 Tekla Structures 云服务提供内容。Tekla Structures 云服务中的内容必须有代号和版本号，以确保兼容性和一致性。",
        immutable_files_info: "为了保持云服务中的内容一致性，您不能删除或修改文件。如果需要修改，请存档当前版本并上传新版本。",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `欲了解更多信息，请参阅<a href="${readMoreUrl}" target="_blank">此页面</a>。`;
        },
        resolve_conflict: {
          title: "解决冲突",
          description: "找到以下各项。 选择“保留现有”可保留在安装时找到的现有项。",
          table_header_name: "名称",
          table_header_type: "类型",
          table_header_action: "操作",
          action_keep_existing: "保留现有",
          action_replace: "替代",
          action_done: "完成",
        },
        auth: {
          authentication_process_failed: "身份验证过程失败",
          access_denied: "访问被拒绝",
          handle_unauthorized: {
            unauthorized: "未授权",
            please_log_in: "请登录或注册",
          },
        },
        translations: {
          edit_translations: "编辑翻译",
          edit_translations_info: "请从列表中选择语言，然后编辑并保存",
          edit_translations_default_language_info: "若用户选取的语言不可用，则会显示<b>默认值</b>",
          edit_content: "内容信息",
          edit_support: "支持",
          updated_successfully: "已成功更新翻译",
          error_on_update: "更新翻译时出错",
          default: "默认值",
          batch_translations: "批量翻译",
          export_import_info:
            "<b>输出</b>您所选内容的批量翻译模板，编辑可添加翻译的模板，并重新<b>输入</b>模板以便一次保存多个翻译。",
          import_translations: "输入",
          export_translations: "输出",
          translations_missing: " (空)",
          select_content: "选择要输出的翻译:",
          import_finished: "输入完成!",
          select_file: "选择文件",
          select_file_title: "选择要输入的文件:",
          import_from_file: "输入",
          import_error_info: "文件未遵循模板格式:",
          export_selected: "输出",
          invalid_file: "文件内容格式无效",
          import_translations_finished: "已输入翻译",
          details_too_long: "详细信息内容超出最大允许长度",
          import_failed: "输入翻译失败",
          translate_to_other_languages: "翻译为其他语言",
          import_count: function (count) {
            return "内容的已更新 " + count + " 翻译";
          },
          invalid_language: function (contentId, language) {
            return "内容 '" + contentId + "' 包含无效语言 '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "内容 '" + contentId + "' 包含无效翻译字段 '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return "内容 '" + contentId + "' 翻译字段 '" + translationField + "'  值超过最大允许长度";
          },
        },
        "3dPic": {
          "3d_pic_description": "3D 模型",
          "3d_pic_added": "已成功添加 3D 模型",
          "3d_pic_updated": "已成功更新 3D 模型",
          "3d_pic_removed": "已成功删除 3D 模型",
          "3d_pic_update_failed": "未能更新 3D 模型",
          "3d_pic_remove_failed": "未能删除 3D 模型",
          "3d_pic_missing": "无相关内容的 3D 图像。 通过单击下面的“选择 3D 模型”来选择图像。",
          "3d_pic_uploading": "正在加载模型。 请稍候...",
        },
        catalog_entity_edit: {
          froala_placeholder: "单击此处以编辑内容",
          updated_successfully: "已成功更新",
          update_failed: "更新失败",
          edit_search_criteria: "编辑搜索条件",
          edit_group_and_category: "编辑组和类别",
          edit_content: "编辑内容",
          edit_thumbnail: "编辑缩略图",
          edit_thumbnail_descriptive: "建议尺寸: 400 x 300 像素",
          edit_description: "编辑描述",
          edit_download_link: "添加或编辑合作伙伴下载链接",
          versions_not_shown_to_users:
            "由于此内容项包含合作伙伴下载链接，Tekla Warehouse 用户无法查看您之前添加到该内容项的任何版本。具有编辑权限的 Tekla Warehouse 管理员和用户仍然可以查看该内容项的所有版本。",
          partner_download_link: "输入下载 URL",
          partner_download_link_disclaimer:
            "如果您将合作伙伴下载链接添加到现有内容项，Tekla Warehouse 用户将无法再查看您之前添加到该内容项的任何版本。此操作无法撤消。",
          edit_title_or_delete: "编辑标题或删除",
          edit_title_or_move_delete: "编辑标题、移动或删除",
          edit_support: "编辑支持信息",
          edit_release_notes: "编辑发布说明",
          edit_content_wysiwyg: "编辑内容详细信息",
          edit_content_embed_link: "更多信息请参阅 Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "您只能嵌入来自 YouTube、Vimeo 或 Alugha 的视频。",
          details_unallowed_embed_info: "您只能嵌入来自以下域的内容：YouTube、Vimeo 或 Alugha。允许的 URL 格式为：",
          your_video_id: "您的视频 ID",
          edit_content_video_link_error: "视频链接无效。您可以通过 YouTube 和 Vimeo 的 URL 添加视频",
          edit_content_vide_code_error: "嵌入格式无效。仅接受 iframe。允许的视频提供商是 YouTube、Vimeo 和 Alugha。",
          details_max_length_exceeded: "详细信息内容过长",
          editor_mode_label: "编辑器模式:",
          editor_mode_on: "打开",
          editor_mode_off: "关闭",
          do_not_notify: "次要更新，不通知订阅人",
          invalid_tag: "标签无效",
        },
        transfer_package: {
          header: "移动内容",
          actions: {
            move: "移动",
            cancel: "取消",
          },
          loading: "正在加载集合...",
          moving: "正在移动内容",
          select_collection: "选择集合",
          content_moved: "内容已移动。 更改可能在几分钟后可见。",
          operation_failed: "操作失败",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "内容已成功链接至 " + name;
          },
          selected_resources_linked: function (name) {
            return "选定内容已成功链接至 " + name;
          },
          resources_linked_with_errors: "内容已链接，某些内容包含错误",
          resources_linking_failed: "链接内容失败",
          resource_unlinked: "已成功删除链接。 更改可能在几分钟后可见。",
          resources_unlinked: "已从所选内容中成功删除链接。 更改可能在几分钟后可见。",
          resources_unlinked_with_errors: "已删除链接，某些内容包含错误",
          resources_unlinking_failed: "删除链接失败",
          linking: "正在创建链接",
          linking_failed: "链接失败",
          linking_failed_title: "无法链接以下内容:",
          unlinking_failed_title: "无法取消以下内容的链接:",
          linking_failed_already_contains: "内容已链接至所选集合。 可能要过一些时间才会显示更新。",
          linking_failed_already_contains_short: "内容已链接至所选集合",
          unlinking_failed: "删除链接失败",
          unlinking_failed_does_not_contain: "已删除集合中的内容链接。 可能要过一些时间才会显示更新。",
          unlinking_failed_does_not_contain_short: "已删除集合中的内容链接",
        },
        notification_channel: {
          title_success: "成功",
          title_notice: "通知",
          title_info: "信息",
          title_error: "错误",
        },
        maintenance: {
          download_requires_tekla_maintenance: "下载需要 Tekla Maintenance",
          can_access_maintenance: {
            yes: "是",
            no: "否",
          },
        },
        confirm: {
          title: "确认",
          yes: "是",
          no: "否",
          ok: "确认",
          close: "关闭",
          dont_show: "不再显示此对话框",
        },
        show_more: " 显示更多",
        show_less: " 显示更少",
        spinner_loading: "正在加载",
        spinner_processing: "正在处理",
        spinner_loading_long_time: "加载内容可能需要几分钟时间",
        local_service: {
          info: "需要 Tekla Warehouse Service 插件才能创建本地和网络内容。 使用 Tekla Structures 20.1 SR1 或更新版本，您可以直接从模型上传内容。",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件</a> " +
            "才能创建本地和网络内容。",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件</a> " +
            "不可用。 需要该服务才能启用本地和网络集合。",
          download_to_unlock_all_features_html:
            "下载 " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件</a> " +
            "以解锁所有功能。",
          service_out_of_date_html:
            "您的 Tekla Warehouse 插件已过期。 更新 " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "需要 Tekla Warehouse Service 插件</a> " +
            "以解锁所有功能。",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "选择语言",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "邀请其他用户",
          send_invitation_to_email: "发送邀请至电子邮件",
          add_e_mail_addresses: "添加电子邮件地址",
          and_assign_role: "并分配角色",
          send_invitation: "发送邀请",
          invitation_already_sent: "邀请已发送!",
          invitation_sent: "邀请已发送!",
          update_selected_invitations: "更新所选邀请",
          new_role: "新角色",
          invitations_list_updated: "邀请列表已更新!",
        },
        item_browser_title: "选择内容项",
        prerequisites: {
          description_placeholder: "请在此处添加描述",
          link_name_placeholder: "请在此处添加链接名称",
          add_prerequisites_and_recommendations: "先决条件和建议(可选)",
          list_the_profiles_components_files: "列出所需的截面、组件、文件或模型，以便正常使用内容。",
          link_to_existing_content: "链接至现有内容",
          please_enter_prerequisite_url: "请输入先决条件 URL",
          please_enter_valid_prerequisite_url: "请输入有效的先决条件 URL",
          missing_http: "缺少 http://",
          add_link: "添加链接",
        },
        treeview: {
          cannot_export_this_container: "无法导出此容器。 请选择单个项。",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "文件必须小于 " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "文件类型无效。  文件必须是以下类型之一 " + allowed_types;
          },
        },
        analytics: {
          timeframe: "时间范围:",
          timeframe_start: "起始:",
          timeframe_end: "结束:",
          eventtype: "事件类型:",

          downloads_daily_by_version: "按版本下载",
          downloads_by_country: "按国家/地区下载",
          downloads_by_version: "按版本下载",
          downloads_by_version_organization: "按版本和组织下载",
          downloads_by_organization: "按组织下载",
          downloads_daily_by_package: "按内容项下载",
          views_daily_by_package: "按内容项查看",
          views_by_country: "按国家/地区查看",
          views_by_organization: "按组织查看",

          downloads_by_package: "按内容项下载",
          views_by_package: "按内容项查看",
          downloads_daily_by_collection: "按集合下载",
          downloads_by_collection: "按集合下载",
          views_daily_by_collection: "按集合查看",
          views_by_collection: "按集合查看",

          events_by_organization_func: function (version) {
            return '按组织划分版本 "' + version + '" 事件'; // Version "2.1" events by organizations
          },
          pie_slice_others: "其他",
          sum_all_versions: "所有版本",
          sum_all_packages: "所有内容项",
          sum_all_collections: "所有集合",
          sum_all_organizations: "所有组织",
          recording_started_at: "数据记录自 2015 年 6 月开始",
          recording_view_events_started_at: "数据记录自 2017 年 3 月开始",
          version_title_func: function (title) {
            return '版本 "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return title + " (" + pluralize(count, [count + " 下载", count + " 下载", count + " 下载"]) + ")"; // Tekla Oy (21 downloads)
          },
          counted_events_info: "数字包括所有已完成的下载。",
          counted_events_info_package: "数字包括所有已完成的下载。",
          export: {
            caption: "输出分析",
            select_options: "选择选项",
            select_timeframe_description: "请在下面选择要从中输出分析的时间范围:",
            select_eventtype_description: "请选择要从中输出分析的事件类型:",
          },
        },
        access_rights: {
          editors: "编辑器",
          download_access: "查看和下载访问",
          write_user_id_placeholder: "写入用户 ID",
          write_organization_id: "输入有效的组织 ID。每个 ID 都应在单独的行中输入。",
          write_id_placeholder: "输入用户 ID、用户电子邮件地址或组织 ID。在单独的行中输入各个 ID 或电子邮件地址。",
          id_help_html_short:
            "出于安全考虑，您将无法搜索我们的用户。<br/><br/>" +
            "直接请求您希望授予其访问权限的人员提供用户 ID。 <br/>" +
            '用户 ID 显示在<a href="#/my/">我的详细信息</a>中。',
          id_help_html:
            "出于安全考虑，您将无法搜索我们的用户。<br/><br/>" +
            "直接请求您希望授予其访问权限的人员提供用户 ID。 要为组织中的所有用户提供访问权限，请使用组织 ID。<br/><br/>" +
            '用户 ID 和组织 ID 显示在<a href="#/my/">我的详细信息</a>中。',
          max_input_limit: function (max_rows) {
            return "在单独的行中输入各个 ID 或电子邮件地址。您一次可以输入 " + max_rows + " 行。";
          },
          input_too_long: "您输入的文本过长。",
          viewer_already_added: function (input) {
            return `${input} 已添加为查看者`;
          },
          wrong_format: function (input) {
            return `${input} 已添加为查看者`;
          },
          candidates_for_keyword: function (keyword) {
            return "找到具有该电子邮件地址或 ID 的用户" + keyword;
          },
        },
      },
      images: {
        add_new: "添加新的",
        add: "添加",
        delete: "删除",
        set_as_default: "默认缩略图",
        select_file: "选择文件",
        choose_option: "选择选项",
        info_3D_exists: "您已添加一个 3D 模型，您无法将一个以上模型添加到内容中。",
        action_options: {
          image: "图像",
          "3DModel": "3D 模型",
          video: "视频",
        },
        video: {
          url: "视频 URL",
          invalid_url: "URL 无效",
        },
      },
      units: {
        bytes: "字节",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "下载",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " 次赞", number + " 次赞", number + " 次赞"]);
      },
      main_info: {
        header_title: "Tekla Warehouse 网站推出新外观！",
        header_text: "您认为全新的简洁式 Tekla Warehouse 网站怎么样？",
        header_text2: "主要变化如下：",
        bullet_text_1: "“登录”页上的“清除搜索”功能。",
        bullet_text_2: "“搜索”页上的所有搜索过滤器都位于左侧，默认的缩略图视图在同一视图中拥有更多的搜索结果。",
        bullet_text_3:
          "“内容”页上的“下载”选项位于中心，这样便于开始使用内容。 现在可以通过下载按钮同时访问“下载”和“安装”选项。",
        bullet_text_4: "“收集”页和“我的购物车”都提供了三步说明，指导您下载和安装相关内容。",
      },
      organizations: {
        error_loading_collection_contents: "加载集合内容时出错",
        error_loading_organization: "加载组织时出错",
      },
      downloads_graph: {
        date: "日期",
        total_views: "总查看数",
        total_downloads: "总下载数",
        views: "视图",
        downloads: "下载",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service 插件" +
          "</a>" +
          " 为必需。",
        processingFailed: "处理失败",
        forbidden: "已禁止",
        resource_not_found: "找不到资源",
        no_matches_found: "未发现匹配项",
        no_matches_found_for: function (input) {
          return "未找到与该电子邮件地址或 ID 匹配的结果" + input;
        },
        not_identified: "未标识",
        error_while_searching: "搜索时发生错误",
        error_user_not_found: "找不到用户或组织",
        error_while_adding_member: "添加访问权限成员时出错",
        error_while_removing_member: "删除访问权限成员时出错",
      },
      offline_to_online: {
        fail_on_initialize: "处理压缩内容时出错，中止转换",
        fail_on_validate: "内容元数据验证失败，中止转换",
        fail_on_save: "保存内容时出错，回滚更改",
        success: "将离线内容成功转换为在线内容",
        validate_errors_title: "转换内容失败",
        processing: "保存文件包 ",
        processed: "已处理的项目",
        collection_thumbnail_missing_name: "集合缩略图二进制文件缺少“文件名”",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "文件包 '" + packageTitle + "' 缩略图二进制文件缺少 &apos;文件名&apos;";
        },
        package_value_missing_title_func: function () {
          return "文件包缺少 &apos;标题&apos;";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "文件包 '" + packageTitle + "' 缺少 '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return "文件包 '" + packageTitle + "' 字段 '" + field + "' 具有以下无效值: " + invalidValues;
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "文件包 '" + packageTitle + "' 具有无效标记: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "文件包 '" + packageTitle + "' 版本缺少 &apos;标题&apos;";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "文件包 '" + packageTitle + "' 版本 '" + versionTitle + "' 缺少 '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "文件包 '" +
            packageTitle +
            "' 版本 '" +
            versionTitle +
            "' 字段 '" +
            field +
            "' 具有以下无效值： " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "文件包 '" + packageTitle + "' 版本 '" + versionTitle + "' 二进制文件缺少 &apos;文件名&apos;";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: " 重要提示：Tekla Warehouse 中本地集合的终止",
            part1: "从2025年6月2日起，Tekla Warehouse 中将不再提供本地集合。用户将无法再创建、查看或搜索本地集合，也无法使用本地集合中的内容。",
            part2: "如需有关从本地集合到在线集合的过渡帮助，请联系您的本地支持组织。",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `重要提示！作为一项安全增强措施，您必须拥有 Tekla Warehouse 内容编辑者角色才能在 2024 年 11 月 ${date} 日之后管理您所在组织的内容。请要求您所在组织的管理员为您分配该角色。有关详细信息，请参阅<a href="${productBulletinUrl}" target="_blank">产品公告</a>。`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `作为一项安全增强措施，您必须拥有 Tekla Warehouse 内容编辑者角色才能在 2024 年 11 月 ${date} 日之后管理您所在组织的内容。请要求您所在组织的管理员为您分配该角色。有关详细信息，请参阅<a href="${productBulletinUrl}" target="_blank">产品公告</a>。`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `没有看到您的组织的编辑选项？11 月 ${date} 日之后，您必须拥有 Tekla Warehouse 内容编辑者角色才能管理您所在组织的内容。您可以在“我的 Warehouse”>“我的详细信息”下查看您的角色。有关详细信息，请参阅<a href="${productBulletinUrl}" target="_blank">产品公告</a>。`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `没有看到为您的组织创建内容的选项？11 月 ${date} 日之后，您必须拥有 Tekla Warehouse 内容编辑者角色才能管理您所在组织的内容。您可以在“我的 Warehouse”>“我的详细信息”下查看您的角色。有关详细信息，请参阅<a href="${productBulletinUrl}" target="_blank">产品公告</a>。`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `11 月 ${date} 日之后，您必须拥有 Tekla Warehouse 内容编辑者角色才能管理您所在组织的内容。请要求您所在组织的管理员为您分配该角色。您可以在 Tekla Online 个人资料中查看您所在组织的管理员是谁。有关详细信息，请参阅<a href="${productBulletinUrl}" target="_blank">产品公告</a>。`
            },
          },
          service_plugin_update_info:
            "连接 Tekla Structures 时遇到问题？请确保您正在使用最新版本的 <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service 插件</a>。",
        },
        maintenance_info:
          "Tekla Warehouse 在 2017-10-27 22:00:00-23:00:00 UTC 期间将处于维护状态。 我们为给您带来不便深感抱歉。",
        release_notes_info: "Tekla Warehouse 中的新增功能",
        release_notes_link:
          'Tekla Warehouse 已更新，请阅读<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">新增功能</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "需要 Tekla 开发人员协助项目？",
        heading2Dev:
          '请查看新目录，名称是 Tekla 开发专家。<br><br>这里是有才华的程序员和感兴趣的 Tekla 用户的聚集地。<br><br>欢迎所有 Tekla Partners Program 成员向专家目录添加信息。<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">查找开发人员 &rsaquo;</a>',
        static_intro:
          "这是免费的 Tekla Structures BIM 存储空间，您可以在内部全局查找、输入、安装和共享内容。 Tekla Warehouse 旨在使您能够轻松高效地生产高质量模型。",
        new_intro:
          'Tekla Corporation 现在称为 Trimble。 我们将一如既往，继续开发 Tekla 软件解决方案，竭诚为您服务。 了解更多信息: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "请验证您的帐户",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "最新上传&rsaquo;",
          popular: "常用内容&rsaquo;",
          recommended: "建议&rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">新闻&rsaquo;</a>',
          item_title: "这是新闻!",
          item_description:
            '某些与新闻相关的文字，某些与新闻相关的文字，某些与新闻相关的文字，某些与新闻相关的文字，某些与新闻相关的文字，某些与新闻相关的文字。<br><a href="https://developer.tekla.com" target="_blank">阅读更多内容&rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse 编号",
          partners: "合作伙伴",
          partners_count: "352",
          collections: "集合",
          collections_count: "793",
          items: "内容项",
          items_count: "2893",
        },
        partners: "我们的合作伙伴",
        action_links: {
          search_title: "浏览内容?",
          upload_title: "上传内容?",
        },
        videos: {
          title: '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">视频&rsaquo;</a>',
          link: '请访问我们的 <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Youtube 频道</a>，观看更多视频。',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return '请检查您的电子邮件中的验证链接，或<u><a href="' + settingsUrl + '">订购新链接</a></u>。';
        },
        verifyAccountAcceptTerms: "请验证您的电子邮件地址，并接受条款和条件及隐私策略",
        acceptTerms: "请接受条款和条件及隐私策略",
        acceptUpdatedTerms: "请认可经更新的 tekla online services 条款和条件及隐私策略",
        termsNotAccepted_html_func: function (settingsUrl) {
          return '返回到您的<u><a href="' + settingsUrl + '">个人资料</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return '查看并接受您的<u><a href="' + settingsUrl + '">个人资料</a></u>中的条款和条件及隐私策略。';
        },
        suggestions: {
          browseCollections: "浏览集合&rsaquo;",
          new: "新",
          popular: "常用",
          recommended: "推荐",
          organizations: "组织",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "选择文件夹",
        selectFolderInstructions: "本地和网络文件夹可用作集合。",
        selectedFolder: "所选文件夹 (复制粘贴位置):",
        selectedFolderPlaceholder: "例如，C:\\folder、Z:\\network folder、\\\\machine-name\\folder",
        collectionCreation: {
          name: "名称",
          createNewCollection: "创建本地集合",
          pleaseInputTitle: "请输入标题",
          invalidPath: "路径无效",
          access_denied: "未能添加集合: 访问被拒绝",
          unsafe_location: "未能添加集合: 不安全位置",
          add_collection_failed: "未能添加集合",
          minLengthError: "标题必须至少为 3 个字符",
          maxLengthError: "标题不能长于 20 个字符",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "本地和网络文件夹可用作集合。",
        collectionCreation: {
          name: "名称",
          createNewCollection: "创建在线集合",
          pleaseInputTitle: "请输入标题",
          minLengthError: "标题必须至少为 3 个字符",
          maxLengthError: "标题不能长于 20 个字符",
        },
      },
      popups: {
        generic: {
          resourceSaved: "已保存资源",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">您相信它有这个潜力吗?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">向我们提供反馈</span>',
          help_us_improve_html:
            '帮助我们改进 Tekla Warehouse! <br/><a id="showSurvey">单击此处</a>或<a id="dontShow">不再显示此对话框</a>',
          dialog: {
            do_you_see_potential: "您相信它有这个潜力吗?",
            help_us_improve_html: "帮助我们改进 Tekla Warehouse! 您的意见对我们非常重要。",
            continue_to_survey: "开始调查",
            exit_to_warehouse: "不，谢谢",
          },
        },
      },
      search: {
        bar: {
          search: "搜索",
          filter: " ",
          more_filters: "更多过滤器",
          hide_filters: "隐藏过滤器",
          clear: "清除",
          exclude: "排除",
          include: "包括",
          clear_filters: "重置过滤器",
          load_defaults: "使用我的参数&rsaquo;",
          save_defaults: "另存为我的参数&rsaquo;",
          defaults_saved_successfully: "已保存默认过滤器",
          edit_defaults: "编辑默认值",
        },
        info: {
          searching_organizations: "正在组织中搜索...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["无结果", amountTotal + " 个结果"]);
            } else {
              return (
                pluralize(amountTotal, ["无结果", amountTotal + " 个结果"]) + ' 对于 "' + escapeHtml(searchTerm) + '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " 个结果";
            } else if (!searchTerm) {
              return "输入搜索词以查看组织结果";
            } else if (searchTerm.length < 2) {
              return "进行组织搜索需至少提供两个字符";
            } else {
              return (
                pluralize(amountTotal, ["无结果", amountTotal + " 个结果"]) + ' 对于 "' + escapeHtml(searchTerm) + '"'
              );
            }
          },
          online: " 在线内容的结果",
          local: " 本地内容的结果",
          organization: "组织的结果",
          plugin_missing_info:
            '如果您已安装 <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service 插件" +
            "</a>" +
            "，则只显示本地内容。",
          version_filter_info: "软件版本过滤对本地内容结果无效",
        },
        exclude_env_files: "排除环境文件",
        sidebar: {
          filters: {
            measurementUnits: "测量单位",
            locations: "项目位置",
            languages: "语言",
          },
          placeholders: {
            use_categories: "类别",
            measurement_units: "测量单位",
            languages: "语言",
            locations: "位置",
          },
        },
      },
      placeholders: {
        name: "名称...",
        contentOwner: "内容所有者姓名...",
        useCategory: "选择用途",
        selectType: "选择类型",
        filter: "过滤...",
        search: "搜索...",
        choose: "选择...",
        searchFilters: "搜索过滤器...",
        selectContentItem: "选择内容项...",
        search_content: "搜索内容...",
        includeAs: "包括为...",
        selectCollection: "选择集合",
        selectUserGroup: "选择用户组",
        online_collections_tooltip: "单击以搜索在线内容",
        local_collections_tooltip: "单击以搜索本地和网络内容以及 Tekla Structures 安装随附的内容",
        organizations_tooltip: "单击以搜索组织",
        "filter-options": {
          products: "所有软件产品",
          locations: "所有位置",
          languages: "所有语言",
          measurementUnits: "所有测量单位",
          testedVersions: "所有软件版本",
          useCategories: "所有类别",
          useCategoriesCombined: "类别",
          allGroups: "所有组",
          itemTypeCategories: "所有项类型",
        },
        "exclude-filter-options": {
          products: "排除软件产品",
          locations: "排除位置",
          languages: "排除语言",
          measurementUnits: "排除测量单位",
          testedVersions: "排除软件版本",
          useCategories: "排除类别",
          useCategoriesCombined: "排除类别",
          allGroups: "排除组",
          itemTypeCategories: "排除项类型",
        },
      },
      link: {
        link_selected: "链接所选内容",
        unlink_selected: "取消所选内容链接",
      },
      download_install: {
        choose_version: "选择软件版本:",
        choose_content: "从下表中选择内容项:",
        choose_action: "选择要针对所选项执行的操作:",
      },
      download: {
        copy_to_clipboard: "复制到粘帖板",
        download_selected: "下载",
        add_selected_items_to_basket: "将所选项添加到“我的购物车”",
        add_item_to_basket: "将所选项添加到“我的购物车”",
        add_all_items_to_basket: "全部添加至购物车",
        add_all_items_to_basket_info: "有 100 多项。 正在加载，可能需要一些时间",
        add_all_items_to_basket_too_many_results: "添加项目不得超过 1000 项",
        cart_loading: "有 100 多项。 正在加载，可能需要一些时间",
        remove_item_from_basket: "从购物车中删除",
        shopping_cart_no_content: "购物车中无内容",
        shopping_cart_no_content_for_version: "购物车中没有关于所选版本的内容",
        shopping_cart_items_func: function (itemsCount) {
          return "我的购物车(" + itemsCount + ") ";
        },
        download_instructions: {
          title: "下载说明",
          step_one: "使用此按钮，将下载信息复制到粘帖板",
          step_two_func: function (downloadAppUrl) {
            return (
              "运行 Tekla Warehouse 下载器应用程序。 您可以从 " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">此处</a>下载该应用程序。 '
            );
          },
          step_three: "Tekla Warehouse 下载器应用程序将指导您完成其余下载操作。",
          show_data_title: "无法工作?",
          show_data_to_be_copied: "手动复制",
          hide_data_to_be_copied: "隐藏下载数据",
        },
      },
      install: {
        install_title: " 将使用 Tekla Warehouse 插件安装项。",
        failed_install_information:
          "您所选择的内容仅可供下载。 目前不会安装此内容。 请单独从 Tekla Warehouse 下载此内容。",
        verify_service_plugin_is_running: "安装: 请检查您 Tekla Warehouse Service 插件能否正常运行",
        verify_ts_connection: "安装: 请检查 Tekla Structures 是否正在运行",
        select_software_version: "按软件版本过滤，以进行链接、下载或安装",
        download_requires_maintenance: "访问以下某些项需要安装 Tekla Maintenance",
        install_selected: "安装",
        start_install: "开始安装",
        install_started: "安装已开始",
        select_ts_version: "选择 TS 版本",
        install_retry: "如果需要重试，请从 Tekla Warehouse 重新开始安装",
        failed_installs_info_func: function (failedCount) {
          return "不需要安装的内容(" + failedCount + " 版本):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "安装所选 " + totalInstallsCount + " 项";
        },
      },
      helpers: {
        behalf: "由",
        sortBy: "排序依据:",
        filter: "过滤结果",
        showAsThumbs: "显示为缩略图",
        showAsList: "显示为列表",
        resultsPerPage: "按页显示:",
        filter_by_version: "按版本过滤:",
        show_more_options: "更多选项",
        show_less_options: "更少选项",
        filter_by_type: "按类型过滤:",
        select_all: "全选",
        show_content_items: "显示内容项",
        choose_action: "选择要针对所选内容执行的操作: ",
        show_collections: "显示集合",
        search: {
          show: "显示:",
          show_content_items: "内容项",
          show_collections: "集合",
          show_organizations: "组织",
          show_online_content: "在线内容",
          show_offline_content: "离线内容",
          local_content_info:
            '如果您已安装 <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " 插件，则只显示本地内容。",
        },
        tekla_maintenance_required: "访问以下某些项需要安装 Tekla Maintenance",
        show_content_alerts: "显示内容提醒 ",
        show_collection_alerts: "显示集合提醒 ",
      },
      actions: {
        load_more: "加载更多",
        use_infitinite_scroll: "使用无限滚动",
        back: "后退",
        show_download_dialog: "下载",
        download: "只下载",
        download_on_partner_site: "在合作伙伴网站上下载",
        insert: "安装",
        install: "安装",
        downloadAndInstall: "安装",
        createNew: "新建",
        viewItems: "视图",
        viewCollection: "视图集合",
        viewGroup: "显示组",
        joinGroup: "加入组",
        loginToDownload: "登录以下载",
        show3DPic: "显示 3D 模型",
        remove: "删除",
        browse: "浏览",
        browseFolder: "浏览",
        browseFileSystem: "浏览",
        add: "添加",
        save: "保存",
        batch_edit: "批量编辑",
        translations: "翻译",
        cancel: "取消",
        continue: "继续",
        delete: "删除",
        addNetworkPath: "添加网络位置",
        select: "选择",
        create: "创建",
        update: "更新",
        choose_thumbnail: "选择缩略图",
        choose_picture: "选择图片",
        remove_picture: "删除图片",
        choose_3D_picture: "选择 3D 模型",
        remove_3D_picture: "删除 3D 模型",
        like: "喜欢",
        unlike: "不喜欢",
        close: "关闭",
        export: "输出",
        add_or_remove: "添加/删除",
        select_content_to_convert: "选择离线内容",
        search: "搜索",
        upload: "上传",
        edit: "编辑",
        view_3d: "单击以查看模型...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "测试版",
        search: "搜索",
        profile: "我的详细信息",
        myWarehouse: "我的 Warehouse",
        myStuff: "我的资料",
        logout: "注销",
        login: "登录",
        register: "注册",
        upload: "上传",
        uploading: "上传",
        dashboard: "仪表板",
        learnMore: "了解更多信息",
        about: "关于",
        shoppingCart: "我的购物车",
        addToCartInfo: "可以集中添加多个项至我的购物车，然后一次下载或安装这些项。 下载不收取任何费用",
      },
      cubeNavigation: {
        headings: {
          get_started: "入门",
          learn: "学习",
          get_help: "获取帮助",
          extend_your_tekla: "扩展 TEKLA",
          collaborate: "合作",
          students_and_educators: "Students and educators",
          about: "关于",
        },
        "tekla-online": "在线服务",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Tekla 网站",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "我的个人资料",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla 在线学习",
        tekla_services: "TEKLA 服务主页",
        register: "注册",
        login: "登录",
        evaluate: "评估",
        buy: "购买",
        download: "下载",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "产品",
        teklaServices: "服务",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "我的集合",
        myLikes: "我喜欢的项目",
        profile: "我的详细信息",
        preferences: "我的参数",
        myUserGroups: "我的用户组",
        localCollections: "本地和网络集合",
        tsCollections: "Tekla Structures 集合",
        downloads: "下载",
        selectContent: "选择内容文件",
        addMetadata: "添加元数据",
        onlineCollections: "在线集合",
        userGroups: "用户组",
        createNew: "添加内容",
        notifications: "通知",
        contentUsage: "内容使用",
        browseCollections: "浏览集合",
        admin: "管理员",
        sales: "销售",
        shoppingCart: "我的购物车",
      },
      notifications: {
        title: "通知",
        invitedBy: "邀请人",
        invitationType: "邀请类型",
        invitedTo: "邀请至",
        createdAt: "创建日期",
        action: "操作",
        acceptInvitation: "接受",
      },
      batch_edit: {
        selectActionType: "选择操作类型",
        edit_content: "编辑内容元数据",
        content_title: "内容",
        metadataToChange: "要更改的元数据",
        selectContent: "针对内容",
        select_action: "选择操作",
        support: {
          support: "支持",
          licenses: "许可证",
        },
        versions: {
          version: "版本",
          prerequisities: "先决条件和要求",
        },
        content: {
          select_collection: "选择集合",
          show_collection: "显示:",
          show_all_content: "所有集合",
          show_organization_content: "我的组织集合",
          select_all: "全选",
          select_all_info: "(这可能需要一点时间)",
          actionOptions: {
            add: "添加",
            remove: "删除",
            replace: "替换为",
            yes: "是",
            no: "否",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "未选择属性",
            invalid_attributes: "无效属性值",
            invalid_actions: "无效操作",
            content_not_selected: "未选择内容",
            entities_not_selected: "已提供与文件包相关的数据，但未选择文件包",
            versions_not_selected: "已提供与版本相关的数据，但未选择版本",
          },
        },
        update: {
          info_processing: "请将此窗口保持在打开状态，直至处理完所有项。",
          info_processed: "所有项均已处理。",
          processing: "正在处理项",
          error_info: "更新以下项时出现问题:",
          package_failure_func: function (package_title, errorMsg) {
            return "内容: " + package_title + "，错误: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "内容: " + package_title + "，版本: " + version_title + "，错误: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " 项将会更新，是否继续?";
          },
        },
      },
      myUserGroups: {
        title: "用户组",
        createdByPrefix: "由:",
      },
      downloads: {
        title: "下载",
      },
      myCollections: {
        new_collection: "新建集合",
        my_collections: "我的集合",
        created_by_me: "由我创建",
        local_collections: "本地和网络集合",
        online_collections: "在线集合",
        created_by_my_organization: "由我的组织创建",
        created_by_my_organizations: "由我的组织创建",
        shared_with_me: "与我共享",
        shared_with_me_or_organization: "与我或我的组织共享",
        notification: {
          error: "错误",
          error_loading_online_collections: "加载在线集合时出错",
          error_loading_packages_liked_by_me: "加载我喜欢的内容时出错",
          error_loading_my_organization_s_collections: "加载我的组织的集合时出错",
          error_loading_my_shared_collections: "加载我的共享集合时出错",
        },
        local: {
          helpText1: "要访问本地集合，请安装 ",
          helpText2: "Tekla Warehouse Service 插件",
          helpText3: ".",
          title: "本地和网络集合",
          notFound: "尚未找到本地集合。",
          myCollectionsTitle: "我的集合",
          actions: {
            createNew: "创建本地或网络集合",
          },
        },
      },
      organization: {
        news_feed: "新闻源",
        add_comment: "添加源文本",
        edit_comment: "编辑新闻项",
        subTabs: {
          collections: "集合",
          analytics: "分析",
          contact_and_support: "联系方式",
          news_feed: "新闻源",
        },
        edit: {
          edit_title: "编辑标题",
          edit_description: "编辑描述",
          edit_contact: "编辑联系信息",
          edit_logo: "编辑徽标",
          edit_url: "编辑 URL",
          edit_email: "编辑电子邮件",
          edit_phonenumber: "编辑电话号码",
          edit_warehouse_url: "编辑 Warehouse URL",
          edit_warehouse_url_subtitle: "字母表中的字母，不包含斯堪的纳维亚字符、数字或特殊字符",
          edit_url_subtitle: "插入完整 URL",
          edit_logo_descriptive: "最佳图片尺寸大约为 350 * 200 像素",
          edit_facebook_url: "编辑 Facebook URL",
          edit_twitter_url: "编辑 Twitter URL",
          edit_linkedin_url: "编辑 LinkedIn URL",
          edit_youtube_url: "编辑 Youtube URL",
          edit_googleplus_url: "编辑 Google + URL",
          logo_updated: "已更新徽标",
          logo_update_failed: "徽标更新失败",
          logo_remove_failed: "徽标删除失败",
          logo_removed: "已成功删除徽标",
          updated_successfully: "已成功更新",
          updated_failed: "更新失败",
          add_comment: "添加新的",
          edit_comment: "编辑信息项",
          comment_added: "已成功添加新闻源项",
          comment_updated: "已成功更新新闻源项",
          comment_deleted: "已成功删除新闻源项",
          comment_add_failed: "新闻源添加失败",
          comment_update_failed: "未能更新新闻源项",
          comment_delete_failed: "未能删除新闻源项",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "按...搜索在线内容 " + name + " &rsaquo;";
        },
        contact_information: "联系我们",
        website: "网站&rsaquo;",
        show_newer_news: "新版",
        show_older_news: "旧版",
      },
      online_collections: {
        new_collection: "新建集合",
        online_collections: "在线集合",
        created_by_organizations: "由组织创建",
        created_by_users: "由用户创建",
      },
      local_collections: {
        tab_title: "内容",
        local_collections: "本地集合",
      },
      versions: {
        add_tool: "添加应用程序",
        table_header: {
          name: "名称",
          type: "类型",
          platform: "平台",
          modified_at: "修改时间",
          size: "尺寸",
          virus_scan: "病毒扫描",
        },
        // placeholders
        select_file_type: "选择文件类型",
        select_platform: "选择平台",
        select_unit: "选择单位",
        select_quality: "选择质量",
        what_will_be_added: "您希望添加哪些内容?",
        select_files_from_your_disk: "从磁盘中选择文件",
        add: "添加",
        //content management
        add_application: "添加应用程序",
        select_content_from_tekla_structures: "从 Tekla Structures 中选择内容",
        or: "或者",
        files: "文件",
        //version metadata
        version_information: "版本信息",
        version_display_name: "版本显示名称",
        version_number: "版本号",
        version_number_info: "输入版本号。版本号保存后不可更改。",
        existing_versions: "此内容的现有版本号",
        version_number_validation: {
          missing: "您必须输入版本号",
          invalid: "版本号必须遵循语义版本控制规则（MAJOR.MINOR.PATCH）",
          already_in_use: "此版本号已用于此内容。",
        },
        select_update_type: "要生成版本号，首先选择更新类型",
        please_enter_a_version_name_or_number: "请输入版本名称或版本号。",
        virus_scan: {
          soft_failed: "失败",
          hard_failed: "未扫描",
          infected: "受感染",
        },
      },
      collections: {
        by: "由:",
        edit: {
          edit_collection: "编辑集合",
          edit_description: "编辑描述",
        },
        notification: {
          collection_updated: "集合已更新",
          error: "错误",
          error_loading_other_users_collections: "加载其他用户的集合时出错",
          error_loading_other_organizations_collections: "加载其他组织的集合时出错",
          error_loading_collection_contents: "加载集合内容时出错",
          members_list_updated: "成员列表已更新!",
          invitations_removed: "邀请已删除!",
          collection_removed: "集合已删除",
          thumbnail_deleted: "缩略图已删除",
          wrong_file_type: "文件类型错误。",
          invalid_file_type: "文件类型无效。",
          selected_image_is_too_big: "所选图像太大。",
          thumbnail_updated: "缩略图已更新",
          default_thumbnail_updated: "已更新默认缩略图",
          default_thumbnail_update_failed: "默认缩略图更新失败",
          thumbnail_added: "已添加缩略图",
          thumbnail_update_failed: "缩略图更新失败",
          video_added: "已添加视频",
          video_adding_failed: "未能添加视频",
          video_deleted: "已删除视频",
          video_deleting_failed: "未能删除视频",
        },
        confirm: {
          delete_all: function (title) {
            return '删除集合以及集合中的所有内容"' + title + '"?';
          },
          remove_members: "确定吗?",
          remove_invitations: "确定吗?",
        },
        useNetworkDrive: "使用网络驱动器或文件夹",
        createCollection: "创建在线集合",
        createCollectionInstructions: "创建集合以对内容分组。",

        actions: {
          delete_thumbnail: "删除缩略图",
          edit_collection: "编辑集合",
          delete_collection: "删除集合",
          add_content: "添加内容",
        },
        subTabs: {
          content: "内容",
          members: "显示成员",
          analytics: "分析",
          manage_access: "管理访问权限",
        },

        members: {
          add_user_groups: "添加用户组",
          select_user_group: "选择用户组",
          with_role: "角色为",
          user_groups_added: "已添加用户组!",
          members_list_updated: "成员列表已更新!",
          update_selected_members: "更新所选成员",
          table_header: {
            member_type: "成员类型",
            member_name: "成员名称",
            email: "电子邮件",
            role: "角色",
            joined_at: "已加入",
            invited_at: "已受邀",
          },
          new_role: "新角色",
        },
        member_list: {
          users_who_can_access_this_collection: "可以访问此集合的用户",
          add_user_group: "添加用户组",
          update_members: "更新成员",
          remove_members: "删除成员",
          pending_invitations: "待处理的邀请",
          user: "用户",
          invite_member: "邀请成员",
          update_invitations: "更新邀请",
          remove_invitations: "删除邀请",
        },
        labels: {
          select_organization: "选择组织",
          select_visibility: "选择可见性",
          title: "名称",
          name: "姓名",
          createAs: "创建为: ",
          asMyself: "我自己",
          asUserGroup: "用户组",
          asOrganization: "组织",
          selectOwner: "选择内容所有者所在组织",
          visibility: "可见性",
          visibilityOptions: {
            public: "公开",
            private: "私有",
          },
          description: "描述",
        },
        new_collection: {
          please_input_a_username: "请输入用户名",
          title_is_required_to_be_at_least_3_characters: "标题必须至少为 3 个字符",
          title_cannot_be_longer_than_20_characters: "标题不能长于 20 个字符",
        },
        collection_list: {
          no_content: "没有集合。",
          results_summary: function (number) {
            return "" + number + "  集合 " + pluralize(number, ["个项", "个项", "个项"]);
          },
        },
        entity_list: {
          no_content: "集合中无内容。",
          results_summary: function (number) {
            return "" + number + " 内容 " + pluralize(number, ["个项", "个项", "个项"]);
          },
          linked_content: "已链接内容",
          unlink: "取消内容链接",
        },
      },
      alerts: {
        no_updates: "最近未更新",
        notifications_marked_as_read: "标记为已读的通知",
        cleared_alerts: "所有通知已清除",
        alert_by_email: "如下所列内容若有新版本更新或集合有新内容更新，请向我发送电子邮件",
        action_types: {
          create: "已创建",
          update: "已编辑",
          delete: "已删除",
          added_entity: "版本已添加",
          removed_entity: "版本已删除",
          added_collection: "内容已添加",
          removed_collection: "内容已删除",
        },
        property_names: {
          description: "详细信息",
          searchCriteria: "搜索条件信息",
          groupCategory: "组和类别信息",
          support: "支持信息",
          licenses: "许可证信息",
          relatedContent: "相关内容信息",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "已更新 " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "已成功更新通知参数",
          preferences_update_failed: "更新通知参数时出错",
        },
      },
      browseDialog: {
        useNetworkDrive: "使用网络驱动器或文件夹",
        ownerGroups: {
          title: "所有者组",
        },
      },
      itemTypeCategories: {
        all: "所有组",
        application: "应用程序",
        "3dProduct": "3D 产品",
        customComponent: " 自定义组件部件",
        tool: "应用程序",
        profile: "型材/截面",
        material: "材质",
        bolt: "螺栓",
        rebar: "钢筋",
        reportTemplate: "报告模板",
        drawingSetup: "图纸设置文件",
        modelSetup: "模型设置文件",
        environmentFile: "环境文件",
        shape: "形状",
        //'shapeCatalog': '形状目录',
        //'foreignGeometryDefinition': '外部几何形状定义'
      },
      platforms: {
        WIN_X86: "32 位",
        WIN_X64: "64 位",
        ANY: "与平台无关",
      },
      platformsDownload: {
        WIN_X86: "32 位",
        WIN_X64: "64 位",
        ANY: " ",
      },
      contentItemSource: {
        other: "常规 - 模型文件夹",
        run: "运行工具",
        bolt: "螺栓构件",
        component: "组件部件",
        drawing: "图纸设置 (BETA)",
        material: "材料",
        profile: "型材/截面",
        mesh: "钢筋网",
        rebar: "钢筋",
        shape: "形状",
        geometry: "几何形状",
        modeltemplate: "模型模板",
        cloningtemplate: "复制模板",
        noaction: "无操作",
        releasenotes: "版本说明",
      },
      contentItemHeader: {
        bolt: "螺栓构件",
        component: "组件部件",
        drawing: "图纸设置 (BETA)",
        material: "材质",
        profile: "型材/截面",
        mesh: "钢筋网",
        rebar: "钢筋",
        shape: "形状",
      },
      useCategoryGroups: {
        workflow: "工作流",
        structures: "结构",
        drawings: "图纸",
        modeling: "建模",
      },
      useCategories: {
        title: "用于",
        conceptualDesign: "概念设计",
        designEngineeringAnalysis: "设计/工程/分析",
        detailing: "细化",
        fabrication: "制造",
        erectionInstallationPour: "安装/浇筑",
        contracting: "承包",
        scheduling: "预定",
        takeOffEstimation: "工料估算",
        concreteStructures: "现场浇筑混凝土",
        precastConcrete: "预制混凝土",
        offshore: "海岸结构 ",
        steelStructures: "钢制结构",
        timberStructures: "木制结构",
        reinforcement: "钢筋",
        drawingCreation: "图纸生成",
        drawingManagement: "图纸管理",
        drawingEditing: "图纸编辑",
        drawingAnnotation: "图纸注释",
        viewSimulateNavigate: "查看、模拟、导航",
        compareReviewManageData: "比较、检查、管理数据",
        softwareCollaboration: "软件协作",
        productivity: "生产力",
        interoperability: "互操作性",
        systemSetup: "系统设置",
      },
      measurementUnits: {
        "0": "公制",
        "1": "英制",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "打开 BIM (IFC)",
        standalone: "独立",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "不指定版本",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "实验",
        candidate: "候选",
        approved: "已认可",
        standard: "标准",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "英语",
        "zh-Hans": "简体中文",
        "zh-Hant": "繁体中文",
        cs: "捷克语",
        da: "丹麦语",
        nl: "荷兰语",
        fi: "芬兰语",
        fr: "法语",
        de: "德语",
        hu: "匈牙利语",
        it: "意大利语",
        ja: "日语",
        ko: "韩语",
        pl: "波兰语",
        pt: "葡萄牙语",
        "pt-br": "葡萄牙语 - 巴西",
        ru: "俄语",
        es: "西班牙",
        sv: "瑞典语",
        no: "挪威语",
        other: "其他",
      },
      isoLangs: {
        ab: {
          name: "阿布哈兹语",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "阿法尔语",
          nativeName: "Afaraf",
        },
        af: {
          name: "南非荷兰语",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "阿肯语",
          nativeName: "Akan",
        },
        sq: {
          name: "阿尔巴尼亚语",
          nativeName: "Shqip",
        },
        am: {
          name: "阿姆哈拉语",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "阿拉伯语",
          nativeName: "العربية",
        },
        an: {
          name: "阿拉贡语",
          nativeName: "Aragonés",
        },
        hy: {
          name: "亚美尼亚语",
          nativeName: "Հայերեն",
        },
        as: {
          name: "阿萨姆语",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "阿瓦尔语",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "阿维斯陀语",
          nativeName: "avesta",
        },
        ay: {
          name: "艾马拉语",
          nativeName: "aymar aru",
        },
        az: {
          name: "阿塞拜疆语",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "班巴拉语",
          nativeName: "bamanankan",
        },
        ba: {
          name: "巴什基尔语",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "巴斯克语",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "白俄罗斯语",
          nativeName: "Беларуская",
        },
        bn: {
          name: "孟加拉语",
          nativeName: "বাংলা",
        },
        bh: {
          name: "比哈尔语",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "比斯拉马语",
          nativeName: "Bislama",
        },
        bs: {
          name: "波斯尼亚语",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "布列塔尼语",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "保加利亚语",
          nativeName: "български език",
        },
        my: {
          name: "缅甸语",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "加泰罗尼亚语；瓦伦西亚语",
          nativeName: "Català",
        },
        ch: {
          name: "查莫罗语",
          nativeName: "Chamoru",
        },
        ce: {
          name: "车臣语",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "齐佩瓦语；齐切瓦语；尼昂加语",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "简体中文",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "简体中文",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "繁体中文",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "繁体中文 (中国台湾)",
          nativeName: "國語",
        },
        cv: {
          name: "楚瓦什语",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "康沃尔语",
          nativeName: "Kernewek",
        },
        co: {
          name: "科西嘉语",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "克里语",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "克罗地亚语",
          nativeName: "hrvatski",
        },
        cs: {
          name: "捷克语",
          nativeName: "česky, čeština",
        },
        da: {
          name: "丹麦语",
          nativeName: "dansk",
        },
        dv: {
          name: "迪维希语；马尔代夫语",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "荷兰语",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "英语",
          nativeName: "English",
        },
        eo: {
          name: "世界语",
          nativeName: "Esperanto",
        },
        et: {
          name: "爱沙尼亚语",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "维语",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "法罗语",
          nativeName: "føroyskt",
        },
        fj: {
          name: "斐济语",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "芬兰语",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "法语",
          nativeName: "Français",
        },
        ff: {
          name: "富拉语",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "加利西亚语",
          nativeName: "Galego",
        },
        ka: {
          name: "格鲁吉亚语",
          nativeName: "ქართული",
        },
        de: {
          name: "德语",
          nativeName: "Deutsch",
        },
        el: {
          name: "希腊语(现代)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "瓜拉尼语",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "古吉拉特语",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "海地语；海地克里奥尔语",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "豪萨语",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "希伯来语(现代)",
          nativeName: "עברית",
        },
        hz: {
          name: "赫雷罗语",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "北印度语",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "希里莫图语",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "匈牙利语",
          nativeName: "Magyar",
        },
        ia: {
          name: "国际语",
          nativeName: "Interlingua",
        },
        id: {
          name: "印度尼西亚语",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "国际语",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "爱尔兰语",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "伊博语",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "伊努皮克语",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "伊多语",
          nativeName: "Ido",
        },
        is: {
          name: "冰岛语",
          nativeName: "Íslenska",
        },
        it: {
          name: "意大利语",
          nativeName: "Italiano",
        },
        iu: {
          name: "因纽特语",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "日语",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "爪哇语",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "格陵兰语",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "坎那达语",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "卡努里语",
          nativeName: "Kanuri",
        },
        ks: {
          name: "克什米尔语",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "哈萨克语",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "高棉语",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "基库尤语",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "卢旺达语",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "吉尔吉斯语",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "科米语",
          nativeName: "коми кыв",
        },
        kg: {
          name: "刚果语",
          nativeName: "KiKongo",
        },
        ko: {
          name: "韩语",
          nativeName: "한국어",
        },
        ku: {
          name: "库尔德语",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "库瓦亚马语",
          nativeName: "Kuanyama",
        },
        la: {
          name: "拉丁语",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "卢森堡语",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "卢干达语",
          nativeName: "Luganda",
        },
        li: {
          name: "林堡语",
          nativeName: "Limburgs",
        },
        ln: {
          name: "林加拉语",
          nativeName: "Lingála",
        },
        lo: {
          name: "老挝语",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "立陶宛语",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "鲁巴加丹加语",
          nativeName: "",
        },
        lv: {
          name: "拉多维亚语",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "马恩岛语",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "马其顿语",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "马达加斯加语",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "马来语",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "马拉雅拉姆语",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "马耳他语",
          nativeName: "Malti",
        },
        mi: {
          name: "毛利语",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "马拉地语",
          nativeName: "मराठी",
        },
        mh: {
          name: "马绍尔语",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "蒙古语",
          nativeName: "монгол",
        },
        na: {
          name: "瑙鲁语",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "纳瓦霍语",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "巴克摩挪威语",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "北恩德贝勒语",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "尼泊尔语",
          nativeName: "नेपाली",
        },
        ng: {
          name: "恩东加语",
          nativeName: "Owambo",
        },
        nn: {
          name: "挪威尼诺斯克语",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "挪威语",
          nativeName: "Norsk",
        },
        ii: {
          name: "诺苏语",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "南恩德贝勒语",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "奥克西坦语",
          nativeName: "Occitan",
        },
        oj: {
          name: "齐佩瓦语",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "古教会斯拉夫语",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "奥罗莫语",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "奥里雅语",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "奥塞梯语",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "旁遮普语",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "巴利语",
          nativeName: "पाऴि",
        },
        fa: {
          name: "波斯语",
          nativeName: "فارسی",
        },
        pl: {
          name: "波兰语",
          nativeName: "polski",
        },
        ps: {
          name: "普什图语",
          nativeName: "پښتو",
        },
        pt: {
          name: "葡萄牙语",
          nativeName: "Português",
        },
        "pt-br": {
          name: "葡萄牙语 - 巴西",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "盖丘亚语",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "罗曼什语",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "基隆迪语",
          nativeName: "kiRundi",
        },
        ro: {
          name: "罗马尼亚语，摩尔达维亚语，摩尔多瓦语",
          nativeName: "română",
        },
        ru: {
          name: "俄语",
          nativeName: "русский",
        },
        sa: {
          name: "梵语",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "撒丁语",
          nativeName: "sardu",
        },
        sd: {
          name: "信德语",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "北萨米语",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "萨摩亚语",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "桑戈语",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "塞尔维亚语",
          nativeName: "српски језик",
        },
        gd: {
          name: "苏格兰盖立语；盖立语",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "修纳语",
          nativeName: "chiShona",
        },
        si: {
          name: "僧伽罗语",
          nativeName: "සිංහල",
        },
        sk: {
          name: "斯洛伐克语",
          nativeName: "slovenčina",
        },
        sl: {
          name: "斯洛文尼亚语",
          nativeName: "slovenščina",
        },
        so: {
          name: "索马里语",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "南索托语",
          nativeName: "Sesotho",
        },
        es: {
          name: "西班牙", //      'name':'传统/西班牙语',
          nativeName: "西班牙语",
        },
        su: {
          name: "巽他语",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "斯瓦希里语",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "史瓦济兰语",
          nativeName: "SiSwati",
        },
        sv: {
          name: "瑞典语",
          nativeName: "svenska",
        },
        ta: {
          name: "泰米尔语",
          nativeName: "தமிழ்",
        },
        te: {
          name: "泰卢固语",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "塔吉克语",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "泰语",
          nativeName: "ไทย",
        },
        ti: {
          name: "提格里尼亚语",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "标准藏语，中部藏语",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "土库曼语",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "塔加拉族语",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "茨瓦纳语",
          nativeName: "Setswana",
        },
        to: {
          name: "汤加语(汤加群岛)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "土耳其语",
          nativeName: "Türkçe",
        },
        ts: {
          name: "聪加语",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "鞑靼语",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "特维语",
          nativeName: "Twi",
        },
        ty: {
          name: "大溪地语",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "维吾尔语",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "乌克兰语",
          nativeName: "українська",
        },
        ur: {
          name: "乌尔都语",
          nativeName: "اردو",
        },
        uz: {
          name: "乌兹别克语",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "文达语",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "越南语",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "沃拉普克语",
          nativeName: "Volapük",
        },
        wa: {
          name: "瓦隆语",
          nativeName: "Walon",
        },
        cy: {
          name: "威尔士语",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "沃洛夫语",
          nativeName: "Wollof",
        },
        fy: {
          name: "西弗里西亚语",
          nativeName: "Frysk",
        },
        xh: {
          name: "科萨语",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "意第绪语",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "约鲁巴语",
          nativeName: "Yorùbá",
        },
        za: {
          name: "壮语",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "其他",
          nativeName: "其他",
        },
      },

      details: {
        navigation: {
          content_information: "内容信息",
          version_and_file_management: "版本和文件管理",
          details: "详细信息",
          versions: "版本",
          analytics: "分析",
          statistics: "统计资料",
          manage_access: "管理访问权限",
          related_content: "相关",
          properties: "属性",
          support: "公司与支持",
          release_notes: "发布说明",
          comments: "用户评注",
        },
        related_content: {
          other_content_title: "其他您可能喜欢的内容",
          related_content_title: "内容推荐者 ",
          related_content_url: "内容 URL",
          add_related_content: "添加相关内容",
          add_related_content_info: "您只能添加相关的在线内容至在线内容，添加相关的本地内容至本地内容",
          remove_related_content: "删除",
          relatedContentUrlSubtitle: "内容 ID 或完整的内容 URL",
          updated_successfully: "已成功更新相关内容",
          update_failed: "更新相关内容时出错",
          update_failed_invalid_id: "提供的内容 ID 无效",
        },
        actions: {
          move: "移动",
          delete: "删除",
          add_comment: "添加评注",
          add_reply: "添加回复",
          edit_comment: "编辑评注",
          reply_comment: "添加回复",
          view_replies: "查看回复",
          report_bad_comment: "报告错误评注&rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "编辑标题或删除",
            title_or_move_delete: "编辑标题、移动或删除",
            thumbnail: "编辑缩略图",
            description: "编辑描述",
            groupandcategory: "编辑组和类别",
            details: "编辑详细信息",
            versions: "编辑版本",
            support: "编辑支持信息",
            release_notes: "编辑发布说明",
            search_criteria: "编辑搜索条件",
            batchedit_and_translations:
              "使用翻译功能，您可以发布多语言版本的内容。 系统会根据用户所选的 Warehouse 用户界面语言，为用户显示相关内容的对应翻译版本。 使用批量编辑，您可以一次编辑多个项。 首先选择一个字段，然后选择要编辑的项",
          },
        },
        links: {
          link: "链接",
          select_collection_placeholder: "选择要链接的集合",
          linking_collections_label: "链接至此内容的集合",
          private_content_note: "注意: 私有内容仅对能够访问它的用户可见。",
          share_on_social_media: "共享位置:",
          report_bad_content: "报告错误内容&rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "举报非法内容 &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "如果订阅提醒，就表示您同意与内容所有者共享您的 Tekla Account 显示名称和组织名称(如果适用)。",
          alert_me: "提醒我",
          unsubscribe: "取消订阅",
          subscribed_successfully: "已成功订阅",
          unsubscribed_successfully: "已成功取消订阅",
          subscribers_label: "订阅人",
        },
        reviews: {
          reviewers_label: "审核人",
        },
        comments: {
          replies: "回复",
        },
        scan: {
          soft_failed_binaries_info: "病毒扫描失败  ",
          hard_failed_binaries_info: "无法完成病毒扫描 ",
          infected_binaries_info: "病毒扫描发现感染问题 ",
          what_now: "现在怎么办?",
          popup: {
            soft_failed_binaries_title: "二进制文件出现问题",
            hard_failed_binaries_title: "二进制文件出现问题",
            infected_binaries_title: "二进制文件受到感染",
            soft_failed_binaries_info:
              "未能对已上传文件进行病毒扫描。 这只是暂时性问题。 请删除这些文件，然后重新上传。 在对其他用户显示所有文件之前，这些文件必须通过病毒扫描。",
            hard_failed_binaries_info:
              "无法完成病毒扫描。 请删除这些文件。 在对其他用户显示所有文件之前，这些文件必须通过病毒扫描。",
            infected_binaries_info:
              "病毒扫描发现您已上传的文件受到感染。 请删除这些文件。 在对其他用户显示所有文件之前，这些文件必须通过病毒扫描。",
            version: "版本: ",
            fileName: "文件名: ",
          },
        },
        status: {
          content_banned_info:
            "该内容已被管理员屏蔽。目前，除内容所有者外，其他用户无法访问此内容。<a href='https://support.tekla.com/contact-us' target='_blank'>联系支持人员</a>以获取更多信息。",
          content_archived_info:
            "该内容已存档。如果没有编辑权限的用户尝试通过直接链接访问内容，他们将重定向到首页。",
          archived: "已存档",
        },
        modified_by: function (name) {
          return " 由 " + name;
        },
        created_by: function (name) {
          return "创建者 " + name;
        },
        deleteEntity: function (title) {
          return "删除 " + title + "?";
        },
        userComments: "用户评注",
        comment_added: "已成功添加评注",
        comment_updated: "已成功更新评注",
        comment_deleted: "已成功删除评注",
        comment_add_failed: "无法添加评注",
        comment_update_failed: "无法更新评注",
        comment_delete_failed: "无法删除评注",
        comment_delete_confirmation: "删除评注项?",
        name: "姓名",
        details: "详细信息",
        contentOwner: "创建者",
        contentOwnerGroup: "内容所有者组",
        contentGroup: "内容组",
        thumbnail: "缩略图",
        tooltip: "工具提示",
        description: "描述",
        additional_information: "附加信息",
        images: "产品图片",
        installationInfo: "安装信息",
        productCode: "产品代码",
        copyrightInfo: "版权",
        productExpirationDate: "产品到期日期",
        helpUrl: "网站&rsaquo;",
        supportUrl: "支持&rsaquo;",
        specialTermsUrl: "最终用户许可协议 &rsaquo;",
        releaseNotesUrl: "版本说明",
        supportForumUrl: "Discussion Forum &rsaquo;",
        itemTypeCategories: "组",
        useCategories: "类别",
        category: "类别:",
        group: "组:",
        compatibleSoftwareProducts: "兼容软件产品",
        compatibleOperatingSystems: "兼容操作系统",
        locationRestrictions: "产品可用性:",
        supportedLanguages: "语言:",
        measurementUnits: "测量单位:",
        tags: "标签和关键字:",
        prerequisites: "先决条件:",
        releaseNotes: "版本说明:",
        versions: "版本",
        testedVersions: "兼容版本",
        qualityTag: "质量 - 由所有者评估",
        otherAttributes: "其他属性",
        labels: "搜索条件",
        supportInformation: "支持",
        updateInformation: "更新",
        latestUpdate: "最新更新",
        totalDownloads: "总下载数",
        licenses: "许可证",
        maintenanceRequired: "下载需要 Tekla Maintenance",
        tekla_maintenance_content: "Tekla Maintenance 内容",
        download_requires_tekla_maintenance: "下载需要 Tekla Maintenance",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "已成功更新",
        update_failed: "更新失败",
        update_failed_invalid_tag: "更新失败: 标签无效",
        update_failed_details_max_length_exceeded: "更新失败: 详细信息太长",
        show_version_details: "更多信息",
        content_removed: "已删除内容",
        no_versions_available: "无可用版本。 要过一些时间才会显示更新。 刷新浏览器以查看更改。",
        thumbnail_deleted: "已删除缩略图",
        thumbnail_updated: "缩略图已更新",
        verify_service_plugin_is_running: "请检查 Tekla Warehouse Service 插件能否正常运行",
        verify_ts_connection: "请检查 Tekla Structures 是否正在运行",
        download_tos: {
          title: "质量、支持和隐私",
          content_1: "请确保下载的内容符合您的质量标准。 Trimble 不为任何第三方内容提供支持，也不负任何责任。 请直接联系内容提供商以获取支持。",
          content_2: "出于合理正当的目的(例如及时通知您内容有修改)，服务过程中可能会公布您所在国家/地区的名称和组织名称，并将其披露给内容所有者。 您可以取消订阅这些通知。",
          content_3: '对于 Trimble 内容扩展，适用 <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimble 条款</a>。第三方内容可能适用第三方许可条款。请参阅 <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimble 隐私声明</a>。',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "这是免费的 Tekla Structures BIM 存储空间。 可在内部全局查找、输入、安装和共享内容。 高效生成优质模型。",
        },
        landing: {
          title: "Tekla Warehouse",
          description: "Tekla Warehouse 是一个免费的在线和本地库，适用于 Tekla Structures 建模工具、智能零件和模板",
        },
        search: {
          title: "搜索 Tekla Warehouse",
          description: "搜索 Tekla 扩展、3D 零件、形状、自定义组件、型材、钢和混凝土等级等材料、钢筋、钢筋网和模板",
        },
        about: {
          title: "有关 Tekla Warehouse 的信息",
          description: "Tekla Warehouse 可为您的产品提供免费的营销渠道，并让您能够完全掌控内容",
          developers: {
            title: "默认开发者标题",
            description: "默认开发者描述",
          },
          usage: {
            title: "默认使用标题",
            description: "默认使用描述",
          },
          getting_started: {
            title: "默认开始标题",
            description: "默认开始描述",
          },
          content: {
            title: "默认内容标题",
            description: "默认内容描述",
          },
          using_content: {
            title: "默认使用内容标题",
            description: "默认使用内容描述",
          },
          creating_content: {
            title: "默认创建内容标题",
            description: "默认创建内容描述",
          },
        },
        collection: {
          title: "默认集合标题",
          description: "默认集合描述",
        },
        my_warehouse: {
          title: "我的 Tekla Warehouse",
          description: "管理您 Tekla Warehouse 内容",
        },
      },
      upload: {
        actions: {
          cancel: "取消",
          previous: "&lsaquo;上一步",
          next: "下一步&rsaquo;",
        },
        content: {
          add_content: "添加文件",
        },
        collection: {
          heading: "选择或创建集合",
          subHeading:
            "集合组内容。 您可以使用在线集合发布内容，或者使用本地集合与同事共享内容。 您还可以利用本地集合以供个人使用。",
          loading: "正在加载集合...",
          selectFromMyCollections: "从“我的集合”中选择",
          description_of_the_collection: "描述",
          title_validation: {
            missing: "请输入标题。",
            too_short: function (length) {
              return "标题应至少为 " + length + " 个字符。";
            },
            too_long: function (length) {
              return "标题不能长于 " + length + " 个字符。";
            },
          },
        },
        package: {
          basic_information: "基本信息",
          code_name: "代号",
          code_name_info: "输入代号作为此内容的唯一标识符。代号可以包含小写字母、数字和以下特殊字符：_ - @。代号保存后不可更改。",
          code_name_validation: {
            missing: "您必须输入代号",
            wrong_format: "代号可以包含小写字母、数字和以下特殊字符：_ - @ ",
            not_unique: "此代号已被使用。您必须输入唯一的代号。",
          },
          search_criteria: "搜索条件",
          support: "支持",
          title: "标题",
          thumbnail: "缩略图",
          "3D_pic": "3D 模型",
          title_validation: {
            missing: "请输入标题。",
            too_short: function (length) {
              return "标题应至少为 " + length + " 个字符。";
            },
          },
          description_validation: {
            missing: "请输入描述。",
            too_short: function (length) {
              return "描述应至少为 " + length + " 个字符。";
            },
          },
          wrong_file_type: "文件类型错误。",
          selected_image_is_too_big: "所选图像太大。",
        },
        version: {
          select_version: "选择版本",
          select_quality: "选择质量",
          versions: "版本",
          displayNameNew: "版本显示名称",
          copy_from_version: "从现有版本复制元数据(可选)",
          displayName: "显示名称",
          content_information: "内容信息",
          back_to_content_information: "&lsaquo;返回到内容信息",
          version_and_file_management: "版本和文件管理",
          there_s_no_versions_yet: "尚无版本。",
          create_a_version_to_add_files: "创建版本以添加文件。",
          add_version: "添加版本",
          version_information: "版本信息",
          delete_version: "删除版本",
          no_versions_available: "无可用版本。 要过一些时间才会显示更新。",
          update_start_title_html: '<div class="spinner spinner-white">正在更新</div>',
          update_start_message: "这可能需要一点时间。",
          version_was_created: "版本已创建。",
          version_was_created_with_delay: "版本已创建。 更改可能在几分钟后可见。",
          version_was_updated: "版本已更新。",
          version_was_deleted: "版本已删除",
          version_was_deleted_with_delay: "版本已删除。 更改可能在几分钟后可见。",
          confirm_delete: function (title) {
            return "是否删除版本“" + title + "”?";
          },
        },
        publish: {
          package_was_uploaded: "已上传文件包。",
          couldn_t_create_a_package: "无法创建文件包。 更改将回滚。",
          uploading_title_html: '<div class="spinner spinner-white">正在上传</div>',
          uploading_text: "这可能需要一点时间。",
          select_organization: "选择组织",
          collection_visibility: "集合可见性:",
          actions: {
            create: "创建",
            publish: "发布",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "此内容将对所有能够访问本地或网络驱动器的用户可见。",
          this_content_will_be_visible_only_to_you: "此内容仅对您可见。",
          this_content_will_be_visible_only_to_you_and_your_organization: "此内容仅对您和您组织中的所有人可见。",
          this_content_will_be_visible_to_everyone_after_publishing: "此内容在发布后对所有人可见。",
          public_content_disclaimer:
            "您对自己上传的所有内容负责。 您有权上传内容，请确保您的内容安全无害，并且不违反任何知识产权法。 您同意在服务中公布可能适用于您所上传内容的任何使用条款和条件。",
        },
        metadata: {
          title: "元数据",
          subtitle: "基本数据",
          deleteThumbnail: "删除缩略图",
          buttonCreate: "创建",
        },
        basicdata: {
          title: "添加内容",
          clickButtonToSelectFile: "单击按钮以选择文件",
          thumbnail: "缩略图",
          dropImageHere: "在此处放置图片",
        },
        "3dGeometry": {
          title: "创建 3D 产品目录",
          buttonAddEntity: "添加 3D 产品",
          buttonAdd: "添加自定义属性",
          errorAttributeAlreadyExists: "已存在同名属性。",
          buttonCreatePackage: "创建文件包",
          buttonCreatePackages: "创建文件包",
          addCustomAttributePlaceholder: "属性名称",
        },
        please_log_in_fragments: {
          please: "请 ",
          log_in: "登录",
          or: " 或者 ",
          register: "注册",
          to_upload_online_content: " 以上传在线内容。",
        },
        header: {
          upload_header: "上传",
          collection: "集合",
          content: "内容",
          version: "版本",
          finish: "完成",
        },
        contentOwner: "创建者",
        contentOwnerGroup: "发布内容",
        select_action: "选择操作",
        tooltip: "工具提示",
        description: "描述",
        copyright: "版权",
        tags: "标签和关键字",
        tagsSubtitle:
          "使用逗号分隔，例如: 关键字 1, 关键字 2。 如果关键字包含多个词语，请在词语之间使用下划线 (_)，但不允许在词语之间使用空格。 除下划线和逗号之外，不允许使用任何其他特殊字符。",
        images: "图像",
        installationInfo: "安装信息",
        productCode: "产品号",
        copyrightInfo: "版权信息",
        Details: "详细信息",
        specialTermsUrl: "最终用户许可协议",
        productExpirationDate: "产品到期日期",
        helpUrl: "网站",
        helpUrlSubtitle: "插入完整 URL",
        detailsSubtitle:
          "在该阶段，您只能在“详细信息”字段中添加纯文本。 创建内容后，您可以编辑“详细信息”以包含 html 格式。",
        specialTermsUrlSubtitle:
          "内容所有者可以为自己的内容添加其他条款。 所有者需要提供用于定位其特定条款的 URL。 不得与 Trimble 的服务条款冲突。",
        releaseNotes: "发布说明文件",
        releaseNoteFileSubTitle: "将发布说明添加为文件",
        supportUrl: "帮助与支持",
        supportUrlSubtitle: "插入完整 URL 或“mailto:”前缀以发送电子邮件",
        supportForumUrl: "Discussion Forum (网站)",
        supportForumUrlSubtitle: "插入完整 URL",
        measurementUnits: "测量单位",
        itemTypeCategories: "组",
        useCategories: "类别",
        compatibleSoftwareProducts: "兼容软件产品",
        testedVersions: "兼容版本",
        compatibleOperatingSystems: "兼容操作系统",
        locationRestrictions: "产品可用性",
        supportedLanguages: "语言",
        select_releasenotes_file: "选择文件",
        qualityTag: "质量 - 由所有者评估",
        requireMaintenance: "下载需要安装 Tekla Customer Maintenance",
        contentOwnerGroupAsMyself: "作为我自己",
        contentOwnerGroupAsUserGroup: "作为用户组",
        selectUserGroupForContentOwner: "为内容所有者选择用户组",
        defaultUploadTemplate: "默认文件包",
        templateFor3DGeometryFiles: "3D 几何形状文件包",
        title: "标题",
        details: "详细信息",
        selectContentOwner: "选择内容所有者",
        selectContentType: "类型",
        errorWrongfiletype: "文件类型错误。",
        errorImageTooBig: "所选图片太大。",
        errorBlankTitle: "标题不能为空。",
        commonLabelsTitle: "适合所有文件包的通用标签",
      },
      profile: {
        content_usage: "内容使用",
        profile_updated: "个人资料已更新",
        invitation_accepted: function (target) {
          return "已接受邀请! 您现在可以访问  " + target + ".";
        },
        user_groups: {
          edit_user_group: "编辑用户组",
          user_group_updated: "用户组已更新",
          create_a_new_user_group: "创建新用户组",
          name_of_the_user_group: "用户组名称",
          description: "描述",
          group_visibility: "组可见性",
          remove: "“是否确定?”",
          remove_invitations: "“是否确定?”",
          remove_members: "“是否确定?”",
          invitations_removed: "邀请已删除!",
          members_list_updated: "成员列表已更新!",
          user_group_created: "用户组已创建",
        },
        members: {
          update_selected_members: "更新所选成员",
          new_role: "新角色",
          members_list_updated: "成员列表已更新!",
          edit_user_group: "编辑用户组",
          delete_user_group: "删除用户组",
          filter: "过滤",
          user_group_members: "用户组成员",
          update_members: "更新成员",
          remove_members: "删除成员",
          pending_invitations: "待处理的邀请",
          invite_member: "邀请成员",
          update_invitations: "更新邀请",
          remove_invitations: "删除邀请",
        },
        subTabs: {
          alerts: "我的提醒",
          collections: "集合",
          likes: "喜欢",
        },
        admin: {
          grant_sales_admin: "为用户授予销售管理员角色",
          grant_analyst_role: "为用户授予分析师角色",
          role_granted: "已授予角色",
          role_removed: "已删除角色",
          offline_to_online: "将离线内容转换为在线内容",
          use_elastic_search: "使用弹性搜索",
        },
        sales: {
          title: "销售",
        },
        subtitlePreferences: "搜索参数",
        preferenceInfo: '这些过滤器将为您推荐 Tekla Warehouse <a href="#/landing"> 主页</a>上的内容。',
        clearFilters: "清除过滤器&rsaquo;",
        admin_role: "管理员",
        sales_role: "销售",
        admin_mode: "管理员模式:",
        firstName: "名 ",
        lastName: "姓 ",
        displayName: "显示名称:",
        organization: "组织名称:",
        email: "电子邮件:",
        teklaId: "Tekla ID",
        maintenanceAccess: "访问 Tekla Maintenance 内容:",
        myLocations: "项目位置",
        measurementUnits: "单位",
        useCategories: "使用",
        itemTypeCategories: "我对以下类型感兴趣",
        languages: "语言",
        operatingSystems: "操作系统",
        software: "软件产品",
        user_id: "用户 ID:",
        organization_id: "组织 ID:",
        edit_in_atc: "在 Tekla Account 中编辑我的个人资料",
        no_likes: "不喜欢",
        no_alerts: "没有订阅",
        clear_alerts: "清除提醒",
        alerts_info_html:
          "当以下任一项发生改变时，您将收到提醒<br>" +
          "<b>内容:</b> 修改标题、描述、搜索条件或内容项的元数据、添加新版本内容，或删除版本。<br>" +
          "<b>集合:</b> 编辑标题或描述，添加或删除内容。",
      },
      uploader: {
        placeholders: {
          productCode: "产品号",
          title: "名称",
          description: "描述",
          productWeight: "产品重量",
          weightUnit: "重量单位",
          material: "材质",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D 产品目录",
          addToolButton: "应用程序",
          select_files: "磁盘中的文件",
          select_files_from_ts: "Tekla Structures 中的文件",
          partnerDownloadLink: "合作伙伴下载链接",
        },
        headings: {
          geometryFile: "几何形状文件",
          thumbnailFile: "缩略图位图(.bmp)",
          title: "名称",
          description: "描述",
          productCode: "产品号",
          productWeight: "产品重量",
          weightUnit: "重量单位",
          material: "材料",
        },
      },
      constants: {
        countries: {
          GLOBAL: "全球",
          AF: "阿富汗",
          AX: "奥兰德岛",
          AL: "阿尔巴尼亚",
          DZ: "阿尔及利亚",
          AS: "美属萨摩亚",
          AD: "安道尔",
          AO: "安哥拉",
          AI: "安圭拉",
          AQ: "南极洲",
          AG: "安提瓜和巴布达",
          AR: "阿根廷",
          AM: "亚美尼亚",
          AW: "阿鲁巴",
          AU: "澳大利亚",
          AT: "奥地利",
          AZ: "阿塞拜疆",
          BS: "巴哈马",
          BH: "巴林",
          BD: "孟加拉国",
          BB: "巴巴多斯",
          BY: "白俄罗斯",
          BE: "比利时",
          BZ: "伯利兹",
          BJ: "贝宁",
          BM: "百慕大群岛",
          BT: "不丹",
          BO: "玻利维亚",
          BQ: "博内尔岛",
          BA: "波斯尼亚和黑塞哥维那",
          BW: "博茨瓦纳",
          BV: "布韦岛",
          BR: "巴西",
          IO: "英属印度洋领地",
          BN: "文莱达鲁萨兰国",
          BG: "保加利亚",
          BF: "布基纳法索",
          BI: "布隆迪",
          KH: "柬埔寨",
          CM: "喀麦隆",
          CA: "加拿大",
          CV: "佛得角",
          KY: "开曼群岛",
          CF: "中非共和国",
          TD: "乍得",
          CL: "智利",
          CN: "中国",
          CX: "圣诞岛",
          CC: "科科斯(基林)群岛",
          CO: "哥伦比亚",
          KM: "科摩罗",
          CG: "刚果共和国",
          CD: "刚果民主共和国",
          CK: "库克群岛",
          CR: "哥斯达黎加",
          CI: "科特迪瓦",
          HR: "克罗地亚",
          CU: "古巴",
          CW: "库拉索",
          CY: "塞浦路斯",
          CZ: "捷克共和国",
          DK: "丹麦",
          DJ: "吉布提",
          DM: "多米尼加",
          DO: "多米尼加共和国",
          EC: "厄瓜多尔",
          EG: "埃及",
          SV: "萨尔瓦多",
          GQ: "赤道几内亚",
          ER: "厄立特里亚",
          EE: "爱沙尼亚",
          ET: "埃塞俄比亚",
          FK: "马尔维纳斯群岛(福克兰群岛)",
          FO: "法罗群岛",
          FJ: "斐济",
          FI: "芬兰",
          FR: "法国",
          GF: "法属圭亚那",
          PF: "法属波利尼西亚",
          TF: "法属南部领地",
          GA: "加蓬",
          GM: "冈比亚",
          GE: "格鲁吉亚",
          DE: "德国",
          GH: "加纳",
          GI: "直布罗陀",
          GR: "希腊",
          GL: "格陵兰岛",
          GD: "格林纳达",
          GP: "瓜德罗普",
          GU: "关岛",
          GT: "危地马拉",
          GG: "根西岛",
          GN: "几内亚",
          GW: "几内亚比绍",
          GY: "圭亚那",
          HT: "海地",
          HM: "赫德岛和麦克唐纳群岛",
          VA: "梵蒂冈城",
          HN: "洪都拉斯",
          HK: "中国香港特别行政区",
          HU: "匈牙利",
          IS: "冰岛",
          IN: "印度",
          ID: "印度尼西亚",
          IR: "伊朗",
          IQ: "伊拉克",
          IE: "爱尔兰",
          IM: "马恩岛",
          IL: "以色列",
          IT: "意大利",
          JM: "牙买加",
          JP: "日本",
          JE: "泽西岛",
          JO: "约旦",
          KZ: "哈萨克斯坦",
          KE: "肯尼亚",
          KI: "基里巴斯",
          KW: "科威特",
          KG: "吉尔吉斯斯坦",
          LA: "老挝",
          LV: "拉多维亚",
          LB: "黎巴嫩",
          LS: "莱索托",
          LR: "利比里亚",
          LY: "利比亚",
          LI: "列支敦士登",
          LT: "立陶宛",
          LU: "卢森堡",
          MO: "中国澳门特别行政区",
          MK: "马其顿",
          MG: "马达加斯加",
          MW: "马拉维",
          MY: "马来西亚",
          MV: "马尔代夫",
          ML: "马里",
          MT: "马耳他",
          MH: "马绍尔群岛",
          MQ: "马提尼克",
          MR: "毛里塔尼亚",
          MU: "毛里求斯",
          YT: "马约特岛",
          MX: "墨西哥",
          FM: "密克罗尼西亚",
          MD: "摩尔多瓦",
          MC: "摩纳哥",
          MN: "蒙古",
          ME: "黑山",
          MS: "蒙特塞拉特",
          MA: "摩洛哥",
          MZ: "莫桑比克",
          MM: "缅甸",
          NA: "纳米比亚",
          NR: "瑙鲁语",
          NP: "尼泊尔",
          NL: "荷兰",
          NC: "新喀里多尼亚",
          NZ: "新西兰",
          NI: "尼加拉瓜",
          NE: "尼日尔",
          NG: "尼日利亚",
          NU: "纽埃",
          NF: "诺福克岛",
          KP: "朝鲜",
          MP: "北马里亚纳群岛",
          NO: "挪威",
          OM: "阿曼",
          PK: "巴基斯坦",
          PW: "帕劳",
          PS: "巴勒斯坦领土",
          PA: "巴拿马",
          PG: "巴布亚新几内亚",
          PY: "巴拉圭",
          PE: "秘鲁",
          PH: "菲律宾",
          PN: "皮特凯恩",
          PL: "波兰",
          PT: "葡萄牙",
          PR: "波多黎各",
          QA: "卡塔尔",
          RE: "留尼旺",
          RO: "罗马尼亚",
          RU: "俄罗斯联邦",
          RW: "卢旺达",
          BL: "圣巴特岛",
          SH: "圣海伦娜",
          KN: "圣基茨和尼维斯",
          LC: "圣卢西亚",
          MF: "圣马丁",
          PM: "圣皮埃尔和密克隆",
          VC: "圣文森特和格林纳丁斯",
          WS: "萨摩亚",
          SM: "圣马力诺",
          ST: "圣多美和普林西比",
          SA: "沙特阿拉伯",
          SN: "塞内加尔",
          RS: "塞尔维亚",
          SC: "塞舌尔",
          SL: "塞拉利昂",
          SG: "新加坡",
          SX: "荷属圣马丁",
          SK: "斯洛伐克",
          SI: "斯洛文尼亚",
          SB: "所罗门群岛",
          SO: "索马里",
          ZA: "南非",
          GS: "南乔治亚岛",
          KR: "韩国",
          SS: "南苏丹",
          ES: "西班牙",
          LK: "斯里兰卡",
          SD: "苏丹",
          SR: "苏里南",
          SJ: "斯瓦尔巴和扬马延",
          SZ: "斯威士兰",
          SE: "瑞典",
          CH: "瑞士",
          SY: "阿拉伯叙利亚共和国",
          TW: "中国台湾",
          TJ: "塔吉克斯坦",
          TZ: "坦桑尼亚",
          TH: "泰国",
          TL: "东帝汶",
          TG: "多哥",
          TK: "托克劳",
          TO: "汤加",
          TT: "特立尼达和多巴哥",
          TN: "突尼斯",
          TR: "土耳其",
          TM: "土库曼尼斯坦",
          TC: "特克斯和凯科斯群岛",
          TV: "图瓦卢",
          UG: "乌干达",
          UA: "乌克兰",
          AE: "阿拉伯联合酋长国",
          GB: "英国",
          US: "美国",
          UM: "美属麦诺奥特兰群岛",
          UY: "乌拉圭",
          UZ: "乌兹别克斯坦",
          VU: "瓦努阿图",
          VE: "委内瑞拉",
          VN: "越南",
          VG: "英属维尔京群岛",
          VI: "美属维尔京群岛",
          WF: "瓦利斯群岛和富图纳群岛",
          EH: "西撒哈拉",
          YE: "也门",
          ZM: "赞比亚",
          ZW: "津巴布韦",
        },
        sortOptions: {
          "-modifyTime": "最后更新日期",
          "-modifiedAt": "最后更新日期",
          title: "字母顺序 A-Z",
          "-title": "字母顺序 Z-A",
          "-reviewCount": "最喜欢",
          name: "姓名",
          role: "角色",
          email: "电子邮件",
          "-relevance": "相关性",
          "-createdAt": "最近创建时间",
          displayName: "字母顺序 A-Z",
          "-displayName": "字母顺序 Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "今天",
          yesterday: "昨天",
          this_week: "本周",
          previous_week: "上周",
          this_month: "本月",
          previous_month: "上月",
          this_year: "今年",
          previous_year: "上年",
          beginning_of_time: "开始时间",
          custom: "自定义时间范围",
        },
        eventTypeOptions: {
          downloads: "下载",
          views: "查看",
          all_events: "所有事件",
        },
      },
    });
  },
};
