import { observable, runInAction, makeObservable } from "mobx";
import { IBinary, IItem, IResource } from "../../models/dataModel";
import { RootStore, createStoreContext } from "../../stores/rootStore";
import { BinaryStore } from "../../stores/binaryStore";
import { NewRepository } from "../../js/services/NewRepository";

/**
 * Subscribe store.
 */
export class ThumbnailStore extends BinaryStore {
  /**
   * Content item.
   */
  @observable private content: IItem | undefined;

  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore, content?: IItem) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    if (content) {
      runInAction(() => {
        this.content = content;
        this.parseBinaries(content);
      });
    }
  }

  /**
   * Resolve if content can be edited
   * @params binary object that should be inspected
   * @returns true if content can be edited
   */
  public canEditContent(): boolean {
    return this.content ? this.rootStore.getUserStore().canEditResource(this.content as IResource) : false;
  }

  /**
   * Gets binaries
   * @returns binaries array
   */
  public getBinaries(): IBinary[] {
    return this.binaries;
  }

  /**
   * Gets content item
   * @returns content item object
   */
  public getContent(): IItem | undefined {
    return this.content ? this.content : undefined;
  }

  /**
   * Reloads content from NewRepository
   */
  public async reloadContent() {
    if (this.content) {
      try {
        const content = (await NewRepository.getCollection(
          {
            id: this.content.id,
          },
          this.rootStore.getUserStore().getCurrentUser()?.id || "",
        )) as unknown as IItem;

        runInAction(() => {
          this.content = content;
          this.parseBinaries(content);
        });
      } catch {
        console.log("Failed to reload content.");
      }
    }
  }
}

export const ThumbnailStoreContext = createStoreContext<ThumbnailStore>(ThumbnailStore);
