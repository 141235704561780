import { action, observable, runInAction, makeObservable } from "mobx";
import { NewRepository } from "../../js/services/NewRepository";

import { createStoreContext, RootStore } from "../../stores/rootStore";

import { ICollection } from "../../models/dataModel";
import { VisibilityEnum } from "../../models/enums";

/**
 * Access management store
 */
export class AccessManagementStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;
  /**
   * Indicator if collection is being updated
   */
  @observable private updating = false;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Resolves if given collection is public
   * @returns true if collection is publick
   */
  public isPublicCollection(collection?: ICollection) {
    return collection && collection.visibility === VisibilityEnum.PUBLIC;
  }

  /**
   * Gets the collection update status
   * @returns true if currently updating
   */
  public isUpdating(): boolean {
    return this.updating;
  }

  /**
   * Updates collection visibility
   * @param collection collection to be updated
   * @param visibility visibility to be set to given collection
   */
  @action
  public async updateVisibility(collection: ICollection, visibility: VisibilityEnum) {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user && !!collection && collection.visibility !== visibility) {
      try {
        this.updating = true;
        const tmpCollection = this.copyCollection(collection, visibility);
        await NewRepository.updateCollectionVisibilityRecursively(tmpCollection, user);

        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("collections.notification.collection_updated"));

        runInAction(() => {
          collection.visibility = visibility;
          this.updating = false;
        });
      } catch (err) {
        runInAction(() => {
          this.updating = false;
        });
      }
    }
  }

  /**
   * Copies needed data from source collection
   * @params collection object to be copied
   * @returns new object with copied data
   */
  private copyCollection(collection: ICollection, visibility: VisibilityEnum) {
    return {
      id: collection.id,
      isHidden: collection.isHidden,
      visibility: visibility,
      linkedResourcesCollection: collection.linkedResourcesCollection,
    };
  }
}

export const AccessManagementStoreContext = createStoreContext<AccessManagementStore>(AccessManagementStore);
