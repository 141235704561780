import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";
import _ from "underscore";

import { SearchPageSettingsStore } from "./searchPageSettingsStore";

import { IDropdownOption, IItem } from "../models/dataModel";
import { SearchTargetOptionEnum } from "../models/enums";

import { sortArray } from "../utils/functions";

/**
 * Store for handling search functionality.
 */
export class SearchStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;
  /**
   * Search Page View State Store instance, for handles global search page settings (ie. list style).
   */
  @observable private searchPageSettingsStore: SearchPageSettingsStore;

  /**
   * Category type filter
   */
  @observable protected itemCategoryFilter?: IDropdownOption;
  /**
   * The current search target (content items/collections)
   */
  @observable protected searchTarget = SearchTargetOptionEnum.PACKAGE;
  /**
   * String typed to search field
   */
  @observable protected searchTerm = "";

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.searchPageSettingsStore = new SearchPageSettingsStore();
  }

  /**
   * Filters a list of items according to a search term.
   * @param items list of items to be filtered
   * @returns filtered list
   */
  public filterResults(items: IItem[]): IItem[] {
    return items.filter((item: IItem) => {
      const searchableString = !!item.title ? item.title.toLowerCase() : "";
      return searchableString.includes(this.searchTerm.toLowerCase()) && this.filterWithItemTypeCategory(item);
    });
  }

  /**
   * Returns category filter
   * @returns category filter or undefined if not set
   */
  public getItemTypeCategory(): IDropdownOption | undefined {
    return this.itemCategoryFilter;
  }

  /**
   * Gets the view state of the search page (see search_page_view_state_factory.js).
   */
  public getSearchPageSettingsStore() {
    return this.searchPageSettingsStore;
  }

  /**
   * Gets the current search target.
   */
  public getSearchTarget() {
    return this.searchTarget;
  }

  /**
   * Gets the current search target.
   */
  public getSearchTerm() {
    return this.searchTerm;
  }

  /**
   * Resets the search term.
   */
  @action
  public resetSearchTerm() {
    this.searchTerm = "";
  }

  /**
   * Method for setting item type category value
   * @param itemTypeCategory selected item category type value
   */
  @action
  public async setItemTypeCategory(itemTypeCategory: IDropdownOption) {
    this.itemCategoryFilter = itemTypeCategory;
  }

  /**
   * Sets a search target.
   * @param to SearchTargetOptionEnum
   */
  @action
  public setSearchTarget(to: SearchTargetOptionEnum) {
    this.searchTarget = to;
  }

  /**
   * Sets a search term.
   * @param term the search term typed by the user
   */
  @action
  public setSearchTerm(term: string) {
    this.searchTerm = term;
  }

  /**
   * Sorts a list of items according to the sorting rule.
   * @param items list of items to be sorted
   * @returns the sorted list
   */
  public sortResults(items: IItem[]): IItem[] {
    const sorted = items;
    const rule = this.getSearchPageSettingsStore().resultListOrder;
    if (!!rule) {
      return sortArray(sorted, rule);
    } else {
      return sorted;
    }
  }

  private filterWithItemTypeCategory(item: IItem): boolean {
    if (this.itemCategoryFilter && this.itemCategoryFilter.value !== "all") {
      return _.contains(item.attributes!.itemTypeCategories!, this.itemCategoryFilter.value);
    } else {
      return true;
    }
  }
}
