import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UserStore } from "../../stores/userStore";
import { LikeStore, LikeStoreContext } from "./likeStore";
import { Spinner } from "../../components/Spinner";

/**
 * A component that displays like functionality
 */
export const Like = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const userStore: UserStore = rootStore.getUserStore();
  const likeStore: LikeStore = useContext(LikeStoreContext);

  function getReviewCount() {
    const content = likeStore.getContent();
    return !!content ? content.reviewCount : 0;
  }

  return (
    <Fragment>
      {userStore.getCurrentUser() && (
        <li className="action-item likes">
          {likeStore.isLoading() ? (
            <Spinner loadingWhat="likedStatus" />
          ) : (
            <a
              data-testid="like-unlike"
              className={classNames("icon", {
                "icon-liked yellow": likeStore.hasLikedContent(),
                "icon-like": !likeStore.hasLikedContent(),
              })}
              onClick={() => likeStore.likeOrUnlikeContent()}
            ></a>
          )}
          <span className="count">{getReviewCount()}</span>
        </li>
      )}
    </Fragment>
  );
});
