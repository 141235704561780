import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";

import { RootContext, RootStore } from "../../stores/rootStore";
import { IGetResourcePathContext } from "../../search/searchPageStore";
import { RecommendedEntitiesContext, RecommendedEntitiesStore } from "./recommendedEntitiesStore";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import { EntityWithCart } from "../../components/EntityWithCart";
import { IEntity } from "../../models/dataModel";

/**
 * A component that displays list of the recommended packages.
 */
export const RecommendedEntities = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: RecommendedEntitiesStore = useContext(RecommendedEntitiesContext);

  function shouldDisplay(): boolean {
    return store.wasDataFetched() && store.getEntities().length > 0;
  }

  function getRecommendedLink(): string {
    const lang = rootStore.getLangKeyBySelectedLocale();
    const query = new URLSearchParams({
      languages: lang,
      sortBy: "recommended",
      showContentView: "true",
    }).toString();

    return "/search?" + query;
  }

  return (
    <Fragment>
      {shouldDisplay() && (
        <section className="content">
          <div className="content-area">
            <div className="title">
              <Link className="title-link" to={getRecommendedLink()}>
                <TranslatedHtml entry="landing.content.recommended" />
              </Link>
            </div>
            <div className="content-items-section" data-testid="entities-list">
              {store.getEntities().map((entity: IEntity, i: number) => {
                return (
                  <div className="content-items" key={i}>
                    <IGetResourcePathContext.Provider value={store}>
                      <EntityWithCart entity={entity} listStyle="thumbnails" />
                    </IGetResourcePathContext.Provider>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
});
