import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../../stores/rootStore";
import { ReleaseNotesStore, ReleaseNotesStoreContext } from "../tabs/releaseNotesStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { Dialog } from "../../dialogs/Dialog";

const DialogContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ReleaseNotesStore = useContext(ReleaseNotesStoreContext);
  const dialog: DialogStore = useContext(DialogContext);

  function closeDialog() {
    dialog.close();
  }

  function shouldShowRemoveLink() {
    return store.getFileName() != "";
  }

  function fileSelected(data) {
    if (data && data.target && data.target.files[0]) {
      store.setSelectedFile(data.target.files[0]);
      data.target.value = null;
    }
  }

  function save() {
    store.save();
    dialog.close();
  }

  return (
    <article>
      <header>
        <h2 className="l-center-align">{rootStore.getTranslation("shared.catalog_entity_edit.edit_release_notes")}</h2>
      </header>
      <section>
        <form>
          <ol className="support-data">
            <li>
              <label htmlFor="releaseNoteFile">{rootStore.getTranslation("upload.releaseNotes")}</label>
              <input
                type="text"
                id="releaseNoteFile"
                data-testid="release-note-file-name"
                className="release-note-file"
                readOnly
                value={store.getFileName()}
              ></input>
              {shouldShowRemoveLink() && (
                <a
                  data-testid="remove-releasenote-file"
                  onClick={() => store.removeFileSelection()}
                  className="icon icon-trash"
                ></a>
              )}
              <label
                className="button release-note-button"
                htmlFor="chooseReleaseNoteFile"
                data-testid="choose-release-note-file"
              >
                {rootStore.getTranslation("upload.select_releasenotes_file")}
                <input
                  data-testid="choose-releasenote-file"
                  id="chooseReleaseNoteFile"
                  className="hidden-file-upload"
                  type="file"
                  accept=".doc, .docx, .txt, .rtf, .pdf"
                  onChange={fileSelected}
                  required
                />
              </label>
            </li>
          </ol>
        </form>
      </section>
      <div className="actions">
        <button onClick={() => closeDialog()}>{rootStore.getTranslation("actions.cancel")}</button>
        <button data-testid="save-data" disabled={!store.wasFileUpdated()} onClick={() => save()}>
          {rootStore.getTranslation("actions.save")}
        </button>
      </div>
    </article>
  );
});

/**
 * Renders the editor dialog & button for ReleaseNotes in package page
 */
export const EditReleaseNotes = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);

  function openDialog() {
    dialog.open();
  }

  return (
    <Fragment>
      <div className="edit-release-notes">
        <a
          data-testid="edit-release-note"
          className="icon icon-pencil"
          title={rootStore.getTranslation("details.edit.tooltips.release_notes")}
          onClick={() => openDialog()}
        >
          <span>{rootStore.getTranslation("details.edit.tooltips.release_notes")}</span>
        </a>
      </div>
      <Dialog content={<DialogContent />} additionalClass="release-notes" />
    </Fragment>
  );
});
