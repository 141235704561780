import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react";
import Clipboard from "react-clipboard.js";
import _ from "underscore";

import { RootStore, RootContext } from "../../stores/rootStore";
import { Dropdown } from "../../components/Dropdown";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import { DialogContext, DialogStore } from "../dialogStore";
import { DownloadDialogStore, DownloadDialogContext } from "./downloadDialogStore";
import { IClient, IDropdownOption, IEntity } from "../../models/dataModel";
import { Dialog } from "../Dialog";
import { TOSDialog } from "../TOSDialog";
import { Settings } from "../../config/Settings";

/**
 * Component that renders the options to copy the download url to clipboard.
 */
const CopyDataToClipboard = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: DownloadDialogStore = useContext(DownloadDialogContext);

  const [showData, setShowData] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");

  useEffect(() => {
    async function resolveUrl() {
      setDownloadUrl(await store.getDownloadUrlsForApp());
    }
    resolveUrl();
  }, []);

  function changeShowData() {
    setShowData(!showData);
  }

  function onTextClick(event) {
    event.target.select();
  }

  return (
    <div className="step">
      <div className="step-numbering step-one" data-testid="download-step-one" />
      <div className="step-text">
        <label>
          {rootStore.getTranslation("download.download_instructions.step_one")}
          {downloadUrl && (
            <Clipboard
              button-className="buttom-primary"
              button-id="copyToClipBoard"
              data-clipboard-text={downloadUrl}
              data-testid="download-copy-to-clipboard"
            >
              {rootStore.getTranslation("download.copy_to_clipboard")}
            </Clipboard>
          )}
        </label>
      </div>
      <label className="copy-data">
        <a onClick={changeShowData} data-testid="download-change-show-data">
          {showData
            ? rootStore.getTranslation("download.download_instructions.hide_data_to_be_copied")
            : rootStore.getTranslation("download.download_instructions.show_data_to_be_copied")}
        </a>
      </label>
      {showData && (
        <div className="copy-data">
          <input
            className="copy-data-text"
            type="text"
            value={downloadUrl}
            readOnly
            onClick={onTextClick}
            data-testid="download-show-url"
          />
        </div>
      )}
    </div>
  );
});

/**
 * Component that renders the main content of the download dialog.
 */
export const DownloadDialogContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);
  const store: DownloadDialogStore = useContext(DownloadDialogContext);
  const clients: IClient[] = rootStore.getLocalServiceStore().getDownloadCapableClients();

  function handleCloseDialog() {
    dialog.close();
  }

  async function download() {
    store.downloadContent(store.getSelectedClient());
  }

  function selectTSVersion(option: IDropdownOption | null) {
    if (!option) return;

    const selectedClient = _.find(clients, (client) => {
      return client.name === option.value;
    });
    if (selectedClient) {
      store.setSelectedClient(selectedClient);
    }
  }

  function getClientOptions(): IDropdownOption[] {
    const clientOptions: IDropdownOption[] = [];
    _.map(clients, (client) => {
      clientOptions.push({ label: client.name, value: client.name });
    });
    return clientOptions;
  }

  return (
    <article>
      <header>
        <h2 className="l-center-align" data-testid="download-title">
          {store.getSelectionInfo()}
        </h2>
      </header>
      <section className="labels">
        <ol className="basic-data">
          <div className="download-install">
            <div className="install-information" data-testid="download-information">
              {clients.length > 1 && (
                <div className="select-ts-version" data-testid="select-ts-version">
                  <Dropdown
                    options={getClientOptions()}
                    onChange={selectTSVersion}
                    placeholder={rootStore.getTranslation("install.select_ts_version")}
                    className="dropdown-wrapper gray"
                    inputId="selectTSVersion"
                  />
                </div>
              )}
              <label className="install-title" data-testid="download-subtitle">
                {store.getDownladableCount()} {rootStore.getTranslation("download.count_info")}
              </label>
              <button onClick={download} disabled={store.disableDownload()} data-testid="download-start">
                {rootStore.getTranslation("download.download_selected")}
              </button>
              {store.hasDownloadStarted() && (
                <div className="install-progress" data-testid="download-in-progress">
                  <label className="install-started">{rootStore.getTranslation("download.download_started")}</label>
                  <label className="install-title">{rootStore.getTranslation("install.install_retry")}</label>
                </div>
              )}
            </div>
          </div>
        </ol>
      </section>
      <div className="actions">
        <button onClick={handleCloseDialog} data-testid="install-close">
          {rootStore.getTranslation("actions.close")}
        </button>
      </div>
    </article>
  );
});

/**
 * Component that renders the main content of the download app dialog.
 */
export const DownloadAppDialogContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog = useContext(DialogContext);

  function getStepTwoText() {
    return <TranslatedHtml entry={"download.download_instructions.step_two_func"} args={[Settings.downloaderAppUrl]} />;
  }

  function handleCloseDialog() {
    dialog.close();
  }

  return (
    <article>
      <header>
        <h2 className="l-center-align" data-testid="download-title">
          {rootStore.getTranslation("download.download_instructions.title")}
        </h2>
      </header>
      <section className="labels">
        <ol className="basic-data">
          <div className="download-install">
            <div className="steps">
              <CopyDataToClipboard />
              <div className="step">
                <div className="step-numbering step-two" />
                <div className="step-text">
                  <label data-testid="download-step-two">{getStepTwoText()}</label>
                </div>
              </div>
              <div className="step">
                <div className="step-numbering step-three" />
                <div className="step-text">
                  <label data-testid="download-step-three">
                    {rootStore.getTranslation("download.download_instructions.step_three")}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ol>
      </section>
      <div className="actions">
        <button onClick={handleCloseDialog} data-testid="download-close">
          {rootStore.getTranslation("actions.close")}
        </button>
      </div>
    </article>
  );
});

/**
 * Component that renders a download button and the dialog that opens when the button is clicked.
 */
export const Download = observer(
  ({ active, entities, versionFilter }: { active: boolean; entities: IEntity[]; versionFilter?: IDropdownOption }) => {
    const rootStore: RootStore = useContext(RootContext);
    const dialog: DialogStore = useContext(DialogContext);
    const store: DownloadDialogStore = new DownloadDialogStore(rootStore);
    const clients: IClient[] = rootStore.getLocalServiceStore().getDownloadCapableClients();
    const TOSdialog: DialogStore = new DialogStore();
    const userStore = rootStore.getUserStore();

    useEffect(() => {
      store.setDownloadInformation(entities, versionFilter);
    });

    function handleDownload() {
      if (userStore.isUserLoggedIn() && !userStore.hasSeenTOS()) {
        TOSdialog.open();
      } else {
        downloadContent();
      }
    }

    function downloadContent() {
      if (clients.length === 1) {
        store.downloadContent(clients[0]);
      } else {
        store.init();
        dialog.open();
      }
    }

    function continueToDownload(): void {
      TOSdialog.close();
      downloadContent();
    }

    return (
      <DownloadDialogContext.Provider value={store}>
        <button className="download" disabled={!active} onClick={handleDownload} data-testid="download-button">
          <span className="icon icon-download" />
          <TranslatedHtml entry="download.download_selected" />
        </button>
        {clients.length > 1 ? (
          <Dialog content={<DownloadDialogContent />} additionalClass="select-client" />
        ) : (
          <Dialog content={<DownloadAppDialogContent />} />
        )}
        <DialogContext.Provider value={TOSdialog}>
          <TOSDialog confirmAction={continueToDownload} />
        </DialogContext.Provider>
      </DownloadDialogContext.Provider>
    );
  },
);
