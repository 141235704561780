import * as React from "react";
import { useContext, Fragment } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";

import { Dropdown } from "../../components/Dropdown";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import { IDropdownOption } from "../../models/dataModel";

import { UploadFormStore, UploadFormContext } from "../uploadFormStore";
import { CreateNewOnlineCollection } from "./CreateNewOnlineCollection";
import { CreateNewLocalCollection } from "./CreateNewLocalCollection";
import { LoginOrRegister } from "../components/LoginOrRegister";
import { CollectionFormUploadMethod } from "./uploadCollectionStore";

/**
 * Component for rendering the "Select or Create collection" view.
 */
export const UploadCollection = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const collection = form.getCollectionStore();

  const localServiceNeeded =
    rootStore.getUserStore().isUserLoggedIn() && !rootStore.getLocalServiceStore().isLocalServiceAccessible();
  const userNotLoggedIn =
    !rootStore.getUserStore().isUserLoggedIn() && rootStore.getLocalServiceStore().isLocalServiceAccessible();

  function handleSelectOption(option: IDropdownOption | null) {
    if (!option) return;

    collection.setFormStateFromOption(option);
    form.setCollectionTitle(collection.getTitle()!);
  }

  return (
    <Fragment>
      <header className="step-main" data-testid="collectionHeader">
        <h2>{rootStore.getTranslation("upload.collection.heading")}</h2>
        <h3>{rootStore.getTranslation("upload.collection.subHeading")}</h3>
      </header>
      <div className="wrapper">
        {form.isLoading() ? (
          <div className="spinner" data-testid="loadingCollection">
            {rootStore.getTranslation("upload.collection.loading")}
          </div>
        ) : (
          <Fragment>
            <label htmlFor="selectCollection">{rootStore.getTranslation("upload.select_action")}</label>
            <Dropdown
              options={collection.collectionOptions}
              placeholder={rootStore.getTranslation("placeholders.choose")}
              selectedValue={collection.getSelectedCollectionOption()}
              onChange={handleSelectOption}
              className={"dropdown-wrapper gray"}
            />
          </Fragment>
        )}
      </div>
      <hr />
      {collection.getUploadMethod() === CollectionFormUploadMethod.CREATE_NEW_ONLINE_COLLECTION && (
        <CreateNewOnlineCollection />
      )}
      {collection.getUploadMethod() === CollectionFormUploadMethod.CREATE_NEW_LOCAL_COLLECTION && (
        <CreateNewLocalCollection />
      )}
      {localServiceNeeded && (
        <p data-testid="localServiceNeeded">
          <TranslatedHtml entry="shared.local_service.needed_to_create_content_html" />
        </p>
      )}
      {userNotLoggedIn && (
        <p>
          <LoginOrRegister />
        </p>
      )}
    </Fragment>
  );
});
