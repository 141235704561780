import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";

import { RootContext } from "../../stores/rootStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { RelatedContentStoreContext } from "./relatedContentStore";
import { PackagePageStoreContext } from "../packagePageStore";

import { IEntity } from "../../models/dataModel";
import { SearchPageListStyleEnum } from "../../models/enums";

import { EntityListItem } from "../../components/EntityListItem";
import { EditRelatedContent } from "../dialogs/EditRelatedContent";

const RelatedContentList = observer(() => {
  const rootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const store = useContext(RelatedContentStoreContext);

  function canEdit(): boolean {
    return packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function getTestId(packageItem: IEntity) {
    return "related-list-" + packageItem.id;
  }

  function getRemoveButtonTestId(packageItem: IEntity) {
    return "remove-related-content-" + packageItem.id;
  }

  return (
    <section className="results-container as-thumbnails">
      <div className="listing-wrapper">
        <div className="results item-grid">
          {store.getRelatedContent().map((packageItem: IEntity, i) => {
            return (
              <div key={i} data-testid={getTestId(packageItem)} className="result">
                <EntityListItem entity={packageItem} listStyle={SearchPageListStyleEnum.THUMBNAILS} />
                {canEdit() && (
                  <button
                    data-testid={getRemoveButtonTestId(packageItem)}
                    className="button-icon button-red remove-related-content"
                    onClick={() => store.removeRelatedContent(packageItem)}
                  >
                    <span className="icon icon-trash" />
                    <span>{rootStore.getTranslation("details.related_content.remove_related_content")}</span>
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
});

const OtherContentList = observer(() => {
  const store = useContext(RelatedContentStoreContext);

  function getTestId(packageItem: IEntity) {
    return "other-list-" + packageItem.id;
  }

  return (
    <section className="results-container as-thumbnails">
      <div className="listing-wrapper">
        <div className="results item-grid">
          {store.getOtherSimilarContent().map((packageItem: IEntity, i) => {
            return (
              <div key={i} data-testid={getTestId(packageItem)} className="result">
                <EntityListItem entity={packageItem} listStyle={SearchPageListStyleEnum.THUMBNAILS} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
});

/**
 * A component that provides related content functionalities
 */
export const RelatedContent = observer(() => {
  const rootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const store = useContext(RelatedContentStoreContext);

  function canEdit(): boolean {
    return packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function relatedContentExists() {
    return store.getRelatedContent().length > 0;
  }

  function otherContentExists() {
    return store.getOtherSimilarContent().length > 0;
  }

  function getPackageCreator(): string {
    return !!packageItem && !!packageItem.creator ? packageItem.creator.displayName : "";
  }

  return (
    <Fragment>
      {!store.isLoading() && (
        <div className="recommended">
          <section className="related">
            {relatedContentExists() && !!getPackageCreator() && (
              <h4 className="related-title">
                {rootStore.getTranslation("details.related_content.related_content_title")} {getPackageCreator()}
              </h4>
            )}
            {canEdit() && (
              <DialogContext.Provider value={new DialogStore()}>
                <EditRelatedContent />
              </DialogContext.Provider>
            )}
            <RelatedContentList />
          </section>
          {otherContentExists() && (
            <section className="other-content">
              <h4 className="other-content-title">
                {rootStore.getTranslation("details.related_content.other_content_title")}
              </h4>
              <OtherContentList />
            </section>
          )}
        </div>
      )}
    </Fragment>
  );
});
