import _ from "underscore";
import { countries } from "../../constants/CountriesConstants";
import { ICountry } from "../../models/dataModel";

/**
 * Country code converter helper class
 */
export const CountryCodeConverter = {
  /**
   * Resolves country name by country code
   * @params countryCode the code to be used to search contry
   */
  getCountryNameByCode: function (countryCode: string): string {
    const country: ICountry | undefined = _.find(countries, (country: ICountry) => {
      return countryCode === country.ccode;
    });
    return !!country ? country.cname : "";
  },
};
