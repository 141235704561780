import _ from "underscore";
import { LocalGeneralService } from "../services/LocalGeneralService";
import { Settings } from "../../config/Settings";

var data = { response: {}, calls: 0 };
var pollDelay = 3000;
var oldData = undefined;
var cb;
var pollerStarted = false;

function updateData(r) {
  data.response = r;
  data.calls++;
  data.isAccessible = true;
  data.clients = filterValidClients(r.clients);
  data.isTsAccessible = data.clients && data.clients.length > 0;
  data.culture = r.culture;
  data.currentUser = r.currentUser;
  data.serviceVersion = r.serviceVersion;
  data.validServiceVersion = checkServiceVersionValidity(r.serviceVersion);
}

function filterValidClients(clients) {
  return _.filter(clients, function (client) {
    return client.isModelOpen && client.name != "Fake Tekla Structures 99.0";
  });
}

function checkServiceVersionValidity(serviceVersion) {
  var validServiceVersion = Settings.local.validServiceVersions.split(".");
  var currentServiceVersion = serviceVersion.split(".");

  if (parseInt(currentServiceVersion[0]) < parseInt(validServiceVersion[0])) {
    return false;
  }
  if (parseInt(currentServiceVersion[1]) < parseInt(validServiceVersion[1])) {
    return false;
  }
  return true;
}

function notifyOnUpdate() {
  if (!oldData || oldData.isAccessible !== data.isAccessible || oldData.clients.length !== data.clients.length) {
    cb(data);
  }
}

function updateDataOnErr(err) {
  data.response = err;
  data.calls++;
  data.culture = null;
  data.currentUser = null;
  data.serviceVersion = null;
  data.isAccessible = false;
  data.isTsAccessible = false;
  data.clients = [];
}

function startPoller() {
  LocalGeneralService.getLocalInfo().then(
    function (r) {
      updateData(r);
      notifyOnUpdate();
      oldData = _.clone(data);
      if (!!r) {
        pollDelay = 10000;
      } else {
        pollDelay = 3000;
      }
      setTimeout(startPoller, pollDelay);
    },
    function (err) {
      updateDataOnErr(err);
      notifyOnUpdate();
      oldData = _.clone(data);
      pollDelay = 3000;
      setTimeout(startPoller, pollDelay);
    },
  );
}

export const LocalServicePoller = {
  init: function (callback) {
    cb = callback;
    if (!pollerStarted) {
      pollerStarted = true;
      setTimeout(startPoller, 500);
    }
  },
};
