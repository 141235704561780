import { Settings } from "../../config/Settings";
import _ from "underscore";

let currentUser = null;
setOrLoadUser();

function loadUser() {
  return JSON.parse(localStorage.getItem(Settings.auth.currentUserLocalStorageName));
}

function getCurrentUser() {
  return currentUser;
}

function emptyLocalStorages() {
  localStorage.clear();
}

function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function setOrLoadUser(userData, callBack) {
  var user = _.isUndefined(userData) ? loadUser() : userData;
  if (_.isObject(user)) {
    currentUser = _.omit(user, "personalInfo", "roles");
  } else {
    currentUser = user;
  }
  if (callBack) {
    callBack();
  }
}

export const UserSession = {
  setOrLoadUser: setOrLoadUser,
  getCurrentUser: getCurrentUser,
  removeCurrentUser: function (callBack) {
    emptyLocalStorages();
    currentUser = null;
    deleteCookie(Settings.defaultSearchTypeCookieName);
    if (callBack) {
      callBack();
    }
  },
};
