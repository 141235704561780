import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";
import { SearchDataConverter } from "../converters/SearchDataConverter";

export const TCCItemService = {
  searchItems: function (data, dontUnwrap) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/items", SearchDataConverter.addParameters(data)),
      false,
      dontUnwrap,
    );
  },
};
