import * as React from "react";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { ISearchStore } from "../../models/dataModel";
import { useContext } from "react";

/**
 * A component that renders the search field.
 * @param rootStore rootstore for accessing translations and searchStore
 * @param pageWhereUsed page where the search field is used
 */
export const SearchField = observer(
  ({ pageWhereUsed, searchStore }: { pageWhereUsed?: string; searchStore?: ISearchStore }) => {
    const rootStore = useContext(RootContext);
    const store = searchStore ? searchStore : rootStore.getSearchStore();

    const setQueryString = (event) => {
      event.preventDefault();
      store.setSearchTerm(event.target.value || "");
    };

    const pageSpecificClass = pageWhereUsed ? `${pageWhereUsed}` + "-search" : "";

    return (
      <section className={"search " + `${pageSpecificClass}`}>
        <input
          className="search"
          data-testid="search"
          type="search"
          onChange={setQueryString}
          placeholder={rootStore.getTranslation("placeholders.search")}
          autoComplete="off"
        />
      </section>
    );
  },
);
