import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";
import { PackagePageStoreContext, PackagePageStore } from "./packagePageStore";

import { PackagePage } from "./PackagePage";

/**
 * A component that displays package page
 *
 * @param isLocal is the package local
 */
export const PackagePageWrapper = observer(({ islocal }: { islocal: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore = new PackagePageStore(rootStore);

  return (
    <PackagePageStoreContext.Provider value={packagePageStore}>
      <PackagePage isLocal={islocal} />
    </PackagePageStoreContext.Provider>
  );
});
