
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Pl = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["pl-PL"]) {
      window.locale["pl-PL"] = {};
    }

    _.extend(window.locale["pl-PL"], {
      myDetails: {
        my_roles: "Moje role",
        my_roles_link: "Role i uprawnienia dostępu do zawartości Tekla Warehouse",
        my_roles_link_url: "https://support.tekla.com/pl/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Role",
        roles: {
          member: "Edytor zawartości Tekla Warehouse (Pracownik)",
          employee: "Pracownik",
          contentEditor: "Edytor zawartości Tekla Warehouse (Zewnętrzne)",
        },
      },
      immutability: {
        add_code_name_for_package: "Dodaj nazwę kodową dla zawartości",
        code_name: "Nazwa kodowa",
        immutable: "Niezmienny",
        archive: "Archiwizuj",
        archive_collection: "Archiwizuj kolekcję i całą jej zawartość",
        archive_version: "Archiwizuj wybraną wersję",
        archive_package: "Archiwizuj pakiet i całą jego zawartość",
        archive_dialog_text: "Zarchiwizowanej zawartości nie można wyświetlać ani wyszukiwać w Tekla Warehouse. Jeśli jednak zawartość była wcześniej używana z usługą chmury Tekla Structures, usługa chmury może nadal uzyskiwać do niej dostęp.",
        archive_success: function (name: string) {
          return `Pomyślnie zarchiwizowano ${name}`;
        },
        archive_failure: function (name: string) {
          return `Archiwizacja ${name} nie powiodła się`;
        },
        archived_content_redirect_message: "Zawartość, do której próbujesz uzyskać dostęp, została zarchiwizowana",
        edit_title_move_or_archive: "Edytuj tytuł, przenieś lub archiwizuj",
        edit_title_or_archive: "Edytuj tytuł lub zarchiwizuj",
        major_update: "Główna",
        major_update_description: "Zmiany, które nie są wstecznie kompatybilne takie jak dodawanie lub usuwanie funkcji lub inne istotne zmiany.",
        minor_update: "Podrzędna",
        minor_update_description: "Zmiany zgodne z poprzednimi wersjami, takie jak nowe funkcje lub ulepszenia, bez zmian istniejących funkcji.",
        patch_update: "Poprawka",
        patch_update_description: "Poprawki zgodne z poprzednimi wersjami, takie jak poprawki zabezpieczeń, poprawki błędów lub ulepszenia wydajności, bez nowych funkcji.",
        info: "Twoja organizacja udostępnia zawartość dla usługi chmury Tekla Structures. Nazwa kodowa i numer wersji są wymagane w przypadku zawartości usługi chmury Tekla Structures, aby zapewnić zgodność i spójność.",
        immutable_files_info: "Aby zachować spójność zawartości w usłudze w chmurze, nie można usuwać ani modyfikować plików. Jeśli potrzebne są modyfikacje, zarchiwizuj bieżącą wersję i prześlij nową.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Aby uzyskać więcej informacji, zobacz <a href="${readMoreUrl}" target="_blank">tę stronę</a>.`;
        },
        resolve_conflict: {
          title: "Rozwiąż konflikt",
          description:
            "Znaleziono następujące elementy. Wybierz „Zachowaj istniejące”, aby zachować istniejący element odnaleziony w instalacji.",
          table_header_name: "Nazwa",
          table_header_type: "Typ",
          table_header_action: "Działanie",
          action_keep_existing: "Zachowaj istniejące",
          action_replace: "Zastąp",
          action_done: "Gotowe",
        },
        auth: {
          authentication_process_failed: "Proces uwierzytelniania nie powiódł się",
          access_denied: "Odmowa dostępu",
          handle_unauthorized: {
            unauthorized: "Nieupoważniony",
            please_log_in: "Zaloguj się lub zarejestruj",
          },
        },
        translations: {
          edit_translations: "Edytuj tłumaczenia",
          edit_translations_info: "Wybierz język z listy, edytuj i zapisz",
          edit_translations_default_language_info:
            "Jeśli język wybrany przez użytkownika jest niedostępny, wyświetlany jest <b>Domyślny</b>",
          edit_content: "Informacja o zawartości",
          edit_support: "Pomoc techniczna",
          updated_successfully: "Zaktualizowano tłumaczenia",
          error_on_update: "Błąd podczas aktualizacji tłumaczeń",
          default: "Domyślna",
          batch_translations: "Tłumaczenia zawartości",
          export_import_info:
            "<b>Eksportuj</b> szablon tłumaczenia wybranej zawartości, edytuj szablony, dodając tłumaczenia i z powrotem <b>zaimportuj</b> szablon, aby od razu zapisać tłumaczenia.",
          import_translations: "Importuj",
          export_translations: "Eksportuj",
          translations_missing: " (pusty)",
          select_content: "Wybierz tłumaczenia do eksportowania:",
          import_finished: "Zakończono importowanie!",
          select_file: "Wybierz plik",
          select_file_title: "Wybierz plik do zaimportowania:",
          import_from_file: "Importuj",
          import_error_info: "Plik nie jest zgodny z następującym formatem szablonu:",
          export_selected: "Eksportuj",
          invalid_file: "Nieprawidłowy format zawartości pliku",
          import_translations_finished: "Tłumaczenia zostały zaimportowane",
          details_too_long: "Zawartość szczegółów przekracza dozwoloną długość maksymalną",
          import_failed: "Nie można zaimportować tłumaczeń",
          translate_to_other_languages: "Przetłumacz na inne języki",
          import_count: function (count) {
            return "Uaktualniono tłumaczenia dla " + count + " zawartość";
          },
          invalid_language: function (contentId, language) {
            return "Treść '" + contentId + "' zawiera nieprawidłowy język '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "Treść '" + contentId + "' zawiera nieprawidłowe pole tłumaczenia '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "Treść '" +
              contentId +
              "' pole tłumaczenia '" +
              translationField +
              "' wartość przekracza dozwoloną długość"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "Model 3D",
          "3d_pic_added": "Model 3d został dodany",
          "3d_pic_updated": "Model 3d został zaktualizowany",
          "3d_pic_removed": "Model 3d został usunięty",
          "3d_pic_update_failed": "Nie można zaktualizować modelu 3d",
          "3d_pic_remove_failed": "Nie można usunąć modelu 3d",
          "3d_pic_missing": "Brak obrazu 3D dla zawartości. Wybierz obraz, klikając 'Wybierz model 3D' poniżej.",
          "3d_pic_uploading": "Model został wczytany. Proszę czekać...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Kliknij tutaj, aby edytować zawartość",
          updated_successfully: "Zaktualizowano",
          update_failed: "Nieudana aktualizacja",
          edit_search_criteria: "Edytuj kryteria wyszukiwania",
          edit_group_and_category: "Edytuj grupę i kategorię",
          edit_content: "Edytuj zawartość",
          edit_thumbnail: "Edytuj miniaturę",
          edit_thumbnail_descriptive: "Zalecane wymiary: 400 x 300 pikseli",
          edit_description: "Edytuj opis",
          edit_download_link: "Dodaj lub edytuj partnerskie łącze pobierania",
          versions_not_shown_to_users:
            "Ta zawartość ma partnerskie łącze pobierania, dlatego użytkownicy Tekla Warehouse nie widzą żadnych dodanych wcześniej wersji zawartości. Administratorzy i użytkownicy Tekla Warehouse posiadający uprawnienia edycji nadal widzą wszystkie wersje zawartości.",
          partner_download_link: "Wprowadź adres URL pobierania",
          partner_download_link_disclaimer:
            "Jeśli dodasz partnerskie łącze pobierania do istniejącej pozycji zawartości, użytkownicy Tekla Warehouse nie będą już widzieć żadnych dodanych wcześniej wersji zawartości. Tej akcji nie można cofnąć.",
          edit_title_or_delete: "Edytuj lub usuń tytuł",
          edit_title_or_move_delete: "Edytuj, przesuń lub usuń tytuł",
          edit_support: "Edytuj informacje nt. pomocy technicznej",
          edit_release_notes: "Edytuj uwagi dotyczące nowej wersji",
          edit_content_wysiwyg: "Edytuj szczegóły zawartości",
          edit_content_embed_link: "Więcej informacji można znaleźć w Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Możesz osadzać tylko filmy z YouTube, Vimeo lub Alugha.",
          details_unallowed_embed_info: "Można osadzać tylko treści z następujących domen: YouTube, Vimeo lub Alugha. Dozwolone formaty adresów URL to:",
          your_video_id: "ID_TWOJEGO_WIDEO",
          edit_content_video_link_error: "Nieprawidłowe łącze do filmu wideo. Możesz dodawać filmy za pomocą adresu URL z YouTube i Vimeo",
          edit_content_vide_code_error: "Nieprawidłowy format elementu osadzonego. Akceptowane są tylko elementy iframe. Dozwolonymi dostawcami wideo są YouTube, Vimeo i Alugha.",
          details_max_length_exceeded: "Zawartość szczegółów jest za długa",
          editor_mode_label: "Tryb Edytora:",
          editor_mode_on: "Wł.",
          editor_mode_off: "Wył.",
          do_not_notify: "Niewielka aktualizacja - nie powiadamiaj subskrybentów",
          invalid_tag: "Nieprawidłowa etykieta",
        },
        transfer_package: {
          header: "Przenieś zawartość",
          actions: {
            move: "Przesuń",
            cancel: "Anuluj",
          },
          loading: "Wczytywanie kolekcji...",
          moving: "Przenoszenie zawartości",
          select_collection: "Wybierz kolekcję",
          content_moved: "Zawartość przeniesiona. Zmiany mogą być widoczne po kilku minutach.",
          operation_failed: "Operacja nieudana",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Połączono zawartość z " + name;
          },
          selected_resources_linked: function (name) {
            return "Wybrana zawartość została połączona z " + name;
          },
          resources_linked_with_errors: "Zawartość została połączona, wystąpiły błędy dotyczące pewnej zawartości",
          resources_linking_failed: "Nie można połączyć zawartości",
          resource_unlinked: "Usunięto połączenie. Zmiany mogą być widoczne po kilku minutach.",
          resources_unlinked: "Usunięte połączenie z wybranej zawartości. Zmiany mogą być widoczne po kilku minutach.",
          resources_unlinked_with_errors: "Usunięto połączenia. Wystąpiły błędy dotyczące pewnej zawartości",
          resources_unlinking_failed: "Nie można usunąć połączenia",
          linking: "Tworzenie połączenia",
          linking_failed: "Nie można utworzyć połączenia",
          linking_failed_title: "Nie można połączyć następującej zawartości:",
          unlinking_failed_title: "Nie można anulować połączenia następującej zawartości:",
          linking_failed_already_contains:
            "Zawartość już jest połączona z wybraną kolekcją. Aktualizacje mogą pojawić się po chwili.",
          linking_failed_already_contains_short: "Zawartość już jest połączona z wybraną kolekcją",
          unlinking_failed: "Nie można usunąć połączenia",
          unlinking_failed_does_not_contain:
            "Połączenie zostało już usunięte z kolekcji. Aktualizacje mogą pojawić się po chwili.",
          unlinking_failed_does_not_contain_short: "Połączenie zostało już usunięte z kolekcji",
        },
        notification_channel: {
          title_success: "powodzenie",
          title_notice: "powiadomienie",
          title_info: "informacja",
          title_error: "błąd",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Pobieranie wymaga Tekla Maintenance",
          can_access_maintenance: {
            yes: "Tak",
            no: "Nie",
          },
        },
        confirm: {
          title: "Potwierdź",
          yes: "Tak",
          no: "Nie",
          ok: "Ok",
          close: "Zamknij",
          dont_show: "Nie pokazuj więcej",
        },
        show_more: " Pokaż więcej",
        show_less: " Pokaż mniej",
        spinner_loading: "Wczytywanie",
        spinner_processing: "Przetwarzanie",
        spinner_loading_long_time: "Wczytywanie zawartości może potrwać kilka minut",
        local_service: {
          info: "Dodatek Tekla Warehouse Service'jest wymagany do utworzenia zawartości lokalnej i sieciowej. Za pomocą Tekla Structures 20.1 SR1 lub w nowszej wersji można przesłać zawartość bezpośrednio z modelu.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Dodatek Tekla Warehouse Service</a> " +
            "jest wymagany do utworzenia zawartości lokalnej i sieciowej.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Dodatek Tekla Warehouse Service</a> " +
            "jest niedostępny. Usługa jest wymagana, aby włączyć kolekcje lokalne i sieciowe.",
          download_to_unlock_all_features_html:
            "Pobierz " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Dodatek Tekla Warehouse Service</a> " +
            ", aby odblokować wszystkie funkcje.",
          service_out_of_date_html:
            "Dodatek Tekla Warehouse jest nieaktualny. Aktualizuj " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Dodatek Tekla Warehouse Service</a> " +
            ", aby odblokować wszystkie funkcje.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Wybierz język",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Zaproś innych użytkowników",
          send_invitation_to_email: "Wyślij zaproszenie na adres e-mail",
          add_e_mail_addresses: "Dodaj adres e-mail",
          and_assign_role: "i przypisz rolę",
          send_invitation: "Wyślij zaproszenie",
          invitation_already_sent: "Zaproszenie zostało już wysłane!",
          invitation_sent: "Wysłano zaproszenie!",
          update_selected_invitations: "Aktualizuj wybrane zaproszenia",
          new_role: "Nowa rola",
          invitations_list_updated: "Zaktualizowano listę zaproszeń!",
        },
        item_browser_title: "Wybierz elementy zawartości",
        prerequisites: {
          description_placeholder: "Dodaj tutaj opis",
          link_name_placeholder: "Dodaj tutaj nazwę łącza",
          add_prerequisites_and_recommendations: "Wymagania wstępne i zalecenia (opcjonalnie)",
          list_the_profiles_components_files:
            "Utwórz listę profili, komponentów, plików lub modeli, które są wymagane do prawidłowego funkcjonowania zawartości.",
          link_to_existing_content: "Link do istniejącej zawartości",
          please_enter_prerequisite_url: "Wprowadź wymagany URL",
          please_enter_valid_prerequisite_url: "Wprowadź poprawny wymagany URL",
          missing_http: "Brakuje http://",
          add_link: "Dodaj link",
        },
        treeview: {
          cannot_export_this_container:
            "Nie można eksportować tego kontenera. Zamiast tego należy wybrać pojedyncze elementy.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "Plik musi być mniejszy niż " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Nieprawidłowy typ pliku.  Dozwolone są następujące typy plików " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Przedział czasowy:",
          timeframe_start: "Początek:",
          timeframe_end: "Koniec:",
          eventtype: "Typ zdarzenia:",

          downloads_daily_by_version: "Pobrania wg wersji",
          downloads_by_country: "Pobrania wg kraju",
          downloads_by_version: "Pobrania wg wersji",
          downloads_by_version_organization: "Pobrania wg wersji i organizacji",
          downloads_by_organization: "Pobrania wg organizacji",
          downloads_daily_by_package: "Pobrania wg elementu zawartości",
          views_daily_by_package: "Wyświetlenia wg elementu zawartości",
          views_by_country: "Wyświetlenia wg kraju",
          views_by_organization: "Wyświetlenia wg organizacji",

          downloads_by_package: "Pobrania wg elementu zawartości",
          views_by_package: "Wyświetlenia wg elementu zawartości",
          downloads_daily_by_collection: "Pobrania wg kolekcji",
          downloads_by_collection: "Pobrania wg kolekcji",
          views_daily_by_collection: "Wyświetlenia wg kolekcji",
          views_by_collection: "Wyświetlenia wg kolekcji",

          events_by_organization_func: function (version) {
            return 'Wersja "' + version + '" zdarzenia wg. organizacji'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Inne",
          sum_all_versions: "Wszystkie wersje",
          sum_all_packages: "Wszystkie elementy zawartości",
          sum_all_collections: "Wszystkie kolekcje",
          sum_all_organizations: "Wszystkie organizacje",
          recording_started_at: "Rejestrowanie danych rozpoczęto w czerwcu 2015 r.",
          recording_view_events_started_at: "Rejestrowanie danych rozpoczęto w marcu 2017 r.",
          version_title_func: function (title) {
            return 'Wersja "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " pobrania", count + " pobranie", count + " pobrania"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Liczby dotyczą wszystkich ukończonych pobrań.",
          counted_events_info_package: "Liczby dotyczą wszystkich ukończonych pobrań.",
          export: {
            caption: "Eksportuj statystyki",
            select_options: "Wybierz opcje",
            select_timeframe_description: "Wybierz przedział czasowy do eksportu statystyk:",
            select_eventtype_description: "Wybierz typ zdarzenia do eksportu statystyk z:",
          },
        },
        access_rights: {
          editors: "Edytory",
          download_access: "Dostęp do przeglądania i pobierania",
          write_user_id_placeholder: "Wpisz ID użytkownika",
          write_organization_id: "Wprowadź prawidłowe ID organizacji. Wprowadź każdy ID w osobnym wierszu.",
          write_id_placeholder:
            "Wprowadź ID użytkowników, adresy e-mail użytkowników lub ID organizacji. Każdy ID lub adres e-mail wpisz w osobnym wierszu.",
          id_help_html_short:
            "Ze względów bezpieczeństwa naszych użytkowników nie można wyszukiwać.<br/><br/>" +
            "Poproś o ID użytkownika osobę, której chcesz nadać uprawnienia dostępu. <br/>" +
            'ID użytkownika jest widoczny w <a href="#/my/">Moje szczegóły</a>.',
          id_help_html:
            "Ze względów bezpieczeństwa naszych użytkowników nie można wyszukiwać.<br/><br/>" +
            "Poproś o ID użytkownika osobę, której chcesz nadać uprawnienia dostępu. Aby nadać dostęp wszystkim użytkownikom w organizacji, zastosuj ID organizacji.<br/><br/>" +
            'ID użytkowników i organizacji znajdują się w zakładce <a href="#/my/">Moje szczegóły</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Każdy ID lub adres e-mail wpisz w osobnym wierszu. Maksymalna liczba wierszy jaką można wprowadzić na raz wynosi " +
              max_rows +
              "."
            );
          },
          input_too_long: "Wprowadzony tekst jest za długi.",
          viewer_already_added: function (input) {
            return `Użytkownik ${input} został już dodany jako obserwator`;
          },
          wrong_format: function (input) {
            return `${input} nie jest prawidłowym ID ani adresem e-mail`;
          },
          candidates_for_keyword: function (keyword) {
            return "Znaleziono użytkowników mających adres e-mail lub ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Dodaj nowy",
        add: "Dodaj",
        delete: "Usuń",
        set_as_default: "Domyślna miniatura",
        select_file: "Wybierz plik",
        choose_option: "Wybierz opcję",
        info_3D_exists: "Model 3d został już dodany. Nie można dodać do zawartości więcej niż jednego modelu.",
        action_options: {
          image: "Obraz",
          "3DModel": "Model 3D",
          video: "Wideo",
        },
        video: {
          url: "URL wideo",
          invalid_url: "Nieprawidłowy URL",
        },
      },
      units: {
        bytes: "bajty",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Pobierz",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " osób lubi to", number + " osoba lubi to", number + " osób lubi to"]);
      },
      main_info: {
        header_title: "Nowy wygląd Tekla Warehouse!",
        header_text: "Jak Ci się podoba nowa uproszona witryna internetowa Tekla Warehouse?",
        header_text2: "Główne zmiany to:",
        bullet_text_1: "Jasne wyszukiwanie na stronie docelowej.",
        bullet_text_2:
          "Wszystkie filtry wyszukiwania na stronie wyszukiwania znajdują się po lewej stronie, a domyślny widok miniatur ma więcej wyników wyszukiwania w tym samym widoku.",
        bullet_text_3:
          "Opcja pobierania na stronie zawartości jest umieszczona centralnie, dzięki czemu łatwo przystąpić do używania zawartości. Opcje pobierania i instalacji są teraz dostępne za pomocą przycisku Pobierz.",
        bullet_text_4:
          "Zarówno strona kolekcji, jak i Mój koszyk zawierają 3-punktowe instrukcje pobierania i instalowania wszystkiego.",
      },
      organizations: {
        error_loading_collection_contents: "Błąd wczytywania zawartości kolekcji",
        error_loading_organization: "Błąd wczytywania organizacji",
      },
      downloads_graph: {
        date: "Data",
        total_views: "Całkowita liczba wyświetleń",
        total_downloads: "Całkowita liczba pobrań",
        views: "Wyświetlenia",
        downloads: "Pobrania",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Dodatek Tekla Warehouse Service" +
          "</a>" +
          " jest wymagany.",
        processingFailed: "Przetwarzanie nie powiodło się",
        forbidden: "Niedozwolone",
        resource_not_found: "Nie znaleziono zasobu",
        no_matches_found: "Brak wyników",
        no_matches_found_for: function (input) {
          return "Nie znaleziono żadnych użytkowników o podanym adresie e-mail lub ID " + input;
        },
        not_identified: "Nie zidentyfikowano",
        error_while_searching: "Błąd podczas wyszukiwania",
        error_user_not_found: "Nie znaleziono organizacji lub użytkownika",
        error_while_adding_member: "Błąd podczas nadawania użytkownikowi uprawnień dostępu",
        error_while_removing_member: "Błąd podczas odbierania użytkownikowi uprawnień dostępu",
      },
      offline_to_online: {
        fail_on_initialize: "Błąd podczas przetwarzania zawartości zip. Przerwano konwersję",
        fail_on_validate: "Nie można sprawdzić metadanych zawartości. Przerwano konwersję",
        fail_on_save: "Wystąpił błąd podczas zapisywania zawartości. Wycofywanie zmian",
        success: "Skonwertowano zawartość offline na zawartość online",
        validate_errors_title: "Nie można skonwertować zawartości",
        processing: "Zapisywanie pakietu ",
        processed: "Przetworzony element",
        collection_thumbnail_missing_name: 'Brak pliku binarnego miniatury zawartości "filename"',
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Pakiet '" + packageTitle + "' brak pliku binarnego miniatury 'nazwa pliku'";
        },
        package_value_missing_title_func: function () {
          return "Brak pakietu 'tytuł'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Pakiet '" + packageTitle + "' nie istnieje '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "Pakiet '" + packageTitle + "' pole '" + field + "' ma następujące nieprawidłowe wartości: " + invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Pakiet '" + packageTitle + "' ma nieprawidłową etykietę: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "Pakiet '" + packageTitle + "' brak wersji 'tytuł'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Pakiet '" + packageTitle + "' wersja '" + versionTitle + "' nie istnieje '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Pakiet '" +
            packageTitle +
            "' wersja '" +
            versionTitle +
            "' pole wartości '" +
            field +
            "' ma następujące nieprawidłowe wartości: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "Pakiet '" + packageTitle + "' wersja '" + versionTitle + "' brak pliku binarnego 'nazwa pliku'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Ważne: Koniec wsparcia dla lokalnych kolekcji w Tekla Warehouse",
            part1: "Od 2 czerwca 2025 roku lokalne kolekcje nie będą już dostępne w Tekla Warehouse. Użytkownicy nie będą mogli tworzyć, przeglądać ani wyszukiwać lokalnych kolekcji ani korzystać z zawartości lokalnych kolekcji.",
            part2: "Aby uzyskać pomoc w przejściu z lokalnych kolekcji na kolekcje online, skontaktuj się z lokalną organizacją wsparcia.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Ważne! Ze względu na poprawę zabezpieczeń, po ${date} listopada 2024 r., zarządzanie zawartością organizacji będzie wymagało roli Edytora zawartości Tekla Warehouse. <br />Poproś administratora swojej organizacji, aby przydzielił Ci tę rolę. Więcej informacji można znaleźć w <a href="${productBulletinUrl}" target="_blank">biuletynie produktowym</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Ze względu na poprawę zabezpieczeń, po ${date} listopada 2024 r., zarządzanie zawartością organizacji będzie wymagało roli Edytora zawartości Tekla Warehouse. <br />Poproś administratora swojej organizacji, aby przydzielił Ci tę rolę. Więcej informacji można znaleźć w <a href="${productBulletinUrl}" target="_blank">biuletynie produktowym</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Nie widzisz opcji edycji dla swojej organizacji? Po ${date} listopada zarządzanie zawartością organizacji będzie wymagało roli Edytora zawartości Tekla Warehouse. <br />Możesz sprawdzić swoją rolę w Mój Warehouse > Moje szczegóły. Więcej informacji można znaleźć w <a href="${productBulletinUrl}" target="_blank">biuletynie produktowym</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Nie widzisz opcji tworzenia zawartości dla swojej organizacji? Po ${date} listopada zarządzanie zawartością organizacji będzie wymagało roli Edytora zawartości Tekla Warehouse. <br />Możesz sprawdzić swoją rolę w Mój Warehouse > Moje szczegóły. Więcej informacji można znaleźć w <a href="${productBulletinUrl}" target="_blank">biuletynie produktowym</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Po ${date} listopada zarządzanie zawartością organizacji będzie wymagało roli Edytora zawartości Tekla Warehouse. Poproś administratora organizacji o przypisanie Ci tej roli. <br />Aby sprawdzić, kto jest administratorem Twojej organizacji, przejdź do swojego profilu Tekla Online. Więcej informacji można znaleźć w <a href="${productBulletinUrl}" target="_blank">biuletynie produktowym</a>.`
            },
          },
          service_plugin_update_info:
            "Masz problemy z połączeniem z Tekla Structures? Upewnij się, że używasz najnowszej wtyczki <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service</a>.",
        },
        maintenance_info:
          "Tekla Warehouse jest w trakcie konserwacji w terminie 2017-10-27 22:00:00 - 23:00:00 UTC. Przepraszamy za utrudnienia.",
        release_notes_info: "Nowe funkcje w Tekla Warehouse",
        release_notes_link:
          'Zaktualizowano Tekla Warehouse. Przeczytaj <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">co nowego</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Potrzebujesz programisty Tekla do swojego projektu?",
        heading2Dev:
          'Zajrzyj do nowego katalogu o nazwie Tekla Development Experts.<br><br>To miejsce nawiązywania kontaktów między utalentowanymi programistami a zainteresowanymi użytkownikami Tekla.<br><br>Każdy uczestnik Tekla Partners Program może dodać informacje o sobie do katalogu ekspertów.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Znajdź programistę &rsaquo;</a>',
        static_intro:
          "Bezpłatny magazyn Tekla Structures BIM, za pomocą którego możesz wyszukiwać, importować, instalować i udostępniać zawartość zarówno wewnętrznie, jak i globalnie. Tekla Warehouse ma na celu uproszczenie wydajnego tworzenia modeli wysokiej jakości.",
        new_intro:
          'Tekla Corporation nosi teraz nazwę Trimble. Nadal pracujemy nad rozwijaniem oprogramowania Tekla i oferujemy pomoc tych samych specjalistów. Dowiedz się więcej: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Zweryfikuj swoje konto",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Nowe materiały &rsaquo;",
          popular: "Popularna zawartość &rsaquo;",
          recommended: "Polecane &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Wiadomości &rsaquo;</a>',
          item_title: "To jest wiadomość!",
          item_description:
            'Tekst powiązany z pewnymi wiadomościami, tekst powiązany z pewnymi wiadomościami, tekst powiązany z pewnymi wiadomościami, tekst powiązany z pewnymi wiadomościami, tekst powiązany z pewnymi wiadomościami.<br><a href="https://developer.tekla.com" target="_blank">Przeczytaj więcej &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse w liczbach",
          partners: "Partnerzy",
          partners_count: "352",
          collections: "Kolekcje",
          collections_count: "793",
          items: "Pozycje zawartości",
          items_count: "2893",
        },
        partners: "Nasi partnerzy",
        action_links: {
          search_title: "Przeglądaj w poszukiwaniu zawartości?",
          upload_title: "Wczytać zawartość?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Filmy &rsaquo;</a>',
          link: 'Odwiedź nasz <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">kanał Youtube</a>, aby obejrzeć więcej filmów.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Poszukaj łącza weryfikacyjnego w wiadomościach e-mail, lub <u><a href="' +
            settingsUrl +
            '">poproś o nowe łącze</a></u>.'
          );
        },
        verifyAccountAcceptTerms: "Zweryfikuj adres e-mail i zaakceptuj warunki oraz politykę prywatności",
        acceptTerms: "Zaakceptuj warunki oraz politykę prywatności",
        acceptUpdatedTerms:
          "Zaakceptuj zaktualizowane warunki i postanowienia tekla online services oraz politykę prywatności",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Wróć do swojego <u><a href="' + settingsUrl + '">profilu</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Sprawdź i zaakceptuj warunki i postanowienia oraz politykę prywatności w swoim <u><a href="' +
            settingsUrl +
            '">profilu</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Przeglądaj kolekcje &rsaquo;",
          new: "Nowe",
          popular: "Popularne",
          recommended: "Polecane",
          organizations: "Organizacje",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Wybierz folder",
        selectFolderInstructions: "Foldery lokalne i sieciowe mogą być używane jako kolekcje.",
        selectedFolder: "Wybierz folder (skopiuj i wklej lokalizację):",
        selectedFolderPlaceholder: "Np. C\\folder, Z:\\folder sieciowy, \\\nazwa-komputera\\folder",
        collectionCreation: {
          name: "Nazwa",
          createNewCollection: "Utwórz kolekcję lokalną",
          pleaseInputTitle: "Wprowadź tytuł",
          invalidPath: "Nieprawidłowa ścieżka",
          access_denied: "Nie można dodać kolekcji: odmowa dostępu",
          unsafe_location: "Nie można dodać kolekcji: niebezpieczna lokalizacja",
          add_collection_failed: "Nie można dodać kolekcji",
          minLengthError: "Tytuł musi mieć co najmniej 3 znaki",
          maxLengthError: "Tytuł nie może mieć więcej niż 20 znaków",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Lokalne i sieciowe foldery mogą być używane jako kolekcje.",
        collectionCreation: {
          name: "Nazwa",
          createNewCollection: "Utwórz kolekcję online",
          pleaseInputTitle: "Wprowadź tytuł",
          minLengthError: "Tytuł musi mieć co najmniej 3 znaki",
          maxLengthError: "Tytuł nie może mieć więcej niż 20 znaków",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Zasób zapisany",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Czy dostrzegasz możliwości?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Prześlij swoją opinię</span>',
          help_us_improve_html:
            'Pomóż nam ulepszać Tekla Warehouse! <br/><a id="showSurvey">Kliknij tutaj</a> albo <a id="dontShow">nie pokazuj więcej tego komunikatu</a>',
          dialog: {
            do_you_see_potential: "Czy dostrzegasz możliwości?",
            help_us_improve_html: "Pomóż nam ulepszać Tekla Warehouse! Twoja opinia jest dla nas ważna.",
            continue_to_survey: "Rozpocznij ankietę",
            exit_to_warehouse: "Nie, dziękuję",
          },
        },
      },
      search: {
        bar: {
          search: "Szukaj",
          filter: " ",
          more_filters: "Więcej filtrów",
          hide_filters: "Ukryj filtry",
          clear: "Wyczyść",
          exclude: "Wyklucz",
          include: "Dołącz",
          clear_filters: "Zresetuj filtry",
          load_defaults: "Użyj moich preferencji &rsaquo;",
          save_defaults: "Zapisz jako moje preferencje &rsaquo;",
          defaults_saved_successfully: "Zapisano filtry domyślne",
          edit_defaults: "Edytuj domyślne",
        },
        info: {
          searching_organizations: "Wyszukiwanie w organizacji...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Brak wyników", amountTotal + " wyniki"]);
            } else {
              return (
                pluralize(amountTotal, ["Brak wyników", amountTotal + " wyniki"]) +
                ' dla "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " wyniki";
            } else if (!searchTerm) {
              return "Wprowadź warunek wyszukiwania, aby zobaczyć wyniki dla organizacji";
            } else if (searchTerm.length < 2) {
              return "Podaj co najmniej dwa znaki dla wyszukiwania organizacji";
            } else {
              return (
                pluralize(amountTotal, ["Brak wyników", amountTotal + " wyniki"]) +
                ' dla "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " wyniki w zawartości online",
          local: " wyniki w zawartości lokalnej",
          organization: "wyniki w organizacjach",
          plugin_missing_info:
            'Zawartość lokalna jest pokazywana pod warunkiem, że <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Dodatek Tekla Warehouse Service" +
            "</a>" +
            " jest zainstalowana.",
          version_filter_info: "Filtrowanie wersji oprogramowania jest nieprawidłowe w wynikach w lokalnej zawartości",
        },
        exclude_env_files: "Wyklucz pliki środowiska",
        sidebar: {
          filters: {
            measurementUnits: "Jednostki miary",
            locations: "Lokalizacja projektu",
            languages: "Języki",
          },
          placeholders: {
            use_categories: "Kategoria",
            measurement_units: "Jednostki miary",
            languages: "Języki",
            locations: "Lokalizacje",
          },
        },
      },
      placeholders: {
        name: "Nazwa...",
        contentOwner: "Nazwa właściciela zawartości...",
        useCategory: "Wybierz zastosowanie",
        selectType: "Wybierz typ",
        filter: "Filtruj...",
        search: "Szukaj...",
        choose: "Wybierz...",
        searchFilters: "Filtry wyszukiwania...",
        selectContentItem: "Wybierz element zawartości...",
        search_content: "Szukaj zawartości...",
        includeAs: "Uwzględnij jako...",
        selectCollection: "Wybierz kolekcję",
        selectUserGroup: "Wybierz grupę użytkowników",
        online_collections_tooltip: "Kliknij, aby wyszukiwać zawartość online.",
        local_collections_tooltip:
          "Kliknij, aby wyszukać zawartość lokalną, sieciową i uwzględnioną w instalacji Tekla Structures",
        organizations_tooltip: "Kliknij, aby wyszukać organizacje",
        "filter-options": {
          products: "Wszystkie produkty będące oprogramowaniem",
          locations: "Wszystkie lokalizacje",
          languages: "Wszystkie języki",
          measurementUnits: "Wszystkie jednostki miary",
          testedVersions: "Wszystkie wersje programu",
          useCategories: "Wszystkie kategorie",
          useCategoriesCombined: "Kategoria",
          allGroups: "Wszystkie grupy",
          itemTypeCategories: "Wszystkich typy elementów",
        },
        "exclude-filter-options": {
          products: "Wyklucz produkty będące oprogramowaniem",
          locations: "Wyklucz lokalizacje",
          languages: "Wyklucz języki",
          measurementUnits: "Wyklucz jednostki miary",
          testedVersions: "Wyklucz wersje programu",
          useCategories: "Wyklucz kategorie",
          useCategoriesCombined: "Wyklucz kategorię",
          allGroups: "Wyklucz grupy",
          itemTypeCategories: "Wyklucz typy elementów",
        },
      },
      link: {
        link_selected: "Połącz wybrane",
        unlink_selected: "Anuluj połączenie wybranych",
      },
      download_install: {
        choose_version: "Wybierz wersję swojego programu:",
        choose_content: "Wybierz elementy zawartości z listy poniżej:",
        choose_action: "Wybierz czynność dla wybranych elementów:",
      },
      download: {
        copy_to_clipboard: "Kopiuj do schowka",
        download_selected: "Pobierz",
        add_selected_items_to_basket: "Dodaj wybrane do Mojego koszyka",
        add_item_to_basket: "Dodaj wybrane do Mojego koszyka",
        add_all_items_to_basket: "Dodaj wszystko do koszyka",
        add_all_items_to_basket_info: "Liczba elementów przekracza 100. Wczytywanie może trochę potrwać",
        add_all_items_to_basket_too_many_results: "Można dodać maksymalnie 1000 elementów",
        cart_loading: "Liczba elementów przekracza 100. Wczytywanie może trochę potrwać",
        remove_item_from_basket: "Usuń z koszyka",
        shopping_cart_no_content: "Brak zawartości w koszyku",
        shopping_cart_no_content_for_version: "W koszyku brak zawartości dla wybranej wersji",
        shopping_cart_items_func: function (itemsCount) {
          return "Mój koszyk (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Instrukcje pobierania",
          step_one: "Za pomocą tego przycisku skopiuj informacje dotyczące pobierania do schowka",
          step_two_func: function (downloadAppUrl) {
            return (
              "Uruchom aplikację Tekla Warehouse Downloader. Możesz pobrać aplikację " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">tutaj.</a> '
            );
          },
          step_three: "Aplikacja Tekla Warehouse Downloader poprowadzi Cię przez pozostałą część procesu pobierania.",
          show_data_title: "Nie działa?",
          show_data_to_be_copied: "Kopiuj ręcznie",
          hide_data_to_be_copied: "Ukryj dane pobierania",
        },
      },
      install: {
        install_title: " elementy zostaną zainstalowane za pomocą dodatku Tekla Warehouse.",
        failed_install_information:
          "Wybrano zawartość dostępną wyłącznie do pobrania. Ta zawartość nie zostanie teraz zainstalowana. Proszę pobierz tę zawartość oddzielnie z Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Instaluj: proszę zweryfikuj, czy dodatek Tekla Warehouse Service jest uruchomiony prawidłowo",
        verify_ts_connection: "Instaluj: sprawdź, czy Tekla Structures działa",
        select_software_version: "Filtruj według wersji programu w celu połączenia, pobrania lub zainstalowania",
        download_requires_maintenance: "Dostęp do niektórych elementów poniżej wymaga Tekla Maintenance",
        install_selected: "Instaluj",
        start_install: "Uruchom instalację",
        install_started: "Instalacja została uruchomiona",
        select_ts_version: "Wybierz wersję TS",
        install_retry: "Zacznij ponownie od Tekla Warehouse, jeśli konieczna jest ponowna próba",
        failed_installs_info_func: function (failedCount) {
          return "Zawartość, która nie zostanie zainstalowana (" + failedCount + " wersje):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Instalacja dla wybranych " + totalInstallsCount + " elementy";
        },
      },
      helpers: {
        behalf: "Według",
        sortBy: "Sortuj wg:",
        filter: "Wyniki filtrowania",
        showAsThumbs: "Pokaż jako miniatury",
        showAsList: "Pokaż jako listę",
        resultsPerPage: "Pokaż na stronie:",
        filter_by_version: "Filtruj wg wersji:",
        show_more_options: "Więcej opcji",
        show_less_options: "Mniej opcji",
        filter_by_type: "Filtr wg typu:",
        select_all: "Wybierz wszystko",
        show_content_items: "Pokaż elementy zawartości",
        choose_action: "Wybierz czynność dla wybranej zawartości: ",
        show_collections: "Pokaż kolekcje",
        search: {
          show: "Pokaż:",
          show_content_items: "Pozycje zawartości",
          show_collections: "Kolekcje",
          show_organizations: "Organizacje",
          show_online_content: "Zawartość online",
          show_offline_content: "Zawartość offline",
          local_content_info:
            'Zawartość lokalna jest pokazywana pod warunkiem, że dodatek <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " został zainstalowany.",
        },
        tekla_maintenance_required: "Dostęp do niektórych poniższych elementów wymaga Tekla Maintenance",
        show_content_alerts: "Pokaż ostrzeżenia dotyczące zawartości ",
        show_collection_alerts: "Pokaż ostrzeżenia dotyczące kolekcji ",
      },
      actions: {
        load_more: "Wczytaj więcej",
        use_infitinite_scroll: "Użyj ciągłego przewijania",
        back: "Wstecz",
        show_download_dialog: "Pobierz",
        download: "Tylko pobierz",
        download_on_partner_site: "Pobierz ze strony partnerskiej",
        insert: "Instaluj",
        install: "Instaluj",
        downloadAndInstall: "Instaluj",
        createNew: "Utwórz nowy",
        viewItems: "Wyświetl",
        viewCollection: "Wyświetl kolekcję",
        viewGroup: "Pokaż grupę",
        joinGroup: "Dołącz do grupy",
        loginToDownload: "Zaloguj się, aby pobrać",
        show3DPic: "Pokaż model 3D",
        remove: "Usuń",
        browse: "Przeglądaj",
        browseFolder: "Przeglądaj",
        browseFileSystem: "Przeglądaj",
        add: "Dodaj",
        save: "Zapisz",
        batch_edit: "Edycja grupy",
        translations: "Tłumaczenia",
        cancel: "Anuluj",
        continue: "Kontynuuj",
        delete: "Usuń",
        addNetworkPath: "Dodaj lokalizację sieciową",
        select: "Wybierz",
        create: "Utwórz",
        update: "Aktualizuj",
        choose_thumbnail: "Wybierz miniaturę",
        choose_picture: "Wybierz obraz",
        remove_picture: "Usuń obraz",
        choose_3D_picture: "Wybierz model 3D",
        remove_3D_picture: "Usuń model 3D",
        like: "Polub",
        unlike: "Cofnij polubienie",
        close: "Zamknij",
        export: "Eksportuj",
        add_or_remove: "Dodaj/usuń",
        select_content_to_convert: "Wybierz zawartość offline",
        search: "Szukaj",
        upload: "Wyślij",
        edit: "Edycja",
        view_3d: "Kliknij, aby wyświetlić model…",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "beta",
        search: "Szukaj",
        profile: "Moje szczegóły",
        myWarehouse: "Mój Warehouse",
        myStuff: "Moje zawartość",
        logout: "Wyloguj się",
        login: "Zaloguj się",
        register: "Zarejestruj",
        upload: "Wyślij",
        uploading: "Wyślij",
        dashboard: "Panel sterowania",
        learnMore: "Dowiedz się więcej",
        about: "Informacje",
        shoppingCart: "Mój koszyk",
        addToCartInfo:
          "Elementy można gromadzić w koszyku i pobierać lub instalować je od razu. Pobieranie jest całkowicie bezpłatne",
      },
      cubeNavigation: {
        headings: {
          get_started: "ZACZNIJ",
          learn: "POZNAJ",
          get_help: "UZYSKAJ POMOC",
          extend_your_tekla: "PRZEDŁUŻ WAŻNOŚĆ KONTA TEKLA",
          collaborate: "WSPÓŁPRACA",
          students_and_educators: "Students and educators",
          about: "INFORMACJE",
        },
        "tekla-online": "Usługi online",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Strona internetowa Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Mój profil",
        discussionForum: "Forum dyskusyjne",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "STRONA GŁÓWNA USŁUG TEKLA",
        register: "Zarejestruj",
        login: "Zaloguj się",
        evaluate: "Oceń",
        buy: "Kup",
        download: "Pobierz",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Produkty",
        teklaServices: "Usługi",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Moje kolekcje",
        myLikes: "Moje polubienia",
        profile: "Moje szczegóły",
        preferences: "Moje preferencje",
        myUserGroups: "Moje grupy użytkowników",
        localCollections: "Kolekcje lokalne i sieciowe",
        tsCollections: "Kolekcje Tekla Structures",
        downloads: "Do pobrania",
        selectContent: "Wybierz pliki zawartości",
        addMetadata: "Dodaj metadane",
        onlineCollections: "Kolekcje online",
        userGroups: "Grupy użytkownika",
        createNew: "Dodaj zawartość",
        notifications: "Powiadomienia",
        contentUsage: "Użycie zawartości",
        browseCollections: "Przeglądaj kolekcje",
        admin: "Administrator",
        sales: "Sprzedaż",
        shoppingCart: "Mój koszyk",
      },
      notifications: {
        title: "powiadomienie",
        invitedBy: "Zaproszenie od",
        invitationType: "Typ zaproszenia",
        invitedTo: "Zaproszenie do",
        createdAt: "Utworzono w",
        action: "Działanie",
        acceptInvitation: "Akceptuj",
      },
      batch_edit: {
        selectActionType: "Wybierz typ działania",
        edit_content: "Edytuj metadane zawartości",
        content_title: "Zawartość",
        metadataToChange: "Metadane do zmiany",
        selectContent: "Dla zawartości",
        select_action: "Wybierz działanie",
        support: {
          support: "Pomoc techniczna",
          licenses: "Licencje",
        },
        versions: {
          version: "Wersja",
          prerequisities: "Warunki wstępne i wymagania",
        },
        content: {
          select_collection: "Wybierz kolekcję",
          show_collection: "Pokaż:",
          show_all_content: "Wszystkie kolekcje",
          show_organization_content: "Kolekcje mojej organizacji",
          select_all: "Wybierz wszystko",
          select_all_info: "(To może chwilę potrwać).",
          actionOptions: {
            add: "Dodaj",
            remove: "Usuń",
            replace: "Zamień na",
            yes: "Tak",
            no: "nie",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Nie wybrano atrybutów",
            invalid_attributes: "Nieprawidłowe wartości atrybutów",
            invalid_actions: "Nieprawidłowe czynności",
            content_not_selected: "Nie wybrano zawartości",
            entities_not_selected: "Dostarczono dane dotyczące pakietu, ale nie wybrano pakietu",
            versions_not_selected: "Dostarczono dane dotyczące wersji, ale nie wybrano wersji",
          },
        },
        update: {
          info_processing: "Nie zamykaj tego okna do chwili przetworzenia wszystkich elementów.",
          info_processed: "Wszystkie elementy zostały przetworzone.",
          processing: "Przetwarzanie elementu",
          error_info: "Wystąpiły problemy z aktualizacją następujących elementów:",
          package_failure_func: function (package_title, errorMsg) {
            return "Zawartość: " + package_title + ", błąd: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Zawartość: " + package_title + ", wersja: " + version_title + ", błąd: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " elementy(-ów) zostanie zaktualizowanych. Czy chcesz kontynuować?";
          },
        },
      },
      myUserGroups: {
        title: "Grupy użytkowników",
        createdByPrefix: "Według:",
      },
      downloads: {
        title: "Do pobrania",
      },
      myCollections: {
        new_collection: "Nowa kolekcja",
        my_collections: "Moje kolekcje",
        created_by_me: "Utworzone przeze mnie",
        local_collections: "Kolekcje lokalne i sieciowe",
        online_collections: "Kolekcje online",
        created_by_my_organization: "Utworzone przez moją organizację",
        created_by_my_organizations: "Utworzone przez moją organizację",
        shared_with_me: "Udostępnione dla mnie",
        shared_with_me_or_organization: "Udostępnione dla mnie lub mojej organizacji",
        notification: {
          error: "Błąd",
          error_loading_online_collections: "Błąd wczytywania kolekcji w trybie online",
          error_loading_packages_liked_by_me: "Błąd wczytywania zawartości polubionej przeze mnie",
          error_loading_my_organization_s_collections: "Błąd wczytywania kolekcji mojej organizacji",
          error_loading_my_shared_collections: "Błąd wczytywania moich udostępnionych kolekcji",
        },
        local: {
          helpText1: "Aby uzyskać dostęp do lokalnych kolekcji, należy zainstalować ",
          helpText2: "Dodatek Tekla Warehouse Service",
          helpText3: ".",
          title: "Kolekcje lokalne i sieciowe",
          notFound: "Brak kolekcji lokalnych.",
          myCollectionsTitle: "Moje kolekcje",
          actions: {
            createNew: "Utwórz kolekcję lokalną lub sieciową",
          },
        },
      },
      organization: {
        news_feed: "Kanał wiadomości",
        add_comment: "Dodaj tekst kanału",
        edit_comment: "Edytuj element nowości",
        subTabs: {
          collections: "Kolekcje",
          analytics: "Statystyki",
          contact_and_support: "Kontakt",
          news_feed: "Kanał wiadomości",
        },
        edit: {
          edit_title: "Edytuj tytuł",
          edit_description: "Edytuj opis",
          edit_contact: "Edytuj informacje kontaktowe",
          edit_logo: "Edytuj logo",
          edit_url: "Edytuj adres URL",
          edit_email: "Edytuj adres e-mail",
          edit_phonenumber: "Edytuj numer telefonu",
          edit_warehouse_url: "Edytuj adres URL Warehouse",
          edit_warehouse_url_subtitle: "Litery alfabetu, bez znaków skandynawskich, liczb i znaków specjalnych",
          edit_url_subtitle: "Wstaw pełny adres URL",
          edit_logo_descriptive: "Optymalna wielkość obrazu to około 350 * 200 pikseli",
          edit_facebook_url: "Edytuj adres URL Facebook",
          edit_twitter_url: "Edytuj adres URL Twitter",
          edit_linkedin_url: "Edytuj adres URL LinkedIn",
          edit_youtube_url: "Edytuj adres URL YouTube",
          edit_googleplus_url: "Edytuj adres URL Google+",
          logo_updated: "Logo zostało zaktualizowane",
          logo_update_failed: "Nie udało się zaktualizować logo",
          logo_remove_failed: "Nie udało się usunąć logo",
          logo_removed: "Logo zostało usunięte.",
          updated_successfully: "Aktualizacja powiodła się",
          updated_failed: "Nieudana aktualizacja",
          add_comment: "Dodaj nowy",
          edit_comment: "Edytuj element nowości",
          comment_added: "Element kanału wiadomości został pomyślnie dodany",
          comment_updated: "Element kanału wiadomości został pomyślnie zaktualizowany",
          comment_deleted: "Element kanału wiadomości został pomyślnie usunięty",
          comment_add_failed: "Niepowodzenie dodawania kanału wiadomości",
          comment_update_failed: "Nie można zaktualizować elementu kanału wiadomości",
          comment_delete_failed: "Nie można usunąć elementu kanału wiadomości",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Szukaj zawartości online wg " + name + " &rsaquo;";
        },
        contact_information: "Skontaktuj się z nami",
        website: "Strona internetowa &rsaquo;",
        show_newer_news: "Nowsze",
        show_older_news: "Starsze",
      },
      online_collections: {
        new_collection: "Nowa kolekcja",
        online_collections: "Kolekcje online",
        created_by_organizations: "Utworzone przez organizacje",
        created_by_users: "Utworzone przez użytkowników",
      },
      local_collections: {
        tab_title: "Zawartość",
        local_collections: "Kolekcje lokalne",
      },
      versions: {
        add_tool: "Dodaj aplikację",
        table_header: {
          name: "Nazwa",
          type: "Typ",
          platform: "Platforma",
          modified_at: "Zmieniono",
          size: "Rozmiar",
          virus_scan: "Skanowanie wirusów",
        },
        // placeholders
        select_file_type: "Wybierz typ pliku",
        select_platform: "Wybierz platformę",
        select_unit: "Wybierz jednostkę",
        select_quality: "Wybierz jakość",
        what_will_be_added: "Co chcesz dodać?",
        select_files_from_your_disk: "Wybierz pliki z dysku",
        add: "Dodaj",
        //content management
        add_application: "Dodaj aplikację",
        select_content_from_tekla_structures: "Wybierz zawartość z Tekla Structures",
        or: "lub",
        files: "Pliki",
        //version metadata
        version_information: "Informacja o wersji",
        version_display_name: "Wyświetlana nazwa wersji",
        version_number: "Numer wersji",
        version_number_info: "Wprowadź numer wersji. Po zapisaniu numeru wersji nie można go zmienić.",
        existing_versions: "Istniejące numery wersji tej zawartości",
        version_number_validation: {
          missing: "Należy wprowadzić numer wersji",
          invalid: "Numer wersji musi być zgodny z zasadami wersjonowania semantycznego (GŁÓWNA.PODRZĘDNA.POPRAWKA).",
          already_in_use: "Ten numer wersji jest już używany dla tej zawartości.",
        },
        select_update_type: "Aby wygenerować numer wersji, najpierw wybierz typ aktualizacji",
        please_enter_a_version_name_or_number: "Wprowadź nazwę lub numer wersji.",
        virus_scan: {
          soft_failed: "Niepowodzenie",
          hard_failed: "Nie przeskanowano",
          infected: "Zainfekowane",
        },
      },
      collections: {
        by: "Według:",
        edit: {
          edit_collection: "Edytuj kolekcję",
          edit_description: "Edytuj opis",
        },
        notification: {
          collection_updated: "Zaktualizowano kolekcję",
          error: "Błąd",
          error_loading_other_users_collections: "Błąd podczas wczytywania kolekcji innych użytkowników",
          error_loading_other_organizations_collections: "Błąd wczytywania kolekcji innych organizacji",
          error_loading_collection_contents: "Błąd wczytywania zawartości kolekcji",
          members_list_updated: "Zaktualizowano listę członków!",
          invitations_removed: "Usunięto zaproszenia!",
          collection_removed: "Usunięto kolekcję",
          thumbnail_deleted: "Usunięto miniaturę",
          wrong_file_type: "Nieprawidłowy typ pliku.",
          invalid_file_type: "Nieprawidłowy typ pliku.",
          selected_image_is_too_big: "Wybrany obraz jest zbyt duży.",
          thumbnail_updated: "Zaktualizowano miniaturę",
          default_thumbnail_updated: "Zaktualizowano domyślną miniaturę",
          default_thumbnail_update_failed: "Nie można zaktualizować domyślnej miniatury",
          thumbnail_added: "Dodano miniaturę",
          thumbnail_update_failed: "Nie można zaktualizować miniatury",
          video_added: "Dodano wideo",
          video_adding_failed: "Nie można dodać wideo",
          video_deleted: "Usunięto wideo",
          video_deleting_failed: "Nie można usunąć wideo",
        },
        confirm: {
          delete_all: function (title) {
            return 'Usuń kolekcję i całą zawartość w kolekcji "' + title + '"?';
          },
          remove_members: "Czy na pewno?",
          remove_invitations: "Czy na pewno?",
        },
        useNetworkDrive: "Użyj folderu lub dysku sieciowego",
        createCollection: "Utwórz kolekcję online",
        createCollectionInstructions: "Utwórz kolekcję, aby pogrupować zawartość.",

        actions: {
          delete_thumbnail: "Usuń miniaturę",
          edit_collection: "Edytuj kolekcję",
          delete_collection: "Usuń kolekcję",
          add_content: "Dodaj zawartość",
        },
        subTabs: {
          content: "Zawartość",
          members: "Pokaż członków",
          analytics: "Statystyki",
          manage_access: "Zarządzaj dostępem",
        },

        members: {
          add_user_groups: "Dodaj grupy użytkowników",
          select_user_group: "Wybierz grupę użytkowników",
          with_role: "z rolą",
          user_groups_added: "Dodano grupy użytkowników!",
          members_list_updated: "Zaktualizowano listę członków!",
          update_selected_members: "Aktualizuj wybranych członków",
          table_header: {
            member_type: "Typ członka",
            member_name: "Imię członka",
            email: "E-mail",
            role: "Rola",
            joined_at: "Dołączono ",
            invited_at: "Zaproszono ",
          },
          new_role: "Nowa rola",
        },
        member_list: {
          users_who_can_access_this_collection: "Użytkownicy, którzy mają dostęp do tej kolekcji",
          add_user_group: "Dodaj grupę użytkownika",
          update_members: "Aktualizuj członków",
          remove_members: "Usuń członków",
          pending_invitations: "Zaproszenia oczekujące",
          user: "użytkownik",
          invite_member: "Zaproś członka",
          update_invitations: "Aktualizuj zaproszenia",
          remove_invitations: "Usuń zaproszenia",
        },
        labels: {
          select_organization: "Wybierz organizację",
          select_visibility: "Wybierz widoczność",
          title: "Nazwa",
          name: "Nazwa",
          createAs: "Utwórz jako: ",
          asMyself: "Ja",
          asUserGroup: "Grupa użytkowników",
          asOrganization: "Organizacja",
          selectOwner: "Wybierz organizację dla właściciela zawartości",
          visibility: "Widoczność",
          visibilityOptions: {
            public: "Publiczna",
            private: "Prywatna",
          },
          description: "Opis",
        },
        new_collection: {
          please_input_a_username: "Wprowadź nazwę użytkownika",
          title_is_required_to_be_at_least_3_characters: "Tytuł musi mieć co najmniej 3 znaki",
          title_cannot_be_longer_than_20_characters: "Tytuł nie może mieć więcej niż 20 znaków",
        },
        collection_list: {
          no_content: "Brak kolekcji.",
          results_summary: function (number) {
            return "" + number + "  kolekcja " + pluralize(number, ["elementy", "element", "elementy"]);
          },
        },
        entity_list: {
          no_content: "Brak zawartości w kolekcji.",
          results_summary: function (number) {
            return "" + number + " zawartość " + pluralize(number, ["elementy", "element", "elementy"]);
          },
          linked_content: "Połączona zawartość",
          unlink: "Anuluj połączenie zawartości",
        },
      },
      alerts: {
        no_updates: "Nie były ostatnio aktualizowane",
        notifications_marked_as_read: "Powiadomienie oznaczone jako przeczytane",
        cleared_alerts: "Wszystkie powiadomienia zostały wyczyszczone",
        alert_by_email:
          "Wyślij do mnie wiadomość e-mail w przypadku aktualizacji do nowej wersji dla dowolnej wymienionej poniżej zawartości lub aktualizacji w postaci nowej zawartości dla kolekcji",
        action_types: {
          create: "utworzono",
          update: "edytowano",
          delete: "usunięto",
          added_entity: "dodano wersję",
          removed_entity: "usunięto wersję",
          added_collection: "dodano zawartość",
          removed_collection: "usunięto zawartość",
        },
        property_names: {
          description: "Szczegóły",
          searchCriteria: "Informacje o kryteriach wyszukiwania",
          groupCategory: "Informacje o grupie i kategorii",
          support: "Informacje dot. pomocy technicznej",
          licenses: "Informacje o licencjach",
          relatedContent: "Informacje o powiązanej zawartości",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Zaktualizowane " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Preferencje dotyczące powiadomień zostały pomyślnie zaktualizowane",
          preferences_update_failed: "Wystąpił błąd podczas aktualizowania preferencji dotyczących powiadomień",
        },
      },
      browseDialog: {
        useNetworkDrive: "Użyj folderu lub dysku sieciowego",
        ownerGroups: {
          title: "Grupy właścicieli",
        },
      },
      itemTypeCategories: {
        all: "Wszystkie grupy",
        application: "Aplikacje",
        "3dProduct": "Produkty 3D",
        customComponent: "Komponenty użytkownika",
        tool: "Aplikacje",
        profile: "Profile",
        material: "Materiały",
        bolt: "Śruby",
        rebar: "Zbrojenie",
        reportTemplate: "Szablony raportu",
        drawingSetup: "Pliki ustawień rysunku",
        modelSetup: "Pliki ustawień modelu",
        environmentFile: "Pliki środowiska",
        shape: "Kształty",
        //'shapeCatalog': 'Katalog kształtów',
        //'foreignGeometryDefinition': 'Definicja obcej geometrii'
      },
      platforms: {
        WIN_X86: "32 bity",
        WIN_X64: "64 bity",
        ANY: "Platforma niezależna",
      },
      platformsDownload: {
        WIN_X86: "32 bit",
        WIN_X64: "64 bit",
        ANY: " ",
      },
      contentItemSource: {
        other: "Ogólny - do folderu modelu",
        run: "Uruchom narzędzie",
        bolt: "Zespół śrub",
        component: "Komponent",
        drawing: "Ustawienia rysunku (BETA)",
        material: "Materiał",
        profile: "Profil",
        mesh: "Siatka zbrojeniowa",
        rebar: "Pręt zbrojeniowy",
        shape: "Kształt",
        geometry: "Geometria",
        modeltemplate: "Szablon modelu",
        cloningtemplate: "Szablon klonowania",
        noaction: "Brak działania",
        releasenotes: "Informacje o wersji",
      },
      contentItemHeader: {
        bolt: "Zespoły śrub",
        component: "Komponenty",
        drawing: "Ustawienia rysunku (BETA)",
        material: "Materiały",
        profile: "Profile",
        mesh: "Siatki zbrojeniowe",
        rebar: "Pręty zbrojeniowe",
        shape: "Kształty",
      },
      useCategoryGroups: {
        workflow: "Proces pracy",
        structures: "Konstrukcje",
        drawings: "Rysunki",
        modeling: "Modelowanie",
      },
      useCategories: {
        title: "Użyty w/dla",
        conceptualDesign: "Projekt koncepcyjny",
        designEngineeringAnalysis: "Projekt / Inżynieria / Analiza",
        detailing: "Detalowanie",
        fabrication: "Produkcja",
        erectionInstallationPour: "Wznoszenie / Instalacja / Wylewanie",
        contracting: "Zamawianie",
        scheduling: "Planowanie",
        takeOffEstimation: "Przedmiar / Kosztorys",
        concreteStructures: "Konstrukcje monolityczne",
        precastConcrete: "Konstrukcje prefabrykowane",
        offshore: "Konstrukcje morskie",
        steelStructures: "Konstrukcje stalowe",
        timberStructures: "Konstrukcje drewniane",
        reinforcement: "Zbrojenie",
        drawingCreation: "Tworzenie rysunku",
        drawingManagement: "Zarządzanie rysunkiem",
        drawingEditing: "Edycja rysunku",
        drawingAnnotation: "Oznaczenia rysunku",
        viewSimulateNavigate: "Wyświetlanie, symulacja, nawigacja",
        compareReviewManageData: "Porównanie, ocena, zarządzanie danymi",
        softwareCollaboration: "Współpraca oprogramowania",
        productivity: "Produktywność",
        interoperability: "Wymiana danych",
        systemSetup: "Ustawienia systemu",
      },
      measurementUnits: {
        "0": "Metryczne",
        "1": "Brytyjskie",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "SketchUp",
        openBIM: "Open BIM (IFC)",
        standalone: "Aplikacja samodzielna",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Niezwiązane z wersją",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'SketchUp 7',
        //'SK1': 'SketchUp 8'
      },
      qualityTags: {
        experimental: "Eksperymentalny",
        candidate: "Kandydat",
        approved: "Zatwierdzony",
        standard: "Standard",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Język angielski",
        "zh-Hans": "Język chiński uproszczony",
        "zh-Hant": "Język chiński tradycyjny",
        cs: "Język czeski",
        da: "Język duński",
        nl: "Język holenderski",
        fi: "Język fiński",
        fr: "Język francuski",
        de: "Język niemiecki",
        hu: "Język węgierski",
        it: "Język włoski",
        ja: "Język japoński",
        ko: "Język koreański",
        pl: "Język polski",
        pt: "Język portugalski",
        "pt-br": "Język portugalski - brazylijski",
        ru: "Język rosyjski",
        es: "Język hiszpański",
        sv: "Język szwedzki",
        no: "Język norweski",
        other: "Inny",
      },
      isoLangs: {
        ab: {
          name: "Język abchaski",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Język afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Język afrikaans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Język akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Język albański",
          nativeName: "Shqip",
        },
        am: {
          name: "Język amharski",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Język arabski",
          nativeName: "العربية",
        },
        an: {
          name: "Język aragoński",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Język armeński",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Język asamski",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Język awarski",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Język awestyjski",
          nativeName: "avesta",
        },
        ay: {
          name: "Język ajmara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Język azerski",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Język bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Język baszkirski",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Język baskijski",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Język białoruski",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Język bengalski",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Język bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Język bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Język bośniacki",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Język bretoński",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Język bułgarski",
          nativeName: "български език",
        },
        my: {
          name: "Język birmański",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Język kataloński; walencki",
          nativeName: "Català",
        },
        ch: {
          name: "Język czamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Język czeczeński",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Język cziczewa; czewa; nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chiński, uproszczony i tradycyjny",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Język chiński uproszczony",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Język chiński tradycyjny",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Język chiński tajwański",
          nativeName: "國語",
        },
        cv: {
          name: "Język czuwaski",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Język kornijski",
          nativeName: "Kernewek",
        },
        co: {
          name: "Język korsykański",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Język kri",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Język chorwacki",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Język czeski",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Język duński",
          nativeName: "dansk",
        },
        dv: {
          name: "Język malediwski; divehi",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Język holenderski",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Język angielski",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Język estoński",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Język ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Język farerski",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Język fidżyjski",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Język fiński",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Język francuski",
          nativeName: "Français",
        },
        ff: {
          name: "Język ful; fulah; pular",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Język galicyjski",
          nativeName: "Galego",
        },
        ka: {
          name: "Język gruziński",
          nativeName: "ქართული",
        },
        de: {
          name: "Język niemiecki",
          nativeName: "Deutsch",
        },
        el: {
          name: "Język nowogrecki",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Język guarani",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Język gudżarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Język kreolski haitański",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Język hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Język nowohebrajski",
          nativeName: "עברית",
        },
        hz: {
          name: "Język herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Język hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Język węgierski",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Język indonezyjski",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Occidental",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Język irlandzki",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Język igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Język inupiak",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Język islandzki",
          nativeName: "Íslenska",
        },
        it: {
          name: "Język włoski",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Język japoński",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Język jawajski",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Język grenlandzki",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Język kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Język kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Język kaszmirski",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Język kazachski",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Język khmerski",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Język kikuju",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Język ruanda-rundi",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Język kirgiski",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Język komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Język kongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Język koreański",
          nativeName: "한국어",
        },
        ku: {
          name: "Język kurdyjski",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Język kwanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Łacina",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Język luksemburski",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Język luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Język limburski",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Język lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Język laotański",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Język litewski",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Język luba-katanga",
          nativeName: "",
        },
        lv: {
          name: "Język łotewski",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Język manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Język macedoński",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Język malagaski",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Język malajski",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Język malajalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Język maltański",
          nativeName: "Malti",
        },
        mi: {
          name: "Język maori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Język marathi",
          nativeName: "मराठी",
        },
        mh: {
          name: "Język marszalski",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Język mongolski",
          nativeName: "монгол",
        },
        na: {
          name: "Język naurański",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Język navajo, navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Język norweski, Bokmål",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Język ndbele północny",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Język nepalski",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Język ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Język norweski Nynorsk",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Język norweski",
          nativeName: "Norsk",
        },
        ii: {
          name: "Język nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Język ndbele południowy",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Język oksytański",
          nativeName: "Occitan",
        },
        oj: {
          name: "Język odżibwe",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Język staro-cerkiewno-słowiański",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Język oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Język orija",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Język osetyjski",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Język pendżabski",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Język pali",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Język perski",
          nativeName: "فارسی",
        },
        pl: {
          name: "Język polski",
          nativeName: "polski",
        },
        ps: {
          name: "Język paszto",
          nativeName: "پښتو",
        },
        pt: {
          name: "Język portugalski",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Język portugalski - brazylijski",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Język keczua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Język romansz",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Język rundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Język rumuński, mołdawski",
          nativeName: "română",
        },
        ru: {
          name: "Język rosyjski",
          nativeName: "русский",
        },
        sa: {
          name: "Sanskryt",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Język sardyński",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Język północnolapoński",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Język samoański",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Język sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Język serbski",
          nativeName: "српски језик",
        },
        gd: {
          name: "Język gaelicki szkocki; gaelicki",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Język shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Język syngaleski",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Język słowacki",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Język słoweński",
          nativeName: "slovenščina",
        },
        so: {
          name: "Język somalijski",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Język sotho południowy",
          nativeName: "Sesotho",
        },
        es: {
          name: "Język hiszpański", //      'name':'tradycyjny / język hiszpański',
          nativeName: "español",
        },
        su: {
          name: "Język sundajski",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Język suahili",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Język suazi",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Język szwedzki",
          nativeName: "svenska",
        },
        ta: {
          name: "Język tamilski",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Język telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Język tadżycki",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Język tajski",
          nativeName: "ไทย",
        },
        ti: {
          name: "Język tigrinia",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Język tybetański, tybetański centralny",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Język turkmeński",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Język tagalski",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Język tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Język tonga (polinezyjski)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Język turecki",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Język tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Język tatarski",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Język twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Język tahitański",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Język ujgurski",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Język ukraiński",
          nativeName: "українська",
        },
        ur: {
          name: "Język urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Język uzbecki",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Język venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Język wietnamski",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Język waloński",
          nativeName: "Walon",
        },
        cy: {
          name: "Język walijski",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Język wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Język zachodniofryzyjski",
          nativeName: "Frysk",
        },
        xh: {
          name: "Język xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Język jidysz",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Język joruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Język zhuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Inny",
          nativeName: "Inny",
        },
      },

      details: {
        navigation: {
          content_information: "Informacja o zawartości",
          version_and_file_management: "Zarządzanie wersją i plikiem",
          details: "Szczegóły",
          versions: "Wersje",
          analytics: "Statystyki",
          statistics: "Statystyka",
          manage_access: "Zarządzaj dostępem",
          related_content: "Powiązane",
          properties: "Właściwości",
          support: "Firma i pomoc techniczna",
          release_notes: "Uwagi dotyczące nowej wersji",
          comments: "Komentarze użytkownika",
        },
        related_content: {
          other_content_title: "Inna zawartość, która może Cię interesować",
          related_content_title: "Zawartość rekomendowana przez ",
          related_content_url: "Adres URL zawartości",
          add_related_content: "Dodaj powiązaną zawartość",
          add_related_content_info:
            "Do zawartości online można dodać tylko powiązaną zawartość online, a do zawartości lokalnej tylko zawartość lokalną.",
          remove_related_content: "Usuń",
          relatedContentUrlSubtitle: "ID zawartości lub pełny adres URL zawartości",
          updated_successfully: "Powiązana zawartość została zaktualizowana",
          update_failed: "Błąd podczas aktualizacji powiązanej zawartości",
          update_failed_invalid_id: "Podane ID zawartości jest nieprawidłowe",
        },
        actions: {
          move: "Przesuń",
          delete: "Usuń",
          add_comment: "Dodaj komentarz",
          add_reply: "Dodaj odpowiedź",
          edit_comment: "Edytuj komentarz",
          reply_comment: "Dodaj odpowiedź",
          view_replies: "Pokaż odpowiedzi",
          report_bad_comment: "Zgłoś niewłaściwy komentarz &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Edytuj lub usuń tytuł",
            title_or_move_delete: "Edytuj, przesuń lub usuń tytuł",
            thumbnail: "Edytuj miniaturę",
            description: "Edytuj opis",
            groupandcategory: "Edytuj grupę i kategorię",
            details: "Edytuj szczegóły",
            versions: "Edytuj wersje",
            support: "Edytuj informacje nt. pomocy technicznej",
            release_notes: "Edytuj uwagi dotyczące nowej wersji",
            search_criteria: "Edytuj kryteria wyszukiwania",
            batchedit_and_translations:
              "Funkcja tłumaczenia umożliwia publikowanie zawartości w wielu językach. Przetłumaczona wersja zawartości będzie wyświetlana użytkownikom na podstawie języka wybranego w interfejsie użytkownika Warehouse. Edycja grupy umożliwia równoczesną edycję wielu elementów. Najpierw wybierz pole, a następnie elementy, które chcesz edytować",
          },
        },
        links: {
          link: "Połącz",
          select_collection_placeholder: "Wybierz kolekcję do połączenia",
          linking_collections_label: "Kolekcje połączone",
          private_content_note:
            "Uwaga: Prywatna zawartość będzie widoczna tylko dla użytkowników, którzy mają do niej dostęp.",
          share_on_social_media: "Udostępnij:",
          report_bad_content: "Zgłoś nieprawidłową zawartość &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Zgłoś treści niezgodne z prawem &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Subskrybując powiadomienia, zgadzasz się udostępnić właścicielowi zawartości swoją nazwę wyświetlaną i nazwę organizacji w koncie Tekla Account (jeśli ma to zastosowanie).",
          alert_me: "Powiadom mnie",
          unsubscribe: "Zrezygnuj z subskrypcji",
          subscribed_successfully: "Zasubskrybowano",
          unsubscribed_successfully: "Zrezygnowano z subskrypcji",
          subscribers_label: "Subskrybenci",
        },
        reviews: {
          reviewers_label: "Recenzenci",
        },
        comments: {
          replies: "Odpowiedzi",
        },
        scan: {
          soft_failed_binaries_info: "Nie można przeskanować wirusów  ",
          hard_failed_binaries_info: "Nie można przeprowadzić skanowania wirusów ",
          infected_binaries_info: "Podczas skanowania wirusów wykryto infekcję ",
          what_now: "Co teraz?",
          popup: {
            soft_failed_binaries_title: "Pliki binarne - niepowodzenie",
            hard_failed_binaries_title: "Pliki binarne - niepowodzenie",
            infected_binaries_title: "Zainfekowane pliki binarne",
            soft_failed_binaries_info:
              "Nie można przeskanować wirusów w przesłanych plikach. To jest tylko tymczasowa sytuacja. Usuń pliki je i prześlij je ponownie. Wszystkie pliki muszą zostać przeskanowane pod kątem wirusów, zanim staną się widoczne dla innych użytkowników.",
            hard_failed_binaries_info:
              "Nie można przeprowadzić skanowania wirusów. Usuń pliki. Wszystkie pliki muszą zostać przeskanowane pod kątem wirusów, zanim staną się widoczne dla innych użytkowników.",
            infected_binaries_info:
              "W czasie skanowania wirusów wśród przesłanych plików wykryto zainfekowane. Usuń pliki. Wszystkie pliki muszą zostać przeskanowane pod kątem wirusów, zanim staną się widoczne dla innych użytkowników.",
            version: "Wersja: ",
            fileName: "nazwa pliku: ",
          },
        },
        status: {
          content_banned_info:
            "Zawartość została zablokowana przez administratora. Obecnie nie jest ona dostępna dla innych użytkowników z wyjątkiem właściciela zawartości. <a href='https://support.tekla.com/contact-us' target='_blank'>Skontaktuj się z pomocą techniczną</a>, aby uzyskać więcej informacji.",
          content_archived_info:
            "Ta zawartość została zarchiwizowana. Użytkownicy bez uprawnień do edycji zostaną przekierowani na stronę główną, gdy spróbują uzyskać dostęp do zawartości za pomocą bezpośredniego łącza.",
          archived: "Zarchiwizowane",
        },
        modified_by: function (name) {
          return " według " + name;
        },
        created_by: function (name) {
          return "Utworzone przez " + name;
        },
        deleteEntity: function (title) {
          return "Usuń " + title + "?";
        },
        userComments: "Komentarze użytkownika",
        comment_added: "Pomyślnie dodano komentarz",
        comment_updated: "Komentarz został zaktualizowany",
        comment_deleted: "Pomyślnie usunięto komentarz",
        comment_add_failed: "Niepowodzenie dodawania komentarza",
        comment_update_failed: "Niepowodzenie aktualizacji komentarza",
        comment_delete_failed: "Niepowodzenie usuwania komentarza",
        comment_delete_confirmation: "Czy usunąć element komentarza?",
        name: "Nazwa",
        details: "Szczegóły",
        contentOwner: "Autor",
        contentOwnerGroup: "Grupa właścicieli zawartości",
        contentGroup: "Grupa zawartości",
        thumbnail: "Miniatura",
        tooltip: "Podpowiedź",
        description: "Opis",
        additional_information: "Dodatkowe informacje",
        images: "Obrazy produktu",
        installationInfo: "Informacje o instalacji",
        productCode: "Kod produktu",
        copyrightInfo: "Prawa autorskie",
        productExpirationDate: "Data wygaśnięcia produktu",
        helpUrl: "Strona internetowa &rsaquo;",
        supportUrl: "Pomoc techniczna &rsaquo;",
        specialTermsUrl: "Umowa EULA &rsaquo;",
        releaseNotesUrl: "Informacje o wersji",
        supportForumUrl: "Forum dyskusyjne &rsaquo;",
        itemTypeCategories: "Grupa",
        useCategories: "Kategoria",
        category: "Kategoria:",
        group: "Grupa:",
        compatibleSoftwareProducts: "Kompatybilne oprogramowanie",
        compatibleOperatingSystems: "Kompatybilne systemy operacyjne",
        locationRestrictions: "Dostępność produktu:",
        supportedLanguages: "Języki:",
        measurementUnits: "Jednostki miary:",
        tags: "Etykiety i słowa kluczowe:",
        prerequisites: "Wymagania wstępne:",
        releaseNotes: "Uwagi dotyczące wersji:",
        versions: "Wersje",
        testedVersions: "Kompatybilne wersje",
        qualityTag: "Jakość - oceniana przez właściciela",
        otherAttributes: "Inne atrybuty",
        labels: "Kryteria wyszukiwania",
        supportInformation: "Pomoc techniczna",
        updateInformation: "Aktualizacje",
        latestUpdate: "Ostatnia aktualizacja",
        totalDownloads: "Całkowita liczba pobrań",
        licenses: "Licencje",
        maintenanceRequired: "Pobieranie wymaga Tekla Maintenance",
        tekla_maintenance_content: "Zawartość Tekla Maintenence",
        download_requires_tekla_maintenance: "Pobieranie wymaga Tekla Maintenance",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Zaktualizowano",
        update_failed: "Nieudana aktualizacja",
        update_failed_invalid_tag: "Nieudana aktualizacja: nieprawidłowa etykieta",
        update_failed_details_max_length_exceeded: "Nieudana aktualizacja: szczegóły za długie",
        show_version_details: "Więcej informacji",
        content_removed: "Usunięto zawartość",
        no_versions_available:
          "Brak dostępnych wersji. Aktualizacje mogą pojawić się po chwili. Aby zobaczyć zmiany, odśwież przeglądarkę.",
        thumbnail_deleted: "Usunięto miniaturę",
        thumbnail_updated: "Zaktualizowano miniaturę",
        verify_service_plugin_is_running: "Zweryfikuj, czy dodatek Tekla Warehouse Service działa prawidłowo",
        verify_ts_connection: "Sprawdź, czy Tekla Structures jest uruchomiona",
        download_tos: {
          title: "Jakość, pomoc i prywatność",
          content_1: "Upewnij się, że pobierana zawartość odpowiada wymaganym standardom jakości. Trimble nie świadczy pomocy technicznej i nie ponosi odpowiedzialności za zawartość innych firm. W celu uzyskania pomocy skontaktuj się bezpośrednio z dostawcą zawartości.",
          content_2: "Informacja o kraju i nazwie organizacji mogą zostać opublikowane w usłudze i ujawnione właścicielom zawartości w uzasadnionych celach, takich jak dostarczanie informacji o zmianach zawartości. Możesz zrezygnować z subskrypcji tych powiadomień.",
          content_3: 'W przypadku rozszerzeń treści Trimble obowiązują <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">warunki Trimble</a>. Warunki licencyjne stron trzecich mogą mieć zastosowanie do treści stron trzecich. Zobacz <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Informację o Prywatności Trimble</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Bezpłatny magazyn BIM dla Tekla Structures. Wyszukaj, importuj, instaluj i udostępniaj wewnętrznie i globalnie. Wydajne tworzenie modeli wysokiej jakości.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse jest bezpłatną, dostępną w trybie online i lokalnie biblioteką narzędzi modelowania, inteligentnych elementów oraz szablonów Tekla Structures.",
        },
        search: {
          title: "Szukaj w Tekla Warehouse",
          description:
            "Szukaj rozszerzeń Tekla, elementów 3D, kształtów, komponentów użytkownika, profili, materiałów takich jak stal i klasy betonu, pręty zbrojeniowe, siatki i szablony",
        },
        about: {
          title: "Informacja o Tekla Warehouse",
          description:
            "Tekla Warehouse oferuje bezpłatny kanał marketingowy dla produktów i zapewnia pełną kontrolę nad zawartością",
          developers: {
            title: "Domyślny tytuł dewelopera",
            description: "Domyślny opis dewelopera",
          },
          usage: {
            title: "Domyślny tytuł wykorzystania",
            description: "Domyślny opis wykorzystania",
          },
          getting_started: {
            title: "Domyślny tytuł wprowadzenia",
            description: "Domyślny opis wprowadzenia",
          },
          content: {
            title: "Domyślny tytuł zawartości",
            description: "Domyślny opis zawartości",
          },
          using_content: {
            title: "Domyślny tytuł zawartości",
            description: "Domyślny opis zawartości",
          },
          creating_content: {
            title: "Domyślny tytuł tworzenia zawartości",
            description: "Domyślny opis tworzenia zawartości",
          },
        },
        collection: {
          title: "Domyślny tytuł kolekcji",
          description: "Domyślny opis kolekcji",
        },
        my_warehouse: {
          title: "Mój Tekla Warehouse",
          description: "Zarządzaj swoją zawartością Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Anuluj",
          previous: "&lsaquo; Wstecz",
          next: "Dalej &rsaquo;",
        },
        content: {
          add_content: "Dodaj pliki",
        },
        collection: {
          heading: "Wybierz lub utwórz kolekcję",
          subHeading:
            "Zawartość grupy kolekcji. Możesz użyć kolekcji online, aby opublikować swoją zawartość lub kolekcje lokalne w celu udostępnienia zawartości kolegom. Możesz też użyć kolekcji lokalnych do celów prywatnych.",
          loading: "Wczytywanie kolekcji...",
          selectFromMyCollections: "Wybierz z Moich kolekcji",
          description_of_the_collection: "Opis",
          title_validation: {
            missing: "Wprowadź tytuł.",
            too_short: function (length) {
              return "Tytuł powinien mieć co najmniej " + length + " znaków.";
            },
            too_long: function (length) {
              return "Tytuł nie może mieć więcej niż " + length + " znaków.";
            },
          },
        },
        package: {
          basic_information: "Podstawowe informacje",
          code_name: "Nazwa kodowa",
          code_name_info: "Wprowadź nazwę kodową jako niepowtarzalny identyfikator tej zawartości. Nazwa kodowa może zawierać małe litery, cyfry i następujące znaki specjalne: _ - @. Po zapisaniu nazwy kodowej nie można jej zmienić. ",
          code_name_validation: {
            missing: "Należy wprowadzić nazwę kodową",
            wrong_format: "Nazwa kodowa może zawierać małe litery, cyfry oraz następujące znaki specjalne: _ - @ ",
            not_unique: "Ta nazwa kodowa jest już w użyciu. Należy wprowadzić niepowtarzalną nazwę kodową.",
          },
          search_criteria: "Kryteria wyszukiwania",
          support: "Pomoc techniczna",
          title: "Tytuł",
          thumbnail: "Miniatura",
          "3D_pic": "Model 3D",
          title_validation: {
            missing: "Wprowadź tytuł.",
            too_short: function (length) {
              return "Tytuł powinien mieć co najmniej " + length + " znaków.";
            },
          },
          description_validation: {
            missing: "Wprowadź opis.",
            too_short: function (length) {
              return "Opis powinien mieć co najmniej " + length + " znaków.";
            },
          },
          wrong_file_type: "Nieprawidłowy typ pliku.",
          selected_image_is_too_big: "Wybrany obraz jest zbyt duży.",
        },
        version: {
          select_version: "Wybierz wersję",
          select_quality: "Wybierz jakość",
          versions: "Wersje",
          displayNameNew: "Wyświetlana nazwa wersji",
          copy_from_version: "Skopiuj metadane z istniejącej wersji (opcjonalne)",
          displayName: "Nazwa wyświetlana",
          content_information: "Informacja o zawartości",
          back_to_content_information: "&lsaquo; Powrót do informacji o zawartości",
          version_and_file_management: "Zarządzanie wersją i plikiem",
          there_s_no_versions_yet: "Wersja jeszcze nie istnieje.",
          create_a_version_to_add_files: "Utwórz wersję, aby dodać pliki.",
          add_version: "Dodaj wersję",
          version_information: "Informacja o wersji",
          delete_version: "Usuń wersję",
          no_versions_available: "Brak dostępnych wersji. Aktualizacje mogą pojawić się po chwili.",
          update_start_title_html: '<div class="spinner spinner-white">Uaktualnianie</div>',
          update_start_message: "To może chwilę potrwać.",
          version_was_created: "Utworzono wersję.",
          version_was_created_with_delay: "Utworzono wersję. Zmiany mogą być widoczne po kilku minutach.",
          version_was_updated: "Wersja została zaktualizowana.",
          version_was_deleted: "Wersja została usunięta",
          version_was_deleted_with_delay: "Wersja została usunięta. Zmiany mogą być widoczne po kilku minutach.",
          confirm_delete: function (title) {
            return "Usunąć wersję '" + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Pakiet został przesłany.",
          couldn_t_create_a_package: "Nie udało się utworzyć pakietu. Zmiany zostaną cofnięte.",
          uploading_title_html: '<div class="spinner spinner-white">Przesyłanie</div>',
          uploading_text: "To może chwilę potrwać.",
          select_organization: "Wybierz organizację",
          collection_visibility: "Widoczność kolekcji:",
          actions: {
            create: "Utwórz",
            publish: "Publikuj",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Zawartość będzie widoczna dla wszystkich użytkowników, którzy mają dostęp do lokalnego lub sieciowego dysku.",
          this_content_will_be_visible_only_to_you: "Zawartość będzie widoczna tylko dla Ciebie.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Zawartość będzie widoczna tylko dla Ciebie i wszystkich osób w Twojej organizacji.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Zawartość będzie widoczna dla wszystkich po opublikowaniu.",
          public_content_disclaimer:
            "Odpowiedzialność za przesłaną przez Ciebie zawartość spoczywa na Tobie. Upewnij się, że zawartość nie jest szkodliwa, posiadasz uprawnienia do jej przesłania oraz nie narusza praw własności intelektualnej. Zgadzasz się na zamieszczenie w usłudze wszelkich zasad i warunków, które mają zastosowanie do zawartości przesyłanych przez Ciebie.",
        },
        metadata: {
          title: "Metadane",
          subtitle: "Dane podstawowe",
          deleteThumbnail: "Usuń miniaturę",
          buttonCreate: "Utwórz",
        },
        basicdata: {
          title: "Dodaj zawartość",
          clickButtonToSelectFile: "Kliknij przycisk, aby wybrać plik",
          thumbnail: "Miniatura",
          dropImageHere: "Upuść obraz tutaj",
        },
        "3dGeometry": {
          title: "Utwórz katalog produktów 3D",
          buttonAddEntity: "Dodaj produkt 3D",
          buttonAdd: "Dodaj atrybut niestandardowy",
          errorAttributeAlreadyExists: "Atrybut o tej samej nazwie już istnieje.",
          buttonCreatePackage: "Utwórz pakiet",
          buttonCreatePackages: "Utwórz pakiety",
          addCustomAttributePlaceholder: "Nazwa atrybutu",
        },
        please_log_in_fragments: {
          please: "Proszę ",
          log_in: "Zaloguj się",
          or: " lub ",
          register: "zarejestrować,",
          to_upload_online_content: " aby przesłać zawartość online.",
        },
        header: {
          upload_header: "Prześlij",
          collection: "Kolekcja",
          content: "Zawartość",
          version: "Wersja",
          finish: "Zakończ",
        },
        contentOwner: "Autor",
        contentOwnerGroup: "Publikuj zawartość",
        select_action: "Wybierz działanie",
        tooltip: "Podpowiedź",
        description: "Opis",
        copyright: "Copyright",
        tags: "Etykiety i słowa kluczowe",
        tagsSubtitle:
          "Rozdziel przecinkiem, na przykład: słowa_kluczowe1, słowa_kluczowe2. Jeśli słowo kluczowe składa się wielu wyrazów, użyj podkreślenia (_) między wyrazami. Spacje między wyrazami są niedozwolone. Oprócz znaku pokreślenia i przecinka nie są dozwolone inne znaki specjalne.",
        images: "Obrazy",
        installationInfo: "Informacje o instalacji",
        productCode: "Kod produktu",
        copyrightInfo: "Informacje o prawach autorskich",
        Details: "Szczegóły",
        specialTermsUrl: "Umowa EULA",
        productExpirationDate: "Data wygaśnięcia produktu",
        helpUrl: "Strona internetowa",
        helpUrlSubtitle: "wstaw pełny adres URL",
        detailsSubtitle:
          "Na tym etapie do pola Szczegóły można dodać tylko zwykły tekst. Po utworzeniu zawartości można edytować Szczegóły, aby wprowadzić formatowanie html.",
        specialTermsUrlSubtitle:
          "Właściciel zawartości może dodać własne warunki dotyczące jego zawartości. W tym celu powinien podać adres URL strony z tymi szczególnymi warunkami. Nie powoduje to unieważnienia warunków korzystania z usług Trimble.",
        releaseNotes: "Plik informacji o wersji",
        releaseNoteFileSubTitle: "Dodaj informacje o wersji jako plik",
        supportUrl: "Pomoc i wsparcie",
        supportUrlSubtitle: "wprowadź pełny adres URL lub przedrostek „mailto:”, aby wysłać e-mail",
        supportForumUrl: "Forum dyskusyjne (strona internetowa)",
        supportForumUrlSubtitle: "wstaw pełny adres URL",
        measurementUnits: "Jednostki miary",
        itemTypeCategories: "Grupa",
        useCategories: "Kategoria",
        compatibleSoftwareProducts: "Kompatybilne oprogramowanie",
        testedVersions: "Kompatybilne wersje",
        compatibleOperatingSystems: "Kompatybilny system operacyjny",
        locationRestrictions: "Dostępność produktu",
        supportedLanguages: "Języki",
        select_releasenotes_file: "Wybierz plik",
        qualityTag: "Jakość - oceniana przez właściciela",
        requireMaintenance: "Pobieranie wymaga Tekla maintenance",
        contentOwnerGroupAsMyself: "Jako ja",
        contentOwnerGroupAsUserGroup: "Jako grupa użytkowników",
        selectUserGroupForContentOwner: "Wybierz grupę użytkowników dla właściciela zawartości",
        defaultUploadTemplate: "Domyślny pakiet",
        templateFor3DGeometryFiles: "Pakiety geometrii 3D",
        title: "Tytuł",
        details: "Szczegóły",
        selectContentOwner: "Wybierz właściciela zawartości",
        selectContentType: "Typ",
        errorWrongfiletype: "Nieprawidłowy typ pliku.",
        errorImageTooBig: "Wybrany obraz jest zbyt duży.",
        errorBlankTitle: "Tytuł nie może być pusty.",
        commonLabelsTitle: "Takie same etykiety dla wszystkich pakietów",
      },
      profile: {
        content_usage: "Użycie zawartości",
        profile_updated: "Profil zaktualizowany",
        invitation_accepted: function (target) {
          return "Zaproszenie zaakceptowane! Teraz masz dostęp do " + target + ".";
        },
        user_groups: {
          edit_user_group: "Edytuj grupę użytkownika",
          user_group_updated: "Aktualizuj grupę użytkownika",
          create_a_new_user_group: "Utwórz nową grupę użytkownika",
          name_of_the_user_group: "Nazwij grupę użytkownika",
          description: "Opis",
          group_visibility: "Widoczność grupy",
          remove: "Czy na pewno?",
          remove_invitations: "Czy na pewno?",
          remove_members: "Czy na pewno?",
          invitations_removed: "Usunięto zaproszenia!",
          members_list_updated: "Zaktualizowano listę członków!",
          user_group_created: "Utworzono grupę użytkowników",
        },
        members: {
          update_selected_members: "Aktualizuj wybranych członków",
          new_role: "Nowa rola",
          members_list_updated: "Zaktualizowano listę członków!",
          edit_user_group: "Edytuj grupę użytkowników",
          delete_user_group: "Usuń grupę użytkowników",
          filter: "Filtr",
          user_group_members: "Członkowie grupy użytkowników",
          update_members: "Aktualizuj członków",
          remove_members: "Usuń członków",
          pending_invitations: "Zaproszenia oczekujące",
          invite_member: "Zaproś członka",
          update_invitations: "Aktualizuj zaproszenia",
          remove_invitations: "Usuń zaproszenia",
        },
        subTabs: {
          alerts: "Moje powiadomienia",
          collections: "Kolekcje",
          likes: "Polubienia",
        },
        admin: {
          grant_sales_admin: "Przyznaj rolę administratora sprzedaży użytkownikowi",
          grant_analyst_role: "Przyznaj użytkownikowi rolę analityka",
          role_granted: "Rola została przyznana",
          role_removed: "Rola została odebrana",
          offline_to_online: "Konwertuj zawartość offline na online",
          use_elastic_search: "Użyj elastycznego wyszukiwania",
        },
        sales: {
          title: "Sprzedaż",
        },
        subtitlePreferences: "Preferencje wyszukiwania",
        preferenceInfo:
          'Zadaniem tych filtrów jest wybór polecanej zawartości na <a href="#/landing">stronie domowej</a> Tekla Warehouse.',
        clearFilters: "Wyczyść filtry &rsaquo;",
        admin_role: "Administrator",
        sales_role: "Sprzedaż",
        admin_mode: "Tryb administratora:",
        firstName: "Imię",
        lastName: "Nazwisko",
        displayName: "Nazwa wyświetlana:",
        organization: "Nazwa organizacji:",
        email: "E-mail:",
        teklaId: "Tekla ID",
        maintenanceAccess: "Dostęp do zawartości Tekla Maintenance:",
        myLocations: "Lokalizacje projektu",
        measurementUnits: "Jednostki",
        useCategories: "Zastosuj",
        itemTypeCategories: "Interesują mnie następujące typy zawartości",
        languages: "Języki",
        operatingSystems: "Systemy operacyjne",
        software: "Oprogramowanie",
        user_id: "ID użytkownika:",
        organization_id: "ID organizacji:",
        edit_in_atc: "Edytuj mój profil w Tekla Account",
        no_likes: "Brak polubień",
        no_alerts: "Brak subskrypcji",
        clear_alerts: "Wyczyść powiadomienia",
        alerts_info_html:
          "Otrzymasz powiadomienie, gdy zostaną wprowadzone następujące zmiany<br>" +
          "<b>Zawartość:</b> Tytuł, opis, kryteria wyszukiwania lub metadane elementu zawartości są modyfikowane, dodawana jest nowa wersja zawartości lub wersja jest usuwana.<br>" +
          "<b>Kolekcja:</b> Tytuł lub opis są edytowane, zawartość jest dodawana lub usuwana.",
      },
      uploader: {
        placeholders: {
          productCode: "Kod produktu",
          title: "Nazwa",
          description: "Opis",
          productWeight: "Ciężar produktu",
          weightUnit: "Jednostka wagi",
          material: "Materiał",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Katalog Produktów 3D",
          addToolButton: "Aplikacja",
          select_files: "Pliki z dysku",
          select_files_from_ts: "Pliki z Tekla Structures",
          partnerDownloadLink: "Partnerskie łącze pobierania",
        },
        headings: {
          geometryFile: "Plik geometrii",
          thumbnailFile: "Bitmapa miniatury (.bmp)",
          title: "Nazwa",
          description: "Opis",
          productCode: "Kod produktu",
          productWeight: "Ciężar produktu",
          weightUnit: "Jednostka ciężaru",
          material: "Materiał",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Globalny",
          AF: "Afganistan",
          AX: "Wyspy Alandzkie",
          AL: "Albania",
          DZ: "Algieria",
          AS: "Samoa Amerykańskie",
          AD: "Andora",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarktyka",
          AG: "Antigua i Barbuda",
          AR: "Argentyna",
          AM: "Armenia",
          AW: "Aruba",
          AU: "Australia",
          AT: "Austria",
          AZ: "Azerbejdżan",
          BS: "Bahamy",
          BH: "Bahrajn",
          BD: "Bangladesz",
          BB: "Barbados",
          BY: "Białoruś",
          BE: "Belgia",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermudy",
          BT: "Bhutan",
          BO: "Boliwia",
          BQ: "Bonaire",
          BA: "Bośnia i Hercegowina",
          BW: "Botswana",
          BV: "Wyspa Bouveta",
          BR: "Brazylia",
          IO: "Brytyjskie Terytorium Oceanu Indyjskiego",
          BN: "Brunei",
          BG: "Bułgaria",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Kambodża",
          CM: "Kamerun",
          CA: "Kanada",
          CV: "Republika Zielonego Przylądka",
          KY: "Kajmany",
          CF: "Republika Środkowoafrykańska",
          TD: "Czad",
          CL: "Chile",
          CN: "Chiny",
          CX: "Wyspa Bożego Narodzenia",
          CC: "Wyspy Kokosowe",
          CO: "Kolumbia",
          KM: "Komory",
          CG: "Kongo",
          CD: "Demokratyczna Republika Konga",
          CK: "Wyspy Cooka",
          CR: "Kostaryka",
          CI: "Wybrzeże Kości Słoniowej",
          HR: "Chorwacja",
          CU: "Kuba",
          CW: "Curaçao",
          CY: "Cypr",
          CZ: "Czechy",
          DK: "Dania",
          DJ: "Dżibuti",
          DM: "Dominika",
          DO: "Dominikana",
          EC: "Ekwador",
          EG: "Egipt",
          SV: "Salwador",
          GQ: "Gwinea Równikowa",
          ER: "Erytrea",
          EE: "Estonia",
          ET: "Etiopia",
          FK: "Falklandy",
          FO: "Wyspy Owcze",
          FJ: "Fidżi",
          FI: "Finlandia",
          FR: "Francja",
          GF: "Gujana Francuska",
          PF: "Polinezja Francuska",
          TF: "Francuskie Terytoria Południowe i Antarktyczne",
          GA: "Gabon",
          GM: "Gambia",
          GE: "Gruzja",
          DE: "Niemcy",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Grecja",
          GL: "Grenlandia",
          GD: "Grenada",
          GP: "Gwadelupa",
          GU: "Guam",
          GT: "Gwatemala",
          GG: "Guernsey",
          GN: "Gwinea",
          GW: "Gwinea Bissau",
          GY: "Gujana",
          HT: "Haiti",
          HM: "Wyspy Heard i McDonalda",
          VA: "Watykan",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Węgry",
          IS: "Islandia",
          IN: "Indie",
          ID: "Indonezja",
          IR: "Iran",
          IQ: "Irak",
          IE: "Irlandia",
          IM: "Wyspa Man",
          IL: "Izrael",
          IT: "Włochy",
          JM: "Jamajka",
          JP: "Japonia",
          JE: "Jersey",
          JO: "Jordania",
          KZ: "Kazachstan",
          KE: "Kenia",
          KI: "Kiribati",
          KW: "Kuwejt",
          KG: "Kirgistan",
          LA: "Laos",
          LV: "Łotwa",
          LB: "Liban",
          LS: "Lesotho",
          LR: "Liberia",
          LY: "Libia",
          LI: "Liechtenstein",
          LT: "Litwa",
          LU: "Luksemburg",
          MO: "Makau",
          MK: "Macedonia",
          MG: "Madagaskar",
          MW: "Malawi",
          MY: "Malezja",
          MV: "Malediwy",
          ML: "Mali",
          MT: "Malta",
          MH: "Wyspy Marshalla",
          MQ: "Martynika",
          MR: "Mauretania",
          MU: "Mauritius",
          YT: "Majotta",
          MX: "Meksyk",
          FM: "Mikronezja",
          MD: "Mołdawia",
          MC: "Monako",
          MN: "Mongolia",
          ME: "Czarnogóra",
          MS: "Montserrat",
          MA: "Maroko",
          MZ: "Mozambik",
          MM: "Mjanma (Birma)",
          NA: "Namibia",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Holandia",
          NC: "Nowa Kaledonia",
          NZ: "Nowa Zelandia",
          NI: "Nikaragua",
          NE: "Niger",
          NG: "Nigeria",
          NU: "Niue",
          NF: "Norfolk",
          KP: "Korea Północna",
          MP: "Mariany Północne",
          NO: "Norwegia",
          OM: "Oman",
          PK: "Pakistan",
          PW: "Palau",
          PS: "Terytoria Palestyńskie",
          PA: "Panama",
          PG: "Papua-Nowa Gwinea",
          PY: "Paragwaj",
          PE: "Peru",
          PH: "Filipiny",
          PN: "Pitcairn",
          PL: "Polska",
          PT: "Portugalia",
          PR: "Portoryko",
          QA: "Katar",
          RE: "Reunion",
          RO: "Rumunia",
          RU: "Federacja Rosyjska",
          RW: "Rwanda",
          BL: "Saint-Barthélemy",
          SH: "Wyspa Świętej Heleny",
          KN: "Saint Kitts i Nevis",
          LC: "Saint Lucia",
          MF: "Saint Martin",
          PM: "Saint-Pierre i Miquelon",
          VC: "Saint Vincent i Grenadyny",
          WS: "Samoa",
          SM: "San Marino",
          ST: "Wyspy Świętego Tomasza i Książęca",
          SA: "Arabia Saudyjska",
          SN: "Senegal",
          RS: "Serbia",
          SC: "Seszele",
          SL: "Sierra Leone",
          SG: "Singapur",
          SX: "Sint Maarten",
          SK: "Słowacja",
          SI: "Słowenia",
          SB: "Wyspy Salomona",
          SO: "Somalia",
          ZA: "Republika Południowej Afryki",
          GS: "Georgia Południowa i Sandwich Południowy",
          KR: "Korea Południowa",
          SS: "Sudan Południowy",
          ES: "Hiszpania",
          LK: "Sri Lanka",
          SD: "Sudan",
          SR: "Surinam",
          SJ: "Svalbard i Jan Mayen",
          SZ: "Suazi",
          SE: "Szwecja",
          CH: "Szwajcaria",
          SY: "Syryjska Republika Arabska",
          TW: "Tajwan",
          TJ: "Tadżykistan",
          TZ: "Tanzania",
          TH: "Tajlandia",
          TL: "Timor Wschodni",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trynidad i Tobago",
          TN: "Tunezja",
          TR: "Turcja",
          TM: "Turkmenistan",
          TC: "Turks i Caicos",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ukraina",
          AE: "Zjednoczone Emiraty Arabskie",
          GB: "Zjednoczone Królestwo",
          US: "Stany Zjednoczone",
          UM: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
          UY: "Urugwaj",
          UZ: "Uzbekistan",
          VU: "Vanuatu",
          VE: "Wenezuela",
          VN: "Wietnam",
          VG: "Brytyjskie Wyspy Dziewicze",
          VI: "Wyspy Dziewicze Stanów Zjednoczonych",
          WF: "Wallis i Futuna",
          EH: "Sahara Zachodnia",
          YE: "Jemen",
          ZM: "Zambia",
          ZW: "Zimbabwe",
        },
        sortOptions: {
          "-modifyTime": "Ostatnia aktualizacja",
          "-modifiedAt": "Ostatnia aktualizacja",
          title: "Alfabetycznie A-Z",
          "-title": "Alfabetycznie Z-A",
          "-reviewCount": "Najbardziej lubiane",
          name: "Nazwa",
          role: "Rola",
          email: "E-mail",
          "-relevance": "Trafność",
          "-createdAt": "Data utworzenia",
          displayName: "Alfabetycznie A-Z",
          "-displayName": "Alfabetycznie Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Dzisiaj",
          yesterday: "Wczoraj",
          this_week: "W tym tygodniu",
          previous_week: "W zeszłym tygodniu",
          this_month: "W tym miesiącu",
          previous_month: "W zeszłym miesiącu",
          this_year: "W tym roku",
          previous_year: "W zeszłym roku",
          beginning_of_time: "Początek okresu",
          custom: "Niestandardowy przedział czasowy",
        },
        eventTypeOptions: {
          downloads: "Pobrania",
          views: "Wyświetlenia",
          all_events: "Wszystkie zdarzenia",
        },
      },
    });
  },
};
