
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Ja = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["ja-JP"]) {
      window.locale["ja-JP"] = {};
    }

    _.extend(window.locale["ja-JP"], {
      myDetails: {
        my_roles: "役割",
        my_roles_link: "役割とTekla Warehouseコンテンツのアクセス権",
        my_roles_link_url: "https://support.tekla.com/ja/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "役割",
        roles: {
          member: "Tekla Warehouse コンテンツ エディタ (従業員)",
          employee: "従業員",
          contentEditor: "Tekla Warehouse コンテンツ エディタ (外部)",
        },
      },
      immutability: {
        add_code_name_for_package: "コンテンツのコードネームを追加する",
        code_name: "コードネーム",
        immutable: "不変",
        archive: "アーカイブ",
        archive_collection: "コレクションとそのすべてのコンテンツをアーカイブする",
        archive_version: "選択したバージョンをアーカイブする",
        archive_package: "パッケージとそのすべてのコンテンツをアーカイブする",
        archive_dialog_text: "アーカイブされたコンテンツは、Tekla Warehouse で表示または検索できなくなります。ただし、コンテンツが以前に Tekla Structures クラウド サービスで使用されていた場合、クラウド サービスは引き続きコンテンツにアクセスできます。",
        archive_success: function (name: string) {
          return `${name} は正常にアーカイブされました`;
        },
        archive_failure: function (name: string) {
          return `${name} のアーカイブに失敗しました`;
        },
        archived_content_redirect_message: "アクセスしようとしているコンテンツはアーカイブされています",
        edit_title_move_or_archive: "タイトルの編集、移動、またはアーカイブ",
        edit_title_or_archive: "タイトルの編集またはアーカイブ",
        major_update: "メジャーアップデート",
        major_update_description: "下位互換性のない変更 (機能の追加または削除、互換性に影響を与える他の変更など)。",
        minor_update: "マイナーアップデート",
        minor_update_description: "下位互換性のある変更 (既存の機能の互換性に影響を与えない新機能や機能強化など)。",
        patch_update: "パッチアップデート",
        patch_update_description: "下位互換性のある修正 (新機能のないセキュリティ パッチ、不具合の修正、パフォーマンスの向上など)。",
        info: "あなたの組織は、Tekla Structures クラウド サービスのコンテンツを提供しています。互換性と一貫性を確保するために、 Tekla Structures クラウド サービスのコンテンツにはコード名とバージョン番号が必要です。",
        immutable_files_info: "クラウド サービスでコンテンツの一貫性を維持するために、ファイルを削除または変更することはできません。変更が必要な場合、現在のバージョンをアーカイブし、新しいバージョンをアップロードしてください。",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `詳しくは、<a href="${readMoreUrl}" target="_blank">こちらのページ</a>をご覧ください。`;
        },
        resolve_conflict: {
          title: "競合の解決",
          description:
            "次のアイテムが見つかりました。 インストールで見つかった既存のアイテムを保持するには、[既存を保持] を選択してください。",
          table_header_name: "名前",
          table_header_type: "タイプ",
          table_header_action: "アクション",
          action_keep_existing: "既存を保持",
          action_replace: "置換え",
          action_done: "完了",
        },
        auth: {
          authentication_process_failed: "認証プロセスに失敗しました",
          access_denied: "アクセスが拒否されました",
          handle_unauthorized: {
            unauthorized: "許可されていません",
            please_log_in: "ログインまたは登録してください",
          },
        },
        translations: {
          edit_translations: "翻訳を編集",
          edit_translations_info: "リストから言語を選択し、編集して保存してください",
          edit_translations_default_language_info:
            "ユーザーが選択した言語が使用不可の場合は<b>デフォルト</b>の言語が表示されます",
          edit_content: "コンテンツ情報",
          edit_support: "サポート",
          updated_successfully: "翻訳が更新されました",
          error_on_update: "翻訳の更新時にエラーが生じました",
          default: "デフォルト",
          batch_translations: "一括翻訳",
          export_import_info:
            "選択したコンテンツの一括翻訳テンプレートを<b>エクスポート</b>し、テンプレートを編集して翻訳を追加し、テンプレートを<b>インポート</b>しなおして複数の翻訳を一度に保存します。",
          import_translations: "インポート",
          export_translations: "エクスポート",
          translations_missing: " (空白)",
          select_content: "エクスポートする翻訳を選択してください: ",
          import_finished: "インポート完了!",
          select_file: "ファイルの選択",
          select_file_title: "インポートするファイルを選択してください: ",
          import_from_file: "インポート",
          import_error_info: "ファイルがテンプレート形式に従っていません: ",
          export_selected: "エクスポート",
          invalid_file: "無効なファイル コンテンツ形式",
          import_translations_finished: "翻訳をインポートしました",
          details_too_long: "ディテール コンテンツが、許容される最大長を超えています",
          import_failed: "翻訳のインポートに失敗しました",
          translate_to_other_languages: "その他の言語に翻訳",
          import_count: function (count) {
            return "更新された翻訳:  " + count + " コンテンツ";
          },
          invalid_language: function (contentId, language) {
            return "コンテンツ '" + contentId + "'  に無効な言語 '" + language + "' が含まれています";
          },
          invalid_field: function (contentId, translationField) {
            return "コンテンツ '" + contentId + "' に無効な翻訳フィールド '" + translationField + "' が含まれています";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "コンテンツ '" +
              contentId +
              "' の翻訳フィールド '" +
              translationField +
              "' の値が、許容される最大長を超えています"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "3D モデル",
          "3d_pic_added": "3D モデルが正常に追加されました",
          "3d_pic_updated": "3D モデルが正常に更新されました",
          "3d_pic_removed": "3D モデルが正常に削除されました",
          "3d_pic_update_failed": "3D モデルの更新に失敗しました",
          "3d_pic_remove_failed": "3D モデルの削除に失敗しました",
          "3d_pic_missing":
            "コンテンツの 3D イメージがありません。 以下の [3D モデルの選択] をクリックして、イメージを選択します。",
          "3d_pic_uploading": "モデルの読み込み中です。 しばらくお待ちください...",
        },
        catalog_entity_edit: {
          froala_placeholder: "ここをクリックしてコンテンツを編集します",
          updated_successfully: "更新に成功しました",
          update_failed: "更新に失敗しました",
          edit_search_criteria: "検索する文字列の編集",
          edit_group_and_category: "グループとカテゴリーの編集",
          edit_content: "コンテンツの編集",
          edit_thumbnail: "サムネイルの編集",
          edit_thumbnail_descriptive: "推奨される寸法:  400 x 300 px",
          edit_description: "説明の編集",
          edit_download_link: "パートナーのダウンロード リンクを追加または編集",
          versions_not_shown_to_users:
            "このコンテンツ アイテムにはパートナーのダウンロード リンクがあるため、Tekla Warehouse ユーザーはコンテンツ アイテムに以前に追加したバージョンを表示できません。Tekla Warehouse 管理者と編集権限を持つユーザーは、引き続きコンテンツ アイテムのすべてのバージョンを表示できます。",
          partner_download_link: "ダウンロード URL を入力",
          partner_download_link_disclaimer:
            "パートナーのダウンロード リンクを既存のコンテンツ アイテムに追加すると、Tekla Warehouse ユーザーは以前にコンテンツ アイテムに追加したバージョンを表示できなくなります。この操作は取り消すことができません。",
          edit_title_or_delete: "タイトルの編集または削除",
          edit_title_or_move_delete: "タイトルの編集、移動、または削除",
          edit_support: "サポート情報の編集",
          edit_release_notes: "リリースノーツの編集",
          edit_content_wysiwyg: "コンテンツの詳細の編集",
          edit_content_embed_link: "Tekla User Assistance 内の詳細情報 &rsaquo;",
          edit_content_embed_info: "YouTube、Vimeo、または Alugha のビデオのみ埋め込むことができます。",
          details_unallowed_embed_info: "YouTube、Vimeo、Alugha の各ドメインの動画のみ埋め込むことができます。使用できるURL形式は次のとおりです。",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "ビデオリンクが無効です。YouTube と Vimeo からは URL を使用して動画を追加できます。",
          edit_content_vide_code_error: "埋め込み形式が無効です。iframeのみが受け入れられます。許可されているビデオプロバイダーは、YouTube、Vimeo、およびAlughaです。",
          details_max_length_exceeded: "ディテール コンテンツが長すぎます",
          editor_mode_label: "エディタ モード: ",
          editor_mode_on: "オン",
          editor_mode_off: "オフ",
          do_not_notify: "マイナーな更新のため、登録者に通知しない",
          invalid_tag: "無効なタグ",
        },
        transfer_package: {
          header: "コンテンツの移動",
          actions: {
            move: "移動",
            cancel: "キャンセル",
          },
          loading: "コレクションを読み込み中...",
          moving: "コンテンツを移動中",
          select_collection: "コレクションの選択",
          content_moved: "コンテンツが移動されました。 数分後に変更内容が表示されます。",
          operation_failed: "操作が失敗しました",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "コンテンツが次の場所に正常にリンクされました:  " + name;
          },
          selected_resources_linked: function (name) {
            return "選択したコンテンツが次の場所に正常にリンクされました:  " + name;
          },
          resources_linked_with_errors: "コンテンツがリンクされました。一部のコンテンツでエラーが発生しました",
          resources_linking_failed: "コンテンツのリンクに失敗しました",
          resource_unlinked: "リンクが正常に削除されました。 数分後に変更内容が表示されます。",
          resources_unlinked: "選択したコンテンツからリンクが正常に削除されました。 数分後に変更内容が表示されます。",
          resources_unlinked_with_errors: "リンクが削除されました。一部のコンテンツでエラーが発生しました",
          resources_unlinking_failed: "リンクの削除に失敗しました",
          linking: "リンクを作成中",
          linking_failed: "リンクに失敗しました",
          linking_failed_title: "次のコンテンツをリンクできませんでした: ",
          unlinking_failed_title: "次のコンテンツのリンクを解除できませんでした: ",
          linking_failed_already_contains:
            "コンテンツは選択されたコレクションにすでにリンクされています。 更新内容が表示されるまで時間がかかる場合があります。",
          linking_failed_already_contains_short: "コンテンツは選択されたコレクションにすでにリンクされています",
          unlinking_failed: "リンクの削除に失敗しました",
          unlinking_failed_does_not_contain:
            "コンテンツ リンクはコレクションからすでに削除されています。 更新内容が表示されるまで時間がかかる場合があります。",
          unlinking_failed_does_not_contain_short: "コンテンツ リンクはコレクションからすでに削除されています",
        },
        notification_channel: {
          title_success: "成功",
          title_notice: "通知",
          title_info: "情報",
          title_error: "エラー",
        },
        maintenance: {
          download_requires_tekla_maintenance: "ダウンロードには Tekla 保守契約が必要です",
          can_access_maintenance: {
            yes: "はい",
            no: "いいえ",
          },
        },
        confirm: {
          title: "確認",
          yes: "はい",
          no: "いいえ",
          ok: "OK",
          close: "閉じる",
          dont_show: "次回からは表示しない",
        },
        show_more: " 詳細表示",
        show_less: " 簡易表示",
        spinner_loading: "読み込み中",
        spinner_processing: "処理中",
        spinner_loading_long_time: "コンテンツの読み込みには数分かかる場合があります",
        local_service: {
          info: "Tekla Warehouse Service プラグインが、ローカル/ネットワーク コンテンツを作成するのに必要です。 Tekla Structures 20.1 SR1 以降では、モデルからコンテンツを直接アップロードできます。",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service プラグイン</a> " +
            " が、ローカル/ネットワーク コンテンツを作成するのに必要です。",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service プラグイン</a> " +
            " がありません。 ローカル/ネットワークコレクションを有効にするには、このサービスが必要です。",
          download_to_unlock_all_features_html:
            "すべての機能のロックを解除するには、" +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service プラグイン</a> " +
            " をダウンロードしてください。",
          service_out_of_date_html:
            "お使いの Tekla Warehouse プラグインは更新が必要です。 すべての機能のロックを解除するには、" +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service プラグイン</a> " +
            " をダウンロードしてください。",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "言語の選択",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "他のユーザーを招待",
          send_invitation_to_email: "招待を電子メールに送信",
          add_e_mail_addresses: "電子メール アドレスの追加と",
          and_assign_role: "役割の割り当て",
          send_invitation: "招待を送信",
          invitation_already_sent: "招待は既に送信されています!",
          invitation_sent: "招待が送信されました!",
          update_selected_invitations: "選択した招待を更新",
          new_role: "新しい役割",
          invitations_list_updated: "招待リストが更新されました!",
        },
        item_browser_title: "コンテンツ アイテムの選択",
        prerequisites: {
          description_placeholder: "ここに説明を追加します",
          link_name_placeholder: "ここにリンク名を追加します",
          add_prerequisites_and_recommendations: "前提条件と推奨事項 (オプション)",
          list_the_profiles_components_files:
            "コンテンツが正しく機能するために必要なプロファイル、コンポーネント、ファイル、またはモデルを挙げます。",
          link_to_existing_content: "既存のコンテンツへのリンク",
          please_enter_prerequisite_url: "前提条件の URL を入力してください",
          please_enter_valid_prerequisite_url: "有効な前提条件の URL を入力してください",
          missing_http: "http:// が抜けています",
          add_link: "リンクの追加",
        },
        treeview: {
          cannot_export_this_container:
            "このコンテナはエクスポートできません。 代わりに個々のアイテムを選択してください。",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "ファイルは次のサイズより小さい必要があります:  " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "ファイル タイプが無効です。  ファイルは次のいずれかのタイプでなければなりません:  " + allowed_types;
          },
        },
        analytics: {
          timeframe: "タイムフレーム: ",
          timeframe_start: "開始: ",
          timeframe_end: "終了: ",
          eventtype: "イベント タイプ: ",

          downloads_daily_by_version: "バージョン別のダウンロード",
          downloads_by_country: "国別のダウンロード",
          downloads_by_version: "バージョン別のダウンロード",
          downloads_by_version_organization: "バージョンおよび組織別のダウンロード",
          downloads_by_organization: "組織別のダウンロード",
          downloads_daily_by_package: "コンテンツ アイテム別のダウンロード",
          views_daily_by_package: "コンテンツ アイテム別のビュー",
          views_by_country: "国別のビュー",
          views_by_organization: "組織別のビュー",

          downloads_by_package: "コンテンツ アイテム別のダウンロード",
          views_by_package: "コンテンツ アイテム別のビュー",
          downloads_daily_by_collection: "コレクション別のダウンロード",
          downloads_by_collection: "コレクション別のダウンロード",
          views_daily_by_collection: "コレクション別のビュー",
          views_by_collection: "コレクション別のビュー",

          events_by_organization_func: function (version) {
            return 'バージョン "' + version + '" イベント (組織別)'; // Version "2.1" events by organizations
          },
          pie_slice_others: "その他",
          sum_all_versions: "すべてのバージョン",
          sum_all_packages: "すべてのコンテンツ アイテム",
          sum_all_collections: "すべてのコレクション",
          sum_all_organizations: "すべての組織",
          recording_started_at: "データの記録は 2015 年 6 月に開始されました",
          recording_view_events_started_at: "データの記録は 2017 年 3 月に開始されました",
          version_title_func: function (title) {
            return 'バージョン "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title +
              " (" +
              pluralize(count, [
                count + "  件のダウンロード",
                count + "  件のダウンロード",
                count + "  件のダウンロード",
              ]) +
              ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "数字には完了したすべてのダウンロードが含まれます。",
          counted_events_info_package: "数字には完了したすべてのダウンロードが含まれます。",
          export: {
            caption: "解析のエクスポート",
            select_options: "オプションを選択",
            select_timeframe_description: "解析をエクスポートするタイムフレームを選択してください: ",
            select_eventtype_description: "解析をエクスポートするイベント タイプを選択してください: ",
          },
        },
        access_rights: {
          editors: "エディタ",
          download_access: "表示とダウンロードのアクセス権",
          write_user_id_placeholder: "ユーザー ID を入力してください",
          write_organization_id: "有効な組織IDを入力します。各IDを別々の行に入力します。",
          write_id_placeholder:
            "ユーザー ID、ユーザーの電子メール アドレス、または組織 ID を入力します。各 ID またはメール アドレスをそれぞれ別の行に入力します。",
          id_help_html_short:
            "セキュリティ上の理由から、ユーザーを探すことはできません。<br/><br/>" +
            "アクセス権を与えるユーザーにユーザー ID を直接確認してください。 <br/>" +
            'ユーザー ID は<a href="#/my/">マイ ディテール</a>に表示されます。',
          id_help_html:
            "セキュリティ上の理由から、ユーザーを探すことはできません。<br/><br/>" +
            "アクセス権を与えるユーザーにユーザー ID を直接確認してください。 組織内のすべてのユーザーにアクセス権を与えるには、組織 ID を使用します。<br/><br/>" +
            'ユーザー ID と組織 ID は<a href="#/my/">マイ ディテール</a>に表示されます。',
          max_input_limit: function (max_rows) {
            return (
              "各 ID またはメール アドレスをそれぞれ別の行に入力します。一度に " + max_rows + " 行を入力できます。"
            );
          },
          input_too_long: "入力したテキストが長すぎます。",
          viewer_already_added: function (input) {
            return `${input} すでにビューアとして追加されています`;
          },
          wrong_format: function (input) {
            return `${input} は有効な ID または電子メール アドレスではありません`;
          },
          candidates_for_keyword: function (keyword) {
            return "メール アドレスまたは ID " + keyword + " でユーザーが見つかりました";
          },
        },
      },
      images: {
        add_new: "新規追加",
        add: "追加",
        delete: "削除",
        set_as_default: "デフォルトのサムネイル",
        select_file: "ファイルの選択",
        choose_option: "オプションの選択",
        info_3D_exists: "すでに 3D モデルを追加しています。コンテンツにモデルを 2 つ以上追加することはできません。",
        action_options: {
          image: "イメージ",
          "3DModel": "3D モデル",
          video: "ビデオ",
        },
        video: {
          url: "ビデオ URL",
          invalid_url: "無効な URL",
        },
      },
      units: {
        bytes: "バイト",
        kB: "KB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "ダウンロード",
      },
      likes: function (number) {
        return (
          "" + pluralize(number, [number + "  件のいいね！", number + "  件のいいね！", number + "  件のいいね！"])
        );
      },
      main_info: {
        header_title: "Tekla Warehouse のウェブサイトが新しくなりました!",
        header_text: "スマートに生まれ変わった Tekla Warehouse ウェブサイトはいかがでしょうか?",
        header_text2: "主な変更点をご紹介します。",
        bullet_text_1: "ランディング ページの検索が分かりやすくなりました。",
        bullet_text_2:
          "検索ページのすべての検索フィルターが左側に配置され、デフォルトのサムネイル ビューで同一ビューに表示される検索結果の件数が増えました。",
        bullet_text_3:
          "コンテンツ ページのダウンロード オプションが中央に配置され、コンテンツの使用をすぐに開始できるようになりました。 ダウンロードとインストールの両方のオプションが [ダウンロード] ボタンから利用できるようになりました。",
        bullet_text_4:
          "コレクション ページとマイ カートの両方で、ダウンロードとインストールの利用に 3 ステップの操作手順が付きました。",
      },
      organizations: {
        error_loading_collection_contents: "コレクションのコンテンツの読み込みエラー",
        error_loading_organization: "組織の読み込みエラー",
      },
      downloads_graph: {
        date: "日付",
        total_views: "表示総数",
        total_downloads: "ダウンロード総数",
        views: "表示",
        downloads: "ダウンロード",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service プラグイン" +
          "</a>" +
          " が必要です。",
        processingFailed: "処理が失敗しました",
        forbidden: "禁止",
        resource_not_found: "リソースが見つかりません",
        no_matches_found: "一致するものが見つかりません",
        no_matches_found_for: function (input) {
          return "メール アドレスまたは ID " + input + " で一致するものが見つかりませんでした";
        },
        not_identified: "特定できません",
        error_while_searching: "検索中にエラーが発生しました",
        error_user_not_found: "ユーザーまたは組織が見つかりません",
        error_while_adding_member: "アクセス権メンバーを追加するときにエラーが発生しました",
        error_while_removing_member: "アクセス権メンバーを削除するときにエラーが発生しました",
      },
      offline_to_online: {
        fail_on_initialize: "zip コンテンツの処理中にエラーが発生しました。変換を中止しています",
        fail_on_validate: "コンテンツ メタデータの検証に失敗しました。変換を中止しています",
        fail_on_save: "コンテンツの保存中にエラーが発生しました。変更をロールバックしています",
        success: "オフライン コンテンツがオンライン コンテンツに正常に変換されました",
        validate_errors_title: "コンテンツの変換に失敗しました",
        processing: "パッケージの保存中 ",
        processed: "処理済みアイテム",
        collection_thumbnail_missing_name: "コレクション サムネイル バイナリに 'ファイル名' がありません",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "パッケージ '" + packageTitle + "' のサムネイル バイナリに &apos;ファイル名&apos; がありません";
        },
        package_value_missing_title_func: function () {
          return "パッケージに &apos;タイトル&apos; がありません";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "パッケージ '" + packageTitle + "' に '" + field + "' がありません";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "パッケージ '" +
            packageTitle +
            "' のフィールド '" +
            field +
            "' に次の無効な値が含まれています:  " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "パッケージ '" + packageTitle + "' に次の無効なタグが含まれています: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "パッケージ '" + packageTitle + "' のバージョンに &apos;タイトル&apos;がありません";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return (
            "パッケージ '" + packageTitle + "' のバージョン '" + versionTitle + "' に '" + field + "' がありません"
          );
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "パッケージ '" +
            packageTitle +
            "'  のバージョン '" +
            versionTitle +
            "' のフィールド '" +
            field +
            "' に次の無効な値が含まれています:  " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return (
            "パッケージ '" +
            packageTitle +
            "' のバージョン '" +
            versionTitle +
            "' のバイナリに &apos;ファイル名&apos; がありません"
          );
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "重要: Tekla Warehouseのローカルコレクションのサポート終了",
            part1: "2025年6月2日から、Tekla Warehouse でローカルコレクションは利用できなくなります。ユーザーはローカルコレクションを作成、表示、検索、またはローカルコレクションのコンテンツを使用することができなくなります。",
            part2: "ローカルコレクションからオンラインコレクションへの移行に関するサポートが必要な場合は、現地法人にお問い合わせください。",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `重要! セキュリティ強化のため、2024 年 11 月 ${date} 日以降、組織のコンテンツを管理するには Tekla Warehouse コンテンツ エディターの役割が必要です。役割の割り当ては、組織の管理者に依頼してください。詳細については、<a href="${productBulletinUrl}" target="_blank">製品速報</a>を参照してください。`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `セキュリティ強化のため、2024 年 11 月 ${date} 日以降、組織のコンテンツを管理するには Tekla Warehouse コンテンツ エディターの役割が必要です。役割の割り当ては、組織の管理者に依頼してください。詳細については、<a href="${productBulletinUrl}" target="_blank">製品速報</a>を参照してください。`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `編集オプションが表示されませんか?11 月 ${date} 日以降、組織のコンテンツを管理するには Tekla Warehouse コンテンツ エディターの役割が必要です。役割は、[My Warehouse]>[マイディテール]で確認できます。詳細については、<a href="${productBulletinUrl}" target="_blank">製品速報</a>を参照してください。`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `組織のコンテンツを作成するオプションが表示されませんか?11 月 ${date} 日以降、組織のコンテンツを管理するには Tekla Warehouse コンテンツ エディターの役割が必要です。役割は、[My Warehouse]>[マイディテール]で確認できます。詳細については、<a href="${productBulletinUrl}" target="_blank">製品速報</a>を参照してください。`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `11 月 ${date} 日以降、組織のコンテンツを管理するには Tekla Warehouse コンテンツ エディターの役割が必要です。組織の管理者に役割の割り当てを依頼してください。組織の管理者は、Tekla Online プロファイルで確認できます。詳細については、<a href="${productBulletinUrl}" target="_blank">製品速報</a>を参照してください。`
            },
          },
          service_plugin_update_info:
            "Tekla Structuresに接続する際に問題がありますか？最新の<a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Serviceプラグインを使用してください</a>。",
        },
        maintenance_info:
          "Tekla Warehouse は、2017 年 10 月 27 日 22:00:00 ～ 23:00:00 (UTC) の間、メンテナンスを実施します。 ご不便をおかけし、申し訳ありません。",
        release_notes_info: "Tekla Warehouse の新機能",
        release_notes_link:
          'Tekla Warehouse が更新されました。<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">新機能</a> をお読みください',
        heading: "Tekla Warehouse",
        heading1Dev: "プロジェクトで Tekla デベロッパーが必要ですか?",
        heading2Dev:
          '新しい名簿、Tekla 開発エキスパートをご確認ください。<br><br>有能なプログラマーと、関心がある Tekla ユーザーが出会える場所です。<br><br>すべての Tekla Partners Program メンバーは、エキスパート名簿に自由に情報を追加することができます。<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">デベロッパーを見つける&rsaquo;</a>',
        static_intro:
          "これは、無償の Tekla Structures BIM ストレージで、検索、インポート、インストール、また社内や世界中での共有が可能です。 Tekla Warehouse は、高品質なモデルの効率的な作成を容易にすることを目的としています。",
        new_intro:
          'Tekla Corporation は現在、Trimble と呼ばれています。 弊社は今後も Tekla ソフトウェア ソリューションの開発を続け、同じメンバーでお客様をサポートしてまいります。 詳細:  <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "アカウントを確認してください",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "最新のアップロード&rsaquo;",
          popular: "一般的なコンテンツ&rsaquo;",
          recommended: "おすすめ &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">ニュース&rsaquo;</a>',
          item_title: "ニュースをお届けします!",
          item_description:
            'ニュース関連テキスト、ニュース関連テキスト、ニュース関連テキスト、ニュース関連テキスト、ニュース関連テキスト、ニュース関連テキスト。<br><a href="https://developer.tekla.com" target="_blank">詳細をご覧ください&rsaquo;</a>',
        },
        statistics: {
          title: "数字で見る Warehouse",
          partners: "パートナー",
          partners_count: "352",
          collections: "コレクション",
          collections_count: "793",
          items: "コンテンツ アイテム",
          items_count: "2893",
        },
        partners: "当社のパートナー",
        action_links: {
          search_title: "コンテンツを参照しますか?",
          upload_title: "コンテンツをアップロードしますか?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">ビデオ&rsaquo;</a>',
          link: '当社の <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Youtube チャンネル</a>でその他のビデオをご覧ください。',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            '電子メール内の検証リンクをご確認ください。または、<u><a href="' +
            settingsUrl +
            '">新しいリンクを要求</a></u>してください。'
          );
        },
        verifyAccountAcceptTerms: "電子メール アドレスを確認し、使用条件とプライバシー ポリシーに同意してください",
        acceptTerms: "使用条件とプライバシー ポリシーに同意してください",
        acceptUpdatedTerms: "更新された tekla online services 使用条件とプライバシー ポリシーに同意してください",
        termsNotAccepted_html_func: function (settingsUrl) {
          return '<u><a href="' + settingsUrl + '">プロファイル</a></u>に戻ってください';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            '<u><a href="' +
            settingsUrl +
            '">プロファイル</a></u>で、条件とプライバシー ポリシーの内容を確認し、同意してください。'
          );
        },
        suggestions: {
          browseCollections: "コレクションをブラウズ&rsaquo;",
          new: "新規",
          popular: "人気",
          recommended: "おすすめ",
          organizations: "組織",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "フォルダの選択",
        selectFolderInstructions: "ローカル/ネットワーク フォルダをコレクションとして使用できます。",
        selectedFolder: "選択したフォルダ (コピーして貼り付ける場所): ",
        selectedFolderPlaceholder: "例: C:\\folder、Z:\\network folder、\\\\machine-name\\folder",
        collectionCreation: {
          name: "名前",
          createNewCollection: "ローカル コレクションの作成",
          pleaseInputTitle: "タイトルを入力してください",
          invalidPath: "無効なパス",
          access_denied: "コレクションを追加できませんでした:  アクセスが拒否されました",
          unsafe_location: "コレクションを追加できませんでした:  不安定な位置",
          add_collection_failed: "コレクションを追加できませんでした",
          minLengthError: "タイトルは 3 文字以上にする必要があります",
          maxLengthError: "タイトルは 20 文字以内でなければなりません",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "ローカル/ネットワーク フォルダをコレクションとして使用できます。",
        collectionCreation: {
          name: "名前",
          createNewCollection: "オンライン コレクションの作成",
          pleaseInputTitle: "タイトルを入力してください",
          minLengthError: "タイトルは 3 文字以上にする必要があります",
          maxLengthError: "タイトルは 20 文字以内でなければなりません",
        },
      },
      popups: {
        generic: {
          resourceSaved: "リソースが保存されました",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">可能性が見えますか?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">フィードバックをお送りください</span>',
          help_us_improve_html:
            'Tekla Warehouse の改善のためにご協力ください! <br/><a id="showSurvey">ここから回答</a><a id="dontShow">、または次回からは表示しない</a>',
          dialog: {
            do_you_see_potential: "可能性が見えますか?",
            help_us_improve_html: "Tekla Warehouse の改善のためにご協力ください! ぜひ貴重なご意見をお寄せください。",
            continue_to_survey: "アンケートを開始する",
            exit_to_warehouse: "アンケートに回答しない",
          },
        },
      },
      search: {
        bar: {
          search: "検索",
          filter: " ",
          more_filters: "その他のフィルター",
          hide_filters: "フィルターを非表示にする",
          clear: "クリアする",
          exclude: "除く",
          include: "含む",
          clear_filters: "フィルターのリセット",
          load_defaults: "基本設定を使用&rsaquo;",
          save_defaults: "基本設定として保存&rsaquo;",
          defaults_saved_successfully: "デフォルトのフィルターを保存しました",
          edit_defaults: "デフォルトを編集",
        },
        info: {
          searching_organizations: "組織内を検索中...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["結果なし", amountTotal + "  件の結果"]);
            } else {
              return (
                pluralize(amountTotal, ["結果なし", amountTotal + "  件の結果"]) +
                ' 検索語句 "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + "  件の結果";
            } else if (!searchTerm) {
              return "組織結果を見るための検索条件を入力します";
            } else if (searchTerm.length < 2) {
              return "組織検索には少なくとも 2 つの文字を入力します";
            } else {
              return (
                pluralize(amountTotal, ["結果なし", amountTotal + "  件の結果"]) +
                ' 検索語句 "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: "  件の結果 (オンライン コンテンツ)",
          local: "  件の結果 (ローカル コンテンツ)",
          organization: " 件の結果 (組織内)",
          plugin_missing_info:
            'ローカル コンテンツは、<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service プラグイン" +
            "</a>" +
            " がインストールされている場合にのみ表示されます。",
          version_filter_info: "ローカル コンテンツの結果ではソフトウェア バージョンのフィルタリングは無効です",
        },
        exclude_env_files: "環境ファイルを除外",
        sidebar: {
          filters: {
            measurementUnits: "単位系",
            locations: "プロジェクトの場所",
            languages: "言語",
          },
          placeholders: {
            use_categories: "カテゴリー",
            measurement_units: "単位系",
            languages: "言語",
            locations: "場所",
          },
        },
      },
      placeholders: {
        name: "名前...",
        contentOwner: "コンテンツ オーナー名...",
        useCategory: "用途の選択",
        selectType: "タイプの選択",
        filter: "フィルター...",
        search: "検索...",
        choose: "選択...",
        searchFilters: "検索フィルター...",
        selectContentItem: "コンテンツ アイテムの選択...",
        search_content: "コンテンツの検索...",
        includeAs: "含む...",
        selectCollection: "コレクションの選択",
        selectUserGroup: "ユーザー グループの選択",
        online_collections_tooltip: "クリックしてオンライン コンテンツを検索します",
        local_collections_tooltip:
          "クリックして、ローカルおよびネットワーク コンテンツ、Tekla Structures のインストールに含まれるコンテンツを検索します",
        organizations_tooltip: "クリックして組織を検索します",
        "filter-options": {
          products: "すべてのソフトウェア製品",
          locations: "すべての場所",
          languages: "すべての言語",
          measurementUnits: "すべての単位系",
          testedVersions: "すべてソフトウェアのバージョン",
          useCategories: "すべてのカテゴリー",
          useCategoriesCombined: "カテゴリー",
          allGroups: "すべてのグループ",
          itemTypeCategories: "すべてのアイテム タイプ",
        },
        "exclude-filter-options": {
          products: "ソフトウェア製品の除外",
          locations: "場所を除外",
          languages: "言語を除外",
          measurementUnits: "単位系を除外",
          testedVersions: "ソフトウェアのバージョンを除外",
          useCategories: "カテゴリーを除外",
          useCategoriesCombined: "カテゴリーを除外",
          allGroups: "グループを除外",
          itemTypeCategories: "アイテム タイプを除外",
        },
      },
      link: {
        link_selected: "リンクが選択されました",
        unlink_selected: "リンク解除が選択されました",
      },
      download_install: {
        choose_version: "ソフトウェアのバージョンを選択してください: ",
        choose_content: "以下のリストからコンテンツ アイテムを選択してください: ",
        choose_action: "選択されたアイテムの操作を選択してください: ",
      },
      download: {
        copy_to_clipboard: "クリップボードにコピー",
        download_selected: "ダウンロード",
        add_selected_items_to_basket: "マイ カートに選択対象を追加",
        add_item_to_basket: "マイ カートに選択対象を追加",
        add_all_items_to_basket: "カートにすべて追加",
        add_all_items_to_basket_info: "100 を超えるアイテムがあります。 読み込みに時間がかかる場合があります。",
        add_all_items_to_basket_too_many_results: "1000 を超えるアイテムを追加することはできません。",
        cart_loading: "100 を超えるアイテムがあります。 読み込みに時間がかかる場合があります。",
        remove_item_from_basket: "カートから削除",
        shopping_cart_no_content: "カートの中が空です",
        shopping_cart_no_content_for_version: "選択したバージョンのカートの中が空です",
        shopping_cart_items_func: function (itemsCount) {
          return "マイ カート (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "手順のダウンロード",
          step_one: "このボタンを使用して、ダウンロード情報をクリップボードにコピーします",
          step_two_func: function (downloadAppUrl) {
            return (
              "Tekla Warehouse Downloader アプリケーションを実行します。 アプリケーションのダウンロードは " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">ここでできます。</a> '
            );
          },
          step_three: "Tekla Warehouse Downloader アプリケーションは残りのダウンロード手順をガイドしてくれます。",
          show_data_title: "うまくいかなかった場合",
          show_data_to_be_copied: "手動でコピーします",
          hide_data_to_be_copied: "ダウンロード データを非表示にします",
        },
      },
      install: {
        install_title: " アイテムが Tekla Warehouse プラグインを使用してインストールされます。",
        failed_install_information:
          "ダウンロードにのみ使用可能なコンテンツが選択されています。 このコンテンツはインストールされません。 このコンテンツは Tekla Warehouse から別途ダウンロードしてください。",
        verify_service_plugin_is_running:
          "インストール:  Tekla Warehouse Service プラグインが正しく動作していることを確認してください",
        verify_ts_connection: "インストール:  Tekla Structures が実行されていることを確認してください",
        select_software_version:
          "リンク、ダウンロード、またはインストールするためにソフトウェアのバージョンでフィルターします",
        download_requires_maintenance: "以下の一部のアイテムにアクセスするには Tekla 保守契約が必要です",
        install_selected: "インストール",
        start_install: "インストールの開始",
        install_started: "インストールが開始されました",
        select_ts_version: "TS バージョンの選択",
        install_retry: "再試行が必要な場合は Tekla Warehouse から開始してください",
        failed_installs_info_func: function (failedCount) {
          return "インストールされないコンテンツ (" + failedCount + "  バージョン): ";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "選択した次のインストール " + totalInstallsCount + " アイテム";
        },
      },
      helpers: {
        behalf: "変更者",
        sortBy: "並べ替え: ",
        filter: "結果をフィルター",
        showAsThumbs: "サムネイルで表示",
        showAsList: "リストで表示",
        resultsPerPage: "ページ毎で表示: ",
        filter_by_version: "バージョンでフィルター: ",
        show_more_options: "詳細オプション",
        show_less_options: "簡易オプション",
        filter_by_type: "タイプでフィルター: ",
        select_all: "すべての選択",
        show_content_items: "コンテンツ アイテムを表示",
        choose_action: "選択したコンテンツの操作を選択してください:  ",
        show_collections: "コレクションを表示",
        search: {
          show: "表示: ",
          show_content_items: "コンテンツ アイテム",
          show_collections: "コレクション",
          show_organizations: "組織",
          show_online_content: "オンライン コンテンツ",
          show_offline_content: "オフライン コンテンツ",
          local_content_info:
            'ローカル コンテンツは、<b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " プラグインがインストールされました。",
        },
        tekla_maintenance_required: "以下の一部のアイテムにアクセスするには Tekla 保守契約が必要です",
        show_content_alerts: "コンテンツの警告を表示 ",
        show_collection_alerts: "コレクションの警告を表示 ",
      },
      actions: {
        load_more: "さらに読み込む",
        use_infitinite_scroll: "無限スクロールを使用",
        back: "戻る",
        show_download_dialog: "ダウンロード",
        download: "ダウンロードのみ",
        download_on_partner_site: "パートナー サイトでダウンロード",
        insert: "インストール",
        install: "インストール",
        downloadAndInstall: "インストール",
        createNew: "新規作成",
        viewItems: "ビュー",
        viewCollection: "コレクションを表示",
        viewGroup: "グループを表示",
        joinGroup: "グループに参加",
        loginToDownload: "ログインしてダウンロード",
        show3DPic: "3D モデルを表示",
        remove: "削除",
        browse: "ブラウズ",
        browseFolder: "ブラウズ",
        browseFileSystem: "ブラウズ",
        add: "追加",
        save: "上書き保存",
        batch_edit: "一括編集",
        translations: "翻訳",
        cancel: "キャンセル",
        continue: "続ける",
        delete: "削除",
        addNetworkPath: "ネットワークの場所を追加",
        select: "選択する",
        create: "作成",
        update: "更新",
        choose_thumbnail: "サムネイルを選択",
        choose_picture: "画像を選択",
        remove_picture: "画像を削除",
        choose_3D_picture: "3D モデルを選択",
        remove_3D_picture: "3D モデルを削除",
        like: "いいね!",
        unlike: "いいね! を取り消す",
        close: "閉じる",
        export: "エクスポート",
        add_or_remove: "追加・削除",
        select_content_to_convert: "オフライン コンテンツを選択",
        search: "検索",
        upload: "アップロード",
        edit: "編集",
        view_3d: "クリックしてモデルを表示...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "ベータ版",
        search: "検索",
        profile: "マイ ディテール",
        myWarehouse: "My Warehouse",
        myStuff: "マイ コンテンツ",
        logout: "ログアウト",
        login: "サインイン",
        register: "登録",
        upload: "アップロード",
        uploading: "アップロード",
        dashboard: "ダッシュボード",
        learnMore: "詳細を確認",
        about: "説明",
        shoppingCart: "マイ カート",
        addToCartInfo:
          "アイテムをマイ カートに集めて、すべて一度にダウンロードまたはインストールすることができます。 ダウンロードは完全に無料です",
      },
      cubeNavigation: {
        headings: {
          get_started: "使用開始",
          learn: "学習",
          get_help: "ヘルプを参照",
          extend_your_tekla: "TEKLA を拡張",
          collaborate: "コラボレーション",
          students_and_educators: "Students and educators",
          about: "説明",
        },
        "tekla-online": "Online services",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Tekla ウェブサイト",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla エクストラネット",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "マイ プロファイル",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla e ラーニング",
        tekla_services: "TEKLA サービス ホーム",
        register: "登録",
        login: "ログイン",
        evaluate: "評価",
        buy: "購入",
        download: "ダウンロード",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "製品",
        teklaServices: "サービス",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "マイ コレクション",
        myLikes: "お気に入り",
        profile: "マイ ディテール",
        preferences: "基本設定",
        myUserGroups: "マイ ユーザー グループ",
        localCollections: "ローカル/ネットワーク コレクション",
        tsCollections: "Tekla Structures コレクション",
        downloads: "ダウンロード",
        selectContent: "コンテンツ ファイルの選択",
        addMetadata: "メタデータの追加",
        onlineCollections: "オンライン コレクション",
        userGroups: "ユーザー グループ",
        createNew: "コンテンツの追加",
        notifications: "通知",
        contentUsage: "コンテンツの利用",
        browseCollections: "コレクションをブラウズ",
        admin: "管理者",
        sales: "セールス",
        shoppingCart: "マイ カート",
      },
      notifications: {
        title: "通知",
        invitedBy: "招待者",
        invitationType: "招待タイプ",
        invitedTo: "招待先",
        createdAt: "作成場所",
        action: "アクション",
        acceptInvitation: "受け入れる",
      },
      batch_edit: {
        selectActionType: "アクション タイプを選択",
        edit_content: "コンテンツ メタデータを編集",
        content_title: "コンテンツ",
        metadataToChange: "変更するメタデータ",
        selectContent: "コンテンツ対象",
        select_action: "アクションを選択",
        support: {
          support: "サポート",
          licenses: "ライセンス",
        },
        versions: {
          version: "バージョン",
          prerequisities: "前提条件と要件",
        },
        content: {
          select_collection: "コレクションの選択",
          show_collection: "表示: ",
          show_all_content: "すべてのコレクション",
          show_organization_content: "自分の組織のコレクション",
          select_all: "すべて選択",
          select_all_info: "(これには時間がかかる場合があります)",
          actionOptions: {
            add: "追加",
            remove: "削除",
            replace: "置き換え: ",
            yes: "はい",
            no: "いいえ",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "属性が選択されていません",
            invalid_attributes: "属性の値が無効です",
            invalid_actions: "無効な操作です",
            content_not_selected: "コンテンツが選択されていません",
            entities_not_selected: "パッケージに関連するデータが指定されましたが、パッケージが選択されていません",
            versions_not_selected: "バージョンに関連するデータが指定されましたが、バージョンが選択されていません",
          },
        },
        update: {
          info_processing: "すべてのアイテムの処理が完了するまで、このウィンドウを開いたままにしてください。",
          info_processed: "すべてのアイテムの処理が完了しました。",
          processing: "アイテムの処理中",
          error_info: "次のアイテムの更新時に問題が発生しました: ",
          package_failure_func: function (package_title, errorMsg) {
            return "内容: " + package_title + "、エラー: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "内容: " + package_title + "、バージョン: " + version_title + "、エラー: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + "  件のアイテムが更新されます。続けますか？";
          },
        },
      },
      myUserGroups: {
        title: "ユーザー グループ",
        createdByPrefix: "作成者: ",
      },
      downloads: {
        title: "ダウンロード",
      },
      myCollections: {
        new_collection: "新規コレクション",
        my_collections: "マイ コレクション",
        created_by_me: "自分が作成",
        local_collections: "ローカル/ネットワーク コレクション",
        online_collections: "オンライン コレクション",
        created_by_my_organization: "自分の組織が作成",
        created_by_my_organizations: "自分の組織が作成",
        shared_with_me: "自分と共有済み",
        shared_with_me_or_organization: "自分または自分の組織と共有済み",
        notification: {
          error: "エラー",
          error_loading_online_collections: "オンライン コレクションの読み込みエラー",
          error_loading_packages_liked_by_me: "自分がいいね! したコンテンツの読み込みエラー",
          error_loading_my_organization_s_collections: "自分の組織のコレクションの読み込みエラー",
          error_loading_my_shared_collections: "自分の共有コレクションの読み込みエラー",
        },
        local: {
          helpText1: "ローカル コレクションにアクセスするには、 ",
          helpText2: "Tekla Warehouse Service プラグイン",
          helpText3: ".",
          title: "ローカル/ネットワーク コレクション",
          notFound: "ローカル コレクションが見つかりません。",
          myCollectionsTitle: "マイ コレクション",
          actions: {
            createNew: "ローカルまたはネットワーク コレクションを作成",
          },
        },
      },
      organization: {
        news_feed: "ニュース フィード",
        add_comment: "フィード テキストを追加",
        edit_comment: "ニュース アイテムを編集",
        subTabs: {
          collections: "コレクション",
          analytics: "アナリティクス",
          contact_and_support: "お問い合わせ",
          news_feed: "ニュース フィード",
        },
        edit: {
          edit_title: "タイトルの編集",
          edit_description: "説明の編集",
          edit_contact: "連絡先情報の編集",
          edit_logo: "ロゴの編集",
          edit_url: "URL の編集",
          edit_email: "電子メールの編集",
          edit_phonenumber: "電話番号の編集",
          edit_warehouse_url: "Warehouse URL の編集",
          edit_warehouse_url_subtitle: "アルファベット、スカンジナビア語以外の文字、数字、特殊文字",
          edit_url_subtitle: "完全な URL を挿入",
          edit_logo_descriptive: "最適な画像サイズ: 約 350 x 200 ピクセル",
          edit_facebook_url: "Facebook URL の編集",
          edit_twitter_url: "Twitter URL の編集",
          edit_linkedin_url: "LinkedIn URL の編集",
          edit_youtube_url: "Youtube URL の編集",
          edit_googleplus_url: "Google+ URL の編集",
          logo_updated: "ロゴが更新されました",
          logo_update_failed: "ロゴの更新に失敗しました",
          logo_remove_failed: "ロゴの削除に失敗しました",
          logo_removed: "ロゴが正常に削除されました",
          updated_successfully: "更新に成功しました",
          updated_failed: "更新に失敗しました",
          add_comment: "新規追加",
          edit_comment: "ニュース アイテムを編集",
          comment_added: "ニュース フィード アイテムが正常に追加されました",
          comment_updated: "ニュース フィード アイテムが正常に更新されました",
          comment_deleted: "ニュース フィード アイテムが正常に削除されました",
          comment_add_failed: "ニュース フィードの追加に失敗しました",
          comment_update_failed: "ニュース フィード アイテムの更新に失敗しました",
          comment_delete_failed: "ニュース フィード アイテムの削除に失敗しました",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "オンライン コンテンツの検索対象:  " + name + " &rsaquo;";
        },
        contact_information: "お問い合わせ",
        website: "ウェブサイト&rsaquo;",
        show_newer_news: "新しい",
        show_older_news: "古い",
      },
      online_collections: {
        new_collection: "新規コレクション",
        online_collections: "オンライン コレクション",
        created_by_organizations: "組織が作成",
        created_by_users: "ユーザーが作成",
      },
      local_collections: {
        tab_title: "コンテンツ",
        local_collections: "ローカル コレクション",
      },
      versions: {
        add_tool: "アプリケーションの追加",
        table_header: {
          name: "名前",
          type: "タイプ",
          platform: "プラットフォーム",
          modified_at: "変更場所",
          size: "サイズ",
          virus_scan: "ウイルス スキャン",
        },
        // placeholders
        select_file_type: "ファイル タイプを選択",
        select_platform: "プラットフォームを選択",
        select_unit: "単位を選択",
        select_quality: "品質を選択",
        what_will_be_added: "何を追加しますか?",
        select_files_from_your_disk: "ディスクからファイルを選択",
        add: "追加",
        //content management
        add_application: "アプリケーションの追加",
        select_content_from_tekla_structures: "Tekla Structures からコンテンツを選択",
        or: "または",
        files: "ファイル",
        //version metadata
        version_information: "バージョン情報",
        version_display_name: "バージョンの表示名",
        version_number: "バージョン番号",
        version_number_info: "バージョン番号を入力してください。保存後にバージョン番号を変更することはできません。",
        existing_versions: "このコンテンツの既存のバージョン番号",
        version_number_validation: {
          missing: "バージョン番号を入力する必要があります",
          invalid: "バージョン番号は、セマンティック バージョニングのルール (MAJOR.MINOR.PATCH) に従う必要があります",
          already_in_use: "このバージョン番号は、このコンテンツで既に使用されています。",
        },
        select_update_type: "バージョン番号を生成するには、まず更新の種類を選択します",
        please_enter_a_version_name_or_number: "バージョン名またはバージョン番号を入力してください。",
        virus_scan: {
          soft_failed: "失敗",
          hard_failed: "未スキャン",
          infected: "感染",
        },
      },
      collections: {
        by: "作成者: ",
        edit: {
          edit_collection: "コレクションの編集",
          edit_description: "説明の編集",
        },
        notification: {
          collection_updated: "コレクションが更新されました",
          error: "エラー",
          error_loading_other_users_collections: "他のユーザーのコレクションの読み込みエラー",
          error_loading_other_organizations_collections: "他の組織のコレクションの読み込みエラー",
          error_loading_collection_contents: "コレクションのコンテンツの読み込みエラー",
          members_list_updated: "メンバー リストが更新されました!",
          invitations_removed: "招待が削除されました!",
          collection_removed: "コレクションが削除されました",
          thumbnail_deleted: "サムネイルが削除されました",
          wrong_file_type: "ファイル タイプが間違っています。",
          invalid_file_type: "ファイル タイプが無効です。",
          selected_image_is_too_big: "選択した画像が大きすぎます。",
          thumbnail_updated: "サムネイルが更新されました",
          default_thumbnail_updated: "デフォルトのサムネイルが更新されました",
          default_thumbnail_update_failed: "デフォルトのサムネイルを更新できませんでした",
          thumbnail_added: "サムネイルが追加されました",
          thumbnail_update_failed: "サムネイルを更新できませんでした",
          video_added: "ビデオが追加されました",
          video_adding_failed: "ビデオを追加できませんでした",
          video_deleted: "ビデオが削除されました",
          video_deleting_failed: "ビデオの削除に失敗しました",
        },
        confirm: {
          delete_all: function (title) {
            return '次のコレクションとコレクション内のすべてのコンテンツを削除  "' + title + '"?';
          },
          remove_members: "本当によろしいですか？",
          remove_invitations: "本当によろしいですか？",
        },
        useNetworkDrive: "ネットワーク ドライブまたはフォルダを使用",
        createCollection: "オンライン コレクションの作成",
        createCollectionInstructions: "コレクションを作成してコンテンツをグループ化します。",

        actions: {
          delete_thumbnail: "サムネイルの削除",
          edit_collection: "コレクションの編集",
          delete_collection: "コレクションの削除",
          add_content: "コンテンツの追加",
        },
        subTabs: {
          content: "コンテンツ",
          members: "メンバーの表示",
          analytics: "アナリティクス",
          manage_access: "アクセス権の管理",
        },

        members: {
          add_user_groups: "ユーザー グループの追加",
          select_user_group: "ユーザー グループの選択",
          with_role: "役割付き",
          user_groups_added: "ユーザー グループが追加されました!",
          members_list_updated: "メンバー リストが更新されました!",
          update_selected_members: "選択したメンバーの更新",
          table_header: {
            member_type: "メンバー タイプ",
            member_name: "メンバー名",
            email: "電子メール",
            role: "役割",
            joined_at: "参加場所",
            invited_at: "招待場所",
          },
          new_role: "新しい役割",
        },
        member_list: {
          users_who_can_access_this_collection: "このコレクションにアクセスできるユーザー",
          add_user_group: "ユーザー グループの追加",
          update_members: "メンバーの更新",
          remove_members: "メンバーの削除",
          pending_invitations: "保留中の招待",
          user: "ユーザー",
          invite_member: "メンバーを招待",
          update_invitations: "招待の更新",
          remove_invitations: "招待の削除",
        },
        labels: {
          select_organization: "組織の選択",
          select_visibility: "表示/非表示の選択",
          title: "名前",
          name: "名前",
          createAs: "次の立場で作成:  ",
          asMyself: "自分",
          asUserGroup: "ユーザー グループ",
          asOrganization: "組織",
          selectOwner: "コンテンツ オーナーの組織の選択",
          visibility: "表示・非表示",
          visibilityOptions: {
            public: "公開",
            private: "非公開",
          },
          description: "説明",
        },
        new_collection: {
          please_input_a_username: "ユーザー名を入力してください",
          title_is_required_to_be_at_least_3_characters: "タイトルは 3 文字以上にする必要があります",
          title_cannot_be_longer_than_20_characters: "タイトルは 20 文字以内でなければなりません",
        },
        collection_list: {
          no_content: "コレクションがありません。",
          results_summary: function (number) {
            return "" + number + "  コレクション " + pluralize(number, ["アイテム", "アイテム", "アイテム"]);
          },
        },
        entity_list: {
          no_content: "このコレクションにはコンテンツがありません。",
          results_summary: function (number) {
            return "" + number + "  件のコンテンツ " + pluralize(number, ["アイテム", "アイテム", "アイテム"]);
          },
          linked_content: "リンクされたコンテンツ",
          unlink: "コンテンツのリンクを解除する",
        },
      },
      alerts: {
        no_updates: "最近更新されていない",
        notifications_marked_as_read: "既読としてマークされた通知",
        cleared_alerts: "すべての通知の消去",
        alert_by_email:
          "下記のコンテンツが新しいバージョンに更新された場合、またはコレクションが新しいコンテンツで更新された場合、電子メールで通知を送信",
        action_types: {
          create: "が作成されました",
          update: "が編集されました",
          delete: "が削除されました",
          added_entity: "のバージョンが追加されました",
          removed_entity: "のバージョンが削除されました",
          added_collection: "のコンテンツが追加されました",
          removed_collection: "のコンテンツが削除されました",
        },
        property_names: {
          description: "ディテール",
          searchCriteria: "検索する文字列情報",
          groupCategory: "グループとカテゴリー情報",
          support: "サポート情報",
          licenses: "ライセンス情報",
          relatedContent: "関連コンテンツ情報",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "更新済み " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "通知設定が正常に更新されました",
          preferences_update_failed: "通知設定の更新時にエラーが発生しました",
        },
      },
      browseDialog: {
        useNetworkDrive: "ネットワーク ドライブまたはフォルダを使用",
        ownerGroups: {
          title: "オーナー グループ",
        },
      },
      itemTypeCategories: {
        all: "すべてのグループ",
        application: "アプリケーション",
        "3dProduct": "3D 製品",
        customComponent: "カスタム コンポーネント",
        tool: "アプリケーション",
        profile: "プロファイル",
        material: "材質",
        bolt: "ボルト",
        rebar: "鉄筋",
        reportTemplate: "レポート テンプレート",
        drawingSetup: "図面設定ファイル",
        modelSetup: "モデル設定ファイル",
        environmentFile: "環境ファイル",
        shape: "形状",
        //'shapeCatalog': '形状カタログ',
        //'foreignGeometryDefinition': '外部形状の定義'
      },
      platforms: {
        WIN_X86: "32 ビット版",
        WIN_X64: "64 ビット版",
        ANY: "プラットフォームに依存しない",
      },
      platformsDownload: {
        WIN_X86: "32 ビット版",
        WIN_X64: "64 ビット版",
        ANY: " ",
      },
      contentItemSource: {
        other: "全般 - モデル フォルダ",
        run: "ツール実行",
        bolt: "ボルト セット",
        component: "コンポーネント",
        drawing: "図面設定 (BETA)",
        material: "材質",
        profile: "プロファイル",
        mesh: "溶接金網",
        rebar: "鉄筋",
        shape: "形状",
        geometry: "ジオメトリー",
        modeltemplate: "モデル テンプレート",
        cloningtemplate: "図面複製テンプレート",
        noaction: "無視",
        releasenotes: "リリース ノーツ",
      },
      contentItemHeader: {
        bolt: "ボルト セット",
        component: "コンポーネント",
        drawing: "図面設定 (BETA)",
        material: "材質",
        profile: "プロファイル",
        mesh: "溶接金網",
        rebar: "鉄筋",
        shape: "形状",
      },
      useCategoryGroups: {
        workflow: "ワークフロー",
        structures: "構造",
        drawings: "図面",
        modeling: "モデリング",
      },
      useCategories: {
        title: "用途",
        conceptualDesign: "概念設計",
        designEngineeringAnalysis: "設計/エンジニアリング/解析",
        detailing: "詳細設計",
        fabrication: "製作",
        erectionInstallationPour: "架設/建方/現場打ちコンクリート",
        contracting: "契約",
        scheduling: "スケジュール",
        takeOffEstimation: "見積もり/概算",
        concreteStructures: "現場打ちコンクリート",
        precastConcrete: "プレキャスト コンクリート",
        offshore: "オフショア",
        steelStructures: "鉄骨構造",
        timberStructures: "木造",
        reinforcement: "鉄筋",
        drawingCreation: "図面作成",
        drawingManagement: "図面管理",
        drawingEditing: "図面編集",
        drawingAnnotation: "図面注記",
        viewSimulateNavigate: "表示、シミュレーション、ナビゲーション",
        compareReviewManageData: "データの比較、確認、管理",
        softwareCollaboration: "ソフトウェア コラボレーション",
        productivity: "生産性",
        interoperability: "相互運用性",
        systemSetup: "システム設定",
      },
      measurementUnits: {
        "0": "メートル法",
        "1": "フィート・インチ法",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "SketchUp",
        openBIM: "Open BIM (IFC)",
        standalone: "スタンドアロン",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures15',
        //'TS1': 'Tekla Structures16',
        //'TS2': 'Tekla Structures17',
        NVS: "バージョン指定無し",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight1',
        //'TBS1': 'Tekla BIMsight1.5',
        //'TBS2': 'Tekla BIMsight2',
        //'SK0': 'SketchUp 7',
        //'SK1': 'SketchUp 8'
      },
      qualityTags: {
        experimental: "実験版",
        candidate: "候補版",
        approved: "承認済み",
        standard: "標準",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "英語",
        "zh-Hans": "中国語 - 簡体字",
        "zh-Hant": "中国語 - 繁体字",
        cs: "チェコ語",
        da: "デンマーク語",
        nl: "オランダ語",
        fi: "フィンランド語",
        fr: "フランス語",
        de: "ドイツ語",
        hu: "ハンガリー語",
        it: "イタリア語",
        ja: "日本語",
        ko: "韓国語",
        pl: "ポーランド語",
        pt: "ポルトガル語",
        "pt-br": "ポルトガル語 - ブラジル",
        ru: "ロシア語",
        es: "スペイン語",
        sv: "スウェーデン語",
        no: "ノルウェー語",
        other: "その他",
      },
      isoLangs: {
        ab: {
          name: "アブハーズ語",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "アファール語",
          nativeName: "Afaraf",
        },
        af: {
          name: "アフリカーンス語",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "アカン語",
          nativeName: "Akan",
        },
        sq: {
          name: "アルバニア語",
          nativeName: "Shqip",
        },
        am: {
          name: "アムハラ語",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "アラビア語",
          nativeName: "العربية",
        },
        an: {
          name: "アラゴン語",
          nativeName: "Aragonés",
        },
        hy: {
          name: "アルメニア語",
          nativeName: "Հայերեն",
        },
        as: {
          name: "アッサム語",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "アバール語",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "アヴェスタ語",
          nativeName: "avesta",
        },
        ay: {
          name: "アイマラ語",
          nativeName: "aymar aru",
        },
        az: {
          name: "アゼルバイジャン語",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "バンバラ語",
          nativeName: "bamanankan",
        },
        ba: {
          name: "バシュキール語",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "バスク語",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "ベラルーシ語",
          nativeName: "Беларуская",
        },
        bn: {
          name: "ベンガル語",
          nativeName: "বাংলা",
        },
        bh: {
          name: "ビハール語",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "ビスラマ語",
          nativeName: "Bislama",
        },
        bs: {
          name: "ボスニア語",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "ブルトン語",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "ブルガリア語",
          nativeName: "български език",
        },
        my: {
          name: "ミャンマー語",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "カタロニア語/バレンシア語",
          nativeName: "Català",
        },
        ch: {
          name: "チャモロ語",
          nativeName: "Chamoru",
        },
        ce: {
          name: "チェチェン語",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "チチェワ語/チェワ語/ニャンジャ語",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "中国語、簡体字中国語",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "中国語 - 簡体字",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "中国語 - 繁体字",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "中国語 - 台湾",
          nativeName: "國語",
        },
        cv: {
          name: "チュバシ語",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "コーンウォール語",
          nativeName: "Kernewek",
        },
        co: {
          name: "コルシカ語",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "クリー語",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "クロアチア語",
          nativeName: "hrvatski",
        },
        cs: {
          name: "チェコ語",
          nativeName: "česky, čeština",
        },
        da: {
          name: "デンマーク語",
          nativeName: "dansk",
        },
        dv: {
          name: "ディベヒ語/モルディブ語",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "オランダ語",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "英語",
          nativeName: "English",
        },
        eo: {
          name: "エスペラント語",
          nativeName: "Esperanto",
        },
        et: {
          name: "エストニア語",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "エウェ語",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "フェロー語",
          nativeName: "føroyskt",
        },
        fj: {
          name: "フィジー語",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "フィンランド語",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "フランス語",
          nativeName: "Français",
        },
        ff: {
          name: "フラニ語/フラ語/プラール語/プラル語",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "ガリシア語",
          nativeName: "Galego",
        },
        ka: {
          name: "ジョージア語",
          nativeName: "ქართული",
        },
        de: {
          name: "ドイツ語",
          nativeName: "Deutsch",
        },
        el: {
          name: "ギリシャ語 (現代)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "グアラニー語",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "グジャラート語",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "ハイチ語/ハイチ クレオール語",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "ハウサ語",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "ヘブライ語 (現代)",
          nativeName: "עברית",
        },
        hz: {
          name: "ヘレロ語",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "ヒンディー語",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "ヒリモツ語",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "ハンガリー語",
          nativeName: "Magyar",
        },
        ia: {
          name: "インターリングア",
          nativeName: "Interlingua",
        },
        id: {
          name: "インドネシア語",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "インターリング",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "アイルランド語",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "イボ語",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "イヌピアック語",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "イド語",
          nativeName: "Ido",
        },
        is: {
          name: "アイスランド語",
          nativeName: "Íslenska",
        },
        it: {
          name: "イタリア語",
          nativeName: "Italiano",
        },
        iu: {
          name: "イヌクティトゥト語",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "日本語",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "ジャワ語",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "カラーリット語、グリーンランド語",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "カンナダ語",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "カヌリ語",
          nativeName: "Kanuri",
        },
        ks: {
          name: "カシミール語",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "カザフ語",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "クメール語",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "キクユ語",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "キニヤルワンダ語",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "キルギス語",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "コミ語",
          nativeName: "коми кыв",
        },
        kg: {
          name: "コンゴ語",
          nativeName: "KiKongo",
        },
        ko: {
          name: "韓国語",
          nativeName: "한국어",
        },
        ku: {
          name: "クルド語",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "クワニャマ (クワンヤマ) 語",
          nativeName: "Kuanyama",
        },
        la: {
          name: "ラテン語",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "ルクセンブルク語",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "ルガンダ語",
          nativeName: "Luganda",
        },
        li: {
          name: "リンブルフ (リンブルク) 語",
          nativeName: "Limburgs",
        },
        ln: {
          name: "リンガラ語",
          nativeName: "Lingála",
        },
        lo: {
          name: "ラーオ語",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "リトアニア語",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "ルバ・カタンガ語",
          nativeName: "",
        },
        lv: {
          name: "ラトビア語",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "マンクス語",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "マケドニア語",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "マダガスカル語",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "マレー語",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "マラヤーラム語",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "マルタ語",
          nativeName: "Malti",
        },
        mi: {
          name: "マオリ語",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "マラーティー語",
          nativeName: "मराठी",
        },
        mh: {
          name: "マーシャル語",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "モンゴル語",
          nativeName: "монгол",
        },
        na: {
          name: "ナウル語",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "ナバホ語",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "ノルウェー語ブークモール",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "北ンデベレ語",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "ネパール語",
          nativeName: "नेपाली",
        },
        ng: {
          name: "ンドンガ語",
          nativeName: "Owambo",
        },
        nn: {
          name: "ノルウェー語ニーノシュク",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "ノルウェー語",
          nativeName: "Norsk",
        },
        ii: {
          name: "四川彝語",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "南ンデベレ語",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "オック語",
          nativeName: "Occitan",
        },
        oj: {
          name: "オジブウェ (オジブワ) 語",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "古代教会スラブ語、教会スラブ語、古代ブルガリア語、古代スラブ語",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "オロモ語",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "オリヤ語",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "オセット語",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "パンジャーブ語",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "パーリ語",
          nativeName: "पाऴि",
        },
        fa: {
          name: "ペルシャ語",
          nativeName: "فارسی",
        },
        pl: {
          name: "ポーランド語",
          nativeName: "polski",
        },
        ps: {
          name: "パシュトゥー語",
          nativeName: "پښتو",
        },
        pt: {
          name: "ポルトガル語",
          nativeName: "Português",
        },
        "pt-br": {
          name: "ポルトガル語 - ブラジル",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "ケチュア語",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "ロマンシュ語",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "キルンディ語",
          nativeName: "kiRundi",
        },
        ro: {
          name: "ルーマニア語、モルダビア語、モルドバ語",
          nativeName: "română",
        },
        ru: {
          name: "ロシア語",
          nativeName: "русский",
        },
        sa: {
          name: "サンスクリット語",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "サルデーニャ語",
          nativeName: "sardu",
        },
        sd: {
          name: "シンド語",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "北サーミ語",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "サモア語",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "サンゴ語",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "セルビア語",
          nativeName: "српски језик",
        },
        gd: {
          name: "スコットランド ゲール語/ゲール語",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "ショナ語",
          nativeName: "chiShona",
        },
        si: {
          name: "シンハラ語",
          nativeName: "සිංහල",
        },
        sk: {
          name: "スロバキア語",
          nativeName: "slovenčina",
        },
        sl: {
          name: "スロベニア語",
          nativeName: "slovenščina",
        },
        so: {
          name: "ソマリ語",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "南ソト語",
          nativeName: "Sesotho",
        },
        es: {
          name: "スペイン語", //      'name':'トラディショナル/スペイン語',
          nativeName: "español",
        },
        su: {
          name: "スンダ語",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "スワヒリ語",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "スワジ語",
          nativeName: "SiSwati",
        },
        sv: {
          name: "スウェーデン語",
          nativeName: "svenska",
        },
        ta: {
          name: "タミル語",
          nativeName: "தமிழ்",
        },
        te: {
          name: "テルグ語",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "タジク語",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "タイ語",
          nativeName: "ไทย",
        },
        ti: {
          name: "ティグリニャ語",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "チベット標準語、チベット語、中央チベット語",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "トルクメン語",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "タガログ語",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "ツワナ語",
          nativeName: "Setswana",
        },
        to: {
          name: "トンガ語 (トンガ諸島)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "トルコ語",
          nativeName: "Türkçe",
        },
        ts: {
          name: "ツォンガ語",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "タタール語",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "トウィ語",
          nativeName: "Twi",
        },
        ty: {
          name: "タヒチ語",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "ウイグル語",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "ウクライナ語",
          nativeName: "українська",
        },
        ur: {
          name: "ウルドゥー語",
          nativeName: "اردو",
        },
        uz: {
          name: "ウズベク語",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "ベンダ語",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "ベトナム語",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "ボラピュク語",
          nativeName: "Volapük",
        },
        wa: {
          name: "ワロン語",
          nativeName: "Walon",
        },
        cy: {
          name: "ウェールズ語",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "ウォロフ語",
          nativeName: "Wollof",
        },
        fy: {
          name: "西フリジア語",
          nativeName: "Frysk",
        },
        xh: {
          name: "コサ語",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "イディッシュ語",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "ヨルバ語",
          nativeName: "Yorùbá",
        },
        za: {
          name: "チワン語",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "その他",
          nativeName: "その他",
        },
      },

      details: {
        navigation: {
          content_information: "コンテンツ情報",
          version_and_file_management: "バージョンとファイルの管理",
          details: "ディテール",
          versions: "バージョン",
          analytics: "アナリティクス",
          statistics: "統計",
          manage_access: "アクセス権の管理",
          related_content: "関連",
          properties: "プロパティ",
          support: "会社とサポート",
          release_notes: "リリースノーツ",
          comments: "ユーザーのコメント",
        },
        related_content: {
          other_content_title: "その他のおすすめのコンテンツ",
          related_content_title: "コンテンツの推奨者:  ",
          related_content_url: "コンテンツの URL",
          add_related_content: "関連コンテンツの追加",
          add_related_content_info:
            "関連するオンライン コンテンツはオンライン コンテンツにのみ、ローカル コンテンツはローカル コンテンツにのみ追加できます",
          remove_related_content: "削除",
          relatedContentUrlSubtitle: "コンテンツ ID またはコンテンツへの完全 URL",
          updated_successfully: "関連コンテンツが正常に更新されました",
          update_failed: "関連コンテンツの更新中にエラーが発生しました",
          update_failed_invalid_id: "指定されたコンテンツ ID が無効です",
        },
        actions: {
          move: "移動",
          delete: "削除",
          add_comment: "コメントを追加",
          add_reply: "返信を追加",
          edit_comment: "コメントを編集",
          reply_comment: "返信を追加",
          view_replies: "返信を表示",
          report_bad_comment: "不適切なコメントを報告&rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "タイトルの編集または削除",
            title_or_move_delete: "タイトルの編集、移動、または削除",
            thumbnail: "サムネイルの編集",
            description: "説明の編集",
            groupandcategory: "グループとカテゴリーの編集",
            details: "ディテールの編集",
            versions: "バージョンの編集",
            support: "サポート情報の編集",
            release_notes: "リリースノーツの編集",
            search_criteria: "検索する文字列の編集",
            batchedit_and_translations:
              "翻訳の機能を使って、複数の言語でコンテンツを公開できます。 コンテンツの翻訳バージョンは、それぞれのユーザーの Warehouse UI 言語の選択に基いて表示されます。 一括編集を使用すると、同時に複数のアイテムを編集できます。 最初に領域を選択し、次に編集するアイテムを選択します。",
          },
        },
        links: {
          link: "リンク",
          select_collection_placeholder: "リンクさせるコレクションを選択",
          linking_collections_label: "これにリンクされているコレクション",
          private_content_note: "注記:  非公開のコンテンツは、アクセス権を持つユーザーにのみ表示されます。",
          share_on_social_media: "共有: ",
          report_bad_content: "不適切なコンテンツを報告&rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "違法なコンテンツの報告 &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "アラートの受取りに登録すると、自分の Tekla Account の表示名と組織名 (該当する場合) をコンテンツ オーナーと共有することに同意したものとされます。",
          alert_me: "アラートを受け取る",
          unsubscribe: "登録解除",
          subscribed_successfully: "正常に登録されました",
          unsubscribed_successfully: "正常に登録解除されました",
          subscribers_label: "登録者",
        },
        reviews: {
          reviewers_label: "レビュー担当者",
        },
        comments: {
          replies: "返信",
        },
        scan: {
          soft_failed_binaries_info: "ウイルス スキャンに失敗しました  ",
          hard_failed_binaries_info: "ウイルス スキャンは完了していません ",
          infected_binaries_info: "ウイルス スキャンで感染が見つかりました ",
          what_now: "どうすればよいですか?",
          popup: {
            soft_failed_binaries_title: "失敗したバイナリ",
            hard_failed_binaries_title: "失敗したバイナリ",
            infected_binaries_title: "感染バイナリ",
            soft_failed_binaries_info:
              "アップロードしたファイルのウイルス スキャンに失敗しました。 これは一時的なものです。 ファイルを削除し、もう一度アップロードしてください。 すべてのファイルは、他のユーザーに表示される前にウイルス スキャンに合格する必要があります。",
            hard_failed_binaries_info:
              "ウイルス スキャンは完了していません。 ファイルを削除してください。 すべてのファイルは、他のユーザーに表示される前にウイルス スキャンに合格する必要があります。",
            infected_binaries_info:
              "ウイルス スキャンで、アップロードしたファイルに感染が見つかりました。 ファイルを削除してください。 すべてのファイルは、他のユーザーに表示される前にウイルス スキャンに合格する必要があります。",
            version: "バージョン:  ",
            fileName: "ファイル名:  ",
          },
        },
        status: {
          content_banned_info:
            "このコンテンツは、管理者によってブロックされています。現在、コンテンツ所有者以外のユーザーは利用できません。詳しくは、<a href='https://support.tekla.com/contact-us' target='_blank'>サポートにお問い合わせ</a>ください。",
          content_archived_info:
            "このコンテンツはアーカイブされています。編集権限のないユーザーが直接リンクを介してコンテンツにアクセスしようとすると、フロントページにリダイレクトされます。",
          archived: "アーカイブ済み",
        },
        modified_by: function (name) {
          return " 変更者 " + name;
        },
        created_by: function (name) {
          return "作成者 " + name;
        },
        deleteEntity: function (title) {
          return "削除 " + title + "?";
        },
        userComments: "ユーザーのコメント",
        comment_added: "コメントが正常に追加されました",
        comment_updated: "コメントが正常に更新されました",
        comment_deleted: "コメントが正常に削除されました",
        comment_add_failed: "コメントの追加に失敗しました",
        comment_update_failed: "コメントの更新に失敗しました",
        comment_delete_failed: "コメントの削除に失敗しました",
        comment_delete_confirmation: "コメント アイテムを削除しますか?",
        name: "名前",
        details: "ディテール",
        contentOwner: "作成者",
        contentOwnerGroup: "コンテンツ オーナー グループ",
        contentGroup: "コンテンツ グループ",
        thumbnail: "サムネイル",
        tooltip: "ツールティップ",
        description: "説明",
        additional_information: "追加情報",
        images: "製品イメージ",
        installationInfo: "インストール情報",
        productCode: "製品コード",
        copyrightInfo: "著作権",
        productExpirationDate: "製品の有効期限",
        helpUrl: "ウェブサイト&rsaquo;",
        supportUrl: "サポート&rsaquo;",
        specialTermsUrl: "使用許諾契約&rsaquo;",
        releaseNotesUrl: "リリース ノーツ",
        supportForumUrl: "Discussion Forum&rsaquo;",
        itemTypeCategories: "グループ",
        useCategories: "カテゴリー",
        category: "カテゴリー:",
        group: "グループ:",
        compatibleSoftwareProducts: "互換性のあるソフトウェア製品",
        compatibleOperatingSystems: "互換性のあるオペレーティング システム",
        locationRestrictions: "製品の提供状況:",
        supportedLanguages: "言語:",
        measurementUnits: "単位系:",
        tags: "タグとキーワード:",
        prerequisites: "前提条件:",
        releaseNotes: "リリース ノーツ:",
        versions: "バージョン",
        testedVersions: "互換性のあるバージョン",
        qualityTag: "品質 - オーナーが評価",
        otherAttributes: "その他の属性",
        labels: "検索する文字列",
        supportInformation: "サポート",
        updateInformation: "更新",
        latestUpdate: "最終更新",
        totalDownloads: "ダウンロード総数",
        licenses: "ライセンス",
        maintenanceRequired: "ダウンロードには Tekla 保守契約が必要です",
        tekla_maintenance_content: "Tekla 保守契約コンテンツ",
        download_requires_tekla_maintenance: "ダウンロードには Tekla 保守契約が必要です",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "更新に成功しました",
        update_failed: "更新に失敗しました",
        update_failed_invalid_tag: "更新に失敗しました:  無効なタグ",
        update_failed_details_max_length_exceeded: "更新に失敗しました:  ディテールが長すぎます",
        show_version_details: "詳細情報",
        content_removed: "コンテンツが削除されました",
        no_versions_available:
          "使用可能なバージョンがありません。 更新内容を表示するのに時間がかかる場合があります。 ブラウザーを更新して変更を表示します。",
        thumbnail_deleted: "サムネイルが削除されました",
        thumbnail_updated: "サムネイルが更新されました",
        verify_service_plugin_is_running:
          "Tekla Warehouse Service プラグインが正しく動作していることを確認してください",
        verify_ts_connection: "Tekla Structures が実行されていることを確認してください",
        download_tos: {
          title: "品質、サポート、プライバシー",
          content_1: "ダウンロードしたコンテンツが自分の品質標準に従っていることを確認してください。 Trimble は、サード パーティのコンテンツに関するサポートを行っておらず、また責任も負いません。 サポートについては、コンテンツの提供元に直接お問い合わせください。",
          content_2: "コンテンツの変更についての最新情報の提供など、正当な目的のために、国名や組織名がサービスに公開されたり、コンテンツ オーナーに開示されたりする場合があります。 これらの通知の配信を停止することもできます。",
          content_3: 'Trimbleコンテンツ拡張には、<a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimbleの利用規約</a>が適用されます。サードパーティのコンテンツには、サードパーティのライセンス条件が適用される場合があります。<a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimbleのプライバシー通知</a>をご覧ください。',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "これは、無償の Tekla Structures BIM ストレージです。 検索、インポート、インストール、また社内や世界中での共有が可能です。 高品質なモデルを効率的に作成してください。",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse は、Tekla Structures モデリングのツール、インテリジェント部材、およびテンプレートのための無料のオンラインおよびローカル ライブラリです。",
        },
        search: {
          title: "Tekla Warehouse の検索",
          description:
            "Tekla 拡張ツール、3D 部材、形状、カスタム コンポーネント、プロファイル、鉄骨やコンクリート グレードなどの材質、鉄筋、金網、テンプレートを探すことができます",
        },
        about: {
          title: "Tekla Warehouse についての情報",
          description:
            "Tekla Warehouse は、ユーザーの製品に無料のマーケティング チャンネルを提供し、ユーザーがコンテンツを完全にコントロールできるようにします",
          developers: {
            title: "デフォルト デベロッパーのタイトル",
            description: "デフォルト デベロッパーの説明",
          },
          usage: {
            title: "デフォルト用途のタイトル",
            description: "デフォルト用途の説明",
          },
          getting_started: {
            title: "デフォルトの利用開始のタイトル",
            description: "デフォルトの利用開始の説明",
          },
          content: {
            title: "デフォルト コンテンツのタイトル",
            description: "デフォルト コンテンツの説明",
          },
          using_content: {
            title: "デフォルトのコンテンツの使用のタイトル",
            description: "デフォルトのコンテンツの使用の説明",
          },
          creating_content: {
            title: "デフォルトのコンテンツの作成のタイトル",
            description: "デフォルトのコンテンツの作成の説明",
          },
        },
        collection: {
          title: "デフォルトのコレクションのタイトル",
          description: "デフォルトのコレクションの説明",
        },
        my_warehouse: {
          title: "My Tekla Warehouse",
          description: "Tekla Warehouse コンテンツの管理",
        },
      },
      upload: {
        actions: {
          cancel: "キャンセル",
          previous: "&lsaquo;前へ",
          next: "次へ&rsaquo;",
        },
        content: {
          add_content: "ファイルの追加",
        },
        collection: {
          heading: "コレクションの選択または作成",
          subHeading:
            "コレクション グループのコンテンツ。 オンライン コレクションを使用してコンテンツを公開したり、ローカル コレクションを使用してコンテンツを同僚と共有したりできます。 また、個人用にローカル コレクションを使用することもできます。",
          loading: "コレクションを読み込み中...",
          selectFromMyCollections: "マイ コレクションから選択",
          description_of_the_collection: "説明",
          title_validation: {
            missing: "タイトルを入力してください。",
            too_short: function (length) {
              return "タイトルは  " + length + "  文字以上でなければなりません。";
            },
            too_long: function (length) {
              return "タイトルは  " + length + "  文字以内でなければなりません。";
            },
          },
        },
        package: {
          basic_information: "基本情報",
          code_name: "コード名",
          code_name_info: "このコンテンツのコード名を一意の識別子として入力してください。コード名には、小文字、数字、および特殊文字 _ - @ を含めることができます。保存後にコード名を変更することはできません。",
          code_name_validation: {
            missing: "コード名を入力する必要があります",
            wrong_format: "コード名には、小文字、数字、および次の特殊文字を含めることができます:_ - @ ",
            not_unique: "このコードネームはすでに使用されています。一意のコード名を入力する必要があります。",
          },
          search_criteria: "検索する文字列",
          support: "サポート",
          title: "タイトル",
          thumbnail: "サムネイル",
          "3D_pic": "3D モデル",
          title_validation: {
            missing: "タイトルを入力してください。",
            too_short: function (length) {
              return "タイトルは  " + length + "  文字以上でなければなりません。";
            },
          },
          description_validation: {
            missing: "説明を入力してください。",
            too_short: function (length) {
              return "説明は  " + length + "  文字以上でなければなりません。";
            },
          },
          wrong_file_type: "ファイル タイプが間違っています。",
          selected_image_is_too_big: "選択した画像が大きすぎます。",
        },
        version: {
          select_version: "バージョンを選択",
          select_quality: "品質を選択",
          versions: "バージョン",
          displayNameNew: "バージョンの表示名",
          copy_from_version: "既存のバージョンからメタデータをコピー (オプション)",
          displayName: "表示名",
          content_information: "コンテンツ情報",
          back_to_content_information: "&lsaquo;コンテンツ情報に戻る",
          version_and_file_management: "バージョンとファイルの管理",
          there_s_no_versions_yet: "バージョンはまだありません。",
          create_a_version_to_add_files: "ファイルを追加するには、バージョンを作成してください。",
          add_version: "バージョンの追加",
          version_information: "バージョン情報",
          delete_version: "バージョンの削除",
          no_versions_available:
            "使用可能なバージョンがありません。 更新内容を表示するのに時間がかかる場合があります。",
          update_start_title_html: '<div class="spinner spinner-white">更新</div>',
          update_start_message: "これは時間がかかる場合があります。",
          version_was_created: "バージョンが作成されました。",
          version_was_created_with_delay: "バージョンが作成されました。 数分後に変更内容が表示されます。",
          version_was_updated: "バージョンが更新されました。",
          version_was_deleted: "バージョンが削除されました",
          version_was_deleted_with_delay: "バージョンが削除されました。 数分後に変更内容が表示されます。",
          confirm_delete: function (title) {
            return 'このバージョン "' + title + '" を削除しますか?';
          },
        },
        publish: {
          package_was_uploaded: "パッケージがアップロードされました。",
          couldn_t_create_a_package: "パッケージを作成できませんでした。 変更が元に戻されます。",
          uploading_title_html: '<div class="spinner spinner-white">アップロード</div>',
          uploading_text: "これは時間がかかる場合があります。",
          select_organization: "組織の選択",
          collection_visibility: "コレクションの表示/非表示:",
          actions: {
            create: "作成",
            publish: "公開",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "このコンテンツは、ローカルまたはネットワーク ドライブにアクセスできるすべてのユーザーに表示されます。",
          this_content_will_be_visible_only_to_you: "このコンテンツはあなたにのみ表示されます。",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "このコンテンツはあなたと組織のすべてのユーザーにのみ表示されます。",
          this_content_will_be_visible_to_everyone_after_publishing:
            "このコンテンツは、公開後すべてのユーザーに表示されます。",
          public_content_disclaimer:
            "自分がアップロードしたすべてのコンテンツは、自分の責任となります。 自分のコンテンツが無害であり、そのコンテンツをアップロードする権利が自分にあり、知的財産権を一切侵害しないことを確認してください。 このサービスへの投稿は、アップロードするコンテンツの使用に適用される可能性があるすべての使用条件に同意したことになります。",
        },
        metadata: {
          title: "メタデータ",
          subtitle: "基本データ",
          deleteThumbnail: "サムネイルの削除",
          buttonCreate: "作成",
        },
        basicdata: {
          title: "コンテンツの追加",
          clickButtonToSelectFile: "ボタンをクリックしてファイルを選択してください",
          thumbnail: "サムネイル",
          dropImageHere: "ここに画像をドロップ",
        },
        "3dGeometry": {
          title: "3D 製品カタログの作成",
          buttonAddEntity: "3D 製品の追加",
          buttonAdd: "カスタム属性の追加",
          errorAttributeAlreadyExists: "同じ名前の属性が既に存在します。",
          buttonCreatePackage: "パッケージの作成",
          buttonCreatePackages: "複数のパッケージの作成",
          addCustomAttributePlaceholder: "属性名",
        },
        please_log_in_fragments: {
          please: "オンラインコンテンツをアップロードするには、 ",
          log_in: "サインイン",
          or: " または ",
          register: "登録",
          to_upload_online_content: " してください。",
        },
        header: {
          upload_header: "アップロード",
          collection: "コレクション",
          content: "コンテンツ",
          version: "バージョン",
          finish: "終了",
        },
        contentOwner: "作成者",
        contentOwnerGroup: "コンテンツの公開",
        select_action: "アクションを選択",
        tooltip: "ツールティップ",
        description: "説明",
        copyright: "著作権",
        tags: "タグとキーワード",
        tagsSubtitle:
          "カンマで区切ります。例:  キーワード 1, キーワード 2。 キーワードが複数の単語で構成されている場合は、単語間にアンダースコア (_) を使用します。単語間にスペースは使用できません。 アンダースコアとカンマは別として、その他の特殊文字は使用できません。",
        images: "画像",
        installationInfo: "インストール情報",
        productCode: "製品コード",
        copyrightInfo: "著作権情報",
        Details: "ディテール",
        specialTermsUrl: "使用許諾契約",
        productExpirationDate: "製品の有効期限",
        helpUrl: "ウェブサイト",
        helpUrlSubtitle: "完全な URL を挿入",
        detailsSubtitle:
          "このフェーズでは、ディテール フィールドに追加できるのはプレーンテキストのみです。 コンテンツの作成後、ディテールを変更して HTML 形式を含めることができます。",
        specialTermsUrlSubtitle:
          "コンテンツ オーナーは、所有するコンテンツについて規約を追加することができます。 オーナーは、規約の掲載場所の URL を提供する必要があります。 ただしこのような個別の規約を Trimble のサービス利用規約に優先することはできません。",
        releaseNotes: "リリース ノーツ ファイル",
        releaseNoteFileSubTitle: "ファイルとしてリリース ノーツを追加",
        supportUrl: "ヘルプとサポート",
        supportUrlSubtitle: '完全な URL を挿入するか、電子メールの場合は "mailto:" プレフィックスを挿入します',
        supportForumUrl: "Discussion Forum (ウェブサイト)",
        supportForumUrlSubtitle: "完全な URL を挿入",
        measurementUnits: "単位系",
        itemTypeCategories: "グループ",
        useCategories: "カテゴリー",
        compatibleSoftwareProducts: "互換性のあるソフトウェア製品",
        testedVersions: "互換性のあるバージョン",
        compatibleOperatingSystems: "互換性のあるオペレーティング システム",
        locationRestrictions: "製品の提供状況",
        supportedLanguages: "言語",
        select_releasenotes_file: "ファイルの選択",
        qualityTag: "品質 - オーナーが評価",
        requireMaintenance: "ダウンロードには Tekla 保守契約が必要です",
        contentOwnerGroupAsMyself: "ユーザーとして",
        contentOwnerGroupAsUserGroup: "ユーザー グループとして",
        selectUserGroupForContentOwner: "コンテンツ オーナーのユーザー グループの選択",
        defaultUploadTemplate: "デフォルト パッケージ",
        templateFor3DGeometryFiles: "3D 形状パッケージ",
        title: "タイトル",
        details: "ディテール",
        selectContentOwner: "コンテンツ オーナーの選択",
        selectContentType: "タイプ",
        errorWrongfiletype: "ファイル タイプが間違っています。",
        errorImageTooBig: "選択した画像が大きすぎます。",
        errorBlankTitle: "タイトルを空白にすることはできません。",
        commonLabelsTitle: "すべてのパッケージの共通ラベル",
      },
      profile: {
        content_usage: "コンテンツの利用",
        profile_updated: "プロファイルが更新されました",
        invitation_accepted: function (target) {
          return "招待が承諾されました!  次にアクセスできるようになりました:  " + target + ".";
        },
        user_groups: {
          edit_user_group: "ユーザー グループの編集",
          user_group_updated: "ユーザー グループが更新されました",
          create_a_new_user_group: "新しいユーザー グループの作成",
          name_of_the_user_group: "ユーザー グループの名前",
          description: "説明",
          group_visibility: "グループの表示/非表示",
          remove: '"本当によろしいですか?"',
          remove_invitations: '"本当によろしいですか?"',
          remove_members: '"本当によろしいですか?"',
          invitations_removed: "招待が削除されました!",
          members_list_updated: "メンバー リストが更新されました!",
          user_group_created: "ユーザー グループが作成されました",
        },
        members: {
          update_selected_members: "選択したメンバーの更新",
          new_role: "新しい役割",
          members_list_updated: "メンバー リストが更新されました!",
          edit_user_group: "ユーザー グループの編集",
          delete_user_group: "ユーザー グループの削除",
          filter: "フィルター",
          user_group_members: "ユーザー グループのメンバー",
          update_members: "メンバーの更新",
          remove_members: "メンバーの削除",
          pending_invitations: "保留中の招待",
          invite_member: "メンバーを招待",
          update_invitations: "招待の更新",
          remove_invitations: "招待の削除",
        },
        subTabs: {
          alerts: "マイ アラート",
          collections: "コレクション",
          likes: "いいね!",
        },
        admin: {
          grant_sales_admin: "ユーザーに営業管理者の役割を付与",
          grant_analyst_role: "ユーザーにアナリストの役割を付与",
          role_granted: "役割を付与しました",
          role_removed: "役割を削除しました",
          offline_to_online: "オフライン コンテンツをオンラインに変換",
          use_elastic_search: "エラスティック サーチを使用",
        },
        sales: {
          title: "セールス",
        },
        subtitlePreferences: "検索の設定",
        preferenceInfo:
          'これらのフィルターは、Tekla Warehouse <a href="#/landing">ホーム ページ</a>のおすすめコンテンツを提示するためのものです。',
        clearFilters: "フィルターをクリア&rsaquo;",
        admin_role: "管理者",
        sales_role: "セールス",
        admin_mode: "管理者モード:",
        firstName: "名",
        lastName: "姓",
        displayName: "表示名:",
        organization: "組織名:",
        email: "電子メール:",
        teklaId: "Tekla ID",
        maintenanceAccess: "Tekla 保守契約コンテンツへのアクセス: ",
        myLocations: "プロジェクトの場所",
        measurementUnits: "単位",
        useCategories: "対象",
        itemTypeCategories: "以下のタイプに関心がある",
        languages: "言語",
        operatingSystems: "オペレーティング システム",
        software: "ソフトウェア製品",
        user_id: "ユーザー ID:",
        organization_id: "組織 ID:",
        edit_in_atc: "Tekla Account のマイ プロファイルの編集",
        no_likes: "いいね! なし",
        no_alerts: "登録なし",
        clear_alerts: "アラートをクリア",
        alerts_info_html:
          "次の変更のいずれかが行われると、アラートを受け取ります<br>" +
          "<b>コンテンツ:</b> コンテンツ アイテムのタイトル、説明、検索する文字列、またはメタデータが変更された場合、コンテンツの新しいバージョンが追加された場合、またはバージョンが削除された場合。<br>" +
          "<b>コレクション:</b> タイトルまたは説明が編集されるか、コンテンツが追加または削除された場合。",
      },
      uploader: {
        placeholders: {
          productCode: "製品コード",
          title: "名前",
          description: "説明",
          productWeight: "製品重量",
          weightUnit: "重量の単位",
          material: "材質",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D 製品カタログ",
          addToolButton: "アプリケーション",
          select_files: "ディスクからのファイル",
          select_files_from_ts: "Tekla Structures からのファイル",
          partnerDownloadLink: "パートナー ダウンロード リンク",
        },
        headings: {
          geometryFile: "形状ファイル",
          thumbnailFile: "サムネイル ビットマップ (.bmp)",
          title: "名前",
          description: "説明",
          productCode: "製品コード",
          productWeight: "製品重量",
          weightUnit: "重量の単位",
          material: "材質",
        },
      },
      constants: {
        countries: {
          GLOBAL: "グローバル",
          AF: "アフガニスタン",
          AX: "オーランド諸島",
          AL: "アルバニア",
          DZ: "アルジェリア",
          AS: "米領サモア",
          AD: "アンドラ",
          AO: "アンゴラ",
          AI: "アングィラ",
          AQ: "南極",
          AG: "アンティグア・バーブーダ",
          AR: "アルゼンチン",
          AM: "アルメニア",
          AW: "アルバ",
          AU: "オーストラリア",
          AT: "オーストリア",
          AZ: "アゼルバイジャン",
          BS: "バハマ",
          BH: "バーレーン",
          BD: "バングラデシュ",
          BB: "バルバドス",
          BY: "ベラルーシ",
          BE: "ベルギー",
          BZ: "ベリーズ",
          BJ: "ベナン",
          BM: "バミューダ",
          BT: "ブータン",
          BO: "ボリビア",
          BQ: "ボネール島",
          BA: "ボスニア・ヘルツェゴビナ",
          BW: "ボツワナ",
          BV: "ブーベ島",
          BR: "ブラジル環境",
          IO: "英領インド洋地域",
          BN: "ブルネイ・ダルサラーム国",
          BG: "ブルガリア",
          BF: "ブルキナファソ",
          BI: "ブルンジ",
          KH: "カンボジア",
          CM: "カメルーン",
          CA: "カナダ",
          CV: "カーボベルデ",
          KY: "ケイマン諸島",
          CF: "中央アフリカ共和国",
          TD: "チャド",
          CL: "チリ",
          CN: "中国環境",
          CX: "クリスマス島",
          CC: "ココス (キーリング) 諸島",
          CO: "コロンビア",
          KM: "コモロ",
          CG: "コンゴ共和国",
          CD: "コンゴ民主共和国",
          CK: "クック諸島",
          CR: "コスタリカ",
          CI: "コートジボワール",
          HR: "クロアチア",
          CU: "キューバ",
          CW: "キュラソー島",
          CY: "キプロス",
          CZ: "チェコ共和国",
          DK: "デンマーク",
          DJ: "ジブチ",
          DM: "ドミニカ",
          DO: "ドミニカ共和国",
          EC: "エクアドル",
          EG: "エジプト",
          SV: "エルサルバドル",
          GQ: "赤道ギニア",
          ER: "エリトリア",
          EE: "エストニア",
          ET: "エチオピア",
          FK: "フォークランド諸島 (マルビナス)",
          FO: "フェロー諸島",
          FJ: "フィジー",
          FI: "フィンランド",
          FR: "フランス環境",
          GF: "フランス領ギアナ",
          PF: "フランス領ポリネシア",
          TF: "フランス領南方・南極地域",
          GA: "ガボン",
          GM: "ガンビア",
          GE: "ジョージア",
          DE: "ドイツ環境",
          GH: "ガーナ",
          GI: "ジブラルタル",
          GR: "ギリシャ",
          GL: "グリーンランド",
          GD: "グレナダ",
          GP: "グアドループ",
          GU: "グアム",
          GT: "グアテマラ",
          GG: "ガーンジー",
          GN: "ギニア",
          GW: "ギニアビサウ",
          GY: "ガイアナ",
          HT: "ハイチ",
          HM: "ハード島およびマクドナルド諸島",
          VA: "バチカン市国",
          HN: "ホンジュラス",
          HK: "香港",
          HU: "ハンガリー",
          IS: "アイスランド",
          IN: "インド環境",
          ID: "インドネシア",
          IR: "イラン",
          IQ: "イラク",
          IE: "アイルランド",
          IM: "マン島",
          IL: "イスラエル",
          IT: "イタリ環境",
          JM: "ジャマイカ",
          JP: "日本環境",
          JE: "ジャージー",
          JO: "ヨルダン",
          KZ: "カザフスタン",
          KE: "ケニア",
          KI: "キリバス",
          KW: "クウェート",
          KG: "キルギスタン",
          LA: "ラオス",
          LV: "ラトビア",
          LB: "レバノン",
          LS: "レソト",
          LR: "リベリア",
          LY: "リビア",
          LI: "リヒテンシュタイン",
          LT: "リトアニア",
          LU: "ルクセンブルグ",
          MO: "マカオ",
          MK: "マケドニア",
          MG: "マダガスカル",
          MW: "マラウイ",
          MY: "マレーシア",
          MV: "モルディブ",
          ML: "マリ",
          MT: "マルタ",
          MH: "マーシャル諸島",
          MQ: "マルティニーク",
          MR: "モーリタニア",
          MU: "モーリシャス",
          YT: "マヨット",
          MX: "メキシコ",
          FM: "ミクロネシア",
          MD: "モルドバ",
          MC: "モナコ",
          MN: "モンゴル",
          ME: "モンテネグロ",
          MS: "モントセラト",
          MA: "モロッコ",
          MZ: "モザンビーク",
          MM: "ミャンマー (ビルマ)",
          NA: "ナミビア",
          NR: "ナウル語",
          NP: "ネパール",
          NL: "オランダ",
          NC: "ニューカレドニア",
          NZ: "ニュージーランド",
          NI: "ニカラグア",
          NE: "ニジェール",
          NG: "ナイジェリア",
          NU: "ニウエ",
          NF: "ノーフォーク島",
          KP: "北朝鮮",
          MP: "北マリアナ諸島",
          NO: "ノルウェー環境",
          OM: "オマーン",
          PK: "パキスタン",
          PW: "パラオ",
          PS: "パレスチナ自治区",
          PA: "パナマ",
          PG: "パプア ニューギニア",
          PY: "パラグアイ",
          PE: "ペルー",
          PH: "フィリピン",
          PN: "ピトケアン",
          PL: "ポーランド",
          PT: "ポルトガール環境",
          PR: "プエルトリコ",
          QA: "カタール",
          RE: "レユニオン",
          RO: "ルーマニア",
          RU: "ロシア連邦",
          RW: "ルワンダ",
          BL: "サンバルテルミー",
          SH: "セントヘレナ",
          KN: "セントクリストファー・ネイビス",
          LC: "セントルシア",
          MF: "サンマルタン島",
          PM: "サンピエール島・ミクロン島",
          VC: "セントビンセントおよびグレナディーン諸島",
          WS: "サモア",
          SM: "サンマリノ",
          ST: "サントメ・プリンシペ",
          SA: "サウジアラビア",
          SN: "セネガル",
          RS: "セルビア",
          SC: "セーシェル",
          SL: "シエラレオネ",
          SG: "シンガポール",
          SX: "シントマールテン島",
          SK: "スロバキア",
          SI: "スロベニア",
          SB: "ソロモン諸島",
          SO: "ソマリア",
          ZA: "南アフリカ環境",
          GS: "サウスジョージア島",
          KR: "韓国",
          SS: "南スーダン",
          ES: "スペイン環境",
          LK: "スリランカ",
          SD: "スーダン",
          SR: "スリナム",
          SJ: "スバールバル諸島・ヤンマイエン島",
          SZ: "スワジランド",
          SE: "スウェーデン環境",
          CH: "スイス",
          SY: "シリア・アラブ共和国",
          TW: "台湾環境",
          TJ: "タジキスタン",
          TZ: "タンザニア",
          TH: "タイ",
          TL: "東ティモール",
          TG: "トーゴ",
          TK: "トケラウ",
          TO: "トンガ",
          TT: "トリニダード・トバゴ",
          TN: "チュニジア",
          TR: "トルコ",
          TM: "トルクメニスタン",
          TC: "タークス・カイコス諸島",
          TV: "ツバル",
          UG: "ウガンダ",
          UA: "ウクライナ",
          AE: "アラブ首長国連邦",
          GB: "英国環境",
          US: "米国",
          UM: "米領太平洋諸島",
          UY: "ウルグアイ",
          UZ: "ウズベキスタン",
          VU: "バヌアツ",
          VE: "ベネズエラ",
          VN: "ベトナム",
          VG: "バージン諸島 (英国)",
          VI: "バージン諸島 (米国)",
          WF: "ウォリス・フツナ",
          EH: "西サハラ",
          YE: "イエメン",
          ZM: "ザンビア",
          ZW: "ジンバブエ",
        },
        sortOptions: {
          "-modifyTime": "最終更新日",
          "-modifiedAt": "最終更新日",
          title: "アルファベット順 A-Z",
          "-title": "アルファベット順 Z-A",
          "-reviewCount": "いいね! が最も多い",
          name: "名前",
          role: "役割",
          email: "電子メール",
          "-relevance": "関連性",
          "-createdAt": "最終作成日",
          displayName: "アルファベット順 A-Z",
          "-displayName": "アルファベット順 Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "今日",
          yesterday: "昨日",
          this_week: "今週",
          previous_week: "先週",
          this_month: "今月",
          previous_month: "先月",
          this_year: "今年",
          previous_year: "昨年",
          beginning_of_time: "時間の開始",
          custom: "カスタム タイムフレーム",
        },
        eventTypeOptions: {
          downloads: "ダウンロード",
          views: "表示",
          all_events: "すべてのイベント",
        },
      },
    });
  },
};
