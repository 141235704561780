import * as React from "react";
import { useContext, useState, Fragment, useEffect } from "react";
import { observer } from "mobx-react-lite";
import _ from "underscore";
import { Link } from "react-router";
import { ObjectTypeEnum } from "models/enums";

import { IBatchCollection, IBatchVersion, IBatchEntity } from "../../../models/dataModel";

import { RootStore, RootContext } from "../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "./contentManagerStore";

const ClickableListItemLabel = ({ item }: { item: IBatchCollection | IBatchEntity | IBatchVersion }) => {
  const rootStore: RootStore = useContext(RootContext);
  const dontShowLink =
    (item as IBatchVersion).type === ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION ||
    item.id === "invalid-packages-collection";

  function getLink(): string {
    if ((item as IBatchCollection).packages) {
      return "/collections/online/" + item.id;
    } else {
      return "/catalog/details/" + item.id;
    }
  }

  function getBannedDate(): string {
    if (!!item.attributes && !!item.attributes.bannedDate) {
      return new Date(item.attributes.bannedDate).toDateString();
    } else {
      return "";
    }
  }

  return (
    <Fragment>
      {dontShowLink ? (
        item.title
      ) : (
        <Link className="link-to-item" target="_blank" rel="noopener noreferrer" to={getLink()}>
          {item.title}
        </Link>
      )}
      {item.isHidden && <span className="small">{rootStore.getTranslation("profile.admin.hidden_package")}</span>}
      {item.isBanned && (
        <span className="small">
          {` (${rootStore.getTranslation("profile.admin.banned_content")} ${getBannedDate()})`}
        </span>
      )}
    </Fragment>
  );
};

const VersionListItem = observer(({ version }: { version: IBatchVersion }) => {
  return (
    <Fragment>
      <div className="checkbox select-version">
        <label htmlFor={`version-${version.id}`}>
          <ClickableListItemLabel item={version} />
        </label>
      </div>
    </Fragment>
  );
});

/**
 * Renders an individual content list item.
 * @param content content
 * @param isCollectionSelected is the collection content belongs to selected
 */
const ContentListItem = observer(
  ({ content, isCollectionSelected }: { content: IBatchEntity; isCollectionSelected: boolean }) => {
    const rootStore: RootStore = useContext(RootContext);
    const store: ContentManagerStore = useContext(ContentManagerStoreContext);
    const hasContent = !!content.versionCount && content.versionCount > 0;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
      store.selectListItem(content, isCollectionSelected);
    }, [isCollectionSelected]);

    function togglePackage() {
      store.getVersionsForPackage(content);
      setIsOpen(!isOpen);
    }

    function contentSelectionChange() {
      if (!isCollectionSelected) {
        store.selectListItem(content, !content.selected);
      }
    }

    return (
      <Fragment>
        <div className="treecontrols">
          {hasContent && (
            <Fragment>
              {isOpen ? (
                <div className="close" onClick={togglePackage} data-testid={`close-collection-${content.id}`}>
                  {rootStore.getTranslation("batch_edit.content.treecontrol.close")}
                </div>
              ) : (
                <div className="open" onClick={togglePackage} data-testid={`open-collection-${content.id}`}>
                  {rootStore.getTranslation("batch_edit.content.treecontrol.open")}
                </div>
              )}
            </Fragment>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <div className="checkbox select-package">
            <input
              className="white"
              data-testid={`select-package-${content.id}`}
              id={`package-${content.id}`}
              type="checkbox"
              checked={content.selected}
              onChange={contentSelectionChange}
            />
            <label htmlFor={`package-${content.id}`}>
              <ClickableListItemLabel item={content} />
            </label>
          </div>
        </div>
        {isOpen && (
          <div className="versions-list" data-testid={`version-list-for-${content.id}`}>
            {content.loading ? (
              <div className="spinner" data-testid={`loading-versions-for-${content.id}`}>
                {rootStore.getTranslation("shared.spinner_loading")}
              </div>
            ) : (
              (content.versions || []).map((version: IBatchVersion, i: number) => {
                return (
                  <div className="versions-list-item" key={i}>
                    <VersionListItem version={version} />
                  </div>
                );
              })
            )}
          </div>
        )}
      </Fragment>
    );
  },
);

const PackageList = ({ packages, isSelected }: { packages: IBatchEntity[]; isSelected: boolean }) => {
  return (
    <div className="content-list">
      {(packages || []).map((content: IBatchEntity, i: number) => {
        return (
          <div className="content-list-item" key={i}>
            <ContentListItem content={content} isCollectionSelected={isSelected} />
          </div>
        );
      })}
    </div>
  );
};

/**
 * Renders a collection list item for the list of available source collections.
 * @param collection collection
 * @param open is the collection open
 */
export const CollectionListItem = observer(({ collection, open }: { collection: IBatchCollection; open?: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const [isOpen, setIsOpen] = useState<boolean>(open || collection.open || false);
  const hasContent = !!collection.packagesCount && collection.packagesCount > 0;

  function toggleCollection() {
    setIsOpen(!isOpen);
  }

  function collectionSelectionChange(event) {
    if (!isOpen) {
      toggleCollection();
      store.selectListItem(collection, event.target.checked);
      selectAllContent();
    } else {
      store.selectListItem(collection, event.target.checked);
      selectAllContent();
    }
  }

  function selectAllContent() {
    _.each(collection.packages || [], (content: IBatchEntity) => {
      store.selectListItem(content, !!collection.selected);
    });
  }

  return (
    <Fragment>
      <div className="treecontrols">
        {hasContent && (
          <Fragment>
            {isOpen ? (
              <div className="close" onClick={toggleCollection} data-testid={`close-collection-${collection.id}`}>
                {rootStore.getTranslation("batch_edit.content.treecontrol.close")}
              </div>
            ) : (
              <div className="open" onClick={toggleCollection} data-testid={`open-collection-${collection.id}`}>
                {rootStore.getTranslation("batch_edit.content.treecontrol.open")}
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div className="checkbox">
          <input
            className="white"
            id={`collection-item-${collection.id}`}
            type="checkbox"
            checked={collection.selected}
            onChange={collectionSelectionChange}
            data-testid={`select-collection-${collection.id}`}
          />
          <label htmlFor={`collection-item-${collection.id}`} />
        </div>
        <span className="checkbox-label" onClick={toggleCollection}>
          <ClickableListItemLabel item={collection} />
        </span>
      </div>
      {isOpen && <PackageList packages={collection.packages || []} isSelected={!!collection.selected} />}
    </Fragment>
  );
});
