import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootStore } from "../stores/rootStore";
import { OrganizationPageStore, OrganizationPageStoreContext } from "./organizationPageStore";
import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";

import { IComment } from "../models/dataModel";
import { SanitizationModeEnum } from "../models/enums";

import { TextWrapper } from "../components/TextWrapper";
import { Spinner } from "../components/Spinner";
import { EditComment } from "./dialogs/EditComment";
import { AddComment } from "./dialogs/AddComment";

const NewsFeedItem = observer(({ comment }: { comment: IComment }) => {
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);

  return (
    <div>
      <div className="news-feed-createtime" data-testid="newsFeedCreatetime">
        {orgStore.getModifiedDate(comment.createTime)}
      </div>
      <div className="news-feed-text" data-testid="newsFeedText">
        <p>
          <TextWrapper text={comment.text} sanitizationMode={SanitizationModeEnum.JUST_LINKS} />
        </p>
      </div>
      {editorModeStore.getEditorMode() && orgStore.canEditOrganization() && <EditComment comment={comment} />}
    </div>
  );
});

const NewsFeedList = observer(() => {
  const orgStore = useContext(OrganizationPageStoreContext);

  return (
    <div className="news-feed-items">
      {orgStore.getComments().map((element: IComment, index: number) => {
        return <NewsFeedItem comment={element} key={index} />;
      })}
    </div>
  );
});

/**
 * Component that displays organization comments (news feeds).
 */
export const NewsFeed = observer(() => {
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const rootStore: RootStore = orgStore.getRootStore();
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);

  async function loadPreviousFeeds() {
    await orgStore.loadPreviousFeeds();
  }

  async function loadNextFeeds() {
    await orgStore.loadNextFeeds();
  }

  return (
    <Fragment>
      <div className="news-feed" data-testid="newsFeed">
        <section className="news-feed">
          <h3 className="title">{rootStore.getTranslation("organization.news_feed")}</h3>
          <div className="add-and-spinner">
            {editorModeStore.getEditorMode() && orgStore.canEditOrganization() && <AddComment />}
            {orgStore.isLoading() && <Spinner loadingWhat="newsFeedItems" />}
          </div>
          <NewsFeedList />

          <div className="news-feed-text">
            {orgStore.shouldShowPreviousNewsFeedsLink() && (
              <a className="previous" onClick={loadPreviousFeeds} data-testid="loadPreviousFeedsLink">
                <span className="previous-link-text" data-testid="loadPreviousFeedsSpan">
                  {rootStore.getTranslation("organization.show_newer_news")}
                </span>
              </a>
            )}
            {orgStore.shouldShowNextNewsFeedsLink() && (
              <a className="next l-right" onClick={loadNextFeeds} data-testid="loadNextFeedsLink">
                <span className="previous-link-text" data-testid="loadNextFeedsSpan">
                  {rootStore.getTranslation("organization.show_older_news")}
                </span>
              </a>
            )}
          </div>
        </section>
      </div>
    </Fragment>
  );
});
