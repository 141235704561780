import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { IUser } from "../../../models/dataModel";
import { RootContext, RootStore } from "../../../stores/rootStore";
import { ManageUsersStore, ManageUsersStoreContext } from "./manageUsersStore";
import { DialogContext, DialogStore } from "../../../dialogs/dialogStore";
import { ConfirmAction } from "../../../dialogs/ConfirmAction";
import { TranslatedHtml } from "../../../components/TranslatedHtml";

const DeleteUser = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageUsersStore = useContext(ManageUsersStoreContext);
  const confirmDialog = new DialogStore();

  async function openConfirmDialog(e) {
    e.preventDefault();
    confirmDialog.open();
  }

  return (
    <DialogContext.Provider value={confirmDialog}>
      <button
        className="button-primary delete-user"
        onClick={openConfirmDialog}
        disabled={!store.getSelectedUser() || store.isProcessing()}
      >
        {rootStore.getTranslation("profile.admin.delete_user.delete_selected")}
      </button>
      <ConfirmAction
        content={rootStore.getTranslation("profile.admin.delete_user.confirm")}
        callbackCloseDialog={() => {
          store.setSelectedUser(undefined);
          confirmDialog.close();
        }}
        callbackConfirm={async () => {
          await store.deleteUser();
        }}
      />
    </DialogContext.Provider>
  );
});

const SuspendUser = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageUsersStore = useContext(ManageUsersStoreContext);

  return (
    <Fragment>
      <button
        className="button-primary suspend-user"
        data-testid="suspendUserButton"
        onClick={() => store.suspendUser()}
        disabled={!store.getSelectedUser() || store.getSelectedUser()?.suspended || store.isProcessing()}
      >
        {rootStore.getTranslation("profile.admin.suspend_user.suspend_selected")}
      </button>
      <button
        className="button-primary suspend-user"
        data-testid="unsuspendUserButton"
        onClick={() => store.unSuspendUser()}
        disabled={
          !store.getSelectedUser() ||
          !store.getSelectedUser()?.suspended ||
          store.isLoadingSuspensionData() ||
          store.isProcessing()
        }
      >
        {rootStore.getTranslation("profile.admin.suspend_user.unsuspend_selected")}
      </button>
    </Fragment>
  );
});

const ShowSuspendedUsersButton = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageUsersStore = useContext(ManageUsersStoreContext);

  return (
    <button
      disabled={store.isProcessing()}
      className={"button-secondary button-search"}
      onClick={() => store.showSuspendedUsers()}
      data-testid="showSuspendedUsersButton"
    >
      {rootStore.getTranslation("profile.admin.suspend_user.show_suspended_users")}
    </button>
  );
});

const SelectedUser = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageUsersStore = useContext(ManageUsersStoreContext);
  const user = store.getSelectedUser();

  function removeUserSelection() {
    store.setSelectedUser(undefined);
  }

  return (
    <Fragment>
      {!!store.getSelectedUser() && (
        <div className="users-list one-selected" data-testid="oneUserSelected">
          <li className="user-item">
            <span className="blue">{user?.displayName}</span>
            {!!user?.suspended && (
              <div className="user-suspended small" data-testid="userSuspended">
                {store.isLoadingSuspensionData() ? (
                  <div className="loading" data-testid="loadingSuspensionData">
                    <div className="spinner spinner-gray" />
                    {rootStore.getTranslation("profile.admin.suspend_user.loading_suspension_date")}
                  </div>
                ) : (
                  <span data-testid="userSuspendedInfo">
                    {rootStore.getTranslation("profile.admin.suspend_user.suspended", store.getUserSuspendedDate())}
                  </span>
                )}
              </div>
            )}
            <a
              className="icon icon-close small blue"
              onClick={removeUserSelection}
              title={rootStore.getTranslation("actions.remove")}
            />
          </li>
        </div>
      )}
    </Fragment>
  );
});

const SearchUsers = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ManageUsersStore = useContext(ManageUsersStoreContext);

  function handleSearchInput(event) {
    store.setSearchTerm(event.target.value);
  }

  function clearSearchTerm() {
    store.setSearchTerm("");
  }

  async function searchUsers(e) {
    e.preventDefault();
    await store.searchUsers();
  }

  function selectUser(user: IUser) {
    store.setSelectedUser(user);
    clearSearchTerm();
    store.setUserCandidates([]);
  }

  return (
    <div className="search">
      <div className="search-options">
        <form className="search-form" onSubmit={searchUsers} data-testid="searchUserForm">
          <input
            className="search"
            type="search"
            value={store.getSearchTerm()}
            onChange={handleSearchInput}
            placeholder={rootStore.getTranslation("shared.access_rights.write_user_id_placeholder")}
            disabled={store.isLoadingUsers() || store.isProcessing()}
            data-testid="searchUsersInput"
          />
          <button
            type="submit"
            className="input-search"
            disabled={store.isLoadingUsers() || store.isProcessing()}
            data-testid="searchButton"
          />
        </form>
        {"or"}
        <ShowSuspendedUsersButton />
        {store.isProcessing() && (
          <div className="loading" data-testid="isProcessing">
            <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_processing")}</div>
          </div>
        )}
      </div>
      {store.isLoadingUsers() ? (
        <div className="loading" data-testid="isLoadingUsers">
          <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_loading")}</div>
        </div>
      ) : (
        <div className="users">
          {!_.isEmpty(store.getUserCandidates()) && (
            <ul className="users-list" data-testid="multipleUsersSelected">
              {store.getUserCandidates().map((user: IUser, i: number) => {
                return (
                  <li key={i}>
                    <span className="blue">{user.displayName}</span>
                    <a
                      className="icon icon-plus small blue"
                      onClick={() => selectUser(user)}
                      title={rootStore.getTranslation("actions.add")}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
});

export const ManageUsers = observer(() => {
  const rootStore: RootStore = useContext(RootContext);

  return (
    <ManageUsersStoreContext.Provider value={new ManageUsersStore(rootStore)}>
      <div className="manage-users" data-testid="manageUsersComponent">
        <div className="admin-header">{rootStore.getTranslation("profile.admin.manage_users")}</div>
        <div className="select-user">
          <SearchUsers />
        </div>
        <hr />
        <div className="actions">
          <div className="small" data-testid="actionDescriptions">
            <p>
              <TranslatedHtml entry={"profile.admin.suspend_user.description"} />
            </p>
            <p>
              <TranslatedHtml entry={"profile.admin.delete_user.description"} />
            </p>
          </div>
          <SuspendUser />
          <DeleteUser />
        </div>
        <SelectedUser />
      </div>
    </ManageUsersStoreContext.Provider>
  );
});
