/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateTime } from "luxon";
import _ from "underscore";

import { URLBuilder } from "../../utils/URLBuilder";
import { Binary } from "../factories/Binary";
import { LocalVersionRequestTransferObject } from "../factories/LocalVersionRequestTransferObject";
import { Package } from "../factories/Package";
import { TCCEntityRequestTransferObject } from "../factories/TCCEntityRequestTransferObject";
import { Tool } from "../factories/Tool";
import { Version } from "../factories/Version";
import { LocalBinaryService } from "../services/LocalBinaryService";
import { onlyTruthy } from "../utils/onlyTruthy";
import { AttributesConverter } from "./AttributesConverter";

import { fixDisplayName } from "./FixDisplayName";
import { PrerequisiteConverter } from "./PrerequisiteConverter";
import { TagsConverter } from "./TagsConverter";

import { ITCCEntity, TCCBaseAccessLevelEnum } from "../../models/TCCdataModel";
import { IVersion } from "../../models/dataModel";
import { ObjectTypeEnum, PlatformEnum, SourceEnum, VisibilityEnum } from "../../models/enums";

function parseTools(version) {
  if (version.attributes.isTool) {
    var tools = {};
    _.each(version.binaries, function (binary) {
      if (binary.attributes.platform) {
        var url = null;
        if (version.isLocal) {
          var data = {
            subjectId: version.id,
            subjectClass: "version",
            name: binary.reference,
            fn: binary.attributes.fileName || binary.attributes.originalName,
          };
          url = URLBuilder.buildLocalUrl("/TeklaWarehouse/GetBinary", data);
        } else {
          var params = { download: true };
          url = URLBuilder.buildWarehouseURL(
            "/warehouse/v1.0/entities/" + version.id + "/binaries/" + binary.reference,
            params,
            true,
          );
        }
        tools[binary.attributes.platform] = Tool({
          url: url,
          platform: binary.attributes.platform,
        });
      }
    });
    version.tools = _.compact([tools[PlatformEnum.WIN_X64], tools[PlatformEnum.WIN_X86], tools[PlatformEnum.ANY]]);
  }
}

function resolveFromLocalVersionToVersionPrerequisites(data) {
  var prerequisites;
  if (data.attributes.prerequisites) {
    prerequisites = PrerequisiteConverter.fromLocalAttributes({ attributes: data.attributes.prerequisites });
  } else {
    prerequisites = _.map(data.prerequisites || [], function (p) {
      return PrerequisiteConverter.fromLocal({ url: p });
    });
  }
  return prerequisites;
}

function localVersionToVersion(data) {
  data = data || {};
  var binaries = data.binaries || [];

  var localPrerequisities = resolveFromLocalVersionToVersionPrerequisites(data);

  var version = {
    id: data.id,
    title: data.title,
    description: data.description,
    tags: TagsConverter.fromLocal(data.tags),
    isLocal: true,
    visibility: "private",
    source: SourceEnum.LOCAL_SERVICE,
    type: ObjectTypeEnum.LOCAL_VERSION,
    prerequisites: localPrerequisities,
    attributes: data.attributes || {},
  };

  if (data.packageId) {
    version.package = Package({ id: data.packageId });
  }

  version.binaries = _.map(binaries, function (b) {
    return Binary.create({
      id: b.id,
      url: LocalBinaryService.getBinaryUrl("version", data.id, b.name),
      reference: b.name,
      attributes: {
        fileName: b.attributes.fileName,
        originalName: b.attributes.originalFileName,
        fileSize: b.attributes.fileSize,
        platform: b.attributes.platform,
        type: b.attributes.type,
        itemType: b.attributes.itemType,
        itemSource: b.attributes.itemSource,
      },
    });
  });
  parseTools(version);
  return Version(version);
}

function resolveLocalVersionPrerequisiteAttributes(data) {
  return _.map(data.prerequisites, function (p) {
    return PrerequisiteConverter.toLocalAttributes({ url: p.url, title: p.title });
  });
}

function resolveBinariesFromTCC(binaries) {
  return !!binaries
    ? _.map(binaries, function (b, k) {
        var binaryAttributesFromTCC = AttributesConverter.fromTCC(b.attributes || {});
        binaryAttributesFromTCC.metadata = binaryAttributesFromTCC.metadata || {};

        return Binary.create({
          _id: b.id,
          reference: k,
          creator: b.creator,
          modifier: b.modifier,
          createdAt: DateTime.fromSQL(b.createTime, { zone: "utc" }).toJSDate(),
          modifiedAt: DateTime.fromSQL(b.modifyTime, { zone: "utc" }).toJSDate(),
          jobResults: b.jobs,
          url: URLBuilder.buildURL(b.url, {
            createdAt: DateTime.fromSQL(b.modifyTime, { zone: "utc" }).toSeconds(),
          }),
          versionId: b.entity.id,
          ext: b.ext,
          isImmutable: binaryAttributesFromTCC.immutability
            ? binaryAttributesFromTCC.immutability["--immutable"]
            : false,
          attributes: {
            fileName: b.originalFileName,
            originalName: b.originalFileName,
            fileSize: b.fileSize,
            platform: binaryAttributesFromTCC.metadata.platform,
            type: binaryAttributesFromTCC.metadata.type,
            itemType: binaryAttributesFromTCC.metadata.itemType,
            itemSource: binaryAttributesFromTCC.metadata.itemSource,
          },
        });
      })
    : [];
}

export const VersionConverter = {
  fromLocal: function (data) {
    data = data || {};
    if (Array.isArray(data)) {
      return _.map(data, function (d) {
        return localVersionToVersion(d);
      });
    } else {
      return localVersionToVersion(data);
    }
  },
  toLocal: function (data) {
    data = data || {};
    data.attributes.prerequisites = resolveLocalVersionPrerequisiteAttributes(data);

    return LocalVersionRequestTransferObject({
      id: data.id || data._id,
      packageId: data.package ? data.package.id : undefined,
      title: data.title,
      description: data.description,
      tags: TagsConverter.toLocal(data.tags),
      prerequisites: _.map(data.prerequisites, function (p) {
        return PrerequisiteConverter.toLocal({ url: p.url });
      }),
      attributes: data.attributes,
    });
  },
  /**
   * Conversion from TCC entity to IVersion
   * @param {ITCCEntity} data - The data to be converted
   * @returns {IVersion} - The converted data
   */
  fromTCC: function (data) {
    data = data || {};

    var attributesFromTCC = AttributesConverter.fromTCC(data.attributes || {});
    attributesFromTCC.metadata = attributesFromTCC.metadata || {};

    var version = {
      id: data.id,
      title: data.title,
      description: data.description,
      tags: TagsConverter.fromTCC(data.tags),
      isLocal: false,
      isHidden: data.isHidden,
      isBanned: data.baseAccessLevel === TCCBaseAccessLevelEnum.BANNED ? true : false,
      isImmutable: !!attributesFromTCC.immutability ? attributesFromTCC.immutability["--immutable"] : false,
      isArchived: !!attributesFromTCC.status ? attributesFromTCC.status.archived : false,
      visibility: _.includes([TCCBaseAccessLevelEnum.NONE, TCCBaseAccessLevelEnum.BANNED], data.baseAccessLevel)
        ? VisibilityEnum.PRIVATE
        : VisibilityEnum.PUBLIC,
      source: data.source,
      type: data.type,
    };

    if (attributesFromTCC.references && attributesFromTCC.references.parentCollectionId) {
      version.package = Package({ _id: attributesFromTCC.references.parentCollectionId });
    }

    // overwrite if exists
    if (data.parentCollectionId) {
      version.package = Package({ _id: data.parentCollectionId });
    }

    if (attributesFromTCC.prerequisites) {
      version.prerequisites = PrerequisiteConverter.fromTCC(attributesFromTCC);
    }

    version.createdAt = DateTime.fromSQL(data.createTime, { zone: "utc" }).toJSDate();
    version.modifiedAt = DateTime.fromSQL(data.modifyTime, { zone: "utc" }).toJSDate();

    version.creator = fixDisplayName(data.creator);
    version.modifier = fixDisplayName(data.modifier);

    function filterOperatingSystems(operatingSystems) {
      var filteredValues = _.reject(operatingSystems, function (item, itemKey) {
        return item === "winXP" || item === "winVista";
      });
      return filteredValues;
    }

    version.attributes = {
      licensesACL: attributesFromTCC.licenses ? onlyTruthy(attributesFromTCC.licenses) : undefined,

      isTool: attributesFromTCC.metadata.isTool,

      quality: attributesFromTCC.metadata.quality,
      productCode: attributesFromTCC.metadata.productCode,
      productExpirationDate: attributesFromTCC.metadata.productExpirationDate,
      prerequisiteDescription: attributesFromTCC.metadata.prerequisiteDescription,

      testedVersions: onlyTruthy(attributesFromTCC.testedVersions),
      compatibleSoftwareProducts: onlyTruthy(attributesFromTCC.compatibleSoftwareProducts),
      compatibleOperatingSystems: filterOperatingSystems(onlyTruthy(attributesFromTCC.compatibleOperatingSystems)),
      measurementUnits: onlyTruthy(attributesFromTCC.measurementUnits),

      bannedDate: attributesFromTCC.status ? attributesFromTCC.status.bannedDate : undefined,

      versionNumber: !!attributesFromTCC.immutability ? attributesFromTCC.immutability["--versionNumber"] : undefined,
    };

    version.binaries = resolveBinariesFromTCC(data.binaries);
    parseTools(version);
    return Version(version);
  },
  toTCCAttributes: function (data) {
    var attributes = data.attributes || {};
    var attributesForTCC = {
      metadata: {
        isTool: { dataType: "boolean", value: attributes.isTool || false },
        quality: { dataType: "string", value: attributes.quality || "" },
        productCode: { dataType: "string", value: attributes.productCode || "" },
        productExpirationDate: { dataType: "string", value: attributes.productExpirationDate || "" },
        prerequisiteDescription: { dataType: "string", value: attributes.prerequisiteDescription || "" },
      },
    };

    if (data.isImmutable) {
      attributesForTCC.immutability = attributes.immutability || {};
      attributesForTCC.immutability["--immutable"] = { dataType: "boolean", value: true };

      if (!_.isUndefined(attributes.versionNumber)) {
        attributesForTCC.immutability["--versionNumber"] = {
          dataType: "string",
          value: attributes.versionNumber || "",
        };
      }
    }

    if (data.isArchived) {
      attributesForTCC.status = attributes.status || {};
      attributesForTCC.status.archived = { dataType: "boolean", value: true };
    }

    if (!_.isUndefined(attributes.bannedDate)) {
      attributesForTCC.status = {};
      attributesForTCC.status.bannedDate = { dataType: "string", value: attributes.bannedDate || "" };
    }

    var arrayProperties = [
      "testedVersions",
      "compatibleSoftwareProducts",
      "compatibleOperatingSystems",
      "measurementUnits",
    ];

    _.each(arrayProperties, function (ap) {
      var values = attributes[ap] || [];
      if (_.size(values) > 0) {
        var p = (attributesForTCC[ap] = {});
        _.each(values, function (v) {
          p[v] = { dataType: "boolean", value: true };
        });
      }
    });

    if (_.size(data.prerequisites) > 0) {
      var prerequisites = {};
      var urls = [];
      var titles = [];
      _.each(data.prerequisites, function (p) {
        titles.push(p.title || "");
        urls.push(p.url);
      });
      prerequisites["URLTitles"] = { dataType: "stringArray", value: titles };
      prerequisites["URLs"] = { dataType: "stringArray", value: urls };
      attributesForTCC.prerequisites = prerequisites;
    }

    // TODO: temp
    if (attributes.licensesACL) {
      if (_.size(attributes.licensesACL) > 0) {
        var p = (attributesForTCC.licenses = {});
        _.each(attributes.licensesACL, function (v) {
          p[v] = { dataType: "boolean", value: true };
        });
      }
    }

    if (data.package) {
      attributesForTCC.references = {
        parentCollectionId: { dataType: "string", value: "" + data.package.id },
      };
    }
    return attributesForTCC;
  },
  toTCC: function (data) {
    data = data || {};

    var transferObject = {
      id: data.id,
      title: data.title,
      description: data.description || "Version",
      source: ObjectTypeEnum.TEKLA_WAREHOUSE,
      type: ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION,
      tags: TagsConverter.toTCC(data.tags) || "",
      isHidden: data.isHidden,
      baseAccessLevel: data.isBanned
        ? TCCBaseAccessLevelEnum.BANNED
        : data.visibility === VisibilityEnum.PRIVATE
          ? TCCBaseAccessLevelEnum.NONE
          : TCCBaseAccessLevelEnum.VIEWER,
    };

    if (!_.isUndefined(data.doNotNotify)) {
      transferObject.doNotNotify = data.doNotNotify;
    }
    if (data.creatorId) {
      transferObject.creatorId = data.creatorId;
    }
    transferObject.attributes = VersionConverter.toTCCAttributes(data);
    return TCCEntityRequestTransferObject(transferObject);
  },
};
