 
export const EnAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["en-US"]) {
      window.locale["en-US"] = {};
    }

    window.locale["en-US"].about = {
      navigation: {
        why: "Why Tekla Warehouse?",
        usage_basics: "Tekla Warehouse basics",
        "usage_basics__using-content": "Using content",
        "usage_basics__adding-content": "Adding content",
        usage_basics__collection: "Collection",
        "usage_basics__content-item": "Content item",
        usage_basics__version: "Version",
        getting_started: "Getting started",
        "getting_started__tekla-account-and-system-requirements": "Tekla account and system requirements",
        "getting_started__tekla-warehouse-service-plugin": "Tekla Warehouse Service plugin",
        "getting_started__how-do-i-get-tekla-warehouse-service": "How do I get Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures and Tekla Warehouse",
        content: "Content in Tekla Warehouse",
        "content__3d-products": "3D model products",
        "content__custom-components": "Custom components",
        content__applications: "Applications",
        "content__profiles-shapes-etc": "Profiles, materials, bolts, rebars, etc.",
        "content__model-templates": "Model templates",
        "content__drawing-setup": "Drawing setup",
        "content__report-templates-etc": "Report templates and attribute files",
        using_content: "Using content",
        creating_content: "Creating content",
        "creating_content__getting-started-with-content-creation": "Getting started with content creation",
        "creating_content__expert-help-for-creating-content": "Expert help for creating content?",
        application_developers: "Application Development for Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Why Tekla Warehouse?",
        description_1:
          "Tekla Warehouse includes applications, custom components, parts, profiles, materials like steel and concrete grades, bolts, rebars, mesh, shapes, and templates for Tekla Structures. Actual suppliers’ parts in the models lead to the highest accuracy and exact material information for planning and procurement – and using ready-made content speeds up modeling.",
        description_2:
          "Tekla Warehouse provides a centralized access to this content that can now be taken into use in a streamlined way. The content in Tekla Warehouse is constantly increasing.",
        description_3: "With Tekla Warehouse",
        feature_1:
          "Manufacturers can make their products and applications available to users, who can take the exact 3D product models straight into their Tekla models and later use the physical product in construction.",
        feature_2:
          "You can use your company network to share content with colleagues and save content locally for private use",
        description_4: "Tekla Warehouse search makes finding exactly what you need easy regardless the content type.",
        description_5: "Tekla Warehouse Service plugin is needed to enable Network and Local collections.",
        view_licences_html: 'View <a href="/api/third_party_licenses.txt" target="_blank">third party licenses</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Tekla Warehouse Basics",
        using_content: {
          title: "Using content",
          description_1:
            "You can use the content in online collections, share content with your colleagues using network collections, and utilize local collections for private use.",
          description_2:
            "Local and network collections need the Tekla Warehouse Service, which is installed with Tekla Structures 20.1 Service Release 1 and newer Tekla Structures versions.",
          description_3:
            "Specific Tekla collections containing localized environment content are installed with Tekla Structures.",
          description_4: "See section Using content for more information.",
        },
        adding_content: {
          title: "Adding content",
          description_1:
            'You can utilize online collections to publish your content to all Tekla Warehouse users. In order to publish content as an organization, your organization needs to be added to account.tekla.com. Contact your local <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">Tekla office or reseller</a> for more information.',
          description_2: "See section Creating content for more information.",
        },
        collection: {
          title: "Collection",
          feature_1:
            "Collections group content items together, like folders, and define the access rights to the content (public/private).",
          feature_1_1:
            "Modelers can use content in online collections, share content with colleagues with network collections, and utilize local collections for private use.",
          feature_2:
            "Content providers can use online collections to publish their content to all Tekla Warehouse users.",
          feature_3:
            "Local and network collections need the Tekla Warehouse Service plugin, which is installed with Tekla Structures 20.1 Service Release 1 and newer and can also be installed separately.",
        },
        "content-item": {
          title: "Content item",
          feature_1:
            "A content item is a logical group of files. For example, a 3D product catalog, a model template, a tool, a group of custom components, or a profile catalog are content items.",
          feature_2: "Content items can be labeled by type and use case, which helps users find your content.",
        },
        version: {
          title: "Version",
          feature_1:
            "At the same time, a content item can have many versions that are used with different Tekla Structures versions. The user can select the correct version from the content page.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Getting started",
        tekla_account_and_system_requirements: {
          title: "Tekla account and system requirements",
          register_atc_html:
            'You need a Tekla account to log in to Tekla Warehouse. If you have not registered yet, please do it at <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>. (Please note that this is not the old Extranet account.)',
          see_tua_for_prerequisites_html:
            'Please see Tekla User Assistance for information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">prerequisites for using Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Tekla Warehouse Service plugin",
          description_1: "In addition to being an online storage for content, Tekla Warehouse also enables:",
          feature_1: "Local collections for personal use",
          feature_2: "Network collections to share content with colleagues using the company network",
          feature_3: "Easy installation when using or creating content",
          feature_4: "Search local and network content",
          description_2:
            "This is possible with Tekla Warehouse Service, a plugin which works as a local back-end, communicating with Tekla Structures and Tekla Warehouse web site.",
          description_3:
            "If Tekla Warehouse Service is not installed or not running, Tekla Warehouse highlights this at the top of the page to allow troubleshooting.",
          description_4:
            "If problems occur with the service, you can restart it using the Services program in Windows. The service name is Tekla Warehouse. Also check your browser’s security settings.",
          description_5:
            "The Tekla Warehouse website can be used without Tekla Warehouse Service or Tekla Structures. The experience will then be closer to a typical content sharing web site where files are manually uploaded and downloaded.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "How do I get Tekla Warehouse Service?",
          description_1:
            "The Tekla Warehouse Service plugin is installed with Tekla Structures 20.1 Service Release 1 and newer. You can also separately install Tekla Warehouse Service to enable local and network collections but easy installation and upload features are only possible with Tekla Structures 20.1 Service Release 1 and newer.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">DOWNLOAD TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures and Tekla Warehouse",
          description_1:
            "Tekla Warehouse connects directly to Tekla Structures making it easier to take content into use, or to upload it. You can select content directly from your model and also insert content directly into the model without needing to hassle with import/export files (Tekla Structures 20.1 Service Release 1 and newer is needed for this). All needed files can be included in the same content item, such as a custom component or a profile, to ensure that things work as intended.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Content in Tekla Warehouse",
        description_1:
          "Tekla Warehouse offers several different types of content. Here you can find an overview of the different content types and more information on getting started.",
        description_2:
          'From Tekla Warehouse you can also download <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla Extensions</a>.',
        "3d_products": {
          title: "3D model products",
          image_subtitle: "These are objects with fixed geometry, for example embeds, formwork, and accessories.",
          tua_link_html:
            'See Tekla User Assistance for more information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">Creating a 3D product catalog in a Tekla Warehouse collection</a>.',
        },
        custom_components: {
          title: "Custom components",
          image_subtitle: "Custom components are Tekla Structures native objects with fixed or parametric geometry.",
          tua_link_html:
            'See Tekla User Assistance for more information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Uploading content from a Tekla Structures model to a Tekla Warehouse collection</a>.',
        },
        applications: {
          title: "Applications",
          image_subtitle:
            "Applications use Tekla Open API to interact with Tekla Structures. For example, data integration links into a design solution.",
          tua_link_html:
            'See Tekla User Assistance for more information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">Uploading an application to a Tekla Warehouse collection</a>.',
        },
        profiles_shapes_etc: {
          title: "Profiles, shapes, materials, bolt assemblies, rebars, and meshes",
          image_subtitle:
            "Catalogs contain lists of properties related to parts. For example, different material grades and their densities.",
          tua_link_html:
            'See Tekla User Assistance for more information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Uploading content from a Tekla Structures model to a Tekla Warehouse collection</a>.',
        },
        model_templates: {
          title: "Model templates",
          image_subtitle:
            "A model template can be used for similar, recurring projects. The template can contain all necessary catalogs, report templates, drawing setups, and advanced option settings.",
          tua_link_html:
            'See Tekla User Assistance for more information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adding files manually to a Tekla Warehouse collection</a>.',
        },
        drawing_setup: {
          title: "Drawing setup",
          image_subtitle:
            "Setup files define how drawings are created, for example layouts, filters, and cloning templates.",
          tua_link_html:
            'See Tekla User Assistance for more information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adding files manually to a Tekla Warehouse collection</a>.',
        },
        report_templates_etc: {
          title: "Report templates and model attribute files",
          description_1:
            "Report templates define the content and layout of model data in a report. Model attribute files, e.g. filters, work when added to the model folder.",
          tua_link_html:
            'See Tekla User Assistance for more information on <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adding files manually to a Tekla Warehouse collection</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Using content",
        description_1:
          "Depending on the content type, you can either insert your chosen content to your Tekla Structures model or download and install it. Please see Tekla User Assistance for instructions on",
        feature_1:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Searching for content in Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Using content from Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Inserting Tekla Warehouse content into a Tekla Structures model</a>',
        feature_4:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Conflict management in Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Creating content",
        getting_started_with_content_creation: {
          title: "Getting started with content creation",
          description_1: "Please see Tekla User Assistance for more information on:",
          feature_1:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_getting_started" target="_blank">Getting started with Tekla Warehouse</a>',
          feature_2:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_adding_content" target="_blank">Adding content to Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Modifying content in Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Downloading content from Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Expert help for creating content?",
          description_1:
            'If you are interested in publishing content in Tekla Warehouse but would like to have expert help for creating and maintaining it, please contact your local <a href="http://www.tekla.com/contact" target="_blank">Tekla office or reseller</a> for more information on collaboration possibilities.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Application Development for Tekla Structures",
        description_1:
          "Because Tekla has an open approach to building information modeling (BIM), you can run other providers’ solutions and fabrication machinery and still interface with Tekla.",
        description_2:
          '<a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> is a specialized Application Programming Interface (API) developed by Tekla that enables you to develop applications and additional functionality on the Tekla Structures modeling platform and integrate it into your own environment. Tekla Open API is implemented using Microsoft .NET technology.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> is the hub for all Tekla Open API information.',
        description_4:
          'Join <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> if you are interested in developing applications for Tekla Structures.',
      },
    };
  },
};
