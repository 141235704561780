import { TCCUserDS } from "../data-source/TCCUserDS";
import { TCCUserLockoutDS } from "../data-source/TCCUserLockoutDS";
import { NewUserConverter } from "../converters/NewUserConverter";
import _ from "underscore";

export const TCCUserService = {
  get: function (data) {
    data = data || {};
    return TCCUserDS.getUser(data).then(NewUserConverter.fromTCC);
  },
  getUsers: function (data) {
    data = data || {};
    return new Promise((resolve) => {
      var allUsers = [];
      var total;
      var promises = [];
      return TCCUserDS.getUsers({ ...data, count: 500 }).then(function (res) {
        allUsers = _.map(res.entries, NewUserConverter.fromTCC);
        total = res.total;
        if (total > res.endRow) {
          var offset;
          for (offset = res.endRow; offset < total; offset = offset + 500) {
            promises.push(
              TCCUserService.getUsers({ ...data, count: 500, offset: offset }).then(
                function (res) {
                  allUsers = allUsers.concat(_.map(res.entries, NewUserConverter.fromTCC));
                },
                function () {
                  total = 0;
                  offset = 0;
                },
              ),
            );
          }
          Promise.all(promises).finally(function () {
            resolve(allUsers);
          });
        } else {
          resolve(allUsers);
        }
      });
    });
  },
  deleteUser: function (userId) {
    return TCCUserDS.deleteUser(userId);
  },
  getSubscriptions: function (data) {
    data = data || {};
    return TCCUserDS.getSubscriptions(data);
  },
  subscribe: function (data) {
    data = data || {};
    return TCCUserDS.subscribe(data);
  },
  unSubscribe: function (data) {
    data = data || {};
    return TCCUserDS.unSubscribe(data);
  },
  suspend: function (data) {
    data = data || {};
    return TCCUserLockoutDS.createUserLockout(data);
  },
  unSuspend: function (lockoutId) {
    return TCCUserLockoutDS.deleteUserLockout(lockoutId);
  },
  setAttributes: function (userId, data) {
    data = data || {};
    return TCCUserDS.setAttributes(userId, data);
  },
  getAttributes: function (userId) {
    data = data || {};
    return TCCUserDS.getAttributes(userId);
  },
  getAttribute: function (userId, category, name) {
    return new Promise((resolve) => {
      TCCUserDS.getAttributes(userId).then(function (res) {
        if (res) {
          var attributes = JSON.parse(res);
          if (category && name) {
            if (_.has(attributes, category) && _.has(attributes[category], name)) {
              resolve(attributes[category][name]);
            } else {
              resolve(false);
            }
          } else if (category) {
            if (_.has(attributes, category)) {
              resolve(attributes[category]);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    });
  },
};
