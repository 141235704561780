import { action, computed, observable, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import {
  IDropdownOption,
  IEntity,
  IInstallationStatus,
  IShoppingCartItem,
  IVersion,
  IResultOptionsStrategy,
  ISearchCriteria,
} from "../../models/dataModel";
import { SearchPageOrderEnum } from "../../models/enums";

import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import { RootStore, createStoreContext } from "../../stores/rootStore";
import { SearchPageSettingsStore } from "../../stores/searchPageSettingsStore";

/**
 * Result options store.
 */
export class ResultOptionsStore extends AbstractAsyncStore {
  /**
   * A flag marking if all entities should be selected.
   */
  @observable private allSelected = false;
  /**
   * List of unfiltered entities.
   */
  @observable private entities: IEntity[] = [];
  /**
   * Entity category filter.
   */
  @observable private itemCategoryFilter?: IDropdownOption;
  /**
   * A flag that marks if linking popup is visible.
   */
  @observable private linkingPopupVisible = false;
  /**
   * A flag that marks if options section is visible.
   */
  @observable private optionsVisible = false;
  /**
   * List of filtered entities.
   */
  @observable private results: IEntity[] = [];
  /**
   * A string used as a filter.
   */
  @observable private searchTerm = "";
  /**
   * A strategy to be used for entities page
   */
  @observable private strategy?: IResultOptionsStrategy;
  /**
   * Version number filer.
   */
  @observable private versionFilter!: IDropdownOption;

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore, strategy?: IResultOptionsStrategy) {
    super(rootStore);
    makeObservable(this);

    runInAction(() => {
      if (!!strategy) {
        this.strategy = strategy;
      }
      this.versionFilter = {
        label: this.rootStore.getTranslation("download.all_versions"),
        value: "allVersions",
      };
    });
  }

  /**
   * Checkes if linked entities were selected
   * @returns true if any of linked entities were selected
   */
  @computed
  public get isLinkedEntitiesSelected(): boolean {
    return _.size(_.filter(this.results, (entity: IEntity) => !!entity.selected && !!entity.isLinked)) > 0;
  }

  /**
   * Checkes if version was selected.
   * @returns true if any version was selected
   */
  @computed
  public get isVersionSelected(): boolean {
    return !!(this.versionFilter && this.versionFilter.value !== "allVersions");
  }

  /**
   * Returns entities list size
   * @returns number of items
   */
  @computed
  public get itemsCount(): number {
    return this.entities.length;
  }

  /**
   * Checks if entities exist
   * @returns true if entities exist
   */
  @computed
  public get itemsExist(): boolean {
    return this.itemsCount > 0;
  }

  /**
   *  Method for getting selected sort
   *  @returns sort that is currently used
   */
  @computed
  public get resultListOrder(): SearchPageOrderEnum {
    if (this.getSearchPageSettingsStore().resultListOrder == SearchPageOrderEnum.RECOMMENDED) {
      this.setResultListOrder(SearchPageOrderEnum.RELEVANCE);
      return SearchPageOrderEnum.RELEVANCE;
    } else {
      return this.getSearchPageSettingsStore().resultListOrder;
    }
  }

  /**
   * Returns number of entites that match search criteria and are selected
   * @returns number
   */
  @computed
  public get selectedCount(): number {
    return _.size(this.selectedEntities);
  }

  /**
   * Returns entities that match search criteria and are selected
   * @returns entities
   */
  @computed
  public get selectedEntities(): IEntity[] {
    return _.filter(this.results, (entity: IEntity) => !!entity.selected);
  }

  /**
   * Adds selected entites to shopping cart.
   */
  @action
  public addSelectedEntities() {
    this.rootStore.getShoppingCartStoreCache().addAllToCart(this.selectedEntities);
  }

  /**
   * Add cartItem to shopping cart.
   * @param cartItem Cart item.
   */
  @action
  public async addToCart(cartItem: IShoppingCartItem) {
    this.rootStore.getShoppingCartStoreCache().addToCart(cartItem);
  }

  /**
   * Returns information if all entities are selected.
   */
  public areAllSelected(): boolean {
    return this.allSelected;
  }

  /**
   * Checks if entity can be selected
   * @returns true if entity can be selected
   */
  public canSelectEntity(entity: IEntity): boolean {
    return this.strategy ? this.strategy.canSelectEntity(entity, this.getVersionFilter()) : false;
  }

  /**
   * Deselects selected entities.
   */
  @action
  public clearSelection() {
    this.allSelected = false;
    _.each(this.results, (entity: IEntity) => {
      runInAction(() => {
        entity.selected = false;
      });
    });
  }

  /**
   * Fetches details of cart/collection items and collections with linking rights.
   */
  @action
  public async fetchData() {
    this.dataFetched = false;
    this.loading = true;
    this.entities = [];

    if (this.strategy) {
      const entitiesWithDetails = await this.strategy.fetchData();
      runInAction(() => {
        this.entities = entitiesWithDetails;
        this.results = this.entities;
        this.loading = false;
        this.dataFetched = true;
      });
    }
  }

  /**
   * Returns 3dw ticket read from the cookie
   * @returns 3dw ticket
   */
  public get3dwTicket(): string {
    return this.rootStore.getUserStore().get3dwTicket();
  }

  /**
   * Returns entities
   * @returns list of entities
   */
  public getEntities(): IEntity[] {
    return this.entities;
  }

  public getInstallationStatus(): IInstallationStatus {
    const installationStatus: IInstallationStatus = {
      failedInstallationAttempts: [],
      installableEntities: [],
    };

    _.each(this.selectedEntities, (entity) => {
      const installableEntity: { package: IEntity; versions: IVersion[] } = {
        package: entity,
        versions: [],
      };
      const versions = this.getVersionsForSelectedVersion(entity);

      _.each(versions, (version) => {
        if (version.attributes.isTool) {
          installationStatus.failedInstallationAttempts.push({
            title: `${entity.title}, version: ${version.title}`,
          });
        } else {
          installableEntity.versions.push(version);
        }
      });
      installationStatus.installableEntities.push(installableEntity);
    });
    return installationStatus;
  }

  /**
   * Returns entity category filter
   * @returns category filter or undefined if not set
   */
  public getItemTypeCategory(): IDropdownOption | undefined {
    return this.itemCategoryFilter;
  }

  /**
   * Returns linking popup visibility
   * @returns true if linking popup is visible
   */
  public getLinkingPopupVisible() {
    return this.linkingPopupVisible;
  }

  /**
   * Returns options section visibility
   * @returns true if options section is visible
   */
  public getOptionsVisible(): boolean {
    return this.optionsVisible;
  }

  /**
   * Returns filtered entities
   * @returns list of entities
   */
  public getResults(): IEntity[] {
    return this.results;
  }

  /**
   * Returns search page view settings
   * @returns SearchPageSettingsStore object
   */
  public getSearchPageSettingsStore(): SearchPageSettingsStore {
    return this.rootStore.getSearchStore().getSearchPageSettingsStore();
  }

  /**
   * Returns search string
   * @returns search string
   * @default ""
   */
  public getSearchTerm(): string {
    return this.searchTerm;
  }

  /**
   *  Returns version filter
   *  @returns version filter or undefined if not set
   */
  public getVersionFilter(): IDropdownOption {
    return this.versionFilter;
  }

  /**
   * Returns linking popup visibility
   * @params entity The entity to be update
   * @params selected Should entity be selected or de-selected
   */
  @action
  public handleEntitySelection(entity: IEntity, selected: boolean) {
    entity.selected = selected;
  }

  /**
   * Remove cartItem from shopping cart and the cache.
   * @param cartItem Cart item.
   */
  @action
  public async removeFromCart(cartItem: IShoppingCartItem) {
    this.rootStore.getShoppingCartStoreCache().removeFromCart(cartItem);
    this.entities = _.reject(this.entities, (entity) => entity.id == cartItem.id);
    this.filterResults();
  }

  /**
   * Removes selected entities from shopping cart, also updates the cache.
   */
  @action
  public removeSelectedEntities() {
    this.rootStore.getShoppingCartStoreCache().removeAllFromCart(this.selectedEntities);
    const ids = _.map(this.selectedEntities, (entity) => entity.id);
    this.entities = _.reject(this.entities, (entity) => _.includes(ids, entity.id));
    this.filterResults();
  }

  /**
   * Marks all entities as selected
   */
  @action
  public selectAll() {
    this.allSelected = !this.allSelected;
    _.each(this.results, (entity: IEntity) => {
      if (!!this.strategy && this.strategy.canSelectEntity(entity, this.getVersionFilter())) {
        runInAction(() => {
          entity.selected = this.allSelected;
        });
      }
    });
  }

  /**
   * Toggles selection on a given entity.
   * @param entity to mark as selected/not selected
   * @param select boolean marking if entity should be selected or deselected
   */
  @action
  public selectEntity(entity: IEntity, select: boolean) {
    _.each(this.results, (e: IEntity) => {
      if (e.id == entity.id) {
        runInAction(() => {
          entity.selected = select;
        });
      }
    });
  }

  /**
   * Method for setting entity category filter
   * @param itemTypeCategory
   */
  @action
  public async setItemTypeCategory(itemTypeCategory: IDropdownOption) {
    this.itemCategoryFilter = itemTypeCategory;
    this.filterResults();
  }

  /**
   * * Method for setting if linking popup should be visible
   * @param popupVisible
   */
  @action
  public setLinkingPopupVisible(popupVisible: boolean) {
    this.linkingPopupVisible = popupVisible;
  }

  /**
   * Method for setting if options section should be visible
   * @param optionsVisible
   */
  @action
  public setOptionsVisible(optionsVisible: boolean) {
    this.optionsVisible = optionsVisible;
  }

  /**
   * Method for setting sort
   * @param sort to be used
   */
  @action
  public async setResultListOrder(sort: string) {
    this.getSearchPageSettingsStore().setResultListOrder(sort as SearchPageOrderEnum);
    this.filterResults();
  }

  /**
   * Method for setting search string
   * @param searchTerm entities name filter
   */
  @action
  public async setSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.filterResults();
  }

  /**
   * Method for setting version filter
   * @param versionFilter
   */
  @action
  public async setVersionFilter(versionFilter: IDropdownOption) {
    this.versionFilter = versionFilter;
    this.filterResults();
  }

  /**
   * Checks if remove from basket button should be visible
   * @returns true if button should be visible
   */
  public shouldDisplayAddToBasketButton(): boolean {
    return this.strategy ? this.strategy.shouldDisplayAddToBasketButton() : false;
  }

  /**
   * Checks if mass unlink button should be visible
   * @returns true if button should be visible
   */
  public shouldDisplayMassUnlinkButton(): boolean {
    return this.strategy ? this.strategy.shouldDisplayMassUnlinkButton() : false;
  }

  /**
   * Checks if remove from basket button should be visible
   * @returns true if button should be visible
   */
  public shouldDisplayRemoveFromBasketButton(): boolean {
    return this.strategy ? this.strategy.shouldDisplayRemoveFromBasketButton() : false;
  }

  /**
   * Checks if select all options should be visible
   * @returns true if option should be visible
   */
  public shouldDisplaySelectAll(): boolean {
    return !this.loading && this.strategy
      ? this.strategy.shouldDisplaySelectAll(this.getEntities(), this.getVersionFilter())
      : false;
  }

  /**
   * Filters entities list based on given search criteria
   */
  @action
  private async filterResults() {
    if (this.strategy) {
      if (this.strategy.shouldUpdateEntities(this.getSearchCriteria())) {
        this.loading = true;
        const results = await this.strategy.filterEntities(this.getSearchCriteria(), this.entities);
        runInAction(() => {
          this.results = results;
          this.entities = results;
          this.loading = false;
        });
      } else {
        const results = await this.strategy.filterEntities(this.getSearchCriteria(), this.entities);
        runInAction(() => {
          this.results = results;
        });
      }
    }
  }

  /**
   * Returns search criteria
   * @returns search criteria fields wrapped in object
   */
  private getSearchCriteria(): ISearchCriteria {
    return {
      searchTerm: this.searchTerm,
      itemCategoryFilter: this.itemCategoryFilter,
      versionFilter: this.versionFilter,
    };
  }

  private getVersionsForSelectedVersion(entity: IEntity) {
    if (this.getVersionFilter() && this.getVersionFilter()!.value) {
      return _.filter(entity.versions || [], (version) => {
        const supportedVersion = _.contains(version.attributes.testedVersions!, this.getVersionFilter()!.value);
        return supportedVersion || _.contains(version.attributes.testedVersions!, "NVS");
      });
    } else {
      return [];
    }
  }
}

export const ResultOptionsStoreContext = createStoreContext<ResultOptionsStore>(ResultOptionsStore);
