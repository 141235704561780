import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import { Link, useLocation, useParams } from "react-router";

import { RootStore, RootContext } from "../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../upload/uploadFormStore";
import { VersionEditorStore, VersionEditorContext } from "./versionEditorStore";

import { IEntity, IResource, IVersion } from "../models/dataModel";
import { UploadStrategyEnum } from "../models/enums";

import { Breadcrumbs } from "../components/breadcrumbs/Breadcrumbs";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { AddVersion } from "./AddVersion";
import { EditVersion } from "./EditVersion";
import { SelectVersion } from "./SelectVersion";

/**
 * Component that renders the breadcrumbs and the version editor form.
 */
const VersionEditorContent = observer(({ entityId, view }: { entityId: string; view: UploadStrategyEnum }) => {
  const rootStore: RootStore = useContext(RootContext);
  const versionEditor: VersionEditorStore = useContext(VersionEditorContext);
  const entity: IEntity = versionEditor.getEntity();

  const addingVersion = view === UploadStrategyEnum.ADD_VERSION;
  const querying = versionEditor.isLoading();

  const showVersionDropdown = !querying && versionEditor.getExistingVersions().length >= 1;
  const showResultSummary = !querying && versionEditor.getExistingVersions().length === 0;
  const canEditEntity = versionEditor.canEditEntity();

  function getEntityPath(): string {
    const showVersionsQuery = new URLSearchParams({ showVersions: "true" }).toString();

    if (!!entity && entity.isLocal) {
      return "/catalog/localdetails/" + entityId + "?" + showVersionsQuery;
    } else {
      return "/catalog/details/" + entityId + "?" + showVersionsQuery;
    }
  }

  const headerText = addingVersion
    ? rootStore.getTranslation("upload.version.add_version")
    : rootStore.getTranslation("upload.version.versions");

  function renderBreadcrumbs() {
    return (
      <Fragment>
        {!versionEditor.isLoadingBreadcrumbs() && (
          <section className="crumbs">
            <Breadcrumbs item={entity as IResource} />
          </section>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {renderBreadcrumbs()}
      <article className="edit-versions upload" id="content">
        <div className="step">
          <header className="step-main" data-testid="versionHeader">
            <nav>
              <Link to={getEntityPath()}>{<TranslatedHtml entry="upload.version.back_to_content_information" />}</Link>
            </nav>
            <h2>{headerText}</h2>
          </header>
          {querying && (
            <div className="spinner" data-testid="loadingVersions">
              {rootStore.getTranslation("shared.spinner_loading")}
            </div>
          )}
          <div className="wrapper">
            {showVersionDropdown && (
              <div className="selection">
                {addingVersion && (
                  <label className="select-version-title">
                    {rootStore.getTranslation("upload.version.copy_from_version")}
                  </label>
                )}
                <SelectVersion />
              </div>
            )}
          </div>
          {showResultSummary && (
            <div className="results-summary" data-testid="resultsSummary">
              {!canEditEntity && <p>{rootStore.getTranslation("upload.version.there_s_no_versions_yet")}</p>}
              {canEditEntity && <p>{rootStore.getTranslation("upload.version.create_a_version_to_add_files")}</p>}
            </div>
          )}
          {addingVersion ? <AddVersion /> : <EditVersion />}
        </div>
      </article>
    </Fragment>
  );
});

/**
 * Main component for the version editor page.
 */
export const VersionEditor = ({ view }: { view: UploadStrategyEnum }) => {
  const store = useContext(RootContext);
  const versionEditor: VersionEditorStore = new VersionEditorStore(store);
  const form: UploadFormStore = versionEditor.getFormStore();

  const { id, version } = useParams();
  const { pathname } = useLocation();

  init();

  async function init() {
    form.setUploadStrategy(view);
    await versionEditor.initialize(id || "", pathname);

    if (!!version) {
      const selectedVersion: IVersion = _.find(versionEditor.getExistingVersions(), (v: IVersion) => {
        return v.id === version;
      })!;

      versionEditor.setSelectedVersion(selectedVersion);
    }
  }

  return (
    <VersionEditorContext.Provider value={versionEditor}>
      <UploadFormContext.Provider value={form}>
        <VersionEditorContent entityId={id || ""} view={view} />
      </UploadFormContext.Provider>
    </VersionEditorContext.Provider>
  );
};
