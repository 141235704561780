import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import classNames from "classnames";

import { RootContext } from "../../stores/rootStore";
import { BatchEditDialogStoreContext, BatchEditViewState, IError } from "./batchEditDialogStore";
import { DialogContext } from "../dialogStore";

import { EditPackage } from "./EditPackage";
import { EditVersion } from "./EditVersion";
import { SelectContent } from "./SelectContent";

const ErrorMessages = observer(() => {
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function resolveTestId(i: number) {
    return "error-message-" + i;
  }

  return (
    <Fragment>
      {dialogStore.isValidating() && (
        <Fragment>
          {dialogStore.getErrors().map((error, i) => {
            return (
              <div className="error-messages" key={i}>
                <span data-testid={resolveTestId(i)} className="error">
                  {error.message}
                </span>
              </div>
            );
          })}
        </Fragment>
      )}
    </Fragment>
  );
});

const EditView = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function hasErrors(): boolean {
    return dialogStore.isValidating() && dialogStore.errorsExist();
  }

  return (
    <section className="edit-content" data-testid="editView">
      <ol className="basic-data">
        <div>
          <div
            className={classNames("batch-edit-table", {
              "has-errors": hasErrors(),
            })}
          >
            <SelectContent />
            <div className="second-column">
              <h3>{rootStore.getTranslation("batch_edit.metadataToChange")}</h3>
              <div className="column">
                <EditPackage />
                <EditVersion />
              </div>
            </div>
          </div>
          <ErrorMessages />
        </div>
      </ol>
    </section>
  );
});

const ConfirmView = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function showEditView() {
    dialogStore.setViewState(BatchEditViewState.EDIT);
  }

  function update() {
    dialogStore.setViewState(BatchEditViewState.UPDATE);
    dialogStore.performUpdate();
  }

  return (
    <section className="confirm-update l-center-align" data-testid="confirmView">
      <div className="confirm-message" data-testid="confirm-message">
        <span>{dialogStore.resolveConfirmMessage()}</span>
      </div>
      <div className="confirm-buttons">
        <button className="button-large" onClick={showEditView} data-testid="cancel-update">
          {rootStore.getTranslation("shared.confirm.no")}
        </button>
        <button className="button-large" onClick={update} data-testid="confirm-update">
          {rootStore.getTranslation("shared.confirm.yes")}
        </button>
      </div>
    </section>
  );
});

const FailedUpdates = observer(() => {
  const dialogStore = useContext(BatchEditDialogStoreContext);

  return (
    <Fragment>
      {dialogStore.errorsExist() && (
        <div className="failed-updates">
          {dialogStore.getErrors().map((error: IError, i: number) => {
            return <span key={i}>{dialogStore.resolveFailureText(error)}</span>;
          })}
        </div>
      )}
    </Fragment>
  );
});

const UpdateView = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  return (
    <section className="processing-update" data-testid="updateView">
      <div className="info-header">
        {dialogStore.isUpdateProcessed() ? (
          <span>{rootStore.getTranslation("batch_edit.update.info_processed")}</span>
        ) : (
          <Fragment>
            <span>{rootStore.getTranslation("batch_edit.update.info_processing")}</span>
            <div className="spinner" />
          </Fragment>
        )}
        <span className="large">{dialogStore.getProcessingStatusText()}</span>
        {dialogStore.errorsExist() && <span>{rootStore.getTranslation("batch_edit.update.error_info")}</span>}
      </div>
      <FailedUpdates />
    </section>
  );
});

/**
 * Renders actions buttongs
 */
const Actions = observer(() => {
  const rootStore = useContext(RootContext);
  const dialog = useContext(DialogContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function cancel() {
    dialog.close();
    if (dialogStore.shouldReloadPage()) {
      location.reload();
    } else {
      dialogStore.resetValues();
    }
  }

  function showActions(): boolean {
    return dialogStore.getViewState() !== BatchEditViewState.CONFIRM;
  }

  function isUpdateViewActive(): boolean {
    return dialogStore.getViewState() === BatchEditViewState.UPDATE;
  }

  function save() {
    dialogStore.validateForm();
  }

  return (
    <Fragment>
      {showActions() && (
        <div className="actions">
          {isUpdateViewActive() ? (
            <div className="update-view-actions">
              <button disabled={dialogStore.isProcessing()} onClick={cancel} data-testid="close-dialog">
                {rootStore.getTranslation("actions.close")}
              </button>
            </div>
          ) : (
            <Fragment>
              <button disabled={dialogStore.isProcessing()} onClick={cancel} data-testid="close-dialog">
                {rootStore.getTranslation("actions.cancel")}
              </button>
              <button
                disabled={dialogStore.isProcessing()}
                className="button-primary"
                onClick={save}
                data-testid="perform-update"
              >
                {rootStore.getTranslation("actions.save")}
              </button>
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
});

/**
 * Renders batch edit dialog
 */
export const BatchEditDialog = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  return (
    <Fragment>
      <article>
        <header className="main">
          <h2 data-testid="dialog-title">{rootStore.getTranslation("batch_edit.edit_content")}</h2>
        </header>
        {dialogStore.getViewState() === BatchEditViewState.EDIT && <EditView />}
        {dialogStore.getViewState() === BatchEditViewState.CONFIRM && <ConfirmView />}
        {dialogStore.getViewState() === BatchEditViewState.UPDATE && <UpdateView />}
        <Actions />
      </article>
    </Fragment>
  );
});
