/**
 * Finds an item matching the given id from a list of items.
 * @param list list of items
 * @param id id of the wanted item
 */
export function getById(list: any[], id: string) {
  let i = 0;
  for (; i < list.length; i++) {
    if (list[i].id === id) {
      return list[i];
    }
  }
  return null;
}

/**
 * Filters the content ID from a given location.
 *
 * @param location - The location string.
 * @returns The content ID extracted from the location.
 */
export function contentIdFilter(location: string): string {
  const contentUidValidator =
    /^([a-zA-Z0-9_-]){8}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){12}$/;

  if (location) {
    const startId = location.lastIndexOf("/") + 1;
    const id = location.substring(startId, location.length);

    if (contentUidValidator.test(id)) {
      return id;
    }
  }
  return "";
}
