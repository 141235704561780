export function LocalCollectionRequestTransferObject(data) {
  data = data || {};
  var convertedData = {
    title: data.title,
    location: data.location,
    description: data.description,
    tags: data.tags,
    attributes: data.attributes,
  };

  if (data.id) {
    convertedData.id = data.id;
  }
  return convertedData;
}
