import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { ModusRadioGroup } from "@trimble-oss/modus-react-components";
import { ModusRadioGroupCustomEvent } from "@trimble-oss/modus-web-components/loader";
import { VisibilityEnum } from "../../../../models/enums";

import classNames from "classnames";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const VisibilityFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  function handleVisibilityChange(event: ModusRadioGroupCustomEvent<string>) {
    store.setVisibility(event.detail as VisibilityEnum);
  }

  return (
    <div className={classNames({ "search-filter": true, sectioned: true })} data-testid="visibilityFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.visibility")}:</h5>
      <ModusRadioGroup
        name="VisibilityFilter"
        data-testid="visibility-filter"
        checkedId={store.getVisibility()}
        onButtonClick={handleVisibilityChange}
        radioButtons={[
          {
            id: VisibilityEnum.ALL,
            label: rootStore.getTranslation("profile.admin.content_manager.visibility.all"),
            checked: store.getVisibility() === VisibilityEnum.ALL,
          },
          {
            id: VisibilityEnum.PUBLIC,
            label: rootStore.getTranslation("profile.admin.content_manager.visibility.public"),
            checked: store.getVisibility() === VisibilityEnum.PUBLIC,
          },
          {
            id: VisibilityEnum.PRIVATE,
            label: rootStore.getTranslation("profile.admin.content_manager.visibility.private"),
            checked: store.getVisibility() === VisibilityEnum.PRIVATE,
          },
        ]}
      />
    </div>
  );
});
