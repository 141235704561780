import { ObjectTypeEnum, SourceEnum, VisibilityEnum } from "../../models/enums";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IVersion } from "../../models/dataModel";

/**
 * Creates a Version object.
 * @param {Object} data - The data object containing the properties of the Version.
 * @returns {IVersion} - The Version object.
 */
export function Version(data) {
  data = data || {};
  data.attributes = data.attributes || {};
  return {
    _id: data._id || data.id,
    id: data._id || data.id,
    source: data.source || SourceEnum.UNKNOWN,
    type: data.type || ObjectTypeEnum.UNKNOWN,
    title: data.title || "1",
    description: data.description || "Version",
    tags: data.tags || [],
    package: data.package,
    visibility: data.visibility || VisibilityEnum.PUBLIC,
    isHidden: data.isHidden || false,
    isArchived: data.isArchived || false,
    isBanned: data.isBanned || false,
    isLocal: data.isLocal || false,
    isImmutable: data.isImmutable || false,
    binaries: data.binaries || [],
    tools: data.tools || [],
    prerequisites: data.prerequisites || [],
    creator: data.creator,
    modifier: data.modifier,
    modifiedAt: data.modifiedAt,
    createdAt: data.createdAt,
    thumbnail: data.thumbnail,
    richContent: data.richContent || {},
    attributes: {
      licensesACL: data.attributes.licensesACL || undefined,
      isTool: data.attributes.isTool || false,
      quality: data.attributes.quality,
      productCode: data.attributes.productCode,
      productExpirationDate: data.attributes.productExpirationDate,
      prerequisiteDescription: data.attributes.prerequisiteDescription,
      testedVersions: data.attributes.testedVersions || [],
      compatibleSoftwareProducts: data.attributes.compatibleSoftwareProducts || [],
      compatibleOperatingSystems: data.attributes.compatibleOperatingSystems || [],
      measurementUnits: data.attributes.measurementUnits || [],
      versionNumber: data.attributes.versionNumber || undefined,
      bannedDate: data.attributes.bannedDate || undefined,
    },
  };
}
