import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { ResultOptionsStoreContext } from "../components/result-options/resultOptionsStore";
import { EntityListItem } from "./EntityListItem";

import { IEntity } from "../models/dataModel";
import { SearchPageListStyleEnum } from "../models/enums";

/**
 * A component to display Entity with a checkbox making the entity selectable.
 */
export const SelectableEntity = observer(
  ({
    entity,
    listStyle = SearchPageListStyleEnum.THUMBNAILS,
    isSelectable = false,
    showUnlink = false,
  }: {
    entity: IEntity;
    listStyle: string;
    isSelectable: boolean;
    showUnlink?: boolean;
  }) => {
    const resultOptionsStore = useContext(ResultOptionsStoreContext);
    function toggleSelected(event) {
      if (resultOptionsStore) {
        resultOptionsStore.handleEntitySelection(entity, event.target.checked);
      } else {
        entity.selected = event.target.checked;
      }
    }

    return (
      <Fragment>
        <EntityListItem entity={entity} listStyle={listStyle} showUnlink={showUnlink} />
        {isSelectable && (
          <div className="selection" data-testid="selection">
            <input
              className="white-blue-border"
              id={`entity${entity.id}`}
              type="checkbox"
              checked={entity.selected}
              onChange={toggleSelected}
              data-testid={`selectEntity${entity.id}`}
            />
            <label
              htmlFor={`entity${entity.id}`}
              className={classNames({
                "l-middle": listStyle == SearchPageListStyleEnum.LIST,
              })}
            />
          </div>
        )}
      </Fragment>
    );
  },
);
