 
export const RuAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["ru-RU"]) {
      window.locale["ru-RU"] = {};
    }

    window.locale["ru-RU"].about = {
      navigation: {
        why: "Что такое Tekla Warehouse?",
        usage_basics: "Основы работы с Tekla Warehouse",
        "usage_basics__using-content": "Использование контента",
        "usage_basics__adding-content": "Добавление контента",
        usage_basics__collection: "Коллекция",
        "usage_basics__content-item": "Элемент контента",
        usage_basics__version: "Версия",
        getting_started: "С чего начать",
        "getting_started__tekla-account-and-system-requirements": "Tekla account и системные требования",
        "getting_started__tekla-warehouse-service-plugin": "Плагин Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Где взять Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures и Tekla Warehouse",
        content: "Контент на Tekla Warehouse",
        "content__3d-products": "3D-модели изделий",
        "content__custom-components": "Пользовательские компоненты",
        content__applications: "Приложения",
        "content__profiles-shapes-etc": "Профили, материалы, болты, арматура и др.",
        "content__model-templates": "Шаблоны моделей",
        "content__drawing-setup": "Настройки чертежей",
        "content__report-templates-etc": "Шаблоны отчетов и файлы атрибутов",
        using_content: "Использование контента",
        creating_content: "Создание контента",
        "creating_content__getting-started-with-content-creation": "Создание контента: с чего начать",
        "creating_content__expert-help-for-creating-content": "Помощь специалистов по созданию контента",
        application_developers: "Разработка приложений для Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Что такое Tekla Warehouse?",
        description_1:
          "На сервисе Tekla Warehouse хранятся приложения, пользовательские компоненты, детали, профили, материалы (например, сорта стали и бетона), болты, арматура, сетки, формы и шаблоны для использования в Tekla Structures. Использование в моделях деталей, смоделированных самими их изготовителями, обеспечивает максимальную точность и наличие всей необходимой информации для планирования и закупок. К тому же, использование готового контента значительно ускоряет процесс моделирования.",
        description_2:
          "Tekla Warehouse обеспечивает централизованный доступ к такому контенту и удобную работу с ним. Объем контента на Tekla Warehouse постоянно увеличивается.",
        description_3: "С помощью Tekla Warehouse:",
        feature_1:
          "изготовители могут предоставить свои изделия и приложения пользователям, которые, в свою очередь, могут использовать точные 3D-модели изделий в своих моделях Tekla, а впоследствии использовать физические изделия на этапе строительства;",
        feature_2:
          "вы можете использовать сеть своей компании для обмена контентом с коллегами и локального хранения контента для личного пользования.",
        description_4:
          "Пользуясь механизмом поиска Tekla Warehouse, вы легко сможете находить нужный вам контент любого типа.",
        description_5: "Для работы с локальными и сетевыми коллекциями необходим плагин Tekla Warehouse Service.",
        view_licences_html:
          'Просмотреть <a href="/api/third_party_licenses.txt" target="_blank">сторонние лицензии</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Основы работы с Tekla Warehouse",
        using_content: {
          title: "Использование контента",
          description_1:
            "Вы можете использовать контент в онлайн-коллекциях, обмениваться контентом с коллегами с помощью сетевых коллекций, а также создавать локальные коллекции для личного пользования.",
          description_2:
            "Для работы с локальными и сетевыми коллекциями необходим плагин Tekla Warehouse Service, который устанавливается в составе Tekla Structures 20.1 Service Release 1 и более новых версий Tekla Structures.",
          description_3:
            "Некоторые коллекции Tekla, содержащие локализованный контент для сред, устанавливаются вместе с Tekla Structures.",
          description_4: "Подробнее см. в разделе «Использование контента».",
        },
        adding_content: {
          title: "Добавление контента",
          description_1:
            'Онлайн-коллекции дают вам возможность опубликовать свой контент для всех пользователей Tekla Warehouse. Для публикации контента от имени организации ваша организация должна быть добавлена на веб-сайте account.tekla.com. За дополнительной информацией обратитесь в <a href="https://www.tekla.com/ru/%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D1%8B/%D0%BE%D1%84%D0%B8%D1%81%D1%8B-%D0%B8-%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B2%D1%86%D1%8B" target="_blank">офис или к реселлеру Tekla</a> в вашем регионе.',
          description_2: "Подробнее см. в разделе «Создание контента».",
        },
        collection: {
          title: "Коллекция",
          feature_1:
            "Коллекция группирует элементы контента подобно папке, а также определяет права доступа к контенту (общедоступный/частный).",
          feature_1_1:
            "Специалисты, работающие над созданием моделей, могут использовать контент в онлайн-коллекциях, обмениваться контентом с коллегами с помощью сетевых коллекций, а также создавать локальные коллекции для личного пользования.",
          feature_2:
            "Разработчики контента могут использовать онлайн-коллекции для публикации своего контента для всех пользователей Tekla Warehouse.",
          feature_3:
            "Для работы с локальными и сетевыми коллекциями нужен плагин Tekla Warehouse Service, который устанавливается в составе Tekla Structures 20.1 Service Release 1 и более новых версий, а также может быть установлен отдельно.",
        },
        "content-item": {
          title: "Элемент контента",
          feature_1:
            "Элемент контента — это логическая группа файлов. Например, каталог 3D-изделий, шаблон модели, инструмент, группа пользовательских компонентов или каталог профилей — это элементы контента.",
          feature_2:
            "Элементы контента можно маркировать по типу и назначению, чтобы пользователям было легче находить ваш контент.",
        },
        version: {
          title: "Версия",
          feature_1:
            "Элемент контент может иметь несколько версий, предназначенных для использования с разными версиями Tekla Structures. Пользователь может выбрать соответствующую версию на странице контента.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "С чего начать",
        tekla_account_and_system_requirements: {
          title: "Tekla account и системные требования",
          register_atc_html:
            'Для входа на сервис Tekla Warehouse необходима учетная запись Tekla account. Если вы еще не зарегистрировались, это можно сделать на сайте <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>. (Обратите внимание, что это не то же самое, что старая учетная запись Extranet.)',
          see_tua_for_prerequisites_html:
            'На сервисе Tekla User Assistance есть подробная информация о <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">предварительных условиях для использования Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Плагин Tekla Warehouse Service",
          description_1: "Помимо онлайн-хранилища контента, на Tekla Warehouse предусмотрены:",
          feature_1: "локальные коллекции для личного пользования;",
          feature_2: "сетевые коллекции для обмена контентом с коллегами по сети компании;",
          feature_3: "удобный механизм установки при использовании или создании контента;",
          feature_4: "поиск в локальном и сетевом контенте.",
          description_2:
            "Для использования этих функций необходим плагин Tekla Warehouse Service, который представляет собой локальную службы для обмена данными между Tekla Structures и веб-сайтом Tekla Warehouse.",
          description_3:
            "Если плагин Tekla Warehouse Service не установлен или не запущен, вверху страницы Tekla Warehouse появляется соответствующее сообщение, чтобы вы могли устранить проблему.",
          description_4:
            "При возникновении каких-либо проблем со службой ее можно перезапустить в списке служб Windows. Имя службы — Tekla Warehouse. Также проверьте параметры безопасности своего браузера.",
          description_5:
            "Веб-сайтом Tekla Warehouse можно пользоваться и без Tekla Warehouse Service или Tekla Structures. В этом случае он будет функционировать подобно обычному веб-сайту для обмена контентом, с отправкой и загрузкой файлов вручную.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Где взять Tekla Warehouse Service?",
          description_1:
            "Плагин Tekla Warehouse Service устанавливается в составе Tekla Structures 20.1 Service Release 1 и более новых версий. Также можно установить Tekla Warehouse Service отдельно, чтобы работать с локальными и сетевыми коллекциями, однако удобные функции для установки и отправки контента поддерживаются только при наличии Tekla Structures 20.1 Service Release 1 или более новой версии.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">ЗАГРУЗИТЬ TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures и Tekla Warehouse",
          description_1:
            "Tekla Warehouse подключается непосредственно к Tekla Structures, что упрощает как использование контента, так и его отправку в Tekla Warehouse. Вы можете выбирать контент непосредственно из своей модели, а также вставлять контент непосредственно в модель, то есть вам не нужно возиться с импортом и экспортом файлов (для этого необходимо наличие Tekla Structures 20.1 Service Release 1 или более новой версии). Все необходимые файлы можно включить в один и тот же элемент контента, например пользовательский компонент или профиль; это гарантирует, что все будет работать так, как задумано.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Контент на Tekla Warehouse",
        description_1:
          "На сервисе Tekla Warehouse хранится контент различных типов. Ниже приведен обзор этих типов и информация о том, с чего начинать работу с ними.",
        description_2:
          'С Tekla Warehouse также можно загружать <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">расширения Tekla</a>.',
        "3d_products": {
          title: "3D-модели изделий",
          image_subtitle: "Эти объекты с фиксированной геометрией, например закладные, опалубка и комплектующие.",
          tua_link_html:
            'На Tekla User Assistance вы найдете подробную информацию о <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">создании каталога 3D-изделий в коллекции Tekla Warehouse</a>.',
        },
        custom_components: {
          title: "Пользовательские компоненты",
          image_subtitle:
            "Пользовательские компоненты — это оригинальные объекты Tekla Structures с фиксированной или параметрической геометрией.",
          tua_link_html:
            'На Tekla User Assistance есть подробная информация об <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">отправке контента из модели Tekla Structures в коллекцию Tekla Warehouse</a>.',
        },
        applications: {
          title: "Приложения",
          image_subtitle:
            "Приложения взаимодействуют с Tekla Structures через Tekla Open API. Например, это могут быть связи с системой расчета и проектирования (средства для интеграции данных).",
          tua_link_html:
            'На Tekla User Assistance есть подробная информация об <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">отправке приложения в коллекцию Tekla Warehouse</a>.',
        },
        profiles_shapes_etc: {
          title: "Профили, формы, материалы, комплекты болтов, арматура и сетки",
          image_subtitle:
            "Каталоги содержат списки свойств, связанных с деталями. Например, это могут быть различные сорта материалов и их плотности.",
          tua_link_html:
            'На Tekla User Assistance есть подробная информация об <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">отправке контента из модели Tekla Structures в коллекцию Tekla Warehouse</a>.',
        },
        model_templates: {
          title: "Шаблоны моделей",
          image_subtitle:
            "Шаблон модели можно использовать для работы над проектами, похожими на уже выполненные. Шаблон может включать в себя все необходимые каталоги, шаблоны отчетов, настройки чертежей и расширенные параметры.",
          tua_link_html:
            'На Tekla User Assistance есть подробная информация об <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">добавлении файлов в коллекцию Tekla Warehouse вручную</a>.',
        },
        drawing_setup: {
          title: "Настройки чертежей",
          image_subtitle:
            "Файлы настроек определяют, как создаются чертежи, и могут включать в себя, например, компоновки, фильтры и шаблоны клонирования.",
          tua_link_html:
            'На Tekla User Assistance есть подробная информация об <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">добавлении файлов в коллекцию Tekla Warehouse вручную</a>.',
        },
        report_templates_etc: {
          title: "Шаблоны отчетов и файлы атрибутов модели",
          description_1:
            "Шаблоны отчетов определяют содержимое и компоновку извлекаемых из модели данных в отчете. Файлы атрибутов модели — например, фильтры — работают при добавлении в папку модели.",
          tua_link_html:
            'На Tekla User Assistance есть подробная информация об <a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">добавлении файлов в коллекцию Tekla Warehouse вручную</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Использование контента",
        description_1:
          "В зависимости от типа контента вы можете либо вставить выбранный контент в модель Tekla Structures, либо загрузить и установить его. Инструкции можно найти на Tekla User Assistance:",
        feature_1:
          '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Поиск контента на Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Использование контента с Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Вставка контента с Tekla Warehouse в модель Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Устранение конфликтов при работе с Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Создание контента",
        getting_started_with_content_creation: {
          title: "Создание контента: с чего начать",
          description_1: "На Tekla User Assistance есть информация по следующим вопросам:",
          feature_1:
            '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_getting_started" target="_blank">Начало работы с Tekla Warehouse</a>',
          feature_2:
            '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_adding_content" target="_blank">Добавление контента на Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Изменение контента на Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Загрузка контента с Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Помощь специалистов по созданию контента",
          description_1:
            'Если вас интересует публикация контента на Tekla Warehouse, однако вам хотелось бы прибегнуть к помощи специалистов для создания и обслуживания этого контента, обратитесь в <a href="https://www.tekla.com/ru/%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82%D1%8B/%D0%BE%D1%84%D0%B8%D1%81%D1%8B-%D0%B8-%D0%BF%D1%80%D0%BE%D0%B4%D0%B0%D0%B2%D1%86%D1%8B" target="_blank">офис или к реселлеру Tekla</a> в вашем регионе, чтобы больше узнать о возможных вариантах сотрудничества.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Разработка приложений для Tekla Structures",
        description_1:
          "Поскольку Tekla использует открытый подход к информационному моделированию зданий (BIM), вы можете работать с решениями и производственным оборудованием других поставщиков и обмениваться данными с Tekla.",
        description_2:
          '<a href="https://www.tekla.com/ru/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D1%81%D0%BE%D0%B2%D0%BC%D0%B5%D1%81%D1%82%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D1%8C-%D0%B8-%D0%B2%D0%B7%D0%B0%D0%B8%D0%BC%D0%BE%D0%B4%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D0%B5/open-apii" target="_blank">Tekla Open API</a> — это разработанный Tekla специализированный интерфейс прикладного программирования, предназначенный для разработки приложений и дополнительной функциональности на платформе моделирования Tekla Structures и ее интеграции в информационную среду предприятия. Tekla Open API реализован на базе технологии Microsoft .NET.',
        description_3:
          '<a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> — это портал, где собрана вся информация по Tekla Open API.',
        description_4:
          'Если вас интересует разработка приложений для Tekla Structures, присоединяйтесь к <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a>.',
      },
    };
  },
};
