import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";

export const TCCAnalyticsDS = {
  getAnalyticsData: function (data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/analytics", { ...data, contentType: "twh" }),
    );
  },
  getAnalyticsPublicData: function (data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/analytics/public", { ...data, contentType: "twh" }),
    );
  },
  getAnalyticsExtractionData: function (data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/analytics/extraction", { ...data, contentType: "twh" }),
      false,
      true,
    );
  },
  saveAnalyticsData: function (data) {
    return ResponseProcessor.process(RequestBuilder.doPost("/warehouse/v1.0/analytics", data));
  },
};
