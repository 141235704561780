import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Link } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { LinkToCollectionStoreContext } from "./result-options/linkToCollectionStore";
import { CollectionPageStoreContext } from "../collection/collectionPageStore";
import { IGetResourcePathContext } from "../search/searchPageStore";

import { IEntity, IEntityProps, ILinkingResult, IItem, IGetResourcePath, IResource } from "../models/dataModel";
import {
  ExternalResourceTypeEnum,
  SanitizationModeEnum,
  SearchPageListStyleEnum,
  ObjectTypeEnum,
} from "../models/enums";

import { TranslatedHtml } from "./TranslatedHtml";
import { TextWrapper } from "./TextWrapper";
import { AlertIcon } from "../my/alerts/AlertIcon";

import { isLiked, isLicenseRequired } from "../utils/functions";
import { ImageUtils } from "../utils/ImageUtils";
import { ImmutableFlag } from "./immutability/ImmutableFlag";
import { ArchivedFlag } from "./ArchivedFlag";

export const Unlink = observer(({ entity }: { entity: IEntity }) => {
  const rootStore = useContext(RootContext);
  const linkToCollectionStore = useContext(LinkToCollectionStoreContext);
  const collectionPageStore = useContext(CollectionPageStoreContext);
  const collection = collectionPageStore.getCollection();

  async function unlink() {
    if (collection) {
      renderLinkingResult(await linkToCollectionStore.unlink([entity], collection));
    }
  }

  function renderLinkingResult(linkingResult: ILinkingResult) {
    if (linkingResult.failures.length == 0) {
      rootStore
        .getNotificationChannelStore()
        .success(
          rootStore.getTranslation("shared.linked_resources.resources_unlinked", [linkingResult.collection.title]),
        );
    } else {
      rootStore
        .getNotificationChannelStore()
        .error(rootStore.getTranslation("shared.notification_channel.title_error"));
    }
  }

  return (
    <Fragment>
      <li>
        <a
          className="icon icon-close unlink-content"
          onClick={unlink}
          title={rootStore.getTranslation("collections.entity_list.unlink")}
        />
      </li>
    </Fragment>
  );
});

/**
 * A component to display entity / content item as a list item.
 */
export const EntityListItem = observer(
  ({ entity, listStyle = SearchPageListStyleEnum.THUMBNAILS, showAlerts, showUnlink }: IEntityProps) => {
    const rootStore: RootStore = useContext(RootContext);
    const getResourcePath: IGetResourcePath | undefined = useContext(IGetResourcePathContext);

    function handleSendAnalytics(e: React.MouseEvent<HTMLAnchorElement>) {
      if (e.type === "mousedown" && (e.button === 0 || e.button === 1)) {
        if (!!getResourcePath && !!getResourcePath.sendAnalyticsData) {
          getResourcePath?.sendAnalyticsData(entity);
        }
        return true;
      }
    }

    function handleContextMenuClick(e: React.MouseEvent<HTMLAnchorElement>) {
      e.preventDefault();
    }

    function getEntityPath(): string {
      return entity.isLocal ? "/catalog/localdetails/" : "/catalog/details/" + entity.id;
    }

    function getOrganizationPath(): string {
      return "/organization/" + entity.creator!.id;
    }

    function getEntityInlineStyles() {
      let thumbnailUrl;
      try {
        thumbnailUrl = entity.isLocal ? entity.thumbnail!.url : entity.thumbnails![0].contentUrl;
      } catch (err) {
        thumbnailUrl = ImageUtils.getDefaultThumbail();
      }
      return {
        backgroundImage: `url(${thumbnailUrl})`,
      };
    }

    function isMaintenanceLicenseRequired() {
      return isLicenseRequired(entity, "teklamaintenance");
    }

    function wasLiked() {
      return isLiked(entity);
    }

    function renderPackagesCount() {
      return (
        <Fragment>
          {entity.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION && (
            <div className="packages-count" data-testid="packagesCount">
              <span className="icon icon-drawer" />
              <span className="item-count">{entity.packagesCount}</span>
            </div>
          )}
        </Fragment>
      );
    }

    function renderCompanyName() {
      return (
        <Fragment>
          {!!entity.creator && entity.creator.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION && (
            <div className="company-name" data-testid="creatorName">
              <Link to={getOrganizationPath()} className="organization">
                {entity.creator.displayName}
              </Link>
            </div>
          )}
        </Fragment>
      );
    }

    function renderThumbnail() {
      return (
        <div className="thumb-item-details">
          <Link
            to={getEntityPath()}
            onMouseDown={handleSendAnalytics}
            onContextMenu={handleContextMenuClick}
            className="item-title"
            title={entity.title}
            data-testid="entityNameThumbnail"
          >
            {entity.title}
          </Link>
          {renderPackagesCount()}
          {renderCompanyName()}
        </div>
      );
    }

    function renderTags() {
      return (
        <ul className="tags" data-testid="tags">
          {(entity.attributes!.useCategories || []).slice().map((category, i) => {
            return (
              <li key={i} className="category semi-bold">
                <TranslatedHtml entry={"useCategories." + category} />
              </li>
            );
          })}
          {(entity.attributes!.itemTypeCategories || []).slice().map((category, i) => {
            return (
              <li key={i} className="category semi-bold">
                <TranslatedHtml entry={"itemTypeCategories." + category} />
              </li>
            );
          })}
        </ul>
      );
    }

    function renderList() {
      return (
        <div className="details">
          <Link
            to={getEntityPath()}
            onMouseDown={handleSendAnalytics}
            onContextMenu={handleContextMenuClick}
            className="item-title"
          >
            <h3 data-testid="entityNameList">{entity.title}</h3>
          </Link>
          {!entity.isLocal && (
            <ol>
              <div className="likes">
                <TranslatedHtml entry={"likes"} args={[entity.reviewCount || 0]} />
              </div>
            </ol>
          )}
          {renderPackagesCount()}
          {renderCompanyName()}
          {!!entity.description && (
            <div className="description">
              <TextWrapper text={entity.description} sanitizationMode={SanitizationModeEnum.STRICT} truncate={200} />
            </div>
          )}
          {renderTags()}
        </div>
      );
    }

    const maintenanceText = rootStore.getTranslation("shared.maintenance.requires_tekla_maintenance");
    const requiredMaintenanceText = rootStore.getTranslation("shared.maintenance.requires_tekla_maintenance");
    const itemClassNames = classNames("item", "can-select", {
      liked: wasLiked(),
      "thumb-item": listStyle == SearchPageListStyleEnum.THUMBNAILS,
      "item-selected": entity.selected,
    });

    function renderEntity() {
      return (
        <div className={itemClassNames} data-testid={`entity${entity.id}`}>
          <Link
            to={getEntityPath()}
            onMouseDown={handleSendAnalytics}
            onContextMenu={handleContextMenuClick}
            className="thumb-box"
          >
            <figure style={getEntityInlineStyles()} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: "0.5em",
                marginLeft: showAlerts ? "1.3em" : "-1em",
              }}
            >
              <ImmutableFlag resource={entity as IResource} />
              <ArchivedFlag resource={entity as IResource} />
            </div>
          </Link>
          {listStyle == SearchPageListStyleEnum.THUMBNAILS && renderThumbnail()}
          {listStyle == SearchPageListStyleEnum.LIST && renderList()}
          {isMaintenanceLicenseRequired() && (
            <ul
              className={classNames("maintenance", {
                listmode: listStyle == SearchPageListStyleEnum.LIST,
              })}
            >
              <li>
                <div
                  className={classNames("icon", "icon-maintenance", {
                    thumbmode: listStyle == SearchPageListStyleEnum.THUMBNAILS,
                  })}
                  title={maintenanceText}
                >
                  <span className="maintenance-text">{requiredMaintenanceText}</span>
                </div>
              </li>
            </ul>
          )}
          <ul
            className={classNames("item-actions", {
              listmode: listStyle == SearchPageListStyleEnum.LIST,
            })}
          >
            {showAlerts && <AlertIcon item={entity as IItem} />}
            {showUnlink && <Unlink entity={entity} />}
          </ul>
        </div>
      );
    }

    return renderEntity();
  },
);
