import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import { RootContext } from "../../stores/rootStore";
import { BatchEditDialogStoreContext } from "./batchEditDialogStore";
import { IDropdownOption } from "../../models/dataModel";
import { Dropdown } from "../../components/Dropdown";
import classNames from "classnames";

/**
 * A component that renders select action functionality
 */
export const SelectAction = observer(
  ({
    fieldName,
    options,
    getAction,
    setAction,
  }: {
    fieldName: string;
    options: IDropdownOption[];
    getAction: () => IDropdownOption | undefined;
    setAction: (option: IDropdownOption) => void;
  }) => {
    const rootStore = useContext(RootContext);
    const dialogStore = useContext(BatchEditDialogStoreContext);

    function getTestId() {
      return fieldName + "-action";
    }

    function handleActionChange(option: IDropdownOption | null) {
      if (!option) return;
      setAction(option);
    }

    return (
      <div
        className={classNames("actiontype", {
          "invalid-action": dialogStore.hasInvalidAction(fieldName),
        })}
      >
        <div className="dropdown-wrapper">
          <Dropdown
            options={options}
            onChange={handleActionChange}
            placeholder={rootStore.getTranslation("batch_edit.selectActionType")}
            className="dropdown-wrapper gray"
            selectedValue={getAction()}
            inputId={getTestId()}
          />
        </div>
      </div>
    );
  },
);
