import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { OrganizationPageStoreContext, OrganizationPageStore } from "../organizationPageStore";

import { IOrganizationContact } from "../../models/dataModel";
import { Dialog } from "../../dialogs/Dialog";

/**
 * Renders the "Edit contact" dialog content.
 */
const EditContactDialog = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const dialog: DialogStore = useContext(DialogContext);

  const [contactInfo, setContactInfo] = useState<IOrganizationContact>({
    url: { value: "" },
    email: { value: "" },
    phonenumber: { value: "" },
    facebook: { value: "" },
    twitter: { value: "" },
    linkedin: { value: "" },
    youtube: { value: "" },
  });

  useEffect(() => {
    const organization = orgStore.getOrganization();
    if (!!organization && !!organization.contact) {
      _.each(organization.contact, (value, key) => {
        setContactInfo((prevState) => {
          return { ...prevState, [key]: value };
        });
      });
    }
  }, []);

  function handleInput(event, field: string) {
    const strValue = { value: event.target.value };

    setContactInfo((prevState) => {
      return { ...prevState, [field]: strValue };
    });
  }

  function cancel() {
    dialog.close();
  }

  async function updateContact() {
    await orgStore.updateContact(contactInfo);
    dialog.close();
  }

  function renderField(value: string, fieldName: string, fieldType: string) {
    const isUrl = fieldType === "url";

    const renderFieldName = () => {
      let fieldNameStr = "organization.edit.edit_" + fieldName;

      if (fieldType === "url" && fieldName !== "url") {
        fieldNameStr = fieldNameStr + "_url";
      }

      return rootStore.getTranslation(fieldNameStr);
    };

    return (
      <li>
        <label className={classNames({ "tooltip-info": isUrl })} htmlFor={fieldName}>
          {renderFieldName()}
          {isUrl && (
            <span className="icon help blue" title={rootStore.getTranslation("organization.edit.edit_url_subtitle")} />
          )}
        </label>
        <input
          id={fieldName}
          type={fieldType}
          value={value}
          data-testid={fieldName + "Input"}
          onChange={(e) => handleInput(e, fieldName)}
        />
      </li>
    );
  }

  return (
    <article data-testid="editContactDialog">
      <header>
        <h2>{rootStore.getTranslation("organization.edit.edit_contact")}</h2>
      </header>
      <section>
        <ol className="basic-data">
          {renderField(contactInfo.url.value, "url", "url")}
          {renderField(contactInfo.email.value, "email", "email")}
          {renderField(contactInfo.phonenumber.value, "phonenumber", "text")}
          {renderField(contactInfo.facebook.value, "facebook", "url")}
          {renderField(contactInfo.twitter.value, "twitter", "url")}
          {renderField(contactInfo.linkedin.value, "linkedin", "url")}
          {renderField(contactInfo.youtube.value, "youtube", "url")}
        </ol>
      </section>
      <div className="actions">
        <button onClick={cancel}>{rootStore.getTranslation("actions.cancel")}</button>
        <button className="button-primary" onClick={updateContact}>
          {rootStore.getTranslation("actions.save")}
        </button>
      </div>
    </article>
  );
});

/**
 * Component that displays link to edit organization contact information.
 */
export const EditContact = observer(() => {
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const rootStore: RootStore = orgStore.getRootStore();
  const dialog: DialogStore = new DialogStore();

  function editContact() {
    dialog.open();
  }

  return (
    <DialogContext.Provider value={dialog}>
      <a
        className="icon icon-pencil"
        data-testid="organizationEditContactUrl"
        onClick={editContact}
        title={rootStore.getTranslation("organization.edit.edit_contact")}
      >
        <span>{rootStore.getTranslation("organization.edit.edit_contact")}</span>
      </a>
      <Dialog content={<EditContactDialog />} />
    </DialogContext.Provider>
  );
});
