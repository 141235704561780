import { select } from "underscore";
import _ from "underscore.string";

export const TagsConverter = {
  toTCC: function (data) {
    return data;
  },
  fromTCC: function (data) {
    data = data || "";
    return select(_.words(data, ","), function (el) {
      return !_(el).isBlank();
    });
  },
  toLocal: function (data) {
    data = select(data || [], function (el) {
      return !_(el).isBlank();
    });
    return data.join(",");
  },
  fromLocal: function (data) {
    data = data || "";
    return select(_.words(data, ","), function (el) {
      return !_(el).isBlank();
    });
  },
};
