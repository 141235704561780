import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import { Link } from "react-router";

import { RootContext } from "../../stores/rootStore";
import { EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { OrganizationPageStoreContext } from "../organizationPageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { Dialog } from "../../dialogs/Dialog";
import { EditDescriptionDialog } from "./EditDescriptionDialog";
import { TextWrapper } from "../../components/TextWrapper";

/**
 * A component that displays organization description
 */
export const OrganizationDescription = observer(() => {
  const rootStore = useContext(RootContext);
  const onlineStatusStore = rootStore.getOnlineStatusStore();
  const editorModeStore = useContext(EditorModeStoreContext);
  const dialog = new DialogStore();
  const orgStore = useContext(OrganizationPageStoreContext);
  const organization = orgStore.getOrganization();

  function editDescription() {
    dialog.open();
  }

  function searchByCreator(): string {
    const query = new URLSearchParams({
      creator: organization?.id || "",
      searchTarget: "PACKAGE",
    }).toString();

    return "/search?" + query;
  }

  function showSearchByCreatorLink(): boolean {
    return onlineStatusStore.isOnline();
  }

  return (
    <section className="description">
      {editorModeStore.getEditorMode() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-org-description"
            title={rootStore.getTranslation("organization.edit.edit_description")}
            onClick={editDescription}
            className="icon icon-pencil"
          >
            <span>{rootStore.getTranslation("organization.edit.edit_description")}</span>
          </a>
          <Dialog
            content={<EditDescriptionDialog organization={organization!} store={orgStore} />}
            additionalClass="edit-org-description"
          />
        </DialogContext.Provider>
      )}
      <label data-testid="org-description" className="description-text">
        {organization!.description}
      </label>
      {showSearchByCreatorLink() && (
        <div className="creator">
          <Link data-testid="search-by-creator" to={searchByCreator()}>
            <TextWrapper text={rootStore.getTranslation("organization.search_by", organization!.displayName)} />
          </Link>
        </div>
      )}
    </section>
  );
});
