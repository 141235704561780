import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";

import { ILocalCollection } from "../../../models/dataModel";
import { LocalCollectionsContext, LocalCollectionsStore } from "./localCollectionsStore";

/**
 * Renders the local collection card for 'Local and network collections' view in My Collections
 */
export const LocalCollectionItem = observer(({ collection }: { collection: ILocalCollection }) => {
  const store: LocalCollectionsStore = useContext(LocalCollectionsContext);

  return (
    <div className="item thumb-item">
      <Link className="thumb-box" to={collection.getCollectionPath!()}>
        <figure data-testid="image" style={store.getCollectionThumbnail(collection)} />
      </Link>
      <div className="collection-item-details">
        <Link className="localCollection.title" title={collection.title} to={collection.getCollectionPath!()}>
          {collection.title}
        </Link>
        <div className="collection">
          <span className="label">{collection.path}</span>
        </div>
        <div className="items">
          <span data-testid="icon" className="icon icon-drawer" />
          <span className="item-count">{collection.packagesCount}</span>
        </div>
      </div>
    </div>
  );
});
