import { IEntity, IFileItem } from "../../models/dataModel";
import { RootStore } from "../../stores/rootStore";
import { createStoreContext } from "../../stores/rootStore";
import { FileHandleConverter } from "../../utils/converters/FileHandleConverter";
import { TCCPackageService } from "../../js/services/TCCPackageService";
import { NewRepository } from "../../js/services/NewRepository";
import { action, observable, runInAction, makeObservable } from "mobx";

/**
 * Store used to handle data for ReleaseNotes component
 */
export class ReleaseNotesStore {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Package object
   */
  @observable private packageItem: IEntity | undefined;

  /**
   * Selected release note file
   */
  @observable
  private selectedFile?: File;

  /**
   * Filename for selected file
   */
  @observable
  private fileName = "";

  /**
   * Indicator if changes were made to file
   */
  @observable
  private wasUpdated = false;

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param packageItem Package object
   */
  public constructor(rootStore: RootStore, packageItem?: IEntity) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (packageItem) {
      runInAction(() => {
        this.packageItem = packageItem;
      });
    }
    this.init();
  }

  /**
   * Gets package object
   * @returns package object
   */
  public getPackage(): IEntity | undefined {
    return this.packageItem;
  }

  /**
   * Checks if file was being updated
   * @returns true if file was updated
   */
  public wasFileUpdated(): boolean {
    return this.wasUpdated;
  }

  /**
   * Checks if file is selected
   * @returns true if file is selected
   */
  public isFileSelected(): boolean {
    return !!this.selectedFile;
  }

  /**
   * Sets selected file
   * @param file Selected file
   */
  @action
  public setSelectedFile(file: File) {
    this.selectedFile = file;
    this.fileName = file.name;
    this.wasUpdated = true;
  }

  /**
   * Gets selected file
   * @returns selected file or undefined if not selected
   */
  public getSelectedFile(): File | undefined {
    return this.selectedFile;
  }

  /**
   * Gets filename
   * @returns filename for already existing file or selected file
   */
  public getFileName(): string {
    return this.fileName;
  }

  /**
   * Removes file selection
   */
  @action
  public removeFileSelection() {
    this.selectedFile = undefined;
    this.fileName = "";
    this.wasUpdated = true;
  }

  /**
   * Initializes values
   */
  @action
  public init() {
    if (this.packageItem && this.packageItem.releasenote) {
      this.fileName = this.packageItem.releasenote.attributes!.fileName;
    }
    this.selectedFile = undefined;
    this.wasUpdated = false;
  }

  /**
   * Adds or deletes release note file
   */
  public save() {
    if (this.packageItem && this.packageItem.releasenote && !this.isFileSelected()) {
      this.removeReleaseNoteFile();
    } else if (this.packageItem && this.isFileSelected()) {
      this.addReleaseNoteFile();
    }
  }

  /**
   * Checks if release notes file exists
   * @returns true if file exists
   */
  public releaseNoteExists(): boolean {
    return !!this.packageItem && !!this.packageItem.releasenote && !!this.packageItem.releasenote.contentUrl;
  }

  /**
   * Gets release note file
   * @returns release notes file if exists
   */
  public getReleaseNoteFile(): IFileItem | undefined {
    return this.releaseNoteExists() ? this.packageItem!.releasenote! : undefined;
  }

  /**
   * Gets release note file binary data
   * @returns binary data
   */
  public async getFileBinaryData(file: IFileItem): Promise<any> {
    return TCCPackageService.getBinaryData(file.url);
  }

  private async removeReleaseNoteFile() {
    const currentUser = this.rootStore.getUserStore().getCurrentUser();
    if (!!this.packageItem && !!currentUser) {
      try {
        const updatedPackage = await NewRepository.deleteReleaseNote(this.packageItem, currentUser.id);
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("shared.catalog_entity_edit.updated_successfully"));

        runInAction(() => {
          this.packageItem!.releasenote = updatedPackage.releasenote;
        });
      } catch {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("shared.catalog_entity_edit.update_failed"));
      }
    }
  }

  private async addReleaseNoteFile() {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!this.packageItem && !!user) {
      try {
        const updatedPackage = await NewRepository.addReleaseNote(
          this.packageItem,
          FileHandleConverter.fromFilePickerToBinary(this.selectedFile!),
          user.id,
        );
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("shared.catalog_entity_edit.updated_successfully"));

        runInAction(() => {
          this.packageItem!.releasenote = updatedPackage.releasenote;
        });
      } catch {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("shared.catalog_entity_edit.update_failed"));
      }
    }
  }
}

/**
 * Context object for ReleaseNotesStore instances.
 */
export const ReleaseNotesStoreContext = createStoreContext<ReleaseNotesStore>(ReleaseNotesStore);
