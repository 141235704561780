import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../../stores/rootStore";
import { RelatedContentStore, RelatedContentStoreContext } from "../tabs/relatedContentStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { Dialog } from "../../dialogs/Dialog";

const DialogContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: RelatedContentStore = useContext(RelatedContentStoreContext);
  const dialog: DialogStore = useContext(DialogContext);

  function closeDialog() {
    dialog.close();
  }

  function save() {
    store.addRelatedContent();
    dialog.close();
  }

  function handleInputChange(event) {
    store.setRelatedInput(event.target.value);
  }

  return (
    <article>
      <header>
        <h2 className="l-center-align">{rootStore.getTranslation("details.related_content.add_related_content")}</h2>
        <div className="related-info">
          {rootStore.getTranslation("details.related_content.add_related_content_info")}
        </div>
      </header>
      <section>
        <ol className="basic-data">
          <div className="related-input">
            <li>
              <label
                className="tooltip-info"
                title={rootStore.getTranslation("details.related_content.relatedContentUrlSubtitle")}
              >
                {rootStore.getTranslation("details.related_content.related_content_url")}
                <span className="icon help blue" />
              </label>
              <input
                data-testid="related-input"
                type="text"
                value={store.getRelatedInput()}
                onChange={handleInputChange}
              ></input>
            </li>
          </div>
        </ol>
      </section>
      <div className="actions">
        <button onClick={() => closeDialog()}>{rootStore.getTranslation("actions.cancel")}</button>
        <button data-testid="save-related" disabled={!store.isInputValid} onClick={() => save()}>
          {rootStore.getTranslation("actions.save")}
        </button>
      </div>
    </article>
  );
});

/**
 * Renders the editor dialog & button for RelatedContent in package page
 */
export const EditRelatedContent = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);

  function openDialog() {
    dialog.open();
  }

  return (
    <Fragment>
      <button data-testid="add-related-content" className="add-related-content" onClick={openDialog}>
        {rootStore.getTranslation("details.related_content.add_related_content")}
      </button>
      <Dialog content={<DialogContent />} additionalClass="related-content" />
    </Fragment>
  );
});
