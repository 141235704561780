import * as React from "react";
import { useContext, Fragment, useEffect } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootStore, RootContext } from "../../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../../uploadFormStore";
import { UploadVersionStore, VersionUploaderViewState } from "../uploadVersionStore";

import { Dropdown } from "../../../components/Dropdown";
import { metadataConstants } from "../../../constants/MetadataConstants";
import { DialogContext, DialogStore } from "../../../dialogs/dialogStore";

import { IFile, IDropdownOption, IFileItem } from "../../../models/dataModel";
import { PlatformEnum } from "../../../models/enums";

/**
 * Component for the 'Select platform' dropdown.
 */
export const SelectPlatform = observer(({ file }: { file: IFile }) => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const platformOptions: IDropdownOption[] = metadataConstants.platformKeys.map((key) => {
    return { value: key, label: rootStore.getTranslation("platforms." + key) };
  });

  const filteredPlatformOptions = metadataConstants.platformKeys.map((key) => {
    return { value: key, label: rootStore.getTranslation("platforms." + key) };
  }).filter((option: IDropdownOption) => {
    return !version.toolWithPlatformExists(option.value);
  });

  const selectedPlatform: IDropdownOption | undefined = _.find(platformOptions, (option: IDropdownOption) => {
    return version.getPlatformForTool(file.name) === option.value;
  });

  function handleSelectPlatform(option: IDropdownOption | null) {
    if (!option) return;

    version.setPlatformForTool(file.name, option.value);
  }

  return (
    <td data-testid="selectPlatform">
      <Dropdown
        onChange={handleSelectPlatform}
        inputId="selectPlatform"
        options={filteredPlatformOptions}
        placeholder={rootStore.getTranslation("versions.select_platform")}
        selectedValue={selectedPlatform}
        className="dropdown-wrapper light"
      />
    </td>
  );
});

/**
 * Component for rendering a row for a tool/application.
 */
const Tool = observer(({ file }: { file: IFile }) => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  function removeSelectedFile() {
    version.removeSelectedFile(file, "selectedTools");
  }

  return (
    <Fragment>
      <td data-testid="fileName">{file.name}</td>
      <SelectPlatform file={file} />
      {!form.isLocal() && <td>{version.getFileSize(file.size)}</td>}
      <td className="auto-width">
        <a className="icon icon-trash" data-testid="removeApplication" onClick={removeSelectedFile} />
      </td>
    </Fragment>
  );
});

/**
 * Wrapper components for rendering the file list.
 */
const FileList = observer(() => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  return (
    <tbody>
      {version.getSelectedTools().map((file: IFile, i: number) => {
        return (
          <tr key={i}>
            <Tool file={file} />
          </tr>
        );
      })}
    </tbody>
  );
});

/**
 * Main component for the 'upload applications' view of the file upload stage.
 */
export const Applications = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();
  const dialog: DialogStore = useContext(DialogContext);

  const showSelectedFiles = version.getViewState() === VersionUploaderViewState.APPLICATION && version.getSelectedTools().length > 0;
  const acceptedFileTypes = ".msi, .zip, .exe";

  function triggerFileUpload(element) {
    if (element && version.canSelectFiles()) element.click();
  }

  function handleSelectFiles(event) {
    version.selectFiles(event.target.files, "selectedTools");
  }

  return (
    <div data-testid="applications">
      <div className="wrapper full-width-center">
        {!dialog.isDialogVisible() && (
          <input
            className="hidden-file-upload"
            id="toolupload"
            type="file"
            ref={triggerFileUpload}
            accept={acceptedFileTypes}
            onChange={handleSelectFiles}
            multiple
            data-testid="addApplications"
          />
        )}
        {showSelectedFiles && (
          <section className="selected-files" data-testid="selectedFilesList">
            <form name="toolsForm" noValidate>
              <table>
                <thead>
                  <tr>
                    <th>{rootStore.getTranslation("versions.table_header.name")}</th>
                    <th>{rootStore.getTranslation("versions.table_header.platform")}</th>
                    {!form.isLocal() && <th>{rootStore.getTranslation("versions.table_header.size")}</th>}
                    <th className="auto-width" />
                  </tr>
                </thead>
                <FileList />
              </table>
            </form>
          </section>
        )}
      </div>
    </div>
  );
});
