import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootContext } from "../../stores/rootStore";
import { AnalyticsStoreContext, IAnalyticsFiltersSetter, AnalyticsFiltersSetterContext } from "./analyticsStore";

import { IDropdownOption } from "../../models/dataModel";
import { AnalyticsTimeFrameEnum } from "../../models/enums";

import { Dropdown } from "../Dropdown";
import { DateInput } from "../DateInput";

/**
 * A component that renders timeframe filter
 */
export const TimeFrameFilter = observer(() => {
  const rootStore = useContext(RootContext);
  const analyticsStore = useContext(AnalyticsStoreContext);
  const timeFrameOptions = analyticsStore.getTimeFrameOptions();

  const analyticsFilterSetter: IAnalyticsFiltersSetter = useContext(AnalyticsFiltersSetterContext);
  const selectedTimeFrame = analyticsFilterSetter.getTimeFrame();

  function changeTimeFrame(opt: IDropdownOption | null) {
    if (!opt) return;
    analyticsFilterSetter.setTimeFrame(opt.value);
  }

  function getSelectedOption() {
    if (selectedTimeFrame) {
      return _.find(timeFrameOptions, (option) => option.value == selectedTimeFrame);
    }
  }

  function shouldShowCustomDatePicker(opt: IDropdownOption) {
    return opt.value === AnalyticsTimeFrameEnum.CUSTOM;
  }

  function handleStartDateSelection(date: Date | null) {
    if (!date) return;
    analyticsFilterSetter.setStartDate(date);
  }

  function handleEndDateSelection(date: Date | null) {
    if (!date) return;
    analyticsFilterSetter.setEndDate(date);
  }

  const selectedOption = getSelectedOption();

  return (
    <div className="timeframe-options">
      <span className="label">{rootStore.getTranslation("shared.analytics.timeframe")}</span>
      <div className="dropdown-container">
        <Dropdown
          options={timeFrameOptions}
          onChange={changeTimeFrame}
          placeholder={rootStore.getTranslation("shared.analytics.timeframe")}
          className="dropdown-wrapper gray"
          selectedValue={selectedOption}
        />
      </div>
      {shouldShowCustomDatePicker(selectedOption!) && (
        <div data-testid="date-picker" className="timeframe-custom">
          <span className="label">{rootStore.getTranslation("shared.analytics.timeframe_start")}</span>
          <DateInput
            selectedValue={analyticsFilterSetter.getStartDate()}
            onChange={handleStartDateSelection}
            testId="startDate"
          />
          <br />
          <span className="label">{rootStore.getTranslation("shared.analytics.timeframe_end")}</span>
          <DateInput
            selectedValue={analyticsFilterSetter.getEndDate()}
            onChange={handleEndDateSelection}
            testId="endDate"
          />
        </div>
      )}
    </div>
  );
});
