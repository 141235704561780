import * as React from "react";

/**
 * A component that displays Trimble logo
 */
export const TrimbleLogo = () => {
  return (
    <div className="global-block">
      <span className="logo">
        <img src="/images/trimble-logo.svg" alt="Trimble" />
      </span>
    </div>
  );
};
