import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

import { IEntity, ICollection, IItem } from "../../models/dataModel";
import { SearchTargetOptionEnum, SearchPageListStyleEnum } from "../../models/enums";

import { EntityOrCollection } from "../EntityOrCollection";
import { RootContext } from "../../stores/rootStore";
import { NotificationsForItem } from "../../my/alerts/NotificationsForItem";

/**
 * A component that renders the results of a search page.
 * @param pageWhereUsed page where the results are rendered
 * @param packages list of packages
 * @param collections list of collections
 * @param contentFinishedLoading boolean that reflects if the content finished loading
 */
export const ResultList = observer(
  ({
    ...props
  }: {
    pageWhereUsed?: string;
    packages?: IEntity[];
    collections?: ICollection[];
    contentFinishedLoading: boolean;
  }) => {
    const rootStore = useContext(RootContext);
    const searchStore = rootStore.getSearchStore();

    const searchingForPackages = searchStore.getSearchTarget() === SearchTargetOptionEnum.PACKAGE;
    const searchingForCollections = searchStore.getSearchTarget() === SearchTargetOptionEnum.COLLECTION;
    const resultListStyle = searchStore.getSearchPageSettingsStore().resultListStyle;

    const displayAs = resultListStyle !== SearchPageListStyleEnum.LIST ? " as-thumbnails" : "";
    const pageSpecificClass = props.pageWhereUsed ? `${props.pageWhereUsed}` + "-results-container" : "";

    function showAlerts(): boolean {
      if (props.pageWhereUsed && props.pageWhereUsed === "alerts") return true;
      else return false;
    }

    return (
      <section className={classNames("results-container", pageSpecificClass, displayAs)}>
        <div className="listing-wrapper">
          <div className="l-center-align">
            {!props.contentFinishedLoading && (
              <div className="spinner" data-testid="spinner" role="status">
                {rootStore.getTranslation("shared.spinner_loading")}
              </div>
            )}
          </div>
          {props.contentFinishedLoading && (
            <Fragment>
              <div className="results" data-testid="results">
                {props.packages &&
                  searchingForPackages &&
                  searchStore
                    .sortResults(searchStore.filterResults(props.packages as IItem[]))
                    .map((entity: IItem, i: number) => {
                      return (
                        <div className="result" data-testid="package" key={i}>
                          <EntityOrCollection item={entity} style={resultListStyle} showAlerts={showAlerts()} />
                          {showAlerts() && <NotificationsForItem item={entity as IItem} />}
                        </div>
                      );
                    })}
              </div>
              {props.collections &&
                searchingForCollections &&
                searchStore
                  .sortResults(searchStore.filterResults(props.collections as IItem[]))
                  .map((collection: IItem, i: number) => {
                    return (
                      <div className="result" data-testid="collection" key={i}>
                        <EntityOrCollection item={collection} style={resultListStyle} showAlerts={showAlerts()} />
                        {showAlerts() && <NotificationsForItem item={collection as IItem} />}
                      </div>
                    );
                  })}
            </Fragment>
          )}
        </div>
      </section>
    );
  },
);
