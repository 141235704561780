import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";
import classNames from "classnames";
import _ from "underscore";
import * as InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router";

import { RootContext, RootStore } from "../stores/rootStore";
import { UserStore } from "../stores/userStore";
import { SearchPageStore, SearchPageStoreContext } from "./searchPageStore";
import { IDropdownOption, IOrganization } from "../models/dataModel";
import { AvailableAttributes, ResultSetEnum, SearchPageListStyleEnum, SearchTargetOptionEnum } from "../models/enums";

import { EntityOrCollection } from "../components/EntityOrCollection";
import { Dropdown } from "../components/Dropdown";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { TextWrapper } from "../components/TextWrapper";
import { isLicenseRequired } from "../utils/functions";

/**
 * Renders list of TCC resources
 */
const TCCResultsLoop = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  return (
    <Fragment>
      {_.map(searchPageStore.getResultsSorted(ResultSetEnum.TCC), (item, i) => {
        return (
          <div className="result" key={i} data-tcc-item-id={item.id}>
            <EntityOrCollection item={item} style={searchPageStore.getSearchPageSettingsStore().resultListStyle} />
          </div>
        );
      })}
    </Fragment>
  );
});

/**
 * Renders TCC results
 */
const TCCResults = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const userStore: UserStore = rootStore.getUserStore();
  const thumbMode: boolean =
    searchPageStore.getSearchPageSettingsStore().resultListStyle == SearchPageListStyleEnum.THUMBNAILS;

  function setResultsPerPage(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;

    searchPageStore.setResultsPerPage(parseInt(selectedOption.value));
  }

  const resultsPerPageOptions: IDropdownOption[] = _.map([12, 36, 60, 120], (perPage) => {
    return { value: "" + perPage, label: "" + perPage };
  });

  const perPage: string = "" + searchPageStore.getResultsPerPage();
  const resultsPerPageSelectedOption: IDropdownOption = {
    value: perPage,
    label: perPage,
  };

  function showTMinfo(): boolean {
    const tm = "teklamaintenance";
    return _.any(searchPageStore.getResults(ResultSetEnum.TCC), (entity) => {
      if (isLicenseRequired(entity, tm)) {
        return !userStore.hasMaintenance();
      } else {
        return false;
      }
    });
  }

  function displayAddAll(): boolean {
    return searchPageStore.getTotalResultsCount() > 100 && searchPageStore.getTotalResultsCount() < 1000;
  }

  function getSummaryText(): string {
    return rootStore.getTranslation(
      "search.results.summary_html",
      searchPageStore.getResultsCount(ResultSetEnum.TCC),
      searchPageStore.getTotalResultsCount(ResultSetEnum.TCC),
      searchPageStore.getSearchTerm(),
    );
  }

  function hasMore(): boolean {
    return (
      !searchPageStore.isLoading() && !searchPageStore.isTCCDataBeingLoaded() && searchPageStore.isMoreDataToLoad()
    );
  }

  function loadMore(): void {
    searchPageStore.fetchMoreResults(ResultSetEnum.TCC);
  }

  return (
    <Fragment>
      {searchPageStore.getSearchTarget() != SearchTargetOptionEnum.ORGANIZATION && (
        <div>
          {searchPageStore.getResultSet() == ResultSetEnum.TCC && (
            <section className="search-results-tcc">
              <div className="results-summary">
                {!searchPageStore.isTCCDataBeingLoaded() && searchPageStore.wasDataFetched() && (
                  <span className="label results-count">{getSummaryText()}</span>
                )}
                {!searchPageStore.getUseInfiniteScroll() && (
                  <div className="paging-options">
                    <span className="label">{rootStore.getTranslation("helpers.resultsPerPage")}</span>
                    <div className="paging-dropdown">
                      <Dropdown
                        options={resultsPerPageOptions}
                        onChange={setResultsPerPage}
                        selectedValue={resultsPerPageSelectedOption}
                        className="results-per-page dropdown-wrapper light"
                        inputId={`results-per-page`}
                      />
                    </div>
                  </div>
                )}
              </div>
              {userStore.getCurrentUser() && !searchPageStore.isLoading() && (
                <div className="download-install">
                  {searchPageStore.getSearchTarget() === SearchTargetOptionEnum.PACKAGE && (
                    <div className="version-downloads">
                      <div className="version-buttons">
                        {searchPageStore.getTotalResultsCount() > 0 && (
                          <Fragment>
                            <button
                              className="add-all-to-basket"
                              onClick={() => searchPageStore.addAllItemsToShoppingCart()}
                              disabled={searchPageStore.getTotalResultsCount() >= 1000}
                            >
                              <span className="cart-button-text">
                                {rootStore.getTranslation("download.add_all_items_to_basket")}
                                <span className="icon-warehouse" />
                              </span>
                            </button>
                            {displayAddAll() && (
                              <div className="add-all-info-text">
                                {rootStore.getTranslation("download.add_all_items_to_basket_info")}
                              </div>
                            )}
                            {searchPageStore.getTotalResultsCount() >= 1000 && (
                              <div className="add-all-info-text">
                                {rootStore.getTranslation("download.add_all_items_to_basket_too_many_results")}
                              </div>
                            )}
                            {showTMinfo() && (
                              <section className="requires-maintenance">
                                <div
                                  className={classNames({
                                    "icon-maintenance": true,
                                    thumbmode: thumbMode,
                                  })}
                                />
                                <div className="thumbmode">
                                  {rootStore.getTranslation("install.download_requires_maintenance")}
                                </div>
                              </section>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!searchPageStore.isTCCDataBeingLoaded() && searchPageStore.wasDataFetched() && (
                <div className="results item-grid infinite" data-testid="tcc-results">
                  {searchPageStore.getUseInfiniteScroll() && (
                    <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore()} threshold={100}>
                      <TCCResultsLoop />
                    </InfiniteScroll>
                  )}
                  {!searchPageStore.getUseInfiniteScroll() && <TCCResultsLoop />}
                </div>
              )}
              {(searchPageStore.isLoading() || searchPageStore.isTCCDataBeingLoaded()) && (
                <div className="load-indicator">
                  <div className="spinner">{rootStore.getTranslation("shared.spinner_loading")}</div>
                </div>
              )}
              {hasMore() && (
                <button className="button-primary" onClick={loadMore}>
                  {rootStore.getTranslation("actions.load_more")}
                </button>
              )}
            </section>
          )}
        </div>
      )}
    </Fragment>
  );
});

/**
 * Renders list of local resources
 */
const LocalResultsLoop = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  return (
    <Fragment>
      {_.map(searchPageStore.getResultsSorted(ResultSetEnum.LOCAL), (item, i) => {
        return (
          <div className="result" key={i} data-local-item-id={item.id}>
            <EntityOrCollection item={item} style={searchPageStore.getSearchPageSettingsStore().resultListStyle} />
          </div>
        );
      })}
    </Fragment>
  );
});

/**
 * Renders local results
 */
const LocalResults = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  function setResultsPerPage(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;

    searchPageStore.setResultsPerPage(parseInt(selectedOption.value));
  }

  const resultsPerPageOptions: IDropdownOption[] = _.map([12, 36, 60, 120], (perPage: number) => {
    return { value: perPage.toString(), label: perPage.toString() };
  });

  const perPage: string = "" + searchPageStore.getResultsPerPage();
  const resultsPerPageSelectedOption: IDropdownOption = {
    value: perPage,
    label: perPage,
  };

  function shouldShowVersionsInfo(): boolean {
    return (
      _.size(searchPageStore.getFilters(AvailableAttributes.SW_VERSIONS)) > 0 &&
      !searchPageStore.isLocalDataBeingLoaded()
    );
  }

  function hasMore(): boolean {
    return (
      !searchPageStore.isLoading() &&
      !searchPageStore.isLocalDataBeingLoaded() &&
      searchPageStore.isMoreDataToLoad(ResultSetEnum.LOCAL)
    );
  }

  function displaySpinner(): boolean {
    return searchPageStore.isLoading() || searchPageStore.isLocalDataBeingLoaded();
  }

  function loadMore(): void {
    searchPageStore.fetchMoreResults(ResultSetEnum.LOCAL);
  }

  return (
    <Fragment>
      {searchPageStore.getSearchTarget() != SearchTargetOptionEnum.ORGANIZATION && (
        <div>
          {searchPageStore.getResultSet() == ResultSetEnum.LOCAL && (
            <section className="search-results-local">
              {!rootStore.getLocalServiceStore().isLocalServiceAccessible() && (
                <div style={{ marginTop: "2em" } as React.CSSProperties}>
                  <p className="blue">
                    <TranslatedHtml entry={"errors.plugin_is_needed_html"} />
                  </p>
                </div>
              )}
              {rootStore.getLocalServiceStore().isLocalServiceAccessible() && (
                <div>
                  <div className="results-summary">
                    {!searchPageStore.isLocalDataBeingLoaded() && (
                      <span className="label results-count">
                        {rootStore.getTranslation(
                          "search.results.summary_html",
                          searchPageStore.getResultsCount(ResultSetEnum.LOCAL),
                          searchPageStore.getTotalResultsCount(ResultSetEnum.LOCAL),
                          searchPageStore.getSearchTerm(),
                        )}
                      </span>
                    )}
                    {!searchPageStore.getUseInfiniteScroll() && (
                      <div className="paging-options">
                        <span className="label">{rootStore.getTranslation("helpers.resultsPerPage")}</span>
                        <div className="paging-dropdown">
                          <Dropdown
                            options={resultsPerPageOptions}
                            onChange={setResultsPerPage}
                            selectedValue={resultsPerPageSelectedOption}
                            className="results-per-page dropdown-wrapper light"
                            inputId={`results-per-page`}
                          />
                        </div>
                      </div>
                    )}
                    {shouldShowVersionsInfo() && (
                      <div className="tested-versions-filter-info" data-testid="version-info">
                        <div className="warning-sign" />
                        <div className="warning-text">
                          {rootStore.getTranslation("search.results.version_filter_info")}
                        </div>
                      </div>
                    )}
                  </div>
                  {!searchPageStore.isLocalDataBeingLoaded() && (
                    <div className="results item-grid infinite" data-testid="local-results">
                      {searchPageStore.getUseInfiniteScroll() && (
                        <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore()} threshold={100}>
                          <LocalResultsLoop />
                        </InfiniteScroll>
                      )}
                      {!searchPageStore.getUseInfiniteScroll() && <LocalResultsLoop />}
                    </div>
                  )}
                  {displaySpinner() && (
                    <div className="load-indicator">
                      <div className="spinner">{rootStore.getTranslation("shared.spinner_loading")}</div>
                    </div>
                  )}
                  {hasMore() && (
                    <button className="button-primary" onClick={loadMore}>
                      {rootStore.getTranslation("actions.load_more")}
                    </button>
                  )}
                </div>
              )}
            </section>
          )}
        </div>
      )}
    </Fragment>
  );
});

/**
 * Renders organization as a list item
 */
const OrganizationListItem = observer(({ organization }: { organization: IOrganization }) => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const thumbMode: boolean =
    searchPageStore.getSearchPageSettingsStore().resultListStyle == SearchPageListStyleEnum.THUMBNAILS;

  function organizationInlineStyles(organization): React.CSSProperties {
    const thumbnailUrl = organization?.binaries?.thumbnail?.contentUrl || "/images/company_default.png";
    return {
      backgroundImage: `url(${thumbnailUrl})`,
    };
  }

  function thumbBoxClassNames(organization: IOrganization): string {
    return classNames({
      "figure-organization": true,
      defaultbackground: !organization?.binaries?.thumbnail?.contentUrl,
    });
  }

  function getOrganizationPath(): string {
    return "/organization/" + organization.id;
  }

  return (
    <div className="result" data-organization-id={organization.id}>
      <div className={classNames({ item: true, "thumb-item": thumbMode })}>
        <Link to={getOrganizationPath()} className="thumb-box">
          <div className={thumbBoxClassNames(organization)} style={organizationInlineStyles(organization)} />
        </Link>
        {thumbMode && (
          <div className="thumb-item-details">
            {!!organization.displayName && (
              <Link to={getOrganizationPath()} className="item-title" title={organization.displayName}>
                <TextWrapper text={organization.displayName} truncate={40} />
              </Link>
            )}
          </div>
        )}
        {!thumbMode && (
          <div className="details">
            {!!organization.displayName && (
              <Link to={getOrganizationPath()} className="item-title-organization">
                <h3>
                  <TextWrapper text={organization.displayName} truncate={55} />
                </h3>
              </Link>
            )}
            {!!organization.details && (
              <div className="description">
                <TextWrapper text={organization.details} truncate={500} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

/**
 * Renders organizations list
 */
const OrganizationsLoop = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  return (
    <Fragment>
      {_.map(searchPageStore.getOrganizationsSorted(), (organization: IOrganization, i: number) => {
        return <OrganizationListItem organization={organization} key={i} />;
      })}
    </Fragment>
  );
});

/**
 * Renders organizations
 */
const Organizations = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);

  function hasMore(): boolean {
    return (
      !searchPageStore.isLoading() &&
      !searchPageStore.areOrganizationsBeingLoaded() &&
      searchPageStore.areMoreOrganizationsToLoad()
    );
  }

  function getResultsCountText(): string {
    return rootStore.getTranslation(
      "search.results.organization_search_summary_html",
      searchPageStore.getOrganizationsTotalCount(),
      searchPageStore.getSearchTerm(),
    );
  }

  function loadMore(): void {
    searchPageStore.fetchMoreOrganizations();
  }

  return (
    <Fragment>
      {searchPageStore.getSearchTarget() == SearchTargetOptionEnum.ORGANIZATION && (
        <section className="search-results-organizations">
          {!searchPageStore.areOrganizationsBeingLoaded() && searchPageStore.wasDataFetched() && (
            <div className="results-summary">
              <span className="label results-count">{getResultsCountText()}</span>
            </div>
          )}
          <div className="results item-grid infinite" data-testid="organizations-results">
            {searchPageStore.getUseInfiniteScroll() && (
              <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore={hasMore()} threshold={100}>
                <OrganizationsLoop />
              </InfiniteScroll>
            )}
            {!searchPageStore.getUseInfiniteScroll() && <OrganizationsLoop />}
          </div>
          {searchPageStore.areOrganizationsBeingLoaded() && (
            <div className="load-indicator">
              <div className="spinner" data-testid="loadingOrganizations">
                {rootStore.getTranslation("shared.spinner_loading")}
              </div>
            </div>
          )}
          {hasMore() && (
            <button className="button-primary" onClick={loadMore}>
              {rootStore.getTranslation("actions.load_more")}
            </button>
          )}
        </section>
      )}
    </Fragment>
  );
});

/**
 * Search results wrapper
 */
export const SearchResults = observer(() => {
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const thumbMode: boolean =
    searchPageStore.getSearchPageSettingsStore().resultListStyle == SearchPageListStyleEnum.THUMBNAILS;
  const narrowMode: boolean = searchPageStore.isNarrowSearchMode();

  return (
    <div
      className={classNames({
        "right-panel": true,
        narrowmode: narrowMode,
        thumbmode: thumbMode,
      })}
      data-testid="search-results"
    >
      <div className="right-panel-content">
        <section
          className={classNames({
            "results-container": true,
            "as-thumbnails": thumbMode,
          })}
        >
          <div className="listing-wrapper">
            <TCCResults />
            <LocalResults />
            <Organizations />
          </div>
        </section>
      </div>
    </div>
  );
});
