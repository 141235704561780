import { observable, runInAction, makeObservable } from "mobx";
import { IFile, IItem } from "../../models/dataModel";
import { RootStore, createStoreContext } from "../../stores/rootStore";
import { ImageUtils } from "../../utils/ImageUtils";
import { FileHandleConverter } from "../../utils/converters/FileHandleConverter";
import { NewRepository } from "../../js/services/NewRepository";

/**
 * Local thumbnail store.
 */
export class LocalThumbnailStore {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Content item.
   */
  @observable private content: IItem | undefined;

  /**
   * Constructor
   * @param rootStore RootStore instance
   */

  public constructor(rootStore: RootStore, content?: IItem) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (content) {
      runInAction(() => {
        this.content = content;
      });
    }
  }

  /**
   * Gets content item
   * @returns content item object
   */
  public getContent(): IItem | undefined {
    return this.content ? this.content : undefined;
  }

  /**
   * Gets content thumbnail
   * @returns content thumbnail
   */
  public getThumbnail(): { url: string; file?: IFile } {
    return this.content && this.content.thumbnail ? this.content.thumbnail : { url: ImageUtils.getDefaultThumbail() };
  }

  /**
   * Adds thumbnail for content
   */
  public async addThumbnail(file: File) {
    if (!this.content) return;

    try {
      if (ImageUtils.isTooBigImage(file)) {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("collections.notification.selected_image_is_too_big"));
      } else {
        const binary = FileHandleConverter.fromFilePickerToBinary(file);
        const content = await NewRepository.addThumbnail(this.content, binary);
        runInAction(() => {
          this.content = content;
        });
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("collections.notification.thumbnail_added"));
      }
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("collections.notification.thumbnail_update_failed"));
    }
  }

  /**
   * Deletes content thumbnail
   */
  public deleteThumbnail = async () => {
    if (!this.content) return;

    try {
      const content = await NewRepository.deleteThumbnail(this.content);
      runInAction(() => {
        this.content = content;
      });
      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("collections.notification.thumbnail_deleted"));
    } catch (err) {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("collections.notification.thumbnail_update_failed"));
    }
  };
}

export const LocalThumbnailStoreContext = createStoreContext<LocalThumbnailStore>(LocalThumbnailStore);
