import * as React from "react";
import { useContext, Fragment, useEffect } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { MyAlertsContext, MyAlertsStore } from "./myAlertsStore";

import { SearchPageOrderEnum } from "../../models/enums";

import { ListStyleToggle } from "../../components/search/ListStyleToggle";
import { SearchField } from "../../components/search/SearchField";
import { SortBy } from "../../components/search/SortBy";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import { AlertsWrapper } from "./AlertsWrapper";

const NotificationSettings = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: MyAlertsStore = useContext(MyAlertsContext);

  function handlePreferenceChange() {
    if (store.sendNotificationsToUser) store.saveNotificationPreferences(false);
    else store.saveNotificationPreferences(true);
  }

  return (
    <section className="email-notifications">
      <input
        id="emailNotifications"
        type="checkbox"
        data-testid="emailNotifications"
        onChange={handlePreferenceChange}
        checked={store.sendNotificationsToUser}
      />
      <label htmlFor="emailNotifications">{rootStore.getTranslation("alerts.alert_by_email")}</label>
    </section>
  );
});

const SortingOptions = observer(() => {
  const store: MyAlertsStore = useContext(MyAlertsContext);

  return (
    <Fragment>
      {store.hasSubscriptions() && (
        <div className="sorting-options alerts-sorting-options" data-testid="sortingOptions">
          <ListStyleToggle pageWhereUsed="alerts" />
          {store.hasSubscriptions(1) && (
            <Fragment>
              <SearchField pageWhereUsed="alerts" />
              <SortBy pageWhereUsed="alerts" />
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
});

export const MyAlerts = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: MyAlertsStore = new MyAlertsStore(rootStore);

  useEffect(() => {
    store.fetchData();
    rootStore.getSearchStore().resetSearchTerm();
    rootStore.getSearchStore().getSearchPageSettingsStore().setResultListOrder(SearchPageOrderEnum.LAST_UPDATED);
  }, []);

  return (
    <MyAlertsContext.Provider value={store}>
      <section className="alerts collection catalog" data-testid="alerts">
        <section className="result-options alerts-result-options">
          <div className="summary l-center">
            <NotificationSettings />
            <SortingOptions />
          </div>
        </section>
        <AlertsWrapper />
        <section className="alerts-info">
          <p>
            <TranslatedHtml entry="profile.alerts_info_html" />
          </p>
        </section>
      </section>
    </MyAlertsContext.Provider>
  );
});
