import * as React from "react";
import { Fragment, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { AdminStoreContext } from "./adminStore";
import { Dropdown } from "../../components/Dropdown";
import { IDropdownOption } from "../../models/dataModel";
import _ from "underscore";

const EditIsEnabled = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);
  const toggle = store.getSelectedFeatureToggle();

  function handleChange() {
    store.setToggleEnabled(toggle!, !toggle!.isEnabled);
  }

  return (
    <Fragment>
      <div className="edit-is-enabled">
        <input
          id="edit-is-enabled"
          type="checkbox"
          onChange={() => handleChange()}
          checked={toggle!.isEnabled}
          data-testid="edit-toggle-is-enabled"
        />
        <label htmlFor="edit-is-enabled">{rootStore.getTranslation("profile.admin.feature_toggle_is_enabled")}</label>
      </div>
    </Fragment>
  );
});

const EditPercentage = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);
  const toggle = store.getSelectedFeatureToggle();

  const bubblePosition: number = toggle!.percentage;
  const bubbleStyle = {
    left: `${bubblePosition}%`,
  };

  function handlePercentageChange(event) {
    store.setTogglePercentage(toggle!, event.target.value);
  }

  return (
    <Fragment>
      <div className="edit-percentage-title">{rootStore.getTranslation("profile.admin.feature_toggle_percentage")}</div>
      <div className="rzslider">
        <input
          type="range"
          id="toggle-percentage-slider"
          className="slider-line"
          data-testid="edit-toggle-percentage"
          min="1"
          max="100"
          value={toggle!.percentage}
          onChange={handlePercentageChange}
          step="1"
        />
        <output htmlFor="toggle-percentage-slider" style={bubbleStyle} className="slider-handle">
          <span>{toggle!.percentage}</span>
        </output>
      </div>
    </Fragment>
  );
});

const SaveButton = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);

  function save() {
    store.saveSelectedFeatureToggle();
  }

  return (
    <Fragment>
      <div className="save-button">
        <button className="l-right button-primary" onClick={save} data-testid="save-toggle">
          {rootStore.getTranslation("actions.save")}
        </button>
      </div>
    </Fragment>
  );
});

export const ManageFeatureToggles = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);
  const selectedToggle = store.getSelectedFeatureToggle();
  const [newToggleName, setNewToggleName] = useState("");

  useEffect(() => {
    init();
    async function init() {
      await store.initFeatureToggles();
    }
  }, []);

  function changeToggle(opt: IDropdownOption | null) {
    if (!opt) return;
    store.setSelectedFeatureToggle(opt.value);
  }

  function getSelectedOption() {
    if (selectedToggle) {
      return _.find(store.getFeatureToggleOptions, (option: IDropdownOption) => option.value == selectedToggle.name);
    }
  }

  function handleToggleNameChange(event) {
    setNewToggleName(event.target.value);
  }

  function addToggle() {
    store.createFeatureToggle(newToggleName);
  }

  const selectedOption = getSelectedOption();

  return (
    <div className="manage-feature-toggles" data-testid="manage-feature-toggles">
      <div className="admin-header">{rootStore.getTranslation("profile.admin.manage_feature_toggles")}</div>
      <div className="new-toggle">
        <div>{rootStore.getTranslation("profile.admin.add_new_toggle")}</div>
        <input
          data-testid="input-new-toggle-name"
          type="text"
          value={newToggleName}
          onChange={handleToggleNameChange}
        ></input>
        <button data-testid="save-new-toggle" className="button-primary" onClick={addToggle} disabled={!newToggleName}>
          {rootStore.getTranslation("actions.save")}
        </button>
      </div>
      <div className="edit-toggle">{rootStore.getTranslation("profile.admin.edit_existing_toggles")}</div>
      <div className="dropdown-container">
        <Dropdown
          options={store.getFeatureToggleOptions()}
          onChange={changeToggle}
          placeholder={rootStore.getTranslation("profile.admin.select_feature_toggle")}
          selectedValue={selectedOption}
          className="dropdown-wrapper gray"
          inputId="select-toggle"
        />
      </div>
      {selectedToggle && (
        <Fragment>
          <EditIsEnabled />
          <EditPercentage />
          <SaveButton />
        </Fragment>
      )}
    </div>
  );
});
