
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Es = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["es-ES"]) {
      window.locale["es-ES"] = {};
    }

    _.extend(window.locale["es-ES"], {
      myDetails: {
        my_roles: "Mis funciones",
        my_roles_link: "Funciones y derechos de acceso al contenido de Tekla Warehouse",
        my_roles_link_url: "https://support.tekla.com/es/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Funciones",
        roles: {
          member: "Editor Contenido Tekla Warehouse (Empleado)",
          employee: "Empleado",
          contentEditor: "Editor Contenido Tekla Warehouse (Externo)",
        },
      },
      immutability: {
        add_code_name_for_package: "Añadir un nombre en clave para el contenido",
        code_name: "Nombre en clave",
        immutable: "Inmutable",
        archive: "Archivar",
        archive_collection: "Archivar la colección y todo su contenido",
        archive_version: "Archivar la versión seleccionada",
        archive_package: "Archivar el paquete y todo su contenido",
        archive_dialog_text: "El contenido archivado no será visible ni se podrá buscar en Tekla Warehouse. Sin embargo, si el contenido se ha usado anteriormente con el servicio en la nube de Tekla Structures, dicho servicio puede seguir teniendo acceso al contenido.",
        archive_success: function (name: string) {
          return `${name} archivado correctamente`;
        },
        archive_failure: function (name: string) {
          return `Error al archivar ${name}`;
        },
        archived_content_redirect_message: "El contenido al que está intentando acceder se ha archivado",
        edit_title_move_or_archive: "Editar título, mover o archivar",
        edit_title_or_archive: "Editar título o archivar",
        major_update: "Actualización importante",
        major_update_description: "Cambios que no son compatibles con versiones anteriores, como añadir o eliminar características, u otros cambios importantes.",
        minor_update: "Actualización menor",
        minor_update_description: "Cambios compatibles con versiones anteriores, como nuevas funciones o mejoras, sin interrumpir la funcionalidad existente.",
        patch_update: "Actualización de parche",
        patch_update_description: "Correcciones compatibles con versiones anteriores, como parches de seguridad, correcciones de errores o mejoras de rendimiento, sin nuevas características.",
        info: "Su organización proporciona contenido para el servicio en la nube de Tekla Structures. Es necesario usar un nombre en clave y un número de versión para el contenido en el servicio en la nube de Tekla Structures para garantizar la compatibilidad y la coherencia.",
        immutable_files_info: "Para mantener la coherencia del contenido en el servicio en la nube, no puede quitar ni modificar archivos. Si es necesario realizar modificaciones, archive la versión actual y cargue una nueva.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Para obtener más información, consulte <a href="${readMoreUrl}" target="_blank">esta página</a>.`;
        },
        resolve_conflict: {
          title: "Resolver Conflicto",
          description:
            "Se han encontrado los siguientes elementos. Seleccione 'Conservar existente' para conservar el elemento existente encontrado en su instalación.",
          table_header_name: "Nombre",
          table_header_type: "Tipo",
          table_header_action: "Acción",
          action_keep_existing: "Conservar existente",
          action_replace: "Sustituir",
          action_done: "Terminado",
        },
        auth: {
          authentication_process_failed: "Error en el proceso de autenticación",
          access_denied: "Acceso denegado",
          handle_unauthorized: {
            unauthorized: "Sin autorización",
            please_log_in: "Inicie sesión o regístrese",
          },
        },
        translations: {
          edit_translations: "Editar traducciones",
          edit_translations_info: "Seleccione un idioma de la lista, modifique y guarde",
          edit_translations_default_language_info:
            "<b>Por defecto</b> se muestra cuando no está disponible el idioma seleccionado por el usuario",
          edit_content: "Información de contenido",
          edit_support: "Soporte",
          updated_successfully: "Las traducciones se han actualizado correctamente",
          error_on_update: "Error al actualizar las traducciones",
          default: "Por defecto",
          batch_translations: "Traducciones por lotes",
          export_import_info:
            "<b>Exporte</b> la plantilla de traducciones por lotes del contenido de su elección, edite la plantilla añadiendo las traducciones y vuelva a <b>importar</b> la plantilla para guardar varias traducciones a la vez.",
          import_translations: "Importar",
          export_translations: "Exportar",
          translations_missing: " (vacío)",
          select_content: "Seleccione las traducciones a exportar:",
          import_finished: "Importación finalizada.",
          select_file: "Seleccionar archivo",
          select_file_title: "Seleccione el archivo a importar:",
          import_from_file: "Importar",
          import_error_info: "El archivo no sigue el formato de la plantilla:",
          export_selected: "Exportar",
          invalid_file: "Formato de contenido de archivo no válido",
          import_translations_finished: "Traducciones importadas",
          details_too_long: "El contenido de Detalles excede la longitud máxima permitida",
          import_failed: "Error al importar las traducciones",
          translate_to_other_languages: "Traducir a otros idiomas",
          import_count: function (count) {
            return "Traducciones actualizadas para " + count + " contenido";
          },
          invalid_language: function (contentId, language) {
            return "El contenido '" + contentId + "' incluye el idioma no válido '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return (
              "El contenido '" + contentId + "' incluye el campo de traducción no válido '" + translationField + "'"
            );
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "El valor '" +
              translationField +
              "'  del campo de traducción del contenido '" +
              contentId +
              "' excede la longitud permitida máxima"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "Modelo 3D",
          "3d_pic_added": "Modelo 3D añadido correctamente",
          "3d_pic_updated": "Modelo 3D actualizado correctamente",
          "3d_pic_removed": "Modelo 3D eliminado correctamente",
          "3d_pic_update_failed": "Error al actualizar el modelo 3D",
          "3d_pic_remove_failed": "Error al eliminar el modelo 3D",
          "3d_pic_missing":
            "No hay ninguna imagen 3D para el contenido. Elija la imagen haciendo clic en 'Elegir modelo 3D'.",
          "3d_pic_uploading": "El modelo se está cargando. Espere...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Haga clic aquí para editar el contenido",
          updated_successfully: "Se ha actualizado correctamente",
          update_failed: "No se ha podido actualizar",
          edit_search_criteria: "Editar criterios de búsqueda",
          edit_group_and_category: "Editar grupo y categoría",
          edit_content: "Editar contenido",
          edit_thumbnail: "Editar miniatura",
          edit_thumbnail_descriptive: "Dimensiones recomendadas: 400 x 300 px",
          edit_description: "Editar descripción",
          edit_download_link: "Añadir o editar enlace de descarga de partner",
          versions_not_shown_to_users:
            "Dado que este elemento de contenido tiene un enlace de descarga de partner, los usuarios de Tekla Warehouse no pueden ver ninguna versión que haya añadido anteriormente al elemento de contenido. Los administradores y usuarios de Tekla Warehouse que tienen derechos de edición siguen pudiendo ver todas las versiones del elemento de contenido.",
          partner_download_link: "Introduzca la URL de descarga",
          partner_download_link_disclaimer:
            "Si añade el enlace de descarga de un partner a un elemento de contenido existente, los usuarios de Tekla Warehouse ya no podrán ver ninguna versión que haya añadido anteriormente al elemento de contenido. Esta acción no se puede deshacer.",
          edit_title_or_delete: "Editar título o borrar",
          edit_title_or_move_delete: "Editar título, mover o borrar",
          edit_support: "Editar información de soporte",
          edit_release_notes: "Editar novedades",
          edit_content_wysiwyg: "Editar detalles de contenido",
          edit_content_embed_link: "Más información en Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Solo se pueden embeber vídeos de YouTube, Vimeo o Alugha.",
          details_unallowed_embed_info: "Solo se puede embeber contenido de los siguientes dominios: YouTube, Vimeo o Alugha. Los formatos de URL permitidos son:",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "Enlace de vídeo no válido. Puede añadir vídeos por URL desde YouTube y Vimeo",
          edit_content_vide_code_error: "Formato embebido no válido. Solo se aceptan iframes. Los proveedores de video permitidos son YouTube, Vimeo y Alugha.",
          details_max_length_exceeded: "El contenido de Detalles es demasiado largo",
          editor_mode_label: "Modo editor:",
          editor_mode_on: "On",
          editor_mode_off: "Off",
          do_not_notify: "Actualización menor, no notificar a los suscriptores",
          invalid_tag: "Etiqueta no válida",
        },
        transfer_package: {
          header: "Mover contenido",
          actions: {
            move: "Mover",
            cancel: "Cancelar",
          },
          loading: "Cargando colecciones...",
          moving: "Moviendo contenido",
          select_collection: "Seleccionar colección",
          content_moved: "Se ha movido el contenido. Podrá ver los cambios en unos minutos.",
          operation_failed: "Error de la operación",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Contenido enlazado correctamente a " + name;
          },
          selected_resources_linked: function (name) {
            return "Contenido seleccionado enlazado correctamente a " + name;
          },
          resources_linked_with_errors: "Contenido enlazado, hay errores en parte del contenido",
          resources_linking_failed: "Error al enlazar el contenido",
          resource_unlinked: "Enlace eliminado correctamente. Podrá ver los cambios en unos minutos.",
          resources_unlinked:
            "Enlaces eliminados correctamente del contenido seleccionado. Podrá ver los cambios en unos minutos.",
          resources_unlinked_with_errors: "Enlaces eliminados, hay errores en parte del contenido",
          resources_unlinking_failed: "Error al eliminar los enlaces",
          linking: "Creando enlace",
          linking_failed: "Fallo de enlace",
          linking_failed_title: "No se ha podido enlazar el siguiente contenido:",
          unlinking_failed_title: "No se ha podido desenlazar el siguiente contenido:",
          linking_failed_already_contains:
            "El contenido ya se ha enlazado a la colección seleccionada. Las actualizaciones podrían tardar un poco en aparecer.",
          linking_failed_already_contains_short: "El contenido ya se ha enlazado a la colección seleccionada",
          unlinking_failed: "Error de eliminación de enlace",
          unlinking_failed_does_not_contain:
            "El enlace de contenido ya se ha eliminado de la colección. Las actualizaciones podrían tardar un poco en aparecer.",
          unlinking_failed_does_not_contain_short: "El enlace de contenido ya se ha eliminado de la colección",
        },
        notification_channel: {
          title_success: "correcto",
          title_notice: "aviso",
          title_info: "información",
          title_error: "error",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Tekla Maintenance es necesario para la descarga",
          can_access_maintenance: {
            yes: "Sí",
            no: "No",
          },
        },
        confirm: {
          title: "Confirmar",
          yes: "Sí",
          no: "No",
          ok: "Aceptar",
          close: "Cerrar",
          dont_show: "No volver a mostrar",
        },
        show_more: " Mostrar más",
        show_less: " Mostrar menos",
        spinner_loading: "Carga",
        spinner_processing: "Procesando",
        spinner_loading_long_time: "La carga de contenido puede tardar varios minutos",
        local_service: {
          info: "El complemento Tekla Warehouse Service es necesario para crear contenido local y en red. Con Tekla Structures 20.1 SR1 o posterior, puede cargar contenido directamente desde su modelo.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "El complemento Tekla Warehouse Service</a> " +
            "es necesario para crear contenido local y en red.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "El complemento Tekla Warehouse Service</a> " +
            "no está disponible. El servicio es necesario para habilitar las colecciones locales y en red.",
          download_to_unlock_all_features_html:
            "Descargue " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "El complemento Tekla Warehouse Service</a> " +
            "para desbloquear todas las funciones.",
          service_out_of_date_html:
            "Su complemento Tekla Warehouse no está actualizado. Actualizar " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "El complemento Tekla Warehouse Service</a> " +
            "para desbloquear todas las funciones.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Seleccionar idioma",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Invitar a otros usuarios",
          send_invitation_to_email: "Enviar invitación por correo electrónico",
          add_e_mail_addresses: "Añadir direcciones de correo electrónico",
          and_assign_role: "y asignar una función",
          send_invitation: "Enviar invitación",
          invitation_already_sent: "Ya se ha enviado la invitación",
          invitation_sent: "Se ha enviado la invitación.",
          update_selected_invitations: "Actualizar invitaciones seleccionadas",
          new_role: "Nueva función",
          invitations_list_updated: "Se ha actualizado la lista de invitaciones",
        },
        item_browser_title: "Seleccionar elementos de contenido",
        prerequisites: {
          description_placeholder: "Añada la descripción aquí",
          link_name_placeholder: "Añada el nombre del enlace aquí",
          add_prerequisites_and_recommendations: "Requisitos previos y recomendaciones (opcional)",
          list_the_profiles_components_files:
            "Enumere los perfiles, componentes, archivos o modelos necesarios para que el contenido funcione correctamente.",
          link_to_existing_content: "Enlace a contenido existente",
          please_enter_prerequisite_url: "Introduzca URL como requisito previo",
          please_enter_valid_prerequisite_url: "Introduzca una URL válida como requisito previo",
          missing_http: "Falta http://",
          add_link: "Añadir enlace",
        },
        treeview: {
          cannot_export_this_container: "No se puede exportar este contenedor. Seleccione elementos individuales.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "El archivo debe ocupar menos de " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Tipo de archivo no válido.  El tipo de archivo debe ser uno de los siguientes " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Periodo de tiempo:",
          timeframe_start: "Inicial:",
          timeframe_end: "Final:",
          eventtype: "Tipo de evento:",

          downloads_daily_by_version: "Descargas por versión",
          downloads_by_country: "Descargas por país",
          downloads_by_version: "Descargas por versión",
          downloads_by_version_organization: "Descargas por versión y organización",
          downloads_by_organization: "Descargas por organización",
          downloads_daily_by_package: "Descargas por elemento de contenido",
          views_daily_by_package: "Visualizaciones por elemento de contenido",
          views_by_country: "Visualizaciones por país",
          views_by_organization: "Visualizaciones por organización",

          downloads_by_package: "Descargas por elemento de contenido",
          views_by_package: "Visualizaciones por elemento de contenido",
          downloads_daily_by_collection: "Descargas por colección",
          downloads_by_collection: "Descargas por colección",
          views_daily_by_collection: "Visualizaciones por colección",
          views_by_collection: "Visualizaciones por colección",

          events_by_organization_func: function (version) {
            return 'Versión "' + version + '" eventos por organización'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Otros",
          sum_all_versions: "Todas las versiones",
          sum_all_packages: "Todos los elementos de contenido",
          sum_all_collections: "Todas las colecciones",
          sum_all_organizations: "Todas las organizaciones",
          recording_started_at: "Grabación de datos iniciada en junio de 2015",
          recording_view_events_started_at: "Grabación de datos iniciada en marzo de 2017",
          version_title_func: function (title) {
            return 'Versión "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " descargas", count + " descarga", count + " descargas"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Los números incluyen todas las descargas completadas.",
          counted_events_info_package: "Los números incluyen todas las descargas completadas.",
          export: {
            caption: "Exportar análisis",
            select_options: "Seleccionar opciones",
            select_timeframe_description: "Seleccione a continuación un periodo de tiempo para exportar el análisis:",
            select_eventtype_description: "Seleccione el tipo de evento del que desee exportar el análisis:",
          },
        },
        access_rights: {
          editors: "Editores",
          download_access: "Acceso visualizaciones y descargas",
          write_user_id_placeholder: "Escribir ID usuario",
          write_organization_id: "Introduzca ID de organización válidos. Introduzca cada ID en una fila independiente.",
          write_id_placeholder:
            "Introduzca los ID de usuario, las direcciones de correo electrónico de los usuarios o los ID de la organización. Introduzca cada ID o dirección de correo electrónico en una fila diferente.",
          id_help_html_short:
            "Por motivos de seguridad, no se puede buscar a nuestros usuarios.<br/><br/>" +
            "Solicite el ID de usuario directamente a la persona a la que desee conceder el acceso. <br/>" +
            'El ID de usuario se muestra en <a href="#/my/">Mis detalles</a>.',
          id_help_html:
            "Por motivos de seguridad, no se puede buscar a nuestros usuarios.<br/><br/>" +
            "Solicite el ID de usuario directamente a la persona a la que desee conceder el acceso. Para conceder acceso a todos los usuarios en una organización, utilice el ID de la organización.<br/><br/>" +
            'Los ID de usuario y de organización se muestran en <a href="#/my/">Mis detalles</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Introduzca cada ID o dirección de correo electrónico en una fila diferente. Puede introducir " +
              max_rows +
              " filas a la vez."
            );
          },
          input_too_long: "El texto que ha introducido es demasiado largo.",
          viewer_already_added: function (input) {
            return `${input} ya se ha añadido como visualizador`;
          },
          wrong_format: function (input) {
            return `${input} no es una dirección de correo electrónico ni un ID válidos`;
          },
          candidates_for_keyword: function (keyword) {
            return "Usuarios encontrados con la dirección de correo electrónico o ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Añadir nuevo",
        add: "Añadir",
        delete: "Borrar",
        set_as_default: "Vista en miniatura por defecto",
        select_file: "Seleccionar archivo",
        choose_option: "Elegir opción",
        info_3D_exists: "Ya ha añadido un modelo 3D, no se puede añadir más de un modelo al contenido.",
        action_options: {
          image: "Imagen",
          "3DModel": "Modelo 3D",
          video: "Vídeo",
        },
        video: {
          url: "URL de vídeo",
          invalid_url: "URL no válida",
        },
      },
      units: {
        bytes: "bytes",
        kB: "KB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Descargar",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " me gusta", number + " me gusta", number + " me gusta"]);
      },
      main_info: {
        header_title: "El sitio web de Tekla Warehouse tiene un nuevo aspecto!",
        header_text: "Le gusta el nuevo sitio web de Tekla Warehouse optimizado? ",
        header_text2: "Los cambios principales son:",
        bullet_text_1: "Búsqueda clara en la página de inicio.",
        bullet_text_2:
          "Todos los filtros de búsqueda de la página Búsqueda se encuentran a la izquierda y la vista de miniaturas por defecto tiene más resultados de búsqueda en la misma vista.",
        bullet_text_3:
          "La opción Descargar de la página Contenido tiene una ubicación central, por lo que resulta sencillo empezar a usar contenido. Ahora se puede acceder a las opciones Descargar e Instalar con el botón Descargar.",
        bullet_text_4:
          "La página Colección y Mi carrito tienen instrucciones de tres pasos para usar la descarga e instalarlo todo.",
      },
      organizations: {
        error_loading_collection_contents: "Error al cargar el contenido de la colección",
        error_loading_organization: "Error al cargar la organización",
      },
      downloads_graph: {
        date: "Fecha",
        total_views: "Total Visualizaciones",
        total_downloads: "Total Descargas",
        views: "Visualizaciones",
        downloads: "Descargas",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "El complemento Tekla Warehouse Service " +
          "</a>" +
          " es necesario.",
        processingFailed: "Error en el procesamiento",
        forbidden: "Prohibido",
        resource_not_found: "Recurso no encontrado",
        no_matches_found: "No se han encontrado coincidencias",
        no_matches_found_for: function (input) {
          return "No se encontraron coincidencias con la dirección de correo electrónico o ID " + input;
        },
        not_identified: "No identificado",
        error_while_searching: "Error en la búsqueda",
        error_user_not_found: "Usuario u organización no encontrado",
        error_while_adding_member: "Error al añadir miembro con derecho de acceso",
        error_while_removing_member: "Error al eliminar miembro con derecho de acceso",
      },
      offline_to_online: {
        fail_on_initialize: "Error al gestionar el contenido comprimido, se anulará la conversión",
        fail_on_validate: "Error al validar los metadatos del contenido, se anulará la conversión",
        fail_on_save: "Error al guardar contenido; se desharán los cambios",
        success: "El contenido sin conexión se ha convertido correctamente a contenido en línea",
        validate_errors_title: "Error al convertir contenido",
        processing: "Guardando paquete ",
        processed: "Elemento procesado",
        collection_thumbnail_missing_name: "En el binario de vistas en miniatura de la colección falta 'filename'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "En el binario de miniaturas del paquete '" + packageTitle + "' falta 'filename'";
        },
        package_value_missing_title_func: function () {
          return "En el paquete falta 'title'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "En el paquete '" + packageTitle + "' falta '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "El campo '" +
            field +
            "' del paquete '" +
            packageTitle +
            "' tiene los siguientes valores no válidos: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "El paquete '" + packageTitle + "' tiene una etiqueta no válida: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "En la versión del paquete '" + packageTitle + "' falta 'title'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "En la versión '" + versionTitle + "' del paquete '" + packageTitle + "' falta '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "El campo '" +
            field +
            "' de la versión '" +
            versionTitle +
            "' del paquete '" +
            packageTitle +
            "' tiene los siguientes valores no válidos: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return (
            "En el binario de la versión '" + versionTitle + "' del paquete '" + packageTitle + "' falta 'filename'"
          );
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Importante: Fin de vida para colecciones locales en Tekla Warehouse",
            part1: "A partir del 2 de junio de 2025, las colecciones locales ya no estarán disponibles en Tekla Warehouse. Los usuarios ya no podrán crear, ver ni buscar colecciones locales ni utilizar contenido de colecciones locales.",
            part2: "Para obtener ayuda con la transición de colecciones locales a colecciones en línea, póngase en contacto con su organización de soporte local.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `¡Importante! Como mejora de la seguridad, a partir del ${date} de noviembre de 2024 debe tener la función Editor Contenido de Tekla Warehouse para administrar el contenido de su organización. <br />Pida al administrador de su organización que le asigne la función. Para obtener más información, consulte el <a href="${productBulletinUrl}" target="_blank">boletín del producto</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Como mejora de la seguridad, a partir del ${date} de noviembre de 2024 debe tener la función Editor Contenido de Tekla Warehouse para administrar el contenido de su organización. <br />Pida al administrador de su organización que le asigne la función. Para obtener más información, consulte el <a href="${productBulletinUrl}" target="_blank">boletín del producto</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `¿No ve una opción de edición para su organización? A partir del ${date} de noviembre, debe tener la función Editor Contenido de Tekla Warehouse para administrar el contenido de su organización. <br />Puede comprobar cuál es su función en Mi Warehouse > Mis detalles. Para obtener más información, consulte el <a href="${productBulletinUrl}" target="_blank">boletín del producto</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `¿No ve una opción para crear contenido para su organización? A partir del ${date} de noviembre, debe tener la función Editor Contenido de Tekla Warehouse para administrar el contenido de su organización. <br />Puede comprobar cuál es su función en Mi Warehouse > Mis detalles. Para obtener más información, consulte el <a href="${productBulletinUrl}" target="_blank">boletín del producto</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `A partir del ${date} de noviembre, debe tener la función Editor Contenido de Tekla Warehouse para administrar el contenido de su organización. Pida al administrador de su organización que le asigne la función. <br />Puede comprobar quién es el administrador de su organización en su Perfil de Tekla Online. Para obtener más información, consulte el <a href="${productBulletinUrl}" target="_blank">boletín del producto</a>.`
            },
          },
          service_plugin_update_info:
            '¿Tienes problemas para conectarte a Tekla Structures? Asegúrate de estar utilizando el último <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="blank" rel="noopener noreferrer">plugin de Tekla Warehouse Service</a>.',
        },
        maintenance_info:
          "Tekla Warehouse está en mantenimiento durante 2017-10-27 22:00:00 - 23:00:00 UTC. Sentimos las molestias.",
        release_notes_info: "Características nuevas en Tekla Warehouse",
        release_notes_link:
          'Se ha actualizado Tekla Warehouse, consulte las <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">novedades</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "¿Necesita un desarrollador de Tekla para su proyecto?",
        heading2Dev:
          'Consulte el nuevo directorio llamado Tekla Development Experts.<br><br>Es un lugar donde pueden reunirse los programadores con talento y los usuarios de Tekla interesados.<br><br>Todos los miembros de Tekla Partners Program pueden añadir su información al directorio Experts.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Buscar un desarrollador &rsaquo;</a>',
        static_intro:
          "Es el almacenamiento de Tekla Structures gratuito donde puede buscar, importar, instalar y compartir contenido tanto interna como externamente. El objetivo de Tekla Warehouse es facilitar la creación de modelos de calidad de un modo eficaz.",
        new_intro:
          'Tekla Corporation ahora se denomina Trimble. Seguimos desarrollando las soluciones de software Tekla y el mismo equipo humano está a su servicio. Más información: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Verifique su cuenta",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Cargas más recientes &rsaquo;",
          popular: "Contenido popular &rsaquo;",
          recommended: "Recomendado &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Novedades &rsaquo;</a>',
          item_title: "Son las novedades.",
          item_description:
            'Texto relacionado con novedades, texto relacionado con novedades, texto relacionado con novedades, texto relacionado con novedades, texto relacionado con novedades.<br><a href="https://developer.tekla.com" target="_blank">Leer más &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse en números",
          partners: "Partners",
          partners_count: "352",
          collections: "Colecciones",
          collections_count: "793",
          items: "Elementos de contenido",
          items_count: "2893",
        },
        partners: "Nuestros partners",
        action_links: {
          search_title: "¿Buscar contenido?",
          upload_title: "¿Cargar contenido?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Vídeos&rsaquo;</a>',
          link: 'Visite nuestro <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">canal de Youtube</a> para ver más vídeos.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Busque en su correo electrónico el enlace de verificación o <u><a href="' +
            settingsUrl +
            '">solicite un nuevo enlace</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Verifique su dirección de correo electrónico y acepte los términos y condiciones y la política de privacidad",
        acceptTerms: "Acepte los términos y condiciones y la política de privacidad",
        acceptUpdatedTerms:
          "Apruebe los términos y condiciones y la política de privacidad de tekla online services actualizados",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Vuelva a su <u><a href="' + settingsUrl + '">perfil</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Lea y acepte los Términos y condiciones y la Política de privacidad en su <u><a href="' +
            settingsUrl +
            '">perfil</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Examinar colecciones &rsaquo;",
          new: "Nuevo",
          popular: "Popular",
          recommended: "Recomendado",
          organizations: "Organizaciones",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Seleccionar carpeta",
        selectFolderInstructions: "Se pueden utilizar carpetas locales y en red como Colecciones.",
        selectedFolder: "Carpeta seleccionada (copie y pegue la ubicación):",
        selectedFolderPlaceholder: "Por ej.: C:\\carpeta, Z:\\carpeta red, \\\\nombre-pc\\carpeta",
        collectionCreation: {
          name: "Nombre",
          createNewCollection: "Crear colección local",
          pleaseInputTitle: "Introduzca un título",
          invalidPath: "Ruta no válida",
          access_denied: "Error al añadir colección: acceso denegado",
          unsafe_location: "Error al añadir colección: ubicación no segura",
          add_collection_failed: "Error al añadir colección",
          minLengthError: "El título debe contener como mínimo 3 caracteres",
          maxLengthError: "El título no puede tener más de 20 caracteres",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Se pueden utilizar carpetas locales y en red como Colecciones.",
        collectionCreation: {
          name: "Nombre",
          createNewCollection: "Crear colección en línea",
          pleaseInputTitle: "Introduzca un título",
          minLengthError: "El título debe contener como mínimo 3 caracteres",
          maxLengthError: "El título no puede tener más de 20 caracteres",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Se ha guardado el recurso",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">¿Ve el potencial?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Envíenos sus comentarios</span>',
          help_us_improve_html:
            'Ayúdenos a mejorar Tekla Warehouse. <br/><a id="showSurvey">Haga clic aquí</a> o <a id="dontShow">no volver a mostrar</a>',
          dialog: {
            do_you_see_potential: "¿Ve el potencial?",
            help_us_improve_html: "Ayúdenos a mejorar Tekla Warehouse. Su opinión es importante para nosotros.",
            continue_to_survey: "Empezar encuesta",
            exit_to_warehouse: "No, gracias",
          },
        },
      },
      search: {
        bar: {
          search: "Buscar",
          filter: " ",
          more_filters: "Más filtros",
          hide_filters: "Ocultar filtros",
          clear: "Borrar",
          exclude: "Excluir",
          include: "Incluir",
          clear_filters: "Restablecer filtros",
          load_defaults: "Usar mis preferencias &rsaquo;",
          save_defaults: "Guardar como mis preferencias &rsaquo;",
          defaults_saved_successfully: "Filtros por defecto guardados",
          edit_defaults: "Editar valores por defecto",
        },
        info: {
          searching_organizations: "Buscando en organizaciones...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Sin resultados", amountTotal + " resultados"]);
            } else {
              return (
                pluralize(amountTotal, ["Sin resultados", amountTotal + " resultados"]) +
                ' para "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " resultados";
            } else if (!searchTerm) {
              return "Introduzca un término de búsqueda para ver los resultados en organizaciones";
            } else if (searchTerm.length < 2) {
              return "Escriba al menos dos caracteres para la búsqueda en organizaciones";
            } else {
              return (
                pluralize(amountTotal, ["Sin resultados", amountTotal + " resultados"]) +
                ' para "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " resultados en contenido en línea",
          local: " resultados en contenido local",
          organization: "resultados en organizaciones",
          plugin_missing_info:
            'El contenido local solo se muestra si tiene el <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "El complemento Tekla Warehouse Service " +
            "</a>" +
            " instalado.",
          version_filter_info: "El filtrado por versión de software no es válido en los resultados de contenido local",
        },
        exclude_env_files: "Excluir archivos de entorno",
        sidebar: {
          filters: {
            measurementUnits: "Unidades de medida",
            locations: "Ubicaciones de proyectos",
            languages: "Idiomas",
          },
          placeholders: {
            use_categories: "Categoría",
            measurement_units: "Unidades de medida",
            languages: "Idiomas",
            locations: "Ubicaciones",
          },
        },
      },
      placeholders: {
        name: "Nombre...",
        contentOwner: "Nombre del propietario del contenido...",
        useCategory: "Seleccionar uso",
        selectType: "Seleccionar tipo",
        filter: "Filtro...",
        search: "Buscar...",
        choose: "Elegir...",
        searchFilters: "Filtros de búsqueda...",
        selectContentItem: "Seleccionar elemento de contenido...",
        search_content: "Buscar contenido...",
        includeAs: "Incluir como...",
        selectCollection: "Seleccionar colección",
        selectUserGroup: "Seleccionar grupo de usuarios",
        online_collections_tooltip: "Haga clic para buscar contenido en línea",
        local_collections_tooltip:
          "Haga clic para buscar contenido local y en red y contenido incluido con la instalación de Tekla Structures",
        organizations_tooltip: "Haga clic para buscar organizaciones",
        "filter-options": {
          products: "Todos los productos de software",
          locations: "Todas las ubicaciones",
          languages: "Todos los idiomas",
          measurementUnits: "Todas las unidades de medida",
          testedVersions: "Todas las versiones de software",
          useCategories: "Todas las categorías",
          useCategoriesCombined: "Categoría",
          allGroups: "Todos los grupos",
          itemTypeCategories: "Todos los tipos de elemento",
        },
        "exclude-filter-options": {
          products: "Excluir productos de software",
          locations: "Excluir ubicaciones",
          languages: "Excluir idiomas",
          measurementUnits: "Excluir unidades de medida",
          testedVersions: "Excluir versiones de software",
          useCategories: "Excluir categorías",
          useCategoriesCombined: "Excluir categoría",
          allGroups: "Excluir grupos",
          itemTypeCategories: "Excluir tipos de elemento",
        },
      },
      link: {
        link_selected: "Enlazar seleccionado",
        unlink_selected: "Desenlazar seleccionado",
      },
      download_install: {
        choose_version: "Elija su versión de software:",
        choose_content: "Elija los elementos de contenido en la siguiente lista:",
        choose_action: "Elija la acción para los elementos seleccionados:",
      },
      download: {
        copy_to_clipboard: "Copiar en portapapeles",
        download_selected: "Descargar",
        add_selected_items_to_basket: "Añadir seleccionado a Mi carrito",
        add_item_to_basket: "Añadir seleccionado a Mi carrito",
        add_all_items_to_basket: "Añadir todo al carrito",
        add_all_items_to_basket_info: "Hay más de 100 elementos. La carga puede tardar algún tiempo",
        add_all_items_to_basket_too_many_results: "No se pueden añadir más de 1000 elementos",
        cart_loading: "Hay más de 100 elementos. La carga puede tardar algún tiempo",
        remove_item_from_basket: "Eliminar del carrito",
        shopping_cart_no_content: "No hay contenido en el carrito",
        shopping_cart_no_content_for_version: "No hay contenido en el carrito para la versión seleccionada",
        shopping_cart_items_func: function (itemsCount) {
          return "Mi carrito (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Instrucciones de descarga",
          step_one: "Copie la información de descarga en el portapapeles con este botón",
          step_two_func: function (downloadAppUrl) {
            return (
              "Ejecute la aplicación Tekla Warehouse Downloader. Puede descargar la aplicación " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">aquí</a>. '
            );
          },
          step_three: "La aplicación Tekla Warehouse Downloader le guiará en el resto de la descarga.",
          show_data_title: "¿No funcionó?",
          show_data_to_be_copied: "Copiar manualmente",
          hide_data_to_be_copied: "Ocultar datos de descarga",
        },
      },
      install: {
        install_title: " los elementos se instalarán usando el complemento de Tekla Warehouse.",
        failed_install_information:
          "Ha seleccionado contenido que está disponible solo para descarga. Este contenido no se instalará ahora. Descargue este contenido por separado desde Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Instalación: compruebe que su complemento Tekla Warehouse Service se está ejecutando correctamente",
        verify_ts_connection: "Instalación: compruebe que Tekla Structures se está ejecutando",
        select_software_version: "Filtre por versión de software para enlazar, descargar o instalar",
        download_requires_maintenance: "El acceso a algunos de los siguientes elementos requiere Tekla Maintenance",
        install_selected: "Instalar",
        start_install: "Iniciar instalación",
        install_started: "Se ha iniciado la instalación",
        select_ts_version: "Seleccionar versión de TS",
        install_retry: "Vuelva a iniciar desde Tekla Warehouse si es necesario intentarlo otra vez",
        failed_installs_info_func: function (failedCount) {
          return "Contenido que no se instalará (" + failedCount + " versiones):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Instalación para " + totalInstallsCount + " elementos seleccionados";
        },
      },
      helpers: {
        behalf: "Por",
        sortBy: "Ordenar por:",
        filter: "Filtrar resultados",
        showAsThumbs: "Mostrar vistas en miniatura",
        showAsList: "Mostrar lista",
        resultsPerPage: "Mostrar por página:",
        filter_by_version: "Filtrar por versión:",
        show_more_options: "Más opciones",
        show_less_options: "Menos opciones",
        filter_by_type: "Filtrar por tipo:",
        select_all: "Seleccionar todo",
        show_content_items: "Mostrar elementos de contenido",
        choose_action: "Elegir acción para el contenido seleccionado: ",
        show_collections: "Mostrar colecciones",
        search: {
          show: "Mostrar:",
          show_content_items: "Elementos de contenido",
          show_collections: "Colecciones",
          show_organizations: "Organizaciones",
          show_online_content: "Contenido en línea",
          show_offline_content: "Contenido sin conexión",
          local_content_info:
            'El contenido local solo se muestra si tiene el <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "complemento Tekla Warehouse Service" +
            "</a></b>" +
            " instalado.",
        },
        tekla_maintenance_required: "El acceso a algunos de los siguientes elementos requiere Tekla Maintenance",
        show_content_alerts: "Mostrar alertas de contenido ",
        show_collection_alerts: "Mostrar alertas de colección ",
      },
      actions: {
        load_more: "Cargar más",
        use_infitinite_scroll: "Utilizar desplazamiento infinito",
        back: "Atrás",
        show_download_dialog: "Descargar",
        download: "Solo descargar",
        download_on_partner_site: "Descárguelo en el sitio del partner",
        insert: "Instalar",
        install: "Instalar",
        downloadAndInstall: "Instalar",
        createNew: "Crear nuevo",
        viewItems: "Vista",
        viewCollection: "Ver colección",
        viewGroup: "Mostrar grupo",
        joinGroup: "Unirse al grupo",
        loginToDownload: "Inicie sesión para descargar",
        show3DPic: "Mostrar modelo 3D",
        remove: "Eliminar",
        browse: "Examinar",
        browseFolder: "Examinar",
        browseFileSystem: "Examinar",
        add: "Añadir",
        save: "Grabar",
        batch_edit: "Edición por lotes",
        translations: "Traducciones",
        cancel: "Cancelar",
        continue: "Continuar",
        delete: "Borrar",
        addNetworkPath: "Añadir ubicación de red",
        select: "Seleccionar",
        create: "Crear",
        update: "Actualizar",
        choose_thumbnail: "Escoger vista en miniatura",
        choose_picture: "Elegir imagen",
        remove_picture: "Borrar imagen",
        choose_3D_picture: "Elegir modelo 3D",
        remove_3D_picture: "Eliminar modelo 3D",
        like: "Me gusta",
        unlike: "No me gusta",
        close: "Cerrar",
        export: "Exportar",
        add_or_remove: "Añadir/eliminar",
        select_content_to_convert: "Seleccionar contenido sin conexión",
        search: "Buscar",
        upload: "Cargar",
        edit: "Editar",
        view_3d: "Haga clic para ver el modelo...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "beta",
        search: "Buscar",
        profile: "Mis detalles",
        myWarehouse: "Mi Warehouse",
        myStuff: "Mi material",
        logout: "Cerrar sesión",
        login: "Iniciar sesión",
        register: "Registrarse",
        upload: "Cargar",
        uploading: "Cargar",
        dashboard: "Panel",
        learnMore: "Más información",
        about: "Acerca de",
        shoppingCart: "Mi carrito",
        addToCartInfo:
          "Puede añadir elementos a Mi carrito y descargarlos o instalarlos todos a la vez. La descarga es completamente gratuita",
      },
      cubeNavigation: {
        headings: {
          get_started: "INTRODUCCIÓN",
          learn: "APRENDER",
          get_help: "OBTENER AYUDA",
          extend_your_tekla: "AMPLIAR TEKLA",
          collaborate: "COLABORAR",
          students_and_educators: "Students and educators",
          about: "ACERCA DE",
        },
        "tekla-online": "Servicio en línea",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Sitio web de Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Mi perfil",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "INICIO DE SERVICIOS DE TEKLA",
        register: "Registrarse",
        login: "Iniciar sesión",
        evaluate: "Evaluar",
        buy: "Comprar",
        download: "Descargar",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Productos",
        teklaServices: "Servicios",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Mis colecciones",
        myLikes: "Me gusta",
        profile: "Mis detalles",
        preferences: "Mis preferencias",
        myUserGroups: "Mis grupos de usuarios",
        localCollections: "Colecciones locales y en red",
        tsCollections: "Colecciones de Tekla Structures",
        downloads: "Descargas",
        selectContent: "Seleccionar archivos de contenido",
        addMetadata: "Añadir metadatos",
        onlineCollections: "Colecciones en línea",
        userGroups: "Grupos de usuarios",
        createNew: "Añadir contenido",
        notifications: "Notificaciones",
        contentUsage: "Uso del contenido",
        browseCollections: "Examinar colecciones",
        admin: "Administración",
        sales: "Ventas",
        shoppingCart: "Mi carrito",
      },
      notifications: {
        title: "notificación",
        invitedBy: "Invitado por",
        invitationType: "Tipo de invitación",
        invitedTo: "Invitado a",
        createdAt: "Creada en",
        action: "Acción",
        acceptInvitation: "Aceptar",
      },
      batch_edit: {
        selectActionType: "Seleccionar tipo de acción",
        edit_content: "Editar metadatos de contenido",
        content_title: "Contenido",
        metadataToChange: "Metadatos para cambiar",
        selectContent: "Para el contenido",
        select_action: "Seleccionar acción",
        support: {
          support: "Soporte",
          licenses: "Licencias",
        },
        versions: {
          version: "Versión",
          prerequisities: "Requisitos previos y requisitos",
        },
        content: {
          select_collection: "Seleccionar colección",
          show_collection: "Mostrar:",
          show_all_content: "Todas las colecciones",
          show_organization_content: "Colecciones de mi organización",
          select_all: "Seleccionar todo",
          select_all_info: "(Puede tardar un poco).",
          actionOptions: {
            add: "Añadir",
            remove: "Eliminar",
            replace: "Sustituir por",
            yes: "Sí",
            no: "no",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "No hay atributos seleccionados",
            invalid_attributes: "Valores de atributo no válidos",
            invalid_actions: "Acciones no válidas",
            content_not_selected: "No hay contenido seleccionado",
            entities_not_selected:
              "Se han proporcionado datos relacionados con el paquete, pero no hay paquetes seleccionados",
            versions_not_selected:
              "Se han proporcionado datos relacionados con la versión, pero no hay versiones seleccionadas",
          },
        },
        update: {
          info_processing: "Mantenga abierta esta ventana hasta que se hayan procesados todos los elementos.",
          info_processed: "Se han procesado todos los elementos.",
          processing: "Procesando elemento",
          error_info: "Ha habido un problema al actualizar los siguientes elementos:",
          package_failure_func: function (package_title, errorMsg) {
            return "Contenido: " + package_title + ", error: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Contenido: " + package_title + ", versión: " + version_title + ", error: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " elementos se actualizarán, ¿desea continuar?";
          },
        },
      },
      myUserGroups: {
        title: "Grupos de usuarios",
        createdByPrefix: "Por:",
      },
      downloads: {
        title: "Descargas",
      },
      myCollections: {
        new_collection: "Nueva colección",
        my_collections: "Mis colecciones",
        created_by_me: "Creado por mí",
        local_collections: "Colecciones locales y en red",
        online_collections: "Colecciones en línea",
        created_by_my_organization: "Creado por mi organización",
        created_by_my_organizations: "Creado por mis organizaciones",
        shared_with_me: "Compartido conmigo",
        shared_with_me_or_organization: "Compartido conmigo o con mi organización",
        notification: {
          error: "Error",
          error_loading_online_collections: "Error al cargar las colecciones en línea",
          error_loading_packages_liked_by_me: "Error al cargar contenido de Me gusta",
          error_loading_my_organization_s_collections: "Error al cargar las colecciones de mi organización",
          error_loading_my_shared_collections: "Error al cargar mis colecciones compartidas",
        },
        local: {
          helpText1: "Para poder acceder a las colecciones locales, instale el ",
          helpText2: "complemento Tekla Warehouse Service",
          helpText3: ".",
          title: "Colecciones locales y en red",
          notFound: "Aún no se han encontrado colecciones locales.",
          myCollectionsTitle: "Mis colecciones",
          actions: {
            createNew: "Crear colección local o en red",
          },
        },
      },
      organization: {
        news_feed: "Información de fuente",
        add_comment: "Añadir texto de fuente",
        edit_comment: "Editar elemento de información",
        subTabs: {
          collections: "Colecciones",
          analytics: "Análisis",
          contact_and_support: "Contacto",
          news_feed: "Información de fuente",
        },
        edit: {
          edit_title: "Editar título",
          edit_description: "Editar descripción",
          edit_contact: "Editar información de contacto",
          edit_logo: "Editar logotipo",
          edit_url: "Editar dirección URL",
          edit_email: "Editar correo electrónico",
          edit_phonenumber: "Editar número de teléfono",
          edit_warehouse_url: "Editar dirección URL de Warehouse",
          edit_warehouse_url_subtitle:
            "Caracteres alfabéticos, no caracteres escandinavos, números o caracteres especiales",
          edit_url_subtitle: "Insertar dirección URL completa",
          edit_logo_descriptive: "Tamaño óptimo de imagen aproximadamente 350 × 200 píxeles",
          edit_facebook_url: "Editar URL Facebook",
          edit_twitter_url: "Editar URL Twitter",
          edit_linkedin_url: "Editar URL LinkedIn",
          edit_youtube_url: "Editar URL YouTube",
          edit_googleplus_url: "Editar URL Google+",
          logo_updated: "Logotipo actualizado",
          logo_update_failed: "Error al actualizar el logotipo",
          logo_remove_failed: "Error al eliminar el logotipo",
          logo_removed: "Logotipo eliminado correctamente",
          updated_successfully: "Se ha actualizado correctamente",
          updated_failed: "No se ha podido actualizar",
          add_comment: "Añadir nuevo",
          edit_comment: "Editar elemento de información",
          comment_added: "Elemento de información de fuente añadido correctamente",
          comment_updated: "Elemento de información de fuente actualizado correctamente",
          comment_deleted: "Elemento de información de fuente eliminado correctamente",
          comment_add_failed: "Error al añadir la información de fuente",
          comment_update_failed: "Error al actualizar el elemento de información de fuente",
          comment_delete_failed: "Error al borrar el elemento de información de fuente",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Buscar contenido en línea por " + name + " &rsaquo;";
        },
        contact_information: "Contacto",
        website: "Sitio web &rsaquo;",
        show_newer_news: "Más reciente",
        show_older_news: "Más antiguo",
      },
      online_collections: {
        new_collection: "Nueva colección",
        online_collections: "Colecciones en línea",
        created_by_organizations: "Creado por organizaciones",
        created_by_users: "Creado por usuarios",
      },
      local_collections: {
        tab_title: "Contenido",
        local_collections: "Colecciones locales",
      },
      versions: {
        add_tool: "Añadir aplicación",
        table_header: {
          name: "Nombre",
          type: "Tipo",
          platform: "Plataforma",
          modified_at: "Modificado a las",
          size: "Tamaño",
          virus_scan: "Análisis antivirus",
        },
        // placeholders
        select_file_type: "Seleccionar tipo de archivo",
        select_platform: "Seleccionar plataforma",
        select_unit: "Seleccionar unidad",
        select_quality: "Seleccionar calidad",
        what_will_be_added: "¿Qué desea añadir?",
        select_files_from_your_disk: "Seleccionar archivos de su disco",
        add: "Añadir",
        //content management
        add_application: "Añadir aplicación",
        select_content_from_tekla_structures: "Seleccionar contenido de Tekla Structures",
        or: "o",
        files: "Archivos",
        //version metadata
        version_information: "Información de versión",
        version_display_name: "Nombre mostrado versión",
        version_number: "Número versión",
        version_number_info: "Introduzca un número de versión. No puede cambiar el número de versión después de guardarlo.",
        existing_versions: "Números de versión existentes para este contenido",
        version_number_validation: {
          missing: "Debe introducir un número de versión",
          invalid: "El número de versión debe utilizar las reglas semánticas de control de versiones (IMPORTANTE.MENOR.PARCHE)",
          already_in_use: "Este número de versión ya está en uso para este contenido.",
        },
        select_update_type: "Para generar un número de versión, primero seleccione el tipo de actualización",
        please_enter_a_version_name_or_number: "Introduzca un nombre o número de versión.",
        virus_scan: {
          soft_failed: "Error",
          hard_failed: "Sin analizar",
          infected: "Infectado",
        },
      },
      collections: {
        by: "Por:",
        edit: {
          edit_collection: "Editar colección",
          edit_description: "Editar descripción",
        },
        notification: {
          collection_updated: "Colección actualizada",
          error: "Error",
          error_loading_other_users_collections: "Error al cargar las colecciones de otros usuarios",
          error_loading_other_organizations_collections: "Error al cargar las colecciones de otras organizaciones",
          error_loading_collection_contents: "Error al cargar el contenido de la colección",
          members_list_updated: "Se ha actualizado la lista de miembros",
          invitations_removed: "Se han eliminado las invitaciones",
          collection_removed: "Colección eliminada",
          thumbnail_deleted: "Vista en miniatura borrada",
          wrong_file_type: "Tipo de archivo incorrecto.",
          invalid_file_type: "Tipo de archivo no válido.",
          selected_image_is_too_big: "La imagen seleccionada es demasiado grande.",
          thumbnail_updated: "Vista en miniatura actualizada",
          default_thumbnail_updated: "Vista en miniatura por defecto actualizada",
          default_thumbnail_update_failed: "Error al actualizar la vista en miniatura por defecto",
          thumbnail_added: "Vista en miniatura añadida",
          thumbnail_update_failed: "Error al actualizar la vista en miniatura",
          video_added: "Vídeo añadido",
          video_adding_failed: "Error al añadir vídeo",
          video_deleted: "Vídeo borrado",
          video_deleting_failed: "Error al borrar vídeo",
        },
        confirm: {
          delete_all: function (title) {
            return 'Borrar la colección y todo su contenido "' + title + '"?';
          },
          remove_members: "¿Está seguro?",
          remove_invitations: "¿Está seguro?",
        },
        useNetworkDrive: "Utilizar carpeta o unidad en red",
        createCollection: "Crear colección en línea",
        createCollectionInstructions: "Crear una colección para agrupar su contenido.",

        actions: {
          delete_thumbnail: "Borrar vista en miniatura",
          edit_collection: "Editar colección",
          delete_collection: "Borrar colección",
          add_content: "Añadir contenido",
        },
        subTabs: {
          content: "Contenido",
          members: "Mostrar miembros",
          analytics: "Análisis",
          manage_access: "Gestionar acceso",
        },

        members: {
          add_user_groups: "Añadir grupos de usuarios",
          select_user_group: "Seleccionar grupo de usuarios",
          with_role: "con función",
          user_groups_added: "Grupos de usuarios añadidos",
          members_list_updated: "Se ha actualizado la lista de miembros",
          update_selected_members: "Actualizar miembros seleccionados",
          table_header: {
            member_type: "Tipo miembro",
            member_name: "Nombre miembro",
            email: "E-mail",
            role: "Función",
            joined_at: "Unido en",
            invited_at: "Invitado en",
          },
          new_role: "Nueva función",
        },
        member_list: {
          users_who_can_access_this_collection: "Usuarios que tienen acceso a esta colección",
          add_user_group: "Añadir grupo de usuarios",
          update_members: "Actualizar miembros",
          remove_members: "Eliminar miembros",
          pending_invitations: "Invitaciones pendientes",
          user: "usuario",
          invite_member: "Invitar miembro",
          update_invitations: "Actualizar invitaciones",
          remove_invitations: "Eliminar invitaciones",
        },
        labels: {
          select_organization: "Seleccionar organización",
          select_visibility: "Seleccionar visibilidad",
          title: "Nombre",
          name: "Nombre",
          createAs: "Crear como: ",
          asMyself: "Yo mismo",
          asUserGroup: "Grupo de usuario",
          asOrganization: "Organización",
          selectOwner: "Seleccionar organización para propietario de contenido",
          visibility: "Visibilidad",
          visibilityOptions: {
            public: "Público",
            private: "Privado",
          },
          description: "Descripción",
        },
        new_collection: {
          please_input_a_username: "Introduzca un nombre de usuario",
          title_is_required_to_be_at_least_3_characters: "El título debe contener como mínimo 3 caracteres",
          title_cannot_be_longer_than_20_characters: "El título no puede tener más de 20 caracteres",
        },
        collection_list: {
          no_content: "Sin colecciones.",
          results_summary: function (number) {
            return "" + number + "  colección " + pluralize(number, ["elementos", "elemento", "elementos"]);
          },
        },
        entity_list: {
          no_content: "La colección no tiene contenido.",
          results_summary: function (number) {
            return "" + number + " contenido " + pluralize(number, ["elementos", "elemento", "elementos"]);
          },
          linked_content: "Contenido enlazado",
          unlink: "Desenlazar contenido",
        },
      },
      alerts: {
        no_updates: "No actualizado recientemente",
        notifications_marked_as_read: "Notificación marcada como leída",
        cleared_alerts: "Todas las notificaciones borradas",
        alert_by_email:
          "Enviarme un correo electrónico cuando se actualice cualquier contenido de la siguiente lista con una nueva versión, o se actualice una colección con nuevo contenido",
        action_types: {
          create: "se ha creado",
          update: "se ha editado",
          delete: "se ha borrado",
          added_entity: "se ha añadido la versión",
          removed_entity: "se ha eliminado la versión",
          added_collection: "se ha añadido contenido",
          removed_collection: "se ha eliminado contenido",
        },
        property_names: {
          description: "Detalles",
          searchCriteria: "Información sobre criterios de búsqueda",
          groupCategory: "Información sobre grupo y categoría",
          support: "Información soporte",
          licenses: "Información sobre licencias",
          relatedContent: "Información sobre contenido relacionado",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Actualizado " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Las preferencias de notificación se han actualizado correctamente",
          preferences_update_failed: "Se ha producido un error al actualizar las preferencias de notificación",
        },
      },
      browseDialog: {
        useNetworkDrive: "Utilizar carpeta o unidad en red",
        ownerGroups: {
          title: "Grupos de propietarios",
        },
      },
      itemTypeCategories: {
        all: "Todos los grupos",
        application: "Aplicaciones",
        "3dProduct": "Productos 3D",
        customComponent: "Componentes personalizados",
        tool: "Aplicaciones",
        profile: "Perfiles",
        material: "Materiales",
        bolt: "Tornillos",
        rebar: "Armaduras",
        reportTemplate: "Cuadros de informe",
        drawingSetup: "Archivos de configuración de dibujo",
        modelSetup: "Archivos de configuración de modelo",
        environmentFile: "Archivos de entorno",
        shape: "Formas",
        //'shapeCatalog': 'Base de datos de formas',
        //'foreignGeometryDefinition': 'Definición de geometría externa'
      },
      platforms: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: "Plataforma independiente",
      },
      platformsDownload: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: " ",
      },
      contentItemSource: {
        other: "General - a carpeta Modelo",
        run: "Ejecutar herramienta",
        bolt: "Conjunto de tornillo",
        component: "Componente",
        drawing: "Configuración de dibujo (BETA)",
        material: "Material",
        profile: "Perfil",
        mesh: "Malla de armaduras",
        rebar: "Barra de armadura",
        shape: "Forma",
        geometry: "Geometría",
        modeltemplate: "Plantilla de modelo",
        cloningtemplate: "Plantilla de clonación",
        noaction: "Ninguna acción",
        releasenotes: "Novedades",
      },
      contentItemHeader: {
        bolt: "Conjuntos de tornillos",
        component: "Componentes",
        drawing: "Configuraciones de dibujo (BETA)",
        material: "Materiales",
        profile: "Perfiles",
        mesh: "Mallas de armaduras",
        rebar: "Barras de armaduras",
        shape: "Formas",
      },
      useCategoryGroups: {
        workflow: "Trabajo",
        structures: "Estructuras",
        drawings: "Dibujos",
        modeling: "Modelado",
      },
      useCategories: {
        title: "Se usa en/para",
        conceptualDesign: "Diseño conceptual",
        designEngineeringAnalysis: "Diseño / Ingeniería / Análisis",
        detailing: "Detallado",
        fabrication: "Fabricación",
        erectionInstallationPour: "Montaje / Instalación / Vertido",
        contracting: "Contratación",
        scheduling: "Planificación",
        takeOffEstimation: "Reducción / Estimación",
        concreteStructures: "Hormigón de colada in situ",
        precastConcrete: "Hormigón prefabricado",
        offshore: "Plataforma",
        steelStructures: "Estructuras de acero",
        timberStructures: "Estructuras de madera",
        reinforcement: "Armaduras",
        drawingCreation: "Creación de dibujos",
        drawingManagement: "Gestión de dibujos",
        drawingEditing: "Edición de dibujos",
        drawingAnnotation: "Anotación en dibujos",
        viewSimulateNavigate: "Vista, simulación, navegación",
        compareReviewManageData: "Comparar, revisar, gestionar datos",
        softwareCollaboration: "Colaboración Software",
        productivity: "Productividad",
        interoperability: "Interoperabilidad",
        systemSetup: "Configuración del sistema",
      },
      measurementUnits: {
        "0": "Métrico",
        "1": "Imperial",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Open BIM (IFC)",
        standalone: "Independiente",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures15',
        //'TS1': 'Tekla Structures16',
        //'TS2': 'Tekla Structures17',
        NVS: "Ninguna versión específica",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight1',
        //'TBS1': 'Tekla BIMsight1.5',
        //'TBS2': 'Tekla BIMsight2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "Experimental",
        candidate: "Candidato",
        approved: "Aprobado",
        standard: "Estándar",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Inglés",
        "zh-Hans": "Chino - Simplificado",
        "zh-Hant": "Chino - Tradicional",
        cs: "Checo",
        da: "Danés",
        nl: "Neerlandés",
        fi: "Finés",
        fr: "Francés",
        de: "Alemán",
        hu: "Húngaro",
        it: "Italiano",
        ja: "Japonés",
        ko: "Coreano",
        pl: "Polaco",
        pt: "Portugués",
        "pt-br": "Portugués - Brasil",
        ru: "Ruso",
        es: "Español",
        sv: "Sueco",
        no: "Noruego",
        other: "Otro",
      },
      isoLangs: {
        ab: {
          name: "Abjasiano",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikáans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akano",
          nativeName: "Akan",
        },
        sq: {
          name: "Albanés",
          nativeName: "Shqip",
        },
        am: {
          name: "Amárico",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Árabe",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonés",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Armenio",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Asamés",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avar",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avéstico",
          nativeName: "avesta",
        },
        ay: {
          name: "Aimara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azerí",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Baskir",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Euskera",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Bielorruso",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengalí",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bosnio",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Bretón",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Búlgaro",
          nativeName: "български език",
        },
        my: {
          name: "Birmano",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Catalán; Valenciano",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Checheno",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa; Chewa; Nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chino, Simplificado y Chino",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Chino - Simplificado",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Chino - Tradicional",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Chino - Taiwanés",
          nativeName: "國語",
        },
        cv: {
          name: "Chuvasiano",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Córnico",
          nativeName: "Kernewek",
        },
        co: {
          name: "Corso",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Croata",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Checo",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Danés",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi; Maldivo",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Neerlandés",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Inglés",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Estonio",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Feroés",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fiyiano",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finés",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Francés",
          nativeName: "Français",
        },
        ff: {
          name: "Fula; Fulaní; Pulaar; Pula",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Gallego",
          nativeName: "Galego",
        },
        ka: {
          name: "Georgiano",
          nativeName: "ქართული",
        },
        de: {
          name: "Alemán",
          nativeName: "Deutsch",
        },
        el: {
          name: "Griego, moderno",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gujarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haitiano, Haitiano Criollo",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Hebreo (moderno)",
          nativeName: "עברית",
        },
        hz: {
          name: "Herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Húngaro",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonesio",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingua",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Irlandés",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Islandés",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italiano",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japonés",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Javanés",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, Groenlandés",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Cachemiro",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Kazaj",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu, Gikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Ruandés",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Kirguís",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Coreano",
          nativeName: "한국어",
        },
        ku: {
          name: "Curdo",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kwanyama, Kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latín",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxemburgués",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburgués",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Laosiano",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Lituano",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba",
          nativeName: "",
        },
        lv: {
          name: "Letón",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manés",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macedonio",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Malgache",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Malayo",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malayalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltés",
          nativeName: "Malti",
        },
        mi: {
          name: "Maorí",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Maratí",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshalés",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongol",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Noruego Bokmål",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Ndebele del Norte",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepalés",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Noruego Nynorsk",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Noruego",
          nativeName: "Norsk",
        },
        ii: {
          name: "Yi de Sichuán (Nuosu)",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Ndebele del Sur",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Occitano",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwa",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Eslavo, Eslavo Eclesiástico, Eslavón",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Osetio",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Punyabí",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pali",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Persa",
          nativeName: "فارسی",
        },
        pl: {
          name: "Polaco",
          nativeName: "polski",
        },
        ps: {
          name: "Pasto",
          nativeName: "پښتو",
        },
        pt: {
          name: "Portugués",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Portugués - de Brasil",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quechua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Retorrománico",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Rumano, Moldavo",
          nativeName: "română",
        },
        ru: {
          name: "Ruso",
          nativeName: "русский",
        },
        sa: {
          name: "Sánscrito",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sardo",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Lapón del Norte",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoano",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Serbio",
          nativeName: "српски језик",
        },
        gd: {
          name: "Escocés Gaélico; Gaélico",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Sinhala, Cingalés",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Eslovaco",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Esloveno",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somalí",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Sotho del Sur",
          nativeName: "Sesotho",
        },
        es: {
          name: "Español", //      'name':'Tradicional / español',
          nativeName: "español",
        },
        su: {
          name: "Sundanés",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Swahili",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Suazi",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Sueco",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tayiko",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Tailandés",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigriña",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibetano estándar, Tibetano central",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turcomano",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalo",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tongano (Islas de Tonga)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Turco",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tártaro",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Tahitiano",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Uigur",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ucraniano",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Uzbeko",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamita",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Valón",
          nativeName: "Walon",
        },
        cy: {
          name: "Galés",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Frisón occidental",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Yiddish",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Yoruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Chuan, Zhuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Otro",
          nativeName: "Otro",
        },
      },

      details: {
        navigation: {
          content_information: "Información de contenido",
          version_and_file_management: "Gestión de archivos y versiones",
          details: "Detalles",
          versions: "Versiones",
          analytics: "Análisis",
          statistics: "Estadísticas",
          manage_access: "Gestionar acceso",
          related_content: "Relacionado",
          properties: "Propiedades",
          support: "Empresa y soporte",
          release_notes: "Novedades",
          comments: "Comentarios de usuario",
        },
        related_content: {
          other_content_title: "Otro contenido que puede gustarle",
          related_content_title: "Contenido recomendado por ",
          related_content_url: "Dirección URL de contenido",
          add_related_content: "Añadir contenido relacionado",
          add_related_content_info:
            "Solo puede añadir contenido en línea relacionado con el contenido en línea, y contenido local relacionado con el contenido local",
          remove_related_content: "Eliminar",
          relatedContentUrlSubtitle: "Id de contenido o dirección URL completa al contenido",
          updated_successfully: "El contenido relacionado se ha actualizado correctamente",
          update_failed: "Error al actualizar el contenido relacionado",
          update_failed_invalid_id: "El ID de contenido proporcionado no es válido",
        },
        actions: {
          move: "Mover",
          delete: "Borrar",
          add_comment: "Añadir nota",
          add_reply: "Añadir respuesta",
          edit_comment: "Editar nota",
          reply_comment: "Añadir respuesta",
          view_replies: "Ver respuestas",
          report_bad_comment: "Informar de comentarios negativos &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Editar título o eliminar",
            title_or_move_delete: "Editar título, mover o eliminar",
            thumbnail: "Editar miniatura",
            description: "Editar descripción",
            groupandcategory: "Editar grupo y categoría",
            details: "Editar detalles",
            versions: "Editar versiones",
            support: "Editar información de soporte",
            release_notes: "Editar novedades",
            search_criteria: "Editar criterios de búsqueda",
            batchedit_and_translations:
              "Con la función de traducción puede publicar su contenido en varios idiomas. La versión traducida de su contenido se mostrará a los usuarios según su selección de idioma de la interfaz de usuario de Warehouse. Con la edición por lotes puede editar varios elementos a la vez. Primero, seleccione un campo y, a continuación, los elementos que desea editar",
          },
        },
        links: {
          link: "Enlazar",
          select_collection_placeholder: "Seleccione una colección que desee enlazar",
          linking_collections_label: "Colecciones que se enlazan con esta",
          private_content_note:
            "Nota: El contenido privado se mantendrá visible solo para los usuarios con acceso al mismo.",
          share_on_social_media: "Compartir en:",
          report_bad_content: "Informar de contenido incorrecto &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Denunciar contenido ilegal &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Al suscribirse a las alertas, acepta compartir su nombre mostrado de Tekla Account y el nombre de la organización (si se aplica) con el propietario del contenido.",
          alert_me: "Alertarme",
          unsubscribe: "Cancelación de suscripción",
          subscribed_successfully: "Suscrito correctamente",
          unsubscribed_successfully: "Cancelación de suscripción correcta",
          subscribers_label: "Suscriptores",
        },
        reviews: {
          reviewers_label: "Revisores",
        },
        comments: {
          replies: "Respuestas",
        },
        scan: {
          soft_failed_binaries_info: "Error en el análisis antivirus  ",
          hard_failed_binaries_info: "No se pudo realizar el análisis antivirus ",
          infected_binaries_info: "El análisis antivirus ha encontrado una infección ",
          what_now: "¿Qué desea hacer ahora?",
          popup: {
            soft_failed_binaries_title: "Binarios con error",
            hard_failed_binaries_title: "Binarios con error",
            infected_binaries_title: "Binarios infectados",
            soft_failed_binaries_info:
              "Error en el análisis antivirus de los archivos que ha cargado. Solo es temporal. Elimine los archivos y cárguelos de nuevo. Todos los archivos deben superar el análisis antivirus antes de que estén visibles para otros usuarios.",
            hard_failed_binaries_info:
              "No se pudo realizar el análisis antivirus. Elimine los archivos. Todos los archivos deben superar el análisis antivirus antes de que estén visibles para otros usuarios.",
            infected_binaries_info:
              "El análisis antivirus ha encontrado una infección en los archivos que ha cargado. Elimine los archivos. Todos los archivos deben superar el análisis antivirus antes de que estén visibles para otros usuarios.",
            version: "Versión: ",
            fileName: "nombre de archivo: ",
          },
        },
        status: {
          content_banned_info:
            "El administrador ha bloqueado el contenido. Actualmente no está disponible para otros usuarios, excepto para el propietario del contenido. <a href='https://support.tekla.com/contact-us' target='_blank'>Contacte con soporte</a> para obtener más información.",
          content_archived_info:
            "Este contenido ha sido archivado. Los usuarios sin derechos de edición serán redirigidos a la página principal si intentan visitar el contenido a través de un enlace directo.",
          archived: "Archivado",
        },
        modified_by: function (name) {
          return " por " + name;
        },
        created_by: function (name) {
          return "Creado por " + name;
        },
        deleteEntity: function (title) {
          return "Borrar " + title + "?";
        },
        userComments: "Comentarios de usuario",
        comment_added: "Comentario añadido correctamente",
        comment_updated: "Comentario actualizado correctamente",
        comment_deleted: "Comentario borrado correctamente",
        comment_add_failed: "Error al añadir comentario",
        comment_update_failed: "Error al actualizar comentario",
        comment_delete_failed: "Error al borrar comentario",
        comment_delete_confirmation: "¿Borrar elemento de comentario?",
        name: "Nombre",
        details: "Detalles",
        contentOwner: "Creador",
        contentOwnerGroup: "Grupo de propietarios del contenido",
        contentGroup: "Grupo de contenido",
        thumbnail: "Vista en miniatura",
        tooltip: "Información",
        description: "Descripción",
        additional_information: "Información adicional",
        images: "Imágenes del producto",
        installationInfo: "Información sobre la instalación",
        productCode: "Código del producto",
        copyrightInfo: "Copyright",
        productExpirationDate: "Fecha de caducidad",
        helpUrl: "Sitio web &rsaquo;",
        supportUrl: "Soporte &rsaquo;",
        specialTermsUrl: "Acuerdo de licencia de usuario final (EULA) &rsaquo;",
        releaseNotesUrl: "Novedades",
        supportForumUrl: "Discussion Forum &rsaquo;",
        itemTypeCategories: "Grupo",
        useCategories: "Categoría",
        category: "Categoría:",
        group: "Grupo:",
        compatibleSoftwareProducts: "Productos de software compatibles",
        compatibleOperatingSystems: "Sistemas operativos compatibles",
        locationRestrictions: "Disponibilidad del producto:",
        supportedLanguages: "Idiomas:",
        measurementUnits: "Unidades de medida:",
        tags: "Palabras clave y etiquetas:",
        prerequisites: "Requisitos previos:",
        releaseNotes: "Novedades:",
        versions: "Versiones",
        testedVersions: "Versiones compatibles",
        qualityTag: "Calidad - estimada por el propietario",
        otherAttributes: "Otros atributos",
        labels: "Criterios de búsqueda",
        supportInformation: "Soporte",
        updateInformation: "Actualizaciones",
        latestUpdate: "Última actualización",
        totalDownloads: "Total de descargas",
        licenses: "Licencias",
        maintenanceRequired: "Tekla Maintenance es necesario para la descarga",
        tekla_maintenance_content: "Contenido de Tekla Maintenance",
        download_requires_tekla_maintenance: "Tekla Maintenance es necesario para la descarga",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Se ha actualizado correctamente",
        update_failed: "No se ha podido actualizar",
        update_failed_invalid_tag: "No se ha podido actualizar: etiqueta no válida",
        update_failed_details_max_length_exceeded: "No se ha podido actualizar: detalles demasiado largos",
        show_version_details: "Más información",
        content_removed: "Contenido eliminado",
        no_versions_available:
          "No hay versiones disponibles. Las actualizaciones pueden tardar un poco en aparecer. Actualice su navegador para ver los cambios.",
        thumbnail_deleted: "Vista en miniatura borrada",
        thumbnail_updated: "Vista en miniatura actualizada",
        verify_service_plugin_is_running:
          "Compruebe que su complemento Tekla Warehouse Service se está ejecutando correctamente",
        verify_ts_connection: "Compruebe que Tekla Structures se está ejecutando",
        download_tos: {
          title: "Calidad, soporte y privacidad",
          content_1: "Asegúrese de que el contenido descargado cumple con sus estándares de calidad. Trimble no proporciona soporte y no es responsable del contenido de terceros. Si desea obtener soporte, póngase en contacto directamente con el proveedor del contenido.",
          content_2: "Su país y el nombre de su organización pueden publicarse en el servicio y revelarse a los propietarios del contenido con fines legítimos, como mantenerle informado sobre modificaciones del contenido. Puede darse de baja de estas notificaciones.",
          content_3: 'Para las extensiones de contenido de Trimble, se aplican los <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">términos de Trimble</a>. Los términos de licencia de terceros pueden aplicarse al contenido de terceros. Consulte el <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Aviso de Privacidad de Trimble</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Es el almacenamiento de Tekla Structures BIM gratuito. Encuentre, importe, instale y comparta interna y globalmente. Produzca modelos de alta calidad con eficiencia.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse es una biblioteca local y en línea gratuita de herramientas de modelado, plantillas y partes inteligentes de Tekla Structures",
        },
        search: {
          title: "Buscar Tekla Warehouse",
          description:
            "Buscar extensiones, partes 3D, formas, componentes personalizados, perfiles, materiales como calidades de acero y hormigón, armaduras, mallas y plantillas de Tekla",
        },
        about: {
          title: "Información acerca de Tekla Warehouse",
          description:
            "Tekla Warehouse ofrece un canal de marketing gratuito de sus productos y le proporciona un control completo de su contenido",
          developers: {
            title: "Título de desarrolladores por defecto",
            description: "Descripción de desarrolladores por defecto",
          },
          usage: {
            title: "Título de uso por defecto",
            description: "Descripción de uso por defecto",
          },
          getting_started: {
            title: "Título de iniciación por defecto",
            description: "Descripción de iniciación por defecto",
          },
          content: {
            title: "Título de contenido por defecto",
            description: "Descripción de contenido por defecto",
          },
          using_content: {
            title: "Título de uso de contenido por defecto",
            description: "Descripción de uso de contenido por defecto",
          },
          creating_content: {
            title: "Título de creación de contenido por defecto",
            description: "Descripción de creación de contenido por defecto",
          },
        },
        collection: {
          title: "Título de colección por defecto",
          description: "Descripción de colección por defecto",
        },
        my_warehouse: {
          title: "Mi Tekla Warehouse",
          description: "Gestione su contenido de Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Cancelar",
          previous: "&lsaquo; Anterior",
          next: "Siguiente &rsaquo;",
        },
        content: {
          add_content: "Añadir archivos",
        },
        collection: {
          heading: "Crear o seleccionar colección",
          subHeading:
            "Contenido de grupo de colecciones. Puede usar las colecciones en línea para publicar su contenido o colecciones locales para compartir el contenido con sus compañeros. También puede utilizar las colecciones locales para uso privado.",
          loading: "Cargando colecciones...",
          selectFromMyCollections: "Seleccionar desde Mis Colecciones",
          description_of_the_collection: "Descripción",
          title_validation: {
            missing: "Introduzca un título.",
            too_short: function (length) {
              return "El título debe tener como mínimo " + length + " caracteres.";
            },
            too_long: function (length) {
              return "El título no puede tener más de " + length + " caracteres.";
            },
          },
        },
        package: {
          basic_information: "Información básica",
          code_name: "Nombre clave",
          code_name_info:
            "Introduzca un nombre en clave como identificador único de este contenido. El nombre en clave puede incluir letras minúsculas, números y estos caracteres especiales: _ - @. No puede cambiar el nombre en clave una vez guardado.",
          code_name_validation: {
            missing: "Debe introducir un nombre en clave",
            wrong_format: "El nombre en clave puede incluir letras minúsculas, números y estos caracteres especiales: _ - @",
            not_unique: "Este nombre en clave ya está en uso. Debe introducir un nombre en clave único.",
          },
          search_criteria: "Criterios búsqueda",
          support: "Soporte",
          title: "Título",
          thumbnail: "Vista en miniatura",
          "3D_pic": "Modelo 3D",
          title_validation: {
            missing: "Introduzca un título.",
            too_short: function (length) {
              return "El título debe tener como mínimo " + length + " caracteres.";
            },
          },
          description_validation: {
            missing: "Introduzca una descripción.",
            too_short: function (length) {
              return "La descripción debe tener como mínimo " + length + " caracteres.";
            },
          },
          wrong_file_type: "Tipo de archivo incorrecto.",
          selected_image_is_too_big: "La imagen seleccionada es demasiado grande.",
        },
        version: {
          select_version: "Seleccionar versión",
          select_quality: "Seleccionar calidad",
          versions: "Versiones",
          displayNameNew: "Nombre mostrado versión",
          copy_from_version: "Copiar metadatos de la versión existente (opcional)",
          displayName: "Mostrar nombre",
          content_information: "Información de contenido",
          back_to_content_information: "&lsaquo; Volver a información de contenido",
          version_and_file_management: "Gestión de archivos y versiones",
          there_s_no_versions_yet: "Aún no hay versiones disponibles.",
          create_a_version_to_add_files: "Crear una versión para añadir archivos.",
          add_version: "Añadir versión",
          version_information: "Información de versión",
          delete_version: "Borrar versión",
          no_versions_available: "No hay versiones disponibles. Las actualizaciones pueden tardar un poco en aparecer.",
          update_start_title_html: '<div class="spinner spinner-white">Actualizando</div>',
          update_start_message: "Puede tardar un poco.",
          version_was_created: "Se ha creado la versión.",
          version_was_created_with_delay: "Se ha creado la versión. Podrá ver los cambios en unos minutos.",
          version_was_updated: "Se ha actualizado la versión.",
          version_was_deleted: "Se ha borrado la versión.",
          version_was_deleted_with_delay: "Se ha borrado la versión. Podrá ver los cambios en unos minutos.",
          confirm_delete: function (title) {
            return 'Borrar versión "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Se ha cargado el paquete.",
          couldn_t_create_a_package: "No se ha podido crear un paquete. Se revertirán los cambios.",
          uploading_title_html: '<div class="spinner spinner-white">Cargando</div>',
          uploading_text: "Puede tardar un poco.",
          select_organization: "Seleccionar organización",
          collection_visibility: "Visibilidad de colección:",
          actions: {
            create: "Crear",
            publish: "Publicar",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Todos los usuarios que tengan acceso a la unidad local o en red podrán ver este contenido.",
          this_content_will_be_visible_only_to_you: "Nadie más podrá ver este contenido.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Este contenido solo será visible para usted y todos los usuarios de su organización.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Cualquier persona podrá ver este contenido después de publicarlo.",
          public_content_disclaimer:
            "Será responsable de cualquier contenido que cargue. Asegúrese de que el contenido no sea dañino, de que tiene derecho para cargar el contenido y de que no infringe ningún derecho de propiedad intelectual. Acepta publicar en el servicio cualquier término y condición que se aplique al uso del contenido que cargue.",
        },
        metadata: {
          title: "Metadatos",
          subtitle: "Datos básicos",
          deleteThumbnail: "Borrar vista en miniatura",
          buttonCreate: "Crear",
        },
        basicdata: {
          title: "Añadir contenido",
          clickButtonToSelectFile: "Haga clic en el botón para seleccionar un archivo",
          thumbnail: "Vista en miniatura",
          dropImageHere: "Arrastrar Imagen Aquí",
        },
        "3dGeometry": {
          title: "Crear catálogo de productos 3D",
          buttonAddEntity: "Añadir producto 3D",
          buttonAdd: "Añadir atributos personalizados",
          errorAttributeAlreadyExists: "Ya existe un atributo con el mismo nombre.",
          buttonCreatePackage: "Crear paquete",
          buttonCreatePackages: "Crear paquetes",
          addCustomAttributePlaceholder: "Nombre atributo",
        },
        please_log_in_fragments: {
          please: "Puede ",
          log_in: "Iniciar sesión",
          or: " o ",
          register: "registrarse",
          to_upload_online_content: " para cargar contenido en línea.",
        },
        header: {
          upload_header: "Cargar",
          collection: "Colección",
          content: "Contenido",
          version: "Versión",
          finish: "Finalizar",
        },
        contentOwner: "Creador",
        contentOwnerGroup: "Publicar contenido",
        select_action: "Seleccionar acción",
        tooltip: "Información",
        description: "Descripción",
        copyright: "Copyright",
        tags: "Palabras clave y etiquetas",
        tagsSubtitle:
          "Use comas para separar; por ejemplo: clave1, clave2. Si la clave consta de varias palabras, utilice subrayado (_) entre las palabras, no se permiten espacios. Aparte del subrayado y la coma, no se permiten otros caracteres especiales.",
        images: "Imágenes",
        installationInfo: "Información de instalación",
        productCode: "Código producto",
        copyrightInfo: "Información de copyright",
        Details: "Detalles",
        specialTermsUrl: "Acuerdo de licencia de usuario final (EULA)",
        productExpirationDate: "Fecha de caducidad",
        helpUrl: "Sitio web",
        helpUrlSubtitle: "insertar dirección URL completa",
        detailsSubtitle:
          "En esta fase solo puede añadir texto sin formato al campo Detalles. Una vez creado el contenido, puede editar los detalles para que contengan formato html.",
        specialTermsUrlSubtitle:
          "El propietario del contenido tiene la oportunidad de añadir términos adicionales para su propio contenido. El propietario debe proporcionar una dirección URL en la que aparezcan sus términos específicos. Esto no anula las condiciones de uso de Trimble.",
        releaseNotes: "Archivo de novedades",
        releaseNoteFileSubTitle: "Añadir novedades como archivo",
        supportUrl: "Ayuda y soporte",
        supportUrlSubtitle:
          "introduzca la dirección URL completa o el prefijo 'mailto:' para enviar un correo electrónico",
        supportForumUrl: "Discussion Forum (sitio web)",
        supportForumUrlSubtitle: "insertar dirección URL completa",
        measurementUnits: "Unidades de medida",
        itemTypeCategories: "Grupo",
        useCategories: "Categoría",
        compatibleSoftwareProducts: "Productos de software compatibles",
        testedVersions: "Versiones compatibles",
        compatibleOperatingSystems: "Sistema operativo compatible",
        locationRestrictions: "Disponibilidad del producto",
        supportedLanguages: "Idiomas",
        select_releasenotes_file: "Seleccionar archivo",
        qualityTag: "Calidad - estimada por el propietario",
        requireMaintenance: "Para descargar se requiere mantenimiento de cliente de Tekla",
        contentOwnerGroupAsMyself: "Como yo mismo",
        contentOwnerGroupAsUserGroup: "Como grupo de usuarios",
        selectUserGroupForContentOwner: "Seleccionar grupo de usuarios para propietario del contenido",
        defaultUploadTemplate: "Paquete por defecto",
        templateFor3DGeometryFiles: "Paquete(s) de geometría 3D",
        title: "Título",
        details: "Detalles",
        selectContentOwner: "Seleccionar propietario del contenido",
        selectContentType: "Tipo",
        errorWrongfiletype: "Tipo de archivo incorrecto.",
        errorImageTooBig: "La imagen seleccionada es demasiado grande.",
        errorBlankTitle: "El título no puede estar en blanco",
        commonLabelsTitle: "Etiquetas comunes para todos los paquetes",
      },
      profile: {
        content_usage: "Uso del contenido",
        profile_updated: "Perfil actualizado",
        invitation_accepted: function (target) {
          return "Se ha aceptado la invitación Ahora puede acceder a " + target + ".";
        },
        user_groups: {
          edit_user_group: "Editar grupo de usuarios",
          user_group_updated: "Grupo de usuarios actualizado",
          create_a_new_user_group: "Crear un nuevo grupo de usuarios",
          name_of_the_user_group: "Nombre del grupo de usuarios",
          description: "Descripción",
          group_visibility: "Visibilidad de grupo",
          remove: '"¿Está seguro?"',
          remove_invitations: '"¿Está seguro?"',
          remove_members: '"¿Está seguro?"',
          invitations_removed: "Se han eliminado las invitaciones",
          members_list_updated: "Se ha actualizado la lista de miembros",
          user_group_created: "Grupo de usuarios creado",
        },
        members: {
          update_selected_members: "Actualizar miembros seleccionados",
          new_role: "Nueva función",
          members_list_updated: "Se ha actualizado la lista de miembros",
          edit_user_group: "Editar grupo de usuarios",
          delete_user_group: "Borrar grupo de usuarios",
          filter: "Filtrar",
          user_group_members: "Miembros del grupo de usuarios",
          update_members: "Actualizar miembros",
          remove_members: "Eliminar miembros",
          pending_invitations: "Invitaciones pendientes",
          invite_member: "Invitar miembro",
          update_invitations: "Actualizar invitaciones",
          remove_invitations: "Eliminar invitaciones",
        },
        subTabs: {
          alerts: "Mis alertas",
          collections: "Colecciones",
          likes: "Me gusta",
        },
        admin: {
          grant_sales_admin: "Concesión de función de administración de ventas para el usuario",
          grant_analyst_role: "Concesión de función de análisis para el usuario",
          role_granted: "Función concedida",
          role_removed: "Función eliminada",
          offline_to_online: "Convertir contenido sin conexión a contenido en línea",
          use_elastic_search: "Usar búsqueda elástica",
        },
        sales: {
          title: "Ventas",
        },
        subtitlePreferences: "Preferencias de búsqueda",
        preferenceInfo:
          'Estos filtros están pensados para recomendarle contenido en la <a href="#/landing">página principal</a> de Tekla Warehouse.',
        clearFilters: "Borrar filtros &rsaquo;",
        admin_role: "Administración",
        sales_role: "Ventas",
        admin_mode: "Modo de administración:",
        firstName: "Nombre",
        lastName: "Apellido",
        displayName: "Nombre mostrado:",
        organization: "Nombre organización:",
        email: "Correo electrónico:",
        teklaId: "ID de Tekla",
        maintenanceAccess: "Acceso a contenido de Tekla Maintenance:",
        myLocations: "Ubicaciones de proyectos",
        measurementUnits: "Unidades",
        useCategories: "Usar",
        itemTypeCategories: "Me interesan los siguientes tipos",
        languages: "Idiomas",
        operatingSystems: "Sistemas operativos",
        software: "Productos de software",
        user_id: "ID usuario:",
        organization_id: "ID organización:",
        edit_in_atc: "Editar mi perfil en Tekla Account",
        no_likes: "No hay Me gusta",
        no_alerts: "No hay suscripciones",
        clear_alerts: "Borrar alertas",
        alerts_info_html:
          "Recibirá una alerta cuando se produzca alguno de los cambios siguientes<br>" +
          "<b>Contenido:</b> Se modifica el título, descripción, criterios de búsqueda o metadatos del elemento de contenido, se añade una nueva versión de contenido o se elimina una versión.<br>" +
          "<b>Colección:</b> Se edita el título o la descripción, se añade o elimina contenido.",
      },
      uploader: {
        placeholders: {
          productCode: "Código producto",
          title: "Nombre",
          description: "Descripción",
          productWeight: "Peso producto",
          weightUnit: "Unidad de peso",
          material: "Material",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Catálogo de productos 3D",
          addToolButton: "Aplicación",
          select_files: "Archivos de disco",
          select_files_from_ts: "Archivos de Tekla Structures",
          partnerDownloadLink: "Enlace de descarga de partner",
        },
        headings: {
          geometryFile: "Archivo de geometría",
          thumbnailFile: "Mapa de bits (.bmp) de vistas en miniatura",
          title: "Nombre",
          description: "Descripción",
          productCode: "Código producto",
          productWeight: "Peso del producto",
          weightUnit: "Unidad de peso",
          material: "Material",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Global",
          AF: "Afganistán",
          AX: "Islas Åland",
          AL: "Albania",
          DZ: "Algeria",
          AS: "Samoa Americana",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguila",
          AQ: "La Antártida",
          AG: "Antigua y Barbuda",
          AR: "Argentina",
          AM: "Armenia",
          AW: "Aruba",
          AU: "Australia",
          AT: "Austria",
          AZ: "Azerbaiyán",
          BS: "Bahamas",
          BH: "Bahréin",
          BD: "Bangladés",
          BB: "Barbados",
          BY: "Bielorrusia",
          BE: "Bélgica",
          BZ: "Belice",
          BJ: "Benín",
          BM: "Bermudas",
          BT: "Bután",
          BO: "Bolivia",
          BQ: "Bonaire",
          BA: "Bosnia y Herzegovina",
          BW: "Botsuana",
          BV: "Isla Bouvet",
          BR: "Brasil",
          IO: "Territorio Británico del Océano Índico",
          BN: "Brunéi Darussalam",
          BG: "Bulgaria",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Camboya",
          CM: "Camerún",
          CA: "Canadá",
          CV: "Cabo Verde",
          KY: "Islas Caimán",
          CF: "República Centroafricana",
          TD: "Chad",
          CL: "Chile",
          CN: "China",
          CX: "Isla de Navidad",
          CC: "Islas Cocos",
          CO: "Colombia",
          KM: "Comoras",
          CG: "República del Congo",
          CD: "República Democrática del Congo",
          CK: "Islas Cook",
          CR: "Costa Rica",
          CI: "Costa de Marfil",
          HR: "Croacia",
          CU: "Cuba",
          CW: "Curaçao",
          CY: "Chipre",
          CZ: "República Checa",
          DK: "Dinamarca",
          DJ: "Yibuti",
          DM: "Dominica",
          DO: "República Dominicana",
          EC: "Ecuador",
          EG: "Egipto",
          SV: "El Salvador",
          GQ: "Guinea Ecuatorial",
          ER: "Eritrea",
          EE: "Estonia",
          ET: "Etiopía",
          FK: "Islas Malvinas",
          FO: "Islas Feroe",
          FJ: "Fiyi",
          FI: "Finlandia",
          FR: "Francia",
          GF: "Guayana Francesa",
          PF: "Polinesia Francesa",
          TF: "Territorios Australes Franceses",
          GA: "Gabón",
          GM: "Gambia",
          GE: "Georgia",
          DE: "Alemania",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Grecia",
          GL: "Groenlandia",
          GD: "Granada",
          GP: "Guadalupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guinea",
          GW: "Guinea-Bissau",
          GY: "Guyana",
          HT: "Haití",
          HM: "Islas Heard y McDonald",
          VA: "Ciudad del Vaticano",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Hungría",
          IS: "Islandia",
          IN: "India",
          ID: "Indonesia",
          IR: "Irán",
          IQ: "Iraq",
          IE: "Irlanda",
          IM: "Isla de Man",
          IL: "Israel",
          IT: "Italia",
          JM: "Jamaica",
          JP: "Japón",
          JE: "Jersey",
          JO: "Jordania",
          KZ: "Kazajistán",
          KE: "Kenia",
          KI: "Kiribati",
          KW: "Kuwait",
          KG: "Kirguistán",
          LA: "Laos",
          LV: "Letonia",
          LB: "Líbano",
          LS: "Lesoto",
          LR: "Liberia",
          LY: "Libia",
          LI: "Liechtenstein",
          LT: "Lituania",
          LU: "Luxemburgo",
          MO: "Macao",
          MK: "Macedonia",
          MG: "Madagascar",
          MW: "Malawi",
          MY: "Malasia",
          MV: "Maldivas",
          ML: "Mali",
          MT: "Malta",
          MH: "Islas Marshall",
          MQ: "Martinica",
          MR: "Mauritania",
          MU: "Mauricio",
          YT: "Mayotte",
          MX: "México",
          FM: "Micronesia",
          MD: "Moldavia",
          MC: "Mónaco",
          MN: "Mongolia",
          ME: "Montenegro",
          MS: "Montserrat",
          MA: "Marruecos",
          MZ: "Mozambique",
          MM: "Myanmar (Birmania)",
          NA: "Namibia",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Países Bajos",
          NC: "Nueva Caledonia",
          NZ: "Nueva Zelanda",
          NI: "Nicaragua",
          NE: "Níger",
          NG: "Nigeria",
          NU: "Isla Niue",
          NF: "Isla Norfolk",
          KP: "Corea del Norte",
          MP: "Islas Marianas del Norte",
          NO: "Noruega",
          OM: "Omán",
          PK: "Pakistán",
          PW: "Palau",
          PS: "Territorio de Palestina",
          PA: "Panamá",
          PG: "Papúa-Nueva Guinea",
          PY: "Paraguay",
          PE: "Perú",
          PH: "Filipinas",
          PN: "Islas Pitcairn",
          PL: "Polonia",
          PT: "Portugal",
          PR: "Puerto Rico",
          QA: "Qatar",
          RE: "Reunión",
          RO: "Rumanía",
          RU: "Federación de Rusia",
          RW: "Ruanda",
          BL: "San Bartolomé",
          SH: "Santa Helena",
          KN: "San Cristóbal y Nieves",
          LC: "Santa Lucía",
          MF: "San Martín",
          PM: "San Pedro y Miquelón",
          VC: "San Vicente y las Granadinas",
          WS: "Samoa",
          SM: "San Marino",
          ST: "Santo Tomé y Príncipe",
          SA: "Arabia Saudita",
          SN: "Senegal",
          RS: "Serbia",
          SC: "Seychelles",
          SL: "Sierra Leona",
          SG: "Singapur",
          SX: "Sint Maarten",
          SK: "Eslovaquia",
          SI: "Eslovenia",
          SB: "Islas Salomón",
          SO: "Somalia",
          ZA: "Sudáfrica",
          GS: "Georgia del Sur",
          KR: "Corea del Sur",
          SS: "Sudán del Sur",
          ES: "España",
          LK: "Sri Lanka",
          SD: "Sudán",
          SR: "Surinam",
          SJ: "Islas Svalbard y Jan Mayen",
          SZ: "Suazilandia",
          SE: "Suecia",
          CH: "Suiza",
          SY: "República Árabe Siria",
          TW: "Taiwán",
          TJ: "Tayikistán",
          TZ: "Tanzania",
          TH: "Tailandia",
          TL: "Timor Oriental",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad y Tobago",
          TN: "Túnez",
          TR: "Turquía",
          TM: "Turkmenistán",
          TC: "Islas Turcas y Caicos",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ucrania",
          AE: "Emiratos Árabes Unidos",
          GB: "Reino Unido",
          US: "Estados Unidos",
          UM: "Islas Ultramarinas de Estados Unidos",
          UY: "Uruguay",
          UZ: "Uzbekistán",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietnam",
          VG: "Islas Vírgenes (Británicas)",
          VI: "Islas Vírgenes (EE. UU.)",
          WF: "Wallis y Futuna",
          EH: "Sahara Occidental",
          YE: "Yemen",
          ZM: "Zambia",
          ZW: "Zimbabue",
        },
        sortOptions: {
          "-modifyTime": "Última actualización",
          "-modifiedAt": "Última actualización",
          title: "Orden alfabético A-Z",
          "-title": "Orden alfabético Z-A",
          "-reviewCount": "Más populares",
          name: "Nombre",
          role: "Función",
          email: "E-mail",
          "-relevance": "Relevancia",
          "-createdAt": "Creado por última vez",
          displayName: "Orden alfabético A-Z",
          "-displayName": "Orden alfabético Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Hoy",
          yesterday: "Ayer",
          this_week: "Esta semana",
          previous_week: "Semana anterior",
          this_month: "Este mes",
          previous_month: "Mes anterior",
          this_year: "Este año",
          previous_year: "Año anterior",
          beginning_of_time: "Principio del periodo de tiempo",
          custom: "Periodo de tiempo personalizado",
        },
        eventTypeOptions: {
          downloads: "Descargas",
          views: "Visualizaciones",
          all_events: "Todos los eventos",
        },
      },
    });
  },
};
