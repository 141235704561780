import { useContext } from "react";

import { IDropdownOption, IDropdownOptionGroup } from "models/dataModel";
import _ from "underscore";
import { metadataLabels } from "utils/MetadataLabels";

import { ModusDropdownMultiple } from "components/ModusDropdownMultiple";
import { observer } from "mobx-react";
import * as React from "react";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const SoftwareVersionFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const compatibleSoftwareVersionOptions: IDropdownOptionGroup[] = _.map(
    metadataLabels.testedVersionsOptions,
    (product) => {
      return {
        label: rootStore.getTranslation("compatibleSoftwareProductOptions.".concat(product.label)),
        options: !!product.options
          ? product.options.map((option) => {
            return { label: rootStore.getTranslation(option.label), value: option.value };
          })
          : [],
      };
    },
  );

  function handleVersionChange(selectedOptions: readonly IDropdownOption[]) {
    !_.isEmpty(selectedOptions) ? store.setTestedVersions(selectedOptions) : store.setTestedVersions(undefined);
  }

  return (
    <div className="search-filter" data-testid="softwareVersionFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.sw_versions")}:</h5>
      <ModusDropdownMultiple
        placeholder={rootStore.getTranslation("placeholders.filter-options.testedVersions")}
        className="dropdown-sw-versions"
        inputId="sw-versions"
        options={compatibleSoftwareVersionOptions}
        onChange={handleVersionChange}
        selectedValues={store.getTestedVersions()}
        isSearchable={false}
      />
    </div>
  );
});
