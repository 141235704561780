import { action, observable, makeObservable, runInAction } from "mobx";
import _ from "underscore";
import { createStoreContext } from "../../stores/rootStore";

/**
 * Store for handling supernavi popup menus.
 */
export class PopupStatusStore {
  /**
   * Observable for storing supernavi popups state
   */
  @observable private superNaviPopups: Record<string, boolean> = {
    services: false,
    user: false,
    language: false,
  };

  constructor() {
    makeObservable(this);
  }

  /**
   * Method opening selected popup.
   */
  @action
  public togglePopup(name: string) {
    if (_.isBoolean(this.superNaviPopups[name])) {
      runInAction(() => {
        this.superNaviPopups[name] = !this.superNaviPopups[name];
      });
      if (this.superNaviPopups[name]) {
        this.hideOtherPopups(name);
        this.addEventListener();
      }
    }
  }

  /**
   * Method for adding event listener for clicks on document body.
   */
  public addEventListener() {
    const bodyElement = document.querySelector("body");
    if (!!bodyElement) {
      bodyElement.addEventListener("click", this, { passive: true });
    }
  }

  /**
   * Method for removing event listener for clicks on document body.
   */
  public removeEventListener() {
    const bodyElement = document.querySelector("body");
    if (!!bodyElement) {
      bodyElement.removeEventListener("click", this);
    }
  }

  /**
   * Method for handling ui events
   */
  public handleEvent(evt) {
    if (evt.type === "click") {
      if (
        !this.targetOrParentHasId(evt.target, "language-menu") &&
        !this.targetOrParentHasId(evt.target, "services-menu") &&
        !this.targetOrParentHasId(evt.target, "user-menu")
      ) {
        this.hidePopups();
      }
    }
  }

  /**
   * Method checking if popup is open
   * @returns returns true if popup is open
   */
  public isPopupOpen(name: string): boolean {
    return this.superNaviPopups[name];
  }

  /**
   * Method for hiding all popups
   */
  @action
  private hidePopups() {
    Object.keys(this.superNaviPopups).map((key) => {
      runInAction(() => {
        this.superNaviPopups[key] = false;
      });
    });
    this.removeEventListener();
  }

  /**
   * Method for hiding all other popups than the one received as parameter
   */
  @action
  private hideOtherPopups(selectedPopup: string) {
    Object.keys(this.superNaviPopups).map((key) => {
      if (key !== selectedPopup) {
        runInAction(() => {
          this.superNaviPopups[key] = false;
        });
      }
    });
  }

  private targetOrParentHasId(target: HTMLElement, id: string): boolean {
    if (target.id === id) return true;
    else if (!!target.parentElement) return this.targetOrParentHasId(target.parentElement, id);
    else return false;
  }
}

/**
 * Context object for PopupStatusStore instances.
 */
export const PopupStatusStoreContext = createStoreContext<PopupStatusStore>(PopupStatusStore);
