import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { IDropdownOption } from "models/dataModel";
import _ from "underscore";
import { metadataLabels } from "utils/MetadataLabels";

import { ModusDropdownMultiple } from "components/ModusDropdownMultiple";
import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

export const SoftwareProductFilter = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: ContentManagerStore = useContext(ContentManagerStoreContext);

  const compatibleSoftwareProductOptions: IDropdownOption[] = _.map(
    metadataLabels.softwareProductsOptions,
    (option) => {
      return {
        label: rootStore.getTranslation(option.name),
        value: option.value,
      };
    },
  );

  function handleProductChange(selectedOptions: readonly IDropdownOption[]) {
    !_.isEmpty(selectedOptions)
      ? store.setCompatibleSoftwareProducts(selectedOptions)
      : store.setCompatibleSoftwareProducts(undefined);
  }

  return (
    <div className="search-filter" data-testid="softwareProductFilter">
      <h5>{rootStore.getTranslation("profile.admin.content_manager.sidepane.filter_labels.sw_products")}:</h5>
      <ModusDropdownMultiple
        placeholder={rootStore.getTranslation("placeholders.filter-options.products")}
        className="dropdown-sw-products"
        inputId="sw-products"
        options={compatibleSoftwareProductOptions}
        onChange={handleProductChange}
        selectedValues={store.getCompatibleSoftwareProducts()}
        isSearchable={false}
      />
    </div>
  );
});
