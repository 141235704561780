import { action } from "mobx";
import { AbstractCookieStore } from "./abstractCookieStore";

/**
 * Base class for stores that track changes in cookies, where data is stored as JSON.
 */
export abstract class AbstractJSONCookieStore extends AbstractCookieStore {
  /**
   * Reads the cookie and parses it as JSON object
   */
  protected parseCookie(): any {
    let jsonData = {};
    try {
      jsonData = JSON.parse(this.cookieHandle.value);
    } catch {
      console.log("Error parsing JSON from cookie");
    }
    return jsonData;
  }

  /**
   * Stores data in the cookie
   * @param jsonData JSON data
   */
  @action
  protected storeInCookie(jsonData: object) {
    this.cookieHandle.set(JSON.stringify(jsonData));
  }
}
