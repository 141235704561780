import { ObjectTypeEnum, SourceEnum, VisibilityEnum } from "../../models/enums";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IEntity } from "../../models/dataModel";

/**
 * Creates a Package/IEntity object.
 * @param {Object} data - The data object containing package information.
 * @returns {IEntity} - The created Package/IEntity object.
 */
export function Package(data) {
  data = data || {};
  data.attributes = data.attributes || {};

  var pack = {
    _id: data._id || data.id,
    id: data._id || data.id,
    title: data.title,
    codeName: data.codeName || undefined,
    description: data.description,
    source: data.source || SourceEnum.UNKNOWN,
    type: data.type || ObjectTypeEnum.UNKNOWN,
    tags: data.tags || [],
    collection: data.collection,
    related: data.related,
    creator: data.creator,
    modifier: data.modifier,
    modifiedAt: data.modifiedAt,
    createdAt: data.createdAt,
    currentUserRating: data.currentUserRating,
    reviewCount: data.reviewCount,
    isLocal: data.isLocal || false,
    isHidden: data.isHidden || false,
    isBanned: data.isBanned || false,
    isArchived: data.isArchived || false,
    isImmutable: data.isImmutable || false,
    visibility: data.visibility || VisibilityEnum.PUBLIC,
    releasenote: data.releasenote,
    richContent: data.richContent || "",
    copyrightInfo: data.copyrightInfo,
    translations: data.translations,
    versionCount: data.versionCount || 0,
    views: data.views || 0,
    downloads: data.downloads || 0,

    attributes: {
      licensesACL: data.attributes.licensesACL || undefined,
      helpUrl: data.attributes.helpUrl,
      supportUrl: data.attributes.supportUrl,
      discussionForumUrl: data.attributes.discussionForumUrl,
      specialTermsUrl: data.attributes.specialTermsUrl,
      copyrightInfo: data.attributes.copyrightInfo,
      measurementUnits: data.attributes.measurementUnits || [],
      testedVersions: data.attributes.testedVersions || [],
      itemTypeCategories: data.attributes.itemTypeCategories || [],
      useCategories: data.attributes.useCategories || [],
      locationRestrictions: data.attributes.locationRestrictions || [],
      supportedLanguages: data.attributes.supportedLanguages || [],
      ratings: data.attributes.ratings || [],
      defaultThumbnail: data.attributes.defaultThumbnail,
      videourls: data.attributes.videourls,
      contentDownloadUrl: data.attributes.contentDownloadUrl || undefined,
      bannedDate: data.attributes.bannedDate || undefined,
    },
    accessRole: data.accessRole,
  };

  if (data.allowComments) {
    pack.allowComments = data.allowComments;
  }

  if (pack.isLocal) {
    pack.thumbnail = data.thumbnail;
  } else {
    pack.binaries = data.binaries;
    pack.thumbnails = data.thumbnails;
    pack.thumbnails3d = data.thumbnails3d;
    pack.thumbnails_details = data.thumbnails_details;
  }

  return pack;
}
