import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";
import { SearchDataConverter } from "../converters/SearchDataConverter";

export const TCCSearchDS = {
  searchAutocompleteSuggestions: function (data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/suggestions", data));
  },
  searchAutocompleteSuggestionsWithSelectedLanguage: function (data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/suggestions", SearchDataConverter.addParameters(data)),
    );
  },
};
