import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStore, EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { PackagePageStore, PackagePageStoreContext } from "../packagePageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { EditGroupAndCategory } from "../dialogs/EditGroupAndCategory";

/**
 * A component for editing group and category information
 */
export const GroupAndCategory = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function canEdit(): boolean {
    return packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function attributeExists(key: string): boolean {
    return !!packageItem && !!packageItem.attributes && packageItem.attributes[key].length > 0;
  }

  function getAttributeTranslations(attributeName: string): string {
    const translations: string[] = [];
    if (packageItem) {
      _.map(packageItem.attributes![attributeName], (attribute: string) => {
        translations.push(rootStore.getTranslation(attributeName + "." + attribute));
      });
    }
    return translations.join(", ");
  }

  return (
    <Fragment>
      <div />
      {canEdit() && (
        <DialogContext.Provider value={new DialogStore()}>
          <EditGroupAndCategory />
        </DialogContext.Provider>
      )}
      {attributeExists("itemTypeCategories") && (
        <div className="group-category-item">
          <span className="label">{rootStore.getTranslation("details.group")}</span>
          <span data-testid="item-type-category">{getAttributeTranslations("itemTypeCategories")}</span>
        </div>
      )}
      {attributeExists("useCategories") && (
        <div className="group-category-item">
          <span className="label">{rootStore.getTranslation("details.category")}</span>
          <span data-testid="use-category">{getAttributeTranslations("useCategories")}</span>
        </div>
      )}
    </Fragment>
  );
});
