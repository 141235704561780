/* eslint-disable @typescript-eslint/no-unused-vars */
import { contentIdFilter } from "../../utils/filters";
import { ModelTypeConverter } from "../converters/ModelTypeConverter";
import { PackageConverter } from "../converters/PackageConverter";
import { TCCCollectionDS } from "../data-source/TCCCollectionDS";
import { TCCPackageDS } from "../data-source/TCCPackageDS";
import { TCCAclService } from "../services/TCCAclService";
import { TCCLongRunningActionsService } from "../services/TCCLongRunningActionsService";

import { IBatchEntity, IEntity, IEntityPayload } from "../../models/dataModel";
import { ContentTypeEnum, ObjectTypeEnum } from "../../models/enums";
import { ITCCEntity, ITCCPackage } from "../../models/TCCdataModel";

export const TCCPackageService = {
  /**
   * Creates a package, then copies the ACL from the parent collection for the created collection
   * @param {*} data
   * @returns the created package
   */
  create: function (data) {
    return new Promise((resolve, reject) => {
      var packageId = "";
      try {
        TCCPackageDS.createPackage(PackageConverter.toTCC(data), data.creator.id)
          .then(function (res) {
            packageId = contentIdFilter(res["location"]);
            var list = [];
            list.push(packageId);
            return TCCCollectionDS.updateCollectionChildren(data.collection.id, {
              childIds: list,
            });
          })
          .then(function (res) {
            return TCCAclService.cloneAcl(
              data.collection.id,
              ModelTypeConverter.toSubjectClass(data.collection.type),
              packageId,
              ModelTypeConverter.toSubjectClass(ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE),
              data.creator.id,
            );
          })
          .then(function (res) {
            TCCPackageDS.getPackage(packageId, { performAs: data.creator.id }).then(
              function (packageItem) {
                resolve(PackageConverter.fromTCC(packageItem));
              },
              function (err) {
                setTimeout(function () {
                  TCCPackageDS.getPackage(packageId, { performAs: data.creator.id }).then(
                    function (packageItem) {
                      resolve(PackageConverter.fromTCC(packageItem));
                    },
                    function (err) {
                      reject();
                    },
                  );
                }, 2000);
              },
            );
          });
      } catch (err) {
        reject();
      }
    });
  },
  /**
   * Updates the package with given data
   * @param {IEntityPayload | IEntity | IBatchEntity} data
   * @param {boolean} useRecursive
   * @returns the updated package
   */
  replace: function (data, useRecursive = false) {
    var convertedData = PackageConverter.toTCC(data);
    var queryParams = { performAs: data.modifier.id };
    if (data.doNotNotify) {
      queryParams = { ...queryParams, doNotNotify: true };
    }
    return TCCPackageDS.setPackage(convertedData, queryParams)
      .then(function (res) {
        if (useRecursive) {
          return TCCLongRunningActionsService.setVisibilityInChildren(data);
        }
      })
      .then(function (res) {
        return TCCPackageDS.getPackage(convertedData.id, { performAs: data.modifier.id });
      })
      .then(PackageConverter.fromTCC);
  },
  /**
   * Updates the package with given data
   * @param {Object} data
   * @param {boolean} [useRecursive]
   * @param {boolean} [overrideChildVisibility]
   * @returns the updated package
   */
  update: function (data, useRecursive, overrideChildVisibility = true) {
    var convertedData = PackageConverter.toTCCPartial(data);
    var queryParams = {};
    if (data.doNotNotify) {
      queryParams = { ...queryParams, doNotNotify: true };
    }
    if (data.modifier) {
      queryParams = { ...queryParams, performAs: data.modifier.id };
    }
    return TCCPackageDS.updatePackage(convertedData, queryParams)
      .then(function () {
        if (!!useRecursive) {
          return TCCLongRunningActionsService.setVisibilityInChildren({
            ...data,
            type: ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE,
          }, overrideChildVisibility);
        }
      })
      .then(function () {
        return new Promise((resolve) =>
          setTimeout(() => {
            let pack = TCCPackageDS.getPackage(convertedData.id, queryParams);
            resolve(pack);
          }, 1500),
        );
      })      
      .then(PackageConverter.fromTCC);
  },
  removeFromCollection: function (data) {
    return TCCCollectionDS.deleteCollectionChild(data.parentCollectionId, data.childId);
  },
  /**
   * @returns {Promise<{entries: ITCCPackage[], startRow: number, total: number, endRow: number }>}
   */
  searchPackages: function (data, dontUnwrap) {
    return TCCPackageDS.searchPackages(data, dontUnwrap);
  },
  searchRecommendedPackages: function (data, dontUnwrap) {
    return TCCPackageDS.searchRecommendedPackages(data, dontUnwrap);
  },
  /**
   * Fetches a package and its binaries from backend
   * @param {string} packageId id of the package to get
   * @param {string?} performAsId id of the current user
   * @param {{ recordEvent: boolean }?} params additional parameters
   * @returns {Promise<IEntity>} a package
   */
  get: function (packageId, performAsId, params) {
    if (performAsId) params = { ...(params || {}), performAs: performAsId };
    return TCCPackageDS.getPackage(packageId, params).then(function (data) {
      return TCCPackageDS.getBinaries(data.id, params).then(function (binaries) {
        data.binaries = binaries;
        return PackageConverter.fromTCC(data);
      });
    });
  },
  /**
   * Fetches a package and its binaries from backend using codeName
   * Returns null if package not found
   * @param {string} codeName codeName of the package to get
   * @param {object} params additional parameters
   * @returns {Promise<IEntity | null>} a package
   */
  getByCodeName: function (codeName) {
    const params = { showBinaryAttributes: true, showHidden: true };
    return TCCPackageDS.getPackageByExternalId(codeName, params).then(
      function (data) {
        return PackageConverter.fromTCC(data);
      },
      function () {
        return null;
      },
    );
  },
  remove: function (data, editorId) {
    return TCCPackageDS.deletePackage(data, editorId);
  },
  addToPackage: function (data) {
    return TCCPackageDS.setPackageContains(data);
  },
  addReview: function (packageId, review) {
    return TCCPackageDS.addReview(packageId, review);
  },
  deleteReview: function (packageId) {
    return TCCPackageDS.deleteReview(packageId);
  },
  getReviews: function (packageId) {
    return TCCPackageDS.getReviews(packageId);
  },
  getSubscribers: function (packageId) {
    return TCCPackageDS.getSubscribers(packageId);
  },
  getAttributes: function (packageId) {
    return TCCPackageDS.getAttributes(packageId);
  },
  setAttributes: function (packageId, data) {
    return TCCPackageDS.setAttributes(packageId, data);
  },
  updateAttributes: function (packageId, data, doNotNotify, performAsId) {
    var queryParams = {};
    if (doNotNotify) {
      queryParams = { ...queryParams, doNotNotify: true };
    }
    if (performAsId) {
      queryParams = { ...queryParams, performAs: performAsId };
    }

    return TCCPackageDS.updateAttributes(packageId, data, queryParams);
  },
  addToCollection: function (data) {
    var list = [];
    list.push(data.childId);
    return TCCCollectionDS.updateCollectionChildren(data.parentCollectionId, {
      childIds: list,
    });
  },
  getAcl: function (packageId, data) {
    return TCCPackageDS.getAcl(packageId, data);
  },
  setAcl: function (packageId, data) {
    return TCCPackageDS.setAcl(packageId, data);
  },
  createBinary: function (packageId, data, file) {
    return TCCPackageDS.createBinary(packageId, data, file);
  },
  createBinaryWithMime: function (packageId, data, file, fileName, mimeType) {
    return TCCPackageDS.createBinaryWithMime(packageId, data, file, fileName, mimeType);
  },
  /**
   * Fetches all binaries of a package from backend
   * @returns {Promise<{entries: ITCCBinary[], startRow: number, total: number, endRow: number }>
   */
  getBinaries: function (packageId, performAsId) {
    return TCCPackageDS.getBinaries(packageId, !!performAsId ? { performAs: performAsId } : {});
  },
  /**
   * Fetches all entities of a package from backend
   * @returns {Promise<ITCCEntity[]>
   */
  getEntities: function (packageId) {
    var data = { contentType: ContentTypeEnum.TEKLA_WAREHOUSE, showBinaryAttributes: true, showAttributes: true };
    return TCCPackageDS.getEntities(packageId, data);
  },
  getBinary: function (packageId, binaryName, data) {
    return TCCPackageDS.getBinary(packageId, binaryName, data);
  },
  copyBinary: function (targetPackageId, binaryData) {
    return TCCPackageDS.copyBinary(targetPackageId, binaryData);
  },
  getBinaryData: function (url) {
    return TCCPackageDS.getBinaryData(url);
  },
  updateBinary: function (packageId, data, file, fileName) {
    return TCCPackageDS.updateBinary(packageId, data, file, fileName);
  },
  deleteBinary: function (packageId, binaryName) {
    return TCCPackageDS.deleteBinary(packageId, binaryName);
  },
  addComment: function (packageId, commentData, performAsId) {
    return TCCPackageDS.addComment(packageId, commentData, performAsId);
  },
  getComments: function (packageId, sortOptions, dontUnwrap) {
    return TCCPackageDS.getComments(packageId, sortOptions, dontUnwrap);
  },
  updateComment: function (packageId, commentId, commentText, performAsId) {
    return TCCPackageDS.updateComment(packageId, commentId, commentText, performAsId);
  },
  deleteComment: function (packageId, commentId, performAsId) {
    return TCCPackageDS.deleteComment(packageId, commentId, performAsId);
  },
  createBinary: function (packageId, data, file) {
    return TCCPackageDS.createBinary(packageId, data, file);
  },
  createBinaryWithMime: function (packageId, data, file, fileName, mimeType) {
    return TCCPackageDS.createBinaryWithMime(packageId, data, file, fileName, mimeType);
  },
};
