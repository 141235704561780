import { action, observable, runInAction, makeObservable } from "mobx";
import { IItem } from "../../models/dataModel";
import { RootStore, createStoreContext } from "../../stores/rootStore";
import { AbstractAsyncStore } from "../../stores/abstractAsyncStore";
import { NewRepository } from "../../js/services/NewRepository";

/**
 * Subscribe store.
 */
export class SubscribeStore extends AbstractAsyncStore {
  /**
   * Content item.
   */
  @observable private content: IItem | undefined;

  /**
   * Subscription status.
   */
  @observable private hasSubscribedContent = false;

  /**
   * Subscription count.
   */
  @observable private subscriberCount = 0;

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore, content?: IItem) {
    super(rootStore);
    makeObservable(this);
    if (content) {
      runInAction(() => {
        this.content = content;
        this.fetchData();
      });
    }
  }

  /**
   * Fetches users subscription data for content
   */
  @action
  public async fetchData() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!this.content && !!user) {
      this.loading = true;
      try {
        const res = await NewRepository.hasUserSubscribed(this.content, user);
        this.updateSubscriptionCount();
        runInAction(() => {
          this.hasSubscribedContent = res.subscribed;
          this.loading = false;
        });
      } catch {
        console.log("Failed to fetch subscription data");
      }
    }
  }

  /**
   * Gets content item
   * @returns content item object
   */
  public getContent(): IItem | undefined {
    return this.content ? this.content : undefined;
  }

  /**
   * Method for resolving if user has subcsribed content notifications
   * @returns true if user has subcsribed content
   */
  public hasSubscribed(): boolean {
    return this.hasSubscribedContent;
  }

  /**
   * Method for resolving if data is loading
   * @returns true if loading
   */
  public isLoading(): boolean {
    return this.loading;
  }

  /**
   * Method for resolving content subscription count
   * @returns number of subscription
   */
  public getSubscriberCount(): number {
    return this.subscriberCount;
  }

  /**
   * Method for subscribing content
   */
  @action
  public async subscribe() {
    if (this.content) {
      this.loading = true;
      await NewRepository.subscribe(this.content);
      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("details.alerts.subscribed_successfully"));

      this.updateSubscriptionCount();
      runInAction(() => {
        this.hasSubscribedContent = true;
        this.loading = false;
      });
    }
  }

  /**
   * Method for unsubscribing content
   */
  @action
  public async unSubscribe() {
    if (this.content) {
      this.loading = true;
      await NewRepository.unsubscribe(this.content);
      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("details.alerts.unsubscribed_successfully"));

      this.updateSubscriptionCount();
      runInAction(() => {
        this.hasSubscribedContent = false;
        this.loading = false;
      });
    }
  }

  @action
  private async updateSubscriptionCount() {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!this.content && !!user) {
      const subcriberCount = await NewRepository.getSubscriberCount(this.content, user);
      runInAction(() => {
        this.subscriberCount = subcriberCount || 0;
      });
    }
  }
}

export const SubscribeStoreContext = createStoreContext<SubscribeStore>(SubscribeStore);
