import { Options } from "@pnotify/core";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
import { action, makeObservable, observable } from "mobx";
import _ from "underscore";

import {
  ICollection,
  IEntity,
  IOrganization,
  IResource,
  IUser,
  IUserOrganization,
  IUserOrganizationProperties,
} from "../models/dataModel";
import {
  AccessRoleEnum,
  ExternalResourceTypeEnum,
  ObjectTypeEnum,
  OrganizationRoleEnum,
  VisibilityEnum,
} from "../models/enums";

import { RootStore } from "./rootStore";

import { Settings } from "../config/Settings";
import { TCCUserDS } from "../js/data-source/TCCUserDS";
import { Creator } from "../js/factories/Creator";
import { TCCUserFlagsService } from "../js/services/TCCUserFlagsService";

/**
 * Store for handling user information.
 */
export class UserStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * User information for current user.
   */
  @observable private currentUser: IUser | undefined;

  /**
   * Account validation error
   */
  private accountValidationError = "";

  /**
   * Default profile pic url
   */
  public static readonly DEFAULT_PROFILE_PIC = "https://account.tekla.com/images/defaultProfilePic.svg";

  /**
   * Retrieves user information from rootStore.
   */
  @action
  public fetchData = () => {
    const oldUser = this.currentUser;
    const userItem = localStorage.getItem(Settings.auth.currentUserLocalStorageName);
    if (userItem) {
      this.currentUser = JSON.parse(userItem);
      if (!oldUser && this.currentUser) {
        this.rootStore.getNotificationsStore().fetchData();
      }
    } else this.currentUser = undefined;
  };

  /**
   * Method for accessing user information.
   * @return the user.
   */
  public getCurrentUser(): IUser | undefined {
    return this.currentUser;
  }

  /**
   * Checks if a user is logged in.
   * @returns True/False.
   */
  public isUserLoggedIn(): boolean {
    return !!this.currentUser;
  }

  /**
   * Checks if the current user is an internal user in the given organization.
   * @param organizationId - The organization to check.
   * @returns True/False.
   */
  public isInternalUserInOrganization(organizationId: string): boolean {
    return (
      !!this.currentUser &&
      !!this.currentUser.userOrganizations &&
      _.any(
        this.currentUser.userOrganizations,
        (uo: IUserOrganization) =>
          uo.organization.id === organizationId &&
          (uo.roles.includes(OrganizationRoleEnum.MEMBER) || uo.roles.includes(OrganizationRoleEnum.EMPLOYEE)),
      )
    );
  }

  /**
   * Checks if the user has the role 'admin' as their role.
   * @returns True/False.
   */
  public isUserAdmin(): boolean {
    return !!this.currentUser && !!this.currentUser.roles && _.contains(this.currentUser.roles, "admin");
  }

  /**
   * Checks if the user has the role 'sales' as their role.
   * @returns True/False.
   */
  public isUserSalesAdmin(): boolean {
    return !!this.currentUser && !!this.currentUser.roles && _.contains(this.currentUser.roles, "sales");
  }

  /**
   * Checks if the user has the role 'analyst' as their role.
   * @returns True/False.
   */
  public isUserAnalyst(): boolean {
    return !!this.currentUser && !!this.currentUser.roles && _.contains(this.currentUser.roles, "analyst");
  }

  /**
   * Checks if the current user has access to maintenance content.
   * @return True/False. Returns false also if no user is signed in.
   */
  public hasMaintenance(): boolean {
    if (!!this.currentUser && !!this.currentUser.attributes && !!this.currentUser.attributes.licenses) {
      return this.currentUser.attributes.licenses.includes("teklamaintenance");
    } else return false;
  }

  /**
   * Checks hasSeenTos flag
   * @returns hasSeenTos flag
   */
  public hasSeenTOS(): boolean {
    return this.attributeExists(this.currentUser, this.getHasSeenTOSFlagName());
  }

  /**
   * Sets hasSeenTos flag
   * @param value boolean flag
   */
  public setHasSeenTOSFlag(value: boolean): Promise<unknown> {
    return TCCUserFlagsService.setBoolean(this.currentUser, this.getHasSeenTOSFlagName(), value);
  }

  /**
   * Checks if the current user has organizations under their userOrganizations list.
   * @return True/False.
   */
  public hasOrganizations(): boolean {
    return !!this.currentUser && !!this.currentUser.userOrganizations && this.currentUser.userOrganizations.length > 0;
  }

  /**
   * Retrieves the organizations associated with the current user.
   * @returns An array of IUserOrganization objects representing the user's organizations.
   */
  public getOrganizations(): IUserOrganization[] {
    return !!this.currentUser && !!this.currentUser.userOrganizations ? this.currentUser.userOrganizations : [];
  }

  /**
   * Retrieves the organization to which the current user has edit rights.
   * @returns an array of user organizations.
   */
  public getOrganizationsWithEditRights(): IUserOrganizationProperties[] {
    return !!this.currentUser && !!this.currentUser.userOrganizations
      ? _.filter(this.currentUser.userOrganizations, (uo: IUserOrganization) =>
        this.canEditOrganization(uo.organization),
      ).map((uo: IUserOrganization) => uo.organization)
      : [];
  }

  /**
   * Retrieves the organizations to which an user has edit rights as creators for a given user.
   * If the user has userOrganizations, it maps each organization with editor rights to a Creator object.
   * If the user is not defined or has no organizations, an empty array is returned.
   *
   * @paramuser - The user object.
   * @returns an array of Creator objects.
   */
  public getOrganizationsWithEditRightsAsCreators() {
    return _.map(this.getOrganizationsWithEditRights(), (organization: IUserOrganizationProperties) =>
      Creator(organization, ExternalResourceTypeEnum.ORGANIZATION),
    );
  }

  /**
   * Returns the ATC url.
   * @return The ATC url.
   */
  public getATCUrl(): string {
    return Settings.atc.apiEndpoint;
  }

  /**
   * Method for getting the profile thumbnail URL from ATC.
   * Since ATC no longer stores the profile picture, this method returns the default image.
   */
  public getProfileThumbnailUrl(): string {
    return UserStore.DEFAULT_PROFILE_PIC;
  }

  /**
   * Method for setting default profile pic.
   */
  public setDefaultThumbnailUrl(event) {
    event.target.src = UserStore.DEFAULT_PROFILE_PIC;
  }

  /**
   * Checks if admin mode is enabled is for user
   * @returns True/False.
   */
  public isAdminModeEnabled(): boolean {
    return !!this.currentUser && this.currentUser.adminMode ? this.currentUser.adminMode : false;
  }

  /**
   * Method for setting admin mode on/off
   */
  @action
  public setAdminMode(mode) {
    if (!!this.currentUser) {
      this.currentUser.adminMode = mode;
    }
  }

  /** Checks if current user belongs to an organization.
   * @param organization Organization info
   * @param user User info
   * @return True if user belongs to organization, otherwise - false.
   */
  public belongsToOrganization(resource: IResource | IOrganization | IUserOrganizationProperties): boolean {
    const organizationId = this.getOrganizationId(resource);
    return (
      !_.isUndefined(organizationId) && !_.isUndefined(resource) && this.isInternalUserInOrganization(organizationId)
    );
  }

  /**
   * Checks if current user can edit specified organization.
   * @param organization Organization info
   * @return True if user can edit organization, otherwise false.
   */
  public canEditOrganization(
    organization: IOrganization | IUserOrganizationProperties | IUserOrganizationProperties,
  ): boolean {
    return this.isUserAdmin() || this.isContentEditorForOrganization(organization);
  }

  /**
   * Checks if current user is content editor for the given organization.
   * @param resource organization
   * @returns true/false
   */
  public isContentEditorForOrganization(resource: IResource | IOrganization | IUserOrganizationProperties): boolean {
    let org;
    if (!!this.currentUser && !!this.currentUser.userOrganizations) {
      org = _.find(this.currentUser.userOrganizations, (item: IUserOrganization) => {
        return (
          item.organization.id == this.getOrganizationId(resource) &&
          (_.contains(item.roles, OrganizationRoleEnum.CONTENT_EDITOR) ||
            _.contains(item.roles, OrganizationRoleEnum.MEMBER))
        );
      });
    }
    return !_.isUndefined(org);
  }

  /**
   * Checks if user can edit access rights for resourcce
   * @param resource The resource to be checked
   * @return True if user can edit access rights
   */
  public canEditAccessRights(collection: ICollection): boolean {
    const isOwnedByOrganization =
      !!collection.creator && collection.creator.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION;

    return (
      !this.isLocal(collection as IResource) &&
      (this.isUserAdmin() ||
        this.isCreator(collection as IResource) ||
        (isOwnedByOrganization && this.isContentEditorForOrganization(collection as IResource)))
    );
  }

  /**
   * Checks if user can see analytics
   * @param resource The resource to be checked
   * @return True if user can see analytics
   */
  public canSeeAnalytics(resource: IResource): boolean {
    const organizationId = this.getOrganizationId(resource);
    return (
      !this.isLocal(resource) &&
      (this.isUserAdmin() ||
        this.isCreator(resource) ||
        (!!organizationId && this.isInternalUserInOrganization(organizationId)) ||
        this.isUserAnalyst())
    );
  }

  /**
   * Checks if user can see modifier information
   * @param packageItem The package to be checked
   * @return True if user can see modifier
   */
  public canSeeModifier(packageItem: IEntity): boolean {
    return (
      this.isUserAdmin() ||
      (!this.isCreator(packageItem as IResource) &&
        this.isPublic(packageItem as IResource) &&
        packageItem.creator!.externalResourceType == ExternalResourceTypeEnum.USER) ||
      this.isCreatorOrBelongsToOrganization(packageItem as IResource)
    );
  }

  /**
   * Checks if user can see versions that are still in scan process
   * @param resource The resource to be checked
   * @return True if user can see scan results
   */
  public canSeeVersionsStillScanning(resource: IResource): boolean {
    return !!resource && this.isUserAdmin();
  }

  /**
   * Checks if current user can edit specified resource.
   * @param resource The resource object to be checked
   * @return True if user can edit resource, otherwise false.
   */
  public canEditResource(resource: IResource): boolean {
    return (
      this.isUserAdmin() ||
      this.isLocalAndNotPreinstalled(resource) ||
      this.isCreator(resource) ||
      this.isEditor(resource)
    );
  }

  /**
   * Checks if current user can access specified resource.
   * @param resource The resource object to be checked
   * @return True if user can edit resource, otherwise false.
   */
  public canAccessResource(resource: IResource): boolean {
    return (
      this.isUserAdmin() ||
      this.isLocal(resource) ||
      this.isPublic(resource) ||
      this.isCreatorOrBelongsToOrganization(resource) ||
      this.isEditor(resource) ||
      this.isViewer(resource)
    );
  }

  /**
   * Checks if current user can download given entity
   * @param entity The package to check
   * @return True if user can edit entity, otherwise false.
   */
  public canDownload(entity: IEntity): boolean {
    if (
      this.isUserAdmin() ||
      this.isLocal(entity as IResource) ||
      this.isCreatorOrBelongsToOrganization(entity as IResource) ||
      this.isEditor(entity as IResource)
    ) {
      return true;
    } else if (this.licenseRequired(entity)) {
      return this.hasMaintenance();
    } else if (this.isPublic(entity as IResource) || this.isViewer(entity as IResource)) {
      return true;
    }
    return false;
  }

  /**
   * Checks if current user can upload content to collection
   * @param collection The collection to check
   * @return True if user can upload, otherwise false.
   */
  public canUpload(collection: ICollection): boolean {
    return (
      this.isUserAdmin() ||
      this.isLocalAndNotPreinstalled(collection as IResource) ||
      this.isCreator(collection as IResource) ||
      this.isEditor(collection as IResource)
    );
  }

  /**
   * Checks if user is creator of resource
   * @param resource The resource to be checked
   * @return True if user is creator
   */
  public isCreator(resource: IResource): boolean {
    return (
      !!resource &&
      !!this.currentUser &&
      !!this.currentUser.id &&
      !!resource.creator &&
      !!resource.creator.id &&
      resource.creator.id === this.currentUser.id
    );
  }

  /**
   * Checks if auto login should be made
   * @returns true if login should be made
   */
  public shouldDoLogin(): boolean {
    return (
      this.accountValidationError == "" && Settings.auth.sso && this.validATCCookieExists() && !this.get3dwTicket()
    );
  }

  /**
   * Displays the notification for 2024 spring user feedback survey
   */
  public showSurveyNotification(): void {
    const options: Options = {
      text: this.rootStore.getTranslation("popups.survey.help_us_improve_html"),
      textTrusted: true,
      title: this.rootStore.getTranslation("popups.survey.give_us_feedback"),
      titleTrusted: true,
      delay: 10000,
      addClass: "survey-notification",
      width: "320px",
      icons: {
        closer: "icon-remove white",
      },
    };

    if (this.shouldShowSurveyNotification())
      this.rootStore.getNotificationChannelStore().show(options, this.surveyNotificationCallback);
  }

  /**
   * Performs login for user
   */
  public login(url?: string) {
    this.removeCurrentUser();
    TCCUserDS.authorizeWithTekla(url);
  }

  /**
   * Performs logout for user
   */
  public logout() {
    this.removeCurrentUser();
    TCCUserDS.teklaLogoff();
  }

  /**
   * Checks if valid ATC login cookie exists for user
   */
  public validATCCookieExists(): boolean {
    const atcTicket = Settings.ATCTicketCookieName;
    const atcCookie = Cookies.get(atcTicket);
    if (!!atcCookie) {
      const ts = DateTime.fromISO(atcCookie.replace(/\"/g, "")); // strip possible douple quotes
      return ts.isValid && DateTime.now() < ts;
    }
    return false;
  }

  /**
   * Returns 3dw ticket read from the cookie
   * @returns 3dw ticket
   */
  public get3dwTicket(): string {
    if (this.currentUser && this.currentUser.cookies) {
      const cookie = _.find(this.currentUser.cookies, (cookie) => {
        return cookie.name === Settings.tcc.APITicketCookieName;
      });
      if (cookie) {
        return cookie.value;
      }
    }
    return "";
  }

  /**
   * Redirects user to ATC for registering
   */
  public register() {
    window.location.assign(this.getATCUrl());
  }

  /**
   * Sets account validation error
   * @params accountError The error that has occurred while validating account
   */
  @action
  public setAccountValidationError = (accountError: string) => {
    this.accountValidationError = accountError;
    this.rootStore.getRouter().changeRoutingState("/landing");
  };

  /**
   * Gets account validation error
   */
  public getAccountValidationError(): string {
    return this.accountValidationError;
  }

  private removeCurrentUser() {
    localStorage.clear();
    Cookies.remove(Settings.defaultSearchTypeCookieName);
  }

  private shouldShowSurveyNotification(): boolean {
    return Settings.surveys.show012024 && !Cookies.get("hideTWHSurvey-012024");
  }

  private surveyNotificationCallback = (notice) => {
    const showSurv = document.querySelector("#showSurvey");
    if (showSurv) {
      showSurv.addEventListener(
        "click",
        () => {
          window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSdBNiQ6f0rYUd-PnWftq95fWlQSprSw2Xh6plW1w8s4saansQ/viewform",
            "_blank",
          );
          notice.close();
        },
        { passive: true },
      );
    }
    const dontShowSurv = document.querySelector("#dontShow");
    if (dontShowSurv) {
      dontShowSurv.addEventListener(
        "click",
        () => {
          Cookies.set("hideTWHSurvey-012024", "true");
          notice.close();
        },
        { passive: true },
      );
    }
  };

  /* Checks if given attributes exist on user attributes
   * @param user The user which attributes should be checked
   * @param expectedKey The attribute key that should be searched
   * @return True if attribute exists, otherwise false
   */
  private attributeExists(user, expectedKey): boolean {
    return (
      user.attributes &&
      user.attributes.flags &&
      !!_.findKey(user.attributes.flags, (value, key) => {
        return key === expectedKey && (value.value === true || value === true);
      })
    );
  }

  /**
   * Gets TOS flag name
   * @return string representin TOS flag name
   */
  private getHasSeenTOSFlagName(): string {
    return `hasSeenTos${DateTime.now().toFormat("yyyy")}`;
  }

  /**
   * Private helper method to resolve thumbnail base url.
   * @return The base url.
   */
  private resolveThumbnailBaseUrl(atcUrl): string {
    const endInd = atcUrl.indexOf(".com");
    return atcUrl.substring(0, endInd + 4);
  }

  /**
   * Checks if given resource is local
   * @param resource The resource to be checked
   * @return True if reource is local
   */
  private isLocal(resource: IResource): boolean {
    return !!resource.isLocal;
  }

  /**
   * Checks if given resource is local and not preinstalled
   * @param resource The resource to be checked
   * @return True if reource is local and not preinstalled
   */
  private isLocalAndNotPreinstalled(resource: IResource): boolean {
    return !!this.isLocal(resource) && !resource.isPreinstalled;
  }

  /**
   * Checks if user is owner of resource or belongs to organization
   * @param resource The resource to be checked
   * @return True if user is owner or belongs to organization
   */
  private isCreatorOrBelongsToOrganization(resource: IResource): boolean {
    return this.isCreator(resource) || this.belongsToOrganization(resource);
  }

  /**
   * Checks if user has editor role for resource
   * @param resource The resource to be checked for role
   * @return True if role found, otherwise false.
   */
  private isEditor(resource: IResource) {
    return (
      (!!resource && resource.accessRole === AccessRoleEnum.EDITOR) || this.isContentEditorForOrganization(resource)
    );
  }

  /**
   * Checks if user has viewer role for resource
   * @param resource The resource to be checked for role
   * @return True if role found, otherwise false.
   */
  private isViewer(resource: IResource): boolean {
    return !!resource && (AccessRoleEnum.VIEWER === resource.accessRole || this.isEditor(resource));
  }

  /**
   * Checks if license is required for given
   * @param entity The entity to be checked
   * @return True if license is required, otherwise false.
   */
  private licenseRequired(entity: IEntity): boolean {
    return !!entity.attributes && !!entity.attributes.licensesACL;
  }

  /**
   * Checks if given resource is public
   * @param resource The resource to be checked
   * @return True if resource is public, otherwise false.
   */
  private isPublic(resource: IResource): boolean {
    return !!resource.visibility && resource.visibility === VisibilityEnum.PUBLIC;
  }

  private getOrganizationId(resource: IResource | IOrganization | IUserOrganizationProperties): string | undefined {
    if (_.isUndefined(resource)) return undefined;

    if (
      _.includes(
        [
          ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
          ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE,
          ObjectTypeEnum.TEKLA_WAREHOUSE_VERSION,
        ],
        (resource as IResource).type,
      )
    ) {
      if (
        !!(resource as IResource).creator &&
        !!(resource as IResource).creator?.id &&
        (resource as IResource).creator?.externalResourceType == ExternalResourceTypeEnum.ORGANIZATION
      ) {
        return (resource as IResource).creator?.id;
      }
    } else {
      return resource.id;
    }
  }
}
