import * as React from "react";
import { NaviLeft } from "./NaviLeft";
import { NaviRight } from "./NaviRight";
import { setClassWhenAtTop } from "../../utils/functions";

/**
 * A component that displays main navigation
 */
export const MainNavi = () => {
  setClassWhenAtTop("main-navigation", "fix-to-top");

  return (
    <nav id="main-navigation">
      <div className="wrapper">
        <ul className="nav-links">
          <NaviRight />
          <NaviLeft />
        </ul>
      </div>
    </nav>
  );
};
