import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";

export const TCCNotificationService = {
  getNotifications: function (data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet(
        "/warehouse/v1.0/notifications?notificationChannels=BROWSER&sortBy=createTime+DESC&count=100",
        data,
      ),
    );
  },
  setNotificationStatus: function (id, status) {
    return ResponseProcessor.process(RequestBuilder.doPatch("/warehouse/v1.0/notifications/" + id, status));
  },
};
