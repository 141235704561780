// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { IUser } from "../../models/dataModel";
import { ObjectTypeEnum, SourceEnum } from "../../models/enums";
import { UserPreferences } from "./UserPreferences";

/**
 * Creates a new user object.
 *
 * @param {Object} data - The data object containing user information.
 * @returns {IUser} - The newly created user object.
 */
export function NewUser(data) {
  data = data || {};
  data.attributes = data.attributes || {};

  return {
    id: data.id,
    email: data.email,
    displayName: data.displayName,
    ATCId: data.ATCId,
    externalId: data.externalId,
    personalInfo: data.personalInfo,
    source: data.source || SourceEnum.TCC,
    linkedResourcesCollection: data.linkedResourcesCollection,
    organization: data.organization,
    roles: data.roles,
    suspended: data.suspended,
    attributes: {
      licenses: data.attributes.licenses || [],
      flags: data.attributes.flags || [],
    },
    preferences: data.preferences || UserPreferences(),
    type: ObjectTypeEnum.TEKLA_WAREHOUSE_USER,
  };
}
