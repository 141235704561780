import { DateTime } from "luxon";
import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { Link } from "react-router";
import _ from "underscore";

import { ArchivedFlag } from "../../components/ArchivedFlag";
import { EditorModeStore, EditorModeStoreContext } from "../../components/editor-mode/editorModeStore";
import { ImmutableFlag } from "../../components/immutability/ImmutableFlag";
import { SortBy } from "../../components/search/SortBy";
import { IEntity, IVersion } from "../../models/dataModel";
import { RootContext, RootStore } from "../../stores/rootStore";
import { DownloadTools } from "../DownloadTools";
import { DownloadVersion } from "../DownloadVersion";
import { LicenseInfo } from "../LicenseInfo";
import { LoginButton } from "../LoginButton";
import { PackagePageStore, PackagePageStoreContext } from "../packagePageStore";

const VersionsAvailableInfo = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);

  return (
    <Fragment>
      {packagePageStore.hasPartnerDownloadURL() && (
        <div className="row">
          <span className="italic">
            {rootStore.getTranslation("shared.catalog_entity_edit.versions_not_shown_to_users")}
          </span>
        </div>
      )}
    </Fragment>
  );
});

const Prerequisites = observer(({ version }: { version: IVersion }) => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);

  function hasPrerequisiteLinks(): boolean {
    return !!version.prerequisites && version.prerequisites.length > 0;
  }

  return (
    <Fragment>
      {packagePageStore.versionPrerequisiteDataExists(version) && (
        <ul className="versions-table-prerequisites">
          <li>
            <span className="label">{rootStore.getTranslation("details.prerequisites")}</span>
          </li>
          {version.attributes.prerequisiteDescription && (
            <li>
              <span>{version.attributes.prerequisiteDescription}</span>
            </li>
          )}
          {hasPrerequisiteLinks() &&
            version.prerequisites!.map((entry, index) => {
              return (
                <li key={index}>
                  {entry.title && <span className="prerequisite_link">{entry.title}</span>}
                  <a href={entry.url}>{entry.url}</a>
                </li>
              );
            })}
        </ul>
      )}
    </Fragment>
  );
});

const VersionDetails = observer(({ version }: { version: IVersion }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);

  function versionAttributeExists(attributeName: string) {
    return packagePageStore.versionAttributeExists(version, attributeName);
  }

  function getVersionAttributeTranslations(attributeName: string): string {
    const translations: string[] = [];
    const translationKey =
      attributeName == "compatibleSoftwareProducts" ? "compatibleSoftwareProductOptions" : attributeName;
    _.map(version.attributes[attributeName], (attribute) => {
      translations.push(rootStore.getTranslation(translationKey + "." + attribute));
    });
    return translations.join(", ");
  }

  function getProductExpirationDate(): string {
    return !!version.attributes.productExpirationDate
      ? DateTime.fromISO(version.attributes.productExpirationDate as string).toLocaleString(DateTime.DATE_MED)
      : "";
  }

  return (
    <Fragment>
      <table>
        <tbody>
          {versionAttributeExists("compatibleOperatingSystems") && (
            <tr>
              <td data-testid="operating-systems" className="label">
                {rootStore.getTranslation("details.compatibleOperatingSystems")}
              </td>
              <td>{getVersionAttributeTranslations("compatibleOperatingSystems")}</td>
            </tr>
          )}
          {versionAttributeExists("compatibleSoftwareProducts") && (
            <tr>
              <td data-testid="compatible-products" className="label">
                {rootStore.getTranslation("details.compatibleSoftwareProducts")}
              </td>
              <td>{getVersionAttributeTranslations("compatibleSoftwareProducts")}</td>
            </tr>
          )}
          {versionAttributeExists("testedVersions") && (
            <tr>
              <td data-testid="tested-versions" className="label">
                {rootStore.getTranslation("details.testedVersions")}
              </td>
              <td>{getVersionAttributeTranslations("testedVersions")}</td>
            </tr>
          )}
          {!!version.attributes.productExpirationDate && (
            <tr>
              <td data-testid="expiration-date" className="label">
                {rootStore.getTranslation("details.productExpirationDate")}
              </td>
              <td>{getProductExpirationDate()}</td>
            </tr>
          )}
          {!!version.attributes.productCode && (
            <tr>
              <td data-testid="product-code" className="label">
                {rootStore.getTranslation("details.productCode")}
              </td>
              <td>{version.attributes.productCode}</td>
            </tr>
          )}
          {versionAttributeExists("measurementUnits") && (
            <tr>
              <td data-testid="measurement-units" className="label">
                {rootStore.getTranslation("details.measurementUnits")}
              </td>
              <td>{getVersionAttributeTranslations("measurementUnits")}</td>
            </tr>
          )}
        </tbody>
      </table>
      <Prerequisites version={version} />
    </Fragment>
  );
});

const EditVersion = observer(({ version }: { version?: IVersion }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const testId = !!version ? "edit-version-" + version.id : "edit-version";

  function getEditVersionLink(): string {
    let link = "";
    if (!!packageItem) {
      if (packageItem.isLocal) {
        link = "/catalog/editLocalVersion/" + packageItem.id;
      } else {
        link = "/catalog/editVersion/" + packageItem.id;
      }
      return !!version ? link + "/" + version.id : link;
    } else {
      return "";
    }
  }

  return (
    <Link data-testid={testId} className="icon icon-pencil edit-versions" to={getEditVersionLink()}>
      {!version && <span>{rootStore.getTranslation("details.edit.tooltips.versions")}</span>}
    </Link>
  );
});

const InstallButtons = observer(({ version }: { version: IVersion }) => {
  const rootStore = useContext(RootContext);
  const user = rootStore.getUserStore().getCurrentUser();
  const packagePageStore = useContext(PackagePageStoreContext);

  function shouldDisplayButtons() {
    return version.isLocal || !!user;
  }

  return (
    <div className="versions-install-buttons">
      {packagePageStore.shouldDisplayVersion(version) && (
        <Fragment>
          {shouldDisplayButtons() && (
            <Fragment>
              {packagePageStore.isTool(version) ? (
                <DownloadTools version={version} />
              ) : (
                <DownloadVersion version={version} />
              )}
            </Fragment>
          )}
          <ul>
            <li>
              <LicenseInfo />
            </li>
          </ul>
        </Fragment>
      )}
    </div>
  );
});

const BannedFlag = observer(({ version }: { version: IVersion }) => {
  const rootStore: RootStore = useContext(RootContext);

  function formatDate(date: Date | undefined) {
    if (!date) {
      return "";
    }
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  return (
    <Fragment>
      {rootStore.getUserStore().canEditResource(version) && !!version?.isBanned && (
        <span
          data-testid="isBannedFlag"
          style={{
            marginLeft: "1.5em",
            verticalAlign: "super",
            maxHeight: "24px",
            lineHeight: "normal",
            color: "white",
            backgroundColor: "#da212c",
            fontWeight: "bold",
            borderRadius: "5px",
          }}
        >
          {rootStore.getTranslation("profile.admin.content_manager.admin_filters.banned_content")}
          {" "}
          {!!version.attributes?.bannedDate && formatDate(new Date(version.attributes?.bannedDate))}
        </span>
      )}
    </Fragment>
  );
});


const Version = observer(({ version }: { version: IVersion }) => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem: IEntity | undefined = packagePageStore.getPackage();

  const [showDetails, setShowDetails] = useState(false);

  function canEdit(): boolean {
    return (
      editorModeStore.getEditorMode() && packagePageStore.canEditPackage() && !packagePageStore.hasPartnerDownloadURL()
    );
  }

  function getModifiedAt(version: IVersion): string {
    return !!version.modifiedAt ? DateTime.fromJSDate(version.modifiedAt).toLocaleString(DateTime.DATETIME_MED) : "";
  }

  function canSeeModifier(): boolean {
    return !!packageItem
      ? rootStore.getUserStore().canSeeModifier(packageItem) && editorModeStore.getEditorMode()
      : false;
  }

  function showVersionDetailsLink(version: IVersion): boolean {
    return attributeDataExists(version) || prerequisiteDataExists(version);
  }

  function attributeDataExists(version: IVersion): boolean {
    return packagePageStore.versionAttributeDataExits(version);
  }

  function prerequisiteDataExists(version: IVersion): boolean {
    return packagePageStore.versionPrerequisiteDataExists(version);
  }

  return (
    <li className="version">
      <div className="header">
        <div className="version-info">
          <div className="version-title">
            <h4>{version.title}</h4>
            <ImmutableFlag resource={version} />
            <ArchivedFlag resource={version} />
            <BannedFlag version={version} />
          </div>
          {!!version.isImmutable && !!version.attributes.versionNumber && (
            <div className="version-number">
              <span data-testid="versionNumber">
                {`${rootStore.getTranslation("versions.version_number")}: ${version.attributes.versionNumber}`}
              </span>
            </div>
          )}
          <div className="version-edited">
            {canEdit() && <EditVersion version={version} />}
            {!!version.modifiedAt && (
              <Fragment>
                <span className="icon created"></span>
                <span>{getModifiedAt(version)}</span>
              </Fragment>
            )}
            {canSeeModifier() && !!version.modifier && (
              <span>{rootStore.getTranslation("details.modified_by", version.modifier.displayName)}</span>
            )}
          </div>
          {showVersionDetailsLink(version) && (
            <div className="show-version-details">
              {showDetails ? (
                <a data-testid="hide-version-details" className="hide-details" onClick={() => setShowDetails(false)}>
                  {rootStore.getTranslation("details.show_version_details")}
                </a>
              ) : (
                <a data-testid="show-version-details" className="show-details" onClick={() => setShowDetails(true)}>
                  {rootStore.getTranslation("details.show_version_details")}
                </a>
              )}
            </div>
          )}
        </div>
        <InstallButtons version={version} />
      </div>
      {showDetails && <VersionDetails version={version} />}
    </li>
  );
});

const VersionList = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const versions: IVersion[] = packagePageStore.getSortedVersions();

  function versionsMissing(): boolean {
    return !packagePageStore.isLoading() && versions.length === 0 && !packagePageStore.hasPartnerDownloadURL();
  }

  return (
    <Fragment>
      {versionsMissing() ? (
        <ol data-testid="versions-missing" className="versions-missing">
          <span>{rootStore.getTranslation("details.no_versions_available")}</span>
        </ol>
      ) : (
        <ol className="versions-table" data-testid="versions-table">
          {versions.map((version: IVersion, index: number) => {
            return <Version version={version} key={index} />;
          })}
        </ol>
      )}
    </Fragment>
  );
});

/**
 * A component that displays versions tab.
 */
export const Versions = observer(() => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const editorModeStore = useContext(EditorModeStoreContext);
  const versions = packagePageStore.getVersions();

  function shouldShowVersionFilter(): boolean {
    return versions && versions.length > 1;
  }

  function canEdit(): boolean {
    return (
      editorModeStore.getEditorMode() && packagePageStore.canEditPackage() && !packagePageStore.hasPartnerDownloadURL()
    );
  }

  function getAddVersionLink(): string {
    if (packageItem) {
      if (packageItem.isLocal) {
        return "/catalog/addLocalVersion/" + packageItem.id;
      } else {
        return "/catalog/addVersion/" + packageItem.id;
      }
    } else return "";
  }

  return (
    <div className="versions">
      <div className="controls">
        <VersionsAvailableInfo />
        <div className="row">
          <LoginButton />
          <LicenseInfo />
        </div>
        <div className="row">
          {shouldShowVersionFilter() && (
            <div data-testid="sort-options" className="sort-options">
              <SortBy pageWhereUsed="versions" doNotAddUrlParams={true} />
            </div>
          )}
          {canEdit() && (
            <Fragment>
              <Link data-testid="add-version" className="button create add-version" to={getAddVersionLink()}>
                {rootStore.getTranslation("upload.version.add_version")}
              </Link>
              <EditVersion />
            </Fragment>
          )}
        </div>
        <VersionList />
      </div>
    </div>
  );
});
