import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";

import { SearchTargetOptionEnum } from "../../models/enums";

import { ResultList } from "../../components/search/ResultList";
import { RootContext, RootStore } from "../../stores/rootStore";
import { AlertTypeSelector } from "./AlertTypeSelector";
import { MyAlertsContext, MyAlertsStore } from "./myAlertsStore";

const ClearAlerts = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const notifications = rootStore.getNotificationsStore();

  async function clearAlerts() {
    notifications.clearAll();
  }

  return (
    <Fragment>
      {notifications.hasUnreadAlerts() && (
        <a className="button button-primary clear-alerts" onClick={clearAlerts}>
          {rootStore.getTranslation("profile.clear_alerts")}
        </a>
      )}
    </Fragment>
  );
});

const ContentTitle = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: MyAlertsStore = useContext(MyAlertsContext);

  const searchingForPackages = rootStore.getSearchStore().getSearchTarget() === SearchTargetOptionEnum.PACKAGE;
  const searchingForCollections = rootStore.getSearchStore().getSearchTarget() === SearchTargetOptionEnum.COLLECTION;

  return (
    <Fragment>
      {searchingForPackages && store.loadingFinished() && store.hasSubscriptions() && (
        <span className="label results-count">
          {rootStore.getTranslation("collections.entity_list.results_summary", store.contentItemSubscriptions.length)}
        </span>
      )}
      {searchingForCollections && store.loadingFinished() && store.hasSubscriptions() && (
        <span className="label results-count">
          {rootStore.getTranslation(
            "collections.collection_list.results_summary",
            store.collectionSubscriptions.length,
          )}
        </span>
      )}
    </Fragment>
  );
});

export const AlertsWrapper = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: MyAlertsStore = useContext(MyAlertsContext);

  return (
    <section className="results-container alerts-results-container" data-testid="alertsContainer">
      <div className="listing-wrapper">
        <ClearAlerts />
        <AlertTypeSelector />
        <div className="results-summary">
          {store.loadingFinished() && !store.hasSubscriptions() && (
            <span className="label results-count">{rootStore.getTranslation("profile.no_alerts")}</span>
          )}
          {rootStore.getNotificationsStore().isLoadingNotifications() ? (
            <div className="loading-indicator" data-testid={"reloadingNotifications"}>
              <div className="spinner">{rootStore.getTranslation("shared.spinner_loading")}</div>
            </div>
          ) : (
            <ContentTitle />
          )}
        </div>
        <ResultList
          pageWhereUsed="alerts"
          packages={store.contentItemSubscriptions}
          collections={store.collectionSubscriptions}
          contentFinishedLoading={store.loadingFinished()}
        />
      </div>
    </section>
  );
});
