import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";

import { ICollection } from "../../../models/dataModel";
import { ExternalResourceTypeEnum } from "../../../models/enums";

import { OnlineCollectionsContext, OnlineCollectionsStore } from "./onlineCollectionsStore";
import { RootContext, RootStore } from "../../../stores/rootStore";

export const OnlineCollectionItem = observer(({ collection }: { collection: ICollection }) => {
  const store: OnlineCollectionsStore = useContext(OnlineCollectionsContext);
  const rootStore: RootStore = useContext(RootContext);

  return (
    <div data-testid="online-collection" className="item thumb-item">
      <Link to={"/collections/online/" + collection.id} className="thumb-box thumb-box-wide">
        <figure data-testid="image" style={store.getCollectionThumbnail(collection)} />
      </Link>
      <div className="collection-item-details">
        <Link
          className={collection.visibility == "private" ? "item-title icon private" : "item-title icon"}
          title={collection.title}
          to={"/collections/online/" + collection.id}
        >
          {collection.title}
        </Link>
        <div className="author">
          <span>{rootStore.getTranslation("helpers.behalf")}</span>
          {collection.creator!.externalResourceType !== ExternalResourceTypeEnum.ORGANIZATION ? (
            <span className="user">{collection.creator!.displayName}</span>
          ) : (
            <Link className="organization" to={collection.getOrganizationPath!()}>
              {collection.creator!.displayName}
            </Link>
          )}
        </div>
        <div className="items">
          <span data-testid="icon" className="icon icon-drawer" />
          <span className="item-count">{collection.packagesCount}</span>
        </div>
      </div>
    </div>
  );
});
