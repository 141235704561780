import { URLBuilder } from "../utils/URLBuilder";
import { Settings } from "../config/Settings";
import axios from "axios";
import _ from "underscore";
import { Base64 } from "js-base64";

const TCC_API_BASIC_HTTP_AUTHORIZATION_HEADER =
  " Basic " + Base64.encode(Settings.tcc.basicAuth.username + ":" + Settings.tcc.basicAuth.password);

function buildTCCAPIAuthorizationHeaders() {
  return {
    Authorization: TCC_API_BASIC_HTTP_AUTHORIZATION_HEADER.trim(),
    "WHP-Warehouse": "twh",
  };
}

export const RequestBuilder = {
  doGet: function (path, data) {
    return axios.get(URLBuilder.buildWarehouseURL(path), {
      params: data,
      withCredentials: true,
      headers: buildTCCAPIAuthorizationHeaders(),
    });
  },
  doGetBinary: function (url) {
    return axios.get(url, {
      responseType: "blob",
      withCredentials: true,
      headers: buildTCCAPIAuthorizationHeaders(),
    });
  },
  doPost: function (path, data) {
    data = data || {};
    return axios.post(URLBuilder.buildWarehouseURL(path), data, {
      headers: _.extend(
        {
          "Content-Type": "application/json",
        },
        buildTCCAPIAuthorizationHeaders(),
      ),
      withCredentials: true,
    });
  },
  doPostWithParams: function (path, data, params) {
    data = data || {};
    return axios.post(URLBuilder.buildWarehouseURL(path), data, {
      params: params,
      headers: _.extend(
        {
          "Content-Type": "application/json",
        },
        buildTCCAPIAuthorizationHeaders(),
      ),
      withCredentials: true,
    });
  },
  doPostWithSourceHeader: function (path, source) {
    return axios.post(URLBuilder.buildWarehouseURL(path), null, {
      headers: _.extend(
        {
          "Content-Type": "application/json",
          source: source,
        },
        buildTCCAPIAuthorizationHeaders(),
      ),
      withCredentials: true,
    });
  },
  upload: function (path, data, file, performAs) {
    data = data || {};
    return axios.post(URLBuilder.buildWarehouseURL(path), data, {
      params: performAs,
      headers: _.extend(
        {
          "Content-Type": undefined,
        },
        buildTCCAPIAuthorizationHeaders(),
      ),
      withCredentials: true,
      transformRequest: function (data) {
        var fd = new FormData();
        for (var key in data) {
          var val = data[key];
          val = JSON.stringify(val);
          fd.append(key, val);
        }
        fd.append("file", file, file.name);
        return fd;
      },
    });
  },
  upload2: function (path, data, file, mime) {
    data = data || {};
    return axios.post(URLBuilder.buildWarehouseURL(path), data, {
      headers: _.extend(
        {
          "Content-Type": undefined,
        },
        buildTCCAPIAuthorizationHeaders(),
      ),
      withCredentials: true,
      transformRequest: function (data) {
        var fd = new FormData();
        for (var key in data) {
          var val = data[key];
          val = JSON.stringify(val);
          fd.append(key, val);
        }
        fd.append("file", new Blob([file], { type: mime }), data.dto.originalFileName);
        return fd;
      },
    });
  },
  doPatch: function (path, data, params) {
    data = data || {};
    return axios.patch(URLBuilder.buildWarehouseURL(path), data, {
      params: params,
      headers: _.extend(
        {
          "Content-Type": "application/json",
        },
        buildTCCAPIAuthorizationHeaders(),
      ),
      withCredentials: true,
    });
  },
  doPut: function (path, data, params) {
    data = data || {};
    return axios.put(URLBuilder.buildWarehouseURL(path), data, {
      params: params,
      headers: _.extend(
        {
          "Content-Type": "application/json",
        },
        buildTCCAPIAuthorizationHeaders(),
      ),
      withCredentials: true,
    });
  },
  doDelete: function (path, params) {
    return axios.delete(URLBuilder.buildWarehouseURL(path), {
      params: params,
      headers: buildTCCAPIAuthorizationHeaders(),
      withCredentials: true,
    });
  },
};
