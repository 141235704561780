import _ from "underscore";
import { action, observable, runInAction, makeObservable } from "mobx";

import { createStoreContext, RootStore } from "../stores/rootStore";
import { UserStore } from "../stores/userStore";
import { UploadFormStore } from "../upload/uploadFormStore";
import { UploadVersionStore, VersionUploaderViewState } from "../upload/version/uploadVersionStore";
import { UploaderStore } from "../upload/uploaderStore";

import { ScanResultsChecker } from "../utils/ScanResultsChecker";
import { Mask } from "../utils/Mask";
import { NewRepository } from "../js/services/NewRepository";

import {
  ICollection,
  IEntity,
  IVersion,
  IDropdownOption,
  ITranslationObject,
  IVersionPrerequisite,
  IResource,
  IModifier,
  IUser,
  IFileItem,
} from "../models/dataModel";
import { ItemTypeEnum, UploadStrategyEnum } from "../models/enums";

export class VersionEditorStore {
  /** Root store. */
  private rootStore: RootStore;

  /** Stores the upload form store. */
  @observable private form: UploadFormStore;

  /** List of available versions for the entity. */
  @observable private versions: IVersion[] = [];

  /** Collection the version belongs to. */
  @observable private collection?: ICollection;

  /** Entity the version belongs to. */
  @observable private entity?: IEntity;

  /** Stores the unedited version. */
  @observable private originalVersion?: IVersion;

  /** Flag that marks if the entity that the version belongs to is local. */
  @observable private isLocal?: boolean;

  /** Stores infeceted binaries */
  @observable private infectedBinaries?;

  /** The selected version option. */
  @observable private selectedVersionOption?: IDropdownOption;

  /** Stores the authorization information for the version. */
  @observable private auth = {
    canAccessPackage: false,
    canEditPackage: false,
    canDownload: false,
  };

  /** Flag that marks if the store is loading breadcrumb data. */
  @observable private loadingBreadcrumbData = true;

  /** Flag that marks if the store is loading versions data. */
  @observable private loadingVersions = true;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.form = new UploadFormStore(rootStore);
  }

  /** Returns the UploadFormStore that handles the version form data. */
  public getFormStore(): UploadFormStore {
    return this.form;
  }

  /** Returns the version form store. */
  public getVersionForm(): UploadVersionStore {
    return this.form.getVersionStore();
  }

  /** Returns the selected upload strategy, set for upload form. */
  public getStrategy(): UploadStrategyEnum {
    return this.form.getUploadStrategy();
  }

  /** Returns the entity object the version belongs to. */
  public getEntity(): IEntity {
    return this.entity as IEntity;
  }

  /** Returns the selected version dropdown option. */
  public getSelectedVersionOption(): IDropdownOption {
    return this.selectedVersionOption!;
  }

  /** Sets the selected version option. */
  @action
  public setSelectedVersion(version: IVersion) {
    if (!version) return;

    this.selectedVersionOption = { label: version.title, value: version.id };

    if (this.getStrategy() === UploadStrategyEnum.EDIT_VERSION) {
      this.originalVersion = version;
      this.getVersionForm().setVersionForEditing(version);
      this.initVersionStoreViewState();
    }

    this.getVersionForm().copyVersionMetadata(version);
  }

  /** Returns a list of existing versions for the entity. */
  public getExistingVersions(showArchived?: boolean): IVersion[] {
    return !!showArchived ? this.versions : _.filter(this.versions, (version: IVersion) => !version.isArchived);
  }

  /** Returns a list of existing versions for the entity without the currently edited version. */
  public getExistingVersionsWithoutVersionInEdit(showArchived?: boolean): IVersion[] {
    return _.filter(this.versions, (version: IVersion) =>
      (version.id !== this.getVersionForm().getVersionInEdit()?.id) &&
      (!!showArchived ? true : !version.isArchived)
    );
  }

  /** Returns information about if the page is still loading existing versions. */
  public isLoadingBreadcrumbs(): boolean {
    return this.loadingBreadcrumbData;
  }

  /** Returns information about if the page is still loading existing versions. */
  public isLoading(): boolean {
    return this.loadingVersions;
  }
  /** Returns if the user is authorized to edit the entity. */
  public canEditEntity(): boolean {
    return this.auth.canEditPackage;
  }

  /** Handles the errors created in 'loadSelectedEntityFromId' and 'loadSelectedCollection' */
  private handleError(error) {
    const redirectTo = this.form.isLocal() ? { state: "/my/local" } : { state: "/my/collections" };

    if (error.status === 0) {
      console.log("Request aborted");
    } else if (error.status === 401) {
      this.rootStore.getErrorHandlerStore().handleUnauthorized();
    } else if (error.status === 404) {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("errors.resource_not_found"));

      if (redirectTo) {
        setTimeout(() => {
          this.rootStore.getRouter().changeRoutingState(redirectTo.state);
        }, 500);
      }
    }
  }

  /**
   * Sets up the form for adding content to a given collection.
   * @param entityId id of the entity
   */
  @action
  public async initialize(entityId: string, viewName: string) {
    if (this.getStrategy() === UploadStrategyEnum.ADD_VERSION) {
      this.form.getVersionStore().setTitle("");
    }

    this.isLocal =
      viewName.includes("catalog/localversions") ||
      viewName.includes("catalog/addLocalVersion") ||
      viewName.includes("catalog/editLocalVersion");

    try {
      const entity = await this.loadSelectedEntityFromId(entityId);
      const collection = await this.loadSelectedCollection(entity);

      runInAction(() => {
        this.entity = entity;
        this.collection = collection;
        this.form.setIsImmutable(!!entity.isImmutable);
        this.entity!.collection = this.collection as ICollection;
      });

      await this.setTranslations();
      await this.loadExistingVersions();
      this.form.getUploaderStore().setUploadData({ collection: this.collection, entity: this.entity });
    } catch (error) {
      this.handleError(error);
    }
    runInAction(() => {
      this.loadingBreadcrumbData = false;
      this.loadingVersions = false;
    });
  }

  /**
   * Loads an entity based on given id.
   * @param id id of the entity
   */
  @action
  private loadSelectedEntityFromId(id: string): Promise<IEntity> {
    return NewRepository.getPackage(
      { id: id, isLocal: this.isLocal },
      this.getEditor()?.id || "",
    ) as unknown as Promise<IEntity>;
  }

  /**
   * Loads the collection object that the entity belongs to.
   */
  private async loadSelectedCollection(entity: IEntity): Promise<ICollection> {
    return NewRepository.getCollection(
      {
        id: entity.collection.id,
        isLocal: this.isLocal,
      },
      this.getEditor()?.id || "",
    ) as unknown as ICollection;
  }

  /**
   * Sets possible translations for entity and collection.
   */
  @action
  private async setTranslations() {
    if (!!this.entity) {
      if (this.entity.isLocal) {
        this.entity.translatedTitle = this.entity.title;
      } else {
        try {
          const translations = await NewRepository.getTranslations(this.entity);

          runInAction(() => {
            _.extend(this.entity, { translations: translations });
          });
          await this.setTranslatedTitle();
        } catch {
          console.log("Error while loading translations");
        }
      }
    }
  }

  /**
   * Helper function that sets the translated title for entity,
   * and calls the function to set a translated title for collection.
   */
  @action
  private async setTranslatedTitle() {
    if (this.entity) {
      await this.setTranslatedTitleForCollection();

      runInAction(() => {
        if (this.entity!.translations) {
          const translation = this.getTranslationForSelectedLanguage(this.entity!.translations, "title");
          if (translation && (translation as string[]).length > 0 && translation[0] !== "") {
            this.entity!.translatedTitle = translation[0];
          } else {
            this.entity!.translatedTitle = this.entity!.title;
          }
        }
      });
    }
  }

  /**
   * Helper function that sets a translated title for collection.
   */
  @action
  private async setTranslatedTitleForCollection() {
    const translations = await NewRepository.getTranslations(this.entity!.collection);
    const translation = this.getTranslationForSelectedLanguage(translations, "title");

    runInAction(() => {
      if (translation && (translation as string[]).length > 0 && translation[0] !== "") {
        this.entity!.collection.translatedTitle = translation[0];
      } else {
        this.entity!.collection.translatedTitle = this.entity!.collection.title;
      }
    });
  }

  /**
   * Helper function that returns a translation for given field in the selected language.
   * @param translations the translations object
   * @param field name of the translated field
   */
  private getTranslationForSelectedLanguage(
    translations: Record<string, ITranslationObject>,
    field: string,
  ): ITranslationObject | null {
    const language: string = this.rootStore.getLocalizationStore().getLangForSelectedLocale();

    let filterdItems: ITranslationObject | null = null;
    _.each(translations, (translationItem: ITranslationObject, key: string) => {
      if (key === language) {
        filterdItems = _.filter(translationItem, (translation, itemKey) => {
          return itemKey === field;
        }) as ITranslationObject;
      }
    });
    return filterdItems;
  }

  @action
  private initVersionStoreViewState() {
    if (this.form.getVersionStore().getVersionInEdit()?.attributes.isTool) {
      this.form.getVersionStore().setViewState(VersionUploaderViewState.APPLICATION);
    } else if ((this.form.getVersionStore().getExistingFiles() || []).length > 0 && this.hasBinaryWithItemType(ItemTypeEnum.GEOMETRY)) {
      this.form.getVersionStore().setViewState(VersionUploaderViewState.GEOMETRY_FILE);
    }
  }

  private hasBinaryWithItemType(itemType: ItemTypeEnum): boolean {
    return !!this.form.getVersionStore().getExistingFiles().find(
      (binary: IFileItem) => !!binary.attributes && binary.attributes.itemType === itemType,
    );
  }

  /**
   * Loads existing versions for the given entity.
   */
  @action
  private async loadExistingVersions() {
    const userStore: UserStore = this.rootStore.getUserStore();
    this.auth.canAccessPackage = userStore.canAccessResource(this.entity! as IResource);
    this.auth.canEditPackage = userStore.canEditResource(this.entity! as IResource);

    if (!this.auth.canEditPackage) {
      this.rootStore.getErrorHandlerStore().handleUnauthorized();
    } else {
      const isCreator = userStore.isCreator(this.entity! as IResource);

      try {
        const versions = await NewRepository.getVersions(
          this.entity,
          false,
          true,
          this.getStrategy() === UploadStrategyEnum.ADD_VERSION || this.rootStore.getUserStore().canSeeVersionsStillScanning(this.entity as IResource),
        );

        runInAction(() => {
          this.versions = _.map(versions, (version: IVersion) => ({ ...version, package: this.entity }));
        });

        if (isCreator && !this.isLocal) {
          this.setScanResults();
        }

        if (this.auth.canEditPackage && this.versions.length === 0) {
          this.rootStore
            .getNotificationChannelStore()
            .notice(this.rootStore.getTranslation("upload.version.no_versions_available"));
        }
      } catch {
        console.log("Error while loading existing versions");
      }
    }
  }

  /**
   * Sets the scan results for binaries.
   */
  @action
  private setScanResults() {
    this.infectedBinaries = ScanResultsChecker.filterInfectedBinaries(this.versions);
  }

  /**
   * Updates / creates a new version based on form data.
   * @param createNew boolean marking if should create a new version
   */
  @action
  public async updateVersion(createNew: boolean) {
    const uploader = this.form.getUploaderStore();
    const versionData = this.form.getVersionStore().getVersion();

    Mask.show();
    this.rootStore.getNotificationChannelStore().show({
      text: this.rootStore.getTranslation("upload.version.update_start_message"),
      title: this.rootStore.getTranslation("upload.version.update_start_title_html"),
      titleTrusted: true,
      icon: false,
      hide: true,
      remove: true,
      type: "info",
      delay: 3000,
      sticker: false,
      width: "320px",
    });

    versionData!.prerequisites = _.select(versionData!.prerequisites, (prerequisite: IVersionPrerequisite) => {
      const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      return !!prerequisite.url && prerequisite.url !== "http://" && regexp.test(prerequisite.url);
    });

    try {
      uploader.setUploadData({
        collection: this.collection,
        entity: this.entity,
        version: versionData,
      });

      if (createNew) {
        await this.createNewVersion();
      } else {
        await this.updateExistingVersion();
      }
    } catch {
      const transKey = createNew ? "upload.version.version_add_failed" : "upload.version.version_update_failed";
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation(transKey));
    } finally {
      Mask.hide();
    }
  }

  /** Helper function to handle creating a version based on data. */
  @action
  private async createNewVersion() {
    const uploader: UploaderStore = this.form.getUploaderStore();

    await uploader.publish();
  }

  /** Helper function to handle updating existing version. */
  private async updateExistingVersion() {
    this.form.startPublish();

    const uploader = this.form.getUploaderStore();

    await uploader.editVersion(this.getEditor());
    const newVersion: IVersion = uploader.getUploadData().version!;

    const fileHandlesNotAdded = this.form.getUploaderStore().getUploadData().fileHandlesNotAdded;

    runInAction(() => {
      this.versions[this.versions.indexOf(this.originalVersion!)] = newVersion;
      this.form.getVersionStore().setVersionForEditing(newVersion);
      this.form.getVersionStore().updateExistingFiles();

      if (!!fileHandlesNotAdded) {
        this.form.getVersionStore().setSelectedFiles(fileHandlesNotAdded);
      }
    });

    this.rootStore
      .getNotificationChannelStore()
      .success(this.rootStore.getTranslation("upload.version.version_was_updated"));
  }

  /**
   * Remove the version from the entity.
   */
  public async removeVersion() {
    const editor = this.getEditor();
    if (!!editor) {
      const version = this.form.getVersionStore().getVersionInEdit()!;
      try {
        await NewRepository.deleteVersion(version, editor.id);

        runInAction(() => {
          this.deSelectVersion();
          this.resetVersions();
          this.versions.splice(this.versions.indexOf(version), 1);
        });

        const message = this.rootStore.getTranslation(
          this.isLocal ? "upload.version.version_was_deleted" : "upload.version.version_was_deleted_with_delay",
        );

        this.rootStore.getNotificationChannelStore().success(message);
      } catch {
        this.rootStore
          .getNotificationChannelStore()
          .error(this.rootStore.getTranslation("upload.version.version_update_failed"));
      }
    }
  }

  private getEditor(): IModifier | IUser | undefined {
    const currentUser = this.rootStore.getUserStore().getCurrentUser();

    return !!this.entity && this.rootStore.getUserStore().isContentEditorForOrganization(this.entity as IResource)
      ? this.entity?.creator
      : currentUser;
  }

  /** Helper function to reset the selected version.  */
  @action
  private resetVersions() {
    this.form.getVersionStore().setVersionForEditing(undefined);
  }

  /** Helper function to deselect the selected version option.  */
  @action
  private deSelectVersion() {
    this.selectedVersionOption = undefined;
  }
}

export const VersionEditorContext = createStoreContext<VersionEditorStore>(VersionEditorStore);
