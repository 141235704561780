import * as React from "react";
import { observer } from "mobx-react";
import { useContext, useState } from "react";
import * as Autosuggest from "react-autosuggest";
import { useNavigate, useSearchParams } from "react-router";

import { SearchWithAutocompleteStore, SearchWithAutocompleteStoreContext } from "../stores/searchWithAutocompleteStore";
import { RootContext, RootStore } from "../stores/rootStore";

/**
 * A component that renders search form. The form offers resource name autocompletion.
 */
export const SearchWithAutocomplete = observer(
  ({
    cssClassName,
    showClear = false,
    value,
    onSubmit,
    onClear,
  }: {
    cssClassName?: string;
    showClear?: boolean;
    value?: string;
    onSubmit?;
    onClear?;
  }) => {
    const store: SearchWithAutocompleteStore = useContext(SearchWithAutocompleteStoreContext);
    const rootStore: RootStore = useContext(RootContext);
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState(value || "");
    const [searchParams, setSearchParams] = useSearchParams();

    const onSubmitHandler = onSubmit || goToSearchPage;

    function handleSubmit(event) {
      event.preventDefault();
      onSubmitHandler(searchTerm);
    }

    function clearSearch() {
      setSearchTerm("");
      searchParams.set("searchTerm", "");
      setSearchParams(searchParams);

      if (onClear) {
        onClear();
      }
    }

    function goToSearchPage(query: string) {
      const queryStr = new URLSearchParams({
        searchTerm: query,
        showContentView: "true",
      }).toString();

      navigate("/search?" + queryStr);
    }

    function onChange(event, { newValue }) {
      event.preventDefault();
      setSearchTerm(newValue);
    }

    const inputProps = {
      placeholder: rootStore.getTranslation("placeholders.search"),
      value: value || searchTerm || "",
      onChange: onChange,
    };

    function renderSuggestion(suggestion) {
      return suggestion.label;
    }

    function getSuggestionValue(suggestion) {
      return suggestion.label;
    }

    function onSuggestionsFetchRequested({ value }) {
      if (value.length > 3) {
        store.fetchData({ queryString: value });
      }
    }


    function onSuggestionsClearRequested() { }

    function onSuggestionSelected(event, { suggestionValue }) {
      setSearchParams({ searchTerm: suggestionValue });
      onSubmitHandler(suggestionValue);
    }

    function getClassName() {
      return cssClassName ? cssClassName : "input-search";
    }

    return (
      <form role="search" onSubmit={handleSubmit} className="search-form" data-testid="search-form">
        <Autosuggest
          suggestions={store.getSuggestions().slice()}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={onSuggestionSelected}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {!showClear && searchTerm != "" && <div className="clear-search icon-remove" onClick={clearSearch} />}
        {showClear && searchTerm != "" && (
          <a
            className="clear-search button"
            title={rootStore.getTranslation("search.bar.clear")}
            onClick={clearSearch}
            data-testid="search-form-clear"
          >
            {rootStore.getTranslation("search.bar.clear")}
          </a>
        )}
        <button className={getClassName()} />
      </form>
    );
  },
);
