import * as React from "react";
import { useContext, useState, Fragment } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { UploadFormContext } from "../uploadFormStore";

/** Component for the error messages for invalid title field */
const InvalidName = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();

  const nameTooShort = collection.getTitle()!.length < form.getValidations().collection.title.minLength;
  const nameTooLong = collection.getTitle()!.length > form.getValidations().collection.title.maxLength;

  return (
    <div className="error" data-testid="errorMessage">
      {nameTooShort && (
        <small className="error" data-testid="nameTooShortMessage">
          {rootStore.getTranslation(
            "upload.collection.title_validation.too_short",
            form.getValidations().collection.title.minLength,
          )}
        </small>
      )}
      {nameTooLong && (
        <small className="error" data-testid="nameTooLongMessage">
          {rootStore.getTranslation(
            "upload.collection.title_validation.too_long",
            form.getValidations().collection.title.maxLength,
          )}
        </small>
      )}
    </div>
  );
});

/**
 * Component for rendering the collection title field used in CreateNewLocalCollection and CreateNewOnlineCollection.
 */
export const SetCollectionTitle = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const collection = form.getCollectionStore();

  const [fieldNotInFocus, setFieldNotInFocus] = useState(false);

  const nameTooShort = collection.getTitle()!.length < form.getValidations().collection.title.minLength;
  const nameTooLong = collection.getTitle()!.length > form.getValidations().collection.title.maxLength;
  const titleIsInvalid = fieldNotInFocus && (nameTooLong || nameTooShort);

  function handleBlur() {
    setFieldNotInFocus(true);
  }

  function handleInput(event) {
    event.preventDefault();
    collection.setTitle(event.target.value || "");
  }

  const name = form.isLocal()
    ? rootStore.getTranslation("localCollections.collectionCreation.name")
    : rootStore.getTranslation("onlineCollections.collectionCreation.name");

  return (
    <Fragment>
      <label className="required" data-testid="collectionTitle">
        {name}
      </label>
      <input
        type="text"
        name={form.isLocal() ? "localCollectionTitle" : "onlineCollectionTitle"}
        onChange={handleInput}
        onBlur={handleBlur}
        value={collection.getTitle()}
        minLength={form.getValidations().collection.title.minLength}
        maxLength={form.getValidations().collection.title.maxLength}
        data-testid="titleField"
      />
      {titleIsInvalid && <InvalidName />}
    </Fragment>
  );
});
