import { observable, runInAction, makeObservable, action } from "mobx";
import { createContext } from "react";
import _ from "underscore";

import { NewRepository } from "../../js/services/NewRepository";

import { IItem } from "../../models/dataModel";

/**
 * Like store.
 */
export class LikeStore {
  /** Content item */
  @observable private content: IItem | undefined;

  /** Is loading like status */
  @observable private loading: boolean = false;

  /**
   * Constructor
   */
  public constructor(content?: IItem) {
    makeObservable(this);

    if (!!content) {
      runInAction(() => {
        this.content = content;
      });
    }
  }

  /**
   * Gets content item
   * @returns content item object
   */
  public getContent(): IItem | undefined {
    return this.content;
  }

  /**
   * Is updating liked status
   */
  public isLoading(): boolean {
    return this.loading;
  }

  /**
   * Method for resolving if user has liked content
   * @returns true if user has liked content
   */
  public hasLikedContent(): boolean {
    return !!this.content && this.content.currentUserRating == 5 ? true : false;
  }

  /**
   * Method for liking on unliking content depending on previous like status
   */
  @action
  public async likeOrUnlikeContent() {
    if (!this.content) return;

    this.loading = true;
    let res;

    try {
      if (this.hasLikedContent()) {
        res = await NewRepository.deleteReview(this.content);
      } else {
        res = await NewRepository.addReview(this.content, 5);
      }
      runInAction(() => {
        _.extend(this.content, _.pick(res, "reviewCount", "currentUserRating"));
      });
    } catch {
      console.log(`Failed to ${this.hasLikedContent() ? "unlike" : "like"} content`);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

export const LikeStoreContext = createContext<LikeStore>(new LikeStore());
