import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import classNames from "classnames";

import { TreeNode, TreeNodeTypeEnum, TreeViewStore, TreeViewStoreContext } from "./treeViewStore";

/**
 * Renders the tree item/node component.
 */
const TreeItem = observer(({ node }: { node: TreeNode }) => {
  const tree: TreeViewStore = useContext(TreeViewStoreContext);
  const isFile = node.getType() === TreeNodeTypeEnum.FILE;
  const folderCollapsed = !isFile && node.isCollapsed();
  const folderOpen = !isFile && !node.isCollapsed();
  const showChildren = !node.isCollapsed() && !!node.getChildren();

  function checkForExport() {
    tree.checkForExport(node);
  }

  function selectNode() {
    node.selectNode();
  }

  return (
    <li>
      <div className="tree-item-container" data-testid="treeNodeContainer">
        {node.isExportedByUpperLevel() ? (
          <i className="icon-checkbox-checked inherited" />
        ) : (
          <i className={node.getVisualStyle()} onClick={checkForExport} data-testid={"checkForExport"} />
        )}
        <i
          className={classNames({
            "icon-folder collapsed": folderCollapsed,
            "icon-folder-open expanded": folderOpen,
            "icon-file": isFile,
          })}
          data-testid={"selectNode-" + node.getNodeId()}
          onClick={selectNode}
        />
        <span className="white-space" />
        <span
          className={classNames("stretched", { selected: node.isExported() })}
          onClick={selectNode}
          data-testid="itemName"
        >
          {node.getName()}
        </span>
      </div>
      {showChildren && (
        <div className="tree-item">
          <ul>
            {_.map(node.getChildren(), (node: TreeNode, i: number) => {
              return <TreeItem node={node} key={i} />;
            })}
          </ul>
        </div>
      )}
    </li>
  );
});

/**
 * A treeview component, modified from the angular version previously in use,
 * which was a modified version of http://github.com/eu81273/angular.treeview
 */
export const TreeView = ({ ...props }: { treeId: string; items: TreeNode[] }) => {
  const sortedItems = props.items.sort((a, b) => {
    if (a.getName() > b.getName()) return 1;
    if (b.getName() > a.getName()) return -1;
    return 0;
  });

  return (
    <div className="tree tree-view" data-testid="treeContainer">
      <ul>
        {_.map(sortedItems, (node: TreeNode, i: number) => {
          return <TreeItem node={node} key={i} />;
        })}
      </ul>
    </div>
  );
};
