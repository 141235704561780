import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";

import { RootContext, RootStore } from "../stores/rootStore";

import { IResource } from "../models/dataModel";

export const ArchivedFlag = observer(({ resource }: { resource: IResource }) => {
  const rootStore: RootStore = useContext(RootContext);
  return (
    resource?.isArchived && (
      <span
        data-testid={"isArchived-" + resource.id}
        className="role-label"
        style={{ marginLeft: "1.5em", verticalAlign: "super", maxHeight: "16px", lineHeight: "normal" }}
      >
        {rootStore.getTranslation("details.status.archived")}
      </span>
    )
  );
});
