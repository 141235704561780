import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";

import { RootContext, RootStore } from "../stores/rootStore";
import { DialogContext, DialogStore } from "../dialogs/dialogStore";
import { PackagePageStoreContext } from "./packagePageStore";
import { IVersionTool, IVersion } from "../models/dataModel";
import { TOSDialog } from "../dialogs/TOSDialog";
import { Installer } from "../utils/Installer";

const Tool = observer(({ tool, version, index }: { tool: IVersionTool; version: IVersion; index: number }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const userStore = rootStore.getUserStore();
  const TOSdialog: DialogStore = new DialogStore();

  function getTitleForTool() {
    return packagePageStore.getTitleForTool(tool);
  }

  function performDownload() {
    Installer.downloadTool(rootStore, packageItem!, version, tool);
  }

  function downloadTool() {
    if (userStore.isUserLoggedIn() && !userStore.hasSeenTOS()) {
      TOSdialog.open();
    } else {
      performDownload();
    }
  }

  return (
    <li>
      {index === 0 ? (
        <button data-testid="download-tool-button" onClick={() => downloadTool()}>
          <span className="icon icon-download">{getTitleForTool()}</span>
        </button>
      ) : (
        <a data-testid="download-tool-link" className="download" onClick={() => downloadTool()}>
          {getTitleForTool()}
        </a>
      )}
      <DialogContext.Provider value={TOSdialog}>
        <TOSDialog confirmAction={performDownload} />
      </DialogContext.Provider>
    </li>
  );
});

/**
 * A component that displays download tools information
 */
export const DownloadTools = observer(({ version }: { version: IVersion }) => {
  const rootStore: RootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const tools = packagePageStore.sortVersionTools(version.tools);

  function canDownload(): boolean {
    return !!packageItem && rootStore.getUserStore().canDownload(packageItem);
  }

  return (
    <Fragment>
      {canDownload() && (
        <ul data-testid="download-tools">
          {tools.map((tool: IVersionTool, i: number) => {
            return <Tool key={i} tool={tool} version={version} index={i} />;
          })}
        </ul>
      )}
    </Fragment>
  );
});
