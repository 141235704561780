import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";
import { LocalCollectionPage } from "./LocalCollectionPage";
import { LocalCollectionPageStoreContext, LocalCollectionPageStore } from "./localCollectionPageStore";

/**
 * Local collection page wrapper
 */
export const LocalCollectionPageWrapper = observer(() => {
  const store: RootStore = useContext(RootContext);
  const localCollectionPageStore = new LocalCollectionPageStore(store);

  return (
    <LocalCollectionPageStoreContext.Provider value={localCollectionPageStore}>
      <LocalCollectionPage />
    </LocalCollectionPageStoreContext.Provider>
  );
});
