import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";
import { Link } from "react-router";

import { ILocalCollection } from "../../../models/dataModel";
import { LocalCollectionsContext } from "./localCollectionsStore";

import { TranslatedHtml } from "../../../components/TranslatedHtml";
import { LocalCollectionItem } from "./LocalCollectionItem";

export const LocalCollections = observer(() => {
  const store = useContext(LocalCollectionsContext);
  const rootStore = store.getRootStore();

  const renderSpinner = () => {
    return <div className="spinner">{rootStore.getTranslation("shared.spinner_loading")}</div>;
  };

  return (
    <Fragment>
      <section data-testid="myLocalCollections">
        <h2>
          {rootStore.getTranslation("myCollections.local.title")}
          <Link data-testid="help" className="icon help" to="/about/usage_basics" />
        </h2>
        {rootStore.getLocalServiceStore().isLocalServiceAccessible() &&
          (store.wasDataFetched() ? (
            <div className="item-grid" data-testid="localCollectionList">
              {store.getLocalCollections().map((collection: ILocalCollection, i: number) => {
                return (
                  <div data-testid="local-collection" className="container" key={i}>
                    <div className="content-items">
                      <LocalCollectionItem collection={collection} />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            renderSpinner()
          ))}
      </section>
      {!rootStore.getLocalServiceStore().isLocalServiceAccessible() && (
        <section className="no-ls">
          <div className="results-summary">
            <h3>
              <TranslatedHtml entry="shared.local_service.not_available_with_explanation_html" />
            </h3>
          </div>
        </section>
      )}
      {store.wasDataFetched() && store.getLocalCollections().length <= 0 && (
        <section className="no-results">
          <div className="results-summary">
            <h3>{rootStore.getTranslation("myCollections.local.notFound")}</h3>
          </div>
        </section>
      )}
    </Fragment>
  );
});
