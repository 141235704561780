import _ from "underscore";

import { NewRepository } from "../js/services/NewRepository";

import { IEntity, IResource, IVersion } from "../models/dataModel";
import { ExternalResourceTypeEnum, ObjectTypeEnum, UpdateTypeEnum } from "../models/enums";

/**
 * Checks if an organization is immutable.
 *
 * @param organizationId - The ID of the organization to check.
 * @returns A promise that resolves to a boolean indicating whether the organization is immutable.
 */
export async function organizationIsImmutable(organizationId: string): Promise<boolean> {
  try {
    const organizations = await NewRepository.getImmutableOrganizations();
    return !!_.find(organizations, (organization) => organization.id === organizationId);
  } catch {
    return false;
  }
}

/**
 * Determines whether the add code name should be shown for a given resource.
 *
 * @param resource - The resource to check.
 * @returns A boolean indicating whether the add code name should be shown.
 */
export async function shouldShowAddCodeName(resource?: IResource): Promise<boolean> {
  if (!resource) return false;

  const isPackage = resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE;
  const doesNotHaveCodeName = !(resource as IEntity).codeName;
  const creatorIsOrganization = resource.creator?.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION;
  const organizationMarkedAsImmutable = !!resource.creator?.id && (await organizationIsImmutable(resource.creator?.id));

  return !!isPackage && !!doesNotHaveCodeName && !!creatorIsOrganization && !!organizationMarkedAsImmutable;
}

/**
 * Generates a code name (without prefix) based on the given title.
 *
 * @param title - The title to generate the code name from.
 * @returns The generated code name.
 */
export async function suggestCodeNameWithoutPrefix(title: string): Promise<string> {
  if (!title) return "";

  let suggestedCodeName =
    title
      .replace(/[^a-zA-Z0-9\_\- ]/g, "")
      .replace(/[\_]/g, "-")
      .trim()
      .toLowerCase()
      .split(" ")
      .join("-") || "";

  try {
    if (!(await isUniqueCodeName(suggestedCodeName, true))) {
      const originalSuggestedCodeName = suggestedCodeName;

      for (let i = 1; i < 50; i++) {
        suggestedCodeName = `${originalSuggestedCodeName}-${i}`;

        if (await isUniqueCodeName(suggestedCodeName, true)) break;
      }
    }
  } catch {
    console.log("Failed to validate code name");
    return "";
  }

  return suggestedCodeName;
}

/**
 * Checks if a given code name is unique.
 *
 * @param codeName - The code name (without prefix) to validate.
 * @param shouldThrowOnError - A boolean indicating whether an error should be thrown on validation error.
 * @returns A promise that resolves to a boolean indicating whether the code name is unique or not.
 */
export async function isUniqueCodeName(codeName: string, shouldThrowOnError?: boolean): Promise<boolean> {
  if (!codeName) return false;

  try {
    const res = await NewRepository.getPackageByCodeName(codeName);

    if (!!res) {
      return false;
    } else {
      return true;
    }
  } catch {
    if (shouldThrowOnError) throw new Error();
    else return false;
  }
}

/**
 * Checks if a given code name is valid.
 *
 * @param codeName - The code name to validate.
 * @returns A promise that resolves to a boolean indicating whether the code name is valid or not.
 */
export function isValidCodeName(codeName: string): boolean {
  if (!codeName) return false;

  return !!codeName.match(/^[a-z0-9_@-]*$/);
}

/**
 * Suggests a version number based on the previous versions.
 *
 * @param previousVersions - The previous versions to suggest the version number from.
 * @param updateType - The type of the update.
 * @returns The suggested version number.
 */
export function suggestVersionNumber(previousVersions: IVersion[], updateType: UpdateTypeEnum | undefined): string {
  const mostRecentVersion = getMostRecentVersionNumber(previousVersions);

  if (_.isNull(mostRecentVersion)) {
    return "1.0.0";
  } else if (_.isUndefined(updateType)) {
    return "";
  } else {
    const versionParts = mostRecentVersion.split(".");
    const major = parseInt(versionParts[0]);
    const minor = parseInt(versionParts[1]);
    const patch = parseInt(versionParts[2]);

    switch (updateType) {
      case UpdateTypeEnum.MAJOR:
        return `${major + 1}.0.0`;
      case UpdateTypeEnum.MINOR:
        return `${major}.${minor + 1}.0`;
      case UpdateTypeEnum.PATCH:
        return `${major}.${minor}.${patch + 1}`;
    }
  }
}

/**
 * Retrieves the most recent version number from an array of previous versions.
 *
 * @param previousVersions - An array of previous versions.
 * @returns The most recent version number as a string, or null if no version numbers exist.
 */
export function getMostRecentVersionNumber(previousVersions: IVersion[]): string | null {
  const previousVersionNumbers: string[] = previousVersions
    .filter((version: IVersion) => !_.isUndefined(version.attributes.versionNumber))
    .map((version: IVersion) => version.attributes.versionNumber as string);

  if (previousVersionNumbers.length === 0) {
    return null;
  } else {
    const latestMajor = _.chain(previousVersionNumbers)
      .map((versionNumber: string) => parseInt(versionNumber.split(".")[0]))
      .max()
      .value();

    const latestMinor = _.chain(
      _.filter(
        previousVersionNumbers,
        (versionNumber: string) => parseInt(versionNumber.split(".")[0]) === latestMajor,
      ),
    )
      .map((versionNumber: string) => parseInt(versionNumber.split(".")[1]))
      .max()
      .value();

    const latestPatch = _.chain(
      _.filter(
        previousVersionNumbers,
        (versionNumber: string) =>
          parseInt(versionNumber.split(".")[0]) === latestMajor &&
          parseInt(versionNumber.split(".")[1]) === latestMinor,
      ),
    )
      .map((versionNumber: string) => parseInt(versionNumber.split(".")[2]))
      .max()
      .value();

    return `${latestMajor}.${latestMinor}.${latestPatch}`;
  }
}

/**
 * Checks if the given data is immutable.
 *
 * @param data - The data to check.
 * @returns A boolean indicating whether the data is immutable.
 */
export function checkIfImmutable(data) {
  if (!!data.attributes && !!data.attributes.immutability && !!data.attributes.immutability["--immutable"]) {
    return true;
  } else {
    return false;
  }
}

/**
 * Checks if the given version number is valid.
 *
 * @param versionNumber - The version number to check.
 * @returns A boolean indicating whether the version number is valid.
 */
export function isValidVersionNumber(versionNumber: string): boolean {
  return !!versionNumber.match(
    /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(-(0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(\.(0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*)?(\+[0-9a-zA-Z-]+(\.[0-9a-zA-Z-]+)*)?$/,
  );
}

/**
 * Checks if a given code name is unique.
 *
 * @param codeName - The code name to validate.
 * @param shouldThrowOnError - A boolean indicating whether an error should be thrown on validation error.
 * @returns A promise that resolves to a boolean indicating whether the code name is unique or not.
 */
export function versionNumberIsUniqueForThePackage(versionNumber: string, existingVersions: IVersion[]): boolean {
  return !_.find(existingVersions || [], (version: IVersion) => version.attributes.versionNumber === versionNumber);
}
