import { action, computed, observable, runInAction, toJS, makeObservable } from "mobx";
import _ from "underscore";

import { IEntity, IRelatedContent } from "../../models/dataModel";
import { TCCSearchService } from "../../js/services/TCCSearchService";
import { NewRepository } from "../../js/services/NewRepository";
import { RootStore, createStoreContext } from "../../stores/rootStore";

/**
 * Store used to handle data for related content component
 */
export class RelatedContentStore {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Package item object
   */
  @observable private packageItem: IEntity | undefined;

  /**
   * Related content list
   */
  @observable private relatedContent: IEntity[] = [];

  /**
   * Other similar content list
   */
  @observable private otherSimilarContent: IEntity[] = [];

  /**
   * Flag for indicating whether the page is loading
   */
  @observable private loading = false;

  /**
   * Input field for dialog
   */
  @observable private relatedInput = "";

  /**
   * Constructor
   * @param rootStore RootStore instance
   */
  public constructor(rootStore: RootStore, packageItem?: IEntity) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (packageItem) {
      runInAction(() => {
        this.packageItem = packageItem;
      });
      this.init();
    }
  }

  /**
   * Gets related content list
   * @returns list of related content items
   */
  public getRelatedContent(): IEntity[] {
    return this.relatedContent;
  }

  /**
   * Gets other similar content list
   * @returns list of other similar items
   */
  public getOtherSimilarContent(): IEntity[] {
    return this.otherSimilarContent;
  }

  /**
   * Gets related input field value
   * @returns related input field value
   */
  public getRelatedInput(): string {
    return this.relatedInput;
  }

  /**
   * Sets related input field value
   * @params input New value for related input
   */
  @action
  public setRelatedInput(input: string) {
    this.relatedInput = input;
  }

  /**
   * Resolves if page is loading
   * @returns true if page is loading
   */
  public isLoading(): boolean {
    return this.loading;
  }

  /**
   * Resolves if input is valid
   * @returns true if input is valid
   */
  @computed
  public get isInputValid(): boolean {
    return this.isValidContentId(this.relatedInput) || this.isValidUrl(this.relatedInput);
  }

  /**
   * Adds new related content item to package
   */
  public async addRelatedContent() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!this.packageItem && (!!user || this.packageItem?.isLocal)) {
      try {
        const contentId = this.resolveContentIdFromInput(this.relatedInput);

        const relatedPackage = (await NewRepository.getPackage(
          {
            id: contentId,
            isLocal: this.packageItem.isLocal,
          },
          user?.id || "",
        )) as unknown as IEntity;

        let relatedContent = this.packageItem.related;

        if (_.isArray(relatedContent)) {
          relatedContent.push(relatedPackage.id);
        } else {
          relatedContent = [relatedPackage.id];
        }

        await NewRepository.updateRelatedContent(this.packageItem, relatedContent, user?.id);

        runInAction(() => {
          this.packageItem!.related = relatedContent;
        });

        this.reloadRelatedContent();
        this.showSuccessMessage();
      } catch {
        this.showErrorMessage();
      }
    }
  }

  /**
   * Removes related content item from package
   * @params relatedContent the content item to be removed
   */
  @action
  public async removeRelatedContent(relatedContent: IEntity) {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!this.packageItem && (!!user || this.packageItem?.isLocal)) {
      try {
        const relatedContentAfterRemove = this.removeContentFromRelatedList(relatedContent);

        await NewRepository.updateRelatedContent(this.packageItem, relatedContentAfterRemove, user?.id);

        runInAction(() => {
          this.packageItem!.related = relatedContentAfterRemove;
        });
        this.reloadRelatedContent();
        this.showSuccessMessage();
      } catch {
        this.showErrorMessage();
      }
    }
  }

  @action
  private reloadRelatedContent() {
    this.relatedContent = [];

    const isLocal = this.packageItem!.isLocal;
    _.each(this.formatRelatedList(this.packageItem!.related), async (packageId: string) => {
      const packageItem = (await NewRepository.getPackage(
        {
          id: packageId,
          isLocal: isLocal,
        },
        this.rootStore.getUserStore().getCurrentUser()?.id || "",
      )) as unknown as IEntity;
      runInAction(() => {
        this.relatedContent.push(packageItem);
      });
    });
  }

  private isValidContentId(input): boolean {
    const regexp = /^[a-z0-9-]+$/i;
    return input.length == 36 || (input.length == 37 && regexp.test(input));
  }

  private inputHasCorrectPath(input): boolean {
    if (this.packageItem) {
      const path = this.packageItem.isLocal ? "/#/catalog/localdetails/" : "/#/catalog/details/";
      return input.match(path);
    }
    return false;
  }

  private isValidUrl(input): boolean {
    const contentId = this.resolveContentIdFromInput(input);
    return this.isValidContentId(contentId) && this.inputHasCorrectPath(input);
  }

  private removeContentFromRelatedList(relatedContent: IEntity): string[] {
    if (!!this.packageItem) {
      const formattedList: string[] = this.formatRelatedList(this.packageItem.related);
      return _.reject(formattedList, (rel: string) => {
        if (_.isObject(rel) && !!(rel as IRelatedContent).id) {
          return (rel as IRelatedContent).id === relatedContent.id;
        } else {
          return rel === relatedContent.id;
        }
      });
    }
    return [];
  }

  private resolveContentIdFromInput(relatedContent) {
    const index = relatedContent.lastIndexOf("/");
    if (index > 0) {
      return relatedContent.substr(index + 1, relatedContent.length);
    }
    return relatedContent;
  }

  @action
  private async init() {
    if (this.packageItem) {
      this.loading = true;
      this.reloadRelatedContent();
      const queryParams = {
        q: this.packageItem.title,
        offset: 0,
        count: 3,
        sortBy: "createTime DESC",
      };
      const packageList: IEntity[] = await TCCSearchService.searchPackages(
        queryParams,
        true,
        this.rootStore.getUserStore().getCurrentUser()?.id,
      );
      runInAction(() => {
        this.otherSimilarContent = this.filterPackageFromList(packageList);
        this.loading = false;
      });
    }
  }

  private filterPackageFromList(packages: IEntity[]): IEntity[] {
    return _.reject(packages, (packageItem: IEntity) => {
      return !!this.packageItem && packageItem.id === this.packageItem.id;
    });
  }

  private formatRelatedList(relatedList: Array<string | IRelatedContent> | string): string[] {
    const formattedList: string[] = [];
    if (!!relatedList) {
      relatedList = toJS(relatedList);
      if (!_.isArray(relatedList)) {
        relatedList = relatedList.split(",");
      }
      _.each(relatedList, (relatedItem: string | IRelatedContent) => {
        if (_.isObject(relatedItem) && !!relatedItem.id) {
          formattedList.push(relatedItem.id!);
        } else if (_.isString(relatedItem)) {
          formattedList.push(relatedItem);
        }
      });
    }
    return formattedList;
  }

  private showSuccessMessage() {
    this.rootStore
      .getNotificationChannelStore()
      .success(this.rootStore.getTranslation("details.related_content.updated_successfully"));
  }

  private showErrorMessage() {
    this.rootStore
      .getNotificationChannelStore()
      .error(this.rootStore.getTranslation("details.related_content.update_failed"));
  }
}

/**
 * Context object for RelatedContentStore instances.
 */
export const RelatedContentStoreContext = createStoreContext<RelatedContentStore>(RelatedContentStore);
