import { action, observable, runInAction, toJS, makeObservable } from "mobx";
import { saveAs } from "file-saver";
import _ from "underscore";

import { NewRepository } from "../../js/services/NewRepository";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import {
  IBatchCollection,
  IBatchContentHandler,
  IDropdownOption,
  IBatchEntity,
  IBatchVersion,
  IResource,
  ITranslationObject,
  IEntity,
} from "../../models/dataModel";
import { ObjectTypeEnum } from "../../models/enums";

import { supportedLocales } from "../../constants/LocalizationConstants";
import { LanguageCodeConverter } from "../../utils/converters/LanguageCodeConverter";

export interface IImportError {
  message: string;
}

export interface IImportAttributes {
  attributes: Record<string, ITranslationObject>;
  id: string;
}

/**
 * A store that contains logic for translations dialog.
 */
export class BatchTranslationsDialogStore implements IBatchContentHandler {
  /**
   * Available collections list
   */
  private availableCollections: IBatchCollection[] = [];
  /**
   * Collection options for dropdown
   */
  @observable private collectionOptions: IDropdownOption[] = [];
  /**
   * Viewable collections list
   */
  private collections: IBatchCollection[] = [];
  /**
   * Translation attributes to be used for import
   */
  private importAttributes: IImportAttributes[] = [];
  /**
   * Count of imported translations
   */
  @observable private importCount = 0;
  /**
   * list of errors found on import validation check
   */
  @observable private importErrors: IImportError[] = [];
  /**
   * Indicator if is import has been finished
   */
  @observable private importProcessed = false;
  /**
   * Indicator if is processing data
   */
  @observable private processing = false;
  /**
   * Indicator if is translations are being processed
   */
  @observable private processingTranslations = false;
  /**
   * Resource (collection/package)
   */
  @observable private resource: IResource | undefined = undefined;
  /**
   * Root store
   */
  private rootStore: RootStore;
  /**
   * Flag for if all content should be selected
   */
  @observable private selectAll = false;
  /**
   * Selected collection option
   */
  @observable private selectedCollectionOption?: IDropdownOption;
  /**
   * List of supported languages in translations
   */
  private translationLanguages: string[] = [];
  /**
   * Selected translation import file
   */
  @observable private translationsFile?: File;

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore, availableCollections?: IBatchCollection[], resource?: IResource) {
    makeObservable(this);
    this.rootStore = rootStore;
    runInAction(() => {
      if (availableCollections) {
        this.availableCollections = _.clone(toJS(availableCollections));
      }
      if (resource) {
        this.resource = resource;
        this.init();
      }
    });
  }

  /**
   * Resolves if import can be made
   */
  public canImport() {
    return (
      this.getTranslationsFile != null &&
      !this.processing &&
      this.importErrors.length == 0 &&
      this.importAttributes.length > 0
    );
  }

  /**
   * Exports translations to a file for selected content
   */
  @action
  public async exportTranslations() {
    const promises: Array<Promise<void>> = [];
    this.processingTranslations = true;
    const selectedContent = this.getSelectedContent();
    _.each(selectedContent, (content) => {
      promises.push(this.resolveTranslations(content));
    });
    await Promise.all(promises);
    this.downloadFile(selectedContent);
    runInAction(() => {
      this.processingTranslations = false;
    });
  }

  /**
   * Get collections options for dropdown
   * @returns List of collection options
   */
  public getCollectionOptions(): IDropdownOption[] {
    return this.collectionOptions;
  }

  /**
   * Gets viewable collections list
   */
  public getCollections(): IBatchCollection[] {
    return this.collections;
  }

  /**
   * Gets count of imported items
   */
  public getImportCount(): number {
    return this.importCount;
  }

  /**
   * Lists errors found while importing translations
   */
  public getImportErrors(): IImportError[] {
    return this.importErrors;
  }

  /**
   * Resolves if select all flag is on
   */
  public getSelectAll(): boolean {
    return this.selectAll;
  }

  /**
   * Gets selected collection dropdown option
   * @returns selected option
   */
  public getSelectedCollectionOption(): IDropdownOption {
    return this.selectedCollectionOption!;
  }

  /**
   * Gets list of selected content
   */
  public getSelectedContent(): IResource[] {
    const selectedContent: IResource[] = [];
    _.each(this.collections, (collection) => {
      if (collection.selected) {
        selectedContent.push(collection as IResource);
      }
      !!collection.packages &&
        _.each(collection.packages, (packageItem: IEntity) => {
          if (packageItem.selected) {
            selectedContent.push(packageItem as IResource);
          }
        });
    });
    return selectedContent;
  }

  /**
   * Gets selected translations file
   */
  public getTranslationsFile(): File | undefined {
    return this.translationsFile;
  }

  /**
   * Handles actions when new collection is selected
   * @params option the selected dropdown option
   */
  @action
  public async handleCollectionChange(option: IDropdownOption) {
    this.selectedCollectionOption = option;
    const user = this.rootStore.getUserStore().getCurrentUser();
    this.processing = true;
    this.selectAll = false;
    if (option.value === "show_all") {
      this.collections = this.availableCollections;
    } else if (option.value === "show_organization") {
      this.collections = _.filter(this.availableCollections, (collection: IBatchCollection) => {
        return !!user && !!collection.creator && !!user.organization && collection.creator.id === user.organization.id;
      });
    } else {
      this.collections = _.filter(this.availableCollections, (collection: IBatchCollection) => {
        return collection.id === option.value;
      });
    }
    const promises: Array<Promise<void>> = [];
    _.each(this.collections, (collection) => {
      collection.open = false;
      promises.push(this.fetchPackagesForCollection(collection));
    });
    await Promise.all(promises);
    runInAction(() => {
      this.selectAllContent(this.selectAll);
      this.processing = false;
    });
  }

  /**
   * Actions to be processed when new file is selected
   * @params file the selected file
   */
  @action
  public async handleFileSelect(file: File): Promise<void> {
    this.importProcessed = false;
    this.processing = true;
    this.importAttributes = [];
    this.importCount = 0;
    this.importErrors = [];
    this.setTranslationsFile(file);
    await this.validateFile(file);
    runInAction(() => {
      this.processing = false;
    });
  }

  /**
   * Imports translations from selected translations file
   */
  @action
  public async importTranslations() {
    const promises: Array<Promise<void>> = [];
    this.processing = true;
    this.importCount = 0;
    this.importErrors = [];
    this.importProcessed = false;
    _.each(this.importAttributes, (translationAttributes) => {
      promises.push(this.saveTranslations(translationAttributes));
    });
    await Promise.all(promises);
    runInAction(() => {
      this.importProcessed = true;
      this.processing = false;
    });
  }

  /**
   * Resolves if content checkbox should be shown as indeterminated.
   * This is when some (not all) of the content child items are selected
   * @params content The content item that should be inspected
   */
  public isContentIndeterminated(content: IBatchCollection | IBatchEntity | IBatchVersion): boolean {
    if (this.isCollection(content as IResource)) {
      return this.isCollectionIndeterminated(content as IBatchCollection);
    } else {
      return false;
    }
  }

  /**
   * Resolves if import has been processed
   */
  public isImportProcessed(): boolean {
    return this.importProcessed;
  }

  /**
   * Resolves if data is being processed
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Resolves if translations are being processed
   */
  public isProcessingTranslations(): boolean {
    return this.processingTranslations;
  }

  /**
   * Selects content and child items
   * @params content the content to be selected
   */
  public selectContent(content: IBatchCollection | IBatchEntity) {
    if (this.isCollection(content as IResource)) {
      return this.selectCollection(content as IBatchCollection);
    } else if (this.isPackage(content as IResource)) {
      return this.selectPackage(content as IBatchEntity);
    }
  }

  /**
   * Selects/unselects all content
   */
  @action
  public setSelectAll(selectAll: boolean) {
    this.selectAll = selectAll;
    this.processing = true;
    this.selectAllContent(this.selectAll);
    this.processing = false;
  }

  /**
   * Sets file to be used for importing translations
   * @params file the file to be used on import
   */
  @action
  public setTranslationsFile(file: File): void {
    if (file) {
      this.translationsFile = file;
    }
  }

  /**
   * Resolves if version listing should be shown
   */
  public showVersions(): boolean {
    return false;
  }

  /**
   * Opens content on tree
   * @params content the content item to be viewed
   */
  public toggleContent(content: IBatchCollection | IBatchEntity) {
    if (this.isCollection(content as IResource)) {
      this.toggleCollection(content as IBatchCollection);
    }
  }

  @action
  private addError(error: string, translationId: string, errorField?: string) {
    let errorText = "";
    const contentId = this.stripContentIdFromTranslationId(translationId);
    const translationKey = "shared.translations." + error;
    if (errorField) {
      errorText = this.rootStore.getTranslation(translationKey, _.escape(contentId), _.escape(errorField));
    } else {
      errorText = this.rootStore.getTranslation(translationKey, _.escape(contentId));
    }

    const existingMessages = _.filter(this.importErrors, function (error) {
      return error.message === errorText;
    });
    if (existingMessages.length <= 0) {
      this.importErrors.push({ message: errorText });
    }
  }

  private detailsMaxLengthExceeded(details: string) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = details;
    const text = tmp.textContent || tmp.innerText;
    return text && text.length > 5000;
  }

  private downloadFile(selectedContent: IResource[]) {
    let translations: Array<Record<string, ITranslationObject>> = [];
    _.each(selectedContent, function (content) {
      translations = translations.concat(content.translations!);
    });
    const translationsString = JSON.stringify(translations, null, 2);
    const blob = new Blob([translationsString], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "exportedTranslations.txt");
  }

  @action
  private async fetchPackagesForCollection(collection: IBatchCollection) {
    if (!collection.packages) {
      try {
        collection.loading = true;
        const packages: IBatchEntity[] = await NewRepository.getPackages(
          {
            id: collection.id,
          },
          false,
          this.rootStore.getUserStore().getCurrentUser()?.id,
        );

        runInAction(() => {
          collection.packages = _.sortBy(packages, "title");
          collection.loading = false;
        });
      } catch {
        runInAction(() => {
          collection.loading = false;
        });
        console.log("Error occurred while fetching packages for collection", collection.id);
      }
    }
  }

  private generateTranslationId(content: IResource) {
    const prefix = content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE ? "package_" : "collection_";
    return prefix + content.id;
  }

  @action
  private inialializeTranslationsFields(
    content: IResource,
    translations: Record<string, ITranslationObject>,
    language: string,
  ) {
    if (content) {
      translations[language] = {};
      translations[language].title = "";
      translations[language].description = "";
      if (content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
        translations[language].copyright = "";
        translations[language].details = "";
      }
    }
  }

  @action
  private async init() {
    this.processing = true;
    this.initTranslationLanguages();
    this.initCollectionFields(this.availableCollections);
    this.initCollectionOptions();
    this.setSelectedCollectionOption();
    await this.initViewableContent();
    runInAction(() => {
      this.processing = false;
    });
  }

  @action
  private initCollectionFields(collections: IBatchCollection[]) {
    _.each(collections, (collection) => {
      collection.open = false;
      collection.selected = false;
    });
  }

  @action
  private initCollectionOptions() {
    this.collectionOptions = _.map(this.availableCollections, (collection: IBatchCollection) => {
      return { label: collection.title!, value: collection };
    });

    const showOrganizationOption = {
      value: "show_organization",
      label: this.rootStore.getTranslation("batch_edit.content.show_organization_content"),
    };
    this.collectionOptions.unshift(showOrganizationOption);

    const showAllOption = {
      value: "show_all",
      label: this.rootStore.getTranslation("batch_edit.content.show_all_content"),
    };
    this.collectionOptions.unshift(showAllOption);
  }

  @action
  private initTranslationLanguages() {
    this.translationLanguages = Object.keys(supportedLocales).map((key) => {
      const locale = supportedLocales[key];
      return LanguageCodeConverter.convertIsoCodeToLang(locale.isoCode);
    });
  }

  @action
  private async initViewableContent() {
    let collectionId = "";
    if (this.resource && this.availableCollections && this.availableCollections.length > 0) {
      if (this.isPackage(this.resource)) {
        collectionId = this.resource.collection!.id!;
      } else if (this.isCollection(this.resource)) {
        collectionId = this.resource.id || "";
      }
      this.collections = _.filter(this.availableCollections, (collection) => {
        return collection.id === collectionId;
      });
      if (this.collections.length > 0) {
        this.collections[0].open = true;
        if (this.isCollection(this.resource)) {
          this.collections[0].selected = true;
        }
        await this.fetchPackagesForCollection(this.collections[0]);
        this.setPackagesSelection(this.collections[0].packages!);
      }
    }
  }

  private isCollection(resource: IResource): boolean {
    return resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION;
  }

  private isCollectionIndeterminated(collection: IBatchCollection): boolean {
    if (!collection.selected || !collection.packages) {
      return false;
    } else {
      const selectedPackages: IBatchEntity[] = _.filter(collection.packages, (packageItem: IEntity) => {
        return !!packageItem.selected;
      });

      return selectedPackages.length > 0 && selectedPackages.length != collection.packages.length;
    }
  }

  private isPackage(resource: IResource): boolean {
    return resource.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE;
  }

  private isValidTranslationField(
    translationId: string,
    translationField: string,
    translationFieldValue: string,
  ): boolean {
    let validField = true;
    let allowedFields: string[] = [];
    if (translationId.indexOf("collection_") > -1) {
      allowedFields = ["title", "description"];
    } else {
      allowedFields = ["title", "description", "copyright", "details"];
    }
    if (!_.contains(allowedFields, translationField)) {
      validField = false;
      this.addError("invalid_field", translationId, translationField);
    }
    if (translationField === "details" && this.detailsMaxLengthExceeded(translationFieldValue)) {
      validField = false;
      this.addError("invalid_too_long", translationId, translationField);
    }
    return validField;
  }

  private isValidTranslationLanguage(translationId: string, language: string): boolean {
    const validLanguage: boolean = language === "default" || _.contains(this.translationLanguages, language);
    if (!validLanguage) {
      this.addError("invalid_language", translationId, language);
    }
    return validLanguage;
  }

  private resolveTranslationFields(content: IResource): string[] {
    let translationFields: string[] = [];
    if (content) {
      if (content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
        translationFields = ["title", "description", "copyright", "details"];
      } else {
        translationFields = ["title", "description"];
      }
    }
    return translationFields;
  }

  @action
  private async resolveTranslations(content: IResource) {
    content.translations = {};
    const translationId = this.generateTranslationId(content);
    const translations: Record<string, ITranslationObject> = await NewRepository.getTranslations(content);
    this.setMissingTranslationFields(content, translations);
    content.translations[translationId] = translations;
    return;
  }

  @action
  private async saveDefaultTranslationAttributes(
    translationId: string,
    attributes: Record<string, ITranslationObject>,
  ) {
    const contentId = this.stripContentIdFromTranslationId(translationId);
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        if (translationId.indexOf("package_") > -1) {
          const packageItem: IBatchEntity = (await NewRepository.getPackage(
            { id: contentId },
            user?.id,
          )) as unknown as IBatchEntity;

          const updatedPackage = this.getDefaultAttributes(packageItem, attributes);
          await NewRepository.updatePackage(updatedPackage, user);

          runInAction(() => {
            this.importCount = this.importCount + 1;
          });
        } else {
          const collection: IBatchCollection = (await NewRepository.getCollection(
            {
              id: contentId,
            },
            user?.id || "",
          )) as unknown as IBatchCollection;
          this.setDefaultAttributes(collection as IResource, attributes);
          await NewRepository.updateCollection(collection, user);
          runInAction(() => {
            this.importCount = this.importCount + 1;
          });
        }
      } catch (err) {
        this.addError("import_failed_for_content", translationId);
      }
    }
  }

  private async saveTranslations(translationAttributes: IImportAttributes) {
    const defaultAttributes = _.pick(translationAttributes.attributes, "default");
    const attributes = _.omit(translationAttributes.attributes, "default");
    await this.saveTranslationsAttributes(translationAttributes.id, attributes);
    await this.saveDefaultTranslationAttributes(translationAttributes.id, defaultAttributes);
    return;
  }

  private async saveTranslationsAttributes(translationId: string, attributes: Record<string, ITranslationObject>) {
    try {
      const contentId = this.stripContentIdFromTranslationId(translationId);
      let content;
      if (translationId.indexOf("package_") > -1) {
        content = {
          id: contentId,
          type: ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE,
        };
      } else {
        content = {
          id: contentId,
          type: ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION,
        };
      }
      await NewRepository.setTranslations(content, attributes);
      runInAction(() => {
        this.importCount = this.importCount + 1;
      });
    } catch (err) {
      this.addError("import_failed_for_content", translationId);
    }
  }

  @action
  private selectAllContent(selectAll: boolean) {
    _.each(this.collections, (collection) => {
      collection.selected = selectAll;
      !!collection.packages &&
        _.each(collection.packages, (packageItem) => {
          packageItem.selected = selectAll;
        });
    });
  }

  @action
  private selectCollection(collection: IBatchCollection) {
    collection.selected = !collection.selected;
    this.processing = true;
    !!collection.packages &&
      _.each(collection.packages, (packageItem) => {
        packageItem.selected = collection.selected;
      });
    this.processing = false;
  }

  @action
  private selectPackage(packageItem: IBatchEntity) {
    this.processing = true;
    packageItem.selected = !packageItem.selected;
    this.processing = false;
  }

  @action
  private setDefaultAttributes(content: IResource, attributes: Record<string, ITranslationObject>) {
    content.description = attributes["default"].description;
    content.title = attributes["default"].title;
    if (content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      (content as IEntity).copyrightInfo = attributes["default"].copyright;
      (content as IEntity).richContent = attributes["default"].details;
    }
  }

  @action
  private getDefaultAttributes(content: IResource, attributes: Record<string, ITranslationObject>) {
    let defaultAttributes: any = {
      id: content.id,
      description: attributes["default"].description,
      title: attributes["default"].title,
    };

    if (content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      defaultAttributes = {
        ...defaultAttributes,
        copyrightInfo: attributes["default"].copyright,
        richContent: attributes["default"].details,
      };
    }

    return defaultAttributes;
  }

  @action
  private setDefaultTranslationValues(content: IResource, translations: Record<string, ITranslationObject>) {
    translations["default"] = {};
    translations["default"].description = content.description || "";
    translations["default"].title = content.title || "";
    if (content.type === ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE) {
      translations["default"].copyright = (content as IEntity).copyrightInfo || "";
      translations["default"].details = (content as IEntity).richContent || "";
    }
  }

  @action
  private setMissingTranslationFields(content: IResource, translations: Record<string, ITranslationObject>) {
    _.each(this.translationLanguages, (language) => {
      const matchingLanguages = _.filter(translations, function (translation, key) {
        return key === language;
      });

      if (matchingLanguages.length <= 0) {
        this.inialializeTranslationsFields(content, translations, language);
      } else {
        _.each(this.resolveTranslationFields(content), (translationField) => {
          _.each(translations, function (translationItem) {
            const matchingFields = _.filter(translationItem, function (translation, key) {
              return key === translationField;
            });

            if (matchingFields.length === 0) {
              translationItem[translationField] = "";
            }
          });
        });
      }
    });
    this.setDefaultTranslationValues(content, translations);
  }

  @action
  private setPackagesSelection(packages: IBatchEntity[]) {
    const isCollection = this.isCollection(this.resource!);
    _.each(packages, (packageItem) => {
      if (packageItem.id === this.resource!.id || isCollection) {
        packageItem.selected = true;
      }
    });
  }

  @action
  private setSelectedCollectionOption() {
    let option: IDropdownOption[] = [];
    if (this.availableCollections && this.resource) {
      if (this.isCollection(this.resource)) {
        option = _.filter(this.collectionOptions, (collectionOption) => {
          return collectionOption.value.id === this.resource!.id;
        });
      } else if (this.isPackage(this.resource)) {
        option = _.filter(this.collectionOptions, (collectionOption) => {
          return collectionOption.value.id === this.resource!.collection!.id;
        });
      }
      this.selectedCollectionOption = option[0];
    }
  }

  private setTranslationAttributes(translations: Array<Record<string, ITranslationObject>>) {
    _.each(translations, (translation: Record<string, ITranslationObject>) => {
      _.each(translation, (translationItem: ITranslationObject, translationId: string) => {
        const attributes: Record<string, ITranslationObject> = {};
        _.each(translationItem, (translationField: string, translationLang: string) => {
          if (this.isValidTranslationLanguage(translationId, translationLang)) {
            _.each(translationField, (translationFieldValue, key) => {
              if (this.isValidTranslationField(translationId, key.toString(), translationFieldValue)) {
                if (!attributes[translationLang]) {
                  attributes[translationLang] = {};
                }
                if (translationFieldValue && translationFieldValue.length > 0) {
                  attributes[translationLang][key] = translationFieldValue;
                } else {
                  attributes[translationLang][key] = null;
                }
              }
            });
          }
        });
        this.importAttributes.push({
          id: translationId,
          attributes: attributes,
        });
      });
    });
  }

  private stripContentIdFromTranslationId(translationId: string): string {
    if (translationId.indexOf("package_") > -1) {
      return translationId.replace("package_", "");
    } else {
      return translationId.replace("collection_", "");
    }
  }

  @action
  private toggleCollection(collection: IBatchCollection) {
    collection.open = !collection.open;
    if (collection.open) {
      this.fetchPackagesForCollection(collection);
    }
  }

  @action
  private async validateFile(file: File): Promise<void> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        try {
          const translations = JSON.parse(reader.result as string);
          this.setTranslationAttributes(translations);
          resolve();
        } catch (err) {
          this.importErrors.push({ message: (err as Error).message });
          resolve();
        }
      };
    });
  }
}

/**
 * Context object for BatchTranslationsDialogStore instances.
 */
export const BatchTranslationsDialogStoreContext =
  createStoreContext<BatchTranslationsDialogStore>(BatchTranslationsDialogStore);
