import { DateTime } from "luxon";
import * as React from "react";
import { useContext, useEffect } from "react";

import { TemporaryBannerNotification } from "../components/TemporaryBannerNotification";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext, RootStore } from "../stores/rootStore";
import { AccountVerificationErrors } from "./AccountVerificationErrors";
import { BrowseOrUpload } from "./BrowseOrUpload";
import { NewestEntities } from "./newest/NewestEntities";
import { NewestEntitiesContext, NewestEntitiesStore } from "./newest/newestEntitiesStore";
import { Partners } from "./partners/Partners";
import { PartnersContext, PartnersStore } from "./partners/partnersStore";
import { MostPopularEntities } from "./popular/MostPopularEntities";
import { MostPopularEntitiesContext, MostPopularEntitiesStore } from "./popular/mostPopularEntitiesStore";
import { RecommendedEntities } from "./recommended/RecommendedEntities";
import { RecommendedEntitiesContext, RecommendedEntitiesStore } from "./recommended/recommendedEntitiesStore";
import { SearchArea } from "./search/SearchArea";
import { TeklaDevelopmentExperts } from "./TeklaDevelopmentExperts";
import { Videos } from "./videos/Videos";
import { Settings } from "../config/Settings";
import { StatsContext, StatsStore } from "./stats/statsStore";
import { Stats } from "./stats/Stats";


const ServicePluginMessage = () => {
  return (
    <TemporaryBannerNotification text={<TranslatedHtml entry="landing.notifications.service_plugin_update_info" />} bannerFeatureToggle="landingPageBanner_servicePlugin" show={true} />
  );
};

const ContentEditorRoleInfoBefore = () => {
  return (
    <TemporaryBannerNotification
      color="yellow"
      text={<TranslatedHtml entry="landing.notifications.content_editor_role.info_before_switch" args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
      bannerFeatureToggle="contentEditorRoleInfoBefore"
      show={true}
    />
  );
};

const ContentEditorRoleInfoAfter = () => {
  return (
    <TemporaryBannerNotification
      color="light-blue"
      text={<TranslatedHtml entry="landing.notifications.content_editor_role.info_after_switch" args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
      bannerFeatureToggle="contentEditorRoleInfoAfter"
      show={true}
    />
  );
};

/**
 * A component that displays landing page.
 */
export const LandingPage = () => {
  const store: RootStore = useContext(RootContext);

  function resetURL() {
    window.location.replace(window.location.origin + "/");
  }

  useEffect(() => {
    if (window.location.search) {
      resetURL();
    }
  }, []);

  return (
    <div id="content" className="catalog landing" data-testid="landingPage">
      <ServicePluginMessage />
      <ContentEditorRoleInfoBefore />
      <ContentEditorRoleInfoAfter />
      <AccountVerificationErrors />
      <SearchArea />
      <BrowseOrUpload />
      <RecommendedEntitiesContext.Provider value={new RecommendedEntitiesStore(store)}>
        <RecommendedEntities />
      </RecommendedEntitiesContext.Provider>
      <TeklaDevelopmentExperts
        campaignStart={DateTime.fromObject({
          year: 2025,
          month: 2,
          day: 24,
        })
          .startOf("day")
          .toJSDate()}
        campaignEnd={DateTime.fromObject({
          year: 2025,
          month: 5,
          day: 26,
        })
          .startOf("day")
          .toJSDate()}
      />
      <NewestEntitiesContext.Provider value={new NewestEntitiesStore(store)}>
        <NewestEntities />
      </NewestEntitiesContext.Provider>
      <Videos />
      <StatsContext.Provider value={new StatsStore(store)}>
        <Stats />
      </StatsContext.Provider>
      <MostPopularEntitiesContext.Provider value={new MostPopularEntitiesStore(store)}>
        <MostPopularEntities />
      </MostPopularEntitiesContext.Provider>
      <PartnersContext.Provider value={new PartnersStore(store)}>
        <Partners />
      </PartnersContext.Provider>
    </div>
  );
};
