import { AbstractSearchDataSource } from "../data-source/AbstractSearchDataSource";
import _ from "underscore";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SearchPageOrderEnum, SearchTargetOptionEnum } from "../../models/enums";

var dataSources = {};

export const SearchResultSetsView = {
  registerDataSource: function (dataSourceName, dataSource) {
    if (_.isString(dataSourceName) && _.isObject(dataSource)) {
      dataSources[dataSourceName] = dataSource;
      dataSource.reset();
    }
  },
  /**
   * Load initial page for all data sources
   * @param {string} queryString - search query
   * @param {number} rowsNumber - number of rows to load
   * @param {SearchPageOrderEnum} sortBy - sort by
   * @param {FiltersToTCC} filters - filters
   * @param {string?} creator - creator
   * @param {SearchTargetOptionEnum} target - target
   * @param {boolean?} excludeEnvFiles - exclude env files
   * @param {boolean} recordEvent - record event
   * @returns {Promise} - promise
   */
  loadInitialPage: function (queryString, rowsNumber, sortBy, filters, creator, target, excludeEnvFiles, recordEvent) {
    rowsNumber = rowsNumber || 12;
    var promises = _.map(dataSources, function (dataSource, _dataSourceName) {
      return new Promise((resolve, reject) => {
        dataSource.reset(queryString, sortBy, filters, creator, target, excludeEnvFiles, recordEvent);
        dataSource.loadNextRows(rowsNumber, true).then(
          function (res) {
            resolve(res);
          },
          function (err) {
            reject(err);
          },
        );
      });
    });
    return Promise.all(promises);
  },
  getDataSource: function (dataSourceName) {
    if (_.has(dataSources, dataSourceName)) {
      return dataSources[dataSourceName];
    } else {
      return AbstractSearchDataSource();
    }
  },
};
