import * as React from "react";
import { useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router";

import { RootContext } from "../stores/rootStore";

/**
 * A component to display upload button.
 */
export const UploadButton = observer(() => {
  const store = useContext(RootContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <li
      className={classNames("add-link", {
        active: pathname === "/upload",
      })}
    >
      {pathname === "/upload" ? (
        <button className="disabled" data-testid="upload-button-disabled">
          <span>{store.getTranslation("mainNavigation.uploading")}</span>
        </button>
      ) : (
        <button data-testid="upload-button" onClick={() => navigate("/upload")} className="create">
          <span>{store.getTranslation("mainNavigation.upload")}</span>
          <span className="icon-upload"></span>
        </button>
      )}
    </li>
  );
});
