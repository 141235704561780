import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";
import { SearchDataConverter } from "../converters/SearchDataConverter";

export const TCCCollectionDS = {
  createCollection: function (data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPostWithParams("/warehouse/v1.0/collections", data, { performAs: performAsId }),
    );
  },
  searchCollections: function (data, dontUnwrap, performAsId) {
    var params = performAsId ? { ...data, performAs: performAsId } : data;

    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/collections", SearchDataConverter.addParameters(params)),
      false,
      dontUnwrap,
    );
  },
  setCollection: function (data, params) {
    return ResponseProcessor.process(RequestBuilder.doPut("/warehouse/v1.0/collections/" + data.id, data, params));
  },
  updateCollection: function (data, params) {
    return ResponseProcessor.process(RequestBuilder.doPatch("/warehouse/v1.0/collections/" + data.id, data, params));
  },
  getAttributes: function (collectionId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/collections/" + collectionId + "/attributes", data),
    );
  },
  setAttributes: function (collectionId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/collections/" + collectionId + "/attributes", data),
    );
  },
  updateAttributes: function (collectionId, data, performAsId) {
    var params = performAsId ? { performAs: performAsId } : {};

    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/collections/" + collectionId + "/attributes", data, params),
    );
  },
  getCollection: function (collectionId, data) {
    data = data || {};
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/collections/" + collectionId, data));
  },
  deleteCollection: function (data, params) {
    return ResponseProcessor.process(RequestBuilder.doDelete("/warehouse/v1.0/collections/" + data.id, params));
  },
  updateCollectionChildren: function (collectionId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/collections/" + collectionId + "/children", data),
    );
  },
  deleteCollectionChild: function (collectionId, childId) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/collections/" + collectionId + "/children/" + childId),
    );
  },
  setCollectionTranslations: function (id, data) {
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/collections/" + id + "/translations", data),
    );
  },
  getCollectionTranslations: function (id) {
    return ResponseProcessor.process(TCCRequestBuilder.doGet("/warehouse/v1.0/collections/" + id + "/translations"));
  },
  addReview: function (collectionId, review) {
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/collections/" + collectionId + "/reviews", review),
    );
  },
  getReviews: function (collectionId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/collections/" + collectionId + "/reviews"));
  },
  deleteReview: function (collectionId) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/collections/" + collectionId + "/reviews"),
    );
  },
  getSubscribers: function (collectionId) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/collections/" + collectionId + "/followers"),
    );
  },
  getAcl: function (collectionId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/collections/" + collectionId + "/acl", data),
    );
  },
  setAcl: function (collectionId, data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/collections/" + collectionId + "/acl", data, { performAs: performAsId }),
    );
  },
  createBinary: function (collectionId, data, file, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.upload("/warehouse/v1.0/collections/" + collectionId + "/binaries", data, file, {
        performAs: performAsId,
      }),
    );
  },
  createBinaryWithMime: function (collectionId, data, file, mimeType) {
    return ResponseProcessor.process(
      RequestBuilder.upload2("/warehouse/v1.0/collections/" + collectionId + "/binaries", data, file, mimeType),
    );
  },
  getBinaries: function (collectionId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/collections/" + collectionId + "/binaries"));
  },
  getBinary: function (collectionId, binaryName, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/collections/" + collectionId + "/binaries/" + binaryName, data),
    );
  },
  updateBinary: function (collectionId, data, file, fileName) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/collections/" + collectionId + "/binaries/" + fileName, data, file),
    );
  },
  deleteBinary: function (collectionId, binaryName) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/collections/" + collectionId + "/binaries/" + binaryName),
    );
  },
};
