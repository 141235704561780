import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStoreContext, EditorModeStore } from "../../components/editor-mode/editorModeStore";
import { PackagePageStoreContext, PackagePageStore } from "../packagePageStore";
import { DialogStore, DialogContext } from "../../dialogs/dialogStore";

import { ImmutableFlag } from "../../components/immutability/ImmutableFlag";
import { EditTitleDialog } from "../../dialogs/edit/EditTitleDialog";
import { Dialog } from "../../dialogs/Dialog";
import { IResource } from "../../models/dataModel";

/**
 * A component that provides package title functionalities
 */
export const PackageTitle = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const dialog: DialogStore = new DialogStore();
  const packageItem = packagePageStore.getPackage();

  function canEdit() {
    return !!packageItem && packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function editTitle() {
    dialog.open();
  }

  const editPackageTitleButtonText = !!packageItem?.isImmutable
    ? rootStore.getTranslation("immutability.edit_title_move_or_archive")
    : rootStore.getTranslation("shared.catalog_entity_edit.edit_title_or_move_delete");

  return (
    <Fragment>
      {canEdit() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-package-title-dialog"
            title={editPackageTitleButtonText}
            onClick={editTitle}
            className="icon icon-pencil"
          >
            <span>{editPackageTitleButtonText}</span>
          </a>
          <Dialog
            content={
              <EditTitleDialog item={packageItem!} store={packagePageStore} showArchive={!!packageItem?.isImmutable} />
            }
            additionalClass="edit-package-dialog"
          />
        </DialogContext.Provider>
      )}
      <h1 className="details" data-testid="package-title">
        <span>{packagePageStore.getTitle()}</span>
      </h1>
      <ImmutableFlag resource={packageItem as IResource} />
      {!!packageItem?.codeName && (
        <span
          data-testid="codeName"
          className="package-code-name"
          style={{ display: "flex", paddingTop: "0.5em", paddingBottom: "0.5em", color: "#9997ad" }}
        >
          {packageItem.codeName}
        </span>
      )}
    </Fragment>
  );
});
