import { CompatibleSoftwareEnum } from "../models/enums";

/**
 * Metadata constant values
 */
export const metadataConstants = {
  groupedUseCategoriesKeys: {
    workflow: [
      "conceptualDesign",
      "designEngineeringAnalysis",
      "detailing",
      "fabrication",
      "erectionInstallationPour",
      "contracting",
      "scheduling",
      "takeOffEstimation",
    ],
    structures: [
      "concreteStructures",
      "precastConcrete",
      "offshore",
      "steelStructures",
      "timberStructures",
      "reinforcement",
    ],
    drawings: ["drawingCreation", "drawingManagement", "drawingEditing", "drawingAnnotation"],
    modeling: ["viewSimulateNavigate", "compareReviewManageData", "productivity", "interoperability", "systemSetup"],
  },
  itemTypeCategoriesKeys: [
    "3dProduct",
    "customComponent",
    "tool",
    "profile",
    "material",
    "bolt",
    "rebar",
    "reportTemplate",
    "drawingSetup",
    "modelSetup",
    "environmentFile",
    "modelTemplate",
    "shape",
  ],
  measurementUnitKeys: ["0", "1"],
  testedVersionsKeys: [
    "NVS",
    {
      software: CompatibleSoftwareEnum.TEKLA_STRUCTURES,
      versions: [
        "TS2025",
        "TS2024",
        "TS18",
        "TS17",
        "TS16",
        "TS15",
        "TS14",
        "TS13",
        "TS12",
        "TS11",
        "TS10",
        "TS9",
        "TS8",
        "TS7",
        "TS6_1",
        "TS6",
        "TS5_1",
        "TS5",
        "TS4_1",
        "TS4",
        "TS3_1",
        "TS3",
      ],
    },
    {
      software: CompatibleSoftwareEnum.TEKLA_POWERFAB,
      versions: ["PF_2025", "PF_2024i", "PF_2024", "PF_2023i", "PF_2023", "PF_2022i", "PF_2022"],
    },
    {
      software: CompatibleSoftwareEnum.TEKLA_TEDDS,
      versions: ["Tedds_2025", "Tedds_2024", "Tedds_2023", "Tedds_2022"],
    },
    {
      software: CompatibleSoftwareEnum.TEKLA_STRUCTURAL_DESIGNER,
      versions: ["TSD_2025", "TSD_2024", "TSD_2023", "TSD_2022"],
    },
  ],
  qualityKeys: ["experimental", "candidate", "approved", "standard"],
  platformKeys: ["WIN_X64", "WIN_X86", "ANY"],
  contentItemKeys: [
    "other",
    "run",
    "bolt",
    "component",
    "drawing",
    "material",
    "profile",
    "mesh",
    "rebar",
    "shape",
    "geometry",
    "modeltemplate",
    "cloningtemplate",
    "noaction",
    "tsep",
  ],
  softwareProductsOptionsKeys: [
    CompatibleSoftwareEnum.TEKLA_STRUCTURES,
    CompatibleSoftwareEnum.TRIMBLE_CONNECT,
    CompatibleSoftwareEnum.TEKLA_TEDDS,
    CompatibleSoftwareEnum.TEKLA_STRUCTURAL_DESIGNER,
    CompatibleSoftwareEnum.TEKLA_POWERFAB,
    CompatibleSoftwareEnum.SKETCHUP,
    CompatibleSoftwareEnum.OPEN_BIM,
    CompatibleSoftwareEnum.STANDALONE,
  ],
  compatibleOperatingSystemsKeys: ["win10", "win11"],
  supportedLanguagesKeys: [
    "en",
    "zh-Hans",
    "zh-Hant",
    "cs",
    "da",
    "nl",
    "no",
    "fi",
    "fr",
    "de",
    "hu",
    "it",
    "ja",
    "ko",
    "pl",
    "pt",
    "pt-br",
    "ru",
    "es",
    "sv",
    "other",
  ],
  alerts: {
    acceptedAttributePropertyNotifications: ["description", "relatedContent", "contentDownloadUrl"],
    acceptedAttributeCategoryNotifications: {
      support: ["support"],
      licenses: ["licenses"],
      searchCriteria: ["measurementUnits", "locationRestrictions", "supportedLanguages", "testedVersions", "ratings"],
      groupCategory: ["itemTypeCategories", "useCategories"],
    },
  },
};
