import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import { RootStore, RootContext } from "../stores/rootStore";

/**
 * A component that displays unauthorized page.
 */
export const Unauthorized = observer(() => {
  const store: RootStore = useContext(RootContext);
  const userStore = store.getUserStore();

  return (
    <div className="landing" id="content">
      <section className="hero hero-intro">
        <h1>{store.getTranslation("shared.auth.handle_unauthorized.unauthorized")}</h1>
        <p>{store.getTranslation("shared.auth.handle_unauthorized.please_log_in")}</p>
        <button className="button-large-padding" data-testid="register" onClick={() => userStore.register()}>
          {store.getTranslation("mainNavigation.register")}
        </button>
        <button className="button-large-padding" data-testid="login" onClick={() => userStore.login()}>
          {store.getTranslation("mainNavigation.login")}
        </button>
      </section>
    </div>
  );
});
