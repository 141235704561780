import { action, observable, makeObservable } from "mobx";
import _ from "underscore";

import { createStoreContext, RootStore } from "../../../stores/rootStore";
import { IGroupItem } from "../itemBrowserStore";

export enum TreeNodeTypeEnum {
  FILE = "file",
  FOLDER = "folder",
}

/**
 * TreeNode class
 */
export class TreeNode {
  /** List of children under the tree node */
  @observable private children: TreeNode[] = [];

  /** Flag that marks if the node is collapsed */
  @observable private collapsed = true;

  /** The node data item */
  private data: IGroupItem;

  /** Flag that indicates that the node has been selected for export */
  @observable private exported = false;

  /** Flag that marks the node as transported by upper level. */
  @observable private exportedByUpperLevel = false;

  /** Parent node */
  private parent: TreeNode | undefined;

  /** Stores the tree this node belongs to */
  private tree: TreeViewStore;

  /** Type of the node (file/folder) */
  private type: TreeNodeTypeEnum;

  /** Stores the visual style of the node */
  @observable private visualStyle = "";

  /**
   * Constructor for the TreeNode
   * @param item node item
   * @param type type of the item
   * @param parent parent node
   */
  public constructor(tree: TreeViewStore, item: IGroupItem | null, type: TreeNodeTypeEnum, parent?: TreeNode) {
    makeObservable(this);
    this.tree = tree;
    this.data = item || { name: "", path: null, type: "" };
    this.type = type;
    this.parent = parent || undefined;

    this.updateVisualStyle(false);
  }

  /**
   * Tells if the node can be selected for export
   */
  public canSelect(): boolean {
    return (
      this.type === TreeNodeTypeEnum.FILE || (this.type === TreeNodeTypeEnum.FOLDER && this.data.type === "profile")
    );
  }

  /**
   * Marks the export status of the given node.
   * Folder nodes mark the status of their children as well.
   * @param markChildren boolean that indicates whether child nodes should be marked as exported
   */
  @action
  public exportNode(markChildren: boolean) {
    this.exported = !this.exported;

    if (markChildren) {
      this.markChildrenExported(this.exported);
    }

    this.updateVisualStyle(true);
  }

  /**
   * Returns the child nodes of this node
   */
  public getChildren(): TreeNode[] {
    return this.children;
  }

  /**
   * Returns the name of the node
   */
  public getName(): string {
    return this.data.name;
  }

  /**
   * Returns the node id
   */
  public getNodeId(): string {
    return this.data.id || "";
  }

  /**
   * Returns the node type
   */
  public getType(): TreeNodeTypeEnum {
    return this.type;
  }

  /**
   * Returns the visual style of the node
   */
  public getVisualStyle(): string {
    return this.visualStyle;
  }

  /**
   * Checks if the node has children that have been checked for export.
   */
  public hasCheckedChildren(): boolean {
    return _.any(this.children, (child: TreeNode) => {
      return child.isExported() || child.hasCheckedChildren();
    });
  }

  /**
   * Tells wheter the node is collapsed.
   */
  public isCollapsed(): boolean {
    return this.collapsed;
  }

  /**
   * Tells wheter the node is marked to be exported.
   */
  public isExported(): boolean {
    return this.exported || this.exportedByUpperLevel;
  }

  /**
   * Returns if the node is exported by an upper level.
   */
  public isExportedByUpperLevel(): boolean {
    return this.exportedByUpperLevel;
  }

  /**
   * Marks children of the node as exported.
   * @param flag boolean that indicates if the children have been exported.
   */
  @action
  public markChildrenExported(flag: boolean) {
    _.each(this.children, (child) => {
      child.markExportedByUpperLevel(flag);
      child.markChildrenExported(flag);
    });
  }

  /**
   * Sets the exportedByUpperLevel flag.
   */
  public markExportedByUpperLevel(value: boolean): void {
    this.exportedByUpperLevel = value;
  }

  /**
   * Selects the current node:
   * If node is a folder, collapses/uncollapses it
   * If node is a file, checks it for export
   */
  @action
  public selectNode() {
    if (this.type === TreeNodeTypeEnum.FOLDER) {
      this.collapsed = !this.collapsed;
    } else if (this.type === TreeNodeTypeEnum.FILE) {
      this.tree.checkForExport(this);
    }
  }

  /**
   * Updates the visual style of the checkboxes for the tree
   * @param recursive boolean that marks if recursion should continue to parent
   */
  @action
  public updateVisualStyle(recursive: boolean) {
    recursive = recursive || false;

    this.visualStyle = this.exported ? "icon-checkbox-checked highlight" : "icon-checkbox-unchecked";

    if (this.type === TreeNodeTypeEnum.FOLDER && this.hasCheckedChildren() && !this.exported) {
      this.visualStyle = "icon-checkbox-partial";
    }

    if (recursive && this.parent != null) {
      this.parent.updateVisualStyle(true);
    }
  }
}

/**
 * The main tree class.
 */
export class TreeViewStore {
  /** Stores the root node of the tree */
  private rootNode: TreeNode;
  /** Stores the root store object */
  private rootStore: RootStore;

  /**
   * Constructor for the Tree class
   */
  public constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.rootNode = new TreeNode(this, null, TreeNodeTypeEnum.FOLDER);
  }

  /**
   * Checks a selected node to be exported
   * @param selectedNode the node selected for export
   */
  public checkForExport(selectedNode: TreeNode) {
    if (selectedNode.isExportedByUpperLevel()) {
      return;
    }

    if (selectedNode.getType() === TreeNodeTypeEnum.FOLDER) {
      if (selectedNode.canSelect()) {
        selectedNode.exportNode(true);
      } else {
        this.rootStore
          .getNotificationChannelStore()
          .notice(this.rootStore.getTranslation("shared.treeview.cannot_export_this_container"));
      }
    } else if (selectedNode.getType() === TreeNodeTypeEnum.FILE) {
      selectedNode.exportNode(false);
    }
  }

  /**
   * Creates new nodes for the tree based on given item.
   * @param fedItem item that a node is created for
   */
  public feed(fedItem: IGroupItem) {
    let currentContainer = this.rootNode;
    const pathParts = fedItem.path || [];
    const itemType = fedItem.type;

    if (pathParts.length > 0) {
      let pathBuilder = "";
      _.each(pathParts, (part: string) => {
        pathBuilder += part.replace("/", "\n") + "/";

        let item: TreeNode | undefined = _.find(currentContainer.getChildren(), (child: TreeNode) => {
          return child.getName() === part;
        });

        if (_.isUndefined(item)) {
          let itemId = "";

          switch (itemType) {
            case "bolt":
              itemId = "tscatalog://BoltAssembly?item=" + pathBuilder;
              break;
            case "component":
              itemId = "tscatalog://Component?item=" + pathBuilder;
              break;
            case "drawing":
              itemId = "tscatalog://Drawing?item=" + pathBuilder;
              break;
            case "material":
              itemId = "tscatalog://Material?item=" + pathBuilder;
              break;
            case "mesh":
              itemId = "tscatalog://Mesh?item=" + pathBuilder;
              break;
            case "profile":
              itemId = "tscatalog://Profile?item=" + pathBuilder;
              break;
            case "rebar":
              itemId = "tscatalog://Rebar?item=" + pathBuilder;
              break;
            case "shape":
              itemId = "tscatalog://Shape?item=" + pathBuilder;
              break;
          }

          item = new TreeNode(
            this,
            { id: itemId, name: part, type: itemType, path: null },
            TreeNodeTypeEnum.FOLDER,
            currentContainer,
          );

          this.sortedPush(currentContainer.getChildren(), item);
        }

        currentContainer = item;
      });
    }

    const item = new TreeNode(this, fedItem, TreeNodeTypeEnum.FILE, currentContainer);
    this.sortedPush(currentContainer.getChildren(), item);
  }

  /** Returns the root node of the tree */
  public getRoot() {
    return this.rootNode;
  }

  /**
   * Inserts the given item to a list of elements & then sorts the list based on name.
   * @param array array of nodes
   * @param element node to push
   */
  private sortedPush(array: TreeNode[], element: TreeNode) {
    array.push(element);
    array.sort((a, b) => {
      if (a.getName() > b.getName()) return 1;
      if (b.getName() > a.getName()) return -1;
      return 0;
    });
  }
}

export const TreeViewStoreContext = createStoreContext<TreeViewStore>(TreeViewStore);
