import * as React from "react";
import { useContext } from "react";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext } from "../stores/rootStore";

export const UsageBasics = () => {
  const store = useContext(RootContext);

  return (
    <article className="about-usage_basics">
      <header>
        <h2>{store.getTranslation("about.usage_basics.title")}</h2>
      </header>
      <section id="usingContent">
        <h3>{store.getTranslation("about.usage_basics.using_content.title")}</h3>
        <p>{store.getTranslation("about.usage_basics.using_content.description_1")}</p>
        <p>{store.getTranslation("about.usage_basics.using_content.description_2")}</p>
        <p>{store.getTranslation("about.usage_basics.using_content.description_3")}</p>
        <p>{store.getTranslation("about.usage_basics.using_content.description_4")}</p>
      </section>
      <section id="addingContent">
        <h3>{store.getTranslation("about.usage_basics.adding_content.title")}</h3>
        <p>
          <TranslatedHtml entry="about.usage_basics.adding_content.description_1" />
        </p>
        <p>{store.getTranslation("about.usage_basics.adding_content.description_2")}</p>
      </section>
      <section id="collection">
        <h3>{store.getTranslation("about.usage_basics.collection.title")}</h3>
        <ul className="feature-list">
          <li>{store.getTranslation("about.usage_basics.collection.feature_1")}</li>
          <ul className="feature-list">
            <li>{store.getTranslation("about.usage_basics.collection.feature_1_1")}</li>
          </ul>
          <li>{store.getTranslation("about.usage_basics.collection.feature_2")}</li>
          <li>{store.getTranslation("about.usage_basics.collection.feature_3")}</li>
        </ul>
      </section>
      <section id="contentItem">
        <h3>{store.getTranslation("about.usage_basics.content-item.title")}</h3>
        <ul className="feature-list">
          <li>{store.getTranslation("about.usage_basics.content-item.feature_1")}</li>
          <li>{store.getTranslation("about.usage_basics.content-item.feature_2")}</li>
        </ul>
      </section>
      <section id="version">
        <h3>{store.getTranslation("about.usage_basics.version.title")}</h3>
        <ul className="feature-list">
          <li>{store.getTranslation("about.usage_basics.version.feature_1")}</li>
        </ul>
      </section>
    </article>
  );
};

export const usageBasicsNavigationInfo = {
  name: "usage_basics",
  component: <UsageBasics />,
  translationId: "about.navigation.usage_basics",
  subnavigation: [
    {
      name: "usingContent",
      translationId: "about.navigation.usage_basics__using-content",
    },
    {
      name: "addingContent",
      translationId: "about.navigation.usage_basics__adding-content",
    },
    {
      name: "collection",
      translationId: "about.navigation.usage_basics__collection",
    },
    {
      name: "contentItem",
      translationId: "about.navigation.usage_basics__content-item",
    },
    {
      name: "version",
      translationId: "about.navigation.usage_basics__version",
    },
  ],
};
