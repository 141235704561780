import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../stores/rootStore";
import { PackagePageStoreContext } from "./packagePageStore";
import { DialogStore, DialogContext } from "../dialogs/dialogStore";
import { IClient, IVersion } from "../models/dataModel";
import { InsertIntoModelDialog } from "../dialogs/insert-into-model/InsertIntoModelDialog";
import { TOSDialog } from "../dialogs/TOSDialog";
import { Installer } from "../utils/Installer";

const InsertIntoModelButtonWithDialog = observer(({ active, version }: { active: boolean; version: IVersion }) => {
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  return (
    <Fragment>
      {packageItem && (
        <DialogContext.Provider value={new DialogStore()}>
          <InsertIntoModelDialog active={active} installationStatus={packagePageStore.getInstallationStatus(version)} />
        </DialogContext.Provider>
      )}
    </Fragment>
  );
});

const InsertIntoModelButton = observer(({ active, version }: { active: boolean; version: IVersion }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const localServiceStore = rootStore.getLocalServiceStore();
  const clients = localServiceStore.getLocalService().clients;

  function installVersion(version: IVersion) {
    Installer.installVersion(rootStore, packageItem!, version, clients[0]);
  }

  return (
    <button disabled={!active} data-testid="install-version-button" onClick={() => installVersion(version)}>
      <span className="icon icon-install"></span>
      <span>{rootStore.getTranslation("actions.insert")}</span>
    </button>
  );
});

/**
 * A component that displays download version information
 */
export const DownloadVersion = observer(({ version }: { version: IVersion }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();
  const localServiceStore = rootStore.getLocalServiceStore();
  const dialog: DialogStore = new DialogStore();
  const userStore = rootStore.getUserStore();
  const massDownloadClients: IClient[] = localServiceStore.getDownloadCapableClients();
  const clients: IClient[] = localServiceStore.getLocalService().clients;
  const tsepBinariesExist = packagePageStore.versionHasTsepBinaries(version);

  function canInstall(): boolean {
    return isLocalServiceAccessible() && isTSAccessible() && clientsFound();
  }

  function isLocalServiceAccessible(): boolean {
    return localServiceStore.isLocalServiceAccessible();
  }

  function isTSAccessible(): boolean {
    return localServiceStore.isTSAccessible();
  }

  function clientsFound(): boolean {
    return tsepBinariesExist ? massDownloadClients.length > 0 : clients.length > 0;
  }

  function shouldUseInstallDialog(): boolean {
    if (tsepBinariesExist) {
      return massDownloadClients && massDownloadClients.length > 1;
    } else {
      return clients && clients.length > 1;
    }
  }

  function canDownload(): boolean {
    return !!packageItem && rootStore.getUserStore().canDownload(packageItem);
  }

  function performDownload() {
    Installer.downloadVersion(rootStore, packageItem!, version);
  }

  function downloadVersion() {
    if (userStore.isUserLoggedIn() && !userStore.hasSeenTOS()) {
      dialog.open();
    } else {
      performDownload();
    }
  }

  function shouldShowTsepInfo(): boolean {
    return isLocalServiceAccessible() && isTSAccessible() && tsepBinariesExist && massDownloadClients.length == 0;
  }

  function showInsertIntoModel(): boolean {
    return !(
      packagePageStore.versionHasNoActionBinaries(version) && packagePageStore.versionHasFileType(version, "exe")
    );
  }

  return (
    <Fragment>
      {canDownload() && (
        <ul data-testid="download-version">
          {showInsertIntoModel() && (
            <li data-testid="insert-into-model">
              {shouldUseInstallDialog() ? (
                <InsertIntoModelButtonWithDialog active={canInstall()} version={version} />
              ) : (
                <InsertIntoModelButton active={canInstall()} version={version} />
              )}
              {!isLocalServiceAccessible() && (
                <div className="small">{rootStore.getTranslation("details.verify_service_plugin_is_running")}</div>
              )}
              {isLocalServiceAccessible() && !isTSAccessible() && (
                <div className="small">{rootStore.getTranslation("details.verify_ts_connection")}</div>
              )}
              {shouldShowTsepInfo() && (
                <div className="small">{rootStore.getTranslation("details.requires_newer_versions")}</div>
              )}
            </li>
          )}
          <li>
            <a data-testid="download-version-button" className="download" onClick={() => downloadVersion()}>
              {rootStore.getTranslation("actions.download")}
            </a>
          </li>
          <DialogContext.Provider value={dialog}>
            <TOSDialog confirmAction={performDownload} />
          </DialogContext.Provider>
        </ul>
      )}
    </Fragment>
  );
});
