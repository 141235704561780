import { action, observable, runInAction, toJS, makeObservable } from "mobx";
import _ from "underscore";

import { ScanResultsChecker } from "../../utils/ScanResultsChecker";
import { TCCUserDS } from "../../js/data-source/TCCUserDS";
import { TCCOrganizationService } from "../../js/services/TCCOrganizationService";
import { TCCSearchService } from "../../js/services/TCCSearchService";
import { TCCFeatureToggleDS } from "../../js/data-source/TCCFeatureToggleDS";
import { NewRepository } from "../../js/services/NewRepository";

import { createStoreContext, RootStore } from "../../stores/rootStore";

import {
  IBatchVersion,
  ICollection,
  IDropdownOption,
  IBatchEntity,
  IFeatureToggle,
  IFileItem,
  IOrganization,
  IUser,
  ITreeViewCollection,
  ITreeViewContent,
  IJobResult,
  IModifier,
} from "../../models/dataModel";
import { ExternalResourceTypeEnum, JobResultCodeEnum, ObjectTypeEnum } from "../../models/enums";

interface IQuery {
  fq: string;
}

export class AdminStore {
  /**
   * Collection that contains analyst users
   */
  @observable private analystUsersContainerCollection?: ICollection;
  /**
   * Feature toggle dropdown options
   */
  @observable private featureToggleOptions: IDropdownOption[] = [];
  /**
   * Feature toggles
   */
  @observable private featureToggles: IFeatureToggle[] = [];
  /**
   * Packages list
   */
  @observable private packages: IBatchEntity[] = [];
  /**
   * Indicator if data is being processed
   */
  @observable private processing = false;

  /** Flag that marks if store is querying source collections for MoveContent. */
  @observable private queryingSourceCollections = false;

  /** Flag that marks if store is querying target collections for MoveContent. */
  @observable private queryingTargetCollections = false;

  /** Root store */
  private rootStore: RootStore;

  /**
   * Selected featre toggle
   */
  @observable private selectedFeatureToggle: IFeatureToggle | undefined;

  /** Validator to test user ID's against */
   
  private uidValidator =
    /^([a-zA-Z0-9_-]){8}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){12}$/;

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Resolves if any of packages is selected
   * @returns true if selected
   */
  public binariesAreSelected(): boolean {
    return _.size(this.getSelectedBinaries()) > 0;
  }

  /**
   * Checks if any source collections are selected.
   * @param sourceCollections list of source collections
   * @returns true/false
   */
  public collectionsAreSelected(sourceCollections: ITreeViewCollection[]): boolean {
    const selectedCollections = _.filter(sourceCollections, (collection: ITreeViewCollection) => {
      return collection.selected;
    });
    return !!selectedCollections && selectedCollections.length > 0;
  }

  /**
   * Creates new feature toggle
   * @params toggleName The name of the new toggle to be created
   */
  public async createFeatureToggle(toggleName) {
    try {
      const featureToggle: IFeatureToggle = {
        clientId: "twh",
        description: toggleName + " feature toggle",
        name: toggleName,
        isEnabled: false,
        percentage: 0,
        tag: "",
      };
      await TCCFeatureToggleDS.createFeatureToggle(featureToggle);
      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.feature_added"));
      setTimeout(() => {
        this.initFeatureToggles();
      }, 1000);
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.feature_add_failed"));
    }
  }

  /**
   * Fetches collections and packages for the selected source.
   * @param source selected source (user/organization)
   * @returns collections for source, with packages and versions
   */
  @action
  public async fetchCollectionsAndPackages(source: IUser | IOrganization): Promise<ITreeViewCollection[]> {
    let collections: ITreeViewCollection[] = [];
    try {
      this.queryingSourceCollections = true;
      collections = await NewRepository.getCollectionsByCreator(
        source,
        true,
        this.rootStore.getUserStore().getCurrentUser()?.id,
      );
      await Promise.all(
        _.map(collections, async (collection: ITreeViewCollection) => {
          const packages: ITreeViewContent[] = await this.getPackagesAndVersions(collection);
          collection.packages = packages;
        }),
      );
    } finally {
      runInAction(() => {
        this.queryingSourceCollections = false;
      });
    }
    
    return collections;
  }

  /**
   * Fetches collections for selected target.
   * @param target selected target (user/organization)
   * @returns the collections for target user/organization
   */
  public async fetchCollectionsForTarget(target: IUser | IOrganization): Promise<ICollection[]> {
    this.queryingTargetCollections = true;
    let collections: ICollection[] = [];

    try {
      collections = await NewRepository.getCollectionsByCreator(
        target,
        true,
        this.rootStore.getUserStore().getCurrentUser()?.id,
      );
    } finally {
      this.queryingTargetCollections = false; 
    }
    
    return collections;
  }

  /**
   * Filters the selected item from a list of sources/targets.
   * @param list list of sources/target to filter
   * @param item the item to filter out
   */
  public filterItemFromList(
    list: Array<IUser | IOrganization>,
    item?: IUser | IOrganization,
  ): Array<IUser | IOrganization> {
    if (list && list.length > 0 && item)
      list = _.reject(list, (listItem: IUser | IOrganization) => {
        return listItem.id === item.id;
      });
    return list;
  }

  /**
   * Gets analyst users container collection
   * @returns collection
   */
  public getAnalystUsersContainerCollection(): ICollection | undefined {
    return this.analystUsersContainerCollection;
  }

  /**
   * Gets feature toggle options for dropdown
   * @returns options list
   */
  public getFeatureToggleOptions(): IDropdownOption[] {
    return this.featureToggleOptions;
  }

  /**
   * Gets packages
   * @returns list of packages
   */
  public getPackages(): IBatchEntity[] {
    return this.packages;
  }

  /**
   * Returns selected binaries
   * @returns list of binaries
   */
  public getSelectedBinaries(): IFileItem[] {
    let selectedBinaries: IFileItem[] = [];
    _.each(this.packages, (packageItem) => {
      _.each(packageItem.versions || [], (version) => {
        const binaries = _.filter(version.binaries, (binary: IFileItem) => {
          return !!binary.selected;
        });
        if (binaries && binaries.length > 0) {
          selectedBinaries = selectedBinaries.concat(binaries);
        }
      });
    });
    return selectedBinaries;
  }

  /**
   * Gets selected feature toggle
   * @returns Feature toggle or undefined if not selected
   */
  public getSelectedFeatureToggle(): IFeatureToggle | undefined {
    return this.selectedFeatureToggle;
  }

  /**
   * Returns selected packages
   * @returns list of packages
   */
  public getSelectedPackages(): IBatchEntity[] {
    return _.filter(this.packages, (packageItem) => !!packageItem.selected);
  }

  /**
   * Returns selected versions
   * @returns list of versions
   */
  public getSelectedVersions(): IBatchVersion[] {
    let versionsList: IBatchVersion[] = [];
    _.each(this.packages, function (packageItem) {
      const versions = _.filter(packageItem.versions || [], (version) => !!version.selected);
      versionsList = versionsList.concat(versions);
    });
    return versionsList;
  }

  @action
  public async getVersionsForPackage(packageItem: IBatchEntity) {
    if (!packageItem.versions) {
      packageItem.versions = await NewRepository.getVersions(packageItem, false, true);
    }
  }

  /**
   * Initializes analyst users container collection
   */
  @action
  public async initAnalystUsersContainerCollection() {
    const data = { sortBy: "createTime ASC" };
    const type = ObjectTypeEnum.TEKLA_WAREHOUSE_ANALYST_USERS_CONTAINER_COLLECTION;
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!user) return;

    try {
      const results = await TCCSearchService.searchCollections(data, false, type, user.id);

      if (!!results && results.length > 0) {
        runInAction(() => {
          this.analystUsersContainerCollection = _.first(results);
        });
        return;
      } else {
        try {
          const analystCollection = (await NewRepository.createAnalystUsersContainerCollection(
            user,
          )) as unknown as ICollection;

          runInAction(() => {
            this.analystUsersContainerCollection = analystCollection;
          });
          return;
        } catch {
          console.log("Failed to create analyst user container collection.");
        }
      }
    } catch {
      console.log("Failed to search collections.");
    }
  }

  /**
   * Intializes feature toggles and dropdown options
   */
  @action
  public async initFeatureToggles() {
    this.featureToggles = await TCCFeatureToggleDS.getFeatureToggles();
    runInAction(() => {
      this.featureToggleOptions = _.map(this.featureToggles, (toggle) => {
        return { label: toggle.name, value: toggle.name };
      });
    });
  }

  /**
   * Resolves if data is being processed
   * @returns true if is processing
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /** Returns if store is querying source collections for MoveContent. */
  public isQueryingSourceCollections(): boolean {
    return this.queryingSourceCollections;
  }

  /** Returns if store is querying target collections for MoveContent. */
  public isQueryingTargetCollections(): boolean {
    return this.queryingTargetCollections;
  }

  /**
   * Migrates content from a selected owner to target owner.
   * This function will be used with the Confirm dialog in react side.
   */
  public async migrateContent(
    sourceCollections: ITreeViewCollection[],
    targetCollection: ICollection,
    selectedSource: IUser | IOrganization,
    selectedTarget: IUser | IOrganization,
  ): Promise<{
    sourceCollections: ITreeViewCollection[];
    targetCollections: ICollection[];
  }> {
    try {
      const selectedCollections = _.filter(sourceCollections, (collection: ITreeViewCollection) => {
        return collection.selected;
      });
      await this.handleCollectionsMigration(selectedCollections, selectedTarget.id);
      await this.handlePackagesMigration(sourceCollections, targetCollection);
      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.content_moved"));
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } 

    return {
      sourceCollections: await this.fetchCollectionsAndPackages(selectedSource),
      targetCollections: await this.fetchCollectionsForTarget(selectedTarget),
    };
  }

  /**
   * Resolves if any of packages is selected
   * @returns true if selected
   */
  public packagesAreSelected(): boolean {
    return _.size(this.getSelectedPackages()) > 0;
  }

  /**
   * Checks from a list of collections if any of its packages have been
   * selected without the parent collection being selected.
   * @param sourceCollections list of source collections
   * @returns true/false
   */
  public packagesSelectedWithoutCollection(sourceCollections: ITreeViewCollection[]): boolean {
    const selectedPackages = this.getSelectedPackagesWithParentNotSelected(sourceCollections);
    return !!selectedPackages && selectedPackages.length > 0;
  }

  /**
   * Does virus rescan for selected binaries
   */
  @action
  public async rescanSelectedBinaries() {
    const currentUser = this.rootStore.getUserStore().getCurrentUser();
    if (!!currentUser) {
      try {
        const promises: Array<Promise<void>> = [];
        this.processing = true;
        _.each(this.getSelectedBinaries(), (binary) => {
          promises.push(NewRepository.rescanBinary(binary, currentUser!.id));
        });
        await Promise.all(promises);
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("profile.admin.binaries_scan_started"));

        runInAction(() => {
          this.processing = false;
        });
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
      }
    }
  }

  /**
   * Resets selected binaries virus scan result status to ok
   */
  @action
  public async resetSelectedBinariesAsOk() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        const promises: Array<Promise<void>> = [];
        this.processing = true;
        _.each(this.getSelectedBinaries(), (binary: IFileItem) => {
          const queryData = {
            binary: binary || null,
            jobId: this.getFailedJobIdFromBinary(binary) || "",
            status: "SUCCESS",
            resultCode: "SCAN_CLEAN",
          };

          promises.push(
            NewRepository.updateBinaryScanJobState(queryData).then(() => {
              const version = this.getVersionForBinary(binary);
              if (version) {
                if (this.shouldUpdateVersionAfterBinaryReset(version)) {
                  version.isHidden = false;
                  NewRepository.updateVersion(version, user as IModifier);
                }
              }
            }),
          );
        });
        await Promise.all(promises);
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("profile.admin.binaries_state_updated"));

        runInAction(() => {
          this.processing = false;
        });
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
      }
    }
  }

  /**
   * Saves selected feature toggle values
   */
  public async saveSelectedFeatureToggle() {
    try {
      await TCCFeatureToggleDS.updateFeatureToggle(this.selectedFeatureToggle);
      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.feature_updated"));
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.feature_update_failed"));
    }
  }

  @action
  public async searchInfectedPackages() {
    this.processing = true;
    this.packages = [];
    try {
      const versions: IBatchVersion[] = await NewRepository.getInfectedEntities();

      _.each(versions, async (version) => {
        this.fetchPackageForVersion(version);
      });
    } catch {
      console.log("Error while searching for infected packages.");
    } finally {
      runInAction(() => {
        this.processing = false;
      });
    }
  }

  @action
  public async fetchPackageForVersion(version: IBatchVersion) {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!user && !!version.package && !!version.package.id) {
      version.binaries = ScanResultsChecker.filterInfectedBinaries(toJS([version]));

      try {
        const packageItem = await NewRepository.getPackage({ id: version.package!.id }, user.id);

        runInAction(() => {
          this.addPackageToList(packageItem as IBatchEntity, version);
        });
      } catch {
        console.log("Error while fetching package for version " + version.id);
      }
    }
  }

  /**
   * Depending on search term, performs a query to database to search for organization/user.
   * @param query search term
   */
  public async searchOrganizationsAndUsers(query: string): Promise<{ entries: Array<IUser | IOrganization> }> {
    if (query.indexOf("@") > 0) {
      const fqs = "emailAddress==" + query;
      return await this.performQuery({ fq: fqs }, false);
    } else if (this.isValidUserId(query)) {
      const fqs = "externalId==" + query;
      return await this.performQuery({ fq: fqs }, true);
    } else {
      const fqs = "displayName=like='" + query + "'";
      return await this.performQuery({ fq: fqs }, true);
    }
  }

  /**
   * Selects/unselects all packages
   * @params select true if all should be selected, false if unselected
   */
  @action
  public selectAllBinaries(select: boolean) {
    this.processing = true;
    _.each(this.packages, (packageItem) => {
      _.each(packageItem.versions || [], (version) => {
        _.each(version.binaries, (binary) => {
          binary.selected = select;
        });
      });
    });
    this.processing = false;
  }

  /**
   * Selects/unselects all packages
   * @params select true if all should be selected, false if unselected
   */
  @action
  public selectAllPackages(select: boolean) {
    this.processing = true;
    _.each(this.packages, (entity) => {
      entity.selected = select;
    });
    this.processing = false;
  }

  /**
   * Selects given binary
   * @params binary the item to be selected
   */
  @action
  public selectBinary(binary: IFileItem, selected: boolean) {
    this.processing = true;
    binary.selected = selected;
    this.processing = false;
  }

  /**
   * Selects given package
   * @params packageItem the item to be selected
   */
  @action
  public selectPackage(packageItem: IBatchEntity, selected: boolean) {
    this.processing = true;
    packageItem.selected = selected;
    this.processing = false;
  }

  /**
   * Selects given version
   * @params Version the item to be selected
   */
  @action
  public selectVersion(version: IBatchVersion, selected: boolean) {
    this.processing = true;
    version.selected = selected;
    this.processing = false;
  }

  /**
   * sets selected feature toggle
   * @params toggleName the name of the feature toggle that was selected
   */
  @action
  public setSelectedFeatureToggle(toggleName) {
    const selectedToggle = _.filter(this.featureToggles, (featureToggle) => featureToggle.name === toggleName);
    this.selectedFeatureToggle = selectedToggle[0];
  }

  /**
   * Sets if toggle is enabled or disabled
   * @params toggle The toggle to change
   * @params enabled Whether toggle is enabled or disabled
   */
  @action
  public setToggleEnabled(toggle: IFeatureToggle, enabled: boolean) {
    toggle.isEnabled = enabled;
  }

  /**
   * Sets toggle's usage as percentage
   * @params toggle The toggle to change
   * @params percentage Percentage of users that will use this feature
   */
  @action
  public setTogglePercentage(toggle: IFeatureToggle, percentage: number) {
    toggle.percentage = percentage;
  }

  @action
  public async showVersions(packageItem: IBatchEntity, showVersions: boolean) {
    this.processing = true;
    if (!packageItem.versions) {
      packageItem.versions = await NewRepository.getVersions(packageItem, false, true);
    }
    runInAction(() => {
      packageItem.open = showVersions;
      this.processing = false;
    });
  }

  /**
   * Resolves if any of versions is selected
   * @returns true if selected
   */
  public versionsAreSelected(): boolean {
    return _.size(this.getSelectedVersions()) > 0;
  }

  private addPackageToList(packageToAdd: IBatchEntity, version: IBatchVersion) {
    const filteredPackages: IBatchEntity[] = _.filter(this.packages, (packageItem) => {
      return packageItem.id === packageToAdd.id;
    });
    if (filteredPackages && filteredPackages.length === 1) {
      if (!!filteredPackages[0].versions) {
        filteredPackages[0].versions.push(version);
      } else {
        filteredPackages[0].versions = [version];
      }
    } else {
      packageToAdd.versions = [];
      packageToAdd.versions.push(version);
      this.packages.push(packageToAdd);
    }
  }

  /**
   * Filters deleted users from a list of users
   * @param userEntries list of users to be filtered
   * @returns list of filtered users
   */
  private async filterDeletedUsersFromList(userEntries: IUser[]): Promise<IUser[]> {
    const filteredUsers: IUser[] = [];

    await Promise.all(
      userEntries.map(async (userEntry) => {
        const res = await TCCUserDS.getUser(userEntry.id);
        if (res) filteredUsers.push(userEntry);
      }),
    );

    return filteredUsers;
  }

  private filterInvalidPackages() {
    this.packages = _.filter(this.packages, (packageItem) => {
      return !packageItem.collection;
    });
  }

  @action
  private filterOutPrivatePackages() {
    this.packages = _.reject(this.packages, (packageItem) => {
      return (
        packageItem.visibility === "private" &&
        !!packageItem.creator &&
        packageItem.creator.externalResourceType === ExternalResourceTypeEnum.USER
      );
    });
  }

  private filterOutSelectedPackages() {
    _.each(this.getSelectedPackages(), (selectedPackage) => {
      this.packages = _.reject(this.packages, (packageItem) => {
        return packageItem.id === selectedPackage.id;
      });
    });
  }

  private getFailedJobIdFromBinary(binary: IFileItem): string | undefined {
    const jobList =
      !!binary.jobResults &&
      _.filter(binary.jobResults, (jobResult: IJobResult) => {
        return jobResult.binary === binary.id && jobResult.resultCode === JobResultCodeEnum.INFECTED;
      });
    return jobList[0].id;
  }

  private getPackageFromList(fetchedPackage: IBatchEntity): IBatchEntity[] {
    return _.filter(this.packages, (packageItem: IBatchEntity) => {
      return packageItem.id === fetchedPackage.id;
    });
  }

  /**
   * Fetches packages and versions for the selected collection.
   * @param collection selected collection
   */
  private async getPackagesAndVersions(collection: ITreeViewCollection): Promise<ITreeViewContent[]> {
    const packages: ITreeViewContent[] = await NewRepository.getPackages(
      collection,
      true,
      this.rootStore.getUserStore().getCurrentUser()?.id,
    );

    await Promise.all(
      _.map(packages, async (packageItem: ITreeViewContent) => {
        const versions: IBatchVersion[] = await NewRepository.getVersions(packageItem, true);
        packageItem.versions = versions;
      }),
    );

    return packages;
  }

  /**
   * Goes through source collections and returns list of selected packages from unselected collections.
   * @param sourceCollections list of source collections
   * @returns list of packages to migrate
   */
  private getSelectedPackagesWithParentNotSelected(sourceCollections: ITreeViewCollection[]): IBatchEntity[] {
    const selectedPackages: IBatchEntity[] = [];
    const collections: ITreeViewCollection[] = _.filter(sourceCollections, (collection: ITreeViewCollection) => {
      return !collection.selected;
    });
    _.each(collections, (collection: ITreeViewCollection) => {
      _.each(collection.packages, (packageItem: ITreeViewContent) => {
        if (packageItem.selected) {
          selectedPackages.push(packageItem as IBatchEntity);
        }
      });
    });
    return selectedPackages;
  }

  /**
   * Searches the TCCUsersDS for users that match the query items.
   * @param query query item
   * @returns a list of users that match the query
   */
  private async getUsers(query: IQuery): Promise<IUser[]> {
    const res = await TCCUserDS.getUsers(query);
    const userList: IUser[] = await this.filterDeletedUsersFromList(res.entries);
    return userList;
  }

  private getVersionForBinary(binary: IFileItem): IBatchVersion | undefined {
    let versionItem;
    _.each(this.packages, (packageItem) => {
      _.each(packageItem.versions || [], (version) => {
        if (binary.versionId && binary.versionId == version.id) {
          versionItem = version;
        }
      });
    });
    return versionItem;
  }

  /**
   * Migrates selected collections and their contents to new owner.
   * @param selectedCollections list of selected collections
   * @param selectedTargetId id of the new owner
   */
  private async handleCollectionsMigration(selectedCollections: ITreeViewCollection[], selectedTargetId: string) {
    return Promise.all(
      _.map(selectedCollections, async (collection: ITreeViewCollection) => {
        await this.setCollectionOwnerRecursively(collection, selectedTargetId);
      }),
    );
  }

  /**
   * Moves content from a source collection to target collection.
   * @param sourceCollections list of source collections
   * @param targetCollection target collection to migrate content to
   */
  private async handlePackagesMigration(sourceCollections: ITreeViewCollection[], targetCollection: ICollection) {
    const selectedPackages: IBatchEntity[] = this.getSelectedPackagesWithParentNotSelected(sourceCollections);

    return Promise.all(
      _.map(selectedPackages, async (packageItem: IBatchEntity) => {
        await this.transferPackage(packageItem, sourceCollections, targetCollection);
      }),
    );
  }

  /**
   * Checks if the user id is valid.
   * @param userId user id
   * @returns true/false
   */
  private isValidUserId(userId: string): boolean {
    return this.uidValidator.test(userId);
  }

  /**
   * Searches system for users or organizations that match the query.
   * @param query terms by which system is searched
   * @param queryOrganizations boolean that indicates if organizations should be included to search
   * @returns an object containing found users and organizations that match the query
   */
  private async performQuery(
    query: IQuery,
    queryOrganizations: boolean,
  ): Promise<{ entries: Array<IUser | IOrganization> }> {
    let userResults: IUser[] = [];
    let orgResults: IOrganization[] = [];
    const allResults = { entries: [] as Array<IUser | IOrganization> };

    try {
      const res: IUser[] = await this.getUsers(query);
      userResults = res;

      if (queryOrganizations) {
        const orgRes = await TCCOrganizationService.search(query);
        orgResults = orgRes.entries;
      }
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    }

    allResults.entries = (orgResults as Array<IUser | IOrganization>).concat(
      userResults as Array<IUser | IOrganization>,
    );

    if (allResults.entries.length <= 0) {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("errors.no_matches_found"));
    }

    return allResults;
  }

  /**
   * Sets new collection owner to given collection.
   * @param collection collection to update
   * @param selectedTargetId id of the new owner
   */
  private async setCollectionOwnerRecursively(collection: ITreeViewCollection, selectedTargetId: string) {
    collection.creatorId = selectedTargetId;
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!user) await NewRepository.updateCollection(collection, user);
    return this.updatePackagesAndVersions(collection.packages, selectedTargetId);
  }

  private shouldUpdateVersionAfterBinaryReset(version: IBatchVersion): boolean {
    let shouldUpdateVersion = true;
    _.each(version.binaries, (binary) => {
      const foundBinary = _.find(this.getSelectedBinaries(), (selectedBinary) => {
        return selectedBinary.id == binary.id;
      });
      if (_.isUndefined(foundBinary)) {
        shouldUpdateVersion = false;
      }
    });
    return shouldUpdateVersion;
  }

  /**
   * Transfers a package from source collection to target collection.
   * @param packageItem package to transfer
   * @param sourceCollections list of source collections
   * @param targetCollection target collection
   */
  private async transferPackage(
    packageItem: IBatchEntity,
    sourceCollections: ITreeViewCollection[],
    targetCollection: ICollection,
  ) {
    const user = this.rootStore.getUserStore().getCurrentUser();
    const sourceCollection: ITreeViewCollection[] = _.filter(sourceCollections, (collection) => {
      return collection.id === packageItem.collection.id;
    });
    if (sourceCollection && sourceCollection.length === 1) {
      return await NewRepository.transferPackage(packageItem, sourceCollection[0], targetCollection, user);
    }
  }

  /**
   * Updates a single package item.
   * @param packageItem content package to update
   * @param selectedTargetId id of the new owner
   */
  private async updatePackageAndVersion(packageItem: ITreeViewContent, selectedTargetId: string) {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        await NewRepository.setPackage(packageItem, user);
        await this.updateVersions(packageItem.versions || [], selectedTargetId);
      } catch {
        const errorText = "Error while saving package '" + packageItem.title + "'";
        console.log(errorText);
      }
    }
  }

  /**
   * Updates the new owner for packages and versions.
   * @param packages list of packages to update
   * @param selectedTargetId id of the new owner
   */
  private async updatePackagesAndVersions(packages: ITreeViewContent[], selectedTargetId: string) {
    return Promise.all(
      _.map(packages, async (packageItem: ITreeViewContent) => {
        packageItem.creatorId = selectedTargetId;
        await this.updatePackageAndVersion(packageItem, selectedTargetId);
      }),
    );
  }

  /**
   * Updates data for given version.
   * @param version version to update
   */
  private async updateVersion(version: IBatchVersion) {
    const user = this.rootStore.getUserStore().getCurrentUser();
    if (!!user) await NewRepository.updateVersion(version, user as IModifier);
  }

  /**
   * Updates the creator for given list of versions.
   * @param versions list of versions to update
   * @param selectedTargetId id of the new owner
   */
  private async updateVersions(versions: IBatchVersion[], selectedTargetId: string) {
    return Promise.all(
      _.map(versions, async (version: IBatchVersion) => {
        version.creatorId = selectedTargetId;
        return await this.updateVersion(version);
      }),
    );
  }
}

/**
 * Context object for AdminStore instances.
 */
export const AdminStoreContext = createStoreContext<AdminStore>(AdminStore);
