import { observer } from "mobx-react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import { VersionEditorContext, VersionEditorStore } from "../../versions/versionEditorStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";
import { UploadVersionStore } from "./uploadVersionStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import {
  isValidVersionNumber,
  suggestVersionNumber,
  versionNumberIsUniqueForThePackage,
} from "../../utils/Immutability";

import { Dialog } from "../../dialogs/Dialog";
import { ArchivedFlag } from "../../components/ArchivedFlag";

import { UpdateTypeEnum, UploadStrategyEnum } from "../../models/enums";
import { IVersion } from "../../models/dataModel";
import { ModusTooltip } from "@trimble-oss/modus-react-components";

const UpdateTypeRadioGroup = observer(
  ({ updateType, setUpdateType, setVersionNumber }: { updateType; setUpdateType; setVersionNumber }) => {
    const rootStore: RootStore = useContext(RootContext);
    const versionEditor: VersionEditorStore = useContext(VersionEditorContext);

    useEffect(() => {
      setVersionNumber(suggestVersionNumber(versionEditor.getExistingVersionsWithoutVersionInEdit(true) || [], updateType));
    }, [updateType]);

    return (
      <div
        className="form-group"
        style={{ paddingBottom: "0.5em", paddingTop: "0.5em", display: "flex", flexWrap: "wrap" }}
        data-testid="updateTypeRadioGroup"
      >
        <div className="custom-control custom-radio" style={{ display: "flex", paddingRight: "1em" }}>
          <input
            type="radio"
            checked={updateType == UpdateTypeEnum.MAJOR}
            onChange={() => setUpdateType(UpdateTypeEnum.MAJOR)}
            className="custom-control-input"
            id="majorUpdateRadioButton"
            data-testid="majorUpdateRadioButton"
            name="majorUpdate"
          />
          <ModusTooltip text={rootStore.getTranslation("immutability.major_update_description")} position="top">
            <label
              className="custom-control-label"
              htmlFor="majorUpdateRadioButton"
              style={{ fontSize: "0.75em", paddingLeft: "0.5em" }}
            >
              {rootStore.getTranslation("immutability.major_update")}
            </label>
          </ModusTooltip>
        </div>
        <div className="custom-control custom-radio" style={{ display: "flex", paddingRight: "1em" }}>
          <input
            type="radio"
            checked={updateType == UpdateTypeEnum.MINOR}
            onChange={() => setUpdateType(UpdateTypeEnum.MINOR)}
            className="custom-control-input"
            id="minorUpdateRadioButton"
            data-testid="minorUpdateRadioButton"
            name="minorUpdate"
          />
          <ModusTooltip text={rootStore.getTranslation("immutability.minor_update_description")} position="top">
            <label
              className="custom-control-label"
              htmlFor="minorUpdateRadioButton"
              style={{ fontSize: "0.75em", paddingLeft: "0.5em" }}
            >
              {rootStore.getTranslation("immutability.minor_update")}
            </label>
          </ModusTooltip>
        </div>
        <div className="custom-control custom-radio" style={{ display: "flex", paddingRight: "1em" }}>
          <input
            type="radio"
            checked={updateType == UpdateTypeEnum.PATCH}
            onChange={() => setUpdateType(UpdateTypeEnum.PATCH)}
            className="custom-control-input"
            id="patchUpdateRadioButton"
            data-testid="patchUpdateRadioButton"
            name="patchUpdate"
          />
          <ModusTooltip text={rootStore.getTranslation("immutability.patch_update_description")} position="top">
            <label
              className="custom-control-label"
              htmlFor="patchUpdateRadioButton"
              style={{ fontSize: "0.75em", paddingLeft: "0.5em" }}
            >
              {rootStore.getTranslation("immutability.patch_update")}
            </label>
          </ModusTooltip>
        </div>
      </div>
    );
  },
);

const ExistingVersionsDialog = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const versionEditor: VersionEditorStore = useContext(VersionEditorContext);
  const dialog: DialogStore = useContext(DialogContext);

  const existingVersionNumbers = versionEditor
    .getExistingVersionsWithoutVersionInEdit(true)
    .filter((version: IVersion) => !!version.attributes.versionNumber)
    .sort((a: IVersion, b: IVersion) => a.attributes.versionNumber!.localeCompare(b.attributes.versionNumber!))
    .reverse();

  return (
    <Dialog
      content={
        <div data-testid="existingVersionsDialog" style={{ padding: "1em" }}>
          <h4 style={{ marginTop: "0" }}>{rootStore.getTranslation("versions.existing_versions")}</h4>
          <div style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "40vh" }}>
            <ul style={{ listStyle: "none", lineHeight: "2em" }}>
              {_.map(existingVersionNumbers || [], (version: IVersion, i: number) => {
                const versionNumberSameAsTitle = version.attributes.versionNumber === version.title;

                return (
                  <li key={i} style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      {versionNumberSameAsTitle
                        ? version.attributes.versionNumber
                        : `${version.attributes.versionNumber} (${version.title})`}
                      <ArchivedFlag resource={version} />
                    </span>
                    <hr />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="actions" style={{ display: "flex", justifyContent: "flex-end" }}>
            <button className="button-primary" onClick={() => dialog.close()}>
              {rootStore.getTranslation("shared.confirm.close")}
            </button>
          </div>
        </div>
      }
    />
  );
});

export const VersionNumberField = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();
  const versionEditor: VersionEditorStore = useContext(VersionEditorContext);
  const dialog: DialogStore = new DialogStore();

  const [updateType, setUpdateType] = useState<UpdateTypeEnum>();
  const [versionNumber, setVersionNumber] = useState(() => initVersionNumber());
  const [isInvalid, setIsInvalid] = useState(false);

  const cantEditVersionNumber =
    (form.getUploadStrategy() == UploadStrategyEnum.EDIT_VERSION && version.versionInEditIsImmutable()) || form.getUploadStage() === "publish";

  const hasExistingVersionNumbers =
    versionEditor.getExistingVersionsWithoutVersionInEdit(true).filter((version) => !!version.attributes.versionNumber)
      ?.length > 0;

  function initVersionNumber(): string {
    if (hasExistingVersionNumbers && !updateType) {
      return "";
    } else {
      if (version.getVersionNumber()) {
        return version.getVersionNumber();
      } else {
        return suggestVersionNumber(versionEditor.getExistingVersionsWithoutVersionInEdit(true) || [], updateType);
      }
    }
  }

  useEffect(() => {
    !_.isEmpty(version.getVersionNumber()) && setVersionNumber(version.getVersionNumber());
  }, [version.getVersionNumber()]);

  useEffect(() => {
    if (!!versionNumber && isValidVersionNumber(versionNumber)) {
      setIsInvalid(false);
      version.setVersionNumber(versionNumber);
    } else {
      setIsInvalid(true);
      version.setVersionNumber("");
    }
  }, [versionNumber]);

  return (
    <React.Fragment>
      {cantEditVersionNumber ? (
        <li data-testid={`versionNumberFor-${versionEditor.getVersionForm().getVersionInEdit()?.id}`}>
          <label htmlFor="versionNumber">{rootStore.getTranslation("versions.version_number")}</label>
          <span id="versionNumber">{versionNumber}</span>
        </li>
      ) : (
        <li data-testid="versionNumberField">
          <div className="tooltip-info" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <label className="required" htmlFor="versionNumber">
              {rootStore.getTranslation("versions.version_number")}
            </label>
            <span
              title={`${rootStore.getTranslation("immutability.info")} ${rootStore.getTranslation("versions.version_number_info")}`}
              className="icon help blue"
            />
          </div>
          {hasExistingVersionNumbers && (
            <>
              {!updateType && (
                <span style={{ fontStyle: "italic", fontSize: "14px" }}>
                  {rootStore.getTranslation("versions.select_update_type")}
                </span>
              )}
              <UpdateTypeRadioGroup
                updateType={updateType}
                setUpdateType={setUpdateType}
                setVersionNumber={setVersionNumber}
              />
            </>
          )}
          {(!hasExistingVersionNumbers || !!updateType) && (
            <input
              type="text"
              name="versionNumber"
              required
              value={versionNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVersionNumber(e.target.value)}
              data-testid="versionNumberInput"
            />
          )}
          {((hasExistingVersionNumbers && !!updateType) || !hasExistingVersionNumbers) && (
            <>
              {_.isEmpty(versionNumber) ? (
                <div className="error">
                  <small className="error" data-testid="versionNumberMissing">
                    {rootStore.getTranslation("versions.version_number_validation.missing")}
                  </small>
                </div>
              ) : (
                <div className="error">
                  {isInvalid && (
                    <small className="error" data-testid="versionNumberInvalidFormat">
                      {rootStore.getTranslation("versions.version_number_validation.invalid")}
                    </small>
                  )}
                  {!isInvalid &&
                    !versionNumberIsUniqueForThePackage(
                      versionNumber,
                      versionEditor.getExistingVersionsWithoutVersionInEdit(true),
                    ) && (
                      <small className="error" data-testid="versionNumberAlreadyInUse">
                        {rootStore.getTranslation("versions.version_number_validation.already_in_use")}
                      </small>
                    )}
                </div>
              )}
              {hasExistingVersionNumbers && (
                <DialogContext.Provider value={dialog}>
                  <ExistingVersionsDialog />
                  <a
                    type="button"
                    className="small"
                    data-testid="openExistingVersionNumbersDialog"
                    onClick={() => dialog.open()}
                  >
                    {rootStore.getTranslation("versions.existing_versions")}
                  </a>
                </DialogContext.Provider>
              )}
            </>
          )}
        </li>
      )}
    </React.Fragment>
  );
});
