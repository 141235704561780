
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Fr = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["fr-FR"]) {
      window.locale["fr-FR"] = {};
    }

    _.extend(window.locale["fr-FR"], {
      myDetails: {
        my_roles: "Mes rôles",
        my_roles_link: "Rôles et droits d’accès au Tekla Warehouse contenu",
        my_roles_link_url: "https://support.tekla.com/fr/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Rôles",
        roles: {
          member: "Éditeur de contenu Tekla Warehouse (Employé)",
          employee: "Employé",
          contentEditor: "Éditeur de contenu Tekla Warehouse (Externe)",
        },
      },
      immutability: {
        add_code_name_for_package: "Ajouter un nom de code pour le contenu",
        code_name: "Nom de code",
        immutable: "Immuable",
        archive: "Archiver",
        archive_collection: "Archiver la collection et tout son contenu",
        archive_version: "Archiver la version sélectionnée",
        archive_package: "Archiver le package et tout son contenu",
        archive_dialog_text: "Le contenu archivé ne sera ni visible ni consultable dans Tekla Warehouse. Toutefois, si le contenu a déjà été utilisé avec le Tekla Structures service cloud, ce dernier peut continuer à accéder au contenu.",
        archive_success: function (name: string) {
          return `${name} archivé avec succès`;
        },
        archive_failure: function (name: string) {
          return `L’archivage de ${name} a échoué`;
        },
        archived_content_redirect_message: "Le contenu auquel vous essayez d’accéder a été archivé",
        edit_title_move_or_archive: "Modifier le titre, déplacer ou archiver",
        edit_title_or_archive: "Modifier le titre ou archiver",
        major_update: "Mise à jour majeure",
        major_update_description: "Modifications non rétrocompatibles, telles que l’ajout ou la suppression de fonctionnalités ou d’autres modifications de rupture.",
        minor_update: "Mise à jour mineure",
        minor_update_description: "Changements rétrocompatibles, tels que de nouvelles fonctionnalités ou des améliorations sans rupture des fonctionnalités existantes.",
        patch_update: "Mise à jour du correctif",
        patch_update_description: "Des correctifs rétrocompatibles, tels que des correctifs de sécurité, des correctifs de bogues ou des améliorations de performances, sans nouvelles fonctionnalités.",
        info: "Votre organisation fournit du contenu pour le service Cloud de Tekla Structures. Un nom de code et un numéro de version sont requis pour le contenu du service cloud afin de garantir la compatibilité et la Tekla Structures cohérence.",
        immutable_files_info: "Pour maintenir la cohérence du contenu dans le service cloud, vous ne pouvez pas supprimer ou modifier des fichiers. Si des modifications sont nécessaires, archivez la version actuelle et chargez-en une nouvelle.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Pour plus d’informations, voir <a href="${readMoreUrl}" target="_blank">cette page</a>.`;
        },
        resolve_conflict: {
          title: "Résoudre le conflit",
          description:
            "Les éléments suivants ont été trouvés. Sélectionnez « Conserver existant » pour conserver l’élément existant trouvé dans votre installation.",
          table_header_name: "Nom",
          table_header_type: "Type",
          table_header_action: "Action",
          action_keep_existing: "Conserver existant",
          action_replace: "Remplacer",
          action_done: "Terminé",
        },
        auth: {
          authentication_process_failed: "Échec du processus d’authentification",
          access_denied: "Accès refusé",
          handle_unauthorized: {
            unauthorized: "Non autorisé",
            please_log_in: "Veuillez vous connecter ou vous inscrire",
          },
        },
        translations: {
          edit_translations: "Modifier les traductions",
          edit_translations_info: "Sélectionnez une langue dans la liste, modifiez et enregistrez",
          edit_translations_default_language_info:
            "<b>Défaut</b> est affiché lorsque la langue sélectionnée par l’utilisateur n’est pas disponible",
          edit_content: "Informations sur le contenu",
          edit_support: "Assistance",
          updated_successfully: "Traductions mises à jour avec succès",
          error_on_update: "Erreur lors de la mise à jour des traductions",
          default: "Défaut",
          batch_translations: "Traductions par lot",
          export_import_info:
            "<b>Exportez</b> le modèle de traductions par lot pour le contenu de votre choix, modifiez le modèle en ajoutant les traductions, puis <b>importez</b> le modèle pour enregistrer plusieurs traductions simultanément.",
          import_translations: "Importer",
          export_translations: "Export",
          translations_missing: " (vide)",
          select_content: "Sélectionner des traductions à exporter :",
          import_finished: "Import terminé !",
          select_file: "Sélection fichier",
          select_file_title: "Sélectionnez le fichier à importer :",
          import_from_file: "Importer",
          import_error_info: "Le fichier ne respecte pas le format du modèle :",
          export_selected: "Export",
          invalid_file: "Format de contenu du fichier non valide",
          import_translations_finished: "Traductions importées",
          details_too_long: "Le contenu des détails dépasse la longueur maximale autorisée",
          import_failed: "Échec de l'import des traductions",
          translate_to_other_languages: "Traduire dans d’autres langues",
          import_count: function (count) {
            return "Traductions mises à jour pour " + count + " contenu";
          },
          invalid_language: function (contentId, language) {
            return "Le contenu '" + contentId + "' contient une langue non valide '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return (
              "Le contenu '" + contentId + "' contient un champ de traduction non valide '" + translationField + "'"
            );
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "La valeur du contenu '" +
              contentId +
              "' champ de traduction '" +
              translationField +
              "' dépasse la longueur maximale autorisée"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "Modèle 3D",
          "3d_pic_added": "Modèle 3D ajouté avec succès",
          "3d_pic_updated": "Modèle 3D mis à jour avec succès",
          "3d_pic_removed": "Modèle 3D supprimé avec succès",
          "3d_pic_update_failed": "Échec de la mise à jour du modèle 3D",
          "3d_pic_remove_failed": "Échec de la suppression du modèle 3D",
          "3d_pic_missing":
            "Aucune image 3D pour le contenu. Choisissez l’image en cliquant sur 'Choisir modèle 3D ' ci-dessous.",
          "3d_pic_uploading": "Votre modèle est en cours de chargement. Veuillez patienter...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Cliquez ici pour modifier le contenu",
          updated_successfully: "Mis à jour avec succès",
          update_failed: "Échec de la mise à jour",
          edit_search_criteria: "Modifier les critères de recherche",
          edit_group_and_category: "Modifier le groupe et la catégorie",
          edit_content: "Modifier le contenu",
          edit_thumbnail: "Modifier la miniature",
          edit_thumbnail_descriptive: "Dimensions recommandées : 400 x 300 px",
          edit_description: "Modifier la description",
          edit_download_link: "Ajouter ou modifier le lien de téléchargement partenaire",
          versions_not_shown_to_users:
            "Étant donné que cet élément de contenu dispose d’un lien de téléchargement partenaire, les utilisateurs Tekla Warehouse ne peuvent pas voir les versions que vous avez précédemment ajoutées à l’élément de contenu. Les administrateurs et les utilisateurs Tekla Warehouse disposant de droits de modification peuvent toujours voir toutes les versions de l’élément de contenu.",
          partner_download_link: "Entrez l’URL de téléchargement",
          partner_download_link_disclaimer:
            "Si vous ajoutez un lien de téléchargement partenaire à un élément de contenu existant, les utilisateurs Tekla Warehouse ne pourront plus voir les versions que vous avez précédemment ajoutées à l’élément de contenu. Cette action ne peut pas être annulée.",
          edit_title_or_delete: "Modifier le titre ou effacer",
          edit_title_or_move_delete: "Modifier le titre, déplacer ou effacer",
          edit_support: "Modifier les informations sur l’assistance",
          edit_release_notes: "Modifier la liste des nouveautés",
          edit_content_wysiwyg: "Modifier les détails du contenu",
          edit_content_embed_link: "Plus d’informations dans Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Vous ne pouvez intégrer que des vidéos de YouTube, Vimeo ou Alugha.",
          details_unallowed_embed_info: "Vous pouvez uniquement intégrer du contenu provenant des domaines suivants : YouTube, Vimeo ou Alugha. Les formats d’URL autorisés sont :",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "Lien vidéo non valide. Vous pouvez ajouter des vidéos par URL à partir de YouTube et Vimeo",
          edit_content_vide_code_error: "Format d'insert non valide. Seuls les iframes sont acceptés. Les fournisseurs de vidéos autorisés sont YouTube, Vimeo et Alugha.",
          details_max_length_exceeded: "Le contenu des détails est trop long",
          editor_mode_label: "Mode Éditeur :",
          editor_mode_on: "Oui",
          editor_mode_off: "Non",
          do_not_notify: "Mise à jour secondaire, ne pas avertir les abonnés",
          invalid_tag: "Étiquette non valide",
        },
        transfer_package: {
          header: "Déplacer le contenu",
          actions: {
            move: "Déplacer",
            cancel: "Annuler",
          },
          loading: "Chargement des collections...",
          moving: "Déplacement du contenu",
          select_collection: "Sélectionner une collection",
          content_moved: "Contenu déplacé. Les modifications peuvent être visibles au bout de quelques minutes.",
          operation_failed: "Échec de l’opération",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Contenu lié avec succès dans " + name;
          },
          selected_resources_linked: function (name) {
            return "Contenu sélectionné lié avec succès dans " + name;
          },
          resources_linked_with_errors: "Contenu lié, il y a des erreurs dans une partie du contenu",
          resources_linking_failed: "Échec de la création de liens avec le contenu",
          resource_unlinked:
            "Lien supprimé avec succès. Les modifications peuvent être visibles au bout de quelques minutes.",
          resources_unlinked:
            "Liens supprimés avec succès du contenu sélectionné. Les modifications peuvent être visibles au bout de quelques minutes.",
          resources_unlinked_with_errors: "Liens supprimés, il y a des erreurs dans une partie du contenu",
          resources_unlinking_failed: "Échec de la suppression des liens",
          linking: "Création d’un lien",
          linking_failed: "Échec de la création de lien",
          linking_failed_title: "Le contenu suivant n'a pas pu être lié :",
          unlinking_failed_title: "Le lien du contenu suivant n'a pas pu être supprimé :",
          linking_failed_already_contains:
            "Le contenu a déjà été lié à la collection sélectionnée. Les mises à jour peuvent prendre un certain temps pour s’afficher.",
          linking_failed_already_contains_short: "Le contenu a déjà été lié à la collection sélectionnée",
          unlinking_failed: "Échec de la suppression du lien",
          unlinking_failed_does_not_contain:
            "Le lien de contenu a déjà été supprimé de la collection. Les mises à jour peuvent prendre un certain temps pour s’afficher.",
          unlinking_failed_does_not_contain_short: "Le lien de contenu a déjà été supprimé de la collection",
        },
        notification_channel: {
          title_success: "opération terminée",
          title_notice: "notification",
          title_info: "info",
          title_error: "erreur",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Le téléchargement requiert la maintenance Tekla",
          can_access_maintenance: {
            yes: "Oui",
            no: "Non",
          },
        },
        confirm: {
          title: "Confirmer",
          yes: "Oui",
          no: "Non",
          ok: "OK",
          close: "Fermer",
          dont_show: "Ne plus afficher",
        },
        show_more: " Afficher plus",
        show_less: " Afficher moins",
        spinner_loading: "Chargement",
        spinner_processing: "Traitement...",
        spinner_loading_long_time: "Le chargement du contenu peut prendre plusieurs minutes",
        local_service: {
          info: "Le programme additionnel Tekla Warehouse Service est nécessaire pour créer un contenu local et réseau. Avec Tekla Structures 20.1 SR1 ou version ultérieure, vous pouvez charger du contenu directement à partir de votre modèle.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Le programme additionnel Tekla Warehouse Service</a> " +
            "est nécessaire pour créer un contenu local et réseau.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Le programme additionnel Tekla Warehouse Service</a> " +
            "n’est pas disponible. Le service est nécessaire pour activer les collections locales et réseau.",
          download_to_unlock_all_features_html:
            "Télécharger " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Le programme additionnel Tekla Warehouse Service</a> " +
            "pour déverrouiller toutes les fonctionnalités.",
          service_out_of_date_html:
            "Votre programme additionnel Tekla Warehouse est obsolète. Mettre à jour " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Le programme additionnel Tekla Warehouse Service</a> " +
            "pour déverrouiller toutes les fonctionnalités.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Sélectionner la langue",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Inviter d’autres utilisateurs",
          send_invitation_to_email: "Envoyer une invitation par e-mail",
          add_e_mail_addresses: "Ajouter des adresses e-mail",
          and_assign_role: "et affecter un rôle",
          send_invitation: "Envoyer une invitation",
          invitation_already_sent: "Invitation déjà envoyée !",
          invitation_sent: "Invitation envoyée !",
          update_selected_invitations: "Mettre à jour les invitations sélectionnées",
          new_role: "Nouveau rôle",
          invitations_list_updated: "Liste d’invitations mise à jour !",
        },
        item_browser_title: "Sélectionner des éléments de contenu",
        prerequisites: {
          description_placeholder: "Ajouter la description ici",
          link_name_placeholder: "Ajouter le nom du lien ici",
          add_prerequisites_and_recommendations: "Prérequis et recommandations (facultatif)",
          list_the_profiles_components_files:
            "Répertoriez les profils, les composants, les fichiers ou les modèles nécessaires au bon fonctionnement.",
          link_to_existing_content: "Lien vers le contenu existant",
          please_enter_prerequisite_url: "Veuillez entrer l’URL des prérequis",
          please_enter_valid_prerequisite_url: "Veuillez entrer une URL des prérequis valide",
          missing_http: "http:// manquant",
          add_link: "Ajouter lien",
        },
        treeview: {
          cannot_export_this_container:
            "Impossible d’exporter ce conteneur. Sélectionnez des éléments individuels à la place.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "La taille du fichier doit être inférieure à " + max_size + " Mo";
          },
          "invalid_type.": function (allowed_types) {
            return "Type de fichier non valide.  Le type de fichier doit être l’un des suivants : " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Durée :",
          timeframe_start: "Début :",
          timeframe_end: "Fin :",
          eventtype: "Type d’événement :",

          downloads_daily_by_version: "Téléchargements par version",
          downloads_by_country: "Téléchargements par pays",
          downloads_by_version: "Téléchargements par version",
          downloads_by_version_organization: "Téléchargements par version et organisation",
          downloads_by_organization: "Téléchargements par organisation",
          downloads_daily_by_package: "Téléchargements par élément de contenu",
          views_daily_by_package: "Vues par élément de contenu",
          views_by_country: "Vues par pays",
          views_by_organization: "Vues par organisation",

          downloads_by_package: "Téléchargements par élément de contenu",
          views_by_package: "Vues par élément de contenu",
          downloads_daily_by_collection: "Téléchargements par collection",
          downloads_by_collection: "Téléchargements par collection",
          views_daily_by_collection: "Vues par collection",
          views_by_collection: "Vues par collection",

          events_by_organization_func: function (version) {
            return 'Version "' + version + '" événements par organisation'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Autres",
          sum_all_versions: "Toutes les versions",
          sum_all_packages: "Tous les éléments de contenu",
          sum_all_collections: "Toutes les collections",
          sum_all_organizations: "Toutes les organisations",
          recording_started_at: "Enregistrement de données démarré en juin 2015",
          recording_view_events_started_at: "Enregistrement de données démarré en mars 2017",
          version_title_func: function (title) {
            return 'Version "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title +
              " (" +
              pluralize(count, [count + " téléchargements", count + " téléchargement", count + " téléchargements"]) +
              ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Les chiffres incluent tous les téléchargements terminés.",
          counted_events_info_package: "Les chiffres incluent tous les téléchargements terminés.",
          export: {
            caption: "Exporter analyse",
            select_options: "Sélectionner les options",
            select_timeframe_description: "Veuillez sélectionner ci-dessous une période afin d'exporter l’analyse :",
            select_eventtype_description:
              "Veuillez sélectionner le type d’événement à partir duquel exporter l’analyse :",
          },
        },
        access_rights: {
          editors: "Éditeurs",
          download_access: "Accès en consultation et téléchargement",
          write_user_id_placeholder: "Écrire l’ID utilisateur",
          write_organization_id: "Entrez les ID d’organisation valides. Entrez chaque ID sur une ligne distincte.",
          write_id_placeholder:
            "Saisissez les ID utilisateur, les adresses e-mail des utilisateurs ou les ID d’organisation. Saisissez chaque ID ou adresse e-mail sur une ligne distincte.",
          id_help_html_short:
            "Pour des raisons de sécurité, il n’est pas possible d’effectuer de recherches sur nos utilisateurs.<br/><br/>" +
            "Demandez l’ID d’utilisateur directement à la personne à laquelle vous souhaitez accorder un accès. <br/>" +
            ' L\'ID utilisateur est affiché dans <a href="#/my/">Mes informations</a>.',
          id_help_html:
            "Pour des raisons de sécurité, il n’est pas possible d’effectuer de recherches sur nos utilisateurs.<br/><br/>" +
            "Demandez l’ID d’utilisateur directement à la personne à laquelle vous souhaitez accorder un accès. Pour accorder l’accès à tous les utilisateurs d’une organisation, utilisez l’ID de l’organisation.<br/><br/>" +
            'Les ID d’utilisateur et d’organisation sont affichés dans <a href="#/my/">Mes informations</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Saisissez chaque ID ou adresse e-mail sur une ligne distincte. Vous pouvez saisir " +
              max_rows +
              " lignes à la fois."
            );
          },
          input_too_long: "Le texte que vous avez saisi est trop long.",
          viewer_already_added: function (input) {
            return `${input} a déjà été ajouté en tant que visionneur`;
          },
          wrong_format: function (input) {
            return `${input} n’est pas un ID ou une adresse e-mail valide`;
          },
          candidates_for_keyword: function (keyword) {
            return "Utilisateurs trouvés avec l’adresse e-mail ou l’ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Ajouter nouveau",
        add: "Ajouter",
        delete: "Supprimer",
        set_as_default: "Miniature par défaut",
        select_file: "Sélection fichier",
        choose_option: "Choisir une option",
        info_3D_exists: "Vous avez déjà ajouté un modèle 3D, vous ne pouvez pas ajouter plus d’un modèle au contenu.",
        action_options: {
          image: "Image",
          "3DModel": "Modèle 3D",
          video: "Vidéo",
        },
        video: {
          url: "URL de la vidéo",
          invalid_url: "URL non valide",
        },
      },
      units: {
        bytes: "octets",
        kB: "Ko",
        MB: "Mo",
        GB: "Go",
        TB: "To",
        PB: "Po",
      },
      tools: {
        download: "Télécharger",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " aime", number + " aiment", number + " aime"]);
      },
      main_info: {
        header_title: "Le site Web de Tekla Warehouse a une nouvelle interface!",
        header_text: "Appréciez-vous le nouveau site Web optimisé de Tekla Warehouse ?",
        header_text2: "Les principaux changements sont:",
        bullet_text_1: "Recherche claire sur la page d'accueil.",
        bullet_text_2:
          "Tous les filtres de recherche se trouvent sur la gauche dans la page de recherche et la vue des miniatures comporte davantage de résultats de recherche.",
        bullet_text_3:
          "L'option de téléchargement de la page des contenus se trouve au centre. Il est donc facile de commencer à utiliser les contenus. Les options de téléchargement et d'installation sont désormais toutes deux accessibles avec le bouton Télécharger.",
        bullet_text_4:
          "La page de collection et le panier comportent des instructions en 3 étapes pour l'utilisation des fonctions de téléchargement et d'installation.",
      },
      organizations: {
        error_loading_collection_contents: "Erreur de chargement du sommaire de la collection",
        error_loading_organization: "Erreur de chargement de l’organisation",
      },
      downloads_graph: {
        date: "Date",
        total_views: "Total des vues",
        total_downloads: "Total des téléchargements",
        views: "Vues",
        downloads: "Téléchargements",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Le programme additionnel Tekla Warehouse Service" +
          "</a>" +
          " est requis.",
        processingFailed: "Échec du traitement",
        forbidden: "Interdit",
        resource_not_found: "Ressource introuvable",
        no_matches_found: "Aucune correspondance trouvée",
        no_matches_found_for: function (input) {
          return "Aucune correspondance trouvée avec l’adresse e-mail ou l’ID " + input;
        },
        not_identified: "Non identifié",
        error_while_searching: "Erreur lors de la recherche",
        error_user_not_found: "Utilisateur ou organisation introuvable",
        error_while_adding_member: "Erreur lors de l’ajout de droits d’accès au membre",
        error_while_removing_member: "Erreur lors de la suppression de droits d’accès au membre",
      },
      offline_to_online: {
        fail_on_initialize: "Erreur lors du traitement du contenu du zip, annulation de la conversion",
        fail_on_validate: "Échec de validation des métadonnées de contenu, annulation de la conversion",
        fail_on_save: "Erreur lors de l’enregistrement du contenu, annulation des modifications",
        success: "Contenu hors ligne converti en contenu en ligne avec succès",
        validate_errors_title: "Échec de la conversion de contenu",
        processing: "Enregistrement du package ",
        processed: "Élément traité",
        collection_thumbnail_missing_name:
          "Le fichier binaire de la miniature de collection est manquant 'nom de fichier'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Le fichier binaire de la miniature du package '" + packageTitle + "' est manquant 'nom de fichier'";
        },
        package_value_missing_title_func: function () {
          return "Le 'titre' du package est manquant";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Le package '" + packageTitle + "' est manquant '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "Le package '" +
            packageTitle +
            "' champ '" +
            field +
            "' a les valeurs non valides suivantes : " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Le package '" + packageTitle + "' a l’étiquette non valide : " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "La version du package '" + packageTitle + "' est manquante 'titre'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Le package '" + packageTitle + "' version '" + versionTitle + "' est manquant '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Le package '" +
            packageTitle +
            "' version '" +
            versionTitle +
            "' champ '" +
            field +
            "' a les valeurs non valides suivantes : " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return (
            "Le fichier binaire du package '" +
            packageTitle +
            "' version '" +
            versionTitle +
            "' est manquant 'nom de fichier'"
          );
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Important : Fin des collections locales dans Tekla Warehouse",
            part1: "À partir du 2 juin 2025, les collections locales ne seront plus disponibles dans Tekla Warehouse. Les utilisateurs ne pourront plus créer, consulter ou rechercher des collections locales ni utiliser le contenu des collections locales.",
            part2: "Pour obtenir de l'aide lors de la transition des collections locales vers les collections en ligne, veuillez contacter votre organisation de support locale.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Important ! Par mesure de sécurité, vous devez avoir le rôle Éditeur de contenu Tekla Warehouse pour gérer le contenu de votre organisation après le ${date} novembre 2024. <br />Demandez à l’administrateur de votre organisation de vous affecter ce rôle. Pour plus d’informations, reportez-vous au <a href="${productBulletinUrl}" target="_blank">bulletin technique</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Par mesure de sécurité, vous devez avoir le rôle Éditeur de contenu Tekla Warehouse pour gérer le contenu de votre organisation après le ${date} novembre 2024. <br />Demandez à l’administrateur de votre organisation de vous affecter ce rôle. Pour plus d’informations, reportez-vous au <a href="${productBulletinUrl}" target="_blank">bulletin technique</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Vous ne voyez pas d’option de modification pour votre organisation ? Après le ${date} novembre, vous devrez disposer du rôle Éditeur de contenu Tekla Warehouse pour gérer le contenu de votre organisation. <br />Vous pouvez vérifier votre rôle sous Mon Warehouse > Mes informations. Pour plus d’informations, reportez-vous au <a href="${productBulletinUrl}" target="_blank">bulletin technique</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Vous ne voyez pas d’option pour créer du contenu pour votre organisation ? Après le ${date} novembre, vous devrez disposer du rôle Éditeur de contenu Tekla Warehouse pour gérer le contenu de votre organisation. <br />Vous pouvez vérifier votre rôle sous Mon Warehouse > Mes informations. Pour plus d’informations, reportez-vous au <a href="${productBulletinUrl}" target="_blank">bulletin technique</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Après le ${date} novembre, vous devrez disposer du rôle Éditeur de contenu Tekla Warehouse pour gérer le contenu de votre organisation. Demandez à l’administrateur de votre organisation de vous attribuer le rôle. <br />Vous pouvez vérifier qui est l’administrateur de votre organisation dans votre profil Tekla Online. Pour plus d’informations, reportez-vous au <a href="${productBulletinUrl}" target="_blank">bulletin technique</a>.`
            },
          },
          service_plugin_update_info:
            "Avez-vous des problèmes pour vous connecter à Tekla Structures ? Assurez-vous d'utiliser le dernier <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>plugin Tekla Warehouse Service</a>.",
        },
        maintenance_info:
          "Tekla Warehouse est en maintenance le 27/10/2017 de 22h à 23h UTC. Veuillez nous excuser pour la gêne occasionnée.",
        release_notes_info: "Nouvelles fonctions de Tekla Warehouse",
        release_notes_link:
          'Tekla Warehouse a été mis à jour, consultez les <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">nouveautés</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Vous avez besoin d'un développeur Tekla pour votre projet ?",
        heading2Dev:
          'Consultez le nouveau répertoire appelé Tekla Development Experts.<br><br>C\'est un endroit où les programmeurs talentueux et les utilisateurs de Tekla intéressés peuvent se rencontrer.<br><br>Tous les membres du Tekla Partners Program sont invités à ajouter leurs informations dans le répertoire Experts.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Rechercher un développeur &rsaquo;</a>',
        static_intro:
          "C'est le stockage BIM Tekla Structures gratuit où vous pouvez trouver, importer, installer et partager du contenu en interne et globalement. Tekla Warehouse a pour objectif de faciliter la production de modèles de haute qualité efficacement.",
        new_intro:
          'Tekla Corporation s\'appelle maintenant Trimble. Nous continuons de créer des solutions logicielles Tekla et les mêmes personnes sont toujours à votre service. En savoir plus : <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Veuillez vérifier votre compte",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Chargement les plus récents &rsaquo;",
          popular: "Contenu populaire &rsaquo;",
          recommended: "Recommandations &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Actualités &rsaquo;</a>',
          item_title: "Information !",
          item_description:
            'Texte relatif aux actualités, texte relatif aux actualités, texte relatif aux actualités, texte relatif aux actualités, texte relatif aux actualités, texte relatif aux actualités.<br><a href="https://developer.tekla.com" target="_blank">Plus d\'info &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse en chiffres",
          partners: "Partenaires",
          partners_count: "352",
          collections: "Collections",
          collections_count: "793",
          items: "Éléments de contenu",
          items_count: "2893",
        },
        partners: "Nos partenaires",
        action_links: {
          search_title: "Rechercher du contenu ?",
          upload_title: "Charger du contenu ?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Vidéos &rsaquo;</a>',
          link: 'Visitez notre <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">chaîne Youtube</a> pour plus de vidéos.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Consultez vos e-mails pour le lien de vérification, ou<u><a href="' +
            settingsUrl +
            '">commandez un nouveau lien</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Vérifiez votre adresse e-mail, et acceptez les conditions générales et la politique de confidentialité",
        acceptTerms: "Veuillez accepter les conditions générales et la politique de confidentialité",
        acceptUpdatedTerms:
          "Veuillez accepter les conditions générales tekla online services et la politique de confidentialité mises à jour",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Revenir à votre <u><a href="' + settingsUrl + '">profil</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Lisez et acceptez les conditions générales et la politique de confidentialité dans votre <u><a href="' +
            settingsUrl +
            '">profil</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Parcourir les collections &rsaquo;",
          new: "Nouveau",
          popular: "Les plus populaires",
          recommended: "Recommandations",
          organizations: "Organisations",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Sélectionner le dossier",
        selectFolderInstructions: "Les dossiers locaux et réseau peuvent être utilisés comme collections.",
        selectedFolder: "Sélectionnez le dossier (copiez-collez l’emplacement) :",
        selectedFolderPlaceholder: "P. ex. C:\\dossier, Z:\\dossier réseau, \\\\nom-machine\\dossier",
        collectionCreation: {
          name: "Nom",
          createNewCollection: "Créer une collection locale",
          pleaseInputTitle: "Entrez un titre",
          invalidPath: "Chemin d'accès non valide",
          access_denied: "Échec d'ajout de collection : accès refusé",
          unsafe_location: "Échec d'ajout de collection : emplacement non sécurisé",
          add_collection_failed: "Échec d'ajout de collection",
          minLengthError: "Le titre doit comporter au moins 3 caractères",
          maxLengthError: "Le titre ne doit pas comporter plus de 20 caractères",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Les dossiers locaux et réseau peuvent être utilisés comme collections.",
        collectionCreation: {
          name: "Nom",
          createNewCollection: "Créer une collection en ligne",
          pleaseInputTitle: "Entrez un titre",
          minLengthError: "Le titre doit comporter au moins 3 caractères",
          maxLengthError: "Le titre ne doit pas comporter plus de 20 caractères",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Ressource enregistrée",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Vous voyez les conséquences ?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Donnez-nous votre avis</span>',
          help_us_improve_html:
            'Aidez-nous à améliorer Tekla Warehouse ! <br/><a id="showSurvey">Cliquez ici</a> ou sur <a id="dontShow">Ne plus afficher</a>',
          dialog: {
            do_you_see_potential: "Vous voyez les conséquences ?",
            help_us_improve_html: "Aidez-nous à améliorer Tekla Warehouse ! Votre avis est important pour nous.",
            continue_to_survey: "Démarrer l’enquête",
            exit_to_warehouse: "Non merci",
          },
        },
      },
      search: {
        bar: {
          search: "Recherche",
          filter: " ",
          more_filters: "Plus de filtres",
          hide_filters: "Masquer les filtres",
          clear: "Vider",
          exclude: "Exclure",
          include: "Inclure",
          clear_filters: "Réinitialiser les filtres",
          load_defaults: "Utiliser mes préférences &rsaquo;",
          save_defaults: "Enregistrer en tant que mes préférences &rsaquo;",
          defaults_saved_successfully: "Filtres par défaut enregistrés",
          edit_defaults: "Modifier les paramètres par défaut",
        },
        info: {
          searching_organizations: "Recherche dans les organisations...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Aucun résultat", amountTotal + " résultats"]);
            } else {
              return (
                pluralize(amountTotal, ["Aucun résultat", amountTotal + " résultats"]) +
                ' pour "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " résultats";
            } else if (!searchTerm) {
              return "Saisissez un terme de recherche pour afficher les résultats de l’organisation";
            } else if (searchTerm.length < 2) {
              return "Fournir au moins deux caractères pour rechercher une organisation";
            } else {
              return (
                pluralize(amountTotal, ["Aucun résultat", amountTotal + " résultats"]) +
                ' pour "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " résultats dans le contenu en ligne",
          local: " résultats dans le contenu local",
          organization: "résultats dans les organisations",
          plugin_missing_info:
            'Le contenu local n’est affiché que si vous avez installé <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Le programme additionnel Tekla Warehouse Service" +
            "</a>" +
            " installé.",
          version_filter_info:
            "Le filtrage de la version logicielle n’est pas valide dans les résultats du contenu local",
        },
        exclude_env_files: "Exclure les fichiers d’environnement",
        sidebar: {
          filters: {
            measurementUnits: "Unités de mesure",
            locations: "Emplacements des projets",
            languages: "Langues",
          },
          placeholders: {
            use_categories: "Catégorie",
            measurement_units: "Unités de mesure",
            languages: "Langues",
            locations: "Emplacements",
          },
        },
      },
      placeholders: {
        name: "Nom...",
        contentOwner: "Nom du propriétaire du contenu...",
        useCategory: "Sélectionner l’utilisation",
        selectType: "Sélectionner le type",
        filter: "Filtrer...",
        search: "Rechercher...",
        choose: "Choisir...",
        searchFilters: "Filtres de recherche...",
        selectContentItem: "Sélectionner un élément de contenu...",
        search_content: "Rechercher dans le contenu...",
        includeAs: "Inclure comme...",
        selectCollection: "Sélectionner une collection",
        selectUserGroup: "Sélectionner un groupe d’utilisateurs",
        online_collections_tooltip: "Cliquer pour effectuer une recherche dans le contenu en ligne",
        local_collections_tooltip:
          "Cliquer pour effectuer une recherche dans le contenu local et réseau ainsi que dans le contenu inclus lors de l’installation de Tekla Structures",
        organizations_tooltip: "Cliquer pour rechercher les organisations",
        "filter-options": {
          products: "Tous les produits logiciels",
          locations: "Tous les emplacements",
          languages: "Toutes les langues",
          measurementUnits: "Toutes les unités de mesure",
          testedVersions: "Toutes les versions logicielles",
          useCategories: "Toutes les catégories",
          useCategoriesCombined: "Catégorie",
          allGroups: "Tous les groupes",
          itemTypeCategories: "Tous les types d’éléments",
        },
        "exclude-filter-options": {
          products: "Exclure les produits logiciels",
          locations: "Exclure des emplacements",
          languages: "Exclure des langues",
          measurementUnits: "Exclure des unités de mesure",
          testedVersions: "Exclure des versions logicielles",
          useCategories: "Exclure des catégories",
          useCategoriesCombined: "Exclure une catégorie",
          allGroups: "Exclure des groupes",
          itemTypeCategories: "Exclure des types d'élément",
        },
      },
      link: {
        link_selected: "Lien sélectionné",
        unlink_selected: "Dissocier les éléments sélectionnés",
      },
      download_install: {
        choose_version: "Sélectionnez votre version logicielle :",
        choose_content: "Choisissez les éléments de contenu dans la liste ci-dessous :",
        choose_action: "Choisissez une action pour les éléments sélectionnés :",
      },
      download: {
        copy_to_clipboard: "Copier dans le presse-papier",
        download_selected: "Télécharger",
        add_selected_items_to_basket: "Ajouter Sélection à Mon panier",
        add_item_to_basket: "Ajouter Sélection à Mon panier",
        add_all_items_to_basket: "Tout ajouter au panier",
        add_all_items_to_basket_info: "Il y a plus de 100 éléments. Le chargement peut prendre un certain temps",
        add_all_items_to_basket_too_many_results: "Vous ne pouvez pas ajouter plus de 1 000 éléments",
        cart_loading: "Il y a plus de 100 éléments. Le chargement peut prendre un certain temps",
        remove_item_from_basket: "Supprimer du panier",
        shopping_cart_no_content: "Aucun contenu dans le panier",
        shopping_cart_no_content_for_version: "Aucun contenu dans le panier pour la version sélectionnée",
        shopping_cart_items_func: function (itemsCount) {
          return "Mon panier (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Télécharger les instructions",
          step_one: "Copier les informations de téléchargement dans le Presse-papiers avec ce bouton",
          step_two_func: function (downloadAppUrl) {
            return (
              "Exécuter l'application Tekla Warehouse Downloader. Vous pouvez télécharger l’application " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">ici.</a> '
            );
          },
          step_three: "L’application Tekla Warehouse Downloader vous guidera pour le reste du téléchargement.",
          show_data_title: "N’a pas fonctionné ?",
          show_data_to_be_copied: "Copier manuellement",
          hide_data_to_be_copied: "Masquer les données de téléchargement",
        },
      },
      install: {
        install_title: " éléments seront installés à l’aide du programme additionnel Tekla Warehouse.",
        failed_install_information:
          "Vous avez sélectionné du contenu disponible uniquement pour téléchargement. Ce contenu ne sera pas installé maintenant. Téléchargez ce contenu indépendamment de Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Installation : Veuillez vérifier que votre programme additionnel Tekla Warehouse Service fonctionne correctement",
        verify_ts_connection: "Installation : veuillez vérifier que Tekla Structures est en cours d’exécution",
        select_software_version: "Filtrer par version logicielle pour lier, télécharger ou installer",
        download_requires_maintenance: "L’accès à certains éléments ci-dessous requiert la maintenance Tekla",
        install_selected: "Installer",
        start_install: "Démarrer l'installation",
        install_started: "L’installation a commencé",
        select_ts_version: "Sélectionner la version TS",
        install_retry: "Démarrez à nouveau à partir de Tekla Warehouse si un nouvel essai est nécessaire",
        failed_installs_info_func: function (failedCount) {
          return "Contenu qui ne sera pas installé (" + failedCount + " versions) :";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Installation de la sélection d " + totalInstallsCount + " éléments";
        },
      },
      helpers: {
        behalf: "Par",
        sortBy: "Trier par :",
        filter: "Filtrer les résultats",
        showAsThumbs: "Afficher sous forme de miniatures",
        showAsList: "Afficher sous forme de liste",
        resultsPerPage: "Afficher par page :",
        filter_by_version: "Filtrer par version :",
        show_more_options: "Plus d'options",
        show_less_options: "Moins d’options",
        filter_by_type: "Filtrer par type :",
        select_all: "Tout sélectionner",
        show_content_items: "Afficher les éléments de contenu",
        choose_action: "Choisissez une action pour le contenu sélectionné : ",
        show_collections: "Afficher les collections",
        search: {
          show: "Afficher :",
          show_content_items: "Éléments de contenu",
          show_collections: "Collections",
          show_organizations: "Organisations",
          show_online_content: "Contenu en ligne",
          show_offline_content: "Contenu hors ligne",
          local_content_info:
            'Le contenu local n’est affiché que si vous avez installé le programme additionnel <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            ".",
        },
        tekla_maintenance_required: "L’accès à certains éléments ci-dessous requiert la maintenance Tekla",
        show_content_alerts: "Afficher les alertes sur le contenu ",
        show_collection_alerts: "Afficher les alertes sur les collections ",
      },
      actions: {
        load_more: "Charger plus",
        use_infitinite_scroll: "Utiliser le défilement infini",
        back: "Retour",
        show_download_dialog: "Télécharger",
        download: "Seulement télécharger",
        download_on_partner_site: "Télécharger sur le site partenaire",
        insert: "Installer",
        install: "Installer",
        downloadAndInstall: "Installer",
        createNew: "Créer nouveau",
        viewItems: "Vue",
        viewCollection: "Afficher la collection",
        viewGroup: "Afficher le groupe",
        joinGroup: "Rejoindre un groupe",
        loginToDownload: "Se connecter pour télécharger",
        show3DPic: "Afficher le modèle 3D",
        remove: "Supprimer",
        browse: "Parcourir",
        browseFolder: "Parcourir",
        browseFileSystem: "Parcourir",
        add: "Ajouter",
        save: "Enregistrer",
        batch_edit: "Modifier par lots",
        translations: "Traductions",
        cancel: "Annuler",
        continue: "Continuer",
        delete: "Supprimer",
        addNetworkPath: "Ajouter un emplacement réseau",
        select: "Sélection",
        create: "Créer",
        update: "Mise à Jour",
        choose_thumbnail: "Choisir une miniature",
        choose_picture: "Choisir une image",
        remove_picture: "Supprimer l’image",
        choose_3D_picture: "Choisir un modèle 3D",
        remove_3D_picture: "Supprimer un modèle 3D",
        like: "J’aime",
        unlike: "Je n’aime pas",
        close: "Fermer",
        export: "Export",
        add_or_remove: "Ajouter/supprimer",
        select_content_to_convert: "Sélectionner le contenu hors ligne",
        search: "Recherche",
        upload: "Charger",
        edit: "Modification",
        view_3d: "Cliquer pour afficher le modèle...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "bêta",
        search: "Recherche",
        profile: "Mes informations",
        myWarehouse: "Mon Warehouse",
        myStuff: "Mes sélections",
        logout: "Se déconnecter",
        login: "Se connecter",
        register: "S'inscrire",
        upload: "Charger",
        uploading: "Charger",
        dashboard: "Tableau de bord",
        learnMore: "En savoir plus",
        about: "A propos de",
        shoppingCart: "Mon panier",
        addToCartInfo:
          "Vous pouvez collecter des éléments dans Mon panier, et les télécharger ou les installer tous en même temps. Le téléchargement est totalement gratuit",
      },
      cubeNavigation: {
        headings: {
          get_started: "PREMIERS PAS",
          learn: "EN SAVOIR PLUS",
          get_help: "OBTENIR DE L'AIDE",
          extend_your_tekla: "ÉTENDEZ VOTRE TEKLA",
          collaborate: "COLLABORER",
          students_and_educators: "Students and educators",
          about: "À PROPOS DE",
        },
        "tekla-online": "Services en ligne",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Site Web Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Extranet Tekla",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Mon profil",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "ACCUEIL DES SERVICES TEKLA",
        register: "S'inscrire",
        login: "Se connecter",
        evaluate: "Évaluer",
        buy: "Acheter",
        download: "Télécharger",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Produits",
        teklaServices: "Services",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Mes collections",
        myLikes: "Mes « j’aime »",
        profile: "Mes informations",
        preferences: "Mes préférences",
        myUserGroups: "Mes groupes d’utilisateurs",
        localCollections: "Collections locales et réseau",
        tsCollections: "Collections Tekla Structures",
        downloads: "Téléchargements",
        selectContent: "Sélectionner les fichiers",
        addMetadata: "Ajouter des métadonnées",
        onlineCollections: "Collections en ligne",
        userGroups: "Groupes d’utilisateurs",
        createNew: "Ajouter du contenu",
        notifications: "Notifications",
        contentUsage: "Utilisation du contenu",
        browseCollections: "Parcourir les collections",
        admin: "Administration",
        sales: "Ventes",
        shoppingCart: "Mon panier",
      },
      notifications: {
        title: "notification",
        invitedBy: "Invité par",
        invitationType: "Type d’invitation",
        invitedTo: "Invité à",
        createdAt: "Créé à",
        action: "Action",
        acceptInvitation: "Accepter",
      },
      batch_edit: {
        selectActionType: "Sélectionner le type d’action",
        edit_content: "Modifier les métadonnées du contenu",
        content_title: "Contenu",
        metadataToChange: "Métadonnées à modifier",
        selectContent: "Pour le contenu",
        select_action: "Sélectionner une action",
        support: {
          support: "Assistance",
          licenses: "Licences",
        },
        versions: {
          version: "Version",
          prerequisities: "Prérequis et exigences",
        },
        content: {
          select_collection: "Sélectionner une collection",
          show_collection: "Afficher :",
          show_all_content: "Toutes les collections",
          show_organization_content: "Collections de mon organisation",
          select_all: "Tout sélectionner",
          select_all_info: "(Cela peut prendre un certain temps.)",
          actionOptions: {
            add: "Ajouter",
            remove: "Supprimer",
            replace: "Remplacer par",
            yes: "Oui",
            no: "non",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Aucun attribut sélectionné",
            invalid_attributes: "Valeurs d’attribut non valides",
            invalid_actions: "Actions non valides",
            content_not_selected: "Aucun contenu sélectionné",
            entities_not_selected:
              "Des données associées au package sont fournies, mais aucun package n’est sélectionné",
            versions_not_selected:
              "Des données associées à la version sont fournies mais aucune version n’est sélectionnée",
          },
        },
        update: {
          info_processing: "Veuillez laisser cette fenêtre ouverte tant que tous les éléments n’ont pas été traités.",
          info_processed: "Tous les éléments ont été traités.",
          processing: "Traitement de l'élément",
          error_info: "Un problème est survenu lors de la mise à jour des éléments suivants :",
          package_failure_func: function (package_title, errorMsg) {
            return "Contenu: " + package_title + ", erreur : " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Contenu: " + package_title + ", version : " + version_title + ", erreur : " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " éléments vont être mis à jour, voulez-vous continuer ?";
          },
        },
      },
      myUserGroups: {
        title: "Groupes d’utilisateurs",
        createdByPrefix: "Par :",
      },
      downloads: {
        title: "Téléchargements",
      },
      myCollections: {
        new_collection: "Nouvelle collection",
        my_collections: "Mes collections",
        created_by_me: "Créée par moi-même",
        local_collections: "Collections locales et réseau",
        online_collections: "Collections en ligne",
        created_by_my_organization: "Créée par mon organisation",
        created_by_my_organizations: "Créé par mes organisations",
        shared_with_me: "Partagée avec moi",
        shared_with_me_or_organization: "Partagée avec moi ou mon organisation",
        notification: {
          error: "Erreur",
          error_loading_online_collections: "Erreur de chargement des collections en ligne",
          error_loading_packages_liked_by_me: "Erreur de chargement du contenu que j'aime",
          error_loading_my_organization_s_collections: "Erreur de chargement des collections de mon organisation",
          error_loading_my_shared_collections: "Erreur de chargement de mes collections partagées",
        },
        local: {
          helpText1: "Pour accéder aux collections locales, installez ",
          helpText2: "Programme additionnel Tekla Warehouse Service",
          helpText3: ".",
          title: "Collections locales et réseau",
          notFound: "Aucune collection locale trouvée pour l’instant.",
          myCollectionsTitle: "Mes collections",
          actions: {
            createNew: "Créer une collection locale ou réseau",
          },
        },
      },
      organization: {
        news_feed: "Flux d’actualités",
        add_comment: "Créer un texte",
        edit_comment: "Modifier l’élément d’information",
        subTabs: {
          collections: "Collections",
          analytics: "Analyses",
          contact_and_support: "Contact",
          news_feed: "Flux d’actualités",
        },
        edit: {
          edit_title: "Modifier le titre",
          edit_description: "Modifier la description",
          edit_contact: "Modifier les coordonnées",
          edit_logo: "Modifier le logo",
          edit_url: "Modifier l’URL",
          edit_email: "Modifier l’e-mail",
          edit_phonenumber: "Modifier le numéro de téléphone",
          edit_warehouse_url: "Modifier l’URL Warehouse",
          edit_warehouse_url_subtitle: "Seulement des lettres. Aucun chiffre, ni caractère spécial",
          edit_url_subtitle: "Insérer l’URL complète",
          edit_logo_descriptive: "Taille optimale de l’image environ 350 x 200 pixels",
          edit_facebook_url: "Modifier l’URL Facebook",
          edit_twitter_url: "Modifier l’URL Twitter",
          edit_linkedin_url: "Modifier l’URL LinkedIn",
          edit_youtube_url: "Modifier l’URL YouTube",
          edit_googleplus_url: "Modifier l’URL Google+",
          logo_updated: "Logo mis à jour",
          logo_update_failed: "Échec de la mise à jour du logo",
          logo_remove_failed: "Échec de la suppression du logo",
          logo_removed: "Logo supprimé",
          updated_successfully: "Mis à jour avec succès",
          updated_failed: "Échec de la mise à jour",
          add_comment: "Ajouter nouveau",
          edit_comment: "Modifier l’élément d’information",
          comment_added: "Élément de flux d’actualités ajouté avec succès",
          comment_updated: "Élément de flux d’actualités mis à jour avec succès",
          comment_deleted: "Élément de flux d’actualités supprimé avec succès",
          comment_add_failed: "Impossible d’ajouter le flux d’actualités",
          comment_update_failed: "Impossible de mettre à jour l’élément de flux d’actualités",
          comment_delete_failed: "Impossible de supprimer l’élément de flux d’actualités",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Rechercher dans le contenu en ligne par " + name + " &rsaquo;";
        },
        contact_information: "Nous contacter",
        website: "Site Web &rsaquo;",
        show_newer_news: "Plus récente",
        show_older_news: "Plus ancienne",
      },
      online_collections: {
        new_collection: "Nouvelle collection",
        online_collections: "Collections en ligne",
        created_by_organizations: "Créée par les organisations",
        created_by_users: "Créée par les utilisateurs",
      },
      local_collections: {
        tab_title: "Contenu",
        local_collections: "Collections locales",
      },
      versions: {
        add_tool: "Ajouter une application",
        table_header: {
          name: "Nom",
          type: "Type",
          platform: "Plateforme",
          modified_at: "Modifié à",
          size: "Dimension",
          virus_scan: "Analyse antivirus",
        },
        // placeholders
        select_file_type: "Sélectionner le type de fichier",
        select_platform: "Sélectionner la plateforme",
        select_unit: "Sélectionner l’unité",
        select_quality: "Sélectionner la qualité",
        what_will_be_added: "Que voulez-vous ajouter ?",
        select_files_from_your_disk: "Sélectionner des fichiers sur votre disque",
        add: "Ajouter",
        //content management
        add_application: "Ajouter une application",
        select_content_from_tekla_structures: "Sélectionner du contenu dans Tekla Structures",
        or: "ou",
        files: "Fichiers",
        //version metadata
        version_information: "Informations sur la version",
        version_display_name: "Nom complet de la version",
        version_number: "Numéro de version",
        version_number_info: "Entrez un numéro de version. Vous ne pouvez pas modifier le numéro de version une fois qu’il a été enregistré.",
        existing_versions: "Numéros de version existants pour ce contenu",
        version_number_validation: {
          missing: "Vous devez entrer un numéro de version",
          invalid: "Le numéro de version doit respecter les règles de versionnement sémantique (MAJEURE.MINEURE.CORRECTIF)",
          already_in_use: "Ce numéro de version est déjà utilisé pour ce contenu.",
        },
        select_update_type: "Pour générer un numéro de version, sélectionnez d’abord le type de mise à jour",
        please_enter_a_version_name_or_number: "Entrez un nom ou un numéro de version.",
        virus_scan: {
          soft_failed: "Echoué",
          hard_failed: "Non analysé",
          infected: "Infecté",
        },
      },
      collections: {
        by: "Par :",
        edit: {
          edit_collection: "Modifier la collection",
          edit_description: "Modifier la description",
        },
        notification: {
          collection_updated: "Collection mise à jour",
          error: "Erreur",
          error_loading_other_users_collections: "Erreur de chargement des collections des autres utilisateurs",
          error_loading_other_organizations_collections:
            "Erreur de chargement des collections des autres organisations",
          error_loading_collection_contents: "Erreur de chargement du sommaire de la collection",
          members_list_updated: "Liste des membres mise à jour !",
          invitations_removed: "Invitations supprimées !",
          collection_removed: "Collection supprimée",
          thumbnail_deleted: "Miniature supprimée",
          wrong_file_type: "Type de fichier incorrect.",
          invalid_file_type: "Type de fichier non valide.",
          selected_image_is_too_big: "L’image sélectionnée est trop grande.",
          thumbnail_updated: "Miniature mise à jour",
          default_thumbnail_updated: "Miniature par défaut mise à jour",
          default_thumbnail_update_failed: "Échec de la mise à jour miniature par défaut",
          thumbnail_added: "Miniature ajoutée",
          thumbnail_update_failed: "Échec de la mise à jour de la miniature",
          video_added: "Vidéo ajoutée",
          video_adding_failed: "Échec d'ajout de la vidéo",
          video_deleted: "Vidéo supprimée",
          video_deleting_failed: "Échec de suppression de la vidéo",
        },
        confirm: {
          delete_all: function (title) {
            return 'Effacer la collection et tout son contenu  "' + title + '"?';
          },
          remove_members: "Etes-vous sûr?",
          remove_invitations: "Etes-vous sûr?",
        },
        useNetworkDrive: "Utiliser un lecteur ou un dossier réseau",
        createCollection: "Créer une collection en ligne",
        createCollectionInstructions: "Créez une collection pour regrouper votre contenu.",

        actions: {
          delete_thumbnail: "Supprimer la miniature",
          edit_collection: "Modifier la collection",
          delete_collection: "Supprimer la collection",
          add_content: "Ajouter du contenu",
        },
        subTabs: {
          content: "Contenu",
          members: "Afficher les membres",
          analytics: "Analyses",
          manage_access: "Gérer l’accès",
        },

        members: {
          add_user_groups: "Ajouter des groupes d’utilisateurs",
          select_user_group: "Sélectionner un groupe d’utilisateurs",
          with_role: "avec son rôle",
          user_groups_added: "Groupes d’utilisateurs ajoutés !",
          members_list_updated: "Liste des membres mise à jour !",
          update_selected_members: "Mettre à jour les membres sélectionnés",
          table_header: {
            member_type: "Type de membre",
            member_name: "Nom du membre",
            email: "Adresse e-mail",
            role: "Rôle",
            joined_at: "Inscrit à",
            invited_at: "Invité à",
          },
          new_role: "Nouveau rôle",
        },
        member_list: {
          users_who_can_access_this_collection: "Utilisateurs qui peuvent accéder à cette collection",
          add_user_group: "Ajouter un groupe d’utilisateurs",
          update_members: "Mettre à jour les membres",
          remove_members: "Supprimer les membres",
          pending_invitations: "Invitations en attente",
          user: "utilisateur",
          invite_member: "Inviter un membre",
          update_invitations: "Mettre à jour les invitations",
          remove_invitations: "Supprimer des invitations",
        },
        labels: {
          select_organization: "Sélectionner une organisation",
          select_visibility: "Sélectionner la visibilité",
          title: "Nom",
          name: "Nom",
          createAs: "Créer comme : ",
          asMyself: "Moi-même",
          asUserGroup: "Groupe utilisateur",
          asOrganization: "Organisation",
          selectOwner: "Sélectionner une organisation pour le propriétaire du contenu",
          visibility: "Visibilité",
          visibilityOptions: {
            public: "Public",
            private: "Privé",
          },
          description: "Description",
        },
        new_collection: {
          please_input_a_username: "Entrez un nom d’utilisateur",
          title_is_required_to_be_at_least_3_characters: "Le titre doit comporter au moins 3 caractères",
          title_cannot_be_longer_than_20_characters: "Le titre ne doit pas comporter plus de 20 caractères",
        },
        collection_list: {
          no_content: "Aucune collection.",
          results_summary: function (number) {
            return "" + number + "  collection " + pluralize(number, ["éléments", "élément", "éléments"]);
          },
        },
        entity_list: {
          no_content: "Aucun contenu dans la collection.",
          results_summary: function (number) {
            return "" + number + " contenu " + pluralize(number, ["éléments", "élément", "éléments"]);
          },
          linked_content: "Contenu lié",
          unlink: "Supprimer le lien du contenu",
        },
      },
      alerts: {
        no_updates: "Pas de mise à jour récente",
        notifications_marked_as_read: "Notification marquée comme lue",
        cleared_alerts: "Toutes les notifications ont été effacées",
        alert_by_email:
          "M’envoyer un e-mail quand du contenu parmi ceux affichés ci-dessous est mis à jour avec une nouvelle version ou quand une collection est mise à jour avec du nouveau contenu",
        action_types: {
          create: "a été créé",
          update: "a été modifié",
          delete: "a été supprimé",
          added_entity: "la version a été ajoutée",
          removed_entity: "la version a été supprimée",
          added_collection: "du contenu a été ajouté",
          removed_collection: "du contenu a été supprimé",
        },
        property_names: {
          description: "Détails",
          searchCriteria: "Informations sur les critères de recherche",
          groupCategory: "Informations sur les groupes et les catégories",
          support: "Information support",
          licenses: "Informations sur les licences",
          relatedContent: "Informations sur le contenu associé",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Mis à jour " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Préférences de notification mises à jour avec succès",
          preferences_update_failed: "Une erreur s’est produite lors de la mise à jour des préférences de notification",
        },
      },
      browseDialog: {
        useNetworkDrive: "Utiliser un lecteur ou un dossier réseau",
        ownerGroups: {
          title: "Groupes de propriétaires",
        },
      },
      itemTypeCategories: {
        all: "Tous les groupes",
        application: "Applications",
        "3dProduct": "Produits 3D",
        customComponent: "Composants personnalisés",
        tool: "Applications",
        profile: "Profils",
        material: "Matériaux",
        bolt: "Boulons",
        rebar: "Armatures",
        reportTemplate: "Gabarits de listes",
        drawingSetup: "Fichiers de configuration des dessins",
        modelSetup: "Fichiers de configuration des modèles",
        environmentFile: "Fichiers d’environnement",
        shape: "Formes",
        //'shapeCatalog': 'Catalogue de formes',
        //'foreignGeometryDefinition': 'Définition de géométrie'
      },
      platforms: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: "Indépendant de la plateforme",
      },
      platformsDownload: {
        WIN_X86: "32 bits",
        WIN_X64: "64 bits",
        ANY: " ",
      },
      contentItemSource: {
        other: "Général - vers le répertoire modèle",
        run: "Démarrer",
        bolt: "Assemblage de boulon",
        component: "Composant",
        drawing: "Paramètre de dessin (BÊTA)",
        material: "Matériau",
        profile: "Profil",
        mesh: "Treillis soudés",
        rebar: "Armature",
        shape: "Forme",
        geometry: "Géométrie",
        modeltemplate: "Prototype",
        cloningtemplate: "Gabarit de clonage",
        noaction: "Ignorer",
        releasenotes: "Liste des nouveautés",
      },
      contentItemHeader: {
        bolt: "Assemblages de boulons",
        component: "Composants",
        drawing: "Paramètres de dessin (BÊTA)",
        material: "Matériaux",
        profile: "Profils",
        mesh: "Treillis soudés",
        rebar: "Armatures",
        shape: "Formes",
      },
      useCategoryGroups: {
        workflow: "Processus",
        structures: "Structures",
        drawings: "Dessins",
        modeling: "Modélisation",
      },
      useCategories: {
        title: "Utilisé dans/pour",
        conceptualDesign: "Conception",
        designEngineeringAnalysis: "Conception / Ingénierie / Analyse",
        detailing: "Exécution",
        fabrication: "Fabrication",
        erectionInstallationPour: "Montage / Installation / Coulage",
        contracting: "Sous-traitance",
        scheduling: "Planification",
        takeOffEstimation: "Métré / Estimation",
        concreteStructures: "Béton coulé sur site",
        precastConcrete: "Béton préfabriqué",
        offshore: "Offshore",
        steelStructures: "Structures en acier",
        timberStructures: "Structures en bois",
        reinforcement: "Ferraillage",
        drawingCreation: "Création de dessin",
        drawingManagement: "Gestion des dessins",
        drawingEditing: "Modification des dessins",
        drawingAnnotation: "Annotation des dessins",
        viewSimulateNavigate: "Afficher, simuler, naviguer",
        compareReviewManageData: "Comparer, examiner, gérer les données",
        softwareCollaboration: "Collaboration entre logiciels",
        productivity: "Productivité",
        interoperability: "Interopérabilité",
        systemSetup: "Configuration du système",
      },
      measurementUnits: {
        "0": "Unités métriques",
        "1": "Unités impériales",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Open BIM (IFC)",
        standalone: "Autonome",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures15',
        //'TS1': 'Tekla Structures16',
        //'TS2': 'Tekla Structures17',
        NVS: "Non spécifique à une version",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight1',
        //'TBS1': 'Tekla BIMsight1.5',
        //'TBS2': 'Tekla BIMsight2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "Expérimental",
        candidate: "En cours d’approbation",
        approved: "Approuvé",
        standard: "Standard",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Anglais",
        "zh-Hans": "Chinois - Simplifié",
        "zh-Hant": "Chinois - Traditionnel",
        cs: "Tchèque",
        da: "Danois",
        nl: "Néerlandais",
        fi: "Finnois",
        fr: "Français",
        de: "Allemand",
        hu: "Hongrois",
        it: "Italien",
        ja: "Japonais",
        ko: "Coréen",
        pl: "Polonais",
        pt: "Portugais",
        "pt-br": "Portugais - Brésilien",
        ru: "Russe",
        es: "Espagnol",
        sv: "Suédois",
        no: "Norvégien",
        other: "Autres",
      },
      isoLangs: {
        ab: {
          name: "Abkhaze",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikaans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Albanais",
          nativeName: "Shqip",
        },
        am: {
          name: "Amharique",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Arabe",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonais",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Arménien",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Assamais",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avar",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avestique",
          nativeName: "avesta",
        },
        ay: {
          name: "Aymara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azéri",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Bashkir",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Basque",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Biélorusse",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengali",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bosnien",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Breton",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Bulgare",
          nativeName: "български език",
        },
        my: {
          name: "Birman",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Catalan ; valencien",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Tchétchène",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa ; chewa ; nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chinois, simplifié et Chinois",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Chinois - Simplifié",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Chinois - Traditionnel",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Chinois - Taiwan",
          nativeName: "國語",
        },
        cv: {
          name: "Tchouvache",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Cornouaillais",
          nativeName: "Kernewek",
        },
        co: {
          name: "Corse",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cri",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Croate",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Tchèque",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Danois",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi ; dhivehi ; maldivien ;",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Néerlandais",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Anglais",
          nativeName: "English",
        },
        eo: {
          name: "Espéranto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Estonien",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Féroïen",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fidjien",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finnois",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Français",
          nativeName: "Français",
        },
        ff: {
          name: "Fula ; fulah ; pulaar ; pular",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Galicien",
          nativeName: "Galego",
        },
        ka: {
          name: "Géorgien",
          nativeName: "ქართული",
        },
        de: {
          name: "Allemand",
          nativeName: "Deutsch",
        },
        el: {
          name: "Grec (moderne)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gujarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haïtien ; créole haïtien",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Hébreu (moderne)",
          nativeName: "עברית",
        },
        hz: {
          name: "Héréro",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motou",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Hongrois",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonésien",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingua",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Irlandais",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Islandais",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italien",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japonais",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Javanais",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, groenlandais",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanouri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Cachemiri",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Kazakh",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu, gikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Kinyarwanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Kirghize",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Coréen",
          nativeName: "한국어",
        },
        ku: {
          name: "Kurde",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latin",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxembourgeois",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limbourgeois",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Lao",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Lituanien",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-Katanga",
          nativeName: "",
        },
        lv: {
          name: "Letton",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macédonien",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Malgache",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Malais",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malayalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltais",
          nativeName: "Malti",
        },
        mi: {
          name: "Maori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Marathi (marāṭhī)",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshallais",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongol",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo, navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Bokmål norvégien",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Ndébélé du Nord",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Népalais",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Nynorsk norvégien",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Norvégien",
          nativeName: "Norsk",
        },
        ii: {
          name: "Nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Ndébélé du Sud",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Occitan",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwe, ojibwa",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Vieux-slave liturgique, langue liturgique slave, slavon d’église, vieux-bulgare, vieux-slave",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Ossète",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Panjabi, punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pāli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Perse",
          nativeName: "فارسی",
        },
        pl: {
          name: "Polonais",
          nativeName: "polski",
        },
        ps: {
          name: "Pachto, pachtoune",
          nativeName: "پښتو",
        },
        pt: {
          name: "Portugais",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Portugais - Brésilien",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quechua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Romanche",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Roumain, moldave",
          nativeName: "română",
        },
        ru: {
          name: "Russe",
          nativeName: "русский",
        },
        sa: {
          name: "Sanskrit (saṁskṛta)",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sarde",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Same du Nord",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoan",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Serbe",
          nativeName: "српски језик",
        },
        gd: {
          name: "Gaélique écossais ; gaélique",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Cingalais",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Slovaque",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Slovène",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somali",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Sotho du Sud",
          nativeName: "Sesotho",
        },
        es: {
          name: "Espagnol", //      'name':'Espagnol / traditionnel',
          nativeName: "español",
        },
        su: {
          name: "Sundanais",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Swahili",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Swati",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Suédois",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamoul",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Télougou",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tadjik",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Thaï",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrinya",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibétain standard, tibétain, central",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turkmène",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalog",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tonga (îles Tonga)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Turc",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tatar",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Tahitien",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Ouïghour",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ukrainien",
          nativeName: "українська",
        },
        ur: {
          name: "Ourdou",
          nativeName: "اردو",
        },
        uz: {
          name: "Ouzbek",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamien",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Wallon",
          nativeName: "Walon",
        },
        cy: {
          name: "Gallois",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Frison occidental",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Yiddish",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Yoruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Zhuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Autres",
          nativeName: "Autres",
        },
      },

      details: {
        navigation: {
          content_information: "Informations sur le contenu",
          version_and_file_management: "Gestion des versions et des fichiers",
          details: "Détails",
          versions: "Versions",
          analytics: "Analyses",
          statistics: "Statistiques",
          manage_access: "Gérer l’accès",
          related_content: "Contenu associé",
          properties: "Propriétés",
          support: "Entreprise et support",
          release_notes: "Liste des nouveautés",
          comments: "Commentaires de l’utilisateur",
        },
        related_content: {
          other_content_title: "Autre contenu que vous pouvez apprécier",
          related_content_title: "Contenu recommandé par ",
          related_content_url: "URL de contenu",
          add_related_content: "Ajouter des contenus associés",
          add_related_content_info:
            "Vous ne pouvez ajouter du contenu en ligne associé qu’à du contenu en ligne, et du contenu local à du contenu local",
          remove_related_content: "Supprimer",
          relatedContentUrlSubtitle: "ID du contenu ou URL complète permettant d’accéder au contenu",
          updated_successfully: "Contenu associé mis à jour",
          update_failed: "Une erreur s’est produite lors de la mise à jour du contenu associé",
          update_failed_invalid_id: "L'ID de contenu indiqué n'est pas valide",
        },
        actions: {
          move: "Déplacer",
          delete: "Supprimer",
          add_comment: "Ajouter commentaire",
          add_reply: "Ajouter une réponse",
          edit_comment: "Modifier commentaire",
          reply_comment: "Ajouter une réponse",
          view_replies: "Afficher les réponses",
          report_bad_comment: "Rapporter un commentaire incorrect &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Modifier le titre ou effacer",
            title_or_move_delete: "Modifier le titre, déplacer ou effacer",
            thumbnail: "Modifier la miniature",
            description: "Modifier la description",
            groupandcategory: "Modifier le groupe et la catégorie",
            details: "Modifier les détails",
            versions: "Modifier les versions",
            support: "Modifier les informations sur l’assistance",
            release_notes: "Modifier la liste des nouveautés",
            search_criteria: "Modifier les critères de recherche",
            batchedit_and_translations:
              "Avec la fonctionnalité de traduction vous pouvez publier votre contenu dans plusieurs langues. La version traduite de votre contenu sera affichée aux utilisateurs en fonction de leur sélection de langue pour l’interface du Warehouse. Avec la modification par lots, vous pouvez modifier plusieurs éléments en même temps. Commencez par sélectionner un champ, puis les éléments à modifier",
          },
        },
        links: {
          link: "Lien",
          select_collection_placeholder: "Sélectionner une collection à lier",
          linking_collections_label: "Collections liées à",
          private_content_note: "Remarque : Le contenu privé n’est visible que des utilisateurs y ayant accès.",
          share_on_social_media: "Partager sur :",
          report_bad_content: "Rapporter un contenu incorrect &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Signaler un contenu illégal &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "En vous abonnant aux alertes, vous acceptez de partager le nom d’affichage de votre profil Tekla Account ainsi que le nom de votre organisation (si applicable) avec le propriétaire du contenu.",
          alert_me: "M’alerter",
          unsubscribe: "Se désabonner",
          subscribed_successfully: "Abonné avec succès",
          unsubscribed_successfully: "Désabonné avec succès",
          subscribers_label: "Abonnés",
        },
        reviews: {
          reviewers_label: "Réviseurs",
        },
        comments: {
          replies: "Réponses",
        },
        scan: {
          soft_failed_binaries_info: "Échec d'analyse antivirus  ",
          hard_failed_binaries_info: "L'analyse antivirus n'a pas pu être effectuée ",
          infected_binaries_info: "L'analyse antivirus a trouvé une infection ",
          what_now: "Et maintenant ?",
          popup: {
            soft_failed_binaries_title: "Échec des fichiers binaires",
            hard_failed_binaries_title: "Échec des fichiers binaires",
            infected_binaries_title: "Fichiers binaires infectés",
            soft_failed_binaries_info:
              "L'analyse antivirus pour les fichiers que vous avez chargés a échoué. Ceci est uniquement temporaire. Supprimez les fichiers et chargez-les à nouveau. Tous les fichiers doivent passer l’analyse antivirus avant d'être visibles pour les autres utilisateurs.",
            hard_failed_binaries_info:
              "L'analyse antivirus n'a pas pu être effectuée. Veuillez supprimer les fichiers. Tous les fichiers doivent passer l’analyse antivirus avant d'être visibles pour les autres utilisateurs.",
            infected_binaries_info:
              "L'analyse antivirus a trouvé une infection dans les fichiers que vous avez chargés. Veuillez supprimer les fichiers. Tous les fichiers doivent passer l’analyse antivirus avant d'être visibles pour les autres utilisateurs.",
            version: "Version : ",
            fileName: "nom du fichier : ",
          },
        },
        status: {
          content_banned_info:
            "Le contenu est bloqué par l’administrateur. Il n’est actuellement pas disponible pour les autres utilisateurs, à l’exception du propriétaire du contenu. <a href='https://support.tekla.com/contact-us' target='_blank'>Contactez le support</a> pour plus d’informations.",
          content_archived_info:
            "Ce contenu a été archivé. Les utilisateurs sans droits d’édition seront redirigés vers la page d’accueil s’ils essaient de visiter le contenu via un lien direct.",
          archived: "Archivé",
        },
        modified_by: function (name) {
          return " par " + name;
        },
        created_by: function (name) {
          return "Créé par " + name;
        },
        deleteEntity: function (title) {
          return "Supprimer " + title + "?";
        },
        userComments: "Commentaires de l’utilisateur",
        comment_added: "Commentaire ajouté avec succès",
        comment_updated: "Commentaire mis à jour avec succès",
        comment_deleted: "Commentaire supprimé avec succès",
        comment_add_failed: "Impossible d’ajouter le commentaire",
        comment_update_failed: "Impossible de mettre à jour le commentaire",
        comment_delete_failed: "Impossible de supprimer le commentaire",
        comment_delete_confirmation: "Supprimer le commentaire ?",
        name: "Nom",
        details: "Détails",
        contentOwner: "Créateur",
        contentOwnerGroup: "Groupe de propriétaires de contenu",
        contentGroup: "Groupe de contenus",
        thumbnail: "Miniature",
        tooltip: "Info-bulle",
        description: "Description",
        additional_information: "Informations supplémentaires",
        images: "Images de produits",
        installationInfo: "Informations sur l’installation",
        productCode: "Code produit",
        copyrightInfo: "Copyright",
        productExpirationDate: "Date d’expiration du produit",
        helpUrl: "Site Web &rsaquo;",
        supportUrl: "Assistance &rsaquo;",
        specialTermsUrl: "CLUF &rsaquo;",
        releaseNotesUrl: "Liste des nouveautés",
        supportForumUrl: "Forum de discussion &rsaquo;",
        itemTypeCategories: "Grouper",
        useCategories: "Catégorie",
        category: "Catégorie :",
        group: "Groupe :",
        compatibleSoftwareProducts: "Produits logiciels compatibles",
        compatibleOperatingSystems: "Systèmes d’exploitation compatibles",
        locationRestrictions: "Disponibilité du produit :",
        supportedLanguages: "Langues :",
        measurementUnits: "Unités de mesure :",
        tags: "Étiquettes et mots-clés :",
        prerequisites: "Prérequis :",
        releaseNotes: "Liste des nouveautés :",
        versions: "Versions",
        testedVersions: "Versions compatibles",
        qualityTag: "Qualité - évaluée par le propriétaire",
        otherAttributes: "Autres attributs",
        labels: "Critères de recherche",
        supportInformation: "Assistance",
        updateInformation: "Mises à jour",
        latestUpdate: "Dernière mise à jour",
        totalDownloads: "Total des téléchargements",
        licenses: "Licences",
        maintenanceRequired: "Le téléchargement requiert la maintenance Tekla",
        tekla_maintenance_content: "Contenu de maintenance Tekla",
        download_requires_tekla_maintenance: "Le téléchargement requiert la maintenance Tekla",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Mis à jour avec succès",
        update_failed: "Échec de la mise à jour",
        update_failed_invalid_tag: "Échec de la mise à jour : étiquette non valide",
        update_failed_details_max_length_exceeded: "Échec de la mise à jour : détails trop longs",
        show_version_details: "infos supplémentaires",
        content_removed: "Contenu supprimé",
        no_versions_available:
          "Aucune version disponible. Les mises à jour peuvent prendre un certain temps pour s’afficher. Actualisez votre navigateur pour voir les modifications.",
        thumbnail_deleted: "Miniature supprimée",
        thumbnail_updated: "Miniature mise à jour",
        verify_service_plugin_is_running:
          "Veuillez vérifier que votre programme additionnel Tekla Warehouse Service fonctionne correctement",
        verify_ts_connection: "Veuillez vérifier que Tekla Structures est en cours d’exécution",
        download_tos: {
          title: "Qualité, assistance et confidentialité",
          content_1: "Veuillez vous assurer que le contenu téléchargé respecte vos normes de qualité. Trimble ne fournit pas d'assistance et n'est responsable d'aucun contenu distribué par des tiers. Contactez directement le fournisseur du contenu pour obtenir de l'assistance.",
          content_2: "Votre pays et le nom de votre organisation peuvent être publiés dans le service et divulgués aux propriétaires de contenu à des fins légitimes, par exemple pour vous informer de modifications de contenu. Vous pouvez vous désabonner de ces notifications.",
          content_3: `Pour les extensions de contenu Trimble, les <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">conditions de Trimble</a> s'appliquent. Les conditions de licence de tiers peuvent s'appliquer au contenu de tiers. Voir l'<a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Avis de confidentialité de Trimble</a>.`,
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "C'est un stockage BIM Tekla Structures gratuit. Recherchez, importez, installez et partagez en interne et à l’international. Produisez efficacement des modèles de qualité.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse est une bibliothèque en ligne et locale gratuite d'outils de modélisation Tekla Structures, de pièces et des gabarits",
        },
        search: {
          title: "Recherche Tekla Warehouse",
          description:
            "Rechercher des extensions Tekla, pièces 3D, formes, composants personnalisés, profils, matériaux (acier et béton), armatures, treillis et gabarits",
        },
        about: {
          title: "Informations sur Tekla Warehouse",
          description:
            "Tekla Warehouse propose une chaîne marketing gratuite pour vos produits et vous donne un contrôle total sur votre contenu",
          developers: {
            title: "Titre par défaut des développeurs",
            description: "Description par défaut des développeurs",
          },
          usage: {
            title: "Titre par défaut de l’utilisation",
            description: "Description par défaut de l’utilisation",
          },
          getting_started: {
            title: "Titre par défaut de la mise en route",
            description: "Description par défaut de la mise en route",
          },
          content: {
            title: "Titre par défaut du contenu",
            description: "Description par défaut du contenu",
          },
          using_content: {
            title: "Titre par défaut de l’utilisation du contenu",
            description: "Description par défaut de l’utilisation du contenu",
          },
          creating_content: {
            title: "Titre par défaut de la création de contenu",
            description: "Description par défaut de la création de contenu",
          },
        },
        collection: {
          title: "Titre par défaut de la collection",
          description: "Description par défaut de la collection",
        },
        my_warehouse: {
          title: "Mon Tekla Warehouse",
          description: "Gérer votre contenu Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Annuler",
          previous: "&lsaquo; Précédent",
          next: "Suivant &rsaquo;",
        },
        content: {
          add_content: "Ajouter des fichiers",
        },
        collection: {
          heading: "Sélectionner ou créer une collection",
          subHeading:
            "Groupe de collections. Vous pouvez utiliser des collections en ligne pour publier votre contenu ou des collections locales pour partager du contenu avec vos collègues. Vous pouvez également utiliser les collections locales pour un usage privé.",
          loading: "Chargement des collections...",
          selectFromMyCollections: "Sélectionner dans Mes collections",
          description_of_the_collection: "Description",
          title_validation: {
            missing: "Entrez un titre.",
            too_short: function (length) {
              return "Le titre doit comporter au moins " + length + " caractères.";
            },
            too_long: function (length) {
              return "Le titre ne peut pas comporter plus de " + length + " caractères.";
            },
          },
        },
        package: {
          basic_information: "Informations de base",
          code_name: "Nom de code",
          code_name_info:
            "Entrez un nom de code en tant qu’identifiant unique pour ce contenu. Le nom de code peut inclure des lettres minuscules, des chiffres et ces caractères spéciaux : _ - @. Vous ne pouvez pas modifier le nom du code une fois qu’il a été enregistré.",
          code_name_validation: {
            missing: "Vous devez entrer un nom de code",
            wrong_format: "Le nom de code peut inclure des lettres minuscules, des chiffres et les caractères spéciaux suivants : _ - @",
            not_unique: "Ce nom de code est déjà utilisé. Vous devez entrer un nom de code unique.",
          },
          search_criteria: "Critères de recherche",
          support: "Support",
          title: "Titre",
          thumbnail: "Miniature",
          "3D_pic": "Modèle 3D",
          title_validation: {
            missing: "Entrez un titre.",
            too_short: function (length) {
              return "Le titre doit comporter au moins " + length + " caractères.";
            },
          },
          description_validation: {
            missing: "Entrez une description.",
            too_short: function (length) {
              return "La description doit comporter au moins " + length + " caractères.";
            },
          },
          wrong_file_type: "Type de fichier incorrect.",
          selected_image_is_too_big: "L’image sélectionnée est trop grande.",
        },
        version: {
          select_version: "Sélectionner la version",
          select_quality: "Sélectionner la qualité",
          versions: "Versions",
          displayNameNew: "Nom complet de la version",
          copy_from_version: "Copier les métadonnées d'une version existante (facultatif)",
          displayName: "Nom d’affichage",
          content_information: "Informations sur le contenu",
          back_to_content_information: "&lsaquo; Retour aux informations sur le contenu",
          version_and_file_management: "Gestion des versions et des fichiers",
          there_s_no_versions_yet: "Aucune version disponible pour le moment.",
          create_a_version_to_add_files: "Créez une version pour ajouter des fichiers.",
          add_version: "Ajouter une version",
          version_information: "Informations sur la version",
          delete_version: "Supprimer la version",
          no_versions_available:
            "Aucune version disponible. Les mises à jour peuvent prendre un certain temps pour s’afficher.",
          update_start_title_html: '<div class="spinner spinner-white">Mise à jour</div>',
          update_start_message: "Cela peut prendre un certain temps.",
          version_was_created: "La version a été créée.",
          version_was_created_with_delay:
            "La version a été créée. Les modifications peuvent être visibles au bout de quelques minutes.",
          version_was_updated: "La version a été mise à jour.",
          version_was_deleted: "La version a été supprimée",
          version_was_deleted_with_delay:
            "La version a été supprimée. Les modifications peuvent être visibles au bout de quelques minutes.",
          confirm_delete: function (title) {
            return 'Effacer la version "' + title + '" ?';
          },
        },
        publish: {
          package_was_uploaded: "Le package a été téléchargé.",
          couldn_t_create_a_package: "Impossible de créer un package. Les modifications seront restaurées.",
          uploading_title_html: '<div class="spinner spinner-white">Chargement</div>',
          uploading_text: "Cela peut prendre un certain temps.",
          select_organization: "Sélectionner une organisation",
          collection_visibility: "Visibilité de la collection :",
          actions: {
            create: "Créer",
            publish: "Publier",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Ce contenu sera visible pour tous les utilisateurs ayant accès au lecteur local ou réseau.",
          this_content_will_be_visible_only_to_you: "Ce contenu sera visible uniquement pour vous.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Ce contenu sera visible uniquement pour vous et tous les utilisateurs de votre organisation.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Ce contenu sera visible pour tous après la publication.",
          public_content_disclaimer:
            "La responsabilité de l’intégralité du contenu que vous chargez vous incombe. Assurez-vous que votre contenu n’est pas dommageable, que vous disposez des droits nécessaires pour le charger et qu’il ne viole aucun droit de propriété intellectuelle. Vous acceptez de publier sur le service toutes les conditions générales susceptibles de s’appliquer à l’utilisation du contenu que vous chargez.",
        },
        metadata: {
          title: "Métadonnées",
          subtitle: "Données de base",
          deleteThumbnail: "Supprimer la miniature",
          buttonCreate: "Créer",
        },
        basicdata: {
          title: "Ajouter du contenu",
          clickButtonToSelectFile: "Cliquez sur le bouton pour sélectionner un fichier",
          thumbnail: "Miniature",
          dropImageHere: "Déposer l’image ici",
        },
        "3dGeometry": {
          title: "Créer un catalogue de produits 3D",
          buttonAddEntity: "Ajouter un produit 3D",
          buttonAdd: "Ajouter un attribut personnalisé",
          errorAttributeAlreadyExists: "Un attribut portant le même nom existe déjà.",
          buttonCreatePackage: "Créer un package",
          buttonCreatePackages: "Créer des packages",
          addCustomAttributePlaceholder: "Nom attribut",
        },
        please_log_in_fragments: {
          please: "Veuillez ",
          log_in: "Se connecter",
          or: " ou ",
          register: "vous inscrire",
          to_upload_online_content: " pour télécharger le contenu en ligne.",
        },
        header: {
          upload_header: "Charger",
          collection: "Collection",
          content: "Contenu",
          version: "Version",
          finish: "Terminer",
        },
        contentOwner: "Créateur",
        contentOwnerGroup: "Publier le contenu",
        select_action: "Sélectionner une action",
        tooltip: "Info-bulle",
        description: "Description",
        copyright: "Copyright",
        tags: "Étiquettes et mots-clés",
        tagsSubtitle:
          "Utilisez une virgule comme séparateur, par exemple : mots-clés 1, mots-clés 2. Si le mot-clé est composé de plusieurs mots, utilisez le trait de soulignement (_) entre les mots, aucun espace entre les mots n'est autorisé. À l'exception des traits de soulignement et des virgules, aucun caractère spécial n'est autorisé.",
        images: "Images",
        installationInfo: "Informations sur l’installation",
        productCode: "Code produit",
        copyrightInfo: "Informations de copyright",
        Details: "Détails",
        specialTermsUrl: "CLUF",
        productExpirationDate: "Date d’expiration du produit",
        helpUrl: "Site Web",
        helpUrlSubtitle: "Insérez l’URL complète",
        detailsSubtitle:
          "Dans cette phase, vous pouvez uniquement ajouter du texte brut au champ Détails. Une fois le contenu créé, vous pouvez modifier les détails pour qu'ils contiennent du formatage html.",
        specialTermsUrlSubtitle:
          "Le propriétaire a la possibilité d’ajouter des conditions supplémentaires applicables à son propre contenu. Le propriétaire doit fournir une URL où se trouvent ses conditions spécifiques. Celles-ci ne doivent pas outrepasser les conditions d’utilisation du service Trimble.",
        releaseNotes: "Fichier de la liste des nouveautés",
        releaseNoteFileSubTitle: "Ajouter la liste des nouveautés comme un fichier",
        supportUrl: "Aide et assistance",
        supportUrlSubtitle: "insérez l’URL complète ou le préfixe « mailto: » pour l’e-mail",
        supportForumUrl: "Forum de discussion (site Web)",
        supportForumUrlSubtitle: "Insérez l’URL complète",
        measurementUnits: "Unités de mesure",
        itemTypeCategories: "Grouper",
        useCategories: "Catégorie",
        compatibleSoftwareProducts: "Produits logiciels compatibles",
        testedVersions: "Versions compatibles",
        compatibleOperatingSystems: "Système d’exploitation compatible",
        locationRestrictions: "Disponibilité du produit",
        supportedLanguages: "Langues",
        select_releasenotes_file: "Sélection fichier",
        qualityTag: "Qualité - évaluée par le propriétaire",
        requireMaintenance: "Le téléchargement requiert la maintenance client Tekla",
        contentOwnerGroupAsMyself: "En tant que moi-même",
        contentOwnerGroupAsUserGroup: "En tant que groupe d’utilisateurs",
        selectUserGroupForContentOwner: "Sélectionner un groupe d’utilisateurs pour le propriétaire du contenu",
        defaultUploadTemplate: "Package par défaut",
        templateFor3DGeometryFiles: "Package(s) de géométrie 3D",
        title: "Titre",
        details: "Détails",
        selectContentOwner: "Sélectionner le propriétaire du contenu",
        selectContentType: "Type",
        errorWrongfiletype: "Type de fichier incorrect.",
        errorImageTooBig: "L’image sélectionnée est trop grande.",
        errorBlankTitle: "Le titre ne peut pas être vide.",
        commonLabelsTitle: "Étiquettes communes pour tous les packages",
      },
      profile: {
        content_usage: "Utilisation du contenu",
        profile_updated: "Profil mis à jour",
        invitation_accepted: function (target) {
          return "Invitation acceptée ! Vous n’avez pas accès à " + target + ".";
        },
        user_groups: {
          edit_user_group: "Modifier le groupe d’utilisateurs",
          user_group_updated: "Groupe d’utilisateurs mis à jour",
          create_a_new_user_group: "Créer un groupe d’utilisateurs",
          name_of_the_user_group: "Nom du groupe d’utilisateurs",
          description: "Description",
          group_visibility: "Visibilité groupe",
          remove: '"Êtes-vous sûr ?"',
          remove_invitations: '"Êtes-vous sûr ?"',
          remove_members: '"Êtes-vous sûr ?"',
          invitations_removed: "Invitations supprimées !",
          members_list_updated: "Liste des membres mise à jour !",
          user_group_created: "Groupe d’utilisateurs créé",
        },
        members: {
          update_selected_members: "Mettre à jour les membres sélectionnés",
          new_role: "Nouveau rôle",
          members_list_updated: "Liste des membres mise à jour !",
          edit_user_group: "Modifier le groupe d’utilisateurs",
          delete_user_group: "Supprimer le groupe d’utilisateurs",
          filter: "Filtre",
          user_group_members: "Membres du groupe d’utilisateurs",
          update_members: "Mettre à jour les membres",
          remove_members: "Supprimer les membres",
          pending_invitations: "Invitations en attente",
          invite_member: "Inviter un membre",
          update_invitations: "Mettre à jour les invitations",
          remove_invitations: "Supprimer des invitations",
        },
        subTabs: {
          alerts: "Mes alertes",
          collections: "Collections",
          likes: "J’aime",
        },
        admin: {
          grant_sales_admin: "Accorder le rôle d’administrateur des ventes pour l’utilisateur",
          grant_analyst_role: "Attribuer un rôle d'analyste à l’utilisateur",
          role_granted: "Rôle accordé",
          role_removed: "Rôle supprimé",
          offline_to_online: "Convertir le contenu hors ligne en contenu en ligne",
          use_elastic_search: "Utiliser la recherche élastique",
        },
        sales: {
          title: "Ventes",
        },
        subtitlePreferences: "Préférences de recherche",
        preferenceInfo:
          'Ces filtres servent à vous recommander des contenus sur la <a href="#/landing">page d’accueil</a> de Tekla Warehouse.',
        clearFilters: "Effacer les filtres &rsaquo;",
        admin_role: "Administration",
        sales_role: "Ventes",
        admin_mode: "Mode administrateur :",
        firstName: "Prénom",
        lastName: "Nom",
        displayName: "Nom d’affichage :",
        organization: "Nom de l’organisation :",
        email: "E-mail :",
        teklaId: "ID Tekla",
        maintenanceAccess: "Accès au contenu de la maintenance Tekla :",
        myLocations: "Emplacements des projets",
        measurementUnits: "Unités",
        useCategories: "Utiliser",
        itemTypeCategories: "Les types suivants m’intéressent",
        languages: "Langues",
        operatingSystems: "Systèmes d’exploitation",
        software: "Produits logiciels",
        user_id: "ID de l’utilisateur :",
        organization_id: "ID de l’organisation :",
        edit_in_atc: "Modifier mon profil dans Tekla Account",
        no_likes: "Aucun « j’aime »",
        no_alerts: "Aucun abonnement",
        clear_alerts: "Effacer les alertes",
        alerts_info_html:
          "Vous recevez une alerte quand une des modifications suivantes se produit<br>" +
          "<b>Contenu :</b> le titre, la description, les critères de recherche ou des métadonnées d’un élément de contenu sont modifiés, une nouvelle version du contenu est ajoutée, ou une version est supprimée effacée.<br>" +
          "<b>Collection :</b> le titre ou la description est modifiée, du contenu est ajouté ou supprimé.",
      },
      uploader: {
        placeholders: {
          productCode: "Code produit",
          title: "Nom",
          description: "Description",
          productWeight: "Poids du produit",
          weightUnit: "Unité de poids",
          material: "Matériau",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Catalogue des produits 3D",
          addToolButton: "Application",
          select_files: "Fichiers du disque",
          select_files_from_ts: "Fichiers provenant de Tekla Structures",
          partnerDownloadLink: "Lien de téléchargement partenaire",
        },
        headings: {
          geometryFile: "Fichier de géométrie",
          thumbnailFile: "Miniature (.bmp)",
          title: "Nom",
          description: "Description",
          productCode: "Code produit",
          productWeight: "Poids du produit",
          weightUnit: "Unité de poids",
          material: "Matériau",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Global",
          AF: "Afghanistan",
          AX: "Îles Åland",
          AL: "Albanie",
          DZ: "Algérie",
          AS: "Samoa américaines",
          AD: "Andorre",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarctique",
          AG: "Antigua-et-Barbuda",
          AR: "Argentine",
          AM: "Arménie",
          AW: "Aruba",
          AU: "Australie",
          AT: "Autriche",
          AZ: "Azerbaïdjan",
          BS: "Bahamas",
          BH: "Bahreïn",
          BD: "Bangladesh",
          BB: "Barbade",
          BY: "Biélorussie",
          BE: "Belgique",
          BZ: "Belize",
          BJ: "Bénin",
          BM: "Bermudes",
          BT: "Bhoutan",
          BO: "Bolivie",
          BQ: "Bonaire",
          BA: "Bosnie-Herzégovine",
          BW: "Botswana",
          BV: "Île Bouvet",
          BR: "Brésil",
          IO: "Territoire britannique de l’océan Indien",
          BN: "Brunei Darussalam",
          BG: "Bulgarie",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Cambodge",
          CM: "Cameroun",
          CA: "Canada",
          CV: "Cap-Vert",
          KY: "Îles Caïmans",
          CF: "République centrafricaine",
          TD: "Tchad",
          CL: "Chili",
          CN: "Chine",
          CX: "Île Christmas",
          CC: "Îles Cocos (Keeling)",
          CO: "Colombie",
          KM: "Comores",
          CG: "République du Congo",
          CD: "République démocratique du Congo",
          CK: "Îles Cook",
          CR: "Costa Rica",
          CI: "Côte d’Ivoire",
          HR: "Croatie",
          CU: "Cuba",
          CW: "Curaçao",
          CY: "Chypre",
          CZ: "République tchèque",
          DK: "Danemark",
          DJ: "Djibouti",
          DM: "Dominique",
          DO: "République dominicaine",
          EC: "Équateur",
          EG: "Égypte",
          SV: "Salvador",
          GQ: "Guinée équatoriale",
          ER: "Érythrée",
          EE: "Estonie",
          ET: "Éthiopie",
          FK: "Îles Malouines",
          FO: "Îles Féroé",
          FJ: "Fidji",
          FI: "Finlande",
          FR: "France",
          GF: "Guyane française",
          PF: "Polynésie française",
          TF: "Terres australes et antarctiques françaises",
          GA: "Gabon",
          GM: "Gambie",
          GE: "Géorgie",
          DE: "Allemagne",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Grèce",
          GL: "Groenland",
          GD: "Grenade",
          GP: "Guadeloupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernesey",
          GN: "Guinée",
          GW: "Guinée-Bissau",
          GY: "Guyana",
          HT: "Haïti",
          HM: "Îles Heard-et-McDonald",
          VA: "Cité du Vatican",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Hongrie",
          IS: "Islande",
          IN: "Inde",
          ID: "Indonésie",
          IR: "Iran",
          IQ: "Irak",
          IE: "Irlande",
          IM: "Île de Man",
          IL: "Israël",
          IT: "Italie",
          JM: "Jamaïque",
          JP: "Japon",
          JE: "Jersey",
          JO: "Jordanie",
          KZ: "Kazakhstan",
          KE: "Kenya",
          KI: "Kiribati",
          KW: "Koweït",
          KG: "Kirghizistan",
          LA: "Laos",
          LV: "Lettonie",
          LB: "Liban",
          LS: "Lesotho",
          LR: "Libéria",
          LY: "Libye",
          LI: "Liechtenstein",
          LT: "Lituanie",
          LU: "Luxembourg",
          MO: "Macao",
          MK: "Macédoine",
          MG: "Madagascar",
          MW: "Malawi",
          MY: "Malaisie",
          MV: "Maldives",
          ML: "Mali",
          MT: "Malte",
          MH: "Îles Marshall",
          MQ: "Martinique",
          MR: "Mauritanie",
          MU: "Maurice",
          YT: "Mayotte",
          MX: "Mexique",
          FM: "Micronésie",
          MD: "Moldavie",
          MC: "Monaco",
          MN: "Mongolie",
          ME: "Monténégro",
          MS: "Montserrat",
          MA: "Maroc",
          MZ: "Mozambique",
          MM: "Myanmar (Birmanie)",
          NA: "Namibie",
          NR: "Nauru",
          NP: "Népal",
          NL: "Pays-Bas",
          NC: "Nouvelle-Calédonie",
          NZ: "Nouvelle-Zélande",
          NI: "Nicaragua",
          NE: "Niger",
          NG: "Nigeria",
          NU: "Niue",
          NF: "Île Norfolk",
          KP: "Corée du Nord",
          MP: "Îles Mariannes du Nord",
          NO: "Norvège",
          OM: "Oman",
          PK: "Pakistan",
          PW: "Palau",
          PS: "Territoires palestiniens",
          PA: "Panama",
          PG: "Papouasie-Nouvelle-Guinée",
          PY: "Paraguay",
          PE: "Pérou",
          PH: "Philippines",
          PN: "Pitcairn",
          PL: "Pologne",
          PT: "Portugal",
          PR: "Porto Rico",
          QA: "Qatar",
          RE: "Réunion",
          RO: "Roumanie",
          RU: "Fédération de Russie",
          RW: "Rwanda",
          BL: "Saint-Barthélemy",
          SH: "Sainte-Hélène",
          KN: "Saint-Christophe-et-Niévès",
          LC: "Sainte-Lucie",
          MF: "Saint-Martin",
          PM: "Saint-Pierre-et-Miquelon",
          VC: "Saint-Vincent-et-les-Grenadines",
          WS: "Samoa",
          SM: "Saint-Marin",
          ST: "Sao Tomé-et-Principe",
          SA: "Arabie saoudite",
          SN: "Sénégal",
          RS: "Serbie",
          SC: "Seychelles",
          SL: "Sierra Leone",
          SG: "Singapour",
          SX: "Sint Maarten",
          SK: "Slovaquie",
          SI: "Slovénie",
          SB: "Îles Salomon",
          SO: "Somalie",
          ZA: "Afrique du sud",
          GS: "Géorgie du Sud",
          KR: "Corée du Sud",
          SS: "Soudan du Sud",
          ES: "Espagne",
          LK: "Sri Lanka",
          SD: "Soudan",
          SR: "Suriname",
          SJ: "Svalbard et Jan Mayen",
          SZ: "Swaziland",
          SE: "Suède",
          CH: "Suisse",
          SY: "République arabe syrienne",
          TW: "Taiwan",
          TJ: "Tadjikistan",
          TZ: "Tanzanie",
          TH: "Thaïlande",
          TL: "Timor-Leste",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad-et-Tobago",
          TN: "Tunisie",
          TR: "Turquie",
          TM: "Turkménistan",
          TC: "Îles Turques-et-Caïques",
          TV: "Tuvalu",
          UG: "Ouganda",
          UA: "Ukraine",
          AE: "Émirats arabes unis",
          GB: "Royaume Uni",
          US: "États-Unis",
          UM: "Îles mineures éloignées des États-Unis",
          UY: "Uruguay",
          UZ: "Ouzbékistan",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Viêt Nam",
          VG: "Îles Vierges (britanniques)",
          VI: "Îles Vierges (américaines)",
          WF: "Wallis-et-Futuna",
          EH: "Sahara occidental",
          YE: "Yémen",
          ZM: "Zambie",
          ZW: "Zimbabwe",
        },
        sortOptions: {
          "-modifyTime": "Dernière mise à jour",
          "-modifiedAt": "Dernière mise à jour",
          title: "Alphabétique A-Z",
          "-title": "Alphabétique Z-A",
          "-reviewCount": "Le plus apprécié",
          name: "Nom",
          role: "Rôle",
          email: "Adresse e-mail",
          "-relevance": "Pertinence",
          "-createdAt": "Dernière création",
          displayName: "Alphabétique A-Z",
          "-displayName": "Alphabétique Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Aujourd’hui",
          yesterday: "Hier",
          this_week: "Cette semaine",
          previous_week: "Semaine précédente",
          this_month: "Ce mois-ci",
          previous_month: "Mois précédent",
          this_year: "Cette année",
          previous_year: "Année précédente",
          beginning_of_time: "Début des temps",
          custom: "Durée personnalisée",
        },
        eventTypeOptions: {
          downloads: "Téléchargements",
          views: "Vues",
          all_events: "Tous les évènements",
        },
      },
    });
  },
};
