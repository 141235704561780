import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";

import { RootStore, RootContext } from "../stores/rootStore";
import { VersionEditorStore, VersionEditorContext } from "./versionEditorStore";

import { IDropdownOption, IVersion } from "../models/dataModel";
import { UploadStrategyEnum } from "../models/enums";

import { Dropdown } from "../components/Dropdown";

/**
 * Component for rendering the 'Select version' dropdown used in 'Add version' and 'Edit version' pages.
 */
export const SelectVersion = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const versionEditor: VersionEditorStore = useContext(VersionEditorContext);
  const editing = versionEditor.getStrategy() === UploadStrategyEnum.EDIT_VERSION;

  const versionOptions = versionEditor
    .getExistingVersions()
    .map((version) => {
      const versionNumberIsSameAsDisplayName = version.attributes.versionNumber === version.title;

      return {
        label:
          version.isImmutable && !versionNumberIsSameAsDisplayName
            ? `${version.attributes.versionNumber} (${version.title})`
            : version.title,
        value: version.id!,
      };
    })
    .reverse();

  const selectedOption = versionEditor.getSelectedVersionOption();

  function handleSelectVersion(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;

    const selectedVersion: IVersion = _.find(versionEditor.getExistingVersions(), (version) => {
      return version.id === selectedOption.value;
    })!;
    versionEditor.setSelectedVersion(selectedVersion);
  }

  return (
    <Dropdown
      options={versionOptions}
      selectedValue={selectedOption}
      placeholder={rootStore.getTranslation("upload.version.select_version")}
      onChange={handleSelectVersion}
      className={"dropdown-wrapper gray"}
      inputId={editing ? "editVersion" : "addVersion"}
    />
  );
});
