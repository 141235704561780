import * as React from "react";
import { useContext, Fragment } from "react";
import { RootContext } from "../../stores/rootStore";
import { PopupStatusStoreContext } from "./popupStatusStore";
import classNames from "classnames";
import { observer } from "mobx-react";

const PopupToggler = () => {
  const popupStatusStore = useContext(PopupStatusStoreContext);
  const rootStore = useContext(RootContext);
  const userStore = rootStore.getUserStore();

  function getUserName() {
    const currentUser = userStore.getCurrentUser();
    return currentUser && currentUser.displayName
      ? currentUser.displayName
      : rootStore.getTranslation("mainNavigation.profile");
  }

  function openPopup() {
    popupStatusStore.togglePopup("user");
  }

  return (
    <a data-testid="toggle-popup" id="user-menu" className="user popup-wrapper-item" onClick={openPopup}>
      <span className="icon icon-user"></span>
      <span className="text">{getUserName()}</span>
      <span className="icon icon-arrow-down user-menu"></span>
      {userStore.isAdminModeEnabled() && (
        <span className="role-label">{rootStore.getTranslation("profile.admin_role")}</span>
      )}
    </a>
  );
};

const AdminMode = () => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.getUserStore();

  return (
    <Fragment>
      {userStore.isUserAdmin() && (
        <li className="admin-mode">
          <span>{rootStore.getTranslation("profile.admin_mode")}</span>
          <div className="toggle-button">
            <button
              onClick={() => userStore.setAdminMode(false)}
              data-testid="disable-admin-mode"
              className={classNames({
                active: !userStore.isAdminModeEnabled(),
              })}
            >
              {rootStore.getTranslation("shared.catalog_entity_edit.editor_mode_off")}
            </button>
            <button
              onClick={() => userStore.setAdminMode(true)}
              data-testid="enable-admin-mode"
              className={classNames({ active: userStore.isAdminModeEnabled() })}
            >
              {rootStore.getTranslation("shared.catalog_entity_edit.editor_mode_on")}
            </button>
          </div>
        </li>
      )}
    </Fragment>
  );
};

const Popup = () => {
  const rootStore = useContext(RootContext);
  const userStore = rootStore.getUserStore();

  return (
    <ul className="popup">
      <li>
        <a href={userStore.getATCUrl()}>{rootStore.getTranslation("cubeNavigation.teklaMyAccount")}</a>
      </li>
      <li>
        <a data-testid="logout" onClick={() => rootStore.getUserStore().logout()}>
          {rootStore.getTranslation("mainNavigation.logout")}
        </a>
      </li>
      <AdminMode />
    </ul>
  );
};

const Login = () => {
  const rootStore = useContext(RootContext);

  return (
    <div className="login">
      <ul>
        <li>
          <a data-testid="login" onClick={() => rootStore.getUserStore().login()}>
            <span className="icon icon-user"></span>
            <span className="text-with-padding">{rootStore.getTranslation("cubeNavigation.login")}</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

/**
 * A component that displays user menu
 * @param store PopupStatusStore object.
 */
export const UserMenu = observer(() => {
  const popupStatusStore = useContext(PopupStatusStoreContext);
  const rootStore = useContext(RootContext);
  const userStore = rootStore.getUserStore();

  return (
    <div className="global-block last-block">
      {userStore.isUserLoggedIn() ? (
        <div
          className={classNames("profile popup-wrapper", {
            open: popupStatusStore.isPopupOpen("user"),
          })}
        >
          <PopupToggler />
          <Popup />
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
});
