import { action, runInAction, observable, makeObservable } from "mobx";
import { createStoreContext, RootStore } from "../../../stores/rootStore";
import { CollectionsStore } from "../../../stores/collectionsStore";
import { ILocalCollection } from "../../../models/dataModel";
import { NewRepository } from "../../../js/services/NewRepository";

export class LocalCollectionsStore extends CollectionsStore {
  /**
   * List of local collections
   */
  @observable protected localCollections: ILocalCollection[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Gets the local collections that are stored in the store.
   * @return local collections
   */
  public getLocalCollections(): ILocalCollection[] {
    return this.localCollections.filter((collection) => !collection.isPreinstalled);
  }

  /**
   * Gets the preinstalled collections that are stored in the store.
   * @return preinstalled collections
   */
  public getPreinstalledCollections(): ILocalCollection[] {
    return this.localCollections.filter((collection) => collection.isPreinstalled);
  }

  @action
  public async fetchData() {
    this.dataFetched = false;
    this.localCollections = [];

    try {
      const pkg = await NewRepository.getCollections(
        { isLocal: true },
        this.rootStore.getUserStore().getCurrentUser()?.id,
      );
      const result = this.setPaths(pkg);

      runInAction(() => {
        this.localCollections = result;
        this.dataFetched = true;
      });
    } catch {
      console.log("Failed to load local colections.");
      runInAction(() => {
        this.dataFetched = true;
      });
    }
  }

  /**
   * Sets paths for local collections.
   */
  protected setPaths(collections: ILocalCollection[]): ILocalCollection[] {
    return (collections || []).map((collection: ILocalCollection) => {
      collection.getCollectionPath = function () {
        return "/collections/local/" + this.id;
      };
      return collection;
    });
  }
}

/**
 * Context object for CollectionsStore instances.
 */
export const LocalCollectionsContext = createStoreContext<LocalCollectionsStore>(LocalCollectionsStore);
