import { IBinary } from "../models/dataModel";
import { ItemTypeEnum, PlatformEnum } from "../models/enums";

import { IdGenerator } from "./IdGenerator";

/**
 * BinaryUtils helper class
 */
export const BinaryUtils = {
  /**
   * Creates binary object based on given data
   * @param data the data used for creating binary
   */
  create: function (data: any): IBinary {
    const binary: IBinary = {
      attributes: {
        fileName: (!!data.attributes && data.attributes.fileName) || "",
        fileSize: (!!data.attributes && data.attributes.fileSize) || 0,
        itemSource: (!!data.attributes && data.attributes.itemSource) || undefined,
        itemType: (!!data.attributes && data.attributes.itemType) || ItemTypeEnum.NO_ACTION,
        originalName: (!!data.attributes && data.attributes.originalName) || "",
        platform: (!!data.attributes && data.attributes.platform) || PlatformEnum.WIN_X64,
      },
      blob: data.blob || "",
      contentUrl: data.contentUrl || "",
      file: data.file || undefined,
      id: data.id || "",
      location: data.location || "",
      reference: data.reference || IdGenerator.generateUUID(),
      url: data.url || "",
      value: data.value || "",
    };
    return binary;
  },
};
