import _ from "underscore";

export function AbstractSearchDataSource(qs, cat) {
  var queryString;
  var categories;
  var filters;
  var lastRow;
  var rows;
  var lastPage;
  var loaded;
  var totalRowsCount;
  var sortBy;
  var creator;
  var searchTarget;
  var excludeEnvironmentFiles;
  var recordEvent;

  reset(qs, cat);

  function loadNextRows() {}

  function loadNextPage() {}

  function getSearchTarget() {
    return searchTarget;
  }

  function setSearchTarget(target) {
    return (searchTarget = target);
  }

  function getExcludeEnvFiles() {
    return excludeEnvironmentFiles;
  }

  function setExcludeEnvFiles(excludeEnvFiles) {
    return (excludeEnvironmentFiles = excludeEnvFiles);
  }

  function getRecordEvent() {
    return recordEvent;
  }

  function setRecordEvent(record) {
    return (recordEvent = record);
  }

  function isLoaded() {
    return loaded;
  }

  function setLoaded(l) {
    loaded = l;
  }

  function reset(qs, sort, filter, createdBy, target, excludeEnvFiles, record) {
    queryString = qs || "";
    lastRow = 0;
    lastPage = false;
    loaded = true;
    totalRowsCount = 0;
    rows = [];
    sortBy = sort;
    filters = filter;
    creator = createdBy;
    searchTarget = target;
    excludeEnvironmentFiles = excludeEnvFiles;
    recordEvent = record;
  }

  function isAccessible() {
    return false;
  }

  function getLastRow() {
    return lastRow;
  }

  function setLastRow(lr) {
    lastRow = lr;
  }

  function getQueryString() {
    if (queryString) {
      return queryString;
    } else {
      return null;
    }
  }

  function getCategories() {
    return categories;
  }

  function getSortBy() {
    return sortBy;
  }

  function getFilters() {
    return filters;
  }

  function getCreator() {
    return creator;
  }

  function pageNumberToRowIndexesForTCC(itemsCount) {
    var count = itemsCount || 36;
    var rows = { offset: lastRow, count: count };
    return rows;
  }

  function pageNumberToRowIndexes(resultsPerPage, offset) {
    offset = offset || 0;
    resultsPerPage = resultsPerPage || 20;
    var rows = { offset: lastRow, count: lastRow + resultsPerPage - offset };
    return rows;
  }

  function getResults() {
    return rows;
  }

  function getResultsCount() {
    return _.size(rows);
  }

  function getResultsTotalCount() {
    return totalRowsCount;
  }

  function setResultsTotalCount(trc) {
    totalRowsCount = trc;
  }

  function setResults(r) {
    if (_.isArray(r)) {
      rows = r;
    }
  }

  function addResults(r) {}

  function isLastPage() {
    return lastPage;
  }

  function setLastPage(lp) {
    lastPage = lp;
  }

  return {
    loadNextRows: loadNextRows,
    loadNextPage: loadNextPage,
    setResultsTotalCount: setResultsTotalCount,
    getResultsTotalCount: getResultsTotalCount,
    getResultsCount: getResultsCount,
    isAccessible: isAccessible,
    getQueryString: getQueryString,
    getCategories: getCategories,
    getFilters: getFilters,
    pageNumberToRowIndexes: pageNumberToRowIndexes,
    pageNumberToRowIndexesForTCC: pageNumberToRowIndexesForTCC,
    getLastRow: getLastRow,
    setLastRow: setLastRow,
    getResults: getResults,
    addResults: addResults,
    setResults: setResults,
    getSortBy: getSortBy,
    getCreator: getCreator,
    isLastPage: isLastPage,
    setLastPage: setLastPage,
    isLoaded: isLoaded,
    setLoaded: setLoaded,
    reset: reset,
    getSearchTarget: getSearchTarget,
    getExcludeEnvFiles: getExcludeEnvFiles,
    getRecordEvent: getRecordEvent,
  };
}
