import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";

import { RootContext } from "../../stores/rootStore";
import { AdminStoreContext } from "./adminStore";
import { IBatchEntity, IBatchVersion, IEntity, IFileItem, IVersion } from "../../models/dataModel";

const Buttons = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);
  const isDisabled = store.isProcessing() || !store.binariesAreSelected();

  function packagesExist(): boolean {
    return store.getPackages().length > 0;
  }

  return (
    <Fragment>
      <button
        disabled={store.isProcessing()}
        className={"button-primary button-search"}
        onClick={() => store.searchInfectedPackages()}
        data-testid="search-binaries"
      >
        {rootStore.getTranslation("actions.search_infected_binaries")}
      </button>
      <button
        disabled={store.isProcessing() || !packagesExist()}
        className={"button-primary select-all"}
        onClick={() => store.selectAllBinaries(true)}
        data-testid="select-all-binaries"
      >
        {rootStore.getTranslation("helpers.select_all")}
      </button>
      <button
        disabled={isDisabled}
        className={"button-primary select-all"}
        onClick={() => store.selectAllBinaries(false)}
        data-testid="unselect-all-binaries"
      >
        {rootStore.getTranslation("helpers.unselect_all")}
      </button>
      <button
        disabled={isDisabled}
        className={"button-primary hide-selected"}
        onClick={() => store.rescanSelectedBinaries()}
        data-testid="rescan-selected-binaries"
      >
        {rootStore.getTranslation("actions.rescan_selected")}
      </button>
      <button
        disabled={isDisabled}
        className={"button-primary hide-selected"}
        onClick={() => store.resetSelectedBinariesAsOk()}
        data-testid="set-selected-binaries-as-ok"
      >
        {rootStore.getTranslation("actions.mark_as_ok")}
      </button>
      {store.isProcessing() && <div className="spinner">{rootStore.getTranslation("shared.spinner_loading")}</div>}
    </Fragment>
  );
});

const Binary = observer(({ binary, version }: { binary: IFileItem; version: IVersion }) => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);

  function toggleSelected(event) {
    store.selectBinary(binary, event.target.checked);
  }

  return (
    <Fragment>
      {!store.isProcessing() && (
        <div className="binary-list" data-testid="binaryListItem" role="listitem">
          <div className="checkbox select-binary">
            <input
              className="white"
              data-testid={`select-binary-${binary.id}`}
              id={`select-binary-${binary.id}`}
              type="checkbox"
              checked={!!binary.selected}
              onChange={toggleSelected}
            />
            <label htmlFor={`select-binary-${binary.id}`}>
              <div className="version-title">
                {rootStore.getTranslation("details.scan.popup.version")}
                {version.title},
              </div>
              <div className="binary-title">
                {rootStore.getTranslation("details.scan.popup.fileName")}
                {binary.attributes!.fileName}
              </div>
            </label>
          </div>
        </div>
      )}
    </Fragment>
  );
});

const Entity = observer(({ entity }: { entity: IEntity }) => {
  const rootStore = useContext(RootContext);
  const store = useContext(AdminStoreContext);

  function versionsExist(): boolean {
    return !!entity.versions && entity.versions.length > 0;
  }

  function getLink(): string {
    return "/catalog/details/" + entity.id;
  }

  return (
    <Fragment>
      {!store.isProcessing() && versionsExist() && (
        <div className="package-title" role="listitem" style={{ width: "100%" }}>
          {rootStore.getTranslation("details.scan.popup.package")}{" "}
          <Link to={getLink()} className="infected-package" target="_blank" rel="noopener noreferrer">
            {entity.title}
            {(entity.versions || []).map((version: IBatchVersion, i: number) => {
              return (
                <div key={i} className="binary-list" role="group">
                  {version.binaries.map((binary: IFileItem, j: number) => {
                    return <Binary key={j} binary={binary} version={version} />;
                  })}
                </div>
              );
            })}
          </Link>
        </div>
      )}
    </Fragment>
  );
});

const PackageList = observer(() => {
  const store = useContext(AdminStoreContext);

  return (
    <Fragment>
      {!store.isProcessing() && (
        <div className="packages-list" data-testid="packagesList" role="list">
          {store.getPackages().map((entity: IBatchEntity, i: number) => {
            return <Entity key={i} entity={entity} />;
          })}
        </div>
      )}
    </Fragment>
  );
});

export const ManageInfectedBinaries = observer(() => {
  return (
    <div className="virus-scan">
      <Buttons />
      <PackageList />
    </div>
  );
});
