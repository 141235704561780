import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import { RootContext, RootStore } from "../stores/rootStore";
import { IGroupAndCategoryHandler, IDropdownOption } from "../models/dataModel";
import { GroupAndCategoryHandlerContext } from "../package/tabs/groupAndCategoryStore";
import { DropdownMultiple } from "./DropdownMultiple";
import { metadataLabels } from "../utils/MetadataLabels";

//** Component for the Item/Search type category dropdown */
export const ItemTypeCategories = observer(({ hideLabel }: { hideLabel?: boolean }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: IGroupAndCategoryHandler = useContext(GroupAndCategoryHandlerContext);

  const itemTypeCategoriesOptions: IDropdownOption[] = _.map(metadataLabels.itemTypeCategoriesOptions, (option) => {
    return {
      label: rootStore.getTranslation(option.name),
      value: option.value,
    };
  });

  function handleSelectItemType(selectedOptions: readonly IDropdownOption[]) {
    store.setSearchTypeCategories(selectedOptions);
  }

  return (
    <li>
      {!hideLabel && <label>{rootStore.getTranslation("upload.itemTypeCategories")}</label>}
      <DropdownMultiple
        options={itemTypeCategoriesOptions}
        selectedValues={store.getSearchTypeCategories()}
        onChange={handleSelectItemType}
        className="dropdown-wrapper gray"
        inputId="itemTypeCategories"
        menuHeight={175}
      />
    </li>
  );
});
