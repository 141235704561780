import { observable, makeObservable } from "mobx";
import { AbstractAsyncStore } from "./abstractAsyncStore";
import { ICollection } from "../models/dataModel";
import { RootStore } from "./rootStore";

export abstract class CollectionsStore extends AbstractAsyncStore {
  /**
   * Collections that are stored in the store
   */
  @observable protected collections: ICollection[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Gets entities that are stored in the store.
   * @return entities
   */
  public getCollections(): ICollection[] {
    return this.collections;
  }

  /**
   * Gets the thumbnail for the collection
   */
  public getCollectionThumbnail(collection: ICollection) {
    const thumbnailUrl = collection.isLocal ? collection.thumbnail!.url : collection.thumbnails![0].contentUrl;
    return {
      backgroundImage: `url(${thumbnailUrl})`,
    };
  }

  protected setPaths(collections: ICollection[]): ICollection[] {
    return (collections || []).map((collection: ICollection) => {
      collection.getCollectionPath = function () {
        return "/collections/online/" + this.id;
      };
      collection.getOrganizationPath = function () {
        return "/organization/" + this.creator!.id;
      };
      return collection;
    });
  }
}
