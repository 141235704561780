
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const De = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["de-DE"]) {
      window.locale["de-DE"] = {};
    }

    _.extend(window.locale["de-DE"], {
      myDetails: {
        my_roles: "Meine Rollen",
        my_roles_link: "Rollen und Zugriffsrechte für Tekla Warehouse Inhalte",
        my_roles_link_url: "https://support.tekla.com/de/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Rollen",
        roles: {
          member: "Tekla Warehouse Content Editor (Mitarbeiter)",
          employee: "Mitarbeiter",
          contentEditor: "Tekla Warehouse Content Editor (Extern)",
        },
      },
      immutability: {
        add_code_name_for_package: "Hinzufügen eines Codenamens für den Inhalt",
        code_name: "Codename",
        immutable: "Unveränderbar",
        archive: "Archiv",
        archive_collection: "Die Sammlung und alle ihre Inhalte archivieren",
        archive_version: "Ausgewählte Version archivieren",
        archive_package: "Das Paket und alle seine Inhalte archivieren",
        archive_dialog_text:
          "Archivierte Inhalte sind in Tekla Warehouse nicht sichtbar oder durchsuchbar. Wenn der Inhalt jedoch zuvor mit dem Tekla Structures Cloud-Dienst verwendet wurde, kann der Cloud-Dienst weiterhin auf den Inhalt zugreifen.",
        archive_success: function (name: string) {
          return `${name} erfolgreich archiviert`;
        },
        archive_failure: function (name: string) {
          return `Archivierung von ${name} fehlgeschlagen`;
        },
        archived_content_redirect_message: "Der Inhalt, auf den Sie zugreifen möchten, wurde archiviert",
        edit_title_move_or_archive: "Titel bearbeiten, verschieben oder archivieren",
        edit_title_or_archive: "Titel bearbeiten oder archivieren",
        major_update: "Größere Aktualisierung",
        major_update_description: "Änderungen, die nicht abwärtskompatibel sind, z. B. das Hinzufügen oder Entfernen von Funktionen, oder andere nicht abwärtskompatible Änderungen.",
        minor_update: "Kleinere Aktualisierung",
        minor_update_description: "Abwärtskompatible Änderungen, z. B. neue Funktionen oder Erweiterungen, ohne dass bestehende Funktionen beeinträchtigt werden.",
        patch_update: "Patch-Aktualisierung",
        patch_update_description: "Abwärtskompatible Korrekturen, z. B. Sicherheitspatches, Fehlerbehebungen oder Leistungsverbesserungen, ohne neue Funktionen.",
        info: "Ihre Organisation stellt Inhalte für den Tekla Structures Cloud-Dienst bereit. Für Inhalte im Clouddienst sind ein Codename und eine Tekla Structures Versionsnummer erforderlich, um Kompatibilität und Konsistenz zu gewährleisten.",
        immutable_files_info:
          "Um die inhaltliche Konsistenz im Clouddienst zu gewährleisten, können Sie keine Dateien entfernen oder ändern. Wenn Änderungen erforderlich sind, archivieren Sie die aktuelle Version und laden Sie eine neue Version hoch.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Weitere Informationen finden Sie auf <a href="${readMoreUrl}" target="_blank">dieser Seite</a>.`;
        },
        resolve_conflict: {
          title: "Konflikt lösen",
          description:
            'Die folgenden Elemente wurden gefunden. Wählen Sie "Vorhandene behalten", um das in Ihrer Installation gefundene vorhandene Element zu behalten.',
          table_header_name: "Name",
          table_header_type: "Typ",
          table_header_action: "Aktion",
          action_keep_existing: "Vorhandene behalten",
          action_replace: "Ersetzen",
          action_done: "Fertig",
        },
        auth: {
          authentication_process_failed: "Authentifizierungsprozess fehlgeschlagen",
          access_denied: "Zugriff verweigert",
          handle_unauthorized: {
            unauthorized: "Unberechtigt",
            please_log_in: "Bitte melden Sie sich an oder registrieren Sie sich",
          },
        },
        translations: {
          edit_translations: "Übersetzungen bearbeiten",
          edit_translations_info: "Bitte wählen Sie eine Sprache aus der Liste aus, bearbeiten und speichern Sie sie",
          edit_translations_default_language_info:
            "<b>Standard</b> wird angezeigt, wenn die vom Benutzer ausgewählte Sprache nicht verfügbar ist",
          edit_content: "Inhaltsinformationen",
          edit_support: "Support",
          updated_successfully: "Übersetzungen erfolgreich aktualisiert",
          error_on_update: "Fehler beim Aktualisieren der Übersetzungen",
          default: "Standard",
          batch_translations: "Massenübersetzungen",
          export_import_info:
            "<b>Exportieren</b> Sie die Vorlage für Massenübersetzungen von Inhalten Ihrer Wahl, bearbeiten Sie die Vorlage durch Eintragen der Übersetzungen und <b>importieren</b> Sie die Vorlage anschließend, um viele Übersetzungen auf einmal zu speichern.",
          import_translations: "Importieren",
          export_translations: "Exportieren",
          translations_missing: " (leer)",
          select_content: "Zu exportierende Übersetzungen auswählen:",
          import_finished: "Import abgeschlossen!",
          select_file: "Datei auswählen",
          select_file_title: "Zu importierende Datei auswählen:",
          import_from_file: "Importieren",
          import_error_info: "Datei entspricht nicht dem Vorlagenformat:",
          export_selected: "Exportieren",
          invalid_file: "Ungültiges Format für Dateiinhalt",
          import_translations_finished: "Übersetzungen importiert",
          details_too_long: "Die Detailangaben überschreiten die maximal zulässige Länge",
          import_failed: "Importieren der Übersetzungen fehlgeschlagen",
          translate_to_other_languages: "In andere Sprachen übersetzen",
          import_count: function (count) {
            return "Aktualisierte Übersetzungen für " + count + " Inhalt";
          },
          invalid_language: function (contentId, language) {
            return "Inhalt '" + contentId + "' enthält ungültige Sprache '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "Inhalt '" + contentId + "' enthält ungültiges Übersetzungsfeld '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "Inhalt '" +
              contentId +
              "': Übersetzungsfeld '" +
              translationField +
              "' überschreitet die zulässige Länge"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "3D-Modell",
          "3d_pic_added": "3D-Modell erfolgreich hinzugefügt",
          "3d_pic_updated": "3D-Modell erfolgreich aktualisiert",
          "3d_pic_removed": "3D-Modell erfolgreich entfernt",
          "3d_pic_update_failed": "Aktualisierung des 3D-Modells fehlgeschlagen",
          "3d_pic_remove_failed": "Entfernen des 3D-Modells fehlgeschlagen",
          "3d_pic_missing":
            "Kein 3D-Bild für Inhalt. Zum Auswählen eines Bildes unten auf '3D-Modell auswählen ' klicken.",
          "3d_pic_uploading": "Das Modell wird geladen. Bitte warten ...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Zum Bearbeiten der Inhalte hier klicken",
          updated_successfully: "Aktualisierung erfolgreich",
          update_failed: "Aktualisierung fehlgeschlagen",
          edit_search_criteria: "Suchkriterien bearbeiten",
          edit_group_and_category: "Gruppe und Kategorie bearbeiten",
          edit_content: "Inhalte bearbeiten",
          edit_thumbnail: "Miniaturansicht bearbeiten",
          edit_thumbnail_descriptive: "Empfohlene Abmessungen: 400 × 300 px",
          edit_description: "Beschreibung bearbeiten",
          edit_download_link: "Partner-Download-Link hinzufügen oder bearbeiten",
          versions_not_shown_to_users:
            "Da dieses Inhaltselement mit einen Partner-Download-Link versehen ist, werden Tekla Warehouse-Benutzern die zuvor dem Inhaltselement hinzugefügten Versionen nicht angezeigt. Den Administratoren und Benutzern von Tekla Warehouse mit Bearbeitungsrechten werden weiterhin alle Versionen des Inhaltselements angezeigt.",
          partner_download_link: "Download-URL eingeben",
          partner_download_link_disclaimer:
            "Wenn Sie den Partner-Download-Link einem vorhandenen Inhaltselement hinzufügen, werden den Tekla Warehouse-Benutzern nicht mehr die zuvor dem Inhaltselement hinzugefügten Versionen angezeigt. Diese Aktion kann nicht rückgängig gemacht werden.",
          edit_title_or_delete: "Titel bearbeiten oder löschen",
          edit_title_or_move_delete: "Titel bearbeiten, verschieben oder löschen",
          edit_support: "Ergänzende Angaben bearbeiten",
          edit_release_notes: "Versionshinweise bearbeiten",
          edit_content_wysiwyg: "Inhaltsdetails bearbeiten",
          edit_content_embed_link: "Weitere Informationen finden Sie unter Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Sie können nur Videos von YouTube, Vimeo oder Alugha einbetten.",
          details_unallowed_embed_info:
            "Sie können nur Inhalte der folgenden Domänen einbetten: YouTube, Vimeo oder Alugha. Die zulässigen URL-Formate sind:",
          your_video_id: "IHRE_VIDEO_ID",
          edit_content_video_link_error: "Ungültiger Video-Link. Sie können Videos per URL von YouTube und Vimeo hinzufügen",
          edit_content_vide_code_error:
            "Ungültiges Einbettungsformat. Es werden nur iframes akzeptiert. Die zulässigen Videoanbieter sind YouTube, Vimeo und Alugha.",
          details_max_length_exceeded: "Die Detailangaben sind zu lang",
          editor_mode_label: "Bearbeitungsmodus:",
          editor_mode_on: "Ein",
          editor_mode_off: "Aus",
          do_not_notify: "Kleinere Aktualisierung; Abonnenten nicht benachrichtigen",
          invalid_tag: "Ungültige Bezeichnung",
        },
        transfer_package: {
          header: "Inhalte verschieben",
          actions: {
            move: "Verschieben",
            cancel: "Abbrechen",
          },
          loading: "Sammlungen werden geladen...",
          moving: "Inhalte werden verschoben",
          select_collection: "Sammlung auswählen",
          content_moved: "Inhalte verschoben. Die Änderungen sind nach wenigen Minuten sichtbar.",
          operation_failed: "Vorgang fehlgeschlagen",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Inhalte erfolgreich eingebunden in " + name;
          },
          selected_resources_linked: function (name) {
            return "Ausgewählte Inhalte erfolgreich eingebunden in " + name;
          },
          resources_linked_with_errors: "Inhalte verknüpft, bei einigen Inhalten sind Fehler aufgetreten",
          resources_linking_failed: "Verknüpfen der Inhalte fehlgeschlagen",
          resource_unlinked: "Verknüpfung erfolgreich entfernt. Die Änderungen sind nach wenigen Minuten sichtbar.",
          resources_unlinked:
            "Verknüpfungen erfolgreich vom ausgewählten Inhalt entfernt. Die Änderungen sind nach wenigen Minuten sichtbar.",
          resources_unlinked_with_errors: "Verknüpfungen entfernt, bei einigen Inhalten sind Fehler aufgetreten",
          resources_unlinking_failed: "Entfernen der Verknüpfungen fehlgeschlagen",
          linking: "Verknüpfung wird erstellt",
          linking_failed: "Verknüpfen fehlgeschlagen",
          linking_failed_title: "Folgende Inhalte konnten nicht verknüpft werden:",
          unlinking_failed_title: "Für folgende Inhalte konnte die Verknüpfung nicht entfernt werden:",
          linking_failed_already_contains:
            "Inhalt wurde bereits mit der ausgewählten Sammlung verknüpft. Es kann ein wenig dauern, bis die Aktualisierungen angezeigt werden.",
          linking_failed_already_contains_short: "Inhalt wurde bereits mit der ausgewählten Sammlung verknüpft",
          unlinking_failed: "Entfernen der Verknüpfung fehlgeschlagen",
          unlinking_failed_does_not_contain:
            "Inhaltsverknüpfung zur Sammlung wurde bereits getrennt. Es kann ein wenig dauern, bis die Aktualisierungen angezeigt werden.",
          unlinking_failed_does_not_contain_short: "Inhaltsverknüpfung zur Sammlung wurde bereits getrennt",
        },
        notification_channel: {
          title_success: "erfolgreich",
          title_notice: "Benachrichtigung",
          title_info: "Information",
          title_error: "Fehler",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Für den Download ist ein Tekla-Wartungsvertrag erforderlich",
          can_access_maintenance: {
            yes: "Ja",
            no: "Nein",
          },
        },
        confirm: {
          title: "Bestätigen",
          yes: "Ja",
          no: "Nein",
          ok: "OK",
          close: "Schließen",
          dont_show: "Diese Meldung nicht mehr anzeigen",
        },
        show_more: " Mehr anzeigen",
        show_less: " Weniger anzeigen",
        spinner_loading: "Ladevorgang läuft",
        spinner_processing: "In Bearbeitung",
        spinner_loading_long_time: "Das Laden von Inhalten kann einige Minuten dauern",
        local_service: {
          info: "Tekla Warehouse Service-Plug-in ist erforderlich, um lokale und Netzwerkinhalte zu erstellen. Mit Tekla Structures 20.1 SR1 oder höher können Sie Inhalte direkt aus Ihrem Modell hochladen.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-Plug-in</a> " +
            "ist erforderlich, um lokale und Netzwerkinhalte zu erstellen.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-Plug-in</a> " +
            "ist nicht verfügbar. Der Dienst ist erforderlich, um lokale und Netzwerksammlungen zu aktivieren.",
          download_to_unlock_all_features_html:
            "Laden Sie " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-Plug-in</a> " +
            "herunter, um alle Funktionen freizuschalten.",
          service_out_of_date_html:
            "Ihr Tekla Warehouse-Plug-in ist nicht aktuell. Aktualisieren Sie " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service-Plug-in</a> " +
            "herunter, um alle Funktionen freizuschalten.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Sprache auswählen",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Andere Benutzer einladen",
          send_invitation_to_email: "Einladung an E-Mail-Adresse senden",
          add_e_mail_addresses: "E-Mail-Adressen hinzufügen",
          and_assign_role: "und Rolle zuweisen",
          send_invitation: "Einladung senden",
          invitation_already_sent: "Einladung wurde bereits gesendet!",
          invitation_sent: "Einladung gesendet!",
          update_selected_invitations: "Ausgewählte Einladungen aktualisieren",
          new_role: "Neue Rolle",
          invitations_list_updated: "Einladungsliste aktualisiert!",
        },
        item_browser_title: "Inhaltselemente auswählen",
        prerequisites: {
          description_placeholder: "Beschreibung hier hinzufügen",
          link_name_placeholder: "Verknüpfungsnamen hier hinzufügen",
          add_prerequisites_and_recommendations: "Voraussetzungen und Empfehlungen (optional)",
          list_the_profiles_components_files:
            "Listen Sie die Profile, Komponenten, Dateien oder Modelle auf, die für die ordnungsgemäße Funktion der Inhalte erforderlich sind.",
          link_to_existing_content: "Verknüpfung zu vorhandenen Inhalten",
          please_enter_prerequisite_url: "Geben Sie die erforderliche URL ein",
          please_enter_valid_prerequisite_url: "Geben Sie eine gültige erforderliche URL ein",
          missing_http: "http:// fehlt",
          add_link: "Verknüpfung hinzufügen",
        },
        treeview: {
          cannot_export_this_container:
            "Dieser Container kann nicht exportiert werden. Wählen Sie stattdessen die einzelnen Elemente aus.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "Die maximale Dateigröße beträgt " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Ungültiger Dateityp.  Es muss sich um einen der folgenden Dateitypen handeln " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Zeitraum:",
          timeframe_start: "Start:",
          timeframe_end: "Ende:",
          eventtype: "Ereignistyp:",

          downloads_daily_by_version: "Downloads nach Version",
          downloads_by_country: "Downloads nach Land",
          downloads_by_version: "Downloads nach Version",
          downloads_by_version_organization: "Downloads nach Version und Organisation",
          downloads_by_organization: "Downloads nach Organisation",
          downloads_daily_by_package: "Downloads nach Inhaltselement",
          views_daily_by_package: "Aufrufe nach Inhaltselement",
          views_by_country: "Aufrufe nach Land",
          views_by_organization: "Aufrufe nach Organisation",

          downloads_by_package: "Downloads nach Inhaltselement",
          views_by_package: "Aufrufe nach Inhaltselement",
          downloads_daily_by_collection: "Downloads nach Sammlung",
          downloads_by_collection: "Downloads nach Sammlung",
          views_daily_by_collection: "Aufrufe nach Sammlung",
          views_by_collection: "Aufrufe nach Sammlung",

          events_by_organization_func: function (version) {
            return 'Version "' + version + '" Ereignisse nach Organisation'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Andere",
          sum_all_versions: "Alle Versionen",
          sum_all_packages: "Alle Inhaltselemente",
          sum_all_collections: "Alle Sammlungen",
          sum_all_organizations: "Alle Organisationen",
          recording_started_at: "Datenaufzeichnung begann im Juni 2015",
          recording_view_events_started_at: "Datenaufzeichnung begann im März 2017",
          version_title_func: function (title) {
            return 'Version "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " Downloads", count + " Download", count + " Downloads"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Angaben umfassen alle abgeschlossenen Downloads.",
          counted_events_info_package: "Angaben umfassen alle abgeschlossenen Downloads.",
          export: {
            caption: "Analytik exportieren",
            select_options: "Optionen auswählen",
            select_timeframe_description: "Wählen Sie unten einen Zeitraum für den Analytik-Export aus:",
            select_eventtype_description: "Wählen Sie den Ereignistyp für den Analytik-Export aus:",
          },
        },
        access_rights: {
          editors: "Editoren",
          download_access: "Aufrufe und Download-Zugriffe",
          write_user_id_placeholder: "Benutzer-ID schreiben",
          write_organization_id: "Geben Sie gültige Organisations-IDs ein. Geben Sie jede ID in einer separaten Zeile ein.",
          write_id_placeholder:
            "Geben Sie Benutzer-IDs, Benutzer-E-Mail-Adressen oder Organisations-IDs ein. Geben Sie die einzelnen IDs oder E-Mail-Adressen jeweils in einer separaten Zeile ein.",
          id_help_html_short:
            "Aus Sicherheitsgründen kann nicht nach Benutzern gesucht werden.<br/><br/>" +
            "Fordern Sie die Benutzer-ID direkt von der Person an, der Sie Zugriff gewähren möchten. <br/>" +
            'Die Benutzer-ID wird unter <a href="#/my/">Eigene Details</a> angezeigt.',
          id_help_html:
            "Aus Sicherheitsgründen kann nicht nach Benutzern gesucht werden.<br/><br/>" +
            "Fordern Sie die Benutzer-ID direkt von der Person an, der Sie Zugriff gewähren möchten. Um allen Benutzern einer Organisation Zugriff zu gewähren, müssen Sie die ID der Organisation angeben.<br/><br/>" +
            'Benutzer- und Organisations-ID werden unter <a href="#/my/">Eigene Details</a> angezeigt.',
          max_input_limit: function (max_rows) {
            return (
              "Geben Sie die einzelnen IDs oder E-Mail-Adressen jeweils in einer separaten Zeile ein. Sie können jeweils " +
              max_rows +
              " Zeilen auf einmal eingeben."
            );
          },
          candidates_for_keyword: function (keyword) {
            return "Gefundene Benutzer mit der E-Mail-Adresse oder ID " + keyword;
          },
          input_too_long: "Der eingegebene Text ist zu lang.",
          viewer_already_added: function (input) {
            return `${input} wurde bereits als Viewer hinzugefügt`;
          },
          wrong_format: function (input) {
            return `${input} ist keine gültige ID oder E-Mail-Adresse`;
          },
        },
      },
      images: {
        add_new: "Hinzufügen",
        add: "Hinzufügen",
        delete: "Löschen",
        set_as_default: "Standardminiaturansicht",
        select_file: "Datei auswählen",
        choose_option: "Option auswählen",
        info_3D_exists:
          "Sie haben bereits ein 3D-Modell hinzugefügt. Es kann nur ein Modell zum Inhalt hinzugefügt werden.",
        action_options: {
          image: "Bild",
          "3DModel": "3D-Modell",
          video: "Video",
        },
        video: {
          url: "Video-URL",
          invalid_url: "Ungültiger URL",
        },
      },
      units: {
        bytes: "Bytes",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Download",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " Likes", number + " Like", number + " Likes"]);
      },
      main_info: {
        header_title: "Tekla Warehouse im neuen Look!",
        header_text: "Wie gefällt Ihnen die neue, überarbeitete Optik der Tekla Warehouse-Website?",
        header_text2: "Die wichtigsten Änderungen im Überblick:",
        bullet_text_1: "Übersichtlichere Suche auf der Startseite.",
        bullet_text_2:
          "Alle Suchfilter befinden sich links und die Miniaturansicht enthält nun standardmäßig mehr Suchergebnisse bei gleichem Platzbedarf.",
        bullet_text_3:
          "Die Download-Option auf der Inhalteseite wurde zentral platziert, damit die Nutzung von Inhalten einfacher wird. Optionen zum Herunterladen und Installieren stehen nun über die Schaltfläche Download zur Verfügung.",
        bullet_text_4:
          "Auf der Seite mit den Sammlungen und im Warenkorb werden nun drei Schritte zum Herunterladen und Installieren aller Elemente angezeigt.",
      },
      organizations: {
        error_loading_collection_contents: "Fehler beim Laden von Sammlungsinhalten",
        error_loading_organization: "Fehler beim Laden der Organisation",
      },
      downloads_graph: {
        date: "Datum",
        total_views: "Anzahl der Aufrufe",
        total_downloads: "Anzahl der Downloads",
        views: "Ansichten",
        downloads: "Downloads",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service-Plug-in" +
          "</a>" +
          " ist erforderlich.",
        processingFailed: "Bearbeitung fehlgeschlagen",
        forbidden: "Unzulässig",
        resource_not_found: "Ressource nicht gefunden",
        no_matches_found: "Keine Treffer gefunden",
        no_matches_found_for: function (input) {
          return "Keine Ergebnisse für die E-Mail-Adresse oder ID gefunden " + input;
        },
        not_identified: "Nicht identifiziert",
        error_while_searching: "Fehler beim Suchen",
        error_user_not_found: "Benutzer oder Organisation nicht gefunden",
        error_while_adding_member: "Fehler beim Hinzufügen der Zugriffsrechte des Mitglieds",
        error_while_removing_member: "Fehler beim Entfernen der Zugriffsrechte des Mitglieds",
      },
      offline_to_online: {
        fail_on_initialize: "Fehler beim Bearbeiten von ZIP-Inhalten; Konvertierung wird abgebrochen",
        fail_on_validate: "Metadaten-Prüfung der Inhalte fehlgeschlagen, Konvertierung wird abgebrochen",
        fail_on_save: "Fehler beim Speichern der Inhalte; Änderungen werden verworfen",
        success: "Offline-Inhalt erfolgreich in Online-Inhalt konvertiert",
        validate_errors_title: "Konvertieren von Inhalten fehlgeschlagen",
        processing: "Paket wird gespeichert ",
        processed: "Verarbeitetes Element",
        collection_thumbnail_missing_name: "Miniaturansicht-Binärdatei für Sammlung fehlt 'Dateiname'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Paket '" + packageTitle + "': Binärdatei für Miniaturansicht fehlt 'Dateiname'";
        },
        package_value_missing_title_func: function () {
          return "'Titel' für Paket fehlt";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Paket '" + packageTitle + "' ohne '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "Paket '" +
            packageTitle +
            "': Feld '" +
            field +
            "' weist die folgenden ungültigen Werte auf: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Paket '" + packageTitle + "' enthält ungültige Bezeichnung: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "Paket '" + packageTitle + "': Version ohne 'Titel'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Paket '" + packageTitle + "': Version '" + versionTitle + "' fehlt '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Paket '" +
            packageTitle +
            "': Version '" +
            versionTitle +
            "': Feld '" +
            field +
            "' enthält die folgenden ungültigen Werte: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "Paket '" + packageTitle + "': Version '" + versionTitle + "': Binärdatei fehlt 'Dateiname'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Wichtig: Supportende für lokale Sammlungen im Tekla Warehouse",
            part1: "Ab dem 2. Juni 2025 werden lokale Sammlungen im Tekla Warehouse nicht mehr verfügbar sein. Benutzer werden keine lokalen Sammlungen mehr erstellen, anzeigen oder durchsuchen können. Inhalte aus lokalen Sammlungen können nicht mehr verwendet werden.",
            part2: "Für Unterstützung beim Übergang von lokalen Sammlungen zu Online-Sammlungen kontaktieren Sie bitte Ihre lokale Support-Organisation.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Wichtig! Als Sicherheitsverbesserung müssen Sie über die Rolle Tekla Warehouse Content Editor verfügen, um die Inhalte Ihrer Organisation ab dem ${date}. November 2024 verwalten zu können. <br />Bitten Sie den Administrator Ihrer Organisation, Ihnen die Rolle zuzuweisen. Weitere Informationen finden Sie in dem <a href="${productBulletinUrl}" target="_blank">Produktbulletin</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Als Sicherheitsverbesserung müssen Sie über die Rolle Tekla Warehouse Content Editor verfügen, um die Inhalte Ihrer Organisation ab dem ${date}. November 2024 verwalten zu können. <br />Bitten Sie den Administrator Ihrer Organisation, Ihnen die Rolle zuzuweisen. Weitere Informationen finden Sie in dem <a href="${productBulletinUrl}" target="_blank">Produktbulletin</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Sie sehen keine Bearbeitungsoption für Ihre Organisation? Ab dem ${date}. November muss Ihnen die Rolle Tekla Warehouse Content Editor zugewiesen sein, damit Sie die Inhalte Ihrer Organisation verwalten können. <br />Sie können Ihre Rolle unter "Mein Warehouse" > "Eigene Details" überprüfen. Weitere Informationen finden Sie in dem <a href="${productBulletinUrl}" target="_blank">Produktbulletin</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Sie sehen keine Option zum Erstellen von Inhalten für Ihr Unternehmen? Ab dem ${date}. November muss Ihnen die Rolle Tekla Warehouse Content Editor zugewiesen sein, damit Sie die Inhalte Ihrer Organisation verwalten können. <br />Sie können Ihre Rolle unter "Mein Warehouse" > "Eigene Details" überprüfen. Weitere Informationen finden Sie in dem <a href="${productBulletinUrl}" target="_blank">Produktbulletin</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Ab dem ${date}. November muss Ihnen die Rolle Tekla Warehouse Content Editor zugewiesen sein, damit Sie die Inhalte Ihrer Organisation verwalten können. Bitten Sie den Administrator Ihrer Organisation, Ihnen die Rolle zuzuweisen. <br />In Ihrem Tekla Online-Profil können Sie überprüfen, wer der Administrator Ihrer Organisation ist. Weitere Informationen finden Sie in dem <a href="${productBulletinUrl}" target="_blank">Produktbulletin</a>.`
            },
          },
          service_plugin_update_info:
            'Haben Sie Probleme, sich mit Tekla Structures zu verbinden? Stellen Sie sicher, dass Sie das neueste <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="blank" rel="noopener noreferrer">Tekla Warehouse Service Plugin verwenden</a>.',
        },
        maintenance_info:
          "Tekla Warehouse wird zwischen 2017-10-27 22:00:00 und 23:00:00 UTC gewartet. Wir entschuldigen uns für eventuelle Unannehmlichkeiten.",
        release_notes_info: "Neue Funktionen in Tekla Warehouse",
        release_notes_link:
          'Tekla Warehouse wurde aktualisiert: <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">Das ist neu</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Sie suchen für Ihr Projekt einen Tekla Entwickler?",
        heading2Dev:
          'Dann sollten Sie das neue Portal Tekla Development Experts besuchen.<br><br>Dies ist ein Treffpunkt für talentierte Programmierer und interessierte Tekla Anwender.<br><br>Mitglieder des Tekla Partners Program können ihre Anliegen auf dem Experts-Portal eingeben.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Entwickler suchen &rsaquo;</a>',
        static_intro:
          "Dies ist der kostenlose BIM-Speicher für Tekla Structures. Hier können Sie Inhalte suchen, importieren, installieren und intern sowie global freigeben. Mit Tekla Warehouse erstellen Sie hochwertige Modelle auf effiziente Art und Weise.",
        new_intro:
          'Tekla Corporation heißt jetzt Trimble. Wir entwickeln die Tekla-Software-Lösungen kontinuierlich weiter. Auch Ihre Ansprechpartner bleiben dieselben. Weitere Informationen: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Bestätigen Sie Ihr Konto",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Neueste Uploads &rsaquo;",
          popular: "Beliebte Inhalte &rsaquo;",
          recommended: "Empfohlen &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Neues &rsaquo;</a>',
          item_title: "Das ist neu!",
          item_description:
            'Text zu Neuigkeiten, Text zu Neuigkeiten, Text zu Neuigkeiten, Text zu Neuigkeiten, Text zu Neuigkeiten, Text zu Neuigkeiten.<br><a href="https://developer.tekla.com" target="_blank">Weitere Informationen &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse in Zahlen",
          partners: "Partner",
          partners_count: "352",
          collections: "Sammlungen",
          collections_count: "793",
          items: "Inhaltselemente",
          items_count: "2893",
        },
        partners: "Unsere Partner",
        action_links: {
          search_title: "Möchten Sie Inhalte suchen?",
          upload_title: "Möchten Sie Inhalte hochladen?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Videos &rsaquo;</a>',
          link: 'Besuchen Sie unseren <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Youtube-Kanal</a> für weitere Videos.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Prüfen Sie Ihre E-Mails auf den Bestätigungslink, oder <u><a href="' +
            settingsUrl +
            '">fordern Sie einen neuen Link an</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Überprüfen Sie Ihre E-Mail-Adresse, und akzeptieren Sie die Bestimmungen und die Datenschutzrichtlinie",
        acceptTerms: "Bitte akzeptieren Sie die Geschäftsbedingungen und die Datenschutzrichtlinie",
        acceptUpdatedTerms:
          "Stimmen Sie den aktualisierten Bestimmungen und der Datenschutzrichtlinie für die tekla online services zu",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Zurück zu Ihrem <u><a href="' + settingsUrl + '">Profil</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Lesen und akzeptieren Sie die Bestimmungen und die Datenschutzrichtlinien in Ihrem <u><a href="' +
            settingsUrl +
            '">Profil</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Sammlungen durchsuchen &rsaquo;",
          new: "Neu",
          popular: "Beliebt",
          recommended: "Empfohlen",
          organizations: "Organisationen",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Ordner auswählen",
        selectFolderInstructions: "Lokale und Netzwerkordner können als Sammlungen genutzt werden.",
        selectedFolder: "Ausgewählter Ordner (Pfad kopieren und einfügen):",
        selectedFolderPlaceholder: "z. B. C:\\Ordner, Z:\\Netzwerkordner, \\\\Computername\\Ordner",
        collectionCreation: {
          name: "Name",
          createNewCollection: "Lokale Sammlung erstellen",
          pleaseInputTitle: "Geben Sie einen Titel ein",
          invalidPath: "Ungültiger Pfad",
          access_denied: "Hinzufügen der Sammlung fehlgeschlagen: Zugriff verweigert",
          unsafe_location: "Hinzufügen der Sammlung fehlgeschlagen: Unsicherer Speicherort",
          add_collection_failed: "Hinzufügen der Sammlung fehlgeschlagen",
          minLengthError: "Der Titel muss aus mindestens 3 Zeichen bestehen",
          maxLengthError: "Der Titel darf maximal 20 Zeichen enthalten",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Lokale und Netzwerkordner können als Sammlungen genutzt werden.",
        collectionCreation: {
          name: "Name",
          createNewCollection: "Online-Sammlung erstellen",
          pleaseInputTitle: "Geben Sie einen Titel ein",
          minLengthError: "Der Titel muss aus mindestens 3 Zeichen bestehen",
          maxLengthError: "Der Titel darf maximal 20 Zeichen enthalten",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Ressource gespeichert",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Sehen Sie das Potential?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Feedback geben</span>',
          help_us_improve_html:
            'Helfen Sie uns, Tekla Warehouse zu verbessern! <br/><a id="showSurvey">Klicken Sie hier,</a> oder <a id="dontShow">zeigen Sie diese Meldung nicht mehr an</a>',
          dialog: {
            do_you_see_potential: "Sehen Sie das Potential?",
            help_us_improve_html: "Helfen Sie uns, Tekla Warehouse zu verbessern! Ihre Meinung ist uns wichtig.",
            continue_to_survey: "Umfrage starten",
            exit_to_warehouse: "Nein, danke",
          },
        },
      },
      search: {
        bar: {
          search: "Suchen",
          filter: " ",
          more_filters: "Weitere Filter",
          hide_filters: "Filter ausblenden",
          clear: "Löschen",
          exclude: "Ausschließen",
          include: "Einschließen",
          clear_filters: "Filter zurücksetzen",
          load_defaults: "Meine Einstellungen verwenden &rsaquo;",
          save_defaults: "Unter meinen Einstellungen speichern &rsaquo;",
          defaults_saved_successfully: "Standardfilter gespeichert",
          edit_defaults: "Vorgaben bearbeiten",
        },
        info: {
          searching_organizations: "Suche in Organisationen...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Keine Ergebnisse", amountTotal + " Ergebnisse"]);
            } else {
              return (
                pluralize(amountTotal, ["Keine Ergebnisse", amountTotal + " Ergebnisse"]) +
                ' für "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " Ergebnisse";
            } else if (!searchTerm) {
              return "Geben Sie einen Suchbegriff ein, um Organisationsergebnisse anzuzeigen";
            } else if (searchTerm.length < 2) {
              return "Geben Sie mindestens zwei Zeichen für die Organisationssuche ein";
            } else {
              return (
                pluralize(amountTotal, ["Keine Ergebnisse", amountTotal + " Ergebnisse"]) +
                ' für "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " Ergebnisse in Online-Inhalten",
          local: " Ergebnisse in lokalen Inhalten",
          organization: "Ergebnisse in Organisationen",
          plugin_missing_info:
            'Lokale Inhalte werden nur angezeigt, wenn Sie das <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service-Plug-in" +
            "</a>" +
            " installiert haben.",
          version_filter_info: "Das Filtern nach Softwareversionen ist in lokalen Inhaltsergebnissen ungültig.",
        },
        exclude_env_files: "Umgebungsdateien ausschließen",
        sidebar: {
          filters: {
            measurementUnits: "Maßeinheiten",
            locations: "Projektspeicherorte",
            languages: "Sprachen",
          },
          placeholders: {
            use_categories: "Kategorie",
            measurement_units: "Maßeinheiten",
            languages: "Sprachen",
            locations: "Speicherorte",
          },
        },
      },
      placeholders: {
        name: "Name...",
        contentOwner: "Name des Inhaltsbesitzers...",
        useCategory: "Verwendung auswählen",
        selectType: "Typ auswählen",
        filter: "Filtern...",
        search: "Suchen...",
        choose: "Auswählen...",
        searchFilters: "Suchfilter...",
        selectContentItem: "Inhaltselement auswählen...",
        search_content: "Inhalte suchen...",
        includeAs: "Berücksichtigen als...",
        selectCollection: "Sammlung auswählen",
        selectUserGroup: "Benutzergruppe auswählen",
        online_collections_tooltip: "Für die Suche in Online-Inhalten klicken",
        local_collections_tooltip:
          "Klicken Sie zum Suchen nach lokalen und Netzwerkinhalten und Inhalten innerhalb der Tekla Structures-Installation",
        organizations_tooltip: "Klicken Sie für die Suche nach Organisationen",
        "filter-options": {
          products: "Alle Softwareprodukte",
          locations: "Alle Speicherorte",
          languages: "Alle Sprachen",
          measurementUnits: "Alle Maßeinheiten",
          testedVersions: "Alle Softwareversionen",
          useCategories: "Alle Kategorien",
          useCategoriesCombined: "Kategorie",
          allGroups: "Alle Gruppen",
          itemTypeCategories: "Alle Elementtypen",
        },
        "exclude-filter-options": {
          products: "Softwareprodukte ausschließen",
          locations: "Speicherorte ausschließen",
          languages: "Sprachen ausschließen",
          measurementUnits: "Maßeinheiten ausschließen",
          testedVersions: "Softwareversionen ausschließen",
          useCategories: "Kategorien ausschließen",
          useCategoriesCombined: "Kategorie ausschließen",
          allGroups: "Gruppen ausschließen",
          itemTypeCategories: "Elementtypen ausschließen",
        },
      },
      link: {
        link_selected: "Auswahl verknüpfen",
        unlink_selected: "Verknüpfung für Auswahl aufheben",
      },
      download_install: {
        choose_version: "Wählen Sie Ihre Softwareversion aus:",
        choose_content: "Wählen Sie Inhaltselemente aus der nachstehenden Liste aus:",
        choose_action: "Wählen Sie die Aktion für ausgewählte Elemente aus:",
      },
      download: {
        copy_to_clipboard: "In Zwischenablage kopieren",
        download_selected: "Herunterladen",
        add_selected_items_to_basket: "Auswahl zum Warenkorb hinzufügen",
        add_item_to_basket: "Auswahl zum Warenkorb hinzufügen",
        add_all_items_to_basket: "Alle in den Warenkorb",
        add_all_items_to_basket_info: "Es gibt über 100 Elemente. Der Ladevorgang kann einige Zeit dauern",
        add_all_items_to_basket_too_many_results: "Sie können nicht mehr als 1000 Elemente hinzufügen",
        cart_loading: "Es gibt über 100 Elemente. Der Ladevorgang kann einige Zeit dauern",
        remove_item_from_basket: "Aus Warenkorb entfernen",
        shopping_cart_no_content: "Keine Inhalte im Warenkorb",
        shopping_cart_no_content_for_version: "Keine Inhalte im Warenkorb für die ausgewählte Version",
        shopping_cart_items_func: function (itemsCount) {
          return "Mein Warenkorb (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Anleitungen herunterladen",
          step_one: "Kopieren Sie die Downloadinformationen mit dieser Schaltfläche in die Zwischenablage",
          step_two_func: function (downloadAppUrl) {
            return (
              "Führen Sie die Anwendung Tekla Warehouse Downloader aus. Sie können die Anwendung " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">hier herunterladen.</a> '
            );
          },
          step_three: "Die Anwendung Tekla Warehouse Downloader führt Sie durch den restlichen Download.",
          show_data_title: "Probleme?",
          show_data_to_be_copied: "Manuell kopieren",
          hide_data_to_be_copied: "Downloaddaten ausblenden",
        },
      },
      install: {
        install_title: " Elemente werden über das Tekla Warehouse-Plug-in installiert.",
        failed_install_information:
          "Sie haben Inhalte ausgewählt, die nur heruntergeladen werden können. Diese Inhalte werden jetzt nicht installiert. Laden Sie diese Inhalte separat aus Tekla Warehouse herunter.",
        verify_service_plugin_is_running:
          "Installieren: Stellen Sie sicher, dass das Tekla Warehouse Service-Plug-in ausgeführt wird",
        verify_ts_connection: "Installieren: Stellen Sie sicher, dass Tekla Structures ausgeführt wird",
        select_software_version: "Filtern Sie zum Verknüpfen, Herunterladen oder Installieren nach Softwareversion",
        download_requires_maintenance:
          "Für den Zugriff auf einige der Elemente unten ist ein Tekla-Wartungsvertrag erforderlich",
        install_selected: "Installieren",
        start_install: "Installation starten",
        install_started: "Installation wurde gestartet",
        select_ts_version: "TS-Version auswählen",
        install_retry: "Falls eine Wiederholung erforderlich ist, beginnen Sie erneut in Tekla Warehouse",
        failed_installs_info_func: function (failedCount) {
          return "Inhalte, die nicht installiert werden (" + failedCount + " Versionen):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Installation für " + totalInstallsCount + " ausgewählte Elemente";
        },
      },
      helpers: {
        behalf: "Durch",
        sortBy: "Sortieren nach:",
        filter: "Ergebnisse filtern",
        showAsThumbs: "Als Miniaturansichten anzeigen",
        showAsList: "Als Liste anzeigen",
        resultsPerPage: "Pro Seite anzeigen:",
        filter_by_version: "Nach Version filtern:",
        show_more_options: "Weitere Optionen",
        show_less_options: "Weniger Optionen",
        filter_by_type: "Nach Typ filtern:",
        select_all: "Alle auswählen",
        show_content_items: "Inhaltselemente anzeigen",
        choose_action: "Legen Sie eine Aktion für den ausgewählten Inhalt fest: ",
        show_collections: "Sammlungen anzeigen",
        search: {
          show: "Anzeigen:",
          show_content_items: "Inhaltselemente",
          show_collections: "Sammlungen",
          show_organizations: "Organisationen",
          show_online_content: "Online-Inhalt",
          show_offline_content: "Offline-Inhalt",
          local_content_info:
            'Lokale Inhalte werden nur angezeigt, wenn Sie das <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            "-Plug-in installiert haben.",
        },
        tekla_maintenance_required:
          "Für den Zugriff auf einige der Elemente unten ist ein Tekla-Wartungsvertrag erforderlich",
        show_content_alerts: "Inhaltswarnungen anzeigen ",
        show_collection_alerts: "Sammlungswarnungen anzeigen ",
      },
      actions: {
        load_more: "Mehr laden",
        use_infitinite_scroll: "Unendliches Blättern verwenden",
        back: "Zurück",
        show_download_dialog: "Herunterladen",
        download: "Nur herunterladen",
        download_on_partner_site: "Von Partner-Website herunterladen",
        insert: "Installieren",
        install: "Installieren",
        downloadAndInstall: "Installieren",
        createNew: "Neue erstellen",
        viewItems: "Ansicht",
        viewCollection: "Sammlung anzeigen",
        viewGroup: "Gruppe anzeigen",
        joinGroup: "Gruppe beitreten",
        loginToDownload: "Anmelden zum Herunterladen",
        show3DPic: "3D-Modell anzeigen",
        remove: "Entfernen",
        browse: "Durchsuchen",
        browseFolder: "Durchsuchen",
        browseFileSystem: "Durchsuchen",
        add: "Hinzufügen",
        save: "Speichern",
        batch_edit: "Stapelbearbeitung",
        translations: "Übersetzungen",
        cancel: "Abbrechen",
        continue: "Weiter",
        delete: "Löschen",
        addNetworkPath: "Netzwerkspeicherort hinzufügen",
        select: "Auswählen",
        create: "Erstellen",
        update: "Aktualisieren",
        choose_thumbnail: "Miniaturansicht auswählen",
        choose_picture: "Bild auswählen",
        remove_picture: "Bild löschen",
        choose_3D_picture: "3D-Modell auswählen",
        remove_3D_picture: "3D-Modell entfernen",
        like: "Gefällt",
        unlike: "Gefällt nicht",
        close: "Schließen",
        export: "Exportieren",
        add_or_remove: "Hinzufügen/Entfernen",
        select_content_to_convert: "Offline-Inhalte auswählen",
        search: "Suchen",
        upload: "Hochladen",
        edit: "Bearbeiten",
        view_3d: "Zum Anzeigen des Modells klicken...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "Beta",
        search: "Suchen",
        profile: "Eigene Details",
        myWarehouse: "Mein Warehouse",
        myStuff: "Meine Dinge",
        logout: "Abmelden",
        login: "Anmelden",
        register: "Registrieren",
        upload: "Hochladen",
        uploading: "Hochladen",
        dashboard: "Dashboard",
        learnMore: "Weitere Informationen",
        about: "Über",
        shoppingCart: "Mein Warenkorb",
        addToCartInfo:
          "Sie können Elemente in Mein Warenkorb legen und alle gleichzeitig herunterladen oder installieren. Der Download ist kostenlos",
      },
      cubeNavigation: {
        headings: {
          get_started: "ERSTE SCHRITTE",
          learn: "LERNEN",
          get_help: "HILFE ERHALTEN",
          extend_your_tekla: "TEKLA ERWEITERN",
          collaborate: "ZUSAMMENARBEITEN",
          students_and_educators: "Students and educators",
          about: "ÜBER",
        },
        "tekla-online": "Online-Services",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Tekla-Website",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla-Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Mein Profil",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla-E-Learning",
        tekla_services: "TEKLA-SERVICES STARTSEITE",
        register: "Registrieren",
        login: "Anmelden",
        evaluate: "Testen",
        buy: "Kaufen",
        download: "Herunterladen",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Produkte",
        teklaServices: "Dienste",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Meine Sammlungen",
        myLikes: "Meine Gefällt-Angaben",
        profile: "Eigene Details",
        preferences: "Meine Einstellungen",
        myUserGroups: "Meine Benutzergruppen",
        localCollections: "Lokale und Netzwerksammlungen",
        tsCollections: "Tekla Structures-Sammlungen",
        downloads: "Downloads",
        selectContent: "Inhaltsdateien auswählen",
        addMetadata: "Metadaten hinzufügen",
        onlineCollections: "Online-Sammlungen",
        userGroups: "Benutzergruppen",
        createNew: "Inhalte hinzufügen",
        notifications: "Benachrichtigungen",
        contentUsage: "Inhaltsverwendung",
        browseCollections: "Sammlungen durchsuchen",
        admin: "Admin",
        sales: "Vertrieb",
        shoppingCart: "Mein Warenkorb",
      },
      notifications: {
        title: "Benachrichtigung",
        invitedBy: "Eingeladen von",
        invitationType: "Einladungstyp",
        invitedTo: "Eingeladen zu",
        createdAt: "Erstellt",
        action: "Aktion",
        acceptInvitation: "Annehmen",
      },
      batch_edit: {
        selectActionType: "Aktionstyp auswählen",
        edit_content: "Inhaltsmetadaten bearbeiten",
        content_title: "Inhalt",
        metadataToChange: "Zu ändernde Metadaten",
        selectContent: "Für Inhalte",
        select_action: "Aktion auswählen",
        support: {
          support: "Support",
          licenses: "Lizenzen",
        },
        versions: {
          version: "Version",
          prerequisities: "Voraussetzungen und Anforderungen",
        },
        content: {
          select_collection: "Sammlung auswählen",
          show_collection: "Anzeigen:",
          show_all_content: "Alle Sammlungen",
          show_organization_content: "Sammlungen meiner Organisation",
          select_all: "Alle auswählen",
          select_all_info: "(Dies könnte etwas dauern.)",
          actionOptions: {
            add: "Hinzufügen",
            remove: "Entfernen",
            replace: "Ersetzen durch",
            yes: "Ja",
            no: "Nein",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Keine Attribute ausgewählt",
            invalid_attributes: "Ungültige Attributwerte",
            invalid_actions: "Ungültige Aktionen",
            content_not_selected: "Keine Inhalte ausgewählt",
            entities_not_selected: "Paketbezogene Daten bereitgestellt, aber keine Pakete ausgewählt",
            versions_not_selected: "Versionsbezogene Daten bereitgestellt, aber keine Versionen ausgewählt",
          },
        },
        update: {
          info_processing: "Lassen Sie dieses Fenster geöffnet, bis alle Elemente verarbeitet wurden.",
          info_processed: "Es wurden noch nicht alle Elemente verarbeitet.",
          processing: "Element wird verarbeitet",
          error_info: "Beim Aktualisieren der folgenden Elemente ist ein Fehler aufgetreten:",
          package_failure_func: function (package_title, errorMsg) {
            return "Inhalt: " + package_title + ", Fehler: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Inhalt: " + package_title + ", Version: " + version_title + ", Fehler: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " Elemente werden aktualisiert, möchten Sie fortfahren?";
          },
        },
      },
      myUserGroups: {
        title: "Benutzergruppen",
        createdByPrefix: "Durch:",
      },
      downloads: {
        title: "Downloads",
      },
      myCollections: {
        new_collection: "Neue Sammlung",
        my_collections: "Meine Sammlungen",
        created_by_me: "Von mir erstellt",
        local_collections: "Lokale und Netzwerksammlungen",
        online_collections: "Online-Sammlungen",
        created_by_my_organization: "Von meiner Organisation erstellt",
        created_by_my_organizations: "Von meinen Organisationen erstellt",
        shared_with_me: "Mit mir geteilt",
        shared_with_me_or_organization: "Mit mir oder meiner Organisation geteilt",
        notification: {
          error: "Fehler",
          error_loading_online_collections: "Fehler beim Laden der Online-Sammlungen",
          error_loading_packages_liked_by_me: "Fehler beim Laden von Inhalten, die mir gefallen",
          error_loading_my_organization_s_collections: "Fehler beim Laden der Sammlungen meiner Organisation",
          error_loading_my_shared_collections: "Fehler beim Laden meiner freigegebenen Sammlungen",
        },
        local: {
          helpText1: "Um auf lokale Sammlungen zugreifen zu können, installieren Sie das ",
          helpText2: "Tekla Warehouse Service-Plug-in",
          helpText3: ".",
          title: "Lokale und Netzwerksammlungen",
          notFound: "Es wurden noch keine lokalen Sammlungen gefunden.",
          myCollectionsTitle: "Meine Sammlungen",
          actions: {
            createNew: "Lokale oder Netzwerksammlung erstellen",
          },
        },
      },
      organization: {
        news_feed: "Nachrichten-Feed",
        add_comment: "Feed-Text hinzufügen",
        edit_comment: "Nachrichtenbeitrag bearbeiten",
        subTabs: {
          collections: "Sammlungen",
          analytics: "Analytik",
          contact_and_support: "Kontakt",
          news_feed: "Nachrichten-Feed",
        },
        edit: {
          edit_title: "Titel bearbeiten",
          edit_description: "Beschreibung bearbeiten",
          edit_contact: "Kontaktdaten bearbeiten",
          edit_logo: "Logo bearbeiten",
          edit_url: "URL bearbeiten",
          edit_email: "E-Mail-Adresse bearbeiten",
          edit_phonenumber: "Telefonnummer bearbeiten",
          edit_warehouse_url: "Warehouse-URL bearbeiten",
          edit_warehouse_url_subtitle: "Buchstaben, keine skandinavischen Zeichen, Ziffern oder Sonderzeichen",
          edit_url_subtitle: "Vollständige URL einfügen",
          edit_logo_descriptive: "Optimale Größe etwa 350 × 200 Pixel",
          edit_facebook_url: "Facebook-URL bearbeiten",
          edit_twitter_url: "Twitter-URL bearbeiten",
          edit_linkedin_url: "LinkedIn-URL bearbeiten",
          edit_youtube_url: "Youtube-URL bearbeiten",
          edit_googleplus_url: "Google+-URL bearbeiten",
          logo_updated: "Logo aktualisiert",
          logo_update_failed: "Logo-Aktualisierung fehlgeschlagen",
          logo_remove_failed: "Logo-Löschung fehlgeschlagen",
          logo_removed: "Logo erfolgreich entfernt",
          updated_successfully: "Aktualisierung erfolgreich",
          updated_failed: "Aktualisierung fehlgeschlagen",
          add_comment: "Hinzufügen",
          edit_comment: "Nachrichtenbeitrag bearbeiten",
          comment_added: "Nachrichten-Feed-Element erfolgreich hinzugefügt",
          comment_updated: "Nachrichten-Feed-Element erfolgreich aktualisiert",
          comment_deleted: "Nachrichten-Feed-Element erfolgreich gelöscht",
          comment_add_failed: "Fehler beim Hinzufügen eines Nachrichten-Feeds",
          comment_update_failed: "Aktualisierung des Nachrichten-Feed-Elements fehlgeschlagen",
          comment_delete_failed: "Fehler beim Löschen des Nachrichten-Feed-Elements",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Online-Inhalte durchsuchen nach " + name + " &rsaquo;";
        },
        contact_information: "Kontakt",
        website: "Website &rsaquo;",
        show_newer_news: "Neuer",
        show_older_news: "Älter",
      },
      online_collections: {
        new_collection: "Neue Sammlung",
        online_collections: "Online-Sammlungen",
        created_by_organizations: "Erstellt von Organisationen",
        created_by_users: "Erstellt von Benutzern",
      },
      local_collections: {
        tab_title: "Inhalt",
        local_collections: "Lokale Sammlungen",
      },
      versions: {
        add_tool: "Anwendung hinzufügen",
        table_header: {
          name: "Name",
          type: "Typ",
          platform: "Plattform",
          modified_at: "Geändert am",
          size: "Größe",
          virus_scan: "Virenscanner",
        },
        // placeholders
        select_file_type: "Dateityp auswählen",
        select_platform: "Plattform auswählen",
        select_unit: "Einheit auswählen",
        select_quality: "Qualität auswählen",
        what_will_be_added: "Was möchten Sie hinzufügen?",
        select_files_from_your_disk: "Dateien auf Ihrer Festplatte auswählen",
        add: "Hinzufügen",
        //content management
        add_application: "Anwendung hinzufügen",
        select_content_from_tekla_structures: "Inhalte aus Tekla Structures auswählen",
        or: "oder",
        files: "Dateien",
        //version metadata
        version_information: "Versionsinformation",
        version_display_name: "Versionsanzeigename",
        version_number: "Versionsnummer",
        version_number_info: "Geben Sie eine Versionsnummer ein. Sie können die Versionsnummer nach dem Speichern nicht mehr ändern.",
        existing_versions: "Vorhandene Versionsnummern für diesen Inhalt",
        version_number_validation: {
          missing: "Sie müssen eine Versionsnummer eingeben",
          invalid: "Die Versionsnummer muss den Regeln der semantischen Versionierung (GRÖSSER.KLEINER.PATCH) folgen",
          already_in_use: "Diese Versionsnummer wird bereits für diesen Inhalt verwendet.",
        },
        select_update_type: "Um eine Versionsnummer zu generieren, wählen Sie zunächst den Aktualisierungstyp aus",
        please_enter_a_version_name_or_number: "Geben Sie einen Versionsnamen oder eine -nummer ein.",
        virus_scan: {
          soft_failed: "Fehlgeschlagen",
          hard_failed: "Nicht überprüft",
          infected: "Infiziert",
        },
      },
      collections: {
        by: "Durch:",
        edit: {
          edit_collection: "Sammlung bearbeiten",
          edit_description: "Beschreibung bearbeiten",
        },
        notification: {
          collection_updated: "Sammlung aktualisiert",
          error: "Fehler",
          error_loading_other_users_collections: "Fehler beim Laden der Sammlungen anderer Benutzer",
          error_loading_other_organizations_collections: "Fehler beim Laden der Sammlungen anderer Organisationen",
          error_loading_collection_contents: "Fehler beim Laden von Sammlungsinhalten",
          members_list_updated: "Teilnehmerliste aktualisiert!",
          invitations_removed: "Einladungen entfernt!",
          collection_removed: "Sammlung entfernt",
          thumbnail_deleted: "Miniaturansicht gelöscht",
          wrong_file_type: "Falscher Dateityp.",
          invalid_file_type: "Ungültiger Dateityp.",
          selected_image_is_too_big: "Das ausgewählte Bild ist zu groß.",
          thumbnail_updated: "Miniaturansicht aktualisiert",
          default_thumbnail_updated: "Standardminiaturansicht aktualisiert",
          default_thumbnail_update_failed: "Aktualisierung der Standardminiaturansicht fehlgeschlagen",
          thumbnail_added: "Miniaturansicht hinzugefügt",
          thumbnail_update_failed: "Aktualisierung der Miniaturansicht fehlgeschlagen",
          video_added: "Video hinzugefügt",
          video_adding_failed: "Hinzufügen des Videos fehlgeschlagen",
          video_deleted: "Video gelöscht",
          video_deleting_failed: "Löschen des Videos fehlgeschlagen",
        },
        confirm: {
          delete_all: function (title) {
            return 'Löschen der Sammlung und aller Inhalte in der Sammlung "' + title + '"?';
          },
          remove_members: "Sind Sie sicher?",
          remove_invitations: "Sind Sie sicher?",
        },
        useNetworkDrive: "Netzwerklaufwerk oder Ordner verwenden",
        createCollection: "Online-Sammlung erstellen",
        createCollectionInstructions: "Erstellen Sie eine Sammlung, um Ihre Inhalte zu gruppieren.",

        actions: {
          delete_thumbnail: "Miniaturansicht löschen",
          edit_collection: "Sammlung bearbeiten",
          delete_collection: "Sammlung löschen",
          add_content: "Inhalte hinzufügen",
        },
        subTabs: {
          content: "Inhalt",
          members: "Mitglieder zeigen",
          analytics: "Analytik",
          manage_access: "Zugriff verwalten",
        },

        members: {
          add_user_groups: "Benutzergruppen hinzufügen",
          select_user_group: "Benutzergruppe auswählen",
          with_role: "mit Rolle",
          user_groups_added: "Benutzergruppen hinzugefügt!",
          members_list_updated: "Teilnehmerliste aktualisiert!",
          update_selected_members: "Ausgewählte Mitglieder aktualisieren",
          table_header: {
            member_type: "Mitgliedstyp",
            member_name: "Mitgliedsname",
            email: "E-Mail",
            role: "Rolle",
            joined_at: "Beigetreten am",
            invited_at: "Eingeladen am",
          },
          new_role: "Neue Rolle",
        },
        member_list: {
          users_who_can_access_this_collection: "Benutzer, die Zugriff auf diese Sammlung haben",
          add_user_group: "Benutzergruppe hinzufügen",
          update_members: "Mitglieder aktualisieren",
          remove_members: "Mitglieder entfernen",
          pending_invitations: "Offene Einladungen",
          user: "Benutzer",
          invite_member: "Mitglied einladen",
          update_invitations: "Einladungen aktualisieren",
          remove_invitations: "Einladungen entfernen",
        },
        labels: {
          select_organization: "Organisation auswählen",
          select_visibility: "Sichtbarkeit auswählen",
          title: "Name",
          name: "Name",
          createAs: "Erstellen als: ",
          asMyself: "Ich selbst",
          asUserGroup: "Benutzergruppe",
          asOrganization: "Organisation",
          selectOwner: "Organisation für Inhaltsbesitzer auswählen",
          visibility: "Sichtbarkeit",
          visibilityOptions: {
            public: "Öffentlich",
            private: "Privat",
          },
          description: "Beschreibung",
        },
        new_collection: {
          please_input_a_username: "Geben Sie einen Benutzernamen ein",
          title_is_required_to_be_at_least_3_characters: "Der Titel muss aus mindestens 3 Zeichen bestehen",
          title_cannot_be_longer_than_20_characters: "Der Titel darf maximal 20 Zeichen enthalten",
        },
        collection_list: {
          no_content: "Keine Sammlungen.",
          results_summary: function (number) {
            return "" + number + "  Sammlung " + pluralize(number, ["Elemente", "Element", "Elemente"]);
          },
        },
        entity_list: {
          no_content: "Keine Inhalte in dieser Sammlung.",
          results_summary: function (number) {
            return "" + number + " Inhalt " + pluralize(number, ["Elemente", "Element", "Elemente"]);
          },
          linked_content: "Verknüpfter Inhalt",
          unlink: "Inhaltsverknüpfung aufheben",
        },
      },
      alerts: {
        no_updates: "Länger nicht aktualisiert",
        notifications_marked_as_read: "Benachrichtigung markiert als gelesen",
        cleared_alerts: "Alle Benachrichtigungen gelöscht",
        alert_by_email:
          "Senden Sie mir eine E-Mail, wenn der unten aufgeführte Inhalt aktualisiert oder eine Sammlung mit neuen Inhalten aktualisiert wird",
        action_types: {
          create: "wurde erstellt",
          update: "wurde bearbeitet",
          delete: "wurde gelöscht",
          added_entity: "Version wurde hinzugefügt",
          removed_entity: "Version wurde entfernt",
          added_collection: "Inhalt wurde hinzugefügt",
          removed_collection: "Inhalt wurde entfernt",
        },
        property_names: {
          description: "Details",
          searchCriteria: "Informationen zu Suchkriterien",
          groupCategory: "Gruppen und Kategorieinformationen",
          support: "Supportinformationen",
          licenses: "Lizenzinformationen",
          relatedContent: "Informationen zu verwandten Inhalten",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Aktualisiert " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Benachrichtigungseinstellungen erfolgreich aktualisiert",
          preferences_update_failed: "Fehler beim Aktualisieren der Benachrichtigungseinstellungen",
        },
      },
      browseDialog: {
        useNetworkDrive: "Netzwerklaufwerk oder Ordner verwenden",
        ownerGroups: {
          title: "Besitzergruppen",
        },
      },
      itemTypeCategories: {
        all: "Alle Gruppen",
        application: "Anwendungen",
        "3dProduct": "3D-Produkte",
        customComponent: "Benutzerdefinierte Komponenten",
        tool: "Anwendungen",
        profile: "Profile",
        material: "Materialien",
        bolt: "Schrauben",
        rebar: "Bewehrungen",
        reportTemplate: "Listenvorlagen",
        drawingSetup: "Zeichnungs-Setup-Dateien",
        modelSetup: "Modell-Setup-Dateien",
        environmentFile: "Umgebungsdateien",
        shape: "Formen",
        //'shapeCatalog': 'Formkatalog',
        //'foreignGeometryDefinition': 'Externe Geometriedefinition'
      },
      platforms: {
        WIN_X86: "32-Bit",
        WIN_X64: "64-Bit",
        ANY: "plattformunabhängig",
      },
      platformsDownload: {
        WIN_X86: "32-Bit",
        WIN_X64: "64-Bit",
        ANY: " ",
      },
      contentItemSource: {
        other: "Allgemein - zum Modellordner",
        run: "Start",
        bolt: "Schraubengarnitur",
        component: "Komponente",
        drawing: "Zeichnungseinstellung (BETA)",
        material: "Material",
        profile: "Profil",
        mesh: "Bewehrungsmatte",
        rebar: "Bewehrungsstab",
        shape: "Form",
        geometry: "Geometrie",
        modeltemplate: "Modellvorlage",
        cloningtemplate: "Klonvorlage",
        noaction: "Keine Aktion",
        releasenotes: "Versionshinweise",
      },
      contentItemHeader: {
        bolt: "Schraubengarnituren",
        component: "Komponenten",
        drawing: "Zeichnungseinstellungen (BETA)",
        material: "Materialien",
        profile: "Profile",
        mesh: "Bewehrungsmatten",
        rebar: "Bewehrungsstäbe",
        shape: "Formen",
      },
      useCategoryGroups: {
        workflow: "Arbeitsablauf",
        structures: "Strukturen",
        drawings: "Zeichnungen",
        modeling: "Modellierung",
      },
      useCategories: {
        title: "Verwendet in/für",
        conceptualDesign: "Konzeptioneller Entwurf",
        designEngineeringAnalysis: "Entwurf/Engineering/Analyse",
        detailing: "Detaillierung",
        fabrication: "Herstellung",
        erectionInstallationPour: "Errichtung/Installation/Betonarbeiten",
        contracting: "Vertragsabschluss",
        scheduling: "Planung",
        takeOffEstimation: "Ermittlung/Schätzung",
        concreteStructures: "Ortbeton",
        precastConcrete: "Fertigteilbeton",
        offshore: "Offshore",
        steelStructures: "Stahlstrukturen",
        timberStructures: "Holzstrukturen",
        reinforcement: "Bewehrung",
        drawingCreation: "Zeichnungserstellung",
        drawingManagement: "Verwaltung von Zeichnungen",
        drawingEditing: "Zeichnungseditor",
        drawingAnnotation: "Zeichnungsanmerkung",
        viewSimulateNavigate: "Anzeigen, simulieren, navigieren",
        compareReviewManageData: "Vergleichen, prüfen, Daten verwalten",
        softwareCollaboration: "Softwarezusammenarbeit",
        productivity: "Produktivität",
        interoperability: "Interoperabilität",
        systemSetup: "System-Setup",
      },
      measurementUnits: {
        "0": "Metrisch",
        "1": "Imperial",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Open BIM (IFC)",
        standalone: "Eigenständig",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Nicht versionsspezifisch",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "Experimentell",
        candidate: "Kandidat",
        approved: "Genehmigt",
        standard: "Standard",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Englisch",
        "zh-Hans": "Chinesisch - Vereinfacht",
        "zh-Hant": "Chinese - Traditionell",
        cs: "Tschechisch",
        da: "Dänisch",
        nl: "Holländisch",
        fi: "Finnisch",
        fr: "Französisch",
        de: "Deutsch",
        hu: "Ungarisch",
        it: "Italienisch",
        ja: "Japanisch",
        ko: "Koreanisch",
        pl: "Polnisch",
        pt: "Portugiesisch",
        "pt-br": "Portugiesisch - Brasilien",
        ru: "Russisch",
        es: "Spanisch",
        sv: "Schwedisch",
        no: "Norwegisch",
        other: "Andere",
      },
      isoLangs: {
        ab: {
          name: "Abchasisch",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afarisch",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikaans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akansprache",
          nativeName: "Akan",
        },
        sq: {
          name: "Albanisch",
          nativeName: "Shqip",
        },
        am: {
          name: "Amharisch",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Arabisch",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonesisch",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Armenisch",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Assamesisch",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Awarisch",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avestisch",
          nativeName: "avesta",
        },
        ay: {
          name: "Aymara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Aserbaidschanisch",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Baschkirisch",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Baskisch",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Weißrussisch",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengalisch",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bosnisch",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Bretonisch",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Bulgarisch",
          nativeName: "български език",
        },
        my: {
          name: "Burmesisch",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Katalanisch; Valencianisch",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Tschetschenisch",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa; Chewa; Nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chinesisch, vereinfacht und Chinesisch",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Chinesisch - Vereinfacht",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Chinese - Traditionell",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Chinesisch - Taiwanesisch",
          nativeName: "國語",
        },
        cv: {
          name: "Tschuwaschisch",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Kornisch",
          nativeName: "Kernewek",
        },
        co: {
          name: "Korsisch",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Kroatisch",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Tschechisch",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Dänisch",
          nativeName: "dansk",
        },
        dv: {
          name: "Maledivisch",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Holländisch",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Englisch",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Estnisch",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Faröisch",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fidschianisch",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finnisch",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Französisch",
          nativeName: "Français",
        },
        ff: {
          name: "Fula; Fulah; Pulaar; Pular",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Galizisch",
          nativeName: "Galego",
        },
        ka: {
          name: "Georgisch",
          nativeName: "ქართული",
        },
        de: {
          name: "Deutsch",
          nativeName: "Deutsch",
        },
        el: {
          name: "Griechisch (modern)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gujarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haitianisch; Haitianisches Kreolisch",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Hebräisch (modern)",
          nativeName: "עברית",
        },
        hz: {
          name: "Herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Ungarisch",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonesisch",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingue",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Irisch",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Isländisch",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italienisch",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japanisch",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Javanesisch",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, Grönländisch",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Kashmiri",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Kasachisch",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu, Gikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Kinyarwanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Kirgisisch",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kikongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Koreanisch",
          nativeName: "한국어",
        },
        ku: {
          name: "Kurdisch",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kwanyama, Kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latein",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxemburgisch, Letzeburgesch",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburgisch",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Laotisch",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Litauisch",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-Katanga",
          nativeName: "",
        },
        lv: {
          name: "Lettisch",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Mazedonisch",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Madagassisch",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Malaiisch",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malayalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltesisch",
          nativeName: "Malti",
        },
        mi: {
          name: "Māori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Marathi (Marāṭhī)",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshallesisch",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongolisch",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo, Navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Norwegisch Bokmål",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Nord-Ndebele",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepali; Nepalesisch",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Norwegisch Nynorsk",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Norwegisch",
          nativeName: "Norsk",
        },
        ii: {
          name: "Nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Süd-Ndebele",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Okzitanisch",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwe, Ojibwa",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Altkirchenslawisch, Kirchenslawisch, Altbulgarisch, Altslawisch",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Ossetisch",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Panjabi, Punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pāli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Persisch",
          nativeName: "فارسی",
        },
        pl: {
          name: "Polnisch",
          nativeName: "polski",
        },
        ps: {
          name: "Puschtu",
          nativeName: "پښتو",
        },
        pt: {
          name: "Portugiesisch",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Portugiesisch - Brasilien",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quechua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Rätoromanisch",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Rumänisch, Moldawisch, Moldauisch",
          nativeName: "română",
        },
        ru: {
          name: "Russisch",
          nativeName: "русский",
        },
        sa: {
          name: "Sanskrit (Saṁskṛta)",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sardinisch",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Nord-Sami",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoanisch",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Serbisch",
          nativeName: "српски језик",
        },
        gd: {
          name: "Schottisch-Gälisch; Gälisch",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Sinhalesisch",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Slowakisch",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Slowenisch",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somalisch",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Süd-Sotho",
          nativeName: "Sesotho",
        },
        es: {
          name: "Spanisch", //      'name':'Traditionell/Spanisch',
          nativeName: "Español",
        },
        su: {
          name: "Sudanesisch",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Suaheli",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Swati",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Schwedisch",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tadschikisch",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Thailändisch",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrinya",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibetisch Standard, Tibetisch, Zentral",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turkmenisch",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalog",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tonga (Tonga-Inseln)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Türkisch",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tatarisch",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Tahitisch",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Uigurisch",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ukrainisch",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Usbekisch",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamesisch",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Wallonisch",
          nativeName: "Walon",
        },
        cy: {
          name: "Walisisch",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Westfriesisch",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Jiddisch",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Yoruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Zhuang, Chuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Andere",
          nativeName: "Andere",
        },
      },

      details: {
        navigation: {
          content_information: "Inhaltsinformationen",
          version_and_file_management: "Versions- und Dateiverwaltung",
          details: "Details",
          versions: "Versionen",
          analytics: "Analytik",
          statistics: "Statistik",
          manage_access: "Zugriff verwalten",
          related_content: "Zugehörig",
          properties: "Eigenschaften",
          support: "Unternehmen und Support",
          release_notes: "Versionshinweise",
          comments: "Benutzerkommentare",
        },
        related_content: {
          other_content_title: "Andere Inhalte, die Ihnen möglicherweise gefallen",
          related_content_title: "Inhalte empfohlen von ",
          related_content_url: "Inhalts-URL",
          add_related_content: "Verwandte Inhalte hinzufügen",
          add_related_content_info:
            "Sie können nur verwandte Onlineinhalte zu Onlineinhalten und lokale Inhalte zu lokalen Inhalten hinzufügen",
          remove_related_content: "Entfernen",
          relatedContentUrlSubtitle: "Inhalts-ID oder vollständige URL zum Inhalt",
          updated_successfully: "Verwandter Inhalt erfolgreich aktualisiert",
          update_failed: "Fehler beim Aktualisieren des verwandten Inhalts",
          update_failed_invalid_id: "Bereitgestellte Inhalte-ID ist ungültig",
        },
        actions: {
          move: "Verschieben",
          delete: "Löschen",
          add_comment: "Kommentar hinzufügen",
          add_reply: "Antwort hinzufügen",
          edit_comment: "Kommentar bearbeiten",
          reply_comment: "Antwort hinzufügen",
          view_replies: "Antworten anzeigen",
          report_bad_comment: "Kommentar melden &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Titel bearbeiten oder löschen",
            title_or_move_delete: "Titel bearbeiten, verschieben oder löschen",
            thumbnail: "Miniaturansicht bearbeiten",
            description: "Beschreibung bearbeiten",
            groupandcategory: "Gruppe und Kategorie bearbeiten",
            details: "Details bearbeiten",
            versions: "Versionen bearbeiten",
            support: "Ergänzende Angaben bearbeiten",
            release_notes: "Versionshinweise bearbeiten",
            search_criteria: "Suchkriterien bearbeiten",
            batchedit_and_translations:
              "Mit der Übersetzungsfunktion können Sie Ihre Inhalte in mehreren Sprachen veröffentlichen. Die übersetzte Version des Inhalts wird Benutzern aufgrund der Sprachauswahl in der Warehouse-Oberfläche angezeigt. Mit der Stapelbearbeitung können Sie mehrere Objekte gleichzeitig bearbeiten. Wählen Sie zunächst ein Feld und dann die zu bearbeitenden Elemente aus",
          },
        },
        links: {
          link: "Verknüpfung",
          select_collection_placeholder: "Zu verknüpfende Sammlung auswählen",
          linking_collections_label: "Sammlungen, die hierauf verweisen",
          private_content_note: "Anmerkung: Private Inhalte sind nur für Benutzer sichtbar, die Zugriff darauf haben.",
          share_on_social_media: "Freigeben auf:",
          report_bad_content: "Inhalt melden &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Illegale Inhalte melden &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Wenn Sie Benachrichtigungen abonnieren, stimmen Sie zu, dass dem Besitzer des Inhalts Ihr Tekla Account-Anzeigename und der Organisationsname (sofern zutreffend) mitgeteilt werden.",
          alert_me: "Benachrichtigen",
          unsubscribe: "Abbestellen",
          subscribed_successfully: "Erfolgreich abonniert",
          unsubscribed_successfully: "Erfolgreich abbestellt",
          subscribers_label: "Abonnenten",
        },
        reviews: {
          reviewers_label: "Rezensenten",
        },
        comments: {
          replies: "Antworten",
        },
        scan: {
          soft_failed_binaries_info: "Virenscan fehlgeschlagen  ",
          hard_failed_binaries_info: "Virenscan war nicht möglich ",
          infected_binaries_info: "Virenscanner hat eine Infektion gefunden ",
          what_now: "Was nun?",
          popup: {
            soft_failed_binaries_title: "Fehler in Binärdateien",
            hard_failed_binaries_title: "Fehler in Binärdateien",
            infected_binaries_title: "Infizierte Binärdateien",
            soft_failed_binaries_info:
              "Der Virenscan der hochgeladenen Dateien ist fehlgeschlagen. Dies ist ein vorübergehendes Problem. Entfernen Sie die Dateien, und laden Sie sie erneut hoch. Alle Dateien müssen den Virenscan bestehen, bevor sie für andere Benutzer sichtbar werden.",
            hard_failed_binaries_info:
              "Virenscan war nicht möglich. Entfernen Sie die Dateien. Alle Dateien müssen den Virenscan bestehen, bevor sie für andere Benutzer sichtbar werden.",
            infected_binaries_info:
              "Der Virenscanner hat eine Infektion in den hochgeladenen Dateien gefunden. Entfernen Sie die Dateien. Alle Dateien müssen den Virenscan bestehen, bevor sie für andere Benutzer sichtbar werden.",
            version: "Version: ",
            fileName: "Dateiname: ",
          },
        },
        status: {
          content_banned_info:
            "Der Inhalt wird vom Administrator blockiert. Er ist derzeit nur für den Inhaltsbesitzer verfügbar. <a href='https://support.tekla.com/contact-us' target='_blank'>Wenden Sie sich an den Support</a> für weitere Informationen.",
          content_archived_info:
            "Dieser Inhalt wurde archiviert. Anwender ohne Bearbeitungsrechte werden auf die Startseite weitergeleitet, wenn sie versuchen, den Inhalt über einen direkten Link aufzurufen.",
          archived: "Archiviert",
        },
        modified_by: function (name) {
          return " von " + name;
        },
        created_by: function (name) {
          return "Erstellt von " + name;
        },
        deleteEntity: function (title) {
          return "Löschen " + title + "?";
        },
        userComments: "Benutzerkommentare",
        comment_added: "Kommentar erfolgreich hinzugefügt",
        comment_updated: "Kommentar erfolgreich aktualisiert",
        comment_deleted: "Kommentar erfolgreich gelöscht",
        comment_add_failed: "Hinzufügen des Kommentars fehlgeschlagen",
        comment_update_failed: "Aktualisieren des Kommentars fehlgeschlagen",
        comment_delete_failed: "Löschen des Kommentars fehlgeschlagen",
        comment_delete_confirmation: "Kommentareintrag löschen?",
        name: "Name",
        details: "Details",
        contentOwner: "Erstellt von",
        contentOwnerGroup: "Inhaltebesitzergruppe",
        contentGroup: "Inhaltegruppe",
        thumbnail: "Miniaturansicht",
        tooltip: "Tooltip",
        description: "Beschreibung",
        additional_information: "Zusätzliche Informationen",
        images: "Produktbilder",
        installationInfo: "Installationsinformationen",
        productCode: "Produktcode",
        copyrightInfo: "Copyright",
        productExpirationDate: "Produktablaufdatum",
        helpUrl: "Website &rsaquo;",
        supportUrl: "Support &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "Versionshinweise",
        supportForumUrl: "Discussionsforum &rsaquo;",
        itemTypeCategories: "Gruppe",
        useCategories: "Kategorie",
        category: "Kategorie:",
        group: "Gruppe:",
        compatibleSoftwareProducts: "Kompatible Softwareprodukte",
        compatibleOperatingSystems: "Kompatible Betriebssysteme",
        locationRestrictions: "Produktverfügbarkeit:",
        supportedLanguages: "Sprachen:",
        measurementUnits: "Maßeinheiten:",
        tags: "Bezeichnungen und Schlagwörter:",
        prerequisites: "Voraussetzungen:",
        releaseNotes: "Versionshinweise:",
        versions: "Versionen",
        testedVersions: "Kompatible Versionen",
        qualityTag: "Qualität - bewertet durch den Besitzer",
        otherAttributes: "Andere Attribute",
        labels: "Suchkriterien",
        supportInformation: "Support",
        updateInformation: "Aktualisierungen",
        latestUpdate: "Letzte Aktualisierung",
        totalDownloads: "Anzahl der Downloads",
        licenses: "Lizenzen",
        maintenanceRequired: "Für den Download ist ein Tekla-Wartungsvertrag erforderlich",
        tekla_maintenance_content: "Tekla-Wartungsinhalte",
        download_requires_tekla_maintenance: "Für den Download ist ein Tekla-Wartungsvertrag erforderlich",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Aktualisierung erfolgreich",
        update_failed: "Aktualisierung fehlgeschlagen",
        update_failed_invalid_tag: "Aktualisierung fehlgeschlagen: ungültige Bezeichnung",
        update_failed_details_max_length_exceeded: "Aktualisierung fehlgeschlagen: Details zu lang",
        show_version_details: "Weitere Informationen",
        content_removed: "Inhalt entfernt",
        no_versions_available:
          "Keine Versionen verfügbar. Es kann ein wenig dauern, bis die Aktualisierungen angezeigt werden. Aktualisieren Sie Ihren Browser, um Änderungen anzuzeigen.",
        thumbnail_deleted: "Miniaturansicht gelöscht",
        thumbnail_updated: "Miniaturansicht aktualisiert",
        verify_service_plugin_is_running:
          "Stellen Sie sicher, dass das Tekla Warehouse Service-Plug-in ausgeführt wird",
        verify_ts_connection: "Stellen Sie sicher, dass Tekla Structures ausgeführt wird",
        download_tos: {
          title: "Qualität, Support und Datenschutz",
          content_1: "Stellen Sie sicher, dass heruntergeladene Inhalte Ihren Qualitätsstandards entsprechen. Trimble übernimmt weder Support noch Haftung für Inhalte, die von Dritten bereitgestellt werden. Falls Sie Unterstützung benötigen, wenden Sie sich direkt an den Anbieter der Inhalte.",
          content_2: "Ihr Land und Ihre Organisation können im Dienst veröffentlicht werden und sind dadurch für Besitzer der Inhalte sichtbar. Grund dafür sind gerechtfertigte Zwecke; beispielsweise können Sie so über Änderungen an Inhalten informiert werden. Sie können diese Benachrichtigungen abbestellen.",
          content_3: 'Für Trimble-Inhaltserweiterungen gelten die <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimble-Bedingungen</a>. Für Inhalte von Drittanbietern können die Lizenzbedingungen von Drittanbietern gelten. Siehe die <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimble-Datenschutzerklärung</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Dies ist der kostenlose BIM-Speicher für Tekla Structures. Sie können Inhalte suchen, importieren, installieren und intern sowie global freigeben. Erstellen Sie hochwertige Modelle auf effiziente Art und Weise.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse ist eine kostenlose lokale und Online-Bibliothek für Tekla Structures-Modellierungswerkzeuge, intelligente Teile und Vorlagen",
        },
        search: {
          title: "Tekla Warehouse durchsuchen",
          description:
            "Suchen Sie nach Tekla-Erweiterungen, 3D-Teilen, Formen, benutzerdefinierten Komponenten, Profilen, Materialien wie Stahl- und Betongüten, Bewehrungen, Matten und Vorlagen",
        },
        about: {
          title: "Informationen zu Tekla Warehouse",
          description:
            "Tekla Warehouse ist ein kostenloser Marketingkanal für Ihre Produkte und gibt Ihnen die volle Kontrolle über Ihre Inhalte",
          developers: {
            title: "Standardtitel Entwickler",
            description: "Standardbeschreibung Entwickler",
          },
          usage: {
            title: "Standardtitel Verwendung",
            description: "Standardbeschreibung Verwendung",
          },
          getting_started: {
            title: "Standardtitel Erste Schritte",
            description: "Standardbeschreibung Erste Schritte",
          },
          content: {
            title: "Standardtitel Inhalt",
            description: "Standardbeschreibung Inhalt",
          },
          using_content: {
            title: "Standardtitel Verwenden von Inhalten",
            description: "Standardbeschreibung Verwenden von Inhalten",
          },
          creating_content: {
            title: "Standardtitel Erstellen von Inhalten",
            description: "Standardbeschreibung Erstellen von Inhalten",
          },
        },
        collection: {
          title: "Standardtitel Sammlung",
          description: "Standardbeschreibung Sammlung",
        },
        my_warehouse: {
          title: "Mein Tekla Warehouse",
          description: "Ihre Inhalte in Tekla Warehouse verwalten",
        },
      },
      upload: {
        actions: {
          cancel: "Abbrechen",
          previous: "&lsaquo; Zurück",
          next: "Weiter &rsaquo;",
        },
        content: {
          add_content: "Dateien hinzufügen",
        },
        collection: {
          heading: "Sammlung auswählen oder erstellen",
          subHeading:
            "Sammlungsgruppeninhalte. Sie können Online-Sammlungen zur Veröffentlichung Ihrer Inhalte verwenden oder lokale Sammlungen zum Teilen der Inhalte mit Ihren Kollegen. Sie können lokale Sammlungen auch für die private Nutzung verwenden.",
          loading: "Sammlungen werden geladen...",
          selectFromMyCollections: "Aus meinen Sammlungen auswählen",
          description_of_the_collection: "Beschreibung",
          title_validation: {
            missing: "Geben Sie einen Titel ein.",
            too_short: function (length) {
              return "Der Titel muss mindestens " + length + " Zeichen enthalten.";
            },
            too_long: function (length) {
              return "Der Titel darf nicht mehr als " + length + " Zeichen enthalten.";
            },
          },
        },
        package: {
          basic_information: "Grundlegende Informationen",
          code_name: "Codename",
          code_name_info:
            "Geben Sie einen Codenamen als eindeutigen Bezeichner für diesen Inhalt ein. Der Codename kann aus Kleinbuchstaben, Zahlen und den folgenden Sonderzeichen bestehen: _ - @. Sie können den Codenamen nach dem Speichern nicht mehr ändern.",
          code_name_validation: {
            missing: "Sie müssen einen Codenamen eingeben",
            wrong_format: "Der Codename kann aus Kleinbuchstaben, Zahlen und den folgenden Sonderzeichen bestehen: _ - @",
            not_unique: "Dieser Codename wird bereits verwendet. Sie müssen einen eindeutigen Codenamen eingeben.",
          },
          search_criteria: "Suchkriterien",
          support: "Support",
          title: "Titel",
          thumbnail: "Miniaturansicht",
          "3D_pic": "3D-Modell",
          title_validation: {
            missing: "Geben Sie einen Titel ein.",
            too_short: function (length) {
              return "Der Titel muss mindestens " + length + " Zeichen enthalten.";
            },
          },
          description_validation: {
            missing: "Geben Sie eine Beschreibung ein.",
            too_short: function (length) {
              return "Die Beschreibung sollte mindestens " + length + " Zeichen enthalten.";
            },
          },
          wrong_file_type: "Falscher Dateityp.",
          selected_image_is_too_big: "Das ausgewählte Bild ist zu groß.",
        },
        version: {
          select_version: "Version auswählen",
          select_quality: "Qualität auswählen",
          versions: "Versionen",
          displayNameNew: "Versionsanzeigename",
          copy_from_version: "Metadaten aus vorhandener Version kopieren (optional)",
          displayName: "Anzeigename",
          content_information: "Inhaltsinformationen",
          back_to_content_information: "&lsaquo; Zurück zur Inhaltsinformation",
          version_and_file_management: "Versions- und Dateiverwaltung",
          there_s_no_versions_yet: "Noch keine Versionen verfügbar.",
          create_a_version_to_add_files: "Eine Version zum Hinzufügen der Dateien erstellen.",
          add_version: "Version hinzufügen",
          version_information: "Versionsinformation",
          delete_version: "Version löschen",
          no_versions_available:
            "Keine Versionen verfügbar. Es kann ein wenig dauern, bis die Aktualisierungen angezeigt werden.",
          update_start_title_html: '<div class="spinner spinner-white">Aktualisieren</div>',
          update_start_message: "Dies könnte etwas dauern.",
          version_was_created: "Die Version wurde erstellt.",
          version_was_created_with_delay:
            "Die Version wurde erstellt. Die Änderungen sind nach wenigen Minuten sichtbar.",
          version_was_updated: "Die Version wurde aktualisiert.",
          version_was_deleted: "Die Version wurde gelöscht",
          version_was_deleted_with_delay:
            "Die Version wurde gelöscht. Die Änderungen sind nach wenigen Minuten sichtbar.",
          confirm_delete: function (title) {
            return 'Löschen von Version "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Paket wurde hochgeladen.",
          couldn_t_create_a_package: "Das Paket konnte nicht erstellt werden. Änderungen werden rückgängig gemacht.",
          uploading_title_html: '<div class="spinner spinner-white">Upload läuft</div>',
          uploading_text: "Dies könnte etwas dauern.",
          select_organization: "Organisation auswählen",
          collection_visibility: "Sichtbarkeit der Sammlung:",
          actions: {
            create: "Erstellen",
            publish: "Veröffentlichen",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Diese Inhalte sind für alle Benutzer mit Zugriff auf das lokale oder Netzwerklaufwerk sichtbar.",
          this_content_will_be_visible_only_to_you: "Diese Inhalte sind nur für Sie sichtbar.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Diese Inhalte sind nur für Sie und alle in Ihrer Organisation sichtbar.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Diese Inhalte sind nach dem Veröffentlichen für alle Benutzer sichtbar.",
          public_content_disclaimer:
            "Sie sind für von Ihnen hochgeladene Inhalte verantwortlich. Stellen Sie sicher, dass Ihre Inhalte nicht schädlich sind, dass Sie das Recht haben, die Inhalte hochzuladen, und dass diese nicht gegen Schutzrechte verstoßen. Sie stimmen zu, mögliche Bestimmungen und Bedingungen, die für von Ihnen hochgeladene Inhalte gelten, im Dienst zu veröffentlichen.",
        },
        metadata: {
          title: "Metadaten",
          subtitle: "Grundlegende Daten",
          deleteThumbnail: "Miniaturansicht löschen",
          buttonCreate: "Erstellen",
        },
        basicdata: {
          title: "Inhalte hinzufügen",
          clickButtonToSelectFile: "Klicken Sie auf die Schaltfläche, um die Datei auszuwählen",
          thumbnail: "Miniaturansicht",
          dropImageHere: "Bild hier ablegen",
        },
        "3dGeometry": {
          title: "3D-Produktkatalog erstellen",
          buttonAddEntity: "3D-Produkt hinzufügen",
          buttonAdd: "Benutzerdefinierte Attribute hinzufügen",
          errorAttributeAlreadyExists: "Es existiert bereits ein Attribut mit demselben Namen.",
          buttonCreatePackage: "Paket erstellen",
          buttonCreatePackages: "Pakete erstellen",
          addCustomAttributePlaceholder: "Attributname",
        },
        please_log_in_fragments: {
          please: "Bitte ",
          log_in: "Anmelden",
          or: " oder ",
          register: "registrieren Sie sich,",
          to_upload_online_content: " um Online-Inhalte hochzuladen.",
        },
        header: {
          upload_header: "Hochladen",
          collection: "Sammlung",
          content: "Inhalt",
          version: "Version",
          finish: "Beenden",
        },
        contentOwner: "Erstellt von",
        contentOwnerGroup: "Inhalt veröffentlichen",
        select_action: "Aktion auswählen",
        tooltip: "Tooltip",
        description: "Beschreibung",
        copyright: "Copyright",
        tags: "Bezeichnungen und Schlagwörter",
        tagsSubtitle:
          "Verwenden Sie Kommas zum Trennen, zum Beispiel: Schlagwort1, Schlagwort2. Wenn Schlagwörter aus mehreren Wörtern bestehen, verwenden Sie den Unterstrich (_) zwischen Wörtern; Leerzeichen sind nicht zulässig. Außer dem Unterstrich und dem Komma sind keine Sonderzeichen zulässig.",
        images: "Bilder",
        installationInfo: "Installationsinformationen",
        productCode: "Produktcode",
        copyrightInfo: "Copyrightinformationen",
        Details: "Details",
        specialTermsUrl: "EULA",
        productExpirationDate: "Produktablaufdatum",
        helpUrl: "Website",
        helpUrlSubtitle: "vollständige URL einfügen",
        detailsSubtitle:
          "In dieser Phase können Sie nur unformatierten Text in den Details angeben. Nach dem Erstellen des Inhalts können Sie die Details mittels HTML-Code formatieren.",
        specialTermsUrlSubtitle:
          "Inhaltsbesitzer können zusätzliche Bedingungen für ihre eigenen Inhalte hinzufügen. Besitzer müssen eine URL angeben, an der sich die spezifischen Bestimmungen befinden. Diese haben keinen Vorrang vor Trimbles Nutzungsbedingungen.",
        releaseNotes: "Datei mit Versionshinweisen",
        releaseNoteFileSubTitle: "Datei mit Versionshinweisen hinzufügen",
        supportUrl: "Hilfe und Support",
        supportUrlSubtitle: 'vollständige URL oder zum Senden von E-Mails das Präfix "mailto:" einfügen',
        supportForumUrl: "Diskussionsforum (Website)",
        supportForumUrlSubtitle: "vollständige URL einfügen",
        measurementUnits: "Maßeinheiten",
        itemTypeCategories: "Gruppe",
        useCategories: "Kategorie",
        compatibleSoftwareProducts: "Kompatible Softwareprodukte",
        testedVersions: "Kompatible Versionen",
        compatibleOperatingSystems: "Kompatibles Betriebssystem",
        locationRestrictions: "Produktverfügbarkeit",
        supportedLanguages: "Sprachen",
        select_releasenotes_file: "Datei auswählen",
        qualityTag: "Qualität - bewertet durch den Besitzer",
        requireMaintenance: "Für den Download ist ein Tekla-Wartungsvertrag erforderlich",
        contentOwnerGroupAsMyself: "Als ich selbst",
        contentOwnerGroupAsUserGroup: "Als Benutzergruppe",
        selectUserGroupForContentOwner: "Benutzergruppe für Inhaltsbesitzer auswählen",
        defaultUploadTemplate: "Standardpaket",
        templateFor3DGeometryFiles: "3D-Geometriepaket(e)",
        title: "Titel",
        details: "Details",
        selectContentOwner: "Inhaltsbesitzer auswählen",
        selectContentType: "Typ",
        errorWrongfiletype: "Falscher Dateityp.",
        errorImageTooBig: "Das ausgewählte Bild ist zu groß.",
        errorBlankTitle: "Es muss ein Titel eingefügt werden.",
        commonLabelsTitle: "Gemeinsame Beschriftungen für alle Pakete",
      },
      profile: {
        content_usage: "Inhaltsverwendung",
        profile_updated: "Profil aktualisiert",
        invitation_accepted: function (target) {
          return "Einladung angenommen! Sie haben nun Zugriff auf " + target + ".";
        },
        user_groups: {
          edit_user_group: "Benutzergruppe bearbeiten",
          user_group_updated: "Benutzergruppe aktualisiert",
          create_a_new_user_group: "Eine neue Benutzergruppe erstellen",
          name_of_the_user_group: "Bezeichnung der Benutzergruppe",
          description: "Beschreibung",
          group_visibility: "Gruppensichtbarkeit",
          remove: '"Sind Sie sicher?"',
          remove_invitations: '"Sind Sie sicher?"',
          remove_members: '"Sind Sie sicher?"',
          invitations_removed: "Einladungen entfernt!",
          members_list_updated: "Teilnehmerliste aktualisiert!",
          user_group_created: "Benutzergruppe erstellt",
        },
        members: {
          update_selected_members: "Ausgewählte Mitglieder aktualisieren",
          new_role: "Neue Rolle",
          members_list_updated: "Teilnehmerliste aktualisiert!",
          edit_user_group: "Benutzergruppe bearbeiten",
          delete_user_group: "Benutzergruppe löschen",
          filter: "Filter",
          user_group_members: "Mitglieder der Benutzergruppe",
          update_members: "Mitglieder aktualisieren",
          remove_members: "Mitglieder entfernen",
          pending_invitations: "Offene Einladungen",
          invite_member: "Mitglied einladen",
          update_invitations: "Einladungen aktualisieren",
          remove_invitations: "Einladungen entfernen",
        },
        subTabs: {
          alerts: "Meine Benachrichtigungen",
          collections: "Sammlungen",
          likes: "Gefällt-Angaben",
        },
        admin: {
          grant_sales_admin: "Benutzer Vertriebsadministratorrolle erteilen",
          grant_analyst_role: "Benutzer Analystenrolle erteilen",
          role_granted: "Rolle erteilt",
          role_removed: "Rolle entfernt",
          offline_to_online: "Offline-Inhalte in Online-Inhalte konvertieren",
          use_elastic_search: "Elasticsearch verwenden",
        },
        sales: {
          title: "Vertrieb",
        },
        subtitlePreferences: "Sucheinstellungen",
        preferenceInfo:
          'Diese Filter dienen dazu, Ihnen auf der <a href="#/landing">Startseite</a> von Tekla Warehouse Inhalte vorzuschlagen.',
        clearFilters: "Filter löschen &rsaquo;",
        admin_role: "Admin",
        sales_role: "Vertrieb",
        admin_mode: "Admin-Modus:",
        firstName: "Vorname",
        lastName: "Nachname",
        displayName: "Anzeigename:",
        organization: "Organisationsname:",
        email: "E-Mail:",
        teklaId: "Tekla-ID",
        maintenanceAccess: "Zugriff auf Tekla-Wartungsinhalte:",
        myLocations: "Projektspeicherorte",
        measurementUnits: "Einheiten",
        useCategories: "Verwenden",
        itemTypeCategories: "Ich habe Interesse an den folgenden Typen",
        languages: "Sprachen",
        operatingSystems: "Betriebssysteme",
        software: "Softwareprodukte",
        user_id: "Benutzer-ID:",
        organization_id: "Organisations-ID:",
        edit_in_atc: "Mein Profil bearbeiten in Tekla Account",
        no_likes: "Keine Gefällt-Angaben",
        no_alerts: "Keine Abonnements",
        clear_alerts: "Warnungen löschen",
        alerts_info_html:
          "Sie erhalten eine Benachrichtigung, wenn eine der folgenden Änderungen stattfindet<br>" +
          "<b>Inhalt:</b> Titel, Beschreibung, Suchkriterien oder Metadaten des Inhaltselement wird geändert, eine neue Version der Inhalte wird hinzugefügt, oder eine Version wird gelöscht.<br>" +
          "<b>Sammlung:</b> Titel oder Beschreibung wird bearbeitet, Inhalt wird hinzugefügt oder entfernt.",
      },
      uploader: {
        placeholders: {
          productCode: "Produktcode",
          title: "Name",
          description: "Beschreibung",
          productWeight: "Produktgewicht",
          weightUnit: "Gewichtseinheit",
          material: "Material",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D-Produktkatalog",
          addToolButton: "Anwendung",
          select_files: "Dateien von Festplatte",
          select_files_from_ts: "Dateien aus Tekla Structures",
          partnerDownloadLink: "Partner-Download-Link",
        },
        headings: {
          geometryFile: "Geometriedatei",
          thumbnailFile: "Bitmap für Miniaturansicht (.bmp)",
          title: "Name",
          description: "Beschreibung",
          productCode: "Produktcode",
          productWeight: "Produktgewicht",
          weightUnit: "Gewichtseinheit",
          material: "Material",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Global",
          AF: "Afghanistan",
          AX: "Åland-Inseln",
          AL: "Albanien",
          DZ: "Algerien",
          AS: "Amerikanisch-Samoa",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarktika",
          AG: "Antigua und Barbuda",
          AR: "Argentinien",
          AM: "Armenien",
          AW: "Aruba",
          AU: "Australien",
          AT: "Österreich",
          AZ: "Aserbaidschan",
          BS: "Bahamas",
          BH: "Bahrain",
          BD: "Bangladesch",
          BB: "Barbados",
          BY: "Weißrussland",
          BE: "Belgien",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermuda",
          BT: "Bhutan",
          BO: "Bolivien",
          BQ: "Bonaire",
          BA: "Bosnien und Herzegowina",
          BW: "Botswana",
          BV: "Bouvetinsel",
          BR: "Brasilien",
          IO: "Britisches Territorium im Indischen Ozean",
          BN: "Brunei Darussalam",
          BG: "Bulgarien",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Kambodscha",
          CM: "Kamerun",
          CA: "Kanada",
          CV: "Kap Verde",
          KY: "Kaimaninseln",
          CF: "Zentralafrikanische Republik",
          TD: "Tschad",
          CL: "Chile",
          CN: "China",
          CX: "Weihnachtsinsel",
          CC: "Kokosinseln (Keeling)",
          CO: "Kolumbien",
          KM: "Komoren",
          CG: "Republik Kongo",
          CD: "Demokratische Republik Kongo",
          CK: "Cookinseln",
          CR: "Costa Rica",
          CI: "Elfenbeinküste",
          HR: "Kroatien",
          CU: "Kuba",
          CW: "Curaçao",
          CY: "Zypern",
          CZ: "Tschechische Republik",
          DK: "Dänemark",
          DJ: "Djibouti",
          DM: "Dominica",
          DO: "Dominikanische Republik",
          EC: "Ecuador",
          EG: "Ägypten",
          SV: "El Salvador",
          GQ: "Äquatorialguinea",
          ER: "Eritrea",
          EE: "Estland",
          ET: "Äthiopien",
          FK: "Falklandinseln (Malvinas)",
          FO: "Färöer-Inseln",
          FJ: "Fidschi",
          FI: "Finnland",
          FR: "Frankreich",
          GF: "Französisch-Guyana",
          PF: "Französisch-Polynesien",
          TF: "Französische Süd- und Antarktisgebiete",
          GA: "Gabun",
          GM: "Gambia",
          GE: "Georgien",
          DE: "Deutschland",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Griechenland",
          GL: "Grönland",
          GD: "Grenada",
          GP: "Guadeloupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guinea",
          GW: "Guinea-Bissau",
          GY: "Guyana",
          HT: "Haiti",
          HM: "Heard und McDonaldinseln",
          VA: "Vatikanstadt",
          HN: "Honduras",
          HK: "Hongkong",
          HU: "Ungarn",
          IS: "Island",
          IN: "Indien",
          ID: "Indonesien",
          IR: "Iran",
          IQ: "Irak",
          IE: "Irland",
          IM: "Isle of Man",
          IL: "Israel",
          IT: "Italien",
          JM: "Jamaika",
          JP: "Japan",
          JE: "Jersey",
          JO: "Jordanien",
          KZ: "Kasachstan",
          KE: "Kenia",
          KI: "Kiribati",
          KW: "Kuwait",
          KG: "Kirgistan",
          LA: "Laos",
          LV: "Lettland",
          LB: "Libanon",
          LS: "Lesotho",
          LR: "Liberia",
          LY: "Libyen",
          LI: "Liechtenstein",
          LT: "Litauen",
          LU: "Luxemburg",
          MO: "Macau",
          MK: "Mazedonien",
          MG: "Madagaskar",
          MW: "Malawi",
          MY: "Malaysia",
          MV: "Malediven",
          ML: "Mali",
          MT: "Malta",
          MH: "Marshallinseln",
          MQ: "Martinique",
          MR: "Mauretanien",
          MU: "Mauritius",
          YT: "Mayotte",
          MX: "Mexiko",
          FM: "Mikronesien",
          MD: "Moldawien",
          MC: "Monaco",
          MN: "Mongolei",
          ME: "Montenegro",
          MS: "Montserrat",
          MA: "Marokko",
          MZ: "Mosambik",
          MM: "Myanmar (Burma)",
          NA: "Namibia",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Niederlande",
          NC: "Neukaledonien",
          NZ: "Neuseeland",
          NI: "Nicaragua",
          NE: "Niger",
          NG: "Nigeria",
          NU: "Niue",
          NF: "Norfolkinsel",
          KP: "Nordkorea",
          MP: "Nördliche Marianen",
          NO: "Norwegen",
          OM: "Oman",
          PK: "Pakistan",
          PW: "Palau",
          PS: "Palästinensergebiete",
          PA: "Panama",
          PG: "Papua-Neuguinea",
          PY: "Paraguay",
          PE: "Peru",
          PH: "Philippinen",
          PN: "Pitcairn",
          PL: "Polen",
          PT: "Portugal",
          PR: "Puerto Rico",
          QA: "Katar",
          RE: "Réunion",
          RO: "Rumänien",
          RU: "Russische Föderation",
          RW: "Ruanda",
          BL: "Saint Barthélemy",
          SH: "St. Helena",
          KN: "Saint Kitts und Nevis",
          LC: "Saint Lucia",
          MF: "Saint Martin",
          PM: "Saint Pierre und Miquelon",
          VC: "Saint Vincent und die Grenadinen",
          WS: "Samoa",
          SM: "San Marino",
          ST: "São Tomé und Príncipe",
          SA: "Saudi-Arabien",
          SN: "Senegal",
          RS: "Serbien",
          SC: "Seychellen",
          SL: "Sierra Leone",
          SG: "Singapur",
          SX: "Sint Maarten",
          SK: "Slowakei",
          SI: "Slowenien",
          SB: "Salomonen",
          SO: "Somalia",
          ZA: "Süd Afrika",
          GS: "Südgeorgien",
          KR: "Südkorea",
          SS: "Südsudan",
          ES: "Spanien",
          LK: "Sri Lanka",
          SD: "Sudan",
          SR: "Surinam",
          SJ: "Spitzbergen und Jan Mayen",
          SZ: "Swasiland",
          SE: "Schweden",
          CH: "Schweiz",
          SY: "Syrien",
          TW: "Taiwan",
          TJ: "Tadschikistan",
          TZ: "Tansania",
          TH: "Thailand",
          TL: "Osttimor",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad und Tobago",
          TN: "Tunesien",
          TR: "Türkei",
          TM: "Turkmenistan",
          TC: "Turks- und Caicosinseln",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ukraine",
          AE: "Vereinigte Arabische Emirate",
          GB: "Großbritannien",
          US: "Vereinigte Staaten",
          UM: "Vereinigte Staaten, kleinere amerikanische Überseeinseln",
          UY: "Uruguay",
          UZ: "Usbekistan",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietnam",
          VG: "Jungferninseln (Britisch)",
          VI: "Jungferninseln (USA)",
          WF: "Wallis und Futuna",
          EH: "Westsahara",
          YE: "Jemen",
          ZM: "Sambia",
          ZW: "Simbabwe",
        },
        sortOptions: {
          "-modifyTime": "Zuletzt aktualisiert",
          "-modifiedAt": "Zuletzt aktualisiert",
          title: "Alphabetisch A-Z",
          "-title": "Alphabetisch Z-A",
          "-reviewCount": "Am beliebtesten",
          name: "Name",
          role: "Rolle",
          email: "E-Mail",
          "-relevance": "Relevanz",
          "-createdAt": "Zuletzt erstellt",
          displayName: "Alphabetisch A-Z",
          "-displayName": "Alphabetisch Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Heute",
          yesterday: "Gestern",
          this_week: "Diese Woche",
          previous_week: "Letzte Woche",
          this_month: "Dieser Monat",
          previous_month: "Letzter Monat",
          this_year: "Dieses Jahr",
          previous_year: "Letztes Jahr",
          beginning_of_time: "Keine Beschränkung",
          custom: "Benutzerdefinierter Zeitraum",
        },
        eventTypeOptions: {
          downloads: "Downloads",
          views: "Aufrufe",
          all_events: "Alle Ereignisse",
        },
      },
    });
  },
};
