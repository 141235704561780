import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router";

import { ICollection, IEntity, ILocalCollection, IModifier, IResource } from "../../models/dataModel";
import { ExternalResourceTypeEnum, ObjectTypeEnum } from "../../models/enums";

interface IBreadcrumbsData {
  // #region Properties (3)

  collection?: ICollection;
  creator?: IModifier;
  entity?: IEntity;

  // #endregion Properties (3)
}

/**
 * A component that can be used to display breadcrumbs
 */
export const Breadcrumbs = observer(({ item }: { item: IResource }) => {
  const breadcrumbData: IBreadcrumbsData = {};
  const navigate = useNavigate();
  const { pathname } = useLocation();

  parseItem();

  function navigateTo(path: string) {
    if (path == pathname) {
      return;
    } else {
      navigate(path);
    }
  }

  function isOwnedByOrganization(): boolean {
    if (breadcrumbData.creator) {
      return breadcrumbData.creator.externalResourceType == ExternalResourceTypeEnum.ORGANIZATION;
    } else {
      return item.type == ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION;
    }
  }

  function noCollectionNoEntity(): boolean {
    return !breadcrumbData.collection && !breadcrumbData.entity;
  }

  function collectionPresentNoEntity(): boolean {
    return !!breadcrumbData.collection && !breadcrumbData.entity;
  }

  function entityPresent(): boolean {
    return !!breadcrumbData.entity;
  }

  function parseItem() {
    if (item) {
      switch (item.type) {
        case ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION:
          breadcrumbData.collection = item as ICollection;
          breadcrumbData.creator = item.creator;
          break;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE:
          breadcrumbData.collection = (item as IEntity).collection;
          breadcrumbData.entity = item as IEntity;
          breadcrumbData.creator = item.creator;
          break;
        case ObjectTypeEnum.TEKLA_WAREHOUSE_ORGANIZATION:
          breadcrumbData.creator = {
            id: item.id || "",
            displayName: item.displayName || "",
            externalResourceType: ExternalResourceTypeEnum.ORGANIZATION,
            trimbleId: "",
            isVerified: false,
          };
          break;
        case ObjectTypeEnum.LOCAL_PACKAGE:
          breadcrumbData.collection = (item as IEntity).collection;
          breadcrumbData.entity = item as IEntity;
          break;
        case ObjectTypeEnum.LOCAL_COLLECTION:
          breadcrumbData.collection = item as ILocalCollection;
          break;
        default:
          break;
      }
    }
  }

  function getTitle(item: ICollection | IEntity | IModifier): string {
    return (
      (item as ICollection | IEntity).translatedTitle ||
      (item as IModifier).displayName ||
      (item as ICollection | IEntity).title ||
      ""
    );
  }

  function getCollectionPath(collection: ICollection): string {
    if (collection.isLocal) {
      return "/collections/local/" + collection.id;
    } else {
      return "/collections/online/" + collection.id;
    }
  }

  function getOrganizationPath(): string {
    return breadcrumbData.creator ? "/organization/" + breadcrumbData.creator.id : "";
  }

  function renderCreator() {
    return (
      <Fragment>
        {!item.isLocal && breadcrumbData.creator && isOwnedByOrganization() && (
          <li
            data-testid="breadcrumbsCreatorOwnedByOrganization"
            className={classNames({ "is-selected": noCollectionNoEntity() })}
          >
            <a onClick={() => navigateTo(getOrganizationPath())}>
              <span className="icon icon-organization" />
              <span>{getTitle(breadcrumbData.creator)}</span>
            </a>
          </li>
        )}
        {!item.isLocal && breadcrumbData.creator && !isOwnedByOrganization() && (
          <li
            data-testid="breadcrumbsCreatorNotOwnedByOrganization"
            className={classNames({ "is-selected": noCollectionNoEntity() })}
          >
            <a className="is-disabled">
              <span className="icon user" />
              <span>{getTitle(breadcrumbData.creator)}</span>
            </a>
          </li>
        )}
      </Fragment>
    );
  }

  function renderCollection() {
    return (
      <Fragment>
        {breadcrumbData.collection && breadcrumbData.collection.isLocal && (
          <Fragment>
            <li>
              <a onClick={() => navigateTo("/my/local")}>
                <span className="icon icon-search-filter-local breadcumbs-local" />
              </a>
            </li>
            <li
              data-testid="breadcrumbsCollectionLocal"
              className={classNames({
                "is-selected": collectionPresentNoEntity(),
              })}
            >
              {breadcrumbData.collection.title && (
                <a onClick={() => navigateTo(getCollectionPath(breadcrumbData.collection!))}>
                  <span className="icon icon-drawer" />
                  <span>{getTitle(breadcrumbData.collection)}</span>
                </a>
              )}
            </li>
          </Fragment>
        )}
        {breadcrumbData.collection && !breadcrumbData.collection.isLocal && (
          <li
            data-testid="breadcrumbsCollectionOnline"
            className={classNames({
              "is-selected": collectionPresentNoEntity(),
            })}
          >
            {breadcrumbData.collection.title && (
              <a onClick={() => navigateTo(getCollectionPath(breadcrumbData.collection!))}>
                <span
                  className={classNames("icon", {
                    private: breadcrumbData.collection.visibility == "private",
                    "icon-drawer": breadcrumbData.collection.visibility == "public",
                  })}
                />
                <span>{getTitle(breadcrumbData.collection)}</span>
              </a>
            )}
          </li>
        )}
      </Fragment>
    );
  }

  function renderEntity() {
    return (
      <Fragment>
        {breadcrumbData.entity && (
          <li data-testid="breadcrumbsEntity" className={classNames({ "is-selected": entityPresent() })}>
            <a>{getTitle(breadcrumbData.entity)}</a>
          </li>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {item && (
        <section className="crumbs">
          <ol className="tkl-breadcrumbs">
            {renderCreator()}
            {renderCollection()}
            {renderEntity()}
          </ol>
        </section>
      )}
    </Fragment>
  );
});
