import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { IItem } from "../../models/dataModel";
import { RootContext, RootStore } from "../../stores/rootStore";
import { NotificationsStore } from "../../stores/notificationsStore";

/**
 * A component to render the alert icon, used in Entity and Collection.
 */
export const AlertIcon = observer(({ item }: { item: IItem }) => {
  const rootStore: RootStore = useContext(RootContext);
  const notifications: NotificationsStore = rootStore.getNotificationsStore();

  async function handleChange() {
    await notifications.markAsRead(item);
  }

  return (
    <Fragment>
      {notifications.hasUnreadAlerts(item) ? (
        <a
          className="icon icon-alert icon-alert-lists unread"
          data-testid="alertUnread"
          onClick={handleChange}
          title={notifications.lastUpdatedText(item)}
        />
      ) : (
        <span
          className="icon icon-alert icon-alert-lists"
          data-testid="alertRead"
          title={notifications.lastUpdatedText(item)}
        />
      )}
    </Fragment>
  );
});
