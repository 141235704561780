import urlParser from "js-video-url-parser";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { createContext } from "react";
import _ from "underscore";
import * as validator from "validator";

import { Settings } from "../../config/Settings";
import { NewRepository } from "../../js/services/NewRepository";
import { FileHandleConverter } from "../../utils/converters/FileHandleConverter";
import { ImageUtils } from "../../utils/ImageUtils";

import { IBinary, IItem, IModifier, IRootStoreProvider, IUser } from "../../models/dataModel";
import { ExternalResourceTypeEnum, ObjectTypeEnum, SourceEnum, VisibilityEnum } from "../../models/enums";

import { BinaryStore } from "../../stores/binaryStore";
import { RootStore } from "../../stores/rootStore";

import { EditImagesFileType, EditImagesViewState } from "./EditImages";

/**
 * Mobx store used to manage images in thumbnails management dialog
 */
export class EditImagesStore extends BinaryStore implements IRootStoreProvider {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * An item (entity or collection)
   */
  @observable private item!: IItem;

  /**
   * A flag marking if some background processing is in progress currently.
   */
  @observable
  private processing = false;

  /**
   * A flag marking which view is currently rendered.
   */
  @observable
  private viewState: EditImagesViewState = EditImagesViewState.MAIN;

  /**
   * A flag marking which type of file is cyrrently being added.
   */
  @observable
  private addImageFileType?: EditImagesFileType;

  /**
   * Image or .skp file handle.
   */
  @observable
  private selectedFile?: File;

  /**
   * Data URI of the selectedFile
   */
  @observable
  private selectedFileThumbnail?: string;

  /**
   * An URL to the new video
   */
  @observable
  private videoUrl?: string;

  /**
   * A flag marking if error should be displayed
   */
  @observable
  private videoUrlErrorDisplayable = false;

  private threeDThumbnailUrl?: string;

  /**
   * A flag marking if item was updated
   */
  @observable private updated = false;

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param item entity or collection
   */
  public constructor(rootStore: RootStore, item: IItem) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    runInAction(() => {
      this.item = item;
      this.parseBinaries(item);
      this.threeDThumbnailUrl = `${Settings.sketchup.sketchUpEndpoint}${item.id}`;
    });
  }

  /**
   * Sets file handle and generates the thumbnail (data URI a path to default placeholder)
   * @param file file handle
   */
  @action
  public setSelectedFile(file: File | undefined): void {
    this.selectedFile = file;
    if (file) {
      ImageUtils.readDataLocally(this.selectedFile!, (res: any) => {
        runInAction(() => {
          this.selectedFileThumbnail = res;
        });
      });
    } else {
      this.selectedFileThumbnail = undefined;
    }
  }

  /**
   * Reads file data using image utils
   * @param file file to be read
   */
  public readFileData(file: File): any {
    ImageUtils.readDataLocally(file, (res: any) => {
      return res;
    });
  }

  /**
   * Resolves if data was updated
   * @returns true if data was updated
   */
  public isUpdated(): boolean {
    return this.updated;
  }

  /**
   * Sets if the error should be visible (if URL is not properly formed).
   * @param displayable should the url be displayable?
   */
  @action
  public setVideoUrlErrorDisplayable(displayable: boolean): void {
    this.videoUrlErrorDisplayable = displayable;
  }

  /**
   * Returns true if the error should be visible (if URL is not properly formed).
   * @returns true if the error should be visible (if URL is not properly formed).
   */
  public getVideoUrlErrorDisplayable(): boolean {
    return this.videoUrlErrorDisplayable;
  }

  /**
   * Sets video url
   * @param url URL
   */
  @action
  public setVideoUrl(url?: string): void {
    this.videoUrl = url;
  }

  /**
   * Returns video url.
   * @returns video url.
   */
  public getVideoUrl(): string | undefined {
    return this.videoUrl;
  }

  /**
   * Returns true if video url is valid. The protocol part is required and supported protocols are: http, https and ftp.
   */
  @computed
  public get isVideoUrlValid(): boolean {
    return !!(
      this.videoUrl &&
      validator.isURL(this.videoUrl, {
        protocols: ["http", "https", "ftp"],
        require_protocol: true,
      })
    );
  }

  /**
   * Returns file handle for selected file
   * @return file handle for selected file
   */
  public getSelectedFile(): File | undefined {
    return this.selectedFile;
  }

  /**
   * Returns data URI for selected file or path to default placeholder.
   */
  public getSelectedFileThumbnail(): string {
    return this.selectedFileThumbnail || ImageUtils.getDefaultThumbail();
  }

  /**
   * Returns true if currently store is processing data
   * @returns true if currently store is processing data
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Returns default 3d thumbnail url
   */
  public get3DThumbnailUrl(): string | undefined {
    return this.threeDThumbnailUrl;
  }

  /**
   * Returns root store.
   */
  public getRootStore(): RootStore {
    return this.rootStore;
  }

  /**
   * Returns binaries
   * @returns binaries
   */
  public getBinaries(): IBinary[] {
    return this.binaries;
  }

  /**
   * Returns sorted binaries
   * @returns binaries
   */
  @computed
  public get binariesSorted(): IBinary[] {
    return _.sortBy(this.binaries, "reference");
  }

  /**
   * Returns item (entity or collection)
   * @return item
   */
  public getItem(): IItem {
    return this.item;
  }

  /**
   * Returns true if the store is not currently processing data. In case of image, it also checks if the binary is valid
   * and its not thumbnail placeholder.
   * @param binary
   */
  public isBinaryDeletable(binary: IBinary): boolean {
    if (binary.isThumbnail) {
      return !this.isProcessing() && !!binary.id && !binary.isThumbnailPlaceholder;
    } else {
      return !this.isProcessing();
    }
  }

  /**
   * Marks binary as default.
   * @param binary
   */
  @action
  public async setDefaultBinary(binary: IBinary): Promise<IItem> {
    const strategy = this.getSetDefaultBinaryStrategy(binary);
    if (strategy) {
      try {
        this.processing = true;
        const updatedItem = await strategy();
        runInAction(() => {
          this.parseBinaries(updatedItem);
          this.item = updatedItem;
          this.updated = true;
          this.processing = false;
        });
        return updatedItem;
      } catch {
        runInAction(() => {
          this.processing = false;
        });
        throw new Error("Setting deafult binary failed");
      }
    } else {
      throw new Error("no strategy");
    }
  }

  /**
   * Adds link to video. The link gets converted to embedded mode, if possible.
   */
  @action
  public async addVideo(): Promise<string> {
    const failureText = this.rootStore.getTranslation("collections.notification.video_adding_failed");
    if (!this.videoUrl) {
      throw new Error(failureText);
    } else {
      try {
        this.processing = true;
        const urls = {};
        const url = this.tryToConvertVideoUrlToEmbeddedMode(this.videoUrl);
        const reference = this.getNewVideoReference();
        urls[reference] = url;
        const updatedItem = await NewRepository.setContentAttribute(
          this.item,
          { videourls: urls },
          null,
          false,
          this.getRootStore().getUserStore().getCurrentUser()?.id,
        );
        runInAction(() => {
          this.setViewState(EditImagesViewState.MAIN);
          this.videoUrl = undefined;
          this.parseBinaries(updatedItem);
          this.item = updatedItem;
          this.updated = true;
          this.processing = false;
        });
        return this.rootStore.getTranslation("collections.notification.video_added");
      } catch {
        console.log("Error occurred while adding video");
        runInAction(() => {
          this.processing = false;
        });
        throw new Error(failureText);
      }
    }
  }

  /**
   * Upload (previously selected) file (image or skp file).
   */
  @action
  public async addFile(): Promise<string> {
    if (this.addImageFileType === EditImagesFileType.IMAGE) {
      return this.addThumbnail();
    } else {
      return this.addThreeDThumbnail();
    }
  }

  /**
   * Deletes binary and reloads binaries
   * @param binary
   */
  @action
  public async deleteBinary(binary: IBinary): Promise<IItem> {
    const strategy = this.getBinaryDeletionStrategy(binary);
    if (strategy) {
      try {
        this.processing = true;
        const updatedItem = await strategy();
        runInAction(() => {
          this.parseBinaries(updatedItem);
          this.item = updatedItem;
          this.updated = true;
          this.processing = false;
        });
        return this.item;
      } catch {
        runInAction(() => {
          this.processing = false;
        });
        throw new Error("Error occurred while deleting binary");
      }
    } else {
      throw new Error("no strategy");
    }
  }

  /**
   * Returns view state
   * @returns view state
   */
  public getViewState(): EditImagesViewState {
    return this.viewState;
  }

  /**
   * Sets view state
   * @param viewState EditImageViewState object
   */
  @action
  public setViewState(viewState: EditImagesViewState) {
    this.viewState = viewState;
    this.setAddImageFileType(undefined);
    this.resetAddFileData();
  }

  /**
   * Returns new file form type
   */
  public getAddImageFileType(): EditImagesFileType | undefined {
    return this.addImageFileType;
  }

  /**
   * Sets new file form type
   * @param fileType view / file type
   */
  @action
  public setAddImageFileType(fileType?: EditImagesFileType) {
    this.addImageFileType = fileType;
    this.resetAddFileData();
  }

  /**
   * Returns images
   * @returns images
   */
  @computed
  public get thumbnails(): IBinary[] {
    return _.select(this.binaries, (b: IBinary) => !!b.isThumbnail);
  }

  /**
   * Returns images count
   * @returns number of images
   */
  @computed
  public get thumbnailsCount(): number {
    return _.size(this.thumbnails);
  }

  /**
   * Returns skp files
   * @returns skp files
   */
  @computed
  public get threeDThumbnails(): IBinary[] {
    return _.select(this.binaries, (b: IBinary) => !!b.is3DThumbnail);
  }

  /**
   * Returns number of skp files
   * @returns number of skp files
   */
  @computed
  public get threeDThumbnailsCount(): number {
    return _.size(this.threeDThumbnails);
  }

  /**
   * Returns videos
   * @returns videos
   */
  @computed
  public get videos(): IBinary[] {
    return _.filter(this.binaries, (b: IBinary) => !!b.isVideo);
  }

  /**
   * Returns videos count
   * @returns videos count
   */
  @computed
  public get videosCount(): number {
    return _.size(this.videos);
  }

  private resetAddFileData(): void {
    this.setVideoUrl(undefined);
    this.setSelectedFile(undefined);
    this.setVideoUrlErrorDisplayable(false);
  }

  private getBinaryDeletionStrategy(binary: IBinary): any | undefined {
    if (binary.isThumbnail) {
      return () => NewRepository.deleteThumbnail(this.item, binary.reference);
    } else if (binary.is3DThumbnail) {
      return () => NewRepository.delete3dThumbnail(this.item, binary.reference);
    } else if (binary.isVideo) {
      const attributes = {};
      attributes[binary.reference!] = null;
      return () =>
        NewRepository.setContentAttribute(
          this.item,
          { videourls: attributes },
          null,
          false,
          this.getRootStore().getUserStore().getCurrentUser()?.id,
        );
    }
  }

  private tryToConvertVideoUrlToEmbeddedMode(url: string): string | undefined {
    try {
      const parsedUrl = urlParser.parse(url);
      return parsedUrl && parsedUrl.provider === "youtube"
        ? `https://www.youtube.com/embed/${parsedUrl.id}`
        : this.videoUrl;
    } catch (err) {
      return this.videoUrl;
    }
  }

  private getNewVideoReference(): string {
    return this.getNewReference("url");
  }

  private getNewThumbnailReference(): string {
    if (this.thumbnailsCount === 0) {
      return "thumbnail";
    } else {
      return this.getNewReference("thumbnail");
    }
  }

  private getNewThreeDThumbnailReference(): string {
    if (this.threeDThumbnailsCount === 0) {
      return "thumbnail_3d";
    } else {
      return this.getNewReference("thumbnail_3d");
    }
  }

  private getNewReference(baseName: string): string {
    const max = 1000;
    let i = 1;
    let reference = `${baseName}_1`;
    while (this.isReferenceAlreadyUsed(reference) && i < max) {
      i += 1;
      reference = `${baseName}_${i}`;
    }
    return reference;
  }

  private isReferenceAlreadyUsed(reference: string): boolean {
    return !!_.find(this.binaries, (v) => v.reference === reference);
  }

  private getSetDefaultBinaryStrategy(binary: IBinary): any | undefined {
    const user = this.rootStore.getUserStore().getCurrentUser();
    const itemWithAttributes = { ...this.item.attributes, defaultThumbnail: { name: binary.reference } };

    if (!!user) {
      return () => NewRepository.setContentAttribute(this.item, itemWithAttributes, null, true, user.id);
    }
  }

  @action
  private async addThumbnail(): Promise<string> {
    if (!ImageUtils.isTooBigImage(this.selectedFile!)) {
      try {
        this.processing = true;
        const reference = this.getNewThumbnailReference();
        const binary = FileHandleConverter.fromFilePickerToBinary(this.selectedFile!);
        const updatedItem = await NewRepository.addThumbnail(this.item, binary, reference, this.getEditor());
        runInAction(() => {
          this.parseBinaries(updatedItem);
          this.item = updatedItem;
          this.setViewState(EditImagesViewState.MAIN);
          this.selectedFile = undefined;
          this.selectedFileThumbnail = undefined;
          this.processing = false;
          this.updated = true;
        });
        return this.rootStore.getTranslation("collections.notification.thumbnail_added");
      } catch {
        runInAction(() => {
          this.processing = false;
        });
        throw new Error(this.rootStore.getTranslation("shared.catalog_entity_edit.update_failed"));
      }
    } else {
      throw new Error(this.rootStore.getTranslation("collections.notification.selected_image_is_too_big"));
    }
  }

  @action
  private async addThreeDThumbnail(): Promise<string> {
    try {
      this.processing = true;
      const reference = this.getNewThreeDThumbnailReference();
      const binary = FileHandleConverter.fromFilePickerToBinary(this.selectedFile!);
      const updatedItem = await NewRepository.add3DThumbnail(this.item, binary, reference, this.getEditor());
      runInAction(() => {
        this.parseBinaries(updatedItem);
        this.item = updatedItem;
        this.updated = true;
        this.setViewState(EditImagesViewState.MAIN);
        this.selectedFile = undefined;
        this.selectedFileThumbnail = undefined;
        this.processing = false;
      });
      return this.rootStore.getTranslation("shared.3dPic.3d_pic_added");
    } catch {
      runInAction(() => {
        this.processing = false;
      });
      throw new Error(this.rootStore.getTranslation("shared.3dPic.3d_pic_update_failed"));
    }
  }

  /**
   * Returns the editor
   * * the user, is user is the creator or belongs to the owner organization,
   * * the owner organization, if user is contentEditor for the owner organization.
   */
  private getEditor(): IUser | IModifier | undefined {
    const isCreator = this.item?.creator?.id === this.rootStore.getUserStore().getCurrentUser()?.id;

    if (
      isCreator ||
      this.rootStore.getUserStore().isUserAdmin() ||
      this.rootStore.getUserStore().belongsToOrganization(this.item)
    ) {
      return this.rootStore.getUserStore().getCurrentUser();
    } else if (!!this.item && this.rootStore.getUserStore().isContentEditorForOrganization(this.item)) {
      return this.item.creator;
    }
  }
}

const defaultItem: IItem = {
  attributes: {
    itemTypeCategories: [],
    licensesACL: [],
    useCategories: [],
    videourls: {},
  },
  creator: {
    id: "",
    displayName: "",
    externalResourceType: ExternalResourceTypeEnum.USER,
    pictureUrl: "",
    trimbleId: "",
    isVerified: false,
  },
  currentUserRating: 0,
  id: "",
  isHidden: false,
  isLocal: false,
  displayName: "",
  packagesCount: 0,
  reviewCount: 0,
  source: SourceEnum.UNKNOWN,
  thumbnail: { url: "" },
  thumbnails: [],
  thumbnails3d: [],
  translations: {},
  title: "",
  type: ObjectTypeEnum.TEKLA_WAREHOUSE_PACKAGE,
  visibility: VisibilityEnum.PRIVATE,
  getOrganizationPath: () => "",
};

/**
 * Context for EditImagesStore objects
 */
export const EditImagesStoreContext = createContext<EditImagesStore>(new EditImagesStore(new RootStore(), defaultItem));
