
import { observer } from "mobx-react";
import * as React from "react";
import { useContext } from "react";
import _ from "underscore";
import { ModusButton } from "@trimble-oss/modus-react-components";

import { RootContext, RootStore } from "../../../../stores/rootStore";
import { ContentManagerStore, ContentManagerStoreContext } from "../contentManagerStore";

import { ICollection, IEntity } from "../../../../models/dataModel";

export const HideAndUnhide = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const contentManagerStore: ContentManagerStore = useContext(ContentManagerStoreContext);
  const store = contentManagerStore.getActionsStore();

  const processingOrNothingSelected = contentManagerStore.isQuerying() || store.isProcessing() || !contentManagerStore.hasSelectedContent();

  const hideIsDisabled =
    processingOrNothingSelected ||
    _.all(contentManagerStore.getSelectedResults(), (content: IEntity | ICollection) => !!content.isHidden);

  const unhideIsDisabled =
    processingOrNothingSelected ||
    !_.any(contentManagerStore.getSelectedResults(), (content: IEntity | ICollection) => !!content.isHidden && !content.isBanned);

  return (
    <>
      <ModusButton
        disabled={hideIsDisabled}
        className={"button-primary hide-selected"}
        onClick={() => store.hideSelected()}
        data-testid="hideSelected"
      >
        {rootStore.getTranslation("actions.hide_selected")}
      </ModusButton>
      <ModusButton
        disabled={unhideIsDisabled}
        className={"button-primary unhide-selected"}
        onClick={() => store.unhideSelected()}
        data-testid="unhideSelected"
      >
        {rootStore.getTranslation("actions.unhide_selected")}
      </ModusButton>
    </>
  );
});
