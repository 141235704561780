import * as React from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import { RootContext } from "../../stores/rootStore";
import { BatchEditDialogStoreContext } from "./batchEditDialogStore";
import { IDropdownOption, IDropdownOptionGroup } from "../../models/dataModel";
import { DropdownMultiple } from "../../components/DropdownMultiple";
import { DateInput } from "../../components/DateInput";
import { SelectAction } from "./SelectAction";
import classNames from "classnames";
import _ from "underscore";
import { metadataLabels } from "../../utils/MetadataLabels";

const OperatingSystem = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);
  const compatibleOperatingSystemOptions = _.map(metadataLabels.compatibleOperatingSystemsOptions, (option) => {
    return {
      label: rootStore.getTranslation(option.name),
      value: option.value,
    };
  });

  function handleSelectChange(event) {
    dialogStore.setShowOperatingSystem(event.target.checked);
  }

  function handleSelectOperatingSystems(options: readonly IDropdownOption[]) {
    dialogStore.setOperatingSystems(options);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-operating-system"
        id="selectOperatingSystem"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowOperatingSystem()}
      />
      <label htmlFor="selectOperatingSystem">{rootStore.getTranslation("upload.compatibleOperatingSystems")}</label>
      {dialogStore.getShowOperatingSystem() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="operatingSystem"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getOperatingSystemAction}
            setAction={dialogStore.setOperatingSystemAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("operatingSystem"),
            })}
          >
            <DropdownMultiple
              options={compatibleOperatingSystemOptions}
              selectedValues={dialogStore.getOperatingSystems()}
              onChange={handleSelectOperatingSystems}
              className="dropdown-wrapper gray"
              inputId="operatingSystems"
            />
          </div>
        </div>
      )}
    </div>
  );
});

const SoftwareProduct = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);
  const compatibleSoftwareProductOptions: IDropdownOption[] = _.map(
    metadataLabels.softwareProductsOptions,
    (option) => {
      return {
        label: rootStore.getTranslation(option.name),
        value: option.value,
      };
    },
  );

  function handleSelectChange(event) {
    dialogStore.setShowSoftwareProduct(event.target.checked);
  }

  function handleSelectSoftwareProducts(options: readonly IDropdownOption[]) {
    dialogStore.setSoftWareProducts(options as IDropdownOption[]);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-software-product"
        id="selectSoftwareProduct"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowSoftwareProduct()}
      />
      <label htmlFor="selectSoftwareProduct">{rootStore.getTranslation("upload.compatibleSoftwareProducts")}</label>
      {dialogStore.getShowSoftwareProduct() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="softwareProduct"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getSoftwareProductAction}
            setAction={dialogStore.setSoftwareProductAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("softwareProduct"),
            })}
          >
            <DropdownMultiple
              options={compatibleSoftwareProductOptions}
              selectedValues={dialogStore.getSoftwareProducts()}
              onChange={handleSelectSoftwareProducts}
              className="dropdown-wrapper gray"
              inputId="softwareProducts"
            />
          </div>
        </div>
      )}
    </div>
  );
});

const TestedVersion = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  const versionOptions = _.chain(metadataLabels.testedVersionsOptions)
    .map((tvo: IDropdownOption | IDropdownOptionGroup) =>
      _.has(tvo, "options") ? (tvo as IDropdownOptionGroup).options : (tvo as IDropdownOption),
    )
    .flatten()
    .map((option) => {
      return { label: rootStore.getTranslation(option.label), value: option.value };
    })
    .value();

  function handleSelectChange(event) {
    dialogStore.setShowTestedVersion(event.target.checked);
  }

  function handleSelectTestedVersions(options: readonly IDropdownOption[]) {
    dialogStore.setTestedVersions(options as IDropdownOption[]);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-tested-version"
        id="selectTestedVersion"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowTestedVersion()}
      />
      <label htmlFor="selectTestedVersion">{rootStore.getTranslation("upload.testedVersions")}</label>
      {dialogStore.getShowTestedVersion() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="testedVersion"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getTestedVersionAction}
            setAction={dialogStore.setTestedVersionAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("testedVersion"),
            })}
          >
            <DropdownMultiple
              options={versionOptions}
              selectedValues={dialogStore.getTestedVersions()}
              onChange={handleSelectTestedVersions}
              className="dropdown-wrapper gray"
              inputId="testedVersions"
              menuHeight={130}
            />
          </div>
        </div>
      )}
    </div>
  );
});

const ProductCode = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleSelectChange(event) {
    dialogStore.setShowProductCode(event.target.checked);
  }

  function handleProductCodeChange(event) {
    dialogStore.setProductCode(event.target.value);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-product-code"
        id="selectProductCode"
        type="checkbox"
        onChange={handleSelectChange}
        checked={dialogStore.getShowProductCode()}
      />
      <label htmlFor="selectProductCode">{rootStore.getTranslation("upload.productCode")}</label>
      {dialogStore.getShowProductCode() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="productCode"
            options={[dialogStore.getReplaceActionOption()]}
            getAction={dialogStore.getProductCodeAction}
            setAction={dialogStore.setProductCodeAction}
          />
          <div
            className={classNames("inputs", {
              "invalid-action": dialogStore.hasInvalidValue("productCode"),
            })}
          >
            <div>
              <input
                data-testid="input-productCode"
                type="text"
                value={dialogStore.getProductCode()}
                onChange={handleProductCodeChange}
              ></input>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const ProductExpirationDate = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  function handleChange(event) {
    dialogStore.setShowProductExpirationDate(event.target.checked);
  }

  function handleSelectProductExpirationDate(date: Date | null) {
    if (!date) return;
    dialogStore.setProductExpirationDate(date);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-product-expiration-date"
        id="selectProductExpirationDate"
        type="checkbox"
        onChange={handleChange}
        checked={dialogStore.getShowProductExpirationDate()}
      />
      <label htmlFor="selectProductExpirationDate">{rootStore.getTranslation("upload.productExpirationDate")}</label>
      {!!dialogStore.getShowProductExpirationDate() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="productExpirationDate"
            options={[dialogStore.getReplaceActionOption()]}
            getAction={dialogStore.getProductExpirationDateAction}
            setAction={dialogStore.setProductExpirationDateAction}
          />
          <div
            className={classNames("inputs", {
              "invalid-action": dialogStore.hasInvalidValue("productExpirationDate"),
            })}
          >
            <DateInput
              selectedValue={dialogStore.getProductExpirationDate()}
              onChange={handleSelectProductExpirationDate}
              testId="input-productExpirationDate"
              placement="top-start"
            />
          </div>
        </div>
      )}
    </div>
  );
});

const MeasurementUnit = observer(() => {
  const rootStore = useContext(RootContext);
  const dialogStore = useContext(BatchEditDialogStoreContext);

  const measurementUnitOptions: IDropdownOption[] = _.map(metadataLabels.measurementUnitOptions, (option) => {
    return {
      value: option.value,
      label: rootStore.getTranslation(option.name),
    };
  });

  function handleChange(event) {
    dialogStore.setShowVersionMeasurementUnit(event.target.checked);
  }

  function handleSelectMeasurementUnits(selected: readonly IDropdownOption[]) {
    dialogStore.setVersionMeasurementUnits(selected);
  }

  return (
    <div className="edit-group">
      <input
        className="white"
        data-testid="select-version-measurement-unit"
        id="selectVersionMeasurementUnit"
        type="checkbox"
        onChange={handleChange}
        checked={dialogStore.getShowVersionMeasurementUnit()}
      />
      <label htmlFor="selectVersionMeasurementUnit">{rootStore.getTranslation("upload.measurementUnits")}</label>
      {dialogStore.getShowVersionMeasurementUnit() && (
        <div className="content-groups edit-fields">
          <SelectAction
            fieldName="versionMeasurementUnit"
            options={dialogStore.getActionOptions()}
            getAction={dialogStore.getVersionMeasurementUnitAction}
            setAction={dialogStore.setVersionMeasurementUnitAction}
          />
          <div
            className={classNames("tag-list-area", {
              "invalid-action": dialogStore.hasInvalidValue("versionMeasurementUnit"),
            })}
          >
            <DropdownMultiple
              options={measurementUnitOptions}
              selectedValues={dialogStore.getVersionMeasurementUnits()}
              onChange={handleSelectMeasurementUnits}
              className="dropdown-wrapper gray"
              inputId="versionmeasurementUnits"
            />
          </div>
        </div>
      )}
    </div>
  );
});

/**
 * Renders version fields for editing
 */
export const EditVersion = observer(() => {
  const rootStore = useContext(RootContext);

  return (
    <div className="batch-edit-versions l-relative">
      <h4>{rootStore.getTranslation("batch_edit.versions.version")}</h4>
      <OperatingSystem />
      <SoftwareProduct />
      <TestedVersion />
      <ProductCode />
      <ProductExpirationDate />
      <MeasurementUnit />
    </div>
  );
});
