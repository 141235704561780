import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const En = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["en-US"]) {
      window.locale["en-US"] = {};
    }

    _.extend(window.locale["en-US"], {
      myDetails: {
        my_roles: "My roles",
        my_roles_link: "Roles and access rights for Tekla Warehouse content",
        my_roles_link_url: "https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Roles",
        roles: {
          member: "Tekla Warehouse Content Editor (Employee)",
          employee: "Employee",
          contentEditor: "Tekla Warehouse Content Editor (External)",
        },
      },
      immutability: {
        add_code_name_for_package: "Add a code name for the content",
        code_name: "Code name",
        immutable: "Immutable",
        archive: "Archive",
        archive_collection: "Archive the collection and all its content",
        archive_version: "Archive the selected version",
        archive_package: "Archive the package and all its content",
        archive_dialog_text:
          "Archived content will not be visible or searchable in Tekla Warehouse. However, if the content has been previously used with the Tekla Structures cloud service, the cloud service can continue to access the content.",
        archive_success: function (name: string) {
          return `${name} archived successfully`;
        },
        archive_failure: function (name: string) {
          return `Archiving ${name} failed`;
        },
        archived_content_redirect_message: "The content you are trying to access has been archived",
        edit_title_move_or_archive: "Edit title, move or archive",
        edit_title_or_archive: "Edit title or archive",
        major_update: "Major update",
        major_update_description:
          "Changes that are not backward compatible, such as introducing new features, removing existing ones, or making significant alterations.",
        minor_update: "Minor update",
        minor_update_description:
          "Adds functionality in a backward-compatible manner, such as new features or enhancements without breaking existing functionality.",
        patch_update: "Patch update",
        patch_update_description:
          "Backward-compatible fixes, such as security patches, bug fixes, or performance improvements, without new features.",
        info: "Your organization provides content for the Tekla Structures cloud service. A code name and version number are required for content in the Tekla Structures cloud service to ensure compatibility and consistency.",
        immutable_files_info:
          "To maintain content consistency in the cloud service, you cannot remove or modify files. If modifications are needed, archive the current version and upload a new version.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `For more information, see <a href="${readMoreUrl}" target="_blank">this page</a>.`;
        },
        resolve_conflict: {
          title: "Resolve Conflict",
          description:
            'Following items have been found. Select "Keep existing" to keep the existing item found in your installation.',
          table_header_name: "Name",
          table_header_type: "Type",
          table_header_action: "Action",
          action_keep_existing: "Keep existing",
          action_replace: "Replace",
          action_done: "Done",
        },
        auth: {
          authentication_process_failed: "Authentication process failed",
          access_denied: "Access denied",
          handle_unauthorized: {
            unauthorized: "Unauthorized",
            please_log_in: "Please log in or register",
          },
        },
        translations: {
          edit_translations: "Edit translations",
          edit_translations_info: "Please select a language from the list, edit and save",
          edit_translations_default_language_info:
            "<b>Default</b> is shown when the language user selected is not available",
          edit_content: "Content information",
          edit_support: "Support",
          updated_successfully: "Translations updated successfully",
          error_on_update: "Error while updating translations",
          translate_all_completed: "All texts translated",
          translate_all_completed_with_errors: "Translations done, not all texts could be translated",
          default: "Default",
          batch_translations: "Batch translations",
          export_import_info:
            "<b>Export</b> the batch translations template for content of your choosing, edit the template adding the translations, and <b>import</b> the template back to save multiple translations at once.",
          import_translations: "Import",
          export_translations: "Export",
          translations_missing: " (empty)",
          select_content: "Select translations to export:",
          import_finished: "Import finished!",
          select_file: "Select file",
          select_file_title: "Select file to import:",
          import_from_file: "Import",
          import_error_info: "File does not follow template format:",
          export_selected: "Export",
          invalid_file: "Invalid file content format",
          import_translations_finished: "Translations imported",
          details_too_long: "Details content exceeds maximun allowed length",
          import_failed: "Import translations failed",
          import_failed_for_content: function (content) {
            return "Import failed for content " + content;
          },
          translate_to_other_languages: "Translate to other languages",
          import_count: function (count) {
            return "Updated translations for " + count + " content";
          },
          invalid_language: function (contentId, language) {
            return "Content '" + contentId + "' contains invalid language '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "Content '" + contentId + "' contains invalid translation field '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "Content '" +
              contentId +
              "' translation field '" +
              translationField +
              "' value exceeds maximum allowed length"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "3D model",
          "3d_pic_added": "3d model added successfully",
          "3d_pic_updated": "3d model updated successfully",
          "3d_pic_removed": "3d model removed successfully",
          "3d_pic_update_failed": "Failed to update 3d model",
          "3d_pic_remove_failed": "Failed to remove 3d model",
          "3d_pic_missing": "No 3D image for content. Choose image by clicking 'Choose 3D model ' below.",
          "3d_pic_uploading": "Your model is being loaded. Please wait...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Click here to edit content",
          updated_successfully: "Updated successfully",
          update_failed: "Update failed",
          edit_search_criteria: "Edit search criteria",
          edit_group_and_category: "Edit group and category",
          edit_content: "Edit content",
          edit_thumbnail: "Edit thumbnail",
          edit_thumbnail_descriptive: "Recommended dimensions: 400 x 300 px",
          edit_description: "Edit description",
          edit_download_link: "Add or edit partner download link",
          versions_not_shown_to_users:
            "Because this content item has a partner download link, Tekla Warehouse users cannot see any versions that you previously added to the content item. Tekla Warehouse administrators and users who have editing rights can still see all versions of the content item.",
          partner_download_link: "Enter the download URL",
          partner_download_link_disclaimer:
            "If you add a partner download link to an existing content item, Tekla Warehouse users will no longer be able to see any versions that you previously added to the content item. This action cannot be undone.",
          partner_download_link_placeholder: "http://",
          edit_title_or_delete: "Edit title or delete",
          edit_title_or_move_delete: "Edit title, move or delete",
          edit_support: "Edit support information",
          edit_release_notes: "Edit release notes",
          edit_content_wysiwyg: "Edit content details",
          edit_content_embed_info: "You can only embed videos from YouTube, Vimeo or Alugha.",
          edit_content_embed_link: "More information in Tekla User Assistance &rsaquo;",
          details_unallowed_embed_info:
            "Please only embed content from the following domains: YouTube, Vimeo or Alugha. The allowed URL formats are:",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "Invalid video link. You can add videos by URL from YouTube and Vimeo.",
          edit_content_vide_code_error:
            "Invalid embed format. Only iframes accepted. The allowed video providers are: YouTube, Vimeo & Alugha",
          details_max_length_exceeded: "The Details content is too long",
          editor_mode_label: "Editor mode:",
          editor_mode_on: "On",
          editor_mode_off: "Off",
          do_not_notify: "Minor update, don't notify subscribers",
          invalid_tag: "Invalid tag",
        },
        transfer_package: {
          move_header: "Move content",
          copy_header: "Copy content",
          actions: {
            copy: "Copy",
            move: "Move",
            cancel: "Cancel",
          },
          loading: "Loading collections...",
          copying: "Copying content",
          moving: "Moving content",
          select_collection: "Select collection",
          content_moved: "Content moved. Changes might be visible after a few minutes.",
          content_copied: "Content copied. Changes might be visible after a few minutes.",
          operation_failed: "Operation failed",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Content linked successfully into " + name;
          },
          selected_resources_linked: function (name) {
            return "Selected content linked successfully into " + name;
          },
          resources_linked_with_errors: "Content linked, there were errors with some of the content",
          resources_linking_failed: "Content linking failed",
          resource_unlinked: "Link removed successfully. Changes might be visible after a few minutes.",
          resources_unlinked:
            "Links removed successfully from selected content. Changes might be visible after a few minutes.",
          resources_unlinked_with_errors: "Links removed, there were errors with some of the content",
          resources_unlinking_failed: "Links removing failed",
          linking: "Creating link",
          linking_failed: "Linking failed",
          linking_failed_title: "Following content could not be linked:",
          unlinking_failed_title: "Following content could not be unlinked:",
          linking_failed_already_contains:
            "Content has already been linked to selected collection. Updates might take a while to appear.",
          linking_failed_already_contains_short: "Content has already been linked to selected collection",
          unlinking_failed: "Removing link failed",
          unlinking_failed_does_not_contain:
            "Content link has already been removed from collection. Updates might take a while to appear.",
          unlinking_failed_does_not_contain_short: "Content link has already been removed from collection",
        },
        notification_channel: {
          title_success: "success",
          title_notice: "notice",
          title_info: "info",
          title_error: "error",
        },
        commenting: {
          content_commenting: "Allow content commenting",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Download requires Tekla Maintenance",
          requires_tekla_maintenance: "Requires Tekla Maintenance",
          can_access_maintenance: {
            yes: "Yes",
            no: "No",
          },
        },
        confirm: {
          title: "Confirm",
          yes: "Yes",
          no: "No",
          ok: "Ok",
          close: "Close",
          dont_show: "Do not show this again",
        },
        show_more: " Show more",
        show_less: " Show less",
        invalid_request: "Invalid request",
        resource_not_found: "Requested resource could not be found",
        spinner_loading: "Loading",
        spinner_processing: "Processing",
        spinner_loading_long_time: "Loading content may take several minutes",
        local_service: {
          info: "Tekla Warehouse Service plugin is needed to create local and network content. With Tekla Structures 20.1 SR1 or newer you can upload content directly from your model.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service plugin</a> " +
            "is needed to create local and network content.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service plugin</a> " +
            "is not available. The service is needed to enable local and network collections.",
          download_to_unlock_all_features_html:
            "Download " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service plugin</a> " +
            "to unlock all features.",
          service_out_of_date_html:
            "Your Tekla Warehouse plugin is out of date. Update " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service plugin</a> " +
            "to unlock all features.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Select language",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Invite other users",
          send_invitation_to_email: "Send invitation to email",
          add_e_mail_addresses: "Add e-mail addresses",
          and_assign_role: "and assign role",
          send_invitation: "Send invitation",
          invitation_already_sent: "Invitation already sent!",
          invitation_sent: "Invitation sent!",
          update_selected_invitations: "Update selected invitations",
          new_role: "New role",
          invitations_list_updated: "Invitations list updated!",
        },
        item_browser_title: "Select content items",
        prerequisites: {
          description_placeholder: "Add description here",
          link_name_placeholder: "Add link name here",
          add_prerequisites_and_recommendations: "Prerequisites and recommendations (optional)",
          list_the_profiles_components_files:
            "List the profiles, components, files or models that are needed for the content to function correctly.",
          link_to_existing_content: "Link to existing content",
          please_enter_prerequisite_url: "Please enter prerequisite URL",
          please_enter_valid_prerequisite_url: "Please enter valid prerequisite URL",
          missing_http: "Missing http://",
          add_link: "Add link",
        },
        treeview: {
          cannot_export_this_container: "Cannot export this container. Please select individual items instead.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "File must be smaller than " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Invalid file type.  File must be one of following types " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Timeframe:",
          timeframe_start: "Start:",
          timeframe_end: "End:",
          eventtype: "Event type:",

          downloads_daily_by_version: "Downloads by version",
          downloads_by_country: "Downloads by country",
          downloads_by_version: "Downloads by version",
          downloads_by_version_organization: "Downloads by version and organization",
          downloads_by_organization: "Downloads by organization",
          downloads_daily_by_package: "Downloads by content item",

          views_daily_by_package: "Views by content item",
          views_by_country: "Views by country",
          views_by_organization: "Views by organization",

          twh_clicked_partner_link_daily_by_package: "Partner download link clicks by content item",
          twh_clicked_partner_link_by_country: "Partner download link clicks by country",
          twh_clicked_partner_link_by_organization: "Partner download link clicks by organization",

          downloads_by_package: "Downloads by content item",
          views_by_package: "Views by content item",
          downloads_daily_by_collection: "Downloads by collection",
          downloads_by_collection: "Downloads by collection",
          views_daily_by_collection: "Views by collection",
          views_by_collection: "Views by collection",

          events_by_organization_func: function (version) {
            return 'Version "' + version + '" events by organization'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Others",
          sum_all_versions: "All versions",
          sum_all_packages: "All content items",
          sum_all_collections: "All collections",
          sum_all_organizations: "All organizations",
          recording_started_at: "Data recording started June 2015",
          recording_view_events_started_at: "Data recording started March 2017",
          version_title_func: function (title) {
            return 'Version "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " downloads", count + " download", count + " downloads"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Numbers include all completed downloads.",
          counted_events_info_package: "Numbers include all completed downloads.",
          export: {
            caption: "Export analytics",
            select_options: "Select options",
            select_timeframe_description: "Please select below a timeframe to export analytics from:",
            select_eventtype_description: "Please select event type to export analytics from:",
          },
        },
        access_rights: {
          editors: "Editors",
          download_access: "View & download access",
          write_user_id_placeholder: "Write user ID or email address",
          write_organization_id: "Enter valid organization IDs. Enter each ID on a separate row.",
          write_id_placeholder:
            "Enter user IDs, user email addresses, or organization IDs. Enter each ID or email address on a separate row.",
          write_id_or_name_placeholder: "User/organization ID or name or user email address",
          id_help_html_short:
            "For security reasons our users are not searchable.<br/><br/>" +
            "Request the user ID directly from the person you wish to give access to. <br/>" +
            'User ID is shown in <a href="#/my/">My details</a>.',
          id_help_html:
            "For security reasons our users are not searchable.<br/><br/>" +
            "Request the user ID directly from the person you wish to give access to. To give access to all users in an organization use the organization ID.<br/><br/>" +
            'The user and organization IDs are shown in <a href="#/my/">My details</a>.',
          max_input_limit: function (max_rows) {
            return "Enter each ID or email address on a separate row. You can enter " + max_rows + " rows at a time.";
          },
          input_too_long: "The text that you entered is too long.",
          viewer_already_added: function (input) {
            return `${input} was already added as a viewer`;
          },
          wrong_format: function (input) {
            return `${input} is not a valid ID or email address`;
          },
          candidates_for_keyword: function (keyword) {
            return "Users found with the email address or ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Add new",
        add: "Add",
        delete: "Delete",
        set_as_default: "Default thumbnail",
        select_file: "Select file",
        choose_option: "Choose option",
        info_3D_exists: "You have allready added a 3d model, you can not add more than one model to content.",
        action_options: {
          image: "Image",
          "3DModel": "3D model",
          three_d: "3D model",
          video: "Video",
        },
        video: {
          url: "Video URL",
          invalid_url: "Invalid URL",
        },
      },
      units: {
        bytes: "bytes",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Download",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " likes", number + " like", number + " likes"]);
      },
      organizations: {
        error_loading_collection_contents: "Error loading collection contents",
        error_loading_organization: "Error loading organization",
      },
      downloads_graph: {
        date: "Date",
        total_views: "Total Views",
        total_downloads: "Total Downloads",
        views: "Views",
        downloads: "Downloads",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Tekla Warehouse Service plugin" +
          "</a>" +
          " is needed.",
        processingFailed: "Processing failed",
        forbidden: "Forbidden",
        resource_not_found: "Resource not found",
        no_matches_found: "No matches found",
        no_matches_found_for: function (input) {
          return "No matches found with the email address or ID " + input;
        },
        too_many_matches: "Found more than one match",
        not_identified: "Not identified",
        error_while_searching: "Error while searching",
        error_user_not_found: "User or organization not found",
        error_while_adding_member: "Error while adding access right member",
        error_while_removing_member: "Error while removing access right member",
        error_while_granting_analyst_role: "Error while adding analyst role",
        error_while_removing_analyst_role: "Error while removing analyst role",
      },
      offline_to_online: {
        fail_on_initialize: "Error while handling zip content, aborting convert",
        fail_on_validate: "Content metadata validation failed, aborting convert",
        fail_on_save: "Error occurred  while saving content, rolling back changes",
        success: "Successfully converted offline content to online content",
        validate_errors_title: "Converting content failed",
        processing: "Saving package ",
        collection_thumbnail_missing_name: "Collection thumbnail binary is missing 'filename'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Package '" + packageTitle + "' thumbnail binary is missing 'filename'";
        },
        package_value_missing_title_func: function () {
          return "Package is missing 'title'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Package '" + packageTitle + "' is missing '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return "Package '" + packageTitle + "' field '" + field + "' has following invalid values: " + invalidValues;
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Package '" + packageTitle + "' has invalid tag: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "Package '" + packageTitle + "' version is missing 'title'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Package '" + packageTitle + "' version '" + versionTitle + "' is missing '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Package '" +
            packageTitle +
            "' version '" +
            versionTitle +
            "' field '" +
            field +
            "' has following invalid values: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "Package '" + packageTitle + "' version '" + versionTitle + "' binary is missing 'filename'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Important: End of Life for Local Collections in Tekla Warehouse",
            part1: "Starting on June 2, 2025, local collections will no longer be available in Tekla Warehouse. Users will no longer be able to create, view, or search local collections or use content from local collections.",
            part2: "For assistance with the transition from local collections to online collections, please contact your local support organization.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Important! As a security enhancement, you must have the Tekla Warehouse Content Editor role to manage your organization's content after November ${date}, 2024. <br />Please ask your organization's administrator to assign the role to you. For more information, see the <a href="${productBulletinUrl}" target="_blank">product bulletin</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `As a security enhancement, you must have the Tekla Warehouse Content Editor role to manage your organization’s content after November ${date}, 2024. <br />Please ask your organization's administrator to assign the role to you. For more information, see the <a href="${productBulletinUrl}" target="_blank">product bulletin</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Don't see an edit option for your organization? After November ${date}, you must have the Tekla Warehouse Content Editor role to manage your organization's content.<br /> You can check your role under My Warehouse > My Details. For more information, see the <a href="${productBulletinUrl}" target="_blank">product bulletin</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Don't see an option to create content for your organization? After November ${date}, you must have the Tekla Warehouse Content Editor role to manage your organization's content. <br />You can check your role under My Warehouse > My Details. For more information, see the <a href="${productBulletinUrl}" target="_blank">product bulletin</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `After November ${date}, you must have the Tekla Warehouse Content Editor role to manage your organization's content. Please ask your organization's administrator to assign the role to you. <br />You can check who is your organization's administrator in your Tekla Online Profile. For more information, see the <a href="${productBulletinUrl}" target="_blank">product bulletin</a>.`
            },
          },
          service_plugin_update_info:
            'Having issues connecting to Tekla Structures? Make sure that you are running the latest <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="blank" rel="noopener noreferrer">Tekla Warehouse Service plugin</a>.',
          upload_delay_info:
            "There are delays in uploads due to the virus scanner quota limits. No need to upload several times, the original upload will become visible when it has gone through the virus scanner.",
          version_creation_stuck_info:
            "New versions cannot be created at the moment. We are currently investigating the issue, please try again later.",
        },
        service_plugin_info:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
          "Tekla Warehouse Service plugin</a> has been updated. Please download the newest version to unlock all features.",
        maintenance_info:
          "Tekla Warehouse is under maintenance during 2018-07-27 21:00:00 - 00:00:00 UTC. We are sorry for the inconvenience.",
        release_notes_info: "New features in Tekla Warehouse",
        release_notes_link:
          'Tekla Warehouse has been updated, read <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">what is new</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Need a Tekla developer for your project?",
        heading2Dev:
          'Check the directory called Tekla Development Experts.<br><br>It\'s a place where talented programmers and interested Tekla users can get together.<br><br>All Tekla Partners Program members are welcome to add their information to the Experts directory.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Find a developer &rsaquo;</a>',
        experts_campaign_title_september: "Voting is over",
        experts_campaign_description_september:
          '<b>Tekla Developer Awards 2020 winners announced soon</b><br><br>The jury will select one winner of the 10 participating Tekla apps from all over the world. The winner will receive a Mavic Pro drone. Besides that, the community have voted for their favorite application.<br><br>Stay tuned. The winners will be announced soon.<br><br><a href="https://www.tekla.com/bim-awards/developer-awards" target="_blank">Learn more &rsaquo;</a>',
        experts_campaign_title_october: "Tekla Developer Awards 2020 winner selected!",
        experts_campaign_description_october:
          'The jury has now selected the winner of Tekla Developer Awards 2020. The top prize goes to the <b>Bolt Clearance Check</b> developed by JMT Consultants. Congratulations!<br><br>In addition, Tekla users and developers around the world voted for their favorite Tekla application and <b>ASK IDEA-Tekla Link</b> got the most votes!<br><br>Thank you to all the participants and voters.<br><br><a href="https://www.tekla.com/bim-awards/bolt-clearance-check" target="_blank">Check the winner &rsaquo;</a>',
        seeking_experts_for_your_project: {
          title: "Seeking a developer for your coding project?",
          description:
            '<p>Browse the Tekla Development Experts - a directory of companies with expertise in Tekla API programming.</p><p>We invite all members of the Tekla Partners Program to include their information in the Experts directory.</p><p><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Find a developer &rsaquo;</a></p>',
        },
        open_api_fundamentals: {
          title: "Take a free API certificate course",
          description:
            '<p>Tekla Structures Open API Fundamentals Certification course is now available at learn.trimble.com.</p><p>If you pass the eLearning course and tests, you will earn a certificate. With the certificate, you prove your coding skills to your professional network.</p><p><a href="https://learn.trimble.com/learn/learning_plan/view/453/tekla-open-api-fundamentals" target="_blank">Get Tekla Open API certificate &rsaquo;</a></p>',
        },
        developer_awards_2022: {
          title: "The winners have been selected!",
          description:
            "<p>The jury has selected the winner of the Developer category of the 2022 Tekla Global BIM Awards. The winner is ScaffPlan from Australia!</p>" +
            "<p>Congratulations also to other category winners!</p>" +
            "<p>Thank you to all the participants and voters.</p>" +
            "<p><a href='https://www.tekla.com/resources/news/trimble-announces-tekla-global-bim-awards-2022-winners' target='_blank'>Read more &rsaquo;</a></p>",
        },
        trimble_0_60: {
          title: "Calling all entrepreneurs, developers and early-stage startups!",
          description:
            "<p>Join the Trimble 0-60 Challenge, a global initiative designed to identify and support the most innovative startups in the AECO industry.</p>" +
            "<p>Apply now and take your startup to the next level!</p>" +
            "<p><a href='https://www.trimble.com/en/our-company/events/dimensions/experiences/0-60-challenge' target='_blank'>Join the challenge &rsaquo;</a></p>",
        },
        static_intro:
          "This is the Tekla Structures BIM storage where you can find, import, install and share content both internally and globally. Tekla Warehouse aims to make it easy to produce high quality models efficiently.",
        new_intro:
          'Tekla Corporation is now called Trimble. We continue developing Tekla software solutions and the same people are there for you. Learn more: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Please verify your account",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Newest uploads &rsaquo;",
          popular: "Popular content &rsaquo;",
          recommended: "Recommended &rsaquo;",
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">News &rsaquo;</a>',
          item_title: "This is news!",
          item_description:
            'Some news related text, some news related text, some news related text, some news related text, some news related text, some news related text.<br><a href="https://developer.tekla.com" target="_blank">Read more &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse in numbers",
          page_views: "Page views",
          sessions: "Sessions",
          users: "Users",
          organizations: "Organizations",
          collections: "Collections",
          packages: "Packages",
        },
        partners: "Our partners &rsaquo;",
        action_links: {
          search_title: "Find content",
          upload_title: "Share content",
        },
        videos: {
          title_text: "Streamline your work with Tekla Structures",
          description:
            "Tekla Structures, the most advanced structural BIM software, lets you create, combine, manage and share multi-material 3D models packed with valuable construction information.<br><br>You can use Tekla Structures throughout the project, from conceptual planning to fabrication, construction and maintenance, for design, detailing and information management.",
          url: "https://www.youtube.com/embed/0IbAwIU_WgU?autoplay=1",
          img: "https://i.ytimg.com/vi/0IbAwIU_WgU/hqdefault.jpg",
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return 'Check your email for verification link, or <u><a href="' + settingsUrl + '">order new link</a></u>.';
        },
        verifyAccountAcceptTerms:
          "Please verify your email address and accept the terms and conditions and the privacy policy",
        acceptTerms: "Please accept the terms and conditions and the privacy policy",
        acceptUpdatedTerms: "Please approve the updated tekla online services terms and conditions and privacy policy",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Return to your <u><a href="' + settingsUrl + '">profile</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Review and accept the Terms and conditions and the Privacy policy in your <u><a href="' +
            settingsUrl +
            '">profile</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Browse collections &rsaquo;",
          new: "New",
          popular: "Popular",
          recommended: "Recommended",
          organizations: "Organizations",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Select folder",
        selectFolderInstructions: "Local and network folders can be used as Collections.",
        selectedFolder: "Selected folder (copy-paste location):",
        selectedFolderPlaceholder: "E.g. C:\\folder, Z:\\network folder, \\\\machine-name\\folder",
        collectionCreation: {
          name: "Name",
          createNewCollection: "Create local collection",
          pleaseInputTitle: "Please input a title",
          invalidPath: "Invalid path",
          access_denied: "Failed to add collection: access denied",
          unsafe_location: "Failed to add collection: unsafe location",
          addCollectionFailed: "Failed to add collection",
          minLengthError: "Title is required to be at least 3 characters",
          maxLengthError: "Title cannot be longer than 20 characters",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Local and network folders can be used as Collections.",
        collectionCreation: {
          name: "Name",
          addCollectionFailed: "Failed to add collection",
          createNewCollection: "Create online collection",
          pleaseInputTitle: "Please input a title",
          minLengthError: "Title is required to be at least 3 characters",
          maxLengthError: "Title cannot be longer than 20 characters",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Resource saved",
        },
        survey: {
          give_us_feedback: '<span style="font-size: 1.1em">Give us feedback</span>',
          do_you_see_potential: '<span style="font-size: 1.1em">Do you see the potential?</span>',
          help_us_improve_html:
            'Help us improve Tekla Warehouse! <br/><a id="showSurvey">Click here</a> or <a id="dontShow">don\'t show this again</a>',
          dialog: {
            do_you_see_potential: "Do you see the potential?",
            help_us_improve_html: "Help us improve Tekla Warehouse! Your opinion is important to us.",
            continue_to_survey: "Start survey",
            exit_to_warehouse: "No thanks",
          },
        },
      },
      search: {
        bar: {
          search: "Search",
          filter: " ",
          more_filters: "More filters",
          hide_filters: "Hide filters",
          clear: "Clear",
          exclude: "Exclude",
          include: "Include",
          clear_filters: "Reset filters",
          load_defaults: "Use my preferences &rsaquo;",
          save_defaults: "Save as my preferences &rsaquo;",
          defaults_saved_successfully: "Default filters saved",
          edit_defaults: "Edit defaults",
        },
        info: {
          searching_organizations: "Searching in organizations...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["No results", amountTotal + " results"]);
            } else {
              return (
                pluralize(amountTotal, ["No results", amountTotal + " results"]) +
                ' for "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["No results", amountTotal + " results"]);
            } else {
              return (
                pluralize(amountTotal, ["No results", amountTotal + " results"]) +
                ' for "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " results in online content",
          local: " results in local content",
          organization: "results in organizations",
          plugin_missing_info:
            'Local content is only shown if you have <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Tekla Warehouse Service plugin" +
            "</a>" +
            " installed.",
          version_filter_info: "Software version filtering is not valid in local content results",
        },
        exclude_env_files: "Exclude environment files",
        sidebar: {
          filters: {
            measurementUnits: "Measurement units",
            locations: "Project locations",
            languages: "Languages",
          },
          placeholders: {
            use_categories: "Category",
            measurement_units: "Measurement Units",
            languages: "Languages",
            locations: "Locations",
          },
        },
      },
      placeholders: {
        name: "Name...",
        contentOwner: "Content owner name...",
        useCategory: "Select use",
        selectType: "Select type",
        filter: "Filter...",
        search: "Search...",
        choose: "Choose...",
        searchFilters: "Search filters...",
        selectContentItem: "Select content item...",
        search_content: "Search content...",
        includeAs: "Include as...",
        selectCollection: "Select collection",
        selectUserGroup: "Select user group",
        online_collections_tooltip: "Click to search online content",
        local_collections_tooltip:
          "Click to search local and network content and content included with Tekla Structures installation",
        organizations_tooltip: "Click to search organizations",
        "filter-options": {
          products: "All software products",
          locations: "All locations",
          languages: "All languages",
          measurementUnits: "All measurement units",
          testedVersions: "All software versions",
          useCategories: "All categories",
          useCategoriesCombined: "Category",
          allGroups: "All groups",
          itemTypeCategories: "All item types",
        },
        "exclude-filter-options": {
          products: "Exclude software products",
          locations: "Exclude locations",
          languages: "Exclude languages",
          measurementUnits: "Exclude measurement units",
          testedVersions: "Exclude software versions",
          useCategories: "Exclude categories",
          useCategoriesCombined: "Exclude Category",
          allGroups: "Exclude groups",
          itemTypeCategories: "Exclude item types",
        },
      },
      link: {
        link_selected: "Link selected",
        unlink_selected: "Unlink selected",
      },
      download_install: {
        choose_version: "Choose your software version:",
        choose_content: "Choose content items from list below:",
        choose_action: "Choose action for selected items:",
      },
      download: {
        all_versions: "All versions",
        copy_to_clipboard: "Copy to clipboard",
        count_info: " items will be installed using the Tekla Warehouse Plugin.",
        download_selected: "Download",
        download_started: "Download has been started",
        add_selected_items_to_basket: "Add selected to My cart",
        add_item_to_basket: "Add item to My cart",
        add_all_items_to_basket: "Add all to cart",
        add_all_items_to_basket_info: "There are over 100 items. Loading may take some time",
        add_all_items_to_basket_too_many_results: "You cannot add over 1000 items",
        cart_loading: "There are over 100 items. Loading may take some time",
        remove_item_from_basket: "Remove from cart",
        shopping_cart_no_content: "No content in cart",
        shopping_cart_no_content_for_version: "No content in cart for the selected version",
        download_error: "Error occurred on download",
        shopping_cart_items_func: function (itemsCount) {
          return "My cart (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Download instructions",
          step_one: "Copy the download information to your clipboard with this button",
          step_two_func: function (downloadAppUrl) {
            return (
              "Run the Tekla Warehouse Downloader application. You can download the application " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">here.</a> '
            );
          },
          step_three: "The Tekla Warehouse Downloader application will guide you through the rest of the download.",
          show_data_title: "Didn't work?",
          show_data_to_be_copied: "Copy manually",
          hide_data_to_be_copied: "Hide download data",
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Download for selected " + totalInstallsCount + " items";
        },
      },
      install: {
        install_title: " items will be installed using the Tekla Warehouse Plugin.",
        failed_install_information:
          "You have selected content that is available for downloading only. This content will not be installed now. Please download this content separately from Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Install: please verify your Tekla Warehouse Service plugin is running correctly",
        verify_ts_connection: "Install: please verify Tekla Structures is running",
        select_software_version: "Filter by software version in order to link, download or install",
        download_requires_maintenance: "Access to some items below requires Tekla Maintenance",
        install_selected: "Install",
        start_install: "Start installation",
        install_started: "Installation has been started",
        select_ts_version: "Select TS version",
        install_retry: "Please start again from Tekla Warehouse if a retry is needed",
        failed_installs_info_func: function (failedCount) {
          return "Content that will not be installed (" + failedCount + " versions):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Installation for selected " + totalInstallsCount + " items";
        },
      },
      helpers: {
        behalf: "By",
        sortBy: "Sort by:",
        filter: "Filter results",
        showAsThumbs: "Show as thumbnails",
        showAsList: "Show as list",
        resultsPerPage: "Show per page:",
        filter_by_version: "Filter by version:",
        show_more_options: "More options",
        show_less_options: "Less options",
        filter_by_type: "Filter by type:",
        select_all: "Select all",
        unselect_all: "Unselect all",
        show_content_items: "Show content items",
        choose_action: "Choose action for selected content: ",
        show_collections: "Show collections",
        geolocation: "Select geolocation",
        search: {
          use_personalized_search: "Use personalized search",
          show: "Show:",
          show_content_items: "Content items",
          show_collections: "Collections",
          show_organizations: "Organizations",
          show_online_content: "Online content",
          show_offline_content: "Offline content",
          local_content_info:
            'Local content is only shown if you have <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " plugin installed.",
        },
        tekla_maintenance_required: "Access to some items below require Tekla Maintenance",
        show_content_alerts: "Show content alerts ",
        show_collection_alerts: "Show collection alerts ",
      },
      actions: {
        load_more: "Load more",
        use_infitinite_scroll: "Use infinite scroll",
        back: "Back",
        show_download_dialog: "Download",
        download: "Just download",
        download_on_partner_site: "Download on partner site",
        insert: "Insert into model",
        install: "Install",
        downloadAndInstall: "Install",
        createNew: "Create new",
        viewItems: "View",
        viewCollection: "View collection",
        viewGroup: "Show group",
        joinGroup: "Join group",
        loginToDownload: "Log in to download",
        show3DPic: "Show 3D model",
        remove: "Remove",
        browse: "Browse",
        browseFolder: "Browse",
        browseFileSystem: "Browse",
        add: "Add",
        save: "Save",
        batch_edit: "Batch edit",
        translations: "Translations",
        cancel: "Cancel",
        continue: "Continue",
        delete: "Delete",
        addNetworkPath: "Add network location",
        select: "Select",
        create: "Create",
        update: "Update",
        choose_thumbnail: "Choose thumbnail",
        choose_picture: "Choose picture",
        remove_picture: "Delete picture",
        choose_3D_picture: "Choose 3D model",
        remove_3D_picture: "Remove 3D model",
        like: "Like",
        unlike: "Unlike",
        close: "Close",
        export: "Export",
        add_or_remove: "Add / Remove",
        select_content_to_convert: "Select offline content",
        search: "Search",
        upload: "Upload",
        edit: "Edit",
        view_3d: "Click to view model...",
        search_invalid_packages: "Search packages",
        search_infected_binaries: "Search infected binaries",
        delete_selected: "Delete selected",
        hide_selected: "Hide selected",
        unhide_selected: "Unhide selected",
        transfer_selected: "Move selected",
        mark_as_ok: "Update selected as clean",
        rescan_selected: "Rescan selected binaries",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "beta",
        search: "Search",
        profile: "My details",
        myWarehouse: "My Warehouse",
        myStuff: "My stuff",
        logout: "Sign out",
        login: "Sign in",
        register: "Register",
        upload: "Upload",
        uploading: "Upload",
        dashboard: "Dashboard",
        learnMore: "Learn more",
        about: "About",
        shoppingCart: "My cart",
        addToCartInfo:
          "You can collect items to My Cart and download or install them all at once. Downloading is fully free of charge",
      },
      cubeNavigation: {
        headings: {
          get_started: "Get started",
          learn: "Learn",
          get_help: "Get help",
          extend_your_tekla: "Extend your Tekla",
          collaborate: "Collaborate",
          students_and_educators: "Students and educators",
          about: "About",
        },
        "tekla-online": "Online services",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Tekla Website",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "My Profile",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "TEKLA SERVICES HOME",
        register: "Register",
        login: "Sign in",
        teklaWebinars: "Tekla Webinars",
        localSupport: "Local Support",
        teklaMaintenance: "Tekla Maintenance",
        teklaModelSharing: "Tekla Model Sharing",
        trimbleConnect: "Trimble Connect",
        evaluate: "Evaluate",
        buy: "Buy",
        download: "Download",
        teklaTrainings: "Tekla Trainings",
        teklaAbout: "Tekla",
        teklaProducts: "Products",
        teklaServices: "Services",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "https://www.tekla.com/services",
        evaluate: "https://tekla.com/contact/offices-and-resellers",
        buy: "https://tekla.com/contact/buy-tekla",
        teklaTrainings: "https://tekla.com/services",
        teklaWebinars: "https://tekla.com/about/webinars",
        localSupport: "https://support.tekla.com/contact-us",
        teklaMaintenance: "https://tekla.com/maintenance",
        trimbleConnect: "https://www.tekla.com/products/trimble-connect",
        teklaModelSharing: "https://tekla.com/products/tekla-model-sharing",
        teklaAbout: "https://tekla.com/about",
        teklaProducts: "https://tekla.com/products",
        teklaServices: "https://tekla.com/services",
      },
      subNavigation: {
        myCollections: "My collections",
        myLikes: "My likes",
        profile: "My details",
        preferences: "My preferences",
        myUserGroups: "My user groups",
        localCollections: "Local and network collections",
        tsCollections: "Tekla Structures collections",
        downloads: "Downloads",
        selectContent: "Select content files",
        addMetadata: "Add metadata",
        onlineCollections: "Online collections",
        userGroups: "User groups",
        createNew: "Add content",
        notifications: "Notifications",
        contentUsage: "Content usage",
        browseCollections: "Browse collections",
        admin: "Admin",
        sales: "Sales",
        shoppingCart: "My cart",
      },
      notifications: {
        title: "notification",
        invitedBy: "Invited by",
        invitationType: "Invitation type",
        invitedTo: "Invited to",
        createdAt: "Created at",
        action: "Action",
        acceptInvitation: "Accept",
      },
      batch_edit: {
        selectActionType: "Select action type",
        edit_content: "Edit content metadata",
        content_title: "Content",
        metadataToChange: "Metadata to change",
        selectContent: "Content to change",
        select_action: "Select action",
        support: {
          support: "Support",
          licenses: "Licenses",
        },
        versions: {
          version: "Version",
          prerequisities: "Prerequisities and requirements",
        },
        content: {
          current_organization: "Current organization:",
          select_organization: "Select organization",
          loading_org_collections: "Loading collections for organization",
          select_collection: "Select collection",
          cancel_select_organization: "Cancel",
          show_collection: "Show:",
          show_all_content: "All collections",
          show_organization_content: "My organization collections",
          select_all: "Select all",
          select_all_info: "(This might take a while)",
          actionOptions: {
            add: "Add",
            remove: "Remove",
            replace: "Replace with",
            yes: "Yes",
            no: "No",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "No attributes selected",
            invalid_attributes: "Invalid attribute values",
            invalid_actions: "Invalid actions",
            content_not_selected: "No content selected",
            entities_not_selected: "Package related data provided but no packages selected",
            versions_not_selected: "Version related data provided but no versions selected",
          },
        },
        update: {
          info_processing: "Please keep this window open until all items have been processed.",
          info_processed: "All items have now been processed.",
          processing: "Processing item",
          processed: "Processed item",
          error_info: "There was a problem updating following items:",
          package_failure_func: function (package_title, errorMsg) {
            return "Content: " + package_title + ", error: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Content: " + package_title + ", version: " + version_title + ", error: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " items will be updated, do you want to continue?";
          },
        },
      },
      myUserGroups: {
        title: "User groups",
        createdByPrefix: "By:",
      },
      downloads: {
        title: "Downloads",
      },
      myCollections: {
        new_collection: "New collection",
        my_collections: "My collections",
        created_by_me: "Created by me",
        local_collections: "Local and network collections",
        online_collections: "Online collections",
        created_by_my_organization: "Created by my organization",
        created_by_my_organizations: "Created by my organizations",
        shared_with_me: "Shared with me",
        shared_with_me_or_organization: "Shared with me or my organization",
        notification: {
          error: "Error",
          error_loading_online_collections: "Error loading online collections",
          error_loading_packages_liked_by_me: "Error loading content liked by me",
          error_loading_my_organization_s_collections: "Error loading my organization's collections",
          error_loading_my_shared_collections: "Error loading my shared collections",
        },
        local: {
          helpText1: "To access local collections, please install ",
          helpText2: "Tekla Warehouse Service plugin",
          helpText3: ".",
          title: "Local and network collections",
          notFound: "No local collections found yet.",
          myCollectionsTitle: "My Collections",
          actions: {
            createNew: "Create local or network collection",
          },
        },
      },
      organization: {
        news_feed: "News feed",
        add_comment: "Add feed text",
        edit_comment: "Edit news item",
        subTabs: {
          collections: "Collections",
          analytics: "Analytics",
          contact_and_support: "Contact",
          news_feed: "News feed",
        },
        edit: {
          edit_title: "Edit title",
          edit_description: "Edit description",
          edit_contact: "Edit contact information",
          edit_logo: "Edit logo",
          edit_url: "Edit url",
          edit_email: "Edit email",
          edit_phonenumber: "Edit phonenumber",
          edit_warehouse_url: "Edit Warehouse url",
          edit_displayname_no_link: "Organization display name can be edited in Tekla Online Admin Tool",
          edit_displayname: function (url) {
            return (
              "Organization display name can be edited in <a href=" +
              url +
              ' target="_blank">Tekla Online Admin Tool</a>'
            );
          },
          edit_warehouse_url_subtitle: "Alphabets, no scandinavian characters, numbers or special characters",
          edit_url_subtitle: "Insert complete url",
          edit_logo_descriptive: "Optimal image size approximately 350 * 200 pixels",
          edit_facebook_url: "Edit Facebook url",
          edit_twitter_url: "Edit Twitter url",
          edit_linkedin_url: "Edit LinkedIn url",
          edit_youtube_url: "Edit Youtube url",
          edit_googleplus_url: "Edit Google+ url",
          logo_updated: "Logo updated",
          logo_update_failed: "Logo update failed",
          logo_remove_failed: "Logo remove failed",
          logo_removed: "Logo removed successfully",
          updated_successfully: "Updated successfully",
          updated_failed: "Update failed",
          add_comment: "Add new",
          edit_comment: "Edit news item",
          comment_added: "News feed item added successfully",
          comment_updated: "News feed item updated successfully",
          comment_deleted: "News feed item deleted successfully",
          comment_add_failed: "Failed to add news feed",
          comment_update_failed: "Failed to update news feed item",
          comment_delete_failed: "Failed to delete news feed item",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Search online content by " + name + " &rsaquo;";
        },
        contact_information: "Contact us",
        website: "Website &rsaquo;",
        show_newer_news: "Newer",
        show_older_news: "Older",
      },
      online_collections: {
        new_collection: "New collection",
        online_collections: "Online collections",
        created_by_organizations: "Created by organizations",
        created_by_users: "Created by users",
      },
      local_collections: {
        tab_title: "Content",
        local_collections: "Local collections",
      },
      versions: {
        add_tool: "Add application",
        table_header: {
          name: "Name",
          type: "Type",
          platform: "Platform",
          modified_at: "Modified at",
          size: "Size",
          virus_scan: "Virus scan",
        },
        // placeholders
        select_file_type: "Select file type",
        select_platform: "Select platform",
        select_unit: "Select unit",
        select_quality: "Select quality",
        what_will_be_added: "What do you want to add?",
        select_files_from_your_disk: "Select files from your disk",
        add: "Add",
        //content management
        add_application: "Add application",
        select_content_from_tekla_structures: "Select content from Tekla Structures",
        or: "or",
        files: "Files",
        //version metadata
        version_information: "Version information",
        version_display_name: "Version display name",
        version_number: "Version number",
        version_number_info:
          "Enter a version number. You cannot change the version number after it is saved.",
        existing_versions: "Existing version numbers for this content",
        version_number_validation: {
          missing: "You must enter a version number",
          invalid:
            "The version number must follow the rules of semantic versioning (MAJOR.MINOR.PATCH)",
          already_in_use: "This version number is already in use for this content.",
        },
        select_update_type: "To generate a version number, first select the update type",
        please_enter_a_version_name_or_number: "Please enter a version name or number.",
        virus_scan: {
          soft_failed: "Failed",
          hard_failed: "Not scanned",
          infected: "Infected",
          rescan: "Rescan",
          scan_in_progress: "Scan in progress",
          scan_started: "Virus scan started",
        },
      },
      collections: {
        by: "By:",
        edit: {
          edit_collection: "Edit collection",
          edit_description: "Edit description",
        },
        notification: {
          collection_updated: "Collection updated",
          error: "Error",
          error_loading_other_users_collections: "Error loading other users' collections",
          error_loading_other_organizations_collections: "Error loading other organizations' collections",
          error_loading_collection_contents: "Error loading collection contents",
          members_list_updated: "Members list updated!",
          invitations_removed: "Invitations removed!",
          collection_removed: "Collection removed",
          thumbnail_deleted: "Thumbnail deleted",
          wrong_file_type: "Wrong file type.",
          invalid_file_type: "Invalid file type.",
          selected_image_is_too_big: "Selected image is too big.",
          thumbnail_updated: "Thumbnail updated",
          default_thumbnail_updated: "Default thumbnail updated",
          default_thumbnail_update_failed: "Default thumbnail update failed",
          thumbnail_added: "Thumbnail added",
          thumbnail_update_failed: "Thumbnail update failed",
          video_added: "Video added",
          video_adding_failed: "Failed to add video",
          video_deleted: "Video deleted",
          video_deleting_failed: "Failed to delete video",
        },
        confirm: {
          delete_all: function (title) {
            return 'Delete collection and all content in collection  "' + title + '"?';
          },
          remove_members: "Are you sure?",
          remove_invitations: "Are you sure?",
        },
        useNetworkDrive: "Use network drive or folder",
        createCollection: "Create online collection",
        createCollectionInstructions: "Create a collection to group your content.",

        actions: {
          delete_thumbnail: "Delete thumbnail",
          edit_collection: "Edit collection",
          delete_collection: "Delete collection",
          add_content: "Add content",
        },
        subTabs: {
          content: "Content",
          members: "Show members",
          analytics: "Analytics",
          manage_access: "Manage access",
        },

        members: {
          add_user_groups: "Add user groups",
          select_user_group: "Select user group",
          with_role: "with role",
          user_groups_added: "User groups added!",
          members_list_updated: "Members list updated!",
          update_selected_members: "Update selected members",
          table_header: {
            member_type: "Member type",
            member_name: "Member name",
            email: "Email",
            role: "Role",
            joined_at: "Joined at",
            invited_at: "Invited at",
          },
          new_role: "New role",
        },
        member_list: {
          users_who_can_access_this_collection: "Users who can access this collection",
          add_user_group: "Add user group",
          update_members: "Update members",
          remove_members: "Remove members",
          pending_invitations: "Pending invitations",
          user: "user",
          invite_member: "Invite member",
          update_invitations: "Update invitations",
          remove_invitations: "Remove invitations",
        },
        labels: {
          select_organization: "Select organization",
          select_visibility: "Select visibility",
          title: "Name",
          name: "Name",
          createAs: "Create as: ",
          asMyself: "Myself",
          asUserGroup: "User group",
          asOrganization: "Organization",
          selectOwner: "Select organization for content owner",
          visibility: "Visibility",
          visibilityOptions: {
            public: "Public",
            private: "Private",
          },
          description: "Description",
        },
        new_collection: {
          please_input_a_username: "Please input a username",
          title_is_required_to_be_at_least_3_characters: "Title is required to be at least 3 characters",
          title_cannot_be_longer_than_20_characters: "Title cannot be longer than 20 characters",
        },
        collection_list: {
          no_content: "No collections.",
          results_summary: function (number) {
            return "" + number + "  collection " + pluralize(number, ["items", "item", "items"]);
          },
        },
        entity_list: {
          no_content: "No content in the collection.",
          results_summary: function (number) {
            return "" + number + " content " + pluralize(number, ["items", "item", "items"]);
          },
          linked_content: "Linked content",
          unlink: "Unlink content",
        },
      },
      alerts: {
        no_updates: "Not updated recently",
        notifications_marked_as_read: "Notification marked as read",
        cleared_alerts: "All notifications cleared",
        alert_by_email:
          "Send me e-mail when any content listed below is updated with new version, or a collection is updated with new content",
        action_types: {
          create: "was created",
          update: "was edited",
          added: "was added",
          delete: "was deleted",
          added_entity: "version was added",
          removed_entity: "version was removed",
          added_package: "package was added",
          removed_package: "package was removed",
          added_collection: "content was added",
          removed_collection: "content was removed",
        },
        property_names: {
          description: "Details",
          searchCriteria: "Search criteria information",
          groupCategory: "Group and category information",
          support: "Support information",
          licenses: "Licenses information",
          relatedContent: "Related content information",
          contentDownloadUrl: "Partner site link",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Updated " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Notification preferences updated successfully",
          preferences_update_failed: "Error occurred while updating notification preferences",
        },
      },
      browseDialog: {
        useNetworkDrive: "Use network drive or folder",
        ownerGroups: {
          title: "Owner groups",
        },
      },
      itemTypeCategories: {
        all: "All groups",
        application: "Applications",
        "3dProduct": "3D products",
        customComponent: "Custom components",
        tool: "Applications",
        profile: "Profiles",
        material: "Materials",
        bolt: "Bolts",
        rebar: "Rebars",
        reportTemplate: "Report templates",
        drawingSetup: "Drawing setup files",
        modelSetup: "Model setup files",
        environmentFile: "Environment files",
        modelTemplate: "Model templates",
        shape: "Shapes",
      },
      platforms: {
        WIN_X86: "32-bit",
        WIN_X64: "64-bit",
        ANY: "Platform independent",
      },
      platformsDownload: {
        WIN_X86: "32-bit",
        WIN_X64: "64-bit",
        ANY: " ",
      },
      contentItemSource: {
        other: "General - to Model folder",
        run: "Run tool",
        bolt: "Bolt assembly",
        component: "Component",
        drawing: "Drawing setting (BETA)",
        material: "Material",
        profile: "Profile",
        mesh: "Reinforcement mesh",
        rebar: "Reinforcement bar",
        shape: "Shape",
        geometry: "Geometry",
        modeltemplate: "Model template",
        cloningtemplate: "Cloning template",
        noaction: "No action",
        releasenotes: "Release notes",
        tsep: "Tsep file",
      },
      contentItemHeader: {
        bolt: "Bolt assemblies",
        component: "Components",
        drawing: "Drawing settings (BETA)",
        material: "Materials",
        profile: "Profiles",
        mesh: "Reinforcement meshes",
        rebar: "Reinforcement bars",
        shape: "Shapes",
      },
      useCategoryGroups: {
        workflow: "Workflow",
        structures: "Structures",
        drawings: "Drawings",
        modeling: "Modeling",
      },
      useCategories: {
        title: "Used in/for",
        conceptualDesign: "Conceptual design",
        designEngineeringAnalysis: "Design / Engineering / Analysis",
        detailing: "Detailing",
        fabrication: "Fabrication",
        erectionInstallationPour: "Erection / Installation / Pour",
        contracting: "Contracting",
        scheduling: "Scheduling",
        takeOffEstimation: "Take off / Estimation",
        concreteStructures: "Cast in place concrete",
        precastConcrete: "Precast concrete",
        offshore: "Offshore",
        steelStructures: "Steel structures",
        timberStructures: "Timber structures",
        reinforcement: "Reinforcement",
        drawingCreation: "Drawing creation",
        drawingManagement: "Drawing management",
        drawingEditing: "Drawing editing",
        drawingAnnotation: "Drawing annotation",
        viewSimulateNavigate: "View, simulate, navigate",
        compareReviewManageData: "Compare, review, manage data",
        softwareCollaboration: "Software Collaboration",
        productivity: "Productivity",
        interoperability: "Interoperability",
        systemSetup: "System setup",
      },
      measurementUnits: {
        "0": "Metric",
        "1": "Imperial",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Open BIM (IFC)",
        standalone: "Standalone",
        PF: "Tekla PowerFab",
        tedds: "Tekla Tedds",
        TSD: "Tekla Structural Designer",
      },
      testedVersions: {
        NVS: "Not version specific",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        TS2024: "TS 2024",
        TS2025: "TS 2025",
        PF_2022: "PF 2022",
        PF_2022i: "PF 2022i",
        PF_2023: "PF 2023",
        PF_2023i: "PF 2023i",
        PF_2024: "PF 2024",
        PF_2024i: "PF 2024i",
        PF_2025: "PF 2025",
        Tedds_2022: "Tedds 2022",
        Tedds_2023: "Tedds 2023",
        Tedds_2024: "Tedds 2024",
        Tedds_2025: "Tedds 2025",
        TSD_2022: "TSD 2022",
        TSD_2023: "TSD 2023",
        TSD_2024: "TSD 2024",
        TSD_2025: "TSD 2025",
      },
      qualityTags: {
        experimental: "Experimental",
        candidate: "Candidate",
        approved: "Approved",
        standard: "Standard",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
        win11: "Windows 11",
      },
      supportedLanguages: {
        en: "English",
        "zh-Hans": "Chinese - Simplified",
        "zh-Hant": "Chinese - Traditional",
        cs: "Czech",
        da: "Danish",
        nl: "Dutch",
        fi: "Finnish",
        fr: "French",
        de: "German",
        hu: "Hungarian",
        it: "Italian",
        ja: "Japanese",
        ko: "Korean",
        pl: "Polish",
        pt: "Portuguese",
        "pt-br": "Portuguese - Brasilian",
        ru: "Russian",
        es: "Spanish",
        sv: "Swedish",
        no: "Norwegian",
        other: "Other",
      },
      isoLangs: {
        ab: {
          name: "Abkhaz",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikaans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Albanian",
          nativeName: "Shqip",
        },
        am: {
          name: "Amharic",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Arabic",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonese",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Armenian",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Assamese",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avaric",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avestan",
          nativeName: "avesta",
        },
        ay: {
          name: "Aymara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azerbaijani",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Bashkir",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Basque",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Belarusian",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengali",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bosnian",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Breton",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Bulgarian",
          nativeName: "български език",
        },
        my: {
          name: "Burmese",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Catalan; Valencian",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Chechen",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa; Chewa; Nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Chinese, Simplified & Chinese",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Chinese - Simplified",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Chinese - Traditional",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Chinese - Taiwanese",
          nativeName: "國語",
        },
        cv: {
          name: "Chuvash",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Cornish",
          nativeName: "Kernewek",
        },
        co: {
          name: "Corsican",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Croatian",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Czech",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Danish",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi; Dhivehi; Maldivian;",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Dutch",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "English",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Estonian",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Faroese",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Fijian",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finnish",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "French",
          nativeName: "Français",
        },
        ff: {
          name: "Fula; Fulah; Pulaar; Pular",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Galician",
          nativeName: "Galego",
        },
        ka: {
          name: "Georgian",
          nativeName: "ქართული",
        },
        de: {
          name: "German",
          nativeName: "Deutsch",
        },
        el: {
          name: "Greek, Modern",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gujarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haitian; Haitian Creole",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Hebrew (modern)",
          nativeName: "עברית",
        },
        hz: {
          name: "Herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Hungarian",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonesian",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingue",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Irish",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Icelandic",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italian",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Japanese",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Javanese",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, Greenlandic",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Kashmiri",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Kazakh",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu, Gikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Kinyarwanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Kirghiz, Kyrgyz",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Korean",
          nativeName: "한국어",
        },
        ku: {
          name: "Kurdish",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kwanyama, Kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latin",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Luxembourgish, Letzeburgesch",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburgish, Limburgan, Limburger",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Lao",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Lithuanian",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-Katanga",
          nativeName: "",
        },
        lv: {
          name: "Latvian",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macedonian",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Malagasy",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Malay",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malayalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltese",
          nativeName: "Malti",
        },
        mi: {
          name: "Māori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Marathi (Marāṭhī)",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshallese",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongolian",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo, Navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Norwegian Bokmål",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "North Ndebele",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepali",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Norwegian Nynorsk",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Norwegian",
          nativeName: "Norsk",
        },
        ii: {
          name: "Nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "South Ndebele",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Occitan",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwe, Ojibwa",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Ossetian, Ossetic",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Panjabi, Punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pāli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Persian",
          nativeName: "فارسی",
        },
        pl: {
          name: "Polish",
          nativeName: "polski",
        },
        ps: {
          name: "Pashto, Pushto",
          nativeName: "پښتو",
        },
        pt: {
          name: "Portuguese",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Portuguese - Brazilian",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quechua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Romansh",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Romanian, Moldavian, Moldovan",
          nativeName: "română",
        },
        ru: {
          name: "Russian",
          nativeName: "русский",
        },
        sa: {
          name: "Sanskrit (Saṁskṛta)",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sardinian",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Northern Sami",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoan",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Serbian",
          nativeName: "српски језик",
        },
        gd: {
          name: "Scottish Gaelic; Gaelic",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Sinhala, Sinhalese",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Slovak",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Slovene",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somali",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Southern Sotho",
          nativeName: "Sesotho",
        },
        es: {
          name: "Spanish", //      'name':'Traditional / Spanish',
          nativeName: "español",
        },
        su: {
          name: "Sundanese",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Swahili",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Swati",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Swedish",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tajik",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Thai",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrinya",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibetan Standard, Tibetan, Central",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turkmen",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Tagalog",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tonga (Tonga Islands)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Turkish",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tatar",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Tahitian",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Uighur, Uyghur",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ukrainian",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Uzbek",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamese",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Walloon",
          nativeName: "Walon",
        },
        cy: {
          name: "Welsh",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Western Frisian",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Yiddish",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Yoruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Zhuang, Chuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Other",
          nativeName: "Other",
        },
      },

      details: {
        navigation: {
          content_information: "Content information",
          version_and_file_management: "Version and file management",
          details: "Details",
          versions: "Versions",
          analytics: "Analytics",
          statistics: "Statistics",
          manage_access: "Manage access",
          related_content: "Related",
          properties: "Properties",
          support: "Company and support",
          release_notes: "Release notes",
          comments: "User comments",
        },
        related_content: {
          other_content_title: "Other content you may like",
          related_content_title: "Content recommended by ",
          related_content_url: "Content url",
          add_related_content: "Add related content",
          add_related_content_info:
            "You can only add related online content to online content, and local content to local content",
          remove_related_content: "Remove",
          relatedContentUrlSubtitle: "Content id or complete url to content",
          updated_successfully: "Related content updated successfully",
          update_failed: "Error while updating related content",
          update_failed_invalid_id: "Provided content id is invalid",
        },
        actions: {
          move: "Move",
          copy: "Copy",
          delete: "Delete",
          add_comment: "Add comment",
          add_reply: "Add reply",
          edit_comment: "Edit comment",
          reply_comment: "Add reply",
          view_replies: "View replies",
          report_bad_comment: "Report bad comment &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Edit title or delete",
            title_or_move_delete: "Edit title, move or delete",
            thumbnail: "Edit thumbnail",
            description: "Edit description",
            groupandcategory: "Edit group and category",
            details: "Edit details",
            versions: "Edit versions",
            support: "Edit support information",
            release_notes: "Edit release notes",
            search_criteria: "Edit search criteria",
            batchedit_and_translations:
              "With Translation feature you can publish your content in multiple languages. Translated version of your content will be shown to users based on their selection of Warehouse UI language. With Batch edit you can edit multiple items at once. First, select a field and then items you want to edit",
          },
        },
        links: {
          link: "Link",
          select_collection_placeholder: "Select collection to link",
          linking_collections_label: "Collections linking to this",
          private_content_note: "Note: Private content will remain visible only to users with access to it.",
          share_on_social_media: "Share on:",
          report_bad_content: "Report bad content &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Report illegal content &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "By subscribing to alerts you agree to share your Tekla Account display name and organization name (if applicable) with the content owner.",
          alert_me: "Alert me",
          unsubscribe: "Unsubscribe",
          subscribed_successfully: "Subscribed successfully",
          unsubscribed_successfully: "Unsubscribed successfully",
          subscribers_label: "Subscribers",
        },
        reviews: {
          reviewers_label: "Reviewers",
        },
        comments: {
          replies: "Replies",
        },
        scan: {
          soft_failed_binaries_info: "Virus scan failed  ",
          hard_failed_binaries_info: "Virus scan could not be done ",
          infected_binaries_info: "Virus scan found an infection ",
          what_now: "What now?",
          contact_trimble: "Contact Trimble before uploading again",
          popup: {
            soft_failed_binaries_title: "Failed binaries",
            hard_failed_binaries_title: "Failed binaries",
            infected_binaries_title: "Infected binaries",
            soft_failed_binaries_info:
              "Virus scan for the files you have uploaded has failed. This is only temporary. Please remove the files and upload them again. All files must pass the virus scan before they become visible to other users.",
            hard_failed_binaries_info:
              "Virus scan could not be done. Please remove the files. All files must pass the virus scan before they become visible to other users.",
            infected_binaries_info:
              "Virus scan found an infection in the files you have uploaded. Please remove the files. All files must pass the virus scan before they become visible to other users.",
            package: "Package:",
            version: "Version: ",
            fileName: "Filename: ",
          },
        },
        status: {
          content_banned_info:
            "The content is blocked by the administrator. It is currently not available to other users except for the content owner. <a href='https://support.tekla.com/contact-us' target='_blank'>Contact support</a> for more information.",
          content_archived_info:
            "This content has been archived. Users without editing rights will be redirected to the front page if they try to visit the content via a direct link.",
          archived: "Archived",
        },
        modified_by: function (name) {
          return " by " + name;
        },
        created_by: function (name) {
          return "Created by " + name;
        },
        deleteEntity: function (title) {
          return "Delete " + title + "?";
        },
        userComments: "User comments",
        comment_added: "Comment added successfully",
        comment_updated: "Comment updated successfully",
        comment_deleted: "Comment deleted successfully",
        comment_add_failed: "Failed to add comment",
        comment_update_failed: "Failed to update comment",
        comment_delete_failed: "Failed to delete comment",
        comment_delete_confirmation: "Delete comment item?",
        name: "Name",
        details: "Details",
        contentOwner: "Creator",
        contentOwnerGroup: "Content owner group",
        contentGroup: "Content group",
        thumbnail: "Thumbnail",
        tooltip: "Tooltip",
        description: "Description",
        additional_information: "Additional information",
        images: "Product images",
        installationInfo: "Installation information",
        productCode: "Product code",
        copyrightInfo: "Copyright",
        productExpirationDate: "Product expiration date",
        helpUrl: "Website &rsaquo;",
        supportUrl: "Support &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "Release notes",
        supportForumUrl: "Discussion forum &rsaquo;",
        itemTypeCategories: "Group",
        useCategories: "Category",
        category: "Category:",
        group: "Group:",
        compatibleSoftwareProducts: "Compatible software products",
        compatibleOperatingSystems: "Compatible operating systems",
        locationRestrictions: "Product availability:",
        supportedLanguages: "Languages:",
        measurementUnits: "Measurement units:",
        tags: "Tags and keywords:",
        prerequisites: "Prerequisites:",
        releaseNotes: "Release notes:",
        versions: "Versions",
        testedVersions: "Compatible versions",
        qualityTag: "Quality - evaluated by owner",
        otherAttributes: "Other Attributes",
        labels: "Search criteria",
        supportInformation: "Support",
        updateInformation: "Updates",
        latestUpdate: "Latest update",
        totalDownloads: "Total downloads",
        licenses: "Licenses",
        content_commenting: "Content commenting",
        maintenanceRequired: "Download requires Tekla Maintenance",
        tekla_maintenance_content: "Tekla Maintenance Content",
        download_requires_tekla_maintenance: "Download requires Tekla Maintenance",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Updated successfully",
        update_failed: "Update failed",
        update_failed_invalid_tag: "Update failed: invalid tag",
        update_failed_details_max_length_exceeded: "Update failed: details too long",
        show_version_details: "More info",
        content_removed: "Content removed",
        no_versions_available:
          "No versions available. Updates can take a while to appear. Refresh your browser to see changes.",
        thumbnail_deleted: "Thumbnail deleted",
        thumbnail_updated: "Thumbnail updated",
        verify_service_plugin_is_running: "Please verify your Tekla Warehouse Service plugin is running correctly",
        verify_ts_connection: "Please verify Tekla Structures is running",
        requires_newer_versions: "Requires Tekla Structures 2021 and Tekla Warehouse Service 1.3.33.0 or newer",
        download_tos: {
          title: "Quality, support, and privacy",
          content_1: "Please ensure that the downloaded content complies with your quality standards. Trimble does not provide support and is not responsible for any third party content. Please contact the content provider directly for support.",
          content_2: "Your country and organization name may be published in the service and disclosed to the owners of the content for legitimate purposes such as keeping you updated about content modifications. You can unsubscribe from these notifications.",
          content_3: 'For Trimble content extensions, <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">Trimble terms</a> apply. Third party license terms may apply to third party content. See the <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Trimble Privacy Notice</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "This is the Tekla Structures BIM storage. Find, import, install, and share internally and globally. Produce high quality models efficiently.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse is a free online and local library for Tekla Structures modeling tools, intelligent parts and templates",
        },
        search: {
          title: "Search Tekla Warehouse",
          description:
            "Search for Tekla extensions, 3D parts, shapes, custom components, profiles, materials like steel and concrete grades, rebars, meshes, and templates",
        },
        about: {
          title: "Information about Tekla Warehouse",
          description:
            "Tekla Warehouse offers a free marketing channel for your products and gives you full control of your content",
          developers: {
            title: "Default devopers title",
            description: "Default developers description",
          },
          usage: {
            title: "Default usage title",
            description: "Default usage description",
          },
          getting_started: {
            title: "Default gettings started title",
            description: "Default gettng started description",
          },
          content: {
            title: "Default content title",
            description: "Default content description",
          },
          using_content: {
            title: "Default using content title",
            description: "Default using content description",
          },
          creating_content: {
            title: "Default creating content title",
            description: "Default creating content description",
          },
        },
        collection: {
          title: "Default collection title",
          description: "Default collection description",
        },
        my_warehouse: {
          title: "My Tekla Warehouse",
          description: "Manage your Tekla Warehouse content",
        },
      },
      upload: {
        actions: {
          cancel: "Cancel",
          previous: "&lsaquo; Previous",
          next: "Next &rsaquo;",
        },
        content: {
          add_content: "Add files",
        },
        collection: {
          heading: "Select or create collection",
          subHeading:
            "Collections group content. You can use online collections to publish your content or local collections to share content with your colleagues. You can also use local collections for private use.",
          loading: "Loading collections...",
          selectFromMyCollections: "Select from My Collections",
          description_of_the_collection: "Description",
          location_validation: {
            invalid: "Invalid path",
          },
          organization_missing: "Please select an organization.",
          title_validation: {
            missing: "Please enter a title.",
            too_short: function (length) {
              return "Title should be at least " + length + " characters.";
            },
            too_long: function (length) {
              return "Title cannot be more than " + length + " characters long.";
            },
          },
        },
        package: {
          basic_information: "Basic information",
          code_name: "Code name",
          code_name_info:
            "Enter a code name as a unique identifier for this content. The code name can include lowercase letters, numbers, and these special characters: _ - @. You cannot change the code name after it is saved.",
          code_name_validation: {
            missing: "You must enter a code name",
            wrong_format: "The code name can include lowercase letters, numbers, and these special characters: _ - @ ",
            not_unique: "This code name is already in use. You must enter a unique code name.",
          },
          search_criteria: "Search criteria",
          support: "Support",
          title: "Title",
          thumbnail: "Thumbnail",
          "3D_pic": "3D model",
          title_validation: {
            missing: "Please enter a title.",
            too_short: function (length) {
              return "Title should be at least " + length + " characters.";
            },
          },
          description_validation: {
            missing: "Please enter a description.",
            too_short: function (length) {
              return "Description should be at least " + length + " characters.";
            },
          },
          wrong_file_type: "Wrong file type.",
          selected_image_is_too_big: "Selected image is too big.",
        },
        version: {
          select_version: "Select version",
          select_quality: "Select quality",
          versions: "Versions",
          displayNameNew: "Version display name",
          copy_from_version: "Copy metadata from existing version (optional)",
          displayName: "Display name",
          content_information: "Content information",
          back_to_content_information: "&lsaquo; Back to content information",
          version_and_file_management: "Version and file management",
          there_s_no_versions_yet: "There's no versions yet.",
          create_a_version_to_add_files: "Create a version to add files.",
          add_version: "Add version",
          version_information: "Version information",
          delete_version: "Delete version",
          no_versions_available: "No versions available. Updates can take a while to appear.",
          update_start_title_html: '<div class="spinner spinner-white">Updating</div>',
          update_start_message: "This might take a while.",
          version_was_created: "Version was created.",
          version_was_created_with_delay: "Version was created. Changes might be visible after a few minutes.",
          version_was_updated: "Version was updated.",
          version_was_deleted: "Version was deleted",
          version_add_failed: "Failed to add version",
          version_update_failed: "Failed to update version",
          file_is_too_big: function (fileName) {
            return "File '" + fileName + "' is too big. Max file size is 500 megabytes";
          },
          unallowed_file_type: function (fileName) {
            return "File '" + fileName + "' has unallowed file type.";
          },
          version_was_deleted_with_delay: "Version was deleted. Changes might be visible after a few minutes.",
          confirm_delete: function (title) {
            return 'Delete version "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Package was uploaded.",
          couldn_t_create_a_package: "Couldn't create a package. Changes will be rolled back.",
          uploading_title_html: '<div class="spinner spinner-white">Uploading</div>',
          uploading_text: "This might take a while.",
          select_organization: "Select organization",
          collection_visibility: "Collection visibility:",
          actions: {
            create: "Create",
            publish: "Publish",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "This content will be visible to all users with access to the local or network drive.",
          this_content_will_be_visible_only_to_you: "This content will be visible only to you.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "This content will be visible only to you and everyone in your organization.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "This content will be visible to everyone after publishing.",
          public_content_disclaimer:
            "You are responsible for any content that you upload. Ensure that your content is not harmful, you have the right to upload the content, and it does not violate any intellectual property rights. You agree to post in the service any terms and conditions that may apply to the use of content that you upload.",
        },
        metadata: {
          title: "Metadata",
          subtitle: "Basic data",
          deleteThumbnail: "Delete thumbnail",
          buttonCreate: "Create",
        },
        basicdata: {
          title: "Add content",
          clickButtonToSelectFile: "Click button to select file",
          thumbnail: "Thumbnail",
          dropImageHere: "Drop Image Here",
        },
        "3dGeometry": {
          title: "Create 3D product catalog",
          buttonAddEntity: "Add 3D product",
          buttonAdd: "Add custom attribute",
          errorAttributeAlreadyExists: "Attribute with same name already exists.",
          buttonCreatePackage: "Create package",
          buttonCreatePackages: "Create packages",
          addCustomAttributePlaceholder: "Attribute name",
        },
        please_log_in_fragments: {
          please: "Please ",
          log_in: "Sign in",
          or: " or ",
          register: "register",
          to_upload_online_content: " to upload online content.",
        },
        header: {
          upload_header: "Upload",
          collection: "Collection",
          content: "Content",
          version: "Version",
          finish: "Finish",
        },
        contentOwner: "Creator",
        contentOwnerGroup: "Publish content",
        select_action: "Select action",
        tooltip: "Tooltip",
        description: "Description",
        copyright: "Copyright",
        tags: "Tags and keywords",
        tagsSubtitle:
          "Use comma to separate, for example: keywords1, keywords2. If keyword consists of multiple words, use underscore (_) between words, no spaces between words are allowed. Apart from undescore and comma, no other special characters are allowed.",
        images: "Images",
        installationInfo: "Installation info",
        productCode: "Product code",
        copyrightInfo: "Copyright information",
        Details: "Details",
        specialTermsUrl: "EULA",
        productExpirationDate: "Product expiration date",
        helpUrl: "Website",
        helpUrlSubtitle: "insert complete url",
        detailsSubtitle:
          "In this phase you can only add plain text to the Details field. After the content is created you can edit the Details to contain html-formatting.",
        specialTermsUrlSubtitle:
          "Content owner has an opportunity to add additional terms for their own content. Owner needs to provide an URL where their specific terms are located. This does not overrule Trimble's Terms of Service.",
        releaseNotes: "Release notes file",
        releaseNoteFileSubTitle: "Add release notes as file",
        supportUrl: "Help & Support",
        supportUrlSubtitle: 'insert complete url or "mailto:" prefix for emailing',
        supportForumUrl: "Discussion forum (website)",
        supportForumUrlSubtitle: "insert complete url",
        measurementUnits: "Measurement units",
        itemTypeCategories: "Group",
        useCategories: "Category",
        compatibleSoftwareProducts: "Compatible software products",
        testedVersions: "Compatible versions",
        compatibleOperatingSystems: "Compatible operating system",
        locationRestrictions: "Product availability",
        supportedLanguages: "Languages",
        select_releasenotes_file: "Select file",
        qualityTag: "Quality - evaluated by owner",
        requireMaintenance: "Download requires Tekla customer maintenance",
        contentOwnerGroupAsMyself: "As myself",
        contentOwnerGroupAsUserGroup: "As user group",
        selectUserGroupForContentOwner: "Select user group for content owner",
        defaultUploadTemplate: "Default package",
        templateFor3DGeometryFiles: "3D geometry package(s)",
        title: "Title",
        details: "Details",
        selectContentOwner: "Select content owner",
        selectContentType: "Type",
        errorWrongfiletype: "Wrong file type.",
        errorImageTooBig: "Selected image is too big.",
        errorBlankTitle: "Title can't be blank.",
        commonLabelsTitle: "Common labels for all packages",
      },
      profile: {
        content_usage: "Content usage",
        profile_updated: "Profile updated",
        invitation_accepted: function (target) {
          return "Invitation accepted! You have now access to " + target + ".";
        },
        user_groups: {
          edit_user_group: "Edit user group",
          user_group_updated: "User group updated",
          create_a_new_user_group: "Create a new user group",
          name_of_the_user_group: "Name of the user group",
          description: "Description",
          group_visibility: "Group visibility",
          remove: '"Are you sure?"',
          remove_invitations: '"Are you sure?"',
          remove_members: '"Are you sure?"',
          invitations_removed: "Invitations removed!",
          members_list_updated: "Members list updated!",
          user_group_created: "User group created",
        },
        members: {
          update_selected_members: "Update selected members",
          new_role: "New role",
          members_list_updated: "Members list updated!",
          edit_user_group: "Edit user group",
          delete_user_group: "Delete user group",
          filter: "Filter",
          user_group_members: "User group members",
          update_members: "Update members",
          remove_members: "Remove members",
          pending_invitations: "Pending invitations",
          invite_member: "Invite member",
          update_invitations: "Update invitations",
          remove_invitations: "Remove invitations",
        },
        subTabs: {
          alerts: "My alerts",
          collections: "Collections",
          likes: "Likes",
        },
        admin: {
          grant_sales_admin: "Grant sales admin role for user",
          grant_analyst_role: "Grant analyst role for user",
          manage_feature_toggles: "Manage feature toggles",
          add_new_toggle: "Add new toggle",
          error: "Error occurred",
          edit_existing_toggles: "Edit existing toggles",
          select_feature_toggle: "Select feature toggle",
          feature_toggle_percentage: "Percentage",
          feature_toggle_is_enabled: "Enabled",
          feature_added: "Feature added",
          feature_add_failed: "Adding feature failed",
          feature_updated: "Feature updated",
          feature_update_failed: "Feature update failed",
          export_analytics_failed: "Export all analytics failed",
          role_granted: "Role granted",
          role_removed: "Role removed",
          packages_removed: "Selected items removed",
          content_moved: "Selected content moved",
          binaries_state_updated: "Selected binaries scan result marked as clean",
          binaries_scan_started: "Virus scan started for selected binaries",
          packages_hidden: "Selected items hidden",
          packages_unhidden: "Selected items unhidden",
          ban_selected: "Ban selected",
          unban_selected: "Unban selected",
          packages_banned: "Selected items banned",
          packages_unbanned: "Selected items unbanned",
          enter_package_or_collection_id: "Enter collection / package / organization / user id or email address",
          packages_move_succesfull: "Packages moved to selected destination",
          packages_move_fail: "Packages move failed for some packages",
          offline_to_online: "Convert offline content to online",
          show_hidden_content: "Show also hidden content",
          show_banned_content: "Show banned content",
          show_invalid_content: "Show invalid content",
          hidden_package: " (hidden)",
          banned_content: "banned",
          use_elastic_search: "Use elastic search",
          export_analytics: "Export all analytics",
          create_sitemap: "Create sitemap",
          search_content: "Search content",
          search_users_and_organizations: "Search users and organizations",
          search_relevancy: {
            header: "Search relevancy settings",
            title: "Title",
            title_translations: "Title translations",
            description: "Description",
            description_translations: "Description translations",
            details: "Details",
            details_translations: "Details translations",
            views_popularity: "Views popularity",
            downloads_popularity: "Downloads popularity",
          },
          choose_action: "Choose action",
          manage_users: "Manage users",
          manage_organizations: {
            title: "Manage organizations",
            collection_count: "Collection count",
            package_count: "Package count",
            marked_as_immutable: "Marked as immutable",
            make_immutable: "Make selected immutable",
            show_immutable: "Show immutable organizations",
            making_immutable_succeeded: `Making organizations immutable succeeded`,
            making_immutable_failed: `Making organizations immutable failed`,
            make_unimmutable: "Remove immutable status from selected",
            making_unimmutable_succeeded: `Removing immutable status from organization succeeded`,
            making_unimmutable_failed: `Removing immutable status from organization failed`,
          },
          delete_user: {
            title: "Delete user",
            description: "<b>Delete the user</b> will hard delete the user and all the content created by the user.",
            delete_selected: "Delete the user",
            confirm: "Delete the user?",
            user_deleted: "User deleting triggered",
            user_deleting_failed: "User could not be deleted",
          },
          suspend_user: {
            suspend_selected: "Suspend the user",
            unsuspend_selected: "Unsuspend the user",
            description:
              "<b>Suspend the user</b> will create a UserLockout, which will prevent the user from using Warehouse.",
            confirm: "Suspend the user?",
            user_suspended: "User suspension triggered",
            user_suspending_failed: "User could not be suspended",
            user_unsuspended: "User unsuspension triggered",
            user_unsuspending_failed: "User could not be unsuspended",
            suspended: function (date) {
              return `(suspended ${date})`;
            },
            show_suspended_users: "Show suspended users",
            loading_suspension_date: "Loading suspension date",
          },
          migrate: {
            title: "Move content",
            migrate_from: "Select source organization or user",
            migrate_to: "Select target organization or user",
            delete_user: "Delete user",
            do_migration: "Move content",
            confirm: "Move content?",
          },
          content_manager: {
            sidepane: {
              filter_labels: {
                admin: "Admin filters",
                creator: "Creator",
                date_created: "Created",
                date_modified: "Modified",
                downloads: "Downloads",
                external_resource_type: "Created by",
                item_types: "Item types",
                languages: "Languages",
                locations: "Locations",
                search_target: "Show",
                sw_products: "Compatible software products",
                sw_versions: "Compatible software versions",
                title: "Title",
                use_categories: "Categories",
                views: "Views",
                visibility: "Visibility",
                special: "Special filters",
              },
            },
            title: "Content manager",
            visibility: {
              all: "All",
              public: "Public",
              private: "Private",
            },
            creator: {
              all: "All",
              organization: "Organization",
              user: "User",
            },
            special_filters: {
              requires_license: "Requires Tekla Maintenance license",
              has_partner_link: "Has a partner download link",
            },
            admin_filters: {
              show_invalid_content: "Show invalid content",
              show_immutable_content: "Show immutable content",
              show_banned_content: "Show banned content",
              show_hidden_content: "Show hidden content",
              hidden_content: "Hidden",
              banned_content: "Banned",
              includes_banned_content: "Includes banned versions",
            },
            helpers: {
              from: "From",
              to: "To",
              date_format: "mm/dd/yyyy",
              no_content_found: "No content found with the selected filters",
              yes: "Yes",
              no: "No",
              show_all: "Show all",
            },
            ban_and_unban: {
              confirm_ban: "Ban selected collections and all their content?",
              confirm_unban: "Unban selected collections and all their content?",
            },
          },
        },
        sales: {
          title: "Sales",
        },
        subtitlePreferences: "Search preferences",
        preferenceInfo:
          'These filters are to recommend content for you on the Tekla Warehouse <a href="#/landing">home page.</a>',
        clearFilters: "Clear filters &rsaquo;",
        admin_role: "Admin",
        sales_role: "Sales",
        admin_mode: "Admin mode:",
        firstName: "First name",
        lastName: "Last name",
        displayName: "Display name:",
        organization: "Organization name:",
        email: "E-mail:",
        teklaId: "Tekla ID",
        maintenanceAccess: "Access to Tekla Maintenance content:",
        myLocations: "Project locations",
        measurementUnits: "Units",
        useCategories: "Use",
        itemTypeCategories: "I'm interested in following types",
        languages: "Languages",
        operatingSystems: "Operating systems",
        software: "Software products",
        user_id: "User ID:",
        organization_id: "Organization ID:",
        edit_in_atc: "Edit my profile in Tekla Account",
        no_likes: "No likes",
        no_alerts: "No subscriptions",
        clear_alerts: "Clear alerts",
        alerts_info_html:
          "You will receive an alert when any of the following changes take place<br>" +
          "<b>Content:</b> Title, description, search criteria, or metadata of content item is modified, a new version of  content is added, or a version is deleted.<br>" +
          "<b>Collection:</b> Title or description is edited, content is added or removed.",
      },
      uploader: {
        placeholders: {
          productCode: "Product code",
          title: "Name",
          description: "Description",
          productWeight: "Product weight",
          weightUnit: "Weight unit",
          material: "Material",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "3D product catalog",
          addToolButton: "Application",
          select_files: "Files from disk",
          select_files_from_ts: "Files from Tekla Structures",
          partnerDownloadLink: "Partner download link",
        },
        headings: {
          geometryFile: "Geometry file",
          thumbnailFile: "Thumbnail bitmap (.bmp)",
          title: "Name",
          description: "Description",
          productCode: "Product code",
          productWeight: "Product weight",
          weightUnit: "Weight unit",
          material: "Material",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Global",
          AF: "Afghanistan",
          AX: "Åland Islands",
          AL: "Albania",
          DZ: "Algeria",
          AS: "American Samoa",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antarctica",
          AG: "Antigua and Barbuda",
          AR: "Argentina",
          AM: "Armenia",
          AW: "Aruba",
          AU: "Australia",
          AT: "Austria",
          AZ: "Azerbaijan",
          BS: "Bahamas",
          BH: "Bahrain",
          BD: "Bangladesh",
          BB: "Barbados",
          BY: "Belarus",
          BE: "Belgium",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermuda",
          BT: "Bhutan",
          BO: "Bolivia",
          BQ: "Bonaire",
          BA: "Bosnia and Herzegovina",
          BW: "Botswana",
          BV: "Bouvet Island",
          BR: "Brazil",
          IO: "British Indian Ocean Territory",
          BN: "Brunei Darussalam",
          BG: "Bulgaria",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Cambodia",
          CM: "Cameroon",
          CA: "Canada",
          CV: "Cape Verde",
          KY: "Cayman Islands",
          CF: "Central African Republic",
          TD: "Chad",
          CL: "Chile",
          CN: "China",
          CX: "Christmas Island",
          CC: "Cocos (Keeling) Islands",
          CO: "Colombia",
          KM: "Comoros",
          CG: "Republic of the Congo",
          CD: "Democratic Republic of the Congo",
          CK: "Cook Islands",
          CR: "Costa Rica",
          CI: "Côte d'Ivoire",
          HR: "Croatia",
          CU: "Cuba",
          CW: "Curaçao",
          CY: "Cyprus",
          CZ: "Czech Republic",
          DK: "Denmark",
          DJ: "Djibouti",
          DM: "Dominica",
          DO: "Dominican Republic",
          EC: "Ecuador",
          EG: "Egypt",
          SV: "El Salvador",
          GQ: "Equatorial Guinea",
          ER: "Eritrea",
          EE: "Estonia",
          ET: "Ethiopia",
          FK: "Falkland Islands (Malvinas)",
          FO: "Faroe Islands",
          FJ: "Fiji",
          FI: "Finland",
          FR: "France",
          GF: "French Guiana",
          PF: "French Polynesia",
          TF: "French Southern Territories",
          GA: "Gabon",
          GM: "Gambia",
          GE: "Georgia",
          DE: "Germany",
          GH: "Ghana",
          GI: "Gibraltar",
          GR: "Greece",
          GL: "Greenland",
          GD: "Grenada",
          GP: "Guadeloupe",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guinea",
          GW: "Guinea-Bissau",
          GY: "Guyana",
          HT: "Haiti",
          HM: "Heard Island and McDonald Islands",
          VA: "Vatican City",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Hungary",
          IS: "Iceland",
          IN: "India",
          ID: "Indonesia",
          IR: "Iran",
          IQ: "Iraq",
          IE: "Ireland",
          IM: "Isle of Man",
          IL: "Israel",
          IT: "Italy",
          JM: "Jamaica",
          JP: "Japan",
          JE: "Jersey",
          JO: "Jordan",
          KZ: "Kazakhstan",
          KE: "Kenya",
          KI: "Kiribati",
          KW: "Kuwait",
          KG: "Kyrgyzstan",
          LA: "Laos",
          LV: "Latvia",
          LB: "Lebanon",
          LS: "Lesotho",
          LR: "Liberia",
          LY: "Libya",
          LI: "Liechtenstein",
          LT: "Lithuania",
          LU: "Luxembourg",
          MO: "Macao",
          MK: "Macedonia",
          MG: "Madagascar",
          MW: "Malawi",
          MY: "Malaysia",
          MV: "Maldives",
          ML: "Mali",
          MT: "Malta",
          MH: "Marshall Islands",
          MQ: "Martinique",
          MR: "Mauritania",
          MU: "Mauritius",
          YT: "Mayotte",
          MX: "Mexico",
          FM: "Micronesia",
          MD: "Moldova",
          MC: "Monaco",
          MN: "Mongolia",
          ME: "Montenegro",
          MS: "Montserrat",
          MA: "Morocco",
          MZ: "Mozambique",
          MM: "Myanmar (Burma)",
          NA: "Namibia",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Netherlands",
          NC: "New Caledonia",
          NZ: "New Zealand",
          NI: "Nicaragua",
          NE: "Niger",
          NG: "Nigeria",
          NU: "Niue",
          NF: "Norfolk Island",
          KP: "North Korea",
          MP: "Northern Mariana Islands",
          NO: "Norway",
          OM: "Oman",
          PK: "Pakistan",
          PW: "Palau",
          PS: "Palestinian Territory",
          PA: "Panama",
          PG: "Papua New Guinea",
          PY: "Paraguay",
          PE: "Peru",
          PH: "Philippines",
          PN: "Pitcairn",
          PL: "Poland",
          PT: "Portugal",
          PR: "Puerto Rico",
          QA: "Qatar",
          RE: "Réunion",
          RO: "Romania",
          RU: "Russian Federation",
          RW: "Rwanda",
          BL: "Saint Barthélemy",
          SH: "Saint Helena",
          KN: "Saint Kitts and Nevis",
          LC: "Saint Lucia",
          MF: "Saint Martin",
          PM: "Saint Pierre and Miquelon",
          VC: "Saint Vincent and the Grenadines",
          WS: "Samoa",
          SM: "San Marino",
          ST: "São Tomé and Príncipe",
          SA: "Saudi Arabia",
          SN: "Senegal",
          RS: "Serbia",
          SC: "Seychelles",
          SL: "Sierra Leone",
          SG: "Singapore",
          SX: "Sint Maarten",
          SK: "Slovakia",
          SI: "Slovenia",
          SB: "Solomon Islands",
          SO: "Somalia",
          ZA: "South Africa",
          GS: "South Georgia",
          KR: "South Korea",
          SS: "South Sudan",
          ES: "Spain",
          LK: "Sri Lanka",
          SD: "Sudan",
          SR: "Suriname",
          SJ: "Svalbard and Jan Mayen",
          SZ: "Swaziland",
          SE: "Sweden",
          CH: "Switzerland",
          SY: "Syrian Arab Republic",
          TW: "Taiwan",
          TJ: "Tajikistan",
          TZ: "Tanzania",
          TH: "Thailand",
          TL: "Timor-Leste",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad and Tobago",
          TN: "Tunisia",
          TR: "Turkey",
          TM: "Turkmenistan",
          TC: "Turks and Caicos Islands",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ukraine",
          AE: "United Arab Emirates",
          GB: "United Kingdom",
          US: "United States",
          UM: "United States Minor Outlying Islands",
          UY: "Uruguay",
          UZ: "Uzbekistan",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Viet Nam",
          VG: "Virgin Islands (British)",
          VI: "Virgin Islands (U.S.)",
          WF: "Wallis and Futuna",
          EH: "Western Sahara",
          YE: "Yemen",
          ZM: "Zambia",
          ZW: "Zimbabwe",
        },
        sortOptions: {
          "-modifyTime": "Last updated",
          "-modifiedAt": "Last updated",
          title: "Alphabetical A-Z",
          "-title": "Alphabetical Z-A",
          "-reviewCount": "Most liked",
          name: "Name",
          role: "Role",
          email: "Email",
          "-relevance": "Relevance",
          "-createdAt": "Last created",
          displayName: "Alphabetical A-Z",
          "-displayName": "Alphabetical Z-A",
          popularity: "Popularity",
          creatorName: "Creator",
          recommended: "Recommended",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Today",
          yesterday: "Yesterday",
          this_week: "This week",
          previous_week: "Previous week",
          this_month: "This month",
          previous_month: "Previous month",
          this_year: "This year",
          previous_year: "Previous year",
          beginning_of_time: "Beginning of time",
          custom: "Custom timeframe",
        },
        eventTypeOptions: {
          downloads: "Downloads",
          views: "Views",
          partnerDownload: "Partner download link clicks",
          all_events: "All events",
        },
      },
    });
  },
};
