import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";

export const TCCTranslationDS = {
  setPackageTranslations: function (id, data) {
    return ResponseProcessor.process(RequestBuilder.doPatch("/warehouse/v1.0/packages/" + id + "/translations", data));
  },
  getPackageTranslations: function (id) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/packages/" + id + "/translations"));
  },
  setCollectionTranslations: function (id, data) {
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/collections/" + id + "/translations", data),
    );
  },
  getCollectionTranslations: function (id) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/collections/" + id + "/translations"));
  },
  translate: function (data) {
    return ResponseProcessor.process(RequestBuilder.doPost("/warehouse/v1.0/translations", data));
  },
};
