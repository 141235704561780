import * as React from "react";
import { useContext, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { RootContext, RootStore } from "../stores/rootStore";
import { SearchWithAutocompleteStore, SearchWithAutocompleteStoreContext } from "../stores/searchWithAutocompleteStore";
import { SearchPageStore, SearchPageStoreContext } from "./searchPageStore";
import { SearchWithAutocomplete } from "../components/SearchWithAutocomplete";
import { useSearchParams } from "react-router";

/**
 * A component that renders search bar
 */
export const SearchBar = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const searchPageStore: SearchPageStore = useContext(SearchPageStoreContext);
  const narrowMode: boolean = searchPageStore.isNarrowSearchMode();

  const [value, setValue] = useState(searchPageStore.getSearchTerm() || "");
  const [searchParams, setSearchParams] = useSearchParams();

  function setSearchString(query): void {
    searchParams.set("searchTerm", query);
    setSearchParams(searchParams);

    searchPageStore.search(query || "");
  }

  function clear(): void {
    setValue("");
    searchParams.set("searchTerm", "");
    setSearchParams(searchParams);
  }

  return (
    <div className={classNames("searchbar-container", { narrowmode: narrowMode })} data-testid="search-bar">
      <div className="catalog-searchbar">
        <h1>{rootStore.getTranslation("search.bar.search")}</h1>
        <section className={classNames("search", { narrowmode: narrowMode })}>
          <SearchWithAutocompleteStoreContext.Provider value={new SearchWithAutocompleteStore(rootStore)}>
            <SearchWithAutocomplete showClear={true} value={value} onSubmit={setSearchString} onClear={clear} />
          </SearchWithAutocompleteStoreContext.Provider>
        </section>
      </div>
    </div>
  );
});
