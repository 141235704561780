
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const It = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["it-IT"]) {
      window.locale["it-IT"] = {};
    }

    _.extend(window.locale["it-IT"], {
      myDetails: {
        my_roles: "I miei ruoli",
        my_roles_link: "Ruoli e diritti di accesso per Tekla Warehouse il contenuto",
        my_roles_link_url: "https://support.tekla.com/it/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Ruoli",
        roles: {
          member: "Editor di contenuti Tekla Warehouse (Dipendente)",
          employee: "Dipendente",
          contentEditor: "Editor di contenuti Tekla Warehouse (Esterno)",
        },
      },
      immutability: {
        add_code_name_for_package: "Aggiungi un nome in codice per il contenuto",
        code_name: "Nome in codice",
        immutable: "Immutabile",
        archive: "Archivia",
        archive_collection: "Archiviare la raccolta e tutti i suoi contenuti",
        archive_version: "Archiviare la versione selezionata",
        archive_package: "Archiviare il pacchetto e tutto il relativo contenuto",
        archive_dialog_text: "I contenuti archiviati non saranno visibili né ricercabili in Tekla Warehouse. Tuttavia, se il contenuto è stato utilizzato in precedenza con il Tekla Structures servizio cloud, il servizio cloud può continuare ad accedere al contenuto.",
        archive_success: function (name: string) {
          return `${name} archiviato correttamente`;
        },
        archive_failure: function (name: string) {
          return `Archiviazione di ${name} non riuscita`;
        },
        archived_content_redirect_message: "Il contenuto a cui si sta tentando di accedere è stato archiviato",
        edit_title_move_or_archive: "Modificare titolo, spostare o archiviare",
        edit_title_or_archive: "Modifica il titolo o archivia",
        major_update: "Aggiornamento principale",
        major_update_description: "Modifiche non compatibili con le versioni precedenti, come l'aggiunta o la rimozione di funzioni o altre modifiche che interrompono la compatibilità.",
        minor_update: "Aggiornamento minore",
        minor_update_description: "Modifiche compatibili con le versioni precedenti, ad esempio nuove funzioni o miglioramenti senza interrompere le funzionalità esistenti.",
        patch_update: "Aggiornamento patch",
        patch_update_description: "Correzioni compatibili con le versioni precedenti, ad esempio patch di sicurezza, correzioni di bug o miglioramenti delle prestazioni, senza nuove funzionalità.",
        info: "L'organizzazione fornisce contenuti per il servizio cloud Tekla Structures. Un nome in codice e un numero di versione sono necessari per il contenuto nel Tekla Structures servizio cloud per garantire compatibilità e coerenza.",
        immutable_files_info: "Per mantenere la coerenza dei contenuti nel servizio cloud, non è possibile rimuovere o modificare i file. Se sono necessarie modifiche, archiviare la versione corrente e caricare una nuova versione.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Per ulteriori informazioni, vedere <a href="${readMoreUrl}" target="_blank">questa pagina</a>.`;
        },
        resolve_conflict: {
          title: "Risolvi conflitto",
          description:
            "Sono stati rilevati i seguenti elementi. Selezionare \"Mantieni esistente\" per mantenere l'elemento esistente trovato nell'installazione.",
          table_header_name: "Nome",
          table_header_type: "Tipo",
          table_header_action: "Azione",
          action_keep_existing: "Mantieni esistente",
          action_replace: "Sostituisci",
          action_done: "Fine",
        },
        auth: {
          authentication_process_failed: "Processo di autenticazione non riuscito",
          access_denied: "Accesso negato",
          handle_unauthorized: {
            unauthorized: "Non autorizzato",
            please_log_in: "Eseguire l'accesso o la registrazione",
          },
        },
        translations: {
          edit_translations: "Modifica traduzioni",
          edit_translations_info: "Selezionare una lingua dalla lista, modificare e salvare",
          edit_translations_default_language_info:
            "<b>Default</b> viene visualizzato quando la lingua selezionata non è disponibile",
          edit_content: "Informazioni contenuto",
          edit_support: "Supporto",
          updated_successfully: "Aggiornamento traduzioni riuscito",
          error_on_update: "Errore durante l'aggiornamento delle traduzioni",
          default: "Default",
          batch_translations: "Traduzioni in batch",
          export_import_info:
            "<b>Esporta</b> il template di traduzioni in batch per il contenuto scelto, modifica il template aggiungendo le traduzioni e <b>importa</b> di nuovo il template per salvare più traduzioni contemporaneamente.",
          import_translations: "Importa",
          export_translations: "Esporta",
          translations_missing: " (vuoto)",
          select_content: "Seleziona le traduzioni da esportare:",
          import_finished: "Importazione terminata.",
          select_file: "Seleziona file",
          select_file_title: "Seleziona il file da importare:",
          import_from_file: "Importa",
          import_error_info: "Il file non segue il formato del template:",
          export_selected: "Esporta",
          invalid_file: "Formato contenuto file non valido",
          import_translations_finished: "Traduzioni importate",
          details_too_long: "Il contenuto dettagli supera la lunghezza massima consentita",
          import_failed: "Importazione traduzioni non riuscita",
          translate_to_other_languages: "Traduci in altre lingue",
          import_count: function (count) {
            return "Traduzioni aggiornate per " + count + " contenuto";
          },
          invalid_language: function (contentId, language) {
            return "Content '" + contentId + "' contains invalid language '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "Content '" + contentId + "' contains invalid translation field '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "Content '" +
              contentId +
              "' translation field '" +
              translationField +
              "' value exceeds maximum allowed length"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "Modello 3D",
          "3d_pic_added": "Aggiunta modello 3D riuscita",
          "3d_pic_updated": "Aggiornamento modello 3D riuscito",
          "3d_pic_removed": "Rimozione modello 3D riuscita",
          "3d_pic_update_failed": "Impossibile aggiornare il modello 3D",
          "3d_pic_remove_failed": "Impossibile rimuovere il modello 3D.",
          "3d_pic_missing":
            "Nessuna immagine 3D per il contenuto. Scegliere l'immagine cliccando su 'Scegli modello 3D' di seguito.",
          "3d_pic_uploading": "Caricamento del modello in corso. Attendere...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Clicca qui per modificare il contenuto",
          updated_successfully: "Aggiornamento riuscito",
          update_failed: "Aggiornamento non riuscito",
          edit_search_criteria: "Modifica criteri di ricerca",
          edit_group_and_category: "Modifica gruppo e categoria",
          edit_content: "Modifica contenuto",
          edit_thumbnail: "Modifica immagine",
          edit_thumbnail_descriptive: "Dimensioni consigliate: 400 x 300 px",
          edit_description: "Modifica descrizione",
          edit_download_link: "Aggiungi o modifica il collegamento per il download dei partner",
          versions_not_shown_to_users:
            "Poiché questo elemento di contenuto include un collegamento per il download dei partner, gli utenti di Tekla Warehouse non possono visualizzare le versioni aggiunte in precedenza all'elemento di contenuto. Gli amministratori di Tekla Warehouse e gli utenti che dispongono di diritti di modifica possono comunque visualizzare tutte le versioni dell'elemento di contenuto.",
          partner_download_link: "Inserisci l'URL di download",
          partner_download_link_disclaimer:
            "Se aggiungi un collegamento per il download dei partner a un elemento di contenuto esistente, gli utenti di Tekla Warehouse non potranno più visualizzare le versioni aggiunte in precedenza all'elemento di contenuto. Questa azione non può essere annullata.",
          edit_title_or_delete: "Modifica titolo o elimina",
          edit_title_or_move_delete: "Modifica titolo, sposta o elimina",
          edit_support: "Modifica informazioni di supporto",
          edit_release_notes: "Modifica note sulla versione",
          edit_content_wysiwyg: "Modifica dettagli del contenuto",
          edit_content_embed_link: "Per maggiori informazioni, vedere Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "È possibile inserire solo video da YouTube, Vimeo o Alugha.",
          details_unallowed_embed_info: "È possibile inserire solo contenuti provenienti dai seguenti domini: YouTube, Vimeo o Alugha. I formati URL consentiti sono:",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "Collegamento video non valido. Puoi aggiungere video tramite URL da YouTube e Vimeo",
          edit_content_vide_code_error: "Formato di inserimento non valido. Sono accettati solo iframe. I fornitori di video consentiti sono YouTube, Vimeo e Alugha.",
          details_max_length_exceeded: "Il contenuto dettagli è troppo lungo",
          editor_mode_label: "Modalità editor:",
          editor_mode_on: "Sì",
          editor_mode_off: "No",
          do_not_notify: "Aggiornamento secondario, non informare gli iscritti",
          invalid_tag: "Etichetta non valida",
        },
        transfer_package: {
          header: "Sposta contenuto",
          actions: {
            move: "Muovi",
            cancel: "Cancella",
          },
          loading: "Caricamento raccolte...",
          moving: "Spostamento del contenuto",
          select_collection: "Seleziona raccolta",
          content_moved: "Contenuto spostato. Le modifiche saranno visibili tra alcuni istanti.",
          operation_failed: "Operazione non riuscita",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Contenuto collegato correttamente a " + name;
          },
          selected_resources_linked: function (name) {
            return "Contenuto selezionato collegato correttamente a " + name;
          },
          resources_linked_with_errors: "Contenuto collegato, si sono verificati errori con parte del contenuto",
          resources_linking_failed: "Collegamento contenuto non riuscito",
          resource_unlinked: "Rimozione collegamento completata. Le modifiche saranno visibili tra alcuni istanti.",
          resources_unlinked:
            "Collegamenti rimossi correttamente dal contenuto selezionato. Le modifiche saranno visibili tra alcuni istanti.",
          resources_unlinked_with_errors: "Collegamenti rimossi, si sono verificati errori con parte del contenuto",
          resources_unlinking_failed: "Rimozione collegamenti non riuscita",
          linking: "Creazione collegamento",
          linking_failed: "Collegamento non riuscito",
          linking_failed_title: "Impossibile collegare il seguente contenuto:",
          unlinking_failed_title: "Impossibile scollegare il seguente contenuto:",
          linking_failed_already_contains:
            "Il contenuto è già stato collegato alla raccolta selezionata. La visualizzazione degli aggiornamenti potrebbe richiedere alcuni minuti.",
          linking_failed_already_contains_short: "Il contenuto è già stato collegato alla raccolta selezionata",
          unlinking_failed: "Rimozione collegamento non riuscita",
          unlinking_failed_does_not_contain:
            "Il collegamento al contenuto è già stato rimosso dalla raccolta. La visualizzazione degli aggiornamenti potrebbe richiedere alcuni minuti.",
          unlinking_failed_does_not_contain_short: "Il collegamento al contenuto è già stato rimosso dalla raccolta",
        },
        notification_channel: {
          title_success: "operazione riuscita",
          title_notice: "avviso",
          title_info: "info",
          title_error: "errore",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Download solo con contratto di manutenzione Tekla",
          can_access_maintenance: {
            yes: "Sì",
            no: "No",
          },
        },
        confirm: {
          title: "Conferma",
          yes: "Sì",
          no: "No",
          ok: "OK",
          close: "Chiudi",
          dont_show: "Non visualizzare più questo messaggio",
        },
        show_more: " Mostra più",
        show_less: " Mostra meno",
        spinner_loading: "Caricamento",
        spinner_processing: "Elaborazione",
        spinner_loading_long_time: "Il caricamento del contenuto potrebbe richiedere alcuni minuti",
        local_service: {
          info: "Plug-in Tekla Warehouse Service necessario per creare il contenuto di rete e locale. Con Tekla Structures 20.1 SR1 o versione successiva, è possibile caricare il contenuto direttamente dal modello.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Plug-in Tekla Warehouse Service</a> " +
            "necessario per creare il contenuto di rete e locale.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Plug-in Tekla Warehouse Service</a> " +
            "non disponibile. Il servizio è necessario per abilitare le raccolte di rete e locali.",
          download_to_unlock_all_features_html:
            "Scarica " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Plug-in Tekla Warehouse Service</a> " +
            "per sbloccare tutte le funzioni.",
          service_out_of_date_html:
            "Plug-in Tekla Warehouse non aggiornato. Aggiorna " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Plug-in Tekla Warehouse Service</a> " +
            "per sbloccare tutte le funzioni.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Seleziona lingua",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Invita altri utenti",
          send_invitation_to_email: "Invia invito per e-mail",
          add_e_mail_addresses: "Aggiungi indirizzi e-mail",
          and_assign_role: "e assegna ruolo",
          send_invitation: "Invia invito",
          invitation_already_sent: "Invito già inviato.",
          invitation_sent: "Invito inviato.",
          update_selected_invitations: "Aggiorna inviti selezionati",
          new_role: "Nuovo ruolo",
          invitations_list_updated: "Lista inviti aggiornata.",
        },
        item_browser_title: "Seleziona elementi di contenuto",
        prerequisites: {
          description_placeholder: "Aggiungi descrizione qui",
          link_name_placeholder: "Aggiungi nome collegamento qui",
          add_prerequisites_and_recommendations: "Prerequisiti e raccomandazioni (facoltativo)",
          list_the_profiles_components_files:
            "Elenca profili, componenti, file o modelli necessari affinché il contenuto funzioni correttamente.",
          link_to_existing_content: "Collegamento al contenuto esistente",
          please_enter_prerequisite_url: "Immettere URL prerequisiti",
          please_enter_valid_prerequisite_url: "Immettere URL prerequisiti valido",
          missing_http: "http:// mancante",
          add_link: "Aggiungi collegamento",
        },
        treeview: {
          cannot_export_this_container: "Impossibile esportare contenitore. Selezionare singoli elementi.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "Il file deve essere inferiore a " + max_size + " MB";
          },
          "invalid_type.": function (allowed_types) {
            return "Tipo di file non valido.  Il file deve essere di uno dei seguenti tipi " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Periodo di tempo:",
          timeframe_start: "Inizio:",
          timeframe_end: "Fine:",
          eventtype: "Tipo di evento:",

          downloads_daily_by_version: "Download per versione",
          downloads_by_country: "Download per paese",
          downloads_by_version: "Download per versione",
          downloads_by_version_organization: "Download per versione e organizzazione",
          downloads_by_organization: "Download per organizzazione",
          downloads_daily_by_package: "Download per elemento di contenuto",
          views_daily_by_package: "Viste per elemento di contenuto",
          views_by_country: "Viste per paese",
          views_by_organization: "Viste per organizzazione",

          downloads_by_package: "Download per elemento di contenuto",
          views_by_package: "Viste per elemento di contenuto",
          downloads_daily_by_collection: "Download per raccolta",
          downloads_by_collection: "Download per raccolta",
          views_daily_by_collection: "Viste per raccolta",
          views_by_collection: "Viste per raccolta",

          events_by_organization_func: function (version) {
            return 'Versione "' + version + '" eventi per organizzazione'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Altri",
          sum_all_versions: "Tutte le versioni",
          sum_all_packages: "Tutti gli elementi di contenuto",
          sum_all_collections: "Tutte le raccolte",
          sum_all_organizations: "Tutte le organizzazioni",
          recording_started_at: "Registrazione dei dati iniziata a giugno 2015",
          recording_view_events_started_at: "Registrazione dei dati iniziata a marzo 2017",
          version_title_func: function (title) {
            return 'Versione "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " download", count + " download", count + " download"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "I numeri includono tutti i download completati.",
          counted_events_info_package: "I numeri includono tutti i download completati.",
          export: {
            caption: "Esporta analisi",
            select_options: "Seleziona opzioni",
            select_timeframe_description:
              "Selezionare di seguito un periodo di tempo per l'esportazione dell'analisi da:",
            select_eventtype_description: "Selezionare il tipo di evento da cui esportare l'analisi:",
          },
        },
        access_rights: {
          editors: "Editor",
          download_access: "Accesso per visualizzazione e download",
          write_user_id_placeholder: "Scrivi ID utente",
          write_organization_id: "Immettere ID organizzazione validi. Inserisci ogni ID in una riga separata.",
          write_id_placeholder:
            "Inserisci ID utente, indirizzi e-mail degli utenti o ID dell'organizzazione. Inserisci ciascun ID o indirizzo e-mail in una riga separata.",
          id_help_html_short:
            "Per motivi di sicurezza i nostri utenti non sono ricercabili.<br/><br/>" +
            "Richiedere l'ID utente direttamente alla persona a cui si desidera fornire l'accesso. <br/>" +
            'ID utente visualizzato in <a href="#/my/">Dettagli</a>.',
          id_help_html:
            "Per motivi di sicurezza i nostri utenti non sono ricercabili.<br/><br/>" +
            "Richiedere l'ID utente direttamente alla persona a cui si desidera fornire l'accesso. Per fornire accesso a tutti gli utenti in un'organizzazione, utilizzare l'ID organizzazione.<br/><br/>" +
            'Gli ID utente e organizzazione sono mostrati in <a href="#/my/">Dettagli</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Inserisci ciascun ID o indirizzo e-mail in una riga separata. È possibile inserire " +
              max_rows +
              " righe alla volta."
            );
          },
          input_too_long: "Il testo inserito è troppo lungo.",
          viewer_already_added: function (input) {
            return `${input} è già stato aggiunto come visualizzatore`;
          },
          wrong_format: function (input) {
            return `${input} non è un ID o un indirizzo email valido`;
          },
          candidates_for_keyword: function (keyword) {
            return "Utenti trovati con l'indirizzo e-mail o l'ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Aggiungi nuovo",
        add: "Aggiungi",
        delete: "Elimina",
        set_as_default: "Immagine di default",
        select_file: "Seleziona file",
        choose_option: "Scegli opzione",
        info_3D_exists:
          "È già stato aggiunto un modello 3D, non è possibile aggiungere più di un modello al contenuto.",
        action_options: {
          image: "Immagine",
          "3DModel": "Modello 3D",
          video: "Video",
        },
        video: {
          url: "URL video",
          invalid_url: "URL non valido",
        },
      },
      units: {
        bytes: "byte",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
      },
      tools: {
        download: "Download",
      },
      likes: function (number) {
        return "" + pluralize(number, [number + " like", number + " like", number + " like"]);
      },
      organizations: {
        error_loading_collection_contents: "Errore di caricamento dei contenuti della raccolta",
        error_loading_organization: "Errore di caricamento organizzazione",
      },
      downloads_graph: {
        date: "Data",
        total_views: "Totale visualizzazioni",
        total_downloads: "Totale download",
        views: "Visualizzazioni",
        downloads: "Download",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Plug-in Tekla Warehouse Service" +
          "</a>" +
          "necessario.",
        processingFailed: "Elaborazione non riuscita.",
        forbidden: "Non consentito",
        resource_not_found: "Risorsa non trovata",
        no_matches_found: "Nessuna corrispondenza trovata",
        no_matches_found_for: function (input) {
          return "Nessuna corrispondenza trovata con l'indirizzo e-mail o l'ID " + input;
        },
        not_identified: "Non identificato",
        error_while_searching: "Errore di ricerca",
        error_user_not_found: "Impossibile trovare utente o organizzazione",
        error_while_adding_member: "Errore di aggiunta membro con diritti di accesso",
        error_while_removing_member: "Errore di rimozione membro con diritti di accesso",
      },
      offline_to_online: {
        fail_on_initialize: "Errore durante la gestione del contenuto zip, interruzione della conversione",
        fail_on_validate: "Convalida dei metadati del contenuto non riuscita, interruzione della conversione",
        fail_on_save: "Errore durante il salvataggio del contenuto, annullamento delle modifiche",
        success: "Conversione riuscita del contenuto offline in contenuto online",
        validate_errors_title: "Conversione del contenuto non riuscita",
        processing: "Salvataggio pacchetto ",
        processed: "Elemento elaborato",
        collection_thumbnail_missing_name: "Codice binario immagine raccolta privo di 'nome file'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "Package '" + packageTitle + "' thumbnail binary is missing 'filename'";
        },
        package_value_missing_title_func: function () {
          return "Package is missing 'title'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "Package '" + packageTitle + "' is missing '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return "Package '" + packageTitle + "' field '" + field + "' has following invalid values: " + invalidValues;
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Package '" + packageTitle + "' has invalid tag: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "Package '" + packageTitle + "' version is missing 'title'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "Package '" + packageTitle + "' version '" + versionTitle + "' is missing '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Package '" +
            packageTitle +
            "' version '" +
            versionTitle +
            "' field '" +
            field +
            "' has following invalid values: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return "Package '" + packageTitle + "' version '" + versionTitle + "' binary is missing 'filename'";
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Importante: Fine del supporto per le raccolte locali in Tekla Warehouse",
            part1: "A partire dal 2 giugno 2025, le raccolte locali non saranno più disponibili in Tekla Warehouse. Gli utenti non potranno più creare, visualizzare o cercare raccolte locali né utilizzare i contenuti delle raccolte locali.",
            part2: "Per assistenza nella transizione dalle raccolte locali alle raccolte online, si prega di contattare la propria organizzazione di supporto locale.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Importante. Per migliorare la sicurezza, è necessario disporre del ruolo di Editor di contenuti di Tekla Warehouse per gestire i contenuti dell'organizzazione dopo il ${date} novembre 2024. <br />Chiedere all'amministratore dell'organizzazione di assegnare il ruolo. Per ulteriori informazioni, consultare il <a href="${productBulletinUrl}" target="_blank">bollettino del prodotto</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Per migliorare la sicurezza, è necessario disporre del ruolo di Editor di contenuti di Tekla Warehouse per gestire i contenuti dell'organizzazione dopo il ${date} novembre 2024. <br />Chiedere all'amministratore dell'organizzazione di assegnare il ruolo. Per ulteriori informazioni, consultare il <a href="${productBulletinUrl}" target="_blank">bollettino del prodotto</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Non vedi un'opzione di modifica per la tua organizzazione? Dopo il ${date} novembre, per gestire i contenuti dell'organizzazione è necessario disporre del ruolo di Editor di contenuti di Tekla Warehouse. <br />È possibile verificare il proprio ruolo in Warehouse personale > Dettagli personali. Per ulteriori informazioni, consultare il <a href="${productBulletinUrl}" target="_blank">bollettino del prodotto</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Non vedi un'opzione per creare contenuti per la tua organizzazione? Dopo il ${date} novembre, per gestire i contenuti dell'organizzazione è necessario disporre del ruolo di Editor di contenuti di Tekla Warehouse. <br />È possibile verificare il proprio ruolo in Warehouse personale > Dettagli personali. Per ulteriori informazioni, consultare il <a href="${productBulletinUrl}" target="_blank">bollettino del prodotto</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `Dopo il ${date} novembre, per gestire i contenuti dell'organizzazione è necessario disporre del ruolo di Editor di contenuti di Tekla Warehouse. Chiedere all'amministratore dell'organizzazione l'assegnazione del ruolo. <br />Puoi controllare chi è l'amministratore della tua organizzazione nel tuo Tekla Online profilo. Per ulteriori informazioni, consultare il <a href="${productBulletinUrl}" target="_blank">bollettino del prodotto</a>.`
            },
          },
          service_plugin_update_info:
            "Hai problemi a connetterti a Tekla Structures? Assicurati di utilizzare l'ultimo <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>plugin Tekla Warehouse Service</a>.",
        },
        maintenance_info:
          "Tekla Warehouse è in fase di manutenzione il 27-10-2017, dalle 22:00:00 alle 23:00:00 UTC. Ci scusiamo per l'inconveniente.",
        release_notes_info: "Nuove funzioni in Tekla Warehouse",
        release_notes_link:
          'Tekla Warehouse è stato aggiornato. Scopri le <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">novità</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Ti serve uno sviluppatore Tekla per il tuo progetto?",
        heading2Dev:
          'Dai un\'occhiata alla nuova directory denominata Tekla Development Experts.<br><br>È un luogo dove i programmatori di talento e gli utenti interessati di Tekla possono incontrarsi.<br><br>Tutti i membri del Tekla Partners Program sono invitati ad aggiungere le proprie informazioni alla directory di esperti.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Trova uno sviluppatore &rsaquo;</a>',
        static_intro:
          "Questo è l'archivio BIM gratuito di Tekla Structures dove è possibile trovare, importare, installare e condividere contenuti internamente e globalmente. Tekla Warehouse mira a facilitare la creazione di modelli di alta qualità in modo efficiente.",
        new_intro:
          'Tekla Corporation ora è denominata Trimble. Continuiamo a sviluppare le soluzioni software Tekla e le stesse persone di sempre sono pronte a supportarti. Ulteriori informazioni: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Verifica il tuo account",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Caricamenti più recenti &rsaquo;",
          popular: "Contenuto popolare &rsaquo;",
          recommended: "Consigliati &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Novità &rsaquo;</a>',
          item_title: "Queste sono le novità!",
          item_description:
            'Alcuni testi relativi alle novità, alcuni testi relativi alle novità, alcuni testi relativi alle novità, alcuni testi relativi alle novità, alcuni testi relativi alle novità, alcuni testi relativi alle novità.<br><a href="https://developer.tekla.com" target="_blank">Ulteriori informazioni &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse in cifre",
          partners: "Partner",
          partners_count: "352",
          collections: "Raccolte",
          collections_count: "793",
          items: "Elementi di contenuto",
          items_count: "2893",
        },
        partners: "I nostri partner",
        action_links: {
          search_title: "Cercare dei contenuti?",
          upload_title: "Caricare il contenuto?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Video &rsaquo;</a>',
          link: 'Visita il nostro <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">canale Youtube</a> per altri video.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Controlla la posta elettronica per il link di verifica oppure <u><a href="' +
            settingsUrl +
            '">richiedi un nuovo link</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Verifica il tuo indirizzo e-mail e accetta i termini e le condizioni e l'informativa sulla privacy",
        acceptTerms: "Accetta i termini e le condizioni e l'informativa sulla privacy",
        acceptUpdatedTerms:
          "Approva i termini e le condizioni e l'informativa sulla privacy aggiornati di tekla online services",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Torna al <u><a href="' + settingsUrl + '">profilo personale</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            "Esamina accetta i termini e le condizioni e l'informativa sulla privacy nel <u><a href=\"" +
            settingsUrl +
            '">profilo personale</a></u>.'
          );
        },
        suggestions: {
          browseCollections: "Sfoglia raccolte &rsaquo;",
          new: "Nuovo",
          popular: "Popolari",
          recommended: "Consigliati",
          organizations: "Organizzazioni",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Seleziona cartella",
        selectFolderInstructions: "Le cartelle di rete e locali possono essere utilizzate come raccolte.",
        selectedFolder: "Cartella selezionata (copia/incolla posizione):",
        selectedFolderPlaceholder: "Es. C:\\cartella, Z:\\cartella di rete, \\\\nome-macchina\\cartella",
        collectionCreation: {
          name: "Nome",
          createNewCollection: "Crea raccolta locale",
          pleaseInputTitle: "Immetti un titolo",
          invalidPath: "Percorso non valido",
          access_denied: "Impossibile aggiungere la raccolta: accesso negato",
          unsafe_location: "Impossibile aggiungere la raccolta: percorso non sicuro",
          add_collection_failed: "Impossibile aggiungere la raccolta",
          minLengthError: "Il titolo deve essere composto da almeno 3 caratteri",
          maxLengthError: "La lunghezza del titolo non può essere superiore a 20 caratteri",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "Le cartelle di rete e locali possono essere utilizzate come raccolte.",
        collectionCreation: {
          name: "Nome",
          createNewCollection: "Crea raccolta online",
          pleaseInputTitle: "Immetti un titolo",
          minLengthError: "Il titolo deve essere composto da almeno 3 caratteri",
          maxLengthError: "La lunghezza del titolo non può essere superiore a 20 caratteri",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Risorsa salvata",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Vedi il potenziale?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Inviaci un feedback</span>',
          help_us_improve_html:
            'Aiutarci a migliorare Tekla Warehouse. <br/><a id="showSurvey">Clicca qui</a> o <a id="dontShow">non visualizzare più questo messaggio</a>',
          dialog: {
            do_you_see_potential: "Vedi il potenziale?",
            help_us_improve_html: "Aiutarci a migliorare Tekla Warehouse. La tua opinione è importante per noi.",
            continue_to_survey: "Inizia questionario",
            exit_to_warehouse: "No, grazie",
          },
        },
      },
      search: {
        bar: {
          search: "Ricerca",
          filter: " ",
          more_filters: "Altri filtri",
          hide_filters: "Nascondi filtri",
          clear: "Annulla",
          exclude: "Escludi",
          include: "Comprendi",
          clear_filters: "Azzera filtri",
          load_defaults: "Usa preferenze personali &rsaquo;",
          save_defaults: "Salva come preferenze personali &rsaquo;",
          defaults_saved_successfully: "Filtri di default salvati",
          edit_defaults: "Modifica impostazioni di default",
        },
        info: {
          searching_organizations: "Ricerca in organizzazioni...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Nessun risultato", amountTotal + " risultati"]);
            } else {
              return (
                pluralize(amountTotal, ["Nessun risultato", amountTotal + " risultati"]) +
                ' per "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " risultati";
            } else if (!searchTerm) {
              return "Immetti un termine di ricerca per visualizzare i risultati dell'organizzazione";
            } else if (searchTerm.length < 2) {
              return "Fornisci almeno due caratteri per la ricerca nell'organizzazione";
            } else {
              return (
                pluralize(amountTotal, ["Nessun risultato", amountTotal + " risultati"]) +
                ' per "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " risultati su contenuto online",
          local: " risultati su contenuto locale",
          organization: "risultati in organizzazioni",
          plugin_missing_info:
            'Il contenuto locale è mostrato soltanto se si ha <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Plug-in Tekla Warehouse Service" +
            "</a>" +
            " installato.",
          version_filter_info: "Il filtro della versione software non è valido nei risultati del contenuto locale",
        },
        exclude_env_files: "Escludi file di ambiente",
        sidebar: {
          filters: {
            measurementUnits: "Unità di misura",
            locations: "Posizioni progetto",
            languages: "Lingue",
          },
          placeholders: {
            use_categories: "Categoria",
            measurement_units: "Unità di misura",
            languages: "Lingue",
            locations: "Posizioni",
          },
        },
      },
      placeholders: {
        name: "Nome...",
        contentOwner: "Nome proprietario contenuto...",
        useCategory: "Seleziona utilizzo",
        selectType: "Seleziona tipo",
        filter: "Filtro...",
        search: "Ricerca...",
        choose: "Scegli...",
        searchFilters: "Filtri di ricerca...",
        selectContentItem: "Seleziona elemento di contenuto...",
        search_content: "Cerca contenuto...",
        includeAs: "Includi come...",
        selectCollection: "Seleziona raccolta",
        selectUserGroup: "Seleziona gruppo utenti",
        online_collections_tooltip: "Clicca per cercare contenuto online",
        local_collections_tooltip:
          "Clicca per eseguire la ricerca del contenuto locale e di rete incluso nell'installazione Tekla Structures",
        organizations_tooltip: "Clicca per cercare le organizzazioni",
        "filter-options": {
          products: "Tutti i prodotti software",
          locations: "Tutte le posizioni",
          languages: "Tutte le lingue",
          measurementUnits: "Tutte le unità di misura",
          testedVersions: "Tutte le versioni software",
          useCategories: "Tutte le categorie",
          useCategoriesCombined: "Categoria",
          allGroups: "Tutti i gruppi",
          itemTypeCategories: "Tutti i tipi di elementi",
        },
        "exclude-filter-options": {
          products: "Escludi prodotti software",
          locations: "Escludi posizioni",
          languages: "Escludi lingue",
          measurementUnits: "Escludi unità di misura",
          testedVersions: "Escludi versioni software",
          useCategories: "Escludi categorie",
          useCategoriesCombined: "Escludi categoria",
          allGroups: "Escludi gruppi",
          itemTypeCategories: "Escludi tipi di elementi",
        },
      },
      link: {
        link_selected: "Collega selezione",
        unlink_selected: "Scollega selezione",
      },
      download_install: {
        choose_version: "Scegli la versione del software:",
        choose_content: "Scegli gli elementi di contenuto dalla lista di seguito:",
        choose_action: "Scegli l'azione per gli elementi selezionati:",
      },
      download: {
        copy_to_clipboard: "Copia nel clipboard",
        download_selected: "Download",
        add_selected_items_to_basket: "Aggiungi selezione al carrello",
        add_item_to_basket: "Aggiungi selezione al carrello",
        add_all_items_to_basket: "Aggiungi tutto al carrello",
        add_all_items_to_basket_info: "Sono presenti più di 100 elementi. Il caricamento può richiedere alcuni minuti",
        add_all_items_to_basket_too_many_results: "Non è possibile aggiungere più di 1000 elementi",
        cart_loading: "Sono presenti più di 100 elementi. Il caricamento può richiedere alcuni minuti",
        remove_item_from_basket: "Rimuovi dal carrello",
        shopping_cart_no_content: "Nessun contenuto nel carrello",
        shopping_cart_no_content_for_version: "Nessun contenuto nel carrello per la versione selezionata",
        shopping_cart_items_func: function (itemsCount) {
          return "Carrello (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Download delle istruzioni",
          step_one: "Copiare le informazioni di download per il clipboard con questo pulsante",
          step_two_func: function (downloadAppUrl) {
            return (
              "Esegui l'applicazione Tekla Warehouse Downloader. È possibile scaricare l'applicazione " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">qui.</a> '
            );
          },
          step_three: "L'applicazione Tekla Warehouse Downloader guiderà l'utente attraverso il resto del download.",
          show_data_title: "Non ha funzionato?",
          show_data_to_be_copied: "Copia manualmente",
          hide_data_to_be_copied: "Nascondi dati di download",
        },
      },
      install: {
        install_title: " gli elementi verranno installati utilizzando il plug-in Tekla Warehouse.",
        failed_install_information:
          "È stato selezionato contenuto che è disponibile solo per il download. Il contenuto non verrà installato ora. Scaricare il contenuto separatamente da Tekla Warehouse.",
        verify_service_plugin_is_running:
          "Installazione: verificare che il plug-in Tekla Warehouse Service sia eseguito correttamente",
        verify_ts_connection: "Installazione: verificare che Tekla Structures sia in esecuzione",
        select_software_version: "Filtra per versione software per collegare, scaricare o installare",
        download_requires_maintenance: "Accesso ad alcuni elementi di seguito solo con contratto di manutenzione Tekla",
        install_selected: "Installa",
        start_install: "Avvia installazione",
        install_started: "Installazione avviata",
        select_ts_version: "Seleziona versione TS",
        install_retry: "Avviare di nuovo da Tekla Warehouse se è necessario riprovare",
        failed_installs_info_func: function (failedCount) {
          return "Contenuto che non sarà installato (" + failedCount + " versioni):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Installazione per " + totalInstallsCount + " elementi selezionati";
        },
      },
      helpers: {
        behalf: "Da",
        sortBy: "Ordina per:",
        filter: "Filtra risultati",
        showAsThumbs: "Mostra come immagini",
        showAsList: "Mostra come lista",
        resultsPerPage: "Mostra per pagina:",
        filter_by_version: "Filtra per versione:",
        show_more_options: "Ulteriori opzioni",
        show_less_options: "Meno opzioni",
        filter_by_type: "Filtra per tipo:",
        select_all: "Seleziona tutto",
        show_content_items: "Elementi di contenuto",
        choose_action: "Scegli azione per il contenuto selezionato: ",
        show_collections: "Raccolte",
        search: {
          show: "Mostra:",
          show_content_items: "Elementi di contenuto",
          show_collections: "Raccolte",
          show_organizations: "Organizzazioni",
          show_online_content: "Contenuto online",
          show_offline_content: "Contenuto offline",
          local_content_info:
            'Il contenuto locale è mostrato soltanto se si ha <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            " plug-in installato.",
        },
        tekla_maintenance_required: "Accesso ad alcuni elementi di seguito solo con contratto di manutenzione Tekla",
        show_content_alerts: "Notifiche contenuti ",
        show_collection_alerts: "Notifiche raccolte ",
      },
      actions: {
        load_more: "Carica altro",
        use_infitinite_scroll: "Usa scorri infinito",
        back: "Indietro",
        show_download_dialog: "Download",
        download: "Solo download",
        download_on_partner_site: "Scarica su sito partner",
        insert: "Installa",
        install: "Installa",
        downloadAndInstall: "Installa",
        createNew: "Crea nuovo",
        viewItems: "Vista",
        viewCollection: "Visualizza raccolta",
        viewGroup: "Mostra gruppo",
        joinGroup: "Partecipa a gruppo",
        loginToDownload: "Accedi per eseguire il download",
        show3DPic: "Mostra modello 3D",
        remove: "Rimuovi",
        browse: "Sfoglia",
        browseFolder: "Sfoglia",
        browseFileSystem: "Sfoglia",
        add: "Aggiungi",
        save: "Salva",
        batch_edit: "Modifica in batch",
        translations: "Traduzioni",
        cancel: "Annulla",
        continue: "Continua",
        delete: "Elimina",
        addNetworkPath: "Aggiungi posizione di rete",
        select: "Seleziona",
        create: "Crea",
        update: "Aggiorna",
        choose_thumbnail: "Scegli immagine",
        choose_picture: "Scegli immagine",
        remove_picture: "Elimina immagine",
        choose_3D_picture: "Scegli modello 3D",
        remove_3D_picture: "Rimuovi modello 3D",
        like: "Like",
        unlike: "Non mi piace",
        close: "Chiudi",
        export: "Esporta",
        add_or_remove: "Aggiungi/Rimuovi",
        select_content_to_convert: "Seleziona contenuto offline",
        search: "Ricerca",
        upload: "Carica",
        edit: "Modifica",
        view_3d: "Clicca per visualizzare il modello...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "beta",
        search: "Ricerca",
        profile: "Dettagli",
        myWarehouse: "Warehouse personale",
        myStuff: "Personali",
        logout: "Esci",
        login: "Accedi",
        register: "Registrazione",
        upload: "Carica",
        uploading: "Carica",
        dashboard: "Dashboard",
        learnMore: "Ulteriori informazioni",
        about: "Informazioni su",
        shoppingCart: "Carrello",
        addToCartInfo:
          "È possibile raccogliere elementi nel Carrello e scaricarli o installarli contemporaneamente. Il download è completamente gratuito",
      },
      cubeNavigation: {
        headings: {
          get_started: "PER INIZIARE",
          learn: "FORMAZIONE",
          get_help: "OTTIENI ASSISTENZA",
          extend_your_tekla: "ESTENDI TEKLA",
          collaborate: "COLLABORA",
          students_and_educators: "Students and educators",
          about: "INFORMAZIONI SU",
        },
        "tekla-online": "Servizi online",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Sito Web Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Profilo",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "TEKLA SERVICES HOME",
        register: "Registrazione",
        login: "Accedi",
        evaluate: "Valuta",
        buy: "Acquista",
        download: "Download",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Prodotti",
        teklaServices: "Servizi",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Raccolte personali",
        myLikes: "I miei like",
        profile: "Dettagli",
        preferences: "Preferenze personali",
        myUserGroups: "Gruppi utenti",
        localCollections: "Raccolte di rete e locali",
        tsCollections: "Raccolte Tekla Structures",
        downloads: "Download",
        selectContent: "Seleziona file di contenuto",
        addMetadata: "Aggiungi metadati",
        onlineCollections: "Raccolte online",
        userGroups: "Gruppi utenti",
        createNew: "Aggiungi contenuto",
        notifications: "Notifiche",
        contentUsage: "Utilizzo contenuto",
        browseCollections: "Sfoglia raccolte",
        admin: "Admin",
        sales: "Vendite",
        shoppingCart: "Carrello",
      },
      notifications: {
        title: "notifica",
        invitedBy: "Invitato da",
        invitationType: "Tipo di invito",
        invitedTo: "Invitato a",
        createdAt: "Data creazione",
        action: "Azione",
        acceptInvitation: "Accetta",
      },
      batch_edit: {
        selectActionType: "Seleziona tipo di azione",
        edit_content: "Modifica metadati del contenuto",
        content_title: "Contenuto",
        metadataToChange: "Metadati da cambiare",
        selectContent: "Per contenuto",
        select_action: "Seleziona azione",
        support: {
          support: "Supporto",
          licenses: "Licenze",
        },
        versions: {
          version: "Versione",
          prerequisities: "Prerequisiti e requisiti",
        },
        content: {
          select_collection: "Seleziona raccolta",
          show_collection: "Mostra:",
          show_all_content: "Tutte le raccolte",
          show_organization_content: "Raccolte della mia organizzazione",
          select_all: "Seleziona tutto",
          select_all_info: "(Questa operazione può richiedere del tempo)",
          actionOptions: {
            add: "Aggiungi",
            remove: "Rimuovi",
            replace: "Sostituisci con",
            yes: "Sì",
            no: "no",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Nessun attributo selezionato",
            invalid_attributes: "Valori degli attributi non validi",
            invalid_actions: "Azioni non valide",
            content_not_selected: "Nessun contenuto selezionato",
            entities_not_selected: "Forniti dati relativi al pacchetto ma nessun pacchetto selezionato",
            versions_not_selected: "Forniti dati relativi alla versione ma nessuna versione selezionata",
          },
        },
        update: {
          info_processing: "Mantenere aperta la finestra fino a quando tutti gli elementi sono stati elaborati.",
          info_processed: "Tutti gli elementi sono stati elaborati.",
          processing: "Elaborazione elemento",
          error_info: "Si è verificato un problema con l'aggiornamento dei seguenti elementi:",
          package_failure_func: function (package_title, errorMsg) {
            return "Contenuto: " + package_title + ", errore: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Contenuto: " + package_title + ", versione: " + version_title + ", errore: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " elementi verranno aggiornati, continuare?";
          },
        },
      },
      myUserGroups: {
        title: "Gruppi utenti",
        createdByPrefix: "Da:",
      },
      downloads: {
        title: "Download",
      },
      myCollections: {
        new_collection: "Nuova raccolta",
        my_collections: "Raccolte personali",
        created_by_me: "Creato dall'utente corrente",
        local_collections: "Raccolte di rete e locali",
        online_collections: "Raccolte online",
        created_by_my_organization: "Creato da propria organizzazione",
        created_by_my_organizations: "Creato dalle proprie organizzazioni",
        shared_with_me: "Condiviso con l'utente corrente",
        shared_with_me_or_organization: "Condiviso con l'utente corrente e la sua organizzazione",
        notification: {
          error: "Errore",
          error_loading_online_collections: "Errore di caricamento raccolte online",
          error_loading_packages_liked_by_me: "Errore di caricamento contenuto apprezzato dall'utente",
          error_loading_my_organization_s_collections: "Errore di caricamento raccolte della propria organizzazione",
          error_loading_my_shared_collections: "Errore di caricamento delle raccolte personali condivise",
        },
        local: {
          helpText1: "Per accedere alle raccolte locali, installare ",
          helpText2: "Plug-in Tekla Warehouse Service",
          helpText3: ".",
          title: "Raccolte di rete e locali",
          notFound: "Nessuna raccolta locale trovata al momento.",
          myCollectionsTitle: "Raccolte personali",
          actions: {
            createNew: "Crea raccolta di rete o locale",
          },
        },
      },
      organization: {
        news_feed: "Feed novità",
        add_comment: "Aggiungi testo feed",
        edit_comment: "Modifica elemento novità",
        subTabs: {
          collections: "Raccolte",
          analytics: "Analisi",
          contact_and_support: "Contatti",
          news_feed: "Feed novità",
        },
        edit: {
          edit_title: "Modifica titolo",
          edit_description: "Modifica descrizione",
          edit_contact: "Modifica informazioni di contatto",
          edit_logo: "Modifica logo",
          edit_url: "Modifica URL",
          edit_email: "Modifica e-mail",
          edit_phonenumber: "Modifica numero di telefono",
          edit_warehouse_url: "Modifica URL Warehouse",
          edit_warehouse_url_subtitle: "Lettere, caratteri non scandinavi, numeri o caratteri speciali",
          edit_url_subtitle: "Inserisci URL completo",
          edit_logo_descriptive: "Dimensioni ottimali immagine circa 350 * 200 pixel",
          edit_facebook_url: "Modifica URL Facebook",
          edit_twitter_url: "Modifica URL Twitter",
          edit_linkedin_url: "Modifica URL LinkedIn",
          edit_youtube_url: "Modifica URL Youtube",
          edit_googleplus_url: "Modifica URL Google+",
          logo_updated: "Logo aggiornato",
          logo_update_failed: "Aggiornamento logo non riuscito",
          logo_remove_failed: "Rimozione logo non riuscita",
          logo_removed: "Rimozione logo completata",
          updated_successfully: "Aggiornamento riuscito",
          updated_failed: "Aggiornamento non riuscito",
          add_comment: "Aggiungi nuovo",
          edit_comment: "Modifica elemento novità",
          comment_added: "Aggiunta elemento feed novità completata",
          comment_updated: "Aggiornamento elemento feed novità completato",
          comment_deleted: "Eliminazione elemento feed novità completata",
          comment_add_failed: "Impossibile aggiungere feed novità",
          comment_update_failed: "Impossibile aggiornare elemento feed novità",
          comment_delete_failed: "Impossibile eliminare elemento feed novità",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Cerca contenuto online per " + name + " &rsaquo;";
        },
        contact_information: "Contattaci",
        website: "Sito Web &rsaquo;",
        show_newer_news: "Più recente",
        show_older_news: "Meno recente",
      },
      online_collections: {
        new_collection: "Nuova raccolta",
        online_collections: "Raccolte online",
        created_by_organizations: "Creato da organizzazioni",
        created_by_users: "Creato da utenti",
      },
      local_collections: {
        tab_title: "Contenuto",
        local_collections: "Raccolte locali",
      },
      versions: {
        add_tool: "Aggiungi applicazione",
        table_header: {
          name: "Nome",
          type: "Tipo",
          platform: "Piattaforma",
          modified_at: "Data modifica",
          size: "Dimensione",
          virus_scan: "Scansione antivirus",
        },
        // placeholders
        select_file_type: "Seleziona tipo di file",
        select_platform: "Seleziona piattaforma",
        select_unit: "Seleziona unità",
        select_quality: "Seleziona qualità",
        what_will_be_added: "Cosa desideri aggiungere?",
        select_files_from_your_disk: "Seleziona file dal disco",
        add: "Aggiungi",
        //content management
        add_application: "Aggiungi applicazione",
        select_content_from_tekla_structures: "Seleziona contenuto da Tekla Structures",
        or: "oppure",
        files: "File",
        //version metadata
        version_information: "Informazioni sulla versione",
        version_display_name: "Nome visualizzato versione",
        version_number: "Numero di versione",
        version_number_info: "Immettere un numero di versione. Non è possibile modificare il numero di versione dopo che è stato salvato.",
        existing_versions: "Numeri di versione esistenti per questo contenuto",
        version_number_validation: {
          missing: "È necessario immettere un numero di versione",
          invalid: "Il numero di versione deve seguire le regole del versioning semantico (MAJOR.MINOR.PATCH)",
          already_in_use: "Questo numero di versione è già in uso per questo contenuto.",
        },
        select_update_type: "Per generare un numero di versione, selezionare innanzitutto il tipo di aggiornamento",
        please_enter_a_version_name_or_number: "Immettere un nome o un numero di versione.",
        virus_scan: {
          soft_failed: "Non riuscita",
          hard_failed: "Non analizzati",
          infected: "Infettati",
        },
      },
      collections: {
        by: "Da:",
        edit: {
          edit_collection: "Modifica raccolta",
          edit_description: "Modifica descrizione",
        },
        notification: {
          collection_updated: "Raccolta aggiornata",
          error: "Errore",
          error_loading_other_users_collections: "Errore di caricamento raccolte di altri utenti",
          error_loading_other_organizations_collections: "Errore di caricamento raccolte di altre organizzazioni",
          error_loading_collection_contents: "Errore di caricamento dei contenuti della raccolta",
          members_list_updated: "Lista membri aggiornata.",
          invitations_removed: "Inviti rimossi.",
          collection_removed: "Raccolta rimossa",
          thumbnail_deleted: "Immagine eliminata",
          wrong_file_type: "Tipo di file errato.",
          invalid_file_type: "Tipo di file non valido.",
          selected_image_is_too_big: "Immagine selezionata troppo grande.",
          thumbnail_updated: "Immagine aggiornata",
          default_thumbnail_updated: "Immagine di default aggiornata",
          default_thumbnail_update_failed: "Aggiornamento immagine di default non riuscito",
          thumbnail_added: "Immagine aggiunta",
          thumbnail_update_failed: "Aggiornamento immagine non riuscito",
          video_added: "Video aggiunto",
          video_adding_failed: "Impossibile aggiungere il video",
          video_deleted: "Video cancellato",
          video_deleting_failed: "Impossibile eliminare il video",
        },
        confirm: {
          delete_all: function (title) {
            return 'Eliminare la raccolta e tutto il contenuto nella raccolta "' + title + '"?';
          },
          remove_members: "Continuare?",
          remove_invitations: "Continuare?",
        },
        useNetworkDrive: "Usa cartella o unità di rete",
        createCollection: "Crea raccolta online",
        createCollectionInstructions: "Crea una raccolta per raggruppare il contenuto.",

        actions: {
          delete_thumbnail: "Elimina immagine",
          edit_collection: "Modifica raccolta",
          delete_collection: "Elimina raccolta",
          add_content: "Aggiungi contenuto",
        },
        subTabs: {
          content: "Contenuto",
          members: "Mostra membri",
          analytics: "Analisi",
          manage_access: "Gestione accesso",
        },

        members: {
          add_user_groups: "Aggiungi gruppi utenti",
          select_user_group: "Seleziona gruppo utenti",
          with_role: "con ruolo",
          user_groups_added: "Gruppi utenti aggiunti.",
          members_list_updated: "Lista membri aggiornata.",
          update_selected_members: "Aggiorna membri selezionati",
          table_header: {
            member_type: "Tipo di membro",
            member_name: "Nome membro",
            email: "E-mail",
            role: "Ruolo",
            joined_at: "Data inizio partecipazione",
            invited_at: "Data invito",
          },
          new_role: "Nuovo ruolo",
        },
        member_list: {
          users_who_can_access_this_collection: "Utenti che possono accedere a questa raccolta",
          add_user_group: "Aggiungi gruppo utenti",
          update_members: "Aggiorna membri",
          remove_members: "Rimuovi membri",
          pending_invitations: "Inviti in sospeso",
          user: "utente",
          invite_member: "Invita membro",
          update_invitations: "Aggiorna inviti",
          remove_invitations: "Rimuovi inviti",
        },
        labels: {
          select_organization: "Seleziona organizzazione",
          select_visibility: "Seleziona visibilità",
          title: "Nome",
          name: "Nome",
          createAs: "Crea come: ",
          asMyself: "Utente corrente",
          asUserGroup: "Gruppo utente",
          asOrganization: "Organizzazione",
          selectOwner: "Seleziona organizzazione per il proprietario contenuto",
          visibility: "Visibilità",
          visibilityOptions: {
            public: "Pubblico",
            private: "Privato",
          },
          description: "Descrizione",
        },
        new_collection: {
          please_input_a_username: "Immettere un nome utente",
          title_is_required_to_be_at_least_3_characters: "Il titolo deve essere composto da almeno 3 caratteri",
          title_cannot_be_longer_than_20_characters: "La lunghezza del titolo non può essere superiore a 20 caratteri",
        },
        collection_list: {
          no_content: "Nessuna raccolta.",
          results_summary: function (number) {
            return "" + number + "  raccolta " + pluralize(number, ["elementi", "elemento", "elementi"]);
          },
        },
        entity_list: {
          no_content: "Nessun contenuto nella raccolta.",
          results_summary: function (number) {
            return "" + number + " contenuto " + pluralize(number, ["elementi", "elemento", "elementi"]);
          },
          linked_content: "Contenuto collegato",
          unlink: "Scollega contenuto",
        },
      },
      alerts: {
        no_updates: "Nessun aggiornamento recente",
        notifications_marked_as_read: "Notifica contrassegnata come letta",
        cleared_alerts: "Tutte le notifiche eliminate",
        alert_by_email:
          "Inviami un messaggio e-mail quando qualsiasi contenuto elencato di seguito viene aggiornato con nuova versione oppure quando una raccolta viene aggiornata con nuovo contenuto",
        action_types: {
          create: "creato",
          update: "modificato",
          delete: "eliminato",
          added_entity: "versione aggiunta",
          removed_entity: "versione rimossa",
          added_collection: "contenuto aggiunto",
          removed_collection: "contenuto rimosso",
        },
        property_names: {
          description: "Dettagli",
          searchCriteria: "Informazioni sui criteri di ricerca",
          groupCategory: "Informazioni su gruppi e categorie",
          support: "Informazioni supporto",
          licenses: "Informazioni sulle licenze",
          relatedContent: "Informazioni sul contenuto correlato",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Aggiornato " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Aggiornamento delle preferenze di notifica riuscito",
          preferences_update_failed: "Si è verificato un errore durante l'aggiornamento delle preferenze di notifica",
        },
      },
      browseDialog: {
        useNetworkDrive: "Usa cartella o unità di rete",
        ownerGroups: {
          title: "Gruppi proprietari",
        },
      },
      itemTypeCategories: {
        all: "Tutti i gruppi",
        application: "Applicazioni",
        "3dProduct": "Prodotti 3D",
        customComponent: "Componenti personalizzati",
        tool: "Applicazioni",
        profile: "Profili",
        material: "Materiali",
        bolt: "Bulloni",
        rebar: "Barre d'armatura",
        reportTemplate: "Report",
        drawingSetup: "File impostazione disegno",
        modelSetup: "File impostazione modello",
        environmentFile: "File di ambiente",
        shape: "Sagome",
        //'shapeCatalog': 'Catalogo sagome',
        //'foreignGeometryDefinition': 'Definizione di geometria esterna'
      },
      platforms: {
        WIN_X86: "32 bit",
        WIN_X64: "64 bit",
        ANY: "Indipendente da piattaforma",
      },
      platformsDownload: {
        WIN_X86: "32 bit",
        WIN_X64: "64 bit",
        ANY: " ",
      },
      contentItemSource: {
        other: "Generale - a cartella modello",
        run: "Avvia",
        bolt: "Assemblaggio bullone",
        component: "Componente",
        drawing: "Proprietà disegno (BETA)",
        material: "Materiale",
        profile: "Profilo",
        mesh: "Rete d'armatura",
        rebar: "Barra d'armatura",
        shape: "Sagoma",
        geometry: "Geometria",
        modeltemplate: "Modello prototipo",
        cloningtemplate: "Template di clonazione",
        noaction: "Nessuna azione",
        releasenotes: "Note sulla versione",
      },
      contentItemHeader: {
        bolt: "Assemblaggi di bulloni",
        component: "Componenti",
        drawing: "Proprietà disegno (BETA)",
        material: "Materiali",
        profile: "Profili",
        mesh: "Reti d'armatura",
        rebar: "Barre d'armatura",
        shape: "Sagome",
      },
      useCategoryGroups: {
        workflow: "Flusso di lavoro",
        structures: "Strutture",
        drawings: "Disegni",
        modeling: "Modellazione",
      },
      useCategories: {
        title: "Utilizzato in/per",
        conceptualDesign: "Progettazione concettuale",
        designEngineeringAnalysis: "Progettazione/Ingegneria/Analisi",
        detailing: "Lavorazioni",
        fabrication: "Produzione",
        erectionInstallationPour: "Costruzione/Installazione/Getto",
        contracting: "Appalto",
        scheduling: "Programmazione",
        takeOffEstimation: "Take-off/Stima",
        concreteStructures: "Calcestruzzo gettato in opera",
        precastConcrete: "Calcestruzzo prefabbricato",
        offshore: "Offshore",
        steelStructures: "Strutture in acciaio",
        timberStructures: "Strutture in legno",
        reinforcement: "Armatura",
        drawingCreation: "Creazione disegno",
        drawingManagement: "Gestione disegni",
        drawingEditing: "Modifica disegni",
        drawingAnnotation: "Annotazione disegni",
        viewSimulateNavigate: "Visualizza, simula, sposta",
        compareReviewManageData: "Confronta, rivedi, gestisci dati",
        softwareCollaboration: "Collaborazione software",
        productivity: "Produttività",
        interoperability: "Interoperabilità",
        systemSetup: "Configurazione di sistema",
      },
      measurementUnits: {
        "0": "Metrico",
        "1": "Imperiale",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "Sketchup",
        openBIM: "Open BIM (IFC)",
        standalone: "Autonomo",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Non specifico della versione",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'Sketchup 7',
        //'SK1': 'Sketchup 8'
      },
      qualityTags: {
        experimental: "Sperimentale",
        candidate: "Candidato",
        approved: "Approvato",
        standard: "Standard",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Inglese",
        "zh-Hans": "Cinese - Semplificato",
        "zh-Hant": "Cinese - Tradizionale",
        cs: "Ceco",
        da: "Danese",
        nl: "Olandese",
        fi: "Finlandese",
        fr: "Francese",
        de: "Tedesco",
        hu: "Ungherese",
        it: "Italiano",
        ja: "Giapponese",
        ko: "Coreano",
        pl: "Polacco",
        pt: "Portoghese",
        "pt-br": "Portoghese - Brasiliano",
        ru: "Russo",
        es: "Spagnolo",
        sv: "Svedese",
        no: "Norvegese",
        other: "Altro",
      },
      isoLangs: {
        ab: {
          name: "Abkhaz",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Afar",
          nativeName: "Afaraf",
        },
        af: {
          name: "Afrikaans",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Akan",
          nativeName: "Akan",
        },
        sq: {
          name: "Albanese",
          nativeName: "Shqip",
        },
        am: {
          name: "Amarico",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Arabo",
          nativeName: "العربية",
        },
        an: {
          name: "Aragonese",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Armeno",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Assamese",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Avarico",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Avestico",
          nativeName: "avesta",
        },
        ay: {
          name: "Aymara",
          nativeName: "aymar aru",
        },
        az: {
          name: "Azero",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Bambara",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Baschiro",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Basco",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Bielorusso",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Bengalese",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Bihari",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Bislama",
          nativeName: "Bislama",
        },
        bs: {
          name: "Bosniaco",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Bretone",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Bulgaro",
          nativeName: "български език",
        },
        my: {
          name: "Birmano",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Catalano; Valenziano",
          nativeName: "Català",
        },
        ch: {
          name: "Chamorro",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Chechen",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Chichewa; Chewa; Nyanja",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Cinese, semplificato e cinese",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Cinese - Semplificato",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Cinese - Tradizionale",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Cinese - Taiwanese",
          nativeName: "國語",
        },
        cv: {
          name: "Ciuvascio",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Cornico",
          nativeName: "Kernewek",
        },
        co: {
          name: "Corso",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Cree",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Croato",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Ceco",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Danese",
          nativeName: "dansk",
        },
        dv: {
          name: "Divehi; Dhivehi; Maldiviano;",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Olandese",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Inglese",
          nativeName: "English",
        },
        eo: {
          name: "Esperanto",
          nativeName: "Esperanto",
        },
        et: {
          name: "Estone",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Ewe",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Faroese",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Figiano",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Finlandese",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Francese",
          nativeName: "Français",
        },
        ff: {
          name: "Fula; Fulah; Pulaar; Pular",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Gallego",
          nativeName: "Galego",
        },
        ka: {
          name: "Georgiano",
          nativeName: "ქართული",
        },
        de: {
          name: "Tedesco",
          nativeName: "Deutsch",
        },
        el: {
          name: "Greco, moderno",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Guaraní",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Gujarati",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Haitiano; Creolo haitiano",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Hausa",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Ebraico (moderno)",
          nativeName: "עברית",
        },
        hz: {
          name: "Herero",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Hindi",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Hiri Motu",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Ungherese",
          nativeName: "Magyar",
        },
        ia: {
          name: "Interlingua",
          nativeName: "Interlingua",
        },
        id: {
          name: "Indonesiano",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Interlingue",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Irlandese",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Igbo",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Inupiaq",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Ido",
          nativeName: "Ido",
        },
        is: {
          name: "Islandese",
          nativeName: "Íslenska",
        },
        it: {
          name: "Italiano",
          nativeName: "Italiano",
        },
        iu: {
          name: "Inuktitut",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Giapponese",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Giavanese",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Kalaallisut, Groenlandese",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Kannada",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Kanuri",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Kashmiri",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Kazaco",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Khmer",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Kikuyu, Gikuyu",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Kinyarwanda",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Kirghiso",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Komi",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Kongo",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Coreano",
          nativeName: "한국어",
        },
        ku: {
          name: "Curdo",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Kwanyama, Kuanyama",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Latino",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Lussemburghese",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Luganda",
          nativeName: "Luganda",
        },
        li: {
          name: "Limburghese",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Lingala",
          nativeName: "Lingála",
        },
        lo: {
          name: "Laotiano",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Lituano",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Luba-Katanga",
          nativeName: "",
        },
        lv: {
          name: "Lettone",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Manx",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Macedone",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Malgascio",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Malese",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Malayalam",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Maltese",
          nativeName: "Malti",
        },
        mi: {
          name: "Māori",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Marathi (Marāṭhī)",
          nativeName: "मराठी",
        },
        mh: {
          name: "Marshallese",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Mongolo",
          nativeName: "монгол",
        },
        na: {
          name: "Nauru",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Navajo, Navaho",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Norvegese Bokmål",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Ndebele del nord",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Nepalese",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ndonga",
          nativeName: "Owambo",
        },
        nn: {
          name: "Nynorsk norvegese",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Norvegese",
          nativeName: "Norsk",
        },
        ii: {
          name: "Nuosu",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Ndebele del sud",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Occitano",
          nativeName: "Occitan",
        },
        oj: {
          name: "Ojibwe, Ojibwa",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Variante slavo ecclesiastico, Slavo ecclesiastico, Slavonico ecclesiastico, Bulgaro antico, Slavonico antico",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Oromo",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Oriya",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Osseto, ossetico",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Panjabi, Punjabi",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Pāli",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Persiano",
          nativeName: "فارسی",
        },
        pl: {
          name: "Polacco",
          nativeName: "polski",
        },
        ps: {
          name: "Pashto, Pushto",
          nativeName: "پښتو",
        },
        pt: {
          name: "Portoghese",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Portoghese - Brasile",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Quechua",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Romancio",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Kirundi",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Romeno, Moldavo, Moldova",
          nativeName: "română",
        },
        ru: {
          name: "Russo",
          nativeName: "русский",
        },
        sa: {
          name: "Sanscrito (Saṁskṛta)",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Sardo",
          nativeName: "sardu",
        },
        sd: {
          name: "Sindhi",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Sami settentrionale",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Samoano",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Sango",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Serbo",
          nativeName: "српски језик",
        },
        gd: {
          name: "Gaelico scozzese; Gaelico",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Shona",
          nativeName: "chiShona",
        },
        si: {
          name: "Sinhala, Singalese",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Slovacco",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Sloveno",
          nativeName: "slovenščina",
        },
        so: {
          name: "Somalo",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Sotho meridionale",
          nativeName: "Sesotho",
        },
        es: {
          name: "Spagnolo", //      'name':'tradizionale/Spagnolo',
          nativeName: "español",
        },
        su: {
          name: "Sundanese",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Swahili",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Swati",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Svedese",
          nativeName: "svenska",
        },
        ta: {
          name: "Tamil",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Telugu",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Tagico",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Tailandese",
          nativeName: "ไทย",
        },
        ti: {
          name: "Tigrino",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Tibetano standard, Tibetano, Centrale",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Turkmeno",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Pilipino",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Tswana",
          nativeName: "Setswana",
        },
        to: {
          name: "Tonga (Isole Tonga)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Turco",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Tsonga",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Tartaro",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Twi",
          nativeName: "Twi",
        },
        ty: {
          name: "Tahitiano",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Uighur, Uyghur",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Ucraino",
          nativeName: "українська",
        },
        ur: {
          name: "Urdu",
          nativeName: "اردو",
        },
        uz: {
          name: "Uzbeco",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Venda",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Vietnamita",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Volapük",
          nativeName: "Volapük",
        },
        wa: {
          name: "Vallone",
          nativeName: "Walon",
        },
        cy: {
          name: "Gallese",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Wolof",
          nativeName: "Wollof",
        },
        fy: {
          name: "Frisone occidentale",
          nativeName: "Frysk",
        },
        xh: {
          name: "Xhosa",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Yiddish",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Yoruba",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Zhuang, Chuang",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Altro",
          nativeName: "Altro",
        },
      },

      details: {
        navigation: {
          content_information: "Informazioni contenuto",
          version_and_file_management: "Gestione file e versioni",
          details: "Dettagli",
          versions: "Versioni",
          analytics: "Analisi",
          statistics: "Statistiche",
          manage_access: "Gestione accesso",
          related_content: "Correlato",
          properties: "Proprietà",
          support: "Azienda e supporto",
          release_notes: "Release notes",
          comments: "Commenti utente",
        },
        related_content: {
          other_content_title: "Altro contenuto che potrebbe piacerti",
          related_content_title: "Contenuto consigliato da ",
          related_content_url: "URL contenuto",
          add_related_content: "Aggiungi contenuto correlato",
          add_related_content_info:
            "È possibile aggiungere solo contenuto online relativo al contenuto online e contenuto locale relativo al contenuto locale",
          remove_related_content: "Rimuovi",
          relatedContentUrlSubtitle: "ID contenuto o URL completo al contenuto",
          updated_successfully: "Aggiornamento contenuto correlato riuscito",
          update_failed: "Errore durante l'aggiornamento del contenuto correlato",
          update_failed_invalid_id: "ID contenuto fornito non valido",
        },
        actions: {
          move: "Muovi",
          delete: "Elimina",
          add_comment: "Aggiungi commento",
          add_reply: "Aggiungi risposta",
          edit_comment: "Modifica commento",
          reply_comment: "Aggiungi risposta",
          view_replies: "Visualizza risposte",
          report_bad_comment: "Segnala commento negativo &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Modifica titolo o elimina",
            title_or_move_delete: "Modifica titolo, sposta o elimina",
            thumbnail: "Modifica immagine",
            description: "Modifica descrizione",
            groupandcategory: "Modifica gruppo e categoria",
            details: "Modifica dettagli",
            versions: "Modifica versioni",
            support: "Modifica informazioni di supporto",
            release_notes: "Modifica note sulla versione",
            search_criteria: "Modifica criteri di ricerca",
            batchedit_and_translations:
              "Con la funzionalità di traduzione è possibile pubblicare il contenuto in più lingue. La versione tradotta del contenuto sarà visualizzata agli utenti in base alla selezione della lingua dell'interfaccia utente di Warehouse. Con Modifica in batch è possibile modificare più elementi contemporaneamente. Selezionare prima un campo, quindi tutti gli elementi richiesti",
          },
        },
        links: {
          link: "Collega",
          select_collection_placeholder: "Seleziona raccolta da collegare",
          linking_collections_label: "Raccolte di collegamento a questa",
          private_content_note: "Nota: i contenuti privati rimarranno visibili solo agli utenti che vi hanno accesso.",
          share_on_social_media: "Condividi su:",
          report_bad_content: "Segnala contenuto non valido &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Segnala contenuti illegali &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Sottoscrivendo la ricezione delle notifiche, l'utente accetta di condividere il nome visualizzato in Tekla Account e il nome dell'organizzazione (se pertinente) con il proprietario del contenuto.",
          alert_me: "Invia notifiche",
          unsubscribe: "Annulla sottoscrizione",
          subscribed_successfully: "Sottoscrizione riuscita",
          unsubscribed_successfully: "Annullamento sottoscrizione riuscito",
          subscribers_label: "Iscritti",
        },
        reviews: {
          reviewers_label: "Revisori",
        },
        comments: {
          replies: "Risposte",
        },
        scan: {
          soft_failed_binaries_info: "Scansione antivirus non riuscita  ",
          hard_failed_binaries_info: "Impossibile eseguire la scansione antivirus ",
          infected_binaries_info: "Infezione rilevata da scansione antivirus ",
          what_now: "Cosa fare ora?",
          popup: {
            soft_failed_binaries_title: "File binari non riusciti",
            hard_failed_binaries_title: "File binari non riusciti",
            infected_binaries_title: "File binari infetti",
            soft_failed_binaries_info:
              "Scansione antivirus dei file caricati non riuscita. Il problema è solo temporaneo. Rimuovere i file e caricarli nuovamente. Tutti i file devono superare la scansione antivirus prima di essere visibili agli altri utenti.",
            hard_failed_binaries_info:
              "Impossibile eseguire la scansione antivirus. Rimuovere i file. Tutti i file devono superare la scansione antivirus prima di essere visibili agli altri utenti.",
            infected_binaries_info:
              "La scansione antivirus ha rilevato un'infezione nei file caricati. Rimuovere i file. Tutti i file devono superare la scansione antivirus prima di essere visibili agli altri utenti.",
            version: "Versione: ",
            fileName: "Nome file: ",
          },
        },
        status: {
          content_banned_info:
            "Il contenuto viene bloccato dall'amministratore. Al momento non è disponibile per altri utenti ad eccezione del proprietario del contenuto. <a href='https://support.tekla.com/contact-us' target='_blank'>Contatta l'assistenza</a> per ulteriori informazioni.",
          content_archived_info:
            "Questo contenuto è stato archiviato. Gli utenti senza diritti di modifica saranno reindirizzati alla prima pagina se cercano di visitare il contenuto tramite un collegamento diretto.",
          archived: "Archiviato",
        },
        modified_by: function (name) {
          return " da " + name;
        },
        created_by: function (name) {
          return "Creato da " + name;
        },
        deleteEntity: function (title) {
          return "Elimina " + title + "?";
        },
        userComments: "Commenti utente",
        comment_added: "Aggiunta del commento riuscita",
        comment_updated: "Aggiornamento commento riuscito",
        comment_deleted: "Eliminazione commento riuscita",
        comment_add_failed: "Impossibile aggiungere il commento",
        comment_update_failed: "Impossibile aggiornare il commento",
        comment_delete_failed: "Impossibile eliminare il commento",
        comment_delete_confirmation: "Eliminare l'elemento commento?",
        name: "Nome",
        details: "Dettagli",
        contentOwner: "Autore",
        contentOwnerGroup: "Gruppo proprietario contenuto",
        contentGroup: "Gruppo contenuto",
        thumbnail: "Immagine",
        tooltip: "Descrizione comando",
        description: "Descrizione",
        additional_information: "Informazioni aggiuntive",
        images: "Immagini prodotto",
        installationInfo: "Informazioni sull'installazione",
        productCode: "Codice prodotto",
        copyrightInfo: "Copyright",
        productExpirationDate: "Data di scadenza prodotto",
        helpUrl: "Sito Web &rsaquo;",
        supportUrl: "Supporto &rsaquo;",
        specialTermsUrl: "EULA &rsaquo;",
        releaseNotesUrl: "Note sulla versione",
        supportForumUrl: "Discussion Forum &rsaquo;",
        itemTypeCategories: "Gruppo",
        useCategories: "Categoria",
        category: "Categoria:",
        group: "Gruppo:",
        compatibleSoftwareProducts: "Prodotti software compatibili",
        compatibleOperatingSystems: "Sistemi operativi compatibili",
        locationRestrictions: "Disponibilità prodotti:",
        supportedLanguages: "Lingue:",
        measurementUnits: "Unità di misura:",
        tags: "Etichette e parole chiave:",
        prerequisites: "Prerequisiti:",
        releaseNotes: "Note sulla versione:",
        versions: "Versioni",
        testedVersions: "Versioni compatibili",
        qualityTag: "Qualità - valutata dal proprietario",
        otherAttributes: "Altri attributi",
        labels: "Criteri di ricerca",
        supportInformation: "Supporto",
        updateInformation: "Aggiornamenti",
        latestUpdate: "Aggiornamento più recente",
        totalDownloads: "Totale download",
        licenses: "Licenze",
        maintenanceRequired: "Download solo con contratto di manutenzione Tekla",
        tekla_maintenance_content: "Contenuto sotto contratto di manutenzione Tekla",
        download_requires_tekla_maintenance: "Download solo con contratto di manutenzione Tekla",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Aggiornamento riuscito",
        update_failed: "Aggiornamento non riuscito",
        update_failed_invalid_tag: "Aggiornamento non riuscito: etichetta non valida",
        update_failed_details_max_length_exceeded: "Aggiornamento non riuscito: dettagli troppo lunghi",
        show_version_details: "Informazioni aggiuntive",
        content_removed: "Contenuto rimosso",
        no_versions_available:
          "Nessuna versione disponibile. La visualizzazione degli aggiornamenti può richiedere del tempo. Aggiornare il browser per visualizzare le modifiche.",
        thumbnail_deleted: "Immagine eliminata",
        thumbnail_updated: "Immagine aggiornata",
        verify_service_plugin_is_running:
          "Verificare che il plug-in Tekla Warehouse Service sia eseguito correttamente",
        verify_ts_connection: "Verificare che Tekla Structures sia in esecuzione",
        download_tos: {
          title: "Qualità, supporto e privacy",
          content_1: "Assicurati che il contenuto scaricato sia conforme agli standard di qualità. Trimble non fornisce assistenza e non è responsabile del contenuto di terze parti. Contattare direttamente il proprietario del contenuto per richiedere assistenza.",
          content_2: "Il paese e il nome dell'organizzazione potrebbero essere pubblicati nel servizio e condivisi con i proprietari del contenuto per scopi legittimi come aggiornarti sulle modifiche del contenuto. È possibile annullare l'iscrizione a queste notifiche.",
          content_3: `Per le estensioni di contenuto Trimble, si applicano i <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">termini di Trimble</a>. I termini di licenza di terze parti possono applicarsi al contenuto di terze parti. Vedi l'<a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Informativa sulla privacy di Trimble</a>.`,
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Questo è l'archivio BIM gratuito di Tekla Structures. Trova, importa, installa e condividi a livello interno e a livello globale. Crea modelli di alta qualità in modo efficiente.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse è una raccolta online e locale gratuita per gli strumenti di modellazione, le parti e i modelli intelligenti di Tekla Structures",
        },
        search: {
          title: "Ricerca in Tekla Warehouse",
          description:
            "Ricerca di estensioni di Tekla, parti 3D, forme, componenti personalizzati, profili, materiali come acciaio e calcestruzzo, barre d'armatura, reti e modelli",
        },
        about: {
          title: "Informazioni su Tekla Warehouse",
          description:
            "Tekla Warehouse offre un canale marketing gratuito per i prodotti e consente il controllo completo dei contenuti",
          developers: {
            title: "Titolo sviluppatori di default",
            description: "Descrizione sviluppatori di default",
          },
          usage: {
            title: "Titolo utilizzo di default",
            description: "Descrizione utilizzo di default",
          },
          getting_started: {
            title: "Titolo Per iniziare di default",
            description: "Descrizione Per iniziare di default",
          },
          content: {
            title: "Titolo contenuto di default",
            description: "Descrizione contenuto di default",
          },
          using_content: {
            title: "Titolo Utilizzo di contenuto di default",
            description: "Descrizione Utilizzo di contenuto di default",
          },
          creating_content: {
            title: "Titolo Creazione di contenuto di default",
            description: "Descrizione Creazione di contenuto di default",
          },
        },
        collection: {
          title: "Titolo raccolta di default",
          description: "Descrizione raccolta di default",
        },
        my_warehouse: {
          title: "Il mio Tekla Warehouse",
          description: "Gestisci il contenuto di Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Annulla",
          previous: "&lsaquo; Precedente",
          next: "Successivo &rsaquo;",
        },
        content: {
          add_content: "Aggiungi file",
        },
        collection: {
          heading: "Seleziona o crea raccolta",
          subHeading:
            "Contenuto gruppo di raccolte. È possibile utilizzare le raccolte online per pubblicare il contenuto o le raccolte locali per condividere il contenuto con i colleghi o le raccolte locali per uso privato. È anche possibile utilizzare le raccolte locali per uso privato.",
          loading: "Caricamento raccolte...",
          selectFromMyCollections: "Seleziona da Raccolte personali",
          description_of_the_collection: "Descrizione",
          title_validation: {
            missing: "Immettere un titolo.",
            too_short: function (length) {
              return "Il titolo deve contenere almeno " + length + " caratteri.";
            },
            too_long: function (length) {
              return "La lunghezza del titolo non può essere superiore a " + length + " caratteri.";
            },
          },
        },
        package: {
          basic_information: "Informazioni di base",
          code_name: "Nome in codice",
          code_name_info:
            "Immettere un nome in codice come identificatore univoco per questo contenuto. Il nome in codice può includere lettere minuscole, numeri e questi caratteri speciali: _ - @. Non è possibile modificare il nome in codice dopo che è stato salvato.",
          code_name_validation: {
            missing: "È necessario immettere un nome in codice",
            wrong_format: "Il nome in codice può includere lettere minuscole, numeri e questi caratteri speciali: _ - @",
            not_unique: "Questo nome in codice è già in uso. È necessario immettere un nome in codice univoco.",
          },
          search_criteria: "Criteri di ricerca",
          support: "Supporto",
          title: "Titolo",
          thumbnail: "Immagine",
          "3D_pic": "Modello 3D",
          title_validation: {
            missing: "Immettere un titolo.",
            too_short: function (length) {
              return "Il titolo deve contenere almeno " + length + " caratteri.";
            },
          },
          description_validation: {
            missing: "Immettere una descrizione.",
            too_short: function (length) {
              return "La descrizione deve contenere almeno " + length + " caratteri.";
            },
          },
          wrong_file_type: "Tipo di file errato.",
          selected_image_is_too_big: "Immagine selezionata troppo grande.",
        },
        version: {
          select_version: "Seleziona versione",
          select_quality: "Seleziona qualità",
          versions: "Versioni",
          displayNameNew: "Nome visualizzato versione",
          copy_from_version: "Copia metadati da versione esistente (opzionale)",
          displayName: "Nome visualizzato",
          content_information: "Informazioni contenuto",
          back_to_content_information: "&lsaquo; Torna alle informazioni contenuto",
          version_and_file_management: "Gestione file e versioni",
          there_s_no_versions_yet: "Nessuna versione disponibile al momento.",
          create_a_version_to_add_files: "Crea una versione per aggiungere i file.",
          add_version: "Aggiungi versione",
          version_information: "Informazioni sulla versione",
          delete_version: "Elimina versione",
          no_versions_available:
            "Nessuna versione disponibile. La visualizzazione degli aggiornamenti può richiedere del tempo.",
          update_start_title_html: '<div class="spinner spinner-white">Aggiornamento</div>',
          update_start_message: "L'operazione potrebbe richiedere alcuni minuti.",
          version_was_created: "Versione creata.",
          version_was_created_with_delay: "Versione creata. Le modifiche saranno visibili tra alcuni istanti.",
          version_was_updated: "Versione aggiornata.",
          version_was_deleted: "Versione eliminata",
          version_was_deleted_with_delay: "Versione eliminata. Le modifiche saranno visibili tra alcuni istanti.",
          confirm_delete: function (title) {
            return 'Eliminare versione "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Il pacchetto è stato caricato.",
          couldn_t_create_a_package: "Impossibile creare un pacchetto. Le modifiche saranno ripristinate.",
          uploading_title_html: '<div class="spinner spinner-white">Upload in corso</div>',
          uploading_text: "L'operazione potrebbe richiedere alcuni minuti.",
          select_organization: "Seleziona organizzazione",
          collection_visibility: "Visibilità raccolta:",
          actions: {
            create: "Crea",
            publish: "Pubblica",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Il contenuto sarà visibile a tutti gli utenti con accesso all'unità di rete o locale.",
          this_content_will_be_visible_only_to_you: "Il contenuto sarà visibile solo all'utente corrente.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Il contenuto sarà visibile solo all'utente e a tutti gli utenti dell'organizzazione.",
          this_content_will_be_visible_to_everyone_after_publishing:
            "Il contenuto sarà visibile a tutti dopo la pubblicazione.",
          public_content_disclaimer:
            "Sei responsabile di tutti i contenuti che carichi. Assicurati che il contenuto non sia deleterio, che tu sia autorizzato a caricare il contenuto e che questo non andrà a violare alcun diritto di proprietà intellettuale. Acconsenti di pubblicare nel servizio tutti i termini e condizioni applicabili all'uso dei contenuti che carichi.",
        },
        metadata: {
          title: "Metadati",
          subtitle: "Dati di base",
          deleteThumbnail: "Elimina immagine",
          buttonCreate: "Crea",
        },
        basicdata: {
          title: "Aggiungi contenuto",
          clickButtonToSelectFile: "Clicca sul pulsante per selezionare il file",
          thumbnail: "Immagine",
          dropImageHere: "Trascina immagine qui",
        },
        "3dGeometry": {
          title: "Crea catalogo prodotti 3D",
          buttonAddEntity: "Aggiungi prodotto 3D",
          buttonAdd: "Aggiungi attributo personalizzato",
          errorAttributeAlreadyExists: "Attributo con lo stesso nome già esistente.",
          buttonCreatePackage: "Crea pacchetto",
          buttonCreatePackages: "Crea pacchetti",
          addCustomAttributePlaceholder: "Nome attributo",
        },
        please_log_in_fragments: {
          please: "Eseguire ",
          log_in: "Accedi",
          or: " oppure ",
          register: "la registrazione",
          to_upload_online_content: " per caricare il contenuto online.",
        },
        header: {
          upload_header: "Carica",
          collection: "Raccolta",
          content: "Contenuto",
          version: "Versione",
          finish: "Fine",
        },
        contentOwner: "Autore",
        contentOwnerGroup: "Pubblica contenuto",
        select_action: "Seleziona azione",
        tooltip: "Descrizione comando",
        description: "Descrizione",
        copyright: "Copyright",
        tags: "Etichette e parole chiave",
        tagsSubtitle:
          "Utilizza le virgole per separare, ad esempio: parole chiave 1, parole chiave 2. Se la parola chiave è composta da più parole, usare il carattere di sottolineatura (_) tra le parole, non sono consentiti spazi tra le parole. A parte il carattere di sottolineatura e la virgola, non sono consentiti altri caratteri speciali.",
        images: "Immagini",
        installationInfo: "Informazioni sull'installazione",
        productCode: "Codice prodotto",
        copyrightInfo: "Informazioni sul copyright",
        Details: "Dettagli",
        specialTermsUrl: "EULA",
        productExpirationDate: "Data di scadenza prodotto",
        helpUrl: "Sito Web",
        helpUrlSubtitle: "inserisci URL completo",
        detailsSubtitle:
          "In questa fase è possibile aggiungere solo testo normale nel campo Dettagli. Dopo che il contenuto è stato creato è possibile modificare i dettagli per contenere formattazione html.",
        specialTermsUrlSubtitle:
          "Il proprietario del contenuto ha la possibilità di aggiungere termini aggiuntivi per il proprio contenuto. Il proprietario deve fornire un URL in cui si trovano i termini specifici. Questo non prevale sui Termini di servizio di Trimble.",
        releaseNotes: "File note sulla versione",
        releaseNoteFileSubTitle: "Aggiungi note sulla versione come file",
        supportUrl: "Guida e supporto",
        supportUrlSubtitle: 'inserisci URL completo o prefisso "mailto:" per l\'invio tramite e-mail',
        supportForumUrl: "Discussion forum (sito Web)",
        supportForumUrlSubtitle: "inserisci URL completo",
        measurementUnits: "Unità di misura",
        itemTypeCategories: "Gruppo",
        useCategories: "Categoria",
        compatibleSoftwareProducts: "Prodotti software compatibili",
        testedVersions: "Versioni compatibili",
        compatibleOperatingSystems: "Sistema operativo compatibile",
        locationRestrictions: "Disponibilità prodotti",
        supportedLanguages: "Lingue",
        select_releasenotes_file: "Seleziona file",
        qualityTag: "Qualità - valutata dal proprietario",
        requireMaintenance: "Download solo con contratto di manutenzione clienti Tekla",
        contentOwnerGroupAsMyself: "Come utente corrente",
        contentOwnerGroupAsUserGroup: "Come gruppo utenti",
        selectUserGroupForContentOwner: "Seleziona gruppo utenti per il proprietario contenuto",
        defaultUploadTemplate: "Pacchetto di default",
        templateFor3DGeometryFiles: "Pacchetti geometria 3D",
        title: "Titolo",
        details: "Dettagli",
        selectContentOwner: "Seleziona proprietario contenuto",
        selectContentType: "Tipo",
        errorWrongfiletype: "Tipo di file errato.",
        errorImageTooBig: "Immagine selezionata troppo grande.",
        errorBlankTitle: "Il campo del titolo non può essere vuoto.",
        commonLabelsTitle: "Etichette comuni per tutti i pacchetti",
      },
      profile: {
        content_usage: "Utilizzo contenuto",
        profile_updated: "Profilo aggiornato",
        invitation_accepted: function (target) {
          return "Invito accettato. È ora possibile accedere a " + target + ".";
        },
        user_groups: {
          edit_user_group: "Modifica gruppo utenti",
          user_group_updated: "Gruppo utenti aggiornato",
          create_a_new_user_group: "Crea nuovo gruppo utenti",
          name_of_the_user_group: "Nome del gruppo utenti",
          description: "Descrizione",
          group_visibility: "Visibilità del gruppo",
          remove: '"Continuare?"',
          remove_invitations: '"Continuare?"',
          remove_members: '"Continuare?"',
          invitations_removed: "Inviti rimossi.",
          members_list_updated: "Lista membri aggiornata.",
          user_group_created: "Gruppo utenti creato",
        },
        members: {
          update_selected_members: "Aggiorna membri selezionati",
          new_role: "Nuovo ruolo",
          members_list_updated: "Lista membri aggiornata.",
          edit_user_group: "Modifica gruppo utenti",
          delete_user_group: "Elimina gruppo utenti",
          filter: "Filtro",
          user_group_members: "Membri gruppo utenti",
          update_members: "Aggiorna membri",
          remove_members: "Rimuovi membri",
          pending_invitations: "Inviti in sospeso",
          invite_member: "Invita membro",
          update_invitations: "Aggiorna inviti",
          remove_invitations: "Rimuovi inviti",
        },
        subTabs: {
          alerts: "Notifiche",
          collections: "Raccolte",
          likes: "Like",
        },
        admin: {
          grant_sales_admin: "Concedi il ruolo di amministratore delle vendite per l'utente",
          grant_analyst_role: "Concedi ruolo analista per l'utente",
          role_granted: "Ruolo concesso",
          role_removed: "Ruolo rimosso",
          offline_to_online: "Converti contenuto non in linea in contenuto in linea",
          use_elastic_search: "Usa ricerca flessibile",
        },
        sales: {
          title: "Vendite",
        },
        subtitlePreferences: "Preferenze di ricerca",
        preferenceInfo:
          'Questi filtri vengono usati per consigliarti i contenuti sulla home page di Tekla Warehouse <a href="#/landing">.</a>',
        clearFilters: "Cancella i filtri &rsaquo;",
        admin_role: "Admin",
        sales_role: "Vendite",
        admin_mode: "Modalità admin:",
        firstName: "Nome",
        lastName: "Cognome",
        displayName: "Nome visualizzato:",
        organization: "Nome organizzazione:",
        email: "E-mail:",
        teklaId: "ID Tekla",
        maintenanceAccess: "Accesso al contenuto di Tekla Maintenance:",
        myLocations: "Posizioni progetto",
        measurementUnits: "Unità",
        useCategories: "Utilizzo",
        itemTypeCategories: "Mi interessano i seguenti tipi",
        languages: "Lingue",
        operatingSystems: "Sistemi operativi",
        software: "Prodotti software",
        user_id: "ID utente:",
        organization_id: "ID organizzazione:",
        edit_in_atc: "Modifica profilo personale in Tekla Account",
        no_likes: "Nessun like",
        no_alerts: "Nessuna iscrizione",
        clear_alerts: "Cancella notifiche",
        alerts_info_html:
          "Verrà inviato una notifica quando viene eseguita una delle seguenti modifiche<br>" +
          "<b>Contenuto:</b> Il titolo, la descrizione, i criteri di ricerca o i metadati dell'elemento contenuto vengono modificati, viene aggiunta una nuova versione del contenuto oppure una versione viene eliminata.<br>" +
          "<b>Raccolta:</b> Il titolo o la descrizione viene modificata, il contenuto viene aggiunto o rimosso.",
      },
      uploader: {
        placeholders: {
          productCode: "Codice prodotto",
          title: "Nome",
          description: "Descrizione",
          productWeight: "Peso prodotto",
          weightUnit: "Unità di peso",
          material: "Materiale",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Catalogo di prodotti 3D",
          addToolButton: "Applicazione",
          select_files: "File dal disco",
          select_files_from_ts: "File da Tekla Structures",
          partnerDownloadLink: "Collegamento per il download dei partner",
        },
        headings: {
          geometryFile: "File geometria",
          thumbnailFile: "Immagine bitmap (.bmp)",
          title: "Nome",
          description: "Descrizione",
          productCode: "Codice prodotto",
          productWeight: "Peso prodotto",
          weightUnit: "Unità di peso",
          material: "Materiale",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Globale",
          AF: "Afghanistan",
          AX: "Isole Ãland",
          AL: "Albania",
          DZ: "Algeria",
          AS: "Samoa americane",
          AD: "Andorra",
          AO: "Angola",
          AI: "Anguilla",
          AQ: "Antartide",
          AG: "Antigua e Barbuda",
          AR: "Argentina",
          AM: "Armenia",
          AW: "Aruba",
          AU: "Australia",
          AT: "Austria",
          AZ: "Azerbaigian",
          BS: "Bahamas",
          BH: "Bahrein",
          BD: "Bangladesh",
          BB: "Barbados",
          BY: "Bielorussia",
          BE: "Belgio",
          BZ: "Belize",
          BJ: "Benin",
          BM: "Bermuda",
          BT: "Bhutan",
          BO: "Bolivia",
          BQ: "Bonaire",
          BA: "Bosnia ed Erzegovina",
          BW: "Botswana",
          BV: "Isola Bouvet",
          BR: "Brasile",
          IO: "Territorio britannico dell'Oceano Indiano",
          BN: "Brunei Darussalam",
          BG: "Bulgaria",
          BF: "Burkina Faso",
          BI: "Burundi",
          KH: "Cambogia",
          CM: "Camerun",
          CA: "Canada",
          CV: "Capo Verde",
          KY: "Isole Cayman",
          CF: "Repubblica Centrafricana",
          TD: "Ciad",
          CL: "Cile",
          CN: "Cina",
          CX: "Isola Christmas",
          CC: "Isole Cocos (Keeling)",
          CO: "Colombia",
          KM: "Comore",
          CG: "Repubblica del Congo",
          CD: "Repubblica Democratica del Congo",
          CK: "Isole Cook",
          CR: "Costa Rica",
          CI: "Côte d'Ivoire",
          HR: "Croazia",
          CU: "Cuba",
          CW: "Curaçao",
          CY: "Cipro",
          CZ: "Repubblica Ceca",
          DK: "Danimarca",
          DJ: "Gibuti",
          DM: "Dominica",
          DO: "Repubblica Dominicana",
          EC: "Ecuador",
          EG: "Egitto",
          SV: "El Salvador",
          GQ: "Guinea Equatoriale",
          ER: "Eritrea",
          EE: "Estonia",
          ET: "Etiopia",
          FK: "Isole Falkland (Malvine)",
          FO: "Fær Øer",
          FJ: "Figi",
          FI: "Finlandia",
          FR: "Francia",
          GF: "Guayana francese",
          PF: "Polinesia Francese",
          TF: "Terre Australi Francesi",
          GA: "Gabon",
          GM: "Gambia",
          GE: "Georgia",
          DE: "Germania",
          GH: "Ghana",
          GI: "Gibilterra",
          GR: "Grecia",
          GL: "Groenlandia",
          GD: "Grenada",
          GP: "Guadalupa",
          GU: "Guam",
          GT: "Guatemala",
          GG: "Guernsey",
          GN: "Guinea",
          GW: "Guinea-Bissau",
          GY: "Guyana",
          HT: "Haiti",
          HM: "Heard e McDonald",
          VA: "Città del Vaticano",
          HN: "Honduras",
          HK: "Hong Kong",
          HU: "Ungheria",
          IS: "Islanda",
          IN: "India",
          ID: "Indonesia",
          IR: "Iran",
          IQ: "Iraq",
          IE: "Irlanda",
          IM: "Isola di Man",
          IL: "Israele",
          IT: "Italia",
          JM: "Giamaica",
          JP: "Giappone",
          JE: "Jersey",
          JO: "Giordania",
          KZ: "Kazakstan",
          KE: "Kenya",
          KI: "Kiribati",
          KW: "Kuwait",
          KG: "Kirghizistan",
          LA: "Laos",
          LV: "Lettonia",
          LB: "Libano",
          LS: "Lesotho",
          LR: "Liberia",
          LY: "Libia",
          LI: "Liechtenstein",
          LT: "Lituania",
          LU: "Lussemburgo",
          MO: "Macao",
          MK: "Macedonia",
          MG: "Madagascar",
          MW: "Malawi",
          MY: "Malaysia",
          MV: "Maldive",
          ML: "Mali",
          MT: "Malta",
          MH: "Isole Marshall",
          MQ: "Martinica",
          MR: "Mauritania",
          MU: "Mauritius",
          YT: "Mayotte",
          MX: "Messico",
          FM: "Micronesia",
          MD: "Moldova",
          MC: "Monaco",
          MN: "Mongolia",
          ME: "Montenegro",
          MS: "Montserrat",
          MA: "Marocco",
          MZ: "Mozambico",
          MM: "Myanmar (Burma)",
          NA: "Namibia",
          NR: "Nauru",
          NP: "Nepal",
          NL: "Paesi Bassi",
          NC: "Nuova Caledonia",
          NZ: "Nuova Zelanda",
          NI: "Nicaragua",
          NE: "Niger",
          NG: "Nigeria",
          NU: "Niue",
          NF: "Norfolk",
          KP: "Corea del Nord",
          MP: "Isole Marianne Settentrionali",
          NO: "Norvegia",
          OM: "Oman",
          PK: "Pakistan",
          PW: "Palau",
          PS: "Territorio Palestinese",
          PA: "Panama",
          PG: "Papua Nuova Guinea",
          PY: "Paraguay",
          PE: "Perù",
          PH: "Filippine",
          PN: "Pitcairn",
          PL: "Polonia",
          PT: "Portogallo",
          PR: "Portorico",
          QA: "Qatar",
          RE: "Réunion",
          RO: "Romania",
          RU: "Federazione russa",
          RW: "Ruanda",
          BL: "Saint Barthélemy",
          SH: "Sant'Elena",
          KN: "Saint Kitts e Nevis",
          LC: "Saint Lucia",
          MF: "Saint Martin",
          PM: "Saint-Pierre e Miquelon",
          VC: "Saint Vincent e Grenadine",
          WS: "Samoa",
          SM: "San Marino",
          ST: "São Tomé e Príncipe",
          SA: "Arabia Saudita",
          SN: "Senegal",
          RS: "Serbia",
          SC: "Seychelles",
          SL: "Sierra Leone",
          SG: "Singapore",
          SX: "Saint-Martin",
          SK: "Slovacchia",
          SI: "Slovenia",
          SB: "Isole Salomone",
          SO: "Somalia",
          ZA: "Sudafrica",
          GS: "Georgia del Sud",
          KR: "Corea del Sud",
          SS: "Sudan del Sud",
          ES: "Spagna",
          LK: "Sri Lanka",
          SD: "Sudan",
          SR: "Suriname",
          SJ: "Svalbard e Jan Mayen",
          SZ: "Swaziland",
          SE: "Svezia",
          CH: "Svizzera",
          SY: "Repubblica araba siriana",
          TW: "Taiwan",
          TJ: "Tagikistan",
          TZ: "Tanzania",
          TH: "Thailandia",
          TL: "Timor-Leste",
          TG: "Togo",
          TK: "Tokelau",
          TO: "Tonga",
          TT: "Trinidad e Tobago",
          TN: "Tunisia",
          TR: "Turchia",
          TM: "Turkmenistan",
          TC: "Isole Turks e Caicos",
          TV: "Tuvalu",
          UG: "Uganda",
          UA: "Ucraina",
          AE: "Emirati Arabi Uniti",
          GB: "Regno Unito",
          US: "Stati Uniti",
          UM: "Altre isole americane del Pacifico",
          UY: "Uruguay",
          UZ: "Uzbekistan",
          VU: "Vanuatu",
          VE: "Venezuela",
          VN: "Vietnam",
          VG: "Isole Vergini (Britanniche)",
          VI: "Isole Vergini (USA)",
          WF: "Wallis e Futuna",
          EH: "Sahara Occidentale",
          YE: "Yemen",
          ZM: "Zambia",
          ZW: "Zimbabwe",
        },
        sortOptions: {
          "-modifyTime": "Data ultimo aggiornamento",
          "-modifiedAt": "Data ultimo aggiornamento",
          title: "Ordine alfabetico A-Z",
          "-title": "Ordine alfabetico Z-A",
          "-reviewCount": "Più apprezzati",
          name: "Nome",
          role: "Ruolo",
          email: "E-mail",
          "-relevance": "Rilevanza",
          "-createdAt": "Ultima creazione",
          displayName: "Ordine alfabetico A-Z",
          "-displayName": "Ordine alfabetico Z-A",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Oggi",
          yesterday: "Ieri",
          this_week: "Questa settimana",
          previous_week: "Settimana precedente",
          this_month: "Questo mese",
          previous_month: "Mese precedente",
          this_year: "Anno in corso",
          previous_year: "Anno precedente",
          beginning_of_time: "Inizio della fase",
          custom: "Periodo di tempo personalizzato",
        },
        eventTypeOptions: {
          downloads: "Download",
          views: "Visualizzazioni",
          all_events: "Tutti gli eventi",
        },
      },
    });
  },
};
