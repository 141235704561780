import { IEntity } from "../models/dataModel";
import { observable, makeObservable } from "mobx";
import { AbstractAsyncStore } from "./abstractAsyncStore";
import { RootStore } from "./rootStore";

/**
 * Base class for stores that operate on entities.
 */
export abstract class EntitiesStore extends AbstractAsyncStore {
  /**
   * Entities that are stored in the store
   */
  @observable protected entities: IEntity[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  /**
   * Gets entities that are stored in the store.
   * @return entities
   */
  public getEntities(): IEntity[] {
    return this.entities;
  }

  /**
   * Processes entities passed as parameter and defines methods that return paths to entity and creators.
   * @param entities to be updated
   * @returns processed entities
   */
  protected setPaths(entities: IEntity[]): IEntity[] {
    return (entities || []).map((entity: IEntity) => {
      entity.getEntityPath = function () {
        return "/catalog/details/" + this.id;
      };
      entity.getOrganizationPath = function () {
        return "/organization/" + this.creator!.id;
      };
      return entity;
    });
  }
}
