import { createContext } from "react";
import { action, runInAction, makeObservable } from "mobx";

import { AnalyticsService } from "../../js/services/AnalyticsService";
import { TCCSearchService } from "../../js/services/TCCSearchService";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { EntitiesStore } from "../../stores/entitiesStore";

import { IEntity, ICollection, IPackageSearchQuery, IGetResourcePath } from "../../models/dataModel";
import { AnalyticsEventTypeEnum, ExternalResourceTypeEnum } from "../../models/enums";

/**
 * A store that can be used to fetch the newest packages added to Tekla Warehouse.
 * It takes current locale into account.
 */
export class NewestEntitiesStore extends EntitiesStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.fetchData();
  }

  /**
   * Gets 5 the newest packages supporting current locale.
   */

  @action
  public async fetchData() {
    this.dataFetched = false;
    this.entities = [];
    const organizationType = ExternalResourceTypeEnum.ORGANIZATION;

    const queryParams: IPackageSearchQuery = {
      offset: 0,
      count: 5,
      showBinaryMetadata: true,
      sortBy: "createTime DESC",
      fq: `creator.externalResourceType==${organizationType}`,
    };

    const lang = this.rootStore.getLangKeyBySelectedLocale();
    if (lang) {
      queryParams.fq =
        `(attribute:supportedLanguages:${lang}:boolean==true);` + `creator.externalResourceType==${organizationType}`;
    }

    try {
      const pkg = await TCCSearchService.searchPackages(
        queryParams,
        true,
        this.rootStore.getUserStore().getCurrentUser()?.id,
      );
      const entities = this.setPaths(pkg);

      runInAction(() => {
        this.dataFetched = true;
        this.entities = entities;
      });
    } catch {
      console.log("Could not fetch newest entities");
    }
  }

  /**
   * Method that handles routing state when clicked in resource
   */
  public getResourcePath(resource: IEntity | ICollection): string {
    return "/catalog/details/" + resource.id;
  }

  /**
   * Sends analytics data for content viewed
   * @param resource
   */
  public async sendAnalyticsData(resource: IEntity | ICollection): Promise<any> {
    try {
      await AnalyticsService.contentViewed(
        this.rootStore.getUserStore().getCurrentUser(),
        resource,
        AnalyticsEventTypeEnum.NEWEST_CONTENT_VIEWED,
      );
    } catch {
      console.log("Could not send analytics data");
    }
  }
}

/**
 * Context object for NewestEntitieStore instances.
 */
export const NewestEntitiesContext = createStoreContext<NewestEntitiesStore>(NewestEntitiesStore);

/**
 * Context object for IGetResourcePath instances (of classes that implement IGetResourcePath interface).
 */
export const IGetResourcePathContext = createContext<IGetResourcePath | undefined>(undefined);
