import * as React from "react";

/**
 * Renders a spinner that indicates loading content
 * @param loadingWhat optional 'what are we loading' parameter
 */
export const Spinner = ({ loadingWhat }: { loadingWhat: string }) => {
  return (
    <div className="loading-indicator" data-testid={loadingWhat ? `loading-${loadingWhat}` : "loading"}>
      <div className="spinner"></div>
    </div>
  );
};
