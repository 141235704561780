import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext, useState } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStoreContext, EditorModeStore } from "../../components/editor-mode/editorModeStore";
import { PackagePageStoreContext, PackagePageStore } from "../packagePageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { TextWrapper } from "../../components/TextWrapper";
import { Dialog } from "../../dialogs/Dialog";
import { EditDescriptionDialog } from "../../dialogs/edit/EditDescriptionDialog";

import { IItem } from "../../models/dataModel";
import { SanitizationModeEnum } from "../../models/enums";
import { stripHtml } from "../../utils/functions";

const EditDescription = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);
  const dialog: DialogStore = useContext(DialogContext);
  const packagePageStore: PackagePageStore = useContext(PackagePageStoreContext);
  const packageItem = packagePageStore.getPackage();

  function canEdit() {
    return !!packageItem && packagePageStore.canEditPackage() && editorModeStore.getEditorMode();
  }

  function editDescription() {
    dialog.open();
  }

  return (
    <Fragment>
      {canEdit() && (
        <DialogContext.Provider value={dialog}>
          <a
            data-testid="open-edit-description-dialog"
            title={rootStore.getTranslation("shared.catalog_entity_edit.edit_description")}
            onClick={editDescription}
            className="icon icon-pencil"
          >
            <span>{rootStore.getTranslation("shared.catalog_entity_edit.edit_description")}</span>
          </a>
          <Dialog
            content={<EditDescriptionDialog item={packageItem! as IItem} store={packagePageStore} />}
            additionalClass={packageItem!.isLocal ? "basic-edit-local" : "basic-edit"}
          />
        </DialogContext.Provider>
      )}
    </Fragment>
  );
});

/**
 * A component that displays package description.
 */
export const PackageDescription = observer(({ description }: { description: string }) => {
  const rootStore = useContext(RootContext);
  const [showShortDescription, setShowShortDescription] = useState(descriptionLimitExceeded());

  function descriptionLimitExceeded() {
    return description && stripHtml(description).length > 300;
  }

  function showMore() {
    setShowShortDescription(false);
  }

  function showLess() {
    setShowShortDescription(true);
  }

  return (
    <Fragment>
      <ul className="description-labels"></ul>
      <EditDescription />
      {showShortDescription ? (
        <div className="shortDescription">
          <p data-testid="short-description" className="sanitized-description">
            <TextWrapper
              text={description}
              cssClasses={["translated-html"]}
              sanitizationMode={SanitizationModeEnum.MODERATE}
              truncate={300}
            />
          </p>
          <a data-testid="show-more" onClick={showMore}>
            <span>{rootStore.getTranslation("shared.show_more")}</span>
          </a>
        </div>
      ) : (
        <div className="longDescription">
          <p data-testid="long-description" className="sanitized-description">
            <TextWrapper
              text={description}
              cssClasses={["translated-html"]}
              sanitizationMode={SanitizationModeEnum.MODERATE}
            />
          </p>
          {descriptionLimitExceeded() && (
            <a data-testid="show-less" onClick={showLess}>
              <span>{rootStore.getTranslation("shared.show_less")}</span>
            </a>
          )}
        </div>
      )}
    </Fragment>
  );
});
