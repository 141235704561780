import * as React from "react";
import { useContext } from "react";

import { MyLikesContext } from "./myLikesStore";

export const NoLikesMessage = () => {
  const store = useContext(MyLikesContext);
  const rootStore = store.getRootStore();

  if (!store.hasLikedContent() && store.contentFinishedLoading()) {
    return (
      <section>
        <span className="label semi-bold">{rootStore.getTranslation("profile.no_likes")}</span>
      </section>
    );
  } else return <section />;
};
