import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../../stores/rootStore";
import { SearchCriteriaStoreContext, SearchCriteriaHandlerContext } from "../tabs/searchCriteriaStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { Dialog } from "../../dialogs/Dialog";
import { DialogActions } from "../../components/DialogActions";
import { LocationRestrictions } from "../../components/LocationRestrictions";
import { LanguageOptions } from "../../components/LanguageOptions";
import { MeasurementUnits } from "../../components/MeasurementUnits";
import { Tags } from "../../components/Tags";

const DialogContent = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(SearchCriteriaStoreContext);

  return (
    <article>
      <header>
        <h2 className="l-center-align">
          {rootStore.getTranslation("shared.catalog_entity_edit.edit_search_criteria")}
        </h2>
      </header>
      <section className="labels">
        <ol className="basic-data">
          <SearchCriteriaHandlerContext.Provider value={store}>
            <LocationRestrictions />
            <LanguageOptions />
            <MeasurementUnits />
            <Tags />
          </SearchCriteriaHandlerContext.Provider>
        </ol>
      </section>
      <DialogActions store={store} />
    </article>
  );
});

/**
 * Renders the editor dialog & button for SearchCriteria in package page
 */
export const EditSearchCriteria = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const dialog: DialogStore = useContext(DialogContext);

  function openDialog() {
    dialog.open();
  }

  return (
    <Fragment>
      <div className="edit-search-criteria">
        <a
          data-testid="edit-search-criteria"
          className="icon icon-pencil"
          title={rootStore.getTranslation("details.edit.tooltips.search_criteria")}
          onClick={() => openDialog()}
        >
          <span>{rootStore.getTranslation("details.edit.tooltips.search_criteria")}</span>
        </a>
      </div>
      <Dialog content={<DialogContent />} additionalClass="group-and-category" />
    </Fragment>
  );
});
