import _ from "underscore";

import { Settings } from "../../config/Settings";
import { ItemSourceEnum, LocalUploadStateEnum } from "../../models/enums";
import { LocalUploadService } from "../services/LocalUploadService";
import { LocalBinaryService } from "../services/LocalBinaryService";
import { TCCBinaryService } from "../services/TCCBinaryService";
import { ModelTypeConverter } from "../converters/ModelTypeConverter";
import { IntervalChecker } from "../utils/IntervalChecker";
import { TCCUserDS } from "../data-source/TCCUserDS";

function getCookie(cookieName) {
  return TCCUserDS.getCookies().then(function (cookies) {
    const cookie = _.find(cookies, (cookie) => {
      return cookie.name === cookieName;
    });

    if (cookie) {
      return cookie.value;
    }

    return "";
  });
}

function UploadSummary(data) {
  data = data || {};
  return {
    error: data.error,
    subject: data.subject,
    binaries: data.binaries,
  };
}

function uploadBinary(subject, binary, type, forceOldUploadStrategyForLocal) {
  if (type) {
    binary.attributes.type = type;
  }
  if (subject.isLocal) {
    if (binary.attributes.itemSource == ItemSourceEnum.EXPORTED_FROM_TS || forceOldUploadStrategyForLocal) {
      return LocalBinaryService.upload(subject, binary);
    } else {
      return LocalBinaryService.upload2(subject, binary);
    }
  } else {
    if (binary.attributes.itemSource == ItemSourceEnum.EXPORTED_FROM_TS) {
      return new Promise((resolve, reject) => {
        return getCookie(Settings.tcc.APITicketCookieName).then(function (ticket) {
          // console.log("ticket", ticket)

          if (!ticket) {
            reject("no ticket");
          } else {
            var attributesForTCC = {
              metadata: {
                platform: { dataType: "string", value: "" + binary.attributes.platform },
                fileName: { dataType: "string", value: "" + binary.attributes.fileName },
                originalName: { dataType: "string", value: "" + binary.attributes.originalName }, // TODO
                type: { dataType: "string", value: "" + binary.attributes.type },
                itemType: { dataType: "string", value: "" + binary.attributes.itemType },
                itemSource: { dataType: "string", value: "" + binary.attributes.itemSource },
                fileSize: { dataType: "string", value: "" + binary.attributes.fileSize },
                location: { dataType: "string", value: "" + binary.location },
              },
            };

            if (binary.attributes.immutability) {
              attributesForTCC.immutability = binary.attributes.immutability;
            }

            var data = {
              reference: binary.location,
              ticket: ticket,
              subjectId: subject.id,
              subjectClass: ModelTypeConverter.toSubjectClass(subject.type),
              attributes: attributesForTCC,
            };

            LocalUploadService.upload(data).then(
              function (uploadJob) {
                var uploadChecker = IntervalChecker(function () {
                  LocalUploadService.getUploadStatus(uploadJob).then(
                    function (res) {
                      if (res.state == LocalUploadStateEnum.COMPLETED && res.progress == 100) {
                        uploadChecker.stop();
                        resolve(uploadJob);
                      } else if (res.state == LocalUploadStateEnum.FAILED) {
                        uploadChecker.stop();
                        reject("upload failed");
                      } else if (res.state == LocalUploadStateEnum.CANCELLED) {
                        uploadChecker.stop();
                        reject("upload cancelled");
                      }
                    },
                    function (err) {
                      console.log(err);
                      uploadChecker.stop();
                      reject(err);
                    },
                  );
                });
              },
              function (err) {
                reject(err);
              },
            );
          }
        });
      });
    } else {
      return TCCBinaryService.upload(subject, binary);
    }
  }
}

export const Uploader = {
  upload: function (subject, binaryOrBinaries, type, forceOldUploadStrategyForLocal) {
    var binaries = _.isArray(binaryOrBinaries) ? binaryOrBinaries : [binaryOrBinaries];

    return new Promise((resolve, reject) => {
      if (subject.isLocal) {
        var uploadBinaryDataPromises = _.map(binaries, function (binary) {
          return function () {
            return uploadBinary(subject, binary, type, forceOldUploadStrategyForLocal);
          };
        });

        _.reduce(
          uploadBinaryDataPromises,
          function (temp, a) {
            return temp.then(a);
          },
          Promise.resolve({}),
        ).then(
          function (data) {
            resolve(UploadSummary({ binaries: binaries, subject: subject }));
          },
          function (err) {
            reject(UploadSummary({ error: err }));
          },
        );
      } else {
        var uploadBinaryDataPromises = _.map(binaries, function (binary) {
          return uploadBinary(subject, binary, type, forceOldUploadStrategyForLocal);
        });

        Promise.all(uploadBinaryDataPromises).then(
          function (dataArr) {
            resolve(UploadSummary({ binaries: binaries, subject: subject }));
          },
          function (err) {
            reject(UploadSummary({ error: err }));
          },
        );
      }
    });
  },
};
