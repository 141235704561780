import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { PopupStatusStore, PopupStatusStoreContext } from "./popupStatusStore";

import { TrimbleLogo } from "./TrimbleLogo";
import { LanguageMenu } from "./LanguageMenu";
import { UserMenu } from "./UserMenu";
import { TeklaNavigation } from "./TeklaNavigation";

/**
 * A component that displays supernavi items
 */
export const SuperNavi = observer(() => {
  const rootStore = useContext(RootContext);
  const onlineStatusStore = rootStore.getOnlineStatusStore();
  const popupStatusStore = new PopupStatusStore();

  return (
    <div>
      {onlineStatusStore.isOnline() && (
        <div className="global">
          <div className="global-items-left">
            <TeklaNavigation />
            <TrimbleLogo />
          </div>
          <div className="global-items-right">
            <PopupStatusStoreContext.Provider value={popupStatusStore}>
              <LanguageMenu />
              <UserMenu />
            </PopupStatusStoreContext.Provider>
          </div>
        </div>
      )}
    </div>
  );
});
