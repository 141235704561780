import _ from "underscore";

import { AbstractSearchDataSource } from "../data-source/AbstractSearchDataSource";
import { TCCSearchService } from "../services/TCCSearchService";
import { SearchAttributesConverter } from "../converters/SearchAttributesConverter";
import { OnlineStatus } from "../utils/OnlineStatus";

import { SearchTargetOptionEnum } from "../../models/enums";

export function TCCSearchDataSource() {
  var searchDataSource = AbstractSearchDataSource();

  function isLastPage(resultsCount, total) {
    return resultsCount >= total;
  }

  /**
   * Searches for content based on the provided data and search target.
   * @param {Object} data - The data object containing search parameters.
   * @param {string} searchTarget - The search target option.
   * @returns {Promise} - A promise that resolves with the search results.
   */
  function searchContent(data, searchTarget) {
    var sortBy = data.sortBy;
    if (data.sortBy === "recommended ASC") {
      data.sortBy = "createTime DESC";
    }
    if (searchTarget === SearchTargetOptionEnum.COLLECTION) {
      return TCCSearchService.searchCollectionsRaw(data, true);
    } else {
      if (sortBy === "recommended ASC") {
        return TCCSearchService.searchRecommendedPackages(data, true);
      } else {
        return TCCSearchService.searchPackagesRaw(data, true);
      }
    }
  }

  searchDataSource.loadNextRows = function (rowsNumber, initial) {
    return new Promise((resolve, reject) => {
      if (!OnlineStatus.isOnline()) {
        resolve([]);
      }
      rowsNumber = rowsNumber || 12;
      var converterData = {};
      if (searchDataSource.getSearchTarget() == SearchTargetOptionEnum.COLLECTION) {
        converterData = {
          q: searchDataSource.getQueryString(),
          sortBy: searchDataSource.getSortBy(),
          categories: searchDataSource.getCategories(),
        };
      } else {
        converterData = {
          q: searchDataSource.getQueryString(),
          sortBy: searchDataSource.getSortBy(),
          categories: searchDataSource.getCategories(),
          filters: searchDataSource.getFilters(),
        };
      }
      _.extend(converterData, searchDataSource.pageNumberToRowIndexesForTCC(rowsNumber));
      var data = SearchAttributesConverter.toTCC(converterData);
      if (initial) {
        searchDataSource.setLoaded(false);
      }
      return searchContent(data, searchDataSource.getSearchTarget()).then(function (res) {
        searchDataSource.setResultsTotalCount(res.raw.total);
        searchDataSource.setResults(_.union(searchDataSource.getResults(), res.results));
        searchDataSource.setLastPage(isLastPage(searchDataSource.getResultsCount(), res.raw.total));
        searchDataSource.setLastRow(res.raw.endRow);
        if (initial) {
          searchDataSource.setLoaded(true);
        }
        resolve(res.results);
      });
    });
  };

  searchDataSource.loadNextPage = function (rowsNumber) {
    return searchDataSource.loadNextRows(rowsNumber);
  };
  return searchDataSource;
}
