import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootStore } from "../../stores/rootStore";
import { OrganizationPageStoreContext, OrganizationPageStore } from "../organizationPageStore";
import { DialogContext, DialogStore } from "../../dialogs/dialogStore";

import { IComment } from "../../models/dataModel";

import { Dialog } from "../../dialogs/Dialog";
import { EditCommentDialog } from "../../dialogs/comments/EditCommentDialog";

/**
 * Component that displays link to edit the comment.
 * @param comment Comment to edit.
 */
export const EditComment = observer(({ comment }: { comment: IComment }) => {
  const orgStore: OrganizationPageStore = useContext(OrganizationPageStoreContext);
  const dialog: DialogStore = new DialogStore();
  const rootStore: RootStore = orgStore.getRootStore();

  async function openDialog() {
    dialog.open();
  }

  return (
    <DialogContext.Provider value={dialog}>
      <a
        className="icon icon-pencil"
        title={rootStore.getTranslation("organization.edit.edit_comment")}
        onClick={openDialog}
        data-testid="editCommentLink"
      >
        <span data-testid="editCommentSpan">{rootStore.getTranslation("organization.edit.edit_comment")}</span>
      </a>
      <Dialog
        content={<EditCommentDialog subject={orgStore.getOrganization()!} commentToEdit={comment} />}
        additionalClass="comment-dialog"
      />
    </DialogContext.Provider>
  );
});
