import * as React from "react";
import { useContext } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "./uploadFormStore";

/** Component for rendering the publish spinner */
export const Publish = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);

  const showSpinner = form.getUploaderStore().publishing();

  return (
    <div id="content">
      <div className="publish-phase">
        {showSpinner && (
          <div className="spinner spinner-gray">{rootStore.getTranslation("shared.spinner_processing")}</div>
        )}
      </div>
    </div>
  );
});
