import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";
import { OrganizationConverter } from "../converters/OrganizationConverter";

export const TCCOrganizationService = {
  createBinary: function (organizationId, data, file, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.upload("/warehouse/v1.0/organizations/" + organizationId + "/binaries", data, file, {
        performAs: performAsId,
      }),
    );
  },
  createBinaryWithMime: function (organizationId, data, file, mimeType) {
    return ResponseProcessor.process(
      RequestBuilder.upload2("/warehouse/v1.0/organizations/" + organizationId + "/binaries", data, file, mimeType),
    );
  },
  get: function (data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/organizations/" + data.id)).then(
      OrganizationConverter.fromTCC,
    );
  },
  update: function (orgId, data, performAsId) {
    data = data || {};
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/organizations/" + orgId, data, { performAs: performAsId }),
    );
  },
  search: function (data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/organizations", data), false, true);
  },
  getAttributes: function (organizationId) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/organizations/" + organizationId + "/attributes"),
    );
  },
  setAttributes: function (organizationId, data, performAsId) {
    data = data || {};
    const params = { doNotNotify: true };
    if (performAsId) {
      params.performAs = performAsId;
    }
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/organizations/" + organizationId + "/attributes", data, params),
    );
  },
  addComment: function (organizationId, commentData) {
    return ResponseProcessor.process(
      RequestBuilder.doPostWithParams("/warehouse/v1.0/organizations/" + organizationId + "/comments", commentData, {
        performAs: organizationId,
      }),
    );
  },
  getComments: function (organizationId, sortOptions, dontUnwrap) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/organizations/" + organizationId + "/comments", sortOptions),
      false,
      true,
    );
  },
  updateComment: function (organizationId, commentId, commentText, performAs) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/organizations/" + organizationId + "/comments/" + commentId, commentText, {
        performAs: performAs,
      }),
    );
  },
  deleteComment: function (organizationId, commentId, performAs) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/organizations/" + organizationId + "/comments/" + commentId, {
        performAs: performAs,
      }),
    );
  },
  getBinaries: function (organizationId) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/organizations/" + organizationId + "/binaries"),
    );
  },
  getBinary: function (organizationId, binaryName, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/organizations/" + organizationId + "/binaries/" + binaryName, data),
    );
  },
  updateBinary: function (organizationId, data, file, fileName) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/organizations/" + organizationId + "/binaries/" + fileName, data, file),
    );
  },
  deleteBinary: function (organizationId, binaryName) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/organizations/" + organizationId + "/binaries/" + binaryName),
    );
  },
};
