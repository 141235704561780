import { createContext } from "react";
import _ from "underscore";
import { action, runInAction, makeObservable } from "mobx";

import { AnalyticsService } from "../../js/services/AnalyticsService";
import { TCCSearchService } from "../../js/services/TCCSearchService";
import { TCCFeatureToggleDS } from "../../js/data-source/TCCFeatureToggleDS";

import { createStoreContext, RootStore } from "../../stores/rootStore";
import { EntitiesStore } from "../../stores/entitiesStore";

import { ICollection, IEntity, IFeatureToggle, IGetResourcePath, IPackageSearchQuery } from "../../models/dataModel";
import { AnalyticsEventTypeEnum } from "../../models/enums";

/**
 * A store that can be used to fetch recommended packages
 */
export class RecommendedEntitiesStore extends EntitiesStore implements IGetResourcePath {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);

    this.fetchData();
  }

  /**
   * Method that handles routing state when clicked in resource
   */
  public getResourcePath(resource: IEntity | ICollection): string {
    return "/catalog/details/" + resource.id;
  }

  /**
   * Sends analytics data for content viewed
   * @param resource
   */
  public async sendAnalyticsData(resource: IEntity | ICollection): Promise<any> {
    try {
      await AnalyticsService.contentViewed(
        this.rootStore.getUserStore().getCurrentUser(),
        resource,
        AnalyticsEventTypeEnum.RECOMMENDED_CONTENT_VIEWED,
      );
    } catch {
      console.log("Could not send analytics data");
    }
  }

  /**
   * Gets first five recommend packages
   */
  @action
  public async fetchData(): Promise<IEntity[]> {
    this.dataFetched = false;
    this.entities = [];
    let entities: IEntity[] = [];

    try {
      const recommendedContentToggle: IFeatureToggle = await TCCFeatureToggleDS.getFeatureToggle("recommendedContent");

      if (recommendedContentToggle.isEnabled) {
        const excludeDownloadedToggle: IFeatureToggle = await TCCFeatureToggleDS.getFeatureToggle("excludeDownloaded");

        const queryParams: IPackageSearchQuery = {
          offset: 0,
          count: 5,
          showBinaryMetadata: true,
          sortBy: "createTime DESC",
        };

        if (excludeDownloadedToggle.isEnabled) {
          _.extend(queryParams, { filter: "excludeDownloaded" });
        } else {
          _.extend(queryParams, { filter: "excludeInteracted" });
        }

        try {
          const pkg = await TCCSearchService.searchRecommendedPackages(
            queryParams,
            undefined,
            this.rootStore.getUserStore().getCurrentUser()?.id,
          );

          if (pkg && pkg.results) {
            entities = this.setPaths(pkg.results);
          }
        } catch {
          console.log("Could not get recommended packages");
        }
      }
    } catch {
      console.log("Could not get feature toggles");
    }

    runInAction(() => {
      this.dataFetched = true;
      this.entities = entities;
    });

    return entities;
  }
}

/**
 * Context object for RecommendedEntitieStore instances.
 */
export const RecommendedEntitiesContext = createStoreContext<RecommendedEntitiesStore>(RecommendedEntitiesStore);

/**
 * Context object for IGetResourcePath instances (of classes that implement IGetResourcePath interface).
 */
export const IGetResourcePathContext = createContext<IGetResourcePath | undefined>(undefined);
