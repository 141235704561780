import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useContext } from "react";
import { RootContext } from "../stores/rootStore";
import { ThumbnailStoreContext } from "../components/thumbnail/thumbnailStore";
import { DialogContext } from "./dialogStore";
import { Settings } from "../config/Settings";

/**
 * Renders 3d pic viewer dialog
 */
export const ThreeDPicViewerDialog = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(ThumbnailStoreContext);
  const content = store.getContent();
  const dialog = useContext(DialogContext);

  function cancel() {
    dialog.close();
  }

  function get3DPicUrl(): string {
    return !!content ? Settings.sketchup.sketchUpEndpoint + content.id : "";
  }

  return (
    <Fragment>
      <article>
        <header className="l-center-align header-3d">
          <div className="pic-frame-area">
            <iframe
              data-testid="threeD-pic-viewer"
              className="threed-pic-frame"
              src={get3DPicUrl()}
              frameBorder="0"
            ></iframe>
          </div>
        </header>
        <div className="actions">
          <button onClick={cancel} data-testid="close-3d-viewer-dialog">
            {rootStore.getTranslation("actions.close")}
          </button>
        </div>
      </article>
    </Fragment>
  );
});
