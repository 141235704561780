import * as React from "react";
import { observer } from "mobx-react";
import { Fragment, useCallback, useContext, useState } from "react";
import { IBatchCollection, IBatchEntity, IBatchVersion } from "../../models/dataModel";
import { BatchContentHandlerContext } from "../../dialogs/batch-edit/batchEditDialogStore";

/**
 * Content checkbox for batch dialogs
 */
export const ContentCheckbox = observer(({ content }: { content: IBatchCollection | IBatchEntity | IBatchVersion }) => {
  const dialogStore = useContext(BatchContentHandlerContext);
  const [stateChanged, setStateChanged] = useState(false);

  const checkRef = useCallback(
    (el) => {
      if (el) {
        el.indeterminate = dialogStore.isContentIndeterminated(content);
      }
    },
    [stateChanged],
  );

  function resolveSelectContentId(content: IBatchCollection | IBatchEntity | IBatchVersion) {
    return "select-content-" + content.id;
  }

  function handleContentSelectionChange(content: IBatchCollection | IBatchEntity | IBatchVersion) {
    dialogStore.selectContent(content);
    setStateChanged(!stateChanged);
  }

  function getRandomInt(): number {
    return Math.floor(Math.random() * 1000);
  }

  return (
    <Fragment>
      {!dialogStore.isProcessing() && (
        <div className="checkbox">
          <input
            key={getRandomInt()}
            id={resolveSelectContentId(content)}
            type="checkbox"
            checked={content.selected}
            onChange={() => handleContentSelectionChange(content)}
            data-testid={resolveSelectContentId(content)}
            ref={checkRef}
          />
          <label htmlFor={resolveSelectContentId(content)}></label>
        </div>
      )}
    </Fragment>
  );
});
