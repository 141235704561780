import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useState } from "react";

import { DialogContext, DialogStore } from "../dialogs/dialogStore";
import { RootContext, RootStore } from "../stores/rootStore";
import { VersionEditorContext, VersionEditorStore } from "./versionEditorStore";
import { UploadVersionStore } from "../upload/version/uploadVersionStore";
import { UploadFormStore } from "../upload/uploadFormStore";

import { ConfirmAction } from "../dialogs/ConfirmAction";
import { AddFiles } from "../upload/version/AddFiles";
import { VersionInformation } from "../upload/version/VersionInformation";
import { ArchiveButton } from "../components/immutability/ArchiveButton";

import { IResource } from "../models/dataModel";
import { versionNumberIsUniqueForThePackage } from "../utils/Immutability";

/**
 * Renders the "delete version" button.
 */
const DeleteVersionButton = () => {
  const rootStore: RootStore = useContext(RootContext);
  const confirmDialog: DialogStore = useContext(DialogContext);
  const versionEditor: VersionEditorStore = useContext(VersionEditorContext);
  const versionForm = versionEditor.getVersionForm();

  function openConfirmationDialog() {
    confirmDialog.open();
  }

  return (
    <Fragment>
      <button
        className={"button-large l-left delete"}
        data-testid="deleteVersionButton"
        onClick={openConfirmationDialog}
      >
        {rootStore.getTranslation("upload.version.delete_version")}
      </button>
      <ConfirmAction
        content={rootStore.getTranslation("upload.version.confirm_delete", versionForm.getVersionInEdit()!.title)}
        callbackCloseDialog={() => confirmDialog.close()}
        callbackConfirm={() => versionEditor.removeVersion()}
      />
    </Fragment>
  );
};

/**
 * Main component for the Edit version page.
 */
export const EditVersion = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const versionEditor: VersionEditorStore = useContext(VersionEditorContext);
  const versionForm: UploadVersionStore = versionEditor.getVersionForm();
  const form: UploadFormStore = versionEditor.getFormStore();

  const [saved, setSaved] = useState(false);

  const querying = versionEditor.isLoading();
  const showVersionForm = !querying && versionForm.getVersionInEdit();

  async function updateVersion() {
    setSaved(true);
    await versionEditor.updateVersion(false);
    setSaved(false);
  }

  function canUpdateVersion() {
    return (
      versionForm.getVersionInEdit() &&
      versionForm.isFormValid() &&
      ((versionEditor.getFormStore().isImmutable() &&
        versionNumberIsUniqueForThePackage(
          versionForm.getVersion().attributes.versionNumber,
          versionEditor.getExistingVersionsWithoutVersionInEdit(),
        )) ||
        !versionEditor.getFormStore().isImmutable())
    );
  }

  const saveDisabled = saved || !canUpdateVersion();

  return (
    <Fragment>
      {showVersionForm && (
        <div data-testid="updateVersionContent">
          <section className="files">
            <AddFiles />
          </section>
          <hr />
          <section className="version-metadata">
            <VersionInformation />
          </section>
          <section className="actions actions-bottom" style={{ display: "flex", flexDirection: "column" }}>
            <div className="previous-and-next">
              <DialogContext.Provider value={new DialogStore()}>
                {form.isImmutable() ? (
                  <ArchiveButton resource={versionForm.getVersionInEdit() as IResource} />
                ) : (
                  <DeleteVersionButton />
                )}
              </DialogContext.Provider>
              <button className="button-large l-right button-primary" onClick={updateVersion} disabled={saveDisabled}>
                {rootStore.getTranslation("actions.save")}
              </button>
            </div>
          </section>
        </div>
      )}
    </Fragment>
  );
});
