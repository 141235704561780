import { observer } from "mobx-react";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import _ from "underscore";

import { RootContext, RootStore } from "../../stores/rootStore";
import { DialogContext, DialogStore } from "../dialogStore";
import { EditTranslationsContext, EditTranslationsStore } from "./editTranslationsStore";
import { CollectionPageStore, CollectionPageStoreContext } from "../../collection/collectionPageStore";
import { PackagePageStore, PackagePageStoreContext } from "../../package/packagePageStore";

import { IItem, ITranslationObject } from "../../models/dataModel";
import { ObjectTypeEnum } from "../../models/enums";
import { stripHtml } from "../../utils/functions";

import { Dropdown } from "../../components/Dropdown";
import { RichTextEditor } from "../../components/RichTextEditor";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import { Dialog } from "../Dialog";

/**
 * Renders the language dropdown.
 */
const SelectLanguage = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: EditTranslationsStore = useContext(EditTranslationsContext);

  function handleSelectLanguage(option) {
    store.setSelectedLanguageOption(option);
  }

  return (
    <div className="language">
      <Dropdown
        options={store.supportedLanguageOptions}
        selectedValue={store.getSelectedLanguageOption()}
        placeholder={rootStore.getTranslation("shared.footer.title.select_language")}
        onChange={handleSelectLanguage}
        className="dropdown-wrapper gray"
        inputId={"selectLanguage"}
      />
    </div>
  );
});

/**
 * Renders dialog content for the Edit translations dialog.
 */
const EditTranslationsDialog = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: EditTranslationsStore = useContext(EditTranslationsContext);
  const parentStore: CollectionPageStore | PackagePageStore = store.getTargetItem().type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION ? useContext(CollectionPageStoreContext) : useContext(PackagePageStoreContext);
  const dialog: DialogStore = useContext(DialogContext);

  const canEdit = !!store.getSelectedLanguage();
  const selectedLanguage = store.getSelectedLanguage();

  const [translations, setTranslations] = useState<ITranslationObject>({
    title: "",
    copyright: "",
    description: "",
    details: "",
  });

  useEffect(() => {
    if (store.getSelectedLanguageTranslations()) {
      setTranslations(store.getSelectedLanguageTranslations()!);
    }
  }, [selectedLanguage]);

  function handleTitleInput(event) {
    setTranslations({ ...translations, title: event.target.value });
  }

  function handleCopyrightInput(event) {
    setTranslations({ ...translations, copyright: event.target.value });
  }

  function handleDescriptionInput(input: string) {
    setTranslations({ ...translations, description: input });
  }

  function handleDetailsInput(input: string) {
    setTranslations({ ...translations, details: input });
  }

  function isFormValid(): boolean {
    return translations.details
      ? !!store.getSelectedLanguage() && !store.detailsMaxLengthExceeded(translations.details!)
      : !!store.getSelectedLanguage();
  }

  async function saveTranslationsForSelectedLanguage() {
    const translationObject = {
      title: !!translations.title ? translations.title.trim() : undefined,
      copyright: !!translations.copyright ? translations.copyright.trim() : undefined,
      description:
        !!translations.description && !!stripHtml(translations.description).trim() ? translations.description : undefined,
      details: !!translations.details && !!stripHtml(translations.details).trim() ? translations.details : undefined,
    };

    await store.saveTranslationsForSelectedLanguage(
      store.getTargetItem().type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION
        ? _.pick(translationObject, "title", "description")
        : translationObject,
      parentStore);
  }

  function close() {
    dialog.close();
    store.setSelectedLanguageOption(undefined);
  }

  function renderHeaderSection() {
    return (
      <header className="translations-header">
        <h2 className="l-center-align">{rootStore.getTranslation("shared.translations.edit_translations")}</h2>
        <div className="translations-info">
          <table>
            <tbody>
              <tr>
                <td className="first-column" />
                <td className="second-column">
                  {rootStore.getTranslation("shared.translations.edit_translations_info")}
                </td>
                <td className="third-column" />
              </tr>
            </tbody>
          </table>
        </div>
      </header>
    );
  }

  function renderActions() {
    return (
      <div className="action">
        <div className="save-translations">
          <button
            className="button-primary"
            disabled={!isFormValid()}
            onClick={saveTranslationsForSelectedLanguage}
            data-testid="saveTranslations"
          >
            {rootStore.getTranslation("actions.save")}
          </button>
        </div>
        <div className="close-translations">
          <button onClick={close} data-testid="closeButton">
            {rootStore.getTranslation("actions.close")}
          </button>
        </div>
      </div>
    );
  }

  function renderTranslationInfos() {
    const defaultLanguageSelected = store.getSelectedLanguage() === "default";
    const entityDetailsTooLong = store.targetIsEntity() && store.detailsMaxLengthExceeded(translations.details!);
    const showTranslationInfos = entityDetailsTooLong || defaultLanguageSelected;

    return (
      showTranslationInfos && (
        <div className={store.targetIsEntity() ? "translation-infos" : "collection-translation-infos"}>
          {entityDetailsTooLong && (
            <div className="translation-details-too-long">
              {rootStore.getTranslation("shared.catalog_entity_edit.details_max_length_exceeded")}
            </div>
          )}
          {defaultLanguageSelected && (
            <div className="default-language-info">
              <TranslatedHtml entry={"shared.translations.edit_translations_default_language_info"} />
            </div>
          )}
        </div>
      )
    );
  }

  function renderTranslationFields() {
    return (
      <div className="edit-translation-fields">
        <div className="edit-translation-field">
          <label title={rootStore.getTranslation("upload.title")}>{rootStore.getTranslation("upload.title")}</label>
          <input
            type="text"
            value={translations.title}
            onChange={handleTitleInput}
            data-testid="titleTranslation"
            disabled={!canEdit}
          />
        </div>
        {store.targetIsEntity() && (
          <div className="edit-translation-field">
            <label title={rootStore.getTranslation("upload.copyright")}>
              {rootStore.getTranslation("upload.copyright")}
            </label>
            <input
              type="text"
              onChange={handleCopyrightInput}
              value={translations.copyright}
              data-testid="copyrightTranslation"
              disabled={!canEdit}
            />
          </div>
        )}
        <div className="edit-translation-field">
          <label title={rootStore.getTranslation("upload.description")}>
            {rootStore.getTranslation("upload.description")}
          </label>
          <div className={"editor description"} data-testid="descriptionField">
            <RichTextEditor
              field="description"
              onChange={handleDescriptionInput}
              value={translations.description!}
              targetItem={store.getTargetItem()}
              allowQuickInsert={true}
              disabled={!canEdit}
            />
          </div>
        </div>
        {store.targetIsEntity() && (
          <div className="edit-translation-field edit-rich-content">
            <div className="details-editor">
              <label title={rootStore.getTranslation("upload.details")}>
                {rootStore.getTranslation("upload.details")}
              </label>
              <div className={"editor details"} data-testid="detailsField">
                <RichTextEditor
                  field="details"
                  onChange={handleDetailsInput}
                  value={translations.details!}
                  targetItem={store.getTargetItem()}
                  allowQuickInsert={true}
                  disabled={!canEdit}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="translation-dialog-content">
      <article data-testid="translationsDialogContent">
        {renderHeaderSection()}
        <section className="translations-dialog">
          <div className="edit-translations">
            <SelectLanguage />
            {renderActions()}
            {!store.targetIsEntity() && <br />}
            {renderTranslationInfos()}
            {renderTranslationFields()}
          </div>
        </section>
      </article>
    </div>
  );
});

/**
 * Component that renders the 'Edit translations' button and dialog content.
 */
export const EditTranslationsButton = ({ item }: { item: IItem }) => {
  const rootStore: RootStore = useContext(RootContext);
  const store: EditTranslationsStore = new EditTranslationsStore(rootStore);
  const dialog: DialogStore = new DialogStore();

  store.initialize(item);

  function openDialog() {
    dialog.open();
  }

  return (
    <EditTranslationsContext.Provider value={store}>
      <DialogContext.Provider value={dialog}>
        <button
          className="button button-primary button-bulk-edit"
          onClick={openDialog}
          data-testid="translationsButton"
        >
          {rootStore.getTranslation("actions.translations")}
        </button>

        <Dialog content={<EditTranslationsDialog />} additionalClass={"translations-edit"} />
      </DialogContext.Provider>
    </EditTranslationsContext.Provider>
  );
};
