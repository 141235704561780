import * as React from "react";
import { Fragment } from "react";
import { observer } from "mobx-react";

import { IEntity, IItem, ICollection } from "../models/dataModel";
import { SearchPageListStyleEnum, ObjectTypeEnum } from "../models/enums";

import { EntityWithCart } from "./EntityWithCart";
import { CollectionListItem } from "./CollectionListItem";

/**
 * A component being a wrapper for EntityWithCart or Collection. The wrapper provides RootStore object via RootContext.
 */
export const EntityOrCollection = observer(
  ({
    item,
    style = SearchPageListStyleEnum.THUMBNAILS,
    showAlerts,
  }: {
    item: IItem;
    style: SearchPageListStyleEnum;
    showAlerts?: boolean;
  }) => {
    const renderPartial = () => {
      if (item.type === ObjectTypeEnum.TEKLA_WAREHOUSE_COLLECTION) {
        return <CollectionListItem collection={item as ICollection} listStyle={style} showAlerts={showAlerts} />;
      } else {
        return <EntityWithCart entity={item as IEntity} listStyle={style} showAlerts={showAlerts} />;
      }
    };

    return <Fragment>{renderPartial()}</Fragment>;
  },
);
