import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Link } from "react-router";

import { RootContext } from "../stores/rootStore";
import { LocalCollectionPageStoreContext } from "./localCollectionPageStore";

import { IEntity, IItem } from "../models/dataModel";
import { SearchPageListStyleEnum, SanitizationModeEnum } from "../models/enums";

import { CategoryFilter } from "../components/search/CategoryFilter";
import { AddContentButton } from "../components/AddContentButton";
import { SortBy } from "../components/search/SortBy";
import { SearchField } from "../components/search/SearchField";
import { TextWrapper } from "../components/TextWrapper";
import { ListStyleToggle } from "../components/search/ListStyleToggle";

/**
 * A component that displays filters and sorting options
 */
export const SortingOptions = observer(() => {
  const store = useContext(LocalCollectionPageStoreContext);
  const packages = store.getPackages();

  function packagesExist() {
    return packages && packages.length > 0;
  }

  function shouldShowSort() {
    return packages && packages.length > 1;
  }

  return (
    <Fragment>
      {packagesExist() && (
        <section className="result-options local-result-options" data-testid="sortingOptions">
          <div className="summary l-center">
            <div className="version-sort version-sort-local">
              <CategoryFilter pageWhereUsed="local" />
            </div>
            <div className="local-search-filters">
              <ListStyleToggle pageWhereUsed="local" />
              {shouldShowSort() && (
                <Fragment>
                  <SearchField pageWhereUsed="local" />
                  <div data-testid="local-sorting-options" className="sorting-options local-sorting-options">
                    <SortBy />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
});

/*
/**
 * A component that displays package item
 */
export const PackageThumbItem = observer(({ packageItem }: { packageItem: IEntity }) => {
  const store = useContext(LocalCollectionPageStoreContext);
  const pathToPackage = "/catalog/localdetails/" + packageItem.id;

  return (
    <div className="result">
      <div className="item thumb-item">
        <Link to={pathToPackage} className="thumb-box">
          <figure data-testid="local-image" style={store.getPackageThumbnail(packageItem)} />
        </Link>
        <div className="thumb-item-details">
          <Link to={pathToPackage} className="item-title" data-testid="thumb-package-link" title={packageItem.title}>
            {packageItem.title}
          </Link>
        </div>
      </div>
    </div>
  );
});

/*
/**
 * A component that displays package item
 */
export const PackageListItem = observer(({ packageItem }: { packageItem: IEntity }) => {
  const rootStore = useContext(RootContext);
  const store = useContext(LocalCollectionPageStoreContext);
  const pathToPackage = "/catalog/localdetails/" + packageItem.id;

  function getDesription() {
    return packageItem && packageItem.description ? packageItem.description : "";
  }

  function getUseCategoryTranslation(category) {
    return rootStore.getTranslation("useCategories." + category);
  }

  function getItemTypeCategoryTranslation(category) {
    return rootStore.getTranslation("itemTypeCategories." + category);
  }

  return (
    <div className="result">
      <div className="item">
        <Link to={pathToPackage} className="thumb-box">
          <figure data-testid="local-image" style={store.getPackageThumbnail(packageItem)} />
        </Link>
        <div className="details">
          <Link to={pathToPackage} data-testid="list-package-link" className="item-title" title={packageItem.title}>
            <h3>{packageItem.title}</h3>
          </Link>
          <div className="description">
            <TextWrapper
              text={getDesription()}
              cssClasses={["translated-html"]}
              sanitizationMode={SanitizationModeEnum.MODERATE}
              truncate={200}
            />
          </div>
          <ul className="tags">
            {packageItem.attributes!.useCategories!.map((category: string, i: number) => {
              return (
                <li key={i} className="category semi-bold">
                  {getUseCategoryTranslation(category)}
                </li>
              );
            })}
            {packageItem.attributes!.itemTypeCategories!.map((category: string, i: number) => {
              return (
                <li key={i} className="category semi-bold">
                  {getItemTypeCategoryTranslation(category)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
});

/**
 * A component that displays package items
 */
export const PackageList = observer(() => {
  const rootStore = useContext(RootContext);
  const store = useContext(LocalCollectionPageStoreContext);
  const searchStore = rootStore.getSearchStore();
  const searchPageSettingsStore = searchStore.getSearchPageSettingsStore();
  const thumbMode = searchPageSettingsStore.resultListStyle == SearchPageListStyleEnum.THUMBNAILS;
  const packages = searchStore.sortResults(searchStore.filterResults(store.getPackages() as IItem[])) as IEntity[];

  function getResultSummaryText() {
    return packages.length > 0
      ? rootStore.getTranslation("collections.entity_list.results_summary", [packages.length])
      : rootStore.getTranslation("collections.entity_list.no_content");
  }

  return (
    <Fragment>
      <section
        className={classNames("results-container local-collection", {
          "as-thumbnails": thumbMode,
        })}
      >
        <div className="listing-wrapper">
          {store.isLoading() ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
            </div>
          ) : (
            <Fragment>
              <div className="results-summary">
                <span data-testid="results-count" className="label results-count">
                  {getResultSummaryText()}
                </span>
              </div>
              <AddContentButton collection={store.getCollection()} />
              {packages.map((packageItem: IEntity, i: number) => {
                return (
                  <div key={i} className="results results-local">
                    {thumbMode ? (
                      <PackageThumbItem packageItem={packageItem} />
                    ) : (
                      <PackageListItem packageItem={packageItem} />
                    )}
                  </div>
                );
              })}
            </Fragment>
          )}
        </div>
      </section>
    </Fragment>
  );
});

/**
 * A component that displays local collection package list.
 */
export const Packages = observer(() => {
  return (
    <div className="details-tabs-container">
      <div className="tab-view">
        <div className="catalog">
          <SortingOptions />
          <PackageList />
        </div>
      </div>
    </div>
  );
});
