import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";

import { RootContext, RootStore } from "../../stores/rootStore";
import { NewestEntitiesContext, NewestEntitiesStore } from "./newestEntitiesStore";
import { IEntity } from "../../models/dataModel";

import { TranslatedHtml } from "../../components/TranslatedHtml";
import { EntityWithCart } from "../../components/EntityWithCart";
import { IGetResourcePathContext } from "../../search/searchPageStore";

/**
 * A component that displays list of the newest packages.
 */
export const NewestEntities = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const store: NewestEntitiesStore = useContext(NewestEntitiesContext);

  function shouldDisplay(): boolean {
    return store.wasDataFetched() && store.getEntities().length > 0;
  }

  function getNewestLink(): string {
    const lang = rootStore.getLangKeyBySelectedLocale();
    const query = new URLSearchParams({
      languages: lang,
      sortBy: "-createdAt",
      showContentView: "true",
    }).toString();

    return "/search?" + query;
  }

  return (
    <Fragment>
      {shouldDisplay() && (
        <section className="content">
          <div className="content-area">
            <div className="title">
              <Link to={getNewestLink()} className="title-link">
                <TranslatedHtml entry="landing.content.newest" />
              </Link>
            </div>
            <div className="content-items-section" data-testid="entities-list">
              {store.getEntities().map((entity: IEntity, i: number) => {
                return (
                  <div className="content-items" key={i}>
                    <IGetResourcePathContext.Provider value={store}>
                      <EntityWithCart entity={entity} listStyle="thumbnails" />
                    </IGetResourcePathContext.Provider>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
});
