import * as React from "react";
import { useContext } from "react";

import { TranslatedHtml } from "../components/TranslatedHtml";
import { RootContext } from "../stores/rootStore";

export const CreatingContent = () => {
  const store = useContext(RootContext);

  return (
    <article className="about-creating_content">
      <header>
        <h2>{store.getTranslation("about.creating_content.title")}</h2>
      </header>
      <section id="gettingStartedWithContentCreation">
        <h3>{store.getTranslation("about.creating_content.getting_started_with_content_creation.title")}</h3>
        <p>{store.getTranslation("about.creating_content.getting_started_with_content_creation.description_1")}</p>
        <ul className="feature-list">
          <li>
            <TranslatedHtml entry="about.creating_content.getting_started_with_content_creation.feature_1" />
          </li>
          <li>
            <TranslatedHtml entry="about.creating_content.getting_started_with_content_creation.feature_2" />
          </li>
          <li>
            <TranslatedHtml entry="about.creating_content.getting_started_with_content_creation.feature_3" />
          </li>
          <li>
            <TranslatedHtml entry="about.creating_content.getting_started_with_content_creation.feature_4" />
          </li>
        </ul>
      </section>
      <section id="expertHelpForCreatingContent">
        <h3>{store.getTranslation("about.creating_content.expert_help_for_creating_content.title")}</h3>
        <p>
          <TranslatedHtml entry="about.creating_content.expert_help_for_creating_content.description_1" />
        </p>
      </section>
    </article>
  );
};

export const creatingContentNavigationInfo = {
  name: "creating_content",
  component: <CreatingContent />,
  translationId: "about.navigation.creating_content",
  subnavigation: [
    {
      name: "gettingStartedWithContentCreation",
      translationId: "about.navigation.creating_content__getting-started-with-content-creation",
    },
    {
      name: "expertHelpForCreatingContent",
      translationId: "about.navigation.creating_content__expert-help-for-creating-content",
    },
  ],
};
