 
export const PtBrAbt = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }

    if (!window.locale["pt-BR"]) {
      window.locale["pt-BR"] = {};
    }

    window.locale["pt-BR"].about = {
      navigation: {
        why: "Por que o Tekla Warehouse?",
        usage_basics: "Básicos do Tekla Warehouse",
        "usage_basics__using-content": "Usando conteúdo",
        "usage_basics__adding-content": "Adicionando conteúdo",
        usage_basics__collection: "Coleção",
        "usage_basics__content-item": "Item de conteúdo",
        usage_basics__version: "Versão",
        getting_started: "Introdução",
        "getting_started__tekla-account-and-system-requirements": "Tekla account e requisitos do sistema",
        "getting_started__tekla-warehouse-service-plugin": "Plug-in Tekla Warehouse Service",
        "getting_started__how-do-i-get-tekla-warehouse-service": "Como obter o Tekla Warehouse Service?",
        "getting_started__tekla-structures-and-tekla-warehouse": "Tekla Structures e Tekla Warehouse",
        content: "Conteúdo do Tekla Warehouse",
        "content__3d-products": "Produtos de modelo 3D",
        "content__custom-components": "Componentes personalizados",
        content__applications: "Aplicações",
        "content__profiles-shapes-etc": "Perfis, materiais, parafusos, armaduras, etc.",
        "content__model-templates": "Templates de modelo",
        "content__drawing-setup": "Configuração de desenhos",
        "content__report-templates-etc": "Templates de lista e arquivos de atributos",
        using_content: "Usando o conteúdo",
        creating_content: "Criando conteúdo",
        "creating_content__getting-started-with-content-creation": "Guia de introdução à criação de conteúdo",
        "creating_content__expert-help-for-creating-content": "Precisa de ajuda especializada para criar conteúdo?",
        application_developers: "Desenvolvimento de aplicações para Tekla Structures",
      },

      //https://warehouse.tekla.com/#/about
      why: {
        title: "Por que o Tekla Warehouse?",
        description_1:
          "O Tekla Warehouse inclui aplicações, componentes personalizados, peças perfis, materiais como graus de aço e concreto, parafusos, telas soldadas, formas e templates para Tekla Structures. A utilização de peças reais dos fornecedores nos modelos permite a mais elevada precisão e disponibiliza informações exatas para fins de planejamento e aquisição. Além disso, a utilização de conteúdo pronto para o uso acelera a modelagem.",
        description_2:
          "O Tekla Warehouse proporciona um acesso centralizado a este conteúdo, o qual pode agora ser utilizado de forma otimizada. O conteúdo no Tekla Warehouse está aumentando constantemente.",
        description_3: "Com o Tekla Warehouse",
        feature_1:
          "Os fabricantes podem disponibilizar produtos e aplicações para os usuários, os quais podem utilizar os modelos exatos dos produtos 3D diretamente em seus modelos Tekla e, posteriormente, usar o produto físico na construção.",
        feature_2:
          "Você pode usar a rede da empresa para compartilhar conteúdo com colegas e salvar conteúdo localmente para uso pessoal",
        description_4:
          "A pesquisa no Tekla Warehouse facilita encontrar exatamente o que você precisa, não importa o tipo de conteúdo.",
        description_5: "O plug-in Tekla Warehouse Service é necessário para permitir coleções locais e de rede.",
        view_licences_html: 'Ver <a href="/api/third_party_licenses.txt" target="_blank">licenças de terceiros</a>.',
      },

      //https://warehouse.tekla.com/#/about/usage_basics
      usage_basics: {
        title: "Básicos do Tekla Warehouse",
        using_content: {
          title: "Usando conteúdo",
          description_1:
            "Você pode usar o conteúdo em coleções online, compartilhar conteúdo com seus colegas via coleções de rede e usar coleções locais para utilização pessoal.",
          description_2:
            "As coleções de rede e locais precisam do Tekla Warehouse Service, que é instalado com o Tekla Structures 20.1 Service Release 1 e versões do Tekla Structures mais recentes.",
          description_3:
            "Coleções Tekla específicas que oferecem conteúdo em ambiente localizado são instaladas com o Tekla Structures.",
          description_4: "Consulte a seção Usando conteúdo para obter mais informações.",
        },
        adding_content: {
          title: "Adicionando conteúdo",
          description_1:
            'Você pode utilizar coleções online para publicar conteúdo para todos os usuários do Tekla Warehouse. Para poder publicar conteúdo como uma organização, sua organização precisa ser adicionada à account.tekla.com. Contate o <a href="http://www.tekla.com/contact/offices-and-resellers" target="_blank">escritório ou revendedor Tekla</a> local para obter mais informações.',
          description_2: "Consulte a seção Criando conteúdo para obter mais informações.",
        },
        collection: {
          title: "Coleção",
          feature_1:
            "As coleções agrupam itens de conteúdo em conjuntos, como pastas, e definem os direitos de acesso ao conteúdo (público/privado).",
          feature_1_1:
            "Os modeladores podem utilizar conteúdo em coleções online, compartilhar conteúdo com os colegas via coleções de rede e usar coleções locais para utilização pessoal.",
          feature_2:
            "Os fornecedores de conteúdo podem utilizar coleções online para publicar o respectivo conteúdo para todos os usuários do Tekla Warehouse.",
          feature_3:
            "As coleções locais e de rede necessitam do plug-in Tekla Warehouse Service, o qual é instalado com o Tekla Structures 20.1 Service Release 1 e versões mais recentes, mas também pode ser instalado separadamente.",
        },
        "content-item": {
          title: "Item de conteúdo",
          feature_1:
            "Um item de conteúdo é um grupo lógico de arquivos. Por exemplo, um catálogo de produtos 3D, um modelo template, uma ferramenta, um grupo de componentes personalizados ou um catálogo de perfis são itens de conteúdo.",
          feature_2:
            "Os itens de conteúdo podem ser rotulados por tipo e por utilização, o que ajuda os usuários a encontrar o conteúdo.",
        },
        version: {
          title: "Versão",
          feature_1:
            "Ao mesmo tempo, um item de conteúdo pode ter muitas versões que são utilizadas com diferentes versões do Tekla Structures. O usuário pode selecionar a versão correta na página de conteúdo.",
        },
      },

      //https://warehouse.tekla.com/#/about/getting_started
      getting_started: {
        title: "Introdução",
        tekla_account_and_system_requirements: {
          title: "Tekla account e requisitos do sistema",
          register_atc_html:
            'Você precisa de uma Tekla account para fazer login no Tekla Warehouse. Se ainda não se registrou, faça-o em <a href="https://account.tekla.com/" target="_blank">account.tekla.com</a>. (Observe que esta não é a antiga conta da Extranet.)',
          see_tua_for_prerequisites_html:
            'Consulte a Tekla User Assistance para obter informações sobre <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_getting_started#id_ejc_g1d_xsb" target="_blank">pré-requisitos para usar o Tekla Warehouse</a>.',
        },
        tekla_warehouse_service_plugin: {
          title: "Plug-in Tekla Warehouse Service",
          description_1: "Além de ser um diretório online de conteúdo, o Tekla Warehouse também possibilita:",
          feature_1: "Coleções locais para uso pessoal",
          feature_2: "Coleções de rede para compartilhar conteúdo com colegas via rede da empresa",
          feature_3: "Fácil instalação durante a utilização ou a criação de conteúdo",
          feature_4: "Pesquisa de conteúdo local e de rede",
          description_2:
            "Isso é possível com o Tekla Warehouse Service, um plug-in que funciona como um back-end local que se comunica com o Tekla Structures e com o site do Tekla Warehouse.",
          description_3:
            "Se o Tekla Warehouse Service não estiver instalado ou não estiver funcionando, o Tekla Warehouse o avisará na parte superior da página para permitir a solução do problema.",
          description_4:
            "Se houver problemas com o serviço, você poderá reiniciá-lo via programa Serviços no Windows. O nome do serviço é Tekla Warehouse. Verifique também as configurações de segurança do navegador.",
          description_5:
            "O site do Tekla Warehouse pode ser usado sem o Tekla Warehouse Service ou o Tekla Structures. Nesse caso, a experiência será mais semelhante à de um site típico de compartilhamento de conteúdo, onde os arquivos são carregados e baixados manualmente.",
        },
        how_do_i_get_tekla_warehouse_service: {
          title: "Como obter o Tekla Warehouse Service?",
          description_1:
            "O plug-in Tekla Warehouse Service é instalado com o Tekla Structures 20.1 Service Release 1 e versão mais recente. Você também pode instalar o Tekla Warehouse Service separadamente para ativar coleções locais e de rede, mas a instalação direta no modelo e os recursos de upload só são possíveis com o Tekla Structures 20.1 Service Release 1 e versões mais recentes.",
          download_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" target="_blank">BAIXAR O TEKLA WAREHOUSE SERVICE</a>',
        },
        tekla_structures_and_tekla_warehouse: {
          title: "Tekla Structures e Tekla Warehouse",
          description_1:
            "O Tekla Warehouse conecta-se diretamente ao Tekla Structures, facilitando a utilização de conteúdo existente ou o upload de conteúdo para o diretório online. Você pode selecionar conteúdo diretamente do seu modelo e também inserir conteúdo diretamente no modelo sem precisar importar/exportar arquivos (para obter esta funcionalidade, é necessário o Tekla Structures 20.1 Service Release 1 e versões mais recentes). Todos os arquivos necessários podem ser incluídos no mesmo item de conteúdo, como um componente personalizado ou um perfil, para garantir que tudo funcione da forma desejada.",
        },
      },

      // https://warehouse.tekla.com/#/about/content
      content: {
        title: "Conteúdo do Tekla Warehouse",
        description_1:
          "O Tekla Warehouse oferece diferentes tipos de conteúdo. Aqui você pode encontrar informações sobre os diferentes tipos de conteúdo e mais informações de iniciação.",
        description_2:
          'No Tekla Warehouse, você pode baixar <a href="https://teklastructures.support.tekla.com/210/en/ext_main_listing" target="_blank">Tekla Extensões</a>.',
        "3d_products": {
          title: "Produtos de modelo 3D",
          image_subtitle: "Estes são objetos com geometria fixa, por exemplo, objetos embutidos, formas e acessórios.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações na seção <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-DECFBF8C-0CCC-4AEE-B489-0CBF9091AACE__GUID-7E345A16-C018-4BBC-82D0-89CE147BD850" target="_blank">Criando um catálogo de produtos 3D em uma coleção do Tekla Warehouse</a>.',
        },
        custom_components: {
          title: "Componentes personalizados",
          image_subtitle:
            "Os componentes personalizados são objetos nativos do Tekla Structures com geometria fixa ou paramétrica.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações na seção <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Enviando conteúdo de um modelo do Tekla Structures para uma coleção do Tekla Warehouse</a>.',
        },
        applications: {
          title: "Aplicações",
          image_subtitle:
            "As aplicações usam o Tekla Open API para interagirem com o Tekla Structures. Por exemplo, links de integração de dados a uma solução de dimensionamento.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações na seção <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-D5AA20A4-051A-45A4-98A0-38D7AEA82E2D" target="_blank">Enviando uma aplicação para uma coleção do Tekla Warehouse</a>.',
        },
        profiles_shapes_etc: {
          title: "Perfis, formas, materiais, conjuntos de parafusos, armaduras e telas soldadas",
          image_subtitle:
            "Os catálogos contêm listas de propriedades relacionadas a peças. Por exemplo, diferentes qualidades de material e suas densidades.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações na seção <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-23C9F0BD-07A9-4417-975A-E8386823909C" target="_blank">Enviando conteúdo de um modelo do Tekla Structures para uma coleção do Tekla Warehouse</a>.',
        },
        model_templates: {
          title: "Templates de modelo",
          image_subtitle:
            "Um template de modelo pode ser usado para facilitar projetos semelhantes e recorrentes. O template pode conter todos os catálogos, templates de lista, configurações de desenhos e configurações de opções avançadas necessários.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adicionando arquivos manualmente a uma coleção do Tekla Warehouse</a>.',
        },
        drawing_setup: {
          title: "Configuração de desenhos",
          image_subtitle:
            "Os arquivos de configuração definem o modo de criação dos desenhos, por exemplo, layouts, filtros e modelos de clonagem.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adicionando arquivos manualmente a uma coleção do Tekla Warehouse</a>.',
        },
        report_templates_etc: {
          title: "Templates de lista e arquivos de atributos de modelos",
          description_1:
            "Os templates de lista definem o conteúdo e o layout de dados de um modelo em um relatório. Os arquivos de atributos de modelos, por exemplo, filtros, funcionam quando adicionados à pasta do modelo.",
          tua_link_html:
            'Consulte o Tekla User Assistance para obter mais informações sobre <a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_upload_content#GUID-9D201617-9696-4264-A3A5-3863C37513B5" target="_blank">Adicionando arquivos manualmente a uma coleção do Tekla Warehouse</a>.',
        },
      },

      //https://warehouse.tekla.com/#/about/using_content
      using_content: {
        title: "Usando conteúdo",
        description_1:
          "Dependendo do tipo de conteúdo, você pode inserir seu conteúdo escolhido no modelo do Tekla Structures ou baixá-lo e instalá-lo. Consulte a Tekla User Assistance para obter instruções sobre",
        feature_1:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_searching_for_content" target="_blank">Pesquisando conteúdo no Tekla Warehouse</a>',
        feature_2:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_using_content" target="_blank">Usando conteúdo do Tekla Warehouse</a>',
        feature_3:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_inserting_content_into_ts_model" target="_blank">Inserindo conteúdo do Tekla Warehouse em um modelo do Tekla Structures</a>',
        feature_4:
          '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_conflict_management" target="_blank">Gestão de conflitos no Tekla Warehouse</a>',
      },

      //https://warehouse.tekla.com/#/about/creating_content
      creating_content: {
        title: "Criando conteúdo",
        getting_started_with_content_creation: {
          title: "Guia de introdução à criação de conteúdo",
          description_1: "Consulte a Tekla User Assistance para obter mais informações sobre:",
          feature_1:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_getting_started" target="_blank">Introdução ao Tekla Warehouse</a>',
          feature_2:
            '<a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_adding_content" target="_blank">Adicionando conteúdo ao Tekla Warehouse</a>',
          feature_3:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_modifying_content" target="_blank">Modificando conteúdo no Tekla Warehouse</a>',
          feature_4:
            '<a href="https://support.tekla.com/doc/tekla-structures/not-version-specific/twh_download_content" target="_blank">Baixando conteúdo do Tekla Warehouse</a>',
        },
        expert_help_for_creating_content: {
          title: "Precisa de ajuda especializada para criar conteúdo?",
          description_1:
            'Se você estiver interessado em publicar conteúdo no Tekla Warehouse, mas gostaria de dispor de ajuda especializada na criação e na manutenção desse conteúdo, contate o escritório ou revendedor <a href="http://www.tekla.com/contact" target="_blank">Tekla local</a> para obter mais informações sobre possibilidades de colaboração.',
        },
      },

      //https://warehouse.tekla.com/#/about/application_developers
      application_developers: {
        title: "Desenvolvimento de aplicações para Tekla Structures",
        description_1:
          "Como a Tekla emprega uma abordagem aberta em relação à modelagem informações de construção (BIM - Building Information Modeling), você pode executar soluções e operar máquinas de outros fornecedores e, ainda assim, interagir com o produto Tekla.",
        description_2:
          'A <a href="http://www.tekla.com/openAPI" target="_blank">Tekla Open API</a> é uma interface de programação de aplicações (API) desenvolvida pela Tekla que permite o desenvolvimento de aplicações e funcionalidades adicionais na plataforma de modelagem do Tekla Structures e a respectiva integração em ambiente próprio. A Tekla Open API é implementada com base na tecnologia Microsoft .NET.',
        description_3:
          'O <a href="https://developer.tekla.com" target="_blank">Tekla Developer Center </a> é o ponto central para todas as informações sobre a Tekla Open API.',
        description_4:
          'Acesse o <a href="https://developer.tekla.com/Tekla-Partners-Program" target="_blank">Tekla Partners Program</a> se você estiver interessado em desenvolver aplicações para o Tekla Structures.',
      },
    };
  },
};
