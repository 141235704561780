import { IObservableArray, action, makeObservable, observable } from "mobx";
import _ from "underscore";

import { IBinary, IItem } from "../models/dataModel";
import { ImageUtils } from "../utils/ImageUtils";

/**
 * Base class binary handling.
 */
export abstract class BinaryStore {
  /**
   * Parsed thumbnails
   */
  protected binaries: IObservableArray<IBinary> = observable.array([]);

  constructor() {
    makeObservable(this);
  }

  @action
  protected parseBinaries(item: IItem): void {
    this.binaries.replace([]);

    _.each(item.thumbnails || [], (thumbnail: IBinary) => {
      this.addBinary(item, thumbnail);
    });
    _.each(item.thumbnails3d || [], (thumbnail: IBinary) => {
      this.addBinary(item, thumbnail);
    });
    _.each(this.convertUrlAttributesToArray(item.attributes!.videourls || {}), (url) => {
      this.addBinary(item, url);
    });
  }

  protected addBinary(item: IItem, binary: IBinary): void {
    binary.is3DThumbnail = this.is3DThumbnail(binary);
    binary.isThumbnailPlaceholder = this.isThumbnailPlaceholder(binary);
    binary.isThumbnail = this.isThumbnail(binary);
    binary.isDefaultBinary = this.isDefaultBinary(item, binary);
    binary.isVideo = this.isVideo(binary);
    this.binaries.push(binary);
  }

  protected isDefaultBinary(item: IItem, binary: IBinary): boolean {
    if (!!item.attributes && !!item.attributes.defaultThumbnail && item.attributes!.defaultThumbnail.name) {
      return item.attributes!.defaultThumbnail!.name === binary.reference;
    } else {
      return false;
    }
  }

  protected is3DThumbnail(binary: IBinary): boolean {
    return !!binary.reference && binary.reference.includes("thumbnail_3d");
  }

  protected isThumbnail(binary: IBinary): boolean {
    return (
      !this.is3DThumbnail(binary) &&
      ((binary.reference && binary.reference.includes("thumbnail")) || this.isThumbnailPlaceholder(binary))
    );
  }

  protected isVideo(binary: IBinary): boolean {
    return !!binary.reference && binary.reference.includes("url_");
  }

  protected isThumbnailPlaceholder(binary: IBinary): boolean {
    return binary.url === ImageUtils.getDefaultThumbail();
  }

  protected convertUrlAttributesToArray(
    urlAttributes: Record<string, string>,
  ): Array<{ reference: string; value: string }> {
    return _.map(urlAttributes, (url, key) => {
      return { reference: key, value: url };
    });
  }
}
