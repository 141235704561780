import { action, observable, runInAction, makeObservable } from "mobx";

/**
 * Store for handling online status.
 */
export class OnlineStatusStore {
  /**
   * Online status
   */
  @observable private onlineStatus = true;

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor() {
    makeObservable(this);
    runInAction(() => {
      this.onlineStatus = window.navigator.onLine;
      this.initListeners();
    });
  }

  /**
   * Method for resolving if network is available
   * @returns returns true if network available
   */
  public isOnline(): boolean {
    return this.onlineStatus;
  }

  /**
   * Initialize listeners for checking changes on network availability
   */

  @action
  private initListeners() {
    window.addEventListener(
      "online",
      () => {
        runInAction(() => {
          this.onlineStatus = true;
        });
      },
      { passive: true },
    );
    window.addEventListener(
      "offline",
      () => {
        runInAction(() => {
          this.onlineStatus = false;
        });
      },
      { passive: true },
    );
  }
}
