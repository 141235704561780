const DEFAULT_THUMBNAIL = "/images/placeholder.png";
const IMAGE_MAX_SIZE = 3000000;
const IMAGE_MIME_TYPE_REGEXP = /^image\//;

/**
 * Helper class for image related actions
 */
export const ImageUtils = {
  /**
   * Resolves if given file is image file
   * @params file the file to be inspected
   */
  isImage: (file: File): boolean => {
    return !!file && !!file.type && !!file.type.match(IMAGE_MIME_TYPE_REGEXP) && !file.type.includes("svg");
  },
  /**
   * Gets default thumbnail path
   */
  getDefaultThumbail: (): string => {
    return DEFAULT_THUMBNAIL;
  },
  /**
   * Resolves if given image file is to big
   * @params file the file to be inspected
   */
  isTooBigImage: (file: File): boolean => {
    return !!file && !!file.size && file.size > IMAGE_MAX_SIZE;
  },
  /**
   * Reads file data and calls given method when finished reading
   * @params file the file to be read
   * @params callBack the method to be called when file has been read
   */
  readDataLocally: (file: File, callback): void => {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  },
};
