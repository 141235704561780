import { action, observable, runInAction, makeObservable } from "mobx";

import { NewRepository } from "../../../js/services/NewRepository";
import { TCCUserService } from "../../../js/services/TCCUserService";

import { createStoreContext, RootStore } from "../../../stores/rootStore";

import { IUser } from "../../../models/dataModel";

export class ManageUsersStore {
  /**
   * The search term used to filter users.
   */
  @observable private searchTerm: string = "";

  /**
   * The selected user in the manage users store.
   */
  @observable private selectedUser: IUser | undefined;

  /**
   * The suspension data for the user.
   */
  @observable private suspensionData?: any;

  /**
   * Indicates whether suspension data is currently being loaded.
   */
  @observable private loadingSuspensionData: boolean = false;

  /**
   * Indicates whether the users are currently being loaded.
   */
  @observable private loadingUsers: boolean = false;

  /**
   * The list of user candidates.
   */
  @observable private userCandidates: IUser[] = [];

  /**
   * Indicates whether the processing is currently in progress.
   */
  @observable private processing: boolean = false;

  /** Root store */
  private rootStore: RootStore;

  /** Validator to test user ID's against */
   
  private uidValidator =
    /^([a-zA-Z0-9_-]){8}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){12}$/;

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Deletes an user with from system, and displayes a message on whether the deletion succeeded.
   */
  @action
  public async deleteUser() {
    if (!this.selectedUser) return;

    this.processing = true;

    try {
      await NewRepository.deleteUser(this.selectedUser.id);

      runInAction(() => {
        this.selectedUser = undefined;
      });

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.delete_user.user_deleted"));
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.delete_user.user_deleting_failed"));
    } finally {
      runInAction(() => {
        this.processing = false;
      });
    }
  }

  /**
   * Resolves the selected user
   * @returns selected user
   */
  public getSelectedUser(): IUser | undefined {
    return this.selectedUser;
  }

  /**
   * Sets the selected user
   * @param user user to be set as selected
   */
  @action
  public async setSelectedUser(user: IUser | undefined) {
    runInAction(() => {
      this.selectedUser = user;
    });
    if (!!user && user.suspended) await this.loadSuspendedData(user);
  }

  /**
   * Resolves the user candidates
   * @returns user candidates
   */
  public getUserCandidates(): IUser[] {
    return this.userCandidates || [];
  }

  /**
   * Sets the user candidates
   * @param userCandidates user candidates to be set
   */
  @action
  public setUserCandidates(userCandidates: IUser[]) {
    this.userCandidates = userCandidates;
  }

  /**
   * Returns the search term
   */
  public getSearchTerm(): string {
    return this.searchTerm || "";
  }

  /**
   * Returns the search term
   */
  public getUserSuspendedDate(): string {
    return new Date(this.suspensionData?.createTime).toDateString() || "";
  }

  /**
   * Checks if the suspension data is currently being loaded.
   * @returns {boolean} True if the suspension data is being loaded, false otherwise.
   */
  public isLoadingSuspensionData(): boolean {
    return this.loadingSuspensionData;
  }

  /**
   * Checks if users are currently being loaded.
   * @returns {boolean} True if users are being loaded, false otherwise.
   */
  public isLoadingUsers(): boolean {
    return this.loadingUsers;
  }

  /**
   * Sets the search term
   * @param searchTerm search term to be set
   */
  @action
  public setSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  /**
   * Resolves if data is being processed
   * @returns true if is processing
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Finds users based on given search term: user id or email.
   * If the search term isn't an email address or a valid user ID, returns nothing.
   * @param searchTerm user id or email
   * @returns object containing users/organizations that match the search term
   */
  public async searchUsers(): Promise<void> {
    if (this.searchTerm.indexOf("@") > 0) {
      const fqs = "emailAddress==" + this.searchTerm;
      await this.getUsers(fqs);
    } else if (this.isValidUserId(this.searchTerm)) {
      const fqs = "externalId==" + this.searchTerm;
      await this.getUsers(fqs);
    }
  }

  /**
   * Suspends the selected user by updating their attributes and invoking the suspendUser method from the NewRepository.
   * Displays success or error notifications based on the result.
   */
  @action
  public async suspendUser(): Promise<void> {
    if (!this.selectedUser) return;

    this.processing = true;

    try {
      await NewRepository.suspendUser(this.selectedUser);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.suspend_user.user_suspended"));

      this.reloadUser();
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.suspend_user.user_suspending_failed"));
    } finally {
      runInAction(() => {
        this.processing = false;
      });
    }
  }

  /**
   * Unsuspends the selected user by updating their attributes,
   * and invoking the unsuspendUser method from the NewRepository.
   * Displays success or error notifications based on the result.
   */
  @action
  public async unSuspendUser() {
    if (!this.selectedUser) return;

    this.processing = true;

    try {
      await NewRepository.unsuspendUser(this.suspensionData.id);

      this.rootStore
        .getNotificationChannelStore()
        .success(this.rootStore.getTranslation("profile.admin.suspend_user.user_unsuspended"));

      this.reloadUser();
    } catch {
      this.rootStore
        .getNotificationChannelStore()
        .error(this.rootStore.getTranslation("profile.admin.suspend_user.user_unsuspending_failed"));
    } finally {
      runInAction(() => {
        this.processing = false;
      });
    }
  }

  @action
  private async filterDeletedUsersFromList(userEntries: IUser[]): Promise<IUser[]> {
    const filteredUsers: IUser[] = [];

    await Promise.all(
      userEntries.map(async (userEntry: IUser) => {
        try {
          const res = await TCCUserService.get(userEntry.id);

          if (!!res) {
            filteredUsers.push(res);
          }
        } catch {
          console.log("User " + userEntry.id + " not found");
        }
      }),
    );

    return filteredUsers;
  }

  @action
  private async loadSuspendedData(userEntry: IUser): Promise<void> {
    try {
      runInAction(() => {
        this.loadingSuspensionData = true;
      });

      const data = await NewRepository.getUserSuspendedData(userEntry.id);

      runInAction(() => {
        this.suspensionData = data;
        this.loadingSuspensionData = false;
      });
    } catch {
      console.log("Error getting suspended data for user " + userEntry.id);
    }
  }

  /**
   * Searches the TCCUsersDS for users that match the query items.
   * @param queryStr query string
   * @returns a list of users that match the query
   */
  @action
  private async getUsers(queryStr: string): Promise<void> {
    this.loadingUsers = true;
    let userList: IUser[] = [];

    try {
      const res = await TCCUserService.getUsers({ fq: queryStr });
      userList = await this.filterDeletedUsersFromList(res);

      runInAction(() => {
        if (!!userList) {
          if (userList.length === 1) {
            this.setSelectedUser(userList[0] as IUser);
            this.searchTerm = "";
          } else if (userList.length > 1) {
            this.userCandidates = userList;
          } else if (userList.length <= 0) {
            this.rootStore
              .getNotificationChannelStore()
              .error(this.rootStore.getTranslation("errors.no_matches_found"));
          }
        }

        this.loadingUsers = false;
      });
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    }
  }

  /**
   * Searches the TCCUsersDS for suspended users.
   * @returns a list of suspended users
   */
  @action
  public async showSuspendedUsers(): Promise<void> {
    this.loadingUsers = true;
    this.searchTerm = "";
    this.selectedUser = undefined;

    try {
      const userList = await TCCUserService.getUsers({ fq: `(isLocked==true;type==twh)` });

      runInAction(() => {
        if (!!userList) {
          if (userList.length === 1) {
            this.setSelectedUser(userList[0] as IUser);
          } else if (userList.length > 1) {
            this.userCandidates = userList;
          } else if (userList.length <= 0) {
            this.rootStore
              .getNotificationChannelStore()
              .error(this.rootStore.getTranslation("errors.no_matches_found"));
          }
        }

        this.loadingUsers = false;
      });
    } catch {
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    }
  }

  @action
  private async reloadUser(): Promise<void> {
    if (!!this.selectedUser) {
      this.loadingUsers = true;

      const updatedUsers = await this.filterDeletedUsersFromList([this.selectedUser]);

      runInAction(() => {
        this.setSelectedUser(updatedUsers[0]);
        this.loadingUsers = false;
      });
    }
  }

  /**
   * Checks if the user id is valid.
   * @param userId user id
   * @returns true/false
   */
  private isValidUserId(userId: string): boolean {
    return this.uidValidator.test(userId);
  }
}

/**
 * Context object for ManageUserStore instances.
 */
export const ManageUsersStoreContext = createStoreContext<ManageUsersStore>(ManageUsersStore);
