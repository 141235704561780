import { IFileItem, IVersion } from "../../models/dataModel";
import { RootStore } from "../../stores/rootStore";
import { createStoreContext } from "../../stores/rootStore";
import { action, observable, toJS, makeObservable } from "mobx";
import { ScanResultsChecker } from "../../utils/ScanResultsChecker";

/**
 * Store used to handle data for ScanReport component
 */
export class ScanReportDialogStore {
  /**
   * Root store
   */
  private rootStore: RootStore;

  /**
   * Infected binaries list
   */
  @observable
  private infectedBinaries: IFileItem[] = [];

  /**
   * Constructor
   * @param rootStore RootStore instance
   * @param versions Package versions list
   */
  public constructor(rootStore: RootStore, versions?: IVersion[]) {
    makeObservable(this);
    this.rootStore = rootStore;
    if (versions && versions.length > 0) {
      this.checkVersions(versions);
    }
  }

  /**
   * Gets list of infected binaries
   * @returns list of binaries
   */
  public getInfectedBinaries(): IFileItem[] {
    return this.infectedBinaries;
  }

  /**
   * Checks if any of version binaries have failed scan
   * @returns true if scan failures exist
   */
  public infectedBinariesExist(): boolean {
    return this.infectedBinaries.length > 0;
  }

  @action
  private checkVersions(versions: IVersion[]) {
    this.infectedBinaries = ScanResultsChecker.filterInfectedBinaries(toJS(versions));
  }
}

/**
 * Context object for ScanReportDialogStore instances.
 */
export const ScanReportDialogStoreContext = createStoreContext<ScanReportDialogStore>(ScanReportDialogStore);
