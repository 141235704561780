import * as React from "react";
import { useContext } from "react";
import { RootContext } from "../stores/rootStore";
import { TextWrapper } from "./TextWrapper";

/**
 * A component to wrap translated text containing HTML.
 */
export const TranslatedHtml = ({ ...props }: { entry: string; nonBreaking?: boolean; args?: any[] }) => {
  const store = useContext(RootContext);
  const html = store.getTranslation(props.entry, ...(props.args || []));
  return <TextWrapper text={html} nonBreaking={props.nonBreaking} cssClasses={["translated-html"]} />;
};
