import * as React from "react";
import { Fragment, useContext } from "react";
import { Link, useLocation } from "react-router";

import { RootContext } from "../stores/rootStore";
import { scrollToId } from "../utils/functions";

export const SideNavigationLink = ({ linkData }: { linkData: any }) => {
  const rootStore = useContext(RootContext);
  const { pathname } = useLocation();

  const currentViewActive = "/about/" + linkData.name == pathname;
  const classNameListItem = currentViewActive ? linkData.name + " header active" : linkData.name + " header";
  const classNameLink = linkData.subnavigation ? "sub-items before" : "";
  const testId = linkData.name + "-nav";

  if (linkData.subnavigation && currentViewActive) {
    return (
      <li className={classNameListItem} data-testid={testId}>
        <a className={classNameLink}>{rootStore.getTranslation(linkData.translationId)}</a>
        <ul>
          <li className="subheader">
            {linkData.subnavigation.map((linkData: { name: string; translationId: string }, index: number) => (
              <div key={index}>
                <a onClick={() => scrollToId(linkData.name)}>{rootStore.getTranslation(linkData.translationId)}</a>
              </div>
            ))}
          </li>
        </ul>
      </li>
    );
  } else {
    return (
      <Fragment>
        <li className={classNameListItem} data-testid={testId}>
          <Link to={"/about/" + linkData.name} className={classNameLink}>
            {rootStore.getTranslation(linkData.translationId)}
          </Link>
        </li>
      </Fragment>
    );
  }
};
