import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext } from "react";

import { DialogContext, DialogStore } from "../../dialogs/dialogStore";
import { RootContext, RootStore } from "../../stores/rootStore";
import { EditorModeStore, EditorModeStoreContext } from "./editorModeStore";

import { BatchEdit } from "../../dialogs/batch-edit/BatchEdit";
import { BatchTranslations } from "../../dialogs/batch-translations/BatchTranslations";
import { Translations } from "../../dialogs/translations/Translations";
import { AddCodeNameForPackageDialog } from "./AddCodeNameForPackageDialog";

import { shouldShowAddCodeName } from "../../utils/Immutability";

import { IEntity } from "../../models/dataModel";

/**
 * A component that displays toggle buttons
 */
export const ToggleButtons = observer(() => {
  const rootStore = useContext(RootContext);
  const editorModeStore = useContext(EditorModeStoreContext);
  const dialog = useContext(DialogContext);

  const [shouldShowAddCodeNameDialog, setShouldShowAddCodeNameDialog] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    (async () => {
      setShouldShowAddCodeNameDialog(await shouldShowAddCodeName(editorModeStore.getResource()!));
      setIsLoading(false);
    })();
  }, [editorModeStore.getResource()]);

  function handleEditorModeOn() {
    if (!!editorModeStore.getResource() && shouldShowAddCodeNameDialog) {
      dialog.open();
    } else {
      editorModeStore.setEditorMode(true);
    }
  }

  return (
    <div className="toggle-button">
      <button
        data-testid="editor-mode-off"
        onClick={() => editorModeStore.setEditorMode(false)}
        className={classNames({ active: !editorModeStore.getEditorMode() })}
      >
        {rootStore.getTranslation("shared.catalog_entity_edit.editor_mode_off")}
      </button>
      {!isLoading && (
        <button
          data-testid="editor-mode-on"
          onClick={handleEditorModeOn}
          className={classNames({ active: editorModeStore.getEditorMode() })}
        >
          {rootStore.getTranslation("shared.catalog_entity_edit.editor_mode_on")}
        </button>
      )}
      {!!editorModeStore.getResource() && shouldShowAddCodeNameDialog && (
        <AddCodeNameForPackageDialog contentItem={editorModeStore.getResource() as IEntity} />
      )}
    </div>
  );
});

/**
 * A component that displays editor mode selection.
 */
export const EditorMode = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const editorModeStore: EditorModeStore = useContext(EditorModeStoreContext);

  return (
    <div>
      <div className="editor-mode">
        <div className="l-right">
          <span>{rootStore.getTranslation("shared.catalog_entity_edit.editor_mode_label")}</span>
          <DialogContext.Provider value={new DialogStore()}>
            <ToggleButtons />
          </DialogContext.Provider>
          <div className="edits">
            {editorModeStore.shouldShowTranslationDialogs() && (
              <Fragment>
                <Translations />
                <BatchTranslations />
              </Fragment>
            )}
            {editorModeStore.shouldShowBatchEditDialog() && <BatchEdit />}
          </div>
        </div>
      </div>
    </div>
  );
});
