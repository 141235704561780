import * as React from "react";
import { Fragment, useContext } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { RootContext } from "../../stores/rootStore";
import { AclEditorStore, AclEditorStoreContext } from "../acl-editor/aclEditorStore";
import { CollectionPageStoreContext } from "../collectionPageStore";
import { AccessManagementStoreContext } from "./accessManagementStore";

import { VisibilityEnum } from "../../models/enums";

import { TranslatedHtml } from "../../components/TranslatedHtml";
import { AclEditor } from "../acl-editor/AclEditor";

/**
 * Displays private/public button for setting visibility
 */
export const ToggleButtons = observer(() => {
  const rootStore = useContext(RootContext);
  const collectionPageStore = useContext(CollectionPageStoreContext);
  const collection = collectionPageStore.getCollection();
  const accessManagementStore = useContext(AccessManagementStoreContext);

  function updateVisibility(visibility: VisibilityEnum) {
    if (collection) {
      accessManagementStore.updateVisibility(collection, visibility);
    }
  }

  return (
    <div className="toggle-button">
      {!collection?.isBanned && (
        <button
          data-testid="update-visibility-public"
          className={classNames({
            active: accessManagementStore.isPublicCollection(collection),
            inactive: !accessManagementStore.isPublicCollection(collection),
          })}
          disabled={accessManagementStore.isPublicCollection(collection)}
          onClick={() => updateVisibility(VisibilityEnum.PUBLIC)}
        >
          {rootStore.getTranslation("collections.labels.visibilityOptions.public")}
        </button>
      )}
      <button
        data-testid="update-visibility-private"
        className={classNames({
          active: !accessManagementStore.isPublicCollection(collection),
          inactive: accessManagementStore.isPublicCollection(collection),
        })}
        disabled={!accessManagementStore.isPublicCollection(collection)}
        onClick={() => updateVisibility(VisibilityEnum.PRIVATE)}
      >
        {rootStore.getTranslation("collections.labels.visibilityOptions.private")}
      </button>
    </div>
  );
});

/**
 * Displays spinner for indicating loading in progress
 */
export const Spinner = observer(() => {
  const accessManagementStore = useContext(AccessManagementStoreContext);

  return (
    <Fragment>
      {accessManagementStore.isUpdating() && (
        <div className="loading-indicator" data-testid="loading-visibility">
          <div className="spinner"></div>
        </div>
      )}
    </Fragment>
  );
});

/**
 * A component that displays access management features
 */
export const AccessManagement = observer(() => {
  const rootStore = useContext(RootContext);
  const collectionPageStore = useContext(CollectionPageStoreContext);
  const collection = collectionPageStore.getCollection();
  const accessManagementStore = useContext(AccessManagementStoreContext);
  const aclStore: AclEditorStore = new AclEditorStore(rootStore, collection);

  return (
    <AclEditorStoreContext.Provider value={aclStore}>
      <div className="access-rights">
        <ul className="wrapper">
          <li className="visibility">
            <span className="label">{rootStore.getTranslation("collections.labels.visibility")}</span>
            <ToggleButtons />
            <Spinner />
          </li>
          <li
            data-testid="accessContainer"
            className={classNames({
              "l-opacity-30": accessManagementStore.isPublicCollection(collection),
            })}
          >
            <span className="label">{rootStore.getTranslation("shared.access_rights.download_access")}</span>
            <AclEditor />
          </li>
        </ul>
        <div className="info">
          <div>
            <TranslatedHtml entry="shared.access_rights.id_help_html" />
          </div>
          <div className="max-inputs">
            <TranslatedHtml entry="shared.access_rights.max_input_limit" args={[aclStore.getMaxInputLimit()]} />
          </div>
        </div>
      </div>
    </AclEditorStoreContext.Provider>
  );
});
