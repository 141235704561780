import { observer } from "mobx-react";
import * as React from "react";
import { useContext, useState } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { UploadFormContext, UploadFormStore } from "../uploadFormStore";

import { CodeNameField } from "../../components/immutability/CodeNameField";
import { SetThumbnail } from "../components/SetThumbnail";
import { suggestCodeNameWithoutPrefix } from "../../utils/Immutability";

/**
 * Component for rendering the 'Invalid title' messages.
 */
const InvalidTitle = observer(() => {
  const rootStore = useContext(RootContext);
  const form = useContext(UploadFormContext);
  const entity = form.getEntityStore();

  const nameTooShort = entity.getTitle()!.length < form.getValidations().package.title.minLength;
  const titleMissing = entity.getTitle() === "";

  return (
    <div className="error">
      {nameTooShort && (
        <small className="error">
          {rootStore.getTranslation(
            "upload.package.title_validation.too_short",
            form.getValidations().package.title.minLength,
          )}
        </small>
      )}
      {titleMissing && (
        <small className="error">{rootStore.getTranslation("upload.package.title_validation.missing")}</small>
      )}
    </div>
  );
});

/**
 * Component for title field.
 */
const TitleField = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity = form.getEntityStore();

  const [fieldNotInFocus, setFieldNotInFocus] = useState(false);

  function handleInput(event) {
    event.preventDefault();
    entity.setTitle(event.target.value || "");
  }

  async function handleBlur() {
    setFieldNotInFocus(true);

    if (form.isImmutable() && !entity.getCodeName() && entity.getTitle()!.length >= form.getValidations().package.title.minLength) {
      entity.setCodeName(await suggestCodeNameWithoutPrefix(entity.getTitle()));
    }
  }

  return (
    <li>
      <label className="required" htmlFor="title">
        {rootStore.getTranslation("upload.package.title")}
      </label>
      <input
        type="text"
        name="title"
        value={entity.getTitle()}
        required
        onChange={handleInput}
        onBlur={handleBlur}
        data-testid="contentTitle"
      />
      {fieldNotInFocus && <InvalidTitle />}
    </li>
  );
});

/**
 * Component for rendering the 'Invalid description' messages.
 */
const InvalidDescription = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity = form.getEntityStore();

  const descriptionTooShort = entity.getDescription()!.length < form.getValidations().package.description.minLength;
  const descriptionMissing = entity.getDescription() === "";

  return (
    <div className="error">
      {descriptionTooShort && (
        <small className="error" data-testid="descriptionTooShort">
          {rootStore.getTranslation(
            "upload.package.description_validation.too_short",
            form.getValidations().package.description.minLength,
          )}
        </small>
      )}
      {descriptionMissing && (
        <small className="error">{rootStore.getTranslation("upload.package.description_validation.missing")}</small>
      )}
    </div>
  );
});

/**
 * Component for rendering the description field.
 */
const DescriptionField = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity = form.getEntityStore();

  const [fieldNotInFocus, setFieldNotInFocus] = useState(false);

  function handleInput(event) {
    entity.setDescription(event.target.value || "");
  }

  function handleBlur() {
    setFieldNotInFocus(true);
  }

  return (
    <li>
      <label className="required" htmlFor="description">
        {rootStore.getTranslation("upload.description")}
      </label>
      <textarea
        name="description"
        onChange={handleInput}
        data-testid="contentDescription"
        onBlur={handleBlur}
        value={entity.getDescription()}
        required
      />
      {fieldNotInFocus && <InvalidDescription />}
    </li>
  );
});

/**
 * Component for rendering the Content information column of the Upload content form.
 */
export const ContentInformation = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const entity = form.getEntityStore();

  const [alreadyInUse, setAlreadyInUse] = useState(false);

  React.useEffect(() => {
    async function checkCodeName() {
      setAlreadyInUse(!(await entity.isCodeNameUnique()));
    }

    form.isImmutable() && checkCodeName();
  }, [entity.getCodeName()]);

  return (
    <div className="column" data-testid="contentInformation">
      <h3 className="required">{rootStore.getTranslation("upload.version.content_information")}</h3>
      <ol>
        <TitleField />
        {form.isImmutable() && (
          <div>
            <CodeNameField
              codeName={entity.getCodeName()}
              setCodeName={(e) => entity.setCodeName(e.target.value)}
              isValid={entity.isCodeNameInCorrectFormat()}
              alreadyInUse={alreadyInUse}
              showErrorOnUnfocus={true}
              showTooltip={true}
            />
          </div>
        )}
        <DescriptionField />
        <SetThumbnail />
      </ol>
    </div>
  );
});
