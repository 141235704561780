import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import classNames from "classnames";
import { useSearchParams } from "react-router";

import { IDropdownOption, ISortOptions, ISortSettingsStore, ISortOption } from "../../models/dataModel";
import { SearchPageOrderEnum, SearchQueryParamEnum } from "../../models/enums";

import { Dropdown } from "../Dropdown";
import { RootContext } from "../../stores/rootStore";
import { SortOptions } from "../../utils/SortOptions";

/**
 * Component for rendering the SortBy dropdown.
 * @param RootStore rootstore for accessing translations and searchStore
 * @param pageWhereUsed the page where the sorting dropdown is used
 */
export const SortBy = observer(
  ({
    pageWhereUsed,
    sortSettingsStore,
    doNotAddUrlParams,
  }: {
    pageWhereUsed?: string;
    sortSettingsStore?: ISortSettingsStore;
    doNotAddUrlParams?: boolean;
  }) => {
    const rootStore = useContext(RootContext);
    const store = sortSettingsStore ? sortSettingsStore : rootStore.getSearchStore().getSearchPageSettingsStore();
    const sortOptions: IDropdownOption[] = resolveSortOptions();
    const pageSpecificClass = pageWhereUsed ? `${pageWhereUsed}` + "-sort-dropdown" : "";

    const [searchParams, setSearchParams] = useSearchParams();

    function resolveSortOptions() {
      const sortOptions: ISortOptions = SortOptions.get();
      if (pageWhereUsed === "versions") {
        return sortOptions.versions.map((option) => {
          return {
            value: option.rule,
            label: rootStore.getTranslation(option.label),
          };
        });
      } else if (pageWhereUsed === "local") {
        return sortOptions.local.map((option) => {
          return {
            value: option.rule,
            label: rootStore.getTranslation(option.label),
          };
        });
      } else if (pageWhereUsed === "result-options") {
        return _.filter(sortOptions.online, (option: ISortOption) => option.rule !== "recommended").map((option) => {
          return {
            value: option.rule,
            label: rootStore.getTranslation(option.label),
          };
        });
      } else {
        return sortOptions.online.map((option) => {
          return {
            value: option.rule,
            label: rootStore.getTranslation(option.label),
          };
        });
      }
    }

    function checkIfSortShouldBeChanged() {
      const filteredOption: IDropdownOption[] = _.filter(sortOptions, (sortOption) => {
        return sortOption.value === store.resultListOrder;
      });
      if (filteredOption.length == 0) {
        changeSort(sortOptions[0]);
      }
    }

    useEffect(() => {
      checkIfSortShouldBeChanged();
    }, []);

    function changeSort(opt: IDropdownOption | null) {
      if (!opt) return;

      if (opt.value) {
        store.setResultListOrder(opt.value as SearchPageOrderEnum);
        if (!doNotAddUrlParams) {
          searchParams.set(SearchQueryParamEnum.sortBy, opt.value);
          setSearchParams(searchParams);
        }
      }
    }

    function getSelectedOption() {
      if (store.resultListOrder) {
        return _.find(sortOptions, (so) => so.value == store.resultListOrder);
      }
    }

    const selectedOption = getSelectedOption();

    return (
      <div className={classNames("sort-dropdown", pageSpecificClass)} data-testid="sort">
        {sortOptions && (
          <Fragment>
            <span className="label">{rootStore.getTranslation("helpers.sortBy")}</span>
            <Dropdown
              options={sortOptions}
              onChange={changeSort}
              placeholder={rootStore.getTranslation("helpers.sortBy")}
              className="dropdown-wrapper sort-dropdown-wrapper light"
              selectedValue={selectedOption}
              inputId="sortingDropdown"
            />
          </Fragment>
        )}
      </div>
    );
  },
);
