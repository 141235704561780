import { IJobResult } from "./dataModel";

export interface ITCCCollection {
  id: string;
  creator?: ITCCContentOwner;
  modifier?: ITCCContentOwner;
  createTime?: string;
  modifyTime?: string;
  accessRole?: TCCAccessRoleEnum;
  allowComments?: boolean;
  attributes?: any;
  baseAccessLevel?: string;
  binaries?: { [name: string]: ITCCBinary };
  binaryNames?: string[];
  commentCount?: number;
  contentType: string;
  copyright?: string;
  customStrings?: any;
  description: string;
  details?: string;
  isCertified?: boolean;
  isHidden?: boolean;
  subtype?: string;
  tags?: string[];
  title: string;
  translations?: any;
  type: string;
  currentUserRating?: number;
  jobs?: IJobResult[];
  parentCatalogId?: string;
  reviewCount?: number;
  source?: string;
  views?: number;
  averageReview?: number;
  collectionCount?: number;
  entityCount?: number;
  materialCount?: number;
  packageCount?: number;
  isCatalog?: boolean;
  isPremium?: boolean;
  externalId?: string;
  subjectClass?: TCCObjectClassEnum;
  class?: TCCObjectClassEnum;
}

export interface ITCCPackage {
  id: string;
  creator?: ITCCContentOwner;
  modifier?: ITCCContentOwner;
  createTime?: string;
  modifyTime?: string;
  accessRole?: TCCAccessRoleEnum;
  allowComments?: boolean;
  attributes?: any;
  baseAccessLevel?: string;
  binaries?: { [name: string]: ITCCBinary };
  binaryNames?: string[];
  commentCount?: number;
  contentType: string;
  copyright?: string;
  customStrings?: any;
  description: string;
  details?: string;
  externalId?: string;
  isCertified?: boolean;
  isHidden?: boolean;
  subtype?: string;
  tags?: string[];
  title: string;
  translations?: any;
  type: string;
  currentUserRating?: number;
  jobs?: IJobResult[];
  parentCatalogId?: string;
  reviewCount?: number;
  source?: string;
  views?: number;
  averageReview?: number;
  downloads?: number;
  entityCount?: number;
  externalUrl?: string;
  subjectClass?: TCCObjectClassEnum;
  class?: TCCObjectClassEnum;
}

export interface ITCCEntity {
  id: string;
  averageReview?: number;
  accessRole?: TCCAccessRoleEnum;
  allowComments?: boolean;
  attributes?: any;
  baseAccessLevel?: string;
  binaries?: { [name: string]: ITCCBinary };
  binaryNames?: string[];
  class?: TCCObjectClassEnum;
  commentCount?: number;
  contentType: string;
  contributors?: string[];
  copyright?: string;
  creator?: ITCCContentOwner;
  createTime?: string;
  currentUserRating?: number;
  customStrings?: any;
  description: string;
  details?: string;
  downloads?: number;
  externalId?: string;
  externalUrl?: string;
  isCertified?: boolean;
  isHidden?: boolean;
  subtype?: string;
  tags?: string[];
  title: string;
  translations?: any;
  type: string;
  jobs?: IJobResult[];
  modifier?: ITCCContentOwner;
  modifyTime?: string;
  parentCatalogId?: string;
  reviewCount?: number;
  source?: string;
  views?: number;
  subComponents?: string[];
  superComponents?: string[];
  location?: { latitude: number; longitude: number; altitude: number };
  materialCount?: number;
  subjectClass?: TCCObjectClassEnum;
}

export interface ITCCBinary {
  id: string;
  creator?: ITCCContentOwner;
  modifier?: ITCCContentOwner;
  createTime?: string;
  modifyTime?: string;
  accessRole?: TCCAccessRoleEnum;
  allowComments?: boolean;
  attributes?: any;
  contentUrl?: string;
  ext?: string;
  fileSize?: number;
  isMetadata?: boolean;
  name: string;
  originalFileName?: string;
  url?: string;
  collection?: ITCCSimpleBinaryAttachableProjection;
  entity?: ITCCSimpleBinaryAttachableProjection;
  group?: ITCCSimpleBinaryAttachableProjection;
  material?: ITCCSimpleBinaryAttachableProjection;
  newsItem?: ITCCSimpleBinaryAttachableProjection;
  user?: ITCCSimpleBinaryAttachableProjection;
  package?: ITCCSimpleBinaryAttachableProjection;
  jobs?: IJobResult[];
}

export interface ITCCSimpleBinaryAttachableProjection {
  contentType?: string;
  id?: string;
}

export interface ITCCAccessControlObject {
  baseAccessLevel: TCCBaseAccessLevelEnum;
  isPublished?: boolean;
  finders: IACLEntry[];
  viewers: IACLEntry[];
  editors: IACLEntry[];
}

export interface IACLEntry {
  description?: string;
  id: string;
  isPublished?: boolean;
}

export interface ITCCContentOwner {
  id: string;
  displayName: string;
  emailAddress?: string;
  externalResourceType: TCCExternalResourceTypeEnum;
  pictureUrl?: string;
  trimbleId?: string;
  isVerified?: boolean;
}

export interface ITCCUser {
  id: string;
  createTime?: string;
  modifyTime?: string;
  binaries?: any;
  displayName: string;
  entityCount?: { [key: string]: number };
  collectionCount?: { [key: string]: number };
  packageCount?: { [key: string]: number };
  isVerified?: boolean;
  emailAddress?: string;
  attributes?: any;
  roles: string[];
  atcId?: string;
  externalId?: string;
  externalResourceType: TCCExternalResourceTypeEnum | "null";
  customWarehouseUrl?: string;
  personalInfo?: any;
  canContactUser?: boolean;
  suspendExpiration?: string | null;
  notificationPreferences?: any;
  authProvider?: TCCAuthProvidersEnum;
  isMuted?: boolean;
  description?: string;
  details?: string;
  trimbleId?: string | null;
  masterId?: string | null; // returned only for internal content editors
  language?: string | null;
  picture?: string | null; //defined as required in the API, but not always returned
  code?: string;
  isLocked?: boolean;

  groupName?: string; // obsolete?
}

export enum TCCExternalResourceTypeEnum {
  WORKGROUP = "workGroup",
  ENTITLEMENT = "entitlement",
  ORGANIZATION = "organization",
  USER = "user",
}

export enum TCCAccessRoleEnum {
  VIEWER = "viewer",
  EDITOR = "editor",
  FINDER = "finder",
  OWNER = "owner",
}

export enum TCCBaseAccessLevelEnum {
  VIEWER = "viewer",
  FINDER = "finder",
  BANNED = "banned",
  NONE = "none",
}

export enum TCCObjectClassEnum {
  COLLECTION = "collection",
  ENTITY = "entity",
  MATERIAL = "material",
  PACKAGE = "package",
}

export enum TCCAuthProvidersEnum {
  TRIMBLE_ID = "TRIMBLE_ID",
  DEV_LOGIN = "DEV_LOGIN",
  ENCRYPTED_GOOGLE = "ENCRYPTED_GOOGLE",
  ACS = "ACS",
  ACS_DUPLICATE = "ACS_DUPLICATE",
  TEKLA = "TEKLA",
}
