import * as React from "react";
import { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import { MyLikesContext, MyLikesStore } from "./myLikesStore";
import { RootContext } from "../../stores/rootStore";

import { ResultsWrapper } from "./ResultsWrapper";

export const MyLikes = observer(() => {
  const rootStore = useContext(RootContext);
  const store = new MyLikesStore(rootStore);

  useEffect(() => {
    store.fetchData();
    rootStore.getSearchStore().resetSearchTerm();
  }, []);

  return (
    <MyLikesContext.Provider value={store}>
      <section className="collection catalog" data-testid="myLikes">
        <ResultsWrapper />
      </section>
    </MyLikesContext.Provider>
  );
});
