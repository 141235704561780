import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";

import { PartnersContext, PartnersStore } from "./partnersStore";
import { TranslatedHtml } from "../../components/TranslatedHtml";
import { IOrganization } from "../../models/dataModel";

/**
 * A components that render list of partner organizations.
 */
export const Partners = observer(() => {
  const store: PartnersStore = useContext(PartnersContext);

  function getLink(organization: IOrganization): string {
    return "/organization/" + organization.id;
  }

  return (
    <Fragment>
      {store.wasDataFetched() && store.getPartners().length > 0 && (
        <section className="partners" data-testid="partners">
          <div className="partner-area">
            <div className="title">
              <Link to={store.getPartnersLink()} className="title-link">
                <TranslatedHtml entry="landing.partners" />
              </Link>
            </div>
            <div className="partner-items-section">
              {store.getPartners().map((partner: IOrganization, i: number) => {
                const imageLinkId = `partner-link-image-${partner.id}`;
                const imageContentUrl = partner.binaries?.thumbnail.contentUrl;
                return (
                  <div key={i} className="partner-items">
                    <Link to={getLink(partner)} data-testid={`partner-link-${partner.id}`}>
                      <img data-testid={imageLinkId} src={imageContentUrl} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
});
