import * as React from "react";
import { useContext } from "react";

import { RootContext, RootStore } from "../stores/rootStore";
import { Settings } from "../config/Settings";
import { LoginOrRegister } from "../upload/components/LoginOrRegister";

/**
 * Renders a login notification component.
 * This component displays a login note and a header with a login or register button.
 * If login is needed and the application is not offline, it also displays additional information.
 */
export const LoginNotification = () => {
  const rootStore: RootStore = useContext(RootContext);
  const localServiceNeeded = Settings.isOffline && !rootStore.getLocalServiceStore().isLocalServiceAccessible();

  return (
    <div className="login-note step" data-testid="loginNote">
      <header className="l-center-align">
        <h3>
          <LoginOrRegister />
        </h3>
        {localServiceNeeded && (
          <div id="loginNeeded" data-testid="localServiceInfo">
            {rootStore.getTranslation("shared.local_service.info")}
          </div>
        )}
      </header>
    </div>
  );
};
