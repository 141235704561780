import { Settings } from "../../config/Settings";
import { supportedLocales } from "../../constants/LocalizationConstants";
import _ from "underscore";

function getCookie(cookieName) {
  let cookie = {};
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
}

export const SearchDataConverter = {
  addParameters: function (data) {
    var selectedLocale = getCookie("locale");
    if (selectedLocale) {
      var filteredLocale = _.filter(supportedLocales, function (locale, key) {
        var isoCode = locale.isoCode.replace("-", "_");
        return isoCode === selectedLocale;
      });
      if (filteredLocale[0] && filteredLocale[0].lang) {
        _.extend(data, {
          lang: filteredLocale[0].lang,
        });
      }
    }
    _.extend(data, {
      relevancyScoringParams: {},
    });
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".titleBoost")) {
      _.extend(data.relevancyScoringParams, {
        titleBoost: localStorage.getItem(Settings.searchRelevancyStorage + ".titleBoost"),
      });
    }
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".titleTranslationsBoost")) {
      _.extend(data.relevancyScoringParams, {
        titleTranslationsBoost: localStorage.getItem(Settings.searchRelevancyStorage + ".titleTranslationsBoost"),
      });
    }
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".descriptionBoost")) {
      _.extend(data.relevancyScoringParams, {
        descriptionBoost: localStorage.getItem(Settings.searchRelevancyStorage + ".descriptionBoost"),
      });
    }
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".descriptionTranslationsBoost")) {
      _.extend(data.relevancyScoringParams, {
        descriptionTranslationsBoost: localStorage.getItem(
          Settings.searchRelevancyStorage + ".descriptionTranslationsBoost",
        ),
      });
    }
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".detailsBoost")) {
      _.extend(data.relevancyScoringParams, {
        detailsBoost: localStorage.getItem(Settings.searchRelevancyStorage + ".detailsBoost"),
      });
    }
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".detailsTranslationsBoost")) {
      _.extend(data.relevancyScoringParams, {
        detailsTranslationsBoost: localStorage.getItem(Settings.searchRelevancyStorage + ".detailsTranslationsBoost"),
      });
    }
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".viewsPopularityFactorBoost")) {
      _.extend(data.relevancyScoringParams, {
        viewsPopularityFactor: localStorage.getItem(Settings.searchRelevancyStorage + ".viewsPopularityFactorBoost"),
      });
    }
    if (localStorage.getItem(Settings.searchRelevancyStorage + ".downloadsPopularityFactorBoost")) {
      _.extend(data.relevancyScoringParams, {
        downloadsPopularityFactor: localStorage.getItem(
          Settings.searchRelevancyStorage + ".downloadsPopularityFactorBoost",
        ),
      });
    }
    const adaptiveSearch = localStorage.getItem(Settings.adaptiveSearchStorage + ".useAdaptiveSearch");
    if (adaptiveSearch && data.sortBy === "relevance DESC") {
      _.extend(data, {
        personalizeSearch: true,
      });
      const geoLocation = localStorage.getItem(Settings.adaptiveSearchStorage + ".geoLocation") || "FI";
      if (geoLocation) {
        _.extend(data, {
          personalizeSearchParams: { geoLocationCountry: geoLocation },
        });
      }
    }
    return data;
  },
};
