import _ from "underscore";

import { URLBuilder } from "../../utils/URLBuilder";
import { IdGenerator } from "../../utils/IdGenerator";
import { ModelTypeConverter } from "../converters/ModelTypeConverter";
import { LocalBinaryDS } from "../data-source/LocalBinaryDS";

function prepareData(subject, binary, client) {
  subject = subject || {};
  binary = binary || { attributes: {} };
  binary.attributes.location = binary.location;
  return {
    subjectId: subject.id,
    subjectClass: ModelTypeConverter.toSubjectClass(subject.type),
    reference: binary.location,
    name: binary.reference,
    attributes: binary.attributes,
  };
}

export const LocalBinaryService = {
  getBinaryUrl: function (subjectClass, subjectId, name) {
    var data = {
      subjectId: subjectId,
      subjectClass: subjectClass,
      name: name,
    };
    return URLBuilder.buildLocalUrl("/TeklaWarehouse/GetBinary", data);
  },
  upload: function (subject, binary, client) {
    binary = binary || {};
    return LocalBinaryDS.setBinary(prepareData(subject, binary, client), binary.file);
  },
  upload2: function (subject, binary, client) {
    binary = binary || {};

    var data = prepareData(subject, binary, client);
    if (!data.reference) {
      data.reference = IdGenerator.generateUUID();
    }
    return LocalBinaryDS.setBinary2({ payload: JSON.stringify(data) }, binary.file);
  },
  remove: function (subject, binary) {
    var data = _.pick(prepareData(subject, binary), "subjectId", "subjectClass", "name");
    return LocalBinaryDS.deleteBinary(data);
  },
};
