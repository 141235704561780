import { RequestBuilder } from "../RequestBuilder";
import { ResponseProcessor } from "../ResponseProcessor";
import { SearchDataConverter } from "../converters/SearchDataConverter";

export const TCCPackageDS = {
  createPackage: function (data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPostWithParams("/warehouse/v1.0/packages", data, { performAs: performAsId }),
    );
  },
  setPackage: function (data, params) {
    return ResponseProcessor.process(RequestBuilder.doPut("/warehouse/v1.0/packages/" + data.id, data, params));
  },
  updatePackage: function (data, params) {
    return ResponseProcessor.process(RequestBuilder.doPatch("/warehouse/v1.0/packages/" + data.id, data, params));
  },
  getAttributes: function (packageId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/attributes", data),
    );
  },
  setAttributes: function (packageId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/packages/" + packageId + "/attributes", data),
    );
  },
  updateAttributes: function (packageId, data, params) {
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/packages/" + packageId + "/attributes", data, params),
    );
  },
  getBinaries: function (packageId, data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/binaries", data));
  },
  getBinary: function (packageId, binaryName, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/binaries/" + binaryName, data),
    );
  },
  getBinaryData: function (url) {
    return RequestBuilder.doGetBinary(url);
  },
  copyBinary: function (targetPackageId, binaryData) {
    return ResponseProcessor.process(
      RequestBuilder.doPostWithSourceHeader(
        "/warehouse/v1.0/packages/" + targetPackageId + "/binaries/" + binaryData.reference,
        binaryData.url,
      ),
    );
  },
  updateBinary: function (packageId, data, file, fileName) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/packages/" + packageId + "/binaries/" + fileName, data, file),
    );
  },
  getPackage: function (packageId, data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId, data));
  },
  getPackageByExternalId: function (externalId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/packages/externalId/" + externalId, data),
      false,
      false,
      true,
    );
  },
  deleteBinary: function (packageId, binaryName) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/packages/" + packageId + "/binaries/" + binaryName),
    );
  },
  deletePackage: function (data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/packages/" + data.id, { performAs: performAsId }),
    );
  },
  updatePackageChildren: function (packageId, data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/packages/" + packageId + "/children", data, { performAs: performAsId }),
    );
  },
  deletePackageChild: function (packageId, data) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/packages/" + packageId + "/children/" + data.id),
    );
  },
  searchPackages: function (data, dontUnwrap) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/packages", SearchDataConverter.addParameters(data)),
      false,
      dontUnwrap,
    );
  },
  searchRecommendedPackages: function (data, dontUnwrap) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/packages/recommended", data),
      false,
      dontUnwrap,
    );
  },
  addReview: function (packageId, review) {
    return ResponseProcessor.process(
      RequestBuilder.doPatch("/warehouse/v1.0/packages/" + packageId + "/reviews", review),
    );
  },
  getReviews: function (packageId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/reviews"));
  },
  deleteReview: function (packageId) {
    return ResponseProcessor.process(RequestBuilder.doDelete("/warehouse/v1.0/packages/" + packageId + "/reviews"));
  },
  getSubscribers: function (packageId) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/followers"));
  },
  getAcl: function (packageId, data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/acl", data));
  },
  setAcl: function (packageId, data, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/packages/" + packageId + "/acl", data, { performAs: performAsId }),
    );
  },
  addComment: function (packageId, commentData) {
    return ResponseProcessor.process(
      RequestBuilder.doPost("/warehouse/v1.0/packages/" + packageId + "/comments", commentData),
    );
  },
  getComments: function (packageId, sortOptions, dontUnwrap) {
    return ResponseProcessor.process(
      RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/comments", sortOptions),
      false,
      dontUnwrap,
    );
  },
  updateComment: function (packageId, commentId, commentText) {
    return ResponseProcessor.process(
      RequestBuilder.doPut("/warehouse/v1.0/packages/" + packageId + "/comments/" + commentId, commentText),
    );
  },
  deleteComment: function (packageId, commentId) {
    return ResponseProcessor.process(
      RequestBuilder.doDelete("/warehouse/v1.0/packages/" + packageId + "/comments/" + commentId),
    );
  },
  createBinary: function (packageId, data, file, performAsId) {
    return ResponseProcessor.process(
      RequestBuilder.upload("/warehouse/v1.0/packages/" + packageId + "/binaries", data, file, {
        performAs: performAsId,
      }),
    );
  },
  createBinaryWithMime: function (packageId, data, file, mimeType) {
    return ResponseProcessor.process(
      RequestBuilder.upload2("/warehouse/v1.0/packages/" + packageId + "/binaries", data, file, mimeType),
    );
  },
  getEntities: function (packageId, data) {
    return ResponseProcessor.process(RequestBuilder.doGet("/warehouse/v1.0/packages/" + packageId + "/entities", data));
  },
};
