import * as React from "react";
import { useContext, useState } from "react";
import { observer } from "mobx-react";
import { RootContext } from "../../stores/rootStore";
import { AdminStoreContext, AdminStore } from "./adminStore";
import { ManageUsers } from "./manage-users/ManageUsers";
import { MoveContent } from "./move-content/MoveContent";
import { AdjustRelevancySettings } from "./AdjustRelevancySettings";
import { ManageFeatureToggles } from "./ManageFeatureToggles";
import { ManageContent } from "./manage-content/ManageContent";
import { ManageInfectedBinaries } from "./ManageInfectedBinaries";
import { ExportAnalytics } from "./ExportAnalytics";
import { GrantAnalyst } from "./GrantAnalyst";
import { Dropdown } from "../../components/Dropdown";
import { IDropdownOption } from "../../models/dataModel";
import { AnalyticsStore, AnalyticsStoreContext } from "../../components/analytics/analyticsStore";
import { ContentManager } from "./content-manager/ContentManager";
import { ManageOrganizations } from "./manage-organizations/ManageOrganizations";

export const AdminPage = observer(() => {
  const rootStore = useContext(RootContext);
  const store = new AdminStore(rootStore);
  const [action, setAction] = useState("");

  const actionOptions: IDropdownOption[] = [
    { label: "Content manager", value: "contentManager" },
    { label: "Adjust relevancy settings", value: "relevancySettings" },
    { label: "Export analytics", value: "exportAnalytics" },
    { label: "Grant analyst role", value: "grantAnalyst" },
    { label: "Manage feature toggles", value: "manageFeatureToggles" },
    { label: "Manage content", value: "manageContent" },
    { label: "Manage infected binaries", value: "manageInfectedBinaries" },
    { label: "Manage users", value: "manageUsers" },
    { label: "Manage organizations", value: "manageOrganizations" },
    { label: "Move content", value: "migrate" },
  ];

  function handleSelectAction(selectedOption: IDropdownOption | null) {
    if (!selectedOption) return;

    setAction(selectedOption.value);
  }

  return (
    <AdminStoreContext.Provider value={store}>
      <div className="profile" data-testid="adminPage">
        <div className="access-rights">
          <div className="wrapper">
            <section className="group">
              <div className="action-dropdown">
                <Dropdown
                  options={actionOptions}
                  placeholder={rootStore.getTranslation("profile.admin.choose_action")}
                  onChange={handleSelectAction}
                  className="dropdown-wrapper gray"
                />
              </div>
              {action === "grantAnalyst" && <GrantAnalyst />}
              {action === "exportAnalytics" && (
                <AnalyticsStoreContext.Provider value={new AnalyticsStore(rootStore)}>
                  <ExportAnalytics />
                </AnalyticsStoreContext.Provider>
              )}
              {action === "convertOffline" && <div />}
              {action === "manageFeatureToggles" && <ManageFeatureToggles />}
              {action === "manageContent" && <ManageContent />}
              {action === "contentManager" && <ContentManager />}
              {action === "manageInfectedBinaries" && <ManageInfectedBinaries />}
              {action === "migrate" && <MoveContent />}
              {action === "manageUsers" && <ManageUsers />}
              {action === "manageOrganizations" && <ManageOrganizations />}
              {action === "relevancySettings" && <AdjustRelevancySettings />}
              {action === "createSiteMap" && <div />}
            </section>
          </div>
        </div>
      </div>
    </AdminStoreContext.Provider>
  );
});
