import { action, observable, runInAction, makeObservable } from "mobx";
import _ from "underscore";

import { NewRepository } from "../../../js/services/NewRepository";
import { NewCollection } from "../../../js/factories/NewCollection";

import { createStoreContext, RootStore } from "../../../stores/rootStore";

import { IBatchVersion, ICollection, IBatchCollection, IBatchEntity, IModifier } from "../../../models/dataModel";

interface IQuery {
  offset: number;
  count: number;
  sortBy: string;
  showHidden: boolean;
  fq?: string;
}

export class ContentManagerStore {
  /**
   * Packages list
   */
  @observable private collections: IBatchCollection[] = [];

  /**
   * Indicator if data is being queried
   */
  @observable private querying = false;

  /**
   * Indicator if data is being processed
   */
  @observable private processing = false;

  /** Root store */
  private rootStore: RootStore;

  /**
   * Search options
   */
  @observable private searchOptions: Record<string, any> = {
    includeHidden: false,
    includeBanned: false,
    searchedId: "",
  };

  /** Validator to test ID's against */
   
  private contentUidValidator =
    /^([a-zA-Z0-9_-]){8}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){12}$/;

  /** Validator to test ID's against */
   
  private orgUidValidator =
    /^([a-zA-Z0-9_-]){9}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){4}-([a-zA-Z0-9_-]){12}$/;

  /**
   * Constructor
   * @param rootStore RootStore object providing dependencies.
   */
  public constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  /**
   * Retrieves the collections from the content manager store
   * @returns An array of ICollection objects representing the collections.
   */
  public getCollections(): IBatchCollection[] {
    return this.collections;
  }

  /**
   * Returns the search ID
   */
  public getSearchedId(): string {
    return this.searchOptions.searchedId || "";
  }

  /**
   * Resolves if hidden packages should be searched
   * @returns true if hidden packages should be searched
   */
  public getIncludeHiddenContent(): boolean {
    return this.searchOptions.includeHidden;
  }

  /**
   * Resolves if banned content should be searched
   * @returns true if banned content should be searched
   */
  public getIncludeBannedContent(): boolean {
    return this.searchOptions.includeBanned;
  }

  /**
   * Returns selected collections
   * @returns list of collections
   */
  public getSelectedCollections(): IBatchCollection[] {
    return _.filter(
      this.collections,
      (collection: IBatchCollection) => !!collection.selected && collection.id !== "invalid-packages-collection",
    );
  }

  /**
   * Returns selected packages
   * @returns list of packages
   */
  public getSelectedPackages(): IBatchEntity[] {
    let selectedPackages: IBatchEntity[] = [];

    _.each(this.collections, (collection: IBatchCollection) => {
      if (!!collection.packages)
        selectedPackages = selectedPackages.concat(
          _.filter(collection.packages, (packageItem) => !!packageItem.selected),
        );
    });

    return selectedPackages;
  }

  /**
   * Returns selected versions
   * @returns list of versions
   */
  public getSelectedVersions(): IBatchVersion[] {
    let versionsList: IBatchVersion[] = [];

    _.each(this.collections, (collection: IBatchCollection) => {
      !!collection.packages &&
        _.each(collection.packages, (packageItem: IBatchEntity) => {
          const versions = _.filter(packageItem.versions || [], (version: IBatchVersion) => !!version.selected);
          versionsList = versionsList.concat(versions);
        });
    });

    return versionsList;
  }

  /**
   * Checks if any content is selected.
   * @returns {boolean} True if any content is selected, false otherwise.
   */
  public hasSelectedContent(): boolean {
    return this.getSelectedCollections().length > 0 || this.packagesAreSelected() || this.versionsAreSelected();
  }

  /**
   * Retrieves the versions for a given package.
   * @param packageItem - The package item to retrieve versions for.
   * @returns A promise that resolves to the versions of the package.
   */
  @action
  public async getVersionsForPackage(packageItem: IBatchEntity) {
    packageItem.loading = true;

    try {
      const versions = await NewRepository.getVersions(packageItem, false, this.searchOptions.includeHidden);

      runInAction(() => {
        packageItem.open = true;
        packageItem.versions = _.map(versions, (version: IBatchVersion) => {
          version.package = packageItem;
          version.attributes.bannedDate = packageItem.attributes?.bannedDate;
          return version;
        });
      });
    } catch {
      console.log("Error while fetching versions for package " + packageItem.title);
    } finally {
      runInAction(() => {
        packageItem.loading = false;
      });
    }
  }

  /**
   * Hides selected packages
   */
  @action
  public async hideSelectedPackagesAndVersions() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        const promises: Array<Promise<any>> = [];
        this.processing = true;
        _.each(this.getSelectedCollections(), (collection: IBatchCollection) => {
          collection.isHidden = true;
          promises.push(NewRepository.updateCollection(collection, user));
        });
        _.each(this.getSelectedPackages(), (packageItem: IBatchEntity) => {
          packageItem.isHidden = true;
          promises.push(NewRepository.updatePackage({ id: packageItem.id, isHidden: true }, user, true));
        });
        _.each(this.getSelectedVersions(), (version: IBatchVersion) => {
          version.isHidden = true;
          promises.push(NewRepository.updateVersion(version, user as IModifier));
        });
        await Promise.all(promises);

        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("profile.admin.packages_hidden"));
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
      } finally {
        runInAction(() => {
          this.processing = false;
        });

        await this.searchCollectionsAndPackages();
      }
    }
  }

  /**
   * Bans selected packages
   */
  @action
  public async banSelectedPackages() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        const promises: Array<Promise<any>> = [];
        this.processing = true;

        const bannedDate = new Date().toISOString();
        const collections = _.map(this.collections, (collection: IBatchCollection) => {
          if (collection.selected) {
            collection.isBanned = true;
            collection.attributes = { ...collection.attributes, bannedDate: bannedDate };
            promises.push(NewRepository.updateCollection(collection, user));
          }

          collection.packages = _.map(collection.packages || [], (packageItem: IBatchEntity) => {
            if (collection.selected || packageItem.selected) {
              collection.open = true;
              promises.push(
                NewRepository.updatePackage(
                  {
                    id: packageItem.id,
                    isBanned: true,
                    attributes: { ...packageItem.attributes, bannedDate: bannedDate },
                  },
                  user,
                  true,
                ),
              );
              return {
                ...packageItem,
                isBanned: true,
                attributes: { ...packageItem.attributes, bannedDate: bannedDate },
              };
            }
            return packageItem;
          });

          return collection;
        });

        await Promise.all(promises);

        runInAction(() => {
          this.collections = collections;
        });

        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("profile.admin.packages_banned"));
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
      } finally {
        runInAction(() => {
          this.processing = false;
        });
      }
    }
  }

  /**
   * Unbans selected packages
   */
  @action
  public async unbanSelectedPackages() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        const promises: Array<Promise<any>> = [];
        this.processing = true;

        const collections = _.map(this.collections, (collection: IBatchCollection) => {
          if (collection.selected) {
            collection.isBanned = false;
            collection.attributes = { ...collection.attributes, bannedDate: undefined };
            promises.push(NewRepository.updateCollection(collection, user));
          }

          collection.packages = _.map(collection.packages || [], (packageItem: IBatchEntity) => {
            if (packageItem.selected) {
              collection.open = true;
              promises.push(
                NewRepository.updatePackage(
                  {
                    id: packageItem.id,
                    isBanned: false,
                    visibility: collection.visibility,
                    attributes: { ...packageItem.attributes, bannedDate: undefined },
                  },
                  user,
                  true,
                ),
              );
              return {
                ...packageItem,
                isBanned: false,
                visibility: collection.visibility,
                attributes: { ...packageItem.attributes, bannedDate: undefined },
              };
            }
            return packageItem;
          });

          return collection;
        });

        await Promise.all(promises);

        runInAction(() => {
          this.collections = collections;
        });

        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("profile.admin.packages_unbanned"));
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
      } finally {
        runInAction(() => {
          this.processing = false;
        });
      }
    }
  }

  /**
   * Resolves if data is being queried
   * @returns true if is querying
   */
  public isQuerying(): boolean {
    return this.querying;
  }

  /**
   * Resolves if data is being processed
   * @returns true if is processing
   */
  public isProcessing(): boolean {
    return this.processing;
  }

  /**
   * Resolves if any of packages is selected
   * @returns true if selected
   */
  public packagesAreSelected(): boolean {
    return _.size(this.getSelectedPackages()) > 0;
  }

  /**
   * Deletes selected content
   */
  @action
  public async removeSelectedContent() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        const promises: Array<Promise<void>> = [];
        this.processing = true;
        _.each(this.getSelectedVersions(), (version) => {
          promises.push(NewRepository.deleteVersion(version, user.id));
        });
        _.each(this.getSelectedPackages(), (packageItem) => {
          promises.push(NewRepository.deletePackage(packageItem, user.id));
        });
        _.each(this.getSelectedCollections(), (collection) => {
          promises.push(NewRepository.deleteCollection(collection, user.id));
        });
        await Promise.all(promises);

        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("profile.admin.packages_removed"));

        runInAction(() => {
          this.collections = [];
          this.processing = false;
        });
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
      }
    }
  }

  /**
   * Searches for invalid packages.
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  @action
  public async searchInvalidPackages(): Promise<void> {
    try {
      this.querying = true;

      const packages = await NewRepository.getInvalidPackages();

      const collectionForInvalidPackages: any = NewCollection({
        id: "invalid-packages-collection",
        title: "Invalid packages",
      });
      collectionForInvalidPackages.packages = packages;
      collectionForInvalidPackages.open = true;

      runInAction(() => {
        this.collections = [collectionForInvalidPackages];
      });
    } catch {
      console.log("Error while fetching invalid packages");
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        this.querying = false;
      });
    }
  }

  /**
   * Searches for banned content.
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  @action
  public async searchBannedContent(): Promise<void> {
    const admin = this.rootStore.getUserStore().getCurrentUser();
    if (!admin) return;

    try {
      this.querying = true;

      const bannedPackages: IBatchEntity[] = await NewRepository.getBannedPackages(admin.id);
      let collections: IBatchCollection[] = [];

      if (!!bannedPackages) {
        await Promise.all(
          _.map(bannedPackages, async (packageItem: IBatchEntity) => {
            const collection = await NewRepository.getCollection(
              { id: packageItem.collection.id, isLocal: false },
              admin.id,
            );
            collections.push(collection as IBatchCollection);
          }),
        );

        collections = await Promise.all(
          _.chain(collections)
            .uniq(false, (collection: IBatchCollection) => collection.id)
            .map(async (collection: IBatchCollection) => {
              const packages: IBatchEntity[] = await this.getPackagesForCollection(collection);

              return {
                ...collection,
                packages: packages,
                open: !collection.isBanned,
                selected: collection.isBanned,
              };
            })
            .value(),
        );
      }

      runInAction(() => {
        this.collections = collections;
      });
    } catch {
      console.log("Error while fetching banned content");
      this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
    } finally {
      runInAction(() => {
        this.querying = false;
      });
    }
  }

  /**
   * Searches for collections and packages based on the provided query string.
   * @param queryStr The query string to search for.
   * @returns A promise that resolves to an array of IBatchCollection objects or undefined.
   */
  @action
  public async searchCollectionsAndPackages(queryStr?: string): Promise<IBatchCollection[] | undefined> {
    if (!queryStr) {
      queryStr = this.searchOptions.searchedId;
    }

    if (
      !!queryStr &&
      !_.isEmpty(queryStr) &&
      !(this.isValidContentOrCreatorId(queryStr) || this.isValidEmail(queryStr))
    )
      return;

    let collections: IBatchCollection[] = [];
    const admin = this.rootStore.getUserStore().getCurrentUser();
    if (!admin) return;

    try {
      this.querying = true;

      const max = 1000;
      let offset = 0;
      let queryParams: IQuery = {
        offset: offset,
        count: max,
        sortBy: "modifyTime ASC",
        showHidden: this.searchOptions.includeHidden,
      };

      if (!!queryStr && this.isValidContentOrCreatorId(queryStr)) {
        this.searchOptions.searchedId = queryStr;
        queryParams = { ...queryParams, fq: `(id==${queryStr}),(childIds=like=${queryStr}),(creator.id==${queryStr})` };
      } else if (!!queryStr && this.isValidEmail(queryStr)) {
        const user = await NewRepository.getUserByEmail(queryStr);
        queryParams = { ...queryParams, fq: `(creator.id==${user.id})` };
      }

      const result: { raw: any; results: ICollection[] } = await NewRepository.getCollectionsWithTotalCount(
        queryParams,
        admin.id,
      );

      while (offset < result.raw.total) {
        const res = await NewRepository.getCollections(queryParams, admin.id);
        runInAction(() => {
          collections = collections.concat(res);
          offset = offset + max;
        });
      }

      await Promise.all(
        _.map(collections, async (collection: IBatchCollection) => {
          const packages: IBatchEntity[] = await this.getPackagesForCollection(collection);

          runInAction(() => {
            collection.packages = packages;
            if (collection.id == queryStr) collection.selected = true;
          });
        }),
      );
    } finally {
      runInAction(() => {
        this.collections = collections;
        this.querying = false;
      });
    }

    return collections;
  }

  /**
   * Selects/unselects all packages
   * @params select true if all should be selected, false if unselected
   */
  @action
  public selectAllCollections(select: boolean) {
    this.processing = true;
    _.each(this.collections, (collection: IBatchCollection) => {
      collection.selected = select;
    });
    this.processing = false;
  }

  /**
   * Selects given item
   * @param item the item to be selected
   */
  @action
  public selectListItem(item: IBatchCollection | IBatchEntity | IBatchVersion, selected: boolean) {
    this.processing = true;
    item.selected = selected;
    this.processing = false;
  }

  /**
   * Sets searchHiddenPackages value
   */
  @action
  public setIncludeHiddenContent(): void {
    this.searchOptions.includeHidden = !this.searchOptions.includeHidden;
  }

  /**
   * Sets includeBanned value
   */
  @action
  public setIncludeBannedContent(): void {
    this.searchOptions.includeBanned = !this.searchOptions.includeBanned;
  }

  /**
   * Unhides selected packages
   */
  @action
  public async unhideSelectedPackagesAndVersions() {
    const user = this.rootStore.getUserStore().getCurrentUser();

    if (!!user) {
      try {
        const promises: Array<Promise<any>> = [];
        this.processing = true;
        _.each(this.getSelectedCollections(), (collection: IBatchCollection) => {
          collection.isHidden = false;
          promises.push(NewRepository.updateCollection(collection, user));
        });
        _.each(this.getSelectedPackages(), (packageItem) => {
          promises.push(NewRepository.updatePackage({ id: packageItem.id, isHidden: false }, user, true));
        });
        _.each(this.getSelectedVersions(), (version) => {
          version.isHidden = false;
          promises.push(NewRepository.updateVersion(version, user as IModifier));
        });
        await Promise.all(promises);
        this.rootStore
          .getNotificationChannelStore()
          .success(this.rootStore.getTranslation("profile.admin.packages_unhidden"));
      } catch {
        this.rootStore.getNotificationChannelStore().error(this.rootStore.getTranslation("profile.admin.error"));
      } finally {
        runInAction(() => {
          this.processing = false;
        });
        await this.searchCollectionsAndPackages();
      }
    }
  }

  /**
   * Resolves if any of versions is selected
   * @returns true if selected
   */
  public versionsAreSelected(): boolean {
    return _.size(this.getSelectedVersions()) > 0;
  }

  private isValidContentOrCreatorId(id: string): boolean {
    return this.contentUidValidator.test(id) || this.orgUidValidator.test(id);
  }

  private isValidEmail(emailAddress: string): boolean {
    if (!!emailAddress) {
      const pattern = new RegExp(/\S+@\S+/);
      return pattern.test(emailAddress.toLowerCase());
    } else {
      return false;
    }
  }

  private async getPackagesForCollection(collection: IBatchCollection): Promise<IBatchEntity[]> {
    let packages: IBatchEntity[] = [];
    try {
      packages = await NewRepository.getPackages(
        collection,
        true,
        this.rootStore.getUserStore().getCurrentUser()?.id,
        this.searchOptions.includeHidden,
      );

      await Promise.all(
        _.map(packages, async (packageItem: IBatchEntity) => {
          try {
            runInAction(() => {
              packageItem.selected = packageItem.id === this.searchOptions.searchedId;

              if (packageItem.selected) {
                this.getVersionsForPackage(packageItem);
              }
            });
          } catch {
            console.log(
              "Error while fetching versions for package " + packageItem.title + " in collection " + collection.title,
            );
          }
        }),
      );
    } catch {
      console.log("Error while fetching packages and versions for collection " + collection.title);
    } 
    
    return packages || [];
    
  }
}

/**
 * Context object for ContentManagerStore instances.
 */
export const ContentManagerStoreContext = createStoreContext<ContentManagerStore>(ContentManagerStore);
