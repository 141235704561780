import * as React from "react";
import { Fragment, useContext } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Link } from "react-router";

import { ICollectionProps, IItem, IGetResourcePath } from "../models/dataModel";
import { ExternalResourceTypeEnum, SanitizationModeEnum, SearchPageListStyleEnum } from "../models/enums";

import { TranslatedHtml } from "./TranslatedHtml";
import { TextWrapper } from "./TextWrapper";
import { AlertIcon } from "../my/alerts/AlertIcon";
import { IGetResourcePathContext } from "../search/searchPageStore";
import { isLiked } from "../utils/functions";

/**
 * A component to display CollectionListItem.
 */
export const CollectionListItem = observer(
  ({ collection, listStyle = SearchPageListStyleEnum.THUMBNAILS, showAlerts }: ICollectionProps) => {
    const getResourcePath: IGetResourcePath | undefined = useContext(IGetResourcePathContext);

    function getCollectionPath(): string {
      if (!!getResourcePath) {
        return getResourcePath.getResourcePath(collection);
      } else {
        return (collection.isLocal ? "/collections/localDetails" : "/collections/online/") + collection.id;
      }
    }

    function getOrganizationPath(): string {
      return "/organization/" + collection.creator!.id;
    }

    function getCollectionInlineStyles() {
      const thumbnailUrl = collection.isLocal ? collection.thumbnail!.url : collection.thumbnails![0].contentUrl;
      return {
        backgroundImage: `url(${thumbnailUrl})`,
      };
    }

    function wasLiked(): boolean {
      return isLiked(collection);
    }

    function renderPackagesCount(): JSX.Element {
      return (
        <Fragment>
          <div className="packages-count" data-testid="packagesCount">
            <span className="icon icon-drawer" />
            <span className="item-count">{collection.packagesCount}</span>
          </div>
        </Fragment>
      );
    }

    function renderCompanyName(): JSX.Element {
      return (
        <Fragment>
          {collection.creator!.externalResourceType === ExternalResourceTypeEnum.ORGANIZATION && (
            <div className="company-name" data-testid="creatorName">
              <Link to={getOrganizationPath()} className="organization">
                {collection.creator!.displayName}
              </Link>
            </div>
          )}
        </Fragment>
      );
    }

    function renderThumbnails(): JSX.Element {
      return (
        <div className="thumb-item-details">
          <Link
            to={getCollectionPath()}
            className="item-title"
            title={collection.title}
            data-testid="collectionNameThumbnail"
          >
            {collection.title}
          </Link>
          {renderPackagesCount()}
          {renderCompanyName()}
        </div>
      );
    }

    function renderList(): JSX.Element {
      return (
        <div className="details">
          <Link to={getCollectionPath()} className="item-title">
            <h3 data-testid="collectionNameList">{collection.title}</h3>
          </Link>
          {!collection.isLocal && (
            <ol>
              <div className="likes">
                <TranslatedHtml entry={"likes"} args={[collection.reviewCount || 0]} />
              </div>
            </ol>
          )}
          {renderPackagesCount()}
          {renderCompanyName()}
          {collection.description && (
            <div className="description">
              <TextWrapper
                text={collection.description}
                sanitizationMode={SanitizationModeEnum.STRICT}
                truncate={200}
              />
            </div>
          )}
        </div>
      );
    }

    function renderCollection(): JSX.Element {
      const itemClassNames = classNames("item", "can-select", {
        liked: wasLiked(),
        "thumb-item": listStyle == SearchPageListStyleEnum.THUMBNAILS,
      });

      return (
        <div className={itemClassNames}>
          <Link to={getCollectionPath()} className="thumb-box">
            <figure data-testid="inlineStyles" style={getCollectionInlineStyles()} />
          </Link>
          {listStyle == SearchPageListStyleEnum.THUMBNAILS && renderThumbnails()}
          {listStyle == SearchPageListStyleEnum.LIST && renderList()}
          <ul
            className={classNames("item-actions", {
              listmode: listStyle == SearchPageListStyleEnum.LIST,
            })}
          >
            {showAlerts && <AlertIcon item={collection as IItem} />}
          </ul>
        </div>
      );
    }

    return renderCollection();
  },
);
