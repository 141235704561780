
import _ from "underscore";
import { escapeHtml, pluralize } from "../utils/functions";

export const Ru = {
  set: (): void => {
    if (!window.locale) {
      window.locale = {};
    }
    if (!window.locale["ru-RU"]) {
      window.locale["ru-RU"] = {};
    }

    _.extend(window.locale["ru-RU"], {
      myDetails: {
        my_roles: "Мои роли",
        my_roles_link: "Роли и права доступа для Tekla Warehouse контента",
        my_roles_link_url: "https://support.tekla.com/ru/doc/tekla-structures/not-version-specific/twh_roles_and_access_rights",
        organization_roles: "Роли",
        roles: {
          member: "Редактор содержимого Tekla Warehouse (Сотрудник)",
          employee: "Сотрудник",
          contentEditor: "Редактор содержимого Tekla Warehouse (Внешняя)",
        },
      },
      immutability: {
        add_code_name_for_package: "Добавить кодовое имя для содержимого",
        code_name: "Кодовое имя",
        immutable: "Неизменный",
        archive: "Архивировать",
        archive_collection: "Архивировать коллекцию и все ее содержимое",
        archive_version: "Архивировать выбранную версию",
        archive_package: "Архивировать пакет и все его содержимое",
        archive_dialog_text: "Заархивированное содержимое недоступно для отображения и поиска в Tekla Warehouse. Однако при предварительном использовании содержимого с облачной Tekla Structures службой облачная служба может продолжать обращаться к нему.",
        archive_success: function (name: string) {
          return `${name} заархивировано`;
        },
        archive_failure: function (name: string) {
          return `Не удалось заархивировать ${name}`;
        },
        archived_content_redirect_message: "Содержимое, к которому вы пытаетесь перейти, заархивировано.",
        edit_title_move_or_archive: "Редактировать заголовок, переместить или архивировать",
        edit_title_or_archive: "Редактировать заголовок или архивировать",
        major_update: "Крупное обновление",
        major_update_description: "Изменения, нарушающие обратную совместимость: добавление или удаление функций, другие критические изменения.",
        minor_update: "Незначительное обновление",
        minor_update_description: "Изменения с обратной совместимостью, например новые функции или усовершенствования, без разрыва существующей функциональности.",
        patch_update: "Исправление",
        patch_update_description: "Обратно совместимые исправления, такие как патчи к безопасности, исправления ошибок или повышение производительности, без новых функций.",
        info: "Ваша организация предоставляет содержимое для облачной службы Tekla Structures. Содержимое в облачное службе Tekla Structures должно иметь кодовое имя и номер версии для обеспечения совместимости и единообразия.",
        immutable_files_info: "Для обеспечения единообразия содержимого в облачной службе удалять или изменять файлы невозможно. Если необходимы изменения, архивируйте текущую версию и загрузите новую.",
      },
      shared: {
        read_more_link: function (readMoreUrl: string) {
          return `Подробнее см. на <a href="${readMoreUrl}" target="_blank">этой странице</a>.`;
        },
        resolve_conflict: {
          title: "Разрешение конфликта",
          description:
            'Найдены следующие элементы. Выберите "Оставить существующий", чтобы сохранить установленный на вашем компьютере элемент.',
          table_header_name: "Имя",
          table_header_type: "Тип",
          table_header_action: "Действие",
          action_keep_existing: "Оставить существующий",
          action_replace: "Заменить",
          action_done: "Готово",
        },
        auth: {
          authentication_process_failed: "Процесс аутентификации завершился сбоем",
          access_denied: "В доступе отказано",
          handle_unauthorized: {
            unauthorized: "Нет прав доступа",
            please_log_in: "Войдите или зарегистрируйтесь",
          },
        },
        translations: {
          edit_translations: "Редактировать переводы",
          edit_translations_info: "Выберите из списка язык, отредактируйте перевод и сохраните его",
          edit_translations_default_language_info:
            "Язык <b>По умолчанию</b> отображается, когда выбранный пользователем язык недоступен",
          edit_content: "Информация о контенте",
          edit_support: "Поддержка",
          updated_successfully: "Переводы успешно обновлены",
          error_on_update: "Ошибка при обновлении переводов",
          default: "По умолчанию",
          batch_translations: "Пакетные переводы",
          export_import_info:
            "<b>Экспортируйте</b> шаблон пакетных переводов для контента, который вы хотите локализовать, отредактируйте шаблон путем добавления в него переводов, а затем <b>импортируйте</b> шаблон обратно, чтобы сохранить сразу несколько переводов.",
          import_translations: "Импорт",
          export_translations: "Экспорт",
          translations_missing: " (пусто)",
          select_content: "Выберите переводы для экспорта:",
          import_finished: "Импорт завершен.",
          select_file: "Выбрать файл",
          select_file_title: "Выберите файл для импорта:",
          import_from_file: "Импорт",
          import_error_info: "Файл не соответствует формату шаблона:",
          export_selected: "Экспорт",
          invalid_file: "Недопустимый формат содержимого файла",
          import_translations_finished: "Переводы импортированы",
          details_too_long: "Длина сведений превышает максимально допустимую",
          import_failed: "Не удалось импортировать переводы",
          translate_to_other_languages: "Перевести на другие языки",
          import_count: function (count) {
            return "Обновлены переводы для " + count + " элементов контента";
          },
          invalid_language: function (contentId, language) {
            return "Контент '" + contentId + "' содержит недопустимый язык '" + language + "'";
          },
          invalid_field: function (contentId, translationField) {
            return "Контент '" + contentId + "' содержит недопустимое поле перевода '" + translationField + "'";
          },
          invalid_too_long: function (contentId, translationField) {
            return (
              "Длина значения поля перевода '" +
              translationField +
              "' в контенте '" +
              contentId +
              "' превышает максимально допустимую"
            );
          },
        },
        "3dPic": {
          "3d_pic_description": "3D-модель",
          "3d_pic_added": "3D-модель успешно добавлена",
          "3d_pic_updated": "3D-модель успешно обновлена",
          "3d_pic_removed": "3D-модель успешно удалена",
          "3d_pic_update_failed": "Не удалось обновить 3D-модель",
          "3d_pic_remove_failed": "Не удалось удалить 3D-модель",
          "3d_pic_missing":
            "Отсутствует 3D-изображение для контента. Выберите изображение, нажав кнопку 'Выбрать 3D-модель ' ниже.",
          "3d_pic_uploading": "Модель загружается. Подождите...",
        },
        catalog_entity_edit: {
          froala_placeholder: "Щелкните здесь для редактирования контента",
          updated_successfully: "Успешно обновлено",
          update_failed: "Сбой при обновлении",
          edit_search_criteria: "Редактировать критерии поиска",
          edit_group_and_category: "Редактировать группу и категорию",
          edit_content: "Редактировать контент",
          edit_thumbnail: "Редактировать эскиз",
          edit_thumbnail_descriptive: "Рекомендуемые размеры: 400 x 300 px",
          edit_description: "Редактировать описание",
          edit_download_link: "Добавить или изменить партнерскую ссылку для загрузки",
          versions_not_shown_to_users:
            "Этот элемент содержимого содержит партнерскую ссылку для загрузки, поэтому пользователи Tekla Warehouse не могут видеть ранее добавленные к нему версии. Администраторы Tekla Warehouse и пользователи с правами на редактирование по-прежнему смогут видеть все версии элемента содержимого.",
          partner_download_link: "Введите URL-адрес для загрузки",
          partner_download_link_disclaimer:
            "Если вы добавите партнерскую ссылку для загрузки к существующему элементу содержимого, пользователи Tekla Warehouse не смогут видеть ранее добавленные к нему версии. Это действие невозможно отменить.",
          edit_title_or_delete: "Редактировать название или удалить",
          edit_title_or_move_delete: "Редактировать название, переместить или удалить",
          edit_support: "Редактировать информацию о поддержке",
          edit_release_notes: "Редактировать замечания к выпуску",
          edit_content_wysiwyg: "Редактировать сведения о контенте",
          edit_content_embed_link: "Подробнее в Tekla User Assistance &rsaquo;",
          edit_content_embed_info: "Встраивать можно только видео с YouTube, Vimeo или Alugha.",
          details_unallowed_embed_info: "Встраивать можно только содержимое со следующих доменов: YouTube, Vimeo или Alugha. Допустимые форматы URL-адресов:",
          your_video_id: "YOUR_VIDEO_ID",
          edit_content_video_link_error: "Недопустимая ссылка на видео. Можно добавлять видео с помощью URL-адресов YouTube и Vimeo.",
          edit_content_vide_code_error: "Недопустимый формат встраиваемого содержимого. Принимаются только iframes. Разрешенные поставщики видео — YouTube, Vimeo и Alugha.",
          details_max_length_exceeded: "Слишком длинные сведения",
          editor_mode_label: "Режим редактирования:",
          editor_mode_on: "Вкл.",
          editor_mode_off: "Выкл.",
          do_not_notify: "Незначительное обновление, уведомлять подписчиков не нужно",
          invalid_tag: "Недопустимый тег",
        },
        transfer_package: {
          header: "Переместить контент",
          actions: {
            move: "Переместить",
            cancel: "Отмена",
          },
          loading: "Загрузка коллекций...",
          moving: "Перемещение контента",
          select_collection: "Выберите коллекцию",
          content_moved: "Контент перемещен. Отображение изменений может занять несколько минут.",
          operation_failed: "Не удалось выполнить операцию",
        },
        linked_resources: {
          resource_linked: function (name) {
            return "Ссылка на контент успешно создана в " + name;
          },
          selected_resources_linked: function (name) {
            return "Ссылка на выбранный контент успешно создана в " + name;
          },
          resources_linked_with_errors: "Ссылка на контент создана, с некоторым контентом произошли ошибки",
          resources_linking_failed: "Не удалось создать ссылку на контент",
          resource_unlinked: "Ссылка успешно удалена. Отображение изменений может занять несколько минут.",
          resources_unlinked:
            "Ссылки успешно удалены из выбранного контента. Отображение изменений может занять несколько минут.",
          resources_unlinked_with_errors: "Ссылки удалены, с некоторым контентом произошли ошибки",
          resources_unlinking_failed: "Не удалось удалить ссылки",
          linking: "Создание ссылки",
          linking_failed: "Не удалось создать ссылку",
          linking_failed_title: "Для следующего контента не удалось создать ссылку:",
          unlinking_failed_title: "Для следующего контента не удалось удалить ссылку:",
          linking_failed_already_contains:
            "В выбранной коллекции уже присутствует ссылка на контент. Отображение изменений может занять некоторое время.",
          linking_failed_already_contains_short: "В выбранной коллекции уже присутствует ссылка на контент",
          unlinking_failed: "Не удалось удалить ссылку",
          unlinking_failed_does_not_contain:
            "Ссылка на контент уже удалена из коллекции. Отображение изменений может занять некоторое время.",
          unlinking_failed_does_not_contain_short: "Ссылка на контент уже удалена из коллекции",
        },
        notification_channel: {
          title_success: "успех",
          title_notice: "уведомление",
          title_info: "информация",
          title_error: "ошибка",
        },
        maintenance: {
          download_requires_tekla_maintenance: "Для загрузки требуется договор на обслуживание с Tekla",
          can_access_maintenance: {
            yes: "Да",
            no: "Нет",
          },
        },
        confirm: {
          title: "Подтверждение",
          yes: "Да",
          no: "Нет",
          ok: "ОК",
          close: "Закрыть",
          dont_show: "Больше не показывать",
        },
        show_more: " Показать больше",
        show_less: " Показать меньше",
        spinner_loading: "Загрузка",
        spinner_processing: "Обработка",
        spinner_loading_long_time: "Загрузка контента может занять несколько минут",
        local_service: {
          info: "Для создания локального и сетевого контента требуется плагин Tekla Warehouse Service. Используя Tekla Structures 20.1 SR1 и выше, вы сможете отправлять контент непосредственно из своей модели.",
          needed_to_create_content_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Плагин Tekla Warehouse Service</a> " +
            "необходим для создания локального и сетевого контента.",
          not_available_with_explanation_html:
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Плагин Tekla Warehouse Service</a> " +
            "отсутствует. Это плагин, необходимый для работы с локальными и сетевыми коллекциями.",
          download_to_unlock_all_features_html:
            "Загрузите " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Плагин Tekla Warehouse Service</a> " +
            "и получите доступ ко всем функциям.",
          service_out_of_date_html:
            "Ваш плагин Tekla Warehouse устарел. Обновите " +
            '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Плагин Tekla Warehouse Service</a> " +
            "и получите доступ ко всем функциям.",
        },
        footer: {
          links: {
            terms_and_conditions: "http://www.tekla.com/terms-and-conditions",
            extension_terms_and_conditions:
              "https://www.tekla.com/terms-and-conditions/terms-of-service-online-services",
            privacy_notice: "http://www.tekla.com/privacy-notice",
          },
          title: {
            select_language: "Выберите язык",
          },
          tekla_warehouse: "Tekla Warehouse",
          tekla_warehouse_service: "Tekla Warehouse Service",
        },
        invitations: {
          invite_other_users: "Пригласить других пользователей",
          send_invitation_to_email: "Отправить приглашение по электронной почте",
          add_e_mail_addresses: "Добавить адреса электронной почты",
          and_assign_role: "и назначить роль",
          send_invitation: "Отправить приглашение",
          invitation_already_sent: "Приглашение уже отправлено.",
          invitation_sent: "Приглашение отправлено.",
          update_selected_invitations: "Обновить выбранные приглашения",
          new_role: "Новая роль",
          invitations_list_updated: "Список приглашений обновлен.",
        },
        item_browser_title: "Выберите элементы контента",
        prerequisites: {
          description_placeholder: "Введите здесь описание",
          link_name_placeholder: "Введите здесь название ссылки",
          add_prerequisites_and_recommendations: "Предварительные условия и рекомендации (необязательно)",
          list_the_profiles_components_files:
            "Перечислите профили, компоненты, файлы или модели, необходимые для правильного функционирования контента.",
          link_to_existing_content: "Ссылка на существующий контент",
          please_enter_prerequisite_url: "Введите URL-адрес предварительного условия",
          please_enter_valid_prerequisite_url: "Введите действительный URL-адрес предварительного условия",
          missing_http: "Отсутствует http://",
          add_link: "Добавить ссылку",
        },
        treeview: {
          cannot_export_this_container:
            "Невозможно экспортировать этот контейнер. Выберите вместо него отдельные элементы.",
        },
        file_drop_zone: {
          too_big: function (max_size) {
            return "Файл должен быть меньше " + max_size + " МБ";
          },
          "invalid_type.": function (allowed_types) {
            return "Недопустимый тип файла.  Файл должен быть одного из следующих типов " + allowed_types;
          },
        },
        analytics: {
          timeframe: "Период:",
          timeframe_start: "Начало:",
          timeframe_end: "Конец:",
          eventtype: "Тип события:",

          downloads_daily_by_version: "Загрузки по версии",
          downloads_by_country: "Загрузки по стране",
          downloads_by_version: "Загрузки по версии",
          downloads_by_version_organization: "Загрузки по версии и организации",
          downloads_by_organization: "Загрузки по организации",
          downloads_daily_by_package: "Загрузки по элементу контента",
          views_daily_by_package: "Просмотры по элементу контента",
          views_by_country: "Просмотры по стране",
          views_by_organization: "Просмотры по организации",

          downloads_by_package: "Загрузки по элементу контента",
          views_by_package: "Просмотры по элементу контента",
          downloads_daily_by_collection: "Загрузки по коллекции",
          downloads_by_collection: "Загрузки по коллекции",
          views_daily_by_collection: "Просмотры по коллекции",
          views_by_collection: "Просмотры по коллекции",

          events_by_organization_func: function (version) {
            return 'События для версии "' + version + '" по организации'; // Version "2.1" events by organizations
          },
          pie_slice_others: "Другие",
          sum_all_versions: "Все версии",
          sum_all_packages: "Все элементы контента",
          sum_all_collections: "Все коллекции",
          sum_all_organizations: "Все организации",
          recording_started_at: "Запись данных ведется с июня 2015 г.",
          recording_view_events_started_at: "Запись данных ведется с марта 2017 г.",
          version_title_func: function (title) {
            return 'Версия "' + title + '"'; // Version "2.1"
          },
          downloads_count_func: function (title, count) {
            return (
              title + " (" + pluralize(count, [count + " загрузки", count + " загрузка", count + " загрузок"]) + ")"
            ); // Tekla Oy (21 downloads)
          },
          counted_events_info: "Количество загрузок включает все завершенные загрузки.",
          counted_events_info_package: "Количество загрузок включает все завершенные загрузки.",
          export: {
            caption: "Экспорт аналитики",
            select_options: "Выберите параметры",
            select_timeframe_description: "Выберите ниже период для экспорта аналитики:",
            select_eventtype_description: "Выберите ниже тип события для экспорта аналитики:",
          },
        },
        access_rights: {
          editors: "Редакторы",
          download_access: "Доступ на просмотр и загрузку",
          write_user_id_placeholder: "Введите ID пользователя",
          write_organization_id: "Введите допустимые идентификаторы организаций. Каждый идентификатор вводится в отдельной строке.",
          write_id_placeholder:
            "Введите ID пользователей, адреса электронной почты пользователей или ID организации. Вводите каждый ID или адрес электронной почты в отдельной строке.",
          id_help_html_short:
            "Из соображений безопасности поиск в нашей базе пользователей не предусмотрен.<br/><br/>" +
            "Узнайте ID пользователя непосредственно у человека, которому вы хотите предоставить доступ. <br/>" +
            'ID пользователя отображается в разделе <a href="#/my/">Мой профиль</a>.',
          id_help_html:
            "Из соображений безопасности поиск в нашей базе пользователей не предусмотрен.<br/><br/>" +
            "Узнайте ID пользователя непосредственно у человека, которому вы хотите предоставить доступ. Чтобы предоставить доступ всем пользователям в организации, укажите ID организации.<br/><br/>" +
            'ID пользователя и организации указаны в разделе <a href="#/my/">Мой профиль</a>.',
          max_input_limit: function (max_rows) {
            return (
              "Вводите каждый ID или адрес электронной почты в отдельной строке. Одновременно можно вводить до " +
              max_rows +
              " строк."
            );
          },
          input_too_long: "Введен слишком длинный текст.",
          viewer_already_added: function (input) {
            return `Пользователь ${input} уже добавлен в качестве наблюдателя`;
          },
          wrong_format: function (input) {
            return `${input} не является действительным ID или адресом электронной почты.`;
          },
          candidates_for_keyword: function (keyword) {
            return "Пользователи, найденные по адресу электронной почты или ID " + keyword;
          },
        },
      },
      images: {
        add_new: "Добавить",
        add: "Добавить",
        delete: "Удалить",
        set_as_default: "Эскиз по умолчанию",
        select_file: "Выбрать файл",
        choose_option: "Выберите один из вариантов",
        info_3D_exists: "Вы уже добавили 3D-модель. В контент можно добавить только одну модель.",
        action_options: {
          image: "Изображение",
          "3DModel": "3D-модель",
          video: "Видео",
        },
        video: {
          url: "URL-адрес видео",
          invalid_url: "Недопустимый URL-адрес",
        },
      },
      units: {
        bytes: "байт",
        kB: "КБ",
        MB: "МБ",
        GB: "ГБ",
        TB: "ТБ",
        PB: "ПБ",
      },
      tools: {
        download: "Загрузить",
      },
      likes: function (number) {
        return (
          "" +
          pluralize(number, [
            number + " пользователям понравилось",
            number + " пользователю понравилось",
            number + " пользователям понравилось",
          ])
        );
      },
      main_info: {
        header_title: "У Tekla Warehouse новый дизайн!",
        header_text: "Как вам новый, усовершенствованный сайт Tekla Warehouse?",
        header_text2: "Основные изменения:",
        bullet_text_1: "Четко заметный поиск на главной странице.",
        bullet_text_2:
          "Все фильтры на странице «Поиск» теперь находятся слева, а в результатах поиска помещается больше эскизов на одной странице.",
        bullet_text_3:
          "Кнопка «Загрузить» на страницах контента теперь находится по центру, чтобы использовать контент было легче. И загрузка, и установка теперь доступны с помощью кнопки Загрузить.",
        bullet_text_4:
          "И на страницах коллекций, и в корзине теперь есть инструкции для загрузки и установки всего контента, состоящие из трех шагов.",
      },
      organizations: {
        error_loading_collection_contents: "Ошибка при загрузке контента коллекции",
        error_loading_organization: "Ошибка при загрузке организации",
      },
      downloads_graph: {
        date: "Дата",
        total_views: "Всего просмотров",
        total_downloads: "Всего загрузок",
        views: "Виды",
        downloads: "Загрузки",
      },
      errors: {
        plugin_is_needed_html:
          '<a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="yellow">' +
          "Требуется плагин Tekla Warehouse Service" +
          "</a>" +
          ".",
        processingFailed: "Сбой обработки",
        forbidden: "Запрещено",
        resource_not_found: "Ресурс не найден",
        no_matches_found: "Совпадения не найдены",
        no_matches_found_for: function (input) {
          return "Пользователей с адресом электронной почты или ID " + input + " не найдено";
        },
        not_identified: "Не определено",
        error_while_searching: "Ошибка при поиске",
        error_user_not_found: "Пользователь или организация не найдены",
        error_while_adding_member: "Ошибка при добавлении участника прав доступа",
        error_while_removing_member: "Ошибка при удалении участника прав доступа",
      },
      offline_to_online: {
        fail_on_initialize: "Ошибка при обработке содержимого ZIP-файла, преобразование будет прервано",
        fail_on_validate: "Проверка метаданных не пройдена, преобразование прервано",
        fail_on_save: "Ошибка при сохранении контента, откат изменений",
        success: "Офлайн-контент успешно преобразован в онлайн-контент",
        validate_errors_title: "Не удалось преобразовать контент",
        processing: "Сохранение пакета ",
        processed: "Обработанный элемент",
        collection_thumbnail_missing_name: "В двоичных данных эскизов коллекции отсутствует 'имя файла'",
        collection_value_missing_func: function (field) {
          return "Collection is missing '" + field + "'";
        },
        package_thumbnail_missing_name_func: function (packageTitle) {
          return "В двоичных данных эскизов пакета '" + packageTitle + "' отсутствует 'имя файла'";
        },
        package_value_missing_title_func: function () {
          return "В пакете отсутствует 'заголовок'";
        },
        package_value_missing_func: function (packageTitle, field) {
          return "В пакете '" + packageTitle + "' отсутствует '" + field + "'";
        },
        package_invalid_values_func: function (packageTitle, field, invalidValues) {
          return (
            "Поле '" +
            field +
            "' в пакете '" +
            packageTitle +
            "' содержит следующие недопустимые значения: " +
            invalidValues
          );
        },
        package_invalid_tag_value_func: function (packageTitle, field, invalidValue) {
          return "Пакет '" + packageTitle + "' имеет недопустимый тег: " + invalidValue;
        },
        version_value_missing_title_func: function (packageTitle) {
          return "В версии пакета '" + packageTitle + "' отсутствует 'заголовок'";
        },
        version_value_missing_func: function (packageTitle, versionTitle, field) {
          return "В версии '" + packageTitle + "' пакета '" + versionTitle + "' отсутствует '" + field + "'";
        },
        version_invalid_values_func: function (packageTitle, versionTitle, field, invalidValues) {
          return (
            "Поле '" +
            field +
            "' в пакете '" +
            packageTitle +
            "' версии '" +
            versionTitle +
            "' содержит следующие недопустимые значения: " +
            invalidValues
          );
        },
        version_binary_missing_name_func: function (packageTitle, versionTitle) {
          return (
            "В двоичных данных пакета '" + packageTitle + "' версии '" + versionTitle + "' отсутствует 'имя файла'"
          );
        },
      },
      landing: {
        notifications: {
          local_collections_end_of_life: {
            title: "Важно: Завершение поддержки локальных коллекций в Tekla Warehouse",
            part1: "Начиная с 2 июня 2025 года, локальные коллекции больше не будут доступны в Tekla Warehouse. Пользователи больше не смогут создавать, просматривать или искать локальные коллекции, а также использовать контент из локальных коллекций.",
            part2: "Для получения помощи при переходе с локальных коллекций на онлайн-коллекции, пожалуйста, свяжитесь с вашей локальной службой поддержки.",
          },
          content_editor_role: {
            info_before_switch: function (date: string, productBulletinUrl: string) {
              return `Важно! Из соображений безопасности после ${date} ноября 2024 года для управления содержимым организации вам будет необходима роль редактора содержимого Tekla Warehouse. Попросите администратора своей организации предоставить вам эту роль. Подробнее см. в <a href="${productBulletinUrl}" target="_blank">информационном бюллетене</a>.`
            },
            info_after_switch: function (date: string, productBulletinUrl: string) {
              return `Из соображений безопасности после ${date} ноября 2024 года для управления содержимым организации вам будет необходима роль редактора содержимого Tekla Warehouse. Попросите администратора своей организации предоставить вам эту роль. Подробнее см. в <a href="${productBulletinUrl}" target="_blank">информационном бюллетене</a>.`
            },
            content_page_info: function (date: string, productBulletinUrl: string) {
              return `Не можете редактировать содержимое своей организации? После ${date} ноября вы должны иметь роль редактора содержимого Tekla Warehouse, чтобы управлять содержимым организации. Вы можете посмотреть свою роль, перейдя в раздел «Мой Warehouse» > «Мой профиль». Подробнее см. в <a href="${productBulletinUrl}" target="_blank">информационном бюллетене</a>.`
            },
            create_collections_info: function (date: string, productBulletinUrl: string) {
              return `Не видите возможность создания контента для вашей организации? После ${date} ноября вы должны иметь роль редактора содержимого Tekla Warehouse, чтобы управлять содержимым организации. Вы можете посмотреть свою роль, перейдя в раздел «Мой Warehouse» > «Мой профиль». Подробнее см. в <a href="${productBulletinUrl}" target="_blank">информационном бюллетене</a>.`
            },
            my_details_info: function (date: string, productBulletinUrl: string) {
              return `После ${date} ноября вы должны иметь роль редактора содержимого Tekla Warehouse, чтобы управлять содержимым организации. Попросите администратора вашей организации назначить вам роль. Вы можете узнать, кто является администратором вашей организации, в вашем Tekla Online профиле. Подробнее см. в <a href="${productBulletinUrl}" target="_blank">информационном бюллетене</a>.`
            },
          },
          service_plugin_update_info:
            "Возникают проблемы с подключением к Tekla Structures? Убедитесь, что вы используете последнюю версию плагина <a href='https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43' target='blank' rel='noopener noreferrer'>Tekla Warehouse Service</a>.",
        },
        maintenance_info:
          "Tekla Warehouse находится на обслуживании с 22:00:00 по 23:00:00 UTC 27.10.2017 г. Приносим извинения за неудобства.",
        release_notes_info: "Новые возможности Tekla Warehouse",
        release_notes_link:
          'Мы обновили Tekla Warehouse. Узнайте, <a href="https://teklastructures.support.tekla.com/not-version-specific/en/twh_development_updates_known_issues" target="_blank">что нового</a>',
        heading: "Tekla Warehouse",
        heading1Dev: "Ищете разработчика Tekla для своего проекта?",
        heading2Dev:
          'Предлагаем вашему вниманию новый каталог, который называется Tekla Development Experts.<br><br>Это место встречи талантливых программистов и пользователей Tekla, заинтересованных в их услугах.<br><br>Добавить в каталог информацию о себе могут все участники Tekla Partners Program.<br><br><a href="https://developer.tekla.com/tekla-development-experts" target="_blank">Найти разработчика &rsaquo;</a>',
        static_intro:
          "Это бесплатное хранилище для поиска, импорта и установки BIM-контента для Tekla Structures, а также обмена таким контентом внутри вашей компании и за ее пределами. Tekla Warehouse поможет вам с легкостью создавать модели высокого качества.",
        new_intro:
          'Корпорация Tekla теперь называется Trimble. Мы продолжаем разрабатывать программные решения Tekla, и у нас работают те же специалисты, что и раньше. Дальнейшая информация: <a href="http://www.tekla.com/evolution" target="_blank">www.tekla.com/evolution</a>',
        verifyAccount: "Подтвердите свою учетную запись",
        content: {
          next: "&rsaquo;",
          previous: "&lsaquo;",
          newest: "Последний добавленный контент &rsaquo;",
          popular: "Популярный контент &rsaquo;",
          recommended: "Рекомендуемые &rsaquo;"
        },
        news: {
          title: '<a href="https://www.tekla.com/about/news" target="_blank">Новости &rsaquo;</a>',
          item_title: "Это новости.",
          item_description:
            'Какие-нибудь новости, какие-нибудь новости, какие-нибудь новости, какие-нибудь новости, какие-нибудь новости, какие-нибудь новости.<br><a href="https://developer.tekla.com" target="_blank">Подробнее &rsaquo;</a>',
        },
        statistics: {
          title: "Warehouse в цифрах",
          partners: "Партнеров",
          partners_count: "352",
          collections: "Коллекций",
          collections_count: "793",
          items: "Элементов контента",
          items_count: "2893",
        },
        partners: "Наши партнеры",
        action_links: {
          search_title: "Искать контент?",
          upload_title: "Добавить контент?",
        },
        videos: {
          title:
            '<a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">Видеоролики &rsaquo;</a>',
          link: 'Больше видеороликов на нашем <a href="https://www.youtube.com/channel/UCwUVau--moWZXKKsvTmhWzg" target="_blank">канале YouTube</a>.',
        },
        unverifiedInstructions_html_func: function (settingsUrl) {
          return (
            'Поищите ссылку для подтверждения у себя в почтовом ящике или <u><a href="' +
            settingsUrl +
            '">запросите новую ссылку</a></u>.'
          );
        },
        verifyAccountAcceptTerms:
          "Подтвердите свой адрес электронной почты и примите условия использования и политику конфиденциальности",
        acceptTerms: "Примите условия использования и политику конфиденциальности",
        acceptUpdatedTerms:
          "Примите обновленные условия использования и политику конфиденциальности tekla online services",
        termsNotAccepted_html_func: function (settingsUrl) {
          return 'Вернитесь к своему <u><a href="' + settingsUrl + '">профилю</a></u>';
        },
        updatedTermsNotAccepted_html_func: function (settingsUrl) {
          return (
            'Ознакомьтесь с условиями использования и политикой конфиденциальности в своем <u><a href="' +
            settingsUrl +
            '">профиле</a></u> и примите их.'
          );
        },
        suggestions: {
          browseCollections: "Смотреть коллекции &rsaquo;",
          new: "Новые",
          popular: "Популярные",
          recommended: "Рекомендуемые",
          organizations: "Организации",
        },
        hero_images: {
          hero1: "../images/2015-05-Warehouse-campaign-carousel-en.png",
        },
      },
      localCollections: {
        selectFolder: "Выбрать папку",
        selectFolderInstructions: "В качестве коллекций можно использовать локальные и сетевые папки.",
        selectedFolder: "Выбранная папка (скопируйте и вставьте путь):",
        selectedFolderPlaceholder: "Например: C:\\папка, Z:\\сетевая папка, \\\\имя_компьютера\\папка",
        collectionCreation: {
          name: "Имя",
          createNewCollection: "Создать локальную коллекцию",
          pleaseInputTitle: "Введите название",
          invalidPath: "Недопустимый путь",
          access_denied: "Не удалось добавить коллекцию: отказано в доступе",
          unsafe_location: "Не удалось добавить коллекцию: небезопасное расположение",
          add_collection_failed: "Не удалось добавить коллекцию",
          minLengthError: "Название должно быть не короче 3 символов",
          maxLengthError: "Название должно быть не длиннее 20 символов",
        },
      },
      onlineCollections: {
        selectFolderInstructions: "В качестве коллекций можно использовать локальные и сетевые папки.",
        collectionCreation: {
          name: "Имя",
          createNewCollection: "Создать онлайн-коллекцию",
          pleaseInputTitle: "Введите название",
          minLengthError: "Название должно быть не короче 3 символов",
          maxLengthError: "Название должно быть не длиннее 20 символов",
        },
      },
      popups: {
        generic: {
          resourceSaved: "Ресурс сохранен",
        },
        survey: {
          do_you_see_potential: '<span style="font-size: 1.1em">Видите потенциал?</span>',
          give_us_feedback: '<span style="font-size: 1.1em">Обратная связь</span>',
          help_us_improve_html:
            'Помогите нам усовершенствовать Tekla Warehouse. <br/><a id="showSurvey">Щелкните здесь</a> или <a id="dontShow">больше не показывать</a>',
          dialog: {
            do_you_see_potential: "Видите потенциал?",
            help_us_improve_html: "Помогите нам усовершенствовать Tekla Warehouse. Ваше мнение очень важно для нас.",
            continue_to_survey: "Пройти опрос",
            exit_to_warehouse: "Нет, спасибо",
          },
        },
      },
      search: {
        bar: {
          search: "Поиск",
          filter: " ",
          more_filters: "Еще фильтры",
          hide_filters: "Скрыть фильтры",
          clear: "Очистить",
          exclude: "Исключить",
          include: "Включить",
          clear_filters: "Сбросить фильтры",
          load_defaults: "Использовать мои предпочтения &rsaquo;",
          save_defaults: "Сохранить как мои предпочтения &rsaquo;",
          defaults_saved_successfully: "Фильтры по умолчанию сохранены",
          edit_defaults: "Изменить настройки по умолчанию",
        },
        info: {
          searching_organizations: "Поиск в организациях...",
        },
        results: {
          summary_html: function (amount, amountTotal, searchTerm) {
            if (!searchTerm) {
              return pluralize(amountTotal, ["Нет результатов", amountTotal + " результатов"]);
            } else {
              return (
                pluralize(amountTotal, ["Нет результатов", amountTotal + " результатов"]) +
                ' по запросу "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          organization_search_summary_html: function (amountTotal, searchTerm, orgList) {
            if (orgList) {
              return amountTotal + " результатов";
            } else if (!searchTerm) {
              return "Введите слово для поиска, чтобы увидеть результаты по организации";
            } else if (searchTerm.length < 2) {
              return "Для поиска по организации необходимо ввести хотя бы два символа";
            } else {
              return (
                pluralize(amountTotal, ["Нет результатов", amountTotal + " результатов"]) +
                ' по запросу "' +
                escapeHtml(searchTerm) +
                '"'
              );
            }
          },
          online: " результатов в онлайн-контенте",
          local: " результатов в локальном контенте",
          organization: "результатов в организациях",
          plugin_missing_info:
            'Локальный контент отображается, только если у вас установлен плагин <a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43" class="blue">' +
            "Требуется плагин Tekla Warehouse Service" +
            "</a>" +
            ".",
          version_filter_info: "Фильтрация по версии ПО не работает в результатах для локального контента",
        },
        exclude_env_files: "Исключить файлы сред",
        sidebar: {
          filters: {
            measurementUnits: "Единицы измерения",
            locations: "Регионы",
            languages: "Языки",
          },
          placeholders: {
            use_categories: "Категория",
            measurement_units: "Единицы измерения",
            languages: "Языки",
            locations: "Регионы",
          },
        },
      },
      placeholders: {
        name: "Имя...",
        contentOwner: "Имя владельца контента...",
        useCategory: "Выберите категорию использования",
        selectType: "Выберите тип",
        filter: "Фильтр...",
        search: "Поиск...",
        choose: "Выбрать...",
        searchFilters: "Фильтры поиска...",
        selectContentItem: "Выбрать элемент контента...",
        search_content: "Поиск в контенте...",
        includeAs: "Включить как...",
        selectCollection: "Выберите коллекцию",
        selectUserGroup: "Выберите группу пользователей",
        online_collections_tooltip: "Щелкните для поиска в онлайн-контенте",
        local_collections_tooltip:
          "Щелкните для поиска в локальном и сетевом контенте, а также в контенте установленного экземпляра Tekla Structures",
        organizations_tooltip: "Щелкните для поиска в организациях",
        "filter-options": {
          products: "Все программные продукты",
          locations: "Все регионы",
          languages: "Все языки",
          measurementUnits: "Все единицы измерения",
          testedVersions: "Все версии ПО",
          useCategories: "Все категории",
          useCategoriesCombined: "Категория",
          allGroups: "Все группы",
          itemTypeCategories: "Все типы элементов",
        },
        "exclude-filter-options": {
          products: "Исключить программные продукты",
          locations: "Исключить регионы",
          languages: "Исключить языки",
          measurementUnits: "Исключить единицы измерения",
          testedVersions: "Исключить версии ПО",
          useCategories: "Исключить категории",
          useCategoriesCombined: "Исключить категорию",
          allGroups: "Исключить группы",
          itemTypeCategories: "Исключить типы элементов",
        },
      },
      link: {
        link_selected: "Создать ссылку на выбранное",
        unlink_selected: "Удалить ссылку на выбранное",
      },
      download_install: {
        choose_version: "Выберите версию ПО:",
        choose_content: "Выберите элементы контента в списке ниже:",
        choose_action: "Выберите действие для выбранных элементов:",
      },
      download: {
        copy_to_clipboard: "Копировать в буфер обмена",
        download_selected: "Загрузить",
        add_selected_items_to_basket: "Добавить выбранное в корзину",
        add_item_to_basket: "Добавить выбранное в корзину",
        add_all_items_to_basket: "Добавить все в корзину",
        add_all_items_to_basket_info: "Элементов более 100. Загрузка может занять некоторое время",
        add_all_items_to_basket_too_many_results: "Добавить можно не более 1000 элементов",
        cart_loading: "Элементов более 100. Загрузка может занять некоторое время",
        remove_item_from_basket: "Удалить из корзины",
        shopping_cart_no_content: "В корзине нет контента",
        shopping_cart_no_content_for_version: "В корзине нет контента для выбранной версии",
        shopping_cart_items_func: function (itemsCount) {
          return "Моя корзина (" + itemsCount + ") ";
        },
        download_instructions: {
          title: "Инструкции по загрузке",
          step_one: "Скопируйте данные для загрузки в буфер обмена с помощью этой кнопки",
          step_two_func: function (downloadAppUrl) {
            return (
              "Запустите приложение Tekla Warehouse Downloader. Загрузить это приложение можно " +
              "<a href=" +
              downloadAppUrl +
              ' target="_blank">здесь.</a> '
            );
          },
          step_three: "Приложение Tekla Warehouse Downloader поможет вам завершить процедуру загрузки.",
          show_data_title: "Не получается?",
          show_data_to_be_copied: "Скопировать вручную",
          hide_data_to_be_copied: "Скрыть данные для загрузки",
        },
      },
      install: {
        install_title: " элементов будет установлено с помощью плагина Tekla Warehouse.",
        failed_install_information:
          "Вы выбрали контент, который доступен только для загрузки. Этот контент не будет установлен. Загрузите этот контент с Tekla Warehouse отдельно.",
        verify_service_plugin_is_running: "Установка: убедитесь, что плагин Tekla Warehouse Service работает корректно",
        verify_ts_connection: "Установка: убедитесь, что программа Tekla Structures запущена",
        select_software_version: "Фильтровать контент для загрузки, установки или создания ссылки по версии ПО",
        download_requires_maintenance:
          "Для доступа к некоторым элементам ниже требуется договор на обслуживание с Tekla",
        install_selected: "Установить",
        start_install: "Начать установку",
        install_started: "Установка начата",
        select_ts_version: "Выберите версию TS",
        install_retry: "Если нужно повторить попытку, запустите установку с Tekla Warehouse еще раз",
        failed_installs_info_func: function (failedCount) {
          return "Контент, который не будет установлен (" + failedCount + " версий):";
        },
        total_selection_info_func: function (totalInstallsCount) {
          return "Установка выбранных " + totalInstallsCount + " элементов";
        },
      },
      helpers: {
        behalf: "От",
        sortBy: "Сортировать по:",
        filter: "Фильтровать результаты",
        showAsThumbs: "В виде эскизов",
        showAsList: "В виде списка",
        resultsPerPage: "На странице:",
        filter_by_version: "Фильтр по версии:",
        show_more_options: "Больше параметров",
        show_less_options: "Меньше параметров",
        filter_by_type: "Фильтр по типу:",
        select_all: "Выбрать все",
        show_content_items: "Показать элементы контента",
        choose_action: "Выберите действие для выбранного контента: ",
        show_collections: "Показать коллекции",
        search: {
          show: "Показать:",
          show_content_items: "Элементы контента",
          show_collections: "Коллекции",
          show_organizations: "Организации",
          show_online_content: "Онлайн-контент",
          show_offline_content: "Офлайн-контент",
          local_content_info:
            'Локальный контент отображается, только если у вас установлен плагин <b><a href="https://warehouse.tekla.com/#/catalog/details/f7a269e6-2966-4e18-8746-6893f1ab8b43">' +
            "Tekla Warehouse Service" +
            "</a></b>" +
            ".",
        },
        tekla_maintenance_required: "Для доступа к некоторым элементам ниже требуется договор на обслуживание с Tekla",
        show_content_alerts: "Показать уведомления по контенту ",
        show_collection_alerts: "Показать уведомления по коллекциям ",
      },
      actions: {
        load_more: "Загрузить еще",
        use_infitinite_scroll: "Бесконечная прокрутка",
        back: "Назад",
        show_download_dialog: "Загрузить",
        download: "Просто загрузить",
        download_on_partner_site: "Скачать с партнерского сайта",
        insert: "Установить",
        install: "Установить",
        downloadAndInstall: "Установить",
        createNew: "Создать",
        viewItems: "Просмотреть",
        viewCollection: "Просмотреть коллекцию",
        viewGroup: "Показать группу",
        joinGroup: "Присоединиться к группе",
        loginToDownload: "Войти и загрузить",
        show3DPic: "Показать 3D-модель",
        remove: "Удалить",
        browse: "Обзор",
        browseFolder: "Обзор",
        browseFileSystem: "Обзор",
        add: "Добавить",
        save: "Сохранить",
        batch_edit: "Пакетное изменение",
        translations: "Переводы",
        cancel: "Отмена",
        continue: "Продолжить",
        delete: "Удалить",
        addNetworkPath: "Добавить сетевую папку",
        select: "Выбрать",
        create: "Создать",
        update: "Обновить",
        choose_thumbnail: "Выбрать эскиз",
        choose_picture: "Выбрать изображение",
        remove_picture: "Удалить изображение",
        choose_3D_picture: "Выбрать 3D-модель",
        remove_3D_picture: "Удалить 3D-модель",
        like: "Нравится",
        unlike: "Не нравится",
        close: "Закрыть",
        export: "Экспорт",
        add_or_remove: "Добавить / удалить",
        select_content_to_convert: "Выбрать офлайн-контент",
        search: "Поиск",
        upload: "Отправить",
        edit: "Изменить",
        view_3d: "Щелкните для просмотра модели...",
      },
      mainNavigation: {
        teklaWarehouse: "Warehouse",
        teklaWarehouseBeta: "бета",
        search: "Поиск",
        profile: "Мой профиль",
        myWarehouse: "Мой Warehouse",
        myStuff: "Мой контент",
        logout: "Выход",
        login: "Войти",
        register: "Регистрация",
        upload: "Добавить",
        uploading: "Отправка",
        dashboard: "Панель управления",
        learnMore: "Подробнее",
        about: "О сервисе",
        shoppingCart: "Моя корзина",
        addToCartInfo:
          "Вы можете добавлять элементы в корзину, а затем загрузить или установить их все сразу. Загрузка полностью бесплатна",
      },
      cubeNavigation: {
        headings: {
          get_started: "НАЧАЛО РАБОТЫ",
          learn: "ОБУЧЕНИЕ",
          get_help: "СПРАВКА И ПОМОЩЬ",
          extend_your_tekla: "РАСШИРЕНИЕ ВОЗМОЖНОСТЕЙ TEKLA",
          collaborate: "COLABORAR",
          students_and_educators: "Students and educators",
          about: "О СЕРВИСЕ",
        },
        "tekla-online": "Онлайн-сервисы",
        teklaWarehouse: "Tekla Warehouse",
        teklaDevCenter: "Tekla Developer Center",
        teklaWebsite: "Веб-сайт Tekla",
        teklaCampus: "Tekla Campus",
        teklaDownloads: "Tekla Downloads",
        teklaForum: "Tekla Discussion Forum",
        teklaExtranet: "Tekla Extranet",
        teklaUserAssistance: "Tekla User Assistance",
        teklaMyAccount: "Мой профиль",
        discussionForum: "Discussion Forum",
        teklaBimSight: "Tekla BIMsight",
        tekla_e_learning: "Tekla E-learning",
        tekla_services: "СЕРВИСЫ TEKLA — ГЛАВНАЯ",
        register: "Регистрация",
        login: "Вход",
        evaluate: "Попробовать",
        buy: "Купить",
        download: "Загрузить",
        teklaTrainings: "Tekla Trainings",
        teklaProducts: "Продукты",
        teklaServices: "Услуги",
      },
      cubeNavigationLinks: {
        teklaWebsite: "http://www.tekla.com",
        teklaCampus: "https://www.tekla.com/solutions/campus",
        teklaUserAssistance: "https://support.tekla.com/",
        teklaDevCenter: "https://developer.tekla.com/",
        teklaForum: "https://forum.tekla.com/",
        teklaDownloads: "https://download.tekla.com/",
        teklaWarehouse: "https://warehouse.tekla.com/",
        tekla_services: "http://www.tekla.com/services",
      },
      subNavigation: {
        myCollections: "Мои коллекции",
        myLikes: "Мне нравится",
        profile: "Мой профиль",
        preferences: "Мои предпочтения",
        myUserGroups: "Мои группы пользователей",
        localCollections: "Локальные и сетевые коллекции",
        tsCollections: "Коллекции Tekla Structures",
        downloads: "Загрузки",
        selectContent: "Выбрать файлы контента",
        addMetadata: "Добавить метаданные",
        onlineCollections: "Онлайн-коллекции",
        userGroups: "Группы пользователей",
        createNew: "Добавить контент",
        notifications: "Уведомления",
        contentUsage: "Использование контента",
        browseCollections: "Смотреть коллекции",
        admin: "Администратор",
        sales: "Продажи",
        shoppingCart: "Моя корзина",
      },
      notifications: {
        title: "уведомление",
        invitedBy: "Приглашение от",
        invitationType: "Тип приглашения",
        invitedTo: "Приглашение в",
        createdAt: "Создано",
        action: "Действие",
        acceptInvitation: "Принять",
      },
      batch_edit: {
        selectActionType: "Выберите тип действия",
        edit_content: "Редактировать метаданные контента",
        content_title: "Контент",
        metadataToChange: "Изменяемые метаданные",
        selectContent: "Для контента",
        select_action: "Выберите действие",
        support: {
          support: "Поддержка",
          licenses: "Лицензии",
        },
        versions: {
          version: "Версия",
          prerequisities: "Необходимые компоненты и требования",
        },
        content: {
          select_collection: "Выберите коллекцию",
          show_collection: "Показать:",
          show_all_content: "Все коллекции",
          show_organization_content: "Коллекции моей организации",
          select_all: "Выбрать все",
          select_all_info: "(Это может занять некоторое время)",
          actionOptions: {
            add: "Добавить",
            remove: "Удалить",
            replace: "Заменить на",
            yes: "Да",
            no: "нет",
          },
          treecontrol: {
            open: "+",
            close: "-",
          },
          validation: {
            no_attributes_selected: "Атрибуты не выбраны",
            invalid_attributes: "Недопустимые значения атрибутов",
            invalid_actions: "Недопустимые действия",
            content_not_selected: "Контент не выбран",
            entities_not_selected: "Введены связанные с пакетами данные, однако пакеты не выбраны",
            versions_not_selected: "Введены связанные с версиями данные, однако версии не выбраны",
          },
        },
        update: {
          info_processing: "Не закрывайте это окно, пока все элементы не будут обработаны.",
          info_processed: "Все элементы обработаны.",
          processing: "Обработка элемента",
          error_info: "Возникли проблемы при обновлении следующих элементов:",
          package_failure_func: function (package_title, errorMsg) {
            return "Контент: " + package_title + ", ошибка: " + errorMsg;
          },
          version_failure_func: function (package_title, version_title, errorMsg) {
            return "Контент: " + package_title + ", версия: " + version_title + ", ошибка: " + errorMsg;
          },
          confirm_update_func: function (contentCount) {
            return "" + contentCount + " элементов будет обновлено. Продолжить?";
          },
        },
      },
      myUserGroups: {
        title: "Группы пользователей",
        createdByPrefix: "Автор:",
      },
      downloads: {
        title: "Загрузки",
      },
      myCollections: {
        new_collection: "Новая коллекция",
        my_collections: "Мои коллекции",
        created_by_me: "Созданные мной",
        local_collections: "Локальные и сетевые коллекции",
        online_collections: "Онлайн-коллекции",
        created_by_my_organization: "Созданные моей организацией",
        created_by_my_organizations: "Создано моими организациями",
        shared_with_me: "К которым мне предоставлен доступ",
        shared_with_me_or_organization: "К которым предоставлен доступ мне или моей организации",
        notification: {
          error: "Ошибка",
          error_loading_online_collections: "Ошибка при загрузке онлайн-коллекций",
          error_loading_packages_liked_by_me: "Ошибка при загрузке контента, который вам нравится",
          error_loading_my_organization_s_collections: "Ошибка при загрузке коллекций вашей организации",
          error_loading_my_shared_collections: "Ошибка при загрузке ваших коллекций с общим доступом",
        },
        local: {
          helpText1: "Для доступа к локальным коллекциям установите ",
          helpText2: "плагин Tekla Warehouse Service",
          helpText3: ".",
          title: "Локальные и сетевые коллекции",
          notFound: "Локальные коллекции пока не найдены.",
          myCollectionsTitle: "Мои коллекции",
          actions: {
            createNew: "Создать локальную или сетевую коллекцию",
          },
        },
      },
      organization: {
        news_feed: "Лента новостей",
        add_comment: "Добавить текст ленты",
        edit_comment: "Редактировать элемент ленты",
        subTabs: {
          collections: "Коллекции",
          analytics: "Аналитика",
          contact_and_support: "Контакты",
          news_feed: "Лента новостей",
        },
        edit: {
          edit_title: "Редактировать название",
          edit_description: "Редактировать описание",
          edit_contact: "Редактировать контактные данные",
          edit_logo: "Редактировать логотип",
          edit_url: "Редактировать URL-адрес",
          edit_email: "Редактировать адрес электронной почты",
          edit_phonenumber: "Редактировать номер телефона",
          edit_warehouse_url: "Редактировать URL-адрес Warehouse",
          edit_warehouse_url_subtitle: "Буквенные символы, без скандинавских символов, цифр или специальных символов",
          edit_url_subtitle: "Вставьте полный URL-адрес",
          edit_logo_descriptive: "Оптимальный размер изображения — примерно 350 * 200 пикселей",
          edit_facebook_url: "Редактировать URL-адрес Facebook",
          edit_twitter_url: "Редактировать URL-адрес Twitter",
          edit_linkedin_url: "Редактировать URL-адрес LinkedIn",
          edit_youtube_url: "Редактировать URL-адрес YouTube",
          edit_googleplus_url: "Редактировать URL-адрес Google+",
          logo_updated: "Логотип обновлен",
          logo_update_failed: "Не удалось обновить логотип",
          logo_remove_failed: "Не удалось удалить логотип",
          logo_removed: "Логотип успешно удален",
          updated_successfully: "Успешно обновлено",
          updated_failed: "Сбой при обновлении",
          add_comment: "Добавить",
          edit_comment: "Редактировать элемент ленты",
          comment_added: "Элемент ленты новостей успешно добавлен",
          comment_updated: "Элемент ленты новостей успешно обновлен",
          comment_deleted: "Элемент ленты новостей успешно удален",
          comment_add_failed: "Не удалось добавить ленту новостей",
          comment_update_failed: "Не удалось обновить элемент ленты новостей",
          comment_delete_failed: "Не удалось удалить элемент ленты новостей",
          comment_delete_confirmation: "Delete news feed item?",
        },
        search_by: function (name) {
          return "Искать онлайн-контент по " + name + " &rsaquo;";
        },
        contact_information: "Связаться с нами",
        website: "Веб-сайт &rsaquo;",
        show_newer_news: "Более новые",
        show_older_news: "Более старые",
      },
      online_collections: {
        new_collection: "Новая коллекция",
        online_collections: "Онлайн-коллекции",
        created_by_organizations: "Созданные организациями",
        created_by_users: "Созданные пользователями",
      },
      local_collections: {
        tab_title: "Контент",
        local_collections: "Локальные коллекции",
      },
      versions: {
        add_tool: "Добавить приложение",
        table_header: {
          name: "Имя",
          type: "Тип",
          platform: "Платформа",
          modified_at: "Изменено",
          size: "Размер",
          virus_scan: "Проверка на вирусы",
        },
        // placeholders
        select_file_type: "Выберите тип файла",
        select_platform: "Выберите платформу",
        select_unit: "Выберите единицу",
        select_quality: "Выберите качество",
        what_will_be_added: "Что вы хотите добавить?",
        select_files_from_your_disk: "Выбрать файлы с диска",
        add: "Добавить",
        //content management
        add_application: "Добавить приложение",
        select_content_from_tekla_structures: "Выбрать контент из Tekla Structures",
        or: "или",
        files: "Файлы",
        //version metadata
        version_information: "Информация о версии",
        version_display_name: "Отображаемое имя версии",
        version_number: "Номер версии",
        version_number_info: "Введите номер версии. После сохранения номера версии изменить его невозможно.",
        existing_versions: "Номера имеющихся версий этого содержимого",
        version_number_validation: {
          missing: "Необходимо ввести номер версии.",
          invalid: "Номер версии должен соответствовать правилам семантики наименования версий (КРУПНОЕ ОБНОВЛЕНИЕ.НЕЗНАЧИТЕЛЬНОЕ ОБНОВЛЕНИЕ.ИСПРАВЛЕНИЕ)",
          already_in_use: "Этот номер версии уже используется для данного содержимого.",
        },
        select_update_type: "Для создания номера версии сначала выберите тип обновления.",
        please_enter_a_version_name_or_number: "Введите имя или номер версии.",
        virus_scan: {
          soft_failed: "Не выполнено",
          hard_failed: "Не проверено",
          infected: "Заражено",
        },
      },
      collections: {
        by: "Автор:",
        edit: {
          edit_collection: "Редактировать коллекцию",
          edit_description: "Редактировать описание",
        },
        notification: {
          collection_updated: "Коллекция обновлена",
          error: "Ошибка",
          error_loading_other_users_collections: "Ошибка при загрузке коллекций других пользователей",
          error_loading_other_organizations_collections: "Ошибка при загрузке коллекций других организаций",
          error_loading_collection_contents: "Ошибка при загрузке контента коллекции",
          members_list_updated: "Список участников обновлен.",
          invitations_removed: "Приглашения удалены.",
          collection_removed: "Коллекция удалена",
          thumbnail_deleted: "Эскиз удален",
          wrong_file_type: "Неверный тип файла.",
          invalid_file_type: "Недопустимый тип файла.",
          selected_image_is_too_big: "Выбранное изображение слишком велико.",
          thumbnail_updated: "Эскиз обновлен",
          default_thumbnail_updated: "Эскиз по умолчанию обновлен",
          default_thumbnail_update_failed: "Не удалось обновить эскиз по умолчанию",
          thumbnail_added: "Эскиз добавлен",
          thumbnail_update_failed: "Не удалось обновить эскиз",
          video_added: "Видеоролик добавлен",
          video_adding_failed: "Не удалось добавить видеоролик",
          video_deleted: "Видеоролик удален",
          video_deleting_failed: "Не удалось удалить видеоролик",
        },
        confirm: {
          delete_all: function (title) {
            return 'Удалить коллекцию и весь контент в коллекции "' + title + '"?';
          },
          remove_members: "Продолжить?",
          remove_invitations: "Продолжить?",
        },
        useNetworkDrive: "Использовать сетевой диск или папку",
        createCollection: "Создать онлайн-коллекцию",
        createCollectionInstructions: "Создайте коллекцию для группирования вашего контента.",

        actions: {
          delete_thumbnail: "Удалить эскиз",
          edit_collection: "Редактировать коллекцию",
          delete_collection: "Удалить коллекцию",
          add_content: "Добавить контент",
        },
        subTabs: {
          content: "Контент",
          members: "Показать участников",
          analytics: "Аналитика",
          manage_access: "Управление доступом",
        },

        members: {
          add_user_groups: "Добавить группы пользователей",
          select_user_group: "Выберите группу пользователей",
          with_role: "с ролью",
          user_groups_added: "Группы пользователей добавлены.",
          members_list_updated: "Список участников обновлен.",
          update_selected_members: "Обновить выбранных участников",
          table_header: {
            member_type: "Тип участника",
            member_name: "Имя участника",
            email: "Электронная почта",
            role: "Роль",
            joined_at: "Присоединился",
            invited_at: "Приглашен",
          },
          new_role: "Новая роль",
        },
        member_list: {
          users_who_can_access_this_collection: "Пользователи, которым открыт доступ к этой коллекции",
          add_user_group: "Добавить группу пользователей",
          update_members: "Обновить участников",
          remove_members: "Удалить участников",
          pending_invitations: "Ожидающие приглашения",
          user: "пользователь",
          invite_member: "Пригласить участника",
          update_invitations: "Обновить приглашения",
          remove_invitations: "Удалить приглашения",
        },
        labels: {
          select_organization: "Выберите организацию",
          select_visibility: "Выберите видимость",
          title: "Название",
          name: "Имя",
          createAs: "Создать от имени: ",
          asMyself: "своего",
          asUserGroup: "группы пользователей",
          asOrganization: "организации",
          selectOwner: "Выберите организацию в качестве владельца контента",
          visibility: "Видимость",
          visibilityOptions: {
            public: "Общедоступная",
            private: "Частная",
          },
          description: "Описание",
        },
        new_collection: {
          please_input_a_username: "Введите имя пользователя",
          title_is_required_to_be_at_least_3_characters: "Название должно быть не короче 3 символов",
          title_cannot_be_longer_than_20_characters: "Название должно быть не длиннее 20 символов",
        },
        collection_list: {
          no_content: "Коллекции отсутствуют.",
          results_summary: function (number) {
            return "" + number + "  коллекция " + pluralize(number, ["элемента", "элемент", "элементов"]);
          },
        },
        entity_list: {
          no_content: "В этой коллекции нет контента.",
          results_summary: function (number) {
            return "" + number + " контент " + pluralize(number, ["элемента", "элемент", "элементов"]);
          },
          linked_content: "Связанный контент",
          unlink: "Удалить ссылку на контент",
        },
      },
      alerts: {
        no_updates: "В последнее время обновлений не было",
        notifications_marked_as_read: "Уведомление отмечено как прочитанное",
        cleared_alerts: "Все уведомления удалены",
        alert_by_email:
          "Отправить мне сообщение по электронной почте, когда что-либо из перечисленного ниже будет обновлено до новой версии или в коллекции появится новый контент",
        action_types: {
          create: "создано",
          update: "отредактировано",
          delete: "удалено",
          added_entity: "добавлена версия",
          removed_entity: "удалена версия",
          added_collection: "контент добавлен",
          removed_collection: "контент удален",
        },
        property_names: {
          description: "Сведения",
          searchCriteria: "Информация о критериях поиска",
          groupCategory: "Информация о группе и категории",
          support: "Информация о поддержке",
          licenses: "Информация о лицензиях",
          relatedContent: "Информация о связанном контенте",
        },
        count_active: function (amount) {
          return "(" + amount + " active)";
        },
        notification_text: function (action, updatedDate) {
          const ret = action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        property_change_notification_text: function (property, action, updatedDate) {
          const ret = property + " " + action + " " + updatedDate;
          return ret.charAt(0).toUpperCase() + ret.slice(1);
        },
        updated_func: function (dateStr) {
          return "Обновленные " + dateStr;
        },
        notification_channel_update: {
          preferences_updated_successfully: "Настройки уведомлений успешно обновлены",
          preferences_update_failed: "При обновлении настроек уведомлений произошла ошибка",
        },
      },
      browseDialog: {
        useNetworkDrive: "Использовать сетевой диск или папку",
        ownerGroups: {
          title: "Группы владельцев",
        },
      },
      itemTypeCategories: {
        all: "Все группы",
        application: "Приложения",
        "3dProduct": "3D-изделия",
        customComponent: "Пользовательские компоненты",
        tool: "Приложения",
        profile: "Профили",
        material: "Материалы",
        bolt: "Болты",
        rebar: "Арматура",
        reportTemplate: "Шаблоны отчетов",
        drawingSetup: "Файлы настроек чертежей",
        modelSetup: "Файлы настроек моделей",
        environmentFile: "Файлы сред",
        shape: "Формы",
        //'shapeCatalog': 'Каталог форм',
        //'foreignGeometryDefinition': 'Внешнее определение геометрии'
      },
      platforms: {
        WIN_X86: "32-разрядная",
        WIN_X64: "64-разрядная",
        ANY: "Без привязки к платформе",
      },
      platformsDownload: {
        WIN_X86: "32-разрядная",
        WIN_X64: "64-разрядная",
        ANY: " ",
      },
      contentItemSource: {
        other: "Общие - в папку модели",
        run: "Исполняемый инструмент",
        bolt: "Комплект болта",
        component: "Компонент",
        drawing: "Настройки чертежа (бета)",
        material: "Материал",
        profile: "Профиль",
        mesh: "Арматурная сетка",
        rebar: "Арматурный стержень",
        shape: "Форма",
        geometry: "Геометрия",
        modeltemplate: "Шаблон модели",
        cloningtemplate: "Шаблон клонирования",
        noaction: "Ничего не делать",
        releasenotes: "Замечания к выпуску",
      },
      contentItemHeader: {
        bolt: "Комплекты болтов",
        component: "Компоненты",
        drawing: "Настройки чертежа (бета)",
        material: "Материалы",
        profile: "Профили",
        mesh: "Арматурные сетки",
        rebar: "Арматурные стержни",
        shape: "Формы",
      },
      useCategoryGroups: {
        workflow: "Рабочий процесс",
        structures: "Конструкции",
        drawings: "Чертежи",
        modeling: "Моделирование",
      },
      useCategories: {
        title: "Используется в/для",
        conceptualDesign: "Эскизное проектирование",
        designEngineeringAnalysis: "Проектирование / расчет",
        detailing: "Деталировка",
        fabrication: "Производство",
        erectionInstallationPour: "Монтаж / установка / заливка",
        contracting: "Организация подрядных работ",
        scheduling: "Календарное планирование",
        takeOffEstimation: "Подсчет объемов / составление смет",
        concreteStructures: "Монолитный бетон",
        precastConcrete: "Сборный бетон",
        offshore: "Морские сооружения",
        steelStructures: "Металлоконструкции",
        timberStructures: "Деревянные конструкции",
        reinforcement: "Армирование",
        drawingCreation: "Создание чертежей",
        drawingManagement: "Управление чертежами",
        drawingEditing: "Редактирование чертежей",
        drawingAnnotation: "Аннотирование чертежей",
        viewSimulateNavigate: "Просмотр, имитационное моделирование, навигация",
        compareReviewManageData: "Сравнение, анализ и управление данными",
        softwareCollaboration: "Коллективная работа",
        productivity: "Повышение производительности труда",
        interoperability: "Совместимость и взаимодействие",
        systemSetup: "Настройка системы",
      },
      measurementUnits: {
        "0": "Метрические",
        "1": "Британские",
      },
      compatibleSoftwareProductOptions: {
        TC: "Trimble Connect",
        TS: "Tekla Structures",
        TBS: "Tekla BIMsight",
        SK: "SketchUp",
        openBIM: "Open BIM (IFC)",
        standalone: "Автономный контент",
      },
      testedVersions: {
        //'TS0': 'Tekla Structures 15',
        //'TS1': 'Tekla Structures 16',
        //'TS2': 'Tekla Structures 17',
        NVS: "Без привязки к версии",
        TS3: "TS 18.0",
        TS3_1: "TS 18.1",
        TS4: "TS 19.0",
        TS4_1: "TS 19.1",
        TS5: "TS 20.0",
        TS5_1: "TS 20.1",
        TS6: "TS 21.0",
        TS6_1: "TS 21.1",
        TS7: "TS 2016",
        TS8: "TS 2016i",
        TS9: "TS 2017",
        TS10: "TS 2017i",
        TS11: "TS 2018",
        TS12: "TS 2018i",
        TS13: "TS 2019",
        TS14: "TS 2019i",
        TS15: "TS 2020",
        TS16: "TS 2021",
        TS17: "TS 2022",
        TS18: "TS 2023",
        //'TBS0': 'Tekla BIMsight 1',
        //'TBS1': 'Tekla BIMsight 1.5',
        //'TBS2': 'Tekla BIMsight 2',
        //'SK0': 'SketchUp 7',
        //'SK1': 'SketchUp 8'
      },
      qualityTags: {
        experimental: "Экспериментальная версия",
        candidate: "Версия-кандидат",
        approved: "Одобренная версия",
        standard: "Стандартная версия",
      },
      compatibleOperatingSystems: {
        winXP: "Windows XP",
        winVista: "Windows Vista",
        win7: "Windows 7",
        win8: "Windows 8",
        win10: "Windows 10",
      },
      supportedLanguages: {
        en: "Английский",
        "zh-Hans": "Китайский (упрощенный)",
        "zh-Hant": "Китайский (традиционный)",
        cs: "Чешский",
        da: "Датский",
        nl: "Нидерландский",
        fi: "Финский",
        fr: "Французский",
        de: "Немецкий",
        hu: "Венгерский",
        it: "Итальянский",
        ja: "Японский",
        ko: "Корейский",
        pl: "Польский",
        pt: "Португальский",
        "pt-br": "Португальский (Бразилия)",
        ru: "Русский",
        es: "Испанский",
        sv: "Шведский",
        no: "Норвежский",
        other: "Другой",
      },
      isoLangs: {
        ab: {
          name: "Абхазский",
          nativeName: "аҧсуа",
        },
        aa: {
          name: "Афарский",
          nativeName: "Afaraf",
        },
        af: {
          name: "Африкаанс",
          nativeName: "Afrikaans",
        },
        ak: {
          name: "Акан",
          nativeName: "Akan",
        },
        sq: {
          name: "Албанский",
          nativeName: "Shqip",
        },
        am: {
          name: "Амхарский",
          nativeName: "አማርኛ",
        },
        ar: {
          name: "Арабский",
          nativeName: "العربية",
        },
        an: {
          name: "Арагонский",
          nativeName: "Aragonés",
        },
        hy: {
          name: "Армянский",
          nativeName: "Հայերեն",
        },
        as: {
          name: "Ассамский",
          nativeName: "অসমীয়া",
        },
        av: {
          name: "Аварский",
          nativeName: "авар мацӀ, магӀарул мацӀ",
        },
        ae: {
          name: "Авестийский",
          nativeName: "avesta",
        },
        ay: {
          name: "Аймара",
          nativeName: "aymar aru",
        },
        az: {
          name: "Азербайджанский",
          nativeName: "azərbaycan dili",
        },
        bm: {
          name: "Бамана",
          nativeName: "bamanankan",
        },
        ba: {
          name: "Башкирский",
          nativeName: "башҡорт теле",
        },
        eu: {
          name: "Баскский",
          nativeName: "euskara, euskera",
        },
        be: {
          name: "Белорусский",
          nativeName: "Беларуская",
        },
        bn: {
          name: "Бенгальский",
          nativeName: "বাংলা",
        },
        bh: {
          name: "Бихарский",
          nativeName: "भोजपुरी",
        },
        bi: {
          name: "Бислама",
          nativeName: "Bislama",
        },
        bs: {
          name: "Боснийский",
          nativeName: "bosanski jezik",
        },
        br: {
          name: "Бретонский",
          nativeName: "brezhoneg",
        },
        bg: {
          name: "Болгарский",
          nativeName: "български език",
        },
        my: {
          name: "Бирманский",
          nativeName: "ဗမာစာ",
        },
        ca: {
          name: "Каталанский; валенсийский",
          nativeName: "Català",
        },
        ch: {
          name: "Чаморро",
          nativeName: "Chamoru",
        },
        ce: {
          name: "Чеченский",
          nativeName: "нохчийн мотт",
        },
        ny: {
          name: "Чичева; чева; ньянджа",
          nativeName: "chiCheŵa, chinyanja",
        },
        zh: {
          name: "Китайский (упрощенный) и китайский",
          nativeName: "中文 (Zhōngwén), 汉语, 漢語",
        },
        "zh-Hans": {
          name: "Китайский (упрощенный)",
          nativeName: "汉语",
        },
        "zh-Hant": {
          name: "Китайский (традиционный)",
          nativeName: "漢語",
        },
        "zh-tw": {
          name: "Китайский (Тайвань)",
          nativeName: "國語",
        },
        cv: {
          name: "Чувашский",
          nativeName: "чӑваш чӗлхи",
        },
        kw: {
          name: "Корнский",
          nativeName: "Kernewek",
        },
        co: {
          name: "Корсиканский",
          nativeName: "corsu, lingua corsa",
        },
        cr: {
          name: "Кри",
          nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
        },
        hr: {
          name: "Хорватский",
          nativeName: "hrvatski",
        },
        cs: {
          name: "Чешский",
          nativeName: "česky, čeština",
        },
        da: {
          name: "Датский",
          nativeName: "dansk",
        },
        dv: {
          name: "Дивехи; мальдивский",
          nativeName: "ދިވެހި",
        },
        nl: {
          name: "Нидерландский",
          nativeName: "Nederlands, Vlaams",
        },
        en: {
          name: "Английский",
          nativeName: "English",
        },
        eo: {
          name: "Эсперанто",
          nativeName: "Esperanto",
        },
        et: {
          name: "Эстонский",
          nativeName: "eesti, eesti keel",
        },
        ee: {
          name: "Эве",
          nativeName: "Eʋegbe",
        },
        fo: {
          name: "Фарерский",
          nativeName: "føroyskt",
        },
        fj: {
          name: "Фиджи",
          nativeName: "vosa Vakaviti",
        },
        fi: {
          name: "Финский",
          nativeName: "suomi, suomen kieli",
        },
        fr: {
          name: "Французский",
          nativeName: "Français",
        },
        ff: {
          name: "Фула; пель; фульбе; пулар",
          nativeName: "Fulfulde, Pulaar, Pular",
        },
        gl: {
          name: "Галисийский",
          nativeName: "Galego",
        },
        ka: {
          name: "Грузинский",
          nativeName: "ქართული",
        },
        de: {
          name: "Немецкий",
          nativeName: "Deutsch",
        },
        el: {
          name: "Греческий (современный)",
          nativeName: "Ελληνικά",
        },
        gn: {
          name: "Гуарани",
          nativeName: "Avañeẽ",
        },
        gu: {
          name: "Гуджарати",
          nativeName: "ગુજરાતી",
        },
        ht: {
          name: "Гаитянский; гаитянский креольский",
          nativeName: "Kreyòl ayisyen",
        },
        ha: {
          name: "Хауса",
          nativeName: "Hausa, هَوُسَ",
        },
        he: {
          name: "Иврит (современный)",
          nativeName: "עברית",
        },
        hz: {
          name: "Гереро",
          nativeName: "Otjiherero",
        },
        hi: {
          name: "Хинди",
          nativeName: "हिन्दी, हिंदी",
        },
        ho: {
          name: "Хири-моту",
          nativeName: "Hiri Motu",
        },
        hu: {
          name: "Венгерский",
          nativeName: "Magyar",
        },
        ia: {
          name: "Интерлингва",
          nativeName: "Interlingua",
        },
        id: {
          name: "Индонезийский",
          nativeName: "Bahasa Indonesia",
        },
        ie: {
          name: "Интерлингве",
          nativeName: "Originally called Occidental; then Interlingue after WWII",
        },
        ga: {
          name: "Ирландский",
          nativeName: "Gaeilge",
        },
        ig: {
          name: "Ибо",
          nativeName: "Asụsụ Igbo",
        },
        ik: {
          name: "Инупиак",
          nativeName: "Iñupiaq, Iñupiatun",
        },
        io: {
          name: "Идо",
          nativeName: "Ido",
        },
        is: {
          name: "Исландский",
          nativeName: "Íslenska",
        },
        it: {
          name: "Итальянский",
          nativeName: "Italiano",
        },
        iu: {
          name: "Инуктитут",
          nativeName: "ᐃᓄᒃᑎᑐᑦ",
        },
        ja: {
          name: "Японский",
          nativeName: "日本語 (にほんご／にっぽんご)",
        },
        jv: {
          name: "Яванский",
          nativeName: "basa Jawa",
        },
        kl: {
          name: "Калааллисут; гренландский",
          nativeName: "kalaallisut, kalaallit oqaasii",
        },
        kn: {
          name: "Каннада",
          nativeName: "ಕನ್ನಡ",
        },
        kr: {
          name: "Канури",
          nativeName: "Kanuri",
        },
        ks: {
          name: "Кашмирский",
          nativeName: "कश्मीरी, كشميري‎",
        },
        kk: {
          name: "Казахский",
          nativeName: "Қазақ тілі",
        },
        km: {
          name: "Кхмерский",
          nativeName: "ភាសាខ្មែរ",
        },
        ki: {
          name: "Кикуйу; кикуйю",
          nativeName: "Gĩkũyũ",
        },
        rw: {
          name: "Руанда",
          nativeName: "Ikinyarwanda",
        },
        ky: {
          name: "Киргизский, кыргызский",
          nativeName: "кыргыз тили",
        },
        kv: {
          name: "Коми",
          nativeName: "коми кыв",
        },
        kg: {
          name: "Конго",
          nativeName: "KiKongo",
        },
        ko: {
          name: "Корейский",
          nativeName: "한국어",
        },
        ku: {
          name: "Курдский",
          nativeName: "Kurdî, كوردی‎",
        },
        kj: {
          name: "Кваньяма, ошикваньяма",
          nativeName: "Kuanyama",
        },
        la: {
          name: "Латинский",
          nativeName: "latine, lingua latina",
        },
        lb: {
          name: "Люксембургский",
          nativeName: "Lëtzebuergesch",
        },
        lg: {
          name: "Луганда; ганда",
          nativeName: "Luganda",
        },
        li: {
          name: "Лимбургский; лимбуржский",
          nativeName: "Limburgs",
        },
        ln: {
          name: "Лингала",
          nativeName: "Lingála",
        },
        lo: {
          name: "Лаосский",
          nativeName: "ພາສາລາວ",
        },
        lt: {
          name: "Литовский",
          nativeName: "lietuvių kalba",
        },
        lu: {
          name: "Луба-катанга",
          nativeName: "",
        },
        lv: {
          name: "Латышский",
          nativeName: "latviešu valoda",
        },
        gv: {
          name: "Мэнский",
          nativeName: "Gaelg, Gailck",
        },
        mk: {
          name: "Македонский",
          nativeName: "македонски јазик",
        },
        mg: {
          name: "Малагасийский",
          nativeName: "Malagasy fiteny",
        },
        ms: {
          name: "Малайский",
          nativeName: "bahasa Melayu, بهاس ملايو‎",
        },
        ml: {
          name: "Малаялам",
          nativeName: "മലയാളം",
        },
        mt: {
          name: "Мальтийский",
          nativeName: "Malti",
        },
        mi: {
          name: "Маори",
          nativeName: "te reo Māori",
        },
        mr: {
          name: "Маратхи",
          nativeName: "मराठी",
        },
        mh: {
          name: "Маршалльский",
          nativeName: "Kajin M̧ajeļ",
        },
        mn: {
          name: "Монгольский",
          nativeName: "монгол",
        },
        na: {
          name: "Науру",
          nativeName: "Ekakairũ Naoero",
        },
        nv: {
          name: "Навахо",
          nativeName: "Diné bizaad, Dinékʼehǰí",
        },
        nb: {
          name: "Норвежский букмол",
          nativeName: "Norsk bokmål",
        },
        nd: {
          name: "Северный ндебеле",
          nativeName: "isiNdebele",
        },
        ne: {
          name: "Непальский",
          nativeName: "नेपाली",
        },
        ng: {
          name: "Ндонга",
          nativeName: "Owambo",
        },
        nn: {
          name: "Норвежский нюнорск",
          nativeName: "Norsk nynorsk",
        },
        no: {
          name: "Норвежский",
          nativeName: "Norsk",
        },
        ii: {
          name: "Носу",
          nativeName: "ꆈꌠ꒿ Nuosuhxop",
        },
        nr: {
          name: "Южный ндебеле",
          nativeName: "isiNdebele",
        },
        oc: {
          name: "Окситанский",
          nativeName: "Occitan",
        },
        oj: {
          name: "Оджибве",
          nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
        },
        cu: {
          name: "Древнецерковнославянский; церковнославянский; старославянский",
          nativeName: "ѩзыкъ словѣньскъ",
        },
        om: {
          name: "Оромо",
          nativeName: "Afaan Oromoo",
        },
        or: {
          name: "Ория",
          nativeName: "ଓଡ଼ିଆ",
        },
        os: {
          name: "Осетинский",
          nativeName: "ирон æвзаг",
        },
        pa: {
          name: "Панджаби; пенджаби",
          nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
        },
        pi: {
          name: "Пали",
          nativeName: "पाऴि",
        },
        fa: {
          name: "Персидский",
          nativeName: "فارسی",
        },
        pl: {
          name: "Польский",
          nativeName: "polski",
        },
        ps: {
          name: "Пушту; пашто",
          nativeName: "پښتو",
        },
        pt: {
          name: "Португальский",
          nativeName: "Português",
        },
        "pt-br": {
          name: "Португальский (Бразилия)",
          nativeName: "Português do Brasil",
        },
        qu: {
          name: "Кечуа",
          nativeName: "Runa Simi, Kichwa",
        },
        rm: {
          name: "Ретороманский",
          nativeName: "rumantsch grischun",
        },
        rn: {
          name: "Кирунди",
          nativeName: "kiRundi",
        },
        ro: {
          name: "Румынский; молдавский",
          nativeName: "română",
        },
        ru: {
          name: "Русский",
          nativeName: "русский",
        },
        sa: {
          name: "Санскрит",
          nativeName: "संस्कृतम्",
        },
        sc: {
          name: "Сардинский",
          nativeName: "sardu",
        },
        sd: {
          name: "Синдхи",
          nativeName: "सिन्धी, سنڌي، سندھی‎",
        },
        se: {
          name: "Северносаамский",
          nativeName: "Davvisámegiella",
        },
        sm: {
          name: "Самоанский",
          nativeName: "gagana faa Samoa",
        },
        sg: {
          name: "Санго",
          nativeName: "yângâ tî sängö",
        },
        sr: {
          name: "Сербский",
          nativeName: "српски језик",
        },
        gd: {
          name: "Гэльский",
          nativeName: "Gàidhlig",
        },
        sn: {
          name: "Шона",
          nativeName: "chiShona",
        },
        si: {
          name: "Сингальский",
          nativeName: "සිංහල",
        },
        sk: {
          name: "Словацкий",
          nativeName: "slovenčina",
        },
        sl: {
          name: "Словенский",
          nativeName: "slovenščina",
        },
        so: {
          name: "Сомалийский",
          nativeName: "Soomaaliga, af Soomaali",
        },
        st: {
          name: "Южный сото; сесото",
          nativeName: "Sesotho",
        },
        es: {
          name: "Испанский", //      'name':'Традиционный испанский',
          nativeName: "Español",
        },
        su: {
          name: "Сунданский",
          nativeName: "Basa Sunda",
        },
        sw: {
          name: "Суахили",
          nativeName: "Kiswahili",
        },
        ss: {
          name: "Свати",
          nativeName: "SiSwati",
        },
        sv: {
          name: "Шведский",
          nativeName: "svenska",
        },
        ta: {
          name: "Тамильский",
          nativeName: "தமிழ்",
        },
        te: {
          name: "Телугу",
          nativeName: "తెలుగు",
        },
        tg: {
          name: "Таджикский",
          nativeName: "тоҷикӣ, toğikī, تاجیکی‎",
        },
        th: {
          name: "Тайский",
          nativeName: "ไทย",
        },
        ti: {
          name: "Тигринья",
          nativeName: "ትግርኛ",
        },
        bo: {
          name: "Тибетский стандартный; центральный тибетский",
          nativeName: "བོད་ཡིག",
        },
        tk: {
          name: "Туркменский",
          nativeName: "Türkmen, Түркмен",
        },
        tl: {
          name: "Тагальский; тагалог",
          nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
        },
        tn: {
          name: "Тсвана",
          nativeName: "Setswana",
        },
        to: {
          name: "Тонганский (острова Тонга)",
          nativeName: "faka Tonga",
        },
        tr: {
          name: "Турецкий",
          nativeName: "Türkçe",
        },
        ts: {
          name: "Тсонга",
          nativeName: "Xitsonga",
        },
        tt: {
          name: "Татарский",
          nativeName: "татарча, tatarça, تاتارچا‎",
        },
        tw: {
          name: "Тви",
          nativeName: "Twi",
        },
        ty: {
          name: "Таитянский",
          nativeName: "Reo Tahiti",
        },
        ug: {
          name: "Уйгурский",
          nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
        },
        uk: {
          name: "Украинский",
          nativeName: "українська",
        },
        ur: {
          name: "Урду",
          nativeName: "اردو",
        },
        uz: {
          name: "Узбекский",
          nativeName: "zbek, Ўзбек, أۇزبېك‎",
        },
        ve: {
          name: "Венда",
          nativeName: "Tshivenḓa",
        },
        vi: {
          name: "Вьетнамский",
          nativeName: "Tiếng Việt",
        },
        vo: {
          name: "Волапюк",
          nativeName: "Volapük",
        },
        wa: {
          name: "Валлонский",
          nativeName: "Walon",
        },
        cy: {
          name: "Уэльский",
          nativeName: "Cymraeg",
        },
        wo: {
          name: "Волоф",
          nativeName: "Wollof",
        },
        fy: {
          name: "Западнофризский",
          nativeName: "Frysk",
        },
        xh: {
          name: "Коса",
          nativeName: "isiXhosa",
        },
        yi: {
          name: "Идиш",
          nativeName: "ייִדיש",
        },
        yo: {
          name: "Йоруба",
          nativeName: "Yorùbá",
        },
        za: {
          name: "Чжуанский",
          nativeName: "Saɯ cueŋƅ, Saw cuengh",
        },
        other: {
          name: "Другой",
          nativeName: "Другой",
        },
      },

      details: {
        navigation: {
          content_information: "Информация о контенте",
          version_and_file_management: "Управление версиями и файлами",
          details: "Сведения",
          versions: "Версии",
          analytics: "Аналитика",
          statistics: "Статистика",
          manage_access: "Управление доступом",
          related_content: "Связанный контент",
          properties: "Свойства",
          support: "Компания и поддержка",
          release_notes: "Замечания к выпуску",
          comments: "Комментарии пользователей",
        },
        related_content: {
          other_content_title: "Другой контент, который может вам понравиться",
          related_content_title: "Контент, который рекомендует ",
          related_content_url: "URL-адрес контента",
          add_related_content: "Добавить связанный контент",
          add_related_content_info:
            "Добавить связанный онлайн-контент можно только к онлайн-контенту, а локальный контент — к локальному контенту",
          remove_related_content: "Удалить",
          relatedContentUrlSubtitle: "ID контента или полный URL-адрес контента",
          updated_successfully: "Связанный контент успешно обновлен",
          update_failed: "Ошибка при обновлении связанного контента",
          update_failed_invalid_id: "Указан неверный ID контента",
        },
        actions: {
          move: "Переместить",
          delete: "Удалить",
          add_comment: "Добавить комментарий",
          add_reply: "Добавить ответ",
          edit_comment: "Редактировать комментарий",
          reply_comment: "Добавить ответ",
          view_replies: "Просмотреть ответы",
          report_bad_comment: "Сообщить о ненадлежащем комментарии &rsaquo;",
        },
        edit: {
          tooltips: {
            title_or_delete: "Редактировать название или удалить",
            title_or_move_delete: "Редактировать название, переместить или удалить",
            thumbnail: "Редактировать эскиз",
            description: "Редактировать описание",
            groupandcategory: "Редактировать группу и категорию",
            details: "Редактировать сведения",
            versions: "Редактировать версии",
            support: "Редактировать информацию о поддержке",
            release_notes: "Редактировать замечания к выпуску",
            search_criteria: "Редактировать критерии поиска",
            batchedit_and_translations:
              "Функция 'Перевод' позволяет публиковать ваш контент на нескольких языках. Пользователи будут видеть переведенную версию вашего контента в зависимости от того, какой языка они выбрали для интерфейса Warehouse. Функция 'Пакетное изменение' позволяет редактировать сразу несколько элементов. Сначала выберите поле, а затем элементы, которые вы хотите отредактировать",
          },
        },
        links: {
          link: "Создать ссылку",
          select_collection_placeholder: "Выберите коллекцию, на которую будет указывать ссылка",
          linking_collections_label: "Коллекции, связанные ссылками с этой коллекцией",
          private_content_note:
            "Примечание. Частный контент будет виден только пользователям, у которых есть к нему доступ.",
          share_on_social_media: "Поделиться:",
          report_bad_content: "Сообщить о ненадлежащем контенте &rsaquo;",
          report_bad_content_link: "http://teklastructures.support.tekla.com/webform/tekla-warehouse-report-abuse",
          report_illegal_content: "Сообщить о недопустимом содержимом &rsaquo;",
          report_illegal_content_link: "https://support.tekla.com/digital-safety",
        },
        alerts: {
          subscriber_note:
            "Подписываясь на уведомления, вы соглашаетесь, что ваши отображаемое имя Tekla Account и название организации (если применимо) станут известны владельцу контента.",
          alert_me: "Уведомлять меня",
          unsubscribe: "Отменить подписку",
          subscribed_successfully: "Подписка успешно зарегистрирована",
          unsubscribed_successfully: "Подписка успешно отменена",
          subscribers_label: "Подписчики",
        },
        reviews: {
          reviewers_label: "Рецензенты",
        },
        comments: {
          replies: "Ответы",
        },
        scan: {
          soft_failed_binaries_info: "Проверка на вирусы не пройдена  ",
          hard_failed_binaries_info: "Не удалось выполнить проверку на вирусы ",
          infected_binaries_info: "При проверке обнаружено заражение ",
          what_now: "Что дальше?",
          popup: {
            soft_failed_binaries_title: "Двоичные файлы, не прошедшие проверку",
            hard_failed_binaries_title: "Двоичные файлы, не прошедшие проверку",
            infected_binaries_title: "Зараженные двоичные файлы",
            soft_failed_binaries_info:
              "Проверка отправленных вами файлов на вирусы завершилась сбоем. Это временная проблема. Удалите файлы и отправьте их еще раз. Все файлы должны пройти проверку на вирусы, прежде чем они станут видны другим пользователям.",
            hard_failed_binaries_info:
              "Не удалось выполнить проверку на вирусы. Удалите файлы. Все файлы должны пройти проверку на вирусы, прежде чем они станут видны другим пользователям.",
            infected_binaries_info:
              "При проверке отправленных вами файлов обнаружено заражение. Удалите файлы. Все файлы должны пройти проверку на вирусы, прежде чем они станут видны другим пользователям.",
            version: "Версия: ",
            fileName: "имя файла: ",
          },
        },
        status: {
          content_banned_info:
            "Содержимое заблокировано администратором. Сейчас оно недоступно для других пользователей, кроме владельца содержимого. <a href='https://support.tekla.com/contact-us' target='_blank'>Обратиться в службу поддержки</a> за дополнительной информацией.",
          content_archived_info:
            "Это содержимое заархивировано. Пользователи без прав на редактирование будут перенаправлены на главную страницу, если они попытаются перейти к содержимому по ссылке.",
          archived: "Заархивировано",
        },
        modified_by: function (name) {
          return " автор " + name;
        },
        created_by: function (name) {
          return "Создано пользователем " + name;
        },
        deleteEntity: function (title) {
          return "Удалить " + title + "?";
        },
        userComments: "Комментарии пользователей",
        comment_added: "Комментарий успешно добавлен",
        comment_updated: "Комментарий успешно обновлен",
        comment_deleted: "Комментарий успешно удален",
        comment_add_failed: "Не удалось добавить комментарий",
        comment_update_failed: "Не удалось обновить комментарий",
        comment_delete_failed: "Не удалось удалить комментарий",
        comment_delete_confirmation: "Удалить комментарий?",
        name: "Имя",
        details: "Сведения",
        contentOwner: "Автор",
        contentOwnerGroup: "Группа владельцев контента",
        contentGroup: "Группа контента",
        thumbnail: "Эскиз",
        tooltip: "Всплывающая подсказка",
        description: "Описание",
        additional_information: "Дополнительная информация",
        images: "Изображения изделия",
        installationInfo: "Информация об установке",
        productCode: "Код изделия",
        copyrightInfo: "Авторские права",
        productExpirationDate: "Дата окончания срока действия изделия",
        helpUrl: "Веб-сайт &rsaquo;",
        supportUrl: "Поддержка &rsaquo;",
        specialTermsUrl: "Лицензионное соглашение &rsaquo;",
        releaseNotesUrl: "Замечания к выпуску",
        supportForumUrl: "Форум для обсуждения &rsaquo;",
        itemTypeCategories: "Группа",
        useCategories: "Категория",
        category: "Категория:",
        group: "Группа:",
        compatibleSoftwareProducts: "Совместимые программные продукты",
        compatibleOperatingSystems: "Совместимые операционные системы",
        locationRestrictions: "Доступно в регионе:",
        supportedLanguages: "Языки:",
        measurementUnits: "Единицы измерения:",
        tags: "Теги и ключевые слова:",
        prerequisites: "Предварительные условия:",
        releaseNotes: "Замечания к выпуску:",
        versions: "Версии",
        testedVersions: "Совместимые версии",
        qualityTag: "Качество (по оценке владельца)",
        otherAttributes: "Прочие атрибуты",
        labels: "Критерии поиска",
        supportInformation: "Поддержка",
        updateInformation: "Обновления",
        latestUpdate: "Последнее обновление",
        totalDownloads: "Всего загрузок",
        licenses: "Лицензии",
        maintenanceRequired: "Для загрузки требуется договор на обслуживание с Tekla",
        tekla_maintenance_content: "Контент, доступный в рамках договора на обслуживание с Tekla",
        download_requires_tekla_maintenance: "Для загрузки требуется договор на обслуживание с Tekla",
        download_requires_tekla_maintenance_link: "http://www.tekla.com/products/tekla-structures/maintenance",
        updated_successfully: "Успешно обновлено",
        update_failed: "Сбой при обновлении",
        update_failed_invalid_tag: "Сбой при обновлении: недопустимый тег",
        update_failed_details_max_length_exceeded: "Сбой при обновлении: слишком длинные сведения",
        show_version_details: "Подробнее",
        content_removed: "Контент удален",
        no_versions_available:
          "Версии отсутствуют. Отображение обновлений может занять некоторое время. Обновите окно браузера, чтобы увидеть изменения.",
        thumbnail_deleted: "Эскиз удален",
        thumbnail_updated: "Эскиз обновлен",
        verify_service_plugin_is_running: "Убедитесь, что плагин Tekla Warehouse Service работает корректно",
        verify_ts_connection: "Убедитесь, что программа Tekla Structures запущена",
        download_tos: {
          title: "Качество, поддержка и конфиденциальность",
          content_1: "Убедитесь, что загружаемый контент соответствует вашим стандартам качества. Trimble не предоставляет поддержку стороннего контента и не несет за него ответственности. Обращайтесь за поддержкой непосредственно к поставщику контента.",
          content_2: "Ваша страна и название вашей организации могут быть опубликованы на сервисе и предоставлены владельцам контента для законных целей, например для уведомления вас о внесении изменений в контент. Вы можете отказаться от получения этих уведомлений.",
          content_3: 'Для расширений контента Trimble применяются <a href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank">условия Trimble</a>. Условия лицензии третьих сторон могут применяться к контенту третьих сторон. См. <a href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank">Уведомление о конфиденциальности Trimble</a>.',
        },
      },
      page_metadata: {
        default: {
          title: "Tekla Warehouse",
          description:
            "Это бесплатное хранилище BIM-контент для Tekla Structures. Находите, импортируйте, устанавливайте и обменивайтесь наработками внутри компании и глобально. Tekla Warehouse поможет вам эффективно создавать модели высокого качества.",
        },
        landing: {
          title: "Tekla Warehouse",
          description:
            "Tekla Warehouse — это бесплатная онлайновая и локальная библиотека инструментов моделирования, интеллектуальных деталей и шаблонов для Tekla Structures.",
        },
        search: {
          title: "Поиск на Tekla Warehouse",
          description:
            "Поиск расширений для Tekla, 3D-деталей, форм, пользовательских компонентов, профилей, материалов (например, сортов стали и бетона), арматурных стержней, сеток и шаблонов",
        },
        about: {
          title: "Информация о Tekla Warehouse",
          description:
            "Tekla Warehouse — это бесплатный канал для маркетинга ваших продуктов, который обеспечивает вам полный контроль над вашим контентом",
          developers: {
            title: "Название разработчика по умолчанию",
            description: "Описание разработчика по умолчанию",
          },
          usage: {
            title: "Название назначения по умолчанию",
            description: "Описание назначения по умолчанию",
          },
          getting_started: {
            title: "Название раздела 'начало работы' по умолчанию",
            description: "Описание раздела 'начало работы' по умолчанию",
          },
          content: {
            title: "Название контента по умолчанию",
            description: "Описание контента по умолчанию",
          },
          using_content: {
            title: "Название раздела 'использование контента' по умолчанию",
            description: "Описание раздела 'использование контента' по умолчанию",
          },
          creating_content: {
            title: "Название раздела 'создание контента' по умолчанию",
            description: "Описание раздела 'создание контента' по умолчанию",
          },
        },
        collection: {
          title: "Название коллекции по умолчанию",
          description: "Описание коллекции по умолчанию",
        },
        my_warehouse: {
          title: "My Tekla Warehouse",
          description: "Управление вашим контентом на Tekla Warehouse",
        },
      },
      upload: {
        actions: {
          cancel: "Отмена",
          previous: "&lsaquo; Назад",
          next: "Далее &rsaquo;",
        },
        content: {
          add_content: "Добавить файлы",
        },
        collection: {
          heading: "Выбрать или создать коллекцию",
          subHeading:
            "Контент группируется в коллекции. Вы можете использовать онлайн-коллекции для публикации своего контент или локальные коллекции для обмена контентом со своими коллегами. Локальные коллекции также удобны собственного пользования.",
          loading: "Загрузка коллекций...",
          selectFromMyCollections: "Выбрать из моих коллекций",
          description_of_the_collection: "Описание",
          title_validation: {
            missing: "Введите название.",
            too_short: function (length) {
              return "Название должно быть не короче " + length + " символов.";
            },
            too_long: function (length) {
              return "Название должно быть не длиннее " + length + " символов.";
            },
          },
        },
        package: {
          basic_information: "Основная информация",
          code_name: "Кодовое имя",
          code_name_info: "Введите кодовое имя в качестве уникального идентификатора данного содержимого. Кодовое имя может содержать буквы нижнего регистра, цифры и специальные символы _ - @. После сохранения кодового имени изменить его невозможно. ",
          code_name_validation: {
            missing: "Необходимо ввести кодовое имя",
            wrong_format: "Кодовое имя может включать в себя буквы нижнего регистра, цифры и эти специальные символы: _ - @",
            not_unique: "Это кодовое имя уже используется. Необходимо ввести уникальное кодовое имя.",
          },
          search_criteria: "Критерии поиска",
          support: "Поддержка",
          title: "Заголовок",
          thumbnail: "Эскиз",
          "3D_pic": "3D-модель",
          title_validation: {
            missing: "Введите название.",
            too_short: function (length) {
              return "Название должно быть не короче " + length + " символов.";
            },
          },
          description_validation: {
            missing: "Введите описание.",
            too_short: function (length) {
              return "Описание должно быть не короче " + length + " символов.";
            },
          },
          wrong_file_type: "Неверный тип файла.",
          selected_image_is_too_big: "Выбранное изображение слишком велико.",
        },
        version: {
          select_version: "Выберите версию",
          select_quality: "Выберите качество",
          versions: "Версии",
          displayNameNew: "Отображаемое имя версии",
          copy_from_version: "Копировать метаданные из существующей версии (необязательно)",
          displayName: "Отображаемое имя",
          content_information: "Информация о контенте",
          back_to_content_information: "&lsaquo; Назад к информации о контенте",
          version_and_file_management: "Управление версиями и файлами",
          there_s_no_versions_yet: "Версии пока отсутствуют.",
          create_a_version_to_add_files: "Создайте версию для добавления файлов.",
          add_version: "Добавить версию",
          version_information: "Информация о версии",
          delete_version: "Удалить версию",
          no_versions_available: "Версии отсутствуют. Отображение обновлений может занять некоторое время.",
          update_start_title_html: '<div class="spinner spinner-white">Обновление</div>',
          update_start_message: "Это может занять некоторое время.",
          version_was_created: "Версия создана.",
          version_was_created_with_delay: "Версия создана. Отображение изменений может занять несколько минут.",
          version_was_updated: "Версия обновлена.",
          version_was_deleted: "Версия удалена.",
          version_was_deleted_with_delay: "Версия удалена. Отображение изменений может занять несколько минут.",
          confirm_delete: function (title) {
            return 'Удалить версию "' + title + '"?';
          },
        },
        publish: {
          package_was_uploaded: "Пакет отправлен.",
          couldn_t_create_a_package: "Не удалось создать пакет. Будет выполнен откат изменений.",
          uploading_title_html: '<div class="spinner spinner-white">Отправка</div>',
          uploading_text: "Это может занять некоторое время.",
          select_organization: "Выберите организацию",
          collection_visibility: "Видимость коллекции:",
          actions: {
            create: "Создать",
            publish: "Опубликовать",
          },
          this_content_will_be_visible_to_all_users_with_access_to_the_local_or_network_drive:
            "Этот контент будет виден всем пользователям, имеющим доступ к локальному или сетевому диску.",
          this_content_will_be_visible_only_to_you: "Это контент будет виден только вам.",
          this_content_will_be_visible_only_to_you_and_your_organization:
            "Этот контент будет виден только вам и пользователям в вашей организации.",
          this_content_will_be_visible_to_everyone_after_publishing: "Это контент после публикации будет виден всем.",
          public_content_disclaimer:
            "Вы несете ответственность за весь контент, отправляемый вами на сервис. Убедитесь, что контент не является вредоносным, а также что у вас есть право отправлять его на сервис и что вы не нарушаете никаких прав на интеллектуальную собственность. Вы соглашаетесь публиковать на сервисе все условия, которые могут действовать в отношении использования отправляемого вами контента.",
        },
        metadata: {
          title: "Метаданные",
          subtitle: "Основные данные",
          deleteThumbnail: "Удалить эскиз",
          buttonCreate: "Создать",
        },
        basicdata: {
          title: "Добавить контент",
          clickButtonToSelectFile: "Нажмите кнопку, чтобы выбрать файл",
          thumbnail: "Эскиз",
          dropImageHere: "Перетащите изображение сюда",
        },
        "3dGeometry": {
          title: "Создать каталог 3D-изделий",
          buttonAddEntity: "Добавить 3D-изделие",
          buttonAdd: "Добавить пользовательский атрибут",
          errorAttributeAlreadyExists: "Атрибут с таким именем уже существует.",
          buttonCreatePackage: "Создать пакет",
          buttonCreatePackages: "Создать пакеты",
          addCustomAttributePlaceholder: "Имя атрибута",
        },
        please_log_in_fragments: {
          please: "Пожалуйста, ",
          log_in: "войдите",
          or: " или ",
          register: "зарегистрируйтесь,",
          to_upload_online_content: " чтобы отправлять онлайн-контент.",
        },
        header: {
          upload_header: "Отправка контента в Warehouse",
          collection: "Коллекция",
          content: "Контент",
          version: "Версия",
          finish: "Готово",
        },
        contentOwner: "Автор",
        contentOwnerGroup: "Опубликовать контент",
        select_action: "Выберите действие",
        tooltip: "Всплывающая подсказка",
        description: "Описание",
        copyright: "Авторские права",
        tags: "Теги и ключевые слова",
        tagsSubtitle:
          "разделенные запятыми, например: ключевые_слова1, ключевые_слова2. Если ключевое слово состоит из нескольких слов, между словами должен быть знак подчеркивания (_); пробелы между словами не допускаются. Кроме знака подчеркивания, никакие другие специальные символы не допускаются.",
        images: "Изображения",
        installationInfo: "Информация об установке",
        productCode: "Код изделия",
        copyrightInfo: "Информация об авторских правах",
        Details: "Сведения",
        specialTermsUrl: "Лицензионное соглашение",
        productExpirationDate: "Дата окончания срока действия изделия",
        helpUrl: "Веб-сайт",
        helpUrlSubtitle: "вставьте полный URL-адрес",
        detailsSubtitle:
          "На этом этапе в поле сведений можно добавить только простой текст. После создания контента вы сможете отредактировать сведения и добавить HTML-форматирование.",
        specialTermsUrlSubtitle:
          "Владелец контента может добавить для своего контента дополнительные условия использования. Для этого необходимо указать URL-адрес на страницу, где эти условия опубликованы. Условия владельца никоим образом не отменяют условий использования, оговоренных Trimble.",
        releaseNotes: "Файл замечаний к выпуску",
        releaseNoteFileSubTitle: "Добавить замечания к выпуску в виде файла",
        supportUrl: "Справка и поддержка",
        supportUrlSubtitle:
          'вставьте полный URL-адрес или префикс "mailto:" для отправки запросов по электронной почте',
        supportForumUrl: "Форум для обсуждения (веб-сайт)",
        supportForumUrlSubtitle: "вставьте полный URL-адрес",
        measurementUnits: "Единицы измерения",
        itemTypeCategories: "Группа",
        useCategories: "Категория",
        compatibleSoftwareProducts: "Совместимые программные продукты",
        testedVersions: "Совместимые версии",
        compatibleOperatingSystems: "Совместимая операционная система",
        locationRestrictions: "Доступно в регионе",
        supportedLanguages: "Языки",
        select_releasenotes_file: "Выбрать файл",
        qualityTag: "Качество (по оценке владельца)",
        requireMaintenance: "Для загрузки требуется договор на обслуживание с Tekla",
        contentOwnerGroupAsMyself: "Себя",
        contentOwnerGroupAsUserGroup: "Группы пользователей",
        selectUserGroupForContentOwner: "Выберите группу пользователей в качестве владельца контента",
        defaultUploadTemplate: "Пакет по умолчанию",
        templateFor3DGeometryFiles: "Пакет(ы) 3D-геометрии",
        title: "Название",
        details: "Сведения",
        selectContentOwner: "Выберите владельца контента",
        selectContentType: "Тип",
        errorWrongfiletype: "Неверный тип файла",
        errorImageTooBig: "Выбранное изображение слишком велико.",
        errorBlankTitle: "Название не может быть пустым.",
        commonLabelsTitle: "Общие метки для всех пакетов",
      },
      profile: {
        content_usage: "Использование контента",
        profile_updated: "Профиль обновлен",
        invitation_accepted: function (target) {
          return "Приглашение принято! Теперь у вас есть доступ к " + target + ".";
        },
        user_groups: {
          edit_user_group: "Редактировать группу пользователей",
          user_group_updated: "Группа пользователей обновлена",
          create_a_new_user_group: "Создать новую группу пользователей",
          name_of_the_user_group: "Имя группы пользователей",
          description: "Описание",
          group_visibility: "Видимость группы",
          remove: '"Вы уверены?"',
          remove_invitations: '"Вы уверены?"',
          remove_members: '"Вы уверены?"',
          invitations_removed: "Приглашения удалены.",
          members_list_updated: "Список участников обновлен.",
          user_group_created: "Группа пользователей создана",
        },
        members: {
          update_selected_members: "Обновить выбранных участников",
          new_role: "Новая роль",
          members_list_updated: "Список участников обновлен.",
          edit_user_group: "Редактировать группу пользователей",
          delete_user_group: "Удалить группу пользователей",
          filter: "Фильтр",
          user_group_members: "Участники группы пользователей",
          update_members: "Обновить участников",
          remove_members: "Удалить участников",
          pending_invitations: "Ожидающие приглашения",
          invite_member: "Пригласить участника",
          update_invitations: "Обновить приглашения",
          remove_invitations: "Удалить приглашения",
        },
        subTabs: {
          alerts: "Мои уведомления",
          collections: "Коллекции",
          likes: "Мне нравится",
        },
        admin: {
          grant_sales_admin: "Предоставить пользователю роль администратора продаж",
          grant_analyst_role: "Предоставить пользователю роль аналитика",
          role_granted: "Роль предоставлена",
          role_removed: "Роль отозвана",
          offline_to_online: "Преобразовать офлайн-контент в онлайн-контент",
          use_elastic_search: "Использовать эластичный поиск",
        },
        sales: {
          title: "Продажи",
        },
        subtitlePreferences: "Предпочтения поиска",
        preferenceInfo:
          'Эти фильтры предназначены для отображения рекомендуемого вам контента на <a href="#/landing">главной странице</a> Tekla Warehouse.',
        clearFilters: "Удалить фильтры &rsaquo;",
        admin_role: "Администратор",
        sales_role: "Специалист по продажам",
        admin_mode: "Режим администратора:",
        firstName: "Имя",
        lastName: "Фамилия",
        displayName: "Отображаемое имя:",
        organization: "Название организации:",
        email: "Эл. почта:",
        teklaId: "Tekla ID",
        maintenanceAccess: "Доступ к контенту, предлагаемому при наличии договора на обслуживание с Tekla:",
        myLocations: "Регионы",
        measurementUnits: "Единицы измерения",
        useCategories: "Использовать",
        itemTypeCategories: "Меня интересуют следующие типы контента",
        languages: "Языки",
        operatingSystems: "Операционные системы",
        software: "Программные продукты",
        user_id: "ID пользователя:",
        organization_id: "ID организации:",
        edit_in_atc: "Редактировать мой профиль в Tekla Account",
        no_likes: "Отметки 'нравится' отсутствуют",
        no_alerts: "Подписки отсутствуют",
        clear_alerts: "Удалить уведомления",
        alerts_info_html:
          "Вы получите уведомление, если произойдет какое-либо из следующих изменений:<br>" +
          "<b>Контент:</b> изменено название, описание, критерии поиска или метаданные элемента контента, добавлена новая версия контента либо удалена существующая версия.<br>" +
          "<b>Коллекция:</b> отредактировано название или описание, добавлен или удален контент.",
      },
      uploader: {
        placeholders: {
          productCode: "Код изделия",
          title: "Название",
          description: "Описание",
          productWeight: "Масса изделия",
          weightUnit: "Единица измерения массы",
          material: "Материал",
          enter: "",
        },
        addContent: {
          "3dGeometryButton": "Каталог 3D-изделий",
          addToolButton: "Приложение",
          select_files: "Файлы с диска",
          select_files_from_ts: "Файлы из Tekla Structures",
          partnerDownloadLink: "Партнерская ссылка для загрузки",
        },
        headings: {
          geometryFile: "Файл геометрии",
          thumbnailFile: "Файл эскиза (.bmp)",
          title: "Название",
          description: "Описание",
          productCode: "Код изделия",
          productWeight: "Масса изделия",
          weightUnit: "Единица измерения массы",
          material: "Материал",
        },
      },
      constants: {
        countries: {
          GLOBAL: "Весь мир",
          AF: "Афганистан",
          AX: "Аландские острова",
          AL: "Албания",
          DZ: "Алжир",
          AS: "Восточное Самоа",
          AD: "Андорра",
          AO: "Ангола",
          AI: "Ангилья",
          AQ: "Антарктида",
          AG: "Антигуа и Барбуда",
          AR: "Аргентина",
          AM: "Армения",
          AW: "Аруба",
          AU: "Австралия",
          AT: "Австрия",
          AZ: "Азербайджан",
          BS: "Багамы",
          BH: "Бахрейн",
          BD: "Бангладеш",
          BB: "Барбадос",
          BY: "Беларусь",
          BE: "Бельгия",
          BZ: "Белиз",
          BJ: "Бенин",
          BM: "Бермудские острова",
          BT: "Бутан",
          BO: "Боливия",
          BQ: "Бонайре",
          BA: "Босния и Герцеговина",
          BW: "Ботсвана",
          BV: "Остров Буве",
          BR: "Бразилия",
          IO: "Британская территория в Индийском океане",
          BN: "Бруней-Даруссалам",
          BG: "Болгария",
          BF: "Буркина-Фасо",
          BI: "Бурунди",
          KH: "Камбоджа",
          CM: "Камерун",
          CA: "Канада",
          CV: "Кабо-Верде",
          KY: "Каймановы острова",
          CF: "Центральноафриканская Республика",
          TD: "Чад",
          CL: "Чили",
          CN: "Китай",
          CX: "Остров Рождества",
          CC: "Кокосовые острова (острова Килинг)",
          CO: "Колумбия",
          KM: "Коморские острова",
          CG: "Республика Конго",
          CD: "Демократическая Республика Конго",
          CK: "Острова Кука",
          CR: "Коста-Рика",
          CI: "Кот-д'Ивуар",
          HR: "Хорватия",
          CU: "Куба",
          CW: "Кюрасао",
          CY: "Кипр",
          CZ: "Чешская Республика",
          DK: "Дания",
          DJ: "Джибути",
          DM: "Доминика",
          DO: "Доминиканская Республика",
          EC: "Эквадор",
          EG: "Египет",
          SV: "Сальвадор",
          GQ: "Экваториальная Гвинея",
          ER: "Эритрея",
          EE: "Эстония",
          ET: "Эфиопия",
          FK: "Фолклендские (Мальвинские) острова",
          FO: "Фарерские острова",
          FJ: "Фиджи",
          FI: "Финляндия",
          FR: "Франция",
          GF: "Французская Гвиана",
          PF: "Французская Полинезия",
          TF: "Французские Южные Территории",
          GA: "Габон",
          GM: "Гамбия",
          GE: "Грузия",
          DE: "Германия",
          GH: "Гана",
          GI: "Гибралтар",
          GR: "Греция",
          GL: "Гренландия",
          GD: "Гренада",
          GP: "Гваделупа",
          GU: "Гуам",
          GT: "Гватемала",
          GG: "Гернси",
          GN: "Гвинея",
          GW: "Гвинея-Бисау",
          GY: "Гайана",
          HT: "Гаити",
          HM: "Остров Херд и острова МакДональд",
          VA: "Ватикан",
          HN: "Гондурас",
          HK: "Гонконг",
          HU: "Венгрия",
          IS: "Исландия",
          IN: "Индия",
          ID: "Индонезия",
          IR: "Иран",
          IQ: "Ирак",
          IE: "Ирландия",
          IM: "Остров Мэн",
          IL: "Израиль",
          IT: "Италия",
          JM: "Ямайка",
          JP: "Япония",
          JE: "Джерси",
          JO: "Иордания",
          KZ: "Казахстан",
          KE: "Кения",
          KI: "Кирибати",
          KW: "Кувейт",
          KG: "Киргизия",
          LA: "Лаос",
          LV: "Латвия",
          LB: "Ливан",
          LS: "Лесото",
          LR: "Либерия",
          LY: "Ливия",
          LI: "Лихтенштейн",
          LT: "Литва",
          LU: "Люксембург",
          MO: "Макао",
          MK: "Македония",
          MG: "Мадагаскар",
          MW: "Малави",
          MY: "Малайзия",
          MV: "Мальдивы",
          ML: "Мали",
          MT: "Мальта",
          MH: "Маршалловы острова",
          MQ: "Мартиника",
          MR: "Мавритания",
          MU: "Маврикий",
          YT: "Майотта",
          MX: "Мексика",
          FM: "Микронезия",
          MD: "Молдова",
          MC: "Монако",
          MN: "Монголия",
          ME: "Черногория",
          MS: "Монтсеррат",
          MA: "Марокко",
          MZ: "Мозамбик",
          MM: "Мьянма (Бирма)",
          NA: "Намибия",
          NR: "Науру",
          NP: "Непал",
          NL: "Нидерланды",
          NC: "Новая Каледония",
          NZ: "Новая Зеландия",
          NI: "Никарагуа",
          NE: "Нигер",
          NG: "Нигерия",
          NU: "Ниуэ",
          NF: "Остров Норфолк",
          KP: "Северная Корея",
          MP: "Северные Марианские острова",
          NO: "Норвегия",
          OM: "Оман",
          PK: "Пакистан",
          PW: "Палау",
          PS: "Палестинская Автономия",
          PA: "Панама",
          PG: "Папуа-Новая Гвинея",
          PY: "Парагвай",
          PE: "Перу",
          PH: "Филиппины",
          PN: "Питкэрн",
          PL: "Польша",
          PT: "Португалия",
          PR: "Пуэрто-Рико",
          QA: "Катар",
          RE: "Реюньон",
          RO: "Румыния",
          RU: "Россия",
          RW: "Руанда",
          BL: "Сен-Бартельми",
          SH: "Остров Святой Елены",
          KN: "Сент-Китс и Невис",
          LC: "Сент-Люсия",
          MF: "Сен-Мартен",
          PM: "Сен-Пьер и Микелон",
          VC: "Сент-Винсент и Гренадины",
          WS: "Самоа",
          SM: "Сан-Марино",
          ST: "Сан-Томе и Принсипи",
          SA: "Саудовская Аравия",
          SN: "Сенегал",
          RS: "Сербия",
          SC: "Сейшелы",
          SL: "Сьерра-Леоне",
          SG: "Сингапур",
          SX: "Синт-Мартен",
          SK: "Словакия",
          SI: "Словения",
          SB: "Соломоновы острова",
          SO: "Сомали",
          ZA: "Южная Африка",
          GS: "Южная Георгия",
          KR: "Южная Корея",
          SS: "Южный Судан",
          ES: "Испания",
          LK: "Шри-Ланка",
          SD: "Судан",
          SR: "Суринам",
          SJ: "Шпицберген и Ян-Майен",
          SZ: "Свазиленд",
          SE: "Швеция",
          CH: "Швейцария",
          SY: "Сирийская Арабская Республика",
          TW: "Тайвань",
          TJ: "Таджикистан",
          TZ: "Танзания",
          TH: "Таиланд",
          TL: "Восточный Тимор",
          TG: "Того",
          TK: "Токелау",
          TO: "Тонга",
          TT: "Тринидад и Тобаго",
          TN: "Тунис",
          TR: "Турция",
          TM: "Туркменистан",
          TC: "Острова Тёркс и Кайкос",
          TV: "Тувалу",
          UG: "Уганда",
          UA: "Украина",
          AE: "Объединенные Арабские Эмираты",
          GB: "Великобритания",
          US: "США",
          UM: "Внешние малые острова США",
          UY: "Уругвай",
          UZ: "Узбекистан",
          VU: "Вануату",
          VE: "Венесуэла",
          VN: "Вьетнам",
          VG: "Виргинские острова (Великобритания)",
          VI: "Виргинские острова (США)",
          WF: "Уоллис и Футуна",
          EH: "Западная Сахара",
          YE: "Йемен",
          ZM: "Замбия",
          ZW: "Зимбабве",
        },
        sortOptions: {
          "-modifyTime": "дате обновления",
          "-modifiedAt": "дате обновления",
          title: "алфавиту А-Я",
          "-title": "алфавиту Я-А",
          "-reviewCount": "популярности",
          name: "имени (названию)",
          role: "роли",
          email: "адресу эл. почты",
          "-relevance": "релевантности",
          "-createdAt": "дате создания",
          displayName: "алфавиту А-Я",
          "-displayName": "алфавиту Я-А",
          //'owner': 'Creator' }, // would sort by ide, not name
          // '-reviewCount': 'Least liked'}  // would be useful for content owners
        },
        analyticsTimeframeOptions: {
          today: "Сегодня",
          yesterday: "Вчера",
          this_week: "На этой неделе",
          previous_week: "На прошлой неделе",
          this_month: "В этом месяце",
          previous_month: "В прошлом месяце",
          this_year: "В этом году",
          previous_year: "В прошлом году",
          beginning_of_time: "С начала времени",
          custom: "Свой период",
        },
        eventTypeOptions: {
          downloads: "Загрузки",
          views: "Просмотры",
          all_events: "Все события",
        },
      },
    });
  },
};
