import { Settings as LuxonSettings } from "luxon";
import _ from "underscore";

import { RootStore } from "./rootStore";
import { AbstractCookieStore } from "./abstractCookieStore";
import { IDropdownOption } from "../models/dataModel";
import { supportedLocales } from "../constants/LocalizationConstants";
import { Localizations } from "../localizations/Localizations";
import { Settings } from "../config/Settings";

/**
 * Store for handling of localization selection.
 */
export class LocalizationStore extends AbstractCookieStore {
  /**
   * Root store.
   */
  private rootStore: RootStore;

  /**
   * Supported locale options
   */
  private localeOptions: IDropdownOption[] = [];

  /**
   * Cookie name for presenting current locale
   */
  public static readonly COOKIE_NAME = "locale";

  private static readonly DEFAULT_LANGUAGE = "en-US";

  /**
   * Constructor
   * @param rootStore RootStore
   */
  public constructor(rootStore) {
    super();
    this.rootStore = rootStore;
    Localizations.init();
    this.initLocale();
  }

  /**
   * Method for getting locale options.
   * @returns Locale options.
   */
  public getLocaleOptions(): IDropdownOption[] {
    if (!this.localeOptions || this.localeOptions.length === 0) {
      this.localeOptions = this.fetchLocaleOptions();
    }
    return this.localeOptions;
  }

  /**
   * Method for getting selected locale option
   * @return Selected locale option.
   */
  public getSelectedLocaleOption(): IDropdownOption | undefined {
    const currentLocale = this.getLocale();
    return _.find(this.getLocaleOptions(), function (localeOption) {
      return localeOption.value == currentLocale;
    });
  }

  /**
   * Method for getting selected locale in lang format
   * @return Selected locale in the lang format (e.g. 'it', 'en', 'pt-PT').
   */
  public getLangForSelectedLocale(): string {
    const currentLocale = this.getLocale().replace("_", "-");
    let lang = "";

    if (currentLocale && currentLocale.length > 0) {
      const filteredLocale = _.find(supportedLocales, (locale) => {
        return locale.isoCode === currentLocale;
      });

      if (filteredLocale) {
        lang = filteredLocale.lang;
      }
    }
    return lang;
  }

  /**
   * Gets cookie name
   * @returns cookie name.
   */
  public getCookieName(): string {
    return LocalizationStore.COOKIE_NAME;
  }

  /**
   * Gets cookie value.
   * @returns cookie value.
   */
  public parseCookie(): string {
    return this.cookieHandle.value;
  }

  /**
   * Reads current locale setting from cookies.
   * @returns current locale
   * @default "en-US"
   */
  public getLocale(): string {
    const cookie = this.parseCookie();
    return !!cookie ? cookie.replace("_", "-") : "en-US";
  }

  /**
   * Stores cookie.
   * @params cookieValue the valut to be saved to cookie
   */
  public storeInCookie(cookieValue: string) {
    if (Settings.isLocalDev) {
      this.cookieHandle.set(cookieValue, { expires: 365 });
    } else {
      this.cookieHandle.set(cookieValue, {
        expires: 365,
        domain: ".tekla.com",
      });
    }
  }

  /**
   * Method for reading the language param from url
   * @param search the search part of the url
   */
  public setLocaleByLanguageParam(search: URLSearchParams): void {
    const langParam = search.get("tekla_hv_language");

    if (!!langParam) {
      const newLocale = _.find(supportedLocales, (sl) => sl.lang.toLowerCase() === langParam.replace("_", "-"));

      if (!!newLocale && this.getLocale().replace("_", "-") !== newLocale.isoCode) {
        this.changeLocale(newLocale.isoCode);
      }
    }
  }

  /**
   * Method for changing selected locale
   * @param locale locale value.
   */
  public changeLocale(locale: string) {
    const currentLocale = _.findWhere(supportedLocales, { isoCode: locale });
    if (currentLocale) {
      LuxonSettings.defaultLocale = currentLocale.lang;
      this.storeInCookie(currentLocale.isoCode.replace("-", "_"));
      location.reload();
    }
  }

  /**
   * Method for getting translation for given key
   * @param keyStr the key to be searched with.
   @ @returns translated string or function
   */
  public getTranslation(keyStr: string): string | any {
    let translation: string | any;
    if (!window.locale[this.getLocale()]) {
      translation = "missing locale: " + this.getLocale();
    } else {
      const t = this.findByKeyStr(keyStr);
      translation = _.isString(t) || _.isFunction(t) ? t : "missing key: " + keyStr;
    }
    return translation;
  }

  private findByKeyArrayFromObject(object: any, keys: string[]) {
    let match = object;
    for (let i = 0; i < keys.length; i++) {
      if (match && Object.prototype.hasOwnProperty.call(match, keys[i])) {
        match = match[keys[i]];
      } else {
        match = null;
        break;
      }
    }
    return match;
  }

  private findByKeyStr(keyStr: string) {
    const keys = keyStr.split(".");
    let match = this.findByKeyArrayFromObject(window.locale[this.getLocale()], keys);

    if (!match && this.getLocale() != LocalizationStore.DEFAULT_LANGUAGE) {
      match = this.findByKeyArrayFromObject(window.locale[LocalizationStore.DEFAULT_LANGUAGE], keys);
    }
    return match;
  }

  private getLangCode(lang: string): string {
    if (lang === "zh-Hans") {
      return "zh";
    } else if (lang === "zh-Hant") {
      return "zh-tw";
    } else return lang;
  }

  /**
   * Fetches locale options.
   * @returns Locale options.
   */
  private fetchLocaleOptions() {
    return Object.keys(supportedLocales).map((key) => {
      const locale = supportedLocales[key];
      let name = this.rootStore.getTranslation("isoLangs." + this.getLangCode(locale.langKey) + ".nativeName");
      name = name.charAt(0).toUpperCase() + name.slice(1);
      return { value: locale.isoCode, label: name };
    });
  }

  private initLocale(): void {
    if (!this.parseCookie()) {
      if (navigator.language) {
        if (this.isSupportedLang(navigator.language)) {
          const localeObj = _.findWhere(supportedLocales, {
            lang: navigator.language,
          });
          !!localeObj && this.storeInCookie(localeObj.isoCode.replace("-", "_"));
        } else if (this.isSupportedIsoLocaleCode(navigator.language)) {
          const localeObj = _.findWhere(supportedLocales, {
            isoCode: navigator.language,
          });
          !!localeObj && this.storeInCookie(localeObj.isoCode.replace("-", "_"));
        }
      }
    }
  }

  private isSupportedLang(lang: string): boolean {
    return _.contains(_.pluck(supportedLocales, "lang"), lang);
  }

  private isSupportedIsoLocaleCode(isoCode: string): boolean {
    return _.contains(_.pluck(supportedLocales, "isoCode"), isoCode);
  }
}
