import { observer } from "mobx-react";
import * as React from "react";
import { Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router";

import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";
import { TabNavigationStore, TabNavigationStoreContext } from "../components/tab-navigation/tabNavigationStore";
import { DialogContext, DialogStore } from "../dialogs/dialogStore";
import { RootContext } from "../stores/rootStore";
import { ScanReportDialogStore, ScanReportDialogStoreContext } from "./dialogs/scanReportDialogStore";
import { PackagePageStoreContext } from "./packagePageStore";

import { TemporaryBannerNotification } from "../components/TemporaryBannerNotification";
import { TranslatedHtml } from "../components/TranslatedHtml";
import { BannedBanner } from "../components/BannedBanner";
import { ArchivedBanner } from "../components/ArchivedBanner";
import { Breadcrumbs } from "../components/breadcrumbs/Breadcrumbs";
import { IResource } from "../models/dataModel";
import { Info } from "./Info";
import { Tabs } from "./Tabs";
import { ScanReport } from "./dialogs/ScanReport";

import { Settings } from "../config/Settings";

/**
 * A component that displays package page.
 */
export const PackagePage = observer(({ isLocal }: { isLocal: boolean }) => {
  const rootStore = useContext(RootContext);
  const packagePageStore = useContext(PackagePageStoreContext);
  const versions = packagePageStore.getVersions();
  const scanReportDialogStore = new ScanReportDialogStore(rootStore, versions);
  const packageItem = packagePageStore.getPackage();

  const { id } = useParams();

  async function fetchData() {
    await packagePageStore.fetchData({ packageId: id || "", isLocal: isLocal });
  }

  useEffect(() => {
    fetchData();
  }, [id]);


  return (
    <Fragment>
      {packagePageStore.wasDataFetched() && packagePageStore.getPackage() && (
        <Breadcrumbs item={packagePageStore.getPackage()! as IResource} />
      )}
      <TemporaryBannerNotification text={<TranslatedHtml
        entry="landing.notifications.content_editor_role.content_page_info"
        args={[Settings.notifications.contentEditorRoleLaunchDate, Settings.notifications.contentEditorRoleProductBulletinLink]} />}
        bannerFeatureToggle="contentEditorRoleInfoAfter"
        show={
          rootStore.getUserStore().isUserLoggedIn() &&
          (!!packageItem &&
            packagePageStore.isOwnedByOrganization(packageItem) &&
            rootStore.getUserStore().belongsToOrganization(packageItem) &&
            !rootStore.getUserStore().canEditOrganization(packagePageStore.getOrganization()!)
          )
        }
      />
      <div id="content-details" className="catalog entity">
        <section className="main">
          <DialogContext.Provider value={new DialogStore()}>
            <ScanReportDialogStoreContext.Provider value={scanReportDialogStore}>
              <ScanReport />
            </ScanReportDialogStoreContext.Provider>
          </DialogContext.Provider>
          <EditorModeStoreContext.Provider value={new EditorModeStore(rootStore, packagePageStore)}>
            {packagePageStore.wasDataFetched() && (
              <Fragment>
                <BannedBanner item={packagePageStore.getPackage()!} />
                <ArchivedBanner item={packagePageStore.getPackage()!} />
                <Info />
                <TabNavigationStoreContext.Provider value={new TabNavigationStore()}>
                  <Tabs />
                </TabNavigationStoreContext.Provider>
              </Fragment>
            )}
          </EditorModeStoreContext.Provider>
        </section>
      </div>
    </Fragment>
  );
});
