import * as React from "react";
import { observer } from "mobx-react";
import { useContext, Fragment, useEffect } from "react";

import { RootContext, RootStore } from "../../stores/rootStore";
import { DialogContext, DialogStore } from "../dialogStore";
import { UserCommentsStoreContext, UserCommentsStore } from "../../package/tabs/userCommentsStore";

import { IEntity, IOrganization, IComment, IUser } from "../../models/dataModel";
import { SanitizationModeEnum } from "../../models/enums";

import { TextWrapper } from "../../components/TextWrapper";
import { AddCommentDialog } from "./AddCommentDialog";
import { EditCommentDialog } from "./EditCommentDialog";
import { ReplyDialogStore, ReplyDialogStoreContext } from "./ReplyDialogStore";

const ReplyItem = ({ reply }: { reply: IComment }) => {
  const rootStore: RootStore = useContext(RootContext);
  const commentStore: UserCommentsStore = useContext(UserCommentsStoreContext);
  const replyStore: ReplyDialogStore = useContext(ReplyDialogStoreContext);

  const user: IUser | undefined = rootStore.getUserStore().getCurrentUser();
  const canEditComment = user && reply.author.id === user.id;
  const replyTestId = "reply-text-" + reply.id;

  function enterEditReplyView() {
    replyStore.setSelectedReply(reply);
    replyStore.setDialogState("editReply");
  }

  return (
    <Fragment>
      <div className="comment-createtime">{commentStore.getModifiedDate(reply.createTime)}</div>
      <div className="news-feed-text" data-testid={replyTestId}>
        <p>
          <TextWrapper text={reply.text} sanitizationMode={SanitizationModeEnum.JUST_LINKS} />
        </p>
      </div>
      {canEditComment && (
        <a
          className="icon icon-pencil edit-comment-link"
          title={rootStore.getTranslation("details.actions.edit_comment")}
          onClick={enterEditReplyView}
        >
          <span>{rootStore.getTranslation("details.actions.edit_comment")}</span>
        </a>
      )}
      <section className="report-bad-comment">
        <a
          href={rootStore.getTranslation("details.links.report_bad_content_link")}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextWrapper
            text={rootStore.getTranslation("details.actions.report_bad_comment")}
            sanitizationMode={SanitizationModeEnum.JUST_LINKS}
          />
        </a>
      </section>
    </Fragment>
  );
};

/**
 * Renders the "View replies" dialog used on package page.
 */
export const ViewRepliesDialog = observer(
  ({ subject, parentComment }: { subject: IEntity | IOrganization; parentComment: IComment }) => {
    const rootStore: RootStore = useContext(RootContext);
    const commentStore: UserCommentsStore = useContext(UserCommentsStoreContext);
    const replyStore: ReplyDialogStore = useContext(ReplyDialogStoreContext);
    const dialog: DialogStore = useContext(DialogContext);

    useEffect(() => {
      replyStore.setDialogState("viewReplies");
      replyStore.setReplies(parentComment.replies!);
    }, []);

    const replies = replyStore.getReplies();

    async function closeDialog() {
      await commentStore.handleCommentEdit(false);
      dialog.close();
    }

    function enterAddReplyView() {
      replyStore.setDialogState("addReply");
    }

    function renderViewRepliesView() {
      return (
        <article data-testid="viewRepliesDialog">
          <header>
            <h2 className="l-center-align" data-testid="viewRepliesHeader">
              {rootStore.getTranslation("details.comments.replies")}
            </h2>
          </header>
          <section className="main-comment">
            <div className="comment-createtime">{commentStore.getModifiedDate(parentComment.createTime)}</div>
            <div className="news-feed-text" data-testid="parentCommentText">
              <TextWrapper text={parentComment.text} sanitizationMode={SanitizationModeEnum.JUST_LINKS} />
            </div>
          </section>
          <section className="reply-comments">
            <div className="reply-comment-title">{rootStore.getTranslation("details.comments.replies")}</div>
            <div className="user-comment-items">
              {replies.map((reply: IComment, i: number) => {
                return <ReplyItem key={i} reply={reply} />;
              })}
            </div>
          </section>
          <div className="actions">
            <button onClick={closeDialog} data-testid="closeDialogButton">
              {rootStore.getTranslation("actions.close")}
            </button>
            <button className="button-primary" onClick={enterAddReplyView} data-testid="addReplyButton">
              {rootStore.getTranslation("details.actions.add_reply")}
            </button>
          </div>
        </article>
      );
    }

    function renderAddReplyView() {
      return <AddCommentDialog subject={subject} parentComment={parentComment} />;
    }

    function renderEditReplyView() {
      return <EditCommentDialog subject={subject} commentToEdit={replyStore.getSelectedReply()} />;
    }

    return (
      <Fragment>
        {replyStore.getDialogState() === "viewReplies" && renderViewRepliesView()}
        {replyStore.getDialogState() === "addReply" && renderAddReplyView()}
        {replyStore.getDialogState() === "editReply" && renderEditReplyView()}
      </Fragment>
    );
  },
);
