import * as React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Fragment, useContext, useEffect } from "react";

import { RootContext } from "../stores/rootStore";
import { LocalCollectionPageStoreContext } from "./localCollectionPageStore";
import { EditorModeStore, EditorModeStoreContext } from "../components/editor-mode/editorModeStore";
import { LocalThumbnailStore, LocalThumbnailStoreContext } from "../components/thumbnail/localThumbnailStore";

import { IResource } from "../models/dataModel";

import { Breadcrumbs } from "../components/breadcrumbs/Breadcrumbs";
import { EditorMode } from "../components/editor-mode/EditorMode";
import { LocalCollectionTitle } from "./dialogs/LocalCollectionTitle";
import { CollectionDescription } from "../collection/dialogs/CollectionDescription";
import { Packages } from "./Packages";
import { LocalThumbnail } from "../components/thumbnail/LocalThumbnail";

/**
 * A component that displays thumbnails and description
 */
export const Info = observer(() => {
  const rootStore = useContext(RootContext);
  const localCollectionPageStore = useContext(LocalCollectionPageStoreContext);

  return (
    <article id="collection_info">
      <div className="description">
        <LocalThumbnailStoreContext.Provider
          value={new LocalThumbnailStore(rootStore, localCollectionPageStore.getCollection())}
        >
          <LocalThumbnail />
        </LocalThumbnailStoreContext.Provider>
        <CollectionDescription description={localCollectionPageStore.getDescription()} isLocal={true} />
      </div>
    </article>
  );
});

/**
 * A component that displays local collection page.
 */
export const LocalCollectionPage = observer(() => {
  const rootStore = useContext(RootContext);
  const localCollectionPageStore = useContext(LocalCollectionPageStoreContext);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    localCollectionPageStore.fetchData({ collectionId: id! });
  }, [id]);

  return (
    <div>
      {localCollectionPageStore.wasDataFetched() ? (
        localCollectionPageStore.getCollection() && (
          <Fragment>
            <Breadcrumbs item={localCollectionPageStore.getCollection()! as IResource} />
            <div id="content" className="collection">
              <EditorModeStoreContext.Provider value={new EditorModeStore(rootStore, localCollectionPageStore)}>
                {localCollectionPageStore.wasDataFetched() && (
                  <section className="content-details">
                    <EditorMode />
                    <LocalCollectionTitle />
                    <Info />
                  </section>
                )}
                <Packages />
              </EditorModeStoreContext.Provider>
            </div>
          </Fragment>
        )
      ) : (
        <div className="spinner l-margin-default l-center-align" data-testid="loadingLocalCollection">
          {rootStore.getTranslation("shared.spinner_loading")}
        </div>
      )}
    </div>
  );
});
