import { IModifier } from "../../models/dataModel";
import { ExternalResourceTypeEnum } from "../../models/enums";

/**
 * Helper class for handling creator data
 */
export const Creator = {
  /**
   * Generates creator object
   * @params data source to create object from
   * @params creatorType type of creator (user/organization)
   */
  convert: (data: any, creatorType?: ExternalResourceTypeEnum): IModifier => {
    const creator: IModifier = {
      id: data.id || "",
      displayName: data.displayName || "",
      externalResourceType: creatorType || ExternalResourceTypeEnum.USER,
      pictureUrl: data.pictureUrl || "",
      trimbleId: data.trimbleId || "",
      isVerified: data.isVerified || false,
    };
    return creator;
  },
};
