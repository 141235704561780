import * as React from "react";
import { Fragment, useContext, useState } from "react";
import { observer } from "mobx-react";
import _ from "underscore";
import { Link } from "react-router";

import { RootContext, RootStore } from "../../stores/rootStore";
import { ResultOptionsStoreContext, ResultOptionsStore } from "./resultOptionsStore";
import { LinkToCollectionStoreContext, LinkToCollectionStore } from "./linkToCollectionStore";
import { DialogStore, DialogContext } from "../../dialogs/dialogStore";

import { TranslatedHtml } from "../TranslatedHtml";
import { Dropdown } from "../Dropdown";
import { ConfirmAction } from "../../dialogs/ConfirmAction";

import { IDropdownOption, IEntity, ILinkingResult } from "../../models/dataModel";

/**
 * Component that renders 'link' button & displays the linking result:
 * * If linking is successful, displays a 'success' notification.
 * * If linking to a resources fails, displays a 'failure' notification and opens a dialog listing the failed links.
 */
const LinkingResult = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const failureDialog: DialogStore = useContext(DialogContext);
  const linkToCollectionStore: LinkToCollectionStore = useContext(LinkToCollectionStoreContext);
  const resultOptionsStore: ResultOptionsStore = useContext(ResultOptionsStoreContext);

  const [failures, setFailures] = useState<Array<{ error: string; entity: IEntity }>>([]);

  async function link() {
    try {
      renderLinkingResult(await linkToCollectionStore.link(resultOptionsStore.selectedEntities));
    } catch (err) {
      console.log(err);
    }
  }

  function renderLinkingResult(linkingResult: ILinkingResult) {
    if (linkingResult.failures.length === 0) {
      rootStore
        .getNotificationChannelStore()
        .success(
          rootStore.getTranslation("shared.linked_resources.selected_resources_linked", [
            linkingResult.collection.title,
          ]),
        );
    } else {
      if (linkingResult.linkedCount > 0) {
        rootStore
          .getNotificationChannelStore()
          .error(rootStore.getTranslation("shared.linked_resources.resources_linked_with_errors"));
      } else {
        rootStore
          .getNotificationChannelStore()
          .error(rootStore.getTranslation("shared.linked_resources.resources_linking_failed"));
      }

      setFailures(linkingResult.failures);
      failureDialog.open();
    }
  }

  function getDialogContent(failures: Array<{ error: string; entity: IEntity }>) {
    return (
      <ul className="link-errors" data-testid="failure-dialog">
        {_.map(failures, (failure, i) => {
          return (
            <li key={i}>
              <Link target="_blank" rel="noopener noreferrer" to={getEntityLink(failure.entity)}>
                {failure.entity.title}
              </Link>
              <span>{failure.error}</span>
            </li>
          );
        })}
      </ul>
    );
  }

  function getEntityLink(entity: IEntity) {
    if (entity.isLocal) {
      return "/catalog/localdetails/" + entity.id;
    } else {
      return "/catalog/details/" + entity.id;
    }
  }

  return (
    <Fragment>
      <button
        className="link"
        disabled={!linkToCollectionStore.getLinkToCollection()}
        onClick={link}
        data-testid="link-button"
      >
        <TranslatedHtml entry="details.links.link" />
      </button>
      <ConfirmAction
        title={rootStore.getTranslation("shared.linked_resources.linking_failed_title")}
        content={getDialogContent(failures)}

        callbackConfirm={() => { }}
        callbackCloseDialog={() => failureDialog.close()}
        labelCancel={""}
        labelConfirm={rootStore.getTranslation("shared.confirm.close")}
      />
    </Fragment>
  );
});

/**
 * A component displaying a popup that let user select collection to link an entity to.
 */
const Popup = observer(() => {
  const rootStore = useContext(RootContext);
  const resultOptionsStore = useContext(ResultOptionsStoreContext);
  const linkToCollectionStore = useContext(LinkToCollectionStoreContext);
  const confirmDialog: DialogStore = new DialogStore();

  const collectionOptions = _.map(linkToCollectionStore.getCollectionsWithLinkingRights(), (collection) => {
    return { label: collection.title!, value: collection.id! };
  });

  function hidePopup() {
    resultOptionsStore.setLinkingPopupVisible(false);
  }

  function changeCollection(opt: IDropdownOption | null) {
    if (!opt) return;

    linkToCollectionStore.setLinkToCollection(opt);
  }

  return (
    <Fragment>
      {resultOptionsStore.getLinkingPopupVisible() && (
        <div className="link-popup" onMouseLeave={hidePopup} data-testid="linking-popup">
          {linkToCollectionStore.getQueryingLinks() && (
            <div className="querying">
              <div className="spinner spinner-gray">
                <TranslatedHtml entry="shared.spinner_loading" />
              </div>
            </div>
          )}
          {!linkToCollectionStore.getQueryingLinks() && (
            <div className="popup-content" data-testid="linking-popup-content">
              <Dropdown
                options={collectionOptions}
                onChange={changeCollection}
                placeholder={rootStore.getTranslation("details.links.select_collection_placeholder")}
                className="dropdown-wrapper light"
                selectedValue={linkToCollectionStore.getLinkToCollection()}
                inputId="link-to-collection-select"
              />
              <div className="l-center-align">
                {!linkToCollectionStore.isLinking() && (
                  <DialogContext.Provider value={confirmDialog}>
                    <LinkingResult />
                  </DialogContext.Provider>
                )}
                {linkToCollectionStore.isLinking() && (
                  <div className="l-margin-default spinner spinner-gray">
                    <TranslatedHtml entry="shared.linked_resources.linking" />
                  </div>
                )}
              </div>
              <TranslatedHtml entry="details.links.private_content_note" />
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
});

/**
 * A components that provides link to collection feature.
 */
export const LinkToCollection = observer(() => {
  const resultOptionsStore: ResultOptionsStore = useContext(ResultOptionsStoreContext);
  const linkToCollectionStore: LinkToCollectionStore = useContext(LinkToCollectionStoreContext);

  function showPopup() {
    resultOptionsStore.setLinkingPopupVisible(true);
  }

  return (
    <Fragment>
      <button
        className="link-selected"
        disabled={
          !(resultOptionsStore.selectedCount > 0 && linkToCollectionStore.getCollectionsWithLinkingRights().length > 0)
        }
        onClick={showPopup}
        data-testid="link-selected"
      >
        <TranslatedHtml entry="link.link_selected" />
      </button>
      <Popup />
    </Fragment>
  );
});
