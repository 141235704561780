import * as React from "react";
import { useContext, Fragment, useState } from "react";
import { observer } from "mobx-react";

import { RootStore, RootContext } from "../../stores/rootStore";
import { UploadFormStore, UploadFormContext } from "../uploadFormStore";
import { UploadVersionStore } from "./uploadVersionStore";

/**
 * Component for rendering an individual prerequisite link.
 */
const PrerequisiteLink = observer(({ prerequisite }) => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  const [fieldNotInFocus, setFieldNotInFocus] = useState(false);

  function handleTitleInput(event) {
    version.setPrequisiteTitle(prerequisite, event.target.value);
  }

  function handleUrlInput(event) {
    version.setPrequisiteUrl(prerequisite, event.target.value);
  }

  function handleBlur() {
    setFieldNotInFocus(true);
  }

  const urlMissing = !prerequisite.url || prerequisite.url.length === 0;
  const urlInvalid = !prerequisite.url || !prerequisite.url.match(/^(https?|ftp):\/\//);
  const errors = fieldNotInFocus && (urlMissing || urlInvalid);

  function removePrerequisite() {
    version.removePrerequisite(prerequisite);
  }

  return (
    <Fragment>
      <label className="block">{rootStore.getTranslation("shared.prerequisites.link_to_existing_content")}</label>
      <input
        type="text"
        name="prerequisiteTitle"
        placeholder={rootStore.getTranslation("shared.prerequisites.link_name_placeholder")}
        value={prerequisite.title}
        onChange={handleTitleInput}
        data-testid="prerequisiteTitle"
      />
      <div className="l-width-80 l-inline">
        <input
          type="url"
          name="prerequisiteURL"
          onChange={handleUrlInput}
          onBlur={handleBlur}
          value={prerequisite.url}
          data-testid="prerequisiteUrl"
        />
        {errors && (
          <div className="error">
            {urlMissing && (
              <small className="error">
                {rootStore.getTranslation("shared.prerequisites.please_enter_prerequisite_url")}
              </small>
            )}
            {urlInvalid && (
              <Fragment>
                <small className="error">
                  {rootStore.getTranslation("shared.prerequisites.please_enter_valid_prerequisite_url")}
                </small>
                <small className="error">{rootStore.getTranslation("shared.prerequisites.missing_http")}</small>
              </Fragment>
            )}
          </div>
        )}
      </div>
      <div className="l-width-10 l-inline">
        <button className="icon-only remove-prerequisite" onClick={removePrerequisite} data-testid="removeLink">
          <span className="icon icon-trash" />
        </button>
      </div>
    </Fragment>
  );
});

/**
 * Renders a list of prerequisites for the version.
 */
const PrerequisiteList = observer(() => {
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  return (
    <Fragment>
      {version.getPrerequisites().map((prerequisite, i: number) => {
        return (
          <li key={i}>
            <PrerequisiteLink prerequisite={prerequisite} />
          </li>
        );
      })}
    </Fragment>
  );
});

/**
 * Main component for the 'Prerequisites and recommendations' section.
 */
export const Prerequisites = observer(() => {
  const rootStore: RootStore = useContext(RootContext);
  const form: UploadFormStore = useContext(UploadFormContext);
  const version: UploadVersionStore = form.getVersionStore();

  function handlePrerequisitesInput(event) {
    version.setPrerequisiteDescription(event.target.value || "");
  }

  function addPrerequisite(e) {
    e.preventDefault();
    version.addPrerequisite({ title: "", url: "http://" });
  }

  return (
    <section className="prerequisites">
      <header>
        <h3>{rootStore.getTranslation("shared.prerequisites.add_prerequisites_and_recommendations")}</h3>
      </header>
      <div className="wrapper">
        <ol>
          <li>
            <label>{rootStore.getTranslation("shared.prerequisites.list_the_profiles_components_files")}</label>
            <textarea
              placeholder={rootStore.getTranslation("shared.prerequisites.description_placeholder")}
              value={version.getPrerequisiteDescription()}
              onChange={handlePrerequisitesInput}
              data-testid="prerequisiteDescription"
            />
          </li>
          <PrerequisiteList />
        </ol>
        <button className="add l-right" onClick={addPrerequisite} data-testid="add">
          {rootStore.getTranslation("shared.prerequisites.add_link")}
        </button>
      </div>
    </section>
  );
});
