import _ from "underscore";
import { IBinary, IFile } from "../../models/dataModel";
import { ItemSourceEnum, ItemTypeEnum, PlatformEnum } from "../../models/enums";

import { TreeNodeTypeEnum } from "../../dialogs/item-browser/tree-view/treeViewStore";
import { BinaryUtils } from "../BinaryUtils";

export interface IFileFromTS {
  nodeType: TreeNodeTypeEnum.FILE;
  itemType: ItemTypeEnum;
  reference: string;
  name: string;
}

function fileHandleToBinary(file: IFile): IBinary {
  const data = {
    id: file.id || "",
    location: "",
    file: file.wrappedObject,
    attributes: {
      fileName: file.name,
      originalName: file.name,
      fileSize: file.size,
      itemType: file.type,
      platform: file.platform,
      itemSource: "" + file.itemSource,
    },
  };

  if (file.itemSource == ItemSourceEnum.CUSTOM_FILE_PICKER || file.itemSource == ItemSourceEnum.BROWSER_FILE_PICKER) {
    data.location = "file://" + data.id;
  } else if (file.itemSource == ItemSourceEnum.EXPORTED_FROM_TS) {
    data.location = data.id;
  }
  return BinaryUtils.create(data);
}

function generateFileId(file: IFile) {
  const size = file.size ? file.size : 0;
  return "" + file.name + "-" + size;
}

function FileHandle(file: IFile, itemSource: number, forcedItemType?: ItemTypeEnum): IFile {
  const id = file.id || file._id || generateFileId(file);
  const type = forcedItemType ? forcedItemType : file.type;

  const fileHandle: IFile = {
    id: id,
    _id: id,
    wrappedObject: file.wrappedObject || undefined,
    name: file.name,
    size: file.size || 0,
    type: type || ItemTypeEnum.NO_ACTION,
    platform: file.platform || PlatformEnum.WIN_X64,
    itemSource: itemSource,
  };
  return fileHandle;
}

/**
 * File handle helper class
 */
export const FileHandleConverter = {
  /**
   * Converts given file to file handle object
   * @param file the file to be converted
   * @param forcedItemType use this itemtype instead from file
   */
  fromTS: (file: IFileFromTS, forcedItemType: ItemTypeEnum): IFile => {
    return FileHandle(
      {
        id: file.reference,
        name: file.name,
        type: file.itemType,
      },
      ItemSourceEnum.EXPORTED_FROM_TS,
      forcedItemType,
    );
  },
  /**
   * Converts given data to file handle object
   * @param file the file to be converted
   * @param forcedItemType use this itemtype instead from file
   */
  fromFilePicker: (file: File, forcedItemType: ItemTypeEnum): IFile => {
    return FileHandle(
      {
        name: file.name,
        size: file.size,
        wrappedObject: file,
      },
      ItemSourceEnum.BROWSER_FILE_PICKER,
      forcedItemType,
    );
  },
  /**
   * Converts given file to binary object
   * @param file the file to be converted
   */
  fromFilePickerToBinary: (file: File): IBinary => {
    const fileHandle: IFile = FileHandle(
      {
        name: file.name,
        size: file.size,
        wrappedObject: file,
      },
      ItemSourceEnum.BROWSER_FILE_PICKER,
    );
    return fileHandleToBinary(fileHandle);
  },
  /**
   * Converts given file to binary object
   * @param file the file to be converted
   */
  fromCustomFilePickerToBinary: (file: IFile): IBinary => {
    const fileHandle: IFile = FileHandle(
      {
        id: file.id,
        name: file.name,
        size: file.size,
        wrappedObject: file as File,
      },
      ItemSourceEnum.CUSTOM_FILE_PICKER,
    );
    return fileHandleToBinary(fileHandle);
  },
  /**
   * Converts file handle to binary
   * @param file the file to be converted
   */
  fileHandleToBinary: (file: IFile): IBinary => {
    return fileHandleToBinary(file);
  },
  /**
   * Converts file handles to binaries
   * @param files the files to be converted
   */
  fileHandlesToBinary: (files: IFile[]): IBinary[] => {
    return _.map(files, fileHandleToBinary);
  },
};
