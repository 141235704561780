import * as $ from "jquery";

const mask = $('<div id="dialog-mask"></div>').appendTo(document.body).hide();

/**
 * Helper class for showing and hiding mask
 */
export const Mask = {
  /**
   * Hides mask
   */
  hide: (): void => {
    mask.hide();
  },
  /**
   * Shows mask
   */
  show: (): void => {
    mask.show();
  },
};
